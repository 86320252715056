import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST, ISaveMechanicAssignmentRequest } from "./saveMechanicAssignmentConstants";


export interface ISaveMechanicAssignmentLoadAction {
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.REQUEST;
    data: ISaveMechanicAssignmentRequest
}
export const saveMechanicAssignmentLoadAction = (data: ISaveMechanicAssignmentRequest): ISaveMechanicAssignmentLoadAction => ({
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.REQUEST,
    data
});
export interface ISaveMechanicAssignmentSuccessAction {
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveMechanicAssignmentLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveMechanicAssignmentSuccessAction => ({
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS,
    message
});
export interface ISaveMechanicAssignmentLoadFailedAction {
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.FAILED;
    message: string;
}
export const saveMechanicAssignmentLoadFailedAction = (message: string): ISaveMechanicAssignmentLoadFailedAction => ({
    type: ISAVE_MECHANIC_ASSIGNMENT_REQUEST.FAILED,
    message
});
