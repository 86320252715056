
export enum IGET_ATTACHMENTS_REQUEST {
    REQUEST = "getAttachments/GET_MACHINE_CONTROL_REQUEST",
    SUCCESS = "getAttachments/GET_MACHINE_CONTROL_SUCCESS",
    FAILED = "getAttachments/GET_MACHINE_CONTROL_FAILED"
};

export interface IGetAttachmentsRequest {
    token: string;
};

export interface IAttachments {
    id: number;
    attachment_ID: number;
    g_H: number | null;
    g_J: number | null;
    width: number | null;
    j_K: number | null;
    j_J1: number | null;
    notes: string;
    images?: string;
    attachment_No?: string;
    type?: string;
    coupler_Design?: string;
    style?: string;
    attached_Unit_No?: string;
    site?: string;
    pictures: ISurveyAttachmentPicture[];
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};

export interface ISurveyAttachmentPicture {
    name: string;
    description: string;
    base64String: string;
};