import React, { PureComponent, ReactNode, useState } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import { Menu } from "@material-ui/core";
import { LAMenuItem } from "../shared/dropDownMenu";
import { LAIconButton } from "../shared/buttons";
import { SecondListIcon } from "../shared/icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { DARK_GREY_COLOR } from "../shared/theme";
import { LAPaperWithLessPadding } from "../shared/paper";
import { getTokenFromUrl } from "../shared/constExports";
import { IRole } from "../../redux/getAuthorization/getAuthorizationConstants";

const KantechSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .current-mobile {
        background-color: ${DARK_GREY_COLOR};
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface IKantechSubHeaderProps extends RouteComponentProps {
    role: IRole;
};

export const KantechSubHeader: React.FC<IKantechSubHeaderProps> = React.memo((props: IKantechSubHeaderProps) => {

    const path = props.location.pathname;

    const onClick = (value: string): void => {
        props.history.push(value);
    };

    const [admin, setAdmin] = useState<any>(null);
    const handleAdmin = (event?: any): void => admin !== null ? setAdmin(null) : setAdmin(event.currentTarget);

    const onHistory = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_report.rdl", "_blank");
    };

    const onAttendance = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_Attendance.rdl", "_blank");
    };

    return (
        <KantechSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={() => onClick(ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS)} className={(path.includes(ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS)) ? "current" : ""}>
                            ADJUSTMENTS
                        </LAMenuItem>

                        <LAMenuItem onClick={handleAdmin}>
                            REPORT
                        </LAMenuItem>

                        <Menu
                            anchorEl={admin}
                            open={admin}
                            onClose={handleAdmin}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <LAMenuItem onClick={onHistory}>
                                HISTORY
                            </LAMenuItem>

                            <LAMenuItem onClick={onAttendance}>
                                ATTENDANCE
                            </LAMenuItem>
                        </Menu>
                    </div>
                </p>
            </div>
        </KantechSubHeaderStyles>
    );
});


interface IKantechSubHeaderMobileState {
    drawer: boolean;
};

export class KantechSubHeaderMobile extends PureComponent<IKantechSubHeaderProps, IKantechSubHeaderMobileState> {

    public constructor(props: IKantechSubHeaderProps) {
        super(props);
        this.state = {
            drawer: false
        };
    }

    public render(): ReactNode {

        const path = this.props.location.pathname;

        return (
            <LAPaperWithLessPadding>
                <LAIconButton
                    label="Open"
                    icon={<SecondListIcon />}
                    onClick={this.handleDrawer}
                />

                <SwipeableDrawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    open={this.state.drawer}
                    onClose={this.handleDrawer}
                    onOpen={this.handleDrawer}
                >
                    <KantechSubHeaderStyles>
                        <LAGrid className="ml-3 mt-3" spacing={3}>

                            <LAGridItem xs={12} onClick={this.onAdjustment} className={(path.includes(ROUTE.FIELD.ATTACHMENT_LIST.INDEX)) ? "current-mobile" : ""}>
                                <span>
                                    ADJUSTMENTS
                                </span>
                            </LAGridItem>

                                <hr />
                                <LAGridItem xs={12} onClick={this.onHistory}>
                                    <span>
                                        HISTORY
                                    </span>
                                </LAGridItem>

                                <LAGridItem xs={12} onClick={this.onAttendance}>
                                    <span>
                                        ATTENDANCE
                                    </span>
                                </LAGridItem>

                        </LAGrid>
                    </KantechSubHeaderStyles>
                </SwipeableDrawer>
            </LAPaperWithLessPadding>
        );
    };

    private handleDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    private onAdjustment = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS,
            search: getTokenFromUrl(false)
        });
    };

    private onAttendance = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_Attendance.rdl", "_blank");
    };

    private onHistory = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_report.rdl", "_blank");
    };

}