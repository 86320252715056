import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IAPPROVE_ST_TIMESHEET_REQUEST, IApproveSTTimesheetRequest } from "./approveSTTimesheetConstants";


export interface IApproveSTTimesheetLoadAction {
    type: IAPPROVE_ST_TIMESHEET_REQUEST.REQUEST;
    data: IApproveSTTimesheetRequest
}
export const approveSTTimesheetLoadAction = (data: IApproveSTTimesheetRequest): IApproveSTTimesheetLoadAction => ({
    type: IAPPROVE_ST_TIMESHEET_REQUEST.REQUEST,
    data
});
export interface IApproveSTTimesheetSuccessAction {
    type: IAPPROVE_ST_TIMESHEET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const approveSTTimesheetLoadSuccessAction = (message: SurewayAPIResponse<string>): IApproveSTTimesheetSuccessAction => ({
    type: IAPPROVE_ST_TIMESHEET_REQUEST.SUCCESS,
    message
});
export interface IApproveSTTimesheetLoadFailedAction {
    type: IAPPROVE_ST_TIMESHEET_REQUEST.FAILED;
    message: string;
}
export const approveSTTimesheetLoadFailedAction = (message: string): IApproveSTTimesheetLoadFailedAction => ({
    type: IAPPROVE_ST_TIMESHEET_REQUEST.FAILED,
    message
});
