import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import '../../shared/soctStyles/soctStyles.css';

interface IProps{
    handleRoute:Function;
  }

  const HighlightedLink:any = {
    border: '2px solid white',
    borderRadius:'5px'
  }

export default class NavigationBar extends React.Component<IProps,{}>{
    constructor(props:any){
        super(props);
      
    }
    handleClick(elem:any){

    }
    render(){
        return (
            <Box sx={{ display: 'flex', alignItems:"flex-start"}}>
                {/* <MenuItem id='test1' onClick={() => this.props.handleRoute('home')}  className="SOCT-App-link">
                   Home
                </MenuItem> */}
                <MenuItem id='test2' onClick={() => this.props.handleRoute('sotc')} className="SOCT-App-link" style={window.location.href.includes("soct") && !window.location.href.includes("sites") && !window.location.href.includes("bulksoct") && !window.location.href.includes("meterapp") ? HighlightedLink:null}>
                   SOCT
                </MenuItem>
                {/* <MenuItem id='test5' onClick={() => this.props.handleRoute('sites')} className="SOCT-App-link">
                  Working Sites
                </MenuItem> */}
                 <MenuItem id='test6' onClick={() => this.props.handleRoute('bulkedit')} className="SOCT-App-link">
                  Bulk Meter Adjustment
                </MenuItem>
                {/* <MenuItem id='test7' onClick={() => this.props.handleRoute('meterapp')} className="SOCT-App-link" style={window.location.href.includes("meterapp") ? HighlightedLink:null}>
                  Meter App Update
                </MenuItem> */}

                <MenuItem id='test8' onClick={() => this.props.handleRoute('report')} className="SOCT-App-link" style={window.location.href.includes("report") ? HighlightedLink:null}>
                  Report
                </MenuItem>
            </Box>
        );
    }
}