import React, { useEffect, useState } from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACheckBox } from "../../shared/checkBox";
import { DataGrid } from "devextreme-react";
import { ById } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { DragDropContext } from "react-beautiful-dnd";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { undefinedFunction } from "../../shared/constExports";
import { Column, Editing, Paging } from "devextreme-react/data-grid";
import { IAssignmentSite, IDriverAssignmentSelection } from "./driversAssignment";
import { getTodayAndTomorrow, isYesterday } from "./driversAssignmentDragAndDrop";
import { IServiceTruck } from "../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksConstants";

export interface IAssignmentSiteClone {
    id: number;
    idx: number;
    site: string;
    notes: string;
    status: string;
    unitNo: string;
    drivers: string;
    cloned: boolean;
    swampers: string;
    service_Required: string | null;
};

interface IDriverAssignmentClone {
    open: boolean;
    onClose: () => void;
    serviceTrucks: ById<IServiceTruck>;
    driverAssignment: IAssignmentSite[];
    selection: IDriverAssignmentSelection;
    onComplete: (idxList: number[]) => void;
};

export const DriverAssignmentClone: React.FC<IDriverAssignmentClone> = React.memo((props: IDriverAssignmentClone) => {
    const view = props.selection === "tomorrow" ? "today" : "yesterday";

    const [cloneAll, setCloneAll] = useState<boolean>(true);
    const [list, setList] = useState<IAssignmentSiteClone[]>([]);

    useEffect(() => {
        const data: IAssignmentSiteClone[] = [];
        props.driverAssignment.forEach((item, idx) => {
            const yesterday = isYesterday(view, item.date);
            if (item.serviceTruck !== undefined) {
                if ((yesterday) || ((view === "today") && (new Date(item.date).toLocaleDateString('en-US') === getTodayAndTomorrow().today))) {

                    const st = item.serviceTruck;
                    const drivers = (st && st.service_Trucks.length > 0) ? st.service_Trucks.map(({ driver }) => driver).join(", ") : "";
                    const swampers = (st && st.service_Trucks.length > 0) ? st.service_Trucks.map(({ swamper }) => swamper).join(",") : "";
                    const units = (st && st.service_Trucks.length > 0) ? st.service_Trucks.map(({ unit_No }) => unit_No).join(", ") : "";
                    data.push({
                        idx,
                        cloned: true,
                        unitNo: units,
                        site: item.site,
                        drivers: drivers,
                        id: item.site_ID,
                        swampers: swampers,
                        notes: item.serviceTruck ? item.serviceTruck.notes : "",
                        status: item.serviceTruck ? item.serviceTruck.status : "",
                        service_Required: item.service_Required
                    });
                };
            };
        });
        setList(data);
    }, [props.driverAssignment, view]);

    const onSelectAll = (): void => {
        if (cloneAll) {
            const updatedList = list.map(item => ({
                ...item,
                cloned: false
            }));
            setList(updatedList);
        } else {
            const updatedList = list.map(item => ({
                ...item,
                cloned: true
            }));
            setList(updatedList);
        }
        setCloneAll(!cloneAll);
    };

    const onChange = (updatedData: { data: any, key: number, type: string }[]): void => {
        const values = list;
        updatedData.forEach((x) => {
            values[x.key].cloned = x.data.cloned;
        });
        setList(values);
    };

    const onComplete = (): void => {
        const idxList = list.filter(item => item.cloned === true).map(item => item.idx);
        props.onComplete(idxList);
    };

    return <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <DragDropContext onDragStart={undefinedFunction} onDragEnd={undefinedFunction}>
            <LAPaperWithLessPadding>
                <LAGrid className="text-center">

                    <LAGridItem xs={12}>
                        <strong className="green-text">CLONE</strong>
                        <br />

                        <LACheckBox
                            label="Select All"
                            value={cloneAll}
                            onChange={onSelectAll}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LASaveAndCancelButton
                            onSave={onComplete}
                            saveButtonText="CLONE Selected Items"
                            cancelButtonText="CLOSE"
                            onCancel={props.onClose}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <DataGrid
                            keyExpr="idx"
                            dataSource={list}
                            showBorders={true}
                            columnAutoWidth={true}
                        >
                            <Paging enabled={false} />
                            <Editing
                                mode="batch"
                                allowUpdating={true}
                                onChangesChange={onChange}
                            />
                            <Column dataField="cloned" caption="Clone" dataType="boolean" />
                            <Column dataField="site" caption="Site" dataType="string" allowEditing={false} />
                            <Column dataField="status" caption="Status" dataType="string" allowEditing={false} />
                            <Column dataField="service_Required" caption="Service Required" dataType="string" allowEditing={false} />
                            <Column dataField="drivers" caption="Drivers" dataType="string" allowEditing={false} />
                            <Column dataField="swampers" caption="Swampers" dataType="string" allowEditing={false} />
                            <Column dataField="unitNo" caption="Unit #" dataType="string" allowEditing={false} />
                            <Column dataField="notes" caption="Notes" dataType="string" allowEditing={false} />
                        </DataGrid>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LASaveAndCancelButton
                            onSave={onComplete}
                            saveButtonText="CLONE Selected Items"
                            cancelButtonText="CLOSE"
                            onCancel={props.onClose}
                        />
                    </LAGridItem>

                </LAGrid>
            </LAPaperWithLessPadding>
        </DragDropContext>
    </LAPopover>
});