import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";
import { IRepairLinePicture } from "../../repairLine/getRepairLines/getRepairLinesConstants";

export enum IGET_FILTER_CUTS_REQUEST {
    REQUEST = "getFilterCuts/GET_FILTER_CUTS_REQUEST",
    SUCCESS = "getFilterCuts/GET_FILTER_CUTS_SUCCESS",
    FAILED = "getFilterCuts/GET_FILTER_CUTS_FAILED"
};

export interface IGetFilterCutRequest extends ISurewayTokenRequestBody {
    request : {
        view : string;
    }
};

export interface IFilterCut {
    id: number;
    date_Cut: string;
    unit_ID?: number;
    hours?: number | null;
    km?: number;
    filter: string;
    circuit?: string;
    results: string;
    comments: string;
    manager_Status?: string;
    images: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    pictures: IRepairLinePicture[];
};

