import React, { LegacyRef, PureComponent, ReactNode, useEffect, useRef, useState } from "react";
import { END_POINTS } from "../../redux/endpoints";
import { webConfig } from "../../utils/webConfig";
import { LACheckBox } from "./checkBox";
import LAGrid from "./grid";
import LAGridItem from "./gridList";
import LALoading from "./loading";
import { LAPopover } from "./popOver";
import { LocationPointerIcon } from "./icons";
import styled from "styled-components";
import queryString from "query-string";
import { IToken, ITokenRequest } from "../../redux/getToken/getTokenConstants";
import { Server, hasPayload, isNotLoaded } from "../../redux/server";
import { LAGreenPaper, LAGreyPaper } from "./paper";
import { SurewayAPIResponse } from "./publicInterfaces";
import { BLUE_COLOR, DARK_GREY_COLOR, DARK_RED_COLOR, GREEN_COLOR, LIGHT_BLUE_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR } from "./theme";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { IStore, IDispatch } from "../../redux/reducers";
import { getToken } from "../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../redux/getToken/getTokenActions";
import { getDate, getTokenFromUrl, userName } from "./constExports";
import GoogleMapReact from "google-map-react";
import LAErrorBox from "./errorBox";



interface IGPSRecordComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
};

interface IGPSRecordComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
};

interface IGPSRecordOwnProps {

};

interface IGPSRecordComponentState {

};

const GPSRecordStyles = styled(LAGreyPaper)`
    margin: 20px 40px;
    text-align: center;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IGPSRecordComponentProps =
    RouteComponentProps
    & IGPSRecordOwnProps
    & IGPSRecordComponentStoreProps
    & IGPSRecordComponentDispatchProps;

class GPSRecord extends PureComponent<IGPSRecordComponentProps, IGPSRecordComponentState> {

    public constructor(props: IGPSRecordComponentProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IGPSRecordComponentProps): void {
        this.callServer();
    };


    public render(): ReactNode {

        const { token } = this.props;
        const query: any = queryString.parse(this.props.location.search);

        return (
            <GPSRecordStyles>
                {(query.id && query.page) ?
                    <GPSRecordView
                        token={token}
                        page={query.page}
                        id={Number(query.id)}
                    /> :
                    <LAErrorBox text="No GPS Records Found" />
                }
            </GPSRecordStyles>
        );
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.token))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });
    };

}

const mapStateToProps = (state: IStore): IGPSRecordComponentStoreProps => ({
    token: getToken(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IGPSRecordComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(GPSRecord);


export type GPSPageType = "ET" | "ST";

interface IGPSRecordViewProps {
    id: number;
    page: GPSPageType;
    token: Server<SurewayAPIResponse<IToken>>;
};

interface IGPSRecord {
    title: string | null;
    driver_Name: string;
    to_Location: string;
    from_Location: string;
    gpS_Records: IGPS_RECORD_API[];
};

interface IGPS_RECORD_API {
    id: number;
    timestamp: string;
    latitude: string;
    longitude: string;
    status: string;
}

export const defaultMapProps = {
    center: {
        lat: 54.6287182,
        lng: -115.6812021
    },
    zoom: 6
};

const GPSRecordViewStyles = styled.div`
    .title {
        font-weight: bold;
        background-color: ${DARK_GREY_COLOR};
    };

    .hand-cursor {
        cursor: pointer;
    };

    .popup-div {
        width: 20px;
        margin: auto;
    };
`;


export const GPSRecordView: React.FC<IGPSRecordViewProps> = React.memo((props: IGPSRecordViewProps) => {
    const map: LegacyRef<any> = useRef(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [satView, setSatView] = useState<boolean>(false);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(statusList.map(item => item.status));
    const [data, setData] = useState<IGPSRecord>({ title: "", driver_Name: "", to_Location: "", from_Location: "", gpS_Records: [] });

    const handleCheckboxChange = (status: string) => {
        if (selectedStatuses.includes(status)) {
            setSelectedStatuses(selectedStatuses.filter(item => item !== status));
          } else {
            setSelectedStatuses([...selectedStatuses, status]);
          }
    };

    const callEndpoint = (): void => {
        if (hasPayload(props.token)) {
            fetch(END_POINTS.FIELD.GPS_RECORD, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {
                        ID: props.id,
                        Page: props.page
                    },
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response !== null) {
                        setData({
                            ...res.response,
                            gpS_Records: res.response.gpS_Records === null ? [] : res.response.gpS_Records
                        });
                    } else {
                        setData({ title: null, driver_Name: "", to_Location: "", from_Location: "", gpS_Records: [] });
                    };
                    setLoading(false);
                })
                .catch((res) => console.log(res));
        }
    };

    useEffect(() => {
        callEndpoint();
    }, []);


    return <GPSRecordViewStyles>
        <LAGrid spacing={0}>

            {((data.title === null) && (data.gpS_Records.length === 0)) ?
                <LAGridItem xs={12}>
                    <h3>GPS RECORDS</h3>
                    <LAErrorBox text="No GPS Records Found" />
                </LAGridItem>
                :
                <LAGridItem xs={12}>
                    <RenderStatusListValue
                        page={props.page}
                        selected={selectedStatuses}
                        onCheckBox={handleCheckboxChange}
                    />
                    <h3 className="title">{data.title}</h3>
                    <hr />

                    <strong>
                        DRIVER:
                    </strong>
                    <span className="pl-1 pr-2">{data.driver_Name}</span>

                    <strong>
                        FROM:
                    </strong>
                    <span className="pl-1 pr-2">{data.from_Location}</span>

                    <strong>
                        TO:
                    </strong>
                    <span className="pl-1">{data.to_Location}</span>

                    <LACheckBox
                        className="ml-3"
                        value={satView}
                        onChange={() => setSatView(!satView)}
                        label={satView ? "Terrain View" : "Satellite View"}
                    />
                </LAGridItem>}

            {(loading === true) && <LAGridItem xs={12}>
                <LALoading message="Loading GPS Records..." />
            </LAGridItem>}

            {(loading === false) && <>
                <div style={{ height: (window.screen.height - 300), width: (window.screen.width - 150) }} >
                    <GoogleMapReact
                        ref={map}
                        bootstrapURLKeys={{ key: webConfig.googleMapKey }}
                        defaultCenter={(data.gpS_Records.length === 0) ? defaultMapProps.center : { lat: +data.gpS_Records[data.gpS_Records.length - 1].latitude, lng: +data.gpS_Records[data.gpS_Records.length - 1].longitude }}
                        defaultZoom={(data.gpS_Records.length === 0) ? defaultMapProps.zoom : 11}
                        options={map => ({
                            scrollwheel: true,
                            mapTypeId: satView ? map.MapTypeId.HYBRID : map.MapTypeId.TERRAIN
                        })}
                    >
                        {data.gpS_Records
                            .sort(compareTimestamps)
                            .filter((q) => selectedStatuses.includes(q.status))
                            .map((x, idx) => (
                                <LocationPin
                                    data={x}
                                    idx={idx+1}
                                    lat={x.latitude}
                                    lng={x.longitude}
                                />
                            ))}
                    </GoogleMapReact>
                </div>
            </>}

        </LAGrid>
    </GPSRecordViewStyles>
});

const compareTimestamps = (a: IGPS_RECORD_API, b: IGPS_RECORD_API) => {
    const timestampA = Date.parse(a.timestamp);
    const timestampB = Date.parse(b.timestamp);
    return timestampA - timestampB;
  };

interface ILocationPin {
    lat: any;
    lng: any;
    idx: number;
    data: IGPS_RECORD_API;
}

const LocationPin: React.FC<ILocationPin> = (props: ILocationPin) => {
    const onClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>();

    return <>
        <div ref={anchorEl} className="hand-cursor" onClick={onClick} style={{
            width: "50px",
            height: "50px",
            position: "relative",
            top: "-30px",
            left: "-27px",
        }}>
            <LocationPointerIcon text={props.idx.toString()} width={40} height={40} color={getColorStatus(props.data)} />
        </div>

        <LAPopover open={open} onClose={() => setOpen(false)} anchorRef={anchorEl} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <LAGreenPaper>
                <br />
                <br />
                <strong>DATE: </strong>
                <span>{getDate(true, props.data.timestamp)} | </span>

                <strong>STATUS: </strong>
                <span>{props.data.status}</span>
            </LAGreenPaper>
        </LAPopover>
    </>
};

interface IRenderStatusListValue {
    page: GPSPageType;
    selected: string[];
    onCheckBox: (status: string) => void;
}

const RenderStatusListValue: React.FC<IRenderStatusListValue> = (props: IRenderStatusListValue): JSX.Element => {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h2 style={{ textAlign: "center" }}>GPS RECORDS</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            {statusList
            .filter((x) => x.pages.includes(props.page))
            .map(item => (
              <div key={item.status} style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                <input
                  type="checkbox"
                  id={item.status}
                  checked={props.selected.includes(item.status)}
                  onChange={() => props.onCheckBox(item.status)}
                  style={{ marginRight: "5px" }}
                />
                <label htmlFor={item.status} style={{ marginRight: "5px" }}>
                  <div style={{ width: "10px", height: "10px", backgroundColor: item.color }}></div>
                </label>
                <span>{item.status}</span>
              </div>
            ))}
          </div>
        </div>
      );
}

const statusList: { status: string, color: string, pages: GPSPageType[] }[] = [
{ status: "Dispatched", color: GREEN_COLOR, pages: ["ET", "ST"] }, { status: "En Route", color: ORANGE_COLOR, pages: ["ET", "ST"] }, 
{ status: "Driving to pick-up site", color: BLUE_COLOR, pages: ["ET"] }, { status: "Moved", color: LIGHT_BLUE_COLOR, pages: ["ET"] },
{ status: "Completed", color: BLUE_COLOR, pages: ["ST"] }, { status: "At Site", color: LIGHT_BLUE_COLOR, pages: ["ST"] }
];

const getColorStatus = (data: IGPS_RECORD_API): string => {
    const idx = statusList.findIndex((x) => x.status === data.status);

    if(idx >= 0){
        return statusList[idx].color;
    } else {
        return DARK_RED_COLOR;
    };
};