import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MEDICAL_FACILITIES_REQUEST, IGetMedicalFacilities } from "./getMedicalFacilitiesConstants";
import { IGetMedicalFacilitiesLoadAction, IGetMedicalFacilitiesLoadFailedAction, IGetMedicalFacilitiesSuccessAction, 
    getMedicalFacilitiesLoadFailedAction, getMedicalFacilitiesLoadSuccessAction } from "./getMedicalFacilitiesActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getMedicalFacilitiesEpic: Epic = (
    action$: ActionsObservable<IGetMedicalFacilitiesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMedicalFacilitiesSuccessAction | IGetMedicalFacilitiesLoadFailedAction> =>
    action$.ofType(IGET_MEDICAL_FACILITIES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetMedicalFacilities>>>(
                    END_POINTS.FIELD.GET_MEDICAL_FACILITIES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetMedicalFacilities>>): IGetMedicalFacilitiesSuccessAction => {
                            return getMedicalFacilitiesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getMedicalFacilitiesLoadFailedAction(response.message)))
                    )
            )
        );