
export enum IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST {
    REQUEST = "getMechanicTimecardVersionHistory/GET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST",
    SUCCESS = "getMechanicTimecardVersionHistory/GET_MECHANIC_TIMECARD_VERSION_HISTORY_SUCCESS",
    FAILED = "getMechanicTimecardVersionHistory/GET_MECHANIC_TIMECARD_VERSION_HISTORY_FAILED"
};

export interface IGetMechanicTimecardVersionHistoryRequest {
    request: {
        ID: number;
        Type: string;
    };
    token: string;
};

export interface IGetMechanicTimecardVersionHistory {
    id: number;
    operation: number;
    itemID: number;
    date: string;
    timesheet_ID: number;
    defect_ID: number;
    hours: number;
    comments: string;
    status: string;
    repair_Location: string;
    field_Log: string;
    uploaded_Date: string;
    parts_Delay: string;
    parts_Delay_Hours: number;
    parts_Delay_Comments: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    display: string;
    unit_ID: number;
    attachment_ID: number;
    defect_Item: string;
    issue: string;
    component_Code: string;
    work_Order_No: string;
    job_No: string;
    cost_Code: string;
    item_Repair_Location: string;
    manager_Instructions: "";
    defect_Status: string;
    damaged: string;
    warranty: string;
    major_Component_Replacement: string;
    repair_ID: number;
    mechanic_Name: string;
    email: string;
    mechanic_ID: number;
    start_Date: string;
    end_Date: string;
    start_Time: string;
    end_Time: string;
    name: string;
    truck: string;
    employee_Code: string;
    item_Attachment_ID: number;
    meter_Reading: number;
    serial_No: string;
    major_Component_Replacement_Type: string;
    major_Component_Replacement_Hours: string;
    major_Component_Replacement_Notes: string;
    rejection_Notes: string;
    modified_After_Upload: string;
    payroll_Acknowledged: string;
  };