import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_OPERATOR_LOGS_REQUEST, IOperatorLogs } from "./getOperatorLogsConstants";
import { IGetOperatorLogsLoadAction, IGetOperatorLogsLoadFailedAction, IGetOperatorLogsSuccessAction } from "./getOperatorLogsActions";
import { IAddOperatorLogSuccessAction } from "../addOperatorLog/addOperatorLogActions";
import { IUpdateOperatorLogSuccessAction } from "../updateOperatorLog/updateOperatorLogActions";
import { IUPDATE_OPERATOR_LOG_REQUEST } from "../updateOperatorLog/updateOperatorLogConstants";
import { IADD_OPERATOR_LOG_REQUEST } from "../addOperatorLog/addOperatorLogConstants";
import { EXTERNAL_USER_LOGOUT } from "../../../external/externalUserLogOut/externalUserLogOutConstants";
import { IExternalUserLogOutSuccessAction } from "../../../external/externalUserLogOut/externalUserLogOutActions";

type Actions =
    | IGetOperatorLogsLoadAction
    | IGetOperatorLogsSuccessAction
    | IGetOperatorLogsLoadFailedAction
    | IAddOperatorLogSuccessAction
    | IUpdateOperatorLogSuccessAction
    | IExternalUserLogOutSuccessAction
    | IFlushDataSuccessAction;

export const GetOperatorLogsReducer = (state: Server<SurewayAPIResponse<ById<IOperatorLogs>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IOperatorLogs>>> => {
    switch (action.type) {
        case IGET_OPERATOR_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_OPERATOR_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_OPERATOR_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_OPERATOR_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_OPERATOR_LOG_REQUEST.SUCCESS:
            return notLoaded;

            case EXTERNAL_USER_LOGOUT.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};