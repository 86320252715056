import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../shared/paper";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../redux/server";
import { LADevExtremeGrid } from "../shared/devExtreme";
import { LAButton } from "../shared/buttons";
import { AddIcon } from "../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../shared/theme";
import { ROUTE } from "../routes";
import PageSpacing from "../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../shared/constExports";
import { getToken } from "../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../redux/getToken/getTokenActions";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import LALoading from "../shared/loading";
import { NotApplicable } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IExternalUser, IGetExternalUsersRequest } from "../../redux/externalUser/getExternalUsers/getExternalUsersConstants";
import { getExternalUsers } from "../../redux/externalUser/getExternalUsers/getExternalUsersAccessor";
import { getExternalUsersLoadAction } from "../../redux/externalUser/getExternalUsers/getExternalUsersActions";
import LAErrorBox from "../shared/errorBox";

interface IExternalUsersStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getExternalUser: Server<SurewayAPIResponse<ById<IExternalUser>>>;
};

interface IExternalUsersDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getExternalUsersRequest: (data: IGetExternalUsersRequest) => unknown;
};


interface IExternalUsersOwnProps {

};

interface IExternalUsersState {
    activeView: boolean;
    activeExternalUsers: IExternalUser[];
};

const ExternalUsersStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IExternalUsersProps = RouteComponentProps
    & IExternalUsersStoreProps
    & IExternalUsersDispatchProps
    & IExternalUsersOwnProps;

class ExternalUsers extends PureComponent<IExternalUsersProps, IExternalUsersState> {

    public constructor(props: IExternalUsersProps) {
        super(props);
        this.state = {
            activeView: true,
            activeExternalUsers: []
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IExternalUsersProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getExternalUser, getToken } = this.props;
        const { activeView, activeExternalUsers } = this.state;
        const getRole = pageAccessCheck(getToken, "externalUsers");
        const data = activeView ? activeExternalUsers : (hasPayload(getExternalUser) && getExternalUser.payload.response && Object.values(getExternalUser.payload.response).length > 0) ? Object.values(getExternalUser.payload.response) : [];

        return (
            <PageSpacing title="ExternalUsers" description="FIELD - EXTERNAL USERS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <ExternalUsersStyles>
                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">EXTERNAL USERS</h2>
                                    <hr />

                                    {getExternalUser.kind === STATUS_ENUM.LOADING && <LALoading message="Loading External Users..." />}
                                    {getExternalUser.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load External Users..." />}

                                    {getExternalUser.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>

                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                    <FormControlLabel value="active" control={<Radio />} label="Active External Users" />
                                                    <FormControlLabel value="all" control={<Radio />} label="All External Users" />
                                                </RadioGroup>
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    id="External-UsersList"
                                                    customRowColor={true}
                                                    exportFileName="External-Users"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "firstname", caption: "First Name", type: "string" },
                                                        { name: "lastname", caption: "Last Name", type: "string" },
                                                        { name: "email", caption: "Email", type: "string" },
                                                        { name: "phone", caption: "Phone", type: "string" },
                                                        { name: "role", caption: "Role", type: "string" },
                                                        { name: "company", caption: "Company", type: "string" },
                                                        { name: "status", caption: "Status", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "created_By", caption: "created By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    </ExternalUsersStyles>}
            </PageSpacing>
        );
    }


    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });

        let activeView = !this.state.activeView;
        const getStorageValue = localStorage.getItem("key_external_users");
        if (getStorageValue) {
            if (activeView) {
                localStorage.setItem("key_external_users", "active");
            }
            else {
                localStorage.setItem("key_external_users", "all");
            }
        }
        else {
            localStorage.setItem("key_external_users", "active");
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.EXTERNAL_USER.EXTERNAL_USER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.EXTERNAL_USER.EXTERNAL_USER
        });
    };

    private checkViewSelections = (): void => {
        let activeView = this.state.activeView;
        const getStorageValue = localStorage.getItem("key_external_users");
        if (getStorageValue) {
            if (getStorageValue === "active") {
                activeView = true;
            }
            else {
                activeView = false;
            }
        }
        else {
            localStorage.setItem("key_external_users", "active");
        }
        this.setState({ activeView: activeView });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "externalUsers") !== NotApplicable) {
                if (isNotLoaded(this.props.getExternalUser)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.INDEX,
                    search: getTokenFromUrl(false)
                });
            }

            if (hasPayload(this.props.getExternalUser)) {
                const res = this.props.getExternalUser.payload.response;
                const activeExternalUsers = (res && Object.values(res).length > 0) ? Object.values(this.props.getExternalUser.payload.response).filter(x => x.status === "Active") : []
                this.setState({ activeExternalUsers });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "externalUsers") !== NotApplicable) {
            this.props.getExternalUsersRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IExternalUsersStoreProps => ({
    getToken: getToken(state),
    getExternalUser: getExternalUsers(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalUsersDispatchProps => ({
    getExternalUsersRequest: (data: IGetExternalUsersRequest) => dispatch(getExternalUsersLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUsers);