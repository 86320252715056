import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_TECH_SERVICE_REQUEST, IUpdateTechServiceRequest } from "./updateTechServiceConstants";


export interface IUpdateTechServiceLoadAction {
    type: IUPDATE_TECH_SERVICE_REQUEST.REQUEST;
    data: IUpdateTechServiceRequest
}
export const updateTechServiceLoadAction = (data: IUpdateTechServiceRequest): IUpdateTechServiceLoadAction => ({
    type: IUPDATE_TECH_SERVICE_REQUEST.REQUEST,
    data
});
export interface IUpdateTechServiceSuccessAction {
    type: IUPDATE_TECH_SERVICE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateTechServiceLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateTechServiceSuccessAction => ({
    type: IUPDATE_TECH_SERVICE_REQUEST.SUCCESS,
    message
});
export interface IUpdateTechServiceLoadFailedAction {
    type: IUPDATE_TECH_SERVICE_REQUEST.FAILED;
    message: string;
}
export const updateTechServiceLoadFailedAction = (message: string): IUpdateTechServiceLoadFailedAction => ({
    type: IUPDATE_TECH_SERVICE_REQUEST.FAILED,
    message
});
