
export enum IGET_BULK_METER_READINGS_REQUEST {
    REQUEST = "getBulkMeterReadings/GET_BULK_METER_READINGS_REQUEST",
    SUCCESS = "getBulkMeterReadings/GET_BULK_METER_READINGS_SUCCESS",
    FAILED = "getBulkMeterReadings/GET_BULK_METER_READINGS_FAILED"
};

export interface IBulkMeterReadingsRequest {
    token: string;
};


export interface IBulkMeterReading {
  row_number: number;
  id: number;
  date: string;
  unit_Number: string;
  current_Hours: number;
  location: string;
  sub_Location?: string;
  sub_Location_ID?: number;
  update_Location_Check: string;
  new_Location?: string;
  new_Sub_Location?: string;
  new_Sub_Location_ID?: number;
  ecm?: number;
  cancelled?: string;
  comments?: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  meter_Replacement: string;
  socT_ID?: number;
  service_Truck_ID?: number;
  rw: number;
  source_ID?: number;
  source: string;
};
