import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isFailed, isNotLoaded, isSucceeded, Server } from "../../../redux/server";
import { ById, SurewayAPIResponse, ToolRentalsResponse } from "../../shared/publicInterfaces";
import { YesOrNo, getTokenFromUrl,  undefinedFunction, userName } from "../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import { WHITE_COLOR } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { ROUTE } from "../../routes";
import queryString from "query-string";
import RequestStatus from "../../shared/requestStatusSnackbar";
import PageSpacing from "../../shared/pageSpacing";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LATextField from "../../shared/textField";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../shared/roleBaseAccessForShop";
import LAErrorBox from "../../shared/errorBox";
import { addEquipmentTypeStatus } from "../../../redux/field/addEquipmentType/addEquipmentTypeAccessor";
import { updateEquipmentTypeStatus } from "../../../redux/field/updateEquipmentType/updateEquipmentTypeAccessor";
import { IAddEquipmentTypeRequest } from "../../../redux/field/addEquipmentType/addEquipmentTypeConstants";
import { addEquipmentTypeLoadAction } from "../../../redux/field/addEquipmentType/addEquipmentTypeActions";
import { updateEquipmentTypeLoadAction } from "../../../redux/field/updateEquipmentType/updateEquipmentTypeActions";
import { IUpdateEquipmentTypeRequest } from "../../../redux/field/updateEquipmentType/updateEquipmentTypeConstants";
import { IShopEquipmentRequest } from "../../../redux/field/getEquipments/getEquipmentConstants";
import { IGetEquipmentType, IGetEquipmentTypeRequest } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesConstants";
import { getEquipmentTypesLoadAction } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesActions";
import { getEquipmentTypes } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesAccessor";
import LAAutoComplete from "../../shared/autoComplete";

interface IEquipmentTypeStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    addEquipmentType: Server<SurewayAPIResponse<string>>;
    updateEquipmentType: Server<SurewayAPIResponse<string>>;
    getEquipmentTypes: Server<SurewayAPIResponse<ById<IGetEquipmentType>>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IEquipmentTypeDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    addEquipmentTypeRequest: (data: IAddEquipmentTypeRequest) => unknown;
    getEquipmentTypesRequest: (data: IShopEquipmentRequest) => unknown;
    updateEquipmentTypeRequest: (data: IUpdateEquipmentTypeRequest) => unknown;
};

interface IEquipmentTypeOwnProps {

};

interface IEquipmentTypeState {
    sError: string;
    type: IGetEquipmentType;
    errors: ById<IFieldErrorKeyValue>;
};

const EquipmentTypeStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IEquipmentTypeProps = RouteComponentProps
    & IEquipmentTypeStoreProps
    & IEquipmentTypeDispatchProps
    & IEquipmentTypeOwnProps;

class EquipmentType extends PureComponent<IEquipmentTypeProps, IEquipmentTypeState> {

    public constructor(props: IEquipmentTypeProps) {
        super(props);
        this.state = {
            errors: {
                "name": { key: "name", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            type: {
                id: 0,
                created: "",
                modified: "",
                created_By: "",
                modified_By: "",
                name: "",
                active: "Yes"
            },
            sError: ""
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IEquipmentTypeProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addEquipmentType !== prevProps.addEquipmentType) {
                if (isSucceeded(this.props.addEquipmentType)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.addEquipmentType)) {
                    this.setState({ sError: this.props.addEquipmentType.message as string });
                };
            };

            if (this.props.updateEquipmentType !== prevProps.updateEquipmentType) {
                if (isSucceeded(this.props.updateEquipmentType)) {
                    this.handleCancel();
                };
                if (isFailed(this.props.updateEquipmentType)) {
                    this.setState({ sError: this.props.updateEquipmentType.message as string });
                };
            };
        }
    };


    public render(): ReactNode {

        const { type, errors, sError } = this.state;
        const { updateEquipmentType, addEquipmentType, validateShopGroup } = this.props;
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;
        const onActiveChange = (e: unknown, value: { id: number, name: string }) => this.onChange("active", (value !== null) ? value.name : "");

        return (
            <PageSpacing title="Equipment List - Equipment Type" description="Equipment List - Equipment Type" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                    <EquipmentTypeStyles>

                        <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                        <h2 className="text-center">{type.id > 0 ? "VIEW/UPDATE " : "ADD "} EQUIPMENT TYPE</h2>
                        <hr />

                        <LAPaperWithPadding>
                            <LAGrid spacing={2}>

                                <LAGridItem xs={6}>
                                    <LATextField
                                        variant="outlined"
                                        label="Name"
                                        fullWidth={true}
                                        name="name"
                                        onChange={this.onChange}
                                        disabled={readOnly}
                                        value={type.name}
                                        errorText={errors["name"] ? errors["name"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={6}>
                                    <LAAutoComplete
                                        dropDownPlaceHolder="Active"
                                        multiple={false}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        autoHighlight={true}
                                        onChange={onActiveChange}
                                        selectionRemove={undefinedFunction}
                                        option={YesOrNo}
                                        value={YesOrNo.find(x => x.name === type.active)}
                                        defaultValue={YesOrNo.find(x => x.name === type.active)}
                                    />
                                </LAGridItem>

                                {sError.length > 0 && <LAGridItem xs={12}>
                                    <LAErrorBox text={sError} />
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LASaveAndCancelButton
                                        onSave={this.onSave}
                                        onCancel={this.handleCancel}
                                        disableSave={Object.values(errors).length > 0 ? true : readOnly}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>

                        <RequestStatus requestStatus={addEquipmentType.kind} successMessage="Equipment Type successfully saved" />
                        <RequestStatus requestStatus={updateEquipmentType.kind} successMessage="Equipment Type successfully updated" />
                    </EquipmentTypeStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        
        this.props.history.push({
            pathname: ROUTE.FIELD.EQUIPMENT_TYPE.INDEX,
            search: getTokenFromUrl(false)
          });
    };

    private onSave = (): void => {
        const data = this.state.type;
        if (hasPayload(this.props.getToken))
            if (this.state.type.id === 0) {
                this.props.addEquipmentTypeRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Modified_By: this.props.getToken.payload.response.upn,
                        Created_By: this.props.getToken.payload.response.upn,
                        Active: data.active,
                        Name: data.name
                    }
                });
            } else {
                this.props.updateEquipmentTypeRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: data.id,
                        Created_By: data.created_By,
                        Modified_By: this.props.getToken.payload.response.upn,
                        Name: data.name,
                        Active: data.active
                    }
                });
            };

        this.setState({ sError: "" });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            type: {
                ...this.state.type,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipmentTypes) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getEquipmentTypesRequest({
                token: this.props.getToken.payload.response.token
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getEquipmentTypes)) && (this.state.type.id === 0)) {
            const type = this.props.getEquipmentTypes.payload.response[Number(id)];

            if (type)
                this.setState({ type, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IEquipmentTypeStoreProps => ({
    getToken: getToken(state),
    getEquipmentTypes: getEquipmentTypes(state),
    validateShopGroup: validateShopGroup(state),
    addEquipmentType: addEquipmentTypeStatus(state),
    updateEquipmentType: updateEquipmentTypeStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IEquipmentTypeDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    addEquipmentTypeRequest: (data: IAddEquipmentTypeRequest) => dispatch(addEquipmentTypeLoadAction(data)),
    getEquipmentTypesRequest: (data: IGetEquipmentTypeRequest) => dispatch(getEquipmentTypesLoadAction(data)),
    updateEquipmentTypeRequest: (data: IUpdateEquipmentTypeRequest) => dispatch(updateEquipmentTypeLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentType);