import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { ISAVE_DRIVER_ASSIGNMENT_REQUEST, ISaveDriverAssignmentRequest } from "./saveDriverAssignmentConstants";


export interface ISaveDriverAssignmentLoadAction {
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.REQUEST;
    data: ISaveDriverAssignmentRequest
}
export const saveDriverAssignmentLoadAction = (data: ISaveDriverAssignmentRequest): ISaveDriverAssignmentLoadAction => ({
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.REQUEST,
    data
});
export interface ISaveDriverAssignmentSuccessAction {
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveDriverAssignmentLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveDriverAssignmentSuccessAction => ({
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.SUCCESS,
    message
});
export interface ISaveDriverAssignmentLoadFailedAction {
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.FAILED;
    message: string;
}
export const saveDriverAssignmentLoadFailedAction = (message: string): ISaveDriverAssignmentLoadFailedAction => ({
    type: ISAVE_DRIVER_ASSIGNMENT_REQUEST.FAILED,
    message
});
