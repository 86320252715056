import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { IDispatch, IStore } from "../redux/reducers";
import { ROUTE } from "./routes";
import { getTokenFromUrl } from "./shared/constExports";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { LAPaperWithPadding } from "./shared/paper";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE } from "./shared/theme";
import { AttachmentIcon, BaseKitsIcon, BulldozerIcon, LevelsIcon, RepeaterIcon, RoverKitsIcon, } from "./shared/icons";


interface ISurveyHomeStoreProps {
};

interface ISurveyHomeDispatchProps {
};

interface ISurveyHomeState {
    showHeader: boolean;
    showFooter: boolean;
};

const SurveyHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
        
    };

    .SurveyHomeMenu {
        color: #000;
        height: 90px;
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        border: 2px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        margin-right: 10%;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10%;
        text-shadow: 0.5px 1px #719de3;
    };

    .ITtitle {       
        color: #8289dd;
    };

    .TileSpacing{
        padding: 20px;
    }

    .FilterSytle{
        padding-top: 20px !important;
    }

    .HRtitle {
        color: #5d95f0;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;

        .title {
            font-size: 30px;
          }
      
          .appTitle {
            font-size: 16px;
          }
    };
`;

type ISurveyHomeProps =
    RouteComponentProps &
    ISurveyHomeDispatchProps &
    ISurveyHomeStoreProps;

class SurveyHome extends Component<ISurveyHomeProps, ISurveyHomeState> {
    public constructor(props: ISurveyHomeProps) {
        super(props);
        this.state = {
            showHeader: true,
            showFooter: true
        };
    }

    public render(): ReactNode {
        return (
            <SurveyHomeStyles>
                <LAGrid spacing={2} className="text-center">
                    <LAGridItem xs={12}>
                        <h2 className="title">Survey</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        {/* <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="FIELD" expanded={true} onClick={undefinedFunction}> */}
                            <LAGrid className="TileSpacing">

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onMachineControl}>
                                        <BulldozerIcon width={50} height={50} /><br />
                                        <span className="appTitle HRtitle">Machine Control</span>
                                    </div>
                                </LAGridItem>

                                {/* <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onAttachment}>
                                        <AttachmentIcon width={50} height={50} /><br />
                                        <span className="appTitle HRtitle">Attachments</span>
                                    </div>
                                </LAGridItem> */}

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onBaseKit}>
                                        <BaseKitsIcon width={50} height={50} color="#c48816"/><br />
                                        <span className="appTitle HRtitle">Base Kits</span>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onRoverKit}>
                                        <RoverKitsIcon width={50} height={50} color="#c48816"/><br />
                                        <span className="appTitle HRtitle">Rover Kits</span>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onRepeater}>
                                        <RepeaterIcon width={50} height={50} color="#c48816"/><br />
                                        <span className="appTitle HRtitle">Repeater Kits</span>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onLaser}>
                                        <LevelsIcon width={50} height={50} color="#c48816" /><br />
                                        <span className="appTitle HRtitle">Lasers</span>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="SurveyHomeMenu" onClick={this.onLevel}>
                                        <LevelsIcon width={50} height={50} color="#c48816" /><br />
                                        <span className="appTitle HRtitle">Levels</span>
                                    </div>
                                </LAGridItem>
                                
                            </LAGrid>
                        {/* </LAExpansionPanel> */}
                    </LAGridItem>
                </LAGrid>
            </SurveyHomeStyles>
        );

    }

    private onMachineControl = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onAttachment = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.ATTACHMENTS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onBaseKit = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.BASE_KITS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onRoverKit = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.ROVER_KITS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onRepeater = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.REPEATER_KITS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onLaser = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.LASERS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onLevel = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.LEVELS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

}

const mapStateToProps = (state: IStore): ISurveyHomeStoreProps => ({
});

const mapDispatchToProps = (dispatch: IDispatch): ISurveyHomeDispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(SurveyHome);