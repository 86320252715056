import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_SHEET_REPORT_REQUEST, IServiceSheetReport, IGetServiceSheetReportRequest } from "./getServiceSheetReportConstants";

export interface IGetServiceSheetReportLoadAction {
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.REQUEST;
    data: IGetServiceSheetReportRequest
}
export const getServiceSheetReportLoadAction = (data: IGetServiceSheetReportRequest): IGetServiceSheetReportLoadAction => ({
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.REQUEST,
    data
});
export interface IGetServiceSheetReportSuccessAction {
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IServiceSheetReport>>;
}
export const getServiceSheetReportLoadSuccessAction = (list: SurewayAPIResponse<ById<IServiceSheetReport>>): IGetServiceSheetReportSuccessAction => ({
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.SUCCESS,
    list
});
export interface IGetServiceSheetReportLoadFailedAction {
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.FAILED;
    message: string;
}
export const getServiceSheetReportLoadFailedAction = (message: string): IGetServiceSheetReportLoadFailedAction => ({
    type: IGET_SERVICE_SHEET_REPORT_REQUEST.FAILED,
    message
});
