import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST, IGetUnitInspectionSheetByID, IGetUnitInspectionSheetByIDRequest } from "./getUnitInspectionSheetByIDConstants";

export interface IGetUnitInspectionSheetByIDLoadAction {
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.REQUEST;
    data: IGetUnitInspectionSheetByIDRequest
}
export const getUnitInspectionSheetByIDLoadAction = (data: IGetUnitInspectionSheetByIDRequest): IGetUnitInspectionSheetByIDLoadAction => ({
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetUnitInspectionSheetByIDSuccessAction {
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetUnitInspectionSheetByID>;
}
export const getUnitInspectionSheetByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetUnitInspectionSheetByID>): IGetUnitInspectionSheetByIDSuccessAction => ({
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetUnitInspectionSheetByIDLoadFailedAction {
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getUnitInspectionSheetByIDLoadFailedAction = (message: string): IGetUnitInspectionSheetByIDLoadFailedAction => ({
    type: IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.FAILED,
    message
});
