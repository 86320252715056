import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_EXTERNAL_USER_REQUEST } from "./updateExternalUserConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IUpdateExternalUserLoadAction, IUpdateExternalUserLoadFailedAction, IUpdateExternalUserSuccessAction, 
    updateExternalUserLoadFailedAction, updateExternalUserLoadSuccessAction } from "./updateExternalUserActions";

export const updateExternalUserEpic: Epic = (
    action$: ActionsObservable<IUpdateExternalUserLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateExternalUserSuccessAction | IUpdateExternalUserLoadFailedAction> =>
    action$.ofType(IUPDATE_EXTERNAL_USER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.EXTERNAL_USER.UPDATE_EXTERNAL_USER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateExternalUserSuccessAction | IUpdateExternalUserLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateExternalUserLoadSuccessAction(response);
                            } else {
                                return updateExternalUserLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateExternalUserLoadFailedAction(response.message)))
                    )
            )
        );