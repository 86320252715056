import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_ET_LOOKUPS_REQUEST, IETLookups } from "./getETLookupsConstants";
import { IGetETLookupsLoadAction, IGetETLookupsLoadFailedAction, IGetETLookupsSuccessAction } from "./getETLookupsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    | IGetETLookupsLoadAction
    | IGetETLookupsSuccessAction
    | IGetETLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetETLookupsReducer = (state: Server<SurewayAPIResponse<IETLookups>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IETLookups>> => {
    switch (action.type) {
        case IGET_ET_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_ET_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ET_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};