import { IGET_DRIVERS_REQUEST, IETDriver, IGetETDriverRequest } from "./getDriversConstants";

export interface IGetDriversLoadAction {
    type: IGET_DRIVERS_REQUEST.REQUEST;
    data: IGetETDriverRequest
}
export const getDriversLoadAction = (data: IGetETDriverRequest): IGetDriversLoadAction => ({
    type: IGET_DRIVERS_REQUEST.REQUEST,
    data
});
export interface IGetDriversSuccessAction {
    type: IGET_DRIVERS_REQUEST.SUCCESS;
    list: IETDriver[];
}
export const getDriversLoadSuccessAction = (list: IETDriver[]): IGetDriversSuccessAction => ({
    type: IGET_DRIVERS_REQUEST.SUCCESS,
    list
});
export interface IGetDriversLoadFailedAction {
    type: IGET_DRIVERS_REQUEST.FAILED;
    message: string;
}
export const getDriversLoadFailedAction = (message: string): IGetDriversLoadFailedAction => ({
    type: IGET_DRIVERS_REQUEST.FAILED,
    message
});
