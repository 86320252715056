
export enum IAPPROVE_SERVICE_SHEET_REQUEST {
    REQUEST = "approveServiceSheet/APPROVE_SERVICE_SHEET_REQUEST",
    SUCCESS = "approveServiceSheet/APPROVE_SERVICE_SHEET_SUCCESS",
    FAILED = "approveServiceSheet/APPROVE_SERVICE_SHEET_FAILED"
};

export interface IApproveServiceSheetRequest {
    token: string;
    request: IAddUpdateServiceDue;
};

export interface IAddUpdateServiceDue {
    ID: number;
    Hours: number;
    Comments: string;
    Approved: boolean;
    Rejected: boolean;
    Modified_By: string;
    Service_Date: string;
    Admin_Comments: string;
    Details?: IDetails[]
}

export interface IDetails {
    ID:number;
    Choice: string;
    Comments?: string;
}