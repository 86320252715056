import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_SERVICE_DUE_REQUEST } from "./addServiceDueConstants";
import { IAddServiceDueLoadAction, IAddServiceDueLoadFailedAction, IAddServiceDueSuccessAction, addServiceDueLoadFailedAction, addServiceDueLoadSuccessAction } from "./addServiceDueActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addServiceDueEpic: Epic = (
    action$: ActionsObservable<IAddServiceDueLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddServiceDueSuccessAction | IAddServiceDueLoadFailedAction> =>
    action$.ofType(IADD_SERVICE_DUE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.PM_SHEETS.ADD_SERVICE_DUE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddServiceDueSuccessAction | IAddServiceDueLoadFailedAction => {
                            if(response.message === "Success"){
                                return addServiceDueLoadSuccessAction(response);
                            } else {
                                return addServiceDueLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addServiceDueLoadFailedAction(response.message)))
                    )
            )
        );