import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "./updateCouplerConstants";
import { IUpdateAttachmentCouplerLoadAction, IUpdateAttachmentCouplerLoadFailedAction, IUpdateAttachmentCouplerSuccessAction, updateAttachmentCouplerLoadFailedAction, updateAttachmentCouplerLoadSuccessAction } from "./updateCouplerActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateCouplerEpic: Epic = (
    action$: ActionsObservable<IUpdateAttachmentCouplerLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateAttachmentCouplerSuccessAction | IUpdateAttachmentCouplerLoadFailedAction> =>
    action$.ofType(IUPDATE_ATTACHMENT_COUPLER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.UPDATE_COUPLER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateAttachmentCouplerSuccessAction | IUpdateAttachmentCouplerLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateAttachmentCouplerLoadSuccessAction(response);
                            } else {
                                return updateAttachmentCouplerLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateAttachmentCouplerLoadFailedAction(response.message)))
                    )
            )
        );