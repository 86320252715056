export enum IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST {
    REQUEST = "getHazardControlPlanListByDepartmentID/GET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST",
    SUCCESS = "getHazardControlPlanListByDepartmentID/GET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_SUCCESS",
    FAILED = "getHazardControlPlanListByDepartmentID/GET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_FAILED",
};

export interface IGetHazardControlPlanListByDepartmentIDRequest {
    token: string;
    request: {
        department_id: number 
    }
};

export interface IGetHazardControlPlanListByDepartmentID {
    hazards: IHazardControlPlanList[];
    types: IHazardTypes[];
};

export interface IHazardTypes {
    id: number;
    name: string;
};

export interface IHazardControlPlanList {
    hazard: string,
    type_ID: number;
    control_Plans: string[],
};

