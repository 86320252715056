import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateSurveyAttachmentRequest } from "../addSurveyAttachment/addSurveyAttachmentConstants";
import { IUPDATE_SURVEY_ATTACHMENT_REQUEST } from "./updateSurveyAttachmentConstants";

export interface IUpdateSurveyAttachmentLoadAction {
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.REQUEST;
    data: IAddUpdateSurveyAttachmentRequest;
}
export const updateSurveyAttachmentLoadAction = (data: IAddUpdateSurveyAttachmentRequest): IUpdateSurveyAttachmentLoadAction => ({
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.REQUEST,
    data
});
export interface IUpdateSurveyAttachmentSuccessAction {
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateSurveyAttachmentLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateSurveyAttachmentSuccessAction => ({
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.SUCCESS,
    list
});
export interface IUpdateSurveyAttachmentLoadFailedAction {
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.FAILED;
    message: string;
}
export const updateSurveyAttachmentLoadFailedAction = (message: string): IUpdateSurveyAttachmentLoadFailedAction => ({
    type: IUPDATE_SURVEY_ATTACHMENT_REQUEST.FAILED,
    message
});
