import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST, ISTTimesheetForApprovalGroupByDate, IGetSTTimesheetForApprovalGroupByDateRequest } from './getSTTimesheetForApprovalGroupByDateConstants';

export interface IGetSTTimesheetForApprovalGroupByDateLoadAction {
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST;
    data: IGetSTTimesheetForApprovalGroupByDateRequest
}
export const getSTTimesheetForApprovalGroupByDateLoadAction = (data: IGetSTTimesheetForApprovalGroupByDateRequest): IGetSTTimesheetForApprovalGroupByDateLoadAction => ({
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST,
    data
});
export interface IGetSTTimesheetForApprovalGroupByDateSuccessAction {
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>;
}
export const getSTTimesheetForApprovalGroupByDateLoadSuccessAction = (list: SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>): IGetSTTimesheetForApprovalGroupByDateSuccessAction => ({
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS,
    list
});
export interface IGetSTTimesheetForApprovalGroupByDateLoadFailedAction {
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED;
    message: string;
}
export const getSTTimesheetForApprovalGroupByDateLoadFailedAction = (message: string): IGetSTTimesheetForApprovalGroupByDateLoadFailedAction => ({
    type: IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED,
    message
});
