import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IWORK_ORDER_REQUEST } from "./workOrderConstants";
import { IWorkOrderLoadAction, IWorkOrderLoadFailedAction, IWorkOrderSuccessAction, workOrderLoadFailedAction, workOrderLoadSuccessAction } from "./workOrderActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const workOrderEpic: Epic = (
    action$: ActionsObservable<IWorkOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IWorkOrderSuccessAction | IWorkOrderLoadFailedAction> =>
    action$.ofType(IWORK_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.REPAIR_LINE_WORK_ORDER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IWorkOrderSuccessAction | IWorkOrderLoadFailedAction => {
                            if(response.message === "Success"){
                                return workOrderLoadSuccessAction(response);
                            } else {
                                return workOrderLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(workOrderLoadFailedAction(response.message)))
                    )
            )
        );