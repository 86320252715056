import { IVALIDATE_MECHANIC_LOGIN_REQUEST, IValidateMechanicLogin, IValidateMechanicLoginRequest } from "./validateMechanicLoginConstants";

export interface IValidateMechanicLoginLoadAction {
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.REQUEST;
    data: IValidateMechanicLoginRequest
}
export const validateMechanicLoginLoadAction = (data: IValidateMechanicLoginRequest): IValidateMechanicLoginLoadAction => ({
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.REQUEST,
    data
});
export interface IValidateMechanicLoginSuccessAction {
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.SUCCESS;
    data: IValidateMechanicLogin;
}
export const validateMechanicLoginLoadSuccessAction = (data: IValidateMechanicLogin): IValidateMechanicLoginSuccessAction => ({
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.SUCCESS,
    data
});
export interface IValidateMechanicLoginLoadFailedAction {
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.FAILED;
    message: string;
}
export const validateMechanicLoginLoadFailedAction = (message: string): IValidateMechanicLoginLoadFailedAction => ({
    type: IVALIDATE_MECHANIC_LOGIN_REQUEST.FAILED,
    message
});
