import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_TYPES_REQUEST, IAttachmentType } from "./getTypesConstants";
import { IGetTypesLoadAction, IGetTypesLoadFailedAction, IGetTypesSuccessAction } from "./getTypesActions";
import { IADD_ATTACHMENT_TYPE_REQUEST } from "../addType/addTypeConstants";
import { IUPDATE_ATTACHMENT_TYPE_REQUEST } from "../updateType/updateTypeConstants";
import { IAddAttachmentTypeSuccessAction } from "../addType/addTypeActions";
import { IUpdateAttachmentTypeSuccessAction } from "../updateType/updateTypeActions";

type Actions =
    | IGetTypesLoadAction
    | IGetTypesSuccessAction
    | IGetTypesLoadFailedAction
    | IUpdateAttachmentTypeSuccessAction
    | IAddAttachmentTypeSuccessAction
    | IFlushDataSuccessAction;

export const GetTypesReducer = (state: Server<SurewayAPIResponse<ById<IAttachmentType>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IAttachmentType>>> => {
    switch (action.type) {
        case IGET_TYPES_REQUEST.REQUEST:
            return loading;

        case IGET_TYPES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_TYPES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};