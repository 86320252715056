import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_TECH_SERVICE_REQUEST } from "./updateTechServiceConstants";
import { IUpdateTechServiceLoadAction, IUpdateTechServiceLoadFailedAction, IUpdateTechServiceSuccessAction, updateTechServiceLoadFailedAction, updateTechServiceLoadSuccessAction } from "./updateTechServiceActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateTechServiceEpic: Epic = (
    action$: ActionsObservable<IUpdateTechServiceLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateTechServiceSuccessAction | IUpdateTechServiceLoadFailedAction> =>
    action$.ofType(IUPDATE_TECH_SERVICE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.UPDATE_TECH_SERVICE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateTechServiceSuccessAction | IUpdateTechServiceLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateTechServiceLoadSuccessAction(response);
                            } else {
                                return updateTechServiceLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateTechServiceLoadFailedAction(response.message)))
                    )
            )
        );