import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { Navbar, SOCTNavbarMenu } from "../../../shared/navbars";
import { SOCTTabNavbarMenu, TabNavbar } from "../../../shared/tabNavbar";
import queryString from "query-string";
import { IGetMeterHistoryBySOCTIDRequest, IMeterHistory } from "../../../../redux/field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDConstants";
import { getMeterHistoryBySOCTIDLoadAction } from "../../../../redux/field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDActions";
import { getMeterHistoryBySOCTID } from "../../../../redux/field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDAccessor";

interface IMeterHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMeterHistoryBySOCTID: Server<SurewayAPIResponse<ById<IMeterHistory>>>;
};

interface IMeterHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMeterHistoryBySOCTIDRequest: (data: IGetMeterHistoryBySOCTIDRequest) => unknown;
};


interface IMeterHistoryOwnProps {

};

interface IMeterHistoryState {
    unit_Number: string | undefined;
    unit_ID: number | undefined;
};

const MeterHistoryStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMeterHistoryProps = RouteComponentProps
    & IMeterHistoryStoreProps
    & IMeterHistoryDispatchProps
    & IMeterHistoryOwnProps;

class MeterHistory extends PureComponent<IMeterHistoryProps, IMeterHistoryState> {

    public constructor(props: IMeterHistoryProps) {
        super(props);
        this.state = {
            unit_Number: undefined,
            unit_ID: undefined,
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IMeterHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getMeterHistoryBySOCTID } = this.props;
        const data = hasPayload(getMeterHistoryBySOCTID) ? getMeterHistoryBySOCTID.payload.response && Object.values(getMeterHistoryBySOCTID.payload.response ) : [];
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        // console.log(data);
        const query = queryString.parse(this.props.location.search);

        return (
            <PageSpacing title="SOCT - Meter History" description="SOCT - Meter History" fixedSpaceOnSmallerScreens={true}>

                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                {query && query.id && Number(query.id) > 0 && <TabNavbar tabs={SOCTTabNavbarMenu} handleTabClick={this.handleTabClick}/>}
                <MeterHistoryStyles>
                    {(getRole === true) && 
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">Meter History - {this.state.unit_Number && this.state.unit_Number}</h2>
                                    <hr />

                                    {getMeterHistoryBySOCTID.kind === STATUS_ENUM.LOADING && <LALoading message="Loading SOCT Meter History..." />}
                                    {getMeterHistoryBySOCTID.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load SOCT Meter History..." />}

                                    {getMeterHistoryBySOCTID.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="MeterHistoryList"
                                                    customRowColor={true}
                                                    exportFileName="MeterHistory"
                                                    storageKey="MeterHistoryListSessionKey"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "unit_Number", caption: "Unit Number", type: "string" },
                                                        { name: "date", caption: "Date", type: "date" },
                                                        { name: "current_Hours", caption: "Current Hours", type: "number" },                                                   
                                                        { name: "location", caption: "Location", type: "string" },
                                                        { name: "sub_Location", caption: "Sub Location", type: "string" },
                                                        { name: "ecm", caption: "ECM", type: "number" },
                                                        { name: "cancelled", caption: "Cancelled", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string" },
                                                        { name: "source", caption: "Source", type: "string" },
                                                        { name: "source_ID", caption: "Source ID", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime"},
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    } 
                </MeterHistoryStyles>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.id) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: e.row.data.id.toString(), soct_ID: query.id.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.METER_HISTORY.ADD_UPDATE_METER_HISTORY
            });
        }
    };

    private onClick = (): void => {

    };

    private handleTabClick = (e: any, url: string): void => {
        const query = queryString.parse(this.props.location.search);
        if(e.target.textContent === "Unit History") {
            if(this.state.unit_ID)
                window.open(url + "?id=" + this.state.unit_ID.toString(), "_blank")
        }
        else {
            if(query && query.id) {
                callRouteWithQueryString({
                    route: this.props,
                    search: { id: query.id.toString(), token: getTokenFromUrl(true) },
                    pathName: url
                });
            }
        }
    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.id) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: "0", soct_ID: query.id.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.METER_HISTORY.ADD_UPDATE_METER_HISTORY
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                if (isNotLoaded(this.props.getMeterHistoryBySOCTID)) {
                    this.getDataForTable();
                };

                if(hasPayload(this.props.getMeterHistoryBySOCTID)) {
                    if(this.props.getMeterHistoryBySOCTID.payload.response) {
                        const data = Object.values(this.props.getMeterHistoryBySOCTID.payload.response);
                        if(data.length > 0) {
                            const unit_Number = data[0].unit_Number ?? undefined;
                            const unit_ID = data[0].unit_ID;
                            this.setState({ unit_Number, unit_ID });
                        }
                    }
                }
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        const query = queryString.parse(this.props.location.search);
        if (hasPayload(this.props.getToken) && query && query.id) {
            this.props.getMeterHistoryBySOCTIDRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    SOCT_ID : Number(query.id)
                }
            });
        };
    };
}

const mapStateToProps = (state: IStore): IMeterHistoryStoreProps => ({
    getToken: getToken(state),
    // getBulkMeterReadings: getBulkMeterReadings(state),
    getMeterHistoryBySOCTID: getMeterHistoryBySOCTID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IMeterHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getBulkMeterReadingsRequest: (data: IBulkMeterReadingsRequest) => dispatch(getBulkMeterReadingsLoadAction(data)),
    getMeterHistoryBySOCTIDRequest: (data: IGetMeterHistoryBySOCTIDRequest) => dispatch(getMeterHistoryBySOCTIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeterHistory);