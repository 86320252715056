import { IAddUpdateControlPlan } from "../addControlPlan/addControlPlanConstants";

export enum IUPDATE_CONTROL_PLAN_REQUEST {
    REQUEST = "updateControlPlan/UPDATE_CONTROL_PLAN_REQUEST",
    SUCCESS = "updateControlPlan/UPDATE_CONTROL_PLAN_SUCCESS",
    FAILED = "updateControlPlan/UPDATE_CONTROL_PLAN_FAILED"
};

export interface IUpdateControlPlanRequest {
    token: string;
    request: IAddUpdateControlPlan;
};