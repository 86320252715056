import { ReactNode, PureComponent } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IStore, IDispatch } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { GREEN_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { userName, undefinedFunction, getTokenFromUrl, ZEROTH } from '../../shared/constExports';
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IGetPayrollMechanicLogsReportRequest, IPayrollMechanicLogsReport } from "../../../redux/field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportConstants";
import { getPayrollMechanicLogsReport } from "../../../redux/field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportAccessor";
import { getPayrollMechanicLogsReportLoadAction } from "../../../redux/field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportActions";
import { ArrowLeftIcon, SummaryIcon } from "../../shared/icons";
import { LAButton } from "../../shared/buttons";
import { IAcknowledgeLogByPayrollRequest } from "../../../redux/field/repairLine/acknowledgeLogByPayroll/acknowledgeLogByPayrollConstants";
import { acknowledgeLogByPayrollStatus } from "../../../redux/field/repairLine/acknowledgeLogByPayroll/acknowledgeLogByPayrollAccessor";
import { acknowledgeLogByPayrollLoadAction } from "../../../redux/field/repairLine/acknowledgeLogByPayroll/acknowledgeLogByPayrollActions";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LADatePicker from "../../shared/datePicker";
import LALinkButton from "../../shared/linkButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IGetMechanicTimecardVersionHistory, IGetMechanicTimecardVersionHistoryRequest } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";
import { getMechanicTimecardVersionHistoryLoadAction } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryActions";
import { getMechanicTimecardVersionHistory } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryAccessor";
import { MechanicLogsVersionHistory } from "./mechanicLogsVersionHistory";


interface IPayrollContractorsMechanicLogsReportStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getPayrollMechanicLogsReport: Server<SurewayAPIResponse<IPayrollMechanicLogsReport[]>>;
    acknowledgeLogByPayroll: Server<SurewayAPIResponse<string>>;
    getMechanicLogsVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
};

interface IPayrollContractorsMechanicLogsReportDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getPayrollMechanicLogsReportRequest: (data: IGetPayrollMechanicLogsReportRequest) => unknown;
    acknowledgeLogByPayrollRequest: (data: IAcknowledgeLogByPayrollRequest) => unknown;
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest) => unknown;
};

interface IPayrollContractorsMechanicLogsReportState {
    data: IPayrollMechanicLogsReport[];
    editMode: boolean;
    serverError: string | undefined;
    dateRange: Date[] | undefined,
    mechLogsVersionHistoryPopup: boolean;
};

const payrollAcknowlegedOptions: IIdName[] = [
    {id: 1, name: "Yes"},
    {id: 2, name: "No"}
]

const PayrollContractorsMechanicLogsReportStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    
    .dx-datagrid-save-button {
        display: none;
    };

    .dx-datagrid-cancel-button {
        display: none;
    };

    .back-home {       
        float: right;    
    };

    .clear-filters{       
        float: left;
        background-color: rgb(168, 0, 0);        
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IPayrollContractorsMechanicLogsReportProps =
    RouteComponentProps
    & IPayrollContractorsMechanicLogsReportStoreProps
    & IPayrollContractorsMechanicLogsReportDispatchProps;

class PayrollContractorsMechanicLogsReport extends PureComponent<IPayrollContractorsMechanicLogsReportProps, IPayrollContractorsMechanicLogsReportState> {

    public constructor(props: IPayrollContractorsMechanicLogsReportProps) {
        super(props);
        this.state = {
            data: [],
            editMode: false,
            serverError: undefined,
            dateRange: [],
            mechLogsVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IPayrollContractorsMechanicLogsReportProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.acknowledgeLogByPayroll !== prevProps.acknowledgeLogByPayroll) {
                if (this.props.acknowledgeLogByPayroll.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.acknowledgeLogByPayroll.message });

                if (hasPayload(this.props.acknowledgeLogByPayroll) && (this.props.acknowledgeLogByPayroll.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };
        };
    }

    public render(): ReactNode {

        const { getToken, getPayrollMechanicLogsReport, acknowledgeLogByPayroll, getMechanicLogsVersionHistory } = this.props;
        const { kind } = getPayrollMechanicLogsReport;
        const { data, editMode, serverError, dateRange, mechLogsVersionHistoryPopup } = this.state;
        const today = new Date();
        // const minDate = new Date(new Date().setDate(today.getDate() - 30)).toDateString();
        const maxDate = new Date().toDateString();
        const records = dateRange ? data.filter((x) => dateRange[1] ? ((new Date(x.date) >= dateRange[0]) && (new Date(x.date) <= dateRange[1])) : true) : data
        const mechanicLogsVersionHistorypopupData = hasPayload(getMechanicLogsVersionHistory) ? getMechanicLogsVersionHistory.payload.response : {};
        // console.log(data)
        const onEditStart = (): void => this.handleEditMode(true);
        const onEditCancel = (): void => this.handleEditMode(false);

        return (
            <PageSpacing title="Payroll Mechanic Logs (Contractors) Report" description="FIELD - PAYROLL MECHANIC LOGS (CONTRACTORS) REPORT" fixedSpaceOnSmallerScreens={true}>
                <PayrollContractorsMechanicLogsReportStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid spacing={1} className="text-center">

                            {/* <LAButton
                                label="Back to Payroll"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            /> */}
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    className=""
                                    label="Explorer Upload"
                                    onClick={this.handleExplorerUpload}
                                    startIcon={<SummaryIcon color={WHITE_COLOR} />}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <LAButton
                                    className=""
                                    label="Summary"
                                    onClick={this.handleSummary}
                                    startIcon={<AccessTimeIcon />}
                                />
                                <h2>MECHANIC LOGS (CONTRACTORS) PAYROLL REPORT</h2>
                                <hr />

                                <strong>
                                    Date Range: <LADatePicker
                                        key={"clear"}
                                        mode="range"
                                        value={dateRange}
                                        // minDate={minDate}
                                        maxDate={maxDate}
                                        onChange={this.setDateRange}
                                    />
                                </strong>

                                <LALinkButton onClick={this.clearDateRange} label="clear"></LALinkButton>
                            </LAGridItem>

                            <LAGridItem xs={12}>

                                {(kind === STATUS_ENUM.LOADING) &&
                                    <LALoading className="text-center" message="Loading Sheets. Please Wait..." />
                                }

                                {(kind === STATUS_ENUM.FAILED) &&
                                    <LAErrorBox color={WARNING_COLOR} text="Sorry Failed to Load Data. Try to refresh the window..." />
                                }

                                {((kind === STATUS_ENUM.SUCCEEDED)) &&
                                    <LADevExtremeGrid
                                        id="PayrollMechanicLogsContractorsReport"
                                        data={records}
                                        searchPanel={true}
                                        filterHeader={true}
                                        columnsHiding={true}
                                        customRowColor={false}
                                        key="id"
                                        getRefreshDataCall={this.getDataForTable}
                                        export={true}
                                        onClick={undefinedFunction}
                                        onClearDateRange={this.clearDateRange}
                                        editRow={true}
                                        onEditStart={onEditStart}
                                        onEditCancel={onEditCancel}
                                        onSaving={this.onSaveClick}
                                        paging={100}
                                        height={4000}
                                        exportFileName="PayrollMechanicLogsContractorsReport"
                                        storageKey="PayrollMechanicLogsContractorsReportSessionStorage"
                                        onRowPrepared={this.rowPrepPayrollMechanicLogsReportPage}
                                        onCustomClick={this.handleMechLogsVersionHistoryClick}
                                        allowRowEditing={this.handleAllowRowEditing}
                                        columns={[
                                            { name: "start_Date", caption: "Date", type: "date", sortDesc: true, allowEditing: false, width: 90 },
                                            { name: "name", caption: "Name", type: "string", allowEditing: false },
                                            { name: "employee_Code", caption: "Employee Code", type: "string", allowEditing: false },
                                            { name: "display", caption: "Unit/Attachment", type: "string", allowEditing: false },
                                            { name: "defect_Item", caption: "Defect Item", type: "string", renderCell: (e:any) => { return <div className="link-btn" onClick={() => this.onOpenDefectItem("defect_Item", e.row.data)}>{e.row.data.defect_Item}</div>} },  
                                            { name: "comments", caption: "Comments", type: "string", allowEditing: false },
                                            { name: "hours", caption: "Hours", type: "number", allowEditing: false },
                                            { name: "loa", caption: "LOA", type: "string", allowEditing: false },
                                            { name: "component_Code", caption: "Component Code", type: "string", allowEditing: false },
                                            { name: "work_Order_No", caption: "Work Order", type: "string", allowEditing: false },
                                            { name: "job_No", caption: "Job No.", type: "string", allowEditing: false },
                                            { name: "cost_Code", caption: "Cost Code", type: "string", allowEditing: false },
                                            { name: "parts_Delay", caption: "Parts Delay", type: "string", allowEditing: false },
                                            { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number", allowEditing: false },
                                            { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string", allowEditing: false },
                                            { name: "truck", caption: "Truck", type: "string", allowEditing: false },
                                            { name: "start_Time", caption: "Time", type: "string",allowEditing: false, renderCell: (e:any) => { return <div>{e.row.data.start_Time + '-' + e.row.data.end_Time  } </div>}},
                                            // { name: "end_Time", caption: "End Time", type: "string", allowEditing: false, show: false },
                                            { name: "field_Log", caption: "Field Log", type: "string", allowEditing: false },
                                            { name: "uploaded_Date", caption: "Uploaded Date", type: "datetime",  allowEditing: false},
                                            { name: "type", caption: "Type", type: "string",  allowEditing: false},
                                            { name: "history", caption: "Version History",  allowEditing: false, dropDownSource: undefined, renderCell: (e:any) => { return <LALinkButton label="Version History" onClick={() => this.handleMechLogsVersionHistoryClick("version_History", e)}></LALinkButton>}},
                                            { name: "payroll_Acknowledged", caption: "Payroll Acknowleged", type: "string", allowEditing: true,  dropDownSource: payrollAcknowlegedOptions, dropDownName:"name", dropDownId: "name"},
                                            { name: "travel_Type", caption: "Travel Type", type: "string",  allowEditing: false, show: false},
                                            { name: "truck_Hours", caption: "Travel Hours", type: "number",  allowEditing: false, show: false},
                                            { name: "description", caption: "Description", type: "string",  allowEditing: false, show: false},
                                            { name: "employee_Code", caption: "Employee Code", type: "string",  allowEditing: false, show: false},
                                            { name: "charge_Location", caption: "Charge Location", type: "string",  allowEditing: false, show: false},
                                            { name: "status", caption: "Status", type: "string",  allowEditing: false, show: false},
                                            { name: "issue", caption: "Issue", type: "string",  allowEditing: false, show: false},
                                            { name: "item_Repair_Location", caption: "Item Repair Location", type: "string",  allowEditing: false, show: false},
                                            { name: "manager_Instructions", caption: "Manager Instructions", type: "string",  allowEditing: false, show: false},
                                            { name: "defect_Status", caption: "Defect Status", type: "string",  allowEditing: false, show: false},
                                            { name: "damaged", caption: "Damaged", type: "string",  allowEditing: false, show: false},
                                            { name: "warranty", caption: "Warranty", type: "string",  allowEditing: false, show: false},
                                            { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string",  allowEditing: false, show: false},
                                            { name: "serial_No", caption: "Serial No", type: "string",  allowEditing: false, show: false},
                                            { name: "major_Component_Replacement_Type", caption: "Major Component Replacement Type", type: "string",  allowEditing: false, show: false},
                                            { name: "major_Component_Replacement_Hours", caption: "Major Component Replacement Hours", type: "number",  allowEditing: false, show: false},
                                            { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string",  allowEditing: false, show: false},
                                            { name: "rejection_Notes", caption: "Rejection Notes", type: "string",  allowEditing: false, show: false},
                                            { name: "modified_After_Upload", caption: "Modified After Upload", type: "string", show: false},
                                        ]}
                                    />
                                }
                            </LAGridItem>
                            
                            {(serverError !== null) && (serverError !== undefined) && (serverError.length > ZEROTH) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            {mechLogsVersionHistoryPopup && <MechanicLogsVersionHistory
                                data={mechanicLogsVersionHistorypopupData}
                                // display={display}
                                open={mechLogsVersionHistoryPopup}
                                status={getMechanicLogsVersionHistory.kind}
                                onCancel={this.handleMechLogsVersionHistoryPopupClose}
                            />}
                        </LAGrid>
                    </LAPaperWithPadding>
                    <Prompt when={editMode} message="You have unsaved changes ?" />
                    <RequestStatus requestStatus={acknowledgeLogByPayroll.kind} successMessage="Record successfully updated" />
                </PayrollContractorsMechanicLogsReportStyles>
            </PageSpacing>
        );
    }

    private onOpenDefectItem = (name: string, data: IPayrollMechanicLogsReport) => {
        // console.log(name, data);
        if(data.defect_ID && data.repair_ID)
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_ID.toString() + "&defect_ID=" + data.defect_ID.toString()+ "&view=All")
    }

    private handleMechLogsVersionHistoryPopupClose = (): void => {
        this.setState({ mechLogsVersionHistoryPopup: false });
    };

    private handleMechLogsVersionHistoryClick = async (name: string, e: any): Promise<void> => {
        // console.log(e.row.data)
        if (hasPayload(this.props.getToken)) {
            if(e.row.data.defect_ID > 0)
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Defect"
                    }
                });
            else {
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Misc"
                    }
                });
            }
        }
        await this.setState({ mechLogsVersionHistoryPopup: true });
    }
    private rowPrepPayrollMechanicLogsReportPage = (e: any) => {
        // console.log(e)
        if(e.data) {
            if(e.data.modified_After_Upload === "Yes") {
                e.rowElement.bgColor = LIGHT_ORANGE_COLOR;
            }
            if(e.data.payroll_Acknowledged === "Yes") {
                e.rowElement.bgColor = LIGHT_GREEN_COLOR;
            }
        }
    }

    private handleAllowRowEditing = (e: any): boolean | undefined => {
        // console.log(e)
        if(e.row) {
            if(e.row.data.modified_After_Upload === "Yes" && e.row.data.payroll_Acknowledged !== "Yes") {
                return true
            }
            return false
        }
    }

    private clearDateRange = (): void => {
        this.setState({ dateRange: undefined });
    };

    private setDateRange = (date: string, date2?: string): void => {
        if (date2) {
            this.setState({ dateRange: [new Date(date), new Date(date2)] });
        } else {
            this.setState({ dateRange: [new Date(date)] });
        }
    };

    private handleEditMode = (editMode: boolean) => {
        this.setState({ editMode });
    }

    // private handleEditstart = (e:any) => {
    //     console.log(e)
    //     if(e.data.modified_After_Upload === "Yes") {
    //         alert("You can't edit this row")
    //     }
    //     this.setState({ editMode: true});
    // }

    private onSaveClick = (record: any): void => {
        // console.log(record)
        const { getToken, acknowledgeLogByPayrollRequest } = this.props;

        if (record.changes[0] !== undefined && hasPayload(getToken)) {
            const { data } = record.changes[0];

            let request = {
                ID: data.id,
                Defect_ID: data.defect_ID,
                Payroll_Acknowledged: data.payroll_Acknowledged,
                Modified_By: getToken.payload.response.upn
            };

            acknowledgeLogByPayrollRequest({
                request: request,
                token: getToken.payload.response.token
            });

            this.handleEditMode(false);
        }
    }

    private handleExplorerUpload = (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS);
    };

    private handleSummary = (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS_SUMMARY);
    };

    private handleCancel = (): void => {
        // this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL,
            search: getTokenFromUrl(false)
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        
        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.payrollContractorAccess) {
                if (isNotLoaded(this.props.getPayrollMechanicLogsReport)) {
                    this.getDataForTable();
                };

                if (hasPayload(this.props.getPayrollMechanicLogsReport)) {
                    const data: IPayrollMechanicLogsReport[] = this.props.getPayrollMechanicLogsReport.payload.response;

                    this.setState({ data });
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (this.props.getToken.payload.response.payrollContractorAccess)) {
                this.props.getPayrollMechanicLogsReportRequest({
                    token: this.props.getToken.payload.response.token,
                    request : {
                        Type: "Contractors"
                    }
                });
        }
    };

}

const mapStateToProps = (state: IStore): IPayrollContractorsMechanicLogsReportStoreProps => ({
    getToken: getToken(state),
    getPayrollMechanicLogsReport: getPayrollMechanicLogsReport(state),
    acknowledgeLogByPayroll: acknowledgeLogByPayrollStatus(state),
    getMechanicLogsVersionHistory: getMechanicTimecardVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IPayrollContractorsMechanicLogsReportDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getPayrollMechanicLogsReportRequest: (data: IGetPayrollMechanicLogsReportRequest) => dispatch(getPayrollMechanicLogsReportLoadAction(data)),
    acknowledgeLogByPayrollRequest: (data: IAcknowledgeLogByPayrollRequest) => dispatch(acknowledgeLogByPayrollLoadAction(data)),
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest): unknown => dispatch(getMechanicTimecardVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollContractorsMechanicLogsReport);