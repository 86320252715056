import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_JOURNELS_REQUEST, IJournels } from "./getJournelsConstants";
import { IGetJournelsLoadAction, IGetJournelsLoadFailedAction, IGetJournelsSuccessAction } from "./getJournelsActions";
import { IAddJournelSuccessAction } from "../addJournel/addJournelActions";
import { IADD_JOURNEL_REQUEST } from "../addJournel/addJournelConstants";
import { IUpdateJournelSuccessAction } from "../updateJournel/updateJournelActions";
import { IUPDATE_JOURNEL_REQUEST } from "../updateJournel/updateJournelConstants";

type Actions =
    | IGetJournelsLoadAction
    | IGetJournelsSuccessAction
    | IGetJournelsLoadFailedAction
    | IAddJournelSuccessAction
    | IUpdateJournelSuccessAction
    | IFlushDataSuccessAction;

export const GetJournelsReducer = (state: Server<SurewayAPIResponse<ById<IJournels>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IJournels>>> => {
    switch (action.type) {
        case IGET_JOURNELS_REQUEST.REQUEST:
            return loading;

        case IGET_JOURNELS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_JOURNELS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_JOURNEL_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_JOURNEL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};