
export enum ISAVE_DRIVER_ASSIGNMENT_REQUEST {
    REQUEST = "saveDriverAssignment/SAVE_DRIVER_ASSIGNMENT_REQUEST",
    SUCCESS = "saveDriverAssignment/SAVE_DRIVER_ASSIGNMENT_SUCCESS",
    FAILED = "saveDriverAssignment/SAVE_DRIVER_ASSIGNMENT_FAILED"
};

export interface ISaveDriverAssignmentRequest {
    upn: string;
    token: string;
    request: ISaveDriverAssignment[];
};

export interface ISaveDriverAssignment {
    Notes: string | null;
    Pilots: string;
    Driver_ID: number;
    Move_Request_ID: number;
    Trailer_Config_ID: number | null;
    Dispatch_Priority: number | null;
};