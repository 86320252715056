import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST, IGetFormResponseVersionHistoryByID, IGetFormResponseVersionHistoryByIDRequest } from "./getFormResponseVersionHistoryByIDConstants";

export interface IGetFormResponseVersionHistoryByIDLoadAction {
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.REQUEST;
    data: IGetFormResponseVersionHistoryByIDRequest
}
export const getFormResponseVersionHistoryByIDLoadAction = (data: IGetFormResponseVersionHistoryByIDRequest): IGetFormResponseVersionHistoryByIDLoadAction => ({
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetFormResponseVersionHistoryByIDSuccessAction {
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>;
}
export const getFormResponseVersionHistoryByIDLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>): IGetFormResponseVersionHistoryByIDSuccessAction => ({
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetFormResponseVersionHistoryByIDLoadFailedAction {
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getFormResponseVersionHistoryByIDLoadFailedAction = (message: string): IGetFormResponseVersionHistoryByIDLoadFailedAction => ({
    type: IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.FAILED,
    message
});
