import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_CONTROL_PLAN_NEW_REQUEST, IGetControlPlansNew, IGetControlPlansNewRequest } from "./getControlPlansNewConstants";

export interface IGetControlPlansNewLoadAction {
    type: IGET_CONTROL_PLAN_NEW_REQUEST.REQUEST;
    data: IGetControlPlansNewRequest
}
export const getControlPlansNewLoadAction = (data: IGetControlPlansNewRequest): IGetControlPlansNewLoadAction => ({
    type: IGET_CONTROL_PLAN_NEW_REQUEST.REQUEST,
    data
});
export interface IGetControlPlansNewSuccessAction {
    type: IGET_CONTROL_PLAN_NEW_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetControlPlansNew>>;
}
export const getControlPlansNewLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetControlPlansNew>>): IGetControlPlansNewSuccessAction => ({
    type: IGET_CONTROL_PLAN_NEW_REQUEST.SUCCESS,
    list
});
export interface IGetControlPlansNewLoadFailedAction {
    type: IGET_CONTROL_PLAN_NEW_REQUEST.FAILED;
    message: string;
}
export const getControlPlansNewLoadFailedAction = (message: string): IGetControlPlansNewLoadFailedAction => ({
    type: IGET_CONTROL_PLAN_NEW_REQUEST.FAILED,
    message
});
