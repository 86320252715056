import React from 'react';
import {TextField, Button, Grid, MenuItem, FormControl, FormHelperText, Paper, Typography, FormLabel, FormControlLabel, FormGroup, Checkbox} from '@mui/material';
import {getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID} from '../../../api/workingsites/workingSitesAPI';
import {addSOCT, getSOCTUnitNumbers} from '../../../api/soct/soctAPI';
import {OutlinedRequiredStyle, OutlinedRequiredStyleSelected} from '../../../../../shared/styles';
import Autocomplete from "@mui/material/Autocomplete";
import jwt_decode from "jwt-decode";
import { getSOCTUserRole } from '../../../api/soct/soctAPI';
import { userName } from '../../../../../shared/constExports';

// const getToken = localStorage.getItem("adal.idtoken");
// const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\amit.patel" };
// const currentUser = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

interface IProps {
    history:any;
    unitNumbers:any[];
}
interface IState{
    isUnitUnique:boolean;
    locations:any[];
    sub_locations:any[];
    unit_numbers:any[];
    loaded:boolean;
}

var soct_data:any = {unit_number:'',make:'',model:'',location:'',sub_location:'', sub_location_id:0,service_date:'',current_hours:'',last_eo:'',filter_cuttings:'', comments:'', attachments:[], geotab_sync:false, location_id:0};
var equipment_list:any[];
var location_list:String[] = [];
var sub_location_list:String[] = [];
var unit_number_list:String[] = [];
export default class CreateSOCTMain extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state={isUnitUnique:false, locations:[], sub_locations:[], unit_numbers:[], loaded:false};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
    }
    handleFileRemove(index:number,event:any){
        soct_data.attachments.splice(index,1);
        this.setState({});
    }
    handleFileUpload(event:any){
        soct_data.attachments.push(event.target.files[0].name);
        this.setState({});
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    async onFormSave(){
        var isUnitUnique = true;
        var count = 0;
        for(var idx = 0; idx<this.props.unitNumbers.length; idx++){
            if(this.props.unitNumbers[idx] === soct_data.unit_number && count<1){
                count++;
                alert("Unit is not unique");
                isUnitUnique = false;
            }
        }
        if(!isUnitUnique){
            this.setState({isUnitUnique:true});
        }
        else{
            this.setState({isUnitUnique:false});
        }
        if(isUnitUnique){
            if(soct_data.unit_number !=''){
                var res:any = await addSOCT(soct_data, userName);
                if(res.status === 200){
                    alert("Successfully added new SOCT");
                    this.props.history.goBack();
                }
                else{
                    alert("Failed to add new SOCT");
                    this.props.history.goBack();
                }
            }
            else{
                alert("Unit Number and current hours are mandatory");
            }
        }
    }
    async onChange(event:any){
        const fieldName = event.target.name;
        switch(fieldName){
            case 'unit_number':
                soct_data.unit_number = event.target.value;
                equipment_list.map((unit:any) => {
                    // model_Name is null for each record for some reason
                    if(unit.unit_Number === event.target.value){
                        soct_data.make = unit.make;
                        soct_data.model = unit.model;
                    }
                })
                break;
            case 'make':
                soct_data.make = event.target.value;
                break;
            case 'model':
                soct_data.model = event.target.value;
                break;
            case 'location':
                soct_data.location = event.target.value;
                var parent_id=0;   
                this.state.locations.map((site:any) => {
                    if(site.site_Name === event.target.value){
                        parent_id = site.id;
                    }
                });
                var res:any = await getSOCTWorkingSubSitesByParentID(parent_id, true);
                this.setState({sub_locations:res.sort((a:any,b:any) => (a.sub_Site_Name > b.sub_Site_Name) ? 1:-1)});
                break;
            case 'sub_location':
                soct_data.sub_location = event.target.value;
                this.state.sub_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        soct_data.sub_location_id = site.id;
                    }
                });
                break;
            case 'service_date':
                soct_data.service_date = event.target.value;
                break;
            case 'current_hours':
                soct_data.current_hours = event.target.value;
                break;
            case 'last_eo':
                soct_data.last_eo = event.target.value;
                break;
            case 'filter_cuttings':
                soct_data.filter_cuttings = event.target.value;
                break;
            case 'comments':
                soct_data.comments = event.target.value;
                break;
            case 'geotab_sync':
                soct_data.geotab_sync = !soct_data.geotab_sync;
                break;
        }
        this.setState({isUnitUnique:false});
    }
    async componentDidMount(){
        var res:any = await getSOCTUserRole(userName);
        if(res.isAuthenticated){
            if(res.role !='Admin'){
                this.props.history.push('/');
            }
        }
        var temp_location_list:string[] = [];
        let master_temp_list = await getSOCTWorkingSites(true);
        if(master_temp_list != null){
            master_temp_list.map((location:any) => {
                temp_location_list.push(location);
                location_list.push(location.site_Name);
            });
            this.setState({locations:temp_location_list.sort((a:any,b:any) => (a.site_Name > b.site_Name) ? 1:-1)});
            location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
        }
        let unit_numbers = await getSOCTUnitNumbers();
        unit_number_list = [];
        equipment_list = [];
        equipment_list = unit_numbers;
        if(unit_numbers != null){
            let temp:any[] = this.props.unitNumbers;
            equipment_list = equipment_list.filter(function(el) {
                return !temp.includes(el.unit_Number);
            });
            equipment_list.map((elem:any) => {
                unit_number_list.push(elem.unit_Number);
            });
            unit_number_list = unit_number_list.sort((a,b) => (a>b) ? 1:-1);
            this.setState({unit_numbers: equipment_list.sort((a,b) => (a.unit_Number > b.unit_Number) ? 1:-1).map((unit:any) => <MenuItem value={unit.unit_Number}>{unit.unit_Number}</MenuItem>)});
        }
        this.setState({loaded:true});
    }
    render(){
        console.log('render: ', unit_number_list)
        if(this.state.loaded){
        return (
            <Grid container spacing={6}>
            <Grid item xs={4}>
                <FormControl sx={{width:'100%'}}>
                <Autocomplete
                    onChange={async (event:any, newValue:String | null) => {soct_data.unit_number=newValue;
                        equipment_list.map((unit:any) => {
                            console.log(unit.unit_Number, newValue)
                            if(unit.unit_Number === newValue){
                                soct_data.make = unit.make;
                                soct_data.model = unit.model;
                            }
                        });
                        this.setState({});
                    }}
                    style={soct_data.unit_number ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                    disablePortal 
                    autoHighlight
                    options={unit_number_list} 
                    renderInput={(params:any):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="Unit Number"></TextField>}></Autocomplete>
                <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} label="Make" value={soct_data.unit_number ? soct_data.make:""} name="make" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} label="Model" value={soct_data.unit_number ? soct_data.model:""} name="model" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={4}>
            <FormControl sx={{width:'100%'}}>
                <Autocomplete
                style={{backgroundColor:'white'}}
                    onChange={async (event:any, newValue:String | null) => {
                        soct_data.location = newValue;
                         sub_location_list = [];
                         soct_data.sub_location = '';     
                         soct_data.sub_location_id = 0;       
                         this.setState({});                       
                        var parent_id=0;
                        this.state.locations.map((site:any) => {
                            if(site.site_Name === newValue){
                                parent_id = site.id;
                            }
                        });
                        soct_data.location_id = parent_id;
                        var res:any = await getSOCTWorkingSubSitesByParentID(parent_id, true);
                        res.map((sub:any) => {
                            sub_location_list.push(sub.sub_Site_Name);
                        })
                        sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                        this.setState({sub_locations:res.sort((a:any,b:any) => (a.sub_Site_Name > b.sub_Site_Name) ? 1:-1)});} }
                    disablePortal 
                    autoHighlight
                    options={location_list} 
                    renderInput={(params:any):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="Location"></TextField>}></Autocomplete>
                    
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <Autocomplete
                style={{backgroundColor:'white'}}
                    onChange={(event:any, newValue:String | null) =>  {
                        soct_data.sub_location = newValue;
                        this.state.sub_locations.map((site:any) => {
                            if(site.sub_Site_Name === newValue){
                                soct_data.sub_location_id = site.id;
                            }
                        });
                        this.setState({})}}
                    disablePortal 
                    autoHighlight
                    options={sub_location_list} 
                    value={soct_data.sub_location}
                    renderInput={(params:any):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} type="date" helperText="Service Date" placeholder="date" name="service_date" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} label="Current Hours" placeholder="hours" name="current_hours" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField style={{backgroundColor:'white'}} label="Last_E:O" placeholder="last_EO" name="last_eo" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={3}>
                <TextField style={{backgroundColor:'white'}} label="Filter Cuttings" name="filter_cuttings" onChange={this.onChange}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} label="Comments" name="comments" onChange={this.onChange} multiline rows={3} fullWidth></TextField>
            </Grid>
            <Grid item xs={4}>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Sync Engine Hours with Device API</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={soct_data.geotab_sync} onChange={this.onChange} name="geotab_sync"/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
            <Paper variant="outlined" style={{padding:'15px', backgroundColor:'#e0bfbc'}}>
                <Grid item xs={12}>
                    <Typography style={{borderBottomColor:'black', borderBottomWidth:'thick', borderBottom:'solid', textAlign:'center', fontWeight:'bold'}}>Attachments</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" component="label">Drag and drop, or click to upload files<input hidden accept="image/*" multiple type="file" onChange={this.handleFileUpload}></input>
                    </Button>
                    {soct_data.attachments ? soct_data.attachments.map((file:any, index:number) => {
                         return <Grid item xs={3}>{file}<Button onClick={this.handleFileRemove.bind(this,index)}>X</Button></Grid>
                     }):null}
                </Grid>
            </Paper>
            </Grid>
            <Grid item xs={12}>
                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                <Button disabled={this.state.isUnitUnique} style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
            </Grid>
        </Grid>
        );
                    }
                    else{
                        return null;
                    }
    }
}
export {soct_data};