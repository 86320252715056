import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_LOGS_BY_ID_REQUEST, IMechanicLogById, IGetMechanicLogsByIdRequest } from "./getMechanicLogsByIdConstants";

export interface IGetMechanicLogsByIdLoadAction {
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.REQUEST;
    data: IGetMechanicLogsByIdRequest
}
export const getMechanicLogsByIdLoadAction = (data: IGetMechanicLogsByIdRequest): IGetMechanicLogsByIdLoadAction => ({
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetMechanicLogsByIdSuccessAction {
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMechanicLogById>>;
}
export const getMechanicLogsByIdLoadSuccessAction = (list: SurewayAPIResponse<ById<IMechanicLogById>>): IGetMechanicLogsByIdSuccessAction => ({
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicLogsByIdLoadFailedAction {
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getMechanicLogsByIdLoadFailedAction = (message: string): IGetMechanicLogsByIdLoadFailedAction => ({
    type: IGET_MECHANIC_LOGS_BY_ID_REQUEST.FAILED,
    message
});
