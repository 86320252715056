import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_VENDOR_ORDER_BY_ID_REQUEST, IGetVendorOrderByID } from "./getVendorOrderByIDConstants";
import { IGetVendorOrderByIDLoadAction, IGetVendorOrderByIDLoadFailedAction, IGetVendorOrderByIDSuccessAction,
    getVendorOrderByIDLoadFailedAction, getVendorOrderByIDLoadSuccessAction } from "./getVendorOrderByIDActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getVendorOrderByIDEpic: Epic = (
    action$: ActionsObservable<IGetVendorOrderByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetVendorOrderByIDSuccessAction | IGetVendorOrderByIDLoadFailedAction> =>
    action$.ofType(IGET_VENDOR_ORDER_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetVendorOrderByID>>(
                    END_POINTS.FIELD.VENDOR.GET_VENDOR_ORDER_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetVendorOrderByID>): IGetVendorOrderByIDSuccessAction => {
                            return getVendorOrderByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getVendorOrderByIDLoadFailedAction(response.message)))
                    )
            )
        );