import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_MECHANIC_REQUEST, IAddMechanicRequest } from "./addMechanicConstants";


export interface IAddMechanicLoadAction {
    type: IADD_MECHANIC_REQUEST.REQUEST;
    data: IAddMechanicRequest
}
export const addMechanicLoadAction = (data: IAddMechanicRequest): IAddMechanicLoadAction => ({
    type: IADD_MECHANIC_REQUEST.REQUEST,
    data
});
export interface IAddMechanicSuccessAction {
    type: IADD_MECHANIC_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addMechanicLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddMechanicSuccessAction => ({
    type: IADD_MECHANIC_REQUEST.SUCCESS,
    message
});
export interface IAddMechanicLoadFailedAction {
    type: IADD_MECHANIC_REQUEST.FAILED;
    message: string;
}
export const addMechanicLoadFailedAction = (message: string): IAddMechanicLoadFailedAction => ({
    type: IADD_MECHANIC_REQUEST.FAILED,
    message
});
