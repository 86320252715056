import { IUPDATE_JOB_PIT_REQUEST } from "./updateJobPitConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IUpdateJobPitLoadAction, IUpdateJobPitLoadFailedAction, IUpdateJobPitSuccessAction } from "./updateJobPitActions";

type Actions =
    | IUpdateJobPitLoadAction
    | IUpdateJobPitSuccessAction
    | IUpdateJobPitLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateJobPitReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_JOB_PIT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_JOB_PIT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_JOB_PIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};