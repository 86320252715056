import { combineEpics, Epic } from "redux-observable";
import { addJobEpic } from "./accounting/jobs/addJob/addJobEpic";
import { addJobMhrEpic } from "./accounting/jobs/addJobMhr/addJobMhrEpic";
import { addJobPitEpic } from "./accounting/jobs/addJobPit/addJobPitEpic";
import { getJobsEpic } from "./accounting/jobs/getJobs/getJobsEpic";
import { getJobsMHREpic } from "./accounting/jobs/getJobsMhr/getJobsMhrEpic";
import { getJobsPitsEpic } from "./accounting/jobs/getJobsPits/getJobsPitsEpic";
import { updateJobEpic } from "./accounting/jobs/updateJob/updateJobEpic";
import { updateJobMhrEpic } from "./accounting/jobs/updateJobMhr/updateJobMhrEpic";
import { updateJobPitEpic } from "./accounting/jobs/updateJobPit/updateJobPitEpic";
import { addShopEquipmentEpic } from "./field/addEquipments/addShopEquipmentsEpic";
import { addEquipmentTypeEpic } from "./field/addEquipmentType/addEquipmentTypeEpic";
import { addFilterEpic } from "./field/addFilter/addFilterEpic";
import { addLocationEpic } from "./field/addLocation/addLocationEpic";
import { addShopManufacturesEpic } from "./field/addManufactures/addShopManufacturesEpic";
import { addShopModelEpic } from "./field/addModels/addShopModelsEpic";
import { assignEquipmentTypeColumnEpic } from "./field/assignEquipmentTypeColumn/assignEquipmentTypeColumnEpic";
import { getEquipmentHistoryColumnsEpic } from "./field/getEquipmentHistoryColumn/getEquipmentHistoryColumnEpic";
import { getShopEquipmentsEpic } from "./field/getEquipments/getEquipmentEpic";
import { getEquipmentTypesEpic } from "./field/getEquipmentTypes/getEquipmentTypesEpic";
import { getEquipmentVersionHistoryEpic } from "./field/getEquipmentVersionHistory/getEquipmentVersionHistoryEpic";
import { getFilterEpic } from "./field/getFilter/getFilterEpic";
import { getLocationEpic } from "./field/getLocation/getLocationEpic";
import { getShopLookupsEpic } from "./field/getLookups/getLookupsEpic";
import { getShopManufacturesEpic } from "./field/getManufactures/getShopManufacturesEpic";
import { getShopModelsEpic } from "./field/getModels/getShopModelEpic";
import { getShopEquipmentColumnsEpic } from "./field/getShopEquipmentColumns/getShopEquipmentColumnsEpic";
import { updateShopEquipmentEpic } from "./field/updateEquipments/updateShopEquipmentsEpic";
import { updateEquipmentTypeEpic } from "./field/updateEquipmentType/updateEquipmentTypeEpic";
import { updateFilterEpic } from "./field/updateFilter/updateFilterEpic";
import { updateLocationEpic } from "./field/updateLocation/updateLocationEpic";
import { updateShopManufacturesEpic } from "./field/updateManufactures/updateShopManufacturesEpic";
import { updateShopModelEpic } from "./field/updateModels/updateShopModelsEpic";
import { getAuthorizationEpic } from "./getAuthorization/getAuthorizationEpic";
import { getLookupsEpic } from "./getLookups/getLookupsEpic";
import { getTokenEpic } from "./getToken/getTokenEpic";
import { IAction, IStore } from "./reducers";
import { EpicDependencies } from "./store";
import { validateShopGroupEpic } from "./toolRentals/validateShopGroup/validateShopGroupEpic";
import { getFilterReportEpic } from "./field/getFilterReport/getFilterReportEpic";
import { addFilterPartEpic } from "./field/addFilterPart/addFilterPartEpic";
import { updateFilterPartEpic } from "./field/updateFilterPart/updateFilterPartEpic";
import { getServiceDuesEpic } from "./field/pmSheets/getServiceDues/getServiceDuesEpic";
import { getServiceSectionsEpic } from "./field/pmSheets/getServiceSections/getServiceSectionsEpic";
import { getPMLookupsEpic } from "./field/pmSheets/getPMLookups/getPMLookupsEpic";
import { addServiceDueEpic } from "./field/pmSheets/addServiceDue/addServiceDueEpic";
import { updateServiceDueEpic } from "./field/pmSheets/updateServiceDue/updateServiceDueEpic";
import { addServiceSectionEpic } from "./field/pmSheets/addServiceSection/addServiceSectionEpic";
import { updateServiceSectionEpic } from "./field/pmSheets/updateServiceSection/updateServiceSectionEpic";
import { getServiceSheetReportEpic } from "./field/pmSheets/getServiceSheetReport/getServiceSheetReportEpic";
import { updateRepairLineEpic } from "./field/repairLine/updateRepairLine/updateRepairLineEpic";
import { addRepairLineEpic } from "./field/repairLine/addRepairLine/addRepairLineEpic";
import { getRepairLinesEpic } from "./field/repairLine/getRepairLines/getRepairLinesEpic";
import { getUnitDetailsEpic } from "./field/getUnitDetails/getUnitDetailsEpic";
import { getMoveRequestsEpic } from "./field/getMoveRequests/getMoveRequestsEpic";
import { getDriverEpic } from "./field/getDrivers/getDriversEpic";
import { saveDriverAssignmentEpic } from "./field/saveDriverAssignment/saveDriverAssignmentEpic";
import { approveServiceSheetEpic } from "./field/pmSheets/approveServiceSheet/approveServiceSheetEpic";
import { getMoveRequestEpic } from "./external/getMoveRequest/getMoveRequestEpic";
import { getWorkingSiteLogsEpic } from "./field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsEpic";
import { getFieldLookupsEpic } from "./field/workingSiteLogs/getFieldLookups/getFieldLookupsEpic";
import { addWorkingSiteLogEpic } from "./field/workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogEpic";
import { updateWorkingSiteLogEpic } from "./field/workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogEpic";
import { getOperatorLogsEpic } from "./field/operatorLogs/getOperatorLogs/getOperatorLogsEpic";
import { addOperatorLogEpic } from "./field/operatorLogs/addOperatorLog/addOperatorLogEpic";
import { updateOperatorLogEpic } from "./field/operatorLogs/updateOperatorLog/updateOperatorLogEpic";
import { validateOperatorLoginEpic } from "./field/operatorLogs/validateOperatorLogin/validateOperatorLoginEpic";
import { localStorageTokenEpic } from "./field/operatorLogs/localStorageToken/localStorageTokenEpics";
import { mechanicLocalTokenEpic } from "./field/mechanics/mechanicLocalToken/mechanicLocalTokenEpics";
import { validateMechanicLoginEpic } from "./field/mechanics/validateMechanicLogin/validateMechanicLoginEpic";
import { updateMechanicEpic } from "./field/mechanics/updateMechanic/updateMechanicEpic";
import { getMechanicsEpic } from "./field/mechanics/getMechanics/getMechanicsEpic";
import { addMechanicEpic } from "./field/mechanics/addMechanic/addMechanicEpic";
import { updateRepairLineByMechanicEpic } from "./field/mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicEpic";
import { updateMechanicLogEpic } from "./field/mechanics/updateMechanicLog/updateMechanicLogEpic";
import { addMechanicLogEpic } from "./field/mechanics/addMechanicLog/addMechanicLogEpic";
import { getMechanicLogsByIdEpic } from "./field/mechanics/getMechanicLogsById/getMechanicLogsByIdEpic";
import { flushDataEpic } from "./clearRedux/clearReduxEpics";
import { addDriverEpic } from "./field/driver/addDriver/addDriverEpic";
import { getDriversEpic } from "./field/driver/getDrivers/getDriversEpic";
import { updateDriverEpic } from "./field/driver/updateDriver/updateDriverEpic";
import { getWorkingSiteLogVersionHistoryEpic } from "./field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryEpic";
import { getDriverCommunicationLogsEpic } from "./field/getDriverCommunicationLogs/getDriverCommunicationLogsEpic";
import { getServiceTrucksEpic } from "./field/dispatch/getServiceTrucks/getServiceTrucksEpic";
import { addServiceTruckEpic } from "./field/dispatch/addServiceTruck/addServiceTruckEpic";
import { updateServiceTruckEpic } from "./field/dispatch/updateServiceTruck/updateServiceTruckEpic";
import { getDriversAssignmentEpic } from "./field/dispatch/getDriversAssignment/getDriversAssignmentEpic";
import { saveDriversAssignmentEpic } from "./field/dispatch/saveDriversAssignment/saveDriversAssignmentEpic";
import { getSwampersEpic } from "./field/dispatch/getSwampers/getSwampersEpic";
import { addSwamperEpic } from "./field/dispatch/addSwamper/addSwamperEpic";
import { updateSwamperEpic } from "./field/dispatch/updateSwamper/updateSwamperEpic";
import { getDriversAssignmentHistoryEpic } from "./field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryEpic";
import { getMechanicLogsEpic } from "./field/repairLine/getMechanicLogs/getMechanicLogsEpic";
import { getEquipmentMoveRequestEpic } from "./field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestEpic";
import { getMappingViewEpic } from "./field/equipmentTracker/getMappingView/getMappingViewEpic";
import { initiateMoveEpic } from "./field/equipmentTracker/initiateMove/initiateMoveEpic";
import { getETLookupsEpic } from "./field/equipmentTracker/getETLookups/getETLookupsEpic";
import { getServiceTruckCommunicationLogsEpic } from "./field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsEpic";
import { getWorkingSiteLogsOnCallEpic } from "./field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallEpic";
import { updateWorkingSiteLogOnCallEpic } from "./field/workingSiteLogs/updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallEpic";
import { updateExternalUserEpic } from "./externalUser/updateExternalUser/updateExternalUserEpic";
import { getExternalUsersEpic } from "./externalUser/getExternalUsers/getExternalUsersEpic";
import { addExternalUserEpic } from "./externalUser/addExternalUser/addExternalUserEpic";
import { getSoctListEpic } from "./field/soct/getSoctList/getSoctListEpic";
import { getSoctReportEpic } from "./field/soct/getSoctReport/getSoctReportEpic";
import { updateSoctReportEpic } from "./field/soct/updateSoctReport/updateSoctReportEpic";
import { getComponentWaitingPartsEpic } from "./field/repairLine/getComponentWaitingParts/getComponentWaitingPartsEpic";
import { saveComponentWaitingPartsEpic } from "./field/repairLine/saveComponentWaitingParts/saveComponentWaitingPartsEpic";
import { saveMechanicAssignmentEpic } from "./field/repairLine/saveMechanicAssignment/saveMechanicAssignmentEpic";
import { approveMechanicLogEpic } from "./field/repairLine/approveMechanicLog/approveMechanicLogEpic";
import { getCompaniesEpic } from "./field/pmSheets/getCompanies/getCompaniesEpic";
import { getSTSchedulesEpic } from "./field/dispatch/getSTSchedules/getSTSchedulesEpic";
import { updateSTSchedulesEpic } from "./field/dispatch/updateSTSchedules/updateSTSchedulesEpic";
import { getDriverAssignmentVersionHistoryEpic } from "./field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryEpic";
import { getAttachmentsEpic } from "./field/attachment/getAttachments/getAttachmentsEpic";
import { getTypesEpic } from "./field/attachment/getTypes/getTypesEpic";
import { getCouplersEpic } from "./field/attachment/getCouplers/getCouplersEpic";
import { addTypeEpic } from "./field/attachment/addType/addTypeEpic";
import { updateTypeEpic } from "./field/attachment/updateType/updateTypeEpic";
import { addCouplerEpic } from "./field/attachment/addCoupler/addCouplerEpic";
import { updateCouplerEpic } from "./field/attachment/updateCoupler/updateCouplerEpic";
import { addAttachmentEpic } from "./field/attachment/addAttachment/addAttachmentEpic";
import { updateAttachmentEpic } from "./field/attachment/updateAttachment/updateAttachmentEpic";
import { getAttachmentVersionHistoryEpic } from "./field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryEpic";
import { getAttachmentMovesEpic } from "./field/attachment/getAttachmentMoves/getAttachmentMovesEpic";
import { addAttachmentMoveEpic } from "./field/attachment/addAttachmentMove/addAttachmentMoveEpic";
import { updateAttachmentMoveEpic } from "./field/attachment/updateAttachmentMove/updateAttachmentMoveEpic";
import { getKantechAdjustmentsEpic } from "./sil/Kantech/getKantechAdjustments/getKantechAdjustmentsEpic";
import { addKantechAdjustmentEpic } from "./sil/Kantech/addKantechAdjustment/addKantechAdjustmentEpic";
import { updateKantechAdjustmentEpic } from "./sil/Kantech/updateKantechAdjustment/updateKantechAdjustmentEpic";
import { addStyleEpic } from "./field/attachment/addStyle/addStyleEpic";
import { updateStyleEpic } from "./field/attachment/updateStyle/updateStyleEpic";
import { getStylesEpic } from "./field/attachment/getStyles/getStylesEpic";
import { getMoveRequestHistoryEpic } from "./field/attachment/getMoveRequestHistory/getMoveRequestHistoryEpic";

import { getIGLookupsEpic } from "./field/imageGallery/getImageGalleryLookups/getImageGalleryEpic";

import { addAttachmentListGETEpic } from "./field/attachment/addAttachmentListGET/addAttachmentListGETEpic";
import { updateAttachmentListGETEpic } from "./field/attachment/updateAttachmentListGET/updateAttachmentListGETEpic";
import { getAttachmentListGETEpic } from "./field/attachment/getAttachmentListGET/getAttachmentListGETEpic";

import { bulkMoveEpic } from "./field/equipmentTracker/bulkMove/bulkMoveEpic";
import { externalUserLogoutEpic } from "./external/externalUserLogOut/externalUserLogOutEpics";
import { deleteFilterPartEpic } from "./field/deleteFilterPart/deleteFilterPartEpic";
import { deleteAttachmentListGETEpic } from "./field/attachment/deleteAttachmentListGET/deleteAttachmentListGETEpic";
import { getRepairLineSummaryEpic } from "./field/repairLine/getRepairLineSummary/getRepairLineSummaryEpic";
import { getRepairLineByMechanicIdEpic } from "./field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdEpic";

import { getRepairLineHistoryEpic } from "./field/repairLine/getRepairLineHistory/getRepairLineHistoryEpic";

import { workOrderEpic } from "./field/repairLine/workOrder/workOrderEpic";
import { cloneAttachmentGETEpic } from "./field/attachment/cloneAttachmentGET/cloneAttachmentGETEpic";
import { getRepairLineWOEpic } from "./field/repairLine/getRepairLineWO/getRepairLineWOEpic";
import { getFilterCutsEpic } from "./field/filterCuts/getFilterCuts/getFilterCutsEpic";
import { updateFilterCutEpic } from "./field/filterCuts/updateFilterCut/updateFilterCutEpic";
import { addFilterCutEpic } from "./field/filterCuts/addFilterCut/addFilterCutEpic";
import { getRepairLineVersionHistoryEpic } from "./field/getRepairLineVersionHistory/getRepairLineVersionHistoryEpic";
import { getRepairLineByIdEpic } from "./field/repairLine/getRepairLineById/getRepairLineByIdEpic";
import { getRepairSmsLogsEpic } from "./field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsEpic";
import { getUnitHistoryByIdEpic } from "./field/getUnitHistoryById/getUnitHistoryByIdEpic";
import { addEquipmentCertificateEpic } from "./field/addEquipmentCertificate/addEquipmentCertificateEpic";
import { getFieldEmployeesEpic } from "./field/workingSiteLogs/getFieldEmployees/getFieldEmployeesEpic";
import { addFieldEmployeeEpic } from "./field/workingSiteLogs/addFieldEmployee/addFieldEmployeeEpic";
import { updateFieldEmployeeEpic } from "./field/workingSiteLogs/updateFieldEmployee/updateFieldEmployeeEpic";

import { getEmployeeMessagesByTokenEpic } from "./external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenEpic";

import { getForemanMessagingEpic } from "./field/workingSiteLogs/getForemanMessaging/getForemanMessagingEpic";
import { addForemanMessagingEpic } from "./field/workingSiteLogs/addForemanMessaging/addForemanMessagingEpic";
import { updateForemanMessagingEpic } from "./field/workingSiteLogs/updateForemanMessaging/updateForemanMessagingEpic";
import { saveEmployeeMessageResponseEpic } from "./external/saveEmployeeMessageResponse/saveEmployeeMessageResponseEpic";
import { getRepairLineWOUnitsEpic } from "./field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsEpic";
import { addRepairLineWOUnitEpic } from "./field/repairLine/addRepairLineWOUnit/addRepairLineWOUnitEpic";
import { updateRepairLineWOUnitEpic } from "./field/repairLine/updateRepairLineWOUnit/updateRepairLineWOUnitEpic";
import { getETMoveAttachmentVersionHistoryEpic } from "./field/getETMoveAttachmentVersionHistory/getETMoveAttachmentVersionHistoryEpic";
import { getPMSheetVersionHistoryEpic } from "./field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryEpic";
import { getPayrollMechanicLogsEpic } from "./field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsEpic";
import { getPayrollMechanicLogsReportEpic } from "./field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportEpic";
import { payrollMechanicLogsUploadEpic } from "./field/repairLine/payrollMechanicLogsUpload/payrollMechanicLogsUploadEpic";
import { getMechanicTimesheetForApprovalEpic } from "./field/repairLine/getMechanicTimeSheets/getMechanicTimesheetForApprovalEpic";
import { saveManagerCommentsForDefectItemEpic } from "./field/repairLine/SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemEpic";
import { getBulkMeterReadingsEpic } from "./field/soct/getBulkMeterReadings/getBulkMeterReadingsEpic";
import { getSOCTLookupsEpic } from "./field/soct/getSOCTLookups/getSOCTLookupsEpic";
import { getMeterReadingByIdEpic } from "./field/soct/getMeterReadingById/getMeterReadingByIdEpic";
import { addMeterReadingEpic } from "./field/soct/addMeterReading/addMeterReadingEpic";
import { updateMeterReadingEpic } from "./field/soct/updateMeterReading/updateMeterReadingEpic";
import { getRepairLineItemByIdEpic } from "./field/repairLine/getRepairLineItemById/getRepairLineItemByIdEpic";
import { saveRepairLineItemEpic } from "./field/repairLine/saveRepairLineItem/saveRepairLineItemEpic";
import { getMechanicTimesheetForApprovalGroupByDateEpic } from "./field/repairLine/getMechanicTimesheetForApprovalGroupByDate/getMechanicTimesheetForApprovalGroupByDateEpic";
import { getMechanicLogsByDefectIDEpic } from "./field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDEpic";
import { getSOCTByIdEpic } from "./field/soct/getSOCTById/getSOCTByIdEpic";
import { getMeterHistoryBySOCTIDEpic } from "./field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDEpic";
import { addSOCTEpic } from "./field/soct/addSOCT/addSOCTEpic";
import { updateSOCTEpic } from "./field/soct/updateSOCT/updateSOCTEpic";
import { getServiceHistoryBySOCTIDEpic } from "./field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDEpic";
import { getScheduleServiceHistoryByIdEpic } from "./field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdEpic";
import { addScheduleServiceHistoryEpic } from "./field/soct/addScheduleServiceHistory/addScheduleServiceHistoryEpic";
import { updateScheduleServiceHistoryEpic } from "./field/soct/updateScheduleServiceHistory/updateScheduleServiceHistoryEpic";
import { getRepairLineDefectItemsByRepairIDEpic } from "./field/repairLine/getRepairLineDefectItemsByRepairID/getRepairLineDefectItemsByRepairIDEpic";
import { getTechServicesEpic } from "./field/repairLine/getTechServices/getTechServicesEpic";
import { addTechServiceEpic } from "./field/repairLine/addTechService/addTechServiceEpic";
import { updateTechServiceEpic } from "./field/repairLine/updateTechService/updateTechServiceEpic";
import { getTechServicesByUnitIDEpic } from "./field/repairLine/getTechServicesByUnitID/getTechServicesByUnitIDEpic";
import { rejectMechanicLogEpic } from "./field/repairLine/rejectMechanicLog/rejectMechanicLogEpic";
import { getEquipmentManualsEpic } from "./field/equipmentManuals/getEquipmentManuals/getEquipmentManualsEpic";
import { addEquipmentManualEpic } from "./field/equipmentManuals/addEquipmentManual/addEquipmentManualEpic";
import { updateEquipmentManualEpic } from "./field/equipmentManuals/updateEquipmentManual/updateEquipmentManualEpic";
import { acknowledgeLogByPayrollEpic } from "./field/repairLine/acknowledgeLogByPayroll/acknowledgeLogByPayrollEpic";
import { getPayrollMechanicLogsSummaryEpic } from "./field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryEpic";
import { getMechanicTimecardVersionHistoryEpic } from "./field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryEpic";
import { getUnitUtilizationByIDEpic } from "./field/getUnitUtilizationByID/getUnitUtilizationByIDEpic";
import { getWorkingSiteLogByIdEpic } from "./field/workingSiteLogs/getWorkingSiteLogById/getWorkingSiteLogByIdEpic";
import { getWSSLogsEpic } from "./field/workingSiteLogs/getWSSLogs/getWSSLogsEpic";
import { getPMSheetReportByIdEpic } from "./field/pmSheets/getPMSheetReportById/getPMSheetReportByIdEpic";
import { getPMSheetsReportEpic } from "./field/pmSheets/getPMSheetsReport/getPMSheetsReportEpic";
import { getWSSLogsHistoryBySiteIDEpic } from "./field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDEpic";
import { getAttachmentssEpic } from "./field/attachment/getAttachmentss/getAttachmentssEpic";
import { getAttachmentByIDEpic } from "./field/attachment/getAttachmentByID/getAttachmentByIDEpic";
import { getSOCTCustomServiceItemsBySOCTIDEpic } from "./field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDEpic";
import { getEquipmentByIDEpic } from "./field/getEquipmentByID/getEquipmentByIDEpic";
import { getEquipmentEpic } from "./field/getEquipment/getEquipmentEpic";
import { getFormsEpic } from "./field/getForms/getFormsEpic";
import { getFormLookupEpic } from "./field/getFormLookup/getFormLookupEpic";
import { addFormTemplateEpic } from "./field/addFormTemplate/addFormTemplateEpic";
import { updateFormTemplateEpic } from "./field/updateFormTemplate/updateFormTemplateEpic";
import { getFormByTemplateIDEpic } from "./field/getFormByTemplateID/getFormByTemplateIDEpic";
import { addFormResponseEpic } from "./field/addFormResponse/addFormResponseEpic";
import { updateFormResponseEpic } from "./field/updateFormResponse/updateFormResponseEpic";
import { getFormResponsesEpic } from "./field/getFormResponses/getFormResponsesEpic";
import { getFormResponseByIDEpic } from "./field/getFormResponseByID/getFormResponseByIDEpic";
import { getWSSLogInfoByIDEpic } from "./field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDEpic";
import { getCrewsEpic } from "./field/getCrews/getCrewsEpic";
import { addCrewEpic } from "./field/addCrew/addCrewEpic";
import { updateCrewEpic } from "./field/updateCrew/updateCrewEpic";
import { getTasksEpic } from "./field/getTasks/getTasksEpic";
import { addTaskEpic } from "./field/addTask/addTaskEpic";
import { updateTaskEpic } from "./field/updateTask/updateTaskEpic";
import { getHazardTypesEpic } from "./field/getHazardTypes/getHazardTypesEpic";
import { addHazardTypeEpic } from "./field/addHazardType/addHazardTypeEpic";
import { updateHazardTypeEpic } from "./field/updateHazardType/updateHazardTypeEpic";
import { getControlPlansEpic } from "./field/getControlPlans/getControlPlansEpic";
import { addControlPlanEpic } from "./field/addControlPlan/addControlPlanEpic";
import { updateControlPlanEpic } from "./field/updateControlPlan/updateControlPlanEpic";
import { getHazardsEpic } from "./field/getHazards/getHazardsEpic";
import { addHazardEpic } from "./field/addHazard/addHazardEpic";
import { updateHazardEpic } from "./field/updateHazard/updateHazardEpic";

import { getVendorOrderEpic } from "./field/vendors/getVendorOrders/getVendorOrdersEpic";
import { addVendorOrderEpic } from "./field/vendors/addVendorOrder/addVendorOrderEpic";
import { updateVendorOrderEpic } from "./field/vendors/updateVendorOrder/updateVendorOrderEpic";
import { getVendorOrderByIDEpic } from "./field/vendors/getVendorOrderByID/getVendorOrderByIDEpic";
import { getVendorLookupsEpic } from "./field/vendors/getVendorLookups/getVendorLookupsEpic";

import { getFormSignatureHistoryEpic } from "./field/getFormSignatureHistory/getFormSignatureHistoryEpic";
import { getMedicalFacilitiesEpic } from "./field/getMedicalFacilities/getMedicalFacilitiesEpic";
import { addMedicalFacilityEpic } from "./field/addMedicalFacility/addMedicalFacilityEpic";
import { updateMedicalFacilityEpic } from "./field/updateMedicalFacility/updateMedicalFacilityEpic";
import { getFormResponseVersionHistoryByIDEpic } from "./field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDEpic";
import { saveAcknowledgeResponseEpic } from "./field/saveAcknowledgeResponse/saveAcknowledgeResponseEpic";
import { getDepartmentsEpic } from "./field/getDepartments/getDepartmentsEpic";
import { addDepartmentEpic } from "./field/addDepartment/addDepartmentEpic";
import { updateDepartmentEpic } from "./field/updateDepartment/updateDepartmentEpic";
import { updateMachineReceiverEpic } from "./survey/updateMachineReceiver/updateMachineReceiverEpic";
import { updateMachineControlBoxEpic } from "./survey/updateMachineControlBox/updateMachineControlBoxEpic";
import { getMachineControlBoxesEpic } from "./survey/getMachineControlBoxes/getMachineControlBoxesEpic";
import { getMachineControlEpic } from "./survey/getMachineControls/getMachineControlsEpic";
import { getMachineReceiverEpic } from "./survey/getMachineReceivers/getMachineReceiversEpic";
import { getSurveyLookupEpic } from "./survey/getSurveyLookup/getSurveyLookupEpic";
import { updateMachineControlEpic } from "./survey/updateMachineControl/updateMachineControlEpic";
import { addMachineReceiverEpic } from "./survey/addMachineReceiver/addMachineReceiverEpic";
import { addMachineControlBoxEpic } from "./survey/addMachineControlBox/addMachineControlBoxEpic";
import { addMachineControlEpic } from "./survey/addMachineControl/addMachineControlEpic";
import { getHazardControlPlanListByDepartmentIDEpic } from "./field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDEpic";
import { addHazardNewEpic } from "./field/addHazardNew/addHazardNewEpic";
import { getHazardByIDEpic } from "./field/getHazardByID/getHazardByIDEpic";
import { updateHazardNewEpic } from "./field/updateHazardNew/updateHazardNewEpic";
import { getBaseKitEpic } from "./survey/getBaseKits/getBaseKitsEpic";
import { getRoverKitEpic } from "./survey/getRoverKits/getRoverKitsEpic";
import { getRepeaterKitEpic } from "./survey/getRepeaterKits/getRepeaterKitsEpic";
import { updateBaseKitEpic } from "./survey/updateBaseKit/updateBaseKitEpic";
import { updateRoverKitEpic } from "./survey/updateRoverKit/updateRoverKitEpic";
import { updateRepeaterKitEpic } from "./survey/updateRepeaterKit/updateRepeaterKitEpic";
import { addBaseKitEpic } from "./survey/addBaseKit/addBaseKitEpic";
import { addRoverKitEpic } from "./survey/addRoverKit/addRoverKitEpic";
import { addRepeaterKitEpic } from "./survey/addRepeaterKit/addRepeaterKitEpic";
import { getLasersEpic } from "./survey/getLasers/getLasersEpic";
import { getLevelsEpic } from "./survey/getLevels/getLevelsEpic";
import { addLaserEpic } from "./survey/addLaser/addLaserEpic";
import { addLevelEpic } from "./survey/addLevel/addLevelEpic";
import { updateLevelEpic } from "./survey/updateLevel/updateLevelEpic";
import { updateLaserEpic } from "./survey/updateLaser/updateLaserEpic";
import { getUnitInspectionFormByIDEpic } from "./field/getUnitInspectionFormByID/getUnitInspectionFormByIDEpic";
import { saveInspectionSheetEpic } from "./field/saveInspectionSheet/saveInspectionSheetEpic";
import { getUnitInspectionSheetsEpic } from "./field/getUnitInspectionSheets/getUnitInspectionSheetsEpic";
import { getUnitInspectionSheetByIDEpic } from "./field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDEpic";
import { getSurveyAttachmentsEpic } from "./survey/getSurveyAttachment/getSurveyAttachmentsEpic";
import { addSurveyAttachmentEpic } from "./survey/addSurveyAttachment/addSurveyAttachmentEpic";
import { updateSurveyAttachmentEpic } from "./survey/updateSurveyAttachment/updateSurveyAttachmentEpic";
import { getCostCodesByJobIDEpic } from "./field/getCostCodesByJobID/getCostCodesByJobIDEpic";
import { getVendorItemsByOrderIDEpic } from "./field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDEpic";
import { getFormResponseByUserTokenEpic } from "./external/getFormResponseByUserToken/getFormResponseByUserTokenEpic";
import { saveCrewSignatureByIDEpic } from "./external/saveCrewSignatureByID/saveCrewSignatureByIDEpic";
import { getJournelsEpic } from "./field/workingSiteLogs/getJournels/getJournelsEpic";
import { addJournelEpic } from "./field/workingSiteLogs/addJournel/addJournelEpic";
import { updateJournelEpic } from "./field/workingSiteLogs/updateJournel/updateJournelEpic";
import { getVendorPOLogsEpic } from "./field/vendors/getVendorPOLogs/getVendorPOLogsEpic";
import { saveVendorPOLogEpic } from "./field/vendors/saveVendorPOLog/saveVendorPOLogEpic";
import { getVendorPOLogByIDEpic } from "./field/vendors/getVendorPOLogByID/getVendorPOLogByIDEpic";
import { getCrewByUsernameEpic } from "./field/workingSiteLogs/getCrewByUsername/getCrewByUsernameEpic";
import { getFormVersionHistoryEpic } from "./field/getFormVersionHistory/getFormVersionHistoryEpic";
import { getJournalVersionHistoryEpic } from "./field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryEpic";
import { getCrewVersionHistoryEpic } from "./field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryEpic";
import { getCleanupDataEpic } from "./field/getCleanupData/getCleanupDataEpic";
import { removeDepartmentFromItemsEpic } from "./field/removeDepartmentFromItems/removeDepartmentFromItemsEpic";
import { getControlPlansNewEpic } from "./field/getControlPlansNew/getControlPlansNewEpic";
import { saveControlPlanEpic } from "./field/saveControlPlan/saveControlPlanEpic";
import { getSTTimesheetForApprovalGroupByDateEpic } from "./field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateEpic";
import { getSTLogsEpic } from "./field/dispatch/getSTLogs/getSTLogsEpic";
import { approveSTTimesheetEpic } from "./field/dispatch/approveSTTimesheet/approveSTTimesheetEpic";
import { rejectSTTimesheetEpic } from "./field/dispatch/rejectSTTimesheet/rejectSTTimesheetEpic";




export type IEpic = Epic<IAction, IAction, IStore, EpicDependencies>;

export default combineEpics(
    flushDataEpic,
    externalUserLogoutEpic,
    getTokenEpic,
    getAuthorizationEpic,
    getJobsEpic,
    addJobEpic,
    updateJobEpic,
    getLookupsEpic,
    getJobsPitsEpic,
    addJobPitEpic,
    updateJobPitEpic,
    updateJobMhrEpic,
    getJobsMHREpic,
    addJobMhrEpic,
    getShopManufacturesEpic,
    addShopManufacturesEpic,
    updateShopManufacturesEpic,
    getShopLookupsEpic,
    getShopModelsEpic,
    addShopModelEpic,
    updateShopModelEpic,
    getShopEquipmentsEpic,
    getEquipmentEpic,
    getEquipmentByIDEpic,
    addShopEquipmentEpic,
    updateShopEquipmentEpic,
    getEquipmentTypesEpic,
    addEquipmentTypeEpic,
    updateEquipmentTypeEpic,
    getShopEquipmentColumnsEpic,
    assignEquipmentTypeColumnEpic,
    getEquipmentHistoryColumnsEpic,
    getEquipmentVersionHistoryEpic,
    validateShopGroupEpic,
    getFilterEpic,
    addFilterEpic,
    updateFilterEpic,
    getLocationEpic,
    addLocationEpic,
    updateLocationEpic,
    getFilterReportEpic,
    addFilterPartEpic,
    updateFilterPartEpic,
    getServiceDuesEpic,
    getServiceSectionsEpic,
    getPMLookupsEpic,
    addServiceDueEpic,
    updateServiceDueEpic,
    addServiceSectionEpic,
    updateServiceSectionEpic,
    getServiceSheetReportEpic,
    updateRepairLineEpic,
    addRepairLineEpic,
    getRepairLinesEpic,
    getRepairLineVersionHistoryEpic,
    getUnitDetailsEpic,
    getMoveRequestsEpic,
    getDriverEpic,
    saveDriverAssignmentEpic,
    approveServiceSheetEpic,
    getMoveRequestEpic,
    getWorkingSiteLogsEpic,
    getFieldLookupsEpic,
    addWorkingSiteLogEpic,
    updateWorkingSiteLogEpic,
    getOperatorLogsEpic,
    addOperatorLogEpic,
    updateOperatorLogEpic,
    validateOperatorLoginEpic,
    localStorageTokenEpic,
    mechanicLocalTokenEpic,
    validateMechanicLoginEpic,
    updateMechanicEpic,
    getMechanicsEpic,
    addMechanicEpic,
    updateRepairLineByMechanicEpic,
    updateMechanicLogEpic,
    addMechanicLogEpic,
    getMechanicLogsByIdEpic,
    addDriverEpic,
    getDriversEpic,
    updateDriverEpic,
    getWorkingSiteLogVersionHistoryEpic,
    getDriverCommunicationLogsEpic,
    getServiceTrucksEpic,
    addServiceTruckEpic,
    updateServiceTruckEpic,
    getDriversAssignmentEpic,
    saveDriversAssignmentEpic,
    getSwampersEpic,
    addSwamperEpic,
    updateSwamperEpic,
    getDriversAssignmentHistoryEpic,
    getMechanicLogsEpic,
    getEquipmentMoveRequestEpic,
    getMappingViewEpic,
    initiateMoveEpic,
    getETLookupsEpic,
    getServiceTruckCommunicationLogsEpic,
    getWorkingSiteLogsOnCallEpic,
    updateWorkingSiteLogOnCallEpic,
    getFieldEmployeesEpic,
    addFieldEmployeeEpic,
    updateFieldEmployeeEpic,
    getCrewByUsernameEpic,
    getForemanMessagingEpic,
    addForemanMessagingEpic,
    updateForemanMessagingEpic,
    getExternalUsersEpic,
    addExternalUserEpic,
    updateExternalUserEpic,
    getSoctListEpic,
    getSoctReportEpic,
    updateSoctReportEpic,
    getComponentWaitingPartsEpic,
    saveComponentWaitingPartsEpic,
    saveMechanicAssignmentEpic,
    approveMechanicLogEpic,
    getCompaniesEpic,
    getSTSchedulesEpic,
    updateSTSchedulesEpic,
    getDriverAssignmentVersionHistoryEpic,
    getAttachmentsEpic,
    getAttachmentssEpic,
    getAttachmentByIDEpic,
    getTypesEpic,
    getCouplersEpic,
    addTypeEpic,
    updateTypeEpic,
    addCouplerEpic,
    updateCouplerEpic,
    addAttachmentEpic,
    updateAttachmentEpic,
    getAttachmentVersionHistoryEpic,
    getAttachmentMovesEpic,
    addAttachmentMoveEpic,
    updateAttachmentMoveEpic,
    getKantechAdjustmentsEpic,
    addKantechAdjustmentEpic,
    updateKantechAdjustmentEpic,
    addStyleEpic,
    updateStyleEpic,
    getStylesEpic,
    getMoveRequestHistoryEpic,
    getIGLookupsEpic,
    getAttachmentListGETEpic,
    addAttachmentListGETEpic,
    updateAttachmentListGETEpic,
    bulkMoveEpic,
    deleteFilterPartEpic,
    deleteAttachmentListGETEpic,
    getRepairLineSummaryEpic,
    getRepairLineByMechanicIdEpic,
    getRepairLineHistoryEpic,
    workOrderEpic,
    cloneAttachmentGETEpic,
    getRepairLineWOEpic,
    getFilterCutsEpic,
    addFilterCutEpic,
    updateFilterCutEpic,
    getRepairLineByIdEpic,
    getRepairLineItemByIdEpic,
    saveRepairLineItemEpic,
    getRepairSmsLogsEpic,
    getUnitHistoryByIdEpic,
    addEquipmentCertificateEpic,
    getEmployeeMessagesByTokenEpic,
    saveEmployeeMessageResponseEpic,
    getRepairLineWOUnitsEpic,
    addRepairLineWOUnitEpic,
    updateRepairLineWOUnitEpic,
    getETMoveAttachmentVersionHistoryEpic,
    getPMSheetVersionHistoryEpic,
    getPayrollMechanicLogsEpic,
    getPayrollMechanicLogsReportEpic,
    getPayrollMechanicLogsSummaryEpic,
    payrollMechanicLogsUploadEpic,
    getMechanicTimesheetForApprovalEpic,
    saveManagerCommentsForDefectItemEpic,
    getBulkMeterReadingsEpic,
    getSOCTLookupsEpic,
    getMeterReadingByIdEpic,
    addMeterReadingEpic,
    updateMeterReadingEpic,
    getMechanicTimesheetForApprovalGroupByDateEpic,
    getMechanicLogsByDefectIDEpic,
    getSOCTByIdEpic,
    getMeterHistoryBySOCTIDEpic,
    addSOCTEpic,
    updateSOCTEpic,
    getServiceHistoryBySOCTIDEpic,
    getScheduleServiceHistoryByIdEpic,
    addScheduleServiceHistoryEpic,
    updateScheduleServiceHistoryEpic,
    getRepairLineDefectItemsByRepairIDEpic,
    getTechServicesEpic,
    addTechServiceEpic,
    updateTechServiceEpic,
    getTechServicesByUnitIDEpic,
    rejectMechanicLogEpic,
    getEquipmentManualsEpic,
    addEquipmentManualEpic,
    updateEquipmentManualEpic,
    acknowledgeLogByPayrollEpic,
    getMechanicTimecardVersionHistoryEpic,
    getUnitUtilizationByIDEpic,
    getWorkingSiteLogByIdEpic,
    getWSSLogsEpic,
    getPMSheetReportByIdEpic,
    getPMSheetsReportEpic,
    getWSSLogsHistoryBySiteIDEpic,
    getSOCTCustomServiceItemsBySOCTIDEpic,
    getFormsEpic,
    getFormLookupEpic,
    addFormTemplateEpic,
    updateFormTemplateEpic,
    getFormByTemplateIDEpic,
    getFormResponsesEpic,
    getFormResponseByIDEpic,
    addFormResponseEpic,
    updateFormResponseEpic,
    getWSSLogInfoByIDEpic,
    getCrewsEpic,
    addCrewEpic,
    updateCrewEpic,
    getTasksEpic,
    addTaskEpic,
    updateTaskEpic,
    getHazardTypesEpic,
    addHazardTypeEpic,
    updateHazardTypeEpic,
    getControlPlansEpic,
    getControlPlansNewEpic,
    addControlPlanEpic,
    saveControlPlanEpic,
    updateControlPlanEpic,
    getHazardsEpic,
    addHazardEpic,
    updateHazardEpic,

    addVendorOrderEpic,
    updateVendorOrderEpic,
    getVendorOrderEpic,
    getVendorItemsByOrderIDEpic,
    getVendorOrderByIDEpic,
    getVendorLookupsEpic,
    getCostCodesByJobIDEpic,
    getVendorPOLogsEpic,
    getVendorPOLogByIDEpic,
    saveVendorPOLogEpic,

    getFormSignatureHistoryEpic,
    getMedicalFacilitiesEpic,
    addMedicalFacilityEpic,
    updateMedicalFacilityEpic,
    getFormResponseVersionHistoryByIDEpic,
    getFormVersionHistoryEpic,
    saveAcknowledgeResponseEpic,
    getDepartmentsEpic,
    addDepartmentEpic,
    updateDepartmentEpic,
    getMachineControlBoxesEpic,
    getMachineControlEpic,
    getMachineReceiverEpic,
    getSurveyLookupEpic,
    updateMachineControlEpic,
    updateMachineControlBoxEpic,
    updateMachineReceiverEpic,
    addMachineReceiverEpic,
    addMachineControlBoxEpic,
    addMachineControlEpic,
    getHazardControlPlanListByDepartmentIDEpic,
    getHazardByIDEpic,
    addHazardNewEpic,
    updateHazardNewEpic,
    getBaseKitEpic,
    getRoverKitEpic,
    getRepeaterKitEpic,
    updateBaseKitEpic,
    updateRoverKitEpic,
    updateRepeaterKitEpic,
    addBaseKitEpic,
    addRoverKitEpic,
    addRepeaterKitEpic,
    getLasersEpic,
    getLevelsEpic,
    addLaserEpic,
    addLevelEpic,
    updateLaserEpic,
    updateLevelEpic,
    getUnitInspectionSheetsEpic,
    getUnitInspectionFormByIDEpic,
    getUnitInspectionSheetByIDEpic,
    saveInspectionSheetEpic,
    getSurveyAttachmentsEpic,
    addSurveyAttachmentEpic,
    updateSurveyAttachmentEpic,

    getFormResponseByUserTokenEpic,
    saveCrewSignatureByIDEpic,
    getJournelsEpic,
    addJournelEpic,
    updateJournelEpic,
    getJournalVersionHistoryEpic,
    getCrewVersionHistoryEpic,
    getCleanupDataEpic,
    removeDepartmentFromItemsEpic,

    getSTTimesheetForApprovalGroupByDateEpic,
    getSTLogsEpic,
    approveSTTimesheetEpic,
    rejectSTTimesheetEpic
);