
export enum IGET_MACHINE_CONTROL_REQUEST {
    REQUEST = "getMachineControl/GET_MACHINE_CONTROL_REQUEST",
    SUCCESS = "getMachineControl/GET_MACHINE_CONTROL_SUCCESS",
    FAILED = "getMachineControl/GET_MACHINE_CONTROL_FAILED"
};

export interface IMachineControlRequest {
    token: string;
};

export interface IMachineControl {
    id: number;
    unit_ID?: number;
    unit_No?: string;
    site?: string;
    equipment_Type?: string;
    make?: string;
    model?: string;
    gps_make?: string;
    status?: string;
    control_Box_ID?: number;
    receiver_ID_1?: number;
    receiver_ID_2?: number;
    details: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    controlBox: string;
    receiver_1: string;
    receiver_2: string;
};