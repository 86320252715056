import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_DRIVER_REQUEST, IDriver } from "./getDriversConstants";
import { IGetDriverLoadAction, IGetDriverLoadFailedAction, IGetDriverSuccessAction } from "./getDriversActions";
import { ISaveDriverAssignmentSuccessAction } from "../saveDriverAssignment/saveDriverAssignmentActions";
import { ISAVE_DRIVER_ASSIGNMENT_REQUEST } from "../saveDriverAssignment/saveDriverAssignmentConstants";

type Actions =
    | IGetDriverLoadAction
    | IGetDriverSuccessAction
    | IGetDriverLoadFailedAction
    | ISaveDriverAssignmentSuccessAction
    | IFlushDataSuccessAction;

export const GetDriverReducer = (state: Server<SurewayAPIResponse<ById<IDriver>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IDriver>>> => {
    switch (action.type) {
        case IGET_DRIVER_REQUEST.REQUEST:
            return loading;

        case IGET_DRIVER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DRIVER_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_DRIVER_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};