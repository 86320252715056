import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IGetLookupsLoadAction, IGetLookupsLoadFailedAction, IGetLookupsSuccessAction } from "./getLookupsActions";
import { IGET_LOOKUPS_REQUEST, IShopLookups } from "./getLookupsConstants";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IAddEquipmentTypeSuccessAction } from "../addEquipmentType/addEquipmentTypeActions";
import { IAddShopManufacturesSuccessAction } from "../addManufactures/addShopManufacturesActions";
import { IAssignEquipmentTypeColumnSuccessAction } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnActions";
import { IUpdateEquipmentTypeSuccessAction } from "../updateEquipmentType/updateEquipmentTypeActions";
import { IUpdateShopManufacturesSuccessAction } from "../updateManufactures/updateShopManufacturesActions";
import { IADD_EQUIPMENT_TYPE_REQUEST } from "../addEquipmentType/addEquipmentTypeConstants";
import { IADD_SHOP_MANUFACTURERS_REQUEST } from "../addManufactures/addShopManufacturesConstants";
import { IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnConstants";
import { IUPDATE_EQUIPMENT_TYPE_REQUEST } from "../updateEquipmentType/updateEquipmentTypeConstants";
import { IUPDATE_SHOP_MANUFACTURERS_REQUEST } from "../updateManufactures/updateShopManufacturesConstants";
import { IAddAttachmentCouplerSuccessAction } from "../attachment/addCoupler/addCouplerActions";
import { IADD_ATTACHMENT_COUPLER_REQUEST } from "../attachment/addCoupler/addCouplerConstants";
import { IAddAttachmentStyleSuccessAction } from "../attachment/addStyle/addStyleActions";
import { IADD_ATTACHMENT_STYLE_REQUEST } from "../attachment/addStyle/addStyleConstants";
import { IAddAttachmentTypeSuccessAction } from "../attachment/addType/addTypeActions";
import { IADD_ATTACHMENT_TYPE_REQUEST } from "../attachment/addType/addTypeConstants";
import { IUpdateAttachmentCouplerSuccessAction } from "../attachment/updateCoupler/updateCouplerActions";
import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "../attachment/updateCoupler/updateCouplerConstants";
import { IUpdateAttachmentStyleSuccessAction } from "../attachment/updateStyle/updateStyleActions";
import { IUPDATE_ATTACHMENT_STYLE_REQUEST } from "../attachment/updateStyle/updateStyleConstants";
import { IUpdateAttachmentTypeSuccessAction } from "../attachment/updateType/updateTypeActions";
import { IUPDATE_ATTACHMENT_TYPE_REQUEST } from "../attachment/updateType/updateTypeConstants";

type Actions =
    | IGetLookupsLoadAction
    | IGetLookupsSuccessAction
    | IGetLookupsLoadFailedAction
    | IAssignEquipmentTypeColumnSuccessAction
    | IUpdateShopManufacturesSuccessAction
    | IAddShopManufacturesSuccessAction
    | IUpdateEquipmentTypeSuccessAction
    | IAddEquipmentTypeSuccessAction
    | IUpdateAttachmentTypeSuccessAction
    | IUpdateAttachmentStyleSuccessAction
    | IUpdateAttachmentCouplerSuccessAction
    | IAddAttachmentTypeSuccessAction
    | IAddAttachmentStyleSuccessAction
    | IAddAttachmentCouplerSuccessAction
    | IFlushDataSuccessAction;

export const GetShopLookupsReducer = (state: Server<SurewayAPIResponse<IShopLookups>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IShopLookups>> => {
    switch (action.type) {
        case IGET_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};