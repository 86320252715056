import React from 'react';

  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, Radio, RadioGroup, FormControl, FormControlLabel} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LAPopover } from '../../shared/popOver';
import { LADevExtremeGrid } from '../../shared/devExtreme';
import { getSOCTMeterAppHistoryByID } from './api/meterreading/meterReadingAPI';

interface IState {
    moveRequests:any;
  open:boolean;
  showEditForm:boolean;
  view:string;
  showAddNewForm:boolean;
}
interface IProps{
  id:number;
  onCancel:any;
  open:boolean;
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
function StyledStatusComponent(cellData:any):any {
  if(cellData.column.dataField === 'status'){
      switch(cellData.data.status){
          case 'Initiated':
              return <div style={{backgroundColor:'rgb(146, 218, 150)', padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Completed':
              return <div style={{backgroundColor:'rgb(236, 176, 65)',padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Cancelled':
            return <div style={{backgroundColor:'rgb(205, 92, 92)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Moved':
            return <div style={{backgroundColor:'rgb(189, 220, 255)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
            case 'Requested':
            return <div style={{backgroundColor:'#fad296',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
            case 'Rejected':
            return <div style={{backgroundColor:'rgb(205, 92, 92)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
      }
  }
  // else{
  // /* <div className={cellData.data.email === 'admin.test@gmail.com' ? "devex-styled-grid-row-green": cellData.data.email ==='dylan.ollikka@sil.ab.ca' ? "devex-styled-grid-row-red":"devex-styled-grid-row-orange"}>{cellData.value}</div> */
  //     return (
  //         <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
  //     )
  // }
}

var globalEditID:Number = 0;
var renderPageType:string = '';
export default class MeterAppVersionHistory extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={moveRequests:[], open:false, showEditForm:false, view:'all', showAddNewForm:false};
    this.setOpen = this.setOpen.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
  }
  addNewEquipmentMove(){
    this.setState({showAddNewForm:true});
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  async refreshGrid(){
    var res:any = await getSOCTMeterAppHistoryByID(this.props.id);
    console.log(res, this.props.id);
    this.setState({moveRequests:res});
    // let temp_request:any[] = [];
    // var move_requests = await getMoveRequestVersionHistory(Number(this.props.id));
    // console.log(move_requests)
    // temp_request = move_requests;
    // copy_list = temp_request;
    // this.setState({moveRequests:temp_request});
  }
  async componentDidMount(){
    this.refreshGrid();
  }
    render(){
      return (
       <LAPopover anchorRef={null} open={this.props.open} onClose={this.props.onCancel}> 
            <Card className='dataGrid-card' variant="outlined" >
          <Typography className='dataGrid-title'>Version History</Typography>
          <LADevExtremeGrid
          onClick={this.addNewEquipmentMove}
            data={this.state.moveRequests}
            searchPanel={true}
            filterHeader={true}
            export={true}
            actionWidth={50}
            removeStyleBtn={true}
            id="soctMeterAppVersionHistory"
            customRowColor={true}
            exportFileName="soct_meter_app_version_history"   
            columns={[
                       { name: "unit_Number", caption: "Unit Number"},
                       { name: "previous_Reading", caption: "Previous Reading"},
                       { name: "previous_Meter_Date", caption: "Previous Meter Date"},
                       { name: "date", caption: "Meter Date", sortAsc:true},
                       { name: "current_Hours", caption: "Current Hours"},
                       { name: "ecm", caption: "ECM"},
                       { name: "previous_ECM_Reading", caption: "previous_ECM_Reading"},
                       { name: "location", caption: "Location"},
                       { name: "sub_Location", caption: "Sub Location"},
                       { name: "approved_Time", caption: "Approved Time"},
                       { name: "captured_Datetime", caption: "Captured Datetime"},
                       { name: "latitude", caption: "Latitude"},
                       { name: "longitude", caption: "Longitude"},
                       { name: "status", caption: "Status"},
                       { name: "comments", caption: "Comments"},
                       { name: "rejection_Comments", caption: "Rejection Comments"},
                       { name: "created", caption: "Created"},
                       { name: "created_By", caption: "Created By"},
                       { name: "modified", caption: "Modified"},
                       { name: "modified_By", caption: "Modified By"},
                ]}
          >

          </LADevExtremeGrid>
          {/* <DataGrid focusedRowEnabled={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
              dataSource={this.state.moveRequests}
              keyExpr="id"
              showBorders={true}
              wordWrapEnabled={true}
              columnAutoWidth={true}
              > 
          <SearchPanel visible={true}
              width={340}
              placeholder="Search for..." />    
            <Paging enabled={true} defaultPageSize={20}/>
            <Pager
              visible={true}
              allowedPageSizes={[20,40,60]}
              displayMode={'full'}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true} />
              <FilterRow visible={true}></FilterRow>
              <FilterPanel visible={true} />
              <Scrolling columnRenderingMode="virtual" />
 <FilterBuilderPopup position={filterBuilderPopupPosition} />
   <HeaderFilter visible={true} allowSearch={true} />
   <FilterRow visible={true}></FilterRow>
    <FilterPanel visible={true} />
    <Export enabled={true} allowExportSelectedData={true} />
      <Column dataField="unit_Number" caption="Unit Number" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="custom_Unit_Number" caption="Custom Unit Number" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="from_Location" caption="From Location" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column calculateCellValue={(e:any) => {if(e.job != null){return e.to_Location + "-" +e.job}else{return e.to_Location}}} caption="To Location" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column sortOrder={"asc"} dataField="completed_Date" dataType="date" caption="Completed Date" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="comments" caption="Request Details" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_Notes" caption="Modified Notes" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="status" caption="Status" alignment="left"  headerCellRender={renderTitleHeader} cellRender={StyledStatusComponent}/>
       <Column dataField="driver_ID" caption="Driver ID" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="trailer_ID" caption="Trailer ID" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="assigned_Tractor_ID" caption="Tractor ID" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="pilot" caption="Pilot" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="city_Permit" caption="City Permit" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column format={"currency"} dataField="city_Permit_Cost" caption="City Permit Cost"  alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="gov_Permit" caption="Gov Permit" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column format={"currency"} dataField="gov_Permit_Cost" caption="Gov Permit Cost"  alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="f_A" caption="FA" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="commodity" caption="Commodity" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column dataField="cancelled" caption="Cancelled" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="drive_Over" caption="Drive Over/Non Billable" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column format={"decimal"} dataField="hours" caption="Hours" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column format={"currency"} dataField="trailer_Rate" caption="Rate" alignment="left"  headerCellRender={renderTitleHeader} />
       <Column format={"currency"} dataField="total_Cost" caption="Total Cost" alignment="left"  headerCellRender={renderTitleHeader} />
       <Column dataField="moved_Previously" caption="Moved Previously" alignment="left"  headerCellRender={renderTitleHeader} />
       <Column dataType="datetime" dataField="created" caption="Created" alignment="left"  headerCellRender={renderTitleHeader} />
       <Column dataType="datetime" dataField="modified" caption="Modified" alignment="left"  headerCellRender={renderTitleHeader}/>
       <Column dataField="created_By" caption="Created By" alignment="left"  headerCellRender={renderTitleHeader} />
       <Column dataField="modified_By" caption="Modified By" alignment="left"  headerCellRender={renderTitleHeader}/>
          </DataGrid> */}
          </Card>
          </LAPopover>
      );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};