import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_OPERATOR_LOGS_REQUEST {
    REQUEST = "getOperatorLogs/GET_OPERATOR_LOGS_REQUEST",
    SUCCESS = "getOperatorLogs/GET_OPERATOR_LOGS_SUCCESS",
    FAILED = "getOperatorLogs/GET_OPERATOR_LOGS_FAILED"
};

export interface IGetOperatorLogsRequest extends ISurewayTokenRequestBody {
    request: {
        email: string;
    };
};

export interface IOperatorLogs {
    id: number;
    date_: string;
    weekends: string;
    name: string;
    project: string;
    job_ID: number;
    foreman: string;
    start_Time: string;
    end_Time: string;
    lunch: string;
    total_Hours: string;
    travel: string;
    loa: string;
    comments: string;
    hurt_Today: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    start_Time_Val: string;
    end_Time_Val: string;
    lunch_Val: string;
    total_Hours_Val: string;
    equipments: IOperatorLogEquipment[];
};

export interface IOperatorLogEquipment {
    id: number;
    operator_Log_ID: number;
    unit_ID: number;
    type: string;
    hours: number;
    loads: number;
    work_Description: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    walkarounds: IOperatorLogWalkRounds[];
};

export interface IOperatorLogWalkRounds {
    id: number;
    operator_Log_Equipment_ID: number;
    operator_Log_Equipment_Walk_Around_ID: number;
    response: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};