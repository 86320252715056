import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FILTER_REPORT_REQUEST, IFilterReport } from "./getFilterReportConstants";
import { IGetFilterReportLoadAction, IGetFilterReportLoadFailedAction, IGetFilterReportSuccessAction, getFilterReportLoadFailedAction, getFilterReportLoadSuccessAction } from "./getFilterReportActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getFilterReportEpic: Epic = (
    action$: ActionsObservable<IGetFilterReportLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFilterReportSuccessAction | IGetFilterReportLoadFailedAction> =>
    action$.ofType(IGET_FILTER_REPORT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IFilterReport>>>(
                    END_POINTS.FIELD.GET_FILTER_REPORT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IFilterReport>>): IGetFilterReportSuccessAction => {
                            return getFilterReportLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFilterReportLoadFailedAction(response.message)))
                    )
            )
        );