import { IDriver } from "./field/getDrivers/getDriversConstants";
import { IUnitDetails } from "./field/getUnitDetails/getUnitDetailsConstants";
import { Reducer, combineReducers, Dispatch } from "redux";
import { ById, SurewayAPIResponse, ToolRentalsResponse } from "../react/shared/publicInterfaces";
import { AddJobReducer } from "./accounting/jobs/addJob/addJobReducer";
import { AddJobMhrReducer } from "./accounting/jobs/addJobMhr/addJobMhrReducer";
import { AddJobPitReducer } from "./accounting/jobs/addJobPit/addJobPitReducer";
import { IJob } from "./accounting/jobs/getJobs/getJobsConstants";
import { GetJobsReducer } from "./accounting/jobs/getJobs/getJobsReducer";
import { IJobMhr } from "./accounting/jobs/getJobsMhr/getJobsMhrConstants";
import { GetJobsMHRReducer } from "./accounting/jobs/getJobsMhr/getJobsMhrReducer";
import { IJobPit } from "./accounting/jobs/getJobsPits/getJobsPitsConstants";
import { GetJobsPitsReducer } from "./accounting/jobs/getJobsPits/getJobsPitsReducer";
import { UpdateJobReducer } from "./accounting/jobs/updateJob/updateJobReducer";
import { UpdateJobMhrReducer } from "./accounting/jobs/updateJobMhr/updateJobMhrReducer";
import { UpdateJobPitReducer } from "./accounting/jobs/updateJobPit/updateJobPitReducer";
import { AddShopEquipmentReducer } from "./field/addEquipments/addShopEquipmentsReducer";
import { AddEquipmentTypeReducer } from "./field/addEquipmentType/addEquipmentTypeReducer";
import { AddFilterReducer } from "./field/addFilter/addFilterReducer";
import { AddLocationReducer } from "./field/addLocation/addLocationReducer";
import { AddShopManufacturersReducer } from "./field/addManufactures/addShopManufacturesReducer";
import { AddShopModelReducer } from "./field/addModels/addShopModelsReducer";
import { AssignEquipmentTypeColumnReducer } from "./field/assignEquipmentTypeColumn/assignEquipmentTypeColumnReducer";
import { IGetEquipmentHistoryColumn } from "./field/getEquipmentHistoryColumn/getEquipmentHistoryColumnConstants";
import { GetEquipmentHistoryColumnsReducer } from "./field/getEquipmentHistoryColumn/getEquipmentHistoryColumnReducer";
import { IShopEquipment } from "./field/getEquipments/getEquipmentConstants";
import { GetShopEquipmentsReducer } from "./field/getEquipments/getEquipmentReducer";
import { IGetEquipmentType } from "./field/getEquipmentTypes/getEquipmentTypesConstants";
import { GetEquipmentTypesReducer } from "./field/getEquipmentTypes/getEquipmentTypesReducer";
import { IGetEquipmentVersionHistory } from "./field/getEquipmentVersionHistory/getEquipmentVersionHistoryConstants";
import { GetEquipmentVersionHistoryReducer } from "./field/getEquipmentVersionHistory/getEquipmentVersionHistoryReducer";
import { IFilter } from "./field/getFilter/getFilterConstants";
import { GetFilterReducer } from "./field/getFilter/getFilterReducer";
import { ILocation } from "./field/getLocation/getLocationConstants";
import { GetLocationReducer } from "./field/getLocation/getLocationReducer";
import { IShopLookups } from "./field/getLookups/getLookupsConstants";
import { GetShopLookupsReducer } from "./field/getLookups/getLookupsReducer";
import { IShopManufacture } from "./field/getManufactures/getShopManufacturesConstants";
import { GetShopManufacturersReducer } from "./field/getManufactures/getShopManufacturesReducer";
import { IShopModel } from "./field/getModels/getShopModelConstants";
import { GetShopModelsReducer } from "./field/getModels/getShopModelReducer";
import { IGetShopEquipmentColumn } from "./field/getShopEquipmentColumns/getShopEquipmentColumnsConstants";
import { GetShopEquipmentColumnsReducer } from "./field/getShopEquipmentColumns/getShopEquipmentColumnsReducer";
import { UpdateShopEquipmentReducer } from "./field/updateEquipments/updateShopEquipmentsReducer";
import { UpdateEquipmentTypeReducer } from "./field/updateEquipmentType/updateEquipmentTypeReducer";
import { UpdateFilterReducer } from "./field/updateFilter/updateFilterReducer";
import { UpdateLocationReducer } from "./field/updateLocation/updateLocationReducer";
import { UpdateShopManufacturersReducer } from "./field/updateManufactures/updateShopManufacturesReducer";
import { UpdateShopModelReducer } from "./field/updateModels/updateShopModelsReducer";
import { IAuthorization } from "./getAuthorization/getAuthorizationConstants";
import { GetAuthorizationReducer } from "./getAuthorization/getAuthorizationReducer";
import { ILookup } from "./getLookups/getLookupsConstants";
import { GetLookupsReducer } from "./getLookups/getLookupsReducer";
import { IToken } from "./getToken/getTokenConstants";
import { GetTokenReducer } from "./getToken/getTokenReducer";
import { Server } from "./server";
import { IValidateShopGroup } from "./toolRentals/validateShopGroup/validateShopGroupConstants";
import { ValidateShopGroupReducer } from "./toolRentals/validateShopGroup/validateShopGroupReducer";
import { GetFilterReportReducer } from "./field/getFilterReport/getFilterReportReducer";
import { IFilterReport } from "./field/getFilterReport/getFilterReportConstants";
import { AddFilterPartReducer } from "./field/addFilterPart/addFilterPartReducer";
import { UpdateFilterPartReducer } from "./field/updateFilterPart/updateFilterPartReducer";
import { IServiceDue } from "./field/pmSheets/getServiceDues/getServiceDuesConstants";
import { GetServiceDuesReducer } from "./field/pmSheets/getServiceDues/getServiceDuesReducer";
import { GetServiceSectionsReducer } from "./field/pmSheets/getServiceSections/getServiceSectionsReducer";
import { IServiceSection } from "./field/pmSheets/getServiceSections/getServiceSectionsConstants";
import { IPMLookups } from "./field/pmSheets/getPMLookups/getPMLookupsConstants";
import { GetPMLookupsReducer } from "./field/pmSheets/getPMLookups/getPMLookupsReducer";
import { AddServiceDueReducer } from "./field/pmSheets/addServiceDue/addServiceDueReducer";
import { UpdateServiceDueReducer } from "./field/pmSheets/updateServiceDue/updateServiceDueReducer";
import { AddServiceSectionReducer } from "./field/pmSheets/addServiceSection/addServiceSectionReducer";
import { UpdateServiceSectionReducer } from "./field/pmSheets/updateServiceSection/updateServiceSectionReducer";
import { IServiceSheetReport } from "./field/pmSheets/getServiceSheetReport/getServiceSheetReportConstants";
import { GetServiceSheetReportReducer } from "./field/pmSheets/getServiceSheetReport/getServiceSheetReportReducer";
import { UpdateRepairLineReducer } from "./field/repairLine/updateRepairLine/updateRepairLineReducer";
import { AddRepairLineReducer } from "./field/repairLine/addRepairLine/addRepairLineReducer";
import { GetRepairLinesReducer } from "./field/repairLine/getRepairLines/getRepairLinesReducer";
import { IRepairLine, IRepairLineItem } from "./field/repairLine/getRepairLines/getRepairLinesConstants";
import { GetUnitDetailsReducer } from "./field/getUnitDetails/getUnitDetailsReducer";
import { GetMoveRequestsReducer } from './field/getMoveRequests/getMoveRequestsReducer';
import { IMoveRequests } from './field/getMoveRequests/getMoveRequestsConstants';
import { GetDriverReducer } from './field/getDrivers/getDriversReducer';
import { SaveDriverAssignmentReducer } from './field/saveDriverAssignment/saveDriverAssignmentReducer';
import { ApproveServiceSheetReducer } from './field/pmSheets/approveServiceSheet/approveServiceSheetReducer';
import { IMoveRequest } from './external/getMoveRequest/getMoveRequestConstants';
import { GetMoveRequestReducer } from './external/getMoveRequest/getMoveRequestReducer';
import { GetWorkingSiteLogsReducer } from './field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsReducer';
import { IWorkingSiteLog } from './field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsConstants';
import { GetFieldLookupsReducer } from './field/workingSiteLogs/getFieldLookups/getFieldLookupsReducer';
import { IFieldLookup } from './field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants';
import { AddWorkingSiteLogReducer } from "./field/workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogReducer";
import { UpdateWorkingSiteLogReducer } from "./field/workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogReducer";
import { IOperatorLogs } from "./field/operatorLogs/getOperatorLogs/getOperatorLogsConstants";
import { UpdateOperatorLogReducer } from "./field/operatorLogs/updateOperatorLog/updateOperatorLogReducer";
import { AddOperatorLogReducer } from "./field/operatorLogs/addOperatorLog/addOperatorLogReducer";
import { GetOperatorLogsReducer } from "./field/operatorLogs/getOperatorLogs/getOperatorLogsReducer";
import { ValidateOperatorLoginReducer } from "./field/operatorLogs/validateOperatorLogin/validateOperatorLoginReducer";
import { IValidateOperatorLogin } from "./field/operatorLogs/validateOperatorLogin/validateOperatorLoginConstants";
import { IMechanic } from "./field/mechanics/getMechanics/getMechanicsConstants";
import { IValidateMechanicLogin } from "./field/mechanics/validateMechanicLogin/validateMechanicLoginConstants";
import { ValidateMechanicLoginReducer } from "./field/mechanics/validateMechanicLogin/validateMechanicLoginReducer";
import { GetMechanicsReducer } from "./field/mechanics/getMechanics/getMechanicsReducer";
import { AddMechanicReducer } from "./field/mechanics/addMechanic/addMechanicReducer";
import { UpdateMechanicReducer } from "./field/mechanics/updateMechanic/updateMechanicReducer";
import { GetMechanicLogsByIdReducer } from "./field/mechanics/getMechanicLogsById/getMechanicLogsByIdReducer";
import { AddMechanicLogReducer } from "./field/mechanics/addMechanicLog/addMechanicLogReducer";
import { UpdateMechanicLogReducer } from "./field/mechanics/updateMechanicLog/updateMechanicLogReducer";
import { UpdateRepairLineByMechanicReducer } from "./field/mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicReducer";
import { IMechanicLogById } from "./field/mechanics/getMechanicLogsById/getMechanicLogsByIdConstants";
import { IETDriver } from "./field/driver/getDrivers/getDriversConstants";
import { GetETDriversReducer } from "./field/driver/getDrivers/getDriversReducer";
import { AddETDriverReducer } from "./field/driver/addDriver/addDriverReducer";
import { UpdateETDriverReducer } from "./field/driver/updateDriver/updateDriverReducer";
import { GetWorkingSiteLogVersionHistoryReducer } from "./field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryReducer";
import { IGetWorkingSiteLogVersionHistory } from "./field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryConstants";
import { GetDriverCommunicationLogsReducer } from "./field/getDriverCommunicationLogs/getDriverCommunicationLogsReducer";
import { IGetDriverCommunicationLogs } from "./field/getDriverCommunicationLogs/getDriverCommunicationLogsConstants";
import { GetSwampersReducer } from "./field/dispatch/getSwampers/getSwampersReducer";
import { ISwamper } from "./field/dispatch/getSwampers/getSwampersConstants";
import { AddSwamperReducer } from "./field/dispatch/addSwamper/addSwamperReducer";
import { UpdateSwamperReducer } from "./field/dispatch/updateSwamper/updateSwamperReducer";
import { AddServiceTruckReducer } from "./field/dispatch/addServiceTruck/addServiceTruckReducer";
import { UpdateServiceTruckReducer } from "./field/dispatch/updateServiceTruck/updateServiceTruckReducer";
import { SaveDriversAssignmentReducer } from "./field/dispatch/saveDriversAssignment/saveDriversAssignmentReducer";
import { GetServiceTrucksReducer } from "./field/dispatch/getServiceTrucks/getServiceTrucksReducer";
import { GetDriversAssignmentReducer } from "./field/dispatch/getDriversAssignment/getDriversAssignmentReducer";
import { IDriversAssignment } from "./field/dispatch/getDriversAssignment/getDriversAssignmentConstants";
import { IServiceTruck } from "./field/dispatch/getServiceTrucks/getServiceTrucksConstants";
import { IDriversAssignmentHistory } from "./field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryConstants";
import { GetDriversAssignmentHistoryReducer } from "./field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryReducer";
import { GetMechanicLogsReducer } from "./field/repairLine/getMechanicLogs/getMechanicLogsReducer";
import { IMechanicLogs } from "./field/repairLine/getMechanicLogs/getMechanicLogsConstants";
import { GetEquipmentMoveRequestReducer } from "./field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestReducer";
import { IEquipmentMoveRequest } from "./field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestConstants";
import { GetMappingViewReducer } from "./field/equipmentTracker/getMappingView/getMappingViewReducer";
import { IMappingView } from "./field/equipmentTracker/getMappingView/getMappingViewConstants";
import { InitiateMoveReducer } from "./field/equipmentTracker/initiateMove/initiateMoveReducer";
import { GetETLookupsReducer } from "./field/equipmentTracker/getETLookups/getETLookupsReducer";
import { IETLookups } from "./field/equipmentTracker/getETLookups/getETLookupsConstants";
import { GetServiceTruckCommunicationLogsReducer } from "./field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsReducer";
import { IGetServiceTruckCommunicationLogs } from "./field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsConstants";
import { GetWorkingSiteLogsOnCallReducer } from "./field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallReducer";
import { IWorkingSiteLogOnCall } from "./field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallConstants";
import { UpdateWorkingSiteLogOnCallReducer } from "./field/workingSiteLogs/updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallReducer";
import { IExternalUser } from "./externalUser/getExternalUsers/getExternalUsersConstants";
import { GetExternalUsersReducer } from "./externalUser/getExternalUsers/getExternalUsersReducer";
import { AddExternalUserReducer } from "./externalUser/addExternalUser/addExternalUserReducer";
import { UpdateExternalUserReducer } from "./externalUser/updateExternalUser/updateExternalUserReducer";
import { GetSoctListReducer } from "./field/soct/getSoctList/getSoctListReducer";
import { ISoctList } from "./field/soct/getSoctList/getSoctListConstants";
import { ISoctReport } from "./field/soct/getSoctReport/getSoctReportConstants";
import { GetSoctReportReducer } from "./field/soct/getSoctReport/getSoctReportReducer";
import { UpdateSoctReportReducer } from "./field/soct/updateSoctReport/updateSoctReportReducer";
import { IComponentWaitingParts } from "./field/repairLine/getComponentWaitingParts/getComponentWaitingPartsConstants";
import { GetComponentWaitingPartsReducer } from "./field/repairLine/getComponentWaitingParts/getComponentWaitingPartsReducer";
import { SaveComponentWaitingPartsReducer } from "./field/repairLine/saveComponentWaitingParts/saveComponentWaitingPartsReducer";
import { SaveMechanicAssignmentReducer } from "./field/repairLine/saveMechanicAssignment/saveMechanicAssignmentReducer";
import { ApproveMechanicLogReducer } from "./field/repairLine/approveMechanicLog/approveMechanicLogReducer";
import { IGetCompaniesResponse } from "./field/pmSheets/getCompanies/getCompaniesConstants";
import { GetCompaniesReducer } from "./field/pmSheets/getCompanies/getCompaniesReducer";
import { GetSTSchedulesReducer } from "./field/dispatch/getSTSchedules/getSTSchedulesReducer";
import { ISTSchedules } from "./field/dispatch/getSTSchedules/getSTSchedulesConstants";
import { UpdateSTSchedulesReducer } from "./field/dispatch/updateSTSchedules/updateSTSchedulesReducer";
import { GetDriverAssignmentVersionHistoryReducer } from "./field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryReducer";
import { IGetDriverAssignmentVersionHistory } from "./field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryConstants";
import { GetAttachmentsReducer } from "./field/attachment/getAttachments/getAttachmentsReducer";
import { IAttachment } from "./field/attachment/getAttachments/getAttachmentsConstants";
import { GetTypesReducer } from "./field/attachment/getTypes/getTypesReducer";
import { IAttachmentType } from "./field/attachment/getTypes/getTypesConstants";
import { ICoupler } from "./field/attachment/getCouplers/getCouplersConstants";
import { GetCouplersReducer } from "./field/attachment/getCouplers/getCouplersReducer";
import { AddAttachmentTypeReducer } from "./field/attachment/addType/addTypeReducer";
import { UpdateAttachmentTypeReducer } from "./field/attachment/updateType/updateTypeReducer";
import { AddAttachmentCouplerReducer } from "./field/attachment/addCoupler/addCouplerReducer";
import { UpdateAttachmentCouplerReducer } from "./field/attachment/updateCoupler/updateCouplerReducer";
import { AddAttachmentReducer } from "./field/attachment/addAttachment/addAttachmentReducer";
import { UpdateAttachmentReducer } from "./field/attachment/updateAttachment/updateAttachmentReducer";
import { GetAttachmentVersionHistoryReducer } from "./field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryReducer";
import { IGetAttachmentVersionHistory } from "./field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { GetAttachmentMovesReducer } from "./field/attachment/getAttachmentMoves/getAttachmentMovesReducer";
import { IAttachmentMove } from "./field/attachment/getAttachmentMoves/getAttachmentMovesConstants";
import { AddAttachmentMoveReducer } from "./field/attachment/addAttachmentMove/addAttachmentMoveReducer";
import { UpdateAttachmentMoveReducer } from "./field/attachment/updateAttachmentMove/updateAttachmentMoveReducer";
import { GetKantechAdjustmentsReducer } from "./sil/Kantech/getKantechAdjustments/getKantechAdjustmentsReducer";
import { IKantechAdjustment } from "./sil/Kantech/getKantechAdjustments/getKantechAdjustmentsConstants";
import { AddKantechAdjustmentReducer } from "./sil/Kantech/addKantechAdjustment/addKantechAdjustmentReducer";
import { UpdateKantechAdjustmentReducer } from "./sil/Kantech/updateKantechAdjustment/updateKantechAdjustmentReducer";
import { IStyle } from "./field/attachment/getStyles/getStylesConstants";
import { UpdateAttachmentStyleReducer } from "./field/attachment/updateStyle/updateStyleReducer";
import { AddAttachmentStyleReducer } from "./field/attachment/addStyle/addStyleReducer";
import { GetStylesReducer } from "./field/attachment/getStyles/getStylesReducer";
import { GetMoveRequestHistoryReducer } from "./field/attachment/getMoveRequestHistory/getMoveRequestHistoryReducer";
import { IMoveRequestHistory } from "./field/attachment/getMoveRequestHistory/getMoveRequestHistoryConstants";

import { GetIGLookupsReducer } from "./field/imageGallery/getImageGalleryLookups/getImageGalleryReducer";
import { IIGLookups } from "./field/imageGallery/getImageGalleryLookups/getImageGalleryConstants";

import { AddAttachmentListGETReducer } from "./field/attachment/addAttachmentListGET/addAttachmentListGETReducer";
import { UpdateAttachmentListGETReducer } from "./field/attachment/updateAttachmentListGET/updateAttachmentListGETReducer";
import { GetAttachmentListGETReducer } from "./field/attachment/getAttachmentListGET/getAttachmentListGETReducer";
import { IAttachmentListGET } from "./field/attachment/getAttachmentListGET/getAttachmentListGETConstants";
import { BulkMoveReducer } from "./field/equipmentTracker/bulkMove/bulkMoveReducer";
import { DeleteFilterPartReducer } from "./field/deleteFilterPart/deleteFilterPartReducer";
import { DeleteAttachmentListGETReducer } from "./field/attachment/deleteAttachmentListGET/deleteAttachmentListGETReducer";
import { IRepairLineSummary } from "./field/repairLine/getRepairLineSummary/getRepairLineSummaryConstants";
import { GetRepairLineSummaryReducer } from "./field/repairLine/getRepairLineSummary/getRepairLineSummaryReducer";
import { GetRepairLineByMechanicIdReducer } from "./field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdReducer";
import { IRepairLineByMechanicId } from "./field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdConstants";

import { IGetRepairLineHistory } from "./field/repairLine/getRepairLineHistory/getRepairLineHistoryConstants";
import { GetRepairLineHistoryReducer } from "./field/repairLine/getRepairLineHistory/getRepairLineHistoryReducer";

import { WorkOrderReducer } from "./field/repairLine/workOrder/workOrderReducer";
import { CloneAttachmentGETReducer } from "./field/attachment/cloneAttachmentGET/cloneAttachmentGETReducer";
import { GetRepairLineWOReducer } from "./field/repairLine/getRepairLineWO/getRepairLineWOReducer";
import { IRepairLineWO } from "./field/repairLine/getRepairLineWO/getRepairLineWOConstants";
import { IFilterCut } from "./field/filterCuts/getFilterCuts/getFilterCutsConstants";
import { GetFilterCutsReducer } from "./field/filterCuts/getFilterCuts/getFilterCutsReducer";
import { UpdateFilterCutReducer } from "./field/filterCuts/updateFilterCut/updateFilterCutReducer";
import { AddFilterCutReducer } from "./field/filterCuts/addFilterCut/addFilterCutReducer";
import { IGetRepairLineVersionHistory } from "./field/getRepairLineVersionHistory/getRepairLineVersionHistoryConstants";
import { GetRepairLineVersionHistoryReducer } from "./field/getRepairLineVersionHistory/getRepairLineVersionHistoryReducer";
import { GetRepairLineByIdReducer } from "./field/repairLine/getRepairLineById/getRepairLineByIdReducer";
import { GetRepairSmsLogsReducer } from "./field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsReducer";
import { IRepairLineSmsLog } from "./field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsConstants";
import { IGetUnitHistoryById } from "./field/getUnitHistoryById/getUnitHistoryByIdConstants";
import { GetUnitHistoryByIdReducer } from "./field/getUnitHistoryById/getUnitHistoryByIdReducer";
import { AddEquipmentCertificateReducer } from "./field/addEquipmentCertificate/addEquipmentCertificateReducer";
import { IFieldEmployee } from "./field/workingSiteLogs/getFieldEmployees/getFieldEmployeesConstants";
import { GetFieldEmployeesReducer } from "./field/workingSiteLogs/getFieldEmployees/getFieldEmployeesReducer";
import { AddFieldEmployeeReducer } from "./field/workingSiteLogs/addFieldEmployee/addFieldEmployeeReducer";
import { UpdateFieldEmployeeReducer } from "./field/workingSiteLogs/updateFieldEmployee/updateFieldEmployeeReducer";

import { IEmployeeMessage } from "./external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenConstants";
import { GetEmployeeMessagesByTokenReducer } from "./external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenReducer";
import { SaveEmployeeMessageResponseReducer } from "./external/saveEmployeeMessageResponse/saveEmployeeMessageResponseReducer";
import { IForemanMessaging } from "./field/workingSiteLogs/getForemanMessaging/getForemanMessagingConstants";
import { GetForemanMessagingReducer } from "./field/workingSiteLogs/getForemanMessaging/getForemanMessagingReducer";
import { AddForemanMessagingReducer } from "./field/workingSiteLogs/addForemanMessaging/addForemanMessagingReducer";
import { UpdateForemanMessagingReducer } from "./field/workingSiteLogs/updateForemanMessaging/updateForemanMessagingReducer";
import { IRepairLineWOUnits } from "./field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsConstants";
import { GetRepairLineWOUnitsReducer } from "./field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsReducer";
import { AddRepairLineWOUnitReducer } from "./field/repairLine/addRepairLineWOUnit/addRepairLineWOUnitReducer";
import { UpdateRepairLineWOUnitReducer } from "./field/repairLine/updateRepairLineWOUnit/updateRepairLineWOUnitReducer";
import { IGetETMoveAttachmentVersionHistory } from "./field/getETMoveAttachmentVersionHistory/getETMoveAttachmentVersionHistoryConstants";
import { GetETMoveAttachmentVersionHistoryReducer } from "./field/getETMoveAttachmentVersionHistory/getETMoveAttachmentVersionHistoryReducer";
import { GetPMSheetVersionHistoryReducer } from "./field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryReducer";
import { IGetPMSheetVersionHistory } from "./field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryConstants";
import { GetPayrollMechanicLogsReducer } from "./field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsReducer";
import { IPayrollMechanicLogs } from "./field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsConstants";
import { IPayrollMechanicLogsReport } from "./field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportConstants";
import { GetPayrollMechanicLogsReportReducer } from "./field/repairLine/getPayrollMechanicLogsReport/getPayrollMechanicLogsReportReducer";
import { PayrollMechanicLogsUploadReducer } from "./field/repairLine/payrollMechanicLogsUpload/payrollMechanicLogsUploadReducer";
import { GetMechanicTimesheetForApprovalReducer } from "./field/repairLine/getMechanicTimeSheets/getMechanicTimesheetForApprovalReducer";
import { IMechanicTimesheetForApproval } from "./field/repairLine/getMechanicTimeSheets/getMechanicTimesheetForApprovalConstants";
import { SaveManagerCommentsForDefectItemReducer } from "./field/repairLine/SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemReducer";
import { IBulkMeterReading } from "./field/soct/getBulkMeterReadings/getBulkMeterReadingsConstants";
import { GetBulkMeterReadingsReducer } from "./field/soct/getBulkMeterReadings/getBulkMeterReadingsReducer";
import { GetSOCTLookupsReducer } from "./field/soct/getSOCTLookups/getSOCTLookupsReducer";
import { ISOCTLookup } from "./field/soct/getSOCTLookups/getSOCTLookupsConstants";
import { IMeterReading } from "./field/soct/getMeterReadingById/getMeterReadingByIdConstants";
import { GetMeterReadingByIdReducer } from "./field/soct/getMeterReadingById/getMeterReadingByIdReducer";
import { AddMeterReadingReducer } from "./field/soct/addMeterReading/addMeterReadingReducer";
import { UpdateMeterReadingReducer } from "./field/soct/updateMeterReading/updateMeterReadingReducer";
import { GetRepairLineItemByIdReducer } from "./field/repairLine/getRepairLineItemById/getRepairLineItemByIdReducer";
import { SaveRepairLineReducer } from "./field/repairLine/saveRepairLineItem/saveRepairLineItemReducer";
import { GetMechanicTimesheetForApprovalGroupByDateReducer } from "./field/repairLine/getMechanicTimesheetForApprovalGroupByDate/getMechanicTimesheetForApprovalGroupByDateReducer";
import { IMechanicTimesheetForApprovalGroupByDate } from "./field/repairLine/getMechanicTimesheetForApprovalGroupByDate/getMechanicTimesheetForApprovalGroupByDateConstants";
import { GetMechanicLogsByDefectIDReducer } from "./field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDReducer";
import { IMechanicLogsByDefectID } from "./field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDConstants";
import { GetRepairLineDefectItemsByRepairIDReducer } from "./field/repairLine/getRepairLineDefectItemsByRepairID/getRepairLineDefectItemsByRepairIDReducer";
import { GetSOCTByIdReducer } from "./field/soct/getSOCTById/getSOCTByIdReducer";
import { ISOCT } from "./field/soct/getSOCTById/getSOCTByIdConstants";
import { GetMeterHistoryBySOCTIDReducer } from "./field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDReducer";
import { IMeterHistory } from "./field/soct/getMeterHistoryBySOCTID/getMeterHistoryBySOCTIDConstants";
import { AddSOCTReducer } from "./field/soct/addSOCT/addSOCTReducer";
import { UpdateSOCTReducer } from "./field/soct/updateSOCT/updateSOCTReducer";
import { GetServiceHistoryBySOCTIDReducer } from "./field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDReducer";
import { IServiceHistory } from "./field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDConstants";
import { IScheduleServiceHistory } from "./field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdConstants";
import { GetScheduleServiceHistoryByIdReducer } from "./field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdReducer";
import { AddScheduleServiceHistoryReducer } from "./field/soct/addScheduleServiceHistory/addScheduleServiceHistoryReducer";
import { UpdateScheduleServiceHistoryReducer } from "./field/soct/updateScheduleServiceHistory/updateScheduleServiceHistoryReducer";
import { GetTechServicesReducer } from "./field/repairLine/getTechServices/getTechServicesReducer";
import { ITechService } from "./field/repairLine/getTechServices/getTechServicesConstants";
import { AddTechServiceReducer } from "./field/repairLine/addTechService/addTechServiceReducer";
import { UpdateTechServiceReducer } from "./field/repairLine/updateTechService/updateTechServiceReducer";
import { GetTechServicesByUnitIDReducer } from "./field/repairLine/getTechServicesByUnitID/getTechServicesByUnitIDReducer";
import { RejectMechanicLogReducer } from "./field/repairLine/rejectMechanicLog/rejectMechanicLogReducer";
import { GetEquipmentManualsReducer } from "./field/equipmentManuals/getEquipmentManuals/getEquipmentManualsReducer";
import { IEquipmentManual } from "./field/equipmentManuals/getEquipmentManuals/getEquipmentManualsConstants";
import { AddEquipmentManualReducer } from "./field/equipmentManuals/addEquipmentManual/addEquipmentManualReducer";
import { UpdateEquipmentManualReducer } from "./field/equipmentManuals/updateEquipmentManual/updateEquipmentManualReducer";
import { AcknowledgeLogByPayrollReducer } from "./field/repairLine/acknowledgeLogByPayroll/acknowledgeLogByPayrollReducer";
import { IPayrollMechanicLogsSummary } from "./field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryConstants";
import { GetPayrollMechanicLogsSummaryReducer } from "./field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryReducer";
import { IGetMechanicTimecardVersionHistory } from "./field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";
import { GetMechanicTimecardVersionHistoryReducer } from "./field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryReducer";
import { IGetUnitUtilizationByID } from "./field/getUnitUtilizationByID/getUnitUtilizationByIDConstants";
import { GetUnitUtilizationByIDReducer } from "./field/getUnitUtilizationByID/getUnitUtilizationByIDReducer";
import { GetWorkingSiteLogByIdReducer } from "./field/workingSiteLogs/getWorkingSiteLogById/getWorkingSiteLogByIdReducer";
import { IWSSLogs } from "./field/workingSiteLogs/getWSSLogs/getWSSLogsConstants";
import { GetWSSLogsReducer } from "./field/workingSiteLogs/getWSSLogs/getWSSLogsReducer";
import { GetPMSheetReportByIdReducer } from "./field/pmSheets/getPMSheetReportById/getPMSheetReportByIdReducer";
import { IPMSheetsReport } from "./field/pmSheets/getPMSheetsReport/getPMSheetsReportConstants";
import { GetPMSheetsReportReducer } from "./field/pmSheets/getPMSheetsReport/getPMSheetsReportReducer";
import { GetWSSLogsHistoryBySiteIDReducer } from "./field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDReducer";
import { IWSSLogsHistory } from "./field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDConstants";
import { GetAttachmentssReducer } from "./field/attachment/getAttachmentss/getAttachmentssReducer";
import { IGetAttachment } from "./field/attachment/getAttachmentss/getAttachmentssConstants";
import { IGetAttachmentByID } from "./field/attachment/getAttachmentByID/getAttachmentByIDConstants";
import { GetAttachmentByIDReducer } from "./field/attachment/getAttachmentByID/getAttachmentByIDReducer";
import { ISOCTCustomServiceItemsBySOCTID, } from "./field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDConstants";
import { GetSOCTCustomServiceItemsBySOCTIDReducer } from "./field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDReducer";
import { GetEquipmentByIDReducer } from "./field/getEquipmentByID/getEquipmentByIDReducer";
import { GetEquipmentReducer } from "./field/getEquipment/getEquipmentReducer";
import { IGetEquipment } from "./field/getEquipment/getEquipmentConstants";
import { IChecklistForm } from "./field/getForms/getFormsConstants";
import { GetFormsReducer } from "./field/getForms/getFormsReducer";
import { IFormLookup } from "./field/getFormLookup/getFormLookupConstants";
import { GetFormLookupReducer } from "./field/getFormLookup/getFormLookupReducer";
import { AddFormTemplateReducer } from "./field/addFormTemplate/addFormTemplateReducer";
import { UpdateFormTemplateReducer } from "./field/updateFormTemplate/updateFormTemplateReducer";
import { GetFormByTemplateIDReducer } from "./field/getFormByTemplateID/getFormByTemplateIDReducer";
import { AddFormResponseReducer } from "./field/addFormResponse/addFormResponseReducer";
import { UpdateFormResponseReducer } from "./field/updateFormResponse/updateFormResponseReducer";
import { GetFormResponsesReducer } from "./field/getFormResponses/getFormResponsesReducer";
import { IFormResponse } from "./field/getFormResponses/getFormResponsesConstants";
import { IFormResponseByID } from "./field/getFormResponseByID/getFormResponseByIDConstants";
import { GetFormResponseByIDReducer } from "./field/getFormResponseByID/getFormResponseByIDReducer";
import { GetWSSLogInfoByIDReducer } from "./field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDReducer";
import { IGetWSSLogInfoByID } from "./field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDConstants";
import { IGetCrews } from "./field/getCrews/getCrewsConstants";
import { GetCrewsReducer } from "./field/getCrews/getCrewsReducer";
import { AddCrewReducer } from "./field/addCrew/addCrewReducer";
import { UpdateCrewReducer } from "./field/updateCrew/updateCrewReducer";
import { GetTasksReducer } from "./field/getTasks/getTasksReducer";
import { IGetTasks } from "./field/getTasks/getTasksConstants";
import { AddTaskReducer } from "./field/addTask/addTaskReducer";
import { UpdateTaskReducer } from "./field/updateTask/updateTaskReducer";
import { IGetHazardTypes } from "./field/getHazardTypes/getHazardTypesConstants";
import { GetHazardTypesReducer } from "./field/getHazardTypes/getHazardTypesReducer";
import { AddHazardTypeReducer } from "./field/addHazardType/addHazardTypeReducer";
import { UpdateHazardTypeReducer } from "./field/updateHazardType/updateHazardTypeReducer";
import { GetControlPlansReducer } from "./field/getControlPlans/getControlPlansReducer";
import { IGetControlPlans } from "./field/getControlPlans/getControlPlansConstants";
import { AddControlPlanReducer } from "./field/addControlPlan/addControlPlanReducer";
import { UpdateControlPlanReducer } from "./field/updateControlPlan/updateControlPlanReducer";
import { GetHazardsReducer } from "./field/getHazards/getHazardsReducer";
import { IGetHazards } from "./field/getHazards/getHazardsConstants";
import { AddHazardReducer } from "./field/addHazard/addHazardReducer";
import { UpdateHazardReducer } from "./field/updateHazard/updateHazardReducer";

import { IGetVendorOrderByID } from "./field/vendors/getVendorOrderByID/getVendorOrderByIDConstants";
import { IVendorOrder, IVendorOrderWork } from "./field/vendors/getVendorOrders/getVendorOrdersConstants";
import { IVendorLookup } from "./field/vendors/getVendorLookups/getVendorLookupsConstants";
import { AddVendorOrderReducer } from "./field/vendors/addVendorOrder/addVendorOrderReducer";
import { GetVendorLookupsReducer } from "./field/vendors/getVendorLookups/getVendorLookupsReducer";
import { UpdateVendorOrderReducer } from "./field/vendors/updateVendorOrder/updateVendorOrderReducer";
import { GetVendorOrderByIDReducer } from "./field/vendors/getVendorOrderByID/getVendorOrderByIDReducer";
import { GetVendorOrderReducer } from "./field/vendors/getVendorOrders/getVendorOrdersReducer";

import { GetFormSignatureHistoryReducer } from "./field/getFormSignatureHistory/getFormSignatureHistoryReducer";
import { IGetFormSignatureHistory } from "./field/getFormSignatureHistory/getFormSignatureHistoryConstants";
import { IGetMedicalFacilities } from "./field/getMedicalFacilities/getMedicalFacilitiesConstants";
import { GetMedicalFacilitiesReducer } from "./field/getMedicalFacilities/getMedicalFacilitiesReducer";
import { AddMedicalFacilityReducer } from "./field/addMedicalFacility/addMedicalFacilityReducer";
import { UpdateMedicalFacilityReducer } from "./field/updateMedicalFacility/updateMedicalFacilityReducer";
import { GetFormResponseVersionHistoryByIDReducer } from "./field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDReducer";
import { IGetFormResponseVersionHistoryByID } from "./field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDConstants";
import { SaveAcknowledgeResponseReducer } from "./field/saveAcknowledgeResponse/saveAcknowledgeResponseReducer";
import { GetDepartmentsReducer } from "./field/getDepartments/getDepartmentsReducer";
import { IGetDepartments } from "./field/getDepartments/getDepartmentsConstants";
import { AddDepartmentReducer } from "./field/addDepartment/addDepartmentReducer";
import { UpdateDepartmentReducer } from "./field/updateDepartment/updateDepartmentReducer";


import { UpdateMachineControlReducer } from "./survey/updateMachineControl/updateMachineControlReducer";
import { UpdateMachineControlBoxReducer } from "./survey/updateMachineControlBox/updateMachineControlBoxReducer";
import { UpdateMachineReceiverReducer } from "./survey/updateMachineReceiver/updateMachineReceiverReducer";
import { AddMachineControlReducer } from "./survey/addMachineControl/addMachineControlReducer";
import { AddMachineReceiverReducer } from "./survey/addMachineReceiver/addMachineReceiverReducer";
import { AddMachineControlBoxReducer } from "./survey/addMachineControlBox/addMachineControlBoxReducer";
import { GetMachineControlBoxesReducer } from "./survey/getMachineControlBoxes/getMachineControlBoxesReducer";
import { GetMachineReceiverReducer } from "./survey/getMachineReceivers/getMachineReceiversReducer";
import { GetMachineControlReducer } from "./survey/getMachineControls/getMachineControlsReducer";
import { IMachineControl } from "./survey/getMachineControls/getMachineControlsConstants";
import { IMachineControlBoxes } from "./survey/getMachineControlBoxes/getMachineControlBoxesConstants";
import { IMachineReceiver } from "./survey/getMachineReceivers/getMachineReceiversConstants";
import { GetSurveyLookupReducer } from "./survey/getSurveyLookup/getSurveyLookupReducer";
import { ISurveyLookup } from "./survey/getSurveyLookup/getSurveyLookupConstants";

import { IGetHazardControlPlanListByDepartmentID, } from "./field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDConstants";
import { GetHazardControlPlanListByDepartmentIDReducer } from "./field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDReducer";
import { AddHazardNewReducer } from "./field/addHazardNew/addHazardNewReducer";
import { GetHazardByIDReducer } from "./field/getHazardByID/getHazardByIDReducer";
import { IGetHazardByID } from "./field/getHazardByID/getHazardByIDConstants";
import { UpdateHazardNewReducer } from "./field/updateHazardNew/updateHazardNewReducer";
import { AddBaseKitReducer } from "./survey/addBaseKit/addBaseKitReducer";
import { GetBaseKitReducer } from "./survey/getBaseKits/getBaseKitsReducer";
import { UpdateBaseKitReducer } from "./survey/updateBaseKit/updateBaseKitReducer";
import { AddRepeaterKitReducer } from "./survey/addRepeaterKit/addRepeaterKitReducer";
import { AddRoverKitReducer } from "./survey/addRoverKit/addRoverKitReducer";
import { GetRepeaterKitReducer } from "./survey/getRepeaterKits/getRepeaterKitsReducer";
import { GetRoverKitReducer } from "./survey/getRoverKits/getRoverKitsReducer";
import { UpdateRepeaterKitReducer } from "./survey/updateRepeaterKit/updateRepeaterKitReducer";
import { UpdateRoverKitReducer } from "./survey/updateRoverKit/updateRoverKitReducer";
import { IRepeaterKit } from "./survey/getRepeaterKits/getRepeaterKitsConstants";
import { IRoverKit } from "./survey/getRoverKits/getRoverKitsConstants";
import { IBaseKit } from "./survey/getBaseKits/getBaseKitsConstants";
import { ILasers } from "./survey/getLasers/getLasersConstants";
import { ILevels } from "./survey/getLevels/getLevelsConstants";
import { AddLaserReducer } from "./survey/addLaser/addLaserReducer";
import { UpdateLaserReducer } from "./survey/updateLaser/updateLaserReducer";
import { GetLasersReducer } from "./survey/getLasers/getLasersReducer";
import { AddLevelReducer } from "./survey/addLevel/addLevelReducer";
import { UpdateLevelReducer } from "./survey/updateLevel/updateLevelReducer";
import { GetLevelsReducer } from "./survey/getLevels/getLevelsReducer";
import { IGetUnitInspectionFormByID } from "./field/getUnitInspectionFormByID/getUnitInspectionFormByIDConstants";
import { GetUnitInspectionFormByIDReducer } from "./field/getUnitInspectionFormByID/getUnitInspectionFormByIDReducer";
import { SaveInspectionSheetReducer } from "./field/saveInspectionSheet/saveInspectionSheetReducer";
import { IGetUnitInspectionSheets } from "./field/getUnitInspectionSheets/getUnitInspectionSheetsConstants";
import { GetUnitInspectionSheetsReducer } from "./field/getUnitInspectionSheets/getUnitInspectionSheetsReducer";
import { GetUnitInspectionSheetByIDReducer } from "./field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDReducer";
import { IGetUnitInspectionSheetByID } from "./field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDConstants";
import { GetSurveyAttachmentsReducer } from "./survey/getSurveyAttachment/getSurveyAttachmentsReducer";
import { IAttachments } from "./survey/getSurveyAttachment/getSurveyAttachmentsConstants";
import { AddSurveyAttachmentReducer } from "./survey/addSurveyAttachment/addSurveyAttachmentReducer";
import { UpdateSurveyAttachmentReducer } from "./survey/updateSurveyAttachment/updateSurveyAttachmentReducer";
import { GetCostCodesByJobIDReducer } from "./field/getCostCodesByJobID/getCostCodesByJobIDReducer";
import { GetVendorItemsByOrderIDReducer } from "./field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDReducer";
import { IFormResponseByUserToken } from "./external/getFormResponseByUserToken/getFormResponseByUserTokenConstants";
import { GetFormResponseByUserTokenReducer } from "./external/getFormResponseByUserToken/getFormResponseByUserTokenReducer";
import { SaveCrewSignatureByIDReducer } from "./external/saveCrewSignatureByID/saveCrewSignatureByIDReducer";
import { IJournels } from "./field/workingSiteLogs/getJournels/getJournelsConstants";
import { GetJournelsReducer } from "./field/workingSiteLogs/getJournels/getJournelsReducer";
import { AddJournelReducer } from "./field/workingSiteLogs/addJournel/addJournelReducer";
import { UpdateJournelReducer } from "./field/workingSiteLogs/updateJournel/updateJournelReducer";
import { IVendorPOLogs } from "./field/vendors/getVendorPOLogs/getVendorPOLogsConstants";
import { GetVendorPOLogsReducer } from "./field/vendors/getVendorPOLogs/getVendorPOLogsReducer";
import { SaveVendorPOLogReducer } from "./field/vendors/saveVendorPOLog/saveVendorPOLogReducer";
import { GetVendorPOLogByIDReducer } from "./field/vendors/getVendorPOLogByID/getVendorPOLogByIDReducer";
import { IGetVendorPOLogByID } from "./field/vendors/getVendorPOLogByID/getVendorPOLogByIDConstants";
import { IGetCrewByUsername } from "./field/workingSiteLogs/getCrewByUsername/getCrewByUsernameConstants";
import { GetCrewByUsernameReducer } from "./field/workingSiteLogs/getCrewByUsername/getCrewByUsernameReducer";
import { IGetCostCodesByJobID } from "./field/getCostCodesByJobID/getCostCodesByJobIDConstants";
import { IGetFormVersionHistory } from "./field/getFormVersionHistory/getFormVersionHistoryConstants";
import { GetFormVersionHistoryReducer } from "./field/getFormVersionHistory/getFormVersionHistoryReducer";
import { GetJournalVersionHistoryReducer } from "./field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryReducer";
import { IGetJournalVersionHistory } from "./field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryConstants";
import { IGetCrewVersionHistory } from "./field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryConstants";
import { GetCrewVersionHistoryReducer } from "./field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryReducer";
import { ICleanupData, IGetCleanupData } from "./field/getCleanupData/getCleanupDataConstants";
import { GetCleanupDataReducer } from "./field/getCleanupData/getCleanupDataReducer";
import { RemoveDepartmentFromItemsReducer } from "./field/removeDepartmentFromItems/removeDepartmentFromItemReducer";
import { GetControlPlansNewReducer } from "./field/getControlPlansNew/getControlPlansNewReducer";
import { IGetControlPlansNew } from "./field/getControlPlansNew/getControlPlansNewConstants";
import { SaveControlPlanReducer } from "./field/saveControlPlan/saveControlPlanReducer";
import { ISTTimesheetForApprovalGroupByDate } from "./field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateConstants";
import { GetSTTimesheetForApprovalGroupByDateReducer } from "./field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateReducer";
import { ISTLogs } from "./field/dispatch/getSTLogs/getSTLogsConstants";
import { GetSTLogsReducer } from "./field/dispatch/getSTLogs/getSTLogsReducer";
import { ApproveSTTimesheetReducer } from "./field/dispatch/approveSTTimesheet/approveSTTimesheetReducer";
import { RejectSTTimesheetReducer } from "./field/dispatch/rejectSTTimesheet/rejectSTTimesheetReducer";


export interface IAction {
  type: any;
  [key: string]: any;
}

export type ReducerMap<T> = { [K in keyof T]: Reducer<T[K], IAction>; };

export type IDispatch = Dispatch<IAction>;

export interface IStore {
  GetToken: Server<SurewayAPIResponse<IToken>>;
  GetAuthorization: Server<SurewayAPIResponse<IAuthorization[]>>;
  ValidateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
  GetLookups: Server<SurewayAPIResponse<ILookup>>;
  EXTERNAL: {
    MOVE_REQUEST: Server<IMoveRequest[]>;
    SAVE_EMPLOYEE_MESSAGE_RESPONSE: Server<string>;
    GET_EMPLOYEE_MESSAGES_BY_TOKEN: Server<SurewayAPIResponse<IEmployeeMessage>>;
    GET_FORM_RESPONSE_BY_USER_TOKEN: Server<SurewayAPIResponse<IFormResponseByUserToken>>;
    SAVE_CREW_SIGNATURE_BY_ID: Server<string>;
  },
  Accounting: {
    Jobs: {
      GetJobs: Server<SurewayAPIResponse<ById<IJob>>>;
      AddJob: Server<SurewayAPIResponse<string>>;
      UpdateJob: Server<SurewayAPIResponse<string>>;
      GetJobsPits: Server<SurewayAPIResponse<ById<IJobPit>>>;
      AddJobPit: Server<SurewayAPIResponse<string>>;
      UpdateJobPit: Server<SurewayAPIResponse<string>>;
      GetJobsMhr: Server<SurewayAPIResponse<ById<IJobMhr>>>;
      AddJobMhr: Server<SurewayAPIResponse<string>>;
      UpdateJobMhr: Server<SurewayAPIResponse<string>>;
    },
  },
  SIL: {
    KANTECH: {
      GetKantechAdjustments: Server<SurewayAPIResponse<ById<IKantechAdjustment>>>;
      AddKantechAdjustment: Server<SurewayAPIResponse<string>>;
      UpdateKantechAdjustment: Server<SurewayAPIResponse<string>>;
    },
  },
  Field: {
    Manufacturer: Server<SurewayAPIResponse<ById<IShopManufacture>>>;
    GetUnitHistoryById: Server<SurewayAPIResponse<ById<IGetUnitHistoryById>>>;
    GetUnitDetails: Server<SurewayAPIResponse<IUnitDetails>>;
    AddManufacturer: Server<SurewayAPIResponse<string>>;
    UpdateManufacturer: Server<SurewayAPIResponse<string>>;
    ShopLookups: Server<SurewayAPIResponse<IShopLookups>>;
    Models: Server<SurewayAPIResponse<ById<IShopModel>>>;
    AddModel: Server<SurewayAPIResponse<string>>;
    UpdateModel: Server<SurewayAPIResponse<string>>;
    Equipments: Server<SurewayAPIResponse<ById<IShopEquipment>>>;
    GetEquipment: Server<SurewayAPIResponse<ById<IGetEquipment>>>;
    GetEquipmentByID: Server<SurewayAPIResponse<IShopEquipment>>;
    AddEquipment: Server<SurewayAPIResponse<string>>;
    UpdateEquipment: Server<SurewayAPIResponse<string>>;
    GetEquipmentTypes: Server<SurewayAPIResponse<ById<IGetEquipmentType>>>;
    GetUnitUtilizationByID: Server<SurewayAPIResponse<ById<IGetUnitUtilizationByID>>>;
    AddEquipmentType: Server<SurewayAPIResponse<string>>;
    UpdateEquipmentType: Server<SurewayAPIResponse<string>>;
    GetShopEquipmentColumns: Server<SurewayAPIResponse<IGetShopEquipmentColumn>>;
    AssignEquipmentTypeColumn: Server<SurewayAPIResponse<string>>;
    GetEquipmentHistoryColumns: Server<SurewayAPIResponse<IGetEquipmentHistoryColumn[]>>;
    GetEquipmentVersionHistory: Server<SurewayAPIResponse<IGetEquipmentVersionHistory[]>>;
    GetFilter: Server<SurewayAPIResponse<ById<IFilter>>>;
    AddFilter: Server<SurewayAPIResponse<string>>;
    UpdateFilter: Server<SurewayAPIResponse<string>>;
    GetLocation: Server<SurewayAPIResponse<ById<ILocation>>>;
    AddLocation: Server<SurewayAPIResponse<string>>;
    UpdateLocation: Server<SurewayAPIResponse<string>>;
    GetFilterReport: Server<SurewayAPIResponse<ById<IFilterReport>>>;
    AddFilterPart: Server<SurewayAPIResponse<string>>;
    DeleteFilterPart: Server<SurewayAPIResponse<string>>;
    UpdateFilterPart: Server<SurewayAPIResponse<string>>;
    GetMoveRequests: Server<SurewayAPIResponse<IMoveRequests>>;
    GetDriver: Server<SurewayAPIResponse<ById<IDriver>>>;
    SaveDriverAssignment: Server<SurewayAPIResponse<string>>;
    GetDriverCommunicationLogs: Server<SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>>;
    AddEquipmentCertificate: Server<SurewayAPIResponse<string>>;
    GetETMoveAttachmentVersionHistory: Server<SurewayAPIResponse<IGetETMoveAttachmentVersionHistory[]>>;
    GetForms: Server<SurewayAPIResponse<IChecklistForm[]>>;
    GetFormLookup: Server<SurewayAPIResponse<IFormLookup>>;
    AddFormTemplate: Server<SurewayAPIResponse<string>>;
    UpdateFormTemplate: Server<SurewayAPIResponse<string>>;
    GetFormByTemplateID: Server<SurewayAPIResponse<IChecklistForm>>;
    GetFormResponses: Server<SurewayAPIResponse<IFormResponse[]>>;
    GetFormResponseByID: Server<SurewayAPIResponse<IFormResponseByID>>;
    AddFormResponse: Server<SurewayAPIResponse<string>>;
    UpdateFormResponse: Server<SurewayAPIResponse<string>>;
    GetCrews: Server<SurewayAPIResponse<ById<IGetCrews>>>;
    AddCrew: Server<SurewayAPIResponse<string>>;
    UpdateCrew: Server<SurewayAPIResponse<string>>;
    GetMedicalFacilities: Server<SurewayAPIResponse<ById<IGetMedicalFacilities>>>;
    AddMedicalFacility: Server<SurewayAPIResponse<string>>;
    UpdateMedicalFacility: Server<SurewayAPIResponse<string>>;
    Forms: {
      GetFormSignatureHistory: Server<SurewayAPIResponse<IGetFormSignatureHistory[]>>;
      GetFormResponseVersionHistoryByID: Server<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>>;
      GetFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
      SaveAcknowledgeResponse: Server<SurewayAPIResponse<string>>;
      GetHazardControlPlanListByDepartmentID: Server<SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>>;
      Admin: {
        GetTasks: Server<SurewayAPIResponse<ById<IGetTasks>>>;
        AddTask: Server<SurewayAPIResponse<string>>;
        UpdateTask: Server<SurewayAPIResponse<string>>;
        GetDepartments: Server<SurewayAPIResponse<ById<IGetDepartments>>>;
        AddDepartment: Server<SurewayAPIResponse<string>>;
        UpdateDepartment: Server<SurewayAPIResponse<string>>;
        GetHazardTypes: Server<SurewayAPIResponse<ById<IGetHazardTypes>>>;
        AddHazardType: Server<SurewayAPIResponse<string>>;
        UpdateHazardType: Server<SurewayAPIResponse<string>>;
        GetControlPlans: Server<SurewayAPIResponse<ById<IGetControlPlans>>>;
        GetControlPlansNew: Server<SurewayAPIResponse<ById<IGetControlPlansNew>>>;
        AddControlPlan: Server<SurewayAPIResponse<string>>;
        SaveControlPlan: Server<SurewayAPIResponse<string>>;
        UpdateControlPlan: Server<SurewayAPIResponse<string>>;
        // UpdateControlPlanNew: Server<SurewayAPIResponse<string>>;
        GetHazards: Server<SurewayAPIResponse<ById<IGetHazards>>>;
        AddHazard: Server<SurewayAPIResponse<string>>;
        GetHazardByID: Server<SurewayAPIResponse<IGetHazardByID>>;
        UpdateHazard: Server<SurewayAPIResponse<string>>;
        AddHazardNew: Server<SurewayAPIResponse<string>>;
        UpdateHazardNew: Server<SurewayAPIResponse<string>>;
      },
      Cleanup: {
        GetCleanupData: Server<SurewayAPIResponse<IGetCleanupData>>;
        RemoveDepartmentFromItems: Server<SurewayAPIResponse<string>>;
      }
    },
    Equipment_Tracker: {
      Admin: {
        GetDrivers: Server<IETDriver[]>;
        AddDriver: Server<string>;
        UpdateDriver: Server<string>;
      },
      GetMappingView: Server<IMappingView[]>;
      GetETLookups: Server<SurewayAPIResponse<IETLookups>>;
      InitiateMove: Server<SurewayAPIResponse<string>>;
      BulkMove: Server<SurewayAPIResponse<string>>;
      GetEquipmentMoveRequest: Server<IEquipmentMoveRequest[]>;
    },
    Repair_Line: {
      GetRepairLine: Server<SurewayAPIResponse<ById<IRepairLine>>>;
      AddRepairLine: Server<SurewayAPIResponse<string>>;
      UpdateRepairLine: Server<SurewayAPIResponse<string>>;
      GetMechanicLogs: Server<SurewayAPIResponse<IMechanicLogs[]>>;
      GetPayrollMechanicLogs: Server<SurewayAPIResponse<IPayrollMechanicLogs[]>>;
      GetPayrollMechanicLogsReport: Server<SurewayAPIResponse<IPayrollMechanicLogsReport[]>>;
      GetPayrollMechanicLogsSummary: Server<SurewayAPIResponse<IPayrollMechanicLogsSummary[]>>;
      PayrollMechanicLogsUpload: Server<SurewayAPIResponse<string>>;
      AcknowledgeLogByPayroll: Server<SurewayAPIResponse<string>>;
      GetComponentWaitingParts: Server<SurewayAPIResponse<IComponentWaitingParts[]>>;
      SaveComponentWaitingParts: Server<SurewayAPIResponse<string>>;
      SaveMechanicAssignment: Server<SurewayAPIResponse<string>>;
      ApproveMechanicLog: Server<SurewayAPIResponse<string>>;
      RejectMechanicLog: Server<SurewayAPIResponse<string>>;
      GetRepairLineSummary: Server<SurewayAPIResponse<IRepairLineSummary>>;
      GetRepairLineByMechanicId: Server<SurewayAPIResponse<IRepairLineByMechanicId[]>>;
      GetRepairLineHistory: Server<SurewayAPIResponse<ById<IGetRepairLineHistory>>>;
      WorkOrder: Server<SurewayAPIResponse<string>>;
      GetRepairLineWO: Server<SurewayAPIResponse<IRepairLineWO[]>>;
      GetRepairLineVersionHistory: Server<SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>>;
      getRepairLineById: Server<SurewayAPIResponse<IRepairLine>>;
      getRepairLineItemById: Server<SurewayAPIResponse<IRepairLineItem>>;
      SaveRepairLineItem: Server<SurewayAPIResponse<string>>;
      GetRepairSmsLogs: Server<SurewayAPIResponse<ById<IRepairLineSmsLog>>>;
      GetRepairLineWOUnits: Server<SurewayAPIResponse<ById<IRepairLineWOUnits>>>;
      AddRepairLineWOUnit: Server<SurewayAPIResponse<string>>;
      UpdateRepairLineWOUnit: Server<SurewayAPIResponse<string>>;
      GetMechanicTimesheetForApproval: Server<SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>>;
      GetMechanicTimesheetForApprovalGroupByDate: Server<SurewayAPIResponse<IMechanicTimesheetForApprovalGroupByDate[]>>;
      SaveManagerCommentsForDefectItem: Server<SurewayAPIResponse<string>>;
      GetMechanicLogsByDefectID: Server<SurewayAPIResponse<IMechanicLogsByDefectID[]>>;
      GetRepairLineDefectItemsByRepairID: Server<SurewayAPIResponse<IRepairLineItem[]>>;
      GetTechServices: Server<SurewayAPIResponse<ById<ITechService>>>;
      GetTechServicesByUnitID: Server<SurewayAPIResponse<ITechService[]>>;
      AddTechService: Server<SurewayAPIResponse<string>>;
      UpdateTechService: Server<SurewayAPIResponse<string>>;
      GetMechanicTimecardVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
    },
    Inspection_Form: {
      GetUnitInspectionSheets: Server<SurewayAPIResponse<IGetUnitInspectionSheets[]>>;
      GetUnitInspectionFormByID: Server<SurewayAPIResponse<IGetUnitInspectionFormByID>>;
      GetUnitInspectionSheetByID: Server<SurewayAPIResponse<IGetUnitInspectionSheetByID>>;
      SaveInspectionSheet: Server<SurewayAPIResponse<string>>;
    }
    Filter_Cut: {
      GetFilterCuts: Server<SurewayAPIResponse<ById<IFilterCut>>>;
      AddFilterCut: Server<SurewayAPIResponse<string>>;
      UpdateFilterCut: Server<SurewayAPIResponse<string>>;
    },
    PM_Sheets: {
      GetServiceDues: Server<SurewayAPIResponse<ById<IServiceDue>>>;
      GetServiceSections: Server<SurewayAPIResponse<ById<IServiceSection>>>;
      GetPMLookups: Server<SurewayAPIResponse<IPMLookups>>;
      AddServiceDue: Server<SurewayAPIResponse<string>>;
      UpdateServiceDue: Server<SurewayAPIResponse<string>>;
      AddServiceSection: Server<SurewayAPIResponse<string>>;
      UpdateServiceSection: Server<SurewayAPIResponse<string>>;
      GetServiceSheetReport: Server<SurewayAPIResponse<ById<IServiceSheetReport>>>;
      ApproveServiceSheet: Server<SurewayAPIResponse<string>>;
      GetCompanies: Server<SurewayAPIResponse<IGetCompaniesResponse[]>>;
      GetPMSheetVersionHistory: Server<SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>>;
      GetPMSheetReportById: Server<SurewayAPIResponse<IServiceSheetReport>>;
      GetPMSheetsReport: Server<SurewayAPIResponse<IPMSheetsReport[]>>;
    },
    Attachment: {
      GetAttachments: Server<SurewayAPIResponse<ById<IAttachment>>>;
      GetAttachmentss: Server<SurewayAPIResponse<ById<IGetAttachment>>>;
      getAttachmentByID: Server<SurewayAPIResponse<IGetAttachmentByID>>;
      GetTypes: Server<SurewayAPIResponse<ById<IAttachmentType>>>;
      GetCouplers: Server<SurewayAPIResponse<ById<ICoupler>>>;
      AddAttachmentType: Server<SurewayAPIResponse<string>>;
      UpdateAttachmentType: Server<SurewayAPIResponse<string>>;
      AddAttachmentCoupler: Server<SurewayAPIResponse<string>>;
      UpdateAttachmentCoupler: Server<SurewayAPIResponse<string>>;
      AddAttachment: Server<SurewayAPIResponse<string>>;
      UpdateAttachment: Server<SurewayAPIResponse<string>>;
      GetAttachmentVersionHistory: Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>>;
      GetAttachmentMoves: Server<SurewayAPIResponse<ById<IAttachmentMove>>>;
      AddAttachmentMove: Server<SurewayAPIResponse<string>>;
      UpdateAttachmentMove: Server<SurewayAPIResponse<string>>;
      GetStyles: Server<SurewayAPIResponse<ById<IStyle>>>;
      AddAttachmentStyle: Server<SurewayAPIResponse<string>>;
      UpdateAttachmentStyle: Server<SurewayAPIResponse<string>>;
      GetMoveRequestHistory: Server<SurewayAPIResponse<ById<IMoveRequestHistory>>>;
      GetAttachmentListGET: Server<SurewayAPIResponse<ById<IAttachmentListGET>>>;
      AddAttachmentListGET: Server<SurewayAPIResponse<string>>;
      UpdateAttachmentListGET: Server<SurewayAPIResponse<string>>;
      DeleteAttachmentListGET: Server<SurewayAPIResponse<string>>;
      CloneAttachmentGET: Server<SurewayAPIResponse<string>>;
    },
    WORKING_SITE_LOGS: {
      GetFieldLookups: Server<SurewayAPIResponse<IFieldLookup>>;
      GetWorkingSiteLogs: Server<SurewayAPIResponse<ById<IWorkingSiteLog>>>;
      GetWSSLogs: Server<SurewayAPIResponse<ById<IWSSLogs>>>;
      GetWorkingSiteLogById: Server<SurewayAPIResponse<IWorkingSiteLog>>;
      AddWorkingSiteLog: Server<SurewayAPIResponse<string>>;
      UpdateWorkingSiteLog: Server<SurewayAPIResponse<string>>;
      VersionHistory: Server<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>>;
      GetWorkingSiteLogsOnCall: Server<SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>>;
      UpdateWorkingSiteLogOnCall: Server<SurewayAPIResponse<string>>;
      GetCrewByUsername: Server<SurewayAPIResponse<IGetCrewByUsername>>;
      GetFieldEmployees: Server<SurewayAPIResponse<ById<IFieldEmployee>>>;
      AddFieldEmployee: Server<SurewayAPIResponse<string>>;
      UpdateFieldEmployee: Server<SurewayAPIResponse<string>>;
      GetForemanMessaging: Server<SurewayAPIResponse<ById<IForemanMessaging>>>;
      AddForemanMessaging: Server<SurewayAPIResponse<string>>;
      UpdateForemanMessaging: Server<SurewayAPIResponse<string>>;
      GetWSSLogsHistoryBySiteID: Server<SurewayAPIResponse<IWSSLogsHistory[]>>;
      GetWSSLogInfoByID: Server<SurewayAPIResponse<IGetWSSLogInfoByID>>;
      GetJournels: Server<SurewayAPIResponse<ById<IJournels>>>;
      AddJournel: Server<SurewayAPIResponse<string>>;
      UpdateJournel: Server<SurewayAPIResponse<string>>;
      GetJournalVersionHistory: Server<SurewayAPIResponse<ById<IGetJournalVersionHistory>>>;
      GetCrewVersionHistory: Server<SurewayAPIResponse<ById<IGetCrewVersionHistory>>>;
    },
    OPERATOR_LOGS: {
      AddOperatorLog: Server<SurewayAPIResponse<string>>;
      UpdateOperatorLog: Server<SurewayAPIResponse<string>>;
      ValidateOperatorLogin: Server<IValidateOperatorLogin>;
      GetOperatorLogs: Server<SurewayAPIResponse<ById<IOperatorLogs>>>;
    },
    MECHANICS: {
      GetMechanics: Server<SurewayAPIResponse<ById<IMechanic>>>;
      AddMechanic: Server<SurewayAPIResponse<string>>;
      UpdateMechanic: Server<SurewayAPIResponse<string>>;
      ValidateMechanic: Server<IValidateMechanicLogin>;
      GetMechanicLogsById: Server<SurewayAPIResponse<ById<IMechanicLogById>>>;
      AddMechanicLog: Server<SurewayAPIResponse<string>>;
      UpdateMechanicLog: Server<SurewayAPIResponse<string>>;
      UpdateRepairLineByMechanic: Server<SurewayAPIResponse<string>>;
    },
    Dispatch: {
      GetSwampers: Server<SurewayAPIResponse<ById<ISwamper>>>;
      AddSwamper: Server<SurewayAPIResponse<string>>;
      UpdateSwamper: Server<SurewayAPIResponse<string>>;
      GetServiceTrucks: Server<SurewayAPIResponse<ById<IServiceTruck>>>;
      AddServiceTruck: Server<SurewayAPIResponse<string>>;
      UpdateServiceTruck: Server<SurewayAPIResponse<string>>;
      GetDriversAssignment: Server<SurewayAPIResponse<ById<IDriversAssignment>>>;
      SaveDriverAssignment: Server<SurewayAPIResponse<string>>;
      GetDriversAssignmentHistory: Server<SurewayAPIResponse<ById<IDriversAssignmentHistory>>>;
      GetServiceTruckCommunicationLogs: Server<SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>>;
      GetSTSchedules: Server<SurewayAPIResponse<ById<ISTSchedules>>>;
      UpdateSTSchedules: Server<SurewayAPIResponse<string>>;
      GetDriverAssignmentVersionHistory: Server<SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>>;
      GetSTTimesheetForApprovalGroupByDate: Server<SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>>;
      GetSTLogs: Server<SurewayAPIResponse<ISTLogs[]>>;
      ApproveSTTimesheet: Server<SurewayAPIResponse<string>>;
      RejectSTTimesheet: Server<SurewayAPIResponse<string>>;
    },
    SOCT: {
      GetSoctList: Server<ISoctList[]>;
      UpdateSoctReport: Server<SurewayAPIResponse<string>>;
      GetSoctReport: Server<SurewayAPIResponse<ById<ISoctReport>>>;
      GetSOCTLookups: Server<SurewayAPIResponse<ISOCTLookup>>;
      GetSOCTCustomServiceItemsBySOCTID: Server<SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>>;
      GetBulkMeterReadings: Server<SurewayAPIResponse<ById<IBulkMeterReading>>>;
      GetMeterReadingById: Server<SurewayAPIResponse<IMeterReading>>;
      AddMeterReading: Server<SurewayAPIResponse<string>>;
      UpdateMeterReading: Server<SurewayAPIResponse<string>>;
      GetSOCTById: Server<SurewayAPIResponse<ISOCT>>;
      GetMeterHistoryBySOCTID: Server<SurewayAPIResponse<ById<IMeterHistory>>>;
      AddSOCT: Server<SurewayAPIResponse<string>>;
      UpdateSOCT: Server<SurewayAPIResponse<string>>;
      GetServiceHistoryBySOCTID: Server<SurewayAPIResponse<ById<IServiceHistory>>>;
      GetScheduleServiceHistoryById: Server<SurewayAPIResponse<IScheduleServiceHistory>>;
      AddScheduleServiceHistory: Server<SurewayAPIResponse<string>>;
      UpdateScheduleServiceHistory: Server<SurewayAPIResponse<string>>;
    },
    EXTERNAL_USER: {
      GetExternalUsers: Server<SurewayAPIResponse<ById<IExternalUser>>>;
      AddExternalUser: Server<SurewayAPIResponse<string>>;
      UpdateExternalUser: Server<SurewayAPIResponse<string>>;
    },
    IMAGE_GALLERY: {
      GET_IG_LOOKUPS: Server<SurewayAPIResponse<IIGLookups>>;
    },
    EQUIPMENT_MANUALS: {
      GetEquipmentManuals: Server<SurewayAPIResponse<ById<IEquipmentManual>>>;
      AddEquipmentManual: Server<SurewayAPIResponse<string>>;
      UpdateEquipmentManual: Server<SurewayAPIResponse<string>>;
    },
    Vendor: {
      AddVendorOrder: Server<SurewayAPIResponse<string>>;
      UpdateVendorOrder: Server<SurewayAPIResponse<string>>;
      GetVendorOrderByID: Server<SurewayAPIResponse<IGetVendorOrderByID>>;
      GetVendorOrder: Server<SurewayAPIResponse<ById<IVendorOrder>>>;
      GetVendorLookups: Server<SurewayAPIResponse<IVendorLookup>>;
      GetCostCodesByJobID: Server<SurewayAPIResponse<IGetCostCodesByJobID[]>>;
      GetVendorItemsByOrderID: Server<SurewayAPIResponse<IVendorOrderWork[]>>;
      GetVendorPOLogs: Server<SurewayAPIResponse<ById<IVendorPOLogs>>>;
      GetVendorPOLogByID: Server<SurewayAPIResponse<IGetVendorPOLogByID>>;
      SaveVendorPOLog: Server<SurewayAPIResponse<string>>;
    }
  },
  Survey: {
    AddMachineControl: Server<SurewayAPIResponse<string>>;
    AddMachineReceiver: Server<SurewayAPIResponse<string>>;
    AddMachineControlBox: Server<SurewayAPIResponse<string>>;
    UpdateMachineControl: Server<SurewayAPIResponse<string>>;
    UpdateMachineReceiver: Server<SurewayAPIResponse<string>>;
    UpdateMachineControlBox: Server<SurewayAPIResponse<string>>;
    GetSurveyLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    GetMachineControl: Server<SurewayAPIResponse<ById<IMachineControl>>>;
    GetMachineReceiver: Server<SurewayAPIResponse<ById<IMachineReceiver>>>;
    GetMachineControlBoxes: Server<SurewayAPIResponse<ById<IMachineControlBoxes>>>;
    GetSurveyAttachments: Server<SurewayAPIResponse<ById<IAttachments>>>;
    AddSurveyAttachment: Server<SurewayAPIResponse<string>>;
    UpdateSurveyAttachment: Server<SurewayAPIResponse<string>>;
    AddBaseKit: Server<SurewayAPIResponse<string>>;
    UpdateBaseKit: Server<SurewayAPIResponse<string>>;
    GetBaseKit: Server<SurewayAPIResponse<ById<IBaseKit>>>;
    AddRoverKit: Server<SurewayAPIResponse<string>>;
    UpdateRoverKit: Server<SurewayAPIResponse<string>>;
    GetRoverKit: Server<SurewayAPIResponse<ById<IRoverKit>>>;
    AddRepeaterKit: Server<SurewayAPIResponse<string>>;
    UpdateRepeaterKit: Server<SurewayAPIResponse<string>>;
    GetRepeaterKit: Server<SurewayAPIResponse<ById<IRepeaterKit>>>;
    AddLaser: Server<SurewayAPIResponse<string>>;
    UpdateLaser: Server<SurewayAPIResponse<string>>;
    GetLaser: Server<SurewayAPIResponse<ById<ILasers>>>;
    AddLevel: Server<SurewayAPIResponse<string>>;
    UpdateLevel: Server<SurewayAPIResponse<string>>;
    GetLevel: Server<SurewayAPIResponse<ById<ILevels>>>;
  }
};

const reducerMap: ReducerMap<IStore> = {
  GetToken: GetTokenReducer,
  GetAuthorization: GetAuthorizationReducer,
  ValidateShopGroup: ValidateShopGroupReducer,
  GetLookups: GetLookupsReducer,
  EXTERNAL: combineReducers({
    MOVE_REQUEST: GetMoveRequestReducer,
    GET_EMPLOYEE_MESSAGES_BY_TOKEN: GetEmployeeMessagesByTokenReducer,
    SAVE_EMPLOYEE_MESSAGE_RESPONSE: SaveEmployeeMessageResponseReducer,
    GET_FORM_RESPONSE_BY_USER_TOKEN: GetFormResponseByUserTokenReducer,
    SAVE_CREW_SIGNATURE_BY_ID: SaveCrewSignatureByIDReducer,
  }),
  Accounting: combineReducers({
    Jobs: combineReducers({
      GetJobs: GetJobsReducer,
      AddJob: AddJobReducer,
      UpdateJob: UpdateJobReducer,
      GetJobsPits: GetJobsPitsReducer,
      AddJobPit: AddJobPitReducer,
      UpdateJobPit: UpdateJobPitReducer,
      GetJobsMhr: GetJobsMHRReducer,
      AddJobMhr: AddJobMhrReducer,
      UpdateJobMhr: UpdateJobMhrReducer
    })
  }),
  SIL: combineReducers({
    KANTECH: combineReducers({
      GetKantechAdjustments: GetKantechAdjustmentsReducer,
      AddKantechAdjustment: AddKantechAdjustmentReducer,
      UpdateKantechAdjustment: UpdateKantechAdjustmentReducer
    }),
  }),
  Field: combineReducers({
    Manufacturer: GetShopManufacturersReducer,
    GetUnitHistoryById: GetUnitHistoryByIdReducer,
    AddManufacturer: AddShopManufacturersReducer,
    UpdateManufacturer: UpdateShopManufacturersReducer,
    ShopLookups: GetShopLookupsReducer,
    Models: GetShopModelsReducer,
    AddModel: AddShopModelReducer,
    UpdateModel: UpdateShopModelReducer,
    Equipments: GetShopEquipmentsReducer,
    GetEquipment: GetEquipmentReducer,
    GetEquipmentByID: GetEquipmentByIDReducer,
    AddEquipment: AddShopEquipmentReducer,
    UpdateEquipment: UpdateShopEquipmentReducer,
    GetEquipmentTypes: GetEquipmentTypesReducer,
    GetUnitUtilizationByID: GetUnitUtilizationByIDReducer,
    AddEquipmentType: AddEquipmentTypeReducer,
    UpdateEquipmentType: UpdateEquipmentTypeReducer,
    GetShopEquipmentColumns: GetShopEquipmentColumnsReducer,
    AssignEquipmentTypeColumn: AssignEquipmentTypeColumnReducer,
    GetEquipmentHistoryColumns: GetEquipmentHistoryColumnsReducer,
    GetEquipmentVersionHistory: GetEquipmentVersionHistoryReducer,
    GetFilter: GetFilterReducer,
    AddFilter: AddFilterReducer,
    UpdateFilter: UpdateFilterReducer,
    GetLocation: GetLocationReducer,
    AddLocation: AddLocationReducer,
    UpdateLocation: UpdateLocationReducer,
    GetFilterReport: GetFilterReportReducer,
    AddFilterPart: AddFilterPartReducer,
    DeleteFilterPart: DeleteFilterPartReducer,
    UpdateFilterPart: UpdateFilterPartReducer,
    GetUnitDetails: GetUnitDetailsReducer,
    GetMoveRequests: GetMoveRequestsReducer,
    SaveDriverAssignment: SaveDriverAssignmentReducer,
    GetDriverCommunicationLogs: GetDriverCommunicationLogsReducer,
    AddEquipmentCertificate: AddEquipmentCertificateReducer,
    GetETMoveAttachmentVersionHistory: GetETMoveAttachmentVersionHistoryReducer,
    GetForms: GetFormsReducer,
    GetFormLookup: GetFormLookupReducer,
    AddFormTemplate: AddFormTemplateReducer,
    UpdateFormTemplate: UpdateFormTemplateReducer,
    GetFormByTemplateID: GetFormByTemplateIDReducer,
    GetFormResponses: GetFormResponsesReducer,
    GetFormResponseByID: GetFormResponseByIDReducer,
    AddFormResponse: AddFormResponseReducer,
    UpdateFormResponse: UpdateFormResponseReducer,
    GetCrews: GetCrewsReducer,
    AddCrew: AddCrewReducer,
    UpdateCrew: UpdateCrewReducer,
    GetMedicalFacilities: GetMedicalFacilitiesReducer,
    AddMedicalFacility: AddMedicalFacilityReducer,
    UpdateMedicalFacility: UpdateMedicalFacilityReducer,
    Forms: combineReducers({
      GetFormSignatureHistory: GetFormSignatureHistoryReducer,
      GetFormResponseVersionHistoryByID: GetFormResponseVersionHistoryByIDReducer,
      GetFormVersionHistory: GetFormVersionHistoryReducer,
      SaveAcknowledgeResponse: SaveAcknowledgeResponseReducer,
      GetHazardControlPlanListByDepartmentID: GetHazardControlPlanListByDepartmentIDReducer,
      Admin: combineReducers({
        GetTasks: GetTasksReducer,
        AddTask: AddTaskReducer,
        UpdateTask: UpdateTaskReducer,
        GetDepartments: GetDepartmentsReducer,
        AddDepartment: AddDepartmentReducer,
        UpdateDepartment: UpdateDepartmentReducer,
        GetHazardTypes: GetHazardTypesReducer,
        AddHazardType: AddHazardTypeReducer,
        UpdateHazardType: UpdateHazardTypeReducer,
        GetControlPlans:GetControlPlansReducer,
        GetControlPlansNew: GetControlPlansNewReducer,
        AddControlPlan: AddControlPlanReducer,
        SaveControlPlan: SaveControlPlanReducer,
        UpdateControlPlan: UpdateControlPlanReducer,
        // UpdateControlPlanNew: UpdateControlPlanNewReducer,
        GetHazards: GetHazardsReducer,
        AddHazard: AddHazardReducer,
        UpdateHazard: UpdateHazardReducer,
        GetHazardByID: GetHazardByIDReducer,
        AddHazardNew: AddHazardNewReducer,
        UpdateHazardNew: UpdateHazardNewReducer,
      }),
      Cleanup: combineReducers({
        GetCleanupData: GetCleanupDataReducer,
        RemoveDepartmentFromItems: RemoveDepartmentFromItemsReducer
      })
    }),
    Filter_Cut: combineReducers({
      GetFilterCuts: GetFilterCutsReducer,
      AddFilterCut: AddFilterCutReducer,
      UpdateFilterCut: UpdateFilterCutReducer
    }),
    Equipment_Tracker: combineReducers({
      Admin: combineReducers({
        GetDrivers: GetETDriversReducer,
        AddDriver: AddETDriverReducer,
        UpdateDriver: UpdateETDriverReducer,
      }),
      GetEquipmentMoveRequest: GetEquipmentMoveRequestReducer,
      InitiateMove: InitiateMoveReducer,
      BulkMove: BulkMoveReducer,
      GetETLookups: GetETLookupsReducer,
      GetMappingView: GetMappingViewReducer
    }),
    GetDriver: GetDriverReducer,
    OPERATOR_LOGS: combineReducers({
      GetOperatorLogs: GetOperatorLogsReducer,
      AddOperatorLog: AddOperatorLogReducer,
      UpdateOperatorLog: UpdateOperatorLogReducer,
      ValidateOperatorLogin: ValidateOperatorLoginReducer
    }),
    MECHANICS: combineReducers({
      GetMechanics: GetMechanicsReducer,
      AddMechanic: AddMechanicReducer,
      UpdateMechanic: UpdateMechanicReducer,
      ValidateMechanic: ValidateMechanicLoginReducer,
      GetMechanicLogsById: GetMechanicLogsByIdReducer,
      AddMechanicLog: AddMechanicLogReducer,
      UpdateMechanicLog: UpdateMechanicLogReducer,
      UpdateRepairLineByMechanic: UpdateRepairLineByMechanicReducer,
    }),
    Repair_Line: combineReducers({
      GetRepairLine: GetRepairLinesReducer,
      AddRepairLine: AddRepairLineReducer,
      UpdateRepairLine: UpdateRepairLineReducer,
      GetMechanicLogs: GetMechanicLogsReducer,
      GetPayrollMechanicLogs: GetPayrollMechanicLogsReducer,
      GetPayrollMechanicLogsReport: GetPayrollMechanicLogsReportReducer,
      GetPayrollMechanicLogsSummary: GetPayrollMechanicLogsSummaryReducer,
      PayrollMechanicLogsUpload: PayrollMechanicLogsUploadReducer,
      GetComponentWaitingParts: GetComponentWaitingPartsReducer,
      SaveComponentWaitingParts: SaveComponentWaitingPartsReducer,
      SaveMechanicAssignment: SaveMechanicAssignmentReducer,
      ApproveMechanicLog: ApproveMechanicLogReducer,
      RejectMechanicLog: RejectMechanicLogReducer,
      GetRepairLineSummary: GetRepairLineSummaryReducer,
      GetRepairLineByMechanicId: GetRepairLineByMechanicIdReducer,
      GetRepairLineHistory: GetRepairLineHistoryReducer,
      WorkOrder: WorkOrderReducer,
      GetRepairLineWO: GetRepairLineWOReducer,
      GetRepairLineVersionHistory: GetRepairLineVersionHistoryReducer,
      getRepairLineById: GetRepairLineByIdReducer,
      getRepairLineItemById: GetRepairLineItemByIdReducer,
      SaveRepairLineItem: SaveRepairLineReducer,
      GetRepairSmsLogs: GetRepairSmsLogsReducer,
      GetRepairLineWOUnits: GetRepairLineWOUnitsReducer,
      AddRepairLineWOUnit: AddRepairLineWOUnitReducer,
      UpdateRepairLineWOUnit: UpdateRepairLineWOUnitReducer,
      GetMechanicTimesheetForApproval : GetMechanicTimesheetForApprovalReducer,
      GetMechanicTimesheetForApprovalGroupByDate : GetMechanicTimesheetForApprovalGroupByDateReducer,
      SaveManagerCommentsForDefectItem : SaveManagerCommentsForDefectItemReducer,
      GetMechanicLogsByDefectID: GetMechanicLogsByDefectIDReducer,
      GetRepairLineDefectItemsByRepairID: GetRepairLineDefectItemsByRepairIDReducer,
      GetTechServices: GetTechServicesReducer,
      GetTechServicesByUnitID: GetTechServicesByUnitIDReducer,
      AddTechService: AddTechServiceReducer,
      UpdateTechService: UpdateTechServiceReducer,
      AcknowledgeLogByPayroll: AcknowledgeLogByPayrollReducer,
      GetMechanicTimecardVersionHistory: GetMechanicTimecardVersionHistoryReducer,
    }),
    Inspection_Form: combineReducers({
      GetUnitInspectionSheets: GetUnitInspectionSheetsReducer,
      GetUnitInspectionFormByID: GetUnitInspectionFormByIDReducer,
      GetUnitInspectionSheetByID: GetUnitInspectionSheetByIDReducer,
      SaveInspectionSheet: SaveInspectionSheetReducer,
    }),
    Attachment: combineReducers({
      GetAttachments: GetAttachmentsReducer,
      GetAttachmentss: GetAttachmentssReducer,
      getAttachmentByID: GetAttachmentByIDReducer,
      GetTypes: GetTypesReducer,
      GetCouplers: GetCouplersReducer,
      AddAttachmentType: AddAttachmentTypeReducer,
      UpdateAttachmentType: UpdateAttachmentTypeReducer,
      AddAttachmentCoupler: AddAttachmentCouplerReducer,
      UpdateAttachmentCoupler: UpdateAttachmentCouplerReducer,
      AddAttachment: AddAttachmentReducer,
      UpdateAttachment: UpdateAttachmentReducer,
      GetAttachmentVersionHistory: GetAttachmentVersionHistoryReducer,
      GetAttachmentMoves: GetAttachmentMovesReducer,
      AddAttachmentMove: AddAttachmentMoveReducer,
      UpdateAttachmentMove: UpdateAttachmentMoveReducer,
      GetStyles: GetStylesReducer,
      AddAttachmentStyle: AddAttachmentStyleReducer,
      UpdateAttachmentStyle: UpdateAttachmentStyleReducer,
      GetMoveRequestHistory: GetMoveRequestHistoryReducer,
      GetAttachmentListGET: GetAttachmentListGETReducer,
      AddAttachmentListGET: AddAttachmentListGETReducer,
      UpdateAttachmentListGET: UpdateAttachmentListGETReducer,
      DeleteAttachmentListGET: DeleteAttachmentListGETReducer,
      CloneAttachmentGET: CloneAttachmentGETReducer
    }),
    PM_Sheets: combineReducers({
      GetServiceDues: GetServiceDuesReducer,
      GetServiceSections: GetServiceSectionsReducer,
      GetPMLookups: GetPMLookupsReducer,
      AddServiceDue: AddServiceDueReducer,
      UpdateServiceDue: UpdateServiceDueReducer,
      AddServiceSection: AddServiceSectionReducer,
      UpdateServiceSection: UpdateServiceSectionReducer,
      GetServiceSheetReport: GetServiceSheetReportReducer,
      ApproveServiceSheet: ApproveServiceSheetReducer,
      GetCompanies: GetCompaniesReducer,
      GetPMSheetVersionHistory: GetPMSheetVersionHistoryReducer,
      GetPMSheetReportById: GetPMSheetReportByIdReducer,
      GetPMSheetsReport: GetPMSheetsReportReducer,
    }),
    WORKING_SITE_LOGS: combineReducers({
      GetWorkingSiteLogs: GetWorkingSiteLogsReducer,
      GetWSSLogs: GetWSSLogsReducer,
      GetWorkingSiteLogById: GetWorkingSiteLogByIdReducer,
      GetFieldLookups: GetFieldLookupsReducer,
      AddWorkingSiteLog: AddWorkingSiteLogReducer,
      UpdateWorkingSiteLog: UpdateWorkingSiteLogReducer,
      VersionHistory: GetWorkingSiteLogVersionHistoryReducer,
      GetWorkingSiteLogsOnCall: GetWorkingSiteLogsOnCallReducer,
      UpdateWorkingSiteLogOnCall: UpdateWorkingSiteLogOnCallReducer,
      GetCrewByUsername: GetCrewByUsernameReducer,
      GetFieldEmployees: GetFieldEmployeesReducer,
      AddFieldEmployee: AddFieldEmployeeReducer,
      UpdateFieldEmployee: UpdateFieldEmployeeReducer,
      GetForemanMessaging: GetForemanMessagingReducer,
      AddForemanMessaging: AddForemanMessagingReducer,
      UpdateForemanMessaging: UpdateForemanMessagingReducer,
      GetWSSLogsHistoryBySiteID: GetWSSLogsHistoryBySiteIDReducer,
      GetWSSLogInfoByID: GetWSSLogInfoByIDReducer,
      GetJournels: GetJournelsReducer,
      AddJournel: AddJournelReducer,
      UpdateJournel: UpdateJournelReducer,
      GetJournalVersionHistory: GetJournalVersionHistoryReducer,
      GetCrewVersionHistory: GetCrewVersionHistoryReducer,
    }),
    Dispatch: combineReducers({
      GetSwampers: GetSwampersReducer,
      AddSwamper: AddSwamperReducer,
      UpdateSwamper: UpdateSwamperReducer,
      GetServiceTrucks: GetServiceTrucksReducer,
      AddServiceTruck: AddServiceTruckReducer,
      UpdateServiceTruck: UpdateServiceTruckReducer,
      GetDriversAssignment: GetDriversAssignmentReducer,
      SaveDriverAssignment: SaveDriversAssignmentReducer,
      GetDriversAssignmentHistory: GetDriversAssignmentHistoryReducer,
      GetServiceTruckCommunicationLogs: GetServiceTruckCommunicationLogsReducer,
      GetSTSchedules: GetSTSchedulesReducer,
      UpdateSTSchedules: UpdateSTSchedulesReducer,
      GetDriverAssignmentVersionHistory: GetDriverAssignmentVersionHistoryReducer,
      GetSTTimesheetForApprovalGroupByDate: GetSTTimesheetForApprovalGroupByDateReducer,
      GetSTLogs: GetSTLogsReducer,
      ApproveSTTimesheet: ApproveSTTimesheetReducer,
      RejectSTTimesheet: RejectSTTimesheetReducer,
    }),
    SOCT: combineReducers({
      GetSoctList: GetSoctListReducer,
      GetSoctReport: GetSoctReportReducer,
      UpdateSoctReport: UpdateSoctReportReducer,
      GetBulkMeterReadings: GetBulkMeterReadingsReducer,
      GetSOCTLookups : GetSOCTLookupsReducer,
      GetSOCTCustomServiceItemsBySOCTID : GetSOCTCustomServiceItemsBySOCTIDReducer,
      GetMeterReadingById: GetMeterReadingByIdReducer,
      AddMeterReading: AddMeterReadingReducer,
      UpdateMeterReading: UpdateMeterReadingReducer,
      GetSOCTById: GetSOCTByIdReducer,
      GetMeterHistoryBySOCTID: GetMeterHistoryBySOCTIDReducer,
      AddSOCT: AddSOCTReducer,
      UpdateSOCT: UpdateSOCTReducer,
      GetServiceHistoryBySOCTID: GetServiceHistoryBySOCTIDReducer,
      GetScheduleServiceHistoryById: GetScheduleServiceHistoryByIdReducer,
      AddScheduleServiceHistory: AddScheduleServiceHistoryReducer,
      UpdateScheduleServiceHistory: UpdateScheduleServiceHistoryReducer,
    }),
    EXTERNAL_USER: combineReducers({
      GetExternalUsers: GetExternalUsersReducer,
      AddExternalUser: AddExternalUserReducer,
      UpdateExternalUser: UpdateExternalUserReducer
    }),
    IMAGE_GALLERY: combineReducers({
      GET_IG_LOOKUPS: GetIGLookupsReducer,
    }),
    EQUIPMENT_MANUALS: combineReducers({
      GetEquipmentManuals: GetEquipmentManualsReducer,
      AddEquipmentManual: AddEquipmentManualReducer,
      UpdateEquipmentManual: UpdateEquipmentManualReducer,
    }),
    Vendor: combineReducers({
      AddVendorOrder: AddVendorOrderReducer,
      UpdateVendorOrder: UpdateVendorOrderReducer,
      GetVendorOrderByID: GetVendorOrderByIDReducer,
      GetVendorOrder: GetVendorOrderReducer,
      GetVendorItemsByOrderID: GetVendorItemsByOrderIDReducer,
      GetVendorLookups: GetVendorLookupsReducer,
      GetCostCodesByJobID: GetCostCodesByJobIDReducer,
      GetVendorPOLogs: GetVendorPOLogsReducer,
      GetVendorPOLogByID: GetVendorPOLogByIDReducer,
      SaveVendorPOLog: SaveVendorPOLogReducer
    })
  }),
  Survey: combineReducers({
    AddMachineControl: AddMachineControlReducer,
    AddMachineReceiver: AddMachineReceiverReducer,
    AddMachineControlBox: AddMachineControlBoxReducer,
    UpdateMachineControl: UpdateMachineControlReducer,
    UpdateMachineReceiver: UpdateMachineReceiverReducer,
    UpdateMachineControlBox: UpdateMachineControlBoxReducer,
    GetSurveyLookup: GetSurveyLookupReducer,
    GetMachineControl: GetMachineControlReducer,
    GetMachineReceiver: GetMachineReceiverReducer,
    GetMachineControlBoxes: GetMachineControlBoxesReducer,
    GetSurveyAttachments: GetSurveyAttachmentsReducer,
    AddSurveyAttachment: AddSurveyAttachmentReducer,
    UpdateSurveyAttachment: UpdateSurveyAttachmentReducer,
    AddBaseKit: AddBaseKitReducer,
    UpdateBaseKit: UpdateBaseKitReducer,
    GetBaseKit: GetBaseKitReducer,
    AddRoverKit: AddRoverKitReducer,
    UpdateRoverKit: UpdateRoverKitReducer,
    GetRoverKit: GetRoverKitReducer,
    AddRepeaterKit: AddRepeaterKitReducer,
    UpdateRepeaterKit: UpdateRepeaterKitReducer,
    GetRepeaterKit: GetRepeaterKitReducer,
    AddLaser: AddLaserReducer,
    UpdateLaser: UpdateLaserReducer,
    GetLaser: GetLasersReducer,
    AddLevel: AddLevelReducer,
    UpdateLevel: UpdateLevelReducer,
    GetLevel: GetLevelsReducer
  })
};

export default combineReducers<IStore>(reducerMap);