
export enum IGET_CREW_VERSION_HISTORY_REQUEST {
    REQUEST = "getCrewVersionHistory/GET_CREW_VERSION_HISTORY_REQUEST",
    SUCCESS = "getCrewVersionHistory/GET_CREW_VERSION_HISTORY_SUCCESS",
    FAILED = "getCrewVersionHistory/GET_CREW_VERSION_HISTORY_FAILED"
};

export interface IGetCrewVersionHistoryRequest {
    request: {
        id: number;
    },
    token: string;
};

export interface IGetCrewVersionHistory {
    id: number;
    itemID: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;
    foreman: string;
    crew_ID: number;
    crew_Name: string;
    emp_ID: number;
    employee: string;
};