import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_METER_READING_REQUEST, IUpdateMeterReadingRequest } from "./updateMeterReadingConstants";

export interface IUpdateMeterReadingLoadAction {
    type: IUPDATE_METER_READING_REQUEST.REQUEST;
    data: IUpdateMeterReadingRequest
}
export const updateMeterReadingLoadAction = (data: IUpdateMeterReadingRequest): IUpdateMeterReadingLoadAction => ({
    type: IUPDATE_METER_READING_REQUEST.REQUEST,
    data
});
export interface IUpdateMeterReadingSuccessAction {
    type: IUPDATE_METER_READING_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateMeterReadingLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateMeterReadingSuccessAction => ({
    type: IUPDATE_METER_READING_REQUEST.SUCCESS,
    message
});
export interface IUpdateMeterReadingLoadFailedAction {
    type: IUPDATE_METER_READING_REQUEST.FAILED;
    message: string;
}
export const updateMeterReadingLoadFailedAction = (message: string): IUpdateMeterReadingLoadFailedAction => ({
    type: IUPDATE_METER_READING_REQUEST.FAILED,
    message
});
