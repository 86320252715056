import { IUPDATE_ST_SCHEDULES_REQUEST, IUpdateSTSchedulesRequest } from "./updateSTSchedulesConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IUpdateSTSchedulesLoadAction {
    type: IUPDATE_ST_SCHEDULES_REQUEST.REQUEST;
    data: IUpdateSTSchedulesRequest
}
export const updateSTSchedulesLoadAction = (data: IUpdateSTSchedulesRequest): IUpdateSTSchedulesLoadAction => ({
    type: IUPDATE_ST_SCHEDULES_REQUEST.REQUEST,
    data
});
export interface IUpdateSTSchedulesSuccessAction {
    type: IUPDATE_ST_SCHEDULES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateSTSchedulesLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateSTSchedulesSuccessAction => ({
    type: IUPDATE_ST_SCHEDULES_REQUEST.SUCCESS,
    list
});
export interface IUpdateSTSchedulesLoadFailedAction {
    type: IUPDATE_ST_SCHEDULES_REQUEST.FAILED;
    message: string;
}
export const updateSTSchedulesLoadFailedAction = (message: string): IUpdateSTSchedulesLoadFailedAction => ({
    type: IUPDATE_ST_SCHEDULES_REQUEST.FAILED,
    message
});
