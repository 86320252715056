import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { SurewayAPIResponse } from '../../../../react/shared/publicInterfaces';
import { IInitiateMoveLoadAction, IInitiateMoveLoadFailedAction, IInitiateMoveSuccessAction, initiateMoveLoadFailedAction, initiateMoveLoadSuccessAction } from "./initiateMoveActions";
import { IINITIATE_MOVE_REQUEST } from './initiateMoveConstants';

export const initiateMoveEpic: Epic = (
    action$: ActionsObservable<IInitiateMoveLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IInitiateMoveSuccessAction | IInitiateMoveLoadFailedAction> =>
    action$.ofType(IINITIATE_MOVE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.INITIATE_MOVE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IInitiateMoveSuccessAction => {
                            return initiateMoveLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(initiateMoveLoadFailedAction(response)))
                    )
            )
        );