import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_JOBS_REQUEST, IJob } from "./getJobsConstants";
import { IGetJobsLoadAction, IGetJobsLoadFailedAction, IGetJobsSuccessAction, getJobsLoadFailedAction, getJobsLoadSuccessAction } from "./getJobsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const getJobsEpic: Epic = (
    action$: ActionsObservable<IGetJobsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetJobsSuccessAction | IGetJobsLoadFailedAction> =>
    action$.ofType(IGET_JOBS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IJob>>>(
                    END_POINTS.ACCOUNTING.JOBS.GET_JOBS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IJob>>): IGetJobsSuccessAction | IGetJobsLoadFailedAction => {
                            if(response.message === "Success"){
                                return getJobsLoadSuccessAction(response);
                            }
                            return getJobsLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getJobsLoadFailedAction(response.message)))
                    )
            )
        );