import React from "react";
import styled from "styled-components";
import { Link, RouteComponentProps } from "react-router-dom";
import { DARK_WHITE, MAIN_COLOR, MEDIA_QUERY_PHONE } from "../shared/theme";
import { ROUTE } from "../routes";
import { logo } from "../shared/icons";
import headerBack from "./headerImg.png";
import LogoutButton from "./logoutButton";
import { hasPayload, Server } from "../../redux/server";
import { ToolRentalsResponse } from "../shared/publicInterfaces";
import { IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ShopDropDown, ShopDropDownMobile } from "./shopDropDown";
import { LASecondaryButton } from "../shared/buttons";
import { getTokenFromUrl } from "../shared/constExports";


const HeaderStyles = styled.div`
    .main-header {
        display: flex;
        align-items: center;
        height: 45px;
        background-image: url(${headerBack});
        background-color: #cccccc;
        background-size: cover;
        width: 100%;

        .logo-container {
            display: flex;
            align-items: center;
            color: ${MAIN_COLOR};
            padding: 1rem 2rem;
            margin-right: auto;
        }

        .upn {
            margin-right: 1rem;
            color: #bf0000;
        }

        .webserver {
            margin-left: auto;
            margin-right: 1rem;
            color: ${DARK_WHITE};
        }

        .home-btn {
            margin-right: 1rem;
        }
        
        .logout-btn {
            margin-right: 1rem;
        }
    };

    .sub-header {
        display: flex;
        align-items: center;
        background-color: #fff;
        width: 100%;
        box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
        height: auto;

        @media (max-width: 1882px) {
            height: 70px;
        }
    };

    .logo-img {
        padding-left: 35px;
        width: 200px;
        height: 45px;
    };


    .small-logo {
        width: 45px;
        height: 45px;
    };

    .show-on-phone {
        display: none;
    };

    @media (max-width: ${MEDIA_QUERY_PHONE}) {
        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };

        .main-header .logo-container {
            margin-right: 0.5rem;
            padding: 0, 1rem, 1rem, 1rem;
        };

        .main-header .logo-container img {
            margin-right: 0;
        };
    };
`;


export const doNothing = (): void => { };

interface IHeaderProps extends RouteComponentProps {
    userName: string;
    webserver: string;
    validateGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};


const Header: React.FC<IHeaderProps> = React.memo((props: IHeaderProps) => {
    const homeClick = (): void => {
        props.history.push({
            pathname: ROUTE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    return <HeaderStyles>
        <div className="main-header">
            <Link to={(getTokenFromUrl(true) !== undefined) ? (ROUTE.INDEX + getTokenFromUrl(false)) : ROUTE.INDEX} className="logo-container">
                <img onClick={homeClick} src={logo} className="logo-img" alt="logo" title="logo" />
            </Link>

            <span className="webserver">
                {props.webserver}
            </span>

            <strong className="upn hide-on-phone">
                Hello, {props.userName? props.userName.split('.')[0].charAt(0).toUpperCase() + props.userName.split('.')[0].slice(1) : ''}
            </strong>

            <LASecondaryButton 
            label="Home" 
            onClick={homeClick} 
            className="mr-2 home-btn hide-on-phone" 
            />

            <div className="logout-btn hide-on-phone">
                <LogoutButton />
            </div>
        </div>

        {(hasPayload(props.validateGroup) && (props.validateGroup.payload.response.adminAccess !== null)) &&
            (props.location.pathname.includes(ROUTE.FIELD.INDEX)) &&
            <>
                <div className="sub-header hide-on-phone">
                    <ShopDropDown {...props} />
                </div>

                <div className="sub-header show-on-phone">
                {(window.location.href.toLowerCase().indexOf("token") === -1) &&                
                    <ShopDropDownMobile {...props} /> }
                </div>
            </>}
    </HeaderStyles>
});

export default Header;
