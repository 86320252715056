import { IUPDATE_EQUIPMENT_MANUAL_REQUEST } from "./updateEquipmentManualConstants";
import { IUpdateEquipmentManualLoadAction, IUpdateEquipmentManualLoadFailedAction, IUpdateEquipmentManualSuccessAction } from "./updateEquipmentManualActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateEquipmentManualLoadAction
    | IUpdateEquipmentManualSuccessAction
    | IUpdateEquipmentManualLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateEquipmentManualReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_EQUIPMENT_MANUAL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_EQUIPMENT_MANUAL_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_EQUIPMENT_MANUAL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};