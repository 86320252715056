import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { IGetRepairLineSmsLogsRequest, IRepairLineSmsLog } from "../../../redux/field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsConstants";
import { getRepairSmsLogsLoadAction } from "../../../redux/field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsActions";
import { getRepairSmsLogs } from "../../../redux/field/repairLine/getRepairLineSmsLogs/getRepairLineSmsLogsAccessor";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";

interface IRepairLineSmsLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getRepairLineSmsLog: Server<SurewayAPIResponse<ById<IRepairLineSmsLog>>>;
};

interface IRepairLineSmsLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRepairLineSmsLogsRequest: (data: IGetRepairLineSmsLogsRequest) => unknown;
};


interface IRepairLineSmsLogsOwnProps {

};

interface IRepairLineSmsLogsState {
};

const RepairLineSmsLogsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IRepairLineSmsLogsProps = RouteComponentProps
    & IRepairLineSmsLogsStoreProps
    & IRepairLineSmsLogsDispatchProps
    & IRepairLineSmsLogsOwnProps;

class RepairLineSmsLogs extends PureComponent<IRepairLineSmsLogsProps, IRepairLineSmsLogsState> {

    public constructor(props: IRepairLineSmsLogsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IRepairLineSmsLogsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getRepairLineSmsLog, getToken } = this.props;
        const getRole = pageAccessCheck(getToken, "repairLine");
        const data = hasPayload(getRepairLineSmsLog) ? Object.values(getRepairLineSmsLog.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Repair Lines" description="FIELD - REPAIR LINE SMS LOGS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <RepairLineSmsLogsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <h2 className="title">REPAIR LINE SMS LOGS</h2>
                                <hr />

                                {getRepairLineSmsLog.kind === STATUS_ENUM.LOADING && <LALoading message="Loading repair line sms logs..." />}
                                {getRepairLineSmsLog.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load repair line sms logs..." />}

                                {getRepairLineSmsLog.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onClick={undefinedFunction}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="repairLineSmsLogList"
                                                customRowColor={true}
                                                exportFileName="RepairLineSmsLogs"
                                                timeout={120}
                                                storageKey="repairLineSmsLogsSessionKey"
                                                onCustomClick={this.handleCustomClick}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    // { name: "mechanic_ID", caption: "Mechanic Id", type: "number" },
                                                    // { name: "repair_Line_ID", caption: "Repair Line Id", type: "number" },
                                                    { name: "mechanic_Name", caption: "Mechanic Name", type: "string" },
                                                    { name: "defect_Item", caption: "Defect Item", type: "button" },
                                                    { name: "repair_Type", caption: "Repair Type", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    { name: "message", caption: "Message", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", sortDesc: true },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "twilio_Status", caption: "Twilio Status", type: "string" },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </RepairLineSmsLogsStyles>}
            </PageSpacing>
        );
    }

    private handleCustomClick = (name: string, e:any) => {
        if(name === "defect_Item") {
            // console.log(e)
            if(e.row.data.repair_Line_ID > 0 && e.row.data.defect_ID > 0)
                window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + e.row.data.repair_Line_ID.toString() + "&defect_ID=" + e.row.data.defect_ID.toString()+ "&view=All");
        }
    }


    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getRepairLineSmsLog)) {
                    this.getDataForTable();
                };
            } else {

                this.props.history.push({
                    pathname: ROUTE.INDEX,
                    search: getTokenFromUrl(false)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getRepairLineSmsLogsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IRepairLineSmsLogsStoreProps => ({
    getToken: getToken(state),
    getRepairLineSmsLog: getRepairSmsLogs(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRepairLineSmsLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRepairLineSmsLogsRequest: (data: IGetRepairLineSmsLogsRequest) => dispatch(getRepairSmsLogsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairLineSmsLogs);