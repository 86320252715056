import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "./saveDriversAssignmentConstants";
import { ISaveDriversAssignmentLoadAction, ISaveDriversAssignmentLoadFailedAction, ISaveDriversAssignmentSuccessAction, 
    saveDriversAssignmentLoadFailedAction, saveDriversAssignmentLoadSuccessAction } from "./saveDriversAssignmentActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { SurewayAPIResponse } from '../../../../react/shared/publicInterfaces';

export const saveDriversAssignmentEpic: Epic = (
    action$: ActionsObservable<ISaveDriversAssignmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveDriversAssignmentSuccessAction | ISaveDriversAssignmentLoadFailedAction> =>
    action$.ofType(ISAVE_DRIVERS_ASSIGNMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.DISPATCH.SAVE_DRIVERS_ASSIGNMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveDriversAssignmentSuccessAction | ISaveDriversAssignmentLoadFailedAction => {
                            if(response !== null || response === "Success"){
                                return saveDriversAssignmentLoadSuccessAction(response);
                            } else {
                                return saveDriversAssignmentLoadFailedAction(response);
                            }
                        }),
                        catchError((response: string) => ActionsObservable.of(saveDriversAssignmentLoadFailedAction(response)))
                    )
            )
        );