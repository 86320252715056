import { IWSS } from "../../workingSiteLogs/getFieldLookups/getFieldLookupsConstants";

export enum IGET_SOCT_LOOKUPS_REQUEST {
    REQUEST = "getSOCTLookups/GET_SOCT_LOOKUPS_REQUEST",
    SUCCESS = "getSOCTLookups/GET_SOCT_LOOKUPS_SUCCESS",
    FAILED = "getSOCTLookups/GET_SOCT_LOOKUPS_FAILED"
};

export interface ISOCTLookupRequest {
    token: string;
    request: {
        page: string;
        SOCT_ID? : number
    };
};

export interface ISOCTLookup {
    units: IUnit[];
    wssList: IWSS[];
};

export interface IUnit {
    id: number;
    unit_Number: string;
    location_Details: string;
    reading_Type?: string;
    make?: string;
    model?: string;
    meter_Date?: string;
    meter_Reading?: number;
    socT_ID?: number;
    service_Date?: string;
    service_Reading?: number;
    service_Hours?: string;
};
