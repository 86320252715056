import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_MACHINE_RECEIVER_REQUEST, IMachineReceiver } from "./getMachineReceiversConstants";
import { IGetMachineReceiverLoadAction, IGetMachineReceiverLoadFailedAction, IGetMachineReceiverSuccessAction } from "./getMachineReceiversActions";
import { IUpdateMachineControlSuccessAction } from "../updateMachineControl/updateMachineControlActions";
import { IUPDATE_MACHINE_CONTROL_REQUEST } from "../updateMachineControl/updateMachineControlConstants";
import { IUpdateMachineControlBoxSuccessAction } from "../updateMachineControlBox/updateMachineControlBoxActions";
import { IUPDATE_MACHINE_CONTROL_BOX_REQUEST } from "../updateMachineControlBox/updateMachineControlBoxConstants";
import { IUpdateMachineReceiverSuccessAction } from "../updateMachineReceiver/updateMachineReceiverActions";
import { IUPDATE_MACHINE_RECEIVER_REQUEST } from "../updateMachineReceiver/updateMachineReceiverConstants";
import { IAddMachineControlSuccessAction } from "../addMachineControl/addMachineControlActions";
import { IADD_MACHINE_CONTROL_REQUEST } from "../addMachineControl/addMachineControlConstants";
import { IAddMachineControlBoxSuccessAction } from "../addMachineControlBox/addMachineControlBoxActions";
import { IAddMachineReceiverSuccessAction } from "../addMachineReceiver/addMachineReceiverActions";
import { IADD_MACHINE_RECEIVER_REQUEST } from "../addMachineReceiver/addMachineReceiverConstants";
import { IADD_MACHINE_CONTROL_BOX_REQUEST } from "../addMachineControlBox/addMachineControlBoxConstants";

type Actions =
    | IGetMachineReceiverLoadAction
    | IGetMachineReceiverSuccessAction
    | IGetMachineReceiverLoadFailedAction
    | IUpdateMachineControlSuccessAction
    | IUpdateMachineControlBoxSuccessAction
    | IUpdateMachineReceiverSuccessAction
    | IAddMachineReceiverSuccessAction
    | IAddMachineControlBoxSuccessAction
    | IAddMachineControlSuccessAction
    | IFlushDataSuccessAction;

export const GetMachineReceiverReducer = (state: Server<SurewayAPIResponse<ById<IMachineReceiver>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMachineReceiver>>> => {
    switch (action.type) {
        case IGET_MACHINE_RECEIVER_REQUEST.REQUEST:
            return loading;

        case IGET_MACHINE_RECEIVER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MACHINE_RECEIVER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_MACHINE_RECEIVER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_MACHINE_CONTROL_BOX_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_MACHINE_CONTROL_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MACHINE_CONTROL_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MACHINE_CONTROL_BOX_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MACHINE_RECEIVER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};