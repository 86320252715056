import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateMachineControlRequest } from "../addMachineControl/addMachineControlConstants";
import { IUPDATE_MACHINE_CONTROL_REQUEST } from "./updateMachineControlConstants";

export interface IUpdateMachineControlLoadAction {
    type: IUPDATE_MACHINE_CONTROL_REQUEST.REQUEST;
    data: IAddUpdateMachineControlRequest;
}
export const updateMachineControlLoadAction = (data: IAddUpdateMachineControlRequest): IUpdateMachineControlLoadAction => ({
    type: IUPDATE_MACHINE_CONTROL_REQUEST.REQUEST,
    data
});
export interface IUpdateMachineControlSuccessAction {
    type: IUPDATE_MACHINE_CONTROL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateMachineControlLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateMachineControlSuccessAction => ({
    type: IUPDATE_MACHINE_CONTROL_REQUEST.SUCCESS,
    list
});
export interface IUpdateMachineControlLoadFailedAction {
    type: IUPDATE_MACHINE_CONTROL_REQUEST.FAILED;
    message: string;
}
export const updateMachineControlLoadFailedAction = (message: string): IUpdateMachineControlLoadFailedAction => ({
    type: IUPDATE_MACHINE_CONTROL_REQUEST.FAILED,
    message
});
