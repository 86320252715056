import { IAddUpdateFieldEmployee } from "../addFieldEmployee/addFieldEmployeeConstants";

export enum IUPDATE_FIELDEMPLOYEE_REQUEST {
    REQUEST = "updateFieldEmployee/UPDATE_FIELDEMPLOYEE_REQUEST",
    SUCCESS = "updateFieldEmployee/UPDATE_FIELDEMPLOYEE_SUCCESS",
    FAILED = "updateFieldEmployee/UPDATE_FIELDEMPLOYEE_FAILED"
};

export interface IUpdateFieldEmployeeRequest {
    token: string;
    request: IAddUpdateFieldEmployee;
};