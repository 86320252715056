import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST } from "./updateRepairLineByMechanicConstants";
import { IUpdateRepairLineByMechanicLoadAction, IUpdateRepairLineByMechanicLoadFailedAction, IUpdateRepairLineByMechanicSuccessAction } from "./updateRepairLineByMechanicActions";

type Actions =
    | IUpdateRepairLineByMechanicLoadAction
    | IUpdateRepairLineByMechanicSuccessAction
    | IUpdateRepairLineByMechanicLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateRepairLineByMechanicReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.REQUEST:
            return loading;

        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};