import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentHistoryColumn } from "./getEquipmentHistoryColumnConstants";
import { IGetEquipmentHistoryColumnsLoadAction, IGetEquipmentHistoryColumnsLoadFailedAction, IGetEquipmentHistoryColumnsSuccessAction } from "./getEquipmentHistoryColumnActions";

type Actions =
    | IGetEquipmentHistoryColumnsLoadAction
    | IGetEquipmentHistoryColumnsSuccessAction
    | IGetEquipmentHistoryColumnsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetEquipmentHistoryColumnsReducer = (state: Server<SurewayAPIResponse<IGetEquipmentHistoryColumn[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetEquipmentHistoryColumn[]>> => {
    switch (action.type) {
        case IGET_EQUIPMENT_TYPES_REQUEST.REQUEST:
            return loading;

        case IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EQUIPMENT_TYPES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};