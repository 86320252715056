import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import {  } from "../getForms/getFormsConstants";
import { IFormResponseByID, IGET_FORM_RESPONSE_BY_ID_REQUEST, IGetFormResponseByIDRequest } from "./getFormResponseByIDConstants";

export interface IGetFormResponseByIDLoadAction {
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.REQUEST;
    data: IGetFormResponseByIDRequest
}
export const getFormResponseByIDLoadAction = (data: IGetFormResponseByIDRequest): IGetFormResponseByIDLoadAction => ({
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetFormResponseByIDSuccessAction {
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IFormResponseByID>;
}
export const getFormResponseByIDLoadSuccessAction = (list: SurewayAPIResponse<IFormResponseByID>): IGetFormResponseByIDSuccessAction => ({
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetFormResponseByIDLoadFailedAction {
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getFormResponseByIDLoadFailedAction = (message: string): IGetFormResponseByIDLoadFailedAction => ({
    type: IGET_FORM_RESPONSE_BY_ID_REQUEST.FAILED,
    message
});
