


const OutlinedRequiredStyle:any = {
    border: '2px solid orange',
    backgroundColor:'white'
};
const OutlinedRequiredStyleSelected:any = {
    border: '1px solid lightgrey',
    backgroundColor:'white'
};
const SelectedButtonStyle:any = {
  //  border: '1px solid #61dafb',
    border: '1px solid black',
    color:'black',
    fontWeight: 'bold',
    backgroundColor:'#ececec'
}
const MobileBoxStyle:any = {
    position: 'absolute' as 'absolute',
    width: '100%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '2ch'
  };
 
  // URL for SOCT API Calls
  const SOCT_URL:any = "https://api.sureway.ca";
  //const SOCT_URL:any = "http://localhost:51380";

export {OutlinedRequiredStyle, OutlinedRequiredStyleSelected, SelectedButtonStyle,MobileBoxStyle, SOCT_URL}