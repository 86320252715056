export enum IGET_AUTHORIZATION_REQUEST {
    REQUEST = "getAuthorization/GET_AUTHORIZATION_REQUEST",
    SUCCESS = "getAuthorization/GET_AUTHORIZATION_SUCCESS",
    FAILED = "getAuthorization/GET_AUTHORIZATION_FAILED"
};

export interface IAuthorizationRequest {
    token: string;
    request: {
        username: string;
        department: IAuthDepartment;
    };
};

export interface IAuthorization {
    page: IAuthPage;
    access: IRole;
};

export type IAuthPage = "Jobs";
export type IRole = "R" | "RW" | "NA" | "" | "Yes" | "No" | true | false;
export type IAuthDepartment = "accounting";