import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_LASER_REQUEST } from "./updateLaserConstants";
import {
    IUpdateLaserLoadAction, IUpdateLaserLoadFailedAction, IUpdateLaserSuccessAction,
    updateLaserLoadFailedAction, updateLaserLoadSuccessAction
} from "./updateLaserActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateLaserEpic: Epic = (
    action$: ActionsObservable<IUpdateLaserLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateLaserSuccessAction | IUpdateLaserLoadFailedAction> =>
    action$.ofType(IUPDATE_LASER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_LASER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateLaserSuccessAction | IUpdateLaserLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateLaserLoadSuccessAction(response);
                            } else {
                                return updateLaserLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateLaserLoadFailedAction(response.message)))
                    )
            )
        );