import { Grid, Button, Box, Modal, Backdrop, Typography, TextField} from "@mui/material";
import React from 'react';
import {getTrailerRates, updateTrailerRate} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {editData} from '../../trailerRatesPage';
import { MobileBoxStyle } from "../../../../shared/styles";


interface IProps{
    locationOptions:any
    closeEquipmentMoveForm:any;
    clickedItem:any;
    clickedLocation:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
    trailer_list:any[];
    loaded:boolean;
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }
  var edit_trailer_rate:any = {id:0, rate:0, ara_rate:0, description:''};
  var trailer_rates:any[] = [];
  var old_edit_desc:string = "";
  var grid_xs_width:number = 4;

export default class EditTrailerRatesForm extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], trailer_list:[],loaded:false};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    closePopup(){
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'rate':
              edit_trailer_rate.rate = event.target.value;
                break;
            case 'ara_rate':
              edit_trailer_rate.ara_rate = event.target.value;
                break;
            case 'description':
              edit_trailer_rate.description = event.target.value;
                break;
        }
        this.setState({});
    }
    async onSave(){
      var isUnique = true;
        if(edit_trailer_rate.rate != null && edit_trailer_rate.description!=''){
          this.state.trailer_list.map((rate:any) => {
            if((rate.description.toLowerCase().trim() != old_edit_desc.toLowerCase().trim()) && (rate.description.toLowerCase().trim() === edit_trailer_rate.description.toLowerCase().trim())){
              isUnique = false;
            }
          });
          if(isUnique){
            var res:any = await updateTrailerRate(edit_trailer_rate);
            if(res.status == 200){
                this.props.closeEquipmentMoveForm();
            }
            else{
              alert("Error updating move request");
            }
          }
          else{
            alert("Trailer Config already exists")
          }
        }
        else{
          alert("Trailer rate is mandatory");
        }
    }
    async componentDidMount(){
      if(window.innerWidth <=760){
        grid_xs_width = 12;
      }
      else{
        grid_xs_width = 4;
      }
      trailer_rates = await getTrailerRates();
      edit_trailer_rate.id = editData.id;
      edit_trailer_rate.description = editData.description;
      edit_trailer_rate.trailer_id = editData.trailer_ID;
      edit_trailer_rate.trailer = editData.trailer;
      edit_trailer_rate.rate = editData.rate;
      edit_trailer_rate.ara_rate = editData.arA_Rate;
      old_edit_desc = editData.description;
      this.setState({trailer_list:trailer_rates, loaded:true});
    }
    render(){
      if(this.state.loaded){

      
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <=760 ? MobileBoxStyle: BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Updating Trailer Rate</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Trailer Config" onChange={this.handleChange} name="description" defaultValue={edit_trailer_rate.description}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Rate" onChange={this.handleChange} name="rate" defaultValue={edit_trailer_rate.rate}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="ARA Rate" onChange={this.handleChange} name="ara_rate" defaultValue={edit_trailer_rate.ara_rate}></TextField>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                      </Grid></Box></Modal>
        );
                  }else{
                    return null;
                  }
    }
}