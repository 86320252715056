import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { ISAVE_CONTROL_PLAN_REQUEST, ISaveControlPlanRequest } from "./saveControlPlanConstants";


export interface ISaveControlPlanLoadAction {
    type: ISAVE_CONTROL_PLAN_REQUEST.REQUEST;
    data: ISaveControlPlanRequest
}
export const saveControlPlanLoadAction = (data: ISaveControlPlanRequest): ISaveControlPlanLoadAction => ({
    type: ISAVE_CONTROL_PLAN_REQUEST.REQUEST,
    data
});
export interface ISaveControlPlanSuccessAction {
    type: ISAVE_CONTROL_PLAN_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveControlPlanLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveControlPlanSuccessAction => ({
    type: ISAVE_CONTROL_PLAN_REQUEST.SUCCESS,
    message
});
export interface ISaveControlPlanLoadFailedAction {
    type: ISAVE_CONTROL_PLAN_REQUEST.FAILED;
    message: string;
}
export const saveControlPlanLoadFailedAction = (message: string): ISaveControlPlanLoadFailedAction => ({
    type: ISAVE_CONTROL_PLAN_REQUEST.FAILED,
    message
});
