
export enum IGET_IG_LOOKUPS_REQUEST {
    REQUEST = "getImageGalleryLookups/GET_IG_LOOKUPS_REQUEST",
    SUCCESS = "getImageGalleryLookups/GET_IG_LOOKUPS_SUCCESS",
    FAILED = "getImageGalleryLookups/GET_IG_LOOKUPS_FAILED"
};

export interface IIGLookupsRequest {
    token: string;
    request: {
        Unit_ID?: string;
        From_Date?: string;
        To_Date?: string;
        Working_Sub_Site_ID?: string;
    };
};


export interface IIGLookups {
    images: IIG_ImageLookup[];
};

export interface IIG_ImageLookup { 
    id: number;
    guid: string;
    // type:string;
    // type_ID: number;
    description:string;
    source:string;
    // source_table_item_id:number;
    url:string;
    // extension:string;
    // gps:string;
    // created_By: string;
    // modified_By: string;
    // created: string;
    // modified: string;
};