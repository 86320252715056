import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST } from "./getRepairLineDefectItemsByRepairIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetRepairLineDefectItemsByRepairIDLoadAction, IGetRepairLineDefectItemsByRepairIDLoadFailedAction, IGetRepairLineDefectItemsByRepairIDSuccessAction, getRepairLineDefectItemsByRepairIDLoadFailedAction, getRepairLineDefectItemsByRepairIDLoadSuccessAction } from "./getRepairLineDefectItemsByRepairIDActions";
import { IRepairLineItem } from "../getRepairLines/getRepairLinesConstants";

export const getRepairLineDefectItemsByRepairIDEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineDefectItemsByRepairIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineDefectItemsByRepairIDSuccessAction | IGetRepairLineDefectItemsByRepairIDLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IRepairLineItem[]>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_DEFECT_ITEM_BY_REPAIR_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IRepairLineItem[]>): IGetRepairLineDefectItemsByRepairIDSuccessAction => {
                            return getRepairLineDefectItemsByRepairIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineDefectItemsByRepairIDLoadFailedAction(response.message)))
                    )
            )
        );