
export enum IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST {
    REQUEST = "getUnitInspectionFormByID/GET_UNIT_INSPECTION_FORM_BY_ID_REQUEST",
    SUCCESS = "getUnitInspectionFormByID/GET_UNIT_INSPECTION_FORM_BY_ID_SUCCESS",
    FAILED = "getUnitInspectionFormByID/GET_UNIT_INSPECTION_FORM_BY_ID_FAILED"
};

export interface IGetUnitInspectionFormByIDRequest {
    request: {
        page: string;
        id: number;
    };
    token: string;
};

export interface IGetUnitInspectionFormByID {
    inspection: IInspection[];
};

export interface IInspection {
    type: string;
    items: IInspectionItem[];
}

export interface IInspectionItem {
    id: number;
    type_ID: number;
    item: string;
    active: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
}