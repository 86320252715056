
export enum IADD_SERVICE_TRUCK_REQUEST {
    REQUEST = "addServiceTruck/ADD_SERVICE_TRUCK_REQUEST",
    SUCCESS = "addServiceTruck/ADD_SERVICE_TRUCK_SUCCESS",
    FAILED = "addServiceTruck/ADD_SERVICE_TRUCK_FAILED"
};

export interface IAddServiceTruckRequest {
    token: string;
    request: IAddUpdateServiceTruck;
};

export interface IAddUpdateServiceTruck {
    ID: number;
    Unit_ID: number;
    Driver_UPN: string;
    Swamper_ID: number;
    Active: string;
    Notes: string;
    Created_By: string;
};