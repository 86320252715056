import { IGET_SWAMPERS_REQUEST, ISwamper, IGetSwamperRequest } from "./getSwampersConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IGetSwampersLoadAction {
    type: IGET_SWAMPERS_REQUEST.REQUEST;
    data: IGetSwamperRequest
}
export const getSwampersLoadAction = (data: IGetSwamperRequest): IGetSwampersLoadAction => ({
    type: IGET_SWAMPERS_REQUEST.REQUEST,
    data
});
export interface IGetSwampersSuccessAction {
    type: IGET_SWAMPERS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ISwamper>>;
}
export const getSwampersLoadSuccessAction = (list: SurewayAPIResponse<ById<ISwamper>>): IGetSwampersSuccessAction => ({
    type: IGET_SWAMPERS_REQUEST.SUCCESS,
    list
});
export interface IGetSwampersLoadFailedAction {
    type: IGET_SWAMPERS_REQUEST.FAILED;
    message: string;
}
export const getSwampersLoadFailedAction = (message: string): IGetSwampersLoadFailedAction => ({
    type: IGET_SWAMPERS_REQUEST.FAILED,
    message
});
