import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_VENDOR_LOOKUPS_REQUEST, IVendorLookup, IVendorLookupRequest } from "./getVendorLookupsConstants";

export interface IGetVendorLookupsLoadAction {
    type: IGET_VENDOR_LOOKUPS_REQUEST.REQUEST;
    data: IVendorLookupRequest
}
export const getVendorLookupsLoadAction = (data: IVendorLookupRequest): IGetVendorLookupsLoadAction => ({
    type: IGET_VENDOR_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetVendorLookupsSuccessAction {
    type: IGET_VENDOR_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IVendorLookup>;
}
export const getVendorLookupsLoadSuccessAction = (list: SurewayAPIResponse<IVendorLookup>): IGetVendorLookupsSuccessAction => ({
    type: IGET_VENDOR_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetVendorLookupsLoadFailedAction {
    type: IGET_VENDOR_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getVendorLookupsLoadFailedAction = (message: string): IGetVendorLookupsLoadFailedAction => ({
    type: IGET_VENDOR_LOOKUPS_REQUEST.FAILED,
    message
});
