import React, { useState } from "react";
import { IMappingView } from "../../../redux/field/equipmentTracker/getMappingView/getMappingViewConstants";
import { IETLookups } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsConstants";
import { LAPaperWithPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LATextField from "../../shared/textField";
import { YesOrNo, undefinedFunction } from "../../shared/constExports";
import LAAutoComplete from "../../shared/autoComplete";
import LATextArea from "../../shared/textArea";
import { IInitiateMove } from "../../../redux/field/equipmentTracker/initiateMove/initiateMoveConstants";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { FIELD_VALIDATOR_ERRORS } from "../../shared/fieldValidator";
import { LAPopover } from "../../shared/popOver";

interface IListViewInitiatePopup {
    data: IMappingView;
    lookup: IETLookups;
    onCancel: () => void;
    onSave: (data: IInitiateMove) => void;
};

export const ListViewInitiatePopup = React.memo((props: IListViewInitiatePopup): JSX.Element => {

    const [data, setDate] = useState<IInitiateMove>({
        Unit_ID: props.data.id,
        From_Site_ID: props.data.working_Site_ID,
        From_Sub_Site_ID: props.data.working_Site_Sub_ID,
        To_Site_ID: 0,
        Drive_Over: YesOrNo[1].name,
        Send_Email: YesOrNo[1].name,
        Moved: YesOrNo[1].name,
        Moved_Previously: YesOrNo[1].name,
        Request_Details: "",
        Created_By: "",
    });

    const onChange = (name: string, value: string): void => {
        setDate({
            ...data,
            [name]: value
        });
    };

    const onToSite = (event: unknown, value: any): void => {
        onChange("To_Site_ID", value !== null ? value.id : 0);
    };

    const onToSubSite = (event: unknown, value: any): void => {
        onChange("To_Sub_Site_ID", value !== null ? value.id : undefined);
    };

    const onEmail = (event: unknown, value: any): void => {
        onChange("Send_Email", value.name);
    };

    const onDriver = (event: unknown, value: any): void => {
        onChange("Drive_Over", value.name);
    };

    const onMoved = (event: unknown, value: any): void => {
        onChange("Moved", value.name);
    };

    const onPreviously = (event: unknown, value: any): void => {
        onChange("Moved_Previously", value.name);
    };

    const onSave = (): void => props.onSave(data);
    const toSubLocations = (data.To_Site_ID > 0) ? props.lookup.locationInfo.find(x => x.id === data.To_Site_ID)?.sub_Sites : [];

    return (
        <LAPopover open={true} onClose={props.onCancel} anchorRef={null}>
            <LAPaperWithPadding>
                <LAGrid>

                    <LAGridItem xs={12} className="text-center">
                        <strong>Confirm and Enter Move Request Information</strong>
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            label="Unit #"
                            name="unit_Number"
                            disabled={true}
                            fullWidth={true}
                            value={props.data.unit_Number}
                            onChange={undefinedFunction}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            label="From"
                            name="site_Name"
                            disabled={true}
                            fullWidth={true}
                            value={props.data.site_Name}
                            onChange={undefinedFunction}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            label="From Sub Location"
                            name="sub_Site_Name"
                            disabled={true}
                            fullWidth={true}
                            value={props.data.sub_Site_Name}
                            onChange={undefinedFunction}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            getOptionLabel="site_Name"
                            autoHighlight={true}
                            onChange={onToSite}
                            filterSelectedOptions={true}
                            option={props.lookup.locationInfo}
                            selectionRemove={undefinedFunction}
                            dropDownPlaceHolder="To"
                            errorText={(data.To_Site_ID === 0) ? FIELD_VALIDATOR_ERRORS.REQUIRED : undefined}
                            value={data.To_Site_ID ? props.lookup.locationInfo.find(q => q.id === data.To_Site_ID) : null}
                            defaultValue={data.To_Site_ID ? props.lookup.locationInfo.find(q => q.id === data.To_Site_ID) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={toSubLocations}
                            getOptionLabel="sub_Site_Name"
                            autoHighlight={true}
                            onChange={onToSubSite}
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="To Sub Location"
                            selectionRemove={undefinedFunction}
                            value={data.To_Sub_Site_ID && toSubLocations ? toSubLocations.find(q => q.id === data.To_Sub_Site_ID) : null}
                            defaultValue={data.To_Sub_Site_ID && toSubLocations ? toSubLocations.find(q => q.id === data.To_Sub_Site_ID) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={YesOrNo}
                            getOptionLabel="name"
                            autoHighlight={true}
                            onChange={onEmail}
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            dropDownPlaceHolder="Send Notification Email"
                            value={data.Send_Email ? YesOrNo.find(q => q.name === data.Send_Email) : null}
                            defaultValue={data.Send_Email ? YesOrNo.find(q => q.name === data.Send_Email) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={YesOrNo}
                            getOptionLabel="name"
                            autoHighlight={true}
                            onChange={onDriver}
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="Driver Over\Non-Billable"
                            selectionRemove={undefinedFunction}
                            value={data.Drive_Over ? YesOrNo.find(q => q.name === data.Drive_Over) : null}
                            defaultValue={data.Drive_Over ? YesOrNo.find(q => q.name === data.Drive_Over) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={YesOrNo}
                            getOptionLabel="name"
                            autoHighlight={true}
                            onChange={onMoved}
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="Moved"
                            selectionRemove={undefinedFunction}
                            value={data.Moved ? YesOrNo.find(q => q.name === data.Moved) : null}
                            defaultValue={data.Moved ? YesOrNo.find(q => q.name === data.Moved) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={YesOrNo}
                            getOptionLabel="name"
                            autoHighlight={true}
                            onChange={onPreviously}
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="Active"
                            selectionRemove={undefinedFunction}
                            value={data.Moved_Previously ? YesOrNo.find(q => q.name === data.Moved_Previously) : null}
                            defaultValue={data.Moved_Previously ? YesOrNo.find(q => q.name === data.Moved_Previously) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={6}>
                        <LATextArea
                            minRows={4}
                            rowsMax={10}
                            name="Request_Details"
                            label="Details"
                            fullWidth={true}
                            onChange={onChange}
                            value={data.Request_Details}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LASaveAndCancelButton
                            onSave={onSave}
                            saveButtonText="Save"
                            onCancel={props.onCancel}
                            cancelButtonText="Cancel/Close"
                            disableSave={(data.To_Site_ID === 0) ? true : undefined}
                        />
                    </LAGridItem>

                </LAGrid>
            </LAPaperWithPadding>
        </LAPopover>
    )
});