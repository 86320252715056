import { IAddTechService } from "../addTechService/addTechServiceConstants";

export enum IUPDATE_TECH_SERVICE_REQUEST {
    REQUEST = "updateTechService/UPDATE_TECH_SERVICE_REQUEST",
    SUCCESS = "updateTechService/UPDATE_TECH_SERVICE_SUCCESS",
    FAILED = "updateTechService/UPDATE_TECH_SERVICE_FAILED"
};

export interface IUpdateTechServiceRequest {
    token: string;
    request: IAddTechService;
};