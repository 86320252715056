import React from 'react';
import {Paper, Button} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import CreateSOCTMain from './createSOCTMain';
import {unitNumbers} from '../../../sotc';

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;
}
export default class CreateSOTCPage extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false};
    }
    renderSOCTMain(){
        this.setState({showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
    }
    
    render(){
        return(
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Creating new SOCT</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Button
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                   onClick={this.renderSOCTMain}
                                >
                                    Sureway Oil Change Tracker
                                </Button>
                              
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    </Box>
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                {this.state.showMain ?
                   <CreateSOCTMain history={this.props.history} unitNumbers={unitNumbers}></CreateSOCTMain>
                :null}
                    </Paper>
                    </Paper>
                </Paper>
            </div>
        )
    }
}