import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_EQUIPMENT_TYPE_REQUEST, IAddEquipmentTypeRequest } from "./addEquipmentTypeConstants";


export interface IAddEquipmentTypeLoadAction {
    type: IADD_EQUIPMENT_TYPE_REQUEST.REQUEST;
    data: IAddEquipmentTypeRequest
}
export const addEquipmentTypeLoadAction = (data: IAddEquipmentTypeRequest): IAddEquipmentTypeLoadAction => ({
    type: IADD_EQUIPMENT_TYPE_REQUEST.REQUEST,
    data
});
export interface IAddEquipmentTypeSuccessAction {
    type: IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addEquipmentTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddEquipmentTypeSuccessAction => ({
    type: IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS,
    message
});
export interface IAddEquipmentTypeLoadFailedAction {
    type: IADD_EQUIPMENT_TYPE_REQUEST.FAILED;
    message: string;
}
export const addEquipmentTypeLoadFailedAction = (message: string): IAddEquipmentTypeLoadFailedAction => ({
    type: IADD_EQUIPMENT_TYPE_REQUEST.FAILED,
    message
});
