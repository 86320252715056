import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_SERVICE_SECTIONS_REQUEST, IServiceSection } from "./getServiceSectionsConstants";
import { IGetServiceSectionsLoadAction, IGetServiceSectionsLoadFailedAction, IGetServiceSectionsSuccessAction } from "./getServiceSectionsActions";
import { IUpdateServiceSectionSuccessAction } from "../updateServiceSection/updateServiceSectionActions";
import { IAddServiceSectionSuccessAction } from "../addServiceSection/addServiceSectionActions";
import { IADD_SERVICE_SECTION_REQUEST } from "../addServiceSection/addServiceSectionConstants";
import { IUPDATE_SERVICE_SECTION_REQUEST } from "../updateServiceSection/updateServiceSectionConstants";
import { IADD_SERVICE_DUE_REQUEST } from "../addServiceDue/addServiceDueConstants";
import { IUPDATE_SERVICE_DUE_REQUEST } from "../updateServiceDue/updateServiceDueConstants";
import { IAddServiceDueSuccessAction } from "../addServiceDue/addServiceDueActions";
import { IUpdateServiceDueSuccessAction } from "../updateServiceDue/updateServiceDueActions";
import { IAPPROVE_SERVICE_SHEET_REQUEST } from "../approveServiceSheet/approveServiceSheetConstants";
import { IApproveServiceSheetSuccessAction } from "../approveServiceSheet/approveServiceSheetActions";

type Actions =
    | IGetServiceSectionsLoadAction
    | IGetServiceSectionsSuccessAction
    | IAddServiceSectionSuccessAction
    | IUpdateServiceSectionSuccessAction
    | IGetServiceSectionsLoadFailedAction
    | IApproveServiceSheetSuccessAction
    | IAddServiceDueSuccessAction
    | IUpdateServiceDueSuccessAction
    | IFlushDataSuccessAction;

export const GetServiceSectionsReducer = (state: Server<SurewayAPIResponse<ById<IServiceSection>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IServiceSection>>> => {
    switch (action.type) {
        case IGET_SERVICE_SECTIONS_REQUEST.REQUEST:
            return loading;

        case IGET_SERVICE_SECTIONS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SERVICE_SECTIONS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;
            
            case IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};