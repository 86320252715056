import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_REQUEST, IAddAttachmentRequest } from "./addAttachmentConstants";


export interface IAddAttachmentLoadAction {
    type: IADD_ATTACHMENT_REQUEST.REQUEST;
    data: IAddAttachmentRequest
}
export const addAttachmentLoadAction = (data: IAddAttachmentRequest): IAddAttachmentLoadAction => ({
    type: IADD_ATTACHMENT_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentSuccessAction {
    type: IADD_ATTACHMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentSuccessAction => ({
    type: IADD_ATTACHMENT_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentLoadFailedAction {
    type: IADD_ATTACHMENT_REQUEST.FAILED;
    message: string;
}
export const addAttachmentLoadFailedAction = (message: string): IAddAttachmentLoadFailedAction => ({
    type: IADD_ATTACHMENT_REQUEST.FAILED,
    message
});
