import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_MACHINE_RECEIVER_REQUEST } from "./updateMachineReceiverConstants";
import {
    IUpdateMachineReceiverLoadAction, IUpdateMachineReceiverLoadFailedAction, IUpdateMachineReceiverSuccessAction,
    updateMachineReceiverLoadFailedAction, updateMachineReceiverLoadSuccessAction
} from "./updateMachineReceiverActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateMachineReceiverEpic: Epic = (
    action$: ActionsObservable<IUpdateMachineReceiverLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateMachineReceiverSuccessAction | IUpdateMachineReceiverLoadFailedAction> =>
    action$.ofType(IUPDATE_MACHINE_RECEIVER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_MACHINE_RECEIVER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateMachineReceiverSuccessAction | IUpdateMachineReceiverLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateMachineReceiverLoadSuccessAction(response);
                            } else {
                                return updateMachineReceiverLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateMachineReceiverLoadFailedAction(response.message)))
                    )
            )
        );