import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_KANTECH_ADJUSTMENT_REQUEST, IUpdateKantechAdjustmentRequest } from "./updateKantechAdjustmentConstants";


export interface IUpdateKantechAdjustmentLoadAction {
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.REQUEST;
    data: IUpdateKantechAdjustmentRequest
}
export const updateKantechAdjustmentLoadAction = (data: IUpdateKantechAdjustmentRequest): IUpdateKantechAdjustmentLoadAction => ({
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.REQUEST,
    data
});
export interface IUpdateKantechAdjustmentSuccessAction {
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateKantechAdjustmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateKantechAdjustmentSuccessAction => ({
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.SUCCESS,
    message
});
export interface IUpdateKantechAdjustmentLoadFailedAction {
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const updateKantechAdjustmentLoadFailedAction = (message: string): IUpdateKantechAdjustmentLoadFailedAction => ({
    type: IUPDATE_KANTECH_ADJUSTMENT_REQUEST.FAILED,
    message
});
