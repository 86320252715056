import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateMachineControlBoxRequest } from "../addMachineControlBox/addMachineControlBoxConstants";
import { IUPDATE_MACHINE_CONTROL_BOX_REQUEST } from "./updateMachineControlBoxConstants";

export interface IUpdateMachineControlBoxLoadAction {
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.REQUEST;
    data: IAddUpdateMachineControlBoxRequest
}
export const updateMachineControlBoxLoadAction = (data: IAddUpdateMachineControlBoxRequest): IUpdateMachineControlBoxLoadAction => ({
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.REQUEST,
    data
});
export interface IUpdateMachineControlBoxSuccessAction {
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateMachineControlBoxLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateMachineControlBoxSuccessAction => ({
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.SUCCESS,
    list
});
export interface IUpdateMachineControlBoxLoadFailedAction {
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.FAILED;
    message: string;
}
export const updateMachineControlBoxLoadFailedAction = (message: string): IUpdateMachineControlBoxLoadFailedAction => ({
    type: IUPDATE_MACHINE_CONTROL_BOX_REQUEST.FAILED,
    message
});
