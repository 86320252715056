import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon, AttachmentIcon } from "../../shared/icons";
import { BLUE_COLOR, BLUE_GREEN_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading, { LACenteredLoading } from "../../shared/loading";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetRepairLineRequest, IRepairLine, IRepairLineItem } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { getRepairLines } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesAccessor";
import { getRepairLinesLoadAction } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesActions";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import LAErrorBox from "../../shared/errorBox";
import { getRepairLineSummaryLoadAction } from "../../../redux/field/repairLine/getRepairLineSummary/getRepairLineSummaryActions";
import { getRepairLineByMechanicIdLoadAction } from "../../../redux/field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdActions";
import { IGetRepairLineSummaryRequest, IRepairLineSummary, IRepairLineSummaryUnit } from "../../../redux/field/repairLine/getRepairLineSummary/getRepairLineSummaryConstants";
import { IGetRepairLineByMechanicIdRequest, IRepairLineByMechanicId } from "../../../redux/field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdConstants";
import { getRepairLineByMechanicId } from "../../../redux/field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdAccessor";
import { getRepairLineSummary } from "../../../redux/field/repairLine/getRepairLineSummary/getRepairLineSummaryAccessor";
import { RepairLineMechanicPopup, RepairLineSummary } from "./repairLineSummary";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { IGetRepairLineHistory, IGetRepairLineHistoryRequest } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryConstants";
import { RepairLineHistory } from "./repairLineHistory";
import { getRepairLineHistoryLoadAction } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryActions";
import { getRepairLineHistory } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryAccessor";
import { IGetRepairLineVersionHistory, IGetRepairLineVersionHistoryRequest } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryConstants";
import { getRepairLineVersionHistoryLoadAction } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryActions";
import { getRepairLineVersionHistory } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryAccessor";
import { RepairLineVersionHistory } from "./repairLineVersionHistory";
import { RepairLineVersionHistoryDefectItem } from "./repairLineVersionHistoryDefectItem";
import { LAPopover } from "../../shared/popOver";
import queryString from "query-string";
import { IGetRepairLineDefectItemsByRepairIDRequest } from "../../../redux/field/repairLine/getRepairLineDefectItemsByRepairID/getRepairLineDefectItemsByRepairIDConstants";
import { getRepairLineDefectItemsByRepairID } from "../../../redux/field/repairLine/getRepairLineDefectItemsByRepairID/getRepairLineDefectItemsByRepairIDAccessor";
import { getRepairLineDefectItemsByRepairIDLoadAction } from "../../../redux/field/repairLine/getRepairLineDefectItemsByRepairID/getRepairLineDefectItemsByRepairIDActions";
import { ITechService } from "../../../redux/field/repairLine/getTechServices/getTechServicesConstants";
import { IGetTechServicesByUnitIDRequest } from "../../../redux/field/repairLine/getTechServicesByUnitID/getTechServicesByUnitIDConstants";
import { getTechServicesByUnitID } from "../../../redux/field/repairLine/getTechServicesByUnitID/getTechServicesByUnitIDAccessor";
import { getTechServicesByUnitIDLoadAction } from "../../../redux/field/repairLine/getTechServicesByUnitID/getTechServicesByUnitIDActions";
import UnitDetails from "../equipment/unitDetails";

interface IRepairLinesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getRepairLine: Server<SurewayAPIResponse<ById<IRepairLine>>>;
    getRepairLineSummary: Server<SurewayAPIResponse<IRepairLineSummary>>;
    getRepairLineByMechanicId: Server<SurewayAPIResponse<IRepairLineByMechanicId[]>>;
    getRepairLineHistory: Server<SurewayAPIResponse<ById<IGetRepairLineHistory>>>;
    getRepairLineDefectItemsByRepairID: Server<SurewayAPIResponse<IRepairLineItem[]>>;
    getTechServicesByUnitID: Server<SurewayAPIResponse<ITechService[]>>;
    // getVersionHistory: Server<SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>>;
};

interface IRepairLinesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRepairLinesRequest: (data: IGetRepairLineRequest) => unknown;
    getRepairLineSummaryRequest: (request: IGetRepairLineSummaryRequest) => unknown;
    getRepairLineByMechanicIdRequest: (request: IGetRepairLineByMechanicIdRequest) => unknown;
    getRepairLineHistoryRequest: (request: IGetRepairLineHistoryRequest) => unknown;
    getRepairLineDefectItemsByRepairIDRequest: (request: IGetRepairLineDefectItemsByRepairIDRequest) => unknown;
    getTechServicesByUnitIDRequest: (request: IGetTechServicesByUnitIDRequest) => unknown;
    // getVersionHistoryRequest: (request: IGetRepairLineVersionHistoryRequest) => unknown;
};


interface IRepairLinesOwnProps {

};

interface IRepairLinesState {
    activeView: string;
    mechanicName: string;
    mechanicPopup: boolean;
    // activeLines: IRepairLine[];
    isSummaryExpanded: boolean;
    defectItemsPopup: boolean;
    techServicesPopup: boolean;
    repairLineHistoryPopup: boolean;
    display: string | undefined;
    unitDetailsPopup: boolean;
    unitIDForUnitDetails: number | undefined;
    // repairLineVersionHistoryPopup: boolean;
    // repairLineVersionHistoryComponentPopup: boolean;
    // defectItemsPopUpData: IRepairLine | undefined;
};

const RepairLinesStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IRepairLinesProps = RouteComponentProps
    & IRepairLinesStoreProps
    & IRepairLinesDispatchProps
    & IRepairLinesOwnProps;

class RepairLines extends PureComponent<IRepairLinesProps, IRepairLinesState> {

    public constructor(props: IRepairLinesProps) {
        super(props);
        this.state = {
            // activeLines: [],
            mechanicName: "",
            activeView: "Field",
            mechanicPopup: false,
            isSummaryExpanded: false,
            defectItemsPopup: false,
            techServicesPopup: false,
            repairLineHistoryPopup: false,
            display: "",
            unitDetailsPopup: false,
            unitIDForUnitDetails: undefined,
            // repairLineVersionHistoryPopup: false,
            // repairLineVersionHistoryComponentPopup: false,
            // defectItemsPopUpData: undefined
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.checkViewSelections();
        this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IRepairLinesProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();

    };


    public render(): ReactNode {

        const { activeView, mechanicPopup, mechanicName, isSummaryExpanded, repairLineHistoryPopup, defectItemsPopup, techServicesPopup, unitDetailsPopup, unitIDForUnitDetails } = this.state;
        const { getRepairLine, getToken, getRepairLineSummary, getRepairLineByMechanicId, getRepairLineHistory, getRepairLineDefectItemsByRepairID, getTechServicesByUnitID } = this.props;
        const getRole = pageAccessCheck(getToken, "repairLine");
        const data =  hasPayload(getRepairLine) ? Object.values(getRepairLine.payload.response) : [];
        // console.log(data);
        const summary = hasPayload(getRepairLineSummary) ? getRepairLineSummary.payload.response : undefined;
        const mechanicRepairLines = hasPayload(getRepairLineByMechanicId) ? getRepairLineByMechanicId.payload.response : [];
        const defectItemsPopUpDatas = hasPayload(getRepairLineDefectItemsByRepairID) ? getRepairLineDefectItemsByRepairID.payload.response : [];
        const techServicesPopUpData = hasPayload(getTechServicesByUnitID) ? getTechServicesByUnitID.payload.response : [];
        const repairLineHistorypopupData = hasPayload(getRepairLineHistory) ? getRepairLineHistory.payload.response : {};
        // const repairLineVersionHistorypopupData = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};
        const repairLineRole = hasPayload(getToken) ? getToken.payload.response.repairLineRole : undefined

        return (
            <PageSpacing title="Repair Lines" description="FIELD - REPAIR LINE" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <RepairLinesStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    disabled={undefined}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">REPAIR LINE</h2>
                                <hr />

                                {getRepairLine.kind === STATUS_ENUM.LOADING && <LALoading message="Loading repair lines..." />}
                                {getRepairLine.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load repair lines..." />}

                                {getRepairLine.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12} md={6}>
                                            <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="SUMMARY" expanded={isSummaryExpanded} onClick={this.summaryExpand}>
                                                {(summary) && <RepairLineSummary 
                                                    data={summary}
                                                    onClick={this.handleMechanicClick}
                                                    onUnitClick={this.handleSummaryUnitClick}
                                                />}
                                            </LAExpansionPanel>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView} onChange={this.onViewClick}>
                                                <FormControlLabel value="Field" control={<Radio />} label="Active Items (Field)" />
                                                <FormControlLabel value="Shop" control={<Radio />} label="Active Items (Shop)" />
                                                <FormControlLabel value="Lube" control={<Radio />} label="Active Items (Lube)" />
                                                <FormControlLabel value="Winter_Repair" control={<Radio />} label="Winter Repair" />
                                                <FormControlLabel value="All" control={<Radio />} label="All Items" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnReordering={true}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="repairLineList"
                                                customRowColor={true}
                                                storageKey="repairLineListSessionKey"
                                                onCustomClick={this.handleCustomClick}
                                                exportFileName="RepairLines"
                                                timeout={120}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "display", caption: "Unit/Attachment", type: "string", 
                                                        renderCell: (e:any) => {
                                                            if (e.data) {
                                                                const find = e.data["display"];
                                                                const onUnitClick = (): void => find && this.handleCustomClick("unit_Details", e);
                                                                const onAttachmentClick = (): void => find && this.handleCustomClick("attachment_Details", e);
                                                                return <div>
                                                                    {find && e.data.unit_ID > 0 ? <div className="link-btn" onClick={onUnitClick}>{find}</div> : 
                                                                       find && e.data.attachment_ID > 0 ? <div className="link-btn" onClick={onAttachmentClick}> <AttachmentIcon width={15} height={15} /> {find}</div> : ""}
                                                                </div>
                                                            }
                                                        } 
                                                    },
                                                    { name: "attached_Unit_No", caption: "Attached Unit No.", type: "string" },
                                                    { name: "repair_Location", caption: "Repair Location", type: "string", show: activeView === 'All' ? true : false },
                                                    { name: "work_Order_No", caption: "Work Order #", type: "string", show: (activeView === 'Shop') ? true : false },
                                                    { name: "location_Info", caption: "Current Location", type: "string" },
                                                    { name: "priority", caption: "Priority", type: "string" },
                                                    { name: "make", caption: "Make", type: "string", show: false },
                                                    { name: "model", caption: "Model", type: "string", show: false },
                                                    { name: "job_No", caption: "Job #", type: "string" },
                                                    { name: "created", caption: "Initiated Date", type: "date" },
                                                    { name: "status", caption: "Status", type: "string",  },
                                                    { name: "winter_Repair", caption: "Winter Repair", type: "string", show: (activeView === 'All' || activeView === "Winter_Repair") ? true : false},
                                                    { name: "assigned_Mech", caption: "Assigned Mechanic", type: "string", width: 100, },
                                                    { name: "defect_Items", caption: "Defect Items", type: "button" },
                                                    { name: "repair_History", caption: "Repair History", type: "button"},
                                                    { name: "tech_Services", caption: "Tech Services", type: "button" },
                                                    { name: "date_Completed", caption: "Date Completed", type: "date" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>

                        {/* {repairLineVersionHistoryPopup && <RepairLineVersionHistory 
                          data={repairLineVersionHistorypopupData}
                          display={this.state.display}
                          open={repairLineVersionHistoryPopup}
                          status={getVersionHistory.kind}
                          onCancel={this.handleVersionHistoryPopupClose}
                        />}

                        {repairLineVersionHistoryComponentPopup && <RepairLineVersionHistoryComponent 
                          data={repairLineVersionHistorypopupData}
                          display={this.state.display}
                          open={repairLineVersionHistoryComponentPopup}
                          status={getVersionHistory.kind}
                          onCancel={this.handleVersionHistoryComponentPopupClose}
                        />} */}

                        {/* {defectItemsPopUpData &&
                            <DefectItems
                                data={defectItemsPopUpData}
                                onClose={this.handleDefectItemsPopupClose}
                                open={defectItemsPopUpData ? true : false}
                                onOpenDefectItem={this.onOpenDefectItem}
                            />} */}

                        {unitDetailsPopup && <LAPopover
                            open={unitDetailsPopup}
                            onClose={this.handleUnitDetails}
                            transformOrigin={{ horizontal: "left", vertical: "center" }}
                            anchorOrigin={{ horizontal: "left", vertical: "top" }}
                            anchorRef={null}>
                            <UnitDetails
                                {...this.props}
                                id={unitIDForUnitDetails}
                            />
                        </LAPopover>}

                        {defectItemsPopup && <DefectItems
                          data={defectItemsPopUpDatas}
                          open={defectItemsPopup}
                          display={this.state.display}
                          status={getRepairLineDefectItemsByRepairID.kind}
                          onClose={this.handleDefectItemsPopupClose}
                          onOpenDefectItem={this.onOpenDefectItem}
                        />}

                        {repairLineHistoryPopup && <RepairLineHistory 
                          data={repairLineHistorypopupData}
                          display={this.state.display}
                          open={repairLineHistoryPopup}
                          status={getRepairLineHistory.kind}
                          onCancel={this.handlePopupClose}
                        />}

                        {techServicesPopup && <TechServices
                          data={techServicesPopUpData}
                          open={techServicesPopup}
                          display={this.state.display}
                          status={getTechServicesByUnitID.kind}
                          onClose={this.handleTechServicesPopupClose}
                        //   onOpenDefectItem={this.onOpenDefectItem}
                        />}

                        {(mechanicPopup) && <RepairLineMechanicPopup
                            open={mechanicPopup}
                            onEdit={this.onEdit}
                            data={mechanicRepairLines}
                            mechanicName={mechanicName}
                            onClose={this.handleMechanicPopup}
                            status={getRepairLineByMechanicId.kind}
                        />}

                    </LAPaperWithPadding>
                </RepairLinesStyles>}
            </PageSpacing>
        );
    }

    private handleUnitDetails = (): void => {
        this.setState({
            unitDetailsPopup: !this.state.unitDetailsPopup,
            unitIDForUnitDetails: undefined
        });
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IRepairLine = e.row.data;
        
        this.setState({ display: data?.display})
        if(name === "repair_History") {
            this.handleRepairLineHistory(data);
        }
        else if(name === "defect_Items") {
            this.handleDefectItems(data);
            // this.setState({ defectItemsPopUpData : data, })
        }
        else if(name === "tech_Services") {
            this.handleTechServices(data);
        }
        else if(name === "unit_Details") {
            if (data.unit_ID > 0) {
                this.setState({ unitDetailsPopup: true, unitIDForUnitDetails: data.unit_ID });
            }
        }
        else if(name === "attachment_Details") {
            if (data.attachment_ID > 0) {
                window.open(ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT + "?id=" + data.attachment_ID.toString());
            }
        }
        // else if(name === "version_History") {
        //     this.handleRepairLineVersionHistory(data);
        // }
        // else if(name === "version_History_Component") {
        //     this.handleRepairLineVersionHistoryComponent(data);
        // }

    };

    private handleTechServicesPopupClose = (): void => {
        this.setState({ techServicesPopup: false });
    };

    private handleDefectItemsPopupClose = (): void => {
        // this.setState({ defectItemsPopUpData: undefined });
        this.setState({ defectItemsPopup: false });
    };

    private onOpenDefectItem = (name:string, data: any): void => {
        const query = queryString.parse(this.props.location.search);
        window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString() + "&defect_ID=" + data.id.toString()+ "&view=" + (query.view ? query.view.toString() : "All"))
    };
    // private handleRepairLineVersionHistoryComponent = async (data:IRepairLine): Promise<void> => {
    //     // console.log(data)
    //     if (hasPayload(this.props.getToken)) {
    //         this.props.getVersionHistoryRequest({
    //             token: this.props.getToken.payload.response.token,
    //             request: {
    //                 ID: data.id,
    //                 Type: "Repair_Line_Component"
    //             }
    //         });
    //     }
    //     await this.setState({ repairLineVersionHistoryComponentPopup: true });
    // };

    // private handleVersionHistoryComponentPopupClose = (): void => {
    //     this.setState({ repairLineVersionHistoryComponentPopup: false });
    // };

    // private handleRepairLineVersionHistory = async (data:IRepairLine): Promise<void> => {
    //     // console.log(data)
    //     if (hasPayload(this.props.getToken)) {
    //         this.props.getVersionHistoryRequest({
    //             token: this.props.getToken.payload.response.token,
    //             request: {
    //                 ID: data.id,
    //                 Type: "Repair_Line"
    //             }
    //         });
    //     }
    //     await this.setState({ repairLineVersionHistoryPopup: true });
    // };

    // private handleVersionHistoryPopupClose = (): void => {
    //     this.setState({ repairLineVersionHistoryPopup: false });
    // };

    private handleRepairLineHistory = async (data:IRepairLine): Promise<void> => {
        if(data.unit_ID > 0) {
            if (hasPayload(this.props.getToken)) {
                this.props.getRepairLineHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: data.unit_ID,
                        Type: "Unit"
                    }
                });
            }
        } else if(data.attachment_ID > 0) {
            if (hasPayload(this.props.getToken)) {
                this.props.getRepairLineHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: data.attachment_ID,
                        Type: "Attachment"
                    }
                });
            }
        }
        await this.setState({ repairLineHistoryPopup: true });
    };

    private handleDefectItems = async (data:IRepairLine): Promise<void> => {
        if(data.id > 0) {
            if (hasPayload(this.props.getToken)) {
                this.props.getRepairLineDefectItemsByRepairIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: data.id,
                    }
                });
            }
        } 
        await this.setState({ defectItemsPopup: true });
    };

    private handleTechServices = async (data:IRepairLine): Promise<void> => {
        if(data.unit_ID > 0) {
            if (hasPayload(this.props.getToken)) {
                this.props.getTechServicesByUnitIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        unit_id: data.unit_ID,
                    }
                });
            }
            await this.setState({ techServicesPopup: true });
        } 
    };

    private handlePopupClose = (): void => {
        this.setState({ repairLineHistoryPopup: false });
    };

    private summaryExpand = (): void => {
        this.setState({
            ...this.state,
            isSummaryExpanded : !this.state.isSummaryExpanded
        });
    };

    private handleSummaryUnitClick = (data:IRepairLineSummaryUnit): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: data.id.toString() },
            pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
        });
    };

    private handleMechanicClick = (mechanic_id: number, mechanicName: string): void => {
        if(hasPayload(this.props.getToken)){
            this.props.getRepairLineByMechanicIdRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    mechanic_id
                }
            });

            this.setState({
                mechanicName,
                mechanicPopup: true,
             });
        };
    };

    private handleMechanicPopup = (): void => {
        this.setState({ mechanicPopup: !this.state.mechanicPopup });
    };

    private onViewClick = async(e:any, value:string): Promise<void> => {
        // console.log(value);
        localStorage.removeItem("repairLineListSessionKey");
        this.setState({ activeView: value });
        await callRouteWithQueryString({
            route: this.props,
            search: { view: value },
            pathName: ROUTE.FIELD.REPAIR_LINE.INDEX
        });
        this.getDataForTable();
    };

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true), view: query.view ? query.view.toString() : "Field" },
            pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
        });
    };

    private checkViewSelections = (): void => {
        const query = queryString.parse(this.props.location.search);
        let activeView = this.state.activeView;
        
        this.setState({ activeView: query.view ? query.view.toString() : activeView });
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getRepairLine)) {
                    this.getDataForTable();
                };
            } else {

                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

            if (isNotLoaded(this.props.getRepairLineSummary))
                this.props.getRepairLineSummaryRequest({
                    token: this.props.getToken.payload.response.token
                });

        };
    };

    private getDataForTable = (): void => {
        const query = queryString.parse(this.props.location.search);
        if (hasPayload(this.props.getToken)) {
            this.props.getRepairLinesRequest({
                token: this.props.getToken.payload.response.token,
                request : {
                    view: query.view ? query.view.toString() : this.state.activeView
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IRepairLinesStoreProps => ({
    getToken: getToken(state),
    getRepairLine: getRepairLines(state),
    getRepairLineSummary: getRepairLineSummary(state),
    getRepairLineByMechanicId: getRepairLineByMechanicId(state),
    getRepairLineHistory: getRepairLineHistory(state),
    getRepairLineDefectItemsByRepairID: getRepairLineDefectItemsByRepairID(state),
    getTechServicesByUnitID: getTechServicesByUnitID(state),
    // getVersionHistory: getRepairLineVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IRepairLinesDispatchProps => ({
    getRepairLinesRequest: (data: IGetRepairLineRequest) => dispatch(getRepairLinesLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRepairLineSummaryRequest: (request: IGetRepairLineSummaryRequest): unknown => dispatch(getRepairLineSummaryLoadAction(request)),
    getRepairLineByMechanicIdRequest: (request: IGetRepairLineByMechanicIdRequest): unknown => dispatch(getRepairLineByMechanicIdLoadAction(request)),
    getRepairLineHistoryRequest: (request: IGetRepairLineHistoryRequest): unknown => dispatch(getRepairLineHistoryLoadAction(request)),
    getRepairLineDefectItemsByRepairIDRequest: (request: IGetRepairLineDefectItemsByRepairIDRequest): unknown => dispatch(getRepairLineDefectItemsByRepairIDLoadAction(request)),
    getTechServicesByUnitIDRequest: (request: IGetTechServicesByUnitIDRequest): unknown => dispatch(getTechServicesByUnitIDLoadAction(request)),
    // getVersionHistoryRequest: (request: IGetRepairLineVersionHistoryRequest): unknown => dispatch(getRepairLineVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairLines);

interface IDefectItemsPopup {
    open: boolean;
    data: IRepairLineItem[];
    status: STATUS_ENUM;
    onClose: () => void;
    onOpenDefectItem: (name: string, e: any) => void;
    display: string | undefined;
};

export const DefectItems: React.FC<IDefectItemsPopup> = React.memo((props: IDefectItemsPopup) => {

    const onCustomClick = (name:string, e: any): void => {
        if(name === "mechanic_Logs") {
            window.open(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_BY_DEFECT_ID + "?id=" + e.row.data.id.toString())
        }
    };
    // console.log(props.data)
    return (
        <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
            <LAPaperWithPadding>

                <div className="text-center">

                    <strong>
                        Defect Items: 
                        <span>
                            {props.display}
                        </span>
                    </strong>

                    <hr />
                </div>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Defect Items..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                <LADevExtremeGrid
                    height={800}
                    id="repairLineList"
                    searchPanel={true}
                    filterHeader={true}
                    onClick={undefinedFunction}
                    data={props.data ?? []}
                    onCustomIconClick={props.onOpenDefectItem}
                    onCustomClick={onCustomClick}
                    columnChoose={true}
                    columnsHiding={true}
                    export={true}
                    removeStyleBtn={true}
                    customRowColor={true}
                    exportFileName="RepairLine-DefectItems"
                    storageKey="repairLineDefectItemsSessionKey"
                    columns={[
                        { name: "defect_Item", caption: "Defect Item", type: "link" },
                        { name: "issue", caption: "Issue", type: "string" },
                        { name: "priority", caption: "Priority", type: "string" },
                        { name: "status", caption: "Status", type: "string" },
                        { name: "damaged", caption: "Damaged", type: "string" },
                        { name: "work_Order_No", caption: "WO#/Job-Cost# ", type: "string", renderCell : (e:any) => { return <div>{(e.row.data.job_No || e.row.data.cost_Code) ?  (e.row.data.job_No && e.row.data.job_No) + "-" + (e.row.data.cost_Code && e.row.data.cost_Code) : e.row.data.work_Order_No && e.row.data.work_Order_No }</div>}},
                        { name: "work_Order_No", caption: "Work Order", type: "string", show: false },
                        { name: "job_No", caption: "Job No.", type: "string", show: false },
                        { name: "cost_Code", caption: "Cost Code", type: "string", show: false },
                        { name: "repair_Type", caption: "Repair Type", type: "string" },
                        { name: "winter_Repair", caption: "Winter Repair", type: "string" },
                        { name: "manager_Instructions", caption: "Manager Instructions", type: "string" },
                        { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                        { name: "mechanic_Comments", caption: "Mechanic Comments", type: "string" },
                        { name: "mechanic_Names", caption: "Assigned Mechanics", type: "string" },
                        { name: "lube_Driver", caption: "Lube Driver", type: "string" },
                        { name: "meter_Reading", caption: "Meter Reading", type: "string", show: false },
                        { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string" },
                        { name: "component_Code", caption: "Component Code", type: "string", show: false },
                        { name: "hours", caption: "Hours", type: "string", show: false },
                        { name: "serial_No", caption: "Serial No", type: "string", show: false },
                        { name: "type", caption: "Type", type: "string", show: false },
                        { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string", show: false },
                        { name: "damaged_Notes", caption: "Damaged Notes", type: "string", show: false },
                        { name: "parts_Notes", caption: "Parts Notes", type: "string", show: false },
                        { name: "mechanic_Logs", caption: "Mechanic Logs", type: "button"},
                        { name: "created_By", caption: "Requested By", type: "string" },
                        { name: "modified_By", caption: "Modified By", type: "string", show: false },
                        { name: "created", caption: "Created", type: "datetime" },
                        { name: "modified", caption: "Modified", type: "datetime", show: false },
                        { name: "created_Location_Info", caption: "Created Location Info", type: "string", show: false },
                        { name: "repair_Location_Info", caption: "Repair Location Info", type: "string", show: false },
                    ]}
                />
            </LAPaperWithPadding>
        </LAPopover>
)});

interface ITechServicesPopup {
    open: boolean;
    data: ITechService[];
    status: STATUS_ENUM;
    onClose: () => void;
    // onOpenTechService: (name: string, e: any) => void;
    display: string | undefined;
};

export const TechServices: React.FC<ITechServicesPopup> = React.memo((props: ITechServicesPopup) => {

    // const onCustomClick = (name:string, e: any): void => {
    //     if(name === "mechanic_Logs") {
    //         window.open(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_BY_DEFECT_ID + "?id=" + e.row.data.id.toString())
    //     }
    // };
    // console.log(props.data)
    return (
        <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
            <LAPaperWithPadding>

                <div className="text-center">

                    <strong>
                        Tech Services: 
                        <span>
                            {props.display}
                        </span>
                    </strong>

                    <hr />
                </div>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Defect Items..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                <LADevExtremeGrid
                    height={800}
                    id="techServicesList"
                    searchPanel={true}
                    filterHeader={true}
                    onClick={undefinedFunction}
                    data={props.data ?? []}
                    // onCustomIconClick={props.onOpenDefectItem}
                    // onCustomClick={onCustomClick}
                    columnChoose={true}
                    columnsHiding={true}
                    export={true}
                    removeStyleBtn={true}
                    customRowColor={true}
                    exportFileName="RepairLine-TechServices"
                    storageKey="repairLineTechServicesSessionKey"
                    columns={[
                        { name: "date", caption: "Date", type: "date" },
                        // { name: "unit_Number", caption: "Unit #", type: "string" },
                        { name: "make", caption: "Make", type: "number" },                                                   
                        { name: "model", caption: "Model", type: "number" },
                        { name: "technician", caption: "Technician", type: "string" },
                        { name: "component", caption: "Component", type: "string" },
                        { name: "operational_Complaint", caption: "Operational Complaint", type: "string" },
                        { name: "tShooting_Procedure", caption: "T-Shooting Procedure", type: "string" },
                        { name: "repair_Improvement_Procedure", caption: "Repair Improvement Procedure", type: "string" },
                        { name: "created_By", caption: "Created By", type: "string" },
                        { name: "created", caption: "Created", type: "datetime" },
                        { name: "modified_By", caption: "Modified By", type: "string" },
                        { name: "modified", caption: "Modified", type: "datetime" },
                    ]}
                />
            </LAPaperWithPadding>
        </LAPopover>
)});