import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST, IMechanicLogsByDefectID } from "./getMechanicLogsByDefectIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetMechanicLogsByDefectIDLoadAction, IGetMechanicLogsByDefectIDLoadFailedAction, IGetMechanicLogsByDefectIDSuccessAction, getMechanicLogsByDefectIDLoadFailedAction, getMechanicLogsByDefectIDLoadSuccessAction } from "./getMechanicLogsByDefectIDActions";

export const getMechanicLogsByDefectIDEpic: Epic = (
    action$: ActionsObservable<IGetMechanicLogsByDefectIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMechanicLogsByDefectIDSuccessAction | IGetMechanicLogsByDefectIDLoadFailedAction> =>
    action$.ofType(IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IMechanicLogsByDefectID[]>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_MECHANIC_LOGS_BY_DEFECT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IMechanicLogsByDefectID[]>): IGetMechanicLogsByDefectIDSuccessAction => {
                            return getMechanicLogsByDefectIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getMechanicLogsByDefectIDLoadFailedAction(response.message)))
                    )
            )
        );