import { webConfig } from "../utils/webConfig";

export const END_POINTS = {
  GET_USER_TOKEN: `${webConfig.apiBaseUrl}/api/Sureway/GetUserToken`,
  GET_AUTHORIZATION: `${webConfig.apiBaseUrl}/api/Sureway/GetAuthorization`,
  GET_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/GetLookups`,
  ACCOUNTING: {
    JOBS: {
      GET_JOBS: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/GetJobs`,
      ADD_JOB: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/AddJob`,
      UPDATE_JOB: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/UpdateJob`,
      GET_JOBS_PITS: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/GetJobsPits`,
      ADD_JOB_PIT: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/AddJobPit`,
      UPDATE_JOB_PIT: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/UpdateJobPit`,
      GET_JOBS_MHR: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/GetJobsMHR`,
      ADD_JOB_MHR: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/AddJobMHR`,
      UPDATE_JOB_MHR: `${webConfig.apiBaseUrl}/api/Sureway/Accounting/UpdateJobMHR`,
    }
  },
  SIL: {
    KANTECH: {
      GET_KANTECH_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/Sureway/Kantech/GetKantechAdjustments`,
      ADD_KANTECH_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/Sureway/Kantech/AddKantechAdjustment`,
      UPDATE_KANTECH_ADJUSTMENT: `${webConfig.apiBaseUrl}/api/Sureway/Kantech/UpdateKantechAdjustment`,
    },
  },
  FIELD: {
    GET_FIELD_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetFieldLookups`,
    GET_UNIT_HISTORY_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitHistoryByID`,
    GET_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/GetManufacturers`,
    ADD_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/AddManufacturer`,
    UPDATE_MANUFACTURE: `${webConfig.apiBaseUrl}/api/Shop/UpdateManufacturer`,
    GET_SHOP_LOOKUP: `${webConfig.apiBaseUrl}/api/Shop/GetShopLookup`,
    GET_MODEL: `${webConfig.apiBaseUrl}/api/Shop/GetModels`,
    ADD_MODEL: `${webConfig.apiBaseUrl}/api/Shop/AddModel`,
    UPDATE_MODEL: `${webConfig.apiBaseUrl}/api/Shop/UpdateModel`,
    GET_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/GetEquipments`,
    GET_EQUIPMENT_NEW: `${webConfig.apiBaseUrl}/api/Shop/GetEquipment`,
    GET_EQUIPMENT_BY_ID: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentByID`,
    ADD_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/AddEquipment`,
    UPDATE_EQUIPMENT: `${webConfig.apiBaseUrl}/api/Shop/UpdateEquipment`,
    GET_UNIT_UTILIZATION_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitUtilizationByID`,
    GET_EQUIPMENT_TYPES: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentTypes`,
    ADD_EQUIPMENT_TYPE: `${webConfig.apiBaseUrl}/api/Shop/AddEquipmentType`,
    UPDATE_EQUIPMENT_TYPE: `${webConfig.apiBaseUrl}/api/Shop/UpdateEquipmentType`,
    GET_SHOP_EQUIPMENT_COLUMNS: `${webConfig.apiBaseUrl}/api/Shop/GetShopEquipmentColumns`,
    ASSIGN_EQUIPMENT_TYPE_COLUMNS: `${webConfig.apiBaseUrl}/api/Shop/AssignEquipmentTypeColumns`,
    GET_EQUIPMENT_FILE: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentFile`,
    GET_EQUIPMENT_MASTER_FILE: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentsMasterFile`,
    GET_EQUIPMENT_HISTORY_COLUMN: `${webConfig.apiBaseUrl}/api/Shop/GetEquipmentListHistoryByColumn`,
    GET_EQUIPMENT_LIST_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/GetEquipmentListVersionHistory`,
    CERTIFICATE_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/GetEquipmentListCertificateHistory`,
    GPS_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetEquipmentGPSHistory`,
    GPS_RECORD: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetGPSRecords`,
    GET_FILTER_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetFilterList`,
    ADD_FILTER: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddFilter`,
    UPDATE_FILTER: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateFilter`,
    GET_LOCATION_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetLocations`,
    ADD_LOCATION: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddLocation`,
    UPDATE_LOCATION: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateLocation`,
    GET_FILTER_REPORT: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetFilterListReport`,
    ADD_FILTER_PART: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddFilterPart`,
    DELETE_FILTER_PART: `${webConfig.apiBaseUrl}/api/Sureway/Field/RemoveFilterPart`,
    UPDATE_FILTER_PART: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateFilterPart`,
    LOCATION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetLocationVersionHistory`,
    GET_UNIT_DETAILS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitDetails`,
    ADD_EQUIPMENT_CERTIFICATE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddEquipmentCertificate`,
    GET_FORM_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormLookups`,
    GET_FORMS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormTemplates`,
    ADD_FORMS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddFormTemplate`,
    UPDATE_FORMS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateFormTemplate`,
    DOWNLOAD_FORM_ATTACHMENT: `${webConfig.apiBaseUrl}/api/Sureway/Forms/DownloadFormAttachment`,
    GET_FORM_BY_TEMPLATE_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormByTemplateID`,
    GET_FORM_RESPONSES: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormResponsesWeb`,
    GET_FORM_RESPONSE_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormResponsesByID`,
    ADD_FORM_RESPONSE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddFormResponse`,
    UPDATE_FORM_RESPONSE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateFormResponse`,
    GET_WSS_LOG_INFO_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetWSSLogInfoByID`,
    GET_CREWS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetCrews`,
    ADD_CREW: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddCrew`,
    UPDATE_CREW: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateCrew`,
    GET_MEDICAL_FACILITIES: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMedicalFacilities`,
    ADD_MEDICAL_FACILITY: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddMedicalFacility`,
    UPDATE_MEDICAL_FACILITY: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateMedicalFacility`,
    FORMS: {
      GET_FORM_SIGNATURE_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormSignatureHistory`,
      GET_FORM_RESPONSE_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormResponseVersionHistoryByID`,
      GET_FORM_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormVersionHistory`,
      SAVE_ACKNOWLEDGE_RESPONSE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/SaveAcknowledgeResponse`,
      GET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetHazardControlPlanListbyDepartmentID`,
      ADMIN: {
        GET_TASKS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetTasks`,
        ADD_TASK: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddTask`,
        UPDATE_TASK: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateTask`,
        GET_DEPARTMENTS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetDepartments`,
        ADD_DEPARTMENT: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddDepartment`,
        UPDATE_DEPARTMENT: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateDepartment`,
        GET_HAZARD_TYPES: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetHazardTypes`,
        ADD_HAZARD_TYPE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddHazardType`,
        UPDATE_HAZARD_TYPE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateHazardType`,
        GET_CONTROL_PLANS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetControlPlans`, // not in use
        GET_CONTROL_PLANS_NEW: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetControlPlansNew`,
        ADD_CONTROL_PLAN: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddControlPlan`, // not in use
        SAVE_CONTROL_PLAN: `${webConfig.apiBaseUrl}/api/Sureway/Forms/SaveControlPlan`,
        UPDATE_CONTROL_PLAN: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateControlPlan`, // not in use
        GET_HAZARDS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetHazards`,
        ADD_HAZARD: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddHazard`,
        UPDATE_HAZARD: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateHazard`,
        GET_HAZARD_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetHazardByID`,
        ADD_HAZARD_NEW: `${webConfig.apiBaseUrl}/api/Sureway/Forms/AddHazardNew`,
        UPDATE_HAZARD_NEW: `${webConfig.apiBaseUrl}/api/Sureway/Forms/UpdateHazardNew`,
      },
      CLEANUP: {
        GET_CLEANUP_DATA: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetCleanupData`,
        REMOVE_DEPARTMENT_FROM_ITEMS: `${webConfig.apiBaseUrl}/api/Sureway/Forms/RemoveDepartmentFromItems`,
      },
      DOWNLOAD_FORM_RESPONSE_PDF: `${webConfig.apiBaseUrl}/api/Sureway/Forms/DownloadPDFByID`,
      // DOWNLOAD_PSI_FILE: `${webConfig.apiBaseUrl}/api/Sureway/Forms/DownloadPSIFile`,
    },
    EQUIPMENT_MANUALS: {
      GET_EQUIPMENT_MANUALS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetEquipmentManuals`,
      ADD_EQUIPMENT_MANUAL: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddEquipmentManual`,
      UPDATE_EQUIPMENT_MANUAL: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateEquipmentManual`,
    },
    EQUIPMENT_TRACKER: {
      ADMIN: {
        GET_DRIVER: `${webConfig.apiBaseUrl}/api/EquipmentTracker/GetDrivers`,
        ADD_DRIVER: `${webConfig.apiBaseUrl}/api/EquipmentTracker/AddDriver`,
        UPDATE_DRIVER: `${webConfig.apiBaseUrl}/api/EquipmentTracker/UpdateDriver`,
      },
      GET_ET_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/GetETLookups`,
      INITIATE_MOVE: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/InitiateMove`,
      BULK_MOVE: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/SaveBulkMoveRequests`,
      GET_MAPPING_VIEW: `${webConfig.apiBaseUrl}/api/EquipmentTracker/GetMappingView`,
      GET_EQUIPMENT_MOVE_REQUEST: `${webConfig.apiBaseUrl}/api/EquipmentTracker/GetEquipmentMoveRequests`
    },
    REPAIR_LINE: {
      GET_REPAIR_LINE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLine`,
      ADD_REPAIR_LINE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddRepairLine`,
      UPDATE_REPAIR_LINE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateRepairLine`,
      SAVE_REPAIR_LINE_ITEM: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveRepairLineItem`,
      ITEM_IMAGE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineItemImage`,
      GET_ALL_MECHANIC_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAllMechanicLogs`,
      GET_MECHANIC_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicLogs`,
      GET_MECHANIC_TIMESHEET_FOR_APPROVAL: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicTimesheetForApproval`,
      GET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicTimesheetForApprovalGroupByDate`,
      GET_MECHANIC_LOGS_BY_DEFECT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicLogsByDefectID`,
      SAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveManagerCommentsForDefectItem`,
      GET_COMPONENT_WAITING_PARTS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetComponentsWaitingForParts`,
      SAVE_COMPONENT_WAITING_PARTS: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveComponentPartsNotes`,
      SAVE_MECHANICS_ASSIGNMENT: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveMechanicsAssignment`,
      APPROVE_MECHANIC_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/ApproveMechanicLog`,
      REJECT_MECHANIC_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/RejectMechanicLog`,
      GET_REPAIR_LINE_SUMMARY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineSummary`,
      GET_REPAIR_LINE_BY_MECHANIC_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLinesByMechanicID`,
      GET_REPAIR_LINE_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineHistoryByID`,
      REPAIR_LINE_WORK_ORDER: `${webConfig.apiBaseUrl}/api/Sureway/Field/AssignRepairLineWorkOrder`,
      GET_REPAIR_LINE_WO: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineWORecords`,
      GET_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineVersionHistory`,
      GET_REPAIR_LINE_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineByID`,
      GET_REPAIR_LINE_DEFECT_ITEM_BY_REPAIR_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineDefectItemsByRepairID`,
      GET_REPAIR_LINE_ITEM_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineItemByID`,
      GET_REPAIR_LINE_SMS_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineSMSLogs`,
      GET_REPAIR_LINE_WO_UNITS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetRepairLineWOUnits`,
      ADD_REPAIR_LINE_WO_UNIT: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddRepairLineWOUnit`,
      UPDATE_REPAIR_LINE_WO_UNIT: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateRepairLineWOUnit`,
      GET_PAYROLL_MECHANIC_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPayrollMechanicLogs`,
      GET_PAYROLL_MECHANIC_LOGS_REPORT: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPayrollMechanicLogsReport`,
      GET_PAYROLL_MECHANIC_LOGS_SUMMARY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPayrollMechanicLogsSummary`,
      PAYROLL_MECHANIC_LOGS_UPLOAD: `${webConfig.apiBaseUrl}/api/Sureway/Field/PayrollMechanicLogsUpload`,
      ACKNOWLEDGE_LOG_BY_PAYROLL: `${webConfig.apiBaseUrl}/api/Sureway/Field/AcknowledgeLogByPayroll`,
      GET_TECH_SERVICES: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetTechServices`,
      GET_TECH_SERVICES_BY_UNIT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetTechServicesByUnitID`,
      ADD_TECH_SERVICE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddTechService`,
      UPDATE_TECH_SERVICE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateTechService`,
      GET_MECHANIC_TIMECARD_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicTimeCardVersionEntry`,
    },
    INSPECTION_FORM: {
      GET_UNIT_INSPECTION_FORM_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitInspectionFormByID`,
      GET_UNIT_INSPECTION_SHEETS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitInspectionSheets`,
      GET_UNIT_INSPECTION_SHEET_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetUnitInspectionSheetByID`,
      SAVE_INSPECTION_SHEET: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveInspectionSheet`,
    },
    FILTER_CUTS: {
      GET_FILTER_CUTS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetFilterCuts`,
      ADD_FILTER_CUT: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddFilterCut`,
      UPDATE_FILTER_CUT: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateFilterCut`
    },
    ATTACHMENT_LIST : {
      GET_TYPES: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentTypes`,
      ADD_TYPE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachmentType`,
      UPDATE_TYPE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachmentType`,
      GET_COUPLERS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentCouplers`,
      ADD_COUPLER: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachmentCoupler`,
      UPDATE_COUPLER: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachmentCoupler`,
      GET_ATTACHMENT_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentList`,
      GET_ATTACHMENTS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachments`,
      GET_ATTACHMENT_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentByID`,
      ADD_ATTACHMENT_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachment`,
      UPDATE_ATTACHMENT_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachment`,
      DOWNLOAD_FILE: `${webConfig.apiBaseUrl}/api/Sureway/Field/DownloadFile`,
      GET_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentListVersionHistory`,
      GET_ATTACHMENT_MOVE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentMoveRequests`,
      ADD_ATTACHMENT_MOVE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachmentMoveRequest`,
      UPDATE_ATTACHMENT_MOVE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachmentMoveRequest`,
      GET_ATTACHMENT_STYLE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentStyles`,
      ADD_ATTACHMENT_STYLE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachmentStyle`,
      UPDATE_ATTACHMENT_STYLE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachmentStyle`,
      GET_MOVE_REQUEST_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentMoveRequestsHistory`,
      GET_ATTACHMENT_LIST_GET: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAttachmentListGET`,
      ADD_ATTACHMENT_LIST_GET: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddAttachmentGET`,
      UPDATE_ATTACHMENT_LIST_GET: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateAttachmentGET`,
      DELETE_ATTACHMENT_LIST_GET: `${webConfig.apiBaseUrl}/api/Sureway/Field/RemoveAttachmentGET`,
      CLONE_ATTACHMENT_GET: `${webConfig.apiBaseUrl}/api/Sureway/Field/CloneAttachmentGET`,
    },
    PM_SHEETS: {
      GET_SERVICE_DUES: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetServiceDues`,
      ADD_SERVICE_DUE: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/AddServiceDue`,
      UPDATE_SERVICE_DUE: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/UpdateServiceDue`,
      GET_SERVICE_SECTIONS: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetServiceSections`,
      ADD_SERVICE_SECTION: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/AddServiceSection`,
      UPDATE_SERVICE_SECTION: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/UpdateServiceSection`,
      GET_PM_SHEET_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetPMSheetLookups`,
      GET_SERVICE_SHEET_REPORT: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetServiceSheetReport`,
      GET_PM_SHEET_REPORT: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetPMSheetsReport`,
      GET_PM_SHEET_REPORT_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/GetPMSheetReportByID`,
      APPROVE_SERVICE_SHEET: `${webConfig.apiBaseUrl}/api/Sureway/Field/PMSheet/ApproveServiceSheet`,
      GET_REPORT_IMAGE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPMSheetReportImage`,
      GET_REPORT_ITEM_IMAGE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPMSheetReportItemImage`,
      GET_COMPANIES: `${webConfig.apiBaseUrl}/api/GetTimesheetCompanies`,
      GET_PM_SHEET_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetPMSheetVersionHistory`,
    },
    GET_MOVE_REQUESTS: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/GetMoveRequests`,
    Get_ET_MoveAttachment_VersionHistory: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetETMoveAttachmentVersionHistory`,
    GET_DRIVER_LIST: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/GetDriverList`,
    GET_DRIVER_COMM_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/GetDriverSMSLogs`,
    SAVE_DRIVERS_ASSIGNMENT: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/SaveDriversAssignment`,
    CHECK_LAST_UPDATED_MOVE_REQUEST: `${webConfig.apiBaseUrl}/api/Sureway/Field/ET/CheckLastUpdatedMoveRequests`,
    WORKING_SITE_LOGS: {
      GET_WORKING_SITE_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWorkingSiteLogs`,
      GET_WSS_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWSSLogs`,
      GET_WORKING_SITE_LOG_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWorkingSiteLogByID`,
      GET_WSS_LOGS_HISTORY_BY_SITE_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWSSLogsHistoryBySiteID`,
      ADD_WORKING_SITE_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddWorkingSiteLog`,
      UPDATE_WORKING_SITE_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateWorkingSiteLog`,
      VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWorkingSiteLogsVersionHistory`,
      GET_WORKING_SITE_LOGS_ON_CALL: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetWorkingSiteLogsOnCall`,
      UPDATE_WORKING_SITE_LOG_ON_CALL: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateWorkingSiteLogOnCall`,
      GET_FIELD_EMPLOYEES: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetFieldEmployees`,
      GET_CREW_BY_USERNAME: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetCrewByUsername`,
      ADD_FIELD_EMPLOYEE: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddFieldEmployee`,
      UPDATE_FIELD_EMPLOYEE: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateFieldEmployee`,
      GET_FOREMAN_MESSAGING: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetForemanMessaging`,
      ADD_FOREMAN_MESSAGING: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddForemanMessaging`,
      UPDATE_FOREMAN_MESSAGING: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateForemanMessaging`,
      GET_JOURNELS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetJournels`,
      ADD_JOURNEL: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddJournel`,
      UPDATE_JOURNEL: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateJournel`,
      GET_JOURNAL_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetJournalVersionHistory`,
      GET_CREW_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetCrewVersionHistory`,
    },
    OPERATOR_LOGS: {
      VALIDATE_OPERATOR_LOGIN: `${webConfig.apiBaseUrl}/api/Sureway/Field/ValidateOperatorLogin`,
      GET_OPERATOR_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetOperatorLogs`,
      ADD_OPERATOR_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddOperatorLog`,
      UPDATE_OPERATOR_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateOperatorLog`,
    },
    MECHANICS: {
      VALIDATE_MECHANIC_LOGIN: `${webConfig.apiBaseUrl}/api/Sureway/Field/ValidateMechanicLogin`,
      GET_MECHANICS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAllMechanics`,
      ADD_MECHANIC: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddMechanic`,
      UPDATE_MECHANIC: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateMechanic`,
      GET_MECHANIC_LOGS_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetMechanicLogsByID`,
      ADD_MECHANIC_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddMechanicLog`,
      UPDATE_MECHANIC_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateMechanicLog`,
      UPDATE_REPAIR_LINE_BY_MECHANIC: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateRepairLineByMechanic`,
    },
    DISPATCH: {
      GET_SWAMPERS: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetAllSwampers`,
      ADD_SWAMPER: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/AddSwamper`,
      UPDATE_SWAMPER: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/UpdateSwamper`,
      GET_SERVICE_TRUCKS: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetAllServiceTrucks`,
      ADD_SERVICE_TRUCK: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/AddServiceTruck`,
      UPDATE_SERVICE_TRUCK: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/UpdateServiceTruck`,
      SAVE_DRIVERS_ASSIGNMENT: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/SaveDriversAssignment`,
      GET_DRIVERS_ASSIGNMENT: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetDriversAssignment`,
      GET_SERVICE_TRUCK_COMM_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetDriverSMSLogs`,
      GET_DRIVERS_ASSIGNMENT_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetDriversAssignmentHistory`,
      GET_ST_SCHEDULES: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetServiceTruckSchedules`,
      UPDATE_ST_SCHEDULE: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/UpdateServiceTruckStatus`,
      GET_DRIVER_ASSIGNMENT_VERSION_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/Dispatch/GetDriversAssignmentVersionHistory`,
      GET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetSTTimesheetForApprovalGroupByDate`,
      GET_ST_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetSTLogs`,
      APPROVE_ST_TIMESHEET: `${webConfig.apiBaseUrl}/api/Sureway/Field/ApproveSTTimesheet`,
      REJECT_ST_TIMESHEET: `${webConfig.apiBaseUrl}/api/Sureway/Field/RejectSTTimesheet`,
    },
    EXTERNAL_USER: {
      GET_EXTERNAL_USERS: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetAllExternalUsers`,
      ADD_EXTERNAL_USER: `${webConfig.apiBaseUrl}/api/Sureway/Field/AddExternalUser`,
      UPDATE_EXTERNAL_USER: `${webConfig.apiBaseUrl}/api/Sureway/Field/UpdateExternalUser`
    },
    SCOT: {
      GetSoctList: `${webConfig.apiBaseUrl}/api/SOCT/GetSOCTMasterList`,
      GetReport: `${webConfig.apiBaseUrl}/api/SOCT/GetReportConfig`,
      UpdateReport: `${webConfig.apiBaseUrl}/api/SOCT/UpdateReportConfig`,
      GET_SOCT_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetSOCTLookups`,
      GetBulkMeterReadings: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetBulkMeterReadings`,
      GET_METER_READING_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetMeterReadingByID`,
      ADD_METER_READING: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/AddMeterReading`,
      UPDATE_METER_READING: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/UpdateMeterReading`,
      GET_SOCT_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetSOCTByID`,
      GET_METER_HISTORY_BY_SOCT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetMeterHistoryBySOCTID`,
      ADD_SOCT: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/AddSOCTRecord`,
      UPDATE_SOCT: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/UpdateSOCTRecord`,
      DOWNLOAD_FILE: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/DownloadFile`,
      GET_SERVICE_HISTORY_BY_SOCT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetServiceHistoryBySOCTID`,
      GET_SCHEDULE_SERVICE_HISTORY_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetScheduleServiceHistoryByID`,
      ADD_SCHEDULE_SERVICE_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/AddScheduledServiceHistory`,
      UPDATE_SCHEDULE_SERVICE_HISTORY: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/UpdateScheduledServiceHistory`,
      GET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID: `${webConfig.apiBaseUrl}/api/Sureway/Field/SOCT/GetSOCTCustomServiceItemsBySOCTID`,
    },
    IMAGE_GALLERY: {
      GET_IG_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Field/ImageGallerySearch`,
    },
    VENDOR: {
      GET_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorLookups`,
      GET_VENDOR_ORDERS: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorOrders`,
      GET_VENDOR_ITEMS_BY_ORDER_ID: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorItemsByOrderID`,
      GET_VENDOR_ORDER_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorOrderByID`,
      ADD_VENDOR_ORDER: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/AddVendorOrder`,
      UPDATE_VENDOR_ORDER: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/UpdateVendorOrder`,
      GET_NEXT_PO_BY_JOB_ID: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetNextPOByJobID`,
      GET_COST_CODES_BY_JOB_ID: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetCostCodesByJobID`,
      GET_VENDOR_PO_LOGS: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorPOLogs`,
      GET_VENDOR_PO_LOG_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/GetVendorPOLogByID`,
      SAVE_VENDOR_PO_LOG: `${webConfig.apiBaseUrl}/api/Sureway/Vendor/SaveVendorPOLog`,
    }
  },
  GET_TOOL_RENTALS: {
    GET_TOKEN: `${webConfig.apiBaseUrl}/api/GetShopUserToken`,
    VALIDATE_SHOP_GROUP: `${webConfig.apiBaseUrl}/api/ValidateShopGroup`,
  },
  IMG_DIRECTORY: `https://apps.sureway.ca/shared_images/`,
  EXTERNAL: {
    MOVE_REQUEST: `${webConfig.apiBaseUrl}/api/EquipmentTracker/GetEquipmentMoveRequests`,
    GET_EMPLOYEE_MESSAGES_BY_TOKEN: `${webConfig.apiBaseUrl}/api/Sureway/Field/GetEmployeeMessagesbyToken`,
    SAVE_EMPLOYEE_MESSAGE_RESPONSE: `${webConfig.apiBaseUrl}/api/Sureway/Field/SaveEmployeeMessageResponse`,
    GET_FORM_RESPONSE_BY_USER_TOKEN: `${webConfig.apiBaseUrl}/api/Sureway/Forms/GetFormResponseByUserToken`,
    SAVE_CREW_SIGNATURE_BY_ID: `${webConfig.apiBaseUrl}/api/Sureway/Forms/SaveCrewSignatureByID`,
  },
  SURVEY: {
    GET_SURVEY_LOOKUPS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetSurveyLookups`,
    GET_MACHINE_CONTROLS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetMachineControls`,
    ADD_MACHINE_CONTROL: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddMachineControl`,
    UPDATE_MACHINE_CONTROL: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateMachineControl`,
    GET_ATTACHMENTS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetAttachments`,
    ADD_SURVEY_ATTACHMENT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddAttachment`,
    UPDATE_SURVEY_ATTACHMENT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateAttachment`,
    GET_MACHINE_BOXES: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetMachineControlBoxes`,
    ADD_MACHINE_BOX: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddMachineControlBox`,
    UPDATE_MACHINE_BOX: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateMachineControlBox`,
    GET_MACHINE_RECEIVERS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetMachineReceivers`,
    ADD_MACHINE_RECEIVER: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddMachineReceiver`,
    UPDATE_MACHINE_RECEIVER: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateMachineReceiver`,
    GET_BASE_KITS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetBaseKits`,
    ADD_BASE_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddBaseKit`,
    UPDATE_BASE_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateBaseKit`,
    GET_ROVER_KITS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetRoverKits`,
    ADD_ROVER_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddRoverKit`,
    UPDATE_ROVER_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateRoverKit`,
    GET_REPEATER_KITS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetRepeaterKits`,
    ADD_REPEATER_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddRepeaterKit`,
    UPDATE_REPEATER_KIT: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateRepeaterKit`,
    GET_LASERS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetLasers`,
    ADD_LASER: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddLaser`,
    UPDATE_LASER: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateLaser`,
    GET_LEVELS: `${webConfig.apiBaseUrl}/api/Sureway/Survey/GetLevels`,
    ADD_LEVEL: `${webConfig.apiBaseUrl}/api/Sureway/Survey/AddLevel`,
    UPDATE_LEVEL: `${webConfig.apiBaseUrl}/api/Sureway/Survey/UpdateLevel`,
  }
};