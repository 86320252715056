import { IGET_ST_SCHEDULES_REQUEST, ISTSchedules, IGetSTSchedulesRequest } from "./getSTSchedulesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IGetSTSchedulesLoadAction {
    type: IGET_ST_SCHEDULES_REQUEST.REQUEST;
    data: IGetSTSchedulesRequest
}
export const getSTSchedulesLoadAction = (data: IGetSTSchedulesRequest): IGetSTSchedulesLoadAction => ({
    type: IGET_ST_SCHEDULES_REQUEST.REQUEST,
    data
});
export interface IGetSTSchedulesSuccessAction {
    type: IGET_ST_SCHEDULES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ISTSchedules>>;
}
export const getSTSchedulesLoadSuccessAction = (list: SurewayAPIResponse<ById<ISTSchedules>>): IGetSTSchedulesSuccessAction => ({
    type: IGET_ST_SCHEDULES_REQUEST.SUCCESS,
    list
});
export interface IGetSTSchedulesLoadFailedAction {
    type: IGET_ST_SCHEDULES_REQUEST.FAILED;
    message: string;
}
export const getSTSchedulesLoadFailedAction = (message: string): IGetSTSchedulesLoadFailedAction => ({
    type: IGET_ST_SCHEDULES_REQUEST.FAILED,
    message
});
