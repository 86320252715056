
export enum IGET_HAZARD_BY_ID_REQUEST {
    REQUEST = "getHazardByID/GET_HAZARD_BY_ID_REQUEST",
    SUCCESS = "getHazardByID/GET_HAZARD_BY_ID_SUCCESS",
    FAILED = "getHazardByID/GET_HAZARD_BY_ID_FAILED",
};

export interface IGetHazardByIDRequest {
    token: string;
    request: {
        id: number 
    }
};

export interface IGetHazardByID {
    id: number;
    hazard: string;
    active: string;
    departments: string;
    department_ID: number;
    type_ID: number;
    control_Plans: string[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    
};

