
import { IAuthPage, IRole } from './../../redux/getAuthorization/getAuthorizationConstants';
import jwt_decode from "jwt-decode";
import { IAuthorization } from "../../redux/getAuthorization/getAuthorizationConstants";
import { IIdName, IIdNameColor, SurewayAPIResponse } from "./publicInterfaces";
import { IToken } from "../../redux/getToken/getTokenConstants";
import { Server, hasPayload } from "../../redux/server";
import { webConfig } from '../../utils/webConfig';
import { EXTRA_LIGHT_YELLOW_COLOR, LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR } from './theme';
import { IFormTemplateFile } from '../../redux/field/getForms/getFormsConstants';

export const SUCCESS_MESSAGE = "Success";
export const deleteText = "Are you sure, you want to delete this ?";

export const calculateAge = (dateString: string): number => {
  var birthday = +new Date(dateString);
  return ~~((Date.now() - birthday) / (31557600000));
};

export const ConditionValues: { id: number, name: string }[] = [{ id: 1, name: "New" }, { id: 2, name: "Used" }];
export const YesOrNo: IIdName[] = [{ id: 1, name: "Yes" }, { id: 2, name: "No" }];
export const YesOrNoOrNA: IIdName[] = [{ id: 1, name: "Yes" }, { id: 2, name: "No" }, { id: 2, name: "NA" }];
export const PassOrFailOrNA: IIdName[] = [{ id: 1, name: "Pass" }, { id: 2, name: "Fail" }, { id: 3, name: "NA" }];
export const ToggleOrInput: IIdName[] = [{ id: 1, name: "Toggle" }, { id: 2, name: "Input" }];
export const ActiveOrInActive: IIdName[] = [{ id: 1, name: "Active" }, { id: 2, name: "InActive" }];
export const GreenOrRed: IIdName[] = [{ id: 1, name: "Green" }, { id: 2, name: "Red" }];
export const InOrOut: IIdName[] = [{ id: 1, name: "IN" }, { id: 2, name: "OUT" }];
export const SurveyDD: IIdNameColor[] = [
  { id: 1, name: "Office", color: LIGHT_GREEN_COLOR}, { id: 2, name: "Site", color: EXTRA_LIGHT_YELLOW_COLOR }, { id: 3, name: "Out for Service", color: LIGHT_BLUE_COLOR },
  { id: 4, name: "Retired", color: LIGHT_ORANGE_COLOR }, { id: 5, name: "Missing", color: LIGHT_RED_COLOR }
];

export const ZEROTH = 0;
export const ONE = 0;

export const isWeekend = (date: Date): boolean => {
  return date.getDay() === 0 || date.getDay() === 6;
};

export const convertNameToIdName = (data: any[], autoInc: boolean): IIdName[] => {
  const list: IIdName[] = [];
  let count = 0;

  data.forEach((x) => {
      list.push({ name: count ? x : x.name, id: autoInc ? count : x.id });
      count++;
  });

  return list;
};

export const lbsToKg = (lbs: number) => {
  const kgPerLb = 0.45359237;
  const kg = lbs * kgPerLb;
  return parseFloat(kg.toFixed(2));
}

export const kgToLbs = (kg: number) => {
  const lbPerKg = 2.20462;
  const lbs = kg * lbPerKg;
  return parseFloat(lbs.toFixed(2));
};

export const inchesToCm = (inches: number) => {
  return parseFloat((inches * 2.54).toFixed(4));
}
export const inchesTometer = (inches: number) => {
  return parseFloat((inches / 39.37).toFixed(4));
}
export const inchesToFoot = (inches: number) => {
  return parseFloat((inches / 12).toFixed(4));
};

export const cmToInches = (cm: number) => {
  return parseFloat((cm / 2.54).toFixed(4));
};
export const cmToMeter = (cm: number) => {
  return parseFloat((cm / 100).toFixed(4));
}
export const cmToFoot = (cm: number) => {
  return parseFloat((cm / 30.48).toFixed(4));
}

export const meterToInches = (meter: number) => {
  return parseFloat((meter * 39.37).toFixed(4));
};
export const meterToCM = (meter: number) => {
  return parseFloat((meter * 100).toFixed(4));
};
export const meterToFoot = (meter: number) => {
  return parseFloat((meter * 3.281).toFixed(4));
};


export const footToInches = (foot: number) => {
  return parseFloat((foot * 12).toFixed(4));
};
export const footToMeter = (foot: number) => {
  return parseFloat((foot / 3.281).toFixed(4));
};
export const footToCM = (foot: number) => {
  return parseFloat((foot * 30.48).toFixed(4));
};

export const getConvertedValue = (currentView: string, selectedView: string, value: number) => {
  const conversionMap: Record<string, (value: number) => number> = {
    "Inches-CM": inchesToCm,
    "Inches-Meters": inchesTometer,
    "Inches-Foot": inchesToFoot,
    "CM-Inches": cmToInches,
    "CM-Meters": cmToMeter,
    "CM-Foot": cmToFoot,
    "Meters-Inches": meterToInches,
    "Meters-CM": meterToCM,
    "Meters-Foot": meterToFoot,
    "Foot-Inches": footToInches,
    "Foot-CM": footToCM,
    "Foot-Meters": footToMeter,
  };

  const convert = conversionMap[`${currentView}-${selectedView}`];
  return convert ? convert(value) : value;
};

export const cubicYardsToCubicMeters = (cubicYards: number) => {
  return Math.round(cubicYards * 0.764555);
}

export const cubicMetersToCubicYards = (cubicMeters: number) => {
  return Math.round(cubicMeters * 1.30795);
};

export const reactVersionCheck = (): void => {
  const storageKey = "react-version";
  const deploymentVersion = webConfig.version;
  const localVersion = localStorage.getItem(storageKey);

  if((localVersion !== undefined) && (localVersion !== null)){
    if(deploymentVersion !== localVersion){
      localStorage.clear();
      sessionStorage.clear();
      
      if (caches) {
        caches.keys().then(cacheNames => {
          cacheNames.forEach(cacheName => {
            caches.delete(cacheName);
          });
        });
      }
      
      window.location.reload();
    }
  } else {
    if(deploymentVersion)
      localStorage.setItem(storageKey, deploymentVersion);
  };
};

export const gethourTime = (dt2: Date, dt1: Date): string => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  let num = Math.abs(Math.round(diff));
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  // const finHour = (hours + 11) % 12 + 1;
  return `${hours}:${padLeadingZeros(minutes, 2)}`;
};

export const gethourOnlyTime = (dt2: Date, dt1: Date): string => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  let num = Math.abs(Math.round(diff));
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  const tHour = minutes / 60;
  const totalHours = hours + tHour;
  // const finHour = (hours + 11) % 12 + 1;
  return `${totalHours}`;
};

const padLeadingZeros = (num: number, size: number): string => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}
export const undefinedFunction = (): void => undefined;

// Javascript Month Index starts from 0, so it return wrong month number so that's why we need to declare these below array values
export const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
  "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
];

export const monthInNumber = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getDate = (showTime: boolean, value?: string): string => {
  if (value !== "" && value) {

    const date = new Date(value);
    let dateFormat = `${monthInNumber[date.getMonth()]}/${date.getDate()}/${date.getFullYear()}`;

    if (showTime) {
      let hours: any = date.getHours();
      let minutes: any = date.getMinutes();
      let ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12;

      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      dateFormat = dateFormat + " " + hours + ':' + minutes + ' ' + ampm;
    };

    return dateFormat;
  }
  return "";
};

export const fileToBase64Image = async (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const checkFileNameDuplication = (files: IFormTemplateFile[]): boolean => {
    if(files && files.length > 1) {
        const filesName = new Set<string>();
        return files.some(file => {
            if (filesName.has(file.name)) return true; 
            filesName.add(file.name);
            return false;
        });
    }
    return false;
}

export const base64ToImageUrl = (base64String :string, format = 'png') => {
  // Check if the string is already an image URL (data URL format)
  if(base64String) {
    if (base64String.startsWith('data:image/')) {
        return base64String;
    }
    // Otherwise, convert it to a data URL
    return `data:image/${format};base64,${base64String}`;
  }
}

/* This function helps to convert any kind of image file to webP format which keeps image quality but decrease the image size */
export const imageFileToWebP = async (file: File): Promise<string> => {
  const img = new Image();
  img.src = URL.createObjectURL(file);

  await new Promise((resolve, reject) => {
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      resolve(true);
    };
    img.onerror = reject;
  });

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  const ctx: any = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob === null) {
        reject(new Error("Failed to convert image to webp"));
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          if (reader.result) {
            resolve(reader.result.toString());
          } else {
            reject(new Error("Failed to convert image to webp"));
          }
        };
      }
    }, "image/webp", 0.8);
  });
};

export interface IQueryString {
  id?: string;
  clone_ID?: string;
  token?: string;
  keyword?: string;
  view?: string;
  attachment_ID? : string;
  soct_ID? : string;
  logId? : string;
  form_ID? : string;
  page? : string;
  defect_ID? : string;
  unit_ID? : string;
};

interface ICallRouteWithQueryStringProps {
  route: any;
  pathName: string;
  search: IQueryString;
};

export const callRouteWithQueryString = (props: ICallRouteWithQueryStringProps): void => {
  let search = {};
  if (props.search.id)
    search = { ...search, id: props.search.id };
  if (props.search.clone_ID)
    search = { ...search, clone_ID: props.search.clone_ID };
  if (props.search.attachment_ID)
    search = { ...search, attachment_ID: props.search.attachment_ID };
  if (props.search.token)
    search = { ...search, token: props.search.token };
  if (props.search.keyword)
    search = { ...search, keyword: props.search.keyword };
  if (props.search.view)
    search = { ...search, view: props.search.view };
  if (props.search.soct_ID)
    search = { ...search, soct_ID: props.search.soct_ID };
  if (props.search.logId)
    search = { ...search, logId: props.search.logId };
  if (props.search.form_ID)
    search = { ...search, form_ID: props.search.form_ID };
  if (props.search.page)
    search = { ...search, page: props.search.page };
  if (props.search.defect_ID)
    search = { ...search, defect_ID: props.search.defect_ID };
  if (props.search.unit_ID)
    search = { ...search, unit_ID: props.search.unit_ID };

  props.route.history.push({ pathname: props.pathName, search: "?" + new URLSearchParams(search).toString() });
};

export const StaticToken = "19515195-d571-44a3-b0db-637f5bf24f54";

export const generateRandomText = (length: number): string => {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
};

export const addDashesToPhone = (phone: string): string => {
  // eslint-disable-next-line no-useless-escape
  phone = phone.replace('/\D[^\.]/g', "");
  phone = phone.replace(/-/g, "");
  phone = phone.slice(0, 3) + "-" + phone.slice(3, 6) + "-" + phone.slice(6);
  return phone;
};

export const numberAndDashCheck = (value: string): boolean => {
  return /^[0-9-]*$/.test(value);
};

export const numberAndDecimalCheck = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};

export const accessCheck = (roles: IAuthorization[], page: IAuthPage): IRole => {
  const findIndex = roles.findIndex(x => x.page === page);

  if (findIndex >= 0) {
    return roles[findIndex].access;
  } else {
    return "NA";
  }
};

export type IPageRoleAccessTypes = "jobList" | "locationList" | "partsAccess" | "filterList" | "repairLine" | "pmSheets" | "workingSiteLogs" | "dispatch" | "externalUsers" | "attachmentAccess" | "kantechAccess" | "etAccess" | "workorder" | "soctAccess" | "partsDispatch" | "itAccess" | "formsAdmin" | "surveyAccess" | 'vendorAccess' | "formsCleanupAccess";

export const pageAccessCheck = (tokenResponse: Server<SurewayAPIResponse<IToken>>, type: IPageRoleAccessTypes): IRole => {
  if (hasPayload(tokenResponse)) {
    const findRole = tokenResponse.payload.response[type];

    if (findRole) {
      return findRole;
    } else {
      return "NA";
    }
  } else {
    return "NA";
  }
};

export interface IConvertList {
  id: string;
  name: string;
};

export const convertList = (list: any[]): IConvertList[] => {
  let response = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i] !== null)
      response.push({ id: list[i], name: list[i] });
  };
  return response;
};


export const sortByName = (a: any, b: any, objName: string): any => {
  if (a[objName].toLowerCase() < b[objName].toLowerCase()) { return -1; }
  if (a[objName].toLowerCase() > b[objName].toLowerCase()) { return 1; }

  return 0;
};

export const getTokenFromUrl = (onlyToken: boolean): string | undefined => {
  const url = window.location.search;


  if (url.length > 0 && url.indexOf("token") > 0) {
    const urlParams = new URLSearchParams(window.location.search);    
    const getToken = urlParams.get('token');
    if (getToken && ((getToken.length > 0) && (!getToken.includes("?")))) {
      if (onlyToken) {
        return getToken;
      } else {
        return url;
      }
    } else {
      return " ";
    }
  };

  return undefined;
};

export const unSecuredRouteCheck = (): boolean => {
  if(window.location.pathname.includes("external")){
    if(window.location.pathname.includes("external-user")){
      return false;
    }
    return true;
  } else {
    return /^\/.*\/(external)(?!-user)(\/|$)/.test(window.location.pathname);
  }
};

const getAdalToken = localStorage.getItem("adal.idtoken");

export const token: any = (getAdalToken !== null && getAdalToken.length > 0) ? jwt_decode(getAdalToken) : { unique_name: "SUREWAYGROUP\\amit.patel" };

export const userName = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

export const compareTwoObjects = (obj1: any, obj2: any) => {
  const differences: any = {};

  for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
          differences[key] = obj1[key];
      }
  }

  return differences;
}

//#region User Device Info
export const getDeviceAndIPInfo = async (): Promise<string> => {
  const ip = await getPublicIP();
  const device = await getDeviceType();
  const bVersion = await getBrowserDetails();
  const appVersion = webConfig.version;
  
  return `IP: ${ip}, DEVICE: ${device}, BROWSER NAME: ${bVersion.name}, BROWSER VERSION: ${bVersion.version}, REACT APP VERSION: ${appVersion}`;
};

interface IBrowserDetail {
  name: string;
  version: number | string;
};

const getBrowserDetails = () => {
  const { userAgent } = navigator;
  const browserInfo: IBrowserDetail = { name: "", version: "" };

  if (userAgent.indexOf('Chrome') > -1) {
    browserInfo.name = 'Chrome';
  } else if (userAgent.indexOf('Safari') > -1) {
    browserInfo.name = 'Safari';
  } else if (userAgent.indexOf('Firefox') > -1) {
    browserInfo.name = 'Firefox';
  } else if (userAgent.indexOf('Edge') > -1) {
    browserInfo.name = 'Edge';
  } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
    browserInfo.name = 'Internet Explorer';
  } else {
    browserInfo.name = 'Unknown';
  }

  const versionMatch = userAgent.match(/(?:MSIE |Edge\/|Firefox\/|Chrome\/|Safari\/)(\d+)/);
  browserInfo.version = versionMatch ? parseInt(versionMatch[1]) : 'Unknown';

  return browserInfo;
};

const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();

  if (/android/i.test(userAgent)) {
    return 'Android';
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    return 'iOS';
  } else if (/tablet|ipad/i.test(userAgent)) {
    return 'Tablet';
  } else if (/mac/i.test(platform)) {
    return 'Mac';
  } else if (/win/i.test(platform)) {
    return 'Windows';
  } else if (/linux/i.test(platform)) {
    return 'Linux';
  } else {
    return 'Laptop/Desktop';
  }
};

const getPublicIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip.toString();
  } catch (error) {
    console.error('Error fetching public IP:', error);
    return "Unavailable";
  }
};
//#endregion