import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST, IWSSLogsHistory } from "./getWSSLogsHistoryBySiteIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWSSLogsHistoryBySiteIDLoadAction, IGetWSSLogsHistoryBySiteIDLoadFailedAction, IGetWSSLogsHistoryBySiteIDSuccessAction } from "./getWSSLogsHistoryBySiteIDActions";

type Actions =
    | IGetWSSLogsHistoryBySiteIDLoadAction
    | IGetWSSLogsHistoryBySiteIDSuccessAction
    | IGetWSSLogsHistoryBySiteIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetWSSLogsHistoryBySiteIDReducer = (state: Server<SurewayAPIResponse<IWSSLogsHistory[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IWSSLogsHistory[]>> => {
    switch (action.type) {
        case IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.REQUEST:
            return loading;

        case IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};