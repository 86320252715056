import { ICostCode } from "../../workingSiteLogs/getFieldLookups/getFieldLookupsConstants";

export enum IGET_VENDOR_LOOKUPS_REQUEST {
    REQUEST = "getVendorLookups/GET_VENDOR_LOOKUPS_REQUEST",
    SUCCESS = "getVendorLookups/GET_VENDOR_LOOKUPS_SUCCESS",
    FAILED = "getVendorLookups/GET_VENDOR_LOOKUPS_FAILED"
};

export interface IVendorLookupRequest {
    token: string;
    request: {
        page: "Vendor" | "Vendor_Log";
    };
};

export interface IVendorLookup {
    vendorList: IVendorList[];
    costCodeList: ICostCode[];
    wssList: IVendorWSS[];
    jobs: IVendorJobList[];
    foremanList: IVendorForeman[];
};

export interface IVendorList {
    id: number;
    po: string;
    vendor: string;
    requested_By: string;
    requested_By_Name: string;
    date: string;
    job_ID: number;
    job_No: string;
};

export interface IVendorForeman {
    upn: string;
    display_Name: string;
};

export interface IVendorWSS {
    site: string;
    wsS_ID: number;
    site_Name: string;
    sub_Site_Name: string;
    job: string;
    site_Address: string;
    equip_On_Site: number;
}

export interface IVendorJobList {
    id: number;
    job_No: string;
    name: string;
    company: string;
    project_Manager: string;
    year: string;
    type: string;
    client: string;
    coordinator: string;
    superintendent: string;
    active: string;
    location: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    location_Id: number;
    working_Site: string;
};