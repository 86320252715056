import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { IGET_AUTHORIZATION_REQUEST, IAuthorization, IAuthorizationRequest } from "./getAuthorizationConstants";


export interface IGetAuthorizationLoadAction {
    type: IGET_AUTHORIZATION_REQUEST.REQUEST;
    data: IAuthorizationRequest
}
export const getAuthorizationLoadAction = (data: IAuthorizationRequest): IGetAuthorizationLoadAction => ({
    type: IGET_AUTHORIZATION_REQUEST.REQUEST,
    data
});
export interface IGetAuthorizationSuccessAction {
    type: IGET_AUTHORIZATION_REQUEST.SUCCESS;
    data: SurewayAPIResponse<IAuthorization[]>;
}
export const getAuthorizationLoadSuccessAction = (data: SurewayAPIResponse<IAuthorization[]>): IGetAuthorizationSuccessAction => ({
    type: IGET_AUTHORIZATION_REQUEST.SUCCESS,
    data
});
export interface IGetAuthorizationLoadFailedAction {
    type: IGET_AUTHORIZATION_REQUEST.FAILED;
    message: string;
}
export const getAuthorizationLoadFailedAction = (message: string): IGetAuthorizationLoadFailedAction => ({
    type: IGET_AUTHORIZATION_REQUEST.FAILED,
    message
});
