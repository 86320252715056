import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SOCT_LOOKUPS_REQUEST, ISOCTLookup, ISOCTLookupRequest } from "./getSOCTLookupsConstants";

export interface IGetSOCTLookupsLoadAction {
    type: IGET_SOCT_LOOKUPS_REQUEST.REQUEST;
    data: ISOCTLookupRequest
}
export const getSOCTLookupsLoadAction = (data: ISOCTLookupRequest): IGetSOCTLookupsLoadAction => ({
    type: IGET_SOCT_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetSOCTLookupsSuccessAction {
    type: IGET_SOCT_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISOCTLookup>;
}
export const getSOCTLookupsLoadSuccessAction = (list: SurewayAPIResponse<ISOCTLookup>): IGetSOCTLookupsSuccessAction => ({
    type: IGET_SOCT_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetSOCTLookupsLoadFailedAction {
    type: IGET_SOCT_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getSOCTLookupsLoadFailedAction = (message: string): IGetSOCTLookupsLoadFailedAction => ({
    type: IGET_SOCT_LOOKUPS_REQUEST.FAILED,
    message
});
