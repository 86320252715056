import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST {
    REQUEST = "getWSSLogsHistoryBySiteID/GET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST",
    SUCCESS = "getWSSLogsHistoryBySiteID/GET_WSS_LOGS_HISTORY_BY_SITE_ID_SUCCESS",
    FAILED = "getWSSLogsHistoryBySiteID/GET_WSS_LOGS_HISTORY_BY_SITE_ID_FAILED"
};

export interface IGetWSSLogsHistoryBySiteIDRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};

export interface IWSSLogsHistory {
  id: number;
  equip_On_Site: number;
  equip_Working: number;
  emp_Working: number;
  service_Required: string;
  prime_Contractor: string;
  radio: string;
  start_Time_Val: string;
  end_Time_Val: string;
  work_Description: string;
  safety_Observations_Comments: string;
  saturday_Working: string;
  sunday_Working: string;
  scheduled_Days_Off: string;
  job_No: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  date_: string;
  foreman_Display: string;
  wsS_ID: number;
  status: string;
  site: string;
  images: null;
  images_Count: number;
  logs_Count: number;
};