import { IAddUpdateHazard } from "../addHazard/addHazardConstants";

export enum IUPDATE_HAZARD_REQUEST {
    REQUEST = "updateHazard/UPDATE_HAZARD_REQUEST",
    SUCCESS = "updateHazard/UPDATE_HAZARD_SUCCESS",
    FAILED = "updateHazard/UPDATE_HAZARD_FAILED"
};

export interface IUpdateHazardRequest {
    token: string;
    request: IAddUpdateHazard;
};