import React from "react";
import { HasClass } from "../../../shared/publicInterfaces";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { IAcknowledgeForm } from "../../../../redux/field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDConstants";

interface IFormResponseAcknowledgeProps extends HasClass {
    open: boolean;
    acknowledge_Status: boolean | undefined;
    acknowledge_Form: IAcknowledgeForm | undefined;
    onCancel: () => void;
    onAcknowledge: () => void;
    display: string | undefined;
    style?: React.CSSProperties;
};

export const FormResponseAcknowledge: React.FC<IFormResponseAcknowledgeProps> = React.memo((props: IFormResponseAcknowledgeProps) => {

    return <Dialog 
        open={props.open} 
        onClose={props.onCancel}
        PaperProps={{
            style: {
                ...props.style, 
            },
        }}
    >
        <DialogTitle style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <strong>{props.display}</strong>
        </DialogTitle>
        <DialogContent>
            <span>{props.acknowledge_Form?.message}</span>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onAcknowledge} color="primary">
                Acknowledge
            </Button>
            <Button onClick={props.onCancel} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
});