import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_DRIVERS_REQUEST, IETDriver } from "./getDriversConstants";
import { IADD_DRIVER_REQUEST } from "../addDriver/addDriverConstants";
import { IAddDriverSuccessAction } from "../addDriver/addDriverActions";
import { IUPDATE_DRIVER_REQUEST } from "../updateDriver/updateDriverConstants";
import { IUpdateDriverSuccessAction } from "../updateDriver/updateDriverActions";
import { IGetDriversLoadAction, IGetDriversLoadFailedAction, IGetDriversSuccessAction } from "./getDriversActions";

type Actions =
    | IGetDriversLoadAction
    | IGetDriversSuccessAction
    | IAddDriverSuccessAction
    | IUpdateDriverSuccessAction
    | IGetDriversLoadFailedAction
    | IFlushDataSuccessAction;

export const GetETDriversReducer = (state: Server<IETDriver[]> = notLoaded, action: Actions): Server<IETDriver[]> => {
    switch (action.type) {
        case IGET_DRIVERS_REQUEST.REQUEST:
            return loading;

        case IGET_DRIVERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DRIVERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_DRIVER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_DRIVER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};