import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST, IRepairLineByMechanicId, IGetRepairLineByMechanicIdRequest } from "./getRepairLineByMechanicIdConstants";

export interface IGetRepairLineByMechanicIdLoadAction {
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.REQUEST;
    data: IGetRepairLineByMechanicIdRequest
}
export const getRepairLineByMechanicIdLoadAction = (data: IGetRepairLineByMechanicIdRequest): IGetRepairLineByMechanicIdLoadAction => ({
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineByMechanicIdSuccessAction {
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLineByMechanicId[]>;
}
export const getRepairLineByMechanicIdLoadSuccessAction = (list: SurewayAPIResponse<IRepairLineByMechanicId[]>): IGetRepairLineByMechanicIdSuccessAction => ({
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineByMechanicIdLoadFailedAction {
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.FAILED;
    message: string;
}
export const getRepairLineByMechanicIdLoadFailedAction = (message: string): IGetRepairLineByMechanicIdLoadFailedAction => ({
    type: IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST.FAILED,
    message
});
