import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST, IMechanicTimesheetForApproval, IGetMechanicTimesheetForApprovalRequest } from './getMechanicTimesheetForApprovalConstants';

export interface IGetMechanicTimesheetForApprovalLoadAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.REQUEST;
    data: IGetMechanicTimesheetForApprovalRequest
}
export const getMechanicTimesheetForApprovalLoadAction = (data: IGetMechanicTimesheetForApprovalRequest): IGetMechanicTimesheetForApprovalLoadAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.REQUEST,
    data
});
export interface IGetMechanicTimesheetForApprovalSuccessAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>;
}
export const getMechanicTimesheetForApprovalLoadSuccessAction = (list: SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>): IGetMechanicTimesheetForApprovalSuccessAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicTimesheetForApprovalLoadFailedAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.FAILED;
    message: string;
}
export const getMechanicTimesheetForApprovalLoadFailedAction = (message: string): IGetMechanicTimesheetForApprovalLoadFailedAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.FAILED,
    message
});
