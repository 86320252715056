import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IREJECT_ST_TIMESHEET_REQUEST } from "./rejectSTTimesheetConstants";
import { IRejectSTTimesheetLoadAction, IRejectSTTimesheetLoadFailedAction, IRejectSTTimesheetSuccessAction, rejectSTTimesheetLoadFailedAction, rejectSTTimesheetLoadSuccessAction } from "./rejectSTTimesheetActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const rejectSTTimesheetEpic: Epic = (
    action$: ActionsObservable<IRejectSTTimesheetLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IRejectSTTimesheetSuccessAction | IRejectSTTimesheetLoadFailedAction> =>
    action$.ofType(IREJECT_ST_TIMESHEET_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.DISPATCH.REJECT_ST_TIMESHEET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IRejectSTTimesheetSuccessAction | IRejectSTTimesheetLoadFailedAction => {
                            if(response.message === "Success"){
                                return rejectSTTimesheetLoadSuccessAction(response);
                            } else {
                                return rejectSTTimesheetLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(rejectSTTimesheetLoadFailedAction(response.message)))
                    )
            )
        );