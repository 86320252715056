import React from "react";
import { IGetAttachmentVersionHistory } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { TableContainer } from "@material-ui/core";
import { undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { IGetETMoveAttachmentVersionHistory } from "../../../redux/field/getETMoveAttachmentVersionHistory/getETMoveAttachmentVersionHistoryConstants";


interface IAttachmentVersionHistoryProps<T> extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: T;
};

export const AttachmentVersionHistory: React.FC<IAttachmentVersionHistoryProps<ById<IGetAttachmentVersionHistory>>> = React.memo((props: IAttachmentVersionHistoryProps<ById<IGetAttachmentVersionHistory>>) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data.map(x=>x.attachment_No)[0])
    // console.log(props.data)

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY - {data.map(x=>x.attachment_No)[0]}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <LADevExtremeGrid
                            data={data}
                            columnChoose={false}
                            columnsHiding={false}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="attachmentVersionHistoryList"
                            customRowColor={true}
                            exportFileName="Attachment-Version-History"
                            storageKey="attachmentVersionHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "attached_Unit_No", caption: "Attached Unit #", type: "string"},
                                { name: "location", caption: "Location", type: "string"},
                                { name: "job_No", caption: "Job No", type: "string"},
                                { name: "move_Status", caption: "Move Status", type: "string" },
                                { name: "type", caption: "Type", type: "string" },
                                { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                { name: "style", caption: "Style", type: "string" },
                                { name: "weight", caption: "Weight", type: "number" },
                                { name: "width", caption: "Width", type: "number" },
                                { name: "length", caption: "Length", type: "number" },
                                { name: "eT_Move_Pilot_Required", caption: "ET pilot Required", type: "string" },
                                { name: "capacity", caption: "Capacity", type: "number" },
                                { name: "serial_Number", caption: "Serial #", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "notes", caption: "Notes", type: "string" }
                                // { name: "attachment_No", caption: "Attachment #", type: "string" },
                                // { name: "operation", caption: "Operation", type: "string" },
                                // { name: "make", caption: "Make", type: "string" },
                                // { name: "model", caption: "Model", type: "string" },
                                // { name: "owner", caption: "Owner", type: "string" },
                                // { name: "inspection_Date", caption: "Inspection Date", type: "date" },
                                // { name: "repair_Status", caption: "Repair Status", type: "string" },

                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});


export const ETMoveAttachmentVersionHistory: React.FC<IAttachmentVersionHistoryProps<IGetETMoveAttachmentVersionHistory[]>> = React.memo((props: IAttachmentVersionHistoryProps<IGetETMoveAttachmentVersionHistory[]>) => {
    const data = Object.values(props.data);

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY - {data.map(x=>x.attachment_No)[0]}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <LADevExtremeGrid
                            data={data}
                            columnChoose={false}
                            columnsHiding={false}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="ETMoveVersionHistoryList"
                            customRowColor={true}
                            exportFileName="ET-Move_Attachment-Version-History"
                            storageKey="et-move-VersionHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "attached_Unit_No", caption: "Attached Unit #", type: "string"},
                                { name: "location", caption: "Location", type: "string"},
                                // { name: "operation", caption: "Operation", type: "number"},
                                { name: "type", caption: "Type", type: "string" },
                                { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                { name: "style", caption: "Style", type: "string" },
                                { name: "from_Location", caption: "From Location", type: "string" },
                                { name: "to_Location", caption: "To Location", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "site", caption: "Site", type: "string" },
                                { name: "complete_By", caption: "Complete By", type: "string" },
                                { name: "move_Type", caption: "Move Type", type: "string" },
                                { name: "previously_Moved", caption: "Previously Moved", type: "string" },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});