import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST } from "./removeDepartmentFromItemsConstants";
import { IRemoveDepartmentFromItemsLoadAction, IRemoveDepartmentFromItemsLoadFailedAction, IRemoveDepartmentFromItemsSuccessAction } from "./removeDepartmentFromItemsActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IRemoveDepartmentFromItemsLoadAction
    | IRemoveDepartmentFromItemsSuccessAction
    | IRemoveDepartmentFromItemsLoadFailedAction
    | IFlushDataSuccessAction;

export const RemoveDepartmentFromItemsReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.REQUEST:
            return loading;

        case IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};