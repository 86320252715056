
export enum IADD_SERVICE_SECTION_REQUEST {
    REQUEST = "addServiceSection/ADD_SERVICE_SECTION_REQUEST",
    SUCCESS = "addServiceSection/ADD_SERVICE_SECTION_SUCCESS",
    FAILED = "addServiceSection/ADD_SERVICE_SECTION_FAILED"
};

export interface IAddServiceSectionRequest {
    token: string;
    request: IAddUpdateServiceSection;
};

export interface IAddUpdateServiceSection {
    ID: number;  
    Section_Name: string;   
    Active: string;
    Equipment_Type_ID: number;
    Service_Due_ID: number;
    Created_By: string;
    Modified_By: string;
    Service_Items: IAddUpdateServiceItem[];
    Display_Order: number;
    company: string;
};

export interface IAddUpdateServiceItem {
    ID?: number; // Required for Update
    Service_Item_Name: string;
    Apply_To_Make?: string;
    Apply_To_Model?: string;
    Apply_To_Unit_No?: string;
    Exclude_Unit_No?: string;
    Exclude_Model?: string;
    Comments?: string;
    Active: string;
    Picture_Required: string;
    Type: string;
    Display_Order: number;
    Service_Hours: string;
};