import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_TASKS_REQUEST, IGetTasks, IGetTasksRequest } from "./getTasksConstants";

export interface IGetTasksLoadAction {
    type: IGET_TASKS_REQUEST.REQUEST;
    data: IGetTasksRequest
}
export const getTasksLoadAction = (data: IGetTasksRequest): IGetTasksLoadAction => ({
    type: IGET_TASKS_REQUEST.REQUEST,
    data
});
export interface IGetTasksSuccessAction {
    type: IGET_TASKS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetTasks>>;
}
export const getTasksLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetTasks>>): IGetTasksSuccessAction => ({
    type: IGET_TASKS_REQUEST.SUCCESS,
    list
});
export interface IGetTasksLoadFailedAction {
    type: IGET_TASKS_REQUEST.FAILED;
    message: string;
}
export const getTasksLoadFailedAction = (message: string): IGetTasksLoadFailedAction => ({
    type: IGET_TASKS_REQUEST.FAILED,
    message
});
