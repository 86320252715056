import { IREJECT_MECHANIC_LOG_REQUEST } from "./rejectMechanicLogConstants";
import { IRejectMechanicLogLoadAction, IRejectMechanicLogLoadFailedAction, IRejectMechanicLogSuccessAction } from "./rejectMechanicLogActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IRejectMechanicLogLoadAction
    | IRejectMechanicLogSuccessAction
    | IRejectMechanicLogLoadFailedAction
    | IFlushDataSuccessAction;

export const RejectMechanicLogReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IREJECT_MECHANIC_LOG_REQUEST.REQUEST:
            return loading;

        case IREJECT_MECHANIC_LOG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IREJECT_MECHANIC_LOG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};