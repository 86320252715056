import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SERVICE_SHEET_REPORT_REQUEST, IServiceSheetReport } from "./getServiceSheetReportConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetServiceSheetReportLoadAction, IGetServiceSheetReportLoadFailedAction, IGetServiceSheetReportSuccessAction, getServiceSheetReportLoadFailedAction, getServiceSheetReportLoadSuccessAction } from "./getServiceSheetReportActions";

export const getServiceSheetReportEpic: Epic = (
    action$: ActionsObservable<IGetServiceSheetReportLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetServiceSheetReportSuccessAction | IGetServiceSheetReportLoadFailedAction> =>
    action$.ofType(IGET_SERVICE_SHEET_REPORT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IServiceSheetReport>>>(
                    END_POINTS.FIELD.PM_SHEETS.GET_SERVICE_SHEET_REPORT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IServiceSheetReport>>): IGetServiceSheetReportSuccessAction => {
                            return getServiceSheetReportLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getServiceSheetReportLoadFailedAction(response.message)))
                    )
            )
        );