import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGetHazardByID, IGET_HAZARD_BY_ID_REQUEST } from "./getHazardByIDConstants";
import { IGetHazardByIDLoadAction, IGetHazardByIDLoadFailedAction, IGetHazardByIDSuccessAction } from "./getHazardByIDActions";
import { IADD_HAZARD_NEW_REQUEST } from "../addHazardNew/addHazardNewConstants";
import { IAddHazardNewSuccessAction } from "../addHazardNew/addHazardNewActions";
import { IUPDATE_HAZARD_NEW_REQUEST } from "../updateHazardNew/updateHazardNewConstants";
import { IUpdateHazardNewSuccessAction } from "../updateHazardNew/updateHazardNewActions";

type Actions =
    | IGetHazardByIDLoadAction
    | IGetHazardByIDSuccessAction
    | IGetHazardByIDLoadFailedAction
    | IAddHazardNewSuccessAction
    | IUpdateHazardNewSuccessAction
    | IFlushDataSuccessAction;

export const GetHazardByIDReducer = (state: Server<SurewayAPIResponse<IGetHazardByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetHazardByID>> => {
    switch (action.type) {
        case IGET_HAZARD_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_HAZARD_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_HAZARD_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_HAZARD_NEW_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_HAZARD_NEW_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};