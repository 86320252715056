import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MACHINE_RECEIVER_REQUEST, IMachineReceiver, IMachineReceiverRequest } from "./getMachineReceiversConstants";

export interface IGetMachineReceiverLoadAction {
    type: IGET_MACHINE_RECEIVER_REQUEST.REQUEST;
    data: IMachineReceiverRequest
}
export const getMachineReceiverLoadAction = (data: IMachineReceiverRequest): IGetMachineReceiverLoadAction => ({
    type: IGET_MACHINE_RECEIVER_REQUEST.REQUEST,
    data
});
export interface IGetMachineReceiverSuccessAction {
    type: IGET_MACHINE_RECEIVER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMachineReceiver>>;
}
export const getMachineReceiverLoadSuccessAction = (list: SurewayAPIResponse<ById<IMachineReceiver>>): IGetMachineReceiverSuccessAction => ({
    type: IGET_MACHINE_RECEIVER_REQUEST.SUCCESS,
    list
});
export interface IGetMachineReceiverLoadFailedAction {
    type: IGET_MACHINE_RECEIVER_REQUEST.FAILED;
    message: string;
}
export const getMachineReceiverLoadFailedAction = (message: string): IGetMachineReceiverLoadFailedAction => ({
    type: IGET_MACHINE_RECEIVER_REQUEST.FAILED,
    message
});
