import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST, IRemoveDepartmentFromItemsRequest } from "./removeDepartmentFromItemsConstants";


export interface IRemoveDepartmentFromItemsLoadAction {
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.REQUEST;
    data: IRemoveDepartmentFromItemsRequest
}
export const removeDepartmentFromItemsLoadAction = (data: IRemoveDepartmentFromItemsRequest): IRemoveDepartmentFromItemsLoadAction => ({
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.REQUEST,
    data
});
export interface IRemoveDepartmentFromItemsSuccessAction {
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const removeDepartmentFromItemsLoadSuccessAction = (message: SurewayAPIResponse<string>): IRemoveDepartmentFromItemsSuccessAction => ({
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.SUCCESS,
    message
});
export interface IRemoveDepartmentFromItemsLoadFailedAction {
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.FAILED;
    message: string;
}
export const removeDepartmentFromItemsLoadFailedAction = (message: string): IRemoveDepartmentFromItemsLoadFailedAction => ({
    type: IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.FAILED,
    message
});
