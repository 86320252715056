import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../../shared/errorBox";
import { FormsMenu, Navbar } from "../../../shared/navbars";
import { IGetCrews, IGetCrewsRequest } from "../../../../redux/field/getCrews/getCrewsConstants";
import { getCrews } from "../../../../redux/field/getCrews/getCrewsAccessor";
import { getCrewsLoadAction } from "../../../../redux/field/getCrews/getCrewsActions";
import { WorkingSiteLogSubHeader } from "../../../header/WorkingSiteLogSubHeader";
import { IGetCrewVersionHistory, IGetCrewVersionHistoryRequest } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryConstants";
import { getCrewVersionHistory } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryAccessor";
import { getCrewVersionHistoryLoadAction } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryActions";
import { CrewVersionHistoryPopup } from "./crewVersionHistoryPopup";

interface ICrewsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getCrews: Server<SurewayAPIResponse<ById<IGetCrews>>>;
    getCrewVersionHistory: Server<SurewayAPIResponse<ById<IGetCrewVersionHistory>>>;
};

interface ICrewsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getCrewsRequest: (data: IGetCrewsRequest) => unknown;
    getCrewVersionHistoryRequest: (data: IGetCrewVersionHistoryRequest) => unknown;
};


interface ICrewsOwnProps {
};

interface ICrewsState {
    crewVersionHistoryPopup: boolean;
    // responsesPopUpData: IForemanMessaging | undefined;
};

const CrewsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ICrewsProps = RouteComponentProps
    & ICrewsStoreProps
    & ICrewsDispatchProps
    & ICrewsOwnProps;

class Crews extends PureComponent<ICrewsProps, ICrewsState> {

    public constructor(props: ICrewsProps) {
        super(props);
        this.state = {
            // responsesPopUpData: undefined,
            crewVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: ICrewsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { crewVersionHistoryPopup } = this.state;
        const { getCrews, getToken, getCrewVersionHistory } = this.props;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const data = hasPayload(getCrews) ? Object.values(getCrews.payload.response) : [];
        const crewVersionHistoryPopupData = hasPayload(getCrewVersionHistory) ? Object.values(getCrewVersionHistory.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Crews" description="Foreman Crews List" fixedSpaceOnSmallerScreens={true}>
                {/* <Navbar menu={FormsMenu} role={true}/> */}
                <WorkingSiteLogSubHeader
                    {...this.props}
                />
                {(getRole !== NotApplicable) && 
                    <CrewsStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">CREWS</h2>
                                    <hr />

                                    {getCrews.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Crews..." />}
                                    {getCrews.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Crews..." />}

                                    {getCrews.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={120}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="CrewsList"
                                                    customRowColor={true}
                                                    storageKey="CrewsSessionKey"
                                                    exportFileName="Crews"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "foreman", caption: "Foreman", type: "string" },
                                                        { name: "crew_Name", caption: "Crew Name", type: "string" },
                                                        { name: "employees", caption: "Employees", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "version_History", caption: "Version History", type: "button"},
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                            {crewVersionHistoryPopup && <CrewVersionHistoryPopup
                                data={crewVersionHistoryPopupData}
                                display={`Version History`}
                                open={crewVersionHistoryPopup}
                                status={getCrewVersionHistory.kind}
                                onCancel={this.handleCrewVersionHistoryPopupClose}
                            />}
                        </LAPaperWithPadding>
                    </CrewsStyles>
                }
            </PageSpacing>
        
        );
    }


    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.CREW.ADD_UPDATE_CREW
        });
    };

    private onClick = (): void => {

    };

    
    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.CREW.ADD_UPDATE_CREW
        });
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IGetCrews = e.row.data;
        if (name === "version_History") {
            this.handleCrewVersionHistory(data)
        }
    }

    private handleCrewVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            await this.props.getCrewVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: data.id,
                }
            });
        }
        this.setState({ crewVersionHistoryPopup: true });
    }

    private handleCrewVersionHistoryPopupClose = (): void => {
        this.setState({ crewVersionHistoryPopup: false });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getCrews)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getCrewsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ICrewsStoreProps => ({
    getToken: getToken(state),
    getCrews: getCrews(state),
    getCrewVersionHistory: getCrewVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ICrewsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getCrewsRequest: (data: IGetCrewsRequest) => dispatch(getCrewsLoadAction(data)),
    getCrewVersionHistoryRequest: (data: IGetCrewVersionHistoryRequest) => dispatch(getCrewVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Crews);
