import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENT_VERSION_HISTORY_REQUEST, IGetAttachmentVersionHistory, IGetAttachmentVersionHistoryRequest } from "./getAttachmentVersionHistoryConstants";

export interface IGetAttachmentVersionHistoryLoadAction {
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetAttachmentVersionHistoryRequest
}
export const getAttachmentVersionHistoryLoadAction = (data: IGetAttachmentVersionHistoryRequest): IGetAttachmentVersionHistoryLoadAction => ({
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentVersionHistorySuccessAction {
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>;
}
export const getAttachmentVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>): IGetAttachmentVersionHistorySuccessAction => ({
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentVersionHistoryLoadFailedAction {
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getAttachmentVersionHistoryLoadFailedAction = (message: string): IGetAttachmentVersionHistoryLoadFailedAction => ({
    type: IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.FAILED,
    message
});
