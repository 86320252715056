export enum IDELETE_ATTACHMENT_LIST_GET_REQUEST {
    REQUEST = "deleteAttachmentListGET/DELETE_ATTACHMENT_LIST_GET_REQUEST",
    SUCCESS = "deleteAttachmentListGET/DELETE_ATTACHMENT_LIST_GET_SUCCESS",
    FAILED = "deleteAttachmentListGET/DELETE_ATTACHMENT_LIST_GET_FAILED"
};

export interface IDeleteAttachmentListGETRequest {
    token: string;
    request: {
        id: number;
    };
};