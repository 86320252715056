import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_JOB_REQUEST } from "./updateJobConstants";
import { IUpdateJobLoadAction, IUpdateJobLoadFailedAction, IUpdateJobSuccessAction, updateJobLoadFailedAction, updateJobLoadSuccessAction } from "./updateJobActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateJobEpic: Epic = (
    action$: ActionsObservable<IUpdateJobLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateJobSuccessAction | IUpdateJobLoadFailedAction> =>
    action$.ofType(IUPDATE_JOB_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.ACCOUNTING.JOBS.UPDATE_JOB,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateJobSuccessAction | IUpdateJobLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateJobLoadSuccessAction(response);
                            }
                            return updateJobLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateJobLoadFailedAction(response.message)))
                    )
            )
        );