import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_SURVEY_LOOKUP_REQUEST, ISurveyLookup } from "./getSurveyLookupConstants";
import { IGetSurveyLookupLoadAction, IGetSurveyLookupLoadFailedAction, IGetSurveyLookupSuccessAction } from "./getSurveyLookupActions";

type Actions =
    | IGetSurveyLookupLoadAction
    | IGetSurveyLookupSuccessAction
    | IGetSurveyLookupLoadFailedAction
    | IFlushDataSuccessAction;

export const GetSurveyLookupReducer = (state: Server<SurewayAPIResponse<ISurveyLookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ISurveyLookup>> => {
    switch (action.type) {
        case IGET_SURVEY_LOOKUP_REQUEST.REQUEST:
            return loading;

        case IGET_SURVEY_LOOKUP_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SURVEY_LOOKUP_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};