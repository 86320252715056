import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANICS_REQUEST, IMechanic, IGetMechanicsRequest } from "./getMechanicsConstants";

export interface IGetMechanicsLoadAction {
    type: IGET_MECHANICS_REQUEST.REQUEST;
    data: IGetMechanicsRequest
}
export const getMechanicsLoadAction = (data: IGetMechanicsRequest): IGetMechanicsLoadAction => ({
    type: IGET_MECHANICS_REQUEST.REQUEST,
    data
});
export interface IGetMechanicsSuccessAction {
    type: IGET_MECHANICS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMechanic>>;
}
export const getMechanicsLoadSuccessAction = (list: SurewayAPIResponse<ById<IMechanic>>): IGetMechanicsSuccessAction => ({
    type: IGET_MECHANICS_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicsLoadFailedAction {
    type: IGET_MECHANICS_REQUEST.FAILED;
    message: string;
}
export const getMechanicsLoadFailedAction = (message: string): IGetMechanicsLoadFailedAction => ({
    type: IGET_MECHANICS_REQUEST.FAILED,
    message
});
