import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINE_BY_ID_REQUEST {
    REQUEST = "getRepairLineById/GET_REPAIR_LINE_BY_ID_REQUEST",
    SUCCESS = "getRepairLineById/GET_REPAIR_LINE_BY_ID_SUCCESS",
    FAILED = "getRepairLineById/GET_REPAIR_LINE_BY_ID_FAILED"
};

export interface IGetRepairLineByIdRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};