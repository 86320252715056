import React, { useState } from "react";
import { ROUTE } from "../routes";
import styled from "styled-components";
import { Menu } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { getTokenFromUrl } from "../shared/constExports";
import RoleBasedAccessForShop from "../shared/roleBaseAccessForShop";
import { ReadAndWrite } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";

const ShopSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface IShopSubHeaderProps extends RouteComponentProps {

};

export const ShopSubHeader: React.FC<IShopSubHeaderProps> = React.memo((props: IShopSubHeaderProps) => {

    const path = props.location.pathname;

    const onClick = (value: string): void => {
        if (value === "list")
            props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
                search: getTokenFromUrl(false)
            });
        if (value === "type")
            props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT_TYPE.INDEX,
                search: getTokenFromUrl(false)
            });
        if (value === "make")
            props.history.push({
                pathname: ROUTE.FIELD.MANUFACTURER.INDEX,
                search: getTokenFromUrl(false)
            });
        if (value === "config")
            props.history.push({
                pathname: ROUTE.FIELD.CONFIG.INDEX,
                search: getTokenFromUrl(false)
            });
            if (value === "cert_overdue")
            window.open('https://reportserver.propsense.com/reports/report/Reports/Field/ET_Certs_Overdue', '_blank');
        if (value === "equipment_manual")
            props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX,
                search: getTokenFromUrl(false)
            });
    };

    const [admin, setAdmin] = useState<any>(null);
    const [report, setReport] = useState<any>(null);
    const handleAdmin = (event?: any): void => admin !== null ? setAdmin(null) : setAdmin(event.currentTarget);
    const handleReports = (event?: any): void => report !== null ? setReport(null) : setReport(event.currentTarget);

    return (
        <ShopSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={() => onClick("list")} className={(path.includes(ROUTE.FIELD.EQUIPMENT.INDEX)) ? "current" : ""}>
                            Equipment List
                        </LAMenuItem>

                        <LAMenuItem onClick={handleReports}>
                            Reports
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick("equipment_manual")} className={(path.includes(ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX)) ? "current" : ""}>
                            Equipment Manuals
                        </LAMenuItem>

                        <RoleBasedAccessForShop error={false} roleFor={[ReadAndWrite]}>
                            <LAMenuItem onClick={handleAdmin} className={isAdminURL(path) ? "current" : ""}>
                                Admin
                            </LAMenuItem>
                        </RoleBasedAccessForShop>
                    </div>
                </p>
            </div>

            <Menu
                anchorEl={admin}
                open={admin}
                onClose={handleAdmin}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <LAMenuItem onClick={() => onClick("type")}>
                    Type
                </LAMenuItem>

                <LAMenuItem onClick={() => onClick("make")} >
                    Make
                </LAMenuItem>

                <LAMenuItem onClick={() => onClick("config")}>
                    Config
                </LAMenuItem>
            </Menu>


            <Menu
                anchorEl={report}
                open={report}
                onClose={handleReports}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <LAMenuItem onClick={() => onClick("cert_overdue")}>
                    Certs Overdue
                </LAMenuItem>                
            </Menu>
        </ShopSubHeaderStyles>
    );
});

const isAdminURL = (path: string): boolean => {
    if (path.includes(ROUTE.FIELD.CONFIG.INDEX)
        || (path.includes(ROUTE.FIELD.MANUFACTURER.INDEX))
        || path.includes(ROUTE.FIELD.EQUIPMENT_TYPE.INDEX)) {
        return true;
    }

    return false;
};