
export enum IGET_FILTER_REQUEST {
    REQUEST = "getFilter/GET_FILTER_REQUEST",
    SUCCESS = "getFilter/GET_FILTER_SUCCESS",
    FAILED = "getFilter/GET_FILTER_FAILED"
};

export interface IFilterRequest {
    token: string;
};

export interface IFilter {
    id: number;
    make: string;
    make_ID: number;
    models: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    parts: IFilterPart[];
};

export interface IFilterPart {
    id: number;
    filter_ID: number;
    unit_No: string;
    unit_ID: number;
    type: string;
    sub_Type: string;
    part_No: string;
    quantity: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};