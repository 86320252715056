import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_KANTECH_ADJUSTMENTS_REQUEST {
    REQUEST = "getKantechAdjustments/GET_KANTECH_ADJUSTMENTS_REQUEST",
    SUCCESS = "getKantechAdjustments/GET_KANTECH_ADJUSTMENTS_SUCCESS",
    FAILED = "getKantechAdjustments/GET_KANTECH_ADJUSTMENTS_FAILED"
};

export interface IGetKantechAdjustmentRequest extends ISurewayTokenRequestBody {

};

export interface IKantechAdjustment {
    id: number;
    userID?: number;
    punchTime: string;
    in_Out?: string;
    notes: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    userName: string;
};