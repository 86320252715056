import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_JOBS_REQUEST, IJob, IJobsRequest } from "./getJobsConstants";


export interface IGetJobsLoadAction {
    type: IGET_JOBS_REQUEST.REQUEST;
    data: IJobsRequest
}
export const getJobsLoadAction = (data: IJobsRequest): IGetJobsLoadAction => ({
    type: IGET_JOBS_REQUEST.REQUEST,
    data
});
export interface IGetJobsSuccessAction {
    type: IGET_JOBS_REQUEST.SUCCESS;
    data: SurewayAPIResponse<ById<IJob>>;
}
export const getJobsLoadSuccessAction = (data: SurewayAPIResponse<ById<IJob>>): IGetJobsSuccessAction => ({
    type: IGET_JOBS_REQUEST.SUCCESS,
    data
});
export interface IGetJobsLoadFailedAction {
    type: IGET_JOBS_REQUEST.FAILED;
    message: string;
}
export const getJobsLoadFailedAction = (message: string): IGetJobsLoadFailedAction => ({
    type: IGET_JOBS_REQUEST.FAILED,
    message
});
