import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

interface ILADropZoneProps {
    onFileDrop: (event: Array<File | DataTransferItem>) => void;
};

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return "#00e676";
    }
    if (props.isDragReject) {
        return "#ff1744";
    }
    if (props.isDragActive) {
        return "#2196f3";
    }
    return "#eeeeee";
};

const LADropZoneStyles = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
`;

export const LADropZone: React.FC<ILADropZoneProps> = React.memo((props: ILADropZoneProps) => {
    const onDrop = useCallback((acceptedFiles) => {
        props.onFileDrop(acceptedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject } = useDropzone({ onDrop });

    return (
        <div className="container">
            <LADropZoneStyles {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                <input {...getInputProps()} multiple={true} />
                <span>Drag "n" drop some files here, or click to select files</span>
            </LADropZoneStyles>
        </div>
    )
});
