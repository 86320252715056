import React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { getTokenFromUrl } from "../shared/constExports";

const LocationSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface ILocationSubHeaderProps extends RouteComponentProps {

};

export const LocationSubHeader: React.FC<ILocationSubHeaderProps> = React.memo((props: ILocationSubHeaderProps) => {

    const path = props.location.pathname;

    const onClick = (value: string): void => {
        if (value === "location")
            props.history.push({
                pathname: ROUTE.FIELD.LOCATION.INDEX,
                search: getTokenFromUrl(false)
            });
        if (value === "sub-location")
            props.history.push({
                pathname: ROUTE.FIELD.SOCT.WORKING_SITES.INDEX,
                search: getTokenFromUrl(false)
            });
    };

    return (
        <LocationSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={() => onClick("location")} className={(path.includes(ROUTE.FIELD.LOCATION.INDEX)) ? "current" : ""}>
                            Locations
                        </LAMenuItem>

                        {/* <LAMenuItem onClick={() => onClick("sub-location")} className={(path.includes(ROUTE.FIELD.SOCT.WORKING_SITES.INDEX)) ? "current" : ""}>
                            Working Sites
                        </LAMenuItem> */}
                    </div>
                </p>
            </div>
        </LocationSubHeaderStyles>
    );
});