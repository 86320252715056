import { IAddEquipmentManual } from "../addEquipmentManual/addEquipmentManualConstants";

export enum IUPDATE_EQUIPMENT_MANUAL_REQUEST {
    REQUEST = "updateEquipmentManual/UPDATE_EQUIPMENT_MANUAL_REQUEST",
    SUCCESS = "updateEquipmentManual/UPDATE_EQUIPMENT_MANUAL_SUCCESS",
    FAILED = "updateEquipmentManual/UPDATE_EQUIPMENT_MANUAL_FAILED"
};

export interface IUpdateEquipmentManualRequest {
    token: string;
    request: IAddEquipmentManual;
};