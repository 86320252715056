import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_KANTECH_ADJUSTMENTS_REQUEST, IKantechAdjustment } from "./getKantechAdjustmentsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetKantechAdjustmentsLoadAction, IGetKantechAdjustmentsLoadFailedAction, IGetKantechAdjustmentsSuccessAction, getKantechAdjustmentsLoadFailedAction, getKantechAdjustmentsLoadSuccessAction } from "./getKantechAdjustmentsActions";
import { withJsonContentType } from "../../../epicUtils";

export const getKantechAdjustmentsEpic: Epic = (
    action$: ActionsObservable<IGetKantechAdjustmentsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetKantechAdjustmentsSuccessAction | IGetKantechAdjustmentsLoadFailedAction> =>
    action$.ofType(IGET_KANTECH_ADJUSTMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IKantechAdjustment>>>(
                    END_POINTS.SIL.KANTECH.GET_KANTECH_ADJUSTMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IKantechAdjustment>>): IGetKantechAdjustmentsSuccessAction => {
                            return getKantechAdjustmentsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getKantechAdjustmentsLoadFailedAction(response.message)))
                    )
            )
        );