
export enum IADD_ATTACHMENT_MOVE_REQUEST {
    REQUEST = "addAttachmentMove/ADD_ATTACHMENT_MOVE_REQUEST",
    SUCCESS = "addAttachmentMove/ADD_ATTACHMENT_MOVE_SUCCESS",
    FAILED = "addAttachmentMove/ADD_ATTACHMENT_MOVE_FAILED"
};

export interface IAddAttachmentMoveRequest {
    token: string;
    request: IAddUpdateAttachmentMove;
};

export interface IAddUpdateAttachmentMove {
    ID?: number; //only for update
    Custom?: string;
    Requested_By?: string;
    Type_ID: number;
    Coupler_Design_ID: number;
    Style_ID: number;
    Attachment_ID: number;
    Complete_By: string;
    From_Sub_Location_ID?: number;
    To_Sub_Location_ID: number;
    Attached_Unit_ID: number;
    Status: string;
    Previously_Moved: string;
    Move_Type : string;
    Comments : string;
    Created_By: string;
    Modified_By: string;
};