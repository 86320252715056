import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FORM_VERSION_HISTORY_REQUEST, IGetFormVersionHistory } from "./getFormVersionHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetFormVersionHistoryLoadAction, IGetFormVersionHistoryLoadFailedAction, IGetFormVersionHistorySuccessAction, 
    getFormVersionHistoryLoadFailedAction, getFormVersionHistoryLoadSuccessAction } from "./getFormVersionHistoryActions";

export const getFormVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetFormVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormVersionHistorySuccessAction | IGetFormVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_FORM_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetFormVersionHistory>>>(
                    END_POINTS.FIELD.FORMS.GET_FORM_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetFormVersionHistory>>): IGetFormVersionHistorySuccessAction => {
                            return getFormVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );