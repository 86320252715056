import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_LEVEL_REQUEST, ILevels, ILevelsRequest } from "./getLevelsConstants";

export interface IGetLevelsLoadAction {
    type: IGET_LEVEL_REQUEST.REQUEST;
    data: ILevelsRequest
}
export const getLevelsLoadAction = (data: ILevelsRequest): IGetLevelsLoadAction => ({
    type: IGET_LEVEL_REQUEST.REQUEST,
    data
});
export interface IGetLevelsSuccessAction {
    type: IGET_LEVEL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ILevels>>;
}
export const getLevelsLoadSuccessAction = (list: SurewayAPIResponse<ById<ILevels>>): IGetLevelsSuccessAction => ({
    type: IGET_LEVEL_REQUEST.SUCCESS,
    list
});
export interface IGetLevelsLoadFailedAction {
    type: IGET_LEVEL_REQUEST.FAILED;
    message: string;
}
export const getLevelsLoadFailedAction = (message: string): IGetLevelsLoadFailedAction => ({
    type: IGET_LEVEL_REQUEST.FAILED,
    message
});
