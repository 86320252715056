import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_DRIVER_COMMUNICATION_LOGS_REQUEST, IGetDriverCommunicationLogs, IGetDriverCommunicationLogsRequest } from "./getDriverCommunicationLogsConstants";

export interface IGetDriverCommunicationLogsLoadAction {
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.REQUEST;
    data: IGetDriverCommunicationLogsRequest
}
export const getDriverCommunicationLogsLoadAction = (data: IGetDriverCommunicationLogsRequest): IGetDriverCommunicationLogsLoadAction => ({
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetDriverCommunicationLogsSuccessAction {
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>;
}
export const getDriverCommunicationLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>): IGetDriverCommunicationLogsSuccessAction => ({
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetDriverCommunicationLogsLoadFailedAction {
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.FAILED;
    message: string;
}
export const getDriverCommunicationLogsLoadFailedAction = (message: string): IGetDriverCommunicationLogsLoadFailedAction => ({
    type: IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.FAILED,
    message
});
