import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_VENDOR_ORDER_REQUEST, IVendorOrder, IGetVendorOrderRequest } from "./getVendorOrdersConstants";

export interface IGetVendorOrderLoadAction {
    type: IGET_VENDOR_ORDER_REQUEST.REQUEST;
    data: IGetVendorOrderRequest
}
export const getVendorOrderLoadAction = (data: IGetVendorOrderRequest): IGetVendorOrderLoadAction => ({
    type: IGET_VENDOR_ORDER_REQUEST.REQUEST,
    data
});
export interface IGetVendorOrderSuccessAction {
    type: IGET_VENDOR_ORDER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IVendorOrder>>;
}
export const getVendorOrderLoadSuccessAction = (list: SurewayAPIResponse<ById<IVendorOrder>>): IGetVendorOrderSuccessAction => ({
    type: IGET_VENDOR_ORDER_REQUEST.SUCCESS,
    list
});
export interface IGetVendorOrderLoadFailedAction {
    type: IGET_VENDOR_ORDER_REQUEST.FAILED;
    message: string;
}
export const getVendorOrderLoadFailedAction = (message: string): IGetVendorOrderLoadFailedAction => ({
    type: IGET_VENDOR_ORDER_REQUEST.FAILED,
    message
});
