import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINE_SUMMARY_REQUEST {
    REQUEST = "getRepairLineSummary/GET_REPAIR_LINE_SUMMARY_REQUEST",
    SUCCESS = "getRepairLineSummary/GET_REPAIR_LINE_SUMMARY_SUCCESS",
    FAILED = "getRepairLineSummary/GET_REPAIR_LINE_SUMMARY_FAILED"
};

export interface IGetRepairLineSummaryRequest extends ISurewayTokenRequestBody {

};

export interface IRepairLineSummary {
    active_Units: IRepairLineSummaryPriority;
    mechanic_Assigned: IRepairLineSummaryMechanicAssigned[];
    priority_Down: IRepairLineSummaryPriority;
    priority_Urgent: IRepairLineSummaryPriority;
};

export interface IRepairLineSummaryMechanicAssigned {
    id: number;
    name: string;
    count: number;
};

export interface IRepairLineSummaryPriority {
    count: number;
    units: IRepairLineSummaryUnit[];
};
export interface IRepairLineSummaryUnit {
    id: number;
    unit_No: string;
    status: string;
    priority: string;
};
