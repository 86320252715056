
export enum IADD_MACHINE_RECEIVER_REQUEST {
    REQUEST = "addMachineReceiver/ADD_MACHINE_RECEIVER_REQUEST",
    SUCCESS = "addMachineReceiver/ADD_MACHINE_RECEIVER_SUCCESS",
    FAILED = "addMachineReceiver/ADD_MACHINE_RECEIVER_FAILED"
};

export interface IAddUpdateMachineReceiverRequest {
    token: string;
    request: IAddUpdateMachineReceiver;
};

export interface IAddUpdateMachineReceiver {
    Id: number;
    Unit_No: string;
    Serial_No: string;
    Make: string;
    Model: string;
    Version: number;
    Status: string;
    Notes: string;
    Created?: string;
    Modified?: string;
    Created_By: string;
    Modified_By: string;
};