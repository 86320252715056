import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PM_SHEET_REPORT_BY_ID_REQUEST, IGetPMSheetReportByIdRequest, IServiceSheetReport } from "./getPMSheetReportByIdConstants";

export interface IGetPMSheetReportByIdLoadAction {
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.REQUEST;
    data: IGetPMSheetReportByIdRequest
}
export const getPMSheetReportByIdLoadAction = (data: IGetPMSheetReportByIdRequest): IGetPMSheetReportByIdLoadAction => ({
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetPMSheetReportByIdSuccessAction {
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IServiceSheetReport>;
}
export const getPMSheetReportByIdLoadSuccessAction = (list: SurewayAPIResponse<IServiceSheetReport>): IGetPMSheetReportByIdSuccessAction => ({
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetPMSheetReportByIdLoadFailedAction {
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getPMSheetReportByIdLoadFailedAction = (message: string): IGetPMSheetReportByIdLoadFailedAction => ({
    type: IGET_PM_SHEET_REPORT_BY_ID_REQUEST.FAILED,
    message
});
