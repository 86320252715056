import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_CREW_BY_USERNAME_REQUEST, IGetCrewByUsername, IGetCrewByUsernameRequest } from "./getCrewByUsernameConstants";

export interface IGetCrewByUsernameLoadAction {
    type: IGET_CREW_BY_USERNAME_REQUEST.REQUEST;
    data: IGetCrewByUsernameRequest
}
export const getCrewByUsernameLoadAction = (data: IGetCrewByUsernameRequest): IGetCrewByUsernameLoadAction => ({
    type: IGET_CREW_BY_USERNAME_REQUEST.REQUEST,
    data
});
export interface IGetCrewByUsernameSuccessAction {
    type: IGET_CREW_BY_USERNAME_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetCrewByUsername>;
}
export const getCrewByUsernameLoadSuccessAction = (list: SurewayAPIResponse<IGetCrewByUsername>): IGetCrewByUsernameSuccessAction => ({
    type: IGET_CREW_BY_USERNAME_REQUEST.SUCCESS,
    list
});
export interface IGetCrewByUsernameLoadFailedAction {
    type: IGET_CREW_BY_USERNAME_REQUEST.FAILED;
    message: string;
}
export const getCrewByUsernameLoadFailedAction = (message: string): IGetCrewByUsernameLoadFailedAction => ({
    type: IGET_CREW_BY_USERNAME_REQUEST.FAILED,
    message
});
