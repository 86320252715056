import React from "react";
import { HasClass } from "./publicInterfaces";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

interface ILADatePickerProps extends HasClass {
    value: any;
    id?: any;
    mode?: "time" | "range" | "multiple" | "single" | undefined;
    onChange: (date: string, date2?: string) => void;
    disable?: true | undefined;
    minDate?: string;
    maxDate?: string;
    dateFormat?: string;
    enableTime?: true;
    time_24hr?: true;
}

const LADatePicker: React.FC<ILADatePickerProps> = (props: ILADatePickerProps) => {
    let newD = undefined;
    if (props.value || (props.value !== null)) {
        if ((props.mode === "range")) {
            newD = props.value;
        } else if (props.mode === "time") {
            const hours = new Date(props.value).getHours();
            if(!isNaN(hours)) {
                newD = new Date(props.value).getHours() +":"+new Date(props.value).getMinutes() ;
            } else {
                newD = props.value;
            }
        } else {
            newD = new Date(props.value);
        }
    }

    let minDate = props.minDate ? new Date(props.minDate) : undefined;
    let maxDate = props.maxDate ? new Date(props.maxDate) : undefined;
    if ((props.mode === "range")) {
        return <Flatpickr
            id={props.id}
            key={props.id}
            value={newD}
            options={{
                dateFormat: props.dateFormat ?? "m/d/Y",
                mode: props.mode,
                minDate: minDate,
                maxDate: maxDate
            }}
            disabled={props.disable}
            onChange={(date: any) => props.mode === "range" ? props.onChange(date[0].toString(), date[1] ? date[1].toString() : undefined) : props.onChange(date[0].toString())}
        />;
    } else if (props.mode === "time") {
        const onChange = (time: any) => {
            const date = new Date(time[0].toString());
            const hours = date.getHours();
            const minutes = date.getMinutes();
            props.onChange((`${hours}:${minutes}`).toString())
        };

        return <Flatpickr
            id={props.id}
            key={props.id}
            value={newD}
            options={{
                enableTime: true,
                noCalendar: true,
                time_24hr: props.time_24hr ?? false,
                dateFormat: 'H:i',
            }}
            disabled={props.disable}
            onChange={onChange}
        />
    } else {
        return <Flatpickr
            id={props.id}
            key={props.id}
            value={newD}
            options={{
                enableTime: props.enableTime,
                time_24hr: false,
                dateFormat: props.dateFormat ?? "m/d/Y",
                minDate: minDate,
                maxDate: maxDate
            }}
            disabled={props.disable}
            onChange={(date: any) => props.mode === "range" ? props.onChange(date[0].toString(), date[1] ? date[1].toString() : undefined) : props.onChange(date[0].toString())}
        />
    }

};

export default LADatePicker;
