import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_METER_READING_REQUEST, IAddMeterReadingRequest } from "./addMeterReadingConstants";


export interface IAddMeterReadingLoadAction {
    type: IADD_METER_READING_REQUEST.REQUEST;
    data: IAddMeterReadingRequest
}
export const addMeterReadingLoadAction = (data: IAddMeterReadingRequest): IAddMeterReadingLoadAction => ({
    type: IADD_METER_READING_REQUEST.REQUEST,
    data
});
export interface IAddMeterReadingSuccessAction {
    type: IADD_METER_READING_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addMeterReadingLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddMeterReadingSuccessAction => ({
    type: IADD_METER_READING_REQUEST.SUCCESS,
    message
});
export interface IAddMeterReadingLoadFailedAction {
    type: IADD_METER_READING_REQUEST.FAILED;
    message: string;
}
export const addMeterReadingLoadFailedAction = (message: string): IAddMeterReadingLoadFailedAction => ({
    type: IADD_METER_READING_REQUEST.FAILED,
    message
});
