import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../../shared/errorBox";
import { FormsMenu, Navbar } from "../../../shared/navbars";
import { IGetTasks, IGetTasksRequest } from "../../../../redux/field/getTasks/getTasksConstants";
import { getTasksLoadAction } from "../../../../redux/field/getTasks/getTasksActions";
import { getTasks } from "../../../../redux/field/getTasks/getTasksAccessor";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import { FormVersionHistoryPopup } from "../formVersionHistoryPopup";
import { getFormVersionHistory } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";

interface ITasksStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getTasks: Server<SurewayAPIResponse<ById<IGetTasks>>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
};

interface ITasksDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getTasksRequest: (data: IGetTasksRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
};


interface ITasksOwnProps {

};

interface ITasksState {
    activeView: boolean;
    formVersionHistoryPopup: boolean;
};

const TasksStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ITasksProps = RouteComponentProps
    & ITasksStoreProps
    & ITasksDispatchProps
    & ITasksOwnProps;

class Tasks extends PureComponent<ITasksProps, ITasksState> {

    public constructor(props: ITasksProps) {
        super(props);
        this.state = {
            activeView: true,
            formVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: ITasksProps): void {
        if (this.props !== prevProps)
            this.callServer();    
    };


    public render(): ReactNode {

        const { activeView, formVersionHistoryPopup } = this.state;
        const { getTasks, getToken, getFormVersionHistory } = this.props;
        const getRole = pageAccessCheck(getToken, "formsAdmin");
        const getCleanUpRole = pageAccessCheck(getToken, "formsCleanupAccess");
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];
        const data = hasPayload(getTasks) ? activeView ? Object.values(getTasks.payload.response).filter(x => x.active === "Yes") : 
                        Object.values(getTasks.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Tasks" description="Forms - Tasks List" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={FormsMenu} role={getRole === true ? true : getCleanUpRole === true ? "formsCleanupAccess": getRole}/>
                {(getRole !== NotApplicable) && 
                    <TasksStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">TASKS</h2>
                                    <hr />

                                    {getTasks.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Tasks..." />}
                                    {getTasks.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Tasks..." />}

                                    {getTasks.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                                                    <FormControlLabel value="all" control={<Radio />} label="All" />
                                                </RadioGroup>
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={240}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="TasksList"
                                                    customRowColor={true}
                                                    storageKey="TasksSessionKey"
                                                    exportFileName="Tasks"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "name", caption: "Task", type: "string" },
                                                        { name: "department_Names", caption: "Departments", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "version_History", caption: "Version History", type: "button"},
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                            {formVersionHistoryPopup && <FormVersionHistoryPopup
                                page="FormAdminTaskVersionHistory"
                                data={formVersionHistoryPopupData}
                                display={`Version History` }
                                open={formVersionHistoryPopup}
                                status={getFormVersionHistory.kind}
                                onCancel={this.handleFormVersionHistoryPopupClose}
                            />}
                        </LAPaperWithPadding>
                    </TasksStyles>
                }
            </PageSpacing>
        
        );
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.ADMIN.TASKS.ADD_UPDATE_TASK
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.ADMIN.TASKS.ADD_UPDATE_TASK
        });
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IGetTasks = e.row.data;
        if (name === "version_History") {
            this.handleFormVersionHistory(data)
        }
    }

    private handleFormVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: data.id,
                    page: "Tasks"
                }
            });
        }
        this.setState({ formVersionHistoryPopup: true });
    }

    private handleFormVersionHistoryPopupClose = (): void => {
        this.setState({ formVersionHistoryPopup: false });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "formsAdmin") !== NotApplicable) {
                if (isNotLoaded(this.props.getTasks)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getTasksRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ITasksStoreProps => ({
    getToken: getToken(state),
    getTasks: getTasks(state),
    getFormVersionHistory: getFormVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ITasksDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getTasksRequest: (data: IGetTasksRequest) => dispatch(getTasksLoadAction(data)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
