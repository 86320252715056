import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_RESPONSES_REQUEST, IFormResponse } from "./getFormResponsesConstants";
import { IGetFormResponsesLoadAction, IGetFormResponsesLoadFailedAction, IGetFormResponsesSuccessAction } from "./getFormResponsesActions";
import { IAddFormResponseSuccessAction } from "../addFormResponse/addFormResponseActions";
import { IUpdateFormResponseSuccessAction } from "../updateFormResponse/updateFormResponseActions";
import { IADD_FORM_RESPONSE_REQUEST } from "../addFormResponse/addFormResponseConstants";
import { IUPDATE_FORM_RESPONSE_REQUEST } from "../updateFormResponse/updateFormResponseConstants";

type Actions =
    | IGetFormResponsesLoadAction
    | IGetFormResponsesSuccessAction
    | IGetFormResponsesLoadFailedAction
    | IAddFormResponseSuccessAction
    | IUpdateFormResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetFormResponsesReducer = (state: Server<SurewayAPIResponse<IFormResponse[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IFormResponse[]>> => {
    switch (action.type) {
        case IGET_FORM_RESPONSES_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_RESPONSES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_RESPONSES_REQUEST.FAILED:
            return failed(action.message);

        case IADD_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};