import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_FIELDEMPLOYEE_REQUEST } from "./updateFieldEmployeeConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IUpdateFieldEmployeeLoadAction, IUpdateFieldEmployeeLoadFailedAction, IUpdateFieldEmployeeSuccessAction, 
    updateFieldEmployeeLoadFailedAction, updateFieldEmployeeLoadSuccessAction } from "./updateFieldEmployeeActions";

export const updateFieldEmployeeEpic: Epic = (
    action$: ActionsObservable<IUpdateFieldEmployeeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateFieldEmployeeSuccessAction | IUpdateFieldEmployeeLoadFailedAction> =>
    action$.ofType(IUPDATE_FIELDEMPLOYEE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.UPDATE_FIELD_EMPLOYEE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateFieldEmployeeSuccessAction | IUpdateFieldEmployeeLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateFieldEmployeeLoadSuccessAction(response);
                            } else {
                                return updateFieldEmployeeLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateFieldEmployeeLoadFailedAction(response.message)))
                    )
            )
        );