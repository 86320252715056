import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINES_WO_REQUEST, IRepairLineWO, IGetRepairLineWORequest } from "./getRepairLineWOConstants";

export interface IGetRepairLineWOLoadAction {
    type: IGET_REPAIR_LINES_WO_REQUEST.REQUEST;
    data: IGetRepairLineWORequest
}
export const getRepairLineWOLoadAction = (data: IGetRepairLineWORequest): IGetRepairLineWOLoadAction => ({
    type: IGET_REPAIR_LINES_WO_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineWOSuccessAction {
    type: IGET_REPAIR_LINES_WO_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLineWO[]>;
}
export const getRepairLineWOLoadSuccessAction = (list: SurewayAPIResponse<IRepairLineWO[]>): IGetRepairLineWOSuccessAction => ({
    type: IGET_REPAIR_LINES_WO_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineWOLoadFailedAction {
    type: IGET_REPAIR_LINES_WO_REQUEST.FAILED;
    message: string;
}
export const getRepairLineWOLoadFailedAction = (message: string): IGetRepairLineWOLoadFailedAction => ({
    type: IGET_REPAIR_LINES_WO_REQUEST.FAILED,
    message
});
