import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { BLACK_COLOR, DEFAULT_GREY, EXTRA_LIGHT_BLUE_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, RED_COLOR, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString, deleteText, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { AddIcon, ArrowLeftIcon, DeleteIcon, DownloadIcon } from "../../../shared/icons";
import LATextArea from "../../../shared/textArea";
import splitDate from "../../../shared/splitDate";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { LACheckBox } from "../../../shared/checkBox";
import { FormHelperText } from "@material-ui/core";
import { ISOCTLookup, ISOCTLookupRequest } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsConstants";
import { getSOCTLookups } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsAccessor";
import { getSOCTLookupsLoadAction } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsActions";
import PageSpacing from "../../../shared/pageSpacing";
import { LADropZone } from "../../../shared/dropZone";
import { IShopEquipmentFile } from "../../../../redux/field/getEquipments/getEquipmentConstants";
import { IGetScheduleServiceHistoryByIdRequest, IScheduleServiceHistory } from "../../../../redux/field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdConstants";
import { getScheduleServiceHistoryById } from "../../../../redux/field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdAccessor";
import { getScheduleServiceHistoryByIdLoadAction } from "../../../../redux/field/soct/getScheduleServiceHistoryById/getScheduleServiceHistoryByIdActions";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { END_POINTS } from "../../../../redux/endpoints";
import { IAddScheduleServiceHistoryRequest, IAddUpdateScheduleServiceHistory } from "../../../../redux/field/soct/addScheduleServiceHistory/addScheduleServiceHistoryConstants";
import { addScheduleServiceHistoryStatus } from "../../../../redux/field/soct/addScheduleServiceHistory/addScheduleServiceHistoryAccessor";
import { addScheduleServiceHistoryLoadAction } from "../../../../redux/field/soct/addScheduleServiceHistory/addScheduleServiceHistoryActions";
import { IUpdateScheduleServiceHistoryRequest } from "../../../../redux/field/soct/updateScheduleServiceHistory/updateScheduleServiceHistoryConstants";
import { updateScheduleServiceHistoryStatus } from "../../../../redux/field/soct/updateScheduleServiceHistory/updateScheduleServiceHistoryAccessor";
import { updateScheduleServiceHistoryLoadAction } from "../../../../redux/field/soct/updateScheduleServiceHistory/updateScheduleServiceHistoryActions";
import { LAMultiSelectDropdown } from "../../../shared/multiSelectDropdown";
import { IGetSOCTCustomServiceItemsBySOCTIDRequest, ISOCTCustomServiceItemsBySOCTID } from "../../../../redux/field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDConstants";
import { getSOCTCustomServiceItemsBySOCTID } from "../../../../redux/field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDAccessor";
import { getSOCTCustomServiceItemsBySOCTIDLoadAction } from "../../../../redux/field/soct/getSOCTCustomServiceItemsBySOCTID/getSOCTCustomServiceItemsBySOCTIDActions";

const RequiredFields: string[] = ["unit_Number", "service_Date", "meter_Reading"];

interface IAddUpdateServiceHistoryComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISOCTLookup>>;
    getScheduleServiceHistoryByID: Server<SurewayAPIResponse<IScheduleServiceHistory>>;
    addScheduleServiceHistoryStatus: Server<SurewayAPIResponse<string>>;
    updateScheduleServiceHistoryStatus: Server<SurewayAPIResponse<string>>;
    getSOCTCustomServiceItemsBySOCTID: Server<SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>>;
};

interface IAddUpdateServiceHistoryComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISOCTLookupRequest) => unknown;
    getScheduleServiceHistoryByIDRequest: (request: IGetScheduleServiceHistoryByIdRequest) => unknown;
    addScheduleServiceHistoryRequest: (data: IAddScheduleServiceHistoryRequest) => unknown;
    updateScheduleServiceHistoryRequest: (data: IUpdateScheduleServiceHistoryRequest) => unknown;
    getSOCTCustomServiceItemsBySOCTIDRequest: (data: IGetSOCTCustomServiceItemsBySOCTIDRequest) => unknown;
};

interface IAddUpdateServiceHistoryOwnProps {
    id?: string;
};

interface IAddUpdateServiceHistoryComponentState {
    data: IScheduleServiceHistory;
    custom_Services: any[];
    attachments: IShopEquipmentFile[];
    service_Hours: string[];
    serviceTypesArray: string[]
    disableByPM: true | undefined;
    disableForm: true | undefined;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateServiceHistoryStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    // .header-row {
    //     background-color: ${DEFAULT_GREY};
    // };

    .custom-text {
        color: ${RED_COLOR}
    }

    .even-row {
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
        border: 1px solid ${BLACK_COLOR};
        border-bottom: 2.3px solid ${BLACK_COLOR};
        border-top: 2.3px solid ${BLACK_COLOR};
    }
    
    .odd-row {
        background-color: ${WHITE_COLOR};
        border: 1px solid ${BLACK_COLOR};
        border-bottom: 2.3px solid ${BLACK_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateServiceHistoryComponentProps =
    RouteComponentProps
    & IAddUpdateServiceHistoryOwnProps
    & IAddUpdateServiceHistoryComponentStoreProps
    & IAddUpdateServiceHistoryComponentDispatchProps;

class AddUpdateServiceHistory extends PureComponent<IAddUpdateServiceHistoryComponentProps, IAddUpdateServiceHistoryComponentState> {

    public constructor(props: IAddUpdateServiceHistoryComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                unit_ID: 0,
                socT_ID: 0,
                service_Date: "",
                meter_Reading: null,
                ecm: null,
                // service_Sub_Location_ID: 0,
                cancelled: "No",
                // update_Current_Hours: "No",
                service_Type: "",
                custom_Service: "No",
                service_Items: "",
                comments: "",
                attachments: "",
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            custom_Services: [],
            attachments: [],
            disableByPM: undefined,
            disableForm: undefined,
            service_Hours: [],
            serviceTypesArray: [],
            errors: {},
            serverError: "",
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.getData();
        this.getSOCTCustomServiceItemsBySOCTIDData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateServiceHistoryComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addScheduleServiceHistoryStatus !== prevProps.addScheduleServiceHistoryStatus) {

                if (this.props.addScheduleServiceHistoryStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addScheduleServiceHistoryStatus.message });

                if (hasPayload(this.props.addScheduleServiceHistoryStatus) && this.props.addScheduleServiceHistoryStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateScheduleServiceHistoryStatus !== prevProps.updateScheduleServiceHistoryStatus) {

                if (this.props.updateScheduleServiceHistoryStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateScheduleServiceHistoryStatus.message });

                if (hasPayload(this.props.updateScheduleServiceHistoryStatus) && this.props.updateScheduleServiceHistoryStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, attachments, service_Hours, disableByPM , disableForm, custom_Services} = this.state;
        const { getToken,  getLookup, addScheduleServiceHistoryStatus, updateScheduleServiceHistoryStatus, getSOCTCustomServiceItemsBySOCTID} = this.props;
        const getRole = pageAccessCheck(getToken, "soctAccess");
        // console.log(this.state)

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        // console.log(unit)
        // const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        // console.log(wssList)
        // const onServiceLocation = (value: string): void => this.handleNumber("service_Sub_Location_ID", value.toString());
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const onCancelledServiceCheckbox = (checked: boolean): void => this.handleChange("cancelled", checked ? "Yes" : "No");
        const disableSave = (Object.values(errors).length > 0) ? true : 
                            (custom_Services.length > 0 && custom_Services.some((obj: any) => (((obj.hour_Interval === 0 || obj.hour_Interval === "") && (obj.service_Date == null || obj.service_Date === "")))  || (obj.notes == null || obj.notes === ""))) ? true : 
                            undefined;
        // const onUpdateCurrentHoursCheckbox = (checked: boolean): void => this.handleChange("update_Current_Hours", checked ? "Yes" : "No");
        // console.log(data)
        const serviceItemsOptions = hasPayload(getSOCTCustomServiceItemsBySOCTID) ? getSOCTCustomServiceItemsBySOCTID.payload.response ?? [] : [];

        return (
            <PageSpacing title="SOCT - Add/Update Service History" description="SOCT - ADD/Update Service History" fixedSpaceOnSmallerScreens={true}>
                <AddUpdateServiceHistoryStyles>
                    {(getRole === true) && 
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? `VIEW/UPDATE ` : "ADD "}Service <span className="custom-text">{data.custom_Service === "Yes" ? ` - Custom` : ''}</span></h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Unit#</strong>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder=""
                                    disabled={true}
                                    displayExp="unit_Number"
                                    name="unit_Number"
                                    errorName="unit_Number"
                                    errors={errors}
                                    currentVal={unit?.unit_Number}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "make", caption: "Make", type: "string" },
                                        { name: "model", caption: "Model", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                        { name: "reading_Type", caption: "Reading Type", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Location Details</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="location"
                                    onChange={undefinedFunction}
                                    value={unit ? unit.location_Details : ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Reading Type</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="reading_Type"
                                    onChange={undefinedFunction}
                                    value={(unit && unit.reading_Type) ?? ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Previous Date</strong>
                                <LATextField
                                    id="previous_Date"
                                    value={(unit && unit.service_Date && splitDate(unit.service_Date)) ?? ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="previous_Date"
                                    label=""
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Previous Reading</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="previous_Reading"
                                    type="number"
                                    disabled={true}
                                    value={(unit && unit.service_Reading) ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>
                            
                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Service Date</strong>
                                <LATextField
                                    id="service_Date"
                                    value={data.service_Date ? splitDate(data.service_Date) : ''}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disableByPM || disableForm}
                                    name="service_Date"
                                    label=""
                                    errorText={errors["service_Date"] ? errors["service_Date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Meter Reading</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="meter_Reading"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={(data.id > 0 && data.custom_Service !== "Yes") || disableByPM || disableForm}
                                    value={data.meter_Reading ?? ""}
                                    onChange={this.handleNumber}
                                    errorText={errors["meter_Reading"] ? errors["meter_Reading"].message : undefined}
                                />
                            </LAGridItem>

                            {unit?.reading_Type === "ECM" && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>ECM</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="ecm"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={disableByPM || disableForm}
                                    value={data.ecm ?? ""}
                                    onChange={this.handleNumber}
                                />
                            </LAGridItem>}

                            {/* <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Service Location</strong>
                                <DevExtremePopupLookup
                                    data={wssList}
                                    id="wss-search"
                                    placeHolder=""
                                    disabled={disableByPM || disableForm}
                                    displayExp="site"
                                    returnExp="wsS_ID"
                                    name="wsS_ID"
                                    errorName="wsS_ID"
                                    errors={errors}
                                    currentVal={data.service_Sub_Location_ID}
                                    onClick={onServiceLocation}
                                    columns={[
                                        { name: "site", caption: "Site", type: "string" },
                                        { name: "job", caption: "Job", type: "string" },
                                        { name: "site_Address", caption: "Site Address", type: "string" },
                                        { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                    ]}
                                />
                            </LAGridItem> */}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Service Type</strong>
                                {service_Hours.length > 0 &&  service_Hours.map((x, index) => {
                                    const label = x === "0" ? "0 (non pm service)" : x;
                                    const onServiceTypeCheck = (checked: boolean) => this.handleServiceType("service_Type", checked, x, index);
                                    return (
                                        <LAGridItem xs={12} key={index}>
                                            <LACheckBox
                                                label={label}
                                                key={index}
                                                // disabled={disableByPM || disableForm || (x !== "0" && (this.state.serviceTypesArray.includes("0") || data.custom_Service === "Yes") ? true : undefined) || (x === "0" && data.id > 0 && data.custom_Service === "Yes") ? true : undefined}
                                                disabled={
                                                    disableByPM || disableForm || 
                                                    (x !== "0" && (this.state.serviceTypesArray.includes("0") ) ? true : undefined) || 
                                                    ((x === "0" && data.id > 0 ) ? true : undefined) ||
                                                    (x !== "custom" && (this.state.serviceTypesArray.includes("custom") || data.custom_Service === "Yes") ? true : undefined) || 
                                                    (x === "custom" && data.id > 0 && data.custom_Service === "Yes") ? true: undefined
                                                }
                                                onChange={onServiceTypeCheck}
                                                value={this.state.serviceTypesArray.includes(x) ? true : x === "custom" && data.custom_Service==="Yes" ? true :false}
                                            />
                                        </LAGridItem>
                                    )
                                })}
                            </LAGridItem>

                            {data.custom_Service === "Yes" && data.id > 0 && 
                                <LAGridItem xs={12} sm={6} md={3} >
                                    <strong>Hour Interval</strong>
                                    <LATextField
                                        label=""
                                        fullWidth={true}
                                        variant="outlined"
                                        name="hour"
                                        // type="number"
                                        InputProps={{
                                            inputProps: {
                                                min: 0
                                            }
                                        }}
                                        disabled={true}
                                        value={data.service_Type ? data.service_Type : ""}
                                        onChange={undefinedFunction}
                                    />
                                </LAGridItem>
                            }

                            {data.custom_Service === "Yes" && data.id > 0 && 
                                <LAGridItem xs={12} sm={6} md={3} >
                                    <strong>Completed Date</strong>
                                    <LATextField
                                        id="completed_Date"
                                        value={data.completed_Date ? splitDate(data.completed_Date) : ""}
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={disableForm}
                                        name="completed_Date"
                                        label=""
                                        onChange={this.handleChange}
                                        type="date"
                                    />
                                </LAGridItem>
                            }

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <br />
                                <LACheckBox
                                    label="Cancel Service?"
                                    disabled={disableForm}
                                    onChange={onCancelledServiceCheckbox}
                                    value={data.cancelled === "Yes" ? true : false}
                                />
                            </LAGridItem>}

                            {data.custom_Service === "Yes" && data.id > 0 && 
                                <LAGridItem xs={12} sm={6} md={3} >
                                    <strong>Service Items</strong>
                                    <LAMultiSelectDropdown
                                        selectAllOption={true}
                                        valueExpr="name"
                                        displayExpr="name"
                                        options={serviceItemsOptions}
                                        selectedOptions={this.getSelectedServiceItemsEdit(data.service_Items ?? "", serviceItemsOptions)}
                                        onChange={this.handleMultiSelectServiceItemEditChange}
                                    />
                                </LAGridItem>
                            }
                            {/* {data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <br />
                                <LACheckBox
                                    label="Do you want to update Current Hours?"
                                    disabled={disableByPM || disableForm}
                                    onChange={onUpdateCurrentHoursCheckbox}
                                    value={data.update_Current_Hours === "Yes" ? true : false}
                                />
                            </LAGridItem>} */}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Comments</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disableForm}
                                    name="comments"
                                    value={data.comments ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                />
                            </LAGridItem>

                            {data.custom_Service === "Yes" && data.id === 0 && <LAGridItem xs={12} >
                                <CustomServiceTable
                                    disableForm={disableForm}
                                    data={custom_Services}
                                    serviceItemsOptions={serviceItemsOptions ?? []}
                                    onDelete={this.handleDeleteCustomService}
                                    onAddCustomService={this.handleAddCustomService}
                                    handleCustomeServiceChange={this.handleCustomServiceTableChange}
                                />
                            </LAGridItem>}


                            <LAGridItem xs={12} className="text-center">
                                <LABluePaper>

                                    <LAGrid>
                                        <LAGridItem xs={12} className="text-center">
                                            <h3>ATTACHMENTS</h3>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADropZone onFileDrop={this.handleAttachment} />
                                        </LAGridItem>

                                        {attachments.length > 0 && attachments.map((x, index) => {
                                            const onDownload = (): void => this.handleDownload(x);
                                            const onRemove = (): void => this.handleFileRemove(index);
                                            // const onChange = (name: string, value: string): void => this.handleAtatachmentNameChange(value + "." + x.name.split(".")[1], index);
                                        
                                            return (
                                                <LAGridItem xs={12} sm={4} md={3} key={index} className="text-center">
                                                    <LAPaperWithLessPadding>
                                                        <strong>{x.name}</strong>
                                                        <br /><br />
                                                        {/* <LATextField
                                                            name="name"
                                                            label="Description"
                                                            onChange={onChange}
                                                            value={x.name.split(".")[0]}
                                                        /> */}
                                                        <LAIconButton
                                                            name="file"
                                                            label={x.name}
                                                            id={(index).toString()}
                                                            onClick={onDownload}
                                                            icon={<DownloadIcon />}
                                                        />

                                                        <LAIconButton
                                                            key={index}
                                                            label="Delete"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove}
                                                        />
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            )
                                        })}
                                    </LAGrid>
                                </LABluePaper>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            variant="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave || disableForm}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    
                    }
                    <RequestStatus requestStatus={addScheduleServiceHistoryStatus.kind} successMessage="Schedule Service has been added successfully" />
                    <RequestStatus requestStatus={updateScheduleServiceHistoryStatus.kind} successMessage="Schedule Service has been updated successfully" /> 
                </AddUpdateServiceHistoryStyles>
            </PageSpacing>
        );
    }

    private getSelectedServiceItemsEdit = (values: string, options:IIdName[]): any => {
        const response: any = [];
        // console.log(values)
        if(values) {
            const selectedData = values != null ? values.split(",").map(String) : [];
            selectedData.forEach((x:any) => {
                const index = options.findIndex((q:any) => q.name === x);
                if(index >= 0) {
                    response.push(options[index].name);
                }
            })
        }
        // console.log(response);
        return response;
    }

    private handleMultiSelectServiceItemEditChange = (selected:any) => { 
        // let errors = { ...state.errors };
        const serviceItemsOptions = hasPayload(this.props.getSOCTCustomServiceItemsBySOCTID) ? this.props.getSOCTCustomServiceItemsBySOCTID.payload.response : [];
        let serviceItems: number[] = [];
        let selectedServiceItems = this.state.data.service_Items;
        // console.log(selected)
        selected.forEach((x: any) => {
            const service_Item:any = serviceItemsOptions.find((item: IIdName) => item.name === x);
            
            if(service_Item) {
                serviceItems.push(service_Item.name)
            }
        })

        selectedServiceItems = serviceItems.join(",");
        this.handleChange("service_Items", selectedServiceItems);
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        let errors = { ...this.state.errors };
        if (value === undefined) {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value.toString(), errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
            },
            errors
        });
    };

    private handleAttachment = (files: Array<File | DataTransferItem>): void => {
        const newFiles: IShopEquipmentFile[] = [...this.state.attachments];

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const check = newFiles.findIndex(z => z.name === x.name);
                            if (check === -1) {
                                newFiles.push({
                                    name: x.name,
                                    base64String: reader.result.toString()
                                });
                            } else {
                                newFiles[check].base64String = reader.result.toString()
                            };

                            if (files.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                attachments: newFiles
            });
        })
    };

    private handleDeleteCustomService = async (index: number, data: any) => { 
        // console.log(index, data)
        const selection = window.confirm(deleteText);

        if(selection) {
            const custom_Services = [...this.state.custom_Services];
            custom_Services.splice(index, 1);
            this.setState({
                ...this.state,
                custom_Services: custom_Services
            });
        }
    }

    private handleCustomServiceTableChange = (name: string, value: any, index: number, ) => { 
        // console.log(value, index, name);
        if (index !== undefined) {
            const iS: any[] = [...this.state.custom_Services];
            
            let record: any = { ...iS[index] };
            record = {
                ...record,
                [name]: name === "hour_Interval" ? (value.length > 0 ? Number(value) : null) : value
            }
            iS[index] = record;
            
            this.setState({
                ...this.state,
                custom_Services: iS
            });
        }
    }

    private handleAddCustomService = () : void => {
        const initialOptionObject = {
            id: 0,
            hour_Interval: null,
            service_Date: "",
            completed_Date: "",
            notes: "",
            notify_Shop_Admin: "No",
            service_Items: ""
        }

        const list = this.state.custom_Services !== null ? [...this.state.custom_Services] : [];
        list.push(initialOptionObject);

        this.setState({
            ...this.state,
            custom_Services: list
        });
    }

    private handleFileRemove = (index: number): void => {
        const iS = [...this.state.attachments];
        const selection = window.confirm(deleteText);

        if (selection) {
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                attachments: iS
            });
        }
    };

    private handleDownload = (file: IShopEquipmentFile): void => {
        if (file.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            this.downloadFromEndpoint(file.name);
        }
    };

    private downloadFromEndpoint = async (fileName: string): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.getToken)) {
                // console.log(fileName, this.state.data.id)
                await fetch(END_POINTS.FIELD.SCOT.DOWNLOAD_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            File_Name: fileName,
                            ID: this.state.data.id,
                            Page:"SOCT_Scheduled_Service",
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };
    
    private handleCancel = (): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.soct_ID) {
            this.setState({ serverError: "" });

            callRouteWithQueryString({
                route: this.props,
                search: { id: query.soct_ID.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.SERVICE_HISTORY.INDEX
            });
        }
    };

    private handleSave = async (): Promise<void> => {

        const { data, attachments, custom_Services } = this.state;

        if(data.custom_Service === "Yes" && data.id === 0 && custom_Services.length === 0) {
            alert("Add atleast one custom service")
        } 
        else {
            if (hasPayload(this.props.getToken)) {
    
                if (data.id === 0) {
                    let request: IAddUpdateScheduleServiceHistory = {
                        ID: data.id,
                        SOCT_ID: data.socT_ID,
                        Service_Type: data.service_Type,
                        Service_Date: data.service_Date,
                        Meter_Reading: data.meter_Reading,
                        ECM: data.ecm,
                        Custom_Service: data.custom_Service,
                        Custom_Services: custom_Services,
                        Comments: data.comments,
                        Files: attachments,
                        // Service_Items: data.service_Items,
                        Created_By: this.props.getToken.payload.response.upn,
                        Modified_By: this.props.getToken.payload.response.upn,
                    };
                    // console.log(request)
                    this.props.addScheduleServiceHistoryRequest({
                        token: this.props.getToken.payload.response.token,
                        request
                    });
                } 
                else {
                    let updateRequest: IAddUpdateScheduleServiceHistory = {
                        ID: data.id,
                        SOCT_ID: data.socT_ID,
                        Service_Type: data.service_Type,
                        Service_Date: data.service_Date,
                        Completed_Date: data.completed_Date,
                        Meter_Reading: Number(data.meter_Reading),
                        ECM: Number(data.ecm) > 0 ? Number(data.ecm) : null,
                        Custom_Service: data.custom_Service,
                        Comments: data.comments,
                        Cancelled: data.cancelled,
                        Files: attachments,
                        Service_Items: data.service_Items,
                        Created_By: data.created_By,
                        Modified_By: this.props.getToken.payload.response.upn,
                    };
                    console.log(updateRequest)
                    this.props.updateScheduleServiceHistoryRequest({
                        token: this.props.getToken.payload.response.token,
                        request : updateRequest
                    });
                };
    
                this.setState({ serverError: "" });
            }
        }

    };

    private handleNumber = (name: string, value: string): void => {
        // console.log(name, value)
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value ? Number(value) : null
            },
            errors
        });
    };

    private handleServiceType = (name: string, checked: boolean, value: string, index: number): void => {
        // console.log(name, checked, value, index);
        const { data } = this.state;
        let custom_Services = this.state.custom_Services;
    
        let serviceTypesArray: string[] = data.service_Type ? data.service_Type.split(",") : [];
    
        if (checked) {
            if (!serviceTypesArray.includes(value)) {
                serviceTypesArray.push(value);
            }
            if(value === "0") {
                // data.custom_Service = "Yes";
                serviceTypesArray = ["0"];
            }
            if(value === "custom") {
                data.custom_Service = "Yes";
                serviceTypesArray = ["custom"];
            }
        } 
        else {
            if(value === "0") {
                // data.custom_Service = "No";
                custom_Services = []
            }
            if(value === "custom") {
                data.custom_Service = "No";
                custom_Services = []
            }
            serviceTypesArray = serviceTypesArray.filter(item => item.trim() !== value);
        }
    
        const updatedServiceType = serviceTypesArray.join(",");
    
        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: updatedServiceType
            },
            serviceTypesArray,
            custom_Services
        });
    };
    

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any =  queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.getToken))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.getToken)) {
                if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                    
                    if (query.id !== "0") {
                        if(isNotLoaded(this.props.getScheduleServiceHistoryByID)) {
                            this.getData();
                        }

                        if (hasPayload(this.props.getScheduleServiceHistoryByID)) {
                            // if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getScheduleServiceHistoryByID.payload.response;
                                const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
                                // console.log(data)
                                const unit = (query && query.soct_ID && units) ? units.find(q => q.socT_ID === Number(query.soct_ID)) : null;
                                let serviceHours: string[] = [];
                                if (unit) {
                                    serviceHours = unit.service_Hours ? unit.service_Hours.split(",") : [];
                                    if(!serviceHours.includes("custom")) {
                                        serviceHours.unshift("custom");
                                    }
                                    if(!serviceHours.includes("0")) {
                                        serviceHours.push("0");
                                    }
                                }
                                const attachments: IShopEquipmentFile[] = [];

                                if (data) {
                                    const existingAtt = data.attachments ? data.attachments.split(";") : [];

                                    existingAtt.forEach(x => {
                                        attachments.push({ name: x, base64String: "" });
                                    });
                                }

                                this.setState({
                                    data,
                                    attachments,
                                    disableByPM: data.pM_Sheet_ID && data.pM_Sheet_ID > 0 ? true : undefined,
                                    disableForm: data.cancelled === "Yes" ? true : undefined,
                                    service_Hours: serviceHours,
                                    serviceTypesArray: data.service_Type ? data.service_Type.split(",") : []
                                });
                            // }
                        } 
                    }
                    else {
                        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
                        const unit = (query && query.soct_ID && units) ? units.find(q => q.socT_ID === Number(query.soct_ID)) : null;
                        // console.log(unit)
                        let serviceHours: string[] = [];
                        // console.log(unit)
                        if (unit) {
                            serviceHours = unit.service_Hours ? unit.service_Hours.split(",") : [];
                            if(!serviceHours.includes("custom")) {
                                serviceHours.unshift("custom");
                            }
                            if(!serviceHours.includes("0")) {
                                serviceHours.push("0");
                            }
                        }
                        // console.log(serviceHours)
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        if(unit) {
                            delete errors["unit_Number"];
                        }

                        this.setState({ 
                            data: {
                                ...this.state.data,
                                unit_ID: unit ? unit.id : 0,
                                socT_ID: Number(query.soct_ID)
                            },
                            service_Hours: serviceHours,
                            errors 
                        });
                    }

                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        const query: any =  queryString.parse(this.props.location.search);
        if (hasPayload(this.props.getToken) && query !== undefined && Number(query.soct_ID) > 0)
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Service_History"
                }
            });
    };

    private getData = () : void => {
        if (hasPayload(this.props.getToken)) {
            const query: any =  queryString.parse(this.props.location.search);

            if (query !== undefined && query.id !== undefined && Number(query.id) > 0) {
                this.props.getScheduleServiceHistoryByIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: Number(query.id)
                    }
                });
            }
        }
    }
    private getSOCTCustomServiceItemsBySOCTIDData = () : void => {
        if (hasPayload(this.props.getToken)) {
            const query: any =  queryString.parse(this.props.location.search);
            if (query !== undefined && query.soct_ID !== undefined && Number(query.soct_ID) > 0) {
                this.props.getSOCTCustomServiceItemsBySOCTIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        soct_id: Number(query.soct_ID)
                    }
                });
            }
        }
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateServiceHistoryComponentStoreProps => ({
    getToken: getToken(state),
    getLookup: getSOCTLookups(state),
    getScheduleServiceHistoryByID: getScheduleServiceHistoryById(state),
    addScheduleServiceHistoryStatus: addScheduleServiceHistoryStatus(state),
    updateScheduleServiceHistoryStatus: updateScheduleServiceHistoryStatus(state),
    getSOCTCustomServiceItemsBySOCTID: getSOCTCustomServiceItemsBySOCTID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateServiceHistoryComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISOCTLookupRequest) => dispatch(getSOCTLookupsLoadAction(data)),
    getScheduleServiceHistoryByIDRequest: (request: IGetScheduleServiceHistoryByIdRequest) => dispatch(getScheduleServiceHistoryByIdLoadAction(request)),
    addScheduleServiceHistoryRequest: (data: IAddScheduleServiceHistoryRequest) => dispatch(addScheduleServiceHistoryLoadAction(data)),
    updateScheduleServiceHistoryRequest: (data: IUpdateScheduleServiceHistoryRequest) => dispatch(updateScheduleServiceHistoryLoadAction(data)),
    getSOCTCustomServiceItemsBySOCTIDRequest: (data: IGetSOCTCustomServiceItemsBySOCTIDRequest) => dispatch(getSOCTCustomServiceItemsBySOCTIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateServiceHistory);

interface ICustomServiceTable {
    disableForm: true | undefined;
    data: any;
    handleCustomeServiceChange:(name: string, value:any, index:number) => void;
    onAddCustomService:()=>void;
    onDelete:(index:number, data:any)=>void;
    serviceItemsOptions: IIdName[];
  };

const CustomServiceTable: React.FC<ICustomServiceTable> = React.memo((props: ICustomServiceTable) => (
    <TableContainer >
        <LAGreenPaper>
            <LAGrid>
                <LAGridItem xs={5}>
                    <LAIconButton
                        label="Add New"
                        disabled={props.disableForm ? true : undefined}
                        icon={<AddIcon />}
                        onClick={props.onAddCustomService} 
                    /> 
                </LAGridItem> 
                <h3>Custom Service</h3>
            </LAGrid>
          
            <LAGrid>
                <Table size="small" aria-label="Custom Services">
                    <TableHead className="header-row">
                        
                    <TableRow>
                        <TableCell className="title">Hour Interval</TableCell>
                        <TableCell className="title">Specific Service Date</TableCell>
                        <TableCell className="title">Completed Date</TableCell>
                        <TableCell className="title">Notes</TableCell>
                        <TableCell className="title">Notify Shop Admin</TableCell>
                        <TableCell className="title"></TableCell>
                    </TableRow>
                    </TableHead>

                    {props.data !== null && props.data.length > 0 ? 
                        props.data.map((x: any, index: number) => {
                            const onHourChange = (e: unknown, value: any) => props.handleCustomeServiceChange("hour_Interval", (value !== null) ? value : "", index);
                            const onNotesChange = (e: unknown, value: any) => props.handleCustomeServiceChange("notes", (value !== null) ? value : "", index);
                            const onServiceDateChange = (e: unknown, value: any) => props.handleCustomeServiceChange("service_Date", (value !== null) ? value : "", index);
                            const onCompletedDateChange = (e: unknown, value: any) => props.handleCustomeServiceChange("completed_Date", (value !== null) ? value : "", index);
                            const onSendEmailCheck = (value: boolean): void => props.handleCustomeServiceChange("notify_Shop_Admin", value === true ? "Yes" : "No", index);

                            const getSelectedServiceItems = (values: string, options:IIdName[]): any => {
                                const response: any = [];
                                // console.log(values)
                                if(values) {
                                    const selectedData = values != null ? values.split(",").map(Number) : [];
                                    selectedData.forEach((x:any) => {
                                        const index = options.findIndex((q:any) => q.id === x);
                                        if(index >= 0) {
                                            response.push(options[index].id);
                                        }
                                    })
                                }
                                // console.log(response);
                                return response;
                            }

                            const handleMultiSelectServiceItemChange = (selected:any) => { 
                                // let errors = { ...state.errors };
                                let serviceItems: number[] = [];
                                let selectedServiceItems = x.service_Items;
                                // console.log(selected)
                                selected.forEach((x: any) => {
                                    const service_Item:any = props.serviceItemsOptions.find((item: IIdName) => item.id === x);
                                
                                    if(service_Item) {
                                        serviceItems.push(service_Item.id)
                                    }
                                })
                        
                                selectedServiceItems = serviceItems.join(",");
                                props.handleCustomeServiceChange("service_Items", selectedServiceItems, index);
                            }
                            // console.log(x.service_Items)
                            
                            return <TableBody key={index} className={index % 2 ? "odd-row" : "even-row"}>
                                <TableRow>
                                    <TableCell >
                                        <LATextField
                                            label="Hour Interval"
                                            fullWidth={true}
                                            variant="outlined"
                                            name="hour_Interval"
                                            type="number"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            disabled={props.disableForm}
                                            value={x.hour_Interval > 0 ? x.hour_Interval : ""}
                                            onChange={onHourChange}
                                        />
                                        <FormHelperText className="required-text">{((!(x.hour_Interval > 0) || x.hour_Interval === "") && !x.service_Date) ? "Required" : ""}</FormHelperText>
                                    </TableCell>
                                    <TableCell >
                                        <LATextField
                                            id="service_Date"
                                            value={x.service_Date ? splitDate(x.service_Date) : ""}
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={props.disableForm}
                                            name="service_Date"
                                            label=""
                                            onChange={onServiceDateChange}
                                            type="date"
                                        />
                                        <FormHelperText className="required-text">{((!(x.hour_Interval > 0) || x.hour_Interval === "") && !x.service_Date) ? "Required" : ""}</FormHelperText>
                                    </TableCell>
                                    <TableCell >
                                        <LATextField
                                            id="completed_Date"
                                            value={x.completed_Date ? splitDate(x.completed_Date) : ""}
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={props.disableForm}
                                            name="completed_Date"
                                            label=""
                                            onChange={onCompletedDateChange}
                                            type="date"
                                        />
                                    </TableCell>
                                    <TableCell width={400}>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            indexPosition={index}
                                            name="notes"
                                            label="Notes"
                                            disabled={props.disableForm}
                                            value={x.notes? x.notes: ''}
                                            onChange={onNotesChange}
                                            type="text"

                                        />
                                        <FormHelperText className="required-text">{!x.notes ? "Required" : ""}</FormHelperText>
                                    </TableCell>
                                    <TableCell >
                                        <LACheckBox
                                            value={x.notify_Shop_Admin === "Yes" ? true : false}
                                            label="Yes"
                                            disabled={props.disableForm}
                                            onChange={onSendEmailCheck}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <LAIconButton
                                            label="Delete"
                                            icon={<DeleteIcon />}
                                            onClick={(e:any)=>{props.onDelete(index, x)}} 
                                            disabled={props.disableForm ? true : undefined}
                                            />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <LAGridItem xs={4} > 
                                            <strong>Service Items</strong>
                                            <LAMultiSelectDropdown
                                                selectAllOption={true}
                                                valueExpr="id"
                                                displayExpr="name"
                                                options={props.serviceItemsOptions}
                                                selectedOptions={getSelectedServiceItems(x.service_Items, props.serviceItemsOptions)}
                                                onChange={handleMultiSelectServiceItemChange}
                                            />
                                        </LAGridItem>
                                    </TableCell>
                                </TableRow>
                                {/* <hr /> */}
                            </TableBody>
                        }) 
                    : null}
                </Table>
            </LAGrid>
    </LAGreenPaper>
    </TableContainer>
));