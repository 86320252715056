import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_VENDOR_ORDER_REQUEST } from "./updateVendorOrderConstants";
import { IUpdateVendorOrderLoadAction, IUpdateVendorOrderLoadFailedAction, IUpdateVendorOrderSuccessAction, updateVendorOrderLoadFailedAction, updateVendorOrderLoadSuccessAction } from "./updateVendorOrderActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateVendorOrderEpic: Epic = (
    action$: ActionsObservable<IUpdateVendorOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateVendorOrderSuccessAction | IUpdateVendorOrderLoadFailedAction> =>
    action$.ofType(IUPDATE_VENDOR_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.VENDOR.UPDATE_VENDOR_ORDER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateVendorOrderSuccessAction | IUpdateVendorOrderLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateVendorOrderLoadSuccessAction(response);
                            } else {
                                return updateVendorOrderLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateVendorOrderLoadFailedAction(response.message)))
                    )
            )
        );