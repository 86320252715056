import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_VENDOR_ORDER_REQUEST, IAddVendorOrderRequest } from "./addVendorOrderConstants";


export interface IAddVendorOrderLoadAction {
    type: IADD_VENDOR_ORDER_REQUEST.REQUEST;
    data: IAddVendorOrderRequest
}
export const addVendorOrderLoadAction = (data: IAddVendorOrderRequest): IAddVendorOrderLoadAction => ({
    type: IADD_VENDOR_ORDER_REQUEST.REQUEST,
    data
});
export interface IAddVendorOrderSuccessAction {
    type: IADD_VENDOR_ORDER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addVendorOrderLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddVendorOrderSuccessAction => ({
    type: IADD_VENDOR_ORDER_REQUEST.SUCCESS,
    message
});
export interface IAddVendorOrderLoadFailedAction {
    type: IADD_VENDOR_ORDER_REQUEST.FAILED;
    message: string;
}
export const addVendorOrderLoadFailedAction = (message: string): IAddVendorOrderLoadFailedAction => ({
    type: IADD_VENDOR_ORDER_REQUEST.FAILED,
    message
});
