//var url:string = "https://api.sureway.ca";
//var url:string = "http://localhost:51380";

import { SOCT_URL } from "../../../../shared/styles";

async function AddWorkingSite(workingSite:any, subWorkingSites:any[], currentUser:string){
    let responseStatus = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            Site_Name:workingSite.site_Name,
            Site_Address:workingSite.site_Address,
            Active:workingSite.active,
            Notes:workingSite.notes,
            Working_Sub_Sites:subWorkingSites,
            No_Sub_Site_Check:workingSite.no_sub_site_check,
            Rental_Status:workingSite.rental_status,
            Created_By:currentUser,
            Modified_By:currentUser,
            }
    })
  }
    await fetch(SOCT_URL+'/api/SOCT/AddSOCTWorkingSite', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}

async function getSOCTWorkingSites(activeFlag:boolean){
  let master_temp_list:any = [];
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
    }
      await fetch(SOCT_URL+'/api/SOCT/GetSOCTWorkingSites', requestOptions)
      .then(async response => {
          const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          // no error
          data.map((history:any) => {
            if(activeFlag){
              if(history.active === "Yes"){
                master_temp_list.push(history);
              }
            }
            else{
              master_temp_list.push(history);
            }
          });
      });
      return master_temp_list;
  }
async function getSOCTWorkingSubSitesByParentID(parentID:number, activeFlag:boolean){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{ParentID:parentID}})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTWorkingSubSitesByParentID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      data.map((history:any) => {
        if(activeFlag){
          if(history.active ==="Yes"){
            master_temp_list.push(history);  
          }
        }
        else{
          master_temp_list.push(history);    
        }
      });
  });
  return master_temp_list;
}

async function updateSOCTWorkingSites(previousData:any, subWorkingSites:any[], currentUser:string){
  let responseStatus = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            ID:previousData.id,
            Active:previousData.active,
            Site_Name:previousData.site_Name,
            Notes:previousData.notes,
            Working_Sub_Sites:subWorkingSites,
            Rental_Status:previousData.rental_Status,
            Modified_By:currentUser,
            }
    })
  }
    await fetch(SOCT_URL+'/api/SOCT/UpdateSOCTWorkingSite', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}


async function checkWorkingSiteUsed(site_name:string){
  var responseStatus ="";
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type':'application/json' }, 
        body: JSON.stringify({request:{Site_Name:site_name},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
    }
      await fetch(SOCT_URL+'/api/SOCT/checkWorkingSiteUsed', requestOptions)
      .then(async response => {
          const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
            // error response
            if(!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          // no error
          responseStatus = data;
      });
      return responseStatus;
  }

  
async function getSOCTWorkingSubSitesByID(sub_site_id:number){
  let master_temp_list:any = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{ID:sub_site_id}})
}
  await fetch(SOCT_URL+'/api/SOCT/getSOCTWorkingSubSitesByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      master_temp_list = data;
     
  });
  return master_temp_list;
}

export {AddWorkingSite, getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID, updateSOCTWorkingSites, checkWorkingSiteUsed, getSOCTWorkingSubSitesByID};