
export enum IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST {
    REQUEST = "getWorkingSiteLogVersionHistory/GET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST",
    SUCCESS = "getWorkingSiteLogVersionHistory/GET_WORKING_SITE_LOG_VERSION_HISTORY_SUCCESS",
    FAILED = "getWorkingSiteLogVersionHistory/GET_WORKING_SITE_LOG_VERSION_HISTORY_FAILED"
};

export interface IGetWorkingSiteLogVersionHistoryRequest {
    request: {
        ID: number;
    },
    token: string;
};

export interface IGetWorkingSiteLogVersionHistory {
    id: number;
    itemID: number;
    date_: string;
    foreman: string;
    wsS_ID: number;
    status: string;
    equip_On_Site: number;
    equip_Working: number;
    emp_Working: number;
    service_Required: string;
    radio: string;
    start_Time: string;
    end_Time: string;
    work_Description: string;
    saturday_Working: string;
    sunday_Working: string;
    scheduled_Days_Off: string;
    prime_Contractor: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;
    site: string;
};