import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_MACHINE_RECEIVER_REQUEST } from "./addMachineReceiverConstants";
import {
    IAddMachineReceiverLoadAction, IAddMachineReceiverLoadFailedAction, IAddMachineReceiverSuccessAction,
    addMachineReceiverLoadFailedAction, addMachineReceiverLoadSuccessAction
} from "./addMachineReceiverActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addMachineReceiverEpic: Epic = (
    action$: ActionsObservable<IAddMachineReceiverLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddMachineReceiverSuccessAction | IAddMachineReceiverLoadFailedAction> =>
    action$.ofType(IADD_MACHINE_RECEIVER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_MACHINE_RECEIVER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddMachineReceiverSuccessAction | IAddMachineReceiverLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return addMachineReceiverLoadSuccessAction(response);
                            } else {
                                return addMachineReceiverLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addMachineReceiverLoadFailedAction(response.message)))
                    )
            )
        );