import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_VENDOR_PO_LOG_BY_ID_REQUEST, IGetVendorPOLogByID } from "./getVendorPOLogByIDConstants";
import { IGetVendorPOLogByIDLoadAction, IGetVendorPOLogByIDLoadFailedAction, IGetVendorPOLogByIDSuccessAction } from "./getVendorPOLogByIDActions";
import { ISaveVendorPOLogSuccessAction } from "../saveVendorPOLog/saveVendorPOLogActions";
import { ISAVE_VENDOR_PO_LOG_REQUEST } from "../saveVendorPOLog/saveVendorPOLogConstants";

type Actions =
    | IGetVendorPOLogByIDLoadAction
    | IGetVendorPOLogByIDSuccessAction
    | IGetVendorPOLogByIDLoadFailedAction
    | ISaveVendorPOLogSuccessAction
    | IFlushDataSuccessAction;

export const GetVendorPOLogByIDReducer = (state: Server<SurewayAPIResponse<IGetVendorPOLogByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetVendorPOLogByID>> => {
    switch (action.type) {
        case IGET_VENDOR_PO_LOG_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_PO_LOG_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_PO_LOG_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_VENDOR_PO_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};