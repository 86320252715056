import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ST_LOGS_REQUEST {
    REQUEST = "getSTLogs/GET_ST_LOGS_REQUEST",
    SUCCESS = "getSTLogs/GET_ST_LOGS_SUCCESS",
    FAILED = "getSTLogs/GET_ST_LOGS_FAILED"
};

export interface IGetSTLogsRequest extends ISurewayTokenRequestBody {
    request: {
        view: string;
    }
};

export interface ISTLogs {
  id: number;
  repair_ID?: number;
  defect_ID?: number;
  display?: string;
  defect_Item?: string;
  date: string;
  component_Code?: string;
  name: string;
  comments?: string;
  hours: number;
  status: string;
  job_No?: string;
  cost_Code?: string;
  type?: string;
  description?: string;
  timesheet_ID: number;
  field_Log: string;
  uploaded_Date: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  unit_ID: number;
  issue: string;
  work_Order_No: string;
  manager_Instructions: string;
  defect_Status: string;
  site: string;
  email: string;
  driver_ID: number;
  truck: string;
  employee_Code: string;
  meter_Reading: string;
  rejection_Notes: string;
  modified_After_Upload: string;
  payroll_Acknowledged: string;
  truck_Hours: string;
  start_Date: string;
  end_Date: string;
  start_Time:string;
  end_Time: string;
};