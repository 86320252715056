import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_MECHANIC_REQUEST } from "./updateMechanicConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IUpdateMechanicLoadAction, IUpdateMechanicLoadFailedAction, IUpdateMechanicSuccessAction, 
    updateMechanicLoadFailedAction, updateMechanicLoadSuccessAction } from "./updateMechanicActions";

export const updateMechanicEpic: Epic = (
    action$: ActionsObservable<IUpdateMechanicLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateMechanicSuccessAction | IUpdateMechanicLoadFailedAction> =>
    action$.ofType(IUPDATE_MECHANIC_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.MECHANICS.UPDATE_MECHANIC,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateMechanicSuccessAction | IUpdateMechanicLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateMechanicLoadSuccessAction(response);
                            } else {
                                return updateMechanicLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateMechanicLoadFailedAction(response.message)))
                    )
            )
        );