import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_FILTER_PART_REQUEST, IUpdateFilterPartRequest } from "./updateFilterPartConstants";


export interface IUpdateFilterPartLoadAction {
    type: IUPDATE_FILTER_PART_REQUEST.REQUEST;
    data: IUpdateFilterPartRequest
}
export const updateFilterPartLoadAction = (data: IUpdateFilterPartRequest): IUpdateFilterPartLoadAction => ({
    type: IUPDATE_FILTER_PART_REQUEST.REQUEST,
    data
});
export interface IUpdateFilterPartSuccessAction {
    type: IUPDATE_FILTER_PART_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFilterPartLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFilterPartSuccessAction => ({
    type: IUPDATE_FILTER_PART_REQUEST.SUCCESS,
    message
});
export interface IUpdateFilterPartLoadFailedAction {
    type: IUPDATE_FILTER_PART_REQUEST.FAILED;
    message: string;
}
export const updateFilterPartLoadFailedAction = (message: string): IUpdateFilterPartLoadFailedAction => ({
    type: IUPDATE_FILTER_PART_REQUEST.FAILED,
    message
});
