import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_BY_ID_REQUEST, IShopEquipment, IGetEquipmentByIDRequest } from "./getEquipmentByIDConstants";

export interface IGetEquipmentByIDLoadAction {
    type: IGET_EQUIPMENT_BY_ID_REQUEST.REQUEST;
    data: IGetEquipmentByIDRequest
}
export const getEquipmentByIDLoadAction = (data: IGetEquipmentByIDRequest): IGetEquipmentByIDLoadAction => ({
    type: IGET_EQUIPMENT_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentByIDSuccessAction {
    type: IGET_EQUIPMENT_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IShopEquipment>;
}
export const getEquipmentByIDLoadSuccessAction = (list: SurewayAPIResponse<IShopEquipment>): IGetEquipmentByIDSuccessAction => ({
    type: IGET_EQUIPMENT_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentByIDLoadFailedAction {
    type: IGET_EQUIPMENT_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getEquipmentByIDLoadFailedAction = (message: string): IGetEquipmentByIDLoadFailedAction => ({
    type: IGET_EQUIPMENT_BY_ID_REQUEST.FAILED,
    message
});
