import { IRole } from "../getAuthorization/getAuthorizationConstants";

export enum IGET_TOKENS_REQUEST {
    REQUEST = "getToken/GET_TOKENS_REQUEST",
    SUCCESS = "getToken/GET_TOKENS_SUCCESS",
    FAILED = "getToken/GET_TOKENS_FAILED"
};

export interface ITokenRequest {
    request: {
        username?: string;
        user_token?: string;
    };
    token?: string;
};

export interface IToken {
    token: string;
    upn: string;
    jobList: IRole;
    dispatch: IRole;
    pmSheets: IRole;
    partsAccess: IRole;
    filterList: IRole;
    repairLine: IRole;
    accounting: boolean;
    locationList: IRole;
    externalUsers: IRole;
    workingSiteLogs: IRole;
    attachmentAccess: IRole;
    kantechAccess: IRole;
    etAccess: IRole;
    workorder: "Yes" | "No";
    email: string;
    mobileToken: string;
    repairLineRole: string;
    filterCutsManagerAccess: boolean;
    payrollAccess: boolean;
    payrollContractorAccess: boolean;
    repairRoles: IRepairRoles;
    attachmentsAdmin: boolean;
    soctAccess: boolean;
    partsDispatch: boolean;
    safetyAdmin: boolean;
    itAccess : boolean;
    formsAdmin : boolean;
    surveyAccess: boolean;
    vendorAccess: boolean;
    formsCleanupAccess: boolean;
};

export interface IRepairRoles {
  field_Manager: Boolean;
  field_Mechanic: Boolean;
  foreman: Boolean;
  lube_Manager: Boolean;
  mechanic_ID: number;
  shop_Manager: Boolean;
  shop_Mechanic: Boolean;
}