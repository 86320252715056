import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl,  pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IServiceDue, IGetServiceDueRequest } from "../../../redux/field/pmSheets/getServiceDues/getServiceDuesConstants";
import { IAddServiceDueRequest } from "../../../redux/field/pmSheets/addServiceDue/addServiceDueConstants";
import { IUpdateServiceDueRequest } from "../../../redux/field/pmSheets/updateServiceDue/updateServiceDueConstants";
import { getServiceDues } from "../../../redux/field/pmSheets/getServiceDues/getServiceDuesAccessor";
import { addServiceDueStatus } from "../../../redux/field/pmSheets/addServiceDue/addServiceDueAccessor";
import { updateServiceDueStatus } from "../../../redux/field/pmSheets/updateServiceDue/updateServiceDueAccessor";
import { getServiceDuesLoadAction } from "../../../redux/field/pmSheets/getServiceDues/getServiceDuesActions";
import { addServiceDueLoadAction } from "../../../redux/field/pmSheets/addServiceDue/addServiceDueActions";
import { updateServiceDueLoadAction } from "../../../redux/field/pmSheets/updateServiceDue/updateServiceDueActions";

const RequiredFields: string[] = ["service_Due_Name"];

interface IAddUpdateServiceDueComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    serviceTypes: Server<SurewayAPIResponse<ById<IServiceDue>>>;
    addServiceDueStatus: Server<SurewayAPIResponse<string>>;
    updateServiceDueStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateServiceDueComponentDispatchProps {
    getServiceDuesRequest: (data: IGetServiceDueRequest) => unknown
    addServiceDueRequest: (data: IAddServiceDueRequest) => unknown;
    updateServiceDueRequest: (data: IUpdateServiceDueRequest) => unknown;
};

interface IAddUpdateServiceDueOwnProps {
    id?: string;
};

interface IAddUpdateServiceDueComponentState {
    data: IServiceDue;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateServiceDueStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateServiceDueComponentProps =
    RouteComponentProps
    & IAddUpdateServiceDueOwnProps
    & IAddUpdateServiceDueComponentStoreProps
    & IAddUpdateServiceDueComponentDispatchProps;

class AddUpdateServiceDue extends PureComponent<IAddUpdateServiceDueComponentProps, IAddUpdateServiceDueComponentState> {

    public constructor(props: IAddUpdateServiceDueComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                service_Due_Name: "",
                active: YesOrNo[0].name,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateServiceDueComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addServiceDueStatus !== prevProps.addServiceDueStatus) {

                if (this.props.addServiceDueStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addServiceDueStatus.message });

                if (hasPayload(this.props.addServiceDueStatus) && this.props.addServiceDueStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateServiceDueStatus !== prevProps.updateServiceDueStatus) {

                if (this.props.updateServiceDueStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateServiceDueStatus.message });

                if (hasPayload(this.props.updateServiceDueStatus) && this.props.updateServiceDueStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { token, addServiceDueStatus, updateServiceDueStatus } = this.props;
        const getRole = pageAccessCheck(token, "pmSheets");
        const disabled = getRole === ReadOnly ? true : undefined;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);

        return (
            <AddUpdateServiceDueStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>SERVICE DUE</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Service Due Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="service_Due_Name"
                                value={data.service_Due_Name}
                                onChange={this.handleChange}
                                errorText={errors["service_Due_Name"] ? errors["service_Due_Name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addServiceDueStatus.kind} successMessage="Service Due has been added successfully" />
                <RequestStatus requestStatus={updateServiceDueStatus.kind} successMessage="Service Due has been updated successfully" />
            </AddUpdateServiceDueStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        
        this.props.history.push({
            pathname: ROUTE.FIELD.PM_SHEETS.SERVICE_DUES,
            search: getTokenFromUrl(false)
          });
    };

    private handleSave = async (): Promise<void> => {
        const { id, service_Due_Name, active } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addServiceDueRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Active: active,
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn,
                        Service_Due_Name: service_Due_Name
                    }
                });
            } else {
                this.props.updateServiceDueRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Active: active,
                        Modified_By: this.props.token.payload.response.upn,
                        Service_Due_Name: service_Due_Name
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "pmSheets") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (hasPayload(this.props.serviceTypes)) {
                            const data = this.props.serviceTypes.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getServiceDuesRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateServiceDueComponentStoreProps => ({
    serviceTypes: getServiceDues(state),
    token: getToken(state),
    addServiceDueStatus: addServiceDueStatus(state),
    updateServiceDueStatus: updateServiceDueStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateServiceDueComponentDispatchProps => ({
    addServiceDueRequest: (data: IAddServiceDueRequest) => dispatch(addServiceDueLoadAction(data)),
    getServiceDuesRequest: (data: IGetServiceDueRequest) => dispatch(getServiceDuesLoadAction(data)),
    updateServiceDueRequest: (data: IUpdateServiceDueRequest) => dispatch(updateServiceDueLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateServiceDue);