import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST {
    REQUEST = "getMechanicTimesheetForApproval/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST",
    SUCCESS = "getMechanicTimesheetForApproval/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_SUCCESS",
    FAILED = "getMechanicTimesheetForApproval/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_FAILED"
};

export interface IGetMechanicTimesheetForApprovalRequest extends ISurewayTokenRequestBody {

};

// export interface IMechanicTimesheets {
//     id: number;
//     mechanic_ID: number;
//     start_Date: string;
//     end_Date: string;
//     start_Time: string;
//     end_Time: number;
//     truck_ID: number;
//     loa: string;
//     acknowledged: string;
//     status: string;
//     lunch: string;
//     notes: string;
//     travel: string;
//     travel_Hours: number;
//     travel_Truck_ID: number;
//     logs : IMechanicLogs[];
//     created_By: string;
//     modified_By: string;
//     created: string;
//     modified: string;
// };

// export interface IMechanicLogs {
//     id: number;
//     timesheet_ID: number;
//     defect_ID: number;
//     hours: number;
//     comments: string;
//     status: string;
//     repair_Location: string;
//     field_Log: string;
//     uploaded_Date: string;
//     parts_Delay: string;
//     parts_Delay_Hours: number;
//     parts_Delay_Comments: string;
//     created_By: string;
//     modified_By: string;
//     created: string;
//     modified: string;
//     display: string;
//     unit_ID: number;
//     attachment_ID: number;
//     defect_Item: string;
//     issue: string;
//     component_Code: string;
//     work_Order_No: string;
//     job_No: string;
//     cost_Code: string;
//     item_Repair_Location: string;
// }

export interface IMechanicTimesheetForApproval {
    id: number;
    timesheet_ID: number;
    defect_ID: number;
    hours: number;
    comments: string;
    status: string;
    repair_Location: string;
    field_Log: string;
    uploaded_Date: string;
    parts_Delay: string;
    parts_Delay_Hours: number;
    parts_Delay_Comments: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    display: string;
    unit_ID: number;
    attachment_ID: number;
    defect_Item: string;
    issue: string;
    component_Code: string;
    work_Order_No: string;
    job_No: string;
    cost_Code: string;
    item_Repair_Location: string;
    mechanic_ID: number;
    start_Date: string;
    end_Date: string;
    start_Time: string;
    end_Time: number;
}