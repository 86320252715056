import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FILTER_REPORT_REQUEST, IFilterReport, IFilterReportRequest } from "./getFilterReportConstants";

export interface IGetFilterReportLoadAction {
    type: IGET_FILTER_REPORT_REQUEST.REQUEST;
    data: IFilterReportRequest
}
export const getFilterReportLoadAction = (data: IFilterReportRequest): IGetFilterReportLoadAction => ({
    type: IGET_FILTER_REPORT_REQUEST.REQUEST,
    data
});
export interface IGetFilterReportSuccessAction {
    type: IGET_FILTER_REPORT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IFilterReport>>;
}
export const getFilterReportLoadSuccessAction = (list: SurewayAPIResponse<ById<IFilterReport>>): IGetFilterReportSuccessAction => ({
    type: IGET_FILTER_REPORT_REQUEST.SUCCESS,
    list
});
export interface IGetFilterReportLoadFailedAction {
    type: IGET_FILTER_REPORT_REQUEST.FAILED;
    message: string;
}
export const getFilterReportLoadFailedAction = (message: string): IGetFilterReportLoadFailedAction => ({
    type: IGET_FILTER_REPORT_REQUEST.FAILED,
    message
});
