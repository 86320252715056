import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SERVICE_TRUCK_REQUEST, IAddServiceTruckRequest } from "./addServiceTruckConstants";


export interface IAddServiceTruckLoadAction {
    type: IADD_SERVICE_TRUCK_REQUEST.REQUEST;
    data: IAddServiceTruckRequest
}
export const addServiceTruckLoadAction = (data: IAddServiceTruckRequest): IAddServiceTruckLoadAction => ({
    type: IADD_SERVICE_TRUCK_REQUEST.REQUEST,
    data
});
export interface IAddServiceTruckSuccessAction {
    type: IADD_SERVICE_TRUCK_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addServiceTruckLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddServiceTruckSuccessAction => ({
    type: IADD_SERVICE_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IAddServiceTruckLoadFailedAction {
    type: IADD_SERVICE_TRUCK_REQUEST.FAILED;
    message: string;
}
export const addServiceTruckLoadFailedAction = (message: string): IAddServiceTruckLoadFailedAction => ({
    type: IADD_SERVICE_TRUCK_REQUEST.FAILED,
    message
});
