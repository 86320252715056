import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_SURVEY_ATTACHMENT_REQUEST, IAddUpdateSurveyAttachmentRequest } from "./addSurveyAttachmentConstants";

export interface IAddSurveyAttachmentLoadAction {
    type: IADD_SURVEY_ATTACHMENT_REQUEST.REQUEST;
    data: IAddUpdateSurveyAttachmentRequest
}
export const addSurveyAttachmentLoadAction = (data: IAddUpdateSurveyAttachmentRequest): IAddSurveyAttachmentLoadAction => ({
    type: IADD_SURVEY_ATTACHMENT_REQUEST.REQUEST,
    data
});
export interface IAddSurveyAttachmentSuccessAction {
    type: IADD_SURVEY_ATTACHMENT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addSurveyAttachmentLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddSurveyAttachmentSuccessAction => ({
    type: IADD_SURVEY_ATTACHMENT_REQUEST.SUCCESS,
    list
});
export interface IAddSurveyAttachmentLoadFailedAction {
    type: IADD_SURVEY_ATTACHMENT_REQUEST.FAILED;
    message: string;
}
export const addSurveyAttachmentLoadFailedAction = (message: string): IAddSurveyAttachmentLoadFailedAction => ({
    type: IADD_SURVEY_ATTACHMENT_REQUEST.FAILED,
    message
});
