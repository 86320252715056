import { IAddUpdateKantechAdjustment } from "../addKantechAdjustment/addKantechAdjustmentConstants";


export enum IUPDATE_KANTECH_ADJUSTMENT_REQUEST {
    REQUEST = "updateKantechAdjustment/UPDATE_KANTECH_ADJUSTMENT_REQUEST",
    SUCCESS = "updateKantechAdjustment/UPDATE_KANTECH_ADJUSTMENT_SUCCESS",
    FAILED = "updateKantechAdjustment/UPDATE_KANTECH_ADJUSTMENT_FAILED"
};

export interface IUpdateKantechAdjustmentRequest {
    token: string;
    request: IAddUpdateKantechAdjustment;
};