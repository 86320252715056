
export enum IADD_TASK_REQUEST {
    REQUEST = "addTask/ADD_TASK_REQUEST",
    SUCCESS = "addTask/ADD_TASK_SUCCESS",
    FAILED = "addTask/ADD_TASK_FAILED"
};

export interface IAddTaskRequest {
    token: string;
    request: IAddUpdateTask;
};

export interface IAddUpdateTask {
    ID: number;
    Name: string;
    Departments: string;
    // Department_ID: number;
    Active: string;
    Created_By: string;
    Modified_By: string;
}
