
export enum IADD_SWAMPER_REQUEST {
    REQUEST = "addSwamper/ADD_SWAMPER_REQUEST",
    SUCCESS = "addSwamper/ADD_SWAMPER_SUCCESS",
    FAILED = "addSwamper/ADD_SWAMPER_FAILED"
};

export interface IAddSwamperRequest {
    token: string;
    request: IAddUpdateSwamper;
};

export interface IAddUpdateSwamper {
    ID: number;
    FirstName: string;
    LastName: string;
    Active: string;
    Email: string;
    Phone: string;
    Password: string;
    LoginInfo: boolean;
    Comments: string;
    Created_By: string;
    Modified_By: string;
};