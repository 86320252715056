import React from 'react';
  import {Button as Typography} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import SOCTHome from './equipmenttrackerhome';
import Iframe from 'react-iframe';

interface IState {
}
interface IProps extends RouteComponentProps{
  history:any
}

export default class MoveHistoryReportPage extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={};
    this.refreshGrid = this.refreshGrid.bind(this);
  }
  async refreshGrid(){
   
    this.setState({});
  }
  async componentDidMount(){
    this.refreshGrid();
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
         <div >
         <Typography className='dataGrid-title'>ET Move History</Typography>
         <Iframe url="https://reportserver.propsense.com/ReportServer/Pages/ReportViewer.aspx?%2fReports%2fField%2fET_Move_History&rs:Command=Render&rs:embed=true&rc:LinkTarget=_blank"
        width= "100%"
        height="1800"
        position="relative"
        scrolling="yes" 
        />
         </div>
            </>
        );
    }
}