import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_LASER_REQUEST } from "./updateLaserConstants";
import { IUpdateLaserLoadAction, IUpdateLaserLoadFailedAction, IUpdateLaserSuccessAction } from "./updateLaserActions";

type Actions =
    | IUpdateLaserLoadAction
    | IUpdateLaserSuccessAction
    | IUpdateLaserLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateLaserReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_LASER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_LASER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_LASER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};