import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { callRouteWithQueryString } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IGetRepairLineRequest, IRepairLine } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { getRepairLines } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesAccessor";
import { getRepairLinesLoadAction } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesActions";
import { IValidateMechanicLogin } from "../../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginConstants";
import { validateMechanicLogin } from "../../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginAccessor";
import { LAButton } from "../../shared/buttons";
import { LogOutIcon } from "../../shared/icons";
import { flushDataRequestAction } from "../../../redux/clearRedux/clearReduxActions";
import LAErrorBox from "../../shared/errorBox";

const storageKey = "key_mechanic_repair_line";

interface IMechanicRepairLinesStoreProps {
    validateMechanicLoginStatus: Server<IValidateMechanicLogin>;
    getRepairLine: Server<SurewayAPIResponse<ById<IRepairLine>>>;
};

interface IMechanicRepairLinesDispatchProps {
    requestLogOut: () => unknown;
    getMechanicRepairLinesRequest: (data: IGetRepairLineRequest) => unknown;
};


interface IMechanicRepairLinesOwnProps {

};

interface IMechanicRepairLinesState {
    assignedView: boolean;
    assignedLines: IRepairLine[];
};

const MechanicRepairLinesStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right-username {
        top: 0.5%;
        right: 2.5%;
        position: absolute;
        color: red;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMechanicRepairLinesProps = RouteComponentProps
    & IMechanicRepairLinesStoreProps
    & IMechanicRepairLinesDispatchProps
    & IMechanicRepairLinesOwnProps;

class MechanicRepairLines extends PureComponent<IMechanicRepairLinesProps, IMechanicRepairLinesState> {

    public constructor(props: IMechanicRepairLinesProps) {
        super(props);
        this.state = {
            assignedView: true,
            assignedLines: []
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.callServer();

    };

    public componentDidUpdate(prevProps: IMechanicRepairLinesProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();

    };


    public render(): ReactNode {

        const { assignedView, assignedLines } = this.state;
        const { getRepairLine, validateMechanicLoginStatus } = this.props;
        const username = hasPayload(validateMechanicLoginStatus) ? validateMechanicLoginStatus.payload.mechanic.name : "";
        const data = assignedView ? (assignedLines) : (hasPayload(getRepairLine) ? Object.values(getRepairLine.payload.response) : []);

        return (
            <PageSpacing title="MECHANIC" description="MECHANIC - REPAIR LINE" fixedSpaceOnSmallerScreens={true}>
                <MechanicRepairLinesStyles>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12}>
                                <h2 className="text-center title">MECHANIC REPAIR LINES</h2>
                                <hr />

                                {getRepairLine.kind === STATUS_ENUM.LOADING && <LALoading message="Loading repair lines..." />}
                                {getRepairLine.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load repair lines..." />}

                                {getRepairLine.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <strong className="pull-right-username">WELCOME, {username}</strong>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={assignedView ? "assigned" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="assigned" control={<Radio />} label="Assigned to me" />
                                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                            </RadioGroup>

                                            <LAButton
                                                label="LogOut"
                                                className="pull-right"
                                                onClick={this.handleLogOut}
                                                startIcon={<LogOutIcon color={WHITE_COLOR} />}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="mechanicRepairLineList"
                                                customRowColor={true}
                                                exportFileName="MechanicRepairLines"
                                                columns={[
                                                    { name: "unit_No", caption: "Unit #", type: "string" },
                                                    { name: "make", caption: "Make", type: "string" },
                                                    { name: "model", caption: "Model", type: "string" },
                                                    { name: "type", caption: "Type", type: "string" },
                                                    { name: "site_Name", caption: "Site", type: "string" },
                                                    { name: "sub_Site_Name", caption: "Sub Site", type: "string" },
                                                    { name: "job_No", caption: "Job #", type: "string" },
                                                    { name: "meter_Hours", caption: "Meter Hours", type: "number" },
                                                    { name: "initiated_Date", caption: "Initiated Date", type: "date" },
                                                    { name: "requested_By", caption: "Requested By", type: "string" },
                                                    { name: "mechanic_Names", caption: "Assigned Mechanic", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    { name: "comments", caption: "Mechanic Comments", type: "string" },
                                                    { name: "estimated_Completion_Date", caption: "Estimated Completion Date", type: "date" },
                                                    { name: "date_Completed", caption: "Date Completed", type: "date" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </MechanicRepairLinesStyles>
            </PageSpacing>
        );
    }

    private handleLogOut = (): void => {
        this.props.requestLogOut();
    };

    private onViewClick = (): void => {
        this.setState({ assignedView: !this.state.assignedView });
        let assignedView = !this.state.assignedView;
        const getStorageValue = localStorage.getItem(storageKey);
        if (getStorageValue) {
            if (assignedView) {
                localStorage.setItem(storageKey, "assigned");
            }
            else {
                localStorage.setItem(storageKey, "all");
            }
        }
        else {
            localStorage.setItem(storageKey, "assigned");
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.EXTERNAL.MECHANIC
        });
    };

    private onClick = (): void => {

    };

    private checkViewSelections = (): void => {
        let assignedView = this.state.assignedView;
        const getStorageValue = localStorage.getItem(storageKey);
        if (getStorageValue) {
            if (getStorageValue === "assigned") {
                assignedView = true;
            }
            else {
                assignedView = false;
            }
        }
        else {
            localStorage.setItem(storageKey, "assigned");
        }
        this.setState({ assignedView: assignedView });
    };

    private callServer = (): void => {

        const token = this.props.validateMechanicLoginStatus;

        if (hasPayload(token)) {

            if (isNotLoaded(this.props.getRepairLine)) {
                this.props.getMechanicRepairLinesRequest({
                    token: token.payload.token,
                    request : {
                        view: "All"
                    }
                });
            };

            if (hasPayload(this.props.getRepairLine)) {
                this.setState({
                    assignedLines: Object.values(this.props.getRepairLine.payload.response)
                        .filter(x => (x.assigned_Mech !== null) && (x.assigned_Mech.split(",").includes(token.payload.mechanic.id.toString())))
                });
            };

        };
    };

}

const mapStateToProps = (state: IStore): IMechanicRepairLinesStoreProps => ({
    getRepairLine: getRepairLines(state),
    validateMechanicLoginStatus: validateMechanicLogin(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMechanicRepairLinesDispatchProps => ({
    requestLogOut: () => dispatch(flushDataRequestAction()),
    getMechanicRepairLinesRequest: (data: IGetRepairLineRequest) => dispatch(getRepairLinesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MechanicRepairLines);