import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_BASE_KIT_REQUEST } from "./updateBaseKitConstants";
import { IUpdateBaseKitLoadAction, IUpdateBaseKitLoadFailedAction, IUpdateBaseKitSuccessAction } from "./updateBaseKitActions";

type Actions =
    | IUpdateBaseKitLoadAction
    | IUpdateBaseKitSuccessAction
    | IUpdateBaseKitLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateBaseKitReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_BASE_KIT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_BASE_KIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_BASE_KIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};