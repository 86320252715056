import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_REPEATER_KIT_REQUEST, IRepeaterKit, IRepeaterKitRequest } from "./getRepeaterKitsConstants";

export interface IGetRepeaterKitLoadAction {
    type: IGET_REPEATER_KIT_REQUEST.REQUEST;
    data: IRepeaterKitRequest
}
export const getRepeaterKitLoadAction = (data: IRepeaterKitRequest): IGetRepeaterKitLoadAction => ({
    type: IGET_REPEATER_KIT_REQUEST.REQUEST,
    data
});
export interface IGetRepeaterKitSuccessAction {
    type: IGET_REPEATER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IRepeaterKit>>;
}
export const getRepeaterKitLoadSuccessAction = (list: SurewayAPIResponse<ById<IRepeaterKit>>): IGetRepeaterKitSuccessAction => ({
    type: IGET_REPEATER_KIT_REQUEST.SUCCESS,
    list
});
export interface IGetRepeaterKitLoadFailedAction {
    type: IGET_REPEATER_KIT_REQUEST.FAILED;
    message: string;
}
export const getRepeaterKitLoadFailedAction = (message: string): IGetRepeaterKitLoadFailedAction => ({
    type: IGET_REPEATER_KIT_REQUEST.FAILED,
    message
});
