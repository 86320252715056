import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_COUPLER_REQUEST, IAddAttachmentCouplerRequest } from "./addCouplerConstants";


export interface IAddAttachmentCouplerLoadAction {
    type: IADD_ATTACHMENT_COUPLER_REQUEST.REQUEST;
    data: IAddAttachmentCouplerRequest
}
export const addAttachmentCouplerLoadAction = (data: IAddAttachmentCouplerRequest): IAddAttachmentCouplerLoadAction => ({
    type: IADD_ATTACHMENT_COUPLER_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentCouplerSuccessAction {
    type: IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentCouplerLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentCouplerSuccessAction => ({
    type: IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentCouplerLoadFailedAction {
    type: IADD_ATTACHMENT_COUPLER_REQUEST.FAILED;
    message: string;
}
export const addAttachmentCouplerLoadFailedAction = (message: string): IAddAttachmentCouplerLoadFailedAction => ({
    type: IADD_ATTACHMENT_COUPLER_REQUEST.FAILED,
    message
});
