import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
import { IAddSOCTSuccessAction } from "../addSOCT/addSOCTActions";
import { IADD_SOCT_REQUEST } from "../addSOCT/addSOCTConstants";
import { IUpdateSOCTSuccessAction } from "../updateSOCT/updateSOCTActions";
import { IUPDATE_SOCT_REQUEST } from "../updateSOCT/updateSOCTConstants";
import { IGetSoctListLoadAction, IGetSoctListLoadFailedAction, IGetSoctListSuccessAction } from "./getSoctListActions";
import { IGET_SOCT_LIST_REQUEST, ISoctList } from "./getSoctListConstants";

type Actions =
    | IGetSoctListLoadAction
    | IGetSoctListSuccessAction
    | IGetSoctListLoadFailedAction
    | IAddSOCTSuccessAction
    | IUpdateSOCTSuccessAction
    | IFlushDataSuccessAction;

export const GetSoctListReducer = (state: Server<ISoctList[]> = notLoaded, action: Actions): Server<ISoctList[]> => {
    switch (action.type) {
        case IGET_SOCT_LIST_REQUEST.REQUEST:
            return loading;

        case IGET_SOCT_LIST_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SOCT_LIST_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SOCT_REQUEST.SUCCESS:
            return notLoaded;
    
        case IUPDATE_SOCT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};