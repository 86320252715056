import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getDate, getTokenFromUrl, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import queryString from "query-string";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { getUnitDetails } from "../../../redux/field/getUnitDetails/getUnitDetailsAccessor";
import { getUnitDetailsLoadAction } from "../../../redux/field/getUnitDetails/getUnitDetailsActions";
import { IUnitDetails, IUnitDetailsRequest } from "../../../redux/field/getUnitDetails/getUnitDetailsConstants";
import LALoading from "../../shared/loading";
import { StaticTextField } from "../../shared/staticTextField";
import splitDate from "../../shared/splitDate";
import LAErrorBox from "../../shared/errorBox";
import { IGetUnitUtilizationByID, IGetUnitUtilizationByIDRequest } from "../../../redux/field/getUnitUtilizationByID/getUnitUtilizationByIDConstants";
import { getUnitUtilizationByID } from "../../../redux/field/getUnitUtilizationByID/getUnitUtilizationByIDAccessor";
import { getUnitUtilizationByIDLoadAction } from "../../../redux/field/getUnitUtilizationByID/getUnitUtilizationByIDActions";
import { UtilizationPopup } from "./utilizationPopup";
import LALinkButton from "../../shared/linkButton";

interface IUnitDetailsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getUnitDetailsData: Server<SurewayAPIResponse<IUnitDetails>>;
    getUnitUtilizationByID: Server<SurewayAPIResponse<ById<IGetUnitUtilizationByID>>>;
};

interface IUnitDetailsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getUnitDetailsRequest: (data: IUnitDetailsRequest) => unknown;
    getUnitUtilizationByIDRequest: (request: IGetUnitUtilizationByIDRequest) => unknown;
};


interface IUnitDetailsOwnProps {
    id?: number;
};

interface IUnitDetailsState {
    utilizationPopup: boolean;
};

const UnitDetailsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;
`;

type IUnitDetailsProps = RouteComponentProps
    & IUnitDetailsStoreProps
    & IUnitDetailsDispatchProps
    & IUnitDetailsOwnProps;

class UnitDetails extends PureComponent<IUnitDetailsProps, IUnitDetailsState> {

    public constructor(props: IUnitDetailsProps) {
        super(props);
        this.state = {
            utilizationPopup: false
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IUnitDetailsProps): void {
        if (this.props.getToken !== prevProps.getToken)
            this.callServer();
    };


    public render(): ReactNode {

        const { getUnitDetailsData, getUnitUtilizationByID } = this.props;
        const { utilizationPopup } = this.state;
        const { kind } = getUnitDetailsData;
        const data = hasPayload(getUnitDetailsData) ? getUnitDetailsData.payload.response : undefined;
        const getUnitUtilizationByIDData = hasPayload(getUnitUtilizationByID) ? getUnitUtilizationByID.payload.response : {};
        // console.log(data)

        return (
            <PageSpacing title="Equipments" description="FIELD - Equipments" fixedSpaceOnSmallerScreens={true}>
                <UnitDetailsStyles>

                    {(kind === STATUS_ENUM.LOADING) && <LALoading message="Loading... Please wait" />}

                    {((kind === STATUS_ENUM.SUCCEEDED) && (data)) ? 
                        <LAGrid>
                            <LAGridItem xs={12}>
                                <h2 className="text-center">UNIT DETAILS - {data.unit_Number}</h2>
                                {/* <strong >Unit#: {data.unit_Number}</strong> */}
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>

                                <LAGrid>
                                    <LAGridItem xs={12} sm={12} md={4} lg={2}>
                                        <StaticTextField
                                            label="Serial Number"
                                            value={data.serial_Number}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={12} lg={5}>
                                        <StaticTextField
                                            label="Site Name"
                                            value={data.site_Name}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={12} lg={5}>
                                        <StaticTextField
                                            label="Site Address"
                                            value={data.site_Address}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Sub Site Name"
                                            value={data.sub_Site_Name}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Job No"
                                            value={data.job_No}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={12} lg={5}>
                                        <StaticTextField
                                            label="Move Details"
                                            value={data.move_Details}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Move Status"
                                            value={data.move_Status}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Repair Status"
                                            value={data.repair_Status}
                                            color={((data.repair_Status === "Completed") || (data.repair_Status === "") || (!data.repair_Status)) ? undefined : "red"}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Scot Status"
                                            value={data.socT_Status}
                                            color={(data.socT_Status === "Green") ? "green" : (data.socT_Status === "Red") ? "red" : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Current Hours"
                                            value={data.current_Hours}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Meter Date"
                                            value={data.meter_Date ? splitDate(data.meter_Date) : ""}
                                            color={this.isWithinLast7Days(data.meter_Date) ? "red" : "green"}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Service Date"
                                            value={data.service_Date ? splitDate(data.service_Date) : ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Next 250 Due"
                                            value={data.next_250_Due}
                                            // color={(data.next_250_Due !== null) ? ((data.next_250_Due < -50) && (data.next_250_Due >= 50)) ? "yellow" : "red" : undefined}
                                            color={(data.next_250_Due) && (data.next_250_Due > 50) ? 'red': 
                                                (data.next_250_Due &&  data.next_250_Due > -51 && data.next_250_Due < 51 && data.last_EO != null && data.next_250_Due != null) ? 'yellow'
                                                : undefined }
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Next 500 Due"
                                            value={data.next_500_Due}
                                            // color={(data.next_500_Due !== null) ? ((data.next_500_Due < -50) && (data.next_500_Due >= 50)) ? "yellow" : "red" : undefined}
                                            color={(data.next_500_Due) && (data.next_500_Due > 50 && data.last_500 != null ) ? 'red': 
                                                (data.next_500_Due &&  data.next_500_Due > -51 && data.next_500_Due < 51 && data.last_500 != null && data.next_500_Due != null) ? 'yellow'
                                                : undefined }
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Next 1000 Due"
                                            value={data.next_1000_Due}
                                            // color={(data.next_1000_Due !== null) ? ((data.next_1000_Due < -100) && (data.next_1000_Due >= 100)) ? "yellow" : "red" : undefined}
                                            color={(data.next_1000_Due) && (data.next_1000_Due > 100 && data.last_1000 != null) ? 'red': 
                                                (data.next_1000_Due &&  data.next_1000_Due > -101 && data.next_1000_Due < 101 && data.last_1000 != null && data.next_1000_Due != null) ? 'yellow'
                                                : undefined }
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Next 1500 Due"
                                            value={data.next_1500_Due}
                                            // color={(data.next_1500_Due !== null) ? ((data.next_1500_Due < -150) && (data.next_1500_Due >= 150)) ? "yellow" : "red" : undefined}
                                            color={(data.next_1500_Due) && (data.next_1500_Due > 150 && data.last_1500 != null) ? 'red': 
                                                (data.next_1500_Due &&  data.next_1500_Due > -151 && data.next_1500_Due < 151 && data.last_1500 != null && data.next_1500_Due != null) ? 'yellow'
                                                : undefined }
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={1}>
                                        <StaticTextField
                                            label="Next 3000 Due"
                                            value={data.next_3000_Due}
                                            // color={(data.next_3000_Due !== null) ? ((data.next_3000_Due < -300) && (data.next_3000_Due >= 300)) ? "yellow" : "red" : undefined}
                                            color={(data.next_3000_Due) && (data.next_3000_Due > 300 && data.last_3000 != null) ? 'red': 
                                                (data.next_3000_Due &&  data.next_3000_Due > -301 && data.next_3000_Due < 301 && data.last_3000 != null && data.next_3000_Due != null) ? 'yellow'
                                                : undefined }
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Next ScheduleA Due"
                                            value={data.next_ScheduleA_Due}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Next ScheduleB Due"
                                            value={data.next_ScheduleB_Due}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Next ScheduleD Due"
                                            value={data.next_ScheduleD_Due}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Next CVIP Due"
                                            value={data.next_CVIP_Due ? splitDate(data.next_CVIP_Due) : ""}
                                            color={data.next_CVIP_Due && (new Date(splitDate(data.next_CVIP_Due)) < new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)) ? 'red' : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Customer Service Comments"
                                            value={data.custom_Service_Comments}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Soct Comments"
                                            value={data.socT_Comments}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Add to Soct"
                                            value={data.add_To_SOCT}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last EO"
                                            value={data.last_EO}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last 500"
                                            value={data.last_500}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last 1000"
                                            value={data.last_1000}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last 1500"
                                            value={data.last_1500}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last 3000"
                                            value={data.last_3000}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last ScheduleA"
                                            value={data.last_ScheduleA}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last ScheduleB"
                                            value={data.last_ScheduleB}
                                        />
                                    </LAGridItem>
                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Last ScheduleD"
                                            value={data.last_ScheduleD}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Customer Service Due"
                                            value={data.custom_Service_Due}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Ecm"
                                            value={data.ecm}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Status"
                                            value={data.status}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Service Truck"
                                            value={data.service_Truck}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4} lg={2}>
                                        <StaticTextField
                                            label="Utilization"
                                            value={Math.round(data.utilization * 100) + '%'}
                                        />
                                        <LALinkButton
                                            label="View Utilization"
                                            onClick={() => this.handleUtilizationClick(data)}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={12} md={12} lg={5}>
                                        <StaticTextField
                                            label="Comments"
                                            value={data.comments}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAGridItem>

                        </LAGrid> :
                        (kind === STATUS_ENUM.SUCCEEDED) && (!data) && <LAErrorBox text="No records found" />
                    }

                    {utilizationPopup && <UtilizationPopup 
                        data={getUnitUtilizationByIDData}
                        display={data?.unit_Number}
                        open={utilizationPopup}
                        status={getUnitUtilizationByID.kind}
                        onCancel={this.handleUtilizationPopupClose}
                    />}
                </UnitDetailsStyles>
            </PageSpacing>
        );
    }

    private handleUtilizationClick = (data: IUnitDetails): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getUnitUtilizationByIDRequest({
                request: {
                    id: data.unit_ID,
                },
                token: this.props.getToken.payload.response.token
            });

            this.setState({ utilizationPopup: true });
        }
    }

    private handleUtilizationPopupClose = (): void => {
        this.setState({ utilizationPopup: false });
    };
    
    private isWithinLast7Days = (value: string): boolean => {
        const today = new Date();
        const date = new Date(value);
        const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
      
        return date >= sevenDaysAgo && date <= today;
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

            this.props.getUnitDetailsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    unit_id: query.id
                }
            });
        }
    };

}

const mapStateToProps = (state: IStore): IUnitDetailsStoreProps => ({
    getToken: getToken(state),
    getUnitDetailsData: getUnitDetails(state),
    getUnitUtilizationByID: getUnitUtilizationByID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IUnitDetailsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getUnitDetailsRequest: (data: IUnitDetailsRequest) => dispatch(getUnitDetailsLoadAction(data)),
    getUnitUtilizationByIDRequest: (request: IGetUnitUtilizationByIDRequest): unknown => dispatch(getUnitUtilizationByIDLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetails);