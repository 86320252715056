import React from "react";
import styled from "styled-components";
import { HasClass } from "./publicInterfaces";
import { undefinedFunction } from "./constExports";
import { BLACK_COLOR, GREEN_COLOR, RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "./theme";

interface IStaticTextFieldProps extends HasClass {
    label: string;
    value: number | string;
    color?: "red" | "green" | "yellow";
}

const StyledStaticTextField = styled.div`
  position: relative;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  flex-direction: column;
  
  label {
    position: absolute;
    top: -8px;
    left: 8px;
    font-size: 14px;
    font-weight: bold;
    background-color: #fff;
    padding: 0 4px;
  };
  
  input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    height: 40px;
    outline: none;
    transition: border-color 0.2s ease-in-out;
  };

  .red {
    color: ${WHITE_COLOR};
    background-color: ${RED_COLOR};
  };

  .green {
    color: ${WHITE_COLOR};
    background-color: ${GREEN_COLOR};
  };

  .yellow {
    color: ${BLACK_COLOR};
    background-color: ${YELLOW_COLOR};
  };
`;


export const StaticTextField: React.FC<IStaticTextFieldProps> = (props: IStaticTextFieldProps) => {
    const { label, value, color } = props;

    return (
        <StyledStaticTextField>
            <label>{label}</label>
            <input
                type="text"
                value={value}
                disabled={true}
                className={color}
                onChange={undefinedFunction}
            />
        </StyledStaticTextField>
    );
}
