import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_COUPLER_REQUEST, IUpdateAttachmentCouplerRequest } from "./updateCouplerConstants";


export interface IUpdateAttachmentCouplerLoadAction {
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.REQUEST;
    data: IUpdateAttachmentCouplerRequest
}
export const updateAttachmentCouplerLoadAction = (data: IUpdateAttachmentCouplerRequest): IUpdateAttachmentCouplerLoadAction => ({
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentCouplerSuccessAction {
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentCouplerLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentCouplerSuccessAction => ({
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentCouplerLoadFailedAction {
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentCouplerLoadFailedAction = (message: string): IUpdateAttachmentCouplerLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_COUPLER_REQUEST.FAILED,
    message
});
