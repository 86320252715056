import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IGET_TOKENS_REQUEST, IToken } from "./getTokenConstants";
import { IGetTokenLoadAction, IGetTokenLoadFailedAction, IGetTokenSuccessAction } from "./getTokenActions";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";

type Actions =
    | IGetTokenLoadAction
    | IGetTokenSuccessAction
    | IGetTokenLoadFailedAction
    | IFlushDataSuccessAction;

export const GetTokenReducer = (state: Server<SurewayAPIResponse<IToken>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IToken>> => {
    switch (action.type) {
        case IGET_TOKENS_REQUEST.REQUEST:
            return loading;

        case IGET_TOKENS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_TOKENS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};