import { IWSS } from "../workingSiteLogs/getFieldLookups/getFieldLookupsConstants";

export enum IGET_LOOKUPS_REQUEST {
    REQUEST = "getLookups/GET_LOOKUPS_REQUEST",
    SUCCESS = "getLookups/GET_LOOKUPS_SUCCESS",
    FAILED = "getLookups/GET_LOOKUPS_FAILED"
};

export interface IShopLookupsRequest {
    token: string;
};


export interface IShopLookups {
    equipment_Types: string[];
    models: IShopModel[];
    manufacturers: IShopMakes[];
    owners: IShopLookupOwners[];
    units: IEquipmentList[];
    filter_Types: string[];
    wss_List: IWSS[];
};

export interface IShopModel {
    id: number;
    model: string;
}

export interface IShopLookupOwners { 
    id: number;
    name: string;
    company: string;
};

export interface IEquipmentList { 
    id: number;
    unit_Number: string;
    make: string;
    model: string;
};

export interface IShopMakes {
    id: number;
    manufacturer_Name: string;
};
