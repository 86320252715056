import { IMechanicLocalToken, MECHANIC_LOCAL_TOKEN } from "./mechanicLocalTokenConstants";

export interface IMechanicLocalTokenRequestAction {
    type: MECHANIC_LOCAL_TOKEN.REQUEST;
}

export const mechanicLocalTokenRequestAction = (): IMechanicLocalTokenRequestAction => ({
    type: MECHANIC_LOCAL_TOKEN.REQUEST,
});

export interface IMechanicLocalTokenSuccessAction {
    type: MECHANIC_LOCAL_TOKEN.SUCCESS;
    data: IMechanicLocalToken
}

export const mechanicLocalTokenSuccessAction = (data: IMechanicLocalToken): IMechanicLocalTokenSuccessAction => ({
    type: MECHANIC_LOCAL_TOKEN.SUCCESS,
    data
});

export interface IMechanicLocalTokenFailedAction {
    type: MECHANIC_LOCAL_TOKEN.FAILED;
}

export const mechanicLocalTokenFailedAction = (): IMechanicLocalTokenFailedAction => ({
    type: MECHANIC_LOCAL_TOKEN.FAILED,
});