
export enum ISAVE_INSPECTION_SHEET_REQUEST {
    REQUEST = "saveInspectionSheet/SAVE_INSPECTION_SHEET_REQUEST",
    SUCCESS = "saveInspectionSheet/SAVE_INSPECTION_SHEET_SUCCESS",
    FAILED = "saveInspectionSheet/SAVE_INSPECTION_SHEET_FAILED"
};

export interface ISaveInspectionSheetRequest {
    token: string;
    request: ISaveInspectionSheet;
};

export interface ISaveInspectionSheet {
    ID: number;
    Unit_ID: number;
    Defect_ID: number;
    Hours: number | null;
    Comments: string;
    Created_By: string;
    Modified_By: string;
    Items: ISaveInspectionSheetItems[];
};

export interface ISaveInspectionSheetItems {
    ID: number;
    Item_ID: number;
    Good: string;
    Needs_Repair: string;
    NA: string;
    Estimated_Hours: number | null;
    Estimated_Labour_Cost: number | null;
    Estimated_Parts_Cost: number | null;
    Approved: string;
    Comments: string;
}