import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_FIELDEMPLOYEE_REQUEST, IAddFieldEmployeeRequest } from "./addFieldEmployeeConstants";


export interface IAddFieldEmployeeLoadAction {
    type: IADD_FIELDEMPLOYEE_REQUEST.REQUEST;
    data: IAddFieldEmployeeRequest
}
export const addFieldEmployeeLoadAction = (data: IAddFieldEmployeeRequest): IAddFieldEmployeeLoadAction => ({
    type: IADD_FIELDEMPLOYEE_REQUEST.REQUEST,
    data
});
export interface IAddFieldEmployeeSuccessAction {
    type: IADD_FIELDEMPLOYEE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFieldEmployeeLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFieldEmployeeSuccessAction => ({
    type: IADD_FIELDEMPLOYEE_REQUEST.SUCCESS,
    message
});
export interface IAddFieldEmployeeLoadFailedAction {
    type: IADD_FIELDEMPLOYEE_REQUEST.FAILED;
    message: string;
}
export const addFieldEmployeeLoadFailedAction = (message: string): IAddFieldEmployeeLoadFailedAction => ({
    type: IADD_FIELDEMPLOYEE_REQUEST.FAILED,
    message
});
