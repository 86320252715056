import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_FOREMAN_MESSAGING_REQUEST } from "./updateForemanMessagingConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IUpdateForemanMessagingLoadAction, IUpdateForemanMessagingLoadFailedAction, IUpdateForemanMessagingSuccessAction, 
    updateForemanMessagingLoadFailedAction, updateForemanMessagingLoadSuccessAction } from "./updateForemanMessagingActions";

export const updateForemanMessagingEpic: Epic = (
    action$: ActionsObservable<IUpdateForemanMessagingLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateForemanMessagingSuccessAction | IUpdateForemanMessagingLoadFailedAction> =>
    action$.ofType(IUPDATE_FOREMAN_MESSAGING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.UPDATE_FOREMAN_MESSAGING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateForemanMessagingSuccessAction | IUpdateForemanMessagingLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateForemanMessagingLoadSuccessAction(response);
                            } else {
                                return updateForemanMessagingLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateForemanMessagingLoadFailedAction(response.message)))
                    )
            )
        );