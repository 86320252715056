import { connect } from "react-redux";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../redux/server";
import { IDriversAssignmentHistory, IGetDriversAssignmentHistoryRequest } from "../../../redux/field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryConstants";
import { LAPaperWithPadding } from "../../shared/paper";
import { getDriversAssignmentHistory } from "../../../redux/field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryAccessor";
import { getDriversAssignmentHistoryLoadAction } from "../../../redux/field/dispatch/getDriversAssignmentHistory/getDriversAssignmentHistoryActions";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IStore, IDispatch } from "../../../redux/reducers";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { DispatchSubHeader } from "../../header/dispatchSubHeader";
import { ROUTE } from "../../routes";
import { pageAccessCheck, getTokenFromUrl, userName, undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import PageSpacing from "../../shared/pageSpacing";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { getDriverAssignmentVersionHistory } from "../../../redux/field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryAccessor";
import { getDriverAssignmentVersionHistoryLoadAction } from "../../../redux/field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryActions";
import { IGetDriverAssignmentVersionHistory, IGetDriverAssignmentVersionHistoryRequest } from "../../../redux/field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryConstants";
import { DriverAssignmentVersionHistory } from "./logsPopup";
import LAErrorBox from "../../shared/errorBox";

interface IDriversAssignmentHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>>;
    getDriversAssignmentHistoryStatus: Server<SurewayAPIResponse<ById<IDriversAssignmentHistory>>>;
};

interface IDriversAssignmentHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getVersionHistoryRequest: (data: IGetDriverAssignmentVersionHistoryRequest) => unknown;
    getDriversAssignmentHistoryRequest: (data: IGetDriversAssignmentHistoryRequest) => unknown;
};


interface IDriversAssignmentHistoryOwnProps {

};

interface IDriversAssignmentHistoryState {
    historyPopup: boolean;
};

const DriversAssignmentHistoryStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 2.5%;
        top: 3%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IDriversAssignmentHistoryProps = RouteComponentProps
    & IDriversAssignmentHistoryStoreProps
    & IDriversAssignmentHistoryDispatchProps
    & IDriversAssignmentHistoryOwnProps;

class DriversAssignmentHistory extends PureComponent<IDriversAssignmentHistoryProps, IDriversAssignmentHistoryState> {

    public constructor(props: IDriversAssignmentHistoryProps) {
        super(props);
        this.state = {
            historyPopup: false,
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDriversAssignmentHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { historyPopup } = this.state;
        const { getDriversAssignmentHistoryStatus, getToken, getVersionHistory } = this.props;
        const getRole = pageAccessCheck(getToken, "dispatch");
        const data = hasPayload(getDriversAssignmentHistoryStatus) ? Object.values(getDriversAssignmentHistoryStatus.payload.response) : [];
        const popupData = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};

        return (
            <PageSpacing title="DriversAssignmentHistory" description="FIELD - DRIVERS ASSIGNMENT HISTORY" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <>
                    <DriversAssignmentHistoryStyles>

                        <DispatchSubHeader
                            {...this.props}
                            token={getToken}
                        />

                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <h2 className="text-center">DRIVERS ASSIGNMENT HISTORY</h2>
                                <hr />

                                {getDriversAssignmentHistoryStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Drivers Assignment History..." />}
                            {getDriversAssignmentHistoryStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Drivers Assignment History..." />}
                            </LAGridItem>

                            {getDriversAssignmentHistoryStatus.kind === STATUS_ENUM.SUCCEEDED && <>

                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        data={data}
                                        columnChoose={true}
                                        columnsHiding={true}
                                        onClick={undefinedFunction}
                                        searchPanel={true}
                                        filterHeader={true}
                                        export={true}
                                        removeStyleBtn={true}
                                        id="driversAssignmentHistoryList"
                                        customRowColor={true}
                                        onCustomClick={this.handleCustomClick}
                                        exportFileName="DriversAssignmentHistory"
                                        storageKey="driversAssignmentHistorySessionKey"
                                        getRefreshDataCall={this.getDataForTable}
                                        columns={[
                                            { name: "site_Info", caption: "Site", type: "string" },
                                            { name: "unit_No", caption: "Unit #", type: "string" },
                                            { name: "date", caption: "Date", type: "date" },
                                            { name: "status", caption: "Status", type: "string" },
                                            { name: "notes", caption: "Notes", type: "string" },
                                            { name: "driver", caption: "Driver", type: "string" },
                                            { name: "swamper", caption: "Swamper", type: "string" },
                                            { name: "eta", caption: "ETA", type: "string" },
                                            { name: "departed_Time", caption: "Departed Time", type: "string" },
                                            { name: "arrival_Time", caption: "Arrival Time", type: "string" },
                                            { name: "created", caption: "Created", type: "datetime" },
                                            { name: "created_By", caption: "Created By", type: "string" },
                                            { name: "modified_By", caption: "Modified By", type: "string" },
                                            { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                            { name: "version_history", caption: "Version History", type: "button" },
                                        ]}
                                    />
                                </LAGridItem>
                            </>}

                        </LAGrid>

                        {historyPopup && <DriverAssignmentVersionHistory 
                          data={popupData}
                          open={historyPopup}
                          status={getVersionHistory.kind}
                          onCancel={this.handlePopupClose}
                        />}
                        
                    </DriversAssignmentHistoryStyles>
                </>}
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ historyPopup: false });
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IDriversAssignmentHistory = e.row.data;
        this.handleVersionHistory(data.id);
    };

    private handleVersionHistory = async (id: number): Promise<void> => {
        await this.setState({ historyPopup: true });

        if (hasPayload(this.props.getToken)) {

            this.props.getVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    ID: id
                }
            });
        }
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "dispatch") !== NotApplicable) {
                if (isNotLoaded(this.props.getDriversAssignmentHistoryStatus)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.UNAUTHORIZED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getDriversAssignmentHistoryRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IDriversAssignmentHistoryStoreProps => ({
    getToken: getToken(state),
    getVersionHistory: getDriverAssignmentVersionHistory(state),
    getDriversAssignmentHistoryStatus: getDriversAssignmentHistory(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDriversAssignmentHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getDriversAssignmentHistoryRequest: (data: IGetDriversAssignmentHistoryRequest) => dispatch(getDriversAssignmentHistoryLoadAction(data)),
    getVersionHistoryRequest: (data: IGetDriverAssignmentVersionHistoryRequest) => dispatch(getDriverAssignmentVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriversAssignmentHistory);