import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_LOCATION_REQUEST, ILocation, ILocationRequest } from "./getLocationConstants";

export interface IGetLocationLoadAction {
    type: IGET_LOCATION_REQUEST.REQUEST;
    data: ILocationRequest
}
export const getLocationLoadAction = (data: ILocationRequest): IGetLocationLoadAction => ({
    type: IGET_LOCATION_REQUEST.REQUEST,
    data
});
export interface IGetLocationSuccessAction {
    type: IGET_LOCATION_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ILocation>>;
}
export const getLocationLoadSuccessAction = (list: SurewayAPIResponse<ById<ILocation>>): IGetLocationSuccessAction => ({
    type: IGET_LOCATION_REQUEST.SUCCESS,
    list
});
export interface IGetLocationLoadFailedAction {
    type: IGET_LOCATION_REQUEST.FAILED;
    message: string;
}
export const getLocationLoadFailedAction = (message: string): IGetLocationLoadFailedAction => ({
    type: IGET_LOCATION_REQUEST.FAILED,
    message
});
