
export enum IADD_LASER_REQUEST {
    REQUEST = "addLaser/ADD_LASER_REQUEST",
    SUCCESS = "addLaser/ADD_LASER_SUCCESS",
    FAILED = "addLaser/ADD_LASER_FAILED"
};

export interface IAddUpdateLaserRequest {
    token: string;
    request: IAddUpdateLaser;
};

export interface IAddUpdateLaser {
    ID: number;
    Serial_No: string;
    Type?: string;
    Brand?: string;
    Model?: string;
    Remote_Serial_Number?: string;
    Remote_Model?: string;
    Sureway_No?: string;
    Status?: string;
    Site_Foreman?: string;
    Certification_Date?: string;
    WSS_ID: number | null;
    Foreman: string;
    Notes?: string;
    Created_By?: string;
    Modified_By?: string;
};