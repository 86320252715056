import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IWORK_ORDER_REQUEST {
    REQUEST = "workOrder/WORK_ORDER_REQUEST",
    SUCCESS = "workOrder/WORK_ORDER_SUCCESS",
    FAILED = "workOrder/WORK_ORDER_FAILED"
};

export interface IWorkOrderRequest extends ISurewayTokenRequestBody {
    request: IRepairLineWorkOrder;
};

export interface IRepairLineWorkOrder {
    ID: number;
    Work_Order_No: string;
    Job_No: string;
    Cost_Code: string;
    Modified_By: string;
};