import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST, IUpdateRepairLineByMechanicRequest } from "./updateRepairLineByMechanicConstants";


export interface IUpdateRepairLineByMechanicLoadAction {
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.REQUEST;
    data: IUpdateRepairLineByMechanicRequest
}
export const updateRepairLineByMechanicLoadAction = (data: IUpdateRepairLineByMechanicRequest): IUpdateRepairLineByMechanicLoadAction => ({
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.REQUEST,
    data
});
export interface IUpdateRepairLineByMechanicSuccessAction {
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateRepairLineByMechanicLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateRepairLineByMechanicSuccessAction => ({
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS,
    message
});
export interface IUpdateRepairLineByMechanicLoadFailedAction {
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.FAILED;
    message: string;
}
export const updateRepairLineByMechanicLoadFailedAction = (message: string): IUpdateRepairLineByMechanicLoadFailedAction => ({
    type: IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.FAILED,
    message
});
