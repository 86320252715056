import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString,  getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../../shared/checkBox";
import { LACenteredLoading } from "../../../shared/loading";
import { IGetTasks, IGetTasksRequest } from "../../../../redux/field/getTasks/getTasksConstants";
import { getTasksLoadAction } from "../../../../redux/field/getTasks/getTasksActions";
import { getTasks } from "../../../../redux/field/getTasks/getTasksAccessor";
import { IAddTaskRequest, IAddUpdateTask } from "../../../../redux/field/addTask/addTaskConstants";
import { addTaskLoadAction } from "../../../../redux/field/addTask/addTaskActions";
import { addTaskStatus } from "../../../../redux/field/addTask/addTaskAccessor";
import { IUpdateTaskRequest } from "../../../../redux/field/updateTask/updateTaskConstants";
import { updateTaskStatus } from "../../../../redux/field/updateTask/updateTaskAccessor";
import { updateTaskLoadAction } from "../../../../redux/field/updateTask/updateTaskActions";
import { IFormDepartments, IFormLookup, IFormLookupRequest } from "../../../../redux/field/getFormLookup/getFormLookupConstants";
import { getFormLookup } from "../../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getFormLookupLoadAction } from "../../../../redux/field/getFormLookup/getFormLookupActions";
import { LAMultiSelectDropdown } from "../../../shared/multiSelectDropdown";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import LALinkButton from "../../../shared/linkButton";
import { FormVersionHistoryPopup } from "../formVersionHistoryPopup";
import { getFormVersionHistory } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";

const RequiredFields: string[] = ["name", "departments"];

interface IAddUpdateTaskComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    getTasks: Server<SurewayAPIResponse<ById<IGetTasks>>>;
    addTaskStatus: Server<SurewayAPIResponse<string>>;
    updateTaskStatus: Server<SurewayAPIResponse<string>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
};

interface IAddUpdateTaskComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getTasksRequest: (data: IGetTasksRequest) => unknown
    addTaskRequest: (data: IAddTaskRequest) => unknown;
    updateTaskRequest: (data: IUpdateTaskRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
};

interface IAddUpdateTaskOwnProps {
    id?: string;
};

interface IAddUpdateTaskComponentState {
    data: IGetTasks;
    formVersionHistoryPopup: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateTaskStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateTaskComponentProps =
    RouteComponentProps
    & IAddUpdateTaskOwnProps
    & IAddUpdateTaskComponentStoreProps
    & IAddUpdateTaskComponentDispatchProps;

class AddUpdateTask extends PureComponent<IAddUpdateTaskComponentProps, IAddUpdateTaskComponentState> {

    public constructor(props: IAddUpdateTaskComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                // department_ID: 0,
                departments: "",
                name: "",
                active: "Yes",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            formVersionHistoryPopup: false,
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateTaskComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addTaskStatus !== prevProps.addTaskStatus) {

                if (this.props.addTaskStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addTaskStatus.message });

                if (hasPayload(this.props.addTaskStatus) && this.props.addTaskStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateTaskStatus !== prevProps.updateTaskStatus) {

                if (this.props.updateTaskStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateTaskStatus.message });

                if (hasPayload(this.props.updateTaskStatus) && this.props.updateTaskStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, formVersionHistoryPopup } = this.state;
        const title = data.id > 0 ? "Update Task" : "Add Task";
        const { token, addTaskStatus, updateTaskStatus, getLookup, getFormVersionHistory} = this.props;
        const getRole = pageAccessCheck(token, "formsAdmin");
        const disabled = (getRole === false) ? true : undefined;
        // const disabled =  undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const departmentList = hasPayload(getLookup) ? getLookup.payload.response.form_departments : [];
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];
        // const onDepartment = (event: unknown, value: any): void => this.handleChange("department_ID", value !== null ? value.id : data.department_ID);
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");
        // console.log(data)

        if(getLookup.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateTaskStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={12} sm={12} md={3}>
                                <LALinkButton onClick={() => this.handleFormVersionHistory(data, "Tasks")} label="Version History"></LALinkButton>
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Task</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} >
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Task</strong>
                                        <LATextField
                                            name="name"
                                            label=""
                                            fullWidth={true}
                                            disabled={disabled}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.name ?? ""}
                                        />
                                    </LAGridItem>

                                    {/* <LAGridItem xs={12} sm={6} md={3} >
                                        <LAAutoComplete
                                            disabled={disabled}
                                            multiple={false}
                                            option={departmentList ?? []}
                                            autoHighlight={true}
                                            onChange={onDepartment}
                                            filterSelectedOptions={true}
                                            getOptionLabel="name"
                                            dropDownPlaceHolder="Department"
                                            errorText={errors["department_ID"] ? errors["department_ID"].message : undefined}
                                            selectionRemove={undefinedFunction}
                                            value={data.department_ID ? departmentList.find(q => q.id === data.department_ID) : null}
                                            defaultValue={data.department_ID ? departmentList.find(q => q.id === data.department_ID) : null}
                                        />
                                    </LAGridItem> */}

                                    <LAGridItem xs={12} sm={6} md={3} >
                                        <strong>Departments</strong>
                                        <LAMultiSelectDropdown
                                            selectAllOption={true}
                                            valueExpr="id"
                                            displayExpr="name"
                                            errorText={errors["departments"] ? errors["departments"].message : undefined}
                                            options={departmentList ?? []}
                                            selectedOptions={this.getSelectedDepartment(data.departments, departmentList)}
                                            onChange={this.handleMultiSelectDepartmentChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }
                    {formVersionHistoryPopup && <FormVersionHistoryPopup
                        page="FormAdminTaskVersionHistory"
                        data={formVersionHistoryPopupData}
                        display={`Version History` }
                        open={formVersionHistoryPopup}
                        status={getFormVersionHistory.kind}
                        onCancel={this.handleFormVersionHistoryPopupClose}
                    />}

                    <RequestStatus requestStatus={addTaskStatus.kind} successMessage="Task has been added successfully" />
                    <RequestStatus requestStatus={updateTaskStatus.kind} successMessage="Task has been updated successfully" />

                </AddUpdateTaskStyles>
            </PageSpacing>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.ADMIN.TASKS.INDEX
        });
    };

    private getSelectedDepartment = (ids: string, options:any): any => {
        const response: any = [];
        if(ids) {
            const selectedData = ids != null ? ids.split(",").map(Number) : [];
            selectedData.forEach((x:any) => {
                const index = options.findIndex((q:any) => q.id === x);
                if(index >= 0) {
                    response.push(options[index].id);
                }
            })
        }
        return response;
    }

    private handleMultiSelectDepartmentChange = (selected:any) => { 
        const data = { ...this.state.data };
        let errors = { ...this.state.errors };
        const departmentsOptions: IFormDepartments[] = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.form_departments : [];
        let departments: number[] = [];
        // console.log(selected)
        selected.forEach((x: any) => {
            const department:any = departmentsOptions.find((item: IFormDepartments) => item.id === x);
            if(department) {
                departments.push(department.id)
            }
        })

        data.departments = departments.join(",");

        errors = this.errorChecker("departments", data.departments, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                departments: data.departments,
            },
            errors
        });
    }

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            let request: IAddUpdateTask = {
                ID: data.id,
                Name: data.name.trim(),
                Departments: data.departments,
                // Department_ID: data.department_ID,
                Active: data.active,
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };
            // console.log(request)
            if (data.id === 0) {
                this.props.addTaskRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateTaskRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private handleFormVersionHistory = async(data: any, page: string): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: data.id,
                    page: page
                }
            });
        }
        this.setState({ formVersionHistoryPopup: true });
    }

    private handleFormVersionHistoryPopupClose = (): void => {
        this.setState({ formVersionHistoryPopup: false });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {
                    if (hasPayload(this.props.getTasks)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getTasks.payload.response[+query.id];

                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getTasksRequest({
                            token: this.props.token.payload.response.token
                        });
                    }
                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Task"
                }
            });
    };

}

const mapStateToProps = (state: IStore): IAddUpdateTaskComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFormLookup(state),
    getTasks: getTasks(state),
    addTaskStatus: addTaskStatus(state),
    updateTaskStatus: updateTaskStatus(state),
    getFormVersionHistory: getFormVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateTaskComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getTasksRequest: (data: IGetTasksRequest) => dispatch(getTasksLoadAction(data)),
    addTaskRequest: (data: IAddTaskRequest) => dispatch(addTaskLoadAction(data)),
    updateTaskRequest: (data: IUpdateTaskRequest) => dispatch(updateTaskLoadAction(data)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateTask);