import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST, IGetServiceTruckCommunicationLogs } from "./getServiceTruckCommunicationLogsConstants";
import { IGetServiceTruckCommunicationLogsLoadAction, IGetServiceTruckCommunicationLogsLoadFailedAction, IGetServiceTruckCommunicationLogsSuccessAction } from "./getServiceTruckCommunicationLogsActions";

type Actions =
    | IGetServiceTruckCommunicationLogsLoadAction
    | IGetServiceTruckCommunicationLogsSuccessAction
    | IGetServiceTruckCommunicationLogsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetServiceTruckCommunicationLogsReducer = (state: Server<SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>> => {
    switch (action.type) {
        case IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};