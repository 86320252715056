import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_JOURNELS_REQUEST , IJournels, IGetJournelsRequest } from "./getJournelsConstants";

export interface IGetJournelsLoadAction {
    type: IGET_JOURNELS_REQUEST.REQUEST;
    data: IGetJournelsRequest
}
export const getJournelsLoadAction = (data: IGetJournelsRequest): IGetJournelsLoadAction => ({
    type: IGET_JOURNELS_REQUEST.REQUEST,
    data
});
export interface IGetJournelsSuccessAction {
    type: IGET_JOURNELS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IJournels>>;
}
export const getJournelsLoadSuccessAction = (list: SurewayAPIResponse<ById<IJournels>>): IGetJournelsSuccessAction => ({
    type: IGET_JOURNELS_REQUEST.SUCCESS,
    list
});
export interface IGetJournelsLoadFailedAction {
    type: IGET_JOURNELS_REQUEST.FAILED;
    message: string;
}
export const getJournelsLoadFailedAction = (message: string): IGetJournelsLoadFailedAction => ({
    type: IGET_JOURNELS_REQUEST.FAILED,
    message
});
