import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_CREW_SIGNATURE_BY_ID_REQUEST } from "./saveCrewSignatureByIDConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { ISaveCrewSignatureByIDLoadAction, ISaveCrewSignatureByIDLoadFailedAction, ISaveCrewSignatureByIDSuccessAction, 
    saveCrewSignatureByIDLoadFailedAction, saveCrewSignatureByIDLoadSuccessAction } from "./saveCrewSignatureByIDActions";

export const saveCrewSignatureByIDEpic: Epic = (
    action$: ActionsObservable<ISaveCrewSignatureByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveCrewSignatureByIDSuccessAction | ISaveCrewSignatureByIDLoadFailedAction> =>
    action$.ofType(ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.EXTERNAL.SAVE_CREW_SIGNATURE_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveCrewSignatureByIDSuccessAction | ISaveCrewSignatureByIDLoadFailedAction => {
                            if(response.message === "Success")
                                return saveCrewSignatureByIDLoadSuccessAction(response.message);

                            return saveCrewSignatureByIDLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveCrewSignatureByIDLoadFailedAction(response.message)))
                    )
            )
        );