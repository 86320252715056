import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IVALIDATE_OPERATOR_LOGIN_REQUEST, IValidateOperatorLogin } from "./validateOperatorLoginConstants";
import { LOCAL_STORAGE_TOKEN, saveToken } from "../localStorageToken/localStorageTokenConstants";
import { ILocalStorageTokenFailedAction, ILocalStorageTokenSuccessAction } from "../localStorageToken/localStorageTokenActions";
import { IValidateOperatorLoginLoadAction, IValidateOperatorLoginLoadFailedAction, IValidateOperatorLoginSuccessAction } from "./validateOperatorLoginActions";
import { EXTERNAL_USER_LOGOUT } from "../../../external/externalUserLogOut/externalUserLogOutConstants";
import { IExternalUserLogOutSuccessAction } from "../../../external/externalUserLogOut/externalUserLogOutActions";

type Actions =
    | IValidateOperatorLoginLoadAction
    | IValidateOperatorLoginSuccessAction
    | IValidateOperatorLoginLoadFailedAction
    | ILocalStorageTokenSuccessAction
    | ILocalStorageTokenFailedAction
    | IExternalUserLogOutSuccessAction
    | IFlushDataSuccessAction;

export const ValidateOperatorLoginReducer = (state: Server<IValidateOperatorLogin> = notLoaded, action: Actions): Server<IValidateOperatorLogin> => {
    switch (action.type) {
        case IVALIDATE_OPERATOR_LOGIN_REQUEST.REQUEST:
            return loading;

        case IVALIDATE_OPERATOR_LOGIN_REQUEST.SUCCESS:
            saveToken(action.token, action.email);
            return succeeded({ token: action.token, email: action.email });

        case IVALIDATE_OPERATOR_LOGIN_REQUEST.FAILED:
            return failed(action.message);

        case LOCAL_STORAGE_TOKEN.SUCCESS:
            return succeeded({ token: action.data.token, email: action.data.email });

        case LOCAL_STORAGE_TOKEN.FAILED:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case EXTERNAL_USER_LOGOUT.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};