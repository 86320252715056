import { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { IDispatch } from "../../../redux/reducers";
import { LARedButton } from "../../shared/buttons";
import { LogOutIcon } from "../../shared/icons";
import { HasClass } from "../../shared/publicInterfaces";
import { WHITE_COLOR } from "../../shared/theme";
import styled from "styled-components";
import { externalUserLogoutRequestAction } from "../../../redux/external/externalUserLogOut/externalUserLogOutActions";

interface IExternalUserLogoutButtonDispatchProps {
    externalUserLogoutRequest: () => unknown;
}

type IExternalUserLogoutButtonProps = IExternalUserLogoutButtonDispatchProps & HasClass;

const ExternalUserLogoutButtonStyles = styled.div`
    position: absolute;
    right: 3%;
    height: 3%;
    top: 2%;
`;

class ExternalUserLogoutButton extends PureComponent<IExternalUserLogoutButtonProps> {
    public render(): ReactNode {
        return (
            <ExternalUserLogoutButtonStyles>
            <LARedButton
                label="Logout"
                fullWidth={true}
                onClick={this.logout}
                className={this.props.className}
                startIcon={<LogOutIcon color={WHITE_COLOR} />}
            />
            </ExternalUserLogoutButtonStyles>
        );
    }

    private logout = (): void => {
        this.props.externalUserLogoutRequest();
        const token = localStorage.getItem("token");
        if(token)
            localStorage.removeItem("token");
    };
}

const mapDispatchToProps = (dispatch: IDispatch): IExternalUserLogoutButtonDispatchProps => ({
    externalUserLogoutRequest: (): unknown => dispatch(externalUserLogoutRequestAction())
});

export default connect(null, mapDispatchToProps)(ExternalUserLogoutButton);
