import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CLEANUP_DATA_REQUEST, IGetCleanupData } from "./getCleanupDataConstants";
import { IGetCleanupDataLoadAction, IGetCleanupDataLoadFailedAction, IGetCleanupDataSuccessAction } from "./getCleanupDataActions";

type Actions =
    | IGetCleanupDataLoadAction
    | IGetCleanupDataSuccessAction
    | IGetCleanupDataLoadFailedAction
    | IFlushDataSuccessAction;

export const GetCleanupDataReducer = (state: Server<SurewayAPIResponse<IGetCleanupData>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetCleanupData>> => {
    switch (action.type) {
        case IGET_CLEANUP_DATA_REQUEST.REQUEST:
            return loading;

        case IGET_CLEANUP_DATA_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CLEANUP_DATA_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};