import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_STYLES_REQUEST, IStyle } from "./getStylesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetStylesLoadAction, IGetStylesLoadFailedAction, IGetStylesSuccessAction, getStylesLoadFailedAction, getStylesLoadSuccessAction } from "./getStylesActions";
import { withJsonContentType } from "../../../epicUtils";

export const getStylesEpic: Epic = (
    action$: ActionsObservable<IGetStylesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetStylesSuccessAction | IGetStylesLoadFailedAction> =>
    action$.ofType(IGET_STYLES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IStyle>>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_ATTACHMENT_STYLE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IStyle>>): IGetStylesSuccessAction => {
                            return getStylesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getStylesLoadFailedAction(response.message)))
                    )
            )
        );