import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST, IGetMechanicLogsByDefectIDRequest, IMechanicLogsByDefectID } from "./getMechanicLogsByDefectIDConstants";

export interface IGetMechanicLogsByDefectIDLoadAction {
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.REQUEST;
    data: IGetMechanicLogsByDefectIDRequest
}
export const getMechanicLogsByDefectIDLoadAction = (data: IGetMechanicLogsByDefectIDRequest): IGetMechanicLogsByDefectIDLoadAction => ({
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.REQUEST,
    data
});
export interface IGetMechanicLogsByDefectIDSuccessAction {
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IMechanicLogsByDefectID[]>;
}
export const getMechanicLogsByDefectIDLoadSuccessAction = (list: SurewayAPIResponse<IMechanicLogsByDefectID[]>): IGetMechanicLogsByDefectIDSuccessAction => ({
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicLogsByDefectIDLoadFailedAction {
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.FAILED;
    message: string;
}
export const getMechanicLogsByDefectIDLoadFailedAction = (message: string): IGetMechanicLogsByDefectIDLoadFailedAction => ({
    type: IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.FAILED,
    message
});
