import { Grid, Button, Box, Modal, Backdrop, Typography, TextField, FormControlLabel, Checkbox, InputLabel} from "@mui/material";
import React from 'react';
import {getTrailers, updateTrailer} from '../../api/equipmenttracker/equipmentTrackerAPI';
import splitDate from '../../../../shared/splitDate';
import {editData} from '../../trailers';
import {MobileBoxStyle} from '../../../../shared/styles';

interface IProps{
    locationOptions:any
    closeEquipmentMoveForm:any;
    clickedItem:any;
    clickedLocation:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
    trailer_list:any[];
    loaded:boolean;
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }
  var edit_trailer:any = {description:'', unit_number:'', serial_number:'', active:false, license_number:'', last_inspection_date:'', last_service_date:'', year:0};
var disableForm:boolean = false;
var temp_drivers:any[] = [];
var old_edit_unit_number:string = "";
var grid_xs_width:number = 4;

export default class EditTrailersForm extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[],trailer_list:[],loaded:false};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    closePopup(){
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'description':
                edit_trailer.description = event.target.value;
                break;
            case 'unit_number':
                edit_trailer.unit_number = event.target.value;
                break;
            case 'serial_number':
                edit_trailer.serial_number = event.target.value;
                break;
            case 'license_number':
                edit_trailer.license_number = event.target.value;
                break;
            case 'year':
                edit_trailer.year = event.target.value;
                break;
            case 'last_inspection_date':
                edit_trailer.last_inspection_date = event.target.value;
                break;
            case 'last_service_date':
                edit_trailer.last_service_date = event.target.value;
                break;
            case 'active':
                edit_trailer.active = !edit_trailer.active;
                break;
        }
        this.setState({});
    }
    async onSave(){
      var isUnique = true;
        if(edit_trailer.unit_number != ''){
          this.state.trailer_list.map((trailer:any) => {
            if((old_edit_unit_number.toLowerCase().trim() != trailer.unit_Number.toLowerCase().trim()) && (edit_trailer.unit_number.toLowerCase().trim() === trailer.unit_Number.toLowerCase().trim())){
              isUnique = false;
            }
          });
          if(isUnique){
            var res:any = await updateTrailer(edit_trailer);
            if(res.status == 200){
                this.props.closeEquipmentMoveForm();
            }
            else{
              alert("Error updating trailer");
            }
          }
          else{
            alert("This unit number already exists");
          }
        }
        else{
            alert("Description and unit number are mandatory");
        }
    }
    async componentDidMount(){
      if(window.innerWidth <=760){
        grid_xs_width = 12;
      }
      else{
        grid_xs_width = 4;
      }
      var res:any = await getTrailers();
      old_edit_unit_number = editData.unit_Number;
      edit_trailer.id = editData.id;
      edit_trailer.description = editData.description;
      edit_trailer.unit_number = editData.unit_Number;
      edit_trailer.serial_number = editData.serial_Number;
      edit_trailer.license_number = editData.license_Number;
      edit_trailer.year = editData.year;
      edit_trailer.last_inspection_date = editData.last_Inspection_Date;
      edit_trailer.last_service_date = editData.last_Service_Date;
      edit_trailer.active = editData.active === 'Yes';
      this.setState({trailer_list:res, loaded:true});
    }
    render(){
      if(this.state.loaded){
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <=760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Updating Trailer</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled label="Trailer Config" onChange={this.handleChange} defaultValue={edit_trailer.description}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Unit Number" onChange={this.handleChange} name="unit_number" defaultValue={edit_trailer.unit_number}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Serial Number" onChange={this.handleChange} name="serial_number" defaultValue={edit_trailer.serial_number}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="License Number" onChange={this.handleChange} name="license_number" defaultValue={edit_trailer.license_number}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Year" type="number" onChange={this.handleChange} name="year" defaultValue={edit_trailer.year}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <InputLabel>Last Inspection Date</InputLabel>
                        <TextField type="date" onChange={this.handleChange} name="last_inspection_date" defaultValue={edit_trailer.last_inspection_date ? splitDate(edit_trailer.last_inspection_date):null}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <InputLabel>Last Service Date</InputLabel>
                        <TextField type="date" onChange={this.handleChange} name="last_service_date" defaultValue={edit_trailer.last_service_date ? splitDate(edit_trailer.last_service_date):null}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                            <FormControlLabel control={<Checkbox onChange={this.handleChange} name="active" checked={edit_trailer.active}/>} label="Active"></FormControlLabel>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                      </Grid></Box></Modal>
        );
                  }
                  else{
                    return null;
                  }
    }
}