import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_METER_HISTORY_BY_SOCT_ID_REQUEST {
    REQUEST = "getMeterHistoryBySOCTID/GET_METER_HISTORY_BY_SOCT_ID_REQUEST",
    SUCCESS = "getMeterHistoryBySOCTID/GET_METER_HISTORY_BY_SOCT_ID_SUCCESS",
    FAILED = "getMeterHistoryBySOCTID/GET_METER_HISTORY_BY_SOCT_ID_FAILED"
};


export interface IGetMeterHistoryBySOCTIDRequest extends ISurewayTokenRequestBody {
  request: {
    SOCT_ID: number;
  };
};


export interface IMeterHistory {
    id: number,
    date: string,
    unit_Number?: string,
    unit_ID: number,
    current_Hours: number,
    location?: string,
    sub_Location?: string,
    sub_Location_ID?: number,
    update_Location_Check?: string,
    new_Location?: string,
    new_Sub_Location?: string,
    new_Sub_Location_ID?: number,
    ecm: number,
    cancelled: string,
    comments: string,
    created_By: string,
    modified_By: string,
    created: string,
    modified: string,
    meter_Replacement: string,
    replacement_Date: string,
    meter_Reset: number,
    socT_ID?: number,
    service_Truck_ID?: number,
    latitude?: string,
    longitude?: string,
    source?: string,
    source_ID?: number,
    latest?: boolean
};
