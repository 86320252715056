import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IBulkMoveLoadAction, IBulkMoveLoadFailedAction, IBulkMoveSuccessAction } from "./bulkMoveActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IBULK_MOVE_REQUEST } from "./bulkMoveConstants";

type Actions =
    | IBulkMoveLoadAction
    | IBulkMoveSuccessAction
    | IBulkMoveLoadFailedAction
    | IFlushDataSuccessAction;

export const BulkMoveReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IBULK_MOVE_REQUEST.REQUEST:
            return loading;

        case IBULK_MOVE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IBULK_MOVE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};