import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST, IUpdateShopEquipmentRequest } from "./updateShopEquipmentsConstants";


export interface IUpdateShopEquipmentLoadAction {
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.REQUEST;
    data: IUpdateShopEquipmentRequest
}
export const updateShopEquipmentLoadAction = (data: IUpdateShopEquipmentRequest): IUpdateShopEquipmentLoadAction => ({
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IUpdateShopEquipmentSuccessAction {
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateShopEquipmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateShopEquipmentSuccessAction => ({
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS,
    message
});
export interface IUpdateShopEquipmentLoadFailedAction {
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const updateShopEquipmentLoadFailedAction = (message: string): IUpdateShopEquipmentLoadFailedAction => ({
    type: IUPDATE_SHOP_EQUIPMENT_REQUEST.FAILED,
    message
});
