import { IGET_EQUIPMENT_MOVE_REQUEST_REQUEST, IEquipmentMoveRequest, IEquipmentMoveRequestRequest } from "./getEquipmentMoveRequestConstants";

export interface IGetEquipmentMoveRequestLoadAction {
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.REQUEST;
    data: IEquipmentMoveRequestRequest
}
export const getEquipmentMoveRequestLoadAction = (data: IEquipmentMoveRequestRequest): IGetEquipmentMoveRequestLoadAction => ({
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentMoveRequestSuccessAction {
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.SUCCESS;
    list: IEquipmentMoveRequest[];
}
export const getEquipmentMoveRequestLoadSuccessAction = (list: IEquipmentMoveRequest[]): IGetEquipmentMoveRequestSuccessAction => ({
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentMoveRequestLoadFailedAction {
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.FAILED;
    message: string;
}
export const getEquipmentMoveRequestLoadFailedAction = (message: string): IGetEquipmentMoveRequestLoadFailedAction => ({
    type: IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.FAILED,
    message
});
