import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_VENDOR_ORDER_BY_ID_REQUEST, IGetVendorOrderByIDRequest, IGetVendorOrderByID } from "./getVendorOrderByIDConstants";

export interface IGetVendorOrderByIDLoadAction {
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.REQUEST;
    data: IGetVendorOrderByIDRequest
}
export const getVendorOrderByIDLoadAction = (data: IGetVendorOrderByIDRequest): IGetVendorOrderByIDLoadAction => ({
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetVendorOrderByIDSuccessAction {
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetVendorOrderByID>;
}
export const getVendorOrderByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetVendorOrderByID>): IGetVendorOrderByIDSuccessAction => ({
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetVendorOrderByIDLoadFailedAction {
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getVendorOrderByIDLoadFailedAction = (message: string): IGetVendorOrderByIDLoadFailedAction => ({
    type: IGET_VENDOR_ORDER_BY_ID_REQUEST.FAILED,
    message
});
