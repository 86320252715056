import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_LOOKUPS_REQUEST, ILookup } from "./getLookupsConstants";
import { IGetLookupsLoadAction, IGetLookupsLoadFailedAction, IGetLookupsSuccessAction, getLookupsLoadFailedAction, getLookupsLoadSuccessAction } from "./getLookupsActions";
import { IStore } from "../reducers";
import { EpicDependencies } from "../store";
import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { END_POINTS } from "../endpoints";
import { withJsonContentType } from "../epicUtils";

export const getLookupsEpic: Epic = (
    action$: ActionsObservable<IGetLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetLookupsSuccessAction | IGetLookupsLoadFailedAction> =>
    action$.ofType(IGET_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ILookup>>(
                    END_POINTS.GET_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ILookup>): IGetLookupsSuccessAction | IGetLookupsLoadFailedAction => {
                            if(response.message === "Success"){
                                return getLookupsLoadSuccessAction(response);
                            }
                            return getLookupsLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getLookupsLoadFailedAction(response.message)))
                    )
            )
        );