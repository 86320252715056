import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_VENDOR_ORDER_REQUEST, IVendorOrder } from "./getVendorOrdersConstants";
import { IGetVendorOrderLoadAction, IGetVendorOrderLoadFailedAction, IGetVendorOrderSuccessAction } from "./getVendorOrdersActions";
import { IAddVendorOrderSuccessAction } from "../addVendorOrder/addVendorOrderActions";
import { IADD_VENDOR_ORDER_REQUEST } from "../addVendorOrder/addVendorOrderConstants";
import { IUpdateVendorOrderSuccessAction } from "../updateVendorOrder/updateVendorOrderActions";
import { IUPDATE_VENDOR_ORDER_REQUEST } from "../updateVendorOrder/updateVendorOrderConstants";

type Actions =
    | IGetVendorOrderLoadAction
    | IGetVendorOrderSuccessAction
    | IGetVendorOrderLoadFailedAction
    | IUpdateVendorOrderSuccessAction
    | IAddVendorOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetVendorOrderReducer = (state: Server<SurewayAPIResponse<ById<IVendorOrder>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IVendorOrder>>> => {
    switch (action.type) {
        case IGET_VENDOR_ORDER_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_ORDER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

         case IUPDATE_VENDOR_ORDER_REQUEST.SUCCESS:
             return notLoaded;
 
         case IADD_VENDOR_ORDER_REQUEST.SUCCESS:
             return notLoaded;

        default:
            return state;
    }
};