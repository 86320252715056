import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_KANTECH_ADJUSTMENT_REQUEST } from "./addKantechAdjustmentConstants";
import { IAddKantechAdjustmentLoadAction, IAddKantechAdjustmentLoadFailedAction, IAddKantechAdjustmentSuccessAction, addKantechAdjustmentLoadFailedAction, addKantechAdjustmentLoadSuccessAction } from "./addKantechAdjustmentActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addKantechAdjustmentEpic: Epic = (
    action$: ActionsObservable<IAddKantechAdjustmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddKantechAdjustmentSuccessAction | IAddKantechAdjustmentLoadFailedAction> =>
    action$.ofType(IADD_KANTECH_ADJUSTMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SIL.KANTECH.ADD_KANTECH_ADJUSTMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddKantechAdjustmentSuccessAction | IAddKantechAdjustmentLoadFailedAction => {
                            if(response.message === "Success"){
                                return addKantechAdjustmentLoadSuccessAction(response);
                            } else {
                                return addKantechAdjustmentLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addKantechAdjustmentLoadFailedAction(response.message)))
                    )
            )
        );