import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_CONTROL_PLAN_REQUEST, IUpdateControlPlanRequest } from "./updateControlPlanConstants";


export interface IUpdateControlPlanLoadAction {
    type: IUPDATE_CONTROL_PLAN_REQUEST.REQUEST;
    data: IUpdateControlPlanRequest
}
export const updateControlPlanLoadAction = (data: IUpdateControlPlanRequest): IUpdateControlPlanLoadAction => ({
    type: IUPDATE_CONTROL_PLAN_REQUEST.REQUEST,
    data
});
export interface IUpdateControlPlanSuccessAction {
    type: IUPDATE_CONTROL_PLAN_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateControlPlanLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateControlPlanSuccessAction => ({
    type: IUPDATE_CONTROL_PLAN_REQUEST.SUCCESS,
    message
});
export interface IUpdateControlPlanLoadFailedAction {
    type: IUPDATE_CONTROL_PLAN_REQUEST.FAILED;
    message: string;
}
export const updateControlPlanLoadFailedAction = (message: string): IUpdateControlPlanLoadFailedAction => ({
    type: IUPDATE_CONTROL_PLAN_REQUEST.FAILED,
    message
});
