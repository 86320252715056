import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_FILTER_CUT_REQUEST } from "./addFilterCutConstants";
import { IAddFilterCutLoadAction, IAddFilterCutLoadFailedAction, IAddFilterCutSuccessAction, addFilterCutLoadFailedAction, addFilterCutLoadSuccessAction } from "./addFilterCutActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addFilterCutEpic: Epic = (
    action$: ActionsObservable<IAddFilterCutLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddFilterCutSuccessAction | IAddFilterCutLoadFailedAction> =>
    action$.ofType(IADD_FILTER_CUT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FILTER_CUTS.ADD_FILTER_CUT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddFilterCutSuccessAction | IAddFilterCutLoadFailedAction => {
                            if(response.message === "Success"){
                                return addFilterCutLoadSuccessAction(response);
                            } else {
                                return addFilterCutLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addFilterCutLoadFailedAction(response.message)))
                    )
            )
        );