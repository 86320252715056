import { IADD_JOB_REQUEST } from "./addJobConstants";
import { IAddJobLoadAction, IAddJobLoadFailedAction, IAddJobSuccessAction } from "./addJobActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";

type Actions =
    | IAddJobLoadAction
    | IAddJobSuccessAction
    | IAddJobLoadFailedAction
    | IFlushDataSuccessAction;

export const AddJobReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_JOB_REQUEST.REQUEST:
            return loading;

        case IADD_JOB_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_JOB_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};