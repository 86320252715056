import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_MACHINE_CONTROL_BOX_REQUEST } from "./addMachineControlBoxConstants";
import {
    IAddMachineControlBoxLoadAction, IAddMachineControlBoxLoadFailedAction, IAddMachineControlBoxSuccessAction,
    addMachineControlBoxLoadFailedAction, addMachineControlBoxLoadSuccessAction
} from "./addMachineControlBoxActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addMachineControlBoxEpic: Epic = (
    action$: ActionsObservable<IAddMachineControlBoxLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddMachineControlBoxSuccessAction | IAddMachineControlBoxLoadFailedAction> =>
    action$.ofType(IADD_MACHINE_CONTROL_BOX_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_MACHINE_BOX,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddMachineControlBoxSuccessAction | IAddMachineControlBoxLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return addMachineControlBoxLoadSuccessAction(response);
                            } else {
                                return addMachineControlBoxLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addMachineControlBoxLoadFailedAction(response.message)))
                    )
            )
        );