import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ATTACHMENT_MOVE_REQUEST } from "./updateAttachmentMoveConstants";
import { IUpdateAttachmentMoveLoadAction, IUpdateAttachmentMoveLoadFailedAction, IUpdateAttachmentMoveSuccessAction, updateAttachmentMoveLoadFailedAction, updateAttachmentMoveLoadSuccessAction } from "./updateAttachmentMoveActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateAttachmentMoveEpic: Epic = (
    action$: ActionsObservable<IUpdateAttachmentMoveLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateAttachmentMoveSuccessAction | IUpdateAttachmentMoveLoadFailedAction> =>
    action$.ofType(IUPDATE_ATTACHMENT_MOVE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.UPDATE_ATTACHMENT_MOVE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateAttachmentMoveSuccessAction | IUpdateAttachmentMoveLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateAttachmentMoveLoadSuccessAction(response);
                            } else {
                                return updateAttachmentMoveLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateAttachmentMoveLoadFailedAction(response.message)))
                    )
            )
        );