import React, { PureComponent, ReactNode, useState } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { ROUTE } from "../routes";
import { LAMenuItem } from "../shared/dropDownMenu";
import { LAIconButton } from "../shared/buttons";
import { SecondListIcon } from "../shared/icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { DARK_GREY_COLOR } from "../shared/theme";
import { LAPaperWithLessPadding } from "../shared/paper";
import { getTokenFromUrl } from "../shared/constExports";
import { IToken } from "../../redux/getToken/getTokenConstants";
import { Server, hasPayload } from "../../redux/server";
import { SurewayAPIResponse } from "../shared/publicInterfaces";
import { Menu } from "@material-ui/core";

const RepairLinesSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .current-mobile {
        background-color: ${DARK_GREY_COLOR};
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface IRepairLinesSubHeaderProps extends RouteComponentProps {
    token: Server<SurewayAPIResponse<IToken>>;
};

export const RepairLinesSubHeader: React.FC<IRepairLinesSubHeaderProps> = React.memo((props: IRepairLinesSubHeaderProps) => {

    const path = props.location.pathname;
    // console.log(props.token)

    const [admin, setAdmin] = useState<any>(null);
    const handleAdmin = (event?: any): void => admin !== null ? setAdmin(null) : setAdmin(event.currentTarget);

    const onPartsNotesTV = (): void => {
        window.open(ROUTE.EXTERNAL.REPAIRLINE_PARTS_INSTRUCTIONS, "_blank");
    }

    const onClick = (value: string): void => {
        props.history.push(value);
    };

    const onPortal = (): void => {
        window.open(ROUTE.EXTERNAL.MECHANICS, "_blank");
    };

    const onTechServices = (): void => {
        props.history.push(ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES);
    };

    const [mechanicLogs, setMechanicLogs] = useState<any>(null);
    const handleMechanicLogs = (event?: any): void => mechanicLogs !== null ? setMechanicLogs(null) : setMechanicLogs(event.currentTarget);

    const onApprovedLogs = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.APPROVED_MECHANIC_LOGS);
    };

    const onViewLogs = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.VIEW_MECHANIC_LOGS);
    };

    const onParts = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.PARTS);
    };

    const onInspection = (): void => {
        props.history.push(ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS);
    };

    const onAssignement = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_ASSIGNMENT);
    };

    const onWorkOrder = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER);
    };

    const onWorkOrderUnits = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS);
    };

    const onSmsLogs = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.SMS_LOGS);
    };

    const onPayroll = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL);
    };

    const onPayrollContractors = (): void => {
        props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS);
    };

    const isAdminURL = (path: string): boolean => {
        if (path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL) || path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS)) {
            return true;
        }
    
        return false;
    };

    return (
        <RepairLinesSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={() => onClick(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES)} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES)) ? "current" : ""}>
                            REPAIR LINE
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick(ROUTE.FIELD.REPAIR_LINE.MECHANICS)} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANICS)) ? "current" : ""}>
                            MECHANIC
                        </LAMenuItem>

                        <LAMenuItem onClick={onTechServices} className={(path.includes(ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES)) ? "current" : ""}>
                            TSI
                        </LAMenuItem>

                        <LAMenuItem onClick={handleMechanicLogs} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.APPROVED_MECHANIC_LOGS) || path.includes(ROUTE.FIELD.REPAIR_LINE.VIEW_MECHANIC_LOGS)) ? "current" : ""}>
                            MECHANIC LOGS
                        </LAMenuItem>

                        <Menu
                            anchorEl={mechanicLogs}
                            open={mechanicLogs}
                            onClose={handleMechanicLogs}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            
                            {(hasPayload(props.token) && props.token.payload.response.repairLineRole === "Manager") && 
                                <LAMenuItem onClick={onApprovedLogs}>
                                    APPROVE LOGS
                                </LAMenuItem>
                            }
                            <LAMenuItem onClick={onViewLogs}>
                                VIEW LOGS
                            </LAMenuItem>
                        </Menu>

                        <LAMenuItem onClick={onParts} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.PARTS)) ? "current" : ""}>
                            PARTS
                        </LAMenuItem>

                        <LAMenuItem onClick={onInspection} className={(path.includes(ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS)) ? "current" : ""}>
                            INSPECTION
                        </LAMenuItem>

                        <LAMenuItem onClick={onAssignement} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANIC_ASSIGNMENT)) ? "current" : ""}>
                            MECHANIC ASSIGNMENT
                        </LAMenuItem>

                        {(hasPayload(props.token) && props.token.payload.response.workorder === "Yes") &&
                            <LAMenuItem onClick={onWorkOrder} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER)) ? "current" : ""}>
                                WORK ORDER
                            </LAMenuItem>}

                        {(hasPayload(props.token) && props.token.payload.response.workorder === "Yes") &&
                            <LAMenuItem onClick={onWorkOrderUnits} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS)) ? "current" : ""}>
                                WORK ORDER UNITS
                            </LAMenuItem>}

                        <LAMenuItem onClick={onSmsLogs} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.SMS_LOGS)) ? "current" : ""}>
                            SMS LOGS
                        </LAMenuItem>

                        <LAMenuItem onClick={handleAdmin} className={isAdminURL(path) ? "current" : ""}>
                            ADMIN
                        </LAMenuItem>

                        <Menu
                            anchorEl={admin}
                            open={admin}
                            onClose={handleAdmin}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            <LAMenuItem onClick={onPartsNotesTV}>
                                PARTS NOTES TV
                            </LAMenuItem>

                            {(hasPayload(props.token) && props.token.payload.response.payrollAccess) && 
                                <LAMenuItem onClick={onPayroll}>
                                    PAYROLL - HOURLY
                                </LAMenuItem>
                            }

                            {(hasPayload(props.token) && props.token.payload.response.payrollContractorAccess) && 
                                <LAMenuItem onClick={onPayrollContractors}>
                                    PAYROLL - CONTRACTORS
                                </LAMenuItem>
                            }
                        </Menu>
                    </div>
                </p>
            </div>
        </RepairLinesSubHeaderStyles>
    );
});


interface IRepairLinesSubHeaderMobileState {
    drawer: boolean;
    admin: any;
    mechanicLogs: any;
};

export class RepairLinesSubHeaderMobile extends PureComponent<IRepairLinesSubHeaderProps, IRepairLinesSubHeaderMobileState> {

    public constructor(props: IRepairLinesSubHeaderProps) {
        super(props);
        this.state = {
            drawer: false,
            admin: null,
            mechanicLogs: null,
        };
    }

    public render(): ReactNode {

        const path = this.props.location.pathname;

        return (
            <LAPaperWithLessPadding>
                <LAIconButton
                    label="Open"
                    icon={<SecondListIcon />}
                    onClick={this.handleDrawer}
                />

                <SwipeableDrawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    open={this.state.drawer}
                    onClose={this.handleDrawer}
                    onOpen={this.handleDrawer}
                >
                    <RepairLinesSubHeaderStyles>
                        <LAGrid className="ml-3 mt-3" spacing={3}>

                            <LAGridItem xs={12} onClick={this.onRepairLine} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES)) ? "current-mobile" : ""}>
                                <span>
                                    REPAIR LINE
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onMechanic} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANICS)) ? "current-mobile" : ""}>
                                <span>
                                    MECHANIC
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onTechServices} className={(path.includes(ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES)) ? "current-mobile" : ""}>
                                <span>
                                    TECH SERVICES
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.handleMechanicLogs} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.APPROVED_MECHANIC_LOGS) || path.includes(ROUTE.FIELD.REPAIR_LINE.VIEW_MECHANIC_LOGS)) ? "current" : ""}>
                                <span>
                                    MECHANIC LOGS
                                </span>
                            </LAGridItem>

                            <Menu
                                anchorEl={this.state.mechanicLogs}
                                open={this.state.mechanicLogs}
                                onClose={this.handleMechanicLogs}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                            
                                {(hasPayload(this.props.token) && this.props.token.payload.response.repairLineRole === "Manager") && 
                                    <LAMenuItem onClick={this.onApprovedLogs}>
                                        APPROVE LOGS
                                    </LAMenuItem>
                                }
                                <LAMenuItem onClick={this.onViewLogs}>
                                    VIEW LOGS
                                </LAMenuItem>
                            </Menu>

                            <LAGridItem xs={12} onClick={this.onParts} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.PARTS)) ? "current" : ""}>
                                <span>
                                    PARTS
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onInspection} className={(path.includes(ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS)) ? "current" : ""}>
                                <span>
                                    INSPECTION
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onAssignment} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.MECHANIC_ASSIGNMENT)) ? "current" : ""}>
                                <span>
                                    MECHANIC ASSIGNMENT
                                </span>
                            </LAGridItem>

                            {(hasPayload(this.props.token) && this.props.token.payload.response.workorder === "Yes") &&
                                <LAGridItem xs={12} onClick={this.onWorkOrder} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER)) ? "current" : ""}>
                                    <span>
                                        WORK ORDER
                                    </span>
                                </LAGridItem>}
                            
                            {(hasPayload(this.props.token) && this.props.token.payload.response.workorder === "Yes") &&
                                <LAMenuItem onClick={this.onWorkOrderUnits} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS)) ? "current" : ""}>
                                    WORK ORDER UNITS
                                </LAMenuItem>}

                            <LAGridItem xs={12} onClick={this.onSmsLogs} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.SMS_LOGS)) ? "current" : ""}>
                                <span>
                                    SMS LOGS
                                </span>
                            </LAGridItem>

                            <LAMenuItem onClick={this.handleAdmin} className={""}>
                                ADMIN
                            </LAMenuItem>

                            <Menu
                                anchorEl={this.state.admin}
                                open={this.state.admin}
                                onClose={this.handleAdmin}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <LAMenuItem onClick={this.onPartsNotesTV}>
                                    PARTS NOTES TV
                                </LAMenuItem>

                                {(hasPayload(this.props.token) && this.props.token.payload.response.payrollAccess) &&
                                    <LAMenuItem onClick={this.onPayroll}>
                                        PAYROLL - HOURLY
                                    </LAMenuItem>}
                                {(hasPayload(this.props.token) && this.props.token.payload.response.payrollContractorAccess) &&
                                    <LAMenuItem onClick={this.onPayrollContractors}>
                                        PAYROLL - CONTRACTORS
                                    </LAMenuItem>}
                            </Menu>

                        </LAGrid>
                    </RepairLinesSubHeaderStyles>
                </SwipeableDrawer>
            </LAPaperWithLessPadding>
        );
    };

    private handleAdmin = (e?:any) : void => {
        const { admin} = this.state;
        this.setState({admin : admin !== null ? null : e.currentTarget});
    }
    
    private handleMechanicLogs = (e?:any) : void => {
        const { mechanicLogs } = this.state;
        this.setState({mechanicLogs : mechanicLogs !== null ? null : e.currentTarget});
    }

    private onPartsNotesTV = (): void => {
        window.open(ROUTE.EXTERNAL.REPAIRLINE_PARTS_INSTRUCTIONS, "_blank");
    };

    private onSmsLogs = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.SMS_LOGS,
            search: getTokenFromUrl(false)
        });
    };

    private onPayroll = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL,
            search: getTokenFromUrl(false)
        });
    };

    private onPayrollContractors = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS,
            search: getTokenFromUrl(false)
        });
    };

    private onPortal = (): void => {
        window.open(ROUTE.EXTERNAL.MECHANICS, "_blank");
    };

    private handleDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    private onTechServices = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES,
            search: getTokenFromUrl(false)
        });
    };

    private onApprovedLogs = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.APPROVED_MECHANIC_LOGS,
            search: getTokenFromUrl(false)
        });
    };

    private onViewLogs = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.VIEW_MECHANIC_LOGS,
            search: getTokenFromUrl(false)
        });
    };

    private onRepairLine = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES,
            search: getTokenFromUrl(false)
        });
    };

    private onAssignment = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANIC_ASSIGNMENT,
            search: getTokenFromUrl(false)
        });
    };

    private onWorkOrder = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER,
            search: getTokenFromUrl(false)
        });
    };
    
    private onWorkOrderUnits = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS,
            search: getTokenFromUrl(false)
        });
    };

    private onParts = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.PARTS,
            search: getTokenFromUrl(false)
        });
    };

    private onInspection = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS,
            search: getTokenFromUrl(false)
        });
    };

    private onMechanic = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANICS,
            search: getTokenFromUrl(false)
        });
    };

}