import styled from 'styled-components';

const LAExternalPSIResponseSuccessStyles = styled.div`
    // padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
`;
const ExternalPSIResponseSuccess = () => {
  return <LAExternalPSIResponseSuccessStyles>
        <div className='text-center'>
            <strong >Thank you for complying with the form. Your confirmation is recorded. You may close the browser.</strong>
        </div>
    </LAExternalPSIResponseSuccessStyles>
}

export default ExternalPSIResponseSuccess