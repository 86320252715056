import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading, { LACenteredLoading } from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { getVendorOrderLoadAction } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersActions";
import { IGetVendorOrderRequest, IVendorOrder, IVendorOrderWork } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersConstants";
import { getVendorOrder } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersAccessor";
import { IGetVendorItemsByOrderIDRequest } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDConstants";
import { getVendorItemsByOrderID } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDAccessor";
import { getVendorItemsByOrderIDLoadAction } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDActions";
import { LAPopover } from "../../shared/popOver";
import { Navbar, VendorsMenu } from "../../shared/navbars";

interface IVendorOrdersStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getVendorOrders: Server<SurewayAPIResponse<ById<IVendorOrder>>>;
    getVendorItemsByOrderID: Server<SurewayAPIResponse<IVendorOrderWork[]>>;
};

interface IVendorOrdersDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getVendorOrdersRequest: (data: IGetVendorOrderRequest) => unknown;
    getVendorItemsByOrderIDRequest: (request: IGetVendorItemsByOrderIDRequest) => unknown;
};


interface IVendorOrdersOwnProps {

};

interface IVendorOrdersState {
    // view: string;
    poLogsPopup: boolean;
};

const VendorOrdersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IVendorOrdersProps = RouteComponentProps
    & IVendorOrdersStoreProps
    & IVendorOrdersDispatchProps
    & IVendorOrdersOwnProps;

class VendorOrders extends PureComponent<IVendorOrdersProps, IVendorOrdersState> {

    public constructor(props: IVendorOrdersProps) {
        super(props);
        this.state = {
            // view: "Active",
            poLogsPopup: false
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IVendorOrdersProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        // const { view } = this.state;
        const { getToken, getVendorOrders, getVendorItemsByOrderID } = this.props;
        const { poLogsPopup } = this.state;
        const getRole = pageAccessCheck(getToken, "vendorAccess");
        const data = hasPayload(getVendorOrders) ? Object.values(getVendorOrders.payload.response) : [];
        const poLogsPopupDatas = hasPayload(getVendorItemsByOrderID) ? getVendorItemsByOrderID.payload.response : [];

        return (
            <PageSpacing title="Vendor PO" description="FIELD - VENDOR PO" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={VendorsMenu} role={true}/>
                {(getRole !== NotApplicable) && <>
                  
                    <VendorOrdersStyles>

                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>VENDOR PO</h2><br />
                                <hr />

                                {getVendorOrders.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Vendor Logs..." />}
                                {getVendorOrders.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Vendor Logs..." />}

                                {getVendorOrders.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        {/* 
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Active" control={<Radio />} label="Today" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem> */}

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                onCustomClick={this.handleCustomClick}
                                                paging={50}
                                                id="VendorOrders"
                                                customRowColor={true}
                                                exportFileName="VendorOrders"
                                                getRefreshDataCall={this.getDataForTable}
                                                storageKey={"vendor-logs-datagrid"}
                                                columns={[
                                                    { name: "vendor", caption: "Vendor", type: "string" },
                                                    { name: "po", caption: "PO", type: "string" },
                                                    { name: "requested_By_Name", caption: "Requested By", type: "string" },
                                                    { name: "date", caption: "Date", type: "date" },
                                                    { name: "job_No", caption: "Job", type: "string" },
                                                    { name: "po_Logs", caption: "PO Logs", type: "button" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>

                        {poLogsPopup && <POLogsPopup
                            data={poLogsPopupDatas}
                            open={poLogsPopup}
                            display={""}
                            status={getVendorItemsByOrderID.kind}
                            onClose={this.handlePOLogsPopupClose}
                        //   onOpenDefectItem={this.onOpenDefectItem}
                        />}

                    </VendorOrdersStyles>
                </>}
            </PageSpacing>
        );
    }

    private handleCustomClick = (name: string, e: any): void => {
        const data: IVendorOrder = e.row.data;
        if(name === "po_Logs") {
            this.handlePOLogs(data);
        }
    }

    private handlePOLogs = async (data:IVendorOrder): Promise<void> => {
        if(data.id > 0) {
            if (hasPayload(this.props.getToken)) {
                await this.props.getVendorItemsByOrderIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: data.id,
                    }
                });
            }
            this.setState({ poLogsPopup: true });
        } 
    };

    private handlePOLogsPopupClose = (): void => {
        // this.setState({ defectItemsPopUpData: undefined });
        this.setState({ poLogsPopup: false });
    };

    // private onViewClick = (e:any): void => {
    //     this.setState({ view: e.target.value });
    //     this.getDataForTable(e.target.value);
    // };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.VENDOR.VENDOR_ORDER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.VENDOR.VENDOR_ORDER
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "vendorAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getVendorOrders)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (view?: string): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getVendorOrdersRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IVendorOrdersStoreProps => ({
    getToken: getToken(state),
    getVendorOrders: getVendorOrder(state),
    getVendorItemsByOrderID: getVendorItemsByOrderID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IVendorOrdersDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getVendorOrdersRequest: (data: IGetVendorOrderRequest) => dispatch(getVendorOrderLoadAction(data)),
    getVendorItemsByOrderIDRequest: (request: IGetVendorItemsByOrderIDRequest): unknown => dispatch(getVendorItemsByOrderIDLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorOrders);

interface IPOLogsPopup {
    open: boolean;
    data: IVendorOrderWork[];
    status: STATUS_ENUM;
    onClose: () => void;
    display: string | undefined;
};

export const POLogsPopup: React.FC<IPOLogsPopup> = React.memo((props: IPOLogsPopup) => {

    // console.log(props.data)
    return (
        <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
            <LAPaperWithPadding>

                <div className="text-center">

                    <strong>
                        Vendor PO Logs 
                        {/* <span>
                            {props.display}
                        </span> */}
                    </strong>

                    <hr />
                </div>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Order Items..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                <LADevExtremeGrid
                    height={800}
                    id="repairLineList"
                    searchPanel={true}
                    filterHeader={true}
                    onClick={undefinedFunction}
                    data={props.data ?? []}
                    // onCustomIconClick={props.onOpenDefectItem}
                    // onCustomClick={onCustomClick}
                    columnChoose={true}
                    columnsHiding={true}
                    export={true}
                    removeStyleBtn={true}
                    customRowColor={true}
                    exportFileName="VendorPo-Logs"
                    storageKey="vendorPOLogsPopupSessionKey"
                    columns={[
                        // { name: "defect_Item", caption: "Defect Item", type: "link" },
                        { name: "scope", caption: "Scope", type: "string" },
                        { name: "description", caption: "Description", type: "string" },
                        { name: "cost_Code", caption: "Cost Code", type: "string" },
                        { name: "ticket", caption: "Ticket", type: "string" },
                        { name: "invoice", caption: "Invocie", type: "string" },
                        { name: "leM_No", caption: "LEM No.", type: "string" },
                        { name: "in_CDMS", caption: "In CDMS", type: "string", },
                        { name: "in_Explorer", caption: "In Explorer", type: "string", },
                        { name: "committed_Amount", caption: "Committed Amount", type: "number" },
                        { name: "invoiced_Amount", caption: "Invoiced Amount", type: "number" },
                        { name: "accrued_Cost", caption: "Accrued Cost", type: "string" },
                        { name: "accrued_Revenue", caption: "Accrued Revenue", type: "string" },
                        { name: "rog", caption: "ROG", type: "string" },
                        { name: "roG_Date", caption: "ROG Date", type: "date" },
                        { name: "created", caption: "Created", type: "datetime" },
                        { name: "modified", caption: "Modified", type: "datetime" },
                        { name: "created_By", caption: "Created By", type: "string", show: false },
                        { name: "modified_By", caption: "Modified By", type: "string", show: false },
                    ]}
                />
            </LAPaperWithPadding>
        </LAPopover>
)});