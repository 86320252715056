
export enum IGET_CLEANUP_DATA_REQUEST {
    REQUEST = "getCleanupData/GET_CLEANUP_DATA_REQUEST",
    SUCCESS = "getCleanupData/GET_CLEANUP_DATA_SUCCESS",
    FAILED = "getCleanupData/GET_CLEANUP_DATA_FAILED"
};

export interface IGetCleanupDataRequest {
    token: string;
    request: {
        department_id: number,
        page: "Hazards" | "Tasks" | "Control Plans" 
    }
};

export interface IGetCleanupData {
    payload: ICleanupData[];
    
};

export interface ICleanupData {
    id: number;
    name?: string;
    type?: string;
    hazard?: string;
    control_Plan?: string;
}