import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_METER_READING_BY_ID_REQUEST, IGetMeterReadingByIdRequest, IMeterReading } from "./getMeterReadingByIdConstants";

export interface IGetMeterReadingByIdLoadAction {
    type: IGET_METER_READING_BY_ID_REQUEST.REQUEST;
    data: IGetMeterReadingByIdRequest
}
export const getMeterReadingByIdLoadAction = (data: IGetMeterReadingByIdRequest): IGetMeterReadingByIdLoadAction => ({
    type: IGET_METER_READING_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetMeterReadingByIdSuccessAction {
    type: IGET_METER_READING_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IMeterReading>;
}
export const getMeterReadingByIdLoadSuccessAction = (list: SurewayAPIResponse<IMeterReading>): IGetMeterReadingByIdSuccessAction => ({
    type: IGET_METER_READING_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetMeterReadingByIdLoadFailedAction {
    type: IGET_METER_READING_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getMeterReadingByIdLoadFailedAction = (message: string): IGetMeterReadingByIdLoadFailedAction => ({
    type: IGET_METER_READING_BY_ID_REQUEST.FAILED,
    message
});
