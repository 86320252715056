
export enum IADD_MACHINE_CONTROL_BOX_REQUEST {
    REQUEST = "addMachineControlBox/ADD_MACHINE_CONTROL_BOX_REQUEST",
    SUCCESS = "addMachineControlBox/ADD_MACHINE_CONTROL_BOX_SUCCESS",
    FAILED = "addMachineControlBox/ADD_MACHINE_CONTROL_BOX_FAILED"
};

export interface IAddUpdateMachineControlBoxRequest {
    token: string;
    request: IAddUpdateMachineBox;
};

export interface IAddUpdateMachineBox {
    Id: number;
    Serial_No: string;
    Make: string;
    Model: string;
    Version: number;
    Notes: string;
    Auto_Dozer: string;
    Auto_Grader: string;
    Auto_Excavator: string;
    Created?: string;
    Modified?: string;
    Created_By: string;
    Modified_By: string;
};