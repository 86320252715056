import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_REPAIR_LINE_WO_UNIT_REQUEST, IAddRepairLineWOUnitRequest } from "./addRepairLineWOUnitConstants";


export interface IAddRepairLineWOUnitLoadAction {
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.REQUEST;
    data: IAddRepairLineWOUnitRequest
}
export const addRepairLineWOUnitLoadAction = (data: IAddRepairLineWOUnitRequest): IAddRepairLineWOUnitLoadAction => ({
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.REQUEST,
    data
});
export interface IAddRepairLineWOUnitSuccessAction {
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addRepairLineWOUnitLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddRepairLineWOUnitSuccessAction => ({
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS,
    message
});
export interface IAddRepairLineWOUnitLoadFailedAction {
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.FAILED;
    message: string;
}
export const addRepairLineWOUnitLoadFailedAction = (message: string): IAddRepairLineWOUnitLoadFailedAction => ({
    type: IADD_REPAIR_LINE_WO_UNIT_REQUEST.FAILED,
    message
});
