import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { IBulkMeterReading, IBulkMeterReadingsRequest } from "../../../../redux/field/soct/getBulkMeterReadings/getBulkMeterReadingsConstants";
import { getBulkMeterReadings } from "../../../../redux/field/soct/getBulkMeterReadings/getBulkMeterReadingsAccessor";
import { getBulkMeterReadingsLoadAction } from "../../../../redux/field/soct/getBulkMeterReadings/getBulkMeterReadingsActions";
import SOCTHome from "../socthome";
import { Navbar, SOCTNavbarMenu } from "../../../shared/navbars";

interface IBulkMeterAdjustmentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getBulkMeterReadings: Server<SurewayAPIResponse<ById<IBulkMeterReading>>>;
};

interface IBulkMeterAdjustmentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getBulkMeterReadingsRequest: (data: IBulkMeterReadingsRequest) => unknown;
};


interface IBulkMeterAdjustmentOwnProps {

};

interface IBulkMeterAdjustmentState {

};

const BulkMeterAdjustmentStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IBulkMeterAdjustmentProps = RouteComponentProps
    & IBulkMeterAdjustmentStoreProps
    & IBulkMeterAdjustmentDispatchProps
    & IBulkMeterAdjustmentOwnProps;

class BulkMeterAdjustment extends PureComponent<IBulkMeterAdjustmentProps, IBulkMeterAdjustmentState> {

    public constructor(props: IBulkMeterAdjustmentProps) {
        super(props);
        this.state = {

        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IBulkMeterAdjustmentProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getBulkMeterReadings } = this.props;
        const data = hasPayload(getBulkMeterReadings) ? Object.values(getBulkMeterReadings.payload.response) : [];
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        // console.log(data);

        return (
            <PageSpacing title="Bulk Meter Adjustment" description="FIELD - Bulk Meter Adjustment" fixedSpaceOnSmallerScreens={true}>
                {/* {(getRole !== NotApplicable) &&  */}

                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                <BulkMeterAdjustmentStyles>
                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">Bulk Meter Adjustment</h2>
                                <hr />

                                {getBulkMeterReadings.kind === STATUS_ENUM.LOADING && <LALoading message="Loading SOCT Bulk Meter Readings..." />}
                                {getBulkMeterReadings.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load SOCT Bulk Meter Readings..." />}

                                {getBulkMeterReadings.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="bulkMeterAdjustmentList"
                                                customRowColor={true}
                                                exportFileName="BulkMeterAdjustment"
                                                storageKey="bulkMeterAdjustmentListSessionKey"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "unit_Number", caption: "Unit Number", type: "string" },
                                                    { name: "date", caption: "Date", type: "date" },
                                                    { name: "current_Hours", caption: "Current Hours", type: "number" },                                                   
                                                    { name: "location", caption: "Location", type: "string" },
                                                    { name: "sub_Location", caption: "Sub Location", type: "string" },
                                                    { name: "ecm", caption: "ECM", type: "string" },
                                                    { name: "cancelled", caption: "Cancelled", type: "string" },
                                                    { name: "comments", caption: "Comments", type: "string" },
                                                    { name: "source", caption: "Source", type: "string" },
                                                    { name: "source_ID", caption: "Source ID", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime"},
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                }
                            </LAGridItem>
                        </LAGrid>
                    </LAPaperWithPadding>
                </BulkMeterAdjustmentStyles>
                {/* } */}
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.SOCT.BULK_METER_ADJUSTMENT.ADD_UPDATE_BULK_METER_READING
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.SOCT.BULK_METER_ADJUSTMENT.ADD_UPDATE_BULK_METER_READING
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                if (isNotLoaded(this.props.getBulkMeterReadings)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getBulkMeterReadingsRequest({
                token: this.props.getToken.payload.response.token,
            });
        };
    };
}

const mapStateToProps = (state: IStore): IBulkMeterAdjustmentStoreProps => ({
    getToken: getToken(state),
    getBulkMeterReadings: getBulkMeterReadings(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IBulkMeterAdjustmentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getBulkMeterReadingsRequest: (data: IBulkMeterReadingsRequest) => dispatch(getBulkMeterReadingsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkMeterAdjustment);