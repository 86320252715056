
export enum IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST {
    REQUEST = "getFormResponseVersionHistoryByID/GET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST",
    SUCCESS = "getFormResponseVersionHistoryByID/GET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_SUCCESS",
    FAILED = "getFormResponseVersionHistoryByID/GET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_FAILED"
};

export interface IGetFormResponseVersionHistoryByIDRequest {
    request: {
        id: number;
    },
    token: string;
};

export interface IGetFormResponseVersionHistoryByID {
    id: number;
    itemID: number;
    form_ID: number;
    emp_ID: number;
    wsS_Log_ID: number;
    name: string;
    tool_Box_Meeting: string;
    signature: string;
    additional_Images: string;
    crew_Signature_Sign_In_Upload: string;
    working_Site: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;
};