
export enum IGET_MOVE_REQUEST_REQUEST {
    REQUEST = "getMoveRequest/GET_MOVE_REQUEST_REQUEST",
    SUCCESS = "getMoveRequest/GET_MOVE_REQUEST_SUCCESS",
    FAILED = "getMoveRequest/GET_MOVE_REQUEST_FAILED"
};

export interface IMoveRequestRequest {
    token: string;
    request: {
        View: string;
    };
};

export interface IMoveRequest {
    roW_ID: number;
    from_Location: string;
    to_Location: string;
    status: string;
    completed_Date: string;
    equipment_ID: number;
    send_Email: string;
    comments: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    trailer_Config: string;
    driver_ID: string;
    trailer_ID: string;
    pilot: string;
    tri_Drive: string;
    hours: string;
    city_Permit: string;
    city_Permit_Cost: string;
    gov_Permit: string;
    gov_Permit_Cost: number;
    f_A: string;
    commodity: string;
    cancelled: string;
    modified_Notes: string;
    driver: string;
    dispatch_Notes: string;
    total_Cost: number;
    trailer_Rate: string;
    custom_Unit_Number: string;
    from_Location_ID: number;
    to_Location_ID: number;
    from_Sub_Location_ID: number;
    to_Sub_Location_ID: number;
    assigned_Tractor_ID: string;
    drive_Over: string;
    from_Sub_Location_Name: string;
    to_Sub_Location_Name: string;
    from_Job_No: string;
    to_Job_No: string;
    driver_Firstname: string;
    driver_Lastname: string;
    trailer_Unit_Number: string;
    trailer_Rate_ID: string;
    current_Trailer_Rate: string;
    trailer_ARA_Rate: string;
    trailer_Description: string;
    unit_Number: string;
    job: string;
    id: number;
    from_Sub_Location: string;
    to_Sub_Location: string;
    make: string;
    model: string;
    from_Location_Info: string;
    to_Location_Info: string;
    details: string;
    combined_Unit_Number: string;
    combined_Unit_Info: string;
    pilot_Names: string;
};