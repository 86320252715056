import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_VENDOR_LOOKUPS_REQUEST, IVendorLookup } from "./getVendorLookupsConstants";
import { IGetVendorLookupsLoadAction, IGetVendorLookupsLoadFailedAction, IGetVendorLookupsSuccessAction } from "./getVendorLookupsActions";

type Actions =
    | IGetVendorLookupsLoadAction
    | IGetVendorLookupsSuccessAction
    | IGetVendorLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetVendorLookupsReducer = (state: Server<SurewayAPIResponse<IVendorLookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IVendorLookup>> => {
    switch (action.type) {
        case IGET_VENDOR_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};