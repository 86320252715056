import { IADD_SERVICE_DUE_REQUEST } from "./addServiceDueConstants";
import { IAddServiceDueLoadAction, IAddServiceDueLoadFailedAction, IAddServiceDueSuccessAction } from "./addServiceDueActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IAddServiceDueLoadAction
    | IAddServiceDueSuccessAction
    | IAddServiceDueLoadFailedAction
    | IFlushDataSuccessAction;

export const AddServiceDueReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_SERVICE_DUE_REQUEST.REQUEST:
            return loading;

        case IADD_SERVICE_DUE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SERVICE_DUE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};