
export enum IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST {
    REQUEST = "getDriverAssignmentVersionHistory/GET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST",
    SUCCESS = "getDriverAssignmentVersionHistory/GET_DRIVER_ASSIGNMENT_VERSION_HISTORY_SUCCESS",
    FAILED = "getDriverAssignmentVersionHistory/GET_DRIVER_ASSIGNMENT_VERSION_HISTORY_FAILED"
};

export interface IGetDriverAssignmentVersionHistoryRequest {
    request: {
        ID: number;
    };
    token: string;
};

export interface IGetDriverAssignmentVersionHistory {
    id: number;
    service_Truck_ID: number;
    sub_Site_ID: number;
    date: string;
    status: string;
    created: string;
    created_By: string;
    notes: string;
    modified: string;
    modified_By: string;
    site_Info: string;
    unit_No: string;
    driver: string;
    swamper: string;
    eta: string;
    arrival_Time: string;
    departed_Time: string;
};