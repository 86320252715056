export enum IGET_EQUIPMENT_REQUEST {
    REQUEST = "getEquipment/GET_EQUIPMENT_REQUEST",
    SUCCESS = "getEquipment/GET_EQUIPMENT_SUCCESS",
    FAILED = "getEquipment/GET_EQUIPMENT_FAILED"
};

export interface IGetEquipmentRequest {
    token: string;
    request: {
        view: string;
    }
};
export interface IGetEquipment {
    id: number;
    socT_ID?: number;
    equipment: string;
    equipmentCode?: string;
    equipment_Type?: string;
    owner: string;
    serial_Number?: string;
    quick_Coupler_Size?: string;
    details?: string;
    status?: string;
    year?: number;
    driver?: string;
    job_No : string;
    location?: string;
    sub_Location?: string;
    plate?: string;
    make: string;
    model: string;
    unit_Number?: string;
    unit_Display?: string;
    // created_By: string;
    // modified_By: string;
    // created: string;
    // modified: string;
    rental:string;
    registration_Certificate: string;
    service_Truck: string;
    move_Status: string;
    repair_Status: string;
    unit_History: string;
    working_Site_ID?: number;
    working_Site_Sub_ID?: number;
    default_Image?: string;
    images?: string;
    pictures: IShopEquipmentPicture[];
    unit_Attachments?: string;
    repair_Status_ID?: number;
    move_Status_ID?: number;
    utilization?: number;
}

// export interface IShopEquipment extends IShopEquipmentFields {
//     id: number;
//     attachments?: string;
//     field_Attachments?: string;
// };

// export interface IShopEquipmentFile {
//     name: string;
//     base64String: string;
// };

export interface IShopEquipmentPicture {
    name: string;
    description: string;
    base64String: string;
    default?: string;
};

// export interface IShopEquipmentFields {
//     id: number;
//     socT_ID?: number;
//     equipment: string;
//     equipment_Type?: string;
//     owner: string;
//     serial_Number?: string;
//     second_Serial_Number?: string;
//     quick_Coupler_Size?: string;
//     details?: string;
//     tire_Size?: string;
//     status?: string;
//     year?: number;
//     driver?: string;
//     add_To_SOCT?: string;
//     job_No : string;
//     location?: string;
//     sub_Location?: string;
//     driver_Comments?: string;
//     driver_End_Date?: string;
//     driver_Owned_By?: string;
//     driver_Start_Date?: string;
//     reading_Type?: string;
//     gl?: number;
//     current_Hours?: string;
//     class?: string;
//     positive_Air?: string;
//     gpS_Level?: string;
//     gpS_Grade?: string;
//     plate?: string;
//     plated?: string;
//     cert_Req?: string;
//     truck_Trailer_Due?: string;
//     tank_Crane_Due?: string;
//     cert_Number?: string;
//     inspection_Date?: string;
//     gvw?: number;
//     comments?: string;
//     purchased_From?: string;
//     insurance_Category?: string;
//     equipment_Details?: string;
//     swing_Grease_Depth?: string;
//     swing_Grease_Volume?: string;
//     divisions?: string;
//     model_ID: number;
//     make: string;
//     model: string;
//     certificates: ICertificate[];
//     files: IShopEquipmentFile[];
//     field_files: IShopEquipmentFile[];
//     quick_Attach_Serial?: string;
//     unit_Number?: string;
//     purchase_Date?: string;
//     purchase_Meter?: string;
//     created_By: string;
//     modified_By: string;
//     service_Hours: string;
//     created: string;
//     modified: string;
//     rental:string;
//     parts_Truck:string;
//     registration_Certificate: string;
//     service_Truck: string;
//     move_Status: string;
//     repair_Status: string;
//     unit_History: string;
//     working_Site_ID?: number;
//     working_Site_Sub_ID?: number;
//     default_Image?: string;
//     images?: string;
//     pictures: IShopEquipmentPicture[];
//     update_Location?: string;
//     New_WSS_ID?: number;
//     unit_Attachments?: string;
//     unit_Attachment_List?: IUnitAttachmentList[]
//     repair_Status_ID?: number;
//     move_Status_ID?: number;
//     utilization?: number;
//     width?: number;
//     length?: number;
//     eT_Move_Pilot_Required: string;
// };

// export interface IUnitAttachmentList {
//   attachment_No: string;
//   attachment_Unit_ID: number;
//   coupler_Design: string;
//   id: number;
//   status: string;
//   style: string;
//   type: string;
//   detachable: string;
// }

// export interface ICertificate {
//     id?: number
//     type: string;
//     due_Date: string;
//     certificate_No: string;
//     equipment_List_ID: string;
//     attachments: string;
//     files: IShopEquipmentFile[];
// };

// export const driverFormIds = ["9","38","36","37","35"];
// export const positiveAirShutoffDropdown = [{ id: "Yes", name: "Yes" }, { id: "No", name: "No" }];
// export const equipmentStatusDropdown = [
// { id: "Active", name: "Active" },
// { id: "Inactive", name: "Inactive" },
// { id: "Beyond Repair", name: "Beyond Repair" },
// { id: "Parked", name: "Parked" },
// { id: "Sold", name: "Sold" },
// { id: "UP-Dismantled", name: "UP-Dismantled" }
// ];

// export const equipmentReadingsDropdown = [    
//     { id: "ECM", name: "ECM"},
//     { id: "Meter", name: "Meter" },
//     { id: "KM", name: "KM" },
//     ];

// export interface IDynamicColumn {
//     id: number;
//     name: string;
//     orderId: number;
//     label: string;
//     type: "string" | "number" | "date" | "upload" | "dropdown" | "array";
//     dropdownData?: any[];
// };

// export const DynamicColumns: IDynamicColumn[] = [
//     {
//         id: 1,
//         name: "attachments",
//         orderId: 22,
//         label: "Attachments",
//         type: "upload"
//     },
//     {
//         id: 2,
//         name: "cert_Number",
//         orderId: 14,
//         label: "Cert Number",
//         type: "string"
//     },
//     {
//         id: 3,
//         name: "cert_Req",
//         orderId: 11,
//         label: "Cert Req",
//         type: "string"
//     },
//     {
//         id: 4,
//         name: "class",
//         orderId: 4,
//         label: "Class",
//         type: "string"
//     },
//     {
//         id: 8,
//         name: "divisions",
//         orderId: 20,
//         label: "Divisions",
//         type: "string"
//     },
//     {
//         id: 10,
//         name: "gl",
//         orderId: 2,
//         label: "GL",
//         type: "string"
//     },
//     {
//         id: 11,
//         name: "gpS_Grade",
//         orderId: 8,
//         label: "GPS Grade",
//         type: "string"
//     },
//     {
//         id: 12,
//         name: "gpS_Level",
//         orderId: 7,
//         label: "GPS Level",
//         type: "string"
//     },
//     {
//         id: 13,
//         name: "gvw",
//         orderId: 16,
//         label: "GVW",
//         type: "string"
//     },
//     {
//         id: 14,
//         name: "inspection_Date",
//         orderId: 15,
//         label: "Inspection Date",
//         type: "date"
//     },
//     {
//         id: 15,
//         name: "insurance_Category",
//         orderId: 17,
//         label: "Insurance Category",
//         type: "string"
//     },
//     {
//         id: 19,
//         name: "plated",
//         orderId: 10,
//         label: "Plated",
//         type: "string"
//     },
//     {
//         id: 20,
//         name: "positive_Air",
//         orderId: 5,
//         label: "Positive Air",
//         type: "string"
//     },
//     {
//         id: 21,
//         name: "positive_Air_Shutoff",
//         orderId: 6,
//         label: "Positive Air Shutoff",
//         type: "dropdown",
//         dropdownData: positiveAirShutoffDropdown
//     },
//     {
//         id: 25,
//         name: "quick_Attach_Serial",
//         orderId: 21,
//         label: "Quick Attach Serial",
//         type: "string"
//     },
//     {
//         id: 28,
//         name: "swing_Grease_Depth",
//         orderId: 18,
//         label: "Swing Grease Depth",
//         type: "string"
//     },
//     {
//         id: 29,
//         name: "swing_Grease_Volume",
//         orderId: 19,
//         label: "Swing Grease Volume",
//         type: "string"
//     },
//     {
//         id: 30,
//         name: "tank_Crane_Due",
//         orderId: 13,
//         label: "Tank Crane Due",
//         type: "string"
//     },
//     {
//         id: 31,
//         name: "tire_Size",
//         orderId: 3,
//         label: "Tire Size",
//         type: "string"
//     },
//     {
//         id: 32,
//         name: "truck_Trailer_Due",
//         orderId: 12,
//         label: "Truck Trailer Due",
//         type: "date"
//     },
//     {
//         id: 9,
//         name: "Driver",
//         orderId: 12,
//         label: "Truck Trailer Due",
//         type: "date"
//     },
//     {
//         id: 38,
//         name: "Driver_Comments",
//         orderId: 0,
//         label: "Driver Comments",
//         type: "string"
//     },
//     {
//         id: 36,
//         name: "Driver_End_Date",
//         orderId: 0,
//         label: "Driver End Date",
//         type: "date"
//     },
//     {
//         id: 37,
//         name: "Driver_Owned_By",
//         orderId: 0,
//         label: "Driver Owned By",
//         type: "string"
//     },
//     {
//         id: 35,
//         name: "Driver_Start_Date",
//         orderId: 0,
//         label: "Driver Start Date",
//         type: "date"
//     },
//     {
//         id: 43,
//         name: "certificates",
//         orderId: 39,
//         label: "Certificate",
//         type: "array"
//     },
// ];


// export const CertificateTypes: { id: number, name: string }[] = [{ id: 0, name: "CVIP" },{ id: 1, name: "CVIP_6_Month" }, { id: 2, name: "Crane" }, { id: 3, name: "VK" }, { id: 4, name: "VKIP" }, { id: 5, name: "NA" },{ id: 6, name: "Registration" }];