import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_EXTERNAL_USERS_REQUEST, IExternalUser } from "./getExternalUsersConstants";
import { IGetExternalUsersLoadAction, IGetExternalUsersLoadFailedAction, IGetExternalUsersSuccessAction } from "./getExternalUsersActions";
import { IAddExternalUserSuccessAction } from "../addExternalUser/addExternalUserActions";
import { IUpdateExternalUserSuccessAction } from "../updateExternalUser/updateExternalUserActions";
import { IUPDATE_EXTERNAL_USER_REQUEST } from "../updateExternalUser/updateExternalUserConstants";
import { IADD_EXTERNAL_USER_REQUEST } from "../addExternalUser/addExternalUserConstants";

type Actions =
    | IGetExternalUsersLoadAction
    | IGetExternalUsersSuccessAction
    | IGetExternalUsersLoadFailedAction
    | IAddExternalUserSuccessAction
    | IUpdateExternalUserSuccessAction
    | IFlushDataSuccessAction;

export const GetExternalUsersReducer = (state: Server<SurewayAPIResponse<ById<IExternalUser>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IExternalUser>>> => {
    switch (action.type) {
        case IGET_EXTERNAL_USERS_REQUEST.REQUEST:
            return loading;

        case IGET_EXTERNAL_USERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EXTERNAL_USERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_EXTERNAL_USER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_EXTERNAL_USER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};