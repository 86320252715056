import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST } from "./getRepairLineDefectItemsByRepairIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetRepairLineDefectItemsByRepairIDLoadAction, IGetRepairLineDefectItemsByRepairIDLoadFailedAction, IGetRepairLineDefectItemsByRepairIDSuccessAction } from "./getRepairLineDefectItemsByRepairIDActions";
import { IRepairLineItem } from "../getRepairLines/getRepairLinesConstants";


type Actions =
    | IGetRepairLineDefectItemsByRepairIDLoadAction
    | IGetRepairLineDefectItemsByRepairIDSuccessAction
    | IGetRepairLineDefectItemsByRepairIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetRepairLineDefectItemsByRepairIDReducer = (state: Server<SurewayAPIResponse<IRepairLineItem[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IRepairLineItem[]>> => {
    switch (action.type) {
        case IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};