import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetKantechAdjustmentRequest, IKantechAdjustment } from "../../../redux/sil/Kantech/getKantechAdjustments/getKantechAdjustmentsConstants";
import { IAddKantechAdjustmentRequest } from "../../../redux/sil/Kantech/addKantechAdjustment/addKantechAdjustmentConstants";
import { IUpdateKantechAdjustmentRequest } from "../../../redux/sil/Kantech/updateKantechAdjustment/updateKantechAdjustmentConstants";
import { getKantechAdjustmentsStatus } from "../../../redux/sil/Kantech/getKantechAdjustments/getKantechAdjustmentsAccessor";
import { addKantechAdjustmentStatus } from "../../../redux/sil/Kantech/addKantechAdjustment/addKantechAdjustmentAccessor";
import { updateKantechAdjustmentStatus } from "../../../redux/sil/Kantech/updateKantechAdjustment/updateKantechAdjustmentAccessor";
import { addKantechAdjustmentLoadAction } from "../../../redux/sil/Kantech/addKantechAdjustment/addKantechAdjustmentActions";
import { updateKantechAdjustmentLoadAction } from "../../../redux/sil/Kantech/updateKantechAdjustment/updateKantechAdjustmentActions";
import { getKantechAdjustmentsLoadAction } from "../../../redux/sil/Kantech/getKantechAdjustments/getKantechAdjustmentsActions";
import { ILookup, ILookupUsers, ILookupsRequest } from "../../../redux/getLookups/getLookupsConstants";
import { getLookupsLoadAction } from "../../../redux/getLookups/getLookupsActions";
import LATextArea from "../../shared/textArea";
import LADatePicker from "../../shared/datePicker";
import { getLookup } from "../../../redux/getLookups/getLookupsAccessor";

const RequiredFields: string[] = ["userID", "punchTime", "in_Out", "punchDate"];

interface IAddUpdateKantechAdjustmentComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookupStatus: Server<SurewayAPIResponse<ILookup>>;
    addKantechAdjustmentStatus: Server<SurewayAPIResponse<string>>;
    updateKantechAdjustmentStatus: Server<SurewayAPIResponse<string>>;
    getKantechAdjustment: Server<SurewayAPIResponse<ById<IKantechAdjustment>>>;
};

interface IAddUpdateKantechAdjustmentComponentDispatchProps {
    getLookupsRequest: (data: ILookupsRequest) => unknown;
    getKantechAdjustmentsRequest: (data: IGetKantechAdjustmentRequest) => unknown
    addKantechAdjustmentRequest: (data: IAddKantechAdjustmentRequest) => unknown;
    updateKantechAdjustmentRequest: (data: IUpdateKantechAdjustmentRequest) => unknown;
};

interface IAddUpdateKantechAdjustmentOwnProps {
    id?: string;
};

interface IAddUpdateKantechAdjustmentComponentState {
    serverError: string;
    punchDate: string;
    users: ILookupUsers[];
    data: IKantechAdjustment;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateKantechAdjustmentStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    .required {
        color: ${WARNING_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateKantechAdjustmentComponentProps =
    RouteComponentProps
    & IAddUpdateKantechAdjustmentOwnProps
    & IAddUpdateKantechAdjustmentComponentStoreProps
    & IAddUpdateKantechAdjustmentComponentDispatchProps;

class AddUpdateKantechAdjustment extends PureComponent<IAddUpdateKantechAdjustmentComponentProps, IAddUpdateKantechAdjustmentComponentState> {

    public constructor(props: IAddUpdateKantechAdjustmentComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                punchTime: "",
                notes: "",
                userName: "",
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            punchDate: "",
            users: [],
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.callGetLookup();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateKantechAdjustmentComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addKantechAdjustmentStatus !== prevProps.addKantechAdjustmentStatus) {

                if (this.props.addKantechAdjustmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addKantechAdjustmentStatus.message });

                if (hasPayload(this.props.addKantechAdjustmentStatus) && this.props.addKantechAdjustmentStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateKantechAdjustmentStatus !== prevProps.updateKantechAdjustmentStatus) {

                if (this.props.updateKantechAdjustmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateKantechAdjustmentStatus.message });

                if (hasPayload(this.props.updateKantechAdjustmentStatus) && this.props.updateKantechAdjustmentStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, users, punchDate } = this.state;
        const { token, addKantechAdjustmentStatus, updateKantechAdjustmentStatus } = this.props;
        const getRole = pageAccessCheck(token, "kantechAccess");
        const disabled = getRole === ReadOnly ? true : undefined;
        const onUser = (event: unknown, value: ILookupUsers): void => this.handleUser("userID", value !== null ? value.userID.toString() : "");

        return (
            <AddUpdateKantechAdjustmentStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>KANTECH ADJUSTMENT</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={users}
                                getOptionLabel="userName"
                                autoHighlight={true}
                                onChange={onUser}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="User"
                                selectionRemove={undefinedFunction}
                                errorText={errors["userID"] ? errors["userID"].message : undefined}
                                value={data.userID && users ? users.find(q => q.userID === data.userID) : null}
                                defaultValue={data.userID && users ? users.find(q => q.userID === data.userID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                            <strong>Punch Time</strong>
                            <br />
                            <LADatePicker
                                id="punchDate"
                                disable={disabled}
                                value={punchDate}
                                maxDate={new Date().toLocaleDateString('en-US')}
                                onChange={this.handlePunchDate}
                            />
                     
                            <LADatePicker
                                id="punchTime"
                                mode="time"
                                time_24hr={true}
                                disable={disabled}
                                value={data.punchTime}
                                onChange={this.handlePunchTime}
                            />
                            <br />
                            {errors["punchTime"] || errors["punchDate"] ? <span className="required">{FIELD_VALIDATOR_ERRORS.REQUIRED}</span> : undefined}
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.in_Out ? (data.in_Out === "In" ? "In" : "Out") : null} onChange={this.onIntOut}>
                                <FormControlLabel value="In" control={<Radio />} label="In" />
                                <FormControlLabel value="Out" control={<Radio />} label="Out" />
                            </RadioGroup>
                            {errors["in_Out"] ? <span className="required">{errors["in_Out"].message}</span> : undefined}
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={6}>
                            <LATextArea
                                rowsMax={10}
                                minRows={3}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addKantechAdjustmentStatus.kind} successMessage="Kantech Adjustment has been added successfully" />
                <RequestStatus requestStatus={updateKantechAdjustmentStatus.kind} successMessage="Kantech Adjustment has been updated successfully" />
            </AddUpdateKantechAdjustmentStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {
        const { id, userID, created_By, in_Out, punchTime, notes } = this.state.data;

        if (hasPayload(this.props.token)) {

            const timeD = new Date(punchTime);
            const hours = timeD.getHours();
            const minutes = timeD.getMinutes();
            console.log(hours)
            console.log(minutes)
            const date = new Date(this.state.punchDate);
            date.setHours(hours);
            date.setMinutes(minutes);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

            if (id === 0) {
                this.props.addKantechAdjustmentRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        Notes: notes,
                        UserID: userID ?? 0,
                        In_Out: in_Out ?? "In",
                        PunchTime: date.toISOString(),
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn
                    }
                });
            } else {
                this.props.updateKantechAdjustmentRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Notes: notes,
                        UserID: userID ?? 0,
                        In_Out: in_Out ?? "In",
                        Created_By: created_By,
                        PunchTime: date.toISOString(),
                        Modified_By: this.props.token.payload.response.upn,
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };
    
    private handlePunchDate = (value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes("punchDate"))
            errors = this.errorChecker("punchDate", value, errors);
     
        this.setState({
            ...this.state,
            punchDate: new Date(value).toLocaleDateString('en-US'),
            errors
        });
    };

    private handlePunchTime = (value: string): void => {
        let errors = { ...this.state.errors };

        const currentDate = this.state.data.punchTime.length > 0 ? new Date(this.state.data.punchTime) : new Date();
        const time = value.split(":");
        currentDate.setHours(parseInt(time[0]));
        currentDate.setMinutes(parseInt(time[1]));

    if (RequiredFields.includes("punchTime"))
        errors = this.errorChecker("punchTime", value, errors);

    this.setState({
        ...this.state,
        data: {
            ...this.state.data,
            punchTime: currentDate.toISOString()
        },
        errors
    });
    };

    private onIntOut = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes("in_Out"))
            errors = this.errorChecker("in_Out", value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                in_Out: (this.state.data.in_Out === "In") ? "Out" : "In"
            },
            errors
        });
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleUser = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name) && value !== "0")
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: Number(value)
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "kantechAccess") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookupStatus)) {
                        this.callGetLookup();
                    };

                    if(hasPayload(this.props.getLookupStatus)){
                        const res = this.props.getLookupStatus.payload.response;
                        if(res.userList){
                            this.setState({
                                users: res.userList
                            });
                        }
                    };

                    if (query.id !== "0") {
                        if (hasPayload(this.props.getKantechAdjustment)) {
                            const data = this.props.getKantechAdjustment.payload.response[query.id];
                            this.setState({
                                data,
                                punchDate: new Date(data.punchTime).toLocaleDateString('en-US')
                            });
                        } else {
                            this.props.getKantechAdjustmentsRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const data: any = this.state.data;
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            if(((data[x] === undefined) || (data[x] === "")) && ((x !== "punchDate") && (x !== "punchTime")))
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        const date = new Date();

                        this.setState({ 
                            errors,
                            data: {
                                ...this.state.data,
                                punchTime: date.toISOString(),
                            },
                            punchDate: date.toLocaleDateString('en-US')
                         });
                    };

                } else {
                    this.props.history.push({
                        pathname: ROUTE.INDEX,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private callGetLookup = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Kantech_Adjustments"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateKantechAdjustmentComponentStoreProps => ({
    token: getToken(state),
    getLookupStatus: getLookup(state),
    getKantechAdjustment: getKantechAdjustmentsStatus(state),
    addKantechAdjustmentStatus: addKantechAdjustmentStatus(state),
    updateKantechAdjustmentStatus: updateKantechAdjustmentStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateKantechAdjustmentComponentDispatchProps => ({
    getLookupsRequest: (data: ILookupsRequest) => dispatch(getLookupsLoadAction(data)),
    addKantechAdjustmentRequest: (data: IAddKantechAdjustmentRequest) => dispatch(addKantechAdjustmentLoadAction(data)),
    getKantechAdjustmentsRequest: (data: IGetKantechAdjustmentRequest) => dispatch(getKantechAdjustmentsLoadAction(data)),
    updateKantechAdjustmentRequest: (data: IUpdateKantechAdjustmentRequest) => dispatch(updateKantechAdjustmentLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateKantechAdjustment);