import { IAddUpdateAttachment } from "../addAttachment/addAttachmentConstants";

export enum IUPDATE_ATTACHMENT_REQUEST {
    REQUEST = "updateAttachment/UPDATE_ATTACHMENT_REQUEST",
    SUCCESS = "updateAttachment/UPDATE_ATTACHMENT_SUCCESS",
    FAILED = "updateAttachment/UPDATE_ATTACHMENT_FAILED"
};

export interface IUpdateAttachmentRequest {
    token: string;
    request: IAddUpdateAttachment;
};