export const NEW_APP_URL = "https://apps2.sureway.ca";

export const ROUTE = {
    INDEX: "/",
    UNAUTHORIZED: "/unauthorized",
    ACCESS_DENIED: "/access-denied",
    SECURE_PDF: "/secure-pdf",
    CLOSEWINDOW:"/closewindow",
    EXTERNAL: {
        INDEX: "/external",
        MOVE_REQUEST: "/external/move-request",
        REPAIRLINE_PARTS_INSTRUCTIONS: "/external/sureway/repairline/parts_instructions",
        OPERATOR_LOGS: "/external/operator-logs",
        OPERATOR_LOG: "/external/operator-log",
        MECHANICS: "/external/mechanic/repair-lines",
        MECHANIC: "/external/mechanic/repair-line",
        ST_SCHEDULES: "/external/st-schedules",
        MESSAGES: "/external/field/messages",
        RESPONSE: "/external/field/messages/response",
        PSI: "/external/field/psi/formResponse",
        PSI_RESPSONSE_SUCCESS: "/external/field/psi/formResponse-Success",
    },
    EXTERNAL_USER: {
        EXTERNAL_USERS: "/external-user/users",
        EXTERNAL_USER: "/external-user/user",
    },
    HIRE_FORM: {
        INDEX: NEW_APP_URL + "/sureway/hr/hires",
        EXTERNAL_USERS: NEW_APP_URL + "/sureway/hr/external-users "
    },
    SIL: {
        INDEX: "/sil",
        KANTECH: {
            INDEX: "/sil/kantech",
            KANTECH_ADJUSTMENTS: "/sil/kantech/adjustments",
            KANTECH_ADJUSTMENT: "/sil/kantech/adjustment"
        }
    },
    FIELD: {
        INDEX: "/field",
        GPS_RECORD: "/field/gps-record",
        JOBS: {
            INDEX: "/field/jobs",
            JOB: () => `/field/job`,
            PITS: "/field/jobs/pits",
            JOB_PIT: () => `/field/jobs/pit`,
            MHR: "/field/jobs/mhrs",
            JOB_MHR: () => `/field/jobs/mhr`,
        },
        WORKING_SITE_LOGS: {
            INDEX: "/field/working-site-logs",
            WORKING_SITE_LOGS: "/field/working-site-logs",
            WORKING_SITE_LOG: "/field/working-site-log",
            WORKING_SITE_LOGS_ON_CALL: "/field/working-site-logs-on-call",
            FIELD_EMPLOYEES: "/field/field-employees",
            FIELD_EMPLOYEE: "/field/field-employee",
            MESSAGINGS: "/field/messagings",
            MESSAGING: "/field/messaging",
            JOURNALS: "/field/journals",
            ADD_UPDATE_JOURNAL: "/field/journels/journal",
            CREWS: "/field/crews",
            ADD_UPDATE_CREW: "/field/crews/crew"
        },
        PM_SHEETS: {
            INDEX: "/field/service-sheet-report",
            SERVICE_DUES: "/field/service-dues",
            SERVICE_DUE: "/field/service-due",
            SERVICE_SECTIONS: "/field/service-sections",
            SERVICE_SECTION: "/field/service-section",
            SERVICE_SHEET_REPORT: "/field/service-sheet-report",
            PM_SHEET_VERSION_HISTORY: "/field/PM/service-sheet-version-history",
            PM_SHEET_DETAILS_VERSION_HISTORY: "/field/PM/service-sheet-details-version-history",
        },
        REPAIR_LINE: {
            INDEX: "/field/repair-lines",
            REPAIR_LINES: "/field/repair-lines",
            REPAIR_LINE: "/field/repair-line",
            MECHANICS: "/field/mechanics",
            MECHANIC: "/field/mechanic",
            APPROVED_MECHANIC_LOGS: "/field/mechanic-logs/approveLogs",
            VIEW_MECHANIC_LOGS: "/field/mechanic-logs/viewLogs",
            MECHANIC_LOGS_PAYROLL: "/field/mechanic_logs/payroll",
            MECHANIC_LOGS_PAYROLL_REPORT: "/field/mechanic_logs/payroll_report",
            MECHANIC_LOGS_PAYROLL_SUMMARY: "/field/mechanic_logs/payroll_summary",
            MECHANIC_LOGS_PAYROLL_CONTRACTORS: "/field/mechanic_logs/payroll_contractors",
            MECHANIC_LOGS_PAYROLL_CONTRACTORS_REPORT: "/field/mechanic_logs/payroll_contractors_report",
            MECHANIC_LOGS_PAYROLL_CONTRACTORS_SUMMARY: "/field/mechanic_logs/payroll_contractors_summary",
            PARTS: "/field/parts",
            MECHANIC_ASSIGNMENT: "/field/mechanic-assignment",
            WORK_ORDER: "/field/work-order",
            WORK_ORDER_UNITS: "/field/workorder-units",
            WORK_ORDER_UNIT: "/field/workorder-unit",
            SMS_LOGS: "/field/repair-line-sms-logs",
            MECHANIC_LOGS_BY_DEFECT_ID: "/field/repair-line/defect/mechanic_logs"
        },

        VENDOR: {
            INDEX: "/sureway/field/vendor-po",
            VENDOR_ORDER: "/sureway/field/vendor-order",
            VENDOR_LOGS: "/sureway/field/vendor-logs",
            VENDOR_LOG: "/sureway/field/vendor-logs/vendor-log",
        },
        INSPECTION_FORM: {
            INSPECTION_FORMS: "/field/units/inspection_sheets",
            INSPECTION_FORM: "/field/units/inspection"
        },
        TECH_SERVICE: {
            TECH_SERVICES: "/field/tech-services",
            TECH_SERVICE: "/field/tech-service"
        },
        FILTER_CUT: {
            FILTER_CUTS: "/field/filter-cuts",
            FILTER_CUT: "/field/filter-cut"
        },
        FILTER: {
            FILTER_REPORT: "/field/filter-report",
        },
        LOCATION: {
            INDEX: "/field/locations",
            LOCATION: "/field/location",
        },
        MEDICAL_FACILITIES: {
            INDEX: "/field/medical-facilities",
            ADD_UPDATE_MEDICAL_FACILITY: "/field/medical-facilities/medical-facility"
        },
        MANUFACTURER: {
            INDEX: "/field/manufacturers",
            MANUFACTURER: "/field/manufacturer",
        },
        MODEL: {
            INDEX: "/field/models",
            MODEL: "/field/model",
        },
        EQUIPMENT: {
            INDEX: "/field/equipments",
            EQUIPMENT: "/field/equipment",
            UNIT_DETAILS: "/field/unit-details",
            EQUIPMENT_HISTORY: "/field/equipment-history",
            EQUIPMENT_MANUAL: {
                INDEX: "/field/equipment-manuals",
                ADDUPDATE_EQUIPMENT_MANUAL: "/field/equipment-manual",
            }
        },
        ATTACHMENT_LIST: {
            INDEX: "/field/attachments",
            LOCATION_VIEW: "/field/attachment-location-view",
            ATTACHMENTS: "/field/attachments",
            ATTACHMENT: "/field/attachment",
            TYPES: "/field/attachment-types",
            TYPE: "/field/attachment-type",
            COUPLERS: "/field/attachment-couplers",
            COUPLER: "/field/attachment-coupler",
            MOVE_REQUESTS: "/field/move-requests",
            MOVE_REQUEST: "/field/move-request",
            STYLES: "/field/attachment-styles",
            STYLE: "/field/attachment-style",
            MOVE_REQUEST_HISTORY: "/field/move-request-history",
            ATTACHMENT_LIST_GET: "/field/attachment-get"
        },
        EQUIPMENT_TYPE: {
            INDEX: "/field/equipment-types",
            TYPE: "/field/equipment-type",
        },
        CONFIG: {
            INDEX: "/field/config"
        },
        SOCT: {
            INDEX:"/field/soct/lists",
            SOCT_LIST: "/field/soctList",
            ADD_UPDATE_SOCT: "/field/soct",
            SOCT_REPORT: "/field/soct/report",
            SOCT_MAIN: {
                LISTCREATE:"/field/soct/lists/create",
                LISTEDIT:"/field/soct/lists/edit/id=:id",
                SERVICETABLEEDIT:"/field/soct/lists/edit/scheduledservicetable/id=:id",
                EQUIPMENTMOVESTABLEEDIT:"/field/soct/lists/edit/movestable/id=:id",
                METERSTABLEEDIT:"/field/soct/lists/edit/meterstable/id=:id",
                REPORTSTABLEEDIT:"/field/soct/lists/edit/reportstable/id=:id",
            },
            METERS: {
                INDEX:"/field/soct/meters",
                METERSCREATE:"/field/soct/meters/create",
                METERSEDIT:"/field/soct/meters/edit/id=:id",
            },
            SERVICES: {
                INDEX:"/field/soct/scheduledservice",
                SCHEDULEDSERVICECREATE:"/field/soct/scheduledservice/create",
                SCHEDULEDSERVICEEDIT:"/field/soct/scheduledservice/edit/id=:id",
                CUSTOMSERVICE:"/field/soct/customservice",
                CUSTOMSERVICECREATE:"/field/soct/customservice/create",
                CUSTOMSERVICEEDIT:"/field/soct/customservice/edit/id=:id",
            },
            EQUIPMENT_MOVES: {
                INDEX:"/field/soct/moves",
                MOVESCREATE:"/field/soct/moves/create",
                MOVESEDIT:"/field/soct/moves/edit/id=:id",
            },
            WORKING_SITES: {
                INDEX:"/field/locations",
                SITESCREATE:"/field/soct/sites/create",
                SITESEDIT:"/field/soct/sites/edit/id=:id",
            },
            TRUCKING: {
                INDEX:"/field/soct/trucking"
            },
            BULKSOCT: {
                INDEX:"/field/soct/bulksoct"
            },
            METERAPP: {
                INDEX:"/field/soct/meterapp"
            },
            BULK_METER_ADJUSTMENT : {
                INDEX: "/field/soct/bulk-meter-readings",
                ADD_UPDATE_BULK_METER_READING : "/field/soct/bulk-meter-reading"
            },
            METER_HISTORY: {
                INDEX: "/field/soct/meter-history",
                ADD_UPDATE_METER_HISTORY: "/field/soct/meter-reading",
            },
            SERVICE_HISTORY: {
                INDEX: "/field/soct/service-history",
                ADD_UPDATE_SERVICE_HISTORY: "/field/soct/scheduled-service",
            }
        },
        EQUIPMENT_TRACKER: {
            TRUCKING: {
                INDEX: NEW_APP_URL + "/sureway/field/equipment-tracker/trucking",
                MOVEREQUEST: NEW_APP_URL + "/sureway/field/equipment-tracker/moverequests",                
                MOVE_REQUEST: NEW_APP_URL + "/field/equipmenttracker/equipment-moves",
                MOVEREQUESTEDIT:NEW_APP_URL + "/field/equipmenttracker/equipmentmoves/edit/id=:id",
                LISTVIEW:NEW_APP_URL +"/sureway/field/equipment-tracker/listview",
                DRIVER_LOGS : {
                    APPROVE_LOGS: NEW_APP_URL + "/sureway/field/equipment-tracker/driver-logs/approveLogs",
                    VIEW_LOGS: NEW_APP_URL + "/sureway/field/equipment-tracker/driver-logs/viewLogs",
                },
                LIST_VIEW:NEW_APP_URL +"/sureway/field/equipment-tracker/listview",
                DISPATCH:"/field/equipmenttracker/dispatch",
                MOVEREQUESTADD:NEW_APP_URL +"/sureway/field/equipment-tracker/moverequests/moverequest?id=id",
                MOVEREQUESTADDNEW:NEW_APP_URL +"/sureway/field/equipment-tracker/moverequests/moverequest?id=id",
                MOVEREQUESTVIEWUPDATE:NEW_APP_URL +"/sureway/field/equipment-tracker/moverequests/moverequest?id=",
            },
            // TRUCKING: {
            //     INDEX:"/field/equipmenttracker/trucking",
            //     MOVEREQUEST:"/field/equipmenttracker/equipmentmoves",
            //     MOVE_REQUEST:"/field/equipmenttracker/equipment-moves",
            //     MOVEREQUESTEDIT:"/field/equipmenttracker/equipmentmoves/edit/id=:id",
            //     LISTVIEW:"/field/equipmenttracker/listview",
            //     LIST_VIEW:"/field/equipmenttracker/list-view",
            //     DISPATCH:"/field/equipmenttracker/dispatch",
            //     MOVEREQUESTADD:"/field/equipmenttracker/equipmentmoves/add/id=:id",
            //     MOVEREQUESTADDNEW:"/field/equipmenttracker/equipmentmoves/id=:id",
            // },
            DRIVERS: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/drivers",
                DRIVER:NEW_APP_URL +"/sureway/field/equipment-tracker/drivers/driver?id=0",
            },
            TRAILERS: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/trailers",
                TRAILEREDIT:NEW_APP_URL +"/sureway/field/equipment-tracker/trailers/trailer?id=id",
            },
            TRAILERRATES: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/trailerrates",
                TRAILERRATESEDIT:NEW_APP_URL +"/sureway/field/equipment-tracker/trailerrates/trailerrate?id=id",
            },
            BILLING: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/billingreports",
                BILLINGEDIT:NEW_APP_URL +"/sureway/field/equipment-tracker/billingreports/id=:id",
            },
            TRUCKINGREQUESTEMAILS: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/emails",
                TRUCKINGREQUESTEMAILSEDIT:NEW_APP_URL +"/sureway/field/equipment-tracker/emails/email?id=id",
            },
            BULKMOVES: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/bulkmoves",
            },
            QUOTES: {
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/quotes",
            },
            VERSION_HISTORY:{
                MOVE_REQUESTS:NEW_APP_URL +"/sureway/field/equipment-tracker/history/id=id",
            },
            MOVE_HISTORY_REPORT:{
                INDEX:NEW_APP_URL +"/sureway/field/equipment-tracker/movehistoryreport",
            },
        },
        DISPATCH: {
            INDEX:"/field/dispatch",
            SWAMPERS: "/field/dispatch/swampers",
            SWAMPER: "/field/dispatch/swamper",
            SERVICE_TRUCKS: "/field/dispatch/service-trucks",
            SERVICE_TRUCK: "/field/dispatch/service-truck",
            DRIVER_ASSIGNMENT: "/field/dispatch/drivers-assignment",
            DRIVER_ASSIGNMENT_HISTORY: "/field/dispatch/drivers-assignment-history",
            APPROVE_DRIVER_LOGS: "/field/dispatch/approveDriverLogs",
            VIEW_DRIVER_LOGS: "/field/dispatch/viewDriverLogs",
        },
        IMAGE_GALLERY: {
            INDEX:"/field/imagegallery",
        },
        FORMS: {
            FORM_TEMPLATE: {
                INDEX: "/field/forms",
                ADD_UPDATE_FORM_TEMPLATE: "/field/forms/form"
            },
            FORM_RESPONSE: {
                INDEX: "/field/formResponses",
                ADD_UPDATE_FORM_RESPONSE: "/field/formResponses/formResponse",
                FORM_RESPONSE_READ_ONLY: "/field/formResponses/viewResponse"
            },
            CREW: {
                INDEX: "/field/crews",
                ADD_UPDATE_CREW: "/field/crews/crew"
            },
            ADMIN: {
                TASKS: {
                    INDEX: "/field/tasks",
                    ADD_UPDATE_TASK: "/field/tasks/task"
                },
                DEPARTMENTS: {
                    INDEX: "/field/departments",
                    ADD_UPDATE_DEPARTMENT: "/field/departments/department"
                },
                HAZARD_TYPES: {
                    INDEX: "/field/hazard-types",
                    ADD_UPDATE_HAZARD_TYPE: "/field/hazard-types/hazard-type"
                },
                CONTROL_PLANS: {
                    INDEX: "/field/control-plans",
                    ADD_UPDATE_CONTROL_PLAN: "/field/control-plans/control-plan"
                },
                HAZARDS: {
                    INDEX: "/field/hazards",
                    ADD_UPDATE_HAZRAD: "/field/hazards/hazard"
                },
                HAZARDSNEW: {
                    INDEX: "/field/hazardsNew",
                    ADD_UPDATE_HAZRAD: "/field/hazardsNew/hazard"
                },
            },
            CLEAN_UP: {
                INDEX: "/field/form/clean-up",
            }
        },
        SURVEY: {
            INDEX: "/field/survey",
            MACHINE_CONTROL: {
                INDEX: "/field/survey/machine-controls",
                ADD_UPDATE_MACHINE_CONTROL: "/field/survey/machine-control",
                MACHINE_CONTROL_BOXES: "/field/survey/machine-control-boxes",
                ADD_UPDATE_MACHINE_CONTROL_BOX: "/field/survey/machine-control-box",
                MACHINE_CONTROL_RECEIVERS: "/field/survey/machine-control-receivers",
                ADD_UPDATE_MACHINE_CONTROL_RECEIVER: "/field/survey/machine-control-receiver",
            },
            ATTACHMENTS: {
                INDEX: "/field/survey/attachments",
                ADD_UPDATE_ATTACHMENT: "/field/survey/attachment",
            },
            BASE_KITS: {
                INDEX: "/field/survey/base-kits",
                ADD_UPDATE_BASE_KITS: "/field/survey/base-kit",
            },
            ROVER_KITS: {
                INDEX: "/field/survey/rover-kits",
                ADD_UPDATE_ROVER_KITS: "/field/survey/rover-kit",
            },
            REPEATER_KITS: {
                INDEX: "/field/survey/repeater-kits",
                ADD_UPDATE_REPEATER_KITS: "/field/survey/repeater-kit",
            },
            LASERS: {
                INDEX: "/field/survey/lasers",
                ADD_UPDATE_LASERS: "/field/survey/laser",
            },
            LEVELS: {
                INDEX: "/field/survey/levels",
                ADD_UPDATE_LEVELS: "/field/survey/level",
            },
        }
    },
};