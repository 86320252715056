
export enum IADD_ATTACHMENT_STYLE_REQUEST {
    REQUEST = "addAttachmentStyle/ADD_ATTACHMENT_STYLE_REQUEST",
    SUCCESS = "addAttachmentStyle/ADD_ATTACHMENT_STYLE_SUCCESS",
    FAILED = "addAttachmentStyle/ADD_ATTACHMENT_STYLE_FAILED"
};

export interface IAddAttachmentStyleRequest {
    token: string;
    request: IAddUpdateAttachmentStyle;
};

export interface IAddUpdateAttachmentStyle {
    ID?: number; //Only for Update
    Name: string;
    Active: string;
    Created_By: string;
    Modified_By: string;
}