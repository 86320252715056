import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_JOURNAL_VERSION_HISTORY_REQUEST, IGetJournalVersionHistory } from "./getJournalVersionHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetJournalVersionHistoryLoadAction, IGetJournalVersionHistoryLoadFailedAction, IGetJournalVersionHistorySuccessAction, 
    getJournalVersionHistoryLoadFailedAction, getJournalVersionHistoryLoadSuccessAction } from "./getJournalVersionHistoryActions";

export const getJournalVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetJournalVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetJournalVersionHistorySuccessAction | IGetJournalVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_JOURNAL_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetJournalVersionHistory>>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.GET_JOURNAL_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetJournalVersionHistory>>): IGetJournalVersionHistorySuccessAction => {
                            return getJournalVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getJournalVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );