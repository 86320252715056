import { IIdName, ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_JOBS_REQUEST {
    REQUEST = "getJobs/GET_JOBS_REQUEST",
    SUCCESS = "getJobs/GET_JOBS_SUCCESS",
    FAILED = "getJobs/GET_JOBS_FAILED"
};

export interface IJobsRequest extends ISurewayTokenRequestBody {

};

export interface IJob {
    id: number;
    year: string;
    type: string;
    job_No: string;
    name: string;
    client: string;
    coordinator: string;
    project_Manager: string;
    superintendent: string;
    company: string;
    active: string;
    location_Id?: number;
    location: string;
    working_Site: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;   
};

export const IJobType: IIdName[] = [{ id: 0, name: "Jobs" }, { id: 1, name: "Pits" }, { id: 0, name: "Hourly" }];

export const JobYears = (): IIdName[] => {
    const currentYear = new Date().getFullYear();
    const last = currentYear + 2;
    const first = last - 25;

    const years: IIdName[] = [{ id: 0, name: "All" }];

    for(let i = last -1; i >= first; i--){
        years.push({ id: i, name: i.toString() });
    };

    return years;
};