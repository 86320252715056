import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_EQUIPMENT_MOVE_REQUEST_REQUEST, IEquipmentMoveRequest } from "./getEquipmentMoveRequestConstants";
import { IGetEquipmentMoveRequestLoadAction, IGetEquipmentMoveRequestLoadFailedAction, IGetEquipmentMoveRequestSuccessAction } from "./getEquipmentMoveRequestActions";

type Actions =
    | IGetEquipmentMoveRequestLoadAction
    | IGetEquipmentMoveRequestSuccessAction
    | IGetEquipmentMoveRequestLoadFailedAction
    | IFlushDataSuccessAction;

export const GetEquipmentMoveRequestReducer = (state: Server<IEquipmentMoveRequest[]> = notLoaded, action: Actions): Server<IEquipmentMoveRequest[]> => {
    switch (action.type) {
        case IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.REQUEST:
            return loading;

        case IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};