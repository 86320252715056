import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_JOB_MHR_REQUEST } from "./addJobMhrConstants";
import { IAddJobMhrLoadAction, IAddJobMhrLoadFailedAction, IAddJobMhrSuccessAction, addJobMhrLoadFailedAction, addJobMhrLoadSuccessAction } from "./addJobMhrActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const addJobMhrEpic: Epic = (
    action$: ActionsObservable<IAddJobMhrLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddJobMhrSuccessAction | IAddJobMhrLoadFailedAction> =>
    action$.ofType(IADD_JOB_MHR_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.ACCOUNTING.JOBS.ADD_JOB_MHR,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddJobMhrSuccessAction | IAddJobMhrLoadFailedAction => {
                            if(response.message === "Success"){
                                return addJobMhrLoadSuccessAction(response);
                            }
                            return addJobMhrLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addJobMhrLoadFailedAction(response.message)))
                    )
            )
        );