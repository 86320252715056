import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_JOURNELS_REQUEST, IJournels } from "./getJournelsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetJournelsLoadAction, IGetJournelsLoadFailedAction, IGetJournelsSuccessAction, getJournelsLoadFailedAction, getJournelsLoadSuccessAction } from "./getJournelsActions";

export const getJournelsEpic: Epic = (
    action$: ActionsObservable<IGetJournelsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetJournelsSuccessAction | IGetJournelsLoadFailedAction> =>
    action$.ofType(IGET_JOURNELS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IJournels>>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.GET_JOURNELS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IJournels>>): IGetJournelsSuccessAction => {
                            return getJournelsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getJournelsLoadFailedAction(response.message)))
                    )
            )
        );