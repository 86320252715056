import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_PM_SHEET_REPORT_BY_ID_REQUEST, IServiceSheetReport } from "./getPMSheetReportByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetPMSheetReportByIdLoadAction, IGetPMSheetReportByIdLoadFailedAction, IGetPMSheetReportByIdSuccessAction } from "./getPMSheetReportByIdActions";


type Actions =
    | IGetPMSheetReportByIdLoadAction
    | IGetPMSheetReportByIdSuccessAction
    | IGetPMSheetReportByIdLoadFailedAction
    // | IAddPMSheetReportSuccessAction
    // | IUpdatePMSheetReportSuccessAction
    | IFlushDataSuccessAction;

export const GetPMSheetReportByIdReducer = (state: Server<SurewayAPIResponse<IServiceSheetReport>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IServiceSheetReport>> => {
    switch (action.type) {
        case IGET_PM_SHEET_REPORT_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_PM_SHEET_REPORT_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PM_SHEET_REPORT_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        // case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};