
import ReactDOM from 'react-dom';
import App, { ExternalApp } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import { getTokenFromUrl, reactVersionCheck, unSecuredRouteCheck } from './react/shared/constExports';

const DO_NOT_LOGIN = false;
// In order to run app locally comment this section (Production with Login) and uncomment section (Development without Login)
const external = unSecuredRouteCheck();
const tokenFromUrl = getTokenFromUrl(true);
reactVersionCheck();

if(external){
  ReactDOM.render(
    <ExternalApp />,
  document.getElementById('root')
  );
  reportWebVitals();
} else {
if(!tokenFromUrl){
  runWithAdal(authContext, () => {
    ReactDOM.render(
      <App />,
    document.getElementById('root')
  );
  reportWebVitals();
  }, DO_NOT_LOGIN)
} else {
  ReactDOM.render(
    <App />,
  document.getElementById('root')
  );
  reportWebVitals();
}
}



//#region Development without Login

// if(external){
//   ReactDOM.render(
//     <ExternalApp />,
//   document.getElementById('root')
//   );
//   reportWebVitals();
// } else {
// ReactDOM.render(
//   <App />,
// document.getElementById('root')
// );
// reportWebVitals();  
// }




//#endregion


