import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_KANTECH_ADJUSTMENT_REQUEST, IAddKantechAdjustmentRequest } from "./addKantechAdjustmentConstants";


export interface IAddKantechAdjustmentLoadAction {
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.REQUEST;
    data: IAddKantechAdjustmentRequest
}
export const addKantechAdjustmentLoadAction = (data: IAddKantechAdjustmentRequest): IAddKantechAdjustmentLoadAction => ({
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.REQUEST,
    data
});
export interface IAddKantechAdjustmentSuccessAction {
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addKantechAdjustmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddKantechAdjustmentSuccessAction => ({
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.SUCCESS,
    message
});
export interface IAddKantechAdjustmentLoadFailedAction {
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.FAILED;
    message: string;
}
export const addKantechAdjustmentLoadFailedAction = (message: string): IAddKantechAdjustmentLoadFailedAction => ({
    type: IADD_KANTECH_ADJUSTMENT_REQUEST.FAILED,
    message
});
