import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ET_LOOKUPS_REQUEST, IETLookups } from "./getETLookupsConstants";
import { IGetETLookupsLoadAction, IGetETLookupsLoadFailedAction, IGetETLookupsSuccessAction, getETLookupsLoadFailedAction, getETLookupsLoadSuccessAction } from "./getETLookupsActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { SurewayAPIResponse } from '../../../../react/shared/publicInterfaces';

export const getETLookupsEpic: Epic = (
    action$: ActionsObservable<IGetETLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetETLookupsSuccessAction | IGetETLookupsLoadFailedAction> =>
    action$.ofType(IGET_ET_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IETLookups>>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.GET_ET_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IETLookups>): IGetETLookupsSuccessAction => {
                            return getETLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getETLookupsLoadFailedAction(response)))
                    )
            )
        );