import  { ReactNode, PureComponent, } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton, } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, VendorsMenu } from "../../shared/navbars";
import { IGetVendorPOLogsRequest, IVendorPOLogs } from "../../../redux/field/vendors/getVendorPOLogs/getVendorPOLogsConstants";
import { getVendorPOLogs } from "../../../redux/field/vendors/getVendorPOLogs/getVendorPOLogsAccessor";
import { getVendorPOLogsLoadAction } from "../../../redux/field/vendors/getVendorPOLogs/getVendorPOLogsActions";

interface IVendorLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getVendorPOLogs: Server<SurewayAPIResponse<ById<IVendorPOLogs>>>;
};

interface IVendorLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getVendorPOLogsRequest: (data: IGetVendorPOLogsRequest) => unknown;
};


interface IVendorLogsOwnProps {

};

interface IVendorLogsState {

};

const VendorLogsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IVendorLogsProps = RouteComponentProps
    & IVendorLogsStoreProps
    & IVendorLogsDispatchProps
    & IVendorLogsOwnProps;

class VendorLogs extends PureComponent<IVendorLogsProps, IVendorLogsState> {

    public constructor(props: IVendorLogsProps) {
        super(props);
        this.state = {

        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IVendorLogsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getToken, getVendorPOLogs } = this.props;
        const getRole = pageAccessCheck(getToken, "vendorAccess");
        const data = hasPayload(getVendorPOLogs) ? Object.values(getVendorPOLogs.payload.response) : [];

        return (
            <PageSpacing title="Vendor PO Logs" description="FIELD - VENDOR PO LOGS" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={VendorsMenu} role={true}/>
                {(getRole !== NotApplicable) && <>
                    <VendorLogsStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>VENDOR PO LOGS</h2><br />
                                <hr />

                                {getVendorPOLogs.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Vendor PO Logs..." />}
                                {getVendorPOLogs.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Vendor PO Logs..." />}

                                {getVendorPOLogs.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        {/* 
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Active" control={<Radio />} label="Today" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem> */}

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={3000}
                                                // onCustomClick={this.handleCustomClick}
                                                paging={40}
                                                id="vendorPoLogs"
                                                customRowColor={true}
                                                exportFileName="VendorPoLogs"
                                                getRefreshDataCall={this.getDataForTable}
                                                storageKey={"vendor-po-logs-datagrid"}
                                                columns={[
                                                    { name: "vendor", caption: "Vendor", type: "string" },
                                                    { name: "po", caption: "PO", type: "string" },
                                                    { name: "requested_By_Name", caption: "Requested By", type: "string" },
                                                    { name: "date", caption: "Date", type: "date" },
                                                    { name: "job_No", caption: "Job", type: "string" },
                                                    { name: "scope", caption: "Scope", type: "string" },
                                                    { name: "description", caption: "Description", type: "string" },
                                                    { name: "cost_Code", caption: "Cost Code", type: "string" },
                                                    { name: "ticket", caption: "Ticket", type: "string" },
                                                    { name: "invoice", caption: "Invocie", type: "string" },
                                                    { name: "leM_No", caption: "LEM No.", type: "string" },
                                                    { name: "in_CDMS", caption: "In CDMS", type: "string", },
                                                    { name: "in_Explorer", caption: "In Explorer", type: "string", },
                                                    { name: "committed_Amount", caption: "Committed Amount", type: "number" },
                                                    { name: "invoiced_Amount", caption: "Invoiced Amount", type: "number" },
                                                    { name: "accrued_Cost", caption: "Accrued Cost", type: "string" },
                                                    { name: "accrued_Revenue", caption: "Accrued Revenue", type: "string" },
                                                    { name: "rog", caption: "ROG", type: "string" },
                                                    { name: "roG_Date", caption: "ROG Date", type: "date" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                    </VendorLogsStyles>
                </>}
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.VENDOR.VENDOR_LOG
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.VENDOR.VENDOR_LOG
        });
    };
    

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "vendorAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getVendorPOLogs)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (view?: string): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getVendorPOLogsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IVendorLogsStoreProps => ({
    getToken: getToken(state),
    getVendorPOLogs: getVendorPOLogs(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IVendorLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getVendorPOLogsRequest: (data: IGetVendorPOLogsRequest) => dispatch(getVendorPOLogsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorLogs);
