export enum IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST {
    REQUEST = "getEmployeeMessagesByToken/GET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST",
    SUCCESS = "getEmployeeMessagesByToken/GET_EMPLOYEE_MESSAGES_BY_TOKEN_SUCCESS",
    FAILED = "getEmployeeMessagesByToken/GET_EMPLOYEE_MESSAGES_BY_TOKEN_FAILED"
};

export interface IEmployeeMessageRequest {
    request: {
        id: number;
        token: string;
    }
};

export interface IEmployeeMessage {
    id: number;
    employee_ID: number;
    message: string;
    employee: string;
    comments: string;
    selected_Option: number;
    options: IMessageOption[];
};

export interface IMessageOption {
    id: number;
    option: string;
};