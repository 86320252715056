import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_TYPES_REQUEST {
    REQUEST = "getTypes/GET_TYPES_REQUEST",
    SUCCESS = "getTypes/GET_TYPES_SUCCESS",
    FAILED = "getTypes/GET_TYPES_FAILED"
};

export interface IGetTypeRequest extends ISurewayTokenRequestBody {

};

export interface IAttachmentType {
    id: number;
    name: string;
    active: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};