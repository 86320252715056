import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_FORM_RESPONSE_REQUEST, IUpdateFormResponseRequest } from "./updateFormResponseConstants";


export interface IUpdateFormResponseLoadAction {
    type: IUPDATE_FORM_RESPONSE_REQUEST.REQUEST;
    data: IUpdateFormResponseRequest
}
export const updateFormResponseLoadAction = (data: IUpdateFormResponseRequest): IUpdateFormResponseLoadAction => ({
    type: IUPDATE_FORM_RESPONSE_REQUEST.REQUEST,
    data
});
export interface IUpdateFormResponseSuccessAction {
    type: IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFormResponseLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFormResponseSuccessAction => ({
    type: IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS,
    message
});
export interface IUpdateFormResponseLoadFailedAction {
    type: IUPDATE_FORM_RESPONSE_REQUEST.FAILED;
    message: string;
}
export const updateFormResponseLoadFailedAction = (message: string): IUpdateFormResponseLoadFailedAction => ({
    type: IUPDATE_FORM_RESPONSE_REQUEST.FAILED,
    message
});
