import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ROVER_KIT_REQUEST } from "./updateRoverKitConstants";
import {
    IUpdateRoverKitLoadAction, IUpdateRoverKitLoadFailedAction, IUpdateRoverKitSuccessAction,
    updateRoverKitLoadFailedAction, updateRoverKitLoadSuccessAction
} from "./updateRoverKitActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateRoverKitEpic: Epic = (
    action$: ActionsObservable<IUpdateRoverKitLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateRoverKitSuccessAction | IUpdateRoverKitLoadFailedAction> =>
    action$.ofType(IUPDATE_ROVER_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_ROVER_KIT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateRoverKitSuccessAction | IUpdateRoverKitLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateRoverKitLoadSuccessAction(response);
                            } else {
                                return updateRoverKitLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateRoverKitLoadFailedAction(response.message)))
                    )
            )
        );