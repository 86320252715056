import React, { useState } from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACheckBox } from "../../shared/checkBox";
import { DataGrid } from "devextreme-react";
import { LAPopover } from "../../shared/popOver";
import LAAutoComplete from "../../shared/autoComplete";
import { REQUIRED } from "../../shared/textField";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { LASaveAndCancelButton } from "../../shared/buttons";
import { undefinedFunction } from "../../shared/constExports";
import { Column, Editing, Paging } from "devextreme-react/data-grid";
import { IAttachmentListGET } from "../../../redux/field/attachment/getAttachmentListGET/getAttachmentListGETConstants";
import { ICloneAttachmentGET } from "../../../redux/field/attachment/cloneAttachmentGET/cloneAttachmentGETConstants";
import { IAttachment } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
import LAErrorBox from "../../shared/errorBox";

interface IAttachmentGetClone extends IAttachmentListGET {
    cloned?: boolean;
};

interface IAttachmentGetCloneClone {
    open: boolean;
    username: string;
    onClose: () => void;
    dropdown: IAttachment[];
    data: IAttachmentGetClone[];
    serverError: string | undefined;
    onComplete: (list: ICloneAttachmentGET[]) => void;
};

export const AttachmentGetCloneClone: React.FC<IAttachmentGetCloneClone> = React.memo((props: IAttachmentGetCloneClone) => {
    const [cloneAll, setCloneAll] = useState<boolean>(true);
    const [list, setList] = useState<IAttachmentGetClone[]>([]);
    const [from, setFrom] = useState<IAttachment | undefined>(undefined);
    const [to, setTo] = useState<IAttachment | undefined>(undefined);
    const [isAnySelected, setIsAnySelected] = useState<boolean>(false);

    const onSelectAll = (): void => {
        if (cloneAll) {
            const updatedList = list.map(item => ({
                ...item,
                cloned: false
            }));
            setList(updatedList);
            setBtnDisable(true);
        } else {
            const updatedList = list.map(item => ({
                ...item,
                cloned: true
            }));
            setList(updatedList);
            setBtnDisable(false);
        }
        setCloneAll(!cloneAll);
    };

    const onChange = (updatedData: { data: any, key: number, type: string }[]): void => {
        const values = list;

        updatedData.forEach((x) => {
            const index = values.findIndex((value) => value.id === x.key);

            if (index !== -1) {
                if ((x.data.cloned !== undefined) && (x.data.cloned !== null)) {
                    values[index].cloned = x.data.cloned;
                }

                if ((x.data.quantity !== undefined) && (x.data.quantity !== null))
                    values[index].quantity = x.data.quantity;

                if ((x.data.part_No !== undefined) && (x.data.part_No !== null))
                    values[index].part_No = x.data.part_No;

                if ((x.data.description !== undefined) && (x.data.description !== null))
                    values[index].description = x.data.description;
            }
        });
        setList(values);
        setBtnDisable();
    };

    const onComplete = (): void => {
        if (to) {
            const idxList = list.filter(item => item.cloned === true)
                .map(item => ({
                    Attachment_ID: to.id,
                    Quantity: item.quantity,
                    Part_No: item.part_No,
                    Description: item.description,
                    Created_By: props.username
                }));

            props.onComplete(idxList);
        }
    };

    const onFrom = (event: unknown, value: IAttachment): void => {
        if (value !== null ? value : undefined) {
            const newList = props.data
                .filter((x) => x.attachment_No === value.attachment_No)
                .map((item) => ({ ...item, cloned: true }));

            setList(newList);
            setFrom(value !== null ? value : undefined);
        }
        else {
            setFrom(value !== null ? value : undefined);
        }
    };

    const onTo = (event: unknown, value: IAttachment): void => {
        setTo(value !== null ? value : undefined);
    };

    const setBtnDisable = (pre?: boolean): void => {
        if (pre !== undefined) {
            setIsAnySelected(pre)
        } else {
            const check = list.findIndex((x) => x.cloned && x.cloned === true);
            if (check >= 0) {
                setIsAnySelected(false);
            } else {
                setIsAnySelected(true);
            }
        }
    };

    const disableSave = !isAnySelected ? (from && to) ? (from.id !== to.id) ? (list.length > 0) ? undefined : true : true : true : true;

    return <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={0}>

                <LAGridItem xs={12} className="text-center">
                    <strong className="green-text">CLONE</strong>
                    <hr />
                    <br />

                    {props.serverError && <LAErrorBox text={props.serverError} />}
                </LAGridItem>

                <LAGridItem xs={12} sm={4} md={4}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.dropdown}
                        getOptionLabel="attachment_No"
                        autoHighlight={true}
                        onChange={onFrom}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="From"
                        selectionRemove={undefinedFunction}
                        errorText={!from ? REQUIRED : undefined}
                        value={props.dropdown && from ? props.dropdown.find(q => q.id === from.id) : null}
                        defaultValue={props.dropdown && from ? props.dropdown.find(q => q.id === from.id) : null}
                    />
                </LAGridItem>

                <LAGridItem xs={12} sm={4} md={4}>
                    <LAAutoComplete
                        multiple={false}
                        option={props.dropdown}
                        getOptionLabel="attachment_No"
                        autoHighlight={true}
                        onChange={onTo}
                        filterSelectedOptions={true}
                        dropDownPlaceHolder="To"
                        selectionRemove={undefinedFunction}
                        errorText={!to ? REQUIRED : undefined}
                        value={props.dropdown && to ? props.dropdown.find(q => q.id === to.id) : null}
                        defaultValue={props.dropdown && to ? props.dropdown.find(q => q.id === to.id) : null}
                    />
                </LAGridItem>

                <LAGridItem xs={12} sm={4} md={4}>
                    <LASaveAndCancelButton
                        onSave={onComplete}
                        saveButtonText="CLONE Selected Items"
                        cancelButtonText="CLOSE"
                        onCancel={props.onClose}
                        disableSave={disableSave}
                    />
                </LAGridItem>

                <LAGridItem xs={12} className="ml-6">
                <LACheckBox
                        label="Select All"
                        value={cloneAll}
                        onChange={onSelectAll}
                    />
                </LAGridItem>

                <LAGridItem xs={12}>
                    <DataGrid
                        keyExpr="id"
                        dataSource={list}
                        showBorders={true}
                        columnAutoWidth={true}
                    >
                        <Paging enabled={false} />
                        <Editing
                            mode="batch"
                            allowUpdating={true}
                            onChangesChange={onChange}
                        />
                        <Column dataField="cloned" caption="Select" dataType="boolean" />
                        <Column dataField="attachment_No" caption="Attachment #" dataType="string" allowEditing={false} />
                        <Column dataField="quantity" caption="Quantity" dataType="number" allowEditing={true} />
                        <Column dataField="part_No" caption="Part #" dataType="string" allowEditing={true} />
                        <Column dataField="description" caption="Description" dataType="string" allowEditing={true} />
                    </DataGrid>
                </LAGridItem>

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});