import { IUPDATE_SWAMPER_REQUEST } from "./updateSwamperConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { IUpdateSwamperLoadAction, IUpdateSwamperLoadFailedAction, IUpdateSwamperSuccessAction } from "./updateSwamperActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    | IUpdateSwamperLoadAction
    | IUpdateSwamperSuccessAction
    | IUpdateSwamperLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateSwamperReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_SWAMPER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SWAMPER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SWAMPER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};