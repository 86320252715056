
export enum IADD_MECHANIC_LOG_REQUEST {
    REQUEST = "addMechanicLog/ADD_MECHANIC_LOG_REQUEST",
    SUCCESS = "addMechanicLog/ADD_MECHANIC_LOG_SUCCESS",
    FAILED = "addMechanicLog/ADD_MECHANIC_LOG_FAILED"
};

export interface IAddMechanicLogRequest {
    token: string;
    request: IAddUpdateMechanicLog;
};

export interface IAddUpdateMechanicLog {
    ID?: number;
    Mechanic_ID: number;
    Component_ID: number;
    Date: string;
    Hours: number;
    Comments: string;
    Created_By: string;
}