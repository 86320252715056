import { IADD_JOURNEL_REQUEST } from "./addJournelConstants";
import { IAddJournelLoadAction, IAddJournelLoadFailedAction, IAddJournelSuccessAction } from "./addJournelActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IAddJournelLoadAction
    | IAddJournelSuccessAction
    | IAddJournelLoadFailedAction
    | IFlushDataSuccessAction;

export const AddJournelReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_JOURNEL_REQUEST.REQUEST:
            return loading;

        case IADD_JOURNEL_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_JOURNEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};