import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_ROVER_KIT_REQUEST, IRoverKit, IRoverKitRequest } from "./getRoverKitsConstants";

export interface IGetRoverKitLoadAction {
    type: IGET_ROVER_KIT_REQUEST.REQUEST;
    data: IRoverKitRequest
}
export const getRoverKitLoadAction = (data: IRoverKitRequest): IGetRoverKitLoadAction => ({
    type: IGET_ROVER_KIT_REQUEST.REQUEST,
    data
});
export interface IGetRoverKitSuccessAction {
    type: IGET_ROVER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IRoverKit>>;
}
export const getRoverKitLoadSuccessAction = (list: SurewayAPIResponse<ById<IRoverKit>>): IGetRoverKitSuccessAction => ({
    type: IGET_ROVER_KIT_REQUEST.SUCCESS,
    list
});
export interface IGetRoverKitLoadFailedAction {
    type: IGET_ROVER_KIT_REQUEST.FAILED;
    message: string;
}
export const getRoverKitLoadFailedAction = (message: string): IGetRoverKitLoadFailedAction => ({
    type: IGET_ROVER_KIT_REQUEST.FAILED,
    message
});
