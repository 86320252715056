import { IShopEquipmentFile } from "../getEquipments/getEquipmentConstants";

export enum IADD_EQUIPMENT_CERTIFICATE_REQUEST {
    REQUEST = "addEquipmentCertificate/ADD_EQUIPMENT_CERTIFICATE_REQUEST",
    SUCCESS = "addEquipmentCertificate/ADD_EQUIPMENT_CERTIFICATE_SUCCESS",
    FAILED = "addEquipmentCertificate/ADD_EQUIPMENT_CERTIFICATE_FAILED"
};

export interface IAddEquipmentCertificateRequest {
    token: string;
    request: IAddEquipmentCertificate;
};

export interface IAddEquipmentCertificate {
    Equipment_List_ID: number;
    Type: string;
    Due_Date: string;
    Created_By: string;
    Attachments: string;
    Certificate_No: string;
    Files: IShopEquipmentFile[];
};