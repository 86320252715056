import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST, IWSSLogsHistory } from "./getWSSLogsHistoryBySiteIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetWSSLogsHistoryBySiteIDLoadAction, IGetWSSLogsHistoryBySiteIDLoadFailedAction, IGetWSSLogsHistoryBySiteIDSuccessAction, getWSSLogsHistoryBySiteIDLoadFailedAction, getWSSLogsHistoryBySiteIDLoadSuccessAction } from "./getWSSLogsHistoryBySiteIDActions";


export const getWSSLogsHistoryBySiteIDEpic: Epic = (
    action$: ActionsObservable<IGetWSSLogsHistoryBySiteIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetWSSLogsHistoryBySiteIDSuccessAction | IGetWSSLogsHistoryBySiteIDLoadFailedAction> =>
    action$.ofType(IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IWSSLogsHistory[]>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.GET_WSS_LOGS_HISTORY_BY_SITE_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IWSSLogsHistory[]>): IGetWSSLogsHistoryBySiteIDSuccessAction => {
                            return getWSSLogsHistoryBySiteIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getWSSLogsHistoryBySiteIDLoadFailedAction(response.message)))
                    )
            )
        );