import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST, IUpdateScheduleServiceHistoryRequest } from "./updateScheduleServiceHistoryConstants";


export interface IUpdateScheduleServiceHistoryLoadAction {
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.REQUEST;
    data: IUpdateScheduleServiceHistoryRequest
}
export const updateScheduleServiceHistoryLoadAction = (data: IUpdateScheduleServiceHistoryRequest): IUpdateScheduleServiceHistoryLoadAction => ({
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.REQUEST,
    data
});
export interface IUpdateScheduleServiceHistorySuccessAction {
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateScheduleServiceHistoryLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateScheduleServiceHistorySuccessAction => ({
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.SUCCESS,
    message
});
export interface IUpdateScheduleServiceHistoryLoadFailedAction {
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.FAILED;
    message: string;
}
export const updateScheduleServiceHistoryLoadFailedAction = (message: string): IUpdateScheduleServiceHistoryLoadFailedAction => ({
    type: IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.FAILED,
    message
});
