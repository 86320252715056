import { IOptions, IResponses } from "../getForemanMessaging/getForemanMessagingConstants";

export enum IADD_FOREMAN_MESSAGING_REQUEST {
    REQUEST = "addForemanMessaging/ADD_FOREMAN_MESSAGING_REQUEST",
    SUCCESS = "addForemanMessaging/ADD_FOREMAN_MESSAGING_SUCCESS",
    FAILED = "addForemanMessaging/ADD_FOREMAN_MESSAGING_FAILED"
};

export interface IAddForemanMessagingRequest {
    token: string;
    request: IAddUpdateForemanMessaging;
};

export interface IAddUpdateForemanMessaging {
    ID?: number;
    Message: string;
    Options: IOptions[];
    Responses: IResponses[];
    Created_By: string;
    Modified_By?: string;
}