import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_REPAIR_SMS_LOGS_REQUEST, IRepairLineSmsLog } from "./getRepairLineSmsLogsConstants";
import { IADD_REPAIR_LINE_REQUEST } from "../addRepairLine/addRepairLineConstants";
import { IAddRepairLineSuccessAction } from "../addRepairLine/addRepairLineActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_REQUEST } from "../updateRepairLine/updateRepairLineConstants";
import { IUpdateRepairLineSuccessAction } from "../updateRepairLine/updateRepairLineActions";
import { IGetRepairSmsLogsLoadAction, IGetRepairSmsLogsLoadFailedAction, IGetRepairSmsLogsSuccessAction } from "./getRepairLineSmsLogsActions";
import { IUpdateRepairLineByMechanicSuccessAction } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicActions";
import { IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicConstants";
import { ISaveMechanicAssignmentSuccessAction } from "../saveMechanicAssignment/saveMechanicAssignmentActions";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "../saveMechanicAssignment/saveMechanicAssignmentConstants";
import { ISAVE_COMPONENT_WAITING_PARTS_REQUEST } from "../saveComponentWaitingParts/saveComponentWaitingPartsConstants";
import { ISaveComponentWaitingPartsSuccessAction } from "../saveComponentWaitingParts/saveComponentWaitingPartsActions";
import { IWorkOrderSuccessAction } from "../workOrder/workOrderActions";
import { IWORK_ORDER_REQUEST } from "../workOrder/workOrderConstants";

type Actions =
    | IGetRepairSmsLogsLoadAction
    | IGetRepairSmsLogsSuccessAction
    | IAddRepairLineSuccessAction
    | IUpdateRepairLineSuccessAction
    | IGetRepairSmsLogsLoadFailedAction
    | ISaveMechanicAssignmentSuccessAction
    | IUpdateRepairLineByMechanicSuccessAction
    | ISaveComponentWaitingPartsSuccessAction
    | IWorkOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetRepairSmsLogsReducer = (state: Server<SurewayAPIResponse<ById<IRepairLineSmsLog>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IRepairLineSmsLog>>> => {
    switch (action.type) {
        case IGET_REPAIR_SMS_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_SMS_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_SMS_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS:
            return notLoaded;

        case IWORK_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};