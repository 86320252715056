import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGetHazardControlPlanListByDepartmentID, IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST, IGetHazardControlPlanListByDepartmentIDRequest } from "./getHazardControlPlanListByDepartmentIDConstants";

export interface IGetHazardControlPlanListByDepartmentIDLoadAction {
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.REQUEST;
    data: IGetHazardControlPlanListByDepartmentIDRequest
}
export const getHazardControlPlanListByDepartmentIDLoadAction = (data: IGetHazardControlPlanListByDepartmentIDRequest): IGetHazardControlPlanListByDepartmentIDLoadAction => ({
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.REQUEST,
    data
});
export interface IGetHazardControlPlanListByDepartmentIDSuccessAction {
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>;
}
export const getHazardControlPlanListByDepartmentIDLoadSuccessAction = (list: SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>): IGetHazardControlPlanListByDepartmentIDSuccessAction => ({
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.SUCCESS,
    list
});
export interface IGetHazardControlPlanListByDepartmentIDLoadFailedAction {
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.FAILED;
    message: string;
}
export const getHazardControlPlanListByDepartmentIDLoadFailedAction = (message: string): IGetHazardControlPlanListByDepartmentIDLoadFailedAction => ({
    type: IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.FAILED,
    message
});
