import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, GreenOrRed, pageAccessCheck, SurveyDD, undefinedFunction, userName, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { IAddUpdateRoverKit, IAddUpdateRoverKitRequest } from "../../../redux/survey/addRoverKit/addRoverKitConstants";
import { addRoverKitLoadAction } from "../../../redux/survey/addRoverKit/addRoverKitActions";
import { updateRoverKitLoadAction } from "../../../redux/survey/updateRoverKit/updateRoverKitActions";
import LAAutoComplete from "../../shared/autoComplete";
import { IRoverKit, IRoverKitRequest } from "../../../redux/survey/getRoverKits/getRoverKitsConstants";
import { addRoverKits } from "../../../redux/survey/addRoverKit/addRoverKitAccessor";
import { getRoverKits } from "../../../redux/survey/getRoverKits/getRoverKitsAccessor";
import { updateRoverKits } from "../../../redux/survey/updateRoverKit/updateRoverKitAccessor";
import { getRoverKitLoadAction } from "../../../redux/survey/getRoverKits/getRoverKitsActions";
import { ISurveyLookup, ISurveyLookupRequest } from "../../../redux/survey/getSurveyLookup/getSurveyLookupConstants";
import { getSurveyLookupLoadAction } from "../../../redux/survey/getSurveyLookup/getSurveyLookupActions";
import { GetSurveyLookup } from "../../../redux/survey/getSurveyLookup/getSurveyLookupAccessor";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";

const RequiredFields: string[] = ["kit_No", "unit_No", "model"];

interface IAddUpdateRoverKitComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    addRoverKitStatus: Server<SurewayAPIResponse<string>>;
    updateRoverKitStatus: Server<SurewayAPIResponse<string>>;
    getRoverKitsStatus: Server<SurewayAPIResponse<ById<IRoverKit>>>;
};

interface IAddUpdateRoverKitComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRoverKitsRequest: (data: IRoverKitRequest) => unknown;
    getLookupsRequest: (data: ISurveyLookupRequest) => unknown;
    addRoverKitRequest: (data: IAddUpdateRoverKitRequest) => unknown;
    updateRoverKitRequest: (data: IAddUpdateRoverKitRequest) => unknown;
};

interface IAddUpdateRoverKitOwnProps {
    id?: string;
};

interface IAddUpdateRoverKitComponentState {
    data: IRoverKit;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateRoverKitStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .green-border {
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateRoverKitComponentProps =
    RouteComponentProps
    & IAddUpdateRoverKitOwnProps
    & IAddUpdateRoverKitComponentStoreProps
    & IAddUpdateRoverKitComponentDispatchProps;

class AddUpdateRoverKit extends PureComponent<IAddUpdateRoverKitComponentProps, IAddUpdateRoverKitComponentState> {

    public constructor(props: IAddUpdateRoverKitComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                kit_No: "",
                unit_No: "",
                model: "",
                data_Collector_Unit_No: "",
                data_Collector_Model: "",
                antenna: "",
                chargers: "",
                status: "",
                // site: "",
                wsS_ID: 0,
                notes: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateRoverKitComponentProps): void {
        if (this.props !== prevProps) {

            if ((this.props.getRoverKitsStatus !== prevProps.getRoverKitsStatus)
                || (this.props.token !== prevProps.token))
                this.setDataToState();

            if (this.props.addRoverKitStatus !== prevProps.addRoverKitStatus) {

                if (this.props.addRoverKitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRoverKitStatus.message });

                if (hasPayload(this.props.addRoverKitStatus) && this.props.addRoverKitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateRoverKitStatus !== prevProps.updateRoverKitStatus) {

                if (this.props.updateRoverKitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRoverKitStatus.message });

                if (hasPayload(this.props.updateRoverKitStatus) && this.props.updateRoverKitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " ROVER KIT";
        const { addRoverKitStatus, updateRoverKitStatus, getLookup } = this.props;

        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const operators = hasPayload(getLookup) ? getLookup.payload.response.operators : [];

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onAntenna = (event: unknown, value: IIdName): void => this.handleChange("antenna", value !== null ? value.name : data.antenna);
        const onCharges = (event: unknown, value: IIdName): void => this.handleChange("chargers", value !== null ? value.name : data.chargers);
        const onOperator = (value: number): void => this.handleOperator("operator_ID", value !== null ? value : data.operator_ID);
        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status ?? "");
        const onWSS = (value: string): void => this.handleNumber("wsS_ID", value);

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateRoverKitStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="mt-4">
                            <LATextField
                                label="Kit #"
                                fullWidth={true}
                                variant="outlined"
                                name="kit_No"
                                value={data.kit_No}
                                onChange={this.handleChange}
                                errorText={errors["kit_No"] ? errors["kit_No"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="green-border text-center mr-3 mb-3">
                            <strong>Rover</strong>
                            <LAGrid spacing={0} className="mt-1">

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Unit #"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="unit_No"
                                        onChange={this.handleChange}
                                        value={data.unit_No}
                                        errorText={errors["unit_No"] ? errors["unit_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Model"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="model"
                                        onChange={this.handleChange}
                                        value={data.model}
                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="green-border text-center mb-3">
                            <strong>Data Collector</strong>
                            <LAGrid spacing={0} className="mt-1">

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Unit #"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="data_Collector_Unit_No"
                                        onChange={this.handleChange}
                                        value={data.data_Collector_Unit_No}
                                        errorText={errors["data_Collector_Unit_No"] ? errors["data_Collector_Unit_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Model"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="data_Collector_Model"
                                        onChange={this.handleChange}
                                        value={data.data_Collector_Model}
                                        errorText={errors["data_Collector_Model"] ? errors["data_Collector_Model"].message : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onAntenna}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Antenna"
                                selectionRemove={undefinedFunction}
                                value={data.antenna ? YesOrNo.find(q => q.name === data.antenna) : null}
                                defaultValue={data.antenna ? YesOrNo.find(q => q.name === data.antenna) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onCharges}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Chargers"
                                selectionRemove={undefinedFunction}
                                value={data.chargers ? YesOrNo.find(q => q.name === data.chargers) : null}
                                defaultValue={data.chargers ? YesOrNo.find(q => q.name === data.chargers) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={SurveyDD}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onStatus}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                value={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            {/* <LATextField
                                label="Site"
                                fullWidth={true}
                                variant="outlined"
                                name="site"
                                onChange={this.handleChange}
                                value={data.site}
                                errorText={errors["site"] ? errors["site"].message : undefined}
                            /> */}
                            <DevExtremePopupLookup
                                data={wssList ?? []}
                                id="wss-search"
                                placeHolder="Site"
                                disabled={undefined}
                                displayExp="site"
                                returnExp="wsS_ID"
                                name="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                currentVal={data.wsS_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={operators}
                                id="operators"
                                placeHolder="Operator"
                                disabled={undefined}
                                displayExp="username"
                                name="id"
                                returnExp="id"
                                errorName="id"
                                errors={errors}
                                currentVal={data.operator_ID}
                                onClick={onOperator}
                                columns={[
                                    { name: "username", caption: "User Name", type: "string" },
                                    { name: "first_Name", caption: "First Name", type: "string" },
                                    { name: "last_Name", caption: "Last Name", type: "string" },
                                    { name: "company", caption: "Company", type: "string" },
                                    { name: "reporting_Manager", caption: "Reporting Manager", type: "string" }
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addRoverKitStatus.kind} successMessage="Rover Kit has been added successfully" />
                    <RequestStatus requestStatus={updateRoverKitStatus.kind} successMessage="Rover Kit has been updated successfully" />

                </AddUpdateRoverKitStyles>
            </PageSpacing>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.ROVER_KITS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const request: IAddUpdateRoverKit = {
            ID: data.id,
            Kit_No: data.kit_No,
            Unit_No: data.unit_No,
            Model: data.model,
            Data_Collector_Unit_No: data.data_Collector_Unit_No,
            Data_Collector_Model: data.data_Collector_Model,
            Antenna: data.antenna,
            Chargers: data.chargers,
            // Site: data.site,
            Status: data.status,
            WSS_ID: data.wsS_ID,
            Operator_ID: data.operator_ID,
            Notes: data.notes,
            Created_By: data.created_By,
            Modified_By: data.modified_By
        };

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addRoverKitRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            }
            else {
                request.Modified_By = this.props.token.payload.response.upn;

                this.props.updateRoverKitRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private handleNumber = (name: string, value: string): void => {
        if (value !== undefined) {
            // console.log(value)
            let errors = { ...this.state.errors };

            if (RequiredFields.includes(name))
                errors = this.errorChecker(name, value.toString(), errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }
    };

    private handleOperator = (name: string, value?: number): void => {
        const data = { ...this.state.data };

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            }
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getRoverKitsStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getRoverKitsStatus.payload.response[query.id];

                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getRoverKitsRequest({
                            token
                        });
                    }
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Survey_Pages"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateRoverKitComponentStoreProps => ({
    token: getToken(state),
    getLookup: GetSurveyLookup(state),
    addRoverKitStatus: addRoverKits(state),
    getRoverKitsStatus: getRoverKits(state),
    updateRoverKitStatus: updateRoverKits(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateRoverKitComponentDispatchProps => ({
    getRoverKitsRequest: (data: IRoverKitRequest) => dispatch(getRoverKitLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISurveyLookupRequest) => dispatch(getSurveyLookupLoadAction(data)),
    addRoverKitRequest: (data: IAddUpdateRoverKitRequest) => dispatch(addRoverKitLoadAction(data)),
    updateRoverKitRequest: (data: IAddUpdateRoverKitRequest) => dispatch(updateRoverKitLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateRoverKit);