import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_FOREMAN_MESSAGING_REQUEST, IForemanMessaging } from "./getForemanMessagingConstants";
import { IGetForemanMessagingLoadAction, IGetForemanMessagingLoadFailedAction, IGetForemanMessagingSuccessAction } from "./getForemanMessagingActions";
import { IAddForemanMessagingSuccessAction } from "../addForemanMessaging/addForemanMessagingActions";
import { IADD_FOREMAN_MESSAGING_REQUEST } from "../addForemanMessaging/addForemanMessagingConstants";
import { IUpdateForemanMessagingSuccessAction } from "../updateForemanMessaging/updateForemanMessagingActions";
import { IUPDATE_FOREMAN_MESSAGING_REQUEST } from "../updateForemanMessaging/updateForemanMessagingConstants";
import { ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST } from "../../../external/saveEmployeeMessageResponse/saveEmployeeMessageResponseConstants";
import { ISaveEmployeeMessageResponseSuccessAction } from "../../../external/saveEmployeeMessageResponse/saveEmployeeMessageResponseActions";

type Actions =
    | IGetForemanMessagingLoadAction
    | IGetForemanMessagingSuccessAction
    | IGetForemanMessagingLoadFailedAction
    | IAddForemanMessagingSuccessAction
    | IUpdateForemanMessagingSuccessAction
    | ISaveEmployeeMessageResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetForemanMessagingReducer = (state: Server<SurewayAPIResponse<ById<IForemanMessaging>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IForemanMessaging>>> => {
    switch (action.type) {
        case IGET_FOREMAN_MESSAGING_REQUEST.REQUEST:
            return loading;

        case IGET_FOREMAN_MESSAGING_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FOREMAN_MESSAGING_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_FOREMAN_MESSAGING_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_FOREMAN_MESSAGING_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};