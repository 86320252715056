import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST {
    REQUEST = "getMechanicTimesheetForApprovalGroupByDate/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST",
    SUCCESS = "getMechanicTimesheetForApprovalGroupByDate/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_SUCCESS",
    FAILED = "getMechanicTimesheetForApprovalGroupByDate/GET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_FAILED"
};

export interface IGetMechanicTimesheetForApprovalGroupByDateRequest extends ISurewayTokenRequestBody {
    request: {
        Manager_Type: string;
    }
};


export interface IMechanicTimesheetForApprovalGroupByDate {
    date: string;
    mechanics: IMechanicsLogsInfo[];
    expand?: boolean;
}

export interface IMechanicsLogsInfo {
    mechanic_ID: number;
    mechanic_Name: string;
    mechanic_Email: string;
    mechanic_UPN: string;
    lunch: string;
    start_Time: string;
    end_Time: string;
    logs: IMechanicLog[];
    expand?: boolean;
    view?: string;
    reject_Comments?: string;
}

export interface IMechanicLog {
    id: number;
    display: string;
    defect_ID: number;
    defect_Item: string;
    hours: number;
    comments: string;
    status: string;
    component_Code: string;
    manager_Instructions: string;
    repair_Location: string;
    charge_Location: string;
    loa: string;
    type: string;
    travel_Type: string;
    // misc_Hours: number;
    billable: string;
    billable_Percentage: number | null;
    job_No: string;
    cost_Code: string;
    work_Order_No: string;
    defect_Status: string;
    defect_Type: string;
    item_Attachment_ID: number;
    meter_Reading: number;
    major_Component_Replacement: string;
    major_Type?: string;
    component_Repaired_Location?: string;
    oeM_Part?: string;
    vendor?: string;
    exchange_Type?: string;
    condition?: string;
    warranty_Details?: string;
    beR_Details?: string;
    serial_No: string;
    major_Component_Replacement_Type: string;
    major_Component_Replacement_Hours: number;
    major_Component_Replacement_Notes: string;
    new_Major_Component_Replacement_Notes: string;
    parts_Delay_Hours: number;
    parts_Delay_Comments: string;
    unit_ID: number;
    unit_Status?: string;
}