import React, {PureComponent, ReactNode} from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";
import { ITextFieldProps, ENTER_KEY_CODE, ESCAPE_KEY_CODE } from "./textField";
import { DARK_GREY_COLOR } from "./theme";
import { ITextAreaWrapperProps } from "./publicInterfaces";

export const TEXT_AREA_VARIANT = "outlined";
const styles: React.CSSProperties = {fontSize: 12, color: DARK_GREY_COLOR, padding: "0.3rem"};

const StyledCancel = styled.span`
    cursor: pointer;
    color: #6e89ab;
    font-weight: bold;
    &:hover {
        text-decoration: underline;
    }
`;

interface ILATextAreaProps {
    rowsMax: number;
    minRows?: number;
    size?: "medium" | "small" | undefined;
    showInstructions?: true;
    style?: React.CSSProperties;
    onPressEscape?: () => unknown;
    onBlur?: () => unknown;
}

class LATextArea extends PureComponent<ITextFieldProps & ILATextAreaProps> {
    public render(): ReactNode {
        const helperText: string | undefined = this.props.errorText;
        const error: true | undefined = helperText ? true : undefined;

        return (
            <div className={this.props.className}>
                <TextField
                    autoFocus={this.props.autoFocus}
                    style={this.props.style}
                    variant={TEXT_AREA_VARIANT}
                    id={this.props.id}
                    minRows={this.props.minRows}
                    name={this.props.name}
                    label={this.props.label}
                    value={this.props.value}
                    required={this.props.required}
                    fullWidth={this.props.fullWidth}
                    placeholder={this.props.placeholder}
                    hiddenLabel={this.props.hiddenLabel}
                    margin={this.props.margin}
                    helperText={helperText}
                    error={error}
                    disabled={this.props.disabled}
                    type={this.props.type}
                    InputProps={this.props.InputProps}
                    onChange={this.handleOnChange}
                    onKeyUp={this.onKeyUp}
                    onKeyPress={this.onKeyPress}
                    multiline={true}
                    color="secondary"
                    maxRows={this.props.rowsMax}
                    onBlur={this.props.onBlur}
                    size={this.props.size}
                />
                {this.props.showInstructions &&
                    <div style={styles}>
                        Press "Enter" to submit • "Shift + Enter" for newline
                        {this.props.onPressEscape
                            ? <span> • Press "Escape" to{" "}
                                <StyledCancel onClick={this.props.onPressEscape}>
                                    cancel
                                </StyledCancel>
                            </span>
                            : null
                        }
                    </div>
                }
            </div>
        );
    }

    private onKeyUp = (e: React.KeyboardEvent): void => {
        if (e.which === ESCAPE_KEY_CODE && this.props.onPressEscape) {
            this.props.onPressEscape();
            e.preventDefault();
        }
    };

    private onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        if (this.props.onPressEnter && e.which === ENTER_KEY_CODE && !e.shiftKey) {
            this.props.onPressEnter();
            e.preventDefault();
        }
    };

    private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = event.target;
        this.props.onChange(name, value, this.props.indexPosition);
    };
}

interface IEditableTextAreaProps extends ITextFieldProps, ILATextAreaProps {
    isEditMode: true | undefined;
    canEdit: boolean;
    fieldName?: string;
    unSavedValue: ITextFieldProps["value"];
    Wrapper: React.FunctionComponent<ITextAreaWrapperProps>;
    handleEditModeOn?: () => unknown;
}

export const EditableTextArea: React.FC<IEditableTextAreaProps> = React.memo((props: IEditableTextAreaProps) => {
    const {Wrapper, ...rest} = props;

    return props.isEditMode
        ? <LATextArea {...rest} />
        : <Wrapper
            fieldName={props.fieldName}
            value={props.unSavedValue}
            onClick={props.canEdit ? props.handleEditModeOn : undefined}
        />;
});

interface ISearchBoxEditableTextAreaProps extends ITextFieldProps, ILATextAreaProps {
    isEditMode: true | undefined;
    canEdit: boolean;
    fieldName?: string;
    unSavedValue: ITextFieldProps["value"];
    Wrapper: React.FunctionComponent<ITextAreaWrapperProps>;
    handleEditModeOn?: () => unknown;
}

export const SearchBoxEditableTextArea: React.FC<ISearchBoxEditableTextAreaProps> = React.memo((props: ISearchBoxEditableTextAreaProps) => {
    const {Wrapper, ...rest} = props;

    return props.isEditMode
        ? <LATextArea {...rest} showInstructions={undefined} />
        : <Wrapper
            fieldName={props.fieldName}
            value={props.unSavedValue}
            onClick={props.canEdit ? props.handleEditModeOn : undefined}
        />;
});


export default LATextArea;
