import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_WSS_LOGS_REQUEST, IWSSLogs, IGetWSSLogsRequest } from "./getWSSLogsConstants";

export interface IGetWSSLogsLoadAction {
    type: IGET_WSS_LOGS_REQUEST.REQUEST;
    data: IGetWSSLogsRequest
}
export const getWSSLogsLoadAction = (data: IGetWSSLogsRequest): IGetWSSLogsLoadAction => ({
    type: IGET_WSS_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetWSSLogsSuccessAction {
    type: IGET_WSS_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IWSSLogs>>;
}
export const getWSSLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IWSSLogs>>): IGetWSSLogsSuccessAction => ({
    type: IGET_WSS_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetWSSLogsLoadFailedAction {
    type: IGET_WSS_LOGS_REQUEST.FAILED;
    message: string;
}
export const getWSSLogsLoadFailedAction = (message: string): IGetWSSLogsLoadFailedAction => ({
    type: IGET_WSS_LOGS_REQUEST.FAILED,
    message
});
