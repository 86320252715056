export enum IADD_FORM_RESPONSE_REQUEST {
    REQUEST = "addFormResponse/ADD_FORM_RESPONSE_REQUEST",
    SUCCESS = "addFormResponse/ADD_FORM_RESPONSE_SUCCESS",
    FAILED = "addFormResponse/ADD_FORM_RESPONSE_FAILED"
};

export interface IAddFormResponseRequest {
    token: string;
    request: IAddUpdateFormResponse;
};

export interface IAddUpdateFormResponse {
    ID: number;
    Form_Name?: string;
    Form_ID: number;
    WSS_Log_ID?: number;
    Emp_ID: number;
    Name: string;
    WSS_ID: number;
    Location_Other: string;
    Supervisor: string;
    Muster_Point?: string;
    Nearest_Medical_Facility?: string;
    Responses: IAddUpdateResponses[];
    Department_ID: number;
    Task_List: string[];
    Hazard_List: IAddUpdateFormResponseHazardList[];
    Tasks: IAddUpdateFormResponseTask[];
    Tool_Box_Meeting: string;
    Pictures: IAddUpdateFormResponsePicture[];
    Files: IAddUpdateFormResponseFile[];
    Crew: IAddUpdateCrew[];
    Crew_Sign_In_Upload: IAddUpdateFormResponsePicture;
    Guests: IAddUpdateGuest[];
    Signature: string | null;
    Foreman_Signed: string,
    Foreman_Signed_Date: string,
    Form_Updated?: string;
    Created_By?: string;
    Modified_By: string;
}

export interface IAddUpdateFormResponseTask {
    id: number;
    task_ID: number;
    custom_Task: string;
    flagged: string;
    comments: string;
    hazards: IAddUpdateFormResponseHazards[];
    pictures: IAddUpdateFormResponsePicture[];
    images?: string;
}

export interface IAddUpdateFormResponseHazards {
    id: number;
    hazard_ID: number;
    severity: string;
    priority: string;
    hazard_Control_IDs: string;
    custom_Hazard_Control: string;
}
export interface IAddUpdateFormResponseHazardList {
    id: number;
    hazard: string;
    severity: string;
    priority: string;
    control_Plans: string[];
}

export interface IAddUpdateGuest {
    id: number;
    name: string;
    signature: string | null;
}

export interface IAddUpdateCrew {
    id: number;
    emp_ID: number;
    name: string;
    signature: string | null;
}

export interface IAddUpdateResponses {
    ID: number;
    Form_Question_ID: number;
    Question?: string;
    Answer: string;
    Flagged: string;
    Comments: string;
    Pictures: IAddUpdateFormResponsePicture[];
    Type?: string;
    Options?: string;
};

export interface IAddUpdateFormResponsePicture {
    name: string;
    description: string;
    base64String: string | null;
};

export interface IAddUpdateFormResponseFile {
    name: string;
    description: string;
};
