import { IChecklistQuestionFile, IChecklistQuestionPicture, IFormTemplateFile } from "../getForms/getFormsConstants";
import { IWorkingSiteLogPicture } from "../workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsConstants";

export enum IADD_FORM_TEMPLATE_REQUEST {
    REQUEST = "addFormTemplate/ADD_FORM_TEMPLATE_REQUEST",
    SUCCESS = "addFormTemplate/ADD_FORM_TEMPLATE_SUCCESS",
    FAILED = "addFormTemplate/ADD_FORM_TEMPLATE_FAILED"
};

export interface IAddFormTemplateRequest {
    token: string;
    request: IAddUpdateFormTemplate;
};

export interface IAddUpdateFormTemplate {
    ID: number;
    Form_Name: string;
    Active: string;
    Type: string;
    WSS_ID: number;
    All_Sites: string | null;
    WSS_IDS: string | null;
    Departments: string;
    Foreman: string;
    Employees: string;
    Frequency: string;
    Start_Date: string;
    Expiry_Date: string;
    Flagged_Notification_Contacts: string;
    Flagged_Notification_Method: string;
    Hazard_Assessment: string;
    Requires_Crew: string;
    Requires_Guest: string;
    Requires_Toolbox: string;
    Sections: string;
    Message: string;
    Created_By?: string;
    Modified_By: string;
    Questions: IAddUpdateQuestion[];
    Files: IFormTemplateFile[];
}

export interface IAddUpdateQuestion {
    ID: number;
    Question: string;
    Type: string;
    Options: string;
    Flagged_Options: string;
    Section: string;
    Display_Order: number;
    Attachments: string;
    Files:  IChecklistQuestionFile[];
    Pictures?: IChecklistQuestionPicture[];
    Required: string;
    Image_Required: string;
};
