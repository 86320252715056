
export enum IGET_WSS_LOGS_REQUEST {
    REQUEST = "getWSSLogs/GET_WSS_LOGS_REQUEST",
    SUCCESS = "getWSSLogs/GET_WSS_LOGS_SUCCESS",
    FAILED = "getWSSLogs/GET_WSS_LOGS_FAILED"
};

export interface IGetWSSLogsRequest {
    token: string;
    request: {
        view: string;
    }
};

export interface IWSSLogs {
  id: number;
  date: string;
  foreman: string;
  wsS_ID: number;
  site?: string;
  status: string;
  equip_On_Site?: number;
  equip_Working?: number;
  emp_Working?: number;
  service_Required: string;
  prime_Contractor: string;
  radio: string;
  start_Time_Val: string;
  end_Time_Val: string;
  work_Description: string;
  safety_Observations_Comments: string;
  saturday_Working: string;
  sunday_Working: string;
  scheduled_Days_Off: string;
  job_No: string;
  weather?: IWeatherDetails[];
  logs: ILogs[];
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  date_: string;
  foreman_Display: string;
  start_Time: object;
  end_Time: object;
  security: string;
};

export interface IWSSLogPicture {
    name: string;
    description: string;
    base64String: string | null;
    timestamp: string;
};

export interface IWeatherDetails {
    id: number;
    wsS_ID: number;
    date: string;
    time: string;
    weather: string;
    temperature: number;
    wind: number;
    humidity: number;
    precipitation: number | null;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};

export interface ILogs {
    name: string;
    description: string;
    pictures: IWSSLogPicture[];
    created?: string;
    created_By?: string;
    id?: number;
    images?: string;
    log_ID?: number;
    modified?: string;
    modified_By?: string;
}