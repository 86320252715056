import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_SHOP_MODELS_REQUEST, IShopModel, IShopModelRequest } from "./getShopModelConstants";

export interface IGetShopModelsLoadAction {
    type: IGET_SHOP_MODELS_REQUEST.REQUEST;
    data: IShopModelRequest
}
export const getShopModelsLoadAction = (data: IShopModelRequest): IGetShopModelsLoadAction => ({
    type: IGET_SHOP_MODELS_REQUEST.REQUEST,
    data
});
export interface IGetShopModelsSuccessAction {
    type: IGET_SHOP_MODELS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IShopModel>>;
}
export const getShopModelsLoadSuccessAction = (list: SurewayAPIResponse<ById<IShopModel>>): IGetShopModelsSuccessAction => ({
    type: IGET_SHOP_MODELS_REQUEST.SUCCESS,
    list
});
export interface IGetShopModelsLoadFailedAction {
    type: IGET_SHOP_MODELS_REQUEST.FAILED;
    message: string;
}
export const getShopModelsLoadFailedAction = (message: string): IGetShopModelsLoadFailedAction => ({
    type: IGET_SHOP_MODELS_REQUEST.FAILED,
    message
});
