import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { DEFAULT_BOX_SHADOW, DARK_GREY_COLOR, LIGHT_GREY_COLOR, EXTRA_LIGHT_BLUE_COLOR, EXTRA_LIGHT_RED_COLOR, EXTRA_LIGHT_GREEN_COLOR, EXTRA_LIGHT_YELLOW_COLOR, EXTRA_LIGHT_ORANGE_COLOR } from "./theme";
import { HasClassAndChildren } from "./publicInterfaces";

const LAPaperWithPaddingStyles = styled(Paper)<{ removeborder?: boolean }>`
    padding: 15px 25px 25px !important;
    position: relative;
    border: ${(props) => (props.removeborder ? "none" : `1px solid ${DARK_GREY_COLOR}`)};
    ${DEFAULT_BOX_SHADOW}
`;

interface ILAPaperWithPaddingProps extends HasClassAndChildren {
    changeColorOnHover?: boolean;
    removeborder?: boolean;
}


export const LAPaperWithPadding: React.FC<ILAPaperWithPaddingProps> = (props: ILAPaperWithPaddingProps) =>
    <LAPaperWithPaddingStyles removeborder={props.removeborder} className={`${props.className}`} elevation={0}>{props.children}</LAPaperWithPaddingStyles>
    ;

const LAPaperWithLessPaddingStyles = styled(Paper)`
    padding: 5px 5px 5px !important;
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
`;

export const LAPaperWithLessPadding: React.FC<HasClassAndChildren> = (props: ILAPaperWithPaddingProps) =>
    <LAPaperWithLessPaddingStyles className={`${props.className}`} elevation={0}>{props.children}</LAPaperWithLessPaddingStyles>
    ;


const LAGreyPaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${LIGHT_GREY_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAGreyPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAGreyPaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAGreyPaperStyles>
    ;

const LABluePaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${EXTRA_LIGHT_BLUE_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LABluePaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LABluePaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LABluePaperStyles>
;

const LAOrangePaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${EXTRA_LIGHT_ORANGE_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAOrangePaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAOrangePaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAOrangePaperStyles>
;

const LARedPaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${EXTRA_LIGHT_RED_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LARedPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LARedPaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LARedPaperStyles>
;
    
const LAYellowPaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${EXTRA_LIGHT_YELLOW_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAYellowPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAYellowPaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAYellowPaperStyles>
;

const LAGreenPaperStyles = styled(Paper)`
    padding: 15px 25px 25px !important;
    position: relative;
    ${DEFAULT_BOX_SHADOW}
    background-color: ${EXTRA_LIGHT_GREEN_COLOR} !important;
    border: 1px solid ${DARK_GREY_COLOR};
`;

export const LAGreenPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAGreenPaperStyles
        className={props.className}
        elevation={0}
    >
        {props.children}
    </LAGreenPaperStyles>
;

const LAPaperStyles = styled(Paper)`
    position: relative;
    border: 1px solid ${DARK_GREY_COLOR};
    ${DEFAULT_BOX_SHADOW}
`;
export const LAPaper: React.FC<HasClassAndChildren> = (props: HasClassAndChildren) =>
    <LAPaperStyles className={props.className} elevation={0}>{props.children}</LAPaperStyles>
    ;