import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SERVICE_TRUCKS_REQUEST {
    REQUEST = "getServiceTrucks/G_SERVICE_TRUCKS_REQUEST",
    SUCCESS = "getServiceTrucks/G_SERVICE_TRUCKS_SUCCESS",
    FAILED = "getServiceTrucks/G_SERVICE_TRUCKS_FAILED"
};

export interface IGetServiceTruckRequest extends ISurewayTokenRequestBody {

};

export interface IServiceTruck {
    id: number;
    unit_ID: number;
    unit_No: string;
    driver_Name: string;
    driver_UPN: string;
    swamper_Name: string;
    swamper_ID: number;
    active: string;
    notes: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};