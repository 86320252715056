import React from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { LAPopover } from "../../shared/popOver";
import { STATUS_ENUM } from "../../../redux/server";
import { LACenteredLoading } from "../../shared/loading";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import {  TableContainer,  } from "@material-ui/core";
import styled from "styled-components";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, WHITE_COLOR } from "../../shared/theme";
import {  undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { ById } from "../../shared/publicInterfaces";
import { IGetUnitUtilizationByID } from "../../../redux/field/getUnitUtilizationByID/getUnitUtilizationByIDConstants";

interface IUtilizationPopupProps {
    open: boolean;
    display: string | undefined;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetUnitUtilizationByID>;
};

const UtilizationPopupStyles = styled(LAPaperWithPadding)`
        .title {
            font-weight: bold;
            color: ${WHITE_COLOR};
            background-color: ${DARK_GREY_COLOR};
        };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const UtilizationPopup: React.FC<IUtilizationPopupProps> = React.memo((props: IUtilizationPopupProps) => {
    const data = props.data ? Object.values(props.data) : [];
    const onCancel = () => props.onCancel();

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <UtilizationPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>Utilization  - {props.display && props.display}</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Utilization Data..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.SUCCEEDED && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                            
                            <LADevExtremeGrid
                                data={data}
                                // onEdit={onEdit}
                                columnChoose={true}
                                columnsHiding={true}
                                onClick={undefinedFunction}
                                searchPanel={true}
                                filterHeader={true}
                                export={true}
                                removeStyleBtn={true}
                                id="utilizationList"
                                customRowColor={true}
                                exportFileName="Utilization"
                                storageKey="utilizationSessionKey"
                                columns={[
                                    { name: "date_", caption: "Date", type: "date", sortDesc: true },
                                    { name: "employee", caption: "Employee", type: "string" },
                                    { name: "employeeCode", caption: "Employee Code", type: "string" },
                                    { name: "employeeCompany", caption: "Employee Company", type: "string" },
                                    { name: "employee_Hours", caption: "Employee Hours", type: "string" },
                                    { name: "equipment_Hours", caption: "Equipment Hours", type: "string" },
                                    { name: "jobNumber", caption: "Job Number", type: "string" },
                                    { name: "job_Description", caption: "Job Description", type: "string" },
                                    { name: "costCode", caption: "Cost Code", type: "string" },
                                    { name: "cC_Description", caption: "CC Description", type: "string" },
                                    { name: "fieldLogNumber", caption: "FieldLog Number", type: "string" },
                                ]}
                            />
                        </TableContainer>
                            : <LAErrorBox text="No records Found" />}
                    </LAGridItem>}

            </LAGrid>
        </UtilizationPopupStyles>
    </LAPopover>
});