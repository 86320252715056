import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FORMS_REQUEST, IChecklistForm } from "./getFormsConstants";
import { IGetFormsLoadAction, IGetFormsLoadFailedAction, IGetFormsSuccessAction, 
    getFormsLoadFailedAction, getFormsLoadSuccessAction } from "./getFormsActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getFormsEpic: Epic = (
    action$: ActionsObservable<IGetFormsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormsSuccessAction | IGetFormsLoadFailedAction> =>
    action$.ofType(IGET_FORMS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IChecklistForm[]>>(
                    END_POINTS.FIELD.GET_FORMS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IChecklistForm[]>): IGetFormsSuccessAction => {
                            return getFormsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormsLoadFailedAction(response.message)))
                    )
            )
        );