
export enum IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST {
    REQUEST = "getUnitInspectionSheetByID/GET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST",
    SUCCESS = "getUnitInspectionSheetByID/GET_UNIT_INSPECTION_SHEET_BY_ID_SUCCESS",
    FAILED = "getUnitInspectionSheetByID/GET_UNIT_INSPECTION_SHEET_BY_ID_FAILED"
};

export interface IGetUnitInspectionSheetByIDRequest {
    token: string;
    request: {
        id: number;
    };
};

export interface IGetUnitInspectionSheetByID {
    id: number;
    unit_ID: number;
    unit_No?: string;
    defect_ID: number;
    comments: string;
    hours: number;
    items: IGetUnitInspectionSheetByIDItems[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

export interface IGetUnitInspectionSheetByIDItems {
    id: number;
    sheet_ID?: number;
    item_ID: number;
    good: string;
    needs_Repair: string;
    na: string;
    estimated_Hours: number;
    estimated_Labour_Cost: number;
    estimated_Parts_Cost: number;
    approved: string;
    comments: string;
    created_By?: string;
    created?: string;
    modified_By?: string;
    modified?: string;
}