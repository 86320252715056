import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST, IGetWorkingSiteLogVersionHistory, IGetWorkingSiteLogVersionHistoryRequest } from "./getWorkingSiteLogVersionHistoryConstants";

export interface IGetWorkingSiteLogVersionHistoryLoadAction {
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetWorkingSiteLogVersionHistoryRequest
}
export const getWorkingSiteLogVersionHistoryLoadAction = (data: IGetWorkingSiteLogVersionHistoryRequest): IGetWorkingSiteLogVersionHistoryLoadAction => ({
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetWorkingSiteLogVersionHistorySuccessAction {
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>;
}
export const getWorkingSiteLogVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>): IGetWorkingSiteLogVersionHistorySuccessAction => ({
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetWorkingSiteLogVersionHistoryLoadFailedAction {
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getWorkingSiteLogVersionHistoryLoadFailedAction = (message: string): IGetWorkingSiteLogVersionHistoryLoadFailedAction => ({
    type: IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.FAILED,
    message
});
