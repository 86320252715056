import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';

interface IProps{
    handleRoute:Function;
  }

  interface IState{
    open:boolean;
    admin_open:boolean;
    driverlogs_open:boolean;
  }
  const HighlightedLink:any = {
    border: '2px solid white',
    borderRadius:'5px'
  }


const mobileFlexDirection:any = {
  display:'flex',
  alignItems:'flex-start',
  flexDirection:'column',
  flexWrap: 'wrap',
}
const desktopFlexDirection:any = {
  display:'flex',
  alignItems:'flex-start',
}
var boxStyle:any = desktopFlexDirection;
var disableEdit:boolean = false;
var etAccess:any;

export default class NavigationBar extends React.Component<IProps,IState>{
   anchorRef:  React.RefObject<HTMLButtonElement>;
   anchorAdminRef:  React.RefObject<HTMLButtonElement>;
   anchorDriverLogsRef: React.RefObject<HTMLButtonElement>;
    constructor(props:any){
        super(props);
      this.state = {open:false, admin_open:false, driverlogs_open:false};
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleAdminClick = this.handleAdminClick.bind(this);
      this.handleAdminClose = this.handleAdminClose.bind(this);
      this.handleDriverLogsClick = this.handleDriverLogsClick.bind(this);
      this.handleDriverLogsClose = this.handleDriverLogsClose.bind(this);
      this.anchorRef = React.createRef();
      this.anchorAdminRef = React.createRef();
      this.anchorDriverLogsRef = React.createRef();
    }
    handleDriverLogsClose(elem:any){
      this.setState({driverlogs_open:false});
    }
    handleDriverLogsClick(elem:any){
      this.setState({driverlogs_open:true});
    }
    handleAdminClose(elem:any){
      this.setState({admin_open:false});
    }
    handleAdminClick(elem:any){
        this.setState({admin_open:true});
    }
    handleClose(elem:any){
      this.setState({open:false});
    }
    handleClick(elem:any){
        this.setState({open:true});
    }
    componentDidMount(){

      disableEdit = localStorage.getItem("ET-Role")==="R" || window.location.href.includes("?token");
      etAccess = localStorage.getItem("ET-Role")

      if(window.innerWidth < 1056){
        boxStyle = mobileFlexDirection;
      }
      this.setState({});
    }
    componentDidUpdate(){
      if(window.innerWidth < 1056){
        boxStyle = mobileFlexDirection;
      }
      else{
        boxStyle = desktopFlexDirection;
      }
    }
    
    render(){
        return (
          <> 
          {/* <div style={{backgroundColor:'red', color:'white', width:'75%', fontSize:'16px', marginLeft:'10%'}}>*In Development - This is to be used in parallel with the current whiteboard as it updates Equipment Location</div> */}
            {boxStyle === desktopFlexDirection?
            <Box sx={boxStyle}>
                {/* <MenuItem id='test1' onClick={() => this.props.handleRoute('home')}  className="SOCT-App-link">
                   Home
                </MenuItem> */}
                <MenuItem id='test5' onClick={() => this.props.handleRoute('trucks')} className="SOCT-App-link" style={window.location.href.includes("trucking") ? HighlightedLink:null}>
                  Location View
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('equipment_moves')} className="SOCT-App-link" style={window.location.href.includes("equipmentmoves") ? HighlightedLink:null}>
                  Move Requests
                </MenuItem>
                {etAccess === "RW" ? 
                    <MenuItem className="SOCT-App-link" style={window.location.href.includes("approveLogs") || window.location.href.includes("viewLogs") ? HighlightedLink:null}>
                        <Button
                          ref={this.anchorDriverLogsRef}
                          id="fade-button"
                          aria-controls={this.state.driverlogs_open ? 'fade-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={this.state.driverlogs_open ? 'true' : undefined}
                          onClick={this.handleDriverLogsClick}
                        >
                          Driver Logs
                        </Button>
                        <Menu
                          id="fade-menu"
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                          }}
                          anchorEl={this.anchorDriverLogsRef.current}
                          open={this.state.driverlogs_open}
                          onClose={this.handleDriverLogsClose}
                          TransitionComponent={Fade}
                        >
                            <MenuItem id='test6' onClick={() => this.props.handleRoute('approveLogs')} className="SOCT-App-link" style={window.location.href.includes("approveLogs") ? HighlightedLink:null}>
                                Approve Logs
                            </MenuItem>
                            <MenuItem id='test6' onClick={() => this.props.handleRoute('viewLogs')}  className="SOCT-App-link" style={window.location.href.includes("viewLogs") ? HighlightedLink:null}>
                                View Logs
                            </MenuItem>
                      
                        </Menu>
                    </MenuItem>
                : null}
                <MenuItem id='test6' onClick={() => this.props.handleRoute('list_view')} className="SOCT-App-link" style={window.location.href.includes("listview") ? HighlightedLink:null}>
                  List View
                </MenuItem>
                {!disableEdit ? 
                <>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('dispatch')} className="SOCT-App-link" style={window.location.href.includes("dispatch") ? HighlightedLink:null}>
                  Dispatch
                </MenuItem>
                <MenuItem className="SOCT-App-link" style={window.location.href.includes("driver") || window.location.href.includes("trailer") 
                || window.location.href.includes("email")? HighlightedLink:null}>
                  <Button
                    ref={this.anchorAdminRef}
                    id="fade-button"
                    aria-controls={this.state.admin_open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.admin_open ? 'true' : undefined}
                    onClick={this.handleAdminClick}
                  >
                    Admin
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={this.anchorAdminRef.current}
                    open={this.state.admin_open}
                    onClose={this.handleAdminClose}
                    TransitionComponent={Fade}
                  >
                      <MenuItem id='test6' onClick={() => this.props.handleRoute('drivers')} className="SOCT-App-link" style={window.location.href.includes("drivers") ? HighlightedLink:null}>
                  Drivers
                </MenuItem>
                <MenuItem id='testET' onClick={this.onETTV}  className="SOCT-App-link" style={window.location.href.includes("et-tv") ? HighlightedLink:null}>
                  ET-TV
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('trailers')} className="SOCT-App-link" style={window.location.href.includes("trailers") ? HighlightedLink:null}>
                  Trailers
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('trailer_rates')} className="SOCT-App-link" style={window.location.href.includes("trailerrates") ? HighlightedLink:null}>
                  Trailer Rates
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('emails')} className="SOCT-App-link" style={window.location.href.includes("emails") ? HighlightedLink:null}>
                  Trucking Request Emails
                </MenuItem>
                    </Menu>
                    </MenuItem>
                    </>
                :null}
                <MenuItem className="SOCT-App-link" style={window.location.href.includes("report") ? HighlightedLink:null}>
                  <Button
                    ref={this.anchorRef}
                    id="fade-button"
                    aria-controls={this.state.open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}
                  >
                    Reports
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={this.anchorRef.current}
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                  >
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('billing_reports')} className="SOCT-App-link" style={window.location.href.includes("billingreports") ? HighlightedLink:null}>
                      Billing Reports
                    </MenuItem>
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('movehistoryreport')} className="SOCT-App-link" style={window.location.href.includes("movehistoryreport") ? HighlightedLink:null}>
                      Move History Report
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('bulk_moves')} className="SOCT-App-link" style={window.location.href.includes("bulkmoves") ? HighlightedLink:null}>
                  Bulk Moves
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('quotes')} className="SOCT-App-link" style={window.location.href.includes("quotes") ? HighlightedLink:null}>
                  Quotes
                </MenuItem>
            </Box>
            :
            <Box sx={boxStyle}>
                <MenuItem className="SOCT-App-link" style={HighlightedLink}>
                  <Button
                    ref={this.anchorRef}
                    id="fade-button"
                    aria-controls={this.state.open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={this.state.open ? 'true' : undefined}
                    onClick={this.handleClick}
                  >
                    Menu
                  </Button>
                  <Menu
                    id="fade-menu"
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={this.anchorRef.current}
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                  >
                     <MenuItem id='test5' onClick={() => this.props.handleRoute('trucks')} className="SOCT-App-link" style={window.location.href.includes("trucking") ? HighlightedLink:null}>
                  Location View
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('equipment_moves')} className="SOCT-App-link" style={window.location.href.includes("equipmentmoves") ? HighlightedLink:null}>
                  Move Requests
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('list_view')} className="SOCT-App-link" style={window.location.href.includes("listview") ? HighlightedLink:null}>
                  List View
                </MenuItem>
                
                {!disableEdit ?
                <>                
                <MenuItem id='test6' onClick={() => this.props.handleRoute('dispatch')} className="SOCT-App-link" style={window.location.href.includes("dispatch") ? HighlightedLink:null}>
                  Dispatch
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('drivers')} className="SOCT-App-link" style={window.location.href.includes("drivers") ? HighlightedLink:null}>
                  Drivers
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('trailers')} className="SOCT-App-link" style={window.location.href.includes("trailers") ? HighlightedLink:null}>
                  Trailers
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('trailer_rates')} className="SOCT-App-link" style={window.location.href.includes("trailerrates") ? HighlightedLink:null}>
                  Trailer Rates
                </MenuItem>
                <MenuItem id='test6' onClick={() => this.props.handleRoute('emails')} className="SOCT-App-link" style={window.location.href.includes("emails") ? HighlightedLink:null}>
                  Trucking Request Emails
                </MenuItem>
                </>
                :null}
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('billing_reports')} className="SOCT-App-link" style={window.location.href.includes("billingreports") ? HighlightedLink:null}>
                      Billing Reports
                    </MenuItem>
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('movehistoryreport')} className="SOCT-App-link" style={window.location.href.includes("movehistoryreport") ? HighlightedLink:null}>
                      Move History Report
                    </MenuItem>
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('bulk_moves')} className="SOCT-App-link" style={window.location.href.includes("bulkmoves") ? HighlightedLink:null}>
                      Bulk Moves
                    </MenuItem>
                    <MenuItem id='test6' onClick={() => this.props.handleRoute('quotes')} className="SOCT-App-link" style={window.location.href.includes("quotes") ? HighlightedLink:null}>
                      Qoutes
                    </MenuItem>
                  </Menu>
                </MenuItem>
            </Box>
            }
            </>
        );
    }

    private onETTV = (): void => {
 window.open("https://apps.sureway.ca/external/move-request", "_blank");
    };
}