import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_DEPARTMENTS_REQUEST, IGetDepartments } from "./getDepartmentsConstants";
import { IGetDepartmentsLoadAction, IGetDepartmentsLoadFailedAction, IGetDepartmentsSuccessAction, 
    getDepartmentsLoadFailedAction, getDepartmentsLoadSuccessAction } from "./getDepartmentsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getDepartmentsEpic: Epic = (
    action$: ActionsObservable<IGetDepartmentsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetDepartmentsSuccessAction | IGetDepartmentsLoadFailedAction> =>
    action$.ofType(IGET_DEPARTMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetDepartments>>>(
                    END_POINTS.FIELD.FORMS.ADMIN.GET_DEPARTMENTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetDepartments>>): IGetDepartmentsSuccessAction => {
                            return getDepartmentsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getDepartmentsLoadFailedAction(response.message)))
                    )
            )
        );