import { IGET_LOOKUPS_REQUEST, ILookup } from "./getLookupsConstants";
import { IGetLookupsLoadAction, IGetLookupsLoadFailedAction, IGetLookupsSuccessAction } from "./getLookupsActions";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";

type Actions =
    | IGetLookupsLoadAction
    | IGetLookupsSuccessAction
    | IGetLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetLookupsReducer = (state: Server<SurewayAPIResponse<ILookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ILookup>> => {
    switch (action.type) {
        case IGET_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};