import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { BLUE_COLOR, DARK_MAROON_COLOR, DARK_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable, ReadAndWrite } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetMechanicsRequest, IMechanic } from "../../../redux/field/mechanics/getMechanics/getMechanicsConstants";
import { getMechanicsLoadAction } from "../../../redux/field/mechanics/getMechanics/getMechanicsActions";
import { getMechanics } from "../../../redux/field/mechanics/getMechanics/getMechanicsAccessor";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import LAErrorBox from "../../shared/errorBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

interface IMechanicsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMechanic: Server<SurewayAPIResponse<ById<IMechanic>>>;
};

interface IMechanicsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMechanicsRequest: (data: IGetMechanicsRequest) => unknown;
};


interface IMechanicsOwnProps {

};

interface IMechanicsState {
    activeView: boolean;
    activeList: IMechanic[];
};

const MechanicsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .hireForm_Link {
        color: ${DARK_RED_COLOR}
    }
        
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMechanicsProps = RouteComponentProps
    & IMechanicsStoreProps
    & IMechanicsDispatchProps
    & IMechanicsOwnProps;

class Mechanics extends PureComponent<IMechanicsProps, IMechanicsState> {

    public constructor(props: IMechanicsProps) {
        super(props);
        this.state = {
            activeView: true,
            activeList: []
        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IMechanicsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getMechanic, getToken } = this.props;
        const { activeView, activeList } = this.state;
        const getRole = pageAccessCheck(getToken, "repairLine");
        const payRollAccess = hasPayload(this.props.getToken) && this.props.getToken.payload.response.payrollAccess;
        const data = activeView ? (activeList) : hasPayload(getMechanic) ? Object.values(getMechanic.payload.response) : [];
        // console.log(this.state.activeList);

        return (
            <PageSpacing title="Mechanics" description="FIELD - MECHANIC List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable || payRollAccess) && <MechanicsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                {/* <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                /> */}

                                <h2 className="title">MECHANIC LIST</h2>
                                <hr />

                                {getMechanic.kind === STATUS_ENUM.LOADING && <LALoading message="Loading mechanics..." />}
                                {getMechanic.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load mechanics..." />}

                                {getMechanic.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="active" control={<Radio />} label="Active Items" />
                                                <FormControlLabel value="all" control={<Radio />} label="All Items" />
                                            </RadioGroup>
                                        </LAGridItem>
                                        <LAGridItem xs={12}>
                                            <h3 className="hireForm_Link">Mechanics are managed through Hire form. <span className="link-btn" onClick={this.handleClickHereBtn}>Click here</span> to access the HR portal. </h3>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onEditColumnLabel="View"
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="mechanicList"
                                                customRowColor={true}
                                                exportFileName="Mechanics"
                                                storageKey="mechanicsListSessionKey"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "firstName", caption: "First Name", type: "string" },
                                                    { name: "lastName", caption: "Last Name", type: "string" },
                                                    { name: "email", caption: "Email", type: "string" },                                                   
                                                    { name: "type", caption: "Type", type: "string" },                                                   
                                                    { name: "company", caption: "Company", type: "string" },
                                                    { name: "contractor", caption: "Contractor", type: "string" },
                                                    { name: "employee_Code", caption: "Employee Code", type: "string" },
                                                    { name: "comments", caption: "Comments", type: "string" },
                                                    { name: "active", caption: "Active", type: "string" },
                                                    { name: "phone", caption: "Phone", type: "string" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </MechanicsStyles>}
            </PageSpacing>
        );
    }

    private handleClickHereBtn = (): void => {
        window.open(ROUTE.HIRE_FORM.INDEX, '_blank')
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        let activeView = !this.state.activeView;
        const getStorageValue = localStorage.getItem("key_mechanic_list");
        if (getStorageValue) {
            if (activeView) {
                localStorage.setItem("key_mechanic_list", "active");
            }
            else {
                localStorage.setItem("key_mechanic_list", "all");
            }
        }
        else {
            localStorage.setItem("key_mechanic_list", "active");
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.MECHANIC
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.MECHANIC
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable || this.props.getToken.payload.response.payrollAccess) {
                if (isNotLoaded(this.props.getMechanic)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };

        if (hasPayload(this.props.getMechanic)) {
            this.setState({
                activeList: Object.values(this.props.getMechanic.payload.response)
                    .filter(x => x.active === "Yes")
            });
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMechanicsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IMechanicsStoreProps => ({
    getToken: getToken(state),
    getMechanic: getMechanics(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMechanicsDispatchProps => ({
    getMechanicsRequest: (data: IGetMechanicsRequest) => dispatch(getMechanicsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Mechanics);