import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST, IGetFormResponseVersionHistoryByID } from "./getFormResponseVersionHistoryByIDConstants";
import { IGetFormResponseVersionHistoryByIDLoadAction, IGetFormResponseVersionHistoryByIDLoadFailedAction, IGetFormResponseVersionHistoryByIDSuccessAction } from "./getFormResponseVersionHistoryByIDActions";

type Actions =
    | IGetFormResponseVersionHistoryByIDLoadAction
    | IGetFormResponseVersionHistoryByIDSuccessAction
    | IGetFormResponseVersionHistoryByIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFormResponseVersionHistoryByIDReducer = (state: Server<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>> => {
    switch (action.type) {
        case IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};