import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_REPAIR_LINE_SUMMARY_REQUEST, IRepairLineSummary } from "./getRepairLineSummaryConstants";
import { IADD_REPAIR_LINE_REQUEST } from "../addRepairLine/addRepairLineConstants";
import { IAddRepairLineSuccessAction } from "../addRepairLine/addRepairLineActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_REQUEST } from "../updateRepairLine/updateRepairLineConstants";
import { IUpdateRepairLineSuccessAction } from "../updateRepairLine/updateRepairLineActions";
import { IGetRepairLineSummaryLoadAction, IGetRepairLineSummaryLoadFailedAction, IGetRepairLineSummarySuccessAction } from "./getRepairLineSummaryActions";
import { IUpdateRepairLineByMechanicSuccessAction } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicActions";
import { IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicConstants";
import { ISaveMechanicAssignmentSuccessAction } from "../saveMechanicAssignment/saveMechanicAssignmentActions";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "../saveMechanicAssignment/saveMechanicAssignmentConstants";
import { ISAVE_COMPONENT_WAITING_PARTS_REQUEST } from "../saveComponentWaitingParts/saveComponentWaitingPartsConstants";
import { ISaveComponentWaitingPartsSuccessAction } from "../saveComponentWaitingParts/saveComponentWaitingPartsActions";

type Actions =
    | IGetRepairLineSummaryLoadAction
    | IGetRepairLineSummarySuccessAction
    | IAddRepairLineSuccessAction
    | IUpdateRepairLineSuccessAction
    | IGetRepairLineSummaryLoadFailedAction
    | ISaveMechanicAssignmentSuccessAction
    | IUpdateRepairLineByMechanicSuccessAction
    | ISaveComponentWaitingPartsSuccessAction
    | IFlushDataSuccessAction;

export const GetRepairLineSummaryReducer = (state: Server<SurewayAPIResponse<IRepairLineSummary>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IRepairLineSummary>> => {
    switch (action.type) {
        case IGET_REPAIR_LINE_SUMMARY_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_LINE_SUMMARY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_LINE_SUMMARY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};