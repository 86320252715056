import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_ATTACHMENT_LIST_GET_REQUEST, IAttachmentListGET } from "./getAttachmentListGETConstants";
import { IAddAttachmentListGETSuccessAction } from "../addAttachmentListGET/addAttachmentListGETActions";
import { IADD_ATTACHMENT_LIST_GET_REQUEST } from "../addAttachmentListGET/addAttachmentListGETConstants";
import { IUpdateAttachmentListGETSuccessAction } from "../updateAttachmentListGET/updateAttachmentListGETActions";
import { IUPDATE_ATTACHMENT_LIST_GET_REQUEST } from "../updateAttachmentListGET/updateAttachmentListGETConstants";
import { IGetAttachmentListGETLoadAction, IGetAttachmentListGETLoadFailedAction, IGetAttachmentListGETSuccessAction } from "./getAttachmentListGETActions";
import { IDeleteAttachmentListGETSuccessAction } from "../deleteAttachmentListGET/deleteAttachmentListGETActions";
import { IDELETE_ATTACHMENT_LIST_GET_REQUEST } from "../deleteAttachmentListGET/deleteAttachmentListGETConstants";
import { ICLONE_ATTACHMENT_GET_REQUEST } from "../cloneAttachmentGET/cloneAttachmentGETConstants";
import { ICloneAttachmentGETSuccessAction } from "../cloneAttachmentGET/cloneAttachmentGETActions";

type Actions =
    | IGetAttachmentListGETLoadAction
    | IGetAttachmentListGETSuccessAction
    | IGetAttachmentListGETLoadFailedAction
    | IUpdateAttachmentListGETSuccessAction
    | IAddAttachmentListGETSuccessAction
    | IDeleteAttachmentListGETSuccessAction
    | ICloneAttachmentGETSuccessAction
    | IFlushDataSuccessAction;

export const GetAttachmentListGETReducer = (state: Server<SurewayAPIResponse<ById<IAttachmentListGET>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IAttachmentListGET>>> => {
    switch (action.type) {
        case IGET_ATTACHMENT_LIST_GET_REQUEST.REQUEST:
            return loading;

        case IGET_ATTACHMENT_LIST_GET_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ATTACHMENT_LIST_GET_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_LIST_GET_REQUEST.SUCCESS:
            return notLoaded;

        case IDELETE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS:
            return notLoaded;

        case ICLONE_ATTACHMENT_GET_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};