import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IADD_FILTER_REQUEST } from "./addFilterConstants";
import { IAddFilterLoadAction, IAddFilterLoadFailedAction, IAddFilterSuccessAction } from "./addFilterActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IAddFilterLoadAction
    | IAddFilterSuccessAction
    | IAddFilterLoadFailedAction
    | IFlushDataSuccessAction;

export const AddFilterReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_FILTER_REQUEST.REQUEST:
            return loading;

        case IADD_FILTER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_FILTER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};