import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MACHINE_CONTROL_BOXES_REQUEST, IMachineControlBoxes, IMachineControlBoxesRequest } from "./getMachineControlBoxesConstants";

export interface IGetMachineControlBoxesLoadAction {
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.REQUEST;
    data: IMachineControlBoxesRequest
}
export const getMachineControlBoxesLoadAction = (data: IMachineControlBoxesRequest): IGetMachineControlBoxesLoadAction => ({
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.REQUEST,
    data
});
export interface IGetMachineControlBoxesSuccessAction {
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMachineControlBoxes>>;
}
export const getMachineControlBoxesLoadSuccessAction = (list: SurewayAPIResponse<ById<IMachineControlBoxes>>): IGetMachineControlBoxesSuccessAction => ({
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.SUCCESS,
    list
});
export interface IGetMachineControlBoxesLoadFailedAction {
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.FAILED;
    message: string;
}
export const getMachineControlBoxesLoadFailedAction = (message: string): IGetMachineControlBoxesLoadFailedAction => ({
    type: IGET_MACHINE_CONTROL_BOXES_REQUEST.FAILED,
    message
});
