import { IForeman, IWSS } from "../../workingSiteLogs/getFieldLookups/getFieldLookupsConstants";

export enum IGET_ET_LOOKUPS_REQUEST {
    REQUEST = "getETLookups/GET_ET_LOOKUPS_REQUEST",
    SUCCESS = "getETLookups/GET_ET_LOOKUPS_SUCCESS",
    FAILED = "getETLookups/GET_ET_LOOKUPS_FAILED"
};

export interface IETLookupsRequest {
    token: string;
    request: {
        page: "List_View" | "Move_Requests" | "Dispatch" | "Mass_Move_Requests";
    }
};

export interface IETLookups {
    subSiteList: IETSubSiteList[];
    locationInfo: IETLocationInfo[];
    trailer_Config: ITrailerConfig[];
    wssList?: IWS[];
    foremanList?: IForeman[];
};

export interface ITrailerConfig {
    id: number;
    unit_Number: string;
    trailer_Rate_ID: number
    description: string;
};

export interface IETSubSiteList {
    id: number;
    sub_Site_Name: string;
    latitude: string;
    longitude: string;
};

export interface IETLocationInfo {
    id: number;
    site_Name: string;
    job_No: string;
    sub_Sites: [
        {
            id: number;
            sub_Site_Name: string;
        }
    ]
};

export interface IWS {
    job: string;
    site: string;
    wsS_ID: number;
    site_Name: string;
    site_Address: string;
    sub_Site_Name: string;
    equip_On_Site: number;
    units: IUnits[];
};

export interface IUnits {
    details: string;
    last_Moved_Date: string;
    make: string;
    model: string;
    unit_ID: number;
    unit_Number: string;
}

