import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST, IGetShopEquipmentColumn } from "./getShopEquipmentColumnsConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetShopEquipmentColumnsLoadAction, IGetShopEquipmentColumnsLoadFailedAction, IGetShopEquipmentColumnsSuccessAction, getShopEquipmentColumnsLoadFailedAction, getShopEquipmentColumnsLoadSuccessAction } from "./getShopEquipmentColumnsActions";

export const getShopEquipmentColumnsEpic: Epic = (
    action$: ActionsObservable<IGetShopEquipmentColumnsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetShopEquipmentColumnsSuccessAction | IGetShopEquipmentColumnsLoadFailedAction> =>
    action$.ofType(IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetShopEquipmentColumn>>(
                    END_POINTS.FIELD.GET_SHOP_EQUIPMENT_COLUMNS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetShopEquipmentColumn>): IGetShopEquipmentColumnsSuccessAction => {
                            return getShopEquipmentColumnsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getShopEquipmentColumnsLoadFailedAction(response.message)))
                    )
            )
        );