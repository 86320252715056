
export enum IGET_FILTER_REPORT_REQUEST {
    REQUEST = "getFilterReport/GET_FILTER_REPORT_REQUEST",
    SUCCESS = "getFilterReport/GET_FILTER_REPORT_SUCCESS",
    FAILED = "getFilterReport/GET_FILTER_REPORT_FAILED"
};

export interface IFilterReportRequest {
    token: string;
};

export interface IFilterReport {
    id: number;
    make: string;
    model: string;
    unit_No: string;
    system: string;
    sub_Type: string;
    part_No: string;
    quantity: number;
    shop_Bin?: string;
    shop_stock?: number;
    silBru_Bin?: string;
    silBru_stock?: number;
};