import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MECHANIC_LOGS_BY_ID_REQUEST, IMechanicLogById } from "./getMechanicLogsByIdConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetMechanicLogsByIdLoadAction, IGetMechanicLogsByIdLoadFailedAction, IGetMechanicLogsByIdSuccessAction, getMechanicLogsByIdLoadFailedAction, getMechanicLogsByIdLoadSuccessAction } from "./getMechanicLogsByIdActions";

export const getMechanicLogsByIdEpic: Epic = (
    action$: ActionsObservable<IGetMechanicLogsByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMechanicLogsByIdSuccessAction | IGetMechanicLogsByIdLoadFailedAction> =>
    action$.ofType(IGET_MECHANIC_LOGS_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IMechanicLogById>>>(
                    END_POINTS.FIELD.MECHANICS.GET_MECHANIC_LOGS_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IMechanicLogById>>): IGetMechanicLogsByIdSuccessAction => {
                            return getMechanicLogsByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getMechanicLogsByIdLoadFailedAction(response.message)))
                    )
            )
        );