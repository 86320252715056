import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_CREW_BY_USERNAME_REQUEST {
    REQUEST = "getCrewByUsername/GET_CREW_BY_USERNAME_REQUEST",
    SUCCESS = "getCrewByUsername/GET_CREW_BY_USERNAME_SUCCESS",
    FAILED = "getCrewByUsername/GET_CREW_BY_USERNAME_FAILED"
};

export interface IGetCrewByUsernameRequest extends ISurewayTokenRequestBody {
    request: {
        username: string;
    }
};

export interface IGetCrewByUsername {
    crew: ICrew[];
};

export interface ICrew {
    id: number;
    foreman: string;
    crew_Name: string;
    active: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    members: number[];
    employees?: string | null;
  }