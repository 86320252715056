import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SERVICE_DUES_REQUEST, IServiceDue } from "./getServiceDuesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetServiceDuesLoadAction, IGetServiceDuesLoadFailedAction, IGetServiceDuesSuccessAction, getServiceDuesLoadFailedAction, getServiceDuesLoadSuccessAction } from "./getServiceDuesActions";

export const getServiceDuesEpic: Epic = (
    action$: ActionsObservable<IGetServiceDuesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetServiceDuesSuccessAction | IGetServiceDuesLoadFailedAction> =>
    action$.ofType(IGET_SERVICE_DUES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IServiceDue>>>(
                    END_POINTS.FIELD.PM_SHEETS.GET_SERVICE_DUES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IServiceDue>>): IGetServiceDuesSuccessAction => {
                            return getServiceDuesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getServiceDuesLoadFailedAction(response.message)))
                    )
            )
        );