import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST, IGetMechanicTimecardVersionHistory, IGetMechanicTimecardVersionHistoryRequest } from "./getMechanicTimecardVersionHistoryConstants";

export interface IGetMechanicTimecardVersionHistoryLoadAction {
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetMechanicTimecardVersionHistoryRequest
}
export const getMechanicTimecardVersionHistoryLoadAction = (data: IGetMechanicTimecardVersionHistoryRequest): IGetMechanicTimecardVersionHistoryLoadAction => ({
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetMechanicTimecardVersionHistorySuccessAction {
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>;
}
export const getMechanicTimecardVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>): IGetMechanicTimecardVersionHistorySuccessAction => ({
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicTimecardVersionHistoryLoadFailedAction {
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getMechanicTimecardVersionHistoryLoadFailedAction = (message: string): IGetMechanicTimecardVersionHistoryLoadFailedAction => ({
    type: IGET_MECHANIC_TIMECARD_VERSION_HISTORY_REQUEST.FAILED,
    message
});
