import React, { ReactNode } from "react";
import IconButton from "@material-ui/core/IconButton";
import { HasClass } from "./publicInterfaces";
import { LAToolTip } from "./toolTip";
import Button from "@material-ui/core/Button";
import {createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BLUE_COLOR, DARK_GREY_COLOR, DARK_ORANGE_COLOR, EXTRA_DARK_GREY_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_COLOR, WHITE_COLOR } from "./theme";
import styled from "styled-components";
import LAGridItem from "./gridList";
import LAGrid from "./grid";
import LALinkButton from "./linkButton";
import { undefinedFunction } from "./constExports";

interface ILAIconButtonProps extends HasClass {
    id?: string;
    label: string;
    name?: string;
    disabled?: true;
    linkButton?: true;
    linkLabel?: string;
    imagesOnly?: true;
    icon: React.ReactNode;
    downloadFile?: () => void;
    anchorRef?: React.RefObject<HTMLButtonElement>;
    onClick?: (event?: React.MouseEvent<HTMLButtonElement> | undefined, name?: string) => void;
    style?: React.CSSProperties | undefined;
    addLabel?: boolean;
}

export const LAIconButton = (props: ILAIconButtonProps): JSX.Element => {
    const onClick = (event?: React.MouseEvent<HTMLButtonElement> | undefined): void => props.onClick ? props.onClick(event, props.name) : undefined;
    return <LAToolTip title={props.label} className={props.className}>
        <span>
            <IconButton disabled={props.disabled} onClick={onClick} ref={props.anchorRef} style={props.style}>
                {props.icon}
            </IconButton>
            {props.addLabel && <strong>{props.label}</strong>}
        </span>
    </LAToolTip>
};


interface ILAButtonProps extends HasClass {
    label: string;
    disabled?: true;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    fullWidth?: true;
    onClick: () => void;
    green?: true;
    grey?: true;
    blue?: true;
}

const buttonTheme = createTheme({
    palette: {
        primary: {
            main: RED_COLOR,
            contrastText: WHITE_COLOR
        },
        background: {
            default: RED_COLOR
        }
    }
});

const greenButtonTheme = createTheme({
    palette: {
        primary: {
            main: GREEN_COLOR,
            contrastText: WHITE_COLOR
        },
        background: {
            default: GREEN_COLOR
        }
    }
});

const orangeButtonTheme = createTheme({
    palette: {
        primary: {
            main: DARK_ORANGE_COLOR,
            contrastText: WHITE_COLOR
        },
        background: {
            default: DARK_ORANGE_COLOR
        }
    }
});

const greyButtonTheme = createTheme({
    palette: {
        primary: {
            main: EXTRA_DARK_GREY_COLOR,
            contrastText: DARK_GREY_COLOR
        },
        background: {
            default: EXTRA_DARK_GREY_COLOR
        }
    }
});

const blueButtonTheme = createTheme({
    palette: {
        primary: {
            main: BLUE_COLOR,
            contrastText: WHITE_COLOR
        },
        background: {
            default: BLUE_COLOR
        }
    }
});


export const LARedButton: React.FC<ILAButtonProps> = React.memo((props: ILAButtonProps) =>
    <ThemeProvider theme={buttonTheme}>
        <Button variant="contained" color="primary" disabled={props.disabled} fullWidth={props.fullWidth} className={props.className} startIcon={props.startIcon} endIcon={props.endIcon} onClick={props.onClick}>
            {props.label}
        </Button>
    </ThemeProvider>
);

export const LAGreenButton: React.FC<ILAButtonProps> = React.memo((props: ILAButtonProps) =>
    <ThemeProvider theme={greenButtonTheme}>
        <Button variant="contained" color="primary" disabled={props.disabled} fullWidth={props.fullWidth} className={props.className} startIcon={props.startIcon} endIcon={props.endIcon} onClick={props.onClick}>
            {props.label}
        </Button>
    </ThemeProvider>
);

export const LAOrangeButton: React.FC<ILAButtonProps> = React.memo((props: ILAButtonProps) =>
    <ThemeProvider theme={orangeButtonTheme}>
        <Button variant="contained" color="primary" disabled={props.disabled} fullWidth={props.fullWidth} className={props.className} startIcon={props.startIcon} endIcon={props.endIcon} onClick={props.onClick}>
            {props.label}
        </Button>
    </ThemeProvider>
);


export const LASecondaryButton: React.FC<ILAButtonProps> = React.memo((props: ILAButtonProps) =>
    <ThemeProvider theme={props.grey ? greyButtonTheme : props.blue ? blueButtonTheme : props.green ? greenButtonTheme : buttonTheme}>
        <Button variant="outlined" color="primary" disabled={props.disabled} fullWidth={props.fullWidth} className={props.className} startIcon={props.startIcon} endIcon={props.endIcon} onClick={props.onClick}>
            {props.label}
        </Button>
    </ThemeProvider>
);


export const LAButton: React.FC<ILAButtonProps> = React.memo((props: ILAButtonProps) =>
    <ThemeProvider theme={blueButtonTheme}>
        <Button variant="contained" color="primary" disabled={props.disabled} fullWidth={props.fullWidth} className={props.className} startIcon={props.startIcon} endIcon={props.endIcon} onClick={props.onClick}>
            {props.label}
        </Button>
    </ThemeProvider>
);

export const LAIconUploadButton = (props: ILAIconButtonProps): JSX.Element =>
    <LAToolTip title={props.label} className={props.className}>
        <label htmlFor={props.id}>
            <IconButton color="primary" aria-label="upload" component="span">
                {props.icon}
            </IconButton>
        </label>
        <input disabled={props.disabled} style={{ display: "none" }} multiple={true} type="file" accept={props.imagesOnly ? "image/*" : "image/*,.pdf,.doc,.docx,.xml"} id={props.id} name={props.label} onChange={(e: any) => props.onClick ? props.onClick(e) : undefinedFunction} />
        {props.linkButton && props.downloadFile ? <><span>{props.label}</span> <br /><LALinkButton className="pl-5" label={props.linkLabel ?? props.label} onClick={props.downloadFile} /> </> : props.label}
    </LAToolTip>
    ;

export const SharedTwoButtonStyles = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;
`;

interface ILASaveAndCancelButtonProps {
    disableSave?: true;
    disableCancel?: true;
    saveIcon?: React.ReactNode;
    cancelIcon?: React.ReactNode;
    cancelStartIcon?: React.ReactNode;
    saveButtonText?: string;
    cancelButtonText?: string;
    fullWidth?: true;
    onSave: () => void;
    onCancel: () => void;
}

export const LASaveAndCancelButton = (props: ILASaveAndCancelButtonProps): JSX.Element => {
    return <SharedTwoButtonStyles>
        <LAGrid>
            <LAGridItem xs={6}>
                <LARedButton label={props.cancelButtonText ?? "Close"} disabled={props.disableCancel} fullWidth={props.fullWidth}
                    startIcon={props.cancelIcon} onClick={props.onCancel} />
            </LAGridItem>

            <LAGridItem xs={6}>
                <LAGreenButton label={props.saveButtonText ?? "Save"} disabled={props.disableSave} fullWidth={props.fullWidth}
                    startIcon={props.saveIcon} onClick={props.onSave} />
            </LAGridItem>
        </LAGrid>
    </SharedTwoButtonStyles>;
};


export const LAFlatButton = (props: ILAButtonProps): JSX.Element =>
    <Button color="primary" className={props.className} onClick={props.onClick} disabled={props.disabled} startIcon={props.startIcon} endIcon={props.endIcon}>
        {props.label}
    </Button>
    ;