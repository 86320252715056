
export enum IADD_ROVER_KIT_REQUEST {
    REQUEST = "addRoverKit/ADD_ROVER_KIT_REQUEST",
    SUCCESS = "addRoverKit/ADD_ROVER_KIT_SUCCESS",
    FAILED = "addRoverKit/ADD_ROVER_KIT_FAILED"
};

export interface IAddUpdateRoverKitRequest {
    token: string;
    request: IAddUpdateRoverKit;
};

export interface IAddUpdateRoverKit {
    ID: number;
    Kit_No: string;
    Unit_No: string;
    Model: string;
    Data_Collector_Unit_No: string;
    Data_Collector_Model: string;
    Antenna: string;
    Chargers: string;
    Status: string;
    // Site: string;
    WSS_ID: number | null;
    Operator_ID?: number;
    Notes: string;
    Created_By: string;
    Modified_By: string;
};