import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SHOP_EQUIPMENTS_REQUEST, IShopEquipment } from "./getEquipmentConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetShopEquipmentsLoadAction, IGetShopEquipmentsLoadFailedAction, IGetShopEquipmentsSuccessAction, getShopEquipmentsLoadFailedAction, getShopEquipmentsLoadSuccessAction } from "./getEquipmentActions";

export const getShopEquipmentsEpic: Epic = (
    action$: ActionsObservable<IGetShopEquipmentsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetShopEquipmentsSuccessAction | IGetShopEquipmentsLoadFailedAction> =>
    action$.ofType(IGET_SHOP_EQUIPMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IShopEquipment>>>(
                    END_POINTS.FIELD.GET_EQUIPMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IShopEquipment>>): IGetShopEquipmentsSuccessAction => {
                            return getShopEquipmentsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getShopEquipmentsLoadFailedAction(response.message)))
                    )
            )
        );