import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SERVICE_DUES_REQUEST {
    REQUEST = "getServiceDues/GET_SERVICE_DUES_REQUEST",
    SUCCESS = "getServiceDues/GET_SERVICE_DUES_SUCCESS",
    FAILED = "getServiceDues/GET_SERVICE_DUES_FAILED"
};

export interface IGetServiceDueRequest extends ISurewayTokenRequestBody {

};

export interface IServiceDue {
    id: number;
    service_Due_Name: string;
    active: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};