import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINES_WO_UNITS_REQUEST, IRepairLineWOUnits, IGetRepairLineWOUnitsRequest } from "./getRepairLineWOUnitsConstants";

export interface IGetRepairLineWOUnitsLoadAction {
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.REQUEST;
    data: IGetRepairLineWOUnitsRequest
}
export const getRepairLineWOUnitsLoadAction = (data: IGetRepairLineWOUnitsRequest): IGetRepairLineWOUnitsLoadAction => ({
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineWOUnitsSuccessAction {
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IRepairLineWOUnits>>;
}
export const getRepairLineWOUnitsLoadSuccessAction = (list: SurewayAPIResponse<ById<IRepairLineWOUnits>>): IGetRepairLineWOUnitsSuccessAction => ({
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineWOUnitsLoadFailedAction {
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.FAILED;
    message: string;
}
export const getRepairLineWOUnitsLoadFailedAction = (message: string): IGetRepairLineWOUnitsLoadFailedAction => ({
    type: IGET_REPAIR_LINES_WO_UNITS_REQUEST.FAILED,
    message
});
