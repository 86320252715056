import { SurewayAPIResponse } from './../../../react/shared/publicInterfaces';
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST, IFormResponseByUserToken } from "./getFormResponseByUserTokenConstants";
import { IGetFormResponseByUserTokenLoadAction, IGetFormResponseByUserTokenLoadFailedAction, IGetFormResponseByUserTokenSuccessAction } from "./getFormResponseByUserTokenActions";
import { ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST } from '../saveEmployeeMessageResponse/saveEmployeeMessageResponseConstants';
import { ISaveEmployeeMessageResponseSuccessAction } from '../saveEmployeeMessageResponse/saveEmployeeMessageResponseActions';

type Actions =
    | IGetFormResponseByUserTokenLoadAction
    | IGetFormResponseByUserTokenSuccessAction
    | IGetFormResponseByUserTokenLoadFailedAction
    | ISaveEmployeeMessageResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetFormResponseByUserTokenReducer = (state: Server<SurewayAPIResponse<IFormResponseByUserToken>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IFormResponseByUserToken>> => {
    switch (action.type) {
        case IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

            case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};