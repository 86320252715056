import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_VENDOR_ORDER_REQUEST, IVendorOrder } from "./getVendorOrdersConstants";
import { IGetVendorOrderLoadAction, IGetVendorOrderLoadFailedAction, IGetVendorOrderSuccessAction, getVendorOrderLoadFailedAction, getVendorOrderLoadSuccessAction } from "./getVendorOrdersActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getVendorOrderEpic: Epic = (
    action$: ActionsObservable<IGetVendorOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetVendorOrderSuccessAction | IGetVendorOrderLoadFailedAction> =>
    action$.ofType(IGET_VENDOR_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IVendorOrder>>>(
                    END_POINTS.FIELD.VENDOR.GET_VENDOR_ORDERS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IVendorOrder>>): IGetVendorOrderSuccessAction => {
                            return getVendorOrderLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getVendorOrderLoadFailedAction(response.message)))
                    )
            )
        );