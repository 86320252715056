import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ITechService } from "../getTechServices/getTechServicesConstants";
import { IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST, IGetTechServicesByUnitIDRequest } from "./getTechServicesByUnitIDConstants";

export interface IGetTechServicesByUnitIDLoadAction {
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.REQUEST;
    data: IGetTechServicesByUnitIDRequest
}

export const getTechServicesByUnitIDLoadAction = (data: IGetTechServicesByUnitIDRequest): IGetTechServicesByUnitIDLoadAction => ({
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.REQUEST,
    data
});

export interface IGetTechServicesByUnitIDSuccessAction {
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ITechService[]>;
}

export const getTechServicesByUnitIDLoadSuccessAction = (list: SurewayAPIResponse<ITechService[]>): IGetTechServicesByUnitIDSuccessAction => ({
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.SUCCESS,
    list
});

export interface IGetTechServicesByUnitIDLoadFailedAction {
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.FAILED;
    message: string;
}

export const getTechServicesByUnitIDLoadFailedAction = (message: string): IGetTechServicesByUnitIDLoadFailedAction => ({
    type: IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.FAILED,
    message
});
