import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SERVICE_DUE_REQUEST, IUpdateServiceDueRequest } from "./updateServiceDueConstants";


export interface IUpdateServiceDueLoadAction {
    type: IUPDATE_SERVICE_DUE_REQUEST.REQUEST;
    data: IUpdateServiceDueRequest
}
export const updateServiceDueLoadAction = (data: IUpdateServiceDueRequest): IUpdateServiceDueLoadAction => ({
    type: IUPDATE_SERVICE_DUE_REQUEST.REQUEST,
    data
});
export interface IUpdateServiceDueSuccessAction {
    type: IUPDATE_SERVICE_DUE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateServiceDueLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateServiceDueSuccessAction => ({
    type: IUPDATE_SERVICE_DUE_REQUEST.SUCCESS,
    message
});
export interface IUpdateServiceDueLoadFailedAction {
    type: IUPDATE_SERVICE_DUE_REQUEST.FAILED;
    message: string;
}
export const updateServiceDueLoadFailedAction = (message: string): IUpdateServiceDueLoadFailedAction => ({
    type: IUPDATE_SERVICE_DUE_REQUEST.FAILED,
    message
});
