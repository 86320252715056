import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_METER_HISTORY_BY_SOCT_ID_REQUEST, IMeterHistory, IGetMeterHistoryBySOCTIDRequest } from "./getMeterHistoryBySOCTIDConstants";

export interface IGetMeterHistoryBySOCTIDLoadAction {
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.REQUEST;
    data: IGetMeterHistoryBySOCTIDRequest
}

export const getMeterHistoryBySOCTIDLoadAction = (data: IGetMeterHistoryBySOCTIDRequest): IGetMeterHistoryBySOCTIDLoadAction => ({
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.REQUEST,
    data
});

export interface IGetMeterHistoryBySOCTIDSuccessAction {
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMeterHistory>>;
}

export const getMeterHistoryBySOCTIDLoadSuccessAction = (list: SurewayAPIResponse<ById<IMeterHistory>>): IGetMeterHistoryBySOCTIDSuccessAction => ({
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS,
    list
});

export interface IGetMeterHistoryBySOCTIDLoadFailedAction {
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.FAILED;
    message: string;
}

export const getMeterHistoryBySOCTIDLoadFailedAction = (message: string): IGetMeterHistoryBySOCTIDLoadFailedAction => ({
    type: IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.FAILED,
    message
});
