import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_REPAIR_LINE_ITEM_REQUEST } from "./saveRepairLineItemConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { ISaveRepairLineItemLoadAction, ISaveRepairLineItemLoadFailedAction, ISaveRepairLineItemSuccessAction, saveRepairLineItemLoadFailedAction, saveRepairLineItemLoadSuccessAction } from './saveRepairLineItemActions';

export const saveRepairLineItemEpic: Epic = (
    action$: ActionsObservable<ISaveRepairLineItemLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveRepairLineItemSuccessAction | ISaveRepairLineItemLoadFailedAction> =>
    action$.ofType(ISAVE_REPAIR_LINE_ITEM_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.SAVE_REPAIR_LINE_ITEM,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveRepairLineItemSuccessAction | ISaveRepairLineItemLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveRepairLineItemLoadSuccessAction(response);
                            } else {
                                return saveRepairLineItemLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveRepairLineItemLoadFailedAction(response.message)))
                    )
            )
        );