import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ST_SCHEDULES_REQUEST {
    REQUEST = "getSTSchedules/GET_ST_SCHEDULES_REQUEST",
    SUCCESS = "getSTSchedules/GET_ST_SCHEDULES_SUCCESS",
    FAILED = "getSTSchedules/GET_ST_SCHEDULES_FAILED"
};

export interface IGetSTSchedulesRequest extends ISurewayTokenRequestBody {
    request: {
        Token: string;
    }
};

export interface ISTSchedules {
    id: number;
    assigned_Site: string;
    notes: string;
    eta: string;
    status: string;
    foreman_At_Site: string;
    equipment_At_Site: number;
    service_Truck: string;
    driver: string;
    swamper: string;
    drivingAtSite?: boolean;
    atSite?: boolean;
    completed?: boolean;
};