
export enum IGET_REPAIRLINE_HISTORY_REQUEST {
    REQUEST = "getRepairLineHistory/GET_REPAIRLINE_HISTORY_REQUEST",
    SUCCESS = "getRepairLineHistory/GET_REPAIRLINE_HISTORY_SUCCESS",
    FAILED = "getRepairLineHistory/GET_REPAIRLINE_HISTORY__FAILED"
};

export interface IGetRepairLineHistoryRequest {
    request: {
        ID: number;
        Type: string
    };
    token: string;
};

export interface IGetRepairLineHistory {
    id: number;
    repair_Line_ID: number;
    unit_Attachment: string;
    work_Order_No: string;
    type:string;
    serial_no: number;
    hours: number;
    major_Component_Replacement: string;
    major_Component_Replacement_Notes: string;
    damaged: string;
    damaged_Notes: string;
    comments: string;
    foreman_Comments: string;
    job_No: string;
    meter_Reading: number;
    initiated_Date: string;
    requested_By: string;
    assigned_Mech: string;
    status: string;
    manager_Instructions: string;
    parts_Instructions: string;
    component: string;
    component_Code: number;
    priority: string;
    location_Info: string;
    date_Completed: string;
    // components_Details: string;
    // attachment_ID: number;
    // attachment_No: string;
    // display: string;
    // created_By: string;
    // modified_By: string;
    // created: string;
    modified: string;
};