import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ST_SCHEDULES_REQUEST, ISTSchedules } from "./getSTSchedulesConstants";
import { IGetSTSchedulesLoadAction, IGetSTSchedulesLoadFailedAction, IGetSTSchedulesSuccessAction } from "./getSTSchedulesActions";
import { IUpdateSTSchedulesSuccessAction } from "../updateSTSchedules/updateSTSchedulesActions";
import { IUPDATE_ST_SCHEDULES_REQUEST } from "../updateSTSchedules/updateSTSchedulesConstants";

type Actions =
    | IGetSTSchedulesLoadAction
    | IGetSTSchedulesSuccessAction
    | IGetSTSchedulesLoadFailedAction
    | IUpdateSTSchedulesSuccessAction
    | IFlushDataSuccessAction;

export const GetSTSchedulesReducer = (state: Server<SurewayAPIResponse<ById<ISTSchedules>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ISTSchedules>>> => {
    switch (action.type) {
        case IGET_ST_SCHEDULES_REQUEST.REQUEST:
            return loading;

        case IGET_ST_SCHEDULES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ST_SCHEDULES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ST_SCHEDULES_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};