import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PAYROLL_MECHANIC_LOGS_REQUEST, IPayrollMechanicLogs } from "./getPayrollMechanicLogsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetPayrollMechanicLogsLoadAction, IGetPayrollMechanicLogsLoadFailedAction, IGetPayrollMechanicLogsSuccessAction, getPayrollMechanicLogsLoadFailedAction, getPayrollMechanicLogsLoadSuccessAction } from "./getPayrollMechanicLogsActions";

export const getPayrollMechanicLogsEpic: Epic = (
    action$: ActionsObservable<IGetPayrollMechanicLogsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPayrollMechanicLogsSuccessAction | IGetPayrollMechanicLogsLoadFailedAction> =>
    action$.ofType(IGET_PAYROLL_MECHANIC_LOGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IPayrollMechanicLogs[]>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_PAYROLL_MECHANIC_LOGS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IPayrollMechanicLogs[]>): IGetPayrollMechanicLogsSuccessAction => {
                            return getPayrollMechanicLogsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getPayrollMechanicLogsLoadFailedAction(response.message)))
                    )
            )
        );