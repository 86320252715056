import { ReactNode, PureComponent, useCallback, useState } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { onExporting } from "../../shared/devExtreme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LAErrorBox from "../../shared/errorBox";
import { LAButton } from "../../shared/buttons";
import React from "react";
import { DataTimer } from "../../shared/timer";
import { BLUE_COLOR, GREEN_COLOR, MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { AttachmentSubHeader, AttachmentSubHeaderMobile } from "../../header/attachmentSubHeader";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getAttachmentsStatus } from "../../../redux/field/attachment/getAttachments/getAttachmentsAccessor";
import { getAttachmentListGET } from "../../../redux/field/attachment/getAttachmentListGET/getAttachmentListGETAccessor";
import { getAttachmentListGETLoadAction } from "../../../redux/field/attachment/getAttachmentListGET/getAttachmentListGETActions";
import { addAttachmentListGETLoadAction } from "../../../redux/field/attachment/addAttachmentListGET/addAttachmentListGETActions";
import { IUpdateAttachmentListGETRequest } from "../../../redux/field/attachment/updateAttachmentListGET/updateAttachmentListGETConstants";
import { addAttachmentListGETStatus } from "../../../redux/field/attachment/addAttachmentListGET/addAttachmentListGETAccessor";
import { updateAttachmentListGETStatus } from "../../../redux/field/attachment/updateAttachmentListGET/updateAttachmentListGETAccessor";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { IAttachment, IGetAttachmentRequest } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
import { getAttachmentsLoadAction } from "../../../redux/field/attachment/getAttachments/getAttachmentsActions";
import { updateAttachmentListGETLoadAction } from "../../../redux/field/attachment/updateAttachmentListGET/updateAttachmentListGETActions";
import { IAttachmentListGET, IGetAttachmentListGETRequest } from "../../../redux/field/attachment/getAttachmentListGET/getAttachmentListGETConstants";
import { IAddAttachmentListGETRequest, IAddUpdateAttachmentListGET } from "../../../redux/field/attachment/addAttachmentListGET/addAttachmentListGETConstants";
import DataGrid, { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Lookup, RequiredRule, Scrolling, SearchPanel, StateStoring } from "devextreme-react/data-grid";
import { deleteAttachmentListGETStatus } from "../../../redux/field/attachment/deleteAttachmentListGET/deleteAttachmentListGETAccessor";
import { IDeleteAttachmentListGETRequest } from "../../../redux/field/attachment/deleteAttachmentListGET/deleteAttachmentListGETConstants";
import { deleteAttachmentListGETLoadAction } from "../../../redux/field/attachment/deleteAttachmentListGET/deleteAttachmentListGETActions";
import { AttachmentGetCloneClone } from "./attachmentGETClone";
import { ICloneAttachmentGET, ICloneAttachmentGETRequest } from "../../../redux/field/attachment/cloneAttachmentGET/cloneAttachmentGETConstants";
import { cloneAttachmentGETStatus } from "../../../redux/field/attachment/cloneAttachmentGET/cloneAttachmentGETAccessor";
import { cloneAttachmentGETLoadAction } from "../../../redux/field/attachment/cloneAttachmentGET/cloneAttachmentGETActions";

interface IAttachmentListGETStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    addRequest: Server<SurewayAPIResponse<string>>;
    updateRequest: Server<SurewayAPIResponse<string>>;
    deleteRequest: Server<SurewayAPIResponse<string>>;
    getDropDownData: Server<SurewayAPIResponse<IFieldLookup>>;
    attachments: Server<SurewayAPIResponse<ById<IAttachment>>>;
    saveClone: Server<SurewayAPIResponse<string>>;
    getAttachmentListGET: Server<SurewayAPIResponse<ById<IAttachmentListGET>>>;
};

interface IAttachmentListGETDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getDropDownDataRequest: (data: IFieldLookupRequest) => unknown;
    getAttachmentsRequest: (data: IGetAttachmentRequest) => unknown;
    saveCloneRequest: (request: ICloneAttachmentGETRequest) => unknown;
    deleteAttachmentRequest: (data: IDeleteAttachmentListGETRequest) => unknown;
    addAttachmentListGETRequest: (data: IAddAttachmentListGETRequest) => unknown;
    getAttachmentListGETRequest: (data: IGetAttachmentListGETRequest) => unknown;
    updateAttachmentListGETRequest: (data: IUpdateAttachmentListGETRequest) => unknown;
};

interface IAttachmentListGETOwnProps {

};

interface IAttachmentListGETState {
    clone: boolean;
    editMode: boolean;
    data: ById<IAttachmentListGET>;
    cloneError: string | undefined;
    serverError: string | undefined;
};

const AttachmentListGETStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .right-side {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .clear-filters {       
        margin: 10px 10px;
        background-color: rgb(168, 0, 0);
    };

    .green-text {
        color: ${GREEN_COLOR};
        font-size: 9px;
    };

    .blue-text {
        color: ${BLUE_COLOR};
        font-size: 9px;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .right-side {
            top: auto;
            right: auto;
            position: static;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IAttachmentListGETProps = RouteComponentProps
    & IAttachmentListGETStoreProps
    & IAttachmentListGETDispatchProps
    & IAttachmentListGETOwnProps;

class AttachmentListGET extends PureComponent<IAttachmentListGETProps, IAttachmentListGETState> {

    public constructor(props: IAttachmentListGETProps) {
        super(props);
        this.state = {
            data: {},
            clone: false,
            editMode: false,
            serverError: undefined,
            cloneError: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAttachmentListGETProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addRequest !== prevProps.addRequest) {
                if (this.props.addRequest.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRequest.message });

                if (hasPayload(this.props.addRequest) && (this.props.addRequest.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

            if (this.props.saveClone !== prevProps.saveClone) {
                if (this.props.saveClone.kind === STATUS_ENUM.FAILED)
                    this.setState({ cloneError: this.props.saveClone.message });

                if ((this.props.saveClone.kind === STATUS_ENUM.SUCCEEDED) && (this.state.clone))
                    this.onClone();
            };

            if (this.props.updateRequest !== prevProps.updateRequest) {
                if (this.props.updateRequest.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRequest.message });

                if (hasPayload(this.props.updateRequest) && (this.props.updateRequest.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

        };
    };


    public render(): ReactNode {

        const { editMode, data, serverError, clone, cloneError } = this.state;
        const { getToken, addRequest, updateRequest, getDropDownData, attachments, saveClone } = this.props;

        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);
        const getRole = pageAccessCheck(getToken, "attachmentAccess");
        const upn = hasPayload(getToken) ? getToken.payload.response.upn : "";
        const cloneDropDown = hasPayload(attachments) ? Object.values(attachments.payload.response) : [];
        const lookUp = hasPayload(getDropDownData) ? getDropDownData.payload.response : { types: [], couplers: [], styles: [] };

        return (
            <PageSpacing title="Attachment" description="ATTACHMENT - GET" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <>
                        <AttachmentListGETStyles>
                            <div className="hide-on-phone">
                                <AttachmentSubHeader
                                    {...this.props}
                                    role={getRole}
                                />
                            </div>

                            <div className="show-on-phone">
                                <AttachmentSubHeaderMobile
                                    {...this.props}
                                    role={getRole}
                                />
                            </div>

                            <h2 className="text-center">ATTACHMENT LIST - GET PARTS</h2>
                            <hr />

                            {serverError && <LAErrorBox text={serverError} />}

                            <AttachmentListGETDevExtreme
                                data={data}
                                lookUp={lookUp}
                                editMode={editMode}
                                onClone={this.onClone}
                                onEditStart={onEditStart}
                                onEditCancel={onEditCancel}
                                onSaveClick={this.onSaveClick}
                                attachments={this.getAttachmentWithAll()}
                                getRefreshDataCall={this.getDataForTable}
                            />

                            {clone && <AttachmentGetCloneClone 
                                open={clone}
                                username={upn}
                                onClose={this.onClone}
                                dropdown={cloneDropDown}
                                serverError={cloneError}
                                data={Object.values(data)}
                                onComplete={this.saveClone}
                            />}

                            <Prompt when={editMode} message="You have unsaved changes ?" />
                            <RequestStatus requestStatus={saveClone.kind} successMessage="Clone successfully saved" />
                            <RequestStatus requestStatus={addRequest.kind} successMessage="Record successfully saved" />
                            <RequestStatus requestStatus={updateRequest.kind} successMessage="Record successfully updated" />

                        </AttachmentListGETStyles>
                    </>}
            </PageSpacing>
        );
    }
    
    private saveClone = (request: ICloneAttachmentGET[]): void => {
        if(hasPayload(this.props.getToken)){
            this.props.saveCloneRequest({
                request,
                token: this.props.getToken.payload.response.token
            });
        };
    };

    private onClone = (): void => {
        this.setState({ clone: !this.state.clone, cloneError: undefined });
    };

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private getAttachmentWithAll = (): IAttachment[] => {

        if (hasPayload(this.props.attachments)) {
            const res = Object.values(this.props.attachments.payload.response);

            if (res.findIndex(x => x.id === 0) < 0)
                res.unshift(all);

            return res;
        };
        return [];
    };

    private onSaveClick = (record: any): void => {
        const { getToken, addAttachmentListGETRequest, updateAttachmentListGETRequest, getDropDownData } = this.props;


        if (hasPayload(getToken) && hasPayload(getDropDownData) && record.changes[0] !== undefined) {
            if (record.changes[0].type === "remove") {
                this.props.deleteAttachmentRequest({
                    request: {
                        id: record.changes[0].key
                    },
                    token: getToken.payload.response.token
                });
            } else {

                const { data } = record.changes[0];
                const lookUp = getDropDownData.payload.response;
                const attachmentList = this.getAttachmentWithAll();

                const couplerIdx = lookUp.couplers.findIndex(x => x.name === data.coupler_Design);
                const styleIdx = lookUp.styles.findIndex(x => x.name === data.style);
                const typeIdx = lookUp.types.findIndex(x => x.name === data.type);
                const attachmentIdx = attachmentList.findIndex(x => x.attachment_No === data.attachment_No);

                const id = isNaN(Number(data.id));

                let request: IAddUpdateAttachmentListGET = {
                    ID: id ? undefined : data.id,
                    Part_No: data.part_No,
                    Quantity: data.quantity,
                    Part_Type: data.part_Type,
                    Description: data.description,
                    Type_ID: typeIdx >= 0 ? lookUp.types[typeIdx].id : 0,
                    Style_ID: styleIdx >= 0 ? lookUp.styles[styleIdx].id : 0,
                    Created_By: id ? getToken.payload.response.upn : data.created_By,
                    Coupler_Design_ID: couplerIdx >= 0 ? lookUp.couplers[couplerIdx].id : 0,
                    Attachment_ID: attachmentIdx >= 0 ? attachmentList[attachmentIdx].id : 0,
                };

                if (isNaN(data.id)) {
                    addAttachmentListGETRequest({
                        request,
                        token: getToken.payload.response.token
                    });
                } else {
                    updateAttachmentListGETRequest({
                        request,
                        token: getToken.payload.response.token
                    });
                };

                this.updateEditMode(false);
            };
        };
    }
    
    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (isNotLoaded(this.props.attachments) && hasPayload(this.props.getToken))
            this.props.getAttachmentsRequest({
                token: this.props.getToken.payload.response.token,
            });

        if (isNotLoaded(this.props.getDropDownData) && hasPayload(this.props.getToken))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Attachment_List"
                }
            });

        if (hasPayload(this.props.getAttachmentListGET) && hasPayload(this.props.getToken)) {
            this.setState({ data: this.props.getAttachmentListGET.payload.response });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getAttachmentListGET))
            if ((pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable)) {
                this.getDataForTable();
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable)) {
            this.props.getAttachmentListGETRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAttachmentListGETStoreProps => ({
    getToken: getToken(state),
    getDropDownData: getFieldLookups(state),
    attachments: getAttachmentsStatus(state),
    saveClone: cloneAttachmentGETStatus(state),
    addRequest: addAttachmentListGETStatus(state),
    getAttachmentListGET: getAttachmentListGET(state),
    deleteRequest: deleteAttachmentListGETStatus(state),
    updateRequest: updateAttachmentListGETStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAttachmentListGETDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getDropDownDataRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getAttachmentsRequest: (data: IGetAttachmentRequest) => dispatch(getAttachmentsLoadAction(data)),
    saveCloneRequest: (data: ICloneAttachmentGETRequest) => dispatch(cloneAttachmentGETLoadAction(data)),
    addAttachmentListGETRequest: (data: IAddAttachmentListGETRequest) => dispatch(addAttachmentListGETLoadAction(data)),
    getAttachmentListGETRequest: (data: IGetAttachmentListGETRequest) => dispatch(getAttachmentListGETLoadAction(data)),
    deleteAttachmentRequest: (data: IDeleteAttachmentListGETRequest) => dispatch(deleteAttachmentListGETLoadAction(data)),
    updateAttachmentListGETRequest: (data: IUpdateAttachmentListGETRequest) => dispatch(updateAttachmentListGETLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentListGET);

const filterAttachments = (data: IAttachment[], type: string, coupler_Design: string, style: string): IAttachment[] => {
    const res: IAttachment[] = [all];

    data.forEach((x) => {
        if (x.type === type && x.coupler_Design === coupler_Design && x.style === style) {
            if (!res.some((item) => item.attachment_No === x.attachment_No)) {
                res.push(x);
            }
        }
    });

    return res;
};

const all = {
    id: 0,
    attachment_No: "All",
    attachment_Unit_ID: 0,
    style: "",
    make: "",
    model: "",
    serial_Number: "",
    owner: "",
    inspection_Date: "",
    detachable: "No",
    notes: "",
    photos: "",
    file_Attachments: "",
    status: "",
    move_Status: "",
    repair_Status: "",
    created_By: "",
    modified_By: "",
    created: "",
    modified: "",
    files: [],
    images: [],
    type: "",
    coupler_Design: "",
    location: "",
    attached_Unit_No: "",
    job_No: "",
};

const storageKey = "attachment-list-get-storage-key";
interface IAttachmentListGETDevExtreme {
    data: any;
    lookUp: any;
    attachments: any[];
    editMode: boolean;
    onClone: () => void;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
};

const AttachmentListGETDevExtreme: React.FC<IAttachmentListGETDevExtreme> = React.memo((props: IAttachmentListGETDevExtreme) => {

    const [searchValue, setSearchValue] = useState('');
    const onExportClick = (e: any): void => onExporting(e, "Attachment-GET");
    const [storageChange, setStorageChange] = useState<boolean>(false);

    const setType = (rowData: any, value: any) => {
        rowData.type = value;
        rowData.attachment_No = null;
    };

    

    const setCoupler = (rowData: any, value: any) => {
        rowData.coupler_Design = value;
        rowData.attachment_No = null;
    };

    const setStyle = (rowData: any, value: any) => {
        rowData.style = value;
        rowData.attachment_No = null;
    };

    const setAttachment = (rowData: any, value: any) => {
        if (value !== "All") {
            const idx = props.attachments.findIndex((x) => x.attachment_No === value);
            if (idx >= 0) {
                rowData.type = props.attachments[idx].type;
                rowData.style = props.attachments[idx].style;
                rowData.coupler_Design = props.attachments[idx].coupler_Design;
                rowData.unit_Number = props.attachments[idx].attached_Unit_No;
            };
        }
        rowData.attachment_No = value;
    };

    const getAttachments = (options: any) => {
        const newList = (options.data && options.data.type && options.data.coupler_Design && options.data.style) ?
            filterAttachments(props.attachments, options.data.type, options.data.coupler_Design, options.data.style)
            : props.attachments;

        if (options.data && options.data.type && options.data.style && options.data.coupler_Design)
            newList[0].type = options.data.type;
        /* 
            This filter options is required for Devextreme to work with cascading dropdown and I only add single filter ['type', '=', options.data.type]
            Because we all ready filtering attachment list in above call with function filterAttachments, so this is not required i  just added in type 
            filter for devextreme to work 
        */
        return {
            store: newList,
            filter: options.data && options.data.type && options.data.style && options.data.coupler_Design ? ['type', '=', options.data.type] : null,
        };
    };

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearFilters = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }
        }
    };

    const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };


    return (
        <LAGrid>

            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearFilters}
                    disabled={undefined}
                />

                <LAButton
                    label="Clone"
                    className="clear-filters"
                    onClick={props.onClone}
                    disabled={undefined}
                />

                {props.getRefreshDataCall && <DataTimer
                    key={storageKey}
                    className="right-side"
                    onTimerEnd={props.getRefreshDataCall}
                />}
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    showBorders={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    syncLookupFilterValues={false}
                    columnHidingEnabled={false}
                    filterSyncEnabled={false}
                    wordWrapEnabled={true}
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                >

                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={false} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <StateStoring
                        enabled={true}
                        storageKey={storageKey}
                    />
                    <SearchPanel
                        width={300}
                        visible={true}
                        placeholder="Search..."
                        // text={searchValue}
                        // onTextChange={handleValueChange}
                    />
                    <StateStoring
                        type="custom"
                        enabled={true}
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                    />

                    <Editing
                        mode="row"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                    />

                    <ColumnChooser
                        mode="select"
                        enabled={false}
                        height={window.innerHeight - 100}
                    />
                    <Column dataField="id" caption="ID" dataType="number" allowEditing={false} sortOrder="asc" />

                    <Column dataField="attachment_No" caption="Attachment #" width={100} setCellValue={setAttachment}>
                        <Lookup
                            dataSource={getAttachments}
                            displayExpr="attachment_No" valueExpr="attachment_No"
                        />
                    </Column>
                    <Column dataField="unit_Number" caption="Unit #" allowEditing={false} />
                    <Column dataField="type" caption="Type" width={180} setCellValue={setType}>
                        <Lookup dataSource={props.lookUp.types} displayExpr="name" valueExpr="name" />
                    </Column>

                    <Column dataField="coupler_Design" caption="Coupler Design" width={150} setCellValue={setCoupler}>
                        <Lookup dataSource={props.lookUp.couplers} displayExpr="name" valueExpr="name" />
                    </Column>

                    <Column dataField="style" caption="Style" width={200} setCellValue={setStyle}>
                        <Lookup dataSource={props.lookUp.styles} displayExpr="name" valueExpr="name" />
                    </Column>

                    

                    <Column dataField="quantity" caption="Quantity" dataType="number" allowEditing={true}>
                        <RequiredRule />
                    </Column>

                    <Column dataField="part_No" caption="Part #" dataType="string" allowEditing={true}>
                        <RequiredRule />
                    </Column>

                    {/* <Column dataField="part_Type" caption="Part Type" dataType="string" allowEditing={true}>
                        <RequiredRule />
                    </Column> */}

                    <Column dataField="description" caption="Description" dataType="string" allowEditing={true} width={600} 
                        editorOptions={{
                            multiline: true,
                            onKeyDown: (e:any) => {
                                if (e.event.key === "Enter" && !e.event.ctrlKey && !e.event.shiftKey) {
                                    e.event.stopPropagation();
                                }
                            },
                        }}/>
                    {/* <Column dataField="shop_Bin" caption="1492 Bin" allowEditing={false} />
                    <Column dataField="shop_stock" caption="1492 Stock" allowEditing={false} />
                    <Column dataField="silBru_Bin" caption="Sil Bru Stock" allowEditing={false} />
                    <Column dataField="silBru_stock" caption="Sil Bru Bin" allowEditing={false} /> */}
                    {/* <Column dataField="created_By" caption="Created By" allowEditing={false} /> */}
                    <Column dataField="created" caption="Created" dataType="datetime" allowEditing={false} />

                </DataGrid>
            </LAGridItem>

        </LAGrid>
    )
});



