import React from 'react';
import DataGrid, {
    Column, Export, FilterRow, FilterPanel, HeaderFilter, Paging, Pager, SearchPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import { FormControlLabel, Grid, Button as MUIButton, Radio, RadioGroup} from '@mui/material';
import { getSOCTMeterReports } from '../../../api/meterreading/meterReadingAPI';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import LAGridItem from '../../../../../shared/gridList';
import { undefinedFunction } from '../../../../../shared/constExports';

  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
function StyledComponent(cellData:any):any {
    switch(cellData.data.type){
        case 'Meter History':
            return <div style={{
                backgroundColor: 'rgb(146, 218, 150)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Scheduled Service':
            return <div style={{
                backgroundColor: 'rgb(236, 176, 65)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Custom Service':
            return <div style={{
                backgroundColor: 'rgb(122, 90, 150)',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
        case 'Equipment Moves':
            return <div style={{
                backgroundColor: '#5383d3',
                color: 'black',
                font: "Cookie, Arial, Helvetica, sans-serif",
                fontSize: '16px',
                padding: '5px'}}
                >{cellData.value}</div>;
    }
}
interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState {
    searchValue:string;
    view: string;
    data: any[];
}
var meter_reports:any[] = [];
export default class EditReportTable extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {searchValue: '', view: 'all', data: []};
    }
    async componentDidMount(){
        meter_reports = [];
        meter_reports = await getSOCTMeterReports(this.props.editData.unit_Number);
        // console.log(meter_reports)
        this.setState({data: meter_reports});
    }
    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue:trimmedValue });
      };

    onViewClick = (e:any, value: string): void => {
        // console.log(value)
        this.setState({ view: value });
    };

    filterDataByView = (data:any, view:string) => {
        switch (view) {
            case "all":
                return data;
            case "ET Move Request":
                return data.filter((x:any) => x.type === view || x.type === "SOCT Equipment Move");
            case "PM":
                return data.filter((x:any) => x.type === view || x.type === "SOCT Scheduled Service");
            case "Unit_History":
                return data.filter((x:any) => x.type === view || x.type === "Owner_History");
            case "SOCT Meter History":
                return data.filter((x:any) => x.type === view);
            default:
                return data;
        }
    }

    render(){
        const { data, view, searchValue  } = this.state;
        const filteredData = this.filterDataByView(data, view);
        // console.log(filteredData)
        return (
            <>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <h1 className="text-center">Machine History</h1>
                    <LAGridItem xs={12}>
                        <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                            <FormControlLabel value="ET Move Request" control={<Radio />} label="ET Move Request" />
                            <FormControlLabel value="PM" control={<Radio />} label="PM" />
                            {/* <FormControlLabel value="SOCT Equipment Move" control={<Radio />} label="SOCT Equipment Move" /> */}
                            <FormControlLabel value="SOCT Meter History" control={<Radio />} label="SOCT Meter History" />
                            {/* <FormControlLabel value="SOCT Scheduled Service" control={<Radio />} label="SOCT Scheduled Service" /> */}
                            {/* <FormControlLabel value="Owner_History" control={<Radio />} label="Owner_History" /> */}
                            <FormControlLabel value="Unit_History" control={<Radio />} label="Unit_History" />
                            <FormControlLabel value="all" control={<Radio />} label="All" />
                        </RadioGroup>
                    </LAGridItem>
                    <DataGrid focusedRowEnabled={true}
                        hoverStateEnabled={true}
                        dataSource={filteredData}
                        keyExpr="roW_ID"
                        showBorders={true}
                        onRowPrepared={(e: RowPreparedEvent<any, unknown>) => {
                            if(e.data){
                                switch(e.data.type){
                                    case 'SOCT Meter History':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'SOCT Scheduled Service':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'SOCT Scheduled Service - Completed':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'SOCT Equipment Move':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'ET Move Request':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'PM':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'Repair Line':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'Unit_History':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                    case 'Owner_History':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                }
                                switch(e.data.custom_Service){
                                    case 'Yes':
                                        e.rowElement.style.backgroundColor = e.data.color;
                                        break;
                                        
                                }
                                switch(e.data.meter_Reset){
                                    case 'Yes':
                                        e.rowElement.style.backgroundColor = 'red';
                                        break;
                                }
                            }
                        }}
                        wordWrapEnabled={true}
                        >
                            <SearchPanel visible={true}
                                width={340}
                                placeholder="Search for..." 
                                // text={searchValue}
                                // onTextChange={this.handleValueChange}
                            />    
                            <FilterRow visible={true}></FilterRow>
                            <FilterPanel visible={true} />
                            <HeaderFilter visible={true} allowSearch={true} />
                            <Paging enabled={true} defaultPageSize={20}/>
                            <Pager
                                visible={true}
                                allowedPageSizes={[20,40,60]}
                                displayMode={'full'}
                                showPageSizeSelector={true}
                                showInfo={true}
                                showNavigationButtons={true} />
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Column dataType="string" dataField="type" caption="Type" alignment="left"  headerCellRender={renderTitleHeader} />
                            <Column defaultSortOrder="desc" dataType="date" dataField="date" caption="Specified Date" alignment="left" headerCellRender={renderTitleHeader}/>
                            <Column dataType="string" dataField="hours" caption="Meter Reading" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
                            <Column dataType="string" dataField="location" caption="Location" alignment="left" headerCellRender={renderTitleHeader}/>
                            <Column dataType="string" dataField="service_Type" caption="Service Type" alignment="left" headerCellRender={renderTitleHeader}/>
                            <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                            <Column dataType="string" dataField="custom_Service" caption="Custom Service" alignment="left" headerCellRender={renderTitleHeader}/>
                            <Column dataType="datetime" dataField="created_By" caption="Created By" alignment="left" headerCellRender={renderTitleHeader}/>
                    </DataGrid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            </>
        );
    }
}