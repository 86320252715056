import React, { useEffect, useState } from 'react';
import { getMoveRequestVersionHistory } from '../../api/equipmenttracker/equipmentTrackerAPI';
import { LAPopover } from '../../../../shared/popOver';
import { LADevExtremeGrid } from '../../../../shared/devExtreme';
import { undefinedFunction } from '../../../../shared/constExports';
import LAErrorBox from '../../../../shared/errorBox';
import LAGrid from '../../../../shared/grid';
import LAGridItem from '../../../../shared/gridList';
import { LACenteredLoading } from '../../../../shared/loading';
import { LAPaperWithPadding } from '../../../../shared/paper';


interface IMoveRequestVersionHistoryProps {
  id: number;
  open: boolean;
  onCancel: () => void;
};

export const MoveRequestVersionHistory: React.FC<IMoveRequestVersionHistoryProps> = React.memo((props: IMoveRequestVersionHistoryProps) => {

  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const apiRes = await getMoveRequestVersionHistory(+props.id);
    setData(apiRes);
    setLoading(false);
  };

  return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
    <LAPaperWithPadding>
      <LAGrid spacing={3} className="text-center">

        <LAGridItem xs={12}>
          <h3>VERSION HISTORY</h3>
          <hr />
        </LAGridItem>

        {loading && <LAGridItem xs={12}>
          <LACenteredLoading message="Loading Version History..." />
        </LAGridItem>}

        {!loading && !data && <LAGridItem xs={12}>
          <LAErrorBox text="Failed to load..." />
        </LAGridItem>}

        {(!loading && data && data.length === 0)
          && <LAGridItem xs={12}>
            <LAErrorBox text="No records Found" />
          </LAGridItem>}

        <LAGridItem xs={12}>
          <LADevExtremeGrid
            height={600}
            id="versionHistory"
            searchPanel={false}
            filterHeader={false}
            data={data}
            onClick={undefinedFunction}
            columns={[
              { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
              { name: "modified_By", caption: "Modified By", type: "string" },
              { name: "unit_Number", caption: "Unit Number", type: "string" },
              { name: "custom_Unit_Number", caption: "Custom Unit Number", type: "string" },
              { name: "from_Location", caption: "From Location", type: "string" },              
              { name: "from_Sub_Location", caption: "From Sub Location", type: "string" },
              { name: "to_Location", caption: "To Location", type: "string" },
              { name: "to_Sub_Location", caption: "To Sub Location", type: "string" },            
              { name: "drive_Over", caption: "Drive Over", type: "string" },
              { name: "completed_Date", caption: "Completed Date", type: "date" },
              { name: "comments", caption: "Request Details", type: "string" },
              { name: "modified_Notes", caption: "Modified Notes", type: "string" },
              { name: "status", caption: "Status", type: "string" },
              { name: "device", caption: "Device", type: "string" },
              { name: "driver_ID", caption: "Driver ID", type: "string" },
              { name: "trailer_ID", caption: "Trailer ID", type: "string" },
              { name: "assigned_Tractor_ID", caption: "Tractor ID", type: "string" },
              { name: "pilot", caption: "Pilots", type: "string" },
              { name: "city_Permit", caption: "City Permit", type: "string" },
              { name: "city_Permit_Cost", caption: "City Permit Cost", type: "string" },
              { name: "gov_Permit", caption: "Gov Permit", type: "string" },
              { name: "gov_Permit_Cost", caption: "Gov Permit Cost", format: "currency", type: "string" },
              { name: "f_A", caption: "FA", type: "string" },
              { name: "commodity", caption: "Commodity", type: "string" },
              { name: "cancelled", caption: "Cancelled", type: "string" },             
              
            ]}
          />
        </LAGridItem>

      </LAGrid>
    </LAPaperWithPadding>
  </LAPopover>
});