import { IUPDATE_DRIVER_REQUEST, IUpdateETDriverRequest } from "./updateDriverConstants";


export interface IUpdateDriverLoadAction {
    type: IUPDATE_DRIVER_REQUEST.REQUEST;
    data: IUpdateETDriverRequest
}
export const updateDriverLoadAction = (data: IUpdateETDriverRequest): IUpdateDriverLoadAction => ({
    type: IUPDATE_DRIVER_REQUEST.REQUEST,
    data
});
export interface IUpdateDriverSuccessAction {
    type: IUPDATE_DRIVER_REQUEST.SUCCESS;
    message: string;
}
export const updateDriverLoadSuccessAction = (message: string): IUpdateDriverSuccessAction => ({
    type: IUPDATE_DRIVER_REQUEST.SUCCESS,
    message
});
export interface IUpdateDriverLoadFailedAction {
    type: IUPDATE_DRIVER_REQUEST.FAILED;
    message: string;
}
export const updateDriverLoadFailedAction = (message: string): IUpdateDriverLoadFailedAction => ({
    type: IUPDATE_DRIVER_REQUEST.FAILED,
    message
});
