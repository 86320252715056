import { IGET_SOCT_LIST_REQUEST, ISoctList, ISoctListRequest } from "./getSoctListConstants";


export interface IGetSoctListLoadAction {
    type: IGET_SOCT_LIST_REQUEST.REQUEST;
    data: ISoctListRequest
}
export const getSoctListLoadAction = (data: ISoctListRequest): IGetSoctListLoadAction => ({
    type: IGET_SOCT_LIST_REQUEST.REQUEST,
    data
});
export interface IGetSoctListSuccessAction {
    type: IGET_SOCT_LIST_REQUEST.SUCCESS;
    list: ISoctList[];
}
export const getSoctListLoadSuccessAction = (list: ISoctList[]): IGetSoctListSuccessAction => ({
    type: IGET_SOCT_LIST_REQUEST.SUCCESS,
    list
});
export interface IGetSoctListLoadFailedAction {
    type: IGET_SOCT_LIST_REQUEST.FAILED;
    message: string;
}
export const getSoctListLoadFailedAction = (message: string): IGetSoctListLoadFailedAction => ({
    type: IGET_SOCT_LIST_REQUEST.FAILED,
    message
});
