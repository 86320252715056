import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IInitiateMoveLoadAction, IInitiateMoveLoadFailedAction, IInitiateMoveSuccessAction } from "./initiateMoveActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IINITIATE_MOVE_REQUEST } from "./initiateMoveConstants";

type Actions =
    | IInitiateMoveLoadAction
    | IInitiateMoveSuccessAction
    | IInitiateMoveLoadFailedAction
    | IFlushDataSuccessAction;

export const InitiateMoveReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IINITIATE_MOVE_REQUEST.REQUEST:
            return loading;

        case IINITIATE_MOVE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IINITIATE_MOVE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};