import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { EXTRA_LIGHT_YELLOW_COLOR, LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, SurveyMenu } from "../../shared/navbars";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getRoverKits } from "../../../redux/survey/getRoverKits/getRoverKitsAccessor";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getRoverKitLoadAction } from "../../../redux/survey/getRoverKits/getRoverKitsActions";
import { IRoverKit, IRoverKitRequest } from "../../../redux/survey/getRoverKits/getRoverKitsConstants";

interface IRoverKitsComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getRoverKitStatus: Server<SurewayAPIResponse<ById<IRoverKit>>>;
};

interface IRoverKitsComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRoverKitsRequest: (data: IRoverKitRequest) => unknown;
};


interface IRoverKitsComponentOwnProps {

};

interface IRoverKitsComponentState {
};

const RoverKitsComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IRoverKitsComponentProps = RouteComponentProps
    & IRoverKitsComponentStoreProps
    & IRoverKitsComponentDispatchProps
    & IRoverKitsComponentOwnProps;

class RoverKitsComponent extends PureComponent<IRoverKitsComponentProps, IRoverKitsComponentState> {
    
    public constructor(props: IRoverKitsComponentProps) {
        super(props);
        this.state = {
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IRoverKitsComponentProps): void {

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getRoverKitStatus, getToken  } = this.props;
        const getRole = pageAccessCheck(getToken, "surveyAccess");
        const data = hasPayload(getRoverKitStatus) ? Object.values(getRoverKitStatus.payload.response) : [];

        return (
            <PageSpacing title="Rover Kit" description="Survey - Rover Kit" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={SurveyMenu} role={getRole}/>
                {(getRole !== NotApplicable) && <>
                    <RoverKitsComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>ROVER KIT</h2><br />
                                <hr />

                                {getRoverKitStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Rover Kits..." />}
                                {getRoverKitStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Rover Kits..." />}

                                {getRoverKitStatus.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="RoverKit"
                                                onRowPrepared={this.rowPrepRoverKitsPage}
                                                customRowColor={true}
                                                exportFileName="RoverKit"
                                                storageKey="rover-kit"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "kit_No_Display", caption: "Kit #.", type: "string"},
                                                    { name: "unit_No", caption: "Unit #", type: "string" },
                                                    { name: "model", caption: "Model", type: "string" },
                                                    { name: "data_Collector_Unit_No", caption: "Data Collector Unit #", type: "string" },
                                                    { name: "data_Collector_Model", caption: "Data Collector Model", type: "string" },
                                                    { name: "antenna", caption: "Antenna", type: "string" },
                                                    { name: "chargers", caption: "Chargers", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    { name: "site", caption: "Site", type: "string" },
                                                    { name: "operator", caption: "Operator", type: "string" },
                                                    { name: "notes", caption: "Notes", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true, show: false },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                    </RoverKitsComponentStyles>
                </>}
            </PageSpacing>
        );
    }

    private rowPrepRoverKitsPage = (e: any) : void => {
            // console.log(e)
        if(e.data) {
            const fieldMappings: Record<string, string> = {
                "Antenna": "antenna",
                "Chargers": "chargers",
            };
            e.columns.forEach((item: any, index: number) => {
                const field = fieldMappings[item.caption];
                if (field && e.rowElement.cells[index]) {
                    e.rowElement.cells[index].style.backgroundColor = 
                        e.data[field] === "No" ? LIGHT_RED_COLOR : LIGHT_GREEN_COLOR;
                }
                if (item.caption === "Status") {
                    if (e.data.status === "Office") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    } 
                    if (e.data.status === "Site") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                    if (e.data.status === "Out for Service") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    if (e.data.status === "Retired") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_COLOR;
                    }
                    if (e.data.status === "Missing") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                }
            });
        }
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.SURVEY.ROVER_KITS.ADD_UPDATE_ROVER_KITS
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.SURVEY.ROVER_KITS.ADD_UPDATE_ROVER_KITS
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "surveyAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getRoverKitStatus)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getRoverKitsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IRoverKitsComponentStoreProps => ({
    getToken: getToken(state),
    getRoverKitStatus: getRoverKits(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRoverKitsComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRoverKitsRequest: (data: IRoverKitRequest) => dispatch(getRoverKitLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoverKitsComponent);