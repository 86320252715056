import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_LASER_REQUEST } from "./addLaserConstants";
import { IAddLaserLoadAction, IAddLaserLoadFailedAction, IAddLaserSuccessAction, 
    addLaserLoadFailedAction, addLaserLoadSuccessAction } from "./addLaserActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addLaserEpic: Epic = (
    action$: ActionsObservable<IAddLaserLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddLaserSuccessAction | IAddLaserLoadFailedAction> =>
    action$.ofType(IADD_LASER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_LASER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddLaserSuccessAction | IAddLaserLoadFailedAction => {
                            if(response.message === SUCCESS_MESSAGE)
                                return addLaserLoadSuccessAction(response);
                            return addLaserLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addLaserLoadFailedAction(response.message)))
                    )
            )
        );