
export enum IGET_UNIT_DETAILS_REQUEST {
    REQUEST = "getUnitDetails/GET_UNIT_DETAILS_REQUEST",
    SUCCESS = "getUnitDetails/GET_UNIT_DETAILS_SUCCESS",
    FAILED = "getUnitDetails/GET_UNIT_DETAILS_FAILED"
};

export interface IUnitDetailsRequest {
    token: string;
    request: {
        unit_id: string;
    };
};

export interface IUnitDetails {
    unit_Number: string;
    make: string;
    model: string;
    details: string;
    serial_Number: string;
    site_Name: string;
    sub_Site_Name: string;
    job_No: string;
    site_Address: string;
    move_Status: string;
    move_Details: string;
    repair_Status: string;
    socT_Status: string;
    current_Hours: number;
    meter_Date: string;
    service_Date: string;
    next_250_Due: number;
    next_500_Due: number;
    next_1000_Due: number;
    next_1500_Due: number;
    next_3000_Due: number;
    next_ScheduleA_Due: number;
    next_ScheduleB_Due: number;
    next_ScheduleD_Due: number;
    next_CVIP_Due: string;
    custom_Service_Comments: string;
    socT_Comments: string;
    comments: string;
    latitude: string;
    longitude: string;
    add_To_SOCT: string;
    last_EO: number;
    last_500: number;
    last_1000: string;
    last_1500: number;
    last_3000: number;
    last_ScheduleA: number;
    last_ScheduleB: number;
    last_ScheduleD: number;
    machine_Hours: number;
    custom_Service_Due: string;
    ecm: string;
    geotab_Sync: string;
    meter_App_Status: string;
    meter_Reading_Status: string;
    location: string;
    status: string;
    service_Truck: string;
    utilization: number;
    unit_ID: number;
};