
export enum IADD_DEPARTMENT_REQUEST {
    REQUEST = "addDepartment/ADD_DEPARTMENT_REQUEST",
    SUCCESS = "addDepartment/ADD_DEPARTMENT_SUCCESS",
    FAILED = "addDepartment/ADD_DEPARTMENT_FAILED"
};

export interface IAddDepartmentRequest {
    token: string;
    request: IAddUpdateDepartment;
};

export interface IAddUpdateDepartment {
    ID: number;
    Name: string;
    Active: string;
    Created_By: string;
    Modified_By: string;
}
