import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_LOOKUP_REQUEST, IFormLookup } from "./getFormLookupConstants";
import { IGetFormLookupLoadAction, IGetFormLookupLoadFailedAction, IGetFormLookupSuccessAction } from "./getFormLookupActions";

type Actions =
    | IGetFormLookupLoadAction
    | IGetFormLookupSuccessAction
    | IGetFormLookupLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFormLookupReducer = (state: Server<SurewayAPIResponse<IFormLookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IFormLookup>> => {
    switch (action.type) {
        case IGET_FORM_LOOKUP_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_LOOKUP_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_LOOKUP_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};