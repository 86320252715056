import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "./updateCouplerConstants";
import { IUpdateAttachmentCouplerLoadAction, IUpdateAttachmentCouplerLoadFailedAction, IUpdateAttachmentCouplerSuccessAction } from "./updateCouplerActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateAttachmentCouplerLoadAction
    | IUpdateAttachmentCouplerSuccessAction
    | IUpdateAttachmentCouplerLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateAttachmentCouplerReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};