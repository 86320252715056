import { IAddUpdateFilter } from "../addFilter/addFilterConstants";

export enum IUPDATE_FILTER_REQUEST {
    REQUEST = "updateFilter/UPDATE_FILTER_REQUEST",
    SUCCESS = "updateFilter/UPDATE_FILTER_SUCCESS",
    FAILED = "updateFilter/UPDATE_FILTER_FAILED"
};

export interface IUpdateFilterRequest {
    token: string;
    request: IAddUpdateFilter;
};