import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload } from "../../../../redux/server";
import { YesOrNo, ZEROTH, generateRandomText, getTokenFromUrl, pageAccessCheck, undefinedFunction } from "../../../shared/constExports";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { IGetSwamperRequest, ISwamper } from "../../../../redux/field/dispatch/getSwampers/getSwampersConstants";
import { IAddSwamperRequest } from "../../../../redux/field/dispatch/addSwamper/addSwamperConstants";
import { IUpdateSwamperRequest } from "../../../../redux/field/dispatch/updateSwamper/updateSwamperConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { LAPaperWithPadding } from "../../../shared/paper";
import { NotApplicable, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { ArrowLeftIcon } from "../../../shared/icons";
import { addSwamperStatus } from "../../../../redux/field/dispatch/addSwamper/addSwamperAccessor";
import { addSwamperLoadAction } from "../../../../redux/field/dispatch/addSwamper/addSwamperActions";
import { getSwampers } from "../../../../redux/field/dispatch/getSwampers/getSwampersAccessor";
import { getSwampersLoadAction } from "../../../../redux/field/dispatch/getSwampers/getSwampersActions";
import { updateSwamperStatus } from "../../../../redux/field/dispatch/updateSwamper/updateSwamperAccessor";
import { updateSwamperLoadAction } from "../../../../redux/field/dispatch/updateSwamper/updateSwamperActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IStore, IDispatch } from "../../../../redux/reducers";
import { ROUTE } from "../../../routes";
import LAAutoComplete from "../../../shared/autoComplete";
import { LAButton, LASaveAndCancelButton, LASecondaryButton } from "../../../shared/buttons";
import LAErrorBox from "../../../shared/errorBox";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import LATextField from "../../../shared/textField";
import LATextArea from "../../../shared/textArea";
import { LACheckBox } from "../../../shared/checkBox";

const RequiredFields: string[] = ["lastName", "firstName", "phone", "email", "password"];

interface IAddUpdateSwamperComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    swampers: Server<SurewayAPIResponse<ById<ISwamper>>>;
    addSwamperStatus: Server<SurewayAPIResponse<string>>;
    updateSwamperStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateSwamperComponentDispatchProps {
    getSwampersRequest: (data: IGetSwamperRequest) => unknown
    addSwamperRequest: (data: IAddSwamperRequest) => unknown;
    updateSwamperRequest: (data: IUpdateSwamperRequest) => unknown;
};

interface IAddUpdateSwamperOwnProps {
    id?: string;
};

interface IAddUpdateSwamperComponentState {
    data: ISwamper;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateSwamperStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .hireForm_Link {
        color: ${DARK_RED_COLOR}
    }
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateSwamperComponentProps =
    RouteComponentProps
    & IAddUpdateSwamperOwnProps
    & IAddUpdateSwamperComponentStoreProps
    & IAddUpdateSwamperComponentDispatchProps;

class AddUpdateSwamper extends PureComponent<IAddUpdateSwamperComponentProps, IAddUpdateSwamperComponentState> {

    public constructor(props: IAddUpdateSwamperComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                lastName: "",
                email: "",
                phone: "",
                firstName: "",
                comments: "",
                active: YesOrNo[0].name,
                password: "",
                loginInfo: false,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateSwamperComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addSwamperStatus !== prevProps.addSwamperStatus) {

                if (this.props.addSwamperStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addSwamperStatus.message });

                if (hasPayload(this.props.addSwamperStatus) && this.props.addSwamperStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateSwamperStatus !== prevProps.updateSwamperStatus) {

                if (this.props.updateSwamperStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateSwamperStatus.message });

                if (hasPayload(this.props.updateSwamperStatus) && this.props.updateSwamperStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { token, addSwamperStatus, updateSwamperStatus } = this.props;
        const getRole = pageAccessCheck(token, "dispatch");
        // const disabled = getRole === ReadOnly ? true : undefined;
        const disabled = true;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);

        return (
            <AddUpdateSwamperStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} className="text-center">
                            <h3 className="hireForm_Link">Swampers are managed through Hire form. <span className="link-btn" onClick={this.handleClickHereBtn}>Click here</span> to access the HR portal. </h3>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>SWAMPER</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="First Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="firstName"
                                value={data.firstName}
                                onChange={this.handleChange}
                                errorText={errors["firstName"] ? errors["firstName"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Last Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="lastName"
                                value={data.lastName}
                                onChange={this.handleChange}
                                errorText={errors["lastName"] ? errors["lastName"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Email"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="email"
                                value={data.email}
                                onChange={this.handleChange}
                                errorText={errors["email"] ? errors["email"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Phone"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="phone"
                                value={data.phone}
                                onChange={this.handleChange}
                                errorText={errors["phone"] ? errors["phone"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                            <LATextField
                                label="Password"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="password"
                                value={data.password ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["password"] ? errors["password"].message : undefined}
                            />

                            <LASecondaryButton
                                className="mt-2"
                                disabled={disabled}
                                label="Generate New Password"
                                onClick={this.handleAutoGenerate}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                minRows={3}
                                rowsMax={4}
                                label="Comments"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="comments"
                                value={data.comments}
                                onChange={this.handleChange}
                                errorText={errors["comments"] ? errors["comments"].message : undefined}
                            />
                        </LAGridItem>
                       
                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                value={data.loginInfo}
                                onChange={this.handleCheckBox}
                                disabled={disabled}
                                label="Send login info"
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addSwamperStatus.kind} successMessage="Service Due has been added successfully" />
                <RequestStatus requestStatus={updateSwamperStatus.kind} successMessage="Service Due has been updated successfully" />
            </AddUpdateSwamperStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        
        this.props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.SWAMPERS,
            search: getTokenFromUrl(false)
          });
    };

    private handleClickHereBtn = (): void => {
        window.open(ROUTE.HIRE_FORM.INDEX, '_blank')
    }

    private handleAutoGenerate = (): void => {

        let errors = { ...this.state.errors };
        let password: string = generateRandomText(6);
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };
        errors = this.errorChecker("password", password, errors, rules);        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                password: password
            },
            errors
        });
    }

    private handleCheckBox = (checked: boolean): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                loginInfo: checked
            }
        });
    };

    private handleSave = async (): Promise<void> => {
        const {  id, lastName, email, phone, firstName, comments, active, password, loginInfo, created_By } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addSwamperRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        FirstName: firstName,
                        LastName: lastName,
                        Email: email,
                        Phone: phone,
                        Comments: comments,
                        Active: active,
                        Password: password,
                        LoginInfo: loginInfo,
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn
                    }
                });
            } else {
                this.props.updateSwamperRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        FirstName: firstName,
                        LastName: lastName,
                        Email: email,
                        Phone: phone,
                        Comments: comments,
                        Active: active,
                        Password: password,
                        LoginInfo: loginInfo,
                        Created_By: created_By,
                        Modified_By: this.props.token.payload.response.upn
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)){
            rules.required = true;

            if(name === "phone")
                rules.phone = true;

            errors = this.errorChecker(name, value, errors, rules);
        };

        if(name === "email"){
            if(value.length > 0){
            rules.email = true;
            errors = this.errorChecker(name, value, errors, rules);
            } else {
                delete errors["email"];
            }
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "dispatch") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (hasPayload(this.props.swampers)) {
                            const data = this.props.swampers.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getSwampersRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.UNAUTHORIZED,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateSwamperComponentStoreProps => ({
    token: getToken(state),
    swampers: getSwampers(state),
    addSwamperStatus: addSwamperStatus(state),
    updateSwamperStatus: updateSwamperStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateSwamperComponentDispatchProps => ({
    addSwamperRequest: (data: IAddSwamperRequest) => dispatch(addSwamperLoadAction(data)),
    getSwampersRequest: (data: IGetSwamperRequest) => dispatch(getSwampersLoadAction(data)),
    updateSwamperRequest: (data: IUpdateSwamperRequest) => dispatch(updateSwamperLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSwamper);