import { ReactNode, PureComponent, useCallback, useState } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { onExporting } from "../../shared/devExtreme";
import { ROUTE } from "../../routes";
import queryString from "query-string";
import PageSpacing from "../../shared/pageSpacing";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import DataGrid, { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Scrolling, SearchPanel, StateStoring } from "devextreme-react/data-grid";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LAErrorBox from "../../shared/errorBox";
import { LAButton } from "../../shared/buttons";
import React from "react";
import { DataTimer } from "../../shared/timer";
import { BLUE_COLOR, GREEN_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { IRepairLineWorkOrder, IWorkOrderRequest } from "../../../redux/field/repairLine/workOrder/workOrderConstants";
import { workOrderLoadAction } from "../../../redux/field/repairLine/workOrder/workOrderActions";
import { workOrderStatus } from "../../../redux/field/repairLine/workOrder/workOrderAccessor";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { IGetRepairLineWORequest, IRepairLineWO } from "../../../redux/field/repairLine/getRepairLineWO/getRepairLineWOConstants";
import { getRepairLineWO } from "../../../redux/field/repairLine/getRepairLineWO/getRepairLineWOAccessor";
import { getRepairLineWOLoadAction } from "../../../redux/field/repairLine/getRepairLineWO/getRepairLineWOActions";
import LALinkButton from "../../shared/linkButton";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { truncate } from "fs";

const defaultSessionStore = '{"columns":[{"visibleIndex":0,"dataField":"id","name":"id","dataType":"number","visible":true,"filterValue":null},{"visibleIndex":1,"dataField":"unit_No","name":"unit_No","dataType":"string","width":120,"visible":true,"filterValue":null},{"visibleIndex":2,"dataField":"repair_Location","name":"repair_Location","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":3,"dataField":"defect_Item","name":"defect_Item","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":4,"dataField":"requested_By","name":"requested_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":5,"dataField":"issue","name":"issue","dataType":"string","width":350,"visible":true,"filterValue":null},{"visibleIndex":6,"dataField":"priority","name":"priority","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":7,"dataField":"status","name":"status","dataType":"string","width":90,"visible":true,"filterValue":null},{"visibleIndex":8,"dataField":"location_Info","name":"location_Info","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":9,"dataField":"reason","name":"reason","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":10,"dataField":"modified","name":"modified","dataType":"datetime","visible":true,"filterValue":null},{"visibleIndex":11,"dataField":"work_Order_No","name":"work_Order_No","dataType":"string","visible":true,"sortOrder":"asc","sortIndex":0,"filterValue":null},{"visibleIndex":12,"dataField":"job_No","name":"job_No","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":13,"dataField":"cost_Code","name":"cost_Code","dataType":"string","visible":true,"filterValue":null}],"allowedPageSizes":[10,20,40],"filterPanel":{"filterEnabled":true},"filterValue":null,"searchText":"","pageIndex":0,"pageSize":20}'

interface IWorkOrderStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    updateRequest: Server<SurewayAPIResponse<string>>;
    getRepairLineWO: Server<SurewayAPIResponse<IRepairLineWO[]>>;
};

interface IWorkOrderDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    updateWorkOrderRequest: (data: IWorkOrderRequest) => unknown;
    getRepairLineWORequest: (data: IGetRepairLineWORequest) => unknown;
};

interface IWorkOrderOwnProps {

};

interface IWorkOrderState {
    editMode: boolean;
    data: IRepairLineWO[];
    serverError: string | undefined;
};

const WorkOrderStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .right-side {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .clear-filters {       
        margin: 10px 10px;
        background-color: rgb(168, 0, 0);
    };

    .green-text {
        color: ${GREEN_COLOR};
        font-size: 9px;
    };

    .blue-text {
        color: ${BLUE_COLOR};
        font-size: 9px;
    };

    .show-on-phone {
        display: none;
    };

    .link-btn {
        cursor: pointer;
        color: ${BLUE_COLOR};
    };

    .link-btn:hover {
        text-decoration: underline;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .right-side {
            top: auto;
            right: auto;
            position: static;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IWorkOrderProps = RouteComponentProps
    & IWorkOrderStoreProps
    & IWorkOrderDispatchProps
    & IWorkOrderOwnProps;

class WorkOrder extends PureComponent<IWorkOrderProps, IWorkOrderState> {

    public constructor(props: IWorkOrderProps) {
        super(props);
        this.state = {
            data: [],
            editMode: false,
            serverError: undefined
        };
    }

    public componentDidMount(): void {
        const query: any = queryString.parse(this.props.location.search);

        if (query !== undefined && (query.id !== undefined)) {
            const queryId =  Number(query.id);
            let val = JSON.parse(defaultSessionStore);
            let filterByDefectIDArray: any = [];
            for (let i = 0; i < val.columns.length; i++) {
                if (query.id !== undefined) {
                    if (val.columns[i].name === "id") {
                        filterByDefectIDArray.push(queryId);
                        val.columns[i].filterValues = filterByDefectIDArray;
                        val.columns[i].filterValue = queryId;
                    }
                } 
            }
            localStorage.setItem("work-order-storage-key", JSON.stringify(val));
        } 
        else {
            // localStorage.setItem("work-order-storage-key", defaultSessionStore);
        }
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWorkOrderProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.updateRequest !== prevProps.updateRequest) {
                if (this.props.updateRequest.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRequest.message });

                if (hasPayload(this.props.updateRequest) && (this.props.updateRequest.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

        };
    };


    public render(): ReactNode {


        const { updateRequest, getToken } = this.props;
        const { editMode, data, serverError } = this.state;
        // console.log(data)

        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);

        return (
            <PageSpacing title="Work Order" description="FIELD - Repair Line Work Order" fixedSpaceOnSmallerScreens={true}>
                <WorkOrderStyles>

                <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>
                    
                    <h2 className="text-center">REPAIR LINE - CUSTOM WORK ORDER</h2>
                    <hr />

                    {serverError && <LAErrorBox text={serverError} />}

                    <WorkOrderDevExtreme
                        {...this.props}
                        data={data}
                        editMode={editMode}
                        onEditStart={onEditStart}
                        onEditCancel={onEditCancel}
                        onSaveClick={this.onSaveClick}
                        getRefreshDataCall={this.getDataForTable}
                    />

                    <Prompt when={editMode} message="You have unsaved changes ?" />

                    <RequestStatus requestStatus={updateRequest.kind} successMessage="Record successfully updated" />

                </WorkOrderStyles>
            </PageSpacing>
        );
    }

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private onSaveClick = (record: any): void => {
        const { getToken, updateWorkOrderRequest } = this.props;

        if (record.changes[0] !== undefined && hasPayload(getToken)) {

            const { data } = record.changes[0];

            var datId = +data.id;

            let errorMessage = "";

            if (data.work_Order_No && (data.job_No || data.cost_Code)) {
                errorMessage = "Job no. and cost code must be empty.";
            } else if (!data.work_Order_No && data.job_No && !data.cost_Code) {
                errorMessage = "Cost code is required.";
            } else if (!data.work_Order_No && !data.job_No && data.cost_Code) {
                errorMessage = "Job no. is required.";
            }

            if (errorMessage) {
                alert(errorMessage);
                this.updateEditMode(false);
                this.getDataForTable();
            } 
            else {
                let request = {
                    ID: datId,
                    Work_Order_No: data.work_Order_No,
                    Job_No: data.job_No,
                    Cost_Code: data.cost_Code,
                    Modified_By: getToken.payload.response.upn
                };

                updateWorkOrderRequest({
                    request: request,
                    token: getToken.payload.response.token
                });

                this.updateEditMode(false);
            }

            
        };
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getRepairLineWO) && hasPayload(this.props.getToken)) {
            const data = this.props.getRepairLineWO.payload.response;
            this.setState({ data });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getRepairLineWO))
            if (this.props.getToken.payload.response.workorder === "Yes") {
                this.getDataForTable();
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (this.props.getToken.payload.response.workorder === "Yes")) {
            this.props.getRepairLineWORequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IWorkOrderStoreProps => ({
    getToken: getToken(state),
    getRepairLineWO: getRepairLineWO(state),
    updateRequest: workOrderStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IWorkOrderDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRepairLineWORequest: (data: IGetRepairLineWORequest) => dispatch(getRepairLineWOLoadAction(data)),
    updateWorkOrderRequest: (data: IWorkOrderRequest) => dispatch(workOrderLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrder);


const storageKey = "work-order-storage-key";
interface IWorkOrderDevExtreme extends RouteComponentProps {
    data: IRepairLineWO[];
    editMode: boolean;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
};

const WorkOrderDevExtreme: React.FC<IWorkOrderDevExtreme> = React.memo((props: IWorkOrderDevExtreme) => {

    const onExportClick = (e: any): void => onExporting(e, "Work-Order");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');
    // console.log(props.data)

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearFilters = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
                callRouteWithQueryString({
                    route: props,
                    search: {  },
                    pathName: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER
                });
            }

        }
    };

    const onRepairLine = (data: IRepairLineWO): void => {
        callRouteWithQueryString({
            route: props,
            search: { id: data.repair_Line_ID.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
        });
    };

    const highlightRowClass = (): number | undefined => {
        const query: any = queryString.parse(props.location.search);
        if(query && query.id && query.id !== "0"){
            const idx = props.data.findIndex(row => row.id === +query.id);
            if (idx >= 0) {
                return idx;
            }
        }
        return undefined;
    };

    const getPriorityColor = (priority:string) => {
        switch (priority) {
          case "Low":
            return GREEN_COLOR;
          case "Medium":
            return BLUE_COLOR;
          case "Urgent":
            return ORANGE_COLOR;
          case "Down":
            return LIGHT_RED_COLOR;
          default:
            return "";
        }
      };

    const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };

    const onOpenDefectItem = (data: any): void => {
        window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString() + "&defect_ID=" + data.id.toString())
    };

    return (
        <LAGrid>

            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearFilters}
                    disabled={undefined}
                />

                {props.getRefreshDataCall && <DataTimer
                    key={storageKey}
                    className="right-side"
                    onTimerEnd={props.getRefreshDataCall}
                />}
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    focusedRowEnabled={highlightRowClass() ? true : false}
                    focusedRowIndex={highlightRowClass()}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    syncLookupFilterValues={true}
                    columnHidingEnabled={false} 
                    onCellPrepared={(e: CellPreparedEvent<any, unknown>) => {
                        if(e.column.dataField === "priority" && e.data) {
                            if (e.data.priority) { 
                                e.cellElement.style.backgroundColor = getPriorityColor(e.data.priority);
                            }
                        }
                        if(e.column.dataField === "reason" && e.data) {
                            if (e.data.reason ==="Damaged") { 
                                e.cellElement.style.backgroundColor = LIGHT_RED_COLOR;
                            } else if(e.data.reason === "Warranty") {
                                e.cellElement.style.backgroundColor = GREEN_COLOR;
                            }
                        }
                    }}                   
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                    filterSyncEnabled={false}
                >

                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={true} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <ColumnChooser
                        mode="select"
                        height={window.innerHeight - 100}
                        enabled={true}
                    />
                    <StateStoring
                        type="custom"
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                        enabled={true}
                    />
                    <SearchPanel
                        visible={true}
                        width={300}
                        placeholder="Search..."
                        // text={searchValue}
                        // onTextChange={handleValueChange}
                    />
                    <Editing
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false}
                        mode="row"
                    />
                   
                    {/* <Column dataField="button" caption="Repair Line" type="button"
                        cellRender={(val) => {
                            return <LALinkButton className="linkUrl" label="View" onClick={() => onRepairLine(val.data)} />
                        }} 
                        allowReordering={false} 
                    /> */}
                    <Column dataField="id" caption="Defect ID" type="number" allowEditing={false} visible={true} />
                    <Column dataField="unit_No" caption="Unit/ Attachment" type="link" allowEditing={false} width={120} />
                    <Column dataField="repair_Location" caption="Repair Location" allowEditing={false} />
                    <Column dataField="defect_Item" caption="Defect Item" allowEditing={false} 
                        cellRender={(val) => {
                            if (val.data) {
                                const find = val.data["defect_Item"];
                                const onCustomIconClick = (): void => find && onOpenDefectItem(val.data);
                                return <div>
                                    {find ? <div className="link-btn" onClick={onCustomIconClick}>{find}</div> : ""}
                                </div>
                            }
                        }}
                    />
                    <Column dataField="requested_By" caption="Requested By" allowEditing={false} />
                    <Column dataField="issue" caption="Issue" allowEditing={false} width={350} />
                    <Column dataField="priority" caption="Priority" allowEditing={false} />
                    <Column dataField="status" caption="Status" allowEditing={false} width={90}/>
                    <Column dataField="location_Info" caption="Location" allowEditing={false} />
                    <Column dataField="reason" caption="Reason" allowEditing={false} />
                    <Column dataField="modified" caption="Modified" dataType="datetime" allowEditing={false} />
                    <Column sortOrder="asc" dataField="work_Order_No" caption="WO" allowEditing={true} width={90}/>
                    <Column dataField="job_No" caption="Job No" allowEditing={true} />
                    <Column dataField="cost_Code" caption="Cost Code" allowEditing={true} />
                    {/* <Column dataField="created" caption="Created" dataType="date" allowEditing={false} visible={false}/> */}
                </DataGrid>
            </LAGridItem>

        </LAGrid>
    )
});