import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_FORM_RESPONSE_REQUEST } from "./updateFormResponseConstants";
import { IUpdateFormResponseLoadAction, IUpdateFormResponseLoadFailedAction, IUpdateFormResponseSuccessAction, updateFormResponseLoadFailedAction, updateFormResponseLoadSuccessAction } from "./updateFormResponseActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateFormResponseEpic: Epic = (
    action$: ActionsObservable<IUpdateFormResponseLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateFormResponseSuccessAction | IUpdateFormResponseLoadFailedAction> =>
    action$.ofType(IUPDATE_FORM_RESPONSE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.UPDATE_FORM_RESPONSE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateFormResponseSuccessAction | IUpdateFormResponseLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateFormResponseLoadSuccessAction(response);
                            } else {
                                return updateFormResponseLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateFormResponseLoadFailedAction(response.message)))
                    )
            )
        );