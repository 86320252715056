import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGetRepairLineWOUnitsLoadAction, IGetRepairLineWOUnitsLoadFailedAction, IGetRepairLineWOUnitsSuccessAction } from "./getRepairLineWOUnitsActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINES_WO_UNITS_REQUEST, IRepairLineWOUnits } from "./getRepairLineWOUnitsConstants";
import { IAddRepairLineWOUnitSuccessAction } from "../addRepairLineWOUnit/addRepairLineWOUnitActions";
import { IADD_REPAIR_LINE_WO_UNIT_REQUEST } from "../addRepairLineWOUnit/addRepairLineWOUnitConstants";
import { IUpdateRepairLineWOUnitSuccessAction } from "../updateRepairLineWOUnit/updateRepairLineWOUnitActions";
import { IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST } from "../updateRepairLineWOUnit/updateRepairLineWOUnitConstants";

type Actions =
    | IGetRepairLineWOUnitsLoadAction
    | IGetRepairLineWOUnitsSuccessAction
    | IGetRepairLineWOUnitsLoadFailedAction
    | IAddRepairLineWOUnitSuccessAction
    | IUpdateRepairLineWOUnitSuccessAction
    | IFlushDataSuccessAction;

export const GetRepairLineWOUnitsReducer = (state: Server<SurewayAPIResponse<ById<IRepairLineWOUnits>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IRepairLineWOUnits>>> => {
    switch (action.type) {
        case IGET_REPAIR_LINES_WO_UNITS_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_LINES_WO_UNITS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_LINES_WO_UNITS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;
        
        case IADD_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};