import { Paper } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { HasClassAndChildren } from "./publicInterfaces";
import { BLUE_GREEN_COLOR, DARK_GREY_COLOR, DEFAULT_BOX_SHADOW, LIGHT_GREY_COLOR, WHITE_COLOR } from "./theme";
import zIndex from "@material-ui/core/styles/zIndex";


const LAPaperWithPaddingStyles = styled(Paper)`
padding: 1px 1px 1px !important;
position: relative;
border: 1px solid ${DARK_GREY_COLOR};
${DEFAULT_BOX_SHADOW}
`;

//#region Drop Paper
interface IDropPaperProps extends HasClassAndChildren {
    innerRef?: any;
    styleDraggar?: any;
    greyColor?: true;
    backColor?: string;
    onClick?: () => void;
};

export const dropListStyle = (isDraggingOver: any, greyColor?: true, backColor?: string) => ({
    background: greyColor ? LIGHT_GREY_COLOR : isDraggingOver ? BLUE_GREEN_COLOR : backColor ? backColor : WHITE_COLOR,
    padding: 8,
    margin: 0
});

export const DropPaper: React.FC<IDropPaperProps> = (props: IDropPaperProps) =>
    <LAPaperWithPaddingStyles
        className={`${props.className}`}
        innerRef={props.innerRef}
        style={dropListStyle(props.styleDraggar, props.greyColor, props.backColor)}
        onClick={props.onClick}
        elevation={10}
    >
        {props.children}
    </LAPaperWithPaddingStyles>
;
//#endregion


//#region  Drag Paper
export const dragItemStyle = (isDragging: any, draggableStyle: any, borderColor?: string) => ({
    userSelect: "none",
    padding: 1,
    margin: 1,
    background: isDragging ? WHITE_COLOR : WHITE_COLOR,
    border: `2px solid ${borderColor ?? DARK_GREY_COLOR}`,
    borderRadius: "5px",

    ...draggableStyle
});


//#endregion