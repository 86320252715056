import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_MOVE_REQUEST, IUpdateAttachmentMoveRequest } from "./updateAttachmentMoveConstants";


export interface IUpdateAttachmentMoveLoadAction {
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.REQUEST;
    data: IUpdateAttachmentMoveRequest
}
export const updateAttachmentMoveLoadAction = (data: IUpdateAttachmentMoveRequest): IUpdateAttachmentMoveLoadAction => ({
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentMoveSuccessAction {
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentMoveLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentMoveSuccessAction => ({
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentMoveLoadFailedAction {
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentMoveLoadFailedAction = (message: string): IUpdateAttachmentMoveLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_MOVE_REQUEST.FAILED,
    message
});
