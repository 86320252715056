import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SWAMPER_REQUEST, IUpdateSwamperRequest } from "./updateSwamperConstants";


export interface IUpdateSwamperLoadAction {
    type: IUPDATE_SWAMPER_REQUEST.REQUEST;
    data: IUpdateSwamperRequest
}
export const updateSwamperLoadAction = (data: IUpdateSwamperRequest): IUpdateSwamperLoadAction => ({
    type: IUPDATE_SWAMPER_REQUEST.REQUEST,
    data
});
export interface IUpdateSwamperSuccessAction {
    type: IUPDATE_SWAMPER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateSwamperLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateSwamperSuccessAction => ({
    type: IUPDATE_SWAMPER_REQUEST.SUCCESS,
    message
});
export interface IUpdateSwamperLoadFailedAction {
    type: IUPDATE_SWAMPER_REQUEST.FAILED;
    message: string;
}
export const updateSwamperLoadFailedAction = (message: string): IUpdateSwamperLoadFailedAction => ({
    type: IUPDATE_SWAMPER_REQUEST.FAILED,
    message
});
