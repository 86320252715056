import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FORM_RESPONSES_REQUEST, IFormResponse } from "./getFormResponsesConstants";
import { IGetFormResponsesLoadAction, IGetFormResponsesLoadFailedAction, IGetFormResponsesSuccessAction, 
    getFormResponsesLoadFailedAction, getFormResponsesLoadSuccessAction } from "./getFormResponsesActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getFormResponsesEpic: Epic = (
    action$: ActionsObservable<IGetFormResponsesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormResponsesSuccessAction | IGetFormResponsesLoadFailedAction> =>
    action$.ofType(IGET_FORM_RESPONSES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IFormResponse[]>>(
                    END_POINTS.FIELD.GET_FORM_RESPONSES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IFormResponse[]>): IGetFormResponsesSuccessAction => {
                            return getFormResponsesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormResponsesLoadFailedAction(response.message)))
                    )
            )
        );