import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EQUIPMENT_VERSION_HISTORY_REQUEST, IGetETMoveAttachmentVersionHistory } from "./getETMoveAttachmentVersionHistoryConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetETMoveAttachmentVersionHistoryLoadAction, IGetETMoveAttachmentVersionHistoryLoadFailedAction, IGetETMoveAttachmentVersionHistorySuccessAction, getETMoveAttachmentVersionHistoryLoadFailedAction, getETMoveAttachmentVersionHistoryLoadSuccessAction } from "./getETMoveAttachmentVersionHistoryActions";

export const getETMoveAttachmentVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetETMoveAttachmentVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetETMoveAttachmentVersionHistorySuccessAction | IGetETMoveAttachmentVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetETMoveAttachmentVersionHistory[]>>(
                    END_POINTS.FIELD.Get_ET_MoveAttachment_VersionHistory,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetETMoveAttachmentVersionHistory[]>): IGetETMoveAttachmentVersionHistorySuccessAction => {
                            return getETMoveAttachmentVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getETMoveAttachmentVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );