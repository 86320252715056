import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST, IGetRepairLineVersionHistory } from "./getRepairLineVersionHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetRepairLineVersionHistoryLoadAction, IGetRepairLineVersionHistoryLoadFailedAction, IGetRepairLineVersionHistorySuccessAction, 
    getRepairLineVersionHistoryLoadFailedAction, getRepairLineVersionHistoryLoadSuccessAction } from "./getRepairLineVersionHistoryActions";

export const getRepairLineVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineVersionHistorySuccessAction | IGetRepairLineVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>): IGetRepairLineVersionHistorySuccessAction => {
                            return getRepairLineVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );