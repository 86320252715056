import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PM_SHEET_REPORT_REQUEST, IPMSheetsReport } from "./getPMSheetsReportConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetPMSheetsReportLoadAction, IGetPMSheetsReportLoadFailedAction, IGetPMSheetsReportSuccessAction, getPMSheetsReportLoadFailedAction, getPMSheetsReportLoadSuccessAction } from "./getPMSheetsReportActions";

export const getPMSheetsReportEpic: Epic = (
    action$: ActionsObservable<IGetPMSheetsReportLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPMSheetsReportSuccessAction | IGetPMSheetsReportLoadFailedAction> =>
    action$.ofType(IGET_PM_SHEET_REPORT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IPMSheetsReport[]>>(
                    END_POINTS.FIELD.PM_SHEETS.GET_PM_SHEET_REPORT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IPMSheetsReport[]>): IGetPMSheetsReportSuccessAction => {
                            return getPMSheetsReportLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getPMSheetsReportLoadFailedAction(response.message)))
                    )
            )
        );