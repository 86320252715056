import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PM_LOOKUPS_REQUEST, IPMLookups, IPMLookupsRequest } from "./getPMLookupsConstants";


export interface IGetPMLookupsLoadAction {
    type: IGET_PM_LOOKUPS_REQUEST.REQUEST;
    data: IPMLookupsRequest
}
export const getPMLookupsLoadAction = (data: IPMLookupsRequest): IGetPMLookupsLoadAction => ({
    type: IGET_PM_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetPMLookupsSuccessAction {
    type: IGET_PM_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IPMLookups>;
}
export const getPMLookupsLoadSuccessAction = (list: SurewayAPIResponse<IPMLookups>): IGetPMLookupsSuccessAction => ({
    type: IGET_PM_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetPMLookupsLoadFailedAction {
    type: IGET_PM_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getPMLookupsLoadFailedAction = (message: string): IGetPMLookupsLoadFailedAction => ({
    type: IGET_PM_LOOKUPS_REQUEST.FAILED,
    message
});
