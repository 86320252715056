import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINES_REQUEST, IRepairLine, IGetRepairLineRequest } from "./getRepairLinesConstants";

export interface IGetRepairLinesLoadAction {
    type: IGET_REPAIR_LINES_REQUEST.REQUEST;
    data: IGetRepairLineRequest
}
export const getRepairLinesLoadAction = (data: IGetRepairLineRequest): IGetRepairLinesLoadAction => ({
    type: IGET_REPAIR_LINES_REQUEST.REQUEST,
    data
});
export interface IGetRepairLinesSuccessAction {
    type: IGET_REPAIR_LINES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IRepairLine>>;
}
export const getRepairLinesLoadSuccessAction = (list: SurewayAPIResponse<ById<IRepairLine>>): IGetRepairLinesSuccessAction => ({
    type: IGET_REPAIR_LINES_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLinesLoadFailedAction {
    type: IGET_REPAIR_LINES_REQUEST.FAILED;
    message: string;
}
export const getRepairLinesLoadFailedAction = (message: string): IGetRepairLinesLoadFailedAction => ({
    type: IGET_REPAIR_LINES_REQUEST.FAILED,
    message
});
