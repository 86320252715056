import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MACHINE_CONTROL_REQUEST, IMachineControl, IMachineControlRequest } from "./getMachineControlsConstants";

export interface IGetMachineControlLoadAction {
    type: IGET_MACHINE_CONTROL_REQUEST.REQUEST;
    data: IMachineControlRequest
}
export const getMachineControlLoadAction = (data: IMachineControlRequest): IGetMachineControlLoadAction => ({
    type: IGET_MACHINE_CONTROL_REQUEST.REQUEST,
    data
});
export interface IGetMachineControlSuccessAction {
    type: IGET_MACHINE_CONTROL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMachineControl>>;
}
export const getMachineControlLoadSuccessAction = (list: SurewayAPIResponse<ById<IMachineControl>>): IGetMachineControlSuccessAction => ({
    type: IGET_MACHINE_CONTROL_REQUEST.SUCCESS,
    list
});
export interface IGetMachineControlLoadFailedAction {
    type: IGET_MACHINE_CONTROL_REQUEST.FAILED;
    message: string;
}
export const getMachineControlLoadFailedAction = (message: string): IGetMachineControlLoadFailedAction => ({
    type: IGET_MACHINE_CONTROL_REQUEST.FAILED,
    message
});
