import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_HISTORY_BY_ID_REQUEST, IGetUnitHistoryById } from "./getUnitHistoryByIdConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetUnitHistoryByIdLoadAction, IGetUnitHistoryByIdLoadFailedAction, IGetUnitHistoryByIdSuccessAction, 
    getUnitHistoryByIdLoadFailedAction, getUnitHistoryByIdLoadSuccessAction } from "./getUnitHistoryByIdActions";

export const getUnitHistoryByIdEpic: Epic = (
    action$: ActionsObservable<IGetUnitHistoryByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitHistoryByIdSuccessAction | IGetUnitHistoryByIdLoadFailedAction> =>
    action$.ofType(IGET_UNIT_HISTORY_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetUnitHistoryById>>>(
                    END_POINTS.FIELD.GET_UNIT_HISTORY_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetUnitHistoryById>>): IGetUnitHistoryByIdSuccessAction => {
                            return getUnitHistoryByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitHistoryByIdLoadFailedAction(response.message)))
                    )
            )
        );