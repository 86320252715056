import React from 'react';
import {TextField, Paper, Grid, Button, Typography, FormGroup, FormLabel, InputLabel, FormControlLabel, Checkbox, Modal, Backdrop} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import {editMeterReadingData, newMeterReading, export_meter_list_sorted} from '../SOCTMain/edit/editMeterHistoryTable';
import splitDate from '../../../../shared/splitDate';
import {exportWorkingSites} from '../SOCTMain/edit/editSOCTMain';
import {getSOCTWorkingSubSitesByParentID} from '../../api/workingsites/workingSitesAPI';
import {UpdateMeterReading, getSOCTMeterReading, getSOCTMeterReadingByID} from '../../api/meterreading/meterReadingAPI';
import Autocomplete from "@mui/material/Autocomplete";
import { currentUser, hasUserAccess } from '../../socthome';
import DialogPrompt from '../../../../shared/dialogPrompt';
import { editMeterReadingDataBulk } from '../../bulksoctedit';
import { getSOCTUnitByID } from '../../api/soct/soctAPI';
import { getSOCTWorkingSites} from '../../api/workingsites/workingSitesAPI';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;
    showMeterHist:boolean;
    showMoveHist:boolean;
    showCustomHist:boolean;
    showServiceHist:boolean;
    data:any[];
    serviceHistory:any[];
    meterHistory:any[];
    moveHistory:any[];
    customHistory:any[];
    modalOpen:boolean;
    showPrompt:boolean;
    loaded:boolean;
}
var editMeterReading:any = {location:'', sub_Location:'', sub_Location_ID:0, current_Hours:'', comments:'',
ecm:'',date:'', ID:0, previous_Date:'', unit_Number:'', previous_Reading:'', cancelMeterReadingCheck:false};
var working_site_locations:any[] = [];
var sub_site_locations:any[] = [];
var sub_location_list:any[] = [];
var disableForm:any;
var exportWorkingSitesCopy:any[] = [];
var editMeterReadingDataCombined:any;
var previousReadingCopy:any = {previous_Reading:'', previous_Date:''};
var mapped_location_list:any[] = [];
export default class EditMeterHistory extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false, data:[],
        serviceHistory:[], meterHistory:[],moveHistory:[],customHistory:[], modalOpen:true, showPrompt:false, loaded:false};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.saveMeterReadingPopup = this.saveMeterReadingPopup.bind(this);
        this.cancelMeterReadingPopup = this.cancelMeterReadingPopup.bind(this);
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    async onFormSave(){
        if((editMeterReading.ecm !='') ||(editMeterReading.current_Hours!='')){
            if((previousReadingCopy.previous_Reading != null && (Number(editMeterReading.current_Hours) >= previousReadingCopy.previous_Reading)) || previousReadingCopy.previous_Reading===null 
            ||(editMeterReading.ecm !='' && Number(editMeterReading.ecm) >= previousReadingCopy.previous_Reading)){
                if(previousReadingCopy.previous_Reading != null && (Number(editMeterReading.current_Hours - previousReadingCopy.previous_Reading > 100))){
                    this.setState({showPrompt:true});
                }
                else{
                   var res:any = await UpdateMeterReading(editMeterReading, currentUser);
                    if(res.status === 200){
                        alert("Successfully updated meter reading");
                        this.props.history.goBack();
                    }
                    else{
                        alert("Server error: issue updating meter reading. Please refresh page and try again");
                    }
                }
            }
            else{
                if(editMeterReading.comments !=null && editMeterReading.comments!=''){
                    var res:any = await UpdateMeterReading(editMeterReading, currentUser);
                    if(res.status === 200){
                        alert("Successfully updated meter reading");
                        this.props.history.goBack();
                    }
                    else{
                        alert("Server error: issue updating meter reading. Please refresh page and try again");
                    }
                }
                else{
                    alert("Comments are required if new current hours/ecm are less than previous meter reading!");
                }
            }
        }
        else{
            alert("ECM or Current hours are mandatory");
        }
    }
    async saveMeterReadingPopup(){
        var res:any = await UpdateMeterReading(editMeterReading, currentUser);
        if(res.status === 200){
            this.setState({showPrompt:false});
            this.props.history.goBack();
        }
        else{
            alert("Server error: issue updating meter reading. Please refresh page and try again");
        }
    }
    cancelMeterReadingPopup(){
        this.setState({showPrompt:false});
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'date':
                editMeterReading.date = event.target.value;
                editMeterReading.previous_Date = editMeterReadingDataCombined.date;
                break;
            case 'current_hours':
                editMeterReading.current_Hours = event.target.value;
                break;
            case 'ecm':
                editMeterReading.ecm = event.target.value;
                break;
            case 'comments':
                editMeterReading.comments = event.target.value;
                break;
            // case 'new_location':
            //     editMeterReading.new_Location = event.target.value;
            //     var parent_id=0;   
            //     exportWorkingSites.map((site:any) => {
            //         if(site.site_Name === event.target.value){
            //             parent_id = site.id;
            //         }
            //     });
            //     sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
            //     break;
            // case 'sub_location':
            //     editMeterReading.sub_Location = event.target.value;
            //     sub_site_locations.map((site:any) => {
            //         if(site.sub_Site_Name === event.target.value){
            //             editMeterReading.sub_Location_ID = site.id;
            //         }
            //     });
            //     break;
            // case 'update_location_check':
            //     editMeterReading.updateLocationCheck = !editMeterReading.updateLocationCheck;
            //     break;
            case 'cancel_meter_check':
                editMeterReading.cancelMeterReadingCheck = !editMeterReading.cancelMeterReadingCheck;
                break;
        }
        this.setState({});
    }
    async componentDidMount(){
       // Edit meter reading from original meter page
       exportWorkingSitesCopy = [];
       working_site_locations = [];
       var parent_id = 0;
       let workingSites = await getSOCTWorkingSites(true);
        if(editMeterReadingData !=null){
            var res:any = await getSOCTUnitByID(editMeterReadingData.socT_ID);
            var meter_reading:any = await getSOCTMeterReadingByID(editMeterReadingData.id)
            exportWorkingSitesCopy = workingSites;
            exportWorkingSitesCopy.map((location:any) => {
                let location_name = location.site_Name + ", Job #: " + location.job_No;
                working_site_locations.push(location_name);
                });
                working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                sub_site_locations.map((sub:any) => {
                    sub_location_list.push(sub.sub_Site_Name);
                });
                sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
            editMeterReading.ID = meter_reading.id;
            editMeterReading.comments = meter_reading.comments;
            editMeterReading.current_Hours = meter_reading.current_Hours;
            editMeterReading.location = meter_reading.location;
            editMeterReading.sub_Location = meter_reading.sub_Location;
            // editMeterReading.new_Location = meter_reading.new_Location;
            // editMeterReading.new_Sub_Location = meter_reading.new_Sub_Location;
            editMeterReading.date = meter_reading.date;
            editMeterReading.unit_Number = res.unit_Number;
            editMeterReading.previous_Reading = meter_reading.current_Hours;
            editMeterReading.previous_Date = meter_reading.date;
            editMeterReading.ecm = meter_reading.ecm;
            editMeterReading.cancelMeterReadingCheck = meter_reading.cancelled==='Yes';
            disableForm = editMeterReading.cancelMeterReadingCheck;
            let first_id = export_meter_list_sorted[0].id;
            if(first_id!=editMeterReading.ID){
                disableForm = true;
            } 
            previousReadingCopy.previous_Date = newMeterReading.previous_Date;
            previousReadingCopy.previous_Reading = newMeterReading.previous_Reading;
            editMeterReadingDataCombined = editMeterReading;
            console.log(editMeterReadingDataCombined)
        }
        else{
            var res:any = await getSOCTUnitByID(editMeterReadingDataBulk.socT_ID);
            var meter_reading:any = await getSOCTMeterReadingByID(editMeterReadingDataBulk.id)
            editMeterReading.equipment_Type = res.equipment_Type;
            editMeterReading.ID = meter_reading.id;
            editMeterReading.comments = meter_reading.comments;
            editMeterReading.current_Hours = meter_reading.current_Hours;
            editMeterReading.location = meter_reading.location;
            editMeterReading.sub_Location = meter_reading.sub_Location;
            // editMeterReading.new_Location = meter_reading.new_Location;
            // editMeterReading.new_Sub_Location = meter_reading.new_Sub_Location;
            editMeterReading.date = meter_reading.date;
            editMeterReading.unit_Number = res.unit_Number;
            editMeterReading.previous_Reading = meter_reading.current_Hours;
            editMeterReading.previous_Date = meter_reading.date;
            editMeterReading.ecm = meter_reading.ecm;
            editMeterReading.cancelMeterReadingCheck = meter_reading.cancelled==='Yes';
            disableForm = editMeterReading.cancelMeterReadingCheck;
            if(!window.location.href.includes("latest")) {
                disableForm = true;
            }
            if(workingSites != null){
                exportWorkingSitesCopy = workingSites.sort((a:any,b:any) => (a.site_Name > b.site_Name) ? 1:-1);
                exportWorkingSitesCopy.map((location:any) => {
                    let location_name = location.site_Name + ", Job #: " + location.job_No;
                    working_site_locations.push(location_name);
                   // mapped_location_list.push(location);
                    });
                    working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
                }
            var previous_readings:any = await getSOCTMeterReading(editMeterReadingDataBulk.socT_ID);
            previous_readings.sort(function(a:any,b:any){return new Date(b.date).getTime() - new Date(a.date).getTime()});
            var mostRecentMeterReading = previous_readings[0];
            if(previous_readings.length > 0){
                previousReadingCopy.previous_Date = mostRecentMeterReading.date;
                previousReadingCopy.previous_Reading = mostRecentMeterReading.current_Hours;
            }
            editMeterReadingDataCombined = editMeterReading;
        }
        this.setState({loaded:true});
    }
    render(){
        if(this.state.loaded){
            console.log(editMeterReadingDataCombined);
        return (
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            >  
            <Box sx={BoxStyle}>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Modifying Meter History</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Unit # - {editMeterReadingDataCombined.unit_Number}
                                </Typography>
                              
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                <Grid item xs={4}>
                    <TextField type="date" disabled helperText="Previous Date" defaultValue={splitDate(previousReadingCopy.previous_Date)}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField disabled label="Previous Reading" defaultValue={previousReadingCopy.previous_Reading}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel>Meter Date</InputLabel>
                    <TextField style={{backgroundColor:'white'}} disabled={disableForm} type="date" defaultValue={splitDate(editMeterReadingDataCombined.date)} onChange={this.handleChange} name="date"></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField style={{backgroundColor:'white'}} label="Current Hours" disabled={disableForm} defaultValue={editMeterReadingDataCombined.current_Hours} onChange={this.handleChange} name="current_hours"></TextField>
                </Grid>
                {editMeterReadingDataCombined.equipment_Type === 'Excavators' ? 
                <Grid item xs={4}>
                    <TextField style={{backgroundColor:'white'}} disabled={disableForm} label="ECM" defaultValue={editMeterReadingDataCombined.ecm} onChange={this.handleChange} name="ecm"></TextField>
                </Grid>
                :null}
                <Grid item xs={4}>
                    <TextField disabled label="Location" defaultValue={editMeterReadingDataCombined.location}></TextField>
                </Grid>
                {/* <Grid item xs={4}>
                                    <FormLabel component="legend">Do you want to Update Unit Current Location?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox disabled={disableForm} checked={editMeterReading.updateLocationCheck} onChange={this.handleChange} name="update_location_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                        </Grid> */}
                        <Grid item xs={4}>
                    
                       <FormLabel component="legend">Cancel Meter Reading?</FormLabel>
                           <FormControlLabel label="Yes" control={<Checkbox disabled={disableForm} checked={editMeterReadingDataCombined.cancelMeterReadingCheck} onChange={this.handleChange} name='cancel_meter_check'></Checkbox>}></FormControlLabel>
                       </Grid>
                      
                        {/* <Grid item xs={3}>
                            <Autocomplete
                            disabled={disableForm}
                            onChange={async (event:any, newValue:String | null) => {
                                let splitted_job_no = newValue?.split(", Job #: ")[0];
                                editMeterReading.new_Location = splitted_job_no;
                                sub_location_list = [];
                                editMeterReading.new_Sub_Location = '';     
                                editMeterReading.sub_Location_ID = 0;       
                                var parent_id=0;   
                                exportWorkingSitesCopy.map((site:any) => {
                                    if(site.site_Name === splitted_job_no){
                                        parent_id = site.id;
                                    }
                                });
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                             
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            autoHighlight
                            defaultValue={editMeterReadingDataCombined.new_Location}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="New Location"></TextField>}></Autocomplete>
                        </Grid>
                        <Grid item xs={3}>
                                <Autocomplete
                                disabled={disableForm}
                                onChange={(event:any, newValue:String | null) =>  {
                                    editMeterReading.new_Sub_Location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            editMeterReading.sub_Location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                autoHighlight
                                options={sub_location_list} 
                                defaultValue={editMeterReadingDataCombined.new_Sub_Location}
                                renderInput={(params:any):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
                            </Grid> */}
                         
                         <Grid item xs={6}>
                            <TextField style={{backgroundColor:'white'}} disabled={disableForm} fullWidth multiline rows={4} label="Comments" defaultValue={editMeterReading.comments} onChange={this.handleChange} name="comments"></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                                <Button disabled={disableForm || hasUserAccess} style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
                            </Grid>
                            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
                {this.state.showPrompt ? <DialogPrompt cancelPopup={this.cancelMeterReadingPopup} confirmPopup={this.saveMeterReadingPopup}></DialogPrompt>:null}
            </div>
            </Box>
            </Modal>
        );
                                }
                                else{
                                    return null;
                                }
    }
}