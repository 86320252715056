import { IADD_JOB_MHR_REQUEST } from "./addJobMhrConstants";
import { IAddJobMhrLoadAction, IAddJobMhrLoadFailedAction, IAddJobMhrSuccessAction } from "./addJobMhrActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";

type Actions =
    | IAddJobMhrLoadAction
    | IAddJobMhrSuccessAction
    | IAddJobMhrLoadFailedAction
    | IFlushDataSuccessAction;

export const AddJobMhrReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_JOB_MHR_REQUEST.REQUEST:
            return loading;

        case IADD_JOB_MHR_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_JOB_MHR_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};