import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IRepairLine } from '../getRepairLines/getRepairLinesConstants';
import { IGET_REPAIR_LINE_BY_ID_REQUEST, IGetRepairLineByIdRequest } from "./getRepairLineByIdConstants";

export interface IGetRepairLineByIdLoadAction {
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.REQUEST;
    data: IGetRepairLineByIdRequest
}
export const getRepairLineByIdLoadAction = (data: IGetRepairLineByIdRequest): IGetRepairLineByIdLoadAction => ({
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineByIdSuccessAction {
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLine>;
}
export const getRepairLineByIdLoadSuccessAction = (list: SurewayAPIResponse<IRepairLine>): IGetRepairLineByIdSuccessAction => ({
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineByIdLoadFailedAction {
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getRepairLineByIdLoadFailedAction = (message: string): IGetRepairLineByIdLoadFailedAction => ({
    type: IGET_REPAIR_LINE_BY_ID_REQUEST.FAILED,
    message
});
