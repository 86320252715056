import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LABluePaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { deleteText, getTokenFromUrl, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IFieldEmployee, IGetFieldEmployeesRequest } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesConstants";
import { getFieldEmployees } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesAccessor";
import { AddIcon, ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { FormHelperText, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { IForemanMessaging, IGetForemanMessagingRequest, IOptions } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingConstants";
import { IAddForemanMessagingRequest, IAddUpdateForemanMessaging } from "../../../redux/field/workingSiteLogs/addForemanMessaging/addForemanMessagingConstants";
import { IUpdateForemanMessagingRequest } from "../../../redux/field/workingSiteLogs/updateForemanMessaging/updateForemanMessagingConstants";
import { getForemanMessaging } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingAccessor";
import { addForemanMessagingStatus } from "../../../redux/field/workingSiteLogs/addForemanMessaging/addForemanMessagingAccessor";
import { updateForemanMessagingStatus } from "../../../redux/field/workingSiteLogs/updateForemanMessaging/updateForemanMessagingAccessor";
import { addForemanMessagingLoadAction } from "../../../redux/field/workingSiteLogs/addForemanMessaging/addForemanMessagingActions";
import { getForemanMessagingLoadAction } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingActions";
import { updateForemanMessagingLoadAction } from "../../../redux/field/workingSiteLogs/updateForemanMessaging/updateForemanMessagingActions";
import { getFieldEmployeesLoadAction } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesActions";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import PageSpacing from "../../shared/pageSpacing";
import { IEmployeeList, IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { ICrew, IGetCrewByUsername, IGetCrewByUsernameRequest } from "../../../redux/field/workingSiteLogs/getCrewByUsername/getCrewByUsernameConstants";
import { getCrewByUsername } from "../../../redux/field/workingSiteLogs/getCrewByUsername/getCrewByUsernameAccessor";
import { getCrewByUsernameLoadAction } from "../../../redux/field/workingSiteLogs/getCrewByUsername/getCrewByUsernameActions";
import LAAutoComplete from "../../shared/autoComplete";


const RequiredFields: string[] = ["employee_ID", "message"];

interface IAddUpdateForemanMessagingComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addForemanMessagingStatus: Server<SurewayAPIResponse<string>>;
    getCrewByUsername: Server<SurewayAPIResponse<IGetCrewByUsername>>;
    // fieldEmployees: Server<SurewayAPIResponse<ById<IFieldEmployee>>>;
    foremanMessaging: Server<SurewayAPIResponse<ById<IForemanMessaging>>>;
    updateForemanMessagingStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateForemanMessagingComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getCrewByUsernameRequest: (data: IGetCrewByUsernameRequest) => unknown
    // getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => unknown
    getForemanMessagingRequest: (data: IGetForemanMessagingRequest) => unknown
    addForemanMessagingRequest: (data: IAddForemanMessagingRequest) => unknown;
    updateForemanMessagingRequest: (data: IUpdateForemanMessagingRequest) => unknown;
};

interface IAddUpdateForemanMessagingOwnProps {
    id?: string;
};

interface IAddUpdateForemanMessagingComponentState {
    data: IForemanMessaging;
    crew_Name: string;
    assigned_Employee: string;
    unSavedResponse: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateForemanMessagingStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateForemanMessagingComponentProps =
    RouteComponentProps
    & IAddUpdateForemanMessagingOwnProps
    & IAddUpdateForemanMessagingComponentStoreProps
    & IAddUpdateForemanMessagingComponentDispatchProps;

class AddUpdateForemanMessaging extends PureComponent<IAddUpdateForemanMessagingComponentProps, IAddUpdateForemanMessagingComponentState> {

    public constructor(props: IAddUpdateForemanMessagingComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                message: "",
                responses: [],
                options: [],
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            crew_Name: "",
            assigned_Employee: "",
            unSavedResponse: false,
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.getCrewByUsername();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateForemanMessagingComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addForemanMessagingStatus !== prevProps.addForemanMessagingStatus) {

                if (this.props.addForemanMessagingStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addForemanMessagingStatus.message });

                if (hasPayload(this.props.addForemanMessagingStatus) && this.props.addForemanMessagingStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateForemanMessagingStatus !== prevProps.updateForemanMessagingStatus) {

                if (this.props.updateForemanMessagingStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateForemanMessagingStatus.message });

                if (hasPayload(this.props.updateForemanMessagingStatus) && this.props.updateForemanMessagingStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { token, addForemanMessagingStatus, updateForemanMessagingStatus, foremanMessaging, getLookup, getCrewByUsername} = this.props;
        const { data, errors, serverError, assigned_Employee, crew_Name } = this.state;
        // const fieldEmployeesList = hasPayload(fieldEmployees) ? Object.values(fieldEmployees.payload.response) : [];
        const fieldEmployeesList = hasPayload(getLookup) ? Object.values(getLookup.payload.response.employeeList) : [];
        const crewList = hasPayload(getCrewByUsername) ? Object.values(getCrewByUsername.payload.response.crew) : [];
        const getRole = pageAccessCheck(token, "workingSiteLogs");
        const disabled = getRole === ReadOnly ? true : undefined;
        const disableSave = ((Object.values(errors).length > 0) || data.options.some(obj => obj.option === "") || data.options.length === 0) ? true : this.hasDuplicateOptions(data.options) ? true : disabled;
        // const onAssignedEmployee = (value: any): Promise<void> => this.handleAssignedEmployee(value);
        const handleAssignedEmployeeRemove = (name:string): Promise<void> =>  this.handleAssignedEmployeeRemove(name) ;
        const onResponseEdit = (): void => this.handleEditMode();
        // console.log(data)
        
        return (
            <PageSpacing title="Foreman Messaging" description="Foreman Messaging" fixedSpaceOnSmallerScreens={true}>
                <AddUpdateForemanMessagingStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "} FOREMAN MESSAGING</h2>
                                <hr />
                            </LAGridItem>

                            {data.id === 0  && <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={crewList}
                                    autoHighlight={true}
                                    onChange={this.handleSelectCrewName}
                                    filterSelectedOptions={true}
                                    getOptionLabel="crew_Name"
                                    dropDownPlaceHolder="Crew Name"
                                    selectionRemove={undefinedFunction}
                                    value={crew_Name && crewList ? crewList.find((x:any) => x.crew_Name === crew_Name) : null}
                                    defaultValue={crew_Name && crewList ? crewList.find((x:any) => x.crew_Name === crew_Name) : null}
                                />
                            </LAGridItem>}

                            {data.id === 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <DevExtremePopupLookup
                                    data={fieldEmployeesList}
                                    multiple={true}
                                    id="employee-search"
                                    placeHolder="Employees"
                                    disabled={disabled}
                                    displayExp="name"
                                    name="employee_ID"
                                    errorName="employee_ID"
                                    onSelectionRemove={handleAssignedEmployeeRemove}
                                    errors={errors}
                                    selectionRow={true}
                                    onSelectionChanged={this.handleSelectionChanged}
                                    // returnExp="id"
                                    currentVal={this.getAssignedEmployees(assigned_Employee)}
                                    onClick={undefinedFunction}
                                    columns={[
                                        { name: "name", caption: "Name", type: "string" },
                                        { name: "department", caption: "Department", type: "string" },
                                        // { name: "foreman", caption: "Foreman", type: "string" },
                                        { name: "phone", caption: "Phone", type: "number" },
                                        { name: "email", caption: "Email", type: "string" },
                                    ]}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={3}
                                    fullWidth={true}
                                    name="message"
                                    label="Message/Questions"
                                    disabled={disabled}
                                    value={data.message}
                                    onChange={this.handleChange}
                                    errorText={errors["message"] ? errors["message"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <OptionsTable
                                    disableForm={disabled}
                                    data={data.options}
                                    onDelete={this.handleOptionDelete}
                                    onAddOption={this.handleOptionAdd}
                                    handletOptionChange={this.handleOptionsTableChange}
                                />
                                <FormHelperText className="required-text">{this.hasDuplicateOptions(data.options) ? "Options has duplications.Please correct it." : ""}</FormHelperText>
                            </LAGridItem>

                            {data.id && data.id > 0 ? 
                                <LABluePaper>
                                    <LAGrid>
                                        <LAGridItem xs={12} className="text-center">
                                            <h3>Employee Responses</h3>
                                            <hr />
                                        </LAGridItem>
                                        <LAGridItem xs={12}>
                                            {data.responses &&
                                                <LADevExtremeGrid
                                                    add={true}
                                                    height={500}
                                                    id="foremanMessagingResponses"
                                                    columnWidth={200}
                                                    columnChoose={false}
                                                    searchPanel={false}
                                                    filterHeader={true}
                                                    moveBtnToLeft={true}
                                                    data={data.responses}
                                                    onDelete={onResponseEdit}
                                                    // onCustomEdit={(): void => {}}
                                                    onClick={undefinedFunction}
                                                    onSaving={onResponseEdit}
                                                    onEditStart={onResponseEdit}
                                                    onEditCancel={onResponseEdit}
                                                    storageKey="foremanMessagingResponsesStorageKey"
                                                    onAdd={onResponseEdit}
                                                    columns={[
                                                        { name: "employee_ID", caption: "Employee", dropDownId: "id", dropDownName: "name", dropDownSource: fieldEmployeesList, requiredField: true },
                                                        { name: "comments", caption: "Comments", type: "string", allowEditing: false },
                                                        { name: "employee_Option", caption: "Selected Option", type: "string", allowEditing: false },
                                                        { name: "modified_By", caption: "Modified By", type: "string", allowEditing: false, },
                                                        { name: "modified", caption: "Modified", type: "datetime", allowEditing: false, sortDesc:true},
                                                    ]}
                                                />
                                            }
                                        </LAGridItem> 
                                    </LAGrid>
                                </LABluePaper> : null
                            }
                            
                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            <LAGridItem xs={12} >
                                <></>
                            </LAGridItem>

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>}

                    <RequestStatus requestStatus={addForemanMessagingStatus.kind} successMessage="Foreman Messaging has been added successfully" />
                    <RequestStatus requestStatus={updateForemanMessagingStatus.kind} successMessage="Foreman Messaging has been updated successfully" />

                </AddUpdateForemanMessagingStyles>
            </PageSpacing>
        );
    }

    private hasDuplicateOptions = (optionsArray: IOptions[]) => {
        if(optionsArray.length > 0) {
            const options = new Set(); 
            return optionsArray.some(item => options.has(item.option) || !options.add(item.option));
        }
        return false;
    };

    private handleEditMode = () => {
        // console.log("hello");
        this.setState({ unSavedResponse: !this.state.unSavedResponse });
    }
    private handleOptionAdd = () : void => {
        const initialOptionObject = {
            id: 0,
            message_ID: 0,
            option: ""
        }
        const list = this.state.data.options !== null ? [...this.state.data.options] : [];
        list.push(initialOptionObject);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                options: list
            }
        });
    }

    private handleOptionDelete = async (index: number, data: any) => { 
        // console.log(index, data)
        const selection = window.confirm(deleteText);

        if(selection) {
            const options = [...this.state.data.options];
            options.splice(index, 1);
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    options: options
                }
            });
        }
    }

    private handleOptionsTableChange = (name: string, value: any, index: number, ) => { 
        // console.log(value, index, name);
        if (index !== undefined) {
            const iS: IOptions[] = [...this.state.data.options];
            if (name === "option") { 
                let record: IOptions = { ...iS[index] };
                record = {
                    ...record,
                    option: value
                }
                iS[index] = record;
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    options: iS
                }
            });
        }
    }

     private handleSelectCrewName = (e:any, value: ICrew): void => {
            // console.log(value);
            // let crew: any[] =  [];
            if(value) {
                const data = { ...this.state.data };
                let assigned_Employee = this.state.assigned_Employee;
                const employees: number[] = assigned_Employee && assigned_Employee !== null ? assigned_Employee.split(",").map(Number) : [];

                value.members.forEach((m: number) => {
                    const index = data.responses.length > 0 ? data.responses.findIndex((x) => x.employee_ID === m) : -1;
                    if (index < 0) {
                        // const fieldEmployeesOptions: IFieldEmployee[] = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.field_employees : [];
                        // const employeeList: IEmployeeList[] = hasPayload(this.props.getLookup) ? Object.values(this.props.getLookup.payload.response.employeeList) : [];
                        // const find = employeeList?.find(x => x.id === m);
                        // if(find) {
                            // crew.push({id: 0, emp_ID: m, name: find.name, signature: null});
                            employees.push(m);
                            data.responses.push({ 
                                id: 0,
                                employee_ID: m,
                                selected_Option:"",
                                message_ID:0,
                                comments: ""
                            });
                        // }
                    }
                })

                assigned_Employee = employees.join(",");

                let errors = { ...this.state.errors };
                if (RequiredFields.includes("employee_ID"))
                    errors = this.errorChecker("employee_ID", assigned_Employee, errors);

                this.setState({
                    ...this.state,
                    data: {
                        ...data
                    },
                    assigned_Employee: assigned_Employee,
                    crew_Name: value ? value.crew_Name : "",
                    errors
                });
            }
            else {
                this.setState({
                    ...this.state,
                    crew_Name: ""
                });
            }
        }

    private handleAssignedEmployeeRemove = async (name: string): Promise<void> => {
        const data = { ...this.state.data };
        let assigned_Employee = this.state.assigned_Employee;

        // const employeesList: IFieldEmployee[] = hasPayload(this.props.fieldEmployees) ? Object.values(this.props.fieldEmployees.payload.response) : [];
        const employeesList: IEmployeeList[] = hasPayload(this.props.getLookup) ? Object.values(this.props.getLookup.payload.response.employeeList) : [];

        const ind = employeesList.findIndex(q => q.name === name);

        const employee: number[] = assigned_Employee && assigned_Employee !== null ? assigned_Employee.split(",").map(Number) : [];

        const index = employee.length > 0 ? employee.findIndex((x) => x === employeesList[ind].id) : -1;
        if (index >= 0) {
            employee.splice(index, 1);
            data.responses.splice(index, 1)
        }
        
        assigned_Employee = employee.join(",");

        let errors = { ...this.state.errors };
        if (RequiredFields.includes("employee_ID"))
            errors = this.errorChecker("employee_ID", assigned_Employee, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
            },
            assigned_Employee: assigned_Employee,
            errors
        });
    };

    // private handleAssignedEmployee = async (values: any): Promise<void> => {
    //     console.log(values)
    //     const data = { ...this.state.data };

    //     let assigned_Employee = this.state.assigned_Employee;
    //     const employees: number[] = assigned_Employee && assigned_Employee !== null ? assigned_Employee.split(",").map(Number) : [];

    //     const index = employees.length > 0 ? employees.findIndex((x) => x === values.id) : -1;
    //     if (index < 0) {
    //         employees.push(values.id);
    //         data.responses.push({ 
    //             id: 0,
    //             employee_ID: values.id,
    //             selected_Option:"",
    //             message_ID:0,
    //             comments: ""
    //         })
    //     }

    //     assigned_Employee = employees.join(",");

    //     let errors = { ...this.state.errors };
    //     if (RequiredFields.includes("employee_ID"))
    //         errors = this.errorChecker("employee_ID", assigned_Employee, errors);

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...data,
    //         },
    //         assigned_Employee: assigned_Employee,
    //         errors
    //     });
    // };

    private handleSelectionChanged = (row:any): void => {
        const selectedEmployeesList: IFieldEmployee[] = row.selectedRowsData;
        // console.log(selectedEmployeesList);
        const data = { ...this.state.data };
        let assigned_Employee = this.state.assigned_Employee;
        const employees: number[] = assigned_Employee && assigned_Employee !== null ? assigned_Employee.split(",").map(Number) : [];

        selectedEmployeesList.forEach((x) => {
            const index = employees.length > 0 ? employees.findIndex((e) => e === x.id) : -1;
            if (index < 0) {
                employees.push(x.id);
                data.responses.push({ 
                    id: 0,
                    employee_ID: x.id,
                    selected_Option:"",
                    message_ID:0,
                    comments: ""
                });
            }
        });

        assigned_Employee = employees.join(",");

        let errors = { ...this.state.errors };
        if (RequiredFields.includes("employee_ID"))
            errors = this.errorChecker("employee_ID", assigned_Employee, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
            },
            assigned_Employee: assigned_Employee,
            errors
        });
    }

    private getAssignedEmployees = (ids: string): IEmployeeList[] => {
        if (ids) {
            // console.log(ids)
            const response: IEmployeeList[] = [];
            const assigned = ids !== null ? ids.split(",").map(Number) : [];
            const employees: IEmployeeList[] = hasPayload(this.props.getLookup) ? Object.values(this.props.getLookup.payload.response.employeeList) : [];

            assigned.forEach((x) => {
                const index = employees.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(employees[index]);
            });

            return response;
        }
        return [];
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGINGS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {
        const { unSavedResponse } = this.state;
        const {
            id,
            message,
            options,
            responses,  
        } = this.state.data;

        if (!unSavedResponse) {
            if (hasPayload(this.props.token)) {

                // let response = [...responses];
                // response = response.map((r) => {
                //     return {
                //         id: r.id ? r.id : 0,
                //         employee_ID: r.employee_ID ? r.employee_ID : 0,
                //         selected_Option: r.selected_Option ? r.selected_Option : "",
                //         message_ID: r.message_ID ? r.message_ID : 0,
                //         comments: r.comments ? r.comments : ""
                //     };
                // });
                const hasDuplicateEmployee = responses.filter((response, index, self) => self.findIndex(r => r.employee_ID === response.employee_ID) !== index).length > 0;

                if(hasDuplicateEmployee) {
                    window.confirm("Responses contains duplicate employee");
                } 
                else {
                    const request: IAddUpdateForemanMessaging = {
                        ID: id,
                        Message: message,
                        Options: options,
                        Responses: responses,
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn,
                    }
    
                    // console.log(request);
    
                    if (id === 0) {
                        this.props.addForemanMessagingRequest({
                            token: this.props.token.payload.response.token,
                            request
                        });
                    } else {
                        this.props.updateForemanMessagingRequest({
                            token: this.props.token.payload.response.token,
                            request
                        });
                    };
    
                    this.setState({ serverError: "" });
                }

            }
        } else {
            window.confirm("You have some unsaved changes in Responses Section")
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        // console.log(name, value)
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {

                    // if (isNotLoaded(this.props.fieldEmployees)) {
                    //     this.props.getFieldEmployeesRequest({
                    //         token: this.props.token.payload.response.token
                    //     });
                    // };
                    if (hasPayload(this.props.foremanMessaging)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.foremanMessaging.payload.response[query.id];

                                // const employeeIds = data.responses.map(response => response.employee_ID);
                                // // Join Employee_IDs into a comma-separated string
                                // const employeeIdsStr = employeeIds.join(',');
                                this.setState({
                                    data,
                                    // assigned_Employee : employeeIdsStr
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            await this.setState({ 
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    options: [{
                                        id: 0,
                                        message_ID: 0,
                                        option: ""
                                    }]
                                },
                                errors
                            });
                        }
                    } else {
                        this.props.getForemanMessagingRequest({
                            token: this.props.token.payload.response.token
                        });
                    }
                } else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Foreman_Messaging"
                }
            });
    };

    private getCrewByUsername = (): void => {
        if (hasPayload(this.props.token) && this.state.data.id === 0)
            this.props.getCrewByUsernameRequest({
                token: this.props.token.payload.response.token,
                request: {
                    username: this.props.token.payload.response.upn
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        if (name === "email")
            rules.email = true;

        if (name === "phone")
            rules.phone = true;

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateForemanMessagingComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getCrewByUsername: getCrewByUsername(state),
    // fieldEmployees: getFieldEmployees(state),
    foremanMessaging: getForemanMessaging(state),
    addForemanMessagingStatus: addForemanMessagingStatus(state),
    updateForemanMessagingStatus: updateForemanMessagingStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateForemanMessagingComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addForemanMessagingRequest: (data: IAddForemanMessagingRequest) => dispatch(addForemanMessagingLoadAction(data)),
    getCrewByUsernameRequest: (data: IGetCrewByUsernameRequest) => dispatch(getCrewByUsernameLoadAction(data)),
    // getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => dispatch(getFieldEmployeesLoadAction(data)),
    getForemanMessagingRequest: (data: IGetForemanMessagingRequest) => dispatch(getForemanMessagingLoadAction(data)),
    updateForemanMessagingRequest: (data: IUpdateForemanMessagingRequest) => dispatch(updateForemanMessagingLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateForemanMessaging);

interface IOptionsTable {
    disableForm: true | undefined;
    data: any;
    handletOptionChange:(name: string, value:any, index:number) => void;
    onAddOption:()=>void;
    onDelete:(index:number, data:any)=>void;
  };

const OptionsTable: React.FC<IOptionsTable> = React.memo((props: IOptionsTable) => (
    <TableContainer component={LAPaperWithLessPadding}>
        <LAGrid>
           <LAGridItem xs={5}>
                <LAIconButton
                    label="Add New"
                    disabled={props.disableForm ? true : undefined}
                    icon={<AddIcon />}
                    onClick={props.onAddOption} 
                /> 
            </LAGridItem> 
            <h3>Options</h3>
        </LAGrid>
          

        <FormHelperText className="required-text">{(props.data.some((obj: IOptions) => obj.option === "") || props.data.length === 0) ? "Required" : ""}</FormHelperText>
        <Table size="small" aria-label="Attachment Items">
            {/* <TableHead className="header-row">
                
              <TableRow>
                   <TableCell className="title">Option</TableCell>
                   <TableCell className="title"></TableCell>
              </TableRow>
            </TableHead> */}

            {props.data !== null && props.data.length > 0 ? 
                props.data.map((x: IOptions, index: number) => {
                    const onOptionChange = (e: unknown, value: any) => props.handletOptionChange("option", (value !== null) ? value : "", index);

                    return <TableBody key={index}>
                        <TableRow>
                                <TableCell width={400}>
                                    <LATextField
                                        fullWidth={true}
                                        variant="outlined"
                                        indexPosition={index}
                                        name="option"
                                        label="Option"
                                        disabled={props.disableForm}
                                        value={x.option? x.option: ''}
                                        onChange={onOptionChange}
                                        type="text"

                                    />
                                </TableCell>
                            <TableCell>
                                <LAIconButton
                                    label="Delete"
                                    icon={<DeleteIcon />}
                                    onClick={(e:any)=>{props.onDelete(index, x)}} 
                                    disabled={props.disableForm ? true : undefined}
                                    />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                }) 
            : null}
        </Table>
    </TableContainer>
));