import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CREWS_REQUEST, IGetCrews } from "./getCrewsConstants";
import { IGetCrewsLoadAction, IGetCrewsLoadFailedAction, IGetCrewsSuccessAction } from "./getCrewsActions";
import { IAddCrewSuccessAction } from "../addCrew/addCrewActions";
import { IADD_CREW_REQUEST } from "../addCrew/addCrewConstants";
import { IUpdateCrewSuccessAction } from "../updateCrew/updateCrewActions";
import { IUPDATE_CREW_REQUEST } from "../updateCrew/updateCrewConstants";

type Actions =
    | IGetCrewsLoadAction
    | IGetCrewsSuccessAction
    | IGetCrewsLoadFailedAction
    | IAddCrewSuccessAction
    | IUpdateCrewSuccessAction
    | IFlushDataSuccessAction;

export const GetCrewsReducer = (state: Server<SurewayAPIResponse<ById<IGetCrews>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetCrews>>> => {
    switch (action.type) {
        case IGET_CREWS_REQUEST.REQUEST:
            return loading;

        case IGET_CREWS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CREWS_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_CREW_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CREW_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};