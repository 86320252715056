import React, {Component} from 'react';

interface IState {
    hoverText:any;
    content:any;
    hoverText2?:any;
}


export default class DraggableHover extends Component <IState>{
    state = {
        hover:false
    };
    handleMouseEnter = () => {
        this.setState({hover: !this.state.hover});
    }
    handleMouseExit = () => {
        this.setState({hover: !this.state.hover});
    }

    render() {
        return (
            <div 
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseExit}
            >{this.props.hoverText} {this.props.hoverText2 && <div style={{ fontSize: '12px', marginTop: "-7px", padding: 0}}>{this.props.hoverText2 ? this.props.hoverText2 : ""}</div>}
            {/* removed marginLeft:75% */}
                {this.state.hover ? <div style={{padding:'0px', backgroundColor:'lightgray', width:'100%', color:'black', fontWeight:'bold', fontSize:'10px', marginBottom: 4}}>{this.props.content} </div>:null}
            </div>
        )
    }
}