import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_LEVEL_REQUEST } from "./updateLevelConstants";
import { IUpdateLevelLoadAction, IUpdateLevelLoadFailedAction, IUpdateLevelSuccessAction } from "./updateLevelActions";

type Actions =
    | IUpdateLevelLoadAction
    | IUpdateLevelSuccessAction
    | IUpdateLevelLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateLevelReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_LEVEL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_LEVEL_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_LEVEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};