import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { SurewayAPIResponse } from '../../../../react/shared/publicInterfaces';
import { IBulkMoveLoadAction, IBulkMoveLoadFailedAction, IBulkMoveSuccessAction, bulkMoveLoadFailedAction, bulkMoveLoadSuccessAction } from "./bulkMoveActions";
import { IBULK_MOVE_REQUEST } from './bulkMoveConstants';

export const bulkMoveEpic: Epic = (
    action$: ActionsObservable<IBulkMoveLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IBulkMoveSuccessAction | IBulkMoveLoadFailedAction> =>
    action$.ofType(IBULK_MOVE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.BULK_MOVE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IBulkMoveSuccessAction => {
                            return bulkMoveLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(bulkMoveLoadFailedAction(response)))
                    )
            )
        );