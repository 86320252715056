import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LAButton } from "../../shared/buttons";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { AttachmentSubHeader, AttachmentSubHeaderMobile } from "../../header/attachmentSubHeader";
// import { IAttachment, IGetAttachmentRequest } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
// import { getAttachmentsLoadAction } from "../../../redux/field/attachment/getAttachments/getAttachmentsActions";
// import { getAttachmentsStatus } from "../../../redux/field/attachment/getAttachments/getAttachmentsAccessor";
import LAErrorBox from "../../shared/errorBox";
import { Button } from "@material-ui/core";
import DraggableHover from "../../shared/equipmentHover";
import LATextField from "../../shared/textField";
import { DataTimer } from "../../shared/timer";
import { IGetAttachment, IGetAttachmentsRequest } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssConstants";
import { getAttachmentssStatus } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssAccessor";
import { getAttachmentssLoadAction } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssActions";

interface ILocationViewStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    // getAttachmentList: Server<SurewayAPIResponse<ById<IAttachment>>>;
    getAttachments: Server<SurewayAPIResponse<ById<IGetAttachment>>>;
};

interface ILocationViewDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => unknown;
    getAttachmentssRequest: (data: IGetAttachmentsRequest) => unknown;
};

interface ILocationViewOwnProps {

};

interface ILocationViewState {
    data: any;
    searchValue: string;
};

const LocationViewStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .main-card {
        margin: 8px;
        border: 2px solid black;
    }

    .toggledMenu{
        margin-left: 500px;
    }

    .searchbox {
        display: flex;
        justify-content: space-evenly;
    }

    .show-on-phone {
        display: none;
    };

    .timer {
        margin-right: 2px;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;


        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ILocationViewProps = RouteComponentProps
    & ILocationViewStoreProps
    & ILocationViewDispatchProps
    & ILocationViewOwnProps;

class LocationView extends PureComponent<ILocationViewProps, ILocationViewState> {

    public constructor(props: ILocationViewProps) {
        super(props);
        this.state = {
            data: [],
            searchValue: ''
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ILocationViewProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };

    public render(): ReactNode {

        const { getToken, getAttachments } = this.props;
        const { data, searchValue } = this.state;
        const getRole = pageAccessCheck(getToken, "attachmentAccess");

        return (
            <PageSpacing title="Location View - Attachments" description="Location View - Attachment List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <LocationViewStyles>

                    <div className="hide-on-phone">
                        <AttachmentSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <AttachmentSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    {getAttachments.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Data..." />}
                    {getAttachments.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load data..." />}

                    {getAttachments.kind === STATUS_ENUM.SUCCEEDED &&
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <h2 className="title">Location View - Attachments(Detachable Only)</h2>



                                    <LAGrid direction="row" justify="flex-end" alignItems="center">
                                        

                                        <label htmlFor="from-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: ORANGE_COLOR }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Not Moving</strong>
                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: GREEN_COLOR }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Moving | </strong>

                                        <DataTimer
                                            className="timer"
                                            timeout={120}
                                            onTimerEnd={this.getDataForTable}
                                        />
                                    </LAGrid>
                                    <hr />

                                    <LAGrid spacing={1}>
                                        <LAGridItem xs={12} sm={12} md={12}>
                                            <div className='searchbox'>
                                                <LAGridItem xs={6} sm={6} md={4}>
                                                    <LATextField
                                                        id="Search"
                                                        className='searchbox'
                                                        value={searchValue}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="searchValue"
                                                        label="Search"
                                                        onChange={this.handleChange}
                                                        type="string"
                                                    />

                                                </LAGridItem>

                                                <LAButton
                                                    label="Clear Search"
                                                    onClick={() => this.handleChange("searchValue", "")}
                                                />
                                            </div>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <></>
                                        </LAGridItem>

                                        {data.length > 0 && data.sort((a: any, b: any) => a.location > b.location ? 1 : -1).map((x: any) => {
                                            return <LAGridItem xs={12} sm={x.attachments !== undefined && x.attachments !== null && x.attachments.length > 20 ? 12 : 6} md={x.attachments !== undefined && x.attachments !== null && x.attachments.length > 20 ? 12 : 4}>
                                                <div className="main-card">
                                                    <div>
                                                        <h3 style={{ color: 'white', backgroundColor: '#bf0000', padding: '5px', textAlign: "center" }}>
                                                            {x.location}
                                                        </h3>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', margin: "15px", }}>
                                                            {x.attachments.length > 0 && x.attachments.map((attachment: any, index: number) => {
                                                                return <div>
                                                                    <LAGridItem xs={12}>
                                                                        {attachment.working_Site_Sub_ID === x.working_Site_Sub_ID &&
                                                                            <Button onClick={() => this.openMoveRequest(attachment)} style={{ color: 'black', backgroundColor: attachment.move_Status === "" || attachment.move_Status === "Completed" || attachment.move_Status === null || attachment.move_Status === "Moved" ? ORANGE_COLOR : GREEN_COLOR , fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', margin: 8, padding: "0 5px", width: '100px' }}>
                                                                                <DraggableHover hoverText={attachment.attachment_No} hoverText2={attachment.attached_Unit_No ?? ""} content={attachment.type + " - " + attachment.coupler_Design + " - " + attachment.style} />
                                                                            </Button>
                                                                        }
                                                                    </LAGridItem>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </LAGridItem>
                                        })}
                                    </LAGrid>
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    }
                </LocationViewStyles>}
            </PageSpacing>
        );
    }

    private openMoveRequest = (attachment: any): void => {
        if (attachment.move_Status === null || attachment.move_Status === "" || attachment.move_Status === "Completed" || attachment.move_Status === "Moved") {
            // callRouteWithQueryString({
            //     route: this.props,
            //     search: { id: "0", token: getTokenFromUrl(true), attachment_ID: attachment.id.toString() },
            //     pathName: ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE
            // });
            window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE + `0&attachment_ID=${attachment.id.toString()}`, "_blank")
        }
        else {
            // console.log(attachment)
            if(attachment.move_ID > 0) {
                window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE + `${attachment.move_ID.toString()}`, "_blank");
            }
        }
    }

    private handleChange = (name: string, value: string): void => {
        const data = hasPayload(this.props.getAttachments) ? Object.values(this.props.getAttachments.payload.response) : [];
        const uniqueLocations = data.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.working_Site_Sub_ID === item.working_Site_Sub_ID && t.location === item.location
            ))).map(({ location, working_Site_Sub_ID, }) => ({
                location: location === "-" ? "NA" : location,
                working_Site_Sub_ID,
                attachments: data.filter((att) => att.status === "Active" && att.working_Site_Sub_ID === working_Site_Sub_ID && att.detachable === "Yes")
            }));

        let searchLocations = uniqueLocations?.reduce((result: any, obj: any) => {
            const filteredAttachments = (obj.attachments?.filter((item: any) =>
                (item.location && item.location.toLowerCase().includes(value.toLowerCase())) ||
                (item.attachment_No && item.attachment_No.toLowerCase().includes(value.toLowerCase())) ||
                (item.type && item.type.toLowerCase().includes(value.toLowerCase())) ||
                (item.coupler_Design && item.coupler_Design.toLowerCase().includes(value.toLowerCase())) ||
                (item.style && item.style.toLowerCase().includes(value.toLowerCase())) ||
                (item.attached_Unit_No && item.attached_Unit_No.toLowerCase().includes(value.toLowerCase()))
            ));

            if (filteredAttachments.length > 0) {
                result.push({ ...obj, attachments: filteredAttachments });
            }
            return result;
        }, []);

        this.setState({
            ...this.state,
            data: value.length > 0 ? searchLocations : uniqueLocations,
            [name]: value
        })
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getAttachments)) {
                    this.getDataForTable();
                };

                if (hasPayload(this.props.getAttachments)) {
                    const val = this.props.getAttachments.payload;
                    // console.log(val)
                    if (val) {
                    // if (val && val.callTime) {
                        // const current = new Date().getTime();
                        // const time = new Date(val.callTime).getTime();

                        // if (current - time > 60000) {
                        //     this.getDataForTable();
                        // } 
                        // else {
                            const data = hasPayload(this.props.getAttachments) ? Object.values(this.props.getAttachments.payload.response) : [];
                            const uniqueLocations = data.filter((item, index, self) =>
                                index === self.findIndex((t) => (
                                    t.working_Site_Sub_ID === item.working_Site_Sub_ID && t.location === item.location
                                ))
                            ).map(({ location, working_Site_Sub_ID }) => ({
                                location: location === "-" ? "NA" : location,
                                working_Site_Sub_ID,
                                attachments: data.filter((att) => att.status === "Active" && att.working_Site_Sub_ID === working_Site_Sub_ID && att.detachable === "Yes")
                            }));

                            this.setState({ data: uniqueLocations });
                        // }
                    }
                }

            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
            // this.props.getAttachmentsRequest({
            //     token: this.props.getToken.payload.response.token
            // });
            this.props.getAttachmentssRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };
}

const mapStateToProps = (state: IStore): ILocationViewStoreProps => ({
    getToken: getToken(state),
    // getAttachmentList: getAttachmentsStatus(state),
    getAttachments: getAttachmentssStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): ILocationViewDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => dispatch(getAttachmentsLoadAction(data)),
    getAttachmentssRequest: (data: IGetAttachmentsRequest) => dispatch(getAttachmentssLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationView);