import React, { useEffect, useState } from "react";
import LAGrid from "../../shared/grid";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import LAGridItem from "../../shared/gridList";
import { LAPopover } from "../../shared/popOver";
import { LocationPinIcon } from "../../shared/icons";
import { END_POINTS } from "../../../redux/endpoints";
import { LAGreenPaper, LAPaperWithPadding } from "../../shared/paper";
import LALoading from "../../shared/loading";
import { hasPayload, Server } from "../../../redux/server";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { webConfig } from "../../../utils/webConfig";
import LADatePicker from "../../shared/datePicker";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, RED_COLOR, WHITE_COLOR } from "../../shared/theme";
import { LACheckBox } from "../../shared/checkBox";
import { getDate } from "../../shared/constExports";


interface IGPSHistoryPopupProps {
    open: boolean;
    unitNumber: number;
    unit?: string;
    onCancel: () => void;
    token: Server<SurewayAPIResponse<IToken>>;
};

interface IGPSHistory {
    id: number;
    unit_Number: string;
    location: string;
    latitude: string;
    longitude: string;
    date: string;
    hours: number;
};

const GPSHistoryPopupStyles = styled(LAPaperWithPadding)`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
        background-color: ${DARK_GREY_COLOR};
    };

    .hand-cursor {
        cursor: pointer;
    };

    .popup-div {
        width: 20px;
        margin: auto;
    };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const defaultMapProps = {
    center: {
        lat: 54.6287182,
        lng: -115.6812021
    },
    zoom: 6
};

interface ILocationPin {
    lat: any;
    lng: any;
    data: IGPSHistory;
}

export const LocationPin: React.FC<ILocationPin> = (props: ILocationPin) => {
    const onClick = (event: any): void => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };
    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<any>();

    return <>
        <div className="hand-cursor" onClick={onClick}>
            <LocationPinIcon width={40} height={40} color={RED_COLOR} />
        </div>

        <LAPopover open={open} onClose={() => setOpen(false)} anchorRef={anchorEl} anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
            <LAGreenPaper>
                <br />
                <strong>DATE: </strong>
                <span>{getDate(false, props.data.date)} | </span>

                <strong>LOCATION: </strong>
                <span>{props.data.location}</span>
                <br />
                <strong>HOURS: </strong>
                <span>{props.data.hours}</span>
            </LAGreenPaper>
        </LAPopover>
    </>
};

export const GPSHistoryPopup: React.FC<IGPSHistoryPopupProps> = React.memo((props: IGPSHistoryPopupProps) => {

    const onCancel = () => props.onCancel();
    let defaultLastDate = new Date();
    defaultLastDate.setDate(defaultLastDate.getDate() - 30);

    const [fromDate, setFromDate] = useState<Date>(defaultLastDate);
    const [toDate, setToDate] = useState<Date>(new Date());
    const [loading, setLoading] = useState<boolean>(true);
    const [satView, setSatView] = useState<boolean>(false);
    const [data, setData] = useState<IGPSHistory[]>([]);

    const setFrom = (date: string, date2?: string): void => {
        setFromDate(new Date(date));
    };

    const setTo = (date: string, date2?: string): void => {
        setToDate(new Date(date));
    };

    const callEndpoint = (): void => {

        if (hasPayload(props.token)) {
            fetch(END_POINTS.FIELD.GPS_HISTORY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {
                        Unit_ID: props.unitNumber
                    },
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response !== null) {
                        setData(Object.values(res.response));
                    } else {
                        setData([]);
                    };
                    setLoading(false);
                })
                .catch((res) => console.log(res));
        }
    };

    useEffect(() => {
        callEndpoint();
    }, []);

    const filteredData = (data.length > 0) ? data
        .filter((x) => toDate ? ((new Date(x.date) >= fromDate) && (new Date(x.date) <= toDate)) : true)
        : [];

    let maxFromDate = new Date();
    maxFromDate.setDate(toDate.getDate() - 1);

    let minToDate = new Date(fromDate);
    minToDate.setDate(fromDate.getDate() + 1);

    let maxToDate = new Date();
    maxToDate.setDate(maxToDate.getDate() + 1);


    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <GPSHistoryPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>Unit #: {props.unit} | GPS HISTORY</h3>
                    <hr />

                    <strong>
                        From: <LADatePicker
                            mode="single"
                            value={fromDate}
                            onChange={setFrom}
                            maxDate={maxFromDate.toString()}
                        /> |

                        To: <LADatePicker
                            mode="single"
                            value={toDate}
                            onChange={setTo}
                            minDate={minToDate.toString()}
                            maxDate={maxToDate.toString()}
                        />
                    </strong>

                    <LACheckBox
                        className="ml-3"
                        value={satView}
                        onChange={() => setSatView(!satView)}
                        label={satView ? "Terrain View" : "Satellite View"}
                    />

                </LAGridItem>

                {(loading === true) && <LAGridItem xs={12}>
                    <LALoading message="Loading GPS History..." />
                </LAGridItem>}

                {(loading === false) && <LAGridItem xs={12}>
                    <div style={{ height: (window.screen.height - 300), width: (window.screen.width - 100) }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: webConfig.googleMapKey }}
                            defaultCenter={(filteredData.length === 0) ? defaultMapProps.center : { lat: +filteredData[filteredData.length - 1].latitude, lng: +filteredData[filteredData.length - 1].longitude }}
                            defaultZoom={(filteredData.length === 0) ? defaultMapProps.zoom : 11}
                            options={map => ({
                                scrollwheel: true,
                                mapTypeId: satView ? map.MapTypeId.HYBRID : map.MapTypeId.TERRAIN
                            })}
                        >
                            {filteredData
                                .map((x) => (
                                    <LocationPin
                                        data={x}
                                        lat={x.latitude}
                                        lng={x.longitude}
                                    />
                                ))}
                        </GoogleMapReact>
                    </div>
                </LAGridItem>}

            </LAGrid>
        </GPSHistoryPopupStyles>
    </LAPopover>
});