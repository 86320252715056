import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../redux/server";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getDate, getTokenFromUrl, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IIGLookups, IIGLookupsRequest, IIG_ImageLookup } from "../../../redux/field/imageGallery/getImageGalleryLookups/getImageGalleryConstants";
import { getIGLookups } from "../../../redux/field/imageGallery/getImageGalleryLookups/getImageGalleryAccessor";
import { getIGLookupsLoadAction } from "../../../redux/field/imageGallery/getImageGalleryLookups/getImageGalleryActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithPadding } from "../../shared/paper";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAAutoComplete from "../../shared/autoComplete";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import LAErrorBox from "../../shared/errorBox";
import LADatePicker from "../../shared/datePicker";
import LATextField from "../../shared/textField";

interface IImagegalleryStoreProps {
  getToken: Server<SurewayAPIResponse<IToken>>;
  getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
  getImageGalleryData: Server<SurewayAPIResponse<IIGLookups>>;
};

interface IImageGalleryDispatchProps {
  getTokenRequest: (request: ITokenRequest) => unknown;
  getLookupsRequest: (data: IFieldLookupRequest) => unknown;
  getImageGalleryRequest: (data: IIGLookupsRequest) => unknown;
};

interface IImageGalleryOwnProps {
};

interface IImageGalleryState {
  searchUnit: {
    id:string,
    unit_number: string;
  };
  searchLocation: {
    location: string;
    working_sub_site_Id: string;
  };
  from_Date: string;
  to_Date: string;
};

const ImageGalleryStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

`;

type IImageGalleryProps = RouteComponentProps
    & IImagegalleryStoreProps
    & IImageGalleryDispatchProps
    & IImageGalleryOwnProps;

class ImageGallery extends PureComponent<IImageGalleryProps, IImageGalleryState> {

  public constructor(props: IImageGalleryProps) {
    super(props);
    this.state = {
      searchUnit: {
        id:"",
        unit_number: "",
      },
      searchLocation: {
        location: "",
        working_sub_site_Id: "",
      },
      from_Date: "",
      to_Date: ""
    };
  }

  public componentDidMount(): void {
    this.getLook();
    this.callServer();
  };

  public componentDidUpdate(prevProps: IImageGalleryProps): void {
    if (this.props !== prevProps)
        this.callServer();
  };

  public render(): ReactNode {
    
    const { getImageGalleryData, getToken, getLookup } = this.props;
    const { searchUnit, searchLocation, from_Date, to_Date } = this.state;
    const lookUpData = hasPayload(getLookup) ? getLookup.payload.response : undefined;
    const images = hasPayload(getImageGalleryData) ? getImageGalleryData.payload.response : undefined;
    const units = lookUpData ? lookUpData.units : [];
    const locationOptions = units && Object.values(units.reduce((acc:any, item:any) => {
      acc[item.location_Details] = item;
      return acc;
    }, {})).sort((a:any, b:any) => a.location_Details.localeCompare(b.location_Details));
    const onSearchUnit = (event: unknown, value:any) => this.handleSearchUnit("unit_Number", value !== null ? value : 0);
    const onSearchLocation = (event: unknown, value:any) => this.handleSearchUnit("location", value !== null ? value : 0);

    let maxFromDate = new Date();
    maxFromDate.setDate(maxFromDate.getDate());

    let minToDate = new Date(from_Date);
    minToDate.setDate(minToDate.getDate() + 1);

    let maxToDate = new Date();
    maxToDate.setDate(maxToDate.getDate());

    return (
      <ImageGalleryStyles>
        <LAPaperWithPadding>
          <LAGrid spacing={2}>
            <LAGridItem xs={12} sm={12} md={12} lg={12} className="text-center">
                <h2>Image Gallery</h2>
                <hr />
            </LAGridItem>
            <LAGridItem xs={12} sm={6} md={12} className="text-center">
            <LAGrid >
              <LAGridItem xs={12} sm={6} md={3} className="text text">
                            <strong>Unit#</strong>
                            <LAAutoComplete
                                multiple={false}
                                option={units}
                                getOptionLabel="unit_Number"
                                autoHighlight={true}
                                onChange={onSearchUnit}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Seach Images By Unit"
                                selectionRemove={undefinedFunction}
                                value={searchUnit && units ? units.find(x => x.unit_Number === searchUnit.unit_number) : null}
                                defaultValue={searchUnit && units ? units.find(x => x.unit_Number === searchUnit.unit_number) : null}
                            />
                            
              </LAGridItem>
              <LAGridItem xs={12} sm={6} md={3} className="text text">
                            <strong>Location</strong>
                            <LAAutoComplete
                                multiple={false}
                                option={locationOptions}
                                getOptionLabel="location_Details"
                                autoHighlight={true}
                                onChange={onSearchLocation}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Location"
                                selectionRemove={undefinedFunction}
                                value={searchLocation && units ? units.find(x => x.location_Details === searchLocation.location) : null}
                                defaultValue={searchLocation && units ? units.find(x => x.location_Details === searchLocation.location) : null}
                            />
              </LAGridItem>
              <LAGridItem xs={12} sm={6} md={3} className="text-center">
                            <strong>From Date</strong>
                            <LATextField
                              id="To_Date"
                              value={from_Date}
                              fullWidth={true}
                              variant="outlined"
                              name="from_date"
                              label=""
                              InputProps={{inputProps: {  max: maxFromDate.toLocaleDateString('en-CA')} }}
                              onChange={this.handleFromDate}
                              type="date"
                            >
                            </LATextField>
              </LAGridItem>
              <LAGridItem xs={12} sm={6} md={3} className="text-center">
                            <strong>To Date</strong>
                            <LATextField
                              id="To_Date"
                              value={to_Date}
                              fullWidth={true}
                              variant="outlined"
                              name="to_date"
                              label=""
                              InputProps={{inputProps: { min: minToDate.toLocaleDateString('en-CA'), max: maxToDate.toLocaleDateString('en-CA')} }}
                              onChange={this.handleToDate}
                              type="date"
                            >
                            </LATextField>
              </LAGridItem>
            </LAGrid>
            </LAGridItem>

            {images && Object.values(images).length > 0? Object.values(images).map((image:IIG_ImageLookup, index: number) => {
                const base64 = image.url !== null ? image.url : webConfig.imgApiBaseUrl + image.guid;
                return <LAGridItem key={index} xs={12} md={2}> 
                    <LAThumbnailWithLink
                        className="thumbnail-img"
                        alt={image.description}
                        key={index}
                        url={base64}
                    />
                    <br/>
                  <LAGridItem>
                            <strong>
                                Description:
                            </strong>
                            <span>{image.description}</span>
                  </LAGridItem>
                </LAGridItem>
            }): <LAErrorBox text="No Images Found" />}
          </LAGrid>
        </LAPaperWithPadding>
      </ImageGalleryStyles>
    );
  }

  private handleSearchUnit = async(name: string, value: any)=> {
    // console.log(value);
    if(name === 'unit_Number'){
      await this.setState({
        ...this.setState,
        searchUnit: {
          id: value.id,
          unit_number: value.unit_Number
        }
      });
      this.getImageGalleryRequest();
    }

    if(name === 'location'){
      await this.setState({
        ...this.setState,
        searchLocation: {
          location: value.location_Details,
          working_sub_site_Id: value.working_Site_Sub_ID
        }
      });
      this.getImageGalleryRequest();
    }
  };

  private handleFromDate = async(date: string, e:any) => {
    await this.setState({
        ...this.state,
        from_Date: e
    });
    this.getImageGalleryRequest();
  };

  private handleToDate = async(date: string, e:any) => {
    await this.setState({
        ...this.state,
        to_Date: e
    });
    this.getImageGalleryRequest();
  };

  private getImageGalleryRequest = (): void => {
    if (hasPayload(this.props.getToken)) {
        this.props.getImageGalleryRequest({
          token: this.props.getToken.payload.response.token,
          request: {
            Unit_ID: this.state.searchUnit.id,
            From_Date: getDate(false, this.state.from_Date),
            To_Date: getDate(false,this.state.to_Date),
            Working_Sub_Site_ID: this.state.searchLocation.working_sub_site_Id
          }
        });
    };
  }
  private callServer = (): void => {
    if (isNotLoaded(this.props.getToken))
        this.props.getTokenRequest({
            request: {
                username: getTokenFromUrl(true) ? undefined : userName,
                user_token: getTokenFromUrl(true)
            }
        });

    if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.getToken)) {
      this.getLook();
    }
  };

  private getLook = (): void => {
    if (hasPayload(this.props.getToken)) {
      this.props.getLookupsRequest({
          token: this.props.getToken.payload.response.token,
          request: {
              page: "Image_Gallery"
          }
      });
    }
  };
}

const mapStateToProps = (state: IStore): IImagegalleryStoreProps => ({
  getToken: getToken(state),
  getLookup: getFieldLookups(state),
  getImageGalleryData: getIGLookups(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IImageGalleryDispatchProps => ({
  getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
  getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
  getImageGalleryRequest: (data: IIGLookupsRequest) => dispatch(getIGLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageGallery);
