import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_TECH_SERVICES_REQUEST, ITechService, IGetTechServicesRequest } from "./getTechServicesConstants";

export interface IGetTechServicesLoadAction {
    type: IGET_TECH_SERVICES_REQUEST.REQUEST;
    data: IGetTechServicesRequest
}
export const getTechServicesLoadAction = (data: IGetTechServicesRequest): IGetTechServicesLoadAction => ({
    type: IGET_TECH_SERVICES_REQUEST.REQUEST,
    data
});
export interface IGetTechServicesSuccessAction {
    type: IGET_TECH_SERVICES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ITechService>>;
}
export const getTechServicesLoadSuccessAction = (list: SurewayAPIResponse<ById<ITechService>>): IGetTechServicesSuccessAction => ({
    type: IGET_TECH_SERVICES_REQUEST.SUCCESS,
    list
});
export interface IGetTechServicesLoadFailedAction {
    type: IGET_TECH_SERVICES_REQUEST.FAILED;
    message: string;
}
export const getTechServicesLoadFailedAction = (message: string): IGetTechServicesLoadFailedAction => ({
    type: IGET_TECH_SERVICES_REQUEST.FAILED,
    message
});
