import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_FORM_TEMPLATE_REQUEST, IAddFormTemplateRequest } from "./addFormTemplateConstants";


export interface IAddFormTemplateLoadAction {
    type: IADD_FORM_TEMPLATE_REQUEST.REQUEST;
    data: IAddFormTemplateRequest
}
export const addFormTemplateLoadAction = (data: IAddFormTemplateRequest): IAddFormTemplateLoadAction => ({
    type: IADD_FORM_TEMPLATE_REQUEST.REQUEST,
    data
});
export interface IAddFormTemplateSuccessAction {
    type: IADD_FORM_TEMPLATE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFormTemplateLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFormTemplateSuccessAction => ({
    type: IADD_FORM_TEMPLATE_REQUEST.SUCCESS,
    message
});
export interface IAddFormTemplateLoadFailedAction {
    type: IADD_FORM_TEMPLATE_REQUEST.FAILED;
    message: string;
}
export const addFormTemplateLoadFailedAction = (message: string): IAddFormTemplateLoadFailedAction => ({
    type: IADD_FORM_TEMPLATE_REQUEST.FAILED,
    message
});
