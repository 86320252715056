import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST, IGetWSSLogsHistoryBySiteIDRequest, IWSSLogsHistory } from "./getWSSLogsHistoryBySiteIDConstants";

export interface IGetWSSLogsHistoryBySiteIDLoadAction {
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.REQUEST;
    data: IGetWSSLogsHistoryBySiteIDRequest
}
export const getWSSLogsHistoryBySiteIDLoadAction = (data: IGetWSSLogsHistoryBySiteIDRequest): IGetWSSLogsHistoryBySiteIDLoadAction => ({
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.REQUEST,
    data
});
export interface IGetWSSLogsHistoryBySiteIDSuccessAction {
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IWSSLogsHistory[]>;
}
export const getWSSLogsHistoryBySiteIDLoadSuccessAction = (list: SurewayAPIResponse<IWSSLogsHistory[]>): IGetWSSLogsHistoryBySiteIDSuccessAction => ({
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.SUCCESS,
    list
});
export interface IGetWSSLogsHistoryBySiteIDLoadFailedAction {
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.FAILED;
    message: string;
}
export const getWSSLogsHistoryBySiteIDLoadFailedAction = (message: string): IGetWSSLogsHistoryBySiteIDLoadFailedAction => ({
    type: IGET_WSS_LOGS_HISTORY_BY_SITE_ID_REQUEST.FAILED,
    message
});
