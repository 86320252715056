import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_SMS_LOGS_REQUEST, IRepairLineSmsLog } from "./getRepairLineSmsLogsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetRepairSmsLogsLoadAction, IGetRepairSmsLogsLoadFailedAction, IGetRepairSmsLogsSuccessAction, getRepairSmsLogsLoadFailedAction, getRepairSmsLogsLoadSuccessAction } from "./getRepairLineSmsLogsActions";

export const getRepairSmsLogsEpic: Epic = (
    action$: ActionsObservable<IGetRepairSmsLogsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairSmsLogsSuccessAction | IGetRepairSmsLogsLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_SMS_LOGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IRepairLineSmsLog>>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_SMS_LOGS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IRepairLineSmsLog>>): IGetRepairSmsLogsSuccessAction => {
                            return getRepairSmsLogsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairSmsLogsLoadFailedAction(response.message)))
                    )
            )
        );