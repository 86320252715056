
export enum IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST {
    REQUEST = "updateWorkingSiteLogOnCall/UPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST",
    SUCCESS = "updateWorkingSiteLogOnCall/UPDATE_WORKING_SITE_LOG_ON_CALL_SUCCESS",
    FAILED = "updateWorkingSiteLogOnCall/UPDATE_WORKING_SITE_LOG_ON_CALL_FAILED"
};

export interface IUpdateWorkingSiteLogOnCallRequest {
    token: string;
    request: {
        ID: number;
        Type: string;
        Person: string;
        Modified_By: string;
    };
};