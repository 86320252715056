import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_HAZARD_REQUEST } from "./updateHazardConstants";
import { IUpdateHazardLoadAction, IUpdateHazardLoadFailedAction, IUpdateHazardSuccessAction } from "./updateHazardActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IUpdateHazardLoadAction
    | IUpdateHazardSuccessAction
    | IUpdateHazardLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateHazardReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_HAZARD_REQUEST.REQUEST:
            return loading;

        case IUPDATE_HAZARD_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_HAZARD_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};