import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_INSPECTION_SHEET_REQUEST } from "./saveInspectionSheetConstants";
import { ISaveInspectionSheetLoadAction, ISaveInspectionSheetLoadFailedAction, ISaveInspectionSheetSuccessAction, saveInspectionSheetLoadFailedAction, saveInspectionSheetLoadSuccessAction } from "./saveInspectionSheetActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const saveInspectionSheetEpic: Epic = (
    action$: ActionsObservable<ISaveInspectionSheetLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveInspectionSheetSuccessAction | ISaveInspectionSheetLoadFailedAction> =>
    action$.ofType(ISAVE_INSPECTION_SHEET_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.INSPECTION_FORM.SAVE_INSPECTION_SHEET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveInspectionSheetSuccessAction | ISaveInspectionSheetLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveInspectionSheetLoadSuccessAction(response);
                            } else {
                                return saveInspectionSheetLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveInspectionSheetLoadFailedAction(response.message)))
                    )
            )
        );