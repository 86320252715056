import { IIdName } from "../../../../react/shared/publicInterfaces";
import { IShopMakes } from "../../getLookups/getLookupsConstants";

export enum IGET_FIELD_LOOKUPS_REQUEST {
    REQUEST = "getFieldLookups/GET_FIELD_LOOKUPS_REQUEST",
    SUCCESS = "getFieldLookups/GET_FIELD_LOOKUPS_SUCCESS",
    FAILED = "getFieldLookups/GET_FIELD_LOOKUPS_FAILED"
};

export interface IFieldLookupRequest {
    token: string;
    request: {
        page: string;
    };
};

export interface IFieldLookup {
    wssList: IWSS[];
    foremanList: IForeman[];
    units: IUnit[];
    attachments: IAttachment[];
    drivers: IDriverList[];
    driverList: IDriverList[];
    lube_drivers: IDriverList[];
    mechanics: ILookupMechanic[];
    workAroundList: IIdName[];
    swampers: IIdName[];
    sites: ISiteList[];
    service_Trucks: IServiceTruckList[];
    component_codes: IComponentCodeList[];
    companies: ICompany[];
    types: IIdName[];
    couplers: IIdName[];
    styles: IIdName[];
    jobs: IJob[],
    filterList: IFilterList[];
    filters: IFilters[];
    employees: string[];
    employeeList: IEmployeeList[];
    cost_codes : ICostCode[];
    explorer_jobs : IJobListResponse[];
    manufacturers: IShopMakes[];
    models: IModel[];
    subSites: IWSSListForMedicalFacility[]
};

export interface IEmployeeList {
    id: number;
    name: string;
    username: string;
    mobile: string;
    email: string;
    department: string;
};

export interface IWSSListForMedicalFacility {
    id: number;
    site: string;
    job: string;
};
export interface IModel {
    id: number;
    model: string;
};
export interface ICostCode {
    id: number;
    job: string;
    cost_Code: string;
    description: string;
};

export interface IJobListResponse {
    job: string;
    description: string;
    company: string;
    starT_Date: string;
    endinG_Date: string;
    joB_COMPLETE: string;
};

export interface IFilters {
    filter: string;
    circuit: string;
}

export interface IFilterList {
    unit_ID: number;
    unit_No: string;
    system: string;
    sub_Type: string;
}

export interface ICompany {
    id: number;
    company: string;
    name: string;
}

export interface IJob {
    id: number;
    job_No: string;
    name: string;
    client: string;
}

export interface IComponentCodeList {
    code: string;
    description: string;
};

export interface IServiceTruckList {
    id: number;
    unit_Number: string;
};
export interface IDriverList {
    upn: string;
    display_Name: string;
};

export interface ISiteList {
    site: string;
    site_ID: number;
    working_Site: boolean;
    service_Truck_ID: number | null;
    site_Status: string;
    service_Required: string | null;
};

export interface ILookupMechanic {
    id: number;
    name: string;
    email: string;
    company: string;
    type: string;
};

export interface IWSS {
    job: string;
    site: string;
    wsS_ID: number;
    site_Name: string;
    site_Address: string;
    sub_Site_Name: string;
    equip_On_Site: number;
};

export interface IForeman {
    upn: string;
    display_Name: string;
};

export interface IUnit {
    id: number;
    unit_Number: string;
    location_Details: string;
    active_Repair_Status?: boolean;
    make?: string;
    model?: string;
    repair_ID?: number;
    make_ID?: number;
    equipment_Type?: string;
    status?: string;
};

export interface IAttachment {
    id: number;
    attachment_No: string;
    location: string;
    attached_Unit_No: string;
    type: string;
    coupler_Design: string;
    style: string;
    repair_ID?: number;
};