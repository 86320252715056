import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_STYLES_REQUEST, IStyle, IGetStyleRequest } from "./getStylesConstants";

export interface IGetStylesLoadAction {
    type: IGET_STYLES_REQUEST.REQUEST;
    data: IGetStyleRequest
}
export const getStylesLoadAction = (data: IGetStyleRequest): IGetStylesLoadAction => ({
    type: IGET_STYLES_REQUEST.REQUEST,
    data
});
export interface IGetStylesSuccessAction {
    type: IGET_STYLES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IStyle>>;
}
export const getStylesLoadSuccessAction = (list: SurewayAPIResponse<ById<IStyle>>): IGetStylesSuccessAction => ({
    type: IGET_STYLES_REQUEST.SUCCESS,
    list
});
export interface IGetStylesLoadFailedAction {
    type: IGET_STYLES_REQUEST.FAILED;
    message: string;
}
export const getStylesLoadFailedAction = (message: string): IGetStylesLoadFailedAction => ({
    type: IGET_STYLES_REQUEST.FAILED,
    message
});
