import { ISAVE_CREW_SIGNATURE_BY_ID_REQUEST, ISaveCrewSignatureByIDRequest } from "./saveCrewSignatureByIDConstants";

export interface ISaveCrewSignatureByIDLoadAction {
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.REQUEST;
    data: ISaveCrewSignatureByIDRequest
}
export const saveCrewSignatureByIDLoadAction = (data: ISaveCrewSignatureByIDRequest): ISaveCrewSignatureByIDLoadAction => ({
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.REQUEST,
    data
});
export interface ISaveCrewSignatureByIDSuccessAction {
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.SUCCESS;
    message: string;
}
export const saveCrewSignatureByIDLoadSuccessAction = (message: string): ISaveCrewSignatureByIDSuccessAction => ({
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.SUCCESS,
    message
});
export interface ISaveCrewSignatureByIDLoadFailedAction {
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.FAILED;
    message: string;
}
export const saveCrewSignatureByIDLoadFailedAction = (message: string): ISaveCrewSignatureByIDLoadFailedAction => ({
    type: ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.FAILED,
    message
});
