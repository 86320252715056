import React from 'react';
import DataGrid, {
    Column, Button, Paging, FilterRow, FilterPanel, HeaderFilter, Pager, SearchPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import { Grid, Button as MUIButton, FormControl, InputLabel, Typography, RadioGroup, Radio} from '@mui/material';
  import {Modal, TextField, Backdrop, Fade, Box} from '@mui/material';
  import FormLabel from '@mui/material/FormLabel';
  import FormGroup from '@mui/material/FormGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import FormHelperText from '@mui/material/FormHelperText';
  import Checkbox from '@mui/material/Checkbox';
  import splitDate from '../../../../../shared/splitDate';
  import { AddMeterReading } from '../../../api/meterreading/meterReadingAPI';
  import { getSOCTWorkingSubSitesByParentID } from '../../../api/workingsites/workingSitesAPI';
  import { OutlinedRequiredStyle, OutlinedRequiredStyleSelected, SOCT_URL } from '../../../../../shared/styles';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import Autocomplete from "@mui/material/Autocomplete";
  import { getSOCTWorkingSites } from '../../../api/workingsites/workingSitesAPI';
  import { currentUser, hasUserAccess} from '../../../socthome';
  import DialogPrompt from '../../../../../shared/dialogPrompt';

  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};
interface IProps {
    history:any;
    data:any;
    editData:any;
}
interface IState {
    showAddNewForm:boolean;
    modalOpen:boolean;
    meter_list:any[];
    showPrompt:boolean;
    replacementType:string;
    searchValue:string;
}
var newMeterReading:any = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',location:'',
current_Sub_Location:'',current_Sub_Location_ID:0,new_Location:'', sub_Location:'', sub_Location_ID:0, updateLocationCheck:false, 
meterReplacementCheck:false, meter_reset:'', replacement_date:'', SOCT_ID:0, replacementType:''};
var working_site_locations:any[] = [];
var sub_site_locations:any[] = [];
var sub_location_list:any[] = [];
var editMeterReadingData:any;
var export_meter_list_sorted:any[] = [];
var mapped_location_list:any[] = [];
//var soct_current_hours:Number = 0;
export default class EditMeterHistoryTable extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showAddNewForm:false,modalOpen:true, meter_list:[], showPrompt:false, replacementType:"", searchValue: ''};
        this.onAddNew = this.onAddNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onAddNewCancel = this.onAddNewCancel.bind(this);
        this.onAddNewSave = this.onAddNewSave.bind(this);
        this.saveMeterReadingPopup = this.saveMeterReadingPopup.bind(this);
        this.cancelMeterReadingPopup = this.cancelMeterReadingPopup.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.handleMeterReplacement = this.handleMeterReplacement.bind(this);
    }
    handleMeterReplacement(eventKey:any){
        switch(eventKey.target.value) {
            case 'Rollover':
                newMeterReading.replacementType = 'Rollover';
                this.setState({replacementType:'Rollover'});
                break;
           case 'Reset':
            newMeterReading.replacementType = 'Reset';
            this.setState({replacementType:'Reset'});
               break;
           }
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'date':
                newMeterReading.date = event.target.value;console.log(newMeterReading)
                break;
            case 'current_hours':
                newMeterReading.current_Hours = event.target.value;
                
                break;
            case 'ecm':
                newMeterReading.ecm = event.target.value;
                break;
            case 'comments':
                newMeterReading.comments = event.target.value;
                break;
            case 'new_location':
                newMeterReading.new_Location = event.target.value;
                var parent_id=0;   
                mapped_location_list.map((site:any) => {
                    if(site.site_Name === event.target.value){
                        parent_id = site.id;
                    }
                });
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                break;
            case 'sub_location':
                sub_site_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        newMeterReading.sub_Location_ID = site.id;
                    }
                })
                newMeterReading.sub_Location = event.target.value;
                break;
            case 'update_location_check':
                newMeterReading.updateLocationCheck = !newMeterReading.updateLocationCheck;
                break;
            case 'meter_replacement_check':
                newMeterReading.meterReplacementCheck = !newMeterReading.meterReplacementCheck;
                break;
            case 'replacement_date':
                newMeterReading.replacement_date = event.target.value;
                break;
            case 'meter_reset':
                newMeterReading.meter_reset = event.target.value;
                break;
        }
        this.setState({});
    }
    async onAddNewSave(){
        if(newMeterReading.meterReplacementCheck === true){
            if(newMeterReading.meter_reset!='' && newMeterReading.replacement_date != '' && newMeterReading.replacementType != ''){
                var res:any = await AddMeterReading(newMeterReading, currentUser);
                if(res.status === 200){
                    newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',
                    location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
                    current_Sub_Location_ID:this.props.editData.sub_Location_ID, new_Location:'',sub_Location:'',sub_Location_ID:0,updateLocationCheck:false}
                    this.setState({showAddNewForm:false});
                    this.refreshGrid();
                    this.props.history.push('/field/soct/lists/edit/id='+this.props.data.SOCT_ID);
                }
                else{
                    alert("Server error: issue adding meter reading. Please refresh page and try again");
                }
            }
            else{
                alert("Meter rollover/reset, replacement date and replacement type is required for meter replacements");
            }
        }
        else{
            // reset replacement type if meterReplacementCheck was unchecked
            newMeterReading.replacementType = "";
            if(newMeterReading.date != '' && newMeterReading.unit_Number!= '' && ((newMeterReading.updateLocationCheck===true && newMeterReading.new_Location!='' && newMeterReading.sub_Location!='') || (newMeterReading.updateLocationCheck===false ))
            && ((newMeterReading.current_Hours!='' ) || (newMeterReading.ecm!='')))
            {
            if((newMeterReading.previous_Reading !=null && (Number(newMeterReading.current_Hours) >= newMeterReading.previous_Reading)) || newMeterReading.previous_Reading === null || (newMeterReading.ecm !='' && Number(newMeterReading.ecm) >= newMeterReading.previous_Reading)){
                if(newMeterReading.previous_Reading != null && (Number(newMeterReading.current_Hours - newMeterReading.previous_Reading > 100))){
                    this.setState({showPrompt:true});
                }
                else{
                    var res:any = await AddMeterReading(newMeterReading, currentUser);
                    if(res.status === 200){
                        newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',
                        location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
                        current_Sub_Location_ID:this.props.editData.sub_Location_ID, new_Location:'',sub_Location:'',sub_Location_ID:0,updateLocationCheck:false}
                        this.setState({showAddNewForm:false});
                        this.refreshGrid();
                    }
                    else{
                        alert("Server error: issue adding meter reading. Please refresh page and try again");
                    }
                }
            }
            else{
                if(newMeterReading.comments!=null && newMeterReading.comments!=''){
                    var res:any = await AddMeterReading(newMeterReading, currentUser);
                    if(res.status === 200){
                        newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',
                        location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
                        current_Sub_Location_ID:this.props.editData.sub_Location_ID, new_Location:'',sub_Location:'',sub_Location_ID:0,updateLocationCheck:false}
                        this.setState({showAddNewForm:false});
                        this.refreshGrid();
                    }
                    else{
                        alert("Server error: issue adding meter reading. Please refresh page and try again");
                    }
                }
                else{
                    alert("Comments are required if new current hours/ecm is less than previous meter reading!");
                }
            }
        }else{
            alert("Unit Number, Date and hours/ecm are mandatory. Location is mandatory if update location is checked");
        }
   }
    }
    async saveMeterReadingPopup(){
        var res:any = await AddMeterReading(newMeterReading, currentUser);
        if(res.status === 200){
            newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',
            location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
            current_Sub_Location_ID:this.props.editData.sub_Location_ID, new_Location:'',sub_Location:'',sub_Location_ID:0,updateLocationCheck:false}
            this.setState({showPrompt:false, showAddNewForm:false});
            this.refreshGrid();
        }
        else{
            alert("Server error: issue adding meter reading. Please refresh page and try again");
        }
    }
    cancelMeterReadingPopup(){
        this.setState({showPrompt:false});
    }
    onAddNewCancel(){
        newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'',comments:'',
        location:this.props.editData.location,new_Location:'',current_Sub_Location:this.props.editData.sub_Location, 
        current_Sub_Location_ID:this.props.editData.sub_Location_ID, sub_Location:'',sub_Location_ID:0,updateLocationCheck:false}
        this.setState({showAddNewForm:false});
        this.refreshGrid();
    }
    onAddNew(){
        this.setState({showAddNewForm:true});
      //  this.props.history.push('/soct/meters/create');
    }
    editItem(id:number, data:any){
        editMeterReadingData = data;
        editMeterReadingData.equipment_Type = this.props.editData.equipment_Type;
        //editMeterReadingData.soct_current_unit_hours = soct_current_hours;
        this.props.history.push('/field/soct/meters/edit/id='+id);
      }
      async refreshGrid(){
        working_site_locations = [];
        newMeterReading.unit_Number = this.props.editData.unit_Number;
        newMeterReading.SOCT_ID = this.props.editData.SOCT_ID;
            var tempsites:any = await getSOCTWorkingSites(true);
            tempsites.map((location:any) => {
                let location_name = location.site_Name + ", Job #: " + location.job_No;
                working_site_locations.push(location_name);
                mapped_location_list.push(location);
                });
                working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
       
        let meter_readings:any = [];
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{SOCT_ID:this.props.editData.SOCT_ID}})
      }
        await fetch(SOCT_URL+'/api/SOCT/GetSOCTMeterReading', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
           
            // no error
            data.map((history:any) => {
                meter_readings.push(history);
            });
            meter_readings.sort(function(a:any,b:any){return new Date(b.date).getTime() - new Date(a.date).getTime()});
            export_meter_list_sorted = meter_readings.sort((a:any,b:any) => ( a.id > b.id) ? -1:1);
            this.setState({meter_list:meter_readings});
            var mostRecentMeterReading = meter_readings[0];
            if(meter_readings.length > 0){
                newMeterReading.location = this.props.editData.location;
                newMeterReading.current_Sub_Location = mostRecentMeterReading.new_Sub_Location;
                newMeterReading.current_Sub_Location_ID = mostRecentMeterReading.new_Sub_Location_ID;
                newMeterReading.unit_Number = mostRecentMeterReading.unit_Number;
                newMeterReading.previous_Date = mostRecentMeterReading.date;
                newMeterReading.previous_Reading = mostRecentMeterReading.current_Hours;
            }
        });
        this.setState({});
      }
    async componentDidMount(){
        this.refreshGrid();
    }
    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue:trimmedValue });
      };
    render(){
        return (
            <>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                <MUIButton disabled={hasUserAccess} style={{backgroundColor:'#bf0000', marginLeft:'0%'}} onClick={this.onAddNew} variant="contained" startIcon={<AddCircleIcon />}>Add new</MUIButton>
                    <h1>Meter History</h1>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.meter_list}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                >
                    <SearchPanel visible={true}
                    width={340}
                    placeholder="Search for..." 
                    // text={this.state.searchValue}
                    // onTextChange={this.handleValueChange}
                    />    
                    <FilterRow visible={true}></FilterRow>
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Paging enabled={true} defaultPageSize={20}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[20,40,60]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                    <Column type="buttons" caption="Edit" width={100} headerCellRender={renderTitleHeader}>
                        <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
                    </Column>
                    {/* <Column dataType="string" dataField="unit_Number" caption="Unit Number" alignment="left" width={100} headerCellRender={renderTitleHeader}/> */}
                   
                    <Column dataType="date" dataField="date" caption="Date" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="number" dataField="current_Hours" caption="Current Hours" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="location" caption="Location" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="sub_Location" caption="Sub Location" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="cancelled" caption="Cancelled" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataField="modified" dataType="datetime" caption="Modified" alignment="left" headerCellRender={renderTitleHeader}/>
                    {/* <Column dataField="created" dataType="datetime" caption="Created" alignment="left" sortOrder="desc" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="created_By" caption="Created By" alignment="left" headerCellRender={renderTitleHeader}/> */}
                    <Column dataType="string" dataField="modified_By" caption="Modified By" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="source" caption="Source" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="source_ID" caption="Source ID" alignment="left" headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Grid>
            </Grid>
                        {this.state.showAddNewForm? <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.modalOpen}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        >  
                        <Fade in={this.state.modalOpen}>
                        <Box sx={BoxStyle}>
                            <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <TextField label="Unit Number" disabled defaultValue={newMeterReading.unit_Number ? newMeterReading.unit_Number: this.props.editData.unit_Number}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Previous Date" disabled defaultValue={newMeterReading.previous_Date ? splitDate(newMeterReading.previous_Date):"N/A"}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Previous Reading" disabled defaultValue={newMeterReading.previous_Reading ? newMeterReading.previous_Reading:"N/A"}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Meter Date</InputLabel>
                            <TextField style={newMeterReading.date ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle} type='date' onChange={this.handleChange} name="date"></TextField>
                            {!newMeterReading.date && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Current Hours</InputLabel>
                            <TextField style={newMeterReading.current_Hours ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle} onChange={this.handleChange} name="current_hours"></TextField>
                            {!newMeterReading.current_Hours && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                        </Grid>
                        {this.props.editData.equipment_Type === 'Excavators' ? 
                        <Grid item xs={6}>
                        <InputLabel>ECM</InputLabel>
                            <TextField style={{backgroundColor:'white'}} onChange={this.handleChange} name="ecm"></TextField>
                        </Grid>
                        :null}
                        <Grid item xs={6}>
                        <InputLabel>Comments</InputLabel>
                            <TextField style={{backgroundColor:'white'}} fullWidth multiline rows={3} onChange={this.handleChange} name="comments"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Meter Replacement?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={newMeterReading.meterReplacementCheck} onChange={this.handleChange} name="meter_replacement_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                        {newMeterReading.meterReplacementCheck ? 
                        <>
                          <Grid item xs={3} >
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={this.handleMeterReplacement}
                                        defaultValue={""}
                                    >
                                        <FormControlLabel value="Rollover" control={<Radio size="small"/>} label="Rollover" />
                                        <FormControlLabel value="Reset" control={<Radio size="small"/>} label="Reset" />
                                        </RadioGroup>
                                        <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                                </FormControl>
                             </Grid>
                        <Grid item xs={3}>
                        <InputLabel>Replacement Date</InputLabel>
                            <TextField style={{backgroundColor:'white'}} type="date" variant="outlined" name="replacement_date" onChange={this.handleChange}></TextField>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid> 
                        <Grid item xs={3}>
                            {this.state.replacementType==="Rollover"?<FormLabel>Meter Rollover Value</FormLabel>:<FormLabel>Meter Reset Value</FormLabel>}
                            <TextField style={{backgroundColor:'white'}} type="number" variant="outlined" name="meter_reset" onChange={this.handleChange}></TextField>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid> 
                        </>
                        :null}
                        <Grid item xs={12}>
                        <Typography gutterBottom variant="h5" style={{marginLeft:'0%',borderBottom: 'solid 1px black'}}>Location Info</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled label="Current Location" variant="outlined" defaultValue={this.props.editData.location}>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled label="Current Sub Location" variant="outlined" defaultValue={this.props.editData.sub_Location}>
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Do you want to Update Unit Current Location?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={newMeterReading.updateLocationCheck} onChange={this.handleChange} name="update_location_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                        {newMeterReading.updateLocationCheck ?
                        <>
                        <Grid item xs={3}>
                            <Autocomplete
                            onChange={async (event:any, newValue:String | null) => {
                                let splitted_job_no = newValue?.split(", Job #: ")[0];
                                newMeterReading.new_Location = splitted_job_no;
                                sub_location_list = [];
                                newMeterReading.sub_Location = '';     
                                newMeterReading.sub_Location_ID = 0;       
                                var parent_id=0;   
                                mapped_location_list.map((site:any) => {
                                    if(site.site_Name === splitted_job_no){
                                        parent_id = site.id;
                                    }
                                });
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            autoHighlight
                            style={newMeterReading.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="New Location"></TextField>}></Autocomplete>
                        <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                                 <Autocomplete
                                onChange={(event:any, newValue:String | null) =>  {
                                    newMeterReading.sub_Location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            newMeterReading.sub_Location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                autoHighlight
                                options={sub_location_list} 
                                style={newMeterReading.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                                value={newMeterReading.sub_Location}
                                renderInput={(params):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
                                <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </Grid>
                            </>
                            :null}
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000', left:'30%'}} onClick={this.onAddNewCancel} variant="contained">Cancel</MUIButton>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000',  left:'35%'}} onClick={this.onAddNewSave} variant="contained">Save</MUIButton>
                            </Grid>
                            {this.state.showPrompt ? <DialogPrompt cancelPopup={this.cancelMeterReadingPopup} confirmPopup={this.saveMeterReadingPopup}></DialogPrompt>:null}
                       </Box>
                       </Fade>
                
                        </Modal>
                        :null}
            </>
        );
    }
}
export {editMeterReadingData, newMeterReading, export_meter_list_sorted};