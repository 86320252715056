import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_REPEATER_KIT_REQUEST } from "./addRepeaterKitConstants";
import { IAddRepeaterKitLoadAction, IAddRepeaterKitLoadFailedAction, IAddRepeaterKitSuccessAction, 
    addRepeaterKitLoadFailedAction, addRepeaterKitLoadSuccessAction } from "./addRepeaterKitActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addRepeaterKitEpic: Epic = (
    action$: ActionsObservable<IAddRepeaterKitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddRepeaterKitSuccessAction | IAddRepeaterKitLoadFailedAction> =>
    action$.ofType(IADD_REPEATER_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_REPEATER_KIT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddRepeaterKitSuccessAction | IAddRepeaterKitLoadFailedAction => {
                            if(response.message === SUCCESS_MESSAGE){
                                return addRepeaterKitLoadSuccessAction(response);
                            } else {
                                return addRepeaterKitLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addRepeaterKitLoadFailedAction(response.message)))
                    )
            )
        );