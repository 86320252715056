import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SWAMPER_REQUEST, IAddSwamperRequest } from "./addSwamperConstants";


export interface IAddSwamperLoadAction {
    type: IADD_SWAMPER_REQUEST.REQUEST;
    data: IAddSwamperRequest
}
export const addSwamperLoadAction = (data: IAddSwamperRequest): IAddSwamperLoadAction => ({
    type: IADD_SWAMPER_REQUEST.REQUEST,
    data
});
export interface IAddSwamperSuccessAction {
    type: IADD_SWAMPER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addSwamperLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddSwamperSuccessAction => ({
    type: IADD_SWAMPER_REQUEST.SUCCESS,
    message
});
export interface IAddSwamperLoadFailedAction {
    type: IADD_SWAMPER_REQUEST.FAILED;
    message: string;
}
export const addSwamperLoadFailedAction = (message: string): IAddSwamperLoadFailedAction => ({
    type: IADD_SWAMPER_REQUEST.FAILED,
    message
});
