import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CONTROL_PLAN_REQUEST, IGetControlPlans } from "./getControlPlansConstants";
import { IGetControlPlansLoadAction, IGetControlPlansLoadFailedAction, IGetControlPlansSuccessAction } from "./getControlPlansActions";
import { IADD_CONTROL_PLAN_REQUEST } from "../addControlPlan/addControlPlanConstants";
import { IUPDATE_CONTROL_PLAN_REQUEST } from "../updateControlPlan/updateControlPlanConstants";
import { IAddControlPlanSuccessAction } from "../addControlPlan/addControlPlanActions";
import { IUpdateControlPlanSuccessAction } from "../updateControlPlan/updateControlPlanActions";

type Actions =
    | IGetControlPlansLoadAction
    | IGetControlPlansSuccessAction
    | IGetControlPlansLoadFailedAction
    | IAddControlPlanSuccessAction
    | IUpdateControlPlanSuccessAction
    | IFlushDataSuccessAction;

export const GetControlPlansReducer = (state: Server<SurewayAPIResponse<ById<IGetControlPlans>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetControlPlans>>> => {
    switch (action.type) {
        case IGET_CONTROL_PLAN_REQUEST.REQUEST:
            return loading;

        case IGET_CONTROL_PLAN_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CONTROL_PLAN_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_CONTROL_PLAN_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_CONTROL_PLAN_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};