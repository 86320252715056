import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
// import { IAttachment } from '../getAttachments/getAttachmentsConstants';
import { IGET_ATTACHMENT_BY_ID_REQUEST, IGetAttachmentByID, IGetAttachmentByIDRequest } from "./getAttachmentByIDConstants";

export interface IGetAttachmentByIDLoadAction {
    type: IGET_ATTACHMENT_BY_ID_REQUEST.REQUEST;
    data: IGetAttachmentByIDRequest
}
export const getAttachmentByIDLoadAction = (data: IGetAttachmentByIDRequest): IGetAttachmentByIDLoadAction => ({
    type: IGET_ATTACHMENT_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentByIDSuccessAction {
    type: IGET_ATTACHMENT_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetAttachmentByID>;
}
export const getAttachmentByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetAttachmentByID>): IGetAttachmentByIDSuccessAction => ({
    type: IGET_ATTACHMENT_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentByIDLoadFailedAction {
    type: IGET_ATTACHMENT_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getAttachmentByIDLoadFailedAction = (message: string): IGetAttachmentByIDLoadFailedAction => ({
    type: IGET_ATTACHMENT_BY_ID_REQUEST.FAILED,
    message
});
