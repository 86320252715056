
export enum IGET_FORM_LOOKUP_REQUEST {
    REQUEST = "getFormLookup/GET_FORM_LOOKUP_REQUEST",
    SUCCESS = "getFormLookup/GET_FORM_LOOKUP_SUCCESS",
    FAILED = "getFormLookup/GET_FORM_LOOKUP_FAILED"
};

export interface IFormLookupRequest {
    token: string;
    request: {
        page: "Template" | "Form" | "Form_Web" | "Crew" | "Hazard" | "Task" | "Cleanup" | "Control_Plan"
    }
};

export interface IFormLookup {
    wssList: IFormWSS[];
    foremanList: IFormForeman[];
    employeeList: IFormEmployee[];
    departments: IDepartments[];
    sureway_employees: IFormForeman[];
    field_employees: IFieldEmployee[];
    forms: IForms[];
    tasks: ITask[];
    hazards: IHazard[];
    hazard_controls: IHazardControl[];
    employees: IEmployees[];
    hazardTypes: IHazardTypes[];
    controlPlans: IControlPlans[];
    form_departments: IFormDepartments[];
};

export interface IFormDepartments {
    id: number;
    name: string;
}

export interface IDepartments {
    id: number;
    department: string;
}

export interface IControlPlans {
    id: number;
    control: string;
}
export interface IHazardTypes {
    id: number;
    name: string;
    active: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
}

export interface IEmployees {
    id: number;
    name: string;
    username: string;
    department: string;
    company: string;
    class: string;
}

export interface IHazardControl {
    id: number;
    hazard_IDS: string;
    control: string;
}

export interface IHazard {
    id: number;
    hazard: string;
    type_ID: number;
    type: string;
    department_Names: string;
    // controls: IHazardControl[];
}

export interface ITask {
    id: number;
    name: string;
    department_ID: number;
}

export interface IForms {
    id: number;
    form_Name: string;
}

export interface IFormWSS {
    site: string;
    wsS_ID: number;
    site_Name: string;
    sub_Site_Name: string;
    job: string;
    // site_Address: string;
    // equip_On_Site: number;
    muster_Point: string;
};

export interface IFormForeman {
    upn: string;
    display_Name: string;
};
export interface IFieldEmployee {
    id: number;
    name: string;
    username: string;
    company: string;
    department: string;
    class: string;
    mobile: string;
};

export interface IFormEmployee {
    name: string;
    employee_Code: string;
    company: string;
};