
export enum IREJECT_MECHANIC_LOG_REQUEST {
    REQUEST = "rejectMechanicLog/REJECT_MECHANIC_LOG_REQUEST",
    SUCCESS = "rejectMechanicLog/REJECT_MECHANIC_LOG_SUCCESS",
    FAILED = "rejectMechanicLog/REJECT_MECHANIC_LOG_FAILED"
};

export interface IRejectMechanicLogRequest {
    token: string;
    request: IRejectMechanicLog;
};

export interface IRejectMechanicLog {
    modified_By: string;
    rejection_Notes: string;
    rejectList: IMechanicLogRejectList[];
};

export interface IMechanicLogRejectList {
    id: number;
    defect_ID: number;
}