import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_CONTROL_PLAN_REQUEST, IGetControlPlans, IGetControlPlansRequest } from "./getControlPlansConstants";

export interface IGetControlPlansLoadAction {
    type: IGET_CONTROL_PLAN_REQUEST.REQUEST;
    data: IGetControlPlansRequest
}
export const getControlPlansLoadAction = (data: IGetControlPlansRequest): IGetControlPlansLoadAction => ({
    type: IGET_CONTROL_PLAN_REQUEST.REQUEST,
    data
});
export interface IGetControlPlansSuccessAction {
    type: IGET_CONTROL_PLAN_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetControlPlans>>;
}
export const getControlPlansLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetControlPlans>>): IGetControlPlansSuccessAction => ({
    type: IGET_CONTROL_PLAN_REQUEST.SUCCESS,
    list
});
export interface IGetControlPlansLoadFailedAction {
    type: IGET_CONTROL_PLAN_REQUEST.FAILED;
    message: string;
}
export const getControlPlansLoadFailedAction = (message: string): IGetControlPlansLoadFailedAction => ({
    type: IGET_CONTROL_PLAN_REQUEST.FAILED,
    message
});
