import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST, IAcknowledgeLogByPayrollRequest } from "./acknowledgeLogByPayrollConstants";


export interface IAcknowledgeLogByPayrollLoadAction {
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.REQUEST;
    data: IAcknowledgeLogByPayrollRequest
}
export const acknowledgeLogByPayrollLoadAction = (data: IAcknowledgeLogByPayrollRequest): IAcknowledgeLogByPayrollLoadAction => ({
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.REQUEST,
    data
});
export interface IAcknowledgeLogByPayrollSuccessAction {
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const acknowledgeLogByPayrollLoadSuccessAction = (message: SurewayAPIResponse<string>): IAcknowledgeLogByPayrollSuccessAction => ({
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.SUCCESS,
    message
});
export interface IAcknowledgeLogByPayrollLoadFailedAction {
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.FAILED;
    message: string;
}
export const acknowledgeLogByPayrollLoadFailedAction = (message: string): IAcknowledgeLogByPayrollLoadFailedAction => ({
    type: IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.FAILED,
    message
});
