import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_ATTACHMENTS_REQUEST, IAttachment } from "./getAttachmentsConstants";
import { IGetAttachmentsLoadAction, IGetAttachmentsLoadFailedAction, IGetAttachmentsSuccessAction } from "./getAttachmentsActions";
import { IUPDATE_ATTACHMENT_REQUEST } from "../updateAttachment/updateAttachmentConstants";
import { IADD_ATTACHMENT_REQUEST } from "../addAttachment/addAttachmentConstants";
import { IAddAttachmentSuccessAction } from "../addAttachment/addAttachmentActions";
import { IUpdateAttachmentSuccessAction } from "../updateAttachment/updateAttachmentActions";
import { IUpdateAttachmentTypeSuccessAction } from "../updateType/updateTypeActions";
import { IAddAttachmentTypeSuccessAction } from "../addType/addTypeActions";
import { IUpdateAttachmentCouplerSuccessAction } from "../updateCoupler/updateCouplerActions";
import { IAddAttachmentCouplerSuccessAction } from "../addCoupler/addCouplerActions";
import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "../updateCoupler/updateCouplerConstants";
import { IADD_ATTACHMENT_COUPLER_REQUEST } from "../addCoupler/addCouplerConstants";
import { IADD_ATTACHMENT_TYPE_REQUEST } from "../addType/addTypeConstants";
import { IUPDATE_ATTACHMENT_TYPE_REQUEST } from "../updateType/updateTypeConstants";
import { IUPDATE_ATTACHMENT_MOVE_REQUEST } from "../updateAttachmentMove/updateAttachmentMoveConstants";
import { IADD_ATTACHMENT_MOVE_REQUEST } from "../addAttachmentMove/addAttachmentMoveConstants";
import { IUpdateAttachmentMoveSuccessAction } from "../updateAttachmentMove/updateAttachmentMoveActions";
import { IAddAttachmentMoveSuccessAction } from "../addAttachmentMove/addAttachmentMoveActions";

type Actions =
    | IGetAttachmentsLoadAction
    | IGetAttachmentsSuccessAction
    | IGetAttachmentsLoadFailedAction
    | IUpdateAttachmentSuccessAction
    | IAddAttachmentSuccessAction
    | IUpdateAttachmentTypeSuccessAction
    | IAddAttachmentTypeSuccessAction
    | IUpdateAttachmentCouplerSuccessAction
    | IAddAttachmentCouplerSuccessAction
    | IUpdateAttachmentMoveSuccessAction
    | IAddAttachmentMoveSuccessAction
    | IFlushDataSuccessAction;

export const GetAttachmentsReducer = (state: Server<SurewayAPIResponse<ById<IAttachment>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IAttachment>>> => {
    switch (action.type) {
        case IGET_ATTACHMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_ATTACHMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ATTACHMENTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;
    
        case IADD_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};