import { IAddUpdateETDriver } from "../addDriver/addDriverConstants";

export enum IUPDATE_DRIVER_REQUEST {
    REQUEST = "updateDriver/UPDATE_DRIVER_REQUEST",
    SUCCESS = "updateDriver/UPDATE_DRIVER_SUCCESS",
    FAILED = "updateDriver/UPDATE_DRIVER_FAILED"
};

export interface IUpdateETDriverRequest {
    token: string;
    request: IAddUpdateETDriver;
};