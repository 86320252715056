import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ST_SCHEDULES_REQUEST } from "./updateSTSchedulesConstants";
import { IUpdateSTSchedulesLoadAction, IUpdateSTSchedulesLoadFailedAction, IUpdateSTSchedulesSuccessAction } from "./updateSTSchedulesActions";

type Actions =
    | IUpdateSTSchedulesLoadAction
    | IUpdateSTSchedulesSuccessAction
    | IUpdateSTSchedulesLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateSTSchedulesReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_ST_SCHEDULES_REQUEST.REQUEST:
            return loading;

        case IUPDATE_ST_SCHEDULES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_ST_SCHEDULES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};