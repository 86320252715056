
export enum IADD_ATTACHMENT_LIST_GET_REQUEST {
    REQUEST = "addAttachmentListGET/ADD_ATTACHMENT_LIST_GET_REQUEST",
    SUCCESS = "addAttachmentListGET/ADD_ATTACHMENT_LIST_GET_SUCCESS",
    FAILED = "addAttachmentListGET/ADD_ATTACHMENT_LIST_GET_FAILED"
};

export interface IAddAttachmentListGETRequest {
    token: string;
    request: IAddUpdateAttachmentListGET;
};

export interface IAddUpdateAttachmentListGET {
    ID?: number; //Only for Update
    Attachment_ID: number;
    Type_ID: number;
    Coupler_Design_ID: number;
    Style_ID: number;
    Part_No: string;
    Part_Type: string;
    Description: string;
    Quantity: number;
    Created_By: string;
};