import React from "react";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { TableContainer } from "@material-ui/core";
import { undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { ROUTE } from "../../routes";
import { IGetRepairLineVersionHistory } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryConstants";


interface IRepairLineVersionHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetRepairLineVersionHistory>;
    display: string | undefined;
};

export const RepairLineVersionHistoryDefectItem: React.FC<IRepairLineVersionHistoryProps> = React.memo((props: IRepairLineVersionHistoryProps) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data);

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY - {props.display}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading RepairLine Version History Component..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            // onEdit={onEdit}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="repairLineList"
                            customRowColor={true}
                            exportFileName="RepairLine-Version-History-Component"
                            storageKey="repairLineVersionHistoryComponentSessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "created", caption: "Created", type: "datetime", show: false },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "created_By", caption: "Requested By", type: "string" },
                                { name: "defect_Item", caption: "Defect Item", type: "string" },
                                { name: "issue", caption: "Issue", type: "string", width: 200 },
                                { name: "component_Code", caption: "Component Code", type: "number" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "winter_Repair", caption: "Winter Repair", type: "string" },
                                { name: "priority", caption: "Priority", type: "string" },
                                { name: "mechanic_Names", caption: "Assigned Mechanics", type: "string" },
                                { name: "lube_Driver", caption: "Lube Drivers", type: "string" },
                                { name: "manager_Instructions", caption: "Manager Instructions", type: "string" },
                                { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                                // { name: "mechanic_Comments", caption: "Mechanic Comments", type: "string" },
                                { name: "meter_Reading", caption: "Meter Reading", type: "number" },
                                { name: "parts_Notes", caption: "Parts Notes", type: "string", show: false },
                                { name: "work_Order_No", caption: "Work Order No.", type: "string" },
                                { name: "job_No", caption: "Job No.", type: "string" },
                                { name: "cost_Code", caption: "Cost Code", type: "string" },
                                { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string" },
                                { name: "serial_No", caption: "Serial No.", type: "string", show: false},
                                { name: "type", caption: "Type (New/Used)", type: "string", show: false },
                                { name: "hours", caption: "Hours", type: "number", show: false },
                                { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string", show: false },
                                { name: "damaged", caption: "Damaged", type: "string" },
                                { name: "damaged_Notes", caption: "Damaged Notes", type: "string", show: false },
                                { name: "created_Location_Info", caption: "Created Location Info", type: "string", show: false },
                                { name: "repair_Location_Info", caption: "Repair Location Info", type: "string", show: false },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});