import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SWAMPERS_REQUEST, ISwamper } from "./getSwampersConstants";
import { IADD_SWAMPER_REQUEST } from "../addSwamper/addSwamperConstants";
import { IAddSwamperSuccessAction } from "../addSwamper/addSwamperActions";
import { IUPDATE_SWAMPER_REQUEST } from "../updateSwamper/updateSwamperConstants";
import { IUpdateSwamperSuccessAction } from "../updateSwamper/updateSwamperActions";
import { IGetSwampersLoadAction, IGetSwampersLoadFailedAction, IGetSwampersSuccessAction } from "./getSwampersActions";

type Actions =
    | IGetSwampersLoadAction
    | IGetSwampersSuccessAction
    | IAddSwamperSuccessAction
    | IUpdateSwamperSuccessAction
    | IGetSwampersLoadFailedAction
    | IFlushDataSuccessAction;

export const GetSwampersReducer = (state: Server<SurewayAPIResponse<ById<ISwamper>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ISwamper>>> => {
    switch (action.type) {
        case IGET_SWAMPERS_REQUEST.REQUEST:
            return loading;

        case IGET_SWAMPERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SWAMPERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SWAMPER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SWAMPER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};