// import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PM_SHEET_REPORT_REQUEST, IPMSheetsReport, IGetPMSheetsReportRequest } from "./getPMSheetsReportConstants";

export interface IGetPMSheetsReportLoadAction {
    type: IGET_PM_SHEET_REPORT_REQUEST.REQUEST;
    data: IGetPMSheetsReportRequest
}
export const getPMSheetsReportLoadAction = (data: IGetPMSheetsReportRequest): IGetPMSheetsReportLoadAction => ({
    type: IGET_PM_SHEET_REPORT_REQUEST.REQUEST,
    data
});
export interface IGetPMSheetsReportSuccessAction {
    type: IGET_PM_SHEET_REPORT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IPMSheetsReport[]>;
}
export const getPMSheetsReportLoadSuccessAction = (list: SurewayAPIResponse<IPMSheetsReport[]>): IGetPMSheetsReportSuccessAction => ({
    type: IGET_PM_SHEET_REPORT_REQUEST.SUCCESS,
    list
});
export interface IGetPMSheetsReportLoadFailedAction {
    type: IGET_PM_SHEET_REPORT_REQUEST.FAILED;
    message: string;
}
export const getPMSheetsReportLoadFailedAction = (message: string): IGetPMSheetsReportLoadFailedAction => ({
    type: IGET_PM_SHEET_REPORT_REQUEST.FAILED,
    message
});
