import { connect } from "react-redux";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { ReactNode, PureComponent } from "react";
import PageSpacing from "../../shared/pageSpacing";
import { RouteComponentProps } from "react-router";
import { LACenteredLoading } from "../../shared/loading";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { callRouteWithQueryString } from "../../shared/constExports";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../redux/server";
import { getOperatorLogs } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsAccessor";
import { getOperatorLogsLoadAction } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsActions";
import { validateOperatorLogin } from "../../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginAccessor";
import { IGetOperatorLogsRequest, IOperatorLogs } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsConstants";
import { IValidateOperatorLogin } from "../../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginConstants";
import { ROUTE } from "../../routes";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { WHITE_COLOR } from "../../shared/theme";
import ExternalUserLogoutButton from "../logOut/externalUserLogOutButton";

interface IOperatorLogsStoreProps {
    getLogs: Server<SurewayAPIResponse<ById<IOperatorLogs>>>;
    validateOperatorLoginStatus: Server<IValidateOperatorLogin>;
};

interface IOperatorLogsDispatchProps {
    getLogsRequest: (request: IGetOperatorLogsRequest) => unknown;
};


interface IOperatorLogsOwnProps {

};

interface IOperatorLogsState {

};

const OperatorLogsStyles = styled(LAPaperWithLessPadding)`
    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
`;


type IOperatorLogsProps = RouteComponentProps
    & IOperatorLogsStoreProps
    & IOperatorLogsDispatchProps
    & IOperatorLogsOwnProps;

class OperatorLogs extends PureComponent<IOperatorLogsProps, IOperatorLogsState> {

    public constructor(props: IOperatorLogsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IOperatorLogsProps): void {
        if (this.props !== prevProps)
            this.callServer();

    };


    public render(): ReactNode {

        const { getLogs } = this.props;
        const { kind } = getLogs;
        const data = hasPayload(getLogs) ? Object.values(getLogs.payload.response) : [];

        return (
            <PageSpacing title="Operator Logs" description="Operator Logs" fixedSpaceOnSmallerScreens={true}>
                <OperatorLogsStyles>
                <ExternalUserLogoutButton />

                    <LAGrid>

                        <LAGridItem xs={12}>
                            <LAButton
                                label="Add New"
                                className="pull-left"
                                onClick={this.handleAdd}
                                startIcon={<AddIcon color={WHITE_COLOR} />}
                            />

                            <h2 className="text-center">OPERATOR LOGS</h2>
                            <hr />
                        </LAGridItem>

                        {(kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Please wait... Loading data" />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Failed to load data..." />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                            <LAPaperWithLessPadding>
                                <LADevExtremeGrid
                                    id="OperatorLogs"
                                    data={data}
                                    columnChoose={true}
                                    columnsHiding={true}
                                    onEdit={this.onEdit}
                                    onClick={this.onClick}
                                    searchPanel={true}
                                    filterHeader={true}
                                    export={true}
                                    actionWidth={120}
                                    removeStyleBtn={true}
                                    height={1200}
                                    customRowColor={true}
                                    exportFileName="OperatorLogs"
                                    storageKey="operatorLogSessionKey"
                                    columns={[
                                        { name: "date_", caption: "Date", type: "date" },
                                        { name: "name", caption: "Name", type: "string" },
                                        { name: "project", caption: "Project", type: "string" },
                                        { name: "foreman", caption: "Foreman", type: "string" },
                                        // { name: "start_Time", caption: "Start Time", type: "number" },
                                        // { name: "end_Time", caption: "End Time", type: "number" },
                                        // { name: "lunch", caption: "Lunch", type: "string" },
                                        { name: "total_Hours", caption: "Total Hours", type: "number" },
                                        { name: "travel", caption: "Travel", type: "string" },
                                        { name: "loa", caption: "Loa", type: "string" },
                                        { name: "comments", caption: "Comments", type: "string" },
                                        { name: "hurt_Today", caption: "Hurt Today", type: "date" },
                                        { name: "created_By", caption: "Created By", type: "string" },
                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                        { name: "start_Time_Val", caption: "Start Time Val", type: "string" },
                                        { name: "lunch_Val", caption: "Lunch Val", type: "string" },
                                        { name: "end_Time_Val", caption: "End Time Val", type: "string" },
                                        { name: "total_Hours_Val", caption: "Total Hours val", type: "number" },
                                    ]}
                                />
                            </LAPaperWithLessPadding>
                        </LAGridItem>}

                    </LAGrid>
                </OperatorLogsStyles>
            </PageSpacing>
        );
    }

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.EXTERNAL.OPERATOR_LOG
        });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.EXTERNAL.OPERATOR_LOG
        });
    };

    private onClick = (): void => {

    };


    private callServer = (): void => {

        if (isNotLoaded(this.props.validateOperatorLoginStatus)) {

        };

        if (isNotLoaded(this.props.getLogs) && hasPayload(this.props.validateOperatorLoginStatus))
            this.props.getLogsRequest({
                token: this.props.validateOperatorLoginStatus.payload.token,
                request: {
                    email: this.props.validateOperatorLoginStatus.payload.email ?? ""
                }
            });
    };

}

const mapStateToProps = (state: IStore): IOperatorLogsStoreProps => ({
    getLogs: getOperatorLogs(state),
    validateOperatorLoginStatus: validateOperatorLogin(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IOperatorLogsDispatchProps => ({
    getLogsRequest: (request: IGetOperatorLogsRequest): unknown => dispatch(getOperatorLogsLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(OperatorLogs);