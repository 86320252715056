import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_HAZARDS_REQUEST, IGetHazards } from "./getHazardsConstants";
import { IGetHazardsLoadAction, IGetHazardsLoadFailedAction, IGetHazardsSuccessAction, 
    getHazardsLoadFailedAction, getHazardsLoadSuccessAction } from "./getHazardsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getHazardsEpic: Epic = (
    action$: ActionsObservable<IGetHazardsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetHazardsSuccessAction | IGetHazardsLoadFailedAction> =>
    action$.ofType(IGET_HAZARDS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetHazards>>>(
                    END_POINTS.FIELD.FORMS.ADMIN.GET_HAZARDS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetHazards>>): IGetHazardsSuccessAction => {
                            return getHazardsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getHazardsLoadFailedAction(response.message)))
                    )
            )
        );