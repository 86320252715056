import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_LOOKUPS_REQUEST, IShopLookups } from "./getLookupsConstants";
import { IGetLookupsLoadAction, IGetLookupsLoadFailedAction, IGetLookupsSuccessAction, getLookupsLoadFailedAction, getLookupsLoadSuccessAction } from "./getLookupsActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getShopLookupsEpic: Epic = (
    action$: ActionsObservable<IGetLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetLookupsSuccessAction | IGetLookupsLoadFailedAction> =>
    action$.ofType(IGET_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IShopLookups>>(
                    END_POINTS.FIELD.GET_SHOP_LOOKUP,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IShopLookups>): IGetLookupsSuccessAction => {
                            return getLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getLookupsLoadFailedAction(response.message)))
                    )
            )
        );