import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IVendorOrderWork } from "../getVendorOrders/getVendorOrdersConstants";
import { IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST, IGetVendorItemsByOrderIDRequest } from "./getVendorItemsByOrderIDConstants";

export interface IGetVendorItemsByOrderIDLoadAction {
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.REQUEST;
    data: IGetVendorItemsByOrderIDRequest
}

export const getVendorItemsByOrderIDLoadAction = (data: IGetVendorItemsByOrderIDRequest): IGetVendorItemsByOrderIDLoadAction => ({
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.REQUEST,
    data
});

export interface IGetVendorItemsByOrderIDSuccessAction {
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IVendorOrderWork[]>;
}

export const getVendorItemsByOrderIDLoadSuccessAction = (list: SurewayAPIResponse<IVendorOrderWork[]>): IGetVendorItemsByOrderIDSuccessAction => ({
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.SUCCESS,
    list
});

export interface IGetVendorItemsByOrderIDLoadFailedAction {
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.FAILED;
    message: string;
}

export const getVendorItemsByOrderIDLoadFailedAction = (message: string): IGetVendorItemsByOrderIDLoadFailedAction => ({
    type: IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.FAILED,
    message
});
