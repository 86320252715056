import React from "react";
import { Switch, Route } from "react-router";
import { ROUTE } from "./routes";
import SecuredRoutes from "./securedRoutes";
import UnSecuredRoutes from "./unSecuredRoutes";

const Main: React.FC = () => {
    return <Switch>
        <Route path={ROUTE.INDEX} component={SecuredRoutes} />
    </Switch>
};

export default Main;


export const ExternalMain: React.FC = () => {
    return <Switch>
        <Route path={ROUTE.EXTERNAL.INDEX} component={UnSecuredRoutes} />
    </Switch>
};