
export enum IADD_ATTACHMENT_TYPE_REQUEST {
    REQUEST = "addAttachmentType/ADD_ATTACHMENT_TYPE_REQUEST",
    SUCCESS = "addAttachmentType/ADD_ATTACHMENT_TYPE_SUCCESS",
    FAILED = "addAttachmentType/ADD_ATTACHMENT_TYPE_FAILED"
};

export interface IAddAttachmentTypeRequest {
    token: string;
    request: IAddUpdateAttachmentType;
};

export interface IAddUpdateAttachmentType {
    ID?: number; //Only for Update
    Name: string;
    Active: string;
    Created_By: string;
    Modified_By: string;
}