import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST } from "./getVendorItemsByOrderIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetVendorItemsByOrderIDLoadAction, IGetVendorItemsByOrderIDLoadFailedAction, IGetVendorItemsByOrderIDSuccessAction, getVendorItemsByOrderIDLoadFailedAction, getVendorItemsByOrderIDLoadSuccessAction } from "./getVendorItemsByOrderIDActions";
import { IVendorOrderWork } from "../getVendorOrders/getVendorOrdersConstants";


export const getVendorItemsByOrderIDEpic: Epic = (
    action$: ActionsObservable<IGetVendorItemsByOrderIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetVendorItemsByOrderIDSuccessAction | IGetVendorItemsByOrderIDLoadFailedAction> =>
    action$.ofType(IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IVendorOrderWork[]>>(
                    END_POINTS.FIELD.VENDOR.GET_VENDOR_ITEMS_BY_ORDER_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IVendorOrderWork[]>): IGetVendorItemsByOrderIDSuccessAction => {
                            return getVendorItemsByOrderIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getVendorItemsByOrderIDLoadFailedAction(response.message)))
                    )
            )
        );