import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_REPAIR_LINE_BY_ID_REQUEST } from "./getRepairLineByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetRepairLineByIdLoadAction, IGetRepairLineByIdLoadFailedAction, IGetRepairLineByIdSuccessAction } from "./getRepairLineByIdActions";
import { IRepairLine } from "../getRepairLines/getRepairLinesConstants";
import { ISaveRepairLineItemSuccessAction } from "../saveRepairLineItem/saveRepairLineItemActions";
import { ISAVE_REPAIR_LINE_ITEM_REQUEST } from "../saveRepairLineItem/saveRepairLineItemConstants";

type Actions =
    | IGetRepairLineByIdLoadAction
    | IGetRepairLineByIdSuccessAction
    | IGetRepairLineByIdLoadFailedAction
    | ISaveRepairLineItemSuccessAction
    | IFlushDataSuccessAction;

export const GetRepairLineByIdReducer = (state: Server<SurewayAPIResponse<IRepairLine>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IRepairLine>> => {
    switch (action.type) {
        case IGET_REPAIR_LINE_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_LINE_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_LINE_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_REPAIR_LINE_ITEM_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};