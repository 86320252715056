import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_VERSION_HISTORY_REQUEST, IGetEquipmentVersionHistory, IGetEquipmentVersionHistoryRequest } from "./getEquipmentVersionHistoryConstants";

export interface IGetEquipmentVersionHistoryLoadAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetEquipmentVersionHistoryRequest
}
export const getEquipmentVersionHistoryLoadAction = (data: IGetEquipmentVersionHistoryRequest): IGetEquipmentVersionHistoryLoadAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentVersionHistorySuccessAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetEquipmentVersionHistory[]>;
}
export const getEquipmentVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<IGetEquipmentVersionHistory[]>): IGetEquipmentVersionHistorySuccessAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentVersionHistoryLoadFailedAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getEquipmentVersionHistoryLoadFailedAction = (message: string): IGetEquipmentVersionHistoryLoadFailedAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.FAILED,
    message
});
