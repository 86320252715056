import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetIGLookupsLoadAction, IGetIGLookupsLoadFailedAction, IGetIGLookupsSuccessAction } from "./getImageGalleryActions";
import { IGET_IG_LOOKUPS_REQUEST, IIGLookups } from "./getImageGalleryConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";

type Actions =
    | IGetIGLookupsLoadAction
    | IGetIGLookupsSuccessAction
    | IGetIGLookupsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetIGLookupsReducer = (state: Server<SurewayAPIResponse<IIGLookups>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IIGLookups>> => {
    switch (action.type) {
        case IGET_IG_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_IG_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_IG_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};