import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_TYPE_REQUEST, IAddAttachmentTypeRequest } from "./addTypeConstants";


export interface IAddAttachmentTypeLoadAction {
    type: IADD_ATTACHMENT_TYPE_REQUEST.REQUEST;
    data: IAddAttachmentTypeRequest
}
export const addAttachmentTypeLoadAction = (data: IAddAttachmentTypeRequest): IAddAttachmentTypeLoadAction => ({
    type: IADD_ATTACHMENT_TYPE_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentTypeSuccessAction {
    type: IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentTypeSuccessAction => ({
    type: IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentTypeLoadFailedAction {
    type: IADD_ATTACHMENT_TYPE_REQUEST.FAILED;
    message: string;
}
export const addAttachmentTypeLoadFailedAction = (message: string): IAddAttachmentTypeLoadFailedAction => ({
    type: IADD_ATTACHMENT_TYPE_REQUEST.FAILED,
    message
});
