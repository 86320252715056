import { SurewayAPIResponse } from './../../../react/shared/publicInterfaces';
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST, IEmployeeMessage } from "./getEmployeeMessagesByTokenConstants";
import { IGetEmployeeMessagesByTokenLoadAction, IGetEmployeeMessagesByTokenLoadFailedAction, IGetEmployeeMessagesByTokenSuccessAction } from "./getEmployeeMessagesByTokenActions";
import { ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST } from '../saveEmployeeMessageResponse/saveEmployeeMessageResponseConstants';
import { ISaveEmployeeMessageResponseSuccessAction } from '../saveEmployeeMessageResponse/saveEmployeeMessageResponseActions';

type Actions =
    | IGetEmployeeMessagesByTokenLoadAction
    | IGetEmployeeMessagesByTokenSuccessAction
    | IGetEmployeeMessagesByTokenLoadFailedAction
    | ISaveEmployeeMessageResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetEmployeeMessagesByTokenReducer = (state: Server<SurewayAPIResponse<IEmployeeMessage>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IEmployeeMessage>> => {
    switch (action.type) {
        case IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.REQUEST:
            return loading;

        case IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

            case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};