import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IAttachmentMove, IGetAttachmentMoveRequest } from "../../../redux/field/attachment/getAttachmentMoves/getAttachmentMovesConstants";
import { IAddAttachmentMoveRequest } from "../../../redux/field/attachment/addAttachmentMove/addAttachmentMoveConstants";
import { IUpdateAttachmentMoveRequest } from "../../../redux/field/attachment/updateAttachmentMove/updateAttachmentMoveConstants";
import { getAttachmentMovesStatus } from "../../../redux/field/attachment/getAttachmentMoves/getAttachmentMovesAccessor";
import { addAttachmentMoveStatus } from "../../../redux/field/attachment/addAttachmentMove/addAttachmentMoveAccessor";
import { updateAttachmentMoveStatus } from "../../../redux/field/attachment/updateAttachmentMove/updateAttachmentMoveAccessor";
import { addAttachmentMoveLoadAction } from "../../../redux/field/attachment/addAttachmentMove/addAttachmentMoveActions";
import { updateAttachmentMoveLoadAction } from "../../../redux/field/attachment/updateAttachmentMove/updateAttachmentMoveActions";
import { getAttachmentMovesLoadAction } from "../../../redux/field/attachment/getAttachmentMoves/getAttachmentMovesActions";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import { IFieldLookup, IFieldLookupRequest, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import LADatePicker from "../../shared/datePicker";
import { IAttachment, IGetAttachmentRequest } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
import { getAttachmentsLoadAction } from "../../../redux/field/attachment/getAttachments/getAttachmentsActions";
import { getAttachmentsStatus } from "../../../redux/field/attachment/getAttachments/getAttachmentsAccessor";
import { LACheckBox } from "../../shared/checkBox";
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@material-ui/core";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import LATextArea from "../../shared/textArea";

const RequiredFields: string[] = ["attachment_No", "complete_By", "unit_Number", "radio", "move_Type"];

interface IAddUpdateAttachmentMoveComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addAttachmentMoveStatus: Server<SurewayAPIResponse<string>>;
    attachments: Server<SurewayAPIResponse<ById<IAttachment>>>;
    updateAttachmentMoveStatus: Server<SurewayAPIResponse<string>>;
    attachmentMoves: Server<SurewayAPIResponse<ById<IAttachmentMove>>>;
};

interface IAddUpdateAttachmentMoveComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getAttachmentsRequest: (data: IGetAttachmentRequest) => unknown;
    addAttachmentMoveRequest: (data: IAddAttachmentMoveRequest) => unknown;
    getAttachmentMovesRequest: (data: IGetAttachmentMoveRequest) => unknown;
    updateAttachmentMoveRequest: (data: IUpdateAttachmentMoveRequest) => unknown;
};

interface IAddUpdateAttachmentMoveOwnProps {
    id?: string;
};

interface IAddUpdateAttachmentMoveComponentState {
    cancel: boolean;
    completed: boolean;
    serverError: string;
    data: IAttachmentMove;
    customMove: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateAttachmentMoveStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    .required {
        color: ${WARNING_COLOR};
    };

    .required_attachToUnit {
        color: ${WARNING_COLOR};
        font-size: 12px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateAttachmentMoveComponentProps =
    RouteComponentProps
    & IAddUpdateAttachmentMoveOwnProps
    & IAddUpdateAttachmentMoveComponentStoreProps
    & IAddUpdateAttachmentMoveComponentDispatchProps;

class AddUpdateAttachmentMove extends PureComponent<IAddUpdateAttachmentMoveComponentProps, IAddUpdateAttachmentMoveComponentState> {

    public constructor(props: IAddUpdateAttachmentMoveComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                style_ID: 0,
                type_ID: 0,
                coupler_Design_ID: 0,
                attachment_ID: 0,
                radio: "",
                custom: "",
                requested_By: hasPayload(this.props.token) ? this.props.token.payload.response.upn : "",
                // unit_ID: 0,
                // unit_Number: "",
                to_Sub_Location_ID: 0,
                move_Request_ID: 0,
                attached_Unit_ID: 0,
                status: "Dispatched",
                complete_By: "",
                attachment_No: "",
                from_Location: "",
                to_Location: "",
                attached_Unit_No: "",
                type: "",
                style: "",
                coupler_Design: "",
                previously_Moved: "No",
                move_Type: "",
                comments: "",
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            cancel: false,
            completed: false,
            customMove : false,
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateAttachmentMoveComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addAttachmentMoveStatus !== prevProps.addAttachmentMoveStatus) {

                if (this.props.addAttachmentMoveStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addAttachmentMoveStatus.message });

                if (hasPayload(this.props.addAttachmentMoveStatus) && this.props.addAttachmentMoveStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateAttachmentMoveStatus !== prevProps.updateAttachmentMoveStatus) {

                if (this.props.updateAttachmentMoveStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateAttachmentMoveStatus.message });

                if (hasPayload(this.props.updateAttachmentMoveStatus) && this.props.updateAttachmentMoveStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const query: any = queryString.parse(this.props.location.search);
        const { data, errors, serverError, cancel, completed, customMove} = this.state;
        const { token, addAttachmentMoveStatus, updateAttachmentMoveStatus, getLookup, attachments } = this.props;
        // const getRole = pageAccessCheck(token, "attachmentAccess");
        const attachmentsAdminAccess = hasPayload(token) ? token.payload.response.attachmentsAdmin : undefined;
        // const disabled = getRole === ReadOnly ? true : undefined;
        const disabled =  undefined;
        const lookUpData = hasPayload(getLookup) ? getLookup.payload.response : undefined;
        // console.log(data)
        const wss = lookUpData ? lookUpData.wssList : [];
        const units = lookUpData ? lookUpData.units : [];
        const foremanList = lookUpData ? lookUpData.foremanList : undefined;
        const list = hasPayload(attachments) ? Object.values(attachments.payload.response) : [];
        const attachmentList = this.getAttachment(list);
        // const typeList = this.getType(list);
        // const couplerList = this.getCouplerDesign(list);
        // const styleList = this.getStyleDesign(list);
        const onWSS = (value: string): void => this.handleNumber("to_Sub_Location_ID", value.toString());
        const onFromLocation = (value: string): void => this.handleNumber("from_Sub_Location_ID", value.toString());
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => this.handleRadioChange(value);
        const handleRequestedByChange = (event: unknown, value: any): void => this.handleChange("requested_By", value !== null ? value.upn : data.requested_By);
        // const onMoveTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => this.handleMoveTypeChange(value);
        const disableByStatus = (data.status === "Cancelled" || data.status === "Completed") ? true : undefined;

        return (
            <PageSpacing title="Attachment Move Request" description="FIELD - ATTACHMENT MOVE REQUEST" fixedSpaceOnSmallerScreens={true}>
                <AddUpdateAttachmentMoveStyles>
                    {/* {(getRole !== NotApplicable) && */}
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id > 0 ? "VIEW/UPDATE " : "ADD "} GET MOVE</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} >
                                {/* <strong>Custom Move?</strong><br /> */}
                                <LACheckBox
                                    value={this.state.customMove ? true : false}
                                    label="Custom Parts/Misc?"
                                    disabled={data.id > 0 || query.attachment_ID > 0 ? true : undefined}
                                    onChange={this.handleCustomMoveCheckBox}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                {!customMove && <DevExtremePopupLookup
                                        data={attachmentList}
                                        id="attachment-no"
                                        placeHolder="Attachment No"
                                        disabled={disabled || disableByStatus || data.id > 0 || query.attachment_ID > 0}
                                        displayExp="attachment_No"
                                        name="attachment_No"
                                        errorName="attachment_No"
                                        errors={errors}
                                        currentVal={data.attachment_No}
                                        onClick={this.onAttachment}
                                        columns={[
                                            { name: "attachment_No", caption: "Attachment No", type: "string" },
                                            { name: "type", caption: "Type", type: "string" },
                                            { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                            { name: "style", caption: "Style", type: "string" },
                                            { name: "location", caption: "Location", type: "string" },
                                            { name: "attached_Unit_No", caption: "Attached Unit No", type: "string" },
                                            { name: "move_Status", caption: "Move Status", type: "string" }
                                        ]} 
                                />}

                                {customMove && <LATextField
                                    label="Name"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled || disableByStatus || data.id > 0 }
                                    name="custom"
                                    value={data.custom ?? ""}
                                    onChange={this.handleCustomChange}
                                    errorText={errors["custom"] ? errors["custom"].message : undefined}
                                />}
                            </LAGridItem>

                            {!customMove && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Type"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="type"
                                    value={data.type}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {!customMove && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Coupler Design"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="type"
                                    value={data.coupler_Design}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {!customMove && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Style"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="type"
                                    value={data.style}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LAAutoComplete
                                    disabled={disabled || disableByStatus || data.id > 0}
                                    multiple={false}
                                    option={foremanList}
                                    getOptionLabel="display_Name"
                                    autoHighlight={true}
                                    onChange={handleRequestedByChange}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Requested By"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["requested_By"] ? errors["requested_By"].message : undefined}
                                    value={(data.requested_By && foremanList) ? foremanList.find((q: IForeman) => q.upn === data.requested_By) : null}
                                    defaultValue={(data.requested_By && foremanList) ? foremanList.find((q: IForeman) => q.upn === data.requested_By) : null}
                                />
                            </LAGridItem>

                            {!customMove && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="From Location"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="from_Location"
                                    value={data.from_Location}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {customMove && <LAGridItem xs={12} sm={6} md={3}>
                                <DevExtremePopupLookup
                                    data={wss}
                                    id="from-location-search"
                                    placeHolder="From Location"
                                    disabled={disabled || disableByStatus || data.id > 0}
                                    displayExp="site"
                                    name="wsS_ID"
                                    errorName="from_Sub_Location_ID"
                                    errors={errors}
                                    returnExp="wsS_ID"
                                    currentVal={data.from_Sub_Location_ID}
                                    onClick={onFromLocation}
                                    columns={[
                                        { name: "site", caption: "Site", type: "string" },
                                        { name: "job", caption: "Job", type: "string" },
                                        { name: "site_Address", caption: "Site Address", type: "string" }
                                    ]}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.id > 0 ? (data.to_Sub_Location_ID > 0 ? "move_To_Location" : "attach_To_Unit") : data.radio } onChange={onRadioChange} >
                                        <FormControlLabel value={"move_To_Location"} control={<Radio />} label="Move To Location" disabled= {data.id > 0}/>
                                        <FormControlLabel value={"attach_To_Unit"} control={<Radio />} label="Attach To Unit" disabled= {data.id > 0 || !attachmentsAdminAccess} />
                                        
                                    </RadioGroup>
                                    <FormHelperText className="required">{errors["radio"] ? errors["radio"].message : undefined}</FormHelperText>
                                    {!attachmentsAdminAccess && <span className="required_attachToUnit">Only Parts admin can attach to unit</span>}
                            </LAGridItem>

                            {(data.radio === "move_To_Location" || data.to_Sub_Location_ID > 0) && <LAGridItem xs={12} sm={6} md={3}>
                                <DevExtremePopupLookup
                                    data={wss}
                                    id="to-location-search"
                                    placeHolder="To Location"
                                    disabled={disabled || disableByStatus || data.id > 0}
                                    displayExp="site"
                                    name="wsS_ID"
                                    errorName="wsS_ID"
                                    errors={errors}
                                    returnExp="wsS_ID"
                                    currentVal={data.to_Sub_Location_ID}
                                    onClick={onWSS}
                                    columns={[
                                        { name: "site", caption: "Site", type: "string" },
                                        { name: "job", caption: "Job", type: "string" },
                                        { name: "site_Address", caption: "Site Address", type: "string" }
                                    ]}
                                />
                            </LAGridItem>}

                            {(data.radio === "attach_To_Unit" || data.attached_Unit_ID > 0 || data.attachment_ID > 0) && <LAGridItem xs={12} sm={6} md={3}>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder="Attached Unit"
                                    disabled={disabled || disableByStatus || data.id > 0 || data.to_Sub_Location_ID > 0 || data.radio !== "attach_To_Unit"}
                                    displayExp="unit_Number"
                                    name="unit_Number"
                                    errorName="unit_Number"
                                    errors={errors}
                                    currentVal={data?.attached_Unit_No}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                    ]}
                                />
                            </LAGridItem>}

                            {/* <LAGridItem xs={12} sm={6} md={3}>
                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.move_Type} onChange={onMoveTypeChange} >
                                        <FormControlLabel value={"Parts"} control={<Radio />} label="Parts Move" disabled= {disabled || disableByStatus}/>
                                        <FormControlLabel value={"Trucking"} control={<Radio />} label="Trucking Move" disabled= {disabled || disableByStatus} />
                                    </RadioGroup>
                                    <FormHelperText className="required">{errors["move_Type"] ? errors["move_Type"].message : undefined}</FormHelperText>
                            </LAGridItem> */}
                            {/* <LAGridItem xs={6} sm={3} md={2}><></></LAGridItem> */}

                            {data.id === 0  && <LAGridItem xs={6} sm={3} md={2}>
                                <LACheckBox
                                    value={data.move_Type === "Parts" ? true : false}
                                    label="Have Attachment moved By Parts (will send email to Parts)"
                                    onChange={this.handleMoveTypeCheckBox}
                                />
                                <FormHelperText className="required">{errors["move_Type"] ? errors["move_Type"].message : undefined}</FormHelperText>
                            </LAGridItem>}

                            {data.id === 0  && <LAGridItem xs={6} sm={3} md={1}>
                                <LACheckBox
                                    value={data.previously_Moved === "Yes" ? true : false}
                                    label="Already Moved"
                                    onChange={this.handlePrevMoveCheckBox}
                                />
                            </LAGridItem>}

                            {data.previously_Moved !== "Yes" && <LAGridItem xs={12} sm={6} md={3} className="text-center">
                                <strong>Complete By</strong>
                                <br />
                                <LADatePicker
                                    id="complete_By"
                                    disable={disabled || disableByStatus}
                                    value={data.complete_By}
                                    onChange={this.handleCompleteBy}
                                />
                                <br />
                                {errors["complete_By"] ? <span className="required">{errors["complete_By"].message}</span> : undefined}
                            </LAGridItem>}

                            

                            {/* <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Attached Unit"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="attached_Unit_No"
                                    value={data.attached_Unit_No}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem> */}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Status"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="status"
                                    value={data.status}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextArea
                                    rowsMax={3}
                                    minRows={3}
                                    fullWidth={true}
                                    name="comments"
                                    label="Comments"
                                    disabled={disabled || disableByStatus}
                                    value={data.comments}
                                    onChange={this.handleChange}
                                     />
                            </LAGridItem>
                            {/* {data.id ==0  && <LAGridItem xs={12} sm={6} md={3}>
                                <LACheckBox
                                    value={data.previously_Moved === "Yes" ? true : false}
                                    label="Completed"
                                    disabled={data.id > 0 || disableByStatus}
                                    onChange={this.handlePrevMoveCheckBox}
                                />
                            </LAGridItem>} */}

                            {data.id > 0  && data.previously_Moved === "Yes" && <LAGridItem xs={12} sm={6} md={3}>
                                <LACheckBox
                                    value={data.previously_Moved === "Yes" ? true : false}
                                    label="Previously Moved"
                                    disabled={data.id > 0 || disableByStatus}
                                    onChange={this.handlePrevMoveCheckBox}
                                />
                            </LAGridItem>}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={1}>
                                <LACheckBox
                                    value={cancel}
                                    label="Cancel"
                                    disabled={disableByStatus}
                                    onChange={this.handleCancelCheckBox}
                                />
                            </LAGridItem>}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={1}>
                                <LACheckBox
                                    value={completed}
                                    label="Completed"
                                    disabled={disableByStatus}
                                    onChange={this.handleCompletedCheckBox}
                                />
                            </LAGridItem>}

                            {data.id && data.id > 0 ? <LAGrid>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            variant="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </LAGrid> : null}

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            {serverError.length > ZEROTH && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}
                            
                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    onSave={this.handleSave}
                                    cancelButtonText="Close"
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > 0 ? true : (disabled || disableByStatus)}
                                />
                            </LAGridItem>

                        </LAGrid>

                    <RequestStatus requestStatus={addAttachmentMoveStatus.kind} successMessage="Attachment Move has been added successfully" />
                    <RequestStatus requestStatus={updateAttachmentMoveStatus.kind} successMessage="Attachment Move has been updated successfully" />
                </AddUpdateAttachmentMoveStyles>
            </PageSpacing>
        );
    }

    private handleCustomMoveCheckBox = (checked: boolean): void => {
        // console.log(checked)
        let errors = { ...this.state.errors };
        if(checked) {
            delete errors["attachment_No"]
            errors = this.errorChecker("custom", "",  errors);
            errors = this.errorChecker("from_Sub_Location_ID", "",  errors);
        }
        if(!checked) {
            errors = this.errorChecker("attachment_No", "",  errors);
            delete errors["custom"];
            delete errors["from_Sub_Location_ID"];
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                custom: "",
                type: "",
                attachment_ID: 0,
                style: "",
                type_ID: 0,
                style_ID: 0,
                coupler_Design_ID: 0,
                from_Location: "",
                attachment_No: "",
                coupler_Design: "",
                attached_Unit_No: "",
                attached_Unit_ID: 0,
                from_Sub_Location_ID: 0,
            },
            customMove: checked,
            errors
        });
    };

    private handleCustomChange = (name: string, value: string): void => {
        // console.log(name, value);
        let errors = { ...this.state.errors };
        
        errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    }

    private handleMoveTypeCheckBox = (checked: boolean): void => {
        // console.log(checked)
        let errors = { ...this.state.errors };
        if(checked) {
            delete errors["move_Type"];
            errors = this.errorChecker("complete_By", "",  errors);
        }
        if(!checked) {
            delete errors["complete_By"];
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                move_Type: checked ? "Parts" : "",
                previously_Moved : checked ? "No" : "Yes",
                complete_By: !checked ? new Date().toISOString() : ""
            },
            errors
        });
    };
    private handlePrevMoveCheckBox = (checked: boolean): void => {
        // console.log(checked)
        let errors = { ...this.state.errors };
        if(checked) {
            delete errors["move_Type"];
            delete errors["complete_By"];
        } else {
            errors = this.errorChecker("complete_By", "",  errors);
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                previously_Moved: checked ? "Yes" : "No",
                move_Type: checked ? "" : "Parts",
                complete_By: checked ? new Date().toISOString() : ""
            },
            errors
        });
    };

    private handleCancelCheckBox = (checked: boolean): void => {
        // console.log(checked)
        this.setState({
            cancel: checked,
            completed: checked === true ? false : this.state.completed 
        });
    };

    private handleCompletedCheckBox = (checked: boolean): void => {
        this.setState({
            cancel: checked === true ? false : this.state.cancel ,
            completed: checked
        });
    };

    private getAttachment = (data: IAttachment[]): IAttachment[] => {
        const state = this.state.data;
        let list: IAttachment[] = [];

        list = data.filter((x) => (x.attachment_No === state.attachment_No) ? true : x.status === "Active" && ((x.move_Status === null) || (x.move_Status === "") || (x.move_Status === "Completed")));
        // data
        // .filter((x) => 
        //     (x.attachment_No === state.attachment_No) ? true :
        //     (x.move_Status === null) || (x.move_Status === "") || (x.move_Status === "Completed")
        // )
        // .forEach((x) => {
        //     if ((state.coupler_Design !== null && state.type !== null) && (state.coupler_Design !== "" && state.type !== "")) {
        //         if ((x.coupler_Design === state.coupler_Design) && (x.type === state.type)) {
        //             list.push(x);
        //         }
        //     } else if (state.type !== null && state.type !== "") {
        //         if (x.type === state.type) {
        //             list.push(x);
        //         }
        //     } else if (state.coupler_Design !== null && state.coupler_Design !== "") {
        //         if (x.coupler_Design === state.coupler_Design) {
        //             list.push(x);
        //         }
        //     } else {
        //         list.push(x);
        //     }
        // });

        return list;
    };

    // private getType = (data: IAttachment[]): IAttachment[] => {
    //     const state = this.state.data;
    //     const list: IAttachment[] = [];

    //     data
    //     .filter((x) => 
    //     (x.type_ID === state.type_ID) ? true :
    //     (x.move_Status === null) || (x.move_Status === "") || (x.move_Status === "Completed")
    //     )
    //     .forEach((x) => {
    //         const idx = list.findIndex((q) => q.type === x.type)

    //         if (idx < 0)
    //             if (state.attachment_ID !== 0) {
    //                 if (state.attachment_ID === x.id)
    //                     list.push(x);
    //             } else if ((state.style !== null && state.coupler_Design !== null) && (state.style !== "" && state.coupler_Design !== "")) {
    //                 if (state.style === x.style && state.coupler_Design === x.coupler_Design)
    //                     list.push(x);
    //             } else if (state.style !== null && state.style !== "") {
    //                 if (state.style === x.style)
    //                     list.push(x);
    //             } else if (state.coupler_Design !== null && state.coupler_Design !== "") {
    //                 if (state.coupler_Design === x.coupler_Design)
    //                     list.push(x);
    //             } else {
    //                 list.push(x);
    //             }
    //     });

    //     return list;
    // };

    // private getCouplerDesign = (data: IAttachment[]): IAttachment[] => {
    //     const state = this.state.data;
    //     const list: IAttachment[] = [];

    //     data
    //     .filter((x) => 
    //     (x.coupler_Design_ID === state.coupler_Design_ID) ? true :
    //     (x.move_Status === null) || (x.move_Status === "") || (x.move_Status === "Completed")
    //     )
    //     .forEach((x) => {
    //         const idx = list.findIndex((q) => q.coupler_Design === x.coupler_Design)

    //         if (idx < 0)
    //             if (state.attachment_ID !== 0) {
    //                 if (state.attachment_ID === x.id)
    //                     list.push(x);
    //             } else if ((state.type !== null && state.style !== null) && state.type !== "" && state.style !== "") {
    //                 if (state.type === x.type && state.style === x.style)
    //                     list.push(x);
    //             } else if ((state.type !== null) && state.type !== "") {
    //                 if (state.type === x.type)
    //                     list.push(x);
    //             } else if (state.style !== null && state.style !== "") {
    //                 if (state.style === x.style)
    //                     list.push(x);
    //             } else {
    //                 list.push(x);
    //             };
    //     });

    //     return list;
    // };

    // private getStyleDesign = (data: IAttachment[]): IAttachment[] => {
    //     const state = this.state.data;
    //     const list: IAttachment[] = [];

    //     data
    //     .filter((x) => 
    //     (x.style_ID === state.style_ID) ? true :
    //     (x.move_Status === null) || (x.move_Status === "") || (x.move_Status === "Completed")
    //     )
    //     .forEach((x) => {
    //         const idx = list.findIndex((q) => q.style === x.style)

    //         if (idx < 0)
    //             if (state.attachment_ID !== 0) {
    //                 if (state.attachment_ID === x.id)
    //                     list.push(x);
    //             } else if ((state.type !== null && state.coupler_Design !== null) && (state.type !== "" && state.coupler_Design !== "")) {
    //                 if (state.type === x.type && state.coupler_Design === x.coupler_Design)
    //                     list.push(x);
    //             } else if (state.type !== null && state.type !== "") {
    //                 if (state.type === x.type)
    //                     list.push(x);
    //             } else if (state.coupler_Design !== null && state.coupler_Design !== "") {
    //                 if (state.coupler_Design === x.coupler_Design)
    //                     list.push(x);
    //             } else {
    //                 list.push(x);
    //             }
    //     });

    //     return list;
    // };

    private handleNumber = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value !== "0" ? value : "", errors);

        if (name === "to_Sub_Location_ID")
            errors = this.errorChecker("wsS_ID", value !== "0" ? value : "", errors);

        if (name === "from_Sub_Location_ID")
            errors = this.errorChecker("from_Sub_Location_ID", value !== "0" ? value : "", errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value !== "" ? Number(value) : null
            },
            errors
        });
    };

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);
        // console.log(unit)
        let errors = { ...this.state.errors };
        if (value !== undefined) {
            errors = this.errorChecker("unit_Number", value !== "" ? value : "", errors);
        } else {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                attached_Unit_ID: unit?.id ?? 0,
                attached_Unit_No: value ? value : "",
                // unit_ID: unit?.id ?? 0,
                // unit_Number: value
            },
            errors
        });
    };

    private handleRadioChange = (value: string): void => {
        let errors = { ...this.state.errors };
        let iS = this.state.data;
        errors = this.errorChecker("radio", value !== "" ? value : "", errors);
        // console.log(value)
        if(value === "move_To_Location") {
            // iS.unit_ID = 0;
            // iS.unit_Number = "";
            errors = this.errorChecker("wsS_ID", iS.to_Sub_Location_ID?.toString() !== "0" ? iS.to_Sub_Location_ID?.toString() : "", errors);
            if (errors["unit_Number"])
                delete errors["unit_Number"]
        } 
        else if(value === "attach_To_Unit") {
            iS.to_Sub_Location_ID = 0;
            // console.log(iS.attached_Unit_ID)
            errors = this.errorChecker("unit_Number", iS.attached_Unit_ID ? (iS.attached_Unit_ID?.toString() !== "0" ? iS.attached_Unit_ID?.toString() : "") : "", errors);
            if (errors["wsS_ID"])
                delete errors["wsS_ID"]
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                radio: value
            },
            errors
        });
    };

    // private handleMoveTypeChange = (value: string): void => {
    //     let errors = { ...this.state.errors };
    //     errors = this.errorChecker("move_Type", value !== "" ? value : "", errors);

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             move_Type: value
    //         },
    //         errors
    //     });
    // };

    private onAttachment = (val: string): void => {
       if(hasPayload(this.props.attachments)){
        let errors = { ...this.state.errors };
        const list: IAttachment[] = this.getAttachment(Object.values(this.props.attachments.payload.response));
        const idx: number = list.findIndex((x) => x.attachment_No === val);
        
        if (idx >= 0) {
            const value = list[idx];
            if (errors["attachment_No"])
                delete errors["attachment_No"]

            if (errors["type"])
                delete errors["type"]

            if (errors["style"])
                delete errors["style"]

            if (errors["coupler_Design"])
                delete errors["coupler_Design"]

            if (errors["unit_Number"])
                delete errors["unit_Number"]

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    type: value.type,
                    attachment_ID: value.id,
                    style: value.style,
                    type_ID: value.type_ID ?? 0,
                    style_ID: value.style_ID ?? 0,
                    coupler_Design_ID: value.coupler_Design_ID ?? 0,
                    from_Location: value.location ? value.location : "",
                    attachment_No: value.attachment_No,
                    coupler_Design: value.coupler_Design,
                    attached_Unit_No: value.attached_Unit_No,
                    attached_Unit_ID: value.attachment_Unit_ID ?? 0,
                    from_Sub_Location_ID: value.working_Site_Sub_ID ?? 0,
                },
                errors
            });
        } else {
            this.handleDropDownReset();
        };
       }
    };

    // private onType = (name: unknown, value: IAttachment): void => {
    //     let errors = { ...this.state.errors };
    //     const data = { ...this.state.data };

    //     if (value !== null) {
    //         data.type = value.type;
    //         data.type_ID = value.type_ID ?? 0;
    //         errors = this.errorChecker("type", data.type, errors);

    //         this.setState({
    //             ...this.state,
    //             data,
    //             errors
    //         });
    //     } else {
    //         this.handleDropDownReset();
    //     };
    // };

    // private onCoupler = (name: unknown, value: IAttachment): void => {
    //     let errors = { ...this.state.errors };
    //     const data = { ...this.state.data };

    //     if (value !== null) {
    //         data.coupler_Design = value.coupler_Design;
    //         data.coupler_Design_ID = value.coupler_Design_ID ?? 0;
    //         errors = this.errorChecker("coupler_Design", data.coupler_Design, errors);

    //         this.setState({
    //             ...this.state,
    //             data,
    //             errors
    //         });
    //     } else {
    //         this.handleDropDownReset();
    //     };
    // };

    // private onStyle = (name: unknown, value: IAttachment): void => {
    //     let errors = { ...this.state.errors };
    //     const data = { ...this.state.data };

    //     if (value !== null) {
    //         data.style = value.style;
    //         data.style_ID = value.style_ID ?? 0;
    //         errors = this.errorChecker("style", data.style, errors);

    //         this.setState({
    //             ...this.state,
    //             data,
    //             errors
    //         });
    //     } else {
    //         this.handleDropDownReset();
    //     };
    // };

    private handleDropDownReset = (): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        data.attachment_ID = 0;
        data.type = "";
        data.style = "";
        data.attachment_No = "";
        data.from_Location = "";
        data.coupler_Design = "";
        data.attached_Unit_ID = 0;
        data.attached_Unit_No = "";
        data.style_ID = 0;
        data.coupler_Design_ID = 0;
        data.type_ID = 0;
        data.from_Sub_Location_ID = undefined;

        errors = this.errorChecker("attachment_No", data.attachment_No, errors);
        errors = this.errorChecker("type", data.type, errors);
        errors = this.errorChecker("style", data.style, errors);
        errors = this.errorChecker("coupler_Design", data.coupler_Design, errors);

        this.setState({
            ...this.state,
            data,
            errors
        });
    };

    private handleCompleteBy = (value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes("complete_By"))
            errors = this.errorChecker("complete_By", value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                complete_By: value
            },
            errors
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        const query = queryString.parse(this.props.location.search);
        if(query.attachment_ID) {
            this.props.history.push({
                pathname: ROUTE.FIELD.ATTACHMENT_LIST.LOCATION_VIEW,
                search: getTokenFromUrl(false)
            });
        } else {
            this.props.history.push({
                pathname: ROUTE.FIELD.ATTACHMENT_LIST.MOVE_REQUESTS,
                search: getTokenFromUrl(false)
            });
        }
    };

    private handleSave = async (): Promise<void> => {
        const { id, attached_Unit_ID, status, from_Sub_Location_ID, attachment_ID, to_Sub_Location_ID, 
            created_By, type_ID, coupler_Design_ID, style_ID, complete_By, previously_Moved, move_Type, custom, requested_By, comments} = this.state.data;

        const Complete_By = new Date(complete_By).toISOString();

        let currentDate = new Date();
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        let selectedDate = new Date(complete_By);
        selectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
         
        const isCompletedDateInPastOrToday = selectedDate <= currentDate;

        if (hasPayload(this.props.token)) {
            if (id === 0) {
                if(previously_Moved === "Yes" && !isCompletedDateInPastOrToday) {
                    alert("Complete By date should be less than or equal to today's date.")
                } 
                else {
                    this.props.addAttachmentMoveRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            Complete_By,
                            Custom: custom,
                            Requested_By: requested_By,
                            Status: status,
                            Type_ID: type_ID,
                            Style_ID: style_ID,
                            Attachment_ID: attachment_ID,
                            Attached_Unit_ID: attached_Unit_ID,
                            Coupler_Design_ID: coupler_Design_ID,
                            To_Sub_Location_ID: to_Sub_Location_ID,
                            From_Sub_Location_ID: from_Sub_Location_ID,
                            Move_Type: move_Type,
                            Previously_Moved: previously_Moved,
                            Comments: comments,
                            Created_By: this.props.token.payload.response.upn,
                            Modified_By: this.props.token.payload.response.upn
                        }
                    });
                }
            } else {
                this.props.updateAttachmentMoveRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Complete_By,
                        Custom: custom,
                        Requested_By: requested_By,
                        Type_ID: type_ID,
                        Style_ID: style_ID,
                        Created_By: created_By,
                        Attachment_ID: attachment_ID,
                        Attached_Unit_ID: attached_Unit_ID,
                        Coupler_Design_ID: coupler_Design_ID,
                        To_Sub_Location_ID: to_Sub_Location_ID,
                        From_Sub_Location_ID: from_Sub_Location_ID,
                        Move_Type: move_Type,
                        Status: this.state.cancel ? "Cancelled" : this.state.completed ? "Completed": "Dispatched",
                        Previously_Moved: previously_Moved,
                        Comments: comments,
                        Modified_By: this.props.token.payload.response.upn,
                    }
                });
            };
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        // const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);
        const query: any = queryString.parse(this.props.location.search);

        this.props.history.push({
            pathname: ROUTE.FIELD.ATTACHMENT_LIST.INDEX,
            search: getTokenFromUrl(false)
        });

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                // if (pageAccessCheck(this.props.token, "attachmentAccess") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookup))
                        this.getLook();

                    if (isNotLoaded(this.props.attachments))
                        this.props.getAttachmentsRequest({
                            token: this.props.token.payload.response.token
                        });

                    if (query.id !== "0") {
                        if (hasPayload(this.props.attachmentMoves)) {
                            const data: any = this.props.attachmentMoves.payload.response[query.id];

                            let errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                if(x === "wsS_ID"){
                                    if(data.to_Sub_Location_ID !== 0) {
                                        const val = data["to_Location"] === null ? "" : data["to_Location"];
                                        errors = this.errorChecker("wsS_ID", val, errors);
                                    }
                                } else {
                                    const val = data[x] === null ? "" : data[x];
                                    errors = this.errorChecker(x, val, errors);
                                };
                            });

                            if(data.attachment_ID === 0) {
                                delete errors["attachment_No"]
                            }

                            this.setState({
                                data,
                                errors,
                                cancel: data.status === "Cancelled" ? true : false,
                                completed: data.status === "Completed" ? true : false,
                                customMove: data.attachment_ID === 0 ? true : false
                            });
                        } else {
                            this.props.getAttachmentMovesRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        if(query.attachment_ID) {
                            if (hasPayload(this.props.attachments)) {
                                const data = this.props.attachments.payload.response[query.attachment_ID];
                                const errors: ById<IFieldErrorKeyValue> = {};
                                // console.log(data)

                                const initialState : any = {...this.state.data}

                                initialState.attachment_ID = data.id;
                                initialState.attachment_No = data.attachment_No;
                                initialState.type_ID = data.type_ID ?? 0;
                                initialState.type = data.type;
                                initialState.coupler_Design_ID = data.coupler_Design_ID ?? 0;
                                initialState.coupler_Design = data.coupler_Design;
                                initialState.style_ID = data.style_ID ?? 0;
                                initialState.style = data.style;
                                initialState.attached_Unit_ID = data.attachment_Unit_ID;
                                initialState.attached_Unit_No = data.attached_Unit_No;
                                initialState.from_Sub_Location_ID = data.working_Site_Sub_ID ?? 0;
                                initialState.from_Location = data.location;

                                RequiredFields.forEach((x) => {
                                    if ((initialState[x] === undefined) || (initialState[x] === "") || (initialState[x] === 0))
                                        errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                                });

                                delete errors["unit_Number"]
                                
                                this.setState({ data : initialState, errors, customMove: false });
                            }
                        } else {
                            const data: any = this.state.data;
                            const errors: ById<IFieldErrorKeyValue> = {};
                            
                            RequiredFields.forEach((x) => {
                                if ((data[x] === undefined) || (data[x] === "") || (data[x] === 0))
                                    errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });
    
                            this.setState({ errors });
                        }
                    };

                // } else {
                //     this.props.history.push({
                //         pathname: ROUTE.ACCESS_DENIED,
                //         search: getTokenFromUrl(false)
                //     });
                // };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Attachment_List"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateAttachmentMoveComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    attachments: getAttachmentsStatus(state),
    attachmentMoves: getAttachmentMovesStatus(state),
    addAttachmentMoveStatus: addAttachmentMoveStatus(state),
    updateAttachmentMoveStatus: updateAttachmentMoveStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateAttachmentMoveComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getAttachmentsRequest: (data: IGetAttachmentRequest) => dispatch(getAttachmentsLoadAction(data)),
    addAttachmentMoveRequest: (data: IAddAttachmentMoveRequest) => dispatch(addAttachmentMoveLoadAction(data)),
    getAttachmentMovesRequest: (data: IGetAttachmentMoveRequest) => dispatch(getAttachmentMovesLoadAction(data)),
    updateAttachmentMoveRequest: (data: IUpdateAttachmentMoveRequest) => dispatch(updateAttachmentMoveLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateAttachmentMove);