import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IFormResponseByID, IGET_FORM_RESPONSE_BY_ID_REQUEST } from "./getFormResponseByIDConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetFormResponseByIDLoadAction, IGetFormResponseByIDLoadFailedAction, IGetFormResponseByIDSuccessAction, getFormResponseByIDLoadFailedAction, getFormResponseByIDLoadSuccessAction } from "./getFormResponseByIDActions";


export const getFormResponseByIDEpic: Epic = (
    action$: ActionsObservable<IGetFormResponseByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormResponseByIDSuccessAction | IGetFormResponseByIDLoadFailedAction> =>
    action$.ofType(IGET_FORM_RESPONSE_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IFormResponseByID>>(
                    END_POINTS.FIELD.GET_FORM_RESPONSE_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IFormResponseByID>): IGetFormResponseByIDSuccessAction => {
                            return getFormResponseByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormResponseByIDLoadFailedAction(response.message)))
                    )
            )
        );