import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
import { IAddMeterReadingSuccessAction } from "../addMeterReading/addMeterReadingActions";
import { IADD_METER_READING_REQUEST } from "../addMeterReading/addMeterReadingConstants";
import { IUpdateMeterReadingSuccessAction } from "../updateMeterReading/updateMeterReadingActions";
import { IUPDATE_METER_READING_REQUEST } from "../updateMeterReading/updateMeterReadingConstants";
import { IGetMeterHistoryBySOCTIDLoadAction, IGetMeterHistoryBySOCTIDLoadFailedAction, IGetMeterHistoryBySOCTIDSuccessAction } from "./getMeterHistoryBySOCTIDActions";
import { IMeterHistory, IGET_METER_HISTORY_BY_SOCT_ID_REQUEST } from "./getMeterHistoryBySOCTIDConstants";

type Actions =
    | IGetMeterHistoryBySOCTIDLoadAction
    | IGetMeterHistoryBySOCTIDSuccessAction
    | IGetMeterHistoryBySOCTIDLoadFailedAction
    | IAddMeterReadingSuccessAction
    | IUpdateMeterReadingSuccessAction
    | IFlushDataSuccessAction;

export const GetMeterHistoryBySOCTIDReducer = (state: Server<SurewayAPIResponse<ById<IMeterHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMeterHistory>>> => {
    switch (action.type) {
        case IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_METER_READING_REQUEST.SUCCESS:
            return notLoaded;
    
        case IUPDATE_METER_READING_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};