import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST, IGetUnitInspectionFormByID, IGetUnitInspectionFormByIDRequest } from "./getUnitInspectionFormByIDConstants";

export interface IGetUnitInspectionFormByIDLoadAction {
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.REQUEST;
    data: IGetUnitInspectionFormByIDRequest
}
export const getUnitInspectionFormByIDLoadAction = (data: IGetUnitInspectionFormByIDRequest): IGetUnitInspectionFormByIDLoadAction => ({
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetUnitInspectionFormByIDSuccessAction {
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetUnitInspectionFormByID>;
}
export const getUnitInspectionFormByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetUnitInspectionFormByID>): IGetUnitInspectionFormByIDSuccessAction => ({
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetUnitInspectionFormByIDLoadFailedAction {
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getUnitInspectionFormByIDLoadFailedAction = (message: string): IGetUnitInspectionFormByIDLoadFailedAction => ({
    type: IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.FAILED,
    message
});
