import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FILTER_REPORT_REQUEST, IFilterReport } from "./getFilterReportConstants";
import { IGetFilterReportLoadAction, IGetFilterReportLoadFailedAction, IGetFilterReportSuccessAction } from "./getFilterReportActions";
import { IAddFilterSuccessAction } from "../addFilter/addFilterActions";
import { IUpdateFilterSuccessAction } from "../updateFilter/updateFilterActions";
import { IUPDATE_FILTER_REQUEST } from "../updateFilter/updateFilterConstants";
import { IADD_FILTER_REQUEST } from "../addFilter/addFilterConstants";
import { IADD_FILTER_PART_REQUEST } from "../addFilterPart/addFilterPartConstants";
import { IUPDATE_FILTER_PART_REQUEST } from "../updateFilterPart/updateFilterPartConstants";
import { IUpdateFilterPartSuccessAction } from "../updateFilterPart/updateFilterPartActions";
import { IAddFilterPartSuccessAction } from "../addFilterPart/addFilterPartActions";
import { IDELETE_FILTER_PART_REQUEST } from "../deleteFilterPart/deleteFilterPartConstants";
import { IDeleteFilterPartSuccessAction } from "../deleteFilterPart/deleteFilterPartActions";

type Actions =
    | IGetFilterReportLoadAction
    | IGetFilterReportSuccessAction
    | IUpdateFilterSuccessAction
    | IAddFilterSuccessAction
    | IDeleteFilterPartSuccessAction
    | IUpdateFilterPartSuccessAction
    | IAddFilterPartSuccessAction
    | IGetFilterReportLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFilterReportReducer = (state: Server<SurewayAPIResponse<ById<IFilterReport>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IFilterReport>>> => {
    switch (action.type) {
        case IGET_FILTER_REPORT_REQUEST.REQUEST:
            return loading;

        case IGET_FILTER_REPORT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FILTER_REPORT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_FILTER_REQUEST.SUCCESS:
            return notLoaded;

        case IDELETE_FILTER_PART_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FILTER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_FILTER_PART_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FILTER_PART_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};