import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_JOBS_MHR_REQUEST, IJobMhr } from "./getJobsMhrConstants";
import { IGetJobsMHRLoadAction, IGetJobsMHRLoadFailedAction, IGetJobsMHRSuccessAction, getJobsMHRLoadFailedAction, getJobsMHRLoadSuccessAction } from "./getJobsMhrActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const getJobsMHREpic: Epic = (
    action$: ActionsObservable<IGetJobsMHRLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetJobsMHRSuccessAction | IGetJobsMHRLoadFailedAction> =>
    action$.ofType(IGET_JOBS_MHR_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IJobMhr>>>(
                    END_POINTS.ACCOUNTING.JOBS.GET_JOBS_MHR,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IJobMhr>>): IGetJobsMHRSuccessAction | IGetJobsMHRLoadFailedAction => {
                            if(response.message === "Success"){
                                return getJobsMHRLoadSuccessAction(response);
                            }
                            return getJobsMHRLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getJobsMHRLoadFailedAction(response.message)))
                    )
            )
        );