import React from 'react';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, Radio, RadioGroup, FormControl, FormControlLabel} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import {getTrailerRates} from './api/equipmenttracker/equipmentTrackerAPI';
import SOCTHome from './equipmenttrackerhome';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditTrailerRatesForm from './forms/trailerrates/trailerRatesEdit';
import AddNewTrailerRate from './forms/trailerrates/trailerRatesAdd';
import { LADevExtremeGrid } from '../../shared/devExtreme';

interface IState {
    trailerRates:any;
  open:boolean;
  showEditForm:boolean;
  view:string;
  showAddNewForm:boolean;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];


var globalEditID:Number = 0;
var renderPageType:string = '';
export default class TrailerRatePage extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={trailerRates:[], open:false, showEditForm:false, view:'all', showAddNewForm:false};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.filterView = this.filterView.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
  }
  addNewEquipmentMove(){
    this.setState({showAddNewForm:true});
  }
  filterView(eventKey:any){
    switch(eventKey.target.value) {
      case 'greater':
          this.setState({view:'greater'});
          var filtered_list = copy_list.filter((elem:any) => { return (elem.rate >100)});
          this.setState({trailerRates:filtered_list});
          break;
      case 'less':
        this.setState({view:'less'});
        var filtered_list = copy_list.filter((elem:any) => { return (elem.rate <=100 )});
        this.setState({trailerRates:filtered_list});
        break;
      case 'all':
        this.setState({view:'all'});
        var filtered_list = copy_list.filter((elem:any) => { return (elem)});
        this.setState({trailerRates:filtered_list});
        break;
    }
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  closeEditForm(){
      this.setState({showEditForm:false, showAddNewForm:false});
      this.props.history.push('/field/equipmenttracker/trailerrates');
      this.refreshGrid();
  }
  editItem = (e:any):void => {
    
    renderPageType='';
    editData = e.row.data;
    globalEditID = e.row.data.id;
    this.props.history.push('/field/equipmenttracker/trailerrates/edit/id='+e.row.data.id);
    this.setState({showEditForm:true});
  }
  async refreshGrid(){

  if(localStorage.getItem("ET-Role")==="R"){
    this.props.history.push("/field/equipmenttracker/trucking");
  }
    var trailer_rates = await getTrailerRates();
    copy_list = trailer_rates;
    console.log(trailer_rates);
    this.setState({trailerRates:trailer_rates});
  }
  async componentDidMount(){
    this.refreshGrid();
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={this.addNewEquipmentMove}  style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
            <Typography className='dataGrid-title'>Trailer Rates</Typography>
            <Grid item xs={3}>
              <FormControl>
                  <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={this.filterView}
                      defaultValue={"all"}
                  >
                      <FormControlLabel value="greater" control={<Radio size="small"/>} label="Greater than 100" />
                      <FormControlLabel value="less" control={<Radio size="small"/>} label="Less than 100" />
                      <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                      </RadioGroup>
              </FormControl>
            </Grid>
            <LADevExtremeGrid
                                               onClick={(e:any)=>{}}
                                                onEdit={this.editItem}
                                                data={this.state.trailerRates}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={100}
                                                removeStyleBtn={true}
                                                height={1200}
                                                paging={100}
                                                customRowColor={true}
                                                exportFileName="Trailer_Rates"                                  
                                                columns={[    
                                                  
                                                  { name: "description", caption: "Description", type: "string"},       
                                                  { name: "rate", caption: "Rate", type: "number"},             
                                                  { name: "arA_Rate", caption: "ARA Rate", type: "number"},                                             
                                                ]}
                                                />
            {this.state.showEditForm ? <EditTrailerRatesForm locationOptions={[]} closeEquipmentMoveForm={this.closeEditForm} clickedItem={[]} clickedLocation={null} refreshGrid={this.refreshGrid}></EditTrailerRatesForm>:null}
            {this.state.showAddNewForm ? <AddNewTrailerRate refreshGrid={this.refreshGrid.bind(this)}  closeEquipmentMoveForm={this.closeEditForm}></AddNewTrailerRate>:null}
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};