// import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST, IPayrollMechanicLogsSummary, IGetPayrollMechanicLogsSummaryRequest } from "./getPayrollMechanicLogsSummaryConstants";

export interface IGetPayrollMechanicLogsSummaryLoadAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.REQUEST;
    data: IGetPayrollMechanicLogsSummaryRequest
}
export const getPayrollMechanicLogsSummaryLoadAction = (data: IGetPayrollMechanicLogsSummaryRequest): IGetPayrollMechanicLogsSummaryLoadAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.REQUEST,
    data
});
export interface IGetPayrollMechanicLogsSummarySuccessAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IPayrollMechanicLogsSummary[]>;
}
export const getPayrollMechanicLogsSummaryLoadSuccessAction = (list: SurewayAPIResponse<IPayrollMechanicLogsSummary[]>): IGetPayrollMechanicLogsSummarySuccessAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.SUCCESS,
    list
});
export interface IGetPayrollMechanicLogsSummaryLoadFailedAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.FAILED;
    message: string;
}
export const getPayrollMechanicLogsSummaryLoadFailedAction = (message: string): IGetPayrollMechanicLogsSummaryLoadFailedAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST.FAILED,
    message
});
