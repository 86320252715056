import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_DETAILS_REQUEST, IUnitDetails, IUnitDetailsRequest } from "./getUnitDetailsConstants";

export interface IGetUnitDetailsLoadAction {
    type: IGET_UNIT_DETAILS_REQUEST.REQUEST;
    data: IUnitDetailsRequest
}
export const getUnitDetailsLoadAction = (data: IUnitDetailsRequest): IGetUnitDetailsLoadAction => ({
    type: IGET_UNIT_DETAILS_REQUEST.REQUEST,
    data
});
export interface IGetUnitDetailsSuccessAction {
    type: IGET_UNIT_DETAILS_REQUEST.SUCCESS;
    data: SurewayAPIResponse<IUnitDetails>;
}
export const getUnitDetailsLoadSuccessAction = (data: SurewayAPIResponse<IUnitDetails>): IGetUnitDetailsSuccessAction => ({
    type: IGET_UNIT_DETAILS_REQUEST.SUCCESS,
    data
});
export interface IGetUnitDetailsLoadFailedAction {
    type: IGET_UNIT_DETAILS_REQUEST.FAILED;
    message: string;
}
export const getUnitDetailsLoadFailedAction = (message: string): IGetUnitDetailsLoadFailedAction => ({
    type: IGET_UNIT_DETAILS_REQUEST.FAILED,
    message
});
