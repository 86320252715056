import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_OPERATOR_LOG_REQUEST, IUpdateOperatorLogRequest } from "./updateOperatorLogConstants";


export interface IUpdateOperatorLogLoadAction {
    type: IUPDATE_OPERATOR_LOG_REQUEST.REQUEST;
    data: IUpdateOperatorLogRequest
}
export const updateOperatorLogLoadAction = (data: IUpdateOperatorLogRequest): IUpdateOperatorLogLoadAction => ({
    type: IUPDATE_OPERATOR_LOG_REQUEST.REQUEST,
    data
});
export interface IUpdateOperatorLogSuccessAction {
    type: IUPDATE_OPERATOR_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateOperatorLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateOperatorLogSuccessAction => ({
    type: IUPDATE_OPERATOR_LOG_REQUEST.SUCCESS,
    message
});
export interface IUpdateOperatorLogLoadFailedAction {
    type: IUPDATE_OPERATOR_LOG_REQUEST.FAILED;
    message: string;
}
export const updateOperatorLogLoadFailedAction = (message: string): IUpdateOperatorLogLoadFailedAction => ({
    type: IUPDATE_OPERATOR_LOG_REQUEST.FAILED,
    message
});
