import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, SurveyMenu } from "../../shared/navbars";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getMachineControlBoxes } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesAccessor";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { IMachineControlBoxes, IMachineControlBoxesRequest } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesConstants";
import { getMachineControlBoxesLoadAction } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesActions";

interface IMachineControlBoxesComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMachineControlBoxStatus: Server<SurewayAPIResponse<ById<IMachineControlBoxes>>>;
};

interface IMachineControlBoxesComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => unknown;
};


interface IMachineControlBoxesComponentOwnProps {

};

interface IMachineControlBoxesComponentState {
    // view: string;
};

const MachineControlBoxesComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IMachineControlBoxesComponentProps = RouteComponentProps
    & IMachineControlBoxesComponentStoreProps
    & IMachineControlBoxesComponentDispatchProps
    & IMachineControlBoxesComponentOwnProps;

class MachineControlBoxesComponent extends PureComponent<IMachineControlBoxesComponentProps, IMachineControlBoxesComponentState> {
    
    public constructor(props: IMachineControlBoxesComponentProps) {
        super(props);
        this.state = {
            // view: "Active"
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IMachineControlBoxesComponentProps): void {

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getMachineControlBoxStatus, getToken  } = this.props;
        const getRole = pageAccessCheck(getToken, "surveyAccess");
        const data = hasPayload(getMachineControlBoxStatus) ? Object.values(getMachineControlBoxStatus.payload.response) : [];

        return (
            <PageSpacing title="Machine Control" description="Survey - Machine Control" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={SurveyMenu} role={getRole}/>
                {(getRole !== NotApplicable) && <>
                    <MachineControlBoxesComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>MACHINE CONTROL BOXES</h2><br />
                                <hr />

                                {getMachineControlBoxStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Machine Control Boxes..." />}
                                {getMachineControlBoxStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Machine Control Boxes..." />}

                                {getMachineControlBoxStatus.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="MachineControlBoxes"
                                                customRowColor={true}
                                                exportFileName="MachineControlBoxes"
                                                storageKey="machine-control-boxes"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    // { name: "unit_No", caption: "Unit #", type: "string" },
                                                    { name: "serial_No", caption: "Serial #", type: "string"},
                                                    { name: "make", caption: "Make", type: "string" },
                                                    { name: "model", caption: "Model", type: "string" },
                                                    { name: "version", caption: "Version", type: "number" },
                                                    { name: "notes", caption: "Notes", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true, show: false },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                    </MachineControlBoxesComponentStyles>
                </>}
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL_BOX
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL_BOX
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "surveyAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getMachineControlBoxStatus)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMachineControlBoxesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IMachineControlBoxesComponentStoreProps => ({
    getToken: getToken(state),
    getMachineControlBoxStatus: getMachineControlBoxes(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMachineControlBoxesComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => dispatch(getMachineControlBoxesLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineControlBoxesComponent);