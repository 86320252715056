import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById,  SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import {  LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString,  getTokenFromUrl, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon,  } from "../../../shared/icons";
import LATextArea from "../../../shared/textArea";
import splitDate from "../../../shared/splitDate";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { IFieldLookup, IFieldLookupRequest, IModel } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { IGetUnitInspectionFormByID, IGetUnitInspectionFormByIDRequest } from "../../../../redux/field/getUnitInspectionFormByID/getUnitInspectionFormByIDConstants";
import { getUnitInspectionFormByID } from "../../../../redux/field/getUnitInspectionFormByID/getUnitInspectionFormByIDAccessor";
import { getUnitInspectionFormByIDLoadAction } from "../../../../redux/field/getUnitInspectionFormByID/getUnitInspectionFormByIDActions";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import { LACheckBox } from "../../../shared/checkBox";
import { ISaveInspectionSheet, ISaveInspectionSheetItems, ISaveInspectionSheetRequest } from "../../../../redux/field/saveInspectionSheet/saveInspectionSheetConstants";
import { saveInspectionSheet } from "../../../../redux/field/saveInspectionSheet/saveInspectionSheetAccessor";
import { saveInspectionSheetLoadAction } from "../../../../redux/field/saveInspectionSheet/saveInspectionSheetActions";
import { LACenteredLoading } from "../../../shared/loading";
import { getUnitInspectionSheetByIDLoadAction } from "../../../../redux/field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDActions";
import { IGetUnitInspectionSheetByID, IGetUnitInspectionSheetByIDRequest } from "../../../../redux/field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDConstants";
import { getUnitInspectionSheetByID } from "../../../../redux/field/getUnitInspectionSheetByID/getUnitInspectionSheetByIDAccessor";
import { InputAdornment } from "@mui/material";

const RequiredFields: string[] = ["hours"];

interface IAddUpdateInspectionFormComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    getUnitInspectionFormByID: Server<SurewayAPIResponse<IGetUnitInspectionFormByID>>;
    getUnitInspectionSheetByID: Server<SurewayAPIResponse<IGetUnitInspectionSheetByID>>;
    saveInspectionSheet: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateInspectionFormComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getUnitInspectionFormByIDRequest: (data: IGetUnitInspectionFormByIDRequest) => unknown;
    getUnitInspectionSheetByIDRequest: (data: IGetUnitInspectionSheetByIDRequest) => unknown;
    saveInspectionSheetRequest: (data: ISaveInspectionSheetRequest) => unknown;
};

interface IAddUpdateInspectionFormOwnProps {
    id?: string;
};

interface IAddUpdateInspectionFormComponentState {
    data: any;
    collapseAll: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateInspectionFormStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateInspectionFormComponentProps =
    RouteComponentProps
    & IAddUpdateInspectionFormOwnProps
    & IAddUpdateInspectionFormComponentStoreProps
    & IAddUpdateInspectionFormComponentDispatchProps;

class AddUpdateInspectionForm extends PureComponent<IAddUpdateInspectionFormComponentProps, IAddUpdateInspectionFormComponentState> {

    public constructor(props: IAddUpdateInspectionFormComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                // date: new Date().toISOString(),
                unit_ID: 0,
                defect_ID: 0,
                // technician: userName,
                hours: null,
                comments: "",
                items: [],
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            collapseAll: false,
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getUnitInspectionFormByID();
        this.getUnitInspectionSheetByID();
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateInspectionFormComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.saveInspectionSheet !== prevProps.saveInspectionSheet) {

                if (this.props.saveInspectionSheet.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveInspectionSheet.message });

                if (hasPayload(this.props.saveInspectionSheet) && this.props.saveInspectionSheet.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, collapseAll } = this.state;
        const query: any = queryString.parse(this.props.location.search);
        const title = data.id > 0 ? "Update Inspection Form" : "Add Inspection Form";
        const { token, getLookup, getUnitInspectionFormByID, getUnitInspectionSheetByID, saveInspectionSheet } = this.props;
        const managerAccess = hasPayload(token) ? (token.payload.response.repairRoles.field_Manager || token.payload.response.repairRoles.shop_Manager) : false;
        // console.log(managerAccess)

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): Promise<void> => value !== null ? this.handleUnit(value) : this.handleUnit();
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;

        if(getLookup.kind === STATUS_ENUM.LOADING || getUnitInspectionFormByID.kind === STATUS_ENUM.LOADING || getUnitInspectionSheetByID.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }
        
        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateInspectionFormStyles>
                    {/* {(getRole !== NotApplicable) && */}
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}INSPECTION FORM</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder="Unit Number"
                                    disabled={(query.unit_ID !== undefined || data.id > 0 ) ? true : false}
                                    // disabled={undefined}
                                    returnExp="id"
                                    displayExp="unit_Number"
                                    name="id"
                                    errorName="unit_ID"
                                    errors={errors}
                                    currentVal={data.unit_ID}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "make", caption: "Make", type: "string" },
                                        { name: "model", caption: "Model", type: "string" },
                                        { name: "equipment_Type", caption: "Equiment Type", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Make"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="make"
                                    disabled={true}
                                    value={unit?.make ?? ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Model"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="make"
                                    disabled={true}
                                    value={unit?.model ?? ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Euqipment Type"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="equipment_Type"
                                    disabled={true}
                                    value={unit?.equipment_Type ?? ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                {data.id === 0 ? <LATextField
                                    id="Date"
                                    value={splitDate(new Date().toISOString())}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="date"
                                    label="Date"
                                    errorText={errors["date"] ? errors["date"].message : undefined}
                                    onChange={undefinedFunction}
                                    type="date"
                                /> : 
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Date"
                                    variant="outlined"
                                    disabled={true}
                                    value={splitDate(data.created)}
                                    // value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    type="date"
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />}
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Technician"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="technician"
                                    disabled={true}
                                    value={data.created_By ?? ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["technician"] ? errors["technician"].message : undefined}
                                />
                            </LAGridItem>

                            {/* <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Serial Number"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="serial_No"
                                    disabled={undefined}
                                    value={data.serial_No ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["serial_No"] ? errors["serial_No"].message : undefined}
                                />
                            </LAGridItem> */}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    label="Meter Reading"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="hours"
                                    disabled={undefined}
                                    value={data.hours ?? ""}
                                    onChange={this.handleChange}
                                    type="number"
                                    errorText={errors["hours"] ? errors["hours"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label="Comments"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="comments"
                                    value={data.comments ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                />
                            </LAGridItem>

                            {(data.unit_ID > 0 || data.defect_ID > 0) && data.items && <LAGridItem xs={12} >
                                <LAButton
                                    className="collapse-btn"
                                    label={collapseAll ? "Expand All" : "Collapse All" }
                                    onClick={this.handleCollapseAll}
                                />
                                {data.items && data.items.length > 0 && data.items.map((x:any, idx: number) => {
                                    const answerNumbers = x.items.filter((record:any) => record.good === "Yes" || record.needs_Repair === "Yes" || record.na === "Yes").length;
                                    return <LAExpansionPanel className="mt-3" color={GREEN_COLOR} textColor={WHITE_COLOR} label={x.type} questionNumbers={x.items.length} answerNumbers={answerNumbers} expanded={x.expand ? true : false} onClick={() => this.handleExpand(idx)} key={idx}> 
                                        <LAGridItem xs={12} className="mt-3" key={idx}>
                                            {x.items && x.items.length > 0 && x.items.map((i:any, iIdx: number) => {
                                                const onGoodChange = (checked: boolean) => this.handleItemChange("good", checked ? "Yes" : "No", iIdx, idx);
                                                const onNeedRepairChange = (checked: boolean) => this.handleItemChange("needs_Repair", checked ? "Yes" : "No", iIdx, idx);
                                                const onNAChange = (checked: boolean) => this.handleItemChange("na", checked ? "Yes" : "No", iIdx, idx);
                                                const onHoursChange = (name: string, value: string) => this.handleItemChange("estimated_Hours", value, iIdx, idx);
                                                const onLabourCostChange = (name: string, value: string) => this.handleItemChange("estimated_Labour_Cost", value, iIdx, idx);
                                                const onPartsCostChange = (name: string, value: string) => this.handleItemChange("estimated_Parts_Cost", value, iIdx, idx);
                                                const onApprovedChange = (checked: boolean) => this.handleItemChange("approved", checked ? "Yes" : "No", iIdx, idx);
                                                const onCommentsChange = (name: string, value: string) => this.handleItemChange("comments", value, iIdx, idx);

                                                return (
                                                    <LAGridItem xs={12} key={iIdx}>
                                                        <LAPaperWithLessPadding className={iIdx % 2 ? "odd-row" : "even-row"} key={iIdx}>
                                                            <LAGrid>
                                                                <LAGridItem xs={12} >
                                                                    <strong>{iIdx + 1}. {i.item}</strong>
                                                                </LAGridItem>

                                                                {i.item !== "Comments" && <>
                                                                    <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Good</strong> <br />
                                                                        <LACheckBox
                                                                            label="Yes"
                                                                            disabled={undefined}
                                                                            onChange={onGoodChange}
                                                                            value={i.good === "Yes" ? true : false}
                                                                        />
                                                                    </LAGridItem>
                                                                    <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Need Repair</strong> <br />
                                                                        <LACheckBox
                                                                            label="Yes"
                                                                            disabled={undefined}
                                                                            onChange={onNeedRepairChange}
                                                                            value={i.needs_Repair === "Yes" ? true : false}
                                                                        />
                                                                    </LAGridItem>
                                                                    <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>N/A</strong> <br />
                                                                        <LACheckBox
                                                                            label="N/A"
                                                                            disabled={undefined}
                                                                            onChange={onNAChange}
                                                                            value={i.na === "Yes" ? true : false}
                                                                        />
                                                                    </LAGridItem>
                                                                    {i.needs_Repair === "Yes" && <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Estimated Hours</strong> <br />
                                                                        <LATextField
                                                                            label=""
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            name="estimated_Hours"
                                                                            disabled={undefined}
                                                                            indexPosition={iIdx}
                                                                            value={i.estimated_Hours ?? ""}
                                                                            onChange={onHoursChange}
                                                                            type="number"
                                                                            errorText={errors["estimated_Hours"] ? errors["estimated_Hours"].message : undefined}
                                                                        />
                                                                    </LAGridItem>}
                                                                    {i.needs_Repair === "Yes" && <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Estimated Labour Cost</strong> <br />
                                                                        <LATextField
                                                                            label=""
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            name="estimated_Labour_Cost"
                                                                            disabled={undefined}
                                                                            indexPosition={iIdx}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">$</InputAdornment>
                                                                                ),
                                                                                inputProps: {
                                                                                    min: 0
                                                                                }
                                                                            }}
                                                                            value={i.estimated_Labour_Cost ?? ""}
                                                                            onChange={onLabourCostChange}
                                                                            type="number"
                                                                            errorText={errors["estimated_Labour_Cost"] ? errors["estimated_Labour_Cost"].message : undefined}
                                                                        />
                                                                    </LAGridItem>}
                                                                    {i.needs_Repair === "Yes" && <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Estimated Parts Cost</strong> <br />
                                                                        <LATextField
                                                                            label=""
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            name="estimated_Part_Cost"
                                                                            disabled={undefined}
                                                                            indexPosition={iIdx}
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">$</InputAdornment>
                                                                                ),
                                                                                inputProps: {
                                                                                    min: 0
                                                                                }
                                                                            }}
                                                                            value={i.estimated_Parts_Cost ?? ""}
                                                                            onChange={onPartsCostChange}
                                                                            type="number"
                                                                            errorText={errors["estimated_Parts_Cost"] ? errors["estimated_Parts_Cost"].message : undefined}
                                                                        />
                                                                    </LAGridItem>}
                                                                    <LAGridItem xs={12} sm={6} md={2}>
                                                                        <strong>Approved</strong> <br />
                                                                        <LACheckBox
                                                                            label="Approved"
                                                                            disabled={managerAccess ? undefined : true}
                                                                            onChange={onApprovedChange}
                                                                            value={i.approved === "Yes" ? true : false}
                                                                        />
                                                                    </LAGridItem>
                                                                </>}
                                                                {i.item === "Comments" && <LAGridItem xs={12} sm={12} md={6}>
                                                                    {/* <strong>Comments</strong> <br /> */}
                                                                    <LATextArea
                                                                        minRows={3}
                                                                        rowsMax={6}
                                                                        label="Comments"
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        disabled={undefined}
                                                                        name="comments"
                                                                        value={i.comments ?? ""}
                                                                        onChange={onCommentsChange}
                                                                        errorText={errors["comments"] ? errors["comments"].message : undefined}
                                                                    />
                                                                </LAGridItem>}
                                                            </LAGrid>
                                                        </LAPaperWithLessPadding>
                                                        <br />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGridItem>
                                    </LAExpansionPanel>
                                })}
                            </LAGridItem>}
                            
                            {data.id && data.id > 0 ? <LAGridItem xs={12}>
                                <LAGrid>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            variant="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>
                                    

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} ><></></LAGridItem>

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    {/* } */}

                    <RequestStatus requestStatus={saveInspectionSheet.kind} successMessage="Inspection Form has been added successfully" />
                    <RequestStatus requestStatus={saveInspectionSheet.kind} successMessage="Inspection Form has been updated successfully" />

                </AddUpdateInspectionFormStyles>
            </PageSpacing>
        );
    }

    private handleExpand = (typeIndex: number) => {
        const iS = { ...this.state.data };
        let type = {
            ...iS.items[typeIndex]
        };
        type.expand =!type.expand;

        iS.items[typeIndex] = type;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                items: iS.items, 
                // name: upn
            }
        });
    };

    private handleCollapseAll = () : void => { 
        const iS = { ...this.state.data };
        let items = iS.items;

        items.forEach((x: any) => {
            x.expand = this.state.collapseAll ? true : false;
        });
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                items: items
            },
            collapseAll: this.state.collapseAll ? false : true
        });
    }

    private handleUnit = async(value?: string): Promise<void> => {
        if(Number(value) > 0) {
            console.log(value)
            let errors = { ...this.state.errors };
            if (hasPayload(this.props.token)) {

                await this.props.getUnitInspectionFormByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        page: "Unit",
                        id: Number(value)
                    }
                });

                // if (hasPayload(this.props.getUnitInspectionFormByID)) {

                //     const items = this.props.getUnitInspectionFormByID.payload.response.inspection.map(inspection => ({
                //         type: inspection.type,
                //         items: inspection.items.map(item => ({
                //             id: 0,
                //             item: item.item,
                //             item_ID: item.id,
                //             good: "No",
                //             needs_Repair: "No",
                //             NA: "No",
                //             estimated_Hours: null,
                //             estimated_Labour_Cost: null,
                //             estimated_Parts_Cost: null,
                //             approved: "No",
                //             comments: ""
                //         }))
                //     }));
        
                // }
            }
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    unit_ID: Number(value),
                    // items: items
                },
                errors
            });
        }
    };

    private handleItemChange = (name: string, value: string, iIndex: number, index: number): void => {
        if (index !== undefined) {
            const iS = { ...this.state.data };
            let item = {
                ...iS.items[index].items[iIndex]
            };

            if(name === "good" && value === "Yes") {
                item.needs_Repair = "No";
                item.na = "No";
                item.estimated_Hours = null;
                item.estimated_Labour_Cost = null;
                item.estimated_Parts_Cost = null;
            }
            if(name === "needs_Repair" && value === "Yes") {
                item.good = "No";
                item.na = "No";
                item.estimated_Hours = null;
                item.estimated_Labour_Cost = null;
                item.estimated_Parts_Cost = null;
            }
            if(name === "na" && value === "Yes") {
                item.good = "No";
                item.needs_Repair = "No";
                item.estimated_Hours = null;
                item.estimated_Labour_Cost = null;
                item.estimated_Parts_Cost = null;
            }

            if((name === "estimated_Hours" || name === "estimated_Labour_Cost" || name === "estimated_Parts_Cost")) {
                if(Number(value) >= 0) {
                    iS.items[index].items[iIndex] = { ...item, [name]: value };
                }
                else {
                    iS.items[index].items[iIndex] = { ...item, [name]: null };
                }
            }
            else {
                iS.items[index].items[iIndex] = { ...item, [name]: value };
            }

            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS
        });
    };

    private handleSave = async (): Promise<void> => {
        const { data } = this.state;

        if (hasPayload(this.props.token)) {
            const items: ISaveInspectionSheetItems[] = [];

            const convertBacktoItems = data.items.reduce((result :any, group :any) => {
                // Add all questions from each section group to the result array
                return result.concat(group.items);
            }, []);

            // console.log(convertBacktoItems)

            await convertBacktoItems.forEach((x:any) => {
                items.push({
                    ID: x.id,
                    Item_ID: x.item_ID,
                    Good: x.good,
                    Needs_Repair: x.needs_Repair,
                    NA: x.na,
                    Estimated_Hours: x.estimated_Hours ? Number(x.estimated_Hours) : null,
                    Estimated_Labour_Cost: x.estimated_Labour_Cost ? Number(x.estimated_Labour_Cost) : null,
                    Estimated_Parts_Cost: x.estimated_Parts_Cost ? Number(x.estimated_Parts_Cost) : null,
                    Approved: x.approved,
                    Comments: x.comments,
                });
            });
            // const images: string = popupImgs.map(picture => picture.name).join(";");

            let request: ISaveInspectionSheet = {
                ID: data.id,
                Unit_ID: data.unit_ID,
                Defect_ID: data.defect_ID,
                Hours: data.hours ? Number(data.hours) : null,
                Comments: data.comments,
                Items: items,
                Created_By: this.props.token.payload.response.upn,
                Modified_By: this.props.token.payload.response.upn,
            };

            // console.log(request)
            if (data.id === 0) {
                this.props.saveInspectionSheetRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.saveInspectionSheetRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any =  queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                // if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (hasPayload(this.props.getUnitInspectionFormByID)) {
                        if (query.id !== "0") {
                            if (this.state.data.id !== Number(query.id)) {
                                if (hasPayload(this.props.getUnitInspectionSheetByID)) {
                                                                
                                    const data = this.props.getUnitInspectionSheetByID.payload.response;

                                    if(data) {
                                        const items = this.props.getUnitInspectionFormByID.payload.response.inspection.map(inspection => ({
                                            type: inspection.type,
                                            items: inspection.items.map(item => {
                                                const existingItem = data.items.find(x => x.item_ID === item.id);
                                                return {
                                                    id: existingItem ? existingItem.id : 0,
                                                    item: item.item,
                                                    item_ID: item.id,
                                                    good: existingItem ? existingItem.good : "No",
                                                    needs_Repair: existingItem ? existingItem.needs_Repair : "No",
                                                    na: existingItem ? existingItem.na : "No",
                                                    estimated_Hours: existingItem ? existingItem.estimated_Hours : null,
                                                    estimated_Labour_Cost: existingItem ? existingItem.estimated_Labour_Cost : null,
                                                    estimated_Parts_Cost: existingItem ? existingItem.estimated_Parts_Cost : null,
                                                    approved: existingItem ? existingItem.approved : "No",
                                                    comments: existingItem ? existingItem.comments : ""
                                                };
                                            })
                                        }));
            
                                        this.setState({
                                            ...this.state,
                                            data: {
                                                ...data,
                                                items: items,
                                            },
                                            collapseAll: true,
                                            errors: {} 
                                        });
                                    }
                                }
                                else {
                                    if(isNotLoaded(this.props.getUnitInspectionFormByID)) {
                                        this.getUnitInspectionSheetByID();
                                    };
                                }
                            }
                        }
                        else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            const items = this.props.getUnitInspectionFormByID.payload.response?.inspection.map(inspection => ({
                                type: inspection.type,
                                expand: true,
                                items: inspection.items.map(item => ({
                                    id: 0,
                                    item: item.item,
                                    item_ID: item.id,
                                    good: "No",
                                    needs_Repair: "No",
                                    na: "No",
                                    estimated_Hours: null,
                                    estimated_Labour_Cost: null,
                                    estimated_Parts_Cost: null,
                                    approved: "No",
                                    comments: ""
                                }))
                            }));

                            this.setState({
                                ...this.state,
                                data: {
                                    ...this.state.data,
                                    defect_ID: query.defect_ID ? Number(query.defect_ID) : 0,
                                    unit_ID: query.unit_ID ? Number(query.unit_ID) : this.state.data.unit_ID,
                                    items: items,
                                },
                                collapseAll: false,
                                errors 
                            });
                        }
                    }
                    // else {
                    //     this.getUnitInspectionFormByID();
                    // }
                    // if (hasPayload(this.props.getTechServices)) {
                    //     if (query.id !== "0") {

                    //         if (this.state.data.id !== Number(query.id)) {
                    //             const data = this.props.getTechServices.payload.response[query.id];
                    //             // const pictures = data.images && data.images.split(";");
                    //             // const pictures = data.pictures; 
                                
                    //             // const popupImgs: IRepairLinePicture[] = [];
                    //             // pictures && pictures.forEach((x) => {
                    //             //     popupImgs.push({
                    //             //         name: x.name,
                    //             //         description: x.description,
                    //             //         base64String: ""
                    //             //     });
                    //             // });
                    //             // console.log(popupImgs)
                    //             this.setState({
                    //                 data
                    //             });
                    //         };
                    //     } else {
                    //         const errors: ById<IFieldErrorKeyValue> = {};

                    //         RequiredFields.forEach((x) => {
                    //             errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    //         });
                    //         if (errors["date"])
                    //             delete errors["date"]
                    //         if (errors["technician"])
                    //             delete errors["technician"]

                    //         this.setState({ errors });
                    //     }
                    // } 
                    // else {
                    //     this.props.getTechServicesRequest({
                    //         token: this.props.token.payload.response.token,
                    //         request: {
                    //             unit_id: 0
                    //         }
                    //     });
                    // }

                // } 
                // else {

                //     this.props.history.push({
                //         pathname: ROUTE.ACCESS_DENIED,
                //         search: getTokenFromUrl(false)
                //     });
                // };
            };
        };
    };

    private getUnitInspectionFormByID = (): void => {
        const query: any = queryString.parse(this.props.location.search);
        if (hasPayload(this.props.token))
            if(query !== undefined && query.defect_ID !== undefined && Number(query.defect_ID) > 0) {
                this.props.getUnitInspectionFormByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        page: "Defect",
                        id: Number(query.defect_ID)
                    }
                });
            }
            else if (query !== undefined && query.unit_ID !== undefined && Number(query.unit_ID) > 0) {
                this.props.getUnitInspectionFormByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        page: "Unit",
                        id: Number(query.unit_ID)
                    }
                });
            }
    };

    private getUnitInspectionSheetByID = async(): Promise<void> => {
            const query: any = queryString.parse(this.props.history.location.search);
            if (hasPayload(this.props.token)) {
                if(Number(query.id) > 0) {
                    await this.props.getUnitInspectionSheetByIDRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            id: Number(query.id)
                        }
                    });
                }
            }
        };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Inspection"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateInspectionFormComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getUnitInspectionFormByID: getUnitInspectionFormByID(state),
    getUnitInspectionSheetByID: getUnitInspectionSheetByID(state),
    saveInspectionSheet: saveInspectionSheet(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateInspectionFormComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getUnitInspectionFormByIDRequest: (data: IGetUnitInspectionFormByIDRequest) => dispatch(getUnitInspectionFormByIDLoadAction(data)),
    getUnitInspectionSheetByIDRequest: (data: IGetUnitInspectionSheetByIDRequest) => dispatch(getUnitInspectionSheetByIDLoadAction(data)),
    saveInspectionSheetRequest: (data: ISaveInspectionSheetRequest) => dispatch(saveInspectionSheetLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateInspectionForm);