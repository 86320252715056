import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST, IMechanicLogsByDefectID } from "./getMechanicLogsByDefectIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetMechanicLogsByDefectIDLoadAction, IGetMechanicLogsByDefectIDLoadFailedAction, IGetMechanicLogsByDefectIDSuccessAction } from "./getMechanicLogsByDefectIDActions";

type Actions =
    | IGetMechanicLogsByDefectIDLoadAction
    | IGetMechanicLogsByDefectIDSuccessAction
    | IGetMechanicLogsByDefectIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetMechanicLogsByDefectIDReducer = (state: Server<SurewayAPIResponse<IMechanicLogsByDefectID[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IMechanicLogsByDefectID[]>> => {
    switch (action.type) {
        case IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};