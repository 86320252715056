import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGetHazardControlPlanListByDepartmentID, IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST } from "./getHazardControlPlanListByDepartmentIDConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetHazardControlPlanListByDepartmentIDLoadAction, IGetHazardControlPlanListByDepartmentIDLoadFailedAction, IGetHazardControlPlanListByDepartmentIDSuccessAction, getHazardControlPlanListByDepartmentIDLoadFailedAction, getHazardControlPlanListByDepartmentIDLoadSuccessAction } from "./getHazardControlPlanListByDepartmentIDActions";


export const getHazardControlPlanListByDepartmentIDEpic: Epic = (
    action$: ActionsObservable<IGetHazardControlPlanListByDepartmentIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetHazardControlPlanListByDepartmentIDSuccessAction | IGetHazardControlPlanListByDepartmentIDLoadFailedAction> =>
    action$.ofType(IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>>(
                    END_POINTS.FIELD.FORMS.GET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>): IGetHazardControlPlanListByDepartmentIDSuccessAction => {
                            return getHazardControlPlanListByDepartmentIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getHazardControlPlanListByDepartmentIDLoadFailedAction(response.message)))
                    )
            )
        );