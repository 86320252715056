import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { generateRandomText, getTokenFromUrl, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton, LASecondaryButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetMechanicsRequest, IMechanic } from "../../../redux/field/mechanics/getMechanics/getMechanicsConstants";
import { IAddMechanicRequest, IAddUpdateMechanic } from "../../../redux/field/mechanics/addMechanic/addMechanicConstants";
import { IUpdateMechanicRequest } from "../../../redux/field/mechanics/updateMechanic/updateMechanicConstants";
import { ArrowLeftIcon } from "../../shared/icons";
import { getMechanics } from "../../../redux/field/mechanics/getMechanics/getMechanicsAccessor";
import { addMechanicStatus } from "../../../redux/field/mechanics/addMechanic/addMechanicAccessor";
import { updateMechanicStatus } from "../../../redux/field/mechanics/updateMechanic/updateMechanicAccessor";
import { addMechanicLoadAction } from "../../../redux/field/mechanics/addMechanic/addMechanicActions";
import { getMechanicsLoadAction } from "../../../redux/field/mechanics/getMechanics/getMechanicsActions";
import { updateMechanicLoadAction } from "../../../redux/field/mechanics/updateMechanic/updateMechanicActions";
import LAAutoComplete from "../../shared/autoComplete";
import { LACheckBox } from "../../shared/checkBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";


const RequiredFields: string[] = ["firstName", "lastName", "email", "password", "company", "phone"];
export const StatusList: IIdName[] = [{ id: 0, name: "Active" }, { id: 1, name: "InActive" }, { id: 2, name: "Verified" }];

interface IAddUpdateMechanicComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    addMechanicStatus: Server<SurewayAPIResponse<string>>;
    mechanics: Server<SurewayAPIResponse<ById<IMechanic>>>;
    updateMechanicStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateMechanicComponentDispatchProps {
    getMechanicsRequest: (data: IGetMechanicsRequest) => unknown
    addMechanicRequest: (data: IAddMechanicRequest) => unknown;
    updateMechanicRequest: (data: IUpdateMechanicRequest) => unknown;
};

interface IAddUpdateMechanicOwnProps {
    id?: string;
};

interface IAddUpdateMechanicComponentState {
    data: IMechanic;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateMechanicStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .hireForm_Link {
        color: ${DARK_RED_COLOR}
    }

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateMechanicComponentProps =
    RouteComponentProps
    & IAddUpdateMechanicOwnProps
    & IAddUpdateMechanicComponentStoreProps
    & IAddUpdateMechanicComponentDispatchProps;

class AddUpdateMechanic extends PureComponent<IAddUpdateMechanicComponentProps, IAddUpdateMechanicComponentState> {

    public constructor(props: IAddUpdateMechanicComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                loginInfo: false,
                status: StatusList[0].name,
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
                company: "",
                employee_Code: "",
                phone: "",
                active:YesOrNo[0].name,
                comments: "",
                type: 'Field',
                contractor: "No"
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMechanicComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addMechanicStatus !== prevProps.addMechanicStatus) {

                if (this.props.addMechanicStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addMechanicStatus.message });

                if (hasPayload(this.props.addMechanicStatus) && this.props.addMechanicStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateMechanicStatus !== prevProps.updateMechanicStatus) {

                if (this.props.updateMechanicStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateMechanicStatus.message });

                if (hasPayload(this.props.updateMechanicStatus) && this.props.updateMechanicStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { token, addMechanicStatus, updateMechanicStatus } = this.props;
        const { data, errors, serverError } = this.state;

        const getRole = pageAccessCheck(token, "repairLine");
        const payRollAccess = hasPayload(this.props.token) && this.props.token.payload.response.payrollAccess;
        // const disabled = getRole === ReadOnly ? true : undefined;
        const disabled = true;
        const disableSave = (Object.values(errors).length > 0) ? true : disabled;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);
        const handleContactorCheckBox = (checked: boolean, name?: string | undefined): void => this.handleChange(name ?? "", checked ? "Yes" : "No");
        
        return (
            <AddUpdateMechanicStyles>
                {(getRole === ReadAndWrite || payRollAccess) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} className="text-center">
                            <h3 className="hireForm_Link">Mechanics are managed through Hire form. <span className="link-btn" onClick={this.handleClickHereBtn}>Click here</span> to access the HR portal. </h3>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>MECHANIC</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="First Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled || data.id > 0}
                                name="firstName"
                                value={data.firstName ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["firstName"] ? errors["firstName"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Last Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled || data.id > 0}
                                name="lastName"
                                value={data.lastName ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["lastName"] ? errors["lastName"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Company"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="company"
                                value={data.company ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["company"] ? errors["company"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Employee Code"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="employee_Code"
                                value={data.employee_Code ?? ""}
                                onChange={this.handleChange}
                            />
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Comments"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="comments"
                                value={data.comments ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["comments"] ? errors["comments"].message : undefined}
                            />
                        </LAGridItem>


                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Email"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="email"
                                value={data.email ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["email"] ? errors["email"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Phone (Digits Only)"
                                fullWidth={true}
                                variant="outlined"
                                InputProps={{
                                    inputProps: {
                                        maxLength: 10
                                    }
                                }}
                                disabled={disabled}
                                name="phone"
                                value={data.phone ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["phone"] ? errors["phone"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                            <LATextField
                                label="Password"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="password"
                                value={data.password ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["password"] ? errors["password"].message : undefined}
                            />

                            <LASecondaryButton
                                className="mt-2"
                                disabled={disabled}
                                label="Generate New Password"
                                onClick={this.handleAutoGenerate}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.type} onChange={this.onTypeClick}>
                                <FormControlLabel value="Field" control={<Radio />} label="Field" disabled={disabled}/>
                                <FormControlLabel value="Shop" control={<Radio />} label="Shop" disabled={disabled}/>
                                <FormControlLabel value="Both" control={<Radio />} label="Both" disabled={disabled}/>
                            </RadioGroup>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                name="contractor"
                                value={data.contractor === "Yes" ? true : false}
                                onChange={handleContactorCheckBox}
                                label="Contractor"
                                disabled={disabled}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                value={data.loginInfo}
                                onChange={this.handleCheckBox}
                                label="Send login info"
                                disabled={disabled}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addMechanicStatus.kind} successMessage="Mechanic has been added successfully" />
                <RequestStatus requestStatus={updateMechanicStatus.kind} successMessage="Mechanic has been updated successfully" />

            </AddUpdateMechanicStyles>
        );
    }

    private handleClickHereBtn = (): void => {
        window.open(ROUTE.HIRE_FORM.INDEX, '_blank')
    }

    private onTypeClick = (e:any, value:string): void => {
        this.setState({ 
            ...this.state,
            data: {
                ...this.state.data,
                type: value
            }, });
        
    };

    private handleAutoGenerate = (): void => {

        let errors = { ...this.state.errors };
        let password: string = generateRandomText(6)
        errors = this.errorChecker("password", password, errors);        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                password: password
            },
            errors
        });
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANICS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {

        const {
            id,
            firstName,
            lastName,
            active,
            email,
            password,            
            company,
            employee_Code,
            phone,
            loginInfo,
            comments,
            type, 
            contractor
        } = this.state.data;

        if (hasPayload(this.props.token)) {

            const request: IAddUpdateMechanic = {
                ID: id,
                FirstName: firstName,
                LastName: lastName,
                LoginInfo: loginInfo,
                Active: active,
                Email: email,
                Password: password,
                Created_By: this.props.token.payload.response.upn,
                Company: company,
                Employee_Code: employee_Code,
                Comments: comments,
                Phone: phone,
                Type: type,
                Contractor: contractor,
                Modified_By: this.props.token.payload.response.upn,
            }

            if (id === 0) {
                this.props.addMechanicRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } else {
                this.props.updateMechanicRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleCheckBox = (checked: boolean): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                loginInfo: checked
            }
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "repairLine") === ReadAndWrite || this.props.token.payload.response.payrollAccess) {

                    if (hasPayload(this.props.mechanics)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.mechanics.payload.response[query.id];
                                this.setState({
                                    data
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getMechanicsRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        if (name === "email")
            rules.email = true;

        if (name === "phone")
            rules.phone = true;

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMechanicComponentStoreProps => ({
    token: getToken(state),
    mechanics: getMechanics(state),
    addMechanicStatus: addMechanicStatus(state),
    updateMechanicStatus: updateMechanicStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMechanicComponentDispatchProps => ({
    addMechanicRequest: (data: IAddMechanicRequest) => dispatch(addMechanicLoadAction(data)),
    getMechanicsRequest: (data: IGetMechanicsRequest) => dispatch(getMechanicsLoadAction(data)),
    updateMechanicRequest: (data: IUpdateMechanicRequest) => dispatch(updateMechanicLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMechanic);