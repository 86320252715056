import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_EQUIPMENT_MANUAL_REQUEST, IUpdateEquipmentManualRequest } from "./updateEquipmentManualConstants";


export interface IUpdateEquipmentManualLoadAction {
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.REQUEST;
    data: IUpdateEquipmentManualRequest
}
export const updateEquipmentManualLoadAction = (data: IUpdateEquipmentManualRequest): IUpdateEquipmentManualLoadAction => ({
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.REQUEST,
    data
});
export interface IUpdateEquipmentManualSuccessAction {
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateEquipmentManualLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateEquipmentManualSuccessAction => ({
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.SUCCESS,
    message
});
export interface IUpdateEquipmentManualLoadFailedAction {
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.FAILED;
    message: string;
}
export const updateEquipmentManualLoadFailedAction = (message: string): IUpdateEquipmentManualLoadFailedAction => ({
    type: IUPDATE_EQUIPMENT_MANUAL_REQUEST.FAILED,
    message
});
