import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_VERSION_HISTORY_REQUEST, IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "./getFormVersionHistoryConstants";

export interface IGetFormVersionHistoryLoadAction {
    type: IGET_FORM_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetFormVersionHistoryRequest
}
export const getFormVersionHistoryLoadAction = (data: IGetFormVersionHistoryRequest): IGetFormVersionHistoryLoadAction => ({
    type: IGET_FORM_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetFormVersionHistorySuccessAction {
    type: IGET_FORM_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetFormVersionHistory>>;
}
export const getFormVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetFormVersionHistory>>): IGetFormVersionHistorySuccessAction => ({
    type: IGET_FORM_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetFormVersionHistoryLoadFailedAction {
    type: IGET_FORM_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getFormVersionHistoryLoadFailedAction = (message: string): IGetFormVersionHistoryLoadFailedAction => ({
    type: IGET_FORM_VERSION_HISTORY_REQUEST.FAILED,
    message
});
