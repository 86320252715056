import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IADD_HAZARD_NEW_REQUEST } from "./addHazardNewConstants";
import { IAddHazardNewLoadAction, IAddHazardNewLoadFailedAction, IAddHazardNewSuccessAction } from "./addHazardNewActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IAddHazardNewLoadAction
    | IAddHazardNewSuccessAction
    | IAddHazardNewLoadFailedAction
    | IFlushDataSuccessAction;

export const AddHazardNewReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_HAZARD_NEW_REQUEST.REQUEST:
            return loading;

        case IADD_HAZARD_NEW_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_HAZARD_NEW_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};