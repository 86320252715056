import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINE_SUMMARY_REQUEST, IRepairLineSummary, IGetRepairLineSummaryRequest } from "./getRepairLineSummaryConstants";

export interface IGetRepairLineSummaryLoadAction {
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.REQUEST;
    data: IGetRepairLineSummaryRequest
}
export const getRepairLineSummaryLoadAction = (data: IGetRepairLineSummaryRequest): IGetRepairLineSummaryLoadAction => ({
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineSummarySuccessAction {
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLineSummary>;
}
export const getRepairLineSummaryLoadSuccessAction = (list: SurewayAPIResponse<IRepairLineSummary>): IGetRepairLineSummarySuccessAction => ({
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineSummaryLoadFailedAction {
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.FAILED;
    message: string;
}
export const getRepairLineSummaryLoadFailedAction = (message: string): IGetRepairLineSummaryLoadFailedAction => ({
    type: IGET_REPAIR_LINE_SUMMARY_REQUEST.FAILED,
    message
});
