import { ActionsObservable, Epic } from "redux-observable";
import { catchError, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { MECHANIC_LOCAL_TOKEN, getStoredToken } from "./mechanicLocalTokenConstants";
import { IMechanicLocalTokenRequestAction, mechanicLocalTokenSuccessAction, mechanicLocalTokenFailedAction } from "./mechanicLocalTokenActions";
import { IAction } from "../../../reducers";

export const mechanicLocalTokenEpic: Epic = (
    action$: ActionsObservable<IMechanicLocalTokenRequestAction>
): Observable<IAction> =>
    action$.ofType(MECHANIC_LOCAL_TOKEN.REQUEST)
        .pipe(
            mergeMap(() => {
                const data = getStoredToken();
                if(data === null){
                    return ActionsObservable.of(mechanicLocalTokenFailedAction());
                } else {
                    return ActionsObservable.of(mechanicLocalTokenSuccessAction(data));
                };
            }),
            catchError(() => {
                return ActionsObservable.of(mechanicLocalTokenFailedAction());
            })
        );
