import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import LAErrorBox from "../shared/errorBox";
import LATextField from "../shared/textField";
import PasswordTextField from "../shared/passwordField";
import { IDispatch, IStore } from "../../redux/reducers";
import { STATUS_ENUM, Server } from "../../redux/server";
import { LALoadingButton } from "../shared/loadingButton";
import PublicActionPageWrapper from "../shared/publicActionWrapper";
import { MEDIA_QUERY_PHONE_NUMBER } from "../shared/theme";
import { IValidateMechanicLogin, IValidateMechanicLoginRequest } from "../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginConstants";
import { validateMechanicLogin } from "../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginAccessor";
import { validateMechanicLoginLoadAction } from "../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginActions";
import { mechanicLocalTokenRequestAction } from "../../redux/field/mechanics/mechanicLocalToken/mechanicLocalTokenActions";

interface IExternalMechanicLoginStoreProps {
    validateMechanicLoginStatus: Server<IValidateMechanicLogin>;
};

interface IExternalMechanicLoginDispatchProps {
    RequestLocalToken: () => unknown;
    validateMechanicLoginRequest: (data: IValidateMechanicLoginRequest) => unknown;
};

interface IExternalMechanicLoginState {
    username: string;
    password: string;
};

type IExternalMechanicLoginProps =
    IExternalMechanicLoginDispatchProps &
    IExternalMechanicLoginStoreProps;

class ExternalMechanicLogin extends Component<IExternalMechanicLoginProps, IExternalMechanicLoginState> {
    public constructor(props: IExternalMechanicLoginProps) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    };

    public componentDidMount(): void {
        this.authorizationCheck();
    };

    public render(): ReactNode {

        const { username, password } = this.state;
        const { validateMechanicLoginStatus } = this.props;

        return (
            <PublicActionPageWrapper title="Mechanic Portal" width={500} minWidth={MEDIA_QUERY_PHONE_NUMBER} minHeight={383}>
                <LAGrid alignItems="center" justify="center">
                    <LAGridItem xs={12}>
                        {validateMechanicLoginStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text={validateMechanicLoginStatus.message} className="mb-3" />}
                        <LATextField
                            id="username"
                            name="username"
                            label="Username"
                            value={username}
                            required={true}
                            onChange={this.onChange}
                            fullWidth={true}
                            onPressEnter={this.onSubmit}
                            className="mb-3"
                        />
                        <PasswordTextField
                            id="password"
                            name="password"
                            label="Password"
                            value={password}
                            required={true}
                            onChange={this.onChange}
                            fullWidth={true}
                            onPressEnter={this.onSubmit}
                            className="mb-2"
                        />

                        <div className="mb-4">
                            <span>Forgot your password? Contact Sureway IT Department</span>
                        </div>

                        <LALoadingButton
                            label="Login"
                            fullWidth={true}
                            onClick={this.onSubmit}
                            disabled={validateMechanicLoginStatus.kind === STATUS_ENUM.LOADING ? true : undefined}
                            isLoadingStatus={validateMechanicLoginStatus.kind === STATUS_ENUM.LOADING ? true : false}
                        />
                    </LAGridItem>
                </LAGrid>
            </PublicActionPageWrapper>
        )
    }

    private onChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private authorizationCheck = (): void => {
        this.props.RequestLocalToken();
    };

    private onSubmit = (): void => {
        this.props.validateMechanicLoginRequest({
            request: {
                email: this.state.username,
                password: this.state.password
            }
        });
    };

}

const mapStateToProps = (state: IStore): IExternalMechanicLoginStoreProps => ({
    validateMechanicLoginStatus: validateMechanicLogin(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalMechanicLoginDispatchProps => ({
    RequestLocalToken: (): unknown => dispatch(mechanicLocalTokenRequestAction()),
    validateMechanicLoginRequest: (request: IValidateMechanicLoginRequest): unknown => dispatch(validateMechanicLoginLoadAction(request)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ExternalMechanicLogin);