import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_FOREMAN_MESSAGING_REQUEST {
    REQUEST = "getForemanMessaging/GET_FOREMAN_MESSAGING_REQUEST",
    SUCCESS = "getForemanMessaging/GET_FOREMAN_MESSAGING_SUCCESS",
    FAILED = "getForemanMessaging/GET_FOREMAN_MESSAGING_FAILED"
};

export interface IGetForemanMessagingRequest extends ISurewayTokenRequestBody {

};

export interface IOptions {
    id: number;
    message_ID: number;
    option: string;
}

export interface IResponses {
    id: number;
    comments: string;
    employee?: string;
    employee_ID: number;
    employee_Option?: string;
    message_ID: number;
    selected_Option: string;
}

export interface IForemanMessaging {
    id?: number;
    message: string;
    options: IOptions[];
    responses: IResponses[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};