
export enum IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST {
    REQUEST = "payrollMechanicLogsUpload/PAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST",
    SUCCESS = "payrollMechanicLogsUpload/PAYROLL_MECHANIC_LOGS_UPLOAD_SUCCESS",
    FAILED = "payrollMechanicLogsUpload/PAYROLL_MECHANIC_LOGS_UPLOAD_FAILED"
};

export interface IPayrollMechanicLogsUploadRequest {
    token: string;
    request: IPayrollMechanicLogsUploadRequestFormat[];
};

export interface IPayrollMechanicLogsUploadRequestFormat {
    id: number;
    defect_ID: number;
}
