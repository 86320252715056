import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PAYROLL_MECHANIC_LOGS_REQUEST, IPayrollMechanicLogs, IGetPayrollMechanicLogsRequest } from "./getPayrollMechanicLogsConstants";

export interface IGetPayrollMechanicLogsLoadAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.REQUEST;
    data: IGetPayrollMechanicLogsRequest
}
export const getPayrollMechanicLogsLoadAction = (data: IGetPayrollMechanicLogsRequest): IGetPayrollMechanicLogsLoadAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetPayrollMechanicLogsSuccessAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IPayrollMechanicLogs[]>;
}
export const getPayrollMechanicLogsLoadSuccessAction = (list: SurewayAPIResponse<IPayrollMechanicLogs[]>): IGetPayrollMechanicLogsSuccessAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetPayrollMechanicLogsLoadFailedAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.FAILED;
    message: string;
}
export const getPayrollMechanicLogsLoadFailedAction = (message: string): IGetPayrollMechanicLogsLoadFailedAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REQUEST.FAILED,
    message
});
