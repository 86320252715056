
export enum IGET_LEVEL_REQUEST {
    REQUEST = "getLevels/GET_LEVEL_REQUEST",
    SUCCESS = "getLevels/GET_LEVEL_SUCCESS",
    FAILED = "getLevels/GET_LEVEL_FAILED"
};

export interface ILevelsRequest {
    token: string;
};

export interface ILevels {
    id: number;
    serial_No: string;
    brand?: string;
    model?: string;
    status?: string;
    site_Foreman?: string;
    certification_Date?: string;
    notes: string;
    wsS_ID: number | null;
    foreman: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};