import { IGET_MOVE_REQUEST_REQUEST, IMoveRequest, IMoveRequestRequest } from "./getMoveRequestConstants";

export interface IGetMoveRequestLoadAction {
    type: IGET_MOVE_REQUEST_REQUEST.REQUEST;
    data: IMoveRequestRequest
}
export const getMoveRequestLoadAction = (data: IMoveRequestRequest): IGetMoveRequestLoadAction => ({
    type: IGET_MOVE_REQUEST_REQUEST.REQUEST,
    data
});
export interface IGetMoveRequestSuccessAction {
    type: IGET_MOVE_REQUEST_REQUEST.SUCCESS;
    list: IMoveRequest[];
}
export const getMoveRequestLoadSuccessAction = (list: IMoveRequest[]): IGetMoveRequestSuccessAction => ({
    type: IGET_MOVE_REQUEST_REQUEST.SUCCESS,
    list
});
export interface IGetMoveRequestLoadFailedAction {
    type: IGET_MOVE_REQUEST_REQUEST.FAILED;
    message: string;
}
export const getMoveRequestLoadFailedAction = (message: string): IGetMoveRequestLoadFailedAction => ({
    type: IGET_MOVE_REQUEST_REQUEST.FAILED,
    message
});
