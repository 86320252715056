import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_FILTER_CUTS_REQUEST, IFilterCut, IGetFilterCutRequest } from "./getFilterCutsConstants";

export interface IGetFilterCutsLoadAction {
    type: IGET_FILTER_CUTS_REQUEST.REQUEST;
    data: IGetFilterCutRequest
}
export const getFilterCutsLoadAction = (data: IGetFilterCutRequest): IGetFilterCutsLoadAction => ({
    type: IGET_FILTER_CUTS_REQUEST.REQUEST,
    data
});
export interface IGetFilterCutsSuccessAction {
    type: IGET_FILTER_CUTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IFilterCut>>;
}
export const getFilterCutsLoadSuccessAction = (list: SurewayAPIResponse<ById<IFilterCut>>): IGetFilterCutsSuccessAction => ({
    type: IGET_FILTER_CUTS_REQUEST.SUCCESS,
    list
});
export interface IGetFilterCutsLoadFailedAction {
    type: IGET_FILTER_CUTS_REQUEST.FAILED;
    message: string;
}
export const getFilterCutsLoadFailedAction = (message: string): IGetFilterCutsLoadFailedAction => ({
    type: IGET_FILTER_CUTS_REQUEST.FAILED,
    message
});
