import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE, } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import {  SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import {  getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { IGetMechanicLogsByDefectIDRequest, IMechanicLogsByDefectID } from "../../../redux/field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDConstants";
import { getMechanicLogsByDefectID } from "../../../redux/field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDAccessor";
import { getMechanicLogsByDefectIDLoadAction } from "../../../redux/field/repairLine/getMechanicLogsByDefectID/getMechanicLogsByDefectIDActions";
import queryString from "query-string";

interface IMechanicLogsForDefectItemStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMechanicLogsByDefectID: Server<SurewayAPIResponse<IMechanicLogsByDefectID[]>>;
};

interface IMechanicLogsForDefectItemDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMechanicLogsByDefectIDRequest: (data: IGetMechanicLogsByDefectIDRequest) => unknown;
};


interface IMechanicLogsForDefectItemOwnProps {

};

interface IMechanicLogsForDefectItemState {
};

const MechanicLogsForDefectItemStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMechanicLogsForDefectItemProps = RouteComponentProps
    & IMechanicLogsForDefectItemStoreProps
    & IMechanicLogsForDefectItemDispatchProps
    & IMechanicLogsForDefectItemOwnProps;

class MechanicLogsForDefectItem extends PureComponent<IMechanicLogsForDefectItemProps, IMechanicLogsForDefectItemState> {

    public constructor(props: IMechanicLogsForDefectItemProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IMechanicLogsForDefectItemProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const {  } = this.state;
        const { getMechanicLogsByDefectID, getToken } = this.props;
        
        const getRole = pageAccessCheck(getToken, "repairLine");
        const data = hasPayload(getMechanicLogsByDefectID) ? Object.values(getMechanicLogsByDefectID.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Mechanic Logs - Defect Item" description="Mechanic Logs - Defect Item" fixedSpaceOnSmallerScreens={true}>
                {getRole !== NotApplicable && <MechanicLogsForDefectItemStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>
                    
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <h2 className="title">MECHANIC LOGS</h2>
                                    <hr />

                                    {getMechanicLogsByDefectID.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Mechanic Logs..." />}
                                    {getMechanicLogsByDefectID.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Mechanic Logs..." />}

                                    {getMechanicLogsByDefectID.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    paging={30}
                                                    removeStyleBtn={true}
                                                    key="id"
                                                    id="MechanicLogsForDefectItemList"
                                                    sumColumn={[{ caption: "Total Hours:", columnName: "hours" }]}
                                                    customRowColor={true}
                                                    storageKey="MechanicLogsForDefectItemSessionKey"
                                                    exportFileName="MechanicLogsForDefectItem"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "display", caption: "Unit/Attachment #", type: "string" },
                                                        { name: "defect_Item", caption: "Defect Item", type: "string" },
                                                        { name: "date", caption: "Date", type: "date" },
                                                        { name: "component_Code", caption: "Component Code", type: "string" },
                                                        { name: "mechanic_Name", caption: "Mechanic Name", type: "string" },
                                                        { name: "hours", caption: "Hours", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string"},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </MechanicLogsForDefectItemStyles>
                }
            </PageSpacing>
        
        );
    }

    private onClick = (): void => {

    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getMechanicLogsByDefectID)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        const query = queryString.parse(this.props.location.search);

        if(query && query.id && Number(query.id) > 0 ) {
            if (hasPayload(this.props.getToken)) {
                this.props.getMechanicLogsByDefectIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: Number(query.id)
                    }
                });
            };
        }
    };

}

const mapStateToProps = (state: IStore): IMechanicLogsForDefectItemStoreProps => ({
    getToken: getToken(state),
    getMechanicLogsByDefectID: getMechanicLogsByDefectID(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMechanicLogsForDefectItemDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMechanicLogsByDefectIDRequest: (data: IGetMechanicLogsByDefectIDRequest) => dispatch(getMechanicLogsByDefectIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MechanicLogsForDefectItem);