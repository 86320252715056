import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_ATTACHMENT_LIST_GET_REQUEST } from "./addAttachmentListGETConstants";
import { IAddAttachmentListGETLoadAction, IAddAttachmentListGETLoadFailedAction, IAddAttachmentListGETSuccessAction, addAttachmentListGETLoadFailedAction, addAttachmentListGETLoadSuccessAction } from "./addAttachmentListGETActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addAttachmentListGETEpic: Epic = (
    action$: ActionsObservable<IAddAttachmentListGETLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddAttachmentListGETSuccessAction | IAddAttachmentListGETLoadFailedAction> =>
    action$.ofType(IADD_ATTACHMENT_LIST_GET_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.ADD_ATTACHMENT_LIST_GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddAttachmentListGETSuccessAction | IAddAttachmentListGETLoadFailedAction => {
                            if(response.message === "Success"){
                                return addAttachmentListGETLoadSuccessAction(response);
                            } else {
                                return addAttachmentListGETLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addAttachmentListGETLoadFailedAction(response.message)))
                    )
            )
        );