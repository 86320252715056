import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, SurveyDD, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { GetSurveyLookup } from "../../../redux/survey/getSurveyLookup/getSurveyLookupAccessor";
import { getMachineControls } from "../../../redux/survey/getMachineControls/getMachineControlsAccessor";
import { addMachineControls } from "../../../redux/survey/addMachineControl/addMachineControlAccessor";
import { updateMachineControls } from "../../../redux/survey/updateMachineControl/updateMachineControlAccessor";
import { IAddUpdateMachineControlRequest } from "../../../redux/survey/addMachineControl/addMachineControlConstants";
import { IMachineControl, IMachineControlRequest } from "../../../redux/survey/getMachineControls/getMachineControlsConstants";
import { ISurveyLookup, ISurveyLookupRequest } from "../../../redux/survey/getSurveyLookup/getSurveyLookupConstants";
import { getSurveyLookupLoadAction } from "../../../redux/survey/getSurveyLookup/getSurveyLookupActions";
import { getMachineControlLoadAction } from "../../../redux/survey/getMachineControls/getMachineControlsActions";
import { addMachineControlLoadAction } from "../../../redux/survey/addMachineControl/addMachineControlActions";
import { updateMachineControlLoadAction } from "../../../redux/survey/updateMachineControl/updateMachineControlActions";
import { IMachineReceiver, IMachineReceiverRequest } from "../../../redux/survey/getMachineReceivers/getMachineReceiversConstants";
import { getMachineReceiverLoadAction } from "../../../redux/survey/getMachineReceivers/getMachineReceiversActions";
import { getMachineControlBoxesLoadAction } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesActions";
import { IMachineControlBoxes, IMachineControlBoxesRequest } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesConstants";
import { getMachineControlBoxes } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesAccessor";
import { getMachineReceivers } from "../../../redux/survey/getMachineReceivers/getMachineReceiversAccessor";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAAutoComplete from "../../shared/autoComplete";

const RequiredFields: string[] = ["unit_ID"];

interface IAddUpdateMachineControlComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    addMachineControlStatus: Server<SurewayAPIResponse<string>>;
    updateMachineControlStatus: Server<SurewayAPIResponse<string>>;
    getMachineControlStatus: Server<SurewayAPIResponse<ById<IMachineControl>>>;
    getMachineReceiverStatus: Server<SurewayAPIResponse<ById<IMachineReceiver>>>;
    getMachineBoxesStatus: Server<SurewayAPIResponse<ById<IMachineControlBoxes>>>;
};

interface IAddUpdateMachineControlComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISurveyLookupRequest) => unknown;
    getMachineControlRequest: (data: IMachineControlRequest) => unknown
    addMachineControlRequest: (data: IAddUpdateMachineControlRequest) => unknown;
    updateMachineControlRequest: (data: IAddUpdateMachineControlRequest) => unknown;
    getMachineReceiverRequest: (data: IMachineReceiverRequest) => unknown;
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => unknown;
};

interface IAddUpdateMachineControlOwnProps {
    id?: string;
};

interface IAddUpdateMachineControlComponentState {
    data: IMachineControl;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateMachineControlStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .green-border {
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateMachineControlComponentProps =
    RouteComponentProps
    & IAddUpdateMachineControlOwnProps
    & IAddUpdateMachineControlComponentStoreProps
    & IAddUpdateMachineControlComponentDispatchProps;

class AddUpdateMachineControl extends PureComponent<IAddUpdateMachineControlComponentProps, IAddUpdateMachineControlComponentState> {

    public constructor(props: IAddUpdateMachineControlComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                details: "",
                status: "",
                controlBox: "",
                receiver_1: "",
                receiver_2: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMachineControlComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addMachineControlStatus !== prevProps.addMachineControlStatus) {

                if (this.props.addMachineControlStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addMachineControlStatus.message });

                if (hasPayload(this.props.addMachineControlStatus) && this.props.addMachineControlStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateMachineControlStatus !== prevProps.updateMachineControlStatus) {

                if (this.props.updateMachineControlStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateMachineControlStatus.message });

                if (hasPayload(this.props.updateMachineControlStatus) && this.props.updateMachineControlStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " MACHINE CONTROL";
        const { addMachineControlStatus, updateMachineControlStatus, getLookup, getMachineBoxesStatus, getMachineReceiverStatus } = this.props;

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const boxes = hasPayload(getMachineBoxesStatus) ? getMachineBoxesStatus.payload.response : {};
        const receivers = hasPayload(getMachineReceiverStatus) ? getMachineReceiverStatus.payload.response : {};

        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();

        const box = data.control_Box_ID && boxes ? boxes[data.control_Box_ID] : null;
        const onBox = (value: string): void => value !== null ? this.handleBox(boxes, value) : this.handleBox(boxes);

        const receiverOne = data.receiver_ID_1 && receivers ? receivers[data.receiver_ID_1] : null;
        const onReceiverOne = (value: string): void => value !== null ? this.handleReceiverOne(receivers, value) : this.handleReceiverOne(receivers);

        const receiverTwo = data.receiver_ID_2 && receivers ? receivers[data.receiver_ID_2] : null;
        const onReceiverTwo = (value: string): void => value !== null ? this.handleReceiverTwo(receivers, value) : this.handleReceiverTwo(receivers);

        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status ?? "");

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateMachineControlStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={units}
                                id="unit-search"
                                placeHolder="Survey Machine Unit #"
                                disabled={undefined}
                                displayExp="unit_Number"
                                name="unit_Number"
                                errorName="unit_ID"
                                errors={errors}
                                currentVal={unit?.unit_Number}
                                onClick={onUnit}
                                columns={[
                                    { name: "unit_Number", caption: "Unit Number", type: "string" },
                                    { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                                    { name: "make", caption: "Make", type: "string" },
                                    { name: "model", caption: "Model", type: "string" },
                                    { name: "location_Details", caption: "Location", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Equipment Type"
                                fullWidth={true}
                                variant="outlined"
                                name="equipment_Type"
                                disabled={true}
                                onChange={this.handleChange}
                                value={unit ? unit.equipment_Type : ""}
                                errorText={errors["equipment_Type"] ? errors["equipment_Type"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Make"
                                fullWidth={true}
                                variant="outlined"
                                name="make"
                                disabled={true}
                                value={unit ? unit.make : ""}
                                onChange={this.handleChange}
                                errorText={errors["make"] ? errors["make"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Model"
                                fullWidth={true}
                                variant="outlined"
                                name="model"
                                disabled={true}
                                value={unit ? unit.model : ""}
                                onChange={this.handleChange}
                                errorText={errors["model"] ? errors["model"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Site"
                                fullWidth={true}
                                variant="outlined"
                                name="site"
                                disabled={true}
                                value={unit ? unit.location_Details : ""}
                                onChange={this.handleChange}
                                errorText={errors["site"] ? errors["site"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={Object.values(boxes)}
                                id="control-boxes"
                                placeHolder="Control Box Serial #"
                                disabled={undefined}
                                displayExp="serial_No"
                                name="serial_No"
                                errorName="serial_No"
                                errors={errors}
                                currentVal={box?.serial_No}
                                onClick={onBox}
                                columns={[
                                    { name: "serial_No", caption: "Serial #", type: "string" },
                                    { name: "make", caption: "Make", type: "string" },
                                    { name: "model", caption: "Model", type: "string" },
                                    { name: "version", caption: "Version", type: "number" },
                                    { name: "auto_Dozer", caption: "Auto Dozer", type: "string" },
                                    { name: "auto_Grader", caption: "Auto Grader", type: "string" },
                                    { name: "auto_Excavator", caption: "Auto Excavator", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="GPS Make"
                                fullWidth={true}
                                variant="outlined"
                                name="gps_Make"
                                disabled={true}
                                value={box ? box.make : ""}
                                onChange={this.handleChange}
                                errorText={errors["gps_Make"] ? errors["gps_Make"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={SurveyDD}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onStatus}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                value={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Details"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="details"
                                value={data.details ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["details"] ? errors["details"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="green-border text-center mb-3">
                            <strong>GNSS Receivers</strong>
                            <LAGrid spacing={0}>
                                
                                <LAGridItem xs={12} sm={6}>
                                    <DevExtremePopupLookup
                                        data={Object.values(receivers)}
                                        id="receiver-1"
                                        placeHolder="Receiver 1 Unit #"
                                        disabled={undefined}
                                        displayExp="unit_No"
                                        name="unit_No"
                                        errorName="unit_No"
                                        errors={errors}
                                        currentVal={receiverOne?.unit_No}
                                        onClick={onReceiverOne}
                                        columns={[
                                            { name: "unit_No", caption: "Unit #", type: "string" },
                                            { name: "serial_No", caption: "Serial #", type: "string" },
                                            { name: "make", caption: "Make", type: "string" },
                                            { name: "model", caption: "Model", type: "string" },
                                            { name: "version", caption: "Version", type: "number" },
                                            { name: "status", caption: "Status", type: "string" },
                                            { name: "notes", caption: "Notes", type: "string" }
                                        ]}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>
                                    <DevExtremePopupLookup
                                        data={Object.values(receivers)}
                                        id="receiver-2"
                                        placeHolder="Receiver 2 Unit #"
                                        disabled={undefined}
                                        displayExp="unit_No"
                                        name="unit_No"
                                        errorName="unit_No"
                                        errors={errors}
                                        currentVal={receiverTwo?.unit_No}
                                        onClick={onReceiverTwo}
                                        columns={[
                                            { name: "unit_No", caption: "Unit #", type: "string" },
                                            { name: "serial_No", caption: "Serial #", type: "string" },
                                            { name: "make", caption: "Make", type: "string" },
                                            { name: "model", caption: "Model", type: "string" },
                                            { name: "version", caption: "Version", type: "number" },
                                            { name: "status", caption: "Status", type: "string" },
                                            { name: "notes", caption: "Notes", type: "string" }
                                        ]}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addMachineControlStatus.kind} successMessage="Machine Control has been added successfully" />
                    <RequestStatus requestStatus={updateMachineControlStatus.kind} successMessage="Machine Control has been updated successfully" />

                </AddUpdateMachineControlStyles>
            </PageSpacing>
        );
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);
        let errors = { ...this.state.errors };

        if (RequiredFields.includes("unit_ID"))
            errors = this.errorChecker("unit_ID", value ?? "", errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0
            },
            errors
        });
    };

    private handleBox = (boxes: ById<IMachineControlBoxes>, value?: string): void => {
        const box = Object.values(boxes).find((x: IMachineControlBoxes) => x.serial_No === value);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                control_Box_ID: box ? box.id : undefined
            }
        });
    };

    private handleReceiverOne = (boxes: ById<IMachineReceiver>, value?: string): void => {
        const receiver = Object.values(boxes).find((x: IMachineReceiver) => x.unit_No === value);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                receiver_ID_1: receiver ? receiver.id : undefined
            }
        });
    };

    private handleReceiverTwo = (boxes: ById<IMachineReceiver>, value?: string): void => {
        const receiver = Object.values(boxes).find((x: IMachineReceiver) => x.unit_No === value);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                receiver_ID_2: receiver ? receiver.id : undefined
            }
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addMachineControlRequest({
                    token: this.props.token.payload.response.token,
                    request: data
                });
            }
            else {
                this.props.updateMachineControlRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ...data,
                        modified_By: this.props.token.payload.response.upn
                    }
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getMachineControlStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getMachineControlStatus.payload.response[query.id];

                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getMachineControlRequest({
                            token
                        });
                    }

                    if (isNotLoaded(this.props.getMachineBoxesStatus))
                        this.props.getMachineControlBoxesRequest({
                            token
                        });

                    if (isNotLoaded(this.props.getMachineReceiverStatus))
                        this.props.getMachineReceiverRequest({
                            token
                        });
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.UNAUTHORIZED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Machine_Control"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMachineControlComponentStoreProps => ({
    token: getToken(state),
    getLookup: GetSurveyLookup(state),
    addMachineControlStatus: addMachineControls(state),
    getMachineControlStatus: getMachineControls(state),
    getMachineBoxesStatus: getMachineControlBoxes(state),
    getMachineReceiverStatus: getMachineReceivers(state),
    updateMachineControlStatus: updateMachineControls(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMachineControlComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISurveyLookupRequest) => dispatch(getSurveyLookupLoadAction(data)),
    getMachineControlRequest: (data: IMachineControlRequest) => dispatch(getMachineControlLoadAction(data)),
    getMachineReceiverRequest: (data: IMachineReceiverRequest) => dispatch(getMachineReceiverLoadAction(data)),
    addMachineControlRequest: (data: IAddUpdateMachineControlRequest) => dispatch(addMachineControlLoadAction(data)),
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => dispatch(getMachineControlBoxesLoadAction(data)),
    updateMachineControlRequest: (data: IAddUpdateMachineControlRequest) => dispatch(updateMachineControlLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMachineControl);