import { Autocomplete } from "@material-ui/lab";
import { Grid, Button, Typography, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel } from "@mui/material";
import React from 'react';
import { currentUser } from "../../equipmenttrackerhome";
import {moveCustomEquipment, getLocations, getActiveUsers, getDrivers, getTrailerRates, getTrailers, getTractors, getForemans, getCurrentSiteForeman, getAttachmentList, removeAttachmentFromUnit} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {getSOCTWorkingSubSitesByParentID} from '../../../soct/api/workingsites/workingSitesAPI';
import LALinkButton from "../../../../shared/linkButton";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LAPaperWithLessPadding } from "../../../../shared/paper";
import { LAIconButton } from "../../../../shared/buttons";
import { AddIcon, DeleteIcon } from "../../../../shared/icons";
import LAAutoComplete from "../../../../shared/autoComplete";
import { undefinedFunction } from "../../../../shared/constExports";
import LATextField from "../../../../shared/textField";
import { DevExtremePopupLookup } from "../../../../shared/devExtremePopupLookup";
import { handleTokenAccess } from "../../../../shared/etTokenAccessWrapper";

interface IProps{
    locationOptions:any
    closeEquipmentMoveForm:any;
    clickedItem:any;
    clickedLocation:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
    freezeSave:boolean;
    attachment_list: any[];
}

  var working_sites:string[] = [];
  var move_request:any = {unit_number:'', from_location:'', to_location:'', completed_date:'', comments:'', send_email:false, moved_item:{}, from_sub_location:'', to_sub_location:'',from_sub_site_id:'', to_sub_site_id:'', pickup_contact_name: '', pickup_contact_upn:'', dropoff_contact_name: '', dropoff_contact_upn:'',
  completed:false, drive_over:false, moved_previously:false, driver_id:0, trailer_id:0, hours:0, driver:'', trailer_config:'',trailer_config_id:0, assigned_unit_number:'', assigned_tractor_unit:'', assigned_tractor_id:0, from_location_latitude:'', from_location_longitude:'',to_location_latitude:'',to_location_longitude:'',
is_from_app:false, attachment_list: []};
  var from_sub_loc_options:any[] = [];
  var from_sub_loc_details:any[] = [];
  var to_sub_loc_options:any[] = [];
  var to_sub_loc_details:any[] = [];
  var working_site_locations:any[] = [];
  var isCompletedDateInPast:boolean = false;
  var isCompletedDateInPastOrToday:boolean = false;
  var grid_xs_width:number = 4;
var trailer_config_options:any[] = [];
var temp_drivers:any[] = [];
var driver_options:any[] = [];
var temp_trailer_rates:any[] = [];
var temp_trailers:any[] = [];
var assigned_unit_number_options:any[] = [];
var assigned_tractor_options:any[] = [];
var temp_tractors:any[] = [];
var disableEdit:boolean = false;
var userList: string[] = [];
var readwriteAccess:boolean = false;
var foremans: any[] = [];
var attachmentList: any[] = [];
var attachemnts: any[] = [];
var getAllAttachmentList:any[] = [];
var errors: any = [{ "attachment_No": '' }];
var user:string = '';

export default class CustomEquipmentMoveForm extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], freezeSave:false, attachment_list: []};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onAttachmentAdd = this.onAttachmentAdd.bind(this);
        this.handleAttachmentTableChange = this.handleAttachmentTableChange.bind(this);
        this.onAttachmentDelete = this.onAttachmentDelete.bind(this);
        this.handleAssignPickupContact = this.handleAssignPickupContact.bind(this);
        this.handleAssignDropoffContact = this.handleAssignDropoffContact.bind(this);
    }
    closePopup(){
      move_request = {unit_number:'', from_location:'',requested_By:'', to_location:'', completed_date:'', comments:'', send_email:false, moved_item:{}, from_sub_location:'', to_sub_location:'', from_sub_site_id:'', to_sub_site_id:'', pickup_contact_name: '', pickup_contact_upn:'', dropoff_contact_name: '', dropoff_contact_upn:'',
                    completed:false, drive_over:false, moved_previously:false, driver_id:0, trailer_id:0, hours:0, driver:'', trailer_config:'',trailer_config_id:0, assigned_unit_number:'', assigned_tractor_unit:'', assigned_tractor_id:0, from_location_latitude:'', from_location_longitude:'',to_location_latitude:'',to_location_longitude:'', is_from_app:false, attachment_list: []};
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'completed_date':
                move_request.completed_date = event.target.value;
                isCompletedDateInPast = false;
                isCompletedDateInPastOrToday = false;
                // create a current date
                let current_date_offset = new Date();
                // calculate yesterdays date
                current_date_offset.setDate(current_date_offset.getDate());
                // use yesterdays date and get only date without timestamp
                current_date_offset = new Date(current_date_offset.getFullYear(),current_date_offset.getMonth(),current_date_offset.getDate());
                // replace - with / for completed date
                let move_req_date = new Date(move_request.completed_date.replace(/-/g,'/'));
                // get only date for completed date without timestamp
                move_req_date = new Date(move_req_date.getFullYear(),move_req_date.getMonth(),move_req_date.getDate());
                // if completed date is in the past besides yesterday make moved_previously mandatory
                if(move_req_date < current_date_offset){
                    isCompletedDateInPast = true;
                }
                if(move_req_date <= current_date_offset){
                  isCompletedDateInPastOrToday = true;
                }
                break;
            case 'comments':
                move_request.comments = event.target.value;
                break;
            case 'send_email':
                move_request.send_email = !move_request.send_email;
                break;
            case 'to_location':
                move_request.to_location = event.target.value;
                break;
            case 'unit_number':
                move_request.unit_number = event.target.value;
                break;
            case 'completed':
              move_request.completed = !move_request.completed;
              break;
            case 'drive_over':
              move_request.drive_over = !move_request.drive_over;
              break;
            case 'moved_previously':
              move_request.moved_previously = !move_request.moved_previously;
              break;
            case 'hours':
              move_request.hours = event.target.value;
              break;
        }
        this.setState({});
    }
    
    handleAssignDropoffContact() {
      move_request.dropoff_contact_upn = user;
      let findRecord = foremans.find(e=>e.upn===move_request.dropoff_contact_upn)
      if(findRecord){
        move_request.dropoff_contact_name = findRecord.display_Name;
      }
      this.setState({});
    }
    handleAssignPickupContact() {
      move_request.pickup_contact_upn = user;
      let findRecord = foremans.find(e=>e.upn===move_request.pickup_contact_upn)
      if(findRecord){
        move_request.pickup_contact_name = findRecord.display_Name;
      }
      this.setState({});
    }

    async onSave(){
      const invalidItems = this.state.attachment_list.filter(item => (
        !item.type || !item.coupler_Design || !item.style
      ));
      // console.log(invalidItems)
  
      if (invalidItems.length > 0) {
        alert("Please fill Type, Coupler Design and Style fields in attachment list table.");
      } else {
        move_request.attachment_list = this.state.attachment_list
          .filter(
            (att) =>
              att.id !== undefined &&
              att.type !== "" &&
              att.coupler_Design !== "" &&
              att.style !== ""
          )
          .map((attachment) => ({
            ID: 0,
            Attachment_ID: attachment.id,
            Coupler_Design_ID: attachment.coupler_Design_ID,
            Type_ID: attachment.type_ID,
            Style_ID: attachment.style_ID,
            From_Sub_Location_ID: attachment.from_Sub_Location_ID,
            To_Sub_Location_ID: attachment.to_Sub_Location_ID
              ? attachment.to_Sub_Location_ID
              : move_request.to_sub_site_id,
          }));
        // move_request.attachment_list = this.state.attachment_list.filter(att => att.id !== undefined && att.id !== 0).map(attachment => ({Attachment_ID: attachment.id, Coupler_Design_ID: attachment.coupler_Design_ID, Type_ID: attachment.type_ID, Style_ID:attachment.style_ID, From_Sub_Location_ID: move_request.from_sub_site_id, To_Sub_Location_ID: move_request.to_sub_site_id}));
          // console.log(move_request)
          if(move_request.unit_number!="" && move_request.completed_date !="" && move_request.to_location != null && move_request.to_sub_location!="" &&
          move_request.to_location != "" && move_request.to_sub_location!=null && move_request.from_location!='' && move_request.from_location!=null && move_request.from_sub_location!=""
          && move_request.from_sub_location!=null){        
            
            if(localStorage.getItem("ET-Role") === "RW" && (move_request.requested_By === undefined || move_request.requested_By === null || move_request.requested_By === ""))
            {
              alert("Please select Requested By");
            } else if( move_request.completed && !isCompletedDateInPastOrToday) { 
              alert("Completed date must be in past or today when moved is checked");
            } else {
              this.setState({freezeSave:true});
              var res:any = await moveCustomEquipment(move_request);
              if(res.status == 200){
                move_request = {unit_number:'', from_location:'',requested_By:'', to_location:'', completed_date:'', comments:'', send_email:false, moved_item:{}, from_sub_location:'', to_sub_location:'',  pickup_contact_name: '', pickup_contact_upn:'', dropoff_contact_name: '', dropoff_contact_upn:'',
                completed:false, drive_over:false, moved_previously:false, driver_id:0, trailer_id:0, hours:0, driver:'', trailer_config:'',trailer_config_id:0, assigned_unit_number:'', assigned_tractor_unit:'', assigned_tractor_id:0, from_location_latitude:'', from_location_longitude:'',to_location_latitude:'',to_location_longitude:'',
                is_from_app:false,attachment_list: []};
                  this.props.closeEquipmentMoveForm();
                  this.props.refreshGrid();
              }
            }
          }
          else{
            alert("Unit number, to-location, and completed date are mandatory");
          }
      }
    }
    async componentDidMount(){
      user = currentUser;
      move_request = {unit_number:'', from_location:'',requested_By:'', to_location:'', completed_date:'', comments:'', send_email:false, moved_item:{}, from_sub_location:'', to_sub_location:'', from_sub_site_id:'', to_sub_site_id:'', pickup_contact_name: '', pickup_contact_upn:'', dropoff_contact_name: '', dropoff_contact_upn:'',
  completed:false, drive_over:false, moved_previously:false, driver_id:0, trailer_id:0, hours:0, driver:'', trailer_config:'',trailer_config_id:0, assigned_unit_number:'', assigned_tractor_unit:'', assigned_tractor_id:0, from_location_latitude:'', from_location_longitude:'',to_location_latitude:'',to_location_longitude:'',
  is_from_app:false, attachment_list: []};
      if(window.innerWidth <= 760){
        grid_xs_width = 9;
      }
      else{
        grid_xs_width = 4;
      }
      if(window.location.href.includes("token")){
        move_request.is_from_app = true;
        let upn = await handleTokenAccess(); 
        user = upn;
      }
      disableEdit = localStorage.getItem("ET-Role") === "R" || window.location.href.includes("?token");
      localStorage.getItem("ET-Role") === "RW" ? readwriteAccess = true : readwriteAccess = false;
      foremans = await getForemans();
      getAllAttachmentList = await getAttachmentList();
      // console.log(attachemnts)
      attachemnts = Object.values(getAllAttachmentList).filter(item => item.move_Status === null || item.move_Status === 'completed');
      // console.log(attachemnts);
      temp_drivers = await getDrivers();
      let drivers:any = [];
      temp_drivers.map((driver:any) => {
        if(driver.active==='Yes'){
          if(driver.assigned_Unit_Number != null && driver.assigned_Unit_Number != ''){
            var driver_name = driver.driver_Firstname + " " + driver.driver_Lastname;
          }
          else{
            var driver_name = driver.driver_Firstname + " " + driver.driver_Lastname;
          }
          drivers.push(driver_name);
        }
      });
      driver_options = drivers;
      temp_trailer_rates = await getTrailerRates();
      temp_trailers = await getTrailers();
      let trailers:any = [];
      temp_trailers.map((trailer:any) => {
        temp_trailer_rates.map((trailer_rate:any) => {
        if(trailer.active==='Yes' && trailer.trailer_Rate_ID == trailer_rate.id){
          var trailer_name = trailer_rate.description;
          if( trailers.indexOf(trailer_name)===-1){
            trailers.push(trailer_name)
          }
        }
      });
    });
    trailer_config_options = trailers;

    temp_tractors = await getTractors();
    let tractors:any = [];
    temp_tractors.map((tractor:any) => {
      var tractor_unit = tractor.unit_Number;
      tractors.push(tractor_unit);
    });
    assigned_tractor_options = tractors;

        move_request.from_location = this.props.clickedLocation;
        working_sites = await getLocations();
        userList = await getActiveUsers();
        var temp_loc:any[] = [];
        working_site_locations = [];
        working_sites.map((location:any) => {
            let location_name = location.site_Name + ", Job #: " + location.job_No;
            working_site_locations.push(location_name);
            temp_loc.push(location);
            });
            working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
            this.setState({location_options:temp_loc});
    }

    handleGPSClick(latitude:any, longitude:any)  {
      let mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude} `;
      window.open(mapUrl, "_blank");
    } 

  handleAttachmentTableChange(value:any, index?:number,name?:any| undefined){
      if (index !== undefined) {

          console.log(value)
          
          const iS = [...this.state.attachment_list];
          // console.log(iS)
          if(name === "attachment_No"){
              if(value) {
                  const val = attachemnts.find(attachment => attachment.attachment_No === value)
                  // console.log(val)
                  let record = { ...iS[index] };
                  // console.log(record)
                  record = {
                    id: val.id,
                    attachment_No: val.attachment_No,
                    type:val.type,
                    type_ID: val.type_ID,
                    coupler_Design:val.coupler_Design,
                    coupler_Design_ID: val.coupler_Design_ID,
                    style:val.style,
                    style_ID: val.style_ID,
                    from_Sub_Location: val.location,
                    from_Sub_Location_ID: val.working_Site_Sub_ID,
                    to_Sub_Location: move_request.to_sub_location ? move_request.to_sub_location : '',
                    to_Sub_Location_ID: move_request.to_sub_site_id ? move_request.to_sub_site_id: '',
                    attached_Unit_No: val.attached_Unit_No,
                    move_Status: val.move_Status
                };
                  iS[index] = record;
                  // console.log(iS)
              } else if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                    id: 0,
                    attachment_No: '',
                    type:'',
                    type_ID: '',
                    coupler_Design:'',
                    coupler_Design_ID: '',
                    style:'',
                    style_ID: '',
                    from_Sub_Location: '',
                    from_Sub_Location_ID: 0,
                    to_Sub_Location: '',
                    to_Sub_Location_ID:  0,
                    attached_Unit_No: '',
                    move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              
          }

          if(name==='type') {
              if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                    id: 0,
                    attachment_No: '',
                    type:'',
                    type_ID: '',
                    coupler_Design:'',
                    coupler_Design_ID: '',
                    style:'',
                    style_ID: '',
                    from_Sub_Location: '',
                    from_Sub_Location_ID: 0,
                    to_Sub_Location: '',
                    to_Sub_Location_ID:  0,
                    attached_Unit_No: '',
                    move_Status:''
                  }
                  iS[index] = record;
                  console.log(iS)
              }
              // console.log(value)
              let record = { ...iS[index] };
              record.type = value.type;
              record.type_ID = value.type_ID;
              iS[index] = record;
              // console.log(iS)
          }

          if(name==='coupler_Design') {
              if (value === '' || value === null || value === undefined){
                  console.log(value)
                  let record = { ...iS[index]}
                  record = {
                    id: 0,
                    attachment_No: '',
                    type:'',
                    type_ID: '',
                    coupler_Design:'',
                    coupler_Design_ID: '',
                    style:'',
                    style_ID: '',
                    from_Sub_Location: '',
                    from_Sub_Location_ID: 0,
                    to_Sub_Location: '',
                    to_Sub_Location_ID:  0,
                    attached_Unit_No: '',
                    move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              // const iS = this.state.attachment_list;
              let record = { ...iS[index] };
              record.coupler_Design = value.coupler_Design;
              record.coupler_Design_ID = value.coupler_Design_ID;
              iS[index] = record;
              // console.log(iS)
          }

          if(name==='style') {
            if (value === '' || value === null || value === undefined){
                console.log(value)
                let record = { ...iS[index]}
                record = {
                  id: 0,
                  attachment_No: '',
                  type:'',
                  type_ID: '',
                  coupler_Design:'',
                  coupler_Design_ID: '',
                  style:'',
                  style_ID: '',
                  from_Sub_Location: '',
                  from_Sub_Location_ID: 0,
                  to_Sub_Location: '',
                  to_Sub_Location_ID:  0,
                  attached_Unit_No: '',
                  move_Status:''
                }
                iS[index] = record;
                // console.log(iS)
            }
            // const iS = this.state.attachment_list;
            let record = { ...iS[index] };
            record.style = value.style;
            record.style_ID = value.style_ID;
            iS[index] = record;
            // console.log(iS)
        }
          // If autcomplete delete set value to empty string
          if(value === null){
            value={value:null};
          }

          this.setState({
              ...this.state,
              attachment_list:iS
          });
          // console.log(this.state.attachment_list)
      }
  }

  onAttachmentAdd() {
      const list = [...this.state.attachment_list];
      list.push({
        id: 0,
        attachment_No: "",
        type: "",
        type_ID: "",
        coupler_Design: "",
        coupler_Design_ID: "",
        style: "",
        style_ID: "",
        from_Sub_Location: "",
        from_Sub_Location_ID: 0,
        to_Sub_Location: "",
        to_Sub_Location_ID: 0,
        attached_Unit_No: "",
        move_Status: "",
      });
      this.setState({
          ...this.state,
          attachment_list: list
      });
      // console.log(this.state.attachment_list)
  }

  async onAttachmentDelete(index: number, data:any) {
      console.log(index, data)
      if(data.attached_Unit_No === "" || data.attached_Unit_No === null) {     
        this.state.attachment_list.splice(index, 1);
      } 
      else {
        if(data.id > 0) {
          const shouldDelete = window.confirm('This will remove the association between the unit and attachment. Do you want to proceed?');
          if(shouldDelete) {
              const result = await removeAttachmentFromUnit(data.id);
              if(result.message === 'Success') {
                  this.state.attachment_list.splice(index, 1);
                  const indexToRemove = move_request.attachment_list.findIndex((item:any) => item.attachment_No === data.attachment_No);
                  if (indexToRemove !== -1) {
                      move_request.attachment_list.splice(indexToRemove, 1);
                      // console.log(move_request.attachment_list)
                  }
              }
          }
        } 
      }
      this.setState({
          attachment_list: [...this.state.attachment_list]
      });
  }

    render(){
        return (
          <>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Confirm and Enter Move Request Information</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Equipment/Material To Move</InputLabel>
                        <TextField style={{backgroundColor:'white'}} name="unit_number" onChange={this.handleChange} fullWidth></TextField>
                      </Grid>
                      { readwriteAccess === true && <Grid item xs={grid_xs_width}>
                            <InputLabel>Requested By</InputLabel>
                            <Autocomplete disablePortal
                                autoHighlight
                                value={move_request.requested_By}
                                options={userList}
                                onChange={async (event: any, newValue: String | null) => {
                                    move_request.requested_By = newValue;
                                    this.setState({});
                                }}                                
                                renderInput={(params): JSX.Element => <TextField color="primary" multiline variant="outlined" {...params} style={{ backgroundColor: 'white' }}></TextField>}></Autocomplete>
                        </Grid> }
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>From</InputLabel>
                      <Autocomplete 
                       onChange={ async (event:any, newValue:String | null) => {
                           let splitted_job_no = newValue?.split(", Job #: ")[0];
                           move_request.from_location = splitted_job_no;
                           this.state.location_options.map((locations:any) => {
                            if(locations.site_Name === move_request.from_location){
                                move_request.from_location = locations;
                            }
                          });
                            var from_sub_site = await getSOCTWorkingSubSitesByParentID(move_request.from_location.id, true);
                            // console.log(from_sub_site)
                           from_sub_loc_options = from_sub_site.map((location:any) => {return location.sub_Site_Name});
                           from_sub_loc_details = from_sub_site.map((sub_location: any) => { return {sub_site_id: sub_location.id, sub_site_name: sub_location.sub_Site_Name, sub_site_latitude: sub_location.latitude, sub_site_longitude:sub_location.longitude} });
                           this.setState({});
                       }}
                        style={{backgroundColor:'white'}}    
                        options={working_site_locations}
                            disablePortal 
                            autoHighlight
                            renderInput={(params):JSX.Element => <TextField multiline color="primary" variant="outlined" name="location"{...params}></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>From Sub Location</InputLabel>
                        <Autocomplete 
                       onChange={ async(event:any, newValue:String | null) => {
                           move_request.from_sub_location = newValue;
                           let findSubSite = from_sub_loc_details.find(x=>x.sub_site_name === move_request.from_sub_location);
                           if(findSubSite) {  
                            move_request.from_location_latitude = findSubSite.sub_site_latitude;
                            move_request.from_location_longitude = findSubSite.sub_site_longitude;
                            move_request.from_sub_site_id = findSubSite.sub_site_id;
                            // console.log(move_request.from_location_latitude, move_request.from_location_longitude);
                            move_request.pickup_contact_name = await getCurrentSiteForeman(findSubSite.sub_site_id);
                            let findPickUpRecord = foremans.find(e=>e.display_Name===move_request.pickup_contact_name) ;
                            if(findPickUpRecord){
                              move_request.pickup_contact_upn = findPickUpRecord.upn
                            }
                        }
                           this.setState({});
                       }}
                        style={{backgroundColor:'white'}}    
                        options={from_sub_loc_options}
                            disablePortal 
                            autoHighlight
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params}></TextField>}></Autocomplete>
                            {move_request.from_location_latitude !== ''  && move_request.from_location_longitude !== '' && move_request.from_location_latitude !== null  && move_request.from_location_longitude !== null  && <LALinkButton
                                label="View in Google Maps"
                                onClick={() => this.handleGPSClick(move_request.from_location_latitude, move_request.from_location_longitude)}
                            />}
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>To</InputLabel>
                        <Autocomplete 
                       onChange={ async (event:any, newValue:String | null) => {
                          let splitted_job_no = newValue?.split(", Job #: ")[0];
                           move_request.to_location = splitted_job_no;
                           this.state.location_options.map((locations:any) => {
                            if(locations.site_Name === move_request.to_location){
                                move_request.to_location = locations;
                            }
                          });
                           var to_sub_site = await getSOCTWorkingSubSitesByParentID(move_request.to_location.id, true);
                           to_sub_loc_options = to_sub_site.map((location:any) => {return location.sub_Site_Name});
                           to_sub_loc_details = to_sub_site.map((sub_location: any) => { return {sub_site_id: sub_location.id, sub_site_name: sub_location.sub_Site_Name, sub_site_latitude: sub_location.latitude, sub_site_longitude:sub_location.longitude} });
                           this.setState({});
                       }}
                        style={{backgroundColor:'white'}}    
                        options={working_site_locations}
                            disablePortal 
                            autoHighlight
                            renderInput={(params):JSX.Element => <TextField multiline color="primary" variant="outlined" name="location"{...params}></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>To Sub Location</InputLabel>
                        <Autocomplete 
                       onChange={ async(event:any, newValue:String | null) => {
                           move_request.to_sub_location = newValue;
                           let findSubSite = to_sub_loc_details.find(x=>x.sub_site_name === move_request.to_sub_location);
                           if(findSubSite) {  
                            move_request.to_location_latitude = findSubSite.sub_site_latitude;
                            move_request.to_location_longitude = findSubSite.sub_site_longitude;
                            move_request.to_sub_site_id = findSubSite.sub_site_id;
                            // console.log(move_request.to_location_latitude, move_request.to_location_longitude);
                            move_request.dropoff_contact_name = await getCurrentSiteForeman(findSubSite.sub_site_id);
                            let findDropoffRecord = foremans.find(e=>e.display_Name===move_request.dropoff_contact_name) ;
                            if(findDropoffRecord){
                                move_request.dropoff_contact_upn = findDropoffRecord.upn
                            }
                        }
                           this.setState({});
                       }}
                        style={{backgroundColor:'white'}}    
                        options={to_sub_loc_options}
                            disablePortal 
                            autoHighlight
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params}></TextField>}></Autocomplete>
                            {move_request.to_location_latitude !== '' && move_request.to_location_longitude !== ''&& move_request.to_location_latitude !== null  && move_request.to_location_longitude !== null && <LALinkButton
                                label="View in Google Maps"
                                onClick={() => this.handleGPSClick(move_request.to_location_latitude, move_request.to_location_longitude)}
                            />}
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Completed By</InputLabel>
                        <TextField style={{backgroundColor:'white'}} type="date" name="completed_date" onChange={this.handleChange}></TextField>
                      </Grid>

                      <Grid item xs={grid_xs_width}>
                        <LALinkButton 
                            label="Assign to me"
                            onClick={this.handleAssignPickupContact}
                        />
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={foremans.map(e=> e.display_Name)} 
                            value={move_request.pickup_contact_name}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.pickup_contact_upn = '';
                              move_request.pickup_contact_name = newValue;
                              let findRecord = foremans.find(e=>e.display_Name===move_request.pickup_contact_name) ;
                              if(findRecord){
                                move_request.pickup_contact_upn = findRecord.upn
                              }
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="Pickup Contact"{...params} label="Pickup Contact"></TextField>}></Autocomplete>
                      </Grid>

                      <Grid item xs={grid_xs_width}>
                        <LALinkButton 
                            label="Assign to me"
                            onClick={this.handleAssignDropoffContact}
                        />
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={foremans.map(e=> e.display_Name)} 
                            value={move_request.dropoff_contact_name}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.dropoff_contact_upn = '';
                              move_request.dropoff_contact_name = newValue;
                              let findRecord = foremans.find(e=>e.display_Name===move_request.dropoff_contact_name) ;
                              if(findRecord){
                                move_request.dropoff_contact_upn = findRecord.upn
                              }
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="Dropoff Contact"{...params} label="Dropoff Contact"></TextField>}></Autocomplete>
                      </Grid>

                       {!disableEdit ? 
                       <>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={driver_options}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.driver_id = null;
                              if(newValue != null){
                                move_request.driver = newValue;
                                  let driver = move_request.driver.split("(")[0];
                                  temp_drivers.map((elem:any) => {
                                    let drivers = elem.driver_Firstname + " " + elem.driver_Lastname;
                                    if(drivers.trim() === driver.trim()){
                                      move_request.driver_id = elem.id;
                                    }
                                  });
                                }
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="driver"{...params} label="Driver"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={trailer_config_options} 
                            defaultValue={move_request.trailer_config}
                            onChange={(event:any, newValue:String | null) => 
                              {
                                  move_request.trailer_id = null;
                                  move_request.trailer_config = newValue;
                                  move_request.assigned_unit_number = "";
                                  assigned_unit_number_options = [];
                                  this.setState({});
                                  var trailer_ids = 0;
                                  temp_trailer_rates.map((elem:any) => {
                                    if(elem.description === move_request.trailer_config){
                                      trailer_ids = elem.id;
                                      move_request.trailer_config_id = trailer_ids;
                                    }
                                  });
                                  temp_trailers.map((elem:any) => {
                                    if(elem.trailer_Rate_ID === trailer_ids){
                                      assigned_unit_number_options.push(elem.unit_Number);
                                    }
                                  })
                                
                                this.setState({});
                              }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="trailer_config"{...params} label="Trailer Config"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={assigned_unit_number_options} 
                             value={move_request.assigned_unit_number}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.trailer_id = null;
                              let assigned_unit_number = newValue;
                              move_request.assigned_unit_number = assigned_unit_number;
                              temp_trailers.map((trailer:any) => {
                                if(trailer.unit_Number === assigned_unit_number){
                                  move_request.trailer_id = trailer.id;
                                }
                              });
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="assigned_unit_number"{...params} label="Trailer Unit #"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            options={assigned_tractor_options} 
                             value={move_request.assigned_tractor_unit}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.assigned_tractor_id = null;
                              move_request.assigned_tractor_unit = newValue;
                              temp_tractors.map((tractor:any) => {
                                if(tractor.unit_Number === move_request.assigned_tractor_unit){
                                  move_request.assigned_tractor_id = tractor.id;
                                }
                              });
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="assigned_tractor"{...params} label="Assigned Tractor"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField type="number" style={{backgroundColor:'white'}} onChange={this.handleChange} label="Hours" name="hours"></TextField>
                      </Grid>
                      </>
                      :null}
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Drive Over\Non Billable</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.drive_over} onChange={this.handleChange} name="drive_over"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>

                      <>
                        <Grid item xs={12}>
                            <Typography className='dataGrid-title'>Attachment List</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AttachmentListTable data={this.state.attachment_list} onAddAttachment={this.onAttachmentAdd} handletAttachmentChange={this.handleAttachmentTableChange} onDelete={this.onAttachmentDelete}></AttachmentListTable>
                        </Grid>
                      </>

                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Moved</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.completed} onChange={this.handleChange} name="completed"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Send Notification Email</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.send_email} onChange={this.handleChange} name="send_email"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField style={{backgroundColor:'white'}} fullWidth multiline rows={3} type="string" label="Request Details" name="comments" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <Button disabled={this.state.freezeSave} style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}}variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                    </Grid>
                    </>
        );
    }
}

interface IAttachmentListTable {
  data: any;
  handletAttachmentChange:(value:any, index?:number, x?:any | undefined) => void;
  onAddAttachment:()=>void;
  onDelete:(index:number, data:any)=>void;
};

const AttachmentListTable: React.FC<IAttachmentListTable> = React.memo((props: IAttachmentListTable) => (
  <TableContainer component={LAPaperWithLessPadding}>
      <LAIconButton
          label="Add New"
          icon={<AddIcon />}
          onClick={props.onAddAttachment} 
      /> 

      <Table size="small" aria-label="Attachment Items">
          <TableHead className="header-row">
            <TableRow>
                 <TableCell className="title">Attachment_No</TableCell>
                 <TableCell className="title">Type</TableCell>
                 <TableCell className="title">Coupler Design</TableCell>
                 <TableCell className="title">Style</TableCell>
                 <TableCell className="title">Location</TableCell>
                 <TableCell className="title">Attached Unit No</TableCell>
                 <TableCell className="title">Move Status</TableCell>
                 <TableCell className="title"></TableCell>
            </TableRow>
          </TableHead>

          {props.data.length > 0 ? 
              props.data.map((x: any, index: number) => {
                  // console.log(x);
                  const getAttachmentOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'attachment_No', props.data);
                  const getTypeOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'type',props.data);
                  const getCouplerDesignOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'coupler_Design', props.data);
                  const getStyleOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'style', props.data);
                  const onAttachmentNoChange = (value: any) => props.handletAttachmentChange( (value !== null) ? value : "", index, "attachment_No");
                  const onTypeChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "type");
                  const onCouplerDesignChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "coupler_Design");
                  const onStyleChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "style");
                  const onLocationChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.location : "", index , "location");
                  const onAttUnitNoChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.attachment_Unit_ID : "", index, "attached_Unit_No");
                  const onStatusChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.move_Status : "", index, "move_Status");
                  return <TableBody key={index}>
                      <TableRow>
                          <TableCell>
                              <DevExtremePopupLookup
                                    data={getAttachmentOptions}
                                    id="attachment-no"
                                    placeHolder="Attachment No"
                                    disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                    displayExp="attachment_No"
                                    name="attachment_No"
                                    errorName="attachment_No"
                                    errors={errors}
                                    currentVal={x.attachment_No }
                                    onClick={onAttachmentNoChange}
                                    columns={[
                                        { name: "attachment_No", caption: "Attachment No", type: "string" },
                                        { name: "type", caption: "Type", type: "string" },
                                        { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                        { name: "style", caption: "Style", type: "string" },
                                        { name: "location", caption: "Location", type: "string" },
                                        { name: "attached_Unit_No", caption: "Attached Unit No", type: "string" },
                                        { name: "move_Status", caption: "Move Status", type: "string" }
                                    ]}>
                              </DevExtremePopupLookup>
                          </TableCell>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={getTypeOptions}
                                  autoHighlight={true}
                                  getOptionLabel="type"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Type"
                                  name="type"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.type ? attachemnts.find((att: any) => att.type === x.type):null}
                                  onChange={onTypeChange}
                                  defaultValue={x.type ? attachemnts.find((att: any) => att.type === x.type):null}
                                  indexPosition={index}
                              />
                          </TableCell>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={getCouplerDesignOptions}
                                  autoHighlight={true}
                                  getOptionLabel="coupler_Design"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Coupler Design"
                                  name="coupler design"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.coupler_Design ? attachemnts.find((att: any) => att.coupler_Design === x.coupler_Design):null}
                                  onChange={onCouplerDesignChange}
                                  defaultValue={x.coupler_Design ? attachemnts.find((att: any) => att.coupler_Design === x.coupler_Design):null}
                                  indexPosition={index}
                              />
                          </TableCell>
                          <TableCell>
                                <LAAutoComplete
                                    multiple={false}
                                    option={getStyleOptions}
                                    autoHighlight={true}
                                    getOptionLabel="style"
                                    filterSelectedOptions={true}
                                    selectionRemove={undefinedFunction}
                                    dropDownPlaceHolder="Style"
                                    name="style"
                                    disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                    value={x.style ? attachemnts.find((att: any) => att.style === x.style):null}
                                    onChange={onStyleChange}
                                    defaultValue={x.style ? attachemnts.find((att: any) => att.style === x.style):null}
                                    indexPosition={index}
                                />
                            </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="location"
                                  label="Location"
                                  disabled={true}
                                  value={x.from_Sub_Location ? x.from_Sub_Location:''}
                                  onChange={onLocationChange}
                                  type="text"
                              />
                          </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="attached unit no"
                                  label="Attached Unit No"
                                  disabled={true}
                                  value={x.attached_Unit_No ? x.attached_Unit_No:''}
                                  onChange={onAttUnitNoChange}
                                  type="text"
                              />
                          </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="move_Status"
                                  label="Move Status"
                                  disabled={true}
                                  value={x.move_Status?x.move_Status:''}
                                  onChange={onStatusChange}
                                  type="text"
                              />
                              </TableCell>
                          <TableCell>
                              <LAIconButton
                                  // disabled={( x.type !== '' && x.coupler_Design !== '' && x.style !== '') ? undefined : true} 
                                  label="Delete"
                                  icon={<DeleteIcon />}
                                  onClick={(e:any)=>{props.onDelete(index, x)}} 
                              />
                          </TableCell>
                      </TableRow>
                  </TableBody>
              }) 
          : null}
      </Table>
  </TableContainer>
));

const filterDropDown = (data: any[], attachment_No: string | undefined, type: string| undefined, coupler_Design: string| undefined, style: string| undefined, forDropDown: string, state:any): any[]  => {
  let response: any[] = [];
  // console.log(data)
  // console.log(attachment_No, type, coupler_Design, style, forDropDown)
  response = data.filter(item => !state.some((stateItem:any) => stateItem.attachment_No === item.attachment_No));
  
  if (attachment_No && attachment_No.length > 0) {
      response = response.filter(x => x.attachment_No === attachment_No);
      // console.log(response);
  } if (coupler_Design && coupler_Design.length > 0) {
      response = response.filter(x => x.coupler_Design === coupler_Design);
      // console.log(response);
  } if (type && type.length > 0) {
      response = response.filter(x => x.type === type);
      // console.log(response);
  } if (style && style.length > 0) {
      response = response.filter(x => x.style === style);
      // console.log(response);
  } if (type && type.length > 0 && coupler_Design && coupler_Design.length > 0 && style && style.length > 0) {
      response = response.filter(x => x.type === type && x.coupler_Design === coupler_Design && x.style === style);
      // console.log(response);
  } 

  if (forDropDown === 'coupler_Design') {
      response = response.filter((v, i, a) => a.findIndex(t => t.coupler_Design === v.coupler_Design) === i);
      // console.log(response);
  } 
  else if (forDropDown === 'type')
  {
      response = response.filter((v, i, a) => a.findIndex(t => t.type === v.type) === i);
      // console.log(response);
  } 
  else if (forDropDown === 'style') 
  {
    response = response.filter((v, i, a) => a.findIndex(t => t.style === v.style) === i);
    // console.log(response);
  }
  else if (forDropDown === 'attachment_No')
  {
    response = response.filter((v, i, a) => a.findIndex(t => t.attachment_No === v.attachment_No) === i);
    response = response.filter(item => !state.some((stateItem:any) => stateItem.attachment_No === item.attachment_No));
    response = response.length > 0 ? response : data.filter(x => x.attachment_No === attachment_No);
    // console.log(response);
  }
  // else {
  //     response = data;
  //     console.log(response);
  // }

  return response;
};
