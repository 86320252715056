import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ICLONE_ATTACHMENT_GET_REQUEST, ICloneAttachmentGETRequest } from "./cloneAttachmentGETConstants";


export interface ICloneAttachmentGETLoadAction {
    type: ICLONE_ATTACHMENT_GET_REQUEST.REQUEST;
    data: ICloneAttachmentGETRequest
}
export const cloneAttachmentGETLoadAction = (data: ICloneAttachmentGETRequest): ICloneAttachmentGETLoadAction => ({
    type: ICLONE_ATTACHMENT_GET_REQUEST.REQUEST,
    data
});
export interface ICloneAttachmentGETSuccessAction {
    type: ICLONE_ATTACHMENT_GET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const cloneAttachmentGETLoadSuccessAction = (message: SurewayAPIResponse<string>): ICloneAttachmentGETSuccessAction => ({
    type: ICLONE_ATTACHMENT_GET_REQUEST.SUCCESS,
    message
});
export interface ICloneAttachmentGETLoadFailedAction {
    type: ICLONE_ATTACHMENT_GET_REQUEST.FAILED;
    message: string;
}
export const cloneAttachmentGETLoadFailedAction = (message: string): ICloneAttachmentGETLoadFailedAction => ({
    type: ICLONE_ATTACHMENT_GET_REQUEST.FAILED,
    message
});
