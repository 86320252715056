import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST, IUpdateRepairLineWOUnitRequest } from "./updateRepairLineWOUnitConstants";


export interface IUpdateRepairLineWOUnitLoadAction {
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.REQUEST;
    data: IUpdateRepairLineWOUnitRequest
}
export const updateRepairLineWOUnitLoadAction = (data: IUpdateRepairLineWOUnitRequest): IUpdateRepairLineWOUnitLoadAction => ({
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.REQUEST,
    data
});
export interface IUpdateRepairLineWOUnitSuccessAction {
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateRepairLineWOUnitLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateRepairLineWOUnitSuccessAction => ({
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.SUCCESS,
    message
});
export interface IUpdateRepairLineWOUnitLoadFailedAction {
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.FAILED;
    message: string;
}
export const updateRepairLineWOUnitLoadFailedAction = (message: string): IUpdateRepairLineWOUnitLoadFailedAction => ({
    type: IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.FAILED,
    message
});
