import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_SERVICE_SHEET_REPORT_REQUEST, IServiceSheetReport } from "./getServiceSheetReportConstants";
import { IGetServiceSheetReportLoadAction, IGetServiceSheetReportLoadFailedAction, IGetServiceSheetReportSuccessAction } from "./getServiceSheetReportActions";
import { IAPPROVE_SERVICE_SHEET_REQUEST } from "../approveServiceSheet/approveServiceSheetConstants";
import { IApproveServiceSheetSuccessAction } from "../approveServiceSheet/approveServiceSheetActions";
import { IUPDATE_SERVICE_SECTION_REQUEST } from "../updateServiceSection/updateServiceSectionConstants";
import { IADD_SERVICE_SECTION_REQUEST } from "../addServiceSection/addServiceSectionConstants";
import { IAddServiceSectionSuccessAction } from "../addServiceSection/addServiceSectionActions";
import { IUpdateServiceSectionSuccessAction } from "../updateServiceSection/updateServiceSectionActions";
import { IADD_SERVICE_DUE_REQUEST } from "../addServiceDue/addServiceDueConstants";
import { IUPDATE_SERVICE_DUE_REQUEST } from "../updateServiceDue/updateServiceDueConstants";
import { IAddServiceDueSuccessAction } from "../addServiceDue/addServiceDueActions";
import { IUpdateServiceDueSuccessAction } from "../updateServiceDue/updateServiceDueActions";

type Actions =
    | IGetServiceSheetReportLoadAction
    | IGetServiceSheetReportSuccessAction
    | IGetServiceSheetReportLoadFailedAction
    | IApproveServiceSheetSuccessAction
    | IAddServiceSectionSuccessAction
    | IUpdateServiceSectionSuccessAction
    | IAddServiceDueSuccessAction
    | IUpdateServiceDueSuccessAction
    | IFlushDataSuccessAction;

export const GetServiceSheetReportReducer = (state: Server<SurewayAPIResponse<ById<IServiceSheetReport>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IServiceSheetReport>>> => {
    switch (action.type) {
        case IGET_SERVICE_SHEET_REPORT_REQUEST.REQUEST:
            return loading;

        case IGET_SERVICE_SHEET_REPORT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SERVICE_SHEET_REPORT_REQUEST.FAILED:
            return failed(action.message);

        case IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};