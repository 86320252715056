import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SERVICE_SECTION_REQUEST, IAddServiceSectionRequest } from "./addServiceSectionConstants";


export interface IAddServiceSectionLoadAction {
    type: IADD_SERVICE_SECTION_REQUEST.REQUEST;
    data: IAddServiceSectionRequest
}
export const addServiceSectionLoadAction = (data: IAddServiceSectionRequest): IAddServiceSectionLoadAction => ({
    type: IADD_SERVICE_SECTION_REQUEST.REQUEST,
    data
});
export interface IAddServiceSectionSuccessAction {
    type: IADD_SERVICE_SECTION_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addServiceSectionLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddServiceSectionSuccessAction => ({
    type: IADD_SERVICE_SECTION_REQUEST.SUCCESS,
    message
});
export interface IAddServiceSectionLoadFailedAction {
    type: IADD_SERVICE_SECTION_REQUEST.FAILED;
    message: string;
}
export const addServiceSectionLoadFailedAction = (message: string): IAddServiceSectionLoadFailedAction => ({
    type: IADD_SERVICE_SECTION_REQUEST.FAILED,
    message
});
