import { IAddUpdateAttachmentMove } from "../addAttachmentMove/addAttachmentMoveConstants";

export enum IUPDATE_ATTACHMENT_MOVE_REQUEST {
    REQUEST = "updateAttachmentMove/UPDATE_ATTACHMENT_MOVE_REQUEST",
    SUCCESS = "updateAttachmentMove/UPDATE_ATTACHMENT_MOVE_SUCCESS",
    FAILED = "updateAttachmentMove/UPDATE_ATTACHMENT_MOVE_FAILED"
};

export interface IUpdateAttachmentMoveRequest {
    token: string;
    request: IAddUpdateAttachmentMove;
};