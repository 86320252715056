import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_TASK_REQUEST, IUpdateTaskRequest } from "./updateTaskConstants";


export interface IUpdateTaskLoadAction {
    type: IUPDATE_TASK_REQUEST.REQUEST;
    data: IUpdateTaskRequest
}
export const updateTaskLoadAction = (data: IUpdateTaskRequest): IUpdateTaskLoadAction => ({
    type: IUPDATE_TASK_REQUEST.REQUEST,
    data
});
export interface IUpdateTaskSuccessAction {
    type: IUPDATE_TASK_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateTaskLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateTaskSuccessAction => ({
    type: IUPDATE_TASK_REQUEST.SUCCESS,
    message
});
export interface IUpdateTaskLoadFailedAction {
    type: IUPDATE_TASK_REQUEST.FAILED;
    message: string;
}
export const updateTaskLoadFailedAction = (message: string): IUpdateTaskLoadFailedAction => ({
    type: IUPDATE_TASK_REQUEST.FAILED,
    message
});
