import { IAddUpdateAttachmentListGET } from "../addAttachmentListGET/addAttachmentListGETConstants";

export enum IUPDATE_ATTACHMENT_LIST_GET_REQUEST {
    REQUEST = "updateAttachmentListGET/UPDATE_ATTACHMENT_LIST_GET_REQUEST",
    SUCCESS = "updateAttachmentListGET/UPDATE_ATTACHMENT_LIST_GET_SUCCESS",
    FAILED = "updateAttachmentListGET/UPDATE_ATTACHMENT_LIST_GET_FAILED"
};

export interface IUpdateAttachmentListGETRequest {
    token: string;
    request: IAddUpdateAttachmentListGET;
};