import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_UTILIZATION_BY_ID_REQUEST, IGetUnitUtilizationByID } from "./getUnitUtilizationByIDConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetUnitUtilizationByIDLoadAction, IGetUnitUtilizationByIDLoadFailedAction, IGetUnitUtilizationByIDSuccessAction, 
    getUnitUtilizationByIDLoadFailedAction, getUnitUtilizationByIDLoadSuccessAction } from "./getUnitUtilizationByIDActions";

export const getUnitUtilizationByIDEpic: Epic = (
    action$: ActionsObservable<IGetUnitUtilizationByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitUtilizationByIDSuccessAction | IGetUnitUtilizationByIDLoadFailedAction> =>
    action$.ofType(IGET_UNIT_UTILIZATION_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetUnitUtilizationByID>>>(
                    END_POINTS.FIELD.GET_UNIT_UTILIZATION_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetUnitUtilizationByID>>): IGetUnitUtilizationByIDSuccessAction => {
                            return getUnitUtilizationByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitUtilizationByIDLoadFailedAction(response.message)))
                    )
            )
        );