import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IBULK_MOVE_REQUEST, IBulkMoveRequest } from "./bulkMoveConstants";

export interface IBulkMoveLoadAction {
    type: IBULK_MOVE_REQUEST.REQUEST;
    data: IBulkMoveRequest
}
export const bulkMoveLoadAction = (data: IBulkMoveRequest): IBulkMoveLoadAction => ({
    type: IBULK_MOVE_REQUEST.REQUEST,
    data
});
export interface IBulkMoveSuccessAction {
    type: IBULK_MOVE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const bulkMoveLoadSuccessAction = (message: SurewayAPIResponse<string>): IBulkMoveSuccessAction => ({
    type: IBULK_MOVE_REQUEST.SUCCESS,
    message
});
export interface IBulkMoveLoadFailedAction {
    type: IBULK_MOVE_REQUEST.FAILED;
    message: string;
}
export const bulkMoveLoadFailedAction = (message: string): IBulkMoveLoadFailedAction => ({
    type: IBULK_MOVE_REQUEST.FAILED,
    message
});
