import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_BASE_KIT_REQUEST, IAddUpdateBaseKitRequest } from "./addBaseKitConstants";

export interface IAddBaseKitLoadAction {
    type: IADD_BASE_KIT_REQUEST.REQUEST;
    data: IAddUpdateBaseKitRequest
}
export const addBaseKitLoadAction = (data: IAddUpdateBaseKitRequest): IAddBaseKitLoadAction => ({
    type: IADD_BASE_KIT_REQUEST.REQUEST,
    data
});
export interface IAddBaseKitSuccessAction {
    type: IADD_BASE_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addBaseKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddBaseKitSuccessAction => ({
    type: IADD_BASE_KIT_REQUEST.SUCCESS,
    list
});
export interface IAddBaseKitLoadFailedAction {
    type: IADD_BASE_KIT_REQUEST.FAILED;
    message: string;
}
export const addBaseKitLoadFailedAction = (message: string): IAddBaseKitLoadFailedAction => ({
    type: IADD_BASE_KIT_REQUEST.FAILED,
    message
});
