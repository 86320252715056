import React, { Component, ReactNode, Suspense } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Route, Switch } from "react-router";
import { IStore, IDispatch } from "../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../redux/server";
import { getTokenFromUrl, userName } from "./shared/constExports";
import styled from "styled-components";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import { LACenteredLoading } from "./shared/loading";
import { SurewayAPIResponse, ToolRentalsResponse } from "./shared/publicInterfaces";
import { IToken, ITokenRequest } from "../redux/getToken/getTokenConstants";
import { IValidateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "./routes";
import { validateShopGroup } from "../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { getToken } from "../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../redux/getToken/getTokenActions";
import { validateShopGroupLoadAction } from "../redux/toolRentals/validateShopGroup/validateShopGroupActions";
import Header from "./header/header";
import Error404Page, { AccessDeniedPage } from "./error404Page";
import UError401Page from "./error404Page";
import Home from "./home";
import Swampers from "./field/dispatch/swamper/swampers";
import AddUpdateSwamper from "./field/dispatch/swamper/swamper";
import DriversAssignment from "./field/dispatch/driversAssignment";
import ServiceTrucks from "./field/dispatch/serviceTruck/serviceTrucks";
import AddUpdateServiceTruck from "./field/dispatch/serviceTruck/serviceTruck";
import DriversAssignmentHistory from "./field/dispatch/driversAssignmentHistory";
import ShopManufacturers from "./field/manufacturer/manufacturers";
import ShopManufacturer from "./field/manufacturer/manufacturer";
import ShopEquipments from "./field/equipment/equipments";
import ShopEquipment from "./field/equipment/equipment";
import EquipmentTypes from "./field/equipmentType/equipmentTypes";
import EquipmentType from "./field/equipmentType/equipmentType";
import ConfigEquipmentType from "./field/config/adminConfig";
import JobsHome from "./accounting/jobs/jobs/jobsHome";
import AddUpdateJob from "./accounting/jobs/jobs/addUpdateJob";
import ShopModels from "./field/model/models";
import ShopModel from "./field/model/model";
import SOTC from "./field/soct/sotc";
import CreateSOTCPage from "./field/soct/forms/SOCTMain/create/createSOTCPage";
import EditSOTCForm from "./field/soct/forms/SOCTMain/edit/editSOTCPage";
import Meters from "./field/soct/meters";
import CreateMeterHistory from "./field/soct/forms/meters/createMeterHistory";
import EditMeterHistory from "./field/soct/forms/meters/editMeterHistory";
import Sites from "./field/soct/workingsites";
import CreateWorkingSite from "./field/soct/forms/workingsites/createWorkingSite";
import EditWorkingSite from "./field/soct/forms/workingsites/editWorkingSite";
import Moves from "./field/soct/moves";
import CreateEquipmentMoves from "./field/soct/forms/equipmentmoves/createEquipmentMoves";
import EditEquipmentMoves from "./field/soct/forms/equipmentmoves/editEquipmentMoves";
import ScheduledService from "./field/soct/scheduledservice";
import EditScheduledService from "./field/soct/forms/scheduledservice/editScheduledService";
import BulkSOTC from "./field/soct/bulksoctedit";
import MeterAppTable from "./field/soct/meterapptable";
import Trucks from "./field/equipmentTracker/trucks";
import MoveRequest from "./field/equipmentTracker/moveRequest";
import EquipmentMoveRequestPage from "./field/equipmentTracker/equipmentMovesPage";
import EquipmentMoveEdit from "./field/equipmentTracker/forms/equipmenttracker/equipmentMoveEdit";
import ETDrivers from "./field/equipmentTracker/driver/drivers";
import AddUpdateETDriver from "./field/equipmentTracker/driver/driver";
import TrailersPage from "./field/equipmentTracker/trailers";
import EditTrailersForm from "./field/equipmentTracker/forms/trailers/editTrailers";
import FilterReport from "./field/filter/filterReport";
import Locations from "./field/location/locations";
import AddUpdateLocation from "./field/location/location";
import BillingReportsPage from "./field/equipmentTracker/billingReportsPage";
import TrailerRatePage from "./field/equipmentTracker/trailerRatesPage";
import TruckingRequestEmailsPage from "./field/equipmentTracker/truckingRequestEmailsPage";
import EditTrailerRatesForm from "./field/equipmentTracker/forms/trailerrates/trailerRatesEdit";
import EditTruckingRequestEmails from "./field/equipmentTracker/forms/truckingrequestemails/truckingRequestEmailsEdit";
import ServiceDues from "./field/pmSheet/serviceDues"
import AddUpdateServiceDue from "./field/pmSheet/serviceDue";
import ServiceSections from "./field/pmSheet/serviceSections";
import AddUpdateServiceSection from "./field/pmSheet/serviceSection";
import ServiceSheetReport from "./field/pmSheet/serviceSheetReport";
import { MoveRequestVersionHistory } from "./field/equipmentTracker/forms/equipmenttracker/moveRequestVersionHistory";
import MoveHistoryReportPage from "./field/equipmentTracker/moveHistoryReportPage";
import ListViewPage from "./field/equipmentTracker/listViewPage";
import ListView from "./field/equipmentTracker/listView";
import UnitDetails from "./field/equipment/unitDetails";
import Mechanics from "./field/repairLine/mechanics";
import Mechanic from "./field/repairLine/mechanic";
import WorkingSiteLogs from "./field/workingSiteLogs/workingSiteLogs";
import CloseWindowPage from "./closeWindowPage";
import RepairLines from "./field/repairLine/repairLines";
import AddUpdateRepairLine from "./field/repairLine/repairLine";
import DriverDispatch from "./field/equipmentTracker/driverDispatch";
import AddUpdateWorkingSiteLog from "./field/workingSiteLogs/workingSiteLog";
import WorkingSiteLogsOnCall from "./field/workingSiteLogs/workingSiteLogsOnCall";
import EquipmentMoveForm from "./field/equipmentTracker/forms/equipmenttracker/equipmentMoveRequest";
import AddEquipmentMoveForm from "./field/equipmentTracker/forms/equipmenttracker/equipmentMoveRequestAdd";
import FilterCuts from "./field/filterCut/filterCuts";
import AddUpdateFilterCut from "./field/filterCut/filterCut";
import MechanicLogs from "./field/repairLine/mechanicLogs";
import ExternalUsers from "./externalUser/externalUsers";
import AddUpdateExternalUser from "./externalUser/externalUser";
import ComponentWaitingParts from "./field/repairLine/parts";
import MechanicDispatch from "./field/repairLine/mechanicsAssignment";
import SoctReport from "./field/soct/soctReport";
import Attachments from "./field/attachment/attachments";
import AddUpdateAttachment from "./field/attachment/attachment";
import AttachmentTypes from "./field/attachment/attachmentTypes";
import AttachmentType from "./field/attachment/attachmentType";
import AttachmentCouplers from "./field/attachment/attachmentCouplers";
import AttachmentCoupler from "./field/attachment/attachmentCoupler";
import AttachmentMoveRequests from "./field/attachment/attachmentMoveRequests";
import AddUpdateAttachmentMove from "./field/attachment/attachmentMoveRequest";
import KantechHome from "./sil/kantechHome";
import GPSRecord from "./shared/gpsRecord";
import KantechAdjustments from "./sil/kantech/kantechAdjustments";
import AttachmentListGET from "./field/attachment/attachmentListGET";
import AttachmentStyles from "./field/attachment/attachmentStyles";
import AddUpdateKantechAdjustment from "./sil/kantech/kantechAdjustment";
import AddUpdateAttachmentStyle from "./field/attachment/attachmentStyle";
import AttachmentMoveRequestHistory from "./field/attachment/attachmentMoveRequestHistory";
import ImageGallery from "./field/imageGallery/imageGallery";
import BulkMoves from "./field/equipmentTracker/forms/bulkmoves/bulkMoves";
import WorkOrder from "./field/repairLine/workOrder";
import RepairLineWOUnits from "./field/repairLine/workOrderUnits";
import AddUpdateRepairLineWOUnit from "./field/repairLine/workOrderUnit";
import RepairLineSmsLogs from "./field/repairLine/repairLineSmsLogs";
import EquipmentHistory from "./field/equipment/equipmentHistory";
import FieldEmployees from "./field/workingSiteLogs/fieldEmployees";
import FieldEmployee from "./field/workingSiteLogs/fieldEmployee";
import Messagings from "./field/workingSiteLogs/messagings";
import Messaging from "./field/workingSiteLogs/messaging";
import LocationView from "./field/attachment/locationView";
import PMSheetVersionHistory from "./field/pmSheet/pmServiceSheetVersionHistory";
import PMSheetDetailsVersionHistory from "./field/pmSheet/pmServiceSheetDetailsVersionHistory";
import PayrollMechanicLogs from "./field/repairLine/payrollMechanicLogs";
import PayrollMechanicLogsReport from "./field/repairLine/payrollMechanicLogsReport";
import PayrollMechanicLogsSummary from "./field/repairLine/payrollMechanicLogsSummary";
import BulkMeterAdjustment from "./field/soct/bulkMeterAdjustment/bulkMeterAdjustment";
import AddUpdateBulkMeterAdjustment from "./field/soct/bulkMeterAdjustment/AddUpdateBulkMeterAdjustment";
import ApproveMechanicLogs from "./field/repairLine/approveMechanicLogs";
import MechanicLogsForDefectItem from "./field/repairLine/mechanicLogsForDefectItem";
import ViewMechanicLogs from "./field/repairLine/viewMechanicLogs";
import SoctList from "./field/soct/soct_main/soctList";
import AddUpdateSOCT from "./field/soct/soct_main/addUpdateSOCT";
import MeterHistory from "./field/soct/meterHistory/meterHistory";
import AddUpdateMeterReading from "./field/soct/meterHistory/AddUpdateMeterReading";
import ServiceHistory from "./field/soct/serviceHistory/serviceHistory";
import AddUpdateServiceHistory from "./field/soct/serviceHistory/AddUpdateServiceHistory";
import TechServices from "./field/repairLine/techService/techServices";
import AddUpdateTechService from "./field/repairLine/techService/techService";
import EquipmentManuals from "./field/equipment/equipmentManual/equipmentManuals";
import EquipmentManual from "./field/equipment/equipmentManual/equipmentManual";
import FormsComponent from "./field/forms/forms";
import AddUpdateForms from "./field/forms/form";
import FormResponsesComponent from "./field/forms/formResponses/formResponses";
import AddUpdateFormResponse from "./field/forms/formResponses/formResponse";
import CrewsComponent from "./field/forms/crews/crews";
import AddUpdateCrew from "./field/forms/crews/crew";
import TasksComponent from "./field/forms/tasks/tasks";
import AddUpdateTask from "./field/forms/tasks/task";
import HazardTypesComponent from "./field/forms/hazardTypes/hazardTypes";
import AddUpdateHazardType from "./field/forms/hazardTypes/hazardType";
import ControlPlansComponent from "./field/forms/controlPlans/controlPlans";
// import AddUpdateControlPlan from "./field/forms/controlPlans/controlPlan";
import AddUpdateControlPlan from "./field/forms/controlPlans/controlPlanNew";

// import HazardsComponent from "./field/forms/hazards/hazards";
// import AddUpdateHazard from "./field/forms/hazards/hazard";
import VendorOrders from "./field/vendor/vendorOrders";
import AddUpdateVendorOrder from "./field/vendor/vendorOrder";

import HazardsNewComponent from "./field/forms/hazards/hazards";
import AddUpdateHazardNew from "./field/forms/hazards/hazard";
import MedicalFacilitiesComponent from "./field/medicalFacilities/medicalFacilities";
import AddUpdateMedicalFacility from "./field/medicalFacilities/AddUpdateMedicalFacility";
import ProtectedPDFPage from "./ProtectedPDFPage";
import SurveyHome from "./surveyHome";
import MachineControlComponents from "./field/survey/machineControls";
import AddUpdateMachineControl from "./field/survey/machineControl";
import MachineControlBoxesComponents from "./field/survey/machineControlBoxes";
import AddUpdateMachineControlBox from "./field/survey/machineControlBox";
import MachineControlReceivers from "./field/survey/machineControlReceivers";
import AddUpdateMachineControlReceiver from "./field/survey/machineControlReceiver";
import DepartmentsComponent from "./field/forms/departments/departments";
import AddUpdateDepartment from "./field/forms/departments/department";
import PayrollContractorsMechanicLogs from "./field/repairLine/payrollContractorsMechanicLogs";
import PayrollContractorsMechanicLogsReport from "./field/repairLine/payrollContractorsMechanicLogsReport";
import PayrollContractorsMechanicLogsSummary from "./field/repairLine/payrollContractorsMechanicLogsSummary";
import BaseKitsComponents from "./field/survey/baseKits";
import RoverKitsComponents from "./field/survey/roverKits";
import RepeaterKitsComponents from "./field/survey/repeaterKits";
import AttachmentsComponents from "./field/survey/attachments";
import AddUpdateAttachmentMC from "./field/survey/attachment";
import BaseKitComponents from "./field/survey/baseKit";
import RoverKitComponents from "./field/survey/roverKit";
import RepeaterKitComponents from "./field/survey/repeaterKit";
import LaserComponents from "./field/survey/laser";
import LasersComponents from "./field/survey/lasers";
import LevelComponents from "./field/survey/level";
import LevelsComponents from "./field/survey/levels";
import InspectionSheets from "./field/repairLine/inspectionForm/inspectionForms";
import AddUpdateInspectionForm from "./field/repairLine/inspectionForm/inspectionForm";
import VendorLogs from "./field/vendor/vendorLogs";
import AddUpdateVendorPOLogs from "./field/vendor/vendorLog";
import Journels from "./field/workingSiteLogs/journels";
import AddUpdateJournel from "./field/workingSiteLogs/journel";
import FormResponseReadOnly from "./field/forms/formResponses/formResponseReadOnly";
import FormCleanupComponent from "./field/forms/cleanup/cleanup";
import ApproveSTDriverLogs from "./field/dispatch/approveSTDriverLogs";
import ViewSTDriverLogs from "./field/dispatch/viewSTDriverLogs";


interface ISecuredRoutesStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface ISecuredRoutesDispatchProps {
    getTokenRequest: (data: ITokenRequest) => unknown;
    RequestValidateShopGroup: (request: ITokenRequest) => unknown;
};

interface ISecuredRoutesState {
    showHeader: boolean;
    loading: boolean;
};

const SecuredRoutesStyles = styled.div`
    
    .mobileView {
        display: none;
    };

    .desktopView {
        display: block;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .mobileView {
            display: block;
        };

        .desktopView {
            display: none;
        };
    };
    
`;

type ISecuredRoutesProps =
    RouteComponentProps &
    ISecuredRoutesDispatchProps &
    ISecuredRoutesStoreProps;

class SecuredRoutes extends Component<ISecuredRoutesProps, ISecuredRoutesState> {

    public constructor(props: ISecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: true,
            loading: true
        };
    }

    public componentDidMount(): void {
        this.authorizationCheck();
    }

    public componentDidUpdate(prevProps: ISecuredRoutesProps): void {

        if ((this.props.token !== prevProps.token)) {

            if (this.props.token.kind === STATUS_ENUM.FAILED) {
                this.props.history.push(ROUTE.UNAUTHORIZED);
            };

            this.authorizationCheck();
            this.requestValidateShopGroup();
        };
    }

    public render(): ReactNode {

        const { token, validateShopGroup } = this.props;

        return (
            this.state.loading ? <LACenteredLoading message="Loading..." /> :

                <SecuredRoutesStyles>
                    <Suspense fallback={<div>Loading...</div>}>
                        {this.state.showHeader && <Header
                            {...this.props}
                            validateGroup={validateShopGroup}
                            userName={hasPayload(token) ? token.payload.response.upn : ""}
                            webserver={hasPayload(token) ? token.payload.webserver : ""}
                        />}

                        <Switch>
                            <Route exact path={"/secured_pdfs"} component={ProtectedPDFPage} />
                            <Route exact path={ROUTE.SECURE_PDF} component={ProtectedPDFPage} />
                            
                            <Route exact={true} path={ROUTE.INDEX} component={Home} />
                            <Route exact={true} path={ROUTE.FIELD.INDEX} component={JobsHome} />
                            <Route exact={true} path={ROUTE.FIELD.JOBS.INDEX} component={JobsHome} />
                            <Route exact={true} path={ROUTE.FIELD.JOBS.JOB()} component={AddUpdateJob} />

                            <Route path={ROUTE.EXTERNAL_USER.EXTERNAL_USERS} component={ExternalUsers} />
                            <Route path={ROUTE.EXTERNAL_USER.EXTERNAL_USER} component={AddUpdateExternalUser} />

                            <Route path={ROUTE.FIELD.GPS_RECORD} component={GPSRecord} />
                            <Route path={ROUTE.FIELD.MANUFACTURER.INDEX} component={ShopManufacturers} />
                            <Route path={ROUTE.FIELD.MANUFACTURER.MANUFACTURER} component={ShopManufacturer} />
                            <Route path={ROUTE.FIELD.MODEL.INDEX} component={ShopModels} />
                            <Route path={ROUTE.FIELD.MODEL.MODEL} component={ShopModel} />
                            <Route path={ROUTE.FIELD.EQUIPMENT.INDEX} component={ShopEquipments} />
                            <Route path={ROUTE.FIELD.EQUIPMENT.EQUIPMENT} component={ShopEquipment} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TYPE.INDEX} component={EquipmentTypes} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TYPE.TYPE} component={EquipmentType} />
                            <Route path={ROUTE.FIELD.FILTER.FILTER_REPORT} component={FilterReport} />
                            <Route path={ROUTE.FIELD.LOCATION.INDEX} component={Locations} />
                            <Route path={ROUTE.FIELD.LOCATION.LOCATION} component={AddUpdateLocation} />
                            <Route exact path={ROUTE.FIELD.MEDICAL_FACILITIES.INDEX} component={MedicalFacilitiesComponent} />
                            <Route exact path={ROUTE.FIELD.MEDICAL_FACILITIES.ADD_UPDATE_MEDICAL_FACILITY} component={AddUpdateMedicalFacility} />
                            <Route path={ROUTE.FIELD.CONFIG.INDEX} component={ConfigEquipmentType} />
                            <Route exact path={ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX} component={EquipmentManuals} />
                            <Route exact path={ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.ADDUPDATE_EQUIPMENT_MANUAL} component={EquipmentManual} />

                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.INDEX} component={WorkingSiteLogs} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOGS} component={WorkingSiteLogs} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOG} component={AddUpdateWorkingSiteLog} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOGS_ON_CALL} component={WorkingSiteLogsOnCall} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEES} component={FieldEmployees} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEE} component={FieldEmployee} />
                            <Route exact path={ROUTE.FIELD.WORKING_SITE_LOGS.CREWS} component={CrewsComponent}/>
                            <Route exact path={ROUTE.FIELD.WORKING_SITE_LOGS.ADD_UPDATE_CREW} component={AddUpdateCrew}/>
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGINGS} component={Messagings} />
                            <Route path={ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGING} component={Messaging} />
                            <Route exact path={ROUTE.FIELD.WORKING_SITE_LOGS.JOURNALS} component={Journels} />
                            <Route exact path={ROUTE.FIELD.WORKING_SITE_LOGS.ADD_UPDATE_JOURNAL} component={AddUpdateJournel} />

                            <Route path={ROUTE.FIELD.REPAIR_LINE.INDEX} component={RepairLines} />
                            <Route path={ROUTE.FIELD.EQUIPMENT.UNIT_DETAILS} component={UnitDetails} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES} component={RepairLines} />
                            <Route exact path={ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE} component={AddUpdateRepairLine} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANICS} component={Mechanics} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC} component={Mechanic} />
                            {/* <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS} component={MechanicLogs} /> */}
                            <Route path={ROUTE.FIELD.REPAIR_LINE.APPROVED_MECHANIC_LOGS} component={ApproveMechanicLogs} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL} component={PayrollMechanicLogs} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_REPORT} component={PayrollMechanicLogsReport} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_SUMMARY} component={PayrollMechanicLogsSummary} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS} component={PayrollContractorsMechanicLogs} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS_REPORT} component={PayrollContractorsMechanicLogsReport} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS_SUMMARY} component={PayrollContractorsMechanicLogsSummary} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.PARTS} component={ComponentWaitingParts} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_ASSIGNMENT} component={MechanicDispatch} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.WORK_ORDER} component={WorkOrder} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS} component={RepairLineWOUnits} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNIT} component={AddUpdateRepairLineWOUnit} />
                            <Route path={ROUTE.FIELD.REPAIR_LINE.SMS_LOGS} component={RepairLineSmsLogs} />
                            <Route exact path={ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_BY_DEFECT_ID} component={MechanicLogsForDefectItem} />
                            <Route exact path={ROUTE.FIELD.REPAIR_LINE.VIEW_MECHANIC_LOGS} component={ViewMechanicLogs} />

                            <Route exact path={ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES} component={TechServices} />
                            <Route exact path={ROUTE.FIELD.TECH_SERVICE.TECH_SERVICE} component={AddUpdateTechService} />
                            <Route exact path={ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS} component={InspectionSheets} />
                            <Route exact path={ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORM} component={AddUpdateInspectionForm} />

                            <Route path={ROUTE.FIELD.FILTER_CUT.FILTER_CUTS} component={FilterCuts} />
                            <Route path={ROUTE.FIELD.FILTER_CUT.FILTER_CUT} component={AddUpdateFilterCut} />

                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.LOCATION_VIEW} component={LocationView} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.INDEX} component={Attachments} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENTS} component={Attachments} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT} component={AddUpdateAttachment} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.TYPES} component={AttachmentTypes} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.TYPE} component={AttachmentType} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.COUPLERS} component={AttachmentCouplers} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.COUPLER} component={AttachmentCoupler} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.MOVE_REQUESTS} component={AttachmentMoveRequests} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.MOVE_REQUEST} component={AddUpdateAttachmentMove} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.STYLES} component={AttachmentStyles} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.STYLE} component={AddUpdateAttachmentStyle} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.MOVE_REQUEST_HISTORY} component={AttachmentMoveRequestHistory} />
                            <Route path={ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT_LIST_GET} component={AttachmentListGET} />

                            <Route path={ROUTE.FIELD.PM_SHEETS.INDEX} component={ServiceSheetReport} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.SERVICE_DUES} component={ServiceDues} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.SERVICE_DUE} component={AddUpdateServiceDue} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.SERVICE_SECTIONS} component={ServiceSections} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.SERVICE_SECTION} component={AddUpdateServiceSection} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT} component={ServiceSheetReport} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.PM_SHEET_VERSION_HISTORY} component={PMSheetVersionHistory} />
                            <Route path={ROUTE.FIELD.PM_SHEETS.PM_SHEET_DETAILS_VERSION_HISTORY} component={PMSheetDetailsVersionHistory} />

                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.INDEX} component={DriversAssignment} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT} component={DriversAssignment} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.SWAMPERS} component={Swampers} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.SWAMPER} component={AddUpdateSwamper} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.SERVICE_TRUCKS} component={ServiceTrucks} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.SERVICE_TRUCK} component={AddUpdateServiceTruck} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT_HISTORY} component={DriversAssignmentHistory} />
                            <Route exact={true} path={ROUTE.FIELD.DISPATCH.APPROVE_DRIVER_LOGS} component={ApproveSTDriverLogs} />
                            <Route exact path={ROUTE.FIELD.DISPATCH.VIEW_DRIVER_LOGS} component={ViewSTDriverLogs} />

                            <Route exact={true} path={ROUTE.FIELD.VENDOR.INDEX} component={VendorOrders} />
                            <Route exact={true} path={ROUTE.FIELD.VENDOR.VENDOR_ORDER} component={AddUpdateVendorOrder} />
                            <Route exact={true} path={ROUTE.FIELD.VENDOR.VENDOR_LOGS} component={VendorLogs} />
                            <Route exact={true} path={ROUTE.FIELD.VENDOR.VENDOR_LOG} component={AddUpdateVendorPOLogs} />

                            <Route exact={true} path={ROUTE.SIL.KANTECH.INDEX} component={KantechHome} />
                            <Route exact={true} path={ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS} component={KantechAdjustments} />
                            <Route exact={true} path={ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENT} component={AddUpdateKantechAdjustment} />
                            <Route exact path={ROUTE.FIELD.SOCT.INDEX} component={SOTC}/>
                            <Route exact={true} path={ROUTE.FIELD.SOCT.SOCT_REPORT} component={SoctReport} />
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.LISTCREATE} component={CreateSOTCPage}/>
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.LISTEDIT} component={EditSOTCForm}/>
                            <Route exact path={ROUTE.FIELD.SOCT.METERS.INDEX} component={Meters}/>
                            <Route path={ROUTE.FIELD.SOCT.METERS.METERSCREATE} component={CreateMeterHistory}/>
                            <Route path={ROUTE.FIELD.SOCT.METERS.METERSEDIT} component={EditMeterHistory}/>
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.METERSTABLEEDIT} component={EditSOTCForm}/>
                            <Route exact path={ROUTE.FIELD.SOCT.SERVICES.INDEX} component={ScheduledService}/>
                            <Route path={ROUTE.FIELD.SOCT.SERVICES.SCHEDULEDSERVICEEDIT} component={EditScheduledService}/>
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.SERVICETABLEEDIT} component={EditSOTCForm}/>
                            <Route exact path={ROUTE.FIELD.SOCT.EQUIPMENT_MOVES.INDEX} component={Moves}/>
                            <Route path={ROUTE.FIELD.SOCT.EQUIPMENT_MOVES.MOVESCREATE} component={CreateEquipmentMoves}/>
                            <Route path={ROUTE.FIELD.SOCT.EQUIPMENT_MOVES.MOVESEDIT} component={EditEquipmentMoves}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY} component={EquipmentHistory} />
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.EQUIPMENTMOVESTABLEEDIT} component={EditSOTCForm}/>
                            <Route path={ROUTE.FIELD.SOCT.SOCT_MAIN.REPORTSTABLEEDIT} component={EditSOTCForm}/>
                            <Route exact path={ROUTE.FIELD.SOCT.WORKING_SITES.INDEX} component={Sites}/>
                            <Route path={ROUTE.FIELD.SOCT.WORKING_SITES.SITESCREATE} component={CreateWorkingSite}/>
                            <Route path={ROUTE.FIELD.SOCT.WORKING_SITES.SITESEDIT} component={EditWorkingSite}/>
                            <Route path={ROUTE.FIELD.SOCT.BULKSOCT.INDEX} component={BulkSOTC}/>
                            <Route path={ROUTE.FIELD.SOCT.METERAPP.INDEX} component={MeterAppTable}/>
                            <Route exact path={ROUTE.FIELD.SOCT.BULK_METER_ADJUSTMENT.INDEX} component={BulkMeterAdjustment}/>
                            <Route path={ROUTE.FIELD.SOCT.BULK_METER_ADJUSTMENT.ADD_UPDATE_BULK_METER_READING} component={AddUpdateBulkMeterAdjustment}/>
                            <Route exact={true} path={ROUTE.FIELD.SOCT.SOCT_LIST} component={SoctList} />
                            <Route exact path={ROUTE.FIELD.SOCT.ADD_UPDATE_SOCT} component={AddUpdateSOCT}/>
                            <Route exact path={ROUTE.FIELD.SOCT.METER_HISTORY.INDEX} component={MeterHistory}/>
                            <Route exact path={ROUTE.FIELD.SOCT.METER_HISTORY.ADD_UPDATE_METER_HISTORY} component={AddUpdateMeterReading}/>
                            <Route exact path={ROUTE.FIELD.SOCT.SERVICE_HISTORY.INDEX} component={ServiceHistory}/>
                            <Route exact path={ROUTE.FIELD.SOCT.SERVICE_HISTORY.ADD_UPDATE_SERVICE_HISTORY} component={AddUpdateServiceHistory}/>

                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.INDEX} component={Trucks}/>
                            <Route exact path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST} component={EquipmentMoveRequestPage} />
                            <Route exact path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVE_REQUEST} component={MoveRequest} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTEDIT} component={EquipmentMoveEdit}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.INDEX} component={ETDrivers} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.DRIVER} component={AddUpdateETDriver} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERS.INDEX} component={TrailersPage}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERS.TRAILEREDIT} component={EditTrailersForm}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERRATES.INDEX} component={TrailerRatePage}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERRATES.TRAILERRATESEDIT} component={EditTrailerRatesForm}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.BILLING.INDEX} component={BillingReportsPage}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKINGREQUESTEMAILS.INDEX} component={TruckingRequestEmailsPage}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKINGREQUESTEMAILS.TRUCKINGREQUESTEMAILSEDIT} component={EditTruckingRequestEmails}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.VERSION_HISTORY.MOVE_REQUESTS} component={MoveRequestVersionHistory}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.MOVE_HISTORY_REPORT.INDEX} component={MoveHistoryReportPage}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.LISTVIEW} component={ListViewPage} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.LIST_VIEW} component={ListView} />
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTADD} component={EquipmentMoveForm}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.DISPATCH} component={DriverDispatch}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTADDNEW} component={AddEquipmentMoveForm}/>
                            <Route path={ROUTE.FIELD.EQUIPMENT_TRACKER.BULKMOVES.INDEX} component={BulkMoves}/>

                            <Route path={ROUTE.FIELD.IMAGE_GALLERY.INDEX} component={ImageGallery}/>

                            <Route exact path={ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX} component={FormsComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.FORM_TEMPLATE.ADD_UPDATE_FORM_TEMPLATE} component={AddUpdateForms}/>
                            <Route exact path={ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX} component={FormResponsesComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.FORM_RESPONSE.FORM_RESPONSE_READ_ONLY} component={FormResponseReadOnly}/>
                            <Route exact path={ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE} component={AddUpdateFormResponse}/>
                            {/* <Route exact path={ROUTE.FIELD.FORMS.CREW.INDEX} component={CrewsComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.CREW.ADD_UPDATE_CREW} component={AddUpdateCrew}/> */}
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.TASKS.INDEX} component={TasksComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.TASKS.ADD_UPDATE_TASK} component={AddUpdateTask}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.INDEX} component={HazardTypesComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.ADD_UPDATE_HAZARD_TYPE} component={AddUpdateHazardType}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.CONTROL_PLANS.INDEX} component={ControlPlansComponent}/>
                            {/* <Route exact path={ROUTE.FIELD.FORMS.ADMIN.CONTROL_PLANS.ADD_UPDATE_CONTROL_PLAN} component={AddUpdateControlPlan}/> */}
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.CONTROL_PLANS.ADD_UPDATE_CONTROL_PLAN} component={AddUpdateControlPlan}/>
                            {/* <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARDS.INDEX} component={HazardsComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARDS.ADD_UPDATE_HAZRAD} component={AddUpdateHazard}/> */}
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARDS.INDEX} component={HazardsNewComponent} />
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.HAZARDS.ADD_UPDATE_HAZRAD} component={AddUpdateHazardNew} />
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.DEPARTMENTS.INDEX} component={DepartmentsComponent}/>
                            <Route exact path={ROUTE.FIELD.FORMS.ADMIN.DEPARTMENTS.ADD_UPDATE_DEPARTMENT} component={AddUpdateDepartment}/>
                            <Route exact path={ROUTE.FIELD.FORMS.CLEAN_UP.INDEX} component={FormCleanupComponent}/>

                            <Route exact path={ROUTE.FIELD.SURVEY.INDEX} component={SurveyHome}/>
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.INDEX} component={MachineControlComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL} component={AddUpdateMachineControl}/>
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.MACHINE_CONTROL_RECEIVERS} component={MachineControlReceivers} />
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL_RECEIVER} component={AddUpdateMachineControlReceiver} />
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.MACHINE_CONTROL_BOXES} component={MachineControlBoxesComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL_BOX} component={AddUpdateMachineControlBox} />
                            <Route exact path={ROUTE.FIELD.SURVEY.ATTACHMENTS.INDEX} component={AttachmentsComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.ATTACHMENTS.ADD_UPDATE_ATTACHMENT} component={AddUpdateAttachmentMC} />
                            <Route exact path={ROUTE.FIELD.SURVEY.BASE_KITS.INDEX} component={BaseKitsComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.BASE_KITS.ADD_UPDATE_BASE_KITS} component={BaseKitComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.ROVER_KITS.INDEX} component={RoverKitsComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.ROVER_KITS.ADD_UPDATE_ROVER_KITS} component={RoverKitComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.REPEATER_KITS.INDEX} component={RepeaterKitsComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.REPEATER_KITS.ADD_UPDATE_REPEATER_KITS} component={RepeaterKitComponents} />

                            <Route exact path={ROUTE.FIELD.SURVEY.LASERS.INDEX} component={LasersComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.LASERS.ADD_UPDATE_LASERS} component={LaserComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.LEVELS.INDEX} component={LevelsComponents} />
                            <Route exact path={ROUTE.FIELD.SURVEY.LEVELS.ADD_UPDATE_LEVELS} component={LevelComponents} />

                            <Route exact path={ROUTE.CLOSEWINDOW} render={(): ReactNode => <CloseWindowPage handleShowHeader={this.handleShowHeader} />} />

                            <Route exact path={ROUTE.ACCESS_DENIED} render={(): ReactNode => <AccessDeniedPage handleShowHeader={this.handleShowHeader} />} />
                            <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                            <Route path={ROUTE.UNAUTHORIZED} render={(): ReactNode => <UError401Page handleShowHeader={this.handleShowHeader} />} />
                            <Route path="*" exact={true} render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />

                        </Switch>
                    </Suspense>
                </SecuredRoutesStyles>
        );

    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };

    private authorizationCheck = (): void => {
        switch (this.props.token.kind) {
            case STATUS_ENUM.SUCCEEDED:
                if (this.props.token.payload.message !== "Success" && this.props.token.payload.response === null) {
                    if (this.state.loading === true) {
                        this.setState({ loading: false });
                    } else {
                        this.props.history.push(ROUTE.UNAUTHORIZED);
                    }
                } else {
                    if (this.state.loading)
                        this.setState({ loading: false });
                }
                break;

            case STATUS_ENUM.NOT_LOADED:
                this.getToken();
                break;

            case STATUS_ENUM.FAILED:
                if (this.state.loading === true) {
                    this.setState({ loading: false });
                } else {
                    this.props.history.push(ROUTE.UNAUTHORIZED);
                }
                break;

            case STATUS_ENUM.LOADING:
                if (this.state.loading === false) {
                    this.setState({ loading: true });
                }
                break;

            default:
                break;
        }
    };

    private getToken = (): void => {
        if (isNotLoaded(this.props.token))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });
    };

    private requestValidateShopGroup = (): void => {
        const data = this.props.token;
        if (isNotLoaded(this.props.validateShopGroup) && hasPayload(data)) {
            this.props.RequestValidateShopGroup({ request: { username: data.payload.response.upn }, token: data.payload.response.token });
        }
    };
}

const mapStateToProps = (state: IStore): ISecuredRoutesStoreProps => ({
    token: getToken(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISecuredRoutesDispatchProps => ({
    getTokenRequest: (data: ITokenRequest) => dispatch(getTokenLoadAction(data)),
    RequestValidateShopGroup: (request: ITokenRequest): unknown => dispatch(validateShopGroupLoadAction(request))
});


export default connect(mapStateToProps, mapDispatchToProps)(SecuredRoutes);