import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PM_SHEET_VERSION_HISTORY_REQUEST, IGetPMSheetVersionHistory, IGetPMSheetVersionHistoryRequest } from "./getPMSheetVersionHistoryConstants";

export interface IGetPMSheetVersionHistoryLoadAction {
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetPMSheetVersionHistoryRequest
}
export const getPMSheetVersionHistoryLoadAction = (data: IGetPMSheetVersionHistoryRequest): IGetPMSheetVersionHistoryLoadAction => ({
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetPMSheetVersionHistorySuccessAction {
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>;
}
export const getPMSheetVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>): IGetPMSheetVersionHistorySuccessAction => ({
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetPMSheetVersionHistoryLoadFailedAction {
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getPMSheetVersionHistoryLoadFailedAction = (message: string): IGetPMSheetVersionHistoryLoadFailedAction => ({
    type: IGET_PM_SHEET_VERSION_HISTORY_REQUEST.FAILED,
    message
});
