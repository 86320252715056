import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_JOB_MHR_REQUEST, IAddJobMhrRequest } from "./addJobMhrConstants";


export interface IAddJobMhrLoadAction {
    type: IADD_JOB_MHR_REQUEST.REQUEST;
    data: IAddJobMhrRequest
}
export const addJobMhrLoadAction = (data: IAddJobMhrRequest): IAddJobMhrLoadAction => ({
    type: IADD_JOB_MHR_REQUEST.REQUEST,
    data
});
export interface IAddJobMhrSuccessAction {
    type: IADD_JOB_MHR_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addJobMhrLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddJobMhrSuccessAction => ({
    type: IADD_JOB_MHR_REQUEST.SUCCESS,
    message
});
export interface IAddJobMhrLoadFailedAction {
    type: IADD_JOB_MHR_REQUEST.FAILED;
    message: string;
}
export const addJobMhrLoadFailedAction = (message: string): IAddJobMhrLoadFailedAction => ({
    type: IADD_JOB_MHR_REQUEST.FAILED,
    message
});
