import { IADD_DRIVER_REQUEST, IAddETDriverRequest } from "./addDriverConstants";


export interface IAddDriverLoadAction {
    type: IADD_DRIVER_REQUEST.REQUEST;
    data: IAddETDriverRequest
}
export const addDriverLoadAction = (data: IAddETDriverRequest): IAddDriverLoadAction => ({
    type: IADD_DRIVER_REQUEST.REQUEST,
    data
});
export interface IAddDriverSuccessAction {
    type: IADD_DRIVER_REQUEST.SUCCESS;
    message: string;
}
export const addDriverLoadSuccessAction = (message: string): IAddDriverSuccessAction => ({
    type: IADD_DRIVER_REQUEST.SUCCESS,
    message
});
export interface IAddDriverLoadFailedAction {
    type: IADD_DRIVER_REQUEST.FAILED;
    message: string;
}
export const addDriverLoadFailedAction = (message: string): IAddDriverLoadFailedAction => ({
    type: IADD_DRIVER_REQUEST.FAILED,
    message
});
