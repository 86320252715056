import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_LASER_REQUEST, ILasers } from "./getLasersConstants";
import { IGetLasersLoadAction, IGetLasersLoadFailedAction, IGetLasersSuccessAction } from "./getLasersActions";
import { IAddLaserSuccessAction } from "../addLaser/addLaserActions";
import { IADD_LASER_REQUEST } from "../addLaser/addLaserConstants";
import { IUpdateLaserSuccessAction } from "../updateLaser/updateLaserActions";
import { IUPDATE_LASER_REQUEST } from "../updateLaser/updateLaserConstants";

type Actions =
    | IGetLasersLoadAction
    | IGetLasersSuccessAction
    | IGetLasersLoadFailedAction
    | IUpdateLaserSuccessAction
    | IAddLaserSuccessAction
    | IFlushDataSuccessAction;

export const GetLasersReducer = (state: Server<SurewayAPIResponse<ById<ILasers>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ILasers>>> => {
    switch (action.type) {
        case IGET_LASER_REQUEST.REQUEST:
            return loading;

        case IGET_LASER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LASER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_LASER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_LASER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};