import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { BLACK_COLOR, EXTRA_LIGHT_BLUE_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import {  getDate, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton,  LAGreenButton,  LAOrangeButton,  LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import {  IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import {  ArrowLeftIcon } from "../../shared/icons";
import { IGetVendorPOLogByIDRequest, IGetVendorPOLogByID } from "../../../redux/field/vendors/getVendorPOLogByID/getVendorPOLogByIDConstants";
import { getVendorPOLogByIDLoadAction } from "../../../redux/field/vendors/getVendorPOLogByID/getVendorPOLogByIDActions";
import { IVendorLookup, IVendorLookupRequest } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsConstants";
import { getVendorLookupsLoadAction } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsActions";
import { getVendorPOLogByID } from "../../../redux/field/vendors/getVendorPOLogByID/getVendorPOLogByIDAccessor";
import { getVendorLookups } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsAccessor";
import splitDate from "../../shared/splitDate";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { LACenteredLoading } from "../../shared/loading";
import { IGetCostCodesByJobID, IGetCostCodesByJobIDRequest } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDConstants";
import { getCostCodesByJobID } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDAccessor";
import { getCostCodesByJobIDLoadAction } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDActions";
import { ISaveVendorPOLog, ISaveVendorPOLogRequest } from "../../../redux/field/vendors/saveVendorPOLog/saveVendorPOLogConstants";
import { saveVendorPOLog } from "../../../redux/field/vendors/saveVendorPOLog/saveVendorPOLogAccessor";
import { saveVendorPOLogLoadAction } from "../../../redux/field/vendors/saveVendorPOLog/saveVendorPOLogActions";
import { InputAdornment } from "@material-ui/core";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { IVendorOrderWork } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersConstants";
import { IGetVendorItemsByOrderIDRequest } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDConstants";
import { getVendorItemsByOrderID } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDAccessor";
import { getVendorItemsByOrderIDLoadAction } from "../../../redux/field/vendors/getVendorItemsByOrderID/getVendorItemsByOrderIDActions";
import { POLogsPopup } from "./vendorOrders";


const RequiredFields: string[] = ["po"];

interface IAddUpdateVendorPoLogComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IVendorLookup>>;
    getCostCodesByJobID: Server<SurewayAPIResponse<IGetCostCodesByJobID[]>>;
    saveVendorPOLogStatus: Server<SurewayAPIResponse<string>>; 
    getVendorPOLogByID: Server<SurewayAPIResponse<IGetVendorPOLogByID>>;
    getVendorItemsByOrderID: Server<SurewayAPIResponse<IVendorOrderWork[]>>;
};

interface IAddUpdateVendorPoLogComponentDispatchProps {
    getLookupsRequest: (data: IVendorLookupRequest) => unknown;
    getCostCodesByJobIDRequest: (data: IGetCostCodesByJobIDRequest) => unknown;
    getVendorPOLogByIDRequest: (data: IGetVendorPOLogByIDRequest) => unknown;
    saveVendorPOLogRequest: (data: ISaveVendorPOLogRequest) => unknown;
    getVendorItemsByOrderIDRequest: (request: IGetVendorItemsByOrderIDRequest) => unknown;
};

interface IAddUpdateVendorPoLogOwnProps {
    id?: string;
};

interface IAddUpdateVendorPoLogComponentState {
    data: IGetVendorPOLogByID;
    logsExpand: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
    poLogsPopup: boolean;
};

const AddUpdateVendorPoLogStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .even-row {
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    }
        
    .odd-row {
        background-color: ${WHITE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
    
`;

type IAddUpdateVendorPoLogComponentProps =
    RouteComponentProps
    & IAddUpdateVendorPoLogOwnProps
    & IAddUpdateVendorPoLogComponentStoreProps
    & IAddUpdateVendorPoLogComponentDispatchProps;

class AddUpdateVendorPOLogs extends PureComponent<IAddUpdateVendorPoLogComponentProps, IAddUpdateVendorPoLogComponentState> {

    public constructor(props: IAddUpdateVendorPoLogComponentProps) {
        super(props);
        this.state = {
          data: {
            id: 0,
            order_ID: 0,
            requested_By: "",
            date: "",
            po: "",
            vendor: "",
            scope: "",
            description: "",
            cost_Code: "",
            ticket: "",
            invoice: "",
            leM_No: "",
            in_CDMS: "",
            in_Explorer: "",
            committed_Amount: 0,
            invoiced_Amount: 0,
            accrued_Cost: "",
            accrued_Revenue: "",
            rog: "",
            roG_Date: "",
            job_ID: 0,
            created: "",
            created_By: userName,
            modified: "",
            modified_By: userName,
          },
          logsExpand: true,
          errors: {},
          serverError: "",
          poLogsPopup: false
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.getData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateVendorPoLogComponentProps): void {
        if (this.props !== prevProps) {
            if (this.props.getCostCodesByJobID === prevProps.getCostCodesByJobID && this.props.getVendorItemsByOrderID === prevProps.getVendorItemsByOrderID) {
                this.setDataToState();
            }

            if (this.props.saveVendorPOLogStatus !== prevProps.saveVendorPOLogStatus) {

                if (this.props.saveVendorPOLogStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveVendorPOLogStatus.message });

                if (hasPayload(this.props.saveVendorPOLogStatus) && this.props.saveVendorPOLogStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            };
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, poLogsPopup } = this.state;

        const { token, saveVendorPOLogStatus, getLookup, getCostCodesByJobID, getVendorItemsByOrderID } = this.props;
        const getRole = pageAccessCheck(token, "vendorAccess");
        const poLogsPopUpDatas = hasPayload(getVendorItemsByOrderID) ? getVendorItemsByOrderID.payload.response : [];
        // const disabled = (getRole === ReadOnly) ? true : undefined;
        const disabledEdit = data.id > 0 ? true : undefined;
        const vendorList = hasPayload(getLookup) ? getLookup.payload.response.vendorList : [];
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        const jobList = hasPayload(getLookup) ? getLookup.payload.response.jobs : [];
        const costCodeList = hasPayload(getCostCodesByJobID) && getCostCodesByJobID.payload.response ? getCostCodesByJobID.payload.response : [];
        const filterCostCodes = costCodeList.length > 0 ? costCodeList.map((c, index) => ({
            id: index + 1,
            name: c
          })) : [];
        
        const onPO = (value: number): void => this.handlePO(value ? value : undefined);
        const onRequestedBy = (event: unknown, value: IForeman): void => this.handleChange("requested_By", value !== null ? value.upn : data.requested_By);
        // const onCostCode = (event: unknown, value: IIdName) => this.handleChange("cost_Code", value !== null ? value.name : "");
        const onCostCode = (value: string) => this.handleCostCode("cost_Code", value ? value : data.cost_Code);
        const onCDMS = (event: unknown, value: IIdName) => this.handleChange("in_CDMS", value !== null ? value.name : data.in_CDMS);
        const onExplorer = (event: unknown, value: IIdName) => this.handleChange("in_Explorer", value !== null ? value.name : data.in_Explorer);
        const onRog = (event: unknown, value: IIdName) => this.handleChange("rog", value !== null ? value.name : data.rog);

        if(getLookup.kind === STATUS_ENUM.LOADING || getCostCodesByJobID.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <AddUpdateVendorPoLogStyles>
                {(getRole !== NotApplicable) && <>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "} VENDOR PO LOG</h2>
                            <hr />
                        </LAGridItem>

                        {data.id > 0 ? <LAGridItem xs={12}>
                            <LAGrid>
                                <LAGridItem xs={12} >
                                    <strong>PO: {data.po ?? ""}</strong>
                                </LAGridItem> 
                                <LAGridItem xs={12} >
                                    <strong>Vendor: {data.vendor ?? ""}</strong>
                                </LAGridItem>
                                <LAGridItem xs={12} >
                                    <strong>Job No: {data.job_No ?? ""}</strong>
                                </LAGridItem> 
                                <LAGridItem xs={12} >
                                    <strong>Date: {data.date ? getDate(false,data.date) : ""}</strong>
                                </LAGridItem> 
                                <LAGridItem xs={12} >
                                    <strong>Requested By: {data.requested_By_Name ?? ""}</strong>
                                </LAGridItem>
                                <LAGridItem xs={12} >
                                    <LAOrangeButton
                                        label="View All Logs"
                                        onClick={() => this.handleViewAllLogs(data.order_ID)}
                                        disabled={undefined}
                                        // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                    />
                                </LAGridItem>
                            </LAGrid>
                        </LAGridItem> :
                        <>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>PO</strong>
                                <DevExtremePopupLookup
                                    data={vendorList ?? []}
                                    id="vendor-search"
                                    placeHolder=""
                                    disabled={disabledEdit}
                                    displayExp="po"
                                    returnExp="id"
                                    name="id"
                                    errorName="po"
                                    errors={errors}
                                    currentVal={data.order_ID ?? ""}
                                    onClick={onPO}
                                    columns={[
                                        { name: "po", caption: "PO", type: "string" },
                                        { name: "vendor", caption: "Vendor", type: "string" },
                                        { name: "job_No", caption: "Job No", type: "string" },
                                        { name: "date", caption: "Date", type: "date" },
                                        { name: "requested_By_Name", caption: "Requested By", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Vendor</strong>
                                <LATextField
                                    name="vendor"
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    value={data.vendor ?? ""}
                                    disabled={true}
                                    onChange={this.handleChange}
                                    errorText={errors["vendor"] ? errors["vendor"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Job</strong>
                                {/* <LADropDownForJobs
                                    onChange={onJob}
                                    option={jobList ?? []}
                                    autoHighlight={true}
                                    getOptionLabel="job_No"
                                    disabled={undefined}
                                    dropDownPlaceHolder="Job"
                                    filterSelectedOptions={true}
                                    value={jobList ? jobList.find(q => q.id === vendors.job_ID) : ""}
                                    defaultValue={jobList ? jobList.find(q => q.id === vendors.job_ID) : ""}
                                    disExp={[{ objName: "job_No", size: 4 }, { objName: "name", size: 3 }, { objName: "company", size: 2 }]}
                                /> */}
                                <DevExtremePopupLookup
                                    data={jobList ?? []}
                                    id="job-search"
                                    placeHolder=""
                                    disabled={true}
                                    displayExp="job_No"
                                    returnExp="id"
                                    name="id"
                                    errorName="job_ID"
                                    errors={errors}
                                    currentVal={data.job_ID}
                                    onClick={undefinedFunction}
                                    columns={[
                                        { name: "job_No", caption: "Job No.", type: "string" },
                                        { name: "name", caption: "Name", type: "string" },
                                        { name: "company", caption: "Company", type: "string" },
                                        // { name: "location_Details", caption: "Location", type: "string" },
                                        // { name: "active_Repair_Status", caption: "Repair Status", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Date</strong>
                                <LATextField
                                    name="date"
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    type="date"
                                    disabled={true}
                                    onChange={this.handleChange}
                                    value={splitDate(data.date)}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Requested By</strong>
                                <LAAutoComplete
                                    disabled={true}
                                    multiple={false}
                                    option={foremans}
                                    autoHighlight={true}
                                    onChange={onRequestedBy}
                                    filterSelectedOptions={true}
                                    getOptionLabel="display_Name"
                                    dropDownPlaceHolder=""
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["requested_By"] ? errors["requested_By"].message : undefined}
                                    value={data.requested_By ? foremans?.find(q => q.upn === data.requested_By) : null}
                                    defaultValue={data.requested_By ? foremans?.find(q => q.upn === data.requested_By) : null}
                                />
                            </LAGridItem>

                            {data.order_ID ? <LAGridItem xs={12} sm={6} md={3}>
                                <strong></strong><br />
                                <LAOrangeButton
                                    label="View All Logs"
                                    onClick={() => this.handleViewAllLogs(data.order_ID)}
                                    disabled={undefined}
                                    // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem> : null}
                        </>}

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={GREEN_COLOR} textColor={WHITE_COLOR} label="Log" expanded={true} onClick={undefinedFunction}>
                                <LAGrid>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Cost Code</strong>
                                        <DevExtremePopupLookup
                                            id="cost-code-search"
                                            placeHolder=""
                                            disabled={data.job_ID ? undefined : true}
                                            displayExp="cost_Code"
                                            errors={errors}
                                            name="cost_Code"
                                            errorName="cost_Code"
                                            // multiple={true}
                                            currentVal={data.cost_Code ?? ""}
                                            data={costCodeList ?? []}
                                            onClick={onCostCode}
                                            columns={[
                                                { name: "cost_Code", caption: "Cost Code", type: "string" },
                                                { name: "description", caption: "Description", type: "string" },
                                                { name: "job", caption: "Job", type: "string" },
                                            ]}
                                        />
                                        {/* <LAAutoComplete
                                            multiple={false}
                                            option={filterCostCodes}
                                            autoHighlight={true}
                                            onChange={onCostCode}
                                            getOptionLabel="name"
                                            filterSelectedOptions={true}
                                            dropDownPlaceHolder=""
                                            selectionRemove={undefinedFunction}
                                            disabled={data.job_ID ? undefined : true}
                                            errorText={errors["cost_Code"] ? errors["cost_Code"].message : undefined}
                                            value={filterCostCodes && data.cost_Code ? filterCostCodes.find(q => q.name === data.cost_Code) : null}
                                            defaultValue={filterCostCodes && data.cost_Code ? filterCostCodes.find(q => q.name === data.cost_Code) : null}
                                        />  */}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Scope</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="scope"
                                            label=""
                                            disabled={undefined}
                                            value={data.scope ?? ""}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Description</strong>
                                        <LATextArea
                                            minRows={1}
                                            rowsMax={3}
                                            fullWidth={true}
                                            variant="outlined"
                                            name="description"
                                            label=""
                                            disabled={undefined}
                                            value={data.description ?? ""}
                                            onChange={this.handleChange}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Ticket #</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="ticket"
                                            label=""
                                            disabled={undefined}
                                            value={data.ticket ?? ""}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Invoice</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="invoice"
                                            label=""
                                            disabled={undefined}
                                            value={data.invoice ?? ""}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>LEM #</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="leM_No"
                                            label=""
                                            disabled={undefined}
                                            value={data.leM_No ?? ""}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>In CDMS</strong>
                                        <LAAutoComplete
                                            disabled={undefined}
                                            multiple={false}
                                            option={YesOrNo}
                                            autoHighlight={true}
                                            onChange={onCDMS}
                                            filterSelectedOptions={true}
                                            getOptionLabel="name"
                                            dropDownPlaceHolder=""
                                            selectionRemove={undefinedFunction}
                                            value={data.in_CDMS ? YesOrNo.find(q => q.name === data.in_CDMS) : null}
                                            defaultValue={data.in_CDMS ? YesOrNo.find(q => q.name === data.in_CDMS) : null}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>In Explorer</strong>
                                        <LAAutoComplete
                                            disabled={undefined}
                                            multiple={false}
                                            option={YesOrNo}
                                            autoHighlight={true}
                                            onChange={onExplorer}
                                            filterSelectedOptions={true}
                                            getOptionLabel="name"
                                            dropDownPlaceHolder=""
                                            selectionRemove={undefinedFunction}
                                            value={data.in_Explorer ? YesOrNo.find(q => q.name === data.in_Explorer) : null}
                                            defaultValue={data.in_Explorer ? YesOrNo.find(q => q.name === data.in_Explorer) : null}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Committed Amount</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="committed_Amount"
                                            label=""
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            disabled={undefined}
                                            value={data.committed_Amount > 0 ? data.committed_Amount : ""}
                                            onChange={this.handleNumber}
                                            type="number"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Invoiced Amount</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="invoiced_Amount"
                                            label=""
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0
                                                }
                                            }}
                                            disabled={undefined}
                                            value={data.invoiced_Amount > 0 ? data.invoiced_Amount : ""}
                                            onChange={this.handleNumber}
                                            type="number"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Accrued Cost</strong>
                                        <LATextField
                                            fullWidth={true}
                                            variant="outlined"
                                            name="accrued_Cost"
                                            label=""
                                            disabled={undefined}
                                            value={data.accrued_Cost ?? ""}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>ROG</strong>
                                        <LAAutoComplete
                                            disabled={undefined}
                                            multiple={false}
                                            option={YesOrNo}
                                            autoHighlight={true}
                                            onChange={onRog}
                                            filterSelectedOptions={true}
                                            getOptionLabel="name"
                                            dropDownPlaceHolder=""
                                            selectionRemove={undefinedFunction}
                                            value={data.rog ? YesOrNo.find(q => q.name === data.rog) : null}
                                            defaultValue={data.rog ? YesOrNo.find(q => q.name === data.rog) : null}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>ROG Date</strong>
                                        <LATextField
                                            name="roG_Date"
                                            label=""
                                            fullWidth={true}
                                            variant="outlined"
                                            type="date"
                                            errorText={errors["roG_Date"] ? errors["roG_Date"].message : undefined}
                                            disabled={undefined}
                                            onChange={this.handleChange}
                                            value={data.roG_Date ? splitDate(data.roG_Date) : ""}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : undefined}
                            />
                        </LAGridItem>

                    </LAGrid>

                    {poLogsPopup && <POLogsPopup
                        data={poLogsPopUpDatas}
                        open={poLogsPopup}
                        display={""}
                        status={getVendorItemsByOrderID.kind}
                        onClose={this.handlePoLogsPopupClose}
                    //   onOpenDefectItem={this.onOpenDefectItem}
                    />}
                </>}


                <RequestStatus requestStatus={saveVendorPOLogStatus.kind} successMessage="Vendor PO Log has been added successfully" />
            </AddUpdateVendorPoLogStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.VENDOR.VENDOR_LOGS,
            search: getTokenFromUrl(false)
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleViewAllLogs = async (order_ID: number): Promise<void> => {
        if(order_ID > 0) {
            if (hasPayload(this.props.token)) {
                await this.props.getVendorItemsByOrderIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: order_ID,
                    }
                });
            }
            this.setState({ poLogsPopup: true });
        } 
    };

    private handlePoLogsPopupClose = (): void => {
        this.setState({ poLogsPopup: false });
    };

    private handleSave = async (): Promise<void> => {
        let {
            data
        } = this.state;

        if (hasPayload(this.props.token)) {

            const upn = this.props.token.payload.response.upn;
            const request: ISaveVendorPOLog = {
                ID: data.id,
                Requested_By: data.requested_By,
                Date: data.date,
                PO: data.po,
                Vendor: data.vendor,
                Job_ID: data.job_ID,
                Order_ID: data.order_ID,
                Scope: data.scope,
                Description: data.description,
                Cost_Code: data.cost_Code,
                Ticket: data.ticket,
                Invoice: data.invoice,
                LEM_No: data.leM_No,
                In_CDMS: data.in_CDMS,
                In_Explorer: data.in_Explorer,
                Committed_Amount: data.committed_Amount > 0 ? data.committed_Amount : null,
                Invoiced_Amount: data.invoiced_Amount > 0  ? data.invoiced_Amount : null,
                Accrued_Cost: data.accrued_Cost,
                Accrued_Revenue: data.accrued_Revenue,
                ROG: data.rog,
                ROG_Date: data.roG_Date,
                Created: data.created,
                Created_By: data.created_By,
                Modified: data.modified,
                Modified_By: upn,
            }

            this.props.saveVendorPOLogRequest({
                token: this.props.token.payload.response.token,
                request: request
            });

            this.setState({ serverError: "" });
        }
    };

    private handlePO = (value: number | undefined): void => {
        let errors = { ...this.state.errors };
        if(value) {
            const vendorList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.vendorList : [];
            const selectedVendor = vendorList?.find(v => v.id === value);

            if(selectedVendor) {
                if (RequiredFields.includes("po"))
                    errors = this.errorChecker("po", selectedVendor.po, errors);
    
                this.setState({
                    data: {
                       ...this.state.data,
                        order_ID: selectedVendor.id,
                        vendor: selectedVendor.vendor,
                        date: selectedVendor.date,
                        requested_By: selectedVendor.requested_By,
                        po: selectedVendor.po,
                        job_ID: selectedVendor.job_ID,
                        cost_Code: "",
                        scope: ""
                    },
                    errors
                });
                this.getCostCodesByJobID(selectedVendor.job_ID)
            }
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "rog") {
            if(value === "Yes") {
                errors = this.errorChecker("roG_Date", this.state.data.roG_Date, errors);
            }
            else {
                if(errors["roG_Date"]) 
                    delete errors["roG_Date"];
            }
        }

        if(name === "roG_Date" && this.state.data.rog === "Yes") {
            errors = this.errorChecker(name, value, errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleCostCode = (name: string, value: string): void => {
        const { data, errors } = this.state;
        const costCodeList = hasPayload(this.props.getCostCodesByJobID) && this.props.getCostCodesByJobID.payload.response ? this.props.getCostCodesByJobID.payload.response : [];
        const find = costCodeList?.find(x => x.cost_Code === value);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value,
                scope: find ? find.description : data.scope
            },
            errors
        });
    }

    private handleNumber = (name: string, value: string): void => {
        if (value !== undefined) {
            let errors = { ...this.state.errors };

            if (RequiredFields.includes(name))
                errors = this.errorChecker(name, value.toString(), errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }
    };

    private setDataToState = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "vendorAccess") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (this.state.data.id !== Number(query.id)) {
                            if (hasPayload(this.props.getVendorPOLogByID)) {
                                let errors = this.state.errors;
                                const data = this.props.getVendorPOLogByID.payload.response;

                                if(data.job_ID) {
                                    this.getCostCodesByJobID(data.job_ID)
                                }
                                
                                this.setState({
                                    data,
                                    errors
                                });
                            } else {
                                if (isNotLoaded(this.props.getVendorPOLogByID)) {
                                    this.getData();
                                };
                            }
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({
                            ...this.state,
                            errors
                        });
                    };

                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLook();
                    };
                } else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Vendor_Log"
                }
            });
    };

    private getData = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token)) {
            if (Number(query.id) !== 0) {
                this.props.getVendorPOLogByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: Number(query.id)
                    }
                });
            }
        }
    };

    private getCostCodesByJobID = (value: number): void => {
        if (hasPayload(this.props.token)) {
                if (Number(value) !== 0) {
                    this.props.getCostCodesByJobIDRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            id: Number(value)
                        }
                    }); 
                }
            }
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateVendorPoLogComponentStoreProps => ({
    token: getToken(state),
    getLookup: getVendorLookups(state),
    getCostCodesByJobID: getCostCodesByJobID(state),
    getVendorPOLogByID: getVendorPOLogByID(state),
    saveVendorPOLogStatus: saveVendorPOLog(state),
    getVendorItemsByOrderID: getVendorItemsByOrderID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateVendorPoLogComponentDispatchProps => ({
    getLookupsRequest: (data: IVendorLookupRequest) => dispatch(getVendorLookupsLoadAction(data)),
    getCostCodesByJobIDRequest: (data: IGetCostCodesByJobIDRequest) => dispatch(getCostCodesByJobIDLoadAction(data)),
    getVendorPOLogByIDRequest: (data: IGetVendorPOLogByIDRequest) => dispatch(getVendorPOLogByIDLoadAction(data)),
    saveVendorPOLogRequest: (data: ISaveVendorPOLogRequest) => dispatch(saveVendorPOLogLoadAction(data)),
    getVendorItemsByOrderIDRequest: (request: IGetVendorItemsByOrderIDRequest): unknown => dispatch(getVendorItemsByOrderIDLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateVendorPOLogs);