import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PM_LOOKUPS_REQUEST, IPMLookups } from "./getPMLookupsConstants";
import { IGetPMLookupsLoadAction, IGetPMLookupsLoadFailedAction, IGetPMLookupsSuccessAction, getPMLookupsLoadFailedAction, getPMLookupsLoadSuccessAction } from "./getPMLookupsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getPMLookupsEpic: Epic = (
    action$: ActionsObservable<IGetPMLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPMLookupsSuccessAction | IGetPMLookupsLoadFailedAction> =>
    action$.ofType(IGET_PM_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IPMLookups>>(
                    END_POINTS.FIELD.PM_SHEETS.GET_PM_SHEET_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IPMLookups>): IGetPMLookupsSuccessAction => {
                            return getPMLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getPMLookupsLoadFailedAction(response.message)))
                    )
            )
        );