import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_EQUIPMENT_BY_ID_REQUEST, IShopEquipment } from "./getEquipmentByIDConstants";
import { IAddShopEquipmentSuccessAction } from "../addEquipments/addShopEquipmentsActions";
import { IUpdateShopEquipmentSuccessAction } from "../updateEquipments/updateShopEquipmentsActions";
import { IADD_SHOP_EQUIPMENT_REQUEST } from "../addEquipments/addShopEquipmentsConstants";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST } from "../updateEquipments/updateShopEquipmentsConstants";
import { IGetEquipmentByIDLoadAction, IGetEquipmentByIDLoadFailedAction, IGetEquipmentByIDSuccessAction } from "./getEquipmentByIDActions";
// import { IAddEquipmentTypeSuccessAction } from "../addEquipmentType/addEquipmentTypeActions";
// import { IAddShopManufacturesSuccessAction } from "../addManufactures/addShopManufacturesActions";
// import { IAssignEquipmentTypeColumnSuccessAction } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnActions";
// import { IUpdateEquipmentTypeSuccessAction } from "../updateEquipmentType/updateEquipmentTypeActions";
// import { IUpdateShopManufacturesSuccessAction } from "../updateManufactures/updateShopManufacturesActions";
// import { IADD_EQUIPMENT_TYPE_REQUEST } from "../addEquipmentType/addEquipmentTypeConstants";
// import { IADD_SHOP_MANUFACTURERS_REQUEST } from "../addManufactures/addShopManufacturesConstants";
// import { IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnConstants";
// import { IUPDATE_EQUIPMENT_TYPE_REQUEST } from "../updateEquipmentType/updateEquipmentTypeConstants";
// import { IUPDATE_SHOP_MANUFACTURERS_REQUEST } from "../updateManufactures/updateShopManufacturesConstants";
// import { IAddWorkingSiteLogSuccessAction } from "../workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogActions";
// import { IADD_WORKING_SITE_LOG_REQUEST } from "../workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogConstants";
// import { IUpdateWorkingSiteLogSuccessAction } from "../workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogActions";
// import { IUPDATE_WORKING_SITE_LOG_REQUEST } from "../workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogConstants";
// import { IAddAttachmentCouplerSuccessAction } from "../attachment/addCoupler/addCouplerActions";
// import { IADD_ATTACHMENT_COUPLER_REQUEST } from "../attachment/addCoupler/addCouplerConstants";
// import { IAddAttachmentStyleSuccessAction } from "../attachment/addStyle/addStyleActions";
// import { IADD_ATTACHMENT_STYLE_REQUEST } from "../attachment/addStyle/addStyleConstants";
// import { IAddAttachmentTypeSuccessAction } from "../attachment/addType/addTypeActions";
// import { IADD_ATTACHMENT_TYPE_REQUEST } from "../attachment/addType/addTypeConstants";
// import { IUpdateAttachmentCouplerSuccessAction } from "../attachment/updateCoupler/updateCouplerActions";
// import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "../attachment/updateCoupler/updateCouplerConstants";
// import { IUpdateAttachmentStyleSuccessAction } from "../attachment/updateStyle/updateStyleActions";
// import { IUPDATE_ATTACHMENT_STYLE_REQUEST } from "../attachment/updateStyle/updateStyleConstants";
// import { IUpdateAttachmentTypeSuccessAction } from "../attachment/updateType/updateTypeActions";
// import { IUPDATE_ATTACHMENT_TYPE_REQUEST } from "../attachment/updateType/updateTypeConstants";
// import { IADD_EQUIPMENT_CERTIFICATE_REQUEST } from "../addEquipmentCertificate/addEquipmentCertificateConstants";
// import { IAddEquipmentCertificateSuccessAction } from "../addEquipmentCertificate/addEquipmentCertificateActions";

type Actions =
    | IGetEquipmentByIDLoadAction
    | IGetEquipmentByIDSuccessAction
    | IGetEquipmentByIDLoadFailedAction
    | IUpdateShopEquipmentSuccessAction
    | IAddShopEquipmentSuccessAction
    // | IAssignEquipmentTypeColumnSuccessAction
    // | IUpdateShopManufacturesSuccessAction
    // | IAddShopManufacturesSuccessAction
    // | IUpdateEquipmentTypeSuccessAction
    // | IAddEquipmentTypeSuccessAction
    // | IAddWorkingSiteLogSuccessAction
    // | IUpdateWorkingSiteLogSuccessAction
    // | IUpdateAttachmentTypeSuccessAction
    // | IUpdateAttachmentStyleSuccessAction
    // | IUpdateAttachmentCouplerSuccessAction
    // | IAddAttachmentTypeSuccessAction
    // | IAddAttachmentStyleSuccessAction
    // | IAddAttachmentCouplerSuccessAction
    // | IAddEquipmentCertificateSuccessAction
    | IFlushDataSuccessAction;

export const GetEquipmentByIDReducer = (state: Server<SurewayAPIResponse<IShopEquipment>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IShopEquipment>> => {
    switch (action.type) {
        case IGET_EQUIPMENT_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_EQUIPMENT_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EQUIPMENT_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        // case IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_EQUIPMENT_CERTIFICATE_REQUEST.SUCCESS:
        //     return notLoaded;

        default:
            return state;
    }
};