import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SCHEDULE_SERVICE_HISTORY_REQUEST, IAddScheduleServiceHistoryRequest } from "./addScheduleServiceHistoryConstants";


export interface IAddScheduleServiceHistoryLoadAction {
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.REQUEST;
    data: IAddScheduleServiceHistoryRequest
}
export const addScheduleServiceHistoryLoadAction = (data: IAddScheduleServiceHistoryRequest): IAddScheduleServiceHistoryLoadAction => ({
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.REQUEST,
    data
});
export interface IAddScheduleServiceHistorySuccessAction {
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addScheduleServiceHistoryLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddScheduleServiceHistorySuccessAction => ({
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.SUCCESS,
    message
});
export interface IAddScheduleServiceHistoryLoadFailedAction {
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.FAILED;
    message: string;
}
export const addScheduleServiceHistoryLoadFailedAction = (message: string): IAddScheduleServiceHistoryLoadFailedAction => ({
    type: IADD_SCHEDULE_SERVICE_HISTORY_REQUEST.FAILED,
    message
});
