import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_JOURNAL_VERSION_HISTORY_REQUEST, IGetJournalVersionHistory } from "./getJournalVersionHistoryConstants";
import { IGetJournalVersionHistoryLoadAction, IGetJournalVersionHistoryLoadFailedAction, IGetJournalVersionHistorySuccessAction } from "./getJournalVersionHistoryActions";

type Actions =
    | IGetJournalVersionHistoryLoadAction
    | IGetJournalVersionHistorySuccessAction
    | IGetJournalVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetJournalVersionHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetJournalVersionHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetJournalVersionHistory>>> => {
    switch (action.type) {
        case IGET_JOURNAL_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_JOURNAL_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_JOURNAL_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};