import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_LOCATION_REQUEST } from "./updateLocationConstants";
import { IUpdateLocationLoadAction, IUpdateLocationLoadFailedAction, IUpdateLocationSuccessAction } from "./updateLocationActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IUpdateLocationLoadAction
    | IUpdateLocationSuccessAction
    | IUpdateLocationLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateLocationReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_LOCATION_REQUEST.REQUEST:
            return loading;

        case IUPDATE_LOCATION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_LOCATION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};