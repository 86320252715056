import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_COMPONENT_WAITING_PARTS_REQUEST {
    REQUEST = "getComponentWaitingParts/GET_COMPONENT_WAITING_PARTS_REQUEST",
    SUCCESS = "getComponentWaitingParts/GET_COMPONENT_WAITING_PARTS_SUCCESS",
    FAILED = "getComponentWaitingParts/GET_COMPONENT_WAITING_PARTS_FAILED"
};

export interface IGetComponentWaitingPartsRequest extends ISurewayTokenRequestBody {

};

export interface IComponentWaitingParts {
    id: number;
    unit_No: string;
    priority: string;
    repair_Line_ID: number;
    foreman_Comments: string;
    mechanic_Comments: string;
    parts_Instructions: string;
    component: string;
    parts_Notes: string;
    modified_By: string;
    major_Component_Replacement: string;
    part_Dispatch: string | null;
    // dispatched: boolean;
};