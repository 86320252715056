import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_FILTER_REQUEST, IAddFilterRequest } from "./addFilterConstants";


export interface IAddFilterLoadAction {
    type: IADD_FILTER_REQUEST.REQUEST;
    data: IAddFilterRequest
}
export const addFilterLoadAction = (data: IAddFilterRequest): IAddFilterLoadAction => ({
    type: IADD_FILTER_REQUEST.REQUEST,
    data
});
export interface IAddFilterSuccessAction {
    type: IADD_FILTER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFilterLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFilterSuccessAction => ({
    type: IADD_FILTER_REQUEST.SUCCESS,
    message
});
export interface IAddFilterLoadFailedAction {
    type: IADD_FILTER_REQUEST.FAILED;
    message: string;
}
export const addFilterLoadFailedAction = (message: string): IAddFilterLoadFailedAction => ({
    type: IADD_FILTER_REQUEST.FAILED,
    message
});
