import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_FILTER_PART_REQUEST, IAddFilterPartRequest } from "./addFilterPartConstants";


export interface IAddFilterPartLoadAction {
    type: IADD_FILTER_PART_REQUEST.REQUEST;
    data: IAddFilterPartRequest
}
export const addFilterPartLoadAction = (data: IAddFilterPartRequest): IAddFilterPartLoadAction => ({
    type: IADD_FILTER_PART_REQUEST.REQUEST,
    data
});
export interface IAddFilterPartSuccessAction {
    type: IADD_FILTER_PART_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFilterPartLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFilterPartSuccessAction => ({
    type: IADD_FILTER_PART_REQUEST.SUCCESS,
    message
});
export interface IAddFilterPartLoadFailedAction {
    type: IADD_FILTER_PART_REQUEST.FAILED;
    message: string;
}
export const addFilterPartLoadFailedAction = (message: string): IAddFilterPartLoadFailedAction => ({
    type: IADD_FILTER_PART_REQUEST.FAILED,
    message
});
