import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { IGetPMSheetVersionHistory, IGetPMSheetVersionHistoryRequest } from "../../../redux/field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryConstants";
import { getPMSheetVersionHistory } from "../../../redux/field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryAccessor";
import { getPMSheetVersionHistoryLoadAction } from "../../../redux/field/pmSheets/getPMSheetVersionHistory/getPMSheetVersionHistoryActions";
import { LADevExtremeGrid } from "../../shared/devExtreme";

interface IPMSheetDetailsVersionHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getPMSheetVersionHistory: Server<SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>>;
};

interface IPMSheetDetailsVersionHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getPMSheetVersionHistoryRequest: (request: IGetPMSheetVersionHistoryRequest) => unknown;
};

interface IPMSheetDetailsVersionHistoryOwnProps {

};

interface IPMSheetDetailsVersionHistoryState {
    data: any[];
};

const PMSheetDetailsVersionHistoryStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
    };
`;

type IPMSheetDetailsVersionHistoryProps = RouteComponentProps
    & IPMSheetDetailsVersionHistoryStoreProps
    & IPMSheetDetailsVersionHistoryDispatchProps
    & IPMSheetDetailsVersionHistoryOwnProps;

class PMSheetDetailsVersionHistory extends PureComponent<IPMSheetDetailsVersionHistoryProps, IPMSheetDetailsVersionHistoryState> {

    public constructor(props: IPMSheetDetailsVersionHistoryProps) {
        super(props);
        this.state = {
            data: [],
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IPMSheetDetailsVersionHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {


        const {  data } = this.state;
        const { getToken, getPMSheetVersionHistory } = this.props;
        const getRole = pageAccessCheck(getToken, "pmSheets");
        // console.log(data)

        return (
            <PageSpacing title="PM SHEET DETAILS HISTORY" description="PM - DETAILS VERSION HISTORY" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <PMSheetDetailsVersionHistoryStyles>

                        {(getPMSheetVersionHistory.kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Loading PM Service Sheet Details Version History..." />
                        </LAGridItem>}

                        {(getPMSheetVersionHistory.kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LAErrorBox text="Failed to load..." />
                        </LAGridItem>}

                        {(getPMSheetVersionHistory.kind === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                            <LAGridItem xs={12} className="text-center">
                                <h3>PM SHEET DETAILS VERSION HISTORY - {data && data.length > 0 && data[0].unit_No}</h3>
                                <hr />
                            </LAGridItem>
                            {(data && data.length > 0) ? 
                                <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                        data={data}
                                        columnChoose={true}
                                        columnsHiding={true}
                                        onClick={undefinedFunction}
                                        searchPanel={true}
                                        filterHeader={true}
                                        export={true}
                                        removeStyleBtn={true}
                                        id="pmSheetDetailsVersionHistory"
                                        customRowColor={true}
                                        exportFileName="PM_Service_Sheet_Details_Version_History"
                                        storageKey="pmSheetDetailsVersionHistorySessionKey"
                                        columns={[
                                            { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                            { name: "modified_By", caption: "Modified By", type: "string" },
                                            { name: "service_Section_Name", caption: "Service Section Name", type: "string" },
                                            { name: "service_Date", caption: "Service Date", type: "datetime" },
                                            { name: "service_Due", caption: "Service Due", type: "string" },
                                            { name: "itemName", caption: "Item", type: "string" },
                                            { name: "choice", caption: "Completed", type: "string" },
                                            { name: "comments", caption: "Comments", type: "string" },
                                            { name: "created", caption: "Created", type: "datetime" },
                                            { name: "created_By", caption: "Created By", type: "string" },
                                        ]}
                                    />
                                </LAGridItem>

                            : <LAErrorBox text="No records Found" />}
                            </LAGridItem>
                        }
                    </PMSheetDetailsVersionHistoryStyles>
                }
            </PageSpacing>
        );
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (isNotLoaded(this.props.getPMSheetVersionHistory) && hasPayload(this.props.getToken)) {
            const query: any = queryString.parse(this.props.location.search);

            if (query.id)
                this.props.getPMSheetVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: query.id,
                        Type: "PM_Sheet_details"
                    }
                });
        };

        if (hasPayload(this.props.getPMSheetVersionHistory) && hasPayload(this.props.getToken))
            this.setState({ data: Object.values(this.props.getPMSheetVersionHistory.payload.response) });
    };

}

const mapStateToProps = (state: IStore): IPMSheetDetailsVersionHistoryStoreProps => ({
    getToken: getToken(state),
    getPMSheetVersionHistory: getPMSheetVersionHistory(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IPMSheetDetailsVersionHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getPMSheetVersionHistoryRequest: (request: IGetPMSheetVersionHistoryRequest): unknown => dispatch(getPMSheetVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PMSheetDetailsVersionHistory);
