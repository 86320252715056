import React from "react";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding,  } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse,  } from "../../../shared/publicInterfaces";
import {  undefinedFunction, userName, ZEROTH, getTokenFromUrl, pageAccessCheck, deleteText, callRouteWithQueryString,  } from "../../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon, DownloadIcon, } from "../../../shared/icons";
import { BLUE_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { LACheckBox } from "../../../shared/checkBox";
import queryString from "query-string";
import PageSpacing from "../../../shared/pageSpacing";
import { getSOCTLookups } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsAccessor";
import { ISOCTLookup, ISOCTLookupRequest } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsConstants";
import { getSOCTLookupsLoadAction } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LATextField from "../../../shared/textField";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import splitDate from "../../../shared/splitDate";
import LATextArea from "../../../shared/textArea";
import { LADropZone } from "../../../shared/dropZone";
import { IShopEquipmentFile } from "../../../../redux/field/getEquipments/getEquipmentConstants";
import { SOCTTabNavbarMenu, TabNavbar } from "../../../shared/tabNavbar";
import { Navbar, SOCTNavbarMenu } from "../../../shared/navbars";
import LAErrorBox from "../../../shared/errorBox";
import { IGetSOCTByIdRequest, ISOCT } from "../../../../redux/field/soct/getSOCTById/getSOCTByIdConstants";
import { getSOCTByIdLoadAction } from "../../../../redux/field/soct/getSOCTById/getSOCTByIdActions";
import { getSOCTById } from "../../../../redux/field/soct/getSOCTById/getSOCTByIdAccessor";
import LALinkButton from "../../../shared/linkButton";
import { IAddSOCTRequest, IAddUpdateSOCT } from "../../../../redux/field/soct/addSOCT/addSOCTConstants";
import { IUpdateSOCTRequest } from "../../../../redux/field/soct/updateSOCT/updateSOCTConstants";
import { addSOCTStatus } from "../../../../redux/field/soct/addSOCT/addSOCTAccessor";
import { updateSOCTStatus } from "../../../../redux/field/soct/updateSOCT/updateSOCTAccessor";
import { addSOCTLoadAction } from "../../../../redux/field/soct/addSOCT/addSOCTActions";
import { updateSOCTLoadAction } from "../../../../redux/field/soct/updateSOCT/updateSOCTActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { END_POINTS } from "../../../../redux/endpoints";
import { StaticTextField } from "../../../shared/staticTextField";
import LALoading from "../../../shared/loading";

const RequiredFields = ["unit_Number", "service_Date", "current_Hours"];

interface IAddUpdateSOCTStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISOCTLookup>>;
    getSOCTByID: Server<SurewayAPIResponse<ISOCT>>;
    addSOCTStatus: Server<SurewayAPIResponse<string>>;
    updateSOCTStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateSOCTDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISOCTLookupRequest) => unknown;
    getSOCTByIDRequest: (request: IGetSOCTByIdRequest) => unknown;
    addSOCTRequest: (data: IAddSOCTRequest) => unknown;
    updateSOCTRequest: (data: IUpdateSOCTRequest) => unknown;
};


interface IAddUpdateSOCTOwnProps {

};

interface IAddUpdateSOCTState {
    data:ISOCT;
    attachments: IShopEquipmentFile[];
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateSOCTStyles = styled(LAPaperWithPadding)`
    margin: 20px 20px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .link-btn {
        cursor: pointer;
        color: ${BLUE_COLOR};
    };

    .link-btn:hover {
        text-decoration: underline;
    }

    .border {
        border: 3px solid #000;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateSOCTProps = RouteComponentProps
    & IAddUpdateSOCTStoreProps
    & IAddUpdateSOCTDispatchProps
    & IAddUpdateSOCTOwnProps;

class AddUpdateSOCT extends PureComponent<IAddUpdateSOCTProps, IAddUpdateSOCTState> {

    public constructor(props: IAddUpdateSOCTProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                unit_ID: 0,
                service_Date: "",
                current_Hours: 0,
                last_EO: 0,
                filter_Cuttings: "",
                comments: "",
                geotab_Sync: "No",
                reading_Type_Notes: "",
                attachments: "",
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            attachments: [],
            errors: {},
            serverError: "",
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.getData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateSOCTProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addSOCTStatus !== prevProps.addSOCTStatus) {

                if (this.props.addSOCTStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addSOCTStatus.message });

                if (hasPayload(this.props.addSOCTStatus) && this.props.addSOCTStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateSOCTStatus !== prevProps.updateSOCTStatus) {

                if (this.props.updateSOCTStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateSOCTStatus.message });

                if (hasPayload(this.props.updateSOCTStatus) && this.props.updateSOCTStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, attachments, serverError } = this.state;
        const { getToken, getLookup, addSOCTStatus, updateSOCTStatus, getSOCTByID } = this.props;
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        const units = hasPayload(getLookup) ? (data.id === 0 ? getLookup.payload.response.units.filter(x => x.socT_ID === null) : getLookup.payload.response.units) : [];
        // console.log(units)
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const onGeotabSyncCheckbox = (checked: boolean): void => this.handleChange("geotab_Sync", checked ? "Yes" : "No");
        // console.log(data)
        const serviceHours = data.id > 0 && data.service_Hours ? data.service_Hours.split(",").map(String) : [];
        const query = queryString.parse(this.props.location.search);

        return (
            <PageSpacing title="SOCT - Add/Update SOCT" description="SOCT - ADD/Update SOCT" fixedSpaceOnSmallerScreens={true}>    
                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                {query && query.id && query.id !== "0" && <TabNavbar tabs={SOCTTabNavbarMenu} handleTabClick={this.handleTabClick}/>}
                <AddUpdateSOCTStyles>
                    
                    {(getLookup.kind === STATUS_ENUM.LOADING || getSOCTByID.kind === STATUS_ENUM.LOADING ) && <LALoading message="Loading..." />}
                    {(getRole === true) && getLookup.kind === STATUS_ENUM.SUCCEEDED &&
                        <LAGrid spacing={1}>
                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            {/* {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                                    <LALinkButton onClick={() => this.handleMachineHistory(data)} label="Machine History"></LALinkButton>
                            </LAGridItem>} */}

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? (
                                    <>
                                        VIEW/UPDATE SOCT - <span className="link-btn" onClick={this.handleUnitNumberClick}>{data.unit_Number}</span>
                                    </>)
                                     : "ADD SOCT"}</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Unit#</strong>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder=""
                                    disabled={data.id > 0}
                                    displayExp="unit_Number"
                                    name="unit_Number"
                                    errorName="unit_Number"
                                    errors={errors}
                                    currentVal={unit?.unit_Number}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "make", caption: "Make", type: "string" },
                                        { name: "model", caption: "Model", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                        { name: "reading_Type", caption: "Reading Type", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Make</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="make"
                                    onChange={undefinedFunction}
                                    value={unit?.make ?? ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Model</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="model"
                                    onChange={undefinedFunction}
                                    value={unit?.model ?? ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Location Details</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="location"
                                    onChange={undefinedFunction}
                                    value={unit ? unit.location_Details : ""}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Equipment Type</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="equipment_Type"
                                    value={data.equipment_Type ?? ""}
                                    onChange={this.handleChange}
                                    disabled={data.id > 0}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Reading Type</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="reading_Type"
                                    onChange={undefinedFunction}
                                    value={(unit && unit.reading_Type ) ?? ""}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Meter Date</strong>
                                <LATextField
                                    id="meter_Date"
                                    value={data.meter_Date ? splitDate(data.meter_Date) : ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={data.id > 0}
                                    name="meter_Date"
                                    label=""
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>}

                            {data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Service Date</strong>
                                <LATextField
                                    id="service_Date"
                                    value={data.service_Date ? splitDate(data.service_Date) : ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={data.id > 0}
                                    name="service_Date"
                                    label=""
                                    errorText={errors["service_Date"] ? errors["service_Date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Current Hours</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="current_Hours"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={data.id > 0}
                                    value={data.current_Hours > 0 ? data.current_Hours : ""}
                                    onChange={this.handleNumber}
                                    errorText={errors["current_Hours"] ? errors["current_Hours"].message : undefined}
                                />
                            </LAGridItem>

                            {data.id > 0 &&<LAGridItem xs={12} sm={6} md={3}>
                                <strong>ECM</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="ecm"
                                    type="number"
                                    disabled={data.id > 0}
                                    value={data.ecm ?? ""}
                                    onChange={this.handleNumber}
                                />
                            </LAGridItem>}

                            {data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Last EO</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="last_EO"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={data.id > 0}
                                    value={data.last_EO > 0 ? data.last_EO : ""}
                                    onChange={this.handleNumber}
                                    errorText={errors["last_EO"] ? errors["last_EO"].message : undefined}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Filter Cuttings</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="filter_Cuttings"
                                    value={data.filter_Cuttings ?? ""}
                                    onChange={this.handleChange}
                                    disabled={data.id > 0}
                                    errorText={errors["filter_Cuttings"] ? errors["filter_Cuttings"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Comments</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="comments"
                                    value={data.comments ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                {/* <strong>Sync Engine Hours with Device API</strong><br></br> */}
                                <br />
                                <LACheckBox
                                    label="Sync Engine Hours with Device API"
                                    disabled={undefined}
                                    onChange={onGeotabSyncCheckbox}
                                    value={data.geotab_Sync === "Yes" ? true : false}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Status</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="status"
                                    value={data.status ?? ""}
                                    onChange={this.handleChange}
                                    disabled={data.id > 0}
                                />
                            </LAGridItem>}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>PM Sheet Comments</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={data.id > 0}
                                    name="pm_Sheet_Comments"
                                    value={data.pM_Sheet_Comments ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>PM Sheet Admin Comments</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={data.id > 0}
                                    name="pm_Sheet_Admin_Comments"
                                    value={data.pM_Sheet_Admin_Comments ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}

                            {data.id > 0 && data.equipment_Type === "Excavators" && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Reading Type Notes</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="reading_Type_Notes"
                                    value={data.reading_Type_Notes ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}

                            {data.id > 0 && <LAGridItem xs={12}>
                                <LAGreenPaper>
                                    <LAGrid>
                                        <LAGridItem xs={12} className="text-center">
                                            <h3>Service Summary</h3>
                                            <hr />
                                        </LAGridItem>

                                        {data.id > 0 &&<LAGridItem xs={12} sm={6} md={3}>
                                            <strong>Last Service Date</strong>
                                            <LATextField
                                                id="service_Date"
                                                value={data.service_Date ? splitDate(data.service_Date) : ""}
                                                fullWidth={true}
                                                variant="outlined"
                                                disabled={data.id > 0}
                                                name="service_Date"
                                                label=""
                                                errorText={errors["service_Date"] ? errors["service_Date"].message : undefined}
                                                onChange={this.handleChange}
                                                type="date"
                                            />
                                        </LAGridItem> }

                                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                            <strong >Last Service Hours</strong>
                                            <LATextField
                                                label=""
                                                fullWidth={true}
                                                variant="outlined"
                                                name="last_Service_Hours"
                                                value={data.last_Service_Hours ?? ""}
                                                onChange={this.handleChange}
                                                disabled={data.id > 0}
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("100")  && data.equipment_Type === "Welders" && <LAGridItem xs={12} sm={6} md={3}>
                                            <strong>Last 100</strong>
                                            <LATextField
                                                label=""
                                                fullWidth={true}
                                                variant="outlined"
                                                name="last_100"
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                                disabled={data.id > 0}
                                                value={data.last_100 ?? ""}
                                                onChange={this.handleNumber}
                                                errorText={errors["last_100"] ? errors["last_100"].message : undefined}
                                            />
                                        </LAGridItem>}
                                        {data.id > 0 && serviceHours.includes("100")  && data.equipment_Type === "Welders" && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 100 Due"
                                                value={data.next_100_Due ?? ""}
                                                color={(data.next_100_Due) && (data.next_100_Due > 50) ? 'red': 
                                                        (data.next_100_Due &&  data.next_100_Due > -51 && data.next_100_Due < 51 && data.last_100 != null && data.next_100_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}
                                        {data.id > 0 && serviceHours.includes("250")  && <LAGridItem xs={12} sm={6} md={3}>
                                            <strong>Last EO</strong>
                                            <LATextField
                                                label=""
                                                fullWidth={true}
                                                variant="outlined"
                                                name="last_EO"
                                                type="number"
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0
                                                    }
                                                }}
                                                disabled={data.id > 0}
                                                value={data.last_EO > 0 ? data.last_EO : ""}
                                                onChange={this.handleNumber}
                                                errorText={errors["last_EO"] ? errors["last_EO"].message : undefined}
                                            />
                                        </LAGridItem>}
                                        {data.id > 0 && serviceHours.includes("250") && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 250 Due"
                                                value={data.next_250_Due ?? ""}
                                                color={(data.next_250_Due) && (data.next_250_Due > 50) ? 'red': 
                                                        (data.next_250_Due &&  data.next_250_Due > -51 && data.next_250_Due < 51 && data.last_EO != null && data.next_250_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("500") && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 500 Due"
                                                value={data.next_500_Due ?? ""}
                                                color={(data.next_500_Due) && (data.next_500_Due > 50 && data.last_500 != null ) ? 'red': 
                                                        (data.next_500_Due &&  data.next_500_Due > -51 && data.next_500_Due < 51 && data.last_500 != null && data.next_500_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("1000") && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 1000 Due"
                                                value={data.next_1000_Due ?? ""}
                                                color={(data.next_1000_Due) && (data.next_1000_Due > 100 && data.last_1000 != null) ? 'red': 
                                                        (data.next_1000_Due &&  data.next_1000_Due > -101 && data.next_1000_Due < 101 && data.last_1000 != null && data.next_1000_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("1500") && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 1500 Due"
                                                value={data.next_1500_Due ?? ""}
                                                color={(data.next_1500_Due) && (data.next_1500_Due > 150 && data.last_1500 != null) ? 'red': 
                                                        (data.next_1500_Due &&  data.next_1500_Due > -151 && data.next_1500_Due < 151 && data.last_1500 != null && data.next_1500_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("3000") && <LAGridItem xs={12} sm={6} md={3}>
                                            <StaticTextField
                                                label="Next 3000 Due"
                                                value={data.next_3000_Due ?? ""}
                                                color={(data.next_3000_Due) && (data.next_3000_Due > 300 && data.last_3000 != null) ? 'red': 
                                                        (data.next_3000_Due &&  data.next_3000_Due > -301 && data.next_3000_Due < 301 && data.last_3000 != null && data.next_3000_Due != null) ? 'yellow'
                                                        : undefined }
                                            />
                                        </LAGridItem>}

                                        {data.id > 0 && serviceHours.includes("Schedule A") && <LAGridItem xs={12} sm={6} md={3} >
                                            <StaticTextField
                                                label="Next ScheduleA Due"
                                                value={data.next_ScheduleA_Due ?? ""}
                                            />
                                        </LAGridItem>}
                                        {data.id > 0 && serviceHours.includes("Schedule B") && <LAGridItem xs={12} sm={6} md={3} >
                                            <StaticTextField
                                                label="Next ScheduleB Due"
                                                value={data.next_ScheduleB_Due ?? ""}
                                            />
                                        </LAGridItem>}
                                        {data.id > 0 && serviceHours.includes("Schedule D") && <LAGridItem xs={12} sm={6} md={3} >
                                            <StaticTextField
                                                label="Next ScheduleD Due"
                                                value={data.next_ScheduleD_Due ?? ""}
                                            />
                                        </LAGridItem>}

                                    </LAGrid>
                                </LAGreenPaper>
                            </LAGridItem>}

                            <LAGridItem xs={12} className="text-center">
                                <LABluePaper>

                                    <LAGrid>
                                        <LAGridItem xs={12} className="text-center">
                                            <h3>ATTACHMENTS</h3>
                                            <hr />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADropZone onFileDrop={this.handleAttachment} />
                                        </LAGridItem>

                                        {attachments.length > 0 && attachments.map((x, index) => {
                                            const onDownload = (): void => this.handleDownload(x);
                                            const onRemove = (): void => this.handleFileRemove(index);
                                            // const onChange = (name: string, value: string): void => this.handleAtatachmentNameChange(value + "." + x.name.split(".")[1], index);
                                        
                                            return (
                                                <LAGridItem xs={12} sm={4} md={2} key={index} className="text-center">
                                                    <LAPaperWithLessPadding>
                                                        <strong>{x.name}</strong>
                                                        <br /><br />
                                                        {/* <LATextField
                                                            name="name"
                                                            label="Description"
                                                            onChange={onChange}
                                                            value={x.name.split(".")[0]}
                                                        /> */}
                                                        <LAIconButton
                                                            name="file"
                                                            label={x.name}
                                                            id={(index).toString()}
                                                            onClick={onDownload}
                                                            icon={<DownloadIcon />}
                                                        />

                                                        <LAIconButton
                                                            key={index}
                                                            label="Delete"
                                                            icon={<DeleteIcon />}
                                                            onClick={onRemove}
                                                        />
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            )
                                        })}
                                    </LAGrid>
                                </LABluePaper>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }
                    <RequestStatus requestStatus={addSOCTStatus.kind} successMessage="SOCT record successfully saved" />
                    <RequestStatus requestStatus={updateSOCTStatus.kind} successMessage="SOCT record successfully updated" /> 
                    
                </AddUpdateSOCTStyles>

            </PageSpacing>
        );
    }

    private handleUnitNumberClick = (): void => {
        window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT + "?id=" + this.state.data.unit_ID.toString())
    };

    private handleTabClick = (e: any, url: string): void => {
        const query = queryString.parse(this.props.location.search);
        if(e.target.textContent === "Unit History") {
            if(this.state.data.unit_ID)
                window.open(url + "?id=" + this.state.data.unit_ID.toString(), "_blank")
        }
        else {
            if(query && query.id) {
                callRouteWithQueryString({
                    route: this.props,
                    search: { id: query.id.toString(), token: getTokenFromUrl(true) },
                    pathName: url
                });
            }
        }
    };

    // private handleMachineHistory = (data: ISOCT): void => {
    //     window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY + "?id=" + data.unit_ID.toString(), "_blank")
    // };

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        let errors = { ...this.state.errors };
        if (value === undefined) {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value.toString(), errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
            },
            errors
        });
    };

    private handleAttachment = (files: Array<File | DataTransferItem>): void => {
        const newFiles: IShopEquipmentFile[] = [...this.state.attachments];

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const check = newFiles.findIndex(z => z.name === x.name);
                            if (check === -1) {
                                newFiles.push({
                                    name: x.name,
                                    base64String: reader.result.toString()
                                });
                            } else {
                                newFiles[check].base64String = reader.result.toString()
                            };

                            if (files.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                attachments: newFiles
            });
        })
    };

    private handleFileRemove = (index: number): void => {
        const iS = [...this.state.attachments];
        const selection = window.confirm(deleteText);

        if (selection) {
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                attachments: iS
            });
        }
    };

    private handleDownload = (file: IShopEquipmentFile): void => {
        if (file.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            this.downloadFromEndpoint(file.name);
        }
    };

    private downloadFromEndpoint = async (fileName: string): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.getToken)) {
                // console.log(fileName, this.state.data.id)
                await fetch(END_POINTS.FIELD.SCOT.DOWNLOAD_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            File_Name: fileName,
                            ID: this.state.data.id,
                            Page:"SOCT",
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleAtatachmentNameChange = (value: string, index: number): void => {
        const attachments = [...this.state.attachments];
        attachments[index] = { ...attachments[index], name: value };
        this.setState({ attachments });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
       
        this.props.history.push({
            pathname: ROUTE.FIELD.SOCT.SOCT_LIST,
            search: getTokenFromUrl(false)
        });
       
    };

    private handleSave = (): void => {
        const { data, attachments } = this.state;

        if (hasPayload(this.props.getToken)) {

            if (data.id === 0) {
                let request: IAddUpdateSOCT = {
                    ID: data.id,
                    Unit_ID: data.unit_ID,
                    Service_Date: data.service_Date,
                    ECM: Number(data.ecm),
                    Current_Hours: Number(data.current_Hours),
                    Comments: data.comments,
                    Geotab_Sync: data.geotab_Sync,
                    Files: attachments,
                    Created_By: this.props.getToken.payload.response.upn,
                    Modified_By: this.props.getToken.payload.response.upn,
                };
                // console.log(request)
                this.props.addSOCTRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                let updateRequest: IAddUpdateSOCT = {
                    ID: data.id,
                    Unit_ID: data.unit_ID,
                    Service_Date: data.service_Date,
                    ECM: Number(data.ecm),
                    Current_Hours: Number(data.current_Hours),
                    Comments: data.comments,
                    Geotab_Sync: data.geotab_Sync,
                    Files: attachments,
                    Reading_Type_Notes: data.reading_Type_Notes,
                    Created_By: this.props.getToken.payload.response.upn,
                    Modified_By: this.props.getToken.payload.response.upn,
                };

                this.props.updateSOCTRequest({
                    token: this.props.getToken.payload.response.token,
                    request : updateRequest
                });
            };

            this.setState({ serverError: "" });
        }
            

        this.setState({  serverError: "" });

    };


    private handleChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            errors,
            data: {
                ...this.state.data,
                [name]: value
            }
        });
    };

    private handleNumber = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, Number(value) > 0 ? value : "", errors);

        if(name === "meter_Reset") {
            errors = this.errorChecker(name, Number(value) > 0 ? value : "", errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: Number(value)
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any =  queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.getToken))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.getToken)) {
                if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {

                    if (isNotLoaded(this.props.getLookup))
                        this.getLook();

                    if (query.id !== "0") {
                        if(isNotLoaded(this.props.getSOCTByID)) {
                            this.getData();
                        }

                        if(hasPayload(this.props.getSOCTByID)) {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getSOCTByID.payload.response;
                                // const disabledForm = !data.latest ? true : undefined;
                                const attachments: IShopEquipmentFile[] = [];
                                if (data) {
                                    const existingAtt = data.attachments ? data.attachments.split(";") : [];

                                    existingAtt.forEach(x => {
                                        attachments.push({ name: x, base64String: "" });
                                    });
                                }

                                this.setState({
                                    data,
                                    attachments
                                });
                            };
                        } 
                    }
                    else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    }

                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.getToken))
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "SOCT_Main"
                }
            });
    };

    private getData = () : void => {
        if (hasPayload(this.props.getToken)) {
            const query: any =  queryString.parse(this.props.location.search);

            if (query !== undefined && query.id !== undefined && Number(query.id) > 0) {
                this.props.getSOCTByIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: Number(query.id)
                    }
                });
            }
        }
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateSOCTStoreProps => ({
    getToken: getToken(state),
    getLookup: getSOCTLookups(state),
    getSOCTByID: getSOCTById(state),
    addSOCTStatus: addSOCTStatus(state),
    updateSOCTStatus: updateSOCTStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateSOCTDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISOCTLookupRequest) => dispatch(getSOCTLookupsLoadAction(data)),
    getSOCTByIDRequest: (request: IGetSOCTByIdRequest) => dispatch(getSOCTByIdLoadAction(request)),
    addSOCTRequest: (data: IAddSOCTRequest) => dispatch(addSOCTLoadAction(data)),
    updateSOCTRequest: (data: IUpdateSOCTRequest) => dispatch(updateSOCTLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateSOCT);