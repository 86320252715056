import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_TYPES_REQUEST, IAttachmentType, IGetTypeRequest } from "./getTypesConstants";

export interface IGetTypesLoadAction {
    type: IGET_TYPES_REQUEST.REQUEST;
    data: IGetTypeRequest
}
export const getTypesLoadAction = (data: IGetTypeRequest): IGetTypesLoadAction => ({
    type: IGET_TYPES_REQUEST.REQUEST,
    data
});
export interface IGetTypesSuccessAction {
    type: IGET_TYPES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IAttachmentType>>;
}
export const getTypesLoadSuccessAction = (list: SurewayAPIResponse<ById<IAttachmentType>>): IGetTypesSuccessAction => ({
    type: IGET_TYPES_REQUEST.SUCCESS,
    list
});
export interface IGetTypesLoadFailedAction {
    type: IGET_TYPES_REQUEST.FAILED;
    message: string;
}
export const getTypesLoadFailedAction = (message: string): IGetTypesLoadFailedAction => ({
    type: IGET_TYPES_REQUEST.FAILED,
    message
});
