import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_LEVEL_REQUEST } from "./updateLevelConstants";
import {
    IUpdateLevelLoadAction, IUpdateLevelLoadFailedAction, IUpdateLevelSuccessAction,
    updateLevelLoadFailedAction, updateLevelLoadSuccessAction
} from "./updateLevelActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateLevelEpic: Epic = (
    action$: ActionsObservable<IUpdateLevelLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateLevelSuccessAction | IUpdateLevelLoadFailedAction> =>
    action$.ofType(IUPDATE_LEVEL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_LEVEL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateLevelSuccessAction | IUpdateLevelLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateLevelLoadSuccessAction(response);
                            } else {
                                return updateLevelLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateLevelLoadFailedAction(response.message)))
                    )
            )
        );