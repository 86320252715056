
export enum IBULK_MOVE_REQUEST {
    REQUEST = "bulkMove/BULK_MOVE_REQUEST",
    SUCCESS = "bulkMove/BULK_MOVE_SUCCESS",
    FAILED = "bulkMove/BULK_MOVE_FAILED"
};

export interface IBulkMoveRequest {
    token: string;
    request: IBulkMove;
};

export interface IBulkMove {
    Units: number[];
    Requested_By:string,
    From_Sub_Location_ID: number ,
    To_Sub_Location_ID: number ,
    Completed_Date:string,
    PickUp_Contact: string,
    DropOff_Contact:string,
    Moved_Previously: string;
    Drive_Over: string;
    Send_Email: string;
    Details: string;
    Moved: string;
    Created_By: string;
};