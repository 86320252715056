import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_VENDOR_PO_LOGS_REQUEST, IVendorPOLogs, IGetVendorPOLogsRequest } from "./getVendorPOLogsConstants";

export interface IGetVendorPOLogsLoadAction {
    type: IGET_VENDOR_PO_LOGS_REQUEST.REQUEST;
    data: IGetVendorPOLogsRequest
}
export const getVendorPOLogsLoadAction = (data: IGetVendorPOLogsRequest): IGetVendorPOLogsLoadAction => ({
    type: IGET_VENDOR_PO_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetVendorPOLogsSuccessAction {
    type: IGET_VENDOR_PO_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IVendorPOLogs>>;
}
export const getVendorPOLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IVendorPOLogs>>): IGetVendorPOLogsSuccessAction => ({
    type: IGET_VENDOR_PO_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetVendorPOLogsLoadFailedAction {
    type: IGET_VENDOR_PO_LOGS_REQUEST.FAILED;
    message: string;
}
export const getVendorPOLogsLoadFailedAction = (message: string): IGetVendorPOLogsLoadFailedAction => ({
    type: IGET_VENDOR_PO_LOGS_REQUEST.FAILED,
    message
});
