import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_PM_SHEET_REPORT_REQUEST {
    REQUEST = "getPMSheetsReport/GET_PM_SHEET_REPORT_REQUEST",
    SUCCESS = "getPMSheetsReport/GET_PM_SHEET_REPORT_SUCCESS",
    FAILED = "getPMSheetsReport/GET_PM_SHEET_REPORT_FAILED"
};

export interface IGetPMSheetsReportRequest extends ISurewayTokenRequestBody {
    request: {
        View: string;
    }
};

export interface IPMSheetsReport {
  id: number;
  unit_No: string;
  location: string;
  sub_Location: string;
  service_Due: string;
  comments: string;
  admin_Comments: string;
  status: string;
  service_Date: string;
  approved_Date: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
};
