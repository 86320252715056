import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_HAZARDS_REQUEST, IGetHazards } from "./getHazardsConstants";
import { IGetHazardsLoadAction, IGetHazardsLoadFailedAction, IGetHazardsSuccessAction } from "./getHazardsActions";
import { IAddHazardSuccessAction } from "../addHazard/addHazardActions";
import { IADD_HAZARD_REQUEST } from "../addHazard/addHazardConstants";
import { IUpdateHazardSuccessAction } from "../updateHazard/updateHazardActions";
import { IUPDATE_HAZARD_REQUEST } from "../updateHazard/updateHazardConstants";
import { IADD_HAZARD_NEW_REQUEST } from "../addHazardNew/addHazardNewConstants";
import { IUPDATE_HAZARD_NEW_REQUEST } from "../updateHazardNew/updateHazardNewConstants";
import { IUpdateHazardNewSuccessAction } from "../updateHazardNew/updateHazardNewActions";
import { IAddHazardNewSuccessAction } from "../addHazardNew/addHazardNewActions";

type Actions =
    | IGetHazardsLoadAction
    | IGetHazardsSuccessAction
    | IGetHazardsLoadFailedAction
    | IAddHazardSuccessAction
    | IUpdateHazardSuccessAction
    | IAddHazardNewSuccessAction
    | IUpdateHazardNewSuccessAction
    | IFlushDataSuccessAction;

export const GetHazardsReducer = (state: Server<SurewayAPIResponse<ById<IGetHazards>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetHazards>>> => {
    switch (action.type) {
        case IGET_HAZARDS_REQUEST.REQUEST:
            return loading;

        case IGET_HAZARDS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_HAZARDS_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_HAZARD_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_HAZARD_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_HAZARD_NEW_REQUEST.SUCCESS:
                    return notLoaded;
        
        case IUPDATE_HAZARD_NEW_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};