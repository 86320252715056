import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IAddRepeaterKitLoadAction, IAddRepeaterKitLoadFailedAction, IAddRepeaterKitSuccessAction } from "./addRepeaterKitActions";
import { IADD_REPEATER_KIT_REQUEST } from "./addRepeaterKitConstants";

type Actions =
    | IAddRepeaterKitLoadAction
    | IAddRepeaterKitSuccessAction
    | IAddRepeaterKitLoadFailedAction
    | IFlushDataSuccessAction;

export const AddRepeaterKitReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_REPEATER_KIT_REQUEST.REQUEST:
            return loading;

        case IADD_REPEATER_KIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IADD_REPEATER_KIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};