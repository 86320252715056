import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Export, Selection,
  Editing,
  Button,
  Lookup,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, FormControl, RadioGroup, Radio, FormControlLabel, Modal, Box, TextField, Backdrop} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';
  import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import NavigationBar from './navbar';
import SOCTHome, {currentUser, hasUserAccess} from './socthome';
import {ApproveMeterApp, RejectMeterApp, getSOCTMeterAppHistoryByID} from './api/meterreading/meterReadingAPI';
import {getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID} from './api/workingsites/workingSitesAPI';
import { getEquipmentInfoByUnitNumber, getSOCTUnitByID } from './api/soct/soctAPI';
import { FormHelperText } from '@material-ui/core';
import { LAIconButton } from '../../shared/buttons';
import { AddIcon } from '../../shared/icons';
import { WarningIcon } from '../../shared/icons';
import MeterAppVersionHistory from './meterAppVersionHistory';
import { SOCT_URL } from '../../shared/styles';

interface IState {
  soctMaster:any;
  open:boolean;
  view:string;
  showRejectionPopup:boolean;
  showImage:boolean;
  openImage:boolean;
  time:any;
  showVersionHistory:boolean;
  searchValue: string;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
  }
  const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
  };
  const ExitButtonStyle = {
      bgcolor: 'rgb(174, 180, 184)',
      color: 'white',
      fontWeight: 'bold',
      left:'90%'
  }
var globalEditID:Number = 0;
var renderPageType:string = '';
var approvedData:any[] = [];
var tempApprovedData:any[] = [];
var copy_list:any[] = [];
var working_site_options:any[] = [];
var sub_site_options:any[] = [];
var rejection_notes:string = "";
var rejection_object:any = null;
var imageFromString:any = "";
var version_id:number = 0;

export default class MeterAppTable extends React.Component<IProps,IState>{  
  timerID:number = 0;
  constructor(props:any){
    super(props);
    this.state ={soctMaster:[], open:false, view:'draft', showRejectionPopup:false, showImage:false,openImage:false, time:new Date(), showVersionHistory:false, searchValue: ''};
    
    this.selectedRowKeys = this.selectedRowKeys.bind(this);
    this.handleApproved = this.handleApproved.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.filterView = this.filterView.bind(this);
    this.setWorkingSiteLocation = this.setWorkingSiteLocation.bind(this);
    this.setSubWorkingSiteLocation = this.setSubWorkingSiteLocation.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.cancelRejectionPopup = this.cancelRejectionPopup.bind(this);
    this.confirmRejectionPopup = this.confirmRejectionPopup.bind(this);
    this.closeImage = this.closeImage.bind(this);
    this.handleVersionHistory = this.handleVersionHistory.bind(this);
    this.closeVersionHistory = this.closeVersionHistory.bind(this);
  }
  closeVersionHistory(){
    this.setState({showVersionHistory:false});
  }
  async handleVersionHistory(id:number){
    version_id = id;
    this.setState({showVersionHistory:true});
  }
  closeImage(){
    this.setState({showImage:false});
  }
  handleReject(event:any){
    rejection_object = event.row.data;
    this.setState({showRejectionPopup:true});
  }
  cancelRejectionPopup(){
    this.setState({showRejectionPopup:false});
    rejection_notes = "";
    rejection_object = null;
  }
  async confirmRejectionPopup(){
    var res:any = await RejectMeterApp(rejection_object.id, rejection_notes, currentUser);
    if(res.status === 200){
      alert("Successfully rejected meter reading from app");
      this.setState({showRejectionPopup:false});
      this.refreshState();
    }
    else{
      alert("Server error: refresh page and try again");
    }
    rejection_notes = "";
    rejection_object = null;
  }
  setSubWorkingSiteLocation(rowData:any, value:any){
    rowData.sub_Location = value.sub_Site_Name;
    this.setState({});
  }
  async setWorkingSiteLocation(rowData:any, value:any){
    rowData.location = value.site_Name;
    sub_site_options = await getSOCTWorkingSubSitesByParentID(value.id, false);
    this.setState({});  
  }
  filterView(eventKey:any){
    switch(eventKey.target.value) {
        case 'draft':
            this.setState({view:'draft'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status != "Approved")});
            this.setState({soctMaster:filtered_list});
            break;
        case 'approved':
            this.setState({view:'approved'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status != "Draft")});
            this.setState({soctMaster:filtered_list});
            break;
        case 'all':
            this.setState({view:'all'});
            this.refreshState();
            var filtered_list = copy_list.filter((elem:any) => { return (elem.status === "Draft" || elem.status === "Approved" || elem.status ==="Rejected")});
            this.setState({soctMaster:filtered_list});
            break;
       }
  }
  async handleApproved(){
    var temp_approved:any[] = [];
    var final_approved:any[] = [];
      approvedData.reverse().map((second:any) => {
        if(!temp_approved.some(elem => (elem.id === second.id))){
          temp_approved.push(second);
        }
      });
      temp_approved.map((elem:any) => {
        if(tempApprovedData.some(second => second.id === elem.id)){
          final_approved.push(elem);
        }
      });
    let error = false;
   
    if(final_approved!=null && final_approved.length>0){
        for(var i=0; i< final_approved.length; i++){
            let soct_unit_info:any = await getSOCTUnitByID(final_approved[i].socT_ID);
            console.log(soct_unit_info)
            if((final_approved[i].location === "" || final_approved[i].location===null) || (final_approved[i].sub_Location==="" || final_approved[i].sub_Location===null) 
            || (final_approved[i].ecm === null && final_approved[i].current_Hours===null)){
                alert("Cannot approve record with empty location and or sub location, and current hours or ecm are mandatory");
                error = true;
            }
            if((final_approved[i].current_Hours < soct_unit_info.current_Hours) && (final_approved[0].previous_ECM_Reading==='' || final_approved[0].previous_ECM_Reading===null)){
              alert("Cannot approve record with current hours less than the machine currently has");
              error = true;
            }
        }
        if(!error){
            var res:any = await ApproveMeterApp(final_approved, currentUser);
            if(res.status === 200){
                alert("Successfully approved meter readings for app");
                this.refreshState();
            } 
            else{
                alert("Error approving meter readings for app");
            }
        }
    }
    else{
        alert("At least one record must be selected to approve!")
    }
  }
  onEditChange(event:any){
    let temp_row = event.row;
    if(temp_row){
      approvedData.push(temp_row.data);
    }
    
  }
  selectedRowKeys(event:any){
    tempApprovedData = event.selectedRowsData;
    //this.saveEdits(event);
  }
   refreshState(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
  // api.sureway.ca
     fetch(SOCT_URL+'/api/SOCT/GetSOCTMeterApp', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            console.log(data, response)
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
      //  console.log(data)
        data.map((history:any) => {
            history.temp_location_id = 0;
        //    console.log('here 0', this.state.view, history.status)
            if(this.state.view === 'draft'){    
                if(history.status === 'Draft'){
                    master_temp_list.push(history);
                   // console.log('here')
                }
            }
            else if(this.state.view === 'approved'){
                if(history.status === 'Approved'){
                    master_temp_list.push(history);
                  //  console.log('here 2')
                }
            }
            else{
                master_temp_list.push(history);
              //  console.log('here 3')
            }
        });
        // console.log(master_temp_list)
        // for(var i=0; i< master_temp_list.length; i++) {
        //   //var res:any = await getEquipmentInfoByUnitNumber(master_temp_list[i].unit_Number);
        
        //   var res:any = await getSOCTUnitByID(master_temp_list[i].socT_ID);
        //   let previous_meter_date = res.meter_Date;
        //   master_temp_list[i].previous_Meter_Date = previous_meter_date;
        // }
        copy_list = master_temp_list;
        console.log(copy_list)
        this.setState({soctMaster:master_temp_list});
    });
   
  }
  
  tick(){
    this.setState({time:new Date()});
    window.location.reload();
  }
  componentWillUnmount(){
    clearInterval(this.timerID);
  }
  async componentDidMount(){
    var result:any = await getSOCTWorkingSites(false);
    result.map((location:any) => {
        working_site_options.push(location);
    });
     this.refreshState();
     this.timerID = window.setInterval(()=>this.tick(),300000);
  }
  handleValueChange = (e:any) => {
    // console.log(e);
    const trimmedValue = e.trim();
    this.setState({searchValue:trimmedValue });
  };
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
            
              <Card className='dataGrid-card' variant="outlined" >
            <MUIButton disabled={this.state.view!='draft' || hasUserAccess} onClick={this.handleApproved} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" >Approve</MUIButton>
            {/* <MUIButton disabled={this.state.view!='draft' || hasUserAccess} onClick={this.handleApproved} style={{backgroundColor:'#bf0000', left:'45%'}} variant="contained" >Reject</MUIButton> */}
            <Typography className='dataGrid-title'>Meter App Update</Typography>
            
            <Grid item xs={3}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={this.filterView}
                        defaultValue={"draft"}
                    >
                        <FormControlLabel value="draft" control={<Radio size="small"/>} label="Draft" />
                        <FormControlLabel value="approved" control={<Radio size="small"/>} label="Approved" />
                        <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                        </RadioGroup>
                </FormControl>
            </Grid>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.soctMaster}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                onSelectionChanged={this.selectedRowKeys}
                onEditorPrepared={this.onEditChange}
                >
                    <Editing mode="batch" allowUpdating={true}></Editing> 
            <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                // text={this.state.searchValue}
                // onTextChange={this.handleValueChange}
                />    
              <Paging enabled={true} defaultPageSize={20}/>
              <Pager
                visible={true}
                allowedPageSizes={[20,40,60]}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
                <FilterRow visible={true}></FilterRow>
                <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     <FilterRow visible={true}></FilterRow>
      <FilterPanel visible={true} />
      <Export enabled={true} allowExportSelectedData={true} />
       <Column allowEditing={false} dataField="unit_Number" caption="Unit #" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       {/* <Column allowEditing={false} dataField="make" caption="Make" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="model" caption="Model" alignment="left" width={80} headerCellRender={renderTitleHeader}/> */}
       <Column allowEditing={false} dataField="previous_Reading" caption="Previous Reading" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="previous_Meter_Date" dataType="date" caption="Previous Meter Date" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="date" dataType="date" caption="Current Meter Date" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="current_Hours" caption="Current Hours" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="previous_ECM_Reading" caption="Previous ECM" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       {/* <Column allowEditing={this.state.view==='draft'} dataField="location" caption="Location" alignment="left" width={200} headerCellRender={renderTitleHeader} setCellValue={this.setWorkingSiteLocation}>
        <Lookup dataSource={working_site_options} displayExpr="site_Name" ></Lookup>
       </Column>
       <Column allowEditing={this.state.view==='draft'} dataField="sub_Location" caption="Sub Location" alignment="left" width={200} headerCellRender={renderTitleHeader} setCellValue={this.setSubWorkingSiteLocation}>
        <Lookup dataSource={sub_site_options} displayExpr="sub_Site_Name"></Lookup>
       </Column> */}
       {/* <Column allowEditing={this.state.view==='draft'} dataField="service_Truck_Unit_Number" caption="Service Truck" alignment="left" width={200} headerCellRender={renderTitleHeader} setCellValue={this.setWorkingSiteLocation}>
        <Lookup dataSource={service_truck_options} displayExpr="service_Truck_Unit_Number" ></Lookup>
       </Column> */}
       <Column allowEditing={false} dataField="location" caption="Location" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="sub_Location" caption="Sub Location" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="status" caption="Status" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column allowEditing={this.state.view==='draft'} dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="created" dataType="datetime" caption="Created" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column allowEditing={false} dataField="created_By" caption="Created By" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       {this.state.view === 'draft' ?  <Column type="buttons" caption="Reject?" alignment="left" width={100} headerCellRender={renderTitleHeader}>
          <Button disabled={hasUserAccess} onClick={this.handleReject} cssClass="reject-btn">Reject</Button>
       </Column>:null}
       <Column allowEditing={false} type="buttons" caption="Image" alignment="left" width={80} headerCellRender={renderTitleHeader}>
         <Button text={"View"} onClick={(e:any) =>{ imageFromString = e.row.data.base64Image;this.setState({showImage:true});}}></Button>
       </Column>
       {this.state.showImage && imageFromString!=null && imageFromString!="" ? <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.showImage}
                    onClose={this.closeImage}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}><img src={`data:image/jpeg;base64,${imageFromString}`} width={"100%"} height={"100%"} /></Box></Modal>:null}
       {this.state.view === 'all' ? <Column allowEditing={false} dataField="rejection_Comments" caption="Rejection Comments" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       :null}
         <Column type="buttons" caption="Version History" headerCellRender={renderTitleHeader} cellRender={(e:any) => {
          return  <LAIconButton label="History" icon={<WarningIcon/>} onClick={(s:any)=>{console.log(e);this.handleVersionHistory(e.row.data.id)}}></LAIconButton>
         }}>
       </Column>
       {this.state.view==='draft' ?
       <Selection
            mode="multiple"
            selectAllMode={'page'}
            showCheckBoxesMode={'onClick'}
          />:null}
            </DataGrid>
            {this.state.showRejectionPopup ? <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <MUIButton sx={ExitButtonStyle} onClick={this.cancelRejectionPopup}>X</MUIButton>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Rejection Confirmation</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField fullWidth multiline rows={3} label="Reason for rejection" name="rejection_notes" onChange={(e:any) => rejection_notes=e.target.value}></TextField>
                        <FormHelperText>Optional, but helps indicate reason for rejection</FormHelperText>
                      </Grid>
                      <Grid item xs={12}>
                      <MUIButton style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.confirmRejectionPopup}>Confirm</MUIButton>
                      </Grid>
                      </Grid></Box></Modal>:null}
                      {this.state.showVersionHistory ? <MeterAppVersionHistory id={version_id} onCancel={this.closeVersionHistory} open={this.state.showVersionHistory}></MeterAppVersionHistory>:null}
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};