import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST, IGetUnitInspectionFormByID } from "./getUnitInspectionFormByIDConstants";
import { IGetUnitInspectionFormByIDLoadAction, IGetUnitInspectionFormByIDLoadFailedAction, IGetUnitInspectionFormByIDSuccessAction } from "./getUnitInspectionFormByIDActions";
import { ISAVE_INSPECTION_SHEET_REQUEST } from "../saveInspectionSheet/saveInspectionSheetConstants";
import { ISaveInspectionSheetSuccessAction } from "../saveInspectionSheet/saveInspectionSheetActions";

type Actions =
    | IGetUnitInspectionFormByIDLoadAction
    | IGetUnitInspectionFormByIDSuccessAction
    | IGetUnitInspectionFormByIDLoadFailedAction
    | ISaveInspectionSheetSuccessAction
    | IFlushDataSuccessAction;

export const GetUnitInspectionFormByIDReducer = (state: Server<SurewayAPIResponse<IGetUnitInspectionFormByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetUnitInspectionFormByID>> => {
    switch (action.type) {
        case IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_INSPECTION_SHEET_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};