import { MechanicTokenStoreName } from "../redux/field/mechanics/mechanicLocalToken/mechanicLocalTokenConstants";

const LOGIN_INFO = "iLogin";
const INDEX_DB_INFO = "indexDB";

export enum INDEX_DB_AVAILABILITY {
    UNAVAILABLE = "0",
    AVAILABLE = "1"
}

// intentionally static class defined
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class LocalStorage {
    private static localStorage: Storage = window.localStorage;

    public static setILogin = (login: any): void => {
        LocalStorage.localStorage.setItem(LOGIN_INFO, JSON.stringify(login));
    };

    public static setRefreshToken = (session: any): void => {
        let login = LocalStorage.getILogin();
        if (login) {
            login = session;
            LocalStorage.setILogin(login);
        } else { // this should not happen
            LocalStorage.localStorage.setItem(LOGIN_INFO, JSON.stringify(session));
        }
    };

    public static getILogin = (): any | undefined => {
        const login = LocalStorage.localStorage.getItem(LOGIN_INFO);
        if (login) {
            return JSON.parse(login);
        }
        return undefined;
    };

    public static mechanicLogOut = (): void => {
        LocalStorage.removeItem(MechanicTokenStoreName);
    };

    public static logout = (): void => {
        LocalStorage.removeItem(LOGIN_INFO);
        LocalStorage.setIndexDBInfo(INDEX_DB_AVAILABILITY.UNAVAILABLE);
        LocalStorage.clearIndexDBTables();
    };

    public static clearEntireLocalStorageOnLogout = (): void => {
        LocalStorage.clearLocalStorage();
    }

    public static flushData = (): void => {
        LocalStorage.setIndexDBInfo(INDEX_DB_AVAILABILITY.UNAVAILABLE); 
    };
    
    public static setIndexDBInfo = (available: INDEX_DB_AVAILABILITY): void => {
        LocalStorage.localStorage.setItem(INDEX_DB_INFO, available);
    };
    
    public static isIndexDBAvailable = (): boolean => {
        const available = LocalStorage.localStorage.getItem(INDEX_DB_INFO);
        return available === INDEX_DB_AVAILABILITY.AVAILABLE;
    };

    public static removeILogin(): void {
        this.localStorage.removeItem(LOGIN_INFO);
    }

    private static clearLocalStorage = (): void => {
        LocalStorage.localStorage.clear();
    }

    private static removeItem = (name: string): void => {
        LocalStorage.localStorage.removeItem(name);
    };

    private static clearIndexDBTables = (): void => {
        // deleteAllIndexDB();
    };
}

export default LocalStorage;