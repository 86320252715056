import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IADD_JOB_REQUEST {
    REQUEST = "addJob/ADD_JOB_REQUEST",
    SUCCESS = "addJob/ADD_JOB_SUCCESS",
    FAILED = "addJob/ADD_JOB_FAILED"
};

export interface IAddJobRequest extends ISurewayTokenRequestBody {
    request: {
        Job_No: string;
        Year: string;
        Client: string;
        Name: string;
        Coordinator: string;
        Project_Manager: string;
        Superintendent: string;
        Location: string;
        Company: string;
        Location_Id?: number;
        Working_Site: string;
        Type: string;
        Active: string;
        Created_By: string;
        Modified_By: string;
    };
};