import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_BASE_KIT_REQUEST, IBaseKit } from "./getBaseKitsConstants";
import { IGetBaseKitLoadAction, IGetBaseKitLoadFailedAction, IGetBaseKitSuccessAction } from "./getBaseKitsActions";
import { IAddBaseKitSuccessAction } from "../addBaseKit/addBaseKitActions";
import { IADD_BASE_KIT_REQUEST } from "../addBaseKit/addBaseKitConstants";
import { IUpdateBaseKitSuccessAction } from "../updateBaseKit/updateBaseKitActions";
import { IUPDATE_BASE_KIT_REQUEST } from "../updateBaseKit/updateBaseKitConstants";

type Actions =
    | IGetBaseKitLoadAction
    | IGetBaseKitSuccessAction
    | IGetBaseKitLoadFailedAction
    | IUpdateBaseKitSuccessAction
    | IAddBaseKitSuccessAction
    | IFlushDataSuccessAction;

export const GetBaseKitReducer = (state: Server<SurewayAPIResponse<ById<IBaseKit>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IBaseKit>>> => {
    switch (action.type) {
        case IGET_BASE_KIT_REQUEST.REQUEST:
            return loading;

        case IGET_BASE_KIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_BASE_KIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_BASE_KIT_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_BASE_KIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};