import React from 'react';
import '../../../App.css';
import NavigationBar from './navbar';
import jwt_decode from "jwt-decode";
import { getSOCTUserRole } from './api/soct/soctAPI';
import { ROUTE } from '../../routes';
import { token } from '../../shared/constExports';

const getToken = localStorage.getItem("adal.idtoken");
// const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\amit.patel" };
//const token: any = (getToken !== null && getToken.length > 0) ? jwt_decode(getToken) : { unique_name: "SUREWAYGROUP\\sp.test1" };
const currentUser = token.unique_name.substr(token.unique_name.lastIndexOf("\\") + 1);

interface IProps{
  history:any
}

var hasUserAccess:boolean = false;
export default class SOCTHome extends React.Component<IProps,{}>{

  handleRoute(id:string){
    switch(id){
      case 'home':
        this.props.history.push('/');
        break;
      case 'sotc':
        this.props.history.push('/field/soct/lists');
        break;
      case 'meters':
        this.props.history.push('/field/soct/meters');
        break;
      case 's_service':
        this.props.history.push('/field/soct/scheduledservice');
        break;
      case 'c_service':
        this.props.history.push('/field/soct/customservice');
        break;
      case 'sites':
        this.props.history.push('/field/soct/sites');
        break;
      case 'moves':
        this.props.history.push('/field/soct/moves');
        break;
      case 'foremans':
        this.props.history.push('/field/soct/foremans');
        break;
      case 'trucks':
        this.props.history.push('/field/soct/trucking');
        break;
      case 'bulkedit':
        this.props.history.push('/field/soct/bulk-meter-readings');
        break;
      case 'meterapp':
        this.props.history.push('/field/soct/meterapp');
        break;
        case 'report':
          this.props.history.push(ROUTE.FIELD.SOCT.SOCT_REPORT);
          break;
    }
  }

  async componentDidMount(){
    // console.log(currentUser)
    var res:any = await getSOCTUserRole(currentUser);
    if(res.isAuthenticated){
      if(res.role === 'User'){
        hasUserAccess = true;
      }
      // console.log(res)
      if(res.role !='Admin' && res.role!='User'){
        this.props.history.push('/');
      }
    }
  }

  render(){
  return (
    <div className="SOCT-App">
      <div className="SOCT-App-header">
        <p>
        <NavigationBar handleRoute={this.handleRoute.bind(this)}></NavigationBar>
        </p>
      </div>
      
    </div>
  );
  }
}
export {currentUser, hasUserAccess};