import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_CONTROL_PLAN_REQUEST, IAddControlPlanRequest } from "./addControlPlanConstants";


export interface IAddControlPlanLoadAction {
    type: IADD_CONTROL_PLAN_REQUEST.REQUEST;
    data: IAddControlPlanRequest
}
export const addControlPlanLoadAction = (data: IAddControlPlanRequest): IAddControlPlanLoadAction => ({
    type: IADD_CONTROL_PLAN_REQUEST.REQUEST,
    data
});
export interface IAddControlPlanSuccessAction {
    type: IADD_CONTROL_PLAN_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addControlPlanLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddControlPlanSuccessAction => ({
    type: IADD_CONTROL_PLAN_REQUEST.SUCCESS,
    message
});
export interface IAddControlPlanLoadFailedAction {
    type: IADD_CONTROL_PLAN_REQUEST.FAILED;
    message: string;
}
export const addControlPlanLoadFailedAction = (message: string): IAddControlPlanLoadFailedAction => ({
    type: IADD_CONTROL_PLAN_REQUEST.FAILED,
    message
});
