import React from 'react';
import {TextField, Select, Paper, Grid, Button, Typography, FormControl, InputLabel, MenuItem, FormControlLabel, Checkbox, FormHelperText} from '@mui/material';
import DataGrid, {
    Column, Editing, Selection,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {RouteComponentProps} from 'react-router-dom';
import SubWorkingSiteTable from './subworkingsites/subWorkingSiteTable';
import {editData, globaleditID} from '../../workingsites';
import { getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID, updateSOCTWorkingSites, checkWorkingSiteUsed } from '../../api/workingsites/workingSitesAPI';
import { isUnique } from './subworkingsites/subWorkingSiteTable';
import SOCTHome, { currentUser, hasUserAccess } from '../../socthome';
import { LocationSubHeader } from '../../../../header/locationSubHeader';

const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    subSites:any[];
}
//var newWorkingSiteChanges = {notes:'', active:true};
var old_site_name:any;
var subSitesList:any[] = [{sub_Site_Name:'', sub_Site_Address:'', active:true,notes:'', id:0, latitude:'', longitude:''}];
export default class EditWorkingSite extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {subSites:[]};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.renderSubSiteTable = this.renderSubSiteTable.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    renderSubSiteTable(){
        this.setState({});
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'notes':
                editData.notes = event.target.value;
                break;
            case 'active':
                editData.active = !editData.active;
                break;
            case 'site_name':
                editData.site_Name = event.target.value;
                break;
            case 'rental_status':
                editData.rental_Status = !editData.rental_Status;
                break;
            // case 'latitude':
            //     editData.latitude = event.target.value;
            //     break;
            // case 'longitude':
            //     editData.longitude = event.target.value;
            //     break;
        }
        this.setState({});
    }
    async onFormSave(){
        let isUnique = true;
        var removedNullWorkingSite =[];
        for(var index=0;index<subSitesList.length; index++){
            if(subSitesList[index].sub_Site_Name != ""){
                removedNullWorkingSite.push(subSitesList[index]);
            }
        }
        var result:any = await getSOCTWorkingSites(false);
        result.map((site:any) => {
            if((site.site_Name != old_site_name) && site.site_Name === editData.site_Name){
                isUnique = false;
            }
        });
        if(isUnique){
            var isSiteUsed:any = await checkWorkingSiteUsed(old_site_name);
            if(isSiteUsed && old_site_name != editData.site_Name){
                alert("Old site name has already been used in a meter reading, service, equipment move or soct unit. Cannot change name");
            }
            else{
                var res:any = await updateSOCTWorkingSites(editData, removedNullWorkingSite, currentUser);
                subSitesList = [{sub_Site_Name:'', sub_Site_Address:'', active:true,notes:'', id:0, latitude:'', longitude:''}]
                if(res.status === 200){
                    alert("Successfully updated working site and sub sites");
                    this.props.history.goBack();
                }
                else{
                    alert("Failed to update working site and sub sites");
                }
            }
        }
        else{
            alert("Site name already exists!");
        }
    }
    async componentDidMount(){
        console.log('editing: ', globaleditID, editData);
        if(editData.active === "False"){
            editData.active = false;
        }
        else{
            editData.active = true;
        }
        if(editData.rental_Status === "Yes"){
            editData.rental_Status = true;
        }
        else{
            editData.rental_Status = false;
        }
        old_site_name = editData.site_Name;
        let sub_sites_list = await getSOCTWorkingSubSitesByParentID(globaleditID, false);
        if(sub_sites_list !=null){
            for(var index=0;index<sub_sites_list.length;index++){
                if(sub_sites_list[index].active === "Yes"){
                    sub_sites_list[index].active = true;
                }
                else{
                    sub_sites_list[index].active = false;
                }
            }
            subSitesList = sub_sites_list;
        }
        this.setState({});
    }
    render(){
        return (
            <>
            {/* <SOCTHome history={this.props.history}></SOCTHome> */}
            <LocationSubHeader
                        {...this.props} />
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Modifying Working Sites</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Sureway Oil Change Tracker
                                </Typography>
                              
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                    <Grid item xs={3}>
                            <TextField disabled helperText="Site Name" defaultValue={editData.site_Name} onChange={this.handleChange} name="site_name"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled helperText="Site Address" defaultValue={editData.site_Address}></TextField>
                        </Grid>
                        {/* <Grid item xs={3}>
                            <TextField helperText="optional" placeholder="12.24" label="Latitude" defaultValue={editData.latitude} onChange={this.handleChange} name="latitude"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField helperText="optional" placeholder="-89.33" label="Longitude" defaultValue={editData.longitude} onChange={this.handleChange} name="longitude"></TextField>
                        </Grid> */}
                        <Grid item xs={12}>
                            <SubWorkingSiteTable disabled={hasUserAccess} data={subSitesList} reRender={this.renderSubSiteTable}></SubWorkingSiteTable>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Notes" onChange={this.handleChange} name="notes" defaultValue={editData.notes} autoComplete='nope'></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox disabled checked={editData.active} onChange={this.handleChange} name="active"/>} label="Active"/>
                            <FormHelperText>For when a site is renamed or completely removed</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel control={<Checkbox checked={editData.rental_Status} onChange={this.handleChange} name="rental_status"/>} label="Rental"/>
                            <FormHelperText>For when a site is a rental</FormHelperText>
                        </Grid>
                        <Grid item xs={12}><br></br>
                            <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                            <Button disabled={isUnique || hasUserAccess} style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
                        </Grid>
                            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
            </div>
            </>
        );
    }
}