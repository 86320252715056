import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ATTACHMENTSS_REQUEST {
    REQUEST = "getAttachmentss/GET_ATTACHMENTSS_REQUEST",
    SUCCESS = "getAttachmentss/GET_ATTACHMENTSS_SUCCESS",
    FAILED = "getAttachmentss/GET_ATTACHMENTSS_FAILED"
};

export interface IGetAttachmentsRequest extends ISurewayTokenRequestBody {

};

export interface IGetAttachment {
    id: number;
    attachment_No: string;
    attachment_Unit_ID: number;
    // type_ID?: number;
    // style_ID?: number;
    style: string;
    // coupler_Design_ID?: number;
    weight?: number;
    width?: number;
    length?: number;
    capacity?: number;
    // make: string;
    // model: string;
    serial_Number: string;
    // owner: string;
    inspection_Date: string;
    detachable: string;
    notes: string;
    // photos: string; //Not sure
    // file_Attachments: string;
    status: string;
    working_Site_Sub_ID?: number;
    move_Status: string;
    move_ID?: number | null;
    repair_Line_ID?: number | null;
    defect_Item_ID?: number | null;
    repair_Status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    // files: IAttachmentListFile[],
    // images: IAttachmentListImage[];
    type: string;
    coupler_Design: string;
    location: string;
    attached_Unit_No: string;
    job_No: string;
};