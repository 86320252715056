import { IAddUpdateLocation } from "../addLocation/addLocationConstants";

export enum IUPDATE_LOCATION_REQUEST {
    REQUEST = "updateLocation/UPDATE_LOCATION_REQUEST",
    SUCCESS = "updateLocation/UPDATE_LOCATION_SUCCESS",
    FAILED = "updateLocation/UPDATE_LOCATION_FAILED"
};

export interface IUpdateLocationRequest {
    token: string;
    request: IAddUpdateLocation;
};