import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_CONTROL_PLAN_NEW_REQUEST, IGetControlPlansNew } from "./getControlPlansNewConstants";
import { IGetControlPlansNewLoadAction, IGetControlPlansNewLoadFailedAction, IGetControlPlansNewSuccessAction, 
    getControlPlansNewLoadFailedAction, getControlPlansNewLoadSuccessAction } from "./getControlPlansNewActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getControlPlansNewEpic: Epic = (
    action$: ActionsObservable<IGetControlPlansNewLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetControlPlansNewSuccessAction | IGetControlPlansNewLoadFailedAction> =>
    action$.ofType(IGET_CONTROL_PLAN_NEW_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetControlPlansNew>>>(
                    END_POINTS.FIELD.FORMS.ADMIN.GET_CONTROL_PLANS_NEW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetControlPlansNew>>): IGetControlPlansNewSuccessAction => {
                            return getControlPlansNewLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getControlPlansNewLoadFailedAction(response.message)))
                    )
            )
        );