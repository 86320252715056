
export enum IADD_CONTROL_PLAN_REQUEST {
    REQUEST = "addControlPlan/ADD_CONTROL_PLAN_REQUEST",
    SUCCESS = "addControlPlan/ADD_CONTROL_PLAN_SUCCESS",
    FAILED = "addControlPlan/ADD_CONTROL_PLAN_FAILED"
};

export interface IAddControlPlanRequest {
    token: string;
    request: IAddUpdateControlPlan;
};

export interface IAddUpdateControlPlan {
    ID: number;
    Control: string;
    Active: string;
    Created_By: string;
    Modified_By: string;
}
