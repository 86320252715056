import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_CREW_VERSION_HISTORY_REQUEST, IGetCrewVersionHistory, IGetCrewVersionHistoryRequest } from "./getCrewVersionHistoryConstants";

export interface IGetCrewVersionHistoryLoadAction {
    type: IGET_CREW_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetCrewVersionHistoryRequest
}
export const getCrewVersionHistoryLoadAction = (data: IGetCrewVersionHistoryRequest): IGetCrewVersionHistoryLoadAction => ({
    type: IGET_CREW_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetCrewVersionHistorySuccessAction {
    type: IGET_CREW_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetCrewVersionHistory>>;
}
export const getCrewVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetCrewVersionHistory>>): IGetCrewVersionHistorySuccessAction => ({
    type: IGET_CREW_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetCrewVersionHistoryLoadFailedAction {
    type: IGET_CREW_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getCrewVersionHistoryLoadFailedAction = (message: string): IGetCrewVersionHistoryLoadFailedAction => ({
    type: IGET_CREW_VERSION_HISTORY_REQUEST.FAILED,
    message
});
