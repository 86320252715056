
export enum IGET_MACHINE_CONTROL_BOXES_REQUEST {
    REQUEST = "getMachineControlBoxes/GET_MACHINE_CONTROL_BOXES_REQUEST",
    SUCCESS = "getMachineControlBoxes/GET_MACHINE_CONTROL_BOXES_SUCCESS",
    FAILED = "getMachineControlBoxes/GET_MACHINE_CONTROL_BOXES_FAILED"
};

export interface IMachineControlBoxesRequest {
    token: string;
};

export interface IMachineControlBoxes {
    id: number;
    serial_No: string;
    make: string;
    model: string;
    version: number;
    notes: string;
    auto_Dozer: string;
    auto_Grader: string;
    auto_Excavator: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};