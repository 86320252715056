import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST , IScheduleServiceHistory} from "./getScheduleServiceHistoryByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetScheduleServiceHistoryByIdLoadAction, IGetScheduleServiceHistoryByIdLoadFailedAction, IGetScheduleServiceHistoryByIdSuccessAction, getScheduleServiceHistoryByIdLoadFailedAction, getScheduleServiceHistoryByIdLoadSuccessAction } from "./getScheduleServiceHistoryByIdActions";

export const getScheduleServiceHistoryByIdEpic: Epic = (
    action$: ActionsObservable<IGetScheduleServiceHistoryByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetScheduleServiceHistoryByIdSuccessAction | IGetScheduleServiceHistoryByIdLoadFailedAction> =>
    action$.ofType(IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IScheduleServiceHistory>>(
                    END_POINTS.FIELD.SCOT.GET_SCHEDULE_SERVICE_HISTORY_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IScheduleServiceHistory>): IGetScheduleServiceHistoryByIdSuccessAction => {
                            return getScheduleServiceHistoryByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getScheduleServiceHistoryByIdLoadFailedAction(response.message)))
                    )
            )
        );