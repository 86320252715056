import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST, IServiceHistory } from "./getServiceHistoryBySOCTIDConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetServiceHistoryBySOCTIDLoadAction, IGetServiceHistoryBySOCTIDLoadFailedAction, IGetServiceHistoryBySOCTIDSuccessAction, 
         getServiceHistoryBySOCTIDLoadFailedAction, getServiceHistoryBySOCTIDLoadSuccessAction } from "./getServiceHistoryBySOCTIDActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getServiceHistoryBySOCTIDEpic: Epic = (
    action$: ActionsObservable<IGetServiceHistoryBySOCTIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetServiceHistoryBySOCTIDSuccessAction | IGetServiceHistoryBySOCTIDLoadFailedAction> =>
    action$.ofType(IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IServiceHistory>>>(
                    END_POINTS.FIELD.SCOT.GET_SERVICE_HISTORY_BY_SOCT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IServiceHistory>>): IGetServiceHistoryBySOCTIDSuccessAction => {
                            return getServiceHistoryBySOCTIDLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getServiceHistoryBySOCTIDLoadFailedAction(response)))
                    )
            )
        );