import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import PageSpacing from "../shared/pageSpacing";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LADevExtremeGrid } from "../shared/devExtreme";
import { undefinedFunction } from "../shared/constExports";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../redux/server";
import { IDispatch, IStore } from "../../redux/reducers";
import styled from "styled-components";
import { LACenteredLoading } from "../shared/loading";
import { LAPaperWithLessPadding } from "../shared/paper";
import { BLACK_COLOR, DARK_GREY_COLOR, DARK_RED_COLOR, EXTRA_DARK_GREY_COLOR, RED_COLOR, WHITE_COLOR } from "../shared/theme";
import { SurewayAPIResponse } from "../shared/publicInterfaces";
import { IComponentWaitingParts, IGetComponentWaitingPartsRequest } from "../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsConstants";
import { getComponentWaitingParts } from "../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsAccessor";
import { getComponentWaitingPartsLoadAction } from "../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsActions";

interface IExternalRepairLinePartsStoreProps {
    getComponentParts: Server<SurewayAPIResponse<IComponentWaitingParts[]>>;
};

interface IExternalRepairLinePartsDispatchProps {
    getComponentWaitingPartsRequest: (data: IGetComponentWaitingPartsRequest) => unknown;
};

interface IExternalRepairLinePartsOwnProps {

};

interface IExternalRepairLinePartsState {
    fullScreen: boolean;
    currentPage: number;
    hitLastPage: boolean;
    scrollPosition: number;
    scrollingDown: boolean;
    secondsLeft: number;
};

const ExternalRepairLinePartsStyles = styled(LAPaperWithLessPadding)`
    .pull-right {
        position: absolute;
        right: 3%;
        height: 3%;
        top: 2%;
    };
    .dx-row dx-column-lines dx-header-row
    {
        position: fixed;        
    }

    .dx-datagrid-headers .dx-header-row {  
        font-weight: bolder;
        font-size: 16px !important;
        color: ${WHITE_COLOR} !important;
        background-color: ${EXTRA_DARK_GREY_COLOR} !important;
    };
    
    .red-text {
        color: ${RED_COLOR};
    };

    .dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
        font-size: 12px !important;
    };
    tbody
    {
        font-weight: bolder;
        color: ${BLACK_COLOR} !important;
    }
    tbody tr:nth-child(odd) {
        background-color: ${DARK_GREY_COLOR} !important;
        color:${BLACK_COLOR} !important;
      }

      tbody tr:nth-child(even) {
        background-color: ${WHITE_COLOR} !important;
        color:${BLACK_COLOR} !important;
      }

      .timer{
        font-weight: bolder;
        color: ${DARK_RED_COLOR} !important;
        font-size: 14px !important;
      }
`;

type IExternalRepairLinePartsProps = RouteComponentProps
    & IExternalRepairLinePartsStoreProps
    & IExternalRepairLinePartsDispatchProps
    & IExternalRepairLinePartsOwnProps;

class ExternalRepairLineParts extends PureComponent<IExternalRepairLinePartsProps, IExternalRepairLinePartsState> {
    private pagination: any = null;

    public constructor(props: IExternalRepairLinePartsProps) {
        super(props);
        this.state = {
            secondsLeft: 120,
            fullScreen: false,
            scrollPosition: 0,
            scrollingDown: true,
            currentPage: 0,
            hitLastPage: false,
        };
    }

    public componentDidMount(): void {
        this.callServer();
        setTimeout(function () {
            window.location.reload();
        }, 120000);
    };

    public componentDidUpdate(prevProps: IExternalRepairLinePartsProps): void {
        if (this.props !== prevProps)
            this.callServer();

        if (this.props.getComponentParts !== prevProps.getComponentParts) {
            if (hasPayload(this.props.getComponentParts)) {
                this.startAutoNavigation();
            }
        }
    };

    public componentWillUnmount(): void {
        clearInterval(this.pagination);
    };

    public startAutoNavigation = (): void => {
        if (hasPayload(this.props.getComponentParts)) {
            const records = this.props.getComponentParts.payload.response.length;
            this.pagination = setInterval(() => {
                const { currentPage, hitLastPage } = this.state;
                const totalPages = records % 20 === 0 ? Math.round(records / 20) : Math.round(records / 20) + 1;
                if (totalPages < 1) {
                    clearInterval(this.pagination);
                } else {
                    if (hitLastPage === false) {

                        this.setState({
                            currentPage: currentPage + 1,
                            hitLastPage: (currentPage + 1 === totalPages - 1) ? true : false
                        });
                    } else if (hitLastPage === true) {
                        window.location.reload();
                    };
                }
            }, 20000);
        }
    };

    public render(): ReactNode {

        const { getComponentParts } = this.props;
        const { currentPage } = this.state;
        const { kind } = getComponentParts;
        const data = hasPayload(getComponentParts) ? getComponentParts.payload.response.filter((x:any) => { return !x.unit_No.includes("Test") }) : [];
        // console.log(data)
        return (
            <PageSpacing title="RepairLine Parts" description="RepairLine Parts" fixedSpaceOnSmallerScreens={true}>
                <ExternalRepairLinePartsStyles>
                    <LAGrid>

                        <LAGridItem xs={12} className="text-left">
                            <span className="timer">Last Refreshed: {new Date().toLocaleDateString('en-US') + ", " + new Date().getHours() + ':' + new Date().getMinutes()} </span>
                            <hr />
                        </LAGridItem>

                        {(kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Please wait... Loading data" />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Failed to load data..." />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                            <LADevExtremeGrid
                                paging={10}
                                data={data}
                                height={1100}
                                searchPanel={false}
                                filterHeader={false}
                                columnChoose={false}
                                pageNumber={currentPage}
                                id="ExternalRepairLineParts"
                                onClick={undefinedFunction}
                                columns={[
                                    { name: "unit_No", caption: "Unit#", type: "string", sortAsc: true },
                                    { name: "work_Order_No", caption: "WO#", type: "string"},
                                    { name: "component_Code", caption: "Component Code", type: "string" },
                                    { name: "defect_Item", caption: "Defect Item", type: "string" },
                                    { name: "priority", caption: "Priority", type: "string" },
                                    { name: "foreman_Comments", caption: "Issue", type: "string" },
                                    { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                                    { name: "parts_Notes", caption: "Parts Notes", type: "string" },
                                ]}
                            />
                        </LAGridItem>}

                    </LAGrid>
                </ExternalRepairLinePartsStyles>
            </PageSpacing>
        );
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.getComponentParts))
            this.requestToGetRepairLineParts();
    };

    private requestToGetRepairLineParts = (): void => {
        this.props.getComponentWaitingPartsRequest({
            token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
        });
    }

}

const mapStateToProps = (state: IStore): IExternalRepairLinePartsStoreProps => ({
    getComponentParts: getComponentWaitingParts(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalRepairLinePartsDispatchProps => ({
    getComponentWaitingPartsRequest: (data: IGetComponentWaitingPartsRequest) => dispatch(getComponentWaitingPartsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalRepairLineParts);