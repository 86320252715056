import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENTSS_REQUEST, IGetAttachment, IGetAttachmentsRequest } from "./getAttachmentssConstants";

export interface IGetAttachmentssLoadAction {
    type: IGET_ATTACHMENTSS_REQUEST.REQUEST;
    data: IGetAttachmentsRequest
}
export const getAttachmentssLoadAction = (data: IGetAttachmentsRequest): IGetAttachmentssLoadAction => ({
    type: IGET_ATTACHMENTSS_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentssSuccessAction {
    type: IGET_ATTACHMENTSS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetAttachment>>;
}
export const getAttachmentssLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetAttachment>>): IGetAttachmentssSuccessAction => ({
    type: IGET_ATTACHMENTSS_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentssLoadFailedAction {
    type: IGET_ATTACHMENTSS_REQUEST.FAILED;
    message: string;
}
export const getAttachmentssLoadFailedAction = (message: string): IGetAttachmentssLoadFailedAction => ({
    type: IGET_ATTACHMENTSS_REQUEST.FAILED,
    message
});
