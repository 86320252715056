import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_HAZARD_TYPES_REQUEST, IGetHazardTypes } from "./getHazardTypesConstants";
import { IGetHazardTypesLoadAction, IGetHazardTypesLoadFailedAction, IGetHazardTypesSuccessAction } from "./getHazardTypesActions";
import { IADD_HAZARD_TYPE_REQUEST } from "../addHazardType/addHazardTypeConstants";
import { IUPDATE_HAZARD_TYPE_REQUEST } from "../updateHazardType/updateHazardTypeConstants";
import { IAddHazardTypeSuccessAction } from "../addHazardType/addHazardTypeActions";
import { IUpdateHazardTypeSuccessAction } from "../updateHazardType/updateHazardTypeActions";

type Actions =
    | IGetHazardTypesLoadAction
    | IGetHazardTypesSuccessAction
    | IGetHazardTypesLoadFailedAction
    | IAddHazardTypeSuccessAction
    | IUpdateHazardTypeSuccessAction
    | IFlushDataSuccessAction;

export const GetHazardTypesReducer = (state: Server<SurewayAPIResponse<ById<IGetHazardTypes>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetHazardTypes>>> => {
    switch (action.type) {
        case IGET_HAZARD_TYPES_REQUEST.REQUEST:
            return loading;

        case IGET_HAZARD_TYPES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_HAZARD_TYPES_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_HAZARD_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_HAZARD_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};