import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_VERSION_HISTORY_REQUEST, IGetETMoveAttachmentVersionHistory, IGetETMoveAttachmentVersionHistoryRequest } from "./getETMoveAttachmentVersionHistoryConstants";

export interface IGetETMoveAttachmentVersionHistoryLoadAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetETMoveAttachmentVersionHistoryRequest
}
export const getETMoveAttachmentVersionHistoryLoadAction = (data: IGetETMoveAttachmentVersionHistoryRequest): IGetETMoveAttachmentVersionHistoryLoadAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetETMoveAttachmentVersionHistorySuccessAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetETMoveAttachmentVersionHistory[]>;
}
export const getETMoveAttachmentVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<IGetETMoveAttachmentVersionHistory[]>): IGetETMoveAttachmentVersionHistorySuccessAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetETMoveAttachmentVersionHistoryLoadFailedAction {
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getETMoveAttachmentVersionHistoryLoadFailedAction = (message: string): IGetETMoveAttachmentVersionHistoryLoadFailedAction => ({
    type: IGET_EQUIPMENT_VERSION_HISTORY_REQUEST.FAILED,
    message
});
