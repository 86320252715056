import { ITechService } from "../getTechServices/getTechServicesConstants";

export enum IADD_TECH_SERVICE_REQUEST {
    REQUEST = "addTechService/ADD_TECH_SERVICE_REQUEST",
    SUCCESS = "addTechService/ADD_TECH_SERVICE_SUCCESS",
    FAILED = "addTechService/ADD_TECH_SERVICE_FAILED"
};

export interface IAddTechServiceRequest {
    token: string;
    request: IAddTechService;
};

export interface IAddTechService extends ITechService {

}