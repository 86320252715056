import { IADD_DRIVER_REQUEST } from "./addDriverConstants";
import { IAddDriverLoadAction, IAddDriverLoadFailedAction, IAddDriverSuccessAction } from "./addDriverActions";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IAddDriverLoadAction
    | IAddDriverSuccessAction
    | IAddDriverLoadFailedAction
    | IFlushDataSuccessAction;

export const AddETDriverReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IADD_DRIVER_REQUEST.REQUEST:
            return loading;

        case IADD_DRIVER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_DRIVER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};