import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST, IWorkingSiteLogOnCall, IWorkingSiteLogOnCallRequest } from "./getWorkingSiteLogsOnCallConstants";

export interface IGetWorkingSiteLogsOnCallLoadAction {
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.REQUEST;
    data: IWorkingSiteLogOnCallRequest
}
export const getWorkingSiteLogsOnCallLoadAction = (data: IWorkingSiteLogOnCallRequest): IGetWorkingSiteLogsOnCallLoadAction => ({
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.REQUEST,
    data
});
export interface IGetWorkingSiteLogsOnCallSuccessAction {
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>;
}
export const getWorkingSiteLogsOnCallLoadSuccessAction = (list: SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>): IGetWorkingSiteLogsOnCallSuccessAction => ({
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.SUCCESS,
    list
});
export interface IGetWorkingSiteLogsOnCallLoadFailedAction {
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.FAILED;
    message: string;
}
export const getWorkingSiteLogsOnCallLoadFailedAction = (message: string): IGetWorkingSiteLogsOnCallLoadFailedAction => ({
    type: IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.FAILED,
    message
});
