
export enum IADD_EXTERNAL_USER_REQUEST {
    REQUEST = "addExternalUser/ADD_EXTERNAL_USER_REQUEST",
    SUCCESS = "addExternalUser/ADD_EXTERNAL_USER_SUCCESS",
    FAILED = "addExternalUser/ADD_EXTERNAL_USER_FAILED"
};

export interface IAddExternalUserRequest {
    token: string;
    request: IAddUpdateExternalUser;
};

export interface IAddUpdateExternalUser {
    ID: number;
    Firstname: string;
    Lastname: string;
    Email: string;
    Password: string;
    Phone: string;
    Status: string;
    Company: string;
    Role: string;
    Created_By: string;
}