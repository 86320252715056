
export enum IGET_BASE_KIT_REQUEST {
    REQUEST = "getBaseKit/GET_BASE_KIT_REQUEST",
    SUCCESS = "getBaseKit/GET_BASE_KIT_SUCCESS",
    FAILED = "getBaseKit/GET_BASE_KIT_FAILED"
};

export interface IBaseKitRequest {
    token: string;
};

export interface IBaseKit {
    id: number;
    kit_No: string;
    kit_No_Display?: string;
    unit_No: string;
    model: string;
    gnsS_Antenna: string;
    radio_Antenna_Parts: string;
    cables: string;
    tribrach_Puck: string;
    // in_Out: string;
    status: string;
    // site: string;
    wsS_ID: number;
    channel: string;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};