import { ILogs, IWorkingSiteLogPicture } from "../getWorkingSiteLogs/getWorkingSiteLogsConstants";

export enum IUPDATE_WORKING_SITE_LOG_REQUEST {
    REQUEST = "updateWorkingSiteLog/UPDATE_WORKING_SITE_LOG_REQUEST",
    SUCCESS = "updateWorkingSiteLog/UPDATE_WORKING_SITE_LOG_SUCCESS",
    FAILED = "updateWorkingSiteLog/UPDATE_WORKING_SITE_LOG_FAILED"
};

export interface IUpdateWorkingSiteLogRequest {
    token: string;
    request: {
        ID: number;
        Date: string;
        Foreman: string;        
        WSS_ID: number;
        Status: string;
        Security: string;
        Job_No: string;
        Equip_On_Site: number;
        Equip_Working: number;
        Emp_Working: number;
        Service_Required: string;
        Prime_Contractor: string;
        Radio: string;
        Start_Time_Val: string;
        End_Time_Val: string;
        Work_Description: string;
        Safety_Observations_Comments: string;
        Logs: ILogs[];
        Safety_Addressed: string;
        Safety_Admin_Notes: string;
        Safety_Pictures: IWorkingSiteLogPicture[];
        // Images: string;
        // Pictures: IWorkingSiteLogPicture[];
        sunday_Working: string;
        saturday_Working: string;
        Scheduled_Days_Off: string;
        Modified_By: string;
    };
};