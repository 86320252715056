import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
import { IAddMeterReadingSuccessAction } from "../addMeterReading/addMeterReadingActions";
import { IADD_METER_READING_REQUEST } from "../addMeterReading/addMeterReadingConstants";
import { IUpdateMeterReadingSuccessAction } from "../updateMeterReading/updateMeterReadingActions";
import { IUPDATE_METER_READING_REQUEST } from "../updateMeterReading/updateMeterReadingConstants";
import { IGetBulkMeterReadingsLoadAction, IGetBulkMeterReadingsLoadFailedAction, IGetBulkMeterReadingsSuccessAction } from "./getBulkMeterReadingsActions";
import { IBulkMeterReading, IGET_BULK_METER_READINGS_REQUEST } from "./getBulkMeterReadingsConstants";

type Actions =
    | IGetBulkMeterReadingsLoadAction
    | IGetBulkMeterReadingsSuccessAction
    | IGetBulkMeterReadingsLoadFailedAction
    | IAddMeterReadingSuccessAction
    | IUpdateMeterReadingSuccessAction
    | IFlushDataSuccessAction;

export const GetBulkMeterReadingsReducer = (state: Server<SurewayAPIResponse<ById<IBulkMeterReading>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IBulkMeterReading>>> => {
    switch (action.type) {
        case IGET_BULK_METER_READINGS_REQUEST.REQUEST:
            return loading;

        case IGET_BULK_METER_READINGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_BULK_METER_READINGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_METER_READING_REQUEST.SUCCESS:
            return notLoaded;
    
        case IUPDATE_METER_READING_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};