
export enum IGET_DRIVER_COMMUNICATION_LOGS_REQUEST {
    REQUEST = "getDriverCommunicationLogs/GET_DRIVER_COMMUNICATION_LOGS_REQUEST",
    SUCCESS = "getDriverCommunicationLogs/GET_DRIVER_COMMUNICATION_LOGS_SUCCESS",
    FAILED = "getDriverCommunicationLogs/GET_DRIVER_COMMUNICATION_LOGS_FAILED"
};

export interface IGetDriverCommunicationLogsRequest {
    request: {
        driverID: number;
    };
    token: string;
};

export interface IGetDriverCommunicationLogs {
    id: number;
    unit_Info: string;
    from_Location: string;
    to_Location: string;
    notes?: string;
    status: string;
    created: string;
};