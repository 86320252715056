import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_JOB_PIT_REQUEST, IUpdateJobPitRequest } from "./updateJobPitConstants";


export interface IUpdateJobPitLoadAction {
    type: IUPDATE_JOB_PIT_REQUEST.REQUEST;
    data: IUpdateJobPitRequest
}
export const updateJobPitLoadAction = (data: IUpdateJobPitRequest): IUpdateJobPitLoadAction => ({
    type: IUPDATE_JOB_PIT_REQUEST.REQUEST,
    data
});
export interface IUpdateJobPitSuccessAction {
    type: IUPDATE_JOB_PIT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateJobPitLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateJobPitSuccessAction => ({
    type: IUPDATE_JOB_PIT_REQUEST.SUCCESS,
    message
});
export interface IUpdateJobPitLoadFailedAction {
    type: IUPDATE_JOB_PIT_REQUEST.FAILED;
    message: string;
}
export const updateJobPitLoadFailedAction = (message: string): IUpdateJobPitLoadFailedAction => ({
    type: IUPDATE_JOB_PIT_REQUEST.FAILED,
    message
});
