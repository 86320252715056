import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_EQUIPMENT_CERTIFICATE_REQUEST, IAddEquipmentCertificateRequest } from "./addEquipmentCertificateConstants";


export interface IAddEquipmentCertificateLoadAction {
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.REQUEST;
    data: IAddEquipmentCertificateRequest
}
export const addEquipmentCertificateLoadAction = (data: IAddEquipmentCertificateRequest): IAddEquipmentCertificateLoadAction => ({
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.REQUEST,
    data
});
export interface IAddEquipmentCertificateSuccessAction {
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addEquipmentCertificateLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddEquipmentCertificateSuccessAction => ({
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.SUCCESS,
    message
});
export interface IAddEquipmentCertificateLoadFailedAction {
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.FAILED;
    message: string;
}
export const addEquipmentCertificateLoadFailedAction = (message: string): IAddEquipmentCertificateLoadFailedAction => ({
    type: IADD_EQUIPMENT_CERTIFICATE_REQUEST.FAILED,
    message
});
