import React from "react";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, IName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { LIGHT_GREY_COLOR, LIGHT_ORANGE_COLOR, MEDIA_QUERY_PHONE, SECONDARY_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl,  pageAccessCheck, sortByName, ToggleOrInput, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import { AddIcon, ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IServiceSection, IGetServiceSectionRequest, IServiceItem } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsConstants";
import { IAddServiceSectionRequest, IAddUpdateServiceItem } from "../../../redux/field/pmSheets/addServiceSection/addServiceSectionConstants";
import { IUpdateServiceSectionRequest } from "../../../redux/field/pmSheets/updateServiceSection/updateServiceSectionConstants";
import { getServiceSections } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsAccessor";
import { addServiceSectionStatus } from "../../../redux/field/pmSheets/addServiceSection/addServiceSectionAccessor";
import { updateServiceSectionStatus } from "../../../redux/field/pmSheets/updateServiceSection/updateServiceSectionAccessor";
import { getServiceSectionsLoadAction } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsActions";
import { addServiceSectionLoadAction } from "../../../redux/field/pmSheets/addServiceSection/addServiceSectionActions";
import { updateServiceSectionLoadAction } from "../../../redux/field/pmSheets/updateServiceSection/updateServiceSectionActions";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getPMLookups } from "../../../redux/field/pmSheets/getPMLookups/getPMLookupsAccessor";
import { getPMLookupsLoadAction } from "../../../redux/field/pmSheets/getPMLookups/getPMLookupsActions";
import { IPMLookups, IPMLookupsRequest, IPM_EquipmentLookup, IPM_EquipmentTypeLookup, IPM_MakeLookup, IPM_ServiceDueLookUp } from "../../../redux/field/pmSheets/getPMLookups/getPMLookupsConstants";
import { LACheckBox } from "../../shared/checkBox";
import { IGetCompaniesRequest, IGetCompaniesResponse } from "../../../redux/field/pmSheets/getCompanies/getCompaniesConstants";
import { getCompanies } from "../../../redux/field/pmSheets/getCompanies/getCompaniesAccessor";
import { getCompaniesLoadAction } from "../../../redux/field/pmSheets/getCompanies/getCompaniesActions";
import LALinkButton from "../../shared/linkButton";

const RequiredFields: string[] = ["section_Name", "service_Due_Name", "equipment_Type", "display_Order","company"];
const RequiredDropDownFields: string[] = ["service_Item_Name"];
export const defaultCompany: IGetCompaniesResponse={ companyID:0, company:"All", numberEmployees:0, explorerCode:"All", adminJob:"All", active:"Yes" };

interface IAddUpdateServiceSectionComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    lookUps: Server<SurewayAPIResponse<IPMLookups>>;
    companies: Server<SurewayAPIResponse<IGetCompaniesResponse[]>>
    addServiceSectionStatus: Server<SurewayAPIResponse<string>>;
    serviceTypes: Server<SurewayAPIResponse<ById<IServiceSection>>>;
    updateServiceSectionStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateServiceSectionComponentDispatchProps {
    lookUpRequest: (data: IPMLookupsRequest) => unknown;
    companiesRequest: (data: IGetCompaniesRequest) => unknown;
    addServiceSectionRequest: (data: IAddServiceSectionRequest) => unknown;
    getServiceSectionsRequest: (data: IGetServiceSectionRequest) => unknown;
    updateServiceSectionRequest: (data: IUpdateServiceSectionRequest) => unknown;
};

interface IAddUpdateServiceSectionOwnProps {
    id?: string;
};

interface IAddUpdateServiceSectionComponentState {
    serverError: string;
    tableError: number[];
    data: IServiceSection;
    duplicateIndexList: number[];
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateServiceSectionStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };
    
    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .warning-row {
        background-color: ${LIGHT_ORANGE_COLOR};
    };
    
    .odd-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .show-on-phone {
        display: none;
    };
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IAddUpdateServiceSectionComponentProps =
    RouteComponentProps
    & IAddUpdateServiceSectionOwnProps
    & IAddUpdateServiceSectionComponentStoreProps
    & IAddUpdateServiceSectionComponentDispatchProps;

class AddUpdateServiceSection extends PureComponent<IAddUpdateServiceSectionComponentProps, IAddUpdateServiceSectionComponentState> {

    public constructor(props: IAddUpdateServiceSectionComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                section_Name: "",
                active: YesOrNo[0].name,
                service_Due_ID: 0,
                equipment_Type_ID: 0,
                created_By: "",
                modified_By: "",
                created: "",
                modified: "",
                equipment_Type: "",
                service_Due_Name: "",
                display_Order: 1,
                company:defaultCompany.company,
                service_Items: []
            },
            errors: {},
            tableError: [],
            serverError: "",
            duplicateIndexList: []
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateServiceSectionComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addServiceSectionStatus !== prevProps.addServiceSectionStatus) {

                if (this.props.addServiceSectionStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addServiceSectionStatus.message });

                if (hasPayload(this.props.addServiceSectionStatus) && this.props.addServiceSectionStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateServiceSectionStatus !== prevProps.updateServiceSectionStatus) {

                if (this.props.updateServiceSectionStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateServiceSectionStatus.message });

                if (hasPayload(this.props.updateServiceSectionStatus) && this.props.updateServiceSectionStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, tableError, duplicateIndexList } = this.state;
        const { token, addServiceSectionStatus, updateServiceSectionStatus, lookUps, companies} = this.props;
        const getRole = pageAccessCheck(token, "pmSheets");
        const disabled = getRole === ReadOnly ? true : undefined;
        const dropDownData = hasPayload(lookUps) ? lookUps.payload.response : undefined;
        const dropDownCompaniesData = hasPayload(companies) ? companies.payload.response : undefined;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);

        return (
            <AddUpdateServiceSectionStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>SERVICE SECTION</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                autoHighlight={true}
                                filterSelectedOptions={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder="Equipment Type"
                                selectionRemove={undefinedFunction}
                                onChange={this.handleEquipmentTypeChange}
                                option={dropDownData ? dropDownData.equipmentTypes : []}
                                errorText={errors["equipment_Type"] ? errors["equipment_Type"].message : undefined}
                                value={dropDownData && data.equipment_Type ? dropDownData.equipmentTypes.find(q => q.name === data.equipment_Type) : null}
                                defaultValue={dropDownData && data.equipment_Type ? dropDownData.equipmentTypes.find(q => q.name === data.equipment_Type) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                getOptionLabel="service_Due_Name"
                                autoHighlight={true}
                                onChange={this.handleServiceDueChange}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Service Hours"
                                selectionRemove={undefinedFunction}
                                option={dropDownData ? dropDownData.serviceTypes : []}
                                errorText={errors["service_Due_Name"] ? errors["service_Due_Name"].message : undefined}
                                value={dropDownData && data.service_Due_Name ? dropDownData.serviceTypes.find(q => q.service_Due_Name === data.service_Due_Name) : null}
                                defaultValue={dropDownData && data.service_Due_Name ? dropDownData.serviceTypes.find(q => q.service_Due_Name === data.service_Due_Name) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Section Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="section_Name"
                                value={data.section_Name}
                                onChange={this.handleChange}
                                errorText={errors["section_Name"] ? errors["section_Name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                         <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Display Order"
                                type="number"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="display_Order"
                                value={data.display_Order}
                                onChange={this.handleChange}
                                errorText={errors["display_Order"] ? errors["display_Order"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                autoHighlight={true}
                                filterSelectedOptions={true}
                                getOptionLabel="company"
                                dropDownPlaceHolder="Company"
                                selectionRemove={undefinedFunction}
                                onChange={this.handleCompanyChange}
                                option={dropDownCompaniesData ? dropDownCompaniesData : []}
                                errorText={errors["company"] ? errors["company"].message : undefined}
                                value={dropDownCompaniesData && data.company ? dropDownCompaniesData.find(q => q.explorerCode === data.company) : null}
                                defaultValue={dropDownCompaniesData && data.company ? dropDownCompaniesData.find(q => q.explorerCode === data.company) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} className="hide-on-phone">
                            {dropDownData && <ServiceItems
                                id={Number(data.id)}
                                errors={tableError}
                                onAdd={this.onAdd}
                                onDelete={this.onDelete}
                                disabled={disabled}
                                lookUps={dropDownData}
                                data={data.service_Items}
                                typeId={data.equipment_Type_ID}
                                handleChange={this.handleTableChange}
                                duplicateIndexList={duplicateIndexList}
                                handleDropDownChange={this.handleDropDownChange}
                                handleDropDownRemove={this.handleDropDownRemove}
                                handlePictureRequiredChange={this.onPictureRequiredChange}
                                handleReorderChange={this.handleReorderChange}
                            />}
                        </LAGridItem>

                        <LAGridItem xs={12} className="show-on-phone">
                            {dropDownData && <ServiceItemsMobileView
                                errors={tableError}
                                id={Number(data.id)}
                                onDelete={this.onDelete}
                                onAdd={this.onAdd}
                                disabled={disabled}
                                lookUps={dropDownData}
                                data={data.service_Items}
                                typeId={data.equipment_Type_ID}
                                handleChange={this.handleTableChange}
                                duplicateIndexList={duplicateIndexList}
                                handleDropDownChange={this.handleDropDownChange}
                                handleDropDownRemove={this.handleDropDownRemove}
                                handlePictureRequiredChange={this.onPictureRequiredChange}
                                handleReorderChange={this.handleReorderChange}
                            />}
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={(Object.values(errors).length === 0) ? ((tableError.length > 0) ? true : disabled) : true}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addServiceSectionStatus.kind} successMessage="Service Section has been added successfully" />
                <RequestStatus requestStatus={updateServiceSectionStatus.kind} successMessage="Service Section has been updated successfully" />
            </AddUpdateServiceSectionStyles>
        );
    }
    
    private handleDropDownRemove = (name: string, value: string, index?: number): void => {
        if (index !== undefined) {
            let arry: string[] = [];
            const iS = [...this.state.data.service_Items];
            let record = { ...iS[index] };

            if (name === "apply_To_Make" && record.apply_To_Make) {
                arry = record.apply_To_Make.split(",");
            } else if (name === "apply_To_Model" && record.apply_To_Model) {
                arry = record.apply_To_Model.split(",");
            } else if (name === "apply_To_Unit_No" && record.apply_To_Unit_No) {
                arry = record.apply_To_Unit_No.split(",");
            } else if (name === "exclude_Unit_No" && record.exclude_Unit_No) {
                arry = record.exclude_Unit_No.split(",");
            } else if (name === "exclude_Model" && record.exclude_Model) {
                arry = record.exclude_Model.split(",");
            };

            const findIndex = arry.findIndex(x => x === value);

            if (findIndex >= 0)
                arry.splice(findIndex, 1);


            record = { ...record, [name]: arry.join(",") };
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    service_Items: iS
                }
            });
        };
    };

    private handleDropDownChange = (name: string, values: IName[], index?: number): void => {
        if (index !== undefined) {

            const iS = [...this.state.data.service_Items];
            let record = { ...iS[index] };
            record = { ...record, [name]: values.map(({ name }) => name).join(",") };
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    service_Items: iS
                }
            });
        };
    };

    private onPictureRequiredChange = (value: boolean, index?: number): void => {
        if (index !== undefined) {
            let name: string = "picture_Required";
            const iS = [...this.state.data.service_Items];
            let record = { ...iS[index] };
            record = { ...record, [name]: value === true ? "Yes" : "No" };
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    service_Items: iS
                }
            });
        };
    };

    private onAdd = (): void => {
        const errors = this.state.tableError;
        const list = [...this.state.data.service_Items];

        if(hasPayload(this.props.token))
            list.unshift({
                id: 0,
                modified: "",
                created: "",
                comments: "",
                created_By: this.props.token.payload.response.upn,
                service_Item_Name: "",
                apply_To_Make: undefined,
                apply_To_Unit_No: undefined,
                exclude_Unit_No: undefined,
                exclude_Model: undefined,
                apply_To_Model: undefined,
                modified_By: this.props.token.payload.response.upn,
                active: YesOrNo[0].name,
                service_Section_ID: this.state.data.id,
                picture_Required: "No",
                type: ToggleOrInput[0].name,
                display_Order : 0,
                service_Hours: ""
            });

        // errors.push(list.length - 1);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                service_Items: list
            },
            tableError: errors
        });
    };

    private onDelete = (index:number, x: any): void => {
        const service_Items = [...this.state.data.service_Items];
        service_Items.splice(index, 1);
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                service_Items: service_Items
            }
        });
    }

    private handleReorderChange = () : void => {
        const sortedServiceItems = [...this.state.data.service_Items].sort((a, b) => a.display_Order - b.display_Order);
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                service_Items: sortedServiceItems
            },
        });
    }

    private handleTableChange = (name: string, value: string, index?: number): void => {
        if (index !== undefined) {
            const tableError = this.state.tableError;

            if (RequiredDropDownFields.includes(name)) {
                if (value.length > 0) {
                    if (tableError.includes(index)) {
                        const indexPos = tableError.findIndex(q => q === index);
                        tableError.splice(indexPos, 1);
                    }
                } else {
                    tableError.includes(index);
                };
            };

            const iS = [...this.state.data.service_Items];
            let record = { ...iS[index] };
            record = { ...record, [name]: value };
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    service_Items: iS
                },
                tableError
            });
        }
    };

    private handleServiceDueChange = (event: unknown, value: IPM_ServiceDueLookUp): void => {
        if (value !== null) {
            let errors = { ...this.state.errors };

            if (RequiredFields.includes("service_Due_Name"))
                errors = this.errorChecker("service_Due_Name", value.service_Due_Name, errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    service_Due_Name: value.service_Due_Name,
                    service_Due_ID: value.id
                },
                errors
            });
        }
    };

    private handleCompanyChange = (event: unknown, value: IGetCompaniesResponse): void => {
        if (value !== null) {
            let errors = { ...this.state.errors };

            if (RequiredFields.includes("company"))
                errors = this.errorChecker("company", value.explorerCode, errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    company: value.explorerCode
                },
                errors
            });
        }
    };

    private handleEquipmentTypeChange = (event: unknown, value: IPM_EquipmentTypeLookup): void => {
        if (value !== null) {
            const iS = { ...this.state.data };
            let errors = { ...this.state.errors };

            iS.service_Items.forEach((x) => {
                x.apply_To_Make = "";
                x.apply_To_Model = "";
                x.apply_To_Unit_No = "";
                x.exclude_Unit_No = "";
                x.exclude_Model = "";
            });

            iS.equipment_Type = value.name;
            iS.equipment_Type_ID = value.id;

            if (RequiredFields.includes("equipment_Type"))
                errors = this.errorChecker("equipment_Type", value.name, errors);

            this.setState({
                ...this.state,
                data: iS,
                errors
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        
        this.props.history.push({
            pathname: ROUTE.FIELD.PM_SHEETS.SERVICE_SECTIONS,
            search: getTokenFromUrl(false)
          });
    };

    private handleSave = async (): Promise<void> => {

        let duplicateIndexList: number[] = [];

        const {
            id, section_Name, equipment_Type_ID, service_Due_ID,
            active, service_Items, created_By, display_Order, company
        } = this.state.data;

        let requiredFields = false;
        requiredFields = service_Items.some(item => item.service_Item_Name.length === 0);
        // console.log(this.state.data);
        const itemsArray: IAddUpdateServiceItem[] = [];

        await service_Items.forEach((x) => {

            const duplicateIndex = service_Items.filter(function (y) { return y !== x }).findIndex((q) =>
                (q.service_Item_Name === x.service_Item_Name)
                && (q.apply_To_Make === x.apply_To_Make)
                && (q.apply_To_Model === x.apply_To_Model)
                && (q.apply_To_Unit_No === x.apply_To_Unit_No)
                && (q.exclude_Unit_No === x.exclude_Unit_No)
                && (q.exclude_Model === x.exclude_Model)
            );

            if (duplicateIndex >= 0)
                duplicateIndexList.push(duplicateIndex);

            itemsArray.push({
                ID: x.id,
                Service_Item_Name: x.service_Item_Name,
                Apply_To_Make: x.apply_To_Make ? x.apply_To_Make : undefined,
                Apply_To_Model: x.apply_To_Model ? x.apply_To_Model : undefined,
                Apply_To_Unit_No: x.apply_To_Unit_No ? x.apply_To_Unit_No : undefined,
                Exclude_Unit_No: x.exclude_Unit_No ? x.exclude_Unit_No : undefined,
                Exclude_Model: x.exclude_Model ? x.exclude_Model : undefined,
                Picture_Required: x.picture_Required,
                Comments: x.comments,
                Active: x.active,
                Type: x.type,
                Display_Order: x.display_Order,
                Service_Hours: x.service_Hours
            });
        });

        if(requiredFields) {
            alert("Please select a required field in child table");
        } 
        else {

            if (hasPayload(this.props.token) && (duplicateIndexList.length === ZEROTH)) {
    
                if (id === 0) {
                    this.props.addServiceSectionRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            ID: id,
                            Section_Name: section_Name,
                            Active: active,
                            Equipment_Type_ID: equipment_Type_ID,
                            Service_Due_ID: service_Due_ID,
                            Created_By: this.props.token.payload.response.upn,
                            Modified_By: this.props.token.payload.response.upn,
                            Display_Order: display_Order,
                            company: company,
                            Service_Items: itemsArray
                        }
                    });
                } else {
                    this.props.updateServiceSectionRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            ID: id,
                            Section_Name: section_Name,
                            Active: active,
                            Equipment_Type_ID: equipment_Type_ID,
                            Service_Due_ID: service_Due_ID,
                            Created_By: created_By,
                            Modified_By: this.props.token.payload.response.upn,
                            Display_Order: display_Order,
                            company: company,
                            Service_Items: itemsArray
                        }
                    });
                }
    
                this.setState({ serverError: "", duplicateIndexList: [] });
            } else {
                this.setState({ duplicateIndexList });
            }
        }

    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "pmSheets") !== NotApplicable) {

                    const token = this.props.token.payload.response.token;

                    if (isNotLoaded(this.props.lookUps))
                        this.props.lookUpRequest({ token });

                    
                    if (isNotLoaded(this.props.companies)) {
                        if (hasPayload(this.props.token)) {
                            this.props.companiesRequest({
                                Keywords: "",
                                PageSize: 200,
                                PageNumber: 1,
                                token: this.props.token.payload.response.token,
                            });
                        }
                    };

                    if (query.id !== "0") {
                        if (hasPayload(this.props.serviceTypes)) {
                            let data = this.props.serviceTypes.payload.response[query.id];
                            data.service_Items.forEach(item => {
                                if (item.display_Order === null) {
                                    item.display_Order = 0;
                                }
                            });
                            data.service_Items.sort((a, b) => a.display_Order - b.display_Order);

                            this.setState({
                                data
                            });
                        } else {
                            this.props.getServiceSectionsRequest({ token });
                        }
                    } else {
                        if(query.clone_ID !== undefined) { 
                            if (hasPayload(this.props.serviceTypes)) {
                                let data = this.props.serviceTypes.payload.response[+query.clone_ID];
                                data.service_Items.forEach(item => {
                                    if (item.display_Order === null) {
                                        item.display_Order = 0;
                                    }
                                });
                                data.service_Items.sort((a, b) => a.display_Order - b.display_Order);

                                const cloneData = {
                                    id: 0,
                                    equipment_Type: "",
                                    equipment_Type_ID: 0,
                                    service_Due_Name: "",
                                    service_Due_ID: 0,
                                    section_Name: "",
                                    active: data.active ?? YesOrNo[0].name,
                                    display_Order: data.display_Order ?? 1,
                                    company: data.company ?? defaultCompany.company,
                                    service_Items: data.service_Items ?? [],
                                    created_By: "",
                                    modified_By: "",
                                    created: "",
                                    modified: "",
                                }
                                let errors: ById<IFieldErrorKeyValue> = {};
                                RequiredFields.forEach((x) => {
                                    errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                                });
                                errors = this.errorChecker("display_Order", cloneData.display_Order.toString(), errors);
                                errors = this.errorChecker("company", cloneData.company, errors);
                                this.setState({
                                    data : cloneData,
                                    errors
                                });
                            } else {
                                this.props.getServiceSectionsRequest({ token });
                            }
                        } else {

                            let errors: ById<IFieldErrorKeyValue> = {};
    
                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });
                            errors = this.errorChecker("display_Order", this.state.data.display_Order.toString(), errors);
                            errors = this.errorChecker("company", this.state.data.company, errors);
    
                            this.setState({ errors });
                        }

                    };

                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateServiceSectionComponentStoreProps => ({
    token: getToken(state),
    lookUps: getPMLookups(state),
    companies: getCompanies(state),
    serviceTypes: getServiceSections(state),
    addServiceSectionStatus: addServiceSectionStatus(state),
    updateServiceSectionStatus: updateServiceSectionStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateServiceSectionComponentDispatchProps => ({
    lookUpRequest: (data: IPMLookupsRequest) => dispatch(getPMLookupsLoadAction(data)),
    companiesRequest: (data: IGetCompaniesRequest) => dispatch(getCompaniesLoadAction(data)),
    addServiceSectionRequest: (data: IAddServiceSectionRequest) => dispatch(addServiceSectionLoadAction(data)),
    getServiceSectionsRequest: (data: IGetServiceSectionRequest) => dispatch(getServiceSectionsLoadAction(data)),
    updateServiceSectionRequest: (data: IUpdateServiceSectionRequest) => dispatch(updateServiceSectionLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateServiceSection);


interface IServiceItemTableProps {
    disabled?: true;
    id:number;
    onDelete:(index:number, data:any)=>void;
    typeId: number;
    errors: number[];
    onAdd: () => void;
    data: IServiceItem[];
    lookUps?: IPMLookups;
    duplicateIndexList: number[];
    handleChange: (name: string, value: string, index?: number | undefined) => void;
    handleDropDownRemove: (name: string, value: string, index?: number | undefined) => void;
    handleDropDownChange: (name: string, value: IName[], index?: number | undefined) => void;
    handlePictureRequiredChange: (check:boolean, index?: number | undefined) => void;
    handleReorderChange: () => void;
};

export const ServiceItems: React.FC<IServiceItemTableProps> = React.memo((props: IServiceItemTableProps) => {

    const makeLookup = props.lookUps ? props.lookUps.makes : [];
    const equipments = props.lookUps ? props.lookUps.equipments.filter((x) => x.equipment_Type_ID === props.typeId).map(({ make_ID }) => make_ID) : [];

    const models = props.lookUps ? props.lookUps.equipments.filter((q) => q.equipment_Type_ID === props.typeId) : [];
    const serviceHoursOptions: IIdName[] = [{ id: 1, name: "100H" }, { id: 2, name: "250H" }, { id: 2, name: "500H" }, { id: 2, name: "1000H" }, { id: 2, name: "1500H" }, { id: 2, name: "3000H" }];

    return (
        <TableContainer component={LAPaperWithLessPadding}>

            {(props.duplicateIndexList.length > ZEROTH) &&
                <LAErrorBox text="We found some duplicate records below. Please fix it before save." />}

            <div className="header-container">
                <LAIconButton
                    label="Add New"
                    icon={<AddIcon />}
                    onClick={props.onAdd}
                    disabled={props.disabled}
                />

                <LALinkButton
                    label="Reorder"
                    onClick={props.handleReorderChange}
                />
            </div>

            <Table size="small" aria-label="Service Items">

                <TableHead className="header-row">
                    <TableRow>
                        <TableCell className="title">Item Description</TableCell>
                        <TableCell className="title">Active</TableCell>
                        <TableCell className="title">Service Hours</TableCell>
                        <TableCell className="title">Apply to Make</TableCell>
                        <TableCell className="title">Apply to Model</TableCell>
                        <TableCell className="title">Exclude Model</TableCell>
                        <TableCell className="title">Apply to Units</TableCell>
                        <TableCell className="title">Exclude Units</TableCell>
                        <TableCell className="title">Display Order</TableCell>
                        <TableCell className="title">Type</TableCell>
                        <TableCell className="title">Picture Required</TableCell>
                        <TableCell className="title">Comments</TableCell>
                        {props.id === 0 && <TableCell className="title"></TableCell>}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.data.map((x: IServiceItem, index: number) => {

                        const selMakeArray = x.apply_To_Make ? x.apply_To_Make.split(",") : [];
                        const selectedMakes = convertNameToIdName(selMakeArray);
                        const selModelArray = x.apply_To_Model ? x.apply_To_Model.split(",") : [];
                        const selectedModels = convertNameToIdName(selModelArray);
                        const selUnitArray = x.apply_To_Unit_No ? x.apply_To_Unit_No.split(",") : [];
                        const selectedUnits = convertNameToIdName(selUnitArray);
                        const selExcludeUnitArray = x.exclude_Unit_No ? x.exclude_Unit_No.split(",") : [];
                        const selExcludeModelArray = x.exclude_Model ? x.exclude_Model.split(",") : [];
                        const selectedExcludeUnits = convertNameToIdName(selExcludeUnitArray);
                        const selectedExcludeModel = convertNameToIdName(selExcludeModelArray);

                        const makes = makeList(props.typeId, makeLookup, equipments, selMakeArray);
                        const findMakeIds = makeIds(selMakeArray, makeLookup);
                        const filteredModels = modelList(models, selModelArray, findMakeIds);
                        const filteredExcludeModel = modelList(models, selExcludeModelArray, findMakeIds);
                        const filteredUnits = unitList(models, selUnitArray, findMakeIds, x.apply_To_Model);
                        const filteredExcludeUnits = unitList(models, selExcludeUnitArray, findMakeIds, x.apply_To_Model);

                        const makeRemove = (value: string): void => props.handleDropDownRemove("apply_To_Make", value !== null ? value : "", index);
                        const modelRemove = (value: string): void => props.handleDropDownRemove("apply_To_Model", value !== null ? value : "", index);
                        const unitRemove = (value: string): void => props.handleDropDownRemove("apply_To_Unit_No", value !== null ? value : "", index);
                        const excludeUnitRemove = (value: string): void => props.handleDropDownRemove("exclude_Unit_No", value !== null ? value : "", index);
                        const excludeModelRemove = (value: string): void => props.handleDropDownRemove("exclude_Model", value !== null ? value : "", index);

                        const onActive = (event: unknown, value: IIdName): void => props.handleChange("active", value !== null ? value.name : x.active, index);
                        const onServiceHours = (event: unknown, value: IIdName): void => props.handleChange("service_Hours", value !== null ? value.name : "", index);
                        const onType = (event: unknown, value: IIdName): void => props.handleChange("type", value !== null ? value.name : x.type, index);
                        const onMake = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Make", value !== null ? value : x.apply_To_Make, index);
                        const onModel = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Model", value !== null ? value : selectedModels, index);
                        const onUnit = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Unit_No", value !== null ? value : selectedUnits, index);
                        const onExcludeUnit = (event: unknown, value: any): void => props.handleDropDownChange("exclude_Unit_No", value !== null ? value : selectedExcludeUnits, index);
                        const onExcludeModel = (event: unknown, value: any): void => props.handleDropDownChange("exclude_Model", value !== null ? value : selectedExcludeModel, index);
                        const onPictureRequiredChange = (check: boolean): void => props.handlePictureRequiredChange(check, index); 
                        const onDisplayOrderChange = (event: unknown, value: any): void =>  props.handleChange("display_Order", value !== null ? value : "", index);

                        return <TableRow key={index} className={props.duplicateIndexList.includes(index) ? "red-row" : (index % 2 ? "odd-row" : "")}>

                            <TableCell width={200}>
                                <LATextField
                                    fullWidth={true}
                                    variant="outlined"
                                    indexPosition={index}
                                    name="service_Item_Name"
                                    label="Service Item Name"
                                    disabled={props.disabled}
                                    value={x.service_Item_Name}
                                    onChange={props.handleChange}
                                    // errorText={props.errors.includes(index) ? FIELD_VALIDATOR_ERRORS.REQUIRED : undefined}
                                    errorText={
                                        x.service_Item_Name.length === 0 ?
                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                            : undefined
                                    }
                                />
                            </TableCell>

                            <TableCell>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={YesOrNo}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onActive}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={x.active ? YesOrNo.find(q => q.name === x.active) : null}
                                    defaultValue={x.active ? YesOrNo.find(q => q.name === x.active) : null}
                                />
                            </TableCell>

                            <TableCell width={140}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={serviceHoursOptions}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onServiceHours}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Service Hours"
                                    selectionRemove={undefinedFunction}
                                    value={x.service_Hours ? serviceHoursOptions.find(q => q.name === x.service_Hours) : null}
                                    defaultValue={x.service_Hours ? serviceHoursOptions.find(q => q.name === x.service_Hours) : null}
                                />
                            </TableCell>

                            <TableCell width={250}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={true}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onMake}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Makes"
                                    selectionRemove={makeRemove}
                                    option={makes}
                                    value={selectedMakes}
                                    defaultValue={selectedMakes}
                                />
                            </TableCell>

                            <TableCell width={250}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={true}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onModel}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Models"
                                    selectionRemove={modelRemove}
                                    option={filteredModels}
                                    value={selectedModels}
                                    defaultValue={selectedModels}
                                />
                            </TableCell>

                            <TableCell width={250}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onExcludeModel}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Exclude Model"
                                    selectionRemove={excludeModelRemove}
                                    option={filteredExcludeModel}
                                    value={selectedExcludeModel}
                                    defaultValue={selectedExcludeModel}
                                />
                            </TableCell>

                            <TableCell width={250}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onUnit}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Units"
                                    selectionRemove={unitRemove}
                                    option={filteredUnits}
                                    value={selectedUnits}
                                    defaultValue={selectedUnits}
                                />
                            </TableCell>
                            <TableCell width={250}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onExcludeUnit}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Exclude Units"
                                    selectionRemove={excludeUnitRemove}
                                    option={filteredExcludeUnits}
                                    value={selectedExcludeUnits}
                                    defaultValue={selectedExcludeUnits}
                                />
                            </TableCell>

                            <TableCell width={150}>
                                    <LATextField
                                        label="Display Order"
                                        type="number"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={props.disabled}
                                        name="display_Order"
                                        value={x.display_Order}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                            },
                                        }}
                                        onChange={onDisplayOrderChange}
                                    />
                            </TableCell>

                            <TableCell width={150}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={ToggleOrInput}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onType}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Type"
                                    selectionRemove={undefinedFunction}
                                    value={x.type ? ToggleOrInput.find(q => q.name === x.type) : null}
                                    defaultValue={x.type ? ToggleOrInput.find(q => q.name === x.type) : null}
                                />
                            </TableCell>

                            <TableCell width={50}>
                            <LACheckBox
                                label=""
                                name="picture_Required"
                                onChange={onPictureRequiredChange}
                                disabled={props.disabled}
                                value={x.picture_Required === "Yes" ? true : false}
                            />
                         </TableCell>
                            <TableCell width={150}>
                                <LATextField
                                    label="Comments"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="comments"
                                    indexPosition={index}
                                    value={x.comments ?? ""}
                                    disabled={props.disabled}
                                    onChange={props.handleChange}
                                />
                            </TableCell>
                            {props.id === 0 && <TableCell width={150}>
                                <LAIconButton
                                    label="Delete"
                                    icon={<DeleteIcon />}
                                    onClick={(e:any)=>{props.onDelete(index, x)}} 
                                    disabled={undefined}
                                />
                            </TableCell>}

                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
});


export const ServiceItemsMobileView: React.FC<IServiceItemTableProps> = React.memo((props: IServiceItemTableProps) => {

    const makeLookup = props.lookUps ? props.lookUps.makes : [];
    const equipments = props.lookUps ? props.lookUps.equipments.filter((x) => x.equipment_Type_ID === props.typeId).map(({ make_ID }) => make_ID) : [];
    const models = props.lookUps ? props.lookUps.equipments.filter((q) => q.equipment_Type_ID === props.typeId) : [];
    const serviceHoursOptions: IIdName[] = [{ id: 1, name: "100H" }, { id: 2, name: "250H" }, { id: 2, name: "500H" }, { id: 2, name: "1000H" }, { id: 2, name: "1500H" }, { id: 2, name: "3000H" }];

    return (
        <LAPaperWithLessPadding>

            {(props.duplicateIndexList.length > ZEROTH) &&
                <LAErrorBox text="We found some duplicate records below. Please fix it before save." />}

            <LAGrid>

                <LAGridItem xs={12} className="text-center">
                <strong>SERVICE ITEMS</strong>

                    <LAIconButton
                        label="Add New"
                        icon={<AddIcon />}
                        onClick={props.onAdd}
                        disabled={props.disabled}
                    />

                    <LALinkButton
                        label="Reorder"
                        onClick={props.handleReorderChange}
                    />
                    <hr />
                </LAGridItem>

                {props.data.map((x: IServiceItem, index: number) => {

                    const selMakeArray = x.apply_To_Make ? x.apply_To_Make.split(",") : [];
                    const selectedMakes = convertNameToIdName(selMakeArray);
                    const selModelArray = x.apply_To_Model ? x.apply_To_Model.split(",") : [];
                    const selectedModels = convertNameToIdName(selModelArray);
                    const selUnitArray = x.apply_To_Unit_No ? x.apply_To_Unit_No.split(",") : [];
                    const selectedUnits = convertNameToIdName(selUnitArray);
                    const selExcludeUnitArray = x.exclude_Unit_No ? x.exclude_Unit_No.split(",") : [];
                    const selectedExcludeUnits = convertNameToIdName(selExcludeUnitArray);
                    const selExcludeModelArray = x.exclude_Model ? x.exclude_Model.split(",") : [];
                    const selectedExcludeModel = convertNameToIdName(selExcludeModelArray);

                    const makes = makeList(props.typeId, makeLookup, equipments, selMakeArray);
                    const findMakeIds = makeIds(selMakeArray, makeLookup);
                    const filteredModels = modelList(models, selModelArray, findMakeIds);
                    const filteredExcludeModel = modelList(models, selExcludeUnitArray, findMakeIds);
                    const filteredUnits = unitList(models, selUnitArray, findMakeIds, x.apply_To_Model);
                    const filteredExcludeUnits = unitList(models, selExcludeUnitArray, findMakeIds, x.apply_To_Model);

                    const makeRemove = (value: string): void => props.handleDropDownRemove("apply_To_Make", value !== null ? value : "", index);
                    const modelRemove = (value: string): void => props.handleDropDownRemove("apply_To_Model", value !== null ? value : "", index);
                    const unitRemove = (value: string): void => props.handleDropDownRemove("apply_To_Unit_No", value !== null ? value : "", index);
                    const excludeUnitRemove = (value: string): void => props.handleDropDownRemove("exclude_Unit_No", value !== null ? value : "", index);
                    const excludeModelRemove = (value: string): void => props.handleDropDownRemove("exclude_Model", value !== null ? value : "", index);

                    const onActive = (event: unknown, value: IIdName): void => props.handleChange("active", value !== null ? value.name : x.active, index);
                    const onServiceHours = (event: unknown, value: IIdName): void => props.handleChange("service_Hours", value !== null ? value.name : "", index);
                    const onType = (event: unknown, value: IIdName): void => props.handleChange("type", value !== null ? value.name : x.type, index);
                    const onMake = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Make", value !== null ? value : x.apply_To_Make, index);
                    const onModel = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Model", value !== null ? value : selectedModels, index);
                    const onUnit = (event: unknown, value: any): void => props.handleDropDownChange("apply_To_Unit_No", value !== null ? value : selectedUnits, index);
                    const onExcludeUnit = (event: unknown, value: any): void => props.handleDropDownChange("exclude_Unit_No", value !== null ? value : selectedExcludeUnits, index);
                    const onExcludeModel = (event: unknown, value: any): void => props.handleDropDownChange("exclude_Model", value !== null ? value : selectedExcludeUnits, index);
                    const onPictureRequiredChange = (check: boolean): void => props.handlePictureRequiredChange(check, index); 
                    const onDisplayOrderChange = (event: unknown, value: any): void =>  props.handleChange("display_Order", value !== null ? value : "", index);

                    return <LAGridItem key={index} xs={12}>
                            <LAPaperWithLessPadding className={props.duplicateIndexList.includes(index) ? "warning-row" : (index % 2 ? "odd-row" : "")}>
                        <LAGrid>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    fullWidth={true}
                                    variant="outlined"
                                    indexPosition={index}
                                    name="service_Item_Name"
                                    label="Service Item Name"
                                    disabled={props.disabled}
                                    value={x.service_Item_Name}
                                    onChange={props.handleChange}
                                    errorText={props.errors.includes(index) ? FIELD_VALIDATOR_ERRORS.REQUIRED : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={YesOrNo}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onActive}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={x.active ? YesOrNo.find(q => q.name === x.active) : null}
                                    defaultValue={x.active ? YesOrNo.find(q => q.name === x.active) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={serviceHoursOptions}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onServiceHours}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Service Hours"
                                    selectionRemove={undefinedFunction}
                                    value={x.service_Hours ? serviceHoursOptions.find(q => q.name === x.service_Hours) : null}
                                    defaultValue={x.service_Hours ? serviceHoursOptions.find(q => q.name === x.service_Hours) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={true}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onMake}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Makes"
                                    selectionRemove={makeRemove}
                                    option={makes}
                                    value={selectedMakes}
                                    defaultValue={selectedMakes}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={true}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onModel}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Models"
                                    selectionRemove={modelRemove}
                                    option={filteredModels}
                                    value={selectedModels}
                                    defaultValue={selectedModels}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onExcludeModel}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Exclude Model"
                                    selectionRemove={excludeModelRemove}
                                    option={filteredExcludeModel}
                                    value={selectedExcludeModel}
                                    defaultValue={selectedExcludeModel}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onUnit}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Apply to Units"
                                    selectionRemove={unitRemove}
                                    option={filteredUnits}
                                    value={selectedUnits}
                                    defaultValue={selectedUnits}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={true}
                                    onChange={onExcludeUnit}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    disabled={props.disabled}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Exclude Units"
                                    selectionRemove={excludeUnitRemove}
                                    option={filteredExcludeUnits}
                                    value={selectedExcludeUnits}
                                    defaultValue={selectedExcludeUnits}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextField
                                        label="Display Order"
                                        type="number"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={props.disabled}
                                        name="display_Order"
                                        value={x.display_Order}
                                        InputProps={{
                                            inputProps: {
                                                min: 0,
                                            },
                                        }}
                                        onChange={onDisplayOrderChange}
                                    />
                            </LAGridItem>
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={props.disabled}
                                    multiple={false}
                                    option={ToggleOrInput}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onType}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Type"
                                    selectionRemove={undefinedFunction}
                                    value={x.type ? ToggleOrInput.find(q => q.name === x.type) : null}
                                    defaultValue={x.type ? ToggleOrInput.find(q => q.name === x.type) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={2}>
                                <LACheckBox
                                    label=""
                                    name="picture_Required"
                                    onChange={onPictureRequiredChange}
                                    disabled={props.disabled}
                                    value={x.picture_Required === "Yes" ? true : false}
                                />
                            </LAGridItem>
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Comments"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="comments"
                                    indexPosition={index}
                                    value={x.comments ?? ""}
                                    disabled={props.disabled}
                                    onChange={props.handleChange}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                {props.id === 0 && 
                                    <LAIconButton
                                        label="Delete"
                                        icon={<DeleteIcon />}
                                        onClick={(e:any)=>{props.onDelete(index, x)}} 
                                        disabled={undefined}
                                    />
                                }
                            </LAGridItem>

                        </LAGrid>
                        </LAPaperWithLessPadding>
                        <hr />
                    </LAGridItem>
                })}
            </LAGrid>
        </LAPaperWithLessPadding>
    );
});

const convertNameToIdName = (data: any[], objName?: string): IName[] => {
    const list: IName[] = [];

    data.forEach((x) => {
        list.push({ name: objName ? x[objName] : x });
    });

    return list;
};

const makeList = (typeId: number, data: IPM_MakeLookup[], equList: number[], selected: string[]): IName[] => {
    const list: IName[] = [];

    data.forEach((x) => {
        if (equList.includes(x.id) && (selected.includes(x.manufacturer_Name) === false) && !list.find(q => q.name === x.manufacturer_Name))
            list.push({ name: x.manufacturer_Name });
    });

    return list.sort((a, b) => sortByName(a, b, "name"));
};

const modelList = (data: IPM_EquipmentLookup[], selected: string[], makeIds: number[]): IName[] => {
    const list: IName[] = [];
    // console.log(data)
    data
        .filter(x => (makeIds.length > 0) ? makeIds.includes(x.make_ID) : true)
        .forEach((x) => {

            if ((selected.includes(x.model) === false) && !list.find(q => q.name === x.model))

                list.push({ name: x.model });
        });

    return list.sort((a, b) => sortByName(a, b, "name"));
};

const unitList = (data: IPM_EquipmentLookup[], selected: string[], makeIds: number[], modelName?: string): IName[] => {
    const list: IName[] = [];
    // console.log(modelName, data)
    data
        .filter(x => (modelName && makeIds.length > 0) ?
            (makeIds.includes(x.make_ID) && (x.model === modelName))
            : true)
        .forEach((x) => {

            if ((selected.includes(x.unit_Number) === false) && !list.find(q => q.name === x.unit_Number))

                list.push({ name: x.unit_Number });
        });

    return list.sort((a, b) => sortByName(a, b, "name"));
};


const makeIds = (selected: string[], makes: IPM_MakeLookup[]): number[] => {
    const list: number[] = [];

    selected.forEach((x) => {
        const index = makes.findIndex((q) => q.manufacturer_Name === x);

        if (index >= 0)
            list.push(makes[index].id);
    });

    return list;
};