import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIRLINE_HISTORY_REQUEST, IGetRepairLineHistory } from "./getRepairLineHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetRepairLineHistoryLoadAction, IGetRepairLineHistoryLoadFailedAction, IGetRepairLineHistorySuccessAction, 
    getRepairLineHistoryLoadFailedAction, getRepairLineHistoryLoadSuccessAction } from "./getRepairLineHistoryActions";

export const getRepairLineHistoryEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineHistorySuccessAction | IGetRepairLineHistoryLoadFailedAction> =>
    action$.ofType(IGET_REPAIRLINE_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetRepairLineHistory>>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetRepairLineHistory>>): IGetRepairLineHistorySuccessAction => {
                            return getRepairLineHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineHistoryLoadFailedAction(response.message)))
                    )
            )
        );