import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { IGetRepairLineWOUnitsRequest, IRepairLineWOUnits } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsConstants";
import { getRepairLineWOUnitsLoadAction } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsActions";
import { getRepairLineWOUnits } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsAccessor";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";

interface IRepairLineWOUnitsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getRepairLineWOUnits: Server<SurewayAPIResponse<ById<IRepairLineWOUnits>>>;
};

interface IRepairLineWOUnitsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRepairLineWOUnitsRequest: (data: IGetRepairLineWOUnitsRequest) => unknown;
};


interface IRepairLineWOUnitsOwnProps {

};

interface IRepairLineWOUnitsState {
};

const RepairLineWOUnitsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 11%;
        };

        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 1%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IRepairLineWOUnitsProps = RouteComponentProps
    & IRepairLineWOUnitsStoreProps
    & IRepairLineWOUnitsDispatchProps
    & IRepairLineWOUnitsOwnProps;

class RepairLineWOUnits extends PureComponent<IRepairLineWOUnitsProps, IRepairLineWOUnitsState> {

    public constructor(props: IRepairLineWOUnitsProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IRepairLineWOUnitsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const {  } = this.state;
        const { getRepairLineWOUnits, getToken } = this.props;
        
        const getRole = pageAccessCheck(getToken, "workorder");
        const data = hasPayload(getRepairLineWOUnits) ? Object.values(getRepairLineWOUnits.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="WorkOrder Units" description="WorkOrder Units List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable && getRole !== "No")  &&<RepairLineWOUnitsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>
                    
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">Repair Line Work Order - Units</h2>
                                    <hr />

                                    {getRepairLineWOUnits.kind === STATUS_ENUM.LOADING && <LALoading message="Loading WorkOrder Units..." />}
                                    {getRepairLineWOUnits.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load WorkOrder Units..." />}

                                    {getRepairLineWOUnits.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={120}
                                                    paging={30}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="RepairLineWOUnitsList"
                                                    customRowColor={true}
                                                    storageKey="RepairLineWOUnitsSessionKey"
                                                    exportFileName="RepairLineWOUnits"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "unit_No", caption: "Unit#", type: "string" },
                                                        { name: "work_Order_No", caption: "WorkOrder", type: "string" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime"},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </RepairLineWOUnitsStyles>
                }
            </PageSpacing>
        
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNIT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNIT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "workorder")  === "Yes") {
                if (isNotLoaded(this.props.getRepairLineWOUnits)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getRepairLineWOUnitsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IRepairLineWOUnitsStoreProps => ({
    getToken: getToken(state),
    getRepairLineWOUnits: getRepairLineWOUnits(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRepairLineWOUnitsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRepairLineWOUnitsRequest: (data: IGetRepairLineWOUnitsRequest) => dispatch(getRepairLineWOUnitsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepairLineWOUnits);