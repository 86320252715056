
export enum IGET_ATTACHMENT_VERSION_HISTORY_REQUEST {
    REQUEST = "getAttachmentVersionHistory/GET_ATTACHMENT_VERSION_HISTORY_REQUEST",
    SUCCESS = "getAttachmentVersionHistory/GET_ATTACHMENT_VERSION_HISTORY_SUCCESS",
    FAILED = "getAttachmentVersionHistory/GET_ATTACHMENT_VERSION_HISTORY_FAILED"
};

export interface IGetAttachmentVersionHistoryRequest {
    request: {
        ID: number;
        Unit_ID? : number
    };
    token: string;
};

export interface IGetAttachmentVersionHistory {
    id: number;
    itemID: number;
    attachment_No: string;
    attachment_Unit_ID: number;
    type_ID: number;
    coupler_Design_ID: number;
    weight: number;
    width: number;
    capacity: number;
    operation: number;
    make: string;
    model: string;
    serial_Number: string;
    owner: string;
    inspection_Date: string;
    notes: string;
    photos: string;
    file_Attachments: string;
    status: string;
    working_Site_Sub_ID: number;
    move_Status: string;
    repair_Status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};