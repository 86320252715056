
export enum IADD_METER_READING_REQUEST {
    REQUEST = "addMeterReading/ADD_METER_READING_REQUEST",
    SUCCESS = "addMeterReading/ADD_METER_READING_SUCCESS",
    FAILED = "addMeterReading/ADD_METER_READING_FAILED"
};

export interface IAddMeterReadingRequest {
    token: string;
    request: IAddUpdateMeterReading;
};

export interface IAddUpdateMeterReading {
    ID: number;
    Unit_ID: number;
    Date: string;
    ECM: number;
    Current_Hours: number;
    Comments: string;
    Meter_Replacement?: string;
    Replacement_Date?: string;
    Meter_Reset?: number;
    Cancelled?: string;
    Created_By: string;
    Modified_By?: string;
}