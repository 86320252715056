import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_COST_CODES_BY_JOB_ID_REQUEST, IGetCostCodesByJobID, IGetCostCodesByJobIDRequest } from "./getCostCodesByJobIDConstants";

export interface IGetCostCodesByJobIDLoadAction {
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.REQUEST;
    data: IGetCostCodesByJobIDRequest
}
export const getCostCodesByJobIDLoadAction = (data: IGetCostCodesByJobIDRequest): IGetCostCodesByJobIDLoadAction => ({
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.REQUEST,
    data
});
export interface IGetCostCodesByJobIDSuccessAction {
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetCostCodesByJobID[]>;
}
export const getCostCodesByJobIDLoadSuccessAction = (list: SurewayAPIResponse<IGetCostCodesByJobID[]>): IGetCostCodesByJobIDSuccessAction => ({
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.SUCCESS,
    list
});
export interface IGetCostCodesByJobIDLoadFailedAction {
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.FAILED;
    message: string;
}
export const getCostCodesByJobIDLoadFailedAction = (message: string): IGetCostCodesByJobIDLoadFailedAction => ({
    type: IGET_COST_CODES_BY_JOB_ID_REQUEST.FAILED,
    message
});
