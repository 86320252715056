import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IUPDATE_JOB_PIT_REQUEST {
    REQUEST = "updateJobPit/UPDATE_JOB_PIT_REQUEST",
    SUCCESS = "updateJobPit/UPDATE_JOB_PIT_SUCCESS",
    FAILED = "updateJobPit/UPDATE_JOB_PIT_FAILED"
};

export interface IUpdateJobPitRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
        Job: string;
        Year: number;
        Description: string;
        Requested_By: string;
        Location: string;
        Active: string;
        Modified_By: string;
    };
};