import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EQUIPMENT_MOVE_REQUEST_REQUEST, IEquipmentMoveRequest } from "./getEquipmentMoveRequestConstants";
import { IGetEquipmentMoveRequestLoadAction, IGetEquipmentMoveRequestLoadFailedAction, IGetEquipmentMoveRequestSuccessAction, getEquipmentMoveRequestLoadFailedAction, getEquipmentMoveRequestLoadSuccessAction } from "./getEquipmentMoveRequestActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getEquipmentMoveRequestEpic: Epic = (
    action$: ActionsObservable<IGetEquipmentMoveRequestLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetEquipmentMoveRequestSuccessAction | IGetEquipmentMoveRequestLoadFailedAction> =>
    action$.ofType(IGET_EQUIPMENT_MOVE_REQUEST_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<IEquipmentMoveRequest[]>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.GET_EQUIPMENT_MOVE_REQUEST,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: IEquipmentMoveRequest[]): IGetEquipmentMoveRequestSuccessAction => {
                            return getEquipmentMoveRequestLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getEquipmentMoveRequestLoadFailedAction(response)))
                    )
            )
        );