import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_ROVER_KIT_REQUEST, IAddUpdateRoverKitRequest } from "./addRoverKitConstants";

export interface IAddRoverKitLoadAction {
    type: IADD_ROVER_KIT_REQUEST.REQUEST;
    data: IAddUpdateRoverKitRequest
}
export const addRoverKitLoadAction = (data: IAddUpdateRoverKitRequest): IAddRoverKitLoadAction => ({
    type: IADD_ROVER_KIT_REQUEST.REQUEST,
    data
});
export interface IAddRoverKitSuccessAction {
    type: IADD_ROVER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addRoverKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddRoverKitSuccessAction => ({
    type: IADD_ROVER_KIT_REQUEST.SUCCESS,
    list
});
export interface IAddRoverKitLoadFailedAction {
    type: IADD_ROVER_KIT_REQUEST.FAILED;
    message: string;
}
export const addRoverKitLoadFailedAction = (message: string): IAddRoverKitLoadFailedAction => ({
    type: IADD_ROVER_KIT_REQUEST.FAILED,
    message
});
