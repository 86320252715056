import React from "react";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { TableContainer } from "@mui/material";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { undefinedFunction } from "../../shared/constExports";
import { IGetFormVersionHistory } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";


interface IFormVersionHistoryPopupProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: IGetFormVersionHistory[];
    display: string | undefined;
    page: string;
};

export const FormVersionHistoryPopup: React.FC<IFormVersionHistoryPopupProps> = React.memo((props: IFormVersionHistoryPopupProps) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data)
    // const name = data && data[0]?.name;

    const formTemplateColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "form_Name", caption: "Form Name", type: "string" },
        { name: "type", caption: "Type", type: "string" },
        { name: "wsS_ID", caption: "WSS ID", type: "number" },
        { name: "foreman", caption: "Foreman", type: "string" },
        { name: "employees", caption: "Employees", type: "string" },
        { name: "frequency", caption: "Frequency", type: "string"  },
        { name: "start_Date", caption: "Start Date", type: "date"  },
        { name: "expiry_Date", caption: "Expiry Date", type: "date"  },
        { name: "flagged_Notification_Contacts", caption: "Flagged Notification Contacts", type: "string"  },
        { name: "flagged_Notification_Method", caption: "Flagged Notification Method", type: "string" },
        { name: "sections", caption: "Sections", type: "string"  },
        { name: "hazard_Assessment", caption: "Hazard Assessment", type: "string"  },
        { name: "departments", caption: "Departments", type: "string"  },
        { name: "active", caption: "Active", type: "string" },
        { name: "message", caption: "Message", type: "string" },
        { name: "wsS_IDS", caption: "WSS IDs", type: "string" },
        { name: "all_Sites", caption: "All Sites", type: "string" },
        { name: "requires_Crew", caption: "Requires Crew", type: "string" },
        { name: "requires_Guest", caption: "Requires Guest", type: "string" },
        { name: "requires_Toolbox", caption: "Requires Toolbox", type: "string" },
        { name: "attachments", caption: "Attachments", type: "string" },
        { name: "version", caption: "Version", type: "string", show: false  },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formTemplateQuestionsColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "form_ID", caption: "Form ID", type: "number" },
        { name: "question", caption: "Question", type: "string" },
        { name: "section", caption: "Section", type: "string" },
        { name: "type", caption: "Type", type: "string" },
        { name: "options", caption: "Options", type: "string" },
        { name: "display_Order", caption: "Display Order", type: "number" },
        { name: "flagged_Options", caption: "Flagged Options", type: "string" },
        { name: "required", caption: "Required", type: "string" },
        { name: "image_Required", caption: "Image Required", type: "string" },
        { name: "attachments", caption: "Attachments", type: "string" },
        { name: "images", caption: "Images", type: "string"  },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formResponseColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "name", caption: "Name", type: "string" },
        { name: "location", caption: "Location", type: "string" },
        { name: "supervisor", caption: "Supervisor", type: "string" },
        { name: "tool_Box_Meeting", caption: "Tool Box Meeting", type: "string" },
        // { name: "wsS_Log_ID", caption: "WSS Log ID", type: "number" },
        { name: "emp_ID", caption: "Emp_ID", type: "number", show: false },
        { name: "signature", caption: "Signature", type: "signature",  },
        { name: "department", caption: "Department", type: "string" },
        { name: "form_Updated", caption: "Form Updated", type: "string" },
        { name: "foreman_Signed", caption: "Foreman Signed", type: "string" },
        { name: "foreman_Signed_Date", caption: "Foreman Signed Date", type: "date" },
        { name: "status", caption: "Status", type: "string" },
        { name: "attachments", caption: "Attachments", type: "string" },
        { name: "form_Documents", caption: "Form Documents", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formSheetSectionsColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "question", caption: "Question", type: "string" },
        { name: "answer", caption: "Answer", type: "string" },
        { name: "flagged", caption: "Flagged", type: "string" },
        { name: "comments", caption: "Comments", type: "string" },
        { name: "images", caption: "Images", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formSheetTasksColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "task", caption: "Task", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formSheetHazardAssessmentColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "hazard", caption: "Hazard", type: "string" },
        { name: "severity", caption: "Severity", type: "string" },
        { name: "probability", caption: "Probability", type: "string" },
        { name: "control_Plans", caption: "Control Plans", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formAdminHazardsColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "hazard", caption: "Hazard", type: "string" },
        { name: "type", caption: "Hazard Type", type: "string" },
        { name: "active", caption: "Active", type: "string" },
        { name: "departments", caption: "Departments", type: "string" },
        { name: "admin_Approved", caption: "Admin Approved", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    const formAdminTasksColumns = [
        { name: "modified_By", caption: "Modified By", type: "string" },
        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
        { name: "name", caption: "Task", type: "string" },
        { name: "active", caption: "Active", type: "string" },
        { name: "departments", caption: "Departments", type: "string" },
        { name: "admin_Approved", caption: "Admin Approved", type: "string" },
        { name: "created_By", caption: "Created By", type: "string", show: false },
        { name: "created", caption: "Created", type: "datetime", show: false },
    ];

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>{props.display}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            // onEdit={onEdit}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            height={850}
                            removeStyleBtn={true}
                            id={`${props.page}List`}
                            customRowColor={true}
                            exportFileName={props.page}
                            storageKey={`${props.page}SessionKey`}
                            columns={
                                props.page === "FormTemplateVersionHistory" ? formTemplateColumns : 
                                props.page === "FormTemplateQuestionsVersionHistory" ? formTemplateQuestionsColumns : 
                                props.page === "FormResponseVersionHistory" ? formResponseColumns : 
                                props.page === "FormSheetSectionsVersionHistory" ? formSheetSectionsColumns : 
                                props.page === "FormSheetTasksVersionHistory" ? formSheetTasksColumns : 
                                props.page === "FormSheetHazardAssessmentVersionHistory" ? formSheetHazardAssessmentColumns : 
                                props.page === "FormAdminTaskVersionHistory" ? formAdminTasksColumns : 
                                props.page === "FormAdminHazardVersionHistory" ? formAdminHazardsColumns : 
                                []
                            }
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});