import { IUPDATE_KANTECH_ADJUSTMENT_REQUEST } from "./updateKantechAdjustmentConstants";
import { IUpdateKantechAdjustmentLoadAction, IUpdateKantechAdjustmentLoadFailedAction, IUpdateKantechAdjustmentSuccessAction } from "./updateKantechAdjustmentActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateKantechAdjustmentLoadAction
    | IUpdateKantechAdjustmentSuccessAction
    | IUpdateKantechAdjustmentLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateKantechAdjustmentReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_KANTECH_ADJUSTMENT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_KANTECH_ADJUSTMENT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_KANTECH_ADJUSTMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};