import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EQUIPMENT_REQUEST, IGetEquipment } from "./getEquipmentConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetEquipmentLoadAction, IGetEquipmentLoadFailedAction, IGetEquipmentSuccessAction, getEquipmentLoadFailedAction, getEquipmentLoadSuccessAction } from "./getEquipmentActions";

export const getEquipmentEpic: Epic = (
    action$: ActionsObservable<IGetEquipmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetEquipmentSuccessAction | IGetEquipmentLoadFailedAction> =>
    action$.ofType(IGET_EQUIPMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetEquipment>>>(
                    END_POINTS.FIELD.GET_EQUIPMENT_NEW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetEquipment>>): IGetEquipmentSuccessAction => {
                            return getEquipmentLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getEquipmentLoadFailedAction(response.message)))
                    )
            )
        );