import React, { useState } from "react";
import { ROUTE } from "../routes";
import styled from "styled-components";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { getTokenFromUrl } from "../shared/constExports";
import { hasPayload, Server } from "../../redux/server";
import { IToken } from "../../redux/getToken/getTokenConstants";
import { SurewayAPIResponse } from "../shared/publicInterfaces";
import { Menu } from "@material-ui/core";

const DispatchSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface IDispatchSubHeaderProps extends RouteComponentProps {
    token: Server<SurewayAPIResponse<IToken>>;
};

export const DispatchSubHeader: React.FC<IDispatchSubHeaderProps> = React.memo((props: IDispatchSubHeaderProps) => {

    const path = props.location.pathname;

    const [driverLogs, setDriverLogs] = useState<any>(null);
    const handleDriverLogs = (event?: any): void => driverLogs !== null ? setDriverLogs(null) : setDriverLogs(event.currentTarget);

    const onApprovedLogs = (): void => {
        props.history.push(ROUTE.FIELD.DISPATCH.APPROVE_DRIVER_LOGS);
    };

    const onViewLogs = (): void => {
        props.history.push(ROUTE.FIELD.DISPATCH.VIEW_DRIVER_LOGS);
    };

    const onDispatch = (): void => {
        props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT,
            search: getTokenFromUrl(false)
        });
    };

    const onDriverSwampers = (): void => {
        props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.SERVICE_TRUCKS,
            search: getTokenFromUrl(false)
        });
    };

    const onSwampers = (): void => {
        props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.SWAMPERS,
            search: getTokenFromUrl(false)
        });
    };

    const onHistory = (): void => {
        props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT_HISTORY,
            search: getTokenFromUrl(false)
        });
    };

    return (
        <DispatchSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={onDispatch} className={(path === ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT) ? "current" : ""}>
                            Dispatch
                        </LAMenuItem>

                        <LAMenuItem onClick={handleDriverLogs} className={(path.includes(ROUTE.FIELD.DISPATCH.APPROVE_DRIVER_LOGS) || path.includes(ROUTE.FIELD.DISPATCH.VIEW_DRIVER_LOGS)) ? "current" : ""}>
                            Driver Logs
                        </LAMenuItem>
                        
                        <Menu
                            anchorEl={driverLogs}
                            open={driverLogs}
                            onClose={handleDriverLogs}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            
                            {(hasPayload(props.token) && props.token.payload.response.repairLineRole === "Manager") && 
                                <LAMenuItem onClick={onApprovedLogs}>
                                    Approve Logs
                                </LAMenuItem>
                            }
                            <LAMenuItem onClick={onViewLogs}>
                                View Logs
                            </LAMenuItem>
                        </Menu>

                        <LAMenuItem onClick={onDriverSwampers} className={(path.includes(ROUTE.FIELD.DISPATCH.SERVICE_TRUCKS)) ? "current" : ""}>
                            Service Trucks
                        </LAMenuItem>

                        <LAMenuItem onClick={onSwampers} className={(path.includes(ROUTE.FIELD.DISPATCH.SWAMPERS)) ? "current" : ""}>
                            Swampers
                        </LAMenuItem>

                        <LAMenuItem onClick={onHistory} className={(path === ROUTE.FIELD.DISPATCH.DRIVER_ASSIGNMENT_HISTORY) ? "current" : ""}>
                            History
                        </LAMenuItem>
                    </div>
                </p>
            </div>

        </DispatchSubHeaderStyles>
    );
});