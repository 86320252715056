import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST } from "./updateRepairLineWOUnitConstants";
import { IUpdateRepairLineWOUnitLoadAction, IUpdateRepairLineWOUnitLoadFailedAction, IUpdateRepairLineWOUnitSuccessAction, updateRepairLineWOUnitLoadFailedAction, updateRepairLineWOUnitLoadSuccessAction } from "./updateRepairLineWOUnitActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateRepairLineWOUnitEpic: Epic = (
    action$: ActionsObservable<IUpdateRepairLineWOUnitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateRepairLineWOUnitSuccessAction | IUpdateRepairLineWOUnitLoadFailedAction> =>
    action$.ofType(IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.UPDATE_REPAIR_LINE_WO_UNIT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateRepairLineWOUnitSuccessAction | IUpdateRepairLineWOUnitLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateRepairLineWOUnitLoadSuccessAction(response);
                            } else {
                                return updateRepairLineWOUnitLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateRepairLineWOUnitLoadFailedAction(response.message)))
                    )
            )
        );