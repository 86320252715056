import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MOVE_REQUEST_HISTORY_REQUEST, IMoveRequestHistory, IGetMoveRequestHistoryRequest } from "./getMoveRequestHistoryConstants";

export interface IGetMoveRequestHistoryLoadAction {
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.REQUEST;
    data: IGetMoveRequestHistoryRequest
}
export const getMoveRequestHistoryLoadAction = (data: IGetMoveRequestHistoryRequest): IGetMoveRequestHistoryLoadAction => ({
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetMoveRequestHistorySuccessAction {
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IMoveRequestHistory>>;
}
export const getMoveRequestHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IMoveRequestHistory>>): IGetMoveRequestHistorySuccessAction => ({
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetMoveRequestHistoryLoadFailedAction {
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getMoveRequestHistoryLoadFailedAction = (message: string): IGetMoveRequestHistoryLoadFailedAction => ({
    type: IGET_MOVE_REQUEST_HISTORY_REQUEST.FAILED,
    message
});
