
export enum IGET_TASKS_REQUEST {
    REQUEST = "getTasks/GET_TASKS_REQUEST",
    SUCCESS = "getTasks/GET_TASKS_SUCCESS",
    FAILED = "getTasks/GET_TASKS_FAILED"
};

export interface IGetTasksRequest {
    token: string;
};

export interface IGetTasks {
    id: number;
    name: string;
    active: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    // department_ID: number;
    departments: string;
};

