import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST } from "./saveEmployeeMessageResponseConstants";
import { ISaveEmployeeMessageResponseLoadAction, ISaveEmployeeMessageResponseLoadFailedAction, ISaveEmployeeMessageResponseSuccessAction } from "./saveEmployeeMessageResponseActions";
type Actions =
    | ISaveEmployeeMessageResponseLoadAction
    | ISaveEmployeeMessageResponseSuccessAction
    | ISaveEmployeeMessageResponseLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveEmployeeMessageResponseReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.REQUEST:
            return loading;

        case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};