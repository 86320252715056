import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_CREW_BY_USERNAME_REQUEST, IGetCrewByUsername } from "./getCrewByUsernameConstants";
import { IGetCrewByUsernameLoadAction, IGetCrewByUsernameLoadFailedAction, IGetCrewByUsernameSuccessAction } from "./getCrewByUsernameActions";
import { IAddFieldEmployeeSuccessAction } from "../addFieldEmployee/addFieldEmployeeActions";
import { IADD_FIELDEMPLOYEE_REQUEST } from "../addFieldEmployee/addFieldEmployeeConstants";
import { IUpdateFieldEmployeeSuccessAction } from "../updateFieldEmployee/updateFieldEmployeeActions";
import { IUPDATE_FIELDEMPLOYEE_REQUEST } from "../updateFieldEmployee/updateFieldEmployeeConstants";

type Actions =
    | IGetCrewByUsernameLoadAction
    | IGetCrewByUsernameSuccessAction
    | IGetCrewByUsernameLoadFailedAction
    | IAddFieldEmployeeSuccessAction
    | IUpdateFieldEmployeeSuccessAction
    | IFlushDataSuccessAction;

export const GetCrewByUsernameReducer = (state: Server<SurewayAPIResponse<IGetCrewByUsername>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetCrewByUsername>> => {
    switch (action.type) {
        case IGET_CREW_BY_USERNAME_REQUEST.REQUEST:
            return loading;

        case IGET_CREW_BY_USERNAME_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CREW_BY_USERNAME_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_FIELDEMPLOYEE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_FIELDEMPLOYEE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};