import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_COMPONENT_WAITING_PARTS_REQUEST, IComponentWaitingParts, IGetComponentWaitingPartsRequest } from "./getComponentWaitingPartsConstants";

export interface IGetComponentWaitingPartsLoadAction {
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.REQUEST;
    data: IGetComponentWaitingPartsRequest
}
export const getComponentWaitingPartsLoadAction = (data: IGetComponentWaitingPartsRequest): IGetComponentWaitingPartsLoadAction => ({
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.REQUEST,
    data
});
export interface IGetComponentWaitingPartsSuccessAction {
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IComponentWaitingParts[]>;
}
export const getComponentWaitingPartsLoadSuccessAction = (list: SurewayAPIResponse<IComponentWaitingParts[]>): IGetComponentWaitingPartsSuccessAction => ({
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS,
    list
});
export interface IGetComponentWaitingPartsLoadFailedAction {
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.FAILED;
    message: string;
}
export const getComponentWaitingPartsLoadFailedAction = (message: string): IGetComponentWaitingPartsLoadFailedAction => ({
    type: IGET_COMPONENT_WAITING_PARTS_REQUEST.FAILED,
    message
});
