import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateMachineReceiverRequest } from "../addMachineReceiver/addMachineReceiverConstants";
import { IUPDATE_MACHINE_RECEIVER_REQUEST } from "./updateMachineReceiverConstants";

export interface IUpdateMachineReceiverLoadAction {
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.REQUEST;
    data: IAddUpdateMachineReceiverRequest
}
export const updateMachineReceiverLoadAction = (data: IAddUpdateMachineReceiverRequest): IUpdateMachineReceiverLoadAction => ({
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.REQUEST,
    data
});
export interface IUpdateMachineReceiverSuccessAction {
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateMachineReceiverLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateMachineReceiverSuccessAction => ({
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.SUCCESS,
    list
});
export interface IUpdateMachineReceiverLoadFailedAction {
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.FAILED;
    message: string;
}
export const updateMachineReceiverLoadFailedAction = (message: string): IUpdateMachineReceiverLoadFailedAction => ({
    type: IUPDATE_MACHINE_RECEIVER_REQUEST.FAILED,
    message
});
