import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_SMS_LOGS_REQUEST {
    REQUEST = "getRepairSmsLogs/GET_REPAIR_SMS_LOGS_REQUEST",
    SUCCESS = "getRepairSmsLogs/GET_REPAIR_SMS_LOGS_SUCCESS",
    FAILED = "getRepairSmsLogs/GET_REPAIR_SMS_LOGS_FAILED"
};

export interface IGetRepairLineSmsLogsRequest extends ISurewayTokenRequestBody {

};

export interface IRepairLineSmsLog {
    id: number;
    mechanic_ID: number;
    repair_Line_ID: number;
    repair_Type: string;
    status: string;
    created: string;
    created_By: string;
    message: string;
};