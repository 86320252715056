import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, SurveyDD, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { IAddUpdateLevel, IAddUpdateLevelRequest } from "../../../redux/survey/addLevel/addLevelConstants";
import { addLevelLoadAction } from "../../../redux/survey/addLevel/addLevelActions";
import { updateLevelLoadAction } from "../../../redux/survey/updateLevel/updateLevelActions";
import LAAutoComplete from "../../shared/autoComplete";
import { ILevels, ILevelsRequest } from "../../../redux/survey/getLevels/getLevelsConstants";
import { addLevels } from "../../../redux/survey/addLevel/addLevelAccessor";
import { getLevels } from "../../../redux/survey/getLevels/getLevelsAccessor";
import { updateLevel } from "../../../redux/survey/updateLevel/updateLevelAccessor";
import { getLevelsLoadAction } from "../../../redux/survey/getLevels/getLevelsActions";
import LADatePicker from "../../shared/datePicker";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ISurveyForemanList, ISurveyLookup, ISurveyLookupRequest } from "../../../redux/survey/getSurveyLookup/getSurveyLookupConstants";
import { getSurveyLookupLoadAction } from "../../../redux/survey/getSurveyLookup/getSurveyLookupActions";
import { GetSurveyLookup } from "../../../redux/survey/getSurveyLookup/getSurveyLookupAccessor";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";


const RequiredFields: string[] = ["serial_No"];

interface IAddUpdateLevelComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    addLevelStatus: Server<SurewayAPIResponse<string>>;
    updateLevelStatus: Server<SurewayAPIResponse<string>>;
    getLevelsStatus: Server<SurewayAPIResponse<ById<ILevels>>>;
};

interface IAddUpdateLevelComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISurveyLookupRequest) => unknown;
    getLevelsRequest: (data: ILevelsRequest) => unknown;
    addLevelRequest: (data: IAddUpdateLevelRequest) => unknown;
    updateLevelRequest: (data: IAddUpdateLevelRequest) => unknown;
};

interface IAddUpdateLevelOwnProps {
    id?: string;
};

interface IAddUpdateLevelComponentState {
    data: ILevels;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateLevelStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .green-border {
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateLevelComponentProps =
    RouteComponentProps
    & IAddUpdateLevelOwnProps
    & IAddUpdateLevelComponentStoreProps
    & IAddUpdateLevelComponentDispatchProps;

class AddUpdateLevel extends PureComponent<IAddUpdateLevelComponentProps, IAddUpdateLevelComponentState> {

    public constructor(props: IAddUpdateLevelComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                serial_No: "",
                notes: "",
                wsS_ID: 0,
                foreman: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateLevelComponentProps): void {
        if (this.props !== prevProps) {

            if ((this.props.getLevelsStatus !== prevProps.getLevelsStatus)
                || (this.props.token !== prevProps.token))
                this.setDataToState();

            if (this.props.addLevelStatus !== prevProps.addLevelStatus) {

                if (this.props.addLevelStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addLevelStatus.message });

                if (hasPayload(this.props.addLevelStatus) && this.props.addLevelStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateLevelStatus !== prevProps.updateLevelStatus) {

                if (this.props.updateLevelStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateLevelStatus.message });

                if (hasPayload(this.props.updateLevelStatus) && this.props.updateLevelStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " LEVEL";
        const { addLevelStatus, updateLevelStatus, getLookup } = this.props;

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];

        const onDropDown = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status ?? "");
        const onWSS = (value: string): void => this.handleNumber("wsS_ID", value);
        const onForeman = (event: unknown, value: ISurveyForemanList): void => this.handleChange("foreman", value !== null ? value.upn : "");

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateLevelStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Serial #"
                                fullWidth={true}
                                variant="outlined"
                                name="serial_No"
                                value={data.serial_No}
                                onChange={this.handleChange}
                                errorText={errors["serial_No"] ? errors["serial_No"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Brand"
                                fullWidth={true}
                                variant="outlined"
                                name="brand"
                                value={data.brand ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["brand"] ? errors["brand"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Model"
                                fullWidth={true}
                                variant="outlined"
                                name="model"
                                value={data.model ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["model"] ? errors["model"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={SurveyDD}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onDropDown}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                value={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        {/* <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Site Foreman"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="site_Foreman"
                                value={data.site_Foreman ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["site_Foreman"] ? errors["site_Foreman"].message : undefined}
                            />
                        </LAGridItem> */}

                        <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={wssList ?? []}
                                id="wss-search"
                                placeHolder="Site"
                                disabled={undefined}
                                displayExp="site"
                                returnExp="wsS_ID"
                                name="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                currentVal={data.wsS_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                disabled={undefined}
                                multiple={false}
                                option={foremans}
                                autoHighlight={true}
                                onChange={onForeman}
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                dropDownPlaceHolder="Foreman"
                                selectionRemove={undefinedFunction}
                                value={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                                defaultValue={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="text-center">
                        <strong>Certificate Date</strong>
                        <br />
                        <LADatePicker
                            mode="single"
                            id="date-cert"
                            onChange={this.handleDate}
                            value={data.certification_Date ?? ""}
                        />
                    </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addLevelStatus.kind} successMessage="Level has been added successfully" />
                    <RequestStatus requestStatus={updateLevelStatus.kind} successMessage="Level has been updated successfully" />

                </AddUpdateLevelStyles>
            </PageSpacing>
        );
    }

        private handleDate = (date: string): void => {
            const certification_Date = (date !== "" && date !== null) 
            ? new Date(date).toISOString()
            : "";

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    certification_Date
                }
            });
        };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.LEVELS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const request: IAddUpdateLevel = {
            ID: data.id,
            Serial_No: data.serial_No,
            Brand: data.brand,
            Model: data.model,
            Status: data.status,
            Certification_Date: data.certification_Date,
            Notes: data.notes,
            WSS_ID: data.wsS_ID && data.wsS_ID > 0 ? data.wsS_ID : null,
            Foreman: data.foreman,
            Created_By: data.created_By,
            Modified_By: data.modified_By
        };

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addLevelRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            }
            else {
                request.Modified_By = this.props.token.payload.response.upn;

                this.props.updateLevelRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private handleNumber = (name: string, value: string): void => {
        if (value !== undefined) {
            // console.log(value)
            let errors = { ...this.state.errors };

            if (RequiredFields.includes(name))
                errors = this.errorChecker(name, value.toString(), errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getLevelsStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getLevelsStatus.payload.response[query.id];

                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getLevelsRequest({
                            token
                        });
                    }
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };

            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Survey_Pages"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateLevelComponentStoreProps => ({
    token: getToken(state),
    getLookup: GetSurveyLookup(state),
    addLevelStatus: addLevels(state),
    getLevelsStatus: getLevels(state),
    updateLevelStatus: updateLevel(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateLevelComponentDispatchProps => ({
    getLevelsRequest: (data: ILevelsRequest) => dispatch(getLevelsLoadAction(data)),
    getLookupsRequest: (data: ISurveyLookupRequest) => dispatch(getSurveyLookupLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    addLevelRequest: (data: IAddUpdateLevelRequest) => dispatch(addLevelLoadAction(data)),
    updateLevelRequest: (data: IAddUpdateLevelRequest) => dispatch(updateLevelLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateLevel);