import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_COMPONENT_WAITING_PARTS_REQUEST, ISaveComponentWaitingPartsRequest } from "./saveComponentWaitingPartsConstants";

export interface ISaveComponentWaitingPartsLoadAction {
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.REQUEST;
    data: ISaveComponentWaitingPartsRequest
}
export const saveComponentWaitingPartsLoadAction = (data: ISaveComponentWaitingPartsRequest): ISaveComponentWaitingPartsLoadAction => ({
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.REQUEST,
    data
});
export interface ISaveComponentWaitingPartsSuccessAction {
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveComponentWaitingPartsLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveComponentWaitingPartsSuccessAction => ({
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS,
    message
});
export interface ISaveComponentWaitingPartsLoadFailedAction {
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.FAILED;
    message: string;
}
export const saveComponentWaitingPartsLoadFailedAction = (message: string): ISaveComponentWaitingPartsLoadFailedAction => ({
    type: ISAVE_COMPONENT_WAITING_PARTS_REQUEST.FAILED,
    message
});
