
export enum IADD_KANTECH_ADJUSTMENT_REQUEST {
    REQUEST = "addKantechAdjustment/ADD_KANTECH_ADJUSTMENT_REQUEST",
    SUCCESS = "addKantechAdjustment/ADD_KANTECH_ADJUSTMENT_SUCCESS",
    FAILED = "addKantechAdjustment/ADD_KANTECH_ADJUSTMENT_FAILED"
};

export interface IAddKantechAdjustmentRequest {
    token: string;
    request: IAddUpdateKantechAdjustment;
};

export interface IAddUpdateKantechAdjustment {
    ID?: number;
    UserID: number;
    PunchTime: string;
    In_Out: string;
    Notes: string;
    Created_By: string;
    Modified_By: string;
};