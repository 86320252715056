import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST, IGetUnitInspectionSheetByID } from "./getUnitInspectionSheetByIDConstants";
import { IGetUnitInspectionSheetByIDLoadAction, IGetUnitInspectionSheetByIDLoadFailedAction, IGetUnitInspectionSheetByIDSuccessAction } from "./getUnitInspectionSheetByIDActions";
import { ISaveInspectionSheetSuccessAction } from "../saveInspectionSheet/saveInspectionSheetActions";
import { ISAVE_INSPECTION_SHEET_REQUEST } from "../saveInspectionSheet/saveInspectionSheetConstants";

type Actions =
    | IGetUnitInspectionSheetByIDLoadAction
    | IGetUnitInspectionSheetByIDSuccessAction
    | IGetUnitInspectionSheetByIDLoadFailedAction
    | ISaveInspectionSheetSuccessAction
    | IFlushDataSuccessAction;

export const GetUnitInspectionSheetByIDReducer = (state: Server<SurewayAPIResponse<IGetUnitInspectionSheetByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetUnitInspectionSheetByID>> => {
    switch (action.type) {
        case IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case ISAVE_INSPECTION_SHEET_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};