import { IAddUpdateServiceDue } from "../addServiceDue/addServiceDueConstants";

export enum IUPDATE_SERVICE_DUE_REQUEST {
    REQUEST = "updateServiceDue/UPDATE_SERVICE_DUE_REQUEST",
    SUCCESS = "updateServiceDue/UPDATE_SERVICE_DUE_SUCCESS",
    FAILED = "updateServiceDue/UPDATE_SERVICE_DUE_FAILED"
};

export interface IUpdateServiceDueRequest {
    token: string;
    request: IAddUpdateServiceDue;
};