import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_MOVE_REQUESTS_REQUEST, IMoveRequests } from "./getMoveRequestsConstants";
import { IGetMoveRequestsLoadAction, IGetMoveRequestsLoadFailedAction, IGetMoveRequestsSuccessAction } from "./getMoveRequestsActions";
import { ISAVE_DRIVER_ASSIGNMENT_REQUEST } from "../saveDriverAssignment/saveDriverAssignmentConstants";
import { ISaveDriverAssignmentSuccessAction } from "../saveDriverAssignment/saveDriverAssignmentActions";

type Actions =
    | IGetMoveRequestsLoadAction
    | IGetMoveRequestsSuccessAction
    | IGetMoveRequestsLoadFailedAction
    | ISaveDriverAssignmentSuccessAction
    | IFlushDataSuccessAction;

export const GetMoveRequestsReducer = (state: Server<SurewayAPIResponse<IMoveRequests>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IMoveRequests>> => {
    switch (action.type) {
        case IGET_MOVE_REQUESTS_REQUEST.REQUEST:
            return loading;

        case IGET_MOVE_REQUESTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MOVE_REQUESTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_DRIVER_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};