import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  StateStoring,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import {Button as MUIButton, Typography, TextField, InputLabel, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, FormHelperText, Autocomplete, Paper, Modal, Fade, Box, Backdrop, RadioGroup, Radio} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';
import SOCTHome from './socthome';
import {getAllSOCTUnits, getAPIListUnitByUnitNumber} from './api/soct/soctAPI';
import {getSOCTBulkMeterReading, getSOCTMeterReading, AddMeterReading} from './api/meterreading/meterReadingAPI';
import { OutlinedRequiredStyle, OutlinedRequiredStyleSelected } from '../../shared/styles';
import { getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID } from './api/workingsites/workingSitesAPI';
import splitDate from '../../shared/splitDate';
import {currentUser, hasUserAccess} from './socthome';
import {LACenteredLoading} from '../../shared/loading';


interface IState {
    soctMaster:any;
    selectTextOnEditStart:boolean;
    startEditAction:string;
    meter_list:any[];
    showAddNewForm:boolean;
    modalOpen:boolean;
    pageLoaded:boolean;
    replacementType:string;
    searchValue:string;
  }
  interface IProps extends RouteComponentProps{
    history:any
  }
  
  var editData:any;
  var unitNumbers:any = [];
  const filterBuilderPopupPosition = {
      of: window,
      at: 'top',
      my: 'top',
      offset: { y: 10 },
    };
    const renderTitleHeader = (data:any) => {
      return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
  }
  
const BoxStyle:any = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  backgroundColor: 'rgb(241, 241, 241) !important',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '4ch',
};
  var globalEditID:Number = 0;
  var working_site_locations:any[] = [];
  var sub_location_list:any = [];

  var newMeterReading:any = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',location:'',
  current_Sub_Location:'',current_Sub_Location_ID:0, equipment_Type:'', meterReplacementCheck:false, meter_reset:'',
   replacement_date:'', replacementType:''};
  var editMeterReadingDataBulk:any;
  var working_site_locations:any[] = [];
  var sub_site_locations:any[] = [];
  var working_site_location_data:any[] = [];
  var filterLatestMeterReadings: any;

  export default class BulkSOTC extends React.Component<IProps,IState>{
    constructor(props:any){
      super(props);
      this.state ={soctMaster:[], selectTextOnEditStart: true, startEditAction: 'dblClick', meter_list:[], showAddNewForm:false, modalOpen:true, pageLoaded:false,replacementType:'', searchValue: ''};
      this.handleChange = this.handleChange.bind(this);
      this.onAddNew = this.onAddNew.bind(this);
      this.onAddNewCancel = this.onAddNewCancel.bind(this);
      this.onAddNewSave = this.onAddNewSave.bind(this);
      this.refreshState = this.refreshState.bind(this);
      this.handleMeterReplacement = this.handleMeterReplacement.bind(this);
      this.filterLatestMeterAdjustment = this.filterLatestMeterAdjustment.bind(this);
    }
    handleMeterReplacement(eventKey:any){
      switch(eventKey.target.value) {
          case 'Rollover':
              newMeterReading.replacementType = 'Rollover';
              this.setState({replacementType:'Rollover'});
              break;
         case 'Reset':
          newMeterReading.replacementType = 'Reset';
          this.setState({replacementType:'Reset'});
             break;
         }
  }
    onAddNew(){
      this.setState({showAddNewForm:true});
    }
    onAddNewCancel(){
      newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',location:'',
          current_Sub_Location:'',current_Sub_Location_ID:0, equipment_Type:''};
      this.setState({showAddNewForm:false});
    }
    async onAddNewSave(){
      console.log(newMeterReading)
      if(newMeterReading.meterReplacementCheck === true){
        if(newMeterReading.meter_reset!='' && newMeterReading.replacement_date!='' && newMeterReading.replacementType!=''){
          var res:any = await AddMeterReading(newMeterReading, currentUser);
          if(res.status === 200){
              newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',location:'',
              current_Sub_Location:'',current_Sub_Location_ID:0, equipment_Type:''};
              this.setState({showAddNewForm:false});
              this.refreshState();
          }
          else{
              alert("Server error: issue adding meter reading. Please refresh page and try again");
          }
        }
        else{
          alert("Meter rollover/reset, replacement date and replacement type is required for meter replacements");
      }
      }
      else{
         // reset replacement type if meterReplacementCheck was unchecked
         newMeterReading.replacementType = "";
      if(newMeterReading.date != '' && newMeterReading.unit_Number!= '' 
      && (newMeterReading.current_Hours!=''  || newMeterReading.ecm!=''))
        {
        var res:any = await AddMeterReading(newMeterReading, currentUser);
        if(res.status === 200){
            newMeterReading = {unit_Number:'', previous_Date:'', previous_Reading:'',date:'',current_Hours:'',ecm:'', comments:'',location:'',
            current_Sub_Location:'',current_Sub_Location_ID:0, equipment_Type:''};
            this.setState({showAddNewForm:false});
            this.refreshState();
        }
        else{
            alert("Server error: issue adding meter reading. Please refresh page and try again");
        }
      }
      else{
        alert("Unit Number, Date and hours/ecm are mandatory.");
      }
    }
    }
    handleChange(event:any){
      const fieldName:string = event.target.name;
      switch(fieldName){
          case 'date':
              newMeterReading.date = event.target.value;
              break;
          case 'current_hours':
              newMeterReading.current_Hours = event.target.value;
              break;
          case 'ecm':
              newMeterReading.ecm = event.target.value;
              break;
          case 'comments':
              newMeterReading.comments = event.target.value;
              break;
          // case 'update_location_check':
          //     newMeterReading.updateLocationCheck = !newMeterReading.updateLocationCheck;
          //     break;
          case 'meter_replacement_check':
            newMeterReading.meterReplacementCheck = !newMeterReading.meterReplacementCheck;
            break;
          case 'replacement_date':
              newMeterReading.replacement_date = event.target.value;
              break;
          case 'meter_reset':
              newMeterReading.meter_reset = event.target.value;
              break;
      }
      this.setState({});
  }
    editItem(id:number, data:any){
      editMeterReadingDataBulk = data;
      const idExistsInLatestMeters = filterLatestMeterReadings.some((obj: any) => obj.id === data.id)
      if(idExistsInLatestMeters) {
        this.props.history.push('/field/soct/meters/edit/id='+id+'&latest');
      } else{
        this.props.history.push('/field/soct/meters/edit/id='+id);
      }
    }
    async refreshState(){
      unitNumbers = await getAllSOCTUnits();
      var res:any = await getSOCTBulkMeterReading();
      var tempsites:any = await getSOCTWorkingSites(true);
            tempsites.map((location:any) => {
                working_site_locations.push(location.site_Name);
                working_site_location_data.push(location);
                });
                working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
                console.log(res, unitNumbers)
      this.setState({meter_list:res, pageLoaded:true});
      filterLatestMeterReadings = this.filterLatestMeterAdjustment(this.state.meter_list);
      console.log(this.state.meter_list)

    }
    componentDidMount(){
      this.refreshState();
      this.setState({});
    }
    handleValueChange = (e:any) => {
      // console.log(e);
      const trimmedValue = e.trim();
      this.setState({searchValue:trimmedValue });
    };
      render(){
        if(!this.state.pageLoaded){
          return <LACenteredLoading></LACenteredLoading>
        }
        else{
          return (
            <>
            <SOCTHome history={this.props.history}></SOCTHome>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Bulk Meter Adjustment</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                    <Grid container spacing={6}>
                <Grid item xs={12}>
                <MUIButton disabled={hasUserAccess} style={{backgroundColor:'#bf0000', marginLeft:'0%'}} onClick={this.onAddNew} variant="contained" startIcon={<AddCircleIcon />}>Add new</MUIButton>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.meter_list}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                >
                  <StateStoring enabled={true} type="localStorage" storageKey="storage" />
                     <SearchPanel visible={true}
                    width={340}
                    placeholder="Search for..." 
                    // text={this.state.searchValue}
                    // onTextChange={this.handleValueChange}
                    />    
                    <FilterRow visible={true}></FilterRow>
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Paging enabled={true} defaultPageSize={20}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[20,40,60]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                    <Column type="buttons" caption="Edit" width={100} headerCellRender={renderTitleHeader}>
                        <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
                    </Column>
                    <Column dataType="string" dataField="unit_Number" caption="Unit Number" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
                    <Column dataType="date" dataField="date" caption="Date" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="number" dataField="current_Hours" caption="Current Hours" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="location" caption="Location" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="sub_Location" caption="Sub Location" alignment="left" headerCellRender={renderTitleHeader}/>
                    {/* <Column dataType="string" dataField="new_Location" caption="New Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="new_Sub_Location" caption="New Sub Location" alignment="left"  headerCellRender={renderTitleHeader}/> */}
                    {/* <Column dataType="int" dataField="new_Sub_Location_ID" caption="New Location ID" width={80} alignment="left"  headerCellRender={renderTitleHeader}/> */}
                    <Column dataType="string" dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="cancelled" caption="Cancelled" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column sortOrder={"desc"} dataType="datetime" dataField="created" caption="Created" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="created_By" caption="Created By" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="source" caption="Source" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="source_ID" caption="Source ID" alignment="left" headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Grid>
            </Grid>
                                    {this.state.showAddNewForm ? 
                                    <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.modalOpen}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                    >  
                                    <Fade in={this.state.modalOpen}>
                                    <Box sx={BoxStyle}>
                <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <FormControl sx={{width:'100%'}}>
                          <Autocomplete
                    onChange={async (event:any, newValue:String | null) => {
                      console.log(String(newValue));
                        let unit_number = String(newValue);
                        newMeterReading.unit_Number=unit_number;
                        var res:any = await getAPIListUnitByUnitNumber(unit_number);
                        var result:any = await getSOCTMeterReading(res.socT_ID)

                        result.sort(function(a:any,b:any){return new Date(b.date).getTime() - new Date(a.date).getTime()});
                        let mostRecentMeterReading = result[0];
                        if(result.length > 0){
                          newMeterReading.previous_Date = mostRecentMeterReading.date;
                          newMeterReading.previous_Reading = mostRecentMeterReading.current_Hours;
                        }
                          newMeterReading.equipment_Type = res.equipment_Type;
                        
                        newMeterReading.location = res.site_Name;
                        newMeterReading.current_Sub_Location = res.sub_Site_Name;
                        newMeterReading.current_Sub_Location_ID = res.working_Site_Sub_ID;
                        newMeterReading.SOCT_ID = res.socT_ID;
                        
                        this.setState({});
                    }}
                    style={newMeterReading.unit_Number ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                    disablePortal 
                    autoHighlight
                    options={unitNumbers} 
                    renderInput={(params:any):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="Unit Number"></TextField>}></Autocomplete>
                    {!newMeterReading.unit_Number && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Previous Date" disabled value={newMeterReading.previous_Date  ? splitDate(newMeterReading.previous_Date):"N/A"}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField label="Previous Reading" disabled value={newMeterReading.previous_Reading ? newMeterReading.previous_Reading:"N/A"}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Date</InputLabel>
                            <TextField style={newMeterReading.date ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle} type='date' onChange={this.handleChange} name="date"></TextField>
                            {!newMeterReading.date && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel>Current Hours</InputLabel>
                            <TextField style={newMeterReading.current_Hours ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle} onChange={this.handleChange} name="current_hours"></TextField>
                            {!newMeterReading.current_Hours && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                        </Grid>
                        {newMeterReading.equipment_Type === 'Excavators' ? 
                        <Grid item xs={6}>
                        <InputLabel>ECM</InputLabel>
                            <TextField style={{backgroundColor:'white'}} onChange={this.handleChange} name="ecm"></TextField>
                        </Grid>
                        :null}
                        <Grid item xs={6}>
                        <InputLabel>Comments</InputLabel>
                            <TextField style={{backgroundColor:'white'}} fullWidth multiline rows={3} onChange={this.handleChange} name="comments"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Meter Replacement?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={newMeterReading.meterReplacementCheck} onChange={this.handleChange} name="meter_replacement_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                        {newMeterReading.meterReplacementCheck ? 
                        <>
                             <Grid item xs={3} >
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={this.handleMeterReplacement}
                                        defaultValue={""}
                                    >
                                        <FormControlLabel value="Rollover" control={<Radio size="small"/>} label="Rollover" />
                                        <FormControlLabel value="Reset" control={<Radio size="small"/>} label="Reset" />
                                        </RadioGroup>
                                        <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                                </FormControl>
                             </Grid>
                        <Grid item xs={3}>
                        <InputLabel>Replacement Date</InputLabel>
                            <TextField style={{backgroundColor:'white'}} type="date" variant="outlined" name="replacement_date" onChange={this.handleChange}></TextField>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid> 
                        <Grid item xs={3}>
                          {this.state.replacementType==="Rollover"?<FormLabel>Meter Rollover Value</FormLabel>:<FormLabel>Meter Reset Value</FormLabel>}
                            <TextField style={{backgroundColor:'white'}} type="number" variant="outlined" name="meter_reset" onChange={this.handleChange}></TextField>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid> 
                        </>
                        :null}
                        <Grid item xs={12}>
                        <Typography gutterBottom variant="h5" style={{marginLeft:'0%',borderBottom: 'solid 1px black'}}>Location Info</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField style={{backgroundColor:'white'}} disabled label="Current Location" variant="outlined" value={newMeterReading.location}>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField style={{backgroundColor:'white'}} disabled label="Current Sub Location" variant="outlined" value={newMeterReading.current_Sub_Location}>
                            </TextField>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Do you want to update Location?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox  onChange={this.handleChange} name="update_location_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                        {newMeterReading.updateLocationCheck ?
                        <>
                        <Grid item xs={3}>
                            <Autocomplete
                            onChange={async (event:any, newValue:String | null) => {
                                newMeterReading.new_Location = newValue;
                                sub_location_list = [];
                                newMeterReading.sub_Location = '';     
                                newMeterReading.sub_Location_ID = 0;       
                                var parent_id=0;   
                                working_site_location_data.map((site:any) => {
                                    if(site.site_Name === newValue){
                                        parent_id = site.id;
                                    }
                                });
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            autoHighlight
                            style={newMeterReading.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="New Location"></TextField>}></Autocomplete>
                        <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                                 <Autocomplete
                                onChange={(event:any, newValue:String | null) =>  {
                                    newMeterReading.sub_Location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            newMeterReading.sub_Location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                autoHighlight
                                options={sub_location_list} 
                                style={newMeterReading.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                                value={newMeterReading.sub_Location}
                                renderInput={(params):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
                                <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </Grid>
                            </>
                            :null} */}
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000', left:'30%'}} onClick={this.onAddNewCancel}  variant="contained">Cancel</MUIButton>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000',  left:'35%'}} onClick={this.onAddNewSave} variant="contained">Save</MUIButton>
                            </Grid>
                    </Box>
                    </Fade>
                    </Modal>
                    :null}
                    </Paper>
                </Paper>
            </div>
            </>
          );
                      }
      }
      private filterLatestMeterAdjustment = (Objects:any): any => {
        var filterLatestMeters:any[] = [];
        Objects.forEach((currentObj:any) => {
            const { unit_Number, id } = currentObj;
            const isHigherId = Objects.some(
              (otherObj:any) =>
                otherObj.unit_Number === unit_Number && 
                otherObj.id > id
            );
            if ( unit_Number != null && (!isHigherId && !filterLatestMeters.some(obj => obj.unit_Number === unit_Number )) ) {
              filterLatestMeters.push(currentObj);
            }
        })
        return filterLatestMeters;
        }
  }
  export {editMeterReadingDataBulk};