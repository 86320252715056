import React from 'react';
import '../../../App.css';
import NavigationBar from './navbar';
import {getETUserRole } from './api/equipmenttracker/equipmentTrackerAPI';
import { userName } from '../../shared/constExports';
import { handleTokenAccess } from '../../shared/etTokenAccessWrapper';
import { ROUTE } from '../../routes';

const currentUser = userName;
var currentETUser = "";
const NEW_APP_URL = "https://apps2.sureway.ca";
interface IProps{
  history:any
}
export default class SOCTHome extends React.Component<IProps,{}>{

  handleRoute(id:string){
    switch(id){
      case 'home':
        this.props.history.push('/');
        break;
      case 'sotc':
        this.props.history.push('/soct/lists');
        break;
      case 'meters':
        this.props.history.push('/soct/meters');
        break;
      case 's_service':
        this.props.history.push('/soct/scheduledservice');
        break;
      case 'c_service':
        this.props.history.push('/soct/customservice');
        break;
      case 'sites':
        this.props.history.push('/soct/sites');
        break;
      case 'moves':
        this.props.history.push('/soct/moves');
        break;
      case 'foremans':
        this.props.history.push('/soct/foremans');
        break;
      case 'trucks':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.INDEX, "_self");
        break;
      case 'equipment_moves':
        // this.props.history.push('/field/equipmenttracker/equipmentmoves');
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST, "_self");
        break;
      case 'approveLogs':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.DRIVER_LOGS.APPROVE_LOGS, "_self");
        break;
      case 'viewLogs':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.DRIVER_LOGS.VIEW_LOGS, "_self");
        break;
      case 'drivers':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.INDEX, "_self");
        break;
      case 'trailers':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERS.INDEX, "_self");
        break;
      case 'trailer_rates':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRAILERRATES.INDEX, "_self");
        break;
      case 'billing_reports':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.BILLING.INDEX, "_self");
        break;
      case 'emails':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKINGREQUESTEMAILS.INDEX, "_self");
        break;
      case 'bulk_moves':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.BULKMOVES.INDEX, "_self");
        break;
      case 'movehistoryreport':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.MOVE_HISTORY_REPORT.INDEX, "_self");
        break;
      case 'list_view':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.LISTVIEW, "_self");
        break;
      case 'dispatch':
        this.props.history.push('/field/equipmenttracker/dispatch');
        break;
      case 'quotes':
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.QUOTES.INDEX, "_self");
        break;
    }
  }

  // handleRoute(id:string){
  //   switch(id){
  //     case 'home':
  //       this.props.history.push('/');
  //       break;
  //     case 'sotc':
  //       this.props.history.push('/soct/lists');
  //       break;
  //     case 'meters':
  //       this.props.history.push('/soct/meters');
  //       break;
  //     case 's_service':
  //       this.props.history.push('/soct/scheduledservice');
  //       break;
  //     case 'c_service':
  //       this.props.history.push('/soct/customservice');
  //       break;
  //     case 'sites':
  //       this.props.history.push('/soct/sites');
  //       break;
  //     case 'moves':
  //       this.props.history.push('/soct/moves');
  //       break;
  //     case 'foremans':
  //       this.props.history.push('/soct/foremans');
  //       break;
  //     case 'trucks':
  //       this.props.history.push('/field/equipmenttracker/trucking');
  //       break;
  //     case 'equipment_moves':
  //       this.props.history.push('/field/equipmenttracker/equipmentmoves');
  //       break;
  //     case 'drivers':
  //       this.props.history.push('/field/equipmenttracker/drivers');
  //       break;
  //     case 'trailers':
  //       this.props.history.push('/field/equipmenttracker/trailers');
  //       break;
  //     case 'trailer_rates':
  //       this.props.history.push('/field/equipmenttracker/trailerrates');
  //       break;
  //     case 'billing_reports':
  //       this.props.history.push('/field/equipmenttracker/billingreports');
  //       break;
  //     case 'emails':
  //       this.props.history.push('/field/equipmenttracker/emails');
  //       break;
  //     case 'bulk_moves':
  //       this.props.history.push('/field/equipmenttracker/bulkmoves');
  //       break;
  //     case 'movehistoryreport':
  //       this.props.history.push('/field/equipmenttracker/movehistoryreport');
  //       break;
  //     case 'list_view':
  //       this.props.history.push('/field/equipmenttracker/listview');
  //       break;
  //     case 'dispatch':
  //       this.props.history.push('/field/equipmenttracker/dispatch');
  //       break;
  //   }
  // }

  async componentDidMount(){
    var res:any = await getETUserRole(currentUser);
    if(res.isAuthenticated && !window.location.href.includes("token")){
      localStorage.setItem("ET-Role",res.role);
      currentETUser = currentUser;
      if(res.role !='RW' && res.role!="R"){
        this.props.history.push('/');
      }
    }else{
      let upn = await handleTokenAccess();
      if(upn != null){
        var etuserapp:any = await getETUserRole(upn);
        localStorage.setItem("ET-Role",etuserapp.role);
        currentETUser = upn;
      }
      else{
        this.props.history.push('/');
      }
    }
  }

  render(){
  return (
    <div className="SOCT-App">
      <div className="SOCT-App-header">
        <p>
        <NavigationBar handleRoute={this.handleRoute.bind(this)}></NavigationBar>
        </p>
      </div>
      
    </div>
  );
  }
}
export {currentUser, currentETUser};