
export enum ISAVE_CONTROL_PLAN_REQUEST {
    REQUEST = "saveControlPlan/SAVE_CONTROL_PLAN_REQUEST",
    SUCCESS = "saveControlPlan/SAVE_CONTROL_PLAN_SUCCESS",
    FAILED = "saveControlPlan/SAVE_CONTROL_PLAN_FAILED"
};

export interface ISaveControlPlanRequest {
    token: string;
    request: ISaveControlPlan;
};

export interface ISaveControlPlan {
    ID: number;
    Control_Plan: string;
    Active: string;
    Hazard_List: number[];
    Created_By: string;
    Modified_By: string;
}
