
export enum IADD_ATTACHMENT_REQUEST {
    REQUEST = "addAttachment/ADD_ATTACHMENT_REQUEST",
    SUCCESS = "addAttachment/ADD_ATTACHMENT_SUCCESS",
    FAILED = "addAttachment/ADD_ATTACHMENT_FAILED"
};

export interface IAddAttachmentRequest {
    token: string;
    request: IAddUpdateAttachment;
};

export interface IAddUpdateAttachment {
    ID?: number; //only for update
    Attachment_No: string;
    Attachment_Unit_ID: number;      
    Status: string;
    Type_ID: number;
    Coupler_Design_ID: number;
    Style_ID: number;
    Weight: number;
    Width: number;
    Length: number;
    Capacity: number;
    Make: string;
    Working_Site_Sub_ID: number | undefined;
    Serial_Number: string;
    Owner: string;
    Inspection_Date: string;
    Detachable: string;
    Notes: string;
    Photos: string;
    File_Attachments: string;
    Created_By: string;
    Modified_By: string;
    Files: IAddUpdateAttachmentListFile[],
    Images: IAddUpdateAttachmentListImage[];
    ET_Move_Pilot_Required: string;
};

export interface IAttachmentListFile {
    name: string;
    base64String: string;
};

export interface IAddUpdateAttachmentListFile {
    Name: string;
    Base64String: string;
};

export interface IAttachmentListImage {
    name: string;
    description: string;
    base64String: string;
};

export interface IAddUpdateAttachmentListImage {
    Name: string;
    Description: string;
    Base64String: string;
};