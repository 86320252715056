import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_CREWS_REQUEST, IGetCrews, IGetCrewsRequest } from "./getCrewsConstants";

export interface IGetCrewsLoadAction {
    type: IGET_CREWS_REQUEST.REQUEST;
    data: IGetCrewsRequest
}
export const getCrewsLoadAction = (data: IGetCrewsRequest): IGetCrewsLoadAction => ({
    type: IGET_CREWS_REQUEST.REQUEST,
    data
});
export interface IGetCrewsSuccessAction {
    type: IGET_CREWS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetCrews>>;
}
export const getCrewsLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetCrews>>): IGetCrewsSuccessAction => ({
    type: IGET_CREWS_REQUEST.SUCCESS,
    list
});
export interface IGetCrewsLoadFailedAction {
    type: IGET_CREWS_REQUEST.FAILED;
    message: string;
}
export const getCrewsLoadFailedAction = (message: string): IGetCrewsLoadFailedAction => ({
    type: IGET_CREWS_REQUEST.FAILED,
    message
});
