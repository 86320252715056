import { IChecklistQuestionFile } from "../getForms/getFormsConstants";

export enum IGET_FORM_RESPONSE_BY_ID_REQUEST {
    REQUEST = "getFormResponseByID/GET_FORM_RESPONSE_BY_ID_REQUEST",
    SUCCESS = "getFormResponseByID/GET_FORM_RESPONSE_BY_ID_SUCCESS",
    FAILED = "getFormResponseByID/GET_FORM_RESPONSE_BY_ID_FAILED",
};

export interface IGetFormResponseByIDRequest {
    token: string;
    request: {
        id: number,
        username: string,
    }
};

export interface IFormResponseByID {
    id: number;
    form_ID: number;
    form_Name: string;
    name: string;
    supervisor?: string;
    wsS_ID?: number;
    emp_ID?: number;
    wsS_Log_ID?: number;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    responses: IResponse[];
    tool_Box_Meeting: string;
    pictures: IFormResponsePicture[]; // For additional images
    files: IFormResponseFile[], // For additional files
    attachments: string; // For additional files
    tasks: IFormResponseByIDTask[];
    muster_Point?: string | null;
    location_Other?: string | null;
    department_ID: number;
    task_List: string[]; 
    hazard_List: IFormResponseByIDHazardList[]; 
    crew: IFormResponseByIDCrew[];
    crew_Sign_In_Upload: IFormResponsePicture;
    guests: IFormResponseByIDGuest[];
    signature: string | null;
    foreman_Signed: string | null;
    foreman_Signed_Date: string | null;
    form_Documents: string | null;
};

export interface IFormResponseByIDHazardList {
    id: number;
    hazard: string;
    severity: string;
    probability: string;
    control_Plans: string[];
}

export interface IFormResponseByIDTask {
    id: number;
    task_ID: number;
    custom_Task: string;
    flagged: string;
    comments: string;
    hazards: IFormResponseByIDHazards[];
    pictures: IFormResponsePicture[];
    images?: string;
}

export interface IFormResponseByIDHazards {
    id: number;
    hazard_ID: number;
    severity: string;
    probability: string;
    hazard_Control_IDs: string;
    custom_Hazard_Control: string;
}

export interface IFormResponseByIDGuest {
    id: number;
    form_Sheet_ID?: number;
    name: string;
    signature: string | null;
    created_By?: string;
    created?: string;
    modified_By?: string;
    modified?: string;
}

export interface IFormResponseByIDCrew {
    id: number;
    form_Sheet_ID?: number;
    emp_ID: number;
    name: string;
    signature: string | null;
    created_By?: string;
    created?: string;
    modified_By?: string;
    modified?: string;
}

export interface IResponse {
    id: number;
    form_Sheet_ID: number;
    form_Question_ID: number;
    question: string;
    display_Order: number;
    answer: string;
    required?: string;
    image_Required?: string;
    type?: string;
    options?: string;
    flagged: string;
    comments: string;
    images: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    pictures: IFormResponsePicture[];
    section?: string;
    attachments?: string;
    admin_Files?: IChecklistQuestionFile[];
    admin_Pictures?: IFormResponsePicture[];
};

export interface IFormResponsePicture {
    name: string;
    description: string;
    base64String: string | null;
};

export interface IFormResponseFile {
    name: string;
    base64String: string;
};