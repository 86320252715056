
export enum IUPDATE_SOCT_REPORT_REQUEST {
    REQUEST = "updateSoctReport/UPDATE_SOCT_REPORT_REQUEST",
    SUCCESS = "updateSoctReport/UPDATE_SOCT_REPORT_SUCCESS",
    FAILED = "updateSoctReport/UPDATE_SOCT_REPORT_FAILED"
};

export interface IUpdateSoctReportRequest {
    token: string;
    request: {
        ID: number;
        Report: string
    }
};