import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_CREW_REQUEST } from "./updateCrewConstants";
import { IUpdateCrewLoadAction, IUpdateCrewLoadFailedAction, IUpdateCrewSuccessAction, updateCrewLoadFailedAction, updateCrewLoadSuccessAction } from "./updateCrewActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateCrewEpic: Epic = (
    action$: ActionsObservable<IUpdateCrewLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateCrewSuccessAction | IUpdateCrewLoadFailedAction> =>
    action$.ofType(IUPDATE_CREW_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.UPDATE_CREW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateCrewSuccessAction | IUpdateCrewLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateCrewLoadSuccessAction(response);
                            } else {
                                return updateCrewLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateCrewLoadFailedAction(response.message)))
                    )
            )
        );