import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SOCT_LOOKUPS_REQUEST, ISOCTLookup } from "./getSOCTLookupsConstants";
import { IGetSOCTLookupsLoadAction, IGetSOCTLookupsLoadFailedAction, IGetSOCTLookupsSuccessAction, getSOCTLookupsLoadFailedAction, getSOCTLookupsLoadSuccessAction } from "./getSOCTLookupsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getSOCTLookupsEpic: Epic = (
    action$: ActionsObservable<IGetSOCTLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSOCTLookupsSuccessAction | IGetSOCTLookupsLoadFailedAction> =>
    action$.ofType(IGET_SOCT_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISOCTLookup>>(
                    END_POINTS.FIELD.SCOT.GET_SOCT_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISOCTLookup>): IGetSOCTLookupsSuccessAction => {
                            return getSOCTLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getSOCTLookupsLoadFailedAction(response.message)))
                    )
            )
        );