import { Grid, Button, Box, Typography, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, FormControl, InputLabel, InputAdornment, FilledInput } from "@mui/material";
import React from 'react';
import {updateMoveRequest, getDrivers, getTrailers, getTractors, getTrailerRates, getEquipmentMoveRequestByID, getLocations, completeMoveRequest, approveMoveRequest, rejectMoveRequest, getPilots, getPilotsByMoveID, getETUserRole, compareEditModifiedTimestamp, validateDriverToken, getForemans, getCurrentSiteForeman, getAttachmentList, removeAttachmentFromUnit} from '../../api/equipmenttracker/equipmentTrackerAPI';
import splitDate, {splitDateTime} from '../../../../shared/splitDate';
import Autocomplete from "@mui/material/Autocomplete";
import {getSOCTWorkingSubSitesByParentID, getSOCTWorkingSubSitesByID} from '../../../soct/api/workingsites/workingSitesAPI';
import {MobileBoxStyle} from '../../../../shared/styles';
import CompleteMoveRequestPopup, {complete_move_request_email_check, complete_move_request_approved_check, complete_move_request_rejected_check, rejection_comments} from '../../../../shared/completeMoveRequestPopup';
import '../../../../shared/soctStyles/soctStyles.css';
import LADatePicker from "../../../../shared/datePicker";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { LAIconButton } from "../../../../shared/buttons";
import { AddIcon, DeleteIcon } from "../../../../shared/icons";
import { LAPaperWithLessPadding } from "../../../../shared/paper";
import { getTokenFromUrl, token, undefinedFunction } from "../../../../shared/constExports";
import LAAutoComplete from "../../../../shared/autoComplete";
import { currentUser } from "../../equipmenttrackerhome";
import { handleTokenAccess } from "../../../../shared/etTokenAccessWrapper";
import { MoveRequestVersionHistory } from "./moveRequestVersionHistory";
import LALinkButton from "../../../../shared/linkButton";
import LATextField from "../../../../shared/textField";
import { ROUTE } from "../../../../routes";
import { DevExtremePopupLookup } from "../../../../shared/devExtremePopupLookup";

interface IProps{
    locationOptions:any
    closeEquipmentMoveForm:any;
    clickedItem:any;
    clickedLocation:any;
    refreshGrid:any;
    history:any;
}
interface IState{
  approveCheck: boolean | undefined;
    location_options:any[];
    loaded:boolean;
    showCompleteMovePopup:boolean;
    approvalButtonClick:boolean;
    pilotArray:any;
    originalPilotArray: any;
    showVersionHistory:boolean;
    hasHistory:boolean;
    isDriver:boolean;
    attachment_list: any[];
  }

  var topDisplayReadOnly:string = '20%';
  var DesktopBoxStyle:any = {
    position: 'absolute' as 'absolute',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: '96%',
    top:window.location.href.includes("driver") || window.location.href.includes("token")?topDisplayReadOnly:'',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
  }
  var move_request:any = {id:0,unit_number:'', from_location:'', to_location:'', completed_date:'', modified_notes:'', send_email:false, moved_item:{}, pickup_contact_name: '', pickup_contact_upn:'', dropoff_contact_name: '', dropoff_contact_upn:'',
company:'', driver:'', driver_id:0, trailer_config:'',trailer_config_id:0, trailer_id:0, pilot:'', tri_drive:false, hours:0, city_permit:'', city_permit_cost:0, gov_permit:'', gov_permit_cost:0,
completed:false, f_a:'', commodity:'', cancelled:false, equipment_ID:0, assigned_unit_number:'', drive_over:false, assigned_tractor_unit:'', assigned_tractor_id:0, to_location_id:0,
to_sub_location_id:0,to_sub_location:'',from_sub_location_id:0, is_from_app:false, permit:false, undo_move:false, from_location_latitude:'', from_location_longitude:'',to_location_latitude:'',to_location_longitude:'', attachment_list: []};
var driver_options:any[] = [];
var trailer_config_options:any[]= [];
var disableForm:boolean = false;
var temp_drivers:any[] = [];
var temp_trailers:any[] = [];
var temp_trailer_rates:any[] = [];
var assigned_unit_number_options:any[] = [];
var assigned_tractor_options:any[] = [];
var temp_tractors:any[] = [];
var preview_data:any;
var working_site_locations:any[] = [];
var to_sub_site_options:any[] = [];
var to_sub_site_details:any[] = [];
var locations:any[] = [];
var foremans: any[] = [];
var grid_xs_width:number = 3;
var radio_button_filter:string = "";
var hideRequestUserAccess:boolean = false;
var titleText = "";
var move_complete_id:Number=0;
var pilot_driver_options:any[] = [];
var pilot_truck_options:any[] = [];
var currentETEditUser:any;
var move_version_id:number = 0;
var hasEditBeenMade:any = false;
var byPassOnSaveModifiedCheck:boolean = false;
var attachmentList: any[] = [];
var attachemnts: any[] = [];
var getAllAttachmentList:any[] = [];
var errors: any = [{ "attachment_No": '' }];

export default class EquipmentMoveEdit extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], loaded:false,showCompleteMovePopup:false, approvalButtonClick:false,
          pilotArray:[], showVersionHistory:false, hasHistory:false, isDriver:false, originalPilotArray: [],
          approveCheck: undefined, attachment_list: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.validateCompletedMove = this.validateCompletedMove.bind(this);
        this.validateTrailerConfig = this.validateTrailerConfig.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openConfirmMovePopup = this.openConfirmMovePopup.bind(this);
        this.closeCompleteMovePopup = this.closeCompleteMovePopup.bind(this);
        this.confirmMovePopup = this.confirmMovePopup.bind(this);
        this.verifyCompletedDateInPast = this.verifyCompletedDateInPast.bind(this);
        this.verifySixtyDays = this.verifySixtyDays.bind(this);
        this.handleRejectApprove = this.handleRejectApprove.bind(this);
        this.handlePilotTableChange = this.handlePilotTableChange.bind(this);
        this.validatePilotInfo = this.validatePilotInfo.bind(this);
        this.onPilotAdd = this.onPilotAdd.bind(this);
        this.onPilotDelete = this.onPilotDelete.bind(this);
        this.closeVersionHistory = this.closeVersionHistory.bind(this);
        this.openVersionHistory = this.openVersionHistory.bind(this);
        this.onStatusClick = this.onStatusClick.bind(this);
        this.onAttachmentAdd = this.onAttachmentAdd.bind(this);
        this.handleAttachmentTableChange = this.handleAttachmentTableChange.bind(this);
        this.onAttachmentDelete = this.onAttachmentDelete.bind(this);
    }
    openVersionHistory():void {
      move_version_id = move_request.id;
      this.setState({showVersionHistory:true});
    }
    closeVersionHistory(){
      this.setState({showVersionHistory:false});
    }
    onStatusClick(value?: string){
      this.setState({ approveCheck: value === "Rejected" ? false : value === "Approved" ? true : undefined });
    }
    onPilotAdd(){
      const list = [...this.state.pilotArray.pilotArray];
      move_request.pilot = Number(move_request.pilot) + 1;
      list.push({
          id: 0,
          pilot_driver:{value:null},
          pilot_truck:{value:null},
          hours:0,
          deleted:"No"
      });

      this.setState({
          ...this.state,
          pilotArray: {
              ...this.state.pilotArray,
              pilotArray: list
          }
      });
    }
    onPilotDelete(index:any | undefined){
      const list = [...this.state.pilotArray.pilotArray];
      move_request.pilot = Number(move_request.pilot) - 1;

      list[index].deleted = "Yes";
     
    this.setState({
        ...this.state,
        pilotArray: {
            ...this.state.pilotArray,
            pilotArray: list
        }
    });
    }
    validatePilotInfo(){
      let isVerified = true;
      if(move_request.pilot<=0){
        return true;
      }
      else{
        this.state.pilotArray.pilotArray.map((elem:any) => {
          if(elem.pilot_driver.value > 0 && elem.pilot_truck.value > 0 && elem.hours > 0){
            return true;
          }
          else{
            isVerified = false;
          }
        });
        return isVerified;
      }
    }
    handlePilotTableChange(event:any,value:any, index?:number,name?:any| undefined){
        if (index !== undefined) {
          hasEditBeenMade = false;
            const iS = this.state.pilotArray.pilotArray;
            let record = { ...iS[index] };
            // If hours entered are a character set to 0

            if(name === "hours" && !(Number(value) > 0) ){
              value = null;
            }
            // If hours entered convert to a number
            if(name === "hours"){
              value = Number(value);
            }
            // If autcomplete delete set value to empty string
            if(value === null){
              value={value:null};
            }
            record = { ...record, [name]: value };
            iS[index] = record;
            this.setState({
                ...this.state,
                pilotArray: {
                    ...this.state.pilotArray,
                    pilotArray: iS
                }
            });
        }
    }
    handleRejectApprove(){
      titleText="Take action on this request";
      move_complete_id = Number(move_request.id);
      this.setState({showCompleteMovePopup:true, approvalButtonClick:true});
    }
    verifySixtyDays(completed_date:string, move_status:string){
      // replace - with / for completed date
    let record_completed_date = new Date(splitDate(completed_date).replace(/-/g,'/'));
    let isRecordPast15 = false;
    // create a current date
    let current_date_offset = new Date();
    // calculate 15 days in past date
    current_date_offset.setDate(current_date_offset.getDate()-62);
    // use yesterdays date and get only date without timestamp
    current_date_offset = new Date(current_date_offset.getFullYear(),current_date_offset.getMonth(),current_date_offset.getDate());
    // get only date for completed date without timestamp
    record_completed_date = new Date(record_completed_date.getFullYear(),record_completed_date.getMonth(),record_completed_date.getDate());
    // if completed date is in the past besides yesterday make moved_previously mandatory
    if((record_completed_date < current_date_offset) && move_status==="Completed"){
      isRecordPast15 = true;
    }
    return isRecordPast15;
    }
    verifyCompletedDateInPast(){
    let isCompletedDateInPast = false;
    // create a current date
    let current_date_offset = new Date();
    // calculate yesterdays date
    current_date_offset.setDate(current_date_offset.getDate()-1);
    // use yesterdays date and get only date without timestamp
    current_date_offset = new Date(current_date_offset.getFullYear(),current_date_offset.getMonth(),current_date_offset.getDate());
    // replace - with / for completed date
    let move_req_date = new Date(move_request.completed_date.replace(/-/g,'/'));
    // get only date for completed date without timestamp
    move_req_date = new Date(move_req_date.getFullYear(),move_req_date.getMonth(),move_req_date.getDate());
    // if completed date is in the past besides yesterday make moved_previously mandatory
    if((move_req_date < current_date_offset) && !move_request.moved_previously){
        isCompletedDateInPast = true;
    }
    return isCompletedDateInPast;
  }  

  async confirmMovePopup(){
    byPassOnSaveModifiedCheck = true;
    this.setState({});
    if(move_request.id != 0){
      if(complete_move_request_approved_check){
        var res:any = await approveMoveRequest(move_complete_id, complete_move_request_email_check);
        if(res.status === 200){
          this.onSave();
        }
      }
      if(complete_move_request_rejected_check){
        var res:any = await rejectMoveRequest(move_complete_id, complete_move_request_email_check, rejection_comments);
        if(res.status === 200){
          this.onSave();
        }
      }
      if(!complete_move_request_approved_check && !complete_move_request_rejected_check){
        var res:any = await completeMoveRequest(move_request.id, complete_move_request_email_check);
        if(res.status === 200){
          this.onSave();
        }
      }
    }
    else{
      alert("Error completing move. Refresh page");
    }
    this.setState({showCompleteMovePopup:false});
  }
    closeCompleteMovePopup(){
      this.setState({showCompleteMovePopup:false, approvalButtonClick:false});
    }
    openConfirmMovePopup(){
      titleText="Complete Move?";
      this.setState({showCompleteMovePopup:true});
    }
    validateCompletedMove(){
      return (move_request.hours > 0 && move_request.trailer_id > 0 && move_request.drive_over===false) || move_request.drive_over===true;
    }
    validateTrailerConfig(){
      if(move_request.trailer_config != "" && move_request.trailer_config != null){
        return move_request.assigned_unit_number!="" && move_request.assigned_unit_number != null;
      }
      if(move_request.assigned_unit_number != "" && move_request.assigned_unit_number!=null){
        return move_request.trailer_config != "" && move_request.trailer_config != null;
      }
      return true;
    }
    closePopup(){
      if(radio_button_filter!="" ){
        this.props.history.push('/field/equipmenttracker/equipmentmoves'+"?"+radio_button_filter);
      }
      else{
        if(window.location.href.includes("?token") && !this.state.isDriver){
          if(!this.state.hasHistory){
            //console.log(window.parent);
            //if(window.parent.postMessage("goback") === undefined){
              window.parent.postMessage("goback");
              var tokenfromurl:any = getTokenFromUrl(token);
              if(tokenfromurl.includes("hasHistory")){
                tokenfromurl = tokenfromurl.split("&")[0];
               }
              this.props.history.push('/field/equipmenttracker/equipmentmoves'+"?token="+tokenfromurl);
            // }
            // else{
            //   window.parent.postMessage("goback");
            // }
          }
          else{
            this.props.history.goBack();
          }         
        }
        else{
          if(this.state.isDriver){
            this.props.history.push("/closewindow");
          }
          else{
          if(!this.state.hasHistory){
            this.props.history.push('/field/equipmenttracker/equipmentmoves');
          }
          else{
            this.props.history.goBack();
          }
        }
        }
      }
      //this.props.closeEquipmentMoveForm();
    }

    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'completed_date':
                move_request.completed_date = event.target.value;
                break;
            case 'comments':
                move_request.modified_notes = event.target.value;
                break;
            case 'reject_Comments':
                move_request.reject_Comments = event.target.value;
                break;
            case 'send_email':
                move_request.send_email = !move_request.send_email;
                break;
            case 'pilot':
              hasEditBeenMade = true;
              let originalPilotArray: any = this.state.originalPilotArray;

              if((move_request.pilot <= event.target.value) && (event.target.value !== "")){
                originalPilotArray = this.state.pilotArray.pilotArray;
              }

              move_request.pilot = event.target.value;
             let temp_array:any[] = [];

             if(move_request.pilot <= 10){
              for(var ind=0;ind< move_request.pilot; ind++){
                let toPush = {pilot_driver:{value:0,name:""}, pilot_truck:{value:0,name:""}, hours:0, id:0, deleted:"No"};

                if(originalPilotArray.length > 0 && originalPilotArray[ind] && (event.target.value !== "")){
                  toPush = originalPilotArray[ind]
                }

                temp_array.push(toPush);
              }

                this.setState(state => (
                  {
                    pilotArray:
                  {
                      ...state.pilotArray,
                      pilotArray:temp_array
                  },
                  originalPilotArray
              }
              
              ));
            }
            else{
              alert("Too many pilots");
            }
              break;
            case 'tri_drive':
              move_request.tri_drive = !move_request.tri_drive;
              break;
            case 'hours':
              move_request.hours = event.target.value;
              break;
            case 'city_permit':
              move_request.city_permit = event.target.value;
              break;
            case 'city_permit_cost':
              move_request.city_permit_cost = event.target.value;
              break;
            case 'gov_permit':
              move_request.gov_permit = event.target.value;
              break;
            case 'gov_permit_cost':
              move_request.gov_permit_cost = event.target.value;
              break;
            case 'completed':
              move_request.completed = !move_request.completed;
              break;
            case 'f_a':
              move_request.f_a = event.target.value;
              break;
            case 'commodity':
              move_request.commodity = event.target.value;
              break;
            case 'cancelled':
              move_request.cancelled = !move_request.cancelled;
              break;
            case 'drive_over':
              move_request.drive_over = !move_request.drive_over;
              break;
            case 'request_details':
              move_request.comments = event.target.value;
              break;
            case 'permit':
              move_request.permit = !move_request.permit;
              break;
            case 'Undo Move':
              move_request.undo_move = !move_request.undo_move;
              break;
        }
        this.setState({});
    }
    async onSave(){
        let isValidCompleted = true;
        if(move_request.driver != null){
          let driver = move_request.driver.split("(")[0];
          temp_drivers.map((elem:any) => {
            let drivers = elem.driver_Firstname + " " + elem.driver_Lastname;
            if(drivers.trim() === driver.trim()){
              move_request.driver_id = elem.id;
            }
          })
        }
        else{
          if(!hideRequestUserAccess){
            move_request.driver_id = null;
          }
        }
        if(move_request.completed){
          if(!this.validateCompletedMove()){
            isValidCompleted = false;
          }
        }
        let rejectCheck = true;
        let statusCheck = true;
        let commentsCheck = true;

        if(move_request.status === "Requested"){
          if(this.state.approveCheck === undefined){
            statusCheck = false;
          }

          if(this.state.approveCheck === false){
            if(move_request && 
              (((move_request.reject_Comments) && (move_request.reject_Comments.length === 0))
              || (move_request.reject_Comments === undefined) || (move_request.reject_Comments === null)
              )){
              rejectCheck = false;
            }
          };
        };

        if(move_request.undo_move) {
          move_request.status = "Undo Move";

          if(move_request.modified_notes === "" || move_request.modified_notes==null) {
            commentsCheck = false;
          }
        }

        if(isValidCompleted && rejectCheck && commentsCheck){
          if(move_request.to_location!="" && move_request.to_location!=null && move_request.to_sub_location!="" && move_request.to_sub_location!=null ){
            if(this.validateTrailerConfig()){
          
            var formLastEdited = await compareEditModifiedTimestamp(move_request.id, preview_data.modified);
            let isFormLastEdited = formLastEdited.response.isOlder;
            // bypass modified check if user moved/approved/rejected the move directly from the edit form
            if(byPassOnSaveModifiedCheck){
              isFormLastEdited = false;
            }

            if(preview_data.status === "Requested" && this.state.approveCheck && move_request){
                move_request.status = "Approved";
            };

            if(preview_data.status === "Requested" && (this.state.approveCheck === false) && move_request){
                move_request.status = "Rejected";
            };

              if(!isFormLastEdited){
           //     if(this.validatePilotInfo()){
                const invalidItems = this.state.attachment_list.filter(item => (
                  item.type === "" || item.coupler_Design === "" || item.style === ""
                ));
                // console.log(invalidItems)
            
                if (invalidItems.length > 0) {
                  alert("Please fill Type, Coupler Design and Style fields in attachment list table.");
                } else {
                    move_request.attachment_list = this.state.attachment_list
                    .filter(
                      (att) =>
                        att.id !== undefined &&
                        att.type !== "" &&
                        att.coupler_Design !== "" &&
                        att.style !== ""
                    )
                    .map((attachment) => ({
                      ID: attachment.id,
                      Attachment_ID: attachment.attachment_ID,
                      Coupler_Design_ID: attachment.coupler_Design_ID,
                      Type_ID: attachment.type_ID,
                      Style_ID: attachment.style_ID,
                      From_Sub_Location_ID: attachment.from_Sub_Location_ID,
                      To_Sub_Location_ID: attachment.to_Sub_Location_ID ? attachment.to_Sub_Location_ID : move_request.to_sub_location_id,
                    }));
                  // console.log(move_request)
                  var res:any = await updateMoveRequest(move_request, this.state.pilotArray.pilotArray);
                  if(res.status == 200){
                      if(window.location.href.includes("?token") && !this.state.isDriver){
                        if(!this.state.hasHistory){
                          // console.log(window.parent)
                          // if(window.parent.postMessage("goback") === undefined){
                            window.parent.postMessage("goback");
                            var tokenfromurl:any = getTokenFromUrl(token);
                            if(tokenfromurl.includes("hasHistory")){
                              tokenfromurl = tokenfromurl.split("&")[0];
                            }
                            this.props.history.push('/field/equipmenttracker/equipmentmoves'+"?token="+tokenfromurl);    
                          // }
                          // else{
                        //  }
                        }
                        else{  
                          this.props.history.goBack(); 
                        }         
                      }
                      else{
                        if(radio_button_filter!=""){
                          this.props.history.push('/field/equipmenttracker/equipmentmoves'+"?"+radio_button_filter);
                        }
                        else{
                          if(this.state.isDriver){
                            this.props.history.push("/closewindow");
                          }
                          else{
                            if(!this.state.hasHistory){
                              this.props.history.push('/field/equipmenttracker/equipmentmoves');
                            }
                            else{
                              this.props.history.goBack(); 
                            }
                          }
                        }
                        //this.props.refreshGrid();
                      }
                  }
                  else{
                    alert("Error updating move request");
                  }
                }
              // }
              // else{
              //   alert("Pilot info must be entered");
              // }
          }
          else{
            alert("Form has been edited by another user, the page will be refreshed to fetch the changes from another user");
            window.location.reload();
          }
        }
          else{
            alert("Trailer information requires trailer config and trailer unit number");
          }
          }
          else{
            alert("To location and sub location must be entered");
          }
        }
        else if(!isValidCompleted){
          alert("Completed moves must have hours and trailer entered");
        } else if(rejectCheck === false){
          alert("Reject Comments can not be empty.");
        } else if(statusCheck === false){
          alert("Please Approval/Rejection Status selection is required");
        } else if(commentsCheck === false){ 
          alert("Comments can not be empty.");
        }
    }
    async componentDidMount(){
    var res:any = await getETUserRole(currentUser);
    if(res.isAuthenticated && !window.location.href.includes("token") && !window.location.href.includes("driver")){
      localStorage.setItem("ET-Role",res.role);
      currentETEditUser = currentUser;
      if(res.role !='RW' && res.role!="R"){
        this.props.history.push('/');
      }
      // Token or driver url
    }else{
      // Driver
      if(window.location.href.includes("driver=yes")){
        const url = window.location.search;
        if (url.length > 0 && url.indexOf("driver") > 0) {
          const getToken = url.split("=")[1].split("&driver")[0];
          if(getToken != null){
            let response:any = await validateDriverToken(getToken.toString());
              if(response.isTokenValid){
                let driver = response.driverObject;
                let driverUPN = "Driver: " + driver.driver_Firstname + "." + driver.driver_Lastname;
                currentETEditUser = driverUPN;
                // localStorage.setItem("ET-Role","R");
                this.setState({isDriver:true});
              }
              else{
                this.props.history.push("/");
              }
          }
          
        }
      }
      // App user
      else{
        let upn = await handleTokenAccess();
        if(upn != null){
          var etuserapp:any = await getETUserRole(upn);
          localStorage.setItem("ET-Role",etuserapp.role);
          currentETEditUser = upn;
        }
        else{
          this.props.history.push('/');
        }
      }
    }
      move_request = {};
      editData = {};
      let edit_id = 0;
      if(window.innerWidth <=1056){
        grid_xs_width = 9;
      }
      else{
        grid_xs_width = 2;
      }
      if(localStorage.getItem("ET-Role") === "R" && !this.state.isDriver){
        move_request.is_from_app = true;
        hideRequestUserAccess = true;
      }
      // if href includes ? and no token save the radio button filter
      if(window.location.href.includes("?") && !window.location.href.includes("token") && !window.location.href.includes("driver")){
        radio_button_filter = "";
        radio_button_filter = window.location.href.split("?")[1].split("&")[0];
      }
      // get edit id and hide access if user is from app
      if(window.location.href.includes("token")){
        edit_id = Number(window.location.pathname.split("id=")[1].split("?token")[0]);
        if(!!this.state.isDriver){
        move_request.is_from_app = true;
        }
        hideRequestUserAccess = true;
      }
      // get edit id if user is from desktop
      else{
        if(window.location.pathname.includes("hasHistory")){
          edit_id = Number(window.location.pathname.split("=")[1].split("&")[0]);
        }
        else{

          edit_id = Number(window.location.pathname.split("=")[1]);
        }
      }
      foremans = await getForemans();
        var editData:any = await getEquipmentMoveRequestByID(edit_id);
        console.log(editData);
        move_request.to_location = editData.to_Location;
        move_request.from_location = editData.from_Location;
        move_request.from_location_latitude = editData.from_Location_Gps_Latitude;
        move_request.from_location_longitude = editData.from_Location_Gps_Longitude;
        move_request.to_location_latitude = editData.to_Location_Gps_Latitude;
        move_request.to_location_longitude = editData.to_Location_Gps_Longitute;
        move_request.id = editData.id;
        move_request.completed_date = editData.completed_Date;
        move_request.unit_Number = editData.unit_Number;
        move_request.trailer_config = editData.trailer_Config;
        move_request.trailer_config_id = editData.trailer_Config_ID;
        move_request.pilot = editData.pilot;
        move_request.tri_drive = editData.tri_Drive === "Yes";
        move_request.hours = editData.hours;
        move_request.city_permit = editData.city_Permit;
        move_request.city_permit_cost = editData.city_Permit_Cost;
        move_request.gov_permit = editData.gov_Permit;
        move_request.gov_permit_cost = editData.gov_Permit_Cost;
        move_request.completed = editData.status === "Completed";
        move_request.f_a = editData.f_A;
        move_request.commodity = editData.commodity;
        move_request.modified_notes = editData.modified_Notes;
        move_request.cancelled = editData.cancelled === "Yes";
        move_request.equipment_ID = editData.equipment_ID;
        move_request.drive_over = editData.drive_Over === "Yes";
        move_request.to_location_id = editData.to_Location_ID;
        move_request.to_sub_location_id = editData.to_Sub_Location_ID;
        move_request.from_sub_location_id = editData.from_Sub_Location_ID;
        move_request.comments = editData.comments;
        disableForm = (editData.status === "Cancelled") || this.verifySixtyDays(editData.completed_Date, editData.status);
        move_request.trailer_id = editData.trailer_ID;
        move_request.driver_id = editData.driver_ID;
        move_request.moved_previously = editData.moved_Previously === "Yes";
        move_request.status = editData.status;
        move_request.send_email = false;
        move_request.permit = editData.permit === "Yes";
        move_request.pickup_contact_name = editData.pickup_Contact_Display;
        let findPickupRecord = foremans.find(e=>e.display_Name===move_request.pickup_contact_name) ;
        if(findPickupRecord){
          move_request.pickup_contact_upn = findPickupRecord.upn
        }
        move_request.dropoff_contact_name = editData.dropOff_Contact_Display;
        let findDropoffRecord = foremans.find(e=>e.display_Name===move_request.dropoff_contact_name) ;
        if(findDropoffRecord){
          move_request.dropoff_contact_upn = findDropoffRecord.upn
        }
        attachmentList = editData.attachments ? editData.attachments : [];
        this.setState({attachment_list : editData.attachments ? editData.attachments : []});
        // console.log(this.state.attachment_list);
        getAllAttachmentList = await getAttachmentList();
        attachemnts = Object.values(getAllAttachmentList).filter(item => item.move_Status === null || item.move_Status === 'completed');
        // console.log(attachemnts);
        if(!hideRequestUserAccess){
        temp_drivers = await getDrivers();
        let drivers:any = [];
        temp_drivers.map((driver:any) => {
          if(driver.active==='Yes' && driver.truck_Driver === "Yes"){
            if(driver.assigned_Unit_Number != null && driver.assigned_Unit_Number != ''){
              var driver_name = driver.driver_Firstname + " " + driver.driver_Lastname 
              // + " " + "("+driver.assigned_Unit_Number+")";
              if(driver.id === editData.driver_ID){
                move_request.driver = driver_name;
              }
            }
            else{
              var driver_name = driver.driver_Firstname + " " + driver.driver_Lastname
              //  + " " + "(Not assigned)";
              if(driver.id === editData.driver_ID){
                move_request.driver = driver_name;
              }
            }
            drivers.push(driver_name);
            if(driver.pilot_Driver === "Yes"){
              pilot_driver_options.push({"value":driver.id, "name":driver_name});
            }
          }
        });
        driver_options = drivers;
        temp_trailer_rates = await getTrailerRates();
        temp_trailers = await getTrailers();
        let trailers:any = [];
        temp_trailers.map((trailer:any) => {
          temp_trailer_rates.map((trailer_rate:any) => {
          if(trailer.active==='Yes' && trailer.trailer_Rate_ID == trailer_rate.id){
            var trailer_name = trailer_rate.description;
            if(trailer.id === editData.trailer_ID){
              move_request.trailer_config = trailer_name;
              move_request.assigned_unit_number = trailer.unit_Number;
            }
            if( trailers.indexOf(trailer_name)===-1){
              trailers.push(trailer_name)
            }
          }
        });
      });
        trailer_config_options = trailers;

        temp_tractors = await getTractors();
        let tractors:any = [];
        temp_tractors.map((tractor:any) => {
          var tractor_unit = tractor.unit_Number;
          if(tractor.id === editData.assigned_Tractor_ID){
            move_request.assigned_tractor_unit = tractor_unit;
          }
          tractors.push(tractor_unit);
        });
        assigned_tractor_options = tractors;
   //   }
      }
      locations = [];
      locations = await getLocations();
      working_site_locations = [];
      let temp_loc:any[] = [];
      locations.map((location:any) => {
        let location_name = location.site_Name + ", Job #: " + location.job_No;
        working_site_locations.push(location_name);
        temp_loc.push(location);
        });
        working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
        locations = temp_loc;
        var res:any = await getSOCTWorkingSubSitesByID(editData.to_Sub_Location_ID);
        move_request.to_sub_location = res.working_Sub_Site_Name;
        
        var sub_site_info:any = await getSOCTWorkingSubSitesByParentID(move_request.to_location_id,true);
        to_sub_site_options = (sub_site_info && sub_site_info.length > 0) ? sub_site_info.map((sub_location:any)=> {return sub_location.sub_Site_Name}) : to_sub_site_options;
        
        preview_data = editData;
      let pilot_trucks = await getPilots();
      pilot_trucks.map((elem:any) => {
        pilot_truck_options.push({"value":elem.id, "name":elem.unit_Number});
      });
      if(!hideRequestUserAccess){

      var edit_pilots_array = await getPilotsByMoveID(editData.id);
      let pilotArray = {pilotArray:edit_pilots_array.response};
      if(pilotArray.pilotArray.length > 0){
         this.setState(state => ({pilotArray:
          {
              ...state.pilotArray,
              pilotArray:pilotArray.pilotArray,
          },
      }));
      }
      else{
        let toPush = {pilot_driver:{value:0,name:""}, pilot_truck:{value:0,name:""}, hours:0, id:0, deleted:"No"};
        let temp_array:any[] = [];
         for(var ind=0;ind< move_request.pilot; ind++){
           temp_array.push(toPush);
         }
           this.setState(state => ({pilotArray:
             {
                 ...state.pilotArray,
                 pilotArray:temp_array,
             },
         }));
      }
    }
      this.setState({loaded:true,hasHistory:window.location.href.includes("hasHistory")});
    }

    handleGPSClick(latitude:any, longitude:any)  {
      let mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude} `;
      window.open(mapUrl, "_blank");
    } 

    handleGPSHistory(){
      let url = `${ROUTE.FIELD.GPS_RECORD}?id=${preview_data.id}&page=ET`;
      window.open(url, "_blank");
    }

    handleAttachmentTableChange(value:any, index?:number,name?:any| undefined){
      if (index !== undefined) {
          
          const iS = [...this.state.attachment_list];
          // console.log(iS)
          if(name === "attachment_No"){
              if(value) {
                  const val = attachemnts.find(attachment => attachment.attachment_No === value)
                  // console.log(val)
                  let record = { ...iS[index] };
                  // console.log(record)
                  record = {
                      id: 0,
                      attachment_ID: val.id,
                      attachment_No: val.attachment_No,
                      type:val.type,
                      type_ID: val.type_ID,
                      coupler_Design:val.coupler_Design,
                      coupler_Design_ID: val.coupler_Design_ID,
                      style:val.style,
                      style_ID: val.style_ID,
                      from_Sub_Location: val.location,
                      from_Sub_Location_ID: val.working_Site_Sub_ID,
                      to_Sub_Location: move_request.to_sub_location ? move_request.to_sub_location : '',
                      to_Sub_Location_ID: move_request.to_sub_location_id?move_request.to_sub_location_id: '',
                      attached_Unit_No: val.attached_Unit_No,
                      move_Status: val.move_Status
                  };
                  iS[index] = record;
                  console.log(iS)
              } else if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                      id: 0,
                      attachment_ID: 0,
                      attachment_No: '',
                      type:'',
                      type_ID: '',
                      coupler_Design:'',
                      coupler_Design_ID: '',
                      style:'',
                      style_ID: '',
                      from_Sub_Location: '',
                      from_Sub_Location_ID: 0,
                      to_Sub_Location: '',
                      to_Sub_Location_ID:  0,
                      attached_Unit_No: '',
                      move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              
          }

          if(name==='type') {
              if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                      id: 0,
                      attachment_ID: 0,
                      attachment_No: '',
                      type:'',
                      type_ID: '',
                      coupler_Design:'',
                      coupler_Design_ID: '',
                      style:'',
                      style_ID: '',
                      from_Sub_Location: '',
                      from_Sub_Location_ID: 0,
                      to_Sub_Location: '',
                      to_Sub_Location_ID:  0,
                      attached_Unit_No: '',
                      move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              let record = { ...iS[index] };
              record.type = value.type;
              record.type_ID = value.type_ID;
              iS[index] = record;
              // console.log(iS)
          }

          if(name==='coupler_Design') {
              if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                      id: 0,
                      attachment_ID: 0,
                      attachment_No: '',
                      type:'',
                      type_ID: '',
                      coupler_Design:'',
                      coupler_Design_ID: '',
                      style:'',
                      style_ID: '',
                      from_Sub_Location: '',
                      from_Sub_Location_ID: 0,
                      to_Sub_Location: '',
                      to_Sub_Location_ID:  0,
                      attached_Unit_No: '',
                      move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              // const iS = this.state.attachment_list;
              let record = { ...iS[index] };
              record.coupler_Design = value.coupler_Design;
              record.coupler_Design_ID = value.coupler_Design_ID;
              iS[index] = record;
              // console.log(iS)
          }

          if(name==='style') {
              if (value === '' || value === null || value === undefined){
                  let record = { ...iS[index]}
                  record = {
                    id: 0,
                    attachment_ID: 0,
                    attachment_No: '',
                    type:'',
                    type_ID: '',
                    coupler_Design:'',
                    coupler_Design_ID: '',
                    style:'',
                    style_ID: '',
                    from_Sub_Location: '',
                    from_Sub_Location_ID: 0,
                    to_Sub_Location: '',
                    to_Sub_Location_ID:  0,
                    attached_Unit_No: '',
                    move_Status:''
                  }
                  iS[index] = record;
                  // console.log(iS)
              }
              // const iS = this.state.attachment_list;
              let record = { ...iS[index] };
              record.style = value.style;
              record.style_ID = value.style_ID;
              iS[index] = record;
              // console.log(iS)
          }
          // If autcomplete delete set value to empty string
          if(value === null){
            value={value:null};
          }

          this.setState({
              ...this.state,
              attachment_list:iS
          });
          // console.log(this.state.attachment_list)
      }
  }

  onAttachmentAdd() {
      const list = [...this.state.attachment_list];
      list.push({
        id: 0,
        attachment_ID: 0,
        attachment_No: "",
        type: "",
        type_ID: "",
        coupler_Design: "",
        coupler_Design_ID: "",
        style: "",
        style_ID: "",
        from_Sub_Location: "",
        from_Sub_Location_ID: 0,
        to_Sub_Location: "",
        to_Sub_Location_ID: 0,
        attached_Unit_No: "",
        move_Status: "",
      });
      this.setState({
          ...this.state,
          attachment_list: list
      });
      // console.log(this.state.attachment_list)
  }

  async onAttachmentDelete(index: number, data:any) {
      // console.log(index, data)
      // console.log(this.state.attachment_list)
      if(data.attached_Unit_No === "" || data.attached_Unit_No === null) {
        this.state.attachment_list.splice(index, 1);
        getAllAttachmentList = await getAttachmentList();
        attachemnts = Object.values(getAllAttachmentList).filter(item => item.move_Status === null || item.move_Status === 'completed');
        // console.log(attachemnts)
        this.setState({
          attachment_list: [...this.state.attachment_list]
        });
        // console.log(this.state.attachment_list) 
      } 
      else {
        if(data.attachment_ID > 0) {
          const shouldDelete = window.confirm('This will remove the association between the unit and attachment. Do you want to proceed?');
          if(shouldDelete) {
            // console.log(data.attachment_ID);
              // console.log(index, data)
  
              const result = await removeAttachmentFromUnit(data.attachment_ID);
              // console.log(result);
              if(result.message === 'Success') {
                  this.state.attachment_list.splice(index, 1);
                  getAllAttachmentList = await getAttachmentList();
                  attachemnts = Object.values(getAllAttachmentList).filter(item => item.move_Status === null || item.move_Status === 'completed');
                  // console.log(attachemnts)
                  this.setState({
                    attachment_list: [...this.state.attachment_list]
                  });
                  // console.log(this.state.attachment_list)
                  const indexToRemove = move_request.attachment_list.findIndex((item:any) => item.attachment_No === data.attachment_No);
                  if (indexToRemove !== -1) {
                      move_request.attachment_list.splice(indexToRemove, 1);
                      // console.log(move_request.attachment_list)
                  } 
              }
          }
       }
      } 
      
      this.setState({
          ...this.state,
          attachment_list: [...this.state.attachment_list]
      });
  }

    render(){
      if(this.state.loaded){
        return (
          <Box sx={window.innerWidth <= 1056 ? MobileBoxStyle:DesktopBoxStyle}>
                    <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Button sx={ExitButtonStyle} onClick={this.closePopup}>Close</Button>
                    </Grid>
                    {!hideRequestUserAccess || this.state.isDriver && (preview_data.status==="Initiated" || preview_data.status==="Dispatched" || preview_data.status==="En Route" )? 
                    <Grid item xs={3}>
                      <Button className={'reject-btn'} disabled={(preview_data.status!="Initiated" && preview_data.status!="Dispatched" && preview_data.status!="En Route") || disableForm} onClick={this.openConfirmMovePopup}>Move</Button>
                    </Grid>
                    :null}
                    <Grid item xs={3}>
                      <LALinkButton onClick={this.openVersionHistory} label="Version History"></LALinkButton>
                    </Grid>

                    <LALinkButton 
                          label="GPS Tracking"
                          onClick={this.handleGPSHistory}
                        />

                    </Grid>
                    <Typography className='dataGrid-title'>Equipment Move Info</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>Confirm and Enter Move Request Information</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      {preview_data.unit_Number? <InputLabel>Unit Number</InputLabel>
                      :<InputLabel>Equipment/Material To Move</InputLabel>
                  }
                        <TextField multiline disabled defaultValue={preview_data.unit_Number?preview_data.unit_Number +", "+preview_data.equipment_Type + ", " + 
                        preview_data.make + " " + preview_data.model:preview_data.custom_Unit_Number}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Unit Details</InputLabel>
                        <TextField multiline disabled defaultValue={preview_data.details}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <InputLabel>From</InputLabel>
                        <TextField multiline disabled defaultValue={preview_data.from_Location_Info}></TextField>
                        <div>
                          {move_request.from_location_latitude !== ''  && move_request.from_location_longitude !== '' && move_request.from_location_latitude !== null  && move_request.from_location_longitude !== null && <LALinkButton
                              label="View in Google Maps"
                              onClick={() => this.handleGPSClick(move_request.from_location_latitude, move_request.from_location_longitude)}
                          />}
                        </div>
                      </Grid>
                      {/* <Grid item xs={3}>
                      <InputLabel>To</InputLabel>
                        <TextField disabled defaultValue={preview_data.to_Location}></TextField>
                      </Grid> */}
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>To</InputLabel>
                      <Autocomplete disablePortal 
                            autoHighlight
                            disabled={disableForm ? true : this.state.isDriver ? true : (preview_data.status==="Initiated" || preview_data.status ==="Requested") ? false :  true}
                            value={move_request.to_location}
                            options={working_site_locations} 
                            onChange={async (event:any, newValue:String | null) => {
                                let splitted_job_no = newValue?.split(", Job #: ")[0];
                                move_request.to_location = splitted_job_no;
                                move_request.to_sub_location = "";
                                locations.map(async(locations:any) => {
                                    if(locations.site_Name === splitted_job_no){
                                        move_request.to_location_id = locations.id;
                                        var sub_site_info:any = await getSOCTWorkingSubSitesByParentID(locations.id,true);
                                        to_sub_site_options = sub_site_info.map((sub_location:any)=> {return sub_location.sub_Site_Name});
                                        to_sub_site_details = sub_site_info.map((sub_location: any) => { return {sub_site_id: sub_location.id, sub_site_name: sub_location.sub_Site_Name} });
                                        this.setState({});
                                    }
                                });
                                this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField multiline color="primary" variant="outlined" {...params} style={{backgroundColor:'white'}}></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>To Sub Location</InputLabel>
                      <Autocomplete  disablePortal 
                            autoHighlight
                            disabled={disableForm ? true : this.state.isDriver ? true : (preview_data.status==="Initiated" || preview_data.status ==="Requested") ? false :  true}
                            value={move_request.to_sub_location}
                            options={to_sub_site_options} 
                            onChange={async (event:any, newValue:String | null) => {
                                move_request.to_sub_location = newValue;
                                let findSubSite = to_sub_site_details.find(x=>x.sub_site_name === move_request.to_sub_location);
                                    console.log(findSubSite);
                                    if(findSubSite) {  
                                        move_request.dropoff_contact_name = await getCurrentSiteForeman(findSubSite.sub_site_id);
                                        let findDropoffRecord = foremans.find(e=>e.display_Name===move_request.dropoff_contact_name) ;
                                        if(findDropoffRecord){
                                            move_request.dropoff_contact_upn = findDropoffRecord.upn
                                        }
                                    }
                                this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" {...params} style={{backgroundColor:'white'}}></TextField>}></Autocomplete>
                            {move_request.to_location_latitude !== '' && move_request.to_location_longitude !== ''&& move_request.to_location_latitude !== null  && move_request.to_location_longitude !== null  && <LALinkButton
                              label="View in Google Maps"
                              onClick={() => this.handleGPSClick(move_request.to_location_latitude, move_request.to_location_longitude)}
                            />}
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Requested On</InputLabel>
                        <LADatePicker onChange={this.handleChange} dateFormat="m/d/y h:m" disable={true} value={preview_data.created}></LADatePicker>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Requested By</InputLabel>
                        <strong>{preview_data.moveRequest_By_Name}</strong>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Completed On</InputLabel>
                        <TextField disabled={localStorage.getItem("ET-Role")==="R" || this.state.isDriver} style={{backgroundColor:'white'}} type="date" name="completed_date" onChange={this.handleChange} defaultValue={splitDate(preview_data.completed_Date)}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Status</InputLabel>
                        <TextField disabled defaultValue={preview_data.status}></TextField>
                      </Grid>

                      {preview_data.status === "Requested" && <Grid item xs={grid_xs_width}>
                      <FormControlLabel control={<Checkbox onChange={() => this.onStatusClick("Approved")} 
                        name="status" checked={(this.state.approveCheck === true) ? true : false}/>} label="Approved"
                      />

                      <FormControlLabel control={<Checkbox onChange={() => this.onStatusClick("Rejected")}
                        name="status" checked={(this.state.approveCheck === false) ? true : false}/>} label="Rejected"
                      />
                      </Grid>}

                      {((preview_data.status === "Requested") && (this.state.approveCheck === false)) && <Grid item xs={grid_xs_width}>
                      <TextField error={(!this.state.approveCheck && 
                        (!move_request.reject_Comments || (move_request.reject_Comments && move_request.reject_Comments.length === 0))) ? true : false} disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} fullWidth multiline rows={2} type="string" label="Reject Comments" name="reject_Comments" defaultValue={move_request.reject_Comments}></TextField>
                      </Grid>}

                      {preview_data.status === "Moved" && window.location.href.includes("latest") && <Grid item xs={grid_xs_width}>
                        <FormControlLabel control={<Checkbox disabled={disableForm} onChange={this.handleChange} name="Undo Move" checked={move_request.undo_move}/>} label="Undo Move"/>
                      </Grid>}

                      <Grid item xs={6}>
                      <InputLabel>Request Details</InputLabel>
                        <TextField style={{backgroundColor:'white'}} fullWidth multiline rows={2} disabled={disableForm} defaultValue={preview_data.comments} name="request_details" onChange={this.handleChange}></TextField>
                      </Grid>

                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={foremans.map(e=> e.display_Name)} 
                            value={move_request.pickup_contact_name}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.pickup_contact_upn = '';
                              move_request.pickup_contact_name = newValue;
                              let findRecord = foremans.find(e=>e.display_Name===move_request.pickup_contact_name) ;
                              if(findRecord){
                                move_request.pickup_contact_upn = findRecord.upn
                              }
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="Pickup Contact"{...params} label="Pickup Contact"></TextField>}></Autocomplete>
                      </Grid>

                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={foremans.map(e=> e.display_Name)} 
                            value={move_request.dropoff_contact_name}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.dropoff_contact_upn = '';
                              move_request.dropoff_contact_name = newValue;
                              let findRecord = foremans.find(e=>e.display_Name===move_request.dropoff_contact_name) ;
                              if(findRecord){
                                move_request.dropoff_contact_upn = findRecord.upn
                              }
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="Dropoff Contact"{...params} label="Dropoff Contact"></TextField>}></Autocomplete>
                      </Grid>

                      <Grid item xs={grid_xs_width}>
                            <FormControlLabel control={<Checkbox disabled={disableForm} onChange={this.handleChange} name="cancelled" checked={move_request.cancelled}/>} label="Cancel Move"/>
                      </Grid>
                      {!hideRequestUserAccess ? 
                      <>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled defaultValue={"Sureway"} label="For Company"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={driver_options}
                            defaultValue={move_request.driver}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.driver = newValue;
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="driver"{...params} label="Driver"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={assigned_tractor_options} 
                             value={move_request.assigned_tractor_unit}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.assigned_tractor_id = null;
                              move_request.assigned_tractor_unit = newValue;
                              temp_tractors.map((tractor:any) => {
                                if(tractor.unit_Number === move_request.assigned_tractor_unit){
                                  move_request.assigned_tractor_id = tractor.id;
                                }
                              });
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="assigned_tractor"{...params} label="Assigned Tractor"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={trailer_config_options} 
                            defaultValue={move_request.trailer_config}
                            onChange={(event:any, newValue:String | null) => 
                              {move_request.trailer_config = newValue;
                                move_request.assigned_unit_number = "";
                                assigned_unit_number_options = [];
                                this.setState({});
                                var trailer_ids = 0;
                                temp_trailer_rates.map((elem:any) => {
                                  if(elem.description === move_request.trailer_config){
                                    trailer_ids = elem.id;
                                    move_request.trailer_config_id = trailer_ids;
                                  }
                                });
                                temp_trailers.map((elem:any) => {
                                  if(elem.trailer_Rate_ID === trailer_ids){
                                    assigned_unit_number_options.push(elem.unit_Number);
                                  }
                                })
                                this.setState({});
                              }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="trailer_config"{...params} label="Trailer Config"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            style={{backgroundColor:'white'}}
                            disabled={disableForm}
                            options={assigned_unit_number_options} 
                             value={move_request.assigned_unit_number}
                            onChange={(event:any, newValue:String | null) => {
                              move_request.trailer_id = null;
                              let assigned_unit_number = newValue;
                              move_request.assigned_unit_number = assigned_unit_number;
                              temp_trailers.map((trailer:any) => {
                                if(trailer.unit_Number === assigned_unit_number){
                                  move_request.trailer_id = trailer.id;
                                }
                              });
                              this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="assigned_unit_number"{...params} label="Trailer Unit #"></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <TextField type="number" disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} label="Pilot(s)" name="pilot" value={move_request.pilot}></TextField>
                        {/* <TextField type="number" disabled={disableForm ||   move_request.pilot > 0} style={{backgroundColor:'white'}} onChange={this.handleChange} label="Pilot(s)" name="pilot" value={move_request.pilot}></TextField> */}
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                            <FormControlLabel  control={<Checkbox disabled={disableForm} onChange={this.handleChange} name="tri_drive" checked={move_request.tri_drive}/>} label="Tri Drive"/>
                            {/* <FormHelperText>For when a site is renamed or completely removed</FormHelperText> */}
                        </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} type="number" style={{backgroundColor:'white'}} onChange={this.handleChange} label="Hours" name="hours" defaultValue={move_request.hours}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <FormLabel component="legend">Drive Over\Non Billable</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox disabled={disableForm} checked={move_request.drive_over} onChange={this.handleChange} name="drive_over"/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                      </Grid>
                      {/* <Grid item xs={grid_xs_width}>
                        <FormLabel component="legend">Permit</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox disabled={disableForm} checked={move_request.permit} onChange={this.handleChange} name="permit"/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                      </Grid> */}
                     
                      </>
                      : null}

                      {move_request.pilot > 0 && !hideRequestUserAccess?
                      <>
                      <Grid item xs={12}>
                      <Typography className='dataGrid-title'>Pilot Info</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <PilotDriversTable onAdd={this.onPilotAdd} onDelete={this.onPilotDelete} disabled={move_request.pilot > 0 ? true:undefined} data={this.state.pilotArray} handleChange={this.handlePilotTableChange}></PilotDriversTable>
                      </Grid>
                      </>
                      :null}
                      <>
                        <Grid item xs={12}>
                            <Typography className='dataGrid-title'>Attachment List</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AttachmentListTable data={this.state.attachment_list} onAddAttachment={this.onAttachmentAdd}  handletAttachmentChange={this.handleAttachmentTableChange} onDelete={this.onAttachmentDelete}></AttachmentListTable>
                        </Grid>
                      </>

                      {move_request.drive_over != true && !hideRequestUserAccess && !this.state.isDriver?
                      <>
                      <Grid item xs={12}>
                      <Typography className='dataGrid-title'>Payment Info</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} label="City Permit" name="city_permit" defaultValue={move_request.city_permit}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Cost</InputLabel>
                        <FilledInput disabled={disableForm} type="number" style={{backgroundColor:'white'}} onChange={this.handleChange} name="city_permit_cost" startAdornment={<InputAdornment position="start">$</InputAdornment>} defaultValue={move_request.city_permit_cost}></FilledInput>
                        </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} label="Gov. Permit" name="gov_permit" defaultValue={move_request.gov_permit}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <FormControl fullWidth variant="filled">
                        <InputLabel>Cost</InputLabel>
                        <FilledInput disabled={disableForm} type="number" style={{backgroundColor:'white'}} onChange={this.handleChange} name="gov_permit_cost" startAdornment={<InputAdornment position="start">$</InputAdornment>} defaultValue={move_request.gov_permit_cost}></FilledInput>
                        </FormControl>
                      </Grid>
                      </>
                      :null}
                      {!hideRequestUserAccess && !this.state.isDriver? 
                      <>
                      <Grid item xs={12}>
                      <Typography className='dataGrid-title'>Additional Info</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                            <FormControlLabel disabled={disableForm || preview_data.status==="Completed"} control={<Checkbox onChange={this.handleChange} name="completed" checked={move_request.completed}/>} label="Mark Complete"/>
                            {/* <FormHelperText>For when a site is renamed or completely removed</FormHelperText> */}
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} label="F/A" name="f_a" defaultValue={move_request.f_a}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} style={{backgroundColor:'white'}} onChange={this.handleChange} label="Commodity" name="commodity" defaultValue={move_request.commodity}></TextField>
                      </Grid>
                     
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableForm} required={move_request.undo_move} style={{backgroundColor:'white'}} onChange={this.handleChange} fullWidth multiline rows={2} type="string" label="Comments" name="comments" defaultValue={move_request.modified_notes}></TextField>
                      </Grid>
                      </>
                      : null}
                      <Grid container direction={"row"} spacing={1}>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Created</InputLabel>
                        <TextField disabled defaultValue={splitDateTime(preview_data.created)}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Created By</InputLabel>
                        <TextField disabled defaultValue={preview_data.created_By}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Modified</InputLabel>
                        <TextField disabled defaultValue={splitDateTime(preview_data.modified)}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Modified By</InputLabel>
                        <TextField disabled defaultValue={preview_data.modified_By}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel sx={{color:'rgb(241, 241, 241)'}}>  d  </InputLabel>
                      <Button 
                      disabled={disableForm || (hideRequestUserAccess && (preview_data.status==="Cancelled" || preview_data.status==="Rejected"))} style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid> </Grid>
                    </Grid>
                    {this.state.showCompleteMovePopup? <CompleteMoveRequestPopup isApprovalButton={this.state.approvalButtonClick} titleText={titleText} closePopup={this.closeCompleteMovePopup} confirmPopup={this.confirmMovePopup}></CompleteMoveRequestPopup>:null}
                    {this.state.showVersionHistory ? <MoveRequestVersionHistory id={move_version_id} open={this.state.showVersionHistory} onCancel={this.closeVersionHistory}></MoveRequestVersionHistory>:null}
                    </Box>
                    // </Modal>
        );
                          }
                          else{
                            return null;
                          }
    }
}


interface IPilotDriversTable {
  disabled?: true;
  data: any;
  handleChange:(event:any,value:any, index?:number, x?:any | undefined) => void;
  onAdd:()=>void;
  onDelete:(index:Number | undefined)=>void;
};

const PilotDriversTable: React.FC<IPilotDriversTable> = React.memo((props: IPilotDriversTable) => (
  <TableContainer component={LAPaperWithLessPadding}>
             <LAIconButton
            label="Add New"
            icon={<AddIcon />}
            onClick={props.onAdd} 
            /> 
       <Table size="small" aria-label="Service Items">

          <TableHead className="header-row">
              <TableRow>
                  <TableCell className="title">Pilot Driver</TableCell>
                  <TableCell className="title">Pilot Unit</TableCell>
                  <TableCell className="title">Hours</TableCell>
                   <TableCell className="title"></TableCell>
              </TableRow>
          </TableHead>
          {props.data.pilotArray.length>0 ? 
          props.data.pilotArray.map((x: any, index: number) => {
            if(x.deleted!="Yes"){
              return <TableBody key={index}>
                  <TableRow>

                      <TableCell>
                          <LAAutoComplete
                            multiple={false}
                              option={pilot_driver_options}
                              autoHighlight={true}
                              getOptionLabel="name"
                              filterSelectedOptions={true}
                              selectionRemove={undefinedFunction}
                              dropDownPlaceHolder="Pilot Driver"
                              name="pilot_driver"
                              disabled={false}
                              defaultValue={x.pilot_driver ? pilot_driver_options.find((q) => {if(q.value === x.pilot_driver.value)
                                {x.pilot_driver.name=q.name; return true}}) : null}
                              value={x.pilot_driver}
                              onChange={(e:any,val)=>{props.handleChange(e,val,index,'pilot_driver')}}
                              indexPosition={index}
                          />
                      </TableCell>

                      <TableCell>
                      <LAAutoComplete
                            multiple={false}
                              option={pilot_truck_options}
                              autoHighlight={true}
                              getOptionLabel="name"
                              filterSelectedOptions={true}
                              selectionRemove={undefinedFunction}
                              dropDownPlaceHolder="Pilot Truck"
                              name="pilot_truck"
                              disabled={false}
                              value={x.pilot_truck}
                              onChange={(e:any,val)=>{props.handleChange(e,val,index,'pilot_truck')}}
                              defaultValue={x.pilot_truck ? pilot_truck_options.find((q) => {if(q.value === x.pilot_truck.value)
                                {x.pilot_truck.name=q.name; return true}}) : null}
                              indexPosition={index}
                          />
                      </TableCell>

                      <TableCell>
                          <LATextField
                              fullWidth={true}
                              variant="outlined"
                              indexPosition={index}
                              name="hours"
                              label="Hours"
                              disabled={false}
                              value={x.hours?x.hours:0}
                              onChange={(e:any,val)=>{props.handleChange(e,val,index,'hours')}}
                              type="number"
                          />
                      </TableCell>
                       <TableCell>
                             <LAIconButton
            label="Delete"
             icon={<DeleteIcon />}
             onClick={(e:any)=>{props.onDelete(index)}} 
             disabled={hasEditBeenMade}
         />
                       </TableCell>
                   </TableRow>
              </TableBody>
           }}):null}
       </Table>
   </TableContainer>
 ));

 interface IAttachmentListTable {
  data: any;
  handletAttachmentChange:(value:any, index?:number, x?:any | undefined) => void;
  onAddAttachment:()=>void;
  onDelete:(index:number, data:any)=>void;
};

const AttachmentListTable: React.FC<IAttachmentListTable> = React.memo((props: IAttachmentListTable) => (
  <TableContainer component={LAPaperWithLessPadding}>
      <LAIconButton
          label="Add New"
          icon={<AddIcon />}
          onClick={props.onAddAttachment} 
          disabled={move_request.status === "Completed" || move_request.status === "Cancelled" || move_request.status === "Moved" ? true : undefined}
      /> 

      <Table size="small" aria-label="Attachment Items">
          <TableHead className="header-row">
            <TableRow>
                 <TableCell className="title">Attachment_No</TableCell>
                 <TableCell className="title">Type</TableCell>
                 <TableCell className="title">Coupler Design</TableCell>
                 <TableCell className="title">Style</TableCell>
                 <TableCell className="title">Location</TableCell>
                 <TableCell className="title">Attached Unit No</TableCell>
                 <TableCell className="title">Move Status</TableCell>
                 <TableCell className="title"></TableCell>
            </TableRow>
          </TableHead>

          {props.data ? 
              props.data.map((x: any, index: number) => {
                  // console.log(x);
                  // console.log(attachemnts);
                  const getAttachmentOptions = filterDropDown((x.move_Status === '' || x.move_Status === null || x.move_Status === 'completed') ? attachemnts : attachmentList,x.attachment_No, x.type, x.coupler_Design, x.style, 'attachment_No', props.data);
                  const getTypeOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'type',props.data);
                  const getCouplerDesignOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'coupler_Design', props.data);
                  const getStyleOptions = filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'style', props.data);
                  // const getAttachmentOptions = filteredAttachmentOptions((x.move_Status === '' || x.move_Status === null || x.move_Status === 'completed') ? attachemnts : Object.values(getAllAttachmentList),x.attachment_No, x.type, x.coupler_Design, x.style,  props.data);
                  // const getTypeOptions = filteredTypeOptions(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style,  props.data);
                  // const getCouplerDesignOptions = filteredCouplerDesignOptions(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style,  props.data);
                  // const getStyleOptions = filteredStyleOptions(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style,  props.data);
                  const onAttachmentNoChange = (value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "attachment_No");
                  const onTypeChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "type");
                  const onCouplerDesignChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "coupler_Design");
                  const onStyleChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value : "", index, "style");
                  const onLocationChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.location : "", index , "location");
                  const onAttUnitNoChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.attached_Unit_No : "", index, "attached_Unit_No");
                  const onStatusChange = (e: unknown, value: any) => props.handletAttachmentChange((value !== null) ? value.move_Status : "", index, "move_Status");
                  return <TableBody key={index}>
                      <TableRow>
                          <TableCell>
                              <DevExtremePopupLookup
                                    data={getAttachmentOptions}
                                    id="attachment-no"
                                    placeHolder="Attachment No"
                                    disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                    displayExp="attachment_No"
                                    name="attachment_No"
                                    errorName="attachment_No"
                                    errors={errors}
                                    currentVal={x.attachment_No}
                                    onClick={onAttachmentNoChange}
                                    columns={[
                                        { name: "attachment_No", caption: "Attachment No", type: "string" },
                                        { name: "type", caption: "Type", type: "string" },
                                        { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                        { name: "style", caption: "Style", type: "string" },
                                        { name: "location", caption: "Location", type: "string" },
                                        { name: "attached_Unit_No", caption: "Attached Unit No", type: "string" },
                                        { name: "move_Status", caption: "Move Status", type: "string" }
                                    ]}>
                                </DevExtremePopupLookup>
                              {/* <LAAutoComplete
                                  multiple={false}
                                  // option={attachemnts}
                                  option={filterDropDown(attachemnts,x.attachment_No, x.type, x.coupler_Design, x.style, 'attachment_No', props.data)}
                                  autoHighlight={true}
                                  getOptionLabel="attachment_No"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Attachment No"
                                  name="attachment no"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.attachment_No ? Object.values(getAllAttachmentList).find((att: any) => att.attachment_No === x.attachment_No):null}
                                  onChange={onAttachmentNoChange}
                                  defaultValue={x.attachment_No ? Object.values(getAllAttachmentList).find((att: any) => att.attachment_No === x.attachment_No) : null}
                                  indexPosition={index}
                              /> */}
                          </TableCell>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={getTypeOptions}
                                  autoHighlight={true}
                                  getOptionLabel="type"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Type"
                                  name="type"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.type ? Object.values(getAllAttachmentList).find((att: any) => att.type === x.type):null}
                                  onChange={onTypeChange}
                                  defaultValue={x.type ? Object.values(getAllAttachmentList).find((att: any) => att.type === x.type):null}
                                  indexPosition={index}
                              />
                          </TableCell>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={getCouplerDesignOptions}
                                  autoHighlight={true}
                                  getOptionLabel="coupler_Design"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Coupler Design"
                                  name="coupler design"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.coupler_Design ? Object.values(getAllAttachmentList).find((att: any) => att.coupler_Design === x.coupler_Design):null}
                                  onChange={onCouplerDesignChange}
                                  defaultValue={x.coupler_Design ? Object.values(getAllAttachmentList).find((att: any) => att.coupler_Design === x.coupler_Design):null}
                                  indexPosition={index}
                              />
                          </TableCell>
                          <TableCell>
                              <LAAutoComplete
                                  multiple={false}
                                  option={getStyleOptions}
                                  autoHighlight={true}
                                  getOptionLabel="style"
                                  filterSelectedOptions={true}
                                  selectionRemove={undefinedFunction}
                                  dropDownPlaceHolder="Style"
                                  name="style"
                                  disabled={attachmentList.find((att: any) => att === x) ? true: false}
                                  value={x.style ? Object.values(getAllAttachmentList).find((att: any) => att.style === x.style):null}
                                  onChange={onStyleChange}
                                  defaultValue={x.style ? Object.values(getAllAttachmentList).find((att: any) => att.style === x.style):null}
                                  indexPosition={index}
                              />
                          </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="location"
                                  label="Location"
                                  disabled={true}
                                  value={x.from_Sub_Location? x.from_Sub_Location:''}
                                  onChange={onLocationChange}
                                  type="text"
                              />
                          </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="attached unit no"
                                  label="Attached Unit No"
                                  disabled={true}
                                  value={x.attached_Unit_No?x.attached_Unit_No:''}
                                  onChange={onAttUnitNoChange}
                                  type="text"
                              />
                          </TableCell>
                          <TableCell>
                              <LATextField
                                  fullWidth={true}
                                  variant="outlined"
                                  indexPosition={index}
                                  name="move_Status"
                                  label="Move Status"
                                  disabled={true}
                                  value={x.move_Status?x.move_Status:''}
                                  onChange={onStatusChange}
                                  type="text"
                              />
                          </TableCell>
                          <TableCell>
                              <LAIconButton
                                  // disabled={(x.type !== '' && x.coupler_Design !== ''&& x.style !== '') ? undefined : true} 
                                  disabled={move_request.status === "Completed" || move_request.status === "Cancelled" || move_request.status === "Moved" ? true : undefined}
                                  label="Delete"
                                  icon={<DeleteIcon />}
                                  onClick={(e:any)=>{props.onDelete(index, x)}} 
                              />
                          </TableCell>
                      </TableRow>
                  </TableBody>
              }) 
          : null}
      </Table>
  </TableContainer>
));

const filterDropDown = (data: any[], attachment_No: string | undefined, type: string | undefined, coupler_Design: string| undefined, style: string| undefined,forDropDown: string, state:any): any[]  => {
  let response: any[] = [];
  // console.log(data, attachment_No, type, coupler_Design, style, forDropDown, state)
  response = data.filter(item => !state.some((stateItem:any) => stateItem.attachment_No === item.attachment_No));
  // console.log(response)
  // response = data;
  if (attachment_No && attachment_No.length > 0) {
      response = response.filter(x => x.attachment_No === attachment_No);
      // console.log(response);
  } if (coupler_Design && coupler_Design.length > 0) {
      response = response.filter(x => x.coupler_Design === coupler_Design);
      // console.log(response);
  } if (type && type.length > 0) {
      response = response.filter(x => x.type === type);
      // console.log(response);
  } if (style && style.length > 0) {
      response = response.filter(x => x.style === style);
      // console.log(response);
  } if (type && type.length > 0 && coupler_Design && coupler_Design.length > 0 && style && style.length > 0) {
      response = response.filter(x => x.type === type && x.coupler_Design === coupler_Design && x.style === style);
      // console.log(response);
  } 

  if (forDropDown === 'coupler_Design') {
      response = response.filter((v, i, a) => a.findIndex(t => t.coupler_Design === v.coupler_Design) === i);
      // console.log(response);
  } 
  else if (forDropDown === 'type') 
  {
      response = response.filter((v, i, a) => a.findIndex(t => t.type === v.type) === i);
      // console.log(response);
  } 
  else if (forDropDown === 'style') 
  {
      response = response.filter((v, i, a) => a.findIndex(t => t.style === v.style) === i);
      // console.log(response);
  } 
  else if (forDropDown === 'attachment_No') 
  {
      response = response.filter((v, i, a) => a.findIndex(t => t.attachment_No === v.attachment_No) === i);
      response = response.filter(item => !state.some((stateItem:any) => stateItem.attachment_No === item.attachment_No));
      response = response.length > 0 ? response : data.filter(x => x.attachment_No === attachment_No);
      // console.log(response);
  }
  // else {
  //     response = data;
  //     console.log(response);
  // }

  return response;
};


export {currentETEditUser}