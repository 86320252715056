import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
import { IGetSOCTCustomServiceItemsBySOCTIDLoadAction, IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction, IGetSOCTCustomServiceItemsBySOCTIDSuccessAction } from "./getSOCTCustomServiceItemsBySOCTIDActions";
import { IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST, ISOCTCustomServiceItemsBySOCTID } from "./getSOCTCustomServiceItemsBySOCTIDConstants";

type Actions =
    | IGetSOCTCustomServiceItemsBySOCTIDLoadAction
    | IGetSOCTCustomServiceItemsBySOCTIDSuccessAction
    | IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetSOCTCustomServiceItemsBySOCTIDReducer = (state: Server<SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>> => {
    switch (action.type) {
        case IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};