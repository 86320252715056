import { IAddUpdateServiceSection } from "../addServiceSection/addServiceSectionConstants";

export enum IUPDATE_SERVICE_SECTION_REQUEST {
    REQUEST = "updateServiceSection/UPDATE_SERVICE_SECTION_REQUEST",
    SUCCESS = "updateServiceSection/UPDATE_SERVICE_SECTION_SUCCESS",
    FAILED = "updateServiceSection/UPDATE_SERVICE_SECTION_FAILED"
};

export interface IUpdateServiceSectionRequest {
    token: string;
    request: IAddUpdateServiceSection;
};