
export enum IADD_FILTER_PART_REQUEST {
    REQUEST = "addFilterPart/ADD_FILTER_PART_REQUEST",
    SUCCESS = "addFilterPart/ADD_FILTER_PART_SUCCESS",
    FAILED = "addFilterPart/ADD_FILTER_PART_FAILED"
};

export interface IAddFilterPartRequest {
    token: string;
    request: IAddUpdateFilterPart;
};

export interface IAddUpdateFilterPart {
    ID: number;
    Make_ID: number;
    Model: string;
    Unit_No: string;
    Unit_ID: number;
    Type: string;
    Sub_Type: null,
    Part_No: string;
    Quantity: number;
    Created_By: string;
    Modified_By: string;
};