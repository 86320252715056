import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_ROVER_KIT_REQUEST } from "./updateRoverKitConstants";
import { IUpdateRoverKitLoadAction, IUpdateRoverKitLoadFailedAction, IUpdateRoverKitSuccessAction } from "./updateRoverKitActions";

type Actions =
    | IUpdateRoverKitLoadAction
    | IUpdateRoverKitSuccessAction
    | IUpdateRoverKitLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateRoverKitReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_ROVER_KIT_REQUEST.REQUEST:
            return loading;

        case IUPDATE_ROVER_KIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_ROVER_KIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};