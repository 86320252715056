import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_UTILIZATION_BY_ID_REQUEST, IGetUnitUtilizationByID, IGetUnitUtilizationByIDRequest } from "./getUnitUtilizationByIDConstants";

export interface IGetUnitUtilizationByIDLoadAction {
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.REQUEST;
    data: IGetUnitUtilizationByIDRequest
}
export const getUnitUtilizationByIDLoadAction = (data: IGetUnitUtilizationByIDRequest): IGetUnitUtilizationByIDLoadAction => ({
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetUnitUtilizationByIDSuccessAction {
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetUnitUtilizationByID>>;
}
export const getUnitUtilizationByIDLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetUnitUtilizationByID>>): IGetUnitUtilizationByIDSuccessAction => ({
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetUnitUtilizationByIDLoadFailedAction {
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getUnitUtilizationByIDLoadFailedAction = (message: string): IGetUnitUtilizationByIDLoadFailedAction => ({
    type: IGET_UNIT_UTILIZATION_BY_ID_REQUEST.FAILED,
    message
});
