import { IAddUpdateJournel } from "../addJournel/addJournelConstants";

export enum IUPDATE_JOURNEL_REQUEST {
    REQUEST = "updateJournel/UPDATE_JOURNEL_REQUEST",
    SUCCESS = "updateJournel/UPDATE_JOURNEL_SUCCESS",
    FAILED = "updateJournel/UPDATE_JOURNEL_FAILED"
};

export interface IUpdateJournelRequest {
    token: string;
    request: IAddUpdateJournel;
};