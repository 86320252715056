import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST {
    REQUEST = "getTechServicesByUnitID/GET_TECH_SERVICES_BY_UNIT_ID_REQUEST",
    SUCCESS = "getTechServicesByUnitID/GET_TECH_SERVICES_BY_UNIT_ID_SUCCESS",
    FAILED = "getTechServicesByUnitID/GET_TECH_SERVICES_BY_UNIT_ID_FAILED"
};

export interface IGetTechServicesByUnitIDRequest extends ISurewayTokenRequestBody {
    request: {
        unit_id: number;
    };
};