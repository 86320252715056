
export enum IGET_MAPPING_VIEW_REQUEST {
    REQUEST = "getMappingView/GET_MAPPING_VIEW_REQUEST",
    SUCCESS = "getMappingView/GET_MAPPING_VIEW_SUCCESS",
    FAILED = "getMappingView/GET_MAPPING_VIEW_FAILED"
};

export interface IMappingViewRequest {
    token: string;
};

export interface IMappingView {
    id: number;
    equipment_ID: number;
    unit_Number: string;
    equipment_Type: string;
    make: string;
    model: string;
    move_Status: string;
    working_Site_ID: number;
    working_Site_Sub_ID: number;
    site_Name: string;
    sub_Site_Name: string;
    equipment_Mapping_IDs: string;
    items: string;
    uuid: string;
    job_No: string;
    job_Name: string;
    map_ID: number;
    map_Name: string;
    details: string;
    add_To_SOCT: string;
};