import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_TECH_SERVICE_REQUEST, IAddTechServiceRequest } from "./addTechServiceConstants";


export interface IAddTechServiceLoadAction {
    type: IADD_TECH_SERVICE_REQUEST.REQUEST;
    data: IAddTechServiceRequest
}
export const addTechServiceLoadAction = (data: IAddTechServiceRequest): IAddTechServiceLoadAction => ({
    type: IADD_TECH_SERVICE_REQUEST.REQUEST,
    data
});
export interface IAddTechServiceSuccessAction {
    type: IADD_TECH_SERVICE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addTechServiceLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddTechServiceSuccessAction => ({
    type: IADD_TECH_SERVICE_REQUEST.SUCCESS,
    message
});
export interface IAddTechServiceLoadFailedAction {
    type: IADD_TECH_SERVICE_REQUEST.FAILED;
    message: string;
}
export const addTechServiceLoadFailedAction = (message: string): IAddTechServiceLoadFailedAction => ({
    type: IADD_TECH_SERVICE_REQUEST.FAILED,
    message
});
