import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_VENDOR_PO_LOGS_REQUEST, IVendorPOLogs } from "./getVendorPOLogsConstants";
import { IGetVendorPOLogsLoadAction, IGetVendorPOLogsLoadFailedAction, IGetVendorPOLogsSuccessAction } from "./getVendorPOLogsActions";
import { ISaveVendorPOLogSuccessAction } from "../saveVendorPOLog/saveVendorPOLogActions";
import { ISAVE_VENDOR_PO_LOG_REQUEST } from "../saveVendorPOLog/saveVendorPOLogConstants";

type Actions =
    | IGetVendorPOLogsLoadAction
    | IGetVendorPOLogsSuccessAction
    | IGetVendorPOLogsLoadFailedAction
    | ISaveVendorPOLogSuccessAction
    | IFlushDataSuccessAction;

export const GetVendorPOLogsReducer = (state: Server<SurewayAPIResponse<ById<IVendorPOLogs>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IVendorPOLogs>>> => {
    switch (action.type) {
        case IGET_VENDOR_PO_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_PO_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_PO_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;
 
        case ISAVE_VENDOR_PO_LOG_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};