import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SERVICE_TRUCK_REQUEST, IUpdateServiceTruckRequest } from "./updateServiceTruckConstants";


export interface IUpdateServiceTruckLoadAction {
    type: IUPDATE_SERVICE_TRUCK_REQUEST.REQUEST;
    data: IUpdateServiceTruckRequest
}
export const updateServiceTruckLoadAction = (data: IUpdateServiceTruckRequest): IUpdateServiceTruckLoadAction => ({
    type: IUPDATE_SERVICE_TRUCK_REQUEST.REQUEST,
    data
});
export interface IUpdateServiceTruckSuccessAction {
    type: IUPDATE_SERVICE_TRUCK_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateServiceTruckLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateServiceTruckSuccessAction => ({
    type: IUPDATE_SERVICE_TRUCK_REQUEST.SUCCESS,
    message
});
export interface IUpdateServiceTruckLoadFailedAction {
    type: IUPDATE_SERVICE_TRUCK_REQUEST.FAILED;
    message: string;
}
export const updateServiceTruckLoadFailedAction = (message: string): IUpdateServiceTruckLoadFailedAction => ({
    type: IUPDATE_SERVICE_TRUCK_REQUEST.FAILED,
    message
});
