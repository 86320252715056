import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST, ISaveAcknowledgeResponseRequest } from "./saveAcknowledgeResponseConstants";


export interface ISaveAcknowledgeResponseLoadAction {
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.REQUEST;
    data: ISaveAcknowledgeResponseRequest
}
export const saveAcknowledgeResponseLoadAction = (data: ISaveAcknowledgeResponseRequest): ISaveAcknowledgeResponseLoadAction => ({
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.REQUEST,
    data
});
export interface ISaveAcknowledgeResponseSuccessAction {
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveAcknowledgeResponseLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveAcknowledgeResponseSuccessAction => ({
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.SUCCESS,
    message
});
export interface ISaveAcknowledgeResponseLoadFailedAction {
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.FAILED;
    message: string;
}
export const saveAcknowledgeResponseLoadFailedAction = (message: string): ISaveAcknowledgeResponseLoadFailedAction => ({
    type: ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.FAILED,
    message
});
