import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_METER_HISTORY_BY_SOCT_ID_REQUEST, IMeterHistory } from "./getMeterHistoryBySOCTIDConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetMeterHistoryBySOCTIDLoadAction, IGetMeterHistoryBySOCTIDLoadFailedAction, IGetMeterHistoryBySOCTIDSuccessAction, 
         getMeterHistoryBySOCTIDLoadFailedAction, getMeterHistoryBySOCTIDLoadSuccessAction } from "./getMeterHistoryBySOCTIDActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getMeterHistoryBySOCTIDEpic: Epic = (
    action$: ActionsObservable<IGetMeterHistoryBySOCTIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMeterHistoryBySOCTIDSuccessAction | IGetMeterHistoryBySOCTIDLoadFailedAction> =>
    action$.ofType(IGET_METER_HISTORY_BY_SOCT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IMeterHistory>>>(
                    END_POINTS.FIELD.SCOT.GET_METER_HISTORY_BY_SOCT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IMeterHistory>>): IGetMeterHistoryBySOCTIDSuccessAction => {
                            return getMeterHistoryBySOCTIDLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getMeterHistoryBySOCTIDLoadFailedAction(response)))
                    )
            )
        );