import React from "react";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { TableContainer } from "@material-ui/core";
import { undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { ROUTE } from "../../routes";
import { IGetRepairLineVersionHistory } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryConstants";


interface IRepairLineVersionHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetRepairLineVersionHistory>;
    display: string | undefined;
};

export const RepairLineVersionHistory: React.FC<IRepairLineVersionHistoryProps> = React.memo((props: IRepairLineVersionHistoryProps) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data)

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY - {props.display}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading RepairLine Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            // onEdit={onEdit}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="repairLineList"
                            customRowColor={true}
                            exportFileName="RepairLine-Version-History"
                            storageKey="repairLineVersionHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "repair_Location", caption: "Repair Location", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "location_Info", caption: "Location Info", type: "string" },
                                { name: "date_Completed", caption: "Completed Date", type: "date" },
                                { name: "work_Order_No", caption: "Work Order No.", type: "string" },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});