import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById,  SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LABluePaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, deleteText, fileToBase64Image, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { v4 as uuidv4 } from "uuid";
import { ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import LAAutoComplete from "../../shared/autoComplete";
import LATextArea from "../../shared/textArea";
import { LADropZone } from "../../shared/dropZone";
import { Marker } from "../repairLine/repairLine";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import splitDate from "../../shared/splitDate";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { IFieldLookup, IFieldLookupRequest, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { IGetJournelsRequest, IJournelPicture, IJournels } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsConstants";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IAddJournelRequest, IAddUpdateJournel } from "../../../redux/field/workingSiteLogs/addJournel/addJournelConstants";
import { IUpdateJournelRequest } from "../../../redux/field/workingSiteLogs/updateJournel/updateJournelConstants";
import { addJournelStatus } from "../../../redux/field/workingSiteLogs/addJournel/addJournelAccessor";
import { updateJournelStatus } from "../../../redux/field/workingSiteLogs/updateJournel/updateJournelAccessor";
import { getJournels } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsAccessor";
import { getJournelsLoadAction } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsActions";
import { addJournelLoadAction } from "../../../redux/field/workingSiteLogs/addJournel/addJournelActions";
import { updateJournelLoadAction } from "../../../redux/field/workingSiteLogs/updateJournel/updateJournelActions";
import { LACenteredLoading } from "../../shared/loading";
import LALinkButton from "../../shared/linkButton";
import { IGetJournalVersionHistory, IGetJournalVersionHistoryRequest } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryConstants";
import { getJournalVersionHistory } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryAccessor";
import { getJournalVersionHistoryLoadAction } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryActions";
import { JournalVersionHistoryPopup } from "./journalVersionHistoryPopup";

const RequiredFields: string[] = ["date", "wsS_ID", "name", "work" ];

interface IAddUpdateJournelComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addJournelStatus: Server<SurewayAPIResponse<string>>;
    getJournels: Server<SurewayAPIResponse<ById<IJournels>>>;
    updateJournelStatus: Server<SurewayAPIResponse<string>>;
    getJournalVersionHistory: Server<SurewayAPIResponse<ById<IGetJournalVersionHistory>>>;
};

interface IAddUpdateJournelComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getJournelsRequest: (data: IGetJournelsRequest) => unknown
    addJournelRequest: (data: IAddJournelRequest) => unknown;
    updateJournelRequest: (data: IUpdateJournelRequest) => unknown;
    getJournalVersionHistoryRequest: (data: IGetJournalVersionHistoryRequest) => unknown;
};

interface IAddUpdateJournelOwnProps {
    id?: string;
};

interface IAddUpdateJournelComponentState {
    data: IJournels;
    journalVersionHistoryPopup: boolean;
    serverError: string;
    popupImgs: IJournelPicture[];
    errors: ById<IFieldErrorKeyValue>;
    imageSrc: { src: string, idx: number } | undefined;
};

const AddUpdateJournelStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateJournelComponentProps =
    RouteComponentProps
    & IAddUpdateJournelOwnProps
    & IAddUpdateJournelComponentStoreProps
    & IAddUpdateJournelComponentDispatchProps;

class AddUpdateJournel extends PureComponent<IAddUpdateJournelComponentProps, IAddUpdateJournelComponentState> {

    public constructor(props: IAddUpdateJournelComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                date: new Date().toISOString(),
                wsS_ID: 0,
                name: userName,
                work: "",
                description: "",
                images: "",
                pictures: [],
                created_By: "",
                created: userName,
                modified_By: "",
                modified: userName,
            },
            journalVersionHistoryPopup: false,
            errors: {},
            popupImgs: [],
            serverError: "",
            imageSrc: undefined
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateJournelComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addJournelStatus !== prevProps.addJournelStatus) {

                if (this.props.addJournelStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addJournelStatus.message });

                if (hasPayload(this.props.addJournelStatus) && this.props.addJournelStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateJournelStatus !== prevProps.updateJournelStatus) {

                if (this.props.updateJournelStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateJournelStatus.message });

                if (hasPayload(this.props.updateJournelStatus) && this.props.updateJournelStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, imageSrc, popupImgs, journalVersionHistoryPopup } = this.state;
        const title = data.id > 0 ? "Update Journal" : "Add Journal";
        const { token, addJournelStatus, updateJournelStatus, getLookup, getJournalVersionHistory } = this.props;
        // console.log(token)
        const getRole = pageAccessCheck(token, "workingSiteLogs");
        const disabledEdit = data.id > 0 ? true : undefined;
        const disabled = getRole === ReadOnly ? true : disabledEdit;

        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        const journalVersionHistoryPopupData = hasPayload(getJournalVersionHistory) ? Object.values(getJournalVersionHistory.payload.response) : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onWSS = (value: string): void => this.handleChange("wsS_ID", value ? value : "");
        const onName = (event: unknown, value: IForeman): void => this.handleChange("name", value !== null ? value.upn : data.name);
        
        if(getLookup.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateJournelStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={9} sm={9} md={9}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={3} >
                                <LALinkButton onClick={() => this.handleJournalVersionHistory(data)} label="Version History"></LALinkButton>
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}JOURNAL</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    id="Date"
                                    value={splitDate(data.date)}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled || data.id > 0}
                                    name="date"
                                    label="Date"
                                    errorText={errors["date"] ? errors["date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            {data.id === 0 ? <LAGridItem xs={12} sm={6} md={4}>
                                <DevExtremePopupLookup
                                    data={wssList}
                                    id="wss-search"
                                    placeHolder="Working Site"
                                    disabled={disabled}
                                    displayExp="site"
                                    returnExp="wsS_ID"
                                    name="wsS_ID"
                                    errorName="wsS_ID"
                                    errors={errors}
                                    currentVal={data.wsS_ID}
                                    onClick={onWSS}
                                    columns={[
                                        { name: "site", caption: "Site", type: "string" },
                                        { name: "job", caption: "Job", type: "string" },
                                        { name: "site_Address", caption: "Site Address", type: "string" },
                                        { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                    ]}
                                />
                            </LAGridItem> :

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    name="wsS_ID"
                                    label="Working Site"
                                    fullWidth={true}
                                    disabled={true}
                                    variant="outlined"
                                    onChange={undefinedFunction}
                                    value={data.site ?? ""}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={foremans}
                                    autoHighlight={true}
                                    onChange={onName}
                                    filterSelectedOptions={true}
                                    getOptionLabel="display_Name"
                                    dropDownPlaceHolder="Name"
                                    selectionRemove={undefinedFunction}
                                    value={data.name ? foremans?.find(q => q.upn === data.name) : null}
                                    defaultValue={data.name ? foremans?.find(q => q.upn === data.name) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Work"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="work"
                                    disabled={disabled}
                                    value={data.work ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["work"] ? errors["work"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    minRows={4}
                                    rowsMax={6}
                                    label="Description"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled}
                                    name="description"
                                    value={data.description ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["description"] ? errors["description"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LABluePaper>
                                    <LAGridItem xs={12} className="text-center">
                                        {!imageSrc ? <strong>Images</strong> : <strong>Highlight Image</strong>}<hr />
                                        {!imageSrc && <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e)} />}
                                    </LAGridItem>
                                    <br />
                                    {!imageSrc ? 
                                        <LAGrid>
                                            {popupImgs
                                                .map((q, ind) => {
                                                    const onImgDelete = (): void => this.onImgDelete(q.name, ind);
                                                    const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                    const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                    const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind);

                                                    return (
                                                        <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                            <LAPaperWithLessPadding>
                                                                <LAThumbnailWithLink
                                                                    alt={q.name}
                                                                    key={ind}
                                                                    url={base64}
                                                                    id={(ind + "_").toString()}
                                                                />

                                                                <LATextField
                                                                    className="mt-2"
                                                                    label="Description"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    name="description"
                                                                    disabled={disabled}
                                                                    value={q.description}
                                                                    onChange={onImageName}
                                                                    errorText={
                                                                        q.name.length === 0 ?
                                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                            : undefined
                                                                    }
                                                                />

                                                                {q.base64String && <LAButton 
                                                                    onClick={openImage} 
                                                                    disabled={disabled}
                                                                    label="Highlight Image" 
                                                                />}

                                                                <LAIconButton
                                                                    key={ind}
                                                                    label="Delete"
                                                                    disabled={disabled}
                                                                    onClick={onImgDelete}
                                                                    icon={<DeleteIcon />}
                                                                />
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    );

                                                })
                                            }
                                        </LAGrid>
                                        : <Marker
                                            imageSrc={imageSrc.src}
                                            onSave={this.handleEditImageSave}
                                            onCancel={this.handleEditImageCancel}
                                        />
                                    }

                                </LABluePaper>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave || disabled}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    } 
                    {journalVersionHistoryPopup && <JournalVersionHistoryPopup
                        data={journalVersionHistoryPopupData}
                        display={`Version History`}
                        open={journalVersionHistoryPopup}
                        status={getJournalVersionHistory.kind}
                        onCancel={this.handleJournalVersionHistoryPopupClose}
                    />}
                    <RequestStatus requestStatus={addJournelStatus.kind} successMessage="Journal has been added successfully" />
                    <RequestStatus requestStatus={updateJournelStatus.kind} successMessage="Journal has been updated successfully" />
                </AddUpdateJournelStyles>
            </PageSpacing>
        );
    }

    private handleEditImageSave = (src: string): void => {
        if (this.state.imageSrc)
            this.onImageEdit(src, this.state.imageSrc.idx);

        this.handleEditImageCancel();
    };

    private handleOpenImage = (src: string, idx: number): void => {
        this.setState({ imageSrc: { src, idx } });
    };

    private handleEditImageCancel = (): void => {
        this.setState({ imageSrc: undefined });
    };

    private onImageEdit = (base64String: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], base64String };
        popupImgs[subIndex] = picture;

        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private handleImageName = (name: string, value: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], [name]: value };
        popupImgs[subIndex] = picture;


        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private onImgDelete = (name: string, imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const popupImgs = [...this.state.popupImgs];
            popupImgs.splice(imgIndex, 1);
            
            this.setState({
                ...this.state,
                popupImgs: [
                    ...popupImgs
                ]
            });
        }
    };
    
    private handleImageUpload = async (event: any): Promise<void> => {

        const files = event;
        const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                const imgId = uuidv4();
                let isDuplicateDescription = false;
                // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                let description = x.name;
                const base64String = await fileToBase64Image(x);

                popupImgs.map((img, i) => {
                    // console.log(img.description, description)
                    if(img.description === description.split(".")[0]) {
                        isDuplicateDescription = true;
                    }
                    return isDuplicateDescription
                })
                isDuplicateDescription ?
                    popupImgs.push({
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0] + "_1",
                        base64String,
                    }) : 
                    popupImgs.push({
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0],
                        base64String,
                    });

                this.setState({
                    ...this.state,
                    popupImgs: [
                        ...popupImgs
                    ], 
                });
            }));
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.JOURNALS
        });
    };

    private handleJournalVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await this.props.getJournalVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: data.id
                }
            });
        }
        this.setState({ journalVersionHistoryPopup: true });
    }

    private handleJournalVersionHistoryPopupClose = (): void => {
        this.setState({ journalVersionHistoryPopup: false });
    };

    private handleSave = async (): Promise<void> => {

        const { data, popupImgs } = this.state;

        if (hasPayload(this.props.token)) {

            // const images: string = popupImgs.map(picture => picture.name).join(";");

            let request: IAddUpdateJournel = {
                ID: data.id,
                Date: data.date,
                WSS_ID: data.wsS_ID,
                Name: data.name,
                Work: data.work,
                Description: data.description,
                Pictures: popupImgs,
                Created_By: data.created,
                Modified_By: this.props.token.payload.response.upn,
            };

            // console.log(request)
            if (data.id === 0) {
                this.props.addJournelRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateJournelRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: name === "wsS_ID" ? Number(value) : value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {

                    if (hasPayload(this.props.getJournels)) {
                        if (query.id !== "0") {
                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getJournels.payload.response[query.id];
                                // const pictures = data.images && data.images.split(";");
                                const pictures = data.pictures; 
                                
                                const popupImgs: IJournelPicture[] = [];
                                pictures && pictures.forEach((x) => {
                                    popupImgs.push({
                                        name: x.name,
                                        description: x.description,
                                        base64String: ""
                                    });
                                });
                                // console.log(popupImgs)
                                this.setState({
                                    data,
                                    popupImgs, 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });
                            if (errors["date"])
                                delete errors["date"]
                            if (errors["name"])
                                delete errors["name"]

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getJournelsRequest({
                            token: this.props.token.payload.response.token,
                        });
                    }
                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Journels"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateJournelComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getJournels: getJournels(state),
    addJournelStatus: addJournelStatus(state),
    updateJournelStatus: updateJournelStatus(state),
    getJournalVersionHistory: getJournalVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateJournelComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getJournelsRequest: (data: IGetJournelsRequest) => dispatch(getJournelsLoadAction(data)),
    addJournelRequest: (data: IAddJournelRequest) => dispatch(addJournelLoadAction(data)),
    updateJournelRequest: (data: IUpdateJournelRequest) => dispatch(updateJournelLoadAction(data)),
    getJournalVersionHistoryRequest: (data: IGetJournalVersionHistoryRequest) => dispatch(getJournalVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateJournel);