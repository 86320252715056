
export enum IGET_VENDOR_PO_LOG_BY_ID_REQUEST {
    REQUEST = "getVendorPOLogByID/GET_VENDOR_PO_LOG_BY_ID_REQUEST",
    SUCCESS = "getVendorPOLogByID/GET_VENDOR_PO_LOG_BY_ID_SUCCESS",
    FAILED = "getVendorPOLogByID/GET_VENDOR_PO_LOG_BY_ID_FAILED"
};

export interface IGetVendorPOLogByIDRequest {
    token: string;
    request: {
      id: number;
    };
};

export interface IGetVendorPOLogByID {
    id: number;
    requested_By: string;
    date: string;
    po: string;
    order_ID: number;
    vendor: string;
    job_ID: number;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    scope: string;
    description: string;
    cost_Code: string;
    ticket: string;
    invoice: string;
    leM_No: string;
    in_CDMS: string;
    in_Explorer: string;
    committed_Amount: number;
    invoiced_Amount: number;
    accrued_Cost: string;
    accrued_Revenue: string;
    rog: string;
    roG_Date: string;
    requested_By_Name?: string;
    job_No?: string;
}

