import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_JOB_PIT_REQUEST, IAddJobPitRequest } from "./addJobPitConstants";


export interface IAddJobPitLoadAction {
    type: IADD_JOB_PIT_REQUEST.REQUEST;
    data: IAddJobPitRequest
}
export const addJobPitLoadAction = (data: IAddJobPitRequest): IAddJobPitLoadAction => ({
    type: IADD_JOB_PIT_REQUEST.REQUEST,
    data
});
export interface IAddJobPitSuccessAction {
    type: IADD_JOB_PIT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addJobPitLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddJobPitSuccessAction => ({
    type: IADD_JOB_PIT_REQUEST.SUCCESS,
    message
});
export interface IAddJobPitLoadFailedAction {
    type: IADD_JOB_PIT_REQUEST.FAILED;
    message: string;
}
export const addJobPitLoadFailedAction = (message: string): IAddJobPitLoadFailedAction => ({
    type: IADD_JOB_PIT_REQUEST.FAILED,
    message
});
