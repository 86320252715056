import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";

export const adalConfig = {
    instance: "https://adfs.sureway.ca/",
    tenant: "adfs",
    clientId: "7d3a5f1b-b58d-4575-a45e-551d8a5f3437",
    //clientId: "82737e79-7ce6-488c-9592-bde26b9c60fa",
    endpoints: {
        api: "",
    },
    cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

