import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography} from '@mui/material';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import {RouteComponentProps} from 'react-router-dom';

  interface IProps extends RouteComponentProps{
    history:any
  }
  interface IState {
    searchValue:string
  }
  
var testdata = [{id:1,service_Date:'01/12/2022',SERVICE_DUE:'250', s_Unit_Number:'99413',meter_Read:9821,comment:'test',prev_Date:'01/10/2022',prev_Reading:9820,foreman:1,created:'2021/07/12',modified:'2021/07/12',created_By:'admin',modified_By:'admin'},
{id:1,service_Date:'01/12/2022',SERVICE_DUE:'250,500', s_Unit_Number:'84844',meter_Read:11300,comment:'test',prev_Date:'01/10/2022',prev_Reading:11234,foreman:3,created:'2021/07/12',modified:'2021/07/12',created_By:'admin',modified_By:'admin'},
{id:1,service_Date:'01/12/2022',SERVICE_DUE:'250', s_Unit_Number:'112',meter_Read:120,comment:'test',prev_Date:'01/10/2022',prev_Reading:100,foreman:4,created:'2021/07/12',modified:'2021/07/12',created_By:'admin',modified_By:'admin'},
{id:1,service_Date:'01/12/2022',SERVICE_DUE:'250,500,1500', s_Unit_Number:'444',meter_Read:8474,comment:'test',prev_Date:'01/10/2022',prev_Reading:8474,foreman:2,created:'2021/07/12',modified:'2021/07/12',created_By:'admin',modified_By:'admin'}];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ color:'#5383d3',font: 'Cookie'}}>{data.column.caption}</p>
}
export default class ScheduledService extends React.Component<IProps,IState>{
    constructor(props:any){
      super(props);
      this.state = {searchValue: ''};
    }
    handleValueChange = (e:any) => {
      // console.log(e);
      const trimmedValue = e.trim();
      this.setState({searchValue:trimmedValue });
    };
    render(){
        return (
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={()=> {this.props.history.push('/field/soct/scheduledservice/create')}} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
              <Typography className='dataGrid-title'>Scheduled Service</Typography>
            <DataGrid focusedRowEnabled={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
               dataSource={testdata}
               keyExpr="id"
               showBorders={true}
              > 
           <SearchPanel visible={true}
            width={340}
            placeholder="Search for..." 
            // text={this.state.searchValue}
            // onTextChange={this.handleValueChange}
       />    
     <Paging enabled={true} defaultPageSize={5}/>
     <Pager
       visible={true}
       allowedPageSizes={[5,10,20]}
       displayMode={'full'}
       showPageSizeSelector={true}
       showInfo={true}
       showNavigationButtons={true} />
       <FilterRow visible={true}></FilterRow>
       <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     
     <Column type="buttons" caption="Edit" headerCellRender={renderTitleHeader}>
     <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => this.props.history.push('/field/soct/scheduledservice/edit/id='+e.row.data.id)}></Button>
       </Column>
       <Column dataField="id" caption="id" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="service_Date" dataType="date" caption="Service Date" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="SERVICE_DUE" caption="Service Type" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="s_Unit_Number" caption="S_Unit_Number" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="meter_Read" caption="Meter Read" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="comment" caption="Comment" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="prev_Date" dataType="date" caption="Previous Date" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="prev_Reading" caption="Previous Reading" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="foreman" caption="Foreman ID" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="created" dataType="date" caption="Created" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified" dataType="date" caption="Modified" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="created_By" caption="Created By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
       <Column dataField="modified_By" caption="Modified By" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Card>
            </Grid>
        );
    }
}