import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_LEVEL_REQUEST, IAddUpdateLevelRequest } from "./addLevelConstants";

export interface IAddLevelLoadAction {
    type: IADD_LEVEL_REQUEST.REQUEST;
    data: IAddUpdateLevelRequest
}
export const addLevelLoadAction = (data: IAddUpdateLevelRequest): IAddLevelLoadAction => ({
    type: IADD_LEVEL_REQUEST.REQUEST,
    data
});
export interface IAddLevelSuccessAction {
    type: IADD_LEVEL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addLevelLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddLevelSuccessAction => ({
    type: IADD_LEVEL_REQUEST.SUCCESS,
    list
});
export interface IAddLevelLoadFailedAction {
    type: IADD_LEVEL_REQUEST.FAILED;
    message: string;
}
export const addLevelLoadFailedAction = (message: string): IAddLevelLoadFailedAction => ({
    type: IADD_LEVEL_REQUEST.FAILED,
    message
});
