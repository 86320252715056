import { IGetVendorOrderByID } from "../getVendorOrderByID/getVendorOrderByIDConstants";

export enum IUPDATE_VENDOR_ORDER_REQUEST {
    REQUEST = "updateVendorOrder/UPDATE_VENDOR_ORDER_REQUEST",
    SUCCESS = "updateVendorOrder/UPDATE_VENDOR_ORDER_SUCCESS",
    FAILED = "updateVendorOrder/UPDATE_VENDOR_ORDER_FAILED"
};

export interface IUpdateVendorOrderRequest {
    token: string;
    request: IGetVendorOrderByID;
};