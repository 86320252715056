import { ById } from '../../../../react/shared/publicInterfaces';
import { IGET_JOBS_PITS_REQUEST, IJobPit } from "./getJobsPitsConstants";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGetJobsPitsLoadAction, IGetJobsPitsLoadFailedAction, IGetJobsPitsSuccessAction } from "./getJobsPitsActions";
import { IADD_JOB_PIT_REQUEST } from '../addJobPit/addJobPitConstants';
import { IUPDATE_JOB_PIT_REQUEST } from '../updateJobPit/updateJobPitConstants';
import { IAddJobPitSuccessAction } from '../addJobPit/addJobPitActions';
import { IUpdateJobPitSuccessAction } from '../updateJobPit/updateJobPitActions';

type Actions =
    | IGetJobsPitsLoadAction
    | IGetJobsPitsSuccessAction
    | IAddJobPitSuccessAction
    | IUpdateJobPitSuccessAction
    | IGetJobsPitsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetJobsPitsReducer = (state: Server<SurewayAPIResponse<ById<IJobPit>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IJobPit>>> => {
    switch (action.type) {
        case IGET_JOBS_PITS_REQUEST.REQUEST:
            return loading;

        case IGET_JOBS_PITS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_JOBS_PITS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_JOB_PIT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_JOB_PIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};