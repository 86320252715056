import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST, ISTTimesheetForApprovalGroupByDate } from "./getSTTimesheetForApprovalGroupByDateConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSTTimesheetForApprovalGroupByDateLoadAction, IGetSTTimesheetForApprovalGroupByDateLoadFailedAction, IGetSTTimesheetForApprovalGroupByDateSuccessAction, getSTTimesheetForApprovalGroupByDateLoadFailedAction, getSTTimesheetForApprovalGroupByDateLoadSuccessAction } from "./getSTTimesheetForApprovalGroupByDateActions";

export const getSTTimesheetForApprovalGroupByDateEpic: Epic = (
    action$: ActionsObservable<IGetSTTimesheetForApprovalGroupByDateLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSTTimesheetForApprovalGroupByDateSuccessAction | IGetSTTimesheetForApprovalGroupByDateLoadFailedAction> =>
    action$.ofType(IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>>(
                    END_POINTS.FIELD.DISPATCH.GET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>): IGetSTTimesheetForApprovalGroupByDateSuccessAction => {
                            return getSTTimesheetForApprovalGroupByDateLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getSTTimesheetForApprovalGroupByDateLoadFailedAction(response.message)))
                    )
            )
        );