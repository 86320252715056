import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isLoading, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, IIdName, IIdNameDisplay, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { BLACK_COLOR, BLUE_COLOR, DARK_MAROON_COLOR, DARK_ORANGE_COLOR, DARK_RED_COLOR, EXTRA_LIGHT_ORANGE_COLOR, GREEN_COLOR, LIGHT_GREEN_COLOR, LIGHT_GREY_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, PURPLE_COLOR, RED_COLOR, SECONDARY_COLOR, SKY_BLUE_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { base64ToImageUrl, callRouteWithQueryString, deleteText, fileToBase64Image, getDate, getTokenFromUrl, pageAccessCheck, PassOrFailOrNA, SUCCESS_MESSAGE, undefinedFunction, userName, YesOrNoOrNA, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { AddIcon, ArrowLeftIcon, DeleteIcon, DownloadIcon } from "../../../shared/icons";
import { IDepartments, IFieldEmployee, IFormForeman, IFormLookup, IFormLookupRequest, IHazard, IHazardControl, ITask } from "../../../../redux/field/getFormLookup/getFormLookupConstants";
import { getFormLookup } from "../../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getFormLookupLoadAction } from "../../../../redux/field/getFormLookup/getFormLookupActions";
import { LADropZone } from "../../../shared/dropZone";
import { LAThumbnailWithLink } from "../../../shared/thumbnail";
import { webConfig } from "../../../../utils/webConfig";
import { v4 as uuidv4 } from "uuid";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import { END_POINTS } from "../../../../redux/endpoints";
import LATextArea from "../../../shared/textArea";
import { FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag"
import { LACheckBox } from "../../../shared/checkBox";
import { IAddFormResponseRequest, IAddUpdateFormResponse, IAddUpdateResponses } from "../../../../redux/field/addFormResponse/addFormResponseConstants";
import { IUpdateFormResponseRequest } from "../../../../redux/field/updateFormResponse/updateFormResponseConstants";
import { addFormResponseLoadAction } from "../../../../redux/field/addFormResponse/addFormResponseActions";
import { updateFormResponseLoadAction } from "../../../../redux/field/updateFormResponse/updateFormResponseActions";
import { addFormResponse } from "../../../../redux/field/addFormResponse/addFormResponseAccessor";
import { updateFormResponse } from "../../../../redux/field/updateFormResponse/updateFormResponseAccessor";
import { IFormResponsePicture } from "../../../../redux/field/getFormResponses/getFormResponsesConstants";
import { IChecklistQuestionFile, IFormTemplateFile } from "../../../../redux/field/getForms/getFormsConstants";
import { IFormResponseByID, IFormResponseByIDCrew, IFormResponseByIDGuest, IFormResponseByIDHazardList, IFormResponseFile, IGetFormResponseByIDRequest, IResponse } from "../../../../redux/field/getFormResponseByID/getFormResponseByIDConstants";
import { getFormResponseByID } from "../../../../redux/field/getFormResponseByID/getFormResponseByIDAccessor";
import { getFormResponseByIDLoadAction } from "../../../../redux/field/getFormResponseByID/getFormResponseByIDActions";
import { IAcknowledgeForm, ICrew, IGetWSSLogInfoByID, IGetWSSLogInfoByIDRequest } from "../../../../redux/field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDConstants";
import { getWSSLogInfoByID } from "../../../../redux/field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDAccessor";
import { getWSSLogInfoByIDLoadAction } from "../../../../redux/field/workingSiteLogs/getWSSLogInfoByID/getWSSLogInfoByIDActions";
import SignatureCanvas from "react-signature-canvas";
import { LACenteredLoading } from "../../../shared/loading";
import PageSpacing from "../../../shared/pageSpacing";
import LALinkButton from "../../../shared/linkButton";
import LAFreeTextAutoComplete from "../../../shared/freeTextAutoComplete";
import { FormHelperText } from "@material-ui/core";
import { IGetFormSignatureHistory, IGetFormSignatureHistoryRequest } from "../../../../redux/field/getFormSignatureHistory/getFormSignatureHistoryConstants";
import { getFormSignatureHistory } from "../../../../redux/field/getFormSignatureHistory/getFormSignatureHistoryAccessor";
import { getFormSignatureHistoryLoadAction } from "../../../../redux/field/getFormSignatureHistory/getFormSignatureHistoryActions";
import { FormSignatureHistory } from "./formSignatureHistoryPopup";
import { IGetFormResponseVersionHistoryByID, IGetFormResponseVersionHistoryByIDRequest } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDConstants";
import { getFormResponseVersionHistoryByIDLoadAction } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDActions";
import { getFormResponseVersionHistoryByID } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDAccessor";
import { FormResponseVersionHistoryByID } from "./formResponseVersionHistoryByIDPopup";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { FormResponseAcknowledge } from "./formResponseAcknowledgePopup";
import { ISaveAcknowledge, ISaveAcknowledgeResponseRequest } from "../../../../redux/field/saveAcknowledgeResponse/saveAcknowledgeResponseConstants";
import { saveAcknowledgeResponse } from "../../../../redux/field/saveAcknowledgeResponse/saveAcknowledgeResponseAccessor";
import { saveAcknowledgeResponseLoadAction } from "../../../../redux/field/saveAcknowledgeResponse/saveAcknowledgeResponseActions";
import splitDate from "../../../shared/splitDate";
import { IGetHazardControlPlanListByDepartmentID, IGetHazardControlPlanListByDepartmentIDRequest, IHazardTypes } from "../../../../redux/field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDConstants";
import { getHazardControlPlanListByDepartmentID } from "../../../../redux/field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDAccessor";
import { getHazardControlPlanListByDepartmentIDLoadAction } from "../../../../redux/field/getHazardControlPlanListByDepartmentID/getHazardControlPlanListByDepartmentIDActions";
import MobileOffIcon from '@mui/icons-material/MobileOff';
import { FormVersionHistoryPopup } from "../formVersionHistoryPopup";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import { getFormVersionHistory } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";

const RequiredFields: string[] = ["form_ID"];

const severityOptions: IIdNameDisplay[] = [{id:1, name: "Low", display: "Low (non-serious injury or damage)"},{id:2, name: "Medium", display: "Medium (major injury or damage)"},{id:3, name: "High", display: "High (death, disaster)"} ];
const probabilityOptions: IIdNameDisplay[] = [{id:1, name: "Low", display: "Low (could at some point)"},{id:2, name: "Medium", display: "Medium (eventually)"},{id:3, name: "High", display: "High (immediately or soon)"} ];

interface IAddUpdateFormResponseStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    addRequestStatus: Server<SurewayAPIResponse<string>>;
    updateRequestStatus: Server<SurewayAPIResponse<string>>;
    saveAcknowledgeResponseStatus: Server<SurewayAPIResponse<string>>;
    // getFormResponses: Server<SurewayAPIResponse<IFormResponse[]>>;
    getFormResponseByID: Server<SurewayAPIResponse<IFormResponseByID>>;
    getWSSLogInfoByID: Server<SurewayAPIResponse<IGetWSSLogInfoByID>>;
    getFormSignatureHistory: Server<SurewayAPIResponse<IGetFormSignatureHistory[]>>;
    // getFormResponseVersionHistoryByID: Server<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
    getHazardControlPlanListByDepartmentID: Server<SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>>;
};

interface IAddUpdateFormResponseDispatchProps {
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getFormResponseByIDRequest: (data: IGetFormResponseByIDRequest) => unknown;
    getWSSLogInfoByIDRequest: (data: IGetWSSLogInfoByIDRequest) => unknown;
    // getFormResponsesRequest: (data: IGetFormResponsesRequest) => unknown;
    addRequest: (data: IAddFormResponseRequest) => unknown;
    updateRequest: (data: IUpdateFormResponseRequest) => unknown;
    saveAcknowledgeResponseRequest: (data: ISaveAcknowledgeResponseRequest) => unknown;
    getFormSignatureHistoryRequest: (data: IGetFormSignatureHistoryRequest) => unknown;
    // getFormResponseVersionHistoryByIDRequest: (data: IGetFormResponseVersionHistoryByIDRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
    getHazardControlPlanListByDepartmentIDRequest: (data: IGetHazardControlPlanListByDepartmentIDRequest) => unknown;
};

interface IAddUpdateFormResponseOwnProps {
    id?: string;
};

interface IAddUpdateFormResponseState {
    data: any;
    crew_Name: string,
    crewNamesOptions: ICrew[],
    initialCrewList: IFormResponseByIDCrew[],
    initialGuestsList: IFormResponseByIDGuest[],
    initialHazardList: IFormResponseByIDHazardList[],
    formSignatureHistoryPopup: boolean,
    formSignatureHistoryPopupType: string | undefined,
    formResponseVersionHistoryPopup: boolean,
    formSheetSectionsVersionHistoryPopup: boolean,
    formSheetTasksVersionHistoryPopup: boolean,
    formSheetHazardAssessmentVersionHistoryPopup: boolean,
    hazardAssessmentExpand: boolean;
    additionalDocuments: boolean;
    crewExpand: boolean;
    guestsExpand: boolean;
    foremanSignatureExpand: boolean;
    collapseAll: boolean;
    serverError: string;
    getFormResponseApiServerError: string;
    errors: ById<IFieldErrorKeyValue>;
    acknowledge_Required: boolean;
    acknowledge_Status?: boolean;
    acknowledge_Form?: IAcknowledgeForm;
    copyExternalLinkForGuests: boolean;
    formTemplateFiles: string | null;
    // formTemplate?: IChecklistForm;
    // optionPopup: { index: number, options: string } | undefined;
};

const AddUpdateFormResponseStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .even-row {
        background-color: #F5F5F5;
        border: 1.5px solid ${BLACK_COLOR};
    }
    
    .odd-row {
        background-color: #dddddd;
        border: 1.5px solid ${BLACK_COLOR};
    };
    
    .hazard-even-row {
        background-color: #F5F5F5;
    }
    
    .hazard-odd-row {
        background-color: #dddddd;
    };

    .hazard {
        background-color: ${EXTRA_LIGHT_ORANGE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .required {
        color: ${RED_COLOR};

        strong {
            color: ${RED_COLOR};
        }
    };

    .pull-right {
        top: 0%;
        right: 1%;
        position: absolute;
        // display: flex;
        // justify-content: flex-end;
    };

    .pull-left {
        // top: 3%;
        // left: 1%;
        // position: absolute;
        display: flex;
        justify-content: flex-start;
    };

    .flex-end {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 2%;
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .weather-container {
        text-align: center;
        color: white;
    }

    .weather-row {
        display: flex;
        justify-content: space-around;
    }

    .temp-text {
        font-size: 2em;
        color: black;
    }

    .sign {
        border: 2px solid #000;
    };

    .required-sign {
        border: 2px solid ${WARNING_COLOR};
    };

    .signature-container {
        padding: 5px;
        display: inline-block;
    }

    .signCanvas {
        border: 1px solid #ccc;
        width: 100%;
        max-width: 450px; /* Limit the maximum width */
        height: 200px;
    }

    .drivingDistance {
        font-size: 12px;
        color: ${DARK_MAROON_COLOR};
        font-weight: bold;
    }

    .lowRisk {
        color: ${GREEN_COLOR};
    }
    .mediumRisk {
        color: ${BLUE_COLOR};
    }
    .highRisk {
        color: ${RED_COLOR};
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

`;

type IAddUpdateFormResponseProps =
    RouteComponentProps
    & IAddUpdateFormResponseOwnProps
    & IAddUpdateFormResponseStoreProps
    & IAddUpdateFormResponseDispatchProps;

class AddUpdateForm extends PureComponent<IAddUpdateFormResponseProps, IAddUpdateFormResponseState> {
    signCanvas: React.RefObject<any>;
    signCanvasCrewRefs: React.RefObject<SignatureCanvas>[] = [];
    signCanvasGuestsRefs: React.RefObject<SignatureCanvas>[] = [];

    public constructor(props: IAddUpdateFormResponseProps) {
        super(props);
        this.signCanvas = React.createRef();
        this.signCanvasCrewRefs = [];
        this.signCanvasGuestsRefs = [];
        this.state = {
            data: {
                id: 0,
                form_ID: 0,
                form_Name: "",
                wsS_Log_ID: 0,
                name: userName,
                date: "",
                supervisor: "",
                wsS_ID: 0,
                location_Other: "",
                nearest_Medical_Facility: "",
                muster_Point: "",
                responses: [],
                department_ID: 0,
                task_List: [],
                hazard_List: [],
                tasks: [],
                tool_Box_Meeting: "",
                pictures: [],
                crew: [],
                guests: [],
                signature: null,
                foreman_Signed: "",
                foreman_Signed_Date: "",
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            },
            crew_Name: "",
            crewNamesOptions: [],
            initialCrewList: [],
            initialGuestsList: [],
            initialHazardList: [],
            errors: {},
            hazardAssessmentExpand: true,
            additionalDocuments: true,
            crewExpand: true,
            guestsExpand: true,
            foremanSignatureExpand: true,
            collapseAll: true,
            serverError: "",
            getFormResponseApiServerError: "",
            formSignatureHistoryPopup: false,
            formSignatureHistoryPopupType: undefined,
            formResponseVersionHistoryPopup: false,
            formSheetSectionsVersionHistoryPopup: false,
            formSheetTasksVersionHistoryPopup: false,
            formSheetHazardAssessmentVersionHistoryPopup: false,
            acknowledge_Required: false,
            copyExternalLinkForGuests: false,
            formTemplateFiles: null,
            // formTemplate: undefined,
            // optionPopup: undefined,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLookups();
        this.getFormResponseByIDData();
        this.getWSSLogInfoByIDData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateFormResponseProps): void {
        if (this.props !== prevProps) {
            // if(this.props.getFormSignatureHistory === prevProps.getFormSignatureHistory)
            if(this.props.getHazardControlPlanListByDepartmentID === prevProps.getHazardControlPlanListByDepartmentID) {
                this.setDataToState();
            }

            if(hasPayload(this.props.getFormResponseByID) && this.props.getFormResponseByID.payload.message !== SUCCESS_MESSAGE) {
                this.setState({ getFormResponseApiServerError: this.props.getFormResponseByID.payload.message });
            }

            if (this.props.addRequestStatus !== prevProps.addRequestStatus) {

                if (this.props.addRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRequestStatus.message });

                if (hasPayload(this.props.addRequestStatus) && this.props.addRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }

            if (this.props.updateRequestStatus !== prevProps.updateRequestStatus) {

                if (this.props.updateRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRequestStatus.message });

                if (hasPayload(this.props.updateRequestStatus) && this.props.updateRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }

            if (this.props.saveAcknowledgeResponseStatus !== prevProps.saveAcknowledgeResponseStatus) {

                if (this.props.saveAcknowledgeResponseStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveAcknowledgeResponseStatus.message, acknowledge_Required: false });

                if (hasPayload(this.props.saveAcknowledgeResponseStatus) && this.props.saveAcknowledgeResponseStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.getWSSLogInfoByIDData();
                    this.setState({ acknowledge_Required: false });
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, getFormResponseApiServerError, hazardAssessmentExpand, additionalDocuments, crewExpand, guestsExpand, foremanSignatureExpand, 
            collapseAll, crew_Name, crewNamesOptions, formSignatureHistoryPopup, formSignatureHistoryPopupType, formResponseVersionHistoryPopup, acknowledge_Required, 
            acknowledge_Status, acknowledge_Form, formTemplateFiles, formSheetSectionsVersionHistoryPopup, formSheetTasksVersionHistoryPopup, formSheetHazardAssessmentVersionHistoryPopup } = this.state;
        const query = queryString.parse(this.props.history.location.search);
        const { token, addRequestStatus, updateRequestStatus, getLookup, getFormSignatureHistory , getFormVersionHistory, getFormResponseByID, getWSSLogInfoByID, getHazardControlPlanListByDepartmentID} = this.props;
        const getWorkingSiteLogsRole = pageAccessCheck(this.props.token, "workingSiteLogs");
        const getFormsAdminRole = pageAccessCheck(token, "formsAdmin");
        const disabled = (getWorkingSiteLogsRole === ReadOnly) ? true : undefined;
        // const disabled =  undefined;
        const disabledByCreatedTime = data.id > 0 && data.created && new Date().getTime() > new Date(data.created).getTime() + 16 * 60 * 60 * 1000 ? true : disabled;
        const disabledSaveByQuestions = data.responses.some((obj: any) => obj.questions.some((question: any) => question.required === "Yes" && (question.answer === "" || question.answer === null)));
        const disabledSaveByQuestionsImageRequired = data.responses.some((obj: any) => obj.questions.some((question: any) => question.image_Required === "Yes" && (!question.pictures || question.pictures?.length === 0)));
        // console.log(disabledSaveByQuestionsImageRequired)
        const disabledSaveByHazardAssessment =  this.checkFormValidationForHazardAssessment();
        const disabledEdit = data.id > 0 ? true : undefined;
        const formsList = hasPayload(getLookup) ? getLookup.payload.response.forms : [];
        const fieldEmployeesList = hasPayload(getLookup) ? getLookup.payload.response.field_employees : [];
        const surewayEmployeesList = hasPayload(getLookup) ? getLookup.payload.response.sureway_employees : [];
        const departmentList = hasPayload(getLookup) ? getLookup.payload.response.form_departments : [];
        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const taskList = hasPayload(getLookup) ? getLookup.payload.response.tasks : [];
        const taskListOptions = taskList ? taskList.filter(x => data.department_ID ? x.department_ID === data.department_ID : true).map(x => x.name) : [];
        // const updatedTaskList: ITask[] =  taskList ? [{ id: -1, name: "Custom" }, ...taskList] : [];
        const hazardsList = hasPayload(getLookup) ? getLookup.payload.response.hazards?.sort((a:any, b: any) => a.type_ID > b.type_ID ? 1: -1) : [];
        // const hazardListOptions = hazardsList ? hazardsList.map(x => x.hazard) : [];
        const hazardTypesList = hasPayload(getHazardControlPlanListByDepartmentID) ? getHazardControlPlanListByDepartmentID.payload.response.types : [];
        const hazardControlPlanListOptions = hasPayload(getHazardControlPlanListByDepartmentID) ? getHazardControlPlanListByDepartmentID.payload.response.hazards : []
        const hazardListOptions = hazardControlPlanListOptions ? hazardControlPlanListOptions : [];
        const hazardControlPlansList = hasPayload(getLookup) ? getLookup.payload.response.hazard_controls : [];
        const answerCrewSignatureNumbers = data.crew && data.crew.filter((record:any) => record.signature).length;
        const answerGuestsSignatureNumbers = data.guests && data.guests.filter((record:any) => record.signature).length;
        const onForm = (event: unknown, value: IIdName): any => this.handleFormName("form_ID", value ? value : null);
        const onWSS = (value: string): void => this.handleLocationChange(value != null ? value : "");
        const onSupervisor = (event: unknown, value: IFormForeman): void => this.handleChange("supervisor", value !== null ? value.upn : "");
        const onDepartment = (event: unknown, value: IDepartments): void => this.handleChange("department_ID", value !== null ? value.id.toString() : data.department_ID.toString());
        const crewSignatureBase64 = data.crew_Sign_In_Upload && (data.crew_Sign_In_Upload.base64String !== null && data.crew_Sign_In_Upload.base64String !== "" ? data.crew_Sign_In_Upload.base64String : webConfig.imgApiBaseUrl + data.crew_Sign_In_Upload.name);
        const onCrewSignatureImageName = (name: string, value: string): void => this.handleCrewImageName(name ? name : "", value);
        const formSignatureHistorypopupData = hasPayload(getFormSignatureHistory) ? getFormSignatureHistory.payload.response : [];
        // const formResponseVersionHistoryPopupData = hasPayload(getFormResponseVersionHistoryByID) ? Object.values(getFormResponseVersionHistoryByID.payload.response) : [];
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];
        const onFormUpdated = (checked: boolean): void => this.handleChange("form_Updated", checked ? "Yes" : "No");

        if(getLookup.kind === STATUS_ENUM.LOADING || getWSSLogInfoByID.kind === STATUS_ENUM.LOADING || getFormResponseByID.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }
        if(data.id > 0 && getHazardControlPlanListByDepartmentID.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }
        if(getFormResponseApiServerError.length > ZEROTH ) {
            return <LAErrorBox text={getFormResponseApiServerError} />
        }
        // console.log(this.state)

        return (
            <AddUpdateFormResponseStyles>
                <PageSpacing title="Form Response" description="Forms - Form Response" fixedSpaceOnSmallerScreens={true}>
                    {(getFormsAdminRole !== NotApplicable || getWorkingSiteLogsRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={6}>
                                <LAButton
                                    // label={query && query.logId && Number(query.logId) > 0 ? "Back to Site Log" : "Back to List"}
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                                <LALinkButton onClick={() => this.handleFormResponseVersionHistory(data, "Form_Sheet")} label="Version History"></LALinkButton>
                            </LAGridItem>
                            }
                            {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                                <LALinkButton onClick={() => this.handleDownloadPDF(data)} label="Download PDF"></LALinkButton>
                            </LAGridItem>
                            }

                            <LAGridItem xs={12} sm={12} className="text-center">
                                {!query.logId && !query.form_ID && <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}FORM RESPONSE</h2>}
                                {query.logId && <h2>{data.form_Name}</h2>}
                                <hr />
                            </LAGridItem>

                            {!query.logId && !query.form_ID && <LAGridItem xs={12} sm={6} md={3}>
                                {data.id === 0 ? <LAAutoComplete
                                    multiple={false}
                                    option={formsList}
                                    disabled={disabledByCreatedTime || disabledEdit}
                                    onChange={onForm}
                                    autoHighlight={true}
                                    getOptionLabel="form_Name"
                                    dropDownPlaceHolder="Form"
                                    filterSelectedOptions={true}
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["form_ID"] ? errors["form_ID"].message : undefined}
                                    value={data.form_ID && formsList ? formsList.find(q => q.id === data.form_ID) : null}
                                    defaultValue={data.form_ID && formsList ? formsList.find(q => q.id === data.form_ID) : null}
                                /> :
                                <LATextField
                                    name="form_Name"
                                    label="Form"
                                    fullWidth={true}
                                    disabled={true}
                                    variant="outlined"
                                    errorText={errors["form_Name"] ? errors["form_Name"].message : undefined}
                                    onChange={this.handleChange}
                                    value={data.form_Name ?? ""}
                                />}
                            </LAGridItem>}

                            {query.logId && <LAGridItem xs={12}>
                                {/* <LAExpansionPanel color={BLUE_COLOR} textColor={WHITE_COLOR} label="" expanded={true} onClick={undefinedFunction}> */}
                                    <LAGrid>
                                        <LAGridItem xs={12} >
                                            <strong>Working Site: {data.working_Site}</strong>
                                        </LAGridItem> 
                                        <LAGridItem xs={12} >
                                            <strong>Working Site Log ID: {query.logId}</strong>
                                        </LAGridItem>
                                        <LAGridItem xs={12} >
                                            <strong>Job: {data.job}</strong>
                                        </LAGridItem> 
                                        <LAGridItem xs={12} >
                                            <strong>Work Description: {data.work_Description}</strong>
                                        </LAGridItem> 
                                        <LAGridItem xs={12} >
                                            <strong>Prime Contractor: {data.prime_Contractor}</strong>
                                        </LAGridItem>
                                    </LAGrid>
                                {/* </LAExpansionPanel> */}
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3} >
                                <LAAutoComplete
                                    disabled={disabledByCreatedTime || disabledEdit}
                                    multiple={false}
                                    option={surewayEmployeesList}
                                    autoHighlight={true}
                                    onChange={onSupervisor}
                                    filterSelectedOptions={true}
                                    errorText={errors["supervisor"] ? errors["supervisor"].message : undefined}
                                    getOptionLabel="display_Name"
                                    dropDownPlaceHolder="Foreman/Supervisor"
                                    selectionRemove={undefinedFunction}
                                    value={data.supervisor ? surewayEmployeesList.find(q => q.upn === data.supervisor) : null}
                                    defaultValue={data.supervisor ? surewayEmployeesList.find(q => q.upn === data.supervisor) : null}
                                />
                            </LAGridItem>

                            {data.form_ID !== 19 && <LAGridItem xs={12} sm={6} md={3}>
                                <DevExtremePopupLookup
                                    data={wssList}
                                    id="wss-search"
                                    placeHolder="Location"
                                    disabled={disabledByCreatedTime || disabledEdit}
                                    displayExp="site"
                                    returnExp="wsS_ID"
                                    name="wsS_ID"
                                    errorName="wsS_ID"
                                    errors={errors}
                                    currentVal={data.wsS_ID}
                                    onClick={onWSS}
                                    columns={[
                                        { name: "site", caption: "Site", type: "string" },
                                        { name: "job", caption: "Job", type: "string" },
                                        { name: "muster_Point", caption: "Muster Point", type: "string" },
                                    ]}
                                />
                            </LAGridItem>}

                            {(data.location_Other || data.wsS_ID === 9999) && data.form_ID !== 19 && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    name="location_Other"
                                    label="Other Location"
                                    fullWidth={true}
                                    disabled={disabledByCreatedTime}
                                    variant="outlined"
                                    errorText={errors["location_Other"] ? errors["location_Other"].message : undefined}
                                    onChange={this.handleChange}
                                    value={data.location_Other ?? ""}
                                />
                            </LAGridItem>}
                            {data.form_ID === 19 && <LAGridItem xs={12} sm={6} md={3} >
                                {/* <strong>Nearest Medical Facility: {data.nearest_Medical_Facility}</strong> */}
                                <LATextField
                                    name="nearest_Medical_Facility"
                                    label="Urgent Care Facility"
                                    fullWidth={true}
                                    disabled={true}
                                    variant="outlined"
                                    value={data.nearest_Medical_Facility ?? ""}
                                    onChange={this.handleChange}
                                />
                                <span className="drivingDistance">Driving Distance: {data.nearest_Medical_Facility_Distance} </span>
                            </LAGridItem>}

                            {<LAGridItem xs={12} sm={6} md={3}>
                                {/* <strong>Muster Point: {data.muster_Point}</strong> */}
                                <LATextField
                                    name="muster_Point"
                                    label="Muster Point"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabledByCreatedTime || data.form_ID !== 19}
                                    value={data.muster_Point ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}

                            {data.form_ID !== 19 && <LAGridItem xs={12} sm={6} md={3}>
                                {/* <strong>Muster Point: {data.muster_Point}</strong> */}
                                <LATextField
                                    name="job"
                                    label="Job"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    value={data.job ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}

                            {query.logId && <LAGridItem xs={12} sm={6} md={3} >
                                <LATextField
                                    name="date"
                                    label="Date"
                                    fullWidth={true}
                                    disabled={true}
                                    variant="outlined"
                                    onChange={undefinedFunction}
                                    value={getDate(false, data.date)}
                                />
                            </LAGridItem>}

                            {data.requires_Toolbox === "Yes" && <LAGridItem xs={12} sm={6} md={3}>
                                <LATextArea
                                    minRows={1}
                                    rowsMax={4}
                                    name="tool_Box_Meeting"
                                    label="Tool Box Meeting"
                                    fullWidth={true}
                                    disabled={disabledByCreatedTime}
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    value={data.tool_Box_Meeting ?? ""}
                                />
                            </LAGridItem>}

                            {formTemplateFiles && data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Form Documents:</strong> <br />
                                {formTemplateFiles.split(";").map((x: string) => 
                                    <>
                                        <LALinkButton
                                            label={x}
                                            onClick={() => this.downloadFormAttachmentEndpoint(x, data.form_ID, "Template")}
                                        />
                                        <br/>
                                    </>
                                )}
                            </LAGridItem>}

                            {data.id > 0 && data.form_Documents && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Form Documents:</strong> <br />
                                {data.form_Documents.split(";").map((x: string) => 
                                    <>
                                        <LALinkButton
                                            label={x}
                                            onClick={() => this.downloadFormAttachmentEndpoint(x, data.form_ID, "Template")}
                                        />
                                        <br/>
                                    </>
                                    
                                )}
                            </LAGridItem>}

                            {data.id !== 0 && <LAGridItem xs={12} >
                                <LAButton
                                    className="collapse-btn"
                                    label={collapseAll ? "Collapse All" : "Expand All" }
                                    onClick={this.handleCollapseAll}
                                />
                            </LAGridItem>}

                            {data.responses && data.responses.length > 0 && data.responses.map((x:any, idx: number) => {
                                // #region Quetions
                                const answerNumbers = x.questions.filter((record:any) => record.answer).length;
                                const disableAdminSection = (x.section === "Admin" && getFormsAdminRole === NotApplicable) ? true : undefined;
                                // console.log(x.questions)
                                return <LAGridItem xs={12} >
                                    {data.id > 0 && <>
                                        <LALinkButton className="flex-end" onClick={() => this.handleFormResponseVersionHistory(data, "Form_Sheet_Sections")} label="Version History - Sections"></LALinkButton>
                                        <br /></>
                                    }
                                    <LAExpansionPanel  color={GREEN_COLOR} textColor={WHITE_COLOR} label={x.section} questionNumbers={x.questions.length} answerNumbers={answerNumbers} expanded={x.expand} onClick={() => this.handleExpand(idx)} key={idx}> 
                                        <LAGridItem xs={12} key={idx}>
                                            {x.questions && x.questions.length > 0 && x.questions.sort((a: any, b: any) => a.display_Order - b.display_Order).map((q:any, qIdx: number) => {
                                                const onFreeTextAnswerChange = (name: string, value: string) => this.handleQuestionChange("answer", value, qIdx, idx);
                                                const onDateAnswerChange = (name: string, value: string) => this.handleQuestionChange("answer", value, qIdx, idx);
                                                const onDateTimeAnswerChange = (name: string, value: string) => this.handleQuestionChange("answer", value, qIdx, idx);
                                                const onDropdownAnswerChange = (event: unknown, value: IIdName) => this.handleQuestionChange("answer", value !== null ? value.name : q.answer, qIdx, idx);
                                                const onRadioAnswerChange = (event: unknown, value: any) => this.handleQuestionChange("answer", value !== null ? value : q.answer, qIdx, idx);
                                                const onFlaggedChange = () => this.handleQuestionChange("flagged", q.flagged === "Yes" ? "No" : "Yes", qIdx, idx);
                                                const onCommentsChange = (name: string, value: string) => this.handleQuestionChange("comments", value, qIdx, idx);
                                                const DropdownAnswerOptions = q.options?.split(',').map((x:string, index: number) => ({
                                                    id: index,
                                                    name: x.trim(), 
                                                }));
                                                const checkboxesAnswerOptions = q.options ? q.options.split(','): [];
                                                const answerArray = q.answer ? q.answer.split(',') : [];
                                                const otherForCheckboxAnswer = q.type === "Checkbox" ? answerArray.filter((x:any) => !checkboxesAnswerOptions.includes(x)) : [];
                                                
                                                // console.log(answerArray)
                                                // console.log(otherForCheckboxAnswer)

                                                return (
                                                    <LAGridItem xs={12} key={qIdx}>
                                                        <LAPaperWithLessPadding className={qIdx % 2 ? "odd-row" : "even-row"} key={qIdx}>
                                                                <LAGrid>
                                                                    <LAGridItem xs={12} >
                                                                        <strong>{qIdx + 1}: {q.question}</strong>
                                                                    </LAGridItem>
                                                                    <LAGridItem xs={12} sm={6} md={3}>
                                                                        <strong>Answer:</strong>
                                                                        {q.type === "Free Text" && <LATextArea
                                                                            minRows={1}
                                                                            rowsMax={4}
                                                                            name="answer"
                                                                            label=""
                                                                            fullWidth={true}
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            indexPosition={qIdx}
                                                                            variant="outlined"
                                                                            onChange={onFreeTextAnswerChange}
                                                                            value={q.answer ?? ""}
                                                                        />
                                                                        // <LATextField
                                                                        //     name="answer"
                                                                        //     label=""
                                                                        //     fullWidth={true}
                                                                        //     variant="outlined"
                                                                        //     disabled={disabled}
                                                                        //     indexPosition={qIdx}
                                                                        //     value={q.answer ?? ""}
                                                                        //     onChange={onFreeTextAnswerChange}
                                                                        // />
                                                                        }
                                                                        {q.type === "Date" && 
                                                                            <LATextField
                                                                                id="answer"
                                                                                value={q.answer ? splitDate(q.answer) : ""}
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                disabled={disabledByCreatedTime || disableAdminSection}
                                                                                name="answer"
                                                                                indexPosition={qIdx}
                                                                                label=""
                                                                                onChange={onDateAnswerChange}
                                                                                type="date"
                                                                            />
                                                                        }
                                                                        {q.type === "Datetime" && <LATextField
                                                                            fullWidth={true}
                                                                            name="answer"
                                                                            variant="outlined"
                                                                            indexPosition={qIdx}
                                                                            onChange={onDateTimeAnswerChange}
                                                                            label=""
                                                                            value={q.answer ?? ""}
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            type="datetime-local"
                                                                        />}
        
                                                                        {q.type === "Dropdown" && <LAAutoComplete
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            multiple={false}
                                                                            option={DropdownAnswerOptions}
                                                                            autoHighlight={true}
                                                                            indexPosition={qIdx}
                                                                            onChange={onDropdownAnswerChange}
                                                                            filterSelectedOptions={true}
                                                                            getOptionLabel="name"
                                                                            dropDownPlaceHolder=""
                                                                            selectionRemove={undefinedFunction}
                                                                            value={q.answer && DropdownAnswerOptions ? DropdownAnswerOptions.find((y:any) => y.name.trim() === q.answer.trim()) : null}
                                                                            defaultValue={q.answer && DropdownAnswerOptions ? DropdownAnswerOptions.find((y:any) => y.name.trim() === q.answer.trim()) : null}
                                                                        />}
        
                                                                        {(q.type === "Yes/No/NA") && 
                                                                            <RadioGroup className="view-btn" row aria-label="Yes or No or NA"  name="radioGroup" value={q.answer} onChange={onRadioAnswerChange}>
                                                                                {YesOrNoOrNA.map((item: any, index: number) => (
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        value={item.name}
                                                                                        disabled={disabledByCreatedTime || disableAdminSection}
                                                                                        control={<Radio />}
                                                                                        label={item.name}
                                                                                    />
                                                                                ))}
                                                                            </RadioGroup>
                                                                        }
        
                                                                        {(q.type === "Pass/Fail/NA") && 
                                                                            <RadioGroup className="view-btn" row aria-label="Pass or Fail or NA"  name="radioGroup" value={q.answer} onChange={onRadioAnswerChange}>
                                                                                {PassOrFailOrNA.map((item: any, index: number) => (
                                                                                    <FormControlLabel
                                                                                        key={index}
                                                                                        value={item.name}
                                                                                        control={<Radio />}
                                                                                        disabled={disabledByCreatedTime || disableAdminSection}
                                                                                        label={item.name}
                                                                                    />
                                                                                ))}
                                                                            </RadioGroup>
                                                                        }
        
                                                                        {q.type === "Checkbox" && 
                                                                            checkboxesAnswerOptions.length > 0 && checkboxesAnswerOptions.map((x:any, index:number) => {
                                                                                const onAnswerCheckboxCheck = (checked: boolean) => this.handleQuestionCheckboxesAnswerChange("answer", checked, x, qIdx, idx);
                                                                                return (
                                                                                    <LAGridItem xs={12} key={index}>
                                                                                        <LACheckBox
                                                                                            label={x}
                                                                                            key={index}
                                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                                            onChange={onAnswerCheckboxCheck}
                                                                                            value={answerArray?.includes(x) ? true : false}
                                                                                        />
                                                                                    </LAGridItem>
                                                                                )
                                                                            })
                                                                        }
                                                                        
                                                                        {q.type === "Checkbox" && 
                                                                            <LAFreeTextAutoComplete 
                                                                                value={otherForCheckboxAnswer} 
                                                                                onChange={(newValue: string[]) => this.handleOtherForCheckboxAnswerChange(newValue, qIdx, idx, q.options)} 
                                                                                label="Others" 
                                                                                disabled={disabledByCreatedTime || disableAdminSection}
                                                                                autoHighlight={true}
                                                                                placeholder="Type and press Enter"
                                                                            />
                                                                        }
        
                                                                        {q.type === "Field Employees" && <LAAutoComplete
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            multiple={false}
                                                                            option={fieldEmployeesList}
                                                                            autoHighlight={true}
                                                                            indexPosition={qIdx}
                                                                            onChange={onDropdownAnswerChange}
                                                                            filterSelectedOptions={true}
                                                                            getOptionLabel="name"
                                                                            dropDownPlaceHolder=""
                                                                            selectionRemove={undefinedFunction}
                                                                            value={q.answer && fieldEmployeesList ? fieldEmployeesList.find((x:any) => x.name === q.answer) : null}
                                                                            defaultValue={q.answer && fieldEmployeesList ? fieldEmployeesList.find((x:any) => x.name === q.answer) : null}
                                                                        />}
        
                                                                        {q.type === "Sureway Employees" && <LAAutoComplete
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            multiple={false}
                                                                            option={surewayEmployeesList}
                                                                            autoHighlight={true}
                                                                            indexPosition={idx}
                                                                            onChange={onDropdownAnswerChange}
                                                                            filterSelectedOptions={true}
                                                                            getOptionLabel="name"
                                                                            dropDownPlaceHolder=""
                                                                            selectionRemove={undefinedFunction}
                                                                            value={q.answer && surewayEmployeesList ? surewayEmployeesList.find((x:any) => x.name === q.answer) : null}
                                                                            defaultValue={q.answer && surewayEmployeesList ? surewayEmployeesList.find((x:any) => x.name === q.answer) : null}
                                                                        />}
                                                                        {!q.answer && q.required === "Yes" && <FormHelperText className="required-text">Required</FormHelperText> }
                                                                    </LAGridItem>
        
                                                                    <LAGridItem xs={1}>
                                                                        <strong>Flagged</strong><br />
                                                                        <Tooltip title="flagged" arrow>
                                                                            <IconButton
                                                                                disabled={disabledByCreatedTime || disableAdminSection}
                                                                                onClick={onFlaggedChange}
                                                                                style={{
                                                                                    color: q.flagged === "Yes" ? "red" : "gray",
                                                                                }}
                                                                            >
                                                                                <FlagIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </LAGridItem>
        
                                                                    <LAGridItem xs={12} sm={6} md={3}>
                                                                        <strong>Comments</strong>
                                                                        <LATextArea
                                                                            minRows={2}
                                                                            rowsMax={4}
                                                                            name="comments"
                                                                            label=""
                                                                            fullWidth={true}
                                                                            disabled={disabledByCreatedTime || disableAdminSection}
                                                                            indexPosition={qIdx}
                                                                            variant="outlined"
                                                                            onChange={onCommentsChange}
                                                                            value={q.comments ?? ""}
                                                                        />
                                                                    </LAGridItem>
        
                                                                    <LAGridItem xs={12} sm={6} md={5}>
                                                                        <strong className="text-center">Images</strong>
                                                                        <LADropZone onFileDrop={(e: any) => this.handleQuestionImageUpload(e, qIdx, idx)} />
                                                                        {q.image_Required === "Yes" && (!q.pictures || q.pictures?.length === 0) && <FormHelperText className="required-text">Required</FormHelperText>}
                                                                        <br />
                                                                        <LAGrid>
                                                                            {q.pictures && q.pictures
                                                                                .map((q:IFormResponsePicture, ind: number) => {
                                                                                    const onImgDelete = (): void => this.onQuestionImgDelete(q.name, ind, qIdx, idx);
                                                                                    const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                                                    const onImageName = (name: string, value: string): void => this.handleQuestionImageName(name ? name : "", value, ind, qIdx, idx);
                                                                                    // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                    
                                                                                    return (
                                                                                        <LAGridItem xs={6} sm={6} key={ind} className="text-center">
                                                                                            <LAPaperWithLessPadding key={ind}>
                                                                                                <LAThumbnailWithLink
                                                                                                    alt={q.name}
                                                                                                    key={ind}
                                                                                                    url={base64}
                                                                                                    id={(ind + "_").toString()}
                                                                                                />
                    
                                                                                                <LATextField
                                                                                                    className="mt-2"
                                                                                                    label="Description"
                                                                                                    fullWidth={true}
                                                                                                    variant="outlined"
                                                                                                    name="description"
                                                                                                    disabled={disabledByCreatedTime}
                                                                                                    value={q.description}
                                                                                                    onChange={onImageName}
                                                                                                    errorText={
                                                                                                        q.description.length === 0 ?
                                                                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                                            : undefined
                                                                                                    }
                                                                                                />
                    
                                                                                                <LAIconButton
                                                                                                    key={ind}
                                                                                                    label="Delete"
                                                                                                    disabled={disabledByCreatedTime}
                                                                                                    onClick={onImgDelete}
                                                                                                    icon={<DeleteIcon />}
                                                                                                />
                                                                                            </LAPaperWithLessPadding>
                                                                                        </LAGridItem>
                                                                                    );
                    
                                                                                })}
                                                                        </LAGrid>
                                                                    </LAGridItem>
                                                                </LAGrid>
        
                                                                {((q.admin_Files && q.admin_Files.length > 0) || (q.admin_Pictures && q.admin_Pictures.length > 0)) &&
                                                                    <LAGridItem xs={12} className="text-center">
                                                                        <hr />
                                                                        {/* <strong className="text-center">For References</strong> */}
                                                                    </LAGridItem>
                                                                }
                                                                {((q.admin_Files && q.admin_Files.length > 0) || (q.admin_Pictures && q.admin_Pictures.length > 0)) &&    
                                                                    <LAPaperWithLessPadding>
                                                                        <LAGrid>
                                                                            {q.admin_Files && q.admin_Files.length > 0 && <LAGridItem xs={12} sm={6} md={6}>
                                                                                <strong className="text-center">Admin Attachments</strong>
                                                                                {/* <LADropZone onFileDrop={(e:any) => this.fileUpload(e, idx)} />
                                                                                <br /> */}
                                                                                <LAGrid>
                                                                                    {(q.admin_Files !== null) && q.admin_Files.length > 0 && 
                                                                                        q.admin_Files.map((att:any, ind:any) => {
                                                                                            // const onFileDelete = (): void => this.onFileDelete(ind, idx);
                                                                                            // console.log(q, x)
                                                                                            const onDownload = (): void => this.handleDownload(att, q.form_Question_ID);
            
                                                                                            return (
                                                                                                <LAGridItem xs={12} sm={4} md={3} key={ind} className="text-center">
                                                                                                    <LAPaperWithLessPadding key={ind}>
                                                                                                        <span>{att.name}</span>
                                                                                                        <br />
                                                                                                        <LAIconButton
                                                                                                            name="file"
                                                                                                            label={q.name}
                                                                                                            id={(ind).toString()}
                                                                                                            onClick={onDownload}
                                                                                                            icon={<DownloadIcon />}
                                                                                                        />
            
                                                                                                        {/* <LAIconButton
                                                                                                            key={ind}
                                                                                                            label="Delete"
                                                                                                            icon={<DeleteIcon />}
                                                                                                            onClick={undefinedFunction}
                                                                                                        /> */}
                                                                                                    </LAPaperWithLessPadding>
                                                                                                </LAGridItem>
                                                                                            )
                                                                                        })}
                                                                                </LAGrid>
            
                                                                            </LAGridItem>}
            
                                                                            {q.admin_Pictures && q.admin_Pictures.length > 0 && <LAGridItem xs={12} sm={6} md={6}>
                                                                                <strong className="text-center">Admin Images</strong>
                                                                                {/* <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e, idx)} />
                                                                                <br /> */}
                                                                                <LAGrid>
                                                                                    {q.admin_Pictures && q.admin_Pictures.length > 0 && q.admin_Pictures
                                                                                        .map((q:IFormResponsePicture, ind: number) => {
                                                                                            // const onImgDelete = (): void => this.onImgDelete(q.name, ind, idx);
                                                                                            const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                                                            // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                                                            // const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind, idx);
                            
                                                                                            return (
                                                                                                <LAGridItem xs={6} sm={3} key={ind} className="text-center">
                                                                                                    <LAPaperWithLessPadding key={ind}>
                                                                                                        <LAThumbnailWithLink
                                                                                                            alt={q.name}
                                                                                                            key={ind}
                                                                                                            url={base64}
                                                                                                            id={(ind + "_").toString()}
                                                                                                        />
                            
                                                                                                        <LATextField
                                                                                                            className="mt-2"
                                                                                                            label="Description"
                                                                                                            fullWidth={true}
                                                                                                            variant="outlined"
                                                                                                            name="description"
                                                                                                            disabled={true}
                                                                                                            value={q.description}
                                                                                                            onChange={undefinedFunction}
                                                                                                            errorText={
                                                                                                                q.name.length === 0 ?
                                                                                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                                                    : undefined
                                                                                                            }
                                                                                                        />
                            
                                                                                                        {/* <LAIconButton
                                                                                                            key={ind}
                                                                                                            label="Delete"
                                                                                                            disabled={disabled}
                                                                                                            onClick={onImgDelete}
                                                                                                            icon={<DeleteIcon />}
                                                                                                        /> */}
                                                                                                    </LAPaperWithLessPadding>
                                                                                                </LAGridItem>
                                                                                            );
                            
                                                                                        })}
                                                                                </LAGrid>
                                                                            </LAGridItem>}
                                                                        </LAGrid>
                                                                    </LAPaperWithLessPadding>
                                                                }
                                                        </LAPaperWithLessPadding>
                                                        <br />
                                                    </LAGridItem>
                                                )
                                            })}
                                        </LAGridItem>
                                    </LAExpansionPanel>
                                </LAGridItem>
                            })}

                            {/* old hazard assessment */}
                            {data.hazard_Assessment === "Yes" && data.id > 0 && !data.department_ID && <LAGridItem xs={12} >
                                <LAExpansionPanel color={ORANGE_COLOR} textColor={WHITE_COLOR} label="Hazard Assessment" expanded={hazardAssessmentExpand} onClick={() => this.handleSectionExpand("hazardAssessmentExpand")}> 
                                    <LAGrid>
                                        {data.tasks && data.tasks.length > 0 && data.tasks?.map((x : any, taskIndex: number) => {
                                            const onTaskChange = (event: unknown, value: IIdName) => this.handleTaskInfoChange("task_ID", value !== null ? value.id : x.task_ID, taskIndex);
                                            const onCustomTaskChange = (name: string, value: string) => this.handleTaskInfoChange("custom_Task", value !== null ? value : x.custom_Task, taskIndex);
                                            const onTaskFlaggedChange =  () => this.handleTaskInfoChange("flagged", x.flagged === "Yes" ? "No" : "Yes", taskIndex); 
                                            const onTaskCommentsChange = (name: string, value: string) => this.handleTaskInfoChange("comments", value, taskIndex);
                                            const onAddHazard = () => this.handleAddHazardPerTask(taskIndex);
                                            const onDeleteTask= () => this.handleDeleteTask(taskIndex);
                                            // const onCustomClick = () : void => this.handleTaskInfoChange("customClick", "Yes" , taskIndex);
                                            // #region Hazard Assessment Old
                                            return <LAGridItem xs={12} key={taskIndex}>                     
                                                <LAPaperWithLessPadding className={taskIndex % 2 ? "odd-row" : "even-row"} key={taskIndex}>
                                                    <LAGrid key={taskIndex}>
                                                        <LAGridItem xs={12}>
                                                            <LAIconButton
                                                                key={taskIndex}
                                                                className="pull-right"
                                                                label="Delete Task"
                                                                addLabel={true}
                                                                disabled={disabledByCreatedTime}
                                                                onClick={onDeleteTask}
                                                                icon={<DeleteIcon color={RED_COLOR} />}
                                                            />
                                                        </LAGridItem>
                                                        <br /><br />
                                                        <LAGridItem xs={12} sm={6} md={2}>
                                                            <strong>Task</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime}
                                                                multiple={false}
                                                                option={taskList}
                                                                autoHighlight={true}
                                                                style={{backgroundColor: LIGHT_GREEN_COLOR}}
                                                                onChange={onTaskChange}
                                                                filterSelectedOptions={true}
                                                                indexPosition={taskIndex}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder=""
                                                                selectionRemove={undefinedFunction}
                                                                // errorText={!(x.task_ID > 0) ? "Required" : undefined}
                                                                value={x.task_ID && taskList ? taskList.find(q => q.id === x.task_ID) : null}
                                                                defaultValue={x.task_ID && taskList ? taskList.find(q => q.id === x.task_ID) : null}
                                                            />
                                                            {!(x.task_ID > 0) && <FormHelperText className="required-text">Required</FormHelperText> }
                                                            {/* <LALinkButton
                                                                label="Custom"
                                                                onClick={onCustomClick}
                                                            /> */}
                                                        </LAGridItem>
                                                        {x.task_ID === 15 && <LAGridItem xs={12} sm={6} md={2}>
                                                            <strong>Custom Task</strong>
                                                            <LATextField
                                                                name="custom_Task"
                                                                label=""
                                                                fullWidth={true}
                                                                disabled={disabledByCreatedTime}
                                                                variant="outlined"
                                                                indexPosition={taskIndex}
                                                                value={x.custom_Task ?? ""}
                                                                // errorText={!(x.custom_Task) ? "Required" : undefined}
                                                                onChange={onCustomTaskChange}
                                                            />
                                                        </LAGridItem>}
                                                        <LAGridItem xs={12} sm={6} md={2}>
                                                            <strong>Comments</strong>
                                                            <LATextArea
                                                                minRows={2}
                                                                rowsMax={4}
                                                                name="comments"
                                                                label=""
                                                                fullWidth={true}
                                                                disabled={disabledByCreatedTime}
                                                                indexPosition={taskIndex}
                                                                variant="outlined"
                                                                onChange={onTaskCommentsChange}
                                                                value={x.comments ?? ""}
                                                            />
                                                        </LAGridItem>
                                                        <LAGridItem xs={1}>
                                                            <strong>Flagged</strong><br />
                                                            <Tooltip title="flagged" arrow>
                                                                <IconButton
                                                                    disabled={disabledByCreatedTime}
                                                                    onClick={onTaskFlaggedChange}
                                                                    style={{
                                                                        color: x.flagged === "Yes" ? "red" : "gray",
                                                                    }}
                                                                >
                                                                    <FlagIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={12} md={5} className="text-center">
                                                            <strong className="text-center">Upload Images</strong>
                                                            <LADropZone onFileDrop={(e: any) => this.handleTaskImageUpload(e, taskIndex)} />
                                                            <br />
                                                            <LAGrid>
                                                                {x.pictures && x.pictures
                                                                    .map((q:IFormResponsePicture, ind: number) => {
                                                                        const onImgDelete = (): void => this.onTaskImgDelete(q.name, ind, taskIndex);
                                                                        const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                                        // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                                        const onImageName = (name: string, value: string): void => this.handleTaskImageName(name, value, ind, taskIndex);
        
                                                                        return (
                                                                            <LAGridItem xs={6} sm={3} key={ind} className="text-center">
                                                                                <LAPaperWithLessPadding key={ind}>
                                                                                    <LAThumbnailWithLink
                                                                                        alt={q.name}
                                                                                        key={ind}
                                                                                        url={base64}
                                                                                        id={(ind + "_").toString()}
                                                                                    />
        
                                                                                    <LATextField
                                                                                        className="mt-2"
                                                                                        label="Description"
                                                                                        fullWidth={true}
                                                                                        variant="outlined"
                                                                                        name="description"
                                                                                        disabled={disabledByCreatedTime}
                                                                                        value={q.description}
                                                                                        onChange={onImageName}
                                                                                        errorText={
                                                                                            q.name.length === 0 ?
                                                                                                FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                                : undefined
                                                                                        }
                                                                                    />
        
                                                                                    <LAIconButton
                                                                                        key={ind}
                                                                                        label="Delete"
                                                                                        disabled={disabledByCreatedTime}
                                                                                        onClick={onImgDelete}
                                                                                        icon={<DeleteIcon />}
                                                                                    />
                                                                                </LAPaperWithLessPadding>
                                                                            </LAGridItem>
                                                                        );
        
                                                                    })}
                                                            </LAGrid>
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} >
                                                            <LAPaperWithPadding removeborder={true} className={taskIndex % 2 ? "hazard-odd-row" : "hazard-even-row"} key={taskIndex}>
                                                                {/* <h3 className="text-center">Hazards</h3> */}
                                                            
                                                                {x.hazards && x.hazards.length > 0 && x.hazards.map((h : any, hazardIndex: number) => {
                                                                    const onDeleteHazard= () => this.handleDeleteHazard(hazardIndex, taskIndex);
                                                                    const onHazardChange = (event: unknown, value: IHazard) => this.handleHazardInfoChange("hazard_ID", value !== null ? value.id : h.hazard_ID, hazardIndex, taskIndex);
                                                                    // const onCustomHazardChange = (name: string, value: string) => this.handleHazardInfoChange("custom_Hazard", value !== null ? value : h.custom_Hazard, hazardIndex, taskIndex);
                                                                    const onSeverityChange = (event: unknown, value: IIdName): void => this.handleHazardInfoChange("severity", value !== null ? value.name : h.severity, hazardIndex, taskIndex);
                                                                    const onProbabilityChange = (event: unknown, value: IIdName): void => this.handleHazardInfoChange("probability", value !== null ? value.name : h.probability, hazardIndex, taskIndex);
                                                                    const onControlPlanChange = (event: unknown, values: IHazardControl[]): Promise<void> => this.handleHazardControlPlanChange(event, values, hazardIndex, taskIndex);
                                                                    const onControlPlanRemove = (value: any): Promise<void> => this.handleHazardControlPlanRemove(value, hazardIndex, taskIndex);
                                                                    const onCustomHazardControlChange = (name: string, value: string) => this.handleHazardInfoChange("custom_Hazard_Control", value !== null ? value : h.custom_Hazard, hazardIndex, taskIndex);
                                                                    const controlPlanOptions = hazardControlPlansList && h.hazard_ID ? hazardControlPlansList.filter(x => x.hazard_IDS?.split(",").map(Number).includes(h.hazard_ID)) : [];
                                                                    const riskResult = this.getRiskResult(h.probability, h.severity);
                                                                    // const customControlPlans =  h.custom_Hazard_Control ? h.custom_Hazard_Control.split(',') : [];

                                                                    return <LAGridItem xs={12} key={hazardIndex} className="mt-3">
                                                                        <LAPaperWithPadding className={"hazard"} key={hazardIndex}>
                                                                            <LAGrid>
                                                                                <LAGridItem xs={12}>
                                                                                    <strong className={riskResult==="Low" ? "lowRisk" : riskResult==="Medium" ? "mediumRisk" : riskResult==="High" ? "highRisk" : ""}>Risk: {riskResult}</strong>
                                                                                    <LAIconButton
                                                                                        icon={<DeleteIcon color={RED_COLOR} />}
                                                                                        className="pull-right"
                                                                                        label="Delete Hazard"
                                                                                        addLabel={true}
                                                                                        disabled={disabledByCreatedTime}
                                                                                        onClick={onDeleteHazard}
                                                                                    />
                                                                                </LAGridItem>
                                                                                <br /><br />
                                                                                <LAGridItem xs={12} sm={6} md={3}>
                                                                                    <strong>Hazard</strong>
                                                                                    <LAAutoComplete
                                                                                        disabled={disabledByCreatedTime}
                                                                                        multiple={false}
                                                                                        option={hazardsList}
                                                                                        autoHighlight={true}
                                                                                        onChange={onHazardChange}
                                                                                        style={{backgroundColor: SKY_BLUE_COLOR}}
                                                                                        filterSelectedOptions={true}
                                                                                        getOptionLabel="hazard"
                                                                                        indexPosition={hazardIndex}
                                                                                        dropDownPlaceHolder=""
                                                                                        // errorText={!(h.hazard_ID > 0) ? "Required" : undefined}
                                                                                        selectionRemove={undefinedFunction}
                                                                                        value={h.hazard_ID && hazardsList ? hazardsList.find(q => q.id === h.hazard_ID) : null}
                                                                                        defaultValue={h.hazard_ID && hazardsList ? hazardsList.find(q => q.id === h.hazard_ID) : null}
                                                                                    />
                                                                                    {!(h.hazard_ID > 0) && <FormHelperText className="required-text">Required</FormHelperText> }
                                                                                </LAGridItem>
                                                                                {/* <LAGridItem xs={12} sm={6} md={3}>
                                                                                    <strong>Custom Hazard</strong>
                                                                                    <LATextField
                                                                                        name="custom_Hazard"
                                                                                        label=""
                                                                                        fullWidth={true}
                                                                                        disabled={disabledByCreatedTime}
                                                                                        variant="outlined"
                                                                                        indexPosition={taskIndex}
                                                                                        value={h.custom_Hazard ?? ""}
                                                                                        // errorText={!(h.custom_Hazard) ? "Required" : undefined}
                                                                                        onChange={onCustomHazardChange}
                                                                                    />
                                                                                </LAGridItem> */}
                                                                                <LAGridItem xs={12} sm={6} md={3}>
                                                                                    <strong>Probability</strong>
                                                                                    <LAAutoComplete
                                                                                        disabled={disabledByCreatedTime}
                                                                                        multiple={false}
                                                                                        option={probabilityOptions}
                                                                                        getOptionLabel="name"
                                                                                        autoHighlight={true}
                                                                                        onChange={onProbabilityChange}
                                                                                        indexPosition={hazardIndex}
                                                                                        filterSelectedOptions={true}
                                                                                        dropDownPlaceHolder=""
                                                                                        selectionRemove={undefinedFunction}
                                                                                        value={h.probability ? probabilityOptions.find(q => q.name === h.probability) : null}
                                                                                        defaultValue={h.probability ? probabilityOptions.find(q => q.name === h.probability) : null}
                                                                                    />
                                                                                </LAGridItem>
                                                                                <LAGridItem xs={12} sm={6} md={3}>
                                                                                    <strong>Severity</strong>
                                                                                    <LAAutoComplete
                                                                                        disabled={disabledByCreatedTime}
                                                                                        multiple={false}
                                                                                        option={severityOptions}
                                                                                        getOptionLabel="name"
                                                                                        autoHighlight={true}
                                                                                        onChange={onSeverityChange}
                                                                                        filterSelectedOptions={true}
                                                                                        indexPosition={hazardIndex}
                                                                                        dropDownPlaceHolder=""
                                                                                        selectionRemove={undefinedFunction}
                                                                                        value={h.severity ? severityOptions.find(q => q.name === h.severity) : null}
                                                                                        defaultValue={h.severity ? severityOptions.find(q => q.name === h.severity) : null}
                                                                                    />
                                                                                </LAGridItem>
                                                                                <LAGridItem xs={12} sm={6} md={6}>
                                                                                    <strong>Control Plans</strong>
                                                                                    <LAAutoComplete
                                                                                        disabled={disabledByCreatedTime}
                                                                                        multiple={true}
                                                                                        option={controlPlanOptions}
                                                                                        autoHighlight={true}
                                                                                        onChange={onControlPlanChange}
                                                                                        filterSelectedOptions={true}
                                                                                        indexPosition={hazardIndex}
                                                                                        getOptionLabel="control"
                                                                                        dropDownPlaceHolder=""
                                                                                        selectionRemove={onControlPlanRemove}
                                                                                        value={this.getSelectedHazardControlPlan(h.hazard_Control_IDS, controlPlanOptions)}
                                                                                        defaultValue={this.getSelectedHazardControlPlan(h.hazard_Control_IDS, controlPlanOptions)}
                                                                                    />
                                                                                </LAGridItem>
                                                                                <LAGridItem xs={12} sm={6} md={6}>
                                                                                    <strong>Custom Control Plan</strong>
                                                                                    <LATextField
                                                                                        name="custom_Hazard_Control"
                                                                                        label=""
                                                                                        fullWidth={true}
                                                                                        disabled={disabledByCreatedTime}
                                                                                        variant="outlined"
                                                                                        indexPosition={hazardIndex}
                                                                                        value={h.custom_Hazard_Control ?? ""}
                                                                                        onChange={onCustomHazardControlChange}
                                                                                    />
                                                                                    {/* <LAFreeTextAutoComplete 
                                                                                        value={customControlPlans} 
                                                                                        onChange={(newValue: string[]) => this.handleCustomControlPlanChange(newValue, hazardIndex, taskIndex)} 
                                                                                        label=""
                                                                                        disabled={disabledByCreatedTime}
                                                                                        autoHighlight={true}
                                                                                        placeholder="Type and press Enter"
                                                                                    /> */}
                                                                                </LAGridItem>
                                                                            </LAGrid>
                                                                        </LAPaperWithPadding>
                                                                    </LAGridItem>
                                                                })}
                                                                <br />
                                                                <LAGridItem xs={12} className="text-center">

                                                                    <LAButton
                                                                        label="Add Hazard"
                                                                        disabled={disabledByCreatedTime}
                                                                        onClick={onAddHazard}
                                                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                                                    />
                                                                </LAGridItem>
                                                            </LAPaperWithPadding>
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })}

                                        <LAGridItem xs={3}>
                                            <LAButton
                                                label="Add Task"
                                                disabled={disabledByCreatedTime}
                                                onClick={this.onAddTask}
                                                startIcon={<AddIcon color={WHITE_COLOR} />}
                                            />
                                        </LAGridItem>
                                        <LAGridItem xs={6}>
                                            {(data.tasks.length === 0) && <LAErrorBox color={RED_COLOR} textColor={WHITE_COLOR} className="text-center" text="Please add at least one task." />}
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>}

                            {/* new hazard assessment */}
                            {data.hazard_Assessment === "Yes" && (data.department_ID || data.id === 0) && <LAGridItem xs={12} >
                                <LAExpansionPanel color={ORANGE_COLOR} textColor={WHITE_COLOR} label="Hazard Assessment" expanded={hazardAssessmentExpand} onClick={() => this.handleSectionExpand("hazardAssessmentExpand")}> 
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4} >
                                            <LAAutoComplete
                                                disabled={disabledByCreatedTime || disabledEdit}
                                                multiple={false}
                                                option={departmentList?.sort((a,b) => a.name > b.name ? 1 : -1)}
                                                autoHighlight={true}
                                                onChange={onDepartment}
                                                filterSelectedOptions={true}
                                                getOptionLabel="name"
                                                dropDownPlaceHolder="Department"
                                                errorText={!(data.department_ID > 0) ? "Required" : undefined}
                                                selectionRemove={undefinedFunction}
                                                value={data.department_ID ? departmentList.find(q => q.id === data.department_ID) : null}
                                                defaultValue={data.department_ID ? departmentList.find(q => q.id === data.department_ID) : null}
                                            />
                                        </LAGridItem>

                                        {/* <LAGridItem xs={12} sm={6} md={4} >
                                            <DevExtremePopupLookup
                                                data={taskList}
                                                multiple={true}
                                                id="task-search"
                                                placeHolder="Tasks"
                                                disabled={disabledByCreatedTime || !data.department_ID}
                                                displayExp="name"
                                                name="id"
                                                errorName=""
                                                onSelectionRemove={this.handleAssignedTaskRemove}
                                                errors={errors}
                                                selectionRow={true}
                                                onSelectionChanged={this.handleTaskSelectionChanged}
                                                returnExp="id"
                                                width={500}
                                                currentVal={this.getAssignedTasks(data.task_IDs ? data.task_IDs.split(",").map(Number): [])}
                                                onClick={undefinedFunction}
                                                columns={[
                                                    { name: "name", caption: "Name", type: "string" },
                                                ]}
                                            />
                                        </LAGridItem> */}

                                        <LAGridItem xs={12} sm={6} md={4}>
                                            <LAFreeTextAutoComplete 
                                                value={data.task_List ?? []} 
                                                onChange={(newValue: string[]) => this.handleTaskChangeNew(newValue)} 
                                                label="Tasks"
                                                filterSelectedOptions={true}
                                                options={taskListOptions}
                                                errorText={(!(data.task_List) || (data.task_List?.length === 0)) ? "Required" : undefined}
                                                disabled={disabledByCreatedTime || !data.department_ID}
                                                autoHighlight={true}
                                                placeholder="Type and press Enter"
                                            />
                                            {data.id > 0 && <>
                                                <LALinkButton onClick={() => this.handleFormResponseVersionHistory(data, "Form_Sheet_Tasks")} label="Version History - Tasks"></LALinkButton>
                                            <br /></>
                                        }
                                        </LAGridItem>

                                        {data.id > 0 && <LAGridItem xs={12}>
                                            <LALinkButton className="flex-end" onClick={() => this.handleFormResponseVersionHistory(data, "Form_Sheet_Hazard_Assessment")} label="Version History - Hazard Assessment"></LALinkButton>
                                            <br /></LAGridItem>
                                        }
                                        {data.hazard_List && data.hazard_List.length > 0 && data.hazard_List?.map((h : any, hazardIndex: number) => {
                                            const onDeleteHazard= () => this.handleDeleteHazardNew(hazardIndex);
                                            const onHazardType = (event: unknown, value: IHazardTypes): void => this.handleHazardInfoChangeNew("type_ID", value  ? value.id.toString() : h.type_ID.toString(), hazardIndex);
                                            const onSeverityChange = (event: unknown, value: IIdNameDisplay): void => this.handleHazardInfoChangeNew("severity", value !== null ? value.name : h.severity, hazardIndex);
                                            const onProbabilityChange = (event: unknown, value: IIdNameDisplay): void => this.handleHazardInfoChangeNew("probability", value !== null ? value.name : h.probability, hazardIndex);
                                            const excludedhazardList = data.hazard_List.map((x:any) => x.hazard).filter((x: any) => x !== h.hazard);
                                            // console.log(excludedhazardList)
                                            const filteredhazardListOptions = hazardListOptions?.filter(x => h.type_ID > 0 ? x.type_ID === h.type_ID : true).map(x => x.hazard).filter(item => !excludedhazardList.includes(item));
                                            const controlPlanOptions = hazardControlPlanListOptions && h.hazard ? hazardControlPlanListOptions.find(x => x.hazard === h.hazard)?.control_Plans : [];
                                            const riskResult = this.getRiskResult(h.probability, h.severity);
                                            const disableHazard = data.id > 0 && h.hazard ? this.state.initialHazardList?.some(x => x.hazard === h.hazard) : undefined;
                                            // console.log(h)
                                            // #region Hazard Assessment New
                                            return <LAGridItem xs={12} key={hazardIndex}>
                                                <LAPaperWithLessPadding className={"hazard"} key={hazardIndex}>
                                                    <LAGrid key={hazardIndex}>
                                                        <LAGridItem xs={12}>
                                                            <strong className={riskResult==="Low" ? "lowRisk" : riskResult==="Medium" ? "mediumRisk" : riskResult==="High" ? "highRisk" : ""}>Risk: {riskResult}</strong>
                                                            <LAIconButton
                                                                icon={<DeleteIcon color={RED_COLOR} />}
                                                                className="pull-right"
                                                                label="Delete Hazard"
                                                                addLabel={true}
                                                                disabled={disabledByCreatedTime}
                                                                onClick={onDeleteHazard}
                                                            />
                                                        </LAGridItem>
                                                        <br /><br />
                                                        {/* <LAGridItem xs={12} sm={6} md={3}>
                                                            <strong>Hazard</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime}
                                                                multiple={false}
                                                                option={hazardsList}
                                                                autoHighlight={true}
                                                                onChange={onHazardChange}
                                                                style={{backgroundColor: SKY_BLUE_COLOR}}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="hazard"
                                                                indexPosition={hazardIndex}
                                                                dropDownPlaceHolder=""
                                                                // errorText={!(h.hazard_ID > 0) ? "Required" : undefined}
                                                                selectionRemove={undefinedFunction}
                                                                value={h.hazard_ID && hazardsList ? hazardsList.find(q => q.id === h.hazard_ID) : null}
                                                                defaultValue={h.hazard_ID && hazardsList ? hazardsList.find(q => q.id === h.hazard_ID) : null}
                                                            />
                                                            {!(h.hazard_ID > 0) && <FormHelperText className="required-text">Required</FormHelperText> }
                                                        </LAGridItem> */}
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard Type</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime || disableHazard}
                                                                multiple={false}
                                                                option={hazardTypesList}
                                                                autoHighlight={true}
                                                                onChange={onHazardType}
                                                                dropdownIcon={true}
                                                                dropdownIconWidth={"20"}
                                                                dropdownIconheight={"20"}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder=""
                                                                errorText={!(h.type_ID > 0) ? "Required" : undefined}
                                                                selectionRemove={undefinedFunction}
                                                                value={h.type_ID ? hazardTypesList?.find(q => q.id === h.type_ID) : null}
                                                                defaultValue={h.type_ID ? hazardTypesList?.find(q => q.id === h.type_ID) : null}
                                                            />
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard</strong>
                                                            <LAFreeTextAutoComplete 
                                                                value={h.hazard ? [h.hazard] : []} 
                                                                onChange={(newValue: string[]) => this.handleHazardChangeNew(newValue, hazardIndex)} 
                                                                label=""
                                                                options={filteredhazardListOptions ?? []}
                                                                filterSelectedOptions={true}
                                                                errorText={!(h.hazard) ? "Required" : undefined}
                                                                disabled={disabledByCreatedTime || disableHazard || !(h.type_ID > 0)}
                                                                autoHighlight={true}
                                                                placeholder="Type and press Enter"
                                                            />
                                                            {/* {!(h.hazard) && <FormHelperText className="required-text">Required</FormHelperText> } */}
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Probability</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime || !(h.type_ID > 0)}
                                                                multiple={false}
                                                                option={probabilityOptions}
                                                                getOptionLabel="display"
                                                                autoHighlight={true}
                                                                onChange={onProbabilityChange}
                                                                indexPosition={hazardIndex}
                                                                filterSelectedOptions={true}
                                                                errorText={!(h.probability) ? "Required" : undefined}
                                                                dropDownPlaceHolder=""
                                                                selectionRemove={undefinedFunction}
                                                                value={h.probability ? probabilityOptions.find(q => q.name === h.probability) : null}
                                                                defaultValue={h.probability ? probabilityOptions.find(q => q.name === h.probability) : null}
                                                            />
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Severity</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime || !(h.type_ID > 0)}
                                                                multiple={false}
                                                                option={severityOptions}
                                                                getOptionLabel="display"
                                                                autoHighlight={true}
                                                                onChange={onSeverityChange}
                                                                filterSelectedOptions={true}
                                                                indexPosition={hazardIndex}
                                                                errorText={!(h.severity) ? "Required" : undefined}
                                                                dropDownPlaceHolder=""
                                                                selectionRemove={undefinedFunction}
                                                                value={h.severity ? severityOptions.find(q => q.name === h.severity) : null}
                                                                defaultValue={h.severity ? severityOptions.find(q => q.name === h.severity) : null}
                                                            />
                                                        </LAGridItem>
                                                        {/* <LAGridItem xs={12} sm={6} md={6}>
                                                            <strong>Control Plans</strong>
                                                            <LAAutoComplete
                                                                disabled={disabledByCreatedTime}
                                                                multiple={true}
                                                                option={controlPlanOptions}
                                                                autoHighlight={true}
                                                                onChange={onControlPlanChange}
                                                                filterSelectedOptions={true}
                                                                indexPosition={hazardIndex}
                                                                getOptionLabel="control"
                                                                dropDownPlaceHolder=""
                                                                selectionRemove={undefinedFunction}
                                                                value={this.getSelectedHazardControlPlan(h.hazard_Control_IDS, controlPlanOptions)}
                                                                defaultValue={this.getSelectedHazardControlPlan(h.hazard_Control_IDS, controlPlanOptions)}
                                                            />
                                                        </LAGridItem> */}
                                                        <LAGridItem xs={12} sm={6} md={8}>
                                                            <strong>Control Plans</strong>
                                                            {/* <LATextField
                                                                name="custom_Hazard_Control"
                                                                label=""
                                                                fullWidth={true}
                                                                disabled={disabledByCreatedTime}
                                                                variant="outlined"
                                                                indexPosition={hazardIndex}
                                                                value={h.custom_Hazard_Control ?? ""}
                                                                onChange={onCustomHazardControlChange}
                                                            /> */}
                                                            <LAFreeTextAutoComplete 
                                                                value={h.control_Plans ?? []} 
                                                                onChange={(newValue: string[]) => this.handleCustomControlPlanChangeNew(newValue, hazardIndex)} 
                                                                label=""
                                                                options={controlPlanOptions ?? []}
                                                                disabled={disabledByCreatedTime || !(h.type_ID > 0)}
                                                                errorText={(!(h.control_Plans) || (h.control_Plans?.length === 0)) ? "Required" : undefined}
                                                                filterSelectedOptions={true}
                                                                autoHighlight={true}
                                                                placeholder="Type and press Enter"
                                                            />
                                                        </LAGridItem>
                                                    </LAGrid>  
                                                </LAPaperWithLessPadding>
                                             </LAGridItem>
                                        })}

                                        <LAGridItem xs={12} className="text-center">
                                            <LAButton
                                                label="Add Hazard"
                                                disabled={(disabledByCreatedTime || !data.department_ID) ? true : undefined}
                                                onClick={this.handleAddHazard}
                                                startIcon={<AddIcon color={WHITE_COLOR} />}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} className="text-center">
                                            {(data.hazard_List.length === 0) && <LAErrorBox color={RED_COLOR} textColor={WHITE_COLOR} className="text-center" text="Please add at least one hazard." />}
                                        </LAGridItem>

                                        {/* <LAGridItem xs={3}>
                                            <LAButton
                                                label="Add Task"
                                                disabled={disabledByCreatedTime}
                                                onClick={this.onAddTask}
                                                startIcon={<AddIcon color={WHITE_COLOR} />}
                                            />
                                        </LAGridItem> */}
                                        {/* <LAGridItem xs={6}>
                                            {(data.tasks.length === 0) && <LAErrorBox color={RED_COLOR} textColor={WHITE_COLOR} className="text-center" text="Please add at least one task." />}
                                        </LAGridItem> */}
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>}

                            {data.form_ID ? <LAGridItem xs={12} >
                                <LAExpansionPanel color={PURPLE_COLOR} textColor={WHITE_COLOR} label="Additional Images / Files" expanded={additionalDocuments} onClick={() => this.handleSectionExpand("additionalDocuments")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={6} className="text-center">
                                            <LAPaperWithLessPadding>
                                                <strong className="text-center">Upload Additional Images</strong>
                                                <LADropZone onFileDrop={(e: any) => this.handleAdditionalImageUpload(e)} />
                                                <br />
                                                <LAGrid>
                                                    {data.pictures && data.pictures
                                                        .map((q:IFormResponsePicture, ind: number) => {
                                                            const onImgDelete = (): void => this.onAdditionalImgDelete(q.name, ind);
                                                            const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                            const onImageName = (name: string, value: string): void => this.handleAdditionalImageName(name ? name : "", value, ind);
                                                            // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;

                                                            return (
                                                                <LAGridItem xs={12} sm={6} md={3} key={ind} className="text-center">
                                                                    <LAPaperWithLessPadding key={ind}>
                                                                        <LAThumbnailWithLink
                                                                            alt={q.name}
                                                                            key={ind}
                                                                            url={base64}
                                                                            id={(ind + "_").toString()}
                                                                        />

                                                                        <LATextField
                                                                            className="mt-2"
                                                                            label="Description"
                                                                            fullWidth={true}
                                                                            variant="outlined"
                                                                            name="description"
                                                                            disabled={disabledByCreatedTime}
                                                                            value={q.description}
                                                                            onChange={onImageName}
                                                                            errorText={
                                                                                q.description.length === 0 ?
                                                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                    : undefined
                                                                            }
                                                                        />

                                                                        {/* {q.timestamp && 
                                                                            <strong>{new Date(q.timestamp).toLocaleString()}</strong>
                                                                        }  */}

                                                                        <LAIconButton
                                                                            key={ind}
                                                                            label="Delete"
                                                                            disabled={disabledByCreatedTime}
                                                                            onClick={onImgDelete}
                                                                            icon={<DeleteIcon />}
                                                                        />
                                                                    </LAPaperWithLessPadding>
                                                                </LAGridItem>
                                                            );

                                                        })}
                                                </LAGrid>
                                            </LAPaperWithLessPadding>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={6} className="text-center">
                                            <LAPaperWithLessPadding>
                                                <strong>Upload Additional Files</strong>
                                                <LADropZone onFileDrop={this.handleAdditionalFileUpload} />
                                                <br />
                                                <LAGrid>
                                                    {(data.files) && data.files
                                                        .map((q:any, ind: number) => {
                                                            const onFileDelete = (): void => this.onAdditionalFileDelete(ind);
                                                            const onDownload = (): void => this.handleAdditionalFileDownload(q);

                                                            return (
                                                                <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                                    <LAPaperWithLessPadding>
                                                                        <span>{q.name}</span>
                                                                        <br />
                                                                        <LAIconButton
                                                                            name="file"
                                                                            label={q.name}
                                                                            id={(ind).toString()}
                                                                            onClick={onDownload}
                                                                            icon={<DownloadIcon />}
                                                                        />

                                                                        <LAIconButton
                                                                            key={ind}
                                                                            label="Delete"
                                                                            disabled={disabledByCreatedTime}
                                                                            icon={<DeleteIcon />}
                                                                            onClick={onFileDelete}
                                                                        />
                                                                    </LAPaperWithLessPadding>
                                                                </LAGridItem>
                                                            )
                                                        })}
                                                </LAGrid>
                                            </LAPaperWithLessPadding>
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem> : null}

                            {data.requires_Crew === "Yes" && <LAGridItem xs={12}>
                                <LAExpansionPanel color={RED_COLOR} textColor={WHITE_COLOR} label="Crew" answerNumbers={answerCrewSignatureNumbers} questionNumbers={data.crew?.length} expanded={crewExpand} onClick={() => this.handleSectionExpand("crewExpand")}>
                                    <LAGrid>
                                        {data.form_ID === 19 && <LAGridItem xs={12} sm={12} md={3}>
                                            <LAAutoComplete
                                                disabled={disabledByCreatedTime}
                                                multiple={false}
                                                option={crewNamesOptions}
                                                autoHighlight={true}
                                                onChange={this.handleSelectCrewName}
                                                filterSelectedOptions={true}
                                                getOptionLabel="crew_Name"
                                                dropDownPlaceHolder="Crew Name"
                                                selectionRemove={undefinedFunction}
                                                value={crew_Name && crewNamesOptions ? crewNamesOptions.find((x:any) => x.crew_Name === crew_Name) : null}
                                                defaultValue={crew_Name && crewNamesOptions ? crewNamesOptions.find((x:any) => x.crew_Name === crew_Name) : null}
                                            />
                                        </LAGridItem>}

                                        <LAGridItem xs={12} sm={12} md={5}>
                                            <LAAutoComplete
                                                disabled={disabledByCreatedTime}
                                                multiple={true}
                                                option={fieldEmployeesList}
                                                autoHighlight={true}
                                                onChange={this.handleSelectCrew}
                                                filterSelectedOptions={true}
                                                chipIcon={<MobileOffIcon color="error" fontSize="small"/>}
                                                getOptionLabel="name"
                                                dropDownPlaceHolder="Employees"
                                                selectionRemove={this.handleCrewRemove}
                                                value={this.getSelectedCrew(data.crew)}
                                                defaultValue={this.getSelectedCrew(data.crew)}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                                            <strong className="text-center">Upload Signature Sheet Image</strong>
                                            <LADropZone onFileDrop={(e: any) => this.handleCrewSignatureImageUpload(e)} />
                                            {data.form_ID === 19 && <FormHelperText><strong className="required">Note:</strong> Add <strong className="required">Form ID: {query.logId}</strong> on top of the sheet before taking picture</FormHelperText>}
                                            <br />
                                            <LAGrid>
                                                {data.crew_Sign_In_Upload &&
                                                    <LAGridItem xs={12} key={data.crew_Sign_In_Upload.name} className="text-center">
                                                        <LAPaperWithLessPadding key={data.crew_Sign_In_Upload.name}>
                                                            <LAThumbnailWithLink
                                                                alt={data.crew_Sign_In_Upload.name}
                                                                key={data.crew_Sign_In_Upload.name}
                                                                url={crewSignatureBase64}
                                                                id={(data.crew_Sign_In_Upload.name + "_").toString()}
                                                            />

                                                            <LATextField
                                                                className="mt-2"
                                                                label="Description"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="description"
                                                                disabled={disabledByCreatedTime}
                                                                value={data.crew_Sign_In_Upload.description}
                                                                onChange={onCrewSignatureImageName}
                                                                errorText={
                                                                    data.crew_Sign_In_Upload.description.length === 0 ?
                                                                        FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                        : undefined
                                                                }
                                                            />

                                                            {data.crew_Sign_In_Upload.timestamp && 
                                                                <strong>{new Date(data.crew_Sign_In_Upload.timestamp).toLocaleString()}</strong>
                                                            }

                                                            <LAIconButton
                                                                key={data.crew_Sign_In_Upload.name}
                                                                label="Delete"
                                                                disabled={disabledByCreatedTime}
                                                                onClick={this.onCrewSignatureImgDelete}
                                                                icon={<DeleteIcon />}
                                                            />
                                                        </LAPaperWithLessPadding>
                                                    </LAGridItem>
                                                }
                                            </LAGrid>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LAGrid>
                                                {data.crew && data.crew.length > 0 && 
                                                    data.crew.map((c: any, crewIndex: number) => {
                                                        if (!this.signCanvasCrewRefs[crewIndex]) {
                                                            this.signCanvasCrewRefs[crewIndex] = React.createRef<SignatureCanvas>();
                                                        }
                                                        const onSignatureHistory = () => this.handleFormSignatureHistory(c, data.id, "crew");
                                                        const disableSignature = data.id > 0 && data.form_Updated === "No" && this.state.initialCrewList?.find(x => x.name === c.name)?.signature ? true : undefined;

                                                        return <LAGridItem xs={12} sm={6} md={6} lg={3} key={crewIndex}>
                                                            <LAPaperWithLessPadding key={crewIndex}>
                                                                <strong>{c.name}</strong>
                                                                {data.id > 0 && <LALinkButton
                                                                    className="pull-right"
                                                                    label="History"
                                                                    onClick={onSignatureHistory}
                                                                />}
                                                                <br />
                                                                {(!c.signature || c.signature === "") && <>
                                                                    <span className="required">After review, please sign below and click on the save signature button!</span>
                                                                    <br />
                                                                </>}

                                                                {!disabledByCreatedTime && !disableSignature && <>
                                                                    <div className={`signature-container ${!c.signature ? "required-sign" : "sign"}`}>
                                                                        <SignatureCanvas
                                                                            penColor="black"
                                                                            ref={this.signCanvasCrewRefs[crewIndex]}
                                                                            canvasProps={{width: 500, height: 200,  className: 'signCanvas' }}
                                                                        />
                                                                    </div>
                                                                    <br />
                                                                    <LAButton
                                                                        className="mt-2"
                                                                        label="Save Signature"
                                                                        disabled={disabledByCreatedTime || disableSignature}
                                                                        onClick={() => this.saveCrewSignature(crewIndex)}
                                                                    />

                                                                    <LAButton
                                                                        className="ml-1 mt-2"
                                                                        label="Clear"
                                                                        onClick={() => this.clearCrewSignature(crewIndex)}
                                                                    />
                                                                    <br /><br />
                                                                </>}
                                                                {c.signature && <>
                                                                    <img src={base64ToImageUrl(c.signature)} alt="signature" /><br />
                                                                    {c.timestamp ? <strong>Last Signed at : {new Date(c.timestamp).toLocaleString()}</strong> : c.modified && <strong>Last Signed at : {new Date(c.modified).toLocaleString()} By {c.modified_By}</strong>}
                                                                </>}
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    })
                                                }
                                            </LAGrid>
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>}
                            
                            {data.requires_Guest === "Yes" && <LAGridItem xs={12}>
                                <LAExpansionPanel color={DARK_ORANGE_COLOR} textColor={WHITE_COLOR} label="Subcontractors/Guests" questionNumbers={data.guests?.length} answerNumbers={answerGuestsSignatureNumbers} expanded={guestsExpand} onClick={() => this.handleSectionExpand("guestsExpand")}>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={4} >
                                            <LAFreeTextAutoComplete 
                                                value={this.getGuests(data.guests)} 
                                                onChange={this.handleGuestChange} 
                                                label="Subcontractors/Guests" 
                                                disabled={disabledByCreatedTime}
                                                autoHighlight={true}
                                                placeholder="Type and press Enter"
                                            />
                                            {data.id > 0 && <Tooltip title={this.state.copyExternalLinkForGuests ? "Copied!" : "Copy Shareable Link"}>
                                                <IconButton onClick={this.handleCopyExternalLinkForGuests} color="primary" size="small">
                                                    <span>{this.state.copyExternalLinkForGuests ? "Copied!" : "Copy Shareable Link"}</span>
                                                </IconButton>
                                            </Tooltip>}
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LAGrid>
                                                {data.guests && data.guests.length > 0 && 
                                                    data.guests.map((v: any, guestsIndex: number) => {
                                                        if (!this.signCanvasGuestsRefs[guestsIndex]) {
                                                            this.signCanvasGuestsRefs[guestsIndex] = React.createRef<SignatureCanvas>();
                                                        }
                                                        const onSignatureHistory = () => this.handleFormSignatureHistory(v, data.id, "guests");

                                                        // console.log(v)
                                                        return <LAGridItem xs={12} sm={6} md={6} lg={3} key={guestsIndex}>
                                                            <LAPaperWithLessPadding key={guestsIndex}>
                                                                <strong>{v.name}</strong>
                                                                {data.id > 0 && <LALinkButton
                                                                    className="pull-right"
                                                                    label="History"
                                                                    onClick={onSignatureHistory}
                                                                />}
                                                                <br />
                                                                {(!v.signature || v.signature === "") && <>
                                                                    <span className="required">After review, please sign below and click on the save signature button!</span>
                                                                    <br />
                                                                </>}

                                                                <div className={`signature-container ${!v.signature ? "required-sign" : "sign"}`}>
                                                                    <SignatureCanvas
                                                                        penColor="black"
                                                                        ref={this.signCanvasGuestsRefs[guestsIndex]}
                                                                        canvasProps={{width: 500, height: 200,  className: 'signCanvas' }}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <LAButton
                                                                    className="mt-2"
                                                                    label="Save Signature"
                                                                    disabled={disabledByCreatedTime}
                                                                    onClick={() => this.saveGuestSignature(guestsIndex)}
                                                                />

                                                                <LAButton
                                                                    className="ml-1 mt-2"
                                                                    label="Clear"
                                                                    onClick={() => this.clearGuestSignature(guestsIndex)}
                                                                />
                                                                <br /><br />
                                                                {v.signature && <>
                                                                    <img src={base64ToImageUrl(v.signature)} alt="signature" /><br />
                                                                    {v.timestamp ? <strong>Last Signed at : {new Date(v.timestamp).toLocaleString()}</strong> : v.modified && <strong>Last Signed at : {new Date(v.modified).toLocaleString()} By {v.modified_By}</strong>}
                                                                </>}
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    })
                                                }
                                            </LAGrid>
                                        </LAGridItem>
                                    </LAGrid>
                                </LAExpansionPanel>
                            </LAGridItem>}

                            {data.form_ID ? <LAGridItem xs={12}>  
                                <LAExpansionPanel color={BLUE_COLOR} textColor={WHITE_COLOR} label={data.form_ID === 19 ? "Foreman/Supervisor Signature" : "Signature"} expanded={foremanSignatureExpand} onClick={() => this.handleSectionExpand("foremanSignatureExpand")}>
                                    <LAGrid>       
                                        <LAPaperWithLessPadding>                       
                                            <LAGridItem xs={12}>  
                                                {!data.signature && <>
                                                    <span className="required">After review, please sign below and click on the save signature button!</span>
                                                    <br />
                                                </>}

                                                <div className={`signature-container ${!data.signature ? "required-sign" : "sign"}`}>
                                                    <SignatureCanvas
                                                        penColor="black"
                                                        ref={this.signCanvas}
                                                        canvasProps={{ width: 500, height: 200, className: 'signCanvas' }}
                                                    />
                                                </div>
                                                <br />
                                                <LAButton
                                                    className="mt-2"
                                                    label="Save Signature"
                                                    disabled={disabledByCreatedTime}
                                                    onClick={this.saveSignature}
                                                />

                                                <LAButton
                                                    className="ml-1 mt-2"
                                                    label="Clear"
                                                    onClick={this.clearSignature}
                                                />
                                            </LAGridItem>
                                            <br /><br />
                                            <LAGridItem xs={12}>
                                                {data.signature && (
                                                    <div>
                                                        <img src={base64ToImageUrl(data.signature)} alt="signature" /><br />
                                                        {data.foreman_Signed_Date && <strong>{data.foreman_Signed} signed at : {new Date(data.foreman_Signed_Date).toLocaleString()} </strong> }
                                                    </div>
                                                )}
                                            </LAGridItem>
                                        </LAPaperWithLessPadding>
                                    </LAGrid>  
                                </LAExpansionPanel>
                            </LAGridItem> : null}

                            {serverError.length > ZEROTH && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            {data.id > 0 && data.requires_Crew === "Yes" && <LAGridItem xs={12}>
                                <LACheckBox
                                    name="form_Updated"
                                    label="Notify Crew for new signature (answers or hazards/controls plans were updated)"
                                    disabled={undefined}
                                    onChange={onFormUpdated}
                                    value={(data.form_Updated === "Yes") ? true : false}
                                />
                            </LAGridItem>}
                            <LAGridItem xs={12}>
                                <> </>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={6}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Submit"
                                    onSave={this.handleSave}
                                    cancelButtonText="Close"
                                    onCancel={this.handleCancel}
                                    disableSave={
                                        (Object.values(errors).length > 0) || 
                                        disabledByCreatedTime || disabledSaveByQuestions || disabledSaveByQuestionsImageRequired || disabledSaveByHazardAssessment
                                        ? true 
                                        : undefined}
                                />
                            </LAGridItem>
                        </LAGrid>
                    }

                    {/* {formResponseVersionHistoryPopup && <FormResponseVersionHistoryByID
                        data={formResponseVersionHistoryPopupData}
                        display={`${data.form_Name} - ${data.wsS_Log_ID}` }
                        open={formResponseVersionHistoryPopup}
                        status={getFormResponseVersionHistoryByID.kind}
                        onCancel={this.handleFormResponseVersionHistoryPopupClose}
                    />} */}
                    {formResponseVersionHistoryPopup && <FormVersionHistoryPopup
                        page="FormResponseVersionHistory"
                        data={formVersionHistoryPopupData}
                        display={`Version History`}
                        open={formResponseVersionHistoryPopup}
                        status={getFormVersionHistory.kind}
                        onCancel={this.handleFormResponseVersionHistoryPopupClose}
                    />}
                    {formSheetSectionsVersionHistoryPopup && <FormVersionHistoryPopup
                        page="FormSheetSectionsVersionHistory"
                        data={formVersionHistoryPopupData}
                        display={`Version History`}
                        open={formSheetSectionsVersionHistoryPopup}
                        status={getFormVersionHistory.kind}
                        onCancel={this.handleFormSheetSectionsVersionHistoryPopupClose}
                    />}
                    {formSheetTasksVersionHistoryPopup && <FormVersionHistoryPopup
                        page="FormSheetTasksVersionHistory"
                        data={formVersionHistoryPopupData}
                        display={`Version History`}
                        open={formSheetTasksVersionHistoryPopup}
                        status={getFormVersionHistory.kind}
                        onCancel={this.handleFormSheetTasksVersionHistoryPopupClose}
                    />}
                    {formSheetHazardAssessmentVersionHistoryPopup && <FormVersionHistoryPopup
                        page="FormSheetHazardAssessmentVersionHistory"
                        data={formVersionHistoryPopupData}
                        display={`Version History`}
                        open={formSheetHazardAssessmentVersionHistoryPopup}
                        status={getFormVersionHistory.kind}
                        onCancel={this.handleFormSheetHazardAssessmentVersionHistoryPopupClose}
                    />}

                    {acknowledge_Required && <FormResponseAcknowledge
                        display="Announcement - Safety Department"
                        open={acknowledge_Required && acknowledge_Status === false}
                        style={{ width: '100%', height: "auto"}}
                        acknowledge_Status={acknowledge_Status}
                        acknowledge_Form={acknowledge_Form}
                        onCancel={this.handleFormResponseAcknowledgePopupClose}
                        onAcknowledge={this.handleFormResponseAcknowledgeClick}
                    />}

                    {formSignatureHistoryPopup && <FormSignatureHistory
                        data={formSignatureHistorypopupData}
                        display={`${data.form_Name}` }
                        open={formSignatureHistoryPopup}
                        status={getFormSignatureHistory.kind}
                        onCancel={this.handleFormSignatureHistoryPopupClose}
                        formSignatureHistoryPopupType={formSignatureHistoryPopupType}
                    />}

                    <RequestStatus requestStatus={addRequestStatus.kind} successMessage="Form Response has been added successfully" failedMessage={addRequestStatus.kind === STATUS_ENUM.FAILED ? addRequestStatus.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>
                    <RequestStatus requestStatus={updateRequestStatus.kind} successMessage="Form Response has been updated successfully" failedMessage={updateRequestStatus.kind === STATUS_ENUM.FAILED ? updateRequestStatus.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>
                </PageSpacing>
            </AddUpdateFormResponseStyles>
        );
    }

    private checkFormValidationForHazardAssessment = () : boolean => {
        let disabled = false;
        const { data } = this.state;
        if(data.hazard_Assessment === "Yes" && !data.department_ID) {
            if(data.tasks.length === 0) {
                return true;
            }
            else {
                disabled = data.tasks.some((obj: any) => !(obj.task_ID > 0) 
                            || (obj.task_ID === 15 && !obj.custom_Task) 
                            || obj.hazards.length === 0 
                            || (obj.hazards.length > 0 && obj.hazards?.some((h:any) => !(h.hazard_ID > 0))));
                return disabled;
            }
        }
        else if(data.hazard_Assessment === "Yes" && data.department_ID > 0) {
            if(data.task_List.length === 0 || data.hazard_List.length === 0) {
                return true;
            }
            else {
                disabled = data.hazard_List.some((obj: any) => !(obj.type_ID > 0) || (obj.hazard === "" || obj.hazard === null || obj.hazard === undefined)
                            || (obj.control_Plans.length === 0));
                return disabled;
            }
        }
        return disabled;
    }


    private getAssignedTasks = (assignedTasksIds: number[]): ITask[] => {
        if (assignedTasksIds) {
            const taskList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.tasks : [];
            const response: ITask[] = [];
            // const assignedUnitIDs = ids !== null ? ids.split(",").map(String) : [];

            assignedTasksIds.forEach((x) => {
                const index = taskList.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(taskList[index]);
            });

            return response;
        }
        return [];
    };

    private handleTaskSelectionChanged = (row:any): void => {
        const selectedTasksList: ITask[] = row.selectedRowsData;
        // console.log(selectedTasksList);
        const data = { ...this.state.data };
        let assigned_Tasks = data.task_IDs;
        const assignedTasksArray: number[] = assigned_Tasks && assigned_Tasks !== null ? assigned_Tasks.split(",").map(Number) : [];

        selectedTasksList.forEach((x) => {
            const index = assignedTasksArray.length > 0 ? assignedTasksArray.findIndex((e) => e === x.id) : -1;
            if (index < 0) {
                assignedTasksArray.push(x.id);
                
            }
        });

        assigned_Tasks = assignedTasksArray.join(",");
        // console.log(assigned_Tasks)

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                task_IDs: assigned_Tasks,
            },
        });
    }
    
    private handleAssignedTaskRemove = (value: string): void => {
        // console.log(value)
        // let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        let assigned_Tasks = data.task_IDs;

        const taskList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.tasks : [];

        const assignedTaskArray: number[] = assigned_Tasks && assigned_Tasks !== null ? assigned_Tasks.split(",").map(Number) : [];

        const task = taskList ? taskList.find(q => q.name === value) : null;

        if(task) {
            const index = assignedTaskArray.length > 0 ? assignedTaskArray.findIndex((x) => x === task.id) : -1;
            if (index >= 0) {
                assignedTaskArray.splice(index, 1);
            }
        }

        assigned_Tasks = assignedTaskArray.join(",");
        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                task_IDs: assigned_Tasks,
            },
        });
    }

    private handleTaskChangeNew = async (values: string[]): Promise<void> => {
            const selectedCustomTasks = values;
            // console.log(selectedCustomTasks)
            const data = { ...this.state.data};
            let task_List: string[] = data.task_List ?? [];
            
            // let customControlPlansArray: string[] = hazard && hazard.custom_Hazard_Control ? hazard.custom_Hazard_Control.split(",") : [];

            selectedCustomTasks.forEach(x => {
                if(!(task_List.includes(x) || task_List.includes(x.trim()))) {
                    task_List.push(x.trim());
                }
            })

            task_List = task_List.filter(x =>
                selectedCustomTasks.map(x => x.trim()).includes(x.trim()) 
            );
                
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    task_List: task_List,
                },
            });
    }

    private handleFormSignatureHistoryPopupClose = (): void => {
        this.setState({ formSignatureHistoryPopup: false, formSignatureHistoryPopupType: undefined });
    };

    private handleFormSignatureHistory = async (data: any, formID: number, type: string): Promise<void> => {
        if(data) {
            if (hasPayload(this.props.token)) {
                await this.props.getFormSignatureHistoryRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        Form_ID: formID,
                        Emp_ID: data.emp_ID ?? 0,
                        Name: data.name
                    }
                });
            }
            this.setState({ formSignatureHistoryPopup: true, formSignatureHistoryPopupType: type });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        if (RequiredFields.includes(name) || (name === "location_Other")) {
            errors = this.errorChecker(name, value, errors);
        }

        if(name === "department_ID") {
            if (hasPayload(this.props.token))
                this.props.getHazardControlPlanListByDepartmentIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        department_id: Number(value)
                    }
                });
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: name ==="department_ID" ? Number(value) : value,
                task_List: name ==="department_ID" ? [] : this.state.data.task_List,
                hazard_List: name ==="department_ID" ? [] : this.state.data.hazard_List,
            },
            errors
        });
    };

    private handleLocationChange  = (value: string): void => {
        // console.log(value)
        let errors = { ...this.state.errors };
        if(Number(value) > 0) {
            const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
            const record = wssList.find((x: any) => x.wsS_ID === Number(value));
        
            errors = this.errorChecker("wsS_ID", value, errors);
            if(Number(value) === 9999) {
                errors = this.errorChecker("location_Other", "", errors);
            }
            else {
                delete errors["location_Other"];
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    wsS_ID: Number(value),
                    location_Other: "",
                    job: record?.job,
                    muster_Point: record?.muster_Point
                },
                errors
            });
        }
        else {
            delete errors["location_Other"];
            errors = this.errorChecker("wsS_ID", value, errors);
            this.setState({
                ...this.state,
                data: {
                   ...this.state.data,
                    wsS_ID: 0,
                    location_Other: "",
                },
                errors
            });
        }
        
    }

    private handleSelectCrewName = (e:any, value: ICrew): void => {
        // console.log(value);
        let crew: any[] =  [];
        if(value) {
            value.members.forEach((m: number) => {
                const index = crew.length > 0? crew.findIndex((x) => x.emp_ID === m) : -1;
                if (index < 0) {
                    const fieldEmployeesOptions: IFieldEmployee[] = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.field_employees : [];
                    const find = fieldEmployeesOptions.find(x => x.id === m);
                    if(find)
                        crew.push({id: 0, emp_ID: m, name: find.name, signature: null});
                }
            })
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    crew: crew
                },
                crew_Name: value ? value.crew_Name : ""
            });
        }
        else {
            this.setState({
                ...this.state,
                crew_Name: ""
            });
        }
    }

    private handleSelectCrew = async (e: any, values: IFieldEmployee[]): Promise<void> => {
        // console.log(values);
        // const data = { ...this.state.data };
        let crew: any[] = this.state.data.crew ?? [];
        // const selectedCrewArray: string[] = data.crew_Names && data.crew_Names !== null
        //     ? data.crew_Names.split(",").map(String)
        //     : [];

        await values.forEach((q: IFieldEmployee) => {
            const index = crew.length > 0 ? crew.findIndex((x) => x.name === q.name) : -1;
            if (index < 0) {
                // selectedCrewArray.push(q.name);
                crew.push({id: 0, emp_ID: q.id, name: q.name, signature: null}); 
            }
        });

        // data.crew_Names = selectedCrewArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                crew: crew
                // crew_Names: data.crew_Names,
            },
        });
    };

    private handleCrewRemove = async (value: any): Promise<void> => {
        if (hasPayload(this.props.getLookup) && value) {
            // console.log(value)
            let crew: IFormResponseByIDCrew[] = this.state.data.crew ? [...this.state.data.crew] : [];
            const fieldEmployeesOptions: IFieldEmployee[] = this.props.getLookup.payload.response.field_employees;

            const find = fieldEmployeesOptions.find(x => x.name === value);

            if (find) {
                // const data = { ...this.state.data };
                // const selectedArray: string[] = data.crew_Names && data.crew_Names !== null
                //     ? data.crew_Names.split(",").map(String) : [];

                // const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === find.name) : -1;
                const index = crew.length > 0 ? crew.findIndex((x) => x.name === find.name) : -1;
                if (index >= 0) {
                    // selectedArray.splice(index, 1);
                    crew = crew.filter((item: any) => item.name !== find.name);
                }

                // data.crew_Names = selectedArray.join(",");

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        crew: crew
                        // crew_Names: data.crew_Names,
                    },
                });
            }
        }
    };

    private getSelectedCrew = (value: IFieldEmployee[]): IFieldEmployee[] => {
        if (hasPayload(this.props.getLookup) && value) {

            const response: IFieldEmployee[] = [];
            // const selected = value !== null ? value.split(",").map(String) : [];
            const fieldEmployeesOptions: IFieldEmployee[] = this.props.getLookup.payload.response.field_employees;

            value.forEach((x) => {
                const index = fieldEmployeesOptions.findIndex(q => q.name === x.name);
                if (index >= 0)
                    response.push(fieldEmployeesOptions[index]);
            });

            return response;
        }
        return [];
    };

    private saveCrewSignature = (crewIndex: number) => {
        const { initialCrewList } = this.state;
        const canvasCrewRef = this.signCanvasCrewRefs[crewIndex];
        if(canvasCrewRef && canvasCrewRef.current && !canvasCrewRef.current.isEmpty()) {
            const signatureData = canvasCrewRef.current.getTrimmedCanvas().toDataURL('image/png');
            
            const iS: IFormResponseByIDCrew[] = [...this.state.data.crew];
            let record: any = { ...iS[crewIndex] };

            const initialCrewRecord = record.id > 0 && initialCrewList?.find(x => x.id === record.id);

            if(initialCrewRecord && record.id > 0) {
                record = {
                    ...record,
                    id: initialCrewRecord.signature ? 0 : record.id,
                    signature: signatureData,
                    timestamp: new Date().toLocaleString()
                }
            }
            else {
                record = {
                    ...record,
                    id: record.id === 0 ? 0 : record.id,
                    signature: signatureData,
                    timestamp: new Date().toLocaleString()
                }
            }

            // record = {
            //     ...record,
            //     signature: signatureData,
            //     timestamp: new Date().toLocaleString()
            // }

            iS[crewIndex] = record;
            canvasCrewRef.current.clear();

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    crew: iS
                }
            });
        }
    };

    private clearCrewSignature = (crewIndex: number): void => {
        // this.signCanvas.current.clear();
        const canvasCrewRef = this.signCanvasCrewRefs[crewIndex];
        if (canvasCrewRef && canvasCrewRef.current) {
            canvasCrewRef.current.clear();
        }

        // const iS: any[] = [...this.state.data.crew];
        // let record: any = { ...iS[crewIndex] };

        // record = {
        //     ...record,
        //     signature: null,
        //     // timestamp: null
        // }
        // iS[crewIndex] = record;

        // this.setState({

        //     ...this.state,
        //     data: {
        //         ...this.state.data,
        //         crew: iS
        //     }
        // });
    };

    private saveGuestSignature = (guestIndex: number) => {
        const { initialGuestsList } = this.state;
        const canvasGuestRef = this.signCanvasGuestsRefs[guestIndex];
        if(canvasGuestRef && canvasGuestRef.current && !canvasGuestRef.current.isEmpty()) {
            const signatureData = canvasGuestRef.current.getTrimmedCanvas().toDataURL('image/png');
            
            const iS: any[] = this.state.data.guests ? [...this.state.data.guests] : [];
            let record: any = { ...iS[guestIndex] };
            const initialGuestRecord = record.id > 0 && initialGuestsList?.find(x => x.id === record.id);

            if(initialGuestRecord && record.id > 0) {
                record = {
                    ...record,
                    id: initialGuestRecord.signature ? 0 : record.id,
                    signature: signatureData,
                    timestamp: new Date().toLocaleString()
                }
            }
            else {
                record = {
                    ...record,
                    id: record.id === 0 ? 0 : record.id,
                    signature: signatureData,
                    timestamp: new Date().toLocaleString()
                }
            }

            iS[guestIndex] = record;
            canvasGuestRef.current.clear();

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    guests: iS
                }
            });
        }
    };

    private clearGuestSignature = (guestIndex: number): void => {
        // this.signCanvas.current.clear();
        const canvasGuestRef = this.signCanvasGuestsRefs[guestIndex];
        if (canvasGuestRef && canvasGuestRef.current) {
            canvasGuestRef.current.clear();
        }

        // const iS: any[] = this.state.data.visitors ? [...this.state.data.visitors] : [];
        // let record: any = { ...iS[visitorIndex] };

        // record = {
        //     ...record,
        //     signature: null,
        //     // timestamp: null
        // }
        // iS[visitorIndex] = record;

        // this.setState({

        //     ...this.state,
        //     data: {
        //         ...this.state.data,
        //         visitors: iS
        //     }
        // });
    };

    private handleCopyExternalLinkForGuests = () => {
        navigator.clipboard.writeText(`https://apps.sureway.ca` + ROUTE.EXTERNAL.PSI + `?id=` + this.state.data.id)
        .then(() => {
            this.setState({
                ...this.state,
                copyExternalLinkForGuests: true
            })
            setTimeout(() => this.setState({
                ...this.state,
                copyExternalLinkForGuests: false
            }), 5000); // Reset after 2 seconds
        });
    };

    private handleGuestChange = (options: string[]) => {
        // console.log(options);
        const selectedGuests = options;
        // console.log(files, questionIndex)
        let iS: IFormResponseByIDGuest[] = this.state.data.guests ? [...this.state.data.guests] : [];
        let guests: IFormResponseByIDGuest[] =  [];

        selectedGuests.forEach(v => {
            const index = iS.length > 0 ? iS.findIndex((x:any) => x.name ===  v.trim()) : -1;
            if (index < 0) {
                guests.push({id: 0, name: v.trim(), signature: null});
            }
            else {
                guests.push(iS[index]);
            }
        })

        // console.log(iS)
        // let record: IChecklistQuestion = { ...iS[questionIndex] };
        // const currentOptions = record.options ? record.options : "";
        // record = {
        //    ...record,
        //     options: selectedOptions.map(x => x.trim()).join(","),
        //     flagged_Options: ""
        // }

        // iS[questionIndex] = record;
            
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                guests: guests
            }
        });
    }

    private getGuests = (guests: IFormResponseByIDGuest[]): string[] => {
        if (guests) {
            const assignedGuests = guests !== null ? guests.map(x => x.name) : [];
            return assignedGuests;
        }
        return [];
    }

    private handleDownload = (q: IChecklistQuestionFile, id: number): void => {
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            this.downloadFormAttachmentEndpoint(q.name, id, "Question");
        }
    };

    private downloadFormAttachmentEndpoint = async (fileName: string, id: number, page: string): Promise<void> => {
        // console.log(fileName, id)
        if (fileName !== null && id > 0) {
            if (hasPayload(this.props.token)) {

                await fetch(END_POINTS.FIELD.DOWNLOAD_FORM_ATTACHMENT, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            File_Name: fileName,
                            ID: id,
                            Page: page,
                        },
                        token: this.props.token.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleQuestionImageUpload = async (event: any, questionIndex: number, sectionIndex: number): Promise<void> => {

        const files = event;
        // console.log(files, questionIndex)
        const iS: any[] = [...this.state.data.responses];
        let record: any = { ...iS[sectionIndex].questions[questionIndex] };
        const popupImgs = record.pictures ? [...record.pictures] : [];;

        if (files !== null) {
            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String
                        }) : 
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String
                        });

                    record = {
                        ...record,
                        pictures: popupImgs
                    }

                    iS[sectionIndex].questions[questionIndex] = record;
            
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            responses: iS
                        }
                    });
                }
            }));
        }
    };

    private onQuestionImgDelete = (name: string, imgIndex: number, questionIndex: number, sectionIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS: any[] = [...this.state.data.responses];
            let record: any = { ...iS[sectionIndex].questions[questionIndex] };
            const popupImgs = [...record.pictures];
            popupImgs.splice(imgIndex, 1);
            record = {
                ...record,
                pictures: popupImgs
            }
            
            iS[sectionIndex].questions[questionIndex] = record;
            
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    responses: iS
                }
            });
        }
    };

    private handleQuestionImageName = (name: string, value: string, imgIndex: number, questionIndex: number, sectionIndex: number): void => {
        const iS: any[] = [...this.state.data.responses];
        let record: any = { ...iS[sectionIndex].questions[questionIndex] };
        const popupImgs = [...record.pictures];
        // const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[imgIndex], [name]: value };
        popupImgs[imgIndex] = picture;

        record = {
            ...record,
            pictures: popupImgs
        }

        iS[sectionIndex].questions[questionIndex] = record;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                responses: iS
            }
        });
    };

    private handleTaskImageUpload = async (event: any, taskIndex: number): Promise<void> => {

        const files = event;
        // console.log(files, questionIndex)
        const iS: any[] = [...this.state.data.tasks];
        let record: any = { ...iS[taskIndex] };
        const popupImgs = record.pictures ? [...record.pictures] : [];

        if (files !== null) {
            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String
                        }) : 
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String
                        });

                    record = {
                        ...record,
                        pictures: popupImgs
                    }

                    iS[taskIndex] = record;
            
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            tasks: iS
                        }
                    });
                }
            }));
        }
    };

    private onTaskImgDelete = (name: string, imgIndex: number, taskIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS: any[] = [...this.state.data.tasks];
            let record: any = { ...iS[taskIndex]};
            const popupImgs = [...record.pictures];
            popupImgs.splice(imgIndex, 1);
            record = {
                ...record,
                pictures: popupImgs
            }
            
            iS[taskIndex] = record;
            
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    tasks: iS
                }
            });
        }
    };

    private handleTaskImageName = (name: string, value: string, imgIndex: number, taskIndex: number): void => {
        const iS: any[] = [...this.state.data.tasks];
        let record: any = { ...iS[taskIndex] };
        const popupImgs = [...record.pictures];
        // const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[imgIndex], [name]: value };
        popupImgs[imgIndex] = picture;

        record = {
            ...record,
            pictures: popupImgs
        }

        iS[taskIndex] = record;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                tasks: iS
            }
        });
    };

    private handleAdditionalImageUpload = async (event: any): Promise<void> => {

        const files = event;
        const popupImgs = this.state.data.pictures ? [...this.state.data.pictures] : [];
        // const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String,
                            timestamp: new Date().toLocaleString()
                        }) : 
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String,
                            timestamp: new Date().toLocaleString()
                        });

                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            pictures: popupImgs
                        }
                    });
                
                }
            }));
        }
    };

    private onAdditionalImgDelete = (name: string, imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const popupImgs = this.state.data.pictures ? [...this.state.data.pictures] : [];
            popupImgs.splice(imgIndex, 1);
            
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    pictures: popupImgs
                }
            });
        }
    };

    private handleAdditionalImageName = (name: string, value: string, imgIndex: number): void => {
        const popupImgs = this.state.data.pictures ? [...this.state.data.pictures] : [];

        const picture = { ...popupImgs[imgIndex], [name]: value };
        popupImgs[imgIndex] = picture;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                pictures: popupImgs
            }
        });
    };

    private handleAdditionalFileUpload = (attachments: Array<File | DataTransferItem>): void => {

        const files = this.state.data.files ? this.state.data.files : [];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;

                Object.values(attachments).forEach((x: any, index) => {
                    // console.log(x)
                    if(x.type.includes("image")) {
                        alert("Please select only an attachment")
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                files.map((att: any, i: number) => {x.name.substring(0, x.name.lastIndexOf("."))
                                    // console.log(img.description, description)
                                    if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                        isDuplicateName = true;
                                    }
                                    return isDuplicateName
                                })
                                isDuplicateName ?
                                    files.push({
                                        name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                        base64String: reader.result.toString()
                                    }) : 
                                    files.push({
                                        name:  x.name,
                                        base64String: reader.result.toString()
                                    });
                                // files.push({
                                //     name: x.name,
                                //     base64String: reader.result.toString()
                                // });
    
                                if (attachments.length === index + 1) {
                                    resolve(true);
                                };
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                },
            });
        });
    };

    private handleAdditionalFileDownload = (q: IFormResponseFile): void => {
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            this.downloadFormAttachmentEndpoint(q.name, this.state.data.id, "PSI");
        }
    };

    // private downloadAdditionalFile = async (fileName: string): Promise<void> => {
    //     if (fileName !== null) {
    //         if (hasPayload(this.props.token)) {
    //             await fetch(END_POINTS.FIELD.FORMS.DOWNLOAD_PSI_FILE, {
    //                 method: "POST",
    //                 headers: { "Content-Type": "application/json" },
    //                 body: JSON.stringify({
    //                     token: this.props.token.payload.response.token,
    //                     request: {
    //                         "File_Name": fileName,
    //                         "ID": this.state.data.id,
    //                     },
    //                 })
    //             })
    //                 .then((res: any) => {
    //                     return res.blob();
    //                 })
    //                 .then(blob => {
    //                     let url = window.URL.createObjectURL(blob);
    //                     let a = document.createElement('a');
    //                     a.href = url;
    //                     a.download = fileName;
    //                     document.body.appendChild(a);
    //                     a.click();
    //                     a.remove();
    //                 })
    //                 .catch((res) => console.log(res));
    //         };
    //     };
    // };

    private onAdditionalFileDelete = (fileIdx: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const files = [...this.state.data.files];
            files.splice(fileIdx, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                }
            });
        }
    };

    private handleCrewSignatureImageUpload = async (event: any): Promise<void> => {

        const files = event;
        let popupImg = this.state.data.crew_Sign_In_Upload ;
        // const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImg = {
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0],
                        base64String,
                        // timestamp: new Date().toLocaleString()
                    }

                    // popupImgs.map((img, i) => {
                    //     // console.log(img.description, description)
                    //     if(img.description === description.split(".")[0]) {
                    //         isDuplicateDescription = true;
                    //     }
                    //     return isDuplicateDescription
                    // })
                    // isDuplicateDescription ?
                    //     popupImgs.push({
                    //         name: imgId + "." + description.split(".")[1],
                    //         description: description.split(".")[0] + "_1",
                    //         base64String,
                    //         timestamp: new Date().toLocaleString()
                    //     }) : 
                    //     popupImgs.push({
                    //         name: imgId + "." + description.split(".")[1],
                    //         description: description.split(".")[0],
                    //         base64String,
                    //         timestamp: new Date().toLocaleString()
                    //     });

                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            crew_Sign_In_Upload: popupImg
                        }
                    });
                
                }
            }));
        }
    };

    private onCrewSignatureImgDelete = (): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    crew_Sign_In_Upload: null
                }
            });
        }
    };

    private handleCrewImageName = (name: string, value: string): void => {
        let popupImg = this.state.data.crew_Sign_In_Upload;

        popupImg = {
            ...popupImg,
            [name]: value
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                crew_Sign_In_Upload: popupImg
            }
        });
    };

    private clearSignature = (): void => {
        this.signCanvas.current.clear();
        // this.setState({
        //     ...this.state,
        //     data: {
        //         ...this.state.data,
        //         signature: ""
        //     }
        // });
    };

    private saveSignature = () => {
        // console.log(this.signCanvas.current.isEmpty())
        if(!this.signCanvas.current.isEmpty()) {
            const dataUrl = this.signCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    signature: dataUrl,
                    foreman_Signed: userName,
                    foreman_Signed_Date: new Date().toLocaleString()
                }
            });
            this.clearSignature()
        }
    };

    private handleFormResponseVersionHistory = async(data: any, page: string): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: data.id,
                    page: page
                }
            });
        }
        if(page === "Form_Sheet")
            this.setState({ formResponseVersionHistoryPopup: true });
        if(page === "Form_Sheet_Sections")
            this.setState({ formSheetSectionsVersionHistoryPopup: true });
        if(page === "Form_Sheet_Tasks")
            this.setState({ formSheetTasksVersionHistoryPopup: true });
        if(page === "Form_Sheet_Hazard_Assessment")
            this.setState({ formSheetHazardAssessmentVersionHistoryPopup: true });
    }

    private handleDownloadPDF = async(data: any): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await fetch(END_POINTS.FIELD.FORMS.DOWNLOAD_FORM_RESPONSE_PDF, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    request: {
                        "id": data.id,
                        "token": this.props.token.payload.response.mobileToken
                    },
                    token: this.props.token.payload.response.token
                })
            })
            .then((res: any) => {
                return res.blob();
            })
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${data.name}-${data.form_Name}_${data.id}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((res) => console.log(res));
        };
    }

    private handleFormResponseVersionHistoryPopupClose = (): void => {
        this.setState({ formResponseVersionHistoryPopup: false });
    };
    private handleFormSheetSectionsVersionHistoryPopupClose = (): void => {
        this.setState({ formSheetSectionsVersionHistoryPopup: false });
    };
    private handleFormSheetTasksVersionHistoryPopupClose = (): void => {
        this.setState({ formSheetTasksVersionHistoryPopup: false });
    };
    private handleFormSheetHazardAssessmentVersionHistoryPopupClose = (): void => {
        this.setState({ formSheetHazardAssessmentVersionHistoryPopup: false });
    };
    
    private handleFormResponseAcknowledgePopupClose = (): void => {
        this.setState({ acknowledge_Required: false });
    };

    private handleFormResponseAcknowledgeClick = (): void => {
        const { data, acknowledge_Required, acknowledge_Status, acknowledge_Form } = this.state;

        if (hasPayload(this.props.token)) {
            const upn = this.props.token.payload.response.upn;

            if(acknowledge_Required && acknowledge_Status === false && acknowledge_Form) {

                const saveAcknowledgeRequest: ISaveAcknowledge = {
                    Form_ID: acknowledge_Form.id,
                    Emp_ID: data.emp_ID ?? 0,
                    Name: data.name,
                    Acknowledged: "Yes",
                    WSS_Log_ID: data.wsS_Log_ID ?? 0,
                    Created_By: upn,
                }
        
                console.log(saveAcknowledgeRequest)
        
                this.props.saveAcknowledgeResponseRequest({
                    token: this.props.token.payload.response.token,
                    request: saveAcknowledgeRequest
                });
            }
        }
        this.setState({ acknowledge_Required: false });
    };

    private handleCancel = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);

        if(query && query.logId && Number(query.logId) > 0) {
            this.setState({ serverError: "" });

            if(query.page === "form_Responses") {
                this.props.history.push({
                    pathname: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX,
                    search: getTokenFromUrl(false)
                });
            }
            else {
                callRouteWithQueryString({
                    route: this.props,
                    search: { id: query.logId.toString() },
                    pathName: ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOG
                });
            }
    
        }
        else {
            this.setState({ serverError: "" });
    
            this.props.history.push({
                pathname: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX,
                search: getTokenFromUrl(false)
            });
    
            if (window.location.href.includes("token")) {
                window.parent.postMessage("goback");
            }
        }

    };

    //#region HandleSave
    private handleSave = async (): Promise<void> => {
        const data = this.state.data;

        if (hasPayload(this.props.token)) {
            const responses: IAddUpdateResponses[] = [];
            const upn = this.props.token.payload.response.upn;

            const convertBacktoQuestions = data.responses.reduce((result :any, group :any) => {
                // Add all questions from each section group to the result array
                return result.concat(group.questions);
            }, []);

            // console.log(convertBacktoQuestions)

            await convertBacktoQuestions.forEach((x:any) => {
                responses.push({
                    ID: x.id,
                    Form_Question_ID: x.form_Question_ID,
                    Question: x.question,
                    Answer: x.answer,
                    Flagged: x.flagged,
                    Comments: x.comments,
                    Pictures: x.pictures ?? [],
                    Type: x.type,
                    Options: x.options,
                });
            });

            const addUpdateRequest: IAddUpdateFormResponse  = {
                ID: data.id,
                Form_ID: data.form_ID,
                Emp_ID: data.emp_ID ?? 0,
                WSS_Log_ID: data.wsS_Log_ID ?? 0,
                Name: data.name,
                WSS_ID: data.wsS_ID === 9999 ? 0 : data.wsS_ID,
                Location_Other: data.location_Other,
                Supervisor: data.supervisor,
                Muster_Point: data.muster_Point,
                Nearest_Medical_Facility: data.nearest_Medical_Facility,
                Responses: responses,
                Department_ID: data.department_ID,
                Task_List: data.task_List,
                Hazard_List: data.hazard_List,
                Tasks: data.tasks,
                Tool_Box_Meeting: data.tool_Box_Meeting,
                Pictures: data.pictures,
                Files: data.files,
                Crew: data.crew, 
                Crew_Sign_In_Upload: data.crew_Sign_In_Upload,
                Guests: data.guests,
                Signature: data.signature,
                Foreman_Signed: data.foreman_Signed,
                Foreman_Signed_Date: data.foreman_Signed_Date,
                Form_Updated: data.form_Updated ? data.form_Updated : "No",
                Created_By: upn,
                Modified_By: upn,
            }

            // console.log(addUpdateRequest)

            if (data.id === 0) {
                this.props.addRequest({
                    token: this.props.token.payload.response.token,
                    request: addUpdateRequest
                });
            } 
            else {
                this.props.updateRequest({
                    token: this.props.token.payload.response.token,
                    request: addUpdateRequest
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleQuestionChange = (name: string, value: string, qIndex: number, index: number): void => {
        if (index !== undefined) {
            const iS = { ...this.state.data };
            let response = {
                ...iS.responses[index].questions[qIndex]
            };

            iS.responses[index].questions[qIndex] = { ...response, [name]: value };

            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private handleOtherForCheckboxAnswerChange = (options: string[], questionIndex: number, index: number, checkboxOptions: any) => {
        if (questionIndex !== undefined) {

            const selectedOptions = options;
            // console.log(selectedOptions)
            const iS = { ...this.state.data };
            let response = {
                ...iS.responses[index].questions[questionIndex]
            };
            let answerArray: string[] = response && response.answer ? response.answer.split(",") : [];

            selectedOptions.forEach(x => {
                if(!answerArray.includes(x.trim())) {
                    answerArray.push(x.trim());
                }
            })

            // answerArray.forEach(x => {
            //     if(!selectedOptions.includes(x.trim()) && !checkboxOptions.includes(x.trim())) {
            //         answerArray = answerArray.filter(item => item !== x.trim())
            //     }
            // })
            answerArray = answerArray.filter(x =>
                selectedOptions.includes(x.trim()) || checkboxOptions.includes(x.trim())
            );

            iS.responses[index].questions[questionIndex] = { ...response, answer: answerArray.join(",") };
                
            this.setState({
                ...this.state,
                data: iS
            });
        }
    }

    private handleQuestionCheckboxesAnswerChange = (name: string, checked: boolean, value: string, questionIndex: number, index: number): void => {
        if (questionIndex !== undefined) {
            const iS = { ...this.state.data };
            let response = {
                ...iS.responses[index].questions[questionIndex]
            };
            let answerArray: string[] = response && response.answer ? response.answer.split(",") : [];

            if(checked) {
                answerArray.push(value)
            }
            else {
                // console.log(checked, value, answerArray)
                answerArray = answerArray.filter(item => item.trim() !== value.trim());
                // console.log(answerArray)
            }
            iS.responses[index].questions[questionIndex] = { ...response, answer: answerArray.join(",") };

            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private handleFormName = async(name: string, value: any): Promise<void> => {
        if(name && value) {
            let errors = { ...this.state.errors };
            if (hasPayload(this.props.token)) {
                if (RequiredFields.includes(name)) {
                    errors = this.errorChecker(name, value.form_Name, errors);
                    if(value.id !== 19) {
                        errors = this.errorChecker("wsS_ID", "", errors);
                    }
                    else {
                        delete errors["wsS_ID"]
                    }
                    delete errors["location_Other"]
                }

                try {
                    const response = await fetch(END_POINTS.FIELD.GET_FORM_BY_TEMPLATE_ID, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            token: this.props.token.payload.response.token,
                            request: {
                                id: value.id,
                            }
                        })
                    });
                
                    const data = await response.json();
                    // console.log("Response data:", data);

                    if(data.message !== "Success") {
                        alert(data.message);
                    }
                    else {
                        const questions: any[] = [];
                        // const upn = this.props.token.payload.response.upn;

                        await data.response?.questions.forEach((x:any) => {
                            const existingAtt = x.attachments ? x.attachments.split(",") : [];
                            const files: IChecklistQuestionFile[] = [];
                            existingAtt.forEach((att: string) => {
                                files.push({ name: att, base64String: "" });
                            });
                            questions.push({
                                id: 0,
                                form_Question_ID: x.id,
                                question: x.question,
                                type: x.type,
                                options: x.options,
                                section: x.section,
                                display_Order: x.display_Order,
                                required: x.required,
                                image_Required: x.image_Required,
                                answer: "",
                                comments: "",
                                flagged: "",
                                pictures: [],
                                // flagged_Options: x.flagged_Options,
                                // Attachments: x.attachments ?? "",
                                admin_Files: files ?? [],
                                admin_Pictures: x.pictures ?? []
                            });
                        });

                        const groupedQuestionsBySection = this.groupedQuestionsBySection(questions)
                        // const groupedQuestionsBySection = questions.reduce((result: any, question:any) => {
                        //     const section = question.section ? question.section.trim() : "Questions"; // Normalize section by trimming spaces
                        //     let sectionGroup = result.find((group: any) => group.section === section);
                        
                        //     if (!sectionGroup) {
                        //         // If the section group doesn't exist, create it
                        //         sectionGroup = { section: section, questions: [] };
                        //         result.push(sectionGroup);
                        //     }
                        
                        //     // Add the current question to the section group
                        //     sectionGroup.questions.push(question);
                        //     return result;
                        // }, []);

                        // data.questions.forEach((x: IChecklistQuestion) => {
                        //     const existingAtt = x.attachments ? x.attachments.split(";") : [];
                        //     const files: IChecklistQuestionFile[] = [];
                        //     existingAtt.forEach((att: string) => {
                        //         files.push({ name: att, base64String: "" });
                        //     });
                        //     x.files = files;
                        // })
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                responses: groupedQuestionsBySection, //questions,
                                form_Name: value.form_Name,
                                [name]: value.id,
                                hazard_Assessment: data.response?.hazard_Assessment,
                                requires_Crew: data.response?.requires_Crew,
                                requires_Guest: data.response?.requires_Guest,
                                requires_Toolbox: data.response?.requires_Toolbox,
                                wsS_ID: 0,
                                location_Other: "",
                                supervisor: "",
                                muster_Point: ""
                                // name: upn
                            },
                            formTemplateFiles: data.response?.attachments,
                            // formTemplate: data.response,
                            errors
                        });
                    }
                } 
                catch (error) {
                    console.error("Fetch error:", error);
                }
            }
        }
    }

    private handleSectionExpand = (name: keyof IAddUpdateFormResponseState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    private handleExpand = (sectionIndex: number) => {
        const iS = { ...this.state.data };
        let response = {
            ...iS.responses[sectionIndex]
        };
        response.expand =!response.expand;

        iS.responses[sectionIndex] = response;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                responses: iS.responses, 
                // name: upn
            }
        });
    };

    private handleCollapseAll = () : void => { 
        const iS = { ...this.state.data };
    
        if(this.state.collapseAll) {
            iS.responses.forEach((response: any) => {
                response.expand = false;
            });
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    responses: iS.responses
                },
                collapseAll: false,
                hazardAssessmentExpand: false,
                additionalDocuments: false,
                crewExpand: false,
                guestsExpand: false,
                foremanSignatureExpand: false,
            });
        }
        else {
            iS.responses.forEach((response: any) => {
                response.expand = true;
            });
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    responses: iS.responses
                },
                collapseAll: true,
                hazardAssessmentExpand: true,
                additionalDocuments: true,
                crewExpand: true,
                guestsExpand: true,
                foremanSignatureExpand: true,
            });
        }
    }

    private groupedQuestionsBySection = (questions: any) : any => {
        // console.log(questions)
        return questions.reduce((result: any, question: any) => {
            const section = question.section ? question.section.trim() : "Questions";
            let sectionGroup = result.find((group: any) => group.section === section);
        
            if (!sectionGroup) {
                // If the section group doesn't exist, create it
                sectionGroup = { section: section, expand: true, questions: [] };
                result.push(sectionGroup);
            }
        
            // Add the current question to the section group
            sectionGroup.questions.push(question);
            return result;
        }, []);
    }

    private onAddTask = (): void => {
        const iS = { ...this.state.data };

        iS.tasks.push({
            id: 0,
            task_ID: 0,
            custom_Task: "",
            flagged: "",
            comments: "",
            hazards: [],
            pictures: [],
            images: "",
        });

        this.setState({ data: iS });
    };

    private handleDeleteTask = (taskIndex:number) : void => {
        const selection = window.confirm(deleteText);
        if (selection) {
            const tasks: any[] = [...this.state.data.tasks];
            tasks.splice(taskIndex, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    tasks
                }
            });
        }
    }

    private handleDeleteHazard = (hazardIndex: number, taskIndex:number) : void => {
        const selection = window.confirm(deleteText);
        if (selection) {
            const iS = { ...this.state.data };
            let hazards = [...iS.tasks[taskIndex].hazards]
                
            // const hazards: any[] = [...this.state.data.tasks[taskIndex].hazards];
            hazards.splice(hazardIndex, 1);

            iS.tasks[taskIndex].hazards = hazards

            this.setState({
                ...this.state,
                data: iS
            });
        }
    }

    private handleTaskInfoChange = (name: string, value: string, taskIndex: number): void => {
        if (taskIndex !== undefined) {
            const iS = { ...this.state.data };
            let task = {
                ...iS.tasks[taskIndex]
            };

            if(name === "task_ID") {
                // console.log(value)
                iS.tasks[taskIndex] = { ...task, [name]: Number(value), custom_Task: ""};
                // if(Number(value) === -1) {
                //     iS.tasks[taskIndex] = { ...task, [name]: Number(value), custom_Task: "", customClick: "Yes"};
                // }
                // else {
                //     iS.tasks[taskIndex] = { ...task, [name]: Number(value), custom_Task: "", customClick: "No"};
                // }
            }
            // else if(name === "custom_Task") {
            //     iS.tasks[taskIndex] = { ...task, [name]: value };
            // }
            else {
                iS.tasks[taskIndex] = { ...task, [name]: value };
            }

            // console.log(iS)

            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private handleAddHazard = (): void => {
        // const iS = {...this.state.data };
        let hazard_List: any[] = this.state.data.hazard_List ? [...this.state.data.hazard_List] : [];

        hazard_List.push({
            id: 0,
            type_ID: 0,
            hazard: "",
            severity: "Low",
            probability: "Low",
            control_Plans: []
        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                hazard_List
            }
        });
    }

    private handleDeleteHazardNew = (hazardIndex:number) : void => {
        const selection = window.confirm(deleteText);
        if (selection) {
            const hazard_List: any[] = [...this.state.data.hazard_List];
            hazard_List.splice(hazardIndex, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    hazard_List
                }
            });
        }
    }

    private handleAddHazardPerTask = (taskIndex: number): void => {
        const iS = {...this.state.data };
        let task = {
            ...iS.tasks[taskIndex]
        };

        task.hazards.push({
            id: 0,
            hazard_ID: 0,
            severity: "Low",
            probability: "Low",
            hazard_Control_IDS: "",
            custom_Hazard_Control: ""
        });

        iS.tasks[taskIndex] = task;
        this.setState({
            ...this.state,
            data: iS
        });
    }

    private handleHazardInfoChange = (name: string, value: string, hazardIndex: number, taskIndex: number): void => {
        const iS = { ...this.state.data };
        let hazard = {
            ...iS.tasks[taskIndex].hazards[hazardIndex]
        };

        if(name === "hazard_ID") {
            iS.tasks[taskIndex].hazards[hazardIndex] = { ...hazard, [name]: Number(value), hazard_Control_IDS: "", custom_Hazard_Control: "" };
        }
        // else if(name === "custom_Hazard") {
        //     iS.tasks[taskIndex].hazards[hazardIndex] = { ...hazard, [name]: value, hazard_ID: 0 };
        // }
        else {
            iS.tasks[taskIndex].hazards[hazardIndex] = { ...hazard, [name]: value };
        }

        // console.log(iS)

        this.setState({
            ...this.state,
            data: iS
        });
    };

    private handleHazardInfoChangeNew = (name: string, value: string, hazardIndex: number): void => {
        const iS = { ...this.state.data };
        let hazard = {
            ...iS.hazard_List[hazardIndex]
        };

        if(name === "type_ID") {
            iS.hazard_List[hazardIndex] = { ...hazard, [name]: Number(value), hazard: "", control_Plans: [] };
        }
        else if(name === "hazard_ID") {
            iS.hazard_List[hazardIndex] = { ...hazard, [name]: Number(value), custom_Hazard: "", hazard_Control_IDS: "", custom_Hazard_Control: "" };
        }
        else if(name === "custom_Hazard") {
            iS.hazard_List[hazardIndex] = { ...hazard, [name]: value, hazard_ID: 0 };
        }
        else {
            iS.hazard_List[hazardIndex] = { ...hazard, [name]: value };
        }

        // console.log(iS)

        this.setState({
            ...this.state,
            data: iS
        });
    };

    private getRiskResult = (probability: string, severity: string) : string => {
        // const levels: any = { "Low": 1, "Medium": 2, "High": 3 };
        // Compare the levels of probability and severity
        // if(probability && severity) {
        //     if (levels[probability] === levels[severity]) {
        //         return probability;
        //     } else if (levels[probability] > levels[severity]) {
        //         return probability; // If probability is higher, return probability
        //     } else {
        //         return severity; // If severity is higher, return severity
        //     }
        // }
        // else {
        //     return ""
        // }
        let risk = "";

        if (probability === severity) {
            risk = probability;
        } 
        else if ((probability === "Medium" && severity === "Low") || (probability === "Low" && severity === "Medium")) {
            risk = "Low";
        } 
        else if ((probability === "Low" && severity === "High") || (probability === "High" && severity === "Low")) {
            risk = "Medium";
        }
        else if ((probability === "High" && severity === "Medium") || (probability === "Medium" && severity === "High")) {
            risk = "High";
        } 

        return risk;
    }

    private handleHazardChangeNew = async (values: string[], hazardIndex: number): Promise<void> => {
        const iS = { ...this.state.data };
        let hazard = {
            ...iS.hazard_List[hazardIndex]
        };

        let selectedhazard: string = hazard ? hazard.hazard : "";
        selectedhazard = values.length > 0 ? values[values.length - 1] : "";
        // console.log(selectedhazard)
        iS.hazard_List[hazardIndex].hazard = selectedhazard;
        iS.hazard_List[hazardIndex].control_Plans = [];
            
        this.setState({
            ...this.state,
            data: iS
        });
    }

    private handleCustomControlPlanChangeNew = async (values: string[], hazardIndex: number): Promise<void> => {
        const selectedCustomControlPlans = values;
            // console.log(selectedCustomControlPlans)
            const iS = { ...this.state.data };
            let hazard = {
                ...iS.hazard_List[hazardIndex]
            };
            let controlPlansArray: string[] = hazard ? hazard.control_Plans : [];

            selectedCustomControlPlans.forEach(x => {
                if(!(controlPlansArray.includes(x) || controlPlansArray.includes(x.trim()))) {
                    controlPlansArray.push(x.trim());
                }
            })
            // console.log(controlPlansArray)

            controlPlansArray = controlPlansArray.filter(x =>
                selectedCustomControlPlans.map(x => x.trim()).includes(x.trim()) 
            );

            iS.hazard_List[hazardIndex].control_Plans = controlPlansArray;
            // console.log(iS.hazard_List[hazardIndex].control_Plans)
                
            this.setState({
                ...this.state,
                data: iS
            });
    }

    // private handleHazardControlPlanChangeNew = async (e: any, values: IHazardControl[], hazardIndex: number): Promise<void> => {
    //     // console.log(values)
    //     const iS = { ...this.state.data };
    //     let hazard = {
    //         ...iS.hazard_List[hazardIndex]
    //     };
    //     const selectedHazardControlPlanArray: number[] = hazard.hazard_Control_IDS && hazard.hazard_Control_IDS !== null ? hazard.hazard_Control_IDS.split(",").map(Number) : [];

    //     await values.forEach((q: IHazardControl) => {
    //         const index = selectedHazardControlPlanArray.length > 0 ? selectedHazardControlPlanArray.findIndex((x) => x === q.id) : -1;
    //         if (index < 0) {
    //             selectedHazardControlPlanArray.push(q.id);
    //         }
    //     });

    //     iS.hazard_List[hazardIndex].hazard_Control_IDS = selectedHazardControlPlanArray.join(",");
    //     // console.log(iS)
    //     this.setState({
    //         ...this.state,
    //         data: iS
    //     });
    // };

    private handleHazardControlPlanChange = async (e: any, values: IHazardControl[], hazardIndex: number, taskIndex: number): Promise<void> => {
        // console.log(values)
        const iS = { ...this.state.data };
        let hazard = {
            ...iS.tasks[taskIndex].hazards[hazardIndex]
        };
        const selectedHazardControlPlanArray: number[] = hazard.hazard_Control_IDS && hazard.hazard_Control_IDS !== null ? hazard.hazard_Control_IDS.split(",").map(Number) : [];

        await values.forEach((q: IHazardControl) => {
            const index = selectedHazardControlPlanArray.length > 0 ? selectedHazardControlPlanArray.findIndex((x) => x === q.id) : -1;
            if (index < 0) {
                selectedHazardControlPlanArray.push(q.id);
            }
        });

        iS.tasks[taskIndex].hazards[hazardIndex].hazard_Control_IDS = selectedHazardControlPlanArray.join(",");
        // console.log(iS)
        this.setState({
            ...this.state,
            data: iS
        });
    };

    private handleHazardControlPlanRemove = async (value: any, hazardIndex: number, taskIndex: number): Promise<void> => {
        const iS = { ...this.state.data };
        // console.log(value)
        let hazard = {
            ...iS.tasks[taskIndex].hazards[hazardIndex]
        };
        const selectedHazardControlPlanArray: number[] = hazard.hazard_Control_IDS && hazard.hazard_Control_IDS !== null ? hazard.hazard_Control_IDS.split(",").map(Number) : [];
        const controlPlanOptions: IHazardControl[] = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.hazard_controls : [];
        const find = controlPlanOptions?.find(x => x.control === value);

        if(find) {
            const index = selectedHazardControlPlanArray.length > 0 ? selectedHazardControlPlanArray.findIndex((x) => x === find.id) : -1;
            if (index >= 0) {
                selectedHazardControlPlanArray.splice(index, 1);
            }
    
            iS.tasks[taskIndex].hazards[hazardIndex].hazard_Control_IDS = selectedHazardControlPlanArray.join(",");
    
            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private getSelectedHazardControlPlan = (value: string, controlPlanOptions:IHazardControl[] ): IHazardControl[] => {
        if (hasPayload(this.props.getLookup) && value) {

            const response: IHazardControl[] = [];
            const selectedHazardControlPlans = value !== null ? value.split(",").map(Number) : [];
            // const controlPlanOptions: IHazardControl[] = this.props.getLookup.payload.response.hazard_controls;

            selectedHazardControlPlans.forEach((x) => {
                const index = controlPlanOptions.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(controlPlanOptions[index]);
            });

            return response;
        }
        return [];
    };

    private setDataToState = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.history.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable || pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {
                    if (Number(query.id) !== 0) {
                        if (this.state.data.id !== Number(query.id)) {
                            // console.log(this.props.getWSSLogInfoByID.kind)
                            // console.log(this.props.getFormResponseByID.kind)
                            // console.log(hasPayload(this.props.getFormResponseByID))
                            // console.log(hasPayload(this.props.getWSSLogInfoByID))
                            if (hasPayload(this.props.getFormResponseByID)) {
                                
                                const data = this.props.getFormResponseByID.payload.response;
                                const existingAdditionalFiles = data.attachments ? data.attachments.split(";") : [];
                                const additionalFiles: IFormResponseFile[] = [];

                                existingAdditionalFiles.forEach(x => {
                                    additionalFiles.push({ name: x, base64String: "" });
                                });
                                // console.log(data)
                                const getWSSLogInfoByIDData = hasPayload(this.props.getWSSLogInfoByID) && this.props.getWSSLogInfoByID.payload.response;

                                if(data) {
                                    if(data.department_ID && data.department_ID > 0) {
                                        if (hasPayload(this.props.token))
                                            await this.props.getHazardControlPlanListByDepartmentIDRequest({
                                                token: this.props.token.payload.response.token,
                                                request: {
                                                    department_id: data.department_ID
                                                }
                                            });
                                    }
                                    try {
                                        const response = await fetch(END_POINTS.FIELD.GET_FORM_BY_TEMPLATE_ID, {
                                            method: "POST",
                                            headers: { "Content-Type": "application/json" },
                                            body: JSON.stringify({
                                                token: this.props.token.payload.response.token,
                                                request: {
                                                    id: data.form_ID,
                                                }
                                            })
                                        });
                                    
                                        const templateData = await response.json();
                                        // console.log("Response data:", templateData);
                    
                                        if(templateData.message !== "Success") {
                                            alert(templateData.message);
                                        }
                                        else {
                                            await templateData.response?.questions.forEach((x:any) => {
                                                const existingAtt = x.attachments ? x.attachments.split(",") : [];
                                                let files: IChecklistQuestionFile[] = [];
                                                existingAtt.forEach((att: string) => {
                                                    files.push({ name: att, base64String: "" });
                                                });

                                                data.responses.forEach((r: IResponse) => {
                                                    if(r.form_Question_ID === x.id) {
                                                        r.type = x.type ?? "";
                                                        r.options = x.options ?? "";
                                                        r.admin_Files = files ?? [];
                                                        r.admin_Pictures = x.pictures ?? [];
                                                        r.section = x.section;
                                                        r.display_Order = x.display_Order;
                                                        r.required = x.required;
                                                        r.image_Required = x.image_Required;
                                                    }
                                                });

                                            });

                                            const groupedQuestionsBySection = this.groupedQuestionsBySection(data.responses);
                                            const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
                                            const location = data.wsS_ID ? wssList?.find((x: any) => x.wsS_ID === Number(data.wsS_ID)) : null; 

                                            this.setState({
                                                ...this.state,
                                                data : {
                                                    ...data,
                                                    responses: groupedQuestionsBySection,
                                                    hazard_Assessment: templateData.response?.hazard_Assessment,
                                                    requires_Crew: templateData.response?.requires_Crew,
                                                    requires_Guest: templateData.response?.requires_Guest,
                                                    requires_Toolbox: templateData.response?.requires_Toolbox,
                                                    wsS_Log_ID: query.logId ? Number(query.logId) : null,
                                                    job: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.job : location ? location.job : "",
                                                    working_Site: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.working_Site : "",
                                                    supervisor: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.supervisor : data.supervisor,
                                                    work_Description: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.work_Description : "",
                                                    prime_Contractor: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.prime_Contractor : "",
                                                    nearest_Medical_Facility: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.nearest_Medical_Facility : "",
                                                    nearest_Medical_Facility_Distance: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.nearest_Medical_Facility_Distance : "",
                                                    muster_Point: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.muster_Point : location ? location.muster_Point : "",
                                                    date: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.date : "",
                                                    form_Updated: "No",
                                                    files: additionalFiles
                                                },
                                                crewNamesOptions: getWSSLogInfoByIDData ? getWSSLogInfoByIDData.crews : [],
                                                initialCrewList: data.crew,
                                                initialGuestsList: data.guests,
                                                initialHazardList: data.hazard_List,
                                                acknowledge_Required: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Required ?? false : false,
                                                acknowledge_Status: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Status ?? false : false,
                                                acknowledge_Form: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Form ?? undefined : undefined,
                                            });
                                        }
                                    } 
                                    catch (error) {
                                        console.error("Fetch error:", error);
                                    }
                                }
                            } 
                            else {
                                if(isNotLoaded(this.props.getFormResponseByID)) {
                                    this.getFormResponseByIDData();
                                };
                                if(query.logId && isNotLoaded(this.props.getWSSLogInfoByID)) {
                                    this.getWSSLogInfoByIDData();
                                };
                            }
                        }
                        // if(query.logId && (hasPayload(this.props.getWSSLogInfoByID))) {
                        //     const getWSSLogInfoByIDData = hasPayload(this.props.getWSSLogInfoByID) && this.props.getWSSLogInfoByID.payload.response;
                        //     console.log(this.props.getWSSLogInfoByID.kind)
                        //     if(hasPayload(this.props.getWSSLogInfoByID)) {
                        //         this.setState({
                        //             ...this.state,
                        //             data : {
                        //                 ...this.state.data,
                        //                 // responses: groupedQuestionsBySection,
                        //                 // hazard_Assessment: templateData.response?.hazard_Assessment,
                        //                 // requires_Crew: templateData.response?.requires_Crew,
                        //                 // requires_Guest: templateData.response?.requires_Guest,
                        //                 // requires_Toolbox: templateData.response?.requires_Toolbox,
                        //                 wsS_Log_ID: query.logId ? Number(query.logId) : null,
                        //                 job: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.job : this.state.data.job,
                        //                 working_Site: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.working_Site : this.state.data.working_Site,
                        //                 supervisor: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.supervisor : this.state.data.supervisor,
                        //                 work_Description: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.work_Description : this.state.data.work_Description,
                        //                 prime_Contractor: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.prime_Contractor : this.state.data.prime_Contractor,
                        //                 nearest_Medical_Facility: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.nearest_Medical_Facility : this.state.data.nearest_Medical_Facility,
                        //                 nearest_Medical_Facility_Distance: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.nearest_Medical_Facility_Distance : this.state.data.nearest_Medical_Facility_Distance,
                        //                 muster_Point: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.muster_Point : this.state.data.muster_Point,
                        //                 date: query.logId && getWSSLogInfoByIDData ? getWSSLogInfoByIDData.date : this.state.data.date,
                        //             },
                        //             crewNamesOptions: getWSSLogInfoByIDData ? getWSSLogInfoByIDData.crews : this.state.data.crews,
                        //         });
                        //     }
                        // }
                    } 
                    else {
                        let errors: ById<IFieldErrorKeyValue> = {};
                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        if(Number(query.logId) > 0) {
                            if(hasPayload(this.props.getWSSLogInfoByID)) {
                                const getWSSLogInfoByIDData = this.props.getWSSLogInfoByID.payload.response;
                                // console.log(getWSSLogInfoByIDData)
                                if(Number(query.form_ID) > 0) {
                                    try {
                                        const response = await fetch(END_POINTS.FIELD.GET_FORM_BY_TEMPLATE_ID, {
                                            method: "POST",
                                            headers: { "Content-Type": "application/json" },
                                            body: JSON.stringify({
                                                token: this.props.token.payload.response.token,
                                                request: {
                                                    id: Number(query.form_ID),
                                                }
                                            })
                                        });
                                    
                                        const templateData = await response.json();
                                        // console.log("Response data:", templateData);
                    
                                        if(templateData.message !== "Success") {
                                            alert(templateData.message);
                                        }
                                        else {
                                            const questions: any[] = [];
                                            await templateData.response?.questions.forEach((x:any) => {
                                                const existingAtt = x.attachments ? x.attachments.split(",") : [];
                                                const files: IChecklistQuestionFile[] = [];
                                                existingAtt.forEach((att: string) => {
                                                    files.push({ name: att, base64String: "" });
                                                });
                                                questions.push({
                                                    id: 0,
                                                    form_Question_ID: x.id,
                                                    question: x.question,
                                                    type: x.type,
                                                    options: x.options,
                                                    section: x.section,
                                                    display_Order: x.display_Order,
                                                    required: x.required,
                                                    image_Required: x.image_Required,
                                                    answer: "",
                                                    comments: "",
                                                    flagged: "",
                                                    pictures: [],
                                                    admin_Files: files ?? [],
                                                    admin_Pictures: x.pictures ?? []
                                                });
                                            });

                                            const groupedQuestionsBySection = this.groupedQuestionsBySection(questions);
                                            // const groupedQuestionsBySection = questions.reduce((result: any, question:any) => {
                                            //     const section = question.section ? question.section.trim() : "Questions"; // Normalize section by trimming spaces
                                            //     let sectionGroup = result.find((group: any) => group.section === section);
                                            
                                            //     if (!sectionGroup) {
                                            //         // If the section group doesn't exist, create it
                                            //         sectionGroup = { section: section, questions: [] };
                                            //         result.push(sectionGroup);
                                            //     }
                                            
                                            //     // Add the current question to the section group
                                            //     sectionGroup.questions.push(question);
                                            //     return result;
                                            // }, []);

                                            // console.log(groupedQuestionsBySection)

                                            delete errors["form_ID"];
                                            this.setState({
                                                ...this.state,
                                                data : {
                                                    ...this.state.data,
                                                    responses: groupedQuestionsBySection, //questions
                                                    form_ID: Number(query.form_ID),
                                                    form_Name: templateData.response?.form_Name,
                                                    hazard_Assessment: templateData.response?.hazard_Assessment,
                                                    requires_Crew: templateData.response?.requires_Crew,
                                                    requires_Guest: templateData.response?.requires_Guest,
                                                    requires_Toolbox: templateData.response?.requires_Toolbox,
                                                    wsS_Log_ID: Number(query.logId),
                                                    job: getWSSLogInfoByIDData.job,
                                                    working_Site: getWSSLogInfoByIDData.working_Site,
                                                    supervisor: getWSSLogInfoByIDData.supervisor,
                                                    work_Description: getWSSLogInfoByIDData.work_Description,
                                                    prime_Contractor: getWSSLogInfoByIDData.prime_Contractor,
                                                    nearest_Medical_Facility: getWSSLogInfoByIDData.nearest_Medical_Facility,
                                                    nearest_Medical_Facility_Distance: getWSSLogInfoByIDData.nearest_Medical_Facility_Distance,
                                                    muster_Point: getWSSLogInfoByIDData.muster_Point,
                                                    date: getWSSLogInfoByIDData ? getWSSLogInfoByIDData.date : "",
                                                },
                                                crewNamesOptions: getWSSLogInfoByIDData.crews,
                                                acknowledge_Required: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Required ?? false : false,
                                                acknowledge_Status: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Status ?? false : false,
                                                acknowledge_Form: (Number(query.logId) > 0 && getWSSLogInfoByIDData) ? getWSSLogInfoByIDData.acknowledge_Form ?? undefined : undefined,
                                                formTemplateFiles: templateData.response?.attachments,
                                                errors
                                            });
                                        }
                                    } 
                                    catch (error) {
                                        console.error("Fetch error:", error);
                                    }
                                }

                            }
                            else {
                                if(isNotLoaded(this.props.getWSSLogInfoByID)) {
                                    this.getWSSLogInfoByIDData();
                                };
                            }
                        }
                        // console.log(this.props.token)
                        this.setState({ errors });
                    }

                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLookups();
                    };
                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getFormResponseByIDData = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token)) {
            if(Number(query.id) > 0) {
                await this.props.getFormResponseByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: Number(query.id),
                        username: this.props.token.payload.response.upn
                    }
                });
            }
        }
    };

    private getWSSLogInfoByIDData = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token) && Number(query.logId) > 0) {
            await this.props.getWSSLogInfoByIDRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: Number(query.logId)
                }
            });
        }
    };

    private getLookups = async(): Promise<void> => {
        if (hasPayload(this.props.token))
            await this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Form_Web"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateFormResponseStoreProps => ({
    token: getToken(state),
    getLookup: getFormLookup(state),
    // getFormResponses: getFormResponses(state),
    getFormResponseByID: getFormResponseByID(state),
    getWSSLogInfoByID: getWSSLogInfoByID(state),
    addRequestStatus: addFormResponse(state),
    updateRequestStatus: updateFormResponse(state),
    saveAcknowledgeResponseStatus: saveAcknowledgeResponse(state),
    getFormSignatureHistory: getFormSignatureHistory(state),
    // getFormResponseVersionHistoryByID: getFormResponseVersionHistoryByID(state),
    getFormVersionHistory: getFormVersionHistory(state),
    getHazardControlPlanListByDepartmentID: getHazardControlPlanListByDepartmentID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateFormResponseDispatchProps => ({
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getFormResponseByIDRequest: (data: IGetFormResponseByIDRequest) => dispatch(getFormResponseByIDLoadAction(data)),
    getWSSLogInfoByIDRequest: (data: IGetWSSLogInfoByIDRequest) => dispatch(getWSSLogInfoByIDLoadAction(data)),
    // getFormResponsesRequest: (data: IGetFormResponsesRequest) => dispatch(getFormResponsesLoadAction(data)),
    addRequest: (data: IAddFormResponseRequest) => dispatch(addFormResponseLoadAction(data)),
    updateRequest: (data: IUpdateFormResponseRequest) => dispatch(updateFormResponseLoadAction(data)),
    saveAcknowledgeResponseRequest: (data: ISaveAcknowledgeResponseRequest) => dispatch(saveAcknowledgeResponseLoadAction(data)),
    getFormSignatureHistoryRequest: (data: IGetFormSignatureHistoryRequest) => dispatch(getFormSignatureHistoryLoadAction(data)),
    // getFormResponseVersionHistoryByIDRequest: (data: IGetFormResponseVersionHistoryByIDRequest) => dispatch(getFormResponseVersionHistoryByIDLoadAction(data)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),
    getHazardControlPlanListByDepartmentIDRequest: (data: IGetHazardControlPlanListByDepartmentIDRequest) => dispatch(getHazardControlPlanListByDepartmentIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateForm);