import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString,  getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../../shared/checkBox";
import { IFormLookup, IFormLookupRequest, IHazard } from "../../../../redux/field/getFormLookup/getFormLookupConstants";
import { getFormLookup } from "../../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getFormLookupLoadAction } from "../../../../redux/field/getFormLookup/getFormLookupActions";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { ISaveControlPlanRequest, ISaveControlPlan } from "../../../../redux/field/saveControlPlan/saveControlPlanConstants";
import { saveControlPlanLoadAction } from "../../../../redux/field/saveControlPlan/saveControlPlanActions";
import { IGetControlPlansNew, IGetControlPlansNewRequest } from "../../../../redux/field/getControlPlansNew/getControlPlansNewConstants";
import { getControlPlansNew } from "../../../../redux/field/getControlPlansNew/getControlPlansNewAccessor";
import { getControlPlansNewLoadAction } from "../../../../redux/field/getControlPlansNew/getControlPlansNewActions";
import { saveControlPlanStatus } from "../../../../redux/field/saveControlPlan/saveControlPlanAccessor";

const RequiredFields: string[] = ["control_Plan", "hazard"];


interface IAddUpdateControlPlanComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    getControlPlansNew: Server<SurewayAPIResponse<ById<IGetControlPlansNew>>>;
    saveControlPlanStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateControlPlanComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getControlPlansNewRequest: (data: IGetControlPlansNewRequest) => unknown
    saveControlPlanRequest: (data: ISaveControlPlanRequest) => unknown;
};

interface IAddUpdateControlPlanOwnProps {
    id?: string;
};

interface IAddUpdateControlPlanComponentState {
    data: IGetControlPlansNew;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateControlPlanStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateControlPlanComponentProps =
    RouteComponentProps
    & IAddUpdateControlPlanOwnProps
    & IAddUpdateControlPlanComponentStoreProps
    & IAddUpdateControlPlanComponentDispatchProps;

class AddUpdateControlPlan extends PureComponent<IAddUpdateControlPlanComponentProps, IAddUpdateControlPlanComponentState> {

    public constructor(props: IAddUpdateControlPlanComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                control_Plan: "",
                hazard_List: [],
                active: "Yes",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLookup();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateControlPlanComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.saveControlPlanStatus !== prevProps.saveControlPlanStatus) {

                if (this.props.saveControlPlanStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveControlPlanStatus.message });

                if (hasPayload(this.props.saveControlPlanStatus) && this.props.saveControlPlanStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = data.id > 0 ? "Update CONTROL PLAN" : "Add CONTROL PLAN";
        const { token, saveControlPlanStatus } = this.props;
        const getRole = pageAccessCheck(token, "formsAdmin");
        const hazardList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.hazards : [];
        const disabled = (getRole === false) ? true : undefined;
        // const disabled = undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");
        // console.log(data)

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateControlPlanStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}CONTROL PLAN</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            name="control_Plan"
                                            label="Control Plan"
                                            fullWidth={true}
                                            disabled={disabled || data.id > 0}
                                            errorText={errors["control_Plan"] ? errors["control_Plan"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.control_Plan ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <DevExtremePopupLookup
                                            data={hazardList ?? []}
                                            multiple={true}
                                            id="hazard-search"
                                            placeHolder="Hazard"
                                            disabled={undefined}
                                            displayExp="hazard"
                                            name="id"
                                            errorName="hazard"
                                            onSelectionRemove={this.handleHazardRemove}
                                            errors={errors}
                                            selectionRow={true}
                                            onSelectionChanged={this.handleHazardSelectionChanged}
                                            currentVal={this.getHazards(data.hazard_List ?? [])}
                                            onClick={undefinedFunction}
                                            columns={[
                                                { name: "hazard", caption: "Hazard", type: "string" },
                                                { name: "type", caption: "Hazard Type", type: "string" },
                                                { name: "department_Names", caption: "Departments", type: "string" },
                                            ]}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }

                    <RequestStatus requestStatus={saveControlPlanStatus.kind} successMessage="Control Plan has been added/updated successfully" />
                </AddUpdateControlPlanStyles>
            </PageSpacing>
        );
    }

    private getHazards = (assignedHazardIds: number[]): IHazard[] => {
        if (assignedHazardIds) {
            const hazardList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.hazards : [];
            const response: IHazard[] = [];

            assignedHazardIds.forEach((x) => {
                const index = hazardList.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(hazardList[index]);
            });

            return response;
        }
        return [];
    };

    private handleHazardSelectionChanged = (row:any): void => {
        let errors = { ...this.state.errors };
        const selectedHazardList: any[] = row.selectedRowsData;
        // // console.log(selectedHazardList);
        const data = { ...this.state.data };
        let assigned_Hazard = data.hazard_List ? data.hazard_List : [];

        selectedHazardList.forEach((x) => {
            const index = assigned_Hazard.length > 0 ? assigned_Hazard.findIndex((e) => e === x.id) : -1;
            if (index < 0) {
                assigned_Hazard.push(x.id);
            }
        });

        errors = this.errorChecker("hazard", assigned_Hazard.join(","), errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                hazard_List: assigned_Hazard
            },
            errors
        });
    }

    private handleHazardRemove = (value: string): void => {
        // // console.log(value)
        let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        const hazardList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.hazards : [];

        let assigned_Hazard = data.hazard_List ? data.hazard_List : [];
        const hazard = hazardList ? hazardList.find(q => q.hazard === value) : null;

        if(hazard) {
            const index = assigned_Hazard.length > 0 ? assigned_Hazard.findIndex((x) => x === hazard.id) : -1;
            if (index >= 0) {
                assigned_Hazard.splice(index, 1);
            }
        }

        errors = this.errorChecker("hazard", assigned_Hazard.join(","), errors);
        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                hazard_List: assigned_Hazard,
            },
            errors
        });
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.ADMIN.CONTROL_PLANS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {
        const { data } = this.state;

        if (hasPayload(this.props.token) && hasPayload(this.props.getControlPlansNew)) {
            let request: ISaveControlPlan = {
                ID: data.id,
                Control_Plan: data.control_Plan.trim(),
                Hazard_List: data.hazard_List ?? [],
                Active: data.active,
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };

            // console.log(request)
            if (data.id === 0) {
                let isUnique = true;
                Object.values(this.props.getControlPlansNew.payload.response)?.map((t:any) => {
                    if(t.control_Plan.toLowerCase().trim() === data.control_Plan.toLowerCase().trim()) {
                        isUnique = false;
                    }
                })
                if(isUnique) {
                    this.props.saveControlPlanRequest({
                        token: this.props.token.payload.response.token,
                        request
                    });
                }
                else {
                    alert("Control Plan already exists! Please Modify exisiting one.");
                }
            } 
            else {
                this.props.saveControlPlanRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private getLookup = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Control_Plan"
                }
            });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {

                    if (hasPayload(this.props.getControlPlansNew)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getControlPlansNew.payload.response[+query.id];
                                // console.log(data)
                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } 
                    else {
                        this.props.getControlPlansNewRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateControlPlanComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFormLookup(state),
    getControlPlansNew: getControlPlansNew(state),
    saveControlPlanStatus: saveControlPlanStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateControlPlanComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getControlPlansNewRequest: (data: IGetControlPlansNewRequest) => dispatch(getControlPlansNewLoadAction(data)),
    saveControlPlanRequest: (data: ISaveControlPlanRequest) => dispatch(saveControlPlanLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateControlPlan);