
export enum IADD_FILTER_REQUEST {
    REQUEST = "addFilter/ADD_FILTER_REQUEST",
    SUCCESS = "addFilter/ADD_FILTER_SUCCESS",
    FAILED = "addFilter/ADD_FILTER_FAILED"
};

export interface IAddFilterRequest {
    token: string;
    request: IAddUpdateFilter;
};

export interface IAddUpdateFilter {
    ID?: number;
    Models: string;
    Created_By: string;
    Modified_By: string;
    Make_ID: number;
    Parts: IAddUpdateFilterPart[];
};

export interface IAddUpdateFilterPart {
    ID?: number;
    Unit_No: string;
    Unit_ID: number;
    Type: string;
    Sub_Type: string;
    Part_No: string;
    Quantity: number;
};