import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { BLACK_COLOR, EXTRA_LIGHT_BLUE_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { deleteText, getDate, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ICostCode, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { AddIcon, ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import { IGetVendorOrderByIDRequest, IGetVendorOrderByID } from "../../../redux/field/vendors/getVendorOrderByID/getVendorOrderByIDConstants";
import { getVendorOrderByIDLoadAction } from "../../../redux/field/vendors/getVendorOrderByID/getVendorOrderByIDActions";
import { IVendorJobList, IVendorLookup, IVendorLookupRequest } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsConstants";
import { getVendorLookupsLoadAction } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsActions";
import { getVendorOrderByID } from "../../../redux/field/vendors/getVendorOrderByID/getVendorOrderByIDAccessor";
import { getVendorLookups } from "../../../redux/field/vendors/getVendorLookups/getVendorLookupsAccessor";
import { IAddVendorOrderRequest } from "../../../redux/field/vendors/addVendorOrder/addVendorOrderConstants";
import { IUpdateVendorOrderRequest } from "../../../redux/field/vendors/updateVendorOrder/updateVendorOrderConstants";
import { addVendorOrder } from "../../../redux/field/vendors/addVendorOrder/addVendorOrderAccessor";
import { updateVendorOrder } from "../../../redux/field/vendors/updateVendorOrder/updateVendorOrderAccessor";
import { addVendorOrderLoadAction } from "../../../redux/field/vendors/addVendorOrder/addVendorOrderActions";
import { updateVendorOrderLoadAction } from "../../../redux/field/vendors/updateVendorOrder/updateVendorOrderActions";
import { IGetVendorOrderRequest, IVendorOrder, IVendorOrderWork } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersConstants";
import LADropDownForJobs from "../../shared/dropdownForJobs";
import splitDate from "../../shared/splitDate";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { LACenteredLoading } from "../../shared/loading";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { IGetCostCodesByJobID, IGetCostCodesByJobIDRequest } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDConstants";
import { getCostCodesByJobID } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDAccessor";
import { getCostCodesByJobIDLoadAction } from "../../../redux/field/getCostCodesByJobID/getCostCodesByJobIDActions";
import { END_POINTS } from "../../../redux/endpoints";
import { getVendorOrder } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersAccessor";
import { getVendorOrderLoadAction } from "../../../redux/field/vendors/getVendorOrders/getVendorOrdersActions";


const RequiredFields: string[] = ["po", "vendor", "job_ID"];

interface IAddUpdateVendorOrderComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IVendorLookup>>;
    getCostCodesByJobID: Server<SurewayAPIResponse<IGetCostCodesByJobID[]>>;
    addVendorOrderStatus: Server<SurewayAPIResponse<any>>;
    updateVendorOrderStatus: Server<SurewayAPIResponse<string>>;
    getVendorOrderByID: Server<SurewayAPIResponse<IGetVendorOrderByID>>;
    getVendorOrders: Server<SurewayAPIResponse<ById<IVendorOrder>>>;
};

interface IAddUpdateVendorOrderComponentDispatchProps {
    getLookupsRequest: (data: IVendorLookupRequest) => unknown;
    getCostCodesByJobIDRequest: (data: IGetCostCodesByJobIDRequest) => unknown;
    getVendorOrderByIDRequest: (data: IGetVendorOrderByIDRequest) => unknown;
    addVendorOrderRequest: (data: IAddVendorOrderRequest) => unknown;
    updateVendorOrderRequest: (data: IUpdateVendorOrderRequest) => unknown;
    getVendorOrdersRequest: (data: IGetVendorOrderRequest) => unknown;
};

interface IAddUpdateVendorOrderOwnProps {
    id?: string;
};

interface IAddUpdateVendorOrderComponentState {
    data: IGetVendorOrderByID;
    logsExpand: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateVendorOrderStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .even-row {
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    }
        
    .odd-row {
        background-color: ${WHITE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
    
`;

type IAddUpdateVendorOrderComponentProps =
    RouteComponentProps
    & IAddUpdateVendorOrderOwnProps
    & IAddUpdateVendorOrderComponentStoreProps
    & IAddUpdateVendorOrderComponentDispatchProps;

class AddUpdateVendorOrder extends PureComponent<IAddUpdateVendorOrderComponentProps, IAddUpdateVendorOrderComponentState> {

    public constructor(props: IAddUpdateVendorOrderComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                requested_By: userName,
                date: new Date().toISOString(),
                po: "",
                vendor: "",
                items: [],
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            logsExpand: true,
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.getData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateVendorOrderComponentProps): void {
        if (this.props !== prevProps) {
            if (this.props.getCostCodesByJobID === prevProps.getCostCodesByJobID) {
                this.setDataToState();
            }

            if (this.props.addVendorOrderStatus !== prevProps.addVendorOrderStatus) {

                if (this.props.addVendorOrderStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addVendorOrderStatus.message });

                if (hasPayload(this.props.addVendorOrderStatus) && this.props.addVendorOrderStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            };

            if (this.props.updateVendorOrderStatus !== prevProps.updateVendorOrderStatus) {

                if (this.props.updateVendorOrderStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateVendorOrderStatus.message });

                if (hasPayload(this.props.updateVendorOrderStatus) && this.props.updateVendorOrderStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, logsExpand } = this.state;
        // const { vendors, items } = data;

        const { token, addVendorOrderStatus, updateVendorOrderStatus, getLookup, getCostCodesByJobID } = this.props;
        const getRole = pageAccessCheck(token, "vendorAccess");
        const disabled = (getRole === ReadOnly) ? true : undefined;
        const disbleEdit = data.id > 0 ? true : undefined;
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        const jobList = hasPayload(getLookup) ? getLookup.payload.response.jobs : [];
        const costCodeList = hasPayload(getCostCodesByJobID) && getCostCodesByJobID.payload.response ? getCostCodesByJobID.payload.response : [];
        // const filterCostCodes = costCodeList.length > 0 ? costCodeList.map((c, index) => ({
        //     id: index + 1,
        //     name: c
        //   })) : [];
        // const filterCostCodes = this.filterCostCodes(costCodeList, jobList);
        // const onJob = (event: unknown, value: IVendorJobList): void => this.handleNumber("job_ID", value !== null ? value.id : vendors.job_ID);
        const onJob = (value: any): Promise<void> => this.handleJob(value !== null ? value : data.job_ID);
        // const onRequestedBy = (event: unknown, value: IForeman): void => this.handleChange("requested_By", value !== null ? value.upn : data.requested_By);
        const onRequestedBy = (event: unknown, value: IForeman): Promise<void> => this.handleRequestedBy("requested_By", value !== null ? value.upn : "");

        if(getLookup.kind === STATUS_ENUM.LOADING || getCostCodesByJobID.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <AddUpdateVendorOrderStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "} VENDOR PO ORDER</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                name="date"
                                label="Date"
                                fullWidth={true}
                                variant="outlined"
                                type="date"
                                disabled={true}
                                onChange={this.handleChange}
                                value={splitDate(data.date)}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            {/* {vendors.id > 0 ? <LATextField
                                name="requested_By"
                                label="Requested By"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                onChange={undefinedFunction}
                                value={vendors.requested_By}
                            /> : */}
                            <LAAutoComplete
                                disabled={disbleEdit || disabled}
                                multiple={false}
                                option={foremans}
                                autoHighlight={true}
                                onChange={onRequestedBy}
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                dropDownPlaceHolder="Requested By"
                                selectionRemove={undefinedFunction}
                                errorText={errors["requested_By"] ? errors["requested_By"].message : undefined}
                                value={data.requested_By ? foremans?.find(q => q.upn === data.requested_By) : null}
                                defaultValue={data.requested_By ? foremans?.find(q => q.upn === data.requested_By) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            {/* <LADropDownForJobs
                                onChange={onJob}
                                option={jobList ?? []}
                                autoHighlight={true}
                                getOptionLabel="job_No"
                                disabled={undefined}
                                dropDownPlaceHolder="Job"
                                filterSelectedOptions={true}
                                value={jobList ? jobList.find(q => q.id === vendors.job_ID) : ""}
                                defaultValue={jobList ? jobList.find(q => q.id === vendors.job_ID) : ""}
                                disExp={[{ objName: "job_No", size: 4 }, { objName: "name", size: 3 }, { objName: "company", size: 2 }]}
                            /> */}
                            <DevExtremePopupLookup
                                data={jobList ?? []}
                                id="job-search"
                                placeHolder="Job"
                                disabled={disbleEdit}
                                displayExp="job_No"
                                returnExp="id"
                                name="id"
                                errorName="job_ID"
                                errors={errors}
                                currentVal={data.job_ID}
                                onClick={onJob}
                                columns={[
                                    { name: "job_No", caption: "Job No.", type: "string" },
                                    { name: "name", caption: "Name", type: "string" },
                                    { name: "company", caption: "Company", type: "string" },
                                    // { name: "location_Details", caption: "Location", type: "string" },
                                    // { name: "active_Repair_Status", caption: "Repair Status", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                name="po"
                                label="PO"
                                fullWidth={true}
                                variant="outlined"
                                value={data.po ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["po"] ? errors["po"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={8}>
                            <LATextArea
                                minRows={1}
                                rowsMax={3}
                                name="vendor"
                                label="Vendor"
                                fullWidth={true}
                                disabled={disabled}
                                variant="outlined"
                                value={data.vendor ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["vendor"] ? errors["vendor"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LAExpansionPanel color={GREEN_COLOR} textColor={WHITE_COLOR} label="Logs" expanded={logsExpand} onClick={() => this.handleExpand("logsExpand")}>
                                {/* <LAPaperWithLessPadding> */}
                                <LAGrid>

                                    {/* <LAGridItem className="text-center" xs={12}>
                                        <strong>LOGS</strong>
                                        <hr />
                                    </LAGridItem> */}
                                    <LAGridItem xs={12}>
                                        <LAIconButton
                                            label="Add New"
                                            icon={<AddIcon />}
                                            onClick={this.handleLogAdd}
                                        />
                                    </LAGridItem>

                                    {data.items.length > 0 &&
                                        data.items.map((x: IVendorOrderWork, index: number) => {
                                            const onChange = (name: string, value: string) => this.handleLogsTableChange(name, value, index);
                                            const onNumber = (name: string, value: string) => this.handleLogsTableChange(name, value, index, true);
                                            const onCDMS = (event: unknown, value: IIdName) => this.handleLogsTableChange("in_CDMS", value !== null ? value.name : x.in_CDMS, index);
                                            const onExplorer = (event: unknown, value: IIdName) => this.handleLogsTableChange("in_Explorer", value !== null ? value.name : x.in_Explorer, index);
                                            const onRog = (event: unknown, value: IIdName) => this.handleLogsTableChange("rog", value !== null ? value.name : x.rog, index);
                                            // const onCostCode = (event: unknown, value: IIdName) => this.handleLogsTableChange("cost_Code", value !== null ? value.name : x.cost_Code, index);
                                            const onCostCode = (value: string) => this.handleLogsTableChange("cost_Code", value ? value : x.cost_Code, index);
                                            const onRowDelete = (e: unknown) => this.handleLogDelete(index);

                                            return <LAGridItem key={index} xs={12} >
                                                <LAPaperWithLessPadding className={index % 2 ? "odd-row" : "even-row"} key={index}>
                                                    <LAGrid>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <DevExtremePopupLookup
                                                                id="cost-code-search"
                                                                placeHolder="Cost Code"
                                                                disabled={data.job_ID ? undefined : true}
                                                                displayExp="cost_Code"
                                                                errors={errors}
                                                                name="cost_Code"
                                                                errorName="cost_Code"
                                                                // multiple={true}
                                                                currentVal={x.cost_Code ?? ""}
                                                                data={costCodeList ?? []}
                                                                onClick={onCostCode}
                                                                columns={[
                                                                    { name: "cost_Code", caption: "Cost Code", type: "string" },
                                                                    { name: "description", caption: "Description", type: "string" },
                                                                    { name: "job", caption: "Job", type: "string" },
                                                                ]}
                                                            />
                                                        </LAGridItem>
                                                        {/* <LAGridItem xs={12} sm={6} md={3}>
                                                            <LAAutoComplete
                                                                multiple={false}
                                                                option={filterCostCodes}
                                                                autoHighlight={true}
                                                                onChange={onCostCode}
                                                                getOptionLabel="name"
                                                                filterSelectedOptions={true}
                                                                dropDownPlaceHolder="Cost Code"
                                                                selectionRemove={undefinedFunction}
                                                                disabled={data.job_ID ? undefined : true}
                                                                errorText={errors["cost_Code"] ? errors["cost_Code"].message : undefined}
                                                                value={filterCostCodes && x.cost_Code ? filterCostCodes.find(q => q.name === x.cost_Code) : null}
                                                                defaultValue={filterCostCodes && x.cost_Code ? filterCostCodes.find(q => q.name === x.cost_Code) : null}
                                                            /> 
                                                        </LAGridItem> */}

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="scope"
                                                                label="Scope"
                                                                disabled={disabled}
                                                                value={x.scope ?? ""}
                                                                onChange={onChange}
                                                                type="text"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextArea
                                                                minRows={1}
                                                                rowsMax={3}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="description"
                                                                label="Description"
                                                                disabled={undefined}
                                                                value={x.description ?? ""}
                                                                onChange={onChange}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="ticket"
                                                                label="Ticket #"
                                                                disabled={disabled}
                                                                value={x.ticket ?? ""}
                                                                onChange={onChange}
                                                                type="text"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="invoice"
                                                                label="Invoice"
                                                                disabled={disabled}
                                                                value={x.invoice ?? ""}
                                                                onChange={onChange}
                                                                type="text"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="leM_No"
                                                                label="LEM #"
                                                                disabled={disabled}
                                                                value={x.leM_No ?? ""}
                                                                onChange={onChange}
                                                                type="text"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LAAutoComplete
                                                                disabled={disabled}
                                                                multiple={false}
                                                                option={YesOrNo}
                                                                autoHighlight={true}
                                                                onChange={onCDMS}
                                                                indexPosition={index}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder="In CDMS"
                                                                selectionRemove={undefinedFunction}
                                                                value={x.in_CDMS ? YesOrNo.find(q => q.name === x.in_CDMS) : null}
                                                                defaultValue={x.in_CDMS ? YesOrNo.find(q => q.name === x.in_CDMS) : null}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LAAutoComplete
                                                                disabled={disabled}
                                                                multiple={false}
                                                                option={YesOrNo}
                                                                indexPosition={index}
                                                                autoHighlight={true}
                                                                onChange={onExplorer}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder="In Explorer"
                                                                selectionRemove={undefinedFunction}
                                                                value={x.in_Explorer ? YesOrNo.find(q => q.name === x.in_Explorer) : null}
                                                                defaultValue={x.in_Explorer ? YesOrNo.find(q => q.name === x.in_Explorer) : null}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="committed_Amount"
                                                                label="Committed Amount"
                                                                disabled={disabled}
                                                                value={x.committed_Amount ?? ""}
                                                                onChange={onNumber}
                                                                type="number"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="invoiced_Amount"
                                                                label="Invoiced Amount"
                                                                disabled={disabled}
                                                                value={x.invoiced_Amount ?? ""}
                                                                onChange={onNumber}
                                                                type="number"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="accrued_Cost"
                                                                label="Accrued Cost"
                                                                disabled={disabled}
                                                                value={x.accrued_Cost ?? ""}
                                                                onChange={onChange}
                                                                type="text"
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LAAutoComplete
                                                                disabled={disabled}
                                                                multiple={false}
                                                                option={YesOrNo}
                                                                autoHighlight={true}
                                                                onChange={onRog}
                                                                indexPosition={index}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder="ROG"
                                                                selectionRemove={undefinedFunction}
                                                                value={x.rog ? YesOrNo.find(q => q.name === x.rog) : null}
                                                                defaultValue={x.rog ? YesOrNo.find(q => q.name === x.rog) : null}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <strong>ROG Date</strong>
                                                            <LATextField
                                                                name="roG_Date"
                                                                label=""
                                                                fullWidth={true}
                                                                indexPosition={index}
                                                                variant="outlined"
                                                                type="date"
                                                                disabled={disabled}
                                                                onChange={onChange}
                                                                value={x.roG_Date ? splitDate(x.roG_Date) : ""}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} >
                                                            <LAIconButton
                                                                key={index}
                                                                label="Delete"
                                                                icon={<DeleteIcon />}
                                                                onClick={onRowDelete}
                                                                disabled={disabled || x.id ? true : undefined}
                                                            />
                                                        </LAGridItem>

                                                    </LAGrid>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })
                                    }
                                </LAGrid>
                                {/* </LAPaperWithLessPadding> */}
                            </LAExpansionPanel>
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}


                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : undefined}
                            />
                        </LAGridItem>

                    </LAGrid>}


                <RequestStatus requestStatus={addVendorOrderStatus.kind} successMessage="Vendor Log has been added successfully" />
                <RequestStatus requestStatus={updateVendorOrderStatus.kind} successMessage="Vendor Log has been updated successfully" />
            </AddUpdateVendorOrderStyles>
        );
    }

    private handleExpand = (name: keyof IAddUpdateVendorOrderComponentState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    // private filterCostCodes = (data: ICostCode[], jobList: IVendorJobList[]): ICostCode[] => {
    //     const jobId = this.state.data.job_ID;
    //     if (jobId) {

    //         const findJob = jobList.find(x => x.id === jobId);
    //         console.log(findJob, data)
    //         if(findJob)
    //             return data?.filter((x) => x.job === findJob.job_No);
    //     }
    //     return data;
    // };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.VENDOR.INDEX,
            search: getTokenFromUrl(false)
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleLogAdd = () => {
        if (hasPayload(this.props.token)) {
            const upn = this.props.token.payload.response.upn;
            const iS: IVendorOrderWork[] = [...this.state.data.items];

            iS.push({
                id: 0,
                order_ID: 0,
                scope: "",
                description: "",
                cost_Code: "",
                ticket: "",
                invoice: "",
                leM_No: "",
                in_CDMS: "",
                in_Explorer: "",
                accrued_Cost: "",
                accrued_Revenue: "",
                rog: "",
                roG_Date: "",
                created: "",
                created_By: upn,
                modified: "",
                modified_By: upn
            });

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: iS
                }
            });
        }
    };

    private handleLogsTableChange = (name: string, value: any, index: number, numberType?: true) => {
        if (index !== undefined) {
            const iS: IVendorOrderWork[] = [...this.state.data.items];

            let record: IVendorOrderWork = { ...iS[index] };
            if(name === "cost_Code") {
                const costCodeList = hasPayload(this.props.getCostCodesByJobID) && this.props.getCostCodesByJobID.payload.response ? this.props.getCostCodesByJobID.payload.response : [];
                const find = costCodeList?.find(x => x.cost_Code === value);
                
                record = {
                    ...record,
                    [name]: value,
                    scope: find ? find.description : record.scope
                }
            }
            else {
                record = {
                    ...record,
                    [name]: numberType ? (value ? Number(value) : undefined) : value
                }
            }
            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: iS
                }
            });
        }
    };

    private handleLogDelete = async (index: number) => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const logs: IVendorOrderWork[] = [...this.state.data.items];
            logs.splice(index, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: logs
                }
            });
        }
    }

    private handleSave = async (): Promise<void> => {
        let {
            data
        } = this.state;

        if (hasPayload(this.props.token)) {

            const upn = this.props.token.payload.response.upn;

            if (data.id === 0) {
                data.created_By = upn;
                data.modified_By = upn;

                this.props.addVendorOrderRequest({
                    token: this.props.token.payload.response.token,
                    request: data
                });
            } else {
                data.modified_By = upn;

                this.props.updateVendorOrderRequest({
                    token: this.props.token.payload.response.token,
                    request: data
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleRequestedBy = async(name: string, value: string): Promise<void> => {
        let errors = {...this.state.errors };

        if(hasPayload(this.props.getVendorOrders) && value) {
            const vendorOrders = this.props.getVendorOrders.payload.response;
            const x = Object.values(vendorOrders).filter(x => x.requested_By === value).sort((a,b) => a.id > b.id ? -1 : 1)[0];
            let vendorLogs = [...this.state.data.items];
            if(vendorLogs.length > 0) {
                vendorLogs = vendorLogs.map(log => ({
                   ...log,
                    cost_Code: "",
                    scope: ""
                }));
            }
            if(x) {
                // console.log(x)
                if(x.job_ID) {
                    if(hasPayload(this.props.token)) {
                        await fetch(END_POINTS.FIELD.VENDOR.GET_NEXT_PO_BY_JOB_ID, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                request: {
                                    id: x.job_ID
                                },
                                token: this.props.token.payload.response.token
                            })
                        })
                            .then((res: any) => {
                                return res.json();
                            })
                            .then(res => {
                                // console.log(res)
                                errors = this.errorChecker('job_ID', x.job_ID ? x.job_ID.toString() : "", errors);
                                errors = this.errorChecker('po', res.response ? res.response : "", errors);
                                this.setState({
                                    ...this.state,
                                    data: {
                                        ...this.state.data,
                                        requested_By: value,
                                        job_ID: x.job_ID,
                                        po: res.response,
                                        items: vendorLogs
                                    },
                                    errors
                                });
                            })
                            .catch((res) => console.log(res));
                    }
                    this.getCostCodesByJobID(x.job_ID);
                }
            }
            else {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        requested_By: value,
                        items: vendorLogs
                    },
                    errors
                });
            }
        }
    }

    private handleJob = async(value: number): Promise<void> => {
        // console.log(value)
        if (value !== undefined && value) {
            let vendorWorks = [...this.state.data.items];
            if(vendorWorks.length > 0) {
                vendorWorks = vendorWorks.map(work => ({
                   ...work,
                    cost_Code: "",
                    scope: ""
                }));
            }

            let errors = { ...this.state.errors };

            // if (RequiredFields.includes(name))
            errors = this.errorChecker('job_ID', value.toString(), errors);

            if(hasPayload(this.props.token)) {
                await fetch(END_POINTS.FIELD.VENDOR.GET_NEXT_PO_BY_JOB_ID, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            id: value
                        },
                        token: this.props.token.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.json();
                    })
                    .then(res => {
                        // console.log(res)
                        errors = this.errorChecker('po', res.response ? res.response : "", errors);
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                job_ID: value,
                                po: res.response,
                                items: vendorWorks
                            },
                            errors
                        });
                    })
                    .catch((res) => console.log(res));
            }

            // this.setState({
            //     ...this.state,
            //     data: {
            //         ...this.state.data,
            //         job_ID: value
            //     },
            //     errors
            // });
            this.getCostCodesByJobID(value);
        }
    }

    // private handleNumber = (name: string, value: number | undefined): void => {
    //     if (value !== undefined) {
    //         let errors = { ...this.state.errors };

    //         if (RequiredFields.includes(name))
    //             errors = this.errorChecker(name, value.toString(), errors);

    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...this.state.data,
    //                 vendors: {
    //                     ...this.state.data.vendors,
    //                     [name]: value
    //                 }
    //             },
    //             errors
    //         });
    //     }
    // };

    private setDataToState = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.history.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "vendorAccess") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (this.state.data.id !== Number(query.id)) {
                            if (hasPayload(this.props.getVendorOrderByID)) {
                                let errors = this.state.errors;
                                const data = this.props.getVendorOrderByID.payload.response;

                                // if (RequiredFields.includes(name))
                                errors = this.errorChecker("po", data.po ? data.po.toString() : "", errors);
                                errors = this.errorChecker("vendor", data.vendor ? data.vendor.toString() : "", errors);
                                errors = this.errorChecker("job_ID", data.job_ID ? data.job_ID.toString() : "", errors);
                                if(data.job_ID) {
                                    this.getCostCodesByJobID(data.job_ID)
                                }
                                
                                this.setState({
                                    data,
                                    errors
                                });
                            } else {
                                if (isNotLoaded(this.props.getVendorOrderByID)) {
                                    this.getData();
                                };
                            }
                        }
                    } 
                    else {
                        let errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        if(hasPayload(this.props.getVendorOrders)) {
                            const vendorOrders = this.props.getVendorOrders.payload.response;
                            const x = Object.values(vendorOrders).filter(x => x.requested_By === this.state.data.requested_By).sort((a,b) => a.id > b.id ? -1 : 1)[0];
                
                            if(x) {
                                // console.log(x)
                                if(x.job_ID) {
                                    if(hasPayload(this.props.token)) {
                                        await fetch(END_POINTS.FIELD.VENDOR.GET_NEXT_PO_BY_JOB_ID, {
                                            method: "POST",
                                            headers: { "Content-Type": "application/json" },
                                            body: JSON.stringify({
                                                request: {
                                                    id: x.job_ID
                                                },
                                                token: this.props.token.payload.response.token
                                            })
                                        })
                                            .then((res: any) => {
                                                return res.json();
                                            })
                                            .then(res => {
                                                // console.log(res)
                                                errors = this.errorChecker('job_ID', x.job_ID ? x.job_ID.toString() : "", errors);
                                                errors = this.errorChecker('po', res.response ? res.response : "", errors);
                                                this.setState({
                                                    ...this.state,
                                                    data: {
                                                        ...this.state.data,
                                                        job_ID: x.job_ID,
                                                        po: res.response,
                                                    },
                                                    errors
                                                });
                                            })
                                            .catch((res) => console.log(res));
                                    }
                                    this.getCostCodesByJobID(x.job_ID);
                                }
                            }
                        }

                        this.setState({
                            ...this.state,
                            errors
                        });
                    };

                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLook();
                    };
                } else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Vendor"
                }
            });
    };

    private getData = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token)) {
            if (Number(query.id) !== 0) {
                this.props.getVendorOrderByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: Number(query.id)
                    }
                });
            }
        }
    };

    private getCostCodesByJobID = (value: number): void => {
        if (hasPayload(this.props.token)) {
            if (Number(value) !== 0) {
                this.props.getCostCodesByJobIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: Number(value)
                    }
                }); 
            }
        }
    };


    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateVendorOrderComponentStoreProps => ({
    token: getToken(state),
    getLookup: getVendorLookups(state),
    getCostCodesByJobID: getCostCodesByJobID(state),
    getVendorOrderByID: getVendorOrderByID(state),
    addVendorOrderStatus: addVendorOrder(state),
    updateVendorOrderStatus: updateVendorOrder(state),
    getVendorOrders: getVendorOrder(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateVendorOrderComponentDispatchProps => ({
    getLookupsRequest: (data: IVendorLookupRequest) => dispatch(getVendorLookupsLoadAction(data)),
    getCostCodesByJobIDRequest: (data: IGetCostCodesByJobIDRequest) => dispatch(getCostCodesByJobIDLoadAction(data)),
    getVendorOrderByIDRequest: (data: IGetVendorOrderByIDRequest) => dispatch(getVendorOrderByIDLoadAction(data)),
    addVendorOrderRequest: (data: IAddVendorOrderRequest) => dispatch(addVendorOrderLoadAction(data)),
    updateVendorOrderRequest: (data: IUpdateVendorOrderRequest) => dispatch(updateVendorOrderLoadAction(data)),
    getVendorOrdersRequest: (data: IGetVendorOrderRequest) => dispatch(getVendorOrderLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateVendorOrder);