import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST } from "./saveAcknowledgeResponseConstants";
import { ISaveAcknowledgeResponseLoadAction, ISaveAcknowledgeResponseLoadFailedAction, ISaveAcknowledgeResponseSuccessAction, saveAcknowledgeResponseLoadFailedAction, saveAcknowledgeResponseLoadSuccessAction } from "./saveAcknowledgeResponseActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const saveAcknowledgeResponseEpic: Epic = (
    action$: ActionsObservable<ISaveAcknowledgeResponseLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveAcknowledgeResponseSuccessAction | ISaveAcknowledgeResponseLoadFailedAction> =>
    action$.ofType(ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.SAVE_ACKNOWLEDGE_RESPONSE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveAcknowledgeResponseSuccessAction | ISaveAcknowledgeResponseLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveAcknowledgeResponseLoadSuccessAction(response);
                            } else {
                                return saveAcknowledgeResponseLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveAcknowledgeResponseLoadFailedAction(response.message)))
                    )
            )
        );