import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST {
    REQUEST = "getServiceHistoryBySOCTID/GET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST",
    SUCCESS = "getServiceHistoryBySOCTID/GET_SERVICE_HISTORY_BY_SOCT_ID_SUCCESS",
    FAILED = "getServiceHistoryBySOCTID/GET_SERVICE_HISTORY_BY_SOCT_ID_FAILED"
};


export interface IGetServiceHistoryBySOCTIDRequest extends ISurewayTokenRequestBody {
  request: {
    SOCT_ID: number;
  };
};


export interface IServiceHistory {
    id: number,
    service_Date: string,
    unit_Number: string,
    unit_ID: number,
    meter_Reading: number,
    completed_Meter_Reading: number,
    comments: string,
    ecm: number,
    custom_Service: string,
    completed_Date: string,
    email_Shop_Admin: string,
    attachments: string,
    cancelled: string,
    created_By: string,
    modified_By: string,
    created: string,
    modified: string,
    service_Location: string,
    service_Sub_Location: string,
    service_Sub_Location_ID: number,
    current_Location: string,
    current_Sub_Location: string,
    current_Sub_Location_ID: number,
    socT_ID: number,
    admin_Comments: string,
    pM_Sheet_ID: number,
};
