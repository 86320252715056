import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_VENDOR_PO_LOG_REQUEST, ISaveVendorPOLogRequest } from "./saveVendorPOLogConstants";


export interface ISaveVendorPOLogLoadAction {
    type: ISAVE_VENDOR_PO_LOG_REQUEST.REQUEST;
    data: ISaveVendorPOLogRequest
}
export const saveVendorPOLogLoadAction = (data: ISaveVendorPOLogRequest): ISaveVendorPOLogLoadAction => ({
    type: ISAVE_VENDOR_PO_LOG_REQUEST.REQUEST,
    data
});
export interface ISaveVendorPOLogSuccessAction {
    type: ISAVE_VENDOR_PO_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveVendorPOLogLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveVendorPOLogSuccessAction => ({
    type: ISAVE_VENDOR_PO_LOG_REQUEST.SUCCESS,
    message
});
export interface ISaveVendorPOLogLoadFailedAction {
    type: ISAVE_VENDOR_PO_LOG_REQUEST.FAILED;
    message: string;
}
export const saveVendorPOLogLoadFailedAction = (message: string): ISaveVendorPOLogLoadFailedAction => ({
    type: ISAVE_VENDOR_PO_LOG_REQUEST.FAILED,
    message
});
