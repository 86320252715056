import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_HAZARD_REQUEST } from "./updateHazardConstants";
import { IUpdateHazardLoadAction, IUpdateHazardLoadFailedAction, IUpdateHazardSuccessAction, updateHazardLoadFailedAction, updateHazardLoadSuccessAction } from "./updateHazardActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateHazardEpic: Epic = (
    action$: ActionsObservable<IUpdateHazardLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateHazardSuccessAction | IUpdateHazardLoadFailedAction> =>
    action$.ofType(IUPDATE_HAZARD_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.ADMIN.UPDATE_HAZARD,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateHazardSuccessAction | IUpdateHazardLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateHazardLoadSuccessAction(response);
                            } else {
                                return updateHazardLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateHazardLoadFailedAction(response.message)))
                    )
            )
        );