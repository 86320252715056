export enum IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST {
    REQUEST = "getFormResponseByUserToken/GET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST",
    SUCCESS = "getFormResponseByUserToken/GET_FORM_RESPONSE_BY_USER_TOKEN_SUCCESS",
    FAILED = "getFormResponseByUserToken/GET_FORM_RESPONSE_BY_USER_TOKEN_FAILED"
};

export interface IGetFormResponseByUserTokenRequest {
    token: string;
    request: {
        id: number;
        token?: string;
        mode?: string;
    }
};

export interface IFormResponseByUserToken {
    id: number;
    emp_ID: number;
    emp_Name: string;
    form_ID: number;
    form_Name: string;
    site: string;
    work_Description: string;
    date: string;
    urgent_Care_Facility: string;
    muster_Point: string;
    supervisor: string;
    tool_Box_Meeting: string;
    additional_Images: string;
    responses: IFormResponseByUserTokenQuestions[];
    department: string;
    signature: string;
    signature_Date: string;
    tasks: string;
    hazard_List: IFormResponseByUserTokenHazardList[];
    crews: IFormResponseByUserTokenCrewList[];
    guests: IFormResponseByUserTokenCrewList[];
    form_Updated: string;
    foreman_Signed: string;
    attachments: string | null; 
    form_Documents: string | null; 
};

export interface IFormResponseByUserTokenQuestions {
    question: string;
    answer: string;
    comments: string;
    images: string;
    section?: string;
    display_Order?: number;
    expand?: boolean;
};

export interface IFormResponseByUserTokenHazardList {
    hazard_Type: string;
    hazard: string;
    severity: string;
    probability: string;
    control_Plans: string;
    image: string;
};

export interface IFormResponseByUserTokenCrewList {
    name: string;
    signature: string | null;
    last_Signed_Date: string;
};