import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_STYLES_REQUEST, IStyle } from "./getStylesConstants";
import { IGetStylesLoadAction, IGetStylesLoadFailedAction, IGetStylesSuccessAction } from "./getStylesActions";
import { IUPDATE_ATTACHMENT_STYLE_REQUEST } from "../updateStyle/updateStyleConstants";
import { IADD_ATTACHMENT_STYLE_REQUEST } from "../addStyle/addStyleConstants";
import { IAddAttachmentStyleSuccessAction } from "../addStyle/addStyleActions";
import { IUpdateAttachmentStyleSuccessAction } from "../updateStyle/updateStyleActions";

type Actions =
    | IGetStylesLoadAction
    | IGetStylesSuccessAction
    | IGetStylesLoadFailedAction
    | IUpdateAttachmentStyleSuccessAction
    | IAddAttachmentStyleSuccessAction
    | IFlushDataSuccessAction;

export const GetStylesReducer = (state: Server<SurewayAPIResponse<ById<IStyle>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IStyle>>> => {
    switch (action.type) {
        case IGET_STYLES_REQUEST.REQUEST:
            return loading;

        case IGET_STYLES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_STYLES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};