import { IAddUpdateAttachmentType } from "../addType/addTypeConstants";

export enum IUPDATE_ATTACHMENT_TYPE_REQUEST {
    REQUEST = "updateAttachmentType/UPDATE_ATTACHMENT_TYPE_REQUEST",
    SUCCESS = "updateAttachmentType/UPDATE_ATTACHMENT_TYPE_SUCCESS",
    FAILED = "updateAttachmentType/UPDATE_ATTACHMENT_TYPE_FAILED"
};

export interface IUpdateAttachmentTypeRequest {
    token: string;
    request: IAddUpdateAttachmentType;
};