import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST {
    REQUEST = "getRepairLineDefectItemsByRepairID/GET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST",
    SUCCESS = "getRepairLineDefectItemsByRepairID/GET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_SUCCESS",
    FAILED = "getRepairLineDefectItemsByRepairID/GET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_FAILED"
};

export interface IGetRepairLineDefectItemsByRepairIDRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};