
export enum IGET_CONTROL_PLAN_REQUEST {
    REQUEST = "getControlPlans/GET_CONTROL_PLAN_REQUEST",
    SUCCESS = "getControlPlans/GET_CONTROL_PLAN_SUCCESS",
    FAILED = "getControlPlans/GET_CONTROL_PLAN_FAILED"
};

export interface IGetControlPlansRequest {
    token: string;
};

export interface IGetControlPlans {
    id: number;
    control: string;
    active: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

