import { IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST } from "./payrollMechanicLogsUploadConstants";
import { IPayrollMechanicLogsUploadLoadAction, IPayrollMechanicLogsUploadLoadFailedAction, IPayrollMechanicLogsUploadSuccessAction } from "./payrollMechanicLogsUploadActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IPayrollMechanicLogsUploadLoadAction
    | IPayrollMechanicLogsUploadSuccessAction
    | IPayrollMechanicLogsUploadLoadFailedAction
    | IFlushDataSuccessAction;

export const PayrollMechanicLogsUploadReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.REQUEST:
            return loading;

        case IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};