import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST {
    REQUEST = "getRepairItemLineById/GET_REPAIR_LINE_ITEM_BY_ID_REQUEST",
    SUCCESS = "getRepairItemLineById/GET_REPAIR_LINE_ITEM_BY_ID_SUCCESS",
    FAILED = "getRepairItemLineById/GET_REPAIR_LINE_ITEM_BY_ID_FAILED"
};

export interface IGetRepairLineItemByIdRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};