import React, { useState } from "react";
import { IGetAttachmentVersionHistory } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { FormControlLabel, Radio, RadioGroup, TableContainer } from "@material-ui/core";
import { callRouteWithQueryString, getTokenFromUrl, undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { IGetRepairLineHistory } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryConstants";
import { ROUTE } from "../../routes";


interface IRepairLineHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetRepairLineHistory>;
    display: string | undefined;
};

export const RepairLineHistory: React.FC<IRepairLineHistoryProps> = React.memo((props: IRepairLineHistoryProps) => {
    const [view, setView] = useState("all");
    const data = props.data ? Object.values(props.data) : [];
    const filteredData = view==="major_Component_Replaced" ? data.filter(x => x.major_Component_Replacement === "Yes") : data;
    // console.log(filteredData)

    const onOpenDefectItem = (name:string, data: any): void => {
        window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString() + "&defect_ID=" + data.id.toString())
    };

    const onCustomClick = (name:string, e: any): void => {
        // console.log(name, e)
        if(name === "mechanic_Logs") {
            window.open(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_BY_DEFECT_ID + "?id=" + e.row.data.id.toString())
        }
    };

    const onViewClick = (e:any, value: string): void => {
        setView(value);
    };

    const onEdit = (e: any): void => {
        window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + e.row.data.repair_Line_ID.toString() + "&defect_ID=" + e.row.data.id.toString())
    };

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>REPAIR HISTORY - {props.display}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Repair History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <LAGridItem xs={12}>
                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view === "major_Component_Replaced" ? "major_Component_Replaced" : "all"} onChange={onViewClick}>
                                <FormControlLabel value="major_Component_Replaced" control={<Radio />} label="Major Component Replaced" />
                                <FormControlLabel value="all" control={<Radio />} label="All" />
                            </RadioGroup>
                        </LAGridItem>

                        <LADevExtremeGrid
                            data={filteredData}
                            onEdit={onEdit}
                            // onCustomIconClick={onOpenDefectItem}
                            onCustomClick={onCustomClick}
                            columnChoose={true}
                            columnsHiding={false}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            height={700}
                            export={true}
                            removeStyleBtn={true}
                            id="repairLineList"
                            customRowColor={true}
                            exportFileName="RepairLine-History"
                            storageKey="repairLineHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "defect_Item", caption: "Defect Item", type: "string" },
                                { name: "issue", caption: "Issue", type: "string" },
                                { name: "component_Code", caption: "Component Code", type: "number" },
                                { name: "meter_Reading", caption: "Meter Reading", type: "number" },
                                { name: "created", caption: "Created Date", type: "date" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "damaged", caption: "Damaged", type: "string" },
                                { name: "work_Order_No", caption: "WO#/Job-Cost# ", type: "string", renderCell : (e:any) => { return <div>  {(e.row.data.job_No || e.row.data.cost_Code) ?  (e.row.data.job_No && e.row.data.job_No) + "-" + (e.row.data.cost_Code && e.row.data.cost_Code) : e.row.data.work_Order_No && e.row.data.work_Order_No }</div>}},
                                { name: "work_Order_No", caption: "Work Order No.", type: "string", show: false },
                                { name: "job_No", caption: "Job No.", type: "string",  show: false },
                                { name: "cost_Code", caption: "Cost Code", type: "string", show: false},
                                { name: "location_Info", caption: "Location Info", type: "string" },
                                { name: "repair_Location", caption: "Repair Location", type: "string" },
                                { name: "repair_Type", caption: "Repair Type", type: "string"},
                                { name: "winter_Repair", caption: "Winter Repair", type: "string"},
                                { name: "requested_By", caption: "Requested By", type: "string" },
                                { name: "mechanic_Logs", caption: "Mechanic Logs", type: "button"},
                                { name: "manager_Instructions", caption: "Manager Instructions", type: "string" },
                                { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                                { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string" },
                                { name: "assigned_Mech", caption: "Assigned Mechanics", type: "string" },
                                { name: "date_Completed", caption: "Completed Date", type: "date" },
                                { name: "priority", caption: "Priority", type: "string" },
                                { name: "mechanic_Comments", caption: "Mechanic Comments", type: "string" },
                                { name: "serial_No", caption: "Serial No.", type: "string", show: false},
                                { name: "type", caption: "Type", type: "string", show: false },
                                { name: "hours", caption: "Hours", type: "number", show: false },
                                { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string", show: false },
                                { name: "damaged_Notes", caption: "Damaged Notes", type: "string", show: false },
                                { name: "created_Location_Info", caption: "Created Location Info", type: "string", show: false },
                                { name: "repair_Location_Info", caption: "Repair Location Info", type: "string", show: false },
                                // { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                // { name: "components_Details", caption: "Components", type: "string", width: 400, 
                                //     renderCell: (params) => (
                                //         <div style={{ whiteSpace: 'pre-wrap' }}>
                                //         {params.value.split('\n').map((line:string, index:number) => (
                                //             <React.Fragment key={index}>
                                //             {line}
                                //             <br />
                                //             </React.Fragment>
                                //         ))}
                                //         </div>
                                //     )
                                // },
                                
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});