import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_FORM_RESPONSE_REQUEST, IAddFormResponseRequest } from "./addFormResponseConstants";


export interface IAddFormResponseLoadAction {
    type: IADD_FORM_RESPONSE_REQUEST.REQUEST;
    data: IAddFormResponseRequest
}
export const addFormResponseLoadAction = (data: IAddFormResponseRequest): IAddFormResponseLoadAction => ({
    type: IADD_FORM_RESPONSE_REQUEST.REQUEST,
    data
});
export interface IAddFormResponseSuccessAction {
    type: IADD_FORM_RESPONSE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFormResponseLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFormResponseSuccessAction => ({
    type: IADD_FORM_RESPONSE_REQUEST.SUCCESS,
    message
});
export interface IAddFormResponseLoadFailedAction {
    type: IADD_FORM_RESPONSE_REQUEST.FAILED;
    message: string;
}
export const addFormResponseLoadFailedAction = (message: string): IAddFormResponseLoadFailedAction => ({
    type: IADD_FORM_RESPONSE_REQUEST.FAILED,
    message
});
