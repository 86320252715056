import { IControl } from "../getHazards/getHazardsConstants";

export enum IADD_HAZARD_REQUEST {
    REQUEST = "addHazard/ADD_HAZARD_REQUEST",
    SUCCESS = "addHazard/ADD_HAZARD_SUCCESS",
    FAILED = "addHazard/ADD_HAZARD_FAILED"
};

export interface IAddHazardRequest {
    token: string;
    request: IAddUpdateHazard;
};

export interface IAddUpdateHazard {
    ID: number;
    Hazard: string;
    Active: string;
    Type_ID: number;
    Controls: IControl[];
    Created_By: string;
    Modified_By: string;
}
