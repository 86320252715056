import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ST_LOGS_REQUEST, ISTLogs } from "./getSTLogsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSTLogsLoadAction, IGetSTLogsLoadFailedAction, IGetSTLogsSuccessAction, getSTLogsLoadFailedAction, getSTLogsLoadSuccessAction } from "./getSTLogsActions";

export const getSTLogsEpic: Epic = (
    action$: ActionsObservable<IGetSTLogsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSTLogsSuccessAction | IGetSTLogsLoadFailedAction> =>
    action$.ofType(IGET_ST_LOGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISTLogs[]>>(
                    END_POINTS.FIELD.DISPATCH.GET_ST_LOGS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISTLogs[]>): IGetSTLogsSuccessAction => {
                            return getSTLogsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getSTLogsLoadFailedAction(response.message)))
                    )
            )
        );