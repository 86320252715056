import React from 'react';
import {TextField, Paper, Grid, Button, Typography, FormHelperText, Modal, Backdrop, FormLabel, FormControlLabel, Checkbox} from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {RouteComponentProps} from 'react-router-dom';
import {editEquipmentMovesData, export_moves_list} from '../SOCTMain/edit/editMoveHistoryTable';
import {exportWorkingSites} from '../SOCTMain/edit/editSOCTMain';
import {getSOCTWorkingSubSitesByParentID, getSOCTWorkingSubSitesByID, getSOCTWorkingSites} from '../../api/workingsites/workingSitesAPI';
import splitDate from '../../../../shared/splitDate';
import {updateEquipmentMove} from '../../api/equipmentmoves/equipmentMovesAPI';
import {OutlinedRequiredStyle, OutlinedRequiredStyleSelected} from '../../../../shared/styles';
import Autocomplete from "@mui/material/Autocomplete";
import { currentUser } from '../../socthome';

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};
interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
   modalOpen:boolean;
}
var working_site_locations:any[] = [];
var sub_site_locations:any[] = [];
var sub_location_list:any[] = [];
var mapped_location_list:any[] = [];
var disableForm:any;
export default class EditEquipmentMoves extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {modalOpen:true};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'new_location':
                editEquipmentMovesData.new_Location = event.target.value;
                var parent_id=0;   
                exportWorkingSites.map((site:any) => {
                    if(site.site_Name === event.target.value){
                        parent_id = site.id;
                    }
                });
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                break;
            case 'new_sub_location':
                editEquipmentMovesData.new_Sub_Location = event.target.value;
                sub_site_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        editEquipmentMovesData.new_Sub_Location_ID = site.id;
                    }
                });
                break;
            case 'scheduled_move':
                editEquipmentMovesData.scheduled_Move = event.target.value;
                break;
            case 'date_of_move':
                editEquipmentMovesData.date_Of_Move = event.target.value;
                break;
            case 'comments':
                editEquipmentMovesData.comments = event.target.value;
                break;
            case 'cancel_move_check':
                editEquipmentMovesData.cancelled = !editEquipmentMovesData.cancelled;
                break;
        }
        this.setState({});
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    async onFormSave(){
        var res:any = await updateEquipmentMove(editEquipmentMovesData, currentUser);
        if(res.status === 200){
            alert("Succssfully updated equipment Moves");
            this.props.history.goBack();
        }
        else{
            alert("Failed to update equipment Moves");
        }
    }
    async componentDidMount(){
        console.log('here', editEquipmentMovesData)
        let first_id = export_moves_list[0].id;
        disableForm = editEquipmentMovesData.cancelled === "Yes";
        editEquipmentMovesData.cancelled = editEquipmentMovesData.cancelled === "Yes";
        if(first_id != editEquipmentMovesData.id){
            disableForm = true;
        }
        var tempsites:any = await getSOCTWorkingSites(true);
        tempsites.map((location:any) => {
            let location_name = location.site_Name + ", Job #: " + location.job_No;
            working_site_locations.push(location_name);
            mapped_location_list.push(location);
            });
        if(editEquipmentMovesData.new_Location){
            var parent_id:number=0;
            working_site_locations.map((site:any) => {
                if(site.site_Name === editEquipmentMovesData.new_Location){
                    parent_id = site.id;
                }
            });
            sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
            sub_site_locations.map((sub:any) => {
                sub_location_list.push(sub.sub_Site_Name);
            });
            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
        }
        var res:any = await getSOCTWorkingSubSitesByID(editEquipmentMovesData.current_Sub_Location_ID);
        editEquipmentMovesData.current_Location = res.working_Site_Name;
        var new_location:any = await getSOCTWorkingSubSitesByID(editEquipmentMovesData.new_Sub_Location_ID);
        editEquipmentMovesData.new_Location = new_location.working_Site_Name;
        editEquipmentMovesData.new_Sub_Location = new_location.working_Sub_Site_Name;
        this.setState({});
    }
    render(){
        console.log(editEquipmentMovesData)
        return (
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.modalOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            >  
            <Box sx={BoxStyle}>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Modifying Equipment Move</h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Typography
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                >
                                    Unit # - {editEquipmentMovesData.unit_Number}
                                </Typography>
                              
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    <Grid container spacing={6}>
                    <Grid item xs={4}>
                            <TextField disabled label="Current Location" defaultValue={editEquipmentMovesData.current_Location}></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                            onChange={async (event:any, newValue:String | null) => {
                                let splitted_job_no = newValue?.split(", Job #: ")[0];
                                editEquipmentMovesData.new_Location = splitted_job_no;
                                editEquipmentMovesData.new_Sub_Location = '';
                                editEquipmentMovesData.new_Sub_Location_ID = 0;
                                sub_location_list = [];
                                var parent_id=0;   
                                mapped_location_list.map((site:any) => {
                                    if(site.site_Name === splitted_job_no){
                                        parent_id = site.id;
                                    }
                                });
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            disabled={disableForm}
                            value={editEquipmentMovesData.new_Location}
                            autoHighlight
                            style={editEquipmentMovesData.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="New Location"></TextField>}></Autocomplete>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                onChange={(event:any, newValue:String | null) =>  {
                                    editEquipmentMovesData.new_Sub_Location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            editEquipmentMovesData.new_Sub_Location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                disabled={disableForm}
                                autoHighlight
                                options={sub_location_list}
                                value={editEquipmentMovesData.new_Sub_Location}
                                renderInput={(params):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                       <FormLabel component="legend">Cancel Equipment Move?</FormLabel>
                       <FormControlLabel label="Yes" control={<Checkbox disabled={disableForm} checked={editEquipmentMovesData.cancelled} onChange={this.handleChange} name='cancel_move_check'></Checkbox>}></FormControlLabel>
                       </Grid>
                        {editEquipmentMovesData.scheduled_Move === "Yes" ? 
                        <Grid item xs={4}>
                            <TextField style={{backgroundColor:'white'}} disabled={disableForm} type="date" label="Date of Move" defaultValue={splitDate(editEquipmentMovesData.date_Of_Move)} onChange={this.handleChange} name="date_of_move"></TextField>
                        </Grid>
                        :null}
                        <Grid item xs={4}>
                            <TextField style={{backgroundColor:'white'}} disabled={disableForm} label="Comment" defaultValue={editEquipmentMovesData.comments} onChange={this.handleChange} name="comments"></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <br></br>
                                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                                <Button disabled style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
                            </Grid>
                            </Grid>
                    </Paper>
                    </Paper>
                </Paper>
            </div>
            </Box>
            </Modal>
        );
    }
}