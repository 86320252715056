import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST, IFormResponseByUserToken, IGetFormResponseByUserTokenRequest } from "./getFormResponseByUserTokenConstants";

export interface IGetFormResponseByUserTokenLoadAction {
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.REQUEST;
    data: IGetFormResponseByUserTokenRequest
}
export const getFormResponseByUserTokenLoadAction = (data: IGetFormResponseByUserTokenRequest): IGetFormResponseByUserTokenLoadAction => ({
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.REQUEST,
    data
});
export interface IGetFormResponseByUserTokenSuccessAction {
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IFormResponseByUserToken>;
}
export const getFormResponseByUserTokenLoadSuccessAction = (list: SurewayAPIResponse<IFormResponseByUserToken>): IGetFormResponseByUserTokenSuccessAction => ({
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.SUCCESS,
    list
});
export interface IGetFormResponseByUserTokenLoadFailedAction {
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.FAILED;
    message: string;
}
export const getFormResponseByUserTokenLoadFailedAction = (message: string): IGetFormResponseByUserTokenLoadFailedAction => ({
    type: IGET_FORM_RESPONSE_BY_USER_TOKEN_REQUEST.FAILED,
    message
});
