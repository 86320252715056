import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_IG_LOOKUPS_REQUEST, IIGLookups } from "./getImageGalleryConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetIGLookupsLoadAction, IGetIGLookupsLoadFailedAction, IGetIGLookupsSuccessAction, getIGLookupsLoadFailedAction, getIGLookupsLoadSuccessAction } from "./getImageGalleryActions";

export const getIGLookupsEpic: Epic = (
    action$: ActionsObservable<IGetIGLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetIGLookupsSuccessAction | IGetIGLookupsLoadFailedAction> =>
    action$.ofType(IGET_IG_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IIGLookups>>(
                    END_POINTS.FIELD.IMAGE_GALLERY.GET_IG_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IIGLookups>): IGetIGLookupsSuccessAction => {
                            return getIGLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getIGLookupsLoadFailedAction(response.message)))
                    )
            )
        );