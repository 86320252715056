
export enum ISAVE_ACKNOWLEDGE_RESPONSE_REQUEST {
    REQUEST = "saveAcknowledgeResponse/SAVE_ACKNOWLEDGE_RESPONSE_REQUEST",
    SUCCESS = "saveAcknowledgeResponse/SAVE_ACKNOWLEDGE_RESPONSE_SUCCESS",
    FAILED = "saveAcknowledgeResponse/SAVE_ACKNOWLEDGE_RESPONSE_FAILED"
};

export interface ISaveAcknowledgeResponseRequest {
    token: string;
    request: ISaveAcknowledge;
};

export interface ISaveAcknowledge {
    Form_ID: number;
    Name: string;
    Emp_ID: number;
    Acknowledged: string;
    WSS_Log_ID: number;
    Created_By: string;
};