import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { WorkingSiteLogSubHeader } from "../../header/WorkingSiteLogSubHeader";
import { IFieldEmployee, IGetFieldEmployeesRequest } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesConstants";
import { getFieldEmployees } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesAccessor";
import { getFieldEmployeesLoadAction } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesActions";

interface IFieldEmployeesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getFieldEmployee: Server<SurewayAPIResponse<ById<IFieldEmployee>>>;
};

interface IFieldEmployeesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => unknown;
};


interface IFieldEmployeesOwnProps {

};

interface IFieldEmployeesState {
    activeView: boolean;
    activeList: IFieldEmployee[];
};

const FieldEmployeesStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IFieldEmployeesProps = RouteComponentProps
    & IFieldEmployeesStoreProps
    & IFieldEmployeesDispatchProps
    & IFieldEmployeesOwnProps;

class FieldEmployees extends PureComponent<IFieldEmployeesProps, IFieldEmployeesState> {

    public constructor(props: IFieldEmployeesProps) {
        super(props);
        this.state = {
            activeView: true,
            activeList: []
        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IFieldEmployeesProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getFieldEmployee, getToken } = this.props;
        const { activeView, activeList } = this.state;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const data = activeView ? (activeList) : hasPayload(getFieldEmployee) ? Object.values(getFieldEmployee.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="FieldEmployees" description="Field Employee List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && 
                <>
                    <WorkingSiteLogSubHeader
                    {...this.props}
                    />
                    
                    <FieldEmployeesStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">FIELD EMPLOYEES</h2>
                                    <hr />

                                    {getFieldEmployee.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Field Employees..." />}
                                    {getFieldEmployee.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Field Employees..." />}

                                    {getFieldEmployee.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                    <FormControlLabel value="active" control={<Radio />} label="Active Items" />
                                                    <FormControlLabel value="all" control={<Radio />} label="All Items" />
                                                </RadioGroup>
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="fieldEmployeesList"
                                                    customRowColor={true}
                                                    exportFileName="FieldEmployees"
                                                    storageKey="fieldEmployeeListSessionKey"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "name", caption: "Name", type: "string" },
                                                        { name: "email", caption: "Email", type: "string" },                                                   
                                                        { name: "department", caption: "Department", type: "string" },                                                   
                                                        { name: "foreman", caption: "Foreman", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "phone", caption: "Phone", type: "string" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime" },
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    </FieldEmployeesStyles>
                </>}
            </PageSpacing>
        
        );
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        let activeView = !this.state.activeView;
        const getStorageValue = localStorage.getItem("key_fieldEmployee_list");
        if (getStorageValue) {
            if (activeView) {
                localStorage.setItem("key_fieldEmployee_list", "active");
            }
            else {
                localStorage.setItem("key_fieldEmployee_list", "all");
            }
        }
        else {
            localStorage.setItem("key_fieldEmployee_list", "active");
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getFieldEmployee)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };

        if (hasPayload(this.props.getFieldEmployee)) {
            this.setState({
                activeList: Object.values(this.props.getFieldEmployee.payload.response)
                    .filter(x => x.active === "Yes")
            });
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getFieldEmployeesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IFieldEmployeesStoreProps => ({
    getToken: getToken(state),
    getFieldEmployee: getFieldEmployees(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IFieldEmployeesDispatchProps => ({
    getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => dispatch(getFieldEmployeesLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldEmployees);