import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_COUPLERS_REQUEST, ICoupler } from "./getCouplersConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetCouplersLoadAction, IGetCouplersLoadFailedAction, IGetCouplersSuccessAction, getCouplersLoadFailedAction, getCouplersLoadSuccessAction } from "./getCouplersActions";
import { withJsonContentType } from "../../../epicUtils";

export const getCouplersEpic: Epic = (
    action$: ActionsObservable<IGetCouplersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetCouplersSuccessAction | IGetCouplersLoadFailedAction> =>
    action$.ofType(IGET_COUPLERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<ICoupler>>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_COUPLERS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<ICoupler>>): IGetCouplersSuccessAction => {
                            return getCouplersLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getCouplersLoadFailedAction(response.message)))
                    )
            )
        );