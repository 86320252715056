import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IDELETE_ATTACHMENT_LIST_GET_REQUEST } from "./deleteAttachmentListGETConstants";
import { IDeleteAttachmentListGETLoadAction, IDeleteAttachmentListGETLoadFailedAction, IDeleteAttachmentListGETSuccessAction, deleteAttachmentListGETLoadFailedAction, deleteAttachmentListGETLoadSuccessAction } from "./deleteAttachmentListGETActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const deleteAttachmentListGETEpic: Epic = (
    action$: ActionsObservable<IDeleteAttachmentListGETLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IDeleteAttachmentListGETSuccessAction | IDeleteAttachmentListGETLoadFailedAction> =>
    action$.ofType(IDELETE_ATTACHMENT_LIST_GET_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.DELETE_ATTACHMENT_LIST_GET,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IDeleteAttachmentListGETSuccessAction | IDeleteAttachmentListGETLoadFailedAction => {
                            if(response.message === "Success"){
                                return deleteAttachmentListGETLoadSuccessAction(response);
                            } else {
                                return deleteAttachmentListGETLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(deleteAttachmentListGETLoadFailedAction(response.message)))
                    )
            )
        );