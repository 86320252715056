import React from "react";
import { STATUS_ENUM } from "../../../../redux/server";
import {  HasClass } from "../../../shared/publicInterfaces";
import { LAPopover } from "../../../shared/popOver";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LACenteredLoading } from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { TableContainer } from "@mui/material";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { undefinedFunction } from "../../../shared/constExports";
import { IGetCrewVersionHistory } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryConstants";


interface ICrewVersionHistoryPopupProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: IGetCrewVersionHistory[];
    display: string | undefined;
};

export const CrewVersionHistoryPopup: React.FC<ICrewVersionHistoryPopupProps> = React.memo((props: ICrewVersionHistoryPopupProps) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data)
    // const name = data && data[0]?.name

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>{props.display}</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Crew Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            // onEdit={onEdit}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="CrewVersionHistoryList"
                            customRowColor={true}
                            exportFileName="Crew-Version-History"
                            storageKey="CrewVersionHistorySessionKey"
                            columns={[
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "foreman", caption: "Foreman", type: "string" },
                                { name: "crew_Name", caption: "Crew Name", type: "string" },
                                { name: "employee", caption: "Employee", type: "string" },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});