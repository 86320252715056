import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_DRIVER_COMMUNICATION_LOGS_REQUEST, IGetDriverCommunicationLogs } from "./getDriverCommunicationLogsConstants";
import { IGetDriverCommunicationLogsLoadAction, IGetDriverCommunicationLogsLoadFailedAction, IGetDriverCommunicationLogsSuccessAction } from "./getDriverCommunicationLogsActions";

type Actions =
    | IGetDriverCommunicationLogsLoadAction
    | IGetDriverCommunicationLogsSuccessAction
    | IGetDriverCommunicationLogsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetDriverCommunicationLogsReducer = (state: Server<SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>> => {
    switch (action.type) {
        case IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DRIVER_COMMUNICATION_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};