import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_REPAIRLINE_HISTORY_REQUEST, IGetRepairLineHistory } from "./getRepairLineHistoryConstants";
import { IGetRepairLineHistoryLoadAction, IGetRepairLineHistoryLoadFailedAction, IGetRepairLineHistorySuccessAction } from "./getRepairLineHistoryActions";

type Actions =
    | IGetRepairLineHistoryLoadAction
    | IGetRepairLineHistorySuccessAction
    | IGetRepairLineHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetRepairLineHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetRepairLineHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetRepairLineHistory>>> => {
    switch (action.type) {
        case IGET_REPAIRLINE_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIRLINE_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIRLINE_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};