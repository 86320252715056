import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_MEDICAL_FACILITY_REQUEST, IUpdateMedicalFacilityRequest } from "./updateMedicalFacilityConstants";


export interface IUpdateMedicalFacilityLoadAction {
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.REQUEST;
    data: IUpdateMedicalFacilityRequest
}
export const updateMedicalFacilityLoadAction = (data: IUpdateMedicalFacilityRequest): IUpdateMedicalFacilityLoadAction => ({
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.REQUEST,
    data
});
export interface IUpdateMedicalFacilitySuccessAction {
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateMedicalFacilityLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateMedicalFacilitySuccessAction => ({
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.SUCCESS,
    message
});
export interface IUpdateMedicalFacilityLoadFailedAction {
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.FAILED;
    message: string;
}
export const updateMedicalFacilityLoadFailedAction = (message: string): IUpdateMedicalFacilityLoadFailedAction => ({
    type: IUPDATE_MEDICAL_FACILITY_REQUEST.FAILED,
    message
});
