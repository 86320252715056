import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_LOGS_REQUEST, IMechanicLogs, IGetMechanicLogsRequest } from "./getMechanicLogsConstants";

export interface IGetMechanicLogsLoadAction {
    type: IGET_MECHANIC_LOGS_REQUEST.REQUEST;
    data: IGetMechanicLogsRequest
}
export const getMechanicLogsLoadAction = (data: IGetMechanicLogsRequest): IGetMechanicLogsLoadAction => ({
    type: IGET_MECHANIC_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetMechanicLogsSuccessAction {
    type: IGET_MECHANIC_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IMechanicLogs[]>;
}
export const getMechanicLogsLoadSuccessAction = (list: SurewayAPIResponse<IMechanicLogs[]>): IGetMechanicLogsSuccessAction => ({
    type: IGET_MECHANIC_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicLogsLoadFailedAction {
    type: IGET_MECHANIC_LOGS_REQUEST.FAILED;
    message: string;
}
export const getMechanicLogsLoadFailedAction = (message: string): IGetMechanicLogsLoadFailedAction => ({
    type: IGET_MECHANIC_LOGS_REQUEST.FAILED,
    message
});
