import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST, IMechanicTimesheetForApproval } from "./getMechanicTimesheetForApprovalConstants";
import { IADD_REPAIR_LINE_REQUEST } from "../addRepairLine/addRepairLineConstants";
import { IAddRepairLineSuccessAction } from "../addRepairLine/addRepairLineActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_REQUEST } from "../updateRepairLine/updateRepairLineConstants";
import { IUpdateRepairLineSuccessAction } from "../updateRepairLine/updateRepairLineActions";
import { IGetMechanicTimesheetForApprovalLoadAction, IGetMechanicTimesheetForApprovalLoadFailedAction, IGetMechanicTimesheetForApprovalSuccessAction } from "./getMechanicTimesheetForApprovalActions";
import { IUpdateRepairLineByMechanicSuccessAction } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicActions";
import { IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST } from "../../mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicConstants";
import { IAPPROVE_MECHANIC_LOG_REQUEST } from "../approveMechanicLog/approveMechanicLogConstants";
import { IApproveMechanicLogSuccessAction } from "../approveMechanicLog/approveMechanicLogActions";
import { ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST } from "../SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemConstants";
import { ISaveManagerCommentsForDefectItemSuccessAction } from "../SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemActions";

type Actions =
    | IGetMechanicTimesheetForApprovalLoadAction
    | IGetMechanicTimesheetForApprovalSuccessAction
    | IGetMechanicTimesheetForApprovalLoadFailedAction
    | IAddRepairLineSuccessAction
    | IUpdateRepairLineSuccessAction
    | IUpdateRepairLineByMechanicSuccessAction
    | IApproveMechanicLogSuccessAction
    | ISaveManagerCommentsForDefectItemSuccessAction
    | IFlushDataSuccessAction;

export const GetMechanicTimesheetForApprovalReducer = (state: Server<SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>> => {
    switch (action.type) {
        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IAPPROVE_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};