import { IAddUpdateFormTemplate } from "../addFormTemplate/addFormTemplateConstants";

export enum IUPDATE_FORM_TEMPLATE_REQUEST {
    REQUEST = "updateFormTemplate/UPDATE_FORM_TEMPLATE_REQUEST",
    SUCCESS = "updateFormTemplate/UPDATE_FORM_TEMPLATE_SUCCESS",
    FAILED = "updateFormTemplate/UPDATE_FORM_TEMPLATE_FAILED"
};

export interface IUpdateFormTemplateRequest {
    token: string;
    request: IAddUpdateFormTemplate;
};