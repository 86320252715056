import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
// import { IAddMeterReadingSuccessAction } from "../addMeterReading/addMeterReadingActions";
// import { IADD_METER_READING_REQUEST } from "../addMeterReading/addMeterReadingConstants";
// import { IUpdateMeterReadingSuccessAction } from "../updateMeterReading/updateMeterReadingActions";
// import { IUPDATE_METER_READING_REQUEST } from "../updateMeterReading/updateMeterReadingConstants";
import { IGetServiceHistoryBySOCTIDLoadAction, IGetServiceHistoryBySOCTIDLoadFailedAction, IGetServiceHistoryBySOCTIDSuccessAction } from "./getServiceHistoryBySOCTIDActions";
import { IServiceHistory, IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST } from "./getServiceHistoryBySOCTIDConstants";

type Actions =
    | IGetServiceHistoryBySOCTIDLoadAction
    | IGetServiceHistoryBySOCTIDSuccessAction
    | IGetServiceHistoryBySOCTIDLoadFailedAction
    // | IAddMeterReadingSuccessAction
    // | IUpdateMeterReadingSuccessAction
    | IFlushDataSuccessAction;

export const GetServiceHistoryBySOCTIDReducer = (state: Server<SurewayAPIResponse<ById<IServiceHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IServiceHistory>>> => {
    switch (action.type) {
        case IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        // case IADD_METER_READING_REQUEST.SUCCESS:
        //     return notLoaded;
    
        // case IUPDATE_METER_READING_REQUEST.SUCCESS:
        //     return notLoaded;

        default:
            return state;
    }
};