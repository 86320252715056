import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IAPPROVE_MECHANIC_LOG_REQUEST, IApproveMechanicLogRequest } from "./approveMechanicLogConstants";


export interface IApproveMechanicLogLoadAction {
    type: IAPPROVE_MECHANIC_LOG_REQUEST.REQUEST;
    data: IApproveMechanicLogRequest
}
export const approveMechanicLogLoadAction = (data: IApproveMechanicLogRequest): IApproveMechanicLogLoadAction => ({
    type: IAPPROVE_MECHANIC_LOG_REQUEST.REQUEST,
    data
});
export interface IApproveMechanicLogSuccessAction {
    type: IAPPROVE_MECHANIC_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const approveMechanicLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IApproveMechanicLogSuccessAction => ({
    type: IAPPROVE_MECHANIC_LOG_REQUEST.SUCCESS,
    message
});
export interface IApproveMechanicLogLoadFailedAction {
    type: IAPPROVE_MECHANIC_LOG_REQUEST.FAILED;
    message: string;
}
export const approveMechanicLogLoadFailedAction = (message: string): IApproveMechanicLogLoadFailedAction => ({
    type: IAPPROVE_MECHANIC_LOG_REQUEST.FAILED,
    message
});
