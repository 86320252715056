import { IAddUpdateScheduleServiceHistory } from "../addScheduleServiceHistory/addScheduleServiceHistoryConstants";

export enum IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST {
    REQUEST = "updateScheduleServiceHistory/UPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST",
    SUCCESS = "updateScheduleServiceHistory/UPDATE_SCHEDULE_SERVICE_HISTORY_SUCCESS",
    FAILED = "updateScheduleServiceHistory/UPDATE_SCHEDULE_SERVICE_HISTORY_FAILED"
};

export interface IUpdateScheduleServiceHistoryRequest {
    token: string;
    request: IAddUpdateScheduleServiceHistory;
};

