import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST, IGetDriverAssignmentVersionHistory } from "./getDriverAssignmentVersionHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetDriverAssignmentVersionHistoryLoadAction, IGetDriverAssignmentVersionHistoryLoadFailedAction, IGetDriverAssignmentVersionHistorySuccessAction, 
    getDriverAssignmentVersionHistoryLoadFailedAction, getDriverAssignmentVersionHistoryLoadSuccessAction } from "./getDriverAssignmentVersionHistoryActions";

export const getDriverAssignmentVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetDriverAssignmentVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetDriverAssignmentVersionHistorySuccessAction | IGetDriverAssignmentVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>>(
                    END_POINTS.FIELD.DISPATCH.GET_DRIVER_ASSIGNMENT_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>): IGetDriverAssignmentVersionHistorySuccessAction => {
                            return getDriverAssignmentVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getDriverAssignmentVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );