import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_IG_LOOKUPS_REQUEST, IIGLookups, IIGLookupsRequest } from "./getImageGalleryConstants";

export interface IGetIGLookupsLoadAction {
    type: IGET_IG_LOOKUPS_REQUEST.REQUEST;
    data: IIGLookupsRequest
}
export const getIGLookupsLoadAction = (data: IIGLookupsRequest): IGetIGLookupsLoadAction => ({
    type: IGET_IG_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetIGLookupsSuccessAction {
    type: IGET_IG_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IIGLookups>;
}
export const getIGLookupsLoadSuccessAction = (list: SurewayAPIResponse<IIGLookups>): IGetIGLookupsSuccessAction => ({
    type: IGET_IG_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetIGLookupsLoadFailedAction {
    type: IGET_IG_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getIGLookupsLoadFailedAction = (message: string): IGetIGLookupsLoadFailedAction => ({
    type: IGET_IG_LOOKUPS_REQUEST.FAILED,
    message
});