import { IUPDATE_SERVICE_SECTION_REQUEST } from "./updateServiceSectionConstants";
import { IUpdateServiceSectionLoadAction, IUpdateServiceSectionLoadFailedAction, IUpdateServiceSectionSuccessAction } from "./updateServiceSectionActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateServiceSectionLoadAction
    | IUpdateServiceSectionSuccessAction
    | IUpdateServiceSectionLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateServiceSectionReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_SERVICE_SECTION_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SERVICE_SECTION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};