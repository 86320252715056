import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_COUPLERS_REQUEST, ICoupler } from "./getCouplersConstants";
import { IGetCouplersLoadAction, IGetCouplersLoadFailedAction, IGetCouplersSuccessAction } from "./getCouplersActions";
import { IUPDATE_ATTACHMENT_COUPLER_REQUEST } from "../updateCoupler/updateCouplerConstants";
import { IADD_ATTACHMENT_COUPLER_REQUEST } from "../addCoupler/addCouplerConstants";
import { IAddAttachmentCouplerSuccessAction } from "../addCoupler/addCouplerActions";
import { IUpdateAttachmentCouplerSuccessAction } from "../updateCoupler/updateCouplerActions";

type Actions =
    | IGetCouplersLoadAction
    | IGetCouplersSuccessAction
    | IGetCouplersLoadFailedAction
    | IUpdateAttachmentCouplerSuccessAction
    | IAddAttachmentCouplerSuccessAction
    | IFlushDataSuccessAction;

export const GetCouplersReducer = (state: Server<SurewayAPIResponse<ById<ICoupler>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ICoupler>>> => {
    switch (action.type) {
        case IGET_COUPLERS_REQUEST.REQUEST:
            return loading;

        case IGET_COUPLERS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_COUPLERS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};