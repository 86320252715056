import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IADD_CREW_REQUEST } from "./addCrewConstants";
import { IAddCrewLoadAction, IAddCrewLoadFailedAction, IAddCrewSuccessAction } from "./addCrewActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IAddCrewLoadAction
    | IAddCrewSuccessAction
    | IAddCrewLoadFailedAction
    | IFlushDataSuccessAction;

export const AddCrewReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_CREW_REQUEST.REQUEST:
            return loading;

        case IADD_CREW_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_CREW_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};