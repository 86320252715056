import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_SHOP_MODEL_REQUEST, IAddShopModelRequest } from "./addShopModelsConstants";


export interface IAddShopModelLoadAction {
    type: IADD_SHOP_MODEL_REQUEST.REQUEST;
    data: IAddShopModelRequest
}
export const addShopModelLoadAction = (data: IAddShopModelRequest): IAddShopModelLoadAction => ({
    type: IADD_SHOP_MODEL_REQUEST.REQUEST,
    data
});
export interface IAddShopModelSuccessAction {
    type: IADD_SHOP_MODEL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addShopModelLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddShopModelSuccessAction => ({
    type: IADD_SHOP_MODEL_REQUEST.SUCCESS,
    message
});
export interface IAddShopModelLoadFailedAction {
    type: IADD_SHOP_MODEL_REQUEST.FAILED;
    message: string;
}
export const addShopModelLoadFailedAction = (message: string): IAddShopModelLoadFailedAction => ({
    type: IADD_SHOP_MODEL_REQUEST.FAILED,
    message
});
