import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { ISAVE_CREW_SIGNATURE_BY_ID_REQUEST } from "./saveCrewSignatureByIDConstants";
import { ISaveCrewSignatureByIDLoadAction, ISaveCrewSignatureByIDLoadFailedAction, ISaveCrewSignatureByIDSuccessAction } from "./saveCrewSignatureByIDActions";
type Actions =
    | ISaveCrewSignatureByIDLoadAction
    | ISaveCrewSignatureByIDSuccessAction
    | ISaveCrewSignatureByIDLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveCrewSignatureByIDReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.REQUEST:
            return loading;

        case ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_CREW_SIGNATURE_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};