import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
// import { Tooltip } from "devextreme-react";
import { getForms } from "../../../redux/field/getForms/getFormsAccessor";
import { getFormsLoadAction } from "../../../redux/field/getForms/getFormsActions";
import { IChecklistForm, IFormsRequest } from "../../../redux/field/getForms/getFormsConstants";
import { FormsMenu, Navbar } from "../../shared/navbars";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import { getFormVersionHistory } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";
import { FormVersionHistoryPopup } from "./formVersionHistoryPopup";

interface IFormsComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getFormTemplates: Server<SurewayAPIResponse<IChecklistForm[]>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
};

interface IFormsComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getFormTemplatesRequest: (data: IFormsRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
};


interface IFormsComponentOwnProps {

};

interface IFormsComponentState {
    // view: string;
    activeView: boolean;
    formVersionHistoryPopup: boolean;
};

const FormsComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IFormsComponentProps = RouteComponentProps
    & IFormsComponentStoreProps
    & IFormsComponentDispatchProps
    & IFormsComponentOwnProps;

class FormsComponent extends PureComponent<IFormsComponentProps, IFormsComponentState> {
    
    public constructor(props: IFormsComponentProps) {
        super(props);
        this.state = {
            // view: "Active"
            activeView: true,
            formVersionHistoryPopup: false
        };
    }

    public async componentDidMount(): Promise<void> {
        // this.checkViewSelections();
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IFormsComponentProps): void {
        // this.checkViewSelections();

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getFormTemplates, getToken, getFormVersionHistory  } = this.props;
        const { activeView, formVersionHistoryPopup } = this.state;
        const getRole = pageAccessCheck(getToken, "formsAdmin");
        const getCleanUpRole = pageAccessCheck(getToken, "formsCleanupAccess");
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];
        const data = hasPayload(getFormTemplates) ? activeView ? Object.values(getFormTemplates.payload.response).filter(x => x.active === "Yes") : 
                        Object.values(getFormTemplates.payload.response) : [];

        return (
            <PageSpacing title="Forms" description="FIELD - Forms" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={FormsMenu} role={getRole === true ? true : getCleanUpRole === true ? "formsCleanupAccess": getRole}/>
                {(getRole !== NotApplicable) && <>
                    <FormsComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>FORM TEMPLATES</h2><br />
                                <hr />

                                {getFormTemplates.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Form Templates..." />}
                                {getFormTemplates.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Form Templates..." />}

                                {getFormTemplates.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="active" control={<Radio />} label="Active" />
                                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                onCustomClick={this.handleCustomClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="Forms"
                                                customRowColor={true}
                                                exportFileName="Forms"
                                                storageKey="forms-list"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "form_Name", caption: "Form Name", type: "string" },
                                                    { name: "type", caption: "Type", type: "date"},
                                                    { name: "foreman", caption: "Foreman", type: "string" },
                                                    { name: "frequency", caption: "Frequency", type: "string" },
                                                    { name: "start_Date", caption: "Start Date", type: "date" },
                                                    { name: "expiry_Date", caption: "Expiry Date", type: "date"},
                                                    { name: "active", caption: "Active", type: "string" },
                                                    { name: "version_History", caption: "Version History", type: "button"},
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", show: false, sortDesc: true },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                        {formVersionHistoryPopup && <FormVersionHistoryPopup
                            page="FormTemplateVersionHistory"
                            data={formVersionHistoryPopupData}
                            display={`Version History` }
                            open={formVersionHistoryPopup}
                            status={getFormVersionHistory.kind}
                            onCancel={this.handleFormVersionHistoryPopupClose}
                        />}
                    </FormsComponentStyles>
                </>}
            </PageSpacing>
        );
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.FORMS.FORM_TEMPLATE.ADD_UPDATE_FORM_TEMPLATE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.FORMS.FORM_TEMPLATE.ADD_UPDATE_FORM_TEMPLATE
        });
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IChecklistForm = e.row.data;
        if (name === "version_History") {
            this.handleFormVersionHistory(data)
        }
    }

    private handleFormVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: data.id,
                    page: "Template"
                }
            });
        }
        this.setState({ formVersionHistoryPopup: true });
    }

    private handleFormVersionHistoryPopupClose = (): void => {
        this.setState({ formVersionHistoryPopup: false });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "formsAdmin") !== NotApplicable) {
                if (isNotLoaded(this.props.getFormTemplates)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getFormTemplatesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IFormsComponentStoreProps => ({
    getToken: getToken(state),
    getFormTemplates: getForms(state),
    getFormVersionHistory: getFormVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IFormsComponentDispatchProps => ({
    getFormTemplatesRequest: (data: IFormsRequest) => dispatch(getFormsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsComponent);