import React from "react";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { IGetSTSchedulesRequest, ISTSchedules } from "../../../redux/field/dispatch/getSTSchedules/getSTSchedulesConstants";
import { getSTSchedulesLoadAction } from "../../../redux/field/dispatch/getSTSchedules/getSTSchedulesActions";
import { getSTSchedules } from "../../../redux/field/dispatch/getSTSchedules/getSTSchedulesAccessor";
import { updateSTSchedules } from "../../../redux/field/dispatch/updateSTSchedules/updateSTSchedulesAccessor";
import { updateSTSchedulesLoadAction } from "../../../redux/field/dispatch/updateSTSchedules/updateSTSchedulesActions";
import { IUpdateSTSchedulesRequest } from "../../../redux/field/dispatch/updateSTSchedules/updateSTSchedulesConstants";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { DevDataGrid, STSchedulesMobileView } from "./stSchedulesSubComponent";
import { getDate, getTokenFromUrl } from "../../shared/constExports";
import { ROUTE } from "../../routes";
import LAErrorBox from "../../shared/errorBox";

interface ISTSchedulesStoreProps {
    updateSTSchedule: Server<SurewayAPIResponse<string>>;
    getSTSchedules: Server<SurewayAPIResponse<ById<ISTSchedules>>>;
};

interface ISTSchedulesDispatchProps {
    getSTSchedulesRequest: (data: IGetSTSchedulesRequest) => unknown;
    updateSTScheduleRequest: (data: IUpdateSTSchedulesRequest) => unknown;
};


interface ISTSchedulesOwnProps {

};

interface ISTSchedulesState {
    data: ISTSchedules[];
};

const STSchedulesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISTSchedulesProps = RouteComponentProps
    & ISTSchedulesStoreProps
    & ISTSchedulesDispatchProps
    & ISTSchedulesOwnProps;

class STSchedules extends PureComponent<ISTSchedulesProps, ISTSchedulesState> {

    public constructor(props: ISTSchedulesProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISTSchedulesProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getSTSchedules, updateSTSchedule } = this.props;
        const todayDate = getDate(false, new Date().toISOString());

        return (
            <PageSpacing title="Service Truck Schedules" description="FIELD - SERVICE TRUCK SCHEDULES" fixedSpaceOnSmallerScreens={true}>
                <STSchedulesStyles>

                    <LAGrid>

                        {getSTSchedules.kind === STATUS_ENUM.LOADING && <LACenteredLoading message="Loading Schedules..." />}
                        {getSTSchedules.kind === STATUS_ENUM.FAILED && <LAErrorBox text={`Failed to load Schedules because  ${getSTSchedules.message}`} />}

{(getSTSchedules.kind === STATUS_ENUM.SUCCEEDED) &&
<>
                        {(data && data.length > 0) ?
                        <LAGridItem xs={12} className="text-center">

                            <h2>{data[0].service_Truck} Schedule - {todayDate}</h2><br />
                            <hr />

                                <LAGrid>
                                    <LAGridItem xs={12} className="hide-on-phone">
                                        <DevDataGrid
                                            data={data}
                                            onSave={this.handleSave}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} className="show-on-phone">
                                        <STSchedulesMobileView
                                            data={data}
                                            onSave={this.handleSave}
                                        />
                                    </LAGridItem>
                                </LAGrid>

                        </LAGridItem>
                            : <LAGridItem xs={12}>
                                <LAErrorBox text="No schedules for today" />
                            </LAGridItem>}
                            </>}

                    </LAGrid>
                </STSchedulesStyles>

                <RequestStatus requestStatus={updateSTSchedule.kind} successMessage="Data has been updated successfully" />
            </PageSpacing>
        );
    }


    private handleSave = (data: ISTSchedules): void => {
        let status = data.status;

        if (data.drivingAtSite)
            status = "Driving to Site"

        if (data.atSite)
            status = "At Site"

        if (data.completed)
            status = "Completed";

        this.props.updateSTScheduleRequest({
            token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
            request: {
                ID: data.id,
                status: status,
                eta: new Date(data.eta).toISOString(),
                foreman_At_Site: data.foreman_At_Site,
                equipment_At_Site: data.equipment_At_Site,
                service_Truck: data.service_Truck,
                Modified_By: data.service_Truck
            }
        });
    };

    private callServer = async (): Promise<void> => {

        if (isNotLoaded(this.props.getSTSchedules)) {
            this.getDataForTable();
        };

        if (hasPayload(this.props.getSTSchedules)) {
            const newList: ISTSchedules[] = [];
            const data = Object.values(this.props.getSTSchedules.payload.response);

            await data.forEach((x) => {

                let val: ISTSchedules = {
                    ...x,
                    atSite: false,
                    completed: false,
                    drivingAtSite: false
                };

                switch (val.status) {
                    case "Driving to Site":
                        val.drivingAtSite = true;
                        break;

                    case "At Site":
                        val.drivingAtSite = true;
                        val.atSite = true;
                        break;

                    case "Completed":
                        val.completed = true;
                        val.drivingAtSite = true;
                        val.atSite = true;
                        break;
                }
                newList.push(val);
            });

            await this.setState({
                data: newList
            });
        };
    };

    private getDataForTable = (): void => {
        const urlToken = getTokenFromUrl(true);
        if (urlToken) {
            this.props.getSTSchedulesRequest({
                token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                request: {
                    Token: urlToken,
                }
            });
        } else {
            this.props.history.push(ROUTE.UNAUTHORIZED);
        };
    };

}

const mapStateToProps = (state: IStore): ISTSchedulesStoreProps => ({
    getSTSchedules: getSTSchedules(state),
    updateSTSchedule: updateSTSchedules(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISTSchedulesDispatchProps => ({
    getSTSchedulesRequest: (data: IGetSTSchedulesRequest) => dispatch(getSTSchedulesLoadAction(data)),
    updateSTScheduleRequest: (data: IUpdateSTSchedulesRequest) => dispatch(updateSTSchedulesLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(STSchedules);