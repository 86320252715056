import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_TRUCKS_REQUEST, IServiceTruck } from "./getServiceTrucksConstants";
import { IADD_SERVICE_TRUCK_REQUEST } from "../addServiceTruck/addServiceTruckConstants";
import { IAddServiceTruckSuccessAction } from "../addServiceTruck/addServiceTruckActions";
import { IUPDATE_SERVICE_TRUCK_REQUEST } from "../updateServiceTruck/updateServiceTruckConstants";
import { IUpdateServiceTruckSuccessAction } from "../updateServiceTruck/updateServiceTruckActions";
import { IGetServiceTrucksLoadAction, IGetServiceTrucksLoadFailedAction, IGetServiceTrucksSuccessAction } from "./getServiceTrucksActions";
import { ISaveDriversAssignmentSuccessAction } from "../saveDriversAssignment/saveDriversAssignmentActions";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "../saveDriversAssignment/saveDriversAssignmentConstants";

type Actions =
    | IGetServiceTrucksLoadAction
    | IGetServiceTrucksSuccessAction
    | IAddServiceTruckSuccessAction
    | ISaveDriversAssignmentSuccessAction
    | IUpdateServiceTruckSuccessAction
    | IGetServiceTrucksLoadFailedAction
    | IFlushDataSuccessAction;

export const GetServiceTrucksReducer = (state: Server<SurewayAPIResponse<ById<IServiceTruck>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IServiceTruck>>> => {
    switch (action.type) {
        case IGET_SERVICE_TRUCKS_REQUEST.REQUEST:
            return loading;

        case IGET_SERVICE_TRUCKS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SERVICE_TRUCKS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_TRUCK_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_TRUCK_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};