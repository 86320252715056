import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST, IEmployeeMessage, IEmployeeMessageRequest } from "./getEmployeeMessagesByTokenConstants";

export interface IGetEmployeeMessagesByTokenLoadAction {
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.REQUEST;
    data: IEmployeeMessageRequest
}
export const getEmployeeMessagesByTokenLoadAction = (data: IEmployeeMessageRequest): IGetEmployeeMessagesByTokenLoadAction => ({
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.REQUEST,
    data
});
export interface IGetEmployeeMessagesByTokenSuccessAction {
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IEmployeeMessage>;
}
export const getEmployeeMessagesByTokenLoadSuccessAction = (list: SurewayAPIResponse<IEmployeeMessage>): IGetEmployeeMessagesByTokenSuccessAction => ({
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.SUCCESS,
    list
});
export interface IGetEmployeeMessagesByTokenLoadFailedAction {
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.FAILED;
    message: string;
}
export const getEmployeeMessagesByTokenLoadFailedAction = (message: string): IGetEmployeeMessagesByTokenLoadFailedAction => ({
    type: IGET_EMPLOYEE_MESSAGES_BY_TOKEN_REQUEST.FAILED,
    message
});
