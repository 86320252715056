
export enum IGET_HAZARD_TYPES_REQUEST {
    REQUEST = "getHazardTypes/GET_HAZARD_TYPES_REQUEST",
    SUCCESS = "getHazardTypes/GET_HAZARD_TYPES_SUCCESS",
    FAILED = "getHazardTypes/GET_HAZARD_TYPES_FAILED"
};

export interface IGetHazardTypesRequest {
    token: string;
};

export interface IGetHazardTypes {
    id: number;
    name: string;
    active: string;
    sort_Order: number;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    picture?: IHazardTypeImage;
    image?:string;
};

export interface IHazardTypeImage {
    name: string;
    description: string;
    base64String: string;
};
