import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Export, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Scrolling
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, Radio, RadioGroup, FormControl, FormControlLabel} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import { getUnitNumberByEquipmentID, getTrailers, getBillingReports} from './api/equipmenttracker/equipmentTrackerAPI';
import SOCTHome from './equipmenttrackerhome';
import EquipmentMoveEdit from './forms/equipmenttracker/equipmentMoveEdit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewEquipmentMoveForm from './forms/equipmenttracker/equipmentMoveRequestAddNew';
import {currentUser} from './equipmenttrackerhome';
import Iframe from 'react-iframe'
import { DataTimer } from '../../shared/timer';

interface IState {
    moveRequests:any;
  open:boolean;
  showEditForm:boolean;
  view:string;
  showAddNewForm:boolean;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
function StyledStatusComponent(cellData:any):any {
  if(cellData.column.dataField === 'status'){
      switch(cellData.data.status){
          case 'Initiated':
              return <div style={{backgroundColor:'rgb(146, 218, 150)', padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Completed':
              return <div style={{backgroundColor:'rgb(236, 176, 65)',padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Cancelled':
            return <div style={{backgroundColor:'rgb(205, 92, 92)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
      }
  }
  // else{
  // /* <div className={cellData.data.email === 'admin.test@gmail.com' ? "devex-styled-grid-row-green": cellData.data.email ==='dylan.ollikka@sil.ab.ca' ? "devex-styled-grid-row-red":"devex-styled-grid-row-orange"}>{cellData.value}</div> */
  //     return (
  //         <div className={"devex-styled-grid-row-default"}>{cellData.value}</div>
  //     )
  // }
}

var globalEditID:Number = 0;
var renderPageType:string = '';
export default class BillingReportsPage extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={moveRequests:[], open:false, showEditForm:false, view:'all', showAddNewForm:false};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
  }
  addNewEquipmentMove(){
    this.setState({showAddNewForm:true});
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  closeEditForm(){
      this.setState({showEditForm:false, showAddNewForm:false});
      this.props.history.push('/field/equipmenttracker/equipmentmoves');
      this.refreshGrid();
  }
  editItem(id:number, data:any){
    
    renderPageType='';
    editData = data;

    globalEditID = id;
    this.props.history.push('/field/equipmenttracker/equipmentmoves/edit/id='+id);
    this.setState({showEditForm:true});
  }
  async refreshGrid(){
    let temp_request:any[] = [];
    var move_requests = await getBillingReports();
    temp_request = move_requests;
    copy_list = temp_request;
    this.setState({moveRequests:temp_request});
  }
  async componentDidMount(){
    this.refreshGrid();
  }

  reloadPage(){
    //window.location.reload();
  }

    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
         <div>
          {/* <DataTimer
            key="billing-report"
            onTimerEnd={this.reloadPage}
          /> */}

         <Typography className='dataGrid-title'>Billing Reports</Typography>
         <Iframe url="https://reportserver.propsense.com/ReportServer/Pages/ReportViewer.aspx?%2fReports%2fField%2fET_Billing_Report&rs:Command=Render&rs:embed=true&rc:LinkTarget=_blank"
        width= "100%"
        height="1800"
        position="relative"
        scrolling="yes" 
        />
         </div>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType};