import React, { ReactNode, PureComponent, useState } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { BLUE_COLOR, GREEN_COLOR, MEDIA_QUERY_PHONE,  WHITE_COLOR, } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import {  YesOrNo, ZEROTH, getTokenFromUrl,  undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LAErrorBox from "../../shared/errorBox";
import LATextField from "../../shared/textField";
import { IComponentCodeList, ICostCode, IFieldLookup, IFieldLookupRequest, IJob, IJobListResponse } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { ISaveManagerCommentsForDefectItemRequest } from "../../../redux/field/repairLine/SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemConstants";
import { saveManagerCommentsForDefectItemStatus } from "../../../redux/field/repairLine/SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemAccessor";
import { saveManagerCommentsForDefectItemLoadAction } from "../../../redux/field/repairLine/SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemActions";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { LAPopover } from "../../shared/popOver";
import splitDate from "../../shared/splitDate";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import LATextArea from "../../shared/textArea";
import { LACheckBox } from "../../shared/checkBox";
import LADropDownForJobs from "../../shared/dropdownForJobs";
import LAAutoComplete from "../../shared/autoComplete";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { DispatchSubHeader } from "../../header/dispatchSubHeader";
import { getSTTimesheetForApprovalGroupByDate } from "../../../redux/field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateAccessor";
import { IDriversLogsInfo, IGetSTTimesheetForApprovalGroupByDateRequest, ISTLog, ISTTimesheetForApprovalGroupByDate } from "../../../redux/field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateConstants";
import { getSTTimesheetForApprovalGroupByDateLoadAction } from "../../../redux/field/dispatch/getSTTimesheetForApprovalGroupByDate/getSTTimesheetForApprovalGroupByDateActions";
import { IApproveSTTimesheetRequest } from "../../../redux/field/dispatch/approveSTTimesheet/approveSTTimesheetConstants";
import { approveSTTimesheetLoadAction } from "../../../redux/field/dispatch/approveSTTimesheet/approveSTTimesheetActions";
import { IRejectSTTimesheetRequest } from "../../../redux/field/dispatch/rejectSTTimesheet/rejectSTTimesheetConstants";
import { rejectSTTimesheetLoadAction } from "../../../redux/field/dispatch/rejectSTTimesheet/rejectSTTimesheetActions";
import { approveSTTimesheetStatus } from "../../../redux/field/dispatch/approveSTTimesheet/approveSTTimesheetAccessor";
import { rejectSTTimesheetStatus } from "../../../redux/field/dispatch/rejectSTTimesheet/rejectSTTimesheetAccessor";

interface IApproveSTDriverLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    approveStatus: Server<SurewayAPIResponse<string>>;
    rejectStatus: Server<SurewayAPIResponse<string>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    getSTTimesheetForApprovalGroupByDate: Server<SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>>;
    saveManagerCommentsForDefectItem: Server<SurewayAPIResponse<string>>;
};

interface IApproveSTDriverLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    approveRequest: (request: IApproveSTTimesheetRequest) => unknown;
    rejectRequest: (request: IRejectSTTimesheetRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getSTTimesheetForApprovalGroupByDateRequest: (data: IGetSTTimesheetForApprovalGroupByDateRequest) => unknown;
    saveManagerCommentsForDefectItemRequest: (data: ISaveManagerCommentsForDefectItemRequest) => unknown;
};


interface IApproveSTDriverLogsOwnProps {

};

interface IApproveSTDriverLogsState {
    stTimesheetForApproval: ISTTimesheetForApprovalGroupByDate[];
    logPopup: boolean;
    logPopupData: ISTLog | undefined;
    serverErrorSaveManagerCommentsForDefectItem: string;
    serverErrorApproveSTTimesheet: string;
    serverErrorRejectSTTimesheet: string;
};

const ApproveSTDriverLogsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;
    
    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .bg_MiscLog {
        background-color: #fad296;
    }

    .bg_ServiceLog {
        background-color:#fdffeb;
    }

    .bg_DefectItemLog {
        background-color: lightblue;
    }

    .bg_DefectType {
        background-color: #d67676;
    }

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IApproveSTDriverLogsProps = RouteComponentProps
    & IApproveSTDriverLogsStoreProps
    & IApproveSTDriverLogsDispatchProps
    & IApproveSTDriverLogsOwnProps;

class ApproveSTDriverLogs extends PureComponent<IApproveSTDriverLogsProps, IApproveSTDriverLogsState> {
    public constructor(props: IApproveSTDriverLogsProps) {
        super(props);
        this.state = {
            stTimesheetForApproval: [],
            logPopup: false,
            logPopupData: undefined,
            serverErrorSaveManagerCommentsForDefectItem: "",
            serverErrorApproveSTTimesheet: "",
            serverErrorRejectSTTimesheet: "",
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IApproveSTDriverLogsProps): void {
        if (this.props !== prevProps) {

            this.callServer();

            // if (this.props.saveManagerCommentsForDefectItem !== prevProps.saveManagerCommentsForDefectItem) {
            //     if (this.props.saveManagerCommentsForDefectItem.kind === STATUS_ENUM.FAILED)
            //         this.setState({ serverErrorSaveManagerCommentsForDefectItem: this.props.saveManagerCommentsForDefectItem.message });

            //     if (hasPayload(this.props.saveManagerCommentsForDefectItem) && this.props.saveManagerCommentsForDefectItem.kind === STATUS_ENUM.SUCCEEDED) {
            //         this.setState({
            //             logPopup: false,
            //             logPopupData: undefined,
            //             serverErrorSaveManagerCommentsForDefectItem: ""
            //         });
            //     }
            // }
            if (this.props.approveStatus !== prevProps.approveStatus) {
                if (this.props.approveStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverErrorApproveSTTimesheet: this.props.approveStatus.message });

                if (hasPayload(this.props.approveStatus) && this.props.approveStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.setState({
                        serverErrorApproveSTTimesheet: ""
                    });
                }
            }
            if (this.props.rejectStatus !== prevProps.rejectStatus) {
                if (this.props.rejectStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverErrorRejectSTTimesheet: this.props.rejectStatus.message });

                if (hasPayload(this.props.rejectStatus) && this.props.rejectStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.setState({
                        serverErrorRejectSTTimesheet: ""
                    });
                }
            }

        }
    };

    public render(): ReactNode {

        const { stTimesheetForApproval, logPopup, logPopupData, serverErrorSaveManagerCommentsForDefectItem, serverErrorApproveSTTimesheet, serverErrorRejectSTTimesheet } = this.state;
        const { getToken, approveStatus, rejectStatus, getSTTimesheetForApprovalGroupByDate ,getLookup} = this.props;
        const getRole = hasPayload(this.props.getToken) ? this.props.getToken.payload.response.repairLineRole : "";
        const componentCodes = hasPayload(getLookup) ? getLookup.payload.response.component_codes : [];
        const costcodes = hasPayload(getLookup) ? getLookup.payload.response.cost_codes : [];
        const jobs = hasPayload(getLookup) ? getLookup.payload.response.explorer_jobs : [];
        // console.log(stTimesheetForApproval)

        return (
            <PageSpacing title="ST Logs" description="FIELD - ST LOGS APPROVAL" fixedSpaceOnSmallerScreens={true}>
                {(getRole === "Manager") && <ApproveSTDriverLogsStyles>

                    <DispatchSubHeader
                        {...this.props}
                        token={getToken}
                    />

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12}>

                                <h2 className="text-center">APPROVE ST LOGS</h2>
                                <hr />

                                {getSTTimesheetForApprovalGroupByDate.kind === STATUS_ENUM.LOADING && <LALoading message="Loading ST timesheet..." />}
                                {getSTTimesheetForApprovalGroupByDate.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load ST timesheet..." />}

                                {getSTTimesheetForApprovalGroupByDate.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            {(stTimesheetForApproval && stTimesheetForApproval?.length > 0)  ?  
                                                stTimesheetForApproval.map((x: ISTTimesheetForApprovalGroupByDate, index: number) => {
                                                    
                                                    return <>
                                                        <LAExpansionPanel color={BLUE_COLOR} textColor={WHITE_COLOR} label={"Date: " + splitDate(x.date)} expanded={x.expand ? x.expand : false} onClick={() => this.handleDateExpandClick(index)} key={index}>
                                                            <LAGrid>
                                                                {x.drivers.length > 0 &&
                                                                    x.drivers.map((m: IDriversLogsInfo, mIndex: number) => {
                                                                        const totalHours =  m.logs.length > 0 ? m.logs.reduce((sum, log) => sum + log.hours, 0) : 0;
                                                                        const defectTotalHours = m.logs.length > 0 ? m.logs.filter((x) => x.defect_ID > 0).reduce((sum, log) => sum + log.hours, 0) : 0;
                                                                        const miscTotalHours = m.logs.length > 0 ? m.logs.filter((x) => x.defect_ID === 0 && x.site === null).reduce((sum, log) => sum + log.hours, 0) : 0;
                                                                        const serviceLogsTotalHours = m.logs.length > 0 ? m.logs.filter((x) => x.defect_ID === 0 && x.site).reduce((sum, log) => sum + log.hours, 0) : 0;
                                                                        const onApproveTimesheetClick = (): void => this.handleApproveTimesheet(m);
                                                                        const onRejectTimesheetClick = (): void => this.handleRejectTimesheet(m);
                                                                        const warningIcon = m.logs.some((x) => x.defect_Type);
                                                                        // const loa = m.logs.some((x) => x.loa === "Yes")
                                                                        const handleViewClick = (e:any, value:string): void => this.handleDriverViewClick(index, mIndex, value);
                                                                        const onRejectCommentsChange = (name: string, value: string): void => this.handleDriverRejectCommentsClick(index, mIndex, value);
                                                                        // console.log(m)
                                                                        return <LAGridItem xs={12} sm={6} md={3} key={mIndex}>
                                                                            <LAExpansionPanel color={GREEN_COLOR} textColor={WHITE_COLOR} lunchIcon={m.lunch === "Yes"} label={m.name +` (${m.start_Time} - ${m.end_Time})`} 
                                                                                warningIcon={warningIcon} defectTotalHours={defectTotalHours} miscTotalHours={miscTotalHours} serviceLogsTotalHours={serviceLogsTotalHours} totalHours={totalHours} expanded={m.expand ? m.expand : false} 
                                                                                onClick={() => this.handleDriverExpandClick(index, mIndex)} key={mIndex}
                                                                            >
                                                                                <LAGrid>

                                                                                    <LAGridItem xs={12}>
                                                                                        <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={m.view ? m.view : "Approve"} onChange={handleViewClick}>
                                                                                            <FormControlLabel value="Approve" control={<Radio />} label="Approve" />
                                                                                            <FormControlLabel value="Reject" control={<Radio />} label="Reject" />
                                                                                        </RadioGroup>
                                                                                    </LAGridItem>

                                                                                    {(m.view === "Approve" || m.view === undefined) && 
                                                                                        <LAGridItem xs={12} className="text-center">
                                                                                            <LAButton
                                                                                                label="Approve TimeSheet"
                                                                                                onClick={onApproveTimesheetClick}
                                                                                                disabled={m.driver_UPN === userName ? true : undefined}
                                                                                            />
                                                                                        </LAGridItem>
                                                                                    }

                                                                                    {(m.view === "Reject") && <LAGridItem xs={12} className="text-center">
                                                                                        <LATextArea
                                                                                            minRows={2}
                                                                                            rowsMax={4}
                                                                                            fullWidth={true}
                                                                                            variant="outlined"
                                                                                            indexPosition={index}
                                                                                            name="reject_Comments"
                                                                                            label="Reject Comments"
                                                                                            disabled={undefined}
                                                                                            errorText={m.reject_Comments && m.reject_Comments?.length > 0 ? undefined : "Required" }
                                                                                            value={m.reject_Comments ?? ""}
                                                                                            onChange={onRejectCommentsChange}
                                                                                        />
                                                                                        </LAGridItem>
                                                                                    }

                                                                                    {(m.view === "Reject") && <LAGridItem xs={12} className="text-center">
                                                                                        <LAButton
                                                                                            label="Reject TimeSheet"
                                                                                            onClick={onRejectTimesheetClick}
                                                                                            disabled={m.driver_UPN === userName || m.reject_Comments === undefined || m.reject_Comments.length === 0 ? true : undefined}
                                                                                        />
                                                                                    </LAGridItem>}

                                                                                    {m.logs.length > 0 &&
                                                                                        m.logs.map((log: ISTLog, LIndex: number) => {
                                                                                            const bgColor = this.getBgColorForTile(log);
                                                                                            return <LAGridItem xs={12} key={LIndex} className="cursor" onClick={() => this.handleLogClick(log)}>
                                                                                                <LAPaperWithPadding className={bgColor}>

                                                                                                    {log.defect_ID > 0 && log.defect_Type && <>
                                                                                                        <strong style={{ color: "black"}} className="pull-right">{log.defect_Type}</strong>
                                                                                                        <br />
                                                                                                    </>}
                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Unit/Attachment#:  </strong>
                                                                                                        <span style={{ color: "black"}}>{log.display}</span>
                                                                                                        <br />
                                                                                                    </>}
                                                                                                    
                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Defect Item:  </strong>
                                                                                                        <span style={{ color: "black"}}>{log.defect_Item}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Component Code: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.component_Code}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Manager Instructions: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.manager_Instructions}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {/* {log.defect_ID > 0 && <>
                                                                                                        <strong>Charge Location: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.charge_Location}</span>
                                                                                                        <br />
                                                                                                    </>} */}

                                                                                                    {/* {log.defect_ID > 0 && log.parts_Delay_Hours && <>
                                                                                                        <strong>Parts Delay Hours: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.parts_Delay_Hours.toFixed(1)}</span>
                                                                                                        <br />
                                                                                                    </>} */}

                                                                                                    {/* {log.defect_ID > 0 && log.parts_Delay_Hours && log.parts_Delay_Comments && <>
                                                                                                        <strong>Parts Delay Comments: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.parts_Delay_Comments}</span>
                                                                                                        <br />
                                                                                                    </>} */}

                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Hours: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.hours.toFixed(1)}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>Defect Status: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.defect_Status}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID > 0 && <>
                                                                                                        <strong>WO#/Job-Cost# : </strong>
                                                                                                        <span style={{ color: "black" }} >{
                                                                                                            (log.job_No || log.cost_Code) ? 
                                                                                                                (log.job_No && log.job_No) + "-" + (log.cost_Code && log.cost_Code) : 
                                                                                                                log.work_Order_No && log.work_Order_No 
                                                                                                        }</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID === 0 && log.site === null && <>
                                                                                                        <strong>Type: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.type}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {/* {log.defect_ID === 0 && log.travel_Type === "travel" && <>
                                                                                                        <strong>Travel Type: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.travel_Type}</span>
                                                                                                        <br />
                                                                                                    </>} */}

                                                                                                    {log.defect_ID === 0 && log.site === null && <>
                                                                                                        <strong>Hours: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.hours.toFixed(1)}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID === 0 && log.site === null && <>
                                                                                                        <strong>Job-Cost# : </strong>
                                                                                                        <span style={{ color: "black" }} >{
                                                                                                            (log.job_No || log.cost_Code) &&
                                                                                                                (log.job_No && log.job_No) + "-" + (log.cost_Code && log.cost_Code) 
                                                                                                        }</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID === 0 && log.site && <>
                                                                                                        <strong>Site: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.site}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    {log.defect_ID === 0 && log.site && <>
                                                                                                        <strong>Hours: </strong>
                                                                                                        <span style={{ color: "black"}}>{log.hours.toFixed(1)}</span>
                                                                                                        <br />
                                                                                                    </>}

                                                                                                    <strong>Comments: </strong>
                                                                                                    <span style={{ color: "black"}}>{log.comments}</span>
                                                                                                    <br />
                                                                                                    
                                                                                                </LAPaperWithPadding>
                                                                                            </LAGridItem>
                                                                                        })
                                                                                    }
                                                                                </LAGrid>
                                                                            </LAExpansionPanel>
                                                                        </LAGridItem>
                                                                    })
                                                                }
                                                            </LAGrid>
                                                        </LAExpansionPanel>
                                                        <br />
                                                    </>
                                                })
                                            : <LAErrorBox text="No ST timesheet for approval..." />}
                                        </LAGridItem>
                                        
                                        {/* {logPopup && <LogPopup 
                                            open={logPopup}
                                            onCancel={this.handleLogPopupClose}
                                            onSave={this.handleLogPopupSave}
                                            data={logPopupData}
                                            componentCodes={componentCodes}
                                            jobs={jobs}
                                            costCodes={costcodes}
                                            serverErrorSaveManagerCommentsForDefectItem={serverErrorSaveManagerCommentsForDefectItem}
                                        />} */}
                                    </LAGrid>}
                            </LAGridItem>
                            {((serverErrorApproveSTTimesheet !== null) && (serverErrorApproveSTTimesheet.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverErrorApproveSTTimesheet} />
                            </LAGridItem>}
                            {((serverErrorRejectSTTimesheet !== null) && (serverErrorRejectSTTimesheet.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverErrorRejectSTTimesheet} />
                            </LAGridItem>}
                        </LAGrid>

                        <RequestStatus requestStatus={approveStatus.kind} />
                        <RequestStatus requestStatus={rejectStatus.kind} />
                    </LAPaperWithPadding>
                </ApproveSTDriverLogsStyles>}
            </PageSpacing>
        );
    }

    private getBgColorForTile = (log: ISTLog) : string => {
        let bgColor = "";
        if(log.defect_ID > 0) {
            if(log.defect_Type === null) {
                bgColor = "bg_DefectItemLog";
            } 
            else {
                bgColor = "bg_DefectType"
            }
        } 
        else if(log.defect_ID === 0 && log.site === null) {
            bgColor = "bg_MiscLog"
        }
        else if(log.defect_ID === 0 && log.site) {
            bgColor = "bg_ServiceLog"
        }
        return bgColor
    }

    private handleDateExpandClick = (index: number) : void => {
        const iS = [...this.state.stTimesheetForApproval];
        iS[index].expand = !iS[index].expand;

        this.setState({
            stTimesheetForApproval : iS
        })
    };

    private handleDriverExpandClick = (index: number, mIndex: number) : void => {
        const iS = [...this.state.stTimesheetForApproval];
        iS[index].drivers[mIndex].expand = !iS[index].drivers[mIndex].expand;

        this.setState({
            stTimesheetForApproval : iS
        })
    };

    private handleDriverViewClick = (index: number, mIndex: number, value: string) : void => {
        const iS = [...this.state.stTimesheetForApproval];
        iS[index].drivers[mIndex].view = value;

        if(value) {
            iS[index].drivers[mIndex].reject_Comments = undefined;
        }

        this.setState({
            stTimesheetForApproval : iS
        })
    };

    private handleDriverRejectCommentsClick = (index: number, mIndex: number, value: string) : void => {
        const iS = [...this.state.stTimesheetForApproval];
        iS[index].drivers[mIndex].reject_Comments = value;

        this.setState({
            stTimesheetForApproval : iS
        })
    };

    private handleLogPopupSave = (data: ISTLog | undefined): void => {
        const { getToken, saveManagerCommentsForDefectItemRequest} = this.props;

        if(data && hasPayload(getToken)) {
            // console.log(data)
            // if(data.defect_ID > 0) {
            //     let request = {
            //         ID: data.id,
            //         Defect_ID: data.defect_ID,
            //         Manager_Instructions: data.manager_Instructions,
            //         Major_Component_Replacement: data.major_Component_Replacement,
            //         Major_Type: data.major_Type,
            //         Component_Repaired_Location: data.component_Repaired_Location,
            //         OEM_Part: data.oeM_Part,
            //         Exchange_Type: data.exchange_Type,
            //         Condition: data.condition,
            //         Warranty_Details: data.warranty_Details,
            //         Vendor: data.vendor,
            //         BER_Details: data.beR_Details,
            //         Serial_No: data.serial_No,
            //         Major_Component_Replacement_Type: data.major_Component_Replacement_Type,
            //         Major_Component_Replacement_Hours: Number(data.major_Component_Replacement_Hours),
            //         Major_Component_Replacement_Notes: data.major_Component_Replacement_Notes,
            //         New_Major_Component_Replacement_Notes: data.new_Major_Component_Replacement_Notes,
            //         Meter_Reading: Number(data.meter_Reading),
            //         Defect_Status: data.defect_Status,
            //         Charge_Location: data.charge_Location,
            //         Component_Code: data.component_Code,
            //         Modified_By: getToken.payload.response.upn
            //     };
            //     // console.log(request)
            //     saveManagerCommentsForDefectItemRequest({
            //         request: request,
            //         token: getToken.payload.response.token
            //     });
            // }
            // else if(data.defect_ID === 0) {
            //     let request = {
            //         ID: data.id,
            //         Defect_ID: data.defect_ID,
            //         // Billable: data.billable,
            //         // Billable_Percentage: Number(data.billable_Percentage) > 0 ? Number(data.billable_Percentage) : null,
            //         Job_No: data.job_No,
            //         Cost_Code: data.cost_Code,
            //         Modified_By: getToken.payload.response.upn
            //     };

            //     saveManagerCommentsForDefectItemRequest({
            //         request: request,
            //         token: getToken.payload.response.token
            //     });
            // }
        }
    };

    private handleLogPopupClose = (): void => {
        this.setState({ 
            logPopup: false,
            logPopupData: undefined
        });
    };

    private handleLogClick = (data: ISTLog) : void => { 
        // console.log(data)
        this.setState({ 
            logPopup: true,
            logPopupData: data
        });
    }
  
    private handleApproveTimesheet = (timesheet: IDriversLogsInfo): void => {
        // console.log(timesheet)
        if (hasPayload(this.props.getToken)) {
            this.props.approveRequest({
                request: {
                    // approveList,
                    Timesheet_ID: timesheet.timesheet_ID,
                    Modified_By: this.props.getToken.payload.response.upn,
                },
                token: this.props.getToken.payload.response.token
            });
        }
    };

    private handleRejectTimesheet = (timesheet: IDriversLogsInfo): void => {
        // console.log(timesheet)
        if (hasPayload(this.props.getToken)) {
            if(timesheet.reject_Comments) {
                this.props.rejectRequest({
                    request: {
                        Timesheet_ID : timesheet.timesheet_ID,
                        Rejection_Notes: timesheet.reject_Comments,
                        Modified_By: this.props.getToken.payload.response.upn,
                    },
                    token: this.props.getToken.payload.response.token
                });
            }
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            // console.log(this.props.getToken)
            if (this.props.getToken.payload.response.repairLineRole === "Manager") {
                
                if (isNotLoaded(this.props.getSTTimesheetForApprovalGroupByDate)) {
                    this.getDataForTable();
                };

                // if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.getToken)) {
                //     this.getLook();
                // }
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };

        if (hasPayload(this.props.getSTTimesheetForApprovalGroupByDate)) {
            const data = Object.values(this.props.getSTTimesheetForApprovalGroupByDate.payload.response);
            // console.log(data)
            this.setState({
                stTimesheetForApproval: data
            });
        }
    };

    // private getLook = (): void => {
    //     if (hasPayload(this.props.getToken))
    //         this.props.getLookupsRequest({
    //             token: this.props.getToken.payload.response.token,
    //             request: {
    //                 page: "Mechanic_Timesheets"
    //             }
    //         });
    // };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            // const getToken = this.props.getToken.payload.response;      
            this.props.getSTTimesheetForApprovalGroupByDateRequest({
                token: this.props.getToken.payload.response.token,
                // request: {
                //     Manager_Type : getToken.repairRoles.field_Manager ? "Field" : getToken.repairRoles.shop_Manager ? "Shop" : getToken.repairRoles.lube_Manager ? "Lube" : ""
                // }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IApproveSTDriverLogsStoreProps => ({
    getToken: getToken(state),
    approveStatus: approveSTTimesheetStatus(state),
    rejectStatus: rejectSTTimesheetStatus(state),
    getLookup: getFieldLookups(state),
    getSTTimesheetForApprovalGroupByDate: getSTTimesheetForApprovalGroupByDate(state),
    saveManagerCommentsForDefectItem: saveManagerCommentsForDefectItemStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IApproveSTDriverLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getSTTimesheetForApprovalGroupByDateRequest: (data: IGetSTTimesheetForApprovalGroupByDateRequest) => dispatch(getSTTimesheetForApprovalGroupByDateLoadAction(data)),
    approveRequest: (request: IApproveSTTimesheetRequest): unknown => dispatch(approveSTTimesheetLoadAction(request)),
    rejectRequest: (request: IRejectSTTimesheetRequest): unknown => dispatch(rejectSTTimesheetLoadAction(request)),
    saveManagerCommentsForDefectItemRequest: (data: ISaveManagerCommentsForDefectItemRequest) => dispatch(saveManagerCommentsForDefectItemLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveSTDriverLogs);

// interface ILogPopupProps {
//     open: boolean;
//     onCancel: () => void;
//     onSave: (data:ISTLog | undefined) => void;
//     data: ISTLog | undefined;
//     componentCodes: IComponentCodeList[];
//     jobs: IJobListResponse[];
//     costCodes: ICostCode[];
//     serverErrorSaveManagerCommentsForDefectItem: string;
// };

// export const ChargeLocationList: IIdName[] = [
//     { id: 0, name: "Field" },
//     { id: 1, name: "Shop" }
// ];

// const LogPopup: React.FC<ILogPopupProps> = React.memo((props: ILogPopupProps) => {

//     const [state, setState] = useState<ISTLog | undefined>(props.data);
//     const [errors, setErrors] = useState<ById<IFieldErrorKeyValue>>({});
//     const disableByStatus = props.data ? props.data.defect_Status === "Completed" || props.data.defect_Status === "Cancelled" : false;
//     // console.log(state)

//     const onComponentCode = (value: string): void => {
//         setState((prevState: any) => ({
//             ...prevState,
//             component_Code: value.length > 0 ? value : prevState.component_Code,
//         }));
//     };

//     const handleChange = (name: string, value: string) => {
//         // if(name === "billable_Percentage") {
//         //     if(Number(value) > 100) {
//         //         alert("Billable Percentage must be between 0 to 100.")
//         //     } else {
//         //         setState((prevState: any) => ({
//         //             ...prevState,
//         //             [name]: name === "billable_Percentage" ? Number(value) : value,
//         //         }));
//         //     }
//         // } 
//         // else 
//         if(name === "job_No") {
//             setState((prevState: any) => ({
//                 ...prevState,
//                 [name]: value,
//                 cost_Code: "" 
//             }));
//         }
//         // else if(name === "defect_Status") {
//         //     let error = errors;
//         //     if(value === ComponentStatusList[3].name) {

//         //         if (((state?.meter_Reading === undefined) || (state?.meter_Reading === null)) && !(state?.item_Attachment_ID && state.item_Attachment_ID > 0) && state?.unit_Status !== "Used Parts")  {
//         //             error["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
//         //         } else {
//         //             if (error["meter_Reading"])
//         //                 delete error["meter_Reading"];
//         //         }

//         //         if(state?.major_Component_Replacement === "Yes") {
//         //             // if (error["major_Component_Replacement_Type"] === undefined)
//         //             //     error = errorChecker("major_Component_Replacement_Type", state?.major_Component_Replacement_Type ? state?.major_Component_Replacement_Type : '', errors);
//         //             if (error["major_Type"] === undefined)
//         //                 error = errorChecker("major_Type", state?.major_Type ? state?.major_Type : '', errors);

//         //             if (state?.major_Type === "Repair and Return to Unit") {
//         //                 if(error["component_Repaired_Location"] === undefined) {
//         //                     error = errorChecker("component_Repaired_Location", state?.component_Repaired_Location ? state?.component_Repaired_Location : '', errors);
//         //                 }
//         //                 if(error["oeM_Part"] === undefined) {
//         //                     error = errorChecker("oeM_Part", state?.oeM_Part ? state?.oeM_Part : '', errors);
//         //                 }
//         //             }

//         //             if (state?.major_Type === "Exchange") {
//         //                 if(error["exchange_Type"] === undefined) {
//         //                     error = errorChecker("exchange_Type", state?.exchange_Type ? state?.exchange_Type : '', errors);
//         //                 }
//         //                 if(error["condition"] === undefined) {
//         //                     error = errorChecker("condition", state?.condition ? state?.condition : '', errors);
//         //                 }
//         //                 if(state?.exchange_Type === "External Purchase" && errors["vendor"] === undefined) {
//         //                     error = errorChecker("vendor", state?.vendor ? state?.vendor : '', errors);
//         //                 }
//         //             }

//         //             if (error["serial_No"] === undefined)
//         //                 error = errorChecker("serial_No", state?.serial_No ? state?.serial_No : '', errors);
//         //             if (error["major_Component_Replacement_Hours"] === undefined)
//         //                 error = errorChecker("major_Component_Replacement_Hours", state?.major_Component_Replacement_Hours ? state?.major_Component_Replacement_Hours.toString() : '', errors);
//         //             if (error["new_Major_Component_Replacement_Notes"] === undefined)
//         //                 error = errorChecker("new_Major_Component_Replacement_Notes", state?.new_Major_Component_Replacement_Notes ? state?.major_Component_Replacement_Notes : '', errors);
//         //             // if (error["major_Component_Replacement_Notes"] === undefined)
//         //             //     error = errorChecker("major_Component_Replacement_Notes", state?.major_Component_Replacement_Notes ? state?.major_Component_Replacement_Notes : '', errors);
//         //         } 

//         //         setErrors(error);
//         //     } 
//         //     else {
//         //         if (error["meter_Reading"])
//         //             delete error["meter_Reading"];
//         //         // if (error["major_Component_Replacement_Type"])
//         //         //     delete error["major_Component_Replacement_Type"];
//         //         if(error["component_Repaired_Location"])
//         //             delete error["component_Repaired_Location"];
//         //         if(error["oeM_Part"])
//         //             delete error["oeM_Part"];
//         //         if(state?.major_Type === "Exchange") {
//         //             if(errors["exchange_Type"] === undefined) {
//         //                 error = errorChecker("exchange_Type", state?.exchange_Type ? state?.exchange_Type : '', errors);
//         //             }
//         //         }
//         //         if(state?.major_Type !== "Exchange") {
//         //             if(error["exchange_Type"])
//         //                 delete error["exchange_Type"];
//         //         }
//         //         if(error["condition"])
//         //             delete error["condition"];
//         //         if(error["vendor"])
//         //             delete error["vendor"];

//         //         if (error["serial_No"])
//         //             delete error["serial_No"];
//         //         if (error["major_Component_Replacement_Hours"])
//         //             delete error["major_Component_Replacement_Hours"];
//         //         if (error["new_Major_Component_Replacement_Notes"])
//         //             delete error["new_Major_Component_Replacement_Notes"];
//         //         // if (error["major_Component_Replacement_Notes"])
//         //         //     delete error["major_Component_Replacement_Notes"];

//         //     }
//         //     setState((prevState: any) => ({
//         //         ...prevState,
//         //         [name]: value,
//         //     }));
//         // }
//         // else if(name === "major_Component_Replacement_Type" || name === "serial_No" || name === "major_Component_Replacement_Hours" || name === "new_Major_Component_Replacement_Notes"
//         //     || name === "major_Type" || name === "component_Repaired_Location" || name === "oeM_Part" || name === "vendor"
//         //     || name === "exchange_Type" || name === "condition" || name === "warranty_Details" || name === "beR_Details"
//         // ) {
//         //     let error = errors;
//         //     if(state?.defect_Status === ComponentStatusList[3].name) {
//         //         if (value.length > 0) {
//         //             if (error[name])
//         //                 delete error[name];
//         //         } else {
//         //             error[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
//         //         };
//         //     }
//         //     else {
//         //         if (error[name])
//         //             delete error[name];
//         //     }
            
//         //     setState((prevState: any) => ({
//         //         ...prevState,
//         //         [name]: value,
//         //     }));
//         //     setErrors(error);
//         // }
//         // else if(name === "meter_Reading") {
//         //     let error = errors;
//         //     if (value.length > 0) {
//         //         if (error[name])
//         //             delete error[name];
//         //     } else {
//         //         error[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
//         //     };
            
//         //     setState((prevState: any) => ({
//         //         ...prevState,
//         //         [name]: value,
//         //     }));
//         //     setErrors(error);
//         // }
//         // else {
//         //     setState((prevState: any) => ({
//         //         ...prevState,
//         //         [name]: value,
//         //         // billable_Percentage : (name === "billable" && value === "No") ? null : prevState.billable_Percentage,
//         //     }));
//         // }

//     }

//     const errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
//         const result = FieldValidator(value, { required: true, minLength: 1 });
//         const err: ById<IFieldErrorKeyValue> = errors;

//         if (result.length > 0) {
//             err[name] = { key: name, message: result };
//         } else {
//             delete err[name];
//         }
//         return err;
//     };

//     const handleChargeLocation = (event: unknown, values: any): void =>  handleChange("charge_Location", values != null ? values.name : state?.charge_Location);
//     const handleStatus = (event: unknown, values: any): void =>  handleChange("defect_Status", values != null ? values.name : state?.defect_Status);
//     const handleMajorType = (event: unknown, values: any): void => handleChange("major_Type", values != null ? values.name : state?.major_Type)
//     const handleOEMPart = (event: unknown, values: any): void => handleChange("oeM_Part", values != null ? values.name : state?.oeM_Part)
//     const handleExchangeType = (event: unknown, values: any): void => handleChange("exchange_Type", values != null ? values.name : state?.exchange_Type)
//     const handleCondition = (event: unknown, values: any): void => handleChange("condition", values != null ? values.name : state?.condition)
//     const handleType = (event: unknown, values: any): void => handleChange("major_Component_Replacement_Type", values != null ? values.name : state?.major_Component_Replacement_Type)
    
//     // const handleBillableCheckboxChange = (value: boolean): void => handleChange("billable", value === true ? "Yes" : "No");
//     const onJob = (e: unknown, v: any): void => handleChange("job_No", v !== null ? v.job : "");
//     const onCostCode = (e: unknown, v: ICostCode): void => handleChange("cost_Code", v !== null ? v.cost_Code : "");
//     const onSaveClick = () : void => props.onSave(state);

//     const disableSave = Object.values(errors).length > 0 
//                         // || (props.data?.component_Code === state?.component_Code && 
//                         // props.data?.manager_Instructions === state?.manager_Instructions && 
//                         // props.data?.defect_Status === state?.defect_Status && 
//                         // props.data?.billable === state?.billable && 
//                         // props.data?.billable_Percentage === state?.billable_Percentage &&
//                         // props.data?.job_No === state?.job_No &&
//                         // props.data?.cost_Code === state?.cost_Code);

//     return <LAPopover 
//             anchorRef={null}
//             // fullWidth={true}
//             onClose={props.onCancel}
//             open={props.open}
//         >
//             <LAPaperWithPadding>
//                 <LAGrid>
//                     <LAGridItem xs={12}>
//                         <h2 className="text-center">ST LOG</h2>
//                         <hr />
//                     </LAGridItem>

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="unit"
//                             label="Unit/Attachment"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.display ? state?.display : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="defect_Item"
//                             label="Defect Item"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.defect_Item ? state?.defect_Item : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <DevExtremePopupLookup
//                             id="component-code-search"
//                             placeHolder="Component Code"
//                             disabled={undefined}
//                             displayExp="code"
//                             errors={
//                                 {}
//                             }
//                             name="code"
//                             errorName="code"
//                             currentVal={state?.component_Code ? state?.component_Code : ""}
//                             data={props.componentCodes}
//                             onClick={onComponentCode}
//                             columns={[
//                                 { name: "code", caption: "Code", type: "string" },
//                                 { name: "description", caption: "Description", type: "string" }
//                             ]}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ?<LAGridItem xs={12} sm={6} md={3}>
//                         <LATextArea
//                             minRows={4}
//                             rowsMax={10}
//                             type="string"
//                             fullWidth={true}
//                             variant="outlined"
//                             name="manager_Instructions"
//                             label="Manager Instructions"
//                             onChange={handleChange}
//                             value={state?.manager_Instructions ? state?.manager_Instructions : ""}
//                             disabled={undefined}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ?<LAGridItem xs={12} sm={6} md={3}>
//                         <LAAutoComplete
//                             multiple={false}
//                             option={ChargeLocationList}
//                             autoHighlight={true}
//                             onChange={handleChargeLocation}
//                             disabled={undefined}
//                             getOptionLabel="name"
//                             filterSelectedOptions={true}
//                             dropDownPlaceHolder="Charge Location"
//                             selectionRemove={undefinedFunction}
//                             value={state?.charge_Location && ChargeLocationList ? ChargeLocationList.find(q => q.name === state?.charge_Location) : null}
//                             defaultValue={state?.charge_Location && ChargeLocationList ? ChargeLocationList.find(q => q.name === state?.charge_Location) : null}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 && state.parts_Delay_Hours ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="parts_Delay_Hours"
//                             label="Parts Delay Hours"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             type="number"
//                             value={state?.parts_Delay_Hours ? state?.parts_Delay_Hours : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem> : <></>}

//                     {state?.defect_ID && state.defect_ID > 0 && state.parts_Delay_Hours && state.parts_Delay_Comments ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="parts_Delay_Comments"
//                             label="Parts Delay Comments"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.parts_Delay_Comments ? state?.parts_Delay_Comments : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>: <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="hours"
//                             label="Hours"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             type="number"
//                             value={state?.hours ? state?.hours : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem> : <></>}

//                     {/* {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
                        
//                         <LAAutoComplete
//                             multiple={false}
//                             option={ComponentStatusList}
//                             autoHighlight={true}
//                             onChange={handleStatus}
//                             disabled={disableByStatus}
//                             getOptionLabel="name"
//                             filterSelectedOptions={true}
//                             dropDownPlaceHolder="Defect Status"
//                             selectionRemove={undefinedFunction}
//                             value={state?.defect_Status && ComponentStatusList ? ComponentStatusList.find(q => q.name === state?.defect_Status) : null}
//                             defaultValue={state?.defect_Status && ComponentStatusList ? ComponentStatusList.find(q => q.name === state?.defect_Status) : null}
//                         />

//                     </LAGridItem>: <></>} */}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state.defect_Status === ComponentStatusList[3].name && !(state.item_Attachment_ID > 0) ? <LAGridItem xs={12} sm={6} md={3}>
//                             <LATextField
//                                 type="number"
//                                 label="Meter Reading"
//                                 fullWidth={true}
//                                 variant="outlined"
//                                 name="meter_Reading"
//                                 disabled={disableByStatus || state?.unit_Status === "Used Parts"}
//                                 value={state?.meter_Reading ?? ""}
//                                 onChange={handleChange}
//                                 errorText={
//                                     errors["meter_Reading"] ?
//                                         errors["meter_Reading"].message
//                                         : undefined
//                                 }
//                             />
//                         </LAGridItem>
//                     : <></>} */}

//                     {state?.defect_ID && state.defect_ID > 0 && state.defect_Type ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="defect_Type"
//                             label="Defect Type"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.defect_Type ? state?.defect_Type : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>: <></>}

//                     {state?.defect_ID && state.defect_ID > 0 && !(state.item_Attachment_ID > 0) ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LACheckBox
//                             name="major_Component_Replacement"
//                             label="Major Component Replacement"
//                             onChange={undefinedFunction}
//                             disabled={true}
//                             value={(state?.major_Component_Replacement === "Yes") ? true : false}
//                         />
//                     </LAGridItem>: <></>}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes"  && !(state.item_Attachment_ID > 0) ? <LAGridItem  xs={12} sm={6} md={3}>
//                         <LAAutoComplete
//                             // style={{ backgroundColor: "white"}}
//                             multiple={false}
//                             option={MajorTypeList}
//                             autoHighlight={true}
//                             onChange={handleMajorType}
//                             getOptionLabel="display"
//                             filterSelectedOptions={true}
//                             dropDownPlaceHolder="Major Type"
//                             selectionRemove={undefinedFunction}
//                             disabled={true}
//                             errorText={errors["major_Type"] ? errors["major_Type"].message : undefined}
//                             value={state?.major_Type && MajorTypeList ? MajorTypeList.find(q => q.name === state.major_Type) : null}
//                             defaultValue={state?.major_Type && MajorTypeList ? MajorTypeList.find(q => q.name === state.major_Type) : null}
//                         /> 
//                         </LAGridItem>: <></>} */}

//                     {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes"  && !(state.item_Attachment_ID > 0) && state?.major_Type === "Repair and Return to Unit" ? 
//                         <>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LATextArea
//                                     // style={{ backgroundColor: "white"}}
//                                     minRows={1}
//                                     rowsMax={3}
//                                     fullWidth={true}
//                                     variant="outlined"
//                                     name="component_Repaired_Location"
//                                     label="Where was component Repaired"
//                                     disabled={disableByStatus}
//                                     errorText={errors["component_Repaired_Location"] ? errors["component_Repaired_Location"].message : undefined}
//                                     value={state?.component_Repaired_Location ?? ""}
//                                     onChange={handleChange}
//                                 />
//                             </LAGridItem>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LAAutoComplete
//                                     // style={{ backgroundColor: "white"}}
//                                     multiple={false}
//                                     option={YesOrNo}
//                                     autoHighlight={true}
//                                     onChange={handleOEMPart}
//                                     getOptionLabel="name"
//                                     filterSelectedOptions={true}
//                                     dropDownPlaceHolder="OEM Part"
//                                     selectionRemove={undefinedFunction}
//                                     disabled={disableByStatus}
//                                     errorText={errors["oeM_Part"] ? errors["oeM_Part"].message : undefined}
//                                     value={state?.oeM_Part && YesOrNo ? YesOrNo.find(q => q.name === state?.oeM_Part) : null}
//                                     defaultValue={state?.oeM_Part && YesOrNo ? YesOrNo.find(q => q.name === state?.oeM_Part) : null}
//                                 />
//                             </LAGridItem>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LATextField
//                                     // style={{ backgroundColor: "white"}}
//                                     label="Vendor"
//                                     fullWidth={true}
//                                     variant="outlined"
//                                     name="vendor"
//                                     value={state?.vendor ?? ""}
//                                     onChange={handleChange}
//                                     disabled={disableByStatus}
//                                     errorText={errors["vendor"] ? errors["vendor"].message : undefined}
//                                 />
//                             </LAGridItem>
//                         </>
//                     : <></>}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes"  && !(state.item_Attachment_ID > 0) && state?.major_Type === "Exchange" ? 
//                         <>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LAAutoComplete
//                                     // style={{ backgroundColor: "white"}}
//                                     multiple={false}
//                                     option={ExchangeList}
//                                     autoHighlight={true}
//                                     onChange={handleExchangeType}
//                                     getOptionLabel="name"
//                                     filterSelectedOptions={true}
//                                     dropDownPlaceHolder="Exchange Type"
//                                     selectionRemove={undefinedFunction}
//                                     disabled={true}
//                                     errorText={errors["exchange_Type"] ? errors["exchange_Type"].message : undefined}
//                                     value={state?.exchange_Type && ExchangeList ? ExchangeList.find(q => q.name === state?.exchange_Type) : null}
//                                     defaultValue={state?.exchange_Type && ExchangeList ? ExchangeList.find(q => q.name === state?.exchange_Type) : null}
//                                 />
//                             </LAGridItem>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LAAutoComplete
//                                     // style={{ backgroundColor: "white"}}
//                                     multiple={false}
//                                     option={ConditionList}
//                                     autoHighlight={true}
//                                     onChange={handleCondition}
//                                     getOptionLabel="name"
//                                     filterSelectedOptions={true}
//                                     dropDownPlaceHolder="Condition"
//                                     selectionRemove={undefinedFunction}
//                                     disabled={disableByStatus}
//                                     errorText={errors["condition"] ? errors["condition"].message : undefined}
//                                     value={state?.condition && ConditionList ? ConditionList.find(q => q.name === state.condition) : null}
//                                     defaultValue={state?.condition && ConditionList ? ConditionList.find(q => q.name === state.condition) : null}
//                                 />
//                             </LAGridItem>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LATextArea
//                                     // style={{ backgroundColor: "white"}}
//                                     minRows={1}
//                                     rowsMax={3}
//                                     fullWidth={true}
//                                     variant="outlined"
//                                     name="warranty_Details"
//                                     label="Warranty Details"
//                                     disabled={disableByStatus}
//                                     errorText={errors["warranty_Details"] ? errors["warranty_Details"].message : undefined}
//                                     value={state?.warranty_Details ?? ""}
//                                     onChange={handleChange}
//                                 />
//                             </LAGridItem>
//                             <LAGridItem xs={12} sm={6} md={3}>
//                                 <LATextField
//                                     // style={{ backgroundColor: "white"}}
//                                     label="Vendor"
//                                     fullWidth={true}
//                                     variant="outlined"
//                                     name="vendor"
//                                     value={state?.vendor ?? ""}
//                                     onChange={handleChange}
//                                     disabled={disableByStatus}
//                                     errorText={errors["vendor"] ? errors["vendor"].message : undefined}
//                                 />
//                             </LAGridItem>
//                         </>
//                     : <></>} */}

//                     {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes"  && !(state.item_Attachment_ID > 0) && state?.major_Type === "BER" ? 
//                         <LAGridItem xs={12} sm={6} md={3}>
//                             <LATextArea
//                                 // style={{ backgroundColor: "white"}}
//                                 minRows={1}
//                                 rowsMax={3}
//                                 fullWidth={true}
//                                 variant="outlined"
//                                 name="beR_Details"
//                                 label="BER Details"
//                                 disabled={disableByStatus}
//                                 errorText={errors["beR_Details"] ? errors["beR_Details"].message : undefined}
//                                 value={state?.beR_Details ?? ""}
//                                 onChange={handleChange}
//                             />
//                         </LAGridItem>
//                     : <></>}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes" && state.defect_Status === ComponentStatusList[3].name && !(state.item_Attachment_ID > 0) ? <LAGridItem  xs={12} sm={6} md={3}>
//                         <LATextField
//                             label="Serial #"
//                             fullWidth={true}
//                             variant="outlined"
//                             name="serial_No"
//                             value={state?.serial_No ?? ""}
//                             onChange={handleChange}
//                             disabled={disableByStatus}
//                             errorText={errors["serial_No"] ? errors["serial_No"].message : undefined}
//                         />
//                     </LAGridItem>: <></>} */}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes" && state.defect_Status === ComponentStatusList[3].name && !(state.item_Attachment_ID > 0) ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             type="number"
//                             label="Hours"
//                             fullWidth={true}
//                             variant="outlined"
//                             name="major_Component_Replacement_Hours"
//                             value={state?.major_Component_Replacement_Hours ?? ""}
//                             onChange={handleChange}
//                             disabled={disableByStatus}
//                             errorText={errors["major_Component_Replacement_Hours"] ? errors["major_Component_Replacement_Hours"].message : undefined}
//                         />
//                     </LAGridItem>: <></>} */}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes" && state.defect_Status === ComponentStatusList[3].name && !(state.item_Attachment_ID > 0) ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LAAutoComplete
//                             multiple={false}
//                             option={TypeList}
//                             autoHighlight={true}
//                             onChange={handleType}
//                             getOptionLabel="name"
//                             filterSelectedOptions={true}
//                             dropDownPlaceHolder="Type"
//                             selectionRemove={undefinedFunction}
//                             disabled={disableByStatus}
//                             errorText={errors["major_Component_Replacement_Type"] ? errors["major_Component_Replacement_Type"].message : undefined}
//                             value={state?.major_Component_Replacement_Type && TypeList ? TypeList.find(q => q.name === state?.major_Component_Replacement_Type) : null}
//                             defaultValue={state?.major_Component_Replacement_Type && TypeList ? TypeList.find(q => q.name === state?.major_Component_Replacement_Type) : null}
//                         />
//                     </LAGridItem>: <></>} */}

//                     {/* {state?.defect_ID && state.defect_ID > 0 && state?.major_Component_Replacement === "Yes" && state.defect_Status === ComponentStatusList[3].name && !(state.item_Attachment_ID > 0) ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextArea
//                             minRows={3}
//                             rowsMax={4}
//                             fullWidth={true}
//                             variant="outlined"
//                             name="major_Component_Replacement_Notes"
//                             label="Major Component Replacement Notes"
//                             disabled={true}
//                             errorText={errors["major_Component_Replacement_Notes"] ? errors["major_Component_Replacement_Notes"].message : undefined}
//                             value={state?.major_Component_Replacement_Notes ?? ""}
//                             // value={`${state?.major_Component_Replacement_Notes ?? ""}\n${state?.new_Major_Component_Replacement_Notes ?? ""}` ?? ""}
//                             onChange={handleChange}
//                         />
//                         <LATextArea
//                             style={{ backgroundColor: "white"}}
//                             minRows={3}
//                             rowsMax={4}
//                             fullWidth={true}
//                             variant="outlined"
//                             name="new_Major_Component_Replacement_Notes"
//                             label="Add Notes"
//                             disabled={undefined}
//                             errorText={errors["new_Major_Component_Replacement_Notes"] ? errors["new_Major_Component_Replacement_Notes"].message : undefined}
//                             value={state?.new_Major_Component_Replacement_Notes ?? ""}
//                             onChange={handleChange}
//                         />
//                     </LAGridItem>: <></>} */}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="job_No"
//                             label="Job No"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.job_No ? state?.job_No : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>: <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="cost_Code"
//                             label="Cost Code"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.cost_Code ? state?.cost_Code : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>: <></>}

//                     {state?.defect_ID && state.defect_ID > 0 ? <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="work_Order_No"
//                             label="Work Order"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.work_Order_No ? state?.work_Order_No : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>: <></>}

//                     {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="type"
//                             label="Type"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.type ? state?.type : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>}

//                     {state?.defect_ID === 0 && state?.travel_Type === "travel" && <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="travel_Type"
//                             label="Travel Type"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.travel_Type ? state?.travel_Type : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>}

//                     {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="hours"
//                             label="Hours"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             type="number"
//                             value={state?.hours ? state?.hours : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>}

//                     {/* {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <strong>Billable</strong><br />
//                         <LACheckBox
//                             value={state?.billable === "Yes" ? true : false}
//                             label="Yes"
//                             disabled={undefined}
//                             onChange={handleBillableCheckboxChange}
//                         />
//                     </LAGridItem>} 

//                     {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextField
//                             name="billable_Percentage"
//                             label="Billable Percentage"
//                             fullWidth={true}
//                             disabled={state?.billable !== "Yes"}
//                             variant="outlined"
//                             type="number"
//                             InputProps={{
//                                 inputProps: {
//                                     max: 100
//                                 }
//                             }}
//                             value={state?.billable_Percentage ? state?.billable_Percentage : ""}
//                             onChange={handleChange}
//                         />
//                     </LAGridItem>} */}

//                     {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <LADropDownForJobs
//                             onChange={onJob}
//                             option={props.jobs ?? []}
//                             autoHighlight={true}
//                             getOptionLabel="job"
//                             disabled={undefined}
//                             dropDownPlaceHolder="Job"
//                             filterSelectedOptions={true}
//                             value={props.jobs?.find(q => q.job === state?.job_No) ?? ""}
//                             defaultValue={props.jobs?.find(q => q.job === state?.job_No) ?? ""}
//                             disExp={[{ objName: "job", size: 4 }, { objName: "description", size: 6 }, { objName: "company", size: 2 }]}
//                         />
//                     </LAGridItem>}

//                     {state?.defect_ID === 0 && <LAGridItem xs={12} sm={6} md={3}>
//                         <LADropDownForJobs
//                             dropDownPlaceHolder="Cost Code"
//                             option={filterCostCodes(props.costCodes, state?.job_No) ?? []}
//                             defaultValue={props.costCodes?.find(q => q.cost_Code === state.cost_Code)  ?? ""}
//                             value={props.costCodes?.find(q => q.cost_Code === state.cost_Code) ?? ""}
//                             getOptionLabel="cost_Code"
//                             filterSelectedOptions={true}
//                             autoHighlight={true}
//                             disabled={!state?.job_No || state?.job_No.length === 0}
//                             onChange={onCostCode}
//                             disExp={[{ objName: "cost_Code", size: 4 }, { objName: "description", size: 6 }]}
//                         />
//                     </LAGridItem>}

//                     <LAGridItem xs={12} sm={6} md={3}>
//                         <LATextArea
//                             minRows={4}
//                             rowsMax={10}
//                             type="string"
//                             name="comments"
//                             label="Comments"
//                             fullWidth={true}
//                             disabled={true}
//                             variant="outlined"
//                             value={state?.comments ? state?.comments : ""}
//                             onChange={undefinedFunction}
//                         />
//                     </LAGridItem>

//                     {((props.serverErrorSaveManagerCommentsForDefectItem !== null) && (props.serverErrorSaveManagerCommentsForDefectItem.length > ZEROTH)) && <LAGridItem xs={12}>
//                         <LAErrorBox text={props.serverErrorSaveManagerCommentsForDefectItem} />
//                     </LAGridItem>}

//                     <LAGridItem xs={12} >
//                         <LASaveAndCancelButton
//                             onCancel={props.onCancel}
//                             saveButtonText="Save"
//                             cancelButtonText="Close"
//                             onSave={onSaveClick}
//                             disableSave={disableSave ? true : undefined}
//                         />
//                     </LAGridItem>
//                 </LAGrid>
//             </LAPaperWithPadding>
//         </LAPopover>
// });

// const filterCostCodes = (data: ICostCode[], job?: string): ICostCode[] => {
//     return data?.filter((x) => (job) ? x.job === job : true);
// };