import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SERVICE_SHEET_REPORT_REQUEST {
    REQUEST = "getServiceSheetReport/GET_SERVICE_SHEET_REPORT_REQUEST",
    SUCCESS = "getServiceSheetReport/GET_SERVICE_SHEET_REPORT_SUCCESS",
    FAILED = "getServiceSheetReport/GET_SERVICE_SHEET_REPORT_FAILED"
};

export interface IGetServiceSheetReportRequest extends ISurewayTokenRequestBody {
    request: {
        View: string;
    }
};

export interface IServiceSheetReport {
    id: number;
    unit_ID: number;
    location: string;
    sub_Location: string;
    service_Due: string;
    equipment_Type: string;
    unit_No: string;
    service_Date: string;
    hours: number;
    comments: string;
    make: string;
    model: string;
    status: string;
    approved_Date: string;
    service_Truck: string;
    admin_Comments: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    pictures: string;
    sections: IServiceSheetReportSection[];
};

export interface IServiceSheetReportSection {
    name: string;
    items: IServiceSheetReportSectionItem[];
    display_Order: number;
};

export interface IServiceSheetReportSectionItem {
    id: number;
    name: string;
    value: string; //"Yes" or "No"
    pictures: string;
    comments?: string;
};