import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_MECHANIC_LOG_REQUEST, IUpdateMechanicLogRequest } from "./updateMechanicLogConstants";


export interface IUpdateMechanicLogLoadAction {
    type: IUPDATE_MECHANIC_LOG_REQUEST.REQUEST;
    data: IUpdateMechanicLogRequest
}
export const updateMechanicLogLoadAction = (data: IUpdateMechanicLogRequest): IUpdateMechanicLogLoadAction => ({
    type: IUPDATE_MECHANIC_LOG_REQUEST.REQUEST,
    data
});
export interface IUpdateMechanicLogSuccessAction {
    type: IUPDATE_MECHANIC_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateMechanicLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateMechanicLogSuccessAction => ({
    type: IUPDATE_MECHANIC_LOG_REQUEST.SUCCESS,
    message
});
export interface IUpdateMechanicLogLoadFailedAction {
    type: IUPDATE_MECHANIC_LOG_REQUEST.FAILED;
    message: string;
}
export const updateMechanicLogLoadFailedAction = (message: string): IUpdateMechanicLogLoadFailedAction => ({
    type: IUPDATE_MECHANIC_LOG_REQUEST.FAILED,
    message
});
