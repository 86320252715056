import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_SURVEY_ATTACHMENT_REQUEST } from "./addSurveyAttachmentConstants";
import {
    IAddSurveyAttachmentLoadAction, IAddSurveyAttachmentLoadFailedAction, IAddSurveyAttachmentSuccessAction,
    addSurveyAttachmentLoadFailedAction, addSurveyAttachmentLoadSuccessAction
} from "./addSurveyAttachmentActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addSurveyAttachmentEpic: Epic = (
    action$: ActionsObservable<IAddSurveyAttachmentLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddSurveyAttachmentSuccessAction | IAddSurveyAttachmentLoadFailedAction> =>
    action$.ofType(IADD_SURVEY_ATTACHMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_SURVEY_ATTACHMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddSurveyAttachmentSuccessAction | IAddSurveyAttachmentLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return addSurveyAttachmentLoadSuccessAction(response);
                            } else {
                                return addSurveyAttachmentLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addSurveyAttachmentLoadFailedAction(response.message)))
                    )
            )
        );