import { IHazardTypeImage } from "../getHazardTypes/getHazardTypesConstants";

export enum IADD_HAZARD_TYPE_REQUEST {
    REQUEST = "addHazardType/ADD_HAZARD_TYPE_REQUEST",
    SUCCESS = "addHazardType/ADD_HAZARD_TYPE_SUCCESS",
    FAILED = "addHazardType/ADD_HAZARD_TYPE_FAILED"
};

export interface IAddHazardTypeRequest {
    token: string;
    request: IAddUpdateHazardType;
};

export interface IAddUpdateHazardType {
    ID: number;
    Name: string;
    Sort_Order: number;
    Active: string;
    Picture?: IHazardTypeImage;
    Created_By: string;
    Modified_By: string;
}
