import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, GreenOrRed, InOrOut, pageAccessCheck, SurveyDD, undefinedFunction, userName, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { IAddUpdateRepeaterKit, IAddUpdateRepeaterKitRequest } from "../../../redux/survey/addRepeaterKit/addRepeaterKitConstants";
import { addRepeaterKitLoadAction } from "../../../redux/survey/addRepeaterKit/addRepeaterKitActions";
import { updateRepeaterKitLoadAction } from "../../../redux/survey/updateRepeaterKit/updateRepeaterKitActions";
import LAAutoComplete from "../../shared/autoComplete";
import { IRepeaterKit, IRepeaterKitRequest } from "../../../redux/survey/getRepeaterKits/getRepeaterKitsConstants";
import { addRepeaterKits } from "../../../redux/survey/addRepeaterKit/addRepeaterKitAccessor";
import { getRepeaterKits } from "../../../redux/survey/getRepeaterKits/getRepeaterKitsAccessor";
import { updateRepeaterKits } from "../../../redux/survey/updateRepeaterKit/updateRepeaterKitAccessor";
import { getRepeaterKitLoadAction } from "../../../redux/survey/getRepeaterKits/getRepeaterKitsActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ISurveyLookup, ISurveyLookupRequest } from "../../../redux/survey/getSurveyLookup/getSurveyLookupConstants";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { GetSurveyLookup } from "../../../redux/survey/getSurveyLookup/getSurveyLookupAccessor";
import { getSurveyLookupLoadAction } from "../../../redux/survey/getSurveyLookup/getSurveyLookupActions";

const RequiredFields: string[] = ["kit_No", "unit_No", "model"];

interface IAddUpdateRepeaterKitComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    addRepeaterKitStatus: Server<SurewayAPIResponse<string>>;
    updateRepeaterKitStatus: Server<SurewayAPIResponse<string>>;
    getRepeaterKitsStatus: Server<SurewayAPIResponse<ById<IRepeaterKit>>>;
};

interface IAddUpdateRepeaterKitComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISurveyLookupRequest) => unknown;
    getRepeaterKitsRequest: (data: IRepeaterKitRequest) => unknown;
    addRepeaterKitRequest: (data: IAddUpdateRepeaterKitRequest) => unknown;
    updateRepeaterKitRequest: (data: IAddUpdateRepeaterKitRequest) => unknown;
};

interface IAddUpdateRepeaterKitOwnProps {
    id?: string;
};

interface IAddUpdateRepeaterKitComponentState {
    data: IRepeaterKit;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateRepeaterKitStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .green-border {
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateRepeaterKitComponentProps =
    RouteComponentProps
    & IAddUpdateRepeaterKitOwnProps
    & IAddUpdateRepeaterKitComponentStoreProps
    & IAddUpdateRepeaterKitComponentDispatchProps;

class AddUpdateRepeaterKit extends PureComponent<IAddUpdateRepeaterKitComponentProps, IAddUpdateRepeaterKitComponentState> {

    public constructor(props: IAddUpdateRepeaterKitComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                kit_No: "",
                unit_No: "",
                model: "",
                radio_Antenna_Parts: "",
                cables: "",
                // in_Out: "",
                status: "",
                // site: "",
                wsS_ID: 0,
                notes: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateRepeaterKitComponentProps): void {
        if (this.props !== prevProps) {

            if((this.props.getRepeaterKitsStatus !== prevProps.getRepeaterKitsStatus)
                || (this.props.token !== prevProps.token))
            this.setDataToState();

            if (this.props.addRepeaterKitStatus !== prevProps.addRepeaterKitStatus) {

                if (this.props.addRepeaterKitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRepeaterKitStatus.message });

                if (hasPayload(this.props.addRepeaterKitStatus) && this.props.addRepeaterKitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateRepeaterKitStatus !== prevProps.updateRepeaterKitStatus) {

                if (this.props.updateRepeaterKitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRepeaterKitStatus.message });

                if (hasPayload(this.props.updateRepeaterKitStatus) && this.props.updateRepeaterKitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " REPEATER KIT";
        const { addRepeaterKitStatus, updateRepeaterKitStatus, getLookup } = this.props;

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const onRadioParts = (event: unknown, value: IIdName): void => this.handleChange("radio_Antenna_Parts", value !== null ? value.name : data.radio_Antenna_Parts);
        const onCables = (event: unknown, value: IIdName): void => this.handleChange("cables", value !== null ? value.name : data.cables);
        // const onInOut = (event: unknown, value: IIdName): void => this.handleChange("in_Out", value !== null ? value.name : data.in_Out);
        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status ?? "");
        const onWSS = (value: string): void => this.handleNumber("wsS_ID", value);

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateRepeaterKitStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="mt-4">
                            <LATextField
                                label="Kit #"
                                fullWidth={true}
                                variant="outlined"
                                name="kit_No"
                                onChange={this.handleChange}
                                value={data.kit_No}
                                errorText={errors["kit_No"] ? errors["kit_No"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="green-border text-center mb-3">
                            <strong>Repeater</strong>
                            <LAGrid spacing={0}>

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Unit #"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="unit_No"
                                        onChange={this.handleChange}
                                        value={data.unit_No}
                                        errorText={errors["unit_No"] ? errors["unit_No"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6}>
                                    <LATextField
                                        label="Model"
                                        fullWidth={true}
                                        variant="outlined"
                                        name="model"
                                        onChange={this.handleChange}
                                        value={data.model}
                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="mt-4">
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onRadioParts}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Radio Antenna Parts"
                                selectionRemove={undefinedFunction}
                                value={data.radio_Antenna_Parts ? YesOrNo.find(q => q.name === data.radio_Antenna_Parts) : null}
                                defaultValue={data.radio_Antenna_Parts ? YesOrNo.find(q => q.name === data.radio_Antenna_Parts) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} className="mt-4">
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onCables}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Cables"
                                selectionRemove={undefinedFunction}
                                value={data.cables ? YesOrNo.find(q => q.name === data.cables) : null}
                                defaultValue={data.cables ? YesOrNo.find(q => q.name === data.cables) : null}
                            />
                        </LAGridItem>

                        {/* <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={InOrOut}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onInOut}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="IN/OUT"
                                selectionRemove={undefinedFunction}
                                value={data.in_Out ? InOrOut.find(q => q.name === data.in_Out) : null}
                                defaultValue={data.in_Out ? InOrOut.find(q => q.name === data.in_Out) : null}
                            />
                        </LAGridItem> */}

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={SurveyDD}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onStatus}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                value={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? SurveyDD.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            {/* <LATextField
                                label="Site"
                                fullWidth={true}
                                variant="outlined"
                                name="site"
                                onChange={this.handleChange}
                                value={data.site}
                                errorText={errors["site"] ? errors["site"].message : undefined}
                            /> */}
                            <DevExtremePopupLookup
                                data={wssList ?? []}
                                id="wss-search"
                                placeHolder="Site"
                                disabled={undefined}
                                displayExp="site"
                                returnExp="wsS_ID"
                                name="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                currentVal={data.wsS_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addRepeaterKitStatus.kind} successMessage="Repeater Kit has been added successfully" />
                    <RequestStatus requestStatus={updateRepeaterKitStatus.kind} successMessage="Repeater Kit has been updated successfully" />

                </AddUpdateRepeaterKitStyles>
            </PageSpacing>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.REPEATER_KITS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const request: IAddUpdateRepeaterKit = {
            ID: data.id,
            Kit_No: data.kit_No,
            Unit_No: data.unit_No,
            Model: data.model,
            Radio_Antenna_Parts: data.radio_Antenna_Parts,
            Cables: data.cables,
            // In_Out: data.in_Out,
            Status: data.status,
            // Site: data.site,
            WSS_ID: data.wsS_ID && data.wsS_ID > 0 ? data.wsS_ID : null,
            Notes: data.notes,
            Created_By: data.created_By,
            Modified_By: data.modified_By
        };

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addRepeaterKitRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            }
            else {
                request.Modified_By = this.props.token.payload.response.upn;

                this.props.updateRepeaterKitRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private handleNumber = (name: string, value: string): void => {
        if (value !== undefined) {
            // console.log(value)
            let errors = { ...this.state.errors };

            if (RequiredFields.includes(name))
                errors = this.errorChecker(name, value.toString(), errors);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getRepeaterKitsStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getRepeaterKitsStatus.payload.response[query.id];

                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getRepeaterKitsRequest({
                            token
                        });
                    }
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Survey_Pages"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateRepeaterKitComponentStoreProps => ({
    token: getToken(state),
    getLookup: GetSurveyLookup(state),
    addRepeaterKitStatus: addRepeaterKits(state),
    getRepeaterKitsStatus: getRepeaterKits(state),
    updateRepeaterKitStatus: updateRepeaterKits(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateRepeaterKitComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISurveyLookupRequest) => dispatch(getSurveyLookupLoadAction(data)),
    getRepeaterKitsRequest: (data: IRepeaterKitRequest) => dispatch(getRepeaterKitLoadAction(data)),
    addRepeaterKitRequest: (data: IAddUpdateRepeaterKitRequest) => dispatch(addRepeaterKitLoadAction(data)),
    updateRepeaterKitRequest: (data: IAddUpdateRepeaterKitRequest) => dispatch(updateRepeaterKitLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateRepeaterKit);