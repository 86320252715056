import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUPDATE_MACHINE_RECEIVER_REQUEST } from "./updateMachineReceiverConstants";
import { IUpdateMachineReceiverLoadAction, IUpdateMachineReceiverLoadFailedAction, IUpdateMachineReceiverSuccessAction } from "./updateMachineReceiverActions";

type Actions =
    | IUpdateMachineReceiverLoadAction
    | IUpdateMachineReceiverSuccessAction
    | IUpdateMachineReceiverLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateMachineReceiverReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_MACHINE_RECEIVER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_MACHINE_RECEIVER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IUPDATE_MACHINE_RECEIVER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};