import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById,  IIdName,  SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString, deleteText, getTokenFromUrl, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon, DeleteIcon, DownloadIcon } from "../../../shared/icons";
import LAAutoComplete from "../../../shared/autoComplete";
import { LADropZone } from "../../../shared/dropZone";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { IFieldLookup, IFieldLookupRequest, IModel, IUnit } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { IEquipmentManual, IEquipmentManualsFile, IGetEquipmentManualsRequest } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsConstants";
import { IAddEquipmentManual, IAddEquipmentManualRequest } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualConstants";
import { IUpdateEquipmentManualRequest } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualConstants";
import { getEquipmentManuals } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsAccessor";
import { addEquipmentManualStatus } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualAccessor";
import { updateEquipmentManualStatus } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualAccessor";
import { getEquipmentManualsLoadAction } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsActions";
import { addEquipmentManualLoadAction } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualActions";
import { updateEquipmentManualLoadAction } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualActions";
import { FormHelperText } from "@material-ui/core";

const RequiredFields: string[] = ["category", "files"];

const CategoriesOptions: IIdName[] = [
    {id: 1, name: "Parts"},
    {id: 2, name: "Operation and Maintenance"},
    {id: 3, name: "Service Manual"},
    {id: 4, name: "Field Assembly Manual"},
]

interface IAddUpdateEquipmentManualComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addEquipmentManualStatus: Server<SurewayAPIResponse<string>>;
    getEquipmentManuals: Server<SurewayAPIResponse<ById<IEquipmentManual>>>;
    updateEquipmentManualStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateEquipmentManualComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getEquipmentManualsRequest: (data: IGetEquipmentManualsRequest) => unknown
    addEquipmentManualRequest: (data: IAddEquipmentManualRequest) => unknown;
    updateEquipmentManualRequest: (data: IUpdateEquipmentManualRequest) => unknown;
};

interface IAddUpdateEquipmentManualOwnProps {
    id?: string;
};

interface IAddUpdateEquipmentManualComponentState {
    data: IEquipmentManual;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateEquipmentManualStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateEquipmentManualComponentProps =
    RouteComponentProps
    & IAddUpdateEquipmentManualOwnProps
    & IAddUpdateEquipmentManualComponentStoreProps
    & IAddUpdateEquipmentManualComponentDispatchProps;

class AddUpdateEquipmentManual extends PureComponent<IAddUpdateEquipmentManualComponentProps, IAddUpdateEquipmentManualComponentState> {

    public constructor(props: IAddUpdateEquipmentManualComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                units: "",
                make_ID: 0,
                models: "",
                category: "",
                file_Name: "",
                files: [],
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateEquipmentManualComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addEquipmentManualStatus !== prevProps.addEquipmentManualStatus) {

                if (this.props.addEquipmentManualStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addEquipmentManualStatus.message });

                if (hasPayload(this.props.addEquipmentManualStatus) && this.props.addEquipmentManualStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateEquipmentManualStatus !== prevProps.updateEquipmentManualStatus) {

                if (this.props.updateEquipmentManualStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateEquipmentManualStatus.message });

                if (hasPayload(this.props.updateEquipmentManualStatus) && this.props.updateEquipmentManualStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = data.id > 0 ? "Update Equipment Manual" : "Add Equipment Manual";
        const { token, addEquipmentManualStatus, updateEquipmentManualStatus, getLookup } = this.props;
        // console.log(data)

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        // const models = hasPayload(getLookup) ? getLookup.payload.response.models : [];
        const makes = hasPayload(getLookup) ? getLookup.payload.response.manufacturers : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onMake = (e: any ,value: any): void =>  this.handleChange("make_ID", value ? value.id : "");
        const onCatogoryChange = (e: any ,value: any): void =>  this.handleChange("category", value ? value.name : "");

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateEquipmentManualStyles>
                    {/* {(getRole !== NotApplicable) && */}
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Equipment Manual</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LABluePaper>
                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6} md={3}>
                                            <DevExtremePopupLookup
                                                data={units}
                                                multiple={true}
                                                id="unit-search"
                                                placeHolder="Unit Number"
                                                disabled={undefined}
                                                displayExp="unit_Number"
                                                name="unit_Number"
                                                errorName="unit_Number"
                                                onSelectionRemove={this.handleUnitNumberRemove}
                                                errors={errors}
                                                currentVal={this.getUnitNumbers(data.units ?? "")}
                                                onClick={this.handleUnitNumberClick}
                                                columns={[
                                                    { name: "unit_Number", caption: "Unit Number", type: "string" },
                                                    { name: "make", caption: "Make", type: "string" },
                                                    { name: "model", caption: "Model", type: "string" },
                                                    { name: "location_Details", caption: "Location", type: "string" },
                                                ]}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={1} className="text-center">
                                            <br />
                                            <strong className="red-text">OR</strong>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={3}>
                                            <LAAutoComplete
                                                multiple={false}
                                                option={makes}
                                                getOptionLabel="manufacturer_Name"
                                                autoHighlight={true}
                                                onChange={onMake}
                                                disabled={undefined}
                                                filterSelectedOptions={true}
                                                dropDownPlaceHolder="Make"
                                                selectionRemove={undefinedFunction}
                                                errorText={errors["make_ID"] ? errors["make_ID"].message : undefined}
                                                value={data.make_ID ? makes?.find(q => q.id === data.make_ID) : null}
                                                defaultValue={data.make_ID ? makes?.find(q => q.id === data.make_ID) : null}
                                            />
                                        </LAGridItem>
                                        
                                        <LAGridItem xs={12} sm={6} md={3}>
                                            {/* <LAAutoComplete
                                                multiple={false}
                                                option={models}
                                                getOptionLabel="model"
                                                autoHighlight={true}
                                                onChange={onModel}
                                                disabled={undefined}
                                                filterSelectedOptions={true}
                                                dropDownPlaceHolder="Model"
                                                selectionRemove={undefinedFunction}
                                                errorText={errors["model"] ? errors["model"].message : undefined}
                                                value={data.models ? models?.find(q => q.model === data.models) : null}
                                                defaultValue={data.models ? models?.find(q => q.model === data.models) : null}
                                            /> */}
                                            <LAAutoComplete
                                                disabled={data.make_ID === 0 || data.make_ID === null}
                                                option={this.filteredModelsOptions()}
                                                multiple={true}
                                                getOptionLabel="model"
                                                autoHighlight={true}
                                                onChange={this.handleSelectModels}
                                                filterSelectedOptions={true}
                                                dropDownPlaceHolder="Models"
                                                selectionRemove={this.handleModelRemove}
                                                value={this.getSelectedModels(data.models)}
                                                defaultValue={this.getSelectedModels(data.models)}
                                                errorText={errors["models"] ? errors["models"].message : undefined}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                </LABluePaper>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={CategoriesOptions}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onCatogoryChange}
                                    disabled={undefined}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Category"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["category"] ? errors["category"].message : undefined}
                                    value={data.category ? CategoriesOptions?.find(q => q.name === data.category) : null}
                                    defaultValue={data.category ? CategoriesOptions?.find(q => q.name === data.category) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGreenPaper>
                                    <strong>ATTACHMENTS</strong>
                                    <hr />

                                    <LADropZone onFileDrop={this.fileUpload} />
                                    <br />
                                    {errors["files"] && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}

                                    <LAGrid>
                                        {(data.files !== null) && data.files
                                            .map((q:any, ind:any) => {
                                                const onFileDelete = (): void => this.onFileDelete(ind);
                                                const onDownload = (): void => this.handleDownload(q);

                                                return (
                                                    <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                        <LAPaperWithLessPadding>
                                                            <span>{q.name}</span>
                                                            <br />
                                                            <LAIconButton
                                                                name="file"
                                                                label={q.name}
                                                                id={(ind).toString()}
                                                                onClick={onDownload}
                                                                icon={<DownloadIcon />}
                                                            />

                                                            <LAIconButton
                                                                key={ind}
                                                                label="Delete"
                                                                icon={<DeleteIcon />}
                                                                onClick={onFileDelete}
                                                            />
                                                        </LAPaperWithLessPadding>
                                                    </LAGridItem>
                                                )
                                            })}
                                    </LAGrid>
                                </LAGreenPaper>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    {/* } */}

                    <RequestStatus requestStatus={addEquipmentManualStatus.kind} successMessage="Equipment Manual has been added successfully" />
                    <RequestStatus requestStatus={updateEquipmentManualStatus.kind} successMessage="Equipment Manual has been updated successfully" />

                </AddUpdateEquipmentManualStyles>
            </PageSpacing>
        );
    }

    private getUnitNumbers = (ids: string): IUnit[] => {
        if (ids) {
            const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
            const response: IUnit[] = [];
            const assignedUnitIDs = ids !== null ? ids.split(",").map(String) : [];

            assignedUnitIDs.forEach((x) => {
                const index = units.findIndex(q => q.id.toString() === x);
                if (index >= 0)
                    response.push(units[index]);
            });

            return response;
        }
        return [];
    };

    private handleUnitNumberClick = (value: IUnit): void => {
        // console.log(value)
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        if(value) {

            let unitIDs = this.state.data.units;
            const units_Array: string[] = unitIDs && unitIDs !== null ? unitIDs.split(",").map(String) : [];
        
            const index = units_Array.length > 0 ? units_Array.findIndex((e) => e === value.id.toString()) : -1;
            if (index < 0) {
                units_Array.push(value.id.toString());
            }
    
            unitIDs = units_Array.join(",");

            this.setState({
                ...this.state,
                data: {
                    ...data,
                    units: unitIDs
                },
                errors
            });
            // this.handleChange("unit_IDs", unitIDs);
        }
       
    }

    private handleUnitNumberRemove = (value: string): void => {
        // console.log(value)
        let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];

        let unitIDs = data.units;
        const units_Array: string[] = unitIDs && unitIDs !== null ? unitIDs.split(",").map(String) : [];
        const unit = units ? units.find(q => q.unit_Number === value) : null;

        if(unit) {
            const index = units_Array.length > 0 ? units_Array.findIndex((x) => x === unit?.id.toString()) : -1;
            if (index >= 0) {
                units_Array.splice(index, 1);
            }
        }
        
        unitIDs = units_Array.join(",");

        this.setState({
            ...this.state,
            data: {
                ...data,
                units: unitIDs
            },
            errors
        });
    }

    private getSelectedModels = (models: string): IModel[] => {
        if (hasPayload(this.props.getLookup) && models) {

            const response: IModel[] = [];
            const selectedModels = models !== null ? models.split(",").map(String) : [];
            const modelOptions: IModel[] = this.props.getLookup.payload.response.models;

            selectedModels.forEach((x) => {
                const index = modelOptions.findIndex(q => q.model === x);
                if (index >= 0)
                    response.push(modelOptions[index]);
            });

            return response;
        }
        return [];
    };

    private filteredModelsOptions = (): IModel[] => {
        const {data} = this.state;
        const { getLookup } = this.props;

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const modelOptions: IModel[] = hasPayload(getLookup) ? getLookup.payload.response.models : [];
        // const makes = hasPayload(getLookup) ? getLookup.payload.response.manufacturers : [];

        if(data.make_ID > 0) {
            // const make = makes.find(x => x.id === data.make_ID);
            const filteredUnits = units.filter(x => x.make_ID === data.make_ID);
            const filteredModels: IModel[] = [];
            filteredUnits.forEach(x => {
                const model = modelOptions.find(y => y.model === x.model);
                if(model) {
                    if(!filteredModels.includes(model))
                        filteredModels.push(model)
                }
            })
            return filteredModels
        }
        return modelOptions;
    }

    private handleSelectModels = async(e: any, values: IModel[]): Promise<void> => {
        // console.log(values)
        const data = { ...this.state.data };
        const selectedModelsArray: string[] = data.models && data.models !== null ? data.models.split(",").map(String) : [];

        await values.forEach((q:IModel) => {
            const index = selectedModelsArray.length > 0 ? selectedModelsArray.findIndex((x) => x === q.model) : -1;
            if (index < 0) {
                selectedModelsArray.push(q.model);
            }
        });

        data.models = selectedModelsArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                models: data.models,
            },
        });
    }

    private handleModelRemove = async(value: any): Promise<void> => {
        // console.log(value)
        const data = { ...this.state.data };
        const selectedModelsArray: string[] = data.models && data.models !== null ? data.models.split(",").map(String) : [];

        const index = selectedModelsArray.length > 0 ? selectedModelsArray.findIndex((x) => x === value) : -1;
        if (index >= 0) {
            selectedModelsArray.splice(index, 1);
        }
        
        data.models = selectedModelsArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                models: data.models,
            },
        });
    }
    // private handleUnit = (value?: string): void => {
    //     const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
    //     const unit = units.find((x: any) => x.unit_Number === value);
    //     // console.log(value)
    //     let errors = { ...this.state.errors };
    //     if (value === undefined || value === "") {
    //         errors = this.errorChecker("unit_Number", "", errors);
    //         errors = this.errorChecker("make", "", errors);
    //         errors = this.errorChecker("model", "", errors);
    //     }
    //     else {
    //         errors = this.errorChecker("unit_Number", value.toString(), errors);
    //         if(unit) {
    //             delete errors["make"];
    //             delete errors["model"]; 
    //         }
    //     }

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             unit_ID: unit?.id ?? 0,
    //             make: unit?.make ?? "",
    //             model: unit?.model ?? ""
    //         },
    //         errors
    //     });
    // };

    private fileUpload = (attachments: Array<File | DataTransferItem>): void => {

        const files = this.state.data.files ? this.state.data.files : [];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;
                Object.values(attachments).forEach((x: any, index) => {
                    // console.log(x)
                    if(x.type.includes("image")) {
                        alert("Please select only an attachment")
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                files.map((att: any, i: number) => {x.name.substring(0, x.name.lastIndexOf("."))
                                    // console.log(img.description, description)
                                    if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                        isDuplicateName = true;
                                    }
                                    return isDuplicateName
                                })
                                isDuplicateName ?
                                    files.push({
                                        name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                        base64String: reader.result.toString()
                                    }) : 
                                    files.push({
                                        name:  x.name,
                                        base64String: reader.result.toString()
                                    });
                                // files.push({
                                //     name: x.name,
                                //     base64String: reader.result.toString()
                                // });
    
                                if (attachments.length === index + 1) {
                                    resolve(true);
                                };
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        }).then(() => {
            let errors = { ...this.state.errors };
            const files_Names: string[] = [];
            files.forEach((q:any) => {
                files_Names.push(q.name);
            });

            if(files.length > 0) {
                delete errors["files"]
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files,
                    file_Name: files_Names.join(';'),
                },
                errors
            });
        });
    };

    private onFileDelete = (fileIdx: number): void => {
        const selection = window.confirm(deleteText);
        let errors = { ...this.state.errors };

        if (selection) {
            const files = [...this.state.data.files];
            const files_Names: string[] = [];

            files.splice(fileIdx, 1);

            files.forEach((q:any) => {
                files_Names.push(q.name);
            });

            if(files.length > 0) {
                delete errors["files"]
            } else {
                errors = this.errorChecker("files", files.length > 0 ? files.length.toString() : "", errors)
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files,
                    file_Name: files_Names.join(';'),
                },
                errors
            });
        }
    };

    private handleDownload = (q: IEquipmentManualsFile): void => {
        const data = { ...this.state.data}
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            window.open(`https://apps.sureway.ca/Equipment_Manuals/${data.id}/${q.name}`)
            // this.downloadFromEndpoint(q.name);
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            if(data.units.length > 0 || (data.make_ID > 0 && data.models)) {

                let request: IAddEquipmentManual = {
                    ...data,
                    created_By: this.props.token.payload.response.upn,
                    modified_By: this.props.token.payload.response.upn,
                };
    
                // console.log(request)
                if (data.id === 0) {
                    this.props.addEquipmentManualRequest({
                        token: this.props.token.payload.response.token,
                        request
                    });
                } 
                else {
                    this.props.updateEquipmentManualRequest({
                        token: this.props.token.payload.response.token,
                        request
                    });
                };
    
                this.setState({ serverError: "" });
            }
            else {
                alert("Please select Units or select make and models");
            }
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "make_ID") {
            if(value === undefined || value === "" || value) {
                data.models = "";
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: name === "make_ID" ? Number(value) : value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                // if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (hasPayload(this.props.getEquipmentManuals)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getEquipmentManuals.payload.response[+query.id];
                                const existingAtt = data.file_Name ? data.file_Name.split(";") : [];
                                const files: IEquipmentManualsFile[] = [];

                                existingAtt.forEach(x => {
                                    files.push({ name: x, base64String: "" });
                                });
                                
                                this.setState({
                                    data : {
                                        ...data,
                                        files: files
                                    }
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getEquipmentManualsRequest({
                            token: this.props.token.payload.response.token,
                            request: {
                                unit_id: 0
                            }
                        });
                    }

                // } 
                // else {

                //     this.props.history.push({
                //         pathname: ROUTE.ACCESS_DENIED,
                //         search: getTokenFromUrl(false)
                //     });
                // };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Equipment_Manuals"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateEquipmentManualComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getEquipmentManuals: getEquipmentManuals(state),
    addEquipmentManualStatus: addEquipmentManualStatus(state),
    updateEquipmentManualStatus: updateEquipmentManualStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateEquipmentManualComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getEquipmentManualsRequest: (data: IGetEquipmentManualsRequest) => dispatch(getEquipmentManualsLoadAction(data)),
    addEquipmentManualRequest: (data: IAddEquipmentManualRequest) => dispatch(addEquipmentManualLoadAction(data)),
    updateEquipmentManualRequest: (data: IUpdateEquipmentManualRequest) => dispatch(updateEquipmentManualLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateEquipmentManual);