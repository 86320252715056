import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENTS_REQUEST, IAttachment, IGetAttachmentRequest } from "./getAttachmentsConstants";

export interface IGetAttachmentsLoadAction {
    type: IGET_ATTACHMENTS_REQUEST.REQUEST;
    data: IGetAttachmentRequest
}
export const getAttachmentsLoadAction = (data: IGetAttachmentRequest): IGetAttachmentsLoadAction => ({
    type: IGET_ATTACHMENTS_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentsSuccessAction {
    type: IGET_ATTACHMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IAttachment>>;
}
export const getAttachmentsLoadSuccessAction = (list: SurewayAPIResponse<ById<IAttachment>>): IGetAttachmentsSuccessAction => ({
    type: IGET_ATTACHMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentsLoadFailedAction {
    type: IGET_ATTACHMENTS_REQUEST.FAILED;
    message: string;
}
export const getAttachmentsLoadFailedAction = (message: string): IGetAttachmentsLoadFailedAction => ({
    type: IGET_ATTACHMENTS_REQUEST.FAILED,
    message
});
