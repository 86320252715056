import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST, IAssignEquipmentTypeColumnRequest } from "./assignEquipmentTypeColumnConstants";


export interface IAssignEquipmentTypeColumnLoadAction {
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.REQUEST;
    data: IAssignEquipmentTypeColumnRequest
}
export const assignEquipmentTypeColumnLoadAction = (data: IAssignEquipmentTypeColumnRequest): IAssignEquipmentTypeColumnLoadAction => ({
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.REQUEST,
    data
});
export interface IAssignEquipmentTypeColumnSuccessAction {
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const assignEquipmentTypeColumnLoadSuccessAction = (message: SurewayAPIResponse<string>): IAssignEquipmentTypeColumnSuccessAction => ({
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS,
    message
});
export interface IAssignEquipmentTypeColumnLoadFailedAction {
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.FAILED;
    message: string;
}
export const assignEquipmentTypeColumnLoadFailedAction = (message: string): IAssignEquipmentTypeColumnLoadFailedAction => ({
    type: IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.FAILED,
    message
});
