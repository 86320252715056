import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateLevelRequest } from "../addLevel/addLevelConstants";
import { IUPDATE_LEVEL_REQUEST } from "./updateLevelConstants";

export interface IUpdateLevelLoadAction {
    type: IUPDATE_LEVEL_REQUEST.REQUEST;
    data: IAddUpdateLevelRequest;
}
export const updateLevelLoadAction = (data: IAddUpdateLevelRequest): IUpdateLevelLoadAction => ({
    type: IUPDATE_LEVEL_REQUEST.REQUEST,
    data
});
export interface IUpdateLevelSuccessAction {
    type: IUPDATE_LEVEL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateLevelLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateLevelSuccessAction => ({
    type: IUPDATE_LEVEL_REQUEST.SUCCESS,
    list
});
export interface IUpdateLevelLoadFailedAction {
    type: IUPDATE_LEVEL_REQUEST.FAILED;
    message: string;
}
export const updateLevelLoadFailedAction = (message: string): IUpdateLevelLoadFailedAction => ({
    type: IUPDATE_LEVEL_REQUEST.FAILED,
    message
});
