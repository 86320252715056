import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_MACHINE_CONTROL_BOX_REQUEST } from "./updateMachineControlBoxConstants";
import {
    IUpdateMachineControlBoxLoadAction, IUpdateMachineControlBoxLoadFailedAction, IUpdateMachineControlBoxSuccessAction,
    updateMachineControlBoxLoadFailedAction, updateMachineControlBoxLoadSuccessAction
} from "./updateMachineControlBoxActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateMachineControlBoxEpic: Epic = (
    action$: ActionsObservable<IUpdateMachineControlBoxLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateMachineControlBoxSuccessAction | IUpdateMachineControlBoxLoadFailedAction> =>
    action$.ofType(IUPDATE_MACHINE_CONTROL_BOX_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_MACHINE_BOX,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateMachineControlBoxSuccessAction | IUpdateMachineControlBoxLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateMachineControlBoxLoadSuccessAction(response);
                            } else {
                                return updateMachineControlBoxLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateMachineControlBoxLoadFailedAction(response.message)))
                    )
            )
        );