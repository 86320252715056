import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_LIST_GET_REQUEST, IAddAttachmentListGETRequest } from "./addAttachmentListGETConstants";


export interface IAddAttachmentListGETLoadAction {
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.REQUEST;
    data: IAddAttachmentListGETRequest
}
export const addAttachmentListGETLoadAction = (data: IAddAttachmentListGETRequest): IAddAttachmentListGETLoadAction => ({
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentListGETSuccessAction {
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentListGETLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentListGETSuccessAction => ({
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentListGETLoadFailedAction {
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.FAILED;
    message: string;
}
export const addAttachmentListGETLoadFailedAction = (message: string): IAddAttachmentListGETLoadFailedAction => ({
    type: IADD_ATTACHMENT_LIST_GET_REQUEST.FAILED,
    message
});
