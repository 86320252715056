import { IAddUpdateSwamper } from "../addSwamper/addSwamperConstants";

export enum IUPDATE_SWAMPER_REQUEST {
    REQUEST = "updateSwamper/UPDATE_SWAMPER_REQUEST",
    SUCCESS = "updateSwamper/UPDATE_SWAMPER_SUCCESS",
    FAILED = "updateSwamper/UPDATE_SWAMPER_FAILED"
};

export interface IUpdateSwamperRequest {
    token: string;
    request: IAddUpdateSwamper;
};