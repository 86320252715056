import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_MAPPING_VIEW_REQUEST, IMappingView } from "./getMappingViewConstants";
import { IGetMappingViewLoadAction, IGetMappingViewLoadFailedAction, IGetMappingViewSuccessAction } from "./getMappingViewActions";
import { IINITIATE_MOVE_REQUEST } from "../initiateMove/initiateMoveConstants";
import { IInitiateMoveSuccessAction } from "../initiateMove/initiateMoveActions";

type Actions =
    | IGetMappingViewLoadAction
    | IGetMappingViewSuccessAction
    | IInitiateMoveSuccessAction
    | IGetMappingViewLoadFailedAction
    | IFlushDataSuccessAction;

export const GetMappingViewReducer = (state: Server<IMappingView[]> = notLoaded, action: Actions): Server<IMappingView[]> => {
    switch (action.type) {
        case IGET_MAPPING_VIEW_REQUEST.REQUEST:
            return loading;

        case IGET_MAPPING_VIEW_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MAPPING_VIEW_REQUEST.FAILED:
            return failed(action.message);

            case IINITIATE_MOVE_REQUEST.SUCCESS:
                return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};