import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_REPEATER_KIT_REQUEST, IAddUpdateRepeaterKitRequest } from "./addRepeaterKitConstants";

export interface IAddRepeaterKitLoadAction {
    type: IADD_REPEATER_KIT_REQUEST.REQUEST;
    data: IAddUpdateRepeaterKitRequest
}
export const addRepeaterKitLoadAction = (data: IAddUpdateRepeaterKitRequest): IAddRepeaterKitLoadAction => ({
    type: IADD_REPEATER_KIT_REQUEST.REQUEST,
    data
});
export interface IAddRepeaterKitSuccessAction {
    type: IADD_REPEATER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addRepeaterKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddRepeaterKitSuccessAction => ({
    type: IADD_REPEATER_KIT_REQUEST.SUCCESS,
    list
});
export interface IAddRepeaterKitLoadFailedAction {
    type: IADD_REPEATER_KIT_REQUEST.FAILED;
    message: string;
}
export const addRepeaterKitLoadFailedAction = (message: string): IAddRepeaterKitLoadFailedAction => ({
    type: IADD_REPEATER_KIT_REQUEST.FAILED,
    message
});
