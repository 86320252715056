
export enum IGET_SOCT_REPORT_REQUEST {
    REQUEST = "getSoctReport/GET_SOCT_REPORT_REQUEST",
    SUCCESS = "getSoctReport/GET_SOCT_REPORT_SUCCESS",
    FAILED = "getSoctReport/GET_SOCT_REPORT_FAILED"
};

export interface ISoctReportRequest {
    token: string;
};


export interface ISoctReport {
    id: number;
    unit_ID: number;
    unit_No: string;
    sub_Location_ID: number;
    location: string;
    report: string;
};
