import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_DUES_REQUEST, IServiceDue, IGetServiceDueRequest } from "./getServiceDuesConstants";

export interface IGetServiceDuesLoadAction {
    type: IGET_SERVICE_DUES_REQUEST.REQUEST;
    data: IGetServiceDueRequest
}
export const getServiceDuesLoadAction = (data: IGetServiceDueRequest): IGetServiceDuesLoadAction => ({
    type: IGET_SERVICE_DUES_REQUEST.REQUEST,
    data
});
export interface IGetServiceDuesSuccessAction {
    type: IGET_SERVICE_DUES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IServiceDue>>;
}
export const getServiceDuesLoadSuccessAction = (list: SurewayAPIResponse<ById<IServiceDue>>): IGetServiceDuesSuccessAction => ({
    type: IGET_SERVICE_DUES_REQUEST.SUCCESS,
    list
});
export interface IGetServiceDuesLoadFailedAction {
    type: IGET_SERVICE_DUES_REQUEST.FAILED;
    message: string;
}
export const getServiceDuesLoadFailedAction = (message: string): IGetServiceDuesLoadFailedAction => ({
    type: IGET_SERVICE_DUES_REQUEST.FAILED,
    message
});
