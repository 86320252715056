import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_FILTER_PART_REQUEST } from "./updateFilterPartConstants";
import { IUpdateFilterPartLoadAction, IUpdateFilterPartLoadFailedAction, IUpdateFilterPartSuccessAction, updateFilterPartLoadFailedAction, updateFilterPartLoadSuccessAction } from "./updateFilterPartActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateFilterPartEpic: Epic = (
    action$: ActionsObservable<IUpdateFilterPartLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateFilterPartSuccessAction | IUpdateFilterPartLoadFailedAction> =>
    action$.ofType(IUPDATE_FILTER_PART_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.UPDATE_FILTER_PART,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateFilterPartSuccessAction | IUpdateFilterPartLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateFilterPartLoadSuccessAction(response);
                            } else {
                                return updateFilterPartLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateFilterPartLoadFailedAction(response.message)))
                    )
            )
        );