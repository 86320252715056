import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_TECH_SERVICES_REQUEST, ITechService } from "./getTechServicesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetTechServicesLoadAction, IGetTechServicesLoadFailedAction, IGetTechServicesSuccessAction } from "./getTechServicesActions";
import { IAddTechServiceSuccessAction } from "../addTechService/addTechServiceActions";
import { IUpdateTechServiceSuccessAction } from "../updateTechService/updateTechServiceActions";
import { IADD_TECH_SERVICE_REQUEST } from "../addTechService/addTechServiceConstants";
import { IUPDATE_TECH_SERVICE_REQUEST } from "../updateTechService/updateTechServiceConstants";

type Actions =
    | IGetTechServicesLoadAction
    | IGetTechServicesSuccessAction
    | IAddTechServiceSuccessAction
    | IUpdateTechServiceSuccessAction
    | IGetTechServicesLoadFailedAction
    | IFlushDataSuccessAction;

export const GetTechServicesReducer = (state: Server<SurewayAPIResponse<ById<ITechService>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ITechService>>> => {
    switch (action.type) {
        case IGET_TECH_SERVICES_REQUEST.REQUEST:
            return loading;

        case IGET_TECH_SERVICES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_TECH_SERVICES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_TECH_SERVICE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_TECH_SERVICE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};