import { Grid, Button, Box, FormLabel, FormGroup, FormControlLabel, Checkbox, FormControl} from "@mui/material";
  import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import {getEquipmentList, getETUserRole} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {MobileBoxStyle} from '../../../../shared/styles';
import { currentUser } from "../../equipmenttrackerhome";
import { handleTokenAccess } from "../../../../shared/etTokenAccessWrapper";
import AddNewEquipmentMoveForm from "./equipmentMoveRequestAddNew";
import CustomEquipmentMoveForm from "./equipmentMoveCustom";

interface IProps{
    closeEquipmentMoveForm:any;
    refreshGrid:any;
    isFromRequest:boolean;
}
interface IState{
    location_options:any[];
    units:any[];
    showDatagrid:boolean;
    loaded:boolean;
    freezeSave:boolean;
    showAddNewForm:boolean;
    showCustomMoveForm:boolean;
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    //top: '',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    width: '96%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
  }

  var move_request:any = {unit_number:'', from_location:{site_Name:'',working_Site_ID:0, sub_Site_Name:'', sub_Site_ID:0}, 
  to_location:{site_Name:'',id:0, sub_Site_Name:'', sub_Site_ID:0}, completed_date:'', comments:'', send_email:false, moved_item:{}, from_Sub_Site_Name:"", to_Sub_Site_Name:"",
completed:false, drive_over:false, moved_previously:false, equipment_type:'', make:'', model:'', is_from_app:false};
  var unit_numbers:string[] = [];
  var to_location_list:string[] = [];
  var equipment_info:string[] = [];
  var working_site_locations:any[] = [];
  var grid_xs_width:number = 4;
  var move_type_is_custom = false;
  var disableEdit:any = false;

export default class AddEquipmentMoveForm extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], units:[], showDatagrid:false, loaded:false, freezeSave:false, showAddNewForm:false, showCustomMoveForm:false};
        this.handleChange = this.handleChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.closeMoveRequest = this.closeMoveRequest.bind(this);
    }
    closeMoveRequest(){
        this.setState({showAddNewForm:false, showCustomMoveForm:false});
        window.location.replace("/field/equipmenttracker/equipmentmoves");
    }
    refreshGrid(){

    }
    closePopup(){
        //this.props.closeEquipmentMoveForm();
        move_request = {unit_number:'', from_location:{site_Name:'',working_Site_ID:0, sub_site_name:''}, to_location:{site_Name:'',id:0}, completed_date:'', comments:'', 
        send_email:false, moved_item:{},moved_previously:false,equipment_type:'', make:'', model:'', is_from_app:false};
        window.location.replace("/field/equipmenttracker/equipmentmoves");
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'move_type_is_custom':
                move_type_is_custom = !move_type_is_custom;
                console.log(move_type_is_custom)
                if(move_type_is_custom){
                    this.setState({showCustomMoveForm:true,showAddNewForm:false});
                }
                else{
                    this.setState({showAddNewForm:true,showCustomMoveForm:false});
                }
                break;
        }
        this.setState({});
    }

    async componentDidMount(){
        // commit
        if(window.innerWidth <= 760){
            grid_xs_width = 9;
        }
        else{
            grid_xs_width = 4;
        }
        to_location_list = [];
        working_site_locations = [];
        var res:any = await getEquipmentList();
        res.map((s:any) => {
            unit_numbers.push(s.unit_Number);
        });
        equipment_info = res;

        var res:any = await getETUserRole(currentUser);
        if(res.isAuthenticated && !window.location.href.includes("token")){
          localStorage.setItem("ET-Role",res.role);
          if(res.role !='RW' && res.role!="R"){
            window.location.replace('/');
          }
        }else{
          let upn = await handleTokenAccess();
          if(upn != null){
            var etuserapp:any = await getETUserRole(upn);
            localStorage.setItem("ET-Role",etuserapp.role);
          }
          else{
            window.location.replace('/');
          }
        }

        disableEdit = localStorage.getItem("ET-Role") === "R" || window.location.href.includes("?token");
        if(disableEdit){
            move_request.is_from_app = true;
        }
        this.setState({units:res, loaded:true, showAddNewForm:true});
    }
    render(){
        if(this.state.loaded){
        return (
            <Box sx={window.innerWidth <= 1056 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Custom Move?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox onChange={this.handleChange} name="move_type_is_custom"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                    </Grid>
                </Grid>
                {this.state.showAddNewForm ? <AddNewEquipmentMoveForm isFromRequest={disableEdit} refreshGrid={this.refreshGrid.bind(this)}  closeEquipmentMoveForm={this.closeMoveRequest}></AddNewEquipmentMoveForm>:null}
                {this.state.showCustomMoveForm? <CustomEquipmentMoveForm refreshGrid={this.refreshGrid.bind(this)} clickedLocation={"NA"} clickedItem={{}} locationOptions={["NA","NA"]} closeEquipmentMoveForm={this.closeMoveRequest}></CustomEquipmentMoveForm>:null}
            
                    </Box>

        );
        }else{
            return null;
        }
    }
}