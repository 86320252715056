import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST {
    REQUEST = "saveManagerCommentsForDefectItem/SAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST",
    SUCCESS = "saveManagerCommentsForDefectItem/SAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_SUCCESS",
    FAILED = "saveManagerCommentsForDefectItem/SAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_FAILED"
};

export interface ISaveManagerCommentsForDefectItemRequest extends ISurewayTokenRequestBody {
    request: ISaveManagerCommentsForDefectItem;
};

export interface ISaveManagerCommentsForDefectItem {
    ID: number;
    Defect_ID?: number;
    Component_Code?: string;
    Manager_Instructions?: string;
    Defect_Status?: string;
    Billable?: string,
    Billable_Percentage?: number | null,
    Job_No?: string;
    Cost_Code?: string;
    Modified_By: string;
    Major_Component_Replacement?: string;
    Major_Type?: string,
    Component_Repaired_Location?: string,
    OEM_Part?: string,
    Exchange_Type?: string,
    Condition?: string,
    Warranty_Details?: string,
    Vendor?: string,
    BER_Details?: string,
    Serial_No?: string;
    Major_Component_Replacement_Type?: string;
    Major_Component_Replacement_Hours?: number;
    Major_Component_Replacement_Notes?: string;
    New_Major_Component_Replacement_Notes?: string;
    Meter_Reading?: number;
    Charge_Location?: string;
};