
export enum IAPPROVE_ST_TIMESHEET_REQUEST {
    REQUEST = "approveSTTimesheet/APPROVE_ST_TIMESHEET_REQUEST",
    SUCCESS = "approveSTTimesheet/APPROVE_ST_TIMESHEET_SUCCESS",
    FAILED = "approveSTTimesheet/APPROVE_ST_TIMESHEET_FAILED"
};

export interface IApproveSTTimesheetRequest {
    token: string;
    request: IApproveSTTimesheet;
};

export interface IApproveSTTimesheet {
    Modified_By: string;
    Timesheet_ID: number;
    // approveList: ISTTimesheetApproveList[];
};
