import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SHOP_MODELS_REQUEST, IShopModel } from "./getShopModelConstants";
import { IGetShopModelsLoadAction, IGetShopModelsLoadFailedAction, IGetShopModelsSuccessAction, getShopModelsLoadFailedAction, getShopModelsLoadSuccessAction } from "./getShopModelActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getShopModelsEpic: Epic = (
    action$: ActionsObservable<IGetShopModelsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetShopModelsSuccessAction | IGetShopModelsLoadFailedAction> =>
    action$.ofType(IGET_SHOP_MODELS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IShopModel>>>(
                    END_POINTS.FIELD.GET_MODEL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IShopModel>>): IGetShopModelsSuccessAction => {
                            return getShopModelsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getShopModelsLoadFailedAction(response.message)))
                    )
            )
        );