import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_FIELDEMPLOYEE_REQUEST, IUpdateFieldEmployeeRequest } from "./updateFieldEmployeeConstants";


export interface IUpdateFieldEmployeeLoadAction {
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.REQUEST;
    data: IUpdateFieldEmployeeRequest
}
export const updateFieldEmployeeLoadAction = (data: IUpdateFieldEmployeeRequest): IUpdateFieldEmployeeLoadAction => ({
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.REQUEST,
    data
});
export interface IUpdateFieldEmployeeSuccessAction {
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFieldEmployeeLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFieldEmployeeSuccessAction => ({
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.SUCCESS,
    message
});
export interface IUpdateFieldEmployeeLoadFailedAction {
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.FAILED;
    message: string;
}
export const updateFieldEmployeeLoadFailedAction = (message: string): IUpdateFieldEmployeeLoadFailedAction => ({
    type: IUPDATE_FIELDEMPLOYEE_REQUEST.FAILED,
    message
});
