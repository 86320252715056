import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ATTACHMENT_TYPE_REQUEST } from "./updateTypeConstants";
import { IUpdateAttachmentTypeLoadAction, IUpdateAttachmentTypeLoadFailedAction, IUpdateAttachmentTypeSuccessAction, updateAttachmentTypeLoadFailedAction, updateAttachmentTypeLoadSuccessAction } from "./updateTypeActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateTypeEpic: Epic = (
    action$: ActionsObservable<IUpdateAttachmentTypeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateAttachmentTypeSuccessAction | IUpdateAttachmentTypeLoadFailedAction> =>
    action$.ofType(IUPDATE_ATTACHMENT_TYPE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.UPDATE_TYPE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateAttachmentTypeSuccessAction | IUpdateAttachmentTypeLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateAttachmentTypeLoadSuccessAction(response);
                            } else {
                                return updateAttachmentTypeLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateAttachmentTypeLoadFailedAction(response.message)))
                    )
            )
        );