import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SOCT_REQUEST, IAddSOCTRequest } from "./addSOCTConstants";


export interface IAddSOCTLoadAction {
    type: IADD_SOCT_REQUEST.REQUEST;
    data: IAddSOCTRequest
}
export const addSOCTLoadAction = (data: IAddSOCTRequest): IAddSOCTLoadAction => ({
    type: IADD_SOCT_REQUEST.REQUEST,
    data
});
export interface IAddSOCTSuccessAction {
    type: IADD_SOCT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addSOCTLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddSOCTSuccessAction => ({
    type: IADD_SOCT_REQUEST.SUCCESS,
    message
});
export interface IAddSOCTLoadFailedAction {
    type: IADD_SOCT_REQUEST.FAILED;
    message: string;
}
export const addSOCTLoadFailedAction = (message: string): IAddSOCTLoadFailedAction => ({
    type: IADD_SOCT_REQUEST.FAILED,
    message
});
