import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { WorkingSiteLogSubHeader } from "../../header/WorkingSiteLogSubHeader";
import { IGetJournelsRequest, IJournels } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsConstants";
import { getJournels } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsAccessor";
import { getJournelsLoadAction } from "../../../redux/field/workingSiteLogs/getJournels/getJournelsActions";
import queryString from "query-string";
import { IGetJournalVersionHistory, IGetJournalVersionHistoryRequest } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryConstants";
import { getJournalVersionHistoryLoadAction } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryActions";
import { getJournalVersionHistory } from "../../../redux/field/workingSiteLogs/getJournalVersionHistory/getJournalVersionHistoryAccessor";
import { JournalVersionHistoryPopup } from "./journalVersionHistoryPopup";

const defaultSessionStore = '{"columns":[{"visibleIndex":0,"name":"buttons","width":90,"visible":true,"filterValue":null},{"visibleIndex":1,"dataField":"display_Name","name":"display_Name","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":2,"dataField":"site","name":"site","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":3,"dataField":"date","name":"date","dataType":"date","visible":true,"filterValue":null},{"visibleIndex":4,"dataField":"work","name":"work","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":5,"dataField":"description","name":"description","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":6,"name":"buttons","visible":true,"filterValue":null},{"visibleIndex":7,"dataField":"wsS_ID","name":"wsS_ID","dataType":"number","visible":false,"filterValue":null},{"visibleIndex":8,"dataField":"created_By","name":"created_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":9,"dataField":"created","name":"created","dataType":"datetime","visible":true,"filterValue":null},{"visibleIndex":10,"dataField":"modified_By","name":"modified_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":11,"dataField":"modified","name":"modified","dataType":"datetime","visible":true,"sortOrder":"desc","sortIndex":0,"filterValue":null}],"allowedPageSizes":[10,20,40],"filterPanel":{"filterEnabled":true},"filterValue":null,"searchText":"","pageIndex":0,"pageSize":20}';

interface IJournelsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getJournels: Server<SurewayAPIResponse<ById<IJournels>>>;
    getJournalVersionHistory: Server<SurewayAPIResponse<ById<IGetJournalVersionHistory>>>;
};

interface IJournelsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getJournelsRequest: (data: IGetJournelsRequest) => unknown;
    getJournalVersionHistoryRequest: (data: IGetJournalVersionHistoryRequest) => unknown;
};

interface IJournelsOwnProps {

};

interface IJournelsState {
    journalVersionHistoryPopup: boolean;
};

const JournelsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };


    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

    };
`;

type IJournelsProps = RouteComponentProps
    & IJournelsStoreProps
    & IJournelsDispatchProps
    & IJournelsOwnProps;

class Journels extends PureComponent<IJournelsProps, IJournelsState> {

    public constructor(props: IJournelsProps) {
        super(props);
        this.state = {
            journalVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        const query: any = queryString.parse(this.props.location.search);
        if (query !== undefined && (query.keyword !== undefined)) {
            const queryWSSID = query.keyword;
            let val = JSON.parse(defaultSessionStore);
            // console.log(val)
            for (let i = 0; i < val.columns.length; i++) {
                if (val.columns[i].name === "wsS_ID") {
                    val.columns[i].filterValue = queryWSSID;
                }
                
            }
            localStorage.setItem("JournelsListKeywordSessionKey", JSON.stringify(val));
        }
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IJournelsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getJournels, getToken, getJournalVersionHistory } = this.props;
        const query: any = queryString.parse(this.props.location.search);
        const { journalVersionHistoryPopup } = this.state;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const data = hasPayload(getJournels) ? Object.values(getJournels.payload.response) : [];
        const journalVersionHistoryPopupData = hasPayload(getJournalVersionHistory) ? Object.values(getJournalVersionHistory.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Journals" description="Journals List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && 
                <>
                    <WorkingSiteLogSubHeader
                    {...this.props}
                    />
                    
                    <JournelsStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">JOURNALS</h2>
                                    <hr />

                                    {getJournels.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Journals..." />}
                                    {getJournels.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Journals..." />}

                                    {getJournels.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="JournelsList"
                                                    customRowColor={true}
                                                    exportFileName="Journels"
                                                    storageKey={query.keyword ? "JournelsListKeywordSessionKey" :"JournelsListSessionKey"}
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "display_Name", caption: "Name", type: "string" },
                                                        { name: "site", caption: "Site", type: "string" },                                                   
                                                        { name: "date", caption: "Date", type: "date" },                                                   
                                                        { name: "work", caption: "Work", type: "string" },
                                                        { name: "description", caption: "Description", type: "string" },
                                                        { name: "version_History", caption: "Version History", type: "button"},
                                                        { name: "wsS_ID", caption: "WSS ID", type: "number", show: false},
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                        {journalVersionHistoryPopup && <JournalVersionHistoryPopup
                            data={journalVersionHistoryPopupData}
                            display={`Version History`}
                            open={journalVersionHistoryPopup}
                            status={getJournalVersionHistory.kind}
                            onCancel={this.handleJournalVersionHistoryPopupClose}
                        />}
                    </JournelsStyles>
                </>}
            </PageSpacing>
        
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.ADD_UPDATE_JOURNAL
        });
    };

    private onClick = (): void => {

    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IJournels = e.row.data;
        if (name === "version_History") {
            this.handleJournalVersionHistory(data)
        }
    }

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.ADD_UPDATE_JOURNAL
        });
    };

    private handleJournalVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            await this.props.getJournalVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: data.id,
                }
            });
        }
        this.setState({ journalVersionHistoryPopup: true });
    }

    private handleJournalVersionHistoryPopupClose = (): void => {
        this.setState({ journalVersionHistoryPopup: false });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getJournels)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };

        // if (hasPayload(this.props.getJournels)) {
        //     this.setState({
        //         activeList: Object.values(this.props.getJournels.payload.response)
        //             .filter(x => x.active === "Yes")
        //     });
        // };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getJournelsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IJournelsStoreProps => ({
    getToken: getToken(state),
    getJournels: getJournels(state),
    getJournalVersionHistory: getJournalVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IJournelsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getJournelsRequest: (data: IGetJournelsRequest) => dispatch(getJournelsLoadAction(data)),
    getJournalVersionHistoryRequest: (data: IGetJournalVersionHistoryRequest) => dispatch(getJournalVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Journels);