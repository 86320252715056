import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { ROUTE } from "../../routes";
import queryString from "query-string";
import PageSpacing from "../../shared/pageSpacing";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../redux/server";
import { IDispatch, IStore } from "../../../redux/reducers";
import styled from "styled-components";
import { LACenteredLoading } from "../../shared/loading";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import SignatureCanvas from "react-signature-canvas";
import { LAButton, LAGreenButton } from "../../shared/buttons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { BLACK_COLOR, BLUE_COLOR, EXTRA_LIGHT_ORANGE_COLOR, GREEN_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, RED_COLOR, WHITE_COLOR } from "../../shared/theme";
import { base64ToImageUrl, callRouteWithQueryString, getDate, SUCCESS_MESSAGE, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { IFormResponseByUserToken, IFormResponseByUserTokenHazardList, IGetFormResponseByUserTokenRequest } from "../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenConstants";
import { getFormResponseByUserTokenStatus } from "../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenAccessor";
import { getFormResponseByUserTokenLoadAction } from "../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenActions";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import { ISaveCrewSignatureByIDRequest } from "../../../redux/external/saveCrewSignatureByID/saveCrewSignatureByIDConstants";
import { saveCrewSignatureByIDStatus } from "../../../redux/external/saveCrewSignatureByID/saveCrewSignatureByIDAccessor";
import { saveCrewSignatureByIDLoadAction } from "../../../redux/external/saveCrewSignatureByID/saveCrewSignatureByIDActions";
import LAErrorBox from "../../shared/errorBox";
import { END_POINTS } from "../../../redux/endpoints";
import LATextField from "../../shared/textField";
import { LACheckBox } from "../../shared/checkBox";
import { logo } from "../../shared/icons";
import LALinkButton from "../../shared/linkButton";


interface IExternalPSIResponseStoreProps {
    getFormResponseByUserToken: Server<SurewayAPIResponse<IFormResponseByUserToken>>;
    saveCrewSignatureByID: Server<string>;
};

interface IExternalPSIResponseDispatchProps {
    getFormResponseByUserTokenRequest: (request: IGetFormResponseByUserTokenRequest) => unknown;
    saveCrewSignatureByIDRequest: (request: ISaveCrewSignatureByIDRequest) => unknown;
};

interface IExternalPSIResponseOwnProps {

};

interface IExternalPSIResponseState {
    data: IFormResponseByUserToken | undefined;
    generalExpand: boolean;
    hazardListExpand: boolean;
    signatureExpand: boolean;
    signature: string;
    name: string;
    acknowledge: boolean;
    serverError: string;
};

const ExternalPSIResponseStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .red-text {
        color: ${RED_COLOR};
    };

    .even-row {
        background-color: #F5F5F5;
        border: 1.5px solid ${BLACK_COLOR};
    }
    
    .odd-row {
        background-color: #dddddd;
        border: 1.5px solid ${BLACK_COLOR};
    };

    .pull-right {
        display: flex;
        justify-content: flex-end;
    };

    .lowRisk {
        color: ${GREEN_COLOR};
    }
    .mediumRisk {
        color: ${BLUE_COLOR};
    }
    .highRisk {
        color: ${RED_COLOR};
    }

    .hazard {
        background-color: ${EXTRA_LIGHT_ORANGE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .signature-container {
        padding: 5px;
        display: inline-block;
    }

    .signCanvas {
        border: 1px solid #ccc;
        width: 100%;
        max-width: 450px; /* Limit the maximum width */
        height: 200px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };
    };
`;


type IExternalPSIResponseProps = RouteComponentProps
    & IExternalPSIResponseStoreProps
    & IExternalPSIResponseDispatchProps
    & IExternalPSIResponseOwnProps;

class ExternalPSIResponse extends PureComponent<IExternalPSIResponseProps, IExternalPSIResponseState> {
    signCanvas: React.RefObject<any>;
    public constructor(props: IExternalPSIResponseProps) {
        super(props);
        this.signCanvas = React.createRef();
        this.state = {
            data: undefined,
            signature: "",
            name: "",
            generalExpand: true,
            hazardListExpand: true,
            signatureExpand: true,
            serverError: "",
            acknowledge: false,
        };
    }

    public componentDidMount(): void {
        this.getData();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IExternalPSIResponseProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if(hasPayload(this.props.getFormResponseByUserToken) && this.props.getFormResponseByUserToken.payload.message !== SUCCESS_MESSAGE) {
                this.setState({ serverError: this.props.getFormResponseByUserToken.payload.message });
            }

            if(this.props.saveCrewSignatureByID !== prevProps.saveCrewSignatureByID) {
                if (this.props.saveCrewSignatureByID.kind === STATUS_ENUM.FAILED) {
                    this.setState({ serverError: this.props.saveCrewSignatureByID.message });
                }

                if (hasPayload(this.props.saveCrewSignatureByID) && this.props.saveCrewSignatureByID.kind === STATUS_ENUM.SUCCEEDED) {
                    this.redirect();
                }
            }
        }
    };


    public render(): ReactNode {

        const { getFormResponseByUserToken, saveCrewSignatureByID } = this.props;
        const { data, hazardListExpand, signatureExpand, serverError} = this.state;
        const query: any = queryString.parse(this.props.location.search);
        const disableSaveForGuests = (query.token === undefined && this.state.name.length === 0) ? true : undefined;
        // const onFormUpdated = (checked: boolean): void => this.handleAcknowledge("form_Updated", checked ? "Yes" : "No");
        // console.log(this.state)

        if(getFormResponseByUserToken.kind === STATUS_ENUM.LOADING) {
            <LACenteredLoading message="Please wait... Loading data" />
        }
        if(getFormResponseByUserToken.kind === STATUS_ENUM.FAILED) {
            return <LACenteredLoading message="Failed to load data..." />
        }
        if(serverError && serverError.length > ZEROTH) {
            return <LAErrorBox text={serverError} />
        }

        return (
            <PageSpacing title="PSI Response" description="PSI Response" fixedSpaceOnSmallerScreens={true}>
                <ExternalPSIResponseStyles>
                    <LAGrid>
                        <LAGridItem xs={12}>
                            <LAGridItem xs={12} className="text-center">
                                <img onClick={undefinedFunction} src={logo} className="logo-img" alt="logo" title="logo" />
                                <h2>{data?.form_Name ?? ""}</h2>
                                {data?.form_Updated === "Yes" && <strong className="red-text">This form has been updated by foreman. Please review and sign again.</strong>}
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                 <LAGrid>
                                    {query.token && <LAGridItem xs={12} >
                                        <strong>Employee Name: {data?.emp_Name ?? ""}</strong>
                                    </LAGridItem>}
                                    <LAGridItem xs={12}>
                                        <strong>Working Site: {data?.site ?? ""}</strong>
                                    </LAGridItem>
                                    {data?.work_Description && <LAGridItem xs={12} >
                                        <strong>Work Description: {data?.work_Description ?? ""}</strong>
                                    </LAGridItem>}
                                    <LAGridItem xs={12} >
                                        <strong>Foreman/Supervisor: {data?.supervisor ?? ""}</strong>
                                    </LAGridItem>
                                    {data?.urgent_Care_Facility && <LAGridItem xs={12} >
                                        <strong>Urgent Care Facility: {data?.urgent_Care_Facility ?? ""}</strong>
                                    </LAGridItem>}
                                    {data?.muster_Point &&<LAGridItem xs={12} >
                                        <strong>Muster Point: {data?.muster_Point ?? ""}</strong>
                                    </LAGridItem>}
                                    <LAGridItem xs={12} >
                                        <strong>Date: {data && data.date ? getDate(false,data.date) : ""}</strong>
                                    </LAGridItem>
                                    {data?.tool_Box_Meeting && <LAGridItem xs={12} >
                                        <strong>Tool Box Meeting: {data?.tool_Box_Meeting ?? ""}</strong>
                                    </LAGridItem>}
                                    {data?.form_Documents && <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Form Documents:</strong> <br />
                                        {data?.form_Documents.split(";").map((x: string) => 
                                            <>
                                                <LALinkButton
                                                    label={x}
                                                    onClick={() => this.downloadFormAttachmentEndpoint(x, data.form_ID, "Template")}
                                                />
                                                <br/>
                                            </>
                                            
                                        )}
                                    </LAGridItem>}
                                    {data?.additional_Images && <LAGridItem xs={12}>
                                        <strong>Additional Images:</strong> <br />
                                        <LAGrid>
                                            {data?.additional_Images.split(",").map((img: string, imageIdx: number) => {
                                                return <LAGridItem xs={6} key={imageIdx}>
                                                    <LAThumbnailWithLink
                                                        alt={img}
                                                        key={imageIdx}
                                                        url={webConfig.imgApiBaseUrl + img}
                                                        id={(imageIdx + "_").toString()}
                                                    />
                                                </LAGridItem>
                                        })}
                                        </LAGrid>
                                    </LAGridItem>}
                                    {data?.attachments && <LAGridItem xs={12}>
                                        <strong>Additional Files:</strong> <br />
                                        {data.attachments.split(";").map((x: string) => 
                                            <>
                                                <LALinkButton
                                                    label={x}
                                                    onClick={() => this.downloadFormAttachmentEndpoint(x, Number(query.id), "PSI")}
                                                />
                                                <br/>
                                            </>
                                            
                                        )}
                                    </LAGridItem>}
                                </LAGrid>
                            </LAGridItem>

                            {data && data.responses && data.responses.length > 0 && data.responses.map((x:any, idx: number) => {
                                return <LAExpansionPanel className="mt-2" color={GREEN_COLOR} textColor={WHITE_COLOR} label={x.section} expanded={x.expand} onClick={() => this.handleExpand(idx)}> 
                                    <LAGridItem xs={12} key={idx}>
                                        {x.questions && x.questions.length > 0 && x.questions.sort((a: any, b: any) => a.display_Order - b.display_Order).map((q:any, qIdx: number) => {
                                            return <LAGrid key={qIdx}> 
                                                <LAGridItem xs={12} className="mt-1" key={qIdx}>
                                                    <LAPaperWithLessPadding className={idx % 2 ? "odd-row" : "even-row"} key={qIdx}>
                                                            <LAGridItem xs={12} >
                                                                <strong>{qIdx + 1}: {q.question} - {q.answer}</strong>
                                                            </LAGridItem>
                                                            {q.comments && <LAGridItem xs={12}>
                                                                <strong>Comments: {q.comments}</strong>
                                                            </LAGridItem>}
                                                            {q.images && <LAGridItem xs={12}>
                                                                <LAGrid>
                                                                    {q.images.split(",").map((img: string, imageIdx: number) => {
                                                                        return <LAGridItem xs={6}>
                                                                            <LAThumbnailWithLink
                                                                                alt={img}
                                                                                key={imageIdx}
                                                                                url={webConfig.imgApiBaseUrl + img}
                                                                                id={(imageIdx + "_").toString()}
                                                                            />
                                                                        </LAGridItem>
                                                                })}
                                                                </LAGrid>
                                                            </LAGridItem>}
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            </LAGrid>
                                        })}
                                    </LAGridItem>
                                </LAExpansionPanel>
                            })}
                            {/* <LAExpansionPanel className="mt-3" color={GREEN_COLOR} textColor={WHITE_COLOR} label={"General"} expanded={generalExpand} onClick={() => this.handleSectionExpand("generalExpand")}> 
                                <LAGridItem xs={12}>
                                    {data && data.responses && data.responses.length > 0 && data.responses.map((x:any, idx: number) => {
                                        return <LAGrid key={idx}> 
                                            <LAGridItem xs={12} className="mt-3" key={idx}>
                                                <LAPaperWithLessPadding className={idx % 2 ? "odd-row" : "even-row"} key={idx}>
                                                        <LAGridItem xs={12} >
                                                            <strong>{idx + 1}: {x.question} - {x.answer}</strong>
                                                        </LAGridItem>
                                                        {x.comments && <LAGridItem xs={12}>
                                                            <strong>Comments: {x.comments}</strong>
                                                        </LAGridItem>}
                                                        {x.images && <LAGridItem xs={12}>
                                                            <LAGrid>
                                                                {x.images.split(",").map((img: string, imageIdx: number) => {
                                                                    return <LAGridItem xs={6}>
                                                                        <LAThumbnailWithLink
                                                                            alt={img}
                                                                            key={imageIdx}
                                                                            url={webConfig.imgApiBaseUrl + img}
                                                                            id={(imageIdx + "_").toString()}
                                                                        />
                                                                    </LAGridItem>
                                                            })}
                                                            </LAGrid>
                                                        </LAGridItem>}
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        </LAGrid>
                                    })}
                                </LAGridItem>
                            </LAExpansionPanel> */}

                            {data?.hazard_List && data?.hazard_List.length > 0 && <LAExpansionPanel className="mt-3" color={ORANGE_COLOR} textColor={WHITE_COLOR} label={"Hazard Assessment"} expanded={hazardListExpand} onClick={() => this.handleSectionExpand("hazardListExpand")}>
                                <LAGridItem xs={12}>
                                    <LAGrid>
                                        <LAGridItem xs={12} >
                                            <strong>Department: {data?.department ?? ""}</strong>
                                        </LAGridItem>
                                        <LAGridItem xs={12} >
                                            <strong>Tasks: {data?.tasks ?? ""}</strong>
                                        </LAGridItem>
                                        
                                        {data?.hazard_List && data?.hazard_List.length > 0 && data?.hazard_List.map((h: IFormResponseByUserTokenHazardList, hazardIndex: number) => {
                                            const riskResult = this.getRiskResult(h.probability, h.severity);
                                            return <LAGridItem xs={12} key={hazardIndex}>
                                                <LAPaperWithLessPadding className={"hazard"} key={hazardIndex}>
                                                    <LAGrid key={hazardIndex}>
                                                        <LAGridItem xs={6} >
                                                            <img alt={h.hazard_Type} src={`${END_POINTS.IMG_DIRECTORY}${h.image}`} width={20} height={20} />
                                                        </LAGridItem>
                                                        <LAGridItem xs={6} className="pull-right">
                                                            <strong className={riskResult==="Low" ? "lowRisk" : riskResult==="Medium" ? "mediumRisk" : riskResult==="High" ? "highRisk" : ""}>Risk: {riskResult}</strong>
                                                            </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard Type: {h.hazard_Type}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard: {h.hazard}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Probability: {h.probability}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Severity: {h.severity}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Control Plans: {h.control_Plans}</strong>
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })}
                                    </LAGrid>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            {query.token && <LAExpansionPanel className="mt-3" color={BLUE_COLOR} textColor={WHITE_COLOR} label={"Signature"} expanded={signatureExpand} onClick={() => this.handleSectionExpand("signatureExpand")}>
                                <LAGridItem xs={12}> 
                                    <LACheckBox
                                        name="form_Updated"
                                        label="I acknowledge that I have fully read and understood the hazards and associated control plans mentioned on this form."
                                        disabled={undefined}
                                        onChange={this.handleAcknowledge}
                                        value={this.state.acknowledge}
                                    />
                                    {(data?.form_Updated === "Yes" || (data?.form_Updated === "No" && this.state.signature === null)) && <LAGridItem xs={12}>                                
                                        {!data?.signature && <>
                                            <span className="red-text">After review, please sign below and click on the save signature button and then submit the form!</span>
                                            <br /><br />
                                        </>}

                                        <div className={`signature-container ${!data?.signature ? "required-sign" : "sign"}`}>
                                            <SignatureCanvas
                                                penColor="black"
                                                ref={this.signCanvas}
                                                canvasProps={{ width: 500, height: 200, className: 'signCanvas' }}
                                            />
                                        </div>
                                        <br />
                                        <LAButton
                                            className="mt-2"
                                            label="Save Signature"
                                            disabled={!this.state.acknowledge ? true : undefined}
                                            onClick={this.saveSignature}
                                        />

                                        <LAButton
                                            className="ml-1 mt-2"
                                            label="Clear"
                                            onClick={this.clearSignature}
                                        />
                                    </LAGridItem>}
                                    <br />
                                    <LAGridItem xs={12}>
                                        {data?.signature && (
                                            <div>
                                                <img src={base64ToImageUrl(data?.signature)} alt="signature" />
                                            </div>
                                        )}
                                        {data?.signature_Date && <strong>Last Signed at : {new Date(data?.signature_Date).toLocaleString()}</strong> }
                                    </LAGridItem>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            {query.token === undefined && <LAExpansionPanel className="mt-3" color={BLUE_COLOR} textColor={WHITE_COLOR} label={"Signature"} expanded={signatureExpand} onClick={() => this.handleSectionExpand("signatureExpand")}>
                                <LAGridItem xs={12}> 
                                    <LAGridItem xs={12}>  
                                        <LACheckBox
                                            name="form_Updated"
                                            label="I acknowledge that I have fully read and understood the hazards and associated control plans mentioned on this form. "
                                            disabled={undefined}
                                            onChange={this.handleAcknowledge}
                                            value={this.state.acknowledge}
                                        />     
                                        <br />                         
                                        {!data?.signature && <>
                                            <span className="red-text">After review, please add your name and sign below and click on the save signature button and then submit the form!</span>
                                            <br />
                                        </>}

                                        <LATextField
                                            name="name"
                                            label="Name"
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={undefined}
                                            errorText={this.state.name.length === 0 ? "Required" : undefined}
                                            value={this.state.name ?? ""}
                                            onChange={this.handleChange}
                                        />

                                        <div className={`signature-container ${!data?.signature ? "required-sign" : "sign"}`}>
                                            <SignatureCanvas
                                                penColor="black"
                                                ref={this.signCanvas}
                                                canvasProps={{ width: 500, height: 200, className: 'signCanvas' }}
                                            />
                                        </div>
                                        <br />
                                        <LAButton
                                            className="mt-2"
                                            label="Save Signature"
                                            disabled={!this.state.acknowledge ? true : undefined}
                                            onClick={this.saveSignature}
                                        />

                                        <LAButton
                                            className="ml-1 mt-2"
                                            label="Clear"
                                            onClick={this.clearSignature}
                                        />
                                    </LAGridItem>
                                    <br />
                                    <LAGridItem xs={12}>
                                        {data?.signature && (
                                            <div>
                                                <img src={base64ToImageUrl(data?.signature)} alt="signature" />
                                            </div>
                                        )}
                                        {data?.signature_Date && <strong>Last Signed at : {new Date(data?.signature_Date).toLocaleString()}</strong> }
                                    </LAGridItem>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            <LAGridItem className="mt-3" xs={12} sm={12} md={6}>
                                <LAGreenButton 
                                    label="Submit" 
                                    disabled={(this.state.signature === data?.signature || disableSaveForGuests || !this.state.acknowledge) ? true : data?.signature ? undefined : true} 
                                    fullWidth={true}
                                    onClick={this.handleSave} 
                                />
                            </LAGridItem>
                        </LAGridItem>
                    </LAGrid>
                </ExternalPSIResponseStyles> 
                {/* {saveCrewSignatureByID.kind === STATUS_ENUM.SUCCEEDED && this.redirect()} */}
                <RequestStatus requestStatus={saveCrewSignatureByID.kind} successMessage="Your response has been saved successfully!" />
            </PageSpacing>
        );
    }

    private redirect = (): void => {
        const query: any = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { token: query.token},
            pathName: ROUTE.EXTERNAL.PSI_RESPSONSE_SUCCESS
        });
    }

    private downloadFormAttachmentEndpoint = async (fileName: string, id: number, page: string): Promise<void> => {
        if (fileName !== null) {
            // const query: any = queryString.parse(this.props.location.search);
            await fetch(END_POINTS.FIELD.DOWNLOAD_FORM_ATTACHMENT, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                    request: {
                        File_Name: fileName,
                        ID: id,
                        Page: page
                    },
                })
            })
                .then((res: any) => {
                    return res.blob();
                })
                .then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
        };
    };

    private handleAcknowledge = (value: boolean) => {
        this.setState((prevState) => ({
            ...prevState,               
            acknowledge: value 
        }));
    };

    private handleExpand = (sectionIndex: number) => {
        const iS = { ...this.state.data };
        if(iS.responses && this.state.data) {
            let response = {
                ...iS.responses[sectionIndex]
            };
            response.expand =!response.expand;
    
            iS.responses[sectionIndex] = response;
    
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    responses: iS.responses,
                }
            });
        }
    };

    private handleSectionExpand = (name: keyof IExternalPSIResponseState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    private getRiskResult = (probability: string, severity: string) : string => {
        let risk = "";

        if (probability === severity) {
            risk = probability;
        } 
        else if ((probability === "Medium" && severity === "Low") || (probability === "Low" && severity === "Medium")) {
            risk = "Low";
        } 
        else if ((probability === "Low" && severity === "High") || (probability === "High" && severity === "Low")) {
            risk = "Medium";
        }
        else if ((probability === "High" && severity === "Medium") || (probability === "Medium" && severity === "High")) {
            risk = "High";
        } 

        return risk;
    }

    private handleChange = (name: string, value: string) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: value
        }));
    };

    private clearSignature = (): void => {
        this.signCanvas.current.clear();
    };

    private saveSignature = () => {
        const dataUrl = this.signCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        if(this.state.data) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    signature: dataUrl
                }
            });
            this.clearSignature();
        }
    };

    private handleSave = (): void => {
        const {data, name} = this.state;
        const query: any = queryString.parse(this.props.location.search);
        if(data) {
            if(query.token) {
                const saveRequest = {
                    id: Number(query.id),
                    emp_id: data.emp_ID,
                    signature: data.signature
                }
                this.props.saveCrewSignatureByIDRequest({
                    token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                    request: saveRequest
                });
            }
            else if (query.token === undefined) {
                const saveRequest = {
                    id: Number(query.id),
                    emp_id: 0,
                    signature: data.signature,
                    name: name
                }
                this.props.saveCrewSignatureByIDRequest({
                    token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                    request: saveRequest
                });
            }
        }
    };

    private groupedQuestionsBySection = (questions: any) : any => {
        // console.log(questions)
        return questions.reduce((result: any, question: any) => {
            const section = question.section ? question.section.trim() : "Questions";
            let sectionGroup = result.find((group: any) => group.section === section);
        
            if (!sectionGroup) {
                // If the section group doesn't exist, create it
                sectionGroup = { section: section, expand: true, questions: [] };
                result.push(sectionGroup);
            }
        
            // Add the current question to the section group
            sectionGroup.questions.push(question);
            return result;
        }, []);
    }

    private callServer = (): void => {
        const query: any = queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if(hasPayload(this.props.getFormResponseByUserToken)) {
                const data = this.props.getFormResponseByUserToken.payload.response;
                // console.log(data)
                if(data) {

                    const groupedQuestionsBySection = this.groupedQuestionsBySection(data.responses);
                    // this.setState({ 
                    //     data,
                    //     signature: data.signature
                    // });
                    this.setState({
                        ...this.state,
                        data: {
                            ...data,
                            responses: groupedQuestionsBySection,
                        },
                        signature: data.signature
                    });
                }
            }
            if (isNotLoaded(this.props.getFormResponseByUserToken)) {
                this.getData();
            }
        } else {
            this.props.history.push(ROUTE.ACCESS_DENIED);
        };
    };

    private getData = (): void => {
        const query: any = queryString.parse(this.props.location.search);
        if (query !== undefined && query.token !== undefined && query.id !== undefined) {
            this.props.getFormResponseByUserTokenRequest({
                token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                request: {
                    id : Number(query.id),
                    token : query.token
                }
            });
        }
        else if (query !== undefined && query.id !== undefined) {
            this.props.getFormResponseByUserTokenRequest({
                token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                request: {
                    id : Number(query.id),
                    // token : query.token
                }
            });
        }
    }

}

const mapStateToProps = (state: IStore): IExternalPSIResponseStoreProps => ({
    getFormResponseByUserToken: getFormResponseByUserTokenStatus(state),
    saveCrewSignatureByID: saveCrewSignatureByIDStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalPSIResponseDispatchProps => ({
    getFormResponseByUserTokenRequest: (request: IGetFormResponseByUserTokenRequest) => dispatch(getFormResponseByUserTokenLoadAction(request)),
    saveCrewSignatureByIDRequest: (request: ISaveCrewSignatureByIDRequest) => dispatch(saveCrewSignatureByIDLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalPSIResponse);