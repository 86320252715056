
export enum IGET_CONTROL_PLAN_NEW_REQUEST {
    REQUEST = "getControlPlansNew/GET_CONTROL_PLAN_NEW_REQUEST",
    SUCCESS = "getControlPlansNew/GET_CONTROL_PLAN_NEW_SUCCESS",
    FAILED = "getControlPlansNew/GET_CONTROL_PLAN_NEW_FAILED"
};

export interface IGetControlPlansNewRequest {
    token: string;
};

export interface IGetControlPlansNew {
    id: number;
    control_Plan: string;
    hazard_List: number[];
    hazards?:string;
    active: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

