import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_SHOP_MANUFACTURERS_REQUEST, IUpdateShopManufactureRequest } from "./updateShopManufacturesConstants";


export interface IUpdateShopManufacturesLoadAction {
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.REQUEST;
    data: IUpdateShopManufactureRequest
}
export const updateShopManufacturesLoadAction = (data: IUpdateShopManufactureRequest): IUpdateShopManufacturesLoadAction => ({
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.REQUEST,
    data
});
export interface IUpdateShopManufacturesSuccessAction {
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateShopManufacturesLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateShopManufacturesSuccessAction => ({
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS,
    message
});
export interface IUpdateShopManufacturesLoadFailedAction {
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.FAILED;
    message: string;
}
export const updateShopManufacturesLoadFailedAction = (message: string): IUpdateShopManufacturesLoadFailedAction => ({
    type: IUPDATE_SHOP_MANUFACTURERS_REQUEST.FAILED,
    message
});
