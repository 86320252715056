import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IINITIATE_MOVE_REQUEST, IInitiateMoveRequest } from "./initiateMoveConstants";

export interface IInitiateMoveLoadAction {
    type: IINITIATE_MOVE_REQUEST.REQUEST;
    data: IInitiateMoveRequest
}
export const initiateMoveLoadAction = (data: IInitiateMoveRequest): IInitiateMoveLoadAction => ({
    type: IINITIATE_MOVE_REQUEST.REQUEST,
    data
});
export interface IInitiateMoveSuccessAction {
    type: IINITIATE_MOVE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const initiateMoveLoadSuccessAction = (message: SurewayAPIResponse<string>): IInitiateMoveSuccessAction => ({
    type: IINITIATE_MOVE_REQUEST.SUCCESS,
    message
});
export interface IInitiateMoveLoadFailedAction {
    type: IINITIATE_MOVE_REQUEST.FAILED;
    message: string;
}
export const initiateMoveLoadFailedAction = (message: string): IInitiateMoveLoadFailedAction => ({
    type: IINITIATE_MOVE_REQUEST.FAILED,
    message
});
