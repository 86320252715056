
export enum IGET_CREWS_REQUEST {
    REQUEST = "getCrews/GET_CREWS_REQUEST",
    SUCCESS = "getCrews/GET_CREWS_SUCCESS",
    FAILED = "getCrews/GET_CREWS_FAILED"
};

export interface IGetCrewsRequest {
    token: string;
};

export interface IGetCrews {
    id: number;
    foreman: string;
    crew_Name: string;
    active: string;
    employees?: string;
    members: number[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

