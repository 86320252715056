import { IAddUpdateRepairLineWOUnit } from "../addRepairLineWOUnit/addRepairLineWOUnitConstants";

export enum IUPDATE_REPAIR_LINE_WO_UNIT_REQUEST {
    REQUEST = "updateRepairLineWOUnit/UPDATE_REPAIR_LINE_WO_UNIT_REQUEST",
    SUCCESS = "updateRepairLineWOUnit/UPDATE_REPAIR_LINE_WO_UNIT_SUCCESS",
    FAILED = "updateRepairLineWOUnit/UPDATE_REPAIR_LINE_WO_UNIT_FAILED"
};

export interface IUpdateRepairLineWOUnitRequest {
    token: string;
    request: IAddUpdateRepairLineWOUnit;
};