import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { NEW_APP_URL, ROUTE } from "../../routes";
import { connect } from "react-redux";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading from "../../shared/loading";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import PageSpacing from "../../shared/pageSpacing";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { LAPaperWithPadding } from "../../shared/paper";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { callRouteWithQueryString, getTokenFromUrl,  pageAccessCheck, userName } from "../../shared/constExports";
import { AttachmentSubHeader, AttachmentSubHeaderMobile } from "../../header/attachmentSubHeader";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getMoveRequestHistory } from "../../../redux/field/attachment/getMoveRequestHistory/getMoveRequestHistoryAccessor";
import { getMoveRequestHistoryLoadAction } from "../../../redux/field/attachment/getMoveRequestHistory/getMoveRequestHistoryActions";
import { IGetMoveRequestHistoryRequest, IMoveRequestHistory } from "../../../redux/field/attachment/getMoveRequestHistory/getMoveRequestHistoryConstants";

interface IAttachmentMoveRequestHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getHistory: Server<SurewayAPIResponse<ById<IMoveRequestHistory>>>;
};

interface IAttachmentMoveRequestHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getRequestHistoryRequest: (data: IGetMoveRequestHistoryRequest) => unknown;
};


interface IAttachmentMoveRequestHistoryOwnProps {

};

interface IAttachmentMoveRequestHistoryState {

};

const AttachmentMoveMoveRequestHistory = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadMoveRequestHistory {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IAttachmentMoveRequestHistoryProps = RouteComponentProps
    & IAttachmentMoveRequestHistoryStoreProps
    & IAttachmentMoveRequestHistoryDispatchProps
    & IAttachmentMoveRequestHistoryOwnProps;

class AttachmentMoveRequestHistory extends PureComponent<IAttachmentMoveRequestHistoryProps, IAttachmentMoveRequestHistoryState> {

    public constructor(props: IAttachmentMoveRequestHistoryProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAttachmentMoveRequestHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getHistory, getToken } = this.props;
        const getRole = pageAccessCheck(getToken, "attachmentAccess");
        const data: IMoveRequestHistory[] = hasPayload(getHistory) ? Object.values(getHistory.payload.response) : [];
        // console.log(data)
        return (
            <PageSpacing title="Attachment MoveRequestHistory" description="FIELD - ATTACHMENT STYLES" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <AttachmentMoveMoveRequestHistory>

                    <div className="hide-on-phone">
                        <AttachmentSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <AttachmentSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">
                                <h2 className="title">GET HISTORY</h2>
                                <hr />

                                {getHistory.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Attachment Move Request History..." />}
                                {getHistory.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Attachment Move Request History..." />}

                                {getHistory.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                export={true}
                                                height={1200}
                                                searchPanel={true}
                                                filterHeader={true}
                                                customRowColor={true}
                                                onClick={this.onClick}
                                                onCustomIconClick={this.handleCustom}
                                                id="attachmentMoveRequestHistoryList"
                                                storageKey="attachmentMoveHistoryListSessionKey"
                                                getRefreshDataCall={this.getDataForTable}
                                                exportFileName="AttachmentMoveRequestHistory"
                                                columns={[
                                                    { name: "move_Request_ID", caption: "View ET Move", type: "link", linkButtonText: "Open" },
                                                    { name: "move_Request_ID", caption: "ET Move?", type: "string", renderCell: (e:any) => { return <div>{e.row.data.move_Request_ID > 0 ? "Yes" : "No"} </div>}},
                                                    { name: "attachment_No", caption: "Attachment #", type: "string" },
                                                    { name: "attached_Unit_No", caption: "Attached Unit #", type: "string" },
                                                    { name: "type", caption: "Type", type: "string" },
                                                    { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                                    { name: "style", caption: "Style", type: "string" },
                                                    { name: "from_Location", caption: "From Location", type: "string" },
                                                    { name: "to_Location", caption: "To Location", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    { name: "complete_By", caption: "Complete By", type: "date" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </AttachmentMoveMoveRequestHistory>}
            </PageSpacing>
        );
    }

    private onClick = (): void => {
    };

    private handleCustom = (name: string, data: IMoveRequestHistory): void => {
        // callRouteWithQueryString({
        //     route: this.props,
        //     search: { token: getTokenFromUrl(true) },
        //     pathName: "/field/equipment-tracker/moverequests/moverequest?id=" + data.move_Request_ID.toString()
        // });
        window.open(NEW_APP_URL + "/sureway/field/equipment-tracker/moverequests/moverequest?id=" + + data.move_Request_ID.toString(), '_self');
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getHistory)) {
                    this.getDataForTable();
                };
            } else {
                
                this.props.history.push({
                    pathname: ROUTE.INDEX,
                    search: getTokenFromUrl(false)
                  });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
            this.props.getRequestHistoryRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAttachmentMoveRequestHistoryStoreProps => ({
    getToken: getToken(state),
    getHistory: getMoveRequestHistory(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAttachmentMoveRequestHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRequestHistoryRequest: (data: IGetMoveRequestHistoryRequest) => dispatch(getMoveRequestHistoryLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentMoveRequestHistory);