import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_HAZARD_NEW_REQUEST } from "./updateHazardNewConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IUpdateHazardNewLoadAction, IUpdateHazardNewLoadFailedAction, IUpdateHazardNewSuccessAction, updateHazardNewLoadFailedAction, updateHazardNewLoadSuccessAction } from './updateHazardNewActions';

export const updateHazardNewEpic: Epic = (
    action$: ActionsObservable<IUpdateHazardNewLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateHazardNewSuccessAction | IUpdateHazardNewLoadFailedAction> =>
    action$.ofType(IUPDATE_HAZARD_NEW_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.ADMIN.UPDATE_HAZARD_NEW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateHazardNewSuccessAction | IUpdateHazardNewLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateHazardNewLoadSuccessAction(response);
                            } else {
                                return updateHazardNewLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateHazardNewLoadFailedAction(response.message)))
                    )
            )
        );