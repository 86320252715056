import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentHistoryColumn, IGetEquipmentHistoryColumnRequest } from "./getEquipmentHistoryColumnConstants";

export interface IGetEquipmentHistoryColumnsLoadAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.REQUEST;
    data: IGetEquipmentHistoryColumnRequest
}
export const getEquipmentHistoryColumnsLoadAction = (data: IGetEquipmentHistoryColumnRequest): IGetEquipmentHistoryColumnsLoadAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentHistoryColumnsSuccessAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetEquipmentHistoryColumn[]>;
}
export const getEquipmentHistoryColumnsLoadSuccessAction = (list: SurewayAPIResponse<IGetEquipmentHistoryColumn[]>): IGetEquipmentHistoryColumnsSuccessAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentHistoryColumnsLoadFailedAction {
    type: IGET_EQUIPMENT_TYPES_REQUEST.FAILED;
    message: string;
}
export const getEquipmentHistoryColumnsLoadFailedAction = (message: string): IGetEquipmentHistoryColumnsLoadFailedAction => ({
    type: IGET_EQUIPMENT_TYPES_REQUEST.FAILED,
    message
});
