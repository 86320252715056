import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_DRIVERS_REQUEST, IETDriver } from "./getDriversConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetDriversLoadAction, IGetDriversLoadFailedAction, IGetDriversSuccessAction, getDriversLoadFailedAction, getDriversLoadSuccessAction } from "./getDriversActions";

export const getDriversEpic: Epic = (
    action$: ActionsObservable<IGetDriversLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetDriversSuccessAction | IGetDriversLoadFailedAction> =>
    action$.ofType(IGET_DRIVERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<IETDriver[]>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.ADMIN.GET_DRIVER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: IETDriver[]): IGetDriversSuccessAction => {
                            return getDriversLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getDriversLoadFailedAction(response)))
                    )
            )
        );