import { IUPDATE_DRIVER_REQUEST } from "./updateDriverConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { IUpdateDriverLoadAction, IUpdateDriverLoadFailedAction, IUpdateDriverSuccessAction } from "./updateDriverActions";

type Actions =
    | IUpdateDriverLoadAction
    | IUpdateDriverSuccessAction
    | IUpdateDriverLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateETDriverReducer = (state: Server<string> = notLoaded, action: Actions): Server<string> => {
    switch (action.type) {
        case IUPDATE_DRIVER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_DRIVER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_DRIVER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};