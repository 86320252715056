import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_HAZARD_TYPE_REQUEST, IUpdateHazardTypeRequest } from "./updateHazardTypeConstants";


export interface IUpdateHazardTypeLoadAction {
    type: IUPDATE_HAZARD_TYPE_REQUEST.REQUEST;
    data: IUpdateHazardTypeRequest
}
export const updateHazardTypeLoadAction = (data: IUpdateHazardTypeRequest): IUpdateHazardTypeLoadAction => ({
    type: IUPDATE_HAZARD_TYPE_REQUEST.REQUEST,
    data
});
export interface IUpdateHazardTypeSuccessAction {
    type: IUPDATE_HAZARD_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateHazardTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateHazardTypeSuccessAction => ({
    type: IUPDATE_HAZARD_TYPE_REQUEST.SUCCESS,
    message
});
export interface IUpdateHazardTypeLoadFailedAction {
    type: IUPDATE_HAZARD_TYPE_REQUEST.FAILED;
    message: string;
}
export const updateHazardTypeLoadFailedAction = (message: string): IUpdateHazardTypeLoadFailedAction => ({
    type: IUPDATE_HAZARD_TYPE_REQUEST.FAILED,
    message
});
