import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SERVICE_SECTION_REQUEST, IUpdateServiceSectionRequest } from "./updateServiceSectionConstants";


export interface IUpdateServiceSectionLoadAction {
    type: IUPDATE_SERVICE_SECTION_REQUEST.REQUEST;
    data: IUpdateServiceSectionRequest
}
export const updateServiceSectionLoadAction = (data: IUpdateServiceSectionRequest): IUpdateServiceSectionLoadAction => ({
    type: IUPDATE_SERVICE_SECTION_REQUEST.REQUEST,
    data
});
export interface IUpdateServiceSectionSuccessAction {
    type: IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateServiceSectionLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateServiceSectionSuccessAction => ({
    type: IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS,
    message
});
export interface IUpdateServiceSectionLoadFailedAction {
    type: IUPDATE_SERVICE_SECTION_REQUEST.FAILED;
    message: string;
}
export const updateServiceSectionLoadFailedAction = (message: string): IUpdateServiceSectionLoadFailedAction => ({
    type: IUPDATE_SERVICE_SECTION_REQUEST.FAILED,
    message
});
