import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { ROUTE } from "../../../routes";
import queryString from "query-string";
import PageSpacing from "../../../shared/pageSpacing";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../redux/server";
import { IDispatch, IStore } from "../../../../redux/reducers";
import styled from "styled-components";
import { LACenteredLoading } from "../../../shared/loading";
import { LAPaperWithLessPadding } from "../../../shared/paper";
import { SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { LARedButton } from "../../../shared/buttons";
import { BLACK_COLOR, BLUE_COLOR, DARK_ORANGE_COLOR, EXTRA_LIGHT_ORANGE_COLOR, GREEN_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, RED_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { base64ToImageUrl, callRouteWithQueryString, getDate, getTokenFromUrl, SUCCESS_MESSAGE, userName, ZEROTH } from "../../../shared/constExports";
import { LAExpansionPanel } from "../../../shared/expansionPanel";
import { IFormResponseByUserToken, IFormResponseByUserTokenHazardList, IGetFormResponseByUserTokenRequest } from "../../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenConstants";
import { getFormResponseByUserTokenStatus } from "../../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenAccessor";
import { getFormResponseByUserTokenLoadAction } from "../../../../redux/external/getFormResponseByUserToken/getFormResponseByUserTokenActions";
import { LAThumbnailWithLink } from "../../../shared/thumbnail";
import { webConfig } from "../../../../utils/webConfig";
import LAErrorBox from "../../../shared/errorBox";
import { END_POINTS } from "../../../../redux/endpoints";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LALinkButton from "../../../shared/linkButton";


interface IFormResponseReadOnlyStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getFormResponseByUserToken: Server<SurewayAPIResponse<IFormResponseByUserToken>>;
};

interface IFormResponseReadOnlyDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getFormResponseByUserTokenRequest: (request: IGetFormResponseByUserTokenRequest) => unknown;
};


interface IFormResponseReadOnlyOwnProps {

};

interface IFormResponseReadOnlyState {
    data: IFormResponseByUserToken | undefined;
    hazardListExpand: boolean;
    crewExpand: boolean;
    guestExpand: boolean;
    signatureExpand: boolean;
    // signature: string;
    // name: string;
    // acknowledge: boolean;
    serverError: string;
};

const FormResponseReadOnlyStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .red-text {
        color: ${RED_COLOR};
    };

    .even-row {
        background-color: #F5F5F5;
        border: 1.5px solid ${BLACK_COLOR};
    }
    
    .odd-row {
        background-color: #dddddd;
        border: 1.5px solid ${BLACK_COLOR};
    };

    .pull-right {
        display: flex;
        justify-content: flex-end;
    };

    .lowRisk {
        color: ${GREEN_COLOR};
    }
    .mediumRisk {
        color: ${BLUE_COLOR};
    }
    .highRisk {
        color: ${RED_COLOR};
    }

    .hazard {
        background-color: ${EXTRA_LIGHT_ORANGE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .signature-container {
        padding: 5px;
        display: inline-block;
    }

    .signCanvas {
        border: 1px solid #ccc;
        width: 100%;
        max-width: 450px; /* Limit the maximum width */
        height: 200px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };
    };
`;


type IFormResponseReadOnlyProps = RouteComponentProps
    & IFormResponseReadOnlyStoreProps
    & IFormResponseReadOnlyDispatchProps
    & IFormResponseReadOnlyOwnProps;

class FormResponseReadOnly extends PureComponent<IFormResponseReadOnlyProps, IFormResponseReadOnlyState> {
    signCanvas: React.RefObject<any>;
    public constructor(props: IFormResponseReadOnlyProps) {
        super(props);
        this.signCanvas = React.createRef();
        this.state = {
            data: undefined,
            // signature: "",
            // name: "",
            hazardListExpand: true,
            crewExpand: true,
            guestExpand: true,
            signatureExpand: true,
            serverError: "",
            // acknowledge: false,
        };
    }

    public componentDidMount(): void {
        this.getData();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IFormResponseReadOnlyProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if(hasPayload(this.props.getFormResponseByUserToken) && this.props.getFormResponseByUserToken.payload.message !== SUCCESS_MESSAGE) {
                this.setState({ serverError: this.props.getFormResponseByUserToken.payload.message });
            }
        }
    };


    public render(): ReactNode {

        const { getFormResponseByUserToken } = this.props;
        const { data, hazardListExpand, crewExpand, guestExpand, signatureExpand, serverError} = this.state;
        const query: any = queryString.parse(this.props.location.search);
        // const disableSaveForGuests = (query.token === undefined && this.state.name.length === 0) ? true : undefined;
        // const onFormUpdated = (checked: boolean): void => this.handleAcknowledge("form_Updated", checked ? "Yes" : "No");
        // console.log(this.state)

        if(getFormResponseByUserToken.kind === STATUS_ENUM.LOADING) {
            <LACenteredLoading message="Please wait... Loading data" />
        }
        if(getFormResponseByUserToken.kind === STATUS_ENUM.FAILED) {
            return <LACenteredLoading message="Failed to load data..." />
        }
        if(serverError && serverError.length > ZEROTH) {
            return <LAErrorBox text={serverError} />
        }

        return (
            <PageSpacing title="Form Response" description="Form Response" fixedSpaceOnSmallerScreens={true}>
                <FormResponseReadOnlyStyles>
                    <LAGrid>
                         <LAGridItem xs={12}>
                            <LAGridItem xs={12}  className="pull-right">
                                <LALinkButton onClick={() => this.handleDownloadPDF(data)} label="Download PDF"></LALinkButton>
                            </LAGridItem>
                            
                            <LAGridItem xs={12} className="text-center">
                                <h2>{data?.form_Name ?? ""}</h2>
                                {/* {data?.form_Updated === "Yes" && <strong className="red-text">This form has been updated by foreman. Please review and sign again.</strong>} */}
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                 <LAGrid>
                                    {/* {query.token && <LAGridItem xs={12} >
                                        <strong>Employee Name: {data?.emp_Name ?? ""}</strong>
                                    </LAGridItem>} */}
                                    <LAGridItem xs={12} >
                                        <strong>Working Site: {data?.site ?? ""}</strong>
                                    </LAGridItem>
                                    {data?.work_Description && <LAGridItem xs={12} >
                                        <strong>Work Description: {data?.work_Description ?? ""}</strong>
                                    </LAGridItem>}
                                    <LAGridItem xs={12} >
                                        <strong>Foreman/Supervisor: {data?.supervisor ?? ""}</strong>
                                    </LAGridItem>
                                    {data?.urgent_Care_Facility && <LAGridItem xs={12} >
                                        <strong>Urgent Care Facility: {data?.urgent_Care_Facility ?? ""}</strong>
                                    </LAGridItem>}
                                    {data?.muster_Point &&<LAGridItem xs={12} >
                                        <strong>Muster Point: {data?.muster_Point ?? ""}</strong>
                                    </LAGridItem>}
                                    <LAGridItem xs={12} >
                                        <strong>Date: {data && data.date ? getDate(false,data.date) : ""}</strong>
                                    </LAGridItem>
                                    {data?.tool_Box_Meeting && <LAGridItem xs={12} >
                                        <strong>Tool Box Meeting: {data?.tool_Box_Meeting ?? ""}</strong>
                                    </LAGridItem>}
                                    {data?.form_Documents && <LAGridItem xs={12} sm={6} md={3}>
                                        <strong>Form Documents:</strong> <br />
                                        {data?.form_Documents.split(";").map((x: string) => 
                                            <>
                                                <LALinkButton
                                                    label={x}
                                                    onClick={() => this.downloadFormAttachmentEndpoint(x, data.form_ID, "Template")}
                                                />
                                                <br/>
                                            </>
                                            
                                        )}
                                    </LAGridItem>}
                                    {data?.additional_Images && <LAGridItem xs={12}>
                                        <strong>Additional Images:</strong> <br />
                                        <LAGrid>
                                            {data?.additional_Images.split(",").map((img: string, imageIdx: number) => {
                                                return <LAGridItem xs={6} key={imageIdx}>
                                                    <LAThumbnailWithLink
                                                        alt={img}
                                                        key={imageIdx}
                                                        url={webConfig.imgApiBaseUrl + img}
                                                        id={(imageIdx + "_").toString()}
                                                    />
                                                </LAGridItem>
                                        })}
                                        </LAGrid>
                                    </LAGridItem>}
                                    {data?.attachments && <LAGridItem xs={12}>
                                        <strong>Additional Files:</strong> <br />
                                        {data.attachments.split(";").map((x: string) => 
                                            <>
                                                <LALinkButton
                                                    label={x}
                                                    onClick={() => this.downloadFormAttachmentEndpoint(x, Number(query.id), "PSI")}
                                                />
                                                <br/>
                                            </>
                                            
                                        )}
                                    </LAGridItem>}
                                </LAGrid>
                            </LAGridItem>

                            {data && data.responses && data.responses.length > 0 && data.responses.map((x:any, idx: number) => {
                                return <LAExpansionPanel className="mt-2" color={GREEN_COLOR} textColor={WHITE_COLOR} label={x.section} expanded={x.expand} onClick={() => this.handleExpand(idx)}> 
                                    <LAGridItem xs={12} key={idx}>
                                        {x.questions && x.questions.length > 0 && x.questions.sort((a: any, b: any) => a.display_Order - b.display_Order).map((q:any, qIdx: number) => {
                                            return <LAGrid key={qIdx}> 
                                                <LAGridItem xs={12} className="mt-1" key={qIdx}>
                                                    <LAPaperWithLessPadding className={idx % 2 ? "odd-row" : "even-row"} key={qIdx}>
                                                        <LAGridItem xs={12} >
                                                            <strong>{qIdx + 1}: {q.question} - {q.answer}</strong>
                                                        </LAGridItem>
                                                        {q.comments && <LAGridItem xs={12}>
                                                            <strong>Comments: {q.comments}</strong>
                                                        </LAGridItem>}
                                                        {q.images && <LAGridItem xs={12}>
                                                            <LAGrid>
                                                                {q.images.split(",").map((img: string, imageIdx: number) => {
                                                                    return <LAGridItem xs={6}>
                                                                        <LAThumbnailWithLink
                                                                            alt={img}
                                                                            key={imageIdx}
                                                                            url={webConfig.imgApiBaseUrl + img}
                                                                            id={(imageIdx + "_").toString()}
                                                                        />
                                                                    </LAGridItem>
                                                            })}
                                                            </LAGrid>
                                                        </LAGridItem>}
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            </LAGrid>
                                        })}
                                    </LAGridItem>
                                </LAExpansionPanel>
                            })}

                            {data?.hazard_List && data?.hazard_List.length > 0 && <LAExpansionPanel className="mt-3" color={ORANGE_COLOR} textColor={WHITE_COLOR} label={"Hazard Assessment"} expanded={hazardListExpand} onClick={() => this.handleSectionExpand("hazardListExpand")}>
                                <LAGridItem xs={12}>
                                    <LAGrid>
                                        <LAGridItem xs={12} >
                                            <strong>Department: {data?.department ?? ""}</strong>
                                        </LAGridItem>
                                        <LAGridItem xs={12} >
                                            <strong>Tasks: {data?.tasks ?? ""}</strong>
                                        </LAGridItem>
                                        
                                        {data?.hazard_List && data?.hazard_List.length > 0 && data?.hazard_List.map((h: IFormResponseByUserTokenHazardList, hazardIndex: number) => {
                                            const riskResult = this.getRiskResult(h.probability, h.severity);
                                            return <LAGridItem xs={12} key={hazardIndex}>
                                                <LAPaperWithLessPadding className={"hazard"} key={hazardIndex}>
                                                    <LAGrid key={hazardIndex}>
                                                        <LAGridItem xs={6} >
                                                            <img alt={h.hazard_Type} src={`${END_POINTS.IMG_DIRECTORY}${h.image}`} width={20} height={20} />
                                                        </LAGridItem>
                                                        <LAGridItem xs={6} className="pull-right">
                                                            <strong className={riskResult==="Low" ? "lowRisk" : riskResult==="Medium" ? "mediumRisk" : riskResult==="High" ? "highRisk" : ""}>Risk: {riskResult}</strong>
                                                            </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard Type: {h.hazard_Type}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Hazard: {h.hazard}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Probability: {h.probability}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Severity: {h.severity}</strong>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <strong>Control Plans: {h.control_Plans}</strong>
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })}
                                    </LAGrid>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            {data?.crews && data?.crews.length > 0 && <LAExpansionPanel className="mt-3" color={RED_COLOR} textColor={WHITE_COLOR} label="Crews" expanded={crewExpand} onClick={() => this.handleSectionExpand("crewExpand")}>
                                <LAGridItem xs={12}>
                                    <LAGrid>
                                        {data.crews.map((c: any, crewIndex: number) => {
                                            return <LAGridItem xs={12} sm={6} md={6} lg={3} key={crewIndex}>
                                                <LAPaperWithLessPadding key={crewIndex}>
                                                    <strong>{c.name}</strong><br />
                                                    {c.signature ? <>
                                                        <img src={base64ToImageUrl(c.signature)} alt="signature" /><br />
                                                        {c.last_Signed_Date && <strong>Last Signed at : {new Date(c.last_Signed_Date).toLocaleString()}</strong>}
                                                    </>
                                                    : <h5 className="text-center red-text">No Signature added.</h5>}
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })}
                                    </LAGrid>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            {data?.guests && data?.guests.length > 0 && <LAExpansionPanel className="mt-3" color={DARK_ORANGE_COLOR} textColor={WHITE_COLOR} label="Subcontractors/Guests" expanded={guestExpand} onClick={() => this.handleSectionExpand("guestExpand")}>
                                <LAGridItem xs={12}>
                                    <LAGrid>
                                        {data.guests.map((g: any, guestIndex: number) => {
                                            return <LAGridItem xs={12} sm={6} md={6} lg={3} key={guestIndex}>
                                                <LAPaperWithLessPadding key={guestIndex}>
                                                    <strong>{g.name}</strong><br />
                                                    {g.signature ? <>
                                                        <img src={base64ToImageUrl(g.signature)} alt="signature" /><br />
                                                        {g.last_Signed_Date && <strong>Last Signed at : {new Date(g.last_Signed_Date).toLocaleString()}</strong>}
                                                    </>
                                                    : <h5 className="text-center red-text">No Signature added.</h5>}
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        })}
                                    </LAGrid>
                                </LAGridItem>
                            </LAExpansionPanel>}

                            <LAExpansionPanel className="mt-3" color={BLUE_COLOR} textColor={WHITE_COLOR} label={data?.form_Name === "Pre-Job Safety Inspection" ? "Foreman/Supervisor Signature" :"Signature"} expanded={signatureExpand} onClick={() => this.handleSectionExpand("signatureExpand")}>
                                <LAGridItem xs={12}> 
                                    {data?.signature && (
                                        <div>
                                            <img src={base64ToImageUrl(data?.signature)} alt="signature" />
                                        </div>
                                    )}
                                    {data?.signature_Date && <strong>{data.foreman_Signed} Signed at : {new Date(data?.signature_Date).toLocaleString()}</strong> }
                                </LAGridItem>
                            </LAExpansionPanel>

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            <LAGridItem className="mt-3 text-center" xs={12} sm={12} md={6}>
                                <LARedButton 
                                    label="Close" 
                                    disabled={undefined} 
                                    fullWidth={true}
                                    onClick={this.handleCancel} 
                                />
                            </LAGridItem>
                        </LAGridItem>
                    </LAGrid>
                </FormResponseReadOnlyStyles>
            </PageSpacing>
        );
    }

    

    private handleDownloadPDF = async(data: any): Promise<void> => {
        const query: any = queryString.parse(this.props.location.search);
        if (hasPayload(this.props.getToken)) {
            await fetch(END_POINTS.FIELD.FORMS.DOWNLOAD_FORM_RESPONSE_PDF, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    request: {
                        "id": Number(query.id),
                        "token": this.props.getToken.payload.response.mobileToken
                    },
                    token: this.props.getToken.payload.response.token
                })
            })
            .then((res: any) => {
                return res.blob();
            })
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `${data.form_Name}_${Number(query.id)}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((res) => console.log(res));
        };
    }

    private downloadFormAttachmentEndpoint = async (fileName: string, id: number, page: string): Promise<void> => {
        if (fileName !== null) {
            // const query: any = queryString.parse(this.props.location.search);
            if (hasPayload(this.props.getToken)) {
                await fetch(END_POINTS.FIELD.DOWNLOAD_FORM_ATTACHMENT, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            File_Name: fileName,
                            ID: id,
                            Page: page
                        },
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX
        });
    };

    private handleSectionExpand = (name: keyof IFormResponseReadOnlyState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    private handleExpand = (sectionIndex: number) => {
        const iS = { ...this.state.data };
        if(iS.responses && this.state.data) {
            let response = {
                ...iS.responses[sectionIndex]
            };
            response.expand =!response.expand;
    
            iS.responses[sectionIndex] = response;
    
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    responses: iS.responses,
                }
            });
        }
    };

    private getRiskResult = (probability: string, severity: string) : string => {
        let risk = "";

        if (probability === severity) {
            risk = probability;
        } 
        else if ((probability === "Medium" && severity === "Low") || (probability === "Low" && severity === "Medium")) {
            risk = "Low";
        } 
        else if ((probability === "Low" && severity === "High") || (probability === "High" && severity === "Low")) {
            risk = "Medium";
        }
        else if ((probability === "High" && severity === "Medium") || (probability === "Medium" && severity === "High")) {
            risk = "High";
        } 

        return risk;
    }

    // private handleChange = (name: string, value: string) => {
    //     this.setState((prevState) => ({
    //         ...prevState,               
    //         [name]: value
    //     }));
    // };

    private clearSignature = (): void => {
        this.signCanvas.current.clear();
    };

    private saveSignature = () => {
        const dataUrl = this.signCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        if(this.state.data) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    signature: dataUrl
                }
            });
            this.clearSignature();
        }
    };

    private groupedQuestionsBySection = (questions: any) : any => {
        // console.log(questions)
        return questions.reduce((result: any, question: any) => {
            const section = question.section ? question.section.trim() : "Questions";
            let sectionGroup = result.find((group: any) => group.section === section);
        
            if (!sectionGroup) {
                // If the section group doesn't exist, create it
                sectionGroup = { section: section, expand: true, questions: [] };
                result.push(sectionGroup);
            }
        
            // Add the current question to the section group
            sectionGroup.questions.push(question);
            return result;
        }, []);
    }

    private callServer = (): void => {
        const query: any = queryString.parse(this.props.location.search);

         if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (query !== undefined && query.id !== undefined) {
            if(hasPayload(this.props.getFormResponseByUserToken) && hasPayload(this.props.getToken)) {
                const data = this.props.getFormResponseByUserToken.payload.response;
                // console.log(data)
                if(data) {

                    const groupedQuestionsBySection = this.groupedQuestionsBySection(data.responses);
                   
                    this.setState({
                        ...this.state,
                        data: {
                            ...data,
                            responses: groupedQuestionsBySection,
                        },
                    });
                }
            }
            if (isNotLoaded(this.props.getFormResponseByUserToken)) {
                this.getData();
            }
        } else {
            this.props.history.push(ROUTE.ACCESS_DENIED);
        };
    };

    private getData = (): void => {
        const query: any = queryString.parse(this.props.location.search);
    
        if (hasPayload(this.props.getToken)) {
            if (query !== undefined && query.id !== undefined) {
                this.props.getFormResponseByUserTokenRequest({
                    token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
                    request: {
                        id : Number(query.id),
                        token : this.props.getToken.payload.response.mobileToken,
                        mode: "view"
                    }
                });
            }
        }
    }

}

const mapStateToProps = (state: IStore): IFormResponseReadOnlyStoreProps => ({
    getToken: getToken(state),
    getFormResponseByUserToken: getFormResponseByUserTokenStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IFormResponseReadOnlyDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getFormResponseByUserTokenRequest: (request: IGetFormResponseByUserTokenRequest) => dispatch(getFormResponseByUserTokenLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormResponseReadOnly);