import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_INSPECTION_SHEETS_REQUEST, IGetUnitInspectionSheets, IGetUnitInspectionSheetsRequest } from "./getUnitInspectionSheetsConstants";

export interface IGetUnitInspectionSheetsLoadAction {
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.REQUEST;
    data: IGetUnitInspectionSheetsRequest
}
export const getUnitInspectionSheetsLoadAction = (data: IGetUnitInspectionSheetsRequest): IGetUnitInspectionSheetsLoadAction => ({
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.REQUEST,
    data
});
export interface IGetUnitInspectionSheetsSuccessAction {
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetUnitInspectionSheets[]>;
}
export const getUnitInspectionSheetsLoadSuccessAction = (list: SurewayAPIResponse<IGetUnitInspectionSheets[]>): IGetUnitInspectionSheetsSuccessAction => ({
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.SUCCESS,
    list
});
export interface IGetUnitInspectionSheetsLoadFailedAction {
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.FAILED;
    message: string;
}
export const getUnitInspectionSheetsLoadFailedAction = (message: string): IGetUnitInspectionSheetsLoadFailedAction => ({
    type: IGET_UNIT_INSPECTION_SHEETS_REQUEST.FAILED,
    message
});
