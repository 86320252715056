import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SOCT_REPORT_REQUEST, ISoctReport } from "./getSoctReportConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSoctReportLoadAction, IGetSoctReportLoadFailedAction, IGetSoctReportSuccessAction, 
    getSoctReportLoadFailedAction, getSoctReportLoadSuccessAction } from "./getSoctReportActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSoctReportEpic: Epic = (
    action$: ActionsObservable<IGetSoctReportLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSoctReportSuccessAction | IGetSoctReportLoadFailedAction> =>
    action$.ofType(IGET_SOCT_REPORT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<ISoctReport>>>(
                    END_POINTS.FIELD.SCOT.GetReport,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<ISoctReport>>): IGetSoctReportSuccessAction => {
                            return getSoctReportLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getSoctReportLoadFailedAction(response)))
                    )
            )
        );