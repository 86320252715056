import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_CLEANUP_DATA_REQUEST, IGetCleanupDataRequest, IGetCleanupData } from "./getCleanupDataConstants";

export interface IGetCleanupDataLoadAction {
    type: IGET_CLEANUP_DATA_REQUEST.REQUEST;
    data: IGetCleanupDataRequest
}
export const getCleanupDataLoadAction = (data: IGetCleanupDataRequest): IGetCleanupDataLoadAction => ({
    type: IGET_CLEANUP_DATA_REQUEST.REQUEST,
    data
});
export interface IGetCleanupDataSuccessAction {
    type: IGET_CLEANUP_DATA_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetCleanupData>;
}
export const getCleanupDataLoadSuccessAction = (list: SurewayAPIResponse<IGetCleanupData>): IGetCleanupDataSuccessAction => ({
    type: IGET_CLEANUP_DATA_REQUEST.SUCCESS,
    list
});
export interface IGetCleanupDataLoadFailedAction {
    type: IGET_CLEANUP_DATA_REQUEST.FAILED;
    message: string;
}
export const getCleanupDataLoadFailedAction = (message: string): IGetCleanupDataLoadFailedAction => ({
    type: IGET_CLEANUP_DATA_REQUEST.FAILED,
    message
});
