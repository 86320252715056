import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_SERVICE_DUE_REQUEST, IAddServiceDueRequest } from "./addServiceDueConstants";


export interface IAddServiceDueLoadAction {
    type: IADD_SERVICE_DUE_REQUEST.REQUEST;
    data: IAddServiceDueRequest
}
export const addServiceDueLoadAction = (data: IAddServiceDueRequest): IAddServiceDueLoadAction => ({
    type: IADD_SERVICE_DUE_REQUEST.REQUEST,
    data
});
export interface IAddServiceDueSuccessAction {
    type: IADD_SERVICE_DUE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addServiceDueLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddServiceDueSuccessAction => ({
    type: IADD_SERVICE_DUE_REQUEST.SUCCESS,
    message
});
export interface IAddServiceDueLoadFailedAction {
    type: IADD_SERVICE_DUE_REQUEST.FAILED;
    message: string;
}
export const addServiceDueLoadFailedAction = (message: string): IAddServiceDueLoadFailedAction => ({
    type: IADD_SERVICE_DUE_REQUEST.FAILED,
    message
});
