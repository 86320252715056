import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST, IPayrollMechanicLogsUploadRequest } from "./payrollMechanicLogsUploadConstants";

export interface IPayrollMechanicLogsUploadLoadAction {
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.REQUEST;
    data: IPayrollMechanicLogsUploadRequest
}
export const payrollMechanicLogsUploadLoadAction = (data: IPayrollMechanicLogsUploadRequest): IPayrollMechanicLogsUploadLoadAction => ({
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.REQUEST,
    data
});
export interface IPayrollMechanicLogsUploadSuccessAction {
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const payrollMechanicLogsUploadLoadSuccessAction = (message: SurewayAPIResponse<string>): IPayrollMechanicLogsUploadSuccessAction => ({
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.SUCCESS,
    message
});
export interface IPayrollMechanicLogsUploadLoadFailedAction {
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.FAILED;
    message: string;
}
export const payrollMechanicLogsUploadLoadFailedAction = (message: string): IPayrollMechanicLogsUploadLoadFailedAction => ({
    type: IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.FAILED,
    message
});
