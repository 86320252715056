import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_MACHINE_CONTROL_REQUEST, IAddUpdateMachineControlRequest } from "./addMachineControlConstants";

export interface IAddMachineControlLoadAction {
    type: IADD_MACHINE_CONTROL_REQUEST.REQUEST;
    data: IAddUpdateMachineControlRequest
}
export const addMachineControlLoadAction = (data: IAddUpdateMachineControlRequest): IAddMachineControlLoadAction => ({
    type: IADD_MACHINE_CONTROL_REQUEST.REQUEST,
    data
});
export interface IAddMachineControlSuccessAction {
    type: IADD_MACHINE_CONTROL_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addMachineControlLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddMachineControlSuccessAction => ({
    type: IADD_MACHINE_CONTROL_REQUEST.SUCCESS,
    list
});
export interface IAddMachineControlLoadFailedAction {
    type: IADD_MACHINE_CONTROL_REQUEST.FAILED;
    message: string;
}
export const addMachineControlLoadFailedAction = (message: string): IAddMachineControlLoadFailedAction => ({
    type: IADD_MACHINE_CONTROL_REQUEST.FAILED,
    message
});
