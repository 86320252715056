import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString,  deleteText,  fileToBase64Image,  getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon, DeleteIcon } from "../../../shared/icons";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../../shared/checkBox";
import { LACenteredLoading } from "../../../shared/loading";
import { IGetHazardTypes, IGetHazardTypesRequest } from "../../../../redux/field/getHazardTypes/getHazardTypesConstants";
import { getHazardTypesLoadAction } from "../../../../redux/field/getHazardTypes/getHazardTypesActions";
import { getHazardTypes } from "../../../../redux/field/getHazardTypes/getHazardTypesAccessor";
import { IAddHazardTypeRequest, IAddUpdateHazardType } from "../../../../redux/field/addHazardType/addHazardTypeConstants";
import { addHazardTypeLoadAction } from "../../../../redux/field/addHazardType/addHazardTypeActions";
import { addHazardTypeStatus } from "../../../../redux/field/addHazardType/addHazardTypeAccessor";
import { IUpdateHazardTypeRequest } from "../../../../redux/field/updateHazardType/updateHazardTypeConstants";
import { updateHazardTypeStatus } from "../../../../redux/field/updateHazardType/updateHazardTypeAccessor";
import { updateHazardTypeLoadAction } from "../../../../redux/field/updateHazardType/updateHazardTypeActions";
import { LADropZone } from "../../../shared/dropZone";
import { LAThumbnailWithLink } from "../../../shared/thumbnail";
import { webConfig } from "../../../../utils/webConfig";
import { v4 as uuidv4 } from "uuid";

const RequiredFields: string[] = ["name"];


interface IAddUpdateHazardTypeComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    // getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    getHazardTypes: Server<SurewayAPIResponse<ById<IGetHazardTypes>>>;
    addHazardTypeStatus: Server<SurewayAPIResponse<string>>;
    updateHazardTypeStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateHazardTypeComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getHazardTypesRequest: (data: IGetHazardTypesRequest) => unknown
    addHazardTypeRequest: (data: IAddHazardTypeRequest) => unknown;
    updateHazardTypeRequest: (data: IUpdateHazardTypeRequest) => unknown;
};

interface IAddUpdateHazardTypeOwnProps {
    id?: string;
};

interface IAddUpdateHazardTypeComponentState {
    data: IGetHazardTypes;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateHazardTypeStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateHazardTypeComponentProps =
    RouteComponentProps
    & IAddUpdateHazardTypeOwnProps
    & IAddUpdateHazardTypeComponentStoreProps
    & IAddUpdateHazardTypeComponentDispatchProps;

class AddUpdateHazardType extends PureComponent<IAddUpdateHazardTypeComponentProps, IAddUpdateHazardTypeComponentState> {

    public constructor(props: IAddUpdateHazardTypeComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                sort_Order: 0,
                active: "Yes",
                picture: undefined,
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateHazardTypeComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addHazardTypeStatus !== prevProps.addHazardTypeStatus) {

                if (this.props.addHazardTypeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addHazardTypeStatus.message });

                if (hasPayload(this.props.addHazardTypeStatus) && this.props.addHazardTypeStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateHazardTypeStatus !== prevProps.updateHazardTypeStatus) {

                if (this.props.updateHazardTypeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateHazardTypeStatus.message });

                if (hasPayload(this.props.updateHazardTypeStatus) && this.props.updateHazardTypeStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = data.id > 0 ? "Update HAZARD TYPE" : "Add HAZARD TYPE";
        const { token, addHazardTypeStatus, updateHazardTypeStatus } = this.props;
        const getRole = pageAccessCheck(token, "formsAdmin");
        const disabled = (getRole === false) ? true : undefined;
        // const disabled =  undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");
        const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value);
        const imageBase64 = data.picture && (data.picture.base64String !== null && data.picture.base64String !== "" ? data.picture.base64String : webConfig.imgApiBaseUrl + data.picture.name);
        // console.log(data)

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateHazardTypeStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}HAZARD TYPE</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            name="name"
                                            label="Name"
                                            fullWidth={true}
                                            disabled={disabled || data.id > 0}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            label="Sort Order"
                                            fullWidth={true}
                                            variant="outlined"
                                            name="sort_Order"
                                            value={data.sort_Order}
                                            type="number"
                                            disabled={disabled}
                                            onChange={this.handleChange}
                                            errorText={errors["sort_Order"] ? errors["sort_Order"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            <LAGridItem xs={12}  className="text-center">
                                <strong className="text-center">Upload Image</strong>
                                <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e)} />
                                <br />
                                <LAGrid>
                                    {data.picture &&
                                        <LAGridItem xs={12} sm={6} md={4} key={data.picture.name} className="text-center">
                                            <LAPaperWithLessPadding key={data.picture.name}>
                                                <LAThumbnailWithLink
                                                    alt={data.picture.name}
                                                    key={data.picture.name}
                                                    url={imageBase64 ?? ""}
                                                    id={(data.picture.name + "_").toString()}
                                                />

                                                <LATextField
                                                    className="mt-2"
                                                    label="Description"
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    name="description"
                                                    disabled={undefined}
                                                    value={data.picture.description}
                                                    onChange={onImageName}
                                                    errorText={
                                                        data.picture.description.length === 0 ?
                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                            : undefined
                                                    }
                                                />

                                                {/* {data.picture.timestamp && 
                                                    <strong>{new Date(data.picture.timestamp).toLocaleString()}</strong>
                                                } */}

                                                <LAIconButton
                                                    key={data.picture.name}
                                                    label="Delete"
                                                    disabled={undefined}
                                                    onClick={this.onImageDelete}
                                                    icon={<DeleteIcon />}
                                                />
                                            </LAPaperWithLessPadding>
                                        </LAGridItem>
                                    }
                                </LAGrid>
                            </LAGridItem>
                            

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }

                    <RequestStatus requestStatus={addHazardTypeStatus.kind} successMessage="HazardType has been added successfully" />
                    <RequestStatus requestStatus={updateHazardTypeStatus.kind} successMessage="HazardType has been updated successfully" />

                </AddUpdateHazardTypeStyles>
            </PageSpacing>
        );
    }

    private handleImageUpload = async (event: any): Promise<void> => {

        const files = event;
        let popupImg = this.state.data.picture;
        // const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImg = {
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0],
                        base64String,
                        // timestamp: new Date().toLocaleString()
                    }

                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            picture: popupImg
                        }
                    });
                
                }
            }));
        }
    };

    private handleImageName = (name: string, value: string): void => {
        let popupImg = this.state.data.picture && this.state.data.picture;

        if(popupImg) {
            popupImg = {
                ...popupImg,
                [name]: value
            }
    
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    picture: popupImg
                }
            });
        }
    };

    private onImageDelete = (): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    picture: undefined
                }
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            let request: IAddUpdateHazardType = {
                ID: data.id,
                Name: data.name.trim(),
                Sort_Order: Number(data.sort_Order),
                Active: data.active,
                Picture: data.picture,
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };
            // console.log(request)
            if (data.id === 0) {
                this.props.addHazardTypeRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateHazardTypeRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {

                    if (hasPayload(this.props.getHazardTypes)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getHazardTypes.payload.response[+query.id];

                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getHazardTypesRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateHazardTypeComponentStoreProps => ({
    token: getToken(state),
    // getLookup: getFormLookup(state),
    getHazardTypes: getHazardTypes(state),
    addHazardTypeStatus: addHazardTypeStatus(state),
    updateHazardTypeStatus: updateHazardTypeStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateHazardTypeComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getHazardTypesRequest: (data: IGetHazardTypesRequest) => dispatch(getHazardTypesLoadAction(data)),
    addHazardTypeRequest: (data: IAddHazardTypeRequest) => dispatch(addHazardTypeLoadAction(data)),
    updateHazardTypeRequest: (data: IUpdateHazardTypeRequest) => dispatch(updateHazardTypeLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateHazardType);