import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST {
    REQUEST = "getSTTimesheetForApprovalGroupByDate/GET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST",
    SUCCESS = "getSTTimesheetForApprovalGroupByDate/GET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_SUCCESS",
    FAILED = "getSTTimesheetForApprovalGroupByDate/GET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_FAILED"
};

export interface IGetSTTimesheetForApprovalGroupByDateRequest extends ISurewayTokenRequestBody {
    // request: {
    //     Manager_Type: string;
    // }
};


export interface ISTTimesheetForApprovalGroupByDate {
    date: string;
    drivers: IDriversLogsInfo[];
    expand?: boolean;
}

export interface IDriversLogsInfo {
    timesheet_ID: number;
    driver_ID: number;
    name: string;
    email: string;
    driver_UPN: string;
    lunch: string;
    start_Time: string;
    end_Time: string;
    logs: ISTLog[];
    expand?: boolean;
    view?: string;
    reject_Comments?: string;
}

export interface ISTLog {
  id: number;
  display: string;
  defect_ID: number;
  defect_Item: string;
  hours: number;
  comments: string;
  status: string;
  component_Code: string;
  manager_Instructions: string;
//   repair_Location: string;
//   charge_Location: string;
//   loa: string;
  type: string;
//   travel_Type: string;
  // misc_Hours: number;
//   billable: string;
//   billable_Percentage: number | null;
  job_No: string;
  cost_Code: string;
  work_Order_No: string;
  defect_Status: string;
  defect_Type: string;
//   item_Attachment_ID: number;
  meter_Reading: number;
//   major_Component_Replacement: string;
//   major_Type?: string;
//   component_Repaired_Location?: string;
//   oeM_Part?: string;
//   vendor?: string;
//   exchange_Type?: string;
//   condition?: string;
//   warranty_Details?: string;
//   beR_Details?: string;
//   serial_No: string;
//   major_Component_Replacement_Type: string;
//   major_Component_Replacement_Hours: number;
//   major_Component_Replacement_Notes: string;
//   new_Major_Component_Replacement_Notes: string;
//   parts_Delay_Hours: number;
//   parts_Delay_Comments: string;
  unit_ID: number;
  unit_Status?: string;
  site: string | null;
}