import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_SHOP_EQUIPMENTS_REQUEST, IShopEquipment, IShopEquipmentRequest } from "./getEquipmentConstants";

export interface IGetShopEquipmentsLoadAction {
    type: IGET_SHOP_EQUIPMENTS_REQUEST.REQUEST;
    data: IShopEquipmentRequest
}
export const getShopEquipmentsLoadAction = (data: IShopEquipmentRequest): IGetShopEquipmentsLoadAction => ({
    type: IGET_SHOP_EQUIPMENTS_REQUEST.REQUEST,
    data
});
export interface IGetShopEquipmentsSuccessAction {
    type: IGET_SHOP_EQUIPMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IShopEquipment>>;
}
export const getShopEquipmentsLoadSuccessAction = (list: SurewayAPIResponse<ById<IShopEquipment>>): IGetShopEquipmentsSuccessAction => ({
    type: IGET_SHOP_EQUIPMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetShopEquipmentsLoadFailedAction {
    type: IGET_SHOP_EQUIPMENTS_REQUEST.FAILED;
    message: string;
}
export const getShopEquipmentsLoadFailedAction = (message: string): IGetShopEquipmentsLoadFailedAction => ({
    type: IGET_SHOP_EQUIPMENTS_REQUEST.FAILED,
    message
});
