import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_MECHANIC_REQUEST, IUpdateMechanicRequest } from "./updateMechanicConstants";


export interface IUpdateMechanicLoadAction {
    type: IUPDATE_MECHANIC_REQUEST.REQUEST;
    data: IUpdateMechanicRequest
}
export const updateMechanicLoadAction = (data: IUpdateMechanicRequest): IUpdateMechanicLoadAction => ({
    type: IUPDATE_MECHANIC_REQUEST.REQUEST,
    data
});
export interface IUpdateMechanicSuccessAction {
    type: IUPDATE_MECHANIC_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateMechanicLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateMechanicSuccessAction => ({
    type: IUPDATE_MECHANIC_REQUEST.SUCCESS,
    message
});
export interface IUpdateMechanicLoadFailedAction {
    type: IUPDATE_MECHANIC_REQUEST.FAILED;
    message: string;
}
export const updateMechanicLoadFailedAction = (message: string): IUpdateMechanicLoadFailedAction => ({
    type: IUPDATE_MECHANIC_REQUEST.FAILED,
    message
});
