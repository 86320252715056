import React, { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { store } from "../../App";
import { IDispatch, IStore } from "../../redux/reducers";
import { hasPayload, Server } from "../../redux/server";
import { validateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ZEROTH } from "./constExports";
import LAErrorBox from "./errorBox";
import LALoading from "./loading";
import { HasChildren, ToolRentalsResponse } from "./publicInterfaces";

interface IRoleBasedAccessForShop extends HasChildren {
    roleFor: string[];
    error: boolean;
};


interface IRoleBasedAccessForShopDispatchProps {
}

interface IRoleBasedAccessForShopStoreProps {
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
}

interface IRoleBasedAccessForShopState {
    myRoles?: string;
    isLoading?: boolean;
}

type IRoleBasedAccessForShopProps =
    IRoleBasedAccessForShop &
    IRoleBasedAccessForShopDispatchProps &
    IRoleBasedAccessForShopStoreProps;

class RoleBasedAccessForShop extends PureComponent<IRoleBasedAccessForShopProps, IRoleBasedAccessForShopState> {


    public constructor(props: IRoleBasedAccessForShopProps) {
        super(props);
        this.state = {
            myRoles: "",
            isLoading: true
        };
    };

    public componentDidMount(): void {
        if (hasPayload(this.props.validateShopGroup) && this.state.myRoles?.length === ZEROTH) {
            this.setState({ myRoles: this.props.validateShopGroup.payload.response.adminAccess, isLoading: false });
        }
    }

    public componentDidUpdate(prevProps: IRoleBasedAccessForShopProps): void {
        if (hasPayload(this.props.validateShopGroup) && this.state.myRoles?.length === ZEROTH) {
            this.setState({ myRoles: this.props.validateShopGroup.payload.response.adminAccess, isLoading: false });
        }
    }

    public render(): ReactNode {
        const { myRoles, isLoading } = this.state;
        const { roleFor } = this.props;
        return (
            <>
                {isLoading === false ? myRoles !== undefined && (roleFor.includes(myRoles))
                    ?
                    <>
                        {this.props.children}
                    </>
                    :
                    this.props.error === true ? <LAErrorBox text="You do not have permission to view this page. Contact your system admin." /> : null
                    : <LALoading />
                }
            </>
        )
    }

};

const mapStateToProps = (state: IStore): IRoleBasedAccessForShopStoreProps => ({
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IRoleBasedAccessForShopDispatchProps => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccessForShop);



export const shopRoleCheck = (rolesFor: string[]): boolean => {
    const myRoles = store.getState().ValidateShopGroup;
    if (hasPayload(myRoles)) {
        return rolesFor.includes(myRoles.payload.response.adminAccess);
    }
    return false;
};