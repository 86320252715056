import { IWSSPicture } from "../getLocation/getLocationConstants";

export enum IADD_LOCATION_REQUEST {
    REQUEST = "addLocation/ADD_LOCATION_REQUEST",
    SUCCESS = "addLocation/ADD_LOCATION_SUCCESS",
    FAILED = "addLocation/ADD_LOCATION_FAILED"
};

export interface IAddLocationRequest {
    token: string;
    request: IAddUpdateLocation;
};

export interface IAddUpdateLocation {
    ID?: number;
    Site_Name: string;
    Site_Address: string;
    Notes: string;
    Nearest_Medical_Facility: string;
    Nearest_Medical_Facility_GPS: string;
    Active: string;          
    Created_By: string;
   Modified_By: string;
   Job_No: string;
   No_Job_Number_Required : string;
   Show_Sub_Sites_on_Map: string;
   Daily_Service_Truck_Required: string;
   Working_Sub_Sites: IAddUpdateSubLocation[];
};

export interface IAddUpdateSubLocation {
    ID?: number;
    Sub_Site_Name: string;
    Sub_Site_Address: string;
    Active: string;
    Latitude: string;
    Longitude: string;
    Notes: string;
    Muster_Point_GPS: string;
    Pictures: IWSSPicture[];
    Cost_Code_Billing: string;
    Muster_Point: string;
};