import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";
import { IRepairLinePicture } from "../../repairLine/getRepairLines/getRepairLinesConstants";

export enum IGET_TECH_SERVICES_REQUEST {
    REQUEST = "getTechServices/GET_TECH_SERVICES_REQUEST",
    SUCCESS = "getTechServices/GET_TECH_SERVICES_SUCCESS",
    FAILED = "getTechServices/GET_TECH_SERVICES_FAILED"
};

export interface IGetTechServicesRequest extends ISurewayTokenRequestBody {
    request: IGetTechServices;
};

export interface IGetTechServices {
    unit_id: number;
}

export interface ITechService {
    id: number;
    date: string;
    unit_ID: number | null;
    unit_Number?: string;
    make: string;
    model: string;
    technician: string;
    component_Code: string;
    defect_Item: string;
    operational_Complaint: string;
    tShooting_Procedure: string;
    repair_Improvement_Procedure: string;
    images: string;
    attachments: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    pictures: IRepairLinePicture[];
    files: ITechServiceFile[],
};


export interface ITechServiceFile {
    name: string;
    base64String: string;
};
