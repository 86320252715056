
export enum IGET_DRIVER_REQUEST {
    REQUEST = "getDriver/GET_DRIVER_REQUEST",
    SUCCESS = "getDriver/GET_DRIVER_SUCCESS",
    FAILED = "getDriver/GET_DRIVER_FAILED"
};

export interface IDriverRequest {
    token: string;
    request?: IDriverRequestBody
};

export interface IDriverRequestBody {
    PartsDispatch: string;
};

export interface IDriver {
    id: number;
    email: string;
    license: string;
    smS_Logs: boolean;
    phone_Number: string;
    driver_Lastname: string;
    driver_Firstname: string;
    pilot_Driver: "Yes" | "No";
    truck_Driver: "Yes" | "No";
    terminated_Date: string;
    active: string;
};