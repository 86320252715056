import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_PM_SHEET_VERSION_HISTORY_REQUEST, IGetPMSheetVersionHistory } from "./getPMSheetVersionHistoryConstants";
import { IGetPMSheetVersionHistoryLoadAction, IGetPMSheetVersionHistoryLoadFailedAction, IGetPMSheetVersionHistorySuccessAction } from "./getPMSheetVersionHistoryActions";


type Actions =
    | IGetPMSheetVersionHistoryLoadAction
    | IGetPMSheetVersionHistorySuccessAction
    | IGetPMSheetVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetPMSheetVersionHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetPMSheetVersionHistory>>> => {
    switch (action.type) {
        case IGET_PM_SHEET_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_PM_SHEET_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PM_SHEET_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};