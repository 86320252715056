import { IRepairLinePicture } from "../getRepairLines/getRepairLinesConstants";

export enum IADD_REPAIR_LINE_REQUEST {
    REQUEST = "addRepairLine/ADD_REPAIR_LINE_REQUEST",
    SUCCESS = "addRepairLine/ADD_REPAIR_LINE_SUCCESS",
    FAILED = "addRepairLine/ADD_REPAIR_LINE_FAILED"
};

export interface IAddRepairLineRequest {
    token: string;
    request: IAddUpdateRepairLine;
};

export interface IAddUpdateRepairLine {
    ID?: number; // Only for Add
    Unit_ID: number;
    Attachment_ID: number;
    Repair_Attachment: string;
    Meter_Hours?: number; 
    Initiated_Date?: string;
    Work_Order_No: string;    
    Assigned_Mech?: string;
    Status: string;
    Comments?: string;
    Manager_Instructions?: string;
    Estimated_Completion_Date?: string;
    date_Completed?: string;
    Send_Email?: string;
    Repair_Location?: string;
    Repair_Type?: string;
    Created_By?: string; // only for Add
    Modified_By?: string; // only for Update
    Items: IAddUpdateRepairLineItem[];
}

export interface IAddUpdateRepairLineItem {
    ID?: number // only for update
    Component?: string | null;
    Attachment_ID?: number;
    Description?: string;
    Foreman_Comments?: string;
    Priority: string;
    Status: string;
    Comments?: string;
    Images: string;
    Major_Component_Replacement?: string;
    Serial_No?: string;
    Type?: string;
    Hours?: number;
    Major_Component_Replacement_Notes?: string;
    New_Major_Component_Replacement_Notes?: string;
    Mob_Demob?: string;
    Damaged?: string;
    Damaged_Notes?: string;
    Warranty?: string;
    Component_Code: string;
    SocT_Tracking?: string;
    Meter_Reading?: number;
    work_Order_No?: string;
    Pictures: IRepairLinePicture[];
    Parts?: IAddUpdateRepairLineItemPart[];
    Defect_Item: string
    Issue: string;
    Repair_Type?: string;
    Assigned_Mech: string;
    Lube_Driver: string;
    Manager_Instructions: string;
    Parts_Instructions: string;
    Send_Email: string;
    Mechanic_Comments: string;
    Modified_By: string;
    Winter_Repair?: string;
    Repair_Location?: string;
};

export interface IAddUpdateRepairLineItemPart {
    ID?: number;
    Part_Name: string;
    Part_Number: string;
    Quantity: number;
    Driver: string;
    Status: string;
};