import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_WORKING_SITE_LOGS_REQUEST, IWorkingSiteLog } from "./getWorkingSiteLogsConstants";
import { IGetWorkingSiteLogsLoadAction, IGetWorkingSiteLogsLoadFailedAction, IGetWorkingSiteLogsSuccessAction, getWorkingSiteLogsLoadFailedAction, getWorkingSiteLogsLoadSuccessAction } from "./getWorkingSiteLogsActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getWorkingSiteLogsEpic: Epic = (
    action$: ActionsObservable<IGetWorkingSiteLogsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetWorkingSiteLogsSuccessAction | IGetWorkingSiteLogsLoadFailedAction> =>
    action$.ofType(IGET_WORKING_SITE_LOGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IWorkingSiteLog>>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.GET_WORKING_SITE_LOGS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IWorkingSiteLog>>): IGetWorkingSiteLogsSuccessAction => {
                            return getWorkingSiteLogsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getWorkingSiteLogsLoadFailedAction(response.message)))
                    )
            )
        );