import { IVALIDATE_OPERATOR_LOGIN_REQUEST, IValidateOperatorLoginRequest } from "./validateOperatorLoginConstants";

export interface IValidateOperatorLoginLoadAction {
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.REQUEST;
    data: IValidateOperatorLoginRequest
}
export const validateOperatorLoginLoadAction = (data: IValidateOperatorLoginRequest): IValidateOperatorLoginLoadAction => ({
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.REQUEST,
    data
});
export interface IValidateOperatorLoginSuccessAction {
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.SUCCESS;
    token: string;
    email: string;
}
export const validateOperatorLoginLoadSuccessAction = (token: string, email: string): IValidateOperatorLoginSuccessAction => ({
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.SUCCESS,
    token,
    email
});
export interface IValidateOperatorLoginLoadFailedAction {
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.FAILED;
    message: string;
}
export const validateOperatorLoginLoadFailedAction = (message: string): IValidateOperatorLoginLoadFailedAction => ({
    type: IVALIDATE_OPERATOR_LOGIN_REQUEST.FAILED,
    message
});
