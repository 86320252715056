import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_KANTECH_ADJUSTMENTS_REQUEST, IKantechAdjustment, IGetKantechAdjustmentRequest } from "./getKantechAdjustmentsConstants";

export interface IGetKantechAdjustmentsLoadAction {
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.REQUEST;
    data: IGetKantechAdjustmentRequest
}
export const getKantechAdjustmentsLoadAction = (data: IGetKantechAdjustmentRequest): IGetKantechAdjustmentsLoadAction => ({
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.REQUEST,
    data
});
export interface IGetKantechAdjustmentsSuccessAction {
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IKantechAdjustment>>;
}
export const getKantechAdjustmentsLoadSuccessAction = (list: SurewayAPIResponse<ById<IKantechAdjustment>>): IGetKantechAdjustmentsSuccessAction => ({
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetKantechAdjustmentsLoadFailedAction {
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.FAILED;
    message: string;
}
export const getKantechAdjustmentsLoadFailedAction = (message: string): IGetKantechAdjustmentsLoadFailedAction => ({
    type: IGET_KANTECH_ADJUSTMENTS_REQUEST.FAILED,
    message
});
