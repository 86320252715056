import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SOCT_BY_ID_REQUEST {
    REQUEST = "getSOCTById/GET_SOCT_BY_ID_REQUEST",
    SUCCESS = "getSOCTById/GET_SOCT_BY_ID_SUCCESS",
    FAILED = "getSOCTById/GET_SOCT_BY_ID_FAILED"
};

export interface IGetSOCTByIdRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
    };
};

export interface ISOCT {
  id: number;
  equipment_Type?: string;
  make?: string;
  model?: string;
  location?: string;
  meter_Date?: string;
  service_Date: string;
  current_Hours: number;
  last_EO: number;
  filter_Cuttings: string;
  next_100_Due?: number;
  next_250_Due?: number;
  next_500_Due?: number;
  next_1500_Due?: number;
  next_3000_Due?: number;
  last_100?: number;
  last_500?: number;
  last_1500?: number;
  last_3000?: number;
  machine_Hours?: number;
  truck?: string;
  comments: string;
  meter_Reset?: string;
  previous_Unit_Number?: string;
  ecm?: number;
  sub_Location?: string;
  sub_Location_ID?: number;
  working_Site_ID?: number;
  attachments?: string;
  status?: string;
  updated_By?: string;
  geotab_Sync: string;
  created_By: string;
  modified_By: string;
  created: string;
  modified: string;
  record_ID?: number;
  next_1000_Due?: number;
  last_1000?: number;
  equipment_List_ID?: number;
  unit_ID: number;
  custom_Service_Comments?: string;
  job_Number?: string;
  job_Name?: string;
  unit_Number?: string;
  next_ScheduleA_Due?: number;
  last_ScheduleA?: number;
  next_ScheduleB_Due?: null;
  last_ScheduleB?: number;
  next_ScheduleD_Due?: number;
  last_ScheduleD?: number;
  reading_Type?: string;
  reading_Type_Notes?: string;
  pM_Sheet_Comments?: string;
  pM_Sheet_Admin_Comments?: string;
  last_Service_Hours?: string;
  service_Hours?: string;
};