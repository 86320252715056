import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_LOCATION_REQUEST, IAddLocationRequest } from "./addLocationConstants";


export interface IAddLocationLoadAction {
    type: IADD_LOCATION_REQUEST.REQUEST;
    data: IAddLocationRequest
}
export const addLocationLoadAction = (data: IAddLocationRequest): IAddLocationLoadAction => ({
    type: IADD_LOCATION_REQUEST.REQUEST,
    data
});
export interface IAddLocationSuccessAction {
    type: IADD_LOCATION_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addLocationLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddLocationSuccessAction => ({
    type: IADD_LOCATION_REQUEST.SUCCESS,
    message
});
export interface IAddLocationLoadFailedAction {
    type: IADD_LOCATION_REQUEST.FAILED;
    message: string;
}
export const addLocationLoadFailedAction = (message: string): IAddLocationLoadFailedAction => ({
    type: IADD_LOCATION_REQUEST.FAILED,
    message
});
