import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById,  SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString, deleteText, fileToBase64Image, getTokenFromUrl, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { v4 as uuidv4 } from "uuid";
import { ArrowLeftIcon, DeleteIcon, DownloadIcon } from "../../../shared/icons";
import LAAutoComplete from "../../../shared/autoComplete";
import LATextArea from "../../../shared/textArea";
import { LADropZone } from "../../../shared/dropZone";
import { Marker } from "../../repairLine/repairLine";
import { LAThumbnailWithLink } from "../../../shared/thumbnail";
import { IRepairLinePicture } from "../../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { webConfig } from "../../../../utils/webConfig";
import splitDate from "../../../shared/splitDate";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { IFieldLookup, IFieldLookupRequest, IModel } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { IGetTechServicesRequest, ITechService, ITechServiceFile } from "../../../../redux/field/repairLine/getTechServices/getTechServicesConstants";
import { IAddTechService, IAddTechServiceRequest } from "../../../../redux/field/repairLine/addTechService/addTechServiceConstants";
import { IUpdateTechServiceRequest } from "../../../../redux/field/repairLine/updateTechService/updateTechServiceConstants";
import { getTechServices } from "../../../../redux/field/repairLine/getTechServices/getTechServicesAccessor";
import { addTechServiceStatus } from "../../../../redux/field/repairLine/addTechService/addTechServiceAccessor";
import { updateTechServiceStatus } from "../../../../redux/field/repairLine/updateTechService/updateTechServiceAccessor";
import { getTechServicesLoadAction } from "../../../../redux/field/repairLine/getTechServices/getTechServicesActions";
import { addTechServiceLoadAction } from "../../../../redux/field/repairLine/addTechService/addTechServiceActions";
import { updateTechServiceLoadAction } from "../../../../redux/field/repairLine/updateTechService/updateTechServiceActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { END_POINTS } from "../../../../redux/endpoints";

const RequiredFields: string[] = ["date", "unit_Number", "make", "model", "component_Code","defect_Item", "technician"];

interface IAddUpdateTechServiceComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addTechServiceStatus: Server<SurewayAPIResponse<string>>;
    getTechServices: Server<SurewayAPIResponse<ById<ITechService>>>;
    updateTechServiceStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateTechServiceComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getTechServicesRequest: (data: IGetTechServicesRequest) => unknown
    addTechServiceRequest: (data: IAddTechServiceRequest) => unknown;
    updateTechServiceRequest: (data: IUpdateTechServiceRequest) => unknown;
};

interface IAddUpdateTechServiceOwnProps {
    id?: string;
};

interface IAddUpdateTechServiceComponentState {
    data: ITechService;
    serverError: string;
    popupImgs: IRepairLinePicture[];
    errors: ById<IFieldErrorKeyValue>;
    imageSrc: { src: string, idx: number } | undefined;
};

const AddUpdateTechServiceStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateTechServiceComponentProps =
    RouteComponentProps
    & IAddUpdateTechServiceOwnProps
    & IAddUpdateTechServiceComponentStoreProps
    & IAddUpdateTechServiceComponentDispatchProps;

class AddUpdateTechService extends PureComponent<IAddUpdateTechServiceComponentProps, IAddUpdateTechServiceComponentState> {

    public constructor(props: IAddUpdateTechServiceComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                date: new Date().toISOString(),
                unit_ID: 0,
                make: "",
                model: "",
                technician: userName,
                component_Code: "",
                defect_Item: "",
                operational_Complaint: "",
                tShooting_Procedure: "",
                repair_Improvement_Procedure: "",
                images: "",
                pictures: [],
                attachments: "",
                files: [],
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            errors: {},
            popupImgs: [],
            serverError: "",
            imageSrc: undefined
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateTechServiceComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addTechServiceStatus !== prevProps.addTechServiceStatus) {

                if (this.props.addTechServiceStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addTechServiceStatus.message });

                if (hasPayload(this.props.addTechServiceStatus) && this.props.addTechServiceStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateTechServiceStatus !== prevProps.updateTechServiceStatus) {

                if (this.props.updateTechServiceStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateTechServiceStatus.message });

                if (hasPayload(this.props.updateTechServiceStatus) && this.props.updateTechServiceStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, imageSrc, popupImgs } = this.state;
        const title = data.id > 0 ? "Update Tech Service" : "Add Tech Service";
        const { token, addTechServiceStatus, updateTechServiceStatus, getLookup } = this.props;
        // console.log(token)

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const models = hasPayload(getLookup) ? getLookup.payload.response.models : [];
        const makes = hasPayload(getLookup) ? getLookup.payload.response.manufacturers : [];
        const componentCodes = hasPayload(getLookup) ? getLookup.payload.response.component_codes : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const onMake = (e: any ,value: any): void =>  this.handleChange("make", value ? value.manufacturer_Name : "");
        const onModel = (e: any ,value: any): void =>  this.handleChange("model", value ? value.model : "");
        const onComponentCode = (value: string): void => this.handleChange("component_Code", value ? value : "");
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        
        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateTechServiceStyles>
                    {/* {(getRole !== NotApplicable) && */}
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}TECH SERVICE INFORMATION</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    id="Date"
                                    value={splitDate(data.date)}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="date"
                                    label="Date"
                                    errorText={errors["date"] ? errors["date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder="Unit Number"
                                    disabled={undefined}
                                    displayExp="unit_Number"
                                    name="unit_Number"
                                    errorName="unit_Number"
                                    errors={errors}
                                    currentVal={unit?.unit_Number}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "make", caption: "Make", type: "string" },
                                        { name: "model", caption: "Model", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={makes}
                                    getOptionLabel="manufacturer_Name"
                                    autoHighlight={true}
                                    onChange={onMake}
                                    disabled={undefined}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Make"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["make"] ? errors["make"].message : undefined}
                                    value={data.make ? makes?.find(q => q.manufacturer_Name === data.make) : null}
                                    defaultValue={data.make ? makes?.find(q => q.manufacturer_Name === data.make) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={this.filteredModelsOptions()}
                                    getOptionLabel="model"
                                    autoHighlight={true}
                                    onChange={onModel}
                                    disabled={!data.make}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Model"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["model"] ? errors["model"].message : undefined}
                                    value={data.model ? this.filteredModelsOptions().find(q => q.model === data.model) : null}
                                    defaultValue={data.model ? this.filteredModelsOptions().find(q => q.model === data.model) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Technician"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="technician"
                                    disabled={true}
                                    value={data.technician ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["technician"] ? errors["technician"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Defect Item"
                                    fullWidth={true}
                                    variant="outlined"
                                    name="defect_Item"
                                    InputProps={{inputProps: { maxLength: 40 }}}
                                    disabled={undefined}
                                    value={data.defect_Item ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["defect_Item"] ? errors["defect_Item"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <DevExtremePopupLookup
                                    id="component-code-search"
                                    placeHolder="Component Code"
                                    disabled={undefined}
                                    displayExp="code"
                                    errors={errors}
                                    name="code"
                                    errorName="component_Code"
                                    currentVal={data.component_Code ?? ""}
                                    data={componentCodes}
                                    onClick={onComponentCode}
                                    columns={[
                                        { name: "code", caption: "Code", type: "string" },
                                        { name: "description", caption: "Description", type: "string" }
                                    ]}
                                />
                            </LAGridItem> 

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    minRows={4}
                                    rowsMax={6}
                                    label="Operational Complaint"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="operational_Complaint"
                                    value={data.operational_Complaint ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["operational_Complaint"] ? errors["operational_Complaint"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    minRows={4}
                                    rowsMax={6}
                                    label="T-Shooting Procedure"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="tShooting_Procedure"
                                    value={data.tShooting_Procedure ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["tShooting_Procedure"] ? errors["tShooting_Procedure"].message : undefined}
                                />
                            </LAGridItem>
                            
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    minRows={4}
                                    rowsMax={6}
                                    label="Repair Improvement Procedure"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={undefined}
                                    name="repair_Improvement_Procedure"
                                    value={data.repair_Improvement_Procedure ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["repair_Improvement_Procedure"] ? errors["repair_Improvement_Procedure"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGreenPaper>

                                <strong>ATTACHMENTS</strong>
                                <hr />

                                <LADropZone onFileDrop={this.fileUpload} />
                                    <br />

                                    <LAGrid>
                                        {(data.files !== null) && data.files
                                            .map((q, ind) => {
                                                const onFileDelete = (): void => this.onFileDelete(ind);
                                                const onDownload = (): void => this.handleDownload(q);

                                                return (
                                                    <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                        <LAPaperWithLessPadding>
                                                            <span>{q.name}</span>
                                                            <br />
                                                            <LAIconButton
                                                                name="file"
                                                                label={q.name}
                                                                id={(ind).toString()}
                                                                onClick={onDownload}
                                                                icon={<DownloadIcon />}
                                                            />

                                                            <LAIconButton
                                                                key={ind}
                                                                label="Delete"
                                                                icon={<DeleteIcon />}
                                                                onClick={onFileDelete}
                                                            />
                                                        </LAPaperWithLessPadding>
                                                    </LAGridItem>
                                                )
                                            })}
                                    </LAGrid>
                                </LAGreenPaper>
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LABluePaper>
                                    <LAGridItem xs={12} className="text-center">
                                        {!imageSrc ? <strong>Images</strong> : <strong>Highlight Image</strong>}<hr />
                                        {!imageSrc && <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e)} />}
                                    </LAGridItem>
                                    <br />
                                    {!imageSrc ? 
                                        <LAGrid>
                                            {popupImgs
                                                .map((q, ind) => {
                                                    const onImgDelete = (): void => this.onImgDelete(q.name, ind);
                                                    const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                    const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                    const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind);

                                                    return (
                                                        <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                            <LAPaperWithLessPadding>
                                                                <LAThumbnailWithLink
                                                                    alt={q.name}
                                                                    key={ind}
                                                                    url={base64}
                                                                    id={(ind + "_").toString()}
                                                                />

                                                                <LATextField
                                                                    className="mt-2"
                                                                    label="Description"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    name="description"
                                                                    disabled={undefined}
                                                                    value={q.description}
                                                                    onChange={onImageName}
                                                                    errorText={
                                                                        q.name.length === 0 ?
                                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                            : undefined
                                                                    }
                                                                />

                                                                {q.base64String && <LAButton 
                                                                    onClick={openImage} 
                                                                    disabled={undefined}
                                                                    label="Highlight Image" 
                                                                />}

                                                                <LAIconButton
                                                                    key={ind}
                                                                    label="Delete"
                                                                    disabled={undefined}
                                                                    onClick={onImgDelete}
                                                                    icon={<DeleteIcon />}
                                                                />
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    );

                                                })
                                            }
                                        </LAGrid>
                                        : <Marker
                                            imageSrc={imageSrc.src}
                                            onSave={this.handleEditImageSave}
                                            onCancel={this.handleEditImageCancel}
                                        />
                                    }

                                </LABluePaper>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    {/* } */}

                    <RequestStatus requestStatus={addTechServiceStatus.kind} successMessage="Tech Service has been added successfully" />
                    <RequestStatus requestStatus={updateTechServiceStatus.kind} successMessage="Tech Service has been updated successfully" />

                </AddUpdateTechServiceStyles>
            </PageSpacing>
        );
    }

    private filteredModelsOptions = (): IModel[] => {
        const {data} = this.state;
        const { getLookup } = this.props;

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const modelOptions: IModel[] = hasPayload(getLookup) ? getLookup.payload.response.models : [];

        if(data.make) {
            const filteredUnits = units.filter(x => x.make === data.make);
            const filteredModels: IModel[] = [];
            filteredUnits.forEach(x => {
                const model = modelOptions.find(y => y.model === x.model);
                if(model) {
                    if(!filteredModels.includes(model))
                        filteredModels.push(model)
                }
            })
            return filteredModels
        }
        return modelOptions;
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);
        // console.log(value)
        let errors = { ...this.state.errors };
        if (value === undefined || value === "") {
            errors = this.errorChecker("unit_Number", "", errors);
            errors = this.errorChecker("make", "", errors);
            errors = this.errorChecker("model", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value.toString(), errors);
            if(unit) {
                delete errors["make"];
                delete errors["model"]; 
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
                make: unit?.make ?? "",
                model: unit?.model ?? ""
            },
            errors
        });
    };

    private fileUpload = (attachments: Array<File | DataTransferItem>): void => {

        const files = this.state.data.files ? this.state.data.files : [];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;
                Object.values(attachments).forEach((x: any, index) => {
                    // console.log(x)
                    if(x.type.includes("image")) {
                        alert("Please select only an attachment")
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                files.map((att: any, i: number) => {x.name.substring(0, x.name.lastIndexOf("."))
                                    // console.log(img.description, description)
                                    if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                        isDuplicateName = true;
                                    }
                                    return isDuplicateName
                                })
                                isDuplicateName ?
                                    files.push({
                                        name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                        base64String: reader.result.toString()
                                    }) : 
                                    files.push({
                                        name:  x.name,
                                        base64String: reader.result.toString()
                                    });
                                // files.push({
                                //     name: x.name,
                                //     base64String: reader.result.toString()
                                // });
    
                                if (attachments.length === index + 1) {
                                    resolve(true);
                                };
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                },
            });
        });
    };

    private onFileDelete = (fileIdx: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const files = [...this.state.data.files];
            files.splice(fileIdx, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                }
            });
        }
    };

    private handleDownload = (q: ITechServiceFile): void => {
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            this.downloadFromEndpoint(q.name);
        }
    };

    private downloadFromEndpoint = async (fileName: string): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.token)) {

                await fetch(END_POINTS.FIELD.ATTACHMENT_LIST.DOWNLOAD_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "File_Name": fileName,
                            "ID": this.state.data.id,
                            "Page":"Tech_Service",
                        },
                        token: this.props.token.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleEditImageSave = (src: string): void => {
        if (this.state.imageSrc)
            this.onImageEdit(src, this.state.imageSrc.idx);

        this.handleEditImageCancel();
    };

    private handleOpenImage = (src: string, idx: number): void => {
        this.setState({ imageSrc: { src, idx } });
    };

    private handleEditImageCancel = (): void => {
        this.setState({ imageSrc: undefined });
    };

    private onImageEdit = (base64String: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], base64String };
        popupImgs[subIndex] = picture;

        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private handleImageName = (name: string, value: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], [name]: value };
        popupImgs[subIndex] = picture;


        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private onImgDelete = (name: string, imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const popupImgs = [...this.state.popupImgs];
            popupImgs.splice(imgIndex, 1);
            
            this.setState({
                ...this.state,
                popupImgs: [
                    ...popupImgs
                ]
            });
        }
    };
    
    private handleImageUpload = async (event: any): Promise<void> => {

        const files = event;
        const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                const imgId = uuidv4();
                let isDuplicateDescription = false;
                // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                let description = x.name;
                const base64String = await fileToBase64Image(x);

                popupImgs.map((img, i) => {
                    // console.log(img.description, description)
                    if(img.description === description.split(".")[0]) {
                        isDuplicateDescription = true;
                    }
                    return isDuplicateDescription
                })
                isDuplicateDescription ?
                    popupImgs.push({
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0] + "_1",
                        base64String,
                    }) : 
                    popupImgs.push({
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0],
                        base64String,
                    });

                this.setState({
                    ...this.state,
                    popupImgs: [
                        ...popupImgs
                    ], 
                });
            }));
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data, popupImgs } = this.state;

        if (hasPayload(this.props.token)) {

            const images: string = popupImgs.map(picture => picture.name).join(";");

            let request: IAddTechService = {
                ...data,
                images: images,
                pictures: popupImgs,
                created_By: this.props.token.payload.response.upn,
                modified_By: this.props.token.payload.response.upn,
            };

            console.log(request)
            if (data.id === 0) {
                this.props.addTechServiceRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateTechServiceRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "make") {
            if(value) {
                data.model = "";
                errors = this.errorChecker("model", "", errors);
                if(data.model) {
                    delete errors["unit_Number"]
                }
            } 
            else {
                data.model = "";
                errors = this.errorChecker("model", "", errors);
            }
        }
        if(name === "model") {
            if(value) {
                if(data.make) {
                    delete errors["unit_Number"]
                }
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                // if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (hasPayload(this.props.getTechServices)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getTechServices.payload.response[query.id];
                                // const pictures = data.images && data.images.split(";");
                                const pictures = data.pictures; 
                                
                                const popupImgs: IRepairLinePicture[] = [];
                                pictures && pictures.forEach((x) => {
                                    popupImgs.push({
                                        name: x.name,
                                        description: x.description,
                                        base64String: ""
                                    });
                                });
                                // console.log(popupImgs)
                                this.setState({
                                    data,
                                    popupImgs, 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });
                            if (errors["date"])
                                delete errors["date"]
                            if (errors["technician"])
                                delete errors["technician"]

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getTechServicesRequest({
                            token: this.props.token.payload.response.token,
                            request: {
                                unit_id: 0
                            }
                        });
                    }

                // } 
                // else {

                //     this.props.history.push({
                //         pathname: ROUTE.ACCESS_DENIED,
                //         search: getTokenFromUrl(false)
                //     });
                // };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Tech_Service"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateTechServiceComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getTechServices: getTechServices(state),
    addTechServiceStatus: addTechServiceStatus(state),
    updateTechServiceStatus: updateTechServiceStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateTechServiceComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getTechServicesRequest: (data: IGetTechServicesRequest) => dispatch(getTechServicesLoadAction(data)),
    addTechServiceRequest: (data: IAddTechServiceRequest) => dispatch(addTechServiceLoadAction(data)),
    updateTechServiceRequest: (data: IUpdateTechServiceRequest) => dispatch(updateTechServiceLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateTechService);