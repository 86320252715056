import { ROUTE } from "../routes";
import styled from "styled-components";
import { Server } from "../../redux/server";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { HasClass, ToolRentalsResponse } from "../shared/publicInterfaces";
import { AddPhotoIcon, AttachmentIcon, DispatchIcon, DocumentIcon, DozerIcon, EquipmentTrackerIcon, FilterCutIcon, FilterIcon, FiltersIcon, 
    HomeIcon, JobsIcon, ListIcon, LocationIcon, LogOutIcon, PMSheetIcon, RepairIcon, RepairLineIcon, SoctIcon, 
    TimeLogIcon, TripodIcon, VendorIcon, WSSIcon } from "../shared/icons";
import { IValidateShopGroup } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { DARK_GREY_COLOR } from "../shared/theme";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LAIconButton } from "../shared/buttons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { authContext } from "../../adalConfig";
import { getTokenFromUrl } from "../shared/constExports";

interface IMenuOptions {
    title: string;
    route: string;
    newApp?: true;
    icon: ReactNode;
    multipleIncludes?: string[];
};

const options: IMenuOptions[] = [
    { title: "Jobs", icon: <JobsIcon />, route: ROUTE.FIELD.JOBS.INDEX },
    { title: "Locations", icon: <LocationIcon />, route: ROUTE.FIELD.LOCATION.INDEX, multipleIncludes: [ROUTE.FIELD.SOCT.WORKING_SITES.INDEX] },
    { title: "Equipment List", icon: <DozerIcon />, route: ROUTE.FIELD.EQUIPMENT.INDEX, multipleIncludes: [ROUTE.FIELD.EQUIPMENT_TYPE.INDEX, ROUTE.FIELD.MANUFACTURER.INDEX, ROUTE.FIELD.CONFIG.INDEX] },
    { title: "Attachment List", icon: <AttachmentIcon />, route: ROUTE.FIELD.ATTACHMENT_LIST.INDEX },
    { title: "Filter Cuts", icon: <><FilterCutIcon /><FiltersIcon /></>, route: ROUTE.FIELD.FILTER_CUT.FILTER_CUTS },
    { title: "SOCT", icon: <SoctIcon />, route: ROUTE.FIELD.SOCT.SOCT_LIST },
    { title: "Equipment Tracker", icon: <EquipmentTrackerIcon />, route: ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST, newApp: true },
    { title: "Filters", icon: <FiltersIcon />, route: ROUTE.FIELD.FILTER.FILTER_REPORT },
    { title: "PM Sheets", icon: <PMSheetIcon />, route: ROUTE.FIELD.PM_SHEETS.INDEX, multipleIncludes: [ROUTE.FIELD.PM_SHEETS.SERVICE_DUES, ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT, ROUTE.FIELD.PM_SHEETS.SERVICE_SECTIONS] },
    { title: "Repair Line", icon: <RepairLineIcon />, route: ROUTE.FIELD.REPAIR_LINE.INDEX },
    { title: "Working Site Logs", icon: <WSSIcon />, route: ROUTE.FIELD.WORKING_SITE_LOGS.INDEX },
    { title: "Service Trucks", icon: <DispatchIcon />, route: ROUTE.FIELD.DISPATCH.INDEX },
    { title: "Image Gallery", icon: <AddPhotoIcon />, route: ROUTE.FIELD.IMAGE_GALLERY.INDEX },
    { title: "Forms", icon: <DocumentIcon />, route: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX },
    { title: "Survey", icon: <TripodIcon />, route: ROUTE.FIELD.SURVEY.INDEX },
    { title: "Vendor PO", icon: <VendorIcon />, route: ROUTE.FIELD.VENDOR.INDEX,},
];

const HeaderStyles = styled.div`
.field-page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
};

.current {
    background-color: ${DARK_GREY_COLOR};
};

.field-page > div {
    margin-right: 10px;
    margin-bottom: 10px;
}
`;

interface IShopDropDownProps extends HasClass {
    hideEqp?: true;
    validateGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IShopDropDownState {
    config: any;
};

interface IShopDropDownMobileState extends IShopDropDownState {
    drawer: boolean;
};

type IOrderDropDown =
    RouteComponentProps &
    IShopDropDownProps;


export class ShopDropDown extends PureComponent<IOrderDropDown, IShopDropDownState> {

    public constructor(props: IOrderDropDown) {
        super(props);
        this.state = {
            config: null
        };
    }

    public render(): ReactNode {

        const path = this.props.location.pathname;

        return (
            <HeaderStyles>
                <div className="field-page">
                    {options.map((x, idx) => 
                        <ShopDropDownTile 
                            key={idx}    
                            item={x}
                            path={path}
                            onClick={this.onClick}
                        />
                    )}
                </div>
            </HeaderStyles>
        );
    };

    private onClick = (pathname: string, newApp?: true): void => {
        if(newApp){
            window.open(pathname, "_self");
        } else {
            this.props.history.push({
                pathname,
                search: getTokenFromUrl(false)
            });
        }
    };
}

interface IShopDropDownTile extends HasClass {
    path: string;
    item: IMenuOptions;
    onClick: (route: string, newApp?: true) => void;
};

const ShopDropDownTile = (props: IShopDropDownTile): JSX.Element => {
    const onClick = (): void => props.onClick(props.item.route, props.item.newApp);
    const current =  props.path.includes(props.item.route) || (props.item.multipleIncludes && props.item.multipleIncludes.some(str => props.path.includes(str)));

    return <LAMenuItem onClick={onClick} className={current ? "current" : ""}>
    {props.item.icon}
    <span>
        {props.item.title}
    </span>
</LAMenuItem>
}


export class ShopDropDownMobile extends PureComponent<IOrderDropDown, IShopDropDownMobileState> {

    public constructor(props: IOrderDropDown) {
        super(props);
        this.state = {
            config: null,
            drawer: false
        };
    }

    public render(): ReactNode {

        const path = this.props.location.pathname;

        return (
            <>

                <LAIconButton
                    label="Open"
                    icon={<ListIcon />}
                    onClick={this.handleDrawer}
                />

                <SwipeableDrawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    open={this.state.drawer}
                    onClose={this.handleDrawer}
                    onOpen={this.handleDrawer}
                >
                    <HeaderStyles>
                        <LAGrid className="ml-3" spacing={3}>
                            <LAGridItem xs={12} onClick={this.onHome} className={(path.includes(ROUTE.INDEX)) ? "current" : ""}>
                                <HomeIcon />
                                <span className="ml-2">
                                    Home
                                </span>
                            </LAGridItem>
                            <LAGridItem xs={12} onClick={this.onJobs} className={(path.includes(ROUTE.FIELD.JOBS.INDEX)) ? "current" : ""}>
                                <JobsIcon />
                                <span>
                                    Jobs
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onLocation} className={(path.includes(ROUTE.FIELD.LOCATION.INDEX)) ? "current" : ""}>
                                <LocationIcon />
                                <span>
                                    Locations
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onEquipment} className={(path.includes(ROUTE.FIELD.EQUIPMENT.INDEX) || path.includes(ROUTE.FIELD.EQUIPMENT_TYPE.INDEX) || path.includes(ROUTE.FIELD.MANUFACTURER.INDEX) || path.includes(ROUTE.FIELD.CONFIG.INDEX)) ? "current" : ""}>
                                <DozerIcon />
                                <span>
                                    Equipment List
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onAtt} className={path.includes(ROUTE.FIELD.ATTACHMENT_LIST.INDEX) ? "current" : ""}>
                                <AttachmentIcon />
                                <span>
                                    Attachment List
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onFilterCuts} className={path.includes(ROUTE.FIELD.FILTER_CUT.FILTER_CUTS) ? "current" : ""}>
                                <FilterCutIcon />
                                <FiltersIcon />
                                <span>
                                    Filter Cuts
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onSOCT} className={(path.includes(ROUTE.FIELD.SOCT.INDEX)) ? "current" : ""}>
                                <SoctIcon />
                                <span className="ml-2">
                                    SOCT
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onEquipmentTracker} className={(path.includes(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.INDEX)) ? "current" : ""}>
                                <EquipmentTrackerIcon />
                                <span className="ml-2">
                                    Equipment Tracker
                                </span>
                            </LAGridItem>
                            <LAGridItem xs={12} onClick={this.onFilters} className={(path.includes(ROUTE.FIELD.FILTER.FILTER_REPORT)) ? "current" : ""}>
                                <FilterIcon />
                                <span className="ml-2">
                                    Filters
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onPMSheets} className={(path.includes(ROUTE.FIELD.PM_SHEETS.INDEX)) ? "current" : ""}>
                                <PMSheetIcon />
                                <span className="ml-2">
                                    PM Sheets
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onRepairLine} className={(path.includes(ROUTE.FIELD.REPAIR_LINE.INDEX)) ? "current" : ""}>
                                <RepairIcon />
                                <span className="ml-2">
                                    Repair Line
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onWSL} className={(path.includes(ROUTE.FIELD.WORKING_SITE_LOGS.INDEX)) ? "current" : ""}>
                                <TimeLogIcon />
                                <span className="ml-2">
                                    Working Site Logs
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onDispatch} className={(path.includes(ROUTE.FIELD.DISPATCH.INDEX)) ? "current" : ""}>
                                <DispatchIcon />
                                <span className="ml-2">
                                    Service Trucks
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onImageGallery} className={(path.includes(ROUTE.FIELD.IMAGE_GALLERY.INDEX)) ? "current" : ""}>
                                <AddPhotoIcon />
                                <span className="ml-2">
                                    Image Gallery
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onForms} className={(path.includes(ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX)) ? "current" : ""}>
                                <DocumentIcon />
                                <span className="ml-2">
                                    Forms
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onSurvey} className={(path.includes(ROUTE.FIELD.SURVEY.INDEX)) ? "current" : ""}>
                                <TripodIcon />
                                <span className="ml-2">
                                    Survey
                                </span>
                            </LAGridItem>

                            <LAGridItem xs={12} onClick={this.onLogOut} >
                                <LogOutIcon />
                                <span className="ml-2">
                                    Logout
                                </span>
                            </LAGridItem>


                        </LAGrid>
                    </HeaderStyles>
                </SwipeableDrawer>
            </>
        );
    };
    
    private onSurvey = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onForms = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onImageGallery = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.IMAGE_GALLERY.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onDispatch = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private handleDrawer = (): void => {
        this.setState({ drawer: !this.state.drawer });
    };

    private onWSL = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.WORKING_SITE_LOGS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onEquipmentTracker = (): void => {
        // this.handleDrawer();

        // this.props.history.push({
        //     pathname: ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST,
        //     search: getTokenFromUrl(false)
        // });
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST, "_self");
    };

    private onLocation = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.LOCATION.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onJobs = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.JOBS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onEquipment = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onAtt = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.ATTACHMENT_LIST.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onFilterCuts = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.FILTER_CUT.FILTER_CUTS,
            search: getTokenFromUrl(false)
        });
    };

    private onSOCT = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.SOCT.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onFilters = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.FILTER.FILTER_REPORT,
            search: getTokenFromUrl(false)
        });
    };

    private onPMSheets = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.PM_SHEETS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onRepairLine = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onHome = (): void => {
        this.handleDrawer();

        this.props.history.push({
            pathname: ROUTE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onLogOut = (): void => {
        authContext.logOut();
    };

}