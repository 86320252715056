import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MAIN_COLOR } from "./shared/theme";
import { ROUTE } from "./routes";
import { Button } from "@mui/material";
import LALinkButton from "./shared/linkButton";

const Error404PageStyles = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${MAIN_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .container {
        margin: 0 5rem;
        h1, p#heading {
            text-shadow: 0px 0px 6px #fff;
        }
        h1 {
            margin: 0;
            font-size: 25vw;
            -webkit-text-stroke: 2px #000;
        }
        p#heading {
            text-align: center;
            font-size: 4vw;
            margin-top: 0;
        }
        p#text {
            font-size: 16px;
        }
        #link {
            text-decoration: none;
            color: #fff;
            text-decoration: underline;
        }
    }
`;

interface IGenericClosePagePageProps {
    handleShowHeader: (value: boolean) => void;
}

const CloseWindowPage: React.FC<IGenericClosePagePageProps> = React.memo((props: IGenericClosePagePageProps) => {
    useEffect((): (() => void) => {
        props.handleShowHeader(false);
        return (): unknown => props.handleShowHeader(true);
    });
    return <Error404PageStyles>
        <div className="container">
            {/* <h1>404</h1> */}
            <p id="heading">You can now close this window</p>
            {/* <LALinkButton label="Or click here to close" onClick={()=>{window.open("about:blank", "_self");window.close();}}></LALinkButton> */}
        </div>
    </Error404PageStyles>;
});

export default CloseWindowPage;
