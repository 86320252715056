import { ILocalStorageToken, LOCAL_STORAGE_TOKEN } from "./localStorageTokenConstants";
// FLUSH DATA

export interface ILocalStorageTokenRequestAction {
    type: LOCAL_STORAGE_TOKEN.REQUEST;
}

export const localStorageTokenRequestAction = (): ILocalStorageTokenRequestAction => ({
    type: LOCAL_STORAGE_TOKEN.REQUEST,
});

export interface ILocalStorageTokenSuccessAction {
    type: LOCAL_STORAGE_TOKEN.SUCCESS;
    data: ILocalStorageToken
}

export const localStorageTokenSuccessAction = (data: ILocalStorageToken): ILocalStorageTokenSuccessAction => ({
    type: LOCAL_STORAGE_TOKEN.SUCCESS,
    data
});

export interface ILocalStorageTokenFailedAction {
    type: LOCAL_STORAGE_TOKEN.FAILED;
}

export const localStorageTokenFailedAction = (): ILocalStorageTokenFailedAction => ({
    type: LOCAL_STORAGE_TOKEN.FAILED,
});