import { IAddUpdateMechanicLog } from "../addMechanicLog/addMechanicLogConstants";

export enum IUPDATE_MECHANIC_LOG_REQUEST {
    REQUEST = "updateMechanicLog/UPDATE_MECHANIC_LOG_REQUEST",
    SUCCESS = "updateMechanicLog/UPDATE_MECHANIC_LOG_SUCCESS",
    FAILED = "updateMechanicLog/UPDATE_MECHANIC_LOG_FAILED"
};

export interface IUpdateMechanicLogRequest {
    token: string;
    request: IAddUpdateMechanicLog;
};