import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_FILTER_PART_REQUEST } from "./updateFilterPartConstants";
import { IUpdateFilterPartLoadAction, IUpdateFilterPartLoadFailedAction, IUpdateFilterPartSuccessAction } from "./updateFilterPartActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IUpdateFilterPartLoadAction
    | IUpdateFilterPartSuccessAction
    | IUpdateFilterPartLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateFilterPartReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_FILTER_PART_REQUEST.REQUEST:
            return loading;

        case IUPDATE_FILTER_PART_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_FILTER_PART_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};