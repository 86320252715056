import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_LEVEL_REQUEST } from "./addLevelConstants";
import { IAddLevelLoadAction, IAddLevelLoadFailedAction, IAddLevelSuccessAction, 
    addLevelLoadFailedAction, addLevelLoadSuccessAction } from "./addLevelActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addLevelEpic: Epic = (
    action$: ActionsObservable<IAddLevelLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddLevelSuccessAction | IAddLevelLoadFailedAction> =>
    action$.ofType(IADD_LEVEL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_LEVEL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddLevelSuccessAction | IAddLevelLoadFailedAction => {
                            if(response.message === SUCCESS_MESSAGE)
                                return addLevelLoadSuccessAction(response);
                            return addLevelLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addLevelLoadFailedAction(response.message)))
                    )
            )
        );