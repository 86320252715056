import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../../header/repairLineSubHeader";
import { IGetUnitInspectionSheets, IGetUnitInspectionSheetsRequest } from "../../../../redux/field/getUnitInspectionSheets/getUnitInspectionSheetsConstants";
import { getUnitInspectionSheets } from "../../../../redux/field/getUnitInspectionSheets/getUnitInspectionSheetsAccessor";
import { getUnitInspectionSheetsLoadAction } from "../../../../redux/field/getUnitInspectionSheets/getUnitInspectionSheetsActions";
import queryString from "query-string";

const defaultSessionStore = '{"columns":[{"visibleIndex":0,"name":"buttons","width":90,"visible":true,"filterValue":null},{"visibleIndex":1,"dataField":"unit_No","name":"unit_No","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":2,"dataField":"hours","name":"hours","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":3,"dataField":"comments","name":"comments","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":4,"dataField":"created_By","name":"created_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":5,"dataField":"created","name":"created","dataType":"datetime","visible":true,"filterValue":null},{"visibleIndex":6,"dataField":"modified_By","name":"modified_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":7,"dataField":"modified","name":"modified","dataType":"datetime","visible":true,"sortOrder":"desc","sortIndex":0,"filterValue":null},{"visibleIndex":8,"dataField":"unit_ID","name":"unit_ID","dataType":"number","visible":true,"filterValue":null}],"allowedPageSizes":[10,20,40],"filterPanel":{"filterEnabled":true},"filterValue":null,"searchText":"","pageIndex":0,"pageSize":20}'

interface IInspectionSheetsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getUnitInspectionSheets: Server<SurewayAPIResponse<IGetUnitInspectionSheets[]>>;
};

interface IInspectionSheetsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getUnitInspectionSheetsRequest: (data: IGetUnitInspectionSheetsRequest) => unknown;
};


interface IInspectionSheetsOwnProps {

};

interface IInspectionSheetsState {
    // view: string;
};

const InspectionSheetsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IInspectionSheetsProps = RouteComponentProps
    & IInspectionSheetsStoreProps
    & IInspectionSheetsDispatchProps
    & IInspectionSheetsOwnProps;

class InspectionSheets extends PureComponent<IInspectionSheetsProps, IInspectionSheetsState> {

    public constructor(props: IInspectionSheetsProps) {
        super(props);
        this.state = {
            // view: "Active"
        };
    }

    public async componentDidMount(): Promise<void> {
        const query: any = queryString.parse(this.props.location.search);
        // const localSessionkey = localStorage.getItem("InspectionSheetsListSessionKey");

        if (query !== undefined && (query.keyword !== undefined)) {
            const queryUnitID = query.keyword;
            let val = JSON.parse(defaultSessionStore);
            // console.log(val)
            // let unitIDArray: any = [];
            for (let i = 0; i < val.columns.length; i++) {
                if (val.columns[i].name === "unit_ID") {
                    // unitIDArray.push(queryUnitID);
                    // val.columns[i].filterValues = unitIDArray;
                    val.columns[i].filterValue = queryUnitID;
                    // val.column[i].visible = false;
                }
                
            }
            localStorage.setItem("InspectionSheetsListSessionKey", JSON.stringify(val));
        } 
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IInspectionSheetsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getUnitInspectionSheets, getToken } = this.props;
        // const getRole = pageAccessCheck(getToken, "repairLine");
        const data = hasPayload(getUnitInspectionSheets) ? getUnitInspectionSheets.payload.response : [];

        return (
            <PageSpacing title="Inspection Forms" description="FIELD - Inspection Forms" fixedSpaceOnSmallerScreens={true}>
                {/* {(getRole !== NotApplicable) &&  */}
                <InspectionSheetsStyles>
                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>
                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">INSPECTION FORMS</h2>
                                <hr />

                                {getUnitInspectionSheets.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Inspection Sheets..." />}
                                {getUnitInspectionSheets.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Inspection Sheets..." />}

                                {getUnitInspectionSheets.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="InspectionSheetsList"
                                                customRowColor={true}
                                                exportFileName="InspectionSheets"
                                                storageKey="InspectionSheetsListSessionKey"
                                                onClearClick={this.onClearClick}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    // { name: "date", caption: "Date", type: "date" },
                                                    { name: "unit_No", caption: "Unit No.", type: "string" },
                                                    // { name: "technician", caption: "Technician", type: "string" },
                                                    // { name: "defect_Item", caption: "Defect Item", type: "string" },
                                                    { name: "hours", caption: "Hours", type: "string" },
                                                    { name: "comments", caption: "Comments", type: "string" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    { name: "unit_ID", caption: "Unit ID", type: "number", show: false},
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </InspectionSheetsStyles>
                {/* } */}
            </PageSpacing>
        );
    }

    private onClearClick = async(): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName:ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS
        });
        this.getDataForTable();
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), defect_ID: e.row.data.defect_ID.toString(), unit_ID: e.row.data.unit_ID.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORM
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORM
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            // if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getUnitInspectionSheets)) {
                    this.getDataForTable();
                };
            // } 
            // else {
            //     this.props.history.push({
            //         pathname: ROUTE.ACCESS_DENIED,
            //         search: getTokenFromUrl(false)
            //     });
            // };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getUnitInspectionSheetsRequest({
                token: this.props.getToken.payload.response.token,
            });
        }
    };

}

const mapStateToProps = (state: IStore): IInspectionSheetsStoreProps => ({
    getToken: getToken(state),
    getUnitInspectionSheets: getUnitInspectionSheets(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IInspectionSheetsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getUnitInspectionSheetsRequest: (data: IGetUnitInspectionSheetsRequest) => dispatch(getUnitInspectionSheetsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionSheets);