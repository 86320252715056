
export enum IADD_DRIVER_REQUEST {
    REQUEST = "addDriver/ADD_DRIVER_REQUEST",
    SUCCESS = "addDriver/ADD_DRIVER_SUCCESS",
    FAILED = "addDriver/ADD_DRIVER_FAILED"
};

export interface IAddETDriverRequest {
    token: string;
    request: IAddUpdateETDriver;
};

export interface IAddUpdateETDriver {
    ID: number;
    Driver_Firstname: string;  
    Driver_Lastname: string;
    Active: string;
    Email: string;
    Notes: string,
    Pilot_Driver: string;
    Truck_Driver: string;
    Phone_Number: string;
    License: string;
    Created_By: string;
    Modified_By: string;
};