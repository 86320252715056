
export enum IINITIATE_MOVE_REQUEST {
    REQUEST = "initiateMove/INITIATE_MOVE_REQUEST",
    SUCCESS = "initiateMove/INITIATE_MOVE_SUCCESS",
    FAILED = "initiateMove/INITIATE_MOVE_FAILED"
};

export interface IInitiateMoveRequest {
    token: string;
    request: IInitiateMove;
};

export interface IInitiateMove {
    Unit_ID: number;
    From_Site_ID: number;
    From_Sub_Site_ID?: number;
    To_Site_ID: number;
    To_Sub_Site_ID?: number;
    Drive_Over: string;
    Send_Email: string;
    Moved: string;
    Moved_Previously: string;
    Request_Details: string;
    Created_By: string;
};