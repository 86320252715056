import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_JOB_REQUEST, IAddJobRequest } from "./addJobConstants";


export interface IAddJobLoadAction {
    type: IADD_JOB_REQUEST.REQUEST;
    data: IAddJobRequest
}
export const addJobLoadAction = (data: IAddJobRequest): IAddJobLoadAction => ({
    type: IADD_JOB_REQUEST.REQUEST,
    data
});
export interface IAddJobSuccessAction {
    type: IADD_JOB_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addJobLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddJobSuccessAction => ({
    type: IADD_JOB_REQUEST.SUCCESS,
    message
});
export interface IAddJobLoadFailedAction {
    type: IADD_JOB_REQUEST.FAILED;
    message: string;
}
export const addJobLoadFailedAction = (message: string): IAddJobLoadFailedAction => ({
    type: IADD_JOB_REQUEST.FAILED,
    message
});
