import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_FIELDEMPLOYEES_REQUEST {
    REQUEST = "getFieldEmployees/GET_FIELDEMPLOYEES_REQUEST",
    SUCCESS = "getFieldEmployees/GET_FIELDEMPLOYEES_SUCCESS",
    FAILED = "getFieldEmployees/GET_FIELDEMPLOYEES_FAILED"
};

export interface IGetFieldEmployeesRequest extends ISurewayTokenRequestBody {

};

export interface IFieldEmployee {
    id: number;
    name: string;
    email: string;
    phone: string;
    token?: string;
    department: string;
    active: string;
    foreman: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};