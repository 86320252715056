
export enum ISAVE_DRIVERS_ASSIGNMENT_REQUEST {
    REQUEST = "saveDriversAssignment/SAVE_DRIVERS_ASSIGNMENT_REQUEST",
    SUCCESS = "saveDriversAssignment/SAVE_DRIVERS_ASSIGNMENT_SUCCESS",
    FAILED = "saveDriversAssignment/SAVE_DRIVERS_ASSIGNMENT_FAILED"
};

export interface ISaveDriversAssignmentRequest {
    token: string;
    upn: string;
    request: ISaveUpdateDriversAssignment[];
};

export interface ISaveUpdateDriversAssignment {
    Sub_Site_ID: number;
    Service_Truck_ID: number[];
    Priority: number[];
    Date: string;
    Notes: string;
};