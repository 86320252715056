import { IUPDATE_EXTERNAL_USER_REQUEST } from "./updateExternalUserConstants";
import { IUpdateExternalUserLoadAction, IUpdateExternalUserLoadFailedAction, IUpdateExternalUserSuccessAction } from "./updateExternalUserActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../server";

type Actions =
    | IUpdateExternalUserLoadAction
    | IUpdateExternalUserSuccessAction
    | IUpdateExternalUserLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateExternalUserReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_EXTERNAL_USER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_EXTERNAL_USER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_EXTERNAL_USER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};