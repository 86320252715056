import { IMachineControl } from "../getMachineControls/getMachineControlsConstants";

export enum IADD_MACHINE_CONTROL_REQUEST {
    REQUEST = "addMachineControl/ADD_MACHINE_CONTROL_REQUEST",
    SUCCESS = "addMachineControl/ADD_MACHINE_CONTROL_SUCCESS",
    FAILED = "addMachineControl/ADD_MACHINE_CONTROL_FAILED"
};

export interface IAddUpdateMachineControlRequest {
    token: string;
    request: IMachineControl;
};