import React, { useState } from "react";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import { ISTSchedules } from "../../../redux/field/dispatch/getSTSchedules/getSTSchedulesConstants";
import { LAPaperWithLessPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LADatePicker from "../../shared/datePicker";
import { LACheckBox } from "../../shared/checkBox";
import { LAButton, LASecondaryButton } from "../../shared/buttons";
import { getDate } from "../../shared/constExports";

interface ISTScheduleDataGrid {
    data: ISTSchedules[];
    onSave: (data: ISTSchedules) => void;
};

export const DevDataGrid: React.FC<ISTScheduleDataGrid> = React.memo((props: ISTScheduleDataGrid) => {

    const handleSave = (e: any) => {
        if (e) {
            if (e.changes.length > 0) {
                if (e.changes[0])
                    props.onSave(e.changes[0].data);
            }
        }
    };

    const allowRowEditing = (rowData: any) => {
        return (rowData.row.data.completed === true) ? false : true;
    };

    const onEditorPreparing = (e: any) => {
        if (e.row) {
            if (e.row.data) {
                if (e.dataField === "drivingAtSite") {
                    if ((e.row.data.drivingAtSite !== null) && (e.row.data.drivingAtSite === true)) {
                        e.editorOptions.disabled = true;
                    }
                }

                if (e.dataField === "atSite") {
                    if ((e.row.data.atSite !== null) && (e.row.data.atSite === true)) {
                        e.editorOptions.disabled = true;
                    }

                    if ((e.row.data.drivingAtSite === null) || (e.row.data.drivingAtSite === false)) {
                        e.editorOptions.disabled = true;
                    }
                }

                if (e.dataField === "completed") {
                    if ((e.row.data.atSite === null) || (e.row.data.atSite === false)) {
                        e.editorOptions.disabled = true;
                    }
                }
            };
        };
    };

    return (
        <DataGrid
            showBorders={true}
            onSaved={handleSave}
            dataSource={props.data}
            onEditorPreparing={onEditorPreparing}
        >
            <Editing
                mode="row"
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={allowRowEditing}
            />
            <Column dataField="assigned_Site" caption="Assigned site" dataType="string" allowEditing={false} />
            <Column dataField="notes" caption="Notes" dataType="string" allowEditing={false} />
            <Column dataField="foreman_At_Site" caption="Foreman at site" dataType="string" allowEditing={false} />
            <Column dataField="equipment_At_Site" caption="Equipment at site" dataType="number" allowEditing={false} />
            <Column dataField="driver" caption="Driver" dataType="string" allowEditing={false} />
            <Column dataField="swamper" caption="Swamper" dataType="string" allowEditing={false} />

            <Column dataField="eta" caption="ETA" dataType="datetime" allowEditing={true} />
            <Column dataField="drivingAtSite" caption="Driving to site" dataType="boolean" allowEditing={true} />
            <Column dataField="atSite" caption="At Site" dataType="boolean" allowEditing={true} />
            <Column dataField="completed" caption="Completed" dataType="boolean" allowEditing={true} />
        </DataGrid>
    );
});


export const STSchedulesMobileView: React.FC<ISTScheduleDataGrid> = React.memo((props: ISTScheduleDataGrid) => {

    const [update, setUpdate] = useState<ISTSchedules | undefined>(undefined);
    const [updateIndex, setUpdateIndex] = useState<number | undefined>(undefined);

    const handleSave = () => {
        if (update) {
            props.onSave(update);
            setUpdateIndex(undefined);
        }
    };

    const onCancel = (): void => {
        setUpdate(undefined);
        setUpdateIndex(undefined);
    };

    const handleUpdate = (index: number): void => {
        setUpdateIndex(index);
        setUpdate(props.data[index]);
    };

    const onDrivingToSite = (checked: boolean): void => {
        if (update) {
            setUpdate({
                ...update,
                drivingAtSite: checked
            });
        };
    };

    const atSite = (checked: boolean): void => {
        if (update) {
            setUpdate({
                ...update,
                atSite: checked
            });
        };
    };

    const completed = (checked: boolean): void => {
        if (update) {
            setUpdate({
                ...update,
                completed: checked
            });
        };
    };

    const onETA = (date: string): void => {
        if (update) {
            setUpdate({
                ...update,
                eta: new Date(date).toISOString()
                //.slice(0, 19).replace('T', ' ')
            });
        };
    };

    const allowRowEditing = (data: ISTSchedules): true | undefined => {
        return (data.status === "Completed") ? true : undefined;
    };

    return (
        <>
            {props.data
                .map((q, index) => {
                    const currentIndex = (index === updateIndex);
                    const x = (update && currentIndex) ? update : q;

                    return (
                        <LAPaperWithLessPadding key={index} className="mb-2">
                            <LAGrid>

                                <LAGridItem xs={12} sm={12} md={6}>
                                    <strong>Assigned site: </strong>
                                    <span>{x.assigned_Site}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={12} md={6}>
                                    <strong>Notes: </strong>
                                    <span>{x.notes}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <strong>Foreman at site: </strong>
                                    <span>{x.foreman_At_Site}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <strong>Equipment at site: </strong>
                                    <span>{x.equipment_At_Site}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <strong>Driver: </strong>
                                    <span>{x.driver}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <strong>Swamper: </strong>
                                    <span>{x.swamper}</span>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <strong>ETA: </strong>
                                    {currentIndex ?
                                        <LADatePicker
                                            id={index}
                                            value={x.eta}
                                            onChange={onETA}
                                            enableTime={true}
                                        /> :
                                        <span>{((x.eta === null) || (x.eta.length === 0)) ? " " : getDate(true, x.eta)}</span>}
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <LACheckBox
                                        label="Driving to site"
                                        onChange={onDrivingToSite}
                                        value={x.drivingAtSite ?? false}
                                        disabled={(!currentIndex || ((x.status === "At Site") || (x.status === "Completed") || (x.status === "Driving to Site"))) ? true : undefined} />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <LACheckBox
                                        label="At site"
                                        onChange={atSite}
                                        value={x.atSite ?? false}
                                        disabled={(currentIndex && (x.drivingAtSite === true) && (x.status !== "At Site") && (x.status !== "Completed")) ? undefined : true} />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={6}>
                                    <LACheckBox
                                        label="Completed"
                                        onChange={completed}
                                        value={x.completed ?? false}
                                        disabled={(currentIndex && (x.atSite === true) && (x.status !== "Completed")) ? undefined : true} />
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    {(currentIndex) && <LAButton
                                        disabled={allowRowEditing(x)}
                                        label="Save"
                                        onClick={() => handleSave()}
                                    />}

                                    {(!currentIndex) && <LAButton
                                        disabled={allowRowEditing(x)}
                                        label="Edit"
                                        onClick={() => handleUpdate(index)}
                                    />}

                                    {(currentIndex) && <LASecondaryButton
                                        className="m-2"
                                        label="Cancel"
                                        onClick={onCancel}
                                    />}

                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithLessPadding>
                    );
                })}
        </>
    );
});