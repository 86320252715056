import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { IStore, IDispatch } from "../../redux/reducers";
import { ROUTE } from "../routes";
import { getTokenFromUrl } from "../shared/constExports";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LAPaperWithPadding } from "../shared/paper";
import { DARK_RED_COLOR, MEDIA_QUERY_PHONE } from "../shared/theme";


interface IKantechHomeStoreProps {
};

interface IKantechHomeDispatchProps {
};

interface IKantechHomeState {
    showHeader: boolean;
    showFooter: boolean;
};

const KantechHomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
        
    };

    .homeMenu {
        color: #000;
        height: 90px;
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        border: 2px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        margin-right: 10%;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10%;
        text-shadow: 0.5px 1px #719de3;
    };

    .TileSpacing{
        padding: 20px;
    }

    .FilterSytle{
        padding-top: 20px !important;
    }

    .HRtitle {
        color: #5d95f0;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IKantechHomeProps =
    RouteComponentProps &
    IKantechHomeDispatchProps &
    IKantechHomeStoreProps;

class KantechHome extends Component<IKantechHomeProps, IKantechHomeState> {
    public constructor(props: IKantechHomeProps) {
        super(props);
        this.state = {
            showHeader: true,
            showFooter: true
        };
    }

    public render(): ReactNode {
        return (
            <KantechHomeStyles>
                <LAGrid spacing={2} className="text-center">

                    <LAGridItem xs={12}>
                        <h2 className="title">SIL - KANTECH</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAGrid className="TileSpacing">

                            <LAGridItem xs={6} sm={4} md={2}>
                                <div className="homeMenu" onClick={this.OnAdjustment}>
                                    <h2 className="appTitle HRtitle">Adjustments</h2>
                                </div>
                            </LAGridItem>

                            <LAGridItem xs={6} sm={4} md={2}>
                                <div className="homeMenu" onClick={this.onHistory}>
                                    <h2 className="appTitle HRtitle">History</h2>
                                </div>
                            </LAGridItem>

                            <LAGridItem xs={6} sm={4} md={2}>
                                <div className="homeMenu" onClick={this.onAttendance}>
                                    <h2 className="appTitle HRtitle">Attendance</h2>
                                </div>
                            </LAGridItem>

                        </LAGrid>
                    </LAGridItem>

                </LAGrid>
            </KantechHomeStyles>
        );

    }

    private OnAdjustment = (): void => {
        this.props.history.push({
            pathname: ROUTE.SIL.KANTECH.KANTECH_ADJUSTMENTS,
            search: getTokenFromUrl(false)
        });
    };

    private onAttendance = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_Attendance.rdl", "_blank");
    };

    private onHistory = (): void => {
        window.open("https://sharepoint.sureway.ca/SIL/_layouts/15/ReportServer/RSViewerPage.aspx?rv:RelativeReportUrl=/SIL/Reports/Kantech_report.rdl", "_blank");
    };
}

const mapStateToProps = (state: IStore): IKantechHomeStoreProps => ({
});

const mapDispatchToProps = (dispatch: IDispatch): IKantechHomeDispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(KantechHome);