import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, isSucceeded, Server } from "../../../redux/server";
import { ById, SurewayAPIResponse, ToolRentalsResponse } from "../../shared/publicInterfaces";
import { getTokenFromUrl,  undefinedFunction, userName } from "../../shared/constExports";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import { WHITE_COLOR } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { ROUTE } from "../../routes";
import queryString from "query-string";
import RequestStatus from "../../shared/requestStatusSnackbar";
import PageSpacing from "../../shared/pageSpacing";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { IShopModel, IShopModelRequest } from "../../../redux/field/getModels/getShopModelConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { getShopModelsLoadAction } from "../../../redux/field/getModels/getShopModelActions";
import { IAddShopModelRequest } from "../../../redux/field/addModels/addShopModelsConstants";
import { IUpdateShopModelRequest } from "../../../redux/field/updateModels/updateShopModelsConstants";
import { addShopModelLoadAction } from "../../../redux/field/addModels/addShopModelsActions";
import { updateShopModelLoadAction } from "../../../redux/field/updateModels/updateShopModelsActions";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getShopModels } from "../../../redux/field/getModels/getShopModelAccessor";
import { addShopModel } from "../../../redux/field/addModels/addShopModelsAccessor";
import { updateShopModel } from "../../../redux/field/updateModels/updateShopModelsAccessor";
import { getLookups } from "../../../redux/field/getLookups/getLookupsAccessor";
import { IShopLookups, IShopLookupsRequest } from "../../../redux/field/getLookups/getLookupsConstants";
import { getLookupsLoadAction } from "../../../redux/field/getLookups/getLookupsActions";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../shared/roleBaseAccessForShop";


interface IShopModelStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    addShopModel: Server<SurewayAPIResponse<string>>;
    updateShopModel: Server<SurewayAPIResponse<string>>;
    getModels: Server<SurewayAPIResponse<ById<IShopModel>>>;
    getDropDownData: Server<SurewayAPIResponse<IShopLookups>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
};

interface IShopModelDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getShopModelsRequest: (data: IShopModelRequest) => unknown;
    addModelRequest: (data: IAddShopModelRequest) => unknown;
    updateModelRequest: (data: IUpdateShopModelRequest) => unknown;
    getDropDownDataRequest: (data: IShopLookupsRequest) => unknown;
};


interface IShopModelOwnProps {

};

interface IShopModelState {
    model: IShopModel;
    errors: ById<IFieldErrorKeyValue>;
};

const ShopModelStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
`;

type IShopModelProps = RouteComponentProps
    & IShopModelStoreProps
    & IShopModelDispatchProps
    & IShopModelOwnProps;

class ShopModel extends PureComponent<IShopModelProps, IShopModelState> {

    public constructor(props: IShopModelProps) {
        super(props);
        this.state = {
            errors: {
                "make": { key: "make", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "model": { key: "model", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "unit_Number": { key: "unit_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "manufacturer_Name": { key: "manufacturer_Name", message: FIELD_VALIDATOR_ERRORS.REQUIRED }
            },
            model: {
                id: 0,
                created: "",
                modified: "",
                make: "",
                model: "",
                unit_Number: "",
                created_By: "",
                modified_By: "",
                manufacturer_ID: 0,
                manufacturer_Name: ""
            }
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IShopModelProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addShopModel !== prevProps.addShopModel) {
                if (isSucceeded(this.props.addShopModel)) {
                    this.handleCancel();
                };
            };

            if (this.props.updateShopModel !== prevProps.updateShopModel) {
                if (isSucceeded(this.props.updateShopModel)) {
                    this.handleCancel();
                };
            };
        }
    };


    public render(): ReactNode {

        const { model, errors } = this.state;
        const { updateShopModel, addShopModel, getDropDownData, validateShopGroup } = this.props;
        const dropDown = hasPayload(getDropDownData) ? getDropDownData.payload.response : { manufacturers: [] };
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;
        return (
            <PageSpacing title="Equipment List - Model" description="Equipment List - Model" fixedSpaceOnSmallerScreens={true}>
                       <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>
                <ShopModelStyles>

                    <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                    <h2 className="text-center">{model.id > 0 ? "VIEW/UPDATE " : "ADD "} MODEL</h2>
                    <hr />

                    <LAPaperWithPadding>
                        <LAGrid spacing={2}>

                            <LAGridItem xs={6}>
                                <LATextField
                                    variant="outlined"
                                    label="Make"
                                    fullWidth={true}
                                    name="make"
                                    disabled={readOnly}
                                    value={model.make}
                                    onChange={this.onChange}
                                    errorText={errors["make"] ? errors["make"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={6}>
                                <LATextField
                                variant="outlined"
                                    label="Model"
                                    fullWidth={true}
                                    name="model"
                                    onChange={this.onChange}
                                    value={model.model}
                                    disabled={readOnly}
                                    errorText={errors["model"] ? errors["model"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={6}>
                                <LATextField
                                variant="outlined"
                                    label="Unit Number"
                                    fullWidth={true}
                                    name="unit_Number"
                                    onChange={this.onChange}
                                    value={model.unit_Number}
                                    disabled={readOnly}
                                    errorText={errors["unit_Number"] ? errors["unit_Number"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={6}>
                                <LAAutoComplete
                                    multiple={false}
                                    disabled={readOnly}
                                    autoHighlight={true}
                                    name="manufacturer_Name"
                                    filterSelectedOptions={true}
                                    option={dropDown.manufacturers}
                                    getOptionLabel="manufacturer_Name"
                                    selectionRemove={undefinedFunction}
                                    onChange={this.handleDropDownChange}
                                    dropDownPlaceHolder="Make"
                                    errorText={errors["manufacturer_Name"] ? errors["manufacturer_Name"].message : undefined}
                                    value={(hasPayload(getDropDownData) && model.manufacturer_ID) ? dropDown.manufacturers.find(x => x.id === model.manufacturer_ID) : ""}
                                    defaultValue={(hasPayload(getDropDownData) && model.manufacturer_ID) ? dropDown.manufacturers.find(x => x.id === model.manufacturer_ID) : ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                    onSave={this.onSave}
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > 0 ? true : readOnly}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>

                    <RequestStatus requestStatus={addShopModel.kind} successMessage="Model successfully saved" />
                    <RequestStatus requestStatus={updateShopModel.kind} successMessage="Model successfully updated" />
                </ShopModelStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.MODEL.INDEX,
            search: getTokenFromUrl(false)
          });
    };

    private onSave = (): void => {
        const data = this.state.model;
        if (hasPayload(this.props.getToken))
            if (this.state.model.id === 0) {
                this.props.addModelRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Make: data.make,
                        Model: data.model,
                        Created_By: this.props.getToken.payload.response.upn,
                        Unit_Number: data.unit_Number,
                        Modified_By: this.props.getToken.payload.response.upn,
                        Manufacturer_ID: data.manufacturer_ID.toString(),
                    }
                });
            } else {
                this.props.updateModelRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: data.id,
                        Make: data.make,
                        Model: data.model,
                        Created_By: data.created_By,
                        Unit_Number: data.unit_Number,
                        Modified_By: this.props.getToken.payload.response.upn,
                        Manufacturer_ID: data.manufacturer_ID.toString(),
                    }
                });
            };
    };

    private handleDropDownChange = (event: unknown, value: { id: number, manufacturer_Name: string } | "", name?: string): void => {
        let errors = this.state.errors;

        if (name)
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.manufacturer_Name : "", errors, true);

        if (name)
            this.setState({
                ...this.state,
                errors,
                model: {
                    ...this.state.model,
                    manufacturer_ID: (value !== "" && value !== null) ? value.id : 0,
                    manufacturer_Name: (value !== "" && value !== null) ? value.manufacturer_Name : ""
                }
            });
    };

    private onChange = (name: string, value: string): void => {
        let errors = this.state.errors;
        errors = this.errorChecker(name, value, errors, true);

        this.setState({
            ...this.state,
            errors,
            model: {
                ...this.state.model,
                [name]: value
            }
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getModels) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getShopModelsRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if ((id !== "0") && (hasPayload(this.props.getModels)) && (this.state.model.id === 0)) {
            const model = this.props.getModels.payload.response[Number(id)];

            if (model)
                this.setState({ model, errors: {} });
        };

    };

}

const mapStateToProps = (state: IStore): IShopModelStoreProps => ({
    getToken: getToken(state),
    getModels: getShopModels(state),
    addShopModel: addShopModel(state),
    updateShopModel: updateShopModel(state),
    getDropDownData: getLookups(state),
    validateShopGroup: validateShopGroup(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IShopModelDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getShopModelsRequest: (data: IShopModelRequest) => dispatch(getShopModelsLoadAction(data)),
    addModelRequest: (data: IAddShopModelRequest) => dispatch(addShopModelLoadAction(data)),
    updateModelRequest: (data: IUpdateShopModelRequest) => dispatch(updateShopModelLoadAction(data)),
    getDropDownDataRequest: (data: IShopLookupsRequest) => dispatch(getLookupsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopModel);