import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_COUPLERS_REQUEST, ICoupler, IGetCouplerRequest } from "./getCouplersConstants";

export interface IGetCouplersLoadAction {
    type: IGET_COUPLERS_REQUEST.REQUEST;
    data: IGetCouplerRequest
}
export const getCouplersLoadAction = (data: IGetCouplerRequest): IGetCouplersLoadAction => ({
    type: IGET_COUPLERS_REQUEST.REQUEST,
    data
});
export interface IGetCouplersSuccessAction {
    type: IGET_COUPLERS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<ICoupler>>;
}
export const getCouplersLoadSuccessAction = (list: SurewayAPIResponse<ById<ICoupler>>): IGetCouplersSuccessAction => ({
    type: IGET_COUPLERS_REQUEST.SUCCESS,
    list
});
export interface IGetCouplersLoadFailedAction {
    type: IGET_COUPLERS_REQUEST.FAILED;
    message: string;
}
export const getCouplersLoadFailedAction = (message: string): IGetCouplersLoadFailedAction => ({
    type: IGET_COUPLERS_REQUEST.FAILED,
    message
});
