import { Grid, Button, Box, Modal, Backdrop, Typography, TextField, InputLabel} from "@mui/material";
import React from 'react';
import {addTrailerRate, getTrailerRates} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {MobileBoxStyle} from '../../../../shared/styles';

interface IProps{
    closeEquipmentMoveForm:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
    units:any[];
    trailer_list_options:any[];
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }

  var trailer_rate:any = {description:'',trailer_id:0,rate:0,ara_rate:0};
  var trailer_list_info:string[] = [];
var grid_xs_width:number = 4;
export default class AddNewTrailerRate extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], units:[], trailer_list_options:[]};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
   
    closePopup(){
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'rate':
                trailer_rate.rate = event.target.value;
                break;
            case 'ara_rate':
                trailer_rate.ara_rate = event.target.value;
                break;
            case 'trailer_config':
              trailer_rate.description = event.target.value;
              break;
        }
        this.setState({});
    }
    async onSave(){
        let isUnique = true;
        if(trailer_rate.rate!="" && trailer_rate.rate !=null && trailer_rate.description!='' && trailer_rate.description!=null){
          this.state.trailer_list_options.map((elem:any) => {
            if(elem.toLowerCase().trim() === trailer_rate.description.toLowerCase().trim()){
              isUnique = false;
              this.setState({});
            }
          });
          if(isUnique){
            var res:any = await addTrailerRate(trailer_rate);
            if(res.status == 200){
                alert("Successfully added new trailer rate");
                this.props.closeEquipmentMoveForm();
                this.props.refreshGrid();
            }
            else{
              alert("Error adding trailer rate. Please refresh page");
            }
          }
          else{
            alert("Trailer Config must be unique");
          }
        }
        else{
          alert("Trailer name and rate is mandatory");
        }
    }
    async componentDidMount(){
      if(window.innerWidth <=760){
        grid_xs_width = 12;
      }
      else{
        grid_xs_width = 4;
      }
        let temp_trailer_list:any[] = [];
        trailer_list_info = await getTrailerRates();
        trailer_list_info.map((trailer:any) => {
            temp_trailer_list.push(trailer.description);
        })
        this.setState({trailer_list_options:temp_trailer_list});    
    }
    render(){
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                     <Box sx={window.innerWidth <= 760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Adding New Trailer Rate</Typography>
                      </Grid>
                      
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Trailer Config</InputLabel>
                      <TextField style={{backgroundColor:'white'}} name="trailer_config" onChange={this.handleChange}></TextField>
                    
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Rate</InputLabel>
                        <TextField style={{backgroundColor:'white'}} name="rate" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>ARA Rate</InputLabel>
                        <TextField style={{backgroundColor:'white'}}  name="ara_rate" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}}variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                    </Grid>
                    </Box>
                    </Modal>
        );
    }
}