import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_MOVE_REQUEST_REQUEST, IMoveRequest } from "./getMoveRequestConstants";
import { IGetMoveRequestLoadAction, IGetMoveRequestLoadFailedAction, IGetMoveRequestSuccessAction } from "./getMoveRequestActions";

type Actions =
    | IGetMoveRequestLoadAction
    | IGetMoveRequestSuccessAction
    | IGetMoveRequestLoadFailedAction
    | IFlushDataSuccessAction;

export const GetMoveRequestReducer = (state: Server<IMoveRequest[]> = notLoaded, action: Actions): Server<IMoveRequest[]> => {
    switch (action.type) {
        case IGET_MOVE_REQUEST_REQUEST.REQUEST:
            return loading;

        case IGET_MOVE_REQUEST_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MOVE_REQUEST_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};