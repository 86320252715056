import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST {
    REQUEST = "getRepairLineByMechanicId/GET_REPAIR_LINE_BY_MECHANIC_ID_REQUEST",
    SUCCESS = "getRepairLineByMechanicId/GET_REPAIR_LINE_BY_MECHANIC_ID_SUCCESS",
    FAILED = "getRepairLineByMechanicId/GET_REPAIR_LINE_BY_MECHANIC_ID_FAILED"
};

export interface IGetRepairLineByMechanicIdRequest extends ISurewayTokenRequestBody {
    request:{
        mechanic_id: number;
     };
};

export interface IRepairLineByMechanicId {
    id: number;
    date: string;
    status: string;
    priority: string;
    work_Order_No: string;
};
