import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_MACHINE_RECEIVER_REQUEST, IAddUpdateMachineReceiverRequest } from "./addMachineReceiverConstants";

export interface IAddMachineReceiverLoadAction {
    type: IADD_MACHINE_RECEIVER_REQUEST.REQUEST;
    data: IAddUpdateMachineReceiverRequest
}
export const addMachineReceiverLoadAction = (data: IAddUpdateMachineReceiverRequest): IAddMachineReceiverLoadAction => ({
    type: IADD_MACHINE_RECEIVER_REQUEST.REQUEST,
    data
});
export interface IAddMachineReceiverSuccessAction {
    type: IADD_MACHINE_RECEIVER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addMachineReceiverLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddMachineReceiverSuccessAction => ({
    type: IADD_MACHINE_RECEIVER_REQUEST.SUCCESS,
    list
});
export interface IAddMachineReceiverLoadFailedAction {
    type: IADD_MACHINE_RECEIVER_REQUEST.FAILED;
    message: string;
}
export const addMachineReceiverLoadFailedAction = (message: string): IAddMachineReceiverLoadFailedAction => ({
    type: IADD_MACHINE_RECEIVER_REQUEST.FAILED,
    message
});
