
export enum IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST {
    REQUEST = "getRepairLineVersionHistory/GET_REPAIR_LINE_VERSION_HISTORY_REQUEST",
    SUCCESS = "getRepairLineVersionHistory/GET_REPAIR_LINE_VERSION_HISTORY_SUCCESS",
    FAILED = "getRepairLineVersionHistory/GET_REPAIR_LINE_VERSION_HISTORY_FAILED"
};

export interface IGetRepairLineVersionHistoryRequest {
    request: {
        ID: number;
        Type: string;
    };
    token: string;
};

export interface IGetRepairLineVersionHistory {
    id: number;
    itemID: number;
    operation: number;
    attachment_ID: number;
    repair_Attachment: string;
    unit_ID: number;
    meter_Hours: number;
    initiated_Date: string;
    unit_No: string;
    attachment_No: string;
    attachment_Unit_No: string;
    requested_By: string;
    assigned_Mech: string;
    status: string;
    comments: string;
    estimated_Completion_Date: string;
    date_Completed: string;
    send_Email: string;
    email_Log: string;
    manager_Instructions: string;
    parts_Instructions: string;
    sub_Location_ID: number;
    wsS_ID: number;
    work_Order_No: number;
    items: string;
    mechanic_Names: string;
    assigned_Mechanic_ID: number;
    send_To_Shop: string;
    repair_Location: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};