import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IUPDATE_ST_SCHEDULES_REQUEST {
    REQUEST = "updateSTSchedules/UPDATE_ST_SCHEDULES_REQUEST",
    SUCCESS = "updateSTSchedules/UPDATE_ST_SCHEDULES_SUCCESS",
    FAILED = "updateSTSchedules/UPDATE_ST_SCHEDULES_FAILED"
};

export interface IUpdateSTSchedulesRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
        eta: string;
        status: string;
        foreman_At_Site: string;
        equipment_At_Site: number;
        service_Truck: string;
        Modified_By: string;
    }
};