import React from "react";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import DataGrid, { Column, Editing, Lookup } from "devextreme-react/data-grid";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { WorkingSiteLogSubHeader } from "../../header/WorkingSiteLogSubHeader";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getWorkingSiteLogsOnCall } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallAccessor";
import { updateWorkingSiteLogOnCall } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallAccessor";
import { getWorkingSiteLogsOnCallLoadAction } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallActions";
import { updateWorkingSiteLogOnCallLoadAction } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallActions";
import { IUpdateWorkingSiteLogOnCallRequest } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallConstants";
import { IWorkingSiteLogOnCall, IWorkingSiteLogOnCallRequest } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogsOnCall/getWorkingSiteLogsOnCallConstants";
import { IFieldLookup, IFieldLookupRequest, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { IRole } from "../../../redux/getAuthorization/getAuthorizationConstants";
import LAErrorBox from "../../shared/errorBox";

interface IWorkingSiteLogsOnCallStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    updateStatus: Server<SurewayAPIResponse<string>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    getWorkingSiteLog: Server<SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>>;
};

interface IWorkingSiteLogsOnCallDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getWorkingSiteLogsOnCallRequest: (data: IWorkingSiteLogOnCallRequest) => unknown;
    updateWorkingSiteLogOnCall: (data: IUpdateWorkingSiteLogOnCallRequest) => unknown;
};


interface IWorkingSiteLogsOnCallOwnProps {

};

interface IWorkingSiteLogsOnCallState {
    data: IWorkingSiteLogOnCall[];
};

const WorkingSiteLogsOnCallStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IWorkingSiteLogsOnCallProps = RouteComponentProps
    & IWorkingSiteLogsOnCallStoreProps
    & IWorkingSiteLogsOnCallDispatchProps
    & IWorkingSiteLogsOnCallOwnProps;

class WorkingSiteLogsOnCall extends PureComponent<IWorkingSiteLogsOnCallProps, IWorkingSiteLogsOnCallState> {

    public constructor(props: IWorkingSiteLogsOnCallProps) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWorkingSiteLogsOnCallProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { data } = this.state;
        const { getWorkingSiteLog, getToken, getLookup, updateStatus } = this.props;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const lookup = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];

        return (
            <PageSpacing title="Working Site Logs" description="FIELD - WORKING SITE LOGS ON CALL" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <>
                    <WorkingSiteLogSubHeader
                        {...this.props}
                    />
                    <WorkingSiteLogsOnCallStyles>

                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <h2>WORKING SITE LOGS ON CALL</h2><br />
                                <hr />

                                {getWorkingSiteLog.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Working Site Logs..." />}
                                {getWorkingSiteLog.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Working Site Logs..." />}

                                {getWorkingSiteLog.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <DevDataGrid
                                                data={data}
                                                role={getRole}
                                                lookup={lookup}
                                                onSave={this.handleSave}
                                            />
                                        </LAGridItem>
                                    </LAGrid>}
                            </LAGridItem>
                        </LAGrid>
                    </WorkingSiteLogsOnCallStyles>

                    <RequestStatus requestStatus={updateStatus.kind} successMessage="Data has been updated successfully" />
                </>}
            </PageSpacing>
        );
    }


    private handleSave = (data: IWorkingSiteLogOnCall): void => {
        if(hasPayload(this.props.getToken)){
            this.props.updateWorkingSiteLogOnCall({
                token: this.props.getToken.payload.response.token,
                request: {
                    ID: data.id,
                    Type: data.type,
                    Person: data.person,
                    Modified_By: this.props.getToken.payload.response.upn
                }
            });
        };
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (isNotLoaded(this.props.getLookup))
                this.getLook();

            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getWorkingSiteLog)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

            if (hasPayload(this.props.getWorkingSiteLog)) {
                this.setState({
                    data: Object.values(this.props.getWorkingSiteLog.payload.response)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getWorkingSiteLogsOnCallRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.getToken))
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Working_Site_Logs"
                }
            });
    };

}

const mapStateToProps = (state: IStore): IWorkingSiteLogsOnCallStoreProps => ({
    getToken: getToken(state),
    getLookup: getFieldLookups(state),
    updateStatus: updateWorkingSiteLogOnCall(state),
    getWorkingSiteLog: getWorkingSiteLogsOnCall(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IWorkingSiteLogsOnCallDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getWorkingSiteLogsOnCallRequest: (data: IWorkingSiteLogOnCallRequest) => dispatch(getWorkingSiteLogsOnCallLoadAction(data)),
    updateWorkingSiteLogOnCall: (data: IUpdateWorkingSiteLogOnCallRequest) => dispatch(updateWorkingSiteLogOnCallLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingSiteLogsOnCall);



interface IDevDataGrid {
    role: IRole;
    lookup: IForeman[];
    data: IWorkingSiteLogOnCall[];
    onSave: (data: IWorkingSiteLogOnCall) => void;
};

const DevDataGrid: React.FC<IDevDataGrid> = React.memo((props: IDevDataGrid) => {

    const handleSave = (e: any) => {
        if (e) {
            if (e.changes.length > 0) {
                if (e.changes[0])
                    props.onSave(e.changes[0].data);
            }
        }
    };

    return (
        <DataGrid
            showBorders={true}
            onSaved={handleSave}
            dataSource={props.data}
        >
            <Editing
                mode="row"
                allowAdding={false}
                allowUpdating={true}
                allowDeleting={false}
            />
            <Column dataField="type" caption="Type" dataType="string" allowEditing={false} />
            <Column
                dataField="person"
                caption="Person"
                allowEditing={true}
            >
                <Lookup dataSource={props.lookup} displayExpr="display_Name" valueExpr="upn" />
            </Column>
            <Column dataField="email" caption="Email" dataType="string" allowEditing={false} />
            <Column dataField="mobile" caption="Mobile" dataType="string" allowEditing={false} />
            <Column dataField="modified_By" caption="Modified By" dataType="string" allowEditing={false} />
            <Column dataField="modified" caption="Modified" dataType="datetime" allowEditing={false} />
        </DataGrid>
    );
});