import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IDELETE_FILTER_PART_REQUEST } from "./deleteFilterPartConstants";
import { IDeleteFilterPartLoadAction, IDeleteFilterPartLoadFailedAction, IDeleteFilterPartSuccessAction } from "./deleteFilterPartActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IDeleteFilterPartLoadAction
    | IDeleteFilterPartSuccessAction
    | IDeleteFilterPartLoadFailedAction
    | IFlushDataSuccessAction;

export const DeleteFilterPartReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IDELETE_FILTER_PART_REQUEST.REQUEST:
            return loading;

        case IDELETE_FILTER_PART_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IDELETE_FILTER_PART_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};