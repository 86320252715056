import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_HAZARD_NEW_REQUEST, IUpdateHazardNewRequest } from "./updateHazardNewConstants";

export interface IUpdateHazardNewLoadAction {
    type: IUPDATE_HAZARD_NEW_REQUEST.REQUEST;
    data: IUpdateHazardNewRequest
}
export const updateHazardNewLoadAction = (data: IUpdateHazardNewRequest): IUpdateHazardNewLoadAction => ({
    type: IUPDATE_HAZARD_NEW_REQUEST.REQUEST,
    data
});
export interface IUpdateHazardNewSuccessAction {
    type: IUPDATE_HAZARD_NEW_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateHazardNewLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateHazardNewSuccessAction => ({
    type: IUPDATE_HAZARD_NEW_REQUEST.SUCCESS,
    message
});
export interface IUpdateHazardNewLoadFailedAction {
    type: IUPDATE_HAZARD_NEW_REQUEST.FAILED;
    message: string;
}
export const updateHazardNewLoadFailedAction = (message: string): IUpdateHazardNewLoadFailedAction => ({
    type: IUPDATE_HAZARD_NEW_REQUEST.FAILED,
    message
});
