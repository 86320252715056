import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import LAErrorBox from "../shared/errorBox";
import LATextField from "../shared/textField";
import PasswordTextField from "../shared/passwordField";
import { IDispatch, IStore } from "../../redux/reducers";
import { STATUS_ENUM, Server } from "../../redux/server";
import { LALoadingButton } from "../shared/loadingButton";
import PublicActionPageWrapper from "../shared/publicActionWrapper";
import { MEDIA_QUERY_PHONE_NUMBER } from "../shared/theme";
import { validateOperatorLogin } from "../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginAccessor";
import { validateOperatorLoginLoadAction } from "../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginActions";
import { localStorageTokenRequestAction } from "../../redux/field/operatorLogs/localStorageToken/localStorageTokenActions";
import { IValidateOperatorLogin, IValidateOperatorLoginRequest } from "../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginConstants";

interface IExternalUserLoginStoreProps {
    validateOperatorLoginStatus: Server<IValidateOperatorLogin>;
};

interface IExternalUserLoginDispatchProps {
    RequestLocalToken: () => unknown;
    validateOperatorLogRequest: (data: IValidateOperatorLoginRequest) => unknown;
};

interface IExternalUserLoginState {
    username: string;
    password: string;
};

type IExternalUserLoginProps =
    IExternalUserLoginDispatchProps &
    IExternalUserLoginStoreProps;

class ExternalUserLogin extends Component<IExternalUserLoginProps, IExternalUserLoginState> {
    public constructor(props: IExternalUserLoginProps) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    };

    public componentDidMount(): void {
        this.authorizationCheck();
    };

    public render(): ReactNode {

        const { username, password } = this.state;
        const { validateOperatorLoginStatus } = this.props;

        return (
            <PublicActionPageWrapper title="Operator Login" width={500} minWidth={MEDIA_QUERY_PHONE_NUMBER} minHeight={383}>
                <LAGrid alignItems="center" justify="center">
                    <LAGridItem xs={12}>
                        {validateOperatorLoginStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text={validateOperatorLoginStatus.message} className="mb-3" />}
                        <LATextField
                            id="username"
                            name="username"
                            label="Username"
                            value={username}
                            required={true}
                            onChange={this.onChange}
                            fullWidth={true}
                            onPressEnter={this.onSubmit}
                            className="mb-3"
                        />
                        <PasswordTextField
                            id="password"
                            name="password"
                            label="Password"
                            value={password}
                            required={true}
                            onChange={this.onChange}
                            fullWidth={true}
                            onPressEnter={this.onSubmit}
                            className="mb-2"
                        />

                        <div className="mb-4">
                            <span>Forgot your password? Contact Sureway IT Department</span>
                        </div>

                        <LALoadingButton
                            label="Login"
                            fullWidth={true}
                            onClick={this.onSubmit}
                            disabled={validateOperatorLoginStatus.kind === STATUS_ENUM.LOADING ? true : undefined}
                            isLoadingStatus={validateOperatorLoginStatus.kind === STATUS_ENUM.LOADING ? true : false}
                        />
                    </LAGridItem>
                </LAGrid>
            </PublicActionPageWrapper>
        )
    }

    private onChange = (name: string, value: string): void => {
        this.setState({
            ...this.state,
            [name]: value
        });
    };

    private authorizationCheck = (): void => {
        this.props.RequestLocalToken();
    };

    private onSubmit = (): void => {
        this.props.validateOperatorLogRequest({
            request: {
                email: this.state.username,
                password: this.state.password
            }
        });
    };

}

const mapStateToProps = (state: IStore): IExternalUserLoginStoreProps => ({
    validateOperatorLoginStatus: validateOperatorLogin(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalUserLoginDispatchProps => ({
    RequestLocalToken: (): unknown => dispatch(localStorageTokenRequestAction()),
    validateOperatorLogRequest: (request: IValidateOperatorLoginRequest): unknown => dispatch(validateOperatorLoginLoadAction(request)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ExternalUserLogin);