import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_SIGNATURE_HISTORY_REQUEST, IGetFormSignatureHistory } from "./getFormSignatureHistoryConstants";
import { IGetFormSignatureHistoryLoadAction, IGetFormSignatureHistoryLoadFailedAction, IGetFormSignatureHistorySuccessAction } from "./getFormSignatureHistoryActions";

type Actions =
    | IGetFormSignatureHistoryLoadAction
    | IGetFormSignatureHistorySuccessAction
    | IGetFormSignatureHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFormSignatureHistoryReducer = (state: Server<SurewayAPIResponse<IGetFormSignatureHistory[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetFormSignatureHistory[]>> => {
    switch (action.type) {
        case IGET_FORM_SIGNATURE_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_SIGNATURE_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_SIGNATURE_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};