import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../../shared/theme";
import { getUnitHistoryById } from "../../../redux/field/getUnitHistoryById/getUnitHistoryByIdAccessor";
import { getUnitHistoryByIdLoadAction } from "../../../redux/field/getUnitHistoryById/getUnitHistoryByIdActions";
import { IGetUnitHistoryById, IGetUnitHistoryByIdRequest } from "../../../redux/field/getUnitHistoryById/getUnitHistoryByIdConstants";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import 'devextreme/dist/css/dx.light.css';
import DataGrid, {
    Column, Export, FilterRow, FilterPanel, HeaderFilter, Paging, Pager, SearchPanel, ColumnChooser,
  } from 'devextreme-react/data-grid';
import { RowPreparedEvent } from "devextreme/ui/data_grid";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import LALinkButton from "../../shared/linkButton";
import { GPSHistoryPopup } from "./equipmentListMap";
import { ROUTE } from "../../routes";
import { LAPopover } from "../../shared/popOver";
import { webConfig } from "../../../utils/webConfig";
import GoogleMapReact from "google-map-react";
import { defaultMapProps } from "../../shared/gpsRecord";
import { LocationPinIcon } from "../../shared/icons";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";

interface IEquipmentHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getUnitReport: Server<SurewayAPIResponse<ById<IGetUnitHistoryById>>>;
};

interface IEquipmentHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getUnitReportRequest: (request: IGetUnitHistoryByIdRequest) => unknown;
};

interface IEquipmentHistoryOwnProps {

};

interface IEquipmentHistoryState {
    view: string[];
    searchValue: string;
    data: IGetUnitHistoryById[];
    gpsHistoryUnitNumber: number | undefined;
    openGps: boolean;
    latitude: string | undefined;
    longitude: string | undefined;
};

const EquipmentHistoryStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
    };
`;

type IEquipmentHistoryProps = RouteComponentProps
    & IEquipmentHistoryStoreProps
    & IEquipmentHistoryDispatchProps
    & IEquipmentHistoryOwnProps;

class EquipmentHistory extends PureComponent<IEquipmentHistoryProps, IEquipmentHistoryState> {

    public constructor(props: IEquipmentHistoryProps) {
        super(props);
        this.state = {
            data: [],
            view: ["ET Move Request", "PM", "SOCT Meter History", "Unit_History", "Repair Line", "Filter_Cuts", "Inspection"],
            searchValue: "",
            gpsHistoryUnitNumber: undefined,
            openGps: false,
            latitude: undefined,
            longitude: undefined,
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IEquipmentHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {


        const { view, searchValue, gpsHistoryUnitNumber, openGps, latitude, longitude } = this.state;
        const { getToken, getUnitReport } = this.props;
        const getRole = pageAccessCheck(getToken, "etAccess");
        const filterData = this.filterDataByView();
        const unitNumber = filterData.find(x => x.unit_Number)?.unit_Number;
        // console.log(filterData)

        return (
            <PageSpacing title="UNIT HISTORY" description="EQUIPMENT - UNIT HISTORY" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <>
                        <EquipmentHistoryStyles>
                            {getUnitReport.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Unit History Data..." />}
                            {getUnitReport.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load unit history..." />}

                            {getUnitReport.kind === STATUS_ENUM.SUCCEEDED && <>
                                <h2 className="text-center">UNIT HISTORY - {unitNumber}</h2>
                                <hr />

                                <LAGrid direction="row" justify="flex-end" alignItems="center">
                                        <label htmlFor="from-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#e4ec7d" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>SOCT Meter History</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#aadcf3" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>SOCT Equipment Move</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#cd8df3" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>ET Move Request</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#92da96" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>PM</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#f38d8d" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Repair Line</strong>
                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#33D8C4" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Unit_History</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#EA2D69" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Owner_History</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#6DDAD5" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Certificate_History</strong>

                                        <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#d580a9" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Filter Cuts</strong>
                                        <label htmlFor="Inspection" style={{ marginRight: "5px" }}>
                                            <div style={{ width: "15px", height: "15px", backgroundColor: "#567ad5" }}></div>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Inspection</strong>
                                </LAGrid>
                                <br />
                                <LAGrid>
                                    {/* <LAGridItem xs={12} md={8}>
                                        <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                            <FormControlLabel value="ET Move Request" control={<Radio />} label="ET Move Request" />
                                            <FormControlLabel value="PM" control={<Radio />} label="PM" />
                                            <FormControlLabel value="SOCT Meter History" control={<Radio />} label="SOCT Meter History" />
                                            <FormControlLabel value="Unit_History" control={<Radio />} label="Unit History" />
                                            <FormControlLabel value="Repair_History" control={<Radio />} label="Repair Line History" />
                                            <FormControlLabel value="all" control={<Radio />} label="All" />
                                        </RadioGroup>
                                    </LAGridItem> */}
                                    <LAGridItem xs={12} md={8}>
                                        <FormGroup className="view-btn" row>
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('ET Move Request')} onChange={this.handleCheckboxChange} name="ET Move Request" value="ET Move Request" />}
                                                label="ET Move Request"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('PM')} onChange={this.handleCheckboxChange} name="PM" value="PM" />}
                                                label="PM"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('SOCT Meter History')} onChange={this.handleCheckboxChange} name="SOCT Meter History" value="SOCT Meter History" />}
                                                label="SOCT Meter History"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('Unit_History')} onChange={this.handleCheckboxChange} name="Unit_History" value="Unit_History" />}
                                                label="Unit History"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('Repair Line')} onChange={this.handleCheckboxChange} name="Repair_History" value="Repair Line" />}
                                                label="Repair Line History"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('Filter_Cuts')} onChange={this.handleCheckboxChange} name="Filter_Cuts" value="Filter_Cuts" />}
                                                label="Filter Cuts History"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={view.includes('Inspection')} onChange={this.handleCheckboxChange} name="Inspection" value="Inspection" />}
                                                label="Inspection"
                                            />
                                        </FormGroup>
                                    </LAGridItem>

                                    <LAGridItem xs={12} md={2}>
                                        <LALinkButton onClick={this.openGPSHistory} label="GPS Tracking"></LALinkButton>
                                    </LAGridItem>

                                    {/* <LAGridItem xs={12} md={2}>
                                        <LALinkButton onClick={this.openInspectionHistory} label="Inspection History"></LALinkButton>
                                    </LAGridItem> */}

                                    <LAGridItem xs={12}>
                                        <DataGrid focusedRowEnabled={true}
                                            hoverStateEnabled={true}
                                            dataSource={filterData}
                                            keyExpr="roW_ID"
                                            showBorders={true}
                                            onRowPrepared={(e: RowPreparedEvent<any, unknown>) => {
                                                if (e.data) {
                                                    switch (e.data.type) {
                                                        case 'SOCT Meter History':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'SOCT Scheduled Service':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'SOCT Scheduled Service - Completed':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'SOCT Equipment Move':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'ET Move Request':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'PM':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Repair Line':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Unit_History':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Owner_History':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Certificate_History':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Filter_Cuts':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                        case 'Inspection':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                    }
                                                    switch (e.data.custom_Service) {
                                                        case 'Yes':
                                                            e.rowElement.style.backgroundColor = e.data.color;
                                                            break;
                                                    }
                                                    switch (e.data.meter_Reset) {
                                                        case 'Yes':
                                                            e.rowElement.style.backgroundColor = 'red';
                                                            break;
                                                    }
                                                }
                                            }}
                                            wordWrapEnabled={true}
                                        >
                                            <ColumnChooser
                                                mode="select"
                                                height={window.innerHeight - 100}
                                                enabled={true}
                                            />
                                            <SearchPanel
                                                width={340}
                                                visible={true}
                                                // text={searchValue}
                                                placeholder="Search for..."
                                                // onTextChange={this.handleValueChange}
                                            />
                                            <FilterRow visible={true} />
                                            <FilterPanel visible={true} />
                                            <HeaderFilter visible={true} allowSearch={true} />
                                            <Paging enabled={true} defaultPageSize={100} />
                                            <Pager
                                                visible={true}
                                                allowedPageSizes={[100,200,300]}
                                                displayMode={'full'}
                                                showPageSizeSelector={true}
                                                showInfo={true}
                                                showNavigationButtons={true} 
                                            />
                                            <Export enabled={true} allowExportSelectedData={true} />
                                            
                                            {/* {(view !== "SOCT Meter History") && */}
                                                <Column dataField="button" caption="View" type="buttons" width={100} alignment="left" headerCellRender={renderTitleHeader}
                                                    cellRender={(val) => {
                                                        return (
                                                            (val.data.type !== "SOCT Meter History" && val.data.type !== "SOCT Scheduled Service" && 
                                                                val.data.type !== "SOCT Scheduled Service - Completed" && val.data.type !== "SOCT Equipment Move") && 
                                                                <LALinkButton className="linkUrl" label="View"  onClick={() => this.onCustomClick(val.data)} />
                                                        )
                                                    }} 
                                                    allowReordering={false} 
                                                />   
                                                {/* }   */}
                                            <Column dataType="string" dataField="type" caption="Type" alignment="left" headerCellRender={renderTitleHeader} />
                                            {/* <Column dataType="string" dataField="unit_Number" caption="Unit #" alignment="left" headerCellRender={renderTitleHeader} visible={false}/> */}
                                            <Column defaultSortOrder="desc" dataType="date" dataField="date" caption="Specified Date" alignment="left" headerCellRender={renderTitleHeader} />
                                            <Column dataType="string" dataField="hours" caption="Meter Reading" alignment="left" width={100} headerCellRender={renderTitleHeader} />
                                            <Column dataType="string" dataField="location" caption="Location" alignment="left" headerCellRender={renderTitleHeader} />
                                            <Column dataType="string" dataField="service_Type" caption="Service Type" alignment="left" headerCellRender={renderTitleHeader} />
                                            <Column dataType="string" dataField="comments" caption="Details" alignment="left" headerCellRender={renderTitleHeader} />
                                            <Column dataType="string" dataField="custom_Service" caption="Custom Service" alignment="left" headerCellRender={renderTitleHeader} />
                                            <Column dataType="buttons" dataField="gps" type="buttons" caption="GPS"  headerCellRender={renderTitleHeader} 
                                                cellRender={(e:any) => {
                                                    if(e.row.data.latitude && e.row.data.longitude ) { 
                                                        return <LALinkButton label="View GPS" onClick={() => this.onViewGpsClick(e.row.data.latitude, e.row.data.longitude)} />
                                                    }
                                                    else {
                                                        return <div></div>
                                                    }
                                                }} 
                                            />
                                            <Column dataType="string" dataField="created_By" caption="Created By" alignment="left" headerCellRender={renderTitleHeader} />
                                            
                                        </DataGrid>
                                    </LAGridItem>

                                    {(openGps) && <GPSPopup
                                        onChange={undefinedFunction}
                                        onCancel={this.handleGpsClose}
                                        open={openGps}
                                        lat={latitude ?? null}
                                        long={longitude ?? null}
                                    />}

                                </LAGrid>
                            </>}

                            {gpsHistoryUnitNumber && <GPSHistoryPopup
                                token={getToken}
                                unitNumber={gpsHistoryUnitNumber}
                                unit={unitNumber}
                                open={gpsHistoryUnitNumber ? true : false}
                                onCancel={this.handleGPSHistoryClose}
                            />}

                        </EquipmentHistoryStyles>
                    </>}
            </PageSpacing>
        );
    }

    private onViewGpsClick = (latitude: any, longitude: any) : void => {
        // let mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude} `;
        // window.open(mapUrl, "_blank");
        this.setState({openGps: true, latitude: latitude, longitude: longitude});
    }

    private handleGpsClose = (): void => {
        this.setState({ openGps: false, latitude: undefined, longitude: undefined });
    };

    private onCustomClick = (data: any): void => {
        // console.log(data);
        if(data.type === "Repair Line") {
            // window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.item_ID.toString() , "_blank" )
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_ID.toString() + "&defect_ID=" + data.item_ID.toString() , "_blank" )
        }
        if(data.type === "ET Move Request") {
            window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE + data.item_ID.toString() , "_blank" )
        }
        if(data.type === "PM") {
            window.open(ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT + "?id=" + data.item_ID.toString() , "_blank" )
        }
        if(data.type === "Unit_History" || data.type === "Owner_History" || data.type === "Certificate_History") {
            window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT + "?id=" + data.unit_ID.toString() , "_blank" )
        }
        if(data.type === "Filter_Cuts") {
            window.open(ROUTE.FIELD.FILTER_CUT.FILTER_CUT + "?id=" + data.item_ID.toString() , "_blank" )
        }
        if(data.type === "Inspection") {
            window.open(ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORM + "?id=" + data.item_ID.toString() + "&unit_ID=" + data.unit_ID.toString() , "_blank" )
        }
    };

    private handleGPSHistoryClose = (): void => {
        this.setState({ gpsHistoryUnitNumber: undefined });
    };

    private openGPSHistory = () => {
        const query: any = queryString.parse(this.props.location.search);
        this.setState({ gpsHistoryUnitNumber: query.id });
    }
    private openInspectionHistory = () => {
        const query: any = queryString.parse(this.props.location.search);
        window.open(ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORMS + "?keyword=" + query.id.toString() , "_blank" )
    }

    private filterDataByView = () => {
        const { view, data } = this.state;
        const type = [...this.state.view];

        if(view.includes("ET Move Request")) {
            type.push("SOCT Equipment Move")
        } 
        if(view.includes("PM")) {
            type.push("SOCT Scheduled Service")
            type.push("SOCT Scheduled Service - Completed")
        } 
        if(view.includes("Unit_History")) {
            type.push("Owner_History")
            type.push("Certificate_History")
        }
        if(view.includes("Filter_Cuts")) {
            type.push("Filter_Cuts")
        }

        // console.log(type)

        const filteredData = type.length > 0 ? data.filter((x) => type.includes(x.type)) : data;
        return filteredData;

        // switch (view) {
        //     case "all":
        //         return data;
        //     case "ET Move Request":
        //         return data.filter((x: any) => x.type === view || x.type === "SOCT Equipment Move");
        //     case "PM":
        //         return data.filter((x: any) => x.type === view || x.type === "SOCT Scheduled Service");
        //     case "Unit_History":
        //         return data.filter((x: any) => x.type === view || x.type === "Owner_History");
        //     case "SOCT Meter History":
        //         return data.filter((x: any) => x.type === view);
        //     case "Repair_History":
        //         return data.filter((x: any) => x.type === "Repair Line");
        //     default:
        //         return data;
        // }
    };

    private handleCheckboxChange = (event:any) => {
        const { value } = event.target;
        let updatedView = [...this.state.view];

        if (updatedView.includes(value)) {
            updatedView = updatedView.filter((item) => item !== value);
        } else {
            updatedView.push(value);
        }
        this.setState({ view: updatedView });
    };

    // private onViewClick = (e: any, view: string): void => {
    //     this.setState({ view });
    // };

    private handleValueChange = (e: any) => {
        this.setState({ searchValue: e.trim() });
      };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (isNotLoaded(this.props.getUnitReport) && hasPayload(this.props.getToken)) {
            const query: any = queryString.parse(this.props.location.search);

            if (query.id)
                this.props.getUnitReportRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        id: query.id
                    }
                });
        };

        if (hasPayload(this.props.getUnitReport) && hasPayload(this.props.getToken))
            this.setState({ data: Object.values(this.props.getUnitReport.payload.response) });
    };

}

const mapStateToProps = (state: IStore): IEquipmentHistoryStoreProps => ({
    getToken: getToken(state),
    getUnitReport: getUnitHistoryById(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IEquipmentHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getUnitReportRequest: (request: IGetUnitHistoryByIdRequest): unknown => dispatch(getUnitHistoryByIdLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentHistory);


const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
};

interface IGPSPopupProps {
    lat: string | null;
    long: string | null;
    open: boolean;
    onCancel: () => void;
    onChange: (lat: number, long: number) => void;
};

const GPSPopupStyles = styled(LAPaperWithPadding)`
    .popup-div {
        width: 20px;
        margin: auto;
    };
`;

const GPSPopup: React.FC<IGPSPopupProps> = React.memo((props: IGPSPopupProps) => {

    const onCancel = () => props.onCancel();

    const handleMapClick = (event: any) => {
        props.onChange(event.lat, event.lng);
    };

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <GPSPopupStyles>
            <LAGrid spacing={3} className="text-center">
                <LAGridItem xs={12}>
                    <div style={{ height: (window.screen.height - 300), width: (window.screen.width - 100) }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: webConfig.googleMapKey }}
                            defaultCenter={
                                (props.lat === null || props.long === null || props.lat === undefined || props.long === undefined || props.lat === '' || props.long === '')
                                ? {lat: 53.5280348, lng: -113.3612641}
                                : { lat: +props.lat, lng: +props.long }}
                                options={map => ({
                                    scrollwheel: true,
                                    mapTypeId: map.MapTypeId.HYBRID
                                })}
                            defaultZoom={defaultMapProps.zoom}
                            onClick={handleMapClick}
                        >
                            <Marker
                                lat={props.lat !== null ? +props.lat : ""}
                                lng={props.long !== null ? +props.long  : ""}
                            />
                        </GoogleMapReact>
                    </div>
                </LAGridItem>

            </LAGrid>
        </GPSPopupStyles>
    </LAPopover>
});


const Marker = (props: any) => (
    <LocationPinIcon width={40} height={40} color={RED_COLOR} />
);