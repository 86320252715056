import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_MANUALS_REQUEST, IEquipmentManual, IGetEquipmentManualsRequest } from "./getEquipmentManualsConstants";

export interface IGetEquipmentManualsLoadAction {
    type: IGET_EQUIPMENT_MANUALS_REQUEST.REQUEST;
    data: IGetEquipmentManualsRequest
}
export const getEquipmentManualsLoadAction = (data: IGetEquipmentManualsRequest): IGetEquipmentManualsLoadAction => ({
    type: IGET_EQUIPMENT_MANUALS_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentManualsSuccessAction {
    type: IGET_EQUIPMENT_MANUALS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IEquipmentManual>>;
}
export const getEquipmentManualsLoadSuccessAction = (list: SurewayAPIResponse<ById<IEquipmentManual>>): IGetEquipmentManualsSuccessAction => ({
    type: IGET_EQUIPMENT_MANUALS_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentManualsLoadFailedAction {
    type: IGET_EQUIPMENT_MANUALS_REQUEST.FAILED;
    message: string;
}
export const getEquipmentManualsLoadFailedAction = (message: string): IGetEquipmentManualsLoadFailedAction => ({
    type: IGET_EQUIPMENT_MANUALS_REQUEST.FAILED,
    message
});
