import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IStore, IDispatch } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { userName, undefinedFunction, getTokenFromUrl } from '../../shared/constExports';
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IGetPayrollMechanicLogsSummaryRequest, IPayrollMechanicLogsSummary } from "../../../redux/field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryConstants";
import { getPayrollMechanicLogsSummary } from "../../../redux/field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryAccessor";
import { getPayrollMechanicLogsSummaryLoadAction } from "../../../redux/field/repairLine/getPayrollMechanicLogsSummary/getPayrollMechanicLogsSummaryActions";
import { SummaryIcon } from "../../shared/icons";
import { LAButton } from "../../shared/buttons";
import LADatePicker from "../../shared/datePicker";
import LALinkButton from "../../shared/linkButton";


interface IPayrollContractorsMechanicLogsSummaryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getPayrollMechanicLogsSummary: Server<SurewayAPIResponse<IPayrollMechanicLogsSummary[]>>;
};

interface IPayrollContractorsMechanicLogsSummaryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getPayrollMechanicLogsSummaryRequest: (data: IGetPayrollMechanicLogsSummaryRequest) => unknown;
};

interface IPayrollContractorsMechanicLogsSummaryState {
    data: IPayrollMechanicLogsSummary[];
    editMode: boolean;
    serverError: string | undefined;
    dateRange: Date[] | undefined,
};

const PayrollContractorsMechanicLogsSummaryStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    
    .dx-datagrid-save-button {
        display: none;
    };

    .dx-datagrid-cancel-button {
        display: none;
    };

    .back-home {       
        float: right;    
    };

    .clear-filters{       
        float: left;
        background-color: rgb(168, 0, 0);        
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IPayrollContractorsMechanicLogsSummaryProps =
    RouteComponentProps
    & IPayrollContractorsMechanicLogsSummaryStoreProps
    & IPayrollContractorsMechanicLogsSummaryDispatchProps;

class PayrollContractorsMechanicLogsSummary extends PureComponent<IPayrollContractorsMechanicLogsSummaryProps, IPayrollContractorsMechanicLogsSummaryState> {

    public constructor(props: IPayrollContractorsMechanicLogsSummaryProps) {
        super(props);
        this.state = {
            data: [],
            editMode: false,
            serverError: undefined,
            dateRange: [],
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IPayrollContractorsMechanicLogsSummaryProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        };
    }

    public render(): ReactNode {

        const { getToken, getPayrollMechanicLogsSummary } = this.props;
        const { kind } = getPayrollMechanicLogsSummary;
        const { data, dateRange } = this.state;
        const today = new Date();
        // const minDate = new Date(new Date().setDate(today.getDate() - 30)).toDateString();
        const maxDate = new Date().toDateString();
        const records = dateRange ? data.filter((x) => dateRange[1] ? ((new Date(x.date) >= dateRange[0]) && (new Date(x.date) <= dateRange[1])) : true) : data
        // console.log(data)

        return (
            <PageSpacing title="Payroll  Mechanic Logs (Contractors) Summary" description="FIELD - PAYROLL MECHANIC LOGS (Contractors) Summary" fixedSpaceOnSmallerScreens={true}>
                <PayrollContractorsMechanicLogsSummaryStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid spacing={1} className="text-center">

                            {/* <LAButton
                                label="Back to Payroll"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            /> */}
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    className=""
                                    label="Explorer Upload"
                                    onClick={this.handleExplorerUpload}
                                    startIcon={<SummaryIcon color={WHITE_COLOR} />}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <LAButton
                                    className=""
                                    label="Report"
                                    onClick={this.handleReport}
                                    startIcon={<SummaryIcon color={WHITE_COLOR} />}
                                />
                                <h2>MECHANIC LOGS (CONTRACTORS) PAYROLL SUMMARY</h2>
                                <hr />

                                <strong>
                                    Date Range: <LADatePicker
                                        key={"clear"}
                                        mode="range"
                                        value={dateRange}
                                        // minDate={minDate}
                                        maxDate={maxDate}
                                        onChange={this.setDateRange}
                                    />
                                </strong>

                                <LALinkButton onClick={this.clearDateRange} label="clear"></LALinkButton>
                            </LAGridItem>

                            <LAGridItem xs={12}>

                                {(kind === STATUS_ENUM.LOADING) &&
                                    <LALoading className="text-center" message="Loading Summary. Please Wait..." />
                                }

                                {(kind === STATUS_ENUM.FAILED) &&
                                    <LAErrorBox color={WARNING_COLOR} text="Sorry Failed to Load Data. Try to refresh the window..." />
                                }

                                {((kind === STATUS_ENUM.SUCCEEDED)) &&
                                    <LADevExtremeGrid
                                        id="PayrollMechanicLogsContractorsSummary"
                                        data={records}
                                        searchPanel={true}
                                        filterHeader={true}
                                        columnsHiding={true}
                                        customRowColor={true}
                                        key="id"
                                        getRefreshDataCall={this.getDataForTable}
                                        export={true}
                                        onClick={undefinedFunction}
                                        onClearDateRange={this.clearDateRange}
                                        paging={100}
                                        height={4000}
                                        exportFileName="PayrollMechanicLogsContractorsSummary"
                                        storageKey="PayrollMechanicLogsContractorsSummarySessionStorage"
                                        columns={[
                                            { name: "date", caption: "Date", type: "date", sortDesc: true, allowEditing: false },
                                            { name: "mechanic_Name", caption: "Mechanic Name", type: "string", allowEditing: false },
                                            { name: "emp_Code", caption: "Employee Code", type: "string", allowEditing: false },
                                            { name: "start_Time", caption: "Start Time", type: "string", allowEditing: false,},
                                            { name: "end_Time", caption: "End Time", type: "string", allowEditing: false },
                                            { name: "defect_Log_Hours", caption: "Defect Log Hours", type: "number", allowEditing: false },
                                            { name: "lunch", caption: "Lunch", type: "number", allowEditing: false },
                                            { name: "misc_Hours", caption: "Misc Hours", type: "number", allowEditing: false },
                                            { name: "total_Hours", caption: "Total Hours", type: "number", allowEditing: false },
                                            { name: "loa", caption: "LOA", type: "string", allowEditing: false },
                                            { name: "uploaded", caption: "Uploaded", type: "string", allowEditing: false },
                                            // { name: "start_Time", caption: "Start Time", type: "string",allowEditing: false, renderCell: (e:any) => { return <div>{e.row.data.start_Time + '-' + e.row.data.end_Time  } </div>}},
                                        ]}
                                    />
                                }
                            </LAGridItem>
                            
                        </LAGrid>
                    </LAPaperWithPadding>
                </PayrollContractorsMechanicLogsSummaryStyles>
            </PageSpacing>
        );
    }

    private clearDateRange = (): void => {
        this.setState({ dateRange: undefined });
    };

    private setDateRange = (date: string, date2?: string): void => {
        if (date2) {
            this.setState({ dateRange: [new Date(date), new Date(date2)] });
        } else {
            this.setState({ dateRange: [new Date(date)] });
        }
    };

    private handleExplorerUpload = (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS);
    };

    private handleReport= (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_CONTRACTORS_REPORT);
    };

    private handleCancel = (): void => {
        // this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL,
            search: getTokenFromUrl(false)
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        
        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.payrollContractorAccess) {
                if (isNotLoaded(this.props.getPayrollMechanicLogsSummary)) {
                    this.getDataForTable();
                };

                if (hasPayload(this.props.getPayrollMechanicLogsSummary)) {
                    const data: IPayrollMechanicLogsSummary[] = this.props.getPayrollMechanicLogsSummary.payload.response;

                    this.setState({ data });
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (this.props.getToken.payload.response.payrollContractorAccess)) {
                this.props.getPayrollMechanicLogsSummaryRequest({
                    token: this.props.getToken.payload.response.token,
                    request : {
                        Type: "Contractors"
                    }
                });
        }
    };

}

const mapStateToProps = (state: IStore): IPayrollContractorsMechanicLogsSummaryStoreProps => ({
    getToken: getToken(state),
    getPayrollMechanicLogsSummary: getPayrollMechanicLogsSummary(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IPayrollContractorsMechanicLogsSummaryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getPayrollMechanicLogsSummaryRequest: (data: IGetPayrollMechanicLogsSummaryRequest) => dispatch(getPayrollMechanicLogsSummaryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollContractorsMechanicLogsSummary);