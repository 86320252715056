import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_HAZARD_REQUEST, IAddHazardRequest } from "./addHazardConstants";


export interface IAddHazardLoadAction {
    type: IADD_HAZARD_REQUEST.REQUEST;
    data: IAddHazardRequest
}
export const addHazardLoadAction = (data: IAddHazardRequest): IAddHazardLoadAction => ({
    type: IADD_HAZARD_REQUEST.REQUEST,
    data
});
export interface IAddHazardSuccessAction {
    type: IADD_HAZARD_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addHazardLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddHazardSuccessAction => ({
    type: IADD_HAZARD_REQUEST.SUCCESS,
    message
});
export interface IAddHazardLoadFailedAction {
    type: IADD_HAZARD_REQUEST.FAILED;
    message: string;
}
export const addHazardLoadFailedAction = (message: string): IAddHazardLoadFailedAction => ({
    type: IADD_HAZARD_REQUEST.FAILED,
    message
});
