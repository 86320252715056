import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IG_DRIVERS_ASSIGNMENTS_REQUEST, IDriversAssignment } from "./getDriversAssignmentConstants";
import { IGetDriversAssignmentLoadAction, IGetDriversAssignmentLoadFailedAction, IGetDriversAssignmentSuccessAction } from "./getDriversAssignmentActions";
import { ISaveDriversAssignmentSuccessAction } from "../saveDriversAssignment/saveDriversAssignmentActions";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "../saveDriversAssignment/saveDriversAssignmentConstants";

type Actions =
    | IGetDriversAssignmentLoadAction
    | IGetDriversAssignmentSuccessAction
    | ISaveDriversAssignmentSuccessAction
    | IGetDriversAssignmentLoadFailedAction
    | IFlushDataSuccessAction;

export const GetDriversAssignmentReducer = (state: Server<SurewayAPIResponse<ById<IDriversAssignment>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IDriversAssignment>>> => {
    switch (action.type) {
        case IG_DRIVERS_ASSIGNMENTS_REQUEST.REQUEST:
            return loading;

        case IG_DRIVERS_ASSIGNMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IG_DRIVERS_ASSIGNMENTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};