
export enum IGET_PM_LOOKUPS_REQUEST {
    REQUEST = "getPMLookups/GET_PM_LOOKUPS_REQUEST",
    SUCCESS = "getPMLookups/GET_PM_LOOKUPS_SUCCESS",
    FAILED = "getPMLookups/GET_PM_LOOKUPS_FAILED"
};

export interface IPMLookupsRequest {
    token: string;
};


export interface IPMLookups {
    serviceTypes: IPM_ServiceDueLookUp[];
    equipmentTypes: IPM_EquipmentTypeLookup[];
    equipments: IPM_EquipmentLookup[];
    makes: IPM_MakeLookup[];
};

export interface IPM_ServiceDueLookUp {
    id: number;
    service_Due_Name: string;
}

export interface IPM_EquipmentTypeLookup { 
    id: number;
    name: string;
};

export interface IPM_EquipmentLookup { 
    id: number;
    equipment_Type_ID: number;
    unit_Number: string;
    make_ID: number;
    model: string;
};

export interface IPM_MakeLookup {
    id: number;
    manufacturer_Name: string;
};