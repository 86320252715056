import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_DEPARTMENT_REQUEST, IAddDepartmentRequest } from "./addDepartmentConstants";


export interface IAddDepartmentLoadAction {
    type: IADD_DEPARTMENT_REQUEST.REQUEST;
    data: IAddDepartmentRequest
}
export const addDepartmentLoadAction = (data: IAddDepartmentRequest): IAddDepartmentLoadAction => ({
    type: IADD_DEPARTMENT_REQUEST.REQUEST,
    data
});
export interface IAddDepartmentSuccessAction {
    type: IADD_DEPARTMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addDepartmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddDepartmentSuccessAction => ({
    type: IADD_DEPARTMENT_REQUEST.SUCCESS,
    message
});
export interface IAddDepartmentLoadFailedAction {
    type: IADD_DEPARTMENT_REQUEST.FAILED;
    message: string;
}
export const addDepartmentLoadFailedAction = (message: string): IAddDepartmentLoadFailedAction => ({
    type: IADD_DEPARTMENT_REQUEST.FAILED,
    message
});
