import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_STYLE_REQUEST, IUpdateAttachmentStyleRequest } from "./updateStyleConstants";


export interface IUpdateAttachmentStyleLoadAction {
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.REQUEST;
    data: IUpdateAttachmentStyleRequest
}
export const updateAttachmentStyleLoadAction = (data: IUpdateAttachmentStyleRequest): IUpdateAttachmentStyleLoadAction => ({
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentStyleSuccessAction {
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentStyleLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentStyleSuccessAction => ({
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentStyleLoadFailedAction {
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentStyleLoadFailedAction = (message: string): IUpdateAttachmentStyleLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_STYLE_REQUEST.FAILED,
    message
});
