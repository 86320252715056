import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EXTERNAL_USERS_REQUEST, IExternalUser } from "./getExternalUsersConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetExternalUsersLoadAction, IGetExternalUsersLoadFailedAction, IGetExternalUsersSuccessAction, getExternalUsersLoadFailedAction, getExternalUsersLoadSuccessAction } from "./getExternalUsersActions";

export const getExternalUsersEpic: Epic = (
    action$: ActionsObservable<IGetExternalUsersLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetExternalUsersSuccessAction | IGetExternalUsersLoadFailedAction> =>
    action$.ofType(IGET_EXTERNAL_USERS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IExternalUser>>>(
                    END_POINTS.FIELD.EXTERNAL_USER.GET_EXTERNAL_USERS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IExternalUser>>): IGetExternalUsersSuccessAction => {
                            return getExternalUsersLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getExternalUsersLoadFailedAction(response.message)))
                    )
            )
        );