import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_LIST_GET_REQUEST, IUpdateAttachmentListGETRequest } from "./updateAttachmentListGETConstants";


export interface IUpdateAttachmentListGETLoadAction {
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.REQUEST;
    data: IUpdateAttachmentListGETRequest
}
export const updateAttachmentListGETLoadAction = (data: IUpdateAttachmentListGETRequest): IUpdateAttachmentListGETLoadAction => ({
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentListGETSuccessAction {
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentListGETLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentListGETSuccessAction => ({
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentListGETLoadFailedAction {
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentListGETLoadFailedAction = (message: string): IUpdateAttachmentListGETLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_LIST_GET_REQUEST.FAILED,
    message
});
