import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { EXTRA_LIGHT_YELLOW_COLOR, GREEN_COLOR, LIGHT_GREEN_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl,  pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { AttachmentSubHeader, AttachmentSubHeaderMobile } from "../../header/attachmentSubHeader";
// import { IAttachment, IGetAttachmentRequest } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
// import { getAttachmentsLoadAction } from "../../../redux/field/attachment/getAttachments/getAttachmentsActions";
// import { getAttachmentsStatus } from "../../../redux/field/attachment/getAttachments/getAttachmentsAccessor";
import { getAttachmentVersionHistoryLoadAction } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryActions";
import { IGetAttachmentVersionHistory, IGetAttachmentVersionHistoryRequest } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { getAttachmentVersionHistory } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryAccessor";
import { AttachmentVersionHistory } from "./attachmentVersionHistory";
import LAErrorBox from "../../shared/errorBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IGetAttachment, IGetAttachmentsRequest } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssConstants";
import { getAttachmentssLoadAction } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssActions";
import { getAttachmentssStatus } from "../../../redux/field/attachment/getAttachmentss/getAttachmentssAccessor";

interface IAttachmentsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    // getAttachmentList: Server<SurewayAPIResponse<ById<IAttachment>>>;
    getAttachments: Server<SurewayAPIResponse<ById<IGetAttachment>>>;
    getHistory: Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>>;
};

interface IAttachmentsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => unknown;
    getAttachmentssRequest: (data: IGetAttachmentsRequest) => unknown;
    getVersionHistory: (request: IGetAttachmentVersionHistoryRequest) => unknown;
};

interface IAttachmentsOwnProps {

};

interface IAttachmentsState {
    historyPopup: boolean;
    view: string;
};

const AttachmentsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IAttachmentsProps = RouteComponentProps
    & IAttachmentsStoreProps
    & IAttachmentsDispatchProps
    & IAttachmentsOwnProps;

class Attachments extends PureComponent<IAttachmentsProps, IAttachmentsState> {

    public constructor(props: IAttachmentsProps) {
        super(props);
        this.state = {
            historyPopup: false,
            view: "Active",
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable()
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAttachmentsProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };

    private onViewClick = (e:any, value:string): void => {
        this.setState({ view: value });

        // if (hasPayload(this.props.getToken)) {
        //     this.props.getFilterCutsRequest({
        //         token: this.props.getToken.payload.response.token,
        //         request : {
        //             view: value
        //         }
        //     });
        // };
    };


    public render(): ReactNode {

        const { historyPopup, view } = this.state;
        const { getAttachments, getToken, getHistory } = this.props;
        const getRole = pageAccessCheck(getToken, "attachmentAccess");
        const disabled = getRole === ReadOnly ? true : undefined;
        const data = hasPayload(getAttachments) ? Object.values(getAttachments.payload.response) : [];
        const filteredData = view === "Active" ? data.filter(x => x.status === "Active") : data;
        // console.log(filteredData)
        const popupData = hasPayload(getHistory) ? getHistory.payload.response : {};

        return (
            <PageSpacing title="Attachment List" description="FIELD - ATTACHMENT LIST" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <AttachmentsStyles>

                    <div className="hide-on-phone">
                        <AttachmentSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <AttachmentSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                            <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    disabled={disabled}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">ATTACHMENT LIST</h2>
                                <hr />

                                {getAttachments.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Attachment List..." />}
                                {getAttachments.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Attachment List..." />}

                                {getAttachments.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={filteredData}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={6000}
                                                paging={100}
                                                id="attachmentListList"
                                                customRowColor={true}
                                                columnChoose={true}
                                                onCustomClick={this.handleCustomClick}
                                                exportFileName="Attachments"
                                                storageKey="attachmentsListSessionKey"
                                                onRowPrepared={this.rowPrepAttachmentList}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "attachment_No", caption: "Attachment #", type: "string", sortAsc: true },
                                                    { name: "attached_Unit_No", caption: "Attached Unit #", type: "string" },
                                                    { name: "location", caption: "Location", type: "string" },
                                                    { name: "detachable", caption: "Detachable", type: "string" },
                                                    { name: "type", caption: "Type", type: "string" },
                                                    { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                                    { name: "style", caption: "Style", type: "string" },
                                                    { name: "weight", caption: "Weight (lbs)",width:100, type: "number" },
                                                    { name: "width", caption: "Width (in)",width:100, type: "number" },
                                                    { name: "length", caption: "Length (in)",width:100, type: "number" },
                                                    { name: "capacity", caption: "Capacity (cy)",width:100, type: "number" },
                                                    // { name: "make", caption: "Make", type: "string" },
                                                    // { name: "model", caption: "Model", type: "string" },
                                                    { name: "serial_Number", caption: "Serial Number", type: "string" },
                                                    // { name: "owner", caption: "Owner", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    // { name: "job_No", caption: "Job #", type: "string" },
                                                    { name: "inspection_Date", caption: "Inspection Date", type: "date" },
                                                    { name: "notes", caption: "Notes", type: "string" },
                                                    { name: "move_Status", caption: "Move Status", type: "string", renderCell: (e:any) => { return e.row.data.move_Status === "Initiated" || e.row.data.move_Status === "Dispatched" || e.row.data.move_Status === "Requested" || e.row.data.move_Status === "Moved" || e.row.data.move_Status === "En Route" ? <div className="link-btn" onClick={() => this.handleMoveStatusClick(e.row.data)}>{e.row.data.move_Status} </div> : <div>{e.row.data.move_Status}</div>}  },
                                                    { name: "repair_Status", caption: "Repair Status", type: "string", renderCell: (e:any) => { return e.row.data.repair_Status === "Active" ? <div className="link-btn" onClick={() => this.handleRepairStatusClick(e.row.data)}>{e.row.data.repair_Status} </div> : <div>{e.row.data.repair_Status}</div>} },
                                                    { name: "history", caption: "Version History", type: "button" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>

                        {historyPopup && <AttachmentVersionHistory 
                          data={popupData}
                          open={historyPopup}
                          status={getHistory.kind}
                          onCancel={this.handlePopupClose}
                        />}
                    </LAPaperWithPadding>
                </AttachmentsStyles>}
            </PageSpacing>
        );
    }

    private rowPrepAttachmentList = (e: any) => {
        if (e.data) {

            e.columns.map((item: any, index: number) => {

                if(item.caption === "Status") {
                    if(e.data.status === "Active") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                }
                if(item.caption === "Move Status") {
                    if(e.data.move_Status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if(e.data.move_Status === "Dispatched") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    if(e.data.move_Status === "Initiated") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    if(e.data.move_Status === "Requested") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                    if(e.data.move_Status === "En Route") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }
                if(item.caption === "Repair Status") {
                    if(e.data.repair_Status === "Active") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }
            });
        }
    }

    private handleCustomClick = (name: string, e: any): void => {
        const data: IGetAttachment = e.row.data;
        this.handleVersionHistory(data.id);
    };

    private handleRepairStatusClick = (data: IGetAttachment): void => {
        // console.log(data);
        if(data.repair_Line_ID && data.repair_Line_ID > 0) {
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString())
        }
    };

    private handleMoveStatusClick = (data: IGetAttachment): void => {
        // console.log(data);
        if(data.move_ID && data.move_ID > 0) {
            window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE + data.move_ID.toString())
        }
    };

    private handleVersionHistory = async (id: number): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            this.props.getVersionHistory({
                token: this.props.getToken.payload.response.token,
                request: {
                    ID: id
                }
            });
        }
        await this.setState({ historyPopup: true });
    };

    private handlePopupClose = (): void => {
        this.setState({ historyPopup: false });
    };
    
    private onEdit = (e: any): void => {
        // console.log(this.props)
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getAttachments)) {
                    this.getDataForTable();
                };
            } else {
                
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                  });
            }
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
            // this.props.getAttachmentsRequest({
            //     token: this.props.getToken.payload.response.token
            // });
            this.props.getAttachmentssRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAttachmentsStoreProps => ({
    getToken: getToken(state),
    getHistory: getAttachmentVersionHistory(state),
    // getAttachmentList: getAttachmentsStatus(state),
    getAttachments: getAttachmentssStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAttachmentsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => dispatch(getAttachmentsLoadAction(data)),
    getAttachmentssRequest: (data: IGetAttachmentsRequest) => dispatch(getAttachmentssLoadAction(data)),
    getVersionHistory: (request: IGetAttachmentVersionHistoryRequest): unknown => dispatch(getAttachmentVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);