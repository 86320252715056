import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENTS_REQUEST, IAttachments, IGetAttachmentsRequest } from "./getSurveyAttachmentsConstants";

export interface IGetAttachmentsLoadAction {
    type: IGET_ATTACHMENTS_REQUEST.REQUEST;
    data: IGetAttachmentsRequest
}
export const getAttachmentsLoadAction = (data: IGetAttachmentsRequest): IGetAttachmentsLoadAction => ({
    type: IGET_ATTACHMENTS_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentsSuccessAction {
    type: IGET_ATTACHMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IAttachments>>;
}
export const getAttachmentsLoadSuccessAction = (list: SurewayAPIResponse<ById<IAttachments>>): IGetAttachmentsSuccessAction => ({
    type: IGET_ATTACHMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentsLoadFailedAction {
    type: IGET_ATTACHMENTS_REQUEST.FAILED;
    message: string;
}
export const getAttachmentsLoadFailedAction = (message: string): IGetAttachmentsLoadFailedAction => ({
    type: IGET_ATTACHMENTS_REQUEST.FAILED,
    message
});
