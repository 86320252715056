
export enum IGET_SURVEY_LOOKUP_REQUEST {
    REQUEST = "getSurveyLookup/GET_SURVEY_LOOKUP_REQUEST",
    SUCCESS = "getSurveyLookup/GET_SURVEY_LOOKUP_SUCCESS",
    FAILED = "getSurveyLookup/GET_SURVEY_LOOKUP_FAILED"
};

export interface ISurveyLookupRequest {
    token: string;
    request: {
        page: "Machine_Control" | "Attachments" | "Survey_Pages";
    }
};

export interface ISurveyLookup {
    units: ISurveyUnit[];
    attachment_list: ISurveyAttachmentLst[];
    operators: ISurveyOperator[];
    wssList: ISurveyWSSList[];
    foremanList: ISurveyForemanList[];
};

export interface ISurveyForemanList {
    upn: string;
    display_Name: string;
};

export interface ISurveyWSSList {
    site: string;
    wsS_ID: number;
    site_Name: string;
    sub_Site_Name: string;
    job: string;
};

export interface ISurveyUnit {
    id: number;
    unit_Number: string;
    make: string;
    model: string;
    location_Details: string;
    active_Repair_Status: boolean;
    equipment_Type: string;
};

export interface ISurveyOperator {
    id: number;
    username: string;
    company: string;
    first_Name: string;
    last_Name: string;
    reporting_Manager: string;
};

export interface ISurveyAttachmentLst {
    id: number;
    attachment_No: string;
    type: string;
    coupler_Design: string;
    style: string;
    site: string;
    attached_Unit_No: string;
};