import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINES_REQUEST {
    REQUEST = "getRepairLines/GET_REPAIR_LINES_REQUEST",
    SUCCESS = "getRepairLines/GET_REPAIR_LINES_SUCCESS",
    FAILED = "getRepairLines/GET_REPAIR_LINES_FAILED"
};

export interface IGetRepairLineRequest extends ISurewayTokenRequestBody {
    request:{
        view: string;
     };
};

export interface IRepairLine {
    id: number;
    make?:string;
    model?:string;
    type?:string;
    site_Name?:string;
    priority: string;
    sub_Site_Name?:string;
    job_No?:string;
    meter_Hours?: number;
    initiated_Date: string;
    unit_No?: string;
    attached_Unit_No?: string;
    unit_ID: number;
    repair_Attachment: string;
    attachment_No?: string;
    attachment_ID: number;
    display?: string;
    requested_By: string;
    assigned_Mech: string;
    status: string;
    comments: string;
    manager_Instructions : string;
    sub_Location_ID?: number;
    location_Info: string;
    work_Order_No: string;
    estimated_Completion_Date: string;
    date_Completed: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    send_Email: string;
    email_Log: string;
    mechanic_Names: string;
    components: string;
    parts: string;
    partsInstruction?: string;
    technicianInstructions?: string;
    assigned_Mechanic_ID: number;
    items: IRepairLineItem[];
    drawings?: string | undefined;
    repair_Location?: string;
    repair_Type?: string;
    lube_Repair?: string;
};

export interface IRepairLineItem {
    id: number;
    unit_ID: number;
    work_Order_No: string;
    repair_Line_ID: number;
    attachmentCheckbox?: string;
    attachment_ID?: number;
    item_Attachment_ID?: number;
    component: string;
    description: string;
    priority: string;
    comments: string;
    foreman_Comments: string;
    status: string;
    images: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    date_Completed?: string;
    pictures: IRepairLinePicture[];
    component_Code: string;
    socT_Tracking: string;
    meter_Reading?: number;
    major_Component_Replacement: string;
    hours?: number;
    type?: string;
    major_Type?: string;
    component_Repaired_Location?: string;
    oeM_Part?: string;
    vendor?: string;
    exchange_Type?: string;
    condition?: string;
    warranty_Details?: string;
    beR_Details?: string;
    serial_No?: string;
    new_Major_Component_Replacement_Notes?: string;
    major_Component_Replacement_Notes?: string;
    mob_Demob: string;
    damaged: string;
    damaged_Notes?: string;
    warranty: string;
    parts: IRepairLineItemPart[];
    parts_Notes?: string;
    mechanic_Logs: IRepairLineLog[];
    drawings?: string | undefined;
    defect_Item: string;
    issue: string;
    repair_Type?: string;
    lube_Driver: string;
    manager_Instructions: string;
    parts_Instructions: string;
    assigned_Mech:string;
    mechanic_Comments:string;
    mechanic_Names: string;
    send_Email: string;
    repair_Location?: string;
    created_Location_Info?: string;
    repair_Location_Info?: string;
    winter_Repair ?: string;
    move_To_Shop ?: string;
    job_No?: string;
    cost_Code?: string;
    logs?: any;
    unit_No?: string;
    attachment_No?: string;
    inspection_Form_ID?: number;
};

export interface IRepairLineLog {
    id: number;
    name: string;
    date: string;
    comments: string;
    component_ID: number;
    hours: number;
    component: string;
    unit_Number: string;
    status: string;
    service_Truck: string;
};

export interface IRepairLinePicture {
    name: string;
    description: string;
    base64String: string | null;
};

export interface IRepairLineItemPart {
    id: number;
    component_ID: number;
    part_Name: string;
    part_Number: string;
    quantity: number;
    driver: string;
    status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};