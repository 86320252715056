import React, { useEffect, useState } from "react";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { undefinedFunction } from "../../shared/constExports";
import { END_POINTS } from "../../../redux/endpoints";
import { hasPayload, Server } from "../../../redux/server";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { ILocation, ISubLocation } from "../../../redux/field/getLocation/getLocationConstants";

interface ILocationHistoryPopupProps {
    id: number;
    open: boolean;
    onCancel: () => void;
    token: Server<SurewayAPIResponse<IToken>>;
};

interface ILocationHistory extends ILocation {
};

export const LocationHistoryPopup: React.FC<ILocationHistoryPopupProps> = React.memo((props: ILocationHistoryPopupProps) => {

    const onCancel = () => props.onCancel();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ILocationHistory[]>([]);

    const callEndpoint = (): void => {
        if (hasPayload(props.token))
            fetch(END_POINTS.FIELD.LOCATION_HISTORY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {
                        Type: "site",
                        ID: props.id
                    },
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    setData(Object.values(res.response));
                    setLoading(false);
                })
                .catch((res) => console.log(res));
    };

    useEffect(() => {
        callEndpoint();
    }, []);

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <LAPaperWithLessPadding>
        <div className="text-center">
                    <strong>
                    Location History
                    </strong>
                    <hr />
                    </div>
                        <LADevExtremeGrid
                            data={data}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}                            
                            height={600}
                            id="locationHistory"                            
                            columns={[
                                { name: "site_Name", caption: "Site Name", type: "string", width: 400 },
                                { name: "site_Address", caption: "Site Address", width: 200, type: "string" },
                                { name: "active", caption: "Active", width: 100, type: "string" },
                                { name: "no_Job_Number_Required", caption: "No Job Number Required", width: 150, type: "string" },
                                { name: "job_No", caption: "Job #", width: 100, type: "string" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "rental_Status", caption: "Rental", type: "string" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" }
                            ]}
                        />          
        </LAPaperWithLessPadding>
    </LAPopover>
});


//#region Sub_Location_History
interface ISubLocationHistory extends ISubLocation {
};

export const SubLocationHistoryPopup: React.FC<ILocationHistoryPopupProps> = React.memo((props: ILocationHistoryPopupProps) => {

    const onCancel = () => props.onCancel();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ISubLocationHistory[]>([]);

    const callEndpoint = (): void => {
        if (hasPayload(props.token))
            fetch(END_POINTS.FIELD.LOCATION_HISTORY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    request: {
                        Type: "subsite",
                        ID: props.id
                    },
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    setData(Object.values(res.response));
                    setLoading(false);
                })
                .catch((res) => console.log(res));
    };

    useEffect(() => {
        callEndpoint();
    }, []);

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <LAPaperWithLessPadding>
        <div className="text-center">
                    <strong>
                    Sub Working Sites History
                    </strong>
                    <hr />
                    </div>
                        <LADevExtremeGrid
                            data={data}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}                            
                            height={700}
                            id="subLocationHistory"                           
                            columns={[
                                { name: "itemID", caption: "ID", type: "number" },
                                { name: "sub_Site_Name", caption: "Sub Site Name", type: "string" },
                                { name: "sub_Site_Address", caption: "Sub Site Address", type: "string" },                                
                                { name: "active", caption: "Active", type: "string" },
                                { name: "latitude", caption: "Latitude", type: "string" },
                                { name: "longitude", caption: "Longitude", type: "string" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "service_Truck", caption: "Service Truck", type: "string" },
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                            ]}
                        />
        </LAPaperWithLessPadding>
    </LAPopover>
});

//#endregion