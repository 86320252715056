import { getETUserToken } from "../field/equipmentTracker/api/equipmenttracker/equipmentTrackerAPI";
import { getTokenFromUrl, token } from "./constExports";




export const handleTokenAccess = async() => {
 var tokenfromurl:any = getTokenFromUrl(token);
 let upn = null;
 if(tokenfromurl){
   if(tokenfromurl.includes("hasHistory")){
    tokenfromurl = tokenfromurl.split("&")[0];
   }
   console.log(tokenfromurl)
   let tokens:any = await getETUserToken(tokenfromurl);
   console.log(tokens);
   if(tokens.response != null){
     upn = tokens.response.upn;
   }
   else{
     upn = null;
   }
 }
 return upn;
}