
export enum IGET_FORM_SIGNATURE_HISTORY_REQUEST {
    REQUEST = "getFormSignatureHistory/GET_FORM_SIGNATURE_HISTORY_REQUEST",
    SUCCESS = "getFormSignatureHistory/GET_FORM_SIGNATURE_HISTORY_SUCCESS",
    FAILED = "getFormSignatureHistory/GET_FORM_SIGNATURE_HISTORY_FAILED"
};

export interface IGetFormSignatureHistoryRequest {
    request: {
        Form_ID: number;
        Emp_ID: number;
        Name: string;
    };
    token: string;
};

export interface IGetFormSignatureHistory {
    id: number;
    name: string;
    form_Sheet_ID: number;
    emp_ID?: number;
    signature: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};