import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_MECHANIC_LOGS_BY_ID_REQUEST, IMechanicLogById } from "./getMechanicLogsByIdConstants";
import { IGetMechanicLogsByIdLoadAction, IGetMechanicLogsByIdLoadFailedAction, IGetMechanicLogsByIdSuccessAction } from "./getMechanicLogsByIdActions";
import { IAddMechanicLogSuccessAction } from "../addMechanicLog/addMechanicLogActions";
import { IUpdateMechanicLogSuccessAction } from "../updateMechanicLog/updateMechanicLogActions";
import { IADD_MECHANIC_LOG_REQUEST } from "../addMechanicLog/addMechanicLogConstants";
import { IUPDATE_MECHANIC_LOG_REQUEST } from "../updateMechanicLog/updateMechanicLogConstants";

type Actions =
    | IGetMechanicLogsByIdLoadAction
    | IGetMechanicLogsByIdSuccessAction
    | IGetMechanicLogsByIdLoadFailedAction
    | IAddMechanicLogSuccessAction
    | IUpdateMechanicLogSuccessAction
    | IFlushDataSuccessAction;

export const GetMechanicLogsByIdReducer = (state: Server<SurewayAPIResponse<ById<IMechanicLogById>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMechanicLogById>>> => {
    switch (action.type) {
        case IGET_MECHANIC_LOGS_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANIC_LOGS_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANIC_LOGS_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};