import React from "react";
import { STATUS_ENUM } from "../../../redux/server";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { TableContainer } from "@material-ui/core";
import { undefinedFunction } from "../../shared/constExports";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { IGetMechanicTimecardVersionHistory } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";

interface IMechanicLogsVersionHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetMechanicTimecardVersionHistory>;
    // display: string | undefined;
};

export const MechanicLogsVersionHistory: React.FC<IMechanicLogsVersionHistoryProps> = React.memo((props: IMechanicLogsVersionHistoryProps) => {
    const data = props.data ? Object.values(props.data) : [];
    // console.log(data);

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "center" }}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>MECHANIC LOGS VERSION HISTORY</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Mechanic Logs Version History Component..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        
                        <LADevExtremeGrid
                            data={data}
                            // onEdit={onEdit}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="MechanicLogsVersionHistoryList"
                            customRowColor={true}
                            exportFileName="Mechanic-Logs-Version-History"
                            storageKey="mechanicLogsVersionHistorySessionKey"
                            columns={[
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "created", caption: "Created", type: "datetime", show: false },
                                { name: "created_By", caption: "Requested By", type: "string" },
                                { name: "date", caption: "Date", type: "date" },
                                { name: "mechanic_Name", caption: "Mechanic Name", type: "string" },
                                { name: "defect_Item", caption: "Defect Item", type: "string" },
                                { name: "display", caption: "Unit/Attachment", type: "string",  },
                                { name: "defect_Item", caption: "Defect Item", type: "string",  },
                                { name: "comments", caption: "Comments", type: "string",  },
                                { name: "hours", caption: "Hours", type: "number",  },
                                { name: "loa", caption: "LOA", type: "string",  },
                                { name: "component_Code", caption: "Component Code", type: "string",  },
                                { name: "work_Order_No", caption: "Work Order", type: "string",  },
                                { name: "job_No", caption: "Job No.", type: "string",  },
                                { name: "cost_Code", caption: "Cost Code", type: "string",  },
                                { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number",  },
                                { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string",  },
                                { name: "truck", caption: "Truck", type: "string",  },
                                // { name: "start_Time", caption: "Time", type: "string", renderCell: (e:any) => { return <div>{e.row.data.start_Time + '-' + e.row.data.end_Time  } </div>}},
                                // { name: "end_Time", caption: "End Time", type: "string", , show: false },
                                { name: "status", caption: "Status", type: "string"},
                                { name: "field_Log", caption: "Field Log", type: "string",  },
                                { name: "uploaded_Date", caption: "Uploaded Date", type: "datetime",  },
                                { name: "type", caption: "Type", type: "string",  },
                                { name: "payroll_Acknowledged", caption: "Payroll Acknowleged", type: "string", },
                                { name: "travel_Type", caption: "Travel Type", type: "string",   show: false},
                                { name: "truck_Hours", caption: "Travel Hours", type: "number",  show: false},
                                { name: "description", caption: "Description", type: "string",   show: false},
                                { name: "employee_Code", caption: "Employee Code", type: "string",   show: false},
                                { name: "repair_Location", caption: "Repair Location", type: "string",   show: false},
                                { name: "issue", caption: "Issue", type: "string",  show: false},
                                { name: "item_Repair_Location", caption: "Item Repair Location", type: "string",  show: false},
                                { name: "manager_Instructions", caption: "Manager Instructions", type: "string",  show: false},
                                { name: "defect_Status", caption: "Defect Status", type: "string",   show: false},
                                { name: "damaged", caption: "Damaged", type: "string",   show: false},
                                { name: "warranty", caption: "Warranty", type: "string",   show: false},
                                { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string",   show: false},
                                { name: "serial_No", caption: "Serial No", type: "string",  show: false},
                                { name: "major_Component_Replacement_Type", caption: "Major Component Replacement Type", type: "string",   show: false},
                                { name: "major_Component_Replacement_Hours", caption: "Major Component Replacement Hours", type: "number",   show: false},
                                { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string",   show: false},
                                { name: "rejection_Notes", caption: "Rejection Notes", type: "string",  show: false},
                                { name: "modified_After_Upload", caption: "Modified After Upload", type: "string", show: false},
                                // { name: "issue", caption: "Issue", type: "string", width: 200 },
                                // { name: "component_Code", caption: "Component Code", type: "number" },
                                // { name: "status", caption: "Status", type: "string" },
                                // { name: "winter_Repair", caption: "Winter Repair", type: "string" },
                                // { name: "priority", caption: "Priority", type: "string" },
                                // { name: "mechanic_Names", caption: "Assigned Mechanics", type: "string" },
                                // { name: "lube_Driver", caption: "Lube Drivers", type: "string" },
                                // { name: "manager_Instructions", caption: "Manager Instructions", type: "string" },
                                // { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                                // // { name: "mechanic_Comments", caption: "Mechanic Comments", type: "string" },
                                // { name: "meter_Reading", caption: "Meter Reading", type: "number" },
                                // { name: "parts_Notes", caption: "Parts Notes", type: "string", show: false },
                                // { name: "work_Order_No", caption: "Work Order No.", type: "string" },
                                // { name: "job_No", caption: "Job No.", type: "string" },
                                // { name: "cost_Code", caption: "Cost Code", type: "string" },
                                // { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string" },
                                // { name: "serial_No", caption: "Serial No.", type: "string", show: false},
                                // { name: "type", caption: "Type (New/Used)", type: "string", show: false },
                                // { name: "hours", caption: "Hours", type: "number", show: false },
                                // { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string", show: false },
                                // { name: "damaged", caption: "Damaged", type: "string" },
                                // { name: "damaged_Notes", caption: "Damaged Notes", type: "string", show: false },
                                // { name: "created_Location_Info", caption: "Created Location Info", type: "string", show: false },
                                // { name: "repair_Location_Info", caption: "Repair Location Info", type: "string", show: false },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAPopover>
});