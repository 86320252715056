import { IUPDATE_JOURNEL_REQUEST } from "./updateJournelConstants";
import { IUpdateJournelLoadAction, IUpdateJournelLoadFailedAction, IUpdateJournelSuccessAction } from "./updateJournelActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateJournelLoadAction
    | IUpdateJournelSuccessAction
    | IUpdateJournelLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateJournelReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_JOURNEL_REQUEST.REQUEST:
            return loading;

        case IUPDATE_JOURNEL_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_JOURNEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};