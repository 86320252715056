import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { IGET_TOKENS_REQUEST, IToken, ITokenRequest } from "./getTokenConstants";


export interface IGetTokenLoadAction {
    type: IGET_TOKENS_REQUEST.REQUEST;
    data: ITokenRequest
}
export const getTokenLoadAction = (data: ITokenRequest): IGetTokenLoadAction => ({
    type: IGET_TOKENS_REQUEST.REQUEST,
    data
});
export interface IGetTokenSuccessAction {
    type: IGET_TOKENS_REQUEST.SUCCESS;
    data: SurewayAPIResponse<IToken>;
}
export const getTokenLoadSuccessAction = (data: SurewayAPIResponse<IToken>): IGetTokenSuccessAction => ({
    type: IGET_TOKENS_REQUEST.SUCCESS,
    data
});
export interface IGetTokenLoadFailedAction {
    type: IGET_TOKENS_REQUEST.FAILED;
    message: string;
}
export const getTokenLoadFailedAction = (message: string): IGetTokenLoadFailedAction => ({
    type: IGET_TOKENS_REQUEST.FAILED,
    message
});
