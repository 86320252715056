import React, { Component } from "react";
import { connect } from "react-redux";
import { IStore } from "../redux/reducers";
import { STATUS_ENUM, hasPayload, Server } from "../redux/server";
import { SurewayAPIResponse } from "./shared/publicInterfaces";
import { IToken } from "../redux/getToken/getTokenConstants";
import { getToken } from "../redux/getToken/getTokenAccessor";
import queryString from "query-string";
import { RouteComponentProps } from "react-router";

interface IProtectedPDFPageServerProps {
    token: Server<SurewayAPIResponse<IToken>>;
}

interface IProtectedPDFPageOwnProps {
}

type IProtectedPDFPageProps =
    RouteComponentProps
    & IProtectedPDFPageOwnProps
    & IProtectedPDFPageServerProps;

class ProtectedPDFPage extends Component<IProtectedPDFPageProps> {
    componentDidMount() {
        const query: any = queryString.parse(this.props.location.search);
        const { token } = this.props;

        if (token.kind !== STATUS_ENUM.SUCCEEDED || !hasPayload(token)) {
            window.location.href = "/unauthorized";
            return;
        }

        if (query.filename) {
            const pdfUrl = `/secured_pdfs/${query.filename}`;
            window.location.href = pdfUrl;
        } else {
            window.location.href = "/error404";
        }
    }

    render() {
        return <div>Loading PDF...</div>
    }
}

const mapStateToProps = (state: IStore): IProtectedPDFPageServerProps => ({
    token: getToken(state)
});

export default connect(mapStateToProps)(ProtectedPDFPage);