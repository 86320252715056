import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_FIELD_LOOKUPS_REQUEST, IFieldLookup, IFieldLookupRequest } from "./getFieldLookupsConstants";

export interface IGetFieldLookupsLoadAction {
    type: IGET_FIELD_LOOKUPS_REQUEST.REQUEST;
    data: IFieldLookupRequest
}
export const getFieldLookupsLoadAction = (data: IFieldLookupRequest): IGetFieldLookupsLoadAction => ({
    type: IGET_FIELD_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetFieldLookupsSuccessAction {
    type: IGET_FIELD_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IFieldLookup>;
}
export const getFieldLookupsLoadSuccessAction = (list: SurewayAPIResponse<IFieldLookup>): IGetFieldLookupsSuccessAction => ({
    type: IGET_FIELD_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetFieldLookupsLoadFailedAction {
    type: IGET_FIELD_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getFieldLookupsLoadFailedAction = (message: string): IGetFieldLookupsLoadFailedAction => ({
    type: IGET_FIELD_LOOKUPS_REQUEST.FAILED,
    message
});
