import { IADD_FIELDEMPLOYEE_REQUEST } from "./addFieldEmployeeConstants";
import { IAddFieldEmployeeLoadAction, IAddFieldEmployeeLoadFailedAction, IAddFieldEmployeeSuccessAction } from "./addFieldEmployeeActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IAddFieldEmployeeLoadAction
    | IAddFieldEmployeeSuccessAction
    | IAddFieldEmployeeLoadFailedAction
    | IFlushDataSuccessAction;

export const AddFieldEmployeeReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_FIELDEMPLOYEE_REQUEST.REQUEST:
            return loading;

        case IADD_FIELDEMPLOYEE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_FIELDEMPLOYEE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};