import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_VENDOR_ORDER_REQUEST } from "./addVendorOrderConstants";
import { IAddVendorOrderLoadAction, IAddVendorOrderLoadFailedAction, IAddVendorOrderSuccessAction, addVendorOrderLoadFailedAction, addVendorOrderLoadSuccessAction } from "./addVendorOrderActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addVendorOrderEpic: Epic = (
    action$: ActionsObservable<IAddVendorOrderLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddVendorOrderSuccessAction | IAddVendorOrderLoadFailedAction> =>
    action$.ofType(IADD_VENDOR_ORDER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.VENDOR.ADD_VENDOR_ORDER,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddVendorOrderSuccessAction | IAddVendorOrderLoadFailedAction => {
                            if(response.message === "Success"){
                                return addVendorOrderLoadSuccessAction(response);
                            } else {
                                return addVendorOrderLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addVendorOrderLoadFailedAction(response.message)))
                    )
            )
        );