import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_EQUIPMENT_MANUALS_REQUEST {
    REQUEST = "getEquipmentManuals/GET_EQUIPMENT_MANUALS_REQUEST",
    SUCCESS = "getEquipmentManuals/GET_EQUIPMENT_MANUALS_SUCCESS",
    FAILED = "getEquipmentManuals/GET_EQUIPMENT_MANUALS_FAILED"
};

export interface IGetEquipmentManualsRequest extends ISurewayTokenRequestBody {
    request: IGetEquipmentManuals;
};

export interface IGetEquipmentManuals {
    unit_id: number;
}

export interface IEquipmentManual {
    id: number;
    units: string;
    unit_Numbers?: string;
    make?: string;
    make_ID: number;
    models: string;
    category: string;
    file_Name: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    files: IEquipmentManualsFile[],
};


export interface IEquipmentManualsFile {
    name: string;
    base64String: string;
};
