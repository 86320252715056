import { ReactNode, PureComponent, useState } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { ROUTE } from "../routes";
import queryString from "query-string";
import PageSpacing from "../shared/pageSpacing";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../redux/server";
import { IDispatch, IStore } from "../../redux/reducers";
import styled from "styled-components";
import { LACenteredLoading } from "../shared/loading";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../shared/paper";
import { SurewayAPIResponse } from "../shared/publicInterfaces";
import { IEmployeeMessage, IEmployeeMessageRequest } from "../../redux/external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenConstants";
import { IEmployeeMessageResponseRequest } from "../../redux/external/saveEmployeeMessageResponse/saveEmployeeMessageResponseConstants";
import { getEmployeeMessagesByTokenLoadAction } from "../../redux/external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenActions";
import { saveEmployeeMessageResponseLoadAction } from "../../redux/external/saveEmployeeMessageResponse/saveEmployeeMessageResponseActions";
import { getEmployeeMessagesByTokenStatus } from "../../redux/external/getEmployeeMessagesByToken/getEmployeeMessagesByTokenAccessor";
import { saveEmployeeMessageResponseStatus } from "../../redux/external/saveEmployeeMessageResponse/saveEmployeeMessageResponseAccessor";
import React from "react";
import { LAButton } from "../shared/buttons";
import LATextArea from "../shared/textArea";
import RequestStatus from "../shared/requestStatusSnackbar";
import { MEDIA_QUERY_PHONE, RED_COLOR } from "../shared/theme";
import { logo } from "../shared/icons";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { callRouteWithQueryString } from "../shared/constExports";


interface IExternalEmployeeMessagesStoreProps {
    saveResponse: Server<string>;
    getMessages: Server<SurewayAPIResponse<IEmployeeMessage>>;
};

interface IExternalEmployeeMessagesDispatchProps {
    getMessagesRequest: (request: IEmployeeMessageRequest) => unknown;
    saveMessageRequest: (request: IEmployeeMessageResponseRequest) => unknown;
};


interface IExternalEmployeeMessagesOwnProps {

};

interface IExternalEmployeeMessagesState {

};

const ExternalEmployeeMessagesStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };
    };
`;


type IExternalEmployeeMessagesProps = RouteComponentProps
    & IExternalEmployeeMessagesStoreProps
    & IExternalEmployeeMessagesDispatchProps
    & IExternalEmployeeMessagesOwnProps;

class ExternalEmployeeMessages extends PureComponent<IExternalEmployeeMessagesProps, IExternalEmployeeMessagesState> {

    public constructor(props: IExternalEmployeeMessagesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IExternalEmployeeMessagesProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getMessages, saveResponse } = this.props;
        const { kind } = getMessages;
        const data = hasPayload(getMessages) ? getMessages.payload.response : null;

        return (
            <PageSpacing title="Move Request" description="Move Request" fixedSpaceOnSmallerScreens={true}>
                <ExternalEmployeeMessagesStyles>
                    <LAGrid>

                        {(kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Please wait... Loading data" />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Failed to load data..." />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.SUCCEEDED) && (data !== null) && <LAGridItem xs={12}>
                            <EmployeeMessagePopup
                                message={data}
                                onSave={this.handleSave}
                            />
                        </LAGridItem>}

                    </LAGrid>

                </ExternalEmployeeMessagesStyles>
                {saveResponse.kind === STATUS_ENUM.SUCCEEDED && this.redirect()}
                <RequestStatus requestStatus={saveResponse.kind} successMessage="Your response has been saved successfully!" />
            </PageSpacing>
        );
    }

    private redirect = (): void => {
        const query: any = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { token: query.token},
            pathName: ROUTE.EXTERNAL.RESPONSE
        });
    }

    private handleSave = (data: IEmployeeMessageResponseRequest): void => {
        this.props.saveMessageRequest(data);
    };

    private callServer = (): void => {
        const query: any = queryString.parse(this.props.location.search);

        if (query !== undefined && query.token !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.getMessages)) {
                this.requestToGetMoves(Number(query.id), query.token);
                this.setState({ id: Number(query.id) });
            }
        } else {
            this.props.history.push(ROUTE.UNAUTHORIZED);
        };
    };

    private requestToGetMoves = (id: number, token: string): void => {
        this.props.getMessagesRequest({
            request: {
                id,
                token
            }
        });
    }

}

const mapStateToProps = (state: IStore): IExternalEmployeeMessagesStoreProps => ({
    getMessages: getEmployeeMessagesByTokenStatus(state),
    saveResponse: saveEmployeeMessageResponseStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalEmployeeMessagesDispatchProps => ({
    getMessagesRequest: (request: IEmployeeMessageRequest) => dispatch(getEmployeeMessagesByTokenLoadAction(request)),
    saveMessageRequest: (request: IEmployeeMessageResponseRequest) => dispatch(saveEmployeeMessageResponseLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalEmployeeMessages);


interface IEmployeeMessagePopupProps {
    message: IEmployeeMessage;
    onSave: (data: IEmployeeMessageResponseRequest) => void;
};

const EmployeeMessagePopup: React.FC<IEmployeeMessagePopupProps> = React.memo((props: IEmployeeMessagePopupProps) => {

    const [data, setData] = useState<IEmployeeMessageResponseRequest>({
        ID: 0,
        Comments: "",
        Employee_ID: 0,
        Selected_Option: null,
    });

    const onOption = (e: any, value: string): void => {
        if (value !== null) {
            const option = props.message.options.find(q => q.option === value);
            if (option)
                setData({
                    ...data,
                    Selected_Option: option.id,
                });
        }
    };

    const onChange = (name: string, value: string): void => {
        if (value !== null) {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    const onSave = (): void => {
        const request: IEmployeeMessageResponseRequest = {
            ID: props.message.id,
            Comments: data.Comments,
            Selected_Option: data.Selected_Option,
            Employee_ID: props.message.employee_ID
        };

        props.onSave(request);
    };

    const option = data.Selected_Option ? props.message.options.find(q => q.id === data.Selected_Option)?.option : "";

    return <LAPaperWithPadding>
        <LAGrid className="text-center">

            <LAGridItem xs={12} sm={12} md={12}>
                <img src={logo} className="logo-img" alt="logo" title="logo" />
                <hr />
                <br />
                <h3 className="red-text">{props.message.message}</h3>
            </LAGridItem>

            <LAGridItem xs={12} sm={12} md={12}>
                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={option} onChange={onOption}>
                    {props.message.options.map((x, idx) => (
                        <FormControlLabel key={idx} value={x.option} control={<Radio />} label={x.option} />
                    ))}
                </RadioGroup>
            </LAGridItem>

            <LAGridItem xs={12} sm={12} md={12}>
                <LATextArea
                    minRows={3}
                    rowsMax={10}
                    fullWidth={true}
                    name="Comments"
                    label="Comments"
                    onChange={onChange}
                    value={data.Comments}
                />
            </LAGridItem>

            <LAGridItem xs={12} sm={12} md={12}>
                <LAButton
                    label="Save"
                    onClick={onSave}
                    disabled={((data.Comments === "") && (data.Selected_Option === null)) ? true : undefined}
                />
            </LAGridItem>

        </LAGrid>
    </LAPaperWithPadding>
});