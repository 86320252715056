import { ActionsObservable, Epic } from "redux-observable";
import { catchError, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { FLUSH_DATA } from "./clearReduxConstants";
import { flushDataFailedAction, flushDataSuccessAction, IFlushDataRequestAction } from "./clearReduxActions";
import { IAction } from "../reducers";

export const flushDataEpic: Epic = (
    action$: ActionsObservable<IFlushDataRequestAction>
): Observable<IAction> =>
    action$.ofType(FLUSH_DATA.REQUEST)
        .pipe(
            mergeMap(() => {
                return ActionsObservable.of(flushDataSuccessAction());
            }),
            catchError(() => {
                return ActionsObservable.of(flushDataFailedAction());
            })
        );
