
export enum IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST {
    REQUEST = "getWorkingSiteLogsOnCall/GET_WORKING_SITE_LOGS_ON_CALL_REQUEST",
    SUCCESS = "getWorkingSiteLogsOnCall/GET_WORKING_SITE_LOGS_ON_CALL_SUCCESS",
    FAILED = "getWorkingSiteLogsOnCall/GET_WORKING_SITE_LOGS_ON_CALL_FAILED"
};

export interface IWorkingSiteLogOnCallRequest {
    token: string;
};

export interface IWorkingSiteLogOnCall {
    id: number;
    type: string;
    person: string;
    modified_By: string;
    modified: string;
    email: string;
    mobile: string;
};