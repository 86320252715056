import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGetHazardControlPlanListByDepartmentID, IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST } from "./getHazardControlPlanListByDepartmentIDConstants";
import { IGetHazardControlPlanListByDepartmentIDLoadAction, IGetHazardControlPlanListByDepartmentIDLoadFailedAction, IGetHazardControlPlanListByDepartmentIDSuccessAction } from "./getHazardControlPlanListByDepartmentIDActions";
import { IAddFormResponseSuccessAction } from "../addFormResponse/addFormResponseActions";
import { IUpdateFormResponseSuccessAction } from "../updateFormResponse/updateFormResponseActions";
import { IADD_FORM_RESPONSE_REQUEST } from "../addFormResponse/addFormResponseConstants";
import { IUPDATE_FORM_RESPONSE_REQUEST } from "../updateFormResponse/updateFormResponseConstants";

type Actions =
    | IGetHazardControlPlanListByDepartmentIDLoadAction
    | IGetHazardControlPlanListByDepartmentIDSuccessAction
    | IGetHazardControlPlanListByDepartmentIDLoadFailedAction
    | IAddFormResponseSuccessAction
    | IUpdateFormResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetHazardControlPlanListByDepartmentIDReducer = (state: Server<SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetHazardControlPlanListByDepartmentID>> => {
    switch (action.type) {
        case IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_HAZARD_CONTROL_PLAN_LIST_BY_DEPARTMENT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};