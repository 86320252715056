
import React, { useEffect, useRef, useState } from 'react';
import {MenuItem, SwipeableDrawer} from '@mui/material';
import {Box} from '@mui/material';
import Button from '@mui/material/Box';
import {Menu} from '@mui/material';
import {Fade} from '@mui/material';
import styled from 'styled-components';
import { LAIconButton } from './buttons';
import { AttachmentIcon, BaseKitsIcon, BulldozerIcon, DropDownListIcon, LevelsIcon, RepeaterIcon, RoverKitsIcon, SecondListIcon } from './icons';
import { ROUTE } from '../routes';
import { useHistory, useLocation } from 'react-router';

interface IMenu {
    name: string;
    label: string;
    url: string;
    subMenu?: boolean;
    subMenus?: IMenu[];
    role: any[];
    icon?: any;
}

interface INavbarProps { 
    menu: IMenu[];
    role?: any;
}

const NavbarStyles = styled.div`
    .navbar-header {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        height: 70px;
    }
    .navbar-mobile-header {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white !important;
    }
    .navbar {
        text-align: start;
    }
    .navbar-link {
        color: #61dafb !important;
    }
    .navbar-link:hover {
        color: white !important;
        background-color: #90949ba1 !important;
    }
    .drawer-icon {
      margin-left: 10px;
    }
    .drawer-icon:hover {
      background-color: #90949ba1 !important;
    }
`;

const HighlightedLink = {
    border: '2px solid white',
    borderRadius: '5px',
};

const mobileFlexDirection = {
    display:'flex',
    alignItems:'flex-start',
    flexDirection:'column',
    flexWrap: 'wrap',
}

const desktopFlexDirection = {
    display:'flex',
    alignItems:'flex-start',
}

export const SOCTNavbarMenu : IMenu[] = [
    {name: "soct", label: "SOCT", url: ROUTE.FIELD.SOCT.SOCT_LIST, role: [true, false]},
    {name: "bulk", label: "Bulk Meter Adjustment", url: ROUTE.FIELD.SOCT.BULK_METER_ADJUSTMENT.INDEX, role: [true, false]},
    {name: "report config", label: "Report Config", url: ROUTE.FIELD.SOCT.SOCT_REPORT, role: [true, false]},
];
export const FormsMenu : IMenu[] = [
    {name: "Form Templates", label: "Form Templates", url: ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX, role: [true]},
    {name: "Form Responses", label: "Form Responses", url: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX, role: [true, false, "NA"]},
    // {name: "Crew", label: "Crew", url: ROUTE.FIELD.FORMS.CREW.INDEX, role: [true, false]},
    {name: "Admin", label: "Admin", url: ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX, role: [true], subMenu: true, subMenus : [
        {name: "Departments", label: "Departments", url: ROUTE.FIELD.FORMS.ADMIN.DEPARTMENTS.INDEX, role: [true]},
        {name: "Tasks", label: "Tasks", url: ROUTE.FIELD.FORMS.ADMIN.TASKS.INDEX, role: [true]},
        {name: "Hazard_Types", label: "Hazard Types", url: ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.INDEX, role: [true]},
        {name: "Control_Plans", label: "Control Plans", url: ROUTE.FIELD.FORMS.ADMIN.CONTROL_PLANS.INDEX, role: [true]},
        {name: "Hazards", label: "Hazards", url: ROUTE.FIELD.FORMS.ADMIN.HAZARDS.INDEX, role: [true]},
    ]},
    {name: "Cleanup", label: "Clean Up", url: ROUTE.FIELD.FORMS.CLEAN_UP.INDEX, role: [true, "formsCleanupAccess"]},
];

export const VendorsMenu : IMenu[] = [
    {name: "By PO", label: "By PO", url: ROUTE.FIELD.VENDOR.INDEX, role: [true, false]},
    {name: "By Logs", label: "By Logs", url: ROUTE.FIELD.VENDOR.VENDOR_LOGS, role: [true, false]},
];

export const SurveyMenu : IMenu[] = [
    {name: "Machine Control", label: "Machine Control", url: ROUTE.FIELD.SURVEY.INDEX,
        icon: <BulldozerIcon />, role: [true, false], subMenu: true, 
        subMenus: [
            { name: "Machine Control", label: "Machine Control", url: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.INDEX, role: [true, false] },
            { name: "Control Boxes", label: "Control Boxes", url: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.MACHINE_CONTROL_BOXES, role: [true, false] },
            { name: "Receiver", label: "Receiver", url: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.MACHINE_CONTROL_RECEIVERS, role: [true, false] },
            { name: "Attachments", label: "Attachments", url: ROUTE.FIELD.SURVEY.ATTACHMENTS.INDEX, role: [true, false] },
        ]
    },
    // {name: "Attachments", label: "Attachments", url: ROUTE.FIELD.SURVEY.ATTACHMENTS.INDEX, icon: <AttachmentIcon />, role: [true, false]},
    {name: "Base Kits", label: "Base Kits", url: ROUTE.FIELD.SURVEY.BASE_KITS.INDEX, icon: <BaseKitsIcon width={28} height={28} color="#c48816" />, role: [true, false]},
    {name: "Rover Kits", label: "Rover Kits", url: ROUTE.FIELD.SURVEY.ROVER_KITS.INDEX, icon: <RoverKitsIcon  color="#c48816"/>, role: [true, false]},
    {name: "Repeater Kits", label: "Repeater Kits", url: ROUTE.FIELD.SURVEY.REPEATER_KITS.INDEX, icon: <RepeaterIcon width={28} height={28} color="#c48816"/>, role: [true, false]},
    {name: "Lasers", label: "Lasers", url: ROUTE.FIELD.SURVEY.LASERS.INDEX, icon: <LevelsIcon width={28} height={28} color="#c48816"/>, role: [true, false]},
    {name: "Levels", label: "Levels", url: ROUTE.FIELD.SURVEY.LEVELS.INDEX, icon: <LevelsIcon width={28} height={28} color="#c48816"/>, role: [true, false]},
];

export const LocationsMenu : IMenu[] = [
    {name: "Locations", label: "Locations", url: ROUTE.FIELD.LOCATION.INDEX, role: [true, false]},
    {name: "Medical Facilities", label: "Medical Facilities", url: ROUTE.FIELD.MEDICAL_FACILITIES.INDEX, role: [true, false]},
];

export const Navbar: React.FC<INavbarProps> = React.memo((props: INavbarProps) => {

    const location = useLocation();
    const { pathname } = location;
    const history = useHistory();
  
    const [boxStyle, setBoxStyle] = useState(desktopFlexDirection);
    const [open, setOpen] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [clickedItem, setClickedItem] = useState<string | null>(null);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [anchorEl,setAnchorEl] = useState(null);
  
    useEffect(() => {
        function handleResize() {
            setBoxStyle(window.innerWidth < 1056 ? mobileFlexDirection : desktopFlexDirection);
        }
    
        handleResize();
            window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  
    const handleRoute = (name: string, url: string) => {
        history.push(url);
    };
  
    const handleClick = (e:any, name: string) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
        setClickedItem(name);
    };
  
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        setClickedItem(null);
    };

    const handleDrawer = (): void => {
        setDrawer(!drawer);
    };
  
    const generateMenuItem = (menuItem: IMenu) => {
        if (!menuItem.subMenu && props.role && menuItem.role.includes(props.role)) {
            return (
            <MenuItem
                key={menuItem.name}
                id={menuItem.name}
                onClick={() => handleRoute(menuItem.name, menuItem.url)}
                className="navbar-link"
                style={pathname.includes(menuItem.url) ? HighlightedLink : undefined}
            >
                {menuItem.icon && menuItem.icon}
                {menuItem.label}
            </MenuItem>
            );
        }
    
        if (props.role && menuItem.role.includes(props.role) && menuItem.subMenu) {
            const name = menuItem.name;
            const isSubMenuHighlighted = menuItem.subMenus && menuItem.subMenus.some((sub: IMenu) => pathname.includes(sub.url));
            return (
                <MenuItem key={name} className="navbar-link" style={isSubMenuHighlighted ? HighlightedLink : undefined}>
                    <Button
                        ref={anchorRef}
                        id="fade-button"
                        aria-controls={open && clickedItem === name ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open && clickedItem === name ? 'true' : undefined}
                        onClick={(e) => handleClick(e,name)}
                    >
                        {menuItem.label}
                    <DropDownListIcon height={14} color={boxStyle === mobileFlexDirection ? "black" : '#61dafb'}/>
                    </Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        {props.menu.find((menuItem: IMenu) => clickedItem === menuItem.name)?.subMenus?.map((sub: IMenu) => (
                            <MenuItem
                                key={sub.name}
                                id={sub.name}
                                onClick={() => handleRoute(sub.name, sub.url)}
                                className="navbar-link"
                                style={pathname.includes(sub.name) ? HighlightedLink : undefined}
                            >
                                {sub.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </MenuItem>
            );
        }
    
        return null;
    };

   
    return (
      <NavbarStyles>
        <div className="navbar">
            {boxStyle === desktopFlexDirection ?
                <div className="navbar-header">
                    <Box sx={boxStyle}>
                        {props.menu.map((menuItem: any) => generateMenuItem(menuItem))}
                    </Box>
                </div>
            :
                <div className="navbar-mobile-header">
                    <Box sx={boxStyle}>
                    <LAIconButton
                        label="Open"
                        icon={<SecondListIcon color='white'/>}
                        onClick={handleDrawer}
                        className='drawer-icon'
                    />
                    <SwipeableDrawer
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                            },
                        }}
                        open={drawer}
                        onClose={handleDrawer}
                        onOpen={handleDrawer}
                    >
                        {props.menu.map((menuItem: any) => generateMenuItem(menuItem))}
                    </SwipeableDrawer>
                    </Box>
                </div>
            }  
        </div>
      </NavbarStyles>
    );
  });
  

