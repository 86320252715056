import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { STATUS_ENUM, Server, hasPayload } from "../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../shared/publicInterfaces";
import { IToken } from "../../redux/getToken/getTokenConstants";
import { IExternalUser, IGetExternalUsersRequest } from "../../redux/externalUser/getExternalUsers/getExternalUsersConstants";
import { IAddExternalUserRequest } from "../../redux/externalUser/addExternalUser/addExternalUserConstants";
import { IUpdateExternalUserRequest } from "../../redux/externalUser/updateExternalUser/updateExternalUserConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../shared/fieldValidator";
import { addExternalUserStatus } from "../../redux/externalUser/addExternalUser/addExternalUserAccessor";
import { addExternalUserLoadAction } from "../../redux/externalUser/addExternalUser/addExternalUserActions";
import { getExternalUsers } from "../../redux/externalUser/getExternalUsers/getExternalUsersAccessor";
import { getExternalUsersLoadAction } from "../../redux/externalUser/getExternalUsers/getExternalUsersActions";
import { updateExternalUserStatus } from "../../redux/externalUser/updateExternalUser/updateExternalUserAccessor";
import { updateExternalUserLoadAction } from "../../redux/externalUser/updateExternalUser/updateExternalUserActions";
import { getToken } from "../../redux/getToken/getTokenAccessor";
import { IStore, IDispatch } from "../../redux/reducers";
import { ReadOnly, NotApplicable } from "../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../routes";
import LAAutoComplete from "../shared/autoComplete";
import { LAButton, LASaveAndCancelButton, LASecondaryButton } from "../shared/buttons";
import { pageAccessCheck, undefinedFunction, ZEROTH, getTokenFromUrl, ActiveOrInActive, generateRandomText } from "../shared/constExports";
import LAErrorBox from "../shared/errorBox";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { ArrowLeftIcon } from "../shared/icons";
import { LAPaperWithPadding } from "../shared/paper";
import RequestStatus from "../shared/requestStatusSnackbar";
import LATextField from "../shared/textField";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../shared/theme";

const RequiredFields: string[] = ["firstname", "lastname", "email", "phone", "password"];
const Roles: IIdName[] = [{ id: 1, name: "Mechanic" }, { id: 2, name: "Lube-Driver" }, { id: 3, name: "Operator" }, { id: 4, name: "Swamper" }];

interface IAddUpdateExternalUserComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    serviceTypes: Server<SurewayAPIResponse<ById<IExternalUser>>>;
    addExternalUserStatus: Server<SurewayAPIResponse<string>>;
    updateExternalUserStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateExternalUserComponentDispatchProps {
    getExternalUsersRequest: (data: IGetExternalUsersRequest) => unknown
    addExternalUserRequest: (data: IAddExternalUserRequest) => unknown;
    updateExternalUserRequest: (data: IUpdateExternalUserRequest) => unknown;
};

interface IAddUpdateExternalUserOwnProps {
    id?: string;
};

interface IAddUpdateExternalUserComponentState {
    data: IExternalUser;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateExternalUserStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateExternalUserComponentProps =
    RouteComponentProps
    & IAddUpdateExternalUserOwnProps
    & IAddUpdateExternalUserComponentStoreProps
    & IAddUpdateExternalUserComponentDispatchProps;

class AddUpdateExternalUser extends PureComponent<IAddUpdateExternalUserComponentProps, IAddUpdateExternalUserComponentState> {

    public constructor(props: IAddUpdateExternalUserComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                password: "",
                role: Roles[0].name,
                status: ActiveOrInActive[0].name,
                company: "",
                created_On: "",
                created_By: "",
                modified_On: "",
                modified_By: "",
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateExternalUserComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addExternalUserStatus !== prevProps.addExternalUserStatus) {

                if (this.props.addExternalUserStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addExternalUserStatus.message });

                if (hasPayload(this.props.addExternalUserStatus) && this.props.addExternalUserStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateExternalUserStatus !== prevProps.updateExternalUserStatus) {

                if (this.props.updateExternalUserStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateExternalUserStatus.message });

                if (hasPayload(this.props.updateExternalUserStatus) && this.props.updateExternalUserStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { token, addExternalUserStatus, updateExternalUserStatus } = this.props;
        const getRole = pageAccessCheck(token, "externalUsers");
        const disabled = getRole === ReadOnly ? true : undefined;
        const onRole = (event: unknown, value: IIdName): void => this.handleChange("role", value !== null ? value.name : data.role);
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status);

        return (
            <AddUpdateExternalUserStyles>
                 {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>EXTERNAL USER</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="First Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="firstname"
                                value={data.firstname}
                                onChange={this.handleChange}
                                errorText={errors["firstname"] ? errors["firstname"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Last Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="lastname"
                                value={data.lastname}
                                onChange={this.handleChange}
                                errorText={errors["lastname"] ? errors["lastname"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Phone"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="phone"
                                value={data.phone}
                                onChange={this.handleChange}
                                errorText={errors["phone"] ? errors["phone"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Email"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="email"
                                value={data.email}
                                onChange={this.handleChange}
                                errorText={errors["email"] ? errors["email"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Company"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="company"
                                value={data.company}
                                onChange={this.handleChange}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={Roles}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onRole}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Role"
                                selectionRemove={undefinedFunction}
                                value={data.role ? Roles.find(q => q.name === data.role) : null}
                                defaultValue={data.role ? Roles.find(q => q.name === data.role) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={ActiveOrInActive}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                value={data.status ? ActiveOrInActive.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? ActiveOrInActive.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                            <LATextField
                                label="Password"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="password"
                                value={data.password ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["password"] ? errors["password"].message : undefined}
                            />

                            <LASecondaryButton
                                className="mt-2"
                                label="Generate New Password"
                                onClick={this.handleAutoGenerate}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addExternalUserStatus.kind} successMessage="Service Due has been added successfully" />
                <RequestStatus requestStatus={updateExternalUserStatus.kind} successMessage="Service Due has been updated successfully" />
            </AddUpdateExternalUserStyles>
        );
    }

    private handleAutoGenerate = (): void => {
        let errors = { ...this.state.errors };
        let password: string = generateRandomText(6);
        let rules: IFieldValidatorProps = { required: true };
        errors = this.errorChecker("password", password, errors, rules);  

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                password: password
            },
            errors
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        
        this.props.history.push({
            pathname: ROUTE.EXTERNAL_USER.EXTERNAL_USERS,
            search: getTokenFromUrl(false)
          });
    };

    private handleSave = async (): Promise<void> => {
        const { id, firstname, lastname, company, phone, email, role, status, password } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addExternalUserRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Role: role,
                        Phone: phone,
                        Email: email,
                        Status: status,
                        Company: company,
                        Password: password,
                        Lastname: lastname,
                        Firstname: firstname,
                        Created_By: this.props.token.payload.response.upn
                    }
                });
            } else {
                this.props.updateExternalUserRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Role: role,
                        Phone: phone,
                        Email: email,
                        Status: status,
                        Company: company,
                        Password: password,
                        Lastname: lastname,
                        Firstname: firstname,
                        Created_By: this.props.token.payload.response.upn
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)){
            rules.required = true;

            if(name === "phone")
                rules.phone = true;

            if(name === "email")
                rules.email = true;

            errors = this.errorChecker(name, value, errors, rules);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "externalUsers") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (hasPayload(this.props.serviceTypes)) {
                            const data = this.props.serviceTypes.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getExternalUsersRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const data: any = this.state.data;
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            if(data[x].length === 0)
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.INDEX,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateExternalUserComponentStoreProps => ({
    serviceTypes: getExternalUsers(state),
    token: getToken(state),
    addExternalUserStatus: addExternalUserStatus(state),
    updateExternalUserStatus: updateExternalUserStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateExternalUserComponentDispatchProps => ({
    addExternalUserRequest: (data: IAddExternalUserRequest) => dispatch(addExternalUserLoadAction(data)),
    getExternalUsersRequest: (data: IGetExternalUsersRequest) => dispatch(getExternalUsersLoadAction(data)),
    updateExternalUserRequest: (data: IUpdateExternalUserRequest) => dispatch(updateExternalUserLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateExternalUser);