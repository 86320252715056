import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_UNIT_HISTORY_BY_ID_REQUEST, IGetUnitHistoryById, IGetUnitHistoryByIdRequest } from "./getUnitHistoryByIdConstants";

export interface IGetUnitHistoryByIdLoadAction {
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.REQUEST;
    data: IGetUnitHistoryByIdRequest
}
export const getUnitHistoryByIdLoadAction = (data: IGetUnitHistoryByIdRequest): IGetUnitHistoryByIdLoadAction => ({
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetUnitHistoryByIdSuccessAction {
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetUnitHistoryById>>;
}
export const getUnitHistoryByIdLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetUnitHistoryById>>): IGetUnitHistoryByIdSuccessAction => ({
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetUnitHistoryByIdLoadFailedAction {
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getUnitHistoryByIdLoadFailedAction = (message: string): IGetUnitHistoryByIdLoadFailedAction => ({
    type: IGET_UNIT_HISTORY_BY_ID_REQUEST.FAILED,
    message
});
