import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_TYPES_REQUEST, IAttachmentType } from "./getTypesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetTypesLoadAction, IGetTypesLoadFailedAction, IGetTypesSuccessAction, getTypesLoadFailedAction, getTypesLoadSuccessAction } from "./getTypesActions";
import { withJsonContentType } from "../../../epicUtils";

export const getTypesEpic: Epic = (
    action$: ActionsObservable<IGetTypesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetTypesSuccessAction | IGetTypesLoadFailedAction> =>
    action$.ofType(IGET_TYPES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IAttachmentType>>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_TYPES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IAttachmentType>>): IGetTypesSuccessAction => {
                            return getTypesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getTypesLoadFailedAction(response.message)))
                    )
            )
        );