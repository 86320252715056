import { ById } from '../../../../react/shared/publicInterfaces';
import { IGET_JOBS_REQUEST, IJob } from "./getJobsConstants";
import { IGetJobsLoadAction, IGetJobsLoadFailedAction, IGetJobsSuccessAction } from "./getJobsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IADD_JOB_REQUEST } from '../addJob/addJobConstants';
import { IUPDATE_JOB_REQUEST } from '../updateJob/updateJobConstants';
import { IAddJobSuccessAction } from '../addJob/addJobActions';
import { IUpdateJobSuccessAction } from '../updateJob/updateJobActions';

type Actions =
    | IGetJobsLoadAction
    | IGetJobsSuccessAction
    | IGetJobsLoadFailedAction
    | IUpdateJobSuccessAction
    | IAddJobSuccessAction
    | IFlushDataSuccessAction;

export const GetJobsReducer = (state: Server<SurewayAPIResponse<ById<IJob>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IJob>>> => {
    switch (action.type) {
        case IGET_JOBS_REQUEST.REQUEST:
            return loading;

        case IGET_JOBS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_JOBS_REQUEST.FAILED:
            return failed(action.message);

        case IADD_JOB_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_JOB_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};