import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST, ISTTimesheetForApprovalGroupByDate } from "./getSTTimesheetForApprovalGroupByDateConstants";
import {  SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSTTimesheetForApprovalGroupByDateLoadAction, IGetSTTimesheetForApprovalGroupByDateLoadFailedAction, IGetSTTimesheetForApprovalGroupByDateSuccessAction } from "./getSTTimesheetForApprovalGroupByDateActions";
import { IApproveSTTimesheetSuccessAction } from "../approveSTTimesheet/approveSTTimesheetActions";
import { IAPPROVE_ST_TIMESHEET_REQUEST } from "../approveSTTimesheet/approveSTTimesheetConstants";
import { IRejectSTTimesheetSuccessAction } from "../rejectSTTimesheet/rejectSTTimesheetActions";
import { IREJECT_ST_TIMESHEET_REQUEST } from "../rejectSTTimesheet/rejectSTTimesheetConstants";

type Actions =
    | IGetSTTimesheetForApprovalGroupByDateLoadAction
    | IGetSTTimesheetForApprovalGroupByDateSuccessAction
    | IGetSTTimesheetForApprovalGroupByDateLoadFailedAction
    | IApproveSTTimesheetSuccessAction
    | IRejectSTTimesheetSuccessAction
    // | ISaveManagerCommentsForDefectItemSuccessAction
    | IFlushDataSuccessAction;

export const GetSTTimesheetForApprovalGroupByDateReducer = (state: Server<SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ISTTimesheetForApprovalGroupByDate[]>> => {
    switch (action.type) {
        case IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST:
            return loading;

        case IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ST_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IAPPROVE_ST_TIMESHEET_REQUEST.SUCCESS:
            return notLoaded;

        case IREJECT_ST_TIMESHEET_REQUEST.SUCCESS:
            return notLoaded;

        // case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS:
        //     return notLoaded;

        default:
            return state;
    }
};