import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "./saveMechanicAssignmentConstants";
import { ISaveMechanicAssignmentLoadAction, ISaveMechanicAssignmentLoadFailedAction, ISaveMechanicAssignmentSuccessAction } from "./saveMechanicAssignmentActions";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";

type Actions =
    | ISaveMechanicAssignmentLoadAction
    | ISaveMechanicAssignmentSuccessAction
    | ISaveMechanicAssignmentLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveMechanicAssignmentReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.REQUEST:
            return loading;

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};