
export enum IADD_CREW_REQUEST {
    REQUEST = "addCrew/ADD_CREW_REQUEST",
    SUCCESS = "addCrew/ADD_CREW_SUCCESS",
    FAILED = "addCrew/ADD_CREW_FAILED"
};

export interface IAddCrewRequest {
    token: string;
    request: IAddUpdateCrew;
};

export interface IAddUpdateCrew {
    ID: number;
    Foreman: string;
    Crew_Name: string;
    Members: number[];
    Active: string;
    Created_By: string;
    Modified_By: string;
}
