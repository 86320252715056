import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import PageSpacing from "../../shared/pageSpacing";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { getMappingViews } from "../../../redux/field/equipmentTracker/getMappingView/getMappingViewAccessor";
import { getMappingViewLoadAction } from "../../../redux/field/equipmentTracker/getMappingView/getMappingViewActions";
import { IMappingView, IMappingViewRequest } from "../../../redux/field/equipmentTracker/getMappingView/getMappingViewConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, userName } from "../../shared/constExports";
import SOCTHome from "./equipmenttrackerhome";
import { ListViewInitiatePopup } from "./listViewPopup";
import { IETLookups, IETLookupsRequest } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsConstants";
import { IInitiateMove, IInitiateMoveRequest } from "../../../redux/field/equipmentTracker/initiateMove/initiateMoveConstants";
import { initiateMoveLoadAction } from "../../../redux/field/equipmentTracker/initiateMove/initiateMoveActions";
import { getETLookupsLoadAction } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsActions";
import { InitiateMove } from "../../../redux/field/equipmentTracker/initiateMove/initiateMoveAccessor";
import { getETLookups } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsAccessor";
import LAErrorBox from "../../shared/errorBox";


interface IListViewStoreProps {
    mappingView: Server<IMappingView[]>;
    getToken: Server<SurewayAPIResponse<IToken>>;
    lookup: Server<SurewayAPIResponse<IETLookups>>;
    initiateMoveStatus: Server<SurewayAPIResponse<string>>;
};

interface IListViewDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    mappingViewRequest: (data: IMappingViewRequest) => unknown;
    lookupRequest: (data: IETLookupsRequest) => unknown;
    initiateMoveRequest: (data: IInitiateMoveRequest) => unknown;
};


interface IListViewOwnProps {

};

interface IListViewState {
    data: IMappingView[];
    initiateMove: boolean;
    moveId: number | undefined;
};

const ListViewStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right-username {
        top: 0.5%;
        right: 2.5%;
        position: absolute;
        color: red;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IListViewProps = RouteComponentProps
    & IListViewStoreProps
    & IListViewDispatchProps
    & IListViewOwnProps;

class ListView extends PureComponent<IListViewProps, IListViewState> {

    public constructor(props: IListViewProps) {
        super(props);
        this.state = {
            data: [],
            moveId: undefined,
            initiateMove: false,
        };
    }

    public componentDidMount(): void {
        this.callServer();

    };

    public componentDidUpdate(prevProps: IListViewProps): void {
        if (this.props !== prevProps)
            this.callServer();

    };


    public render(): ReactNode {

        const { data, initiateMove, moveId } = this.state;
        const { mappingView, lookup } = this.props;
        const lookupData = hasPayload(lookup) ? lookup.payload.response : { locationInfo: [], subSiteList: [], trailer_Config: [] };
        const moveData = moveId ? data.find(x => x.id === moveId) : undefined;

        return (
            <PageSpacing title="EQUIPMENT TRACKER" description="EQUIPMENT TRACKER - LIST VIEW" fixedSpaceOnSmallerScreens={true}>
                <ListViewStyles>
                        <LAGrid>

                            <LAGridItem xs={12}>
                                <SOCTHome history={this.props.history} />

                                <h2 className="text-center title">LIST VIEW</h2>
                                <hr />

                                {mappingView.kind === STATUS_ENUM.LOADING && <LALoading message="Loading data..." />}
                                {mappingView.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load data..." />}

                                {mappingView.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="listViewET"
                                                storageKey="listViewET"
                                                customRowColor={true}
                                                exportFileName="ListView"
                                                onCustomIconClick={this.handleCustomIcon}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "unit_Number", caption: "Unit #", type: "string" },
                                                    { name: "make", caption: "Make", type: "string" },
                                                    { name: "model", caption: "Model", type: "string" },
                                                    { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                                                    { name: "details", caption: "Details", type: "string" },
                                                    { name: "site_Name", caption: "Location", type: "string" },
                                                    { name: "sub_Site_Name", caption: "Sub Site", type: "string" },
                                                    { name: "move_Status", caption: "Move Status", type: "string" },
                                                    { name: "job_No", caption: "Job #", type: "string" },
                                                    { name: "working_Site_ID", caption: "Working Site", type: "number" },
                                                    { name: "working_Site_Sub_ID", caption: "Working Sub Site", type: "number" },
                                                    { name: "list-view-history", caption: "History", type: "button" },
                                                    { name: "list-view-action", caption: "Actions", type: "button" },
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}

                                    {(initiateMove && moveData) && <ListViewInitiatePopup 
                                        data={moveData}
                                        lookup={lookupData}
                                        onSave={this.onInitiateMoveSave}
                                        onCancel={this.onInitiateMoveCancel}
                                    />}

                            </LAGridItem>

                        </LAGrid>
                </ListViewStyles>
            </PageSpacing>
        );
    }

    private onInitiateMoveSave = (data: IInitiateMove): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.initiateMoveRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    ...data,
                    Created_By: this.props.getToken.payload.response.upn
                }
            });

            this.onInitiateMoveCancel();
        }
    };

    private onInitiateMoveCancel = (): void => {
        this.setState({
            moveId: undefined,
            initiateMove: false
        });
    };

    private handleCustomIcon = (name: string, data: any): void => {
      if(name === "initiate"){
        this.setState({
            moveId: data.id,
            initiateMove: true
        });
      } else if(name === "soct_history"){
            window.open("/field/soct/lists/edit/reportstable/id="+data.equipment_ID, "_blank");
        } else {
            window.open(
                `https://reportserver.propsense.com/reports/report/Reports/Field/ET_Move_History?Unit=${data.unit_Number}`,
            "_blank");
        }
    };

    private onClick = (): void => {

    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
        this.props.getTokenRequest({
            request: {
                username: getTokenFromUrl(true) ? undefined : userName,
                user_token: getTokenFromUrl(true)
            }
        });

        if (hasPayload(this.props.getToken)) {

            if (isNotLoaded(this.props.mappingView)) {
                this.props.mappingViewRequest({
                    token: this.props.getToken.payload.response.token
                });
            };

            if (hasPayload(this.props.mappingView)) {
                this.setState({
                    data: this.props.mappingView.payload
                });
            };

            if (isNotLoaded(this.props.lookup)) {
                this.props.lookupRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        page: "List_View"
                    }
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.mappingViewRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IListViewStoreProps => ({
    getToken: getToken(state),
    mappingView: getMappingViews(state),
    lookup: getETLookups(state),
    initiateMoveStatus: InitiateMove(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IListViewDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    mappingViewRequest: (data: IMappingViewRequest) => dispatch(getMappingViewLoadAction(data)),
    lookupRequest: (data: IETLookupsRequest) => dispatch(getETLookupsLoadAction(data)),
    initiateMoveRequest: (data: IInitiateMoveRequest) => dispatch(initiateMoveLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);