import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_JOURNAL_VERSION_HISTORY_REQUEST, IGetJournalVersionHistory, IGetJournalVersionHistoryRequest } from "./getJournalVersionHistoryConstants";

export interface IGetJournalVersionHistoryLoadAction {
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetJournalVersionHistoryRequest
}
export const getJournalVersionHistoryLoadAction = (data: IGetJournalVersionHistoryRequest): IGetJournalVersionHistoryLoadAction => ({
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetJournalVersionHistorySuccessAction {
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetJournalVersionHistory>>;
}
export const getJournalVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetJournalVersionHistory>>): IGetJournalVersionHistorySuccessAction => ({
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetJournalVersionHistoryLoadFailedAction {
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getJournalVersionHistoryLoadFailedAction = (message: string): IGetJournalVersionHistoryLoadFailedAction => ({
    type: IGET_JOURNAL_VERSION_HISTORY_REQUEST.FAILED,
    message
});
