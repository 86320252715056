import { IAddUpdateMeterReading } from "../addMeterReading/addMeterReadingConstants";

export enum IUPDATE_METER_READING_REQUEST {
    REQUEST = "updateMeterReading/UPDATE_METER_READING_REQUEST",
    SUCCESS = "updateMeterReading/UPDATE_METER_READING_SUCCESS",
    FAILED = "updateMeterReading/UPDATE_METER_READING_FAILED"
};

export interface IUpdateMeterReadingRequest {
    token: string;
    request: IAddUpdateMeterReading;
};
