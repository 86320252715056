import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MOVE_REQUESTS_REQUEST, IMoveRequests, IMoveRequestsRequest } from "./getMoveRequestsConstants";

export interface IGetMoveRequestsLoadAction {
    type: IGET_MOVE_REQUESTS_REQUEST.REQUEST;
    data: IMoveRequestsRequest
}
export const getMoveRequestsLoadAction = (data: IMoveRequestsRequest): IGetMoveRequestsLoadAction => ({
    type: IGET_MOVE_REQUESTS_REQUEST.REQUEST,
    data
});
export interface IGetMoveRequestsSuccessAction {
    type: IGET_MOVE_REQUESTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IMoveRequests>;
}
export const getMoveRequestsLoadSuccessAction = (list: SurewayAPIResponse<IMoveRequests>): IGetMoveRequestsSuccessAction => ({
    type: IGET_MOVE_REQUESTS_REQUEST.SUCCESS,
    list
});
export interface IGetMoveRequestsLoadFailedAction {
    type: IGET_MOVE_REQUESTS_REQUEST.FAILED;
    message: string;
}
export const getMoveRequestsLoadFailedAction = (message: string): IGetMoveRequestsLoadFailedAction => ({
    type: IGET_MOVE_REQUESTS_REQUEST.FAILED,
    message
});
