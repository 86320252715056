import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_FOREMAN_MESSAGING_REQUEST, IUpdateForemanMessagingRequest } from "./updateForemanMessagingConstants";


export interface IUpdateForemanMessagingLoadAction {
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.REQUEST;
    data: IUpdateForemanMessagingRequest
}
export const updateForemanMessagingLoadAction = (data: IUpdateForemanMessagingRequest): IUpdateForemanMessagingLoadAction => ({
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.REQUEST,
    data
});
export interface IUpdateForemanMessagingSuccessAction {
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateForemanMessagingLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateForemanMessagingSuccessAction => ({
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.SUCCESS,
    message
});
export interface IUpdateForemanMessagingLoadFailedAction {
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.FAILED;
    message: string;
}
export const updateForemanMessagingLoadFailedAction = (message: string): IUpdateForemanMessagingLoadFailedAction => ({
    type: IUPDATE_FOREMAN_MESSAGING_REQUEST.FAILED,
    message
});
