import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentType } from "./getEquipmentTypesConstants";
import { IGetEquipmentTypesLoadAction, IGetEquipmentTypesLoadFailedAction, IGetEquipmentTypesSuccessAction } from "./getEquipmentTypesActions";
import { IUpdateEquipmentTypeSuccessAction } from "../updateEquipmentType/updateEquipmentTypeActions";
import { IAddEquipmentTypeSuccessAction } from "../addEquipmentType/addEquipmentTypeActions";
import { IADD_EQUIPMENT_TYPE_REQUEST } from "../addEquipmentType/addEquipmentTypeConstants";
import { IUPDATE_EQUIPMENT_TYPE_REQUEST } from "../updateEquipmentType/updateEquipmentTypeConstants";

type Actions =
    | IGetEquipmentTypesLoadAction
    | IGetEquipmentTypesSuccessAction
    | IAddEquipmentTypeSuccessAction
    | IUpdateEquipmentTypeSuccessAction
    | IGetEquipmentTypesLoadFailedAction
    | IFlushDataSuccessAction;

export const GetEquipmentTypesReducer = (state: Server<SurewayAPIResponse<ById<IGetEquipmentType>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetEquipmentType>>> => {
    switch (action.type) {
        case IGET_EQUIPMENT_TYPES_REQUEST.REQUEST:
            return loading;

        case IGET_EQUIPMENT_TYPES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EQUIPMENT_TYPES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};