import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUpdateLocationRequest, IUPDATE_LOCATION_REQUEST } from "./updateLocationConstants";


export interface IUpdateLocationLoadAction {
    type: IUPDATE_LOCATION_REQUEST.REQUEST;
    data: IUpdateLocationRequest
}
export const updateLocationLoadAction = (data: IUpdateLocationRequest): IUpdateLocationLoadAction => ({
    type: IUPDATE_LOCATION_REQUEST.REQUEST,
    data
});
export interface IUpdateLocationSuccessAction {
    type: IUPDATE_LOCATION_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateLocationLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateLocationSuccessAction => ({
    type: IUPDATE_LOCATION_REQUEST.SUCCESS,
    message
});
export interface IUpdateLocationLoadFailedAction {
    type: IUPDATE_LOCATION_REQUEST.FAILED;
    message: string;
}
export const updateLocationLoadFailedAction = (message: string): IUpdateLocationLoadFailedAction => ({
    type: IUPDATE_LOCATION_REQUEST.FAILED,
    message
});
