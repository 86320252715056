import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_LINE_SUMMARY_REQUEST, IRepairLineSummary } from "./getRepairLineSummaryConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetRepairLineSummaryLoadAction, IGetRepairLineSummaryLoadFailedAction, IGetRepairLineSummarySuccessAction, getRepairLineSummaryLoadFailedAction, getRepairLineSummaryLoadSuccessAction } from "./getRepairLineSummaryActions";

export const getRepairLineSummaryEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineSummaryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineSummarySuccessAction | IGetRepairLineSummaryLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_LINE_SUMMARY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IRepairLineSummary>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_SUMMARY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IRepairLineSummary>): IGetRepairLineSummarySuccessAction => {
                            return getRepairLineSummaryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineSummaryLoadFailedAction(response.message)))
                    )
            )
        );