
export enum ISAVE_MECHANIC_ASSIGNMENT_REQUEST {
    REQUEST = "saveMechanicAssignment/SAVE_MECHANIC_ASSIGNMENT_REQUEST",
    SUCCESS = "saveMechanicAssignment/SAVE_MECHANIC_ASSIGNMENT_SUCCESS",
    FAILED = "saveMechanicAssignment/SAVE_MECHANIC_ASSIGNMENT_FAILED"
};

export interface ISaveMechanicAssignmentRequest {
    upn: string;
    token: string;
    request: ISaveMechanicAssignment[];
};

export interface ISaveMechanicAssignment {
    Mechanic_IDS: string;
    Repair_Line_ID: number;
    Parts_Instructions: string;
    Technician_Instructions: string;
};