import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IVALIDATE_MECHANIC_LOGIN_REQUEST, IValidateMechanicLogin } from "./validateMechanicLoginConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IValidateMechanicLoginLoadAction, IValidateMechanicLoginLoadFailedAction, IValidateMechanicLoginSuccessAction, validateMechanicLoginLoadFailedAction, validateMechanicLoginLoadSuccessAction } from "./validateMechanicLoginActions";

export const validateMechanicLoginEpic: Epic = (
    action$: ActionsObservable<IValidateMechanicLoginLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IValidateMechanicLoginSuccessAction | IValidateMechanicLoginLoadFailedAction> =>
    action$.ofType(IVALIDATE_MECHANIC_LOGIN_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IValidateMechanicLogin>>(
                    END_POINTS.FIELD.MECHANICS.VALIDATE_MECHANIC_LOGIN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IValidateMechanicLogin>): IValidateMechanicLoginSuccessAction | IValidateMechanicLoginLoadFailedAction => {
                            if(response.response === null){
                                return validateMechanicLoginLoadFailedAction(response.message);
                            };
                            return validateMechanicLoginLoadSuccessAction(response.response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(validateMechanicLoginLoadFailedAction(response.message)))
                    )
            )
        );