import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ATTACHMENT_LIST_GET_REQUEST {
    REQUEST = "getAttachmentListGET/GET_ATTACHMENT_LIST_GET_REQUEST",
    SUCCESS = "getAttachmentListGET/GET_ATTACHMENT_LIST_GET_SUCCESS",
    FAILED = "getAttachmentListGET/GET_ATTACHMENT_LIST_GET_FAILED"
};

export interface IGetAttachmentListGETRequest extends ISurewayTokenRequestBody {

};

export interface IAttachmentListGET {
    id: number;
    attachment_ID: number;
    coupler_Design_ID: number;
    type_ID: number;
    style_ID: number;
    attachment_No: string;
    coupler_Design: string;
    type: string;
    style: string;
    part_No: string;
    description: string;
    part_Type: string;
    quantity: number;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    shop_stock: string; 
    shop_Bin: string;
    silBru_stock: string;
    silBru_Bin: string;
    unit_Number?:string;
};