import { IAddUpdateTask } from "../addTask/addTaskConstants";

export enum IUPDATE_TASK_REQUEST {
    REQUEST = "updateTask/UPDATE_TASK_REQUEST",
    SUCCESS = "updateTask/UPDATE_TASK_SUCCESS",
    FAILED = "updateTask/UPDATE_TASK_FAILED"
};

export interface IUpdateTaskRequest {
    token: string;
    request: IAddUpdateTask;
};