import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_EQUIPMENT_MANUAL_REQUEST, IAddEquipmentManualRequest } from "./addEquipmentManualConstants";


export interface IAddEquipmentManualLoadAction {
    type: IADD_EQUIPMENT_MANUAL_REQUEST.REQUEST;
    data: IAddEquipmentManualRequest
}
export const addEquipmentManualLoadAction = (data: IAddEquipmentManualRequest): IAddEquipmentManualLoadAction => ({
    type: IADD_EQUIPMENT_MANUAL_REQUEST.REQUEST,
    data
});
export interface IAddEquipmentManualSuccessAction {
    type: IADD_EQUIPMENT_MANUAL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addEquipmentManualLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddEquipmentManualSuccessAction => ({
    type: IADD_EQUIPMENT_MANUAL_REQUEST.SUCCESS,
    message
});
export interface IAddEquipmentManualLoadFailedAction {
    type: IADD_EQUIPMENT_MANUAL_REQUEST.FAILED;
    message: string;
}
export const addEquipmentManualLoadFailedAction = (message: string): IAddEquipmentManualLoadFailedAction => ({
    type: IADD_EQUIPMENT_MANUAL_REQUEST.FAILED,
    message
});
