import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
// import { IGET_SOCT_LIST_REQUEST, ISoctList } from "./getSOCTCustomServiceItemsBySOCTIDConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSOCTCustomServiceItemsBySOCTIDLoadAction, IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction, IGetSOCTCustomServiceItemsBySOCTIDSuccessAction, 
    getSOCTCustomServiceItemsBySOCTIDLoadFailedAction, getSOCTCustomServiceItemsBySOCTIDLoadSuccessAction } from "./getSOCTCustomServiceItemsBySOCTIDActions";
import { IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST, ISOCTCustomServiceItemsBySOCTID } from "./getSOCTCustomServiceItemsBySOCTIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getSOCTCustomServiceItemsBySOCTIDEpic: Epic = (
    action$: ActionsObservable<IGetSOCTCustomServiceItemsBySOCTIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSOCTCustomServiceItemsBySOCTIDSuccessAction | IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction> =>
    action$.ofType(IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>>(
                    END_POINTS.FIELD.SCOT.GET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>): IGetSOCTCustomServiceItemsBySOCTIDSuccessAction => {
                            return getSOCTCustomServiceItemsBySOCTIDLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getSOCTCustomServiceItemsBySOCTIDLoadFailedAction(response)))
                    )
            )
        );