import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SOCT_BY_ID_REQUEST , ISOCT} from "./getSOCTByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSOCTByIdLoadAction, IGetSOCTByIdLoadFailedAction, IGetSOCTByIdSuccessAction, getSOCTByIdLoadFailedAction, getSOCTByIdLoadSuccessAction } from "./getSOCTByIdActions";

export const getSOCTByIdEpic: Epic = (
    action$: ActionsObservable<IGetSOCTByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSOCTByIdSuccessAction | IGetSOCTByIdLoadFailedAction> =>
    action$.ofType(IGET_SOCT_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISOCT>>(
                    END_POINTS.FIELD.SCOT.GET_SOCT_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISOCT>): IGetSOCTByIdSuccessAction => {
                            return getSOCTByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getSOCTByIdLoadFailedAction(response.message)))
                    )
            )
        );