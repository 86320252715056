import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString,  getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../../shared/checkBox";
import { LACenteredLoading } from "../../../shared/loading";
import { IGetDepartments, IGetDepartmentsRequest } from "../../../../redux/field/getDepartments/getDepartmentsConstants";
import { getDepartmentsLoadAction } from "../../../../redux/field/getDepartments/getDepartmentsActions";
import { getDepartments } from "../../../../redux/field/getDepartments/getDepartmentsAccessor";
// import { IAddDepartmentRequest, IAddUpdateDepartment } from "../../../../redux/field/addDepartment/addDepartmentConstants";
// import { addDepartmentLoadAction } from "../../../../redux/field/addDepartment/addDepartmentActions";
// import { addDepartmentStatus } from "../../../../redux/field/addDepartment/addDepartmentAccessor";
// import { IUpdateDepartmentRequest } from "../../../../redux/field/updateDepartment/updateDepartmentConstants";
// import { updateDepartmentStatus } from "../../../../redux/field/updateDepartment/updateDepartmentAccessor";
// import { updateDepartmentLoadAction } from "../../../../redux/field/updateDepartment/updateDepartmentActions";
import { IAddDepartmentRequest, IAddUpdateDepartment } from "../../../../redux/field/addDepartment/addDepartmentConstants";
import { addDepartmentLoadAction } from "../../../../redux/field/addDepartment/addDepartmentActions";
import { addDepartmentStatus } from "../../../../redux/field/addDepartment/addDepartmentAccessor";
import { IUpdateDepartmentRequest } from "../../../../redux/field/updateDepartment/updateDepartmentConstants";
import { updateDepartmentStatus } from "../../../../redux/field/updateDepartment/updateDepartmentAccessor";
import { updateDepartmentLoadAction } from "../../../../redux/field/updateDepartment/updateDepartmentActions";

const RequiredFields: string[] = ["name"];

interface IAddUpdateDepartmentComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    // getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    getDepartments: Server<SurewayAPIResponse<ById<IGetDepartments>>>;
    addDepartmentStatus: Server<SurewayAPIResponse<string>>;
    updateDepartmentStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateDepartmentComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getDepartmentsRequest: (data: IGetDepartmentsRequest) => unknown
    addDepartmentRequest: (data: IAddDepartmentRequest) => unknown;
    updateDepartmentRequest: (data: IUpdateDepartmentRequest) => unknown;
};

interface IAddUpdateDepartmentOwnProps {
    id?: string;
};

interface IAddUpdateDepartmentComponentState {
    data: IGetDepartments;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateDepartmentStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateDepartmentComponentProps =
    RouteComponentProps
    & IAddUpdateDepartmentOwnProps
    & IAddUpdateDepartmentComponentStoreProps
    & IAddUpdateDepartmentComponentDispatchProps;

class AddUpdateDepartment extends PureComponent<IAddUpdateDepartmentComponentProps, IAddUpdateDepartmentComponentState> {

    public constructor(props: IAddUpdateDepartmentComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                active: "Yes",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateDepartmentComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addDepartmentStatus !== prevProps.addDepartmentStatus) {

                if (this.props.addDepartmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addDepartmentStatus.message });

                if (hasPayload(this.props.addDepartmentStatus) && this.props.addDepartmentStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateDepartmentStatus !== prevProps.updateDepartmentStatus) {

                if (this.props.updateDepartmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateDepartmentStatus.message });

                if (hasPayload(this.props.updateDepartmentStatus) && this.props.updateDepartmentStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = data.id > 0 ? "Update Department" : "Add Department";
        const { token, addDepartmentStatus, updateDepartmentStatus } = this.props;
        const getRole = pageAccessCheck(token, "formsAdmin");
        const disabled = (getRole === false) ? true : undefined;
        // const disabled =  undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");
        // console.log(data)

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateDepartmentStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Department</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            name="name"
                                            label="Department Name"
                                            fullWidth={true}
                                            disabled={disabled || data.id > 0}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }

                    <RequestStatus requestStatus={addDepartmentStatus.kind} successMessage="Department has been added successfully" />
                    <RequestStatus requestStatus={updateDepartmentStatus.kind} successMessage="Department has been updated successfully" />

                </AddUpdateDepartmentStyles>
            </PageSpacing>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.ADMIN.DEPARTMENTS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            let request: IAddUpdateDepartment = {
                ID: data.id,
                Name: data.name.trim(),
                Active: data.active,
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };
            // console.log(request)
            if (data.id === 0) {
                this.props.addDepartmentRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateDepartmentRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {
                    if (hasPayload(this.props.getDepartments)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getDepartments.payload.response[+query.id];

                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getDepartmentsRequest({
                            token: this.props.token.payload.response.token
                        });
                    }
                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateDepartmentComponentStoreProps => ({
    token: getToken(state),
    // getLookup: getFormLookup(state),
    getDepartments: getDepartments(state),
    addDepartmentStatus: addDepartmentStatus(state),
    updateDepartmentStatus: updateDepartmentStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateDepartmentComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getDepartmentsRequest: (data: IGetDepartmentsRequest) => dispatch(getDepartmentsLoadAction(data)),
    addDepartmentRequest: (data: IAddDepartmentRequest) => dispatch(addDepartmentLoadAction(data)),
    updateDepartmentRequest: (data: IUpdateDepartmentRequest) => dispatch(updateDepartmentLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateDepartment);