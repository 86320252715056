import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../server";
import { IGET_AUTHORIZATION_REQUEST, IAuthorization } from "./getAuthorizationConstants";
import { IGetAuthorizationLoadAction, IGetAuthorizationLoadFailedAction, IGetAuthorizationSuccessAction } from "./getAuthorizationActions";
import { IFlushDataSuccessAction } from "../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../clearRedux/clearReduxConstants";

type Actions =
    | IGetAuthorizationLoadAction
    | IGetAuthorizationSuccessAction
    | IGetAuthorizationLoadFailedAction
    | IFlushDataSuccessAction;

export const GetAuthorizationReducer = (state: Server<SurewayAPIResponse<IAuthorization[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IAuthorization[]>> => {
    switch (action.type) {
        case IGET_AUTHORIZATION_REQUEST.REQUEST:
            return loading;

        case IGET_AUTHORIZATION_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_AUTHORIZATION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};