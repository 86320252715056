
export enum IGET_REPEATER_KIT_REQUEST {
    REQUEST = "getRepeaterKit/GET_REPEATER_KIT_REQUEST",
    SUCCESS = "getRepeaterKit/GET_REPEATER_KIT_SUCCESS",
    FAILED = "getRepeaterKit/GET_REPEATER_KIT_FAILED"
};

export interface IRepeaterKitRequest {
    token: string;
};

export interface IRepeaterKit {
    id: number;
    kit_No: string;
    kit_No_Display?: string;
    unit_No: string;
    model: string;
    radio_Antenna_Parts: string;
    cables: string;
    // in_Out: string;
    status: string;
    // site: string;
    wsS_ID: number | null;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};