import React, { useState } from "react";
import { ROUTE } from "../routes";
import styled from "styled-components";
import { Menu } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { LAMenuItem } from "../shared/dropDownMenu";
import { getTokenFromUrl } from "../shared/constExports";
import { DARK_GREY_COLOR } from "../shared/theme";

const WorkingSiteLogSubHeaderStyles = styled.div`
    .subHeader {
        background-color: #282c34;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: calc(10px + 2vmin);
        color: #61dafb;
        overflow-x: hidden;
    };

    .field-page {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    };

    .current {
        box-shadow: 0 0 2px 2px #FFFFFF;
    };

    .current-mobile {
        background-color: ${DARK_GREY_COLOR};
    };

    .field-page > * {
        margin: 0.5px;
    };
`;

interface IWorkingSiteLogSubHeaderProps extends RouteComponentProps {

};

export const WorkingSiteLogSubHeader: React.FC<IWorkingSiteLogSubHeaderProps> = React.memo((props: IWorkingSiteLogSubHeaderProps) => {

    const path = props.location.pathname;

    const onClick = (value: string): void => {
        if (value === "list")
            props.history.push({
                pathname: ROUTE.FIELD.WORKING_SITE_LOGS.INDEX,
                search: getTokenFromUrl(false)
            });

        // if (value === "field-employees")
        //     props.history.push({
        //         pathname: ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEES,
        //         search: getTokenFromUrl(false)
        //     });
        if (value === "crew")
            props.history.push({
                pathname: ROUTE.FIELD.WORKING_SITE_LOGS.CREWS,
                search: getTokenFromUrl(false)
            });

        if (value === "on-call")
            props.history.push({
                pathname: ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOGS_ON_CALL,
                search: getTokenFromUrl(false)
            });

        if (value === "messaging")
            props.history.push({
                pathname: ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGINGS,
                search: getTokenFromUrl(false)
            });
        
        if (value === "journals")
            props.history.push({
                pathname: ROUTE.FIELD.WORKING_SITE_LOGS.JOURNALS,
                search: getTokenFromUrl(false)
            });
       
        if (value === "working_site_status")
            window.open('https://reportserver.propsense.com/reports/report/Reports/Field/WorkingSitesStatus', '_blank');
    };

    const [report, setReport] = useState<any>(null);   
    const handleReports = (event?: any): void => report !== null ? setReport(null) : setReport(event.currentTarget);

    return (
        <WorkingSiteLogSubHeaderStyles>
            <div className="subHeader">
                <p>
                    <div className="field-page">
                        <LAMenuItem onClick={() => onClick("list")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOGS) ? "current" : ""}>
                            Working Site Logs
                        </LAMenuItem>
                        
                        {/* <LAMenuItem onClick={() => onClick("field-employees")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEES) ? "current" : ""}>
                            Field Employees
                        </LAMenuItem> */}
                        <LAMenuItem onClick={() => onClick("crew")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.CREWS) ? "current" : ""}>
                            Crew
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick("on-call")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOGS_ON_CALL) ? "current" : ""}>
                            On Call
                        </LAMenuItem>
                       
                        <LAMenuItem onClick={handleReports}>
                            Reports
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick("messaging")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGINGS) ? "current" : ""}>
                            Messaging
                        </LAMenuItem>

                        <LAMenuItem onClick={() => onClick("journals")} className={(path === ROUTE.FIELD.WORKING_SITE_LOGS.JOURNALS) ? "current" : ""}>
                            Journals
                        </LAMenuItem>
                       
                    </div>
                </p>
            </div>

            <Menu
                anchorEl={report}
                open={report}
                onClose={handleReports}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <LAMenuItem onClick={() => onClick("working_site_status")}>
                  Working Site Status
                </LAMenuItem>                
            </Menu>
        </WorkingSiteLogSubHeaderStyles>
    );
});