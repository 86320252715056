import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_METER_READING_BY_ID_REQUEST {
    REQUEST = "getMeterReadingById/GET_METER_READING_BY_ID_REQUEST",
    SUCCESS = "getMeterReadingById/GET_METER_READING_BY_ID_SUCCESS",
    FAILED = "getMeterReadingById/GET_METER_READING_BY_ID_FAILED"
};

export interface IGetMeterReadingByIdRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
    };
};

export interface IMeterReading {
    id: number,
    date: string,
    unit_Number?: string,
    unit_ID: number,
    current_Hours: number | null,
    location?: string,
    sub_Location?: string,
    sub_Location_ID?: number,
    update_Location_Check?: string,
    new_Location?: string,
    new_Sub_Location?: string,
    new_Sub_Location_ID?: number,
    ecm: number,
    cancelled: string,
    comments: string,
    created_By: string,
    modified_By: string,
    created: string,
    modified: string,
    meter_Replacement: string,
    replacement_Date: string,
    meter_Reset: number | null,
    socT_ID?: number,
    service_Truck_ID?: null,
    latitude?: string,
    longitude?: string,
    source?: string,
    source_ID?: number,
    latest?: boolean
};