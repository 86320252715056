import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IETDriver, IGetETDriverRequest } from "../../../../redux/field/driver/getDrivers/getDriversConstants";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../redux/server";
import { SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { YesOrNo, callRouteWithQueryString, getTokenFromUrl, userName } from "../../../shared/constExports";
import PageSpacing from "../../../shared/pageSpacing";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { WHITE_COLOR } from "../../../shared/theme";
import LALoading from "../../../shared/loading";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getDriversLoadAction } from "../../../../redux/field/driver/getDrivers/getDriversActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getDrivers } from "../../../../redux/field/driver/getDrivers/getDriversAccessor";
import SOCTHome from "../equipmenttrackerhome";
import LAErrorBox from "../../../shared/errorBox";


interface IDriversStoreProps {
    getDriver: Server<IETDriver[]>;
    getToken: Server<SurewayAPIResponse<IToken>>;
};

interface IDriversDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getDriversRequest: (data: IGetETDriverRequest) => unknown;
};


interface IDriversOwnProps {

};

interface IDriversState {
    activeView: boolean;
    activeDrivers: IETDriver[];
};

const DriversStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };
`;

type IDriversProps = RouteComponentProps
    & IDriversStoreProps
    & IDriversDispatchProps
    & IDriversOwnProps;

class ETDrivers extends PureComponent<IDriversProps, IDriversState> {

    public constructor(props: IDriversProps) {
        super(props);
        this.state = {
            activeView: true,
            activeDrivers: [],
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IDriversProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getDriver } = this.props;
        const { activeView, activeDrivers } = this.state;
        const data = activeView ? activeDrivers : hasPayload(getDriver) ? getDriver.payload : [];

        return (
            <PageSpacing title="Drivers" description="FIELD - DRIVERS" fixedSpaceOnSmallerScreens={true}>

                <DriversStyles>
                <SOCTHome history={this.props.history} />
                
                    <LAGrid>

                        <LAGridItem xs={12}>
                            <LAButton
                                label="Add New"
                                className="pull-left"
                                onClick={this.handleAdd}
                                startIcon={<AddIcon color={WHITE_COLOR} />}
                            />

                            <h2 className="text-center">DRIVERS</h2>
                            <hr />

                            {getDriver.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Drivers..." />}
                            {getDriver.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Drivers..." />}

                            {getDriver.kind === STATUS_ENUM.SUCCEEDED &&
                                <LAGrid>

                                    <LAGridItem xs={12}>
                                        <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                            <FormControlLabel value="active" control={<Radio />} label="Active Drivers" />
                                            <FormControlLabel value="all" control={<Radio />} label="All Drivers" />
                                        </RadioGroup>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LADevExtremeGrid
                                            data={data}
                                            onEdit={this.onEdit}
                                            onClick={this.onClick}
                                            searchPanel={true}
                                            filterHeader={true}
                                            export={true}
                                            actionWidth={120}
                                            removeStyleBtn={true}
                                            height={1200}
                                            id="driverList"
                                            customRowColor={true}
                                            exportFileName="Drivers"
                                            storageKey="driversSessionKey"
                                            getRefreshDataCall={this.getDataForTable}
                                            columns={[
                                                { name: "driver_Firstname", caption: "First Name", type: "string" },
                                                { name: "driver_Lastname", caption: "Last Name", type: "string" },
                                                { name: "active", caption: "Active", type: "string" },
                                                { name: "email", caption: "Email", type: "string" },
                                                { name: "assigned_Unit_Number", caption: "Unit #", type: "string" },
                                                { name: "phone_Number", caption: "Phone #", type: "number" },
                                                { name: "truck_Driver", caption: "Truck Driver", type: "string" },
                                                { name: "pilot_Driver", caption: "Pilot Driver", type: "string" },
                                                { name: "notes", caption: "Notes", type: "string" },
                                                { name: "created", caption: "Created", type: "datetime" },
                                                { name: "created_By", caption: "created By", type: "string" },
                                                { name: "modified", caption: "Modified", type: "datetime" },
                                                { name: "modified_By", caption: "Modified By", type: "string" }
                                            ]}
                                        />
                                    </LAGridItem>

                                </LAGrid>}
                        </LAGridItem>

                    </LAGrid>

                </DriversStyles>
            </PageSpacing>
        );
    }


    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        let activeView = !this.state.activeView;
        const getStorageValue = localStorage.getItem("key_drivers");
        if (getStorageValue) {
            if (activeView) {
                localStorage.setItem("key_drivers", "active");
            }
            else {
                localStorage.setItem("key_drivers", "all");
            }
        }
        else {
            localStorage.setItem("key_drivers", "active");
        }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.DRIVER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.DRIVER
        });
    };

    private checkViewSelections = (): void => {
        let activeView = this.state.activeView;
        const getStorageValue = localStorage.getItem("key_drivers");
        if (getStorageValue) {
            if (getStorageValue === "active") {
                activeView = true;
            }
            else {
                activeView = false;
            }
        }
        else {
            localStorage.setItem("key_drivers", "active");
        }
        this.setState({ activeView: activeView });
    }

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (isNotLoaded(this.props.getDriver)) {
                this.getDataForTable();
            };

            if (hasPayload(this.props.getDriver)) {
                this.setState({ activeDrivers: this.props.getDriver.payload.filter(x => x.active === YesOrNo[0].name) });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getDriversRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IDriversStoreProps => ({
    getToken: getToken(state),
    getDriver: getDrivers(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IDriversDispatchProps => ({
    getDriversRequest: (data: IGetETDriverRequest) => dispatch(getDriversLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(ETDrivers);