import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_METER_READING_REQUEST } from "./updateMeterReadingConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IUpdateMeterReadingLoadAction, IUpdateMeterReadingLoadFailedAction, IUpdateMeterReadingSuccessAction, 
    updateMeterReadingLoadFailedAction, updateMeterReadingLoadSuccessAction } from "./updateMeterReadingActions";

export const updateMeterReadingEpic: Epic = (
    action$: ActionsObservable<IUpdateMeterReadingLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateMeterReadingSuccessAction | IUpdateMeterReadingLoadFailedAction> =>
    action$.ofType(IUPDATE_METER_READING_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.SCOT.UPDATE_METER_READING,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateMeterReadingSuccessAction | IUpdateMeterReadingLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateMeterReadingLoadSuccessAction(response);
                            } else {
                                return updateMeterReadingLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateMeterReadingLoadFailedAction(response.message)))
                    )
            )
        );