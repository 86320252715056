import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST {
    REQUEST = "getVendorItemsByOrderID/GET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST",
    SUCCESS = "getVendorItemsByOrderID/GET_VENDOR_ITEMS_BY_ORDER_ID_SUCCESS",
    FAILED = "getVendorItemsByOrderID/GET_VENDOR_ITEMS_BY_ORDER_ID_FAILED"
};

export interface IGetVendorItemsByOrderIDRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};