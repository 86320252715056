import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_LOCATION_REQUEST } from "./addLocationConstants";
import { IAddLocationLoadAction, IAddLocationLoadFailedAction, IAddLocationSuccessAction, addLocationLoadFailedAction, addLocationLoadSuccessAction } from "./addLocationActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const addLocationEpic: Epic = (
    action$: ActionsObservable<IAddLocationLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddLocationSuccessAction | IAddLocationLoadFailedAction> =>
    action$.ofType(IADD_LOCATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ADD_LOCATION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddLocationSuccessAction | IAddLocationLoadFailedAction => {
                            if(response.message === "Success"){
                                return addLocationLoadSuccessAction(response);
                            } else {
                                return addLocationLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addLocationLoadFailedAction(response.message)))
                    )
            )
        );