import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORMS_REQUEST, IChecklistForm } from "./getFormsConstants";
import { IGetFormsLoadAction, IGetFormsLoadFailedAction, IGetFormsSuccessAction } from "./getFormsActions";
import { IAddFormTemplateSuccessAction } from "../addFormTemplate/addFormTemplateActions";
import { IUpdateFormTemplateSuccessAction } from "../updateFormTemplate/updateFormTemplateActions";
import { IADD_FORM_TEMPLATE_REQUEST } from "../addFormTemplate/addFormTemplateConstants";
import { IUPDATE_FORM_TEMPLATE_REQUEST } from "../updateFormTemplate/updateFormTemplateConstants";

type Actions =
    | IGetFormsLoadAction
    | IGetFormsSuccessAction
    | IGetFormsLoadFailedAction
    | IAddFormTemplateSuccessAction
    | IUpdateFormTemplateSuccessAction
    | IFlushDataSuccessAction;

export const GetFormsReducer = (state: Server<SurewayAPIResponse<IChecklistForm[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IChecklistForm[]>> => {
    switch (action.type) {
        case IGET_FORMS_REQUEST.REQUEST:
            return loading;

        case IGET_FORMS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORMS_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_FORM_TEMPLATE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FORM_TEMPLATE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};