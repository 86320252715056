import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_COUPLERS_REQUEST {
    REQUEST = "getCouplers/GET_COUPLERS_REQUEST",
    SUCCESS = "getCouplers/GET_COUPLERS_SUCCESS",
    FAILED = "getCouplers/GET_COUPLERS_FAILED"
};

export interface IGetCouplerRequest extends ISurewayTokenRequestBody {

};

export interface ICoupler {
    id: number;
    name: string;
    active: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};