import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IAPPROVE_SERVICE_SHEET_REQUEST, IApproveServiceSheetRequest } from "./approveServiceSheetConstants";


export interface IApproveServiceSheetLoadAction {
    type: IAPPROVE_SERVICE_SHEET_REQUEST.REQUEST;
    data: IApproveServiceSheetRequest
}
export const approveServiceSheetLoadAction = (data: IApproveServiceSheetRequest): IApproveServiceSheetLoadAction => ({
    type: IAPPROVE_SERVICE_SHEET_REQUEST.REQUEST,
    data
});
export interface IApproveServiceSheetSuccessAction {
    type: IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const approveServiceSheetLoadSuccessAction = (message: SurewayAPIResponse<string>): IApproveServiceSheetSuccessAction => ({
    type: IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS,
    message
});
export interface IApproveServiceSheetLoadFailedAction {
    type: IAPPROVE_SERVICE_SHEET_REQUEST.FAILED;
    message: string;
}
export const approveServiceSheetLoadFailedAction = (message: string): IApproveServiceSheetLoadFailedAction => ({
    type: IAPPROVE_SERVICE_SHEET_REQUEST.FAILED,
    message
});
