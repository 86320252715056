import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST, IGetFormResponseVersionHistoryByID } from "./getFormResponseVersionHistoryByIDConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetFormResponseVersionHistoryByIDLoadAction, IGetFormResponseVersionHistoryByIDLoadFailedAction, IGetFormResponseVersionHistoryByIDSuccessAction, 
    getFormResponseVersionHistoryByIDLoadFailedAction, getFormResponseVersionHistoryByIDLoadSuccessAction } from "./getFormResponseVersionHistoryByIDActions";

export const getFormResponseVersionHistoryByIDEpic: Epic = (
    action$: ActionsObservable<IGetFormResponseVersionHistoryByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormResponseVersionHistoryByIDSuccessAction | IGetFormResponseVersionHistoryByIDLoadFailedAction> =>
    action$.ofType(IGET_FORM_RESPONSE_VERSION_HISTORY_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>>(
                    END_POINTS.FIELD.FORMS.GET_FORM_RESPONSE_VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>): IGetFormResponseVersionHistoryByIDSuccessAction => {
                            return getFormResponseVersionHistoryByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormResponseVersionHistoryByIDLoadFailedAction(response.message)))
                    )
            )
        );