import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, deleteText, fileToBase64Image, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { GetSurveyLookup } from "../../../redux/survey/getSurveyLookup/getSurveyLookupAccessor";
import { ISurveyLookup, ISurveyLookupRequest } from "../../../redux/survey/getSurveyLookup/getSurveyLookupConstants";
import { getSurveyLookupLoadAction } from "../../../redux/survey/getSurveyLookup/getSurveyLookupActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { LADropZone } from "../../shared/dropZone";
import { v4 as uuidv4 } from "uuid";
import LALoading from "../../shared/loading";
import { IAttachments, IGetAttachmentsRequest } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsConstants";
import { getSurveyAttachments } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsAccessor";
import { getAttachmentsLoadAction } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsActions";
import { webConfig } from "../../../utils/webConfig";
import { IAddUpdateSurveyAttachment, IAddUpdateSurveyAttachmentRequest } from "../../../redux/survey/addSurveyAttachment/addSurveyAttachmentConstants";
import { addSurveyAttachment } from "../../../redux/survey/addSurveyAttachment/addSurveyAttachmentAccessor";
import { updateSurveyAttachment } from "../../../redux/survey/updateSurveyAttachment/updateSurveyAttachmentAccessor";
import { addSurveyAttachmentLoadAction } from "../../../redux/survey/addSurveyAttachment/addSurveyAttachmentActions";
import { updateSurveyAttachmentLoadAction } from "../../../redux/survey/updateSurveyAttachment/updateSurveyAttachmentActions";

const RequiredFields: string[] = ["attachment_ID"];

interface IAddUpdateAttachmentComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISurveyLookup>>;
    getAttachmentsStatus: Server<SurewayAPIResponse<ById<IAttachments>>>;
    addSurveyAttachmentStatus: Server<SurewayAPIResponse<string>>;
    updateSurveyAttachmentStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateAttachmentComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISurveyLookupRequest) => unknown;
    getAttachmentsRequest: (data: IGetAttachmentsRequest) => unknown
    addSurveyAttachmentRequest: (data: IAddUpdateSurveyAttachmentRequest) => unknown;
    updateSurveyAttachmentRequest: (data: IAddUpdateSurveyAttachmentRequest) => unknown;
};

interface IAddUpdateAttachmentOwnProps {
    id?: string;
};

interface IAddUpdateAttachmentComponentState {
    data: IAttachments;
    serverError: string;
    imageUploadLoading: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateAttachmentStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .green-border {
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateAttachmentComponentProps =
    RouteComponentProps
    & IAddUpdateAttachmentOwnProps
    & IAddUpdateAttachmentComponentStoreProps
    & IAddUpdateAttachmentComponentDispatchProps;

class AddUpdateAttachment extends PureComponent<IAddUpdateAttachmentComponentProps, IAddUpdateAttachmentComponentState> {

    public constructor(props: IAddUpdateAttachmentComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                attachment_ID: 0,
                g_H: null,
                g_J: null,
                width: null,
                j_K: null,
                j_J1: null,
                notes: "",
                pictures: [],
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            imageUploadLoading: false,
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateAttachmentComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addSurveyAttachmentStatus !== prevProps.addSurveyAttachmentStatus) {

                if (this.props.addSurveyAttachmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addSurveyAttachmentStatus.message });

                if (hasPayload(this.props.addSurveyAttachmentStatus) && this.props.addSurveyAttachmentStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateSurveyAttachmentStatus !== prevProps.updateSurveyAttachmentStatus) {

                if (this.props.updateSurveyAttachmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateSurveyAttachmentStatus.message });

                if (hasPayload(this.props.updateSurveyAttachmentStatus) && this.props.updateSurveyAttachmentStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, imageUploadLoading } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " ATTACHMENT";
        const { addSurveyAttachmentStatus, updateSurveyAttachmentStatus, getLookup } = this.props;

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;

        const attachments = hasPayload(getLookup) ? getLookup.payload.response.attachment_list : [];
        const attachment = data.attachment_ID && attachments ? attachments.find(q => q.id === data.attachment_ID) : null;
        const onAttachment = (value: string): void => value !== null ? this.handleAttachment(value) : this.handleAttachment();

        if(imageUploadLoading) {
            return <LALoading message="Uploading Files/Images... Please Wait" />
        }

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateAttachmentStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={attachments}
                                id="attachment-search"
                                placeHolder="Attachment No."
                                disabled={undefined}
                                displayExp="attachment_No"
                                name="attachment_No"
                                errorName="attachment_ID"
                                errors={this.state.errors}
                                currentVal={attachment?.attachment_No}
                                onClick={onAttachment}
                                columns={[
                                    { name: "attachment_No", caption: "Attachment No.", type: "string" },
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "type", caption: "Type", type: "string" },
                                    { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                    { name: "style", caption: "Style", type: "string" },
                                    { name: "attached_Unit_No", caption: "Attached Unit No.", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        {/* <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Equipment Type"
                                fullWidth={true}
                                variant="outlined"
                                name="equipment_Type"
                                disabled={true}
                                onChange={this.handleChange}
                                value={unit ? unit.equipment_Type : ""}
                                errorText={errors["equipment_Type"] ? errors["equipment_Type"].message : undefined}
                            />
                        </LAGridItem> */}

                        <LAGridItem xs={12} sm={6} md={3}>
                            {data.id === 0 && <LATextField
                                label="Site"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="location"
                                onChange={undefinedFunction}
                                value={attachment ? attachment.site : ""}
                            />}
                            {(data.id > 0) && <LATextField
                                label="Site"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="location"
                                onChange={undefinedFunction}
                                value={data.site ? data.site : ""}
                            />}
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={3}>
                            {data.id === 0 && <LATextField
                                label="Type"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="type"
                                onChange={undefinedFunction}
                                value={attachment ? attachment.type : ""}
                            />}
                            {(data.id > 0) && <LATextField
                                label="Type"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="type"
                                onChange={undefinedFunction}
                                value={data.type ? data.type : ""}
                            />}
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={3}>
                            {data.id === 0 && <LATextField
                                label="Style"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="style"
                                onChange={undefinedFunction}
                                value={attachment ? attachment.style : ""}
                            />}
                            {(data.id > 0) && <LATextField
                                label="Style"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="style"
                                onChange={undefinedFunction}
                                value={data.style ? data.style : ""}
                            />}
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={3}>
                            {data.id === 0 && <LATextField
                                label="Coupler Design"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="coupler_Design"
                                onChange={undefinedFunction}
                                value={attachment ? attachment.coupler_Design : ""}
                            />}
                            {(data.id > 0) && <LATextField
                                label="Coupler Design"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="coupler_Design"
                                onChange={undefinedFunction}
                                value={data.coupler_Design ? data.coupler_Design : ""}
                            />}
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={3}>
                            {data.id === 0 && <LATextField
                                label="Attached Unit No."
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="attached_Unit_No"
                                onChange={undefinedFunction}
                                value={attachment ? attachment.attached_Unit_No ?? "" : ""}
                            />}
                            {data.id > 0 &&<LATextField
                                label="Attached Unit"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="attached_Unit"
                                onChange={undefinedFunction}
                                value={data.attached_Unit_No ? data.attached_Unit_No ?? "" : ""}
                            />}
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="G-H"
                                fullWidth={true}
                                variant="outlined"
                                name="g_H"
                                disabled={undefined}
                                type="number"
                                value={data.g_H ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["g_H"] ? errors["g_H"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="G-J"
                                fullWidth={true}
                                variant="outlined"
                                name="g_J"
                                disabled={undefined}
                                type="number"
                                value={data.g_J ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["g_J"] ? errors["g_J"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Width"
                                fullWidth={true}
                                variant="outlined"
                                name="width"
                                disabled={undefined}
                                type="number"
                                value={data.width ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["width"] ? errors["width"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="J-K"
                                fullWidth={true}
                                variant="outlined"
                                name="j_K"
                                disabled={undefined}
                                type="number"
                                value={data.j_K ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["j_K"] ? errors["j_K"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="J-J1"
                                fullWidth={true}
                                variant="outlined"
                                name="j_J1"
                                disabled={undefined}
                                type="number"
                                value={data.j_J1 ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["j_J1"] ? errors["j_J1"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="notes"
                                value={data.notes ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} className="text-center">
                            <LAPaperWithLessPadding>
                                <strong>IMAGES</strong>
                                <hr />

                                <LAGridItem xs={12}>
                                    <LADropZone onFileDrop={this.pictureUpload} />
                                </LAGridItem>
                                <br />
                                <LAGrid>
                                    {(data.pictures !== null) && data.pictures.sort((a, b) => a.description > b.description ? 1 : -1)
                                        .map((q, ind) => {
                                            const base64 = (q.base64String !== null) ? q.base64String : (webConfig.imgApiBaseUrl + q.name);
                                            const onImgDelete = (): void => this.onImgDelete(ind);
                                            const onImageName = (name: string, value: string): void => this.onImageDescription(value, ind);

                                            return (
                                                <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                    <LAPaperWithLessPadding>
                                                        <LAThumbnailWithLink
                                                            key={ind}
                                                            url={base64}
                                                            alt={q.description}
                                                            id={(ind).toString()}
                                                        />

                                                        <LATextField
                                                            className="mt-2"
                                                            label="Description"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            name="Description"
                                                            disabled={undefined}
                                                            value={q.description}
                                                            onChange={onImageName}
                                                            errorText={
                                                                q.description.length === 0 ?
                                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                    : undefined
                                                            }
                                                        />

                                                        <LAIconButton
                                                            key={ind}
                                                            label="Delete"
                                                            onClick={onImgDelete}
                                                            icon={<DeleteIcon />}
                                                        />
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            )
                                        })}
                                </LAGrid>
                            </LAPaperWithLessPadding>
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addSurveyAttachmentStatus.kind} successMessage="Attachment has been added successfully" />
                    <RequestStatus requestStatus={updateSurveyAttachmentStatus.kind} successMessage="Attachment has been updated successfully" />

                </AddUpdateAttachmentStyles>
            </PageSpacing>
        );
    }

    private handleAttachment = (value?: string): void => {
        const attachments = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.attachment_list : [];
        const attachment = attachments.find((x: any) => x.attachment_No === value);
        let errors = { ...this.state.errors };

        if (RequiredFields.includes("attachment_ID"))
            errors = this.errorChecker("attachment_ID", value ?? "", errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                attachment_ID: attachment?.id ?? 0,
            },
            errors
        });
    };

    private pictureUpload = async (files: Array<File | DataTransferItem>): Promise<void> => {
        
        // const files = event.target.files;
        const pictures = this.state.data.pictures ? [...this.state.data.pictures] : [];
        
        if (files !== null) {
            
            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else { 
                    this.setState({ imageUploadLoading: true });
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    pictures.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })

                    isDuplicateDescription ?
                        pictures.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String,
                        }) : 
                        pictures.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String,
                        });

                    // images.push({
                    //     name: imgId + "." + description.split(".")[1],
                    //     description: description.split(".")[0],
                    //     base64String,
                    // });

                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            pictures: pictures.sort((a, b) => a.description > b.description ? 1 : -1)
                        },
                        imageUploadLoading: false
                    });
                }
            }));
        }
    };

    private onImageDescription = (value: string, imgIndex: number): void => {
        const pictures = [...this.state.data.pictures];
        pictures[imgIndex].description = value;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                pictures
            }
        });
    };

    private onImgDelete = (imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const pictures = [...this.state.data.pictures];
            pictures.splice(imgIndex, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    pictures
                }
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.ATTACHMENTS.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const request: IAddUpdateSurveyAttachment = {
            ID: data.id,
            Attachment_ID: data.attachment_ID,
            G_H: data.g_H ? Number(data.g_H) : null,
            G_J: data.g_J ? Number(data.g_J) : null,
            Width: data.width ? Number(data.width) : null,
            J_K: data.j_K ? Number(data.j_K) : null,
            J_J1: data.j_J1 ? Number(data.j_J1) : null,
            Notes: data.notes,
            Pictures: data.pictures,
            Created_By: data.created_By,
            Modified_By: data.modified_By
        }

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addSurveyAttachmentRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            }
            else {
                this.props.updateSurveyAttachmentRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ...request,
                        Modified_By: this.props.token.payload.response.upn
                    }
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getAttachmentsStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getAttachmentsStatus.payload.response[query.id];
                                // console.log(data)
                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getAttachmentsRequest({
                            token
                        });
                    }
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Attachments"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateAttachmentComponentStoreProps => ({
    token: getToken(state),
    getLookup: GetSurveyLookup(state),
    addSurveyAttachmentStatus: addSurveyAttachment(state),
    getAttachmentsStatus: getSurveyAttachments(state),
    // getMachineBoxesStatus: getMachineControlBoxes(state),
    // getMachineReceiverStatus: getMachineReceivers(state),
    updateSurveyAttachmentStatus: updateSurveyAttachment(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateAttachmentComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISurveyLookupRequest) => dispatch(getSurveyLookupLoadAction(data)),
    getAttachmentsRequest: (data: IGetAttachmentsRequest) => dispatch(getAttachmentsLoadAction(data)),
    // getMachineReceiverRequest: (data: IMachineReceiverRequest) => dispatch(getMachineReceiverLoadAction(data)),
    addSurveyAttachmentRequest: (data: IAddUpdateSurveyAttachmentRequest) => dispatch(addSurveyAttachmentLoadAction(data)),
    // getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => dispatch(getMachineControlBoxesLoadAction(data)),
    updateSurveyAttachmentRequest: (data: IAddUpdateSurveyAttachmentRequest) => dispatch(updateSurveyAttachmentLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateAttachment);