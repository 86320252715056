import { Grid, Button, Box, Modal, Backdrop, Typography, TextField, FormLabel, FormGroup, Checkbox, FormControlLabel} from "@mui/material";
  import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { addTruckingRequestEmail} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {copy_list} from '../../truckingRequestEmailsPage';
import {MobileBoxStyle} from '../../../../shared/styles';

interface IProps{
    closeEquipmentMoveForm:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
    units:any[];
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }

var trucking_request_email:any = {email:'',requested_email:false};

export default class AddNewTruckingRequestEmail extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[], units:[]};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
  
    closePopup(){
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'email':
                trucking_request_email.email = event.target.value;
                break;
            case 'requested_email':
                trucking_request_email.requested_email = !trucking_request_email.requested_email;
                break;
        }
        this.setState({});
    }
    async onSave(){
        let isUnique = true;
        if(trucking_request_email.email!="" && trucking_request_email.email !=null){
            copy_list.map((old_email:any) => {
                if(old_email.email.toLowerCase().trim() === trucking_request_email.email.toLowerCase().trim()){
                    isUnique = false;
                }
            });
            if(isUnique){
                var res:any = await addTruckingRequestEmail(trucking_request_email);
                if(res.status == 200){
                    this.props.closeEquipmentMoveForm();
                    this.props.refreshGrid();
                }   
            }
            else{
                alert("Email already exists!");
            }
        }
        else{
          alert("Email is mandatory");
        }
    }
    async componentDidMount(){
    }
    render(){
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <= 760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Adding New Trucking Request Email</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField style={{backgroundColor:'white'}} fullWidth label="Email" name="email" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <FormLabel component="legend">Requested Email list</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox onChange={this.handleChange} name="requested_email"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                  </Grid>
                    </Box>
                    </Modal>
        );
    }
}