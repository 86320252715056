import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_REQUEST, IUpdateAttachmentRequest } from "./updateAttachmentConstants";


export interface IUpdateAttachmentLoadAction {
    type: IUPDATE_ATTACHMENT_REQUEST.REQUEST;
    data: IUpdateAttachmentRequest
}
export const updateAttachmentLoadAction = (data: IUpdateAttachmentRequest): IUpdateAttachmentLoadAction => ({
    type: IUPDATE_ATTACHMENT_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentSuccessAction {
    type: IUPDATE_ATTACHMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentSuccessAction => ({
    type: IUPDATE_ATTACHMENT_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentLoadFailedAction {
    type: IUPDATE_ATTACHMENT_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentLoadFailedAction = (message: string): IUpdateAttachmentLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_REQUEST.FAILED,
    message
});
