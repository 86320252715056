import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_CONTROL_PLAN_REQUEST } from "./saveControlPlanConstants";
import { ISaveControlPlanLoadAction, ISaveControlPlanLoadFailedAction, ISaveControlPlanSuccessAction, saveControlPlanLoadFailedAction, saveControlPlanLoadSuccessAction } from "./saveControlPlanActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const saveControlPlanEpic: Epic = (
    action$: ActionsObservable<ISaveControlPlanLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveControlPlanSuccessAction | ISaveControlPlanLoadFailedAction> =>
    action$.ofType(ISAVE_CONTROL_PLAN_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.ADMIN.SAVE_CONTROL_PLAN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveControlPlanSuccessAction | ISaveControlPlanLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveControlPlanLoadSuccessAction(response);
                            } else {
                                return saveControlPlanLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveControlPlanLoadFailedAction(response.message)))
                    )
            )
        );