import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../../shared/errorBox";
import { FormsMenu, Navbar } from "../../../shared/navbars";
import { IGetHazardTypes, IGetHazardTypesRequest } from "../../../../redux/field/getHazardTypes/getHazardTypesConstants";
import { getHazardTypes } from "../../../../redux/field/getHazardTypes/getHazardTypesAccessor";
import { getHazardTypesLoadAction } from "../../../../redux/field/getHazardTypes/getHazardTypesActions";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface IHazardTypesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getHazardTypes: Server<SurewayAPIResponse<ById<IGetHazardTypes>>>;
};

interface IHazardTypesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getHazardTypesRequest: (data: IGetHazardTypesRequest) => unknown;
};


interface IHazardTypesOwnProps {

};

interface IHazardTypesState {
    activeView: boolean;
};

const HazardTypesStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IHazardTypesProps = RouteComponentProps
    & IHazardTypesStoreProps
    & IHazardTypesDispatchProps
    & IHazardTypesOwnProps;

class HazardTypes extends PureComponent<IHazardTypesProps, IHazardTypesState> {

    public constructor(props: IHazardTypesProps) {
        super(props);
        this.state = {
            activeView: true
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IHazardTypesProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { activeView } = this.state;
        const { getHazardTypes, getToken } = this.props;
        const getRole = pageAccessCheck(getToken, "formsAdmin");
        const getCleanUpRole = pageAccessCheck(getToken, "formsCleanupAccess");
        const data = hasPayload(getHazardTypes) ? activeView ? Object.values(getHazardTypes.payload.response).filter(x => x.active === "Yes") : 
                        Object.values(getHazardTypes.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Hazard Types" description="Forms - Hazard Types List" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={FormsMenu} role={getRole === true ? true : getCleanUpRole === true ? "formsCleanupAccess": getRole}/>
                {(getRole !== NotApplicable) && 
                    <HazardTypesStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">HAZARD TYPES</h2>
                                    <hr />

                                    {getHazardTypes.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Hazard Types..." />}
                                    {getHazardTypes.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Hazard Types..." />}

                                    {getHazardTypes.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                    <FormControlLabel value="active" control={<Radio />} label="Active" />
                                                    <FormControlLabel value="all" control={<Radio />} label="All" />
                                                </RadioGroup>
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    // onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={240}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="HazardTypesList"
                                                    customRowColor={true}
                                                    storageKey="HazardTypesSessionKey"
                                                    exportFileName="HazardTypes"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "name", caption: "Hazard Type", type: "string" },
                                                        { name: "sort_Order", caption: "Sort Order", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </HazardTypesStyles>
                }
            </PageSpacing>
        
        );
    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.ADD_UPDATE_HAZARD_TYPE
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FORMS.ADMIN.HAZARD_TYPES.ADD_UPDATE_HAZARD_TYPE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "formsAdmin") !== NotApplicable) {
                if (isNotLoaded(this.props.getHazardTypes)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getHazardTypesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IHazardTypesStoreProps => ({
    getToken: getToken(state),
    getHazardTypes: getHazardTypes(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IHazardTypesDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getHazardTypesRequest: (data: IGetHazardTypesRequest) => dispatch(getHazardTypesLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HazardTypes);
