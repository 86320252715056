import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IADD_JOB_PIT_REQUEST {
    REQUEST = "addJobPit/ADD_JOB_PIT_REQUEST",
    SUCCESS = "addJobPit/ADD_JOB_PIT_SUCCESS",
    FAILED = "addJobPit/ADD_JOB_PIT_FAILED"
};

export interface IAddJobPitRequest extends ISurewayTokenRequestBody {
    request: {
        Job: string;
        Year: number;
        Description: string;
        Requested_By: string;
        Location: string;
        Active: string;
        Created_By: string;
        Modified_By: string;
    };
};