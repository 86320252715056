import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_ATTACHMENT_VERSION_HISTORY_REQUEST, IGetAttachmentVersionHistory } from "./getAttachmentVersionHistoryConstants";
import { IGetAttachmentVersionHistoryLoadAction, IGetAttachmentVersionHistoryLoadFailedAction, IGetAttachmentVersionHistorySuccessAction } from "./getAttachmentVersionHistoryActions";

type Actions =
    | IGetAttachmentVersionHistoryLoadAction
    | IGetAttachmentVersionHistorySuccessAction
    | IGetAttachmentVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetAttachmentVersionHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>> => {
    switch (action.type) {
        case IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ATTACHMENT_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};