import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_WORKING_SITE_LOG_REQUEST, IAddWorkingSiteLogRequest } from "./addWorkingSiteLogConstants";


export interface IAddWorkingSiteLogLoadAction {
    type: IADD_WORKING_SITE_LOG_REQUEST.REQUEST;
    data: IAddWorkingSiteLogRequest
}
export const addWorkingSiteLogLoadAction = (data: IAddWorkingSiteLogRequest): IAddWorkingSiteLogLoadAction => ({
    type: IADD_WORKING_SITE_LOG_REQUEST.REQUEST,
    data
});
export interface IAddWorkingSiteLogSuccessAction {
    type: IADD_WORKING_SITE_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addWorkingSiteLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddWorkingSiteLogSuccessAction => ({
    type: IADD_WORKING_SITE_LOG_REQUEST.SUCCESS,
    message
});
export interface IAddWorkingSiteLogLoadFailedAction {
    type: IADD_WORKING_SITE_LOG_REQUEST.FAILED;
    message: string;
}
export const addWorkingSiteLogLoadFailedAction = (message: string): IAddWorkingSiteLogLoadFailedAction => ({
    type: IADD_WORKING_SITE_LOG_REQUEST.FAILED,
    message
});
