import React from 'react';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, Modal, Box, Backdrop} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
  import SOCTHome from './equipmenttrackerhome';
   import DraggableItem, {locations} from './draggableItem';
   import {filterLocationMapping, getLocationMappingInfo, filterUnitMapping, filterMakeModelMapping} from './api/equipmenttracker/equipmentTrackerAPI';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomEquipmentMoveForm from './forms/equipmenttracker/equipmentMoveCustom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewEquipmentMoveForm from './forms/equipmenttracker/equipmentMoveRequestAddNew';
import { GREEN_COLOR, ORANGE_COLOR } from '../../shared/theme';

  interface IProps extends RouteComponentProps{
    history:any
  }
  interface IState {
    showDriverPopup:boolean;
    locations:any[];
    equipment:any[];
    loaded:boolean;
    showConfirmation:boolean;
    search_string:string;
    unit_search_string:string;
    showCustomMoveRequest:boolean;
    make_model_search_string:string;
    showAddNewForm:boolean;
  }

const BoxStyle:any = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'rgb(241, 241, 241)',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '4ch'
};
const ExitButtonStyle = {
  bgcolor: 'rgb(174, 180, 184)',
  color: 'white',
  fontWeight: 'bold',
  left:'94%'
}

var driverData:any;
var view:any = "trucking";
var temp_data:any[] = [];
var original_equipment:any[] = [];
var original_locations:any[] = [];
var searched_items:any[] = [];

var exportequipment:any[] = []
var exportlocations:any[] = [];
var search_box_xs_width:number = 3;
var disableEdit:boolean = false;

export default class Trucks extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state = {showDriverPopup:false, locations:[], equipment:[], loaded:false, showConfirmation:false,search_string:'', unit_search_string:'', 
    showCustomMoveRequest:false,make_model_search_string:'',showAddNewForm:false};
    this.filterView = this.filterView.bind(this);
    this.onSave = this.onSave.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.openCustomMoveRequest = this.openCustomMoveRequest.bind(this);
    this.refreshDraggableArea = this.refreshDraggableArea.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
  }
  closeEditForm(){
    this.setState({showAddNewForm:false});
    this.props.history.push('/field/equipmenttracker/trucking');
    this.refreshGrid();
}
  addNewEquipmentMove(){
    this.props.history.push("/field/equipmenttracker/equipmentmoves/id=0");
    this.setState({showAddNewForm:true});
  }
  refreshDraggableArea(event:any){
    this.refreshGrid();
  }
  openCustomMoveRequest = ():void => {
    this.setState({showCustomMoveRequest:true});
  }
  closeMoveRequest(){
    this.setState({showCustomMoveRequest:false});
}
  filterView(eventKey:any){
    switch(eventKey.target.value) {
      case 'trucking':
        view = 'trucking';
        this.setState({});
        break;
      case 'location':
        view='location';
        this.setState({});
        break;
    }
  }
  handlePopup(elem:any,event:any){
    driverData = elem;
    this.setState({showDriverPopup:true});
  }
  closePopup(event:any){
    this.setState({showDriverPopup:false})
  }
  onSave(event:any){
    console.log('saving data: ', locations);
  }
  async refreshGrid(){
    disableEdit = localStorage.getItem("ET-Role")==="R";
    let locations = await getLocationMappingInfo();
    original_locations = locations;
    // comment this to use test data
    exportlocations=original_locations;
    original_locations = exportlocations;

    searched_items = original_locations;
    if(window.innerWidth <=760){
      search_box_xs_width=12;
    }
    else{
      search_box_xs_width=3;
    }
    this.setState({locations:exportlocations, equipment:exportequipment});
  }
  async componentDidMount(){
   this.refreshGrid();
  }
    render(){
        return (
          <>
          <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' style={{width:'100%'}} variant="outlined" >
              {!disableEdit ? 
                <Grid container direction="row">
                <Grid item xs={3}>
                <MUIButton disabled={disableEdit} onClick={this.addNewEquipmentMove}  style={{backgroundColor:'#bf0000'}} variant="contained" startIcon={<AddCircleIcon />}>Create Move</MUIButton>
              </Grid>
              </Grid>
              :null}
              <Typography className='dataGrid-title'>Location Mapping</Typography>
              <Grid direction="row" style={{ display: 'flex', justifyContent: "flex-end" , alignItems: "center"}}>
                <label htmlFor="from-location" style={{ marginRight: "5px" }}>
                    <div style={{ width: "15px", height: "15px", backgroundColor: ORANGE_COLOR }}></div>
                </label>
                <strong style={{ marginRight: "5px" }}>Unit Moving From Location</strong>
                <label htmlFor="to-location" style={{ marginRight: "5px" }}>
                    <div style={{ width: "15px", height: "15px", backgroundColor: GREEN_COLOR }}></div>
                </label>
                <strong style={{ marginRight: "5px" }}>Unit Moving To Location</strong>
              </Grid>
              <br></br>
              <Grid container spacing={3} direction="row">
              <Grid item xs={search_box_xs_width}><TextField variant='outlined' label="Search by Location, Job #" 
                 onChange={(e:any)=>{this.setState({search_string:e.target.value});}
                 }
                 value={this.state.search_string}
                 InputProps={{endAdornment:(
                  <MUIButton 
                onClick={async(e:any)=>{
                  searched_items = await filterLocationMapping(original_locations, this.state.search_string);
                  exportlocations = searched_items;
                  console.log(searched_items)
                  this.setState({});}}
                ><SearchIcon></SearchIcon></MUIButton>
                 )}}
                 onKeyDown={async(e:any) => {
                   if(e.key === "Enter"){
                    searched_items = await filterLocationMapping(original_locations, this.state.search_string);
                    console.log(searched_items)
                    exportlocations = searched_items;
                    this.setState({});
                   }
                 }}
                 >
                   
                </TextField></Grid>
                       <Grid item xs={search_box_xs_width}><TextField variant='outlined' label="Search by Unit #" 
                 onChange={async(e:any)=>{
                  this.setState({unit_search_string:e.target.value});
                  // searched_items = await getLocationMappingInfo();
                  // exportlocations = searched_items.map((obj) => ({ ...obj, items:obj.items.filter((item:any) => item.content.toLowerCase().includes(e.target.value.toLowerCase()))})).filter((obj) => obj.items.length > 0);
                  searched_items = this.state.locations;
                  const searchValue = e.target.value.toLowerCase();
                  exportlocations = searched_items.reduce((result, obj) => {
                    const filteredItems = obj.items.filter((item:any) =>
                      item.content.toLowerCase().includes(searchValue)
                    );
                  
                    if (filteredItems.length > 0) {
                      result.push({ ...obj, items: filteredItems });
                    }
                    console.log(result);
                    return result;
                  }, []);
                  this.setState({});
                 }} 
                 value={this.state.unit_search_string}
                 InputProps={{
                   endAdornment:(
                    <MUIButton 
                    onClick={async(e:any)=>{
                      searched_items = await filterUnitMapping(original_locations, this.state.unit_search_string);
                      exportlocations = searched_items;
                      this.setState({});}}
                    ><SearchIcon></SearchIcon></MUIButton>
                   )
                 }}
                 onKeyDown={async(e:any) => {
                  if(e.key === "Enter"){
                   searched_items = await filterUnitMapping(original_locations, this.state.unit_search_string);
                   exportlocations = searched_items;
                   this.setState({});
                  }
                }}>
                </TextField></Grid>
                <Grid item xs={search_box_xs_width}><TextField variant='outlined' label="Search by Make, Model, Type" 
                 onChange={async(e:any)=>{this.setState({make_model_search_string:e.target.value});
                 }} 
                 value={this.state.make_model_search_string}
                 InputProps={{
                   endAdornment:(
                    <MUIButton 
                    onClick={async(e:any)=>{searched_items = await filterMakeModelMapping(original_locations, this.state.make_model_search_string);
                      exportlocations = searched_items;
                      this.setState({});}}
                    ><SearchIcon></SearchIcon></MUIButton>
                   )
                 }}
                 onKeyDown={async(e:any) => {
                  if(e.key === "Enter"){
                   searched_items = await filterMakeModelMapping(original_locations, this.state.make_model_search_string);
                   exportlocations = searched_items;
                   this.setState({});
                  }
                }}>
                </TextField></Grid>
                <Grid item xs={search_box_xs_width}>
                  <MUIButton    
                  variant="contained"
                  onClick={async(e:any)=>{searched_items = await filterUnitMapping(original_locations, "");
                  exportlocations = searched_items;
                  this.setState({search_string:"", unit_search_string:"",make_model_search_string:""})
                }}
                  >Clear Search</MUIButton>
                </Grid>
            </Grid>
            <br></br>
                            <DraggableItem refreshState={this.refreshDraggableArea} loaded={this.state.loaded} unitSearched={searched_items} locations={exportlocations} equipment={exportequipment}></DraggableItem>
              <Grid container spacing={4} direction="row">
    
            {this.state.showDriverPopup ? <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <MUIButton sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</MUIButton><Grid item xs={12}>{driverData.driver}</Grid> </Box>
                    </Modal>:null}
                    {this.state.showCustomMoveRequest? <CustomEquipmentMoveForm refreshGrid={this.refreshGrid.bind(this)} clickedLocation={"NA"} clickedItem={{}} locationOptions={["NA","NA"]} closeEquipmentMoveForm={this.closeMoveRequest.bind(this)}></CustomEquipmentMoveForm>:null}
              </Grid>
              {this.state.showAddNewForm ? <AddNewEquipmentMoveForm isFromRequest={false} refreshGrid={this.refreshGrid.bind(this)}  closeEquipmentMoveForm={this.closeEditForm}></AddNewEquipmentMoveForm>:null}
            </Card>
            </Grid>
            </>
        );
    }
}
export {temp_data};