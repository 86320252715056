
export enum IGET_HAZARDS_REQUEST {
    REQUEST = "getHazards/GET_HAZARDS_REQUEST",
    SUCCESS = "getHazards/GET_HAZARDS_SUCCESS",
    FAILED = "getHazards/GET_HAZARDS_FAILED"
};

export interface IGetHazardsRequest {
    token: string;
};

export interface IGetHazards {
    id: number;
    hazard: string;
    type_ID: number;
    active: string;
    department_Names?: string;
    type?: string;
    department_ID?: number;
    department?: string;
    controls: IControl[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

export interface IControl {
    id: number;
    control: string;
}
