import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_SOCT_BY_ID_REQUEST, ISOCT } from "./getSOCTByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetSOCTByIdLoadAction, IGetSOCTByIdLoadFailedAction, IGetSOCTByIdSuccessAction } from "./getSOCTByIdActions";

type Actions =
    | IGetSOCTByIdLoadAction
    | IGetSOCTByIdSuccessAction
    | IGetSOCTByIdLoadFailedAction
    // | IAddSOCTSuccessAction
    // | IUpdateSOCTSuccessAction
    | IFlushDataSuccessAction;

export const GetSOCTByIdReducer = (state: Server<SurewayAPIResponse<ISOCT>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ISOCT>> => {
    switch (action.type) {
        case IGET_SOCT_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_SOCT_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SOCT_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;
        
        // case IADD_SOCT_REQUEST.SUCCESS:
        //     return notLoaded;
        
        // case IUPDATE_SOCT_REQUEST.SUCCESS:
        //     return notLoaded;

        default:
            return state;
    }
};