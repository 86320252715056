import { ActionsObservable, Epic } from "redux-observable";
import { catchError, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { EXTERNAL_USER_LOGOUT } from "./externalUserLogOutConstants";
import { externalUserLogoutFailedAction, externalUserLogoutSuccessAction, IExternalUserLogOutRequestAction } from "./externalUserLogOutActions";
import { IAction } from "../../reducers";

export const externalUserLogoutEpic: Epic = (
    action$: ActionsObservable<IExternalUserLogOutRequestAction>
): Observable<IAction> =>
    action$.ofType(EXTERNAL_USER_LOGOUT.REQUEST)
        .pipe(
            mergeMap(() => {
                return ActionsObservable.of(externalUserLogoutSuccessAction());
            }),
            catchError(() => {
                return ActionsObservable.of(externalUserLogoutFailedAction());
            })
        );
