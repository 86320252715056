import { ById } from '../../../../react/shared/publicInterfaces';
import { IGET_JOBS_MHR_REQUEST, IJobMhr } from "./getJobsMhrConstants";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGetJobsMHRLoadAction, IGetJobsMHRLoadFailedAction, IGetJobsMHRSuccessAction } from "./getJobsMhrActions";
import { IADD_JOB_MHR_REQUEST } from '../addJobMhr/addJobMhrConstants';
import { IUPDATE_JOB_MHR_REQUEST } from '../updateJobMhr/updateJobMhrConstants';
import { IAddJobMhrSuccessAction } from '../addJobMhr/addJobMhrActions';
import { IUpdateJobMhrSuccessAction } from '../updateJobMhr/updateJobMhrActions';

type Actions =
    | IGetJobsMHRLoadAction
    | IGetJobsMHRSuccessAction
    | IAddJobMhrSuccessAction
    | IUpdateJobMhrSuccessAction
    | IGetJobsMHRLoadFailedAction
    | IFlushDataSuccessAction;

export const GetJobsMHRReducer = (state: Server<SurewayAPIResponse<ById<IJobMhr>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IJobMhr>>> => {
    switch (action.type) {
        case IGET_JOBS_MHR_REQUEST.REQUEST:
            return loading;

        case IGET_JOBS_MHR_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_JOBS_MHR_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_JOB_MHR_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_JOB_MHR_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};