import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SOCT_REPORT_REQUEST, IUpdateSoctReportRequest } from "./updateSoctReportConstants";


export interface IUpdateSoctReportLoadAction {
    type: IUPDATE_SOCT_REPORT_REQUEST.REQUEST;
    data: IUpdateSoctReportRequest
}
export const updateSoctReportLoadAction = (data: IUpdateSoctReportRequest): IUpdateSoctReportLoadAction => ({
    type: IUPDATE_SOCT_REPORT_REQUEST.REQUEST,
    data
});
export interface IUpdateSoctReportSuccessAction {
    type: IUPDATE_SOCT_REPORT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateSoctReportLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateSoctReportSuccessAction => ({
    type: IUPDATE_SOCT_REPORT_REQUEST.SUCCESS,
    message
});
export interface IUpdateSoctReportLoadFailedAction {
    type: IUPDATE_SOCT_REPORT_REQUEST.FAILED;
    message: SurewayAPIResponse<string>;
}
export const updateSoctReportLoadFailedAction = (message: SurewayAPIResponse<string>): IUpdateSoctReportLoadFailedAction => ({
    type: IUPDATE_SOCT_REPORT_REQUEST.FAILED,
    message
});
