import { IUPDATE_TECH_SERVICE_REQUEST } from "./updateTechServiceConstants";
import { IUpdateTechServiceLoadAction, IUpdateTechServiceLoadFailedAction, IUpdateTechServiceSuccessAction } from "./updateTechServiceActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateTechServiceLoadAction
    | IUpdateTechServiceSuccessAction
    | IUpdateTechServiceLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateTechServiceReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_TECH_SERVICE_REQUEST.REQUEST:
            return loading;

        case IUPDATE_TECH_SERVICE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_TECH_SERVICE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};