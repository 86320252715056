import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAAutoComplete from "../../shared/autoComplete";
import { IFieldEmployee, IGetFieldEmployeesRequest } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesConstants";
import { IAddFieldEmployeeRequest, IAddUpdateFieldEmployee } from "../../../redux/field/workingSiteLogs/addFieldEmployee/addFieldEmployeeConstants";
import { IUpdateFieldEmployeeRequest } from "../../../redux/field/workingSiteLogs/updateFieldEmployee/updateFieldEmployeeConstants";
import { getFieldEmployees } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesAccessor";
import { addFieldEmployeeStatus } from "../../../redux/field/workingSiteLogs/addFieldEmployee/addFieldEmployeeAccessor";
import { updateFieldEmployeeStatus } from "../../../redux/field/workingSiteLogs/updateFieldEmployee/updateFieldEmployeeAccessor";
import { addFieldEmployeeLoadAction } from "../../../redux/field/workingSiteLogs/addFieldEmployee/addFieldEmployeeActions";
import { getFieldEmployeesLoadAction } from "../../../redux/field/workingSiteLogs/getFieldEmployees/getFieldEmployeesActions";
import { updateFieldEmployeeLoadAction } from "../../../redux/field/workingSiteLogs/updateFieldEmployee/updateFieldEmployeeActions";
import { ArrowLeftIcon } from "../../shared/icons";
import { IFieldLookup, IFieldLookupRequest, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import PageSpacing from "../../shared/pageSpacing";


const RequiredFields: string[] = ["name", "department", "phone", "foreman"];

interface IAddUpdateFieldEmployeeComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addFieldEmployeeStatus: Server<SurewayAPIResponse<string>>;
    fieldEmployees: Server<SurewayAPIResponse<ById<IFieldEmployee>>>;
    updateFieldEmployeeStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateFieldEmployeeComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => unknown
    addFieldEmployeeRequest: (data: IAddFieldEmployeeRequest) => unknown;
    updateFieldEmployeeRequest: (data: IUpdateFieldEmployeeRequest) => unknown;
};

interface IAddUpdateFieldEmployeeOwnProps {
    id?: string;
};

interface IAddUpdateFieldEmployeeComponentState {
    data: IFieldEmployee;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateFieldEmployeeStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateFieldEmployeeComponentProps =
    RouteComponentProps
    & IAddUpdateFieldEmployeeOwnProps
    & IAddUpdateFieldEmployeeComponentStoreProps
    & IAddUpdateFieldEmployeeComponentDispatchProps;

class AddUpdateFieldEmployee extends PureComponent<IAddUpdateFieldEmployeeComponentProps, IAddUpdateFieldEmployeeComponentState> {

    public constructor(props: IAddUpdateFieldEmployeeComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                department: "",
                email: "",
                foreman: "",
                phone: "",
                active:YesOrNo[0].name,
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateFieldEmployeeComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addFieldEmployeeStatus !== prevProps.addFieldEmployeeStatus) {

                if (this.props.addFieldEmployeeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addFieldEmployeeStatus.message });

                if (hasPayload(this.props.addFieldEmployeeStatus) && this.props.addFieldEmployeeStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateFieldEmployeeStatus !== prevProps.updateFieldEmployeeStatus) {

                if (this.props.updateFieldEmployeeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateFieldEmployeeStatus.message });

                if (hasPayload(this.props.updateFieldEmployeeStatus) && this.props.updateFieldEmployeeStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { token, addFieldEmployeeStatus, updateFieldEmployeeStatus, getLookup } = this.props;
        const { data, errors, serverError } = this.state;
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        // console.log(data)

        const getRole = pageAccessCheck(token, "workingSiteLogs");
        const disabled = getRole === ReadOnly ? true : undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : disabled;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);
        const onForeman = (event: unknown, value: IForeman): void => this.handleChange("foreman", value !== null ? value.upn : data.foreman);
        
        return (
            <PageSpacing title="Field Employee" description="Field Employee" fixedSpaceOnSmallerScreens={true}>
                <AddUpdateFieldEmployeeStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "} FIELD EMPLOYEE</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Name"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled || data.id > 0}
                                    name="name"
                                    value={data.name ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["name"] ? errors["name"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={YesOrNo}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onActive}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                    defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Department"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled}
                                    name="department"
                                    value={data.department ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["department"] ? errors["department"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={foremans}
                                    autoHighlight={true}
                                    onChange={onForeman}
                                    filterSelectedOptions={true}
                                    getOptionLabel="display_Name"
                                    dropDownPlaceHolder="Foreman"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["foreman"] ? errors["foreman"].message : undefined}
                                    value={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                                    defaultValue={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Email"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabled}
                                    name="email"
                                    value={data.email ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    label="Phone (Digits Only)"
                                    fullWidth={true}
                                    variant="outlined"
                                    InputProps={{
                                        inputProps: {
                                            maxLength: 10
                                        }
                                    }}
                                    disabled={disabled}
                                    name="phone"
                                    value={data.phone ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["phone"] ? errors["phone"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>}

                    <RequestStatus requestStatus={addFieldEmployeeStatus.kind} successMessage="FieldEmployee has been added successfully" />
                    <RequestStatus requestStatus={updateFieldEmployeeStatus.kind} successMessage="FieldEmployee has been updated successfully" />

                </AddUpdateFieldEmployeeStyles>
            </PageSpacing>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.WORKING_SITE_LOGS.FIELD_EMPLOYEES,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {

        const {
            id,
            name,
            active,
            email,     
            phone,
            department,
            foreman,
        } = this.state.data;

        if (hasPayload(this.props.token)) {

            const request: IAddUpdateFieldEmployee = {
                ID: id,
                Name: name,
                Department: department,
                Foreman: foreman,
                Active: active,
                Email: email,
                Phone: phone,
                Created_By: this.props.token.payload.response.upn,
                Modified_By: this.props.token.payload.response.upn,
            }

            if (id === 0) {
                this.props.addFieldEmployeeRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } else {
                this.props.updateFieldEmployeeRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {


                    if (hasPayload(this.props.fieldEmployees)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.fieldEmployees.payload.response[query.id];
                                this.setState({
                                    data
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getFieldEmployeesRequest({
                            token: this.props.token.payload.response.token
                        });
                    }
                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLook();
                    };
                } else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Field_Employees"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        if (name === "email")
            rules.email = true;

        if (name === "phone")
            rules.phone = true;

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateFieldEmployeeComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    fieldEmployees: getFieldEmployees(state),
    addFieldEmployeeStatus: addFieldEmployeeStatus(state),
    updateFieldEmployeeStatus: updateFieldEmployeeStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateFieldEmployeeComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addFieldEmployeeRequest: (data: IAddFieldEmployeeRequest) => dispatch(addFieldEmployeeLoadAction(data)),
    getFieldEmployeesRequest: (data: IGetFieldEmployeesRequest) => dispatch(getFieldEmployeesLoadAction(data)),
    updateFieldEmployeeRequest: (data: IUpdateFieldEmployeeRequest) => dispatch(updateFieldEmployeeLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateFieldEmployee);