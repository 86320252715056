import { IUPDATE_JOB_MHR_REQUEST } from "./updateJobMhrConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IUpdateJobMhrLoadAction, IUpdateJobMhrLoadFailedAction, IUpdateJobMhrSuccessAction } from "./updateJobMhrActions";

type Actions =
    | IUpdateJobMhrLoadAction
    | IUpdateJobMhrSuccessAction
    | IUpdateJobMhrLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateJobMhrReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_JOB_MHR_REQUEST.REQUEST:
            return loading;

        case IUPDATE_JOB_MHR_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_JOB_MHR_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};