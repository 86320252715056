import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_WORKING_SITE_LOGS_REQUEST, IWorkingSiteLog, IWorkingSiteLogRequest } from "./getWorkingSiteLogsConstants";

export interface IGetWorkingSiteLogsLoadAction {
    type: IGET_WORKING_SITE_LOGS_REQUEST.REQUEST;
    data: IWorkingSiteLogRequest
}
export const getWorkingSiteLogsLoadAction = (data: IWorkingSiteLogRequest): IGetWorkingSiteLogsLoadAction => ({
    type: IGET_WORKING_SITE_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetWorkingSiteLogsSuccessAction {
    type: IGET_WORKING_SITE_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IWorkingSiteLog>>;
}
export const getWorkingSiteLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IWorkingSiteLog>>): IGetWorkingSiteLogsSuccessAction => ({
    type: IGET_WORKING_SITE_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetWorkingSiteLogsLoadFailedAction {
    type: IGET_WORKING_SITE_LOGS_REQUEST.FAILED;
    message: string;
}
export const getWorkingSiteLogsLoadFailedAction = (message: string): IGetWorkingSiteLogsLoadFailedAction => ({
    type: IGET_WORKING_SITE_LOGS_REQUEST.FAILED,
    message
});
