import { ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST, IEmployeeMessageResponseRequest } from "./saveEmployeeMessageResponseConstants";

export interface ISaveEmployeeMessageResponseLoadAction {
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.REQUEST;
    data: IEmployeeMessageResponseRequest
}
export const saveEmployeeMessageResponseLoadAction = (data: IEmployeeMessageResponseRequest): ISaveEmployeeMessageResponseLoadAction => ({
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.REQUEST,
    data
});
export interface ISaveEmployeeMessageResponseSuccessAction {
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS;
    message: string;
}
export const saveEmployeeMessageResponseLoadSuccessAction = (message: string): ISaveEmployeeMessageResponseSuccessAction => ({
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.SUCCESS,
    message
});
export interface ISaveEmployeeMessageResponseLoadFailedAction {
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.FAILED;
    message: string;
}
export const saveEmployeeMessageResponseLoadFailedAction = (message: string): ISaveEmployeeMessageResponseLoadFailedAction => ({
    type: ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST.FAILED,
    message
});
