import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { IGET_UNIT_DETAILS_REQUEST, IUnitDetails } from "./getUnitDetailsConstants";
import { IGetUnitDetailsLoadAction, IGetUnitDetailsLoadFailedAction, IGetUnitDetailsSuccessAction } from "./getUnitDetailsActions";

type Actions =
    | IGetUnitDetailsLoadAction
    | IGetUnitDetailsSuccessAction
    | IGetUnitDetailsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetUnitDetailsReducer = (state: Server<SurewayAPIResponse<IUnitDetails>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IUnitDetails>> => {
    switch (action.type) {
        case IGET_UNIT_DETAILS_REQUEST.REQUEST:
            return loading;

        case IGET_UNIT_DETAILS_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IGET_UNIT_DETAILS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};