import React from "react";
// import { IGetLogsHistory } from "../../../redux/field/getLogsHistory/getLogsHistoryConstants";
import { STATUS_ENUM } from "../../../redux/server";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { undefinedFunction } from "../../shared/constExports";
import { IWSSLogsHistory } from "../../../redux/field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDConstants";

interface ILogsHistoryPopupProps {
    open: boolean;
    status: STATUS_ENUM;
    site: string | undefined;
    onCancel: () => void;
    data: IWSSLogsHistory[];
};

export const LogsHistoryPopup: React.FC<ILogsHistoryPopupProps> = React.memo((props: ILogsHistoryPopupProps) => {
    const data = props.data;

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LAPaperWithPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>LOGS HISTORY {props.site && ` - ${props.site}`}</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Logs History..." />
                </LAGridItem>}

                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.SUCCEEDED) && (data && data.length === 0)
                    && <LAGridItem xs={12}>
                        <LAErrorBox text="No records Found" />
                    </LAGridItem>}

                <LAGridItem xs={12}>
                    <LADevExtremeGrid
                        data={data}
                        height={600}
                        id="workingSiteLogsHistory"
                        searchPanel={true}
                        storageKey="workingSiteLogsHistorySessionKey"
                        filterHeader={true}
                        onClick={undefinedFunction}
                        columns={[
                            { name: "date_", caption: "Date", type: "date", sortDesc: true },
                            { name: "foreman_Display", caption: "Foreman", type: "string" },
                            // { name: "site", caption: "Site", type: "string" },
                            // { name: "wsS_ID", caption: "WSS ID", type: "number" },
                            { name: "job_No", caption: "Job#", type: "string" },
                            { name: "status", caption: "Status", type: "string" },
                            { name: "equip_On_Site", caption: "Equip On Site", type: "number" },
                            { name: "equip_Working", caption: "Equip Working", type: "number" },
                            { name: "emp_Working", caption: "EMP Working", type: "number" },
                            { name: "images_Count", caption: "Images Count", type: "number" },
                            { name: "logs_Count", caption: "Logs Count", type: "number" },
                            { name: "service_Required", caption: "Service Required", type: "string" },
                            { name: "radio", caption: "Radio", type: "string" },
                            { name: "start_Time_Val", caption: "Start Time", type: "string" },
                            { name: "end_Time_Val", caption: "End Time", type: "string" },
                            { name: "work_Description", caption: "Work Description", type: "string" },
                            { name: "safety_Observations_Comments", caption: "Safety Observations Comments", type: "string" },
                            { name: "saturday_Working", caption: "Saturday Working", type: "string" },
                            { name: "sunday_Working", caption: "Sunday Working", type: "string" },
                            { name: "scheduled_Days_Off", caption: "Scheduled Days Off", type: "string" },
                            { name: "prime_Contractor", caption: "Prime Contractor", type: "string" },
                            { name: "modified", caption: "Modified", type: "datetime", show: false },
                            { name: "modified_By", caption: "Modified By", type: "string", show: false },
                            
                        ]}
                    />
                </LAGridItem>

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
});