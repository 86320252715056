import { IAddUpdateServiceTruck } from "../addServiceTruck/addServiceTruckConstants";

export enum IUPDATE_SERVICE_TRUCK_REQUEST {
    REQUEST = "updateServiceTruck/UPDATE_SERVICE_TRUCK_REQUEST",
    SUCCESS = "updateServiceTruck/UPDATE_SERVICE_TRUCK_SUCCESS",
    FAILED = "updateServiceTruck/UPDATE_SERVICE_TRUCK_FAILED"
};

export interface IUpdateServiceTruckRequest {
    token: string;
    request: IAddUpdateServiceTruck;
};