import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST, IGetDriverAssignmentVersionHistory, IGetDriverAssignmentVersionHistoryRequest } from "./getDriverAssignmentVersionHistoryConstants";

export interface IGetDriverAssignmentVersionHistoryLoadAction {
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetDriverAssignmentVersionHistoryRequest
}
export const getDriverAssignmentVersionHistoryLoadAction = (data: IGetDriverAssignmentVersionHistoryRequest): IGetDriverAssignmentVersionHistoryLoadAction => ({
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetDriverAssignmentVersionHistorySuccessAction {
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>;
}
export const getDriverAssignmentVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>): IGetDriverAssignmentVersionHistorySuccessAction => ({
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetDriverAssignmentVersionHistoryLoadFailedAction {
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getDriverAssignmentVersionHistoryLoadFailedAction = (message: string): IGetDriverAssignmentVersionHistoryLoadFailedAction => ({
    type: IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.FAILED,
    message
});
