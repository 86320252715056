import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_FIELD_LOOKUPS_REQUEST, IFieldLookup } from "./getFieldLookupsConstants";
import { IGetFieldLookupsLoadAction, IGetFieldLookupsLoadFailedAction, IGetFieldLookupsSuccessAction } from "./getFieldLookupsActions";
import { ISaveDriversAssignmentSuccessAction } from "../../dispatch/saveDriversAssignment/saveDriversAssignmentActions";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "../../dispatch/saveDriversAssignment/saveDriversAssignmentConstants";
import { IAddMechanicSuccessAction } from "../../mechanics/addMechanic/addMechanicActions";
import { IADD_MECHANIC_REQUEST } from "../../mechanics/addMechanic/addMechanicConstants";
import { IUpdateMechanicSuccessAction } from "../../mechanics/updateMechanic/updateMechanicActions";
import { IUPDATE_MECHANIC_REQUEST } from "../../mechanics/updateMechanic/updateMechanicConstants";
import { IAddAttachmentSuccessAction } from "../../attachment/addAttachment/addAttachmentActions";
import { IADD_ATTACHMENT_REQUEST } from "../../attachment/addAttachment/addAttachmentConstants";
import { IUpdateAttachmentSuccessAction } from "../../attachment/updateAttachment/updateAttachmentActions";
import { IUPDATE_ATTACHMENT_REQUEST } from "../../attachment/updateAttachment/updateAttachmentConstants";
import { IAddShopEquipmentSuccessAction } from "../../addEquipments/addShopEquipmentsActions";
import { IADD_SHOP_EQUIPMENT_REQUEST } from "../../addEquipments/addShopEquipmentsConstants";
import { IUPDATE_SHOP_EQUIPMENT_REQUEST } from "../../updateEquipments/updateShopEquipmentsConstants";
import { IUpdateShopEquipmentSuccessAction } from "../../updateEquipments/updateShopEquipmentsActions";

type Actions =
    | IGetFieldLookupsLoadAction
    | IGetFieldLookupsSuccessAction
    | ISaveDriversAssignmentSuccessAction
    | IGetFieldLookupsLoadFailedAction
    | IAddMechanicSuccessAction
    | IUpdateMechanicSuccessAction
    | IAddAttachmentSuccessAction
    | IUpdateAttachmentSuccessAction
    | IAddShopEquipmentSuccessAction
    | IUpdateShopEquipmentSuccessAction
    | IFlushDataSuccessAction;

export const GetFieldLookupsReducer = (state: Server<SurewayAPIResponse<IFieldLookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IFieldLookup>> => {
    switch (action.type) {
        case IGET_FIELD_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_FIELD_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FIELD_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};