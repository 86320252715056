import { ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST } from "./saveManagerCommentsForDefectItemConstants";
import { ISaveManagerCommentsForDefectItemLoadAction, ISaveManagerCommentsForDefectItemLoadFailedAction, ISaveManagerCommentsForDefectItemSuccessAction } from "./saveManagerCommentsForDefectItemActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | ISaveManagerCommentsForDefectItemLoadAction
    | ISaveManagerCommentsForDefectItemSuccessAction
    | ISaveManagerCommentsForDefectItemLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveManagerCommentsForDefectItemReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.REQUEST:
            return loading;

        case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};