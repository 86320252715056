import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, SurveyMenu } from "../../shared/navbars";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { IAttachments, IGetAttachmentsRequest } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsConstants";
import { getAttachmentsLoadAction } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsActions";
import { getSurveyAttachments } from "../../../redux/survey/getSurveyAttachment/getSurveyAttachmentsAccessor";

interface IAttachementsComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getAttachmentsStatus: Server<SurewayAPIResponse<ById<IAttachments>>>;
};

interface IAttachementsComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getAttachmentsRequest: (data: IGetAttachmentsRequest) => unknown;
};


interface IAttachementsComponentOwnProps {

};

interface IAttachementsComponentState {
};

const AttachementsComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IAttachementsComponentProps = RouteComponentProps
    & IAttachementsComponentStoreProps
    & IAttachementsComponentDispatchProps
    & IAttachementsComponentOwnProps;

class AttachementsComponent extends PureComponent<IAttachementsComponentProps, IAttachementsComponentState> {
    
    public constructor(props: IAttachementsComponentProps) {
        super(props);
        this.state = {
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAttachementsComponentProps): void {

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getAttachmentsStatus, getToken  } = this.props;
        const getRole = pageAccessCheck(getToken, "surveyAccess");
        const data = hasPayload(getAttachmentsStatus) ? Object.values(getAttachmentsStatus.payload.response) : [];

        return (
            <PageSpacing title="Base Kit" description="Survey - Base Kit" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={SurveyMenu} role={getRole}/>
                {(getRole !== NotApplicable) && <>
                    <AttachementsComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>ATTACHMENTS</h2><br />
                                <hr />

                                {getAttachmentsStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Attachments..." />}
                                {getAttachmentsStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Attachments..." />}

                                {getAttachmentsStatus.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="Attachements"
                                                customRowColor={true}
                                                exportFileName="Attachements"
                                                storageKey="MC-Attachements"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "attachment_No", caption: "Attachment #", type: "string" },
                                                    { name: "type", caption: "Type", type: "string" },
                                                    { name: "attached_Unit_No", caption: "Attached Unit No.", type: "string" },
                                                    { name: "g_H", caption: "G-H", type: "string" },
                                                    { name: "g_J", caption: "G-J", type: "string" },
                                                    { name: "width", caption: "Width", type: "string" },
                                                    { name: "j_K", caption: "J-K", type: "string" },
                                                    { name: "j_J1", caption: "J-J1", type: "string" },
                                                    { name: "notes", caption: "Notes", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true, show: false },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                    </AttachementsComponentStyles>
                </>}
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.SURVEY.ATTACHMENTS.ADD_UPDATE_ATTACHMENT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.SURVEY.ATTACHMENTS.ADD_UPDATE_ATTACHMENT
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "surveyAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getAttachmentsStatus)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getAttachmentsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAttachementsComponentStoreProps => ({
    getToken: getToken(state),
    getAttachmentsStatus: getSurveyAttachments(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAttachementsComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getAttachmentsRequest: (data: IGetAttachmentsRequest) => dispatch(getAttachmentsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachementsComponent);