import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_VENDOR_PO_LOG_BY_ID_REQUEST, IGetVendorPOLogByIDRequest, IGetVendorPOLogByID } from "./getVendorPOLogByIDConstants";

export interface IGetVendorPOLogByIDLoadAction {
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.REQUEST;
    data: IGetVendorPOLogByIDRequest
}
export const getVendorPOLogByIDLoadAction = (data: IGetVendorPOLogByIDRequest): IGetVendorPOLogByIDLoadAction => ({
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetVendorPOLogByIDSuccessAction {
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetVendorPOLogByID>;
}
export const getVendorPOLogByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetVendorPOLogByID>): IGetVendorPOLogByIDSuccessAction => ({
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetVendorPOLogByIDLoadFailedAction {
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getVendorPOLogByIDLoadFailedAction = (message: string): IGetVendorPOLogByIDLoadFailedAction => ({
    type: IGET_VENDOR_PO_LOG_BY_ID_REQUEST.FAILED,
    message
});
