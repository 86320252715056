import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SOCT_LIST_REQUEST, ISoctList } from "./getSoctListConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetSoctListLoadAction, IGetSoctListLoadFailedAction, IGetSoctListSuccessAction, 
    getSoctListLoadFailedAction, getSoctListLoadSuccessAction } from "./getSoctListActions";

export const getSoctListEpic: Epic = (
    action$: ActionsObservable<IGetSoctListLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSoctListSuccessAction | IGetSoctListLoadFailedAction> =>
    action$.ofType(IGET_SOCT_LIST_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<ISoctList[]>(
                    END_POINTS.FIELD.SCOT.GetSoctList,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: ISoctList[]): IGetSoctListSuccessAction => {
                            return getSoctListLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getSoctListLoadFailedAction(response)))
                    )
            )
        );