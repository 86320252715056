import { IAddUpdateAttachmentCoupler } from "../addCoupler/addCouplerConstants";

export enum IUPDATE_ATTACHMENT_COUPLER_REQUEST {
    REQUEST = "updateAttachmentCoupler/UPDATE_ATTACHMENT_COUPLER_REQUEST",
    SUCCESS = "updateAttachmentCoupler/UPDATE_ATTACHMENT_COUPLER_SUCCESS",
    FAILED = "updateAttachmentCoupler/UPDATE_ATTACHMENT_COUPLER_FAILED"
};

export interface IUpdateAttachmentCouplerRequest {
    token: string;
    request: IAddUpdateAttachmentCoupler;
};