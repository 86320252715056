import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_MACHINE_CONTROL_BOX_REQUEST, IAddUpdateMachineControlBoxRequest } from "./addMachineControlBoxConstants";

export interface IAddMachineControlBoxLoadAction {
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.REQUEST;
    data: IAddUpdateMachineControlBoxRequest
}
export const addMachineControlBoxLoadAction = (data: IAddUpdateMachineControlBoxRequest): IAddMachineControlBoxLoadAction => ({
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.REQUEST,
    data
});
export interface IAddMachineControlBoxSuccessAction {
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addMachineControlBoxLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddMachineControlBoxSuccessAction => ({
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.SUCCESS,
    message
});
export interface IAddMachineControlBoxLoadFailedAction {
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.FAILED;
    message: string;
}
export const addMachineControlBoxLoadFailedAction = (message: string): IAddMachineControlBoxLoadFailedAction => ({
    type: IADD_MACHINE_CONTROL_BOX_REQUEST.FAILED,
    message
});
