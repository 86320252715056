import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_LINE_BY_ID_REQUEST } from "./getRepairLineByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IRepairLine } from "../getRepairLines/getRepairLinesConstants";
import { IGetRepairLineByIdLoadAction, IGetRepairLineByIdLoadFailedAction, IGetRepairLineByIdSuccessAction, getRepairLineByIdLoadFailedAction, getRepairLineByIdLoadSuccessAction } from "./getRepairLineByIdActions";

export const getRepairLineByIdEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineByIdSuccessAction | IGetRepairLineByIdLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_LINE_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IRepairLine>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IRepairLine>): IGetRepairLineByIdSuccessAction => {
                            return getRepairLineByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineByIdLoadFailedAction(response.message)))
                    )
            )
        );