import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EQUIPMENT_REQUEST, IGetEquipment, IGetEquipmentRequest } from "./getEquipmentConstants";

export interface IGetEquipmentLoadAction {
    type: IGET_EQUIPMENT_REQUEST.REQUEST;
    data: IGetEquipmentRequest
}
export const getEquipmentLoadAction = (data: IGetEquipmentRequest): IGetEquipmentLoadAction => ({
    type: IGET_EQUIPMENT_REQUEST.REQUEST,
    data
});
export interface IGetEquipmentSuccessAction {
    type: IGET_EQUIPMENT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetEquipment>>;
}
export const getEquipmentLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetEquipment>>): IGetEquipmentSuccessAction => ({
    type: IGET_EQUIPMENT_REQUEST.SUCCESS,
    list
});
export interface IGetEquipmentLoadFailedAction {
    type: IGET_EQUIPMENT_REQUEST.FAILED;
    message: string;
}
export const getEquipmentLoadFailedAction = (message: string): IGetEquipmentLoadFailedAction => ({
    type: IGET_EQUIPMENT_REQUEST.FAILED,
    message
});
