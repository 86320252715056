import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IWorkingSiteLog } from "../getWorkingSiteLogs/getWorkingSiteLogsConstants";
import { IGET_WORKING_SITE_LOG_BY_ID_REQUEST, IGetWorkingSiteLogByIdRequest } from "./getWorkingSiteLogByIdConstants";

export interface IGetWorkingSiteLogByIdLoadAction {
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.REQUEST;
    data: IGetWorkingSiteLogByIdRequest
}
export const getWorkingSiteLogByIdLoadAction = (data: IGetWorkingSiteLogByIdRequest): IGetWorkingSiteLogByIdLoadAction => ({
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetWorkingSiteLogByIdSuccessAction {
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IWorkingSiteLog>;
}
export const getWorkingSiteLogByIdLoadSuccessAction = (list: SurewayAPIResponse<IWorkingSiteLog>): IGetWorkingSiteLogByIdSuccessAction => ({
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetWorkingSiteLogByIdLoadFailedAction {
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getWorkingSiteLogByIdLoadFailedAction = (message: string): IGetWorkingSiteLogByIdLoadFailedAction => ({
    type: IGET_WORKING_SITE_LOG_BY_ID_REQUEST.FAILED,
    message
});
