import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST, IPayrollMechanicLogsReport } from "./getPayrollMechanicLogsReportConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetPayrollMechanicLogsReportLoadAction, IGetPayrollMechanicLogsReportLoadFailedAction, IGetPayrollMechanicLogsReportSuccessAction, getPayrollMechanicLogsReportLoadFailedAction, getPayrollMechanicLogsReportLoadSuccessAction } from "./getPayrollMechanicLogsReportActions";

export const getPayrollMechanicLogsReportEpic: Epic = (
    action$: ActionsObservable<IGetPayrollMechanicLogsReportLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetPayrollMechanicLogsReportSuccessAction | IGetPayrollMechanicLogsReportLoadFailedAction> =>
    action$.ofType(IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IPayrollMechanicLogsReport[]>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_PAYROLL_MECHANIC_LOGS_REPORT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IPayrollMechanicLogsReport[]>): IGetPayrollMechanicLogsReportSuccessAction => {
                            return getPayrollMechanicLogsReportLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getPayrollMechanicLogsReportLoadFailedAction(response.message)))
                    )
            )
        );