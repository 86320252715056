import { IAddUpdateMedicalFacility } from "../addMedicalFacility/addMedicalFacilityConstants";

export enum IUPDATE_MEDICAL_FACILITY_REQUEST {
    REQUEST = "updateMedicalFacility/UPDATE_MEDICAL_FACILITY_REQUEST",
    SUCCESS = "updateMedicalFacility/UPDATE_MEDICAL_FACILITY_SUCCESS",
    FAILED = "updateMedicalFacility/UPDATE_MEDICAL_FACILITY_FAILED"
};

export interface IUpdateMedicalFacilityRequest {
    token: string;
    request: IAddUpdateMedicalFacility;
};