import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_RESPONSES_REQUEST, IFormResponse, IGetFormResponsesRequest } from "./getFormResponsesConstants";

export interface IGetFormResponsesLoadAction {
    type: IGET_FORM_RESPONSES_REQUEST.REQUEST;
    data: IGetFormResponsesRequest
}
export const getFormResponsesLoadAction = (data: IGetFormResponsesRequest): IGetFormResponsesLoadAction => ({
    type: IGET_FORM_RESPONSES_REQUEST.REQUEST,
    data
});
export interface IGetFormResponsesSuccessAction {
    type: IGET_FORM_RESPONSES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IFormResponse[]>;
}
export const getFormResponsesLoadSuccessAction = (list: SurewayAPIResponse<IFormResponse[]>): IGetFormResponsesSuccessAction => ({
    type: IGET_FORM_RESPONSES_REQUEST.SUCCESS,
    list
});
export interface IGetFormResponsesLoadFailedAction {
    type: IGET_FORM_RESPONSES_REQUEST.FAILED;
    message: string;
}
export const getFormResponsesLoadFailedAction = (message: string): IGetFormResponsesLoadFailedAction => ({
    type: IGET_FORM_RESPONSES_REQUEST.FAILED,
    message
});
