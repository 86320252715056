import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_PAYROLL_MECHANIC_LOGS_REQUEST {
    REQUEST = "getPayrollMechanicLogs/GET_PAYROLL_MECHANIC_LOGS_REQUEST",
    SUCCESS = "getPayrollMechanicLogs/GET_PAYROLL_MECHANIC_LOGS_SUCCESS",
    FAILED = "getPayrollMechanicLogs/GET_PAYROLL_MECHANIC_LOGS_FAILED",
}

export interface IGetPayrollMechanicLogsRequest extends ISurewayTokenRequestBody {
    request? : {
        Type?: string;
    }
}

export interface IPayrollMechanicLogs {
    id: number;
    mechanic_ID: number;
    name: string;
    date: string;
    comments: string;
    component_ID: number;
    hours: number;
    component: string;
    unit_Number: string;
    status: string;
    sT_ID: number;
    service_Truck: string | null;
    approved: string;
    loa: string;
    component_Code: string;
    work_Order_No: string | null;
    field_Log: string | null;
    uploaded_Date: string | null;
    timesheet_ID: number;
    defect_ID: number;
    charge_Location: string;
    repair_Location: string;
    parts_Delay: string;
    parts_Delay_Hours: number | null;
    parts_Delay_Comments: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    display: string;
    unit_ID: number;
    attachment_ID: number;
    defect_Item: string;
    issue: string;
    job_No: string | null;
    cost_Code: string | null;
    item_Repair_Location: string;
    manager_Instructions: string;
    defect_Status: string;
    damaged: string;
    warranty: string;
    major_Component_Replacement: string;
    repair_ID: number;
    mechanic_Name: string;
    email: string;
    start_Date: string;
    end_Date: string;
    start_Time: string;
    end_Time: string;
    truck: string;
    employee_Code: string;
    item_Attachment_ID: number;
    meter_Reading: number;
    serial_No: string;
    major_Component_Replacement_Type: string;
    major_Component_Replacement_Hours: number;
    major_Component_Replacement_Notes: string;
    rejection_Notes: string;
    payroll_Acknowledged: string;
    description: string;
    type: string;
    travel_Type: string;
    travel_Truck_ID: number;
    truck_Hours: number;
}
