import { IAttachmentListFile } from "../addSOCT/addSOCTConstants";

export enum IADD_SCHEDULE_SERVICE_HISTORY_REQUEST {
    REQUEST = "addScheduleServiceHistory/ADD_SCHEDULE_SERVICE_HISTORY_REQUEST",
    SUCCESS = "addScheduleServiceHistory/ADD_SCHEDULE_SERVICE_HISTORY_SUCCESS",
    FAILED = "addScheduleServiceHistory/ADD_SCHEDULE_SERVICE_HISTORY_FAILED"
};

export interface IAddScheduleServiceHistoryRequest {
    token: string;
    request: IAddUpdateScheduleServiceHistory;
};

export interface IAddUpdateScheduleServiceHistory {
    ID: number;
    // Unit_ID: number;
    SOCT_ID: number;
    Service_Date: string;
    // Service_Sub_Location_ID?: number;
    Meter_Reading: number | null;
    ECM?: number | null;
    Service_Type: string;
    Custom_Service: string;
    Completed_Date?: string;
    Cancelled?: string;
    Custom_Services?: ICustomService[];
    Files: IAttachmentListFile[];
    Service_Items?: string,
    Comments: string;
    Created_By: string;
    Modified_By?: string;
}

export interface ICustomService {
    hour_Interval: number;
    notes: string;
    notify_Shop_Admin: string;
    completed_Date: string;
    service_Date: string;
}