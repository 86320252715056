import { ReactNode, PureComponent, useCallback, useState } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { onExporting } from "../../shared/devExtreme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { IShopModelRequest } from "../../../redux/field/getModels/getShopModelConstants";
import { getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getFilterReport } from "../../../redux/field/getFilterReport/getFilterReportAccessor";
import { IFilterReport, IFilterReportRequest } from "../../../redux/field/getFilterReport/getFilterReportConstants";
import { getFilterReportLoadAction } from "../../../redux/field/getFilterReport/getFilterReportActions";
import DataGrid, { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Lookup, Scrolling, SearchPanel, StateStoring } from "devextreme-react/data-grid";
import { IUpdateFilterPartRequest } from "../../../redux/field/updateFilterPart/updateFilterPartConstants";
import { IAddFilterPartRequest, IAddUpdateFilterPart } from "../../../redux/field/addFilterPart/addFilterPartConstants";
import { addFilterPartLoadAction } from "../../../redux/field/addFilterPart/addFilterPartActions";
import { updateFilterPartLoadAction } from "../../../redux/field/updateFilterPart/updateFilterPartActions";
import { IEquipmentList, IShopLookups, IShopLookupsRequest } from "../../../redux/field/getLookups/getLookupsConstants";
import { getLookupsLoadAction } from "../../../redux/field/getLookups/getLookupsActions";
import { getLookups } from "../../../redux/field/getLookups/getLookupsAccessor";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { addFilterPart } from "../../../redux/field/addFilterPart/addFilterPartAccessor";
import { updateFilterPart } from "../../../redux/field/updateFilterPart/updateFilterPartAccessor";
import LAErrorBox from "../../shared/errorBox";
import { LAButton } from "../../shared/buttons";
import React from "react";
import { DataTimer } from "../../shared/timer";
import { BLUE_COLOR, GREEN_COLOR, MEDIA_QUERY_PHONE } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { IDeleteFilterPartRequest } from "../../../redux/field/deleteFilterPart/deleteFilterPartConstants";
import { deleteFilterPartLoadAction } from "../../../redux/field/deleteFilterPart/deleteFilterPartActions";
import { deleteFilterPart } from "../../../redux/field/deleteFilterPart/deleteFilterPartAccessor";

interface IFilterReportStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    addRequest: Server<SurewayAPIResponse<string>>;
    deleteRequest: Server<SurewayAPIResponse<string>>;
    updateRequest: Server<SurewayAPIResponse<string>>;
    getReport: Server<SurewayAPIResponse<ById<IFilterReport>>>;
    getDropDownData: Server<SurewayAPIResponse<IShopLookups>>;
};

interface IFilterReportDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getDropDownDataRequest: (data: IShopLookupsRequest) => unknown;
    getFilterReportRequest: (data: IFilterReportRequest) => unknown;
    addFilterPartRequest: (data: IAddFilterPartRequest) => unknown;
    deleteFilterPartRequest: (data: IDeleteFilterPartRequest) => unknown;
    updateFilterPartRequest: (data: IUpdateFilterPartRequest) => unknown;
};

interface IFilterReportOwnProps {

};

interface IFilterReportState {
    editMode: boolean;
    data: ById<IFilterReport>;
    serverError: string | undefined;
};

const FilterReportStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .right-side {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .clear-filters {       
        margin: 10px 10px;
        background-color: rgb(168, 0, 0);
    };

    .green-text {
        color: ${GREEN_COLOR};
        font-size: 9px;
    };

    .blue-text {
        color: ${BLUE_COLOR};
        font-size: 9px;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .right-side {
            top: auto;
            right: auto;
            position: static;
        };
    };
`;

type IFilterReportProps = RouteComponentProps
    & IFilterReportStoreProps
    & IFilterReportDispatchProps
    & IFilterReportOwnProps;

class FilterReport extends PureComponent<IFilterReportProps, IFilterReportState> {

    public constructor(props: IFilterReportProps) {
        super(props);
        this.state = {
            data: {},
            editMode: false,
            serverError: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IFilterReportProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addRequest !== prevProps.addRequest) {
                if (this.props.addRequest.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRequest.message });

                if (hasPayload(this.props.addRequest) && (this.props.addRequest.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

            if (this.props.updateRequest !== prevProps.updateRequest) {
                if (this.props.updateRequest.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRequest.message });

                if (hasPayload(this.props.updateRequest) && (this.props.updateRequest.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };

        };
    };


    public render(): ReactNode {


        const { editMode, data, serverError } = this.state;
        const { getToken, getDropDownData, addRequest, updateRequest } = this.props;

        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);
        const getRole = pageAccessCheck(getToken, "filterList");

        const makes = hasPayload(getDropDownData) ? getDropDownData.payload.response.manufacturers.map(({ manufacturer_Name }) => manufacturer_Name) : [];
        const systemList = hasPayload(getDropDownData) ? getDropDownData.payload.response.filter_Types : [];

        return (
            <PageSpacing title="Filter Report" description="FIELD - FILTER REPORT" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <>
                        <FilterReportStyles>

                            <h2 className="text-center">FILTERS</h2>
                            <hr />

                            {serverError && <LAErrorBox text={serverError} />}

                            <FilterReportDevExtreme
                                data={data}
                                makes={makes}
                                editMode={editMode}
                                systemList={systemList}
                                onEditStart={onEditStart}
                                onEditCancel={onEditCancel}
                                filterUnits={this.filterUnits}
                                onSaveClick={this.onSaveClick}
                                filterModels={this.filterModels}
                                clearUnitValue={this.clearUnitValue}
                                setUnitValue={this.setUnitValue}
                                clearModelValue={this.clearModelValue}
                                getRefreshDataCall={this.getDataForTable}
                            />

                            <Prompt when={editMode} message="You have unsaved changes ?" />

                            <RequestStatus requestStatus={addRequest.kind} successMessage="Record successfully saved" />
                            <RequestStatus requestStatus={updateRequest.kind} successMessage="Record successfully updated" />

                        </FilterReportStyles>
                    </>}
            </PageSpacing>
        );
    }

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private clearModelValue = (rowData: any, value: any, currentRowData: any): void => {
        rowData.model = "";
        rowData.make = value;
    };

    private clearUnitValue = (rowData: any, value: any, currentRowData: any): void => {
        rowData.unit_No = "";
        rowData.model = value;
    };

    private setUnitValue = (rowData: any, value: any, currentRowData: any): void => {
        const { getDropDownData } = this.props;
        const units = hasPayload(getDropDownData) ? getDropDownData.payload.response.units : [];
        const unit = units.find(unit => unit.unit_Number === value)
        rowData.unit_No = value;
        rowData.model = unit?.model;
        rowData.make = unit?.make;
    };

    private filterModels = (options: any): any => {
        const { getDropDownData } = this.props;
        const units = hasPayload(getDropDownData) ? getDropDownData.payload.response.units : [];
        let filteredModels = options.data && options.data.make ? filterModels(units, options.data.make) : units;

        return {
            store: filteredModels,
            filter: options.data && options.data.make ? ['make', '=', options.data.make] : null
        };

    };

    private filterUnits = (options: any): any => {
        const { getDropDownData } = this.props;
        const units = hasPayload(getDropDownData) ? getDropDownData.payload.response.units : [];
        let filteredUnits = (options.data && options.data.make && options.data.model) ? filterUnits(units, options.data.make, options.data.model) : filterUnits(units);

        return {
            store: filteredUnits,
            filter: null
        };
    };

    private onSaveClick = (record: any): void => {
        const { getToken, getDropDownData, addFilterPartRequest, updateFilterPartRequest } = this.props;

        if (hasPayload(getToken) && hasPayload(getDropDownData) && record.changes[0] !== undefined) {
            if(record.changes[0].type === "remove"){
                this.props.deleteFilterPartRequest({
                    request: {
                        id: record.changes[0].key
                    },
                    token: getToken.payload.response.token
                });
            } else {
                const { data } = record.changes[0];

            const makes = getDropDownData.payload.response.manufacturers;
            const units = getDropDownData.payload.response.units;

            const makeIdIndex = makes.findIndex(x => x.manufacturer_Name === data.make);
            const unitIdIndex = units.findIndex(x => x.unit_Number === data.unit_No);
            var datId = data.id;
            if (typeof data.id != "string") {
                datId = data.id;
            }
            else {
                datId = 0;
            }
            let request: IAddUpdateFilterPart = {
                ID: datId,
                Make_ID: (makeIdIndex >= 0) ? makes[makeIdIndex].id : 0,
                Unit_ID: (unitIdIndex >= 0) ? units[unitIdIndex].id : 0,
                Model: typeof data.model === "string" ? data.model : data.model.model,
                Unit_No: data.unit_No,
                Type: data.system,
                Sub_Type: data.sub_Type,
                Part_No: data.part_No,
                Quantity: data.quantity,
                Created_By: getToken.payload.response.upn,
                Modified_By: getToken.payload.response.upn
            };

            if (isNaN(data.id)) {
                addFilterPartRequest({
                    request,
                    token: getToken.payload.response.token
                });
            } else {
                updateFilterPartRequest({
                    request,
                    token: getToken.payload.response.token
                });
            };

            this.updateEditMode(false);
            }
        };
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (isNotLoaded(this.props.getDropDownData) && hasPayload(this.props.getToken))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getReport) && hasPayload(this.props.getToken)) {
            this.setState({ data: this.props.getReport.payload.response });
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getReport))
            if ((pageAccessCheck(this.props.getToken, "filterList") !== NotApplicable)) {
                this.getDataForTable();
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (pageAccessCheck(this.props.getToken, "filterList") !== NotApplicable)) {
            this.props.getFilterReportRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IFilterReportStoreProps => ({
    getToken: getToken(state),
    addRequest: addFilterPart(state),
    getReport: getFilterReport(state),
    getDropDownData: getLookups(state),
    deleteRequest: deleteFilterPart(state),
    updateRequest: updateFilterPart(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IFilterReportDispatchProps => ({
    getDropDownDataRequest: (data: IShopLookupsRequest) => dispatch(getLookupsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    addFilterPartRequest: (data: IAddFilterPartRequest) => dispatch(addFilterPartLoadAction(data)),
    getFilterReportRequest: (data: IShopModelRequest) => dispatch(getFilterReportLoadAction(data)),
    deleteFilterPartRequest: (data: IDeleteFilterPartRequest) => dispatch(deleteFilterPartLoadAction(data)),
    updateFilterPartRequest: (data: IUpdateFilterPartRequest) => dispatch(updateFilterPartLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterReport);



export const filterModels = (data: IEquipmentList[], make?: string): IEquipmentList[] => {
    const res: IEquipmentList[] = [];
    const models: string[] = [];
    if (make)
        data.forEach((x) => {
            if (x.make === make)
                if (models.indexOf(x.model) === -1) {
                    models.push(x.model);
                    res.push(x);
                }
        });

    return res.sort((a, b) => a.model.localeCompare(b.model));
};

export const filterUnits = (data: IEquipmentList[], make?: string, model?: string): string[] => {
    const res: string[] = [];

    if (model && make) {
        res.push("All");
        data.forEach((x) => {
            if ((x.make === make) && (x.model === model))
                if (res.indexOf(x.unit_Number) === -1) {
                    res.push(x.unit_Number);
                }
        });
    }
    else {
        res.push("All");
        data.forEach((x) => {
            if (res.indexOf(x.unit_Number) === -1) {
                res.push(x.unit_Number);
            }
        });
    }
    return res;
};


const storageKey = "filter-report-storage-key";
interface IFilterReportDevExtreme {
    data: any;
    makes: any;
    systemList: any;
    filterUnits: any;
    filterModels: any;
    editMode: boolean;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
    clearModelValue: (rowData: any, value: any, currentRowData: any) => void;
    clearUnitValue: (rowData: any, value: any, currentRowData: any) => void;
    setUnitValue: (rowData: any, value: any, currentRowData: any) => void;
};

const FilterReportDevExtreme: React.FC<IFilterReportDevExtreme> = React.memo((props: IFilterReportDevExtreme) => {

    const onExportClick = (e: any): void => onExporting(e, "Filter-Report");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearFilters = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

        }
    };

    const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };

    return (
        <LAGrid>

            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearFilters}
                    disabled={undefined}
                />

                {props.getRefreshDataCall && <DataTimer
                    key={storageKey}
                    className="right-side"
                    onTimerEnd={props.getRefreshDataCall}
                />}
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    showBorders={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    syncLookupFilterValues={true}
                    columnHidingEnabled={true}
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                    filterSyncEnabled={false}
                >

                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={false} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <StateStoring

                        storageKey={storageKey}
                        enabled={true}
                    />
                    <SearchPanel
                        visible={true}
                        width={300}
                        placeholder="Search..."
                        // text={searchValue}
                        // onTextChange={handleValueChange}
                    />
                    <StateStoring
                        type="custom"
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                        enabled={true}
                    />
                    <Scrolling columnRenderingMode="virtual" />
                    <Editing
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={true}
                        mode="row"
                    />

                    <ColumnChooser
                        enabled={true}
                        mode="select"
                        height={window.innerHeight - 100}
                    />
                    <Column dataField="id" sortOrder={"desc"} />
                    <Column dataField="make" caption="Make" setCellValue={props.clearModelValue} allowEditing={!props.editMode} width={200} >
                        <Lookup dataSource={props.makes} />   </Column>

                    <Column dataField="model" caption="Model" setCellValue={props.clearUnitValue} allowEditing={!props.editMode} width={150}>
                        <Lookup dataSource={props.filterModels} displayExpr="model" valueExpr="model" />
                    </Column>

                    <Column dataField="unit_No" caption="Unit #" width={120} allowEditing={!props.editMode} setCellValue={props.setUnitValue}>
                        <Lookup dataSource={props.filterUnits} />
                    </Column>

                    <Column dataField="system" caption="System" >
                        <Lookup dataSource={props.systemList} />
                    </Column>

                    <Column dataField="sub_Type" />
                    <Column dataField="part_No" />
                    <Column dataField="quantity" width={90} />
                    <Column dataField="shop_stock" caption="1492 Stock" width={120} allowEditing={false} />
                    <Column dataField="shop_Bin" caption="1492 Bin" width={120} allowEditing={false} />
                    <Column dataField="silBru_stock" caption="SilBru Stock" width={120} allowEditing={false} />
                    <Column dataField="silBru_Bin" caption="SilBru Bin" width={120} allowEditing={false} />

                </DataGrid>
            </LAGridItem>

        </LAGrid>
    )
});