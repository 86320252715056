import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_LASER_REQUEST, IAddUpdateLaserRequest } from "./addLaserConstants";

export interface IAddLaserLoadAction {
    type: IADD_LASER_REQUEST.REQUEST;
    data: IAddUpdateLaserRequest
}
export const addLaserLoadAction = (data: IAddUpdateLaserRequest): IAddLaserLoadAction => ({
    type: IADD_LASER_REQUEST.REQUEST,
    data
});
export interface IAddLaserSuccessAction {
    type: IADD_LASER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const addLaserLoadSuccessAction = (list: SurewayAPIResponse<string>): IAddLaserSuccessAction => ({
    type: IADD_LASER_REQUEST.SUCCESS,
    list
});
export interface IAddLaserLoadFailedAction {
    type: IADD_LASER_REQUEST.FAILED;
    message: string;
}
export const addLaserLoadFailedAction = (message: string): IAddLaserLoadFailedAction => ({
    type: IADD_LASER_REQUEST.FAILED,
    message
});
