import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST, IMechanicTimesheetForApprovalGroupByDate, IGetMechanicTimesheetForApprovalGroupByDateRequest } from './getMechanicTimesheetForApprovalGroupByDateConstants';

export interface IGetMechanicTimesheetForApprovalGroupByDateLoadAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST;
    data: IGetMechanicTimesheetForApprovalGroupByDateRequest
}
export const getMechanicTimesheetForApprovalGroupByDateLoadAction = (data: IGetMechanicTimesheetForApprovalGroupByDateRequest): IGetMechanicTimesheetForApprovalGroupByDateLoadAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST,
    data
});
export interface IGetMechanicTimesheetForApprovalGroupByDateSuccessAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IMechanicTimesheetForApprovalGroupByDate[]>;
}
export const getMechanicTimesheetForApprovalGroupByDateLoadSuccessAction = (list: SurewayAPIResponse<IMechanicTimesheetForApprovalGroupByDate[]>): IGetMechanicTimesheetForApprovalGroupByDateSuccessAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS,
    list
});
export interface IGetMechanicTimesheetForApprovalGroupByDateLoadFailedAction {
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED;
    message: string;
}
export const getMechanicTimesheetForApprovalGroupByDateLoadFailedAction = (message: string): IGetMechanicTimesheetForApprovalGroupByDateLoadFailedAction => ({
    type: IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED,
    message
});
