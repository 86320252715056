import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_EXTERNAL_USER_REQUEST, IUpdateExternalUserRequest } from "./updateExternalUserConstants";


export interface IUpdateExternalUserLoadAction {
    type: IUPDATE_EXTERNAL_USER_REQUEST.REQUEST;
    data: IUpdateExternalUserRequest
}
export const updateExternalUserLoadAction = (data: IUpdateExternalUserRequest): IUpdateExternalUserLoadAction => ({
    type: IUPDATE_EXTERNAL_USER_REQUEST.REQUEST,
    data
});
export interface IUpdateExternalUserSuccessAction {
    type: IUPDATE_EXTERNAL_USER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateExternalUserLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateExternalUserSuccessAction => ({
    type: IUPDATE_EXTERNAL_USER_REQUEST.SUCCESS,
    message
});
export interface IUpdateExternalUserLoadFailedAction {
    type: IUPDATE_EXTERNAL_USER_REQUEST.FAILED;
    message: string;
}
export const updateExternalUserLoadFailedAction = (message: string): IUpdateExternalUserLoadFailedAction => ({
    type: IUPDATE_EXTERNAL_USER_REQUEST.FAILED,
    message
});
