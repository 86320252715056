import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_BASE_KIT_REQUEST, IBaseKit, IBaseKitRequest } from "./getBaseKitsConstants";

export interface IGetBaseKitLoadAction {
    type: IGET_BASE_KIT_REQUEST.REQUEST;
    data: IBaseKitRequest
}
export const getBaseKitLoadAction = (data: IBaseKitRequest): IGetBaseKitLoadAction => ({
    type: IGET_BASE_KIT_REQUEST.REQUEST,
    data
});
export interface IGetBaseKitSuccessAction {
    type: IGET_BASE_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IBaseKit>>;
}
export const getBaseKitLoadSuccessAction = (list: SurewayAPIResponse<ById<IBaseKit>>): IGetBaseKitSuccessAction => ({
    type: IGET_BASE_KIT_REQUEST.SUCCESS,
    list
});
export interface IGetBaseKitLoadFailedAction {
    type: IGET_BASE_KIT_REQUEST.FAILED;
    message: string;
}
export const getBaseKitLoadFailedAction = (message: string): IGetBaseKitLoadFailedAction => ({
    type: IGET_BASE_KIT_REQUEST.FAILED,
    message
});
