import React from 'react';
import DataGrid, {
    Column, Paging, Pager, Button, FilterRow, FilterPanel, HeaderFilter, SearchPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import { Grid, Button as MUIButton, FormHelperText} from '@mui/material';
  import {Modal, TextField, Backdrop, Fade, Box, InputLabel, FormControl,  Select, MenuItem} from '@mui/material';
  import 'devextreme-react/text-area';
  import { getSOCTEquipmentMoves, AddEquipmentMove} from '../../../api/equipmentmoves/equipmentMovesAPI';
  import {getSOCTWorkingSubSitesByParentID} from '../../../api/workingsites/workingSitesAPI';
  import { OutlinedRequiredStyle, OutlinedRequiredStyleSelected } from '../../../../../shared/styles';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import Autocomplete from "@mui/material/Autocomplete";
  import { getSOCTWorkingSites } from '../../../api/workingsites/workingSitesAPI';
  import { currentUser} from '../../../socthome';

  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
};
interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState {
   moves_list:any[];
   showAddNewForm:boolean;
   modalOpen:boolean;
   searchValue: string;
}
var editEquipmentMovesData:any;
var newEquipmentMove:any = {unit_number:'',current_location:'', new_location:'',new_sub_location:'', new_sub_location_ID:0,current_sub_location:'',
current_sub_location_ID:0,scheduled_move:'',date_of_move:'', comments:'', SOCT_ID:0};
var working_site_locations:any[] = [];
var sub_site_locations:any[] = [];
var sub_location_list:any[] = [];
var export_moves_list:any[] = [];
var mapped_location_list:any[] = [];
export default class EditMoveHistoryTable extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {moves_list:[], showAddNewForm:false, modalOpen:true, searchValue: ''};
        this.onAddNewCancel = this.onAddNewCancel.bind(this);
        this.onAddNewSave = this.onAddNewSave.bind(this);
        this.onAddNewSave = this.onAddNewSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'new_location':
                newEquipmentMove.new_location = event.target.value;
                var parent_id=0;   
                mapped_location_list.map((site:any) => {
                    console.log(site, event.target.value)
                    if(site.site_Name === event.target.value){
                        parent_id = site.id;
                    }
                });
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                break;
            case 'new_sub_location':
                newEquipmentMove.new_sub_location = event.target.value;
                sub_site_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        newEquipmentMove.new_sub_location_ID = site.id;
                    }
                });
                break;
            case 'scheduled_move':
                newEquipmentMove.scheduled_move = event.target.value;
                break;
            case 'date_of_move':
                newEquipmentMove.date_of_move = event.target.value;
                break;
            case 'comments':
                newEquipmentMove.comments = event.target.value;
                break;
        }
        this.setState({});
    }
    editItem(id:number, data:any){
        editEquipmentMovesData = data;
        editEquipmentMovesData.SOCT_ID = this.props.editData.SOCT_ID;
        console.log(editEquipmentMovesData)
        this.props.history.push('/field/soct/moves/edit/id='+id);
      }
    onAddNew(){
        this.setState({showAddNewForm:true});
    }
    onAddNewCancel(){ 
        newEquipmentMove = {unit_number:'',current_location:'', current_sub_location:'', current_sub_location_ID:'', new_location:'',new_sub_location:'', new_sub_location_ID:0,scheduled_move:'No',date_of_move:'', comments:''};
        this.setState({showAddNewForm:false});
        this.refreshGrid();
    }
    async onAddNewSave(){
        console.log(newEquipmentMove)
        if(newEquipmentMove.new_location!='' && newEquipmentMove.new_sub_location!='' && ((newEquipmentMove.scheduled_move==='Yes' && newEquipmentMove.date_of_move!='')|| (newEquipmentMove.scheduled_move==='No'))){
            var res:any = await AddEquipmentMove(newEquipmentMove, currentUser);
            if(res.status === 200){
                alert("Successfully added new equipment move");
                this.refreshGrid();
            }
            else{
                alert("Failed to add new equipment move");
            }
            this.setState({showAddNewForm:false});
            newEquipmentMove= {unit_number:'',current_location:'', new_location:'',new_sub_location:'', new_sub_location_ID:0,scheduled_move:'No',date_of_move:'', comments:''};
        }
        else{
            alert("Location, sub location, and schedule move are mandatory. If schedule move is yes, date of move must be entered");
        }
    }
    async refreshGrid(){
        working_site_locations = [];
       
        var tempsites:any = await getSOCTWorkingSites(true);
        tempsites.map((location:any) => {
            let location_name = location.site_Name + ", Job #: " + location.job_No;
            working_site_locations.push(location_name);
            mapped_location_list.push(location);
            });
        var unit_Number:string = this.props.editData.unit_Number
        newEquipmentMove.SOCT_ID = this.props.editData.SOCT_ID;
        var temp_moves_list:any = await getSOCTEquipmentMoves(newEquipmentMove.SOCT_ID);
        console.log(temp_moves_list)
        if(temp_moves_list != null){
            export_moves_list = temp_moves_list.sort((a:any,b:any) => ( a.id > b.id) ? -1:1);
        }
        //working_site_locations = working_site_locations.map((elem:any) => {return {}})
        newEquipmentMove.current_location = this.props.editData.location;
        newEquipmentMove.unit_number = this.props.editData.unit_Number;
        newEquipmentMove.current_sub_location = this.props.editData.sub_Location;
        newEquipmentMove.current_sub_location_ID = this.props.editData.sub_Location_ID;
        this.setState({moves_list:temp_moves_list});
    }
    async componentDidMount(){
        this.refreshGrid();
    }
    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue:trimmedValue });
      };
    render(){
        return (
            <>
            <Grid container spacing={6}>
                            <Grid item xs={12}>
                            <MUIButton disabled style={{backgroundColor:'#bf0000', marginLeft:'0%'}} onClick={this.onAddNew} variant="contained" startIcon={<AddCircleIcon />}>Add new</MUIButton>
                             <h1>Equipment Move History</h1>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.moves_list}
                keyExpr="id"
                showBorders={true}
                >
                <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                // text={this.state.searchValue}
                // onTextChange={this.handleValueChange}
                />    
                <Paging enabled={true} defaultPageSize={20}/>
                <Pager
                    visible={true}
                    allowedPageSizes={[20,40,60]}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true} />
                    <FilterRow visible={true}></FilterRow>
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Column type="buttons" caption="Edit" width={100} headerCellRender={renderTitleHeader}>
                        <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
                    </Column>
                    <Column dataType="string" dataField="current_Location" caption="Current Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="current_Sub_Location" caption="Current Sub Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="new_Location" caption="New Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="new_Sub_Location" caption="New Sub Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="scheduled_Move" caption="Scheduled Move?" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column sortOrder={"desc"} dataType="date" dataField="date_Of_Move" caption="Date of Move" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="cancelled" caption="Cancelled" alignment="left" headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Grid>
            </Grid>
            
                        {this.state.showAddNewForm ? <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.modalOpen}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        >  
                        <Fade in={this.state.modalOpen}>
                        <Box sx={BoxStyle}>
                            <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField label="Unit Number" disabled defaultValue={newEquipmentMove.unit_number}></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled label="Current Location" variant="outlined" defaultValue={newEquipmentMove.current_location}>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                            onChange={async (event:any, newValue:String | null) => {
                                let splitted_job_no = newValue?.split(", Job #: ")[0];
                                newEquipmentMove.new_location = splitted_job_no;
                                newEquipmentMove.new_sub_location = '';
                                newEquipmentMove.new_sub_location_ID = 0;
                                sub_location_list = [];
                                var parent_id=0;   
                                mapped_location_list.map((site:any) => {
                                    console.log(site, splitted_job_no)
                                    if(site.site_Name === splitted_job_no){
                                        parent_id = site.id;
                                    }
                                });
                                
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            autoHighlight
                            style={newEquipmentMove.new_location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="New Location"></TextField>}></Autocomplete>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                                <Autocomplete
                                onChange={(event:any, newValue:String | null) =>  {
                                    newEquipmentMove.new_sub_location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            newEquipmentMove.new_sub_location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                autoHighlight
                                options={sub_location_list} 
                                style={newEquipmentMove.new_sub_location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                                value={newEquipmentMove.new_sub_location}
                                renderInput={(params):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Sub Location"></TextField>}></Autocomplete>
                            <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </Grid>
                        <Grid item xs={4}>
                            <TextField style={{backgroundColor:'white'}} label="Comments" variant="outlined" onChange={this.handleChange} name="comments">
                            </TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl required sx={{width:'100%'}}>
                                <InputLabel id="demo-simple-select-label">Scheduled Move?</InputLabel>
                                    <Select
                                        variant="outlined"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={this.handleChange}
                                        name="scheduled_move"
                                        style={newEquipmentMove.scheduled_move ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                                    >
                                    <MenuItem value='Yes'>Yes</MenuItem>
                                    <MenuItem value='No'>No</MenuItem>
                                    </Select>
                                    <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </FormControl>
                        </Grid>
                        {newEquipmentMove.scheduled_move === "Yes" ? 
                        <Grid item xs={4}>
                            <InputLabel >Date Of Move</InputLabel>
                            <TextField style={{backgroundColor:'white'}} type="date"  onChange={this.handleChange} name="date_of_move"></TextField>
                        </Grid>
                        :null}
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000', left:'30%'}} onClick={this.onAddNewCancel} variant="contained">Cancel</MUIButton>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000',  left:'35%'}} onClick={this.onAddNewSave} variant="contained">Save</MUIButton>
                            </Grid>
                       </Box>
                       </Fade>
                        </Modal>:null}
            </>
        );
    }
}
export {editEquipmentMovesData, export_moves_list};