import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateLaserRequest } from "../addLaser/addLaserConstants";
import { IUPDATE_LASER_REQUEST } from "./updateLaserConstants";

export interface IUpdateLaserLoadAction {
    type: IUPDATE_LASER_REQUEST.REQUEST;
    data: IAddUpdateLaserRequest;
}
export const updateLaserLoadAction = (data: IAddUpdateLaserRequest): IUpdateLaserLoadAction => ({
    type: IUPDATE_LASER_REQUEST.REQUEST,
    data
});
export interface IUpdateLaserSuccessAction {
    type: IUPDATE_LASER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateLaserLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateLaserSuccessAction => ({
    type: IUPDATE_LASER_REQUEST.SUCCESS,
    list
});
export interface IUpdateLaserLoadFailedAction {
    type: IUPDATE_LASER_REQUEST.FAILED;
    message: string;
}
export const updateLaserLoadFailedAction = (message: string): IUpdateLaserLoadFailedAction => ({
    type: IUPDATE_LASER_REQUEST.FAILED,
    message
});
