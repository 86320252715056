
export enum IGET_WORKING_SITE_LOGS_REQUEST {
    REQUEST = "getWorkingSiteLogs/GET_WORKING_SITE_LOGS_REQUEST",
    SUCCESS = "getWorkingSiteLogs/GET_WORKING_SITE_LOGS_SUCCESS",
    FAILED = "getWorkingSiteLogs/GET_WORKING_SITE_LOGS_FAILED"
};

export interface IWorkingSiteLogRequest {
    token: string;
    request: {
        view: string;
    }
};

export interface IWorkingSiteLog {
    id: number;
    date: string;
    foreman: string;
    wsS_ID: number;
    site?: string;
    status: string;
    security: string;
    equip_On_Site?: number;
    equip_Working?: number;
    emp_Working?: number;
    service_Required: string;
    prime_Contractor: string;
    radio: string;
    start_Time_Val: string;
    end_Time_Val: string;
    work_Description: string;
    safety_Observations_Comments: string;
    saturday_Working: string;
    sunday_Working: string;
    scheduled_Days_Off: string;
    job_No: string;
    weather?: IWeatherDetails[];
    safety_Admin_Notes: string;
    safety_Addressed: string;
    safety_Images?: string;
    safety_Pictures: IWorkingSiteLogPicture[];
    // images: string;
    // pictures: IWorkingSiteLogPicture[];
    logs: ILogs[];
    hazard_Assessment?: string;
    hazard_Assessment_Form_ID?: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};

export interface IWorkingSiteLogPicture {
    name: string;
    description: string;
    base64String: string | null;
    timestamp: string;
};

export interface IWeatherDetails {
    id: number;
    wsS_ID: number;
    date: string;
    time: string;
    weather: string;
    temperature: number;
    wind: number;
    humidity: number;
    precipitation: number | null;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};

export interface ILogs {
    name: string;
    description: string;
    pictures: IWorkingSiteLogPicture[];
    created?: string;
    created_By?: string;
    id?: number;
    images?: string;
    log_ID?: number;
    modified?: string;
    modified_By?: string;
}