import { IJournelPicture } from "../getJournels/getJournelsConstants";

export enum IADD_JOURNEL_REQUEST {
    REQUEST = "addJournel/ADD_JOURNEL_REQUEST",
    SUCCESS = "addJournel/ADD_JOURNEL_SUCCESS",
    FAILED = "addJournel/ADD_JOURNEL_FAILED"
};

export interface IAddJournelRequest {
    token: string;
    request: IAddUpdateJournel;
};

export interface IAddUpdateJournel {
    ID: number;
    Date: string;
    Name: string;
    WSS_ID: number;
    Work: string;
    Description: string;
    Pictures: IJournelPicture[];
    Created_By: string;
    Modified_By?: string;
}