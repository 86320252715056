import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_SECTIONS_REQUEST, IServiceSection, IGetServiceSectionRequest } from "./getServiceSectionsConstants";

export interface IGetServiceSectionsLoadAction {
    type: IGET_SERVICE_SECTIONS_REQUEST.REQUEST;
    data: IGetServiceSectionRequest
}
export const getServiceSectionsLoadAction = (data: IGetServiceSectionRequest): IGetServiceSectionsLoadAction => ({
    type: IGET_SERVICE_SECTIONS_REQUEST.REQUEST,
    data
});
export interface IGetServiceSectionsSuccessAction {
    type: IGET_SERVICE_SECTIONS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IServiceSection>>;
}
export const getServiceSectionsLoadSuccessAction = (list: SurewayAPIResponse<ById<IServiceSection>>): IGetServiceSectionsSuccessAction => ({
    type: IGET_SERVICE_SECTIONS_REQUEST.SUCCESS,
    list
});
export interface IGetServiceSectionsLoadFailedAction {
    type: IGET_SERVICE_SECTIONS_REQUEST.FAILED;
    message: string;
}
export const getServiceSectionsLoadFailedAction = (message: string): IGetServiceSectionsLoadFailedAction => ({
    type: IGET_SERVICE_SECTIONS_REQUEST.FAILED,
    message
});
