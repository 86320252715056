import { connect } from "react-redux";
import styled from "styled-components";
import { ReactNode, PureComponent, useState, useCallback } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../redux/server";
import { LAPaperWithPadding } from "../../shared/paper";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IStore, IDispatch } from "../../../redux/reducers";
import { DispatchSubHeader } from "../../header/dispatchSubHeader";
import { YesOrNo, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import PageSpacing from "../../shared/pageSpacing";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { ISoctReport, ISoctReportRequest } from "../../../redux/field/soct/getSoctReport/getSoctReportConstants";
import { updateSoctReport } from "../../../redux/field/soct/updateSoctReport/updateSoctReportAccessor";
import { getSoctReport } from "../../../redux/field/soct/getSoctReport/getSoctReportAccessor";
import { getSoctReportLoadAction } from "../../../redux/field/soct/getSoctReport/getSoctReportActions";
import { IUpdateSoctReportRequest } from "../../../redux/field/soct/updateSoctReport/updateSoctReportConstants";
import { updateSoctReportLoadAction } from "../../../redux/field/soct/updateSoctReport/updateSoctReportActions";
import DataGrid, { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Lookup, Scrolling,
    SearchPanel, StateStoring } from "devextreme-react/data-grid";
import { LAButton } from "../../shared/buttons";
import { onExporting } from "../../shared/devExtreme";
import React from "react";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, SOCTNavbarMenu } from "../../shared/navbars";
import { ROUTE } from "../../routes";

interface ISoctReportStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    updateStatus: Server<SurewayAPIResponse<string>>;
    getSoctReportStatus: Server<SurewayAPIResponse<ById<ISoctReport>>>;
};

interface ISoctReportDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    updateRequest: (data: IUpdateSoctReportRequest) => unknown;
    getSoctReportRequest: (data: ISoctReportRequest) => unknown;
};


interface ISoctReportOwnProps {

};

interface ISoctReportState {
    editMode: boolean;
};

const SoctReportStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 2.5%;
        top: 3%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

const storageKey = "soct-report-storage-key";

type ISoctReportProps = RouteComponentProps
    & ISoctReportStoreProps
    & ISoctReportDispatchProps
    & ISoctReportOwnProps;

class SoctReport extends PureComponent<ISoctReportProps, ISoctReportState> {

    public constructor(props: ISoctReportProps) {
        super(props);
        this.state = {
            editMode: false,
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISoctReportProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { editMode } = this.state;
        const { getSoctReportStatus, updateStatus } = this.props;
        // const getRole = pageAccessCheck(getToken, "dispatch");
        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);
        const data = hasPayload(getSoctReportStatus) ? Object.values(getSoctReportStatus.payload.response) : [];
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");

        return (
            <PageSpacing title="SOCT" description="SOCT - REPORT" fixedSpaceOnSmallerScreens={true}>
                {/* {(getRole !== NotApplicable) && <> */}
                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                <SoctReportStyles>

                    <LAGrid>
                        <LAGridItem xs={12} className="text-center">
                            <h2 className="text-center">REPORT CONFIG</h2>
                            <hr />

                            {getSoctReportStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Report..." />}
                            {getSoctReportStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Report..." />}
                        </LAGridItem>

                        {getSoctReportStatus.kind === STATUS_ENUM.SUCCEEDED && <>

                            <LAGridItem xs={12}>
                                <SoctReportDevExtreme
                                    data={data}
                                    editMode={editMode}
                                    onEditStart={onEditStart}
                                    onEditCancel={onEditCancel}
                                    onSaveClick={this.onSaveClick}
                                    getRefreshDataCall={this.getDataForTable}
                                />
                            </LAGridItem>
                        </>}

                        <Prompt when={editMode} message="You have unsaved changes ?" />
                        <RequestStatus requestStatus={updateStatus.kind} />
                    </LAGrid>
                </SoctReportStyles>
                {/* </>} */}
            </PageSpacing>
        );
    }

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                if (isNotLoaded(this.props.getSoctReportStatus)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.UNAUTHORIZED,
                    search: getTokenFromUrl(false)
                });
            }
        };
    };

    private onSaveClick = (record: any): void => {
        const { getToken } = this.props;

        if (hasPayload(getToken) && record.changes[0] !== undefined) {

            const { data } = record.changes[0];

            let request: { ID: number, Report: string } = {
                ID: Number(data.id),
                Report: data.report
            };

            this.props.updateRequest({
                request,
                token: getToken.payload.response.token
            });

            this.updateEditMode(false);
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSoctReportRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISoctReportStoreProps => ({
    getToken: getToken(state),
    updateStatus: updateSoctReport(state),
    getSoctReportStatus: getSoctReport(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISoctReportDispatchProps => ({
    getSoctReportRequest: (data: ISoctReportRequest) => dispatch(getSoctReportLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    updateRequest: (request: IUpdateSoctReportRequest) => dispatch(updateSoctReportLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(SoctReport);


interface ISoctReportDevExtreme {
    data: any;
    editMode: boolean;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
};

const SoctReportDevExtreme: React.FC<ISoctReportDevExtreme> = React.memo((props: ISoctReportDevExtreme) => {

    const onExportClick = (e: any): void => onExporting(e, "Soct-Report");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearSocts = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

        }
    };

    const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };

    return (
        <LAGrid>

            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearSocts}
                    disabled={undefined}
                />
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    showBorders={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    syncLookupFilterValues={true}
                    columnHidingEnabled={true}
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                    filterSyncEnabled={false}
                >

                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={false} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <StateStoring

                        storageKey={storageKey}
                        enabled={true}
                    />
                    <SearchPanel
                        visible={true}
                        width={300}
                        placeholder="Search..."
                        // text={searchValue}
                        // onTextChange={handleValueChange}
                    />
                    <StateStoring
                        type="custom"
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                        enabled={true}
                    />
                    <Scrolling columnRenderingMode="virtual" />
                    <Editing
                        allowUpdating={true}
                        allowAdding={true}
                        allowDeleting={false}
                        mode="row"
                    />

                    <ColumnChooser
                        enabled={true}
                        mode="select"
                        height={window.innerHeight - 100}
                    />
                    <Column dataField="unit_No" caption="Unit #" type="string" allowEditing={false} />
                    <Column dataField="location" caption="Location" type="string" allowEditing={false} />

                    <Column dataField="report" caption="Report" allowEditing={true}>
                        <Lookup displayExpr="name" valueExpr="name" dataSource={YesOrNo} />
                    </Column>

                </DataGrid>
            </LAGridItem>
        </LAGrid>
    )
});