import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FILTER_REQUEST, IFilter } from "./getFilterConstants";
import { IGetFilterLoadAction, IGetFilterLoadFailedAction, IGetFilterSuccessAction, getFilterLoadFailedAction, getFilterLoadSuccessAction } from "./getFilterActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getFilterEpic: Epic = (
    action$: ActionsObservable<IGetFilterLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFilterSuccessAction | IGetFilterLoadFailedAction> =>
    action$.ofType(IGET_FILTER_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IFilter>>>(
                    END_POINTS.FIELD.GET_FILTER_LIST,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IFilter>>): IGetFilterSuccessAction => {
                            return getFilterLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFilterLoadFailedAction(response.message)))
                    )
            )
        );