import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_ATTACHMENT_MOVES_REQUEST {
    REQUEST = "getAttachmentMoves/GET_ATTACHMENT_MOVES_REQUEST",
    SUCCESS = "getAttachmentMoves/GET_ATTACHMENT_MOVES_SUCCESS",
    FAILED = "getAttachmentMoves/GET_ATTACHMENT_MOVES_FAILED"
};

export interface IGetAttachmentMoveRequest extends ISurewayTokenRequestBody {

};

export interface IAttachmentMove {
    id: number;
    attachment_ID: number;
    type_ID: number;
    coupler_Design_ID: number;
    style_ID: number;
    radio?: string;
    custom?: string;
    requested_By?: string;
    from_Sub_Location_ID?: number;
    to_Sub_Location_ID: number;
    // unit_ID: number;
    // unit_Number?: string;
    move_Request_ID: number;
    attached_Unit_ID: number;
    status: string;
    complete_By: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    attachment_No: string;
    from_Location: string;
    to_Location: string;
    attached_Unit_No: string;
    type: string;
    style: string;
    coupler_Design: string;
    previously_Moved: string;
    move_Type: string;
    comments: string;
};