import { ById } from "../../../react/shared/publicInterfaces";

export enum IGET_MOVE_REQUESTS_REQUEST {
    REQUEST = "getMoveRequests/GET_MOVE_REQUESTS_REQUEST",
    SUCCESS = "getMoveRequests/GET_MOVE_REQUESTS_SUCCESS",
    FAILED = "getMoveRequests/GET_MOVE_REQUESTS_FAILED"
};

export interface IMoveRequestsRequest {
    token: string;
    request?: IMoveRequestsRequestBody
};

export interface IMoveRequestsRequestBody {
    PartsDispatch?: string;
    Type?: string;
};

export interface IMoveRequests {
    sort_list: number[];
    response_time: string;
    move_requests: ById<IMoveRequest>;
};

export interface IMoveRequest {
    id: number;
    status: string;
    driver_ID: number;
    equipment_ID: number;
    unit_Number?: string;
    make: string;
    model: string;
    pilot: number;
    pilot_Ids: string;
    completed_Date: string;
    to_Location_Info: string;
    from_Location_Info: string;
    dispatch_Notes: string;
    trailer_Config: string | null;
    dispatch_Priority: number | null;
    trailer_Config_ID: number | null;
    ready_Any_Time? : string;
    site_Status? : string;
    move_Type: string;
    eT_Second_Trip: string;
    mob_Demob: string;
    from_Demob: string | null;
    to_Mob: string | null;
    repair_Notes: string | null;
    initial_From_Demob: string | null;
    initial_To_Mob: string | null;
};