import { ActionsObservable, Epic } from "redux-observable";
import { catchError, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs/internal/Observable";
import { LOCAL_STORAGE_TOKEN, getStoredToken } from "./localStorageTokenConstants";
import { ILocalStorageTokenRequestAction, localStorageTokenSuccessAction, localStorageTokenFailedAction } from "./localStorageTokenActions";
import { IAction } from "../../../reducers";

export const localStorageTokenEpic: Epic = (
    action$: ActionsObservable<ILocalStorageTokenRequestAction>
): Observable<IAction> =>
    action$.ofType(LOCAL_STORAGE_TOKEN.REQUEST)
        .pipe(
            mergeMap(() => {
                const data = getStoredToken();
                if(data === null){
                    return ActionsObservable.of(localStorageTokenFailedAction());
                } else {
                    return ActionsObservable.of(localStorageTokenSuccessAction(data));
                };
            }),
            catchError(() => {
                return ActionsObservable.of(localStorageTokenFailedAction());
            })
        );
