import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LABluePaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { BLUE_COLOR, DARK_RED_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, deleteText, fileToBase64Image, getDate, getTokenFromUrl, pageAccessCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAGreenButton, LAIconButton, LAOrangeButton, LASaveAndCancelButton, LASecondaryButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import DatePicker, { DateObject } from "react-multi-date-picker";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { FormControlLabel, FormHelperText, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { ILogs, IWeatherDetails, IWorkingSiteLog, IWorkingSiteLogPicture, IWorkingSiteLogRequest } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsConstants";
import { IAddWorkingSiteLogRequest } from "../../../redux/field/workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogConstants";
import { IUpdateWorkingSiteLogRequest } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogConstants";
import { getWorkingSiteLogs } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsAccessor";
import { addWorkingSiteLog } from "../../../redux/field/workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogAccessor";
import { updateWorkingSiteLog } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogAccessor";
import { getWorkingSiteLogsLoadAction } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsActions";
import { addWorkingSiteLogLoadAction } from "../../../redux/field/workingSiteLogs/addWorkingSiteLog/addWorkingSiteLogActions";
import { updateWorkingSiteLogLoadAction } from "../../../redux/field/workingSiteLogs/updateWorkingSiteLog/updateWorkingSiteLogActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { IFieldLookup, IFieldLookupRequest, IForeman } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import LADatePicker from "../../shared/datePicker";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { AddIcon, ArrowLeftIcon, CleardayIcon, CloudyIcon, DeleteIcon, PartlyCloudyIcon, RainyIcon, SnowyIcon } from "../../shared/icons";
import { LADropZone } from "../../shared/dropZone";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import { v4 as uuidv4 } from "uuid";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import LALinkButton from "../../shared/linkButton";
import { IGetWorkingSiteLogVersionHistory, IGetWorkingSiteLogVersionHistoryRequest } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryConstants";
import { getWorkingSiteLogVersionHistory } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryAccessor";
import { getWorkingSiteLogVersionHistoryLoadAction } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryActions";
import { WorkingSiteLogVersionHistoryPopup } from "./versionHistory";
import { IGetWorkingSiteLogByIdRequest } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogById/getWorkingSiteLogByIdConstants";
import { getWorkingSiteLogByIdLoadAction } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogById/getWorkingSiteLogByIdActions";
import { getWorkingSiteLogById } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogById/getWorkingSiteLogByIdAccessor";
import { IGetWSSLogsRequest, IWSSLogs } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsConstants";
import { getWSSLogs } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsAccessor";
import { getWSSLogsLoadAction } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsActions";
import { LACheckBox } from "../../shared/checkBox";


const RequiredFields: string[] = ["wsS_ID","security"];
const StatusList: IIdName[] = [{ id: 0, name: "Active" }, { id: 1, name: "Rained/Snowed out" }, { id: 2, name: "Completed" },{ id: 3, name: "Not Working" },{ id: 4, name: "Start Up" },{ id: 5, name: "Weather Standby" }];
const SecurityList: IIdName[] = [{ id: 0, name: "Manned Sureway" }, { id: 1, name: "Remote Tower" }, { id: 2, name: "NA" }];

interface IAddUpdateWorkingSiteLogComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addWorkingSiteLogStatus: Server<SurewayAPIResponse<any>>;
    updateWorkingSiteLogStatus: Server<SurewayAPIResponse<string>>;
    // workingSiteLogs: Server<SurewayAPIResponse<ById<IWorkingSiteLog>>>;
    getWSSLogs: Server<SurewayAPIResponse<ById<IWSSLogs>>>;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>>;
    getWorkingSiteLogByID: Server<SurewayAPIResponse<IWorkingSiteLog>>;
};

interface IAddUpdateWorkingSiteLogComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    // getWorkingSiteLogsRequest: (data: IWorkingSiteLogRequest) => unknown;
    addWorkingSiteLogRequest: (data: IAddWorkingSiteLogRequest) => unknown;
    getWSSLogsRequest: (data: IGetWSSLogsRequest) => unknown;
    updateWorkingSiteLogRequest: (data: IUpdateWorkingSiteLogRequest) => unknown;
    getVersionHistoryRequest: (data: IGetWorkingSiteLogVersionHistoryRequest) => unknown;
    getWorkingSiteLogByIDRequest: (data: IGetWorkingSiteLogByIdRequest) => unknown;
};

interface IAddUpdateWorkingSiteLogOwnProps {
    id?: string;
};

interface IAddUpdateWorkingSiteLogComponentState {
    data: IWorkingSiteLog;
    detailsExpand: boolean;
    weatherExpand: boolean;
    logsExpand: boolean;
    safetyExpand: boolean;
    collapseAll: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
    popupImgs: IWorkingSiteLogPicture[];
    versionHistoryPopup: boolean;
};

const AddUpdateWorkingSiteLogStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .weather-container {
        text-align: center;
        // background-color: red;
        color: white;
    }

    .weather-row {
        display: flex;
        justify-content: space-around;
    }

    .temp-text {
        font-size: 2em;
        color: black;
    }

    .weather-icon {
        width: 50px;
        height: 50px;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
    
`;

type IAddUpdateWorkingSiteLogComponentProps =
    RouteComponentProps
    & IAddUpdateWorkingSiteLogOwnProps
    & IAddUpdateWorkingSiteLogComponentStoreProps
    & IAddUpdateWorkingSiteLogComponentDispatchProps;

class AddUpdateWorkingSiteLog extends PureComponent<IAddUpdateWorkingSiteLogComponentProps, IAddUpdateWorkingSiteLogComponentState> {

    public constructor(props: IAddUpdateWorkingSiteLogComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                date: new Date().toString(),
                foreman: "",
                wsS_ID: 0,
                status: "Active",
                security: "",
                job_No: "",
                service_Required: "No",
                prime_Contractor: "Sureway",
                saturday_Working: "No",
                sunday_Working: "No",
                radio: "",
                start_Time_Val: "",
                end_Time_Val: "",
                work_Description: "",
                scheduled_Days_Off: "",
                safety_Observations_Comments:"",
                safety_Admin_Notes:"",
                safety_Addressed:"No",
                safety_Pictures: [],
                logs: [],
                hazard_Assessment: "No",
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            detailsExpand: true,
            weatherExpand: true,
            logsExpand: true,
            safetyExpand: true,
            collapseAll: true,
            errors: {},
            serverError: "",
            popupImgs: [],
            versionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.getData();
        this.getWSSLogByIDData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateWorkingSiteLogComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addWorkingSiteLogStatus !== prevProps.addWorkingSiteLogStatus) {

                if (this.props.addWorkingSiteLogStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addWorkingSiteLogStatus.message });

                if (hasPayload(this.props.addWorkingSiteLogStatus) && this.props.addWorkingSiteLogStatus.kind === STATUS_ENUM.SUCCEEDED){
                if (window.location.href.includes("token")) {
                    window.parent.postMessage("goback");
                }
                else {
                    const response = this.props.addWorkingSiteLogStatus.payload.response;
                    // console.log(response);
                    if(this.state.data.prime_Contractor === "Ketek")
                    {
                        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfSeE2TqsrJkI3odYIKoVqQ3gFOJ4QYGtKpNE1Io5HmOrL8Tw/viewform','_blank');
                    }
                    else if(response && response.id && response.id > 0) {
                        callRouteWithQueryString({
                            route: this.props,
                            search: { id: "0", logId: response.id.toString(), form_ID: "19", },
                            pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
                        });
                    }
                    else{
                        this.handleCancel();
                    }
                }
            }
            };

            if (this.props.updateWorkingSiteLogStatus !== prevProps.updateWorkingSiteLogStatus) {

                if (this.props.updateWorkingSiteLogStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateWorkingSiteLogStatus.message });

                if (hasPayload(this.props.updateWorkingSiteLogStatus) && this.props.updateWorkingSiteLogStatus.kind === STATUS_ENUM.SUCCEEDED)
                {
                if (window.location.href.includes("token")) {
                    window.parent.postMessage("goback");
                }
                else {
                    this.handleCancel();
                }
            }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, detailsExpand, logsExpand, safetyExpand, weatherExpand, collapseAll, versionHistoryPopup} = this.state;
        // console.log(data)
        const { token, addWorkingSiteLogStatus, updateWorkingSiteLogStatus, getLookup, getVersionHistory, getWorkingSiteLogByID } = this.props;
        const getRole = pageAccessCheck(token, "workingSiteLogs");
        const getITAccess = pageAccessCheck(token, "itAccess");
        const versionHistoryData = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};
        const disabled = (getRole === ReadOnly) ? true : (new Date(data.date).toLocaleDateString('en-US') !== new Date().toLocaleDateString('en-US')) ? true : undefined;
        const safetyAdminAccess = hasPayload(token) ? token.payload.response.safetyAdmin : undefined;
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];
        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : data.status);
        const onSecurity= (event: unknown, value: IIdName): void => this.handleChange("security", value !== null ? value.name : data.security);
        const onForeman = (event: unknown, value: IForeman): void => this.handleChange("foreman", value !== null ? value.upn : data.foreman);
        const onWSS = (value: string): void => this.handleWSSChange(value);
        const onHazardAssessment = (checked: boolean): void => this.handleChange("hazard_Assessment", checked ? "Yes" : "No");

        return (
            <AddUpdateWorkingSiteLogStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                            <LALinkButton onClick={() => this.handleVersionHistory(data.id)} label="Version History"></LALinkButton>
                        </LAGridItem>
                        }

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>WORKING SITE LOG DETAILS</h2>
                            <hr />
                        </LAGridItem>

                        {data.id === 0 ? <LAGridItem xs={12} sm={6} md={3}>
                            <DevExtremePopupLookup
                                data={wssList}
                                id="wss-search"
                                placeHolder="Working Site"
                                disabled={disabled}
                                displayExp="site"
                                name="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                currentVal={data.wsS_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                    { name: "site_Address", caption: "Site Address", type: "string" },
                                    { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                ]}
                            />
                        </LAGridItem> :

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                name="wsS_ID"
                                label="Working Site"
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                onChange={undefinedFunction}
                                value={data.site ?? ""}
                            />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                name="date"
                                label="Date"
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                onChange={undefinedFunction}
                                value={getDate(false, data.date)}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={foremans}
                                autoHighlight={true}
                                onChange={onForeman}
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                dropDownPlaceHolder="Foreman"
                                selectionRemove={undefinedFunction}
                                value={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                                defaultValue={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={StatusList}
                                disabled={disabled}
                                onChange={onStatus}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder="Status"
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                value={data.status ? StatusList.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? StatusList.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                name="job_No"
                                label="Job No"
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                onChange={undefinedFunction}
                                value={data.job_No ?? ""}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={SecurityList}
                                disabled={disabled}
                                onChange={onSecurity}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder="Security"
                                errorText={errors["security"] ? errors["security"].message : undefined}
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                value={data.security ? SecurityList.find(q => q.name === data.security) : null}
                                defaultValue={data.security ? SecurityList.find(q => q.name === data.security) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={4}
                                name="work_Description"
                                label="Work Description"
                                fullWidth={true}
                                disabled={disabled}
                                variant="outlined"
                                onChange={this.handleChange}
                                value={data.work_Description ?? ""}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            {data.id !== 0 && <><LAButton
                                className="collapse-btn"
                                label={collapseAll ? "Collapse All" : "Expand All" }
                                onClick={this.handleCollapseAll}
                                // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                            <br /><br /></>
                            }
                            <LAExpansionPanel color={BLUE_COLOR} textColor={WHITE_COLOR} label="Details" expanded={detailsExpand} onClick={() => this.handleExpand("detailsExpand")}>
                                <LAGrid spacing={1}>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        name="equip_On_Site"
                                        label="Equipment on site"
                                        fullWidth={true}
                                        disabled={true}
                                        variant="outlined"
                                        onChange={undefinedFunction}
                                        value={data.equip_On_Site ?? ""}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        name="equip_Working"
                                        label="Equipment Working"
                                        fullWidth={true}
                                        disabled={disabled}
                                        variant="outlined"
                                        type="number"
                                        value={data.equip_Working ?? ""}
                                        onChange={this.handleNumberChange}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        name="emp_Working"
                                        label="EMP Working"
                                        fullWidth={true}
                                        disabled={disabled}
                                        variant="outlined"
                                        type="number"
                                        value={data.emp_Working ?? ""}
                                        onChange={this.handleNumberChange}
                                    />
                                </LAGridItem>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        name="radio"
                                        label="Radio Channel"
                                        fullWidth={true}
                                        disabled={disabled}
                                        variant="outlined"
                                        value={data.radio}
                                        onChange={this.handleChange}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3} className="text-left">
                                    <strong>Start Time</strong>
                                    <br />

                                    <LADatePicker
                                        id="start_Time"
                                        mode="time"
                                        disable={disabled}
                                        value={data.start_Time_Val}
                                        onChange={this.handleStartTime}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3} className="text-left">
                                    <strong>End Time</strong>
                                    <br />

                                    <LADatePicker
                                        id="end_Time"
                                        mode="time"
                                        disable={disabled}
                                        value={data.end_Time_Val}
                                        onChange={this.handleEndTime}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3} className="text-left">
                                    <strong>Schedule Day Offs</strong>
                                    <br />

                                    <DatePicker
                                        multiple
                                        plugins={[
                                            <DatePanel />
                                        ]}
                                        mapDays={({ date }) => {
                                            let dateProps: any = {}
                                            let isWeekend = this.isWeekendOrHoliday(date.toString());
                                            if (isWeekend) dateProps.className = "highlight highlight-red";
                                            return dateProps
                                        }}
                                        disabled={disabled}
                                        placeholder="Select Dates"
                                        onChange={this.onDateChange}
                                        value={data.scheduled_Days_Off.split(",")}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <strong>Service Required</strong>
                                    <RadioGroup id="service_Required" row aria-label="service_Required" name="service_Required" value={data.service_Required} onChange={this.onServiceRequired}>

                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={disabled || data.wsS_ID === 99999}/>
                                        <FormControlLabel value="No" control={<Radio />} label="No" disabled={disabled || data.wsS_ID === 99999}/>
                                    </RadioGroup>
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <strong>Prime Contractor</strong>
                                    <RadioGroup id="prime_Contractor" row aria-label="prime_Contractor" name="prime_Contractor" value={data.prime_Contractor} onChange={this.onPrimeContractorChange}>
                                        <FormControlLabel value="Sureway" control={<Radio />} label="Sureway" disabled={disabled}/>
                                        <FormControlLabel value="Ketek" control={<Radio />} label="Ketek" disabled={disabled}/>
                                        <FormControlLabel value="Lafarge" control={<Radio />} label="Lafarge" disabled={disabled}/>
                                        <FormControlLabel value="Other" control={<Radio />} label="Other" disabled={disabled}/>
                                    </RadioGroup>
                                </LAGridItem>   

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <strong>Working Saturday</strong>
                                    <RadioGroup id="saturday_Working" row aria-label="saturday_Working" name="saturday_Working" value={data.saturday_Working} onChange={this.onSatWorking}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={disabled || data.wsS_ID === 99999}/>
                                        <FormControlLabel value="No" control={<Radio />} label="No" disabled={disabled || data.wsS_ID === 99999}/>
                                    </RadioGroup>
                                </LAGridItem>   

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <strong>Working Sunday</strong>
                                    <RadioGroup id="sunday_Working" row aria-label="sunday_Working" name="sunday_Working" value={data.sunday_Working} onChange={this.onSunWorking}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled={disabled || data.wsS_ID === 99999}/>
                                        <FormControlLabel value="No" control={<Radio />} label="No" disabled={disabled || data.wsS_ID === 99999}/>
                                    </RadioGroup>
                                </LAGridItem>                      
                                
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        {/* <LAGridItem xs={12}>
                            <LAExpansionPanel color={GREEN_COLOR} textColor={WHITE_COLOR} label="Logs" expanded={logsExpand} onClick={() => this.handleExpand("logsExpand")}>
                                <TableContainer component={LAPaperWithLessPadding}>
                                    <LAGrid>
                                        <LAGridItem xs={5}>
                                            <LAIconButton
                                                label="Add New"
                                                disabled={disabled}
                                                icon={<AddIcon />}
                                                onClick={this.handleLogsTableAdd} 
                                            /> 
                                        </LAGridItem> 
                                    </LAGrid>

                                        <Table size="small" aria-label="logs table">
                                            <TableHead className="header-row">
                                                <TableRow>
                                                    <TableCell className="title">Name</TableCell>
                                                    <TableCell className="title">Description</TableCell>
                                                    <TableCell className="title">Images</TableCell>
                                                    <TableCell className="title"></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            {data.logs && data.logs.length > 0 &&
                                                data.logs.map((x: ILogs, index: number) => {
                                                    const onNameChange = (e: unknown, value: any) => this.handleLogsTableChange("name", (value !== null) ? value : "", index);
                                                    const onDescriptionChange = (e: unknown, value: any) => this.handleLogsTableChange("description", (value !== null) ? value : "", index);
                                                    const onRowDelete = (e: unknown) => this.handleLogDelete(index)
                                                    
                                                    return <TableBody key={index} className={index % 2 ? "" : "table-row"}>
                                                        <TableRow>
                                                            <TableCell>
                                                                <LATextField
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={1}
                                                                    name="name"
                                                                    label="Name"
                                                                    disabled={disabled}
                                                                    value={x.name ?? ""}
                                                                    errorText={!x.name ? "required" : ""}
                                                                    onChange={onNameChange}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <LATextField
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={1}
                                                                    name="description"
                                                                    label="Description"
                                                                    disabled={disabled}
                                                                    value={x.description ?? ""}
                                                                    // errorText={!x.description ? "required" : ""}
                                                                    onChange={onDescriptionChange}
                                                                    type="text"
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e, index)} />
                                                                <br />
                                                                <LAGrid>
                                                                {x.pictures && x.pictures
                                                                    .map((q:IWorkingSiteLogPicture, ind: number) => {
                                                                        const onImgDelete = (): void => this.onImgDelete(q.name, ind, index);
                                                                        const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                                        // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                                        const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind, index);
        
                                                                        return (
                                                                            <LAGridItem xs={4} key={ind} className="text-center">
                                                                                <LAPaperWithLessPadding>
                                                                                    <LAThumbnailWithLink
                                                                                        alt={q.name}
                                                                                        key={ind}
                                                                                        url={base64}
                                                                                        id={(ind + "_").toString()}
                                                                                    />
        
                                                                                    <LATextField
                                                                                        className="mt-2"
                                                                                        label="Description"
                                                                                        fullWidth={true}
                                                                                        variant="outlined"
                                                                                        name="description"
                                                                                        disabled={disabled}
                                                                                        value={q.description}
                                                                                        onChange={onImageName}
                                                                                        errorText={
                                                                                            q.name.length === 0 ?
                                                                                                FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                                : undefined
                                                                                        }
                                                                                    />
        
                                                                                    {q.timestamp && 
                                                                                        <strong>{new Date(q.timestamp).toLocaleString()}</strong>
                                                                                    } 
        
                                                                                    <LAIconButton
                                                                                        key={ind}
                                                                                        label="Delete"
                                                                                        disabled={disabled }
                                                                                        onClick={onImgDelete}
                                                                                        icon={<DeleteIcon />}
                                                                                    />
                                                                                </LAPaperWithLessPadding>
                                                                            </LAGridItem>
                                                                        );
        
                                                                    })}
                                                                </LAGrid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <LAIconButton
                                                                    label="Delete"
                                                                    icon={<DeleteIcon />}
                                                                    onClick={onRowDelete} 
                                                                    disabled={disabled || x.id ? true : undefined}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                })
                                            }
                                            
                                        </Table>
                                </TableContainer>
                            </LAExpansionPanel>
                        </LAGridItem> */}

                        {data.id > 0 && <LAGridItem xs={12}>
                            <LAExpansionPanel color={DARK_RED_COLOR} textColor={WHITE_COLOR} label="Safety" expanded={safetyExpand} onClick={() => this.handleExpand("safetyExpand")}>
                                <LAGrid className="text-center">
                                    {data.id > 0 && <LAGridItem xs={12} className="text-center">
                                        {data.hazard_Assessment_Form_ID && data.hazard_Assessment_Form_ID > 0 ? 
                                            <LAGreenButton
                                                label="View/Edit Pre-Job Safety Inspection"
                                                onClick={() => this.handleHazardAssessmentClick(data.hazard_Assessment_Form_ID)}
                                                disabled={undefined}
                                                // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                            />
                                            :
                                            <LAOrangeButton
                                                label="Complete Pre-Job Safety Inspection"
                                                onClick={this.handleHazardAssessmentClick}
                                                disabled={disabled}
                                                // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                            /> 
                                        }
                                    </LAGridItem>}
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>}

                        {/* {!(getITAccess === true) && <LAGridItem xs={12}>
                            <LAExpansionPanel color={DARK_RED_COLOR} textColor={WHITE_COLOR} label="Safety" expanded={safetyExpand} onClick={() => this.handleExpand("safetyExpand")}>
                                <LAGrid className="text-center">
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextArea
                                            minRows={3}
                                            rowsMax={4}
                                            name="safety_Observations_Comments"
                                            label="Site Safety Observations comments"
                                            fullWidth={true}
                                            disabled={disabled}
                                            errorText={
                                                data.safety_Observations_Comments?.length === 0 && data.safety_Pictures && data.safety_Pictures.length > 0 ?
                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                    : undefined
                                            }
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.safety_Observations_Comments ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={9}>
                                        <strong className="text-center">Upload Safety Images</strong>
                                        <LADropZone onFileDrop={(e: any) => this.handleSafetyImageUpload(e)} />
                                        <br />
                                        <LAGrid>
                                            {data.safety_Pictures && data.safety_Pictures
                                                .map((q:IWorkingSiteLogPicture, ind: number) => {
                                                    const onImgDelete = (): void => this.onSafetyImgDelete(q.name, ind);
                                                    const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                    // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                    const onImageName = (name: string, value: string): void => this.handleSafetyImageName(name ? name : "", value, ind);

                                                    return (
                                                        <LAGridItem xs={3} key={ind} className="text-center">
                                                            <LAPaperWithLessPadding>
                                                                <LAThumbnailWithLink
                                                                    alt={q.name}
                                                                    key={ind}
                                                                    url={base64}
                                                                    id={(ind + "_").toString()}
                                                                />

                                                                <LATextField
                                                                    className="mt-2"
                                                                    label="Description"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    name="description"
                                                                    disabled={disabled}
                                                                    value={q.description}
                                                                    onChange={onImageName}
                                                                    errorText={
                                                                        q.name.length === 0 ?
                                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                            : undefined
                                                                    }
                                                                />

                                                                {q.timestamp && 
                                                                    <strong>{new Date(q.timestamp).toLocaleString()}</strong>
                                                                } 

                                                                <LAIconButton
                                                                    key={ind}
                                                                    label="Delete"
                                                                    disabled={disabled }
                                                                    onClick={onImgDelete}
                                                                    icon={<DeleteIcon />}
                                                                />
                                                            </LAPaperWithLessPadding>
                                                        </LAGridItem>
                                                    );

                                                })}
                                        </LAGrid>
                                    </LAGridItem>

                                    {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextArea
                                            minRows={3}
                                            rowsMax={4}
                                            name="safety_Admin_Notes"
                                            label="Safety Admin Notes"
                                            fullWidth={true}
                                            disabled={disabled || !safetyAdminAccess}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.safety_Admin_Notes ?? ""}
                                        />
                                    </LAGridItem>}
                                    {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                        <LACheckBox
                                            name="safety_Addressed"
                                            label="Safety Addressed"
                                            disabled={disabled || !safetyAdminAccess ? true : undefined}
                                            onChange={this.handlesafetyAddressed}
                                            value={(data.safety_Addressed === "Yes") ? true : false}
                                        />
                                    </LAGridItem>}
                                    
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>} */}

                        {data.id > 0 && <LAGridItem xs={12}>
                            <LAExpansionPanel color={ORANGE_COLOR} textColor={WHITE_COLOR} label="Weather" expanded={weatherExpand} onClick={() => this.handleExpand("weatherExpand")}>
                                <LAGrid className="text-center">
                                    <LAGridItem xs={12} className="weather-row">
                                        {data.weather && data.weather.length > 0 &&
                                            data.weather.map((x: IWeatherDetails, index: number) => {
                                                return <LAGridItem xs={4}>
                                                    <h3>{x.time === "16" ? "4" : x.time} {x.time ==="6" ? "AM" : "PM"}</h3>
                                                    <p>
                                                        <strong className="temp-text">{x.temperature}°c</strong>
                                                        {x.weather === "Clear" && <CleardayIcon width={30} height={28}/>}
                                                        {x.weather === "Party Cloud" && <PartlyCloudyIcon width={50} height={30}/>}
                                                        {x.weather === "Clouds" && <CloudyIcon width={50} height={30}/>}
                                                        {x.weather === "Snow" && <SnowyIcon width={50} height={40}/>}
                                                        {x.weather === "Rain" && <RainyIcon width={50} height={40}/>}
                                                    </p>
                                                    <strong>{x.weather}</strong>
                                                    <p>Wind: {x.wind} kmph {x.precipitation !== null && `| Precipitation: ${x.precipitation}mm`} | Humidity: {x.humidity}%</p>
                                                </LAGridItem>
                                            })
                                        }
                                    </LAGridItem>
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>}

                        <hr />
                        {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                        {data.id === 0 && <LAGridItem xs={12}>
                            <LACheckBox
                                name="hazard_Assessment"
                                label="Save and proceed with Pre-Job Safety Inspection"
                                disabled={disabled}
                                onChange={onHazardAssessment}
                                value={(data.hazard_Assessment === "Yes") ? true : false}
                            />
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled ? true: data.logs.some(obj => !obj.name) ? true : (data.safety_Observations_Comments?.length === 0 && data.safety_Pictures && data.safety_Pictures.length > 0) ? true : undefined}
                            />
                        </LAGridItem>

                    </LAGrid>}

                    <WorkingSiteLogVersionHistoryPopup
                            data={versionHistoryData}
                            open={versionHistoryPopup}
                            status={getVersionHistory.kind}
                            onCancel={this.handleVersionHistoryClose}
                        />

                <RequestStatus requestStatus={addWorkingSiteLogStatus.kind} successMessage="Working Site Log has been added successfully" />
                <RequestStatus requestStatus={updateWorkingSiteLogStatus.kind} successMessage="Working Site Log has been updated successfully" />
            </AddUpdateWorkingSiteLogStyles>
        );
    }

    private handleCollapseAll = () : void => { 
        if(this.state.collapseAll) {
            this.setState({
                ...this.state,
                collapseAll: false,
                detailsExpand: false,
                weatherExpand: false,
                logsExpand: false,
                safetyExpand: false,
            });
        }
        else {
            this.setState({
                ...this.state,
                collapseAll: true,
                detailsExpand: true,
                weatherExpand: true,
                logsExpand: true,
                safetyExpand: true,
            });
        }
    }

    private handleStartTime = (time: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                start_Time_Val: time
            }
        });
    };

    private handleEndTime = (time: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                end_Time_Val: time
            }
        });
    };

    // private handleSafetyImageUpload = async (event: any): Promise<void> => {

    //     const files = event;
    //     const popupImgs = this.state.data.safety_Pictures ? [...this.state.data.safety_Pictures] : [];
    //     // const popupImgs = [...this.state.popupImgs];

    //     if (files !== null) {

    //         await Promise.all(Object.values(files).map(async (x: any) => {
    //             if(!x.type.includes("image")) {
    //                 alert("Please select only an image")
    //             } 
    //             else {  
    //                 const imgId = uuidv4();
    //                 let isDuplicateDescription = false;
    //                 // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
    //                 let description = x.name;
    //                 const base64String = await fileToBase64Image(x);

    //                 popupImgs.map((img, i) => {
    //                     // console.log(img.description, description)
    //                     if(img.description === description.split(".")[0]) {
    //                         isDuplicateDescription = true;
    //                     }
    //                     return isDuplicateDescription
    //                 })
    //                 isDuplicateDescription ?
    //                     popupImgs.push({
    //                         name: imgId + "." + description.split(".")[1],
    //                         description: description.split(".")[0] + "_1",
    //                         base64String,
    //                         timestamp: new Date().toLocaleString()
    //                     }) : 
    //                     popupImgs.push({
    //                         name: imgId + "." + description.split(".")[1],
    //                         description: description.split(".")[0],
    //                         base64String,
    //                         timestamp: new Date().toLocaleString()
    //                     });

    //                 this.setState({
    //                     ...this.state,
    //                     data: {
    //                         ...this.state.data,
    //                         safety_Pictures: popupImgs
    //                     }
    //                 });
                
    //             }
    //         }));
    //     }
    // };

    // private onSafetyImgDelete = (name: string, imgIndex: number): void => {
    //     const selection = window.confirm(deleteText);

    //     if (selection) {
    //         const popupImgs = this.state.data.safety_Pictures ? [...this.state.data.safety_Pictures] : [];
    //         popupImgs.splice(imgIndex, 1);
            
    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...this.state.data,
    //                 safety_Pictures: popupImgs
    //             }
    //         });
    //     }
    // };

    // private handleSafetyImageName = (name: string, value: string, imgIndex: number): void => {
    //     const popupImgs = this.state.data.safety_Pictures ? [...this.state.data.safety_Pictures] : [];

    //     const picture = { ...popupImgs[imgIndex], [name]: value };
    //     popupImgs[imgIndex] = picture;

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             safety_Pictures: popupImgs
    //         }
    //     });
    // };

    // private handleImageUpload = async (event: any, logIndex: number): Promise<void> => {

    //     const files = event;
    //     const iS: ILogs[] = [...this.state.data.logs];
    //     let record: ILogs = { ...iS[logIndex] };
    //     const popupImgs = [...record.pictures];
    //     // const popupImgs = [...this.state.popupImgs];

    //     if (files !== null) {

    //         await Promise.all(Object.values(files).map(async (x: any) => {
    //             if(!x.type.includes("image")) {
    //                 alert("Please select only an image")
    //             } 
    //             else {  
    //                 const imgId = uuidv4();
    //                 let isDuplicateDescription = false;
    //                 // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
    //                 let description = x.name;
    //                 const base64String = await fileToBase64Image(x);

    //                 popupImgs.map((img, i) => {
    //                     // console.log(img.description, description)
    //                     if(img.description === description.split(".")[0]) {
    //                         isDuplicateDescription = true;
    //                     }
    //                     return isDuplicateDescription
    //                 })
    //                 isDuplicateDescription ?
    //                     popupImgs.push({
    //                         name: imgId + "." + description.split(".")[1],
    //                         description: description.split(".")[0] + "_1",
    //                         base64String,
    //                         timestamp: new Date().toLocaleString()
    //                     }) : 
    //                     popupImgs.push({
    //                         name: imgId + "." + description.split(".")[1],
    //                         description: description.split(".")[0],
    //                         base64String,
    //                         timestamp: new Date().toLocaleString()
    //                     });

    //                 record = {
    //                     ...record,
    //                     pictures: popupImgs
    //                 }

    //                 iS[logIndex] = record;
            
    //                 this.setState({
    //                     ...this.state,
    //                     data: {
    //                         ...this.state.data,
    //                         logs: iS
    //                     }
    //                 });
                
    //             }
    //         }));
    //     }
    // };

    // private onImgDelete = (name: string, imgIndex: number, logIndex: number): void => {
    //     const selection = window.confirm(deleteText);

    //     if (selection) {
    //         const iS: ILogs[] = [...this.state.data.logs];
    //         let record: ILogs = { ...iS[logIndex] };
    //         const popupImgs = [...record.pictures];
    //         popupImgs.splice(imgIndex, 1);
    //         record = {
    //             ...record,
    //             pictures: popupImgs
    //         }
            
    //         iS[logIndex] = record;
            
    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...this.state.data,
    //                 logs: iS
    //             }
    //         });
    //     }
    // };

    // private handleEditImageSave = (src: string): void => {
    //     if (this.state.imageSrc)
    //         this.onImageEdit(src, this.state.imageSrc.idx);

    //     this.handleEditImageCancel();
    // };

    // private handleOpenImage = (src: string, idx: number): void => {
    //     this.setState({ imageSrc: { src, idx } });
    // };

    // private handleEditImageCancel = (): void => {
    //     this.setState({ imageSrc: undefined });
    // };

    // private onImageEdit = (base64String: string, subIndex: number): void => {
    //     const popupImgs = [...this.state.popupImgs];

    //     const picture = { ...popupImgs[subIndex], base64String };
    //     popupImgs[subIndex] = picture;

    //     this.setState({
    //         ...this.state,
    //         popupImgs: [
    //             ...popupImgs
    //         ]
    //     });
    // };

    // private handleImageName = (name: string, value: string, imgIndex: number, logIndex: number): void => {
    //     const iS: ILogs[] = [...this.state.data.logs];
    //     let record: ILogs = { ...iS[logIndex] };
    //     const popupImgs = [...record.pictures];
    //     // const popupImgs = [...this.state.popupImgs];

    //     const picture = { ...popupImgs[imgIndex], [name]: value };
    //     popupImgs[imgIndex] = picture;

    //     record = {
    //         ...record,
    //         pictures: popupImgs
    //     }

    //     iS[logIndex] = record;

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             logs: iS
    //         }
    //     });
    // };

    private onDateChange = async (selectedDates: DateObject[]): Promise<void> => {

        let scheduled_Days_Off: string[] = [];

        await selectedDates.forEach(q => {
            const date = new Date(q.toString());

            scheduled_Days_Off.push(
                (`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`).toString()
            );
        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                scheduled_Days_Off: scheduled_Days_Off.join(",").toString()
            }
        });
    };

    private isWeekendOrHoliday = (date: string): boolean => {
        let weekEnd = new Date(date).getDay() === 6 || new Date(date).getDay() === 0;
        return weekEnd;
    };   

    private onPrimeContractorChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                prime_Contractor: value
            }
        });
    };

    private onSatWorking = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                saturday_Working: value
            }
        });
    };

    private onSunWorking = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                sunday_Working: value
            }
        });
    };

    private onServiceRequired = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                service_Required: value
            }
        });
    };

    private handleVersionHistory = async (id: number): Promise<void> => {
        if (hasPayload(this.props.token)) {
            this.props.getVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    ID: id
                }
            });
        }
        await this.setState({ versionHistoryPopup: true });
    };

    private handleVersionHistoryClose = (): void => {
        this.setState({ versionHistoryPopup: false });
    };

    private handleHazardAssessmentClick = (hazard_Assessment_Form_ID?: number): void => {
        if(hazard_Assessment_Form_ID && hazard_Assessment_Form_ID > 0) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: hazard_Assessment_Form_ID.toString(), logId: this.state.data.id.toString(), form_ID: "19", },
                pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
            });
        } 
        else {
            callRouteWithQueryString({
                route: this.props,
                search: { id: "0", logId: this.state.data.id.toString(), form_ID: "19", },
                pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
            });
        }
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.WORKING_SITE_LOGS.INDEX,
            search: getTokenFromUrl(false)
        });
      
        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleExpand = (name: keyof IAddUpdateWorkingSiteLogComponentState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };


    // private handleLogsTableAdd = () : void => {
    //     const initialLogObject = {
    //         name: "",
    //         description: "",
    //         pictures: []
    //     }
    //     const list = this.state.data.logs !== null ? [...this.state.data.logs] : [];
    //     list.push(initialLogObject);

    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             logs: list
    //         }
    //     });
    // }

    // private handleLogsTableChange = (name: string, value: any, index: number, ) => { 
    //     // console.log(value, index, name);
    //     if (index !== undefined) {
    //         const iS: ILogs[] = [...this.state.data.logs];
    //         if (name === "name") { 
    //             let record: ILogs = { ...iS[index] };
    //             record = {
    //                 ...record,
    //                 [name]: value
    //             }
    //             iS[index] = record;
    //         }
    //         if (name === "description") { 
    //             let record: ILogs = { ...iS[index] };
    //             record = {
    //                 ...record,
    //                 [name]: value
    //             }
    //             iS[index] = record;
    //         }

    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...this.state.data,
    //                 logs: iS
    //             }
    //         });
    //     }
    // }

    // private handleLogDelete = async (index: number) => { 
    //     // console.log(index)
    //     const selection = window.confirm(deleteText);

    //     if(selection) {
    //         const logs: ILogs[] = [...this.state.data.logs];
    //         logs.splice(index, 1);

    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...this.state.data,
    //                 logs: logs
    //             }
    //         });
    //     }
    // }

    private handleSave = async (): Promise<void> => {
        const { id, foreman, wsS_ID, security, start_Time_Val, end_Time_Val, status, equip_On_Site, equip_Working, logs, job_No, safety_Addressed, safety_Pictures, safety_Admin_Notes,
            service_Required, radio, work_Description, safety_Observations_Comments, saturday_Working,sunday_Working, scheduled_Days_Off, emp_Working,prime_Contractor, hazard_Assessment
        } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addWorkingSiteLogRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        Date: new Date().toLocaleDateString('en-US'),
                        Foreman: foreman,
                        WSS_ID: wsS_ID,
                        Status: status,
                        Security: security,
                        Job_No: job_No,
                        Equip_On_Site: equip_On_Site ?? 0,
                        Equip_Working: equip_Working ?? 0,
                        Emp_Working: emp_Working ?? 0,
                        Service_Required: service_Required,
                        Prime_Contractor: prime_Contractor,
                        Radio: radio,
                        Start_Time_Val: start_Time_Val,
                        End_Time_Val: end_Time_Val,
                        Work_Description: work_Description,
                        Safety_Observations_Comments: safety_Observations_Comments,
                        saturday_Working: saturday_Working,
                        sunday_Working: sunday_Working,
                        Scheduled_Days_Off: scheduled_Days_Off,
                        Logs: logs,
                        Safety_Addressed: safety_Addressed,
                        Safety_Admin_Notes: safety_Admin_Notes,
                        Safety_Pictures: safety_Pictures,
                        Hazard_Assessment: hazard_Assessment,
                        Created_By: this.props.token.payload.response.upn,
                    }
                });
            } else {
                this.props.updateWorkingSiteLogRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Date: new Date().toLocaleDateString('en-US'),
                        Foreman: foreman,
                        WSS_ID: wsS_ID,
                        Job_No: job_No,
                        Status: status,
                        Security: security,
                        Equip_On_Site: equip_On_Site ?? 0,
                        Equip_Working: equip_Working ?? 0,
                        Emp_Working: emp_Working ?? 0,
                        Service_Required: service_Required,
                        Prime_Contractor: prime_Contractor,
                        Radio: radio,
                        Start_Time_Val: start_Time_Val,
                        End_Time_Val: end_Time_Val,
                        Work_Description: work_Description,
                        Safety_Observations_Comments: safety_Observations_Comments,
                        saturday_Working: saturday_Working,
                        sunday_Working: sunday_Working,
                        Scheduled_Days_Off: scheduled_Days_Off,
                        Logs: logs,
                        Safety_Addressed: safety_Addressed,
                        Safety_Admin_Notes: safety_Admin_Notes,
                        Safety_Pictures: safety_Pictures,
                        Modified_By: this.props.token.payload.response.upn,
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleWSSChange  = (value: string, init?:boolean): void => {

        if (value.length > 0) {
            const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
            const find = init? Number(value) : wssList.find(x => x.site === value)?.wsS_ID;
            const equipCount = init?wssList.find(x => x.wsS_ID === Number(value))?.equip_On_Site : wssList.find(x => x.site === value)?.equip_On_Site;
            const jobNo = init?wssList.find(x => x.wsS_ID === Number(value))?.job : wssList.find(x => x.site === value)?.job;

            if (hasPayload(this.props.getWSSLogs)) {
                
            const workingLogList = this.props.getWSSLogs.payload.response;
            let equip_Working : number | undefined;
            let emp_Working : number | undefined;
            let service_Required : string | undefined ;
            let radio : string | undefined;
            let start_Time : string | undefined;
            let end_Time : string | undefined;
            let work_Description : string | undefined;
            let safety_Observations_Comments : string | undefined;
            let saturday_Working : string | undefined;
            let sunday_Working : string | undefined;
            let prime_Contractor : string | undefined;
            let scheduled_Days_Off : string | undefined ;
            let security : string | undefined ;
            const x = Object.values(workingLogList).filter(x => x.wsS_ID === find && x.foreman === this.state.data.foreman).sort((a,b) => a.id > b.id ? -1 : 1)[0]
            // console.log(x)
            if(x) {
                security = x.security;
                equip_Working = x.equip_Working;
                emp_Working = x.emp_Working;
                service_Required = x.service_Required;
                radio = x.radio;
                start_Time = x.start_Time_Val;
                end_Time = x.end_Time_Val;
                work_Description = x.work_Description;
                safety_Observations_Comments = x.safety_Observations_Comments;
                // equip_Working = x.equip_Working;
                saturday_Working = x.saturday_Working;
                sunday_Working = x.sunday_Working;
                scheduled_Days_Off = x.scheduled_Days_Off;
                prime_Contractor = x.prime_Contractor;
            }
                

            // );
            if(scheduled_Days_Off !== undefined && scheduled_Days_Off !== null)
            {
                let newSchDates : string[] = [];
                const prevDaysOff = scheduled_Days_Off ? scheduled_Days_Off.split(",") : [];
                // console.log(prevDaysOff.length);
                if(prevDaysOff.length > 0)
                {
                    scheduled_Days_Off = undefined;
                prevDaysOff.map((x, index) => {
                    if(new Date(x) >= new Date())
                    {
                        // console.log(x);
                        newSchDates.push(x);
                        scheduled_Days_Off = newSchDates.toString();
                    }
                });
                
                }
            }

            if(value === "_Vacation/Not_Working") {
                service_Required = "No";
                saturday_Working = "No";
                sunday_Working = "No";
            }
            // console.log(service_Required, saturday_Working, sunday_Working, find);
            
            if (find)
                this.handleChange("wsS_ID", find.toString())                
            if (jobNo)
                this.handleChange("job_No", jobNo.toString())                
            if(equipCount)
                this.handleChange("equip_On_Site", equipCount.toString())
                else
                this.handleChange("equip_On_Site", "")
            if (security)
                this.handleChange("security", security.toString())
                else
                this.handleChange("security", "")
            if (equip_Working)
                this.handleChange("equip_Working", equip_Working.toString())
                else
                this.handleChange("equip_Working", "")
            if (emp_Working)
                this.handleChange("emp_Working", emp_Working.toString())
                else
                this.handleChange("emp_Working", "")
            if (service_Required)
                this.handleChange("service_Required", service_Required.toString())                             
            if (radio)
                this.handleChange("radio", radio.toString())
                else
                this.handleChange("radio", "")
            if (start_Time)
                this.handleChange("start_Time_Val", start_Time.toString())
                else
                this.handleChange("start_Time_Val", "")
            if (end_Time)
                this.handleChange("end_Time_Val", end_Time.toString())
                else
                this.handleChange("end_Time_Val", "")
            if (work_Description)
                this.handleChange("work_Description", work_Description.toString())
                else
                this.handleChange("work_Description", "")
            if (safety_Observations_Comments)
                this.handleChange("safety_Observations_Comments", safety_Observations_Comments.toString())
                else
                this.handleChange("safety_Observations_Comments", "")
            if (equip_Working)
                this.handleChange("equip_Working", equip_Working.toString())
                else
                this.handleChange("equip_Working", "")
            if (saturday_Working)
                this.handleChange("saturday_Working", saturday_Working.toString())
            if (sunday_Working)
                this.handleChange("sunday_Working", sunday_Working.toString())        
            if (prime_Contractor)
                this.handleChange("prime_Contractor", prime_Contractor.toString())           
            if (scheduled_Days_Off)               
                this.handleChange("scheduled_Days_Off", scheduled_Days_Off.toString())
                else
                this.handleChange("scheduled_Days_Off", "")
        }
    }};

    // private handlesafetyAddressed = (checked: boolean): void => {
    //     this.setState({
    //         ...this.state,
    //         data: {
    //             ...this.state.data,
    //             safety_Addressed: checked ? "Yes" : "No"
    //         }
    //     });
    // };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "foreman") 
        { 
            if(this.state.data.wsS_ID > 0) {
                // const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
                if (hasPayload(this.props.getWSSLogs)) {
                
                    const workingLogList = this.props.getWSSLogs.payload.response;
                    const x = Object.values(workingLogList).filter(x => x.wsS_ID === this.state.data.wsS_ID  && x.foreman === value).sort((a,b) => a.id > b.id ? -1 : 1)[0]
                    if(x) {
                        let scheduled_Days_Off = x.scheduled_Days_Off;
                        if(x.scheduled_Days_Off !== undefined && x.scheduled_Days_Off !== null)
                        {
                            let newSchDates : string[] = [];
                            const prevDaysOff = x.scheduled_Days_Off ? x.scheduled_Days_Off.split(",") : [];
                            // console.log(prevDaysOff.length);
                            if(prevDaysOff.length > 0)
                            {
                                scheduled_Days_Off = "";
                                prevDaysOff.map((x, index) => {
                                    if(new Date(x) >= new Date())
                                    {
                                        // console.log(x);
                                        newSchDates.push(x);
                                        scheduled_Days_Off = newSchDates.toString();
                                    }
                                });
                            }
                        }
                        
                        errors = this.errorChecker("security", x.security, errors);

                        this.setState({
                            ...this.state,
                            data: {
                               ...this.state.data,
                                [name]: value,
                                security: x.security,
                                equip_On_Site: x.equip_On_Site,
                                equip_Working: x.equip_Working,
                                emp_Working: x.emp_Working,
                                radio: x.radio,
                                start_Time_Val: x.start_Time_Val,
                                end_Time_Val: x.end_Time_Val,
                                scheduled_Days_Off: scheduled_Days_Off,
                                service_Required: x.service_Required,
                                sunday_Working: x.sunday_Working,
                                saturday_Working: x.saturday_Working,
                                prime_Contractor: x.prime_Contractor,
                                work_Description: x.work_Description,
                                safety_Observations_Comments: x.safety_Observations_Comments
                            },
                            errors
                        });
                    }
                    else {
                        errors = this.errorChecker("security", "", errors);
                        this.setState({
                            ...this.state,
                            data: {
                                ...this.state.data,
                                [name]: value,
                                // wsS_ID: 0,
                                // equip_On_Site: undefined,
                                security: "",
                                equip_Working: undefined,
                                emp_Working: undefined,
                                radio: "",
                                start_Time_Val: "",
                                end_Time_Val: "",
                                scheduled_Days_Off: "",
                                service_Required: "No",
                                sunday_Working: "No",
                                saturday_Working: "No",
                                prime_Contractor: "Sureway",
                                work_Description: "",
                                safety_Observations_Comments: ""
                            },
                            errors
                        });
                    }
                }
            }
            else {
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        [name]: value,
                        // wsS_ID: 0,
                        equip_On_Site: undefined,
                        equip_Working: undefined,
                        emp_Working: undefined,
                        radio: "",
                        start_Time_Val: "",
                        end_Time_Val: "",
                        scheduled_Days_Off: "",
                        service_Required: "No",
                        sunday_Working: "No",
                        saturday_Working: "No",
                        prime_Contractor: "Sureway",
                        work_Description: "",
                        safety_Observations_Comments: ""
                    },
                    errors
                });
            }
        }   
        else if(name === "wsS_ID") {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }
        else
        {          
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: value
                },
                errors
            });
        }
    };

    private handleNumberChange = (name: string, value: string): void => {
        let val = Number(value);
        if (value.includes(".")) {
            val = Number(value.split(".")[0]);
        }
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: val
            }
        });
    };

    private setDataToState = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);
        let upn: string = "";
        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {
                   
                    if (query.id !== "0") {
                        if (hasPayload(this.props.getWorkingSiteLogByID)) {
                            const data = this.props.getWorkingSiteLogByID.payload.response;
                            this.setState({
                                data
                            });
                        } else {
                            if (isNotLoaded(this.props.getWorkingSiteLogByID)) {
                                this.getWSSLogByIDData();
                            };
                        }
                    } else {
                        if (hasPayload(this.props.getWSSLogs)) {
                            upn = this.props.token.payload.response.upn;

                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            const records = Object.values(this.props.getWSSLogs.payload.response);

                            records.sort((a, b) => {
                                const dateA = new Date(a.date);
                                const dateB = new Date(b.date);
                                if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                                    return 0;
                                }
                                return dateA.getTime() - dateB.getTime();
                            });

                            const firstRecord = records[0];

                            const foremans = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.foremanList : [];
                            const dispName = foremans?.find(x=>x.upn === upn)?.display_Name;
                            const upnName = foremans?.find(x=>x.upn === upn)?.upn;

                            let existingErrors = this.state.errors;
                            let wss_from_app : number | undefined;
                            if (query.wss_id !== undefined) {
                                if (query.wss_id > 0) {
                                    wss_from_app = Number(query.wss_id);                                                                        
                                
                                existingErrors = this.errorChecker("wsS_ID", wss_from_app ? wss_from_app.toString() : "", errors);
                                const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
                                
                                const equipCount = wssList.find(x => x.wsS_ID === wss_from_app)?.equip_On_Site;
                                if (hasPayload(this.props.getWSSLogs)) {
                                    
                                const workingLogList = this.props.getWSSLogs.payload.response;
                                let security : string ="";
                                let equip_Working : number | undefined;
                                let emp_Working : number | undefined;
                                let service_Required : string | undefined ;
                                let radio : string | undefined;
                                let start_Time : string | undefined;
                                let end_Time : string | undefined;
                                let work_Description : string | undefined;
                                let safety_Observations_Comments : string | undefined;
                                let saturday_Working : string | undefined;
                                let sunday_Working : string | undefined;
                                let prime_Contractor : string | undefined;
                                let scheduled_Days_Off : string | undefined ;

                                const x = Object.values(workingLogList).filter(x => x.wsS_ID === wss_from_app && x.foreman === upnName).sort((a,b) => a.id > b.id ? -1 : 1)[0]
                                if(x)
                                {
                                    // security = x.security;
                                    equip_Working = x.equip_Working;
                                    emp_Working = x.emp_Working;
                                    service_Required = x.service_Required;
                                    radio = x.radio;
                                    start_Time = x.start_Time_Val;
                                    end_Time = x.end_Time_Val;
                                    work_Description = x.work_Description;
                                    safety_Observations_Comments = x.safety_Observations_Comments;
                                    saturday_Working = x.saturday_Working;
                                    sunday_Working = x.sunday_Working;
                                    scheduled_Days_Off = x.scheduled_Days_Off;
                                    prime_Contractor = x.prime_Contractor;
                                }
                                    

                                if(scheduled_Days_Off !== undefined && scheduled_Days_Off !== null)
                                    {
                                        let newSchDates : string[] = [];
                                        const prevDaysOff = scheduled_Days_Off ? scheduled_Days_Off.split(",") : [];
                                        if(prevDaysOff.length > 0)
                                        {
                                            scheduled_Days_Off = undefined;
                                        prevDaysOff.map((x, index) => {
                                            if(new Date(x) >= new Date())
                                            {
                                                newSchDates.push(x);
                                                scheduled_Days_Off = newSchDates.toString();
                                            }
                                        });
                                        
                                        }
                                    }

                                this.setState({
                                    errors: existingErrors,
                                    data: {
                                        ...this.state.data,                                    
                                        foreman: upnName? upnName : "",
                                        wsS_ID: wss_from_app? wss_from_app : firstRecord.wsS_ID,
                                        equip_On_Site: equipCount,                                        
                                        security: security,
                                        equip_Working: equip_Working,
                                        emp_Working: emp_Working,
                                        service_Required: service_Required ? service_Required : "Yes",
                                        radio: radio? radio : "",
                                        start_Time_Val: start_Time? start_Time : "",
                                        end_Time_Val: end_Time? end_Time : "",
                                        work_Description: work_Description? work_Description : "",
                                        safety_Observations_Comments: safety_Observations_Comments ? safety_Observations_Comments : "",
                                        sunday_Working: sunday_Working ? sunday_Working : "Yes",
                                        saturday_Working: saturday_Working ? saturday_Working : "Yes",
                                        scheduled_Days_Off: scheduled_Days_Off ? scheduled_Days_Off : "",
                                        prime_Contractor: prime_Contractor ? prime_Contractor : "Sureway"
                                    }
                                });
                                }
                            }
                            }
                            else
                            {
                                
                            this.setState({
                                errors,
                                data: {
                                    ...this.state.data,                                    
                                    foreman: upnName? upnName : "",
                                    wsS_ID: wss_from_app? wss_from_app : 0
                                }
                            });
                        }
                            
                        } 
                        // else {
                        //     if (isNotLoaded(this.props.getWSSLogs)) {
                        //         this.getData();
                        //     };
                        // }
                    };

                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLook();
                    };
                } else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Working_Site_Logs"
                }
            });
    };
    private getData = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token)) {
            if(Number(query.id) === 0) {
                this.props.getWSSLogsRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        view: "All"
                    }
                });
            }
        }
    };
    private getWSSLogByIDData = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);
        if (hasPayload(this.props.token)) {
            if(Number(query.id) > 0) {
                this.props.getWorkingSiteLogByIDRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: Number(query.id)
                    }
                });
            }
        }
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateWorkingSiteLogComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    // workingSiteLogs: getWorkingSiteLogs(state),
    getWSSLogs: getWSSLogs(state),
    addWorkingSiteLogStatus: addWorkingSiteLog(state),
    updateWorkingSiteLogStatus: updateWorkingSiteLog(state),
    getVersionHistory: getWorkingSiteLogVersionHistory(state),
    getWorkingSiteLogByID: getWorkingSiteLogById(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateWorkingSiteLogComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    // getWorkingSiteLogsRequest: (data: IWorkingSiteLogRequest) => dispatch(getWorkingSiteLogsLoadAction(data)),
    getWSSLogsRequest: (data: IGetWSSLogsRequest) => dispatch(getWSSLogsLoadAction(data)),
    addWorkingSiteLogRequest: (data: IAddWorkingSiteLogRequest) => dispatch(addWorkingSiteLogLoadAction(data)),
    updateWorkingSiteLogRequest: (data: IUpdateWorkingSiteLogRequest) => dispatch(updateWorkingSiteLogLoadAction(data)),
    getVersionHistoryRequest: (data: IGetWorkingSiteLogVersionHistoryRequest) => dispatch(getWorkingSiteLogVersionHistoryLoadAction(data)),
    getWorkingSiteLogByIDRequest: (data: IGetWorkingSiteLogByIdRequest ) => dispatch(getWorkingSiteLogByIdLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateWorkingSiteLog);