import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_HAZARD_REQUEST, IUpdateHazardRequest } from "./updateHazardConstants";


export interface IUpdateHazardLoadAction {
    type: IUPDATE_HAZARD_REQUEST.REQUEST;
    data: IUpdateHazardRequest
}
export const updateHazardLoadAction = (data: IUpdateHazardRequest): IUpdateHazardLoadAction => ({
    type: IUPDATE_HAZARD_REQUEST.REQUEST,
    data
});
export interface IUpdateHazardSuccessAction {
    type: IUPDATE_HAZARD_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateHazardLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateHazardSuccessAction => ({
    type: IUPDATE_HAZARD_REQUEST.SUCCESS,
    message
});
export interface IUpdateHazardLoadFailedAction {
    type: IUPDATE_HAZARD_REQUEST.FAILED;
    message: string;
}
export const updateHazardLoadFailedAction = (message: string): IUpdateHazardLoadFailedAction => ({
    type: IUPDATE_HAZARD_REQUEST.FAILED,
    message
});
