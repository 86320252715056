import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ISaveMechanicAssignmentSuccessAction } from "../saveMechanicAssignment/saveMechanicAssignmentActions";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "../saveMechanicAssignment/saveMechanicAssignmentConstants";
import { ISAVE_COMPONENT_WAITING_PARTS_REQUEST } from "../saveComponentWaitingParts/saveComponentWaitingPartsConstants";
import { ISaveComponentWaitingPartsSuccessAction } from "../saveComponentWaitingParts/saveComponentWaitingPartsActions";
import { IWorkOrderSuccessAction } from "../workOrder/workOrderActions";
import { IWORK_ORDER_REQUEST } from "../workOrder/workOrderConstants";
import { IGetRepairLineWOLoadAction, IGetRepairLineWOLoadFailedAction, IGetRepairLineWOSuccessAction } from "./getRepairLineWOActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINES_WO_REQUEST, IRepairLineWO } from "./getRepairLineWOConstants";

type Actions =
    | IGetRepairLineWOLoadAction
    | IGetRepairLineWOSuccessAction
    | IGetRepairLineWOLoadFailedAction
    | ISaveMechanicAssignmentSuccessAction
    | ISaveComponentWaitingPartsSuccessAction
    | IWorkOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetRepairLineWOReducer = (state: Server<SurewayAPIResponse<IRepairLineWO[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IRepairLineWO[]>> => {
    switch (action.type) {
        case IGET_REPAIR_LINES_WO_REQUEST.REQUEST:
            return loading;

        case IGET_REPAIR_LINES_WO_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPAIR_LINES_WO_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS:
            return notLoaded;

        case IWORK_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};