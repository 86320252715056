import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById,  IIdName,  SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString, deleteText, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import LAAutoComplete from "../../../shared/autoComplete";
import { LADropZone } from "../../../shared/dropZone";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import {  IUnit } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import PageSpacing from "../../../shared/pageSpacing";
import { IAddEquipmentManual, IAddEquipmentManualRequest } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualConstants";
import { IUpdateEquipmentManualRequest } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualConstants";
import { addEquipmentManualStatus } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualAccessor";
import { updateEquipmentManualStatus } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualAccessor";
import { getEquipmentManualsLoadAction } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsActions";
import { addEquipmentManualLoadAction } from "../../../../redux/field/equipmentManuals/addEquipmentManual/addEquipmentManualActions";
import { updateEquipmentManualLoadAction } from "../../../../redux/field/equipmentManuals/updateEquipmentManual/updateEquipmentManualActions";
import { FormHelperText } from "@material-ui/core";
import { getFormLookup } from "../../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getFormLookupLoadAction } from "../../../../redux/field/getFormLookup/getFormLookupActions";
import { IEmployees, IFormLookup, IFormLookupRequest } from "../../../../redux/field/getFormLookup/getFormLookupConstants";
import { NotApplicable, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../../shared/checkBox";
import { IGetCrews, IGetCrewsRequest } from "../../../../redux/field/getCrews/getCrewsConstants";
import { getCrews } from "../../../../redux/field/getCrews/getCrewsAccessor";
import { getCrewsLoadAction } from "../../../../redux/field/getCrews/getCrewsActions";
import { IAddCrewRequest, IAddUpdateCrew } from "../../../../redux/field/addCrew/addCrewConstants";
import { IUpdateCrewRequest } from "../../../../redux/field/updateCrew/updateCrewConstants";
import { addCrewLoadAction } from "../../../../redux/field/addCrew/addCrewActions";
import { updateCrewLoadAction } from "../../../../redux/field/updateCrew/updateCrewActions";
import { addCrewStatus } from "../../../../redux/field/addCrew/addCrewAccessor";
import { updateCrewStatus } from "../../../../redux/field/updateCrew/updateCrewAccessor";
import { LACenteredLoading } from "../../../shared/loading";
import { IGetCrewVersionHistory, IGetCrewVersionHistoryRequest } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryConstants";
import { getCrewVersionHistory } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryAccessor";
import { getCrewVersionHistoryLoadAction } from "../../../../redux/field/workingSiteLogs/getCrewVersionHistory/getCrewVersionHistoryActions";
import LALinkButton from "../../../shared/linkButton";
import { CrewVersionHistoryPopup } from "./crewVersionHistoryPopup";

const RequiredFields: string[] = ["crew_Name"];


interface IAddUpdateCrewComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    getCrews: Server<SurewayAPIResponse<ById<IGetCrews>>>;
    addCrewStatus: Server<SurewayAPIResponse<string>>;
    updateCrewStatus: Server<SurewayAPIResponse<string>>;
    getCrewVersionHistory: Server<SurewayAPIResponse<ById<IGetCrewVersionHistory>>>;
};

interface IAddUpdateCrewComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    getCrewsRequest: (data: IGetCrewsRequest) => unknown
    addCrewRequest: (data: IAddCrewRequest) => unknown;
    updateCrewRequest: (data: IUpdateCrewRequest) => unknown;
    getCrewVersionHistoryRequest: (data: IGetCrewVersionHistoryRequest) => unknown;
};

interface IAddUpdateCrewOwnProps {
    id?: string;
};

interface IAddUpdateCrewComponentState {
    data: IGetCrews;
    crewVersionHistoryPopup: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateCrewStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateCrewComponentProps =
    RouteComponentProps
    & IAddUpdateCrewOwnProps
    & IAddUpdateCrewComponentStoreProps
    & IAddUpdateCrewComponentDispatchProps;

class AddUpdateCrew extends PureComponent<IAddUpdateCrewComponentProps, IAddUpdateCrewComponentState> {

    public constructor(props: IAddUpdateCrewComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                foreman: userName,
                crew_Name: "",
                active: "Yes",
                members: [],
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            crewVersionHistoryPopup: false,
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateCrewComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addCrewStatus !== prevProps.addCrewStatus) {

                if (this.props.addCrewStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addCrewStatus.message });

                if (hasPayload(this.props.addCrewStatus) && this.props.addCrewStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateCrewStatus !== prevProps.updateCrewStatus) {

                if (this.props.updateCrewStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateCrewStatus.message });

                if (hasPayload(this.props.updateCrewStatus) && this.props.updateCrewStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, crewVersionHistoryPopup } = this.state;
        const title = data.id > 0 ? "Update Crew" : "Add Crew";
        const { token, addCrewStatus, updateCrewStatus, getLookup, getCrewVersionHistory } = this.props;
        const getRole = pageAccessCheck(token, "workingSiteLogs");
        const disabled = getRole === ReadOnly ? true : undefined;
        // const disabled = (getRole === false) ? true : undefined;
        // const disabled =  undefined;
        // console.log(data)

        const employees = hasPayload(getLookup) ? getLookup.payload.response.employees : [];
        const crewVersionHistoryPopupData = hasPayload(getCrewVersionHistory) ? Object.values(getCrewVersionHistory.payload.response) : [];
        // const makes = hasPayload(getLookup) ? getLookup.payload.response.manufacturers : [];
        // const models = hasPayload(getLookup) ? getLookup.payload.response.models : [];
        const disableSave = (Object.values(errors).length > 0) ? true : disabled;
        const onForeman = (event: unknown, value: IEmployees): void => this.handleChange("foreman", value !== null ? value.username : data.foreman);
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");
        // console.log(data)

        if(getLookup.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateCrewStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={9} sm={9} md={9}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={3} >
                                <LALinkButton onClick={() => this.handleCrewVersionHistory(data)} label="Version History"></LALinkButton>
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Crew</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LAAutoComplete
                                            disabled={disabled}
                                            multiple={false}
                                            option={employees}
                                            autoHighlight={true}
                                            onChange={onForeman}
                                            filterSelectedOptions={true}
                                            getOptionLabel="name"
                                            dropDownPlaceHolder="Foreman"
                                            selectionRemove={undefinedFunction}
                                            value={data.foreman ? employees && employees.find(q => q.username === data.foreman) : null}
                                            defaultValue={data.foreman ? employees && employees.find(q => q.username === data.foreman) : null}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            name="crew_Name"
                                            label="Crew Name"
                                            fullWidth={true}
                                            disabled={disabled}
                                            errorText={errors["crew_Name"] ? errors["crew_Name"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.crew_Name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <DevExtremePopupLookup
                                            data={employees}
                                            multiple={true}
                                            id="member-search"
                                            placeHolder="Members"
                                            disabled={undefined}
                                            displayExp="name"
                                            name="id"
                                            errorName="members"
                                            onSelectionRemove={this.handleMemberRemove}
                                            errors={errors}
                                            selectionRow={true}
                                            onSelectionChanged={this.handleMembersSelectionChanged}
                                            currentVal={this.getMembers(data.members ?? [])}
                                            onClick={undefinedFunction}
                                            columns={[
                                                { name: "name", caption: "Name", type: "string" },
                                                { name: "company", caption: "Company", type: "string" },
                                                { name: "department", caption: "Department", type: "string" },
                                            ]}
                                        />
                                    </LAGridItem>
                                </LAGrid>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }
                    {crewVersionHistoryPopup && <CrewVersionHistoryPopup
                        data={crewVersionHistoryPopupData}
                        display={`Version History`}
                        open={crewVersionHistoryPopup}
                        status={getCrewVersionHistory.kind}
                        onCancel={this.handleCrewVersionHistoryPopupClose}
                    />}
                    <RequestStatus requestStatus={addCrewStatus.kind} successMessage="Crew has been added successfully" />
                    <RequestStatus requestStatus={updateCrewStatus.kind} successMessage="Crew has been updated successfully" />

                </AddUpdateCrewStyles>
            </PageSpacing>
        );
    }

    private getMembers = (assignedMembersIds: number[]): IEmployees[] => {
        if (assignedMembersIds) {
            const employees = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.employees : [];
            const response: IEmployees[] = [];
            // const assignedUnitIDs = ids !== null ? ids.split(",").map(String) : [];

            assignedMembersIds.forEach((x) => {
                const index = employees.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(employees[index]);
            });

            return response;
        }
        return [];
    };

    private handleMembersSelectionChanged = (row:any): void => {
        const selectedMembersList: IEmployees[] = row.selectedRowsData;
        // console.log(selectedMembersList);
        const data = { ...this.state.data };
        let assigned_Members = data.members;

        selectedMembersList.forEach((x) => {
            const index = assigned_Members.length > 0 ? assigned_Members.findIndex((e) => e === x.id) : -1;
            if (index < 0) {
                assigned_Members.push(x.id);
                
            }
        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                members: assigned_Members,
            },
        });
    }

    private handleMemberRemove = (value: string): void => {
        // console.log(value)
        // let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        const employees = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.employees : [];

        let assigned_Members = data.members;
        // const units_Array: string[] = unitIDs && unitIDs !== null ? unitIDs.split(",").map(String) : [];
        const member = employees ? employees.find(q => q.name === value) : null;

        if(member) {
            const index = assigned_Members.length > 0 ? assigned_Members.findIndex((x) => x === member.id) : -1;
            if (index >= 0) {
                assigned_Members.splice(index, 1);
            }
        }
        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                members: assigned_Members,
            },
        });
    }
     // private handleMemberClick = (value: IEmployees): void => {
    //     // console.log(value)
    //     // let errors = { ...this.state.errors };
    //     const data = { ...this.state.data}
    //     if(value) {
    //         let membersIDs = data.members;

    //         const index = membersIDs.length > 0 ? membersIDs.findIndex((e) => e === value.id) : -1;
    //         if (index < 0) {
    //             membersIDs.push(value.id);
    //         }
    
    //         this.setState({
    //             ...this.state,
    //             data: {
    //                 ...data,
    //                 members: membersIDs
    //             },
    //         });
    //     }
       
    // }


    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.FORMS.CREW.INDEX
        });
    };

    private handleCrewVersionHistory = async(data: any): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await this.props.getCrewVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: data.id
                }
            });
        }
        this.setState({ crewVersionHistoryPopup: true });
    }

    private handleCrewVersionHistoryPopupClose = (): void => {
        this.setState({ crewVersionHistoryPopup: false });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            let request: IAddUpdateCrew = {
                ID: data.id,
                Foreman: data.foreman,
                Crew_Name: data.crew_Name,
                Members: data.members,
                Active: data.active,
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };
            // console.log(request)
            if (data.id === 0) {
                this.props.addCrewRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateCrewRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "workingSiteLogs") !== NotApplicable) {

                    if (hasPayload(this.props.getCrews)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getCrews.payload.response[+query.id];

                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getCrewsRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Crew"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateCrewComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFormLookup(state),
    getCrews: getCrews(state),
    addCrewStatus: addCrewStatus(state),
    updateCrewStatus: updateCrewStatus(state),
    getCrewVersionHistory: getCrewVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateCrewComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    getCrewsRequest: (data: IGetCrewsRequest) => dispatch(getCrewsLoadAction(data)),
    addCrewRequest: (data: IAddCrewRequest) => dispatch(addCrewLoadAction(data)),
    updateCrewRequest: (data: IUpdateCrewRequest) => dispatch(updateCrewLoadAction(data)),
    getCrewVersionHistoryRequest: (data: IGetCrewVersionHistoryRequest) => dispatch(getCrewVersionHistoryLoadAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateCrew);