import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_WORKING_SITE_LOG_BY_ID_REQUEST } from "./getWorkingSiteLogByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWorkingSiteLogByIdLoadAction, IGetWorkingSiteLogByIdLoadFailedAction, IGetWorkingSiteLogByIdSuccessAction } from "./getWorkingSiteLogByIdActions";
import { IWorkingSiteLog } from "../getWorkingSiteLogs/getWorkingSiteLogsConstants";
import { IUpdateWorkingSiteLogSuccessAction } from "../updateWorkingSiteLog/updateWorkingSiteLogActions";
import { IAddWorkingSiteLogSuccessAction } from "../addWorkingSiteLog/addWorkingSiteLogActions";
import { IUPDATE_WORKING_SITE_LOG_REQUEST } from "../updateWorkingSiteLog/updateWorkingSiteLogConstants";
import { IADD_WORKING_SITE_LOG_REQUEST } from "../addWorkingSiteLog/addWorkingSiteLogConstants";

type Actions =
    | IGetWorkingSiteLogByIdLoadAction
    | IGetWorkingSiteLogByIdSuccessAction
    | IGetWorkingSiteLogByIdLoadFailedAction
    | IAddWorkingSiteLogSuccessAction
    | IUpdateWorkingSiteLogSuccessAction
    | IFlushDataSuccessAction;

export const GetWorkingSiteLogByIdReducer = (state: Server<SurewayAPIResponse<IWorkingSiteLog>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IWorkingSiteLog>> => {
    switch (action.type) {
        case IGET_WORKING_SITE_LOG_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_WORKING_SITE_LOG_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WORKING_SITE_LOG_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};