import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST {
    REQUEST = "getPayrollMechanicLogsSummary/GET_PAYROLL_MECHANIC_LOGS_SUMMARY_REQUEST",
    SUCCESS = "getPayrollMechanicLogsSummary/GET_PAYROLL_MECHANIC_LOGS_SUMMARY_SUCCESS",
    FAILED = "getPayrollMechanicLogsSummary/GET_PAYROLL_MECHANIC_LOGS_SUMMARY_FAILED"
};

export interface IGetPayrollMechanicLogsSummaryRequest extends ISurewayTokenRequestBody {
    request? : {
        Type?: string;
    }
};

export interface IPayrollMechanicLogsSummary {
    id: number;
    date: string;
    mechanic_ID: number;
    mechanic_Name: string;
    start_Time: string;
    end_Time: string;
    defect_Log_Hours: number;
    lunch: number;
    misc_Hours: number;
    total_Hours: number;
    uploaded: string;
};