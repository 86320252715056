import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST, IGetWorkingSiteLogVersionHistory } from "./getWorkingSiteLogVersionHistoryConstants";
import { IGetWorkingSiteLogVersionHistoryLoadAction, IGetWorkingSiteLogVersionHistoryLoadFailedAction, IGetWorkingSiteLogVersionHistorySuccessAction } from "./getWorkingSiteLogVersionHistoryActions";

type Actions =
    | IGetWorkingSiteLogVersionHistoryLoadAction
    | IGetWorkingSiteLogVersionHistorySuccessAction
    | IGetWorkingSiteLogVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetWorkingSiteLogVersionHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>> => {
    switch (action.type) {
        case IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};