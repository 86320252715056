import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateRepeaterKitRequest } from "../addRepeaterKit/addRepeaterKitConstants";
import { IUPDATE_REPEATER_KIT_REQUEST } from "./updateRepeaterKitConstants";

export interface IUpdateRepeaterKitLoadAction {
    type: IUPDATE_REPEATER_KIT_REQUEST.REQUEST;
    data: IAddUpdateRepeaterKitRequest;
}
export const updateRepeaterKitLoadAction = (data: IAddUpdateRepeaterKitRequest): IUpdateRepeaterKitLoadAction => ({
    type: IUPDATE_REPEATER_KIT_REQUEST.REQUEST,
    data
});
export interface IUpdateRepeaterKitSuccessAction {
    type: IUPDATE_REPEATER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateRepeaterKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateRepeaterKitSuccessAction => ({
    type: IUPDATE_REPEATER_KIT_REQUEST.SUCCESS,
    list
});
export interface IUpdateRepeaterKitLoadFailedAction {
    type: IUPDATE_REPEATER_KIT_REQUEST.FAILED;
    message: string;
}
export const updateRepeaterKitLoadFailedAction = (message: string): IUpdateRepeaterKitLoadFailedAction => ({
    type: IUPDATE_REPEATER_KIT_REQUEST.FAILED,
    message
});
