import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getDate, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import queryString from "query-string";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
// import { getServiceSheetReport } from "../../../redux/field/pmSheets/getServiceSheetReport/getServiceSheetReportAccessor";
// import { getServiceSheetReportLoadAction } from "../../../redux/field/pmSheets/getServiceSheetReport/getServiceSheetReportActions";
import { PMSheetsSubHeader, PMSheetsSubHeaderMobile } from "../../header/pmSheetsSubHeader";
// import { IServiceSheetReport, IGetServiceSheetReportRequest, IServiceSheetReportSectionItem, IServiceSheetReportSection } from "../../../redux/field/pmSheets/getServiceSheetReport/getServiceSheetReportConstants";
import { LAPopover } from "../../shared/popOver";
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { DARK_GREY_COLOR, LIGHT_GREEN_COLOR, LIGHT_GREY_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, RED_COLOR, SECONDARY_COLOR, WHITE_COLOR } from "../../shared/theme";
import { approveServiceSheetStatus } from "../../../redux/field/pmSheets/approveServiceSheet/approveServiceSheetAccessor";
import { approveServiceSheetLoadAction } from "../../../redux/field/pmSheets/approveServiceSheet/approveServiceSheetActions";
import { IApproveServiceSheetRequest, IDetails } from "../../../redux/field/pmSheets/approveServiceSheet/approveServiceSheetConstants";
import LATextArea from "../../shared/textArea";
import { LASecondaryButton } from "../../shared/buttons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LALinkButton from "../../shared/linkButton";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import LAErrorBox from "../../shared/errorBox";
import LADatePicker from "../../shared/datePicker";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import { getPMSheetReportById } from "../../../redux/field/pmSheets/getPMSheetReportById/getPMSheetReportByIdAccessor";
import { IGetPMSheetReportByIdRequest, IServiceSheetReport, IServiceSheetReportSection, IServiceSheetReportSectionItem } from "../../../redux/field/pmSheets/getPMSheetReportById/getPMSheetReportByIdConstants";
import { getPMSheetReportByIdLoadAction } from "../../../redux/field/pmSheets/getPMSheetReportById/getPMSheetReportByIdActions";
import { IGetPMSheetsReportRequest, IPMSheetsReport } from "../../../redux/field/pmSheets/getPMSheetsReport/getPMSheetsReportConstants";
import { getPMSheetsReport } from "../../../redux/field/pmSheets/getPMSheetsReport/getPMSheetsReportAccessor";
import { getPMSheetsReportLoadAction } from "../../../redux/field/pmSheets/getPMSheetsReport/getPMSheetsReportActions";


interface IServiceSheetReportStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    approveSheet: Server<SurewayAPIResponse<string>>;
    // getServiceSheetReport: Server<SurewayAPIResponse<ById<IServiceSheetReport>>>;
    getPMSheetsReport: Server<SurewayAPIResponse<IPMSheetsReport[]>>;
    getPMSheetReportByID: Server<SurewayAPIResponse<IServiceSheetReport>>;
};

interface IServiceSheetReportDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    approveSheetRequest: (data: IApproveServiceSheetRequest) => unknown;
    // getServiceSheetReportRequest: (data: IGetServiceSheetReportRequest) => unknown;
    getPMSheetsReportRequest: (data: IGetPMSheetsReportRequest) => unknown;
    getPMSheetReportByIDRequest: (data: IGetPMSheetReportByIdRequest) => unknown;
};

interface IServiceSheetReportOwnProps {

};

interface IServiceSheetPopup extends IServiceSheetReport {
    comments: string;
    admin_Comments: string;
    approved: boolean | undefined;
};

interface IServiceSheetReportState {
    loading: boolean;
    popUpData: IServiceSheetPopup | undefined;
    popUpDataID: number | undefined;
    view: "All" | "Pending_Review" | "Pending_Approval";
};

const ServiceSheetReportStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        
        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IServiceSheetReportProps = RouteComponentProps
    & IServiceSheetReportStoreProps
    & IServiceSheetReportDispatchProps
    & IServiceSheetReportOwnProps;

class ServiceSheetReport extends PureComponent<IServiceSheetReportProps, IServiceSheetReportState> {

    public constructor(props: IServiceSheetReportProps) {
        super(props);
        this.state = {
            loading: false,
            popUpData: undefined,
            popUpDataID: undefined,
            view: "Pending_Approval",
        };
    }

    public async componentDidMount(): Promise<void> {
        const query: any = queryString.parse(this.props.location.search);

        if ((query !== undefined) && (query.id !== undefined))
            await this.setState({ view: "All" });

        this.callServer();
    };

    public componentDidUpdate(prevProps: IServiceSheetReportProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.approveSheet !== prevProps.approveSheet) {

                if ((this.state.popUpData) && hasPayload(this.props.approveSheet) && (this.props.approveSheet.kind === STATUS_ENUM.SUCCEEDED))
                    this.handlePopupClose();
            };

            if (this.props.getPMSheetReportByID !== prevProps.getPMSheetReportByID) {
                this.setPMSheetReportData()
            }
        }
    };


    public render(): ReactNode {

        const { view, popUpData, loading, popUpDataID} = this.state;
        const { getPMSheetsReport, getToken, approveSheet, getPMSheetReportByID } = this.props;
        const getRole = pageAccessCheck(getToken, "pmSheets");
        const token = hasPayload(getToken) ? getToken.payload.response.token : "";
        // const data = hasPayload(getServiceSheetReport) ? Object.values(getServiceSheetReport.payload.response) : [];
        const data = hasPayload(getPMSheetsReport) ? getPMSheetsReport.payload.response : [];

        return (
            <PageSpacing title="Service Sheet Report" description="FIELD - SERVICE SHEET REPORT" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <ServiceSheetReportStyles>

                    <div className="hide-on-phone">
                        <PMSheetsSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <PMSheetsSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    {loading ? <LAPaperWithPadding>
                        <LALoading message="Loading data, please wait..." />
                    </LAPaperWithPadding>
                        : <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={12}>

                                    <h2 className="text-center">SERVICE SHEET REPORT</h2>
                                    <hr />

                                    {getPMSheetsReport.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Service Sheet Report..." />}
                                    {getPMSheetsReport.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Service Sheet Report..." />}

                                    {getPMSheetsReport.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>

                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                    <FormControlLabel value="Pending_Approval" control={<Radio />} label="Pending Approval" />
                                                    <FormControlLabel value="Pending_Review" control={<Radio />} label="Pending Review" />
                                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                                </RadioGroup>
                                            </LAGridItem>


                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    onClick={undefinedFunction}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={120}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onCustomClick={this.handleCustom}
                                                    id="serviceSheetReportList"
                                                    customRowColor={true}
                                                    storageKey="serviceSheetReportListSessionKey"
                                                    exportFileName="ServiceSheetReport"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "Service Items", caption: "Service Items", type: "button" },
                                                        { name: "unit_No", caption: "Unit No", type: "string" },
                                                        { name: "location", caption: "Location", type: "string" },
                                                        { name: "sub_Location", caption: "Sub Location", type: "string" },
                                                        { name: "service_Due", caption: "Service Due", type: "string" },
                                                        { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                                                        { name: "service_Date", caption: "Service Date", type: "date" },
                                                        { name: "hours", caption: "Hours", type: "number" },
                                                        { name: "comments", caption: "Comments", type: "string" },
                                                        { name: "status", caption: "Status", type: "string" },
                                                        { name: "approved_Date", caption: "Approved Date", type: "date" },
                                                        { name: "service_Truck", caption: "Service Truck", type: "string" },
                                                        { name: "admin_Comments", caption: "Admin Comments", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime", sortDesc: true },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>

                            </LAGrid>

                            {popUpData &&
                                <ServiceItems
                                    token={token}
                                    data={popUpData}
                                    onApprove={this.onApprove}
                                    onChange={this.onSheetChange}
                                    onSectionItemChange={this.onSectionItemChange}
                                    onClose={this.handlePopupClose}
                                    open={popUpData ? true : false}
                                />}
                        </LAPaperWithPadding>}

                    <RequestStatus
                        requestStatus={approveSheet.kind}
                        successMessage="Service sheet successfully approved"
                        failedMessage="Service sheet failed to update please try again"
                    />
                </ServiceSheetReportStyles>}
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ popUpData: undefined, popUpDataID: undefined });

        this.props.history.push({
            pathname: ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT,
            search: getTokenFromUrl(false)
        });
    };

    private onViewClick = (event: React.ChangeEvent<HTMLInputElement>, view: any): void => {
        this.setState({ view });

        if (hasPayload(this.props.getToken)) {
            this.props.getPMSheetsReportRequest({
                request: {
                    View: view
                },
                token: this.props.getToken.payload.response.token
            });
        }
    };

    private handleCustom = (name: string, column: any): void => {
        const data: IServiceSheetReport = column.row.data;
        this.setState({ loading: true, popUpDataID: data.id});
        this.getPmSheetReportByID();

        // this.setState({
        //     ...this.state,
        //     popUpData: {
        //         ...data,
        //         approved: (data.status === "Completed") ? true : (data.status !== "Draft") ? false : undefined,
        //         admin_Comments: data.admin_Comments ? data.admin_Comments : "",
        //         comments: data.comments
        //     },
        //     loading: false
        // });
        // callRouteWithQueryString({
        //     route: this.props,
        //     search: { id: data.id.toString() },
        //     pathName: ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT
        // });

    };

    private getPmSheetReportByID = async(): Promise<void> => {
        const { popUpDataID } = this.state;
        if (hasPayload(this.props.getToken) && popUpDataID) {
            await this.props.getPMSheetReportByIDRequest({
                request: {
                    id: popUpDataID
                },
                token: this.props.getToken.payload.response.token
            });
            
            // setTimeout(() => {
                // this.setPMSheetReportData();
            // }, 200);
        }
    }

    
    private setPMSheetReportData = () : void => {
        const { getPMSheetReportByID } = this.props;
        const pmSheetReportData = hasPayload(getPMSheetReportByID) ? getPMSheetReportByID.payload.response : undefined;

        if(pmSheetReportData) {
            this.setState({
                ...this.state,
                popUpData: {
                    ...pmSheetReportData,
                    approved: (pmSheetReportData.status === "Completed") ? true : (pmSheetReportData.status !== "Draft") ? false : undefined,
                    admin_Comments: pmSheetReportData.admin_Comments ? pmSheetReportData.admin_Comments : "",
                    comments: pmSheetReportData.comments
                },
                loading: false
            });
            callRouteWithQueryString({
                route: this.props,
                search: { id: pmSheetReportData.id.toString() },
                pathName: ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT
            });
        }
    }

    private onSheetChange = (name: string, value: string | boolean | number): void => {
        const { popUpData } = this.state;
        // console.log(name, value)

        if (popUpData) {
            this.setState({
                popUpData: {
                    ...popUpData,
                    [name]: value
                }
            });
        };
    };

    private onSectionItemChange = (name: string, value: string, sectionIndex: number, itemIndex: number): void => {
        const { popUpData } = this.state;
        // console.log(name, value, sectionIndex, itemIndex);

        if(popUpData) {
            const sectionsList: IServiceSheetReportSection[] =  [...popUpData.sections];

            if(sectionsList[sectionIndex]) {
                const itemsList:IServiceSheetReportSectionItem[] = [...sectionsList[sectionIndex].items];

                if(itemsList[itemIndex]) {
                    const updatedItem: IServiceSheetReportSectionItem = {
                        ...itemsList[itemIndex],
                        [name]: value
                    };
                    itemsList[itemIndex] = updatedItem;
                }

                sectionsList[sectionIndex] = {
                    ...sectionsList[sectionIndex],
                    items: itemsList
                };
            }
            // console.log(sectionsList);
       
            this.setState({
                popUpData: {
                    ...popUpData,
                    sections: sectionsList 
                }
            });
        }
    };

    private onApprove = (): void => {
        const { popUpData } = this.state;

        let detailsArray:IDetails[] = [];
        popUpData?.sections.forEach(section => {
            section.items.forEach((item: IServiceSheetReportSectionItem) => {
                detailsArray.push({
                    ID: item.id,
                    Choice: item.value,
                    Comments: item.comments
                });
            });
        });
        // console.log(detailsArray);

        if (hasPayload(this.props.getToken) && popUpData) {
            this.props.approveSheetRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    ID: popUpData.id,
                    Hours: popUpData.hours,
                    Service_Date: popUpData.service_Date,
                    Approved: popUpData.approved ?? false,
                    Comments: popUpData.comments,
                    Admin_Comments: popUpData.admin_Comments,
                    Details: detailsArray,
                    Rejected: popUpData.approved ? false : true,
                    Modified_By: this.props.getToken.payload.response.upn
                }
            });
        };
    };

    private callServer = async(): Promise<void> => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: { 
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "pmSheets") !== NotApplicable) {
                if (isNotLoaded(this.props.getPMSheetsReport)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }
        };

        const query: any = queryString.parse(this.props.location.search);

        if ((query !== undefined) && (query.id !== undefined) &&
            (this.state.popUpData === undefined) && isNotLoaded(this.props.getPMSheetReportByID)
            ) {
            // const record = this.props.getServiceSheetReport.payload.response[+query.id];

            // if (record)
                await this.setState({ popUpDataID: Number(query.id)});
                this.getPmSheetReportByID();
                // this.handleCustom("", { row: { data: record } });
        }
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "pmSheets") !== NotApplicable) {
            this.props.getPMSheetsReportRequest({
                request: {
                    View: this.state.view
                },
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IServiceSheetReportStoreProps => ({
    getToken: getToken(state),
    approveSheet: approveServiceSheetStatus(state),
    // getServiceSheetReport: getServiceSheetReport(state),
    getPMSheetsReport: getPMSheetsReport(state),
    getPMSheetReportByID: getPMSheetReportById(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IServiceSheetReportDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    approveSheetRequest: (data: IApproveServiceSheetRequest) => dispatch(approveServiceSheetLoadAction(data)),
    // getServiceSheetReportRequest: (data: IGetServiceSheetReportRequest) => dispatch(getServiceSheetReportLoadAction(data)),
    getPMSheetsReportRequest: (data: IGetPMSheetsReportRequest) => dispatch(getPMSheetsReportLoadAction(data)),
    getPMSheetReportByIDRequest: (data: IGetPMSheetReportByIdRequest ) => dispatch(getPMSheetReportByIdLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSheetReport);


const ItemsStyles = styled(LAPaperWithPadding)`
    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .head-title {
        font-size: 20px;
        color: ${RED_COLOR};
    };
    .head-sections {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .completedCSS{
        background-color:${LIGHT_GREEN_COLOR}
    }

    .notcompletedCSS{
        background-color:${LIGHT_RED_COLOR}
    }

    .table-border {
        margin-top: 1.5px;
        border: 1.5px solid ${DARK_GREY_COLOR};
    };

    .equipment-type {
        font-size: 23px;
        font-wight: bold;
    };
    
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };
    
    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
    .thumbnail-img{
        border-width: 0;
        height: auto;
        max-height: 130px;
        max-width: 130px;
        vertical-align: middle;
        width: auto;
        margin-right: 5px;
        padding: 10px;
        float: left;
      }

`;

interface IServiceItemsPopup {
    open: boolean;
    token: string;
    onClose: () => void;
    onApprove: () => void;
    data: IServiceSheetPopup;
    onChange: (name: string, value: string | boolean | number, index? : number) => void;
    onSectionItemChange: (name: string, value: string, sectionIndex: number, itemIndex: number) => void;
};

export const ServiceItems: React.FC<IServiceItemsPopup> = React.memo((props: IServiceItemsPopup) => {

    const onDate = (date: string): void => props.onChange("service_Date", new Date(date).toDateString());
    const onHour = (name: string, value: string): void => {
        if(value.toString().includes(".") === false)
            props.onChange("hours", +value);
    };
    const onApproveCheck = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => props.onChange("approved", value === "approved" ? true : false);
    
    const disabled = (props.data.status === "Completed") ? true : undefined;
    const urls = (props.data.pictures !== null) ? props.data.pictures.split(",") : [];
    // console.log(props.data)
    const completedOptions = [
        { id: 1, name : "Yes"},
        { id: 2, name : "No"},
        { id: 3, name : "NA"},
    ];
    const sortedSections = (props.data.sections.sort((a, b) => {
        if (a.display_Order === null && b.display_Order === null) {
            return 0;
        } else if (a.display_Order === null) {
            return 1;
        } else if (b.display_Order === null) {
            return -1;
        }
        return a.display_Order - b.display_Order
    }));

    const openServiceSheetVersionHistory = () => {
        let pmSheetVersionHistoryUrl = `${ROUTE.FIELD.PM_SHEETS.PM_SHEET_VERSION_HISTORY}?id=${props.data.id}`;
        window.open(pmSheetVersionHistoryUrl, "_blank");
    }

    const openServiceSheetDetailsVersionHistory = () => {
        let pmSheetDetailsVersionHistoryUrl = `${ROUTE.FIELD.PM_SHEETS.PM_SHEET_DETAILS_VERSION_HISTORY}?id=${props.data.id}`;
        window.open(pmSheetDetailsVersionHistoryUrl, "_blank");
    }

    const openUnitHistory = () => {
        let unitHistoryUrl = `${ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY}?id=${props.data.unit_ID}`;
        window.open(unitHistoryUrl, "_blank");
    }

    return (
        <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
            <ItemsStyles>
                <LAGrid spacing={3}>

                    <LAGridItem xs={12} className="text-center">
                        <LAGrid>
                            <LAGridItem xs={12} sm={12} md={2}>
                                <LALinkButton onClick={openServiceSheetVersionHistory} label="Service Sheet Version History"></LALinkButton>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={2}>
                                <LALinkButton onClick={openServiceSheetDetailsVersionHistory} label="Service Sheet Details History"></LALinkButton>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={2}>
                                <LALinkButton onClick={openUnitHistory} label="Unit History"></LALinkButton>
                            </LAGridItem>
                        </LAGrid>
                            
                        <strong className="equipment-type">
                            {props.data.equipment_Type !== null ? props.data.equipment_Type.toUpperCase() : props.data.equipment_Type}
                        </strong>
                        <br />

                        <strong>
                            DATE:
                        </strong>
                        <span>
                            {getDate(false, props.data.service_Date)} |
                        </span>

                        <strong className="pl-1">
                            UNIT:
                        </strong>
                        <span>
                            {props.data.unit_No} |
                        </span>

                        <strong className="pl-1">
                            Equipment Type:
                        </strong>
                        <span>
                            {props.data.equipment_Type} |
                        </span>

                        <strong className="pl-1">
                            Service Due:
                        </strong>
                        <span>
                            {props.data.service_Due} |
                        </span>

                        <strong className="pl-1">
                            HOURS:
                        </strong>
                        <span>
                            {props.data.hours} |
                        </span>

                        <strong className="pl-1">
                            Service Truck:
                        </strong>
                        <span>
                            {props.data.service_Truck}
                        </span>
                        <br />

                        <strong className="pl-1">
                            Make:
                        </strong>
                        <span>
                            {props.data.make} |
                        </span>

                        <strong className="pl-1">
                            Model:
                        </strong>
                        <span>
                            {props.data.model} |
                        </span>

                        <strong className="pl-1">
                            Created By:
                        </strong>
                        <span>
                            {props.data.created_By} |
                        </span>

                        <strong className="pl-1">
                            Created:
                        </strong>
                        <span>
                            {getDate(true, props.data.created)} |
                        </span>

                        <strong className="pl-1">
                            Modified By:
                        </strong>
                        <span>
                            {props.data.modified_By} |
                        </span>

                        <strong className="pl-1">
                            Modified:
                        </strong>
                        <span>
                            {getDate(true, props.data.modified)}
                        </span>

                        <br />

                    </LAGridItem>

                    <hr />

                    <LAGridItem xs={12}>
                        <LAGrid spacing={3}>

                            <LAGridItem xs={12}>
                                <LAPaperWithLessPadding>
                                    <LAGrid spacing={0}>

                                        <LAGridItem xs={12} sm={4} md={2}>
                                            <RadioGroup row aria-label="" name="status-radioGroup"
                                                value={(props.data.approved === true) ? "approved" : (props.data.approved === false) ? "rejected" : ""}
                                                onChange={onApproveCheck}>
                                                <FormControlLabel value="approved" disabled={disabled} control={<Radio />} label="Approved" />
                                                <FormControlLabel value="rejected" disabled={disabled} control={<Radio />} label="Rejected" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={8} md={10}>
                                            <LATextArea
                                                minRows={2}
                                                rowsMax={2}
                                                fullWidth={true}
                                                disabled={disabled}
                                                name="admin_Comments"
                                                label="Admin Comments"
                                                onChange={props.onChange}
                                                value={props.data.admin_Comments}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={4} md={2}>
                                            <strong>Date</strong>
                                            <br />
                                            <LADatePicker
                                                id="service_Date"
                                                onChange={onDate}
                                                // disable={disabled}
                                                value={props.data.service_Date}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={4} md={2}>
                                            <LATextField
                                                type="number"
                                                name="hours"
                                                label="Hours"
                                                fullWidth={true}
                                                onChange={onHour}
                                                // disabled={disabled}
                                                value={props.data.hours}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={4} md={2}>
                                            <></>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={8} md={10}>
                                            <LASecondaryButton
                                                label="Submit"
                                                onClick={props.onApprove}
                                                disabled={(props.data.approved === undefined) ? true : undefined}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAPaperWithLessPadding>
                            </LAGridItem>
                            {sortedSections.map((x, index) => (
                                <LAGridItem sm={12} md={6} key={index} className="table-border">
                                    <div className="head-sections">
                                        <strong className="head-title">{x.name.toUpperCase()}</strong>
                                        <strong className="head-order">Order : {x.display_Order != null ? x.display_Order : '99'}</strong>
                                    </div>
                                    <hr />

                                    <Table size="small" aria-label={index.toString()}>
                                        <TableHead className="header-row">
                                            <TableRow>
                                                <TableCell className="title">Items</TableCell>
                                                <TableCell className="title">Completed</TableCell>
                                                <TableCell className="title">Comments</TableCell>
                                                <TableCell align="center" className="title">Pictures</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {x.items.map((q, ind) => {
                                                const subUrls = q.pictures !== null ? q.pictures.split(",") : [];
                                                const onCommentChange = (name: string, value: string) => props.onSectionItemChange(name, value, index, ind);
                                                const onCompletedChange = (event: unknown, value: IIdName) => props.onSectionItemChange("value", value ? value.name : "", index, ind)
                                                return (
                                                    <TableRow key={ind} className={ind % 2 ? "odd-row" : ""}>
                                                        <TableCell>
                                                            <strong>
                                                                {q.name}
                                                            </strong>
                                                        </TableCell>

                                                        <TableCell>
                                                            {/* {q.value === "Yes" &&
                                                                <span className="completedCSS"><strong>
                                                                    {q.value}
                                                                </strong> </span>}

                                                            {q.value === "No" &&
                                                                <span className="notcompletedCSS"><strong>
                                                                    {q.value}
                                                                </strong> </span>
                                                            }

                                                            {q.value === "NA" &&
                                                                <strong>
                                                                    {q.value}
                                                                </strong>}

                                                            {q.value !== "Yes" && q.value !== "No" && q.value !== "NA" &&
                                                                <strong>
                                                                    {q.value}
                                                                </strong>} */}
                                                            <LAAutoComplete
                                                                disabled={false}
                                                                multiple={false}
                                                                option={completedOptions}
                                                                getOptionLabel="name"
                                                                autoHighlight={true}
                                                                indexPosition={ind}
                                                                onChange={onCompletedChange}
                                                                filterSelectedOptions={true}
                                                                dropDownPlaceHolder="Completed"
                                                                selectionRemove={undefinedFunction}
                                                                value={q.value ? completedOptions.find(x => x.name === q.value) : null}
                                                                defaultValue={q.value ? completedOptions.find(x => x.name === q.value) : null}
                                                            />
                                                        </TableCell>

                                                        <TableCell>
                                                            {/* <strong>
                                                                {q.comments}
                                                            </strong> */}
                                                            <LATextField
                                                                id="Comments"
                                                                value={q.comments ?? ""}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="comments"
                                                                indexPosition={ind}
                                                                label="comments"
                                                                onChange={onCommentChange}
                                                                type="string"
                                                                disabled={false}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {subUrls.map((e, subLinkIndex) => {
                                                                const imgId = e.split(";");
                                                                // const handleSubLinkClick = () => getSubItemImage(q.id, e);
                                                                const handleSubLinkClick = () => {
                                                                    window.open(
                                                                        webConfig.imgApiBaseUrl + imgId[0],
                                                                        "_blank"
                                                                    );
                                                                };
                                                                return (
                                                                    <LALinkButton
                                                                        label={e.indexOf(";") > 0 ? e.split(";")[1] : e}
                                                                        className="pl-2"
                                                                        key={subLinkIndex}
                                                                        onClick={handleSubLinkClick}
                                                                    />
                                                                );
                                                            })}
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}

                                        </TableBody>
                                    </Table>
                                </LAGridItem>
                            ))}

                            {(urls.length > 0) && <LAGridItem sm={12} md={6} className="table-border">
                                <strong className="head-title">PICTURES</strong>
                                <hr />

                                {urls.map((e, linkIndex) => {
                                    const imgId = e.split(";");
                                    return (
                                        <LAThumbnailWithLink
                                            className="thumbnail-img"
                                            alt={e.indexOf(";") > 0 ? e.split(";")[1] : e}
                                            key={linkIndex}
                                            url={webConfig.imgApiBaseUrl + imgId[0]}
                                        />
                                    );
                                })}

                            </LAGridItem>}

                            <LAGridItem xs={4} className="text-center">
                                <></>
                            </LAGridItem>

                            <LAGridItem xs={4} className="text-center">
                                <LATextArea
                                    minRows={2}
                                    rowsMax={2}
                                    fullWidth={true}
                                    name="comments"
                                    label="Comments"
                                    onChange={props.onChange}
                                    value={props.data.comments}
                                />
                            </LAGridItem>

                            <LAGridItem xs={4}>
                                <></>
                            </LAGridItem>

                        </LAGrid>
                    </LAGridItem>

                </LAGrid>
            </ItemsStyles>
        </LAPopover>
    );
});