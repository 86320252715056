import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_HAZARD_NEW_REQUEST, IAddHazardNewRequest } from "./addHazardNewConstants";

export interface IAddHazardNewLoadAction {
    type: IADD_HAZARD_NEW_REQUEST.REQUEST;
    data: IAddHazardNewRequest
}
export const addHazardNewLoadAction = (data: IAddHazardNewRequest): IAddHazardNewLoadAction => ({
    type: IADD_HAZARD_NEW_REQUEST.REQUEST,
    data
});
export interface IAddHazardNewSuccessAction {
    type: IADD_HAZARD_NEW_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addHazardNewLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddHazardNewSuccessAction => ({
    type: IADD_HAZARD_NEW_REQUEST.SUCCESS,
    message
});
export interface IAddHazardNewLoadFailedAction {
    type: IADD_HAZARD_NEW_REQUEST.FAILED;
    message: string;
}
export const addHazardNewLoadFailedAction = (message: string): IAddHazardNewLoadFailedAction => ({
    type: IADD_HAZARD_NEW_REQUEST.FAILED,
    message
});
