import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_MECHANIC_LOGS_REQUEST, IMechanicLogs } from "./getMechanicLogsConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetMechanicLogsLoadAction, IGetMechanicLogsLoadFailedAction, IGetMechanicLogsSuccessAction } from "./getMechanicLogsActions";

type Actions =
    | IGetMechanicLogsLoadAction
    | IGetMechanicLogsSuccessAction
    | IGetMechanicLogsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetMechanicLogsReducer = (state: Server<SurewayAPIResponse<IMechanicLogs[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IMechanicLogs[]>> => {
    switch (action.type) {
        case IGET_MECHANIC_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANIC_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANIC_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};