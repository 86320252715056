import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_COST_CODES_BY_JOB_ID_REQUEST, IGetCostCodesByJobID } from "./getCostCodesByJobIDConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetCostCodesByJobIDLoadAction, IGetCostCodesByJobIDLoadFailedAction, IGetCostCodesByJobIDSuccessAction, 
    getCostCodesByJobIDLoadFailedAction, getCostCodesByJobIDLoadSuccessAction } from "./getCostCodesByJobIDActions";

export const getCostCodesByJobIDEpic: Epic = (
    action$: ActionsObservable<IGetCostCodesByJobIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetCostCodesByJobIDSuccessAction | IGetCostCodesByJobIDLoadFailedAction> =>
    action$.ofType(IGET_COST_CODES_BY_JOB_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetCostCodesByJobID[]>>(
                    END_POINTS.FIELD.VENDOR.GET_COST_CODES_BY_JOB_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetCostCodesByJobID[]>): IGetCostCodesByJobIDSuccessAction => {
                            return getCostCodesByJobIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getCostCodesByJobIDLoadFailedAction(response.message)))
                    )
            )
        );