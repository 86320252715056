import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPEATER_KIT_REQUEST, IRepeaterKit } from "./getRepeaterKitsConstants";
import { IGetRepeaterKitLoadAction, IGetRepeaterKitLoadFailedAction, IGetRepeaterKitSuccessAction, getRepeaterKitLoadFailedAction, getRepeaterKitLoadSuccessAction } from "./getRepeaterKitsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getRepeaterKitEpic: Epic = (
    action$: ActionsObservable<IGetRepeaterKitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepeaterKitSuccessAction | IGetRepeaterKitLoadFailedAction> =>
    action$.ofType(IGET_REPEATER_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IRepeaterKit>>>(
                    END_POINTS.SURVEY.GET_REPEATER_KITS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IRepeaterKit>>): IGetRepeaterKitSuccessAction => {
                            return getRepeaterKitLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepeaterKitLoadFailedAction(response.message)))
                    )
            )
        );