import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_COST_CODES_BY_JOB_ID_REQUEST, IGetCostCodesByJobID } from "./getCostCodesByJobIDConstants";
import { IGetCostCodesByJobIDLoadAction, IGetCostCodesByJobIDLoadFailedAction, IGetCostCodesByJobIDSuccessAction } from "./getCostCodesByJobIDActions";

type Actions =
    | IGetCostCodesByJobIDLoadAction
    | IGetCostCodesByJobIDSuccessAction
    | IGetCostCodesByJobIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetCostCodesByJobIDReducer = (state: Server<SurewayAPIResponse<IGetCostCodesByJobID[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetCostCodesByJobID[]>> => {
    switch (action.type) {
        case IGET_COST_CODES_BY_JOB_ID_REQUEST.REQUEST:
            return loading;

        case IGET_COST_CODES_BY_JOB_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_COST_CODES_BY_JOB_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};