import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_JOBS_PITS_REQUEST, IJobPit, IJobsPitsRequest } from "./getJobsPitsConstants";


export interface IGetJobsPitsLoadAction {
    type: IGET_JOBS_PITS_REQUEST.REQUEST;
    data: IJobsPitsRequest
}
export const getJobsPitsLoadAction = (data: IJobsPitsRequest): IGetJobsPitsLoadAction => ({
    type: IGET_JOBS_PITS_REQUEST.REQUEST,
    data
});
export interface IGetJobsPitsSuccessAction {
    type: IGET_JOBS_PITS_REQUEST.SUCCESS;
    data: SurewayAPIResponse<ById<IJobPit>>;
}
export const getJobsPitsLoadSuccessAction = (data: SurewayAPIResponse<ById<IJobPit>>): IGetJobsPitsSuccessAction => ({
    type: IGET_JOBS_PITS_REQUEST.SUCCESS,
    data
});
export interface IGetJobsPitsLoadFailedAction {
    type: IGET_JOBS_PITS_REQUEST.FAILED;
    message: string;
}
export const getJobsPitsLoadFailedAction = (message: string): IGetJobsPitsLoadFailedAction => ({
    type: IGET_JOBS_PITS_REQUEST.FAILED,
    message
});
