
export enum IADD_BASE_KIT_REQUEST {
    REQUEST = "addBaseKit/ADD_BASE_KIT_REQUEST",
    SUCCESS = "addBaseKit/ADD_BASE_KIT_SUCCESS",
    FAILED = "addBaseKit/ADD_BASE_KIT_FAILED"
};

export interface IAddUpdateBaseKitRequest {
    token: string;
    request: IAddUpdateBaseKit;
};

export interface IAddUpdateBaseKit {
    ID: number;
    Kit_No: string;
    Unit_No: string;
    Model: string;
    GNSS_Antenna: string;
    Radio_Antenna_Parts: string;
    Cables: string;
    Tribrach_Puck: string;
    // In_Out: string;
    Status: string;
    // Site: string;
    WSS_ID: number | null;
    Channel: string;
    Notes: string;
    Created_By: string;
    Modified_By: string;
};