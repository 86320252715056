import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_FILTER_CUT_REQUEST } from "./updateFilterCutConstants";
import { IUpdateFilterCutLoadAction, IUpdateFilterCutLoadFailedAction, IUpdateFilterCutSuccessAction, updateFilterCutLoadFailedAction, updateFilterCutLoadSuccessAction } from "./updateFilterCutActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateFilterCutEpic: Epic = (
    action$: ActionsObservable<IUpdateFilterCutLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateFilterCutSuccessAction | IUpdateFilterCutLoadFailedAction> =>
    action$.ofType(IUPDATE_FILTER_CUT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FILTER_CUTS.UPDATE_FILTER_CUT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateFilterCutSuccessAction | IUpdateFilterCutLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateFilterCutLoadSuccessAction(response);
                            } else {
                                return updateFilterCutLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateFilterCutLoadFailedAction(response.message)))
                    )
            )
        );