import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST, IUpdateWorkingSiteLogOnCallRequest } from "./updateWorkingSiteLogOnCallConstants";


export interface IUpdateWorkingSiteLogOnCallLoadAction {
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.REQUEST;
    data: IUpdateWorkingSiteLogOnCallRequest
}
export const updateWorkingSiteLogOnCallLoadAction = (data: IUpdateWorkingSiteLogOnCallRequest): IUpdateWorkingSiteLogOnCallLoadAction => ({
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.REQUEST,
    data
});
export interface IUpdateWorkingSiteLogOnCallSuccessAction {
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateWorkingSiteLogOnCallLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateWorkingSiteLogOnCallSuccessAction => ({
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.SUCCESS,
    message
});
export interface IUpdateWorkingSiteLogOnCallLoadFailedAction {
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.FAILED;
    message: string;
}
export const updateWorkingSiteLogOnCallLoadFailedAction = (message: string): IUpdateWorkingSiteLogOnCallLoadFailedAction => ({
    type: IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.FAILED,
    message
});
