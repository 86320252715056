
export enum IGET_FORMS_REQUEST {
    REQUEST = "getForms/GET_FORMS_REQUEST",
    SUCCESS = "getForms/GET_FORMS_SUCCESS",
    FAILED = "getForms/GET_FORMS_FAILED"
};

export interface IFormsRequest {
    token: string;
};

export interface IChecklistForm {
    id: number;
    form_Name: string;
    type: string;
    active: string;
    wsS_ID?: number;
    all_Sites?: string;
    wsS_IDS?: string;
    foreman: string;
    employees: string;
    frequency: string;
    start_Date: string;
    expiry_Date: string;
    flagged_Notification_Contacts: string;
    flagged_Notification_Method: string;
    hazard_Assessment:string;
    requires_Crew:string;
    requires_Guest:string;
    requires_Toolbox:string;
    sections: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    departments: string;
    delete_Questions?: boolean;
    questions: IChecklistQuestion[];
    version?: string;
    message: string;
    files: IFormTemplateFile[];
    attachments?: string;
};

export interface IChecklistQuestion {
    id: number;
    form_ID: number;
    question: string;
    type: string;
    options: string;
    flagged_Options: string;
    section: string;
    display_Order: number;
    required: string;
    image_Required: string;
    attachments: string;
    images: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    pictures: IChecklistQuestionPicture[];
    files: IChecklistQuestionFile[];
};

export interface IChecklistQuestionFile {
    name: string;
    base64String: string;
};

export interface IFormTemplateFile {
    name: string;
    base64String: string;
};

export interface IChecklistQuestionPicture {
    name: string;
    description: string;
    base64String: string | null;
};
