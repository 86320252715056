import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetCompaniesRequest, IGetCompaniesResponse, IGET_COMPANIES_REQUEST } from "./getCompaniesConstants";

export interface IGetCompaniesLoadAction {
    type: IGET_COMPANIES_REQUEST.REQUEST;
    data: IGetCompaniesRequest;
}
export const getCompaniesLoadAction = (data: IGetCompaniesRequest): IGetCompaniesLoadAction => ({
    type: IGET_COMPANIES_REQUEST.REQUEST,
    data
});
export interface IGetCompaniesSuccessAction {
    type: IGET_COMPANIES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetCompaniesResponse[]>;
}
export const getCompaniesLoadSuccessAction = (list: SurewayAPIResponse<IGetCompaniesResponse[]>): IGetCompaniesSuccessAction => ({
    type: IGET_COMPANIES_REQUEST.SUCCESS,
    list
});
export interface IGetCompaniesLoadFailedAction {
    type: IGET_COMPANIES_REQUEST.FAILED;
    message: string;
}
export const getCompaniesLoadFailedAction = (message: string): IGetCompaniesLoadFailedAction => ({
    type: IGET_COMPANIES_REQUEST.FAILED,
    message
});
