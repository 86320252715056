import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_JOURNELS_REQUEST {
    REQUEST = "getJournels/GET_JOURNELS_REQUEST",
    SUCCESS = "getJournels/GET_JOURNELS_SUCCESS",
    FAILED = "getJournels/GET_JOURNELS_FAILED"
};

export interface IGetJournelsRequest extends ISurewayTokenRequestBody {

};

export interface IJournels {
    id: number;
    date: string;
    wsS_ID: number;
    name: string;
    work: string;
    description: string;
    images?: string;
    pictures: IJournelPicture[];
    display_Name?: string;
    site?: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

export interface IJournelPicture {
    name: string;
    description: string;
    base64String: string | null;
    timestamp?: string;
    default?: string | null;
};