import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FILTER_REQUEST, IFilter } from "./getFilterConstants";
import { IGetFilterLoadAction, IGetFilterLoadFailedAction, IGetFilterSuccessAction } from "./getFilterActions";
import { IAddFilterSuccessAction } from "../addFilter/addFilterActions";
import { IUpdateFilterSuccessAction } from "../updateFilter/updateFilterActions";
import { IUPDATE_FILTER_REQUEST } from "../updateFilter/updateFilterConstants";
import { IADD_FILTER_REQUEST } from "../addFilter/addFilterConstants";

type Actions =
    | IGetFilterLoadAction
    | IGetFilterSuccessAction
    | IUpdateFilterSuccessAction
    | IAddFilterSuccessAction
    | IGetFilterLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFilterReducer = (state: Server<SurewayAPIResponse<ById<IFilter>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IFilter>>> => {
    switch (action.type) {
        case IGET_FILTER_REQUEST.REQUEST:
            return loading;

        case IGET_FILTER_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FILTER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_FILTER_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FILTER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};