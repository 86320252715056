import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_EQUIPMENT_MANUALS_REQUEST, IEquipmentManual } from "./getEquipmentManualsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetEquipmentManualsLoadAction, IGetEquipmentManualsLoadFailedAction, IGetEquipmentManualsSuccessAction } from "./getEquipmentManualsActions";
import { IAddEquipmentManualSuccessAction } from "../addEquipmentManual/addEquipmentManualActions";
import { IUpdateEquipmentManualSuccessAction } from "../updateEquipmentManual/updateEquipmentManualActions";
import { IADD_EQUIPMENT_MANUAL_REQUEST } from "../addEquipmentManual/addEquipmentManualConstants";
import { IUPDATE_EQUIPMENT_MANUAL_REQUEST } from "../updateEquipmentManual/updateEquipmentManualConstants";

type Actions =
    | IGetEquipmentManualsLoadAction
    | IGetEquipmentManualsSuccessAction
    | IGetEquipmentManualsLoadFailedAction
    | IAddEquipmentManualSuccessAction
    | IUpdateEquipmentManualSuccessAction
    | IFlushDataSuccessAction;

export const GetEquipmentManualsReducer = (state: Server<SurewayAPIResponse<ById<IEquipmentManual>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IEquipmentManual>>> => {
    switch (action.type) {
        case IGET_EQUIPMENT_MANUALS_REQUEST.REQUEST:
            return loading;

        case IGET_EQUIPMENT_MANUALS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_EQUIPMENT_MANUALS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_EQUIPMENT_MANUAL_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_EQUIPMENT_MANUAL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};