import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { BLACK_COLOR, EXTRA_LIGHT_BLUE_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, LIGHT_ORANGE_COLOR, MEDIA_QUERY_PHONE, PURPLE_COLOR, SECONDARY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { checkFileNameDuplication, deleteText, fileToBase64Image, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { AddIcon, ArrowLeftIcon, DeleteIcon, DownloadIcon } from "../../shared/icons";
import { getFormsLoadAction } from "../../../redux/field/getForms/getFormsActions";
import { IChecklistForm, IChecklistQuestion, IChecklistQuestionFile, IChecklistQuestionPicture, IFormsRequest, IFormTemplateFile } from "../../../redux/field/getForms/getFormsConstants";
import { IDepartments, IFormEmployee, IFormForeman, IFormLookup, IFormLookupRequest, IFormWSS } from "../../../redux/field/getFormLookup/getFormLookupConstants";
import { getFormLookup } from "../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getForms } from "../../../redux/field/getForms/getFormsAccessor";
import { getFormLookupLoadAction } from "../../../redux/field/getFormLookup/getFormLookupActions";
import { LAPopover } from "../../shared/popOver";
import LADatePicker from "../../shared/datePicker";
import { LADropZone } from "../../shared/dropZone";
import { addFormTemplate } from "../../../redux/field/addFormTemplate/addFormTemplateAccessor";
import { updateFormTemplate } from "../../../redux/field/updateFormTemplate/updateFormTemplateAccessor";
import { updateFormTemplateLoadAction } from "../../../redux/field/updateFormTemplate/updateFormTemplateActions";
import { addFormTemplateLoadAction } from "../../../redux/field/addFormTemplate/addFormTemplateActions";
import { IAddFormTemplateRequest, IAddUpdateQuestion } from "../../../redux/field/addFormTemplate/addFormTemplateConstants";
import { IUpdateFormTemplateRequest } from "../../../redux/field/updateFormTemplate/updateFormTemplateConstants";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import { v4 as uuidv4 } from "uuid";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import LAFreeTextAutoComplete from "../../shared/freeTextAutoComplete";
import { END_POINTS } from "../../../redux/endpoints";
import { LACheckBox } from "../../shared/checkBox";
import LATextArea from "../../shared/textArea";
import { FormHelperText } from "@material-ui/core";
import splitDate from "../../shared/splitDate";
import { LACenteredLoading } from "../../shared/loading";
import { Format } from "devextreme-react/data-grid";
import LALinkButton from "../../shared/linkButton";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import { getFormVersionHistory } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";
import { FormVersionHistoryPopup } from "./formVersionHistoryPopup";


const RequiredFields: string[] = ["form_Name", "type" ];
const NotificationMethodOptions: IIdName[] = [{ id: 0, name: "Email" }, { id: 1, name: "SMS" }, { id: 2, name: "Both" }];

const FrequencyOptions: IIdName[] = [{ id: 0, name: "Shift" }, { id: 1, name: "Daily" }, { id: 2, name: "Weekly" }, { id: 3, name: "Monthly" }];

const TypeList: IIdName[] = [{ id: 0, name: "General" }, { id: 1, name: "Safety" }, { id: 2, name: "Inspection" }, { id: 3, name: "Acknowledge" },
    { id: 4, name: "Other" }, { id: 5, name: "Misc" }];
    
const FieldTypeOptions: IIdName[] = [{ id: 0, name: "Free Text" }, { id: 1, name: "Checkbox" }, { id: 2, name: "Dropdown" },{ id: 3, name: "Yes/No/NA" }, 
    { id: 4, name: "Pass/Fail/NA" }, { id: 5, name: "Date" }, { id: 6, name: "Datetime" }, { id: 7, name: "Sureway Employees" }, { id: 8, name: "Field Employees" }];
const FlaggedOptionsForYESNoNAType: IIdName[] = [{ id: 0, name: "Yes" }, { id: 1, name: "No" }, { id: 2, name: "NA" }];
const FlaggedOptionsForPassFailNAType: IIdName[] = [{ id: 0, name: "Pass" }, { id: 1, name: "Fail" }, { id: 2, name: "NA" }];

interface IAddUpdateFormStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    addRequestStatus: Server<SurewayAPIResponse<string>>;
    updateRequestStatus: Server<SurewayAPIResponse<string>>;
    getFormTemplates: Server<SurewayAPIResponse<IChecklistForm[]>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
};

interface IAddUpdateFormDispatchProps {
    getFormsRequest: (data: IFormsRequest) => unknown;
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    addRequest: (data: IAddFormTemplateRequest) => unknown;
    updateRequest: (data: IUpdateFormTemplateRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
};

interface IAddUpdateFormOwnProps {
    id?: string;
};

interface IAddUpdateFormState {
    data: IChecklistForm;
    questionsExpand: boolean;
    documentsExpand: boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
    formVersionHistoryPopup: boolean;
    formQuestionsVersionHistoryPopup: boolean;
    // optionPopup: { index: number, options: string } | undefined;
};

const AddUpdateFormStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };

    .pull-right {
        position: absolute;
        right: 3%;
        height: 3%;
    };

    .flex-end {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 3%;
    };

    .even-row {
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    }
    
    .odd-row {
        background-color: ${WHITE_COLOR};
        border: 1px solid ${BLACK_COLOR};
    };

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .table-row {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .weather-container {
        text-align: center;
        color: white;
    }

    .weather-row {
        display: flex;
        justify-content: space-around;
    }

    .temp-text {
        font-size: 2em;
        color: black;
    }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

`;

type IAddUpdateFormProps =
    RouteComponentProps
    & IAddUpdateFormOwnProps
    & IAddUpdateFormStoreProps
    & IAddUpdateFormDispatchProps;

class AddUpdateForm extends PureComponent<IAddUpdateFormProps, IAddUpdateFormState> {

    public constructor(props: IAddUpdateFormProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                form_Name: "",
                active: "Yes",
                type: "",
                foreman: "",
                departments: "",
                employees: "",
                frequency: "",
                start_Date: "",
                expiry_Date: "",
                flagged_Notification_Contacts: "",
                flagged_Notification_Method: "",
                hazard_Assessment: "No",
                requires_Crew: "No",
                requires_Guest: "No",
                requires_Toolbox: "No",
                sections: "",
                message: "",
                questions: [],
                files: [],
                created: "",
                created_By: userName,
                modified: "",
                modified_By: userName,
            },
            errors: {},
            questionsExpand: true,
            documentsExpand: true,
            serverError: "",
            formVersionHistoryPopup: false,
            formQuestionsVersionHistoryPopup: false
            // optionPopup: undefined,
        };
    }

    public componentDidMount(): void {
        this.getLookups();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateFormProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addRequestStatus !== prevProps.addRequestStatus) {

                if (this.props.addRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRequestStatus.message });

                if (hasPayload(this.props.addRequestStatus) && this.props.addRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }

            if (this.props.updateRequestStatus !== prevProps.updateRequestStatus) {

                if (this.props.updateRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRequestStatus.message });

                if (hasPayload(this.props.updateRequestStatus) && this.props.updateRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, documentsExpand, questionsExpand, formVersionHistoryPopup, formQuestionsVersionHistoryPopup} = this.state;
        const { token, addRequestStatus, updateRequestStatus, getLookup, getFormVersionHistory } = this.props;
        const getRole = pageAccessCheck(token, "formsAdmin");
        // console.log(getRole)
        const disabled = (getRole === ReadOnly) ? true : undefined;
        const disabledSave = data.questions?.some(x => x.question === "" || x.question === null);
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];
        // const disabled = undefined;
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];
        const departments = hasPayload(getLookup) ? getLookup.payload.response.departments : [];
        const employees = hasPayload(getLookup) ? getLookup.payload.response.employeeList : [];

        const wssList = hasPayload(getLookup) ? getLookup.payload.response.wssList : [];

        const onType = (event: unknown, value: IIdName): void => this.handleChange("type", value !== null ? value.name : data.type);

        const onNotificationMethod = (event: unknown, value: IIdName): void => this.handleChange("flagged_Notification_Method", value !== null ? value.name : data.flagged_Notification_Method);

        const onFrequency = (event: unknown, value: IIdName): void => this.handleChange("frequency", value !== null ? value.name : data.frequency);
        const onHazardAssessment = (checked: boolean): void => this.handleChange("hazard_Assessment", checked ? "Yes" : "No");
        const onCrewSection = (checked: boolean): void => this.handleChange("requires_Crew", checked ? "Yes" : "No");
        const onGuestSection = (checked: boolean): void => this.handleChange("requires_Guest", checked ? "Yes" : "No");
        const onToolBox = (checked: boolean): void => this.handleChange("requires_Toolbox", checked ? "Yes" : "No");
        const onAllSites = (checked: boolean): void => this.handleChange("all_Sites", checked ? "Yes" : "No");
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");

        const onWSS = (value: number): void => this.handleChange("wsS_ID", value.toString());

        // const popupData = optionPopup ? optionPopup.options.split(",") : [];

        // const onDate = (date: string): void => this.handleDate("start_Date", new Date(date).toDateString());
        // const onDateExpiry = (date: string): void => this.handleDate("expiry_Date", new Date(date).toDateString());
        const sectionDropdownOptions = this.generateDropdownOptions(data.sections);
        // console.log(data)

        if(getLookup.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <AddUpdateFormStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={6}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                            {data.id > 0 && <strong className="pull-right">Version: {data.version}</strong>}
                        </LAGridItem>

                        {data.id > 0 && <LAGridItem xs={12} sm={12} md={3}>
                            <LALinkButton onClick={() => this.handleFormVersionHistory(data, "Template")} label="Version History"></LALinkButton>
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}FORM TEMPLATE</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Form Name</strong>
                            <LATextField
                                name="form_Name"
                                label=""
                                fullWidth={true}
                                disabled={disabled || data.id > 0}
                                variant="outlined"
                                onChange={this.handleChange}
                                value={data.form_Name ?? ""}
                                errorText={errors["form_Name"] ? errors["form_Name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Type</strong>
                            <LAAutoComplete
                                multiple={false}
                                option={TypeList}
                                disabled={disabled || data.id > 0}
                                onChange={onType}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder=""
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                errorText={errors["type"] ? errors["type"].message : undefined}
                                value={data.type && TypeList ? TypeList.find(q => q.name === data.type) : null}
                                defaultValue={data.type && TypeList ? TypeList.find(q => q.name === data.type) : null}
                            />
                        </LAGridItem>
                        
                        <LAGridItem xs={12} sm={6} md={3} className="mt-3">
                            <LACheckBox
                                label="Active"
                                onChange={onActive}
                                disabled={disabled}
                                value={data.active === "Yes" ? true : false}
                            />
                        </LAGridItem>

                        {data.type === "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Message</strong>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                fullWidth={true}
                                name="message"
                                label=""
                                disabled={disabled}
                                value={data.message ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["message"] ? errors["message"].message : undefined}
                            />
                        </LAGridItem>}

                        {data.type === "Acknowledge" && 
                            <LAGridItem xs={12} sm={6} md={2} className="mt-3">
                                <LACheckBox
                                    label="All Sites"
                                    onChange={onAllSites}
                                    disabled={disabled}
                                    value={data.all_Sites === "Yes" ? true : false}
                                />
                                <FormHelperText className="required-text">Active Sites with at least one log added by Foreman in the last 5 days</FormHelperText>
                            </LAGridItem>
                        }

                        {data.type === "Acknowledge" && <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Working Sites</strong>
                            <DevExtremePopupLookup
                                data={wssList}
                                multiple={true}
                                id="working_sites-search"
                                placeHolder=""
                                disabled={disabled || data.all_Sites === "Yes"}
                                displayExp="site"
                                // returnExp="wsS_ID"
                                name="wsS_IDS"
                                errorName="wsS_IDS"
                                onSelectionRemove={this.handleWorkingSiteRemove}
                                errors={errors}
                                selectionRow={true}
                                onSelectionChanged={this.handleWorkingSitesClick}
                                currentVal={this.getWorkingSites(data.wsS_IDS ?? "")}
                                onClick={undefinedFunction}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                    { name: "site_Address", caption: "Site Address", type: "string" },
                                    { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                ]}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Working Site</strong>
                            <DevExtremePopupLookup
                                data={wssList}
                                id="wss-search"
                                placeHolder=""
                                disabled={disabled}
                                displayExp="site"
                                name="wsS_ID"
                                returnExp="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                currentVal={data.wsS_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                    { name: "site_Address", caption: "Site Address", type: "string" },
                                    { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                ]}
                            />
                        </LAGridItem>}


                        {data.type !== "Acknowledge" &&<LAGridItem xs={12} sm={6} md={3}>
                            <strong>Departments</strong>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={true}
                                option={departments}
                                autoHighlight={true}
                                onChange={this.handleSelectDepartments}
                                filterSelectedOptions={true}
                                getOptionLabel="department"
                                dropDownPlaceHolder=""
                                selectionRemove={this.handleDepartmentsRemove}
                                value={this.getSelectedDepartments(data.departments)}
                                defaultValue={this.getSelectedDepartments(data.departments)}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Foreman</strong>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={true}
                                option={foremans}
                                autoHighlight={true}
                                onChange={this.handleSelectFormans}
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                dropDownPlaceHolder=""
                                selectionRemove={this.handleFormansRemove}
                                value={this.getSelectedFormans(data.foreman)}
                                defaultValue={this.getSelectedFormans(data.foreman)}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Employees</strong>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={true}
                                option={employees}
                                autoHighlight={true}
                                onChange={this.handleSelectEmployee}
                                filterSelectedOptions={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder=""
                                selectionRemove={this.handleEmployeeRemove}
                                value={this.getSelectedEmployees(data.employees)}
                                defaultValue={this.getSelectedEmployees(data.employees)}
                            />
                        </LAGridItem>}

                        

                        <LAGridItem xs={12} sm={6} md={3} >
                            <strong>Start Date</strong>
                            <LATextField
                                id="start_Date"
                                value={data.start_Date ? splitDate(data.start_Date) : ""}
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="start_Date"
                                label=""
                                errorText={errors["start_Date"] ? errors["start_Date"].message : undefined}
                                onChange={this.handleChange}
                                type="date"
                            />
                            {/* <LADatePicker
                                id="date"
                                mode="single"
                                value={data.start_Date}
                                onChange={onDate}
                            /> */}
                            {/* {errors["start_Date"] && <FormHelperText className="required-text">{errors["start_Date"].message}</FormHelperText>} */}
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3} >
                            <strong>Expiry Date</strong>
                            <LATextField
                                id="expiry_Date"
                                value={data.expiry_Date ?splitDate(data.expiry_Date) : ""}
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="expiry_Date"
                                label=""
                                errorText={errors["expiry_Date"] ? errors["expiry_Date"].message : undefined}
                                onChange={this.handleChange}
                                type="date"
                            />
                            {/* <LADatePicker
                                id="date"
                                mode="single"
                                value={data.expiry_Date}
                                onChange={onDateExpiry}
                            />
                            {errors["expiry_Date"] && <FormHelperText className="required-text">{errors["expiry_Date"].message}</FormHelperText>} */}
                        </LAGridItem>

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Notification Method</strong>
                            <LAAutoComplete
                                multiple={false}
                                option={NotificationMethodOptions}
                                disabled={disabled}
                                onChange={onNotificationMethod}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder=""
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                value={data.flagged_Notification_Method && NotificationMethodOptions
                                    ? NotificationMethodOptions.find(q => q.name === data.flagged_Notification_Method) : null}
                                defaultValue={data.flagged_Notification_Method && NotificationMethodOptions
                                    ? NotificationMethodOptions.find(q => q.name === data.flagged_Notification_Method) : null}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Flagged Notification Contacts</strong>
                            <LAAutoComplete
                                disabled={disabled}
                                option={foremans}
                                autoHighlight={true}
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                dropDownPlaceHolder=""
                                multiple={true}
                                onChange={this.handleSelectNotifyContacts}
                                selectionRemove={this.handleNotifyContactsRemove}
                                value={this.getSelectedFormans(data.flagged_Notification_Contacts)}
                                defaultValue={this.getSelectedFormans(data.flagged_Notification_Contacts)}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Frequency</strong>
                            <LAAutoComplete
                                multiple={false}
                                option={FrequencyOptions}
                                disabled={disabled}
                                onChange={onFrequency}
                                autoHighlight={true}
                                getOptionLabel="name"
                                dropDownPlaceHolder=""
                                filterSelectedOptions={true}
                                selectionRemove={undefinedFunction}
                                value={data.frequency && FrequencyOptions ? FrequencyOptions.find(q => q.name === data.frequency) : null}
                                defaultValue={data.frequency && FrequencyOptions ? FrequencyOptions.find(q => q.name === data.frequency) : null}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={3}>
                            <strong>Sections</strong>
                            <LAFreeTextAutoComplete 
                                value={this.getOptions(data.sections)} 
                                onChange={this.handleSections} 
                                label="" 
                                disabled={disabled}
                                autoHighlight={true}
                                placeholder="Type and press Enter"
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={2} className="mt-3">
                            <LACheckBox
                                label="Hazard Assessment"
                                onChange={onHazardAssessment}
                                disabled={disabled}
                                value={data.hazard_Assessment === "Yes" ? true : false}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={2} className="mt-3">
                            <LACheckBox
                                label="Crew Section"
                                onChange={onCrewSection}
                                disabled={disabled}
                                value={data.requires_Crew === "Yes" ? true : false}
                            />
                        </LAGridItem>}
                        
                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={2} className="mt-3">
                            <LACheckBox
                                label="Guest Section"
                                onChange={onGuestSection}
                                disabled={disabled}
                                value={data.requires_Guest === "Yes" ? true : false}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12} sm={6} md={2} className="mt-3">
                            <LACheckBox
                                label="Tool Box"
                                onChange={onToolBox}
                                disabled={disabled}
                                value={data.requires_Toolbox === "Yes" ? true : false}
                            />
                        </LAGridItem>}

                        {data.type !== "Acknowledge" && <LAGridItem xs={12}  >
                            {data.id > 0 && <>
                                <LALinkButton className="flex-end" onClick={() => this.handleFormVersionHistory(data, "Questions")} label="Version History - Questions"></LALinkButton>
                                <br /></>
                            }
                            <LAExpansionPanel className="mt-3" color={GREEN_COLOR} textColor={WHITE_COLOR} label="Questions" expanded={questionsExpand} onClick={() => this.handleSectionExpand("questionsExpand")}> 
                            {/* <LAPaperWithLessPadding> */}
                                <LAGrid>

                                    {/* <LAGridItem xs={12} className="text-center">
                                        <strong>QUESTIONS</strong>
                                        <hr />
                                    </LAGridItem> */}

                                    <LAGridItem xs={12}>
                                        <LAIconButton
                                            icon={<AddIcon />}
                                            label="Add New Question"
                                            onClick={this.onAddQuestion}
                                        />
                                    </LAGridItem>

                                    {(data.questions.length === 0) && <LAGridItem xs={12} className="text-center">
                                        <LAErrorBox text="Please add at least one question before save" />
                                    </LAGridItem>}

                                    {data.questions.map((x, idx) => {

                                        const onDelete = () => this.handleQuestionDelete(idx);
                                        const onOptions = (options: string[]) => this.handleOptions(options, idx)
                                        // const onAddTypes = () => this.onAddTypes(idx, x.options);
                                        const onFieldTypeChange = (event: unknown, value: IIdName) => this.handleQuestionChange("type", value !== null ? value.name : x.type, idx);
                                        const onFlaggedOptionsChange = (event: unknown, value: IIdName) => this.handleQuestionChange("flagged_Options", value !== null ? value.name : x.flagged_Options, idx);
                                        const onSectionChange = (event: unknown, value: IIdName) => this.handleQuestionChange("section", value !== null ? value.name : "", idx);
                                        const ondisplayOrderChange = (name: string, value: any) => this.handleQuestionChange("display_Order", value ? value : "", idx);
                                        const onRequired = (checked: boolean): void => this.handleQuestionChange("required", checked ? "Yes" : "No", idx);
                                        const onImageRequired = (checked: boolean): void => this.handleQuestionChange("image_Required", checked ? "Yes" : "No", idx);
                                        const flaggedOptionsDropdownOptions = x.type === "Yes/No/NA" ? FlaggedOptionsForYESNoNAType : 
                                                                              x.type === "Pass/Fail/NA" ? FlaggedOptionsForPassFailNAType : 
                                                                              (x.type === "Checkbox" || x.type === "Dropdown") ? this.generateDropdownOptions(x.options) : 
                                                                              [];
                                        
                                        return (
                                            <LAGridItem xs={12} key={idx}>
                                                <LAPaperWithLessPadding className={idx % 2 ? "odd-row" : "even-row"} key={idx}>
                                                    <LAGrid>
                                                        <LAGridItem xs={12} sm={6} md={2}>
                                                            <LAAutoComplete
                                                                multiple={false}
                                                                option={sectionDropdownOptions}
                                                                disabled={disabled}
                                                                onChange={onSectionChange}
                                                                style={{backgroundColor: LIGHT_ORANGE_COLOR}}
                                                                autoHighlight={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder="Section"
                                                                filterSelectedOptions={true}
                                                                selectionRemove={undefinedFunction}
                                                                value={x.section && sectionDropdownOptions ? sectionDropdownOptions.find((q:any) => q.name === x.section) : null}
                                                                defaultValue={x.section && sectionDropdownOptions ? sectionDropdownOptions.find((q:any) => q.name === x.section) : null}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            {/* <LATextField
                                                                name="question"
                                                                label="Question"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={idx}
                                                                value={x.question ?? ""}
                                                                onChange={this.handleQuestionChange}
                                                            /> */}
                                                            <LATextArea
                                                                minRows={1}
                                                                rowsMax={4}
                                                                name="question"
                                                                label="Question"
                                                                fullWidth={true}
                                                                disabled={undefined}
                                                                indexPosition={idx}
                                                                variant="outlined"
                                                                errorText={!x.question ? "Required" : undefined}
                                                                onChange={this.handleQuestionChange}
                                                                value={x.question ?? ""}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={2}>
                                                            <LAAutoComplete
                                                                disabled={disabled}
                                                                multiple={false}
                                                                option={FieldTypeOptions}
                                                                autoHighlight={true}
                                                                onChange={onFieldTypeChange}
                                                                filterSelectedOptions={true}
                                                                getOptionLabel="name"
                                                                dropDownPlaceHolder="Type"
                                                                selectionRemove={undefinedFunction}
                                                                value={x.type && FieldTypeOptions ? FieldTypeOptions.find(q => q.name === x.type) : null}
                                                                defaultValue={x.type && FieldTypeOptions ? FieldTypeOptions.find(q => q.name === x.type) : null}
                                                            />
                                                        </LAGridItem>

                                                        {(x.type === "Dropdown" || x.type === "Checkbox") &&
                                                            <LAGridItem xs={12} sm={6} md={2} className="text-center">
                                                                <LAFreeTextAutoComplete 
                                                                    value={this.getOptions(x.options)} 
                                                                    onChange={onOptions} 
                                                                    label="Options" 
                                                                    disabled={undefined}
                                                                    autoHighlight={true}
                                                                    placeholder="Type and press Enter"
                                                                />
                                                            </LAGridItem>
                                                        }

                                                        {(x.type === "Yes/No/NA" || x.type === "Pass/Fail/NA" || x.type === "Dropdown" || x.type === "Checkbox") && 
                                                            <LAGridItem xs={12} sm={6} md={2}>
                                                                <LAAutoComplete
                                                                    multiple={false}
                                                                    option={flaggedOptionsDropdownOptions}
                                                                    disabled={disabled}
                                                                    onChange={onFlaggedOptionsChange}
                                                                    autoHighlight={true}
                                                                    getOptionLabel="name"
                                                                    dropDownPlaceHolder="Flagged Options"
                                                                    filterSelectedOptions={true}
                                                                    selectionRemove={undefinedFunction}
                                                                    value={x.flagged_Options && flaggedOptionsDropdownOptions ? flaggedOptionsDropdownOptions.find((q:any) => q.name === x.flagged_Options) : null}
                                                                    defaultValue={x.flagged_Options && flaggedOptionsDropdownOptions ? flaggedOptionsDropdownOptions.find((q:any) => q.name === x.flagged_Options) : null}
                                                                />
                                                            </LAGridItem>
                                                        }

                                                        <LAGridItem xs={12} sm={6} md={1}>
                                                            <LACheckBox
                                                                label="Required"
                                                                onChange={onRequired}
                                                                disabled={disabled}
                                                                value={x.required === "Yes" ? true : false}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={1}>
                                                            <LACheckBox
                                                                label="Image Required"
                                                                onChange={onImageRequired}
                                                                disabled={disabled}
                                                                value={x.image_Required === "Yes" ? true : false}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={1}>
                                                            <LATextField
                                                                label="Display Order"
                                                                type="number"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                disabled={disabled}
                                                                name="display_Order"
                                                                value={x.display_Order}
                                                                onChange={ondisplayOrderChange}
                                                                errorText={errors["display_Order"] ? errors["display_Order"].message : undefined}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={1}>
                                                            <LAIconButton
                                                                label="Delete"
                                                                icon={<DeleteIcon />}
                                                                disabled={data.delete_Questions === false && x.id > 0 ? true : undefined}
                                                                onClick={onDelete}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={12}>
                                                            <LAGrid>
                                                                <LAGridItem xs={6}>
                                                                    <strong className="text-center">Attachments</strong>
                                                                    <LADropZone onFileDrop={(e:any) => this.questionFileUpload(e, idx)} />
                                                                    <br />
                                                                    <LAGrid>
                                                                        {(x.files !== null) && x.files
                                                                            .map((q:any, ind:any) => {
                                                                                const onFileDelete = (): void => this.onQuestionFileDelete(ind, idx);
                                                                                const onDownload = (): void => this.handleQuestionFileDownload(q, x.id);

                                                                                return (
                                                                                    <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                                                        <LAPaperWithLessPadding key={ind}>
                                                                                            <span>{q.name}</span>
                                                                                            <br />
                                                                                            <LAIconButton
                                                                                                name="file"
                                                                                                label={q.name}
                                                                                                id={(ind).toString()}
                                                                                                onClick={onDownload}
                                                                                                icon={<DownloadIcon />}
                                                                                            />

                                                                                            <LAIconButton
                                                                                                key={ind}
                                                                                                label="Delete"
                                                                                                icon={<DeleteIcon />}
                                                                                                onClick={onFileDelete}
                                                                                            />
                                                                                        </LAPaperWithLessPadding>
                                                                                    </LAGridItem>
                                                                                )
                                                                            })}
                                                                    </LAGrid>

                                                                </LAGridItem>

                                                                <LAGridItem xs={6}>
                                                                    <strong className="text-center">Images</strong>
                                                                    <LADropZone onFileDrop={(e: any) => this.handleImageUpload(e, idx)} />
                                                                    <br />
                                                                    <LAGrid>
                                                                        {x.pictures && x.pictures
                                                                            .map((q:IChecklistQuestionPicture, ind: number) => {
                                                                                const onImgDelete = (): void => this.onImgDelete(q.name, ind, idx);
                                                                                const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                                                // const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                                                const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind, idx);
                
                                                                                return (
                                                                                    <LAGridItem xs={4} key={ind} className="text-center">
                                                                                        <LAPaperWithLessPadding key={ind}>
                                                                                            <LAThumbnailWithLink
                                                                                                alt={q.name}
                                                                                                key={ind}
                                                                                                url={base64}
                                                                                                id={(ind + "_").toString()}
                                                                                            />
                
                                                                                            <LATextField
                                                                                                className="mt-2"
                                                                                                label="Description"
                                                                                                fullWidth={true}
                                                                                                variant="outlined"
                                                                                                name="description"
                                                                                                disabled={disabled}
                                                                                                value={q.description}
                                                                                                onChange={onImageName}
                                                                                                errorText={
                                                                                                    q.name.length === 0 ?
                                                                                                        FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                                        : undefined
                                                                                                }
                                                                                            />
                
                                                                                            <LAIconButton
                                                                                                key={ind}
                                                                                                label="Delete"
                                                                                                disabled={disabled}
                                                                                                onClick={onImgDelete}
                                                                                                icon={<DeleteIcon />}
                                                                                            />
                                                                                        </LAPaperWithLessPadding>
                                                                                    </LAGridItem>
                                                                                );
                
                                                                            })}
                                                                    </LAGrid>

                                                                </LAGridItem>
                                                            </LAGrid>
                                                        </LAGridItem>

                                                    </LAGrid>
                                                </LAPaperWithLessPadding>
                                            </LAGridItem>
                                        )
                                    })}

                                </LAGrid>
                            {/* </LAPaperWithLessPadding> */}
                            </LAExpansionPanel>
                        </LAGridItem>}

                        <LAGridItem xs={12} className="mt-3">
                            <LAExpansionPanel color={PURPLE_COLOR} textColor={WHITE_COLOR} label="Documents" expanded={documentsExpand} onClick={() => this.handleSectionExpand("documentsExpand")}> 
                                <LAGrid>
                                    <LAGridItem xs={12} className="text-center">
                                        <strong>Upload Documents</strong>
                                        {/* <hr /> */}
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LADropZone onFileDrop={this.handleDocumentsFileUpload} />
                                    </LAGridItem>

                                    {data.files && data.files.length > 0 && data.files.map((x, index) => {
                                        const onDownload = (): void => this.handleDocumentsFileDownload(x);
                                        const onRemove = (): void => this.onDocumentsFileDelete(index);
                                        const onChange = (name: string, value: string): void => this.handleDocumentFileNameChange(value + "." + x.name.substring(x.name.lastIndexOf(".") + 1), index);
                                        return <LAGridItem key={index} xs={4}>
                                            {x.name && x.name.length > 0
                                                && <>
                                                    <LATextField
                                                        name="name"
                                                        label="Description"
                                                        onChange={onChange}
                                                        disabled={data.id > 0 && !x.base64String ? true : undefined}
                                                        value={x.name.substring(0, x.name.lastIndexOf("."))}
                                                    />

                                                    <LAIconButton
                                                        className="pl-5"
                                                        label="Download"
                                                        onClick={onDownload}
                                                        icon={<DownloadIcon />}
                                                    />
                                                </>}

                                            {x.name && <LAIconButton
                                                label="Remove"
                                                onClick={onRemove}
                                                icon={<DeleteIcon />}
                                            />}
                                        </LAGridItem>
                                    })}
                                </LAGrid>
                            </LAExpansionPanel>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12}><></></LAGridItem>

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={(disabledSave || (Object.values(errors).length > 0) || ((data.type !== "Acknowledge" && data.questions.length === 0))) ? true : undefined}
                            />
                        </LAGridItem>

                    </LAGrid>
                }

                {/* {optionPopup && <LAPopover anchorRef={null} open={optionPopup ? true : false} onClose={this.handleOptionClose}>
                    <LAPaperWithLessPadding className="text-center">
                        <LAGrid>

                            <LAGridItem xs={12}>
                                <strong>Add options for the dropdown</strong>
                            </LAGridItem>

                            {popupData.map((x, idx) => {
                                return (
                                    <LAGridItem key={idx} xs={12} sm={4}>
                                        <LATextField
                                            name="option"
                                            label={"Option"}
                                            fullWidth={true}
                                            variant="outlined"
                                            indexPosition={idx}
                                            value={x ?? ""}
                                            onChange={this.handlePopupDataChange}
                                        />
                                    </LAGridItem>
                                )
                            })}

                            <LAGridItem xs={12}>
                                <LAIconButton
                                    icon={<AddIcon />}
                                    label="Add New Option"
                                    onClick={this.onAddNewOption}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                    onSave={this.popupSave}
                                    onCancel={this.handleOptionClose}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithLessPadding>
                </LAPopover>} */}
                {formVersionHistoryPopup && <FormVersionHistoryPopup
                    page="FormTemplateVersionHistory"
                    data={formVersionHistoryPopupData}
                    display={`Version History` }
                    open={formVersionHistoryPopup}
                    status={getFormVersionHistory.kind}
                    onCancel={this.handleFormVersionHistoryPopupClose}
                />}
                {formQuestionsVersionHistoryPopup && <FormVersionHistoryPopup
                    page="FormTemplateQuestionsVersionHistory"
                    data={formVersionHistoryPopupData}
                    display={`Questions Version History` }
                    open={formQuestionsVersionHistoryPopup}
                    status={getFormVersionHistory.kind}
                    onCancel={this.handleFormQuestionsVersionHistoryPopupClose}
                />}

                <RequestStatus requestStatus={addRequestStatus.kind} successMessage="Form has been added successfully" />
                <RequestStatus requestStatus={updateRequestStatus.kind} successMessage="Form has been updated successfully" />
            </AddUpdateFormStyles>
        );
    }

    private handleSectionExpand = (name: keyof IAddUpdateFormState) => {
        this.setState((prevState) => ({
            ...prevState,               
            [name]: !prevState[name] 
        }));
    };

    private getWorkingSites = (ids: string): IFormWSS[] => {
        if (ids) {
            const workingSites = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];
            const response: IFormWSS[] = [];
            const assignedWorkingSiteIDs = ids !== null ? ids.split(",").map(String) : [];

            assignedWorkingSiteIDs.forEach((x) => {
                const index = workingSites.findIndex(q => q.wsS_ID.toString() === x);
                if (index >= 0)
                    response.push(workingSites[index]);
            });

            return response;
        }
        return [];
    };

    private handleWorkingSitesClick = (row:any): void => {
        // console.log(value)
        const selectedWorkingSitesList: IFormWSS[] = row.selectedRowsData;
        let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        let wsSIDs = data.wsS_IDS;
        const wssIDs_Array: number[] = wsSIDs && wsSIDs !== null ? wsSIDs.split(",").map(Number) : [];
       
        selectedWorkingSitesList.forEach((x) => {
            const index = wssIDs_Array.length > 0 ? wssIDs_Array.findIndex((e) => e === x.wsS_ID) : -1;
            if (index < 0) {
                wssIDs_Array.push(x.wsS_ID);
            }
        });

        wsSIDs = wssIDs_Array.join(",");

        this.setState({
            ...this.state,
            data: {
                ...data,
                wsS_IDS: wsSIDs
            },
            errors
        });
    }

    private handleWorkingSiteRemove = (value: string): void => {
        // console.log(value)
        let errors = { ...this.state.errors };
        const data = { ...this.state.data};
        const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.wssList : [];

        let wsSIDS = data.wsS_IDS;
        const wssIDs_Array: Number[] = wsSIDS && wsSIDS !== null ? wsSIDS.split(",").map(Number) : [];
        const site = wssList ? wssList.find(q => q.site === value) : null;

        if(site) {
            const index = wssIDs_Array.length > 0 ? wssIDs_Array.findIndex((x) => x === site?.wsS_ID) : -1;
            if (index >= 0) {
                wssIDs_Array.splice(index, 1);
            }
        }
        
        wsSIDS = wssIDs_Array.join(",");

        this.setState({
            ...this.state,
            data: {
                ...data,
                wsS_IDS: wsSIDS
            },
            errors
        });
    }

    private handleSelectDepartments = async (e: any, values: IDepartments[]): Promise<void> => {
        const data = { ...this.state.data };
        const selectedArray: string[] = data.departments && data.departments !== null
            ? data.departments.split(",").map(String)
            : [];

        await values.forEach((q: IDepartments) => {
            const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === q.department) : -1;
            if (index < 0) {
                selectedArray.push(q.department);
            }
        });

        data.departments = selectedArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                departments: data.departments,
            },
        });
    };

    private handleDepartmentsRemove = async (value: any): Promise<void> => {
        if (hasPayload(this.props.getLookup) && value) {
            const departmentsOptions: IDepartments[] = this.props.getLookup.payload.response.departments;

            const find = departmentsOptions.find(x => x.department === value);

            if (find) {
                const data = { ...this.state.data };
                const selectedArray: string[] = data.departments && data.departments !== null
                    ? data.departments.split(",").map(String) : [];

                const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === find.department) : -1;
                if (index >= 0) {
                    selectedArray.splice(index, 1);
                }

                data.departments = selectedArray.join(",");

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        departments: data.departments,
                    },
                });
            }
        }
    };

    private getSelectedDepartments = (value: string): IDepartments[] => {
        if (hasPayload(this.props.getLookup) && value) {

            const response: IDepartments[] = [];
            const selected = value !== null ? value.split(",").map(String) : [];
            const departmentsOptions: IDepartments[] = this.props.getLookup.payload.response.departments;

            selected.forEach((x) => {
                const index = departmentsOptions.findIndex(q => q.department === x);
                if (index >= 0)
                    response.push(departmentsOptions[index]);
            });

            return response;
        }
        return [];
    };

    private handleSelectFormans = async (e: any, values: IFormForeman[]): Promise<void> => {
        const data = { ...this.state.data };
        const selectedArray: string[] = data.foreman && data.foreman !== null
            ? data.foreman.split(",").map(String)
            : [];

        await values.forEach((q: IFormForeman) => {
            const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === q.upn) : -1;
            if (index < 0) {
                selectedArray.push(q.upn);
            }
        });

        data.foreman = selectedArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                foreman: data.foreman,
            },
        });
    };

    private handleFormansRemove = async (value: any): Promise<void> => {
        if (hasPayload(this.props.getLookup) && value) {
            const modelOptions: IFormForeman[] = this.props.getLookup.payload.response.foremanList;

            const find = modelOptions.find(x => x.display_Name === value);

            if (find) {
                const data = { ...this.state.data };
                const selectedArray: string[] = data.foreman && data.foreman !== null
                    ? data.foreman.split(",").map(String) : [];

                const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === find.upn) : -1;
                if (index >= 0) {
                    selectedArray.splice(index, 1);
                }

                data.foreman = selectedArray.join(",");

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        foreman: data.foreman,
                    },
                });
            }
        }
    };

    private handleSelectNotifyContacts = async (e: any, values: IFormForeman[]): Promise<void> => {
        const data = { ...this.state.data };
        const selectedArray: string[] = data.flagged_Notification_Contacts && data.flagged_Notification_Contacts !== null
            ? data.flagged_Notification_Contacts.split(",").map(String)
            : [];

        await values.forEach((q: IFormForeman) => {
            const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === q.upn) : -1;
            if (index < 0) {
                selectedArray.push(q.upn);
            }
        });

        data.flagged_Notification_Contacts = selectedArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                flagged_Notification_Contacts: data.flagged_Notification_Contacts,
            },
        });
    };

    private handleNotifyContactsRemove = async (value: any): Promise<void> => {
        if (hasPayload(this.props.getLookup) && value) {
            const modelOptions: IFormForeman[] = this.props.getLookup.payload.response.foremanList;

            const find = modelOptions.find(x => x.display_Name === value);

            if (find) {
                const data = { ...this.state.data };
                const selectedArray: string[] = data.flagged_Notification_Contacts && data.flagged_Notification_Contacts !== null
                    ? data.flagged_Notification_Contacts.split(",").map(String) : [];

                const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === find.upn) : -1;
                if (index >= 0) {
                    selectedArray.splice(index, 1);
                }

                data.flagged_Notification_Contacts = selectedArray.join(",");

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        flagged_Notification_Contacts: data.flagged_Notification_Contacts,
                    },
                });
            }
        }
    };

    private getSelectedFormans = (value: string): IFormForeman[] => {
        if (hasPayload(this.props.getLookup) && value) {

            const response: IFormForeman[] = [];
            const selected = value !== null ? value.split(",").map(String) : [];
            const modelOptions: IFormForeman[] = this.props.getLookup.payload.response.foremanList;

            selected.forEach((x) => {
                const index = modelOptions.findIndex(q => q.upn === x);
                if (index >= 0)
                    response.push(modelOptions[index]);
            });

            return response;
        }
        return [];
    };

    private handleSelectEmployee = async (e: any, values: IFormEmployee[]): Promise<void> => {
        const data = { ...this.state.data };
        const selectedArray: string[] = data.employees && data.employees !== null
            ? data.employees.split(",").map(String)
            : [];

        await values.forEach((q: IFormEmployee) => {
            const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === q.name) : -1;
            if (index < 0) {
                selectedArray.push(q.name);
            }
        });

        data.employees = selectedArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                employees: data.employees,
            },
        });
    };

    private handleEmployeeRemove = async (value: any): Promise<void> => {
        const data = { ...this.state.data };
        const selectedArray: string[] = data.employees && data.employees !== null
            ? data.employees.split(",").map(String) : [];

        const index = selectedArray.length > 0 ? selectedArray.findIndex((x) => x === value) : -1;
        if (index >= 0) {
            selectedArray.splice(index, 1);
        }

        data.employees = selectedArray.join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                employees: data.employees,
            },
        });
    };

    private getSelectedEmployees = (value: string): IFormEmployee[] => {
        if (hasPayload(this.props.getLookup) && value) {

            const response: IFormEmployee[] = [];
            const selected = value !== null ? value.split(",").map(String) : [];
            const modelOptions: IFormEmployee[] = this.props.getLookup.payload.response.employeeList;

            selected.forEach((x) => {
                const index = modelOptions.findIndex(q => q.name === x);
                if (index >= 0)
                    response.push(modelOptions[index]);
            });

            return response;
        }
        return [];
    };

    private handleSections = (sections: string[]) => {
        // console.log(sections);
        // const selectedOptions = sections;
            
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                sections: sections.map(x => x.trim()).join(","),
            }
        });
    }

    // private onAddNewOption = (): void => {
    //     const { optionPopup } = this.state;

    //     if (optionPopup) {
    //         let data = optionPopup.options.split(",");
    //         data.push("");

    //         this.setState({
    //             optionPopup: {
    //                 ...optionPopup,
    //                 options: data.join(",")
    //             }
    //         });
    //     }
    // };

    private onAddQuestion = (): void => {
        const iS = { ...this.state.data };

        iS.questions.unshift({
            // id: (iS.questions.length + 1),
            id: 0,
            form_ID: iS.id,
            question: "",
            type: "",
            options: "",
            flagged_Options: "",
            section: "",
            display_Order: iS.questions.length + 1,
            required: "Yes",
            image_Required: "No",
            attachments: "",
            images: "",
            created_By: userName,
            created: "",
            modified_By: userName,
            modified: "",
            pictures: [],
            files: []
        });

        this.setState({ data: iS });
    };


    private questionFileUpload = (attachments: Array<File | DataTransferItem>, questionIndex: number): void => {

        const iS: IChecklistQuestion[] = [...this.state.data.questions];
        let record: IChecklistQuestion = { ...iS[questionIndex] };
        const files = record.files ? [...record.files] : [];
        // const files = this.state.data.files ? this.state.data.files : [];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;

                Object.values(attachments).forEach((x: any, index) => {
                    // console.log(x)
                    if(x.type.includes("image")) {
                        alert("Please select only an attachment")
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {
                                files.map((att, i) => {x.name.substring(0, x.name.lastIndexOf("."))
                                    // console.log(img.description, description)
                                    if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                        isDuplicateName = true;
                                    }
                                    return isDuplicateName
                                })
                                isDuplicateName ?
                                    files.push({
                                        name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                        base64String: reader.result.toString()
                                    }) : 
                                    files.push({
                                        name:  x.name,
                                        base64String: reader.result.toString()
                                    });
                                // files.push({
                                //     name: x.name,
                                //     base64String: reader.result.toString()
                                // });
    
                                if (attachments.length === index + 1) {
                                    resolve(true);
                                };
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        }).then(() => {
            // let errors = { ...this.state.errors };
            const files_Names: string[] = [];
            files.forEach((q:any) => {
                files_Names.push(q.name);
            });

            record = {
                ...record,
                files: files,
                attachments: files_Names.join(','),
            }

            iS[questionIndex] = record;
    
            // if(files.length > 0) {
            //     delete errors["files"]
            // }
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    questions: iS
                }
            });

            // this.setState({
            //     ...this.state,
            //     data: {
            //         ...this.state.data,
            //         files,
            //         file_Name: files_Names.join(';'),
            //     },
            //     errors
            // });
        });
    };

    private onQuestionFileDelete = (fileIdx: number, questionIndex: number): void => {
        const selection = window.confirm(deleteText);
        let errors = { ...this.state.errors };

        if (selection) {
            const iS: IChecklistQuestion[] = [...this.state.data.questions];
            let record: IChecklistQuestion = { ...iS[questionIndex] };
            const files = [...record.files];
            // const files = [...this.state.data.files];
            const files_Names: string[] = [];

            files.splice(fileIdx, 1);

            files.forEach((q:any) => {
                files_Names.push(q.name);
            });

            record = {
                ...record,
                files: files,
                attachments: files_Names.join(','),
            }

            iS[questionIndex] = record;

            // if(files.length > 0) {
            //     delete errors["files"]
            // } else {
            //     errors = this.errorChecker("files", files.length > 0 ? files.length.toString() : "", errors)
            // }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    questions: iS
                }
            });

            // this.setState({
            //     ...this.state,
            //     data: {
            //         ...this.state.data,
            //         files,
            //         file_Name: files_Names.join(';'),
            //     },
            //     errors
            // });
        }
    };

    private handleQuestionFileDownload = (q: IChecklistQuestionFile, id: number): void => {
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            this.downloadFormAttachmentEndpoint(q.name, id, "Question");
        }
    };

    private downloadFormAttachmentEndpoint = async (fileName: string, id: number, page: string): Promise<void> => {
        if (fileName !== null && id > 0) {
            if (hasPayload(this.props.token)) {

                await fetch(END_POINTS.FIELD.DOWNLOAD_FORM_ATTACHMENT, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            File_Name: fileName,
                            ID: id,
                            Page: page
                        },
                        token: this.props.token.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleImageUpload = async (event: any, questionIndex: number): Promise<void> => {

        const files = event;
        // console.log(files, questionIndex)
        const iS: IChecklistQuestion[] = [...this.state.data.questions];
        let record: IChecklistQuestion = { ...iS[questionIndex] };
        const popupImgs = record.pictures ? [...record.pictures] : [];
        // const popupImgs = [...this.state.popupImgs];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                if(!x.type.includes("image")) {
                    alert("Please select only an image")
                } 
                else {  
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String
                        }) : 
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String
                        });

                    record = {
                        ...record,
                        pictures: popupImgs
                    }

                    iS[questionIndex] = record;
            
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            questions: iS
                        }
                    });
                
                }
            }));
        }
    };

    private onImgDelete = (name: string, imgIndex: number, questionIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS: IChecklistQuestion[] = [...this.state.data.questions];
            let record: IChecklistQuestion = { ...iS[questionIndex] };
            const popupImgs = [...record.pictures];
            popupImgs.splice(imgIndex, 1);
            record = {
                ...record,
                pictures: popupImgs
            }
            
            iS[questionIndex] = record;
            
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    questions: iS
                }
            });
        }
    };

    private handleImageName = (name: string, value: string, imgIndex: number, questionIndex: number): void => {
        const iS: IChecklistQuestion[] = [...this.state.data.questions];
        let record: IChecklistQuestion = { ...iS[questionIndex] };
        const popupImgs = [...record.pictures];
        // const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[imgIndex], [name]: value };
        popupImgs[imgIndex] = picture;

        record = {
            ...record,
            pictures: popupImgs
        }

        iS[questionIndex] = record;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                questions: iS
            }
        });
    };

    // private checkFileNameDuplication = (files: IFormTemplateFile[]): boolean => {
    //     if(files && files.length > 1) {
    //         const filesName = new Set<string>();
    //         return files.some(file => {
    //             if (filesName.has(file.name)) return true; 
    //             filesName.add(file.name);
    //             return false;
    //         });
    //     }
    //     return false;
    // }

    private handleDocumentsFileUpload = (attachments: Array<File | DataTransferItem>): void => {

        const files = this.state.data.files ? this.state.data.files : [];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;

                Object.values(attachments).forEach((x: any, index) => {
                    // let description = x.name;
                    // console.log(x)
                    if(x.type.includes("image")) {
                        alert("Please select only an attachment")
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onloadend = async (e): Promise<void> => {
                            if (reader.result !== null) {

                                files.map((att, i) => {x.name.substring(0, x.name.lastIndexOf("."))
                                    // console.log(img.description, description)
                                    if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                        isDuplicateName = true;
                                    }
                                    return isDuplicateName
                                })
                                isDuplicateName ?
                                    files.push({
                                        name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                        base64String: reader.result.toString()
                                    }) : 
                                    files.push({
                                        name:  x.name,
                                        base64String: reader.result.toString()
                                    });
                                // files.push({
                                //     name: x.name,
                                //     base64String: reader.result.toString()
                                // });
    
                                if (attachments.length === index + 1) {
                                    resolve(true);
                                };
                            };
                        };
                        reader.readAsDataURL(x);
                    }
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                },
            });
        });
    };

    private handleDocumentFileNameChange = (value: string, index: number): void => {
        const files = this.state.data.files ? this.state.data.files : [];
        files[index] = { ...files[index], name: value };
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                files
            },
        });
    };

    private handleDocumentsFileDownload = (q: IFormTemplateFile): void => {
            if (q.base64String !== null && q.base64String.length > 0) {
                let a = document.createElement("a");
                a.href = q.base64String;
                a.download = q.name;
                a.click();
            } else {
                this.downloadFormAttachmentEndpoint(q.name, this.state.data.id, "Template" );
            }
        };

    private onDocumentsFileDelete = (fileIdx: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const files = [...this.state.data.files];
            files.splice(fileIdx, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                }
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.FORMS.FORM_TEMPLATE.INDEX,
            search: getTokenFromUrl(false)
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleFormVersionHistory = async(data: any, page: string): Promise<void> => {
        if (hasPayload(this.props.token)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: data.id,
                    page: page
                }
            });
        }
        if(page === "Template")
            this.setState({ formVersionHistoryPopup: true });
        if(page === "Questions") {
            this.setState({ formQuestionsVersionHistoryPopup: true });
        }
    }

    private handleFormVersionHistoryPopupClose = (): void => {
        this.setState({ formVersionHistoryPopup: false });
    };

    private handleFormQuestionsVersionHistoryPopupClose = (): void => {
        this.setState({ formQuestionsVersionHistoryPopup: false });
    };


    private handleQuestionDelete = async (index: number) => {
        const selection = window.confirm(deleteText);
        if (selection) {
            const questions: IChecklistQuestion[] = [...this.state.data.questions];
            questions.splice(index, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    questions
                }
            });
        }
    }

    private handleOptions = (options: string[], questionIndex: number) => {
        // console.log(options);
        const selectedOptions = options;
        // console.log(files, questionIndex)
        const iS: IChecklistQuestion[] = [...this.state.data.questions];
        let record: IChecklistQuestion = { ...iS[questionIndex] };
        // const currentOptions = record.options ? record.options : "";
        record = {
           ...record,
            options: selectedOptions.map(x => x.trim()).join(","),
            flagged_Options: ""
        }

        iS[questionIndex] = record;
            
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                questions: iS
            }
        });
    }

    private getOptions = (options: string): string[] => {
        if (options) {
            const assignedOptions = options !== null ? options.split(",").map(String) : [];
            return assignedOptions;
        }
        return [];
    }

    private handleSave = async (): Promise<void> => {
        const data = this.state.data;
        const fileNameDuplicate  = checkFileNameDuplication(data.files);

        if(data.questions.length === 0 && data.type !== "Acknowledge") {
            alert("Please add at least one question before save")
        }
        else if(fileNameDuplicate) {
            alert("Please ensure file names are unique in Documents Upload")
        }
        else {     
            if (hasPayload(this.props.token)) {
                const questions: IAddUpdateQuestion[] = [];
                const upn = this.props.token.payload.response.upn;
    
                await data.questions.forEach((x) => {
                    questions.push({
                        ID: x.id,
                        Question: x.question,
                        Type: x.type,
                        Options: x.options,
                        Flagged_Options: x.flagged_Options,
                        Section: x.section,
                        Display_Order: Number(x.display_Order),
                        Required: x.required ?? "No",
                        Image_Required: x.image_Required ?? "No",
                        Attachments: x.attachments ?? "",
                        Files: x.files ?? [],
                        Pictures: x.pictures ?? []
                    });
                });
    
                if (data.id === 0) {
                    this.props.addRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            ID: 0,
                            Form_Name: data.form_Name,
                            Active: data.active,
                            Type: data.type,
                            WSS_ID: data.wsS_ID ?? 0,
                            All_Sites: data.all_Sites ?? null,
                            WSS_IDS: data.wsS_IDS ?? null,
                            Departments: data.departments,
                            Foreman: data.foreman,
                            Employees: data.employees,
                            Frequency: data.frequency,
                            Start_Date: data.start_Date,
                            Expiry_Date: data.expiry_Date,
                            Flagged_Notification_Contacts: data.flagged_Notification_Contacts,
                            Flagged_Notification_Method: data.flagged_Notification_Method,
                            Hazard_Assessment: data.hazard_Assessment,
                            Requires_Crew: data.requires_Crew,
                            Requires_Guest: data.requires_Guest,
                            Requires_Toolbox: data.requires_Toolbox,
                            Sections: data.sections,
                            Message: data.message,
                            Questions: questions,
                            Files: data.files,
                            Created_By: upn,
                            Modified_By: upn,
                        }
                    });
                } else {
                    this.props.updateRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            ID: data.id,
                            Form_Name: data.form_Name,
                            Active: data.active,
                            Type: data.type,
                            WSS_ID: data.wsS_ID ?? 0,
                            All_Sites: data.all_Sites ?? null,
                            WSS_IDS: data.wsS_IDS ?? null,
                            Departments: data.departments,
                            Foreman: data.foreman,
                            Employees: data.employees,
                            Frequency: data.frequency,
                            Start_Date: data.start_Date,
                            Expiry_Date: data.expiry_Date,
                            Flagged_Notification_Contacts: data.flagged_Notification_Contacts,
                            Flagged_Notification_Method: data.flagged_Notification_Method,
                            Hazard_Assessment: data.hazard_Assessment,
                            Requires_Crew: data.requires_Crew,
                            Requires_Guest: data.requires_Guest,
                            Requires_Toolbox: data.requires_Toolbox,
                            Sections: data.sections,
                            Message: data.message,
                            Questions: questions,
                            Files: data.files,
                            Created_By: upn,
                            Modified_By: upn,
                        }
                    });
                }
    
                this.setState({ serverError: "" });
            }
        }

    };

    private handleQuestionChange = (name: string, value: string, index?: number): void => {
        if (index !== undefined) {
            const iS = { ...this.state.data };
            let question = {
                ...iS.questions[index]
            };

            if(name === "type") {
                iS.questions[index] = { ...question, [name]: value, options: "", flagged_Options: "" };
            }
            else {
                iS.questions[index] = { ...question, [name]: value };
            }

            this.setState({
                ...this.state,
                data: iS
            });
        }
    };

    private generateDropdownOptions = (options: string): any => {
        const optionsArray = options ? options.split(",").map(String) : [];
        const dropDownOptions = optionsArray.length > 0 ? optionsArray.map((option: string, index: number) => ({
            id: index,
            name: option
        })) : [];
        // console.log(flaggedOptionsDropdown)
        return dropDownOptions;
    }

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const { data } = this.state;
        // console.log(name, value)

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "type") {
            if(value === "Acknowledge") {
                errors = this.errorChecker("start_Date", data.start_Date, errors);
                errors = this.errorChecker("expiry_Date", data.expiry_Date, errors);
                errors = this.errorChecker("message", data.message, errors);
            }
            else {
                delete errors["start_Date"];
                delete errors["expiry_Date"];
                delete errors["message"];
            }
        }

        if(name === "start_Date" || name === "expiry_Date" || name === "message") {
            if(value){
                delete errors[name];
            }
            else if(data.type === "Acknowledge" && value === "") {
                errors = this.errorChecker(name, value, errors);
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: (name === "wsS_ID") ? Number(value) : value,
                wsS_IDS: (name === "all_Sites" && value === "Yes") ? "" : this.state.data.wsS_IDS
                // message: (name === "type") ? "" : data.message
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = queryString.parse(this.props.history.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {
                    if (query.id !== "0") {
                        if (this.state.data.id !== Number(query.id)) {
                            if (hasPayload(this.props.getFormTemplates)) {
                                let errors: ById<IFieldErrorKeyValue> = {};
                                const data: any = this.props.getFormTemplates.payload.response.find(x => x.id === Number(query.id));
                                const existingdocumentsFiles = data.attachments ? data.attachments.split(";") : [];
                                const documentsFiles: IFormTemplateFile[] = [];
                                existingdocumentsFiles.forEach((att: string) => {
                                    documentsFiles.push({ name: att, base64String: "" });
                                });
                                // console.log(data)
                                data.questions.sort((a: any, b: any) => a.display_Order - b.display_Order).forEach((x: IChecklistQuestion) => {
                                    const existingAtt = x.attachments ? x.attachments.split(",") : [];
                                    const files: IChecklistQuestionFile[] = [];
                                    existingAtt.forEach((att: string) => {
                                        files.push({ name: att, base64String: "" });
                                    });
                                    x.files = files;
                                })

                                RequiredFields.forEach((x) => {
                                    if(x === "wsS_ID"){
                                        if(data?.wsS_ID !== 0) {
                                            const val = data["wsS_ID"] === null ? "" : data["wsS_ID"];
                                            errors = this.errorChecker("wsS_ID", val, errors);
                                        }
                                    } else {
                                        const val = data[x] === null ? "" : data[x];
                                        errors = this.errorChecker(x, val, errors);
                                    };
                                });

                                if (data)
                                    this.setState({
                                        ...this.state,
                                        data : {
                                            ...data,
                                            files: documentsFiles
                                        },
                                        errors
                                    });
                            }
                        }
                    } else {
                        let errors: ById<IFieldErrorKeyValue> = {};
                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });
                        this.setState({ errors });
                    }

                    if (isNotLoaded(this.props.getFormTemplates))
                        this.props.getFormsRequest({
                            token: this.props.token.payload.response.token
                        });

                    if (isNotLoaded(this.props.getLookup)) {
                        this.getLookups();
                    };
                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLookups = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Template"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateFormStoreProps => ({
    token: getToken(state),
    getLookup: getFormLookup(state),
    getFormTemplates: getForms(state),
    addRequestStatus: addFormTemplate(state),
    updateRequestStatus: updateFormTemplate(state),
    getFormVersionHistory: getFormVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateFormDispatchProps => ({
    getFormsRequest: (data: IFormsRequest) => dispatch(getFormsLoadAction(data)),
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    addRequest: (data: IAddFormTemplateRequest) => dispatch(addFormTemplateLoadAction(data)),
    updateRequest: (data: IUpdateFormTemplateRequest) => dispatch(updateFormTemplateLoadAction(data)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateForm);