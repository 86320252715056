import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_INSPECTION_SHEETS_REQUEST, IGetUnitInspectionSheets } from "./getUnitInspectionSheetsConstants";
import { IGetUnitInspectionSheetsLoadAction, IGetUnitInspectionSheetsLoadFailedAction, IGetUnitInspectionSheetsSuccessAction, 
    getUnitInspectionSheetsLoadFailedAction, getUnitInspectionSheetsLoadSuccessAction } from "./getUnitInspectionSheetsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getUnitInspectionSheetsEpic: Epic = (
    action$: ActionsObservable<IGetUnitInspectionSheetsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitInspectionSheetsSuccessAction | IGetUnitInspectionSheetsLoadFailedAction> =>
    action$.ofType(IGET_UNIT_INSPECTION_SHEETS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetUnitInspectionSheets[]>>(
                    END_POINTS.FIELD.INSPECTION_FORM.GET_UNIT_INSPECTION_SHEETS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetUnitInspectionSheets[]>): IGetUnitInspectionSheetsSuccessAction => {
                            return getUnitInspectionSheetsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitInspectionSheetsLoadFailedAction(response.message)))
                    )
            )
        );