import { IAddUpdateForemanMessaging } from "../addForemanMessaging/addForemanMessagingConstants";

export enum IUPDATE_FOREMAN_MESSAGING_REQUEST {
    REQUEST = "updateForemanMessaging/UPDATE_FOREMAN_MESSAGING_REQUEST",
    SUCCESS = "updateForemanMessaging/UPDATE_FOREMAN_MESSAGING_SUCCESS",
    FAILED = "updateForemanMessaging/UPDATE_FOREMAN_MESSAGING_FAILED"
};

export interface IUpdateForemanMessagingRequest {
    token: string;
    request: IAddUpdateForemanMessaging;
};