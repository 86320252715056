import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST, IGetSOCTCustomServiceItemsBySOCTIDRequest, ISOCTCustomServiceItemsBySOCTID } from "./getSOCTCustomServiceItemsBySOCTIDConstants";

export interface IGetSOCTCustomServiceItemsBySOCTIDLoadAction {
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.REQUEST;
    data: IGetSOCTCustomServiceItemsBySOCTIDRequest
}
export const getSOCTCustomServiceItemsBySOCTIDLoadAction = (data: IGetSOCTCustomServiceItemsBySOCTIDRequest): IGetSOCTCustomServiceItemsBySOCTIDLoadAction => ({
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.REQUEST,
    data
});
export interface IGetSOCTCustomServiceItemsBySOCTIDSuccessAction {
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>;
}
export const getSOCTCustomServiceItemsBySOCTIDLoadSuccessAction = (list: SurewayAPIResponse<ISOCTCustomServiceItemsBySOCTID[]>): IGetSOCTCustomServiceItemsBySOCTIDSuccessAction => ({
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.SUCCESS,
    list
});
export interface IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction {
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.FAILED;
    message: string;
}
export const getSOCTCustomServiceItemsBySOCTIDLoadFailedAction = (message: string): IGetSOCTCustomServiceItemsBySOCTIDLoadFailedAction => ({
    type: IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST.FAILED,
    message
});
