import React from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, 
    Paging, Button, Pager, SearchPanel, 
    FilterPanel,
  FilterBuilderPopup,
  Export,
  StateStoring,
  Scrolling,
  Grouping,
  GroupPanel,
  ColumnChooser,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import {completeMoveRequest, getMappingView, getSOCTUnitByEquipmentID, getMoveRequestIDByEquipmentID, rejectMoveRequest, approveMoveRequest} from './api/equipmenttracker/equipmentTrackerAPI';
import SOCTHome from './equipmenttrackerhome';
import EquipmentMoveEdit from './forms/equipmenttracker/equipmentMoveEdit';
import EquipmentMoveForm from './forms/equipmenttracker/equipmentMoveRequest';
import AddNewEquipmentMoveForm from './forms/equipmenttracker/equipmentMoveRequestAddNew';
import {currentUser} from './equipmenttrackerhome';
import CustomEquipmentMoveForm from './forms/equipmenttracker/equipmentMoveCustom';
import CompleteMoveRequestPopup, {complete_move_request_email_check, complete_move_request_approved_check, complete_move_request_rejected_check, rejection_comments} from '../../shared/completeMoveRequestPopup';
import { formatNumber } from 'devextreme/localization';
import { DeleteIcon, SoctIcon, VersionHistoryIcon } from '../../shared/icons';
import { BLUE_COLOR, EXTRA_DARK_GREY_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR, ORANGE_COLOR } from '../../shared/theme';
import { LAIconButton } from '../../shared/buttons';
import { MoveRequestVersionHistory } from './forms/equipmenttracker/moveRequestVersionHistory';
import { DataTimer } from '../../shared/timer';
import { v4 as uuidv4 } from "uuid";
import PageSpacing from '../../shared/pageSpacing';

interface IState {
  listView:any;
  open:boolean;
  timeoutKey: string;
  showEditForm:boolean;
  view:string;
  showAddNewForm:boolean;
  showCustomMoveRequest:boolean;
  showCompleteMovePopup:boolean;
  showInitiateMovePopup:boolean;
  showRequestMovePopup:boolean;
  isFromRequestMove:boolean;
  approvalButtonClick:boolean;
  loaded:boolean;
  showVersionHistory:boolean;
  searchValue:string;
}
interface IProps extends RouteComponentProps{
  history:any
}
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}

function StyledStatusComponent(cellData:any):any {
  if(cellData.column.dataField === 'move_Status'){
      switch(cellData.data.move_Status){
          case 'Initiated':
              return <div style={{backgroundColor:'rgb(146, 218, 150)', padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Completed':
              return <div style={{backgroundColor:'rgb(236, 176, 65)',padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Cancelled':
            return <div style={{backgroundColor:'rgb(205, 92, 92)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Moved':
            return <div style={{backgroundColor:'rgb(189, 220, 255)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Requested':
            return <div style={{backgroundColor:LIGHT_ORANGE_COLOR,padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Rejected':
            return <div style={{backgroundColor:LIGHT_RED_COLOR,padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'Dispatched':
            return <div style={{backgroundColor:'rgb(146, 218, 150)',padding:'5px', color:'white', textAlign:'center'}}>{cellData.value}</div>;
          case 'En Route':
            return <div style={{backgroundColor:EXTRA_DARK_GREY_COLOR, padding:'5px',color:'white', textAlign:'center'}}>{cellData.value}</div>;
      }
  }
}

function gridCellData(gridData:any) {
  return gridData.data[gridData.column.caption.toLowerCase()];
}
function DiffCell(cellData:any) {
  return (
    <div className={gridCellData(cellData).total_Cost === 0 ? 'inc' : 'dec'}>
      <div className="current-value">{formatNumber(gridCellData(cellData).value, { type: 'currency', currency: 'USD', precision: 2 })}</div>
      <div className="diff">{Math.abs(gridCellData(cellData).total_Cost).toFixed(2)}</div>
    </div>
  );
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];
var globalEditID:Number = 0;
var renderPageType:string = '';
var move_complete_id:Number = 0;
var current_view:string = "";
var list_view:any[] = [];
var initiate_move_object:any = {clicked_location:{site_Name:''},clicked_item:{equipment_ID:0,content:'',driver_Info:'',move_Status:'',guid:''},location_options:[]};
var initiate_btn_class:string= "";
var disableEdit:boolean = false;
var move_version_id:number = 0;
var hasListHistory:boolean = false;

export default class ListViewPage extends React.Component<IProps,IState>{
  dataGrid: React.RefObject<DataGrid>;
  constructor(props:any){
    super(props);
    this.state ={listView:[], timeoutKey: "old-list-view-page", open:false, showEditForm:false, view:'initiated', showAddNewForm:false,showCustomMoveRequest:false,showCompleteMovePopup:false, 
    showInitiateMovePopup:false, showRequestMovePopup:false, isFromRequestMove:false, approvalButtonClick:false, loaded:false, showVersionHistory:false, searchValue: ''};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
    this.openCustomMoveRequest = this.openCustomMoveRequest.bind(this);
    this.closeCompleteMovePopup = this.closeCompleteMovePopup.bind(this);
    this.confirmMovePopup = this.confirmMovePopup.bind(this);
    this.openConfirmMovePopup = this.openConfirmMovePopup.bind(this);
    this.openMoveHistory = this.openMoveHistory.bind(this);
    this.openBillingHistory = this.openBillingHistory.bind(this);
    this.openSOCTHistory = this.openSOCTHistory.bind(this);
    this.dataGrid = React.createRef();
    this.clearFilters = this.clearFilters.bind(this);
    this.openEditPopup = this.openEditPopup.bind(this);
    this.openInitiatePopup = this.openInitiatePopup.bind(this);
    this.openRequestMovePopup = this.openRequestMovePopup.bind(this);
    this.openApproveMovePopup = this.openApproveMovePopup.bind(this);
    this.openEditPopupFromApp = this.openEditPopupFromApp.bind(this);
    this.closeVersionHistory = this.closeVersionHistory.bind(this);
  }
  closeVersionHistory(){
    this.setState({showVersionHistory:false});
  }
  async openApproveMovePopup(id:number, data:any){
    var move_req_id:any = await getMoveRequestIDByEquipmentID(id);
    move_complete_id = move_req_id;
    this.setState({showCompleteMovePopup:true, approvalButtonClick:true});
  }
  openRequestMovePopup(id:number, data:any){
    data.content = data.unit_Number;
    initiate_move_object.clicked_item = data;
    initiate_move_object.clicked_location = {site_Name:data.site_Name, sub_Site_Name:data.sub_Site_Name,
      working_Site_ID:data.working_Site_ID,working_Site_Sub_ID:data.working_Site_Sub_ID};
    this.setState({isFromRequestMove:true, showInitiateMovePopup:true});
  }
  async openInitiatePopup(id:number, data:any){
    data.content = data.unit_Number;
    initiate_move_object.clicked_item = data;
    initiate_move_object.clicked_location = {site_Name:data.site_Name, sub_Site_Name:data.sub_Site_Name,
      working_Site_ID:data.working_Site_ID,working_Site_Sub_ID:data.working_Site_Sub_ID};
    console.log(initiate_move_object);
    this.setState({showInitiateMovePopup:true});
  }
  async openEditPopup(id:number, data:any){
    var move_req_id:any = await getMoveRequestIDByEquipmentID(id);
    if(window.location.href.includes("?token")){
      this.props.history.push('/field/equipmenttracker/equipmentmoves/edit/id='+move_req_id +"?"+ window.location.href.split('?')[1]+"&hasHistory", '_self');
    }
    else{
      this.props.history.push('/field/equipmenttracker/equipmentmoves/edit/id='+move_req_id+"&hasHistory");
    }
  }
  async openEditPopupFromApp(id:number, data:any){
    var move_req_id:any = await getMoveRequestIDByEquipmentID(id);
    this.props.history.push('/field/equipmenttracker/equipmentmoves/edit/id='+move_req_id+"?"+window.location.href.split('?')[1]+"&hasHistory");
  }
  clearFilters(){
    this.dataGrid.current?.instance.state(null);
  }
  async openMoveHistory(id:number, data:any){
    let move_id = await getMoveRequestIDByEquipmentID(id);
    move_version_id = move_id;
    this.setState({showVersionHistory:true});
  }
  openBillingHistory(id:number, data:any){
    window.open( `https://reportserver.propsense.com/reports/report/Reports/Field/ET_Move_History?Unit=${data.unit_Number}`,
    "_blank");
  }
  async openSOCTHistory(val:any){
      var res:any = await getSOCTUnitByEquipmentID(val.data.equipment_ID);
      window.open("/field/soct/lists/edit/reportstable/id="+res.id, "_blank");
  }
  async openConfirmMovePopup(id:number, data:any){
    var move_req_id:any = await getMoveRequestIDByEquipmentID(id);
    move_complete_id = move_req_id;
    this.setState({showCompleteMovePopup:true});
  }
  closeCompleteMovePopup(){
    this.setState({showCompleteMovePopup:false});
  }
  async confirmMovePopup(){
    if(move_complete_id != 0){
      if(complete_move_request_approved_check){
        alert("Approving move");
        var res:any = await approveMoveRequest(move_complete_id, complete_move_request_email_check);
        if(res.status === 200){
          alert("Successfully approved move");
          this.refreshGrid();
        }
      }
      if(complete_move_request_rejected_check){
        alert("Rejecting move");
        var res:any = await rejectMoveRequest(move_complete_id, complete_move_request_email_check, rejection_comments);
        if(res.status === 200){
          alert("Successfully rejected move");
          this.refreshGrid();
        }
      }
      else{
        var res:any = await completeMoveRequest(move_complete_id, complete_move_request_email_check);
        if(res.status === 200){
          alert("Successfully completed move");
          this.refreshGrid();
        }
      }
    }
    else{
      alert("Error completing move. Refresh page");
    }
    this.setState({showCompleteMovePopup:false});
  }
  openCustomMoveRequest(event:any){
    this.setState({showCustomMoveRequest:true});
  }
  closeMoveRequest(){
    this.setState({showCustomMoveRequest:false, showInitiateMovePopup:false});
    this.refreshGrid();
}
  addNewEquipmentMove(){
    this.setState({showAddNewForm:true});
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  closeEditForm(){
      this.setState({showEditForm:false, showAddNewForm:false});
      this.props.history.push('/field/equipmenttracker/equipmentmoves');
      this.refreshGrid();
  }
  editItem(id:number, data:any){
    renderPageType='';
    editData = data;
    globalEditID = id;
    this.props.history.push('/field/equipmenttracker/equipmentmoves/edit/id='+id);
    this.setState({showEditForm:true});
  }
  async refreshGrid(){
    disableEdit = localStorage.getItem("ET-Role")==="R";
    list_view = await getMappingView();
    initiate_btn_class = list_view[0].move_Status;
    copy_list = list_view;
    this.setState({loaded:true, timeoutKey: uuidv4() });
  }
  async componentDidMount(){
    hasListHistory = true;
    
    this.refreshGrid();
  }

  handleValueChange = (e:any) => {
    // console.log(e);
    const trimmedValue = e.trim();
    this.setState({searchValue:trimmedValue });
  };

    render(){
      if(this.state.loaded){
        return (
          <PageSpacing title="List View" description="List View" fixedSpaceOnSmallerScreens={true}>
          <SOCTHome history={this.props.history}></SOCTHome>

          <div style={{ top: "15%", right: "3%", position: "absolute", backgroundColor: "white" }}>
          <DataTimer
                        className="right-side"
                        key={this.state.timeoutKey}
                        onTimerEnd={this.refreshGrid}
                    />
          </div>
                    
            <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <Grid container direction="row">
                <Grid item xs={4}>
              <MUIButton onClick={this.clearFilters}  style={{backgroundColor:'#bf0000'}} variant="contained" startIcon={<DeleteIcon color='white'/>}>Clear Filters</MUIButton>
           </Grid>
           </Grid>
            <Typography className='dataGrid-title'>List View</Typography>
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={list_view}
                keyExpr="id"
                height={2200}
                width={window.innerWidth - 100}
                showBorders={true}
                wordWrapEnabled={true}
                ref={this.dataGrid}
                allowColumnReordering={true}
                > 
                <ColumnChooser   enabled={true} mode="select"></ColumnChooser>
                <Grouping autoExpandAll={false}></Grouping>
                <GroupPanel allowColumnDragging={true} visible={true}></GroupPanel>
                <Scrolling mode="virtual" />
                 <StateStoring enabled={true} type="localStorage" storageKey="storage" />
            <SearchPanel visible={true}
                width={340}
                placeholder="Search for..." 
                // text={this.state.searchValue}
                // onTextChange={this.handleValueChange}
                />    
              <Paging enabled={true} defaultPageSize={100}/>
              <Pager
                visible={true}
                allowedPageSizes={[100,150,200]}
                displayMode={'full'}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true} />
                <FilterRow visible={true}></FilterRow>
                <FilterPanel visible={true} />
   <FilterBuilderPopup position={filterBuilderPopupPosition} />
     <HeaderFilter visible={true} allowSearch={true} />
     <FilterRow visible={true}></FilterRow>
      <FilterPanel visible={true} />
      <Export enabled={true} allowExportSelectedData={true} />
       {currentUser === 'dylan.ollikka' ? 
       <Column dataField="equipment_ID" caption="Equipment ID" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
        :null}
       <Column dataField="unit_Number" caption="Unit Number" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="make" caption="Make" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="model" caption="Model" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="equipment_Type" caption="Equipment Type" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="details" caption="Details" alignment="left" width={100} headerCellRender={renderTitleHeader}/>
       <Column dataField="site_Name" caption="Location" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="sub_Site_Name" caption="Sub Site" alignment="left" width={150} headerCellRender={renderTitleHeader}/>
       <Column dataField="move_Status" caption="Move Status" alignment="left" width={100} headerCellRender={renderTitleHeader} cellRender={StyledStatusComponent}/>
       <Column dataField="job_No" caption="Job #" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column cellRender={(e:any) => {if(e.row.data.move_Status === "Initiated" || e.row.data.move_Status==="Requested") { return <div style={{color:'rgb(205, 92, 92)', fontWeight:'bold'}}>{e.row.data.site_Name}</div>}else{return <div>{e.row.data.site_Name}</div>}}} dataField="site_Name" caption="Working Site" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column cellRender={(e:any) => {if(e.row.data.move_Status === "Initiated" || e.row.data.move_Status==="Requested") { return <div style={{color:'rgb(205, 92, 92)',fontWeight:'bold'}}>{e.row.data.sub_Site_Name}</div>}else{return <div>{e.row.data.sub_Site_Name}</div>}}} dataField="sub_Site_Name" caption="Sub Site" alignment="left" headerCellRender={renderTitleHeader}/>
       <Column visible={window.innerWidth>1056} type="buttons" caption="History" headerCellRender={renderTitleHeader}  cellRender={(val) => {
                                if(val.data.add_To_SOCT ==="Yes"){
                                return <>

                               <LAIconButton icon={<SoctIcon color={BLUE_COLOR} />} label="SOCT History" 
                               onClick={(e:any)=>{this.openSOCTHistory(val)}}
                                  />
                               <LAIconButton icon={<VersionHistoryIcon color={ORANGE_COLOR} />} label="Location History" onClick={(e:any) => {this.openBillingHistory(val.data.equipment_ID, val.data)}}/>

                               </>
        }
        else{
          return <> <LAIconButton icon={<VersionHistoryIcon color={ORANGE_COLOR} />} label="Location History" onClick={(e:any) => {this.openBillingHistory(val.data.equipment_ID, val.data)}}/></>
        } }}>
       </Column>
        {!disableEdit ? 
       <Column type="buttons" caption={"Actions"} headerCellRender={renderTitleHeader}>
         <Button visible={(e:any) => {return e.row.data.move_Status!="Initiated" && e.row.data.move_Status!="Requested" && e.row.data.move_Status!="Dispatched" && e.row.data.move_Status!="En Route"}} cssClass={"initiate-btn"} onClick={(e:any) => {this.openInitiatePopup(e.row.data.equipment_ID, e.row.data)}}>Initiate</Button>
         <Button visible={(e:any) => {return e.row.data.move_Status==="Initiated" || e.row.data.move_Status==="Requested" || e.row.data.move_Status==="Dispatched" && e.row.data.move_Status!="En Route"}} cssClass={"reject-btn"} onClick={(e:any) => {this.openConfirmMovePopup(e.row.data.equipment_ID, e.row.data)}}>Move</Button>
         <Button visible={(e:any) => {return e.row.data.move_Status!=""}} cssClass={"complete-btn"} onClick={(e:any) => {this.openEditPopup(e.row.data.equipment_ID, e.row.data)}}>Complete</Button>
         <Button visible={(e:any) => {return e.row.data.move_Status!=""}} cssClass={"cancel-btn"} onClick={(e:any) => {this.openEditPopup(e.row.data.equipment_ID, e.row.data)}}>Cancel</Button>
       </Column>
       :null} 
       {disableEdit?
        <Column type="buttons" caption={"Actions"} headerCellRender={renderTitleHeader}>
         <Button visible={(e:any) => {return e.row.data.move_Status!="Initiated" && e.row.data.move_Status!="Requested" && e.row.data.move_Status!="Dispatched" && e.row.data.move_Status!="En Route" && disableEdit}} cssClass={"initiate-btn"} onClick={(e:any) => {this.openRequestMovePopup(e.row.data.equipment_ID, e.row.data)}}>Request Move</Button>
         <Button visible={(e:any) => {return (e.row.data.move_Status==="Initiated" || e.row.data.move_Status==="Requested" || e.row.data.move_Status==="Dispatched" || e.row.data.move_Status==="En Route") && disableEdit}} cssClass={"complete-btn"} onClick={(e:any) => {if(window.location.href.includes("?token")){this.openEditPopupFromApp(e.row.data.equipment_ID, e.row.data)}else{this.openEditPopup(e.row.data.equipment_ID,e.row.data)}}}>Modify</Button>
       </Column>:null}
            </DataGrid>
            {this.state.showEditForm ? <EquipmentMoveEdit history={this.props.history} locationOptions={[]} closeEquipmentMoveForm={this.closeEditForm} clickedItem={[]} clickedLocation={null} refreshGrid={this.refreshGrid}></EquipmentMoveEdit>:null}
            {this.state.showAddNewForm ? <AddNewEquipmentMoveForm isFromRequest={false} refreshGrid={this.refreshGrid.bind(this)}  closeEquipmentMoveForm={this.closeEditForm}></AddNewEquipmentMoveForm>:null}
            {this.state.showCustomMoveRequest? <CustomEquipmentMoveForm refreshGrid={this.refreshGrid.bind(this)} clickedLocation={"NA"} clickedItem={{}} locationOptions={["NA","NA"]} closeEquipmentMoveForm={this.closeMoveRequest.bind(this)}></CustomEquipmentMoveForm>:null}
            {this.state.showCompleteMovePopup? <CompleteMoveRequestPopup isApprovalButton={this.state.approvalButtonClick} titleText={"Complete Move?"} closePopup={this.closeCompleteMovePopup} confirmPopup={this.confirmMovePopup}></CompleteMoveRequestPopup>:null}
            {this.state.showInitiateMovePopup? <EquipmentMoveForm isFromRequest={this.state.isFromRequestMove} refreshGrid={this.refreshGrid.bind(this)} clickedLocation={initiate_move_object.clicked_location} clickedItem={initiate_move_object.clicked_item} locationOptions={initiate_move_object.location_options} closeEquipmentMoveForm={this.closeMoveRequest.bind(this)}></EquipmentMoveForm>:null}
            {this.state.showVersionHistory ? <MoveRequestVersionHistory id={move_version_id} open={this.state.showVersionHistory} onCancel={this.closeVersionHistory}></MoveRequestVersionHistory>:null}
            </Card>
            </Grid>
            </PageSpacing>
        );
       }else{
         return null;
       }
    }
}
export {editData, globalEditID, unitNumbers, renderPageType, current_view, hasListHistory};