import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST, IGetWorkingSiteLogVersionHistory } from "./getWorkingSiteLogVersionHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetWorkingSiteLogVersionHistoryLoadAction, IGetWorkingSiteLogVersionHistoryLoadFailedAction, IGetWorkingSiteLogVersionHistorySuccessAction, 
    getWorkingSiteLogVersionHistoryLoadFailedAction, getWorkingSiteLogVersionHistoryLoadSuccessAction } from "./getWorkingSiteLogVersionHistoryActions";

export const getWorkingSiteLogVersionHistoryEpic: Epic = (
    action$: ActionsObservable<IGetWorkingSiteLogVersionHistoryLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetWorkingSiteLogVersionHistorySuccessAction | IGetWorkingSiteLogVersionHistoryLoadFailedAction> =>
    action$.ofType(IGET_WORKING_SITE_LOG_VERSION_HISTORY_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.VERSION_HISTORY,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>): IGetWorkingSiteLogVersionHistorySuccessAction => {
                            return getWorkingSiteLogVersionHistoryLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getWorkingSiteLogVersionHistoryLoadFailedAction(response.message)))
                    )
            )
        );