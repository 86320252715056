import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_LOOKUP_REQUEST, IFormLookupRequest, IFormLookup } from "./getFormLookupConstants";

export interface IGetFormLookupLoadAction {
    type: IGET_FORM_LOOKUP_REQUEST.REQUEST;
    data: IFormLookupRequest
}
export const getFormLookupLoadAction = (data: IFormLookupRequest): IGetFormLookupLoadAction => ({
    type: IGET_FORM_LOOKUP_REQUEST.REQUEST,
    data
});
export interface IGetFormLookupSuccessAction {
    type: IGET_FORM_LOOKUP_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IFormLookup>;
}
export const getFormLookupLoadSuccessAction = (list: SurewayAPIResponse<IFormLookup>): IGetFormLookupSuccessAction => ({
    type: IGET_FORM_LOOKUP_REQUEST.SUCCESS,
    list
});
export interface IGetFormLookupLoadFailedAction {
    type: IGET_FORM_LOOKUP_REQUEST.FAILED;
    message: string;
}
export const getFormLookupLoadFailedAction = (message: string): IGetFormLookupLoadFailedAction => ({
    type: IGET_FORM_LOOKUP_REQUEST.FAILED,
    message
});
