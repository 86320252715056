import { ISurewayTokenRequestBody } from "../../react/shared/publicInterfaces";

export enum IGET_LOOKUPS_REQUEST {
    REQUEST = "getLookups/GET_LOOKUPS_REQUEST",
    SUCCESS = "getLookups/GET_LOOKUPS_SUCCESS",
    FAILED = "getLookups/GET_LOOKUPS_FAILED"
};

export interface ILookupsRequest extends ISurewayTokenRequestBody {
    request: {
        department?: "accounting";
        page?: "Kantech_Adjustments"
    }
};

export interface ILookup {
    employees: string[];
    companies: ILookupCompanies[];
    jobs: ILookupJobs[];
    userList: ILookupUsers[];
};

export interface ILookupUsers { 
    userID: number;
    userName: string;
};
export interface ILookupCompanies { 
    id: number;
    name: string;
    company: string;
};
export interface ILookupJobs { 
    id: number;
    job_No: string;
    name: string;
    client: string;
};