
export enum IADD_ATTACHMENT_COUPLER_REQUEST {
    REQUEST = "addAttachmentCoupler/ADD_ATTACHMENT_COUPLER_REQUEST",
    SUCCESS = "addAttachmentCoupler/ADD_ATTACHMENT_COUPLER_SUCCESS",
    FAILED = "addAttachmentCoupler/ADD_ATTACHMENT_COUPLER_FAILED"
};

export interface IAddAttachmentCouplerRequest {
    token: string;
    request: IAddUpdateAttachmentCoupler;
};

export interface IAddUpdateAttachmentCoupler {
    ID?: number; //Only for Update
    Name: string;
    Active: string;
    Created_By: string;
    Modified_By: string;
}