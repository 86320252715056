import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_MECHANIC_LOG_REQUEST, IAddMechanicLogRequest } from "./addMechanicLogConstants";


export interface IAddMechanicLogLoadAction {
    type: IADD_MECHANIC_LOG_REQUEST.REQUEST;
    data: IAddMechanicLogRequest
}
export const addMechanicLogLoadAction = (data: IAddMechanicLogRequest): IAddMechanicLogLoadAction => ({
    type: IADD_MECHANIC_LOG_REQUEST.REQUEST,
    data
});
export interface IAddMechanicLogSuccessAction {
    type: IADD_MECHANIC_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addMechanicLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddMechanicLogSuccessAction => ({
    type: IADD_MECHANIC_LOG_REQUEST.SUCCESS,
    message
});
export interface IAddMechanicLogLoadFailedAction {
    type: IADD_MECHANIC_LOG_REQUEST.FAILED;
    message: string;
}
export const addMechanicLogLoadFailedAction = (message: string): IAddMechanicLogLoadFailedAction => ({
    type: IADD_MECHANIC_LOG_REQUEST.FAILED,
    message
});
