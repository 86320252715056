export enum ISAVE_CREW_SIGNATURE_BY_ID_REQUEST {
    REQUEST = "saveCrewSignatureByID/SAVE_CREW_SIGNATURE_BY_ID_REQUEST",
    SUCCESS = "saveCrewSignatureByID/SAVE_CREW_SIGNATURE_BY_ID_SUCCESS",
    FAILED = "saveCrewSignatureByID/SAVE_CREW_SIGNATURE_BY_ID_FAILED"
};

export interface ISaveCrewSignatureByIDRequest {
    token: string;
    request : {
        id: number;
        signature: string;
        emp_id: number;
        name?: string;
    };
    }