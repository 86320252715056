import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IADD_MACHINE_CONTROL_REQUEST } from "./addMachineControlConstants";
import { IAddMachineControlLoadAction, IAddMachineControlLoadFailedAction, IAddMachineControlSuccessAction } from "./addMachineControlActions";

type Actions =
    | IAddMachineControlLoadAction
    | IAddMachineControlSuccessAction
    | IAddMachineControlLoadFailedAction
    | IFlushDataSuccessAction;

export const AddMachineControlReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_MACHINE_CONTROL_REQUEST.REQUEST:
            return loading;

        case IADD_MACHINE_CONTROL_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IADD_MACHINE_CONTROL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};