import React, { ReactNode, PureComponent, useState, useCallback } from "react";
import { Prompt, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { BLUE_COLOR, GREEN_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IComponentWaitingParts, IGetComponentWaitingPartsRequest } from "../../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsConstants";
import { IComponentWaitingPartsNotes, ISaveComponentWaitingPartsRequest } from "../../../redux/field/repairLine/saveComponentWaitingParts/saveComponentWaitingPartsConstants";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { getComponentWaitingPartsLoadAction } from "../../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsActions";
import { getComponentWaitingParts } from "../../../redux/field/repairLine/getComponentWaitingParts/getComponentWaitingPartsAccessor";
import { saveComponentWaitingParts } from "../../../redux/field/repairLine/saveComponentWaitingParts/saveComponentWaitingPartsAccessor";
import { saveComponentWaitingPartsLoadAction } from "../../../redux/field/repairLine/saveComponentWaitingParts/saveComponentWaitingPartsActions";
import { CheckBox, DataGrid } from "devextreme-react";
import { Column, ColumnChooser, Editing, Export, FilterPanel, FilterRow, HeaderFilter, Pager, Paging, Scrolling, SearchPanel, StateStoring } from "devextreme-react/data-grid";
import { LAButton } from "../../shared/buttons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import LAErrorBox from "../../shared/errorBox";
import LALinkButton from "../../shared/linkButton";
import { onExporting } from "../../shared/devExtreme";
import queryString from "query-string";
import { DataTimer } from "../../shared/timer";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { LACheckBox } from "../../shared/checkBox";

interface IComponentWaitingPartsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    saveComponentParts: Server<SurewayAPIResponse<string>>;
    getComponentParts: Server<SurewayAPIResponse<IComponentWaitingParts[]>>;
};

interface IComponentWaitingPartsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    saveComponentPartsRequest: (Request: ISaveComponentWaitingPartsRequest) => unknown;
    getComponentWaitingPartsRequest: (data: IGetComponentWaitingPartsRequest) => unknown;
};


interface IComponentWaitingPartsOwnProps {

};

interface IComponentWaitingPartsState {
    parts: IComponentWaitingParts[];
    editMode: boolean;
    view: string;
    serverError: string | undefined;
};

const ComponentWaitingPartsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .right-side {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .clear-filters {       
        margin: 10px 10px;
        background-color: rgb(168, 0, 0);
    };

    .green-text {
        color: ${GREEN_COLOR};
        font-size: 9px;
    };

    .blue-text {
        color: ${BLUE_COLOR};
        font-size: 9px;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IComponentWaitingPartsProps = RouteComponentProps
    & IComponentWaitingPartsStoreProps
    & IComponentWaitingPartsDispatchProps
    & IComponentWaitingPartsOwnProps;

class ComponentWaitingParts extends PureComponent<IComponentWaitingPartsProps, IComponentWaitingPartsState> {

    public constructor(props: IComponentWaitingPartsProps) {
        super(props);
        this.state = {
            parts: [],
            editMode: false,
            view: "required_Notes",
            serverError: undefined
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IComponentWaitingPartsProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.saveComponentParts !== prevProps.saveComponentParts) {
                if (this.props.saveComponentParts.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveComponentParts.message });

                if (hasPayload(this.props.saveComponentParts) && (this.props.saveComponentParts.kind === STATUS_ENUM.SUCCEEDED) && (this.state.serverError))
                    this.setState({ serverError: undefined });
            };
        };
    };

    public render(): ReactNode {

        
        const { parts, serverError, editMode, view} = this.state;
        const { getComponentParts, getToken, saveComponentParts } = this.props;
        const getRole = pageAccessCheck(getToken, "partsAccess");
        const onEditStart = (): void => this.updateEditMode(true);
        const onEditCancel = (): void => this.updateEditMode(false);
        // console.log(parts)
        const filetedParts = view === "required_Notes" ? parts.filter((x: IComponentWaitingParts) => (x.parts_Instructions && !x.parts_Notes) || (x.major_Component_Replacement === "Yes" && !x.parts_Notes) ) : parts;
        
        return (
            <PageSpacing title="Component Parts" description="FIELD - COMPONENT WAITING PARTS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <ComponentWaitingPartsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>


                            <LAGridItem xs={12} className="text-center"> 
                                <h2 className="title">Repair Line - Add Parts Notes</h2>
                                <hr />

                                {serverError && <LAErrorBox text={serverError} />}

                                {getComponentParts.kind === STATUS_ENUM.LOADING && <LALoading message="Loading parts..." />}
                                {getComponentParts.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load parts data..." />}

                                {getComponentParts.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGridItem xs={12}>
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" defaultValue={view} onChange={this.onViewChange}>
                                                <FormControlLabel value="required_Notes" name="required_Notes" control={<Radio size="small"/>} label="Required Notes" />
                                                <FormControlLabel value="All" name="All" control={<Radio size="small"/>} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>
                                        <PartsWaitingDevExtreme
                                            {...this.props}
                                            data={filetedParts}
                                            editMode={editMode}
                                            onEditStart={onEditStart}
                                            onEditCancel={onEditCancel}
                                            onSaveClick={this.onSave}
                                            getRefreshDataCall={this.getDataForTable}
                                        />
                                        
                                        <Prompt when={editMode} message="You have unsaved changes ?" />
                                        <RequestStatus requestStatus={saveComponentParts.kind} successMessage="Record successfully updated" />
                                    </LAGridItem>}
                            </LAGridItem>
                        </LAGrid>
                    </LAPaperWithPadding>
                </ComponentWaitingPartsStyles>}
            </PageSpacing>
        );
    }

    private onViewChange = (e: any): void => {
        this.setState({ view: e.target.value });
    };

    private updateEditMode = (editMode: boolean): void => {
        this.setState({ editMode });
    };

    private onSave = async (record: any): Promise<void> => {

        const { getToken, saveComponentPartsRequest } = this.props;

        if (record.changes[0] !== undefined && hasPayload(getToken)) {

            const { data } = record.changes[0];

            var dataId = +data.id;

            let request: IComponentWaitingPartsNotes = {
                id: dataId,
                Parts_Notes: data.parts_Notes,
                Parts_Dispatched: data.parts_Dispatched ? "Yes" : "No",
                Modified_By: getToken.payload.response.upn
            };

            saveComponentPartsRequest({
                request: request,
                token: getToken.payload.response.token
            });

            this.updateEditMode(false);
        };
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "partsAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getComponentParts)) {
                    this.getDataForTable();
                };
            } else {

                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

            if (hasPayload(this.props.getComponentParts)) {
                this.setState({
                    parts: this.props.getComponentParts.payload.response
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getComponentWaitingPartsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IComponentWaitingPartsStoreProps => ({
    getToken: getToken(state),
    getComponentParts: getComponentWaitingParts(state),
    saveComponentParts: saveComponentWaitingParts(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IComponentWaitingPartsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getComponentWaitingPartsRequest: (data: IGetComponentWaitingPartsRequest) => dispatch(getComponentWaitingPartsLoadAction(data)),
    saveComponentPartsRequest: (request: ISaveComponentWaitingPartsRequest): unknown => dispatch(saveComponentWaitingPartsLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentWaitingParts);

const storageKey = "parts-storage-key";
interface IPartsWaitingDevExtreme extends RouteComponentProps {
    data: IComponentWaitingParts[];
    editMode: boolean;
    onEditCancel: () => void;
    getRefreshDataCall?: () => void;
    onSaveClick: (record: any) => void;
    onEditStart: (record: any) => void;
};

const PartsWaitingDevExtreme: React.FC<IPartsWaitingDevExtreme> = React.memo((props: IPartsWaitingDevExtreme) => {

    const onExportClick = (e: any): void => onExporting(e, "Parts_Waiting");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');

    const loadState = useCallback(() => {
        if (storageKey) {
            let data = localStorage.getItem(storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (storageKey)
            localStorage.setItem(storageKey, JSON.stringify(state));
    }, []);


    const clearFilters = async (): Promise<void> => {
        if (storageKey) {
            const getCurrent = localStorage.getItem(storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

        }
    };

    const onRepairLine = (data: IComponentWaitingParts): void => {
        // console.log(data);
        // callRouteWithQueryString({
        //     route: props,
        //     search: { id: data.repair_Line_ID.toString(), token: getTokenFromUrl(true) },
        //     pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
        // });
        if(data.id && data.repair_Line_ID)
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString() + "&defect_ID=" + data.id.toString()+ "&view=All")
    
    };

    const highlightRowClass = (): number | undefined => {
        const query: any = queryString.parse(props.location.search);
        if(query && query.id && query.id !== "0"){
            const idx = props.data.findIndex(row => row.id === +query.id);
            if (idx >= 0) {
                return idx;
            }
        }
        return undefined;
    };

    const getPriorityColor = (priority:string) => {
        switch (priority) {
          case "Low":
            return GREEN_COLOR;
          case "Medium":
            return BLUE_COLOR;
          case "Urgent":
            return ORANGE_COLOR;
          case "Down":
            return LIGHT_RED_COLOR;
          default:
            return "";
        }
      };

    const handleValueChange = (e:any) => {
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };

    return (
        <LAGrid>
            <LAGridItem xs={12}>
                <LAButton
                    label="Clear Filters"
                    className="clear-filters"
                    onClick={clearFilters}
                    disabled={undefined}
                />

                {props.getRefreshDataCall && <DataTimer
                    key={storageKey}
                    className="right-side"
                    onTimerEnd={props.getRefreshDataCall}
                />}
            </LAGridItem>

            <LAGridItem xs={12}>
                <DataGrid
                    keyExpr="id"
                    focusedRowEnabled={highlightRowClass() ? true : false}
                    focusedRowIndex={highlightRowClass()}
                    showBorders={true}
                    columnAutoWidth={true}
                    repaintChangesOnly={true}
                    onInitNewRow={props.onEditCancel}
                    onSaved={props.onSaveClick}
                    onExporting={onExportClick}
                    rowAlternationEnabled={true}
                    onEditingStart={props.onEditStart}
                    onEditCanceled={props.onEditCancel}
                    dataSource={Object.values(props.data)}
                    syncLookupFilterValues={true}
                    columnHidingEnabled={true} 
                    wordWrapEnabled={true}
                    onCellPrepared={(e: CellPreparedEvent<any, unknown>) => {
                        if(e.column.dataField === "priority" && e.data) {
                            if (e.data.priority) { 
                                e.cellElement.style.backgroundColor = getPriorityColor(e.data.priority);
                            }
                        }
                        if(e.column.dataField === "major_Component_Replacement" && e.data) {
                            if (e.data.major_Component_Replacement === "Yes") { 
                                e.cellElement.style.backgroundColor = LIGHT_RED_COLOR;
                            }
                        }
                    }}                   
                    onToolbarPreparing={(e) => {
                        let toolbarItems: any = e.toolbarOptions.items;

                        toolbarItems.forEach(function (item: any) {
                            if (item.name === "addRowButton") {
                                item.location = "before";
                            }
                        });
                    }}
                    filterSyncEnabled={false}
                >
             
                    <Paging
                        defaultPageSize={100}
                    />
                    <Pager
                        visible={true}
                        allowedPageSizes={"auto"}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                    <Export enabled={true} />
                    <Scrolling columnRenderingMode="virtual" />
                    <FilterRow visible={true} />
                    <HeaderFilter allowSearch={true} visible={true} />
                    <FilterPanel visible={false} />
                    <StateStoring

                        storageKey={storageKey}
                        enabled={true}
                    />
                    <SearchPanel
                        visible={true}
                        width={300}
                        placeholder="Search..."
                        // text={searchValue}
                        // onTextChange={handleValueChange}
                    />
                    <StateStoring
                        type="custom"
                        customLoad={loadState}
                        customSave={saveState}
                        storageKey={storageKey}
                        enabled={true}
                    />
                    <Scrolling columnRenderingMode="virtual" />
                    <Editing
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false}
                        mode="row"
                    />

                    <ColumnChooser
                        enabled={true}
                        mode="select"
                        height={window.innerHeight - 100}
                    />
                   
                   <Column dataField="button" caption="Repair Line" type="button" width={100}
                        cellRender={(val) => {
                            return <LALinkButton className="linkUrl" label="View" onClick={() => onRepairLine(val.data)} />
                        }} 
                        allowReordering={false} 
                    />
                    <Column dataField="unit_No" width={150} caption="Unit/Attachment" allowEditing={false} />
                    <Column dataField="component" width={200} caption="Defect Item" allowEditing={false} />
                    <Column dataField="priority" width={100} caption="Priority" allowEditing={false} />
                    <Column dataField="foreman_Comments" caption="Issue" allowEditing={false} />
                    <Column dataField="major_Component_Replacement" caption="Major Component Replacement" allowEditing={false} />
                    <Column dataField="parts_Instructions" width={300} caption="Parts Instructions" allowEditing={false} />
                    <Column dataField="parts_Notes" width={300} caption="Parts Notes" allowEditing={true} />
                    <Column dataField="parts_Dispatched" caption="Parts Dispatched" allowEditing={true}  alignment={"center"} dataType="boolean"
                        cellRender={(val) => {
                            // console.log(val)
                            if (val.data) {
                                return <CheckBox 
                                    disabled={true}
                                    value={val.data.parts_Dispatched === "Yes" ? true : false} 
                                    onValueChanged={undefinedFunction}
                            />
                            }
                        }}
                    >
                    </Column>
                </DataGrid>
            </LAGridItem>

        </LAGrid>
    )
});