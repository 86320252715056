import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANIC_LOGS_BY_ID_REQUEST {
    REQUEST = "getMechanicLogsById/GET_MECHANIC_LOGS_BY_ID_REQUEST",
    SUCCESS = "getMechanicLogsById/GET_MECHANIC_LOGS_BY_ID_SUCCESS",
    FAILED = "getMechanicLogsById/GET_MECHANIC_LOGS_BY_ID_FAILED"
};

export interface IGetMechanicLogsByIdRequest extends ISurewayTokenRequestBody {
    request: {
        mechanic_id: number;
    };
};

export interface IMechanicLogById {
    id: number;
    component_ID: number;
    mechanic_ID: number;
    date: string;
    hours?: number;
    comments: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};