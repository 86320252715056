import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IUPDATE_JOB_MHR_REQUEST {
    REQUEST = "updateJobMhr/UPDATE_JOB_MHR_REQUEST",
    SUCCESS = "updateJobMhr/UPDATE_JOB_MHR_SUCCESS",
    FAILED = "updateJobMhr/UPDATE_JOB_MHR_FAILED"
};

export interface IUpdateJobMhrRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
        Job: string;
        Year: number;
        Code: string;
        Description: string;
        Requested_By: string;
        Location: string;
        Active: string;
        Modified_By: string;
    };
};