import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { STATUS_ENUM, Server, hasPayload } from "../../../../redux/server";
import { IETDriver, IGetETDriverRequest } from "../../../../redux/field/driver/getDrivers/getDriversConstants";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { IAddETDriverRequest } from "../../../../redux/field/driver/addDriver/addDriverConstants";
import { IUpdateETDriverRequest } from "../../../../redux/field/driver/updateDriver/updateDriverConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import LAGridItem from "../../../shared/gridList";
import LAGrid from "../../../shared/grid";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ROUTE } from "../../../routes";
import { YesOrNo, ZEROTH, getTokenFromUrl, undefinedFunction } from "../../../shared/constExports";
import { getDrivers } from "../../../../redux/field/driver/getDrivers/getDriversAccessor";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getDriversLoadAction } from "../../../../redux/field/driver/getDrivers/getDriversActions";
import { addDriverLoadAction } from "../../../../redux/field/driver/addDriver/addDriverActions";
import { updateDriverLoadAction } from "../../../../redux/field/driver/updateDriver/updateDriverActions";
import { updateDriverStatus } from "../../../../redux/field/driver/updateDriver/updateDriverAccessor";
import { addDriverStatus } from "../../../../redux/field/driver/addDriver/addDriverAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import LATextField from "../../../shared/textField";
import LAAutoComplete from "../../../shared/autoComplete";
import { LACheckBox } from "../../../shared/checkBox";
import LATextArea from "../../../shared/textArea";


const RequiredFields: string[] = ["driver_Firstname", "driver_Lastname", "phone_Number"];

interface IAddUpdateDriverComponentStoreProps {
    getDriver: Server<IETDriver[]>;
    addDriverStatus: Server<string>;
    updateDriverStatus: Server<string>;
    getToken: Server<SurewayAPIResponse<IToken>>;
};

interface IAddUpdateDriverComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getDriversRequest: (data: IGetETDriverRequest) => unknown;
    addDriverRequest: (data: IAddETDriverRequest) => unknown;
    updateDriverRequest: (data: IUpdateETDriverRequest) => unknown;
};

interface IAddUpdateDriverOwnProps {

};

interface IAddUpdateDriverComponentState {
    data: IETDriver;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateDriverStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .left-Reset{
        margin-left:4px;
    }
`;

type IAddUpdateDriverComponentProps =
    RouteComponentProps
    & IAddUpdateDriverOwnProps
    & IAddUpdateDriverComponentStoreProps
    & IAddUpdateDriverComponentDispatchProps;

class AddUpdateETDriver extends PureComponent<IAddUpdateDriverComponentProps, IAddUpdateDriverComponentState> {

    public constructor(props: IAddUpdateDriverComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                driver_Firstname: "",
                driver_Lastname: "",
                notes: "",
                active: YesOrNo[0].name,
                email: "",
                assigned_Unit_Number: "",
                created: "",
                modified: "",
                created_By: "",
                modified_By: "",
                pilot_Driver: "",
                truck_Driver: "",
                token: "",
                phone_Number: "",
                license: ""
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateDriverComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addDriverStatus !== prevProps.addDriverStatus) {

                if (this.props.addDriverStatus.kind === STATUS_ENUM.FAILED) {
                    const serverError = this.props.addDriverStatus.message;
                    this.setState({ serverError: serverError !== null ? serverError : "" });
                }

                if (hasPayload(this.props.addDriverStatus) && this.props.addDriverStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateDriverStatus !== prevProps.updateDriverStatus) {

                if (this.props.updateDriverStatus.kind === STATUS_ENUM.FAILED) {
                    const serverError = this.props.updateDriverStatus.message;
                    this.setState({ serverError: serverError !== null ? serverError : "" });
                }

                if (hasPayload(this.props.updateDriverStatus) && this.props.updateDriverStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { addDriverStatus, updateDriverStatus } = this.props;
        const onPilotDriver = (checked: boolean, name?: string | undefined): void => this.handleChange("pilot_Driver", checked ? "Yes" : "No");
        const onTruckDriver = (checked: boolean, name?: string | undefined): void => this.handleChange("truck_Driver", checked ? "Yes" : "No");
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);

        return (
            <AddUpdateDriverStyles>
                <LAGrid spacing={1}>

                    <LAGridItem xs={12} sm={12} md={8}>
                        <LAButton
                            label="Back to List"
                            onClick={this.handleCancel}
                            startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={12} className="text-center">
                        <h2>DRIVER DETAILS</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            name="driver_Firstname"
                            label="First Name"
                            fullWidth={true}
                            variant="outlined"
                            value={data.driver_Firstname}
                            onChange={this.handleChange}
                            errorText={errors["driver_Firstname"] ? errors["driver_Firstname"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            name="driver_Lastname"
                            label="Last Name"
                            fullWidth={true}
                            variant="outlined"
                            value={data.driver_Lastname}
                            onChange={this.handleChange}
                            errorText={errors["driver_Lastname"] ? errors["driver_Lastname"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            name="phone_Number"
                            label="Phone Number"
                            fullWidth={true}
                            variant="outlined"
                            value={data.phone_Number}
                            onChange={this.handleChange}
                            errorText={errors["phone_Number"] ? errors["phone_Number"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            name="email"
                            label="Email"
                            fullWidth={true}
                            variant="outlined"
                            value={data.email}
                            onChange={this.handleChange}
                            errorText={errors["email"] ? errors["email"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={YesOrNo}
                            getOptionLabel="name"
                            autoHighlight={true}
                            onChange={onActive}
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="Active"
                            selectionRemove={undefinedFunction}
                            value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={2}>
                        <LACheckBox
                            label="Pilot Driver"
                            name="pilot_Driver"
                            onChange={onPilotDriver}
                            value={data.pilot_Driver === "Yes" ? true : false}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={2}>
                        <LACheckBox
                            label="Truck Driver"
                            name="truck_Driver"
                            onChange={onTruckDriver}
                            value={data.truck_Driver === "Yes" ? true : false}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            name="license"
                            label="License"
                            fullWidth={true}
                            variant="outlined"
                            value={data.license}
                            onChange={this.handleChange}
                            errorText={errors["license"] ? errors["license"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextArea
                            minRows={3}
                            rowsMax={4}
                            fullWidth={true}
                            name="notes"
                            label="Notes"
                            value={data.notes}
                            onChange={this.handleChange}
                            errorText={errors["notes"] ? errors["notes"].message : undefined}
                        />
                    </LAGridItem>


                    <LAGridItem xs={12} sm={6} md={8}>
                    </LAGridItem>

                    {serverError.length > ZEROTH && <LAGridItem xs={12}>
                        <LAErrorBox text={serverError} />
                    </LAGridItem>}

                    <LAGridItem xs={12} sm={12} md={4}>
                        <LASaveAndCancelButton
                            fullWidth={true}
                            saveButtonText="Save"
                            onSave={this.handleSave}
                            cancelButtonText="Close"
                            onCancel={this.handleCancel}
                            disableSave={Object.values(errors).length > 0 ? true : undefined}
                        />
                    </LAGridItem>

                </LAGrid>

                <RequestStatus requestStatus={addDriverStatus.kind} successMessage="Driver has been added successfully" />
                <RequestStatus requestStatus={updateDriverStatus.kind} successMessage="Driver has been updated successfully" />
            </AddUpdateDriverStyles>
        );
    }


    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.EQUIPMENT_TRACKER.DRIVERS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {
        const { id, notes, active, driver_Firstname, driver_Lastname, pilot_Driver, phone_Number, email, license, truck_Driver } = this.state.data;

        if (hasPayload(this.props.getToken)) {

            if (id === 0) {
                this.props.addDriverRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: id,
                        Email: email,
                        Notes: notes,
                        Active: active,
                        License: license,
                        Pilot_Driver: pilot_Driver,
                        Truck_Driver: truck_Driver,
                        Phone_Number: phone_Number,
                        Driver_Lastname: driver_Lastname,
                        Driver_Firstname: driver_Firstname,
                        Created_By: this.props.getToken.payload.response.upn,
                        Modified_By: this.props.getToken.payload.response.upn

                    }
                });
            } else {
                this.props.updateDriverRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: id,
                        Email: email,
                        Notes: notes,
                        Active: active,
                        License: license,
                        Pilot_Driver: pilot_Driver,
                        Truck_Driver: truck_Driver,
                        Phone_Number: phone_Number,
                        Driver_Lastname: driver_Lastname,
                        Driver_Firstname: driver_Firstname,
                        Created_By: this.props.getToken.payload.response.upn,
                        Modified_By: this.props.getToken.payload.response.upn
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;

            if (name === "phone_Number")
                rules.phone = true;

            errors = this.errorChecker(name, value, errors, rules);
        };

        if (name === "email") {
            rules.email = true;
            errors = this.errorChecker(name, value, errors, rules);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.getToken)) {

                if (query.id !== "0") {
                    if (hasPayload(this.props.getDriver)) {
                        const data = this.props.getDriver.payload.find(x => x.id === Number(query.id));
                        if (data) {
                            this.setState({
                                data
                            });
                        };
                    } else {
                        this.props.getDriversRequest({
                            token: this.props.getToken.payload.response.token
                        });
                    }
                } else {
                    const errors: ById<IFieldErrorKeyValue> = {};

                    RequiredFields.forEach((x) => {
                        errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    });

                    this.setState({
                        ...this.state,
                        errors
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules?: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules ?? { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateDriverComponentStoreProps => ({
    getToken: getToken(state),
    getDriver: getDrivers(state),
    addDriverStatus: addDriverStatus(state),
    updateDriverStatus: updateDriverStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateDriverComponentDispatchProps => ({
    addDriverRequest: (data: IAddETDriverRequest) => dispatch(addDriverLoadAction(data)),
    getDriversRequest: (data: IGetETDriverRequest) => dispatch(getDriversLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    updateDriverRequest: (data: IUpdateETDriverRequest) => dispatch(updateDriverLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateETDriver);