import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_HAZARD_TYPE_REQUEST } from "./addHazardTypeConstants";
import { IAddHazardTypeLoadAction, IAddHazardTypeLoadFailedAction, IAddHazardTypeSuccessAction, addHazardTypeLoadFailedAction, addHazardTypeLoadSuccessAction } from "./addHazardTypeActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const addHazardTypeEpic: Epic = (
    action$: ActionsObservable<IAddHazardTypeLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddHazardTypeSuccessAction | IAddHazardTypeLoadFailedAction> =>
    action$.ofType(IADD_HAZARD_TYPE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.ADMIN.ADD_HAZARD_TYPE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddHazardTypeSuccessAction | IAddHazardTypeLoadFailedAction => {
                            if(response.message === "Success"){
                                return addHazardTypeLoadSuccessAction(response);
                            } else {
                                return addHazardTypeLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addHazardTypeLoadFailedAction(response.message)))
                    )
            )
        );