
export enum IGET_UNIT_HISTORY_BY_ID_REQUEST {
    REQUEST = "getUnitHistoryById/GET_UNIT_HISTORY_BY_ID_REQUEST",
    SUCCESS = "getUnitHistoryById/GET_UNIT_HISTORY_BY_ID_SUCCESS",
    FAILED = "getUnitHistoryById/GET_UNIT_HISTORY_BY_ID__FAILED"
};

export interface IGetUnitHistoryByIdRequest {
    request: {
        id: number;
    };
    token: string;
};

export interface IGetUnitHistoryById {
    roW_ID: number;
    item_ID: number;
    type: string;
    unit_ID: number;
    unit_Number: string;
    date: string;
    hours: number;
    location: string;
    service_Type: string;
    comments: string;
    custom_Service: string;
    created_By: string;
    service_Truck: string;
    modified_By: string;
    modified: string;
    latitude: string;
    longitude: string;
    color: string;
};