import { IAttachments, ISurveyAttachmentPicture } from "../getSurveyAttachment/getSurveyAttachmentsConstants";

export enum IADD_SURVEY_ATTACHMENT_REQUEST {
    REQUEST = "addSurveyAttachment/ADD_SURVEY_ATTACHMENT_REQUEST",
    SUCCESS = "addSurveyAttachment/ADD_SURVEY_ATTACHMENT_SUCCESS",
    FAILED = "addSurveyAttachment/ADD_SURVEY_ATTACHMENT_FAILED"
};

export interface IAddUpdateSurveyAttachmentRequest {
    token: string;
    request: IAddUpdateSurveyAttachment;
};

export interface IAddUpdateSurveyAttachment {
    ID: number;
    Attachment_ID: number;
    G_H: number | null;
    G_J: number | null;
    Width: number | null;
    J_K: number | null;
    J_J1: number | null;
    Notes: string;
    Pictures: ISurveyAttachmentPicture[];
    Created_By: string;
    Modified_By: string;
}