import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IStore, IDispatch } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import LAGridItem from "../../../shared/gridList";
import LAGrid from "../../../shared/grid";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { MEDIA_QUERY_PHONE } from "../../../shared/theme";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { userName, ZEROTH, undefinedFunction, getTokenFromUrl, pageAccessCheck, } from '../../../shared/constExports';
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton, LAGreenButton } from "../../../shared/buttons";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../../shared/paper";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { FormsMenu, Navbar } from "../../../shared/navbars";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAAutoComplete from "../../../shared/autoComplete";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { IFormLookup, IFormLookupRequest } from "../../../../redux/field/getFormLookup/getFormLookupConstants";
import { getFormLookup } from "../../../../redux/field/getFormLookup/getFormLookupAccessor";
import { getFormLookupLoadAction } from "../../../../redux/field/getFormLookup/getFormLookupActions";
import { ICleanupData } from "../../../../redux/field/getCleanupData/getCleanupDataConstants";
// import { getCleanupData } from "../../../../redux/field/getCleanupData/getCleanupDataAccessor";
// import { getCleanupDataLoadAction } from "../../../../redux/field/getCleanupData/getCleanupDataActions";
import { END_POINTS } from "../../../../redux/endpoints";
import { IRemoveDepartmentFromItemsRequest } from "../../../../redux/field/removeDepartmentFromItems/removeDepartmentFromItemsConstants";
import { removeDepartmentFromItems } from "../../../../redux/field/removeDepartmentFromItems/removeDepartmentFromItemsAccessor";
import { removeDepartmentFromItemsLoadAction } from "../../../../redux/field/removeDepartmentFromItems/removeDepartmentFromItemsActions";

const typeOptions : IIdName[] = [
    { id: 1, name: "Tasks" },
    { id: 2, name: "Hazards" },
    { id: 3, name: "Control Plans" },
];

const RequiredFields: string[] = ["department_ID","type",];

interface IFormCleanupStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFormLookup>>;
    // getCleanupData: Server<SurewayAPIResponse<IGetCleanupData>>;
    removeDepartmentFromItems: Server<SurewayAPIResponse<string>>;
};

interface IFormCleanupDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFormLookupRequest) => unknown;
    // getCleanupDataRequest: (data: IGetCleanupDataRequest) => unknown;
    removeDepartmentFromItemsRequest: (request: IRemoveDepartmentFromItemsRequest) => unknown;
};

interface IFormCleanupState {
    data: any;
    cleanupData: ICleanupData[] | undefined;
    removeItemsIds: number[];
    errors: ById<IFieldErrorKeyValue>;
    serverError: string;
};


const FormCleanupStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    
    .dx-datagrid-save-button {
        display: none;
    };

    .dx-datagrid-cancel-button {
        display: none;
    };

    .clear-filters{       
        float: left;
        background-color: rgb(168, 0, 0);        
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IFormCleanupProps =
    RouteComponentProps
    & IFormCleanupStoreProps
    & IFormCleanupDispatchProps;

class FormCleanupComponent extends PureComponent<IFormCleanupProps, IFormCleanupState> {

    public constructor(props: IFormCleanupProps) {
        super(props);
        this.state = {
            data: {
                department_ID: 0,
                type: "",
            },
            cleanupData: undefined,
            errors: {},
            removeItemsIds: [],
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLookUp();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IFormCleanupProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.removeDepartmentFromItems !== prevProps.removeDepartmentFromItems) {

                if (this.props.removeDepartmentFromItems.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.removeDepartmentFromItems.message });

                if (hasPayload(this.props.removeDepartmentFromItems) && this.props.removeDepartmentFromItems.kind === STATUS_ENUM.SUCCEEDED) {
                    this.setState({
                        ...this.state,
                        data: {
                            department_ID: 0,
                            type: "",
                        },
                        cleanupData: undefined,
                        removeItemsIds: []
                    });
                }
            };
        };
    }

    public render(): ReactNode {

        const { getToken, getLookup, removeDepartmentFromItems} = this.props;
        const getRole = pageAccessCheck(getToken, "formsAdmin");
        const getCleanUpRole = pageAccessCheck(getToken, "formsCleanupAccess");
        const { data, cleanupData, serverError , errors} = this.state;
        const departmentList = hasPayload(getLookup) ? getLookup.payload.response.form_departments : [];
        const disableFetch = (Object.values(errors).length > 0) ? true : undefined;
        
        const onDepartment = (event: unknown, value: any): void => this.handleChange("department_ID", value !== null ? value.id : data.department_ID);
        const onType = (event: unknown, value: IIdName): void => this.handleChange("type", value !== null ? value.name : data.type);

        return (
            <PageSpacing title="Clean Up - Form" description="FORM - CLEAN UP" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={FormsMenu} role={getRole === true ? true : getCleanUpRole === true ? "formsCleanupAccess": getRole}/>
                {(getRole !== NotApplicable || getCleanUpRole !== NotApplicable) && 
                    <FormCleanupStyles>
                        <LAPaperWithPadding>
                            <LAGrid spacing={1}>
                                <LAGridItem xs={12} className="text-center">
                                    <h2>CLEAN UP</h2>
                                    <hr />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <strong>Department</strong>
                                    <LAAutoComplete
                                        disabled={undefined}
                                        multiple={false}
                                        option={departmentList ?? []}
                                        autoHighlight={true}
                                        onChange={onDepartment}
                                        filterSelectedOptions={true}
                                        getOptionLabel="name"
                                        dropDownPlaceHolder=""
                                        errorText={errors["department_ID"] ? errors["department_ID"].message : undefined}
                                        selectionRemove={undefinedFunction}
                                        value={data.department_ID ? departmentList && departmentList.find(q => q.id === data.department_ID) : null}
                                        defaultValue={data.department_ID ? departmentList && departmentList.find(q => q.id === data.department_ID) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <strong>Type</strong>
                                    <LAAutoComplete
                                        disabled={undefined}
                                        multiple={false}
                                        option={typeOptions}
                                        getOptionLabel="name"
                                        autoHighlight={true}
                                        onChange={onType}
                                        filterSelectedOptions={true}
                                        errorText={errors["type"] ? errors["type"].message : undefined}
                                        dropDownPlaceHolder=""
                                        selectionRemove={undefinedFunction}
                                        value={data.type ? typeOptions.find(q => q.name === data.type) : null}
                                        defaultValue={data.type ? typeOptions.find(q => q.name === data.type) : null}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4} >
                                    <LAGreenButton
                                        className="mt-4" 
                                        label="Fetch"
                                        disabled={disableFetch}
                                        onClick={this.handleFetchClick}
                                    />
                                </LAGridItem>


                                {(data.department_ID > 0 && data.type && cleanupData && serverError.length === ZEROTH) && 
                                    <LAGridItem xs={12}>
                                        <LAGridItem xs={12} className="text-center">
                                            <LAGridItem xs={12} >
                                                <LAButton
                                                    className="mt-2" 
                                                    label="Remove Selected Items for this department"
                                                    onClick={this.onSave}
                                                />
                                            </LAGridItem>
                                            <LADevExtremeGrid
                                                id="cleanUpGrid"
                                                data={cleanupData ?? []}
                                                searchPanel={true}
                                                filterHeader={true}
                                                columnsHiding={true}
                                                customRowColor={true}
                                                key="id"
                                                // export={true}
                                                onClick={undefinedFunction}
                                                exportFileName="PayrollMechanicLogs"
                                                selectionRow={true}
                                                paging={20}
                                                height={900}
                                                onSelectionChanged={this.onSelectionChange}
                                                storageKey={`FormCleanup${data.type}SessionStorage`}
                                                columns={[
                                                    { name: "name", caption: "Task", type: "string", show: data.type === "Tasks" },
                                                    { name: "control_Plan", caption: "Control Plan", type: "string", show: data.type === "Control Plans" },
                                                    { name: "hazard", caption: "Hazard", type: "string", show: data.type === "Hazards" || data.type === "Control Plans"  },
                                                    { name: "type", caption: "Hazard Type", type: "string", show: data.type === "Hazards" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", sortDesc: true },
                                                ]}
                                            />

                                            <LAButton
                                                className="mt-2" 
                                                label="Remove Selected Items for this department"
                                                onClick={this.onSave}
                                            />
                                        </LAGridItem>
                                    </LAGridItem>
                                }
                                {((serverError !== null) && (serverError.length > ZEROTH)) && 
                                    <LAGridItem xs={12}>
                                        <LAErrorBox text={serverError} />
                                    </LAGridItem>
                                }

                                <RequestStatus requestStatus={removeDepartmentFromItems.kind} successMessage="Your Request is Successful" failedMessage={removeDepartmentFromItems.kind === STATUS_ENUM.FAILED ? removeDepartmentFromItems.message : "Your Request has failed. Please try again or contact IT.Developers for assistance."}/>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </FormCleanupStyles>
                }
            </PageSpacing>
        );
    }

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        // console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: (name === "type_ID") ? Number(value) : value
            },
            cleanupData: undefined,
            removeItemsIds: [],
            errors
        });
    };

    private handleFetchClick = async(): Promise<void> => {
        const { data } = this.state;
        localStorage.removeItem(`FormCleanup${data.type}SessionStorage`);
        if (hasPayload(this.props.getToken)) {
            await fetch(END_POINTS.FIELD.FORMS.CLEANUP.GET_CLEANUP_DATA, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        department_id: data.department_ID,
                        page: data.type
                    },
                })
            })
                .then((res: any) => {
                    return res.json();
                })
                .then(res => {
                    // console.log(res);
                    if(res.message === "Success") {
                        this.setState({
                            ...this.state,
                            cleanupData: res.response.payload,
                        });
                    }
                    else {
                        this.setState({
                            ...this.state,
                            serverError: res.message,
                        });
                    }
                })
                .catch((res) => console.log(res)); 
        }
    }

    private onSelectionChange = (row: any): void => {
        const selectedList: ICleanupData[] = row.selectedRowsData;
        const removeIdsList: any = [];
        selectedList.map(({ id }) => removeIdsList.push(id))
        // console.log(removeIdsList);

        this.setState({
            ...this.state,
            removeItemsIds: removeIdsList
        });
    };

    private onSave = (): void => {
        const { getToken, removeDepartmentFromItemsRequest } = this.props;
        const { removeItemsIds, data } = this.state;
        // console.log(removeItemsIds);

        if (hasPayload(getToken)) {
            if (removeItemsIds.length > 0)
                removeDepartmentFromItemsRequest({
                    token: getToken.payload.response.token,
                    request: {
                        department_id: data.department_ID,
                        page: data.type,
                        items: removeItemsIds,
                        username: getToken.payload.response.upn
                    }
                });
        };
    };

    private getLookUp = (): void => {
        if (hasPayload(this.props.getToken))
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Cleanup"
                }
            });
    };

    private setDataToState = async (): Promise<void> => {
            
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "formsAdmin") !== NotApplicable) {
                const errors: ById<IFieldErrorKeyValue> = {};
                
                RequiredFields.forEach((x) => {
                    errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                });

                this.setState({ errors });
            } 
            else {

                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
            let rules: IFieldValidatorProps = { required: true, minLength: 1 };
    
            const result = FieldValidator(value, rules);
            const err: ById<IFieldErrorKeyValue> = errors;
    
            // console.error(result)
            if (result.length > 0) {
                err[name] = { key: name, message: result };
            } else {
                delete err[name];
            }
            return err;
        };
    

}

const mapStateToProps = (state: IStore): IFormCleanupStoreProps => ({
    getToken: getToken(state),
    getLookup: getFormLookup(state),
    // getCleanupData: getCleanupData(state),
    removeDepartmentFromItems: removeDepartmentFromItems(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IFormCleanupDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFormLookupRequest) => dispatch(getFormLookupLoadAction(data)),
    // getCleanupDataRequest: (data: IGetCleanupDataRequest) => dispatch(getCleanupDataLoadAction(data)),
    removeDepartmentFromItemsRequest: (data: IRemoveDepartmentFromItemsRequest) => dispatch(removeDepartmentFromItemsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormCleanupComponent);