import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_AUTHORIZATION_REQUEST, IAuthorization } from "./getAuthorizationConstants";
import { IGetAuthorizationLoadAction, IGetAuthorizationLoadFailedAction, IGetAuthorizationSuccessAction, getAuthorizationLoadFailedAction, getAuthorizationLoadSuccessAction } from "./getAuthorizationActions";
import { SurewayAPIResponse } from "../../react/shared/publicInterfaces";
import { IStore } from "../reducers";
import { EpicDependencies } from "../store";
import { END_POINTS } from "../endpoints";
import { withJsonContentType } from "../epicUtils";

export const getAuthorizationEpic: Epic = (
    action$: ActionsObservable<IGetAuthorizationLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAuthorizationSuccessAction | IGetAuthorizationLoadFailedAction> =>
    action$.ofType(IGET_AUTHORIZATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IAuthorization[]>>(
                    END_POINTS.GET_AUTHORIZATION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IAuthorization[]>): IGetAuthorizationSuccessAction | IGetAuthorizationLoadFailedAction => {
                            if(response.message === "Success"){
                                return getAuthorizationLoadSuccessAction(response);
                            }
                            return getAuthorizationLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<IAuthorization>) => ActionsObservable.of(getAuthorizationLoadFailedAction(response.message)))
                    )
            )
        );