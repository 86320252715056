import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ROVER_KIT_REQUEST, IRoverKit } from "./getRoverKitsConstants";
import { IGetRoverKitLoadAction, IGetRoverKitLoadFailedAction, IGetRoverKitSuccessAction, getRoverKitLoadFailedAction, getRoverKitLoadSuccessAction } from "./getRoverKitsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getRoverKitEpic: Epic = (
    action$: ActionsObservable<IGetRoverKitLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRoverKitSuccessAction | IGetRoverKitLoadFailedAction> =>
    action$.ofType(IGET_ROVER_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IRoverKit>>>(
                    END_POINTS.SURVEY.GET_ROVER_KITS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IRoverKit>>): IGetRoverKitSuccessAction => {
                            return getRoverKitLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRoverKitLoadFailedAction(response.message)))
                    )
            )
        );