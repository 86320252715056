import { SOCT_URL } from "../../../../shared/styles";

async function AddMeterReading(requestObject:any, currentUser:string){
    
    var update_location_check:string;
    var meter_replacement_check:string;
    if(requestObject.updateLocationCheck){
      update_location_check = "Yes";
    }
    else{
      update_location_check = "No";
    }
    if(requestObject.meterReplacementCheck){
      meter_replacement_check = "Yes";
    }
    else{
      meter_replacement_check = "No";
    }
    let responseStatus = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            Unit_Number:requestObject.unit_Number,
            Previous_Date:requestObject.previous_Date,
            Previous_Reading:requestObject.previous_Reading,
            Date:requestObject.date,
            ECM:requestObject.ecm,
            Current_Hours:requestObject.current_Hours,
            Comments:requestObject.comments,
            Location:requestObject.location,
            Current_Sub_Location:requestObject.current_Sub_Location,
            Current_Sub_Location_ID:requestObject.current_Sub_Location_ID,
            New_Location:requestObject.new_Location,
            Sub_Location:requestObject.sub_Location,
            Sub_Location_ID:requestObject.sub_Location_ID,
            Update_Location_Check:update_location_check,
            Meter_Replacement:requestObject.replacementType,
            Replacement_Date:requestObject.replacement_date,
            Meter_Reset:requestObject.meter_reset,
            Created_By:currentUser,
            Modified_By:currentUser,
            SOCT_ID:requestObject.SOCT_ID
            }
    })
  }
    await fetch(SOCT_URL+'/api/SOCT/AddSOCTMeterReading', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}

async function UpdateMeterReading(meter_reading_object:any, currentUser:string){
  var update_location_check:string, cancelled:string;
  if(meter_reading_object.updateLocationCheck){
    update_location_check = "Yes";
  }
  else{
    update_location_check = "No";
  }
  if(meter_reading_object.cancelMeterReadingCheck){
    cancelled = "Yes";
  }
  else{
    cancelled = "No";
  }
  let responseStatus = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
      token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
      request:{
          ID:meter_reading_object.ID,
          Unit_Number:meter_reading_object.unit_Number,
          Previous_Date:meter_reading_object.previous_Date,
          Previous_Reading:meter_reading_object.previous_Reading,
          Date:meter_reading_object.date,
          ECM:Number(meter_reading_object.ecm),
          Comments:meter_reading_object.comments,
          Current_Hours:meter_reading_object.current_Hours,
          Location:meter_reading_object.location,
          New_Location:meter_reading_object.new_Location,
          Sub_Location:meter_reading_object.new_Sub_Location,
          Sub_Location_ID:meter_reading_object.sub_Location_ID,
          Update_Location_Check:update_location_check,
          Cancelled:cancelled,
          Created_By:currentUser,
          Modified_By:currentUser,
          SOCT_ID:meter_reading_object.SOCT_ID
          }
  })
}
  await fetch(SOCT_URL+'/api/SOCT/UpdateSOCTMeterReading', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json')
        // error response
      if(!response.ok) {
          const error = (data) || response.status;
          return Promise.reject(error);
      }
      responseStatus = response;
  });
  return responseStatus;
}

async function getSOCTMeterReading(soct_id:string){
  let meter_readings:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{SOCT_ID:soct_id}})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTMeterReading', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     
      // no error
      data.map((history:any) => {
          meter_readings.push(history);
      });
    });
    return meter_readings;
}

async function getSOCTBulkMeterReading(){
  let meter_readings:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/getSOCTBulkMeterReading', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     
      // no error
      data.map((history:any) => {
          meter_readings.push(history);
      });
    });
    return meter_readings;
}


async function ApproveMeterApp(meter_reading_object:any, currentUser:string){
  let responseStatus = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
      token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
      request:{
          Data:meter_reading_object,
          Modified_By:currentUser,
          }
  })
}
  await fetch(SOCT_URL+'/api/SOCT/ApproveMeterApp', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json')
        // error response
      if(!response.ok) {
          const error = (data) || response.status;
          return Promise.reject(error);
      }
      responseStatus = response;
  });
  return responseStatus;
}


async function UpdateMeterApp(meter_reading_object:any, currentUser:string){
  let responseStatus = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
      token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
      request:{
          Data:meter_reading_object,
          Modified_By:currentUser,
          }
  })
}
  await fetch(SOCT_URL+'/api/SOCT/UpdateMeterApp', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json')
        // error response
      if(!response.ok) {
          const error = (data) || response.status;
          return Promise.reject(error);
      }
      responseStatus = response;
  });
  return responseStatus;
}


async function RejectMeterApp(meter_reading_id:any, rejection_comments:any, currentUser:string){
  let responseStatus = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
      token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
      request:{
          ID:meter_reading_id,
          Rejection_Comments:rejection_comments,
          Modified_By:currentUser,
          }
  })
}
  await fetch(SOCT_URL+'/api/SOCT/RejectMeterApp', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json')
        // error response
      if(!response.ok) {
          const error = (data) || response.status;
          return Promise.reject(error);
      }
      responseStatus = response;
  });
  return responseStatus;
}

async function getSOCTMeterReports(unit_Number:string){
  let meter_readings:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{Unit_Number:unit_Number}})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTMeterReports', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     
      // no error
      data.map((history:any) => {
          meter_readings.push(history);
      });
    });
    return meter_readings;
}


async function getSOCTMeterReadingByID(unit_id:Number){
  let unit:any = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request: {ID:unit_id}})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTMeterReadingByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      unit = data;
  });
  return unit;
}


async function getSOCTMeterAppHistoryByID(id:number){
  let meter_readings:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:id},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetMeterAppVersionHistory', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     
      // no error
      meter_readings = data.response;
    });
    return meter_readings;
}

export {AddMeterReading, 
  UpdateMeterReading, 
  getSOCTMeterReading, 
  getSOCTBulkMeterReading, 
  ApproveMeterApp, 
  UpdateMeterApp, 
  RejectMeterApp, 
  getSOCTMeterReports, 
  getSOCTMeterReadingByID,
  getSOCTMeterAppHistoryByID};