import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUpdateFilterRequest, IUPDATE_FILTER_REQUEST } from "./updateFilterConstants";


export interface IUpdateFilterLoadAction {
    type: IUPDATE_FILTER_REQUEST.REQUEST;
    data: IUpdateFilterRequest
}
export const updateFilterLoadAction = (data: IUpdateFilterRequest): IUpdateFilterLoadAction => ({
    type: IUPDATE_FILTER_REQUEST.REQUEST,
    data
});
export interface IUpdateFilterSuccessAction {
    type: IUPDATE_FILTER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFilterLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFilterSuccessAction => ({
    type: IUPDATE_FILTER_REQUEST.SUCCESS,
    message
});
export interface IUpdateFilterLoadFailedAction {
    type: IUPDATE_FILTER_REQUEST.FAILED;
    message: string;
}
export const updateFilterLoadFailedAction = (message: string): IUpdateFilterLoadFailedAction => ({
    type: IUPDATE_FILTER_REQUEST.FAILED,
    message
});
