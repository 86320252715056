import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST, IPayrollMechanicLogsReport } from "./getPayrollMechanicLogsReportConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetPayrollMechanicLogsReportLoadAction, IGetPayrollMechanicLogsReportLoadFailedAction, IGetPayrollMechanicLogsReportSuccessAction } from "./getPayrollMechanicLogsReportActions";
import { IPayrollMechanicLogsUploadSuccessAction } from "../payrollMechanicLogsUpload/payrollMechanicLogsUploadActions";
import { IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST } from "../payrollMechanicLogsUpload/payrollMechanicLogsUploadConstants";
import { IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST } from "../acknowledgeLogByPayroll/acknowledgeLogByPayrollConstants";
import { IAcknowledgeLogByPayrollSuccessAction } from "../acknowledgeLogByPayroll/acknowledgeLogByPayrollActions";

type Actions =
    | IGetPayrollMechanicLogsReportLoadAction
    | IGetPayrollMechanicLogsReportSuccessAction
    | IGetPayrollMechanicLogsReportLoadFailedAction
    | IPayrollMechanicLogsUploadSuccessAction
    | IAcknowledgeLogByPayrollSuccessAction
    | IFlushDataSuccessAction;

export const GetPayrollMechanicLogsReportReducer = (state: Server<SurewayAPIResponse<IPayrollMechanicLogsReport[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IPayrollMechanicLogsReport[]>> => {
    switch (action.type) {
        case IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.REQUEST:
            return loading;

        case IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.FAILED:
            return failed(action.message);

        case IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.SUCCESS:
            return notLoaded;

        case IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};