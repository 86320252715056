import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MAPPING_VIEW_REQUEST, IMappingView } from "./getMappingViewConstants";
import { IGetMappingViewLoadAction, IGetMappingViewLoadFailedAction, IGetMappingViewSuccessAction, getMappingViewLoadFailedAction, getMappingViewLoadSuccessAction } from "./getMappingViewActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getMappingViewEpic: Epic = (
    action$: ActionsObservable<IGetMappingViewLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMappingViewSuccessAction | IGetMappingViewLoadFailedAction> =>
    action$.ofType(IGET_MAPPING_VIEW_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<IMappingView[]>(
                    END_POINTS.FIELD.EQUIPMENT_TRACKER.GET_MAPPING_VIEW,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: IMappingView[]): IGetMappingViewSuccessAction => {
                            return getMappingViewLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getMappingViewLoadFailedAction(response)))
                    )
            )
        );