
export enum IGET_LOCATION_REQUEST {
    REQUEST = "getLocation/GET_LOCATION_REQUEST",
    SUCCESS = "getLocation/GET_LOCATION_SUCCESS",
    FAILED = "getLocation/GET_LOCATION_FAILED"
};

export interface ILocationRequest {
    token: string;
};

export interface ILocation {
    id: number;
    site_Name: string;
    active: string;
    site_Address: string;
    notes: string;
    rental_Status: string;
    daily_Service_Truck_Required: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    job_Number_ID? : number;
    job_No : string;
    no_Job_Number_Required: string;
    show_Sub_Sites_on_Map: string;
    equip_On_Site?: number;
    service_Truck: string;
    working_Sub_Sites: ISubLocation[];
    nearest_Medical_Facility: string;
    nearest_Medical_Facility_GPS: string;
    nearest_Medical_Facility_Distance?: string;
};

export interface ISubLocation {
    id: number;
    itemID: number;
    sub_Site_Name: string;
    sub_Site_Address: string;
    notes: string;
    working_Site_ID: number;
    active: string;
    cost_Code_Billing: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    latitude: string;
    longitude: string;
    muster_Point_GPS: string;
    whiteboard_ID: number;
    sT_ID: string;
    service_Truck?: string;
    default_Image?: string;
    pictures: IWSSPicture[];
    muster_Point: string;
};

export interface IWSSPicture {
    name: string;
    description: string;
    base64String: string;
};