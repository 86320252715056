import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_COMPONENT_WAITING_PARTS_REQUEST, IComponentWaitingParts } from "./getComponentWaitingPartsConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetComponentWaitingPartsLoadAction, IGetComponentWaitingPartsLoadFailedAction, IGetComponentWaitingPartsSuccessAction } from "./getComponentWaitingPartsActions";
import { ISaveComponentWaitingPartsSuccessAction } from "../saveComponentWaitingParts/saveComponentWaitingPartsActions";
import { ISAVE_COMPONENT_WAITING_PARTS_REQUEST } from "../saveComponentWaitingParts/saveComponentWaitingPartsConstants";
import { IUPDATE_REPAIR_LINE_REQUEST } from "../updateRepairLine/updateRepairLineConstants";
import { IUpdateRepairLineSuccessAction } from "../updateRepairLine/updateRepairLineActions";
import { IAddRepairLineSuccessAction } from "../addRepairLine/addRepairLineActions";
import { IADD_REPAIR_LINE_REQUEST } from "../addRepairLine/addRepairLineConstants";

type Actions =
    | IGetComponentWaitingPartsLoadAction
    | IGetComponentWaitingPartsSuccessAction
    | IGetComponentWaitingPartsLoadFailedAction
    | ISaveComponentWaitingPartsSuccessAction
    | IAddRepairLineSuccessAction
    | IUpdateRepairLineSuccessAction
    | IFlushDataSuccessAction;

export const GetComponentWaitingPartsReducer = (state: Server<SurewayAPIResponse<IComponentWaitingParts[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IComponentWaitingParts[]>> => {
    switch (action.type) {
        case IGET_COMPONENT_WAITING_PARTS_REQUEST.REQUEST:
            return loading;

        case IGET_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_COMPONENT_WAITING_PARTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_COMPONENT_WAITING_PARTS_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_REPAIR_LINE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};