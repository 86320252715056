import { IVALIDATE_SHOP_GROUP_REQUEST, IValidateShopGroup } from "./validateShopGroupConstants";
import { IValidateShopGroupLoadAction, IValidateShopGroupLoadFailedAction, IValidateShopGroupSuccessAction } from "./validateShopGroupActions";
import { ToolRentalsResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IValidateShopGroupLoadAction
    | IValidateShopGroupSuccessAction
    | IValidateShopGroupLoadFailedAction
    | IFlushDataSuccessAction;

export const ValidateShopGroupReducer = (state: Server<ToolRentalsResponse<IValidateShopGroup>> = notLoaded, action: Actions): Server<ToolRentalsResponse<IValidateShopGroup>> => {
    switch (action.type) {
        case IVALIDATE_SHOP_GROUP_REQUEST.REQUEST:
            return loading;

        case IVALIDATE_SHOP_GROUP_REQUEST.SUCCESS:
            return succeeded(action.data);

        case IVALIDATE_SHOP_GROUP_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};