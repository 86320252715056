import React, { ReactNode } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import { HasChildren } from "./publicInterfaces";
import { CollapseIcon, ExpandIcon } from "./icons";
import { ORANGE_COLOR, RED_COLOR, WHITE_COLOR } from "./theme";
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import RestaurantIcon from '@mui/icons-material/Restaurant';

interface ILAExpansionPanelProps extends HasChildren {
    label: string;
    color?: string;
    icon?: ReactNode;
    expanded: boolean;
    textColor?: string;
    onClick: () => void;
    defectTotalHours?: number;
    miscTotalHours?: number;
    serviceLogsTotalHours?: number;
    totalHours?: number;
    warningIcon?: boolean;
    lunchIcon?: boolean;
    loa?: boolean;
    className?: string;
    questionNumbers?: number;
    answerNumbers?: number;
}


export const LAExpansionPanel: React.FC<ILAExpansionPanelProps> = React.memo((props: ILAExpansionPanelProps) =>
    <Accordion expanded={props.expanded} className={props.className}>
        <AccordionSummary
            expandIcon={props.expanded ? <ExpandIcon color={WHITE_COLOR} /> : <ExpandIcon color={WHITE_COLOR} />}
            aria-controls="panel1a-content"
            onClick={props.onClick}
            id={props.label}
            style={{ backgroundColor: props.color }}
        >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
                <strong style={{ color: props.textColor, alignContent: "center" }}>{props.label}</strong>
                {props.totalHours && <strong style={{ color: props.textColor, alignContent: "center" }}>{" ("}</strong>}
                {props.defectTotalHours && props.defectTotalHours > 0 ? <strong style={{ color: "#0f16a3", alignContent: "center" }}>{props.defectTotalHours}</strong> : props.totalHours ? <strong style={{ color: "#0f16a3", alignContent: "center" }}>{"" + 0 }</strong> :""}
                {props.totalHours ? <strong style={{ color: props.textColor, alignContent: "center" }}>{" + "}</strong> : ""}
                {props.miscTotalHours && props.miscTotalHours > 0 ? <strong style={{ color: ORANGE_COLOR, alignContent: "center" }}>{props.miscTotalHours}</strong> : props.totalHours ? <strong style={{ color: ORANGE_COLOR, alignContent: "center" }}>0</strong> :""}
                {props.totalHours && props.serviceLogsTotalHours ? <strong style={{ color: props.textColor, alignContent: "center" }}>{" + "}</strong> : ""}
                {props.serviceLogsTotalHours && props.serviceLogsTotalHours > 0 ? <strong style={{ color: RED_COLOR, alignContent: "center" }}>{props.serviceLogsTotalHours}</strong> : props.totalHours && props.serviceLogsTotalHours ? <strong style={{ color: RED_COLOR, alignContent: "center" }}>0</strong> :""}
                {props.totalHours && <strong style={{ color: props.textColor, alignContent: "center" }}>{" = " + props.totalHours + " hrs)"} </strong>}
                {props.loa && <strong style={{ color: 'blue', fontSize: 12 }}>LOA</strong>} 
                {props.warningIcon && 
                    <span style={{marginLeft : 4}}>
                        <Tooltip title="Damaged/Warranty/Major Component">
                            <WarningIcon aria-label="warning" style={{ color: 'orange', fontSize: 18 }} />
                        </Tooltip>
                    </span>
                }
                {props.lunchIcon && 
                    <span style={{marginLeft : 4}}>
                        <Tooltip title="Lunch">
                            <RestaurantIcon aria-label="lunch"  style={{ color: 'blue', fontSize: 18 }}/>
                        </Tooltip>
                    </span>
                }
                {props.questionNumbers ? <strong style={{ color: props.textColor, alignContent: "center" }}> ({props.answerNumbers ?? 0}/{props.questionNumbers})</strong> : ""}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            {props.children}
        </AccordionDetails>
    </Accordion>
);