import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_TECH_SERVICE_REQUEST } from "./addTechServiceConstants";
import { IAddTechServiceLoadAction, IAddTechServiceLoadFailedAction, IAddTechServiceSuccessAction, addTechServiceLoadFailedAction, addTechServiceLoadSuccessAction } from "./addTechServiceActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const addTechServiceEpic: Epic = (
    action$: ActionsObservable<IAddTechServiceLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IAddTechServiceSuccessAction | IAddTechServiceLoadFailedAction> =>
    action$.ofType(IADD_TECH_SERVICE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.ADD_TECH_SERVICE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddTechServiceSuccessAction | IAddTechServiceLoadFailedAction => {
                            if(response.message === "Success"){
                                return addTechServiceLoadSuccessAction(response);
                            } else {
                                return addTechServiceLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addTechServiceLoadFailedAction(response.message)))
                    )
            )
        );