// import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
// import { END_POINTS } from "../../../endpoints";
// import { withJsonContentType } from "../../../epicUtils";
// import { mergeMap, catchError, map } from "rxjs/operators";
// import { Observable } from "rxjs";
// import { StateObservable, ActionsObservable, Epic } from "redux-observable";
// import { IStore } from "../../../reducers";
// import { EpicDependencies } from "../../../store";
import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { getCompaniesLoadFailedAction, getCompaniesLoadSuccessAction, IGetCompaniesLoadAction, IGetCompaniesLoadFailedAction, IGetCompaniesSuccessAction } from "./getCompaniesActions";
import { IGetCompaniesResponse, IGET_COMPANIES_REQUEST } from "./getCompaniesConstants";


export const getCompaniesEpic: Epic = (
    action$: ActionsObservable<IGetCompaniesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetCompaniesSuccessAction | IGetCompaniesLoadFailedAction> =>
    action$.ofType(IGET_COMPANIES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetCompaniesResponse[]>>(
                    END_POINTS.FIELD.PM_SHEETS.GET_COMPANIES,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetCompaniesResponse[]>): IGetCompaniesSuccessAction => {
                            return getCompaniesLoadSuccessAction(response);
                        }),
                        catchError(() => ActionsObservable.of(getCompaniesLoadFailedAction("Unable to get sheets")))
                    )
            )
        );