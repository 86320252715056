import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST {
    REQUEST = "getDriversAssignmentHistory/GET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST",
    SUCCESS = "getDriversAssignmentHistory/GET_DRIVERS_ASSIGNMENT_HISTORY_SUCCESS",
    FAILED = "getDriversAssignmentHistory/GET_DRIVERS_ASSIGNMENT_HISTORY_FAILED"
};

export interface IGetDriversAssignmentHistoryRequest extends ISurewayTokenRequestBody {

};

export interface IDriversAssignmentHistory {
    id: number;
    itemID: number;
    service_Truck_ID: number;
    sub_Site_ID: number;
    date: string;
    status: string;
    site_Info: string;
    driver: string;
    swamper: string;
    unit_No: string;
    eta: string;
    departed_Time: string;
    arrival_Time: string;
    notes: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    operation: number;
};