import { IAPPROVE_SERVICE_SHEET_REQUEST } from "./approveServiceSheetConstants";
import { IApproveServiceSheetLoadAction, IApproveServiceSheetLoadFailedAction, IApproveServiceSheetSuccessAction } from "./approveServiceSheetActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IApproveServiceSheetLoadAction
    | IApproveServiceSheetSuccessAction
    | IApproveServiceSheetLoadFailedAction
    | IFlushDataSuccessAction;

export const ApproveServiceSheetReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IAPPROVE_SERVICE_SHEET_REQUEST.REQUEST:
            return loading;

        case IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IAPPROVE_SERVICE_SHEET_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};