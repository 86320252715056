import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST, ISaveManagerCommentsForDefectItemRequest } from "./saveManagerCommentsForDefectItemConstants";


export interface ISaveManagerCommentsForDefectItemLoadAction {
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.REQUEST;
    data: ISaveManagerCommentsForDefectItemRequest
}
export const saveManagerCommentsForDefectItemLoadAction = (data: ISaveManagerCommentsForDefectItemRequest): ISaveManagerCommentsForDefectItemLoadAction => ({
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.REQUEST,
    data
});
export interface ISaveManagerCommentsForDefectItemSuccessAction {
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveManagerCommentsForDefectItemLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveManagerCommentsForDefectItemSuccessAction => ({
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS,
    message
});
export interface ISaveManagerCommentsForDefectItemLoadFailedAction {
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.FAILED;
    message: string;
}
export const saveManagerCommentsForDefectItemLoadFailedAction = (message: string): ISaveManagerCommentsForDefectItemLoadFailedAction => ({
    type: ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.FAILED,
    message
});
