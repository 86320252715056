import { IAddUpdateHazardType } from "../addHazardType/addHazardTypeConstants";

export enum IUPDATE_HAZARD_TYPE_REQUEST {
    REQUEST = "updateHazardType/UPDATE_HAZARD_TYPE_REQUEST",
    SUCCESS = "updateHazardType/UPDATE_HAZARD_TYPE_SUCCESS",
    FAILED = "updateHazardType/UPDATE_HAZARD_TYPE_FAILED"
};

export interface IUpdateHazardTypeRequest {
    token: string;
    request: IAddUpdateHazardType;
};