import { ROUTE } from "./routes";
import styled from "styled-components";
import { connect } from "react-redux";
import { Component, ReactNode } from "react";
import { MEDIA_QUERY_PHONE } from "./shared/theme";
import { IStore, IDispatch } from "../redux/reducers";
import ST_SCHEDULES from "./field/dispatch/stSchedules";
import ExternalMoveRequest from "./external/moveRequest";
import ExternalRepairLineParts from "./external/repairLineParts";
import ExternalUserLogin from "./external/externalUserLogin";
import Error404Page, { AccessDeniedPage, UError401Page } from "./error404Page";
import OperatorLogs from "./external/operatorLogs/operatorLogs";
import { RouteComponentProps, Route, Switch } from "react-router";
import { STATUS_ENUM, Server, hasPayload } from "../redux/server";
import ExternalEmployeeMessages from "./external/employeeMessages";
import ExternalMechanicLogin from "./external/externalMechanicLogin";
import AddUpdateOperatorLog from "./external/operatorLogs/operatorLog";
import MechanicRepairLines from "./external/mechanicPortal/mechanicRepairLines";
import AddUpdateMechanicRepairLine from "./external/mechanicPortal/mechanicRepairLine";
import { IValidateOperatorLogin } from "../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginConstants";
import { validateOperatorLogin } from "../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginAccessor";
import { IValidateMechanicLogin } from "../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginConstants";
import { validateMechanicLogin } from "../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginAccessor";
import ExternalEmployeeResponse from "./external/employeeResponse";
import ExternalPSIResponse from "./external/psi/externalPSIResponse";
import ExternalPSIResponseSuccess from "./external/psi/externalPSIResponseSuccess";

interface IUnSecuredRoutesStoreProps {
    validateOperatorLoginStatus: Server<IValidateOperatorLogin>;
    validateMechanicLoginStatus: Server<IValidateMechanicLogin>;
};

interface IUnSecuredRoutesDispatchProps {

};

interface IUnSecuredRoutesState {
    showHeader: boolean;
};

const UnSecuredRoutesStyles = styled.div`
    
    .mobileView {
        display: none;
    };

    .desktopView {
        display: block;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .mobileView {
            display: block;
        };

        .desktopView {
            display: none;
        };
    };
    
`;

type IUnSecuredRoutesProps =
    RouteComponentProps &
    IUnSecuredRoutesDispatchProps &
    IUnSecuredRoutesStoreProps;

class UnSecuredRoutes extends Component<IUnSecuredRoutesProps, IUnSecuredRoutesState> {

    public constructor(props: IUnSecuredRoutesProps) {
        super(props);
        this.state = {
            showHeader: false
        };
    }

    public componentDidMount(): void {

    }

    public componentDidUpdate(prevProps: IUnSecuredRoutesProps): void {
    
    }

    public render(): ReactNode {

        const { validateOperatorLoginStatus, validateMechanicLoginStatus } = this.props;
        const operatorLogin = (hasPayload(validateOperatorLoginStatus) && validateOperatorLoginStatus.kind === STATUS_ENUM.SUCCEEDED) ? true : false;
        const mechanicLogin = (hasPayload(validateMechanicLoginStatus) && validateMechanicLoginStatus.kind === STATUS_ENUM.SUCCEEDED) ? true : false;

        return (
                <UnSecuredRoutesStyles>
                    <Switch>
                        <Route exact={true} path={ROUTE.EXTERNAL.INDEX} component={ExternalMoveRequest} />
                        <Route exact={true} path={ROUTE.EXTERNAL.MOVE_REQUEST} component={ExternalMoveRequest} />

                        <Route exact={true} path={ROUTE.EXTERNAL.REPAIRLINE_PARTS_INSTRUCTIONS} component={ExternalRepairLineParts} />

                        <Route exact={true} path={ROUTE.EXTERNAL.MESSAGES} component={ExternalEmployeeMessages} />
                        <Route exact={true} path={ROUTE.EXTERNAL.RESPONSE} component={ExternalEmployeeResponse} />
                        <Route exact={true} path={ROUTE.EXTERNAL.PSI} component={ExternalPSIResponse} />
                        <Route exact={true} path={ROUTE.EXTERNAL.PSI_RESPSONSE_SUCCESS} component={ExternalPSIResponseSuccess} />
          
                        <Route exact={true} path={ROUTE.EXTERNAL.ST_SCHEDULES} component={ST_SCHEDULES} />

                        <Route exact={true} path={ROUTE.EXTERNAL.OPERATOR_LOGS} component={!operatorLogin ? ExternalUserLogin : OperatorLogs} />
                        <Route exact={true} path={ROUTE.EXTERNAL.OPERATOR_LOG} component={!operatorLogin ? ExternalUserLogin : AddUpdateOperatorLog} />

                        <Route exact={true} path={ROUTE.EXTERNAL.MECHANICS} component={!mechanicLogin ? ExternalMechanicLogin : MechanicRepairLines} />
                        <Route exact={true} path={ROUTE.EXTERNAL.MECHANIC} component={!mechanicLogin ? ExternalMechanicLogin : AddUpdateMechanicRepairLine} />
                        <Route exact path={ROUTE.ACCESS_DENIED} render={(): ReactNode => <AccessDeniedPage handleShowHeader={this.handleShowHeader} />} />
                        <Route render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />
                        <Route path={ROUTE.UNAUTHORIZED} render={(): ReactNode => <UError401Page handleShowHeader={this.handleShowHeader} />} />
                        <Route path="*" exact={true} render={(): ReactNode => <Error404Page handleShowHeader={this.handleShowHeader} />} />

                    </Switch>
                </UnSecuredRoutesStyles>
        );

    }

    private handleShowHeader = (showHeader: boolean): void => {
        this.setState({ showHeader });
    };

}

const mapStateToProps = (state: IStore): IUnSecuredRoutesStoreProps => ({
    validateOperatorLoginStatus: validateOperatorLogin(state),
    validateMechanicLoginStatus: validateMechanicLogin(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IUnSecuredRoutesDispatchProps => ({

});


export default connect(mapStateToProps, mapDispatchToProps)(UnSecuredRoutes);