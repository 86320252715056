import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_HAZARDS_REQUEST, IGetHazards, IGetHazardsRequest } from "./getHazardsConstants";

export interface IGetHazardsLoadAction {
    type: IGET_HAZARDS_REQUEST.REQUEST;
    data: IGetHazardsRequest
}
export const getHazardsLoadAction = (data: IGetHazardsRequest): IGetHazardsLoadAction => ({
    type: IGET_HAZARDS_REQUEST.REQUEST,
    data
});
export interface IGetHazardsSuccessAction {
    type: IGET_HAZARDS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetHazards>>;
}
export const getHazardsLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetHazards>>): IGetHazardsSuccessAction => ({
    type: IGET_HAZARDS_REQUEST.SUCCESS,
    list
});
export interface IGetHazardsLoadFailedAction {
    type: IGET_HAZARDS_REQUEST.FAILED;
    message: string;
}
export const getHazardsLoadFailedAction = (message: string): IGetHazardsLoadFailedAction => ({
    type: IGET_HAZARDS_REQUEST.FAILED,
    message
});
