
export enum IGET_EQUIPMENT_VERSION_HISTORY_REQUEST {
    REQUEST = "getETMoveAttachmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_REQUEST",
    SUCCESS = "getETMoveAttachmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_SUCCESS",
    FAILED = "getETMoveAttachmentVersionHistory/GET_EQUIPMENT_VERSION_HISTORY_FAILED"
};

export interface IGetETMoveAttachmentVersionHistoryRequest {
    token: string;
    request: {
        ID: number;
    }
};

export interface IGetETMoveAttachmentVersionHistory {
    id: number;
    operation: number;
    itemID: number;
    attachment_ID: number;
    attachment_No: string;
    from_Sub_Location_ID: number;
    to_Sub_Location_ID: number;
    move_Request_ID: number;
    attached_Unit_ID: number;
    status: string;
    complete_By: string | null;
    created: string;
    created_By: string;
    modified: string | null;
    modified_By: string;
    coupler_Design_ID: number;
    type_ID: number;
    style_ID: number;
    previously_Moved: boolean;
    move_Type: string;
    site: string;
    location: string;
    type: string;
    style: string;
    coupler_Design: string;
};