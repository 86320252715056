import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_JOBS_MHR_REQUEST {
    REQUEST = "getJobsMHR/GET_JOBS_MHR_REQUEST",
    SUCCESS = "getJobsMHR/GET_JOBS_MHR_SUCCESS",
    FAILED = "getJobsMHR/GET_JOBS_MHR_FAILED"
};

export interface IJobsMhrRequest extends ISurewayTokenRequestBody {

};

export interface IJobMhr {
    id: number;
    year?: number;
    job: string;
    code: string;
    description: string;
    requested_By: string;
    active: string;
    location: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};