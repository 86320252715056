import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import {  IFieldLookup, IFieldLookupRequest, IWSSListForMedicalFacility } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { LACheckBox } from "../../shared/checkBox";
import { IAddMedicalFacilityRequest, IAddUpdateMedicalFacility } from "../../../redux/field/addMedicalFacility/addMedicalFacilityConstants";
import { IUpdateMedicalFacilityRequest } from "../../../redux/field/updateMedicalFacility/updateMedicalFacilityConstants";
import { addMedicalFacilityLoadAction } from "../../../redux/field/addMedicalFacility/addMedicalFacilityActions";
import { updateMedicalFacilityLoadAction } from "../../../redux/field/updateMedicalFacility/updateMedicalFacilityActions";
import { addMedicalFacilityStatus } from "../../../redux/field/addMedicalFacility/addMedicalFacilityAccessor";
import { updateMedicalFacilityStatus } from "../../../redux/field/updateMedicalFacility/updateMedicalFacilityAccessor";
import { LACenteredLoading } from "../../shared/loading";
import { IGetMedicalFacilities, IGetMedicalFacilitiesRequest } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesConstants";
import { getMedicalFacilitiesLoadAction } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesActions";
import { getMedicalFacilities } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesAccessor";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import LALinkButton from "../../shared/linkButton";
import { webConfig } from "../../../utils/webConfig";
import { GPSPopup } from "../location/location";

const RequiredFields: string[] = ["name"];


interface IAddUpdateMedicalFacilityComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    getMedicalFacilitys: Server<SurewayAPIResponse<ById<IGetMedicalFacilities>>>;
    addMedicalFacilityStatus: Server<SurewayAPIResponse<string>>;
    updateMedicalFacilityStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateMedicalFacilityComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getMedicalFacilitysRequest: (data: IGetMedicalFacilitiesRequest) => unknown
    addMedicalFacilityRequest: (data: IAddMedicalFacilityRequest) => unknown;
    updateMedicalFacilityRequest: (data: IUpdateMedicalFacilityRequest) => unknown;
};

interface IAddUpdateMedicalFacilityOwnProps {
    id?: string;
};

interface IAddUpdateMedicalFacilityComponentState {
    data: IGetMedicalFacilities;
    mapMedicalFacility : boolean;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateMedicalFacilityStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .red-text {
        color: ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateMedicalFacilityComponentProps =
    RouteComponentProps
    & IAddUpdateMedicalFacilityOwnProps
    & IAddUpdateMedicalFacilityComponentStoreProps
    & IAddUpdateMedicalFacilityComponentDispatchProps;

class AddUpdateMedicalFacility extends PureComponent<IAddUpdateMedicalFacilityComponentProps, IAddUpdateMedicalFacilityComponentState> {

    public constructor(props: IAddUpdateMedicalFacilityComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                address: "",
                gps: "",
                active: "Yes",
                wsS_IDs: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            mapMedicalFacility: false,
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMedicalFacilityComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addMedicalFacilityStatus !== prevProps.addMedicalFacilityStatus) {

                if (this.props.addMedicalFacilityStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addMedicalFacilityStatus.message });

                if (hasPayload(this.props.addMedicalFacilityStatus) && this.props.addMedicalFacilityStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateMedicalFacilityStatus !== prevProps.updateMedicalFacilityStatus) {

                if (this.props.updateMedicalFacilityStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateMedicalFacilityStatus.message });

                if (hasPayload(this.props.updateMedicalFacilityStatus) && this.props.updateMedicalFacilityStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, mapMedicalFacility} = this.state;
        const title = data.id > 0 ? "Update Medical Facility" : "Add Medical Facility";
        const { token, addMedicalFacilityStatus, updateMedicalFacilityStatus, getLookup } = this.props;
        const getRole = pageAccessCheck(token, "locationList");
        const disabled = (getRole === ReadOnly) ? true : undefined;
        // const disabled =  undefined;
        // console.log(data)

        const wssList = hasPayload(getLookup) ? getLookup.payload.response.subSites : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onActive = (checked: boolean): void => this.handleChange("active", checked ? "Yes" : "No");

        if(getLookup.kind === STATUS_ENUM.LOADING) {
            return <LACenteredLoading message="Loading..."/>;
        }

        return (
            <PageSpacing title={title} description={`FIELD - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateMedicalFacilityStyles>
                    {(getRole !== NotApplicable) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Medical Facility</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LAGrid>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            name="name"
                                            label="Medical Facility Name"
                                            fullWidth={true}
                                            disabled={disabled}
                                            errorText={errors["name"] ? errors["name"].message : undefined}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            value={data.name ?? ""}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            name="address"
                                            label="Medical Facility Address"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={data.address}
                                            onChange={this.handleChange}
                                            disabled={disabled}
                                            errorText={errors["address"] ? errors["address"].message : undefined}
                                        />
                                        <LALinkButton
                                            label="Open Map"
                                            onClick={this.handleGetCordinatesClick}
                                        />
                                        &nbsp;&nbsp;
                                        <LALinkButton
                                            className="left-Reset"
                                            label="Reset"
                                            onClick={this.resetMedicalFacility}
                                        /> 
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            name="gps"
                                            label="Medical Facility GPS"
                                            fullWidth={true}
                                            disabled={true}
                                            variant="outlined"
                                            value={data.gps}
                                            onChange={this.handleChange}
                                            errorText={errors["gps"] ? errors["gps"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={4}>
                                        <DevExtremePopupLookup
                                            data={wssList}
                                            multiple={true}
                                            id="wss-search"
                                            placeHolder="Working Site"
                                            disabled={undefined}
                                            displayExp="site"
                                            name="id"
                                            errorName="wsS_IDs"
                                            onSelectionRemove={this.handleWSSRemove}
                                            errors={errors}
                                            selectionRow={true}
                                            onSelectionChanged={this.handleWSSSelectionChanged}
                                            currentVal={this.getWSS(data.wsS_IDs ?? "")}
                                            onClick={undefinedFunction}
                                            columns={[
                                                { name: "site", caption: "Site", type: "string" },
                                                { name: "job", caption: "Job", type: "string" },
                                            ]}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="active"
                                            label="Active"
                                            disabled={disabled}
                                            onChange={onActive}
                                            value={(data.active === "Yes") ? true : false}
                                        />
                                    </LAGridItem>

                                    
                                </LAGrid>
                            </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disableSave}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    }

                    {mapMedicalFacility && <GPSPopup
                        onChange={this.handleMedicalFacilityGPSChange}
                        onCancel={this.handleMapMedicalFacilityClose}
                        open={mapMedicalFacility}
                        lat={this.getMedicalFacilityGPS(true)}
                        long={this.getMedicalFacilityGPS(false)}
                    />}

                    <RequestStatus requestStatus={addMedicalFacilityStatus.kind} successMessage="MedicalFacility has been added successfully" />
                    <RequestStatus requestStatus={updateMedicalFacilityStatus.kind} successMessage="MedicalFacility has been updated successfully" />

                </AddUpdateMedicalFacilityStyles>
            </PageSpacing>
        );
    }

    private getMedicalFacilityGPS = (lat: boolean): string => {
        const { data, mapMedicalFacility } = this.state;

        if (mapMedicalFacility) {
            const gps = data.gps;

            if (gps && (gps !== null) && (gps.length > 0)) {
                if (lat) {
                    return gps.split(",")[0];
                } else {
                    return gps.split(",")[1];
                }
            }
        }

        return "";
    };

    private handleMedicalFacilityGPSChange = async(lat: number, long: number): Promise<void> => {
        const { mapMedicalFacility } = this.state;

        if (mapMedicalFacility) {
            let gps = this.state.data.gps;

            gps = lat.toString() + "," + long.toString();

            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${webConfig.googleMapKey}`
                );
                const data = await response.json();
                if (data.status === 'OK' && data.results.length > 0) {
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            address: data.results[0].formatted_address
                        }
                    });
                } 
            } catch (error) {
                console.error('Error fetching address:', error);
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    gps
                }
            });
        };
    };

    private handleMapMedicalFacilityClose = (): void => {
        this.setState({ mapMedicalFacility: false });
    };

    private handleGetCordinatesClick = async() : Promise<void> => {
        const { address } = this.state.data;
        if(address) {
            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        address
                    )}&key=${webConfig.googleMapKey}`
                );
                const data = await response.json();
                if (data.status === 'OK') {
                    const location = data.results[0].geometry.location;
                    
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            gps : location.lat + "," + location.lng
                        },
                        mapMedicalFacility: true
                    });
                } else {
                    alert('Address not found. Please try again.');
                }
            } catch (error) {
                alert('Error fetching coordinates. Please try again.');
            }
        }
        this.setState({
            ...this.state,
            mapMedicalFacility: true
        });
    }

    private resetMedicalFacility = () => {
        this.setState({
            data: {
               ...this.state.data,
                address: "",
                gps: ""
            }
        });
    }

    private getWSS = (assignedWSSIds: string): IWSSListForMedicalFacility[] => {
        if (assignedWSSIds) {
            const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.subSites : [];
            const response: IWSSListForMedicalFacility[] = [];
            const assignedWSSIDs = assignedWSSIds !== null ? assignedWSSIds.split(",").map(Number) : [];

            assignedWSSIDs.forEach((x) => {
                const index = wssList.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(wssList[index]);
            });

            return response;
        }
        return [];
    };

    private handleWSSSelectionChanged = (row:any): void => {
        const selectedWSSList: IWSSListForMedicalFacility[] = row.selectedRowsData;
        // // console.log(selectedMembersList);
        const data = { ...this.state.data };
        let assignedWSSIDs = data.wsS_IDs ? data.wsS_IDs.split(",").map(Number) : [];

        selectedWSSList.forEach((x) => {
            const index = assignedWSSIDs.length > 0 ? assignedWSSIDs.findIndex((e) => e === x.id) : -1;
            if (index < 0) {
                assignedWSSIDs.push(x.id);
            }
        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                wsS_IDs: assignedWSSIDs.join(",")
            },
        });
    }

    private handleWSSRemove = (value: string): void => {
        // // console.log(value)
        const data = { ...this.state.data};
        const wssList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.subSites : [];

        let assignedWSSIDs = data.wsS_IDs ? data.wsS_IDs.split(",").map(Number) : [];
        // // const units_Array: string[] = unitIDs && unitIDs !== null ? unitIDs.split(",").map(String) : [];
        const subsite = wssList ? wssList.find(q => q.site === value) : null;

        if(subsite) {
            const index = assignedWSSIDs.length > 0 ? assignedWSSIDs.findIndex((x) => x === subsite.id) : -1;
            if (index >= 0) {
                assignedWSSIDs.splice(index, 1);
            }
        }
        
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                wsS_IDs: assignedWSSIDs.join(","),
            },
        });
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: { },
            pathName: ROUTE.FIELD.MEDICAL_FACILITIES.INDEX
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {

            let request: IAddUpdateMedicalFacility = {
                ID: data.id,
                Name: data.name,
                Address: data.address,
                GPS: data.gps,
                Active: data.active,
                WSS_IDs: data.wsS_IDs ?? "",
                Created_By: data.created_By,
                Modified_By: this.props.token.payload.response.upn
            };
            // console.log(request)
            if (data.id === 0) {
                this.props.addMedicalFacilityRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } 
            else {
                this.props.updateMedicalFacilityRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };
    
            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        let data = { ...this.state.data}
        console.log(name, value)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "formsAdmin") !== NotApplicable) {

                    if (hasPayload(this.props.getMedicalFacilitys)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getMedicalFacilitys.payload.response[+query.id];

                                this.setState({
                                    data 
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getMedicalFacilitysRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Medical_Facility"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMedicalFacilityComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    getMedicalFacilitys: getMedicalFacilities(state),
    addMedicalFacilityStatus: addMedicalFacilityStatus(state),
    updateMedicalFacilityStatus: updateMedicalFacilityStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMedicalFacilityComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getMedicalFacilitysRequest: (data: IGetMedicalFacilitiesRequest) => dispatch(getMedicalFacilitiesLoadAction(data)),
    addMedicalFacilityRequest: (data: IAddMedicalFacilityRequest) => dispatch(addMedicalFacilityLoadAction(data)),
    updateMedicalFacilityRequest: (data: IUpdateMedicalFacilityRequest) => dispatch(updateMedicalFacilityLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMedicalFacility);