import React from "react";
import { LAPopover } from "../../shared/popOver";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { callRouteWithQueryString, undefinedFunction } from "../../shared/constExports";
import LALinkButton from "../../shared/linkButton";
import { IRepairLineSummary, IRepairLineSummaryUnit } from "../../../redux/field/repairLine/getRepairLineSummary/getRepairLineSummaryConstants";
import { IRepairLineByMechanicId } from "../../../redux/field/repairLine/getRepairLineByMechanicId/getRepairLineByMechanicIdConstants";
import { STATUS_ENUM } from "../../../redux/server";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { ROUTE } from "../../routes";

interface IRepairLineSummaryProps {
    data: IRepairLineSummary;
    onClick: (id: number, name: string) => void;
    onUnitClick: (data:IRepairLineSummaryUnit) => void;
};

export const RepairLineSummary: React.FC<IRepairLineSummaryProps> = React.memo((props: IRepairLineSummaryProps) => {
    // console.log(props.data);
    return <LAGridItem xs={12}>
        <LAPaperWithLessPadding>
            <LAGrid spacing={0}>

                <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        <LAGridItem xs={12}>
                            <LAPaperWithLessPadding>
                                <strong style={{"color": "red"}}>Under Repair: </strong>
                                <br />
                                    <strong style={{fontSize: "14px"}}>Total Units: </strong>
                                    <span style={{fontSize: "14px"}}>{props.data.active_Units.count}</span>
                                    {/* <br />
                                    <strong style={{fontSize: "14px"}}>Units/Attachments:</strong>
                                    <span style={{fontSize: "14px"}}>{props.data.active_Units.units}</span> */}
                            </LAPaperWithLessPadding>
                        </LAGridItem>
                    </LAGrid>
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        <LAGridItem xs={12}>
                            <LAPaperWithLessPadding>
                                <strong style={{"color": "red"}}>Mechanic Assigned: </strong>
                                <br />
                                    {props.data.mechanic_Assigned.map((x, idx) => {
                                        const onClick = (): void => props.onClick(x.id, x.name);
                                        return (
                                            <>
                                                <LALinkButton key={idx} label={x.name} onClick={onClick} />
                                                <span>({x.count})</span>
                                                <> | </>
                                            </>
                                        )
                                    })}
                            </LAPaperWithLessPadding>
                        </LAGridItem>
                    </LAGrid>
                </LAGridItem>

                <LAGridItem xs={12}>
                            <LAPaperWithLessPadding >
                    <LAGrid spacing={0}>
                        <LAGridItem xs={6}>
                                <strong style={{"color": "red"}}>Down</strong>
                                <br />
                                    <strong style={{fontSize: "14px"}}>Total Units: </strong>
                                    <span style={{fontSize: "14px"}} >{props.data.priority_Down.count}</span>
                                    <br />
                                    <strong style={{fontSize: "14px"}}>Units# : </strong>
                                    <span style={{ fontSize: "14px" }}>
                                        {props.data.priority_Down.units.map((x, index) => {
                                            return <React.Fragment key={index}>
                                                <LALinkButton 
                                                    className="linkUrl" 
                                                    label={x.unit_No} 
                                                    onClick={() => props.onUnitClick(x)} 
                                                />
                                                {index < props.data.priority_Down.units.length - 1 && ', '}
                                            </React.Fragment>
                                    })}
                                    </span>


                            {/* </LAPaperWithLessPadding> */}
                            </LAGridItem>
                            <LAGridItem xs={6}>
                            {/* <LAPaperWithLessPadding> */}
                                <strong style={{"color": "red"}}>Urgent</strong>
                                <br/>
                                    <strong style={{fontSize: "14px"}}>Total Units: </strong>
                                    <span style={{fontSize: "14px"}}>{props.data.priority_Urgent.count}</span>
                                    <br />
                                    <strong style={{fontSize: "14px"}}>Units# : </strong>
                                    <span style={{ fontSize: "14px" }}>
                                        {props.data.priority_Urgent.units.map((x, index) => {
                                            return <React.Fragment key={index}>
                                                <LALinkButton 
                                                    className="linkUrl" 
                                                    label={x.unit_No} 
                                                    onClick={() => props.onUnitClick(x)} 
                                                />
                                                {index < props.data.priority_Urgent.units.length - 1 && ', '}
                                            </React.Fragment>
                                    })}
                                    </span>
                        </LAGridItem>
                    </LAGrid>
                            </LAPaperWithLessPadding>
                </LAGridItem>

                {/* <LAGridItem xs={12}>
                    <LAGrid spacing={0}>
                        <LAGridItem xs={12}>
                            <LAPaperWithLessPadding>
                                <strong style={{"color": "red"}}>Priority: Urgent</strong>
                                <br/>
                                    <strong>Total Units: </strong>
                                    <span>{props.data.priority_Urgent.count}</span>
                                    <br />
                                    <strong>Units# :</strong>
                                    <span>{props.data.priority_Urgent.units}</span>
                            </LAPaperWithLessPadding>
                        </LAGridItem>
                    </LAGrid>
                </LAGridItem> */}

            </LAGrid>
        </LAPaperWithLessPadding>
    </LAGridItem>
});

interface IRepairLineMechanicPopup {
    open: boolean;
    status: STATUS_ENUM;
    onClose: () => void;
    mechanicName: string;
    onEdit: (e: any) => void;
    data: IRepairLineByMechanicId[];
};

export const RepairLineMechanicPopup: React.FC<IRepairLineMechanicPopup> = React.memo((props: IRepairLineMechanicPopup) => {
    const onClick = () => undefinedFunction;

    const onOpenDefectItem = (name:string, data: any): void => {
        window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Line_ID.toString() + "&defect_ID=" + data.id.toString())
    };
    const data = props.data.filter(x => x.status !== "Completed" && x.status !== "Cancelled");
    // console.log(props.data)
    return <LAPopover open={props.open} onClose={props.onClose} anchorRef={null} anchorOrigin={{ horizontal: "left", vertical: "top" }}> 
        <LAPaperWithPadding>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>Repair Line - Defect Items - {props.mechanicName}</h3>
                    <hr />
                </LAGridItem>

                {props.status === STATUS_ENUM.LOADING && <LAGridItem xs={12}>
                    <LALoading message={`Loading ${props.mechanicName} repair lines...`} />
                </LAGridItem>}
                {props.status === STATUS_ENUM.FAILED && <LAGridItem xs={12}>
                    <LAErrorBox text={`Failed to load repair lines for ${props.mechanicName}`} />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) &&
                    <LAGridItem xs={12}>
                        <LADevExtremeGrid
                            data={data}
                            columnChoose={false}
                            columnsHiding={true}
                            onCustomIconClick={onOpenDefectItem}
                            onClick={onClick}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            actionWidth={90}
                            removeStyleBtn={true}
                            height={600}
                            key="id"
                            id="repairLineList"
                            customRowColor={true}
                            exportFileName="MechanicRepairLines"
                            columns={[
                                { name: "defect_Item", caption: "Defect Item", type: "link" },
                                { name: "display", caption: "Unit/Attachment", type: "string" },
                                { name: "issue", caption: "Issue", type: "string" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "priority", caption: "Priority", type: "string" },
                                { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string" },
                                { name: "damaged", caption: "Damaged", type: "string" },
                                { name: "manager_Instructions", caption: "Manager Instructions", type: "string" },
                                { name: "parts_Instructions", caption: "Parts Instructions", type: "string" },
                                { name: "mechanic_Comments", caption: "Mechanic Comments", type: "string" },
                                { name: "parts_Notes", caption: "Parts Notes", type: "string" }
                            ]}
                        />
                    </LAGridItem>}

            </LAGrid>
        </LAPaperWithPadding>
    </LAPopover>
});