import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { LAPaperWithPadding } from "../../../shared/paper";
import LAGrid from "../../../shared/grid";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAGridItem from "../../../shared/gridList";
import queryString from "query-string";
import LATextField from "../../../shared/textField";
import LATextArea from "../../../shared/textArea";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import { ROUTE } from "../../../routes";
import { getJobs } from "../../../../redux/accounting/jobs/getJobs/getJobsAccessor";
import { getJobsLoadAction } from "../../../../redux/accounting/jobs/getJobs/getJobsActions";
import { IJob, IJobsRequest, IJobType, JobYears } from "../../../../redux/accounting/jobs/getJobs/getJobsConstants";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../../shared/constExports";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAAutoComplete from "../../../shared/autoComplete";
import { ArrowLeftIcon } from "../../../shared/icons";
import { IAddJobRequest } from "../../../../redux/accounting/jobs/addJob/addJobConstants";
import { IUpdateJobRequest } from "../../../../redux/accounting/jobs/updateJob/updateJobConstants";
import { addJob } from "../../../../redux/accounting/jobs/addJob/addJobAccessor";
import { updateJob } from "../../../../redux/accounting/jobs/updateJob/updateJobAccessor";
import { addJobLoadAction } from "../../../../redux/accounting/jobs/addJob/addJobActions";
import { updateJobLoadAction } from "../../../../redux/accounting/jobs/updateJob/updateJobActions";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../../shared/fieldValidator";
// import { getLookupsLoadAction } from "../../../../redux/getLookups/getLookupsActions";
import { ILookup, ILookupCompanies, ILookupsRequest } from "../../../../redux/getLookups/getLookupsConstants";
// import { getLookup } from "../../../../redux/getLookups/getLookupsAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { LAPopover } from "../../../shared/popOver";
import AddUpdateLocation from "../../../field/location/location";
import { ILocation, ILocationRequest } from "../../../../redux/field/getLocation/getLocationConstants";
import { getLocations } from "../../../../redux/field/getLocation/getLocationAccessor";
import { getLocationLoadAction } from "../../../../redux/field/getLocation/getLocationActions";
import LADropDownForOwners from "../../../field/equipment/equipmentOwnerDropdown";
import { NotApplicable, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getFieldLookups } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { IFieldLookup, IFieldLookupRequest } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";

const RequiredFields: string[] = ["year", "job_No", "company"];

interface IAddUpdateJobComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    jobs: Server<SurewayAPIResponse<ById<IJob>>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addJobStatus: Server<SurewayAPIResponse<string>>;
    updateJobStatus: Server<SurewayAPIResponse<string>>;
    getLocation: Server<SurewayAPIResponse<ById<ILocation>>>;
};

interface IAddUpdateJobComponentDispatchProps {
    getJobsRequest: (data: IJobsRequest) => unknown;
    addJobRequest: (data: IAddJobRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    updateJobRequest: (data: IUpdateJobRequest) => unknown;
    getLocationsRequest: (data: ILocationRequest) => unknown;
};

interface IAddUpdateJobOwnProps {

};

interface IAddUpdateJobComponentState {
    data: IJob;
    serverError: string;
    locationPopup: boolean;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateJobStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateJobComponentProps =
    RouteComponentProps
    & IAddUpdateJobOwnProps
    & IAddUpdateJobComponentStoreProps
    & IAddUpdateJobComponentDispatchProps;

class AddUpdateJob extends PureComponent<IAddUpdateJobComponentProps, IAddUpdateJobComponentState> {

    public constructor(props: IAddUpdateJobComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                year: "",
                job_No: "",
                type: IJobType[0].name,
                name: "",
                client: "",
                coordinator: "",
                project_Manager: "",
                superintendent: "",
                active: YesOrNo[0].name,
                location: "",
                company: "",
                working_Site: "",
                created: "",
                created_By: "",
                modified: "",
                modified_By: ""
            },
            errors: {},
            serverError: "",
            locationPopup: false
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateJobComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addJobStatus !== prevProps.addJobStatus) {

                if (this.props.addJobStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addJobStatus.message });

                if (hasPayload(this.props.addJobStatus) && this.props.addJobStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateJobStatus !== prevProps.updateJobStatus) {

                if (this.props.updateJobStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateJobStatus.message });

                if (hasPayload(this.props.updateJobStatus) && this.props.updateJobStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, locationPopup } = this.state;
        const { token, addJobStatus, updateJobStatus } = this.props;
        const getRole = pageAccessCheck(token, "jobList");
        const onCompany = (e: unknown, v: ILookupCompanies): void => this.handleDropDownChange(e, v !== null ? v.company : "", "company");
        // const locations = hasPayload(getLocation) ? Object.values(getLocation.payload.response).sort((a, b) => a.site_Name.localeCompare(b.site_Name)) : [];
        const disabled = getRole === ReadOnly ? true : undefined;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);
        const onYearChange = (event: unknown, value: IIdName): void => this.handleChange("year", value !== null ? value.name : data.year);
        const onCoordinator = (event: unknown, value: IIdName): void => this.handleChange("coordinator", value !== null ? value.name : "");
        const onSuperintendent = (event: unknown, value: IIdName): void => this.handleChange("superintendent", value !== null ? value.name : "");
        const Years = JobYears();
        const Employees = this.convertNameToIdName();
        const Companies = this.getCompanies();

        return (
            <AddUpdateJobStyles>
                {(getRole !== NotApplicable) &&
                    <>
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>JOB DETAILS</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    name="job_No"
                                    label="Job Number"
                                    fullWidth={true}
                                    variant="outlined"
                                    value={data.job_No}
                                    onChange={this.handleChange}
                                    disabled={data.id > 0 ? true : disabled}
                                    errorText={errors["job_No"] ? errors["job_No"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={Years}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onYearChange}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Year"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["year"] ? errors["year"].message : undefined}
                                    value={data.year ? Years.find(q => q.name === data.year) : null}
                                    defaultValue={data.year ? Years.find(q => q.name === data.year) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={YesOrNo}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onActive}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Active"
                                    selectionRemove={undefinedFunction}
                                    value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                    defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.type} onChange={this.onTypeChange}>
                                    <FormControlLabel value={IJobType[0].name} control={<Radio />} label={IJobType[0].name} />
                                    <FormControlLabel value={IJobType[1].name} control={<Radio />} label={IJobType[1].name} />
                                    <FormControlLabel value={IJobType[2].name} control={<Radio />} label={IJobType[2].name} />
                                </RadioGroup>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={3}
                                    fullWidth={true}
                                    name="client"
                                    label="Client"
                                    disabled={disabled}
                                    value={data.client}
                                    onChange={this.handleChange}
                                    errorText={errors["client"] ? errors["client"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={3}
                                    fullWidth={true}
                                    name="name"
                                    label="Name"
                                    disabled={disabled}
                                    value={data.name}
                                    onChange={this.handleChange}
                                    errorText={errors["name"] ? errors["name"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={Employees}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onCoordinator}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Coordinator"
                                    selectionRemove={undefinedFunction}
                                    value={(data.coordinator) ? Employees.find(q => q.name === data.coordinator) : null}
                                    defaultValue={(data.coordinator) ? Employees.find(q => q.name === data.coordinator) : null}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={true}
                                    option={Employees}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={this.onManagerChange}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Project Manager"
                                    selectionRemove={this.handleRemoveManager}
                                    value={(data.project_Manager != null && data.project_Manager !== undefined && Employees && (data.project_Manager.length > 0)) ? this.getSelectedManagers() : []}
                                    defaultValue={data.project_Manager ? ((Employees && (data.project_Manager.length > 0)) ? this.getSelectedManagers() : []) : []}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    disabled={disabled}
                                    multiple={false}
                                    option={Employees}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onSuperintendent}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Superintendent"
                                    selectionRemove={undefinedFunction}
                                    value={(data.superintendent) ? Employees.find(q => q.name === data.superintendent) : null}
                                    defaultValue={(data.superintendent) ? Employees.find(q => q.name === data.superintendent) : null}
                                />
                            </LAGridItem>
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LADropDownForOwners
                                    dropDownPlaceHolder="Company"
                                    option={Companies}
                                    defaultValue={data.company ? Companies.find(q => q.company === data.company) : null}
                                    value={data.company ? Companies.find(q => q.company === data.company) : null}
                                    getOptionLabel="company"
                                    filterSelectedOptions={true}
                                    autoHighlight={true}
                                    disabled={disabled}
                                    onChange={onCompany}
                                    errorText={errors["company"] ? errors["company"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={3}
                                    fullWidth={true}
                                    name="location"
                                    label="Location"
                                    disabled={disabled}
                                    value={data.location}
                                    onChange={this.handleChange}
                                    errorText={errors["location"] ? errors["location"].message : undefined}
                                />
                            </LAGridItem>
                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={1}
                                    fullWidth={true}
                                    name="working_Site"
                                    label="Working Site"
                                    disabled={true}
                                    value={data.working_Site}
                                    onChange={this.handleChange}
                                    errorText={errors["working_Site"] ? errors["working_Site"].message : undefined}
                                />
                            </LAGridItem>
                            }


                            {/* <LAGridItem xs={12}>
                            <LAButton
                                label="Add New Location"
                                onClick={this.locationPopup}
                            />
                        </LAGridItem> */}

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={1}
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={this.handleChange}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>
                            }
                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={1}
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    value={data.created_By}
                                    onChange={this.handleChange}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>
                            }

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={1}
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={this.handleChange}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>
                            }

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                <LATextArea
                                    rowsMax={1}
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={this.handleChange}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                            }

                            <LAGridItem xs={12} sm={6} md={4}>
                                <></>
                            </LAGridItem>

                            {serverError.length > ZEROTH && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}


                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    onSave={this.handleSave}
                                    cancelButtonText="Close"
                                    onCancel={this.handleCancel}
                                    disableSave={Object.values(errors).length > 0 ? true : disabled}
                                />
                            </LAGridItem>

                        </LAGrid>

                        <LAPopover anchorRef={null} open={locationPopup} onClose={this.locationPopup}>
                            <AddUpdateLocation
                                {...this.props}
                                id="0"
                                popUp={true}
                                onPopupClose={this.locationPopup}
                            />
                        </LAPopover>
                    </>}

                <RequestStatus requestStatus={addJobStatus.kind} successMessage="Job has been added successfully" />
                <RequestStatus requestStatus={updateJobStatus.kind} successMessage="Job has been updated successfully" />
            </AddUpdateJobStyles>
        );
    }

    private onLocationChange = (event: unknown, value: ILocation): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                location: value !== null ? value.site_Name : "",
                location_Id: value !== null ? value.id : undefined
            }
        });
    };


    private handleDropDownChange = (event: unknown, value: any, name?: string): void => {
        let errors = this.state.errors;

        if (name && (name === "company")) {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value : "", errors);

            this.setState({
                ...this.state,
                errors,
                data: {
                    ...this.state.data,
                    company: (value !== "" && value !== null) ? value : "",
                }
            });
        }
    };

    private locationPopup = (): void => {
        this.setState({ locationPopup: !this.state.locationPopup });
    };

    private onManagerChange = (event: unknown, values: IIdName[]): void => {
        const managers = values.map(x => x.name).join(",");

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                project_Manager: managers
            }
        });
    };

    private handleRemoveManager = (value: string): void => {
        let project_Manager = "";
        let selected = this.state.data.project_Manager.split(",");

        selected?.forEach(x => {
            if (x !== "" && x !== value) {
                if (project_Manager.length === 0) {
                    project_Manager = x;
                } else {
                    project_Manager = project_Manager + "," + x;
                }
            }

        });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                project_Manager
            }
        });
    };

    private getSelectedManagers = (): IIdName[] => {
        let response: IIdName[] = [];
        const arr = this.state.data.project_Manager.split(",");

        this.convertNameToIdName()?.forEach(x => {
            if (arr.includes(x.name))
                response.push(x);
        });
        return response;
    };

    private convertNameToIdName = (): IIdName[] => {
        if (hasPayload(this.props.getLookup)) {
            const list: IIdName[] = [];

            this.props.getLookup.payload.response.employees?.forEach((x, index) => {
                list.push({ id: index, name: x });
            });

            return list;
        };
        return [];
    };

    private getCompanies = (): ILookupCompanies[] => {
        if (hasPayload(this.props.getLookup)) {
            const list: ILookupCompanies[] = [];

            this.props.getLookup.payload.response.companies?.forEach((x, index) => {
                list.push({ id: index, name: x.name, company: x.company });
            });

            return list;
        };
        return [];
    };


    private onTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                type: value
            }
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        
        this.props.history.push({
            pathname: ROUTE.FIELD.JOBS.INDEX,
            search: getTokenFromUrl(false)
          });
    };

    private handleSave = async (): Promise<void> => {
        const { id, year, job_No, name, client, coordinator, project_Manager, superintendent, active, location, created_By, type, location_Id, working_Site, company } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addJobRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        Year: year,
                        Job_No: job_No,
                        Client: client,
                        Active: active,
                        Type: type,
                        Location: location,
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn,
                        Name: name,
                        Working_Site: working_Site,
                        Location_Id: location_Id,
                        Coordinator: coordinator,
                        Superintendent: superintendent,
                        Project_Manager: project_Manager,
                        Company: company,
                    }
                });
            } else {
                this.props.updateJobRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Year: year,
                        Job_No: job_No,
                        Client: client,
                        Active: active,
                        Type: type,
                        Location: location,
                        Created_By: created_By,
                        Modified_By: this.props.token.payload.response.upn,
                        Name: name,
                        Location_Id: location_Id,
                        Coordinator: coordinator,
                        Superintendent: superintendent,
                        Project_Manager: project_Manager,
                        Company: company,
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {

                if (pageAccessCheck(this.props.token, "jobList") !== NotApplicable) {
                    if (query.id !== "0") {
                        if (hasPayload(this.props.jobs)) {
                            const data = this.props.jobs.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getJobsRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                    if (isNotLoaded(this.props.getLocation)) {
                        this.props.getLocationsRequest({
                            token: this.props.token.payload.response.token
                        });
                    };

                    if (isNotLoaded(this.props.getLookup)) {
                        this.props.getLookupsRequest({
                            token: this.props.token.payload.response.token,
                            request: {
                                page: "Jobs"
                            }
                        });
                    };
                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateJobComponentStoreProps => ({
    jobs: getJobs(state),
    token: getToken(state),
    getLookup: getFieldLookups(state),
    addJobStatus: addJob(state),
    getLocation: getLocations(state),
    updateJobStatus: updateJob(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateJobComponentDispatchProps => ({
    getJobsRequest: (data: IJobsRequest) => dispatch(getJobsLoadAction(data)),
    addJobRequest: (data: IAddJobRequest) => dispatch(addJobLoadAction(data)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    updateJobRequest: (data: IUpdateJobRequest) => dispatch(updateJobLoadAction(data)),
    getLocationsRequest: (data: ILocationRequest) => dispatch(getLocationLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateJob);