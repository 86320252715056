import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { Navbar, SOCTNavbarMenu } from "../../../shared/navbars";
import { SOCTTabNavbarMenu, TabNavbar } from "../../../shared/tabNavbar";
import queryString from "query-string";
import { IGetServiceHistoryBySOCTIDRequest, IServiceHistory } from "../../../../redux/field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDConstants";
import { getServiceHistoryBySOCTIDLoadAction } from "../../../../redux/field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDActions";
import { getServiceHistoryBySOCTID } from "../../../../redux/field/soct/getServiceHistoryBySOCTID/getServiceHistoryBySOCTIDAccessor";
import LALinkButton from "../../../shared/linkButton";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

interface IServiceHistoryStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getServiceHistoryBySOCTID: Server<SurewayAPIResponse<ById<IServiceHistory>>>;
};

interface IServiceHistoryDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getServiceHistoryBySOCTIDRequest: (data: IGetServiceHistoryBySOCTIDRequest) => unknown;
};


interface IServiceHistoryOwnProps {

};

interface IServiceHistoryState {
    activeView: string;
    unit_Number: string | undefined;
    unit_ID: number | undefined;
};

const ServiceHistoryStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IServiceHistoryProps = RouteComponentProps
    & IServiceHistoryStoreProps
    & IServiceHistoryDispatchProps
    & IServiceHistoryOwnProps;

class ServiceHistory extends PureComponent<IServiceHistoryProps, IServiceHistoryState> {

    public constructor(props: IServiceHistoryProps) {
        super(props);
        this.state = {
            activeView: "All",
            unit_Number: undefined,
            unit_ID: undefined,
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IServiceHistoryProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {
        const { activeView } = this.state;
        const { getServiceHistoryBySOCTID } = this.props;
        const data = hasPayload(getServiceHistoryBySOCTID) ? getServiceHistoryBySOCTID.payload.response && this.filterDataByView(Object.values(getServiceHistoryBySOCTID.payload.response)) : [];
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        // console.log(data);
        const query = queryString.parse(this.props.location.search);

        return (
            <PageSpacing title="SOCT - Service History" description="SOCT - Service History" fixedSpaceOnSmallerScreens={true}>

                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                {query && query.id && Number(query.id) > 0 && <TabNavbar tabs={SOCTTabNavbarMenu} handleTabClick={this.handleTabClick}/>}
                <ServiceHistoryStyles>
                    {(getRole === true) && 
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">Service History - {this.state.unit_Number && this.state.unit_Number}</h2>
                                    <hr />

                                    {getServiceHistoryBySOCTID.kind === STATUS_ENUM.LOADING && <LALoading message="Loading SOCT Meter History..." />}
                                    {getServiceHistoryBySOCTID.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load SOCT Meter History..." />}

                                    {getServiceHistoryBySOCTID.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>

                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView} onChange={this.onViewClick}>
                                                    <FormControlLabel value="Scheduled Services" control={<Radio />} label="Scheduled Services" />
                                                    <FormControlLabel value="Custom Services" control={<Radio />} label="Custom Services" />
                                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                                </RadioGroup>
                                            </LAGridItem>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="ServiceHistoryList"
                                                    customRowColor={true}
                                                    exportFileName="ServiceHistory"
                                                    storageKey="ServiceHistoryListSessionKey"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    onCustomClick={this.handleCustomClick}
                                                    columns={[
                                                        // { name: "unit_Number", caption: "Unit Number", type: "string" },
                                                        { name: "service_Date", caption: "Service Date", type: "date" },
                                                        { name: "service_Type", caption: "Service Type", type: "string" },                                                   
                                                        { name: "meter_Reading", caption: "Meter Reading", type: "number" },
                                                        { name: "ecm", caption: "ECM", type: "number" },
                                                        { name: "admin_Comments", caption: "Admin Comments", type: "string" },
                                                        { name: "pM_Sheet_ID", caption: "PM Sheet", type: "string", renderCell : (e:any) => { return <LALinkButton className="linkUrl" label= {e.row.data.pM_Sheet_ID ? "PM Sheet" : ""} onClick={() => this.handleCustomClick("pm_Sheet", e)} />} },
                                                        { name: "comments", caption: "Comments", type: "string" },
                                                        { name: "custom_Service", caption: "Custom Service", type: "string" },
                                                        { name: "completed_Date", caption: "Completed Date", type: "date" },
                                                        { name: "cancelled", caption: "Cancelled", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime"},
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>
                                    }
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    } 
                </ServiceHistoryStyles>
            </PageSpacing>
        );
    }

    private onViewClick = (e:any, value:string): void => {
        this.setState({ activeView: value });
    };

    private filterDataByView = (data: IServiceHistory[]) => {
        let filteredData = [];
        const { activeView } = this.state;
        if(activeView === "Scheduled Services") {
            filteredData = data.filter(x => x.custom_Service !== "Yes")
        } 
        else if (activeView === "Custom Services") {
            filteredData = data.filter(x => x.custom_Service === "Yes")
        } 
        else {
            filteredData = data
        }
        return filteredData
    }

    private handleCustomClick = (name: string, e: any): void => {
        // console.log(name, e);
        if(name === "pm_Sheet") {
            window.open(ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT + "?id=" + e.row.data.pM_Sheet_ID.toString())
        }
        
    };

    private onEdit = (e: any): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.id) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: e.row.data.id.toString(), soct_ID: query.id.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.SERVICE_HISTORY.ADD_UPDATE_SERVICE_HISTORY
            });
        }
    };

    private onClick = (): void => {

    };

    private handleTabClick = (e: any, url: string): void => {
        const query = queryString.parse(this.props.location.search);
        if(e.target.textContent === "Unit History") {
            if(this.state.unit_ID)
                window.open(url + "?id=" + this.state.unit_ID.toString(), "_blank")
        }
        else {
            if(query && query.id) {
                callRouteWithQueryString({
                    route: this.props,
                    search: { id: query.id.toString(), token: getTokenFromUrl(true) },
                    pathName: url
                });
            }
        }
    };

    private handleAdd = (): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.id) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: "0", soct_ID: query.id.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.SERVICE_HISTORY.ADD_UPDATE_SERVICE_HISTORY
            });
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                if (isNotLoaded(this.props.getServiceHistoryBySOCTID)) {
                    this.getDataForTable();
                };

                if(hasPayload(this.props.getServiceHistoryBySOCTID)) {
                    if(this.props.getServiceHistoryBySOCTID.payload.response) {
                        const data = Object.values(this.props.getServiceHistoryBySOCTID.payload.response);
                        if(data.length > 0) {
                            const unit_Number = data[0].unit_Number ?? undefined;
                            const unit_ID = data[0].unit_ID ?? undefined;
                            this.setState({ unit_Number, unit_ID });
                        }
                    }
                }
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        const query = queryString.parse(this.props.location.search);
        if (hasPayload(this.props.getToken) && query && query.id) {
            this.props.getServiceHistoryBySOCTIDRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    SOCT_ID : Number(query.id)
                }
            });
        };
    };
}

const mapStateToProps = (state: IStore): IServiceHistoryStoreProps => ({
    getToken: getToken(state),
    getServiceHistoryBySOCTID: getServiceHistoryBySOCTID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IServiceHistoryDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getServiceHistoryBySOCTIDRequest: (data: IGetServiceHistoryBySOCTIDRequest) => dispatch(getServiceHistoryBySOCTIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHistory);