import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ATTACHMENT_MOVES_REQUEST, IAttachmentMove } from "./getAttachmentMovesConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetAttachmentMovesLoadAction, IGetAttachmentMovesLoadFailedAction, IGetAttachmentMovesSuccessAction, getAttachmentMovesLoadFailedAction, getAttachmentMovesLoadSuccessAction } from "./getAttachmentMovesActions";
import { withJsonContentType } from "../../../epicUtils";

export const getAttachmentMovesEpic: Epic = (
    action$: ActionsObservable<IGetAttachmentMovesLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAttachmentMovesSuccessAction | IGetAttachmentMovesLoadFailedAction> =>
    action$.ofType(IGET_ATTACHMENT_MOVES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IAttachmentMove>>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_ATTACHMENT_MOVE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IAttachmentMove>>): IGetAttachmentMovesSuccessAction => {
                            return getAttachmentMovesLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getAttachmentMovesLoadFailedAction(response.message)))
                    )
            )
        );