import { ISurewayApiRequest } from "../../../../react/shared/publicInterfaces";

export enum IGET_COMPANIES_REQUEST {
    REQUEST = "getCompanies/GET_COMPANIES_REQUEST",
    SUCCESS = "getCompanies/GET_COMPANIES_SUCCESS",
    FAILED = "getCompanies/GET_COMPANIES_FAILED"
};

export interface IGetCompaniesRequest extends ISurewayApiRequest {
    
};

export interface IGetCompaniesResponse {
    companyID: number;
    company: string;
    numberEmployees: number;
    explorerCode: string;
    adminJob: string;
    active: string;
};
