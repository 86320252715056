import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_OPERATOR_LOG_REQUEST, IAddOperatorLogRequest } from "./addOperatorLogConstants";


export interface IAddOperatorLogLoadAction {
    type: IADD_OPERATOR_LOG_REQUEST.REQUEST;
    data: IAddOperatorLogRequest
}
export const addOperatorLogLoadAction = (data: IAddOperatorLogRequest): IAddOperatorLogLoadAction => ({
    type: IADD_OPERATOR_LOG_REQUEST.REQUEST,
    data
});
export interface IAddOperatorLogSuccessAction {
    type: IADD_OPERATOR_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addOperatorLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddOperatorLogSuccessAction => ({
    type: IADD_OPERATOR_LOG_REQUEST.SUCCESS,
    message
});
export interface IAddOperatorLogLoadFailedAction {
    type: IADD_OPERATOR_LOG_REQUEST.FAILED;
    message: string;
}
export const addOperatorLogLoadFailedAction = (message: string): IAddOperatorLogLoadFailedAction => ({
    type: IADD_OPERATOR_LOG_REQUEST.FAILED,
    message
});
