import { connect } from "react-redux";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading, { LACenteredLoading } from "../../shared/loading";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { ReactNode, PureComponent } from "react";
import PageSpacing from "../../shared/pageSpacing";
import { Prompt, RouteComponentProps } from "react-router";
import { DropPaper } from "../../shared/dragAndDropPaper";
import { LAPaperWithLessPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { userName, getTokenFromUrl, sortByName, pageAccessCheck } from "../../shared/constExports";
import { hasPayload, isNotLoaded, Server, STATUS, STATUS_ENUM } from "../../../redux/server";
import { LAButton, LASecondaryButton } from "../../shared/buttons";
import { GREEN_COLOR, MEDIA_QUERY_PHONE, RED_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import React from "react";
import { IGetMechanicsRequest, IMechanic } from "../../../redux/field/mechanics/getMechanics/getMechanicsConstants";
import { IGetRepairLineRequest, IRepairLine } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { DispatchRepairLine, Mechanic } from "./mechanicsAssignmentDragAndDrop";
import { getRepairLines } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesAccessor";
import { getRepairLinesLoadAction } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesActions";
import { getMechanicsLoadAction } from "../../../redux/field/mechanics/getMechanics/getMechanicsActions";
import { getMechanics } from "../../../redux/field/mechanics/getMechanics/getMechanicsAccessor";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { saveMechanicAssignmentLoadAction } from "../../../redux/field/repairLine/saveMechanicAssignment/saveMechanicAssignmentActions";
import { ISaveMechanicAssignment, ISaveMechanicAssignmentRequest } from "../../../redux/field/repairLine/saveMechanicAssignment/saveMechanicAssignmentConstants";
import { saveMechanicAssignment } from "../../../redux/field/repairLine/saveMechanicAssignment/saveMechanicAssignmentAccessor";
import RequestStatus from "../../shared/requestStatusSnackbar";

interface IMechanicDispatchStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMechanicsStatus: Server<SurewayAPIResponse<ById<IMechanic>>>;
    saveMechanicAssignmentStatus: Server<SurewayAPIResponse<string>>;
    getRepairLine: Server<SurewayAPIResponse<ById<IRepairLine>>>;
};

interface IMechanicDispatchDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMechanicRequest: (data: IGetMechanicsRequest) => unknown;
    getRepairLinesRequest: (data: IGetRepairLineRequest) => unknown;
    saveMechanicAssignmentRequest: (data: ISaveMechanicAssignmentRequest) => unknown;
};


interface IMechanicDispatchOwnProps {

};

interface IMechanicDispatchState {
    lastPull: string;
    pullAlert: boolean;
    hasChange: boolean;
    repairLineSearch: string;
    serverError: string;
    mechanicSearch: string;
    expandIdx: number[];
    repairLine: ById<IRepairLine>;
};

const MechanicDispatchStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    
    .scroll {
        height: 600px;
        width: 100%;
        overflow: auto; 
    };

    .red-text {
        color: ${RED_COLOR};
    };

    .green-text {
        color: ${GREEN_COLOR};
    };
    
    .green-border {
        border: 4px solid green;
    };

    .show-on-phone {
        display: none;
    };

    .red-box {
        border: 2px solid ${RED_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        
        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMechanicDispatchProps = RouteComponentProps
    & IMechanicDispatchStoreProps
    & IMechanicDispatchDispatchProps
    & IMechanicDispatchOwnProps;

class MechanicDispatch extends PureComponent<IMechanicDispatchProps, IMechanicDispatchState> {

    public constructor(props: IMechanicDispatchProps) {
        super(props);
        this.state = {
            repairLine: {},
            lastPull: "",
            expandIdx: [],
            repairLineSearch: "",
            serverError: "",
            pullAlert: false,
            hasChange: false,
            mechanicSearch: ""
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.onInitialLoad();
        await this.callServer();
    };

    public componentDidUpdate(prevProps: IMechanicDispatchProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.saveMechanicAssignmentStatus !== prevProps.saveMechanicAssignmentStatus) {
                if (this.props.saveMechanicAssignmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveMechanicAssignmentStatus.message });
            };

        };
    };


    public render(): ReactNode {

        const { repairLine, mechanicSearch, repairLineSearch, hasChange, pullAlert, expandIdx } = this.state;
        const { getRepairLine, getMechanicsStatus, getToken, saveMechanicAssignmentStatus } = this.props;
        const getRole = pageAccessCheck(getToken, "repairLine");
        // console.log(getRole);
        const mechanics = hasPayload(getMechanicsStatus) ? getMechanicsStatus.payload.response : {};
        const mechanicsList = Object.values(mechanics).filter((m:any) => m.active === "Yes");
        const filterRepairLineByStatus = Object.values(repairLine).filter((r:any) => r.status === "Active")

        return (
            <PageSpacing title="Mechanic Assignment" description="Mechanic Assignment" fixedSpaceOnSmallerScreens={true}>
                <MechanicDispatchStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    {getRepairLine.kind === STATUS.LOADING && <LALoading message={pullAlert ? "The list has been updated since last refresh. Page is getting refreshed. Please assign mechanics with latest information!" : "Loading... Please wait"} />}
                    {getRepairLine.kind === STATUS.FAILED && <LAErrorBox text="Failed to load data... Please try again" />}

                    {((getRepairLine.kind === STATUS_ENUM.SUCCEEDED) && (saveMechanicAssignmentStatus.kind !== STATUS_ENUM.LOADING))
                        && <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                            <LAGrid className="pt-2">

                                {pullAlert && <LAGridItem xs={12}>
                                    <LAErrorBox text="The list has been updated since last refresh. Please refresh the page and assign mechanics with the latest information!" />
                                </LAGridItem>}

                                <LAGridItem xs={12} className="text-center">
                                    <LAButton
                                        label="Save Changes"
                                        onClick={this.callEndpoint}
                                    />
                                </LAGridItem>
                                <LAGridItem xs={6} sm={6} md={6}>
                                    <LAPaperWithLessPadding>
                                        <h2 className="text-center">REPAIR LINES</h2>
                                        <hr />

                                        <LAGrid className="scroll">
                                            <LAGridItem xs={12} sm={4} md={3}>
                                                <LASecondaryButton
                                                    className="mt-2"
                                                    label="Reset Search"
                                                    onClick={this.clearRepairLineSearch}
                                                />
                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={8} md={9}>
                                                <LATextArea
                                                    rowsMax={1}
                                                    fullWidth={true}
                                                    name="repairLineSearch"
                                                    value={repairLineSearch}
                                                    label="Search repairLine by name"
                                                    onChange={this.handleRepairLineSearch}
                                                />
                                            </LAGridItem>

                                            {(repairLine !== null) &&
                                                filterRepairLineByStatus
                                                    .map((x, index) => {
                                                        return (
                                                            <DispatchRepairLine
                                                                key={index}
                                                                data={x}
                                                                index={index}
                                                                expandIdx={expandIdx}
                                                                mechanics={mechanics}
                                                                onChange={this.handleChange}
                                                                onDelete={this.handleDelete}
                                                                onExpand={this.handleExpand}
                                                                repairLineSearch={repairLineSearch}
                                                            />
                                                        );
                                                    })}
                                        </LAGrid>
                                    </LAPaperWithLessPadding>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={6} md={6}>
                                    <LAPaperWithLessPadding>
                                        <h2 className="text-center">MECHANICS</h2>
                                        <hr />

                                        <LAGrid>
                                            <LAGridItem xs={12} sm={4} md={3}>
                                                <LASecondaryButton
                                                    className="mt-2"
                                                    label="Reset Search"
                                                    onClick={this.clearMechanicSearch}
                                                />

                                            </LAGridItem>

                                            <LAGridItem xs={12} sm={8} md={9}>
                                                <LATextArea
                                                    rowsMax={1}
                                                    fullWidth={true}
                                                    name="mechanicSearch"
                                                    value={mechanicSearch}
                                                    label="Search mechanic by name"
                                                    onChange={this.handleMechanicSearch}
                                                />
                                            </LAGridItem>
                                        </LAGrid>

                                        <Droppable key={1} droppableId="mechanic">
                                            {(provided, snapshot) => (
                                                <DropPaper className="text-center"
                                                    innerRef={provided.innerRef}
                                                    styleDraggar={snapshot.isDraggingOver}>

                                                    <LAGrid spacing={0}>
                                                        {Object.values(mechanicsList)
                                                            .sort((a, b) => sortByName(a, b, "firstName"))
                                                            .filter((x) => mechanicSearch.length > 0 ?
                                                                x.firstName.toLowerCase().includes(mechanicSearch.toLowerCase())
                                                                || x.lastName.toLowerCase().includes(mechanicSearch.toLowerCase())
                                                                : true)
                                                            .map((x, index) => {
                                                                return (
                                                                    <Mechanic
                                                                        data={x}
                                                                        key={index}
                                                                        index={index}
                                                                        repairLines={repairLine}
                                                                    />
                                                                );
                                                            })}
                                                    </LAGrid>
                                                    {provided.placeholder}
                                                </DropPaper>
                                            )}

                                        </Droppable>
                                    </LAPaperWithLessPadding>
                                </LAGridItem>

                                <LAGridItem xs={12} className="text-center">
                                    <LAButton
                                        label="Save Changes"
                                        onClick={this.callEndpoint}
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </DragDropContext>}

                    {(saveMechanicAssignmentStatus.kind === STATUS_ENUM.LOADING) && <LACenteredLoading message="Please wait... we are processing your save request" />}

                    <RequestStatus
                        failedMessage="RepairLine/s assignment failed! Please try again or contact IT.Developers@sureway.ca if the issue persists!"
                        requestStatus={saveMechanicAssignmentStatus.kind}
                        successMessage="RepairLine/s assignment is successful!"
                    />

                    <Prompt
                        when={hasChange}
                        message="You have unsaved changes. Please save it before leaving."
                    />

                </MechanicDispatchStyles>
            </PageSpacing>
        );
    }

    private handleExpand = (index: number): void => {
        const iS = [...this.state.expandIdx];
        const findIndex = iS.findIndex(x => x === index);
        
        if(findIndex >= 0){
            iS.splice(findIndex, 1);
        } else {
            iS.push(index);
        };
        this.setState({ expandIdx: iS });
    };

    private clearRepairLineSearch = (): void => {
        this.setState({
            repairLineSearch: ""
        });
    };

    private clearMechanicSearch = (): void => {
        this.setState({
            mechanicSearch: ""
        });
    };

    private handleChange = (name: string, value: string, id: number) => {
        const { repairLine } = this.state;

        repairLine[id] = {
            ...repairLine[id],
            [name]: value
        };

        this.setState({
            ...this.state,
            repairLine: {
                ...repairLine
            },
            hasChange: true
        });
    };

    private handleDelete = (id: number, mechId: number) => {
        const { repairLine } = this.state;

        let ids = repairLine[id].assigned_Mech.split(",").map((x) => Number(x));
        const index = ids.findIndex(x => x === mechId);
        ids.splice(index, 1);

        repairLine[id] = {
            ...repairLine[id],
            assigned_Mech: ids.join(",")
        }

        this.setState({
            ...this.state,
            repairLine: {
                ...this.state.repairLine,
                ...repairLine
            },
            hasChange: true
        });
    };

    private handleMechanicSearch = (name: string, value: string) => {
        this.setState({ mechanicSearch: value });
    };

    private handleRepairLineSearch = (name: string, value: string) => {
        this.setState({ repairLineSearch: value });
    };

    private onDragEnd = async (result: any): Promise<void> => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        const { repairLine, mechanicSearch } = this.state;
        const { getMechanicsStatus } = this.props;

        const mechanics = hasPayload(getMechanicsStatus) ?
            Object.values(getMechanicsStatus.payload.response)
                .sort((a, b) => sortByName(a, b, "firstName"))
                .filter((x) => mechanicSearch.length > 0 ?
                    x.firstName.toLowerCase().includes(mechanicSearch.toLowerCase())
                    || x.lastName.toLowerCase().includes(mechanicSearch.toLowerCase())
                    : true)

            : []

        const desId = destination.droppableId;
        if ((desId !== undefined && source.index !== undefined) && ((repairLine[desId]) && (mechanics[source.index]))) {

            if ((repairLine[desId].assigned_Mech === null) || (!repairLine[desId].assigned_Mech.includes(mechanics[source.index].id.toString()))
            ) {
                let ids = (repairLine[desId].assigned_Mech === null) ? [] : repairLine[desId].assigned_Mech.split(",").map((x) => Number(x));
                ids.push(mechanics[source.index].id);
               
                repairLine[desId].assigned_Mech = ids.join(",");
                this.setState({
                    ...this.state,
                    repairLine: {
                        ...this.state.repairLine,
                        ...repairLine
                    },
                    hasChange: true
                });

                const ind = Object.values(repairLine).findIndex(q => q.id === +desId);
                this.handleExpand(ind);
            }
        };
    };

    private onDragStart = () => {
        // Vibrate on drag (only Mobile)
        if (window.navigator.vibrate) {
            window.navigator.vibrate(200);
        };
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if(pageAccessCheck(this.props.getToken, "repairLine") !== "RW") {
                    this.props.history.push(ROUTE.ACCESS_DENIED);
            }
        };

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getRepairLine))
            this.props.getRepairLinesRequest({
                token: this.props.getToken.payload.response.token,
                request : {
                    view: "All"
                }
            });

        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getMechanicsStatus))
            this.props.getMechanicRequest({
                token: this.props.getToken.payload.response.token
            });

        if (hasPayload(this.props.getRepairLine)) {
            const res = this.props.getRepairLine.payload.response;
            this.setState({
                pullAlert: false,
                repairLine: res ? res : {},
                lastPull: new Date().toTimeString()
            });
        };

    };

    private onInitialLoad = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            this.props.getMechanicRequest({
                token: this.props.getToken.payload.response.token
            });

            this.props.getRepairLinesRequest({
                token: this.props.getToken.payload.response.token,
                request : {
                    view: "All"
                }
            });
        }
    };

    private handleSave = (): void => {
        if (hasPayload(this.props.getToken)) {
            const request: ISaveMechanicAssignment[] = Object.values(this.state.repairLine)
                .map(({ id, partsInstruction, technicianInstructions, assigned_Mech }) =>
                ({
                    Mechanic_IDS: assigned_Mech, Repair_Line_ID: id,
                    Parts_Instructions: partsInstruction ?? "",
                    Technician_Instructions: technicianInstructions ?? ""
                })
                );

            this.props.saveMechanicAssignmentRequest({
                request,
                token: this.props.getToken.payload.response.token,
                upn: this.props.getToken.payload.response.upn,
            });

            this.setState({
                hasChange: false
            });
        }
    };

    private callEndpoint = (): void => {
        this.handleSave();
        // if (hasPayload(this.props.getToken))
        //     fetch(END_POINTS.FIELD.CHECK_LAST_UPDATED_MOVE_REQUEST, {
        //         method: "POST",
        //         headers: {
        //             "Accept": "application/json",
        //             "Content-Type": "application/json"
        //         },
        //         body: JSON.stringify({
        //             token: this.props.getToken.payload.response.token,
        //             request: {
        //                 "Last_Fetched": this.state.lastPull
        //             }
        //         })
        //     })
        //         .then(res => {
        //             return res.json();
        //         })
        //         .then(res => {
        //             if (res.response) {
        //                 this.setState({ pullAlert: false });
        //                 this.handleSave();
        //             } else {
        //                 this.setState({ pullAlert: true });
        //             }
        //         })
        //         .catch((res) => console.log(res));
    };

}

const mapStateToProps = (state: IStore): IMechanicDispatchStoreProps => ({
    getToken: getToken(state),
    getRepairLine: getRepairLines(state),
    getMechanicsStatus: getMechanics(state),
    saveMechanicAssignmentStatus: saveMechanicAssignment(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IMechanicDispatchDispatchProps => ({
    getMechanicRequest: (data: IGetMechanicsRequest) => dispatch(getMechanicsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getRepairLinesRequest: (data: IGetRepairLineRequest) => dispatch(getRepairLinesLoadAction(data)),
    saveMechanicAssignmentRequest: (data: ISaveMechanicAssignmentRequest) => dispatch(saveMechanicAssignmentLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MechanicDispatch);