import React from "react";
import Chip from "@material-ui/core/Chip/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { HasClass } from "./publicInterfaces";
import { undefinedFunction } from "./constExports";
import LAGrid from "./grid";
import LAGridItem from "./gridList";
import { LIGHT_GREY_COLOR, RED_COLOR } from './theme';
import { GridSize } from "@material-ui/core";

interface IDropDownForJobs extends HasClass {
    option: any[];
    disExp: { objName: string, size: GridSize }[];
    getOptionLabel: string;
    defaultValue: any | [];
    disabled?: boolean;
    value: any;
    filterSelectedOptions: boolean;
    autoHighlight: boolean;
    onChange: (event: unknown, values: any) => void;
    dropDownPlaceHolder?: string;
    errorText?: string;
    size?: "small";
}

const DEFAULT_ID = "select-on-focus";


const LADropDownForJobs: React.FC<IDropDownForJobs> = (props: IDropDownForJobs) => {
    const helperText: string | undefined = props.errorText;
    const error: true | undefined = helperText ? true : undefined;
    // console.log(props.option)
    return (
        <Autocomplete
            freeSolo={false}
            size={props.size}
            className={props.className}
            filterSelectedOptions={props.filterSelectedOptions}
            filterOptions={(options, { inputValue }) => options
            .filter((item: any) => item[props.disExp[0].objName].toLowerCase().includes(inputValue.toLowerCase()) || (item[props.disExp[1].objName] && item[props.disExp[1].objName].toLowerCase().includes(inputValue.toLowerCase())))}
            options={props.option ? Object.values(props.option) : []}
            getOptionLabel={(option: any): string => option[props.getOptionLabel]}
            id={DEFAULT_ID}
            disabled={props.disabled || false}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            multiple={false}
            value={props.value}
            autoHighlight={props.autoHighlight}
            renderTags={(value: any): JSX.Element[] =>
                value.map((option: any, index: number): JSX.Element =>
                    <Chip
                        className="mb-2 ml-2 mr-2"
                        variant="outlined"
                        color="primary"
                        key={index}
                        disabled={props.disabled || false}
                        clickable={true}
                        label={option[props.getOptionLabel]}
                        onDelete={undefinedFunction}
                    />
                )
            }
            renderOption={(option, state: any) => {
                let ind = props.option.indexOf(option);
                return (
                    <LAGrid spacing={2} backCol={(props.getOptionLabel === "job" && option["joB_COMPLETE"] === "Y") ? RED_COLOR : (ind !== 0 ? isEven(props.option.indexOf(option)) ? LIGHT_GREY_COLOR : "" : "")} 
                    alignItems="center" justify="space-evenly">
                        {props.disExp.map((x, ind) => (
                            <LAGridItem key={ind} xs={x.size}>{option[x.objName]}</LAGridItem>
                        ))}
                    </LAGrid>
                );
            }}
            renderInput={(params): JSX.Element => {
                const inputProps: any = params.inputProps;
                inputProps.autoComplete = "off";
                return <TextField {...params}
                    rowsMax={2}
                    inputProps={inputProps}
                    multiline={true}
                    variant="outlined"
                    color="secondary"
                    label={props.dropDownPlaceHolder ?? "Search"}
                    error={error}
                    helperText={helperText}
                    fullWidth={true}
                    required={true}
                />;
            }}
        />
    );
};

export default LADropDownForJobs;

const isEven = (index: number): boolean => {
	if (index%2 === 0)
		return true;
	else
		return false;
}