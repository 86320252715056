import React, { useState } from "react";
import { LAPopover } from "./popOver";
import { LAPaperWithPadding } from "./paper";
import { IDevExtremeColumn, LADevExtremeGrid } from "./devExtreme";
import { RowClickEvent } from "devextreme/ui/data_grid";
import LAAutoComplete from "./autoComplete";
import { undefinedFunction } from "./constExports";
import { ById } from "./publicInterfaces";
import { IFieldErrorKeyValue } from "./fieldValidator";
import { LAButton } from "./buttons";

interface IDevExtremePopupLookup {
    id: string;
    data: any[];
    name: string;
    errorName?: string;
    currentVal: any;
    displayExp: string;
    returnExp?: any;
    placeHolder: string;
    columns: IDevExtremeColumn[];
    disabled: boolean | undefined;
    onClick: (value: any) => void;
    errors: ById<IFieldErrorKeyValue>;
    multiple?: boolean;
    onSelectionRemove?: (value: any) => void;
    selectionRow?: boolean;
    onSelectionChanged?: (row:any) => void;
    width?: number;
};

export const DevExtremePopupLookup: React.FC<IDevExtremePopupLookup> = React.memo((props: IDevExtremePopupLookup) => {
    const [anchorEl, setAnchorEl] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const onOpenClose = (): void => setOpen(!open);

    const onOpen = (event: React.SyntheticEvent<Element, Event>): void => {
        setAnchorEl(event.currentTarget);
        onOpenClose();
    };

    const onSelect = (e: RowClickEvent<any, unknown>): void => {
        if(props.multiple) {
            props.onClick((e.data))
        } 
        else {
            if(props.returnExp){
                if(e.data && e.data[props.returnExp])
                    props.onClick(e.data[props.returnExp])
            } 
            else {
                if (e.data && e.data[props.displayExp])
                    props.onClick(e.data[props.displayExp]);

            }
        }
        setOpen(false)
        // onOpenClose();
    };

    const onSelectionChanged = (row:any): void => {
        // console.log(row)
        setData(row)
        // props.onSelectionChanged && props.onSelectionChanged((row));
        // setOpen(false)
    }

    const onAddData = (): void => {
        props.onSelectionChanged && props.onSelectionChanged((data));
        setOpen(false)
    }

    const onRemove = (e: any, value: any): void => props.onClick("");
    const findRecord = props.data ? props.data.find(q => q[props.name]?.toString() === props.currentVal?.toString()) : null;
    // console.log(props.currentVal)
    return (
        <>
            <LAAutoComplete
                option={props.data}
                autoHighlight={true}
                onOpen={onOpen}
                hideIcon = {true}
                disabled={props.disabled}
                onChange={onRemove}
                filterSelectedOptions={true}
                getOptionLabel={props.displayExp}
                dropDownPlaceHolder={props.placeHolder}
                errorText={props.errorName && props.errors[props.errorName] ? props.errors[props.errorName].message : undefined}
                value={props.multiple ? props.currentVal : (props.data && props.currentVal && findRecord) ? findRecord : ""}
                defaultValue={props.multiple ? props.currentVal : (props.data && props.currentVal && findRecord) ? findRecord : ""}
                multiple={props.multiple ? props.multiple : false}
                selectionRemove={props.onSelectionRemove ? props.onSelectionRemove : undefinedFunction}
            />

            <LAPopover open={open} onClose={onOpenClose} anchorRef={anchorEl} anchorOrigin={{ horizontal: "right", vertical: "bottom" }} width={props.width}>
                <LAPaperWithPadding className="text-center">
                    <LADevExtremeGrid
                        height={350}
                        id={props.id}
                        key={props.id}
                        data={props.data}
                        searchPanel={true}
                        filterHeader={true}
                        onClick={onSelect}
                        columns={props.columns}
                        autoFocus={true}
                        selectionRow={props.selectionRow}
                        onSelectionChanged={onSelectionChanged}
                    />
                    <br />
                    {props.multiple && props.selectionRow && <LAButton className="text-center" onClick={onAddData} label="Add"></LAButton>}
                </LAPaperWithPadding>
            </LAPopover>
        </>
    );
});