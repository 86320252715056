import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIRLINE_HISTORY_REQUEST, IGetRepairLineHistory, IGetRepairLineHistoryRequest } from "./getRepairLineHistoryConstants";

export interface IGetRepairLineHistoryLoadAction {
    type: IGET_REPAIRLINE_HISTORY_REQUEST.REQUEST;
    data: IGetRepairLineHistoryRequest
}
export const getRepairLineHistoryLoadAction = (data: IGetRepairLineHistoryRequest): IGetRepairLineHistoryLoadAction => ({
    type: IGET_REPAIRLINE_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineHistorySuccessAction {
    type: IGET_REPAIRLINE_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetRepairLineHistory>>;
}
export const getRepairLineHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetRepairLineHistory>>): IGetRepairLineHistorySuccessAction => ({
    type: IGET_REPAIRLINE_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineHistoryLoadFailedAction {
    type: IGET_REPAIRLINE_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getRepairLineHistoryLoadFailedAction = (message: string): IGetRepairLineHistoryLoadFailedAction => ({
    type: IGET_REPAIRLINE_HISTORY_REQUEST.FAILED,
    message
});
