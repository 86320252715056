import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST {
    REQUEST = "getMechanicLogsByDefectID/GET_MECHANIC_LOGS_BY_DEFECT_ID_REQUEST",
    SUCCESS = "getMechanicLogsByDefectID/GET_MECHANIC_LOGS_BY_DEFECT_ID_SUCCESS",
    FAILED = "getMechanicLogsByDefectID/GET_MECHANIC_LOGS_BY_DEFECT_ID_FAILED"
};

export interface IGetMechanicLogsByDefectIDRequest extends ISurewayTokenRequestBody {
    request: {
        id: number;
    };
};

export interface IMechanicLogsByDefectID {
  id: number;
  defect_ID: number;
  display: string;
  defect_Item: string;
  date: string;
  component_Code: string;
  mechanic_Name: string;
  comments: string;
}