
export enum IADD_LEVEL_REQUEST {
    REQUEST = "addLevel/ADD_LEVEL_REQUEST",
    SUCCESS = "addLevel/ADD_LEVEL_SUCCESS",
    FAILED = "addLevel/ADD_LEVEL_FAILED"
};

export interface IAddUpdateLevelRequest {
    token: string;
    request: IAddUpdateLevel;
};

export interface IAddUpdateLevel {
    ID: number;
    Serial_No: string;
    Brand?: string;
    Model?: string;
    Status?: string;
    // Site_Foreman?: string;
    Certification_Date?: string;
    Notes?: string;
    WSS_ID: number | null;
    Foreman: string;
    Created_By?: string;
    Modified_By?: string;
};