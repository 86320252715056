import { IAddUpdateOperatorLog } from "../addOperatorLog/addOperatorLogConstants";

export enum IUPDATE_OPERATOR_LOG_REQUEST {
    REQUEST = "updateOperatorLog/UPDATE_OPERATOR_LOG_REQUEST",
    SUCCESS = "updateOperatorLog/UPDATE_OPERATOR_LOG_SUCCESS",
    FAILED = "updateOperatorLog/UPDATE_OPERATOR_LOG_FAILED"
};

export interface IUpdateOperatorLogRequest {
    token: string;
    request: IAddUpdateOperatorLog;
};