import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import LALoading from "../../shared/loading";
import LATextArea from "../../shared/textArea";
import { ReactNode, PureComponent } from "react";
import { LAPopover } from "../../shared/popOver";
import { RouteComponentProps } from "react-router";
import { LACheckBox } from "../../shared/checkBox";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, ByIdNumber, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { LAGreyPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { GREEN_COLOR, LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import LAGrid from "../../shared/grid";
import { v4 as uuidv4 } from "uuid";
import LAGridItem from "../../shared/gridList";
import { AddIcon, ArrowLeftIcon, DeleteIcon, EditIcon, SaveIcon, UploadIcon } from "../../shared/icons";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import LAErrorBox from "../../shared/errorBox";
import { LAButton, LAIconButton, LAIconUploadButton, LASaveAndCancelButton, LASecondaryButton } from "../../shared/buttons";
import { deleteText, fileToBase64Image, getDate, getTokenFromUrl, numberAndDecimalCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import { IGetRepairLineRequest, IRepairLine, IRepairLineItem, IRepairLinePicture } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { getRepairLines } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesAccessor";
import { getRepairLinesLoadAction } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesActions";
import { validateMechanicLogin } from "../../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginAccessor";
import { IValidateMechanicLogin } from "../../../redux/field/mechanics/validateMechanicLogin/validateMechanicLoginConstants";
import { ComponentStatusList, PartStatus, PriorityList, RepairLineStatusList } from "../../field/repairLine/repairLine";
import { updateRepairLineByMechanicLoadAction } from "../../../redux/field/mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicActions";
import { IUpdateRepairLineByMechanicItem, IUpdateRepairLineByMechanicRequest } from "../../../redux/field/mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicConstants";
import { updateRepairLineByMechanicStatus } from "../../../redux/field/mechanics/updateRepairLineByMechanic/updateRepairLineByMechanicAccessor";
import { IAddMechanicLogRequest } from "../../../redux/field/mechanics/addMechanicLog/addMechanicLogConstants";
import { IUpdateMechanicLogRequest } from "../../../redux/field/mechanics/updateMechanicLog/updateMechanicLogConstants";
import { updateMechanicLogLoadAction } from "../../../redux/field/mechanics/updateMechanicLog/updateMechanicLogActions";
import { addMechanicLogLoadAction } from "../../../redux/field/mechanics/addMechanicLog/addMechanicLogActions";
import { updateMechanicLogStatus } from "../../../redux/field/mechanics/updateMechanicLog/updateMechanicLogAccessor";
import { addMechanicLogStatus } from "../../../redux/field/mechanics/addMechanicLog/addMechanicLogAccessor";
import LAAutoComplete from "../../shared/autoComplete";
import { getMechanicLogsByIdLoadAction } from "../../../redux/field/mechanics/getMechanicLogsById/getMechanicLogsByIdActions";
import { IGetMechanicLogsByIdRequest, IMechanicLogById } from "../../../redux/field/mechanics/getMechanicLogsById/getMechanicLogsByIdConstants";
import { getMechanicLogsById } from "../../../redux/field/mechanics/getMechanicLogsById/getMechanicLogsByIdAccessor";
import LADatePicker from "../../shared/datePicker";
import LATextField from "../../shared/textField";
import { FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { webConfig } from "../../../utils/webConfig";
import { LADropZone } from "../../shared/dropZone";



interface IAddUpdateMechanicRepairLineComponentStoreProps {
    token: Server<IValidateMechanicLogin>;
    addMechanicLogStatus: Server<SurewayAPIResponse<string>>;
    repairLines: Server<SurewayAPIResponse<ById<IRepairLine>>>;
    updateRepairLineStatus: Server<SurewayAPIResponse<string>>;
    updateMechanicLogStatus: Server<SurewayAPIResponse<string>>;
    getLogs: Server<SurewayAPIResponse<ById<IMechanicLogById>>>;
};

interface IAddUpdateMechanicRepairLineComponentDispatchProps {
    getLogsRequest: (data: IGetMechanicLogsByIdRequest) => unknown;
    getRepairLinesRequest: (data: IGetRepairLineRequest) => unknown;
    addMechanicLogRequest: (data: IAddMechanicLogRequest) => unknown;
    updateMechanicLogRequest: (data: IUpdateMechanicLogRequest) => unknown;
    updateRepairLineRequest: (data: IUpdateRepairLineByMechanicRequest) => unknown;
};

interface IAddUpdateMechanicRepairLineOwnProps {
    id?: string;
};

interface IAddUpdateMechanicRepairLineComponentState {
    data: IRepairLine;
    serverError: string;
    logs: IMechanicLogById[];
    imageUploadLoading: boolean;
    popUpIndex: number | undefined;
    addUpdateLog: number | undefined;
    popupImgs: IRepairLinePicture[];
    original: IRepairLineItem | undefined;
    subTableErrors: ByIdNumber<ById<IFieldErrorKeyValue>>;
};

const AddUpdateMechanicRepairLineStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .parts-back {
        background-color: ${LIGHT_GREY_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .thumbnail-img{
        border-width: 0;
        height: auto;
        max-height: 130px;
        max-width: 130px;
        vertical-align: middle;
        width: auto;
      }
`;

type IAddUpdateMechanicRepairLineComponentProps =
    RouteComponentProps
    & IAddUpdateMechanicRepairLineOwnProps
    & IAddUpdateMechanicRepairLineComponentStoreProps
    & IAddUpdateMechanicRepairLineComponentDispatchProps;

class AddUpdateMechanicRepairLine extends PureComponent<IAddUpdateMechanicRepairLineComponentProps, IAddUpdateMechanicRepairLineComponentState> {

    public constructor(props: IAddUpdateMechanicRepairLineComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                unit_ID: 0,
                repair_Attachment: "No",
                attachment_ID: 0,
                components: "",
                parts: "",
                initiated_Date: new Date().toDateString(),
                requested_By: "",
                assigned_Mech: "",
                status: "Active",
                comments: "",
                manager_Instructions: "",
                estimated_Completion_Date: "",
                date_Completed: "",
                location_Info: "",
                work_Order_No: "",
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
                send_Email: "Yes",
                email_Log: "",
                items: [],
                mechanic_Names: "",
                assigned_Mechanic_ID: 0,
                priority: ""
            },
            logs: [],
            popupImgs: [],
            serverError: "",
            subTableErrors: {},
            original: undefined,
            popUpIndex: undefined,
            addUpdateLog: undefined,
            imageUploadLoading: false
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMechanicRepairLineComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.updateRepairLineStatus !== prevProps.updateRepairLineStatus) {

                if (this.props.updateRepairLineStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRepairLineStatus.message });

                if (hasPayload(this.props.updateRepairLineStatus) && this.props.updateRepairLineStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }
        }
    };


    public render(): ReactNode {

        const { updateRepairLineStatus, token, addMechanicLogStatus, updateMechanicLogStatus } = this.props;
        const { data, serverError, imageUploadLoading, popUpIndex, logs, addUpdateLog, subTableErrors, popupImgs } = this.state;

        const disabled = hasPayload(token) ? (token.payload.mechanic.id === data.assigned_Mechanic_ID) ? undefined : true : true;

        const mechLogStatus = (addMechanicLogStatus.kind === STATUS_ENUM.LOADING || updateMechanicLogStatus.kind === STATUS_ENUM.LOADING) ?
            true : false;

        return (
            <AddUpdateMechanicRepairLineStyles>
                {imageUploadLoading ?
                    <LAGreyPaper>
                        <LALoading message="Uploading Image... Please Wait" />
                    </LAGreyPaper>
                    : <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>MECHANIC REPAIR LINE</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Unit: </strong>
                            <span>{data.unit_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Requested By: </strong>
                            <span>{data.requested_By}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Status: </strong>
                            <span>{data.status && RepairLineStatusList ? RepairLineStatusList.find(q => q.name === data.status)?.name : ""}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Assigned Mechanic: </strong>
                            <span>{data.mechanic_Names}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Work Order #: </strong>
                            <span>{data.work_Order_No}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Comments: </strong>
                            <span>{data.comments}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Initiated Date: </strong>
                            <span>{getDate(false, data.initiated_Date)}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Estimated Completion Date: </strong>
                            <span>{getDate(false, data.estimated_Completion_Date)}</span>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LACheckBox
                                label="Assigned to me"
                                onChange={this.onAssignedToMe}
                                value={(data.assigned_Mechanic_ID === 0) ? false : true}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <strong>Manager INstructions: </strong>
                            <span>{data.manager_Instructions ?? ""}</span>
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <strong>Components: </strong>
                            {data.items.map((x, index) => {
                                const onClick = (): void => this.handleComponentClick(x, index);
                                return (
                                    <LASecondaryButton
                                        key={index}
                                        onClick={onClick}
                                        label={x.component}
                                        className="mr-2"
                                    />
                                );
                            })}
                        </LAGridItem>


                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disabled}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>}

                {(popUpIndex !== undefined) && <LAPopover
                    anchorRef={null}
                    onClose={this.handleComponentClose}
                    open={(popUpIndex !== undefined) ? true : false}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    transformOrigin={{ horizontal: "left", vertical: "center" }}>
                    <MechanicRepairLineComponent
                        logs={logs}
                        data={data.items}
                        disabled={disabled}
                        popupImgs={popupImgs}
                        popUpIndex={popUpIndex}
                        onAddLog={this.onAddLog}
                        logLoading={mechLogStatus}
                        onLogSave={this.onLogSave}
                        addUpdateLog={addUpdateLog}
                        onLogChange={this.onLogChange}
                        onLogUpdate={this.onUpdateLog}
                        onImgDelete={this.onImgDelete}
                        subTableErrors={subTableErrors}
                        handleUpload={this.pictureUpload}
                        onPopupSave={this.handlePopupSave}
                        onImageName={this.handleImageName}
                        handleChange={this.handleSubChange}
                        handleClose={this.handleComponentClose}
                        handleMeterHours={this.handleMeterHours}
                    />
                </LAPopover>}

                <RequestStatus requestStatus={updateRepairLineStatus.kind} successMessage="Repair Line has been updated successfully" />

            </AddUpdateMechanicRepairLineStyles>
        );
    }


    private onLogSave = (): void => {
        const { addUpdateLog, logs } = this.state;

        if (hasPayload(this.props.token) && (addUpdateLog !== undefined)) {
            const data = logs[addUpdateLog];

            if (data.id === 0) {
                this.props.addMechanicLogRequest({
                    token: this.props.token.payload.token,
                    request: {
                        Date: data.date,
                        Hours: data.hours ?? 0,
                        Comments: data.comments,
                        Component_ID: data.component_ID,
                        Mechanic_ID: this.props.token.payload.mechanic.id,
                        Created_By: this.props.token.payload.mechanic.name
                    }
                });
            } else {
                this.props.updateMechanicLogRequest({
                    token: this.props.token.payload.token,
                    request: {
                        ID: data.id,
                        Date: data.date,
                        Hours: data.hours ?? 0,
                        Comments: data.comments,
                        Created_By: data.created_By,
                        Component_ID: data.component_ID,
                        Mechanic_ID: this.props.token.payload.mechanic.id,
                    }
                });
            };
        };

        this.setState({ addUpdateLog: undefined });
    };

    private onLogChange = (name: string, value: string, index: number): void => {
        const logs = [...this.state.logs];
        logs[index] = { ...logs[index], [name]: value };

        this.setState({ logs });
    };

    private onAddLog = (id: number): void => {
        if (hasPayload(this.props.token)) {
            const logs = [...this.state.logs];

            logs.push({
                id: 0,
                created: "",
                comments: "",
                modified: "",
                modified_By: "",
                component_ID: id,
                date: new Date().toDateString(),
                mechanic_ID: this.props.token.payload.mechanic.id,
                created_By: this.props.token.payload.mechanic.name ?? ""
            });

            this.setState({ logs, addUpdateLog: logs.length - 1 });
        };
    };

    private onUpdateLog = (addUpdateLog: number): void => {
        this.setState({
            addUpdateLog
        });
    };

    private handlePopupSave = (): void => {
        const { popUpIndex, data, popupImgs } = this.state;

        if (popUpIndex === undefined) {
            return;
        }

        const updatedItems = [...data.items];
        updatedItems[popUpIndex] = {
            ...updatedItems[popUpIndex],
            pictures: popupImgs
        };

        this.setState({
            popUpIndex: undefined,
            data: {
                ...data,
                items: updatedItems
            },
            original: undefined,
            popupImgs: []
        });
    };

    private handleComponentClick = (popupData: IRepairLineItem, popUpIndex: number): void => {
        this.setState({
            popUpIndex,
            original: popupData,
            popupImgs: popupData.pictures === null ? [] : popupData.pictures
        });
    };

    private handleComponentClose = (): void => {
        const { original, popUpIndex, data } = this.state;

        if (popUpIndex !== undefined) {
            if (original) {
                data.items[popUpIndex] = original;

                const logsCopy = hasPayload(this.props.getLogs) ? Object.values(this.props.getLogs.payload.response) : [];

                this.setState({
                    popUpIndex: undefined,
                    popupImgs: [],
                    logs: logsCopy,
                    data
                });
            } else {
                const iS = [...this.state.data.items];

                iS.splice(popUpIndex, 1);
                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        items: iS
                    },
                    popUpIndex: undefined,
                    popupImgs: []
                });
            }
        }
    };

    private handleImageName = (name: string, value: string, index: number, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], [name]: value };
        popupImgs[subIndex] = picture;

        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private onImgDelete = (name: string, index: number, imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const popupImgs = [...this.state.popupImgs];
            popupImgs.splice(imgIndex, 1);

            this.setState({
                ...this.state,
                popupImgs: [
                    ...popupImgs
                ]
            });
        }
    };

    private handleSubChange = (name: string, value: string, index?: number | undefined): void => {
        if (index !== undefined) {
            let errors = this.state.subTableErrors;
            const list = [...this.state.data.items];
            let item = { ...list[index], [name]: value };

            if ((name === "status") && (value === ComponentStatusList[3].name)) {
                if ((errors[index] === undefined) && (item.meter_Reading === undefined)) {
                    errors[index] = {};
                    errors[index]["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };

                if (errors[index] && (item.meter_Reading === undefined)) {
                    if (errors[index]["meter_Reading"] === undefined)
                        errors[index]["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            }
            else if ((name === "status") && (value !== ComponentStatusList[3].name)) {
                item.meter_Reading = undefined;
                if (errors[index])
                    if (errors[index]["meter_Reading"]) {
                        delete errors[index]["meter_Reading"];
                    }
            };

            list[index] = item;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: list
                },
                subTableErrors: errors
            });
        }
    };

    private handleMeterHours = (name: string, value: string, index: number): void => {
        if (numberAndDecimalCheck(value)) {
            let errors = this.state.subTableErrors;
            const list = [...this.state.data.items];
            let item = { ...list[index], [name]: value };

            if (value.length > 0) {
                if (errors[index]) {
                    if (errors[index][name])
                        delete errors[index][name];
                }
            } else {
                if (errors[index] === undefined) {
                    errors[index] = {};
                }
                errors[index][name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            list[index] = item;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: list
                },
                subTableErrors: errors
            });
        }
    };

    private pictureUpload = async (index: number, event: any): Promise<void> => {

        this.setState({ imageUploadLoading: true });

        const files = event;
        const original = this.state.original;
        const popupImgs = [...this.state.popupImgs];

        if (original) {
            if (files !== null) {

                await Promise.all(Object.values(files).map(async (x: any) => {
                    const imgId = uuidv4();
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    const description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.push({
                        name: imgId + "." + description.split(".")[1],
                        description: description.split(".")[0],
                        base64String,
                    });

                    const imgPaths = original.images !== null ? original.images.split(",") : [];
                    imgPaths.push(imgId + "." + description.split(".")[1]);
                    original.images = imgPaths.filter(Boolean).join(",");

                    this.setState({
                        ...this.state,
                        original: {
                            ...original
                        },
                        popupImgs: [
                            ...popupImgs
                        ],
                        imageUploadLoading: false
                    });
                }));
            }
        }
    };

    private onAssignedToMe = (value: boolean): void => {
        if (hasPayload(this.props.token)) {
            this.setState({
                data: {
                    ...this.state.data,
                    assigned_Mechanic_ID: value ? this.props.token.payload.mechanic.id : 0
                }
            });
        };
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.EXTERNAL.MECHANICS,
            search: getTokenFromUrl(false)
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const { id, unit_ID, items, assigned_Mechanic_ID } = data;

        if (hasPayload(this.props.token)) {

            const requestItems: IUpdateRepairLineByMechanicItem[] = [];

            await items.forEach(async (x, index) => {

                const images = x.pictures !== null && x.pictures.length > 0 ? x.pictures.map(({ name }) => name) : [];

                requestItems.push({
                    ID: x.id,
                    parts: [],
                    Images: images.join(","),
                    Status: x.status,
                    Pictures: x.pictures,
                    Comments: x.comments,
                    Meter_Reading: x.meter_Reading
                });
            });

            this.props.updateRepairLineRequest({
                token: this.props.token.payload.token,
                request: {
                    ID: id,
                    Unit_ID: unit_ID,
                    Items: requestItems,
                    Assigned_Mechanic_ID: assigned_Mechanic_ID,
                    Modified_By: this.props.token.payload.mechanic.name ?? "",
                }
            });

            this.setState({ serverError: "" });
        }
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {

                if (isNotLoaded(this.props.getLogs) && hasPayload(this.props.token))
                    this.props.getLogsRequest({
                        token: this.props.token.payload.token,
                        request: {
                            mechanic_id: this.props.token.payload.mechanic.id
                        }
                    });

                if (hasPayload(this.props.getLogs)) {
                    const logsCopy = Object.values(this.props.getLogs.payload.response);
                    this.setState({
                        logs: logsCopy
                    });
                };


                if (hasPayload(this.props.repairLines)) {
                    if (query.id !== "0") {

                        if (this.state.data.id !== Number(query.id)) {
                            const value = this.props.repairLines.payload.response[query.id];

                            this.setState({
                                data: {
                                    ...value,
                                    assigned_Mechanic_ID: ((value.assigned_Mech !== null)
                                        && (value.assigned_Mech.split(",").includes(this.props.token.payload.mechanic.id.toString())))
                                        ? this.props.token.payload.mechanic.id : 0
                                }
                            });
                        }

                    };
                } else {
                    this.props.getRepairLinesRequest({
                        token: this.props.token.payload.token,
                        request : {
                            view: "All"
                        }
                    });
                }

            } else {
                this.props.history.push({
                    pathname: ROUTE.INDEX,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMechanicRepairLineComponentStoreProps => ({
    token: validateMechanicLogin(state),
    repairLines: getRepairLines(state),
    getLogs: getMechanicLogsById(state),
    addMechanicLogStatus: addMechanicLogStatus(state),
    updateMechanicLogStatus: updateMechanicLogStatus(state),
    updateRepairLineStatus: updateRepairLineByMechanicStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMechanicRepairLineComponentDispatchProps => ({
    getRepairLinesRequest: (data: IGetRepairLineRequest) => dispatch(getRepairLinesLoadAction(data)),
    getLogsRequest: (data: IGetMechanicLogsByIdRequest) => dispatch(getMechanicLogsByIdLoadAction(data)),
    addMechanicLogRequest: (data: IAddMechanicLogRequest) => dispatch(addMechanicLogLoadAction(data)),
    updateMechanicLogRequest: (data: IUpdateMechanicLogRequest) => dispatch(updateMechanicLogLoadAction(data)),
    updateRepairLineRequest: (data: IUpdateRepairLineByMechanicRequest) => dispatch(updateRepairLineByMechanicLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMechanicRepairLine);


interface IMechanicRepairLineComponent {
    disabled?: true;
    popUpIndex: number;
    logLoading: boolean;
    onPopupSave: () => void;
    handleClose: () => void;
    data: IRepairLineItem[];
    logs: IMechanicLogById[];
    popupImgs: IRepairLinePicture[];
    addUpdateLog: number | undefined;
    onLogSave: (index: number) => void;
    onLogUpdate: (index: number) => void;
    onAddLog: (componentId: number) => void;
    subTableErrors: ByIdNumber<ById<IFieldErrorKeyValue>>;
    onImgDelete: (name: string, ind: number, imgIndex: number) => void;
    onLogChange: (name: string, value: string, index: number) => void;
    handleMeterHours: (name: string, value: string, index: number) => void;
    handleChange: (name: string, value: string, index?: number | undefined) => void;
    onImageName: (name: string, value: string, index: number, subIndex: number) => void;
    handleUpload: (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
};

const MechanicRepairLineComponentStyles = styled(LAPaperWithPadding)`
    padding: 40px 40px;

    .addPartBtnClass{
        text-align: left;
    }
`;
const MechanicRepairLineComponent: React.FC<IMechanicRepairLineComponent> = (props: IMechanicRepairLineComponent) => {

    return (
        <MechanicRepairLineComponentStyles>
            <LAPaperWithLessPadding>

                <LAGrid>
                    {props.data.map((x, index) => {

                        const handleMeterHours = (name: string, value: string): void => props.handleMeterHours(name, value, index);
                        const handleStatus = (event: unknown, values: any): void => props.handleChange("status", values != null ? values.name : x.status, index);

                        if (index === props.popUpIndex) {
                            return (
                                <LAGridItem xs={12} key={index}>
                                    <LAGrid>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <strong>Component: </strong>
                                            <span>{x.component}</span>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <strong>Component Code: </strong>
                                            <span>{x.component_Code}</span>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <strong>Description: </strong>
                                            <span>{x.description}</span>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <strong>Priority: </strong>
                                            <span>{x.priority && PriorityList ? PriorityList.find(q => q.name === x.priority)?.name : ""}</span>
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <LAAutoComplete
                                                multiple={false}
                                                option={ComponentStatusList}
                                                autoHighlight={true}
                                                onChange={handleStatus}
                                                disabled={props.disabled}
                                                getOptionLabel="name"
                                                filterSelectedOptions={true}
                                                dropDownPlaceHolder="Status"
                                                selectionRemove={undefinedFunction}
                                                value={x.status && ComponentStatusList ? ComponentStatusList.find(q => q.name === x.status) : null}
                                                defaultValue={x.status && ComponentStatusList ? ComponentStatusList.find(q => q.name === x.status) : null}
                                            />
                                        </LAGridItem>

                                        {(ComponentStatusList[3].name === x.status) && <LAGridItem xs={12} sm={5} md={2}>
                                            <LATextField
                                                type="number"
                                                label="Meter Reading"
                                                fullWidth={true}
                                                variant="outlined"
                                                name="meter_Reading"
                                                disabled={props.disabled}
                                                value={x.meter_Reading ?? ""}
                                                onChange={handleMeterHours}
                                                errorText={
                                                    props.subTableErrors[index] && props.subTableErrors[index]["meter_Reading"] ?
                                                        props.subTableErrors[index]["meter_Reading"].message
                                                        : undefined
                                                }
                                            />
                                        </LAGridItem>}

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <LATextArea
                                                minRows={2}
                                                rowsMax={4}
                                                disabled={true}
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="foreman_Comments"
                                                label="Foreman Comments"
                                                value={x.foreman_Comments}
                                                onChange={props.handleChange}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <LATextArea
                                                minRows={2}
                                                rowsMax={4}
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="comments"
                                                label="Mechanic Comments"
                                                disabled={props.disabled}
                                                value={x.comments}
                                                onChange={props.handleChange}
                                            />
                                        </LAGridItem>
                                        
                                        <LAGridItem xs={12} sm={6} md={2}>
                                            <LACheckBox
                                                name="major_Component_Replacement"
                                                label="Major Component Replacement"
                                                disabled={true}
                                                onChange={undefinedFunction}
                                                value={(x.major_Component_Replacement === "Yes") ? true : false}
                                            />
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            {/* <LAIconUploadButton
                                                imagesOnly={true}
                                                label="Upload Image"
                                                icon={<UploadIcon />}
                                                id={index.toString()}
                                                disabled={props.disabled}
                                                onClick={(e) => props.handleUpload(index, e)}
                                            /> */}
                                            <LAGridItem xs={12} className="text-center">
                                                <strong>Upload Images</strong>
                                                <LADropZone onFileDrop={(e:any) => props.handleUpload(index, e)} />
                                            </LAGridItem>
                                            <br />

                                            <LAGrid>
                                                {props.popupImgs
                                                    .map((q, ind) => {
                                                        const onImgDelete = (): void => props.onImgDelete(q.name, index, ind);
                                                        const base64 = q.base64String !== null ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                        const onImageName = (name: string, value: string): void => props.onImageName(name ? name : "", value, index, ind);

                                                        return (
                                                            <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                                <LAPaperWithLessPadding>
                                                                    <LAThumbnailWithLink
                                                                        alt={q.name}
                                                                        key={ind}
                                                                        id={(ind + index + "_").toString()}
                                                                        url={base64}
                                                                    />

                                                                    <LATextField
                                                                        className="mt-2"
                                                                        label="Description"
                                                                        fullWidth={true}
                                                                        variant="outlined"
                                                                        name="description"
                                                                        disabled={props.disabled}
                                                                        value={q.description}
                                                                        onChange={onImageName}
                                                                        errorText={
                                                                            q.name.length === 0 ?
                                                                                FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                                : undefined
                                                                        }
                                                                    />

                                                                    <LAIconButton
                                                                        key={ind}
                                                                        label="Delete"
                                                                        onClick={onImgDelete}
                                                                        icon={<DeleteIcon />}
                                                                    />
                                                                </LAPaperWithLessPadding>
                                                            </LAGridItem>
                                                        );

                                                    })}
                                            </LAGrid>

                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            {/* <LAGreyPaper className="text-center">
                                                <strong>PARTS</strong>
                                                <hr />

                                                {x.parts.map((e, ind) => {

                                                    return (
                                                        <LAGrid key={ind}>

                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <strong>Part Name: </strong>
                                                                <span>{e.part_Name}</span>
                                                            </LAGridItem>

                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <strong>Part Number: </strong>
                                                                <span>{e.part_Number}</span>
                                                            </LAGridItem>

                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <strong>Driver: </strong>
                                                                <span>{e.driver}</span>
                                                            </LAGridItem>

                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <strong>Status: </strong>
                                                                <span>{e.status && PartStatus ? PartStatus.find(q => q.name === e.status)?.name : ""}</span>
                                                            </LAGridItem>

                                                        </LAGrid>
                                                    )
                                                })}
                                            </LAGreyPaper> */}

                                            <LAPaperWithLessPadding className="text-center mt-2">
                                                <strong>LOGS</strong>

                                                {(props.logLoading) ?
                                                    <LALoading message="Updating your logs... Please wait" />
                                                    : <React.Fragment>
                                                        <LAIconButton
                                                            label="Add Log"
                                                            icon={<AddIcon />}
                                                            disabled={props.disabled}
                                                            onClick={() => props.onAddLog(x.id)}
                                                        />

                                                        <hr />
                                                        {props.logs.map((e, ind) => {
                                                            const onLogSave = (): void => props.onLogSave(ind);
                                                            const onLogUpdate = (): void => props.onLogUpdate(ind);
                                                            const onDate = (date: string): void => props.onLogChange("date", new Date(date).toDateString(), ind);
                                                            const onLogChange = (name: string, value: string) => props.onLogChange(name, value, ind);
                                                            const onHourChange = (name: string, value: string) => {
                                                                if (numberAndDecimalCheck(value))
                                                                    props.onLogChange(name, value, ind);

                                                            };
                                                            const disable = (props.disabled) ? true : (props.addUpdateLog !== undefined) ? (props.addUpdateLog === ind) ? undefined : true : true;

                                                            if (e.component_ID === x.id) {
                                                                return (
                                                                    <LAGrid key={ind}>

                                                                        <LAGridItem xs={12} sm={3} md={2}>
                                                                            {disable ? <LAIconButton
                                                                                label="Edit"
                                                                                icon={<EditIcon />}
                                                                                onClick={onLogUpdate}
                                                                                disabled={props.disabled}
                                                                            /> : <LAIconButton
                                                                                label="Save"
                                                                                onClick={onLogSave}
                                                                                icon={<SaveIcon color={GREEN_COLOR} />}
                                                                            />}
                                                                        </LAGridItem>

                                                                        <LAGridItem xs={12} sm={6} md={3} className="text-center mt-3">
                                                                            <LADatePicker
                                                                                id="date"
                                                                                mode="single"
                                                                                value={e.date}
                                                                                onChange={onDate}
                                                                                disable={disable}
                                                                            />
                                                                        </LAGridItem>

                                                                        <LAGridItem xs={12} sm={6} md={2}>
                                                                            <LATextArea
                                                                                rowsMax={1}
                                                                                name="hours"
                                                                                label="Hours"
                                                                                fullWidth={true}
                                                                                disabled={disable}
                                                                                value={e.hours ?? ""}
                                                                                onChange={onHourChange}
                                                                            />

                                                                            {((props.logs.length - 1) === (ind)) && <>
                                                                                <br />
                                                                                <strong>Total Hours:</strong>
                                                                                <span>{getTotalHours(props.logs, e.component_ID)}</span>
                                                                            </>}
                                                                        </LAGridItem>

                                                                        <LAGridItem xs={12} sm={9} md={2}>
                                                                            <LATextArea
                                                                                rowsMax={2}
                                                                                name="comments"
                                                                                label="Comments"
                                                                                fullWidth={true}
                                                                                value={e.comments}
                                                                                disabled={disable}
                                                                                onChange={onLogChange}
                                                                            />
                                                                        </LAGridItem>

                                                                        <LAGridItem xs={12}>
                                                                            <hr />
                                                                        </LAGridItem>

                                                                    </LAGrid>
                                                                )
                                                            } else {
                                                                return <></>
                                                            }
                                                        })}
                                                    </React.Fragment>}
                                            </LAPaperWithLessPadding>

                                            <hr className="mb-4" />
                                        </LAGridItem>


                                        <LAGridItem xs={12}>
                                            <LASaveAndCancelButton
                                                cancelButtonText="Close"
                                                onSave={props.onPopupSave}
                                                onCancel={props.handleClose}
                                                saveButtonText="Save Component"
                                                disableSave={(props.addUpdateLog !== undefined) ? true : (Object.values(props.subTableErrors).length > 0) ? true : undefined}
                                            />
                                        </LAGridItem>

                                    </LAGrid>
                                </LAGridItem>
                            )
                        }
                    })}
                </LAGrid>
            </LAPaperWithLessPadding>
        </MechanicRepairLineComponentStyles>
    );
};

const getTotalHours = (data: IMechanicLogById[], componentId: number): number => {
    const hours = data.filter(x => x.component_ID === componentId).map(({ hours }) => hours ? Number(hours) : 0);
    return hours.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
};