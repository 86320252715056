import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST, IGetUnitInspectionFormByID } from "./getUnitInspectionFormByIDConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetUnitInspectionFormByIDLoadAction, IGetUnitInspectionFormByIDLoadFailedAction, IGetUnitInspectionFormByIDSuccessAction, 
    getUnitInspectionFormByIDLoadFailedAction, getUnitInspectionFormByIDLoadSuccessAction } from "./getUnitInspectionFormByIDActions";

export const getUnitInspectionFormByIDEpic: Epic = (
    action$: ActionsObservable<IGetUnitInspectionFormByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitInspectionFormByIDSuccessAction | IGetUnitInspectionFormByIDLoadFailedAction> =>
    action$.ofType(IGET_UNIT_INSPECTION_FORM_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetUnitInspectionFormByID>>(
                    END_POINTS.FIELD.INSPECTION_FORM.GET_UNIT_INSPECTION_FORM_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetUnitInspectionFormByID>): IGetUnitInspectionFormByIDSuccessAction => {
                            return getUnitInspectionFormByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitInspectionFormByIDLoadFailedAction(response.message)))
                    )
            )
        );