import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_DRIVER_REQUEST, IDriver, IDriverRequest } from "./getDriversConstants";

export interface IGetDriverLoadAction {
    type: IGET_DRIVER_REQUEST.REQUEST;
    data: IDriverRequest
}
export const getDriverLoadAction = (data: IDriverRequest): IGetDriverLoadAction => ({
    type: IGET_DRIVER_REQUEST.REQUEST,
    data
});
export interface IGetDriverSuccessAction {
    type: IGET_DRIVER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IDriver>>;
}
export const getDriverLoadSuccessAction = (list: SurewayAPIResponse<ById<IDriver>>): IGetDriverSuccessAction => ({
    type: IGET_DRIVER_REQUEST.SUCCESS,
    list
});
export interface IGetDriverLoadFailedAction {
    type: IGET_DRIVER_REQUEST.FAILED;
    message: string;
}
export const getDriverLoadFailedAction = (message: string): IGetDriverLoadFailedAction => ({
    type: IGET_DRIVER_REQUEST.FAILED,
    message
});
