import React from 'react';
  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import {deleteTruckingRequestEmailByID, getTruckingRequestEmails} from './api/equipmenttracker/equipmentTrackerAPI';
import SOCTHome from './equipmenttrackerhome';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditTruckingRequestEmails from './forms/truckingrequestemails/truckingRequestEmailsEdit';
import AddNewTruckingRequestEmail from './forms/truckingrequestemails/truckingRequestEmailsAdd';
import TruckingRequestEmailsDeleteConfirmation from './forms/truckingrequestemails/truckingRequestEmailsDeleteConfirm';
import { LADevExtremeGrid } from '../../shared/devExtreme';

interface IState {
    emailList:any;
  open:boolean;
  showEditForm:boolean;
  view:string;
  showAddNewForm:boolean;
  showDeleteConfirmation:boolean;
}
interface IProps extends RouteComponentProps{
  history:any
}

var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];


var globalEditID:Number = 0;
var renderPageType:string = '';
var delete_record_id:Number = 0;

export default class TruckingRequestEmailsPage extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={emailList:[], open:false, showEditForm:false, view:'all', showAddNewForm:false, showDeleteConfirmation:false};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.filterView = this.filterView.bind(this);
    this.addNewEquipmentMove = this.addNewEquipmentMove.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
  }
  deleteItem= (name: string, column: any): void => {      
    delete_record_id = column.row.data.id;
    this.setState({showDeleteConfirmation:true});
  }
  async confirmDelete(){
    if(delete_record_id != 0){
      var res:any = await deleteTruckingRequestEmailByID(delete_record_id);
      if(res.status === 200){
        alert("Successfully deleted email");
        this.setState({showDeleteConfirmation:false});
        this.refreshGrid();
      }
      else{
        alert("There was an error when deleting this record. Refresh the page and try again");
      }
    }
    else{
        alert("There was an error when deleting this record. Refresh the page and try again");
    }
  }
  cancelDelete(){
      this.setState({showDeleteConfirmation:false});
  }
  addNewEquipmentMove(){
    this.setState({showAddNewForm:true});
  }
  filterView(eventKey:any){
    switch(eventKey.target.value) {
      case 'active':
          this.setState({view:'active'});
          var filtered_list = copy_list.filter((elem:any) => { return (elem.active === "Yes")});
          this.setState({emailList:filtered_list});
          break;
      case 'inactive':
        this.setState({view:'inactive'});
        var filtered_list = copy_list.filter((elem:any) => { return (elem.active === "No")});
        this.setState({emailList:filtered_list});
        break;
      case 'all':
        this.setState({view:'all'});
        var filtered_list = copy_list.filter((elem:any) => { return (elem)});
        this.setState({emailList:filtered_list});
        break;
    }
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  closeEditForm(){
      this.setState({showEditForm:false, showAddNewForm:false});
      this.props.history.push('/field/equipmenttracker/emails');
      this.refreshGrid();
  }
  editItem = (e:any):void => {
    
    renderPageType='';
    editData = e.row.data;
    globalEditID = e.row.data.id;
    this.props.history.push('/field/equipmenttracker/emails/edit/id='+e.row.data.id);
    this.setState({showEditForm:true});
  }
  async refreshGrid(){
    if(localStorage.getItem("ET-Role")==="R"){
      this.props.history.push("/field/equipmenttracker/trucking");
    }
    var email_list = await getTruckingRequestEmails();
    copy_list = email_list;
    this.setState({emailList:email_list});
  }
  async componentDidMount(){
    this.refreshGrid();
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
              <MUIButton onClick={this.addNewEquipmentMove}  style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
            <Typography className='dataGrid-title'>Trucking Request Emails</Typography>
            <LADevExtremeGrid
                                               onClick={(e:any)=>{}}
                                                onEdit={this.editItem}
                                                data={this.state.emailList}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={100}
                                                removeStyleBtn={true}
                                                height={1200}
                                                paging={100}
                                                customRowColor={true}
                                                exportFileName="Trucking_Request_Email_List" 
                                                onDeleteTruckingRequestEmail={this.deleteItem}                                 
                                                columns={[    
                                                  
                                                  { name: "email", caption: "Email", type: "string"},       
                                                  { name: "requested_Email", caption: "Requested Email", type: "string"},             
                                                  {name:"delete_trucking_request_email_button", caption:'Delete', type: "delete_trucking_request_email_button"},                                             
                                                ]}
                                                />
            {this.state.showEditForm ? <EditTruckingRequestEmails closeEquipmentMoveForm={this.closeEditForm} refreshGrid={this.refreshGrid}></EditTruckingRequestEmails>:null}
            {this.state.showAddNewForm ? <AddNewTruckingRequestEmail refreshGrid={this.refreshGrid.bind(this)}  closeEquipmentMoveForm={this.closeEditForm}></AddNewTruckingRequestEmail>:null}
           {this.state.showDeleteConfirmation ? <TruckingRequestEmailsDeleteConfirmation closePopup={this.cancelDelete} confirmPopup={this.confirmDelete}></TruckingRequestEmailsDeleteConfirmation>:null}
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, globalEditID, unitNumbers, renderPageType, copy_list};