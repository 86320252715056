import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_JOURNEL_REQUEST, IAddJournelRequest } from "./addJournelConstants";


export interface IAddJournelLoadAction {
    type: IADD_JOURNEL_REQUEST.REQUEST;
    data: IAddJournelRequest
}
export const addJournelLoadAction = (data: IAddJournelRequest): IAddJournelLoadAction => ({
    type: IADD_JOURNEL_REQUEST.REQUEST,
    data
});
export interface IAddJournelSuccessAction {
    type: IADD_JOURNEL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addJournelLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddJournelSuccessAction => ({
    type: IADD_JOURNEL_REQUEST.SUCCESS,
    message
});
export interface IAddJournelLoadFailedAction {
    type: IADD_JOURNEL_REQUEST.FAILED;
    message: string;
}
export const addJournelLoadFailedAction = (message: string): IAddJournelLoadFailedAction => ({
    type: IADD_JOURNEL_REQUEST.FAILED,
    message
});
