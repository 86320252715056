import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, failed, loading, notLoaded, succeeded } from "../../../server";
import { IGetSoctReportLoadAction, IGetSoctReportLoadFailedAction, IGetSoctReportSuccessAction } from "./getSoctReportActions";
import { IGET_SOCT_REPORT_REQUEST, ISoctReport } from "./getSoctReportConstants";

type Actions =
    | IGetSoctReportLoadAction
    | IGetSoctReportSuccessAction
    | IGetSoctReportLoadFailedAction
    | IFlushDataSuccessAction;

export const GetSoctReportReducer = (state: Server<SurewayAPIResponse<ById<ISoctReport>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ISoctReport>>> => {
    switch (action.type) {
        case IGET_SOCT_REPORT_REQUEST.REQUEST:
            return loading;

        case IGET_SOCT_REPORT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SOCT_REPORT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};