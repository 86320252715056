import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_WORKING_SITE_LOGS_REQUEST, IWorkingSiteLog } from "./getWorkingSiteLogsConstants";
import { IGetWorkingSiteLogsLoadAction, IGetWorkingSiteLogsLoadFailedAction, IGetWorkingSiteLogsSuccessAction } from "./getWorkingSiteLogsActions";
import { IUpdateWorkingSiteLogSuccessAction } from "../updateWorkingSiteLog/updateWorkingSiteLogActions";
import { IAddWorkingSiteLogSuccessAction } from "../addWorkingSiteLog/addWorkingSiteLogActions";
import { IUPDATE_WORKING_SITE_LOG_REQUEST } from "../updateWorkingSiteLog/updateWorkingSiteLogConstants";
import { IADD_WORKING_SITE_LOG_REQUEST } from "../addWorkingSiteLog/addWorkingSiteLogConstants";

type Actions =
    | IGetWorkingSiteLogsLoadAction
    | IGetWorkingSiteLogsSuccessAction
    | IGetWorkingSiteLogsLoadFailedAction
    | IUpdateWorkingSiteLogSuccessAction
    | IAddWorkingSiteLogSuccessAction
    | IFlushDataSuccessAction;

export const GetWorkingSiteLogsReducer = (state: Server<SurewayAPIResponse<ById<IWorkingSiteLog>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IWorkingSiteLog>>> => {
    switch (action.type) {
        case IGET_WORKING_SITE_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_WORKING_SITE_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WORKING_SITE_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};