import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORMS_REQUEST, IChecklistForm, IFormsRequest } from "./getFormsConstants";

export interface IGetFormsLoadAction {
    type: IGET_FORMS_REQUEST.REQUEST;
    data: IFormsRequest
}
export const getFormsLoadAction = (data: IFormsRequest): IGetFormsLoadAction => ({
    type: IGET_FORMS_REQUEST.REQUEST,
    data
});
export interface IGetFormsSuccessAction {
    type: IGET_FORMS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IChecklistForm[]>;
}
export const getFormsLoadSuccessAction = (list: SurewayAPIResponse<IChecklistForm[]>): IGetFormsSuccessAction => ({
    type: IGET_FORMS_REQUEST.SUCCESS,
    list
});
export interface IGetFormsLoadFailedAction {
    type: IGET_FORMS_REQUEST.FAILED;
    message: string;
}
export const getFormsLoadFailedAction = (message: string): IGetFormsLoadFailedAction => ({
    type: IGET_FORMS_REQUEST.FAILED,
    message
});
