import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST } from "./getTechServicesByUnitIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetTechServicesByUnitIDLoadAction, IGetTechServicesByUnitIDLoadFailedAction, IGetTechServicesByUnitIDSuccessAction, getTechServicesByUnitIDLoadFailedAction, getTechServicesByUnitIDLoadSuccessAction } from "./getTechServicesByUnitIDActions";
import { ITechService } from "../getTechServices/getTechServicesConstants";

export const getTechServicesByUnitIDEpic: Epic = (
    action$: ActionsObservable<IGetTechServicesByUnitIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetTechServicesByUnitIDSuccessAction | IGetTechServicesByUnitIDLoadFailedAction> =>
    action$.ofType(IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ITechService[]>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_TECH_SERVICES_BY_UNIT_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ITechService[]>): IGetTechServicesByUnitIDSuccessAction => {
                            return getTechServicesByUnitIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getTechServicesByUnitIDLoadFailedAction(response.message)))
                    )
            )
        );