import {currentUser, currentETUser} from '../../equipmenttrackerhome';
import { currentETEditUser } from '../../forms/equipmenttracker/equipmentMoveEdit';
import { currentCreateETUser } from '../../forms/equipmenttracker/equipmentMoveRequest';
var url:string = "https://api.sureway.ca";
//var url:string = "http://localhost:51380";

async function getCurrentSiteForeman(request_object: any){
  let currentForeman:any = '';
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', "request":{"subsiteid":request_object}})
  }
  await fetch(url+'/api/Sureway/Field/ET/GetCurrentSiteForeman', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
      currentForeman = data.response;
  });
  return currentForeman;
}

async function getForemans(){
  let foremans:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', "request":{"page":"Move_Requests"}})
}
  await fetch(url+'/api/Sureway/Field/ET/GetETLookups', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }

      // no error
      data.response.foremanList.map((history:any) => {
        foremans.push(history);
      });
  });
  return foremans;
}

async function getLocations(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetLocations', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  async function getActiveUsers(){
    let user_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetActiveUsers', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((rec:any) => {
          user_list.push(rec);
        });
    });
    return user_list;
  }
  async function getEquipmentList(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetNonMovedEquipmentList', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

   async function getEquipmentMappingByLocationName(request_object:any){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Location:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/GetEquipmentIDsByLocation', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  async function moveSingleEquipment(request_object:any){
    console.log(currentETUser, currentCreateETUser);
    let response_status:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{From_Location:request_object.from_location,
                    To_Location:request_object.to_location,
                    Moved_Item:request_object.moved_item,
                Completed_Date:request_object.completed_date,
                Requested_By: request_object.requested_By,
                Comments:request_object.comments,
                Send_Email:request_object.send_email===true ? "Yes":"No",
            Modified_By:currentCreateETUser!="" && currentCreateETUser!=null ? currentCreateETUser:currentETUser,
          From_Sub_Site_Name:request_object.from_Sub_Site_Name,
          To_Sub_Site_Name:request_object.to_Sub_Site_Name,
          PickUp_Contact:request_object.pickup_contact_upn,
          DropOff_Contact:request_object.dropoff_contact_upn,
          Attachments: request_object.attachment_list,
        Completed:request_object.completed===true?"Yes":"No",
      Drive_Over:request_object.drive_over===true?"Yes":"No",
      Moved_Previously:request_object.moved_previously===true?"Yes":"No",
    Is_From_App:request_object.is_from_app===true?"Yes":"No"},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/MoveSingleEquipment', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  async function getMoveRequests(view:any){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{View:view},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetEquipmentMoveRequests', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function updateMoveRequest(request_object:any, pilot_array:any[]){
    console.log('updating')
    let response_status:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
                    ID:request_object.id,
                    Unit_Number:request_object.unit_Number,
                    From_Location:request_object.from_location,
                    To_Location:request_object.to_location,
                    Completed_Date:request_object.completed_date,
                    Modified_Notes:request_object.modified_notes,
                    Pilot:request_object.pilot,
                    Status: request_object.status,
                    Rejection_Comments: request_object.reject_Comments,
                    Tri_Drive:request_object.tri_drive===true?"Yes":"No",
                    Hours:request_object.hours,
                    City_Permit:request_object.city_permit,
                    City_Permit_Cost:request_object.city_permit_cost,
                    Gov_Permit:request_object.gov_permit,
                    Gov_Permit_Cost:request_object.gov_permit_cost,
                    Completed:request_object.completed===true?"Yes":"No",
                    F_A:request_object.f_a,
                    Commodity:request_object.commodity,
                    Cancelled:request_object.cancelled===true?"Yes":"No",
                    Driver_ID:request_object.driver_id,
                    Trailer_ID:request_object.trailer_id,
                    Trailer_Config_ID:request_object.trailer_config_id,
                    PickUp_Contact:request_object.pickup_contact_upn,
                    DropOff_Contact:request_object.dropoff_contact_upn,
                    Attachments:request_object.attachment_list,
                    Equipment_ID:request_object.equipment_ID,
                    Send_Email:request_object.send_email===true?"Yes":"No",
                    Modified_By:currentETEditUser,
                    Drive_Over:request_object.drive_over===true?"Yes":"No",
                    Assigned_Tractor_ID:request_object.assigned_tractor_id,
                    To_Location_ID:request_object.to_location_id,
                    To_Sub_Location:request_object.to_sub_location,
                    Comments:request_object.comments,
                    Is_From_App:request_object.is_from_app===true?"Yes":"No",
                    Pilot_Array:pilot_array,
                    Permit:request_object.permit===true?"Yes":"No"
                  },
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'
        })
  }
     await fetch(url+'/api/EquipmentTracker/UpdateMoveRequest', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  
  async function getEquipmentMoveStatusByUnit(request_object:any){
    let status:any = "";
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/GetEquipmentMoveStatusByUnit', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
       
        //no error
        if(data != null){
          status = data.status;
        }
        else {
          status = "NA";
        }
    })
    return status;
  }

  
  async function getUnitNumberByEquipmentID(id:any){
    let unit_num:any = "";
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{ID:id},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/GetUnitNumberByEquipmentID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        //no error
        if(data != null){
          unit_num = data.unit_Number;
        }
        else {
          unit_num = "NA";
        }
    })
    return unit_num;
  }

  async function getDrivers(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetDrivers', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });    
    return master_temp_list;
  }
  
  async function addDriver(driver_object:any){
    let active:string = "";
    let responseStatus:any = null;
    if(driver_object.active){
      active = "Yes";
    }
    else{
      active = "No";
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        request:{
          Driver_Firstname:driver_object.firstname,
          Driver_Lastname:driver_object.lastname, 
          Active:active,
          Email:driver_object.email,
          Assigned_Unit_Number:driver_object.truck_number,
          Notes:driver_object.notes,
          Created_By:currentETUser,
          Modified_By:currentETUser,
          Pilot_Driver:driver_object.pilot_driver===true?"Yes":"No",
          Phone_Number:driver_object.phone_number,
          License: driver_object.license
      },token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/AddDriver', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        // no error
        responseStatus = response;
    });
    return responseStatus;
  }
  
  async function updateDriver(request_object:any){
    let response_status:any = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
            ID:request_object.id,
            Driver_Firstname:request_object.firstname,
            Driver_Lastname:request_object.lastname,
            Email:request_object.email,
            Active:request_object.active===true?"Yes":"No",
            Notes:request_object.notes,
            Assigned_Unit_Number:request_object.truck_number,
            Modified_By:currentETUser,
            Pilot_Driver:request_object.pilot_driver===true?"Yes":"No",
          Phone_Number:request_object.phone_number,
           License: request_object.license},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/UpdateDriver', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  async function getTrailers(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetTrailers', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function addTrailer(trailer:any){
    let responseStatus:any = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        request:{
          Trailer_Rate_ID:trailer.trailer_rate_id,
          Unit_Number:trailer.unit_number, 
          Active:trailer.active ? "Yes":"No",
          License_Number:trailer.license_number,
          Serial_Number:trailer.serial_number,
          Year:trailer.year,
          Last_Inspection_Date:trailer.last_inspection_date,
          Last_Service_Date:trailer.last_service_date,
          Created_By:currentETUser,
          Modified_By:currentETUser
      },token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/AddTrailer', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        // no error
        responseStatus = response;
    });
    return responseStatus;
  }

  
  async function updateTrailer(trailer:any){
    let active:string = "";
    let responseStatus:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        request:{
          ID:trailer.id,
          Unit_Number:trailer.unit_number, 
          Active:trailer.active ? "Yes":"No",
          License_Number:trailer.license_number,
          Serial_Number:trailer.serial_number,
          Year:trailer.year,
          Last_Inspection_Date:trailer.last_inspection_date,
          Last_Service_Date:trailer.last_service_date,
          Created_By:currentETUser,
          Modified_By:currentETUser
      },token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/UpdateTrailer', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        // no error
        responseStatus = response;
    });
    return responseStatus;
  }

  async function getLocationMappingInfo(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetLocationMappingInfo', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  async function getEquipmentListInfo(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetEquipmentListInfo', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function getLocationByID(request_object:any){
    let result:any;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/getLocationByID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        result = data;
    });
    return result;
  }

  async function getTrailerRates(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetTrailerRates', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function getTruckingRequestEmails(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetTruckingRequestEmails', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  async function addTrailerRate(trailer:any){
    let responseStatus:any = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        request:{
          Description:trailer.description, 
          Rate:trailer.rate,
          ARA_Rate:trailer.ara_rate,
          Created_By:currentETUser,
          Modified_By:currentETUser
      },token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/AddTrailerRate', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        // no error
        responseStatus = response;
    });
    return responseStatus;
  }

  
  async function addTruckingRequestEmail(request:any){
    let responseStatus:any = null;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        request:{
          Email:request.email,
          Created_By:currentETUser,
          Modified_By:currentETUser,
          Requested_Email:request.requested_email===true?"Yes":"No"
      },token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/AddTruckingRequestEmail', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json');
          // error response
          if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        // no error
        responseStatus = response;
    });
    return responseStatus;
  }

  
  async function updateTrailerRate(request_object:any){
    let response_status:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
            ID:request_object.id,
            Description:request_object.description,
            Rate:request_object.rate,
            ARA_Rate:request_object.ara_rate,
            Modified_By:currentETUser},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/UpdateTrailerRate', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  
  async function updateTruckingRequestEmail(request_object:any){
    let response_status:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
            ID:request_object.id,
            Email:request_object.email,
            Modified_By:currentETUser,
            Requested_Email:request_object.requested_email===true?"Yes":"No"},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/UpdateTruckingRequestEmail', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  
  async function moveCustomEquipment(request_object:any){
    let response_status:any = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
            Unit_Number:request_object.unit_number,
            Requested_By:request_object.requested_By,
            From_Location:request_object.from_location,
                    To_Location:request_object.to_location,
                    Moved_Item:request_object.moved_item,
                Completed_Date:request_object.completed_date,
                Comments:request_object.comments,
                Send_Email:request_object.send_email===true ? "Yes":"No",
                From_Sub_Location:request_object.from_sub_location,
                To_Sub_Location:request_object.to_sub_location,
            Modified_By:currentETUser,
          Completed:request_object.completed===true?"Yes":"No",
          Drive_Over:request_object.drive_over===true?"Yes":"No",
          Moved_Previously:request_object.moved_previously===true?"Yes":"No",
          Driver_ID:request_object.driver_id,
          PickUp_Contact:request_object.pickup_contact_upn,
          DropOff_Contact:request_object.dropoff_contact_upn,
          Attachments: request_object.attachment_list,
          Trailer_ID:request_object.trailer_id,
          Trailer_Config_ID:request_object.trailer_config_id,
          Hours:request_object.hours,
          Assigned_Tractor_ID:request_object.assigned_tractor_id,
        Is_From_App:request_object.is_from_app===true?"Yes":"No"},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/MoveCustomEquipment', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  
  async function filterLocationMapping(request_object:any, search_string:string){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Location_Mapping_List:request_object, Search_String:search_string},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/FilterLocationMapping', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function filterUnitMapping(request_object:any, search_string:string){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Location_Mapping_List:request_object, Search_String:search_string},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/FilterUnitMapping', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  async function getAttachmentListByUnitID(request_object:any){
    let result:any;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Unit_ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/Sureway/Field/ET/GetAttachmentListByUnitID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        result = data.response;
    });
    return result;
  }

  async function getAttachmentList(){
    let result:any;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/Sureway/Field/GetAttachmentList', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        result = data.response;
    });
    return result;
  }

  async function removeAttachmentFromUnit(requst_Objet:any){
    let result:any;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request: { id: requst_Objet }})
  }
     await fetch(url+'/api/Sureway/Field/RemoveAttachmentFromUnit', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        result = data;
    });
    return result;
  }

  async function getEquipmentListUnitByID(request_object:any){
    let result:any;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Equipment_ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/GetEquipmentListUnitByID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        result = data;
    });
    return result;
  }

  
  async function getBillingReports(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetBillingReports', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function getTractors(){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
    await fetch(url+'/api/EquipmentTracker/GetTractors', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function filterMakeModelMapping(request_object:any, search_string:string){
    let master_temp_list:any = [];
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({request:{Location_Mapping_List:request_object, Search_String:search_string},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/FilterMakeModelMapping', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        data.map((history:any) => {
          master_temp_list.push(history);
        });
    });
    return master_temp_list;
  }

  
  async function deleteTruckingRequestEmailByID(id:any){
    let response_status:any = null;
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
          request:{
            ID:id},
          token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
  }
     await fetch(url+'/api/EquipmentTracker/DeleteTruckingRequestEmailByID', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
          // error response
          if(!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        // no error
        response_status = response;
    });
    return response_status;
  }

  
async function getETUserRole(currentUser:string){
  var role = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{username:currentUser},token: 'ac54889e-5ccb-4898-9963-81bb41b47651' })
}
await fetch(url+'/api/SOCT/GetETUserGroup', requestOptions)
.then(async response => {
    const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
      // error response
      if(!response.ok) {
        const error = (data) || response.status;
        return Promise.reject(error);
    }
        role = data;
    });
    return role;
}

async function completeMoveRequest(move_id:any, email:boolean){
  let response_status:any = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
        request:{
                  ID:move_id,
                  Send_Email:email===true?"Yes":"No",
                Modified_By:currentETEditUser != null && currentETEditUser!=""?currentETEditUser:currentETUser},
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/CompleteMoveRequest', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      response_status = response;
  });
  return response_status;
}

async function getEquipmentMoveRequestByID(request_object:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/GetEquipmentMoveRequestByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data;
  });
  return result;
}


async function getMoveRequestIDByEquipmentID(request_object:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Equipment_ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/GetMoveRequestIDByEquipmentID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data;
  });
  return result;
}

async function getMoveRequestVersionHistory(request_object:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/GetMoveRequestVersionHistory', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data.response;
  });
  return result;
}


async function getMappingView(){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(url+'/api/EquipmentTracker/GetMappingView', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      master_temp_list = data;
  });
  return master_temp_list;
}


async function getSOCTUnitByEquipmentID(request_object:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Equipment_ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/GetSOCTUnitByEquipmentID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data;
  });
  return result;
}


async function requestEquipmentMove(request_object:any){
  console.log(currentETUser)
  let response_status:any = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
        request:{From_Location:request_object.from_location,
                  To_Location:request_object.to_location,
                  Requested_By:currentCreateETUser!=null && currentCreateETUser!=""?currentCreateETUser:currentETUser,
                  Moved_Item:request_object.moved_item,
              Completed_Date:request_object.completed_date,
              Comments:request_object.comments,
              Send_Email:request_object.send_email===true ? "Yes":"No",
          Modified_By:currentCreateETUser!=null && currentCreateETUser!=""?currentCreateETUser:currentETUser,
        From_Sub_Site_Name:request_object.from_Sub_Site_Name,
        To_Sub_Site_Name:request_object.to_Sub_Site_Name,
        PickUp_Contact:request_object.pickup_contact_upn,
        DropOff_Contact:request_object.dropoff_contact_upn,
        Attachments: request_object.attachment_list,
      Completed:request_object.completed===true?"Yes":"No",
    Drive_Over:request_object.drive_over===true?"Yes":"No",
    Moved_Previously:request_object.moved_previously===true?"Yes":"No"},
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/RequestEquipmentMove', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      response_status = response;
  });
  return response_status;
}


async function rejectMoveRequest(move_request_id:any, email:boolean, comments:string){
  let response_status:any = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
        request:{
                  ID:move_request_id,
                  Send_Email:email===true?"Yes":"No",
              Modified_By:currentETEditUser != null && currentETEditUser!=""?currentETEditUser:currentETUser,
            Rejection_Comments:comments},
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/RejectMoveRequest', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      response_status = response;
  });
  return response_status;
}


async function approveMoveRequest(move_id:any, email:boolean){
  let response_status:any = null;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
        request:{
                  ID:move_id,
                  Send_Email:email===true?"Yes":"No",
                Modified_By:currentETEditUser != null && currentETEditUser!=""?currentETEditUser:currentETUser},
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/ApproveMoveRequest', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      response_status = response;
  });
  return response_status;
}

async function getPilots(){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(url+'/api/EquipmentTracker/GetPilots', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     master_temp_list = data;
  });
  return master_temp_list;
}

async function getPilotsByMoveID(request_object:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:request_object},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/GetPilotsByMoveID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data;
  });
  return result;
}

async function getETUserToken(user_token:string){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{user_token:user_token}})
}
  await fetch(url+'/api/Sureway/GetUserToken', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
     master_temp_list = data;
  });
  return master_temp_list;
}


async function compareEditModifiedTimestamp(move_id:number, modified:any){
  let result:any;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:move_id, Modified:modified},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
   await fetch(url+'/api/EquipmentTracker/compareEditModifiedTimestamp', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      result = data;
  });
  return result;
}

async function validateDriverToken(token_from_url:string){
  var isTokenValid = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Token:token_from_url},token: 'ac54889e-5ccb-4898-9963-81bb41b47651' })
}
await fetch(url+'/api/EquipmentTracker/ValidateDriverToken', requestOptions)
.then(async response => {
    const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
      // error response
      if(!response.ok) {
        const error = (data) || response.status;
        return Promise.reject(error);
    }
        isTokenValid = data.response;
    });
    return isTokenValid;
}

  export {getLocations, 
    getActiveUsers,
    getEquipmentList, 
    getEquipmentMappingByLocationName, 
    moveSingleEquipment, 
    getMoveRequests, 
    updateMoveRequest,
    getEquipmentMoveStatusByUnit, 
    getUnitNumberByEquipmentID,
    getDrivers,
    addDriver,
    updateDriver,
    getTrailers,
    addTrailer,
    updateTrailer,
    getLocationMappingInfo,
    getEquipmentListInfo,
    getLocationByID,
    getTrailerRates,
    getTruckingRequestEmails,
    addTrailerRate,
    addTruckingRequestEmail,
    updateTrailerRate,
    updateTruckingRequestEmail,
    moveCustomEquipment,
    filterLocationMapping,
    filterUnitMapping,
    getEquipmentListUnitByID,
    getAttachmentListByUnitID,
    getAttachmentList,
    removeAttachmentFromUnit,
    getBillingReports,
    getTractors,
    filterMakeModelMapping,
    deleteTruckingRequestEmailByID,
    getETUserRole,
    completeMoveRequest,
    getEquipmentMoveRequestByID,
    getMoveRequestIDByEquipmentID,
    getMoveRequestVersionHistory,
    getMappingView,
    getSOCTUnitByEquipmentID,
    requestEquipmentMove,
    rejectMoveRequest,
    approveMoveRequest,
    getPilots,
    getPilotsByMoveID,
    getETUserToken,
    compareEditModifiedTimestamp,
    validateDriverToken,
    getForemans,
    getCurrentSiteForeman
  };