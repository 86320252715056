
export enum IADD_REPAIR_LINE_WO_UNIT_REQUEST {
    REQUEST = "addRepairLineWOUnit/ADD_REPAIR_LINE_WO_UNIT_REQUEST",
    SUCCESS = "addRepairLineWOUnit/ADD_REPAIR_LINE_WO_UNIT_SUCCESS",
    FAILED = "addRepairLineWOUnit/ADD_REPAIR_LINE_WO_UNIT_FAILED"
};

export interface IAddRepairLineWOUnitRequest {
    token: string;
    request: IAddUpdateRepairLineWOUnit;
};

export interface IAddUpdateRepairLineWOUnit {
    ID?: number; 
    Unit_ID: number;
    Work_Order_No: string; 
    Created_By?: string; // only for Add
    Modified_By?: string; // only for Update
}

