import { Grid, Button, Box, Modal, Backdrop, Typography, TextField, FormLabel, FormGroup, Checkbox, FormControlLabel} from "@mui/material";
import React from 'react';
import {updateTruckingRequestEmail} from '../../api/equipmenttracker/equipmentTrackerAPI';
import {editData} from '../../truckingRequestEmailsPage';
import {MobileBoxStyle} from '../../../../shared/styles';

interface IProps{
    closeEquipmentMoveForm:any;
    refreshGrid:any;
}
interface IState{
    location_options:any[];
}

const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }
  var edit_trucking_request_email:any = {id:0,email:'',requested_email:false};
var disableForm:boolean = false;
var temp_drivers:any[] = [];
export default class EditTruckingRequestEmails extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state={location_options:[]};
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    closePopup(){
        this.props.closeEquipmentMoveForm();
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'email':
              edit_trucking_request_email.email = event.target.value;
                break;
            case 'requested_email':
              edit_trucking_request_email.requested_email = !edit_trucking_request_email.requested_email;
              break;
        }
        this.setState({});
    }
    async onSave(){
        if(edit_trucking_request_email!=null && edit_trucking_request_email!=''){
          var res:any = await updateTruckingRequestEmail(edit_trucking_request_email);
          if(res.status == 200){
              this.props.closeEquipmentMoveForm();
          }
          else{
            alert("Error updating move request");
          }
        }
        else{
          alert("Email cannot be empty");
        }
    }
    async componentDidMount(){
      console.log(editData);
      edit_trucking_request_email.id = editData.id;
      edit_trucking_request_email.email = editData.email;
      edit_trucking_request_email.requested_email = editData.requested_Email;
      this.setState({});
    }
    render(){
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <= 760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Updating Trucking Request Email</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField label="Email" onChange={this.handleChange} name="email" defaultValue={edit_trucking_request_email.email}></TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <FormLabel component="legend">Requested Email list</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={edit_trucking_request_email.requested_email} onChange={this.handleChange} name="requested_email"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSave}>Save</Button>
                      </Grid>
                      </Grid></Box></Modal>
        );
    }
}