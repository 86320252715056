import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_HAZARD_TYPES_REQUEST, IGetHazardTypes, IGetHazardTypesRequest } from "./getHazardTypesConstants";

export interface IGetHazardTypesLoadAction {
    type: IGET_HAZARD_TYPES_REQUEST.REQUEST;
    data: IGetHazardTypesRequest
}
export const getHazardTypesLoadAction = (data: IGetHazardTypesRequest): IGetHazardTypesLoadAction => ({
    type: IGET_HAZARD_TYPES_REQUEST.REQUEST,
    data
});
export interface IGetHazardTypesSuccessAction {
    type: IGET_HAZARD_TYPES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetHazardTypes>>;
}
export const getHazardTypesLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetHazardTypes>>): IGetHazardTypesSuccessAction => ({
    type: IGET_HAZARD_TYPES_REQUEST.SUCCESS,
    list
});
export interface IGetHazardTypesLoadFailedAction {
    type: IGET_HAZARD_TYPES_REQUEST.FAILED;
    message: string;
}
export const getHazardTypesLoadFailedAction = (message: string): IGetHazardTypesLoadFailedAction => ({
    type: IGET_HAZARD_TYPES_REQUEST.FAILED,
    message
});
