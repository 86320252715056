export enum ENVIRONMENT {
    DEVELOPMENT = "development",
    PRODUCTION = "production",
    STAGING = "staging"
}

export interface IWebConfig {
    isDevMode: boolean;
    apiBaseUrl: string;
    googleMapKey: string;
    imgApiBaseUrl: string;
    environment: ENVIRONMENT;
    version: string | undefined;
}

const getEnvNameFromString = (envName?: string): ENVIRONMENT | undefined => {
    switch (envName) {
        case ENVIRONMENT.DEVELOPMENT:
        case ENVIRONMENT.PRODUCTION:
        case ENVIRONMENT.STAGING:
            return envName;
    
        default:
            return undefined;
    }
};

const getWebConfig = (): IWebConfig => {
    const version = process.env.REACT_APP_Version;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
    const imgApiBaseUrl = process.env.REACT_APP_IMG_API_BASE_URL;
    const environment = getEnvNameFromString(process.env.REACT_APP_ENVIRONMENT);

    if (apiBaseUrl && environment && googleMapKey && imgApiBaseUrl) {
        return {
            version,
            apiBaseUrl,
            environment,
            googleMapKey,
            imgApiBaseUrl,
            isDevMode: environment === ENVIRONMENT.DEVELOPMENT
        };
    } 

    throw new Error("webConfig is missing something. Check your `.env` file and make sure it contains all the environmental variables");
};

export const webConfig: IWebConfig = getWebConfig();
