
export enum IADD_FIELDEMPLOYEE_REQUEST {
    REQUEST = "addFieldEmployee/ADD_FIELDEMPLOYEE_REQUEST",
    SUCCESS = "addFieldEmployee/ADD_FIELDEMPLOYEE_SUCCESS",
    FAILED = "addFieldEmployee/ADD_FIELDEMPLOYEE_FAILED"
};

export interface IAddFieldEmployeeRequest {
    token: string;
    request: IAddUpdateFieldEmployee;
};

export interface IAddUpdateFieldEmployee {
    ID?: number;
    Name: string;
    Department: string;
    Foreman: string;
    Active: string;
    Email: string;
    Phone: string;
    Created_By: string;
    Modified_By?: string;
}