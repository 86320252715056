import { IRepairLinePicture } from "../getRepairLines/getRepairLinesConstants";

export enum ISAVE_REPAIR_LINE_ITEM_REQUEST {
    REQUEST = "saveRepairLine/SAVE_REPAIR_LINE_ITEM_REQUEST",
    SUCCESS = "saveRepairLine/SAVE_REPAIR_LINE_ITEM_SUCCESS",
    FAILED = "saveRepairLine/SAVE_REPAIR_LINE_ITEM_FAILED"
};

export interface ISaveRepairLineItemRequest {
    token: string;
    request: ISaveRepairLineItem;
};

export interface ISaveRepairLineItem {
    ID: number 
    Repair_Line_ID: number;
    Attachment_ID?: number;
    Priority: string;
    Status: string;
    // Comments?: string;
    Images: string;
    Major_Component_Replacement?: string;
    Major_Type?: string,
    Component_Repaired_Location?: string,
    OEM_Part?: string,
    Exchange_Type?: string,
    Condition?: string,
    Warranty_Details?: string,
    Vendor?: string,
    BER_Details?: string,
    Serial_No?: string;
    Type?: string;
    Hours?: number;
    New_Major_Component_Replacement_Notes?: string;
    Major_Component_Replacement_Notes?: string;
    Mob_Demob?: string;
    Damaged?: string;
    Damaged_Notes?: string;
    Warranty?: string;
    Component_Code: string;
    // SocT_Tracking?: string;
    Meter_Reading?: number;
    work_Order_No?: string;
    Pictures: IRepairLinePicture[];
    // Parts?: IAddUpdateRepairLineItemPart[];
    Defect_Item: string
    Issue: string;
    Repair_Type?: string;
    Assigned_Mech: string;
    Lube_Driver: string;
    Manager_Instructions: string;
    Parts_Instructions: string;
    Send_Email: string;
    Mechanic_Comments: string;
    Modified_By: string;
    Winter_Repair?: string;
    Repair_Location?: string;
}