import { IAddUpdateRepairLine } from "../addRepairLine/addRepairLineConstants";

export enum IUPDATE_REPAIR_LINE_REQUEST {
    REQUEST = "updateRepairLine/UPDATE_REPAIR_LINE_REQUEST",
    SUCCESS = "updateRepairLine/UPDATE_REPAIR_LINE_SUCCESS",
    FAILED = "updateRepairLine/UPDATE_REPAIR_LINE_FAILED"
};

export interface IUpdateRepairLineRequest {
    token: string;
    request: IAddUpdateRepairLine;
};