
export enum IVALIDATE_MECHANIC_LOGIN_REQUEST {
    REQUEST = "validateMechanicLogin/VALIDATE_MECHANIC_LOGIN_REQUEST",
    SUCCESS = "validateMechanicLogin/VALIDATE_MECHANIC_LOGIN_SUCCESS",
    FAILED = "validateMechanicLogin/VALIDATE_MECHANIC_LOGIN_FAILED"
};

export interface IValidateMechanicLoginRequest {
    request:{
        email: string;
        password: string;
    }
};

export interface IValidateMechanicLogin {
    token: string;
    mechanic: {
        id: number;
        name: string;
        email: string;
    };
};