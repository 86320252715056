import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IUpdateRepeaterKitSuccessAction } from "../updateRepeaterKit/updateRepeaterKitActions";
import { IUPDATE_REPEATER_KIT_REQUEST } from "../updateRepeaterKit/updateRepeaterKitConstants";
import { IADD_REPEATER_KIT_REQUEST } from "../addRepeaterKit/addRepeaterKitConstants";
import { IGET_REPEATER_KIT_REQUEST, IRepeaterKit } from "./getRepeaterKitsConstants";
import { IAddRepeaterKitSuccessAction } from "../addRepeaterKit/addRepeaterKitActions";
import { IGetRepeaterKitLoadAction, IGetRepeaterKitLoadFailedAction, IGetRepeaterKitSuccessAction } from "./getRepeaterKitsActions";

type Actions =
    | IGetRepeaterKitLoadAction
    | IGetRepeaterKitSuccessAction
    | IGetRepeaterKitLoadFailedAction
    | IUpdateRepeaterKitSuccessAction
    | IAddRepeaterKitSuccessAction
    | IFlushDataSuccessAction;

export const GetRepeaterKitReducer = (state: Server<SurewayAPIResponse<ById<IRepeaterKit>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IRepeaterKit>>> => {
    switch (action.type) {
        case IGET_REPEATER_KIT_REQUEST.REQUEST:
            return loading;

        case IGET_REPEATER_KIT_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_REPEATER_KIT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_REPEATER_KIT_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_REPEATER_KIT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};