import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_FILTER_CUT_REQUEST, IUpdateFilterCutRequest } from "./updateFilterCutConstants";


export interface IUpdateFilterCutLoadAction {
    type: IUPDATE_FILTER_CUT_REQUEST.REQUEST;
    data: IUpdateFilterCutRequest
}
export const updateFilterCutLoadAction = (data: IUpdateFilterCutRequest): IUpdateFilterCutLoadAction => ({
    type: IUPDATE_FILTER_CUT_REQUEST.REQUEST,
    data
});
export interface IUpdateFilterCutSuccessAction {
    type: IUPDATE_FILTER_CUT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFilterCutLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFilterCutSuccessAction => ({
    type: IUPDATE_FILTER_CUT_REQUEST.SUCCESS,
    message
});
export interface IUpdateFilterCutLoadFailedAction {
    type: IUPDATE_FILTER_CUT_REQUEST.FAILED;
    message: string;
}
export const updateFilterCutLoadFailedAction = (message: string): IUpdateFilterCutLoadFailedAction => ({
    type: IUPDATE_FILTER_CUT_REQUEST.FAILED,
    message
});
