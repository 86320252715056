import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IVALIDATE_MECHANIC_LOGIN_REQUEST, IValidateMechanicLogin } from "./validateMechanicLoginConstants";
import { MECHANIC_LOCAL_TOKEN, saveToken } from "../mechanicLocalToken/mechanicLocalTokenConstants";
import { IMechanicLocalTokenFailedAction, IMechanicLocalTokenSuccessAction } from "../mechanicLocalToken/mechanicLocalTokenActions";
import { IValidateMechanicLoginLoadAction, IValidateMechanicLoginLoadFailedAction, IValidateMechanicLoginSuccessAction } from "./validateMechanicLoginActions";
import LocalStorage from "../../../../utils/localStorage";

type Actions =
    | IValidateMechanicLoginLoadAction
    | IValidateMechanicLoginSuccessAction
    | IValidateMechanicLoginLoadFailedAction
    | IMechanicLocalTokenSuccessAction
    | IMechanicLocalTokenFailedAction
    | IFlushDataSuccessAction;

export const ValidateMechanicLoginReducer = (state: Server<IValidateMechanicLogin> = notLoaded, action: Actions): Server<IValidateMechanicLogin> => {
    switch (action.type) {
        case IVALIDATE_MECHANIC_LOGIN_REQUEST.REQUEST:
            return loading;

        case IVALIDATE_MECHANIC_LOGIN_REQUEST.SUCCESS:
            saveToken(action.data.token, action.data.mechanic.email, action.data.mechanic.name, action.data.mechanic.id);
            return succeeded(action.data);

        case IVALIDATE_MECHANIC_LOGIN_REQUEST.FAILED:
            return failed(action.message);

        case MECHANIC_LOCAL_TOKEN.SUCCESS:
            return succeeded({ token: action.data.token, mechanic: { email: action.data.email, name: action.data.name, id: action.data.id } });

        case MECHANIC_LOCAL_TOKEN.FAILED:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            LocalStorage.mechanicLogOut();
            return notLoaded;

        default:
            return state;
    }
};