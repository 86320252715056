import { IAddUpdateFormResponse } from "../addFormResponse/addFormResponseConstants";

export enum IUPDATE_FORM_RESPONSE_REQUEST {
    REQUEST = "updateFormResponse/UPDATE_FORM_RESPONSE_REQUEST",
    SUCCESS = "updateFormResponse/UPDATE_FORM_RESPONSE_SUCCESS",
    FAILED = "updateFormResponse/UPDATE_FORM_RESPONSE_FAILED"
};

export interface IUpdateFormResponseRequest {
    token: string;
    request: IAddUpdateFormResponse;
};