import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_DEPARTMENT_REQUEST, IUpdateDepartmentRequest } from "./updateDepartmentConstants";


export interface IUpdateDepartmentLoadAction {
    type: IUPDATE_DEPARTMENT_REQUEST.REQUEST;
    data: IUpdateDepartmentRequest
}
export const updateDepartmentLoadAction = (data: IUpdateDepartmentRequest): IUpdateDepartmentLoadAction => ({
    type: IUPDATE_DEPARTMENT_REQUEST.REQUEST,
    data
});
export interface IUpdateDepartmentSuccessAction {
    type: IUPDATE_DEPARTMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateDepartmentLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateDepartmentSuccessAction => ({
    type: IUPDATE_DEPARTMENT_REQUEST.SUCCESS,
    message
});
export interface IUpdateDepartmentLoadFailedAction {
    type: IUPDATE_DEPARTMENT_REQUEST.FAILED;
    message: string;
}
export const updateDepartmentLoadFailedAction = (message: string): IUpdateDepartmentLoadFailedAction => ({
    type: IUPDATE_DEPARTMENT_REQUEST.FAILED,
    message
});
