import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { IFilterCut, IGetFilterCutRequest } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsConstants";
import { getFilterCuts } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsAccessor";
import { getFilterCutsLoadAction } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsActions";
import { Radio, RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@mui/material";

interface IFilterCutsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getFilterCut: Server<SurewayAPIResponse<ById<IFilterCut>>>;
};

interface IFilterCutsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getFilterCutsRequest: (data: IGetFilterCutRequest) => unknown;
};


interface IFilterCutsOwnProps {

};

interface IFilterCutsState {
    view: string;
};

const FilterCutsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IFilterCutsProps = RouteComponentProps
    & IFilterCutsStoreProps
    & IFilterCutsDispatchProps
    & IFilterCutsOwnProps;

class FilterCuts extends PureComponent<IFilterCutsProps, IFilterCutsState> {

    public constructor(props: IFilterCutsProps) {
        super(props);
        this.state = {
            view: "Active"
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IFilterCutsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getFilterCut, getToken } = this.props;
        const { view } = this.state;
        // const getRole = pageAccessCheck(getToken, "repairLine");
        const data = hasPayload(getFilterCut) ? Object.values(getFilterCut.payload.response) : [];
        // const filterData = view === "active_Bad" ? data.filter(x => x.manager_Status === "Active") : data;
        // console.log(filterData)

        return (
            <PageSpacing title="Filter Cuts" description="FIELD - Filter Cut" fixedSpaceOnSmallerScreens={true}>
                {/* {(getRole !== NotApplicable) &&  */}
                <FilterCutsStyles>
                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">FILTER CUTS</h2>
                                <hr />

                                {getFilterCut.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Filter Cuts..." />}
                                {getFilterCut.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Filter Cuts..." />}

                                {getFilterCut.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Active" control={<Radio />} label="Active (Bad)" />
                                                <FormControlLabel value="Latest" control={<Radio />} label="Last 30 Days" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="filterCutList"
                                                customRowColor={true}
                                                exportFileName="FilterCuts"
                                                storageKey="filterCutsListSessionKey"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "date_Cut", caption: "Date Cut", type: "date" },
                                                    { name: "unit_No", caption: "Unit #", type: "string" },
                                                    { name: "hours", caption: "Hours", type: "number" },                                                   
                                                    { name: "km", caption: "Km", type: "number" },
                                                    { name: "filter", caption: "Filter", type: "string" },
                                                    { name: "circuit", caption: "Circuit/Type", type: "string" },
                                                    { name: "results", caption: "Results", type: "string" },
                                                    { name: "comments", caption: "Comments", type: "string" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </FilterCutsStyles>
                {/* } */}
            </PageSpacing>
        );
    }

    private onViewClick = (e:any, value:string): void => {
        this.setState({ view: value });

        if (hasPayload(this.props.getToken)) {
            this.props.getFilterCutsRequest({
                token: this.props.getToken.payload.response.token,
                request : {
                    view: value
                }
            });
        };
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FILTER_CUT.FILTER_CUT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.FILTER_CUT.FILTER_CUT
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            // if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getFilterCut)) {
                    this.getDataForTable();
                };
            // } 
            // else {
            //     this.props.history.push({
            //         pathname: ROUTE.ACCESS_DENIED,
            //         search: getTokenFromUrl(false)
            //     });
            // };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getFilterCutsRequest({
                token: this.props.getToken.payload.response.token,
                request : {
                    view: "Active"
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IFilterCutsStoreProps => ({
    getToken: getToken(state),
    getFilterCut: getFilterCuts(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IFilterCutsDispatchProps => ({
    getFilterCutsRequest: (data: IGetFilterCutRequest) => dispatch(getFilterCutsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterCuts);