import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_REPAIR_LINE_ITEM_REQUEST, ISaveRepairLineItemRequest } from "./saveRepairLineItemConstants";


export interface ISaveRepairLineItemLoadAction {
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.REQUEST;
    data: ISaveRepairLineItemRequest
}
export const saveRepairLineItemLoadAction = (data: ISaveRepairLineItemRequest): ISaveRepairLineItemLoadAction => ({
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.REQUEST,
    data
});
export interface ISaveRepairLineItemSuccessAction {
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveRepairLineItemLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveRepairLineItemSuccessAction => ({
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.SUCCESS,
    message
});
export interface ISaveRepairLineItemLoadFailedAction {
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.FAILED;
    message: string;
}
export const saveRepairLineItemLoadFailedAction = (message: string): ISaveRepairLineItemLoadFailedAction => ({
    type: ISAVE_REPAIR_LINE_ITEM_REQUEST.FAILED,
    message
});
