import { END_POINTS } from "../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST, IGetUnitInspectionSheetByID } from "./getUnitInspectionSheetByIDConstants";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetUnitInspectionSheetByIDLoadAction, IGetUnitInspectionSheetByIDLoadFailedAction, IGetUnitInspectionSheetByIDSuccessAction, 
    getUnitInspectionSheetByIDLoadFailedAction, getUnitInspectionSheetByIDLoadSuccessAction } from "./getUnitInspectionSheetByIDActions";

export const getUnitInspectionSheetByIDEpic: Epic = (
    action$: ActionsObservable<IGetUnitInspectionSheetByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitInspectionSheetByIDSuccessAction | IGetUnitInspectionSheetByIDLoadFailedAction> =>
    action$.ofType(IGET_UNIT_INSPECTION_SHEET_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetUnitInspectionSheetByID>>(
                    END_POINTS.FIELD.INSPECTION_FORM.GET_UNIT_INSPECTION_SHEET_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetUnitInspectionSheetByID>): IGetUnitInspectionSheetByIDSuccessAction => {
                            return getUnitInspectionSheetByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitInspectionSheetByIDLoadFailedAction(response.message)))
                    )
            )
        );