
export enum IGET_PM_SHEET_VERSION_HISTORY_REQUEST {
    REQUEST = "getPMSheetVersionHistory/GET_PM_SHEET_VERSION_HISTORY_REQUEST",
    SUCCESS = "getPMSheetVersionHistory/GET_PM_SHEET_VERSION_HISTORY_SUCCESS",
    FAILED = "getPMSheetVersionHistory/GET_PM_SHEET_VERSION_HISTORY__FAILED"
};

export interface IGetPMSheetVersionHistoryRequest {
    request: {
        ID: number;
        Type: string
    };
    token: string;
};

export interface IGetPMSheetVersionHistory {
    id: number;
    equipment_Type_ID: number;
    unit_No: string;
    equipment_Type: string;
    comments: string;
    admin_Comments:string;
    hours: number;
    itemID: number;
    unit_ID: number;
    sub_Location_ID: number;
    operation: number;
    approved_Date: string;
    major_Component_Replacement_Notes: string;
    location_Info: string;
    make: string;
    model: string;
    sT_ID: number | null;
    service_Date: string;
    service_Due: string;
    service_Truck: string;
    status: string;
    created: string;
    created_By: number;
    modified_By: string;
    modified: string;
};