import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_BASE_KIT_REQUEST } from "./updateBaseKitConstants";
import {
    IUpdateBaseKitLoadAction, IUpdateBaseKitLoadFailedAction, IUpdateBaseKitSuccessAction,
    updateBaseKitLoadFailedAction, updateBaseKitLoadSuccessAction
} from "./updateBaseKitActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateBaseKitEpic: Epic = (
    action$: ActionsObservable<IUpdateBaseKitLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IUpdateBaseKitSuccessAction | IUpdateBaseKitLoadFailedAction> =>
    action$.ofType(IUPDATE_BASE_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_BASE_KIT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateBaseKitSuccessAction | IUpdateBaseKitLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return updateBaseKitLoadSuccessAction(response);
                            } else {
                                return updateBaseKitLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateBaseKitLoadFailedAction(response.message)))
                    )
            )
        );