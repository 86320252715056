import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, numberAndDecimalCheck, pageAccessCheck, undefinedFunction, userName, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextArea from "../../shared/textArea";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import PageSpacing from "../../shared/pageSpacing";
import { getMachineControlBoxesLoadAction } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesActions";
import { IMachineControlBoxes, IMachineControlBoxesRequest } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesConstants";
import { getMachineControlBoxes } from "../../../redux/survey/getMachineControlBoxes/getMachineControlBoxesAccessor";
import { addMachineControlBox } from "../../../redux/survey/addMachineControlBox/addMachineControlBoxAccessor";
import { updateMachineControlBox } from "../../../redux/survey/updateMachineControlBox/updateMachineControlBoxAccessor";
import { IAddUpdateMachineBox, IAddUpdateMachineControlBoxRequest } from "../../../redux/survey/addMachineControlBox/addMachineControlBoxConstants";
import { addMachineControlBoxLoadAction } from "../../../redux/survey/addMachineControlBox/addMachineControlBoxActions";
import { updateMachineControlBoxLoadAction } from "../../../redux/survey/updateMachineControlBox/updateMachineControlBoxActions";
import LAAutoComplete from "../../shared/autoComplete";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";

const RequiredFields: string[] = ["serial_No"];

interface IAddUpdateMachineControlBoxComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    addMachineControlBoxStatus: Server<SurewayAPIResponse<string>>;
    updateMachineControlBoxStatus: Server<SurewayAPIResponse<string>>;
    getMachineBoxesStatus: Server<SurewayAPIResponse<ById<IMachineControlBoxes>>>;
};

interface IAddUpdateMachineControlBoxComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => unknown;
    addMachineControlBoxRequest: (data: IAddUpdateMachineControlBoxRequest) => unknown;
    updateMachineControlBoxRequest: (data: IAddUpdateMachineControlBoxRequest) => unknown;
};

interface IAddUpdateMachineControlBoxOwnProps {
    id?: string;
};

interface IAddUpdateMachineControlBoxComponentState {
    data: IMachineControlBoxes;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateMachineControlBoxStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateMachineControlBoxComponentProps =
    RouteComponentProps
    & IAddUpdateMachineControlBoxOwnProps
    & IAddUpdateMachineControlBoxComponentStoreProps
    & IAddUpdateMachineControlBoxComponentDispatchProps;

class AddUpdateMachineControlBox extends PureComponent<IAddUpdateMachineControlBoxComponentProps, IAddUpdateMachineControlBoxComponentState> {

    public constructor(props: IAddUpdateMachineControlBoxComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                serial_No: "",
                make: "",
                model: "",
                version: 0,
                auto_Dozer: "",
                auto_Grader: "",
                auto_Excavator: "",
                notes: "",
                created_By: userName,
                created: "",
                modified_By: userName,
                modified: "",
            },
            errors: {},
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMachineControlBoxComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addMachineControlBoxStatus !== prevProps.addMachineControlBoxStatus) {

                if (this.props.addMachineControlBoxStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addMachineControlBoxStatus.message });

                if (hasPayload(this.props.addMachineControlBoxStatus) && this.props.addMachineControlBoxStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateMachineControlBoxStatus !== prevProps.updateMachineControlBoxStatus) {

                if (this.props.updateMachineControlBoxStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateMachineControlBoxStatus.message });

                if (hasPayload(this.props.updateMachineControlBoxStatus) && this.props.updateMachineControlBoxStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const title = (data.id > 0 ? "VIEW/UPDATE " : "ADD") + " MACHINE CONTROL BOX";
        const { addMachineControlBoxStatus, updateMachineControlBoxStatus } = this.props;

        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onAutoDozer = (event: unknown, value: IIdName): void => this.handleChange("auto_Dozer", value !== null ? value.name : data.auto_Dozer);
        const onAutoGrader = (event: unknown, value: IIdName): void => this.handleChange("auto_Grader", value !== null ? value.name : data.auto_Grader);
        const onAutoExcavator = (event: unknown, value: IIdName): void => this.handleChange("auto_Excavator", value !== null ? value.name : data.auto_Excavator);

        return (
            <PageSpacing title={title} description={`Survey - ${title}`} fixedSpaceOnSmallerScreens={true}>
                <AddUpdateMachineControlBoxStyles>
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{title}</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Serial #"
                                fullWidth={true}
                                variant="outlined"
                                name="serial_No"
                                onChange={this.handleChange}
                                value={data.serial_No}
                                errorText={errors["serial_No"] ? errors["serial_No"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Make"
                                fullWidth={true}
                                variant="outlined"
                                name="make"
                                onChange={this.handleChange}
                                value={data.make}
                                errorText={errors["make"] ? errors["make"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Model"
                                fullWidth={true}
                                variant="outlined"
                                name="model"
                                onChange={this.handleChange}
                                value={data.model}
                                errorText={errors["model"] ? errors["model"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextField
                                label="Version"
                                fullWidth={true}
                                variant="outlined"
                                name="version"
                                onChange={this.handleVersion}
                                value={data.version}
                                errorText={errors["version"] ? errors["version"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onAutoDozer}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Auto Dozer"
                                selectionRemove={undefinedFunction}
                                value={data.auto_Dozer ? YesOrNo.find(q => q.name === data.auto_Dozer) : null}
                                defaultValue={data.auto_Dozer ? YesOrNo.find(q => q.name === data.auto_Dozer) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onAutoGrader}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Auto Grader"
                                selectionRemove={undefinedFunction}
                                value={data.auto_Grader ? YesOrNo.find(q => q.name === data.auto_Grader) : null}
                                defaultValue={data.auto_Grader ? YesOrNo.find(q => q.name === data.auto_Grader) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LAAutoComplete
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onAutoExcavator}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Auto Excavator"
                                selectionRemove={undefinedFunction}
                                value={data.auto_Excavator ? YesOrNo.find(q => q.name === data.auto_Excavator) : null}
                                defaultValue={data.auto_Excavator ? YesOrNo.find(q => q.name === data.auto_Excavator) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={3}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={undefined}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created"
                                    label="Created"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.created ? new Date(data.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["created"] ? errors["created"].message : undefined}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="created_By"
                                    label="Created By"
                                    disabled={true}
                                    variant="outlined"
                                    value={data.created_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified"
                                    label="Last Modified"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified"] ? errors["modified"].message : undefined}
                                />
                            </LAGridItem>


                            <LAGridItem xs={12} sm={6} md={3}>
                                <LATextField
                                    fullWidth={true}
                                    name="modified_By"
                                    label="Modified By"
                                    variant="outlined"
                                    disabled={true}
                                    value={data.modified_By}
                                    onChange={undefinedFunction}
                                    errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                />
                            </LAGridItem>
                        </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>

                    <RequestStatus requestStatus={addMachineControlBoxStatus.kind} successMessage="Machine Control Box has been added successfully" />
                    <RequestStatus requestStatus={updateMachineControlBoxStatus.kind} successMessage="Machine Control Box has been updated successfully" />

                </AddUpdateMachineControlBoxStyles>
            </PageSpacing>
        );
    }

    private handleVersion = (name: string, value: string): void => {
        if (numberAndDecimalCheck(value)) {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: value
                }
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.MACHINE_CONTROL_BOXES
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        const request: IAddUpdateMachineBox = {
            Id: data.id,
            Serial_No: data.serial_No,
            Make: data.make,
            Model: data.model,
            Version: data.version,
            Notes: data.notes,
            Auto_Dozer: data.auto_Dozer,
            Auto_Grader: data.auto_Grader,
            Auto_Excavator: data.auto_Excavator,
            Created_By: data.created_By,
            Modified_By: data.modified_By
        };

        if (hasPayload(this.props.token)) {
            if (data.id === 0) {
                this.props.addMachineControlBoxRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            }
            else {
                request.Modified_By = this.props.token.payload.response.upn;

                this.props.updateMachineControlBoxRequest({
                    token: this.props.token.payload.response.token,
                    request: request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.token))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.token)) {
                const { token } = this.props.token.payload.response;
                if (pageAccessCheck(this.props.token, "surveyAccess") !== NotApplicable) {
                    if (hasPayload(this.props.getMachineBoxesStatus)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getMachineBoxesStatus.payload.response[query.id];

                                this.setState({
                                    data,
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getMachineControlBoxesRequest({
                            token
                        });
                    }
                }
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMachineControlBoxComponentStoreProps => ({
    token: getToken(state),
    addMachineControlBoxStatus: addMachineControlBox(state),
    getMachineBoxesStatus: getMachineControlBoxes(state),
    updateMachineControlBoxStatus: updateMachineControlBox(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMachineControlBoxComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMachineControlBoxesRequest: (data: IMachineControlBoxesRequest) => dispatch(getMachineControlBoxesLoadAction(data)),
    addMachineControlBoxRequest: (data: IAddUpdateMachineControlBoxRequest) => dispatch(addMachineControlBoxLoadAction(data)),
    updateMachineControlBoxRequest: (data: IAddUpdateMachineControlBoxRequest) => dispatch(updateMachineControlBoxLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMachineControlBox);