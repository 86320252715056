import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_MOVE_REQUEST, IAddAttachmentMoveRequest } from "./addAttachmentMoveConstants";


export interface IAddAttachmentMoveLoadAction {
    type: IADD_ATTACHMENT_MOVE_REQUEST.REQUEST;
    data: IAddAttachmentMoveRequest
}
export const addAttachmentMoveLoadAction = (data: IAddAttachmentMoveRequest): IAddAttachmentMoveLoadAction => ({
    type: IADD_ATTACHMENT_MOVE_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentMoveSuccessAction {
    type: IADD_ATTACHMENT_MOVE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentMoveLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentMoveSuccessAction => ({
    type: IADD_ATTACHMENT_MOVE_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentMoveLoadFailedAction {
    type: IADD_ATTACHMENT_MOVE_REQUEST.FAILED;
    message: string;
}
export const addAttachmentMoveLoadFailedAction = (message: string): IAddAttachmentMoveLoadFailedAction => ({
    type: IADD_ATTACHMENT_MOVE_REQUEST.FAILED,
    message
});
