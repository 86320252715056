import { PureComponent, ReactNode } from "react";
import { connect } from "react-redux";
import { authContext } from "../../adalConfig";
import { flushDataRequestAction } from "../../redux/clearRedux/clearReduxActions";
import { IDispatch } from "../../redux/reducers";
import { LARedButton } from "../shared/buttons";
import { LogOutIcon } from "../shared/icons";
import { HasClass } from "../shared/publicInterfaces";
import { WHITE_COLOR } from "../shared/theme";

interface ILogoutButtonDispatchProps {
    flushDataRequest: () => unknown;
}

type ILogoutButtonProps = ILogoutButtonDispatchProps & HasClass;

class LogoutButton extends PureComponent<ILogoutButtonProps> {
    public render(): ReactNode {
        return (
            <LARedButton
                label="Logout"
                fullWidth={true}
                onClick={this.logout}
                className={this.props.className}
                startIcon={<LogOutIcon color={WHITE_COLOR} />}
            />
        );
    }

    private logout = (): void => {
        this.props.flushDataRequest();
        authContext.logOut();
    };
}

const mapDispatchToProps = (dispatch: IDispatch): ILogoutButtonDispatchProps => ({
    flushDataRequest: (): unknown => dispatch(flushDataRequestAction())
});

export default connect(null, mapDispatchToProps)(LogoutButton);
