import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANIC_LOGS_REQUEST {
    REQUEST = "getMechanicLogs/GET_MECHANIC_LOGS_REQUEST",
    SUCCESS = "getMechanicLogs/GET_MECHANIC_LOGS_SUCCESS",
    FAILED = "getMechanicLogs/GET_MECHANIC_LOGS_FAILED"
};

export interface IGetMechanicLogsRequest extends ISurewayTokenRequestBody {
    request: {
        view: string;
    }
};

export interface IMechanicLogs {
    id: number;
    repair_ID?: number;
    defect_ID?: number;
    display?: string;
    defect_Item?: string;
    date: string;
    component_Code?: string;
    mechanic_Name: string;
    comments?: string;
    hours: number;
    status: string;
    job_No?: string;
    cost_Code?: string;
    type?: string;
    travel_Type?: string;
    billable?: string;
    billable_Percentage?: number;
    description?: string;
    contractor?: string;
};