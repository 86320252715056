import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl, pageAccessCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ArrowLeftIcon } from "../../shared/icons";
import { IGetRepairLineWOUnitsRequest, IRepairLineWOUnits } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsConstants";
import { IAddRepairLineWOUnitRequest, IAddUpdateRepairLineWOUnit } from "../../../redux/field/repairLine/addRepairLineWOUnit/addRepairLineWOUnitConstants";
import { IUpdateRepairLineWOUnitRequest } from "../../../redux/field/repairLine/updateRepairLineWOUnit/updateRepairLineWOUnitConstants";
import { addRepairLineWOUnitLoadAction } from "../../../redux/field/repairLine/addRepairLineWOUnit/addRepairLineWOUnitActions";
import { getRepairLineWOUnitsLoadAction } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsActions";
import { updateRepairLineWOUnitLoadAction } from "../../../redux/field/repairLine/updateRepairLineWOUnit/updateRepairLineWOUnitActions";
import { getRepairLineWOUnits } from "../../../redux/field/repairLine/getRepairLineWOUnits/getRepairLineWOUnitsAccessor";
import { addRepairLineWOUnitStatus } from "../../../redux/field/repairLine/addRepairLineWOUnit/addRepairLineWOUnitAccessor";
import { updateRepairLineWOUnitStatus } from "../../../redux/field/repairLine/updateRepairLineWOUnit/updateRepairLineWOUnitAccessor";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";


const RequiredFields: string[] = ["unit_Number", "work_Order_No"];

interface IAddUpdateRepairLineWOUnitComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addRepairLineWOUnitStatus: Server<SurewayAPIResponse<string>>;
    repairLineWOUnits: Server<SurewayAPIResponse<ById<IRepairLineWOUnits>>>;
    updateRepairLineWOUnitStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateRepairLineWOUnitComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getRepairLineWOUnitsRequest: (data: IGetRepairLineWOUnitsRequest) => unknown
    addRepairLineWOUnitRequest: (data: IAddRepairLineWOUnitRequest) => unknown;
    updateRepairLineWOUnitRequest: (data: IUpdateRepairLineWOUnitRequest) => unknown;
};

interface IAddUpdateRepairLineWOUnitOwnProps {
    id?: string;
};

interface IAddUpdateRepairLineWOUnitComponentState {
    data: IRepairLineWOUnits;
    notAvaUnits: number[];
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateRepairLineWOUnitStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateRepairLineWOUnitComponentProps =
    RouteComponentProps
    & IAddUpdateRepairLineWOUnitOwnProps
    & IAddUpdateRepairLineWOUnitComponentStoreProps
    & IAddUpdateRepairLineWOUnitComponentDispatchProps;

class AddUpdateRepairLineWOUnit extends PureComponent<IAddUpdateRepairLineWOUnitComponentProps, IAddUpdateRepairLineWOUnitComponentState> {

    public constructor(props: IAddUpdateRepairLineWOUnitComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                unit_ID : 0,
                unit_No: "",
                work_Order_No: "",
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            errors: {},
            notAvaUnits: [],
            serverError: "",
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateRepairLineWOUnitComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addRepairLineWOUnitStatus !== prevProps.addRepairLineWOUnitStatus) {

                if (this.props.addRepairLineWOUnitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRepairLineWOUnitStatus.message });

                if (hasPayload(this.props.addRepairLineWOUnitStatus) && this.props.addRepairLineWOUnitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateRepairLineWOUnitStatus !== prevProps.updateRepairLineWOUnitStatus) {

                if (this.props.updateRepairLineWOUnitStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRepairLineWOUnitStatus.message });

                if (hasPayload(this.props.updateRepairLineWOUnitStatus) && this.props.updateRepairLineWOUnitStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { token, addRepairLineWOUnitStatus, updateRepairLineWOUnitStatus, getLookup } = this.props;
        const { data, errors, serverError, notAvaUnits } = this.state;
        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const getRole = pageAccessCheck(token, "workorder");
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        
        return (
            <AddUpdateRepairLineWOUnitStyles>
                {(getRole !== NotApplicable && getRole !== "No") &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "} Repair Line WO#</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                                    <DevExtremePopupLookup
                                        data={(units && units.length > 0) ? units.filter((q) => notAvaUnits.includes(q.id) ? false : true) : []}
                                        id="job-search"
                                        placeHolder="Unit Number"
                                        disabled={false}
                                        displayExp="unit_Number"
                                        name="unit_Number"
                                        errorName="unit_Number"
                                        errors={this.state.errors}
                                        currentVal={unit?.unit_Number}
                                        onClick={onUnit}
                                        columns={[
                                            { name: "unit_Number", caption: "Unit Number", type: "string" },
                                            { name: "make", caption: "Make", type: "string" },
                                            { name: "model", caption: "Model", type: "string" },
                                            { name: "location_Details", caption: "Location", type: "string" },
                                        ]}
                                    />

                                </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Work Order No."
                                fullWidth={true}
                                variant="outlined"
                                disabled={false}
                                name="work_Order_No"
                                value={data.work_Order_No ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["work_Order_No"] ? errors["work_Order_No"].message : undefined}
                            />
                        </LAGridItem>

                        {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addRepairLineWOUnitStatus.kind} successMessage="Work Order Unit has been added successfully" />
                <RequestStatus requestStatus={updateRepairLineWOUnitStatus.kind} successMessage="Work Order Unit has been updated successfully" />

            </AddUpdateRepairLineWOUnitStyles>
        );
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        let errors = { ...this.state.errors };

        if (value === undefined) {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value, errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
                unit_No: value
            },
            errors
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.WORK_ORDER_UNITS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {

        const {
            id,
            unit_ID,
            work_Order_No,
        } = this.state.data;

        if (hasPayload(this.props.token)) {

            const request: IAddUpdateRepairLineWOUnit = {
                ID: id,
                Unit_ID: unit_ID,
                Work_Order_No: work_Order_No,
                Created_By: this.props.token.payload.response.upn,
                Modified_By: this.props.token.payload.response.upn,
            }

            if (id === 0) {
                this.props.addRepairLineWOUnitRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } else {
                this.props.updateRepairLineWOUnitRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.token))
                        this.getLook();

                    if (hasPayload(this.props.repairLineWOUnits)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.repairLineWOUnits.payload.response[query.id];
                                const notAvaUnits = Object.values(this.props.repairLineWOUnits.payload.response).filter(x => x.unit_ID !== data.unit_ID).map(({ unit_ID }) => unit_ID);
                                
                                this.setState({
                                    data,
                                    notAvaUnits
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};
                            const notAvaUnits = Object.values(this.props.repairLineWOUnits.payload.response).map(({ unit_ID }) => unit_ID);
                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({ notAvaUnits, errors });
                        }
                    } else {
                        this.props.getRepairLineWOUnitsRequest({
                            token: this.props.token.payload.response.token
                        });
                    }

                } else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Repair_Line_WO_Units"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        if (name === "email")
            rules.email = true;

        if (name === "phone")
            rules.phone = true;

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateRepairLineWOUnitComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    repairLineWOUnits: getRepairLineWOUnits(state),
    addRepairLineWOUnitStatus: addRepairLineWOUnitStatus(state),
    updateRepairLineWOUnitStatus: updateRepairLineWOUnitStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateRepairLineWOUnitComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addRepairLineWOUnitRequest: (data: IAddRepairLineWOUnitRequest) => dispatch(addRepairLineWOUnitLoadAction(data)),
    getRepairLineWOUnitsRequest: (data: IGetRepairLineWOUnitsRequest) => dispatch(getRepairLineWOUnitsLoadAction(data)),
    updateRepairLineWOUnitRequest: (data: IUpdateRepairLineWOUnitRequest) => dispatch(updateRepairLineWOUnitLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateRepairLineWOUnit);