import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MOVE_REQUEST_HISTORY_REQUEST {
    REQUEST = "getMoveRequestHistory/GET_MOVE_REQUEST_HISTORY_REQUEST",
    SUCCESS = "getMoveRequestHistory/GET_MOVE_REQUEST_HISTORY_SUCCESS",
    FAILED = "getMoveRequestHistory/GET_MOVE_REQUEST_HISTORY_FAILED"
};

export interface IGetMoveRequestHistoryRequest extends ISurewayTokenRequestBody {

};

export interface IMoveRequestHistory {
    id: number;
    status: string;
    complete_By: string;
    attachment_No: string;
    from_Location: string;
    to_Location: string;
    attached_Unit_No: string;
    type: string;
    coupler_Design: string;
    style: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    attachment_ID: number;
    from_Sub_Location_ID: number;
    to_Sub_Location_ID: number;
    move_Request_ID: number;
    attached_Unit_ID: number;
    coupler_Design_ID: number;
    style_ID: number;
    type_ID: number;
};