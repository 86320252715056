import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SWAMPERS_REQUEST {
    REQUEST = "getSwampers/GET_SWAMPERS_REQUEST",
    SUCCESS = "getSwampers/GET_SWAMPERS_SUCCESS",
    FAILED = "getSwampers/GET_SWAMPERS_FAILED"
};

export interface IGetSwamperRequest extends ISurewayTokenRequestBody {

};

export interface ISwamper {
    id: number;
    name: string;
    lastName: string;
    email: string;
    phone: string;
    firstName: string;
    comments: string;
    active: string;
    password: string;
    loginInfo: boolean;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};