import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST, IWorkingSiteLogOnCall } from "./getWorkingSiteLogsOnCallConstants";
import { IGetWorkingSiteLogsOnCallLoadAction, IGetWorkingSiteLogsOnCallLoadFailedAction, IGetWorkingSiteLogsOnCallSuccessAction } from "./getWorkingSiteLogsOnCallActions";
import { IUpdateWorkingSiteLogSuccessAction } from "../updateWorkingSiteLog/updateWorkingSiteLogActions";
import { IAddWorkingSiteLogSuccessAction } from "../addWorkingSiteLog/addWorkingSiteLogActions";
import { IUPDATE_WORKING_SITE_LOG_REQUEST } from "../updateWorkingSiteLog/updateWorkingSiteLogConstants";
import { IADD_WORKING_SITE_LOG_REQUEST } from "../addWorkingSiteLog/addWorkingSiteLogConstants";
import { IUpdateWorkingSiteLogOnCallSuccessAction } from "../updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallActions";
import { IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST } from "../updateWorkingSiteLogOnCall/updateWorkingSiteLogOnCallConstants";

type Actions =
    | IGetWorkingSiteLogsOnCallLoadAction
    | IGetWorkingSiteLogsOnCallSuccessAction
    | IGetWorkingSiteLogsOnCallLoadFailedAction
    | IUpdateWorkingSiteLogSuccessAction
    | IAddWorkingSiteLogSuccessAction
    | IUpdateWorkingSiteLogOnCallSuccessAction
    | IFlushDataSuccessAction;

export const GetWorkingSiteLogsOnCallReducer = (state: Server<SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>> => {
    switch (action.type) {
        case IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.REQUEST:
            return loading;

        case IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
            return notLoaded;

        
        
        case IUPDATE_WORKING_SITE_LOG_ON_CALL_REQUEST.SUCCESS:
                return notLoaded;
    

        
        default:
            return state;
    }
};