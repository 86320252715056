import React from "react";
import { ById } from "../../shared/publicInterfaces";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithLessPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DropPaper } from "../../shared/dragAndDropPaper";
import LAErrorBox from "../../shared/errorBox";
import { DARK_GREY_COLOR, GREEN_COLOR, RED_COLOR, WHITE_COLOR } from "../../shared/theme";
import { LAIconButton } from "../../shared/buttons";
import { CollapseIcon, DeleteIcon, ExpandIcon } from "../../shared/icons";
import { undefinedFunction } from "../../shared/constExports";
import { IMechanic } from "../../../redux/field/mechanics/getMechanics/getMechanicsConstants";
import { IRepairLine } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";

interface IDispatchRepairLine {
    index: number;
    expandIdx: number[];
    repairLineSearch: string;
    mechanics: ById<IMechanic>;
    data: IRepairLine | undefined;
    onExpand: (index: number) => void;
    onDelete: (id: number, mechId: number) => void;
    onChange: (name: string, value: string, id: number) => void;
};

export const DispatchRepairLine = React.memo((props: IDispatchRepairLine): JSX.Element => {
    
    return (
        (props.data === undefined)
            ? <></>
            : (((props.data.site_Name && props.repairLineSearch.length > 0) ? props.data.site_Name.toLowerCase().includes(props.repairLineSearch.toLowerCase()) : true))
                ? <LAGridItem key={props.data.id} xs={12} sm={6} md={4} className={(props.data && (props.data.assigned_Mech === null || props.data.assigned_Mech.length === 0)) ? "red-box" : ""}>
                    <LAPaperWithLessPadding>

                        <RepairLineInfo
                            repairLine={props.data}
                        />

                        <Droppable key={props.data.id} droppableId={props.data.id.toString()} isDropDisabled={props.data.assigned_Mechanic_ID ? true : false}>
                            {(provided, snapshot) => {
                                const onChange = (name: string, value: string) => props.data ? props.onChange(name, value, props.data.id) : undefinedFunction;
                                let ids = (props.data && props.data.assigned_Mech && props.data.assigned_Mech.length > 0) ? props.data.assigned_Mech.split(",").map((x) => Number(x)) : [];
                                const onExpand = () => props.onExpand(props.index);
                                const expandInd = props.expandIdx.includes(props.index);
                                
                                return (
                                    <>
                                        {<DropPaper
                                            className="cursor text-center"
                                            innerRef={provided.innerRef}
                                            key={props.data ? props.data.id : 0}
                                            styleDraggar={snapshot.isDraggingOver}
                                        >
                                            <strong onClick={onExpand}>
                                                MECHANIC
                                                {(props.data && ids.length > 0) && <>
                                                    {expandInd ? <CollapseIcon color={RED_COLOR} /> : <ExpandIcon color={GREEN_COLOR} />}
                                                </>}
                                            </strong>

                                            {expandInd && <>
                                                <hr />
                                                <br />
                                            </>}

                                            {(props.data && ids.length > 0) ?
                                                <>
                                                {expandInd && <>
                                                    <LATextArea
                                                        rowsMax={4}
                                                        minRows={2}
                                                        onChange={onChange}
                                                        name="partsInstruction"
                                                        label="Parts Instructions"
                                                        value={props.data.partsInstruction ?? ""}
                                                    />

                                                    <LATextArea
                                                        rowsMax={4}
                                                        minRows={2}
                                                        onChange={onChange}
                                                        className="mt-2 mb-2"
                                                        name="technicianInstructions"
                                                        label="Technician Instructions"
                                                        value={props.data.technicianInstructions ?? ""}
                                                    />

                                                    {ids.map((q, ind) => {
                                                        const onDelete = () => props.data ? props.onDelete(props.data.id, q) : undefinedFunction;
                                                        if (props.mechanics[q]) {
                                                            return (
                                                                <div key={ind}>
                                                                    <MechanicInfo
                                                                        repairLines={{}}
                                                                        mechanic={props.mechanics[q]}
                                                                    />

                                                                    <LAIconButton
                                                                        label="Delete"
                                                                        onClick={onDelete}
                                                                        icon={<DeleteIcon color={RED_COLOR} />}
                                                                    />
                                                                    <hr />
                                                                </div>
                                                            )
                                                        } else {
                                                            return <></>
                                                        }
                                                    })}
                                                </>}
                                                </>
                                                : <LAErrorBox
                                                    className="text-center"
                                                    text="Mechanic Not Assigned"
                                                />}
                                        </DropPaper>}
                                    </>
                                )
                            }}
                        </Droppable>
                    </LAPaperWithLessPadding>
                </LAGridItem>
                : <></>
    )
});

interface IMechanicProps {
    index: number;
    data: IMechanic;
    repairLines: ById<IRepairLine>;
};

export const Mechanic: React.FC<IMechanicProps> = React.memo((props: IMechanicProps) =>
    <LAGridItem key={props.data.id} xs={12} sm={6} md={3}>
        <Draggable
            key={props.data.id}
            draggableId={"mechanic," + props.data.id.toString()}
            index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={dragItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}>

                    <MechanicInfo
                        mechanic={props.data}
                        repairLines={props.repairLines}
                    />
                </div>
            )}
        </Draggable>
    </LAGridItem>
);

const isMechanicAssigned = (mechanic: IMechanic, repairLines: ById<IRepairLine>): string | undefined => {
    let units = "";

    for (const id in repairLines) {
        if (repairLines[id].assigned_Mechanic_ID === mechanic.id) {

            if (repairLines[id].assigned_Mechanic_ID)
                if (units === "") {
                    units = repairLines[id].site_Name ?? "";
                } else {
                    units = units + ", " + repairLines[id].site_Name;
                }
        }
    }
    return units;
};

export const dragItemStyle = (isDragging: any, draggableStyle: any, borderColor?: string) => ({
    padding: 8,
    margin: 10,
    userSelect: "none",
    borderRadius: "5px",
    wordBreak: "break-word",
    background: isDragging ? WHITE_COLOR : WHITE_COLOR,
    border: `2px solid ${borderColor ?? DARK_GREY_COLOR}`,
    ...draggableStyle
});

interface IMechanicInfo {
    mechanic: IMechanic;
    onDelete?: () => void;
    repairLines: ById<IRepairLine>;
};

export const MechanicInfo: React.FC<IMechanicInfo> = React.memo((props: IMechanicInfo) => {
    const assigned = isMechanicAssigned(props.mechanic, props.repairLines);

    return <LAGrid className={(assigned) ? "green-border text-center" : "text-center"} spacing={1}>

        <LAGridItem xs={12}>
            <strong>{props.mechanic ? props.mechanic.firstName + " " + props.mechanic.lastName : ""}</strong>

            {props.onDelete && <LAIconButton
                label="Delete"
                onClick={props.onDelete}
                icon={<DeleteIcon color={RED_COLOR} width={20} height={20} />}
            />}

        
        </LAGridItem>

        {assigned && <LAGridItem xs={12}>
            <strong>Units: </strong>
            <span>{assigned}</span>
        </LAGridItem>}

    </LAGrid>
});

interface IRepairLineInfo {
    repairLine: IRepairLine;
};

export const RepairLineInfo: React.FC<IRepairLineInfo> = React.memo((props: IRepairLineInfo) => {
    // console.log(props.repairLine)
    return <LAGrid className="text-center">
        <LAGridItem xs={12}>
            <a href={ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + props.repairLine.id} target='_blank' style={{ color: 'blue', textDecoration: 'underline' }} rel="noreferrer"><strong>
                {props.repairLine.display} - {props.repairLine.site_Name} {props.repairLine.make !== null ? `- ${props.repairLine.make}` : ""} {props.repairLine.model !== null ? `- ${props.repairLine.model}` : ""}
            </strong> </a>
            
            <span>
                <br />
                {props.repairLine.components + " - "}
            </span>

            <span className="red-text">
                {props.repairLine.priority}
            </span>
        </LAGridItem>
    </LAGrid>
});