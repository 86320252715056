import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_MOVE_REQUEST_HISTORY_REQUEST, IMoveRequestHistory } from "./getMoveRequestHistoryConstants";
import { IADD_ATTACHMENT_MOVE_REQUEST } from "../addAttachmentMove/addAttachmentMoveConstants";
import { IAddAttachmentMoveSuccessAction } from "../addAttachmentMove/addAttachmentMoveActions";
import { IUpdateAttachmentMoveSuccessAction } from "../updateAttachmentMove/updateAttachmentMoveActions";
import { IUPDATE_ATTACHMENT_MOVE_REQUEST } from "../updateAttachmentMove/updateAttachmentMoveConstants";
import { IGetMoveRequestHistoryLoadAction, IGetMoveRequestHistoryLoadFailedAction, IGetMoveRequestHistorySuccessAction } from "./getMoveRequestHistoryActions";

type Actions =
    | IGetMoveRequestHistoryLoadAction
    | IGetMoveRequestHistorySuccessAction
    | IGetMoveRequestHistoryLoadFailedAction
    | IAddAttachmentMoveSuccessAction
    | IUpdateAttachmentMoveSuccessAction
    | IFlushDataSuccessAction;

export const GetMoveRequestHistoryReducer = (state: Server<SurewayAPIResponse<ById<IMoveRequestHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMoveRequestHistory>>> => {
    switch (action.type) {
        case IGET_MOVE_REQUEST_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_MOVE_REQUEST_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MOVE_REQUEST_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};