import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST, IGetRepairLineVersionHistory, IGetRepairLineVersionHistoryRequest } from "./getRepairLineVersionHistoryConstants";

export interface IGetRepairLineVersionHistoryLoadAction {
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.REQUEST;
    data: IGetRepairLineVersionHistoryRequest
}
export const getRepairLineVersionHistoryLoadAction = (data: IGetRepairLineVersionHistoryRequest): IGetRepairLineVersionHistoryLoadAction => ({
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineVersionHistorySuccessAction {
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>;
}
export const getRepairLineVersionHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>): IGetRepairLineVersionHistorySuccessAction => ({
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineVersionHistoryLoadFailedAction {
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getRepairLineVersionHistoryLoadFailedAction = (message: string): IGetRepairLineVersionHistoryLoadFailedAction => ({
    type: IGET_REPAIR_LINE_VERSION_HISTORY_REQUEST.FAILED,
    message
});
