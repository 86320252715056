import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_JOB_REQUEST, IUpdateJobRequest } from "./updateJobConstants";


export interface IUpdateJobLoadAction {
    type: IUPDATE_JOB_REQUEST.REQUEST;
    data: IUpdateJobRequest
}
export const updateJobLoadAction = (data: IUpdateJobRequest): IUpdateJobLoadAction => ({
    type: IUPDATE_JOB_REQUEST.REQUEST,
    data
});
export interface IUpdateJobSuccessAction {
    type: IUPDATE_JOB_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateJobLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateJobSuccessAction => ({
    type: IUPDATE_JOB_REQUEST.SUCCESS,
    message
});
export interface IUpdateJobLoadFailedAction {
    type: IUPDATE_JOB_REQUEST.FAILED;
    message: string;
}
export const updateJobLoadFailedAction = (message: string): IUpdateJobLoadFailedAction => ({
    type: IUPDATE_JOB_REQUEST.FAILED,
    message
});
