import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_SOCT_REQUEST, IUpdateSOCTRequest } from "./updateSOCTConstants";

export interface IUpdateSOCTLoadAction {
    type: IUPDATE_SOCT_REQUEST.REQUEST;
    data: IUpdateSOCTRequest
}
export const updateSOCTLoadAction = (data: IUpdateSOCTRequest): IUpdateSOCTLoadAction => ({
    type: IUPDATE_SOCT_REQUEST.REQUEST,
    data
});
export interface IUpdateSOCTSuccessAction {
    type: IUPDATE_SOCT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateSOCTLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateSOCTSuccessAction => ({
    type: IUPDATE_SOCT_REQUEST.SUCCESS,
    message
});
export interface IUpdateSOCTLoadFailedAction {
    type: IUPDATE_SOCT_REQUEST.FAILED;
    message: string;
}
export const updateSOCTLoadFailedAction = (message: string): IUpdateSOCTLoadFailedAction => ({
    type: IUPDATE_SOCT_REQUEST.FAILED,
    message
});
