import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import PageSpacing from "../shared/pageSpacing";
import LAGrid from "../shared/grid";
import LAGridItem from "../shared/gridList";
import { LADevExtremeGrid } from "../shared/devExtreme";
import { undefinedFunction } from "../shared/constExports";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../redux/server";
import { IDispatch, IStore } from "../../redux/reducers";
import { IMoveRequest, IMoveRequestRequest } from "../../redux/external/getMoveRequest/getMoveRequestConstants";
import { getMoveRequests } from "../../redux/external/getMoveRequest/getMoveRequestAccessor";
import { getMoveRequestLoadAction } from "../../redux/external/getMoveRequest/getMoveRequestActions";
import styled from "styled-components";
import { LACenteredLoading } from "../shared/loading";
import { LAPaperWithLessPadding } from "../shared/paper";

import { BLACK_COLOR, DARK_GREY_COLOR, DARK_RED_COLOR, EXTRA_DARK_GREY_COLOR, RED_COLOR, WHITE_COLOR } from "../shared/theme";



interface IExternalMoveRequestStoreProps {
    getMoves: Server<IMoveRequest[]>;
};

interface IExternalMoveRequestDispatchProps {
    getMoveRequest: (request: IMoveRequestRequest) => unknown;
};


interface IExternalMoveRequestOwnProps {

};

interface IExternalMoveRequestState {
    fullScreen: boolean;
    currentPage: number;
    hitLastPage: boolean;
    scrollPosition: number;
    scrollingDown: boolean;
    secondsLeft: number;
};

const ExternalMoveRequestStyles = styled(LAPaperWithLessPadding)`
    .pull-right {
        position: absolute;
        right: 3%;
        height: 3%;
        top: 2%;
    };
    .dx-row dx-column-lines dx-header-row
    {
        position: fixed;        
    }

    .dx-datagrid-headers .dx-header-row {  
        font-weight: bolder;
        font-size: 16px !important;
        color: ${WHITE_COLOR} !important;
        background-color: ${EXTRA_DARK_GREY_COLOR} !important;
    };
    
    .red-text {
        color: ${RED_COLOR};
    };

    .dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
        font-size: 12px !important;
    };
    tbody
    {
        font-weight: bolder;
        color: ${BLACK_COLOR} !important;
    }
    tbody tr:nth-child(odd) {
        background-color: ${DARK_GREY_COLOR} !important;
        color:${BLACK_COLOR} !important;
      }

      tbody tr:nth-child(even) {
        background-color: ${WHITE_COLOR} !important;
        color:${BLACK_COLOR} !important;
      }

      .timer{
        font-weight: bolder;
        color: ${DARK_RED_COLOR} !important;
        font-size: 14px !important;
      }
`;


type IExternalMoveRequestProps = RouteComponentProps
    & IExternalMoveRequestStoreProps
    & IExternalMoveRequestDispatchProps
    & IExternalMoveRequestOwnProps;

class ExternalMoveRequest extends PureComponent<IExternalMoveRequestProps, IExternalMoveRequestState> {
    private pagination: any = null;

    public constructor(props: IExternalMoveRequestProps) {
        super(props);
        this.state = {
            secondsLeft: 120,
            fullScreen: false,
            scrollPosition: 0,
            scrollingDown: true,
            currentPage: 0,
            hitLastPage: false,
        };
    }

    public componentDidMount(): void {
        this.callServer();
        setTimeout(function () {
            window.location.reload();
        }, 120000);
    };

    public componentDidUpdate(prevProps: IExternalMoveRequestProps): void {
        if (this.props !== prevProps)
            this.callServer();

        if (this.props.getMoves !== prevProps.getMoves) {
            if (hasPayload(this.props.getMoves)) {
                this.startAutoNavigation();
            }
        }
    };

    public componentWillUnmount(): void {
        clearInterval(this.pagination);
    };

    public startAutoNavigation = (): void => {
        if (hasPayload(this.props.getMoves)) {
            const records = this.props.getMoves.payload.length;
            this.pagination = setInterval(() => {
                const { currentPage, hitLastPage } = this.state;
                const totalPages = records % 20 === 0 ? Math.round(records / 20) : Math.round(records / 20) + 1;
                if (totalPages < 1) {
                    clearInterval(this.pagination);
                } else {
                    if (hitLastPage === false) {

                        this.setState({
                            currentPage: currentPage + 1,
                            hitLastPage: (currentPage + 1 === totalPages - 1) ? true : false
                        });
                    } else if (hitLastPage === true) {
                        window.location.reload();
                    };
                }
            }, 20000);
        }
    };

    public render(): ReactNode {

        const { getMoves } = this.props;
        const { currentPage } = this.state;
        const { kind } = getMoves;
        const data = hasPayload(getMoves) ? getMoves.payload.filter(x => { return !x.combined_Unit_Number.includes("Test") && !x.combined_Unit_Number.includes("test") }) : [];

        return (
            <PageSpacing title="Move Request" description="Move Request" fixedSpaceOnSmallerScreens={true}>
                <ExternalMoveRequestStyles>
                    <LAGrid>

                        <LAGridItem xs={12} className="text-left">
                            <span className="timer">Last Refreshed: {new Date().toLocaleDateString('en-US') + ", " + new Date().getHours() + ':' + new Date().getMinutes()} </span>
                            <hr />
                        </LAGridItem>

                        {(kind === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Please wait... Loading data" />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                            <LACenteredLoading message="Failed to load data..." />
                        </LAGridItem>}

                        {(kind === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                            <LADevExtremeGrid
                                paging={20}
                                data={data}
                                height={1100}
                                searchPanel={false}
                                filterHeader={false}
                                columnChoose={false}
                                pageNumber={currentPage}
                                id="ExternalMoveRequest"
                                onClick={undefinedFunction}
                                columns={[
                                    { name: "combined_Unit_Number", caption: "Unit #", type: "string" },
                                    { name: "make", caption: "Make", type: "string" },
                                    { name: "model", caption: "Model", type: "string" },
                                    { name: "details", caption: "Details", type: "string" },
                                    { name: "from_Location", caption: "From Location", type: "string" },
                                    { name: "from_Job", caption: "From Job #", type: "string" },
                                    { name: "to_Location", caption: "To Location", type: "string" },
                                    { name: "job", caption: "To Job #", type: "string" },
                                    { name: "completed_Date", caption: "Complete By", type: "date", sortAsc: true, width: 150 },
                                    { name: "comments", caption: "Request Details", type: "string" },
                                    { name: "status", caption: "Status", type: "string" },
                                    { name: "driver", caption: "Driver", type: "string" },
                                    { name: "dispatch_Notes", caption: "Dispatch Notes", type: "string" },
                                    { name: "pilot_Names", caption: "Pilots", type: "string" },
                                ]}
                            />
                        </LAGridItem>}

                    </LAGrid>
                </ExternalMoveRequestStyles>
            </PageSpacing>
        );
    }

    private callServer = (): void => {
        if (isNotLoaded(this.props.getMoves))
            this.requestToGetMoves();
    };

    private requestToGetMoves = (): void => {
        this.props.getMoveRequest({
            token: "b3123a8f-3437-4d36-8dd3-1206ae066503",
            request: {
                View: "In Progress"
            }
        });
    }

}

const mapStateToProps = (state: IStore): IExternalMoveRequestStoreProps => ({
    getMoves: getMoveRequests(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IExternalMoveRequestDispatchProps => ({
    getMoveRequest: (request: IMoveRequestRequest): unknown => dispatch(getMoveRequestLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalMoveRequest);