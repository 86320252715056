
export enum IREJECT_ST_TIMESHEET_REQUEST {
    REQUEST = "rejectSTTimesheet/REJECT_ST_TIMESHEET_REQUEST",
    SUCCESS = "rejectSTTimesheet/REJECT_ST_TIMESHEET_SUCCESS",
    FAILED = "rejectSTTimesheet/REJECT_ST_TIMESHEET_FAILED"
};

export interface IRejectSTTimesheetRequest {
    token: string;
    request: IRejectSTTimesheet;
};

export interface IRejectSTTimesheet {
    Modified_By: string;
    Timesheet_ID: number;
    Rejection_Notes: string
};
