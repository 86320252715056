import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "./saveDriversAssignmentConstants";
import { ISaveDriversAssignmentLoadAction, ISaveDriversAssignmentLoadFailedAction, ISaveDriversAssignmentSuccessAction } from "./saveDriversAssignmentActions";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    | ISaveDriversAssignmentLoadAction
    | ISaveDriversAssignmentSuccessAction
    | ISaveDriversAssignmentLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveDriversAssignmentReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.REQUEST:
            return loading;

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};