import { IVendorOrder, IVendorOrderWork } from "../getVendorOrders/getVendorOrdersConstants";

export enum IGET_VENDOR_ORDER_BY_ID_REQUEST {
    REQUEST = "getVendorOrderByID/GET_VENDOR_ORDER_BY_ID_REQUEST",
    SUCCESS = "getVendorOrderByID/GET_VENDOR_ORDER_BY_ID_SUCCESS",
    FAILED = "getVendorOrderByID/GET_VENDOR_ORDER_BY_ID_FAILED"
};

export interface IGetVendorOrderByIDRequest {
    token: string;
    request: {
      id: number;
    };
};
  
export interface IGetVendorOrderByID {
    id: number;
    requested_By: string;
    date: string;
    po: string;
    vendor: string;
    job_ID?: number;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
    items: IVendorOrderWork[];
}

