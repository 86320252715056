
export enum IADD_HAZARD_NEW_REQUEST {
    REQUEST = "addHazardNew/ADD_HAZARD_NEW_REQUEST",
    SUCCESS = "addHazardNew/ADD_HAZARD_NEW_SUCCESS",
    FAILED = "addHazardNew/ADD_HAZARD_NEW_FAILED"
};

export interface IAddHazardNewRequest {
    token: string;
    request: IAddUpdateHazardNew;
};

export interface IAddUpdateHazardNew {
    ID: number;
    Hazard: string;
    Active: string;
    Departments: string;
    Department_ID: number;
    Type_ID: number;
    Control_Plans: string[];
    Created_By: string;
    Modified_By: string;
}
