import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { ById, IIdName, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../redux/server";
import { SUCCESS_MESSAGE, YesOrNo, ZEROTH, getTokenFromUrl, pageAccessCheck, undefinedFunction } from "../../../shared/constExports";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { IGetServiceTruckRequest, IServiceTruck } from "../../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksConstants";
import { IAddServiceTruckRequest } from "../../../../redux/field/dispatch/addServiceTruck/addServiceTruckConstants";
import { IUpdateServiceTruckRequest } from "../../../../redux/field/dispatch/updateServiceTruck/updateServiceTruckConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { LAPaperWithPadding } from "../../../shared/paper";
import { NotApplicable, ReadOnly } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { ArrowLeftIcon } from "../../../shared/icons";
import { addServiceTruckStatus } from "../../../../redux/field/dispatch/addServiceTruck/addServiceTruckAccessor";
import { addServiceTruckLoadAction } from "../../../../redux/field/dispatch/addServiceTruck/addServiceTruckActions";
import { getServiceTrucks } from "../../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksAccessor";
import { getServiceTrucksLoadAction } from "../../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksActions";
import { updateServiceTruckStatus } from "../../../../redux/field/dispatch/updateServiceTruck/updateServiceTruckAccessor";
import { updateServiceTruckLoadAction } from "../../../../redux/field/dispatch/updateServiceTruck/updateServiceTruckActions";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IStore, IDispatch } from "../../../../redux/reducers";
import { ROUTE } from "../../../routes";
import LAAutoComplete from "../../../shared/autoComplete";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LAErrorBox from "../../../shared/errorBox";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import LATextArea from "../../../shared/textArea";
import { getFieldLookups } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { IDriverList, IFieldLookup, IFieldLookupRequest, IServiceTruckList } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";

const RequiredFields: string[] = ["unit_ID", "driver_UPN"];

interface IAddUpdateServiceTruckComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addServiceTruckStatus: Server<SurewayAPIResponse<string>>;
    updateServiceTruckStatus: Server<SurewayAPIResponse<string>>;
    serviceTrucks: Server<SurewayAPIResponse<ById<IServiceTruck>>>;
};

interface IAddUpdateServiceTruckComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    addServiceTruckRequest: (data: IAddServiceTruckRequest) => unknown;
    getServiceTrucksRequest: (data: IGetServiceTruckRequest) => unknown;
    updateServiceTruckRequest: (data: IUpdateServiceTruckRequest) => unknown;
};

interface IAddUpdateServiceTruckOwnProps {
    id?: string;
};

interface IAddUpdateServiceTruckComponentState {
    data: IServiceTruck;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateServiceTruckStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateServiceTruckComponentProps =
    RouteComponentProps
    & IAddUpdateServiceTruckOwnProps
    & IAddUpdateServiceTruckComponentStoreProps
    & IAddUpdateServiceTruckComponentDispatchProps;

class AddUpdateServiceTruck extends PureComponent<IAddUpdateServiceTruckComponentProps, IAddUpdateServiceTruckComponentState> {

    public constructor(props: IAddUpdateServiceTruckComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                driver_UPN: "",
                unit_ID: 0,
                swamper_ID: 0,
                notes: "",
                unit_No: "",
                driver_Name: "",
                swamper_Name: "",
                active: YesOrNo[0].name,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateServiceTruckComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addServiceTruckStatus !== prevProps.addServiceTruckStatus) {

                if (this.props.addServiceTruckStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addServiceTruckStatus.message });

                if (hasPayload(this.props.addServiceTruckStatus) && this.props.addServiceTruckStatus.payload.message !== SUCCESS_MESSAGE)
                    this.setState({ serverError: this.props.addServiceTruckStatus.payload.message });

                if (hasPayload(this.props.addServiceTruckStatus) && this.props.addServiceTruckStatus.payload.message === SUCCESS_MESSAGE && this.props.addServiceTruckStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateServiceTruckStatus !== prevProps.updateServiceTruckStatus) {

                if (this.props.updateServiceTruckStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateServiceTruckStatus.message });

                if (hasPayload(this.props.updateServiceTruckStatus) && this.props.updateServiceTruckStatus.payload.message !== SUCCESS_MESSAGE)
                    this.setState({ serverError: this.props.updateServiceTruckStatus.payload.message });

                if (hasPayload(this.props.updateServiceTruckStatus) && this.props.updateServiceTruckStatus.payload.message === SUCCESS_MESSAGE && this.props.updateServiceTruckStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { token, addServiceTruckStatus, updateServiceTruckStatus, getLookup } = this.props;
        const getRole = pageAccessCheck(token, "dispatch");
        const disabled = getRole === ReadOnly ? true : undefined;
        const drivers = hasPayload(getLookup) ? getLookup.payload.response.drivers : [];
        const swampers = hasPayload(getLookup) ? getLookup.payload.response.swampers : [];
        const units = hasPayload(getLookup) ? getLookup.payload.response.service_Trucks : [];

        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);
        const onUnit = (event: unknown, value: IServiceTruckList): void => this.handleNumberDropDown("unit_ID", value !== null ? value.id : data.unit_ID);
        const onSwamper = (event: unknown, value: IIdName): void => this.handleNumberDropDown("swamper_ID", value !== null ? value.id : undefined);
        const onDriver = (event: unknown, value: IDriverList): void => this.handleChange("driver_UPN", value !== null ? value.upn : "");

        return (
            <AddUpdateServiceTruckStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>SERVICE TRUCK</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                multiple={false}
                                option={units}
                                getOptionLabel="unit_Number"
                                autoHighlight={true}
                                onChange={onUnit}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Unit #"
                                selectionRemove={undefinedFunction}
                                disabled={data.id > 0 ? true : disabled}
                                errorText={errors["unit_ID"] ? errors["unit_ID"].message : undefined}
                                value={data.unit_ID ? units.find(q => q.id === data.unit_ID) : null}
                                defaultValue={data.unit_ID ? units.find(q => q.id === data.unit_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={drivers}
                                autoHighlight={true}
                                onChange={onDriver}
                                dropDownPlaceHolder="Driver"
                                filterSelectedOptions={true}
                                getOptionLabel="display_Name"
                                selectionRemove={undefinedFunction}
                                errorText={errors["driver_UPN"] ? errors["driver_UPN"].message : undefined}
                                value={data.driver_UPN ? drivers.find(q => q.upn === data.driver_UPN) : null}
                                defaultValue={data.driver_UPN ? drivers.find(q => q.upn === data.driver_UPN) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={swampers}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onSwamper}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Swamper"
                                selectionRemove={undefinedFunction}
                                errorText={errors["swamper_ID"] ? errors["swamper_ID"].message : undefined}
                                value={data.swamper_ID ? swampers.find(q => q.id === data.swamper_ID) : null}
                                defaultValue={data.swamper_ID ? swampers.find(q => q.id === data.swamper_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                minRows={3}
                                rowsMax={4}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="notes"
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>


                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addServiceTruckStatus.kind} successMessage="Service Due has been added successfully" />
                <RequestStatus requestStatus={updateServiceTruckStatus.kind} successMessage="Service Due has been updated successfully" />
            </AddUpdateServiceTruckStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.SERVICE_TRUCKS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {
        const { id, swamper_ID, unit_ID, driver_UPN, notes, active, created_By } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addServiceTruckRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Swamper_ID: swamper_ID ?? 0,
                        Unit_ID: unit_ID ?? 0,
                        Driver_UPN: driver_UPN ?? "",
                        Notes: notes,
                        Active: active,
                        Created_By: this.props.token.payload.response.upn
                    }
                });
            } else {
                this.props.updateServiceTruckRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Swamper_ID: swamper_ID ?? 0,
                        Unit_ID: unit_ID ?? 0,
                        Driver_UPN: driver_UPN ?? "",
                        Notes: notes,
                        Active: active,
                        Created_By: created_By
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;
            errors = this.errorChecker(name, value, errors, rules);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleNumberDropDown = (name: string, value?: number): void => {
        let errors = { ...this.state.errors };

        let rules: IFieldValidatorProps = {};

        if (RequiredFields.includes(name)) {
            rules.required = true;
            errors = this.errorChecker(name, (value && (value !== 0)) ? value.toString() : "", errors, rules);
        };

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Service_Trucks"
                }
            });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "dispatch") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.token))
                        this.getLook();

                    if (query.id !== "0") {
                        if (hasPayload(this.props.serviceTrucks)) {
                            const data = this.props.serviceTrucks.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getServiceTrucksRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                } else {

                    this.props.history.push({
                        pathname: ROUTE.UNAUTHORIZED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, rules: IFieldValidatorProps): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateServiceTruckComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    serviceTrucks: getServiceTrucks(state),
    addServiceTruckStatus: addServiceTruckStatus(state),
    updateServiceTruckStatus: updateServiceTruckStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateServiceTruckComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addServiceTruckRequest: (data: IAddServiceTruckRequest) => dispatch(addServiceTruckLoadAction(data)),
    getServiceTrucksRequest: (data: IGetServiceTruckRequest) => dispatch(getServiceTrucksLoadAction(data)),
    updateServiceTruckRequest: (data: IUpdateServiceTruckRequest) => dispatch(updateServiceTruckLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateServiceTruck);