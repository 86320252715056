
export enum IGET_MACHINE_RECEIVER_REQUEST {
    REQUEST = "getMachineReceiver/GET_MACHINE_RECEIVER_REQUEST",
    SUCCESS = "getMachineReceiver/GET_MACHINE_RECEIVER_SUCCESS",
    FAILED = "getMachineReceiver/GET_MACHINE_RECEIVER_FAILED"
};

export interface IMachineReceiverRequest {
    token: string;
};

export interface IMachineReceiver {
    id: number;
    unit_No: string;
    serial_No: string;
    make: string;
    model: string;
    version: number;
    status: string;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};