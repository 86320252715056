import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IDispatch, IStore } from "../../../../../redux/reducers";
import { getToken } from "../../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../../../redux/getToken/getTokenConstants";
import { LAPaperWithPadding } from "../../../../shared/paper";
import { MEDIA_QUERY_PHONE } from "../../../../shared/theme";
import { ById, SurewayAPIResponse } from "../../../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../../redux/server";
import SOCTHome from "../../equipmenttrackerhome";
import LAGridItem from "../../../../shared/gridList";
import LAGrid from "../../../../shared/grid";
import LAAutoComplete from "../../../../shared/autoComplete";
import { YesOrNo, ZEROTH, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../../../shared/constExports";
import { DevExtremePopupLookup } from "../../../../shared/devExtremePopupLookup";
import { IETLookups, IETLookupsRequest, IUnits, IWS } from "../../../../../redux/field/equipmentTracker/getETLookups/getETLookupsConstants";
import { getETLookupsLoadAction } from "../../../../../redux/field/equipmentTracker/getETLookups/getETLookupsActions";
import { getETLookups } from "../../../../../redux/field/equipmentTracker/getETLookups/getETLookupsAccessor";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../../../shared/fieldValidator";
import { LAButton, LASaveAndCancelButton } from "../../../../shared/buttons";
import { DataGrid } from "devextreme-react";
import { Column, Editing, Paging, Selection } from "devextreme-react/data-grid";
import { Box, Button, InputLabel, Modal } from "@mui/material";
import { MobileBoxStyle } from "../../../../shared/styles";
import LATextField from "../../../../shared/textField";
import { IForeman } from "../../../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { LACheckBox } from "../../../../shared/checkBox";
import LATextArea from "../../../../shared/textArea";
import { IBulkMoveRequest } from "../../../../../redux/field/equipmentTracker/bulkMove/bulkMoveConstants";
import { BulkMove } from "../../../../../redux/field/equipmentTracker/bulkMove/bulkMoveAccessor";
import { bulkMoveLoadAction } from "../../../../../redux/field/equipmentTracker/bulkMove/bulkMoveActions";
import { NotApplicable, ReadOnly } from "../../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../../../routes";
import LAErrorBox from "../../../../shared/errorBox";
import LALinkButton from "../../../../shared/linkButton";
import { handleTokenAccess } from "../../../../shared/etTokenAccessWrapper";
import { getCurrentSiteForeman } from "../../api/equipmenttracker/equipmentTrackerAPI";

const RequiredFields: string[] = ["requested_By", "completed_By"];

interface IBulkMovesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getETLookup: Server<SurewayAPIResponse<IETLookups>>;
    addBulkMoveStatus: Server<SurewayAPIResponse<string>>;
  };
  
  interface IBulkMovesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getETLookupsRequest: (data: IETLookupsRequest) => unknown;
    addBulkMoveRequest: (data: IBulkMoveRequest) => unknown;
  };
  
  interface IBulkMovesOwnProps {
    history:any
  };
  
  interface IBulkMovesState {
    from_location: IWS | undefined;
    to_location: IWS | undefined;
    selected_Units: IUnits[];
    data: {
        requested_By: string,
        pickup_Contact:string,
        dropoff_Contact: string,
        completed_By:string,
        drive_Over:boolean,
        moved_Previously:boolean,
        completed: boolean,
        request_Details:string, 
        send_Email:boolean,
    },
    errors: ById<IFieldErrorKeyValue>;
    serverError: string;
    modal: boolean;
  };
  
  const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    width: '60%',
    height:'auto',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
  };

  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
  }
  // const BulkMovesStyles = styled(LAPaperWithPadding)`
  //     margin: 10px 10px;
  
  //     @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
  //         margin: 10px 10px;
  //     };
  
  // `;
  
  type IBulkMovesProps = RouteComponentProps
      & IBulkMovesStoreProps
      & IBulkMovesDispatchProps
      & IBulkMovesOwnProps;

class BulkMoves extends PureComponent<IBulkMovesProps, IBulkMovesState> {
    public constructor(props: IBulkMovesProps) {
        super(props);
        this.state = {
          from_location: undefined,
          to_location:undefined,
          selected_Units: [],
          data : {
            requested_By: "",
            pickup_Contact: "",
            dropoff_Contact: "",
            completed_By:"",
            drive_Over:false,
            moved_Previously:false,
            completed: false,
            request_Details:"", 
            send_Email:false,
          },
          errors: {},
          serverError: "",
          modal: false
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.callServer();
                         
      };
    
    public componentDidUpdate(prevProps: IBulkMovesProps): void {
        if (this.props !== prevProps){
          this.callServer();

          if (this.props.addBulkMoveStatus !== prevProps.addBulkMoveStatus) {
            if (this.props.addBulkMoveStatus.kind === STATUS_ENUM.FAILED)
                this.setState({ serverError: this.props.addBulkMoveStatus.message });

            if (hasPayload(this.props.addBulkMoveStatus) && this.props.addBulkMoveStatus.kind === STATUS_ENUM.SUCCEEDED) {
              
                if (window.location.href.includes("token")) {
                    window.parent.postMessage("goback");
                }
                else {
                    this.handleCancel();
                }
            }
        };
        }
    };

    public render(): ReactNode {
        const { errors, from_location, to_location, data, serverError } = this.state;
        const { getToken, getETLookup } = this.props;
        const getRole = pageAccessCheck(getToken, "etAccess");
        const disabled = getRole === NotApplicable ? true : undefined;
        const wssList = hasPayload(getETLookup) ? getETLookup.payload.response.wssList : [];
        const foremanList = hasPayload(getETLookup) ? getETLookup.payload.response.foremanList : [];
        const onFromLocation = (value: string) => this.handleFromLocationChange(value);
        const onToLocation = (value: string)=> this.handleToLocationChange(value);
        const onRequestedBy = (event:unknown, value: IForeman): void => this.handleChange("requested_By", value !== null ? value.display_Name : "");
        const onPickupContact = (event:unknown, value: IForeman): void => this.handleChange("pickup_Contact", value !== null ? value.display_Name : "");
        const onDropoffContact = (event:unknown, value: IForeman): void => this.handleChange("dropoff_Contact", value !== null ? value.display_Name : "");
        const onCompletedBy = (event:unknown, value: string): void => this.handleChange("completed_By", value !== null ? value : "");
        const onSendEmailChange = (value: boolean): void => this.handleCheckBox("send_Email", value !== null ? value : data.send_Email);
        const onDriveOverChange = (value: boolean): void => this.handleCheckBox("drive_Over", value !== null ? value : data.drive_Over);
        const onMovedChange = (value: boolean): void => this.handleCheckBox("completed", value !== null ? value : data.completed);
        const onMovedPrevChange = (value: boolean): void => this.handleCheckBox("moved_Previously", value !== null ? value : data.moved_Previously);
        const disabledMoveSelectedItemBtn = (from_location && to_location && this.state.selected_Units.length > 0) ? undefined : true;
        const disableSave = (Object.values(errors).length > 0) ? true : disabled;

        return (
          <>
              <SOCTHome history={this.props.history}></SOCTHome>
              {(getRole === NotApplicable) &&  <LAErrorBox text={"User Authentication failed! Please contact IT.Developers@sureway.ca for assistance"} />}
              {(getRole !== NotApplicable) &&
              <LAPaperWithPadding>
                <LAGrid spacing={2}>
                  <LAGridItem xs={12} sm={12} md={12} lg={12} className="text-center">
                    <h2>Bulk Move Request</h2>
                    <hr />
                  </LAGridItem>
                  <LAGridItem xs={12} sm={6} md={12} className="text-center">
                    <LAGrid>
                      <LAGridItem xs={12} sm={6} md={6} className="text text">
                        <strong>From Location</strong>
                        <DevExtremePopupLookup
                                  data={wssList ? wssList: []}
                                  id="wss-search"
                                  placeHolder="From Location"
                                  disabled={disabled}
                                  displayExp="site"
                                  name="wsS_ID"
                                  errorName="wsS_ID"
                                  errors={errors}
                                  currentVal={from_location?.wsS_ID}
                                  onClick={onFromLocation}
                                  columns={[
                                      { name: "site", caption: "Site", type: "string" },
                                      { name: "job", caption: "Job", type: "string" },
                                      { name: "site_Address", caption: "Site Address", type: "string" },
                                      { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                  ]}
                              />
                      </LAGridItem>
                      <LAGridItem xs={12} sm={6} md={6} className="text text">
                        <strong>To Location</strong>
                        <DevExtremePopupLookup
                          data={wssList ? wssList: []}
                          id="wss-search"
                          placeHolder="To Location"
                          disabled={disabled}
                          displayExp="site"
                          name="wsS_ID"
                          errorName="wsS_ID"
                          errors={errors}
                          currentVal={to_location?.wsS_ID}
                          onClick={onToLocation}
                          columns={[
                              { name: "site", caption: "Site", type: "string" },
                              { name: "job", caption: "Job", type: "string" },
                              { name: "site_Address", caption: "Site Address", type: "string" },
                              { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                          ]}
                        />         
                      </LAGridItem>
                    </LAGrid>
                  </LAGridItem>
                  <LAGridItem xs={12}>
                      <DataGrid
                        key="bulkMoves"
                        keyExpr="unit_Number"
                        showBorders={true}
                        columnAutoWidth={true}
                        dataSource={from_location?from_location.units: []}
                        onSelectionChanged={this.onSelectionChanged}
                      >
                        <Paging enabled={false} />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            onChangesChange={this.onChangesChange}
                        />

                        <Column dataField="unit_Number" caption="Unit #" dataType="string" allowEditing={false} />
                        <Column dataField="make" caption="Make" dataType="string" allowEditing={false} />
                        <Column dataField="model" caption="Model" dataType="string" allowEditing={false} />
                        <Column dataField="details" caption="Details" dataType="string" allowEditing={false} />
                        {/* <Column dataField="move" caption="Move" dataType="boolean" /> */}
                        <Selection mode="multiple" showCheckBoxesMode="always" />

                    </DataGrid>                                                 
                  </LAGridItem>
                  <LAGridItem xs={12} className="text-center">
                    <LAButton
                      disabled={disabledMoveSelectedItemBtn}
                      label="Move Selected Items" 
                      onClick={this.handleMoveSeletedItemsBtn}
                    />                                                    
                  </LAGridItem>
                </LAGrid>
              
                { this.state.modal && 
                  <Modal 
                    id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    sx={{overflow:'auto'}}
                    closeAfterTransition
                    BackdropProps={{
                      timeout: 500,
                    }}>
                      <Box sx={window.innerWidth <= 1056 ? MobileBoxStyle:BoxStyle}>
                        <Button sx={ExitButtonStyle} onClick={this.handleModalClose}>Close</Button>
                        <br></br><br></br><br></br>
                        <LAGrid spacing={2}>
                          <LAGridItem xs={12} sm={6} md={12} >
                            <LAGrid>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <strong>Requested By</strong>
                                <LAAutoComplete
                                    multiple={false}
                                    option={foremanList}
                                    getOptionLabel="display_Name"
                                    autoHighlight={true}
                                    onChange={onRequestedBy}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Requested By"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["requested_By"] ? errors["requested_By"].message : undefined}
                                    value={data.requested_By ? foremanList?.find(q => q.display_Name === data.requested_By) : null}
                                    defaultValue={data.requested_By ? foremanList?.find(q => q.display_Name === data.requested_By) : null}
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LALinkButton 
                                    label="Assign to me"
                                    onClick={() => this.handlePickUpDropoffContact("pickup_Contact")}
                                />
                                <LAAutoComplete
                                    multiple={false}
                                    option={foremanList}
                                    getOptionLabel="display_Name"
                                    autoHighlight={true}
                                    onChange={onPickupContact}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Pickup Contact"
                                    selectionRemove={undefinedFunction}
                                    value={data.pickup_Contact ? foremanList?.find(q => q.display_Name === data.pickup_Contact) : null}
                                    defaultValue={data.pickup_Contact ? foremanList?.find(q => q.display_Name === data.pickup_Contact) : null}
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4}>
                                <LALinkButton 
                                      label="Assign to me"
                                      onClick={() => this.handlePickUpDropoffContact("dropoff_Contact")}
                                  />
                                <LAAutoComplete
                                    multiple={false}
                                    option={foremanList}
                                    getOptionLabel="display_Name"
                                    autoHighlight={true}
                                    onChange={onDropoffContact}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Dropoff Contact"
                                    selectionRemove={undefinedFunction}
                                    value={data.dropoff_Contact ? foremanList?.find(q => q.display_Name === data.dropoff_Contact) : null}
                                    defaultValue={data.dropoff_Contact ? foremanList?.find(q => q.display_Name === data.dropoff_Contact) : null}
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4}>
                                <strong>Completed By</strong>
                                <LATextField
                                  id="completed_By"
                                  value={data.completed_By}
                                  fullWidth={true}
                                  variant="outlined"
                                  name="completed_By"
                                  label=""
                                  errorText={errors["completed_By"] ? errors["completed_By"].message : undefined}
                                  onChange={onCompletedBy} 
                                  type="date"
                                >
                                </LATextField>
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LACheckBox
                                    value={data.send_Email}
                                    name="send_Email"
                                    onChange={onSendEmailChange}
                                    label="Send Notification Email"
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LACheckBox
                                    value={data.drive_Over}
                                    name="drive_Over"
                                    onChange={onDriveOverChange}
                                    label="Drive Over/Non-Billable"
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LACheckBox
                                    value={data.completed}
                                    name="completed"
                                    onChange={onMovedChange}
                                    label="Moved"
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LACheckBox
                                    value={data.moved_Previously}
                                    name="moved_Previously"
                                    onChange={onMovedPrevChange}
                                    label="Moved Previously?"
                                />
                              </LAGridItem>
                              <LAGridItem xs={12} sm={6} md={4} >
                                <LATextArea
                                    minRows={3}
                                    rowsMax={4}
                                    name="request_Details"
                                    label="Request Details"
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={this.handleChange}
                                    value={data.request_Details ?? ""}
                                />
                              </LAGridItem>

                              {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                    <LAErrorBox text={serverError} />
                                </LAGridItem>}

                              <LAGridItem xs={12}>
                                <LASaveAndCancelButton
                                  fullWidth={true}
                                  saveButtonText="Save Bulk Moves"
                                  cancelButtonText="Close"
                                  disableSave={disableSave}
                                  onSave={this.handleBulkMoves}
                                  onCancel={this.handleModalClose}
                                />                                             
                              </LAGridItem>
                            </LAGrid>
                          </LAGridItem>
                        </LAGrid>
                      </Box>
                    </Modal>
                }
              </LAPaperWithPadding>}
          </>
        );
    }

    private handlePickUpDropoffContact = async(name:string) => {
      const foremanList = hasPayload(this.props.getETLookup) ? this.props.getETLookup.payload.response.foremanList : [];
      let currentUser = userName;
      if(window.location.href.includes("token")) {
        let upn = await handleTokenAccess(); 
        currentUser = upn;
      }
      // console.log(currentUser)
      let findRecord = foremanList?.find((e:IForeman)=>e.upn===currentUser)
      if(findRecord){
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            [name]: findRecord.display_Name
          }
        })
      }
    }

    private handleCheckBox = (name: string, value: boolean): void => {
      // console.log(name, value);
      const updatedData = {
        ...this.state.data,
        [name]: value,
      };

      if (name === "completed" && value === true) {
        updatedData.moved_Previously = false;
      } else if (name === "moved_Previously" && value === true) {
        updatedData.completed = false;
      }

      this.setState({
        ...this.state,
        data: updatedData,
      });

    };

    private handleChange = (name: string, value: string): void => {
      // console.log(name, value)
      let errors = { ...this.state.errors };
      
      if (RequiredFields.includes(name)) {
        errors = this.errorChecker(name, value, errors);
        // console.log(errors)
      }

      this.setState({
        ...this.state,
        data: {
            ...this.state.data,
            [name]: value,
        },
        errors
      });
    };

    private onSelectionChanged = (row: any): void => {
      // console.log(row.selectedRowsData);

      this.setState({
        ...this.state,
        selected_Units: row.selectedRowsData
      });
  };

    private onChangesChange = (updatedData: { data: any, key: string, type: string }[]): void => {
      updatedData.forEach((x: { data: any, key: string, type: string }) => {
        if(x.data.move === true) {
          const find = this.state.from_location?.units.find(unit => unit.unit_Number === x.key)
          if(find) {
            if(!this.state.selected_Units.includes(find)) {
              this.setState({ 
                ...this.state,
                selected_Units: [
                  ...this.state.selected_Units,
                  find
                ]
              })
            }
          }
        }
        else {
          const find = this.state.from_location?.units.find(unit => unit.unit_Number === x.key)
          if(find) {
            if(this.state.selected_Units.includes(find)) {
              const updatedList = this.state.selected_Units.filter((m:any) => m.unit_Number !== find.unit_Number)
              this.setState({ 
                ...this.state,
                selected_Units: updatedList
              })
            }
          }
        }
      })
    };

    private handleFromLocationChange  = async(value: string) => {
      if (value.length > 0) {
        const wssList = hasPayload(this.props.getETLookup) ? this.props.getETLookup.payload.response.wssList : [];
        const find = wssList?.find(x => x.site === value);
        // console.log(find)
        if(find) {
          const pickupContact = await getCurrentSiteForeman(find.wsS_ID);
          // console.log(pickupContact)
          this.setState({
            ...this.state,
            from_location: find,
            data: {
              ...this.state.data,
              pickup_Contact : pickupContact
            }
        });
        }
      }
    }

    private handleToLocationChange  = async(value: string) => {
      if (value.length > 0) {
        const wssList = hasPayload(this.props.getETLookup) ? this.props.getETLookup.payload.response.wssList : [];
        const find = wssList?.find(x => x.site === value);
        if(find) {
          const dropoffContact = await getCurrentSiteForeman(find.wsS_ID);
          this.setState({
            ...this.state,
            to_location: find,
            data: {
              ...this.state.data,
              dropoff_Contact : dropoffContact
            }
        });
        }
      }
    }

    private handleModalClose = () : void => {
      this.setState({
        ...this.state,
        data : {
          requested_By: "",
          pickup_Contact: this.state.data.pickup_Contact,
          dropoff_Contact: this.state.data.dropoff_Contact,
          completed_By:"",
          drive_Over:false,
          moved_Previously:false,
          request_Details:"", 
          completed:false,
          send_Email:false,
        },
        modal: false,
      })
      this.callServer();
    };

    private handleBulkMoves = () : void => {
      const { data, from_location, to_location, selected_Units } = this.state;
      const foremanList = hasPayload(this.props.getETLookup) ? this.props.getETLookup.payload.response.foremanList : [];
      const pickup_Contact = foremanList?.find(x => x.display_Name === this.state.data.pickup_Contact);
      const dropoff_Contact = foremanList?.find(x => x.display_Name === this.state.data.dropoff_Contact);
      const requested_By = foremanList?.find(x => x.display_Name === this.state.data.requested_By);

      if (hasPayload(this.props.getToken)) {
        const upn = this.props.getToken.payload.response.upn;

        let request = {
          Units: selected_Units.map((x:any) => x.unit_ID),
          Requested_By: requested_By ? requested_By.upn : "",
          From_Sub_Location_ID: from_location? from_location.wsS_ID : 0,
          To_Sub_Location_ID: to_location? to_location.wsS_ID : 0,
          Completed_Date: data.completed_By,
          PickUp_Contact: pickup_Contact? pickup_Contact.upn : "",
          DropOff_Contact: dropoff_Contact? dropoff_Contact.upn : "",
          Moved_Previously: data.moved_Previously === true ? YesOrNo[0].name : YesOrNo[1].name,
          Drive_Over: data.drive_Over === true ? YesOrNo[0].name : YesOrNo[1].name,
          Send_Email: data.send_Email === true ? YesOrNo[0].name : YesOrNo[1].name,
          Moved: data.completed === true ? YesOrNo[0].name : YesOrNo[1].name,
          Details: data.request_Details,     
        }
  
        // console.log(request);
        if (hasPayload(this.props.getToken)) {
          this.props.addBulkMoveRequest({
              token: this.props.getToken.payload.response.token,
              request: {
                ...request,
                Created_By: upn
              }
          });
        };
        this.setState({
          modal: false
        })
      }
    }

    private handleMoveSeletedItemsBtn = () : void => {
      this.setState({
        ...this.state,
        modal: true
      })
    };

    private handleCancel = (): void => {
      this.setState({ serverError: "" });

      window.location.reload();

      if (window.location.href.includes("token")) {
          window.parent.postMessage("goback");
      }
  };

    private callServer = (): void => {
      if (isNotLoaded(this.props.getToken)){
        this.props.getTokenRequest({
          request: {
            username: getTokenFromUrl(true) ? undefined : userName,
            user_token: getTokenFromUrl(true)
          }
        });
      }
      
      if (hasPayload(this.props.getToken)) {
        const errors: ById<IFieldErrorKeyValue> = {};
        RequiredFields.forEach((x) => {
          errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        });

        this.setState({
            errors
        });  

        if (pageAccessCheck(this.props.getToken, "etAccess") !== NotApplicable) {
          if (isNotLoaded(this.props.getETLookup) && hasPayload(this.props.getToken)) {
            this.getLook();
          }
        }
        else {
          this.props.history.push({
            pathname: ROUTE.INDEX,
            search: getTokenFromUrl(false)
          });
        }
      }
    };

    private getLook = (): void => {
      if (hasPayload(this.props.getToken)) {
        this.props.getETLookupsRequest({
            token: this.props.getToken.payload.response.token,
            request: {
                page: "Mass_Move_Requests"
            }
        });
      }
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
      const result = FieldValidator(value, { required: true, minLength: 1 });
      const err: ById<IFieldErrorKeyValue> = errors;

      if (result.length > 0) {
          err[name] = { key: name, message: result };
      } else {
          delete err[name];
      }
      return err;
  };
}

const mapStateToProps = (state: IStore): IBulkMovesStoreProps => ({
    getToken: getToken(state),
    getETLookup: getETLookups(state),
    addBulkMoveStatus: BulkMove(state)
  });
  
  const mapDispatchToProps = (dispatch: IDispatch): IBulkMovesDispatchProps => ({
    getETLookupsRequest: (data: IETLookupsRequest) => dispatch(getETLookupsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    addBulkMoveRequest: (data: IBulkMoveRequest) => dispatch(bulkMoveLoadAction(data)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(BulkMoves);