
export enum IGET_UNIT_INSPECTION_SHEETS_REQUEST {
    REQUEST = "getUnitInspectionSheets/GET_UNIT_INSPECTION_SHEETS_REQUEST",
    SUCCESS = "getUnitInspectionSheets/GET_UNIT_INSPECTION_SHEETS_SUCCESS",
    FAILED = "getUnitInspectionSheets/GET_UNIT_INSPECTION_SHEETS_FAILED"
};

export interface IGetUnitInspectionSheetsRequest {
    token: string;
};

export interface IGetUnitInspectionSheets {
    id: number;
    unit_ID: number;
    unit_No: string;
    defect_ID: number;
    comments: string;
    items: IGetUnitInspectionSheetsItems[];
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

export interface IGetUnitInspectionSheetsItems {
    id: number;
    item_ID: number;
    good: string;
    needs_Repair: string;
    na: string;
    estimated_Hours: number;
    estimated_Labour_Cost: number;
    estimated_Parts_Cost: number;
    approved: string;
    comments: string;
}
