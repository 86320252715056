import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IG_DRIVERS_ASSIGNMENTS_REQUEST {
    REQUEST = "getDriversAssignment/G_DRIVERS_ASSIGNMENTS_REQUEST",
    SUCCESS = "getDriversAssignment/G_DRIVERS_ASSIGNMENTS_SUCCESS",
    FAILED = "getDriversAssignment/G_DRIVERS_ASSIGNMENTS_FAILED"
};

export interface IGetDriversAssignmentRequest extends ISurewayTokenRequestBody {

};

export interface IDriversAssignment {
    id: number;
    notes: string;
    service_Trucks: IDriverAssignmentST[];
    sub_Site_ID: number;
    site_ID: number;
    date: string;
    status: string;
    site_Info: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};

export interface IDriverAssignmentST {
    notes: string;
    driver: string;
    swamper: string;
    unit_No: string;
    priority: number;
    service_Truck_ID: number;
}