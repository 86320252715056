import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_MEDICAL_FACILITIES_REQUEST, IGetMedicalFacilities } from "./getMedicalFacilitiesConstants";
import { IGetMedicalFacilitiesLoadAction, IGetMedicalFacilitiesLoadFailedAction, IGetMedicalFacilitiesSuccessAction } from "./getMedicalFacilitiesActions";
import { IAddMedicalFacilitySuccessAction } from "../addMedicalFacility/addMedicalFacilityActions";
import { IUpdateMedicalFacilitySuccessAction } from "../updateMedicalFacility/updateMedicalFacilityActions";
import { IADD_MEDICAL_FACILITY_REQUEST } from "../addMedicalFacility/addMedicalFacilityConstants";
import { IUPDATE_MEDICAL_FACILITY_REQUEST } from "../updateMedicalFacility/updateMedicalFacilityConstants";

type Actions =
    | IGetMedicalFacilitiesLoadAction
    | IGetMedicalFacilitiesSuccessAction
    | IGetMedicalFacilitiesLoadFailedAction
    | IAddMedicalFacilitySuccessAction
    | IUpdateMedicalFacilitySuccessAction
    | IFlushDataSuccessAction;

export const GetMedicalFacilitiesReducer = (state: Server<SurewayAPIResponse<ById<IGetMedicalFacilities>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetMedicalFacilities>>> => {
    switch (action.type) {
        case IGET_MEDICAL_FACILITIES_REQUEST.REQUEST:
            return loading;

        case IGET_MEDICAL_FACILITIES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MEDICAL_FACILITIES_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_MEDICAL_FACILITY_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_MEDICAL_FACILITY_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};