import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST, IMechanicTimesheetForApproval } from "./getMechanicTimesheetForApprovalConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetMechanicTimesheetForApprovalLoadAction, IGetMechanicTimesheetForApprovalLoadFailedAction, IGetMechanicTimesheetForApprovalSuccessAction, getMechanicTimesheetForApprovalLoadFailedAction, getMechanicTimesheetForApprovalLoadSuccessAction } from "./getMechanicTimesheetForApprovalActions";

export const getMechanicTimesheetForApprovalEpic: Epic = (
    action$: ActionsObservable<IGetMechanicTimesheetForApprovalLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMechanicTimesheetForApprovalSuccessAction | IGetMechanicTimesheetForApprovalLoadFailedAction> =>
    action$.ofType(IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_MECHANIC_TIMESHEET_FOR_APPROVAL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IMechanicTimesheetForApproval>>): IGetMechanicTimesheetForApprovalSuccessAction => {
                            return getMechanicTimesheetForApprovalLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getMechanicTimesheetForApprovalLoadFailedAction(response.message)))
                    )
            )
        );