import React from 'react';
import {TextField, Button, Grid, FormControl, Paper, Typography, FormControlLabel, FormLabel, FormGroup, Checkbox} from '@mui/material';
  import splitDate from '../../../../../shared/splitDate';
import {updateSOCT, getSOCTFile, getSOCTScheduledService} from '../../../api/soct/soctAPI';
import {getSOCTWorkingSites, getSOCTWorkingSubSitesByParentID, getSOCTWorkingSubSitesByID} from '../../../api/workingsites/workingSitesAPI';
import { currentUser, hasUserAccess } from '../../../socthome';
import { SOCT_URL } from '../../../../../shared/styles';

interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState{
    unit_Number:string;
    make:string;
    model:string;
    location:string;
    service_Date:any;
    current_Hours:number;
    last_EO:number;
    filter_Cuttings:string;
    assigned:any[];
    location_list:any[];
    loaded:boolean;
}
var editSOCTMain:any = null;
var workingSites:any = [];
var exportWorkingSites:any;
var sub_site_locations:any[] = [];
var parent_id:number = 0;
var geotab_sync:boolean;
var mostRecentService: any;
export default class EditSOCTMain extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state={unit_Number:'',make:'',model:'',location:'',service_Date:'',current_Hours:0,last_EO:0,filter_Cuttings:'',assigned:[], location_list:[],loaded:false};
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.onGetFileButtonClick = this.onGetFileButtonClick.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
    }
    onGetFileButtonClick(equipment_id:any, filename:string){
        getSOCTFile(equipment_id, filename);
    }
    handleFileRemove(index:number,event:any){
        editSOCTMain.attachments.splice(index,1);
        this.setState({});
    }
    handleFileUpload(event:any){
        if(editSOCTMain.attachments === null || editSOCTMain.attachments===''){
            editSOCTMain.attachments = [];
        }
        editSOCTMain.attachments.push(event.target.files[0].name);
        this.setState({});
    }
    handleStatusChange(event:any){
        switch(event.target.value){
            case 'Active':
                editSOCTMain.status = 'Active';
                break;
            case 'Inactive':
                editSOCTMain.status = 'Inactive';
                break;
            case 'Out Of Service':
                editSOCTMain.status = 'Out Of Service';
                break;
            case 'Sold':
                editSOCTMain.status = 'Sold';
                break;
            case 'Active Rental':
                editSOCTMain.status = 'Active Rental';
                break;
            case 'Inactive Rental':
                editSOCTMain.status = 'Inactive Rental';
                break;
        }
        this.setState({});
    }
    async handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'make':
                editSOCTMain.make = event.target.value;
                break;
            case 'model':
                editSOCTMain.model = event.target.value;
                break;
            case 'location':
                editSOCTMain.location = event.target.value;
                var parent_id_changed=0;   
                exportWorkingSites.map((site:any) => {
                    if(site.site_Name === event.target.value){
                        parent_id_changed = site.id;
                    }
                });
                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id_changed, true);
                break;
            case 'sub_location':
                editSOCTMain.sub_Location = event.target.value;
                sub_site_locations.map((site:any) => {
                    if(site.sub_Site_Name === event.target.value){
                        editSOCTMain.sub_Location_ID = site.id;
                    }
                });
            break;
            case 'servicedate':
                editSOCTMain.service_Date = event.target.value;
                break;
            case 'currenthours':
                editSOCTMain.current_Hours = event.target.value;
                break;
            case 'lasteo':
                editSOCTMain.last_EO = event.target.value;
                break;
            case 'filtercuttings':
                editSOCTMain.filter_Cuttings = event.target.value;
                break;
            case 'ecm':
                editSOCTMain.ecm = event.target.value;
                break;
            case 'comments':
                editSOCTMain.soct_Comments = event.target.value;
                break;
            case 'reading_Type_Notes':
                editSOCTMain.reading_Type_Notes = event.target.value;
                break;
            case 'geotab_sync':
                geotab_sync = !geotab_sync;
                break;
        }
        this.setState({});
    }
    onFormCancel(){
        this.props.history.push('/field/soct/lists');
    }
    async onFormSave(){
        console.log('saving data ', editSOCTMain);
        var result:any = await updateSOCT(editSOCTMain, currentUser, geotab_sync);
        if(result.status === 200){
            alert("Successfully updated SOCT item");
            this.props.history.push('/field/soct/lists');
        }
        else{
            alert("Error updating SOCT item");
        }
    }
    async componentDidMount(){
        // console.log(this.props.editData)
        editSOCTMain = this.props.editData;
        //editSOCTMain.comments = editSOCTMain.socT_Comments;
        if(editSOCTMain.attachments === null || editSOCTMain.attachments===''){
            editSOCTMain.attachments = [];
        }
        geotab_sync = editSOCTMain.geotab_Sync === "Yes";
        var temp_location_list:any[] = [];
        workingSites = await getSOCTWorkingSites(true);

        let scheduled_services:any = await getSOCTScheduledService(this.props.editData.SOCT_ID);
        scheduled_services.sort(function(a:any,b:any){return new Date(b.service_Date).getTime() - new Date(a.service_Date).getTime()});
        mostRecentService = scheduled_services[0];
        // console.log(mostRecentService);
       
        if(workingSites != null){
            workingSites.map((location:any) => {
                temp_location_list.push(location);
                if(location.site_Name === this.props.editData.location){
                    parent_id = location.id;
                }
            });
            exportWorkingSites = temp_location_list.sort((a:any,b:any) => (a.site_Name > b.site_Name) ? 1:-1);
        }
        if(!editSOCTMain.working_Site_Sub_ID){
            var res:any = await getSOCTWorkingSubSitesByID(editSOCTMain.sub_Location_ID);
            editSOCTMain.sub_Location = res.working_Sub_Site_Name;
            editSOCTMain.location = res.working_Site_Name;
        }
        else{
            var res:any = await getSOCTWorkingSubSitesByID(editSOCTMain.working_Site_Sub_ID);
            editSOCTMain.sub_Location = res.working_Sub_Site_Name;
            editSOCTMain.location = res.working_Site_Name;   
        }

        this.setState({loaded:true});
    }
    render(){
        if(this.state.loaded){
        // console.log(this.props.editData)
        return (
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Unit" defaultValue={this.props.editData.unit_Number}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Make" defaultValue={this.props.editData.make} onChange={this.handleChange} name="make"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Model" defaultValue={this.props.editData.model} onChange={this.handleChange} name="model"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Equipment Type" defaultValue={this.props.editData.equipment_Type} onChange={this.handleChange} name="model"></TextField>
            </Grid>
            {this.props.editData.equipment_Type === "Excavators" && <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Reading Type" defaultValue={this.props.editData.reading_Type} onChange={this.handleChange} name="model"></TextField>
            </Grid>}
           
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Job #" defaultValue={this.props.editData.job_No ? this.props.editData.job_No:editSOCTMain.job_Number}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField disabled label="Location" defaultValue={editSOCTMain.location}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField disabled label="Sub Location" defaultValue={editSOCTMain.sub_Location}></TextField>
          
            </Grid>
           
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Current Hours" defaultValue={this.props.editData.current_Hours} onChange={this.handleChange} name="currenthours"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled helperText="Meter Date" defaultValue={this.props.editData.meter_Date!=null ? splitDate(this.props.editData.meter_Date):''}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Last_E:O" defaultValue={this.props.editData.last_EO} onChange={this.handleChange} name="lasteo"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Filter Cuttings" defaultValue={this.props.editData.filter_Cuttings} onChange={this.handleChange} name="filtercuttings"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="ECM" defaultValue={this.props.editData.ecm} onChange={this.handleChange} name="ecm"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Sync Engine Hours with Device API</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                <Checkbox checked={geotab_sync} onChange={this.handleChange} name="geotab_sync"/>
                                }
                                label="Yes"
                            />
                        </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextField style={{backgroundColor:'white'}} fullWidth label="Comments" multiline rows={4} defaultValue={editSOCTMain.comments ? editSOCTMain.comments: this.props.editData.socT_Comments} onChange={this.handleChange} name="comments"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField label="Status" defaultValue={this.props.editData.status ? this.props.editData.status: "Active"}disabled></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
            </Grid>  
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Last Service Date"  defaultValue={this.props.editData.service_Date!=null ? splitDate(this.props.editData.service_Date):''} onChange={this.handleChange} name="servicedate"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Next 250 Due" defaultValue={this.props.editData.next_250_Due} onChange={this.handleChange} name="next250Due"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Next 500 Due" defaultValue={this.props.editData.next_500_Due} onChange={this.handleChange} name="next500Due"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Next 1000 Due" defaultValue={this.props.editData.next_1000_Due} onChange={this.handleChange} name="next1000Due"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Next 1500 Due" defaultValue={this.props.editData.next_1500_Due} onChange={this.handleChange} name="next1500Due"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Next 3000 Due" defaultValue={this.props.editData.next_3000_Due} onChange={this.handleChange} name="next3000Due"></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Last Service Hours" defaultValue={mostRecentService? mostRecentService.service_Type : ""} onChange={this.handleChange} name="lastServiceHours"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} fullWidth disabled label="PM Sheet Comments" multiline rows={4} defaultValue={mostRecentService? mostRecentService.comments : ""} onChange={this.handleChange} name="pMComments"></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} fullWidth disabled label="PM Sheet Admin Comments" multiline rows={4} defaultValue={mostRecentService? mostRecentService.admin_Comments : ""} onChange={this.handleChange} name="pMAdminComments"></TextField>
            </Grid>
            {this.props.editData.equipment_Type === "Excavators" &&<Grid item xs={4}>
                <TextField style={{backgroundColor:'white'}} fullWidth label="Reading Type Notes" multiline rows={4} defaultValue={editSOCTMain.reading_Type_Notes ? editSOCTMain.comments: ""} onChange={this.handleChange} name="reading_Type_Notes"></TextField>
            </Grid>}
            <Grid item xs={12}>
            <Paper variant="outlined" style={{padding:'15px', backgroundColor:'#e0bfbc'}}>
               <Grid container spacing={1}>

               <Grid item xs={12}>
                    <Typography style={{borderBottomColor:'black', borderBottomWidth:'thick', borderBottom:'solid', textAlign:'center', fontWeight:'bold'}}>Attachments</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant="outlined" component="label">Drag and drop, or click to upload files<input hidden accept="image/*" multiple type="file" onChange={this.handleFileUpload}></input></Button>
                    {editSOCTMain ? editSOCTMain.attachments ? editSOCTMain.attachments.map((file:any, index:any) => {
                        return <Grid item xs={12} sm={6} md={3} lg={2}><Button onClick={(e:any)=>{this.onGetFileButtonClick(this.props.editData.id, e.target.innerHTML.substring(0,e.target.innerHTML.indexOf("<")))}} className="dataGrid-edit-btn">{file}</Button><Button onClick={this.handleFileRemove.bind(this,index)}>X</Button></Grid>
                    }):null:null}
                </Grid>

               </Grid>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Created" defaultValue={this.props.editData.created.replace("T"," ")}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Modified" defaultValue={this.props.editData.modified.replace("T"," ")}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Created By" defaultValue={this.props.editData.created_By}></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField disabled label="Modified By" defaultValue={this.props.editData.modified_By}></TextField>
            </Grid>
            <Grid item xs={12}><br></br>
                <Button style={{width:'200px',backgroundColor:'#bf0000'}} onClick={this.onFormCancel} variant="contained">Cancel</Button>
                <Button disabled={hasUserAccess} style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'1%'}} onClick={this.onFormSave} variant="contained">Save</Button>
            </Grid>
        </Grid>
          
        );
                }else{
                    return null;
                }
    }
}
export {exportWorkingSites};