import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_MEDICAL_FACILITY_REQUEST, IAddMedicalFacilityRequest } from "./addMedicalFacilityConstants";


export interface IAddMedicalFacilityLoadAction {
    type: IADD_MEDICAL_FACILITY_REQUEST.REQUEST;
    data: IAddMedicalFacilityRequest
}
export const addMedicalFacilityLoadAction = (data: IAddMedicalFacilityRequest): IAddMedicalFacilityLoadAction => ({
    type: IADD_MEDICAL_FACILITY_REQUEST.REQUEST,
    data
});
export interface IAddMedicalFacilitySuccessAction {
    type: IADD_MEDICAL_FACILITY_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addMedicalFacilityLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddMedicalFacilitySuccessAction => ({
    type: IADD_MEDICAL_FACILITY_REQUEST.SUCCESS,
    message
});
export interface IAddMedicalFacilityLoadFailedAction {
    type: IADD_MEDICAL_FACILITY_REQUEST.FAILED;
    message: string;
}
export const addMedicalFacilityLoadFailedAction = (message: string): IAddMedicalFacilityLoadFailedAction => ({
    type: IADD_MEDICAL_FACILITY_REQUEST.FAILED,
    message
});
