import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { STATUS_ENUM } from "../../../redux/server";
import { getDate, undefinedFunction } from "../../shared/constExports";
import LAErrorBox from "../../shared/errorBox";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LACenteredLoading } from "../../shared/loading";
import { LAPaperWithPadding, LAPaperWithLessPadding } from "../../shared/paper";
import { LAPopover } from "../../shared/popOver";
import { ById, HasClass } from "../../shared/publicInterfaces";
import { WHITE_COLOR, DARK_GREY_COLOR, LIGHT_GREY_COLOR } from "../../shared/theme";
import { IGetServiceTruckCommunicationLogs } from "../../../redux/field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsConstants";
import { IGetDriverAssignmentVersionHistory } from "../../../redux/field/dispatch/getDriverAssignmentVersionHistory/getDriverAssignmentVersionHistoryConstants";
import { LADevExtremeGrid } from "../../shared/devExtreme";


//#region Driver Communication Logs
interface ILogPopupProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    popUpLoading: boolean;
    data: IGetServiceTruckCommunicationLogs[];
};

const LogPopupStyles = styled(LAPaperWithPadding)`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
        background-color: ${DARK_GREY_COLOR};
    };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const DispatchLogPopup: React.FC<ILogPopupProps> = React.memo((props: ILogPopupProps) => {

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LogPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>SMS LOGS</h3>
                    <hr />
                </LAGridItem>

                {((props.status === STATUS_ENUM.LOADING) || (props.popUpLoading)) && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(props.data && props.data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <Table aria-label="logs-table">

                            <TableHead>
                                <TableRow className="header-row">
                                    <TableCell className="title" align="center">Service Truck</TableCell>
                                    <TableCell className="title" align="center">Driver</TableCell>
                                    <TableCell className="title" align="center">Swamper</TableCell>
                                    <TableCell className="title" align="center">Site</TableCell>
                                    <TableCell className="title" align="center">Date</TableCell>
                                    <TableCell className="title" align="center">Status</TableCell>
                                    <TableCell className="title" align="center">Notes</TableCell>
                                    <TableCell className="title" align="center">Created</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.data.sort((a, b) => (a.id > b.id ? -1 : 1)).map((row: IGetServiceTruckCommunicationLogs, index: number) => (
                                    <TableRow key={index} className={index % 2 ? "odd-row" : ""}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.service_Truck}
                                        </TableCell>
                                        <TableCell align="center">{row.driver}</TableCell>
                                        <TableCell align="center">{row.swamper}</TableCell>
                                        <TableCell align="center">{row.site}</TableCell>
                                        <TableCell align="center">{getDate(false, row.date)}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        <TableCell align="center">{getDate(true, row.created)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LogPopupStyles>
    </LAPopover>
});
//#endregion


//#region Version History
interface IDriverAssignmentVersionHistoryProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: ById<IGetDriverAssignmentVersionHistory>;
};

export const DriverAssignmentVersionHistory: React.FC<IDriverAssignmentVersionHistoryProps> = React.memo((props: IDriverAssignmentVersionHistoryProps) => {
    const data = Object.values(props.data);

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LogPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>VERSION HISTORY</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <br /><br /><br /><br />
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <LADevExtremeGrid
                            data={data}
                            columnChoose={true}
                            columnsHiding={true}
                            onClick={undefinedFunction}
                            searchPanel={true}
                            filterHeader={true}
                            export={true}
                            removeStyleBtn={true}
                            id="driversAssignmentVersionHistoryList"
                            customRowColor={true}
                            exportFileName="DriversAssignmentVersionHistory"
                            storageKey="driversAssignmentVersionHistorySessionKey"
                            columns={[                                
                                { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                { name: "modified_By", caption: "Modified By", type: "string" },
                                { name: "date", caption: "Date", type: "date" },
                                { name: "status", caption: "Status", type: "string" },
                                { name: "site_Info", caption: "Site Info", type: "string" },
                                { name: "driver", caption: "Driver", type: "string" },
                                { name: "swamper", caption: "Swamper", type: "string" },
                                { name: "unit_No", caption: "Unit #", type: "string" },
                                { name: "eta", caption: "ETA", type: "datetime" },
                                { name: "departed_Time", caption: "Departed Time", type: "time" },
                                { name: "arrival_Time", caption: "Arrival By", type: "time" },
                                { name: "notes", caption: "Notes", type: "string" },
                                { name: "created", caption: "Created", type: "datetime"},
                                { name: "created_By", caption: "Created By", type: "string" },
                            ]}
                        />
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LogPopupStyles>
    </LAPopover>
});
//#endregion