
export enum IVALIDATE_OPERATOR_LOGIN_REQUEST {
    REQUEST = "validateOperatorLogin/VALIDATE_OPERATOR_LOGIN_REQUEST",
    SUCCESS = "validateOperatorLogin/VALIDATE_OPERATOR_LOGIN_SUCCESS",
    FAILED = "validateOperatorLogin/VALIDATE_OPERATOR_LOGIN_FAILED"
};

export interface IValidateOperatorLoginRequest {
    request:{
        email: string;
        password: string;
    }
};

export interface IValidateOperatorLogin {
    token: string;
    email?: string;
};