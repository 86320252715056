import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST } from "./saveManagerCommentsForDefectItemConstants";
import { ISaveManagerCommentsForDefectItemLoadAction, ISaveManagerCommentsForDefectItemLoadFailedAction, ISaveManagerCommentsForDefectItemSuccessAction, saveManagerCommentsForDefectItemLoadFailedAction, saveManagerCommentsForDefectItemLoadSuccessAction } from "./saveManagerCommentsForDefectItemActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const saveManagerCommentsForDefectItemEpic: Epic = (
    action$: ActionsObservable<ISaveManagerCommentsForDefectItemLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveManagerCommentsForDefectItemSuccessAction | ISaveManagerCommentsForDefectItemLoadFailedAction> =>
    action$.ofType(ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.SAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveManagerCommentsForDefectItemSuccessAction | ISaveManagerCommentsForDefectItemLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveManagerCommentsForDefectItemLoadSuccessAction(response);
                            } else {
                                return saveManagerCommentsForDefectItemLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveManagerCommentsForDefectItemLoadFailedAction(response.message)))
                    )
            )
        );