import React, { useEffect, useState } from "react";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { LAPopover } from "../../shared/popOver";
import { LACenteredLoading } from "../../shared/loading";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import styled from "styled-components";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { getDate, undefinedFunction } from "../../shared/constExports";
import { END_POINTS } from "../../../redux/endpoints";
import { hasPayload, Server } from "../../../redux/server";
import { IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { IAddEquipmentCertificate } from "../../../redux/field/addEquipmentCertificate/addEquipmentCertificateConstants";
import LAAutoComplete from "../../shared/autoComplete";
import LATextField from "../../shared/textField";
import LADatePicker from "../../shared/datePicker";
import { FIELD_VALIDATOR_ERRORS } from "../../shared/fieldValidator";
import { LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import { LADropZone } from "../../shared/dropZone";
import { IShopEquipmentFile } from "../../../redux/field/getEquipments/getEquipmentConstants";
import { DownloadIcon } from "../../shared/icons";

interface ICertificateHistoryPopupProps {
    id: number;
    open: boolean;
    onCancel: () => void;
    token: Server<SurewayAPIResponse<IToken>>;
    downloadFile: (fileName: string) => void;
};

interface ICertificateHistory {
    id: number;
    equipment_List_ID: number;
    type: string;
    due_Date: string;
    certificate_No: string;
    attachments: string;
    created_By: string;
    created: string;
}

const CertificateHistoryPopupStyles = styled(LAPaperWithPadding)`
        .title {
            font-weight: bold;
            color: ${WHITE_COLOR};
            background-color: ${DARK_GREY_COLOR};
        };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

export const CertificateHistoryPopup: React.FC<ICertificateHistoryPopupProps> = React.memo((props: ICertificateHistoryPopupProps) => {

    const onCancel = () => props.onCancel();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ICertificateHistory[]>([]);

    const callEndpoint = (): void => {
        if (hasPayload(props.token))
            fetch(END_POINTS.FIELD.CERTIFICATE_VERSION_HISTORY, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: props.id,
                    token: props.token.payload.response.token
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    setData(res.response);
                    setLoading(false);
                })
                .catch((res) => console.log(res));
    };

    useEffect(() => {
        callEndpoint();
    }, []);

    console.log(data)

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <CertificateHistoryPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>CERTIFICATE HISTORY</h3>
                    <hr />
                </LAGridItem>

                {(loading === true) && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading History..." />
                </LAGridItem>}

                {(loading === false) && <LAGridItem xs={12}>
                    {(data && data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <Table aria-label="history table">
                            <TableHead>
                                <TableRow className="header-row">
                                    <TableCell className="title" align="center">Certificate #</TableCell>
                                    <TableCell className="title" align="center">Due Date</TableCell>
                                    <TableCell className="title" align="center">Type</TableCell>
                                    <TableCell className="title" align="center">Modified Date</TableCell>
                                    <TableCell className="title" align="center">Modified By</TableCell>
                                    <TableCell className="title" align="center">Attachment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.sort((a, b) => a.due_Date < b.due_Date ? 1 : -1).map((row: ICertificateHistory, index: number) => {
                                    const onDownload = () => props.downloadFile(row.attachments);
                                    return (
                                        <TableRow key={index} className={index % 2 ? "odd-row" : ""}>
                                            <TableCell component="th" scope="row" align="center">
                                                {row.certificate_No}
                                            </TableCell>
                                            <TableCell align="center">{getDate(false, row.due_Date)}</TableCell>
                                            <TableCell align="center">{row.type}</TableCell>
                                            <TableCell align="center">{getDate(false, row.created)}</TableCell>
                                            <TableCell align="center">{row.created_By}</TableCell>
                                            <TableCell align="center">
                                                <div>{row.attachments}</div>
                                                {(row.attachments !== null && row.attachments !== "") && <LAIconButton
                                                    className="pl-5"
                                                    label="Download"
                                                    onClick={onDownload}
                                                    icon={<DownloadIcon />}
                                                />}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </CertificateHistoryPopupStyles>
    </LAPopover>
});


interface IAddCertificateHistoryPopupProps {
    open: boolean;
    onCancel: () => void;
    onSave: (data: IAddEquipmentCertificate) => void;
    downloadFile: (fileName: string) => void;
};

const AddCertificateHistoryPopupStyles = styled(LAPaperWithPadding)`
        .title {
            font-weight: bold;
            color: ${WHITE_COLOR};
            background-color: ${DARK_GREY_COLOR};
        };

        .required-text {
            color: ${WARNING_COLOR};
        };
`;

const certificateList: IIdName[] = [
    { id: 0, name: "CVIP" }, { id: 1, name: "CVIP_6_Month" }, { id: 2, name: "Crane" },
    { id: 3, name: "VK" }, { id: 4, name: "VKIP" }, { id: 5, name: "NA" },
    { id: 6, name: "Registration" }
];

export const AddCertificateHistoryPopup: React.FC<IAddCertificateHistoryPopupProps> = React.memo((props: IAddCertificateHistoryPopupProps) => {

    const [data, setData] = useState<IAddEquipmentCertificate>({
        Equipment_List_ID: 0,
        Type: "",
        Due_Date: "",
        Created_By: "",
        Certificate_No: "",
        Attachments: "",
        Files: []
    });

    const [file, setFile] = useState<IShopEquipmentFile | undefined>(undefined);

    const onSave = (): void => {
        let request = data;
        if (file)
            request.Files = [file];

        props.onSave(request);
    };

    const onDate = (name: string, v: string) => setData({
        ...data,
        Due_Date: new Date(v).toISOString()
    });

    const onChange = (name: string, value: string) => setData({
        ...data,
        [name]: value
    });

    const onDropDownChange = (event: unknown, value: IIdName): void => {
        setData({
            ...data,
            Type: value.name
        });
    };

    const sanitizedFileName = (fileName:any) => {
        const lastDotIndex = fileName.lastIndexOf('.');
      
        if (lastDotIndex === -1) {
          return fileName.replace(/[^a-z0-9]/gi, '_');
        }
      
        const name = fileName.substring(0, lastDotIndex);
        const extension = fileName.substring(lastDotIndex);
        const sanitizedFileName = name.replace(/[^a-z0-9]/gi, '_') + extension;
      
        return sanitizedFileName;
      };

    const fileUpload = async (attachments: Array<File | DataTransferItem>): Promise<void> => {

        new Promise((resolve, reject): any => {

            if (attachments && (attachments !== null)) {

                Object.values(attachments).forEach((x: any, index) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const sanitizeFileName = sanitizedFileName(x.name)
                            // console.log(sanitizeFileName)
                            if (attachments.length === index + 1) {
                                setFile({
                                    name: sanitizeFileName,
                                    base64String: reader.result.toString()
                                });
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        })
    };

    const attachment = (file) ? file : (data.Attachments && data.Attachments.length > 0) ? { name: data.Attachments, base64String: "" } : { name: "", base64String: "" };

    const onDownload = (): void => {
        if (file) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            props.downloadFile(attachment.name);
        };
    };

    return <LAPopover anchorRef={null} open={props.open} onClose={props.onCancel}>
        <AddCertificateHistoryPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>ADD CERTIFICATE HISTORY</h3>
                    <hr />
                </LAGridItem>

                <LAGridItem xs={12}>
                    <LAGrid>

                        <LAGridItem xs={6}>
                            <LAAutoComplete
                                freeText={true}
                                multiple={false}
                                autoHighlight={true}
                                option={certificateList}
                                name="name"
                                filterSelectedOptions={true}
                                getOptionLabel="name"
                                onChange={onDropDownChange}
                                selectionRemove={undefinedFunction}
                                dropDownPlaceHolder="Type"
                                value={certificateList.find(x => x.name === data.Type)}
                                defaultValue={certificateList.find(x => x.name === data.Type)}
                                errorText={(data.Type.length === 0) ? FIELD_VALIDATOR_ERRORS.REQUIRED : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={6} className="text-center">
                            <strong>Due Date: </strong>
                            <br />
                            <LADatePicker value={data.Due_Date ?? ""} onChange={(date) => onDate("Due_Date", date)} />
                            <br />
                            {data.Due_Date === "" && <span className="required-text">{FIELD_VALIDATOR_ERRORS.REQUIRED}</span>}
                        </LAGridItem>

                        <LAGridItem xs={6}>
                            <LATextField
                                variant="outlined"
                                fullWidth={true}
                                name="Certificate_No"
                                label="Certificate #"
                                onChange={onChange}
                                value={data.Certificate_No}
                            />
                        </LAGridItem>

                        <LAGridItem xs={6}>
                            <strong>ATTACHMENT</strong>
                            <hr />

                            <LADropZone onFileDrop={fileUpload} />
                            <br />

                            
                            {(attachment.name !== "") && <>
                                <div>{attachment.name}</div>
                                <LAIconButton
                                    id={attachment.name}
                                    className="pl-5"
                                    label="Download"
                                    onClick={onDownload}
                                    icon={<DownloadIcon />}
                                />
                            </>}
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <LASaveAndCancelButton
                                onSave={onSave}
                                onCancel={props.onCancel}
                                disableSave={(data.Due_Date === "" && data.Type === "") ? true : undefined}
                            />
                        </LAGridItem>

                    </LAGrid>
                </LAGridItem>

            </LAGrid>
        </AddCertificateHistoryPopupStyles>
    </LAPopover>
});