import { IUPDATE_MECHANIC_LOG_REQUEST } from "./updateMechanicLogConstants";
import { IUpdateMechanicLogLoadAction, IUpdateMechanicLogLoadFailedAction, IUpdateMechanicLogSuccessAction } from "./updateMechanicLogActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateMechanicLogLoadAction
    | IUpdateMechanicLogSuccessAction
    | IUpdateMechanicLogLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateMechanicLogReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_MECHANIC_LOG_REQUEST.REQUEST:
            return loading;

        case IUPDATE_MECHANIC_LOG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_MECHANIC_LOG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};