import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_WSS_LOG_INFO_BY_ID_REQUEST {
  REQUEST = "getWSSLogInfoByID/GET_WSS_LOG_INFO_BY_ID_REQUEST",
  SUCCESS = "getWSSLogInfoByID/GET_WSS_LOG_INFO_BY_ID_SUCCESS",
  FAILED = "getWSSLogInfoByID/GET_WSS_LOG_INFO_BY_ID_FAILED",
}

export interface IGetWSSLogInfoByIDRequest extends ISurewayTokenRequestBody {
  request: {
    id: number;
  };
}

export interface IGetWSSLogInfoByID {
  job: string;
  working_Site: string;
  supervisor: string;
  work_Description: string;
  prime_Contractor: string;
  nearest_Medical_Facility: string;
  nearest_Medical_Facility_GPS: string;
  nearest_Medical_Facility_Distance: string;
  acknowledge_Required: boolean;
  acknowledge_Status: boolean;
  acknowledge_Form: IAcknowledgeForm;
  muster_Point: string;
  date: string;
  crews: ICrew[];
}

export interface IAcknowledgeForm {
  id: number;
  message: string;
}

export interface ICrew {
  id: number;
  foreman: string;
  crew_Name: string;
  active: string;
  created: string;
  created_By: string;
  modified: string;
  modified_By: string;
  members: number[];
}
