import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl,  pageAccessCheck, userName, YesOrNo } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getCouplers } from "../../../redux/field/attachment/getCouplers/getCouplersAccessor";
import { getCouplersLoadAction } from "../../../redux/field/attachment/getCouplers/getCouplersActions";
import { AttachmentSubHeader, AttachmentSubHeaderMobile } from "../../header/attachmentSubHeader";
import { ICoupler, IGetCouplerRequest } from "../../../redux/field/attachment/getCouplers/getCouplersConstants";
import LAErrorBox from "../../shared/errorBox";

interface IAttachmentCouplersStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getAttachmentCoupler: Server<SurewayAPIResponse<ById<ICoupler>>>;
};

interface IAttachmentCouplersDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getAttachmentCouplersRequest: (data: IGetCouplerRequest) => unknown;
};


interface IAttachmentCouplersOwnProps {

};

interface IAttachmentCouplersState {
    activeView: boolean;
    activeAttachmentCouplers: ICoupler[];
};

const AttachmentCouplersStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IAttachmentCouplersProps = RouteComponentProps
    & IAttachmentCouplersStoreProps
    & IAttachmentCouplersDispatchProps
    & IAttachmentCouplersOwnProps;

class AttachmentCouplers extends PureComponent<IAttachmentCouplersProps, IAttachmentCouplersState> {

    public constructor(props: IAttachmentCouplersProps) {
        super(props);
        this.state = {
            activeView: true,
            activeAttachmentCouplers: []
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IAttachmentCouplersProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getAttachmentCoupler, getToken } = this.props;
        const { activeView, activeAttachmentCouplers } = this.state;
        const getRole = pageAccessCheck(getToken, "attachmentAccess");
        const data = activeView ? activeAttachmentCouplers : hasPayload(getAttachmentCoupler) ? Object.values(getAttachmentCoupler.payload.response) : [];

        return (
            <PageSpacing title="Attachment Couplers" description="FIELD - ATTACHMENT COUPLERS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <AttachmentCouplersStyles>

                    <div className="hide-on-phone">
                        <AttachmentSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <AttachmentSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">ATTACHMENT COUPLERS</h2>
                                <hr />

                                {getAttachmentCoupler.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Attachment Couplers..." />}
                                {getAttachmentCoupler.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Attachment Couplers..." />}

                                {getAttachmentCoupler.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="active" control={<Radio />} label="Active Attachment Couplers" />
                                                <FormControlLabel value="all" control={<Radio />} label="All Attachment Couplers" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                id="attachmentCouplersList"
                                                customRowColor={true}
                                                storageKey="attachmentCouplerListSessionKey"
                                                exportFileName="AttachmentCouplers"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "name", caption: "Name", type: "string", sortAsc: true },
                                                    { name: "active", caption: "Active", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </AttachmentCouplersStyles>}
            </PageSpacing>
        );
    }


    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        	
	let activeView = !this.state.activeView;
    const getStorageValue = localStorage.getItem("key_attachment_coupler");
    if(getStorageValue)
    {
        if(activeView)
        {
            localStorage.setItem("key_attachment_coupler", "active");
        }
        else
        {
            localStorage.setItem("key_attachment_coupler", "all");
        }
    }
    else
    {
        localStorage.setItem("key_attachment_coupler", "active");
    }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.ATTACHMENT_LIST.COUPLER
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.ATTACHMENT_LIST.COUPLER
        });
    };

    private checkViewSelections = (): void => {
        let activeView = this.state.activeView;
                const getStorageValue = localStorage.getItem("key_attachment_coupler");
                if(getStorageValue)
                {
                    if(getStorageValue === "active")
                    {
                        activeView = true;
                    }
                    else
                    {
                        activeView = false;
                    }
                }
                else
                {
                    localStorage.setItem("key_attachment_coupler", "active");
                }
                this.setState({activeView: activeView});
    }
    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getAttachmentCoupler)) {
                    this.getDataForTable();
                };
            } else {
                
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                  });
            }

            if (hasPayload(this.props.getAttachmentCoupler)) {
                this.setState({ activeAttachmentCouplers: Object.values(this.props.getAttachmentCoupler.payload.response).filter(x => x.active === YesOrNo[0].name) });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "attachmentAccess") !== NotApplicable) {
            this.props.getAttachmentCouplersRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IAttachmentCouplersStoreProps => ({
    getToken: getToken(state),
    getAttachmentCoupler: getCouplers(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAttachmentCouplersDispatchProps => ({
    getAttachmentCouplersRequest: (data: IGetCouplerRequest) => dispatch(getCouplersLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentCouplers);