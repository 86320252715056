import React from 'react';
import {Paper, Button} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {RouteComponentProps} from 'react-router-dom';
import EditSOCTMain from './editSOCTMain';
import EditServiceHistoryTable from './editServiceHistoryTable';
import EditMeterHistoryTable from './editMeterHistoryTable';
import EditMoveHistoryTable from './editMoveHistoryTable';
import EditReportTable from './editReportTable';
import {renderPageType} from '../../../sotc';
import SOCTHome from '../../../socthome';
import {getSOCTUnitByID, getEquipmentListIDByUnitNumber, getEquipmentListUnitByID} from '../../../api/soct/soctAPI';
import {SelectedButtonStyle} from '../../../../../shared/styles';

interface IProps extends RouteComponentProps{
  history:any
}
interface IState {
    showMain:boolean;showMeterHist:boolean;showMoveHist:boolean;showCustomHist:boolean;showServiceHist:boolean;data:any[];
    serviceHistory:any[];meterHistory:any[];moveHistory:any[];customHistory:any[];loaded:boolean;
}
var global_unit_info:any;

export default class EditSOTCForm extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false, data:[],
        serviceHistory:[], meterHistory:[],moveHistory:[],customHistory:[], loaded:false};
        this.renderHistoryReports = this.renderHistoryReports.bind(this);
        this.renderMeterHistory = this.renderMeterHistory.bind(this);
        this.renderMoveHistory = this.renderMoveHistory.bind(this);
        this.renderServiceHist = this.renderServiceHist.bind(this);
        this.renderSOCTMain = this.renderSOCTMain.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }
    renderSOCTMain(){
        this.setState({showMain:true,showMeterHist:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
        this.props.history.push('/field/soct/lists/edit/id='+global_unit_info.id);
    }
    renderMeterHistory(){
        this.setState({showMeterHist:true,showMain:false,showMoveHist:false,showCustomHist:false,showServiceHist:false});
        this.props.history.push('/field/soct/lists/edit/meterstable/id='+global_unit_info.id);
    }
    renderMoveHistory(){
        this.setState({showMoveHist:true,showCustomHist:false,showServiceHist:false,showMain:false,showMeterHist:false});
        this.props.history.push('/field/soct/lists/edit/movestable/id='+global_unit_info.id);
    }
    renderHistoryReports(){
        this.setState({showCustomHist:true,showMain:false,showMeterHist:false,showServiceHist:false,showMoveHist:false});
        this.props.history.push('/field/soct/lists/edit/reportstable/id='+global_unit_info.id);
    }
    renderServiceHist(){
        this.setState({showServiceHist:true,showMoveHist:false,showMain:false,showMeterHist:false,showCustomHist:false});
        this.props.history.push('/field/soct/lists/edit/scheduledservicetable/id='+global_unit_info.id);
    }
    onFormCancel(){
        this.props.history.goBack();
    }
    async componentDidMount(){
        
            var soct_unit:any = await getSOCTUnitByID(Number(window.location.pathname.split("=")[1]));
            if(soct_unit.equipment_List_ID != null){
                var equipment_list_unit_info:any = await getEquipmentListUnitByID(soct_unit.equipment_List_ID);
                soct_unit.location = equipment_list_unit_info.site_Name;
                soct_unit.sub_Location = equipment_list_unit_info.sub_Site_Name;
                soct_unit.unit_Number = equipment_list_unit_info.unit_Number;
                soct_unit.make = equipment_list_unit_info.make;
                soct_unit.model = equipment_list_unit_info.model;
                soct_unit.equipment_Type = equipment_list_unit_info.equipment_Type;     
                soct_unit.job_Number = equipment_list_unit_info.job_No;
            }
            global_unit_info = soct_unit;
            global_unit_info.SOCT_ID = soct_unit.id;
            global_unit_info.comments = soct_unit.comments;
          
        if(renderPageType==='Services' || window.location.pathname.includes("scheduledservicetable")){
            this.renderServiceHist();
        }
        if(renderPageType==='Meters' || window.location.pathname.includes("meterstable")){
            this.renderMeterHistory();
        }
        if(renderPageType==='Moves' || window.location.pathname.includes("movestable")){
            this.renderMoveHistory();
        }
        if(window.location.pathname.includes("reportstable")){
            this.renderHistoryReports();
        }
        this.setState({loaded:true});
    }
    render(){
        if(this.state.loaded){
        return (
            <>
            <SOCTHome history={this.props.history}></SOCTHome>
            <div style={{backgroundColor:'white'}}>
                <Paper variant="outlined" style={{padding:'15px'}}>
                    <h1>Editing SOCT For Unit # <Button style={{fontWeight:'bold', color:'#00b0ff', fontSize:'1em', fontFamily: "Heebo, sans-serif", borderBottom:'solid 5px'}} onClick={async (e:any) => 
          {
            var unit_number = global_unit_info.unit_Number;
            var res:any = await getEquipmentListIDByUnitNumber(unit_number);
            if(res!=null && res!=0){
                window.open('/field/equipment?id='+res);
            }
            else{
              alert("Error mapping Unit number with equipment list, contact It.Developers for this error");
            }
            }}>{global_unit_info.unit_Number}</Button></h1>
                    <Paper variant="outlined" style={{padding:'15px'}}>
                        <AppBar position="static" style={{backgroundColor:'#bf0000'}}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>
                                <Button
                                   sx={{ my: 2, color: 'white', display: 'block',fontWeight:'bold', fontSize:'15px'}}
                                   onClick={this.renderSOCTMain}
                                   style={this.state.showMain ? SelectedButtonStyle:null}
                                >
                                    Sureway Oil Change Tracker
                                </Button>
                              
                                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    <Button
                                         sx={{ my: 2, color: 'white', display: 'block'}}
                                         onClick={this.renderServiceHist}
                                         style={this.state.showServiceHist ? SelectedButtonStyle:null}
                                     >
                                         Service History
                                     </Button>
                                   
                                        <Button
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                            onClick={this.renderMeterHistory}
                                            style={this.state.showMeterHist ? SelectedButtonStyle:null}
                                        >
                                            Meter History
                                        </Button>

                                        <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderMoveHistory}
                                         style={this.state.showMoveHist ? SelectedButtonStyle:null}
                                     >
                                         Equipment Move History
                                     </Button>
                                     
                                     <Button
                                         sx={{ my: 2, color: 'white', display: 'block' }}
                                         onClick={this.renderHistoryReports}
                                         style={this.state.showCustomHist ? SelectedButtonStyle:null}
                                     >
                                        Reports
                                     </Button>
                                    </Box>
                                    </Toolbar>
                                    </Container>
                                    </AppBar>
                <Paper variant="outlined" style={{padding:'15px', backgroundColor:'rgb(236, 236, 236)'}}>
                    {this.state.showMain ?
                        <EditSOCTMain editData={global_unit_info} history={this.props.history} data={this.state.data}></EditSOCTMain>
                    :null}
                    {this.state.showMeterHist ?  
                        <EditMeterHistoryTable editData={global_unit_info} history={this.props.history} data={this.state.meterHistory}></EditMeterHistoryTable>
                    :null}
                    {this.state.showServiceHist ?  
                        <EditServiceHistoryTable editData={global_unit_info} history={this.props.history} data={this.state.serviceHistory}></EditServiceHistoryTable>
                    :null}
                    {this.state.showMoveHist ?  
                        <EditMoveHistoryTable editData={global_unit_info} history={this.props.history} data={this.state.moveHistory}></EditMoveHistoryTable>
                   :null}
                     {this.state.showCustomHist ?  
                        <EditReportTable editData={global_unit_info} history={this.props.history} data={this.state.customHistory}></EditReportTable>
                    :null}
                    </Paper>
                    </Paper>
                </Paper>
            </div>
            </>
        );
    }
    else{
        return null;
    }
    }
}