import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IRepairLineItem } from "../getRepairLines/getRepairLinesConstants";
import { IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST, IGetRepairLineDefectItemsByRepairIDRequest } from "./getRepairLineDefectItemsByRepairIDConstants";

export interface IGetRepairLineDefectItemsByRepairIDLoadAction {
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.REQUEST;
    data: IGetRepairLineDefectItemsByRepairIDRequest
}

export const getRepairLineDefectItemsByRepairIDLoadAction = (data: IGetRepairLineDefectItemsByRepairIDRequest): IGetRepairLineDefectItemsByRepairIDLoadAction => ({
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.REQUEST,
    data
});

export interface IGetRepairLineDefectItemsByRepairIDSuccessAction {
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLineItem[]>;
}

export const getRepairLineDefectItemsByRepairIDLoadSuccessAction = (list: SurewayAPIResponse<IRepairLineItem[]>): IGetRepairLineDefectItemsByRepairIDSuccessAction => ({
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.SUCCESS,
    list
});

export interface IGetRepairLineDefectItemsByRepairIDLoadFailedAction {
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.FAILED;
    message: string;
}

export const getRepairLineDefectItemsByRepairIDLoadFailedAction = (message: string): IGetRepairLineDefectItemsByRepairIDLoadFailedAction => ({
    type: IGET_REPAIR_LINE_DEFECT_ITEMS_BY_REPAIR_ID_REQUEST.FAILED,
    message
});
