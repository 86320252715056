import { IGET_SERVICE_TRUCKS_REQUEST, IServiceTruck, IGetServiceTruckRequest } from "./getServiceTrucksConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IGetServiceTrucksLoadAction {
    type: IGET_SERVICE_TRUCKS_REQUEST.REQUEST;
    data: IGetServiceTruckRequest
}
export const getServiceTrucksLoadAction = (data: IGetServiceTruckRequest): IGetServiceTrucksLoadAction => ({
    type: IGET_SERVICE_TRUCKS_REQUEST.REQUEST,
    data
});
export interface IGetServiceTrucksSuccessAction {
    type: IGET_SERVICE_TRUCKS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IServiceTruck>>;
}
export const getServiceTrucksLoadSuccessAction = (list: SurewayAPIResponse<ById<IServiceTruck>>): IGetServiceTrucksSuccessAction => ({
    type: IGET_SERVICE_TRUCKS_REQUEST.SUCCESS,
    list
});
export interface IGetServiceTrucksLoadFailedAction {
    type: IGET_SERVICE_TRUCKS_REQUEST.FAILED;
    message: string;
}
export const getServiceTrucksLoadFailedAction = (message: string): IGetServiceTrucksLoadFailedAction => ({
    type: IGET_SERVICE_TRUCKS_REQUEST.FAILED,
    message
});
