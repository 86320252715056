import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_OPERATOR_LOGS_REQUEST, IOperatorLogs, IGetOperatorLogsRequest } from "./getOperatorLogsConstants";

export interface IGetOperatorLogsLoadAction {
    type: IGET_OPERATOR_LOGS_REQUEST.REQUEST;
    data: IGetOperatorLogsRequest
}
export const getOperatorLogsLoadAction = (data: IGetOperatorLogsRequest): IGetOperatorLogsLoadAction => ({
    type: IGET_OPERATOR_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetOperatorLogsSuccessAction {
    type: IGET_OPERATOR_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IOperatorLogs>>;
}
export const getOperatorLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IOperatorLogs>>): IGetOperatorLogsSuccessAction => ({
    type: IGET_OPERATOR_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetOperatorLogsLoadFailedAction {
    type: IGET_OPERATOR_LOGS_REQUEST.FAILED;
    message: string;
}
export const getOperatorLogsLoadFailedAction = (message: string): IGetOperatorLogsLoadFailedAction => ({
    type: IGET_OPERATOR_LOGS_REQUEST.FAILED,
    message
});
