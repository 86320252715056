import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ET_LOOKUPS_REQUEST, IETLookups, IETLookupsRequest } from "./getETLookupsConstants";

export interface IGetETLookupsLoadAction {
    type: IGET_ET_LOOKUPS_REQUEST.REQUEST;
    data: IETLookupsRequest
}
export const getETLookupsLoadAction = (data: IETLookupsRequest): IGetETLookupsLoadAction => ({
    type: IGET_ET_LOOKUPS_REQUEST.REQUEST,
    data
});
export interface IGetETLookupsSuccessAction {
    type: IGET_ET_LOOKUPS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IETLookups>;
}
export const getETLookupsLoadSuccessAction = (list: SurewayAPIResponse<IETLookups>): IGetETLookupsSuccessAction => ({
    type: IGET_ET_LOOKUPS_REQUEST.SUCCESS,
    list
});
export interface IGetETLookupsLoadFailedAction {
    type: IGET_ET_LOOKUPS_REQUEST.FAILED;
    message: string;
}
export const getETLookupsLoadFailedAction = (message: string): IGetETLookupsLoadFailedAction => ({
    type: IGET_ET_LOOKUPS_REQUEST.FAILED,
    message
});
