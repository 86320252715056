import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { ISAVE_VENDOR_PO_LOG_REQUEST } from "./saveVendorPOLogConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { ISaveVendorPOLogLoadAction, ISaveVendorPOLogLoadFailedAction, ISaveVendorPOLogSuccessAction } from "./saveVendorPOLogActions";

type Actions =
    | ISaveVendorPOLogLoadAction
    | ISaveVendorPOLogSuccessAction
    | ISaveVendorPOLogLoadFailedAction
    | IFlushDataSuccessAction;
    
export const SaveVendorPOLogReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case ISAVE_VENDOR_PO_LOG_REQUEST.REQUEST:
            return loading;

        case ISAVE_VENDOR_PO_LOG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_VENDOR_PO_LOG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};