import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { IStore, IDispatch } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { userName, ZEROTH, undefinedFunction, getTokenFromUrl, } from '../../shared/constExports';
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { SummaryIcon } from "../../shared/icons";
import { ROUTE } from "../../routes";
import LADatePicker from "../../shared/datePicker";
import PageSpacing from "../../shared/pageSpacing";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IGetPayrollMechanicLogsRequest, IPayrollMechanicLogs } from "../../../redux/field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsConstants";
import { getPayrollMechanicLogs } from "../../../redux/field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsAccessor";
import { getPayrollMechanicLogsLoadAction } from "../../../redux/field/repairLine/getPayrollMechanicLogs/getPayrollMechanicLogsActions";
import { IPayrollMechanicLogsUploadRequest, IPayrollMechanicLogsUploadRequestFormat } from "../../../redux/field/repairLine/payrollMechanicLogsUpload/payrollMechanicLogsUploadConstants";
import { payrollMechanicLogsUploadStatus } from "../../../redux/field/repairLine/payrollMechanicLogsUpload/payrollMechanicLogsUploadAccessor";
import { payrollMechanicLogsUploadLoadAction } from "../../../redux/field/repairLine/payrollMechanicLogsUpload/payrollMechanicLogsUploadActions";
import LALinkButton from "../../shared/linkButton";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IGetMechanicTimecardVersionHistory, IGetMechanicTimecardVersionHistoryRequest } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";
import { getMechanicTimecardVersionHistoryLoadAction } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryActions";
import { getMechanicTimecardVersionHistory } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryAccessor";
import { MechanicLogsVersionHistory } from "./mechanicLogsVersionHistory";

interface IPayrollMechanicLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getPayrollMechanicLogs: Server<SurewayAPIResponse<IPayrollMechanicLogs[]>>;
    payrollMechanicLogsUploadStatus: Server<SurewayAPIResponse<string>>;
    getMechanicLogsVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
};

interface IPayrollMechanicLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getPayrollMechanicLogsRequest: (data: IGetPayrollMechanicLogsRequest) => unknown;
    payrollMechanicLogsUploadRequest: (request: IPayrollMechanicLogsUploadRequest) => unknown;
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest) => unknown;
};

interface IPayrollMechanicLogsState {
    dateRange: Date[] | undefined,
    updatedChildIds: IPayrollMechanicLogsUploadRequestFormat[];
    data: IPayrollMechanicLogs[];
    serverError: string;
    mechLogsVersionHistoryPopup: boolean;
};


const PayrollMechanicLogsStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    
    .dx-datagrid-save-button {
        display: none;
    };

    .dx-datagrid-cancel-button {
        display: none;
    };

    .back-home {       
        float: right;    
    };

    .clear-filters{       
        float: left;
        background-color: rgb(168, 0, 0);        
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IPayrollMechanicLogsProps =
    RouteComponentProps
    & IPayrollMechanicLogsStoreProps
    & IPayrollMechanicLogsDispatchProps;

class PayrollMechanicLogs extends PureComponent<IPayrollMechanicLogsProps, IPayrollMechanicLogsState> {

    public constructor(props: IPayrollMechanicLogsProps) {
        super(props);
        this.state = {
            data: [],
            dateRange: [],
            updatedChildIds: [],
            serverError: "",
            mechLogsVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IPayrollMechanicLogsProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.payrollMechanicLogsUploadStatus !== prevProps.payrollMechanicLogsUploadStatus) {

                if (this.props.payrollMechanicLogsUploadStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.payrollMechanicLogsUploadStatus.message });

                if (hasPayload(this.props.payrollMechanicLogsUploadStatus) && this.props.payrollMechanicLogsUploadStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.setState({
                        ...this.state,
                        updatedChildIds: []
                    });
                }
            };
        };
    }

    public render(): ReactNode {

        const { getToken, getPayrollMechanicLogs, payrollMechanicLogsUploadStatus, getMechanicLogsVersionHistory} = this.props;
        const { kind } = getPayrollMechanicLogs;
        const { data, dateRange, serverError , mechLogsVersionHistoryPopup} = this.state;
        const today = new Date();
        // const minDate = new Date(new Date().setDate(today.getDate() - 30)).toDateString();
        const maxDate = new Date().toDateString();
        const records = dateRange ? data.filter((x) => dateRange[1] ? ((new Date(x.date) >= dateRange[0]) && (new Date(x.date) <= dateRange[1])) : true) : data
        const mechanicLogsVersionHistorypopupData = hasPayload(getMechanicLogsVersionHistory) ? getMechanicLogsVersionHistory.payload.response : {};
        // console.log(this.state.dateRange)
        // console.log(data)

        return (
            <PageSpacing title="Payroll Mechanic Logs" description="FIELD - PAYROLL MECHANIC LOGS" fixedSpaceOnSmallerScreens={true}>
                <PayrollMechanicLogsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid spacing={1} className="text-center">

                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    className=""
                                    label="Report"
                                    onClick={this.handleReport}
                                    startIcon={<SummaryIcon color={WHITE_COLOR} />}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <LAButton
                                    className=""
                                    label="Summary"
                                    onClick={this.handleSummary}
                                    startIcon={<AccessTimeIcon />}
                                />

                                <h2>MECHANIC LOGS (HOURLY) EXPLORER UPLOAD</h2>
                                <hr />

                                <strong>
                                    Date Range: <LADatePicker
                                        key={"clear"}
                                        mode="range"
                                        value={dateRange}
                                        // minDate={minDate}
                                        maxDate={maxDate}
                                        onChange={this.setDateRange}
                                    />
                                </strong>

                                <LALinkButton onClick={this.clearDateRange} label="clear"></LALinkButton>

                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    className="mt-2" 
                                    label="Generate Explorer CSV for selected items"
                                    onClick={this.onSave}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                {(kind === STATUS_ENUM.LOADING) &&
                                    <LALoading className="text-center" message="Loading Sheets. Please Wait..." />
                                }

                                {(kind === STATUS_ENUM.FAILED) &&
                                    <LAErrorBox color={WARNING_COLOR} text="Sorry Failed to Load Data. Try to refresh the window..." />
                                }

                                {((kind === STATUS_ENUM.SUCCEEDED) ) &&
                                    <LAGridItem xs={12} >
                                        <LADevExtremeGrid
                                            id="PayrollMechanicLogs"
                                            data={records}
                                            searchPanel={true}
                                            filterHeader={true}
                                            columnsHiding={true}
                                            customRowColor={true}
                                            key="id"
                                            getRefreshDataCall={this.getDataForTable}
                                            export={true}
                                            onClick={undefinedFunction}
                                            onClearDateRange={this.clearDateRange}
                                            exportFileName="PayrollMechanicLogs"
                                            selectionRow={true}
                                            paging={50}
                                            height={3000}
                                            onSelectionChanged={this.onSelectionChange}
                                            onCustomClick={this.handleMechLogsVersionHistoryClick}
                                            storageKey="PayrollMechanicLogsSessionStorage"
                                            columns={[
                                                { name: "start_Date", caption: "Date", type: "date", width: 90 },
                                                { name: "name", caption: "Name", type: "string" },
                                                { name: "employee_Code", caption: "Employee Code", type: "string" },
                                                { name: "display", caption: "Unit/Attachment", type: "string" },
                                                // { name: "defect_Item", caption: "Defect Item", type: "string" },
                                                { name: "defect_Item", caption: "Defect Item", type: "string", renderCell: (e:any) => { return <div className="link-btn" onClick={() => this.onOpenDefectItem("defect_Item", e.row.data)}>{e.row.data.defect_Item}</div>} },         
                                                { name: "comments", caption: "Comments", type: "string" },
                                                { name: "hours", caption: "Hours", type: "number" },
                                                { name: "loa", caption: "LOA", type: "string" },
                                                { name: "component_Code", caption: "Component Code", type: "string" },
                                                { name: "work_Order_No", caption: "Work Order", type: "string" },
                                                { name: "job_No", caption: "Job No.", type: "string" },
                                                { name: "cost_Code", caption: "Cost Code", type: "string" },
                                                { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number" },
                                                { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string" },
                                                { name: "truck", caption: "Truck", type: "string" },
                                                { name: "start_Time", caption: "Time", type: "string", renderCell: (e:any) => { return <div>{e.row.data.start_Time + '-' + e.row.data.end_Time  } </div>}},
                                                // { name: "start_Time", caption: "Start Time", type: "string", },
                                                // { name: "end_Time", caption: "End Time", type: "string" },
                                                { name: "type", caption: "Type", type: "string",  allowEditing: false},
                                                { name: "history", caption: "Version History", type: "button" },
                                                { name: "payroll_Acknowledged", caption: "Payroll Acknowleged", type: "string", allowEditing: true, show: false},
                                                { name: "travel_Type", caption: "Travel Type", type: "string",  allowEditing: false, show: false},
                                                { name: "truck_Hours", caption: "Travel Hours", type: "number",  allowEditing: false, show: false},
                                                { name: "description", caption: "Description", type: "string",  allowEditing: false, show: false},
                                                { name: "employee_Code", caption: "Employee Code", type: "string",  allowEditing: false, show: false},
                                                { name: "charge_Location", caption: "Charge Location", type: "string",  allowEditing: false, show: false},
                                                { name: "status", caption: "Status", type: "string",  allowEditing: false, show: false},
                                                { name: "issue", caption: "Issue", type: "string",  allowEditing: false, show: false},
                                                { name: "item_Repair_Location", caption: "Item Repair Location", type: "string",  allowEditing: false, show: false},
                                                { name: "manager_Instructions", caption: "Manager Instructions", type: "string",  allowEditing: false, show: false},
                                                { name: "defect_Status", caption: "Defect Status", type: "string",  allowEditing: false, show: false},
                                                { name: "damaged", caption: "Damaged", type: "string",  allowEditing: false, show: false},
                                                { name: "warranty", caption: "Warranty", type: "string",  allowEditing: false, show: false},
                                                { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string",  allowEditing: false, show: false},
                                                { name: "serial_No", caption: "Serial No", type: "string",  allowEditing: false, show: false},
                                                { name: "major_Component_Replacement_Type", caption: "Major Component Replacement Type", type: "string",  allowEditing: false, show: false},
                                                { name: "major_Component_Replacement_Hours", caption: "Major Component Replacement Hours", type: "number",  allowEditing: false, show: false},
                                                { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string",  allowEditing: false, show: false},
                                                { name: "rejection_Notes", caption: "Rejection Notes", type: "string",  allowEditing: false, show: false},
                                                { name: "modified_After_Upload", caption: "Modified After Upload", type: "string", show: false},
                                            ]}
                                        />

                                        <LAButton
                                            className="mt-2 mb-2"
                                            label="Generate Explorer CSV for selected items"
                                            onClick={this.onSave}
                                        />
                                    </LAGridItem>
                                } 

                                {((serverError !== null) && (serverError.length > ZEROTH)) && 
                                    <LAGridItem xs={12}>
                                        <LAErrorBox text={serverError} />
                                    </LAGridItem>
                                }
                                
                            </LAGridItem>

                            {mechLogsVersionHistoryPopup && <MechanicLogsVersionHistory
                                data={mechanicLogsVersionHistorypopupData}
                                // display={display}
                                open={mechLogsVersionHistoryPopup}
                                status={getMechanicLogsVersionHistory.kind}
                                onCancel={this.handleMechLogsVersionHistoryPopupClose}
                            />}

                            <RequestStatus requestStatus={payrollMechanicLogsUploadStatus.kind} successMessage="Mechanic logs uploaded successfully." />
                        </LAGrid>
                    </LAPaperWithPadding>
                </PayrollMechanicLogsStyles>
            </PageSpacing>
        );
    }

    private onOpenDefectItem = (name: string, data: IPayrollMechanicLogs) => {
        // console.log(name, data);
        if(data.defect_ID && data.repair_ID)
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_ID.toString() + "&defect_ID=" + data.defect_ID.toString()+ "&view=All")
    }

    private handleMechLogsVersionHistoryPopupClose = (): void => {
        this.setState({ mechLogsVersionHistoryPopup: false });
    };

    private handleMechLogsVersionHistoryClick = async (name: string, e: any): Promise<void> => {
        // console.log(e.row.data)
        if (hasPayload(this.props.getToken)) {
            if(e.row.data.defect_ID > 0)
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Defect"
                    }
                });
            else {
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Misc"
                    }
                });
            }
        }
        await this.setState({ mechLogsVersionHistoryPopup: true });
    }

    private clearDateRange = (): void => {
        this.setState({ dateRange: undefined });
    };

    private setDateRange = (date: string, date2?: string): void => {
        if (date2) {
            this.setState({ dateRange: [new Date(date), new Date(date2)] });
        } else {
            this.setState({ dateRange: [new Date(date)] });
        }
    };

    private handleReport = (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_REPORT);
    };

    private handleSummary = (): void => {
        this.props.history.push(ROUTE.FIELD.REPAIR_LINE.MECHANIC_LOGS_PAYROLL_SUMMARY);
    };

    private onSave = (): void => {
        const { getToken, payrollMechanicLogsUploadRequest } = this.props;
        const { updatedChildIds } = this.state;
        // console.log(updatedChildIds);

        if (hasPayload(getToken)) {
            if (updatedChildIds.length > 0)
                payrollMechanicLogsUploadRequest({
                    token: getToken.payload.response.token,
                    request: updatedChildIds
                });
        };
    };

    private onSelectionChange = (row: any): void => {
        const selectedList: IPayrollMechanicLogs[] = row.selectedRowsData;
        const uploadList: any = [];
        selectedList.map(({ id, defect_ID }) => uploadList.push({ id, defect_ID }))
        // console.log(uploadList);

        this.setState({
            ...this.state,
            updatedChildIds: uploadList
        });
    };

    private setDataToState = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        
        if (hasPayload(this.props.getToken)) {
            if (this.props.getToken.payload.response.payrollAccess) {
                if (isNotLoaded(this.props.getPayrollMechanicLogs)) {
                    this.getDataForTable();
                };

                if (hasPayload(this.props.getPayrollMechanicLogs)) {
                    const data: IPayrollMechanicLogs[] = this.props.getPayrollMechanicLogs.payload.response;

                    this.setState({ data });
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && (this.props.getToken.payload.response.payrollAccess)) {
            this.props.getPayrollMechanicLogsRequest({
                token: this.props.getToken.payload.response.token,
            });
        }
    };

}

const mapStateToProps = (state: IStore): IPayrollMechanicLogsStoreProps => ({
    getToken: getToken(state),
    getPayrollMechanicLogs: getPayrollMechanicLogs(state),
    payrollMechanicLogsUploadStatus: payrollMechanicLogsUploadStatus(state),
    getMechanicLogsVersionHistory: getMechanicTimecardVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IPayrollMechanicLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getPayrollMechanicLogsRequest: (data: IGetPayrollMechanicLogsRequest) => dispatch(getPayrollMechanicLogsLoadAction(data)),
    payrollMechanicLogsUploadRequest: (request: IPayrollMechanicLogsUploadRequest): unknown => dispatch(payrollMechanicLogsUploadLoadAction(request)),
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest): unknown => dispatch(getMechanicTimecardVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollMechanicLogs);