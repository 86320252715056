import { IAddUpdateAttachmentStyle } from "../addStyle/addStyleConstants";

export enum IUPDATE_ATTACHMENT_STYLE_REQUEST {
    REQUEST = "updateAttachmentStyle/UPDATE_ATTACHMENT_STYLE_REQUEST",
    SUCCESS = "updateAttachmentStyle/UPDATE_ATTACHMENT_STYLE_SUCCESS",
    FAILED = "updateAttachmentStyle/UPDATE_ATTACHMENT_STYLE_FAILED"
};

export interface IUpdateAttachmentStyleRequest {
    token: string;
    request: IAddUpdateAttachmentStyle;
};