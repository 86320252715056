import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_UNIT_DETAILS_REQUEST, IUnitDetails } from "./getUnitDetailsConstants";
import { IGetUnitDetailsLoadAction, IGetUnitDetailsLoadFailedAction, IGetUnitDetailsSuccessAction, getUnitDetailsLoadFailedAction, getUnitDetailsLoadSuccessAction } from "./getUnitDetailsActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getUnitDetailsEpic: Epic = (
    action$: ActionsObservable<IGetUnitDetailsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetUnitDetailsSuccessAction | IGetUnitDetailsLoadFailedAction> =>
    action$.ofType(IGET_UNIT_DETAILS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IUnitDetails>>(
                    END_POINTS.FIELD.GET_UNIT_DETAILS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IUnitDetails>): IGetUnitDetailsSuccessAction => {
                            return getUnitDetailsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getUnitDetailsLoadFailedAction(response.message)))
                    )
            )
        );