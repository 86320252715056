
export enum IGET_SOCT_LIST_REQUEST {
    REQUEST = "getSoctList/GET_SOCT_LIST_REQUEST",
    SUCCESS = "getSoctList/GET_SOCT_LIST_SUCCESS",
    FAILED = "getSoctList/GET_SOCT_LIST_FAILED"
};

export interface ISoctListRequest {
    token: string;
};


export interface ISoctList {
    roW_ID: number;
    equipment_ID: number;
    unit_Number: string;
    equipment_Type: string;
    equipment: string;
    make: string;
    model: string;
    serial_Number: string;
    add_To_SOCT: string;
    move_Status: string;
    working_Site_ID: number;
    working_Site_Sub_ID: number;
    site_Name: string;
    site_Address: string;
    sub_Site_Name: string;
    sub_Site_Address: string;
    longitude: string;
    latitude: string;
    job_Number_ID: number;
    job_No: string;
    service_Hours: string;
    socT_ID: number;
    meter_Date: string;
    service_Date: string;
    current_Hours: number;
    next_250_Due: number;
    next_500_Due: number;
    next_1000_Due: number;
    next_1500_Due: number;
    next_3000_Due: number;
    last_EO: number;
    last_500: number;
    last_1000: number;
    last_1500: number;
    last_3000: number;
    machine_Hours: number;
    socT_Comments: string;
    move_Details: string;
    custom_Service_Due: string;
    ecm: string;
    geotab_Sync: string;
    custom_Service_ID: string;
    custom_Service_Comments: string;
    id: number;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    record_ID: number;
    meter_App_Status: string;
    meter_Reading_Status: string;
    location: string;
    comments: string;
    status: string;
    sub_Location_ID: number;
    sub_Location: string;
    padded_Unit: string;
    sT_ID: string;
    service_Truck: string;
    details: string;
    repair_Status: string;
    socT_Status: string;
    repair_Status_ID: number;
    move_Status_ID: number;
    unit_Display: string;
    moveRequest_By_Name: string;
    moveRequest_By_Mobile: string;
    wsS_ID: number;
    site_Status: string;
};
