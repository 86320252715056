import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_PAYROLL_MECHANIC_LOGS_REQUEST, IPayrollMechanicLogs } from "./getPayrollMechanicLogsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetPayrollMechanicLogsLoadAction, IGetPayrollMechanicLogsLoadFailedAction, IGetPayrollMechanicLogsSuccessAction } from "./getPayrollMechanicLogsActions";
import { IPayrollMechanicLogsUploadSuccessAction } from "../payrollMechanicLogsUpload/payrollMechanicLogsUploadActions";
import { IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST } from "../payrollMechanicLogsUpload/payrollMechanicLogsUploadConstants";
import { IApproveMechanicLogSuccessAction } from "../approveMechanicLog/approveMechanicLogActions";
import { IAPPROVE_MECHANIC_LOG_REQUEST } from "../approveMechanicLog/approveMechanicLogConstants";

type Actions =
    | IGetPayrollMechanicLogsLoadAction
    | IGetPayrollMechanicLogsSuccessAction
    | IGetPayrollMechanicLogsLoadFailedAction
    | IPayrollMechanicLogsUploadSuccessAction
    | IApproveMechanicLogSuccessAction
    | IFlushDataSuccessAction;

export const GetPayrollMechanicLogsReducer = (state: Server<SurewayAPIResponse<IPayrollMechanicLogs[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IPayrollMechanicLogs[]>> => {
    switch (action.type) {
        case IGET_PAYROLL_MECHANIC_LOGS_REQUEST.REQUEST:
            return loading;

        case IGET_PAYROLL_MECHANIC_LOGS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PAYROLL_MECHANIC_LOGS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IPAYROLL_MECHANIC_LOGS_UPLOAD_REQUEST.SUCCESS:
            return notLoaded;

        case IAPPROVE_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};