import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENT_MOVES_REQUEST, IAttachmentMove, IGetAttachmentMoveRequest } from "./getAttachmentMovesConstants";

export interface IGetAttachmentMovesLoadAction {
    type: IGET_ATTACHMENT_MOVES_REQUEST.REQUEST;
    data: IGetAttachmentMoveRequest
}
export const getAttachmentMovesLoadAction = (data: IGetAttachmentMoveRequest): IGetAttachmentMovesLoadAction => ({
    type: IGET_ATTACHMENT_MOVES_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentMovesSuccessAction {
    type: IGET_ATTACHMENT_MOVES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IAttachmentMove>>;
}
export const getAttachmentMovesLoadSuccessAction = (list: SurewayAPIResponse<ById<IAttachmentMove>>): IGetAttachmentMovesSuccessAction => ({
    type: IGET_ATTACHMENT_MOVES_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentMovesLoadFailedAction {
    type: IGET_ATTACHMENT_MOVES_REQUEST.FAILED;
    message: string;
}
export const getAttachmentMovesLoadFailedAction = (message: string): IGetAttachmentMovesLoadFailedAction => ({
    type: IGET_ATTACHMENT_MOVES_REQUEST.FAILED,
    message
});
