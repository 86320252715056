import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_HAZARD_TYPE_REQUEST, IAddHazardTypeRequest } from "./addHazardTypeConstants";


export interface IAddHazardTypeLoadAction {
    type: IADD_HAZARD_TYPE_REQUEST.REQUEST;
    data: IAddHazardTypeRequest
}
export const addHazardTypeLoadAction = (data: IAddHazardTypeRequest): IAddHazardTypeLoadAction => ({
    type: IADD_HAZARD_TYPE_REQUEST.REQUEST,
    data
});
export interface IAddHazardTypeSuccessAction {
    type: IADD_HAZARD_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addHazardTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddHazardTypeSuccessAction => ({
    type: IADD_HAZARD_TYPE_REQUEST.SUCCESS,
    message
});
export interface IAddHazardTypeLoadFailedAction {
    type: IADD_HAZARD_TYPE_REQUEST.FAILED;
    message: string;
}
export const addHazardTypeLoadFailedAction = (message: string): IAddHazardTypeLoadFailedAction => ({
    type: IADD_HAZARD_TYPE_REQUEST.FAILED,
    message
});
