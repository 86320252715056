
export enum IDELETE_FILTER_PART_REQUEST {
    REQUEST = "deleteFilterPart/DELETE_FILTER_PART_REQUEST",
    SUCCESS = "deleteFilterPart/DELETE_FILTER_PART_SUCCESS",
    FAILED = "deleteFilterPart/DELETE_FILTER_PART_FAILED"
};

export interface IDeleteFilterPartRequest {
    token: string;
    request: IDeleteUpdateFilterPart;
};

export interface IDeleteUpdateFilterPart {
    id: number;
};