import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl,  userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IShopEquipmentRequest } from "../../../redux/field/getEquipments/getEquipmentConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../shared/roleBaseAccessForShop";
import { ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getEquipmentTypes } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesAccessor";
import { getEquipmentTypesLoadAction } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesActions";
import { IGetEquipmentType, IGetEquipmentTypeRequest } from "../../../redux/field/getEquipmentTypes/getEquipmentTypesConstants";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { ShopSubHeader } from "../../header/shopSubHeader";


interface IEquipmentTypesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getEquipmentTypes: Server<SurewayAPIResponse<ById<IGetEquipmentType>>>;
};

interface IEquipmentTypesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getEquipmentTypesRequest: (data: IShopEquipmentRequest) => unknown;
};


interface IEquipmentTypesOwnProps {

};

interface IEquipmentTypesState {

};

const EquipmentTypesStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-right {
        position: absolute;
        right: 3%;
        height: 3%;
        top: 2%;
    };

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IEquipmentTypesProps = RouteComponentProps
    & IEquipmentTypesStoreProps
    & IEquipmentTypesDispatchProps
    & IEquipmentTypesOwnProps;

class EquipmentTypes extends PureComponent<IEquipmentTypesProps, IEquipmentTypesState> {

    public constructor(props: IEquipmentTypesProps) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
        this.callServer();
    };

    public componentDidUpdate(prevProps: IEquipmentTypesProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getEquipmentTypes } = this.props;
        const data = hasPayload(getEquipmentTypes) ? getEquipmentTypes.payload.response : [];

        return (
            <PageSpacing title="Equipment Types" description="SHOP - Equipment Types" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadAndWrite]}>

                    <ShopSubHeader
                        {...this.props}
                    />

                    <EquipmentTypesStyles>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />
                                <h2>EQUIPMENT TYPES </h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12}>
                                <LADevExtremeGrid
                                    data={Object.values(data)}
                                    onEdit={this.onEdit}
                                    onClick={this.onClick}
                                    searchPanel={true}
                                    filterHeader={true}
                                    export={true}
                                    actionWidth={120}
                                    removeStyleBtn={true}
                                    height={940}
                                    exportFileName="Equipment Types"
                                    storageKey="equipmentTypesListSessionKey"
                                    getRefreshDataCall={this.getDataForTable}
                                    columns={[
                                        { name: "name", caption: "Name", type: "string" },
                                        { name: "active", caption: "Active", type: "string" },
                                        { name: "modified", caption: "Modified", type: "datetime" },
                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                    ]}
                                />
                            </LAGridItem>
                        </LAGrid>
                    </EquipmentTypesStyles>
                </RoleBasedAccessForShop>
            </PageSpacing>
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.EQUIPMENT_TYPE.TYPE
        });
    };

    private onClick = (): void => {

    };

    private handleTabClick = (e: any, value: string): void => {
        if (value === "list")
            
            this.props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
                search: getTokenFromUrl(false)
              });
        if (value === "type")
            
            this.props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT_TYPE.INDEX,
                search: getTokenFromUrl(false)
              });
        if (value === "make")
            
            this.props.history.push({
                pathname: ROUTE.FIELD.MANUFACTURER.INDEX,
                search: getTokenFromUrl(false)
              });
        if (value === "model")
            
            this.props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
                search: getTokenFromUrl(false)
              });
        if (value === "config")
            
            this.props.history.push({
                pathname: ROUTE.FIELD.CONFIG.INDEX,
                search: getTokenFromUrl(false)
              });
    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.EQUIPMENT_TYPE.TYPE
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipmentTypes) )
            this.getDataForTable();
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && shopRoleCheck([ReadOnly, ReadAndWrite])) {
            this.props.getEquipmentTypesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IEquipmentTypesStoreProps => ({
    getToken: getToken(state),
    getEquipmentTypes: getEquipmentTypes(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IEquipmentTypesDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getEquipmentTypesRequest: (data: IGetEquipmentTypeRequest) => dispatch(getEquipmentTypesLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTypes);