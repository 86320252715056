import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import PageSpacing from "../../shared/pageSpacing";
import { SurewayAPIResponse } from "../../shared/publicInterfaces";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, userName } from "../../shared/constExports";
import SOCTHome from "./equipmenttrackerhome";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getEquipmentMoveRequestLoadAction } from "../../../redux/field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestActions";
import { IEquipmentMoveRequest, IEquipmentMoveRequestRequest } from "../../../redux/field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestConstants";
import { getEquipmentMoveRequests } from "../../../redux/field/equipmentTracker/getEquipmentMoveRequest/getEquipmentMoveRequestAccessor";
import LAErrorBox from "../../shared/errorBox";

type views = "In Progress" | "Moved" | "Completed" | "Cancelled";

interface IMoveRequestStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    moves: Server<IEquipmentMoveRequest[]>;
};

interface IMoveRequestDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMovesRequest: (data: IEquipmentMoveRequestRequest) => unknown;
};


interface IMoveRequestOwnProps {

};

interface IMoveRequestState {
    view: views;
    data: IEquipmentMoveRequest[];

};

const MoveRequestStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right-username {
        top: 0.5%;
        right: 2.5%;
        position: absolute;
        color: red;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMoveRequestProps = RouteComponentProps
    & IMoveRequestStoreProps
    & IMoveRequestDispatchProps
    & IMoveRequestOwnProps;

class MoveRequest extends PureComponent<IMoveRequestProps, IMoveRequestState> {

    public constructor(props: IMoveRequestProps) {
        super(props);
        this.state = {
            view: "In Progress",
            data: []
        };
    }

    public componentDidMount(): void {
        this.callServer();

    };

    public componentDidUpdate(prevProps: IMoveRequestProps): void {
        if (this.props !== prevProps)
            this.callServer();

    };


    public render(): ReactNode {

        const { data, view } = this.state;
        const { moves } = this.props;

        return (
            <PageSpacing title="EQUIPMENT TRACKER" description="EQUIPMENT TRACKER - MOVE REQUEST" fixedSpaceOnSmallerScreens={true}>
                <MoveRequestStyles>
                    <LAGrid>

                        <LAGridItem xs={12}>
                            <SOCTHome history={this.props.history} />

                            <h2 className="text-center title">EQUIPMENT MOVE REQUEST</h2>
                            <hr />

                            <LAGridItem xs={12}>
                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                    <FormControlLabel value="In Progress" control={<Radio />} label="In Progress" />
                                    <FormControlLabel value="Moved" control={<Radio />} label="Moved" />
                                    <FormControlLabel value="Completed" control={<Radio />} label="Completed" />
                                    <FormControlLabel value="Cancelled" control={<Radio />} label="Cancelled" />
                                </RadioGroup>
                            </LAGridItem>

                            {moves.kind === STATUS_ENUM.LOADING && <LALoading message="Loading data..." />}
                            {moves.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load data..." />}

                            {moves.kind === STATUS_ENUM.SUCCEEDED &&
                                <LAGrid>

                                    <LAGridItem xs={12}>
                                        <LADevExtremeGrid
                                            data={data}
                                            columnChoose={true}
                                            columnsHiding={true}
                                            onEdit={this.onEdit}
                                            onClick={this.onClick}
                                            searchPanel={true}
                                            filterHeader={true}
                                            export={true}
                                            actionWidth={90}
                                            removeStyleBtn={true}
                                            height={1200}
                                            key="id"
                                            id="MoveRequestET"
                                            customRowColor={true}
                                            storageKey="MoveRequestET"
                                            exportFileName="MoveRequest"
                                            onCustomIconClick={this.handleCustomIcon}
                                            getRefreshDataCall={this.getDataForTable}
                                            columns={[
                                                { name: "unit_Number", caption: "Unit #", type: "number" },
                                                { name: "make", caption: "Make", type: "string" },
                                                { name: "model", caption: "Model", type: "string" },
                                                { name: "details", caption: "Details", type: "string" },
                                                { name: "from_Location", caption: "From Location", type: "string" },
                                                { name: "to_Location", caption: "From Location", type: "string" },
                                                { name: "completed_Date", caption: "From Location", type: "date" },
                                                { name: "modified_Notes", caption: "Sub Location", type: "string" },
                                                { name: "status", caption: "Status", type: "string" },
                                                { name: "driver", caption: "Driver", type: "string" },
                                                { name: "job", caption: "Job #", type: "string" },
                                                { name: "move", caption: "Move", type: "button" },
                                                { name: "history", caption: "History", type: "ws-action-buttons" },
                                                { name: "status-button", caption: "Approve/Reject", type: "ETMoveStatus" },
                                            ]}
                                        />
                                    </LAGridItem>

                                </LAGrid>}
                        </LAGridItem>

                    </LAGrid>
                </MoveRequestStyles>
            </PageSpacing>
        );
    }

    private onViewClick = (event: React.ChangeEvent<HTMLInputElement>, value: any): void => {
        this.setState({ view: value });

        if (hasPayload(this.props.getToken)) {
            this.props.getMovesRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    View: value
                }
            });
        }
    };

    private handleCustomIcon = (name: string, data: any): void => {
        if(name === "history"){
            if (data.unit_Number !== undefined) {
                window.open(
                    `https://reportserver.propsense.com/reports/report/Reports/Field/ET_Move_History?Unit=${data.unit_Number}`,
                    "_blank"
                );
            }
        } else if(name === "move"){

        } else if(name === "ETMoveStatus"){

        }
    };

    private onEdit = (e: any): void => {

    };

    private onClick = (): void => {

    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {

            if (isNotLoaded(this.props.moves)) {
                this.props.getMovesRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        View: this.state.view
                    }
                });
            };

            if (hasPayload(this.props.moves)) {
                this.setState({
                    data: this.props.moves.payload
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMovesRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    View: this.state.view
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IMoveRequestStoreProps => ({
    getToken: getToken(state),
    moves: getEquipmentMoveRequests(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMoveRequestDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMovesRequest: (data: IEquipmentMoveRequestRequest) => dispatch(getEquipmentMoveRequestLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveRequest);