import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IGetPMLookupsLoadAction, IGetPMLookupsLoadFailedAction, IGetPMLookupsSuccessAction } from "./getPMLookupsActions";
import { IGET_PM_LOOKUPS_REQUEST, IPMLookups } from "./getPMLookupsConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IAddServiceDueSuccessAction } from "../addServiceDue/addServiceDueActions";
import { IUpdateServiceDueSuccessAction } from "../updateServiceDue/updateServiceDueActions";
import { IADD_SERVICE_DUE_REQUEST } from "../addServiceDue/addServiceDueConstants";
import { IUPDATE_SERVICE_DUE_REQUEST } from "../updateServiceDue/updateServiceDueConstants";
import { IADD_SERVICE_SECTION_REQUEST } from "../addServiceSection/addServiceSectionConstants";
import { IUPDATE_SERVICE_SECTION_REQUEST } from "../updateServiceSection/updateServiceSectionConstants";
import { IAddServiceSectionSuccessAction } from "../addServiceSection/addServiceSectionActions";
import { IUpdateServiceSectionSuccessAction } from "../updateServiceSection/updateServiceSectionActions";
import { IAPPROVE_SERVICE_SHEET_REQUEST } from "../approveServiceSheet/approveServiceSheetConstants";
import { IApproveServiceSheetSuccessAction } from "../approveServiceSheet/approveServiceSheetActions";

type Actions =
    | IGetPMLookupsLoadAction
    | IGetPMLookupsSuccessAction
    | IGetPMLookupsLoadFailedAction
    | IAddServiceDueSuccessAction
    | IUpdateServiceDueSuccessAction
    | IAddServiceSectionSuccessAction
    | IUpdateServiceSectionSuccessAction
    | IApproveServiceSheetSuccessAction
    | IFlushDataSuccessAction;

export const GetPMLookupsReducer = (state: Server<SurewayAPIResponse<IPMLookups>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IPMLookups>> => {
    switch (action.type) {
        case IGET_PM_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_PM_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_PM_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

            case IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS:
                return notLoaded;

        default:
            return state;
    }
};