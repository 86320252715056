export enum MECHANIC_LOCAL_TOKEN {
    REQUEST =  "mechanicLocalToken/MECHANIC_LOCAL_TOKEN_REQUEST",
    SUCCESS =  "mechanicLocalToken/MECHANIC_LOCAL_TOKEN_SUCCESS",
    FAILED =  "mechanicLocalToken/MECHANIC_LOCAL_TOKEN_FAILED"
}

export interface IMechanicLocalToken {
    token: string;
    email: string;
    name: string;
    id: number;
    storedTime: string;
};

export const MechanicTokenStoreName = "mechanic-token";

export const saveToken = (token: string, email: string, name: string, id: number): void => {
    const tokenObj: IMechanicLocalToken = {
        email,
        token,
        name,
        id,
        storedTime: new Date().toString()
    };
    localStorage.setItem(MechanicTokenStoreName, JSON.stringify(tokenObj));
};

export const getStoredToken = (): IMechanicLocalToken | null => {
    const obj = localStorage.getItem(MechanicTokenStoreName);

    if (obj !== null) {
        const data: IMechanicLocalToken = JSON.parse(obj);
        const currentD = new Date();
        const storedD = new Date(data.storedTime);
        const difference = Math.abs(currentD.getTime() - storedD.getTime());
        var hourDifference = difference / 1000 / 3600;

        if (hourDifference > 8) {
            localStorage.clear();
            return null;
        } else {
            return data;
        }
    } else {
        return null;
    };
};