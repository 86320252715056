import { connect } from "react-redux";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading, { LACenteredLoading } from "../../shared/loading";
import SOCTHome from "./equipmenttrackerhome";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { ReactNode, PureComponent } from "react";
import PageSpacing from "../../shared/pageSpacing";
import { Prompt, RouteComponentProps } from "react-router";
import { DropPaper } from "../../shared/dragAndDropPaper";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { DispatchMove, Driver } from "./driverDispatchDragAndDrop";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ById, HasClass, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { getDrivers } from "../../../redux/field/getDrivers/getDriversAccessor";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getDriverLoadAction } from "../../../redux/field/getDrivers/getDriversActions";
import { userName, getTokenFromUrl, getDate, sortByName, pageAccessCheck, undefinedFunction } from "../../shared/constExports";
import { hasPayload, isNotLoaded, Server, STATUS, STATUS_ENUM } from "../../../redux/server";
import { IDriver, IDriverRequest } from "../../../redux/field/getDrivers/getDriversConstants";
import { getMoveRequests } from "../../../redux/field/getMoveRequests/getMoveRequestsAccessor";
import { getMoveRequestsLoadAction } from "../../../redux/field/getMoveRequests/getMoveRequestsActions";
import { saveDriverAssignment } from "../../../redux/field/saveDriverAssignment/saveDriverAssignmentAccessor";
import { ISaveDriverAssignment, ISaveDriverAssignmentRequest } from "../../../redux/field/saveDriverAssignment/saveDriverAssignmentConstants";
import { saveDriverAssignmentLoadAction } from "../../../redux/field/saveDriverAssignment/saveDriverAssignmentActions";
import { IMoveRequest, IMoveRequests, IMoveRequestsRequest } from "../../../redux/field/getMoveRequests/getMoveRequestsConstants";
import { LAButton, LASecondaryButton } from "../../shared/buttons";
import { BLACK_COLOR, DARK_GREY_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import LATextArea from "../../shared/textArea";
import { END_POINTS } from "../../../redux/endpoints";
import { LAPopover } from "../../shared/popOver";
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { IGetDriverCommunicationLogs, IGetDriverCommunicationLogsRequest } from "../../../redux/field/getDriverCommunicationLogs/getDriverCommunicationLogsConstants";
import { getDriverCommunicationLogsLoadAction } from "../../../redux/field/getDriverCommunicationLogs/getDriverCommunicationLogsActions";
import { getDriverCommunicationLogs } from "../../../redux/field/getDriverCommunicationLogs/getDriverCommunicationLogsAccessor";
import { getETLookups } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsAccessor";
import { getETLookupsLoadAction } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsActions";
import { IETLookups, IETLookupsRequest } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsConstants";
import { LACheckBox } from "../../shared/checkBox";


interface IDriverDispatchStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    lookup: Server<SurewayAPIResponse<IETLookups>>;
    getDriversStatus: Server<SurewayAPIResponse<ById<IDriver>>>;
    saveDriverAssignmentStatus: Server<SurewayAPIResponse<string>>;
    getMoveRequestStatus: Server<SurewayAPIResponse<IMoveRequests>>;
    communicationLogs: Server<SurewayAPIResponse<ById<IGetDriverCommunicationLogs>>>;
};

interface IDriverDispatchDispatchProps {
    lookupRequest: (data: IETLookupsRequest) => unknown;
    getDriverRequest: (data: IDriverRequest) => unknown;
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMoveRequestsRequest: (data: IMoveRequestsRequest) => unknown;
    saveDriverAssignmentRequest: (data: ISaveDriverAssignmentRequest) => unknown;
    getDriverCommLogsRequest: (data: IGetDriverCommunicationLogsRequest) => unknown;
};


interface IDriverDispatchOwnProps {

};

export type IMoveSelection = "assigned" | "unassigned";
export type IMoveSelectionCheckbox = "trucking" | "parts";

interface IDriverDispatchState {
    lastPull: string;
    sortList: number[];
    logsPopup: boolean;
    pullAlert: boolean;
    hasChange: boolean;
    moveSearch: string;
    serverError: string;
    driverSearch: string;
    unAssigned: ById<IMoveRequest>;
    assigned: ById<IMoveRequest>;
    // allMoveRequest: ById<IMoveRequest>;
    // truckingMoveRequest: ById<IMoveRequest>;
    // partsMoveRequest: ById<IMoveRequest>;
    assignedPartsMoveRequest: ById<IMoveRequest>;
    unAssignedPartsMoveRequest: ById<IMoveRequest>;
    assignedTruckingMoveRequest: ById<IMoveRequest>;
    unAssignedTruckingMoveRequest: ById<IMoveRequest>;
    moveSelection: IMoveSelection;
    truckingMoveCheck: boolean;
    partsMoveCheck: boolean;
};

const DriverDispatchStyles = styled(LAPaperWithLessPadding)`
    margin: 10px 10px;
    word-break: break-word;
    font-size: 14px;
    
    .red-text {
        color: ${RED_COLOR};
    };

    .green-text {
        color: ${GREEN_COLOR};
    };

    @keyframes flash {
        0%, 100% { opacity: 1; }
        50% { opacity: 0; }
    }

    .warning-text {
        color: ${WARNING_COLOR}; 
        font-weight: bold;
        animation: flash 1s ease-in-out infinite;  /* Flash animation */
    }
    
    .green-border {
        border: 4px solid green;
    };
`;

type IDriverDispatchProps = RouteComponentProps
    & IDriverDispatchStoreProps
    & IDriverDispatchDispatchProps
    & IDriverDispatchOwnProps;

class DriverDispatch extends PureComponent<IDriverDispatchProps, IDriverDispatchState> {

    public constructor(props: IDriverDispatchProps) {
        super(props);
        this.state = {
            unAssigned: {},
            assigned: {},
            sortList: [],
            lastPull: "",
            moveSearch: "",
            serverError: "",
            pullAlert: false,
            hasChange: false,
            logsPopup: false,
            driverSearch: "",
            moveSelection: "unassigned",
            truckingMoveCheck: false,
            partsMoveCheck: false,
            // allMoveRequest: {},
            // truckingMoveRequest: {},
            // partsMoveRequest: {},
            assignedPartsMoveRequest: {},
            unAssignedPartsMoveRequest: {},
            assignedTruckingMoveRequest: {},
            unAssignedTruckingMoveRequest: {},
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.onInitialLoad();
        await this.callServer();
    };

    public componentDidUpdate(prevProps: IDriverDispatchProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.saveDriverAssignmentStatus !== prevProps.saveDriverAssignmentStatus) {
                if (this.props.saveDriverAssignmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveDriverAssignmentStatus.message });
            };

        };
    };


    public render(): ReactNode {

        const { unAssigned, driverSearch, moveSearch, moveSelection, hasChange, pullAlert, logsPopup, sortList, assigned, truckingMoveCheck, partsMoveCheck } = this.state;
        const { getMoveRequestStatus, getDriversStatus, saveDriverAssignmentStatus, communicationLogs, lookup } = this.props;
        const drivers = hasPayload(getDriversStatus) && getDriversStatus.payload.response ? getDriversStatus.payload.response : {};
        const filterDriverList = Object.fromEntries(
            Object.entries(drivers).filter(([_, x]: [string, IDriver]) => (x.terminated_Date && x.active === "No") ? new Date(x.terminated_Date) > new Date() : (x.active === "No" && x.terminated_Date === null) ? false : true)
        );
        // console.log(filterDriverList, drivers);
        const logsData = hasPayload(communicationLogs) && communicationLogs.payload.response ? Object.values(communicationLogs.payload.response) : [];
        const trailerList = hasPayload(lookup) && lookup.payload.response.trailer_Config ? lookup.payload.response.trailer_Config : [];
        const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch"); 

        return (
            <PageSpacing title="Driver Dispatch" description="Driver Dispatch" fixedSpaceOnSmallerScreens={true}>
                <DriverDispatchStyles>

                    <SOCTHome history={this.props.history} />

                    {getMoveRequestStatus.kind === STATUS.LOADING && <LALoading message={pullAlert ? "The list has been updated since last refresh. Page is getting refreshed. Please assign drivers with latest information!" : "Loading... Please wait"} />}
                    {getMoveRequestStatus.kind === STATUS.FAILED && <LAErrorBox text="Failed to load data... Please try again" />}

                    {((getMoveRequestStatus.kind === STATUS_ENUM.SUCCEEDED) && (saveDriverAssignmentStatus.kind !== STATUS_ENUM.LOADING)) 
                    && <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                        <LAGrid className="pt-2">

                            {pullAlert && <LAGridItem xs={12}>
                                <LAErrorBox text="The list has been updated since last refresh. Please refresh the page and assign drivers with the latest information!" />
                            </LAGridItem>}

                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Save Changes"
                                    onClick={this.callEndpoint}
                                />
                            </LAGridItem>
                            <LAGridItem xs={6} sm={6} md={8}>
                                <LAPaperWithLessPadding>
                                    <h2 className="text-center">MOVES</h2>
                                    <hr />

                                    <RadioGroup className="view-btn text-center" row aria-label="" name="radioGroup" value={moveSelection} onChange={this.onTypeChange}>
                                        <FormControlLabel value="unassigned" control={<Radio />} label="Unassigned" />
                                        <FormControlLabel value="assigned" control={<Radio />} label="Assigned" />

                                        <LASecondaryButton
                                            label="Reset Search"
                                            onClick={this.clearMoveSearch}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <LACheckBox
                                            value={truckingMoveCheck}
                                            disabled={moveSelection === "assigned" || !(getPartsDispatchRole === true) ? true : undefined}
                                            label="Trucking"
                                            name="trucking"
                                            onChange={this.handleTruckingPartsCheckbox}
                                        />
                                        <LACheckBox
                                            value={partsMoveCheck}
                                            disabled={moveSelection === "assigned" || getPartsDispatchRole === true ? true : undefined}
                                            label="Parts"
                                            name="parts"
                                            onChange={this.handleTruckingPartsCheckbox}
                                        />
                                    </RadioGroup>

                                    <LATextArea
                                        rowsMax={1}
                                        className="p-2"
                                        fullWidth={true}
                                        name="moveSearch"
                                        value={moveSearch}
                                        label="Search move by name"
                                        onChange={this.handleMoveSearch}
                                    />

                                    <LAGrid spacing={0}>
                                        {(sortList.length > 0) &&
                                            sortList
                                                .map((x, index) => {
                                                    return (
                                                        <DispatchMove
                                                            {...this.props}
                                                            {...this.state}
                                                            key={index}
                                                            drivers={filterDriverList}
                                                            moveSearch={moveSearch}
                                                            trailerList={trailerList}
                                                            onChange={this.handleChange}
                                                            onDelete={this.handleDelete}
                                                            moveSelection={moveSelection}
                                                            onPilotDelete={this.onPilotDelete}
                                                            onConfigRemove={this.onConfigRemove}
                                                            data={this.assignedMove() ? assigned[x] : unAssigned[x]}
                                                            getPartsDispatchRole={getPartsDispatchRole}
                                                        />
                                                    );
                                                })}
                                    </LAGrid>
                                </LAPaperWithLessPadding>
                            </LAGridItem>

                            <LAGridItem xs={6} sm={6} md={4}>
                                <LAPaperWithLessPadding>
                                    <h2 className="text-center">DRIVERS</h2>
                                    <hr />

                                    <LASecondaryButton
                                            label="Reset Search"
                                            onClick={this.clearDriverSearch}
                                        />

                                    <LATextArea
                                        rowsMax={1}
                                        className="p-2"
                                        fullWidth={true}
                                        name="driverSearch"
                                        value={driverSearch}
                                        label="Search driver by name"
                                        onChange={this.handleDriverSearch}
                                    />

                                    <Droppable key={1} droppableId="driver">

                                        {(provided, snapshot) => (
                                            <DropPaper className="text-center"
                                                innerRef={provided.innerRef}
                                                styleDraggar={snapshot.isDraggingOver}>

                                                <LAGrid spacing={0}>
                                                    {Object.values(filterDriverList)
                                                    .sort((a, b) => sortByName(a, b, "driver_Firstname"))
                                                    .filter((x) => getPartsDispatchRole === true ? true : (x.truck_Driver === "Yes" || x.pilot_Driver === "Yes"))
                                                        .filter((x) => driverSearch.length > 0 ?
                                                            x.driver_Firstname.toLowerCase().includes(driverSearch.toLowerCase())
                                                            || x.driver_Lastname.toLowerCase().includes(driverSearch.toLowerCase())
                                                            : true)
                                                        .map((x, index) => {
                                                            return (
                                                                <Driver
                                                                    data={x}
                                                                    key={index}
                                                                    index={index}
                                                                    assigned={assigned}
                                                                    unAssigned={unAssigned}
                                                                    onLogClick={this.handleLogClick}
                                                                />
                                                            );
                                                        })}
                                                </LAGrid>
                                                {provided.placeholder}
                                            </DropPaper>
                                        )}

                                    </Droppable>
                                </LAPaperWithLessPadding>
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Save Changes"
                                    onClick={this.callEndpoint}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </DragDropContext>}

                    {(saveDriverAssignmentStatus.kind === STATUS_ENUM.LOADING) && <LACenteredLoading message="Please wait... we are processing your save request" />}

                    <RequestStatus
                        failedMessage="Move/s assignment failed! Please try again or contact IT.Developers@sureway.ca if the issue persists!"
                        requestStatus={saveDriverAssignmentStatus.kind}
                        successMessage="Move/s assignment is successful!"
                    />

                    <Prompt
                        when={hasChange}
                        message="You have unsaved changes. Please save it before leaving."
                    />

                    <LogPopup
                        data={logsData}
                        open={logsPopup}
                        status={communicationLogs.kind}
                        onCancel={this.handleLogPopupClose}
                    />

                </DriverDispatchStyles>
            </PageSpacing>
        );
    }

    private assignedMove = (): boolean => {
        return this.state.moveSelection === "assigned" ? true : false;
    };

    private onPilotDelete = (pilotId: number, id: number): void => {
        const { assigned, unAssigned } = this.state;

        const assignedView = this.assignedMove();

        const move = assignedView ? assigned[id] : unAssigned[id];
        const pilots: number[] = move.pilot_Ids ? move.pilot_Ids.split(",").map(Number) : [];
        const index = pilots.findIndex((x) => x === pilotId);

        if(index >= 0)
            pilots.splice(index, 1);

        if(assignedView){
            assigned[id] = {
                ...assigned[id],
                pilot_Ids: pilots.join(",")
            };
        } else {
            unAssigned[id] = {
                ...unAssigned[id],
                pilot_Ids: pilots.join(",")
            };
        }

        this.setState({
            ...this.state,
            assigned: {
                ...this.state.assigned,
                ...assigned
            },
            unAssigned: {
                ...this.state.unAssigned,
                ...unAssigned
            },
            hasChange: true
        });
    };

    private handleLogClick = (id: number): void => {
        if (hasPayload(this.props.getToken)) {

            this.props.getDriverCommLogsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    driverID: id
                }
            });

            this.setState({ logsPopup: true });
        }
    };

    private handleLogPopupClose = (): void => {
        this.setState({ logsPopup: false });
    };

    private clearDriverSearch = (): void => {
        this.setState({
            driverSearch: ""
        });
    };

    private clearMoveSearch = (): void => {
        this.setState({
            moveSearch: ""
        });
    };

    private handleTruckingPartsCheckbox = (checked: boolean, name?: string): void => {
        const { assignedTruckingMoveRequest, unAssignedTruckingMoveRequest, assignedPartsMoveRequest, unAssignedPartsMoveRequest, assigned, unAssigned} = this.state;
        const assignedData: ById<IMoveRequest> = assigned;
        const unAssignedData: ById<IMoveRequest> = unAssigned;
        // console.log(partsMoveRequest)

        if(name === "trucking") {
            if(checked) {
                this.setState({
                    ...this.state,
                    assigned: {...assigned,...assignedTruckingMoveRequest},
                    unAssigned: {...unAssigned,...unAssignedTruckingMoveRequest},
                    truckingMoveCheck: checked
                })
            }
            else {
                const assignedMoveRequestsData = { ...assignedData }; 
                Object.keys(assignedTruckingMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key]; 
                });
                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedTruckingMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });

                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    truckingMoveCheck: checked
                })
            }
        }

        else if(name === "parts") {
            if(checked) {
                this.setState({
                    ...this.state,
                    assigned: {...assigned,...assignedPartsMoveRequest},
                    unAssigned: {...unAssigned,...unAssignedPartsMoveRequest},
                    partsMoveCheck: checked
                })
            }
            else {
                const assignedMoveRequestsData = { ...assignedData }; 
                Object.keys(assignedPartsMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key]; 
                });
                
                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedPartsMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });
                    
                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    partsMoveCheck: checked
                })
            }
        }
    }

    private onTypeChange = (event: React.ChangeEvent<HTMLInputElement>, moveSelection: any): void => {
        const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch");
        const { assignedTruckingMoveRequest, unAssignedTruckingMoveRequest,
            assignedPartsMoveRequest, unAssignedPartsMoveRequest, assigned, unAssigned
        } = this.state;
        const assignedData: ById<IMoveRequest> = assigned;
        const unAssignedData: ById<IMoveRequest> = unAssigned;  
            // console.log(getPartsDispatchRole)
            
        if(moveSelection === "assigned") {
            if(getPartsDispatchRole === true) {
                const assignedMoveRequestsData = { ...assignedData };
                Object.keys(assignedTruckingMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key]; 
                });

                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedTruckingMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });

                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    moveSelection,
                    truckingMoveCheck: getPartsDispatchRole === true ? false : true,
                    partsMoveCheck: getPartsDispatchRole === true ? true : false,
                });
                    
            }
            else {
                const assignedMoveRequestsData = { ...assignedData }; 
                Object.keys(assignedPartsMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key]; 
                });

                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedPartsMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });

                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    moveSelection,
                    truckingMoveCheck: true,
                    partsMoveCheck: false,
                });
            }
        }
        else {
            if(getPartsDispatchRole === true) {
                const assignedMoveRequestsData = { ...assignedData }; 
                Object.keys(assignedTruckingMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key];
                });

                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedTruckingMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });

                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    moveSelection
                });
                
            }
            else {
                const assignedMoveRequestsData = { ...assignedData };
                Object.keys(assignedPartsMoveRequest).forEach((key) => {
                    delete assignedMoveRequestsData[key];
                });

                const unAssignedMoveRequestsData = { ...unAssignedData }; 
                Object.keys(unAssignedPartsMoveRequest).forEach((key) => {
                    delete unAssignedMoveRequestsData[key];
                });

                this.setState({
                    ...this.state,
                    assigned: assignedMoveRequestsData,
                    unAssigned: unAssignedMoveRequestsData,
                    moveSelection
                });
            }
        }
    };

    private handleChange = (name: string, value: string, id: number) => {
        const assignedView = this.assignedMove();

        this.setState((prevState) => {
            const { assigned, unAssigned } = prevState;
      
            if (name === "dispatch_Notes") {
                if(assignedView){
                    assigned[id].dispatch_Notes = value;
                } else {
                    unAssigned[id].dispatch_Notes = value;
                }
            }
      
            if (name === 'dispatch_Priority' && !isNaN(Number(value))) {
                if(assignedView){
                    assigned[id].dispatch_Priority = Number(value);
                } else {
                    unAssigned[id].dispatch_Priority = Number(value);
                }
            }

            if(name === "trailer_Config_ID"){
                if(assignedView){
                    assigned[id].trailer_Config_ID = Number(value);
                } else {
                    unAssigned[id].trailer_Config_ID = Number(value);
                }
            }

            if (name === "from_Demob") {
                if(assignedView){
                    assigned[id].from_Demob = value;
                } else {
                    unAssigned[id].from_Demob = value;
                }
            }
            if (name === "to_Mob") {
                if(assignedView){
                    assigned[id].to_Mob = value;
                } else {
                    unAssigned[id].to_Mob = value;
                }
            }
            if (name === "repair_Notes") {
                if(assignedView){
                    assigned[id].repair_Notes = value;
                } else {
                    unAssigned[id].repair_Notes = value;
                }
            }
      
            return {
                assigned: {
                    ...assigned,
                },
                unAssigned: {
                    ...unAssigned,
                },
                hasChange: true,
            };
          });
    };

    private onConfigRemove = (id: number): void => {
        const assignedView = this.assignedMove();

        this.setState((prevState) => {
            const { assigned, unAssigned } = prevState;
      
            if(assignedView){
                assigned[id].trailer_Config_ID = null;
            } else {
                unAssigned[id].trailer_Config_ID = null;
            }
      
            return {
                assigned: {
                ...assigned,
              },
              unAssigned: {
                ...unAssigned,
              },
              hasChange: true,
            };
          });
    };

    private handleDelete = (id: number) => {
        const { assigned, unAssigned } = this.state;
        const assignedView = this.assignedMove();

        if(assignedView){
            assigned[id] = {
                ...assigned[id],
                driver_ID: 0,
                dispatch_Priority: null
            };
        } else {
            unAssigned[id] = {
                ...unAssigned[id],
                driver_ID: 0,
                dispatch_Priority: null
            };
        }

        this.setState({
            ...this.state,
            assigned: {
                ...this.state.assigned,
                ...assigned
            },
            unAssigned: {
                ...this.state.unAssigned,
                ...unAssigned
            },
            hasChange: true
        });
    };

    private handleDriverSearch = (name: string, value: string) => {
        this.setState({ driverSearch: value });
    };

    private handleMoveSearch = (name: string, value: string) => {
        this.setState({ moveSearch: value });
    };

    private onDragEnd = async (result: any): Promise<void> => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        const { assigned, unAssigned, driverSearch } = this.state;
        const { getDriversStatus } = this.props;
        const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch"); 

        const assignedView = this.assignedMove();

        const drivers = hasPayload(getDriversStatus) && getDriversStatus.payload.response ?
            Object.values(getDriversStatus.payload.response)
            .filter((x:  IDriver) => (x.terminated_Date && x.active === "No") ? new Date(x.terminated_Date) > new Date() : (x.active === "No" && x.terminated_Date === null) ? false : true)
            .sort((a, b) => sortByName(a, b, "driver_Firstname"))
            // .filter((x) => (x.truck_Driver === "Yes") || (x.pilot_Driver === "Yes"))
            .filter((x) => getPartsDispatchRole === true ? true : (x.truck_Driver === "Yes" || x.pilot_Driver === "Yes"))
                .filter((x) => driverSearch.length > 0 ?
                    x.driver_Firstname.toLowerCase().includes(driverSearch.toLowerCase())
                    || x.driver_Lastname.toLowerCase().includes(driverSearch.toLowerCase())
                    : true)

            : []

        const desId = destination.droppableId.includes("pilot") ? destination.droppableId.split("_")[1] : destination.droppableId;

        if(assignedView){
            if ((desId !== undefined && source.index !== undefined) && ((assigned[desId]) && (drivers[source.index]))) {

                if (destination.droppableId.includes("pilot")) {
                    
                    const dropId = drivers[source.index].id;
                    const val = assigned[desId];
                    const pilots: number[] = val.pilot_Ids ? val.pilot_Ids.split(",").map(Number) : [];
                    const check = pilots.findIndex(x => x === dropId);
    
                    if ((check < 0) && (drivers[source.index].pilot_Driver === "Yes")) {
    
                        pilots.push(dropId);
                        assigned[desId].pilot_Ids = pilots.join(",");
    
                        this.setState({
                            ...this.state,
                            assigned: {
                                ...this.state.assigned,
                                ...assigned
                            },
                            hasChange: true
                        });
                    }
    
                } else {
    
                    const driverId = drivers[source.index].id;
                    // console.log(driverId)
                    assigned[desId].driver_ID = driverId;
    
                    const assignedPriority = Object.values(assigned).filter((r) => r.driver_ID !== null && (r.driver_ID === driverId) && r.dispatch_Priority !== null).map(({ dispatch_Priority }) => dispatch_Priority);
                    const maxPriority = Math.max(Number(...assignedPriority.filter((r) => r !== undefined && r !== null)));
                    assigned[desId].dispatch_Priority = (maxPriority !== -Infinity) ? maxPriority + 1 : 1;
   
                    this.setState({
                        assigned: {
                            ...assigned
                        },
                        hasChange: true
                    });
                }
            };
        } else {
            if ((desId !== undefined && source.index !== undefined) && ((unAssigned[desId]) && (drivers[source.index]))) {

                if (destination.droppableId.includes("pilot")) {
    
                    const dropId = drivers[source.index].id;
                    const val = unAssigned[desId];
                    const pilots: number[] = val.pilot_Ids ? val.pilot_Ids.split(",").map(Number) : [];
                    const check = pilots.findIndex(x => x === dropId);
    
                    if ((check < 0) && (drivers[source.index].pilot_Driver === "Yes")) {
    
                        pilots.push(dropId);
                        unAssigned[desId].pilot_Ids = pilots.join(",");
    
                        this.setState({
                            ...this.state,
                            unAssigned: {
                                ...this.state.unAssigned,
                                ...unAssigned
                            },
                            hasChange: true
                        });
                    }
    
                } else {
    
                    const driverId = drivers[source.index].id;
                    // console.log(driverId)
                    unAssigned[desId].driver_ID = driverId;
    
                    const assignedPriority = Object.values(unAssigned).filter((r) => r.driver_ID !== null && (r.driver_ID === driverId) && r.dispatch_Priority !== null).map(({ dispatch_Priority }) => dispatch_Priority);
                    const maxPriority = Math.max(Number(...assignedPriority.filter((r) => r !== undefined && r !== null)));
                    unAssigned[desId].dispatch_Priority = (maxPriority !== -Infinity) ? maxPriority + 1 : 1;
    
                    this.setState({
                        unAssigned: {
                            ...unAssigned
                        },
                        hasChange: true
                    });
                }
            };
        }
    };

    private onDragStart = () => {
        // Vibrate on drag (only Mobile)
        if (window.navigator.vibrate) {
            window.navigator.vibrate(200);
        };
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            const getRole = pageAccessCheck(this.props.getToken, "etAccess");
            if (getRole !== "RW") {
                this.props.history.push(ROUTE.UNAUTHORIZED);
            };
        };

        if (hasPayload(this.props.getToken)) {
            const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch"); 
            if(getPartsDispatchRole === true) {
                if(isNotLoaded(this.props.getMoveRequestStatus))
                    this.props.getMoveRequestsRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            Type: "All",
                            // PartsDispatch: "Yes"
                        }
                    });

                if(isNotLoaded(this.props.getDriversStatus))
                    this.props.getDriverRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            PartsDispatch: "Yes"
                        }
                    });
            }
            else {
                if(isNotLoaded(this.props.getMoveRequestStatus))
                    this.props.getMoveRequestsRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            Type: "All",
                            // PartsDispatch: "Yes"
                        }
                    });
        
                if(isNotLoaded(this.props.getDriversStatus))
                    this.props.getDriverRequest({
                        token: this.props.getToken.payload.response.token
                    });
    
                if (isNotLoaded(this.props.lookup)) {
                    this.props.lookupRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            page: "Dispatch"
                        }
                    });
                };
            }
        }
        // if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getMoveRequestStatus))
        //     this.props.getMoveRequestsRequest({
        //         token: this.props.getToken.payload.response.token
        //     });

        // if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDriversStatus))
        //     this.props.getDriverRequest({
        //         token: this.props.getToken.payload.response.token
        //     });

        if (hasPayload(this.props.getToken) && hasPayload(this.props.getMoveRequestStatus)) {
            const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch");
            // console.log(getPartsDispatchRole)
            const res = this.props.getMoveRequestStatus.payload.response;

            const assigned: ById<IMoveRequest> = {};
            const unAssigned: ById<IMoveRequest> = {};

            if(getPartsDispatchRole === true) {
                res && Object.values(res.move_requests)
                    .forEach((x) => {
                        if(x.move_Type === "Parts") {
                            if(x.driver_ID !== null && x.driver_ID !== 0){
                                assigned[x.id] = x;
                            } else {
                                unAssigned[x.id] = x;
                            };
                        }
                    });
            }
            else {
                res && Object.values(res.move_requests)
                    .forEach((x) => {
                        if(x.move_Type === "Trucking") {
                            if(x.driver_ID !== null && x.driver_ID !== 0){
                                assigned[x.id] = x;
                                assigned[x.id].initial_From_Demob = x.from_Demob ?? "No";
                                assigned[x.id].initial_To_Mob = x.to_Mob ?? "No";
                            } else {
                                unAssigned[x.id] = x;
                                unAssigned[x.id].initial_From_Demob = x.from_Demob ?? "No";
                                unAssigned[x.id].initial_To_Mob = x.to_Mob ?? "No";
                            };
                        }
                    });
            }

            this.setState({
                assigned,
                unAssigned,
                pullAlert: false,
                sortList: res ? res.sort_list : [],
                lastPull: res ? res.response_time : "",
                truckingMoveCheck: getPartsDispatchRole === true ? false : true,
                partsMoveCheck: getPartsDispatchRole === true ? true: false,
                // allMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? this.props.getMoveRequestStatus.payload.response.move_requests : {},
                // truckingMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ?  
                //     Object.fromEntries(
                //         Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                //             .filter(x => x.move_Type === "Trucking")
                //             .map(item => [item.id, item]) 
                //     )
                //     : {},
                // partsMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? Object.fromEntries(
                //     Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                //         .filter(x => x.move_Type === "Parts")
                //         .map(item => [item.id, item]) 
                //     )
                //     : {}, 
                assignedPartsMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? Object.fromEntries(
                    Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                        .filter(x => x.move_Type === "Parts" && x.driver_ID !== null && x.driver_ID !== 0)
                        .map(item => [item.id, item]) 
                    ) 
                    : {},
                unAssignedPartsMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? Object.fromEntries(
                    Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                        .filter(x => x.move_Type === "Parts" && (x.driver_ID === null || x.driver_ID === 0))
                        .map(item => [item.id, item]) 
                    )
                    : {},
                assignedTruckingMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? Object.fromEntries(
                    Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                        .filter(x => x.move_Type === "Trucking" && x.driver_ID !== null && x.driver_ID !== 0)
                        .map(item => [item.id, item]) 
                    ) 
                    : {},
                unAssignedTruckingMoveRequest: this.props.getMoveRequestStatus.payload.response.move_requests ? Object.fromEntries(
                    Object.values(this.props.getMoveRequestStatus.payload.response.move_requests)
                        .filter(x => x.move_Type === "Trucking" && (x.driver_ID === null || x.driver_ID === 0))
                        .map(item => [item.id, item]) 
                    )
                    : {},
                
            });
        };

    };

    private onInitialLoad = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch");
            if(getPartsDispatchRole === true) {
                this.props.getDriverRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        PartsDispatch: "Yes"
                    }
                });
    
                this.props.getMoveRequestsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Type: "All",
                        // PartsDispatch: "Yes"
                    }
                });
            }
            else {
                this.props.getDriverRequest({
                    token: this.props.getToken.payload.response.token
                });
    
                this.props.getMoveRequestsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Type: "All",
                        // PartsDispatch: "Yes"
                    }
                });
    
                if (isNotLoaded(this.props.lookup)) {
                    this.props.lookupRequest({
                        token: this.props.getToken.payload.response.token,
                        request: {
                            page: "Dispatch"
                        }
                    });
                };
            }
        };
        
    };

    private handleSave = (): void => {
        if (hasPayload(this.props.getToken)) {
            const assigned: ISaveDriverAssignment[] = Object.values(this.state.assigned)
                .map(({ driver_ID, id, dispatch_Notes, pilot_Ids, dispatch_Priority, trailer_Config_ID, from_Demob, to_Mob, repair_Notes }) =>
                    ({ Driver_ID: ((driver_ID === null) || (driver_ID === 0)) ? 0 : driver_ID, Move_Request_ID: id, 
                        Notes: dispatch_Notes, Pilots: pilot_Ids, Dispatch_Priority:  dispatch_Priority, Trailer_Config_ID: trailer_Config_ID, 
                        From_Demob: from_Demob, To_Mob: to_Mob, Repair_Notes: repair_Notes })
                );

            const unAssigned: ISaveDriverAssignment[] = Object.values(this.state.unAssigned)
                .map(({ driver_ID, id, dispatch_Notes, pilot_Ids, dispatch_Priority, trailer_Config_ID, from_Demob, to_Mob, repair_Notes}) =>
                    ({ Driver_ID: ((driver_ID === null) || (driver_ID === 0)) ? 0 : driver_ID, Move_Request_ID: id, 
                        Notes: dispatch_Notes, Pilots: pilot_Ids, Dispatch_Priority:  dispatch_Priority, Trailer_Config_ID: trailer_Config_ID, 
                        From_Demob: from_Demob, To_Mob: to_Mob, Repair_Notes: repair_Notes })
                );

            const request = assigned.concat(unAssigned);
            const errorMessages = this.checkPilotBeforeSave();
            if (errorMessages.length > 0) {
              alert(errorMessages.join('\n'));
            }
            else {
                this.props.saveDriverAssignmentRequest({
                    request,
                    token: this.props.getToken.payload.response.token,
                    upn: this.props.getToken.payload.response.upn,
                });
    
                this.setState({
                    hasChange: false
                });
            }
        }
    };

    private checkPilotBeforeSave = (): string[] => {
        const getPartsDispatchRole = pageAccessCheck(this.props.getToken, "partsDispatch");
        const errorMessages:string[] = [];
        if(!(getPartsDispatchRole === true)) {
            const assigned = this.state.assigned && Object.values(this.state.assigned);
            const unAssigned = this.state.unAssigned && Object.values(this.state.unAssigned);
            const request = assigned.concat(unAssigned);
            // console.log(request)
            // request.forEach((x) =>  {
            //     if(x.driver_ID > 0) {
            //         if(x.pilot > 0) {
            //             const assignedPilotsArray = x.pilot_Ids != null ? x.pilot_Ids.split(",").map(Number) : [];
            //             if(assignedPilotsArray.length !== x.pilot) {
            //                 errorMessages.push(`${x.unit_Number} require/s ${x.pilot} Pilot${x.pilot > 1 ? 's' : ''}.`);
            //             }
            //         } 
            //     } 
            // })
            request.forEach((x) =>  {
                const assignedPilotsArray = x.pilot_Ids ? x.pilot_Ids.split(",").map(Number) : [];
                if(assignedPilotsArray.length > 0 && !(x.driver_ID > 0) ) {
                    errorMessages.push(`Driver is required for ${x.unit_Number}`);
                }
            })
        }
        
        return errorMessages;
    }

    private callEndpoint = (): void => {
        if (hasPayload(this.props.getToken))
            fetch(END_POINTS.FIELD.CHECK_LAST_UPDATED_MOVE_REQUEST, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        "Last_Fetched": this.state.lastPull
                    }
                })
            })
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    if (res.response) {
                        this.setState({ pullAlert: false });
                        this.handleSave();
                    } else {
                        this.setState({ pullAlert: true });
                    }
                })
                .catch((res) => console.log(res));
    };

}

const mapStateToProps = (state: IStore): IDriverDispatchStoreProps => ({
    getToken: getToken(state),
    lookup: getETLookups(state),
    getDriversStatus: getDrivers(state),
    getMoveRequestStatus: getMoveRequests(state),
    communicationLogs: getDriverCommunicationLogs(state),
    saveDriverAssignmentStatus: saveDriverAssignment(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IDriverDispatchDispatchProps => ({
    getDriverRequest: (data: IDriverRequest) => dispatch(getDriverLoadAction(data)),
    lookupRequest: (data: IETLookupsRequest) => dispatch(getETLookupsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMoveRequestsRequest: (data: IMoveRequestsRequest) => dispatch(getMoveRequestsLoadAction(data)),
    saveDriverAssignmentRequest: (data: ISaveDriverAssignmentRequest) => dispatch(saveDriverAssignmentLoadAction(data)),
    getDriverCommLogsRequest: (data: IGetDriverCommunicationLogsRequest) => dispatch(getDriverCommunicationLogsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverDispatch);



interface ILogPopupProps extends HasClass {
    open: boolean;
    status: STATUS_ENUM;
    onCancel: () => void;
    data: IGetDriverCommunicationLogs[];
};

const LogPopupStyles = styled(LAPaperWithPadding)`
    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
        background-color: ${DARK_GREY_COLOR};
    };

    .odd-row {
        background-color: ${LIGHT_GREY_COLOR}
    };
`;

const LogPopup: React.FC<ILogPopupProps> = React.memo((props: ILogPopupProps) => {

    return <LAPopover open={props.open} onClose={props.onCancel} anchorRef={null}>
        <LogPopupStyles>
            <LAGrid spacing={3} className="text-center">

                <LAGridItem xs={12}>
                    <h3>SMS LOGS</h3>
                    <hr />
                </LAGridItem>

                {(props.status === STATUS_ENUM.LOADING) && <LAGridItem xs={12}>
                    <LACenteredLoading message="Loading Version History..." />
                </LAGridItem>}

                {(props.status === STATUS_ENUM.FAILED) && <LAGridItem xs={12}>
                    <LAErrorBox text="Failed to load..." />
                </LAGridItem>}


                {(props.status === STATUS_ENUM.SUCCEEDED) && <LAGridItem xs={12}>
                    {(props.data && props.data.length > 0) ? <TableContainer component={LAPaperWithLessPadding}>
                        <Table aria-label="logs-table">

                            <TableHead>
                                <TableRow className="header-row">
                                    <TableCell className="title" align="center">Unit Info</TableCell>
                                    <TableCell className="title" align="center">From Location</TableCell>
                                    <TableCell className="title" align="center">To Location</TableCell>
                                    <TableCell className="title" align="center">Dispatch Status</TableCell>
                                    <TableCell className="title" align="center">Notes</TableCell>
                                    <TableCell className="title" align="center">Created</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.data.sort((a, b) => (a.id > b.id ? -1 : 1)).map((row: IGetDriverCommunicationLogs, index: number) => (
                                    <TableRow key={index} className={index % 2 ? "odd-row" : ""}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.unit_Info}
                                        </TableCell>
                                        <TableCell align="center">{row.from_Location}</TableCell>
                                        <TableCell align="center">{row.to_Location}</TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center">{row.notes}</TableCell>
                                        <TableCell align="center">{getDate(true, row.created)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                        : <LAErrorBox text="No records Found" />}
                </LAGridItem>}

            </LAGrid>
        </LogPopupStyles>
    </LAPopover>
});