
export enum IADD_SOCT_REQUEST {
    REQUEST = "addSOCT/ADD_SOCT_REQUEST",
    SUCCESS = "addSOCT/ADD_SOCT_SUCCESS",
    FAILED = "addSOCT/ADD_SOCT_FAILED"
};

export interface IAddSOCTRequest {
    token: string;
    request: IAddUpdateSOCT;
};

export interface IAddUpdateSOCT {
    ID: number;
    Unit_ID: number;
    Service_Date: string;
    ECM: number;
    Current_Hours: number;
    Comments: string;
    Geotab_Sync: string;
    Files: IAttachmentListFile[];
    Reading_Type_Notes?: string;
    Created_By: string;
    Modified_By?: string;
}

export interface IAttachmentListFile {
    name: string;
    base64String: string;
};