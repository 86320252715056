import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_ATTACHMENTS_REQUEST, IAttachments } from "./getSurveyAttachmentsConstants";
import { IGetAttachmentsLoadAction, IGetAttachmentsLoadFailedAction, IGetAttachmentsSuccessAction } from "./getSurveyAttachmentsActions";
import { IADD_SURVEY_ATTACHMENT_REQUEST } from "../addSurveyAttachment/addSurveyAttachmentConstants";
import { IUPDATE_SURVEY_ATTACHMENT_REQUEST } from "../updateSurveyAttachment/updateSurveyAttachmentConstants";
import { IUpdateSurveyAttachmentSuccessAction } from "../updateSurveyAttachment/updateSurveyAttachmentActions";
import { IAddSurveyAttachmentSuccessAction } from "../addSurveyAttachment/addSurveyAttachmentActions";

type Actions =
    | IGetAttachmentsLoadAction
    | IGetAttachmentsSuccessAction
    | IGetAttachmentsLoadFailedAction
    | IUpdateSurveyAttachmentSuccessAction
    | IAddSurveyAttachmentSuccessAction
    | IFlushDataSuccessAction;

export const GetSurveyAttachmentsReducer = (state: Server<SurewayAPIResponse<ById<IAttachments>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IAttachments>>> => {
    switch (action.type) {
        case IGET_ATTACHMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_ATTACHMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ATTACHMENTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SURVEY_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SURVEY_ATTACHMENT_REQUEST.SUCCESS:
            return notLoaded;


        default:
            return state;
    }
};