import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { ISAVE_INSPECTION_SHEET_REQUEST, ISaveInspectionSheetRequest } from "./saveInspectionSheetConstants";


export interface ISaveInspectionSheetLoadAction {
    type: ISAVE_INSPECTION_SHEET_REQUEST.REQUEST;
    data: ISaveInspectionSheetRequest
}
export const saveInspectionSheetLoadAction = (data: ISaveInspectionSheetRequest): ISaveInspectionSheetLoadAction => ({
    type: ISAVE_INSPECTION_SHEET_REQUEST.REQUEST,
    data
});
export interface ISaveInspectionSheetSuccessAction {
    type: ISAVE_INSPECTION_SHEET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveInspectionSheetLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveInspectionSheetSuccessAction => ({
    type: ISAVE_INSPECTION_SHEET_REQUEST.SUCCESS,
    message
});
export interface ISaveInspectionSheetLoadFailedAction {
    type: ISAVE_INSPECTION_SHEET_REQUEST.FAILED;
    message: string;
}
export const saveInspectionSheetLoadFailedAction = (message: string): ISaveInspectionSheetLoadFailedAction => ({
    type: ISAVE_INSPECTION_SHEET_REQUEST.FAILED,
    message
});
