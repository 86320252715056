import React, { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { WorkingSiteLogSubHeader } from "../../header/WorkingSiteLogSubHeader";
import { IForemanMessaging, IGetForemanMessagingRequest } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingConstants";
import { getForemanMessaging } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingAccessor";
import { getForemanMessagingLoadAction } from "../../../redux/field/workingSiteLogs/getForemanMessaging/getForemanMessagingActions";
import { LAPopover } from "../../shared/popOver";

interface IForemanMessagingStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getForemanMessaging: Server<SurewayAPIResponse<ById<IForemanMessaging>>>;
};

interface IForemanMessagingDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getForemanMessagingRequest: (data: IGetForemanMessagingRequest) => unknown;
};


interface IForemanMessagingOwnProps {

};

interface IForemanMessagingState {
    responsesPopUpData: IForemanMessaging | undefined;
};

const ForemanMessagingStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IForemanMessagingProps = RouteComponentProps
    & IForemanMessagingStoreProps
    & IForemanMessagingDispatchProps
    & IForemanMessagingOwnProps;

class Messagings extends PureComponent<IForemanMessagingProps, IForemanMessagingState> {

    public constructor(props: IForemanMessagingProps) {
        super(props);
        this.state = {
            responsesPopUpData: undefined,
        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IForemanMessagingProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { responsesPopUpData } = this.state;
        const { getForemanMessaging, getToken } = this.props;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const data = hasPayload(getForemanMessaging) ? Object.values(getForemanMessaging.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Foreman Messaging" description="Foreman Messaging List" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && 
                <>
                    <WorkingSiteLogSubHeader
                    {...this.props}
                    />
                    
                    <ForemanMessagingStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">FOREMAN MESSAGING</h2>
                                    <hr />

                                    {getForemanMessaging.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Field Employees..." />}
                                    {getForemanMessaging.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Field Employees..." />}

                                    {getForemanMessaging.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={120}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="ForemanMessagingList"
                                                    customRowColor={true}
                                                    storageKey="foremanMessagingSessionKey"
                                                    exportFileName="ForemanMessaging"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "message", caption: "Message", type: "string" },
                                                        { name: "responses", caption: "Responses", type: "button" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime"},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                            {responsesPopUpData &&
                                <Responses
                                    data={responsesPopUpData}
                                    onClose={this.handleResponsesPopupClose}
                                    open={responsesPopUpData ? true : false}
                                />}
                        </LAPaperWithPadding>
                    </ForemanMessagingStyles>
                </>}
            </PageSpacing>
        
        );
    }

    private handleCustomClick = (name: string, e: any): void => {
        const data: IForemanMessaging = e.row.data;
        // console.log(data)
        if(name === "responses") {
            this.setState({ responsesPopUpData : data, })
        }  
    };

    private handleResponsesPopupClose = (): void => {
        this.setState({ responsesPopUpData: undefined });
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGING
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.MESSAGING
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getForemanMessaging)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getForemanMessagingRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IForemanMessagingStoreProps => ({
    getToken: getToken(state),
    getForemanMessaging: getForemanMessaging(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IForemanMessagingDispatchProps => ({
    getForemanMessagingRequest: (data: IGetForemanMessagingRequest) => dispatch(getForemanMessagingLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Messagings);

interface IResponsesPopup {
    open: boolean;
    data: IForemanMessaging;
    onClose: () => void;
};

export const Responses: React.FC<IResponsesPopup> = React.memo((props: IResponsesPopup) => {

    return (
        <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
            <LAPaperWithPadding>

                <div className="text-center">
                    <strong style={{ color : "red"}}>
                        {props.data.message} 
                    </strong>
                    <hr />
                </div>

                <LADevExtremeGrid
                    height={400}
                    id="foremanMessagingResponses"
                    searchPanel={true}
                    filterHeader={true}
                    onClick={undefinedFunction}
                    data={props.data.responses ?? []}
                    columnChoose={true}
                    columnsHiding={true}
                    export={true}
                    removeStyleBtn={true}
                    customRowColor={true}
                    exportFileName="Foreman_Messaging_Responses"
                    storageKey="foremanMessagingResponsesSessionKey"
                    columns={[
                        { name: "employee", caption: "Employee", type: "string" },
                        { name: "employee_Option", caption: "Selected Option", type: "string" },
                        { name: "comments", caption: "Comments", type: "string" },
                        { name: "modified_By", caption: "Modified By", type: "string" },
                        { name: "modified", caption: "Modified", type: "datetime" },
                    ]}
                />
            </LAPaperWithPadding>
        </LAPopover>
)});