export enum IGET_FORM_BY_TEMPLATE_ID_REQUEST {
    REQUEST = "getFormByTemplateID/GET_FORM_BY_TEMPLATE_ID_REQUEST",
    SUCCESS = "getFormByTemplateID/GET_FORM_BY_TEMPLATE_ID_SUCCESS",
    FAILED = "getFormByTemplateID/GET_FORM_BY_TEMPLATE_ID_FAILED",
};

export interface IGetFormByTemplateIDRequest {
    token: string;
    request: {
        id: number 
    }
};

