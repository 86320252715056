import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FIELD_LOOKUPS_REQUEST, IFieldLookup } from "./getFieldLookupsConstants";
import { IGetFieldLookupsLoadAction, IGetFieldLookupsLoadFailedAction, IGetFieldLookupsSuccessAction, getFieldLookupsLoadFailedAction, getFieldLookupsLoadSuccessAction } from "./getFieldLookupsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getFieldLookupsEpic: Epic = (
    action$: ActionsObservable<IGetFieldLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFieldLookupsSuccessAction | IGetFieldLookupsLoadFailedAction> =>
    action$.ofType(IGET_FIELD_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IFieldLookup>>(
                    END_POINTS.FIELD.GET_FIELD_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IFieldLookup>): IGetFieldLookupsSuccessAction => {
                            return getFieldLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFieldLookupsLoadFailedAction(response.message)))
                    )
            )
        );