import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ATTACHMENT_BY_ID_REQUEST, IGetAttachmentByID } from "./getAttachmentByIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetAttachmentByIDLoadAction, IGetAttachmentByIDLoadFailedAction, IGetAttachmentByIDSuccessAction, getAttachmentByIDLoadFailedAction, getAttachmentByIDLoadSuccessAction } from "./getAttachmentByIDActions";

export const getAttachmentByIDEpic: Epic = (
    action$: ActionsObservable<IGetAttachmentByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAttachmentByIDSuccessAction | IGetAttachmentByIDLoadFailedAction> =>
    action$.ofType(IGET_ATTACHMENT_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetAttachmentByID>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_ATTACHMENT_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetAttachmentByID>): IGetAttachmentByIDSuccessAction => {
                            return getAttachmentByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getAttachmentByIDLoadFailedAction(response.message)))
                    )
            )
        );