import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_WSS_LOG_INFO_BY_ID_REQUEST, IGetWSSLogInfoByID } from "./getWSSLogInfoByIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetWSSLogInfoByIDLoadAction, IGetWSSLogInfoByIDLoadFailedAction, IGetWSSLogInfoByIDSuccessAction } from "./getWSSLogInfoByIDActions";
import { IAddFormResponseSuccessAction } from "../../addFormResponse/addFormResponseActions";
import { IUpdateFormResponseSuccessAction } from "../../updateFormResponse/updateFormResponseActions";
import { IADD_FORM_RESPONSE_REQUEST } from "../../addFormResponse/addFormResponseConstants";
import { IUPDATE_FORM_RESPONSE_REQUEST } from "../../updateFormResponse/updateFormResponseConstants";

type Actions =
    | IGetWSSLogInfoByIDLoadAction
    | IGetWSSLogInfoByIDSuccessAction
    | IGetWSSLogInfoByIDLoadFailedAction
    | IAddFormResponseSuccessAction
    | IUpdateFormResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetWSSLogInfoByIDReducer = (state: Server<SurewayAPIResponse<IGetWSSLogInfoByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetWSSLogInfoByID>> => {
    switch (action.type) {
        case IGET_WSS_LOG_INFO_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_WSS_LOG_INFO_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_WSS_LOG_INFO_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case IADD_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};