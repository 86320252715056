
export enum IADD_MECHANIC_REQUEST {
    REQUEST = "addMechanic/ADD_MECHANIC_REQUEST",
    SUCCESS = "addMechanic/ADD_MECHANIC_SUCCESS",
    FAILED = "addMechanic/ADD_MECHANIC_FAILED"
};

export interface IAddMechanicRequest {
    token: string;
    request: IAddUpdateMechanic;
};

export interface IAddUpdateMechanic {
    ID?: number;
    FirstName: string;
    LastName: string;
    LoginInfo: boolean;
    Email: string;
    Password: string;
    Phone: string;
    Company: string;
    Employee_Code: string;
    Created_By: string;
    Modified_By?: string;
    Active: string;
    Comments: string;
    Type: string;
    Contractor: string;
}