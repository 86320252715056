import React from 'react';

const ExternalEmployeeResponse = () => {
  return (
    <div className='text-center'>
        <strong >Thank you, your response has been recorded. You may close this page!</strong>
    </div>
  )
}

export default ExternalEmployeeResponse