import { IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST, IDriversAssignmentHistory, IGetDriversAssignmentHistoryRequest } from "./getDriversAssignmentHistoryConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IGetDriversAssignmentHistoryLoadAction {
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.REQUEST;
    data: IGetDriversAssignmentHistoryRequest
}
export const getDriversAssignmentHistoryLoadAction = (data: IGetDriversAssignmentHistoryRequest): IGetDriversAssignmentHistoryLoadAction => ({
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetDriversAssignmentHistorySuccessAction {
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IDriversAssignmentHistory>>;
}
export const getDriversAssignmentHistoryLoadSuccessAction = (list: SurewayAPIResponse<ById<IDriversAssignmentHistory>>): IGetDriversAssignmentHistorySuccessAction => ({
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetDriversAssignmentHistoryLoadFailedAction {
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getDriversAssignmentHistoryLoadFailedAction = (message: string): IGetDriversAssignmentHistoryLoadFailedAction => ({
    type: IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.FAILED,
    message
});
