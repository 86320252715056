import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_REPAIR_LINE_REQUEST, IUpdateRepairLineRequest } from "./updateRepairLineConstants";


export interface IUpdateRepairLineLoadAction {
    type: IUPDATE_REPAIR_LINE_REQUEST.REQUEST;
    data: IUpdateRepairLineRequest
}
export const updateRepairLineLoadAction = (data: IUpdateRepairLineRequest): IUpdateRepairLineLoadAction => ({
    type: IUPDATE_REPAIR_LINE_REQUEST.REQUEST,
    data
});
export interface IUpdateRepairLineSuccessAction {
    type: IUPDATE_REPAIR_LINE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateRepairLineLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateRepairLineSuccessAction => ({
    type: IUPDATE_REPAIR_LINE_REQUEST.SUCCESS,
    message
});
export interface IUpdateRepairLineLoadFailedAction {
    type: IUPDATE_REPAIR_LINE_REQUEST.FAILED;
    message: string;
}
export const updateRepairLineLoadFailedAction = (message: string): IUpdateRepairLineLoadFailedAction => ({
    type: IUPDATE_REPAIR_LINE_REQUEST.FAILED,
    message
});
