import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST } from "./getVendorItemsByOrderIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetVendorItemsByOrderIDLoadAction, IGetVendorItemsByOrderIDLoadFailedAction, IGetVendorItemsByOrderIDSuccessAction } from "./getVendorItemsByOrderIDActions";
import { IVendorOrderWork } from "../getVendorOrders/getVendorOrdersConstants";


type Actions =
    | IGetVendorItemsByOrderIDLoadAction
    | IGetVendorItemsByOrderIDSuccessAction
    | IGetVendorItemsByOrderIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetVendorItemsByOrderIDReducer = (state: Server<SurewayAPIResponse<IVendorOrderWork[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IVendorOrderWork[]>> => {
    switch (action.type) {
        case IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_ITEMS_BY_ORDER_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};