import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_EXTERNAL_USER_REQUEST, IAddExternalUserRequest } from "./addExternalUserConstants";


export interface IAddExternalUserLoadAction {
    type: IADD_EXTERNAL_USER_REQUEST.REQUEST;
    data: IAddExternalUserRequest
}
export const addExternalUserLoadAction = (data: IAddExternalUserRequest): IAddExternalUserLoadAction => ({
    type: IADD_EXTERNAL_USER_REQUEST.REQUEST,
    data
});
export interface IAddExternalUserSuccessAction {
    type: IADD_EXTERNAL_USER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addExternalUserLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddExternalUserSuccessAction => ({
    type: IADD_EXTERNAL_USER_REQUEST.SUCCESS,
    message
});
export interface IAddExternalUserLoadFailedAction {
    type: IADD_EXTERNAL_USER_REQUEST.FAILED;
    message: string;
}
export const addExternalUserLoadFailedAction = (message: string): IAddExternalUserLoadFailedAction => ({
    type: IADD_EXTERNAL_USER_REQUEST.FAILED,
    message
});
