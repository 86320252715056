import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_STYLES_REQUEST {
    REQUEST = "getStyles/GET_STYLES_REQUEST",
    SUCCESS = "getStyles/GET_STYLES_SUCCESS",
    FAILED = "getStyles/GET_STYLES_FAILED"
};

export interface IGetStyleRequest extends ISurewayTokenRequestBody {

};

export interface IStyle {
    id: number;
    name: string;
    active: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};