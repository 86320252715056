import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_ATTACHMENT_MOVES_REQUEST, IAttachmentMove } from "./getAttachmentMovesConstants";
import { IGetAttachmentMovesLoadAction, IGetAttachmentMovesLoadFailedAction, IGetAttachmentMovesSuccessAction } from "./getAttachmentMovesActions";
import { IUPDATE_ATTACHMENT_MOVE_REQUEST } from "../updateAttachmentMove/updateAttachmentMoveConstants";
import { IADD_ATTACHMENT_MOVE_REQUEST } from "../addAttachmentMove/addAttachmentMoveConstants";
import { IAddAttachmentMoveSuccessAction } from "../addAttachmentMove/addAttachmentMoveActions";
import { IUpdateAttachmentMoveSuccessAction } from "../updateAttachmentMove/updateAttachmentMoveActions";

type Actions =
    | IGetAttachmentMovesLoadAction
    | IGetAttachmentMovesSuccessAction
    | IGetAttachmentMovesLoadFailedAction
    | IUpdateAttachmentMoveSuccessAction
    | IAddAttachmentMoveSuccessAction
    | IFlushDataSuccessAction;

export const GetAttachmentMovesReducer = (state: Server<SurewayAPIResponse<ById<IAttachmentMove>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IAttachmentMove>>> => {
    switch (action.type) {
        case IGET_ATTACHMENT_MOVES_REQUEST.REQUEST:
            return loading;

        case IGET_ATTACHMENT_MOVES_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_ATTACHMENT_MOVES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_ATTACHMENT_MOVE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};