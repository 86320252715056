import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { deleteText, fileToBase64Image, getTokenFromUrl, pageAccessCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import LATextField from "../../shared/textField";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { v4 as uuidv4 } from "uuid";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetFilterCutRequest, IFilterCut } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsConstants";
import { IAddFilterCutRequest, IAddFilterCut } from "../../../redux/field/filterCuts/addFilterCut/addFilterCutConstants";
import { IUpdateFilterCutRequest } from "../../../redux/field/filterCuts/updateFilterCut/updateFilterCutConstants";
import { ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import { getFilterCuts } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsAccessor";
import { addFilterCutStatus } from "../../../redux/field/filterCuts/addFilterCut/addFilterCutAccessor";
import { updateFilterCutStatus } from "../../../redux/field/filterCuts/updateFilterCut/updateFilterCutAccessor";
import { addFilterCutLoadAction } from "../../../redux/field/filterCuts/addFilterCut/addFilterCutActions";
import { getFilterCutsLoadAction } from "../../../redux/field/filterCuts/getFilterCuts/getFilterCutsActions";
import { updateFilterCutLoadAction } from "../../../redux/field/filterCuts/updateFilterCut/updateFilterCutActions";
import LAAutoComplete from "../../shared/autoComplete";
import LATextArea from "../../shared/textArea";
import { LADropZone } from "../../shared/dropZone";
import { Marker } from "../repairLine/repairLine";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { IRepairLinePicture } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { webConfig } from "../../../utils/webConfig";
import splitDate from "../../shared/splitDate";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { FormHelperText, RadioGroup, FormControlLabel, Radio} from "@mui/material";

const RequiredFields: string[] = ["date_Cut", "unit_Number", "filter", "results", "comments", "image"];

interface IAddUpdateFilterCutComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addFilterCutStatus: Server<SurewayAPIResponse<string>>;
    filterCuts: Server<SurewayAPIResponse<ById<IFilterCut>>>;
    updateFilterCutStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateFilterCutComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getFilterCutsRequest: (data: IGetFilterCutRequest) => unknown
    addFilterCutRequest: (data: IAddFilterCutRequest) => unknown;
    updateFilterCutRequest: (data: IUpdateFilterCutRequest) => unknown;
};

interface IAddUpdateFilterCutOwnProps {
    id?: string;
};

interface IAddUpdateFilterCutComponentState {
    data: IFilterCut;
    manager_Status?: string;
    results?: string;
    serverError: string;
    popupImgs: IRepairLinePicture[];
    errors: ById<IFieldErrorKeyValue>;
    imageSrc: { src: string, idx: number } | undefined;
};

const AddUpdateFilterCutStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

const circuitData = [
    { filter : "Engine 1", circuit : ["Front", "Rear"]},
    { filter : "Engine 2", circuit : ["Front", "Rear"]},
    { filter : "Trani 1", circuit : ["Front", "Rear", "Lube", "Torque", "Left", "Right"]},
    { filter : "Trani 2", circuit : ["Front", "Rear", "Lube", "Torque", "Left", "Right"]},
    { filter : "Hydraulic 1", circuit : ["Left", "Center", "Right", "3spool", "4spool", "Plus"]},
    { filter : "Hydraulic 2", circuit : ["Left", "Center", "Right", "3spool", "4spool", "Plus"]},
    { filter : "Hydraulic 3", circuit : ["Left", "Center", "Right", "3spool", "4spool", "Plus"]},
    { filter : "Case Drain 1", circuit : ["Pump", "Motor", "Travel", "Swing", "Front", "Rear", "Left", "Right", "Auger Motor", "Auger Pump"]},
    { filter : "Case Drain 2", circuit : ["Pump", "Motor", "Travel", "Swing", "Front", "Rear", "Left", "Right", "Auger Motor", "Auger Pump"]},
    { filter : "Axle", circuit : ["Front", "Rear"]},
]

type IAddUpdateFilterCutComponentProps =
    RouteComponentProps
    & IAddUpdateFilterCutOwnProps
    & IAddUpdateFilterCutComponentStoreProps
    & IAddUpdateFilterCutComponentDispatchProps;

class AddUpdateFilterCut extends PureComponent<IAddUpdateFilterCutComponentProps, IAddUpdateFilterCutComponentState> {

    public constructor(props: IAddUpdateFilterCutComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                date_Cut: new Date().toISOString(),
                unit_ID: 0,
                filter: "",
                circuit: "",
                results: "",
                comments: "",
                manager_Status: "NA",
                images: "",
                pictures: [],
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            errors: {},
            manager_Status: "",
            results: "",
            popupImgs: [],
            serverError: "",
            imageSrc: undefined
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateFilterCutComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addFilterCutStatus !== prevProps.addFilterCutStatus) {

                if (this.props.addFilterCutStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addFilterCutStatus.message });

                if (hasPayload(this.props.addFilterCutStatus) && this.props.addFilterCutStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateFilterCutStatus !== prevProps.updateFilterCutStatus) {

                if (this.props.updateFilterCutStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateFilterCutStatus.message });

                if (hasPayload(this.props.updateFilterCutStatus) && this.props.updateFilterCutStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, imageSrc, popupImgs, manager_Status, results } = this.state;
        const { token, addFilterCutStatus, updateFilterCutStatus, getLookup } = this.props;
        // console.log(token)

        const GoodOrBad: IIdName[] = [{ id: 1, name: "Good" }, { id: 2, name: "Bad" }];
        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const filters = hasPayload(getLookup) ? getLookup.payload.response.filters : [];
        const filterDropdownOptions = filters?.filter((item, index, self) => index === self.findIndex(t => (t.filter === item.filter))).sort((a, b) => a.filter > b.filter ? 1 : -1)
        // const getRole = pageAccessCheck(token, "repairLine");
        const filterCutsManagerAccess = hasPayload(token) ? token.payload.response.filterCutsManagerAccess : false;
        // const repairLineRole = hasPayload(token) ? token.payload.response.repairLineRole : undefined
        // const disabled = getRole === ReadOnly ? true : undefined;
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const onFilter = (e: any ,value: any): void =>  this.handleChange("filter", value ? value.filter : "");
        const onCircuit = (e: any ,value: any): void =>  this.handleChange("circuit", value ? value.circuit : "");
        const onResults = (event: unknown, value: IIdName): void => this.handleChange("results", value !== null ? value.name : data.results);
        const disableByManagerStatus = (manager_Status === "Acknowledged" || manager_Status ==="Send to Repair Line") ? true : undefined

        return (
            <AddUpdateFilterCutStyles>
                {/* {(getRole !== NotApplicable) && */}
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}FILTER CUT</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                id="Date_Cut"
                                value={splitDate(data.date_Cut)}
                                fullWidth={true}
                                variant="outlined"
                                disabled={disableByManagerStatus}
                                name="date_Cut"
                                label="Date Cut"
                                errorText={errors["date_Cut"] ? errors["date_Cut"].message : undefined}
                                onChange={this.handleChange}
                                type="date"
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <DevExtremePopupLookup
                                data={units}
                                id="job-search"
                                placeHolder="Unit Number"
                                disabled={disableByManagerStatus}
                                displayExp="unit_Number"
                                name="unit_Number"
                                errorName="unit_Number"
                                errors={errors}
                                currentVal={unit?.unit_Number}
                                onClick={onUnit}
                                columns={[
                                    { name: "unit_Number", caption: "Unit Number", type: "string" },
                                    { name: "make", caption: "Make", type: "string" },
                                    { name: "model", caption: "Model", type: "string" },
                                    { name: "location_Details", caption: "Location", type: "string" },
                                    { name: "active_Repair_Status", caption: "Repair Status", type: "string" },
                                ]}
                            />
                        </LAGridItem>

                        {((manager_Status === "Acknowledged" || manager_Status === "Send to Repair Line") && data.id > 0) ? 
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    id="filter"
                                    value={data.filter}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disableByManagerStatus}
                                    name="filter"
                                    label="Filters"
                                    errorText={errors["filter"] ? errors["filter"].message : undefined}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem> : 
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={filterDropdownOptions}
                                    getOptionLabel="filter"
                                    autoHighlight={true}
                                    onChange={onFilter}
                                    disabled={disableByManagerStatus}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Filters"
                                    selectionRemove={undefinedFunction}
                                    errorText={errors["filter"] ? errors["filter"].message : undefined}
                                    value={data.filter ? filters?.find(q => q.filter === data.filter) : null}
                                    defaultValue={data.filter ? filters?.find(q => q.filter === data.filter) : null}
                                />
                            </LAGridItem>
                        }

                        {(data.id > 0 && (manager_Status === "Acknowledged" || manager_Status === "Send to Repair Line")) ? 
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    id="results"
                                    value={data.circuit ?? ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disableByManagerStatus}
                                    name="circuit"
                                    label="Circuit/Type"
                                    errorText={errors["circuit"] ? errors["circuit"].message : undefined}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem> : 
                            (filters?.find(e => e.filter === data.filter)?.circuit !== null) && (filters?.find(e => e.filter === data.filter)?.circuit !== undefined) && 
                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LAAutoComplete
                                        multiple={false}
                                        option={filters?.filter(e => e.filter === data.filter).sort()}
                                        getOptionLabel="circuit"
                                        autoHighlight={true}
                                        onChange={onCircuit}
                                        disabled={disableByManagerStatus}
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Circuit/Type"
                                        selectionRemove={undefinedFunction}
                                        errorText={errors["circuit"] ? errors["circuit"].message : undefined}
                                        value={data.circuit ? filters?.find(q => q.circuit === data.circuit) : null}
                                        defaultValue={data.circuit ? filters?.find(q => q.circuit === data.circuit) : null}
                                    />
                                </LAGridItem>
                        }
                        
                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Hours"
                                fullWidth={true}
                                variant="outlined"
                                name="hours"
                                type="number"
                                disabled={disableByManagerStatus}
                                value={data.hours ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["hours"] ? errors["hours"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="km"
                                fullWidth={true}
                                variant="outlined"
                                name="km"
                                type="number"
                                disabled={disableByManagerStatus}
                                value={data.km ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["km"] ? errors["km"].message : undefined}
                            />
                        </LAGridItem>

                        {(data.id > 0 && (manager_Status === "Acknowledged" || manager_Status === "Send to Repair Line")) ? 
                            <LAGridItem xs={12} sm={6} md={4}>
                                <LATextField
                                    id="results"
                                    value={data.results}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disableByManagerStatus}
                                    name="results"
                                    label="Results"
                                    errorText={errors["results"] ? errors["results"].message : undefined}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem> : <LAGridItem xs={12} sm={6} md={4}>
                                <LAAutoComplete
                                    multiple={false}
                                    option={GoodOrBad}
                                    getOptionLabel="name"
                                    autoHighlight={true}
                                    onChange={onResults}
                                    disabled={disableByManagerStatus || data.id > 0}
                                    errorText={errors["results"] ? errors["results"].message : undefined}
                                    filterSelectedOptions={true}
                                    dropDownPlaceHolder="Results"
                                    selectionRemove={undefinedFunction}
                                    value={data.results ? GoodOrBad.find(q => q.name === data.results) : null}
                                    defaultValue={data.results ? GoodOrBad.find(q => q.name === data.results) : null}
                                />
                            </LAGridItem>
                        }

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                minRows={3}
                                rowsMax={6}
                                label="Comments"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disableByManagerStatus}
                                name="comments"
                                value={data.comments ?? ""}
                                onChange={this.handleChange}
                                errorText={errors["comments"] ? errors["comments"].message : undefined}
                            />
                        </LAGridItem>

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.manager_Status} onChange={this.handleManagerStatusChange} >
                                    <FormControlLabel value={"Acknowledged"} control={<Radio />} label="Acknowledged" disabled= {!filterCutsManagerAccess || disableByManagerStatus || results === "Good"}/>
                                    <FormControlLabel value={"Send to Repair Line"} control={<Radio />} label="Send to Repair Line" disabled= {!filterCutsManagerAccess || disableByManagerStatus || results === "Good"} />
                                </RadioGroup>
                        </LAGridItem>}

                        <LAGridItem xs={12}>
                                    <LAGridItem xs={12} className="text-center">
                                        {!imageSrc ? <strong>Upload Images</strong> : <strong>Highlight Image</strong>}
                                        {!imageSrc && <LADropZone onFileDrop={(e: any) => this.handleUpload(e)} />}
                                        {errors["image"] && <FormHelperText style={{color:'orange'}}>Required</FormHelperText>}
                                    </LAGridItem>
                                    <br />
                                    {!imageSrc ? <LAGrid>
                                        {popupImgs
                                            .map((q, ind) => {
                                                const onImgDelete = (): void => this.onImgDelete(q.name, ind);
                                                const base64 = (q.base64String !== null && q.base64String !== "") ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                const openImage = () => q.base64String ? this.handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                const onImageName = (name: string, value: string): void => this.handleImageName(name ? name : "", value, ind);

                                                return (
                                                    <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                        <LAPaperWithLessPadding>
                                                            <LAThumbnailWithLink
                                                                alt={q.name}
                                                                key={ind}
                                                                url={base64}
                                                                id={(ind + "_").toString()}
                                                            />

                                                            <LATextField
                                                                className="mt-2"
                                                                label="Description"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="description"
                                                                disabled={disableByManagerStatus}
                                                                value={q.description}
                                                                onChange={onImageName}
                                                                errorText={
                                                                    q.name.length === 0 ?
                                                                        FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                        : undefined
                                                                }
                                                            />

                                                            {q.base64String && <LAButton 
                                                                onClick={openImage} 
                                                                disabled={disableByManagerStatus}
                                                                label="Highlight Image" 
                                                            />}

                                                            <LAIconButton
                                                                key={ind}
                                                                label="Delete"
                                                                disabled={disableByManagerStatus}
                                                                onClick={onImgDelete}
                                                                icon={<DeleteIcon />}
                                                            />
                                                        </LAPaperWithLessPadding>
                                                    </LAGridItem>
                                                );

                                            })}
                                    </LAGrid>
                                        : <Marker
                                            imageSrc={imageSrc.src}
                                            onSave={this.handleEditImageSave}
                                            onCancel={this.handleEditImageCancel}
                                        />}

                                </LAGridItem>

                            {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>
                                

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                disableSave={disableSave || disableByManagerStatus}
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>
                {/* } */}

                <RequestStatus requestStatus={addFilterCutStatus.kind} successMessage="Filter Cut has been added successfully" />
                <RequestStatus requestStatus={updateFilterCutStatus.kind} successMessage="Filter Cut has been updated successfully" />

            </AddUpdateFilterCutStyles>
        );
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        let errors = { ...this.state.errors };
        if (value === undefined) {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value.toString(), errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
            },
            errors
        });
    };

    private handleEditImageSave = (src: string): void => {
        if (this.state.imageSrc)
            this.onImageEdit(src, this.state.imageSrc.idx);

        this.handleEditImageCancel();
    };

    private handleOpenImage = (src: string, idx: number): void => {
        this.setState({ imageSrc: { src, idx } });
    };

    private handleEditImageCancel = (): void => {
        this.setState({ imageSrc: undefined });
    };

    private onImageEdit = (base64String: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], base64String };
        popupImgs[subIndex] = picture;

        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private handleImageName = (name: string, value: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], [name]: value };
        popupImgs[subIndex] = picture;


        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private onImgDelete = (name: string, imgIndex: number): void => {
        let errors = { ...this.state.errors };
        const selection = window.confirm(deleteText);

        if (selection) {
            const popupImgs = [...this.state.popupImgs];
                popupImgs.splice(imgIndex, 1);

                if(popupImgs.length === 0 && this.state.data.results === "Bad") {
                    errors = this.errorChecker("image", popupImgs.length> 0 ? popupImgs.length.toString() : "", errors)
                }
                this.setState({
                    ...this.state,
                    popupImgs: [
                        ...popupImgs
                    ],
                    errors
                });
        }
    };
    
    private handleUpload = async (event: any): Promise<void> => {

        const files = event;
        const popupImgs = [...this.state.popupImgs];
        let errors = { ...this.state.errors };

            if (files !== null) {

                await Promise.all(Object.values(files).map(async (x: any) => {
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    // const webPName = x.name.replace(/\.[^.]+$/, ".jpg");
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        // console.log(img.description, description)
                        if(img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String,
                        }) : 
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String,
                        });

                    if(popupImgs.length > 0) {
                        delete errors["image"]
                    }

                    this.setState({
                        ...this.state,
                        popupImgs: [
                            ...popupImgs
                        ], 
                        errors
                    });
                }));
            }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.FILTER_CUT.FILTER_CUTS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {

        const { data, popupImgs } = this.state;

        if (hasPayload(this.props.token)) {

            const images: string = popupImgs.map(picture => picture.name).join(";");

            let request: IAddFilterCut = {
                ...data,
                // manager_Status: (data.id === 0 && data.results === "Bad") ? "Active" : data.manager_Status,
                manager_Status: (data.results === "Bad" && (data.manager_Status !== "Acknowledged" && data.manager_Status !== "Send to Repair Line")) ? "Active" : 
                                (data.results === "Good" && (data.manager_Status !== "Acknowledged" && data.manager_Status !== "Send to Repair Line") ) ? "NA" : 
                                data.manager_Status,
                hours: data.hours === 0 ? null : data.hours,
                images: images,
                pictures: popupImgs,
                created_By: this.props.token.payload.response.upn,
                modified_By: this.props.token.payload.response.upn,
            };

            if (data.id === 0) {
                // console.log(request)
                this.props.addFilterCutRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            } else {
                this.props.updateFilterCutRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleManagerStatusChange = (e:any, value: string): void => {
        // console.log(value)
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                manager_Status: value
            }
        });
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "filter")  {
            if(circuitData.find(x => x.filter === value)) {
                data.circuit = "";
                errors = this.errorChecker("circuit", data.circuit ? data.circuit : "", errors);
            } else {
                delete errors["circuit"];
                data.circuit = "";
            }
        }

        if(name === "circuit") {
            errors = this.errorChecker("circuit", value, errors)
        }
    
        if(name === "results" && value === "Bad") {
            errors = this.errorChecker("comments", data.comments ? data.comments : "", errors)
            errors = this.errorChecker("image",  data.images ? data.images : "" , errors)
        } else if ((name === "results" && value === "Good") || data.results === "Good") {
            delete errors["comments"];
            delete errors["image"];
        }

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: name==="hours" ? Number(value) : value
            },
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                // if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (hasPayload(this.props.filterCuts)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.filterCuts.payload.response[query.id];
                                // const pictures = data.images && data.images.split(";");
                                const pictures = data.pictures; 
                                
                                const popupImgs: IRepairLinePicture[] = [];
                                pictures && pictures.forEach((x) => {
                                    popupImgs.push({
                                        name: x.name,
                                        description: x.description,
                                        base64String: ""
                                    });
                                });

                                this.setState({
                                    data,
                                    popupImgs, 
                                    manager_Status: data.manager_Status,
                                    results: data.results
                                });
                            };
                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });
                            if (errors["date_Cut"])
                                delete errors["date_Cut"]
                            if (errors["comments"])
                                delete errors["comments"]
                            if (errors["image"])
                                delete errors["image"]

                            this.setState({ errors });
                        }
                    } else {
                        this.props.getFilterCutsRequest({
                            token: this.props.token.payload.response.token,
                            request : {
                                view: ""
                            }
                        });
                    }

                // } 
                // else {

                //     this.props.history.push({
                //         pathname: ROUTE.ACCESS_DENIED,
                //         search: getTokenFromUrl(false)
                //     });
                // };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Filter_Cuts"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateFilterCutComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    filterCuts: getFilterCuts(state),
    addFilterCutStatus: addFilterCutStatus(state),
    updateFilterCutStatus: updateFilterCutStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateFilterCutComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addFilterCutRequest: (data: IAddFilterCutRequest) => dispatch(addFilterCutLoadAction(data)),
    getFilterCutsRequest: (data: IGetFilterCutRequest) => dispatch(getFilterCutsLoadAction(data)),
    updateFilterCutRequest: (data: IUpdateFilterCutRequest) => dispatch(updateFilterCutLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateFilterCut);