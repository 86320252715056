import React, { useState, useEffect } from "react";
import { HasClass } from "./publicInterfaces";

interface IDataTimer extends HasClass {
    key?: any;
    timeout?: number;
    onTimerEnd: () => void;
};

export const DataTimer: React.FC<IDataTimer> = React.memo((props: IDataTimer) => {
    const [remainingTime, setRemainingTime] = useState<number>(props.timeout ?? 300);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime(prevRemainingTime => prevRemainingTime - 1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (remainingTime === 0) {
            props.onTimerEnd();
            setRemainingTime(props.timeout ?? 300);
        }
    }, [remainingTime, props.onTimerEnd]);

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div key={props.key} className={props.className}>

            <strong className="blue-text">
                Next Refresh in
            </strong>

            <span className="green-text pl-1">
                {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
            </span>
        </div>
    );
});
