import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateRoverKitRequest } from "../addRoverKit/addRoverKitConstants";
import { IUPDATE_ROVER_KIT_REQUEST } from "./updateRoverKitConstants";

export interface IUpdateRoverKitLoadAction {
    type: IUPDATE_ROVER_KIT_REQUEST.REQUEST;
    data: IAddUpdateRoverKitRequest;
}
export const updateRoverKitLoadAction = (data: IAddUpdateRoverKitRequest): IUpdateRoverKitLoadAction => ({
    type: IUPDATE_ROVER_KIT_REQUEST.REQUEST,
    data
});
export interface IUpdateRoverKitSuccessAction {
    type: IUPDATE_ROVER_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateRoverKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateRoverKitSuccessAction => ({
    type: IUPDATE_ROVER_KIT_REQUEST.SUCCESS,
    list
});
export interface IUpdateRoverKitLoadFailedAction {
    type: IUPDATE_ROVER_KIT_REQUEST.FAILED;
    message: string;
}
export const updateRoverKitLoadFailedAction = (message: string): IUpdateRoverKitLoadFailedAction => ({
    type: IUPDATE_ROVER_KIT_REQUEST.FAILED,
    message
});
