import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_REPAIR_LINE_REQUEST, IAddRepairLineRequest } from "./addRepairLineConstants";


export interface IAddRepairLineLoadAction {
    type: IADD_REPAIR_LINE_REQUEST.REQUEST;
    data: IAddRepairLineRequest
}
export const addRepairLineLoadAction = (data: IAddRepairLineRequest): IAddRepairLineLoadAction => ({
    type: IADD_REPAIR_LINE_REQUEST.REQUEST,
    data
});
export interface IAddRepairLineSuccessAction {
    type: IADD_REPAIR_LINE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addRepairLineLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddRepairLineSuccessAction => ({
    type: IADD_REPAIR_LINE_REQUEST.SUCCESS,
    message
});
export interface IAddRepairLineLoadFailedAction {
    type: IADD_REPAIR_LINE_REQUEST.FAILED;
    message: string;
}
export const addRepairLineLoadFailedAction = (message: string): IAddRepairLineLoadFailedAction => ({
    type: IADD_REPAIR_LINE_REQUEST.FAILED,
    message
});
