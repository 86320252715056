import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_JOB_MHR_REQUEST, IUpdateJobMhrRequest } from "./updateJobMhrConstants";


export interface IUpdateJobMhrLoadAction {
    type: IUPDATE_JOB_MHR_REQUEST.REQUEST;
    data: IUpdateJobMhrRequest
}
export const updateJobMhrLoadAction = (data: IUpdateJobMhrRequest): IUpdateJobMhrLoadAction => ({
    type: IUPDATE_JOB_MHR_REQUEST.REQUEST,
    data
});
export interface IUpdateJobMhrSuccessAction {
    type: IUPDATE_JOB_MHR_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateJobMhrLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateJobMhrSuccessAction => ({
    type: IUPDATE_JOB_MHR_REQUEST.SUCCESS,
    message
});
export interface IUpdateJobMhrLoadFailedAction {
    type: IUPDATE_JOB_MHR_REQUEST.FAILED;
    message: string;
}
export const updateJobMhrLoadFailedAction = (message: string): IUpdateJobMhrLoadFailedAction => ({
    type: IUPDATE_JOB_MHR_REQUEST.FAILED,
    message
});
