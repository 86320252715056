import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl,  pageAccessCheck, undefinedFunction, userName, YesOrNo } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getServiceSections } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsAccessor";
import { getServiceSectionsLoadAction } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsActions";
import { PMSheetsSubHeader, PMSheetsSubHeaderMobile } from "../../header/pmSheetsSubHeader";
import { IServiceSection, IGetServiceSectionRequest } from "../../../redux/field/pmSheets/getServiceSections/getServiceSectionsConstants";
import { LAPopover } from "../../shared/popOver";
import React from "react";
import LAErrorBox from "../../shared/errorBox";

interface IServiceSectionsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getServiceSections: Server<SurewayAPIResponse<ById<IServiceSection>>>;
};

interface IServiceSectionsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getServiceSectionsRequest: (data: IGetServiceSectionRequest) => unknown;
};


interface IServiceSectionsOwnProps {

};

interface IServiceSectionsState {
    activeView: boolean;
    popUpData: IServiceSection | undefined;
    activeServiceSections: IServiceSection[];
};

const ServiceSectionsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        top: 2%;
        left: 3%;
        height: 3%;
        position: absolute;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu {
        margin-left: 500px;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IServiceSectionsProps = RouteComponentProps
    & IServiceSectionsStoreProps
    & IServiceSectionsDispatchProps
    & IServiceSectionsOwnProps;

class ServiceSections extends PureComponent<IServiceSectionsProps, IServiceSectionsState> {

    public constructor(props: IServiceSectionsProps) {
        super(props);
        this.state = {
            activeView: true,
            popUpData: undefined,
            activeServiceSections: []
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IServiceSectionsProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getServiceSections, getToken } = this.props;
        const { activeView, activeServiceSections, popUpData } = this.state;
        const getRole = pageAccessCheck(getToken, "pmSheets");
        const data = activeView ? activeServiceSections : hasPayload(getServiceSections) ? Object.values(getServiceSections.payload.response) : [];

        return (
            <PageSpacing title="Service Sections" description="FIELD - SERVICE SECTIONS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <ServiceSectionsStyles>
                   
                    <div className="hide-on-phone">
                        <PMSheetsSubHeader
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <div className="show-on-phone">
                        <PMSheetsSubHeaderMobile
                            {...this.props}
                            role={getRole}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">SERVICE SECTIONS</h2>
                                <hr />

                                {getServiceSections.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Service Sections..." />}
                                {getServiceSections.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Service Sections..." />}

                                {getServiceSections.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="active" control={<Radio />} label="Active Service Sections" />
                                                <FormControlLabel value="all" control={<Radio />} label="All Service Sections" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                onCustomClick={this.handleCustom}
                                                id="serviceSectionsList"
                                                customRowColor={true}
                                                storageKey="serviceSectionsListSessionKey"
                                                exportFileName="ServiceSections"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "clone", caption: "Clone" , type: "button" },
                                                    { name: "section_Name", caption: "Service Section Name", type: "string", sortAsc: true },
                                                    { name: "active", caption: "Active", type: "string" },

                                                    { name: "equipment_Type", caption: "Equipment Type", type: "string" },
                                                    { name: "service_Due_Name", caption: "Service Due", type: "string" },
                                                    { name: "serviceItems", caption: "Service Section Items", type: "button" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" }
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>

                        {popUpData &&
                            <ServiceItems
                                data={popUpData}
                                onClose={this.handlePopupClose}
                                open={popUpData ? true : false}
                            />}

                    </LAPaperWithPadding>
                </ServiceSectionsStyles>}
            </PageSpacing>
        );
    }

    private handlePopupClose = (): void => {
        this.setState({ popUpData: undefined });
    };

    private handleCustom = (name: string, column: any): void => {
        if(name === "clone") {
            callRouteWithQueryString({
                route: this.props,
                search: { id: "0", clone_ID: column.row.data.id.toString() },
                pathName: ROUTE.FIELD.PM_SHEETS.SERVICE_SECTION
            });
        } else {
            const data: IServiceSection = column.row.data;
            this.setState({ popUpData: data });
        }
    };

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        let activeView = !this.state.activeView;
        const getStorageValue = localStorage.getItem("key_service_sections");
        if(getStorageValue)
        {
            if(activeView)
            {
                localStorage.setItem("key_service_sections", "active");
            }
            else
            {
                localStorage.setItem("key_service_sections", "all");
            }
        }
        else
        {
            localStorage.setItem("key_service_sections", "active");
        }
        
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.PM_SHEETS.SERVICE_SECTION
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.PM_SHEETS.SERVICE_SECTION
        });
    };


    private checkViewSelections = (): void => {
        let activeView = this.state.activeView;
                const getStorageValue = localStorage.getItem("key_service_sections");
                if(getStorageValue)
                {
                    if(getStorageValue === "active")
                    {
                        activeView = true;
                    }
                    else
                    {
                        activeView = false;
                    }
                }
                else
                {
                    localStorage.setItem("key_service_sections", "active");
                }
                this.setState({activeView: activeView});
    }
	
    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "pmSheets") !== NotApplicable) {
                if (isNotLoaded(this.props.getServiceSections)) {
                    this.getDataForTable();
                };
            } else {
                
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                  });
            }

            if (hasPayload(this.props.getServiceSections)) {
                this.setState({ activeServiceSections: Object.values(this.props.getServiceSections.payload.response).filter(x => x.active === YesOrNo[0].name) });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken) && pageAccessCheck(this.props.getToken, "pmSheets") !== NotApplicable) {
            this.props.getServiceSectionsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IServiceSectionsStoreProps => ({
    getToken: getToken(state),
    getServiceSections: getServiceSections(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IServiceSectionsDispatchProps => ({
    getServiceSectionsRequest: (data: IGetServiceSectionRequest) => dispatch(getServiceSectionsLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSections);


interface IServiceItemsPopup {
    open: boolean;
    onClose: () => void;
    data: IServiceSection;
};

export const ServiceItems: React.FC<IServiceItemsPopup> = React.memo((props: IServiceItemsPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>

            <div className="text-center">

                <strong>
                    Section Name:
                </strong>
                <span>
                    {props.data.section_Name}
                </span>

                &#160;&#160;&#160;&#160;

                <strong>
                    Equipment Type:
                </strong>
                <span>
                    {props.data.equipment_Type}
                </span>

                &#160;&#160;&#160;&#160;

                <strong>
                    Service Due:
                </strong>
                <span>
                    {props.data.service_Due_Name}
                </span>

                <hr />
            </div>

            <LADevExtremeGrid
                height={400}
                id="serviceItems"
                searchPanel={false}
                filterHeader={false}
                onClick={undefinedFunction}
                data={props.data.service_Items}
                columns={[
                    { name: "service_Item_Name", caption: "Service Item Name", type: "string" },
                    { name: "active", caption: "Active", type: "string" },
                    { name: "apply_To_Unit_No", caption: "Apply To Unit #", type: "string" },
                    { name: "apply_To_Make", caption: "Apply To Make", type: "string" },
                    { name: "apply_To_Model", caption: "Apply To Model", type: "string" },
                    { name: "comments", caption: "Comments", type: "string" },
                    { name: "modified_By", caption: "Modified By", type: "string" },
                    { name: "modified", caption: "Modified", type: "datetime" },
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));