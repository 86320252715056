
export enum IAPPROVE_MECHANIC_LOG_REQUEST {
    REQUEST = "approveMechanicLog/APPROVE_MECHANIC_LOG_REQUEST",
    SUCCESS = "approveMechanicLog/APPROVE_MECHANIC_LOG_SUCCESS",
    FAILED = "approveMechanicLog/APPROVE_MECHANIC_LOG_FAILED"
};

export interface IApproveMechanicLogRequest {
    token: string;
    request: IApproveMechanicLog;
};

export interface IApproveMechanicLog {
    modified_By: string;
    approveList: IMechanicLogApproveList[];
};

export interface IMechanicLogApproveList {
    id: number;
    defect_ID: number;
}