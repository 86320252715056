import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST } from "./removeDepartmentFromItemsConstants";
import { IRemoveDepartmentFromItemsLoadAction, IRemoveDepartmentFromItemsLoadFailedAction, IRemoveDepartmentFromItemsSuccessAction, removeDepartmentFromItemsLoadFailedAction, removeDepartmentFromItemsLoadSuccessAction } from "./removeDepartmentFromItemsActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const removeDepartmentFromItemsEpic: Epic = (
    action$: ActionsObservable<IRemoveDepartmentFromItemsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IRemoveDepartmentFromItemsSuccessAction | IRemoveDepartmentFromItemsLoadFailedAction> =>
    action$.ofType(IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.FORMS.CLEANUP.REMOVE_DEPARTMENT_FROM_ITEMS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IRemoveDepartmentFromItemsSuccessAction | IRemoveDepartmentFromItemsLoadFailedAction => {
                            if(response.message === "Success"){
                                return removeDepartmentFromItemsLoadSuccessAction(response);
                            } else {
                                return removeDepartmentFromItemsLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(removeDepartmentFromItemsLoadFailedAction(response.message)))
                    )
            )
        );