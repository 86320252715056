
export enum IGET_FORM_RESPONSES_REQUEST {
    REQUEST = "getFormResponses/GET_FORM_RESPONSES_REQUEST",
    SUCCESS = "getFormResponses/GET_FORM_RESPONSES_SUCCESS",
    FAILED = "getFormResponses/GET_FORM_RESPONSES_FAILED"
};

export interface IGetFormResponsesRequest {
    token: string;
    request: {
        username: string;
        view: string;
    };
};

export interface IFormResponse {
    id: number;
    form_ID: number;
    form_Name: string;
    name: string;
    emp_ID?: number;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    responses: IResponse[];
};

export interface IResponse {
    id: number;
    form_Sheet_ID: number;
    form_Question_ID: number;
    question: string;
    answer: string;
    type: string;
    options: string;
    flagged: string;
    comments: string;
    images: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    pictures: IFormResponsePicture[];
};

export interface IFormResponsePicture {
    name: string;
    description: string;
    base64String: string | null;
};
