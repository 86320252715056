import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../redux/server";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { EXTRA_LIGHT_YELLOW_COLOR, GREEN_COLOR, LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, WHITE_COLOR, YELLOW_COLOR } from "../../../shared/theme";
import PageSpacing from "../../../shared/pageSpacing";
import { LAPaperWithPadding } from "../../../shared/paper";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { AddIcon } from "../../../shared/icons";
import { LAButton } from "../../../shared/buttons";
import LALoading from "../../../shared/loading";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName } from "../../../shared/constExports";
import { ROUTE } from "../../../routes";
import { getSoctList } from "../../../../redux/field/soct/getSoctList/getSoctListAccessor";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { ISoctList, ISoctListRequest } from "../../../../redux/field/soct/getSoctList/getSoctListConstants";
import { getSoctListLoadAction } from "../../../../redux/field/soct/getSoctList/getSoctListActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAErrorBox from "../../../shared/errorBox";
import { Navbar, SOCTNavbarMenu } from "../../../shared/navbars";
import LALinkButton from "../../../shared/linkButton";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

interface ISoctListStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getList: Server<ISoctList[]>;
};

interface ISoctListDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getSoctListRequest: (data: ISoctListRequest) => unknown;
};


interface ISoctListOwnProps {

};

interface ISoctListState {
    activeView: string;
};

const SoctListStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 18%;
        };

        .pull-left {
            left: 32%;
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type ISoctListProps = RouteComponentProps
    & ISoctListStoreProps
    & ISoctListDispatchProps
    & ISoctListOwnProps;

class SoctList extends PureComponent<ISoctListProps, ISoctListState> {

    public constructor(props: ISoctListProps) {
        super(props);
        this.state = {
            activeView: "Active"
        };
    }

    public componentDidMount(): void {
        this.getDataForTable()
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISoctListProps): void {
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getList } = this.props;
        const { activeView } = this.state;
        const data = hasPayload(getList) ? Object.values(getList.payload).filter(x => x.status === activeView) : [];
        // console.log(data)
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        // console.log(getRole)

        return (
            <PageSpacing title="SOCT List" description="FIELD - SOCT MAIN" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={SOCTNavbarMenu} role={getRole}/>
                <SoctListStyles>
                    {(getRole === true) && 
                        <LAPaperWithPadding>
                            <LAGrid>

                                <LAGridItem xs={12} className="text-center">

                                    {/* <SOCTHome history={this.props.history} /> */}

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">SOCT MAIN</h2>
                                    <hr />

                                    {getList.kind === STATUS_ENUM.LOADING && <LALoading message="Loading SOCT List..." />}
                                    {getList.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load SOCT List..." />}

                                    {getList.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>

                                            <LAGridItem xs={12}>
                                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView} onChange={this.onViewClick}>
                                                    <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                                    <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                                                    <FormControlLabel value="Out Of Service" control={<Radio />} label="Out Of Service" />
                                                    <FormControlLabel value="Sold" control={<Radio />} label="Sold" />
                                                    <FormControlLabel value="Rental" control={<Radio />} label="Rental" />
                                                </RadioGroup>
                                            </LAGridItem>

                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    actionWidth={90}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}                                    
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    columnResizing={true}
                                                    export={true}
                                                    // columnWidth={100}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    id="soct-main-list"
                                                    customRowColor={true}
                                                    storageKey="soctMainListSessionKey"
                                                    exportFileName="soct-main-list"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    onCustomClick={this.handleCustomClick}
                                                    onRowPrepared={this.rowPrepSOCTList}
                                                    // onCustomIconClick={this.handleCustomIconClick}
                                                    columns={[
                                                        { name: "unit_Number", caption: "Unit #", type: "string", renderCell: (e:any) => { return <LALinkButton className="linkUrl" label={e.row.data.unit_Number} onClick={() => this.handleCustomClick("unit_Number", e.row.data)} /> }},
                                                        { name: "make", caption: "Make", type: "string", show: false },
                                                        { name: "model", caption: "Model", type: "string", show: false},
                                                        // { name: "job_No", caption: "Job #", type: "string" },
                                                        { name: "site_Name", caption: "Location Info", type: "string", renderCell: (e:any) => { return <div className="link-btn" onClick={() => this.handleLocationInfoClick(e.row.data)}>{e.row.data.site_Name + " - " + e.row.data.sub_Site_Name + " - " + e.row.data.job_No} </div>} },
                                                        // { name: "sub_Site_Name", caption: "Sub Location", type: "string" },
                                                        { name: "current_Hours", caption: "Current Hours", type: "number" },
                                                        { name: "ecm", caption: "ECM", type: "number" },
                                                        { name: "meter_Date", caption: "Meter Date", type: "date" },
                                                        { name: "machine_Hours", caption: "Machine Hours", type: "number"},
                                                        { name: "service_Date", caption: "Service Date", type: "date" },
                                                        { name: "last_EO", caption: "Last EO", type: "number" },
                                                        { name: "last_100", caption: "Last 100", type: "number" },
                                                        { name: "next_100_Due", caption: "Next 100 Due", type: "number" },
                                                        { name: "next_250_Due", caption: "Next 250 Due", type: "number" },
                                                        { name: "last_500", caption: "Last 500", type: "number" },
                                                        { name: "next_500_Due", caption: "Next 500 Due", type: "number" },
                                                        { name: "last_1000", caption: "Last 1000", type: "number" , show: false},
                                                        { name: "next_1000_Due", caption: "Next 1000 Due", type: "number", show: false},
                                                        { name: "last_1500", caption: "Last 1500", type: "number" },
                                                        { name: "next_1500_Due", caption: "Next 1500 Due", type: "number" },
                                                        { name: "last_3000", caption: "Last 3000", type: "number" },
                                                        { name: "next_3000_Due", caption: "Next 3000 Due", type: "number" },
                                                        { name: "last_ScheduleA", caption: "Last Schedule A", type: "number", show: false },
                                                        { name: "next_ScheduleA_Due", caption: "Next Schedule A Due", type: "number", show: false },
                                                        { name: "last_ScheduleB", caption: "Last Schedule B", type: "number", show: false },
                                                        { name: "next_ScheduleB_Due", caption: "Next Schedule B Due", type: "number", show: false },
                                                        { name: "last_ScheduleD", caption: "Last Schedule D", type: "number", show: false },
                                                        { name: "next_ScheduleD_Due", caption: "Next Schedule D Due", type: "number", show: false },
                                                        { name: "socT_Comments", caption: "Comments", type: "string" },
                                                        { name: "unit_History", caption: "Unit History", type: "button" },
                                                        { name: "custom_Service_Comments", caption: "Custom Service Comments", type: "string", show: false },
                                                        { name: "geotab_Sync", caption: "GeoTab", type: "string" },
                                                        { name: "move_Status", caption: "Move Status", type: "string", renderCell: (e:any) => { return e.row.data.move_Status === "Initiated" || e.row.data.move_Status === "Dispatched" || e.row.data.move_Status === "Requested" || e.row.data.move_Status === "Moved" || e.row.data.move_Status === "En Route" ? <div className="link-btn" onClick={() => this.handleMoveStatusClick(e.row.data)}>{e.row.data.move_Status} </div> : <div></div>}  },
                                                        { name: "repair_Status", caption: "Repair Status", type: "string", renderCell: (e:any) => { return e.row.data.repair_Status === "Active" ? <div className="link-btn" onClick={() => this.handleRepairStatusClick(e.row.data)}>{e.row.data.repair_Status} </div> : <div></div>} },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                                    ]}
                                                />
                                            </LAGridItem>

                                        </LAGrid>}
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    }
                </SoctListStyles>
            </PageSpacing>
        );
    }

    private onViewClick = (e:any, value:string): void => {
        this.setState({ activeView: value });
    };

    private rowPrepSOCTList = (e: any) => { 
        if (e.data) {
            e.columns.map((item: any, index: number) => {
                if(item.caption === "Location Info") {
                    if(e.data.site_Status === "Rained/Snowed out" || e.data.site_Status === "Weather Standby") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.background = `url(${process.env.PUBLIC_URL + '/img/giphy.webp'})`;
                    }
                }

                if(item.caption === "Move Status") {
                    if(e.data.move_Status === "Initiated") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    if(e.data.move_Status === "Dispatched") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = YELLOW_COLOR;
                    }
                    if(e.data.move_Status === "Requested") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                    if(e.data.move_Status === "Moved") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    // if(e.data.move_Status === "Completed") {
                    //     if (e.rowElement.cells[index])
                    //         e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    // }
                    // if(e.data.move_Status === "Cancelled") {
                    //     if (e.rowElement.cells[index])
                    //         e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    // }
                    // if(e.data.move_Status === "Rejected") {
                    //     if (e.rowElement.cells[index])
                    //         e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    // }
                    if(e.data.move_Status === "En Route") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }

                if(item.caption === "Repair Status") {
                    if(e.data.repair_Status === "Active") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }
            })
        }
    }

    private handleCustomClick = (name: string, e: any): void => {
        // console.log(name, e);
        if(name === "unit_Number") {
            window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT + "?id=" + e.equipment_ID.toString())
        }
        if(name === "unit_History") {
            window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY + "?id=" + e.row.data.equipment_ID.toString(), "_blank")
        }
    };

    private handleLocationInfoClick = (data: ISoctList): void => {
        // console.log(data);
        if(data.wsS_ID > 0) {
            window.open(ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOG + "?id=" + data.wsS_ID.toString())
        }
    };

    private handleRepairStatusClick = (data: ISoctList): void => {
        // console.log(data);
        if(data.repair_Status_ID > 0) {
            window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_Status_ID.toString())
        }
    };
    private handleMoveStatusClick = (data: ISoctList): void => {
        // console.log(data);
        if(data.move_Status_ID && data.move_Status_ID > 0) {
            window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE + data.move_Status_ID.toString())
        }
    };

    private onEdit = (e: any): void => {
        // console.log(e);
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.socT_ID.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.SOCT.ADD_UPDATE_SOCT
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.SOCT.ADD_UPDATE_SOCT
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                if (isNotLoaded(this.props.getList)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSoctListRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISoctListStoreProps => ({
    getToken: getToken(state),
    getList: getSoctList(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISoctListDispatchProps => ({
    getSoctListRequest: (data: ISoctListRequest) => dispatch(getSoctListLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(SoctList);