
export enum IADD_SERVICE_DUE_REQUEST {
    REQUEST = "addServiceDue/ADD_SERVICE_DUE_REQUEST",
    SUCCESS = "addServiceDue/ADD_SERVICE_DUE_SUCCESS",
    FAILED = "addServiceDue/ADD_SERVICE_DUE_FAILED"
};

export interface IAddServiceDueRequest {
    token: string;
    request: IAddUpdateServiceDue;
};

export interface IAddUpdateServiceDue {
    ID: number;
    Service_Due_Name: string;
    Active: string;
    Created_By?: string;//Required only for Add
    Modified_By: string;
}