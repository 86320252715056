import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_VENDOR_ORDER_BY_ID_REQUEST, IGetVendorOrderByID } from "./getVendorOrderByIDConstants";
import { IGetVendorOrderByIDLoadAction, IGetVendorOrderByIDLoadFailedAction, IGetVendorOrderByIDSuccessAction } from "./getVendorOrderByIDActions";
import { IUpdateVendorOrderSuccessAction } from "../updateVendorOrder/updateVendorOrderActions";
import { IAddVendorOrderSuccessAction } from "../addVendorOrder/addVendorOrderActions";
import { IUPDATE_VENDOR_ORDER_REQUEST } from "../updateVendorOrder/updateVendorOrderConstants";
import { IADD_VENDOR_ORDER_REQUEST } from "../addVendorOrder/addVendorOrderConstants";

type Actions =
    | IGetVendorOrderByIDLoadAction
    | IGetVendorOrderByIDSuccessAction
    | IGetVendorOrderByIDLoadFailedAction
    | IUpdateVendorOrderSuccessAction
    | IAddVendorOrderSuccessAction
    | IFlushDataSuccessAction;

export const GetVendorOrderByIDReducer = (state: Server<SurewayAPIResponse<IGetVendorOrderByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetVendorOrderByID>> => {
    switch (action.type) {
        case IGET_VENDOR_ORDER_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_VENDOR_ORDER_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_VENDOR_ORDER_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_VENDOR_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_VENDOR_ORDER_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};