import { IAddUpdateMechanic } from "../addMechanic/addMechanicConstants";

export enum IUPDATE_MECHANIC_REQUEST {
    REQUEST = "updateMechanic/UPDATE_MECHANIC_REQUEST",
    SUCCESS = "updateMechanic/UPDATE_MECHANIC_SUCCESS",
    FAILED = "updateMechanic/UPDATE_MECHANIC_FAILED"
};

export interface IUpdateMechanicRequest {
    token: string;
    request: IAddUpdateMechanic;
};