import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_UNIT_HISTORY_BY_ID_REQUEST, IGetUnitHistoryById } from "./getUnitHistoryByIdConstants";
import { IGetUnitHistoryByIdLoadAction, IGetUnitHistoryByIdLoadFailedAction, IGetUnitHistoryByIdSuccessAction } from "./getUnitHistoryByIdActions";

type Actions =
    | IGetUnitHistoryByIdLoadAction
    | IGetUnitHistoryByIdSuccessAction
    | IGetUnitHistoryByIdLoadFailedAction
    | IFlushDataSuccessAction;

export const GetUnitHistoryByIdReducer = (state: Server<SurewayAPIResponse<ById<IGetUnitHistoryById>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetUnitHistoryById>>> => {
    switch (action.type) {
        case IGET_UNIT_HISTORY_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_UNIT_HISTORY_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_UNIT_HISTORY_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};