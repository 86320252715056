import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST, IDriversAssignmentHistory } from "./getDriversAssignmentHistoryConstants";
import { IGetDriversAssignmentHistoryLoadAction, IGetDriversAssignmentHistoryLoadFailedAction, IGetDriversAssignmentHistorySuccessAction } from "./getDriversAssignmentHistoryActions";
import { ISaveDriversAssignmentSuccessAction } from "../saveDriversAssignment/saveDriversAssignmentActions";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST } from "../saveDriversAssignment/saveDriversAssignmentConstants";

type Actions =
    | IGetDriversAssignmentHistoryLoadAction
    | IGetDriversAssignmentHistorySuccessAction
    | ISaveDriversAssignmentSuccessAction
    | IGetDriversAssignmentHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetDriversAssignmentHistoryReducer = (state: Server<SurewayAPIResponse<ById<IDriversAssignmentHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IDriversAssignmentHistory>>> => {
    switch (action.type) {
        case IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DRIVERS_ASSIGNMENT_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};