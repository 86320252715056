import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IADD_FORM_RESPONSE_REQUEST } from "./addFormResponseConstants";
import { IAddFormResponseLoadAction, IAddFormResponseLoadFailedAction, IAddFormResponseSuccessAction } from "./addFormResponseActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IAddFormResponseLoadAction
    | IAddFormResponseSuccessAction
    | IAddFormResponseLoadFailedAction
    | IFlushDataSuccessAction;

export const AddFormResponseReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_FORM_RESPONSE_REQUEST.REQUEST:
            return loading;

        case IADD_FORM_RESPONSE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_FORM_RESPONSE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};