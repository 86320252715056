import { IWORK_ORDER_REQUEST } from "./workOrderConstants";
import { IWorkOrderLoadAction, IWorkOrderLoadFailedAction, IWorkOrderSuccessAction } from "./workOrderActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IWorkOrderLoadAction
    | IWorkOrderSuccessAction
    | IWorkOrderLoadFailedAction
    | IFlushDataSuccessAction;

export const WorkOrderReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IWORK_ORDER_REQUEST.REQUEST:
            return loading;

        case IWORK_ORDER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IWORK_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};