import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import {  } from "../getForms/getFormsConstants";
import { IGetHazardByID, IGET_HAZARD_BY_ID_REQUEST, IGetHazardByIDRequest } from "./getHazardByIDConstants";

export interface IGetHazardByIDLoadAction {
    type: IGET_HAZARD_BY_ID_REQUEST.REQUEST;
    data: IGetHazardByIDRequest
}
export const getHazardByIDLoadAction = (data: IGetHazardByIDRequest): IGetHazardByIDLoadAction => ({
    type: IGET_HAZARD_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetHazardByIDSuccessAction {
    type: IGET_HAZARD_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetHazardByID>;
}
export const getHazardByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetHazardByID>): IGetHazardByIDSuccessAction => ({
    type: IGET_HAZARD_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetHazardByIDLoadFailedAction {
    type: IGET_HAZARD_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getHazardByIDLoadFailedAction = (message: string): IGetHazardByIDLoadFailedAction => ({
    type: IGET_HAZARD_BY_ID_REQUEST.FAILED,
    message
});
