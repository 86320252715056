import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FORM_SIGNATURE_HISTORY_REQUEST, IGetFormSignatureHistory, IGetFormSignatureHistoryRequest } from "./getFormSignatureHistoryConstants";

export interface IGetFormSignatureHistoryLoadAction {
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.REQUEST;
    data: IGetFormSignatureHistoryRequest
}
export const getFormSignatureHistoryLoadAction = (data: IGetFormSignatureHistoryRequest): IGetFormSignatureHistoryLoadAction => ({
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.REQUEST,
    data
});
export interface IGetFormSignatureHistorySuccessAction {
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetFormSignatureHistory[]>;
}
export const getFormSignatureHistoryLoadSuccessAction = (list: SurewayAPIResponse<IGetFormSignatureHistory[]>): IGetFormSignatureHistorySuccessAction => ({
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.SUCCESS,
    list
});
export interface IGetFormSignatureHistoryLoadFailedAction {
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.FAILED;
    message: string;
}
export const getFormSignatureHistoryLoadFailedAction = (message: string): IGetFormSignatureHistoryLoadFailedAction => ({
    type: IGET_FORM_SIGNATURE_HISTORY_REQUEST.FAILED,
    message
});
