

export enum ISAVE_VENDOR_PO_LOG_REQUEST {
    REQUEST = "saveVendorPOLog/SAVE_VENDOR_PO_LOG_REQUEST",
    SUCCESS = "saveVendorPOLog/SAVE_VENDOR_PO_LOG_SUCCESS",
    FAILED = "saveVendorPOLog/SAVE_VENDOR_PO_LOG_FAILED"
};

export interface ISaveVendorPOLogRequest {
    token: string;
    request: ISaveVendorPOLog;
};

export interface ISaveVendorPOLog {
    ID: number;
    Requested_By: string;
    Date: string;
    PO: string;
    Vendor: string;
    Job_ID: number;
    Order_ID: number;
    Scope: string;
    Description: string;
    Cost_Code: string;
    Ticket: string;
    Invoice: string;
    LEM_No: string;
    In_CDMS: string;
    In_Explorer: string;
    Committed_Amount: number | null;
    Invoiced_Amount: number | null;
    Accrued_Cost: string;
    Accrued_Revenue: string;
    ROG: string;
    ROG_Date: string;
    Created: string;
    Created_By: string;
    Modified: string;
    Modified_By: string;
};