import { IAddUpdateDepartment } from "../addDepartment/addDepartmentConstants";

export enum IUPDATE_DEPARTMENT_REQUEST {
    REQUEST = "updateDepartment/UPDATE_DEPARTMENT_REQUEST",
    SUCCESS = "updateDepartment/UPDATE_DEPARTMENT_SUCCESS",
    FAILED = "updateDepartment/UPDATE_DEPARTMENT_FAILED"
};

export interface IUpdateDepartmentRequest {
    token: string;
    request: IAddUpdateDepartment;
};