import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_MEDICAL_FACILITIES_REQUEST, IGetMedicalFacilities, IGetMedicalFacilitiesRequest } from "./getMedicalFacilitiesConstants";

export interface IGetMedicalFacilitiesLoadAction {
    type: IGET_MEDICAL_FACILITIES_REQUEST.REQUEST;
    data: IGetMedicalFacilitiesRequest
}
export const getMedicalFacilitiesLoadAction = (data: IGetMedicalFacilitiesRequest): IGetMedicalFacilitiesLoadAction => ({
    type: IGET_MEDICAL_FACILITIES_REQUEST.REQUEST,
    data
});
export interface IGetMedicalFacilitiesSuccessAction {
    type: IGET_MEDICAL_FACILITIES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetMedicalFacilities>>;
}
export const getMedicalFacilitiesLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetMedicalFacilities>>): IGetMedicalFacilitiesSuccessAction => ({
    type: IGET_MEDICAL_FACILITIES_REQUEST.SUCCESS,
    list
});
export interface IGetMedicalFacilitiesLoadFailedAction {
    type: IGET_MEDICAL_FACILITIES_REQUEST.FAILED;
    message: string;
}
export const getMedicalFacilitiesLoadFailedAction = (message: string): IGetMedicalFacilitiesLoadFailedAction => ({
    type: IGET_MEDICAL_FACILITIES_REQUEST.FAILED,
    message
});
