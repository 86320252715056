import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST {
    REQUEST = "getScheduleServiceHistoryById/GET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST",
    SUCCESS = "getScheduleServiceHistoryById/GET_SCHEDULE_SERVICE_HISTORY_BY_ID_SUCCESS",
    FAILED = "getScheduleServiceHistoryById/GET_SCHEDULE_SERVICE_HISTORY_BY_ID_FAILED"
};

export interface IGetScheduleServiceHistoryByIdRequest extends ISurewayTokenRequestBody {
    request: {
        ID: number;
    };
};

export interface IScheduleServiceHistory {
  id: number;
  service_Date: string;
  service_Type: string;
  unit_Number?: string;
  unit_ID: number;
  meter_Reading: number | null;
  completed_Meter_Reading?: number;
  comments: string;
  ecm: number | null;
  custom_Service: string;
  completed_Date?: string;
  email_Shop_Admin?: string;
  attachments: string;
  created_By: string;
  modified_By: string;
  cancelled: string;
  service_Location?: string;
  service_Sub_Location?: string;
  service_Sub_Location_ID?: number;
  current_Location?: string;
  current_Sub_Location?: string;
  current_Sub_Location_ID?: number;
  created: string;
  modified: string;
  socT_ID: number;
  admin_Comments?: string;
  pM_Sheet_ID?: number;
  update_Current_Hours?: string;
  service_Items?: string;
};