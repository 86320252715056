import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_LEVEL_REQUEST, ILevels } from "./getLevelsConstants";
import { IGetLevelsLoadAction, IGetLevelsLoadFailedAction, IGetLevelsSuccessAction } from "./getLevelsActions";
import { IAddLevelSuccessAction } from "../addLevel/addLevelActions";
import { IADD_LEVEL_REQUEST } from "../addLevel/addLevelConstants";
import { IUpdateLevelSuccessAction } from "../updateLevel/updateLevelActions";
import { IUPDATE_LEVEL_REQUEST } from "../updateLevel/updateLevelConstants";

type Actions =
    | IGetLevelsLoadAction
    | IGetLevelsSuccessAction
    | IGetLevelsLoadFailedAction
    | IUpdateLevelSuccessAction
    | IAddLevelSuccessAction
    | IFlushDataSuccessAction;

export const GetLevelsReducer = (state: Server<SurewayAPIResponse<ById<ILevels>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ILevels>>> => {
    switch (action.type) {
        case IGET_LEVEL_REQUEST.REQUEST:
            return loading;

        case IGET_LEVEL_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LEVEL_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_LEVEL_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_LEVEL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};