import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { EXTRA_LIGHT_YELLOW_COLOR, LIGHT_BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_ORANGE_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import LAErrorBox from "../../shared/errorBox";
import { Navbar, SurveyMenu } from "../../shared/navbars";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { getMachineControls } from "../../../redux/survey/getMachineControls/getMachineControlsAccessor";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getMachineControlLoadAction } from "../../../redux/survey/getMachineControls/getMachineControlsActions";
import { IMachineControl, IMachineControlRequest } from "../../../redux/survey/getMachineControls/getMachineControlsConstants";

interface IMachineControlsComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMachineControlStatus: Server<SurewayAPIResponse<ById<IMachineControl>>>;
};

interface IMachineControlsComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMachineControlsRequest: (data: IMachineControlRequest) => unknown;
};


interface IMachineControlsComponentOwnProps {

};

interface IMachineControlsComponentState {
    // view: string;
};

const MachineControlsComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IMachineControlsComponentProps = RouteComponentProps
    & IMachineControlsComponentStoreProps
    & IMachineControlsComponentDispatchProps
    & IMachineControlsComponentOwnProps;

class MachineControlsComponent extends PureComponent<IMachineControlsComponentProps, IMachineControlsComponentState> {
    
    public constructor(props: IMachineControlsComponentProps) {
        super(props);
        this.state = {
            // view: "Active"
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IMachineControlsComponentProps): void {

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getMachineControlStatus, getToken  } = this.props;
        const getRole = pageAccessCheck(getToken, "surveyAccess");
        const data = hasPayload(getMachineControlStatus) ? Object.values(getMachineControlStatus.payload.response) : [];

        return (
            <PageSpacing title="Machine Control" description="Survey - Machine Control" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={SurveyMenu} role={getRole}/>
                {(getRole !== NotApplicable) && <>
                    <MachineControlsComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>MACHINE CONTROL</h2><br />
                                <hr />

                                {getMachineControlStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Machine Controls..." />}
                                {getMachineControlStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Machine Controls..." />}

                                {getMachineControlStatus.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="MachineControl"
                                                customRowColor={true}
                                                exportFileName="MachineControl"
                                                storageKey="machine-control"
                                                onRowPrepared={this.rowPrepMachineControlsPage}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "unit_No", caption: "Unit #", type: "string" },
                                                    { name: "site", caption: "Site", type: "string"},
                                                    { name: "equipment_Type", caption: "Equipment Type", type: "string"},
                                                    { name: "make", caption: "Make", type: "string"},
                                                    { name: "model", caption: "Model", type: "string"},
                                                    { name: "gpS_Make", caption: "GPS Make", type: "string"},
                                                    { name: "controlBox", caption: "Control Box", type: "string"},
                                                    { name: "receiver_1", caption: "Receiver 1", type: "string" },
                                                    { name: "receiver_2", caption: "Receiver 2", type: "string" },
                                                    { name: "attachments", caption: "Attachments", type: "string" },
                                                    { name: "status", caption: "Status", type: "string" },
                                                    { name: "details", caption: "Details", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true, show: false },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>
                    </MachineControlsComponentStyles>
                </>}
            </PageSpacing>
        );
    }

    private rowPrepMachineControlsPage = (e: any) : void => {
        // console.log(e)
        if(e.data) {
            e.columns.forEach((item: any, index: number) => {
                if (item.caption === "Status") {
                    if (e.data.status === "Office") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    } 
                    if (e.data.status === "Site") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                    if (e.data.status === "Out for Service") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    if (e.data.status === "Retired") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_COLOR;
                    }
                    if (e.data.status === "Missing") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                }
            });
        }
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.SURVEY.MACHINE_CONTROL.ADD_UPDATE_MACHINE_CONTROL
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "surveyAccess") !== NotApplicable) {
                if (isNotLoaded(this.props.getMachineControlStatus)) {
                    this.getDataForTable();
                };
            } 
            else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMachineControlsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IMachineControlsComponentStoreProps => ({
    getToken: getToken(state),
    getMachineControlStatus: getMachineControls(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMachineControlsComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMachineControlsRequest: (data: IMachineControlRequest) => dispatch(getMachineControlLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MachineControlsComponent);