import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IADD_VENDOR_ORDER_REQUEST } from "./addVendorOrderConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IAddVendorOrderLoadAction, IAddVendorOrderLoadFailedAction, IAddVendorOrderSuccessAction } from "./addVendorOrderActions";

type Actions =
    | IAddVendorOrderLoadAction
    | IAddVendorOrderSuccessAction
    | IAddVendorOrderLoadFailedAction
    | IFlushDataSuccessAction;

export const AddVendorOrderReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_VENDOR_ORDER_REQUEST.REQUEST:
            return loading;

        case IADD_VENDOR_ORDER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_VENDOR_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};