import { IADD_SWAMPER_REQUEST } from "./addSwamperConstants";
import { IAddSwamperLoadAction, IAddSwamperLoadFailedAction, IAddSwamperSuccessAction } from "./addSwamperActions";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

type Actions =
    | IAddSwamperLoadAction
    | IAddSwamperSuccessAction
    | IAddSwamperLoadFailedAction
    | IFlushDataSuccessAction;

export const AddSwamperReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_SWAMPER_REQUEST.REQUEST:
            return loading;

        case IADD_SWAMPER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_SWAMPER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};