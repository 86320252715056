import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IDELETE_ATTACHMENT_LIST_GET_REQUEST, IDeleteAttachmentListGETRequest } from "./deleteAttachmentListGETConstants";


export interface IDeleteAttachmentListGETLoadAction {
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.REQUEST;
    data: IDeleteAttachmentListGETRequest
}
export const deleteAttachmentListGETLoadAction = (data: IDeleteAttachmentListGETRequest): IDeleteAttachmentListGETLoadAction => ({
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.REQUEST,
    data
});
export interface IDeleteAttachmentListGETSuccessAction {
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const deleteAttachmentListGETLoadSuccessAction = (message: SurewayAPIResponse<string>): IDeleteAttachmentListGETSuccessAction => ({
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.SUCCESS,
    message
});
export interface IDeleteAttachmentListGETLoadFailedAction {
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.FAILED;
    message: string;
}
export const deleteAttachmentListGETLoadFailedAction = (message: string): IDeleteAttachmentListGETLoadFailedAction => ({
    type: IDELETE_ATTACHMENT_LIST_GET_REQUEST.FAILED,
    message
});
