import { IAddFilterCut } from "../addFilterCut/addFilterCutConstants";

export enum IUPDATE_FILTER_CUT_REQUEST {
    REQUEST = "updateFilterCut/UPDATE_FILTER_CUT_REQUEST",
    SUCCESS = "updateFilterCut/UPDATE_FILTER_CUT_SUCCESS",
    FAILED = "updateFilterCut/UPDATE_FILTER_CUT_FAILED"
};

export interface IUpdateFilterCutRequest {
    token: string;
    request: IAddFilterCut;
};