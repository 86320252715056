import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_CREW_REQUEST, IAddCrewRequest } from "./addCrewConstants";


export interface IAddCrewLoadAction {
    type: IADD_CREW_REQUEST.REQUEST;
    data: IAddCrewRequest
}
export const addCrewLoadAction = (data: IAddCrewRequest): IAddCrewLoadAction => ({
    type: IADD_CREW_REQUEST.REQUEST,
    data
});
export interface IAddCrewSuccessAction {
    type: IADD_CREW_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addCrewLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddCrewSuccessAction => ({
    type: IADD_CREW_REQUEST.SUCCESS,
    message
});
export interface IAddCrewLoadFailedAction {
    type: IADD_CREW_REQUEST.FAILED;
    message: string;
}
export const addCrewLoadFailedAction = (message: string): IAddCrewLoadFailedAction => ({
    type: IADD_CREW_REQUEST.FAILED,
    message
});
