import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_JOB_MHR_REQUEST } from "./updateJobMhrConstants";
import { IUpdateJobMhrLoadAction, IUpdateJobMhrLoadFailedAction, IUpdateJobMhrSuccessAction, updateJobMhrLoadFailedAction, updateJobMhrLoadSuccessAction } from "./updateJobMhrActions";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { END_POINTS } from "../../../endpoints";
import { withJsonContentType } from "../../../epicUtils";

export const updateJobMhrEpic: Epic = (
    action$: ActionsObservable<IUpdateJobMhrLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateJobMhrSuccessAction | IUpdateJobMhrLoadFailedAction> =>
    action$.ofType(IUPDATE_JOB_MHR_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.ACCOUNTING.JOBS.UPDATE_JOB_MHR,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateJobMhrSuccessAction | IUpdateJobMhrLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateJobMhrLoadSuccessAction(response);
                            }
                            return updateJobMhrLoadFailedAction(response.message);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateJobMhrLoadFailedAction(response.message)))
                    )
            )
        );