import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_MECHANICS_REQUEST, IMechanic } from "./getMechanicsConstants";
import { IGetMechanicsLoadAction, IGetMechanicsLoadFailedAction, IGetMechanicsSuccessAction } from "./getMechanicsActions";
import { IAddMechanicSuccessAction } from "../addMechanic/addMechanicActions";
import { IUpdateMechanicSuccessAction } from "../updateMechanic/updateMechanicActions";
import { IUPDATE_MECHANIC_REQUEST } from "../updateMechanic/updateMechanicConstants";
import { IADD_MECHANIC_REQUEST } from "../addMechanic/addMechanicConstants";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "../../repairLine/saveMechanicAssignment/saveMechanicAssignmentConstants";
import { ISaveMechanicAssignmentSuccessAction } from "../../repairLine/saveMechanicAssignment/saveMechanicAssignmentActions";

type Actions =
    | IGetMechanicsLoadAction
    | IGetMechanicsSuccessAction
    | IGetMechanicsLoadFailedAction
    | IAddMechanicSuccessAction
    | IUpdateMechanicSuccessAction
    | ISaveMechanicAssignmentSuccessAction
    | IFlushDataSuccessAction;

export const GetMechanicsReducer = (state: Server<SurewayAPIResponse<ById<IMechanic>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IMechanic>>> => {
    switch (action.type) {
        case IGET_MECHANICS_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANICS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANICS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IUPDATE_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_MECHANIC_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_MECHANIC_ASSIGNMENT_REQUEST.SUCCESS:
                return notLoaded;
    

        default:
            return state;
    }
};