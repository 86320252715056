import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_FILTER_CUT_REQUEST, IAddFilterCutRequest } from "./addFilterCutConstants";


export interface IAddFilterCutLoadAction {
    type: IADD_FILTER_CUT_REQUEST.REQUEST;
    data: IAddFilterCutRequest
}
export const addFilterCutLoadAction = (data: IAddFilterCutRequest): IAddFilterCutLoadAction => ({
    type: IADD_FILTER_CUT_REQUEST.REQUEST,
    data
});
export interface IAddFilterCutSuccessAction {
    type: IADD_FILTER_CUT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addFilterCutLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddFilterCutSuccessAction => ({
    type: IADD_FILTER_CUT_REQUEST.SUCCESS,
    message
});
export interface IAddFilterCutLoadFailedAction {
    type: IADD_FILTER_CUT_REQUEST.FAILED;
    message: string;
}
export const addFilterCutLoadFailedAction = (message: string): IAddFilterCutLoadFailedAction => ({
    type: IADD_FILTER_CUT_REQUEST.FAILED,
    message
});
