import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_DEPARTMENTS_REQUEST, IGetDepartments, IGetDepartmentsRequest } from "./getDepartmentsConstants";

export interface IGetDepartmentsLoadAction {
    type: IGET_DEPARTMENTS_REQUEST.REQUEST;
    data: IGetDepartmentsRequest
}
export const getDepartmentsLoadAction = (data: IGetDepartmentsRequest): IGetDepartmentsLoadAction => ({
    type: IGET_DEPARTMENTS_REQUEST.REQUEST,
    data
});
export interface IGetDepartmentsSuccessAction {
    type: IGET_DEPARTMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetDepartments>>;
}
export const getDepartmentsLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetDepartments>>): IGetDepartmentsSuccessAction => ({
    type: IGET_DEPARTMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetDepartmentsLoadFailedAction {
    type: IGET_DEPARTMENTS_REQUEST.FAILED;
    message: string;
}
export const getDepartmentsLoadFailedAction = (message: string): IGetDepartmentsLoadFailedAction => ({
    type: IGET_DEPARTMENTS_REQUEST.FAILED,
    message
});
