import { IGET_MAPPING_VIEW_REQUEST, IMappingView, IMappingViewRequest } from "./getMappingViewConstants";

export interface IGetMappingViewLoadAction {
    type: IGET_MAPPING_VIEW_REQUEST.REQUEST;
    data: IMappingViewRequest
}
export const getMappingViewLoadAction = (data: IMappingViewRequest): IGetMappingViewLoadAction => ({
    type: IGET_MAPPING_VIEW_REQUEST.REQUEST,
    data
});
export interface IGetMappingViewSuccessAction {
    type: IGET_MAPPING_VIEW_REQUEST.SUCCESS;
    list: IMappingView[];
}
export const getMappingViewLoadSuccessAction = (list: IMappingView[]): IGetMappingViewSuccessAction => ({
    type: IGET_MAPPING_VIEW_REQUEST.SUCCESS,
    list
});
export interface IGetMappingViewLoadFailedAction {
    type: IGET_MAPPING_VIEW_REQUEST.FAILED;
    message: string;
}
export const getMappingViewLoadFailedAction = (message: string): IGetMappingViewLoadFailedAction => ({
    type: IGET_MAPPING_VIEW_REQUEST.FAILED,
    message
});
