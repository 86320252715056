import { ISurewayTokenRequestBody } from "../../../react/shared/publicInterfaces";

export enum IGET_EXTERNAL_USERS_REQUEST {
    REQUEST = "getExternalUsers/GET_EXTERNAL_USERS_REQUEST",
    SUCCESS = "getExternalUsers/GET_EXTERNAL_USERS_SUCCESS",
    FAILED = "getExternalUsers/GET_EXTERNAL_USERS_FAILED"
};

export interface IGetExternalUsersRequest extends ISurewayTokenRequestBody {

};

export interface IExternalUser {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
    role: string;
    status: string;
    company: string;
    created_By: string;
    created_On: string;
    modified_By: string;
    modified_On: string;
};