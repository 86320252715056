import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_ATTACHMENT_STYLE_REQUEST, IAddAttachmentStyleRequest } from "./addStyleConstants";


export interface IAddAttachmentStyleLoadAction {
    type: IADD_ATTACHMENT_STYLE_REQUEST.REQUEST;
    data: IAddAttachmentStyleRequest
}
export const addAttachmentStyleLoadAction = (data: IAddAttachmentStyleRequest): IAddAttachmentStyleLoadAction => ({
    type: IADD_ATTACHMENT_STYLE_REQUEST.REQUEST,
    data
});
export interface IAddAttachmentStyleSuccessAction {
    type: IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addAttachmentStyleLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddAttachmentStyleSuccessAction => ({
    type: IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS,
    message
});
export interface IAddAttachmentStyleLoadFailedAction {
    type: IADD_ATTACHMENT_STYLE_REQUEST.FAILED;
    message: string;
}
export const addAttachmentStyleLoadFailedAction = (message: string): IAddAttachmentStyleLoadFailedAction => ({
    type: IADD_ATTACHMENT_STYLE_REQUEST.FAILED,
    message
});
