import { EXTERNAL_USER_LOGOUT } from "./externalUserLogOutConstants";

export interface IExternalUserLogOutRequestAction {
    type: EXTERNAL_USER_LOGOUT.REQUEST;
}

export const externalUserLogoutRequestAction = (): IExternalUserLogOutRequestAction => ({
    type: EXTERNAL_USER_LOGOUT.REQUEST,
});

export interface IExternalUserLogOutSuccessAction {
    type: EXTERNAL_USER_LOGOUT.SUCCESS;
}

export const externalUserLogoutSuccessAction = (): IExternalUserLogOutSuccessAction => ({
    type: EXTERNAL_USER_LOGOUT.SUCCESS
});

export interface IExternalUserLogOutFailedAction {
    type: EXTERNAL_USER_LOGOUT.SUCCESS;
}

export const externalUserLogoutFailedAction = (): IExternalUserLogOutFailedAction => ({
    type: EXTERNAL_USER_LOGOUT.SUCCESS,
});