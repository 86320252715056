import { defaultCompany } from "../../../../react/field/pmSheet/serviceSection";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";
import { IAddServiceDueSuccessAction } from "../addServiceDue/addServiceDueActions";
import { IADD_SERVICE_DUE_REQUEST } from "../addServiceDue/addServiceDueConstants";
import { IAddServiceSectionSuccessAction } from "../addServiceSection/addServiceSectionActions";
import { IADD_SERVICE_SECTION_REQUEST } from "../addServiceSection/addServiceSectionConstants";
import { IApproveServiceSheetSuccessAction } from "../approveServiceSheet/approveServiceSheetActions";
import { IAPPROVE_SERVICE_SHEET_REQUEST } from "../approveServiceSheet/approveServiceSheetConstants";
import { IUpdateServiceDueSuccessAction } from "../updateServiceDue/updateServiceDueActions";
import { IUPDATE_SERVICE_DUE_REQUEST } from "../updateServiceDue/updateServiceDueConstants";
import { IUpdateServiceSectionSuccessAction } from "../updateServiceSection/updateServiceSectionActions";
import { IUPDATE_SERVICE_SECTION_REQUEST } from "../updateServiceSection/updateServiceSectionConstants";
import { IGetCompaniesLoadAction, IGetCompaniesLoadFailedAction, IGetCompaniesSuccessAction } from "./getCompaniesActions";
import { IGetCompaniesResponse, IGET_COMPANIES_REQUEST } from "./getCompaniesConstants";

type Actions =
    | IGetCompaniesLoadAction
    | IGetCompaniesSuccessAction
    | IGetCompaniesLoadFailedAction
    | IAddServiceSectionSuccessAction
    | IUpdateServiceSectionSuccessAction
    | IAddServiceDueSuccessAction
    | IUpdateServiceDueSuccessAction
    | IApproveServiceSheetSuccessAction
    | IFlushDataSuccessAction;

export const GetCompaniesReducer = (state: Server<SurewayAPIResponse<IGetCompaniesResponse[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetCompaniesResponse[]>> => {
    switch (action.type) {
        case IGET_COMPANIES_REQUEST.REQUEST:
            return loading;

        case IGET_COMPANIES_REQUEST.SUCCESS:
            let newList = [defaultCompany];
            newList = newList.concat(action.list.response);
            action = {
                ...action,
                list: {
                    ...action.list,
                    response: newList
                }
            };
            return succeeded(action.list);

        case IGET_COMPANIES_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_SECTION_REQUEST.SUCCESS:
            return notLoaded;

        case IADD_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SERVICE_DUE_REQUEST.SUCCESS:
            return notLoaded;

        case IAPPROVE_SERVICE_SHEET_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};