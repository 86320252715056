
export enum IGET_JOURNAL_VERSION_HISTORY_REQUEST {
    REQUEST = "getJournalVersionHistory/GET_JOURNAL_VERSION_HISTORY_REQUEST",
    SUCCESS = "getJournalVersionHistory/GET_JOURNAL_VERSION_HISTORY_SUCCESS",
    FAILED = "getJournalVersionHistory/GET_JOURNAL_VERSION_HISTORY_FAILED"
};

export interface IGetJournalVersionHistoryRequest {
    request: {
        id: number;
    },
    token: string;
};

export interface IGetJournalVersionHistory {
    id: number;
    itemID: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;
    date: string;
    wsS_ID: number;
    name: string;
    work: string;
    description: string;
    images: string;
    site: string;
};