
export enum IGET_FORM_VERSION_HISTORY_REQUEST {
    REQUEST = "getFormVersionHistory/GET_FORM_VERSION_HISTORY_REQUEST",
    SUCCESS = "getFormVersionHistory/GET_FORM_VERSION_HISTORY_SUCCESS",
    FAILED = "getFormVersionHistory/GET_FORM_VERSION_HISTORY_FAILED"
};

export interface IGetFormVersionHistoryRequest {
    request: {
        id: number;
        page: string;
    },
    token: string;
};

export interface IGetFormVersionHistory {
    id: number;
    itemID: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    operation: number;

    // Template
    form_Name?: string; // Template // Form_Sheet
    type?: string; // Template // Questions //Form Admin Hazards
    wsS_ID?: number;
    foreman?: string;
    employees?: string;
    frequency?: string;
    start_Date?: string;
    expiry_Date?: string | null;
    flagged_Notification_Contacts?: string;
    flagged_Notification_Method?: string;
    sections?: string;
    hazard_Assessment?: string;
    departments?: string; // Template //Form Admin Hazards //Form Admin Hazards
    version?: string;
    active?: string; // Template //Form Admin Hazards //Form Admin Hazards
    message?: string;
    wsS_IDS?: string | null;
    all_Sites?: string;
    requires_Crew?: string | null;
    requires_Guest?: string | null;
    requires_Toolbox?: string | null;
    attachments?: string; // Template // Questions // Form_Sheet

    //Template Questions
    form_ID?: number; // Questions // Form_Sheet
    question?: string; // Questions // Form_Sheet_Sections
    options?: string;
    flagged_Options?: string;
    images?: string; // Questions // Form_Sheet_Sections
    section?: string;
    display_Order?: number;
    required?: string;
    image_Required?: string;

    //Form_Sheet
    emp_ID?: number;
    wsS_Log_ID?: number;
    name?: string;  //Form_Sheet //Form Admin Hazards
    tool_Box_Meeting?: string;
    signature?: string;
    additional_Images?: string;
    crew_Signature_Sign_In_Upload?: string;
    working_Site?: string;
    supervisor?: string;
    department?: string;
    form_Updated?: string;
    foreman_Signed?: string;
    foreman_Signed_Date?: string;
    status?: string;
    form_Documents?: string;
    location?: string;

    //Form Sheet Sections
    form_Sheet_ID?: number; // Form_Sheet_Sections // Form_Sheet_Hazard_Assessment // Form_Sheet_Tasks
    form_Question_ID?: number;
    answer?: string;
    flagged?: string;
    comments?: string;

    //Form_Sheet_Tasks
    task?: string;
    task_ID?: number;

    //Form_Sheet_Hazard_Assessment
    hazard?: string; // Form_Sheet_Hazard_Assessment // Form Admin Hazards
    hazard_ID?: number;
    severity?: string;
    probability?: string;
    control_Plans?: string;

    //Form Admin Hazards
    type_ID?: number;
    admin_Approved?: string; //Form Admin Hazards //Form Admin Tasks

    //Form Admin Tasks
    department_ID: number | null;
}