import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import LAErrorBox from "../../../shared/errorBox";
import { ShopSubHeader } from "../../../header/shopSubHeader";
import { getEquipmentManuals } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsAccessor";
import { IEquipmentManual, IGetEquipmentManualsRequest } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsConstants";
import { getEquipmentManualsLoadAction } from "../../../../redux/field/equipmentManuals/getEquipmentManuals/getEquipmentManualsActions";
import queryString from "query-string";

// const defaultSessionStore = '{"columns":[{"visibleIndex":0,"name":"buttons","width":90,"visible":true,"filterValue":null},{"visibleIndex":1,"dataField":"unit_Numbers","name":"unit_Numbers","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":2,"dataField":"make","name":"make","dataType":"number","visible":true,"filterValue":null},{"visibleIndex":3,"dataField":"models","name":"models","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":4,"dataField":"category","name":"category","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":5,"dataField":"created_By","name":"created_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":6,"dataField":"created","name":"created","dataType":"datetime","visible":true,"filterValue":null},{"visibleIndex":7,"dataField":"modified_By","name":"modified_By","dataType":"string","visible":true,"filterValue":null},{"visibleIndex":8,"dataField":"modified","name":"modified","dataType":"datetime","visible":true,"sortOrder":"desc","sortIndex":0,"filterValue":null}],"allowedPageSizes":[10,20,40],"filterPanel":{"filterEnabled":true},"filterValue":null,"searchText":"","pageIndex":0,"pageSize":20,"selectedRowKeys":[]}'

interface IEquipmentManualsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getEquipmentManuals: Server<SurewayAPIResponse<ById<IEquipmentManual>>>;
};

interface IEquipmentManualsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getEquipmentManualsRequest: (data: IGetEquipmentManualsRequest) => unknown;
};


interface IEquipmentManualsOwnProps {

};

interface IEquipmentManualsState {
    
};

const EquipmentManualsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IEquipmentManualsProps = RouteComponentProps
    & IEquipmentManualsStoreProps
    & IEquipmentManualsDispatchProps
    & IEquipmentManualsOwnProps;

class EquipmentManuals extends PureComponent<IEquipmentManualsProps, IEquipmentManualsState> {

    public constructor(props: IEquipmentManualsProps) {
        super(props);
        this.state = {

        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();

        // const query: any = queryString.parse(this.props.location.search);

        // if (query !== undefined && (query.keyword !== undefined)) {
        //     const queryUnitNo = query.keyword;
        //     let val = JSON.parse(defaultSessionStore);
        //     let unitNoArray: any = [];
        //     for (let i = 0; i < val.columns.length; i++) {
        //         if (val.columns[i].name === "unit_Numbers") {
        //             unitNoArray.push(queryUnitNo);
        //             // val.columns[i].filterValues = unitNoArray;
        //             val.columns[i].filterValue = queryUnitNo;
        //         }
                
        //     }
        //     localStorage.setItem("EquipmentManualsListSessionKey", JSON.stringify(val));
        // } 
        // else {
        //     localStorage.setItem("EquipmentManualsListSessionKey", defaultSessionStore);
        // }

        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IEquipmentManualsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getEquipmentManuals, getToken } = this.props;
        // const getRole = pageAccessCheck(getToken, "repairLine");
        const data = hasPayload(getEquipmentManuals) ? Object.values(getEquipmentManuals.payload.response) : [];

        return (
            <PageSpacing title="Equipment Manuals" description="FIELD - Equipment Manuals" fixedSpaceOnSmallerScreens={true}>
                {/* {(getRole !== NotApplicable) &&  */}
                <ShopSubHeader
                        {...this.props}
                    />
                <EquipmentManualsStyles>
                    
                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="title">Equipment Manuals</h2>
                                <hr />

                                {getEquipmentManuals.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Equipment Manuals..." />}
                                {getEquipmentManuals.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Equipment Manuals..." />}

                                {getEquipmentManuals.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                key="id"
                                                id="EquipmentManualsList"
                                                customRowColor={true}
                                                exportFileName="EquipmentManuals"
                                                storageKey="EquipmentManualsListSessionKey"
                                                onClearClick={this.onClearClick}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "unit_Numbers", caption: "Unit #", type: "string" },
                                                    { name: "make", caption: "Make", type: "string" },                                                   
                                                    { name: "models", caption: "Models", type: "string" },
                                                    { name: "category", caption: "Category", type: "string" },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </EquipmentManualsStyles>
                {/* } */}
            </PageSpacing>
        );
    }

    private onClearClick = async(): Promise<void> => {
        await callRouteWithQueryString({
            route: this.props,
            search: {},
            pathName:ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX
        });
        this.getDataForTable();
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.ADDUPDATE_EQUIPMENT_MANUAL
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.ADDUPDATE_EQUIPMENT_MANUAL
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            // if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getEquipmentManuals)) {
                    this.getDataForTable();
                };
            // } 
            // else {
            //     this.props.history.push({
            //         pathname: ROUTE.ACCESS_DENIED,
            //         search: getTokenFromUrl(false)
            //     });
            // };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            const query: any = queryString.parse(this.props.location.search);

            if (query !== undefined && (query.keyword !== undefined)) {
                const queryUnitNo = query.keyword;
                this.props.getEquipmentManualsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        unit_id: Number(queryUnitNo)
                    }
                });
            }
            else {
                this.props.getEquipmentManualsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        unit_id: 0
                    }
                });
            }
        };
    };

}

const mapStateToProps = (state: IStore): IEquipmentManualsStoreProps => ({
    getToken: getToken(state),
    getEquipmentManuals: getEquipmentManuals(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IEquipmentManualsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getEquipmentManualsRequest: (data: IGetEquipmentManualsRequest) => dispatch(getEquipmentManualsLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentManuals);