import { IAddUpdateSOCT } from "../addSOCT/addSOCTConstants";

export enum IUPDATE_SOCT_REQUEST {
    REQUEST = "updateSOCT/UPDATE_SOCT_REQUEST",
    SUCCESS = "updateSOCT/UPDATE_SOCT_SUCCESS",
    FAILED = "updateSOCT/UPDATE_SOCT_FAILED"
};

export interface IUpdateSOCTRequest {
    token: string;
    request: IAddUpdateSOCT;
};
