import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ATTACHMENT_LIST_GET_REQUEST, IAttachmentListGET, IGetAttachmentListGETRequest } from "./getAttachmentListGETConstants";

export interface IGetAttachmentListGETLoadAction {
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.REQUEST;
    data: IGetAttachmentListGETRequest
}
export const getAttachmentListGETLoadAction = (data: IGetAttachmentListGETRequest): IGetAttachmentListGETLoadAction => ({
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.REQUEST,
    data
});
export interface IGetAttachmentListGETSuccessAction {
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IAttachmentListGET>>;
}
export const getAttachmentListGETLoadSuccessAction = (list: SurewayAPIResponse<ById<IAttachmentListGET>>): IGetAttachmentListGETSuccessAction => ({
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.SUCCESS,
    list
});
export interface IGetAttachmentListGETLoadFailedAction {
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.FAILED;
    message: string;
}
export const getAttachmentListGETLoadFailedAction = (message: string): IGetAttachmentListGETLoadFailedAction => ({
    type: IGET_ATTACHMENT_LIST_GET_REQUEST.FAILED,
    message
});
