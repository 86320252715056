import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST, IGetDriverAssignmentVersionHistory } from "./getDriverAssignmentVersionHistoryConstants";
import { IGetDriverAssignmentVersionHistoryLoadAction, IGetDriverAssignmentVersionHistoryLoadFailedAction, IGetDriverAssignmentVersionHistorySuccessAction } from "./getDriverAssignmentVersionHistoryActions";

type Actions =
    | IGetDriverAssignmentVersionHistoryLoadAction
    | IGetDriverAssignmentVersionHistorySuccessAction
    | IGetDriverAssignmentVersionHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const GetDriverAssignmentVersionHistoryReducer = (state: Server<SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetDriverAssignmentVersionHistory>>> => {
    switch (action.type) {
        case IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.REQUEST:
            return loading;

        case IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DRIVER_ASSIGNMENT_VERSION_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};