import LATextArea from "../../shared/textArea";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import LAGrid from "../../shared/grid";
import LALoading from "../../shared/loading";
import LAGridItem from "../../shared/gridList";
import LAErrorBox from "../../shared/errorBox";
import { ReactNode, PureComponent } from "react";
import PageSpacing from "../../shared/pageSpacing";
import { Prompt, RouteComponentProps } from "react-router";
import { DropPaper } from "../../shared/dragAndDropPaper";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { userName, getTokenFromUrl, pageAccessCheck, callRouteWithQueryString, undefinedFunction } from "../../shared/constExports";
import { hasPayload, isNotLoaded, Server, STATUS, STATUS_ENUM } from "../../../redux/server";
import { saveDriverAssignment } from "../../../redux/field/saveDriverAssignment/saveDriverAssignmentAccessor";
import { LAButton, LASecondaryButton } from "../../shared/buttons";
import { GREEN_COLOR, RED_COLOR } from "../../shared/theme";
import { IGetServiceTruckRequest, IServiceTruck } from "../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksConstants";
import { getServiceTrucksLoadAction } from "../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksActions";
import { getServiceTrucks } from "../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksAccessor";
import { IDriversAssignment, IGetDriversAssignmentRequest } from "../../../redux/field/dispatch/getDriversAssignment/getDriversAssignmentConstants";
import { getDriversAssignmentLoadAction } from "../../../redux/field/dispatch/getDriversAssignment/getDriversAssignmentActions";
import { getDriversAssignment } from "../../../redux/field/dispatch/getDriversAssignment/getDriversAssignmentAccessor";
import { saveDriversAssignmentLoadAction } from "../../../redux/field/dispatch/saveDriversAssignment/saveDriversAssignmentActions";
import { ISaveDriversAssignmentRequest, ISaveUpdateDriversAssignment } from "../../../redux/field/dispatch/saveDriversAssignment/saveDriversAssignmentConstants";
import { DispatchSubHeader } from "../../header/dispatchSubHeader";
import { DispatchDriverAssignment, ServiceTruck, getTodayAndTomorrow } from "./driversAssignmentDragAndDrop";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { IFieldLookup, IFieldLookupRequest, ISiteList } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { ROUTE } from "../../routes";
import { LAPopover } from "../../shared/popOver";
import LAAutoComplete from "../../shared/autoComplete";
import { getServiceTruckCommunicationLogs } from "../../../redux/field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsAccessor";
import { IGetServiceTruckCommunicationLogs, IGetServiceTruckCommunicationLogsRequest } from "../../../redux/field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsConstants";
import { getServiceTruckCommunicationLogsLoadAction } from "../../../redux/field/dispatch/getServiceTruckCommunicationLogs/getServiceTruckCommunicationLogsActions";
import { DispatchLogPopup } from "./logsPopup";
import { DriverAssignmentClone } from "./driverAssignmentClone";
import { MdFormatColorReset, MdWaterDrop } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { Checkbox, FormGroup } from "@mui/material";
import { LACheckBox } from "../../shared/checkBox";

export interface IAssignmentSite {
    site: string;
    site_ID: number;
    date: string;
    service_Truck_ID: number | null;
    serviceTruck: IDriversAssignment | undefined;
    site_Status: string;
    service_Required: string | null;
    assigned?: boolean;
};

interface IDriversAssignmentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    saveDriverAssignmentStatus: Server<SurewayAPIResponse<string>>;
    getServiceTruckStatus: Server<SurewayAPIResponse<ById<IServiceTruck>>>;
    getDriverAssignmentStatus: Server<SurewayAPIResponse<ById<IDriversAssignment>>>;
    communicationLogs: Server<SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>>;
};

interface IDriversAssignmentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getServiceTrucksRequest: (data: IGetServiceTruckRequest) => unknown;
    getDriverAssignmentsRequest: (data: IGetDriversAssignmentRequest) => unknown;
    saveDriverAssignmentRequest: (data: ISaveDriversAssignmentRequest) => unknown;
    getDriverCommLogsRequest: (data: IGetServiceTruckCommunicationLogsRequest) => unknown;
};


interface IDriversAssignmentOwnProps {

};

export type IDriverAssignmentSelection = "today" | "tomorrow" | "yesterday";
export type IDriverAssignmentViewSelection = "assigned" | "unassigned" | "all";

interface IDriversAssignmentState {
    clone: boolean;
    popup: boolean;
    popUpLoading: boolean;
    logsPopup: boolean;
    lastPull: string;
    pullAlert: boolean;
    hasChange: boolean;
    serverError: string;
    serviceTruckSearch: string;
    extraSites: IAssignmentSite[];
    driverAssignmentSearch: string;
    driverAssignments: IAssignmentSite[];
    driverAssignmentSelection: IDriverAssignmentSelection;
    // driverAssignmentViewSelection: IDriverAssignmentViewSelection;
    assignedView: boolean;
    unassignedView: boolean;
};

const DriversAssignmentStyles = styled(LAPaperWithLessPadding)`
    margin: 5px 5px;
    word-break: break-word;
    font-size: 14px;
    
    .red-text {
        color: ${RED_COLOR};
    };

    .green-text {
        color: ${GREEN_COLOR};
    };
    
    .green-border {
        border: 4px solid green;
    };
`;

type IDriversAssignmentProps = RouteComponentProps
    & IDriversAssignmentStoreProps
    & IDriversAssignmentDispatchProps
    & IDriversAssignmentOwnProps;

class DriversAssignment extends PureComponent<IDriversAssignmentProps, IDriversAssignmentState> {

    public constructor(props: IDriversAssignmentProps) {
        super(props);
        this.state = {
            clone: false,
            logsPopup: false,
            popUpLoading: false,
            lastPull: "",
            popup: false,
            serverError: "",
            pullAlert: false,
            hasChange: false,
            extraSites: [],
            driverAssignments: [],
            serviceTruckSearch: "",
            driverAssignmentSearch: "",
            driverAssignmentSelection: "today",
            // driverAssignmentViewSelection: "all",
            assignedView: true,
            unassignedView: true
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.onInitialLoad();
        await this.callServer();
    };

    public componentDidUpdate(prevProps: IDriversAssignmentProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.saveDriverAssignmentStatus !== prevProps.saveDriverAssignmentStatus) {
                if (this.props.saveDriverAssignmentStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.saveDriverAssignmentStatus.message });
            };

        };
    };


    public render(): ReactNode {

        const { getToken, getDriverAssignmentStatus, getServiceTruckStatus, saveDriverAssignmentStatus, getLookup, communicationLogs } = this.props;
        const { driverAssignments, serviceTruckSearch, driverAssignmentSearch, driverAssignmentSelection, assignedView, unassignedView, hasChange, pullAlert, extraSites, 
                popup, logsPopup, popUpLoading, clone } = this.state;
        const serviceTruckList = hasPayload(getLookup) ? getLookup.payload.response.service_Trucks : [];
        const serviceTrucks = hasPayload(getServiceTruckStatus) ? getServiceTruckStatus.payload.response : {};
        const logsData = hasPayload(communicationLogs) ? Object.values(communicationLogs.payload.response) : [];
       
        return (
            <PageSpacing title="Driver Dispatch" description="Driver Dispatch" fixedSpaceOnSmallerScreens={true}>
                <DriversAssignmentStyles>

                    <DispatchSubHeader
                        {...this.props}
                        token={getToken}
                    />

                    {getDriverAssignmentStatus.kind === STATUS.LOADING && <LALoading message={pullAlert ? "The list has been updated since last refresh. Page is getting refreshed. Please assign serviceTrucks with latest information!" : "Loading... Please wait"} />}
                    {getDriverAssignmentStatus.kind === STATUS.FAILED && <LAErrorBox text="Failed to load data... Please try again" />}

                    {(getDriverAssignmentStatus.kind === STATUS_ENUM.SUCCEEDED) && <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                        <LAGrid className="pt-2">

                            {pullAlert && <LAGridItem xs={12}>
                                <LAErrorBox text="The list has been updated since last refresh. Please refresh the page and assign serviceTrucks with the latest information!" />
                            </LAGridItem>}

                            <LAGridItem xs={12} className="text-center">                               
                                <LAButton
                                    label="Save Changes"
                                    onClick={this.handleSave}
                                />
                            </LAGridItem>
                            <LAGridItem xs={6} sm={6} md={8}>
                                <LAPaperWithLessPadding>
                                    <h2 className="text-center">DRIVERS ASSIGNMENT</h2>
                                    <LAGrid direction="row" justify="flex-end" alignItems="center">
                                        <label htmlFor="service-required" >
                                            <MdWaterDrop color="primary" size={20}/>
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Service Required</strong>

                                        <label htmlFor="no-service-required" >
                                            <MdFormatColorReset size={22} />
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>No Service Required</strong>

                                        <label htmlFor="service-scheduled" >
                                            <FaCalendarAlt size={16} color="#1976D2" />
                                        </label>
                                        <strong style={{ marginRight: "5px" }}>Service Scheduled</strong>
                                    </LAGrid>  
                                    <hr />

                                    <RadioGroup className="view-btn text-center" row aria-label="" name="radioGroup" value={driverAssignmentSelection} onChange={this.onTypeChange}>
                                        <FormControlLabel value="yesterday" control={<Radio />} label="Yesterday" />
                                        <FormControlLabel value="today" control={<Radio />} label="Today" />
                                        <FormControlLabel value="tomorrow" control={<Radio />} label="Tomorrow" />

                                        <LASecondaryButton
                                            label="Reset Search"
                                            onClick={this.clearDriverAssignmentSearch}
                                        />

                                        <LASecondaryButton
                                            className="ml-2"
                                            label="Add Site"
                                            onClick={this.handleAddSite}
                                        />

                                        {(driverAssignmentSelection !== "yesterday") && <LASecondaryButton
                                            className="ml-2"
                                            label="Clone"
                                            onClick={this.handleClone}
                                        />}
                                    </RadioGroup>

                                    <LAGrid>
                                        <LAGridItem xs={12} sm={6}>
                                            <LATextArea
                                                rowsMax={1}
                                                className="p-2"
                                                fullWidth={true}
                                                name="driverAssignmentSearch"
                                                value={driverAssignmentSearch}
                                                label="Search by name"
                                                onChange={this.handleDriverAssignmentSearch}
                                            />
                                        </LAGridItem>
                                        <LAGridItem xs={12} sm={5}>
                                            {/* <RadioGroup className="view-btn text-center" row aria-label="" name="radioGroup" value={driverAssignmentViewSelection} onChange={this.onDriverAssignmentViewChange}>
                                                <FormControlLabel value="unassigned" control={<Radio />} label="Unassigned" />
                                                <FormControlLabel value="assigned" control={<Radio />} label="Assigned" />
                                                <FormControlLabel value="all" control={<Radio />} label="All" />
                                            </RadioGroup> */}
                                            <LACheckBox
                                                value={assignedView}
                                                label="Assigned"
                                                name="assigned"
                                                onChange={this.handleViewCheckbox}
                                            />
                                            <LACheckBox
                                                value={unassignedView}
                                                label="Unassigned"
                                                name="unassigned"
                                                onChange={this.handleViewCheckbox}
                                            />
                                        </LAGridItem>
                                    </LAGrid>

                                    <LAGrid spacing={0}>
                                    {driverAssignments.map((x, index) => {
                                            return (
                                                <DispatchDriverAssignment
                                                    {...this.props}
                                                    idx={index}
                                                    data={x}
                                                    onPriority={this.onPriority}
                                                    onNotes={this.handleNotes}
                                                    onDelete={this.handleDelete}
                                                    serviceTrucks={serviceTrucks}
                                                    onChange={this.handleDateChange}
                                                    assignmentList={driverAssignments}
                                                    driverAssignmentSearch={driverAssignmentSearch}
                                                    driverAssignmentSelection={driverAssignmentSelection}
                                                    // driverAssignmentViewSelection={driverAssignmentViewSelection}
                                                    assignedView={assignedView}
                                                    unassignedView={unassignedView}
                                                />
                                            );
                                        })}
                                    </LAGrid>
                                </LAPaperWithLessPadding>
                            </LAGridItem>

                            <LAGridItem xs={6} sm={6} md={4}>
                                <LAPaperWithLessPadding>
                                    <h2 className="text-center">SERVICE TRUCKS</h2>
                                    <hr />

                                    <LASecondaryButton
                                        className="ml-2"
                                        label="Reset Search"
                                        onClick={this.clearDriverSearch}
                                    />

                                    <LATextArea
                                        rowsMax={1}
                                        className="p-2"
                                        fullWidth={true}
                                        name="serviceTruckSearch"
                                        value={serviceTruckSearch}
                                        label="Search by driver name"
                                        onChange={this.handleDriverSearch}
                                    />

                                    <Droppable key={1} droppableId="serviceTruck">

                                        {(provided, snapshot) => (
                                            <DropPaper className="text-center"
                                                innerRef={provided.innerRef}
                                                styleDraggar={snapshot.isDraggingOver}>

                                                <LAGrid spacing={0}>
                                                    {Object.values(serviceTrucks)
                                                        .filter((x) => serviceTruckSearch.length > 0 ?
                                                        (x.unit_No && x.unit_No.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                                                            || (x.driver_Name && x.driver_Name.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                                                            || (x.swamper_Name && x.swamper_Name.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                                                            : true)
                                                        .map((x, index) => (
                                                            <ServiceTruck
                                                                data={x}
                                                                key={index}
                                                                index={index}
                                                                onLog={this.handleLog}
                                                                onEdit={this.handleEditST}
                                                                onTruck={this.handleTruckGPS}
                                                                assignmentList={driverAssignments}
                                                                serviceTruckList={serviceTruckList}
                                                                selection={driverAssignmentSelection}
                                                            />
                                                        ))}
                                                </LAGrid>
                                                {provided.placeholder}
                                            </DropPaper>
                                        )}

                                    </Droppable>
                                </LAPaperWithLessPadding>
                            </LAGridItem>

                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Save Changes"
                                    onClick={this.handleSave}
                                />
                            </LAGridItem>

                        </LAGrid>
                    </DragDropContext>}


                    <LAPopover anchorRef={null} open={popup} onClose={this.handleSiteClose}>
                        <LAPaperWithPadding>
                            <LAGrid className="text-center">

                                <LAGridItem xs={12}>
                                    <strong>Add Site into Existing List</strong>
                                </LAGridItem>

                                <LAGridItem xs={12}>
                                    <LAAutoComplete
                                        value={""}
                                        multiple={false}
                                        defaultValue={""}
                                        option={extraSites}
                                        autoHighlight={true}
                                        getOptionLabel="site"
                                        onChange={this.onSiteAdd}
                                        filterSelectedOptions={true}
                                        selectionRemove={undefinedFunction}
                                        dropDownPlaceHolder="Select a site to add into list"
                                    />
                                </LAGridItem>

                            </LAGrid>
                        </LAPaperWithPadding>
                    </LAPopover>

                    <RequestStatus
                        failedMessage="DriverAssignment/s assignment failed! Please try again or contact IT.Developers@sureway.ca if the issue persists!"
                        requestStatus={saveDriverAssignmentStatus.kind}
                        successMessage="DriverAssignment/s assignment is successful!"
                    />

                    <Prompt
                        when={hasChange}
                        message="You have unsaved changes. Please save it before leaving."
                    />

                    {(logsPopup) && <DispatchLogPopup
                        data={logsData}
                        open={logsPopup}
                        popUpLoading={popUpLoading}
                        status={communicationLogs.kind}
                        onCancel={this.handleLogPopupClose}
                    />}

                    {(clone) && <DriverAssignmentClone 
                        open={clone}
                        onClose={this.cloneClose}
                        serviceTrucks={serviceTrucks}
                        onComplete={this.onCloneComplete}
                        driverAssignment={driverAssignments}
                        selection={driverAssignmentSelection}
                    />}

                </DriversAssignmentStyles>
            </PageSpacing>
        );
    }

    private handleTruckGPS = (id: number): void => {
        // console.log("here")
        window.open(`${ROUTE.FIELD.GPS_RECORD}?id=${id}&page=ST`, "_blank");
    };

    private handleClone = async (): Promise<void> => {
        await this.setState({ clone: true });
    };

    private cloneClose = (): void => {
        this.setState({
            clone: false
        });
    };

    private onCloneComplete = (indexList: number[]): void => {
        const data = [...this.state.driverAssignments];
        let date = this.getTodayOrTomorrow(true);
        const dateObj = new Date(date);
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const formatDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T00:00:00`;

        indexList.forEach((x) => {
            const clone = data[x];
            const idx = data.findIndex(q => (q.date === date) && (clone.site_ID === q.site_ID) && (clone.site === q.site));

            data[idx] = {
                ...data[idx],
                date: date,
                serviceTruck: clone.serviceTruck ? {
                    ...clone.serviceTruck,
                    date: formatDate
                } : clone.serviceTruck,
                service_Truck_ID: clone.service_Truck_ID
            }
        });

        this.setState({
            clone: false,
            driverAssignments: data
        });
    };

    private handleNotes = (value: string, index: number): void => {
        const iS = [...this.state.driverAssignments];

        if (index >= 0) {
            const record = iS[index];
            if (record.serviceTruck !== undefined) {
                record.serviceTruck.notes = value;
            };
            iS[index] = record;
        };

        this.setState({
            driverAssignments: iS
        });
    };

    private handleLogPopupClose = (): void => {
        this.setState({ logsPopup: false, popUpLoading: false });
    };

    private handleLog = async (id: number): Promise<void> => {
        await this.setState({ logsPopup: true, popUpLoading: true });

        setTimeout(() => {
            if (hasPayload(this.props.getToken)) {
                this.props.getDriverCommLogsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        service_truck_id: id
                    }
                });
            }
        }, 500);
    };


    private getTodayOrTomorrow = (toLocalDateString?: true): string => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);

        if (this.state.driverAssignmentSelection === "today") {
            if(toLocalDateString)
                return today.toLocaleDateString('en-US');
            
            return today.toISOString();
        } else {
            if(toLocalDateString)
                return tomorrow.toLocaleDateString('en-US');

            return tomorrow.toISOString();
        }
    }

    private onSiteAdd = (event: unknown, value: IAssignmentSite): void => {
        const data = [...this.state.driverAssignments];

        let addOn = value;
        addOn.date = this.getTodayOrTomorrow();

        data.unshift(addOn);

        this.setState({
            ...this.state,
            popup: false,
            driverAssignments: [
                ...data
            ]
        });
    };

    private handleAddSite = (): void => {
        this.setState({
            ...this.state,
            popup: true
        });
    };

    private handleSiteClose = (): void => {
        this.setState({
            ...this.state,
            popup: false
        });
    };

    private handleEditST = (id: number): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: id.toString() },
            pathName: ROUTE.FIELD.DISPATCH.SERVICE_TRUCK
        });
    };

    private handleDateChange = (date: string, index: number): void => {
        const iS = [...this.state.driverAssignments];

        if (index >= 0) {
            const record = iS[index];
            if (record.serviceTruck !== undefined) {
                record.serviceTruck.date = new Date(date).toISOString();
            };
            iS[index] = record;
        };

        this.setState({
            driverAssignments: iS
        });
    };

    private clearDriverSearch = (): void => {
        this.setState({
            serviceTruckSearch: ""
        });
    };

    private clearDriverAssignmentSearch = (): void => {
        this.setState({
            driverAssignmentSearch: ""
        });
    };

    private onTypeChange = (event: React.ChangeEvent<HTMLInputElement>, driverAssignmentSelection: any): void => {
        this.setState({
            driverAssignmentSelection
        });
    };

    // private onDriverAssignmentViewChange = (event: React.ChangeEvent<HTMLInputElement>, driverAssignmentViewSelection: any): void => {
    //     this.setState({
    //         driverAssignmentViewSelection
    //     });
    // };

    private handleViewCheckbox = (checked: boolean, name?: string): void => {
        if(name === "assigned") {
            this.setState({
                assignedView: checked
            })
        }

        else if(name === "unassigned") {
            this.setState({
                unassignedView: checked
            })
        }
    }

    private handleDelete = (ind: number, truckIndex: number) => {
        const { driverAssignments } = this.state;

        const st = driverAssignments[ind].serviceTruck;
        st?.service_Trucks.splice(truckIndex, 1);

        if (driverAssignments[ind] && st) {
            driverAssignments[ind] = {
                ...driverAssignments[ind],
                serviceTruck: {
                    ...st
                }
            }
        }

        this.setState({
            driverAssignments: [
                ...driverAssignments
            ],
            hasChange: true
        });
    };

    private onPriority = (value: string, ind: number, truckIndex: number) => {
        const { driverAssignments } = this.state;

        const st = driverAssignments[ind].serviceTruck;

        if (st && st.service_Trucks[truckIndex] && !isNaN(Number(value)))
            st.service_Trucks[truckIndex].priority = Number(value);

        if (driverAssignments[ind] && st) {
            driverAssignments[ind] = {
                ...driverAssignments[ind],
                serviceTruck: {
                    ...st
                }
            }
        }

        this.setState({
            driverAssignments: [
                ...driverAssignments
            ],
            hasChange: true
        });
    };

    private handleDriverSearch = (name: string, value: string) => {
        this.setState({ serviceTruckSearch: value });
    };

    private handleDriverAssignmentSearch = (name: string, value: string) => {
        this.setState({ driverAssignmentSearch: value });
    };

    private onDragEnd = async (result: any): Promise<void> => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        const { driverAssignments, serviceTruckSearch, driverAssignmentSelection } = this.state;
        const { getServiceTruckStatus } = this.props;
        const serviceTrucks = hasPayload(getServiceTruckStatus) ?
            Object.values(getServiceTruckStatus.payload.response)
            .filter((x) => serviceTruckSearch.length > 0 ?
            (x.unit_No && x.unit_No.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                || (x.driver_Name && x.driver_Name.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                || (x.swamper_Name && x.swamper_Name.toLowerCase().includes(serviceTruckSearch.toLowerCase()))
                : true)
            : []

        const ind = +destination.droppableId;

        if ((ind !== undefined && source.index !== undefined) && ((driverAssignments[ind]) && (serviceTrucks[source.index]))) {
            const q = serviceTrucks[source.index];

            let stList = driverAssignments[ind].serviceTruck?.service_Trucks;

            let priorityList: number[] = [];
            Object.values(driverAssignments)
                .filter((x) => (driverAssignmentSelection === "today") ? (new Date(x.date).toLocaleDateString('en-US') === getTodayAndTomorrow().today)
                    : (new Date(x.date).toLocaleDateString('en-US') === getTodayAndTomorrow().tomorrow))
                .forEach((x) => {
                    if (x.serviceTruck) {
                        if (x.serviceTruck.service_Trucks.length > 0) {
                            const find = x.serviceTruck.service_Trucks.filter((e) => e.service_Truck_ID === q.id && e.priority).map(({ priority }) => priority);
                            priorityList.push(Math.max(...find));
                        };
                    }
                });

            let max = Math.max(...priorityList);

            if (driverAssignments[ind].serviceTruck?.service_Trucks === undefined) {
                stList = [{
                    notes: "",
                    unit_No: q.unit_No,
                    driver: q.driver_Name,
                    swamper: q.swamper_Name,
                    service_Truck_ID: q.id,
                    priority: (max !== -Infinity) ? max + 1 : 1
                }];
            } else {
                stList?.push({
                    notes: "",
                    unit_No: q.unit_No,
                    driver: q.driver_Name,
                    swamper: q.swamper_Name,
                    service_Truck_ID: q.id,
                    priority: (max !== -Infinity) ? max + 1 : 1
                })
            }

            driverAssignments[ind].serviceTruck = {
                id: q.id,
                service_Trucks: stList ?? [],
                site_ID: driverAssignments[ind].site_ID,
                notes: "",
                sub_Site_ID: driverAssignments[ind].site_ID,
                date: this.getTodayOrTomorrow(),
                status: "",
                site_Info: driverAssignments[ind].site,
                created: q.created,
                created_By: q.created_By,
                modified: q.modified,
                modified_By: q.modified_By
            };

            this.setState({
                ...this.state,
                driverAssignments: [
                    ...driverAssignments
                ],
                hasChange: true
            });
        };
    };

    private onDragStart = () => {
        // Vibrate on drag (only Mobile)
        if (window.navigator.vibrate) {
            window.navigator.vibrate(200);
        };
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (pageAccessCheck(this.props.getToken, "dispatch") !== NotApplicable) {

            if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.getToken))
                this.props.getLookupsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        page: "Service_Trucks"
                    }
                });

            if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDriverAssignmentStatus))
                this.props.getDriverAssignmentsRequest({
                    token: this.props.getToken.payload.response.token
                });

            if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getServiceTruckStatus))
                this.props.getServiceTrucksRequest({
                    token: this.props.getToken.payload.response.token
                });

            if (hasPayload(this.props.getDriverAssignmentStatus) && hasPayload(this.props.getLookup)) {

                const extraSites: IAssignmentSite[] = [];
                const driverAssignments: IAssignmentSite[] = [];
                const sites = this.props.getLookup.payload.response.sites;
                const assignmentList = Object.values(this.props.getDriverAssignmentStatus.payload.response);
                // console.log(sites)

                if (sites && sites.length > 0)
                    sites
                        .sort(this.sortSites)
                        .map((x) => {
                            if (x.working_Site === true) {

                                driverAssignments.push({
                                    site: x.site,
                                    date: getTodayAndTomorrow().yesterday,
                                    site_ID: x.site_ID,
                                    serviceTruck: undefined,
                                    service_Truck_ID: x.service_Truck_ID,
                                    site_Status: x.site_Status,
                                    service_Required: x.service_Required
                                });

                                driverAssignments.push({
                                    site: x.site,
                                    date: getTodayAndTomorrow().today,
                                    site_ID: x.site_ID,
                                    serviceTruck: undefined,
                                    service_Truck_ID: x.service_Truck_ID,
                                    site_Status: x.site_Status,
                                    service_Required: x.service_Required
                                });

                                driverAssignments.push({
                                    site: x.site,
                                    date: getTodayAndTomorrow().tomorrow,
                                    site_ID: x.site_ID,
                                    serviceTruck: undefined,
                                    service_Truck_ID: x.service_Truck_ID,
                                    site_Status: x.site_Status,
                                    service_Required: x.service_Required
                                });
                            } else {
                                extraSites.push({
                                    site: x.site,
                                    date: getTodayAndTomorrow().today,
                                    site_ID: x.site_ID,
                                    serviceTruck: undefined,
                                    service_Truck_ID: x.service_Truck_ID,
                                    site_Status: x.site_Status,
                                    service_Required: x.service_Required
                                });

                                extraSites.push({
                                    site: x.site,
                                    date: getTodayAndTomorrow().tomorrow,
                                    site_ID: x.site_ID,
                                    serviceTruck: undefined,
                                    service_Truck_ID: x.service_Truck_ID,
                                    site_Status: x.site_Status,
                                    service_Required: x.service_Required
                                });
                            }
                        });

                const newList: IAssignmentSite[] = [];
                driverAssignments.forEach((x) => {
                    if (x.service_Truck_ID !== null) {
                        const assign = assignmentList.find((q) => q.sub_Site_ID === x.site_ID && new Date(q.date).toLocaleDateString('en-US') === new Date(x.date).toLocaleDateString('en-US'));
                        newList.push({
                            ...x,
                            serviceTruck: assign,
                            assigned: assign ? true : false
                        });
                    } else {
                        newList.push({
                            ...x,
                            assigned: false
                        });
                    }
                });

                this.setState({
                    extraSites,
                    pullAlert: false,
                    driverAssignments: newList.sort((a:any,b:any) => a.site.toLowerCase() > b.site.toLowerCase() ? 1 : -1),
                    lastPull: new Date().toISOString(),
                });
            };
        } else {
            this.props.history.push({
                pathname: ROUTE.UNAUTHORIZED,
                search: getTokenFromUrl(false)
            });
        }
    };

    private sortSites = (a: ISiteList, b: ISiteList): number => {
        if (a.working_Site && !b.working_Site) {
            return -1;
        } else if (!a.working_Site && b.working_Site) {
            return 1;
        }
        return 0;
    };

    private onInitialLoad = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (pageAccessCheck(this.props.getToken, "dispatch") !== NotApplicable) {
            if (hasPayload(this.props.getToken)) {
                this.props.getDriverAssignmentsRequest({
                    token: this.props.getToken.payload.response.token
                });
            }
        }

        if (hasPayload(this.props.getToken))
            this.getLook();
    };

    private getLook = (): void => {
        if (hasPayload(this.props.getToken))
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Service_Trucks"
                }
            });
    };

    private handleSave = (): void => {

        if (hasPayload(this.props.getToken)) {
            const request: ISaveUpdateDriversAssignment[] = [] 
            this.state.driverAssignments
                .filter((x) => x.serviceTruck !== undefined)
                .map((x) => {
                    const idxCheck = request.findIndex((q) => (q.Date === (x.serviceTruck ? new Date(x.serviceTruck.date).toISOString() : "")) 
                    && (q.Sub_Site_ID === x.site_ID));
                    
                    if(idxCheck < 0){
                        request.push({
                            Sub_Site_ID: x.site_ID,
                            Notes: x.serviceTruck ? x.serviceTruck.notes : "",
                            Date: x.serviceTruck ? new Date(x.serviceTruck.date).toISOString() : "", 
                            Priority: x.serviceTruck ? x.serviceTruck.service_Trucks.map(({ priority }) => priority) : [],
                            Service_Truck_ID: x.serviceTruck ? x.serviceTruck.service_Trucks.map(({ service_Truck_ID }) => service_Truck_ID) : [],
                        })
                    }
                });

            this.props.saveDriverAssignmentRequest({
                request,
                upn: this.props.getToken.payload.response.upn,
                token: this.props.getToken.payload.response.token,
            });

            this.setState({
                hasChange: false
            });
        }
    };

}

const mapStateToProps = (state: IStore): IDriversAssignmentStoreProps => ({
    getToken: getToken(state),
    getLookup: getFieldLookups(state),
    getServiceTruckStatus: getServiceTrucks(state),
    getDriverAssignmentStatus: getDriversAssignment(state),
    saveDriverAssignmentStatus: saveDriverAssignment(state),
    communicationLogs: getServiceTruckCommunicationLogs(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IDriversAssignmentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    getServiceTrucksRequest: (data: IGetServiceTruckRequest) => dispatch(getServiceTrucksLoadAction(data)),
    getDriverAssignmentsRequest: (data: IGetDriversAssignmentRequest) => dispatch(getDriversAssignmentLoadAction(data)),
    saveDriverAssignmentRequest: (data: ISaveDriversAssignmentRequest) => dispatch(saveDriversAssignmentLoadAction(data)),
    getDriverCommLogsRequest: (data: IGetServiceTruckCommunicationLogsRequest) => dispatch(getServiceTruckCommunicationLogsLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriversAssignment);