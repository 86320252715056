
export enum IGET_DEPARTMENTS_REQUEST {
    REQUEST = "getDepartments/GET_DEPARTMENTS_REQUEST",
    SUCCESS = "getDepartments/GET_DEPARTMENTS_SUCCESS",
    FAILED = "getDepartments/GET_DEPARTMENTS_FAILED"
};

export interface IGetDepartmentsRequest {
    token: string;
};

export interface IGetDepartments {
    id: number;
    name: string;
    active: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

