
export enum IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST {
    REQUEST = "getServiceTruckCommunicationLogs/GET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST",
    SUCCESS = "getServiceTruckCommunicationLogs/GET_SERVICE_TRUCK_COMMUNICATION_LOGS_SUCCESS",
    FAILED = "getServiceTruckCommunicationLogs/GET_SERVICE_TRUCK_COMMUNICATION_LOGS_FAILED"
};

export interface IGetServiceTruckCommunicationLogsRequest {
    request: {
        service_truck_id: number;
    };
    token: string;
};

export interface IGetServiceTruckCommunicationLogs {
    id: number;
    notes?: string;
    status: string;
    created: string;
    service_Truck_ID: number;
    service_Truck: string;
    driver: string;
    swamper: string;
    site: string;
    date: string;
};