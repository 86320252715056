import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST {
    REQUEST = "acknowledgeLogByPayroll/ACKNOWLEDGE_LOG_BY_PAYROLL_REQUEST",
    SUCCESS = "acknowledgeLogByPayroll/ACKNOWLEDGE_LOG_BY_PAYROLL_SUCCESS",
    FAILED = "acknowledgeLogByPayroll/ACKNOWLEDGE_LOG_BY_PAYROLL_FAILED"
};

export interface IAcknowledgeLogByPayrollRequest extends ISurewayTokenRequestBody {
    request: IAcknowledgeLogByPayroll;
};

export interface IAcknowledgeLogByPayroll {
    ID: number;
    Defect_ID: number;
    Payroll_Acknowledged: string;
    Modified_By: string;
};