import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_ST_LOGS_REQUEST, ISTLogs, IGetSTLogsRequest } from "./getSTLogsConstants";

export interface IGetSTLogsLoadAction {
    type: IGET_ST_LOGS_REQUEST.REQUEST;
    data: IGetSTLogsRequest
}
export const getSTLogsLoadAction = (data: IGetSTLogsRequest): IGetSTLogsLoadAction => ({
    type: IGET_ST_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetSTLogsSuccessAction {
    type: IGET_ST_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISTLogs[]>;
}
export const getSTLogsLoadSuccessAction = (list: SurewayAPIResponse<ISTLogs[]>): IGetSTLogsSuccessAction => ({
    type: IGET_ST_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetSTLogsLoadFailedAction {
    type: IGET_ST_LOGS_REQUEST.FAILED;
    message: string;
}
export const getSTLogsLoadFailedAction = (message: string): IGetSTLogsLoadFailedAction => ({
    type: IGET_ST_LOGS_REQUEST.FAILED,
    message
});
