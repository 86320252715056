import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
// import { IGetWSSLogInfoByID } from "../getWorkingSiteLogs/getWorkingSiteLogsConstants";
import { IGET_WSS_LOG_INFO_BY_ID_REQUEST, IGetWSSLogInfoByID, IGetWSSLogInfoByIDRequest } from "./getWSSLogInfoByIDConstants";

export interface IGetWSSLogInfoByIDLoadAction {
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.REQUEST;
    data: IGetWSSLogInfoByIDRequest
}
export const getWSSLogInfoByIDLoadAction = (data: IGetWSSLogInfoByIDRequest): IGetWSSLogInfoByIDLoadAction => ({
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetWSSLogInfoByIDSuccessAction {
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IGetWSSLogInfoByID>;
}
export const getWSSLogInfoByIDLoadSuccessAction = (list: SurewayAPIResponse<IGetWSSLogInfoByID>): IGetWSSLogInfoByIDSuccessAction => ({
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetWSSLogInfoByIDLoadFailedAction {
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getWSSLogInfoByIDLoadFailedAction = (message: string): IGetWSSLogInfoByIDLoadFailedAction => ({
    type: IGET_WSS_LOG_INFO_BY_ID_REQUEST.FAILED,
    message
});
