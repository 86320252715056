import React from "react";
import { TextField, Autocomplete, Chip } from "@mui/material";
// import TextField from "@material-ui/core/TextField";

interface ILAFreeTextAutoCompleteProps {
    value: string[];
    onChange: (newValue: string[]) => void;
    label?: string;
    placeholder?: string; 
    options?: string[]; 
    disabled?: boolean; 
    size?: "small" | "medium";
    errorText?: string;
    name?: string;
    id?: id;
    inputRef?: (a: any) => any;
    autoHighlight: boolean;
    style?: React.CSSProperties;
    filterSelectedOptions?: boolean;
    singleSelection?: boolean;
}

type id = "disableOpenOnFocus" | "autoHighlight" | "autoComplete" | "includeInputInList" | "disableClearable" | "clearOnEscape" | "select-on-focus";
const DEFAULT_ID = "select-on-focus";

const LAFreeTextAutoComplete: React.FC<ILAFreeTextAutoCompleteProps> = (props: ILAFreeTextAutoCompleteProps) => {
    const helperText: string | undefined = props.errorText;
    const error: true | undefined = helperText ? true : undefined;

    return (
        <Autocomplete
            multiple
            freeSolo
            options={props.options ?? []}
            value={props.value}
            filterSelectedOptions={props.filterSelectedOptions}
            id={props.id || DEFAULT_ID}
            autoHighlight={props.autoHighlight}
            onChange={(event, newValue) => props.onChange(newValue)}
            disabled={props.disabled}
            style={props.style}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        // className="mb-2 ml-2 mr-2"
                        variant="outlined"
                        color="default"
                        label={option}
                        {...getTagProps({ index })}
                        key={index}
                        // disabled={true}
                        // onDelete={undefinedFunction}
                    />
                ))
            }
            renderInput={(params) => {
                const inputProps: any = params.inputProps;
                inputProps.autoComplete = "off";
                return <TextField
                    {...params}
                    inputRef={props.inputRef}
                    inputProps={inputProps}
                    variant="outlined"
                    label={props.label}
                    // color="secondary"
                    multiline={true}
                    size={props.size}
                    fullWidth={true}
                    error={error}
                    name={props.name}
                    helperText={helperText}
                    placeholder={props.placeholder}
                />
            }}
        />
    );
};

export default LAFreeTextAutoComplete;
