import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_EXTERNAL_USERS_REQUEST, IExternalUser, IGetExternalUsersRequest } from "./getExternalUsersConstants";

export interface IGetExternalUsersLoadAction {
    type: IGET_EXTERNAL_USERS_REQUEST.REQUEST;
    data: IGetExternalUsersRequest
}
export const getExternalUsersLoadAction = (data: IGetExternalUsersRequest): IGetExternalUsersLoadAction => ({
    type: IGET_EXTERNAL_USERS_REQUEST.REQUEST,
    data
});
export interface IGetExternalUsersSuccessAction {
    type: IGET_EXTERNAL_USERS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IExternalUser>>;
}
export const getExternalUsersLoadSuccessAction = (list: SurewayAPIResponse<ById<IExternalUser>>): IGetExternalUsersSuccessAction => ({
    type: IGET_EXTERNAL_USERS_REQUEST.SUCCESS,
    list
});
export interface IGetExternalUsersLoadFailedAction {
    type: IGET_EXTERNAL_USERS_REQUEST.FAILED;
    message: string;
}
export const getExternalUsersLoadFailedAction = (message: string): IGetExternalUsersLoadFailedAction => ({
    type: IGET_EXTERNAL_USERS_REQUEST.FAILED,
    message
});
