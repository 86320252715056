export enum ISAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST {
    REQUEST = "saveEmployeeMessageResponse/SAVE_EMPLOYEE_MESSAGE_RESPONSE_REQUEST",
    SUCCESS = "saveEmployeeMessageResponse/SAVE_EMPLOYEE_MESSAGE_RESPONSE_SUCCESS",
    FAILED = "saveEmployeeMessageResponse/SAVE_EMPLOYEE_MESSAGE_RESPONSE_FAILED"
};

export interface IEmployeeMessageResponseRequest {
    ID: number;
    Comments: string;
    Employee_ID: number;
    Selected_Option: number | null;
};