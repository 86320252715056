import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_REPAIR_SMS_LOGS_REQUEST, IRepairLineSmsLog, IGetRepairLineSmsLogsRequest } from "./getRepairLineSmsLogsConstants";

export interface IGetRepairSmsLogsLoadAction {
    type: IGET_REPAIR_SMS_LOGS_REQUEST.REQUEST;
    data: IGetRepairLineSmsLogsRequest
}
export const getRepairSmsLogsLoadAction = (data: IGetRepairLineSmsLogsRequest): IGetRepairSmsLogsLoadAction => ({
    type: IGET_REPAIR_SMS_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetRepairSmsLogsSuccessAction {
    type: IGET_REPAIR_SMS_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IRepairLineSmsLog>>;
}
export const getRepairSmsLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IRepairLineSmsLog>>): IGetRepairSmsLogsSuccessAction => ({
    type: IGET_REPAIR_SMS_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetRepairSmsLogsLoadFailedAction {
    type: IGET_REPAIR_SMS_LOGS_REQUEST.FAILED;
    message: string;
}
export const getRepairSmsLogsLoadFailedAction = (message: string): IGetRepairSmsLogsLoadFailedAction => ({
    type: IGET_REPAIR_SMS_LOGS_REQUEST.FAILED,
    message
});
