import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IRepairLineItem } from "../getRepairLines/getRepairLinesConstants";
import { IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST, IGetRepairLineItemByIdRequest } from "./getRepairLineItemByIdConstants";

export interface IGetRepairLineItemByIdLoadAction {
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.REQUEST;
    data: IGetRepairLineItemByIdRequest
}
export const getRepairLineItemByIdLoadAction = (data: IGetRepairLineItemByIdRequest): IGetRepairLineItemByIdLoadAction => ({
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetRepairLineItemByIdSuccessAction {
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IRepairLineItem>;
}
export const getRepairLineItemByIdLoadSuccessAction = (list: SurewayAPIResponse<IRepairLineItem>): IGetRepairLineItemByIdSuccessAction => ({
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetRepairLineItemByIdLoadFailedAction {
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getRepairLineItemByIdLoadFailedAction = (message: string): IGetRepairLineItemByIdLoadFailedAction => ({
    type: IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.FAILED,
    message
});
