import React from 'react';
import {TextField, InputLabel, FormControl, Typography, FormHelperText, Radio, RadioGroup, Paper, Autocomplete} from '@mui/material';
import DataGrid, {
    Column, Paging, Pager, Button, FilterRow, FilterPanel, HeaderFilter, Lookup, SearchPanel,
  } from 'devextreme-react/data-grid';
  import 'devextreme/dist/css/dx.light.css';
  import { Grid, Button as MUIButton} from '@mui/material';
  import 'devextreme-react/text-area';
  import {Modal} from '@mui/material';
  import { Backdrop } from '@mui/material';
  import { Box } from '@mui/material';
  import { Fade } from '@mui/material';
  import FormLabel from '@mui/material/FormLabel';
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import FormGroup from '@mui/material/FormGroup';
  import FormControlLabel from '@mui/material/FormControlLabel';
  import Checkbox from '@mui/material/Checkbox';
  import AddScheduledService, {getServiceTypesBySOCTID} from '../../../api/scheduledservice/addScheduledService';
  import { OutlinedRequiredStyle, OutlinedRequiredStyleSelected, SOCT_URL } from '../../../../../shared/styles';
  import CustomServiceTable from '../../scheduledservice/scheduledServiceCustomTable';
  import splitDate from '../../../../../shared/splitDate';
import { currentUser, hasUserAccess } from '../../../socthome';
import { getSOCTWorkingSites } from '../../../api/workingsites/workingSitesAPI';
import {exportWorkingSites} from './editSOCTMain';
import { getSOCTWorkingSubSitesByParentID } from '../../../api/workingsites/workingSitesAPI';
import { LIGHT_ORANGE_COLOR } from '../../../../../shared/theme';
import LALinkButton from '../../../../../shared/linkButton';
import { ROUTE } from '../../../../../routes';
import { callRouteWithQueryString } from '../../../../../shared/constExports';

  const renderTitleHeader = (data:any) => {
    return <p style={{ font: 'Cookie'}}>{data.column.caption}</p>
}
const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    backgroundColor: 'rgb(241, 241, 241) !important',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch',
    overflow: 'auto',
    maxHeight:'100%',
};

interface IProps {
    history:any;
    data:any[];
    editData:any;
}
interface IState {
    scheduled_service_list:any;
    mostRecentService:any;
    showAddNewForm:boolean;
    modalOpen:boolean;
    serviceTypes:string[];
    view:string;
    searchValue: string;
}

var serviceTypeCheck:any = {service_0_check:false,service_100_check:false,service_250_check:false,service_500_check:false,service_1500_check:false,service_3000_check:false,
    service_A_check:false, service_B_check:false, service_D_check:false, service_annual_check:false, service_1000_check:false, service_3Months_check:false, service_5000K_check:false};
var newScheduledService:any = {serviceDate:'',meterReading:'',serviceType:'',service_0:false,service_100:false,service_250:false,service_500:false,service_1500:false,service_3000:false,
service_A:false, service_B:false, service_D:false, service_Annual:false, service_3Months: false, service_5000K: false, updateCurrentHours:false,comments:'',customService:'', currentHoursCheck:false, ecm:'', unit_Number:'', previous_Date:'', previous_Reading:'', attachments:[],
updateLocationCheck:false, new_Location:'', sub_Location:'', sub_Location_ID:0, location:'', current_Sub_Location:'',current_Sub_Location_ID:0, service_1000:false, SOCT_ID:0};
var editScheduledServiceData:any;
var customServiceList:any[] = [{hour_Interval:null, notes:'', completed_Date:''}];
var serviceTypes:any[];
var copy_list:any[];
var working_site_locations:any[] = [];
var sub_site_locations:any[] = [];
var sub_location_list:any[] = [];

export default class EditServiceHistoryTable extends React.Component<IProps,IState>{
    constructor(props:any){
        super(props);
        this.state = {scheduled_service_list:[], mostRecentService:[], showAddNewForm:false,modalOpen:true, serviceTypes:[], view:'scheduled', searchValue:''};
        this.onAddNewCancel = this.onAddNewCancel.bind(this);
        this.onAddNew = this.onAddNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onAddNewSave = this.onAddNewSave.bind(this);
        this.renderSubSiteTable = this.renderSubSiteTable.bind(this);
        this.filterView = this.filterView.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
    }
    handleFileRemove(index:number,event:any){
        newScheduledService.attachments.splice(index,1);
        this.setState({});
    }
    handleFileUpload(event:any){
        newScheduledService.attachments.push(event.target.files[0].name);
        this.setState({});
    }
    // Add string seperated service types to service type string
    updateServiceType(){
        newScheduledService.serviceType = '';
        if(newScheduledService.service_0){
            newScheduledService.serviceType ='0';
        }
        if(newScheduledService.service_100){
            newScheduledService.serviceType =newScheduledService.serviceType+',100';
        }
        if(newScheduledService.service_250){
            newScheduledService.serviceType =newScheduledService.serviceType+',250';
        }
        if(newScheduledService.service_500){
            newScheduledService.serviceType =newScheduledService.serviceType+',500';
        }
        if(newScheduledService.service_1000){
            newScheduledService.serviceType =newScheduledService.serviceType+',1000';
        }
        if(newScheduledService.service_1500){
            newScheduledService.serviceType =newScheduledService.serviceType+',1500';
        }
        if(newScheduledService.service_3000){
            newScheduledService.serviceType =newScheduledService.serviceType+',3000';
        }
        if(newScheduledService.service_3Months){
            newScheduledService.serviceType =newScheduledService.serviceType+',3 Months';
        }
        if(newScheduledService.service_5000K){
            newScheduledService.serviceType =newScheduledService.serviceType+',5000K';
        }
        // if(newScheduledService.service_3Month_5000k){
        //     newScheduledService.serviceType =newScheduledService.serviceType+',3 Month/5000K';
        // }
        if(newScheduledService.service_A){
            newScheduledService.serviceType =newScheduledService.serviceType+',Schedule A';
        }
        if(newScheduledService.service_B){
            newScheduledService.serviceType =newScheduledService.serviceType+',Schedule B';
        }
        if(newScheduledService.service_D){
            newScheduledService.serviceType =newScheduledService.serviceType+',Schedule D';
        }
        if(newScheduledService.service_Annual){
            newScheduledService.serviceType =newScheduledService.serviceType+',Annual Service';
        }
        // Remove first "," if exists
        if(newScheduledService.serviceType[0] === ','){
            newScheduledService.serviceType = newScheduledService.serviceType.substring(1); 
        }
    }
    handleChange(event:any){
        const fieldName:string = event.target.name;
        switch(fieldName){
            case 'service_date':
                newScheduledService.serviceDate = event.target.value;
                break;
            case 'custom_service_notes':
                newScheduledService.customServiceNotes = event.target.value;
                break;
            case 'meter_reading':
                newScheduledService.meterReading = event.target.value;
                break;
            case 'service_type_0':
                newScheduledService.service_0 = !newScheduledService.service_0;
                newScheduledService.service_100 = false;
                newScheduledService.service_250 = false;
                newScheduledService.service_500 = false;
                newScheduledService.service_1000 = false;
                newScheduledService.service_1500 = false;
                newScheduledService.service_3000 = false;
                newScheduledService.service_A = false;
                newScheduledService.service_B = false;
                newScheduledService.service_D = false;
                newScheduledService.service_Annual = false;
                newScheduledService.service_3Months = false;
                newScheduledService.service_5000K = false;
                // newScheduledService.service_3Month_5000k = false;
                if(newScheduledService.service_0 === true){
                    newScheduledService.customService = "Yes";
                }
                else{
                    newScheduledService.customService = "No";
                }
                break;
            case 'service_type_100':
                newScheduledService.service_100 = !newScheduledService.service_100;
                newScheduledService.customService = "No";
                break;
            case 'service_type_250':
                newScheduledService.service_250 = !newScheduledService.service_250;
                newScheduledService.customService = "No";
                break;
            case 'service_type_500':
                newScheduledService.service_500 = !newScheduledService.service_500;
                newScheduledService.customService = "No";
                break;
            case 'service_type_1000':
                newScheduledService.service_1000 = !newScheduledService.service_1000;
                newScheduledService.customService = "No";
                break;
            case 'service_type_1500':
                newScheduledService.service_1500 = !newScheduledService.service_1500;
                newScheduledService.customService = "No";
                break;
            case 'service_type_3000':
                newScheduledService.service_3000 =!newScheduledService.service_3000;
                newScheduledService.customService = "No";
                break;
            case 'service_type_A':
                newScheduledService.service_A = !newScheduledService.service_A;
                newScheduledService.customService = "No";
                break;
            case 'service_type_B':
                newScheduledService.service_B = !newScheduledService.service_B;
                newScheduledService.customService = "No";
                break;
            case 'service_type_D':
                newScheduledService.service_D = !newScheduledService.service_D;
                newScheduledService.customService = "No";
                break;
            case 'service_type_annual':
                newScheduledService.service_Annual = !newScheduledService.service_Annual;
                newScheduledService.customService = "No";
                break;
            case 'service_type_3months':
                newScheduledService.service_3Months = !newScheduledService.service_3Months;
                newScheduledService.customService = "No";
                break;
            case 'service_type_5000K':
                newScheduledService.service_5000K = !newScheduledService.service_5000K;
                newScheduledService.customService = "No";
                break;
            // case 'service_type_3month_5000k':
            //     newScheduledService.service_3Month_5000k = !newScheduledService.service_3Month_5000k;
            //     newScheduledService.customService = "No";
            //     break;
            case 'current_hours_check':
                newScheduledService.currentHoursCheck= !newScheduledService.currentHoursCheck;
                break;
            case 'custom_service_check':
                newScheduledService.customService = event.target.value;
                break;
            case 'comments':
                newScheduledService.comments= event.target.value;
                break;
            case 'ecm':
                newScheduledService.ecm= event.target.value;
                break;
            case 'update_location_check':
                newScheduledService.updateLocationCheck = !newScheduledService.updateLocationCheck;
                break;
        }
        this.setState({});
    }
    async onAddNewSave(){
       // if((newScheduledService.currentHoursCheck && (Number(newScheduledService.meterReading) > soct_current_hours)) || !newScheduledService.currentHoursCheck){
            this.updateServiceType();
            if(newScheduledService.serviceType != '' && newScheduledService.serviceDate!="" && ((newScheduledService.updateLocationCheck === true && newScheduledService.new_Location!='' && newScheduledService.sub_Location!='') || newScheduledService.updateLocationCheck===false)
            &&((newScheduledService.ecm!='') || (newScheduledService.meterReading!='')))
            {
            var removedNullCustomServiceList =[];
            for(var index=0;index<customServiceList.length; index++){
                if(customServiceList[index].hour_Interval != null){
                    removedNullCustomServiceList.push(customServiceList[index]);
                }
            }
            var res:any = await AddScheduledService(newScheduledService, removedNullCustomServiceList, currentUser);
            if(res.status === 200){
                alert("Successfully added new scheduled service");
                newScheduledService = {serviceDate:'',meterReading:'',serviceType:'',service_0:false,service_100:false,service_250:false,service_500:false,service_1500:false,service_3000:false,
                 service_A:false, service_B:false, service_D:false, service_Annual:false,service_3Months:false,service_5000K:false, updateCurrentHours:false,comments:'',customService:'', currentHoursCheck:false, ecm:'', unit_Number:'', previous_Date:'', previous_Reading:'', attachments:[], 
                 updateLocationCheck:false,new_Location:'',sub_Location:'',sub_Location_ID:0,location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
                 current_Sub_Location_ID:this.props.editData.sub_Location_ID, service_1000:false};
                this.setState({showAddNewForm:false});
                this.refreshGrid();

                this.props.history.push('/field/soct/lists/edit/id='+this.props.editData.SOCT_ID);
            }
            else{
                alert("Failed to add new scheduled service");
            }
        }
            else{
                alert("Service type and service date must be entered, ecm or meter reading is mandatory, and location must be entered if update location is checked");
            }
        // }
        // else{
        //     alert("New meter reading cannot be less than what the machine currently has!");
        // }
    }

    onPMSheetClick(data: any) {
        window.open(ROUTE.FIELD.PM_SHEETS.SERVICE_SHEET_REPORT + "?id=" + data.pM_Sheet_ID.toString());
    }

    onAddNewCancel(){
        newScheduledService = {serviceDate:'',meterReading:'',serviceType:'',service_0:false,service_100:false,service_250:false,service_500:false,service_1500:false,service_3000:false,
service_A:false, service_B:false, service_D:false, service_Annual:false, service_3Months:false, service_5000K:false, updateCurrentHours:false,comments:'',customService:'', currentHoursCheck:false, ecm:'', unit_Number:'', previous_Date:'', previous_Reading:'', attachments:[],
updateLocationCheck:false,new_Location:'',sub_Location:'',sub_Location_ID:0,location:this.props.editData.location, current_Sub_Location:this.props.editData.sub_Location, 
current_Sub_Location_ID:this.props.editData.sub_Location_ID, service_1000:false};
        this.setState({showAddNewForm:false});
        this.refreshGrid();
        
    }
    onAddNew(){
        this.setState({showAddNewForm:true}); 
    }
    editItem(id:number, data:any){

        editScheduledServiceData = data;
        editScheduledServiceData.SOCT_ID = this.props.editData.SOCT_ID;
        editScheduledServiceData.equipment_Type = this.props.editData.equipment_Type;
        editScheduledServiceData.current_Location = this.props.editData.location;
        editScheduledServiceData.current_Sub_Location_ID = this.props.editData.sub_Location_ID;
        editScheduledServiceData.current_Sub_Location = this.props.editData.sub_Location;
        if(editScheduledServiceData.attachments != null){
             if(editScheduledServiceData.attachments.length > 1){
                editScheduledServiceData.attachments = editScheduledServiceData.attachments.split(";");
            }
        }
        this.props.history.push('/field/soct/scheduledservice/edit/id='+id);
      }
      renderSubSiteTable(){
        this.setState({});
    }
    filterView(eventKey:any){
            switch(eventKey.target.value) {
             case 'scheduled':
                 this.setState({view:'scheduled'});
                 var filtered_list = copy_list.filter((elem:any) => { return (elem.custom_Service != "Yes")});
                 this.setState({scheduled_service_list:filtered_list});
                 break;
            case 'custom':
                this.setState({view:'custom'});
                var filtered_list = copy_list.filter((elem:any) => { return (elem.custom_Service === "Yes")});
                this.setState({scheduled_service_list:filtered_list});
                break;
            case 'all':
                this.setState({view:'all'});
                var filtered_list = copy_list.filter((elem:any) => { return (elem.custom_Service === "Yes" || elem.custom_Service !="Yes" || elem.custom_Service===null)});
                this.setState({scheduled_service_list:filtered_list});
                break;
            }
    }
    async refreshGrid(){
        working_site_locations = [];
        if(!exportWorkingSites){
            var tempsites:any = await getSOCTWorkingSites(true);
            tempsites.map((location:any) => {
                working_site_locations.push(location.site_Name);
                });
                working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
       }
       else{
        exportWorkingSites.map((location:any) => {
            working_site_locations.push(location.site_Name);
            });
            working_site_locations.sort((a:any,b:any) => (a > b) ? 1:-1);
       }
        newScheduledService.unit_Number = this.props.editData.unit_Number;
        newScheduledService.location = this.props.editData.location;
        newScheduledService.current_Sub_Location = this.props.editData.sub_Location;
        newScheduledService.current_Sub_Location_ID = this.props.editData.sub_Location_ID;
        newScheduledService.SOCT_ID = this.props.editData.SOCT_ID;
        //soct_current_hours = await GetSOCTCurrentHoursByID(this.props.editData.id);
        let scheduled_services:any = [];
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type':'application/json' }, 
          body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request:{SOCT_ID:this.props.editData.SOCT_ID}})
      }
        await fetch(SOCT_URL+'/api/SOCT/GetSOCTScheduledService', requestOptions)
        .then(async response => {
            const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
           
              // error response
              if(!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            // no error
            data.map((history:any) => {
                scheduled_services.push(history);
            });
            scheduled_services.sort(function(a:any,b:any){return new Date(b.service_Date).getTime() - new Date(a.service_Date).getTime()});
            var mostRecentService = scheduled_services[0];
           
            this.setState({scheduled_service_list:scheduled_services, mostRecentService:mostRecentService});
            copy_list = scheduled_services;
            if(scheduled_services.length > 0){
                newScheduledService.previous_Date = mostRecentService.service_Date;
                newScheduledService.previous_Reading = mostRecentService.meter_Reading;
            }
            
          
            var service_types:any = await getServiceTypesBySOCTID(this.props.editData.SOCT_ID);
            serviceTypes = service_types[0].split(",");
            serviceTypes.map((each:any) => {
                if(each === '0'){
                    serviceTypeCheck.service_0_check = true;
                }
                if(each === '100'){
                    serviceTypeCheck.service_100_check = true;
                }
                if(each === '250'){
                    serviceTypeCheck.service_250_check = true;
                }
                if(each === '500'){
                    serviceTypeCheck.service_500_check = true;
                }
                if(each === '1000'){
                    serviceTypeCheck.service_1000_check = true;
                }
                if(each === '1500'){
                    serviceTypeCheck.service_1500_check = true;
                }
                if(each === '3000'){
                    serviceTypeCheck.service_3000_check = true;
                }
                if(each === '3 Months'){
                    serviceTypeCheck.service_3Months_check = true;
                }
                if(each === '5000K'){
                    serviceTypeCheck.service_5000K_check = true;
                }
                // if(each === '3 Month/5000K'){
                //     serviceTypeCheck.service_3Month_5000k_check = true;
                // }
                if(each === 'Schedule A'){
                    serviceTypeCheck.service_A_check = true;
                }
                if(each === 'Schedule B'){
                    serviceTypeCheck.service_B_check = true;
                }
                if(each === 'Schedule D'){
                    serviceTypeCheck.service_D_check = true;
                }
                if(each === 'Annual Service'){
                    serviceTypeCheck.service_annual_check = true;
                }
            });
        });
        this.setState({});
    }
    async componentDidMount(){
     this.refreshGrid();
    }
    handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        this.setState({searchValue:trimmedValue });
      };
    render(){
        return (
            <>
            <Grid container spacing={6}>
                            <Grid item xs={12}><MUIButton disabled={hasUserAccess} style={{backgroundColor:'#bf0000', marginLeft:'0%'}} onClick={this.onAddNew} variant="contained" startIcon={<AddCircleIcon />}>Add new</MUIButton>
                             <h1>Service History</h1>
                             <Grid item xs={3} >
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={this.filterView}
                                        defaultValue={"all"}
                                    >
                                        <FormControlLabel value="scheduled" control={<Radio size="small"/>} label="Scheduled Services" />
                                        <FormControlLabel value="custom" control={<Radio size="small"/>} label="Custom Services" />
                                        <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
                                        </RadioGroup>
                                </FormControl>
                             </Grid>
                             {/* <Grid item xs={3}>
                             <MUIButton style={{width:'200px',backgroundColor:'#bf0000', marginLeft:'340%'}} onClick={this.onAddNew} variant="contained">Add new</MUIButton>
                             </Grid> */}
            <DataGrid focusedRowEnabled={true}
              hoverStateEnabled={true}
              rowAlternationEnabled={true}
                dataSource={this.state.scheduled_service_list}
                keyExpr="id"
                showBorders={true}
                wordWrapEnabled={true}
                onRowPrepared={(e: any) => {
                    let data: any = e.data;
                    if(data){
                      if((data.custom_Service === "Yes") && ((data.completed_Date === undefined) || (data.completed_Date === null) || (data.completed_Date === ""))){
                          e.columns.map((item: any, index: number) => {
                              if(e.rowElement.cells[index]){
                                  e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_COLOR;
                              }
                          })
                      }
                    }
                  }}
                >
                    <SearchPanel visible={true}
                        width={340}
                        placeholder="Search for..." 
                        // text={this.state.searchValue}
                        // onTextChange={this.handleValueChange}
                    />    
                    <FilterRow visible={true}></FilterRow>
                    <FilterPanel visible={true} />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Paging enabled={true} defaultPageSize={20}/>
                    <Pager
                        visible={true}
                        allowedPageSizes={[20,40,60]}
                        displayMode={'full'}
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true} />
                    <Column type="buttons" caption="Edit" width={100} headerCellRender={renderTitleHeader}>
                        <Button text="View/Update" cssClass="dataGrid-edit-btn" onClick={(e:any) => {this.editItem(e.row.data.id, e.row.data)}}></Button>
                    </Column>
                    
                    {/* <Column dataType="string" dataField="unit_Number" caption="Unit Number" alignment="left" width={100}  headerCellRender={renderTitleHeader}/> */}
                    <Column sortOrder="desc" dataType="date" dataField="service_Date" caption="Service Date" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="service_Type" caption="Service_Type" alignment="left" width={300} headerCellRender={renderTitleHeader}/>
                    <Column dataType="number" dataField="meter_Reading" caption="Meter_Read" alignment="left" width={120} headerCellRender={renderTitleHeader}/>
                    <Column dataType="int" dataField="ecm" caption="ECM" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="admin_Comments" caption="Admin Comments" alignment="left" width={80} headerCellRender={renderTitleHeader}/>
                    <Column caption="PM Sheet" type="buttons" cellRender={(val) => {

if ((val.data.pM_Sheet_ID !== undefined) && (val.data.pM_Sheet_ID !== null)) {
    return <LALinkButton className="linkUrl" label="PM Sheet" onClick={() => this.onPMSheetClick(val.data)} />
} else {
    return <></>
}
}} allowReordering={true} headerCellRender={renderTitleHeader} />

                    <Column dataType="string" dataField="comments" caption="Comments" alignment="left" headerCellRender={renderTitleHeader}/>
                    {/* <Column dataType="string" dataField="service_Location" caption="Service Location" alignment="left"  headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="service_Sub_Location" caption="Service Sub Location" alignment="left" headerCellRender={renderTitleHeader}/> */}
                    <Column dataType="string" dataField="custom_Service" caption="Custom Service?" alignment="left" width={100} headerCellRender={renderTitleHeader}>
                        <Lookup dataSource={["Yes","No"]}></Lookup>
                    </Column>
                    <Column dataType="date" dataField="completed_Date" caption="Completed Date" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="string" dataField="cancelled" caption="Cancelled" alignment="left" headerCellRender={renderTitleHeader}/>
                    <Column dataType="datetime" dataField="modified" caption="Modified" alignment="left" headerCellRender={renderTitleHeader}/>
            </DataGrid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
                        </Grid>
                        {this.state.showAddNewForm?
                        <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.modalOpen}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        >  
                        <Fade in={this.state.modalOpen}>
                        <Box sx={BoxStyle}>
                            <Grid container spacing={3}>
                          <Grid item xs={4}>
                            <TextField label="Unit Number" defaultValue={this.props.editData.unit_Number} disabled></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Previous Date" defaultValue={newScheduledService.previous_Date ? splitDate(newScheduledService.previous_Date):"N/A"} disabled></TextField>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label="Previous Reading" defaultValue={newScheduledService.previous_Reading ? newScheduledService.previous_Reading:"N/A"} disabled></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel >Service Date</InputLabel>
                            <TextField style={{backgroundColor:'white'}} type='date' onChange={this.handleChange} name="service_date"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel >Meter Reading</InputLabel>
                            <TextField style={{backgroundColor:'white'}} onChange={this.handleChange} name="meter_reading"></TextField>
                        </Grid>
                        <Grid item xs={3}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormLabel component="legend">Service Type</FormLabel>
                                {/* Map each service type and only display the services that are available */}
                                    <FormGroup row>
                            {serviceTypeCheck.service_100_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_100} onChange={this.handleChange} name="service_type_100" />
                                }
                                label="100"
                            />:null}
                            {serviceTypeCheck.service_250_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_250} onChange={this.handleChange} name="service_type_250" />
                                }
                                label="250"
                            />:null}
                            {serviceTypeCheck.service_500_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_500} onChange={this.handleChange} name="service_type_500" />
                                }
                                label="500"
                            />:null}
                               {serviceTypeCheck.service_1000_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_1000} onChange={this.handleChange} name="service_type_1000" />
                                }
                                label="1000"
                            />:null}
                            {serviceTypeCheck.service_1500_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_1500} onChange={this.handleChange} name="service_type_1500" />
                                }
                                label="1500"
                            />:null}
                         
                            {serviceTypeCheck.service_3000_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_3000} onChange={this.handleChange} name="service_type_3000" />
                                }
                                label="3000"
                            />:null}
                             {serviceTypeCheck.service_3Months_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_3Months_check} onChange={this.handleChange} name="service_type_3months" />
                                }
                                label="3 Months"
                            />:null}
                             {serviceTypeCheck.service_5000K_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_5000K_check} onChange={this.handleChange} name="service_type_5000K" />
                                }
                                label="5000K"
                            />:null}
                             {/* {serviceTypeCheck.service_3Month_5000k_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_3Month_5000k_check} onChange={this.handleChange} name="service_type_3month_5000k" />
                                }
                                label="3 Month/5000K"
                            />:null} */}
                            {serviceTypeCheck.service_A_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_A} onChange={this.handleChange} name="service_type_A" />
                                }
                                label="Schedule A"
                            />:null}
                            {serviceTypeCheck.service_B_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_B} onChange={this.handleChange} name="service_type_B" />
                                }
                                label="Schedule B"
                            />:null}
                            {serviceTypeCheck.service_D_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_D} onChange={this.handleChange} name="service_type_D" />
                                }
                                label="Schedule D"
                            />:null}
                            {serviceTypeCheck.service_annual_check ?
                            <FormControlLabel
                                control={
                                <Checkbox disabled={ newScheduledService.service_0} checked={newScheduledService.service_Annual} onChange={this.handleChange} name="service_type_annual" />
                                }
                                label="Annual Service"
                            />:null}
                                     {serviceTypeCheck.service_0_check ?
                            <FormControlLabel
                                control={
                                <Checkbox checked={newScheduledService.service_0} onChange={this.handleChange} name="service_type_0" />
                                }
                                label="0 (Custom Service Set up / non pm service)"
                            />:null}
                            </FormGroup>
                            <FormHelperText>Select 1 or all</FormHelperText>
                        </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Do you want to add Service Location?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={newScheduledService.updateLocationCheck} onChange={this.handleChange} name="update_location_check"/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Do you want to update Current Hours?</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox checked={newScheduledService.currentHoursCheck} onChange={this.handleChange} name="current_hours_check" />
                                        }
                                        label="Yes"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel >Custom Service</InputLabel>
                                <Select label="Custom Service" variant="outlined" onChange={this.handleChange} name="custom_service_check" style={newScheduledService.customService ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                                <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </FormControl>
                        </Grid> */}
                        {newScheduledService.customService === 'Yes' ? 
                        <>
                        <CustomServiceTable data={customServiceList} reRender={this.renderSubSiteTable} disabled={newScheduledService.customService==="No"}></CustomServiceTable>
                        </>
                        :null}
                        <Grid item xs={3}>
                            <TextField disabled label="Current Location" variant="outlined" defaultValue={this.props.editData.location}>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField disabled label="Current Sub Location" variant="outlined" defaultValue={this.props.editData.sub_Location}>
                            </TextField>
                        </Grid>
                        {newScheduledService.updateLocationCheck ?
                        <>
                        <Grid item xs={3}>
                        {/* <InputLabel id="demo-simple-select-label">New Location</InputLabel> */}
                            {/* <Select label="New Location" variant="outlined" onChange={this.handleChange} name="new_location" style={newMeterReading.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}>
                                {working_site_locations}
                            </Select> */}
                            <Autocomplete
                            onChange={async (event:any, newValue:String | null) => {
                                newScheduledService.new_Location = newValue;
                                sub_location_list = [];
                                newScheduledService.sub_Location = '';     
                                newScheduledService.sub_Location_ID = 0;       
                                var parent_id=0;   
                                exportWorkingSites.map((site:any) => {
                                    if(site.site_Name === newValue){
                                        parent_id = site.id;
                                    }
                                });
                                sub_site_locations =  await getSOCTWorkingSubSitesByParentID(parent_id, true);
                                sub_site_locations.map((sub:any) => {
                                    sub_location_list.push(sub.sub_Site_Name);
                                });
                            sub_location_list = sub_location_list.sort((a:any,b:any) => (a > b) ? 1:-1);
                            this.setState({});}}
                            disablePortal 
                            autoHighlight
                            style={newScheduledService.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                            options={working_site_locations} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" name="location"{...params} label="Service Location"></TextField>}></Autocomplete>
                        <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                        </Grid>
                        <Grid item xs={3}>
                                 <Autocomplete
                                onChange={(event:any, newValue:String | null) =>  {
                                    newScheduledService.sub_Location = newValue;
                                    sub_site_locations.map((site:any) => {
                                        if(site.sub_Site_Name === newValue){
                                            newScheduledService.sub_Location_ID = site.id;
                                        }
                                    });
                                    this.setState({})}}
                                disablePortal 
                                autoHighlight
                                options={sub_location_list} 
                                style={newScheduledService.new_Location ? OutlinedRequiredStyleSelected:OutlinedRequiredStyle}
                                value={newScheduledService.sub_Location}
                                renderInput={(params):JSX.Element => <TextField color="secondary" variant="outlined" name="sub_location"{...params} label="Service Sub Location"></TextField>}></Autocomplete>
                                <FormHelperText style={{color:'orange'}}>Required</FormHelperText>
                            </Grid>
                            </>
                            :null}
                      
                        <Grid item xs={6}>
                            <TextField style={{backgroundColor:'white'}} fullWidth label="Comments" rows={4} multiline onChange={this.handleChange} name="comments"></TextField>
                            </Grid>
                        {/* <Grid item xs={12}>
                        <Typography gutterBottom variant="h4" style={{marginLeft:'0%',borderBottom: 'solid 1px black'}}>Additional Info</Typography>
                        </Grid> */}
                        {this.props.editData.equipment_Type === 'Excavators' ? 
                        <Grid item xs={6}>
                            <TextField style={{backgroundColor:'white'}} label="ECM" onChange={this.handleChange} name="ecm"></TextField>
                        </Grid>
                        :null}
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <Paper variant="outlined" style={{padding:'15px', backgroundColor:'#e0bfbc'}}>
                                <Grid item xs={12}>
                                    <Typography style={{borderBottomColor:'black', borderBottomWidth:'thick', borderBottom:'solid', textAlign:'center', fontWeight:'bold'}}>Attachments</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MUIButton fullWidth variant="outlined" component="label">Drag and drop, or click to upload files<input hidden accept="image/*" multiple type="file" onChange={this.handleFileUpload}></input></MUIButton>
                                    {newScheduledService.attachments ? newScheduledService.attachments.map((file:any, index:any) => {
                                        return <Grid item xs={3}>{file}<MUIButton onClick={this.handleFileRemove.bind(this,index)}>X</MUIButton></Grid>
                                    }):null}
                                </Grid>
                            </Paper>
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000', left:'30%'}} onClick={this.onAddNewCancel} variant="contained">Cancel</MUIButton>
                            <MUIButton style={{width:'200px',backgroundColor:'#bf0000',  left:'35%'}} onClick={this.onAddNewSave} variant="contained">Save</MUIButton>
                            </Grid>
                       </Box>
                       </Fade>
                        </Modal>
                        :null}
            </>
        );
    }
}
export {editScheduledServiceData, newScheduledService};