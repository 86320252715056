import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_ATTACHMENT_STYLE_REQUEST } from "./updateStyleConstants";
import { IUpdateAttachmentStyleLoadAction, IUpdateAttachmentStyleLoadFailedAction, IUpdateAttachmentStyleSuccessAction, updateAttachmentStyleLoadFailedAction, updateAttachmentStyleLoadSuccessAction } from "./updateStyleActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const updateStyleEpic: Epic = (
    action$: ActionsObservable<IUpdateAttachmentStyleLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateAttachmentStyleSuccessAction | IUpdateAttachmentStyleLoadFailedAction> =>
    action$.ofType(IUPDATE_ATTACHMENT_STYLE_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.UPDATE_ATTACHMENT_STYLE,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateAttachmentStyleSuccessAction | IUpdateAttachmentStyleLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateAttachmentStyleLoadSuccessAction(response);
                            } else {
                                return updateAttachmentStyleLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateAttachmentStyleLoadFailedAction(response.message)))
                    )
            )
        );