import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_LOCATION_REQUEST } from "./updateLocationConstants";
import { IUpdateLocationLoadAction, IUpdateLocationLoadFailedAction, IUpdateLocationSuccessAction, updateLocationLoadFailedAction, updateLocationLoadSuccessAction } from "./updateLocationActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateLocationEpic: Epic = (
    action$: ActionsObservable<IUpdateLocationLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateLocationSuccessAction | IUpdateLocationLoadFailedAction> =>
    action$.ofType(IUPDATE_LOCATION_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.UPDATE_LOCATION,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateLocationSuccessAction | IUpdateLocationLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateLocationLoadSuccessAction(response);
                            } else {
                                return updateLocationLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateLocationLoadFailedAction(response.message)))
                    )
            )
        );