import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_CREW_REQUEST, IUpdateCrewRequest } from "./updateCrewConstants";


export interface IUpdateCrewLoadAction {
    type: IUPDATE_CREW_REQUEST.REQUEST;
    data: IUpdateCrewRequest
}
export const updateCrewLoadAction = (data: IUpdateCrewRequest): IUpdateCrewLoadAction => ({
    type: IUPDATE_CREW_REQUEST.REQUEST,
    data
});
export interface IUpdateCrewSuccessAction {
    type: IUPDATE_CREW_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateCrewLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateCrewSuccessAction => ({
    type: IUPDATE_CREW_REQUEST.SUCCESS,
    message
});
export interface IUpdateCrewLoadFailedAction {
    type: IUPDATE_CREW_REQUEST.FAILED;
    message: string;
}
export const updateCrewLoadFailedAction = (message: string): IUpdateCrewLoadFailedAction => ({
    type: IUPDATE_CREW_REQUEST.FAILED,
    message
});
