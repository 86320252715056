import { IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST } from "./updateScheduleServiceHistoryConstants";
import { IUpdateScheduleServiceHistoryLoadAction, IUpdateScheduleServiceHistoryLoadFailedAction, IUpdateScheduleServiceHistorySuccessAction } from "./updateScheduleServiceHistoryActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateScheduleServiceHistoryLoadAction
    | IUpdateScheduleServiceHistorySuccessAction
    | IUpdateScheduleServiceHistoryLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateScheduleServiceHistoryReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.REQUEST:
            return loading;

        case IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_SCHEDULE_SERVICE_HISTORY_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};