import { END_POINTS } from '../../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { ISAVE_MECHANIC_ASSIGNMENT_REQUEST } from "./saveMechanicAssignmentConstants";
import { ISaveMechanicAssignmentLoadAction, ISaveMechanicAssignmentLoadFailedAction, ISaveMechanicAssignmentSuccessAction, saveMechanicAssignmentLoadFailedAction, saveMechanicAssignmentLoadSuccessAction } from "./saveMechanicAssignmentActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const saveMechanicAssignmentEpic: Epic = (
    action$: ActionsObservable<ISaveMechanicAssignmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<ISaveMechanicAssignmentSuccessAction | ISaveMechanicAssignmentLoadFailedAction> =>
    action$.ofType(ISAVE_MECHANIC_ASSIGNMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.REPAIR_LINE.SAVE_MECHANICS_ASSIGNMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): ISaveMechanicAssignmentSuccessAction | ISaveMechanicAssignmentLoadFailedAction => {
                            if(response.message === "Success"){
                                return saveMechanicAssignmentLoadSuccessAction(response);
                            } else {
                                return saveMechanicAssignmentLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(saveMechanicAssignmentLoadFailedAction(response.message)))
                    )
            )
        );