import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SERVICE_SECTIONS_REQUEST {
    REQUEST = "getServiceSections/GET_SERVICE_SECTIONS_REQUEST",
    SUCCESS = "getServiceSections/GET_SERVICE_SECTIONS_SUCCESS",
    FAILED = "getServiceSections/GET_SERVICE_SECTIONS_FAILED"
};

export interface IGetServiceSectionRequest extends ISurewayTokenRequestBody {

};

export interface IServiceSection {
    id: number;
    section_Name: string;
    active: string;
    service_Due_ID: number;
    equipment_Type_ID: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    equipment_Type: string;
    service_Due_Name: string;
    display_Order: number;
    company: string;
    service_Items: IServiceItem[];
};

export interface IServiceItem {
    id: number;
    service_Item_Name: string;
    active: string;
    apply_To_Unit_No?: string;
    apply_To_Make?: string;
    apply_To_Model?: string;
    exclude_Unit_No?: string;
    exclude_Model?: string;
    comments?: string;
    service_Section_ID: number;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    picture_Required: string;
    type: string;
    display_Order: number;
    service_Hours: string;
};