import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINES_WO_REQUEST {
    REQUEST = "getRepairLineWO/GET_REPAIR_LINES_WO_REQUEST",
    SUCCESS = "getRepairLineWO/GET_REPAIR_LINES_WO_SUCCESS",
    FAILED = "getRepairLineWO/GET_REPAIR_LINES_WO_FAILED"
};

export interface IGetRepairLineWORequest extends ISurewayTokenRequestBody {

};

export interface IRepairLineWO {
    id: number;
    repair_Line_ID: number;
    unit_No: string;
    requested_By: string;
    assigned_Mech: string;
    status: string;
    modified: string;
    priority: string;
    component: string;
    damaged: boolean;
    damaged_Notes: string;
    work_Order_No: string;
    job_No: string;
    cost_Code: string;
    location_Info: string;
    foreman_Comments: string;
    major_Component_Replacement: string;
    major_Component_Replacement_Notes: string;
};