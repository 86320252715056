
  import React from 'react';
  import {Button, Typography, Modal, Box, Grid, Backdrop} from '@mui/material';
  import {MobileBoxStyle} from '../../../../shared/styles';

  interface IProps {
    closePopup:any;
    confirmPopup:any;
  }
  interface IState {

  }
  const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }

export default class TruckingRequestEmailsDeleteConfirmation extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state = {};
    }
    render(){
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <= 760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.props.closePopup}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Delete this record?</Typography>
                      </Grid>
                     
                      <Grid item xs={12}>
                      <Button style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.props.confirmPopup}>Confirm</Button>
                      </Grid>
                      </Grid></Box></Modal>
        );
    }
}