import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl,  pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IAttachmentType, IGetTypeRequest } from "../../../redux/field/attachment/getTypes/getTypesConstants";
import { IAddAttachmentTypeRequest } from "../../../redux/field/attachment/addType/addTypeConstants";
import { IUpdateAttachmentTypeRequest } from "../../../redux/field/attachment/updateType/updateTypeConstants";
import { getTypes } from "../../../redux/field/attachment/getTypes/getTypesAccessor";
import { addAttachmentTypeStatus } from "../../../redux/field/attachment/addType/addTypeAccessor";
import { updateAttachmentTypeStatus } from "../../../redux/field/attachment/updateType/updateTypeAccessor";
import { addAttachmentTypeLoadAction } from "../../../redux/field/attachment/addType/addTypeActions";
import { getTypesLoadAction } from "../../../redux/field/attachment/getTypes/getTypesActions";
import { updateAttachmentTypeLoadAction } from "../../../redux/field/attachment/updateType/updateTypeActions";

const RequiredFields: string[] = ["name"];

interface IAddUpdateAttachmentTypeComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    serviceTypes: Server<SurewayAPIResponse<ById<IAttachmentType>>>;
    addAttachmentTypeStatus: Server<SurewayAPIResponse<string>>;
    updateAttachmentTypeStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateAttachmentTypeComponentDispatchProps {
    getAttachmentTypesRequest: (data: IGetTypeRequest) => unknown
    addAttachmentTypeRequest: (data: IAddAttachmentTypeRequest) => unknown;
    updateAttachmentTypeRequest: (data: IUpdateAttachmentTypeRequest) => unknown;
};

interface IAddUpdateAttachmentTypeOwnProps {
    id?: string;
};

interface IAddUpdateAttachmentTypeComponentState {
    data: IAttachmentType;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateAttachmentTypeStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateAttachmentTypeComponentProps =
    RouteComponentProps
    & IAddUpdateAttachmentTypeOwnProps
    & IAddUpdateAttachmentTypeComponentStoreProps
    & IAddUpdateAttachmentTypeComponentDispatchProps;

class AddUpdateAttachmentType extends PureComponent<IAddUpdateAttachmentTypeComponentProps, IAddUpdateAttachmentTypeComponentState> {

    public constructor(props: IAddUpdateAttachmentTypeComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                name: "",
                active: YesOrNo[0].name,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
            },
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateAttachmentTypeComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addAttachmentTypeStatus !== prevProps.addAttachmentTypeStatus) {

                if (this.props.addAttachmentTypeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addAttachmentTypeStatus.message });

                if (hasPayload(this.props.addAttachmentTypeStatus) && this.props.addAttachmentTypeStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateAttachmentTypeStatus !== prevProps.updateAttachmentTypeStatus) {

                if (this.props.updateAttachmentTypeStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateAttachmentTypeStatus.message });

                if (hasPayload(this.props.updateAttachmentTypeStatus) && this.props.updateAttachmentTypeStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError } = this.state;
        const { token, addAttachmentTypeStatus, updateAttachmentTypeStatus } = this.props;
        const getRole = pageAccessCheck(token, "attachmentAccess");
        const disabled = getRole === ReadOnly ? true : undefined;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);

        return (
            <AddUpdateAttachmentTypeStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>ATTACHMENT TYPE</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Attachment Type Name"
                                fullWidth={true}
                                variant="outlined"
                                disabled={disabled}
                                name="name"
                                value={data.name}
                                onChange={this.handleChange}
                                errorText={errors["name"] ? errors["name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12}>
                            <></>
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                onCancel={this.handleCancel}
                                disableSave={Object.values(errors).length > 0 ? true : disabled}
                            />
                        </LAGridItem>

                    </LAGrid>}

                <RequestStatus requestStatus={addAttachmentTypeStatus.kind} successMessage="Attachment Type has been added successfully" />
                <RequestStatus requestStatus={updateAttachmentTypeStatus.kind} successMessage="Attachment Type has been updated successfully" />
            </AddUpdateAttachmentTypeStyles>
        );
    }

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        
        this.props.history.push({
            pathname: ROUTE.FIELD.ATTACHMENT_LIST.TYPES,
            search: getTokenFromUrl(false)
          });
    };

    private handleSave = async (): Promise<void> => {
        const { id, name, active, created_By } = this.state.data;

        if (hasPayload(this.props.token)) {

            if (id === 0) {
                this.props.addAttachmentTypeRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Active: active,
                        Created_By: this.props.token.payload.response.upn,
                        Modified_By: this.props.token.payload.response.upn,
                        Name: name
                    }
                });
            } else {
                this.props.updateAttachmentTypeRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: id,
                        Name: name,
                        Active: active,
                        Created_By: created_By,
                        Modified_By: this.props.token.payload.response.upn,
                    }
                });
            }

            this.setState({ serverError: "" });
        }
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "attachmentAccess") !== NotApplicable) {

                    if (query.id !== "0") {
                        if (hasPayload(this.props.serviceTypes)) {
                            const data = this.props.serviceTypes.payload.response[query.id];
                            this.setState({
                                data
                            });
                        } else {
                            this.props.getAttachmentTypesRequest({
                                token: this.props.token.payload.response.token
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        this.setState({ errors });
                    };

                } else {
                    
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                      });
                };
            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateAttachmentTypeComponentStoreProps => ({
    serviceTypes: getTypes(state),
    token: getToken(state),
    addAttachmentTypeStatus: addAttachmentTypeStatus(state),
    updateAttachmentTypeStatus: updateAttachmentTypeStatus(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateAttachmentTypeComponentDispatchProps => ({
    addAttachmentTypeRequest: (data: IAddAttachmentTypeRequest) => dispatch(addAttachmentTypeLoadAction(data)),
    getAttachmentTypesRequest: (data: IGetTypeRequest) => dispatch(getTypesLoadAction(data)),
    updateAttachmentTypeRequest: (data: IUpdateAttachmentTypeRequest) => dispatch(updateAttachmentTypeLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateAttachmentType);