import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IADD_JOB_MHR_REQUEST {
    REQUEST = "addJobMhr/ADD_JOB_MHR_REQUEST",
    SUCCESS = "addJobMhr/ADD_JOB_MHR_SUCCESS",
    FAILED = "addJobMhr/ADD_JOB_MHR_FAILED"
};

export interface IAddJobMhrRequest extends ISurewayTokenRequestBody {
    request: {
        Job: string;
        Year: number;
        Code: string;
        Description: string;
        Requested_By: string;
        Location: string;
        Active: string;
        Created_By: string;
        Modified_By: string;
    };
};