
export enum IGET_VENDOR_ORDER_REQUEST {
    REQUEST = "getVendorOrder/GET_VENDOR_ORDER_REQUEST",
    SUCCESS = "getVendorOrder/GET_VENDOR_ORDER_SUCCESS",
    FAILED = "getVendorOrder/GET_VENDOR_ORDER_FAILED"
};

export interface IGetVendorOrderRequest {
    token: string;
};
  
  
  export interface IVendorOrder {
    id: number;
    requested_By: string;
    requested_By_Name?: string;
    job_No?: string;
    date: string;
    po: string;
    vendor: string;
    job_ID?: number;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
  };
  
  export interface IVendorOrderWork {
    id: number;
    order_ID: number;
    scope: string;
    description: string;
    cost_Code: string;
    ticket: string;
    invoice: string;
    leM_No: string;
    in_CDMS: string;
    in_Explorer: string;
    committed_Amount?: number;
    invoiced_Amount?: number;
    accrued_Cost: string;
    accrued_Revenue: string;
    rog: string;
    roG_Date: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
  };
  