import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_FORM_BY_TEMPLATE_ID_REQUEST } from "./getFormByTemplateIDConstants";
import { IGetFormByTemplateIDLoadAction, IGetFormByTemplateIDLoadFailedAction, IGetFormByTemplateIDSuccessAction } from "./getFormByTemplateIDActions";
import { IChecklistForm } from "../getForms/getFormsConstants";


type Actions =
    | IGetFormByTemplateIDLoadAction
    | IGetFormByTemplateIDSuccessAction
    | IGetFormByTemplateIDLoadFailedAction
    // | IUpdateShopEquipmentSuccessAction
    // | IAddShopEquipmentSuccessAction
    | IFlushDataSuccessAction;

export const GetFormByTemplateIDReducer = (state: Server<SurewayAPIResponse<IChecklistForm>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IChecklistForm>> => {
    switch (action.type) {
        case IGET_FORM_BY_TEMPLATE_ID_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_BY_TEMPLATE_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_BY_TEMPLATE_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        // case IADD_SHOP_EQUIPMENT_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_SHOP_EQUIPMENT_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_SHOP_MANUFACTURERS_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_STYLE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IUPDATE_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_TYPE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_STYLE_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_ATTACHMENT_COUPLER_REQUEST.SUCCESS:
        //     return notLoaded;

        // case IADD_EQUIPMENT_CERTIFICATE_REQUEST.SUCCESS:
        //     return notLoaded;

        default:
            return state;
    }
};