
export enum IADD_MEDICAL_FACILITY_REQUEST {
    REQUEST = "addMedicalFacility/ADD_MEDICAL_FACILITY_REQUEST",
    SUCCESS = "addMedicalFacility/ADD_MEDICAL_FACILITY_SUCCESS",
    FAILED = "addMedicalFacility/ADD_MEDICAL_FACILITY_FAILED"
};

export interface IAddMedicalFacilityRequest {
    token: string;
    request: IAddUpdateMedicalFacility;
};

export interface IAddUpdateMedicalFacility {
    ID: number;
    Name: string;
    Address: string;
    Active: string;
    GPS: string;
    WSS_IDs: string;
    Created_By: string;
    Modified_By: string;
}
