import { IAddUpdateHazardNew } from "../addHazardNew/addHazardNewConstants";

export enum IUPDATE_HAZARD_NEW_REQUEST {
    REQUEST = "updateHazardNew/UPDATE_HAZARD_NEW_REQUEST",
    SUCCESS = "updateHazardNew/UPDATE_HAZARD_NEW_SUCCESS",
    FAILED = "updateHazardNew/UPDATE_HAZARD_NEW_FAILED"
};

export interface IUpdateHazardNewRequest {
    token: string;
    request: IAddUpdateHazardNew;
};


