import { ReactNode, PureComponent, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { MdCarRepair, MdEngineering } from "react-icons/md";
import { FaRegSnowflake } from "react-icons/fa";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, IIdNameDisplay, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IRepairRoles, IToken } from "../../../redux/getToken/getTokenConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LABluePaper, LAGreenPaper, LAGreyPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { BLUE_COLOR, BLUE_GREEN_COLOR, EXTRA_DARK_GREY_COLOR, EXTRA_LIGHT_BLUE_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, LIGHT_RED_COLOR, MEDIA_QUERY_PHONE, ORANGE_COLOR, RED_COLOR, WARNING_COLOR, WHITE_COLOR, YELLOW_COLOR } from "../../shared/theme";
import { callRouteWithQueryString, compareTwoObjects, deleteText, fileToBase64Image, getTokenFromUrl, numberAndDecimalCheck, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAGreenButton, LAIconButton, LAOrangeButton, LASaveAndCancelButton, LASecondaryButton } from "../../shared/buttons";
import { AddIcon, ArrowLeftIcon, AttachmentIcon, DeleteIcon, PMSheetIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadAndWrite } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IGetRepairLineRequest, IRepairLine, IRepairLineItem, IRepairLineItemPart, IRepairLinePicture } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesConstants";
import { IAddRepairLineRequest, IAddUpdateRepairLineItem, IAddUpdateRepairLineItemPart } from "../../../redux/field/repairLine/addRepairLine/addRepairLineConstants";
import { IUpdateRepairLineRequest } from "../../../redux/field/repairLine/updateRepairLine/updateRepairLineConstants";
import { getRepairLines } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesAccessor";
import { addRepairLineStatus } from "../../../redux/field/repairLine/addRepairLine/addRepairLineAccessor";
import { updateRepairLineStatus } from "../../../redux/field/repairLine/updateRepairLine/updateRepairLineAccessor";
import { addRepairLineLoadAction } from "../../../redux/field/repairLine/addRepairLine/addRepairLineActions";
import { getRepairLinesLoadAction } from "../../../redux/field/repairLine/getRepairLines/getRepairLinesActions";
import { updateRepairLineLoadAction } from "../../../redux/field/repairLine/updateRepairLine/updateRepairLineActions";
import LADatePicker from "../../shared/datePicker";
import LATextArea from "../../shared/textArea";
import { LACheckBox } from "../../shared/checkBox";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import LALinkButton from "../../shared/linkButton";
import { LAPopover } from "../../shared/popOver";
import UnitDetails from "../equipment/unitDetails";
import LALoading from "../../shared/loading";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { IAttachment, IComponentCodeList, IDriverList, IFieldLookup, IFieldLookupRequest, ILookupMechanic } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { webConfig } from "../../../utils/webConfig";
import { RepairLineLogPopup } from "./logsPopup";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { IRole } from "../../../redux/getAuthorization/getAuthorizationConstants";
import { LADropZone } from "../../shared/dropZone";
import CanvasDraw from "react-canvas-draw";
import { RepairLineHistory } from "./repairLineHistory";
import { IGetRepairLineHistory, IGetRepairLineHistoryRequest } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryConstants";
import { getRepairLineHistory } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryAccessor";
import { getRepairLineHistoryLoadAction } from "../../../redux/field/repairLine/getRepairLineHistory/getRepairLineHistoryActions";
import { IGetRepairLineVersionHistory, IGetRepairLineVersionHistoryRequest } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryConstants";
import { getRepairLineVersionHistory } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryAccessor";
import { getRepairLineVersionHistoryLoadAction } from "../../../redux/field/getRepairLineVersionHistory/getRepairLineVersionHistoryActions";
import { RepairLineVersionHistory } from "./repairLineVersionHistory";
import { RepairLineVersionHistoryDefectItem } from "./repairLineVersionHistoryDefectItem";
import { getRepairLineById } from "../../../redux/field/repairLine/getRepairLineById/getRepairLineByIdAccessor";
import { getRepairLineByIdLoadAction } from "../../../redux/field/repairLine/getRepairLineById/getRepairLineByIdActions";
import { IGetRepairLineByIdRequest } from "../../../redux/field/repairLine/getRepairLineById/getRepairLineByIdConstants";
import { IGetRepairLineItemByIdRequest } from "../../../redux/field/repairLine/getRepairLineItemById/getRepairLineItemByIdConstants";
import { getRepairLineItemById } from "../../../redux/field/repairLine/getRepairLineItemById/getRepairLineItemByIdAccessor";
import { getRepairLineItemByIdLoadAction } from "../../../redux/field/repairLine/getRepairLineItemById/getRepairLineItemByIdActions";
import { ISaveRepairLineItem, ISaveRepairLineItemRequest } from "../../../redux/field/repairLine/saveRepairLineItem/saveRepairLineItemConstants";
import { saveRepairLineItemStatus } from "../../../redux/field/repairLine/saveRepairLineItem/saveRepairLineItemAccessor";
import { saveRepairLineItemLoadAction } from "../../../redux/field/repairLine/saveRepairLineItem/saveRepairLineItemActions";
import { LAExpansionPanel } from "../../shared/expansionPanel";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MechanicLogsVersionHistory } from "./mechanicLogsVersionHistory";
import { IGetMechanicTimecardVersionHistory, IGetMechanicTimecardVersionHistoryRequest } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";
import { getMechanicTimecardVersionHistory } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryAccessor";
import { getMechanicTimecardVersionHistoryLoadAction } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryActions";

const RequiredFields: string[] = ["unit_Number", "attachment_No"];
const ClosePopUpText: string = "Any unsaved changes will be lost! are you sure ?";
export const PartStatus: IIdName[] = [{ id: 0, name: "Initiated" }, { id: 1, name: "Picked" }, { id: 2, name: "En Route" }, { id: 3, name: "Delivered" }, { id: 4, name: "Out of stock" }, { id: 5, name: "Installed" }];

interface IAddUpdateRepairLineComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addRepairLineStatus: Server<SurewayAPIResponse<string>>;
    repairLines: Server<SurewayAPIResponse<ById<IRepairLine>>>;
    updateRepairLineStatus: Server<SurewayAPIResponse<string>>;
    getHistory: Server<SurewayAPIResponse<ById<IGetRepairLineHistory>>>;
    updateRepairLineCurrentState: Server<SurewayAPIResponse<IRepairLine>>;
    updateRepairLineItemCurrentState: Server<SurewayAPIResponse<IRepairLineItem>>;
    saveRepairLineItemStatus: Server<SurewayAPIResponse<string>>;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>>;
    getMechanicLogsVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
};

interface IAddUpdateRepairLineComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    getRepairLinesRequest: (data: IGetRepairLineRequest) => unknown
    addRepairLineRequest: (data: IAddRepairLineRequest) => unknown;
    updateRepairLineRequest: (data: IUpdateRepairLineRequest) => unknown;
    getRepairLineHistory: (request: IGetRepairLineHistoryRequest) => unknown;
    updateRepairLineCurrentStateRequest: (request: IGetRepairLineByIdRequest) => unknown;
    updateRepairLineItemCurrentStateRequest: (request: IGetRepairLineItemByIdRequest) => unknown;
    saveRepairLineItemRequest: (data: ISaveRepairLineItemRequest) => unknown;
    getVersionHistoryRequest: (request: IGetRepairLineVersionHistoryRequest) => unknown;
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest) => unknown;
};

interface IAddUpdateRepairLineOwnProps {
    id?: string;
};

interface IAddUpdateRepairLineComponentState {
    fromApp: boolean;
    data: IRepairLine;
    repairLocation: string;
    workOrderNo: string;
    logsPopup: boolean;
    serverError: string;
    serverErrorSaveDefectItem: string;
    unitDetails: boolean;
    historyPopup: boolean;
    notAvaUnits: number[];
    notAvaAttchments: number[];
    imageUploadLoading: boolean;
    popUpIndex: number | undefined;
    expandPart: number | undefined;
    activeError: number | undefined;
    popupImgs: IRepairLinePicture[];
    errors: ById<IFieldErrorKeyValue>;
    saveClicked: boolean;
    difference: IRepairLine | undefined;
    defectItemDifference: IRepairLineItem | undefined;
    original: IRepairLineItem | undefined;
    editPart: IRepairLineItemPart | undefined;
    subTableErrors: ById<IFieldErrorKeyValue>;
    repairLineVersionHistoryPopup: boolean;
    repairLineVersionHistoryComponentPopup: boolean;
    mechLogsVersionHistoryPopup: boolean;
};

const AddUpdateRepairLineStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .parts-back {
        background-color: ${LIGHT_GREY_COLOR};
    };

    .btn {
        cursor: pointer;
        text-align: center;
        background-color: ${WHITE_COLOR};
        border: 2px solid ${BLUE_COLOR};
    };

    .light-red-text {
        color: ${LIGHT_RED_COLOR};
    };

    .green-text { 
        color: ${GREEN_COLOR};
    }

    .orange-text {
        color: ${ORANGE_COLOR};
    }
    .blue-text {
        color: ${BLUE_COLOR};
    }

    .green-btn {
        cursor: pointer;
        text-align: center;
        background-color: ${LIGHT_GREY_COLOR};
        border: 2px solid ${GREEN_COLOR};
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .thumbnail-img{
        border-width: 0;
        height: auto;
        max-height: 130px;
        max-width: 130px;
        vertical-align: middle;
        width: auto;
      }

      .componentBody{
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
      }
      
      .componentSpacing{
        float:none;
        padding: 10px;
      }
      
      .signatureCanvas{
        border: 1px solid black;
        width: 100%;
        min-height: 80px;
      }
`;

type IAddUpdateRepairLineComponentProps =
    RouteComponentProps
    & IAddUpdateRepairLineOwnProps
    & IAddUpdateRepairLineComponentStoreProps
    & IAddUpdateRepairLineComponentDispatchProps;

class AddUpdateRepairLine extends PureComponent<IAddUpdateRepairLineComponentProps, IAddUpdateRepairLineComponentState> {

    public constructor(props: IAddUpdateRepairLineComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                repair_Attachment: "No",
                unit_ID: 0,
                attachment_ID: 0,
                components: "",
                parts: "",
                initiated_Date: new Date().toDateString(),
                requested_By: "",
                assigned_Mech: "",
                status: "Active",
                comments: "",
                manager_Instructions: "",
                estimated_Completion_Date: "",
                date_Completed: "",
                location_Info: "",
                work_Order_No: "",
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
                send_Email: "No",
                email_Log: "",
                items: [],
                assigned_Mechanic_ID: 0,
                mechanic_Names: "",
                priority: "",
                repair_Location: "Field",
                repair_Type: "Field",
            },
            repairLocation: "",
            workOrderNo: "",
            saveClicked: false,
            difference: undefined,
            defectItemDifference: undefined,
            logsPopup: false,
            popupImgs: [],
            original: undefined,
            editPart: undefined,
            expandPart: undefined,
            errors: {},
            popUpIndex: undefined,
            subTableErrors: { "defect_Item": { key: "defect_Item", message: FIELD_VALIDATOR_ERRORS.REQUIRED } , "attachment_No": { key: "attachment_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED }},
            serverError: "",
            serverErrorSaveDefectItem: "",
            notAvaUnits: [],
            notAvaAttchments: [],
            activeError: undefined,
            unitDetails: false,
            historyPopup: false,
            fromApp: false,
            imageUploadLoading: false,
            repairLineVersionHistoryPopup: false,
            repairLineVersionHistoryComponentPopup: false,
            mechLogsVersionHistoryPopup: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.getRepairLineDataByID();
        this.setDataToState();
    };

    public async componentDidUpdate(prevProps: IAddUpdateRepairLineComponentProps): Promise<void> {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addRepairLineStatus !== prevProps.addRepairLineStatus) {

                if (this.props.addRepairLineStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addRepairLineStatus.message });

                if (hasPayload(this.props.addRepairLineStatus) && this.props.addRepairLineStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        // this.handleCancel();
                        callRouteWithQueryString({
                            route: this.props,
                            search: {  },
                            pathName: ROUTE.FIELD.REPAIR_LINE.INDEX
                        });
                    }
                }
            };

            if (this.props.updateRepairLineStatus !== prevProps.updateRepairLineStatus) {

                if (this.props.updateRepairLineStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateRepairLineStatus.message });

                if (hasPayload(this.props.updateRepairLineStatus) && this.props.updateRepairLineStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }

            if (this.props.updateRepairLineCurrentState !== prevProps.updateRepairLineCurrentState) {
                await this.checkDifference();
            };

            if (this.props.updateRepairLineItemCurrentState !== prevProps.updateRepairLineItemCurrentState) {
                await this.checkDefectItemDifference();
            };

            if (this.props.saveRepairLineItemStatus !== prevProps.saveRepairLineItemStatus) {

                if (this.props.saveRepairLineItemStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverErrorSaveDefectItem: this.props.saveRepairLineItemStatus.message });

                if (hasPayload(this.props.saveRepairLineItemStatus) && this.props.saveRepairLineItemStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.setState({
                        popUpIndex: undefined,
                        original: undefined,
                        defectItemDifference: undefined,
                        popupImgs: [],
                        serverErrorSaveDefectItem: ""
                    });

                    const query = queryString.parse(this.props.location.search);

                    if(query && query.defect_ID)
                        callRouteWithQueryString({
                            route: this.props,
                            search: { id: query.id?.toString(), view: query.view ? query.view.toString() : "All" },
                            pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
                        });

                    window.location.reload();
                }
            }

        }
    };


    public render(): ReactNode {

        const { token, addRepairLineStatus, updateRepairLineStatus, getLookup, getHistory, getVersionHistory, getMechanicLogsVersionHistory } = this.props;

        const { data, errors, serverError, serverErrorSaveDefectItem, fromApp, subTableErrors, activeError, unitDetails, historyPopup, repairLineVersionHistoryPopup, repairLineVersionHistoryComponentPopup, mechLogsVersionHistoryPopup,
            imageUploadLoading, expandPart, popUpIndex, original, editPart, popupImgs, logsPopup, difference, defectItemDifference, repairLocation , workOrderNo} = this.state;
        // console.log(data)

        const repairLineRole = hasPayload(token) ? token.payload.response.repairLineRole : undefined
        const repairRoles = hasPayload(token) ? token.payload.response.repairRoles : undefined
        // const managerRole = repairLocation === "Shop" ? repairRoles?.shop_Manager : repairRoles?.field_Manager;
        // console.log(managerRole)
        const hasUrlToken = window.location.href.includes("token");
        const getRole = pageAccessCheck(token, "repairLine");
        const disabled = ((data.id > 0) && ((data.status === RepairLineStatusList[1].name) || data.status === RepairLineStatusList[2].name)) ? true : undefined;
        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const attachments = hasPayload(getLookup) ? getLookup.payload.response.attachments : [];
        const lubeDriversList = hasPayload(getLookup) ? getLookup.payload.response.lube_drivers : [];
        const mechanics = hasPayload(getLookup) ? getLookup.payload.response.mechanics : [];
        // const fieldMechanicsList = mechanics?.filter((x:ILookupMechanic) => x.type === "Field" || x.type === "Both");
        // const shopMechanicsList = mechanics?.filter((x:ILookupMechanic) => x.type === "Shop" || x.type === "Both");
        const componentCodes = hasPayload(getLookup) ? getLookup.payload.response.component_codes : [];
        const workOrder = hasPayload(token) ? (token.payload.response.workorder === "Yes") ? undefined : true : true;
        const onAttachmentRepairCheckbox = (checked: boolean, name?: string | undefined): void => this.handleChange(name ?? "", checked ? "Yes" : "No");
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const onAttachment = (value: string): void => value !== null ? this.handleAttachment(value) : this.handleAttachment();
        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : "");
        const onCompletedDate = (date: string): void => this.handleChange("date_Completed", new Date(date).toISOString());
        
        const disableSave = (data.items.length === 0) ? true : (Object.values(errors).length > 0) ? true : (Object.values(subTableErrors).length > 0) ? true : disabled;
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const usedPartsUnit = unit && unit.status === "Used Parts" ? true : false;
        // console.log(unit)
        // console.log(usedPartsUnit)
        const attachment = data.attachment_ID && attachments ? attachments.find(q => q.id === data.attachment_ID) : null;
        const historypopupData = hasPayload(getHistory) ? getHistory.payload.response : {};
        const repairLineVersionHistorypopupData = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};
        const mechanicLogsVersionHistorypopupData = hasPayload(getMechanicLogsVersionHistory) ? getMechanicLogsVersionHistory.payload.response : {};
        const workOrderAcess = hasPayload(token) && (token.payload.response.workorder === "Yes") ? true : false;
        // console.log(this.state.defectItemDifference)
        
        return (
            <AddUpdateRepairLineStyles>
                {(getRole !== NotApplicable) &&
                    imageUploadLoading ?
                    <LAGreyPaper>
                        <LALoading message="Uploading Image... Please Wait" />
                    </LAGreyPaper>
                    : <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                            <LALinkButton onClick={() => this.handleRepairLineVersionHistory(data)} label="Version History"></LALinkButton>
                        </LAGridItem>
                        }

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>REPAIR LINE</h2>
                            <hr />
                            {difference && <LAErrorBox color={RED_COLOR} text={`${data.modified_By}, has made come changes to this repair line. Changes highlighted in gray box below`} />}
                        </LAGridItem>

                        {data.id === 0 && <LAGridItem xs={12}>
                            <LACheckBox
                                name="repair_Attachment"
                                label="Attachment repair without unit?"
                                onChange={onAttachmentRepairCheckbox}
                                value={(data.repair_Attachment === "Yes") ? true : false}
                            />
                        </LAGridItem>}

                        {data.repair_Attachment !== "Yes" && 
                            <>
                                <LAGridItem xs={12} sm={6} md={4}>
                                    {data.id === 0 && <DevExtremePopupLookup
                                        data={(units && units.length > 0) ? units : []}
                                        id="repairLine-unit-search"
                                        placeHolder="Unit Number"
                                        disabled={fromApp ? fromApp : data.id > 0 ? true : disabled}
                                        displayExp="unit_Number"
                                        name="unit_Number"
                                        errorName="unit_Number"
                                        errors={this.state.errors}
                                        currentVal={unit?.unit_Number}
                                        onClick={onUnit}
                                        columns={[
                                            { name: "unit_Number", caption: "Unit Number", type: "string" },
                                            { name: "make", caption: "Make", type: "string" },
                                            { name: "model", caption: "Model", type: "string" },
                                            { name: "location_Details", caption: "Location", type: "string" },
                                        ]}
                                    />}
                                    {(data.id > 0) &&
                                        <LATextField
                                            label="Unit Number"
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={true}
                                            name="unit_Number"
                                            onChange={undefinedFunction}
                                            value={data.unit_No ? data.unit_No : ""}
                                        />
                                    }

                                    {(data.id > 0) &&
                                        <LALinkButton
                                            label="Unit Details"
                                            onClick={this.handleUnitDetails}
                                        />}
                                    &nbsp;&nbsp;&nbsp;
                                    {(data.id > 0) &&
                                        <LALinkButton
                                            label="Repair History"
                                            onClick={() => this.handleRepairHistory(data)}
                                        />}
                                    &nbsp;&nbsp;&nbsp;
                                    {(data.id > 0) &&
                                        <LALinkButton onClick={() => this.handleLinkClick("Unit_History")} label="Unit History" />
                                    }
                                    &nbsp;&nbsp;&nbsp;
                                    {(data.id > 0) &&
                                        <LALinkButton onClick={() => this.handleLinkClick("Equipment_Manuals")} label="Equipment Manuals" />
                                    }
                                    &nbsp;&nbsp;&nbsp;
                                    {(data.id > 0) &&
                                        <LALinkButton onClick={() => this.handleLinkClick("TSI")} label="TSI" />
                                    }
                                </LAGridItem>
                            
                                <LAGridItem xs={12} sm={6} md={4}>
                                    {(data.id === 0) &&<LATextField
                                        label="Location Details"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="locationDetails"
                                        onChange={undefinedFunction}
                                        value={unit ? unit.location_Details : ""}
                                    />}
                                    {(data.id > 0) && <LATextField
                                        label="Location Details"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="location"
                                        onChange={undefinedFunction}
                                        value={data.location_Info ? data.location_Info : ""}
                                    />}
                                </LAGridItem>
                            </>
                        }

                        {data.repair_Attachment === 'Yes' && 
                            <>
                                <LAGridItem xs={12} sm={6} md={4}>
                                    {data.id === 0 && <DevExtremePopupLookup
                                        data={(attachments && attachments.length > 0) ? attachments : []}
                                        id="repairLine-attachment-search"
                                        placeHolder="Attachment No."
                                        disabled={fromApp ? fromApp : data.id > 0 ? true : disabled}
                                        displayExp="attachment_No"
                                        name="attachment_No"
                                        errorName="attachment_No"
                                        errors={this.state.errors}
                                        currentVal={attachment?.attachment_No}
                                        onClick={onAttachment}
                                        columns={[
                                            { name: "attachment_No", caption: "Attachment No.", type: "string" },
                                            { name: "type", caption: "Type", type: "string" },
                                            { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                            { name: "style", caption: "Style", type: "string" },
                                            { name: "location", caption: "Location", type: "string" },
                                        ]}
                                    />}
                                    {(data.id > 0) &&
                                        <LATextField
                                            label="Attachment No."
                                            fullWidth={true}
                                            variant="outlined"
                                            disabled={true}
                                            name="attachment_No"
                                            onChange={undefinedFunction}
                                            value={data.attachment_No ? data.attachment_No : ""}
                                        />
                                    }
                                    {(data.id > 0) &&
                                        <LALinkButton
                                            label="Attachment Details"
                                            onClick={() => this.handleAttachmentDetails(data.attachment_ID)}
                                        />}
                                    &nbsp;&nbsp;&nbsp;
                                    {(data.id > 0) &&
                                        <LALinkButton
                                            label="Repair History"
                                            onClick={() => this.handleRepairHistory(data)}
                                        />}
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    {data.id === 0 && <LATextField
                                        label="Location Details"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="location"
                                        onChange={undefinedFunction}
                                        value={attachment ? attachment.location : ""}
                                    />}
                                    {(data.id > 0) && <LATextField
                                        label="Location Details"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="location"
                                        onChange={undefinedFunction}
                                        value={data.location_Info ? data.location_Info : ""}
                                    />}
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={4}>
                                    {data.id === 0 && <LATextField
                                        label="Attached Unit"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="attached_Unit"
                                        onChange={undefinedFunction}
                                        value={attachment ? attachment.attached_Unit_No : ""}
                                    />}
                                    {data.id > 0 &&<LATextField
                                        label="Attached Unit"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="attached_Unit"
                                        onChange={undefinedFunction}
                                        value={data.attached_Unit_No ? data.attached_Unit_No : ""}
                                    />}
                                    
                                </LAGridItem>
                            </>
                        }

                        {data.id > 0 &&
                            <>
                                {/* <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextField
                                        type="string"
                                        label="Requested By"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        name="requested_By"
                                        value={data.requested_By}
                                        onChange={undefinedFunction}
                                    />
                                    {difference && difference.requested_By!==data.requested_By && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.requested_By} />}
                                </LAGridItem> */}

                                <LAGridItem xs={12} sm={6} md={4}>
                                    <LAAutoComplete
                                        disabled={true}
                                        multiple={false}
                                        option={RepairLineStatusList}
                                        autoHighlight={true}
                                        onChange={onStatus}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        dropDownPlaceHolder="Status"
                                        selectionRemove={undefinedFunction}
                                        errorText={errors["status"] ? errors["status"].message : undefined}
                                        value={data.status && RepairLineStatusList ? RepairLineStatusList.find(q => q.name === data.status) : null}
                                        defaultValue={data.status && RepairLineStatusList ? RepairLineStatusList.find(q => q.name === data.status) : null}
                                    />
                                    {/* {difference && difference.status !== data.status && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.status} />} */}
                                </LAGridItem>


                                {/* <LAGridItem xs={12} sm={6} md={4}>
                                    <LAAutoComplete
                                        multiple={true}
                                        option={mechanics}
                                        autoHighlight={true}
                                        getOptionLabel="name"
                                        filterSelectedOptions={true}
                                        onChange={this.handleAssignedMech}
                                        dropDownPlaceHolder="Assigned Mechanic"
                                        selectionRemove={this.handleAssignedMechRemove}
                                        disabled={repairLineRole !== "Manager" ? true : disabled ? true : (getRole === ReadOnly) ? true : undefined}
                                        errorText={errors["assigned_Mech"] ? errors["assigned_Mech"].message : undefined}
                                        value={data.assigned_Mech && mechanics ? this.getAssignedMechanics(data.assigned_Mech) : []}
                                        defaultValue={data.assigned_Mech && mechanics ? this.getAssignedMechanics(data.assigned_Mech) : []}
                                    />
                                    {difference && difference.assigned_Mech && difference.assigned_Mech !== data.assigned_Mech && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.assigned_Mech} />}
                                </LAGridItem> */}

                                {workOrderAcess && (repairLocation === "Shop" || workOrderNo === "PENDING") && <LAGridItem xs={12} sm={6} md={4}>
                                    <LATextField
                                        type="string"
                                        label="Work Order #"
                                        fullWidth={true}
                                        variant="outlined"                                        
                                        disabled={workOrder}
                                        name="work_Order_No"
                                        value={data.work_Order_No}
                                        onChange={this.handleChange}
                                    />
                                    {difference && difference.work_Order_No && difference.work_Order_No !== data.work_Order_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.work_Order_No} />}
                                </LAGridItem>}
                            </>}

                            <LAGridItem xs={12} sm={6} md={4}>
                                <LACheckBox
                                    label="Move to shop - UNIT"
                                    disabled={!(repairRoles?.field_Manager || repairRoles?.shop_Manager) || disabled  }
                                    onChange={this.onSendToShopCheck}
                                    value={data.repair_Location === "Shop" ? true : false}
                                />
                                {difference && difference.repair_Location && difference.repair_Location !== data.repair_Location && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.repair_Location} />}
                            </LAGridItem>

                        {(RepairLineStatusList[1].name === data.status) &&
                            <LAGridItem xs={12} sm={6} md={4} className={errors["date_Completed"] ? "required-text text-center" : "text-center"}>

                                <strong>Completion Date</strong>
                                <br />
                                {errors["date_Completed"] && <>
                                    <span className="required-text">{errors["date_Completed"].message}</span>
                                    <br />
                                </>}

                                <LADatePicker
                                    mode="single"
                                    disable={disabled}
                                    id="date_Completed"
                                    onChange={onCompletedDate}
                                    value={data.date_Completed}
                                />
                                {difference && difference.date_Completed && difference.date_Completed !== data.date_Completed && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.date_Completed} />}
                            </LAGridItem>
                        }

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                type="string"
                                label="Last Modified"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="modified"
                                value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                onChange={undefinedFunction}
                            />
                        </LAGridItem>}

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                type="string"
                                label="Last Modified By"
                                fullWidth={true}
                                variant="outlined"
                                disabled={true}
                                name="modified_By"
                                value={data.modified_By}
                                onChange={undefinedFunction}
                            />
                        </LAGridItem>}

                        <LAGrid className="mt-2 componentBody">
                            <LAGridItem xs={12} className="text-center">
                                <strong>DEFECT ITEMS</strong>

                                <LAIconButton
                                    label="Add New"
                                    icon={<AddIcon />}
                                    onClick={this.handleAdd}
                                    disabled={(data.status === "Completed" || data.status === "Cancelled" ? true : undefined)}
                                />
                                <hr />
                                {difference && difference.items && difference.items !== data.items && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} textColor= {RED_COLOR} text="Changes made under Defect Item" />}
                            </LAGridItem>
                        </LAGrid>

                        <LAGrid className="mb-2 componentBody">
                            <LAGridItem xs={12}>
                                {(data.items.length === 0) && <LAErrorBox text="Please add at least one defect item." />}
                            </LAGridItem>

                            {this.sortDefectItems(data.items).map((x, index) => {
                                const onClick = (): void => this.handleComponentClick(x, index);
                                return (
                                    <LAGridItem xs={12} sm={6} md={3} lg={2} key={index}>
                                        <div key={index} onClick={onClick} style={{backgroundColor: (x.winter_Repair === "Yes" && x.status !== "Cancelled") ? YELLOW_COLOR : ""}} className={(ComponentStatusList[3].name === x.status || ComponentStatusList[4].name === x.status) ? "green-btn componentSpacing" : " btn componentSpacing"}>
                                        
                                            {(x.mechanic_Names || x.lube_Driver) && <MdEngineering size={26} color="black"/>} 
                                            {(x.repair_Location === "Shop") && <MdCarRepair size={26} color="black"/>} 
                                            {x.repair_Type === "Lube" && <PMSheetIcon />} 
                                            {(x.attachment_ID && x.attachment_ID > 0) ? <AttachmentIcon /> : undefined} 
                                            {(x.winter_Repair === "Yes") && <FaRegSnowflake  size={26}/>}
                                            <br />
                                            <strong>Defect Item:  </strong>
                                            <span style={{ color: "black"}}>{x.defect_Item}</span>
                                            <br />

                                            <strong>Priority: </strong>
                                            <span className={(x.status === ComponentStatusList[3].name || x.status === ComponentStatusList[4].name) ? "" : ((x.priority === "Down") ? "light-red-text" : (x.priority === "Urgent") ? "orange-text" : (x.priority === "Medium") ? "blue-text" : (x.priority === "Low") ? "green-text" : "")}>{x.priority}</span>
                                            <br />

                                            <strong>Status: </strong>
                                            <span style={{ color: "black"}} >{x.status}</span>
                                            <br />

                                            <strong>WO#/Job-Cost# : </strong>
                                            <span style={{ color: "black" }} >{
                                                (x.job_No || x.cost_Code) ? 
                                                    (x.job_No && x.job_No) + "-" + (x.cost_Code && x.cost_Code) : 
                                                    x.work_Order_No && x.work_Order_No 
                                            }</span>
                                            <br />

                                            {x.mechanic_Names && <>
                                                <strong>Assigned Mechanics: </strong>
                                                <span style={{ color: "black"}}>{x.mechanic_Names}</span>
                                                <br />
                                            </>}

                                            {x.lube_Driver && <>
                                                <strong>Lube Driver: </strong>
                                                <span style={{ color: "black"}}>{x.lube_Driver}</span>
                                                <br />
                                            </>}

                                            <div style={{ fontSize: "12px"}}>
                                                {x.created && <>
                                                    <strong>Created: </strong>
                                                    <span style={{ color: "black"}} >{x.created ? new Date(x.created).toLocaleString() : ""}</span>
                                                    <br />
                                                </>}

                                                <strong>Modified By: </strong>
                                                <span style={{ color: "black"}} >{x.modified_By }</span>
                                                <br />

                                                <strong>Last Modified: </strong>
                                                <span style={{ color: "black"}} >{x.modified ? new Date(x.modified).toLocaleString() : ""}</span>
                                                <br />

                                                {x.date_Completed && <>
                                                    <strong>Date Completed: </strong>
                                                    <span style={{ color: "black"}} >{x.date_Completed ? new Date(x.date_Completed).toLocaleString() : ""}</span>
                                                    <br />
                                                </>}
                                            </div>
                                        </div>
                            </LAGridItem>
                                );
                            })}
                        </LAGrid>

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        {activeError !== undefined && <LAGridItem xs={12}>
                            <LAErrorBox text="This unit is already active, please make changes to the active record." />
                            <LALinkButton
                                label="Open Active Unit"
                                onClick={this.goToActive}
                            />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                                disableSave= { !workOrder ? undefined: (data.id > 0 && data.status === RepairLineStatusList[1].name) || disableSave}
                            />
                        </LAGridItem>


                    </LAGrid>}

                {unitDetails && <LAPopover
                    open={unitDetails}
                    onClose={this.handleUnitDetails}
                    transformOrigin={{ horizontal: "left", vertical: "center" }}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorRef={null}>
                    <UnitDetails
                        {...this.props}
                        id={data.unit_ID}
                    />
                </LAPopover>}

                {repairLineVersionHistoryPopup && <RepairLineVersionHistory
                    data={repairLineVersionHistorypopupData}
                    display={data.display}
                    open={repairLineVersionHistoryPopup}
                    status={getVersionHistory.kind}
                    onCancel={this.handleVersionHistoryPopupClose}
                />}

                {historyPopup && <RepairLineHistory
                    data={historypopupData}
                    display={data.display}
                    open={historyPopup}
                    status={getHistory.kind}
                    onCancel={this.handleHistoryPopupClose}
                />}

                {(popUpIndex !== undefined) && <LAPopover
                    anchorRef={null}
                    fullWidth={true}
                    onClose={this.handleComponentClose}
                    open={(popUpIndex !== undefined) ? true : false}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    transformOrigin={{ horizontal: "left", vertical: "center" }}>
                    <RepairLineComponent
                        repairLocation={data.repair_Location}
                        lubeDrivers={lubeDriversList}
                        mechanics={mechanics}
                        attachments={attachments}
                        getAssignedLubeDrivers={this.getAssignedLubeDrivers}
                        handleAssignedLubeDriver= {this.handleAssignedLubeDriver}
                        handleAssignedLubeDriverRemove={this.handleAssignedLubeDriverRemove}
                        getAssignedMechanics={this.getAssignedMechanics}
                        handleAssignedMech= {this.handleAssignedMech}
                        // handleAssignedDriver= {this.handleAssignedDriver}
                        handleAssignedMechRemove= {this.handleAssignedMechRemove}
                        data={this.sortDefectItems(data.items)}
                        repairAttachment={data.repair_Attachment}
                        getRole={getRole}
                        disabled={disabled}
                        editData={original}
                        editPart={editPart}
                        display={data.display}
                        logsPopup={logsPopup}
                        workOrder={workOrder}
                        popupImgs={popupImgs}
                        popUpIndex={popUpIndex}
                        expandPart={expandPart}
                        difference={difference}
                        defectItemDifference={defectItemDifference}
                        onPartAdd={this.onPartAdd}
                        onDelete={this.handleDelete}
                        onLogsOpen={this.onLogsOpen}
                        onImageEdit={this.onImageEdit}
                        onLogsClose={this.onLogsClose}
                        onImgDelete={this.onImgDelete}
                        repairLineRole={repairLineRole}
                        repairRoles={repairRoles}
                        // managerRole={managerRole}
                        componentCodes={componentCodes}
                        subTableErrors={subTableErrors}
                        onPartDelete={this.onPartDelete}
                        onPartChange={this.onPartChange}
                        handleExpand={this.handleExpand}
                        handleUpload={this.pictureUpload}
                        onImageName={this.handleImageName}
                        onPopupSave={this.handlePopupSave}
                        handleChange={this.handleSubChange}
                        getVersionHistory={getVersionHistory}
                        handleClose={this.handleComponentClose}
                        onPartExpandSave={this.onPartExpandSave}
                        handleMeterHours={this.handleMeterHours}
                        onPartExpandClose={this.onPartExpandClose}
                        handleRepairLineVersionHistoryComponent={this.handleRepairLineVersionHistoryComponent}
                        repairLineVersionHistoryComponentPopup={repairLineVersionHistoryComponentPopup}
                        repairLineVersionHistorypopupData={repairLineVersionHistorypopupData}
                        handleVersionHistoryComponentPopupClose={this.handleVersionHistoryComponentPopupClose}
                        handleMechLogsVersionHistoryClick={this.handleMechLogsVersionHistoryClick}
                        getMechanicLogsVersionHistory={getMechanicLogsVersionHistory}
                        mechanicLogsVersionHistorypopupData={mechanicLogsVersionHistorypopupData}
                        mechLogsVersionHistoryPopup={mechLogsVersionHistoryPopup}
                        handleMechLogsVersionHistoryPopupClose={this.handleMechLogsVersionHistoryPopupClose}
                        serverErrorSaveDefectItem={serverErrorSaveDefectItem}
                        handleLinkClick={this.handleLinkClick}
                        handleInspectionFormClick={this.handleInspectionFormClick}
                        handleUnitDetails={this.handleUnitDetails}
                        usedPartsUnit={usedPartsUnit}
                    />
                </LAPopover>}

                {!hasUrlToken && <RequestStatus requestStatus={addRepairLineStatus.kind} successMessage="Repair Line has been added successfully" />}
                {!hasUrlToken && <RequestStatus requestStatus={updateRepairLineStatus.kind} successMessage="Repair Line has been updated successfully" />}
                
            </AddUpdateRepairLineStyles>
        );
    }

    private handleLinkClick = (name: string): void => {
        if(name === 'Unit_History') {
            window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY + "?id=" + this.state.data.unit_ID.toString(), "_blank")
            // callRouteWithQueryString({
            //     route: this.props,
            //     search: { },
            //     pathName: ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES
            // });
        }
        if(name === 'TSI') {
            callRouteWithQueryString({
                route: this.props,
                search: { keyword: this.state.data.unit_ID.toString() },
                pathName: ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES
            });
        }
        if(name === 'Equipment_Manuals') {
            callRouteWithQueryString({
                route: this.props,
                search: { keyword: this.state.data.unit_ID.toString() },
                pathName: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX
            }); 
        }

    }

    private handleInspectionFormClick = (defect_ID: number | undefined, inspection_Form_ID?: number): void => {
        if(defect_ID && this.state.data.unit_ID)
            callRouteWithQueryString({
                route: this.props,
                search: { id: inspection_Form_ID ? inspection_Form_ID.toString() : "0", defect_ID: defect_ID.toString(), unit_ID: this.state.data.unit_ID.toString() },
                pathName: ROUTE.FIELD.INSPECTION_FORM.INSPECTION_FORM
            });
    }

    private sortDefectItems = (items : IRepairLineItem[]) => {
        const statusOrder:any = { "Completed": 3, "Cancelled": 4 };
        const priorityOrder:any = { "Down": 1, "Urgent": 2, "Medium": 3, "Low": 4 };

        const customSort = (a: IRepairLineItem, b: IRepairLineItem) => {
            if (a.status === b.status) {
                return priorityOrder[a.priority] - priorityOrder[b.priority];
            } else {
                if (a.status === "Completed" || a.status === "Cancelled") return 1;
                if (b.status === "Completed" || b.status === "Cancelled") return -1;

                return statusOrder[a.status] - statusOrder[b.status];
            }
        };

        return items.sort(customSort);
    }

    private checkDifference = async (): Promise<void> => {
        if (hasPayload(this.props.updateRepairLineCurrentState)) {
            if (this.state.saveClicked) {
                const data = this.props.updateRepairLineCurrentState.payload.response;
                const current = this.state.data;

                if (data.modified !== current.modified) {

                    let diff = compareTwoObjects(current, data);

                    if (diff.items && diff.items.length > 0)
                        diff.items = diff.items.filter((x: IRepairLineItem) => (x && x.id && x.id !== 0) ? true : false);

                    if (diff.items && diff.items.length === 0)
                        delete diff.items;

                    if (Object.keys(diff).length === 0) {
                        await this.saveRequest();
                    } 
                    else {
                        this.setState({
                            data,
                            difference: diff
                        });
                    }
                } 
                else {
                    
                    await this.saveRequest();
                }
            };
        };
    }

    private checkDefectItemDifference = async (): Promise<void> => {
        if (hasPayload(this.props.updateRepairLineItemCurrentState)) {
            
                const data = this.props.updateRepairLineItemCurrentState.payload.response;
                const current = this.state.original;
                // console.log(data, current)

                if (data.modified !== current?.modified) {
                    let diff = compareTwoObjects(current, data);
                    // console.log(diff)

                    if (Object.keys(diff).length === 0) {
                        await this.saveDefectItem();
                    } 
                    else {
                        this.setState({
                            // data,
                            original: data,
                            defectItemDifference: diff
                        });
                    }
                } 
                else {
                    await this.saveDefectItem();
                }
            
        };
    }

    private handleRepairLineVersionHistory = async (data: IRepairLine): Promise<void> => {
        if (hasPayload(this.props.token)) {
            this.props.getVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    ID: data.id,
                    Type: "Repair_Line"
                }
            });
        }
        await this.setState({ repairLineVersionHistoryPopup: true });
    };

    private handleVersionHistoryPopupClose = (): void => {
        this.setState({ repairLineVersionHistoryPopup: false });
    };

    private handleRepairLineVersionHistoryComponent = async (data: IRepairLineItem): Promise<void> => {
        if (hasPayload(this.props.token)) {
            this.props.getVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    ID: data.id,
                    Type: "Repair_Line_Component"
                }
            });
        }
        await this.setState({ repairLineVersionHistoryComponentPopup: true });
    };

    private handleVersionHistoryComponentPopupClose = (): void => {
        this.setState({ repairLineVersionHistoryComponentPopup: false });
    };

    private handleMechLogsVersionHistoryClick = async (name: string, e: any): Promise<void> => {
        // console.log(e.row.data)
        if (hasPayload(this.props.token)) {
            this.props.getMechanicLogsVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    ID: e.row.data.id,
                    Type: "Defect"
                }
            });
        }
        await this.setState({ mechLogsVersionHistoryPopup: true });
    }

    private handleMechLogsVersionHistoryPopupClose = (): void => {
        this.setState({ mechLogsVersionHistoryPopup: false });
    };

    private onLogsOpen = (): void => {
        this.setState({
            logsPopup: true
        });
    };

    private onLogsClose = (): void => {
        this.setState({
            logsPopup: false
        });
    };

    private onPartExpandSave = (): void => {
        const { popUpIndex, original, expandPart, editPart } = this.state;

        if (popUpIndex === undefined || expandPart === undefined || !editPart || !original) {
            return;
        }

        const updatedItems = [...original.parts];
        const findIndex = updatedItems[expandPart];

        if (findIndex) {
            updatedItems[expandPart] = editPart;
        } else {
            updatedItems.push(editPart);
        }

        this.setState({
            ...this.state,
            original: {
                ...original,
                parts: updatedItems,
            },
            expandPart: undefined,
            editPart: undefined,
        });

    };

    private onPartExpandClose = (): void => {
        const selection = window.confirm(ClosePopUpText);

        if (selection) {
            this.setState({
                ...this.state,
                expandPart: undefined,
                editPart: undefined
            });
        }
    };

    private handleComponentClose = (): void => {
        const selection = window.confirm(ClosePopUpText);
        if (selection) {
            this.setState({
                ...this.state,
                popUpIndex: undefined,
                original: undefined,
                popupImgs: [],
                subTableErrors: {},
                serverErrorSaveDefectItem: ""
            });
        }
    };

    private onImageEdit = (base64String: string, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], base64String };
        popupImgs[subIndex] = picture;

        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private handleImageName = (name: string, value: string, index: number, subIndex: number): void => {
        const popupImgs = [...this.state.popupImgs];

        const picture = { ...popupImgs[subIndex], [name]: value };
        popupImgs[subIndex] = picture;


        this.setState({
            ...this.state,
            popupImgs: [
                ...popupImgs
            ]
        });
    };

    private handleMeterHours = (name: string, value: string, index: number): void => {
        if (numberAndDecimalCheck(value)) {
            let errors = this.state.subTableErrors;

            if (value.length > 0) {
                if (errors[name])
                    delete errors[name];
            } else {
                errors[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };

            if (this.state.original)
                this.setState({
                    ...this.state,
                    original: {
                        ...this.state.original,
                        [name]: value
                    },
                    subTableErrors: errors
                });
        }
    };

    private handleAssignedMech = async (event: unknown, values: ILookupMechanic[]): Promise<void> => {
        // console.log(values)
        const data = { ...this.state.original };
        const mechs: number[] = data.assigned_Mech && data.assigned_Mech !== null ? data.assigned_Mech.split(",").map(Number) : [];
        const mechsName: string[] = data.mechanic_Names && data.mechanic_Names !== null ? data.mechanic_Names.split(",").map(String) : [];

        await values.forEach((q) => {
            const index = mechs.length > 0 ? mechs.findIndex((x) => x === q.id) : -1;
            if (index < 0) {
                mechs.push(q.id);
                mechsName.push(q.name);
            }
        });

        data.assigned_Mech = mechs.join(",");
        data.mechanic_Names = mechsName.join(",");

        let errors = this.state.subTableErrors;
        if(data.status === ComponentStatusList[3].name) {
            if (data.assigned_Mech.length > 0) {
                if (errors["assigned_Mech"])
                    delete errors["assigned_Mech"];
            } else {
                errors["assigned_Mech"] = { key: "assigned_Mech", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };
        }

        if (this.state.original)
            this.setState({
                ...this.state,
                original: {
                    ...this.state.original,
                    assigned_Mech: data.assigned_Mech,
                    mechanic_Names: data.mechanic_Names
                },
                subTableErrors: errors
            });
    };

    private handleAssignedLubeDriver = async (event: unknown, values: IDriverList[]): Promise<void> => {
        // console.log(values)
        const data = { ...this.state.original };
        const lubeDriversName: string[] = data.lube_Driver && data.lube_Driver !== null ? data.lube_Driver.split(",").map(String) : [];

        await values.forEach((q) => {
            const index = lubeDriversName.length > 0 ? lubeDriversName.findIndex((x) => x === q.upn) : -1;
            if (index < 0) {
                lubeDriversName.push(q.upn);
            }
        });

        data.lube_Driver = lubeDriversName.join(",");

        let errors = this.state.subTableErrors;
        if(data.status === ComponentStatusList[3].name) {
            if (data.lube_Driver.length > 0) {
                if (errors["lube_Driver"])
                    delete errors["lube_Driver"];
            } else {
                errors["lube_Driver"] = { key: "lube_Driver", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            };
        }

        if (this.state.original)
            this.setState({
                ...this.state,
                original: {
                    ...this.state.original,
                    lube_Driver: data.lube_Driver
                },
                subTableErrors: errors
            });
    };

    // private handleAssignedDriver = async (event: unknown, values: IDriverList): Promise<void> => {
    
    //     const lubeDriversList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.lube_drivers : [];
    //     const luberDriver = lubeDriversList.find(x => x.display_Name === values.display_Name)

    //     if (this.state.original)
    //         this.setState({
    //             ...this.state,
    //             original: {
    //                 ...this.state.original,
    //                 lube_Driver: luberDriver?.upn ? luberDriver.upn : ''
    //             },
    //         });
    // };

    private handleAssignedLubeDriverRemove = async (name: string): Promise<void> => {
        if (hasPayload(this.props.getLookup)) {
            const data = { ...this.state.original };
            // console.log(name)

            const payload: IDriverList[] = this.props.getLookup.payload.response.lube_drivers;
            const ind = payload.findIndex(q => q.display_Name === name);

            const lubeDriversName: string[] = data.lube_Driver && data.lube_Driver !== null ? data.lube_Driver.split(",").map(String) : [];
            // console.log(lubeDriversName, ind)

            const index = lubeDriversName.length > 0 ? lubeDriversName.findIndex((x) => x === payload[ind].upn) : -1;

            if (index >= 0)
                lubeDriversName.splice(index, 1);

            data.lube_Driver = lubeDriversName.join(",");

            let errors = this.state.subTableErrors;
            if(data.status === ComponentStatusList[3].name) {
                if (data.lube_Driver.length > 0) {
                    if (errors["lube_Driver"])
                        delete errors["lube_Driver"];
                } else {
                    errors["lube_Driver"] = { key: "lube_Driver", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            }

            if (this.state.original)
                this.setState({
                    ...this.state,
                    original: {
                        ...this.state.original,
                        lube_Driver: data.lube_Driver
                    },
                    subTableErrors: errors
                });
        }
    };

    private handleAssignedMechRemove = async (name: string): Promise<void> => {
        if (hasPayload(this.props.getLookup)) {
            const data = { ...this.state.original };

            const payload: ILookupMechanic[] = this.props.getLookup.payload.response.mechanics;
            const ind = payload.findIndex(q => q.name === name);

            // const mechs = data.assigned_Mech !== null ? data.assigned_Mech.split(",").map(Number) : [];
            const mechs: number[] = data.assigned_Mech && data.assigned_Mech !== null ? data.assigned_Mech.split(",").map(Number) : [];

            const index = mechs.length > 0 ? mechs.findIndex((x) => x === payload[ind].id) : -1;

            if (index >= 0)
                mechs.splice(index, 1);

            data.assigned_Mech = mechs.join(",");

            let errors = this.state.subTableErrors;
            if(data.status === ComponentStatusList[3].name) {
                if (data.assigned_Mech.length > 0) {
                    if (errors["assigned_Mech"])
                        delete errors["assigned_Mech"];
                } else {
                    errors["assigned_Mech"] = { key: "assigned_Mech", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                };
            }

            if (this.state.original)
                this.setState({
                    ...this.state,
                    original: {
                        ...this.state.original,
                        assigned_Mech: data.assigned_Mech
                    },
                    subTableErrors: errors
                });
        }
    };

    private getAssignedMechanics = (ids: string): ILookupMechanic[] => {
        if (hasPayload(this.props.getLookup)) {

            const response: ILookupMechanic[] = [];
            const assigned = ids !== null ? ids.split(",").map(Number) : [];
            const mechs: ILookupMechanic[] = this.props.getLookup.payload.response.mechanics;

            assigned.forEach((x) => {
                const index = mechs.findIndex(q => q.id === x);
                if (index >= 0)
                    response.push(mechs[index]);
            });

            return response;
        }
        return [];
    };

    private getAssignedLubeDrivers = (upns: string): IDriverList[] => {
        if (hasPayload(this.props.getLookup)) {

            const response: IDriverList[] = [];
            const assigned = upns !== null ? upns.split(",").map(String) : [];
            const lubeDriversList = this.props.getLookup.payload.response.lube_drivers;

            assigned.forEach((x) => {
                const index = lubeDriversList.findIndex(q => q.upn === x);
                if (index >= 0)
                    response.push(lubeDriversList[index]);
            });

            return response;
        }
        return [];
    };


    private handlePopupSave = async(): Promise<void> => {
        const { popUpIndex, original, } = this.state;

        if (popUpIndex === undefined || !original) {
            return;
        }

        if (hasPayload(this.props.token)) {
            if (original.id > 0) {
                this.props.updateRepairLineItemCurrentStateRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: original.id
                    }
                });
            };

            if ((original.id === 0)) {
                this.saveDefectItem();
            };
        }
    };

    private saveDefectItem = (): void => {
        const { popUpIndex, original, data } = this.state;

        if (popUpIndex === undefined || !original) {
            return;
        }

        if(data.id > 0) {
            original.pictures = this.state.popupImgs;
            if(hasPayload(this.props.token)) {
                let request : ISaveRepairLineItem = {
                    ID: original.id,
                    Repair_Line_ID: data.id,
                    Attachment_ID: (original.attachmentCheckbox === "Yes" || (original.item_Attachment_ID && original.item_Attachment_ID > 0)) ? original.item_Attachment_ID : undefined,
                    Priority: original.priority,
                    Status: original.status,
                    Images: original.images,
                    Major_Component_Replacement: original.major_Component_Replacement,
                    Major_Type: original.major_Type,
                    Component_Repaired_Location: original.component_Repaired_Location,
                    OEM_Part: original.oeM_Part,
                    Exchange_Type: original.exchange_Type,
                    Condition: original.condition,
                    Warranty_Details: original.warranty_Details,
                    Vendor: original.vendor,
                    BER_Details: original.beR_Details,
                    Serial_No: original.serial_No,
                    Hours: original.hours,
                    Type: original.type,
                    New_Major_Component_Replacement_Notes: original.new_Major_Component_Replacement_Notes,
                    Major_Component_Replacement_Notes: original.major_Component_Replacement_Notes,
                    Mob_Demob: original.mob_Demob,
                    Damaged: original.damaged,
                    Damaged_Notes: original.damaged_Notes,
                    Warranty: original.warranty,
                    Component_Code: original.component_Code,
                    Meter_Reading: original.meter_Reading,
                    Pictures: original.pictures,
                    work_Order_No: original.work_Order_No,
                    Defect_Item: original.defect_Item,
                    Issue: original.issue,
                    Repair_Type: original.repair_Type,
                    Assigned_Mech: original.assigned_Mech,
                    Lube_Driver: original.lube_Driver,
                    Manager_Instructions: original.manager_Instructions,
                    Parts_Instructions: original.parts_Instructions,
                    Send_Email: original.send_Email,
                    Mechanic_Comments: original.mechanic_Comments,
                    Winter_Repair: original.winter_Repair,
                    Repair_Location: original.move_To_Shop === "Yes" ? "Shop" : original.repair_Location,
                    Modified_By: this.props.token.payload.response.upn,
                }
                // console.log(request)
                this.props.saveRepairLineItemRequest({
                    token: this.props.token.payload.response.token,
                    request
                });
            }
        }
        else {
            const updatedItems = [...data.items];
            original.pictures = this.state.popupImgs;
            original.modified = new Date().toISOString();
            original.modified_By = hasPayload(this.props.token) ? this.props.token.payload.response.upn : ""
            updatedItems[popUpIndex] = original;
    
            this.setState({
                data: {
                    ...data,
                    items: updatedItems,
                },
                popUpIndex: undefined,
                original: undefined,
                defectItemDifference: undefined,
                popupImgs: []
            });
        }

    }

    private handleComponentClick = (popupData: IRepairLineItem, popUpIndex: number): void => {

        let subTableErrors = this.state.subTableErrors;
        const { data } = this.state;
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        // console.log(unit)
        const usedPartsUnit = unit && unit.status === "Used Parts" ? true : false;

        if (popupData.status === ComponentStatusList[3].name && this.state.data.unit_ID > 0 && !usedPartsUnit) {
            if ((popupData.meter_Reading === undefined) || (popupData.meter_Reading === null)) {
                subTableErrors["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
        }

        if (popupData.major_Component_Replacement === "Yes" && popupData.status === ComponentStatusList[3].name) {
            if (popupData.serial_No === undefined || popupData.serial_No === null || popupData.serial_No === "") {
                subTableErrors["serial_No"] = { key: "serial_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
            if (popupData.hours === undefined || popupData.hours === null) {
                subTableErrors["hours"] = { key: "hours", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
            // if (popupData.type === undefined || popupData.type === null || popupData.type === "") {
            //     subTableErrors["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            // }
            // if (popupData.new_Major_Component_Replacement_Notes === undefined || popupData.new_Major_Component_Replacement_Notes === null || popupData.new_Major_Component_Replacement_Notes === "") {
            //     subTableErrors["new_Major_Component_Replacement_Notes"] = { key: "new_Major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            // }
            // if (popupData.major_Component_Replacement_Notes === undefined || popupData.major_Component_Replacement_Notes === null || popupData.major_Component_Replacement_Notes === "") {
            //     subTableErrors["major_Component_Replacement_Notes"] = { key: "major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            // }
        }

        if (popupData.major_Component_Replacement === "Yes") {
            // console.log(popupData.major_Type)
            if (popupData.major_Type === undefined || popupData.major_Type === null || popupData.major_Type === "") {
                subTableErrors["major_Type"] = { key: "major_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
        }

        if (popupData.damaged === "Yes" && popupData.status === ComponentStatusList[3].name) {
            if (popupData.damaged_Notes === undefined || popupData.damaged_Notes === null || popupData.damaged_Notes === "") {
                subTableErrors["damaged_Notes"] = { key: "damaged_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
        }

        this.setState({
            ...this.state,
            popUpIndex,
            subTableErrors,
            original: popupData,
            popupImgs: popupData.pictures === null ? [] : popupData.pictures
        });
    };

    private onPartDelete = (index: number, partIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS = [...this.state.data.items];

            iS[index].parts.splice(partIndex, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    items: iS
                }
            });
        }
    };

    private handleExpand = (expandPart: number): void => {
        if (this.state.expandPart === expandPart) {
            this.setState({
                expandPart: undefined,
                editPart: undefined
            });
        } else {

            let data = undefined;
            const original = this.state.original;
            if (this.state.popUpIndex !== undefined && original) {
                data = original.parts[expandPart];
            };

            this.setState({
                ...this.state,
                expandPart,
                editPart: data
            });
        }
    };

    private onPartAdd = (index: number): void => {
        const data = this.state.original;

        if (data) {
            const editPart = {
                id: 0,
                status: PartStatus[0].name,
                component_ID: data.id,
                part_Name: "",
                part_Number: "",
                quantity: 0,
                driver: "",
                created_By: "",
                modified_By: "",
                created: "",
                modified: ""
            };

            this.setState({
                ...this.state,
                editPart,
                expandPart: data.parts.length,
            });
        }
    };

    private onPartChange = (name: string, value: string, index: number, subIndex: number): void => {
        if (index !== undefined) {
            let part = this.state.editPart;

            if (part !== undefined) {
                part = { ...part, [name]: value }
                this.setState({
                    ...this.state,
                    editPart: {
                        ...this.state.editPart,
                        ...part
                    }
                });
            }
        }
    };

    private handleDelete = async (index: number): Promise<void> => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS = { ...this.state.data };
            iS.items.splice(index, 1);

            await this.setState({
                ...this.state,
                data: iS,
                original: undefined,
                popUpIndex: undefined
            });
        }
    };

    private handleUnitDetails = (): void => {
        this.setState({
            unitDetails: !this.state.unitDetails
        });
    };

    private handleAttachmentDetails = (id: number): void => {
        window.open(ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT + "?id=" + id.toString());
    };

    private handleRepairHistory = async (data: any): Promise<void> => {
        if (data.unit_ID > 0) {
            if (hasPayload(this.props.token)) {
                this.props.getRepairLineHistory({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: data.unit_ID,
                        Type: "Unit"
                    }
                });
            }
        } else if (data.attachment_ID > 0) {
            if (hasPayload(this.props.token)) {
                this.props.getRepairLineHistory({
                    token: this.props.token.payload.response.token,
                    request: {
                        ID: data.attachment_ID,
                        Type: "Attachment"
                    }
                });
            }
        }
        await this.setState({
            historyPopup: true
        });
    };

    private handleHistoryPopupClose = (): void => {
        this.setState({ historyPopup: false });
    };

    private goToActive = (): void => {
        if (this.state.activeError !== undefined)
            callRouteWithQueryString({
                route: this.props,
                search: { id: this.state.activeError.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE
            });
    };

    private handleAdd = (): void => {
        const original = {
            id: 0,
            unit_ID: 0,
            // attachemnt_ID: undefined,
            item_Attachment_ID: undefined,
            repair_Line_ID: 0,
            comments: "",
            component: "",
            major_Component_Replacement: "No",
            mob_Demob: "No",
            damaged: "No",
            warranty: "No",
            priority: PriorityList[2].name,
            status: ComponentStatusList[0].name,
            description: "",
            pictures: [],
            images: "",
            created_By: "",
            modified_By: "",
            created: "",
            modified: "",
            component_Code: "",
            foreman_Comments: "",
            socT_Tracking: "",
            mechanic_Logs: [],
            parts: [],
            work_Order_No: "",
            repair_Type: "Field",
            defect_Item: "",
            issue: "",
            lube_Repair: "No",
            lube_Driver: "",
            manager_Instructions: "",
            parts_Instructions: "",
            assigned_Mech: "",
            mechanic_Names: "",
            mechanic_Comments: "",
            send_Email: "No",
            winter_Repair: "No",
            move_To_Shop: "No",
        };

        let errors = this.state.subTableErrors;
        errors["defect_Item"] = { key: "defect_Item", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

        const items = [...this.state.data.items];
        // items.push(original);

        this.setState({
            ...this.state,
            original,
            data: {
                ...this.state.data,
                items
            },
            subTableErrors: errors,
            popUpIndex: (this.state.data.items.length)
        });
    };

    private onImgDelete = (name: string, index: number, imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const original = this.state.original;
            const popupImgs = [...this.state.popupImgs];
            if (original) {
                const imgPaths = original.images.split(",");

                imgPaths.splice(imgPaths.findIndex(x => x === name), 1);
                original.images = imgPaths.join(",");
                popupImgs.splice(imgIndex, 1);

                this.setState({
                    ...this.state,
                    original: {
                        ...original
                    },
                    popupImgs: [
                        ...popupImgs
                    ]
                });
            }
        }
    };

    private handleSubChange = (name: string, value: string, index?: number | undefined): void => {

        if (index !== undefined) {
            const original = this.state.original;
            let errors = this.state.subTableErrors;
            let item_Attachment_ID = original?.item_Attachment_ID;
            const { data } = this.state;
            const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
            const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
            // console.log(unit)
            const usedPartsUnit = unit && unit.status === "Used Parts" ? true : false;
            
            if (original) {
                if(name === 'attachmentCheckbox') {
                    if(value === "Yes") {
                        errors["attachment_No"] = { key: "attachment_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (errors["meter_Reading"])
                            delete errors["meter_Reading"];
                    } else if(value === 'No') {
                        errors["defect_Item"] = { key: "defect_Item", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if(original.status === ComponentStatusList[3].name && !usedPartsUnit) {
                            if((original.meter_Reading === undefined) || (original.meter_Reading === null)) {
                                errors["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            }
                        }
                    }
                }
                if(name === "lube_Repair") {
                    if(value === "Yes") {
                        if (errors["assigned_Mech"])
                            delete errors["assigned_Mech"];
                        if(original.status === ComponentStatusList[3].name) {
                            errors = this.errorChecker("lube_Driver", original.lube_Driver ? original.lube_Driver : '', errors);
                        }
                    }
                    else {
                        if (errors["lube_Driver"])
                            delete errors["lube_Driver"];
                        if(original.status === ComponentStatusList[3].name) {
                            errors = this.errorChecker("assigned_Mech", original.assigned_Mech ? original.assigned_Mech : '', errors);
                        }
                    }
                }
                if (name === "defect_Item") {
                    // console.log(value)
                    if(original.attachmentCheckbox === "Yes" || (original.item_Attachment_ID && original.item_Attachment_ID> 0)) {
                        const attachments = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.attachments : [];
                        const attachment = attachments.find((x: any) => x.attachment_No === value);
                        item_Attachment_ID = attachment ? attachment?.id : original.item_Attachment_ID;
                    }
                    if (value.length > 0) {
                        if (errors[name])
                            delete errors[name];
                            delete errors["attachment_No"];
                    } else {
                        if (errors[name] === undefined) {
                            errors[name] = { key: name, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            errors["attachment_No"] = { key: "attachment_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        }
                    };
                };

                if ((name === "status") && (value === ComponentStatusList[3].name)) {
                    if(original.major_Component_Replacement === "Yes") {
                        // if (errors["type"] === undefined)
                        //     errors = this.errorChecker("type", original.type ? original.type : '', errors);
                        if (errors["serial_No"] === undefined)
                            errors = this.errorChecker("serial_No", original.serial_No ? original.serial_No : '', errors);
                        if (errors["hours"] === undefined)
                            errors = this.errorChecker("hours", original.hours ? original.hours.toString() : '', errors);
                        if (errors["major_Type"] === undefined)
                            errors = this.errorChecker("major_Type", original.major_Type ? original.major_Type : '', errors);

                        if (original.major_Type === "Repair and Return to Unit") {
                            if(errors["component_Repaired_Location"] === undefined) {
                                errors = this.errorChecker("component_Repaired_Location", original.component_Repaired_Location ? original.component_Repaired_Location : '', errors);
                            }
                            if(errors["oeM_Part"] === undefined) {
                                errors = this.errorChecker("oeM_Part", original.oeM_Part ? original.oeM_Part : '', errors);
                            }
                        }

                        if (original.major_Type === "Exchange") {
                            if(errors["exchange_Type"] === undefined) {
                                errors = this.errorChecker("exchange_Type", original.exchange_Type ? original.exchange_Type : '', errors);
                            }
                            if(errors["condition"] === undefined) {
                                errors = this.errorChecker("condition", original.condition ? original.condition : '', errors);
                            }
                            if(original.exchange_Type === "External Purchase" && errors["vendor"] === undefined) {
                                errors = this.errorChecker("vendor", original.vendor ? original.vendor : '', errors);
                            }
                        }
                        
                        if (errors["new_Major_Component_Replacement_Notes"] === undefined)
                            errors = this.errorChecker("new_Major_Component_Replacement_Notes", original.new_Major_Component_Replacement_Notes ? original.new_Major_Component_Replacement_Notes : '', errors);
                        // if (errors["major_Component_Replacement_Notes"] === undefined)
                        //     errors = this.errorChecker("major_Component_Replacement_Notes", original.major_Component_Replacement_Notes ? original.major_Component_Replacement_Notes : '', errors);
                    } 
                    
                    if(original.damaged === "Yes") {
                        if (errors["damaged_Notes"] === undefined)
                            errors = this.errorChecker("damaged_Notes", original.damaged_Notes ? original.damaged_Notes : '', errors);
                    }

                    if (((original.meter_Reading === undefined) || (original.meter_Reading === null)) && (data.unit_ID > 0) && (original.item_Attachment_ID === undefined || original.item_Attachment_ID === 0 || original.item_Attachment_ID === null) && !usedPartsUnit) {
                        errors["meter_Reading"] = { key: "meter_Reading", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        if (errors["meter_Reading"])
                            delete errors["meter_Reading"];
                    }

                    if (((original.component_Code === undefined) || (original.component_Code === null) || (original.component_Code === "")) && (data.unit_ID > 0 || data.attachment_ID > 0)) {
                        errors["code"] = { key: "code", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        if (errors["code"])
                            delete errors["code"];
                    }

                    if (original.repair_Type !== "Lube" && ((original.assigned_Mech === undefined) || (original.assigned_Mech === null) || (original.assigned_Mech === "")) && (data.unit_ID > 0 || data.attachment_ID > 0)) {
                        errors["assigned_Mech"] = { key: "assigned_Mech", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        if (errors["assigned_Mech"])
                            delete errors["assigned_Mech"];
                    }

                    if (original.repair_Type === "Lube" && ((original.lube_Driver === undefined) || (original.lube_Driver === null) || (original.lube_Driver === "")) && (data.unit_ID > 0 || data.attachment_ID > 0)) {
                        errors["lube_Driver"] = { key: "lube_Driver", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        if (errors["lube_Driver"])
                            delete errors["lube_Driver"];
                    }
                }
                else if ((name === "status") && (value !== ComponentStatusList[3].name)) {
                    original.meter_Reading = undefined;
                    if (errors["meter_Reading"])
                        delete errors["meter_Reading"];

                    // original.component_Code = "";
                    if (errors["code"])
                        delete errors["code"];

                    // original.assigned_Mech = "";
                    if (errors["assigned_Mech"])
                        delete errors["assigned_Mech"];

                    if (errors["lube_Driver"])
                        delete errors["lube_Driver"];

                    if(original.major_Component_Replacement === "Yes") {
                        // if (errors["type"])
                        //     delete errors["type"];

                        if(errors["component_Repaired_Location"])
                            delete errors["component_Repaired_Location"];
                        if(errors["oeM_Part"])
                            delete errors["oeM_Part"];
                        if(original.major_Type === "Exchange") {
                            if(errors["exchange_Type"] === undefined) {
                                errors = this.errorChecker("exchange_Type", original.exchange_Type ? original.exchange_Type : '', errors);
                            }
                        }
                        if(original.major_Type !== "Exchange") {
                            if(errors["exchange_Type"])
                                delete errors["exchange_Type"];
                        }
                        if(errors["condition"])
                            delete errors["condition"];
                        if(errors["vendor"])
                            delete errors["vendor"];

                        if (errors["serial_No"])
                            delete errors["serial_No"];

                        if (errors["hours"])
                            delete errors["hours"];

                        // if (errors["major_Type"])
                        //     delete errors["major_Type"];

                        if (errors["new_Major_Component_Replacement_Notes"])
                            delete errors["new_Major_Component_Replacement_Notes"];

                        // if (errors["major_Component_Replacement_Notes"])
                        //     delete errors["major_Component_Replacement_Notes"];
                    }

                    if(original.damaged === "Yes") {
                        if (errors["damaged_Notes"])
                            delete errors["damaged_Notes"];
                    }
                };

                if(name === "component_Code") {
                    if(value.length > 0) {
                        if (errors["code"])
                            delete errors["code"];
                    } else if(original.status === ComponentStatusList[3].name) {
                        errors["code"] = { key: "code", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }

                if (name === "major_Component_Replacement") {
                    if (value === "Yes" && (original.status === ComponentStatusList[3].name)) {
                        // if (errors["type"] === undefined)
                        //     errors["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (errors["serial_No"] === undefined)
                            errors["serial_No"] = { key: "serial_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (errors["hours"] === undefined)
                            errors["hours"] = { key: "hours", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (errors["major_Type"] === undefined)
                            errors["major_Type"] = { key: "major_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        if (errors["new_Major_Component_Replacement_Notes"] === undefined)
                            errors["new_Major_Component_Replacement_Notes"] = { key: "new_Major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        // if (errors["major_Component_Replacement_Notes"] === undefined)
                        //     errors["major_Component_Replacement_Notes"] = { key: "major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        // original.type = undefined;
                        // if (errors["type"])
                        //     delete errors["type"];

                        if(value === "Yes") {
                            if (errors["major_Type"] === undefined)
                                errors["major_Type"] = { key: "major_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        }
                        if(value === "No") {
                            if (errors["major_Type"])
                                delete errors["major_Type"];
                            if (errors["exchange_Type"])
                                delete errors["exchange_Type"];
                            if (errors["condition"])
                                delete errors["condition"];
                            if (errors["component_Repaired_Location"])
                                delete errors["component_Repaired_Location"];
                            if (errors["oeM_Part"])
                                delete errors["oeM_Part"];
                            if (errors["vendor"])
                                delete errors["vendor"];
                        }

                        // original.serial_No = undefined;
                        if (errors["serial_No"])
                            delete errors["serial_No"];

                        // original.hours = undefined;
                        if (errors["hours"])
                            delete errors["hours"];

                        // original.new_Major_Component_Replacement_Notes = undefined;
                        if (errors["new_Major_Component_Replacement_Notes"])
                            delete errors["new_Major_Component_Replacement_Notes"];

                        // original.major_Component_Replacement_Notes = undefined;
                        // if (errors["major_Component_Replacement_Notes"])
                        //     delete errors["major_Component_Replacement_Notes"];
                    }
                };

                if (name === "damaged") {
                    if (value === "Yes" && (original.status === ComponentStatusList[3].name)) {
                        if (errors["damaged_Notes"] === undefined)
                            errors["damaged_Notes"] = { key: "damaged_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    } else {
                        // original.damaged_Notes = undefined;
                        if (errors["damaged_Notes"])
                            delete errors["damaged_Notes"];
                    }
                };

                // if (name === "type" && (original.status === ComponentStatusList[3].name)) {
                //     if (value.length > 0) {
                //         if (errors["type"])
                //             delete errors["type"];
                //     } else {
                //         errors["type"] = { key: "type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                //     }
                // }

                if (name === "major_Type") {
                    if (value.length > 0) {
                        if (errors["major_Type"])
                            delete errors["major_Type"];

                        if(value === "Repair and Return to Unit" ) {
                            if(original.status === ComponentStatusList[3].name) {
                                if (errors["component_Repaired_Location"] === undefined)
                                    errors["component_Repaired_Location"] = { key: "component_Repaired_Location", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                                if (errors["oeM_Part"] === undefined)
                                    errors["oeM_Part"] = { key: "oeM_Part", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            }

                            delete errors["exchange_Type"];
                            delete errors["condition"];
                            delete errors["vendor"];
                        }
                        if(value === "Exchange") {
                            if (errors["exchange_Type"] === undefined)
                                errors["exchange_Type"] = { key: "exchange_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                            if(original.status === ComponentStatusList[3].name) {
                                if (errors["condition"] === undefined)
                                    errors["condition"] = { key: "vendor", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                                // if (errors["vendor"] === undefined)
                                //     errors["vendor"] = { key: "vendor", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            }

                            delete errors["component_Repaired_Location"];
                            delete errors["oeM_Part"];
                        }
                        if(value === "BER") {
                            delete errors["exchange_Type"];
                            delete errors["condition"];
                            delete errors["component_Repaired_Location"];
                            delete errors["oeM_Part"];
                            delete errors["vendor"];
                        }
                    } else {
                        errors["major_Type"] = { key: "major_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }

                if(name === "component_Repaired_Location" && original.major_Type === "Repair and Return to Unit") {
                    if (value.length > 0) {
                        if (errors["component_Repaired_Location"])
                            delete errors["component_Repaired_Location"];
                    } else {
                        if(original.status === ComponentStatusList[3].name) {
                            errors["component_Repaired_Location"] = { key: "component_Repaired_Location", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        }
                    }
                }
                if(name === "oeM_Part" && original.major_Type === "Repair and Return to Unit") {
                    if (value.length > 0) {
                        if (errors["oeM_Part"])
                            delete errors["oeM_Part"];
                    } else {
                        if(original.status === ComponentStatusList[3].name) {
                            errors["oeM_Part"] = { key: "oeM_Part", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        }
                    }
                }
                if(name === "exchange_Type" && original.major_Type === "Exchange") {
                    if (value.length > 0) {
                        if (errors["exchange_Type"])
                            delete errors["exchange_Type"];
                        if(value === "External Purchase" && original.status === ComponentStatusList[3].name) {
                            errors = this.errorChecker("vendor", original.vendor ? original.vendor : '', errors);
                        }
                        else {
                            delete errors["vendor"];
                        }
                    } else {
                        errors["exchange_Type"] = { key: "exchange_Type", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }
                if(name === "condition" && original.major_Type === "Exchange") {
                    if (value.length > 0) {
                        if (errors["condition"])
                            delete errors["condition"];
                    } else {
                        if(original.status === ComponentStatusList[3].name) {
                            errors["condition"] = { key: "condition", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        }
                    }
                }
                if(name === "vendor" && original.major_Type === "Exchange") {
                    if (value.length > 0) {
                        if (errors["vendor"])
                            delete errors["vendor"];
                    }
                    else {
                        if(original.exchange_Type === "External Purchase" && original.status === ComponentStatusList[3].name) {
                            errors = this.errorChecker("vendor", value ? value : '', errors);
                        }
                    }
                }

                if (name === "serial_No" && (original.status === ComponentStatusList[3].name)) {
                    if (value.length > 0) {
                        if (errors["serial_No"])
                            delete errors["serial_No"];
                    } else {
                        errors["serial_No"] = { key: "serial_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }

                if (name === "hours" && (original.status === ComponentStatusList[3].name)) {
                    if (value.length > 0) {
                        if (errors["hours"])
                            delete errors["hours"];
                    } else {
                        errors["hours"] = { key: "hours", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }

                if (name === "new_Major_Component_Replacement_Notes" && (original.status === ComponentStatusList[3].name)) {
                    if (value.length > 0) {
                        if (errors["new_Major_Component_Replacement_Notes"])
                            delete errors["new_Major_Component_Replacement_Notes"];
                    } 
                    else {
                        errors["new_Major_Component_Replacement_Notes"] = { key: "new_Major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }

                // if (name === "major_Component_Replacement_Notes" && (original.status === ComponentStatusList[3].name)) {
                //     if (value.length > 0) {
                //         if (errors["major_Component_Replacement_Notes"])
                //             delete errors["major_Component_Replacement_Notes"];
                //     } else {
                //         errors["major_Component_Replacement_Notes"] = { key: "major_Component_Replacement_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                //     }
                // }

                if (name === "damaged_Notes" && (original.status === ComponentStatusList[3].name)) {
                    if (value.length > 0) {
                        if (errors["damaged_Notes"])
                            delete errors["damaged_Notes"];
                    } else {
                        errors["damaged_Notes"] = { key: "damaged_Notes", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    }
                }
                // console.log(errors);
                this.setState({
                    ...this.state,
                    original: {
                        ...original,

                        [name]: name === "hours" ? Number(value) : value,
                        defect_Item: (name === "attachmentCheckbox" && value) ? "": (name==="defect_Item") ? value : original.defect_Item,
                        item_Attachment_ID: (name === "attachmentCheckbox" && value) ? undefined : (name==="defect_Item") ? item_Attachment_ID : original.item_Attachment_ID,
                        // type: name === "major_Component_Replacement" && value === "No" ? undefined : name === "type"  ? value : original.type,
                        major_Type: name === "major_Component_Replacement" && value === "No" ? undefined : name === "major_Type"  ? value : original.major_Type,
                        component_Repaired_Location: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "component_Repaired_Location"  ? value : original.component_Repaired_Location,
                        oeM_Part: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "oeM_Part"  ? value : original.oeM_Part,
                        vendor: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "vendor"  ? value : original.vendor,
                        exchange_Type: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "exchange_Type"  ? value : original.exchange_Type,
                        condition: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "condition"  ? value : original.condition,
                        warranty_Details: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "warranty_Details"  ? value : original.warranty_Details,
                        beR_Details: ((name === "major_Component_Replacement" && value === "No") || (name === "major_Type")) ? undefined : name === "beR_Details"  ? value : original.beR_Details,
                        serial_No: name === "major_Component_Replacement" && value === "No" ? undefined : name === "serial_No"  ? value : original.serial_No,
                        hours: name === "major_Component_Replacement" && value === "No" ? undefined : name === "hours" ? Number(value) : original.hours,
                        new_Major_Component_Replacement_Notes: name === "major_Component_Replacement" && value === "No" ? undefined : name === "new_Major_Component_Replacement_Notes" ? value : original.new_Major_Component_Replacement_Notes,
                        // major_Component_Replacement_Notes: name === "major_Component_Replacement" && value === "No" ? undefined : name === "major_Component_Replacement_Notes" ? value : original.major_Component_Replacement_Notes,
                        damaged_Notes: name === "damaged" && value === "No" ? undefined : name === "damaged_Notes"  ? value : original.damaged_Notes,
                        repair_Type: name === "lube_Repair" && value === "Yes" ? "Lube" :  name === "lube_Repair" && value === "No" ? "Field" : original.repair_Type,
                        lube_Driver: (name === "lube_Repair" && value === "No") ?  "" : original.lube_Driver,
                        assigned_Mech: (name === "lube_Repair" && value === "Yes") ? "" : original.assigned_Mech,
                    },
                    subTableErrors: errors
                });
            }
        }
    };

    private pictureUpload = async (index: number, event: any): Promise<void> => {

        this.setState({ imageUploadLoading: true });

        const files = event;
        const original = this.state.original;
        const popupImgs = [...this.state.popupImgs];

        if (original) {
            if (files !== null) {

                await Promise.all(Object.values(files).map(async (x: any) => {
                    const imgId = uuidv4();
                    let isDuplicateDescription = false;
                    let description = x.name;
                    const base64String = await fileToBase64Image(x);

                    popupImgs.map((img, i) => {
                        if (img.description === description.split(".")[0]) {
                            isDuplicateDescription = true;
                        }
                        return isDuplicateDescription
                    })
                    isDuplicateDescription ?
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0] + "_1",
                            base64String,
                        }) :
                        popupImgs.push({
                            name: imgId + "." + description.split(".")[1],
                            description: description.split(".")[0],
                            base64String,
                        });

                    const imgPaths = original.images !== null ? original.images.split(",") : [];
                    imgPaths.push(imgId + "." + description.split(".")[1]);
                    original.images = imgPaths.filter(Boolean).join(",");

                    this.setState({
                        ...this.state,
                        original: {
                            ...original
                        },
                        popupImgs: [
                            ...popupImgs
                        ],
                        imageUploadLoading: false
                    });
                }));
            }
        }
    };

    // private onSendEmailCheck = (value: boolean): void => {
    //     let iS = { ...this.state.data };
    //     let sendEmail = iS.send_Email === "Yes" ? "Yes" : "No";
    //     if (value === true) {
    //         sendEmail = "Yes";
    //     }
    //     else {
    //         sendEmail = "No";
    //     }
    //     iS.send_Email = sendEmail;

    //     this.setState({
    //         data: {
    //             ...this.state.data,
    //             send_Email: sendEmail
    //         }
    //     });
    // };

    private onSendToShopCheck = (value: boolean): void => {

        this.setState({
            data: {
                ...this.state.data,
                repair_Location: value ? "Shop" : "Field"
            }
        });
    };

    // private onLubeRepairCheck = (value: boolean): void => {
       
    //     this.setState({
    //         data: {
    //             ...this.state.data,
    //             repair_Type: value ? "Lube" : "Field"
    //         }
    //     });
    // };



    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        if(unit && unit.repair_ID && unit.repair_ID > 0) {
            window.location.href = ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + '?id=' + unit.repair_ID.toString();
        } 
        else {
            let errors = { ...this.state.errors };
            let tData = this.state.data;
            if (value === undefined) {
                errors = this.errorChecker("unit_Number", "", errors);
            }
            else {
                errors = this.errorChecker("unit_Number", tData.unit_ID.toString(), errors);
                if (tData.id !== 0) {
                    errors = this.errorChecker("initiated_Date", tData.initiated_Date.toString(), errors);
                    errors = this.errorChecker("status", tData.status.toString(), errors);
                }
            }
    
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    unit_ID: unit?.id ?? 0,
                    unit_No: value
                },
                errors
            });
        }

    };

    private handleAttachment = (value?: string): void => {
        const attachments = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.attachments : [];
        const attachment = attachments.find((x: any) => x.attachment_No === value);

        if(attachment && attachment.repair_ID && attachment.repair_ID > 0) {
            window.location.href = ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + '?id=' + attachment.repair_ID.toString();
        } 
        else {
            let errors = { ...this.state.errors };
            let tData = this.state.data;
            if (value === undefined) {
                errors = this.errorChecker("attachment_No", "", errors);
            }
            else {
                errors = this.errorChecker("attachment_No", tData.attachment_ID.toString(), errors);
                if (tData.id !== 0) {
                    errors = this.errorChecker("initiated_Date", tData.initiated_Date.toString(), errors);
                    errors = this.errorChecker("status", tData.status.toString(), errors);
                }
            }
    
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    attachment_ID: attachment?.id ?? 0,
                    attachment_No: value
                },
                errors
            });
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        const query = queryString.parse(this.props.location.search);
        callRouteWithQueryString({
            route: this.props,
            search: { view: query.view ? query.view.toString() : "All" },
            pathName: ROUTE.FIELD.REPAIR_LINE.INDEX
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleSave = async (): Promise<void> => {

        const { data, saveClicked } = this.state;

        if (hasPayload(this.props.token)) {
            if (saveClicked === false && (data.id > 0)) {
                this.setState({ saveClicked: true });

                this.props.updateRepairLineCurrentStateRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        id: data.id
                    }
                });
            };

            if ((data.id === 0) || (saveClicked === true)) {
                this.saveRequest();
            };
        }
    };

    private compareObject = (prevObject: any, currObject: any) =>{
        const prevObjectItems = prevObject.items.map((item: IRepairLineItem) => {
            const { modified_By, unit_ID, parts,  ...rest } = item;
            rest.pictures = item.pictures === null ? [] : item.pictures;
            return rest;
        });

        const currObjectItems = currObject.items.map((item: IRepairLineItem) => {
            const { modified_By, unit_ID, parts,  ...rest } = item;
            rest.pictures = item.pictures === null ? [] : item.pictures;
            return rest;
        });

        const prevObjectCopy = { ...prevObject, mechanics_Assigned: prevObject.mechanics_Assigned ===  "No" , items: prevObjectItems, };
        const currObjectCopy = { ...currObject,  mechanics_Assigned: currObject.mechanics_Assigned === "No" , items: currObjectItems };
        // console.log(prevObjectCopy, currObjectCopy);

        return JSON.stringify(prevObjectCopy) === JSON.stringify(currObjectCopy);
    }

    private saveRequest = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.token)) {
            const {
                id,  unit_ID, repair_Attachment, attachment_ID, repair_Location,items,
                 status,  work_Order_No
            } = data;

            const requestItems: IAddUpdateRepairLineItem[] = [];
       
            await items.map(async (x) => {
                const Parts: IAddUpdateRepairLineItemPart[] = [];

                if(x.parts === null)
                    x.parts = []

                if(x.pictures === null)
                    x.pictures = []

                x.parts
                    .forEach((x) => {
                        Parts.push({
                            ID: x.id,
                            Part_Name: x.part_Name,
                            Part_Number: x.part_Number,
                            Quantity: x.quantity,
                            Driver: x.driver,
                            Status: x.status,
                        });
                    });
               
                requestItems.push({
                    ID: (id !== 0) ? x.id : undefined,
                    Attachment_ID: (repair_Attachment === "No" && x.item_Attachment_ID && x.item_Attachment_ID > 0) ? x.item_Attachment_ID : undefined,
                    Priority: x.priority,
                    Status: x.status,
                    Images: x.images,
                    Major_Component_Replacement: x.major_Component_Replacement,
                    Serial_No: x.serial_No,
                    Hours: x.hours,
                    Type: x.type,
                    New_Major_Component_Replacement_Notes: x.new_Major_Component_Replacement_Notes,
                    Major_Component_Replacement_Notes: x.major_Component_Replacement_Notes,
                    Mob_Demob: x.mob_Demob,
                    Damaged: x.damaged,
                    Damaged_Notes: x.damaged_Notes,
                    Warranty: x.warranty,
                    Component_Code: x.component_Code,
                    Meter_Reading: x.meter_Reading,
                    Pictures: x.pictures,
                    work_Order_No: x.work_Order_No,
                    Defect_Item: x.defect_Item,
                    Issue: x.issue,
                    Repair_Type: x.repair_Type,
                    Assigned_Mech: x.assigned_Mech,
                    Lube_Driver: x.lube_Driver,
                    Manager_Instructions: x.manager_Instructions,
                    Parts_Instructions: x.parts_Instructions,
                    Send_Email: x.send_Email,
                    Mechanic_Comments: x.mechanic_Comments,
                    Modified_By: x.modified_By,
                    Winter_Repair: x.winter_Repair,
                    Repair_Location: x.move_To_Shop === "Yes" ? "Shop" : x.repair_Location
                });
            });

            // console.log(requestItems)

            if (id === 0) {
                this.props.addRepairLineRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        Unit_ID: unit_ID,
                        Attachment_ID: attachment_ID,
                        Repair_Attachment: repair_Attachment,
                        // Meter_Hours: meter_Hours ?? 0,
                        // Initiated_Date: new Date().toLocaleDateString('en-US'),
                        // Requested_By: requested_By,
                        // Assigned_Mech: assigned_Mech,
                        Status: status,
                        Work_Order_No: work_Order_No,
                        // Comments: comments,
                        // Manager_Instructions: manager_Instructions,
                        // date_Completed: date_Completed,
                        // Send_Email: send_Email,
                        Repair_Location: repair_Location,
                        // Repair_Type: repair_Type,
                        // Estimated_Completion_Date: estimated_Completion_Date,
                        Created_By: this.props.token.payload.response.upn,
                        Items: requestItems
                    }
                });
            } else {
                const prevStateData = hasPayload(this.props.updateRepairLineCurrentState) && this.props.updateRepairLineCurrentState.payload.response;
                const compareObject = this.compareObject(prevStateData, data)
                // console.log(compareObject)
                if(compareObject) {
                    callRouteWithQueryString({
                        route: this.props,
                        search: { },
                        pathName: ROUTE.FIELD.REPAIR_LINE.REPAIR_LINES
                    });
                } else {
                    this.props.updateRepairLineRequest({
                        token: this.props.token.payload.response.token,
                        request: {
                            ID: id,
                            Unit_ID: unit_ID,
                            Attachment_ID: attachment_ID,
                            Repair_Attachment: repair_Attachment,
                            // Meter_Hours: meter_Hours ?? 0,
                            // Initiated_Date: initiated_Date,
                            // Requested_By: requested_By,
                            // Assigned_Mech: assigned_Mech,
                            Status: status,
                            Work_Order_No: work_Order_No,
                            // Send_Email: send_Email,
                            Repair_Location: repair_Location,
                            // Repair_Type: repair_Type,
                            // Manager_Instructions: manager_Instructions,
                            // date_Completed: date_Completed,
                            // Estimated_Completion_Date: estimated_Completion_Date,
                            Modified_By: this.props.token.payload.response.upn,
                            Items: requestItems
                        }
                    });
                }
            };

            this.setState({ serverError: "", difference: undefined, saveClicked: false });
        }
    };

    private handleChange = (name: string, value: string): void => {

        // console.log(name,value);
        const iS = this.state.data;
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if (iS.status === RepairLineStatusList[1].name) {
            errors = this.errorChecker("date_Completed", name === "date_Completed" ? value : iS.date_Completed ?? "", errors);
        }
        else if (iS.status !== RepairLineStatusList[1].name) {
            if (errors["date_Completed"])
                delete errors["date_Completed"];
        };

        if ((name === "status") && (value === RepairLineStatusList[1].name)) {
            errors = this.errorChecker("date_Completed", iS.date_Completed ?? "", errors);
        }
        else if ((name === "status") && (value !== RepairLineStatusList[1].name)) {
            if (errors["date_Completed"])
                delete errors["date_Completed"];
        }
        else if ((name === "repair_Attachment")) {
            if (value === "Yes") {
                errors = this.errorChecker("attachment_No", iS.attachment_ID !== 0 ? iS.attachment_ID.toString() : "", errors);
                if (errors["unit_Number"]) {
                    delete errors["unit_Number"];
                }
                iS.unit_ID = 0;
            } else {
                errors = this.errorChecker("unit_Number", iS.unit_ID !== 0 ? iS.unit_ID.toString() : "", errors);
                if (errors["attachment_No"]) {
                    delete errors["attachment_No"];
                }
                iS.attachment_ID = 0;
            }
        } 

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (query.id !== "0") {
                    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                    if (isMobile)
                        window.open(`https://sureway.page.link/?link=https://sureway.page.link%2F%3Fusername%3D${this.props.token.payload.response.email}%26token%3d${this.props.token.payload.response.mobileToken}%26repair_line_id%3d${query.id}&apn=mobile.sureway.sureway_apps&isi=6444068753&ibi=com.Sureway.Apps`, "_self");
                }
                if (pageAccessCheck(this.props.token, "repairLine") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.token))
                        this.getLook();


                    // if (hasPayload(this.props.repairLines)) {
                        if (query.id !== "0") {

                            if (this.state.data.id !== Number(query.id)) {
                                if(hasPayload(this.props.updateRepairLineCurrentState)) {
                                    // const data = this.props.repairLines.payload.response[+query.id];
                                    const data = this.props.updateRepairLineCurrentState.payload.response;

                                    if(query.defect_ID) {
                                        const defect_Item = data.items.find(item => item.id === Number(query.defect_ID));
                                        const defect_Item_Index = this.sortDefectItems(data.items).findIndex(item => item.id === Number(query.defect_ID));
                                        // console.log(defect_Item, defect_Item_Index);
                                        if(defect_Item)
                                            this.handleComponentClick(defect_Item, defect_Item_Index)
                                    }
    
                                    // const notAvaUnits = Object.values(this.props.repairLines)
                                    //     .filter((x: IRepairLine) => ((x.status === RepairLineStatusList[0].name)))
                                    //     .map(({ unit_ID }) => unit_ID);
    
                                    // let activeError: any = undefined;
                                    // if (query.token !== undefined) {
                                    //     if (notAvaUnits.includes(data.unit_ID)) {
                                    //         activeError = Object.values(this.props.repairLines)
                                    //             .filter((q) => (q.status !== RepairLineStatusList[1].name))
                                    //             .find((q) => q.unit_ID === data.unit_ID).id;
                                    //     }
                                    // };
    
                                    // data.send_Email = "No";
    
                                    this.setState({
                                        data,
                                        repairLocation: data.repair_Location ? data.repair_Location : "",
                                        workOrderNo: data.work_Order_No,
                                        // notAvaUnits,
                                        // activeError,
                                        errors: {},
                                        subTableErrors: {}
                                    });
                                } 
                                else {
                                    this.props.updateRepairLineCurrentStateRequest({
                                        token: this.props.token.payload.response.token,
                                        request: {
                                            id: Number(query.id)
                                        }
                                    });
                                }
                            }

                        } else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            // const notAvaUnits = Object.values(this.props.repairLines.payload.response)
                            //     .filter((x: IRepairLine) => ((x.status === RepairLineStatusList[0].name)))
                            //     .map(({ unit_ID }) => unit_ID);

                            // const notAvaAttchments = Object.values(this.props.repairLines.payload.response)
                            //     .filter((x: IRepairLine) => ((x.status === RepairLineStatusList[0].name)))
                            //     .map(({ attachment_ID }) => attachment_ID);

                            let initData = this.state.data;
                            let existingErrors = this.state.errors;

                            if (query.unit_id !== undefined) {
                                let fromAppVal = false;
                                if (query.unit_id > 0) {
                                    initData.unit_ID = Number(query.unit_id);
                                    fromAppVal = true;
                                }
                                existingErrors = this.errorChecker("unit_Number", initData.unit_ID !== 0 ? initData.unit_ID.toString() : "", errors);
                                // existingErrors = this.errorChecker("attachment_No", initData.attachment_ID !== 0 ? initData.attachment_ID.toString() : "", errors);

                                this.setState({
                                    data: initData,
                                    fromApp: fromAppVal,
                                    errors: existingErrors,
                                    // notAvaUnits,
                                    // notAvaAttchments,
                                    activeError: undefined

                                });

                            } else if (query.unit_id === undefined) {
                                if (this.state.data.repair_Attachment === "No")
                                    existingErrors = this.errorChecker("unit_Number", initData.unit_ID !== 0 ? initData.unit_ID.toString() : "", errors);
                                this.setState({ 
                                    errors: existingErrors, 
                                    // notAvaUnits, 
                                    // notAvaAttchments, 
                                    activeError: undefined 
                                });
                            }

                        };
                    // } else {
                    //     this.props.getRepairLinesRequest({
                    //         token: this.props.token.payload.response.token,
                    //         request : {
                    //             view: query.view ? query.view.toString() : "All"
                    //         }
                    //     });
                    // }

                } else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getRepairLineDataByID = (): void => {
        const query: any = queryString.parse(this.props.location.search);

        if (hasPayload(this.props.token) && Number(query.id) > 0)
            this.props.updateRepairLineCurrentStateRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: Number(query.id)
                }
            });
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Repair_Line"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true, minLength: 1 });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateRepairLineComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    repairLines: getRepairLines(state),
    getHistory: getRepairLineHistory(state),
    addRepairLineStatus: addRepairLineStatus(state),
    updateRepairLineStatus: updateRepairLineStatus(state),
    getVersionHistory: getRepairLineVersionHistory(state),
    updateRepairLineCurrentState: getRepairLineById(state),
    updateRepairLineItemCurrentState: getRepairLineItemById(state),
    saveRepairLineItemStatus: saveRepairLineItemStatus(state),
    getMechanicLogsVersionHistory: getMechanicTimecardVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateRepairLineComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addRepairLineRequest: (data: IAddRepairLineRequest) => dispatch(addRepairLineLoadAction(data)),
    getRepairLinesRequest: (data: IGetRepairLineRequest) => dispatch(getRepairLinesLoadAction(data)),
    updateRepairLineRequest: (data: IUpdateRepairLineRequest) => dispatch(updateRepairLineLoadAction(data)),
    updateRepairLineCurrentStateRequest: (request: IGetRepairLineByIdRequest) => dispatch(getRepairLineByIdLoadAction(request)),
    updateRepairLineItemCurrentStateRequest: (request: IGetRepairLineItemByIdRequest) => dispatch(getRepairLineItemByIdLoadAction(request)),
    saveRepairLineItemRequest: (data: ISaveRepairLineItemRequest) => dispatch(saveRepairLineItemLoadAction(data)),
    getRepairLineHistory: (request: IGetRepairLineHistoryRequest): unknown => dispatch(getRepairLineHistoryLoadAction(request)),
    getVersionHistoryRequest: (request: IGetRepairLineVersionHistoryRequest): unknown => dispatch(getRepairLineVersionHistoryLoadAction(request)),
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest): unknown => dispatch(getMechanicTimecardVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateRepairLine);

export const RepairLineStatusList: IIdName[] = [
    { id: 0, name: "Active" },
    { id: 1, name: "Completed" },
    { id: 2, name: "Cancelled" },
    { id: 3, name: "Winter Repair" }
];

export const PriorityList: any[] = [
    { id: 0, name: "Down", color: "#d67676" },
    { id: 3, name: "Urgent", color: "#fc9803" },
    { id: 1, name: "Medium", color: "#0275d8" },
    { id: 2, name: "Low", color: "#32a842" },
];

export const ComponentStatusList: IIdName[] = [
    { id: 0, name: "Initiated" },
    { id: 1, name: "Waiting for parts" },
    { id: 2, name: "Assigned to Mech" },
    { id: 3, name: "Completed" },
    { id: 4, name: "Cancelled" },
    { id: 5, name: "Parts Dispatched" },
    { id: 6, name: "Tagged out - donot operate" }
];

export const TypeList: IIdName[] = [
    { id: 0, name: "New" },
    { id: 1, name: "Used" }
];

export const ExchangeList: IIdName[] = [
    { id: 0, name: "Internal Stock" },
    { id: 1, name: "External Purchase" }
];

export const ConditionList: IIdName[] = [
    { id: 0, name: "New" },
    { id: 1, name: "Used" },
    { id: 2, name: "Reman" },
];

export const MajorTypeList: IIdNameDisplay[] = [
    { id: 0, name: "Repair and Return to Unit", display: "Repair and Return to Unit" },
    { id: 1, name: "Exchange", display: "Exchange"},
    { id: 2, name: "BER", display: "BER (Beyond Economical Repair)"},
];

interface IRepairLineComponent {
    disabled?: true;
    getRole: IRole;
    repairLineRole: string | undefined;
    repairRoles: IRepairRoles | undefined;
    // managerRole: Boolean | undefined;
    popUpIndex: number;
    logsPopup: boolean;
    onLogsOpen: () => void;
    onLogsClose: () => void;
    lubeDrivers: IDriverList[];
    mechanics: ILookupMechanic[];
    onPopupSave: () => void;
    handleClose: () => void;
    data: IRepairLineItem[];
    repairAttachment?: string;
    attachments: IAttachment[];
    difference: IRepairLine | undefined;
    defectItemDifference: IRepairLineItem | undefined;
    componentCodes: IComponentCodeList[];
    workOrder: true | undefined;
    onPartExpandClose: () => void;
    expandPart: number | undefined;
    onDelete: (index: number) => void;
    onPartAdd: (subIndex: number) => void;
    handleExpand: (index: number) => void;
    onPartExpandSave: () => void;
    popupImgs: IRepairLinePicture[];
    editData: IRepairLineItem | undefined;
    editPart: IRepairLineItemPart | undefined;
    subTableErrors: ById<IFieldErrorKeyValue>;
    onImageEdit: (src: string, idx: number) => void;
    getAssignedMechanics: (id: string) => void;
    getAssignedLubeDrivers: (upns: string) => void;
    handleAssignedLubeDriver: (e: any, value: any) => Promise<void>;
    handleAssignedLubeDriverRemove: (name:string) => Promise<void>;
    handleAssignedMech: (e: any, value: any) => Promise<void>;
    handleAssignedMechRemove: (name:string) => Promise<void>;
    // handleAssignedDriver: (e: any, value: IDriverList) => Promise<void>;
    onPartDelete: (index: number, subIndex: number) => void;
    onImgDelete: (name: string, ind: number, imgIndex: number) => void;
    handleMeterHours: (name: string, value: string, index: number) => void;
    handleChange: (name: string, value: string, index?: number | undefined) => void;
    onImageName: (name: string, value: string, index: number, subIndex: number) => void;
    onPartChange: (name: string, value: string, index: number, subIndex: number) => void;
    handleUpload: (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
    handleRepairLineVersionHistoryComponent: (data: IRepairLineItem) => Promise<void>;
    handleLinkClick: (name: string) => void;
    handleInspectionFormClick: (defect_ID: number | undefined, inspection_Form_ID?: number | undefined) => void;
    handleUnitDetails: () => void;
    repairLineVersionHistoryComponentPopup: boolean;
    repairLineVersionHistorypopupData: ById<IGetRepairLineVersionHistory>;
    handleVersionHistoryComponentPopupClose: () => void;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetRepairLineVersionHistory>>>;
    getMechanicLogsVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
    handleMechLogsVersionHistoryClick: (name:string, data: any) => Promise<void>;
    mechLogsVersionHistoryPopup: boolean;
    mechanicLogsVersionHistorypopupData: ById<IGetMechanicTimecardVersionHistory>;
    handleMechLogsVersionHistoryPopupClose: () => void;
    display: string | undefined;
    repairLocation: string | undefined;
    serverErrorSaveDefectItem: string;
    usedPartsUnit: boolean;
};

const RepairLineComponentStyles = styled(LAPaperWithPadding)`
    padding: 40px 40px;

    .addPartBtnClass{
        text-align: left;
    }
    .right-side {
        top: 0%;
        right: 3%;
        position: absolute;
    };
    .partsBody{
        background-color: ${LIGHT_GREY_COLOR};
        
      }
      .partsSpacing{
        float:left;
        padding: 10px;
      }
      .signatureCanvas{
        border: 1px solid black;
        width: 100%;
        min-height: 80px;
      }
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .right-side {
            top: auto;
            right: auto;
            position: static;
        };

    };
`;
const RepairLineComponent: React.FC<IRepairLineComponent> = (props: IRepairLineComponent) => {
    const [saveDefectItemClicked, setSaveDefectItemClicked] = useState(false);
    const [isMechanicLogsExpanded, setIsMechanicLogsExpanded] = useState(false);
    const x = props.editData;
    // console.log(x)
    const index = props.popUpIndex;
    const fieldManager = props.repairRoles?.field_Manager;
    const shopManager = props.repairRoles?.shop_Manager;
    const lubeManager = props.repairRoles?.lube_Manager;
    // console.log(props.mechanics);
    // console.log("fieldManager: " + fieldManager)
    // console.log("shopManager: " + shopManager)
    // console.log("lubeManager: " + lubeManager)
    // console.log(!(fieldManager || shopManager))
    const display = x?.id !== 0 ? props.display + ` - ` + props.data[props.popUpIndex].defect_Item : '';
    const disableByStatus = props.data[props.popUpIndex]  ? props.data[props.popUpIndex].status === "Completed" || props.data[props.popUpIndex].status === "Cancelled" : false;
    const disableByCancelledStatus = props.data[props.popUpIndex]  ? props.data[props.popUpIndex].status === "Cancelled" : false;
    const disableByCompletedStatus = props.data[props.popUpIndex]  ? props.data[props.popUpIndex].status === "Completed" : false;
    const disableSave = (Object.values(props.subTableErrors).length > 0) ? true : disableByCancelledStatus ? true : saveDefectItemClicked ? true: undefined;
    const disable = x && x.id > 0 ? props.disabled : undefined;
    const difference = (props.difference && props.difference.items && x && x.id && x.id !== 0) ? props.difference.items.find((z) => z.id === x.id) : undefined;
    const defectItemDifference = props.defectItemDifference ? props.defectItemDifference : undefined;
    const disableAttachmentRepair = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && (props.data?.[props.popUpIndex]?.item_Attachment_ID ?? 0) > 0 ? true : undefined;
    const disableLubeRepair = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].repair_Type === "Lube" ? true : undefined;
    const disableWinterRepair = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].winter_Repair === "Yes" ? true : undefined;
    const disableComponentCode = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].component_Code?.length > 0 ? true : undefined;
    const disableMajorType = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].major_Type ? true : undefined;
    const disableMajorComponentCheckbox = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].major_Component_Replacement === "Yes" ? true : undefined;
    const disableExchangeType = props.data[props.popUpIndex] && props.data[props.popUpIndex].id > 0 && props.data[props.popUpIndex].exchange_Type ? true : undefined;
    // console.log(props.subTableErrors)

    const [imageSrc, setImageSrc] = useState<{ src: string, idx: number } | undefined>(undefined);
    const handleOpenImage = (src: string, idx: number): void => setImageSrc({ src, idx });
    // const onComponentCode = (value: string): void => props.handleChange("component_Code", value, index);
    const handleMeterHours = (name: string, value: string): void => props.handleMeterHours(name, value, index);
    const handleType = (event: unknown, values: any): void => x ? props.handleChange("type", values != null ? values.name : x.type, index) : undefined;
    const handleMajorType = (event: unknown, values: any): void => x ? props.handleChange("major_Type", values != null ? values.name : x.major_Type, index) : undefined;
    const handleOEMPart = (event: unknown, values: any): void => x ? props.handleChange("oeM_Part", values != null ? values.name : x.oeM_Part, index) : undefined;
    const handleExchange = (event: unknown, values: any): void => x ? props.handleChange("exchange_Type", values != null ? values.name : x.exchange_Type, index) : undefined;
    const handleCondition = (event: unknown, values: any): void => x ? props.handleChange("condition", values != null ? values.name : x.condition, index) : undefined;
    const handleAssignedMech = (event: unknown, values: ILookupMechanic[]): Promise<void> =>  props.handleAssignedMech( event, values) ;
    const handleAssignedLubeDriver = (event: unknown, values: IDriverList[]): Promise<void> =>  props.handleAssignedLubeDriver( event, values) ;
    const handleAssignedMechRemove = (name:string): Promise<void> =>  props.handleAssignedMechRemove(name) ;
    const handleAssignedLubeDriverRemove = (name:string): Promise<void> =>  props.handleAssignedLubeDriverRemove(name) ;
    // const handleAssignedDriver = (event: unknown, values: IDriverList): Promise<void> =>  props.handleAssignedDriver( event, values) ;
    const handleStatus = (event: unknown, values: any): void => x ? props.handleChange("status", values != null ? values.name : x.status, index) : undefined;
    const handlePriority = (event: unknown, values: any): void => x ? props.handleChange("priority", values != null ? values.name : x.priority, index) : undefined;
    
    const handleCheckBox = (checked: boolean, name?: string | undefined): void => x ? props.handleChange(name ?? "", checked ? "Yes" : "No", index) : undefined;
    const onAttachment = (value: string): void => x ? props.handleChange("defect_Item", value, index) : undefined;

    const handleEditImageSave = (src: string): void => {
        if (imageSrc)
            props.onImageEdit(src, imageSrc.idx);

        handleEditImageCancel();
    };

    const handleEditImageCancel = (): void => {
        setImageSrc(undefined);
    };

    const handlePopupSave = (): void => { 
        setSaveDefectItemClicked(true);
        props.onPopupSave();
    }

    const handleMechanicLogsExpand = (): void => {
        setIsMechanicLogsExpanded(!isMechanicLogsExpanded)
    }

    const getComponentCodes = (ids: string): IComponentCodeList[] => {
        if (ids) {
            // console.log(ids)
            const response: IComponentCodeList[] = [];
            const assignedCC = ids !== null ? ids.split(",").map(String) : [];
            const componentCodes: IComponentCodeList[] = props.componentCodes;

            assignedCC.forEach((x) => {
                const index = componentCodes.findIndex(q => q.code === x);
                if (index >= 0)
                    response.push(componentCodes[index]);
            });

            return response;
        }
        return [];
    };

    const onComponentCodeClick = (value: IComponentCodeList): void => {
        // console.log(value)
        let component_Code = x ? x.component_Code : "";
        const component_Codes_Array: string[] = component_Code && component_Code !== null ? component_Code.split(",").map(String) : [];
    
        const index = component_Codes_Array.length > 0 ? component_Codes_Array.findIndex((e) => e === value.code) : -1;
        if (index < 0) {
            component_Codes_Array.push(value.code);
        }

        component_Code = component_Codes_Array.join(",");
        props.handleChange("component_Code", component_Code, index);
       
    }

    const handleComponentCodeRemove = (value: any): void => {
        // console.log(value)
        let component_Code = x ? x.component_Code : "";
        const component_Codes_Array: string[] = component_Code && component_Code !== null ? component_Code.split(",").map(String) : [];
        const index = component_Codes_Array.length > 0 ? component_Codes_Array.findIndex((x) => x === value) : -1;
        if (index >= 0) {
            component_Codes_Array.splice(index, 1);
        }
        
        component_Code = component_Codes_Array.join(",");
        props.handleChange("component_Code", component_Code, index);
    }

    if (x) {
        const isDuplicate = props.data.filter((q) => q.status !== ComponentStatusList[4].name)
                                        .some((q) => q.defect_Item.toLowerCase() === x.defect_Item.toLowerCase() && q.id !== x.id);
        // console.log(x)
        return (
            <RepairLineComponentStyles>
                <LAPaperWithLessPadding>

                    <LAGrid>
                        {x.id !== 0 && <LAGridItem xs={12} sm={6} md={2}>
                            <strong>Unit/Attachment # : {x.unit_ID > 0 ? x.unit_No : x.attachment_ID && x.attachment_ID > 0 ? x.attachment_No : ""}</strong>
                        </LAGridItem>}

                        {x.id !== 0 && x.unit_ID > 0 && <LAGridItem xs={12} sm={6} md={2}>
                            <LALinkButton onClick={() => props.handleLinkClick("Unit_History")} label="Unit History" />
                        </LAGridItem>}

                        {x.id !== 0 && x.unit_ID > 0 && <LAGridItem xs={12} sm={6} md={2}>
                            <LALinkButton onClick={() => props.handleLinkClick("Equipment_Manuals")} label="Equipment Manuals" />
                        </LAGridItem>}

                        {x.id !== 0 && x.unit_ID > 0 && <LAGridItem xs={12} sm={6} md={2}>
                            <LALinkButton onClick={() => props.handleLinkClick("TSI")} label="TSI" />
                        </LAGridItem>}

                        {x.id !== 0 && x.unit_ID > 0 && <LAGridItem xs={12} sm={6} md={2}>
                            <LALinkButton
                                label="Unit Details"
                                onClick={props.handleUnitDetails}
                            />
                        </LAGridItem>}

                        {x.id !== 0 && <LAGridItem xs={12} sm={6} md={2} className="right-side">
                            <LALinkButton onClick={() => props.handleRepairLineVersionHistoryComponent(x)} label="Version History Defect Item" />
                        </LAGridItem>}
                        {(isDuplicate) && <LAGridItem xs={12} sm={12} md={12}>
                            <LAErrorBox text="Defect Item already exists for this unit. Please verify." />
                        </LAGridItem>}

                        {defectItemDifference && defectItemDifference.modified && <LAGridItem xs={12} sm={12} md={12}>
                            <LAErrorBox className={"text-center"} color={RED_COLOR} text={`${x.modified_By}, has made come changes to this defect item. Changes highlighted in gray box below`} />
                        </LAGridItem>}

                        <LAGridItem xs={12} key={index}>

                            <LAGrid>
                                <LAGridItem xs={12} sm={12} md={12}>
                                    <div className="text-center">
                                        <h3>Repair Location: {x.repair_Location ? x.repair_Location : props.repairLocation}</h3>
                                    </div>
                                </LAGridItem>
                                <LAGrid>
                                    {props.repairAttachment === "No" && <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="attachmentCheckbox"
                                            label="Attachment?"
                                            onChange={handleCheckBox}
                                            disabled={disable || (disableByStatus ? true : undefined) || disableAttachmentRepair}
                                            value={ ((x.attachmentCheckbox === "Yes" || (x.item_Attachment_ID && x.item_Attachment_ID > 0 )) ? true : false) }
                                        />
                                    </LAGridItem>}

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="lube_Repair"
                                            label="Lube Repair?"
                                            onChange={handleCheckBox}
                                            disabled={disable || (disableByStatus ? true : undefined) || disableLubeRepair}
                                            value={x.repair_Type === "Lube" ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="winter_Repair"
                                            label="Winter Repair?"
                                            onChange={handleCheckBox}
                                            disabled={!(fieldManager || shopManager) || (disableByStatus ? true : undefined) || disableWinterRepair}
                                            value={x.winter_Repair === "Yes" ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="move_To_Shop"
                                            label={`Move to shop - ${((x.attachmentCheckbox === "Yes" || (x.item_Attachment_ID && x.item_Attachment_ID > 0 ))) ? "Attachment" : "Component"}`}
                                            onChange={handleCheckBox}
                                            disabled={props.repairLineRole !== "Manager" || (disableByStatus ? true : undefined) || (x.repair_Location === "Shop" ? true : undefined)}
                                            value={(x.move_To_Shop === "Yes" || x.repair_Location === "Shop") ? true : false}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="mob_Demob"
                                            label="Mob/Demob"
                                            onChange={handleCheckBox}
                                            disabled={(!(fieldManager || shopManager)) ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                            value={(x.mob_Demob === "Yes") ? true : false}
                                        />
                                        {difference && difference.mob_Demob !==x.mob_Demob && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.mob_Demob} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('mob_Demob') && defectItemDifference.mob_Demob !==x.mob_Demob && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.damaged} />}
                                    </LAGridItem>

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="major_Component_Replacement"
                                            label="Major Component Replacement"
                                            onChange={handleCheckBox}
                                            disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : disableMajorComponentCheckbox ? disableMajorComponentCheckbox : (props.getRole === ReadAndWrite) ? disable : true}
                                            value={(x.major_Component_Replacement === "Yes") ? true : false}
                                        />
                                        {difference && difference.major_Component_Replacement!==x.major_Component_Replacement && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.major_Component_Replacement} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('major_Component_Replacement') && defectItemDifference.major_Component_Replacement && defectItemDifference.major_Component_Replacement!==x.major_Component_Replacement && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.major_Component_Replacement} />}
                                    </LAGridItem>

                                    <LAGridItem xs={6} sm={4} md={2}>
                                        <LACheckBox
                                            name="damaged"
                                            label="Damaged"
                                            onChange={handleCheckBox}
                                            disabled={(!(fieldManager || shopManager)) ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                            // disabled={props.repairLineRole !== "Manager" ? true : disableByStatus || (props.getRole === ReadAndWrite) ? disable : true}
                                            value={(x.damaged === "Yes") ? true : false}
                                        />
                                        {difference && difference.damaged !==x.damaged && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.damaged} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('damaged') && defectItemDifference.damaged !==x.damaged && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.damaged} />}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={2}>
                                        <LACheckBox
                                            name="warranty"
                                            label="Warranty"
                                            onChange={handleCheckBox}
                                            disabled={(!(fieldManager || shopManager)) ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                            value={(x.warranty === "Yes") ? true : false}
                                        />
                                        {difference && difference.warranty !==x.warranty && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.warranty} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('warranty') && defectItemDifference.warranty !==x.warranty && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.warranty} />}
                                    </LAGridItem>
                                </LAGrid>

                                {!imageSrc && <>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        {(x.attachmentCheckbox === "Yes" || (x.item_Attachment_ID && x.item_Attachment_ID > 0)) && props.repairAttachment === "No"  ? <DevExtremePopupLookup
                                            // data={(attachments && attachments.length > 0) ? attachments.filter((q) => notAvaAttchments.includes(q.id) ? false : true) : []}
                                            data={props.attachments}
                                            id="job-search"
                                            placeHolder="Defect Item"
                                            disabled={disable || disableByStatus}
                                            displayExp="attachment_No"
                                            name="attachment_No"
                                            errorName="attachment_No"
                                            errors={props.subTableErrors}
                                            currentVal={x.defect_Item}
                                            onClick={onAttachment}
                                            columns={[
                                                { name: "attachment_No", caption: "Attachment No.", type: "string" },
                                                { name: "type", caption: "Type", type: "string" },
                                                { name: "coupler_Design", caption: "Coupler Design", type: "string" },
                                                { name: "style", caption: "Style", type: "string" },
                                                { name: "location", caption: "Location", type: "string" },
                                            ]}
                                        /> :
                                        <LATextArea
                                            rowsMax={1}
                                            fullWidth={true}
                                            variant="outlined"
                                            indexPosition={index}
                                            name="defect_Item"
                                            label="Defect Item"
                                            disabled={disable || disableByStatus}
                                            value={x.defect_Item}
                                            InputProps={{inputProps: { maxLength: 40 }}}
                                            onChange={props.handleChange}
                                            errorText={
                                                x.defect_Item.length === 0 ?
                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                    : undefined
                                            }
                                        />}
                                        {difference && difference.defect_Item !== x.defect_Item && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.defect_Item} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('defect_Item') && defectItemDifference.defect_Item !== x.defect_Item && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.defect_Item} />}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextArea
                                            minRows={3}
                                            rowsMax={4}
                                            fullWidth={true}
                                            variant="outlined"
                                            indexPosition={index}
                                            name="issue"
                                            label="Issue"
                                            value={x.issue}
                                            onChange={props.handleChange}
                                            disabled={disable || disableByStatus}
                                        />
                                        {difference && difference.issue!==x.issue && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.issue} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('issue') && defectItemDifference.issue!==x.issue && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.issue} />}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LAAutoComplete
                                            multiple={false}
                                            option={PriorityList}
                                            autoHighlight={true}
                                            onChange={handlePriority}
                                            disabled={disable || disableByStatus}
                                            getOptionLabel="name"
                                            filterSelectedOptions={true}
                                            dropDownPlaceHolder="Priority"
                                            selectionRemove={undefinedFunction}
                                            value={x.priority && PriorityList ? PriorityList.find(q => q.name === x.priority) : null}
                                            defaultValue={x.priority && PriorityList ? PriorityList.find(q => q.name === x.priority) : null}
                                        />
                                        {difference && difference.priority!==x.priority && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.priority} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('priority') && defectItemDifference.priority!==x.priority && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.priority} />}
                                    </LAGridItem>

                                    {x.repair_Type !== "Lube" ? <LAGridItem xs={12} sm={6} md={3}>
                                        <LAAutoComplete
                                            multiple={true}
                                            option={props.mechanics}
                                            autoHighlight={true}
                                            getOptionLabel="name"
                                            filterSelectedOptions={true}
                                            onChange={handleAssignedMech}
                                            dropDownPlaceHolder="Assigned Mechanic"
                                            selectionRemove={handleAssignedMechRemove}
                                            disabled={props.repairLineRole !== "Manager" || disable || disableByStatus}
                                            errorText={props.subTableErrors["assigned_Mech"] ? props.subTableErrors["assigned_Mech"].message : undefined}
                                            value={x.assigned_Mech && props.mechanics ? props.getAssignedMechanics(x.assigned_Mech) : []}
                                            defaultValue={x.assigned_Mech && props.mechanics ? props.getAssignedMechanics(x.assigned_Mech) : []}
                                        />
                                        {difference && difference.assigned_Mech && difference.assigned_Mech !== x.assigned_Mech && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.assigned_Mech} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('assigned_Mech') && defectItemDifference.assigned_Mech !== x.assigned_Mech && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.assigned_Mech} />}
                                    </LAGridItem> :

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LAAutoComplete
                                            multiple={true}
                                            option={props.lubeDrivers}
                                            autoHighlight={true}
                                            getOptionLabel="display_Name"
                                            filterSelectedOptions={true}
                                            onChange={handleAssignedLubeDriver}
                                            dropDownPlaceHolder="Lube Driver"
                                            selectionRemove={handleAssignedLubeDriverRemove}
                                            disabled={!lubeManager || disable || disableByStatus}
                                            errorText={props.subTableErrors["lube_Driver"] ? props.subTableErrors["lube_Driver"].message : undefined}
                                            value={x.lube_Driver && props.lubeDrivers ? props.getAssignedLubeDrivers(x.lube_Driver) : []}
                                            defaultValue={x.lube_Driver && props.lubeDrivers ? props.getAssignedLubeDrivers(x.lube_Driver) : []}
                                        />
                                        {difference && difference.lube_Driver && difference.lube_Driver !== x.lube_Driver && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.lube_Driver} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('lube_Driver') && defectItemDifference.lube_Driver !== x.lube_Driver && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.lube_Driver} />}
                                    </LAGridItem>}

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LAAutoComplete
                                            multiple={false}
                                            option={ComponentStatusList}
                                            autoHighlight={true}
                                            onChange={handleStatus}
                                            disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") || disable || disableByStatus}
                                            getOptionLabel="name"
                                            filterSelectedOptions={true}
                                            dropDownPlaceHolder="Status"
                                            selectionRemove={undefinedFunction}
                                            value={x.status && ComponentStatusList ? ComponentStatusList.find(q => q.name === x.status) : null}
                                            defaultValue={x.status && ComponentStatusList ? ComponentStatusList.find(q => q.name === x.status) : null}
                                        />
                                        {difference && difference.status!==x.status && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.status} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('status') && defectItemDifference.status!==x.status && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.status} />}
                                    </LAGridItem>

                                    {(ComponentStatusList[3].name === x.status) && x.attachmentCheckbox !== "Yes" && (x.item_Attachment_ID === 0 || x.item_Attachment_ID === undefined || x.item_Attachment_ID === null) && <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            type="number"
                                            label="Meter Reading"
                                            fullWidth={true}
                                            variant="outlined"
                                            name="meter_Reading"
                                            disabled={disable || disableByStatus || props.usedPartsUnit}
                                            value={x.meter_Reading ?? ""}
                                            onChange={handleMeterHours}
                                            errorText={
                                                props.subTableErrors["meter_Reading"] ?
                                                    props.subTableErrors["meter_Reading"].message
                                                    : undefined
                                            }
                                        />
                                        {difference && difference.meter_Reading !==x.meter_Reading  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.meter_Reading?.toString()} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('meter_Reading') && defectItemDifference.meter_Reading !==x.meter_Reading  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.meter_Reading?.toString()} />}
                                    </LAGridItem>}

                                    {/* <LAGridItem xs={12} sm={6} md={3}>
                                        <DevExtremePopupLookup
                                            id="component-code-search"
                                            placeHolder="Component Code"
                                            disabled={props.repairLineRole === "Employee" || disableByCancelledStatus || (disableByCompletedStatus && !props.managerRole)}
                                            displayExp="code"
                                            errors={
                                                props.subTableErrors
                                            }
                                            name="code"
                                            errorName="code"
                                            // multiple={true}
                                            currentVal={x.component_Code ?? ""}
                                            data={props.componentCodes}
                                            onClick={onComponentCode}
                                            columns={[
                                                { name: "code", caption: "Code", type: "string" },
                                                { name: "description", caption: "Description", type: "string" }
                                            ]}
                                        />
                                        {difference && difference.component_Code !==x.component_Code  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.component_Code?.toString()} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('component_Code') && defectItemDifference.component_Code !==x.component_Code  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.component_Code?.toString()} />}
                                    </LAGridItem> */}

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <DevExtremePopupLookup
                                            data={props.componentCodes}
                                            multiple={true}
                                            id="component-code-search"
                                            placeHolder="Component Code"
                                            disabled={disableByCancelledStatus || (disableByCompletedStatus && props.repairLineRole !== "Manager") || disableComponentCode}
                                            displayExp="code"
                                            name="code"
                                            errorName="code"
                                            onSelectionRemove={handleComponentCodeRemove}
                                            errors={props.subTableErrors}
                                            currentVal={getComponentCodes(x.component_Code)}
                                            onClick={onComponentCodeClick}
                                            columns={[
                                                { name: "code", caption: "Code", type: "string" },
                                                { name: "description", caption: "Description", type: "string" }
                                            ]}
                                        />
                                        {difference && difference.component_Code !==x.component_Code  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.component_Code?.toString()} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('component_Code') && defectItemDifference.component_Code !==x.component_Code  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.component_Code?.toString()} />}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                            <LATextArea
                                                minRows={4}
                                                rowsMax={10}
                                                type="string"
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="manager_Instructions"
                                                label="Manager Instructions"
                                                onChange={props.handleChange}
                                                value={x.manager_Instructions}
                                                disabled={props.repairLineRole !== "Manager" ? true : (props.getRole === ReadAndWrite) ? disableByCancelledStatus : true}
                                            />
                                            {difference && difference.manager_Instructions && difference.manager_Instructions !== x.manager_Instructions && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.manager_Instructions} />}
                                            {defectItemDifference && defectItemDifference.hasOwnProperty('manager_Instructions') && defectItemDifference.manager_Instructions !== x.manager_Instructions && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.manager_Instructions} />}
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                            <LATextArea
                                                minRows={4}
                                                rowsMax={10}
                                                type="string"
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="parts_Instructions"
                                                value={x.parts_Instructions}
                                                label="Parts Instructions"
                                                onChange={props.handleChange}
                                                disabled={props.repairLineRole !== "Manager" ? true : (props.getRole === ReadAndWrite) ? disableByStatus : true}
                                            />
                                            {difference && difference.parts_Instructions && difference.parts_Instructions !== x.parts_Instructions && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.parts_Instructions} />}
                                            {defectItemDifference && defectItemDifference.hasOwnProperty('parts_Instructions') && defectItemDifference.parts_Instructions !== x.parts_Instructions && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.parts_Instructions} />}
                                    </LAGridItem>

                                    {/* <LAGridItem xs={12} sm={5} md={3}>
                                            <LATextArea
                                                minRows={4}
                                                rowsMax={4}
                                                name="mechanic_Comments"
                                                fullWidth={true}
                                                variant="outlined"
                                                value={x.mechanic_Comments}
                                                indexPosition={index}
                                                label="Mechanic Comments"
                                                onChange={props.handleChange}
                                                disabled={props.repairLineRole !== "Mechanic" ? true : disableByStatus}
                                            />
                                            {difference && difference.mechanic_Comments && difference.mechanic_Comments !==x.mechanic_Comments && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.mechanic_Comments} />}
                                            {defectItemDifference && defectItemDifference.hasOwnProperty('mechanic_Comments') && defectItemDifference.mechanic_Comments !==x.mechanic_Comments && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.mechanic_Comments} />}
                                    </LAGridItem> */}

                                    <LAGridItem xs={12} sm={5} md={3}>
                                            <LATextArea
                                                minRows={3}
                                                rowsMax={4}
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="parts_Notes"
                                                label="Parts Notes"
                                                disabled={true}
                                                value={x.parts_Notes ?? ""}
                                                onChange={undefinedFunction}
                                            />
                                            {difference && difference.parts_Notes !==x.parts_Notes && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.parts_Notes} />}
                                            {defectItemDifference && defectItemDifference.hasOwnProperty('parts_Notes') && defectItemDifference.parts_Notes !==x.parts_Notes && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.parts_Notes} />}
                                    </LAGridItem>
                                        {(x.major_Component_Replacement === "Yes") && 
                                            <LAGridItem xs={12} className="text-center">
                                                <LAGreenPaper>
                                                    <strong>Major Component Replacement Section</strong>
                                                    <br/><br />
                                                    <LAGrid >
                                                        <LAGridItem xs={4}>
                                                            <></>
                                                        </LAGridItem>
                                                        <LAGridItem xs={12} sm={6} md={4}>
                                                            <LABluePaper>
                                                                <LAAutoComplete
                                                                    style={{ backgroundColor: "white"}}
                                                                    multiple={false}
                                                                    option={MajorTypeList}
                                                                    autoHighlight={true}
                                                                    onChange={handleMajorType}
                                                                    
                                                                    getOptionLabel="display"
                                                                    filterSelectedOptions={true}
                                                                    dropDownPlaceHolder="Major Type"
                                                                    selectionRemove={undefinedFunction}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : disableMajorType ? disableMajorType : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["major_Type"] ?
                                                                            props.subTableErrors["major_Type"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.major_Type && MajorTypeList ? MajorTypeList.find(q => q.name === x.major_Type) : null}
                                                                    defaultValue={x.major_Type && MajorTypeList ? MajorTypeList.find(q => q.name === x.major_Type) : null}
                                                                />
                                                                {/* {difference && difference.major_Type !==x.major_Type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.major_Type} />}
                                                                {defectItemDifference && defectItemDifference.hasOwnProperty('major_Type') && defectItemDifference.major_Type !==x.major_Type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.major_Type} />} */}
                                                            </LABluePaper>
                                                        </LAGridItem>

                                                        <LAGridItem xs={4} >
                                                            <></>
                                                        </LAGridItem>

                                                        {/* <LAGridItem xs={12} sm={6} md={4}>
                                                            <LAAutoComplete
                                                                style={{ backgroundColor: "white"}}
                                                                multiple={false}
                                                                option={TypeList}
                                                                autoHighlight={true}
                                                                onChange={handleType}
                                                                getOptionLabel="name"
                                                                filterSelectedOptions={true}
                                                                dropDownPlaceHolder="Type"
                                                                selectionRemove={undefinedFunction}
                                                                disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                errorText={
                                                                    props.subTableErrors["type"] ?
                                                                        props.subTableErrors["type"].message
                                                                        : undefined
                                                                }
                                                                value={x.type && TypeList ? TypeList.find(q => q.name === x.type) : null}
                                                                defaultValue={x.type && TypeList ? TypeList.find(q => q.name === x.type) : null}
                                                            />
                                                            {difference && difference.type !==x.type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.type} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('type') && defectItemDifference.type !==x.type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.type} />}
                                                        </LAGridItem> */}

                                                        

                                                        {x.major_Type === "Repair and Return to Unit" && <>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LATextArea
                                                                    style={{ backgroundColor: "white"}}
                                                                    minRows={1}
                                                                    rowsMax={3}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={index}
                                                                    name="component_Repaired_Location"
                                                                    label="Where was component Repaired"
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["component_Repaired_Location"] ?
                                                                            props.subTableErrors["component_Repaired_Location"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.component_Repaired_Location ?? ""}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </LAGridItem>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LAAutoComplete
                                                                    style={{ backgroundColor: "white"}}
                                                                    multiple={false}
                                                                    option={YesOrNo}
                                                                    autoHighlight={true}
                                                                    onChange={handleOEMPart}
                                                                    getOptionLabel="name"
                                                                    filterSelectedOptions={true}
                                                                    dropDownPlaceHolder="OEM Part"
                                                                    selectionRemove={undefinedFunction}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["oeM_Part"] ?
                                                                            props.subTableErrors["oeM_Part"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.oeM_Part && YesOrNo ? YesOrNo.find(q => q.name === x.oeM_Part) : null}
                                                                    defaultValue={x.oeM_Part && YesOrNo ? YesOrNo.find(q => q.name === x.oeM_Part) : null}
                                                                />
                                                            </LAGridItem>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LATextField
                                                                    style={{ backgroundColor: "white"}}
                                                                    label="Vendor"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    name="vendor"
                                                                    indexPosition={index}
                                                                    value={x.vendor ?? ""}
                                                                    onChange={props.handleChange}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["vendor"] ?
                                                                            props.subTableErrors["vendor"].message
                                                                            : undefined
                                                                    }
                                                                />
                                                            {/* {difference && difference.vendor!==x.vendor && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.vendor?.toString()} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('vendor') && defectItemDifference.vendor!==x.vendor && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.hours?.toString()} />} */}
                                                            </LAGridItem>
                                                        </>}

                                                        {x.major_Type === "Exchange" && <>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LAAutoComplete
                                                                    style={{ backgroundColor: "white"}}
                                                                    multiple={false}
                                                                    option={ExchangeList}
                                                                    autoHighlight={true}
                                                                    onChange={handleExchange}
                                                                    getOptionLabel="name"
                                                                    filterSelectedOptions={true}
                                                                    dropDownPlaceHolder="Exchange Type"
                                                                    selectionRemove={undefinedFunction}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : disableExchangeType ? disableExchangeType : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["exchange_Type"] ?
                                                                            props.subTableErrors["exchange_Type"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.exchange_Type && ExchangeList ? ExchangeList.find(q => q.name === x.exchange_Type) : null}
                                                                    defaultValue={x.exchange_Type && ExchangeList ? ExchangeList.find(q => q.name === x.exchange_Type) : null}
                                                                />
                                                            </LAGridItem>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LAAutoComplete
                                                                    style={{ backgroundColor: "white"}}
                                                                    multiple={false}
                                                                    option={ConditionList}
                                                                    autoHighlight={true}
                                                                    onChange={handleCondition}
                                                                    getOptionLabel="name"
                                                                    filterSelectedOptions={true}
                                                                    dropDownPlaceHolder="Condition"
                                                                    selectionRemove={undefinedFunction}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["condition"] ?
                                                                            props.subTableErrors["condition"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.condition && ConditionList ? ConditionList.find(q => q.name === x.condition) : null}
                                                                    defaultValue={x.condition && ConditionList ? ConditionList.find(q => q.name === x.condition) : null}
                                                                />
                                                                {/* {difference && difference.type !==x.type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.type} />}
                                                                {defectItemDifference && defectItemDifference.hasOwnProperty('type') && defectItemDifference.type !==x.type && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.type} />} */}
                                                            </LAGridItem>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LATextArea
                                                                    style={{ backgroundColor: "white"}}
                                                                    minRows={1}
                                                                    rowsMax={3}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={index}
                                                                    name="warranty_Details"
                                                                    label="Warranty Details"
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["warranty_Details"] ?
                                                                            props.subTableErrors["warranty_Details"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.warranty_Details ?? ""}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </LAGridItem>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LATextField
                                                                    style={{ backgroundColor: "white"}}
                                                                    label="Vendor"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    name="vendor"
                                                                    indexPosition={index}
                                                                    value={x.vendor ?? ""}
                                                                    onChange={props.handleChange}
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["vendor"] ?
                                                                            props.subTableErrors["vendor"].message
                                                                            : undefined
                                                                    }
                                                                />
                                                            {/* {difference && difference.vendor!==x.vendor && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.vendor?.toString()} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('vendor') && defectItemDifference.vendor!==x.vendor && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.hours?.toString()} />} */}
                                                            </LAGridItem>
                                                        </>}

                                                        {x.major_Type === "BER" && <>
                                                            <LAGridItem xs={12} sm={6} md={3}>
                                                                <LATextArea
                                                                    style={{ backgroundColor: "white"}}
                                                                    minRows={1}
                                                                    rowsMax={3}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={index}
                                                                    name="beR_Details"
                                                                    label="BER Details"
                                                                    disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["beR_Details"] ?
                                                                            props.subTableErrors["beR_Details"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.beR_Details ?? ""}
                                                                    onChange={props.handleChange}
                                                                />
                                                            </LAGridItem>
                                                        </>}

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                style={{ backgroundColor: "white"}}
                                                                label="Serial# / Toolhound#"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="serial_No"
                                                                indexPosition={index}
                                                                value={x.serial_No ?? ""}
                                                                onChange={props.handleChange}
                                                                disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                errorText={
                                                                    props.subTableErrors["serial_No"] ?
                                                                        props.subTableErrors["serial_No"].message
                                                                        : undefined
                                                                }
                                                            />
                                                            {difference && difference.serial_No!==x.serial_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.serial_No} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('serial_No') && defectItemDifference.serial_No!==x.serial_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.serial_No} />}
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} sm={6} md={3}>
                                                            <LATextField
                                                                style={{ backgroundColor: "white"}}
                                                                type="number"
                                                                label="Hours"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="hours"
                                                                indexPosition={index}
                                                                value={x.hours ?? ""}
                                                                onChange={props.handleChange}
                                                                disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                errorText={
                                                                    props.subTableErrors["hours"] ?
                                                                        props.subTableErrors["hours"].message
                                                                        : undefined
                                                                }
                                                            />
                                                            {difference && difference.hours!==x.hours && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.hours?.toString()} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('hours') && defectItemDifference.hours!==x.hours && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.hours?.toString()} />}
                                                        </LAGridItem>

                                                        <LAGridItem xs={12} >
                                                            <LABluePaper>
                                                                <LATextArea
                                                                    minRows={3}
                                                                    rowsMax={4}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={index}
                                                                    name="major_Component_Replacement_Notes"
                                                                    label="Major Component Replacement Notes"
                                                                    disabled={true}
                                                                    // disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["major_Component_Replacement_Notes"] ?
                                                                            props.subTableErrors["major_Component_Replacement_Notes"].message
                                                                            : undefined
                                                                    }
                                                                    // value={`${x.major_Component_Replacement_Notes ?? ""}\n${x.new_Major_Component_Replacement_Notes ?? ""}` ?? ""}
                                                                    value={x.major_Component_Replacement_Notes ?? ""}
                                                                    onChange={props.handleChange}
                                                                />
                                                                <LATextArea
                                                                    style={{ backgroundColor: "white"}}
                                                                    minRows={1}
                                                                    rowsMax={4}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    indexPosition={index}
                                                                    name="new_Major_Component_Replacement_Notes"
                                                                    label="Add Notes"
                                                                    disabled={(props.repairLineRole === "Manager") ? undefined : (props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                    errorText={
                                                                        props.subTableErrors["new_Major_Component_Replacement_Notes"] ?
                                                                            props.subTableErrors["new_Major_Component_Replacement_Notes"].message
                                                                            : undefined
                                                                    }
                                                                    value={x.new_Major_Component_Replacement_Notes ?? ""}
                                                                    onChange={props.handleChange}
                                                                />
                                                                {difference && difference.major_Component_Replacement_Notes !==x.major_Component_Replacement_Notes  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.major_Component_Replacement_Notes} />}
                                                                {defectItemDifference && defectItemDifference.hasOwnProperty('major_Component_Replacement_Notes') && defectItemDifference.major_Component_Replacement_Notes !==x.major_Component_Replacement_Notes  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.major_Component_Replacement_Notes} />}
                                                            </LABluePaper>
                                                        </LAGridItem>

                                                        {/* <LAGridItem xs={12} sm={6} md={6}>
                                                            <LATextArea
                                                                minRows={3}
                                                                rowsMax={4}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                indexPosition={index}
                                                                name="major_Component_Replacement_Notes"
                                                                label="Major Component Replacement Notes"
                                                                disabled={true}
                                                                // disabled={(props.repairLineRole !== "Mechanic" && props.repairLineRole !== "Manager") ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                                                errorText={
                                                                    props.subTableErrors["major_Component_Replacement_Notes"] ?
                                                                        props.subTableErrors["major_Component_Replacement_Notes"].message
                                                                        : undefined
                                                                }
                                                                value={x.major_Component_Replacement_Notes ?? ""}
                                                                onChange={props.handleChange}
                                                            />
                                                            {difference && difference.major_Component_Replacement_Notes !==x.major_Component_Replacement_Notes  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.major_Component_Replacement_Notes} />}
                                                            {defectItemDifference && defectItemDifference.hasOwnProperty('major_Component_Replacement_Notes') && defectItemDifference.major_Component_Replacement_Notes !==x.major_Component_Replacement_Notes  && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.major_Component_Replacement_Notes} />}
                                                        </LAGridItem> */}

                                                    </LAGrid>
                                                </LAGreenPaper>
                                            </LAGridItem>
                                        }
                                </>}

                                {(x.damaged === "Yes") && <>
                                    <LAGridItem xs={12} sm={5} md={3}>
                                        <LATextArea
                                            minRows={3}
                                            rowsMax={4}
                                            fullWidth={true}
                                            variant="outlined"
                                            indexPosition={index}
                                            name="damaged_Notes"
                                            label="Damaged Notes"
                                            disabled={(!(fieldManager || shopManager || lubeManager || props.repairLineRole === "Mechanic")) ? true : disableByStatus ? disableByStatus : (props.getRole === ReadAndWrite) ? disable : true}
                                            value={x.damaged_Notes ?? ""}
                                            errorText={
                                                props.subTableErrors["damaged_Notes"] ?
                                                    props.subTableErrors["damaged_Notes"].message
                                                    : undefined
                                            }
                                            onChange={props.handleChange}
                                        />
                                        {difference && difference.damaged_Notes !==x.damaged_Notes && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.damaged_Notes} />}
                                        {defectItemDifference && defectItemDifference.hasOwnProperty('damaged_Notes') && defectItemDifference.damaged_Notes !== x.damaged_Notes && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.damaged_Notes} />}
                                    </LAGridItem>
                                </>}

                                {x.id !== 0 && <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        type="string"
                                        label="Work Order #"
                                        fullWidth={true}
                                        variant="outlined"
                                        // disabled={props.workOrder || disableByStatus}
                                        disabled={true}
                                        indexPosition={index}
                                        name="work_Order_No"
                                        value={x.work_Order_No}
                                        onChange={props.handleChange}
                                    />
                                    {difference && difference.work_Order_No !==x.work_Order_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.work_Order_No} />}
                                    {defectItemDifference && defectItemDifference.hasOwnProperty('work_Order_No') && defectItemDifference.work_Order_No !==x.work_Order_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.work_Order_No} />}
                                </LAGridItem>}

                                {x.id !== 0 && <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        type="string"
                                        label="Job No #"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        indexPosition={index}
                                        name="job_No"
                                        value={x.job_No ?? ""}
                                        onChange={props.handleChange}
                                    />
                                    {defectItemDifference && defectItemDifference.hasOwnProperty('job_No') && defectItemDifference.job_No !==x.job_No && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.job_No} />}
                                </LAGridItem>}

                                {x.id !== 0 && <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        type="string"
                                        label="Cost Code"
                                        fullWidth={true}
                                        variant="outlined"
                                        disabled={true}
                                        indexPosition={index}
                                        name="cost_Code"
                                        value={x.cost_Code ?? ""}
                                        onChange={props.handleChange}
                                    />
                                    {defectItemDifference && defectItemDifference.hasOwnProperty('cost_Code') && defectItemDifference.cost_Code !==x.cost_Code && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={defectItemDifference.cost_Code} />}
                                
                                </LAGridItem>}

                                {x.id > 0 && x.logs.length > 0 && <LAGridItem xs={12} >
                                    <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="Mechanic Logs" expanded={isMechanicLogsExpanded} onClick={handleMechanicLogsExpand}>
                                        <LAGridItem xs={12}>
                                            {x.logs && <LAPaperWithPadding>
                                                <LAGrid spacing={1} className="text-center">
                                                    <LAGridItem xs={12}>
                                                        <LADevExtremeGrid
                                                            height={600}
                                                            export={true}
                                                            id="logsHistory"
                                                            data={x.logs}
                                                            searchPanel={false}
                                                            filterHeader={true}
                                                            columnsHiding={true}
                                                            sumColumn={[{ caption: "Total Hours:", columnName: "hours" }]}
                                                            exportFileName="Mechanic_Logs"
                                                            onClick={undefinedFunction}
                                                            onCustomClick={props.handleMechLogsVersionHistoryClick}
                                                            columns={[
                                                                { name: "date", caption: "Date", type: "date", sortDesc: true, width: 140 },
                                                                { name: "component_Code", caption: "Component Code", type: "string", width: 140  },
                                                                { name: "mechanic_Name", caption: "Mechanic Name", type: "string", width: 180  },
                                                                { name: "hours", caption: "Hours", type: "string", width: 140 },
                                                                { name: "comments", caption: "Comments", type: "string" },
                                                                { name: "history", caption: "Version History", type: "button", width: 180 },
                                                                { name: "modified_By", caption: "Modified By", type: "string", show: false},
                                                                { name: "modified", caption: "Modified", type: "datetime", show: false },
                                                            ]}
                                                        />
                                                    </LAGridItem>
                                                </LAGrid>
                                            </LAPaperWithPadding>}
                                        </LAGridItem>
                                    </LAExpansionPanel>
                                </LAGridItem>}

                                <LAGridItem xs={12}>
                                    <LAGridItem xs={12} className="text-center">
                                        {!imageSrc ? <strong>Upload Images</strong> : <strong>Highlight Image</strong>}
                                        {!imageSrc && !disable && !disableByStatus && props.repairLineRole !== "Employee" && <LADropZone onFileDrop={(e: any) => props.handleUpload(index, e)} />}
                                    </LAGridItem>
                                    <br />

                                    {!imageSrc ? <LAGrid>
                                        {props.popupImgs
                                            .map((q, ind) => {
                                                const onImgDelete = (): void => props.onImgDelete(q.name, index, ind);
                                                const base64 = q.base64String !== null ? q.base64String : webConfig.imgApiBaseUrl + q.name;
                                                const openImage = () => q.base64String ? handleOpenImage(q.base64String, ind) : undefinedFunction;
                                                const onImageName = (name: string, value: string): void => props.onImageName(name ? name : "", value, index, ind);

                                                return (
                                                    <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                        <LAPaperWithLessPadding>
                                                            <LAThumbnailWithLink
                                                                alt={q.name}
                                                                key={ind}
                                                                url={base64}
                                                                id={(ind + index + "_").toString()}
                                                            />

                                                            <LATextField
                                                                className="mt-2"
                                                                label="Description"
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                name="description"
                                                                disabled={disableByStatus || disable}
                                                                value={q.description}
                                                                onChange={onImageName}
                                                                errorText={
                                                                    q.name.length === 0 ?
                                                                        FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                        : undefined
                                                                }
                                                            />

                                                            {q.base64String && <LAButton
                                                                onClick={openImage}
                                                                label="Highlight Image"
                                                            />}

                                                            <LAIconButton
                                                                key={ind}
                                                                label="Delete"
                                                                icon={<DeleteIcon />}
                                                                onClick={onImgDelete}
                                                                disabled={disableByStatus ? disableByStatus : undefined}
                                                            />
                                                        </LAPaperWithLessPadding>
                                                    </LAGridItem>
                                                );

                                            })}
                                    </LAGrid>
                                        : <Marker
                                            imageSrc={imageSrc.src}
                                            onSave={handleEditImageSave}
                                            onCancel={handleEditImageCancel}
                                        />}

                                </LAGridItem>

                                {/* {!imageSrc && x.mechanic_Logs && <LAGridItem xs={12} className="text-center">
                                    {x.mechanic_Logs.length > 0 ? <>
                                        <LASecondaryButton
                                            label="View Mechanic Logs"
                                            onClick={props.onLogsOpen}
                                        />

                                        <RepairLineLogPopup
                                            open={props.logsPopup}
                                            data={x.mechanic_Logs}
                                            onClose={props.onLogsClose}
                                        />
                                    </> : <LAErrorBox text="No mechanic logs found..." />}
                                </LAGridItem>} */}

                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Created Location Info "
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="created_Location_Info"
                                    value={x.created_Location_Info ?? ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}
                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Repair Location Info "
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="repair_Location_Info"
                                    value={x.repair_Location_Info ?? ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}
                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Requested By"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="created_By"
                                    value={x.created_By}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}
                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Created"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="created"
                                    value={x.created ? new Date(x.created).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Last Modified"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="modified"
                                    value={x.modified ? new Date(x.modified).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {x.id > 0 && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Last Modified By"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="modified_By"
                                    value={x.modified_By}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {x.id > 0 && x.date_Completed && <LAGridItem xs={12} sm={6} md={2}>
                                <LATextField
                                    type="string"
                                    label="Date Completed"
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="date_Completed"
                                    value={x.date_Completed ? new Date(x.date_Completed).toLocaleString() : ""}
                                    onChange={undefinedFunction}
                                />
                            </LAGridItem>}

                            {x.id > 0 && <LAGridItem xs={12}>
                                <LACheckBox
                                    name="send_Email"
                                    label="Send Email"
                                    disabled={(disableByStatus ? true : undefined)}
                                    onChange={handleCheckBox}
                                    value={x.send_Email === "No" ? false : true}
                                />
                                {difference && difference.send_Email && difference.send_Email !== x.send_Email && <LAErrorBox color={EXTRA_DARK_GREY_COLOR} text={difference.send_Email} />}
                            </LAGridItem>}

                            {x.id > 0  && <LAGridItem xs={12} className="text-center">
                                {x.inspection_Form_ID && x.inspection_Form_ID > 0 ? 
                                    <LAGreenButton
                                        label="View/Edit Inspection Form"
                                        onClick={() => props.handleInspectionFormClick(x?.id, x?.inspection_Form_ID)}
                                        disabled={x.unit_ID > 0 ? undefined : true}
                                        // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                    />
                                    :
                                    <LAOrangeButton
                                        label="Add Inspection Form"
                                        onClick={() => props.handleInspectionFormClick(x?.id)}
                                        disabled={x.unit_ID > 0 ? undefined : true}
                                        // startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                    /> 
                                }
                            </LAGridItem>}

                            <LAGridItem xs={12} >
                                <></>
                            </LAGridItem>

                            {((props.serverErrorSaveDefectItem !== null) && (props.serverErrorSaveDefectItem.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={props.serverErrorSaveDefectItem} />
                            </LAGridItem>}

                            {!imageSrc && <LAGridItem xs={12} sm={6} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    onCancel={props.handleClose}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    onSave={handlePopupSave}
                                    // disableSave={props.workOrder ? (props.repairLineRole === "Employee") || disableComponentSave(props.editData) : undefined}
                                    disableSave={(props.workOrder ? false : undefined) || disableComponentSave(props.editData, props.usedPartsUnit) || disableSave }
                                />
                            </LAGridItem>}

                            </LAGrid>
                        </LAGridItem>
                    </LAGrid>
                    {props.repairLineVersionHistoryComponentPopup && <RepairLineVersionHistoryDefectItem
                        data={props.repairLineVersionHistorypopupData}
                        display={display}
                        open={props.repairLineVersionHistoryComponentPopup}
                        status={props.getVersionHistory.kind}
                        onCancel={props.handleVersionHistoryComponentPopupClose}
                    />}
                    {props.mechLogsVersionHistoryPopup && <MechanicLogsVersionHistory
                        data={props.mechanicLogsVersionHistorypopupData}
                        // display={display}
                        open={props.mechLogsVersionHistoryPopup}
                        status={props.getMechanicLogsVersionHistory.kind}
                        onCancel={props.handleMechLogsVersionHistoryPopupClose}
                    />}
                </LAPaperWithLessPadding>
            </RepairLineComponentStyles>
        );
    } else {
        return (
            <></>
        )
    }
};

const disableComponentSave = (x: IRepairLineItem | undefined, usedPartsUnit: boolean): true | undefined => {
    if (x)
        if ((x.status === ComponentStatusList[3].name) &&
            ((!usedPartsUnit && ((x.meter_Reading === undefined) || (x.meter_Reading === null) || (x.meter_Reading.toString() === "")))
                || (x.component_Code === undefined) || (x.component_Code === null) || (x.component_Code === "")
            ) && x.unit_ID > 0) {
            return true;
        } else {
            if ((x.major_Component_Replacement === "Yes" && x.status === ComponentStatusList[3].name)
                && (
                    ((x.serial_No === undefined) || (x.serial_No === null))
                    || ((x.hours === undefined) || (x.hours === null))
                    // || ((x.type === undefined) || (x.type === null))
                    // || ((x.new_Major_Component_Replacement_Notes === undefined) || (x.new_Major_Component_Replacement_Notes === null))
                    // || ((x.major_Component_Replacement_Notes === undefined) || (x.major_Component_Replacement_Notes === null))
                )) {
                return true;
            }
            if ((x.damaged === "Yes" && x.status === ComponentStatusList[3].name)
                && (
                    ((x.damaged_Notes === undefined) || (x.damaged_Notes === null || x.damaged_Notes === ""))
                )) {
                return true;
            }
            if (x.defect_Item.length === 0)
                return true;
        }

    return undefined;
};

interface IMarker {
    imageSrc: string;
    onCancel: () => void;
    onSave: (src: string) => void;
};

export const Marker: React.FC<IMarker> = (props: IMarker) => {
    const canvasRef: any = useRef(null);

    const getBase64WithHighlight = () => {
        let canvas1: any = document.querySelector('div > canvas:first-child');
        let canvas2: any = document.querySelector('div > canvas:nth-child(2)');
        let combinedCanvas = document.createElement('canvas');
        let context: any = combinedCanvas.getContext('2d');

        combinedCanvas.width = canvas1.width;
        combinedCanvas.height = canvas1.height;

        context.drawImage(canvas1, 0, 0);
        context.drawImage(canvas2, 0, 0, canvas1.width, canvas1.height);

        let combinedImageData = combinedCanvas.toDataURL('image/png');

        props.onSave(combinedImageData);
    };

    return (
        <LAPaperWithLessPadding>
            <CanvasDraw
                ref={canvasRef}
                imgSrc={props.imageSrc}
                style={{ border: '1px solid #ccc' }}
                brushColor="#FF5733"
                brushRadius={5}
                hideGridX={true}
                hideGridY={true}
            />

            <LASecondaryButton
                label="Cancel"
                className="mt-1"
                onClick={props.onCancel}
            />

            <LAButton
                label="Save Image"
                className="ml-3 mt-1"
                onClick={getBase64WithHighlight}
            />
        </LAPaperWithLessPadding>
    );
};
