import { IFilterCut } from "../getFilterCuts/getFilterCutsConstants";

export enum IADD_FILTER_CUT_REQUEST {
    REQUEST = "addFilterCut/ADD_FILTER_CUT_REQUEST",
    SUCCESS = "addFilterCut/ADD_FILTER_CUT_SUCCESS",
    FAILED = "addFilterCut/ADD_FILTER_CUT_FAILED"
};

export interface IAddFilterCutRequest {
    token: string;
    request: IAddFilterCut;
};

export interface IAddFilterCut extends IFilterCut {

}