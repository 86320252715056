import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST, IGetServiceTruckCommunicationLogs, IGetServiceTruckCommunicationLogsRequest } from "./getServiceTruckCommunicationLogsConstants";

export interface IGetServiceTruckCommunicationLogsLoadAction {
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.REQUEST;
    data: IGetServiceTruckCommunicationLogsRequest
}
export const getServiceTruckCommunicationLogsLoadAction = (data: IGetServiceTruckCommunicationLogsRequest): IGetServiceTruckCommunicationLogsLoadAction => ({
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.REQUEST,
    data
});
export interface IGetServiceTruckCommunicationLogsSuccessAction {
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>;
}
export const getServiceTruckCommunicationLogsLoadSuccessAction = (list: SurewayAPIResponse<ById<IGetServiceTruckCommunicationLogs>>): IGetServiceTruckCommunicationLogsSuccessAction => ({
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.SUCCESS,
    list
});
export interface IGetServiceTruckCommunicationLogsLoadFailedAction {
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.FAILED;
    message: string;
}
export const getServiceTruckCommunicationLogsLoadFailedAction = (message: string): IGetServiceTruckCommunicationLogsLoadFailedAction => ({
    type: IGET_SERVICE_TRUCK_COMMUNICATION_LOGS_REQUEST.FAILED,
    message
});
