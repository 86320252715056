import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_CONTROL_PLAN_NEW_REQUEST, IGetControlPlansNew } from "./getControlPlansNewConstants";
import { IGetControlPlansNewLoadAction, IGetControlPlansNewLoadFailedAction, IGetControlPlansNewSuccessAction } from "./getControlPlansNewActions";
import { ISAVE_CONTROL_PLAN_REQUEST } from "../saveControlPlan/saveControlPlanConstants";
// import { IUPDATE_CONTROL_PLAN_NEW_REQUEST } from "../updateControlPlanNew/updateControlPlanNewConstants";
// import { IUpdateControlPlanNewSuccessAction } from "../updateControlPlanNew/updateControlPlanNewActions";
import { ISaveControlPlanSuccessAction } from "../saveControlPlan/saveControlPlanActions";

type Actions =
    | IGetControlPlansNewLoadAction
    | IGetControlPlansNewSuccessAction
    | IGetControlPlansNewLoadFailedAction
    | ISaveControlPlanSuccessAction
    // | IUpdateControlPlanNewSuccessAction
    | IFlushDataSuccessAction;

export const GetControlPlansNewReducer = (state: Server<SurewayAPIResponse<ById<IGetControlPlansNew>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetControlPlansNew>>> => {
    switch (action.type) {
        case IGET_CONTROL_PLAN_NEW_REQUEST.REQUEST:
            return loading;

        case IGET_CONTROL_PLAN_NEW_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_CONTROL_PLAN_NEW_REQUEST.FAILED:
            return failed(action.message);
        
        case ISAVE_CONTROL_PLAN_REQUEST.SUCCESS:
            return notLoaded;

        // case IUPDATE_CONTROL_PLAN_NEW_REQUEST.SUCCESS:
        //     return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};