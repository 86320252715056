import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_JOBS_PITS_REQUEST {
    REQUEST = "getJobsPits/GET_JOBS_PITS_REQUEST",
    SUCCESS = "getJobsPits/GET_JOBS_PITS_SUCCESS",
    FAILED = "getJobsPits/GET_JOBS_PITS_FAILED"
};

export interface IJobsPitsRequest extends ISurewayTokenRequestBody {

};

export interface IJobPit {
    id: number;
    year?: number;
    job: string;
    description: string;
    requested_By: string;
    active: string;
    location: string;
    created: string;
    created_By: string;
    modified: string;
    modified_By: string;
};