import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_BULK_METER_READINGS_REQUEST, IBulkMeterReading, IBulkMeterReadingsRequest } from "./getBulkMeterReadingsConstants";

export interface IGetBulkMeterReadingsLoadAction {
    type: IGET_BULK_METER_READINGS_REQUEST.REQUEST;
    data: IBulkMeterReadingsRequest
}

export const getBulkMeterReadingsLoadAction = (data: IBulkMeterReadingsRequest): IGetBulkMeterReadingsLoadAction => ({
    type: IGET_BULK_METER_READINGS_REQUEST.REQUEST,
    data
});

export interface IGetBulkMeterReadingsSuccessAction {
    type: IGET_BULK_METER_READINGS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IBulkMeterReading>>;
}

export const getBulkMeterReadingsLoadSuccessAction = (list: SurewayAPIResponse<ById<IBulkMeterReading>>): IGetBulkMeterReadingsSuccessAction => ({
    type: IGET_BULK_METER_READINGS_REQUEST.SUCCESS,
    list
});

export interface IGetBulkMeterReadingsLoadFailedAction {
    type: IGET_BULK_METER_READINGS_REQUEST.FAILED;
    message: string;
}

export const getBulkMeterReadingsLoadFailedAction = (message: string): IGetBulkMeterReadingsLoadFailedAction => ({
    type: IGET_BULK_METER_READINGS_REQUEST.FAILED,
    message
});
