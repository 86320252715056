import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IADD_FOREMAN_MESSAGING_REQUEST, IAddForemanMessagingRequest } from "./addForemanMessagingConstants";


export interface IAddForemanMessagingLoadAction {
    type: IADD_FOREMAN_MESSAGING_REQUEST.REQUEST;
    data: IAddForemanMessagingRequest
}
export const addForemanMessagingLoadAction = (data: IAddForemanMessagingRequest): IAddForemanMessagingLoadAction => ({
    type: IADD_FOREMAN_MESSAGING_REQUEST.REQUEST,
    data
});
export interface IAddForemanMessagingSuccessAction {
    type: IADD_FOREMAN_MESSAGING_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addForemanMessagingLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddForemanMessagingSuccessAction => ({
    type: IADD_FOREMAN_MESSAGING_REQUEST.SUCCESS,
    message
});
export interface IAddForemanMessagingLoadFailedAction {
    type: IADD_FOREMAN_MESSAGING_REQUEST.FAILED;
    message: string;
}
export const addForemanMessagingLoadFailedAction = (message: string): IAddForemanMessagingLoadFailedAction => ({
    type: IADD_FOREMAN_MESSAGING_REQUEST.FAILED,
    message
});
