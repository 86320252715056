import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_REPAIR_LINES_WO_UNITS_REQUEST {
    REQUEST = "getRepairLineWOUnits/GET_REPAIR_LINES_WO_UNITS_REQUEST",
    SUCCESS = "getRepairLineWOUnits/GET_REPAIR_LINES_WO_UNITS_SUCCESS",
    FAILED = "getRepairLineWOUnits/GET_REPAIR_LINES_WO_UNITS_FAILED"
};

export interface IGetRepairLineWOUnitsRequest extends ISurewayTokenRequestBody {

};

export interface IRepairLineWOUnits {
    id: number;
    unit_ID: number;
    work_Order_No: string;
    unit_No?: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
};