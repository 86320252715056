import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_EQUIPMENT_TYPES_REQUEST, IGetEquipmentHistoryColumn } from "./getEquipmentHistoryColumnConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetEquipmentHistoryColumnsLoadAction, IGetEquipmentHistoryColumnsLoadFailedAction, IGetEquipmentHistoryColumnsSuccessAction, getEquipmentHistoryColumnsLoadFailedAction, getEquipmentHistoryColumnsLoadSuccessAction } from "./getEquipmentHistoryColumnActions";

export const getEquipmentHistoryColumnsEpic: Epic = (
    action$: ActionsObservable<IGetEquipmentHistoryColumnsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetEquipmentHistoryColumnsSuccessAction | IGetEquipmentHistoryColumnsLoadFailedAction> =>
    action$.ofType(IGET_EQUIPMENT_TYPES_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetEquipmentHistoryColumn[]>>(
                    END_POINTS.FIELD.GET_EQUIPMENT_HISTORY_COLUMN,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetEquipmentHistoryColumn[]>): IGetEquipmentHistoryColumnsSuccessAction => {
                            return getEquipmentHistoryColumnsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getEquipmentHistoryColumnsLoadFailedAction(response.message)))
                    )
            )
        );