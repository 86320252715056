
export enum IUPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST {
    REQUEST = "updateRepairLineByMechanic/UPDATE_REPAIR_LINE_BY_MECHANIC_REQUEST",
    SUCCESS = "updateRepairLineByMechanic/UPDATE_REPAIR_LINE_BY_MECHANIC_SUCCESS",
    FAILED = "updateRepairLineByMechanic/UPDATE_REPAIR_LINE_BY_MECHANIC_FAILED"
};

export interface IUpdateRepairLineByMechanicRequest {
    token: string;
    request: IUpdateRepairLineByMechanic;
};

export interface IUpdateRepairLineByMechanic {
    ID: number;
    Unit_ID: number;
    Modified_By: string;
    Assigned_Mechanic_ID: number;
    Items: IUpdateRepairLineByMechanicItem[];
}

export interface IUpdateRepairLineByMechanicItem {
    ID: number;
    Status: string;
    Images: string;
    Comments: string;
    Meter_Reading?: number;
    parts: any[];
    Pictures: any[];
}