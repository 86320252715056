import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";
import { IAttachmentListFile, IAttachmentListImage } from "../addAttachment/addAttachmentConstants";

export enum IGET_ATTACHMENTS_REQUEST {
    REQUEST = "getAttachments/GET_ATTACHMENTS_REQUEST",
    SUCCESS = "getAttachments/GET_ATTACHMENTS_SUCCESS",
    FAILED = "getAttachments/GET_ATTACHMENTS_FAILED"
};

export interface IGetAttachmentRequest extends ISurewayTokenRequestBody {

};

export interface IAttachment {
    id: number;
    attachment_No: string;
    attachment_Unit_ID: number;
    type_ID?: number;
    style_ID?: number;
    style: string;
    coupler_Design_ID?: number;
    weight?: number;
    width?: number;
    length?: number;
    capacity?: number;
    make: string;
    model: string;
    serial_Number: string;
    owner: string;
    inspection_Date: string;
    detachable: string;
    notes: string;
    photos: string; //Not sure
    file_Attachments: string;
    status: string;
    working_Site_Sub_ID?: number;
    move_Status: string;
    move_ID?: number | null;
    repair_Status: string;
    created_By: string;
    modified_By: string;
    created: string;
    modified: string;
    files: IAttachmentListFile[],
    images: IAttachmentListImage[];
    type: string;
    coupler_Design: string;
    location: string;
    attached_Unit_No: string;
    job_No: string;
};