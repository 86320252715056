import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { getJobs } from "../../../../redux/accounting/jobs/getJobs/getJobsAccessor";
import { getJobsLoadAction } from "../../../../redux/accounting/jobs/getJobs/getJobsActions";
import { IJob, IJobsRequest } from "../../../../redux/accounting/jobs/getJobs/getJobsConstants";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { IToken } from "../../../../redux/getToken/getTokenConstants";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ROUTE } from "../../../routes";
import { LAButton } from "../../../shared/buttons";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, YesOrNo } from "../../../shared/constExports";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { AddIcon } from "../../../shared/icons";
import LALoading from "../../../shared/loading";
import PageSpacing from "../../../shared/pageSpacing";
import { LAPaperWithPadding } from "../../../shared/paper";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { WHITE_COLOR } from "../../../shared/theme";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../../shared/errorBox";


interface IJobsHomeStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    jobs: Server<SurewayAPIResponse<ById<IJob>>>;
};

interface IJobsHomeDispatchProps {
    getJobsRequest: (data: IJobsRequest) => unknown;
};

interface IJobsHomeState {
    activeJobs: IJob[];
    activeView: boolean;
};

const JobsHomeStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-family: Arial, Helvetica, sans-serif;
        text-decoration: underline;
        font-size: 16px;
    };
`;

type IJobsHomeProps =
    RouteComponentProps &
    IJobsHomeDispatchProps &
    IJobsHomeStoreProps;

class JobsHome extends Component<IJobsHomeProps, IJobsHomeState> {
    public constructor(props: IJobsHomeProps) {
        super(props);
        this.state = {
            activeJobs: [],
            activeView: true
        };
    }

    public componentDidMount(): void {
        this.callReduxForData();
    };

    public async componentDidUpdate(prevProps: IJobsHomeProps): Promise<void> {
        if (this.props.jobs !== prevProps.jobs) {
            this.callReduxForData();
        }
    };

    public render(): ReactNode {

        const { jobs, token } = this.props;
        const { activeView, activeJobs } = this.state;
        const getRole = pageAccessCheck(token, "jobList");
        const data = activeView ? activeJobs : hasPayload(jobs) ? Object.values(jobs.payload.response) : [];

        return (
            <PageSpacing title="Jobs Home" description="Sureway - Sureway Jobs" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) &&
                    <JobsHomeStyles>
                        <LAGrid>

                            <LAGridItem xs={12}>

                                <LAButton
                                    label="Add New" 
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2 className="text-center">JOBS</h2>
                                <hr />

                                {jobs.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Jobs..." />}
                                {jobs.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Jobs..." />}

                                {jobs.kind === STATUS_ENUM.SUCCEEDED && <LAGrid>
                                    
                                    <LAGridItem xs={12}>
                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                        <FormControlLabel value="active" control={<Radio />} label="Active Jobs (M)" />
                                        <FormControlLabel value="all" control={<Radio />} label="All Jobs" />
                                    </RadioGroup>
                                </LAGridItem>

                                    <LAGridItem xs={12}>
                                    <LADevExtremeGrid
                                    data={data}
                                    onEdit={this.onEdit}
                                    onClick={this.onClick}
                                    searchPanel={true}
                                    filterHeader={true}
                                    export={true}
                                    actionWidth={120}
                                    removeStyleBtn={true}
                                    height={1200}
                                    id="jobsList"
                                    customRowColor={true}
                                    exportFileName="jobs"
                                    storageKey="jobsSessionKey"
                                    getRefreshDataCall={this.getDataForTable}
                                    columns={[        
                                        { name: "job_No", caption: "Job #", type: "string", width: 100 , sortAsc: true},                                
                                        { name: "type", caption: "Type", type: "string",width: 100 , sortAsc: true },                                        
                                        { name: "name", caption: "Name", type: "string" },
                                        { name: "active", caption: "Active", type: "string" },
                                        { name: "client", caption: "Client", type: "string" },
                                        { name: "coordinator", caption: "Coordinator", type: "string" },
                                        { name: "project_Manager", caption: "Project Manager", type: "string" },
                                        { name: "superintendent", caption: "Superintendent", type: "string" },                                        
                                        { name: "location", caption: "Location", type: "string" },
                                        { name: "working_Site", caption: "Working Site", type: "string" },
                                        { name: "company", caption: "Company", type: "string", width: 100 },
                                        { name: "year", caption: "Year", type: "number", width: 80 },
                                        { name: "modified", caption: "Modified", type: "datetime" },
                                        { name: "modified_By", caption: "Modified By", type: "string" }
                                    ]}
                                />
                                    </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </JobsHomeStyles>
    }
            </PageSpacing>
        );

    }

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });

        let activeView = !this.state.activeView;
            const getStorageValue = localStorage.getItem("key_jobs");
            if(getStorageValue)
            {
                if(activeView)
                {
                    localStorage.setItem("key_jobs", "active");
                }
                else
                {
                    localStorage.setItem("key_jobs", "all");
                }
            }
            else
            {
                localStorage.setItem("key_jobs", "active");
            }
    };
    
    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.JOBS.JOB()
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.JOBS.JOB()
        });
    };

    private callReduxForData = (): void => {

        let activeView = this.state.activeView;
            const getStorageValue = localStorage.getItem("key_jobs");
            if(getStorageValue)
            {
                if(getStorageValue == "active")
                {
                    activeView = true;
                }
                else
                {
                    activeView = false;
                }
            }
            else
            {
                localStorage.setItem("key_jobs", "active");
            }
            

        if (hasPayload(this.props.token)) {

            if (pageAccessCheck(this.props.token, "jobList") !== NotApplicable) {
                    if (isNotLoaded(this.props.jobs)) {
                        this.getDataForTable();
                    };
            } else {
                
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                  });
            }

            if(hasPayload(this.props.jobs)){
                this.setState({ activeJobs: Object.values(this.props.jobs.payload.response).filter(x => x.active === YesOrNo[0].name && x.company === "M") });
            };

        };
        this.setState({activeView: activeView});
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.token) && pageAccessCheck(this.props.token, "jobList") !== NotApplicable) {
            this.props.getJobsRequest({
                token: this.props.token.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IJobsHomeStoreProps => ({
    jobs: getJobs(state),
    token: getToken(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IJobsHomeDispatchProps => ({
    getJobsRequest: (data: IJobsRequest) => dispatch(getJobsLoadAction(data))
});


export default connect(mapStateToProps, mapDispatchToProps)(JobsHome);