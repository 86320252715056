import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_FORM_BY_TEMPLATE_ID_REQUEST } from "./getFormByTemplateIDConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetFormByTemplateIDLoadAction, IGetFormByTemplateIDLoadFailedAction, IGetFormByTemplateIDSuccessAction, getFormByTemplateIDLoadFailedAction, getFormByTemplateIDLoadSuccessAction } from "./getFormByTemplateIDActions";
import { IChecklistForm } from '../getForms/getFormsConstants';

export const getFormByTemplateIDEpic: Epic = (
    action$: ActionsObservable<IGetFormByTemplateIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetFormByTemplateIDSuccessAction | IGetFormByTemplateIDLoadFailedAction> =>
    action$.ofType(IGET_FORM_BY_TEMPLATE_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IChecklistForm>>(
                    END_POINTS.FIELD.GET_FORM_BY_TEMPLATE_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IChecklistForm>): IGetFormByTemplateIDSuccessAction => {
                            return getFormByTemplateIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getFormByTemplateIDLoadFailedAction(response.message)))
                    )
            )
        );