import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IChecklistForm } from "../getForms/getFormsConstants";
import { IGET_FORM_BY_TEMPLATE_ID_REQUEST, IGetFormByTemplateIDRequest } from "./getFormByTemplateIDConstants";

export interface IGetFormByTemplateIDLoadAction {
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.REQUEST;
    data: IGetFormByTemplateIDRequest
}
export const getFormByTemplateIDLoadAction = (data: IGetFormByTemplateIDRequest): IGetFormByTemplateIDLoadAction => ({
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.REQUEST,
    data
});
export interface IGetFormByTemplateIDSuccessAction {
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IChecklistForm>;
}
export const getFormByTemplateIDLoadSuccessAction = (list: SurewayAPIResponse<IChecklistForm>): IGetFormByTemplateIDSuccessAction => ({
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.SUCCESS,
    list
});
export interface IGetFormByTemplateIDLoadFailedAction {
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.FAILED;
    message: string;
}
export const getFormByTemplateIDLoadFailedAction = (message: string): IGetFormByTemplateIDLoadFailedAction => ({
    type: IGET_FORM_BY_TEMPLATE_ID_REQUEST.FAILED,
    message
});
