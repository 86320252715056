
export enum IGET_COST_CODES_BY_JOB_ID_REQUEST {
    REQUEST = "getCostCodesByJobID/GET_COST_CODES_BY_JOB_ID_REQUEST",
    SUCCESS = "getCostCodesByJobID/GET_COST_CODES_BY_JOB_ID_SUCCESS",
    FAILED = "getCostCodesByJobID/GET_COST_CODES_BY_JOB_ID_FAILED"
};

export interface IGetCostCodesByJobIDRequest {
    request: {
        id: number;
    };
    token: string;
};

export interface IGetCostCodesByJobID {
    id: number;
    job: string;
    cost_Code: string;
    description: string;
}
