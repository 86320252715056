import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_TASKS_REQUEST, IGetTasks } from "./getTasksConstants";
import { IGetTasksLoadAction, IGetTasksLoadFailedAction, IGetTasksSuccessAction } from "./getTasksActions";
import { IAddTaskSuccessAction } from "../addTask/addTaskActions";
import { IADD_TASK_REQUEST } from "../addTask/addTaskConstants";
import { IUpdateTaskSuccessAction } from "../updateTask/updateTaskActions";
import { IUPDATE_TASK_REQUEST } from "../updateTask/updateTaskConstants";

type Actions =
    | IGetTasksLoadAction
    | IGetTasksSuccessAction
    | IGetTasksLoadFailedAction
    | IAddTaskSuccessAction
    | IUpdateTaskSuccessAction
    | IFlushDataSuccessAction;

export const GetTasksReducer = (state: Server<SurewayAPIResponse<ById<IGetTasks>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetTasks>>> => {
    switch (action.type) {
        case IGET_TASKS_REQUEST.REQUEST:
            return loading;

        case IGET_TASKS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_TASKS_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_TASK_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_TASK_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};