import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_VENDOR_ORDER_REQUEST, IUpdateVendorOrderRequest } from "./updateVendorOrderConstants";


export interface IUpdateVendorOrderLoadAction {
    type: IUPDATE_VENDOR_ORDER_REQUEST.REQUEST;
    data: IUpdateVendorOrderRequest
}
export const updateVendorOrderLoadAction = (data: IUpdateVendorOrderRequest): IUpdateVendorOrderLoadAction => ({
    type: IUPDATE_VENDOR_ORDER_REQUEST.REQUEST,
    data
});
export interface IUpdateVendorOrderSuccessAction {
    type: IUPDATE_VENDOR_ORDER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateVendorOrderLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateVendorOrderSuccessAction => ({
    type: IUPDATE_VENDOR_ORDER_REQUEST.SUCCESS,
    message
});
export interface IUpdateVendorOrderLoadFailedAction {
    type: IUPDATE_VENDOR_ORDER_REQUEST.FAILED;
    message: string;
}
export const updateVendorOrderLoadFailedAction = (message: string): IUpdateVendorOrderLoadFailedAction => ({
    type: IUPDATE_VENDOR_ORDER_REQUEST.FAILED,
    message
});
