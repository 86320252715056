import { IADD_EXTERNAL_USER_REQUEST } from "./addExternalUserConstants";
import { IAddExternalUserLoadAction, IAddExternalUserLoadFailedAction, IAddExternalUserSuccessAction } from "./addExternalUserActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../server";

type Actions =
    | IAddExternalUserLoadAction
    | IAddExternalUserSuccessAction
    | IAddExternalUserLoadFailedAction
    | IFlushDataSuccessAction;

export const AddExternalUserReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_EXTERNAL_USER_REQUEST.REQUEST:
            return loading;

        case IADD_EXTERNAL_USER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_EXTERNAL_USER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};