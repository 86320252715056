import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { IDispatch, IStore } from "../redux/reducers";
import { ROUTE } from "./routes";
import { getTokenFromUrl, undefinedFunction } from "./shared/constExports";
import { LAExpansionPanel } from "./shared/expansionPanel";
import LAGrid from "./shared/grid";
import LAGridItem from "./shared/gridList";
import { LAPaperWithPadding } from "./shared/paper";
import { BLUE_GREEN_COLOR, DARK_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "./shared/theme";

import { AddPhotoIcon, AttachmentIcon, DispatchIcon, DocumentIcon, DozerIcon, EquipmentTrackerIcon, FilterCutIcon, FilterIcon, FiltersIcon, JobsIcon, LocationIcon, PMSheetIcon, RepairLineIcon, SoctIcon, TimeLogIcon, TripodIcon, VendorIcon, WSSIcon } from "./shared/icons";



interface IHomeStoreProps {
};

interface IHomeDispatchProps {
};

interface IHomeState {
    showHeader: boolean;
    showFooter: boolean;
};

const HomeStyles = styled(LAPaperWithPadding)`
    margin: 40px 30px;
    word-break: break-word;

    .title {
        color: ${DARK_RED_COLOR};
        font-size: 40px;
        font-weight: bold;
        text-shadow: 2px 1px #b30003;
        
    };

    .homeMenu {
        color: #000;
        height: 90px;
        cursor: pointer;
        border-radius: 15px;
        padding: 5px;
        border: 2px solid #fff;
        background-color: #E7F2F8;
        box-shadow: 0 2px 10px 0 #b30003;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 5.0;
        margin-right: 10%;
        -webkit-transition: background 2.5s linear;
        -moz-transition: background 2.5s linear;
        -o-transition: background 2.5s linear;
        -ms-transition: background 2.5s linear;
        transition: background 2.5s linear;
    };

    .appTitle {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10%;
        text-shadow: 0.5px 1px #719de3;
    };

    .ITtitle {       
        color: #8289dd;
    };

    .TileSpacing{
        padding: 20px;
    }

    .FilterSytle{
        padding-top: 20px !important;
    }

    .HRtitle {
        color: #5d95f0;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;

        .title {
            font-size: 30px;
          }
      
          .appTitle {
            font-size: 16px;
          }
    };
`;

type IHomeProps =
    RouteComponentProps &
    IHomeDispatchProps &
    IHomeStoreProps;

class Home extends Component<IHomeProps, IHomeState> {
    public constructor(props: IHomeProps) {
        super(props);
        this.state = {
            showHeader: true,
            showFooter: true
        };
    }

    public render(): ReactNode {
        return (
            <HomeStyles>
                <LAGrid spacing={2} className="text-center">

                    <LAGridItem xs={12}>
                        <h2 className="title">SUREWAY APPS</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="EMPLOYEES" expanded={true} onClick={undefinedFunction}>
                            <LAGrid className="TileSpacing">

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onDaily}>
                                        <h2 className="appTitle HRtitle">Daily Field Logs</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onTimesheet}>
                                        <h2 className="appTitle HRtitle">Timesheet</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onVacation}>
                                        <h2 className="appTitle HRtitle">Vacations</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onSafety}>
                                        <h2 className="appTitle HRtitle">Safety Forms</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onSharepoint}>
                                        <h2 className="appTitle HRtitle">Sharepoint</h2>
                                    </div>
                                </LAGridItem>

                                {/* <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onExternalUsers}>
                                        <h2 className="appTitle HRtitle">External Users</h2>
                                    </div>
                                </LAGridItem> */}

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onNewHire}>
                                        <h2 className="appTitle HRtitle">Hire Form</h2>
                                    </div>
                                </LAGridItem>

                            </LAGrid>
                        </LAExpansionPanel>
                    </LAGridItem>


                    <LAGridItem xs={12}>
                        <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="FIELD" expanded={true} onClick={undefinedFunction}>
                            <LAGrid className="TileSpacing">

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onAccounting}>
                                    <JobsIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Sureway Jobs</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onLocations}>
                                    <LocationIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Locations</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onToolRentals}>
                                        <h2 className="appTitle HRtitle">Small Tool Rentals</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onEquipmentList}>
                                        <DozerIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Equipment List</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onAttachmentList}>
                                        <AttachmentIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Attachment List</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onSoct}>
                                    <SoctIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Site Oil Change Tracker</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle">
                                    <div className="homeMenu" onClick={this.onEquipmentTracker}>
                                        <EquipmentTrackerIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Equipment Tracker</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle">
                                    <div className="homeMenu" onClick={this.onFilter}>
                                        <FiltersIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Filters</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onPMSheets}>
                                    <PMSheetIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">PM SHEETS</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onRepairLine}>
                                        <RepairLineIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Repair Line</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onWSL}>
                                        <WSSIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Working Site Logs</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onDispatch}>
                                    <DispatchIcon width={38} height={38} />
                                        <h2 className="appTitle HRtitle">Service Trucks</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onImageGallery}>
                                        <AddPhotoIcon width={38} height={38}/>
                                        <h2 className="appTitle HRtitle">Image Gallery</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onFilterCuts}>
                                        <FilterCutIcon width={38} height={38}/>
                                        <FiltersIcon width={38} height={38}/>
                                        <h2 className="appTitle HRtitle">Filter Cuts</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onForms}>
                                        <DocumentIcon width={38} height={38}/>
                                        <h2 className="appTitle HRtitle">Forms</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onSurvey}>
                                        <TripodIcon width={40} height={40}  />
                                        <h2 className="appTitle HRtitle">Survey</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2} className="FilterSytle" >
                                    <div className="homeMenu" onClick={this.onVendorLogs}>
                                        <VendorIcon width={38} height={38}/>
                                        <h2 className="appTitle HRtitle">Vendor PO</h2>
                                    </div>
                                </LAGridItem>

                            </LAGrid>
                        </LAExpansionPanel>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="SIL" expanded={true} onClick={undefinedFunction}>
                            <LAGrid className="TileSpacing">

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onPSApps}>
                                        <h2 className="appTitle HRtitle">PropSense Apps</h2>
                                    </div>
                                </LAGridItem>

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onKantech}>
                                        <h2 className="appTitle HRtitle">Attendance</h2>
                                    </div>
                                </LAGridItem>

                            </LAGrid>
                        </LAExpansionPanel>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAExpansionPanel color={BLUE_GREEN_COLOR} textColor={WHITE_COLOR} label="SHOP" expanded={true} onClick={undefinedFunction}>
                            <LAGrid className="TileSpacing">

                                <LAGridItem xs={6} sm={4} md={2}>
                                    <div className="homeMenu" onClick={this.onUsedParts}>
                                        <h2 className="appTitle HRtitle">Used Parts</h2>
                                    </div>
                                </LAGridItem>

                            </LAGrid>
                        </LAExpansionPanel>
                    </LAGridItem>


                </LAGrid>
            </HomeStyles>
        );

    }

    private onFilterCuts = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.FILTER_CUT.FILTER_CUTS,
            search: getTokenFromUrl(false)
        });
    };

    private onKantech = (): void => {
        this.props.history.push({
            pathname: ROUTE.SIL.KANTECH.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onExternalUsers = (): void => {
        window.open(ROUTE.HIRE_FORM.EXTERNAL_USERS, "_self");
    };

    private onNewHire = (): void => {
        window.open(ROUTE.HIRE_FORM.INDEX, "_self");
    };

    private onDispatch = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.DISPATCH.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onImageGallery = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.IMAGE_GALLERY.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onForms = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.FORMS.FORM_RESPONSE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

  private onVendorLogs = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.VENDOR.INDEX,
        });
    }

    private onSurvey = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SURVEY.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onWSL = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.WORKING_SITE_LOGS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onLocations = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.LOCATION.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onRepairLine = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.REPAIR_LINE.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onFilter = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.FILTER.FILTER_REPORT,
            search: getTokenFromUrl(false)
        });
    };

    private onPMSheets = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.PM_SHEETS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onAccounting = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.JOBS.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onEquipmentList = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onAttachmentList = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.ATTACHMENT_LIST.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private onToolRentals = (): void => {
        window.open('https://shopadmin.sureway.ca/toolrentals', '_self');
    };

    private onPSApps = (): void => {
        window.open('https://apps.propsense.com', '_self');
    };

    private onSharepoint = (): void => {
        window.open('https://sharepoint.sureway.ca/Pages/Home.aspx', '_self');
    };

    private onSoct = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.SOCT.SOCT_LIST,
            search: getTokenFromUrl(false)
        });
    };

    private onDaily = (): void => {
        window.open('https://daily.sureway.ca/TSHeader/TSHeader', '_self');
    };

    private onTimesheet = (): void => {
        window.open('https://timesheet.sureway.ca', '_self');
    };

    private onVacation = (): void => {
        window.open('https://timesheet.sureway.ca/vacation', '_self');
    };

    private onSafety = (): void => {
        window.open('https://psi.sureway.ca/', '_blank');
    };

    private onUsedParts = (): void => {
        window.open('https://shopadmin.sureway.ca/parts/parts', '_self');
    };

    private onEquipmentTracker = (): void => {
        // this.props.history.push({
        //     pathname: ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST,
        //     search: getTokenFromUrl(false)
        // });
        window.open(ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUEST, "_self");
    };
}

const mapStateToProps = (state: IStore): IHomeStoreProps => ({
});

const mapDispatchToProps = (dispatch: IDispatch): IHomeDispatchProps => ({
});


export default connect(mapStateToProps, mapDispatchToProps)(Home);