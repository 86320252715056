import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IWORK_ORDER_REQUEST, IWorkOrderRequest } from "./workOrderConstants";


export interface IWorkOrderLoadAction {
    type: IWORK_ORDER_REQUEST.REQUEST;
    data: IWorkOrderRequest
}
export const workOrderLoadAction = (data: IWorkOrderRequest): IWorkOrderLoadAction => ({
    type: IWORK_ORDER_REQUEST.REQUEST,
    data
});
export interface IWorkOrderSuccessAction {
    type: IWORK_ORDER_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const workOrderLoadSuccessAction = (message: SurewayAPIResponse<string>): IWorkOrderSuccessAction => ({
    type: IWORK_ORDER_REQUEST.SUCCESS,
    message
});
export interface IWorkOrderLoadFailedAction {
    type: IWORK_ORDER_REQUEST.FAILED;
    message: string;
}
export const workOrderLoadFailedAction = (message: string): IWorkOrderLoadFailedAction => ({
    type: IWORK_ORDER_REQUEST.FAILED,
    message
});
