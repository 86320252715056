
export enum IGET_UNIT_UTILIZATION_BY_ID_REQUEST {
    REQUEST = "getUnitUtilizationByID/GET_UNIT_UTILIZATION_BY_ID_REQUEST",
    SUCCESS = "getUnitUtilizationByID/GET_UNIT_UTILIZATION_BY_ID_SUCCESS",
    FAILED = "getUnitUtilizationByID/GET_UNIT_UTILIZATION_BY_ID_FAILED"
};

export interface IGetUnitUtilizationByIDRequest {
    request: {
        id: number;
    };
    token: string;
};

export interface IGetUnitUtilizationByID {
    id: number;
    jobNumber: string;
    job_Description: string;
    costCode: string;
    cC_Description: string;
    date_: string;
    fieldLogNumber: string;
    employee: string;
    employeeCode: string;
    employeeCompany: string;
    employee_Hours: number;
    equipment_Type: string;
    unit_Number: string;
    unit_ID: number;
    equipment_Hours: string;
};