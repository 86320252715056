import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_FIELDEMPLOYEES_REQUEST , IFieldEmployee, IGetFieldEmployeesRequest } from "./getFieldEmployeesConstants";

export interface IGetFieldEmployeesLoadAction {
    type: IGET_FIELDEMPLOYEES_REQUEST.REQUEST;
    data: IGetFieldEmployeesRequest
}
export const getFieldEmployeesLoadAction = (data: IGetFieldEmployeesRequest): IGetFieldEmployeesLoadAction => ({
    type: IGET_FIELDEMPLOYEES_REQUEST.REQUEST,
    data
});
export interface IGetFieldEmployeesSuccessAction {
    type: IGET_FIELDEMPLOYEES_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IFieldEmployee>>;
}
export const getFieldEmployeesLoadSuccessAction = (list: SurewayAPIResponse<ById<IFieldEmployee>>): IGetFieldEmployeesSuccessAction => ({
    type: IGET_FIELDEMPLOYEES_REQUEST.SUCCESS,
    list
});
export interface IGetFieldEmployeesLoadFailedAction {
    type: IGET_FIELDEMPLOYEES_REQUEST.FAILED;
    message: string;
}
export const getFieldEmployeesLoadFailedAction = (message: string): IGetFieldEmployeesLoadFailedAction => ({
    type: IGET_FIELDEMPLOYEES_REQUEST.FAILED,
    message
});
