import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_KANTECH_ADJUSTMENTS_REQUEST, IKantechAdjustment } from "./getKantechAdjustmentsConstants";
import { IGetKantechAdjustmentsLoadAction, IGetKantechAdjustmentsLoadFailedAction, IGetKantechAdjustmentsSuccessAction } from "./getKantechAdjustmentsActions";
import { IUpdateKantechAdjustmentSuccessAction } from "../updateKantechAdjustment/updateKantechAdjustmentActions";
import { IAddKantechAdjustmentSuccessAction } from "../addKantechAdjustment/addKantechAdjustmentActions";
import { IADD_KANTECH_ADJUSTMENT_REQUEST } from "../addKantechAdjustment/addKantechAdjustmentConstants";
import { IUPDATE_KANTECH_ADJUSTMENT_REQUEST } from "../updateKantechAdjustment/updateKantechAdjustmentConstants";

type Actions =
    | IGetKantechAdjustmentsLoadAction
    | IGetKantechAdjustmentsSuccessAction
    | IGetKantechAdjustmentsLoadFailedAction
    | IAddKantechAdjustmentSuccessAction
    | IUpdateKantechAdjustmentSuccessAction
    | IFlushDataSuccessAction;

export const GetKantechAdjustmentsReducer = (state: Server<SurewayAPIResponse<ById<IKantechAdjustment>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IKantechAdjustment>>> => {
    switch (action.type) {
        case IGET_KANTECH_ADJUSTMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_KANTECH_ADJUSTMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_KANTECH_ADJUSTMENTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_KANTECH_ADJUSTMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_KANTECH_ADJUSTMENT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};