
export enum IGET_ROVER_KIT_REQUEST {
    REQUEST = "getRoverKit/GET_ROVER_KIT_REQUEST",
    SUCCESS = "getRoverKit/GET_ROVER_KIT_SUCCESS",
    FAILED = "getRoverKit/GET_ROVER_KIT_FAILED"
};

export interface IRoverKitRequest {
    token: string;
};

export interface IRoverKit {
    id: number;
    kit_No: string;
    kit_No_Display?: string;
    unit_No: string;
    model: string;
    data_Collector_Unit_No: string;
    data_Collector_Model: string;
    antenna: string;
    chargers: string;
    status: string;
    // site: string;
    wsS_ID: number | null;
    operator_ID?: number;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};