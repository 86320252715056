import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IADD_BASE_KIT_REQUEST } from "./addBaseKitConstants";
import {
    IAddBaseKitLoadAction, IAddBaseKitLoadFailedAction, IAddBaseKitSuccessAction,
    addBaseKitLoadFailedAction, addBaseKitLoadSuccessAction
} from "./addBaseKitActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const addBaseKitEpic: Epic = (
    action$: ActionsObservable<IAddBaseKitLoadAction>,
    store: StateObservable<IStore>,
    { post }: EpicDependencies
): Observable<IAddBaseKitSuccessAction | IAddBaseKitLoadFailedAction> =>
    action$.ofType(IADD_BASE_KIT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.ADD_BASE_KIT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IAddBaseKitSuccessAction | IAddBaseKitLoadFailedAction => {
                            if (response.message === SUCCESS_MESSAGE) {
                                return addBaseKitLoadSuccessAction(response);
                            } else {
                                return addBaseKitLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(addBaseKitLoadFailedAction(response.message)))
                    )
            )
        );