import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAGreyPaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { MEDIA_QUERY_PHONE, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { ActiveOrInActive, cmToFoot, cmToInches, cmToMeter, cubicMetersToCubicYards, cubicYardsToCubicMeters, deleteText, fileToBase64Image, footToCM, footToInches, footToMeter, getConvertedValue, getDate, getTokenFromUrl, inchesToCm, inchesToFoot, inchesTometer, kgToLbs, lbsToKg, meterToCM, meterToFoot, meterToInches, pageAccessCheck, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LAIconUploadButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon, DeleteIcon, DownloadIcon, UploadIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../shared/errorBox";
import { NotApplicable, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { v4 as uuidv4 } from "uuid";
import LALoading from "../../shared/loading";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
// import { IAttachment, IGetAttachmentRequest } from "../../../redux/field/attachment/getAttachments/getAttachmentsConstants";
import { IAddAttachmentRequest, IAddUpdateAttachment, IAddUpdateAttachmentListFile, IAddUpdateAttachmentListImage, IAttachmentListFile } from "../../../redux/field/attachment/addAttachment/addAttachmentConstants";
import { IUpdateAttachmentRequest } from "../../../redux/field/attachment/updateAttachment/updateAttachmentConstants";
import { addAttachmentStatus } from "../../../redux/field/attachment/addAttachment/addAttachmentAccessor";
import { addAttachmentLoadAction } from "../../../redux/field/attachment/addAttachment/addAttachmentActions";
import { updateAttachmentStatus } from "../../../redux/field/attachment/updateAttachment/updateAttachmentAccessor";
import { updateAttachmentLoadAction } from "../../../redux/field/attachment/updateAttachment/updateAttachmentActions";
// import { getAttachmentsStatus } from "../../../redux/field/attachment/getAttachments/getAttachmentsAccessor";
// import { getAttachmentsLoadAction } from "../../../redux/field/attachment/getAttachments/getAttachmentsActions";
import LADatePicker from "../../shared/datePicker";
import LATextArea from "../../shared/textArea";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LADropDownForOwners from "../equipment/equipmentOwnerDropdown";
import { IShopLookupOwners } from "../../../redux/field/getLookups/getLookupsConstants";
import { LADropZone } from "../../shared/dropZone";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { END_POINTS } from "../../../redux/endpoints";
import { LACheckBox } from "../../shared/checkBox";
import LALinkButton from "../../shared/linkButton";
import { IGetAttachmentVersionHistory, IGetAttachmentVersionHistoryRequest } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { getAttachmentVersionHistory } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryAccessor";
import { getAttachmentVersionHistoryLoadAction } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryActions";
import { AttachmentVersionHistory } from "./attachmentVersionHistory";
import { IGetAttachmentByID, IGetAttachmentByIDRequest } from "../../../redux/field/attachment/getAttachmentByID/getAttachmentByIDConstants";
import { getAttachmentByIDStatus } from "../../../redux/field/attachment/getAttachmentByID/getAttachmentByIDAccessor";
import { getAttachmentByIDLoadAction } from "../../../redux/field/attachment/getAttachmentByID/getAttachmentByIDActions";
import { ETPilotRequiredOptions } from "../equipment/equipment";

const RequiredFields: string[] = ["attachment_No", "status", "type_ID", "coupler_Design_ID", "style_ID"];

interface IAddUpdateAttachmentComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    // attachments: Server<SurewayAPIResponse<ById<IAttachment>>>;
    getAttachmentByID: Server<SurewayAPIResponse<IGetAttachmentByID>>;
    addAttachmentRequestStatus: Server<SurewayAPIResponse<string>>;
    updateAttachmentRequestStatus: Server<SurewayAPIResponse<string>>;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>>;
};

interface IAddUpdateAttachmentComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => unknown;
    getAttachmentByIDRequest: (data: IGetAttachmentByIDRequest) => unknown;
    addAttachmentRequest: (data: IAddAttachmentRequest) => unknown;
    updateAttachmentRequest: (data: IUpdateAttachmentRequest) => unknown;
    getVersionHistoryRequest: (request: IGetAttachmentVersionHistoryRequest) => unknown;
};

interface IAddUpdateAttachmentOwnProps {
    id?: string;
};

interface IAddUpdateAttachmentComponentState {
    lbs: boolean;
    newNotes: string;
    // inches: boolean;
    // centimeters: boolean;
    lengthView: string;
    widthView: string;
    cubicYard: boolean;
    data: IGetAttachmentByID;
    serverError: string;
    imageUploadLoading: boolean;
    errors: ById<IFieldErrorKeyValue>;
    versionHistoryPopup: boolean;
};

const AddUpdateAttachmentStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .thumbnail-img{
        border-width: 0;
        height: auto;
        max-height: 130px;
        max-width: 130px;
        vertical-align: middle;
        width: auto;
      }
`;

type IAddUpdateAttachmentComponentProps =
    RouteComponentProps
    & IAddUpdateAttachmentOwnProps
    & IAddUpdateAttachmentComponentStoreProps
    & IAddUpdateAttachmentComponentDispatchProps;

class AddUpdateAttachment extends PureComponent<IAddUpdateAttachmentComponentProps, IAddUpdateAttachmentComponentState> {

    public constructor(props: IAddUpdateAttachmentComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
                style: "",
                attachment_No: "B",
                attachment_Unit_ID: 0,
                make: "",
                model: "",
                serial_Number: "",
                owner: "",
                inspection_Date: "",
                detachable: "No",
                notes: "",
                photos: "",
                file_Attachments: "",
                status: "",
                move_Status: "",
                repair_Status: "",
                files: [],
                images: [],
                type: "",
                location: "",
                job_No: "",
                attached_Unit_No: "",
                coupler_Design: "",
                eT_Move_Pilot_Required: "None"
            },
            newNotes: "",
            lbs: true,
            // inches: true,
            // centimeters: false,
            lengthView: "Inches",
            widthView: "Inches",
            cubicYard: true,
            errors: {},
            serverError: "",
            imageUploadLoading: false,
            versionHistoryPopup: false,
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.getAttachmentByID();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateAttachmentComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();
        
            if (this.props.addAttachmentRequestStatus !== prevProps.addAttachmentRequestStatus) {
                if (this.props.addAttachmentRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addAttachmentRequestStatus.message });

                if (hasPayload(this.props.addAttachmentRequestStatus) && this.props.addAttachmentRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            };

            if (this.props.updateAttachmentRequestStatus !== prevProps.updateAttachmentRequestStatus) {
                if (this.props.updateAttachmentRequestStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateAttachmentRequestStatus.message });
                if (hasPayload(this.props.updateAttachmentRequestStatus) && this.props.updateAttachmentRequestStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    if (window.location.href.includes("token")) {
                        window.parent.postMessage("goback");
                    }
                    else {
                        this.handleCancel();
                    }
                }
            }
        }
    };


    public render(): ReactNode {

        const { token, addAttachmentRequestStatus, updateAttachmentRequestStatus, getLookup, getVersionHistory } = this.props;
        const { data, errors, serverError, imageUploadLoading, lbs, lengthView, widthView, cubicYard, newNotes , versionHistoryPopup} = this.state;

        const hasUrlToken = window.location.href.includes("token");
        const getRole = pageAccessCheck(token, "attachmentAccess");
        const disabled = getRole === ReadOnly ? true : undefined;
        const ReadWriteRole = getRole === ReadAndWrite ? true : undefined;
        const lookUpData = hasPayload(getLookup) ? getLookup.payload.response : undefined;
        const companies = lookUpData ? lookUpData.companies : [];
        const units = lookUpData ? lookUpData.units : [];
        const types = lookUpData ? lookUpData.types : [];
        const couplers = lookUpData ? lookUpData.couplers : [];
        const wss = lookUpData ? lookUpData.wssList : [];
        const styles = lookUpData ? lookUpData.styles : [];
        const onStatus = (event: unknown, value: IIdName): void => this.handleChange("status", value !== null ? value.name : "");
        const onUnit = (event: unknown, value: IIdName): void => this.handleUnitChange("attachment_Unit_ID", value !== null ? value.id : 0);
        const onOwner = (e: unknown, v: IShopLookupOwners): void => this.handleChange("owner", v !== null ? v.company : "");
        const onType = (event: unknown, value: IIdName): void => this.handleNumber("type_ID", value !== null ? value.id.toString() : "");
        const onCoupler = (event: unknown, value: IIdName): void => this.handleNumber("coupler_Design_ID", value !== null ? value.id.toString() : "");
        const onStyle = (event: unknown, value: IIdName): void => this.handleNumber("style_ID", value !== null ? value.id.toString() : "");
        const onWSS = (value: string): void => this.handleNumber("working_Site_Sub_ID", value);
        const onETPilotRequiredChange = (e:unknown, value: IIdName): void => this.handleChange("eT_Move_Pilot_Required",value ? value.name : "");
        const disableSave = (Object.values(errors).length > 0 || disabled) ? true : undefined;
        const versionHistoryPopupData = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};

        return (
            <AddUpdateAttachmentStyles>
                {(getRole !== NotApplicable) &&
                    imageUploadLoading ?
                    <LAGreyPaper>
                        <LALoading message="Uploading Files/Images... Please Wait" />
                    </LAGreyPaper>
                    : <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                label="Back to List"
                                onClick={this.handleCancel}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        {data.id !== 0 && <LAGridItem xs={12} sm={12} md={3}>
                            <LALinkButton onClick={() => this.handleVersionHistory(data.id)} label="Version History"></LALinkButton>
                        </LAGridItem>
                        }

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>ATTACHMENT</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Attachment #"
                                fullWidth={true}
                                variant="outlined"
                                name="attachment_No"
                                disabled={disabled || data.id > 0}
                                onChange={this.handleChange}
                                value={data.attachment_No}
                                errorText={errors["attachment_No"] ? errors["attachment_No"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled || !ReadWriteRole}
                                multiple={false}
                                option={units}
                                getOptionLabel="unit_Number"
                                autoHighlight={true}
                                onChange={onUnit}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Unit #"
                                selectionRemove={undefinedFunction}
                                errorText={errors["attachment_Unit_ID"] ? errors["attachment_Unit_ID"].message : undefined}
                                value={data.attachment_Unit_ID ? units.find(q => q.id === data.attachment_Unit_ID) : null}
                                defaultValue={data.attachment_Unit_ID ? units.find(q => q.id === data.attachment_Unit_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <DevExtremePopupLookup
                                data={wss}
                                id="wss-search"
                                placeHolder="Working Site"
                                disabled={disabled || data.id > 0}
                                displayExp="site"
                                name="wsS_ID"
                                errorName="wsS_ID"
                                errors={errors}
                                returnExp="wsS_ID"
                                currentVal={data.working_Site_Sub_ID}
                                onClick={onWSS}
                                columns={[
                                    { name: "site", caption: "Site", type: "string" },
                                    { name: "job", caption: "Job", type: "string" },
                                    { name: "site_Address", caption: "Site Address", type: "string" }
                                ]}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={ActiveOrInActive}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onStatus}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Status"
                                selectionRemove={undefinedFunction}
                                errorText={errors["status"] ? errors["status"].message : undefined}
                                value={data.status ? ActiveOrInActive.find(q => q.name === data.status) : null}
                                defaultValue={data.status ? ActiveOrInActive.find(q => q.name === data.status) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={types}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onType}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Type"
                                selectionRemove={undefinedFunction}
                                errorText={errors["type_ID"] ? errors["type_ID"].message : undefined}
                                value={data.type_ID ? types.find(q => q.id === data.type_ID) : null}
                                defaultValue={data.type_ID ? types.find(q => q.id === data.type_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={couplers}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onCoupler}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Coupler Design"
                                selectionRemove={undefinedFunction}
                                errorText={errors["coupler_Design_ID"] ? errors["coupler_Design_ID"].message : undefined}
                                value={data.coupler_Design_ID ? couplers.find(q => q.id === data.coupler_Design_ID) : null}
                                defaultValue={data.coupler_Design_ID ? couplers.find(q => q.id === data.coupler_Design_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={styles}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onStyle}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Style"
                                selectionRemove={undefinedFunction}
                                errorText={errors["style_ID"] ? errors["style_ID"].message : undefined}
                                value={data.style_ID ? styles.find(q => q.id === data.style_ID) : null}
                                defaultValue={data.style_ID ? styles.find(q => q.id === data.style_ID) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Weight"
                                fullWidth={true}
                                variant="outlined"
                                name="weight"
                                value={data.weight ?? ""}
                                type="number"
                                disabled={disabled}
                                onChange={this.handleNumber}
                                errorText={errors["weight"] ? errors["weight"].message : undefined}
                            />

                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={lbs ? "Lbs" : "Kg"} onChange={this.onLbs}>
                                <FormControlLabel value="Lbs" control={<Radio />} label="lbs" />
                                <FormControlLabel value="Kg" control={<Radio />} label="kg" />
                            </RadioGroup>

                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Width"
                                fullWidth={true}
                                variant="outlined"
                                name="width"
                                value={data.width ?? ""}
                                type="number"
                                disabled={disabled}
                                onChange={this.handleNumber}
                                errorText={errors["width"] ? errors["width"].message : undefined}
                            />

                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup-width" value={widthView} onChange={this.onWidthView}>
                                <FormControlLabel value="Inches" control={<Radio />} label="inch" />
                                <FormControlLabel value="CM" control={<Radio />} label="cm" />
                                <FormControlLabel value="Meters" control={<Radio />} label="meter" />                               
                                <FormControlLabel value="Foot" control={<Radio />} label="foot" /> 
                            </RadioGroup>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Length"
                                fullWidth={true}
                                variant="outlined"
                                name="length"
                                value={data.length ?? ""}
                                type="number"
                                disabled={disabled}
                                onChange={this.handleNumber}
                                errorText={errors["length"] ? errors["length"].message : undefined}
                            />

                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup-length" value={lengthView} onChange={this.onLengthView}>                                
                                <FormControlLabel value="Inches" control={<Radio />} label="inch" />
                                <FormControlLabel value="CM" control={<Radio />} label="cm" />                               
                                <FormControlLabel value="Meters" control={<Radio />} label="meter" />                               
                                <FormControlLabel value="Foot" control={<Radio />} label="foot" />                               
                            </RadioGroup>
                        </LAGridItem>

                        <LAGridItem xs={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={ETPilotRequiredOptions}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onETPilotRequiredChange}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="ET Pilot Required"
                                selectionRemove={undefinedFunction}
                                value={data.eT_Move_Pilot_Required ? ETPilotRequiredOptions.find(q => q.name === data.eT_Move_Pilot_Required) : null}
                                defaultValue={data.eT_Move_Pilot_Required ? ETPilotRequiredOptions.find(q => q.name === data.eT_Move_Pilot_Required) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Capacity"
                                fullWidth={true}
                                variant="outlined"
                                name="capacity"
                                value={data.capacity ?? ""}
                                type="number"
                                disabled={disabled}
                                onChange={this.handleNumber}
                                errorText={errors["capacity"] ? errors["capacity"].message : undefined}
                            />

                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup-width" value={cubicYard ? "Cubic Yard" : "Cubic Meter"} onChange={this.onCapacity}>
                                <FormControlLabel value="Cubic Yard" control={<Radio />} label="cubic yard" />
                                <FormControlLabel value="Cubic Meter" control={<Radio />} label="cubic meter" />
                            </RadioGroup>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Make"
                                fullWidth={true}
                                variant="outlined"
                                name="make"
                                value={data.make}
                                disabled={disabled}
                                onChange={this.handleChange}
                                errorText={errors["make"] ? errors["make"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Model"
                                fullWidth={true}
                                variant="outlined"
                                name="model"
                                value={data.model}
                                disabled={disabled}
                                onChange={this.handleChange}
                                errorText={errors["model"] ? errors["model"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                label="Serial Number"
                                fullWidth={true}
                                variant="outlined"
                                name="serial_Number"
                                value={data.serial_Number}
                                disabled={disabled}
                                onChange={this.handleChange}
                                errorText={errors["serial_Number"] ? errors["serial_Number"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LADropDownForOwners
                                dropDownPlaceHolder="Owner"
                                option={companies}
                                defaultValue={companies ? companies.find(q => q.company === data.owner) : ""}
                                value={companies ? companies.find(q => q.company === data.owner) : ""}
                                getOptionLabel="Owner"
                                isSourceAttachment={true}
                                size="small"
                                filterSelectedOptions={true}
                                autoHighlight={true}
                                onChange={onOwner}
                                errorText={errors["owner"] ? errors["owner"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4} className="text-center">
                            <strong>Inspection Date</strong>
                            <br />

                            <LADatePicker
                                id="inspection_Date"
                                disable={disabled}
                                value={data.inspection_Date}
                                onChange={this.handleInspectionDate}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LACheckBox
                                value={data.detachable === "Yes" ? true : false}
                                label="Detachable"
                                disabled={disabled}
                                onChange={this.handleDetachable}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={12}>
                            <></>
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={6}>
                            <LATextArea
                                minRows={3}
                                rowsMax={10}
                                label="Notes"
                                fullWidth={true}
                                variant="outlined"
                                name="notes"
                                value={newNotes}
                                disabled={disabled}
                                onChange={this.handleNotes}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} md={6}>
                            <LATextArea
                                minRows={3}
                                rowsMax={10}
                                label="Previous Notes"
                                fullWidth={true}
                                variant="outlined"
                                name="notes"
                                value={data.notes}
                                disabled={true}
                                onChange={this.handleChange}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} className="text-center">
                            <LAPaperWithLessPadding>
                            <strong>IMAGES</strong>
                            <hr />

                                {/* <LAIconUploadButton
                                    id="image-uploader"
                                    imagesOnly={true}
                                    disabled={disabled}
                                    label="Upload Images"
                                    icon={<UploadIcon />}
                                    onClick={(e) => this.pictureUpload(e)}
                                /> */}

                                <LAGridItem xs={12}>
                                    <LADropZone onFileDrop={this.pictureUpload} />
                                </LAGridItem>

                                <br />

                                <LAGrid>
                                    {(data.images !== null) && data.images.sort((a, b) => a.description > b.description ? 1 : -1)
                                        .map((q, ind) => {
                                            const base64 = (q.base64String !== null) ? q.base64String : (webConfig.imgApiBaseUrl + q.name);
                                            const onImgDelete = (): void => this.onImgDelete(ind);
                                            const onImageName = (name: string, value: string): void => this.onImageDescription(value, ind);

                                            return (
                                                <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                    <LAPaperWithLessPadding>
                                                        <LAThumbnailWithLink
                                                            key={ind}
                                                            url={base64}
                                                            alt={q.description}
                                                            id={(ind).toString()}
                                                        />

                                                        <LATextField
                                                            className="mt-2"
                                                            label="Description"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            name="Description"
                                                            disabled={disabled}
                                                            value={q.description}
                                                            onChange={onImageName}
                                                            errorText={
                                                                q.description.length === 0 ?
                                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                    : undefined
                                                            }
                                                        />

                                                        <LAIconButton
                                                            key={ind}
                                                            label="Delete"
                                                            onClick={onImgDelete}
                                                            icon={<DeleteIcon />}
                                                        />
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            )
                                        })}
                                </LAGrid>
                            </LAPaperWithLessPadding>
                        </LAGridItem>

                        <LAGridItem xs={12} className="text-center">
                            <LAPaperWithLessPadding>

                            <strong>DOCUMENTS</strong>
                        <hr />

                            <LADropZone onFileDrop={this.fileUpload} />
                                <br />

                                <LAGrid>
                                    {(data.files !== null) && data.files
                                        .map((q, ind) => {
                                            const onFileDelete = (): void => this.onFileDelete(ind);
                                            const onDownload = (): void => this.handleDownload(q);

                                            return (
                                                <LAGridItem xs={12} sm={4} md={2} key={ind} className="text-center">
                                                    <LAPaperWithLessPadding>
                                                        <span>{q.name}</span>
                                                        <br />
                                                        <LAIconButton
                                                            name="file"
                                                            label={q.name}
                                                            id={(ind).toString()}
                                                            onClick={onDownload}
                                                            icon={<DownloadIcon />}
                                                        />

                                                        <LAIconButton
                                                            key={ind}
                                                            label="Delete"
                                                            icon={<DeleteIcon />}
                                                            onClick={onFileDelete}
                                                        />
                                                    </LAPaperWithLessPadding>
                                                </LAGridItem>
                                            )
                                        })}
                                </LAGrid>
                            </LAPaperWithLessPadding>
                        </LAGridItem>

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                name="move_Status"
                                label="Move Status"
                                onChange={this.handleChange}
                                value={data.move_Status ?? ""}
                            />
                        </LAGridItem>}

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                fullWidth={true}
                                disabled={true}
                                variant="outlined"
                                name="repair_Status"
                                label="Repair Status"
                                onChange={this.handleChange}
                                value={data.repair_Status ?? ""}
                            />
                        </LAGridItem>}

                        {data.id && data.id > 0 ? <LAGrid>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        variant="outlined"
                                        value={data.created_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextField
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        variant="outlined"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={undefinedFunction}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid> : null}

                        <LAGridItem xs={12} sm={12} md={12}>
                            <></>
                        </LAGridItem>

                        {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                cancelButtonText="Close"
                                onSave={this.handleSave}
                                onCancel={this.handleCancel}
                                disableSave={disableSave}
                            />
                        </LAGridItem>

                        {!hasUrlToken && <RequestStatus requestStatus={addAttachmentRequestStatus.kind} successMessage="Attachment has been added successfully" />}
                        {!hasUrlToken && <RequestStatus requestStatus={updateAttachmentRequestStatus.kind} successMessage="Attachment has been updated successfully" />}
                    </LAGrid>}

                    {versionHistoryPopup && <AttachmentVersionHistory 
                          data={versionHistoryPopupData}
                          open={versionHistoryPopup}
                          status={getVersionHistory.kind}
                          onCancel={this.handleVersionHistoryPopupClose}
                        />}

            </AddUpdateAttachmentStyles>
        );
    }

    private handleVersionHistory = async (id: number): Promise<void> => {
        if (hasPayload(this.props.token)) {
            this.props.getVersionHistoryRequest({
                token: this.props.token.payload.response.token,
                request: {
                    ID: id
                }
            });
        }
        await this.setState({ versionHistoryPopup: true });
    };

    private handleVersionHistoryPopupClose = (): void => {
        this.setState({ versionHistoryPopup: false });
    };

    private handleDownload = (q: IAttachmentListFile): void => {
        if (q.base64String !== null && q.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = q.base64String;
            a.download = q.name;
            a.click();
        } else {
            this.downloadFromEndpoint(q.name, );
        }
    };

    private downloadFromEndpoint = async (fileName: string): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.token)) {

                await fetch(END_POINTS.FIELD.ATTACHMENT_LIST.DOWNLOAD_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "File_Name": fileName,
                            "ID": this.state.data.id,
                            "Page":"Attachment_List",
                        },
                        token: this.props.token.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleNotes = (name: string, newNotes: string): void => {
        this.setState({
            newNotes
        });
    };

    private onLbs = (): void => {
        const { lbs, data } = this.state;
        const weight = data.weight ? lbs ? lbsToKg(data.weight ?? 0) : kgToLbs(data.weight ?? 0) : undefined;

        this.setState({
            lbs: !this.state.lbs,
            data: {
                ...this.state.data,
                weight
            }
        });
    };

    private onWidthView = (e: any,value:string): void => {
        // console.log(value);
        const { data, widthView } = this.state;
        // const width = data.width ? inches ? inchesToCm(data.width ?? 0) : cmToInches(data.width ?? 0) : undefined;
        const width = getConvertedValue(widthView, value, data.width ?? 0)
        // console.log(width)
        this.setState({
            // inches: !this.state.inches,
            widthView: value,
            data: {
                ...this.state.data,
                width
            }
        });
    };

    private onLengthView = (e: any,value:string): void => {
        const { data, lengthView } = this.state;
        // console.log(meters)
        // const length = data.length ? centimeters ? cmToInches(data.length ?? 0) : inchesToCm(data.length ?? 0) : undefined;
        const length = getConvertedValue(lengthView, value, data.length ?? 0)
        this.setState({
            // centimeters: !this.state.centimeters,
            lengthView: value,
            data: {
                ...this.state.data,
                length
            }
        });
    };

    private onCapacity = (): void => {
        const { cubicYard, data } = this.state;
        const capacity = data.capacity ? cubicYard ? cubicYardsToCubicMeters(data.capacity ?? 0) : cubicMetersToCubicYards(data.capacity ?? 0) : undefined;

        this.setState({
            cubicYard: !this.state.cubicYard,
            data: {
                ...this.state.data,
                capacity
            }
        });
    };

    private onImgDelete = (imgIndex: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const images = [...this.state.data.images];
            images.splice(imgIndex, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    images
                }
            });
        }
    };

    private onFileDelete = (fileIdx: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const files = [...this.state.data.files];
            files.splice(fileIdx, 1);

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                }
            });
        }
    };

    private onImageDescription = (value: string, imgIndex: number): void => {
        const images = [...this.state.data.images];
        images[imgIndex].description = value;

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                images
            }
        });
    };

    private pictureUpload = async (files: Array<File | DataTransferItem>): Promise<void> => {
        this.setState({ imageUploadLoading: true });

        // const files = event.target.files;
        const images = [...this.state.data.images];

        if (files !== null) {

            await Promise.all(Object.values(files).map(async (x: any) => {
                const imgId = uuidv4();
                const description = x.name;
                const base64String = await fileToBase64Image(x);

                images.push({
                    name: imgId + "." + description.split(".")[1],
                    description: description.split(".")[0],
                    base64String,
                });

                this.setState({
                    ...this.state,
                    data: {
                        ...this.state.data,
                        images: images.sort((a, b) => a.description > b.description ? 1 : -1)
                    },
                    imageUploadLoading: false
                });
            }));
        }
    };

    private fileUpload = (attachments: Array<File | DataTransferItem>): void => {
        this.setState({ imageUploadLoading: true });

        const files = [...this.state.data.files];

        new Promise((resolve, reject): any => {
          
            if (attachments && (attachments !== null)) {
                let isDuplicateName = false;
                Object.values(attachments).forEach((x: any, index) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            files.map((att: any, i: number) => {x.name.substring(0, x.name.lastIndexOf("."))
                                // console.log(img.description, description)
                                if(att.name.substring(0, att.name.lastIndexOf(".")) === x.name.substring(0, x.name.lastIndexOf("."))) {
                                    isDuplicateName = true;
                                }
                                return isDuplicateName
                            })
                            isDuplicateName ?
                                files.push({
                                    name: `${x.name.substring(0, x.name.lastIndexOf("."))}_${files.length + 1}.${x.name.substring(x.name.lastIndexOf(".") + 1)}`,
                                    base64String: reader.result.toString()
                                }) : 
                                files.push({
                                    name:  x.name,
                                    base64String: reader.result.toString()
                                });
                            // files.push({
                            //     name: x.name,
                            //     base64String: reader.result.toString()
                            // });

                            if (attachments.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                    
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    files
                },
                imageUploadLoading: false
            });
        });
    };

    private handleInspectionDate = (date: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                inspection_Date: date
            }
        });
    };

    private handleDetachable = (checked: boolean): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                detachable: checked ? "Yes" : "No"
            }
        });
    };

    private handleNumber = (name: string, value: string): void => {
        // console.log(name, value)
        const { widthView } = this.state;
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "width") {
            const width = getConvertedValue(widthView, "Meters", Number(value))
            const eT_Move_Pilot_Required = width >= 3.81 && width < 4.45 ? "1 Pilot" : width >= 4.45 ? "2 Pilots" : "None"

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value),
                    eT_Move_Pilot_Required : eT_Move_Pilot_Required
                },
            });
        }
        else {
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    [name]: Number(value)
                },
                errors
            });
        }

    };

    private handleUnitChange = (name: string, value: number): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value.toString(), errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "attachment_No") {
            if(value && value.length < 3) {
                errors[name] = { key : name, message: "Attachment No. must be at least 3 characters"}
            }
            else if(value && value.length >= 3 ) {
                if(errors[name])
                    delete errors[name]
            }
            else {
                errors = this.errorChecker(name, value, errors);
            }
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.FIELD.ATTACHMENT_LIST.INDEX,
            search: getTokenFromUrl(false)
        });

        if (window.location.href.includes("token")) {
            window.parent.postMessage("goback");
        }
    };

    private handleSave = async (): Promise<void> => {

        const { data, lbs, newNotes, cubicYard, widthView, lengthView } = this.state;

        const {
            id, status, created_By, attachment_No, attachment_Unit_ID, type_ID,
            coupler_Design_ID,style_ID, weight, width, length, eT_Move_Pilot_Required, capacity, make, serial_Number, owner,
            inspection_Date, photos, file_Attachments, images, files, working_Site_Sub_ID, detachable
        } = data;

        if (hasPayload(this.props.token)) {

            const upn = this.props.token.payload.response.upn;
            const note = newNotes.length > 0 ? (newNotes + " - " + upn + ";" + getDate(true, new Date().toISOString())) : "";
            const weightValue = lbs ? weight : kgToLbs(weight ?? 0);
            // const widthValue = inches ? width : cmToInches(width ?? 0);
            // const lengthValue = !centimeters ? length : cmToInches(length ?? 0);
            const widthValue = getConvertedValue(widthView, "Inches", width ?? 0);
            const lengthValue = getConvertedValue(lengthView, "Inches", length ?? 0);
            const capacityValue = cubicYard ? capacity : cubicMetersToCubicYards(capacity ?? 0);

            const newImg: IAddUpdateAttachmentListImage[] = [];
            const newFiles: IAddUpdateAttachmentListFile[] = [];

            images.forEach((x) => {
                newImg.push({
                    Name: x.name,
                    Description: x.description,
                    Base64String: x.base64String
                });
            });

            files.forEach((x) => {
                newFiles.push({
                    Name: x.name,
                    Base64String: x.base64String
                });
            });

            let request: IAddUpdateAttachment = {
                ID: id !== 0 ? id : undefined,
                Attachment_No: attachment_No,
                Attachment_Unit_ID: attachment_Unit_ID,
                Status: status,
                Type_ID: type_ID ?? 0,
                Coupler_Design_ID: coupler_Design_ID ?? 0,
                Style_ID: style_ID ?? 0,
                Weight: weightValue ?? 0,
                Width: widthValue ?? null,
                Length: lengthValue ?? null,
                ET_Move_Pilot_Required: eT_Move_Pilot_Required,
                Capacity: capacityValue ?? 0,
                Make: make,
                Working_Site_Sub_ID: working_Site_Sub_ID,
                Serial_Number: serial_Number,
                Owner: owner,
                Inspection_Date: inspection_Date,
                Detachable: detachable,
                Notes: note,
                Photos: photos,
                File_Attachments: file_Attachments,
                Created_By: created_By,
                Modified_By: upn,
                Files: newFiles,
                Images: newImg
            };

            if (id === 0) {
                this.props.addAttachmentRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ...request,
                        Created_By: upn,
                    }
                });
            } else {
                this.props.updateAttachmentRequest({
                    token: this.props.token.payload.response.token,
                    request: {
                        ...request,
                        Created_By: created_By,
                        Modified_By: upn,
                    }
                });
            };
            this.setState({ serverError: "" });
        }
    };


    private setDataToState = async (): Promise<void> => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "attachmentAccess") !== NotApplicable) {

                    if (isNotLoaded(this.props.getLookup) && hasPayload(this.props.token))
                        this.getLook();

                    if (query.id !== "0") {
                        if (isNotLoaded(this.props.getAttachmentByID) && hasPayload(this.props.token))
                            this.getAttachmentByID(); 
                        
                        if (hasPayload(this.props.getAttachmentByID)) {

                            if (this.state.data.id !== Number(query.id)) {
                                let data = this.props.getAttachmentByID.payload.response;
                                data.files = data.files === null ? [] : data.files;
                                data.images = data.images === null ? [] : data.images;

                                this.setState({
                                    data,
                                    errors: {}
                                });
                            };
                        } 
                        // else {
                        //     // this.props.getAttachmentsRequest({
                        //     //     token: this.props.token.payload.response.token
                        //     // });
                        //     this.getAttachmentByID();
                        // };
                    } 
                    else {
                            const errors: ById<IFieldErrorKeyValue> = {};

                            RequiredFields.forEach((x) => {
                                errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                            });

                            this.setState({
                                errors
                            });
                        };
                    
                } 
                else {

                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Attachment_List"
                }
            });
    };

    private getAttachmentByID = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.location.search);

        if (hasPayload(this.props.token) && Number(query.id) > 0) {
            await this.props.getAttachmentByIDRequest({
                token: this.props.token.payload.response.token,
                request: {
                    id: Number(query.id)
                }
            });
        }
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true, minLength: 1 });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateAttachmentComponentStoreProps => ({
    token: getToken(state),
    getLookup: getFieldLookups(state),
    // attachments: getAttachmentsStatus(state),
    getAttachmentByID: getAttachmentByIDStatus(state),
    addAttachmentRequestStatus: addAttachmentStatus(state),
    updateAttachmentRequestStatus: updateAttachmentStatus(state),
    getVersionHistory: getAttachmentVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateAttachmentComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addAttachmentRequest: (data: IAddAttachmentRequest) => dispatch(addAttachmentLoadAction(data)),
    // getAttachmentsRequest: (data: IGetAttachmentRequest) => dispatch(getAttachmentsLoadAction(data)),
    getAttachmentByIDRequest: (data: IGetAttachmentByIDRequest) => dispatch(getAttachmentByIDLoadAction(data)),
    updateAttachmentRequest: (data: IUpdateAttachmentRequest) => dispatch(updateAttachmentLoadAction(data)),
    getVersionHistoryRequest: (request: IGetAttachmentVersionHistoryRequest): unknown => dispatch(getAttachmentVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateAttachment);