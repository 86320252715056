import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_DRIVERS_REQUEST {
    REQUEST = "getDrivers/GET_DRIVERS_REQUEST",
    SUCCESS = "getDrivers/GET_DRIVERS_SUCCESS",
    FAILED = "getDrivers/GET_DRIVERS_FAILED"
};

export interface IGetETDriverRequest extends ISurewayTokenRequestBody {

};

export interface IETDriver {
    id: number;
    driver_Firstname: string;
    driver_Lastname: string;
    notes: string;
    active: string;
    email: string;
    assigned_Unit_Number: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
    pilot_Driver: string;
    truck_Driver: string;
    token: string;
    phone_Number: string;
    license: string;
};