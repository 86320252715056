import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_CONTROL_PLAN_REQUEST } from "./updateControlPlanConstants";
import { IUpdateControlPlanLoadAction, IUpdateControlPlanLoadFailedAction, IUpdateControlPlanSuccessAction } from "./updateControlPlanActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IUpdateControlPlanLoadAction
    | IUpdateControlPlanSuccessAction
    | IUpdateControlPlanLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateControlPlanReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_CONTROL_PLAN_REQUEST.REQUEST:
            return loading;

        case IUPDATE_CONTROL_PLAN_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_CONTROL_PLAN_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};