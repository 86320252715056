
export enum IGET_MEDICAL_FACILITIES_REQUEST {
    REQUEST = "getMedicalFacilities/GET_MEDICAL_FACILITIES_REQUEST",
    SUCCESS = "getMedicalFacilities/GET_MEDICAL_FACILITIES_SUCCESS",
    FAILED = "getMedicalFacilities/GET_MEDICAL_FACILITIES_FAILED"
};

export interface IGetMedicalFacilitiesRequest {
    token: string;
};

export interface IGetMedicalFacilities {
    id: number;
    name: string;
    address: string;
    active: string;
    gps: string;
    wsS_IDs?: string;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
};

