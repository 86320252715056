import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import styled from "styled-components";
import { LAButton, LAIconButton } from "./buttons";
import React, { useCallback, useEffect, useState } from "react";
import LALinkButton from "./linkButton";
import "devextreme/dist/css/dx.light.css";
import { DataTimer } from "./timer";
import { base64ToImageUrl, undefinedFunction, ZEROTH } from "./constExports";
import dxDataGrid, { CellPreparedEvent, DataChangeInfo, RowClickEvent, ToolbarPreparingInfo } from "devextreme/ui/data_grid";
import Not_Available_Image from "../../No-Image.jpg";
import { exportDataGrid } from "devextreme/excel_exporter";
import { AddIcon, AttachmentIcon, DeleteIcon, DocumentsIcon, DozerIcon, LocationHistoryIcon, LocationPinIcon, RepairLineIcon, SoctIcon, TechSupportIcon, VersionHistoryIcon } from "./icons";
import DataGrid, { Button, Column, ColumnChooser, Editing, Export, FilterRow, Grouping, HeaderFilter, Lookup, Pager, Paging, Scrolling, SearchPanel, Selection, StateStoring, StringLengthRule, Summary, TotalItem } from "devextreme-react/data-grid";
import { DARK_GREY_COLOR, LIGHT_GREY_COLOR, RED_COLOR, ROW_SELECT_COLOR, GREEN_COLOR, ORANGE_COLOR, BLUE_COLOR, LIGHT_GREEN_COLOR, LIGHT_BLUE_COLOR, LIGHT_RED_COLOR, LIGHT_ORANGE_YELLOW_COLOR, YELLOW_COLOR, EXTRA_LIGHT_YELLOW_COLOR, MEDIA_QUERY_PHONE, LIGHT_ORANGE_COLOR, SKY_BLUE_COLOR, BLUE_GREEN_COLOR } from "./theme";
import LAGrid from "./grid";
import LAGridItem from "./gridList";
import { END_POINTS } from "../../redux/endpoints";
import { EventInfo } from "devextreme/events";
import InfoIcon from '@mui/icons-material/Info';
import { LAThumbnailWithLink } from "./thumbnail";

export const equipmentButtonTypes = ["history", "location_history", "gps_history"];

export interface IDevExtremeColumn {
    name: string;
    max?: number;
    type?: string;
    width?: number;
    show?: boolean;
    format?: string;
    sortAsc?: true;
    dropDownId?: any;
    caption?: string;
    sortDesc?: boolean;
    dropDownName?: any;
    requiredField?: true;
    dropDownSource?: any;
    allowEditing?: boolean;
    linkButtonText?: string;
    calculated?: (rowData: any) => any;
    renderCell?: (rowData: any) => any;
};

interface ISumColumn {
    caption: string;
    columnName: string;
};

interface ILADevExtremeGridProps {
    id?: string;
    autoFocus?: true;
    pageNumber?: number;
    columnsHiding?: boolean;
    columnReordering?: boolean;
    columnResizing?: boolean;
    data: any[];
    add?: boolean;
    export?: true;
    height?: number;
    timeout?: number;
    onAdd?: () => void;
    actionWidth?: number;
    columnWidth?: number;
    searchPanel: boolean;
    removeStyleBtn?: true;
    filterHeader: boolean;
    exportFileName?: string;
    customBtnLabel?: string;
    sumColumn?: ISumColumn[];
    onEdit?: (e: any) => void;
    onEditColumnLabel?: string;
    onDelete?: (e: any) => void;
    columns: IDevExtremeColumn[];
    onEditIcon?: (e: any) => void;
    customBtn?: (event?: any) => void;
    onSaving?: (event?: any) => void;
    customRowColor?: boolean;
    pictureField?: string;
    storageKey?: string;
    getStorageData?: any;
    columnChoose?: boolean;
    onClearClick?: () => void;
    onClearDateRange?: () => void;
    getRefreshDataCall?: () => void;
    onCustomClick?: (name: string, e: any) => void;
    onMoveClick?: (name: string, e: any) => void;
    onClick: (e: RowClickEvent<any, unknown>) => void;
    onCustomSOCTClick?: (name: string, e: any) => void;
    onCustomIconClick?: (name: string, e: any) => void;
    onRejectApproveClick?: (name: string, e: any) => void;
    onETMoveReqMobileClick?: (name: string, e: any) => void;
    paging?: number;
    onDeleteTruckingRequestEmail?: (name: string, e: any) => void;
    onImageClick?: (name: string, images: string[]) => void;
    selectionRow?: boolean;
    onSelectionChanged?: (row:any) => void;
    onEditStart?: () => void;
    onEditCancel?: () => void;
    onEditSave?: (e: EventInfo<dxDataGrid<any, unknown>> & DataChangeInfo<any, unknown>) => void;
    // onNewRowInsert?: () => void;
    onCustomEdit?: (e: any) => void;
    moveBtnToLeft?: boolean;
    onRowPrepared?: (row: any) => void;
    editRow?: boolean;
    allowRowEditing?: (e: any) => boolean | undefined;
};

const LADevExtremeGridStyles = styled.div`
    .dx-datagrid-headers .dx-header-row {  
        font-weight: bold;
        background-color: ${LIGHT_GREY_COLOR};
    };
    
    .dx-datagrid-content
    {
        font-weight:700;
    };
    
    .right-side {
        top: 2%;
        right: 3%;
        position: absolute;
    };
    
    .moveBtn{
        background-color: ${LIGHT_BLUE_COLOR};
        border: 1px solid;
        color: black;
        text-align: center;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
        padding: 5px;
        margin-right: 5px;
    }
    
    .dx-header-filter-indicator, .dx-cell-focus-disabled, .dx-datagrid-content {
        font-size: 12px !important;
    };

    #ExternalRepairLineParts {
        .dx-datagrid-content, .dx-datagrid-text-content {
            font-size: 26px !important;
        }
    };
        
    }
    .red-text {
        color: ${RED_COLOR};
    };

    .green-text {
        color: ${GREEN_COLOR};
        font-size: 9px;
    };

    .blue-text {
        color: ${BLUE_COLOR};
        font-size: 9px;
    };

    .link-btn {
        cursor: pointer;
        color: ${BLUE_COLOR};
    };

    .link-btn:hover {
        text-decoration: underline;
    }
    
    .dx-state-hover
    {
        background-color: ${ROW_SELECT_COLOR} !important;
    };

    .control-button{
        font-weight: 400 !important;
        font-size: 14px !important;
        font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif !important;
    
        padding: 10px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        padding-bottom: 20px !important;
        display:inline-block;
        padding:0.3em 1.2em;
        margin:0 0.1em 0.1em 0;
        border:0.16em solid rgba(255,255,255,0);
        border-radius: 0.5em;
        box-sizing: border-box;
        text-decoration:none;
        font-weight: bold;
        color:#FFFFFF;
        text-align:center;
        transition: all 0.2s;
    };
    a.button4:hover{
        border-color: rgba(255,255,255,1);
    };
    
    .moveRequestFontWeight{
        .dx-datagrid-content
    {
        font-weight:700;
    };
    }
    @media all and (max-width:30em){
        a.button4 {
            display:block;
            margin:0.2em auto;
        };
    };
    .btn {
        color: #337ab7;
        text-align:center;
        transition: all 0.2s;
    };
    .editBg{
        background-color: #4CAF50;
    };
    .cloneBg{
        background-color: orange;
    };
    .deleteBg{
        background-color: #CD5C5C;
    };
        .linkUrl{
            text-decoration: underline;
        text-decoration-color: blue;
        font-size: 12px !important;
        }

    .editMode {
        .dx-edit-row {
            background-color: ${DARK_GREY_COLOR};
        };
    
        .dx-edit-row a {
            background-color: ${DARK_GREY_COLOR};
            color: ${RED_COLOR};
        };
    
        .dx-texteditor-input {
            color: ${GREEN_COLOR};
            border: 2px outset;
        };
    };

    .clear-filters {       
        background-color: rgb(168, 0, 0);
    };

      .moveRequestFontWeight{
        .dx-datagrid-content
    {
        font-weight:700;
    };
    }

    .reject-btn {
        background-color: #bf0000 !important;
        color: white !important;
        padding: 5px !important;
        font-size: 14px; 
        border-radius: 4px !important;
      }
      
      .initiate-btn {
        background-color: rgb(146, 218, 150) !important;
        color: white !important;
        padding: 5px !important;
        font-size: 14px; 
        border-radius: 4px !important;
      }

      .complete-btn {
        background-color: rgb(236, 176, 65) !important;
        color: white !important;
        padding: 5px !important;
        font-size: 14px; 
        border-radius: 4px !important;
      }

      .cancel-btn {
        background-color: rgb(205, 92, 92) !important;
        color: white !important;
        padding: 5px !important;
        font-size: 14px; 
        border-radius: 4px !important;
      }

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .right-side {
            top: auto;
            right: auto;
            position: static;
        };
    };

`;


export const LADevExtremeGrid: React.FC<ILADevExtremeGridProps> = React.memo((props: ILADevExtremeGridProps) => {
    const [searchValue, setSearchValue] = useState('');
    const onExportClick = (e: any): void => onExporting(e, props.exportFileName ?? "File");
    const [storageChange, setStorageChange] = useState<boolean>(false);
    const [devKey, setDevKey] = useState<number>(0);

    const loadState = useCallback(() => {
        if (props.storageKey) {
            let data = localStorage.getItem(props.storageKey);

            if (data)
                return JSON.parse(data);
        }
    }, [storageChange]);

    useEffect(() => {
        if ('matchMedia' in window) {
            // Add a listener for orientation changes
            window.matchMedia('(orientation: portrait)').addListener(handleOrientationChange);
        }
    }, []);

    const handleOrientationChange = (mediaQuery: any) => {
        if (mediaQuery.matches) {
            setDevKey((prevKey) => prevKey + 1);
        } else {
            setDevKey((prevKey) => prevKey + 1);
        }
    };

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        if (props.storageKey)
            localStorage.setItem(props.storageKey, JSON.stringify(state));
    }, []);

    const rowPrepAttachmentList = (e: any) => {
        if (e.data) {

            e.columns.map((item: any, index: number) => {

                if(item.caption === "Status") {
                    if(e.data.status === "Active") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    if(e.data.status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if(e.data.status === "Dispatched") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    if(e.data.status === "Cancelled") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }
                if(item.caption === "Move Status") {
                    if(e.data.move_Status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if(e.data.move_Status === "Dispatched") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    if(e.data.move_Status === "Initiated") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    if(e.data.move_Status === "Requested") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                }
            });
        }
    }

    const rowPrepRepairLineUnitDropdown = (e: any) => {
        if (e.data) {
            e.columns.map((item: any, index: number) => {
                if (item.caption === "Unit Number" || item.caption === "Attachment No.") {
                    if (e.data.repair_ID && e.data.repair_ID > 0) {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    } 
                }
            });
        }
    }

    const rowPreForemanMessaging = (e: any) => {
        if (e.data) {
            e.columns.map((item: any, index: number) => {
                if (item.caption === "Selected Option") {
                    if (e.data.employee_Option) {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    } 
                    else {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    } 
                }
            });
        }
    }

    const rowPrepWorkingSiteLog = (e: any) => {
        if (e.data) {

            e.columns.map((item: any, index: number) => {

                if(item.caption === "Working Site") {
                    if(e.data.status === "Rained/Snowed out" || e.data.status === "Weather Standby") {
                        if (e.rowElement.cells[index])
                            // e.rowElement.cells[index].style.backgroundColor = SKY_BLUE_COLOR;
                            e.rowElement.cells[index].style.background = `url(${process.env.PUBLIC_URL + '/img/giphy.webp'})`;
                    }
                }
            });
        }
    }

    const rowPrepRepairLine = (e: any) => {
        if (e.data) {

            e.columns.map((item: any, index: number) => {

                if(item.caption === "Current Location") {
                    if(e.data.site_Status === "Rained/Snowed out" || e.data.site_Status === "Weather Standby") {
                        if (e.rowElement.cells[index])
                            // e.rowElement.cells[index].style.backgroundColor = SKY_BLUE_COLOR;
                        e.rowElement.cells[index].style.background = `url(${process.env.PUBLIC_URL + '/img/giphy.webp'})`;
                    }
                }

                if (item.caption === "Priority") {
                    if (e.data.priority === "Down") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if (e.data.priority === "Medium") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = BLUE_COLOR;
                    }
                    if (e.data.priority === "Urgent") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                    if (e.data.priority === "Low") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                }

                if(item.caption === "Status") {
                    if(e.data.status === "Active") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    if(e.data.status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if(e.data.status === "Winter Repair") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = YELLOW_COLOR;
                    }
                }

                if(item.caption === "Assigned Mechanic") {
                    if(e.data.assigned_Mech === "No") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }

                if(item.caption === "Winter Repair") {
                    if(e.data.winter_Repair === "Yes") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = YELLOW_COLOR;
                    }
                }
            });
        }
    }

    const rowPrepExternalMovePage = (e: any) => {
        if (e.data) {
            let status = e.data.status;
            let unitID = e.data.equipment_ID;
            let completedDate = e.data.completed_Date;

            e.columns.map((item: any, index: number) => {
                if (item.caption === "Status") {
                    if (status === "Initiated") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                    }
                    else if (status === "Completed") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                    else if (status === "Cancelled") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    else if (status === "Moved") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                    }
                    else if (status === "Requested") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                    }
                    else if (status === "Rejected") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    else if (status === "Dispatched") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = YELLOW_COLOR;
                    }
                    else if (status === "En Route") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                    else {

                    }
                }

                //BG Color for Custom Unit Color
                if (item.name === "combined_Unit_Number") {
                    if (unitID === 0) {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_YELLOW_COLOR;
                    }
                }

                if (item.caption === "Complete By") {
                    let date = new Date(completedDate);
                    let dateMDY = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                    if (dateMDY <= new Date().toLocaleDateString()) {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_YELLOW_COLOR;
                    }
                }


            });
        }
    }

    const rowPrepExternalRepairLinePartsPage = (e: any) => {
        if (e.data) {
            e.columns.map((item: any, index: number) => {

                if (item.caption === "Priority") {
                    if (e.data.priority === "Down") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                    if (e.data.priority === "Medium") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = BLUE_COLOR;
                    }
                    if (e.data.priority === "Urgent") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                    if (e.data.priority === "Low") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                    }
                }

                if(e.data.parts_Instructions) {
                    if(item.caption === "Parts Notes" && (e.data.parts_Notes === undefined || e.data.parts_Notes === null || e.data.parts_Notes === "")) {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                    }
                }

                if(e.data.damaged === "Yes") {
                    if(item.caption === "WO#") {
                        if (e.rowElement.cells[index])
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                    }
                }
            });
        }
    }

    const rowPrepET = (e: any) => {
        if (e.data) {
            let status = e.data.status;
            let completedDate = e.data.completed_Date;


            if (window.innerWidth > 760) {

                e.columns.map((item: any, index: number) => {
                    if (item.caption === "Status") {
                        if (status === "Initiated") {
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_GREEN_COLOR;
                        }
                        else if (status === "Completed") {
                            e.rowElement.cells[index].style.backgroundColor = GREEN_COLOR;
                        }
                        else if (status === "Cancelled") {
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                        }
                        else if (status === "Moved") {
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_BLUE_COLOR;
                        }
                        else if (status === "Requested") {
                            e.rowElement.cells[index].style.backgroundColor = EXTRA_LIGHT_YELLOW_COLOR;
                        }
                        else if (status === "Rejected") {
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_RED_COLOR;
                        }
                        else if (status === "Dispatched") {
                            e.rowElement.cells[index].style.backgroundColor = YELLOW_COLOR;
                        }
                        else if (status === "En Route") {
                            e.rowElement.cells[index].style.backgroundColor = ORANGE_COLOR;
                        }
                        else {

                        }
                    }
                    //BG Color for completed
                    if (item.caption === "Complete By") {
                        let date = new Date(completedDate);
                        let dateMDY = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
                        if (dateMDY < new Date().toLocaleDateString()) {
                            e.rowElement.cells[index].style.backgroundColor = LIGHT_ORANGE_YELLOW_COLOR;
                        }
                    }


                });


            }

        }
    }


    const clearFilters = async (): Promise<void> => {
        if (props.storageKey) {
            const getCurrent = localStorage.getItem(props.storageKey);

            if (getCurrent) {
                let parsed = JSON.parse(getCurrent);
                parsed.filterValue = null;
                parsed.filterValues = null;
                parsed.searchText = null;
                parsed.filterPanel = null;
                //remove header filters on every columns
                parsed.columns.map((row: any, id: number) => (
                    row.filterValue = null,
                    row.filterValues = null,
                    row.filterType = null
                ));

                localStorage.setItem(props.storageKey, JSON.stringify(parsed));
                setStorageChange(!storageChange);
            }

            if (props.onClearClick)
                props.onClearClick();

            if (props.onClearDateRange)
                props.onClearDateRange();
        }
    };

    const onGridReady = () => {
        if(props.autoFocus){
            const firstColumnInputFilter: HTMLInputElement | null = document.querySelector('tr.dx-row input.dx-texteditor-input[tabindex="0"]');
            if (firstColumnInputFilter)
                firstColumnInputFilter.focus();
        }
      };

      const handleValueChange = (e:any) => {
        // console.log(e);
        const trimmedValue = e.trim();
        setSearchValue(trimmedValue);
      };

    return (
        <LADevExtremeGridStyles>
            <LAGrid key={devKey}>

                <LAGridItem xs={12}>
                    {props.storageKey && <LAButton
                        label="Clear Filters"
                        className="clear-filters"
                        onClick={clearFilters}
                        disabled={undefined}
                    />}

                    {props.getRefreshDataCall && <DataTimer
                        className="right-side"
                        key={props.storageKey}
                        timeout={props.timeout}
                        onTimerEnd={props.getRefreshDataCall}
                    />}
                </LAGridItem>

                <LAGridItem xs={12}>
                    <DataGrid
                        id={props.id}
                        showBorders={true}
                        columnAutoWidth={true}
                        wordWrapEnabled={true}
                        dataSource={props.data}
                        hoverStateEnabled={true}
                        onSaved={props.onSaving}
                        rowAlternationEnabled={props.customRowColor === false ? props.customRowColor : true}
                        onInitNewRow={props.onAdd}
                        onContentReady={onGridReady}
                        allowColumnResizing={props.columnResizing === true ? true : false}
                        columnResizingMode={"nextColumn"}
                        onRowClick={props.onClick}
                        onExporting={onExportClick}
                        height={props.height ?? 1000}
                        columnWidth={props.columnWidth}
                        className={props.onEditIcon ? "editMode" : ""}
                        columnHidingEnabled={props.columnsHiding ? true : false}
                        // rowAlternationEnabled={props.customRowColor ? false : true}
                        onSelectionChanged={props.onSelectionChanged}
                        onToolbarPreparing={props.moveBtnToLeft ? moveAddBtn : undefined}
                        onEditingStart={function (e: any): void {
                            if (props.onEditStart)
                                props.onEditStart();
                        }}
                        onEditCanceled={props.onEditCancel}
                        onRowRemoved={props.onDelete ? props.onDelete : undefinedFunction}
                        onRowPrepared={props.id === "foremanMessagingResponses" ? rowPreForemanMessaging : 
                                       props.id === "ExternalMoveRequest" ? rowPrepExternalMovePage : 
                                       props.id === "ExternalRepairLineParts" ? rowPrepExternalRepairLinePartsPage : 
                                       props.id === "repairLineList" ? rowPrepRepairLine : 
                                       (props.id === "attachmentVersionHistoryList" || props.id === "attachmentMoveRequestList" || props.id === "attachmentMoveRequestHistoryList") ? rowPrepAttachmentList :
                                       props.id === "repairLine-unit-search" || props.id === "repairLine-attachment-search" ? rowPrepRepairLineUnitDropdown :
                                       props.id === "WorkingSiteLog" ? rowPrepWorkingSiteLog : 
                                       props.onRowPrepared ? props.onRowPrepared : 
                                       undefinedFunction}
                        onCellPrepared={(e: CellPreparedEvent<any, unknown>) => {
                            if (e.data) {
                                if(props.id === "ExternalRepairLineParts") {
                                    if(e.data.parts_Dispatched === "Yes")
                                        e.cellElement.style.backgroundColor = LIGHT_GREEN_COLOR;
                                }
                                switch (e.column.dataField) {
                                    case 'next_100_Due':
                                        if (e.data.next_100_Due > 50) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if (e.data.next_100_Due > -51 && e.data.next_100_Due < 51 && e.data.last_100 != null && e.data.next_100_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        break;
                                    case 'next_250_Due':
                                        if (e.data.next_250_Due > 50) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if (e.data.next_250_Due > -51 && e.data.next_250_Due < 51 && e.data.last_EO != null && e.data.next_250_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        break;
                                    case 'next_500_Due':
                                        if (e.data.next_500_Due > 50 && e.data.last_500 != null) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if ((e.data.next_500_Due > -51 && e.data.next_500_Due < 51) && e.data.last_500 != null && e.data.next_500_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        if (e.data.last_500 === null) {
                                            e.cellElement.textContent = '';
                                        }
                                        break;
                                    case 'next_1000_Due':
                                        if (e.data.next_1000_Due > 100 && e.data.last_1000 != null) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if ((e.data.next_1000_Due > -101 && e.data.next_1000_Due < 101) && e.data.last_1000 != null && e.data.next_1000_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        if (e.data.last_1000 === null) {
                                            e.cellElement.textContent = '';
                                        }
                                        break;
                                    case 'next_1500_Due':
                                        if (e.data.next_1500_Due > 150 && e.data.last_1500 != null) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if ((e.data.next_1500_Due > -151 && e.data.next_1500_Due < 151) && e.data.last_1500 != null && e.data.next_1500_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        if (e.data.last_1500 === null) {
                                            e.cellElement.textContent = '';
                                        }
                                        break;
                                    case 'next_3000_Due':
                                        if (e.data.next_3000_Due > 300 && e.data.last_3000 != null) {
                                            e.cellElement.style.backgroundColor = 'red';
                                        }
                                        if ((e.data.next_3000_Due > -301 && e.data.next_3000_Due < 301) && e.data.last_3000 != null && e.data.next_3000_Due != null) {
                                            e.cellElement.style.backgroundColor = 'yellow';
                                        }
                                        if (e.data.last_3000 === null) {
                                            e.cellElement.textContent = '';
                                        }
                                        break;
                                }
                            }
                        }}
                    >
                        {(props.onEdit || props.onDelete || props.onEditIcon || props.onCustomEdit || props.editRow) && <Editing
                            mode="row"
                            allowAdding={props.add ? true : false}
                            allowUpdating={props.allowRowEditing ? props.allowRowEditing : props.onCustomEdit ? true : props.onEditIcon ? true : props.onEdit ? true : props.editRow ? true : false}
                            allowDeleting={props.onDelete ? true : false}
                        />}

                        {props.selectionRow && <Selection mode="multiple" />}

                        {((props.onEdit || props.onDelete || props.customBtn || props.customBtnLabel || props.onEditIcon || props.onCustomEdit)) && <Column type="buttons" width={window.innerWidth <= 1340 ? 50 : props.actionWidth ?? 180} >
                            {(props.onEdit) && window.innerWidth > 1340 && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text={props.onEditColumnLabel ? props.onEditColumnLabel : "View/Update"} onClick={props.onEdit} icon={""} />}
                            {(props.onCustomEdit) && window.innerWidth <= 1340 && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text="edit" onClick={props.onCustomEdit} icon={"edit"} />}
                            {(props.onEdit) && window.innerWidth <= 1340 && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text="edit" onClick={props.onEdit} icon={"edit"} />}
                            {(props.onEditIcon) && <LAIconButton label="Edit" icon={<AddIcon />} onClick={props.onEditIcon} />}
                            {props.onDelete && <LAIconButton label="Edit" icon={<DeleteIcon />} onClick={props.onDelete} />}
                            {props.customBtn && props.customBtnLabel && <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text={props.customBtnLabel} onClick={props.customBtn} />}
                        </Column>}

                        <Grouping contextMenuEnabled={true} expandMode="rowClick" />

                        {props.columns.map((x, index): JSX.Element => {
                            if (x.dropDownSource !== undefined) {
                                return <Column dataField={x.name} caption={x.caption ? x.caption : x.name} allowReordering={props.columnReordering === true ? true : false}>

                                    <Lookup dataSource={x.dropDownSource} displayExpr={x.dropDownName} valueExpr={x.dropDownId} />
                                    {x.requiredField && <StringLengthRule
                                        min={1}
                                        message={(x.caption ? x.caption : x.name) + " Required"}
                                    />}
                                </Column>
                            } else {
                                if ((x.dropDownSource === undefined) && (x.type === "date")) {
                                    return <Column
                                        key={index}
                                        width={x.width}
                                        dataType={x.type}
                                        dataField={x.name}
                                        allowEditing={x.allowEditing ?? true}
                                        format="MM/dd/yyyy"
                                        caption={x.caption ? x.caption : x.name}
                                        sortOrder={x.sortDesc ? "desc" : (x.sortAsc ? "asc" : undefined)}
                                        visible={x.show === false ? false : true}
                                        allowReordering={props.columnReordering === true ? true : false}

                                    />
                                }
                                if ((x.dropDownSource === undefined) && (x.type === "button") && (x.name === "equipmentListActions")) {
                                    return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined}
                                        cellRender={(val) => {
                                            if ((val.data.socT_ID !== undefined) && (val.data.socT_ID > ZEROTH)) {
                                                return <>
                                                    <LAIconButton icon={<VersionHistoryIcon color={RED_COLOR} />} style={{ marginRight: '-6px'}} label="Version History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("history", val.data) : undefinedFunction} />
                                                    {/* <LAIconButton icon={<LocationHistoryIcon color={ORANGE_COLOR} />} label="Location History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("loc_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<SoctIcon color={ORANGE_COLOR} />} label="SOCT History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("soct_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<LocationPinIcon color={BLUE_COLOR} />} label="GPS History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("gps_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<RepairLineIcon color={BLUE_COLOR} />} label="Repair History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("repair_history", val.data) : undefinedFunction} /> */}
                                                    <LAIconButton icon={<DozerIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Unit History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("unit_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<InfoIcon color="primary" />}  style={{ marginRight: '-6px'}} label="Unit Details" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("unit_Details", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<AttachmentIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Attachment History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("attachment_History", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<DocumentsIcon color={BLUE_GREEN_COLOR} />} style={{ marginRight: '-6px'}} label="Equipment Manuals" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("equipment_Manuals", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<TechSupportIcon color={BLUE_COLOR} width={30} height={30}/>}  style={{ marginRight: '-6px'}} label="TSI" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("tsi", val.data) : undefinedFunction} />
                                                </>
                                            }
                                            else {
                                                return <>
                                                    <LAIconButton icon={<VersionHistoryIcon color={RED_COLOR} />} style={{ marginRight: '-6px'}} label="Version History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("history", val.data) : undefinedFunction} />
                                                    {/* <LAIconButton icon={<LocationHistoryIcon color={ORANGE_COLOR} />} label="Location History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("loc_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<LocationPinIcon color={BLUE_COLOR} />} label="GPS History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("gps_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<RepairLineIcon color={BLUE_COLOR} />} label="Repair History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("repair_history", val.data) : undefinedFunction} /> */}
                                                    <LAIconButton icon={<DozerIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Unit History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("unit_history", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<InfoIcon color="primary" />} style={{ marginRight: '-6px'}} label="Unit Details" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("unit_Details", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<AttachmentIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Attachment History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("attachment_History", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<DocumentsIcon color={BLUE_GREEN_COLOR} />} style={{ marginRight: '-6px'}} label="Equipment Manuals" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("equipment_Manuals", val.data) : undefinedFunction} />
                                                    <LAIconButton icon={<TechSupportIcon color={BLUE_COLOR} width={30} height={30}/>} style={{ marginRight: '-6px'}} label="TSI" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("tsi", val.data) : undefinedFunction} />
                                                </>
                                            }
                                        }}
                                        allowReordering={props.columnReordering === true ? true : false}
                                    />
                                } else if ((x.dropDownSource === undefined) && (x.type === "button") && (x.name === "list-view-history")) {
                                    return ETListViewDevExtreme(x, props);
                                } else if ((x.dropDownSource === undefined) && (x.type === "button") && (x.name === "list-view-action")) {
                                    return ETListViewActionDevExtreme(x, props);
                                } else if (x.type === "ws-action-buttons") {
                                    return <Column caption={x.caption} width={x.width} type="buttons" alignment="center" allowReordering={props.columnReordering === true ? true : false}
                                        cellRender={(val) => {
                                            if (val.data) {
                                                return <div>
                                                    <LAIconButton icon={<VersionHistoryIcon color={RED_COLOR} />} label="History" onClick={() => props.onCustomIconClick ? props.onCustomIconClick("history", val.data) : undefinedFunction} />
                                                </div>
                                            }
                                        }}

                                    />
                                } else if ((x.dropDownSource === undefined) && x.type === "link") {
                                    return <Column caption={x.caption} width={x.width} type="buttons" alignment="center" allowReordering={props.columnReordering === true ? true : false} visible={x.show === false ? false : true}
                                        cellRender={(val) => {
                                            if (val.data) {
                                                const find = val.data[x.name];
                                                const onCustomIconClick = (): void => find && props.onCustomIconClick ? props.onCustomIconClick(x.name, val.data) : undefined;
                                                return <div>
                                                    {find ? <div className="link-btn" onClick={onCustomIconClick}>{x.linkButtonText ? x.linkButtonText : find}</div> : ""}
                                                </div>
                                            }
                                        }}
                                    />

                                } else if ((x.dropDownSource === undefined) && (x.type === "button")) {
                                    const onCustomClick = (e: any): void => props.onCustomClick ? props.onCustomClick(x.name, e) : undefined;
                                    return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} allowReordering={props.columnReordering === true ? true : false}>
                                        <Button text={x.caption} onClick={onCustomClick} />
                                    </Column>

                                } else if ((x.dropDownSource === undefined) && (x.type === "soct_button")) {
                                    const onCustomSOCTClick = (record: any): void => props.onCustomSOCTClick ? props.onCustomSOCTClick(x.name, record.socT_ID) : undefined;
                                    return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} cellRender={(val) => {

                                        if ((val.data.socT_ID !== undefined) && (val.data.socT_ID > ZEROTH) && (val.data.status === "Active")) {
                                            return <LALinkButton className="linkUrl" label="SOCT" onClick={() => onCustomSOCTClick(val.data)} />
                                        } else {
                                            return <></>
                                        }

                                    }} allowReordering={props.columnReordering === true ? true : false} />

                                } else if (x.type === "move_button") {
                                    const onMoveClick = (e: any): void => props.onMoveClick ? props.onMoveClick(x.name, e) : undefined;
                                    return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} visible={x.show === false ? false : true} allowReordering={props.columnReordering === true ? true : false}
                                        cellRender={(val) => {
                                            if ((val.data.status === "Initiated" || val.data.status === "Requested" || val.data.status === "Dispatched" || val.data.status === "En Route")) {
                                                return <LAButton label={x.caption ? x.caption : ""} onClick={() => onMoveClick(val)} className="moveBtn" />
                                            }
                                            else {
                                                return <></>
                                            }
                                        }} />

                                }
                                else if (x.type === "ETMoveStatus") {
                                   return EquipmentMoveRequestStatusButton(x, props);
                                } else if (x.type === "edit-button") {
                                    if (window.innerWidth > 1340) {
                                        return <Column type="buttons" width={props.actionWidth ?? 180} caption={x.caption} >
                                            <Button cssClass={props.removeStyleBtn ? "btn" : "control-button editBg"} text="Edit" onClick={props.onEdit} icon={"edit"} />
                                        </Column>
                                    }
                                    else {
                                        return <></>
                                    }
                                } else if (x.type === "unit_number_mobile_view_button") {

                                    if (window.innerWidth <= 1340) {
                                        const onETMoveReqMobileClick = (e: any): void => props.onETMoveReqMobileClick ? props.onETMoveReqMobileClick(x.name, e) : undefined;
                                        return <Column allowReordering={props.columnReordering === true ? true : false} caption={x.caption} width={x.width} sortOrder={x.sortAsc ? "asc" : undefined} visible={x.show === false ? false : true}
                                            cellRender={(e: any) => {
                                                if (e.row.data.equipment_ID !== 0) { return <a href="#" style={{ textDecoration: 'underline' }} onClick={() => onETMoveReqMobileClick(e)}>{e.row.data.combined_Unit_Info}</a> }
                                                else { return <a href="#" style={{ textDecoration: 'underline', color: '#ECB041' }} onClick={() => onETMoveReqMobileClick(e)}>{e.row.data.combined_Unit_Number}</a> }
                                            }
                                            }>
                                        </Column>
                                    }
                                    else {
                                        return <></>
                                    }

                                } else if (props.pictureField && (x.type === "picture")) {
                                    return <Column
                                        width={100}
                                        caption={"Photos"}
                                        allowSorting={false}
                                        cellRender={cellRender}
                                        dataField={props.pictureField}

                                        allowReordering={props.columnReordering === true ? true : false}
                                    />
                                } else if ((x.type === "picture")) {
                                    return <Column
                                        width={80}
                                        caption={"Images"}
                                        allowSorting={false}
                                        cellRender={(e:any) => {
                                            if(e.data.default_Image === null) {
                                                return <div></div>
                                            } else {
                                                const image = e.data.pictures?.map((image:any) => ({url:`${END_POINTS.IMG_DIRECTORY}${image.name}`, title: `${image.description}`}));
                                                // const images = e.data.pictures.map((image:any) => `${END_POINTS.IMG_DIRECTORY}${image.name}`);
                                                const onImageClick = (e: any): void => props.onImageClick ? props.onImageClick(x.name, image) : undefined;
                                                    return <img 
                                                            alt="Equipment" 
                                                            onClick={onImageClick} 
                                                            src={e.data.default_Image ? `${END_POINTS.IMG_DIRECTORY}${e.data.default_Image}` : Not_Available_Image} 
                                                            width="40" 
                                                            height="40" 
                                                        />
                                                }
                                            }}
                                            allowReordering={props.columnReordering === true ? true : false}
                                    />
                                } else if ((x.type === "signature")) {
                                    return <Column
                                        width={x.width}
                                        caption={"Signature"}
                                        allowSorting={false}
                                        cellRender={(e:any) => {
                                            return e.data.signature && <LAThumbnailWithLink
                                                alt={"signature"}
                                                key={e.data.name}
                                                url={base64ToImageUrl(e.data.signature) ?? ""}
                                                id={(e.data.name.name + "_").toString()}
                                            />
                                        }}
                                        allowReordering={props.columnReordering === true ? true : false}
                                    />
                                } else if (x.type === "delete_trucking_request_email_button") {
                                    const onDeleteTruckingRequestEmail = (e: any): void => props.onDeleteTruckingRequestEmail ? props.onDeleteTruckingRequestEmail(x.name, e) : undefined;
                                    return <Column allowReordering={true} caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} visible={x.show === false ? false : true} cellRender={(val) => {
                                        return <LAButton label={x.caption ? x.caption : ""} onClick={() => onDeleteTruckingRequestEmail(val)} className="reject-btn" />
                                    }}>
                                    </Column>

                                } else {
                                    return <Column
                                        key={index}
                                        width={x.width}
                                        dataType={x.type}
                                        dataField={x.name}
                                        allowSearch={true}
                                        visible={x.show === false ? false : true}
                                        format={x.format}
                                        alignment={"left"}
                                        sortOrder={x.sortDesc ? "desc" : x.sortAsc ? "asc" : undefined}
                                        calculateCellValue={x.calculated}
                                        allowEditing={x.allowEditing ?? true}
                                        caption={x.caption ? x.caption : x.name}
                                        cellRender={x.renderCell}
                                        allowReordering={props.columnReordering === true ? true : false}

                                    >
                                        {(x.requiredField && x.type === "string") ? <StringLengthRule
                                            min={1}
                                            max={x.max}
                                            message={(x.caption ? x.caption : x.name) + " Required"}
                                        /> : undefined}
                                    </Column>
                                }
                            }
                        })}

                        {props.pageNumber ?
                            <Paging
                                pageIndex={props.pageNumber}
                                defaultPageSize={props.paging ? props.paging : 20}
                            /> :
                            <Paging
                                defaultPageSize={props.paging ? props.paging : 20}
                            />
                        }
                        <Pager
                            visible={true}
                            allowedPageSizes={"auto"}
                            displayMode={'full'}
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true} />
                        {props.export && <Export enabled={true} />}
                        <Scrolling columnRenderingMode="virtual" />
                        <FilterRow visible={props.filterHeader ? true : false} />
                        <HeaderFilter visible={props.filterHeader ? true : false} />
                        <Grouping autoExpandAll={false} />
                        <ColumnChooser
                            mode="select"
                            height={window.innerHeight - 100}
                            enabled={props.columnChoose ?? true}
                        />
                        <StateStoring
                            type="custom"
                            customLoad={loadState}
                            customSave={saveState}
                            storageKey={props.storageKey}
                            enabled={props.storageKey ? true : false}
                        />
                        {props.sumColumn && <Summary>
                            {props.sumColumn.map((x) => (
                                <TotalItem
                                    alignment="left"
                                    summaryType="sum"
                                    column={x.columnName}
                                    showInColumn={x.columnName}
                                    displayFormat={(total: any) => `${x.caption}: ${formatTotalValue(total)}`}
                                />
                            ))}
                        </Summary>}

                        {props.searchPanel && <SearchPanel
                            visible={true}
                            width={200}
                            // text={searchValue}
                            // onTextChange={handleValueChange}
                            placeholder="Search..."
                        />}

                    </DataGrid>
                </LAGridItem>

            </LAGrid>
        </LADevExtremeGridStyles>
    );
});

const moveAddBtn = (e: EventInfo<dxDataGrid<any, unknown>> & ToolbarPreparingInfo): any => {
    return e.toolbarOptions.items?.forEach((x: any) => {
        x.location = "before";
    });
};

export const formatTotalValue = (value: any) => {
    if (isNaN(Number(value))) {
        return value;
    } else {
        return Number(value).toFixed(2);
    }
}

export const onExporting = (e: any, name: string): any => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(name);

    exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), name + ".xlsx");
        });
    });
    e.cancel = true;
};


const cellRender = (data: any): JSX.Element => {
    return <img alt="part" src={data.value ? data.value : Not_Available_Image} width="100" height="auto" />
};

const ETListViewDevExtreme = (x: any, props: any) => {
    return <Column caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined}
        cellRender={(val) => {
            const onSoct = (): void => props.onCustomIconClick ? props.onCustomIconClick("soct_history", val.data) : undefinedFunction();
            const onBill = (): void => props.onCustomIconClick ? props.onCustomIconClick("billing_history", val.data) : undefinedFunction();
            return <>
                {(val.data.add_To_SOCT === "Yes") && <LAIconButton icon={<SoctIcon color={BLUE_COLOR} />} label="SOCT History" onClick={onSoct} />}
                <LAIconButton icon={<VersionHistoryIcon color={ORANGE_COLOR} />} label="Location History" onClick={onBill} />
            </>
        }}
        allowReordering={props.columnReordering === true ? true : false}
    />
};

const ETListViewActionDevExtreme = (x: any, props: any) => {

    return <Column caption={x.caption} width={x.width} type="buttons"
        cellRender={(val) => {

            const onMove = (): void => props.onCustomIconClick ? props.onCustomIconClick("move", val.data) : undefinedFunction();
            const onCancel = (): void => props.onCustomIconClick ? props.onCustomIconClick("cancel", val.data) : undefinedFunction();
            const onInitiate = (): void => props.onCustomIconClick ? props.onCustomIconClick("initiate", val.data) : undefinedFunction();
            const onComplete = (): void => props.onCustomIconClick ? props.onCustomIconClick("complete", val.data) : undefinedFunction();

            const complete = (val.data.move_Status !== "");
            const init = (val.data.move_Status !== "Initiated" && val.data.move_Status !== "Requested" && val.data.move_Status !== "Dispatched" && val.data.move_Status !== "En Route");
            const move = ((val.data.move_Status === "Initiated" || val.data.move_Status === "Requested" || val.data.move_Status === "Dispatched") && (val.data.move_Status !== "En Route"));

            return <>
                {init && <div className="initiate-btn" onClick={onInitiate}>
                    Initiate
                </div>}

                {move && <div className="reject-btn" onClick={onMove}>
                    Move
                </div>}

                {complete && <>
                    <div className="complete-btn" onClick={onComplete}>
                        Complete
                    </div>

                    <div className="cancel-btn" onClick={onCancel}>
                        Cancel
                    </div>
                </>}
            </>
        }}
    />
};

const EquipmentMoveRequestStatusButton = (x: any, props: any) => {
    const onRejectApproveClick = (e: any): void => props.onRejectApproveClick ? props.onCustomIconClick(x.name, e) : undefined;
    return <Column allowReordering={props.columnReordering === true ? true : false} caption={x.caption} width={x.width} type="buttons" sortOrder={x.sortAsc ? "asc" : undefined} visible={x.show === false ? false : true} cellRender={(val) => {
        if (val.data.status === "Requested") {
            return <LAButton label={x.caption ? x.caption : ""} onClick={() => onRejectApproveClick(val)} className="reject-btn" />
        }
        else {
            return <></>
        }
    }}>
    </Column>
};