import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SOCT_BY_ID_REQUEST, IGetSOCTByIdRequest, ISOCT } from "./getSOCTByIdConstants";

export interface IGetSOCTByIdLoadAction {
    type: IGET_SOCT_BY_ID_REQUEST.REQUEST;
    data: IGetSOCTByIdRequest
}
export const getSOCTByIdLoadAction = (data: IGetSOCTByIdRequest): IGetSOCTByIdLoadAction => ({
    type: IGET_SOCT_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetSOCTByIdSuccessAction {
    type: IGET_SOCT_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISOCT>;
}
export const getSOCTByIdLoadSuccessAction = (list: SurewayAPIResponse<ISOCT>): IGetSOCTByIdSuccessAction => ({
    type: IGET_SOCT_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetSOCTByIdLoadFailedAction {
    type: IGET_SOCT_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getSOCTByIdLoadFailedAction = (message: string): IGetSOCTByIdLoadFailedAction => ({
    type: IGET_SOCT_BY_ID_REQUEST.FAILED,
    message
});
