import { IAddUpdateCrew } from "../addCrew/addCrewConstants";

export enum IUPDATE_CREW_REQUEST {
    REQUEST = "updateCrew/UPDATE_CREW_REQUEST",
    SUCCESS = "updateCrew/UPDATE_CREW_SUCCESS",
    FAILED = "updateCrew/UPDATE_CREW_FAILED"
};

export interface IUpdateCrewRequest {
    token: string;
    request: IAddUpdateCrew;
};