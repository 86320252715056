import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST, IWorkingSiteLogOnCall } from "./getWorkingSiteLogsOnCallConstants";
import { IGetWorkingSiteLogsOnCallLoadAction, IGetWorkingSiteLogsOnCallLoadFailedAction, IGetWorkingSiteLogsOnCallSuccessAction, 
    getWorkingSiteLogsOnCallLoadFailedAction, getWorkingSiteLogsOnCallLoadSuccessAction } from "./getWorkingSiteLogsOnCallActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getWorkingSiteLogsOnCallEpic: Epic = (
    action$: ActionsObservable<IGetWorkingSiteLogsOnCallLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetWorkingSiteLogsOnCallSuccessAction | IGetWorkingSiteLogsOnCallLoadFailedAction> =>
    action$.ofType(IGET_WORKING_SITE_LOGS_ON_CALL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>>(
                    END_POINTS.FIELD.WORKING_SITE_LOGS.GET_WORKING_SITE_LOGS_ON_CALL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IWorkingSiteLogOnCall>>): IGetWorkingSiteLogsOnCallSuccessAction => {
                            return getWorkingSiteLogsOnCallLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getWorkingSiteLogsOnCallLoadFailedAction(response.message)))
                    )
            )
        );