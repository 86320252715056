
export enum IGET_LASER_REQUEST {
    REQUEST = "getLasers/GET_LASER_REQUEST",
    SUCCESS = "getLasers/GET_LASER_SUCCESS",
    FAILED = "getLasers/GET_LASER_FAILED"
};

export interface ILasersRequest {
    token: string;
};

export interface ILasers {
    id: number;
    serial_No: string;
    type?: string;
    brand?: string;
    model?: string;
    remote_Serial_Number?: string;
    remote_Model?: string;
    sureway_No?: string;
    status?: string;
    site_Foreman?: string;
    wsS_ID: number | null;
    foreman: string;
    certification_Date?: string;
    notes: string;
    created: string;
    modified: string;
    created_By: string;
    modified_By: string;
};