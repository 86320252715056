import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_FOREMAN_MESSAGING_REQUEST, IForemanMessaging, IGetForemanMessagingRequest } from "./getForemanMessagingConstants";

export interface IGetForemanMessagingLoadAction {
    type: IGET_FOREMAN_MESSAGING_REQUEST.REQUEST;
    data: IGetForemanMessagingRequest
}
export const getForemanMessagingLoadAction = (data: IGetForemanMessagingRequest): IGetForemanMessagingLoadAction => ({
    type: IGET_FOREMAN_MESSAGING_REQUEST.REQUEST,
    data
});
export interface IGetForemanMessagingSuccessAction {
    type: IGET_FOREMAN_MESSAGING_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IForemanMessaging>>;
}
export const getForemanMessagingLoadSuccessAction = (list: SurewayAPIResponse<ById<IForemanMessaging>>): IGetForemanMessagingSuccessAction => ({
    type: IGET_FOREMAN_MESSAGING_REQUEST.SUCCESS,
    list
});
export interface IGetForemanMessagingLoadFailedAction {
    type: IGET_FOREMAN_MESSAGING_REQUEST.FAILED;
    message: string;
}
export const getForemanMessagingLoadFailedAction = (message: string): IGetForemanMessagingLoadFailedAction => ({
    type: IGET_FOREMAN_MESSAGING_REQUEST.FAILED,
    message
});
