import { connect } from "react-redux";
import styled from "styled-components";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { STATUS_ENUM, Server, hasPayload, isNotLoaded } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { IGetSwamperRequest, ISwamper } from "../../../../redux/field/dispatch/getSwampers/getSwampersConstants";
import { LAPaperWithPadding } from "../../../shared/paper";
import { BLUE_COLOR, DARK_RED_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { YesOrNo, callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../../shared/constExports";
import PageSpacing from "../../../shared/pageSpacing";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import LALoading from "../../../shared/loading";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getSwampers } from "../../../../redux/field/dispatch/getSwampers/getSwampersAccessor";
import { getSwampersLoadAction } from "../../../../redux/field/dispatch/getSwampers/getSwampersActions";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { DispatchSubHeader } from "../../../header/dispatchSubHeader";
import LAErrorBox from "../../../shared/errorBox";

interface ISwampersStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getSwamperStatus: Server<SurewayAPIResponse<ById<ISwamper>>>;
};

interface ISwampersDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getSwampersRequest: (data: IGetSwamperRequest) => unknown;
};


interface ISwampersOwnProps {

};

interface ISwampersState {
    // activeView: boolean;
    // activeSwampers: ISwamper[];
};

const SwampersStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 2.5%;
        top: 3%;
        background-color: rgb(191, 0, 0);
    };

    .hireForm_Link {
        color: ${DARK_RED_COLOR}
    }

    .toggledMenu{
        margin-left: 500px;
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type ISwampersProps = RouteComponentProps
    & ISwampersStoreProps
    & ISwampersDispatchProps
    & ISwampersOwnProps;

class Swampers extends PureComponent<ISwampersProps, ISwampersState> {

    public constructor(props: ISwampersProps) {
        super(props);
        this.state = {
            activeView: true,
            activeSwampers: [],
        };
    }

    public componentDidMount(): void {
        // this.checkViewSelections();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ISwampersProps): void {
        // this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getSwamperStatus, getToken } = this.props;
        // const { activeView, activeSwampers } = this.state;
        const getRole = pageAccessCheck(getToken, "dispatch");
        // const data = activeView ? activeSwampers : hasPayload(getSwamperStatus) ? Object.values(getSwamperStatus.payload.response) : [];
        const data = hasPayload(getSwamperStatus) ? Object.values(getSwamperStatus.payload.response) : [];

        return (
            <PageSpacing title="Swampers" description="FIELD - SWAMPERS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <>
                    <SwampersStyles>

                        <DispatchSubHeader
                            {...this.props}
                            token={getToken}
                        />

                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                {/* <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                /> */}

                                <h2 className="text-center">SWAMPERS</h2>
                                <hr />
                            </LAGridItem>

                            {getSwamperStatus.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Swampers..." />}
                            {getSwamperStatus.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Swampers..." />}

                            {getSwamperStatus.kind === STATUS_ENUM.SUCCEEDED && <>
                                {/* <LAGridItem xs={12}>
                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                        <FormControlLabel value="active" control={<Radio />} label="Active Swampers" />
                                        <FormControlLabel value="all" control={<Radio />} label="All Swampers" />
                                    </RadioGroup>
                                </LAGridItem> */}

                                <LAGridItem xs={12} className="text-center">
                                    <h3 className="hireForm_Link">Swampers are managed through Hire form. <span className="link-btn" onClick={this.handleClickHereBtn}>Click here</span> to access the HR portal. </h3>
                                    <LADevExtremeGrid
                                        data={data}
                                        columnChoose={true}
                                        columnsHiding={true}
                                        // onEdit={this.onEdit}
                                        // onEditColumnLabel="View"
                                        onClick={this.onClick}
                                        searchPanel={true}
                                        filterHeader={true}
                                        export={true}
                                        removeStyleBtn={true}
                                        id="swamperList"
                                        customRowColor={true}
                                        exportFileName="Swampers"
                                        storageKey="swampersSessionKey"
                                        getRefreshDataCall={this.getDataForTable}
                                        columns={[
                                            // { name: "name", caption: "Name", type: "string" },
                                            { name: "firstName", caption: "First Name", type: "string" },
                                            { name: "lastName", caption: "Last Name", type: "string" },
                                            { name: "email", caption: "Email", type: "string" },
                                            { name: "phone", caption: "Phone", width: 100, type: "string" },
                                            { name: "comments", caption: "Comments", type: "string" },
                                            { name: "active", caption: "Active", width: 100, type: "string" },
                                            { name: "created", caption: "Created", type: "datetime" },
                                            { name: "created_By", caption: "created By", type: "string" },
                                            { name: "modified", caption: "Modified", type: "datetime" },
                                            { name: "modified_By", caption: "Modified By", type: "string" }
                                        ]}
                                    />
                                </LAGridItem>
                            </>}

                        </LAGrid>
                    </SwampersStyles>
                </>}
            </PageSpacing>
        );
    }

    private handleClickHereBtn = (): void => {
        window.open(ROUTE.HIRE_FORM.INDEX, '_blank')
    }

    // private onViewClick = (): void => {
    //     this.setState({ activeView: !this.state.activeView });
    //     let activeView = !this.state.activeView;
    //     const getStorageValue = localStorage.getItem("key_swampers");
    //     if (getStorageValue) {
    //         if (activeView) {
    //             localStorage.setItem("key_swampers", "active");
    //         }
    //         else {
    //             localStorage.setItem("key_swampers", "all");
    //         }
    //     }
    //     else {
    //         localStorage.setItem("key_swampers", "active");
    //     }
    // };

    // private onEdit = (e: any): void => {
    //     callRouteWithQueryString({
    //         route: this.props,
    //         search: { id: e.row.data.id.toString() },
    //         pathName: ROUTE.FIELD.DISPATCH.SWAMPER
    //     });
    // };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.DISPATCH.SWAMPER
        });
    };

    // private checkViewSelections = (): void => {
    //     let activeView = this.state.activeView;
    //     const getStorageValue = localStorage.getItem("key_swampers");
    //     if (getStorageValue) {
    //         if (getStorageValue === "active") {
    //             activeView = true;
    //         }
    //         else {
    //             activeView = false;
    //         }
    //     }
    //     else {
    //         localStorage.setItem("key_swampers", "active");
    //     }
    //     this.setState({ activeView: activeView });
    // }

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "dispatch") !== NotApplicable) {
                if (isNotLoaded(this.props.getSwamperStatus)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.UNAUTHORIZED,
                    search: getTokenFromUrl(false)
                });
            }

            if (hasPayload(this.props.getSwamperStatus)) {
                this.setState({ activeSwampers: Object.values(this.props.getSwamperStatus.payload.response).filter(x => x.active === YesOrNo[0].name) });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getSwampersRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ISwampersStoreProps => ({
    getToken: getToken(state),
    getSwamperStatus: getSwampers(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ISwampersDispatchProps => ({
    getSwampersRequest: (data: IGetSwamperRequest) => dispatch(getSwampersLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Swampers);