import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LABluePaper, LAGreenPaper, LAPaperWithLessPadding, LAPaperWithPadding, LARedPaper, LAYellowPaper } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse, ToolRentalsResponse } from "../../shared/publicInterfaces";
import { IConvertList, convertList, undefinedFunction, userName, ZEROTH, getTokenFromUrl, fileToBase64Image, deleteText, callRouteWithQueryString, getConvertedValue } from "../../shared/constExports";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import { ArrowLeftIcon, AttachmentIcon, DeleteIcon, DocumentsIcon, DownloadIcon, DozerIcon, TechSupportIcon, VersionHistoryIcon } from "../../shared/icons";
import { BLACK_COLOR, BLUE_COLOR, BLUE_GREEN_COLOR, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { CertificateTypes, DynamicColumns, equipmentStatusDropdown, equipmentReadingsDropdown, IShopEquipment, IShopEquipmentFile, IShopEquipmentPicture, IUnitAttachmentList } from "../../../redux/field/getEquipmentByID/getEquipmentByIDConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
// import { getShopEquipmentsLoadAction } from "../../../redux/field/getEquipments/getEquipmentActions";
import { IAddShopEquipmentRequest } from "../../../redux/field/addEquipments/addShopEquipmentsConstants";
import { IUpdateShopEquipmentRequest } from "../../../redux/field/updateEquipments/updateShopEquipmentsConstants";
import { addShopEquipmentLoadAction } from "../../../redux/field/addEquipments/addShopEquipmentsActions";
import { updateShopEquipmentLoadAction } from "../../../redux/field/updateEquipments/updateShopEquipmentsActions";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
// import { getShopEquipments } from "../../../redux/field/getEquipments/getEquipmentAccessor";
import { addShopEquipment } from "../../../redux/field/addEquipments/addShopEquipmentsAccessor";
import { updateShopEquipment } from "../../../redux/field/updateEquipments/updateShopEquipmentsAccessor";
import { getLookups } from "../../../redux/field/getLookups/getLookupsAccessor";
import { IShopLookupOwners, IShopLookups, IShopLookupsRequest, IShopMakes } from "../../../redux/field/getLookups/getLookupsConstants";
import { getLookupsLoadAction } from "../../../redux/field/getLookups/getLookupsActions";
import { validateShopGroup } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupAccessor";
import { IValidateShopGroup, ReadAndWrite, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import RoleBasedAccessForShop, { shopRoleCheck } from "../../shared/roleBaseAccessForShop";
import { LACenteredLoading } from "../../shared/loading";
import { getShopEquipmentColumnsLoadAction } from "../../../redux/field/getShopEquipmentColumns/getShopEquipmentColumnsActions";
import { IGetShopEquipmentColumn, IGetShopEquipmentColumnRequest } from "../../../redux/field/getShopEquipmentColumns/getShopEquipmentColumnsConstants";
import { getShopEquipmentColumns } from "../../../redux/field/getShopEquipmentColumns/getShopEquipmentColumnsAccessor";
import { IGetEquipmentHistoryColumn, IGetEquipmentHistoryColumnRequest } from "../../../redux/field/getEquipmentHistoryColumn/getEquipmentHistoryColumnConstants";
import { LADropZone } from "../../shared/dropZone";
import { END_POINTS } from "../../../redux/endpoints";
import { getEquipmentHistoryColumnsLoadAction } from "../../../redux/field/getEquipmentHistoryColumn/getEquipmentHistoryColumnActions";
import { getEquipmentHistoryColumns } from "../../../redux/field/getEquipmentHistoryColumn/getEquipmentHistoryColumnAccessor";
import { AttachmentHistoryPopup, HistoryPopup, VersionHistoryPopup } from "./historyPopup";
import { LACheckBox } from "../../shared/checkBox";
import { AddCertificateHistoryPopup, CertificateHistoryPopup } from "./certificateHistoryPopup";
import React from "react";
import queryString from "query-string";
import { IGetEquipmentVersionHistory, IGetEquipmentVersionHistoryRequest } from "../../../redux/field/getEquipmentVersionHistory/getEquipmentVersionHistoryConstants";
import { getEquipmentVersionHistory } from "../../../redux/field/getEquipmentVersionHistory/getEquipmentVersionHistoryAccessor";
import { getEquipmentVersionHistoryLoadAction } from "../../../redux/field/getEquipmentVersionHistory/getEquipmentVersionHistoryActions";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { webConfig } from "../../../utils/webConfig";
import { v4 as uuidv4 } from "uuid";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { IAddEquipmentCertificate, IAddEquipmentCertificateRequest } from "../../../redux/field/addEquipmentCertificate/addEquipmentCertificateConstants";
import { addEquipmentCertificateLoadAction } from "../../../redux/field/addEquipmentCertificate/addEquipmentCertificateActions";
import { addEquipmentCertificateStatus } from "../../../redux/field/addEquipmentCertificate/addEquipmentCertificateAccessor";
import { FormControlLabel, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IGetAttachmentVersionHistory, IGetAttachmentVersionHistoryRequest } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryConstants";
import { getAttachmentVersionHistory } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryAccessor";
import { getAttachmentVersionHistoryLoadAction } from "../../../redux/field/attachment/getAttachmentVersionHistory/getAttachmentVersionHistoryActions";
import UnitDetails from "./unitDetails";
import { LAPopover } from "../../shared/popOver";
import InfoIcon from '@mui/icons-material/Info';
import { IGetEquipmentByIDRequest } from "../../../redux/field/getEquipmentByID/getEquipmentByIDConstants";
import { getEquipmentByID } from "../../../redux/field/getEquipmentByID/getEquipmentByIDAccessor";
import { getEquipmentByIDLoadAction } from "../../../redux/field/getEquipmentByID/getEquipmentByIDActions";
const LATextField = React.lazy(() => import('../../shared/textField'));
const LAAutoComplete = React.lazy(() => import('../../shared/autoComplete'));
const LADatePicker = React.lazy(() => import('../../shared/datePicker'));
const LADeleteConfirm = React.lazy(() => import('../../shared/deleteConfirmationBox'));
const LAErrorBox = React.lazy(() => import('../../shared/errorBox'));
const LALinkButton = React.lazy(() => import('../../shared/linkButton'));
const LADropDownForOwners = React.lazy(() => import('./equipmentOwnerDropdown'));
// const LATextArea = React.lazy(() => import('../../shared/textArea'));
const LAGrid = React.lazy(() => import('../../shared/grid'));
const LAGridItem = React.lazy(() => import('../../shared/gridList'));
const RequestStatus = React.lazy(() => import('../../shared/requestStatusSnackbar'));
const PageSpacing = React.lazy(() => import('../../shared/pageSpacing'));

const requiredFields = ["equipment", "equipment_Type", "unit_Number", "owner", "model", "make", "serial_Number", "equipmentCode"];
const serviceHoursList = ["100", "250", "500", "1000", "1500", "3000", "Schedule A", "Schedule B", "Schedule D", "Annual Service", "3 Months", "5000K"];
const quickCouplerSizeOptions : IIdName[] = [{id: 1, name: "Hoes"}, {id: 2, name: "Loaders"}, {id: 3, name: "Tractor backhoes"}];

interface IShopEquipmentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    addCertificate: Server<SurewayAPIResponse<string>>;
    addShopEquipment: Server<SurewayAPIResponse<string>>;
    updateShopEquipment: Server<SurewayAPIResponse<string>>;
    getDropDownData: Server<SurewayAPIResponse<IShopLookups>>;
    // getEquipments: Server<SurewayAPIResponse<ById<IShopEquipment>>>;
    getEquipmentByID: Server<SurewayAPIResponse<IShopEquipment>>;
    validateShopGroup: Server<ToolRentalsResponse<IValidateShopGroup>>;
    getShopEquipmentColumnsStatus: Server<SurewayAPIResponse<IGetShopEquipmentColumn>>;
    getEquipmentHistoryColumns: Server<SurewayAPIResponse<IGetEquipmentHistoryColumn[]>>;
    getEquipmentVersionHistory: Server<SurewayAPIResponse<IGetEquipmentVersionHistory[]>>;
    getAttachmentHistory: Server<SurewayAPIResponse<ById<IGetAttachmentVersionHistory>>>;
};

interface IShopEquipmentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getShopEquipmentsRequest: (data: IShopEquipmentRequest) => unknown;
    getEquipmentByIDRequest: (data: IGetEquipmentByIDRequest) => unknown;
    addEquipmentRequest: (data: IAddShopEquipmentRequest) => unknown;
    updateEquipmentRequest: (data: IUpdateShopEquipmentRequest) => unknown;
    getDropDownDataRequest: (data: IShopLookupsRequest) => unknown;
    addCertificateRequest: (data: IAddEquipmentCertificateRequest) => unknown;
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => unknown;
    getEquipmentHistoryColumnsRequest: (data: IGetEquipmentHistoryColumnRequest) => unknown;
    getEquipmentVersionHistoryRequest: (data: IGetEquipmentVersionHistoryRequest) => unknown;
    getAttachmentVersionHistoryRequest: (request: IGetAttachmentVersionHistoryRequest) => unknown;
};


interface IShopEquipmentOwnProps {

};

interface IShopEquipmentState {
    column: string;
    serverError: string;
    certificate: boolean;
    historyOpen: boolean;
    back: true | undefined;
    driverChanged: boolean;
    equipment: IShopEquipment;
    certificateHistory: boolean;
    versionHistoryPopup: boolean;
    attachmentVersionHistoryPopup: boolean;
    unitDetailsPopup: boolean;
    backStatus: true | undefined;
    attachments: IShopEquipmentFile[];
    fieldAttachments: IShopEquipmentFile[];
    errors: ById<IFieldErrorKeyValue>;
    certError: ById<ById<IFieldErrorKeyValue>>;
    lengthView: string;
    widthView: string;
};

export const ETPilotRequiredOptions: IIdName[] = [
    {id: 1, name: "None"},
    {id: 2, name: "1 Pilot"},
    {id: 3, name: "2 Pilots"},
]

const ShopEquipmentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;

    .required-text {
        color: ${WARNING_COLOR};
    };
    .floatRight{
        float: right;
        text-decoration: underline;
    }
    .border {
        border: 3px solid ${BLACK_COLOR};
        padding: 15px;
    }
`;

type IShopEquipmentProps = RouteComponentProps
    & IShopEquipmentStoreProps
    & IShopEquipmentDispatchProps
    & IShopEquipmentOwnProps;

class ShopEquipment extends PureComponent<IShopEquipmentProps, IShopEquipmentState> {

    public constructor(props: IShopEquipmentProps) {
        super(props);
        this.state = {
            errors: {
                "equipment": { key: "equipment", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "unit_Number": { key: "unit_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "serial_Number": { key: "serial_Number", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "owner": { key: "owner", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "model": { key: "model", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "status": { key: "status", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
                "equipmentCode": { key: "equipmentCode", message: FIELD_VALIDATOR_ERRORS.REQUIRED },
            },
            certError: {},
            versionHistoryPopup: false,
            attachmentVersionHistoryPopup: false,
            unitDetailsPopup: false,
            back: undefined,
            attachments: [],
            fieldAttachments: [],
            driverChanged: false,
            backStatus: undefined,
            column: "",
            historyOpen: false,
            certificateHistory: false,
            serverError: "",
            certificate: false,
            lengthView: "Meters",
            widthView: "Meters",
            equipment: {
                id: 0,
                equipment: "",
                model_ID: 0,
                make: "",
                model: "",
                owner: "",
                equipmentCode: "",
                job_No: "",
                files: [],
                quick_Coupler_Size: "",
                field_files: [],
                created_By: "",
                modified_By: "",
                service_Hours: "",
                created: "",
                modified: "",
                certificates: [],
                rental: "",
                parts_Truck: "",
                registration_Certificate: "",
                service_Truck: "",
                move_Status: "",
                repair_Status: "",
                unit_History: "",
                pictures: [],
                update_Location: "No",
                New_WSS_ID: 0,
                width: 0,
                length: 0,
                eT_Move_Pilot_Required: "None",
                eT_Second_Trip: "No",
                mob_Demob: "No",
            }
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getEquipmentByID();
        this.getDropDownData();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IShopEquipmentProps): void {
        if (this.props !== prevProps) {
            this.callServer();

            if (this.props.addShopEquipment !== prevProps.addShopEquipment) {
                if (this.props.addShopEquipment.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addShopEquipment.message });

                if (hasPayload(this.props.addShopEquipment) && this.props.addShopEquipment.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateShopEquipment !== prevProps.updateShopEquipment) {
                if (this.props.updateShopEquipment.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateShopEquipment.message });

                if (hasPayload(this.props.updateShopEquipment) && this.props.updateShopEquipment.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };
        }
    };


    public render(): ReactNode {
        const query: any = queryString.parse(this.props.location.search);
        const { versionHistoryPopup,attachmentVersionHistoryPopup, widthView, lengthView, unitDetailsPopup, equipment, errors, back, attachments, driverChanged, historyOpen, column, certError, certificateHistory, serverError, fieldAttachments, certificate } = this.state;
        const { getEquipmentVersionHistory, getAttachmentHistory, updateShopEquipment, addShopEquipment, getDropDownData, validateShopGroup, getEquipmentByID, getShopEquipmentColumnsStatus, getEquipmentHistoryColumns, getToken } = this.props;
        const dropDown = hasPayload(getDropDownData) ? getDropDownData.payload.response : { manufacturers: [], models: [], equipment_Types: [], owners: [], wss_List: [] };
        const equipTypes = hasPayload(getDropDownData) ? convertList(dropDown.equipment_Types ?? []) : [];
        // console.log(equipTypes)
        const wssList = hasPayload(getDropDownData) ? dropDown.wss_List : [];
        const pictures = equipment.pictures ? equipment.pictures : [];
        const equipColumnsCheck = hasPayload(getShopEquipmentColumnsStatus) ? getShopEquipmentColumnsStatus.payload.response.mapped_Columns.find(x => x.equipment_Type === equipment.equipment_Type) : undefined;
        const equipColumns = equipColumnsCheck ? equipColumnsCheck.column_IDs.split(",") : [];
        const serviceHours = equipment.service_Hours ? equipment.service_Hours.split(",") : [];
        const onDate = (name: string, v: string) => this.onChange(name, new Date(v).toISOString());
        const onOwner = (e: unknown, v: IShopLookupOwners): void => this.handleDropDownChange(e, v !== null ? v.company : "", "owner");
        const onEquipmentTypeChange = (e: unknown, v: IConvertList): void => this.handleDropDownChange(e, v !== null ? v : "", "equipment_Type");
        const onManufacturerChange = (e: unknown, v: IShopMakes): void => this.handleDropDownChange(e, v !== null ? v : "", "make");
        const onStatusChange = (e: unknown, v: IConvertList): void => this.handleDropDownChange(e, v !== null ? v : "", "status");
        const onReadingChange = (e: unknown, v: IConvertList): void => this.handleDropDownChange(e, v !== null ? v : "", "reading_Type");
        const getRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.adminAccess : "";
        const getCVIPRole = hasPayload(validateShopGroup) ? validateShopGroup.payload.response.cvipAccess : "";
        const readOnly = getRole === ReadOnly ? true : undefined;
        const CVIPReadOnly = getCVIPRole === ReadOnly ? true : undefined;
        const onSOCTChange = (check: boolean): void => this.handleSOCTCheck(check);
        const onRentalChange = (check: boolean): void => this.handleRentalCheck(check);
        const onPartsTruckChange = (check: boolean): void => this.handlePartsTruckCheck(check);
        const onETSecondTripChange = (check: boolean): void => this.onChange("eT_Second_Trip", check ? "Yes" : "No");
        const onMobDemobChange = (check: boolean): void => this.onChange("mob_Demob", check ? "Yes" : "No");
        const onETPilotRequiredChange = (e:unknown, value: IIdName): void => this.onChange("eT_Move_Pilot_Required",value ? value.name : "");
        const historyData = hasPayload(getEquipmentHistoryColumns) ? getEquipmentHistoryColumns.payload.response : [];
        const driverHistory = (): void => this.callColumnHistory("driver");
        const ownerHistory = (): void => this.callColumnHistory("owner");
        const plateHistory = (): void => this.callColumnHistory("plate");
        const detailsHistory = (): void => this.callColumnHistory("details");
        const serialHistory = (): void => this.callColumnHistory("serial_Number");
        const unitHistory = (): void => this.callColumnHistory("unit_Number");
        const certificateHistoryClick = (): void => this.callColumnHistory("certificate");
        const versionHistory = hasPayload(getEquipmentVersionHistory) ? getEquipmentVersionHistory.payload.response : [];
        const attachmentVersionHistory = hasPayload(getAttachmentHistory) ? getAttachmentHistory.payload.response : {};
        const onUpdateLocationCheckboxChange = (check: boolean): void => this.handleUpdateLocationCheck(check);
        const onWSS = (value: string): void => this.handleNewSiteChange(value);
        // console.log(equipment)

        if(Number(query.id) > 0 && getEquipmentByID.kind === STATUS.LOADING) return <LACenteredLoading message="Loading ... Please wait" />
        if(Number(query.id) > 0 && getEquipmentByID.kind === STATUS.FAILED) return <LACenteredLoading message="Loading ... Please wait" />
        if(getDropDownData.kind === STATUS.LOADING) return <LACenteredLoading message="Loading ... Please wait" />

        return (
            <PageSpacing title="Equipment List - Equipment" description="Equipment List - Equipment" fixedSpaceOnSmallerScreens={true}>
                <RoleBasedAccessForShop error={true} roleFor={[ReadOnly, ReadAndWrite]}>

                    {/* {getEquipmentByID.kind === STATUS.LOADING && <LACenteredLoading message="Loading ... Please wait" />}
                    {getEquipmentByID.kind === STATUS.FAILED && <LAErrorBox text="Failed to load data... Please try again" />}

                    {(getEquipmentByID.kind === STATUS_ENUM.SUCCEEDED)&& <> */}
                        <ShopEquipmentStyles>

                            <LAButton startIcon={<ArrowLeftIcon color={WHITE_COLOR} />} label="Back to list" onClick={this.handleCancel} />
                            <h2 className="text-center">{equipment.id > 0 ? "VIEW/UPDATE " : "ADD "} EQUIPMENT</h2>
                            <hr />

                            <LAPaperWithPadding>
                                <LAGrid spacing={2}>

                                    <LAGridItem xs={12}>
                                        <LAGreenPaper>
                                            {/* {equipment.id > 0 && <LALinkButton
                                                className="ml-2 floatRight"
                                                label="Version History"
                                                onClick={this.handleVersion}
                                            />} */}

                                            {equipment.id > 0 && <span className="border">
                                                <LAIconButton icon={<VersionHistoryIcon color={RED_COLOR} />} style={{ marginRight: '-6px'}} label="Version History" onClick={() => this.onCustomIconClick("version_history", equipment)} />
                                                <LAIconButton icon={<DozerIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Unit History" onClick={() => this.onCustomIconClick("unit_history", equipment)} />
                                                <LAIconButton icon={<InfoIcon color="primary" />}  style={{ marginRight: '-6px'}} label="Unit Details" onClick={() => this.onCustomIconClick("unit_Details", equipment) } />
                                                <LAIconButton icon={<AttachmentIcon color={BLUE_COLOR} />} style={{ marginRight: '-6px'}} label="Attachment History" onClick={() => this.onCustomIconClick("attachment_History", equipment)} />
                                                <LAIconButton icon={<DocumentsIcon color={BLUE_GREEN_COLOR} />} style={{ marginRight: '-6px'}} label="Equipment Manuals" onClick={() => this.onCustomIconClick("equipment_Manuals", equipment)} />
                                                <LAIconButton icon={<TechSupportIcon color={BLUE_COLOR} width={30} height={30}/>}  style={{ marginRight: '-6px'}} label="TSI" onClick={() => this.onCustomIconClick("tsi", equipment) } />
                                            </span>}

                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>DETAILS</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        freeText={true}
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        option={equipTypes}
                                                        name="equipment_Type"
                                                        filterSelectedOptions={true}
                                                        getOptionLabel="name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={onEquipmentTypeChange}
                                                        dropDownPlaceHolder="Equipment Type"
                                                        errorText={errors["equipment_Type"] ? errors["equipment_Type"].message : undefined}
                                                        value={(hasPayload(getDropDownData) && equipment.equipment_Type) ? equipTypes.find(x => x.name === equipment.equipment_Type) : ""}
                                                        defaultValue={(hasPayload(getDropDownData) && equipment.equipment_Type) ? equipTypes.find(x => x.name === equipment.equipment_Type) : ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Equipment"
                                                        fullWidth={true}
                                                        name="equipment"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.equipment}
                                                        errorText={errors["equipment"] ? errors["equipment"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Unit Number"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="unit_Number"
                                                        onChange={this.onChange}
                                                        value={equipment.unit_Number ?? ""}
                                                        errorText={errors["unit_Number"] ? errors["unit_Number"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Unit History"
                                                        onClick={unitHistory}
                                                    />}


                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LADropDownForOwners
                                                        dropDownPlaceHolder="Owner"
                                                        option={dropDown.owners ?? []}
                                                        defaultValue={dropDown.owners.find(q => q.company === equipment.owner) ?? ""}
                                                        value={dropDown.owners.find(q => q.company === equipment.owner) ?? ""}
                                                        getOptionLabel="company"
                                                        size="small"
                                                        filterSelectedOptions={true}
                                                        autoHighlight={true}
                                                        disabled={readOnly}
                                                        onChange={onOwner}
                                                        errorText={errors["owner"] ? errors["owner"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Owner History"
                                                        onClick={ownerHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        freeText={true}
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        name="make"
                                                        filterSelectedOptions={true}
                                                        option={dropDown.manufacturers}
                                                        getOptionLabel="manufacturer_Name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={onManufacturerChange}
                                                        dropDownPlaceHolder="Make"
                                                        errorText={errors["make"] ? errors["make"].message : undefined}
                                                        value={(hasPayload(getDropDownData) && equipment.make) ? dropDown.manufacturers.find(x => x.manufacturer_Name === equipment.make) : ""}
                                                        defaultValue={(hasPayload(getDropDownData) && equipment.make) ? dropDown.manufacturers.find(x => x.manufacturer_Name === equipment.make) : ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Model"
                                                        fullWidth={true}
                                                        name="model"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.model ?? ""}
                                                        errorText={errors["model"] ? errors["model"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Year"
                                                        fullWidth={true}
                                                        name="year"
                                                        disabled={readOnly}
                                                        onChange={this.onYearChange}
                                                        value={equipment.year ?? ""}
                                                        errorText={errors["year"] ? errors["year"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Serial Number"
                                                        fullWidth={true}
                                                        name="serial_Number"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.serial_Number ?? ""}
                                                        errorText={errors["serial_Number"] ? errors["serial_Number"].message : undefined}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Serial History"
                                                        onClick={serialHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Second Serial Number"
                                                        fullWidth={true}
                                                        name="second_Serial_Number"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.second_Serial_Number ?? ""}
                                                        errorText={errors["second_Serial_Number"] ? errors["second_Serial_Number"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Equipment Code entered in Explorer"
                                                        fullWidth={true}
                                                        name="equipmentCode"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.equipmentCode ?? ""}
                                                        errorText={errors["equipmentCode"] ? errors["equipmentCode"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                {(equipment.equipment_Type === "Backhoes" || equipment.equipment_Type === "Loaders" || equipment.equipment_Type === "Excavators")  && 
                                                    <LAGridItem xs={4}>
                                                        <LATextField
                                                            variant="outlined"
                                                            label="Quick Coupler Size"
                                                            fullWidth={true}
                                                            name="quick_Coupler_Size"
                                                            disabled={readOnly}
                                                            onChange={this.onChange}
                                                            value={equipment.quick_Coupler_Size ?? ""}
                                                        />
                                                    </LAGridItem>
                                                }

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        freeText={true}
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        option={equipmentStatusDropdown}
                                                        name="status"
                                                        filterSelectedOptions={true}
                                                        getOptionLabel="name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={onStatusChange}
                                                        dropDownPlaceHolder="Status"
                                                        value={equipment.status ? equipmentStatusDropdown.find(x => x.name === equipment.status) : ""}
                                                        defaultValue={equipment.status ? equipmentStatusDropdown.find(x => x.name === equipment.status) : ""}
                                                        errorText={errors["status"] ? errors["status"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Current Hours"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="current_Hours"
                                                        onChange={this.onChange}
                                                        value={equipment.current_Hours ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Purchase Date</strong>
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.purchase_Date ?? ""} onChange={(date) => onDate("purchase_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Purchased From"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="purchased_From"
                                                        onChange={this.onChange}
                                                        value={equipment.purchased_From ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Purchase Meter"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="purchase_Meter"
                                                        onChange={this.onChange}
                                                        value={equipment.purchase_Meter ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Plate"
                                                        fullWidth={true}
                                                        name="plate"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.plate ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Plate History"
                                                        onClick={plateHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Details / Attachments"
                                                        fullWidth={true}
                                                        name="details"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.details ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Details History"
                                                        onClick={detailsHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Comments"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="comments"
                                                        onChange={this.onChange}
                                                        value={equipment.comments ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Equipment Details"
                                                        fullWidth={true}
                                                        name="equipment_Details"
                                                        disabled={readOnly}
                                                        onChange={this.onChange}
                                                        value={equipment.equipment_Details ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={4}>
                                                    <LATextField
                                                        label="Width"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="width"
                                                        value={equipment.width ?? ""}
                                                        type="number"
                                                        disabled={readOnly}
                                                        onChange={this.handleNumber}
                                                        errorText={errors["width"] ? errors["width"].message : undefined}
                                                    />

                                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup-width" value={widthView} onChange={this.onWidthView}>
                                                        <FormControlLabel value="Meters" control={<Radio />} label="meter" />                               
                                                        <FormControlLabel value="Foot" control={<Radio />} label="foot" /> 
                                                        <FormControlLabel value="Inches" control={<Radio />} label="inch" />
                                                        {/* <FormControlLabel value="CM" control={<Radio />} label="cm" /> */}
                                                    </RadioGroup>
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={6} md={4}>
                                                    <LATextField
                                                        label="Length"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="length"
                                                        value={equipment.length ?? ""}
                                                        type="number"
                                                        disabled={readOnly}
                                                        onChange={this.handleNumber}
                                                        errorText={errors["length"] ? errors["length"].message : undefined}
                                                    />

                                                    <RadioGroup className="view-btn" row aria-label="" name="radioGroup-length" value={lengthView} onChange={this.onLengthView}>                                
                                                        <FormControlLabel value="Meters" control={<Radio />} label="meter" />                               
                                                        <FormControlLabel value="Foot" control={<Radio />} label="foot" />                               
                                                        <FormControlLabel value="Inches" control={<Radio />} label="inch" />
                                                        {/* <FormControlLabel value="CM" control={<Radio />} label="cm" />                       */}
                                                    </RadioGroup>
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        disabled={readOnly}
                                                        multiple={false}
                                                        option={ETPilotRequiredOptions}
                                                        getOptionLabel="name"
                                                        autoHighlight={true}
                                                        onChange={onETPilotRequiredChange}
                                                        filterSelectedOptions={true}
                                                        dropDownPlaceHolder="ET Pilot Required"
                                                        selectionRemove={undefinedFunction}
                                                        value={equipment.eT_Move_Pilot_Required ? ETPilotRequiredOptions.find(q => q.name === equipment.eT_Move_Pilot_Required) : null}
                                                        defaultValue={equipment.eT_Move_Pilot_Required ? ETPilotRequiredOptions.find(q => q.name === equipment.eT_Move_Pilot_Required) : null}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Job #"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="job_No"
                                                        onChange={this.onChange}
                                                        value={equipment.job_No ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Site"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="location"
                                                        onChange={this.onChange}
                                                        value={equipment.location ?? ""}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Subsite"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="sub_Location"
                                                        onChange={this.onChange}
                                                        value={equipment.sub_Location ?? ""}
                                                    />
                                                </LAGridItem>
                                                {equipment.working_Site_ID === 1 && equipment.working_Site_Sub_ID === 1 && <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Update Location"
                                                        onChange={onUpdateLocationCheckboxChange}
                                                        disabled={readOnly}
                                                        value={equipment.update_Location === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>}

                                                {equipment.update_Location === "Yes" && <LAGridItem xs={12} sm={6} md={4}>
                                                    <DevExtremePopupLookup
                                                        data={wssList}
                                                        id="wss-search"
                                                        placeHolder="New Site"
                                                        disabled={false}
                                                        displayExp="site"
                                                        name="wsS_ID"
                                                        errorName="wsS_ID"
                                                        errors={errors}
                                                        currentVal={equipment.New_WSS_ID}
                                                        onClick={onWSS}
                                                        columns={[
                                                            { name: "site", caption: "Site", type: "string" },
                                                            { name: "job", caption: "Job", type: "string" },
                                                            { name: "site_Address", caption: "Site Address", type: "string" },
                                                            { name: "equip_On_Site", caption: "Equip on Site", type: "number" }
                                                        ]}
                                                    />
                                                </LAGridItem>}

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Move Status"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="move_Status"
                                                        onChange={this.onChange}
                                                        value={equipment.move_Status ?? ""}
                                                    />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Repair Status"
                                                        fullWidth={true}
                                                        disabled={true}
                                                        name="repair_Status"
                                                        onChange={this.onChange}
                                                        value={equipment.repair_Status ?? ""}
                                                    />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LAAutoComplete
                                                        freeText={true}
                                                        multiple={false}
                                                        autoHighlight={true}
                                                        option={equipmentReadingsDropdown}
                                                        name="reading_Type"
                                                        filterSelectedOptions={true}
                                                        getOptionLabel="name"
                                                        disabled={readOnly}
                                                        selectionRemove={undefinedFunction}
                                                        onChange={onReadingChange}
                                                        dropDownPlaceHolder="Reading Type"
                                                        value={equipment.reading_Type ? equipmentReadingsDropdown.find(x => x.name === equipment.reading_Type) : ""}
                                                        defaultValue={equipment.reading_Type ? equipmentReadingsDropdown.find(x => x.name === equipment.reading_Type) : ""}
                                                        errorText={errors["reading_Type"] ? errors["reading_Type"].message : undefined}
                                                    />
                                                </LAGridItem>
                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Add Unit to SOCT"
                                                        onChange={onSOCTChange}
                                                        disabled={readOnly}
                                                        value={equipment.add_To_SOCT === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>
                                                {equipment.add_To_SOCT === "Yes" && <LAGridItem xs={4}>
                                                    <LAButton
                                                        label="View in SOCT"
                                                        onClick={this.handleSOCT}
                                                    />
                                                </LAGridItem>


                                                }

                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Rental"
                                                        onChange={onRentalChange}
                                                        disabled={readOnly}
                                                        value={equipment.rental === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Parts Dispatch Truck"
                                                        onChange={onPartsTruckChange}
                                                        disabled={readOnly}
                                                        value={equipment.parts_Truck === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>
                                                
                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Requires Two ET Moves"
                                                        onChange={onETSecondTripChange}
                                                        disabled={readOnly}
                                                        value={equipment.eT_Second_Trip === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LACheckBox
                                                        label="Mob/Demob"
                                                        onChange={onMobDemobChange}
                                                        disabled={readOnly}
                                                        value={equipment.mob_Demob === "Yes" ? true : false}
                                                    />
                                                </LAGridItem>

                                            </LAGrid>

                                        </LAGreenPaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAYellowPaper>
                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>DRIVER INFORMATION</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver"
                                                        onChange={this.onDriverChange}
                                                        value={equipment.driver ?? ""}
                                                    />
                                                    {equipment.id > 0 && <LALinkButton
                                                        label="Driver History"
                                                        onClick={driverHistory}
                                                    />}
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Driver Start Date</strong>
                                                    {<span className="required-text">{errors["driver_Start_Date"] ? errors["driver_Start_Date"].message : ""}</span>}
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.driver_Start_Date ?? ""} onChange={(date) => onDate("driver_Start_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4} className="text-center">
                                                    <strong>Driver End Date</strong>
                                                    <br />
                                                    <LADatePicker disable={readOnly} value={equipment.driver_End_Date ?? ""} onChange={(date) => onDate("driver_End_Date", date)} />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver Owned By"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver_Owned_By"
                                                        onChange={this.onChange}
                                                        value={equipment.driver_Owned_By ?? ""}
                                                        errorText={errors["driver_Owned_By"] ? errors["driver_Owned_By"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={4}>
                                                    <LATextField
                                                        variant="outlined"
                                                        label="Driver Comments"
                                                        fullWidth={true}
                                                        disabled={readOnly}
                                                        name="driver_Comments"
                                                        onChange={this.onChange}
                                                        value={equipment.driver_Comments ?? ""}
                                                        errorText={errors["driver_Comments"] ? errors["driver_Comments"].message : undefined}
                                                    />
                                                </LAGridItem>

                                            </LAGrid>
                                        </LAYellowPaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12}>
                                        <LAGreenPaper>
                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>SERVICE</h3>
                                                    <hr />
                                                </LAGridItem>

                                                {serviceHoursList.map((q, index) => {
                                                    const onCheckBox = (): void => this.handleServiceHours(q);
                                                    return (
                                                        <LAGridItem xs={4} key={index}>
                                                            <LACheckBox
                                                                label={q}
                                                                onChange={onCheckBox}
                                                                disabled={CVIPReadOnly}
                                                                value={serviceHours.includes(q)}
                                                            />
                                                        </LAGridItem>
                                                    );
                                                })}

                                            </LAGrid>
                                        </LAGreenPaper>
                                    </LAGridItem>

                                    {equipColumns.length > 0 && <LAGridItem xs={12}>
                                        <LARedPaper>

                                            <LAGrid spacing={1}>

                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>OTHER INFORMATION</h3>
                                                    <hr />
                                                </LAGridItem>

                                                {DynamicColumns.sort(this.sortColumns).map((x, index) => {
                                                    const val: any = this.state.equipment;

                                                    if (equipColumns && equipColumns.includes(x.id.toString())) {
                                                        if (x.type === "date") {
                                                            return (
                                                                <LAGridItem xs={4} className="text-center">
                                                                    <strong>{x.label}</strong>
                                                                    <br />
                                                                    <LADatePicker disable={readOnly} value={val[x.name] ?? ""} onChange={(date) => onDate(x.name, date)} />
                                                                </LAGridItem>
                                                            );
                                                        } else if (x.type === "string") {
                                                            return (
                                                                <LAGridItem xs={4} key={index}>
                                                                    <LATextField
                                                                        variant="outlined"
                                                                        label={x.label}
                                                                        fullWidth={true}
                                                                        disabled={readOnly}
                                                                        name={x.name}
                                                                        onChange={this.onChange}
                                                                        value={val[x.name] ?? ""}
                                                                    />
                                                                </LAGridItem>
                                                            );
                                                        } else if (x.type === "dropdown" && x.dropdownData) {
                                                            return (
                                                                <LAGridItem xs={4} key={index}>
                                                                    <LAAutoComplete
                                                                        freeText={true}
                                                                        multiple={false}
                                                                        autoHighlight={true}
                                                                        option={x.dropdownData}
                                                                        name={x.name}
                                                                        filterSelectedOptions={true}
                                                                        getOptionLabel="name"
                                                                        disabled={readOnly}
                                                                        selectionRemove={undefinedFunction}
                                                                        onChange={this.handleDropDownValue}
                                                                        dropDownPlaceHolder={x.label}
                                                                        value={val[x.name] ? x.dropdownData.find(q => q.name === val[x.name]) : ""}
                                                                        defaultValue={val[x.name] ? x.dropdownData.find(x => x.name === val[x.name]) : ""}
                                                                    />
                                                                </LAGridItem>
                                                            )
                                                        } else if (x.type === "array") {
                                                            return (
                                                                <LAGridItem xs={12} key={index}>
                                                                    <LAPaperWithLessPadding>
                                                                        <LAGrid>
                                                                            {CertificateTypes.map((q) => {
                                                                                const findIndex = equipment.certificates.findIndex(a => a.type === q.name);
                                                                                const certificate = equipment.certificates[findIndex];
                                                                                const onCheckBox = (check: boolean): void => this.handleCertCheck("type", check, q.name);
                                                                                const dueDate = (date: string): void => this.handleCertificate("due_Date", date, q.name);
                                                                                const onCertificate = (name: string, value: string): void => this.handleCertificate("certificate_No", value, q.name);
                                                                                const fileUpload = (attachment: Array<File | DataTransferItem>) => this.certificateFileUpload(attachment, equipment.certificates.findIndex(a => a.type === q.name));
                                                                                // console.log(equipment)


                                                                                const attachment = (certificate && certificate.files && certificate.files[0])
                                                                                    ? certificate.files[0]
                                                                                    : (certificate && certificate.attachments && certificate.attachments.length > 0)
                                                                                        ? { name: certificate.attachments, base64String: "" } : { name: "", base64String: "" };

                                                                                const onDownload = (): void => this.handleCertificateDownload(attachment);

                                                                                return (
                                                                                    <LAGridItem xs={12} key={q.id}>
                                                                                        <LAGrid>

                                                                                            <LAGridItem xs={2}>
                                                                                                <LACheckBox
                                                                                                    label={q.name}
                                                                                                    disabled={CVIPReadOnly}
                                                                                                    onChange={onCheckBox}
                                                                                                    value={findIndex >= 0 ? true : false}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2}>
                                                                                                <strong>Due Date</strong>

                                                                                                {certError[q.name] && certError[q.name]["due_Date"] ?
                                                                                                    <span className="required-text ml-2">Required</span>
                                                                                                    : <></>}

                                                                                                <br />
                                                                                                <LADatePicker
                                                                                                    disable={CVIPReadOnly}
                                                                                                    onChange={dueDate}
                                                                                                    value={findIndex >= 0 ? equipment.certificates[findIndex].due_Date : ""}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2}>
                                                                                                <LATextField
                                                                                                    name={q.name}
                                                                                                    fullWidth={true}
                                                                                                    variant="outlined"
                                                                                                    disabled={CVIPReadOnly}
                                                                                                    label="Certificate #"
                                                                                                    onChange={onCertificate}
                                                                                                    value={findIndex >= 0 ? equipment.certificates[findIndex].certificate_No : ""}
                                                                                                    errorText={certError[q.name] && certError[q.name]["certificate_No"] ? certError[q.name]["certificate_No"].message : undefined}
                                                                                                />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2}>
                                                                                                <LADropZone onFileDrop={fileUpload} />
                                                                                            </LAGridItem>

                                                                                            <LAGridItem xs={2} className="text-center">
                                                                                                {(attachment.name.length > 0) && <>
                                                                                                    <strong>{attachment.name}</strong>
                                                                                                    <LAIconButton
                                                                                                        className="pl-5"
                                                                                                        label="Download"
                                                                                                        onClick={onDownload}
                                                                                                        icon={<DownloadIcon />}
                                                                                                    />
                                                                                                </>}

                                                                                            </LAGridItem>

                                                                                        </LAGrid>
                                                                                    </LAGridItem>
                                                                                );
                                                                            })}

                                                                            <LAGridItem xs={12}>
                                                                                <LALinkButton
                                                                                    label="Certificate History"
                                                                                    onClick={certificateHistoryClick}
                                                                                />

                                                                                <LALinkButton
                                                                                    className="ml-3"
                                                                                    label="Add History"
                                                                                    onClick={this.addCertificateHistory}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAPaperWithLessPadding>
                                                                </LAGridItem>
                                                            )
                                                        }
                                                    } else {
                                                        return <></>
                                                    }
                                                    return <></>
                                                })}
                                            </LAGrid>

                                        </LARedPaper>
                                    </LAGridItem>}

                                    {equipment.id > 0 && <LAGridItem xs={12} className="text-center">
                                        <LABluePaper>
                                            <LAGrid>
                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>ATTACHMENT LIST</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <TableContainer component={LAPaperWithLessPadding}>
                                                    
                                                    <Table size="small" aria-label="Attachment Items">
                                                        <TableHead className="header-row">
                                                        <TableRow>
                                                            <TableCell className="title">Attachment_No</TableCell>
                                                            <TableCell className="title">Type</TableCell>
                                                            <TableCell className="title">Coupler Design</TableCell>
                                                            <TableCell className="title">Style</TableCell>
                                                            <TableCell className="title">Detachable</TableCell>
                                                            <TableCell className="title"></TableCell>
                                                        </TableRow>
                                                        </TableHead>

                                                        {equipment?.unit_Attachment_List !== undefined && equipment.unit_Attachment_List.length > 0 ? 
                                                            equipment.unit_Attachment_List.map((x: IUnitAttachmentList, index: number) => {
                                                                const onViewAttachmentClick = () => {
                                                                    window.open(ROUTE.FIELD.ATTACHMENT_LIST.ATTACHMENT + "?id=" + x.id.toString());
                                                                }
                                                                return <TableBody key={index}>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <LATextField
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                indexPosition={index}
                                                                                name="attachment_No"
                                                                                label="Attachment No"
                                                                                disabled={true}
                                                                                value={x.attachment_No ?? ""}
                                                                                onChange={undefinedFunction}
                                                                                type="text"
                                                                            />
                                                                            
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <LATextField
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                indexPosition={index}
                                                                                name="type"
                                                                                label="Type"
                                                                                disabled={true}
                                                                                value={x.type ?? ""}
                                                                                onChange={undefinedFunction}
                                                                                type="text"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <LATextField
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                indexPosition={index}
                                                                                name="coupler_Design"
                                                                                label="Coupler Design"
                                                                                disabled={true}
                                                                                value={x.coupler_Design ?? ""}
                                                                                onChange={undefinedFunction}
                                                                                type="text"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <LATextField
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                indexPosition={index}
                                                                                name="style"
                                                                                label="Style"
                                                                                disabled={true}
                                                                                value={x.style ?? ""}
                                                                                onChange={undefinedFunction}
                                                                                type="text"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <LATextField
                                                                                fullWidth={true}
                                                                                variant="outlined"
                                                                                indexPosition={index}
                                                                                name="detachable"
                                                                                label="Detachable"
                                                                                disabled={true}
                                                                                value={x.detachable ?? ""}
                                                                                onChange={undefinedFunction}
                                                                                type="text"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <LALinkButton label="View More Details" onClick={onViewAttachmentClick} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            }) 
                                                        : null}
                                                    </Table>
                                                </TableContainer>
                                            </LAGrid>
                                        </LABluePaper>
                                    </LAGridItem>}

                                    {(getRole === ReadAndWrite) && <LAGridItem xs={12} className="text-center">
                                        <LABluePaper>
                                            <LAGrid>
                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>ADMIN DOCUMENTS</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LADropZone onFileDrop={this.handleAttachment} />
                                                </LAGridItem>

                                                {attachments.length > 0 && attachments.map((x, index) => {
                                                    const onDownload = (): void => this.handleDownload(x, true);
                                                    const onRemove = (): void => this.handleFileRemove(index);
                                                    const onChange = (name: string, value: string): void => this.handleAdminImgName(value + "." + x.name.substring(x.name.lastIndexOf(".") + 1), index);
                                                    return <LAGridItem key={index} xs={4}>
                                                        {x.name && x.name.length > 0
                                                            && <>
                                                                <LATextField
                                                                    name="name"
                                                                    label="Description"
                                                                    onChange={onChange}
                                                                    value={x.name.substring(0, x.name.lastIndexOf("."))}
                                                                />

                                                                <LAIconButton
                                                                    className="pl-5"
                                                                    label="Download"
                                                                    onClick={onDownload}
                                                                    icon={<DownloadIcon />}
                                                                />
                                                            </>}

                                                        {x.name && <LAIconButton
                                                            label="Remove"
                                                            onClick={onRemove}
                                                            icon={<DeleteIcon />}
                                                        />}
                                                    </LAGridItem>
                                                })}
                                            </LAGrid>
                                        </LABluePaper>
                                    </LAGridItem>}

                                    <LAGridItem xs={12} className="text-center">
                                        <LABluePaper>

                                            <LAGrid>
                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>FIELD DOCUMENTS</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    <LADropZone onFileDrop={this.handleDocAttachment} />
                                                </LAGridItem>

                                                {fieldAttachments.length > 0 && fieldAttachments.map((x, index) => {
                                                    const onDownload = (): void => this.handleDownload(x, false);
                                                    const onRemove = (): void => this.handleDocFileRemove(index);
                                                    const onChange = (name: string, value: string): void => this.handleDocImgName(value + "." + x.name.substring(x.name.lastIndexOf(".") + 1), index);

                                                    return <LAGridItem key={index} xs={4}>
                                                        {x.name && x.name.length > 0
                                                            && <>
                                                                <LATextField
                                                                    name="name"
                                                                    label="Description"
                                                                    onChange={onChange}
                                                                    value={x.name.substring(0, x.name.lastIndexOf("."))}
                                                                />

                                                                <LAIconButton
                                                                    className="pl-5"
                                                                    label="Download"
                                                                    onClick={onDownload}
                                                                    icon={<DownloadIcon />}
                                                                />
                                                            </>}

                                                        {x.name && <LAIconButton
                                                            label="Remove"
                                                            onClick={onRemove}
                                                            icon={<DeleteIcon />}
                                                        />}
                                                    </LAGridItem>
                                                })}
                                            </LAGrid>
                                        </LABluePaper>
                                    </LAGridItem>

                                    <LAGridItem xs={12} className="text-center">
                                        <LABluePaper>
                                            <LAGrid>
                                                <LAGridItem xs={12} className="text-center">
                                                    <h3>IMAGES</h3>
                                                    <hr />
                                                </LAGridItem>

                                                <LAGridItem xs={12}>
                                                    {/* <LAIconUploadButton
                                                        imagesOnly={true}
                                                        label="Upload Image"
                                                        icon={<UploadIcon />}
                                                        id='image-upload'
                                                        onClick={(e) => this.handlePictureUpload(e)}
                                                    /> */}
                                                    <LAGridItem xs={12}>
                                                        <LADropZone onFileDrop={this.handlePictureUpload} />
                                                    </LAGridItem>
                                                </LAGridItem>
                                                <br />

                                                {pictures.length > 0 && pictures.map((x, index) => {
                                                    const onPictureDelete = (): void => this.HandlePictureRemove(index);
                                                    const onChange = (name: string, value: string): void => this.handlePictureDescription(name, value, index);
                                                    const base64 = x.base64String !== null ? x.base64String : webConfig.imgApiBaseUrl + x.name;
                                                    const onDefaultImageCheckboxChange = (check: boolean): void => this.HandleDefaultImageCheck(check, index);

                                                    return <LAGridItem key={index} xs={12} md={4}>
                                                        <LAThumbnailWithLink
                                                            className="thumbnail-img"
                                                            alt={x.name}
                                                            key={index}
                                                            url={base64}
                                                        />

                                                        <LATextField
                                                            className="mt-2"
                                                            label="Description"
                                                            variant="outlined"
                                                            name="description"
                                                            value={x.description}
                                                            onChange={onChange}
                                                            errorText={
                                                                x.description.length === 0 ?
                                                                    FIELD_VALIDATOR_ERRORS.REQUIRED
                                                                    : undefined
                                                            }
                                                        />

                                                        <LACheckBox
                                                            label="Default Image"
                                                            onChange={onDefaultImageCheckboxChange}
                                                            disabled={undefined}
                                                            value={x.default === "Yes" ? true : false}
                                                        />

                                                        {x.name && <LAIconButton
                                                            label="Remove"
                                                            onClick={onPictureDelete}
                                                            icon={<DeleteIcon />}
                                                        />}
                                                    </LAGridItem>
                                                })}
                                            </LAGrid>
                                        </LABluePaper>
                                    </LAGridItem>

                                    {equipment && equipment.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            variant="outlined"
                                            label="Created"
                                            disabled={true}
                                            value={equipment.created ? new Date(equipment.created).toLocaleString() : ""}
                                            onChange={this.onChange}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>}

                                    {equipment && equipment.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            variant="outlined"
                                            label="Created By"
                                            disabled={true}
                                            value={equipment.created_By ?? ""}
                                            onChange={this.onChange}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    }

                                    {equipment && equipment.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            variant="outlined"
                                            label="Last Modified"
                                            disabled={true}
                                            value={equipment.modified ? new Date(equipment.modified).toLocaleString() : ""}
                                            onChange={this.onChange}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>
                                    }

                                    {equipment && equipment.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            variant="outlined"
                                            disabled={true}
                                            value={equipment.modified_By}
                                            onChange={this.onChange}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    }

                                    {((Object.values(errors).length > 0) || (Object.values(certError).length > 0)) && driverChanged && <LAGridItem xs={12}>
                                        <LAErrorBox text="Fix the error's first to save the form successfully..." />
                                    </LAGridItem>}

                                    {serverError.length > ZEROTH && <LAGridItem xs={12}>
                                        <LAErrorBox text={serverError} />
                                    </LAGridItem>}
                                    <LAGridItem xs={12}>
                                        <LASaveAndCancelButton
                                            onSave={this.onSave}
                                            onCancel={this.handleCancel}
                                            disableSave={((Object.values(errors).length > 0) || (Object.values(certError).length > 0) || readOnly) ? true : undefined}
                                        />
                                    </LAGridItem>

                                </LAGrid>
                            </LAPaperWithPadding>

                            <RequestStatus requestStatus={addShopEquipment.kind} successMessage="Equipment successfully saved" />
                            <RequestStatus requestStatus={updateShopEquipment.kind} successMessage="Equipment successfully updated" />

                        </ShopEquipmentStyles>

                        <LADeleteConfirm
                            cancelClick={this.cancelBack}
                            confirmClick={this.confirmBack}
                            open={(back !== undefined) ? true : false}
                            text="All unsaved changes will be lost? Are you sure to leave?"
                        />
                    {/* </>} */}
                </RoleBasedAccessForShop>

                <VersionHistoryPopup
                    data={versionHistory}
                    open={versionHistoryPopup}
                    status={getEquipmentVersionHistory.kind}
                    onCancel={this.handleVersionHistoryClose}
                />

                <AttachmentHistoryPopup
                    data={attachmentVersionHistory}
                    open={attachmentVersionHistoryPopup}
                    status={getAttachmentHistory.kind}
                    onCancel={this.handleAttachmentVersionHistoryClose}
                />

                <HistoryPopup
                    column={column}
                    open={historyOpen}
                    data={historyData}
                    onCancel={this.historyPopupClose}
                    status={getEquipmentHistoryColumns.kind}
                />

                {unitDetailsPopup && <LAPopover
                    open={unitDetailsPopup}
                    onClose={this.handleUnitDetails}
                    transformOrigin={{ horizontal: "left", vertical: "center" }}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorRef={null}>
                    <UnitDetails
                        {...this.props}
                        id={equipment.id}
                    />
                </LAPopover>}

                {certificateHistory && <CertificateHistoryPopup
                    token={getToken}
                    id={equipment.id}
                    open={certificateHistory}
                    onCancel={this.historyPopupClose}
                    downloadFile={this.certificateDownloadFromEndpoint}
                />}

                {certificate && <AddCertificateHistoryPopup
                    open={certificate}
                    onSave={this.saveCertificate}
                    onCancel={this.closeCertificate}
                    downloadFile={this.certificateDownloadFromEndpoint}
                />}
            </PageSpacing>
        );
    }

    private handleUnitDetails = (): void => {
        this.setState({
            unitDetailsPopup: !this.state.unitDetailsPopup,
        });
    };

    private onCustomIconClick = (name:string, data: IShopEquipment): void => {
        // console.log(data)
        if(name === "version_history"){
            if (hasPayload(this.props.getToken) && data.id) {

                this.props.getEquipmentVersionHistoryRequest({
                    id: data.id,
                    token: this.props.getToken.payload.response.token
                });
    
                this.setState({ versionHistoryPopup: true });
            }
        } 
        if(name === "unit_history"){
            if (data.id !== undefined) {
                window.open(ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY + "?id=" + data.id.toString(), "_blank")
            }
        } 
        if(name === "attachment_History") {
            if (hasPayload(this.props.getToken) && data.id) {

                this.props.getAttachmentVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request : {
                        ID: 0,
                        Unit_ID: data.id
                    }
                });

                this.setState({ attachmentVersionHistoryPopup: true });
            }
        }
        if(name === "equipment_Manuals"){
            // console.log(data)
            if (data.id !== undefined) {
                callRouteWithQueryString({
                    route: this.props,
                    search: { keyword: data.id.toString() },
                    pathName: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_MANUAL.INDEX
                }); 
            }
        }
        if(name === "tsi"){
            // console.log(data)
            if (data.id !== undefined) {
                callRouteWithQueryString({
                    route: this.props,
                    search: { keyword: data.id.toString()},
                    pathName: ROUTE.FIELD.TECH_SERVICE.TECH_SERVICES
                });
            }
        }
        if(name === "unit_Details"){
            // console.log(data)
            if (data.id !== undefined) {
                this.setState({ unitDetailsPopup: true });
            }
        }

    }

    private certificateFileUpload = (attachments: Array<File | DataTransferItem>, idx: number): void => {

        const iS = { ...this.state.equipment };

        const certificates = [...iS.certificates];
        const file = certificates[idx];
        // console.log(certificates, idx, attachments, file)

        new Promise((resolve, reject): any => {

            if (attachments && (attachments !== null)) {

                Object.values(attachments).forEach((x: any, index) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            file.files = [{
                                name: x.name,
                                base64String: reader.result.toString()
                            }];

                            if (attachments.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
                // console.log(file)
            } else {
                reject();
            }
        }).then(() => {
            certificates[idx] = file;

            this.setState({
                ...this.state,
                equipment: {
                    ...iS,
                    certificates
                }
            });
        });
    };

    private saveCertificate = (data: IAddEquipmentCertificate): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.addCertificateRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    ...data,
                    Created_By: userName,
                    Equipment_List_ID: this.state.equipment.id,
                }
            });

            this.closeCertificate();
        };
    };

    private closeCertificate = (): void => {
        this.setState({
            certificate: false
        });
    };

    private addCertificateHistory = (): void => {
        this.setState({
            certificate: true
        });
    };

    private handleVersion = (): void => {
        if (hasPayload(this.props.getToken)) {

            this.props.getEquipmentVersionHistoryRequest({
                id: this.state.equipment.id,
                token: this.props.getToken.payload.response.token
            });

            this.setState({ versionHistoryPopup: true });
        }
    };

    private handleVersionHistoryClose = (): void => {
        this.setState({ versionHistoryPopup: false });
    };

    private handleAttachmentVersionHistoryClose = (): void => {
        this.setState({ attachmentVersionHistoryPopup: false });
    };

    private handleAdminImgName = (value: string, index: number): void => {
        const attachments = [...this.state.attachments];
        attachments[index] = { ...attachments[index], name: value };
        this.setState({ attachments });
    };

    private handleDocImgName = (value: string, index: number): void => {
        const attachments = [...this.state.fieldAttachments];
        attachments[index] = { ...attachments[index], name: value };
        this.setState({ fieldAttachments: attachments });
    };

    private handlePictureDescription = (name: string, value: string, index: number): void => {
        const pictures = [...this.state.equipment.pictures]
        pictures[index] = { ...pictures[index], [name]: value };
        this.setState({
            ...this.state,
            equipment: {
                ...this.state.equipment,
                pictures
            }
        })
    };

    private handlePictureUpload = async (event: any): Promise<void> => {
        const files = event;
        const newPictures: IShopEquipmentPicture[] = [...this.state.equipment.pictures];

        if (files !== null) {
            await Promise.all(Object.values(files).map(async (x: any) => {
                const imgId = uuidv4();
                const description = x.name;
                const base64String = await fileToBase64Image(x);

                newPictures.push({
                    name: imgId + "." + description.split(".")[1],
                    description: description.split(".")[0],
                    base64String,
                });

                this.setState({
                    ...this.state,
                    equipment: {
                        ...this.state.equipment,
                        pictures: newPictures
                    }
                });
            }));
        }
    };

    private HandlePictureRemove = (index: number): void => {
        const iS = [...this.state.equipment.pictures];
        const selection = window.confirm(deleteText);

        if (selection) {
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                equipment: {
                    ...this.state.equipment,
                    pictures: iS
                }
            });
        }
    };

    private HandleDefaultImageCheck = (checked : boolean, index: number): void => {
        const pictures = [...this.state.equipment.pictures]
        pictures[index] = { ...pictures[index], default: checked ? "Yes" : "No" };

        if (checked) {
            pictures.forEach((picture, ind) => {
                if (ind !== index) {
                    picture.default = "No";
                }
            });
        }

        this.setState({
            ...this.state,
            equipment: {
                ...this.state.equipment,
                pictures
            }
        })
    };

    private handleCertCheck = (name: string, check: boolean, type: string): void => {
        const iS = { ...this.state.equipment };
        const errors = this.state.certError;
        const index = iS.certificates.findIndex(x => x.type === type);
        // console.log(type)

        if (index >= 0) {
            iS.certificates.splice(index, 1);
            delete errors[type];
        } else {
            iS.certificates.push({
                id: 0,
                type: type,
                certificate_No: "",
                due_Date: "",
                equipment_List_ID: iS.id.toString(),
                attachments: "",
                files: []
            });

            errors[type] = {};
            errors[type]["certificate_No"] = { key: "certificate_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            if(type !== "NA" && type !== "Registration")
                errors[type]["due_Date"] = { key: "due_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
        };

        this.setState({ equipment: iS, certError: errors });
    };

    private handleCertificate = (name: string, value: string, type: string): void => {
        const iS = { ...this.state.equipment };
        const errors = this.state.certError;
        const index = iS.certificates.findIndex(x => x.type === type);

        if (index >= 0) {
            const arrays = [...iS.certificates];
            let record = { ...arrays[index] };

            record = { ...record, [name]: name === "due_Date" ? new Date(value).toLocaleDateString('us') : value };
            arrays[index] = record;
            iS.certificates = arrays;

            if (errors[type]) {
                if (errors[type][name])
                    delete errors[type][name];
            };

            if (errors[type] && (Object.values(errors[type]).length === 0))
                delete errors[type];

        } else {
            iS.certificates.push({
                type: type,
                certificate_No: name === "certificate_No" ? value : "",
                due_Date: name === "due_Date" ? new Date(value).toLocaleDateString('us') : "",
                equipment_List_ID: iS.id.toString(),
                attachments: "",
                files: []
            });

            errors[type] = {};
            if (name === "certificate_No") {
                errors[type]["due_Date"] = { key: "due_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            } else if (name === "due_Date") {
                errors[type]["certificate_No"] = { key: "certificate_No", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
            }
        };

        this.setState({ equipment: iS, certError: errors });
    };

    private handleServiceHours = (value: string): void => {
        let iS = { ...this.state.equipment };
        let serviceHours = iS.service_Hours ? iS.service_Hours.split(",") : [];
        const index = serviceHours.findIndex(x => x === value);

        if (index >= 0) {
            serviceHours.splice(index, 1);
        } else {
            serviceHours.push(value);
        };

        iS.service_Hours = serviceHours.toString();

        this.setState({ equipment: iS });
    };
    private handleSOCTCheck = (value: boolean): void => {
        let iS = { ...this.state.equipment };
        let soctEnabled = iS.add_To_SOCT === "Yes" ? "Yes" : "No";
        if (value === true) {
            soctEnabled = "Yes";
        }
        else {
            soctEnabled = "No";
        }
        iS.add_To_SOCT = soctEnabled;

        this.setState({ equipment: iS });
    };
    
    private handlePartsTruckCheck = (value: boolean): void => {
        let iS = { ...this.state.equipment };
        let partsTruck = iS.parts_Truck === "Yes" ? "Yes" : "No";
        if (value === true) {
            partsTruck = "Yes";
        }
        else {
            partsTruck = "No";
        }
        iS.parts_Truck = partsTruck;

        this.setState({ equipment: iS });
    };

    private handleUpdateLocationCheck = (value: boolean): void => {
        let iS = { ...this.state.equipment };
        let updateLocation = iS.update_Location === "Yes" ? "Yes" : "No";
        if (value === true) {
            updateLocation = "Yes";
        }
        else {
            updateLocation = "No";
        }
        iS.update_Location = updateLocation;

        this.setState({ equipment: iS });
    };

    private handleNewSiteChange = (value: string): void => {
        if (value) {
            let iS = { ...this.state.equipment };
            const dropDown = hasPayload(this.props.getDropDownData) ? this.props.getDropDownData.payload.response : { manufacturers: [], models: [], equipment_Types: [], owners: [], wss_List: [] };
            const wssList = hasPayload(this.props.getDropDownData) ? dropDown.wss_List : [];
            const newSiteDetails = wssList.find((wss: any) => wss.site === value);
            iS.New_WSS_ID = newSiteDetails?.wsS_ID;

            this.setState({ equipment: iS });
        }
    }

    private handleRentalCheck = (value: boolean): void => {
        let iS = { ...this.state.equipment };
        let rentalEnabled = iS.rental === "Yes" ? "Yes" : "No";
        if (value === true) {
            rentalEnabled = "Yes";
        }
        else {
            rentalEnabled = "No";
        }
        iS.rental = rentalEnabled;

        this.setState({ equipment: iS });
    };

    private handleFileRemove = (index: number): void => {
        const iS = [...this.state.attachments];
        const selection = window.confirm(deleteText);

        if (selection) {
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                attachments: iS
            });
        }
    };

    private handleDocFileRemove = (index: number): void => {
        const iS = [...this.state.fieldAttachments];
        const selection = window.confirm(deleteText);

        if (selection) {
            iS.splice(index, 1);
            this.setState({
                ...this.state,
                fieldAttachments: iS
            });
        }
    };

    private handleSOCT = (): void => {
        if (hasPayload(this.props.getToken) && this.state.equipment.id !== 0) {
            const soctId = this.state.equipment.socT_ID;
            if(soctId)
                window.open(ROUTE.FIELD.SOCT.ADD_UPDATE_SOCT + `?id=${soctId.toString()}`, '_blank');
        }
    };

    private handleDownload = (file: IShopEquipmentFile, admin: boolean): void => {
        if (file.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            this.downloadFromEndpoint(file.name, admin);
        }
    };

    private downloadFromEndpoint = async (fileName: string, admin: boolean): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.getToken)) {

                await fetch(END_POINTS.FIELD.GET_EQUIPMENT_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "File": fileName,
                            "Field": admin ? undefined : true,
                            "Equipment_ID": this.state.equipment.id
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };


    private handleCertificateDownload = (file: IShopEquipmentFile): void => {
        if (file.base64String.length > 0) {
            let a = document.createElement("a");
            a.href = file.base64String;
            a.download = file.name;
            a.click();
        } else {
            this.certificateDownloadFromEndpoint(file.name);
        }
    };

    private certificateDownloadFromEndpoint = async (fileName: string): Promise<void> => {
        if (fileName !== null) {
            if (hasPayload(this.props.getToken)) {

                await fetch(END_POINTS.FIELD.GET_EQUIPMENT_FILE, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "File": fileName,
                            "Field": false,
                            "Equipment_ID": this.state.equipment.id,
                            "Certificate": true
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                    .then((res: any) => {
                        return res.blob();
                    })
                    .then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    })
                    .catch((res) => console.log(res));
            };
        };
    };

    private handleAttachment = (files: Array<File | DataTransferItem>): void => {
        const newFiles: IShopEquipmentFile[] = [...this.state.attachments];

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const check = newFiles.findIndex(z => z.name === x.name);
                            if (check === -1) {
                                newFiles.push({
                                    name: x.name,
                                    base64String: reader.result.toString()
                                });
                            } else {
                                newFiles[check].base64String = reader.result.toString()
                            };

                            if (files.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                attachments: newFiles
            });
        })
    };

    private handleDocAttachment = (files: Array<File | DataTransferItem>): void => {
        const newFiles: IShopEquipmentFile[] = [...this.state.fieldAttachments];

        new Promise((resolve, reject): any => {
            if (files && (files !== null)) {

                Object.values(files).forEach((x: any, index, array) => {
                    const reader = new FileReader();

                    reader.onloadend = async (e): Promise<void> => {
                        if (reader.result !== null) {
                            const check = newFiles.findIndex(z => z.name === x.name);
                            if (check === -1) {
                                newFiles.push({
                                    name: x.name,
                                    base64String: reader.result.toString()
                                });
                            } else {
                                newFiles[check].base64String = reader.result.toString()
                            };

                            if (files.length === index + 1) {
                                resolve(true);
                            };
                        };
                    };
                    reader.readAsDataURL(x);
                })
            } else {
                reject();
            }
        }).then(() => {
            this.setState({
                ...this.state,
                fieldAttachments: newFiles
            });
        })
    };

    private sortColumns = (a: any, b: any): any => {
        return a.id - b.id;
    };

    private cancelBack = (): void => {
        this.setState({ back: undefined });
    };

    private confirmBack = (): void => {
        this.props.history.push({
            pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
            search: getTokenFromUrl(false)
        });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>, isRequired: boolean): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: isRequired ? true : undefined, minLength: 1, decimal: undefined });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        };
        return err;
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });
        if (this.state.backStatus === undefined) {

            this.props.history.push({
                pathname: ROUTE.FIELD.EQUIPMENT.INDEX,
                search: getTokenFromUrl(false)
            });
        } else {
            this.setState({ back: true });
        }
    };

    private onSave = (): void => {
        let request = { ...this.state.equipment };
        const { widthView , lengthView } = this.state;
        const widthValue = getConvertedValue(widthView, "Meters", request.width ?? 0)
        const lengthValue = getConvertedValue(lengthView, "Meters", request.length ?? 0)

        if (hasPayload(this.props.getToken))
            if (this.state.equipment.id === 0) {
                const date = new Date();
                request.created = date.toISOString();
                request.modified = date.toISOString();
                request.created_By = this.props.getToken.payload.response.upn;
                request.modified_By = this.props.getToken.payload.response.upn;
                request.files = this.state.attachments;
                request.field_files = this.state.fieldAttachments;
                request.width = widthValue ?? null;
                request.length = lengthValue ?? null;
                this.props.addEquipmentRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } else {

                if (hasPayload(this.props.getEquipmentByID)) {
                    // const equipment = this.props.getEquipments.payload.response[Number(request.id)];
                    const equipment = this.props.getEquipmentByID.payload.response;

                    if ((equipment.driver !== request.driver) && (this.state.driverChanged === false)) {
                        let errors: ById<IFieldErrorKeyValue> = {};

                        request.driver_Comments = "";
                        request.driver_Start_Date = "";
                        request.driver_End_Date = "";
                        request.driver_Owned_By = "";

                        errors["driver_Start_Date"] = { key: "driver_Start_Date", message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        errors["driver_Owned_By"] = { key: "driver_Owned_By", message: FIELD_VALIDATOR_ERRORS.REQUIRED };

                        this.setState({ driverChanged: true, errors, equipment: request });
                    } else {
                        request.files = this.state.attachments;
                        request.field_files = this.state.fieldAttachments;

                        if (Object.values(this.state.errors).length === 0) {
                            if (request.created_By === undefined || request.created_By === "" || request.created_By === null)
                                request.created_By = this.props.getToken.payload.response.upn;

                            request.modified_By = this.props.getToken.payload.response.upn;
                            request.width = widthValue ?? 0;
                            request.length = lengthValue ?? 0;
                            // console.log(request)
                            this.props.updateEquipmentRequest({
                                token: this.props.getToken.payload.response.token,
                                request
                            });
                        }
                    }
                }
            };

        this.setState({ backStatus: undefined, serverError: "" });

    };

    private handleDropDownValue = (event: unknown, value: any, name?: string): void => {
        if (name)
            this.setState({
                ...this.state,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : ""
                }
            });
    };

    private handleDropDownChange = (event: unknown, value: any, name?: string): void => {
        let errors = this.state.errors;

        if (name && name === "make") {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    make: (value !== "" && value !== null) ? value.manufacturer_Name : ""
                }
            });
        }
        if (name && name === "reading_Type") {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : ""
                }
            });
        }
        if (name && name === "status") {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : ""
                }
            });
        }

        if (name && (name === "equipment_Type")) {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value.name : "", errors, true);
            // console.log(value.name)
            if(value && (value.name === "UN-Dismantled" || value.name === "UP-Dismantled" || value.name === "UP-Dismantled_Heavy_Trucks" || value.name === "UP-Dismantled_Pickup_Trucks")) {
                if(errors["status"]) {
                    delete errors["status"];
                }
            }
            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value.name : "",
                    status: (value !== "" && value !== null && 
                        (value.name === "UN-Dismantled" || value.name === "UP-Dismantled" || value.name === "UP-Dismantled_Heavy_Trucks" || value.name === "UP-Dismantled_Pickup_Trucks"))
                                ? equipmentStatusDropdown[5].name : this.state.equipment.status,
                    quick_Coupler_Size: "",
                }
            });
        }

        if (name && (name === "owner")) {
            errors = this.errorChecker(name, (value !== "" && value !== null) ? value : "", errors, true);

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: (value !== "" && value !== null) ? value : ""
                }
            });
        }
    };

    private onDriverChange = (name: string, value: string): void => {
        let errors = this.state.errors;

        if (this.state.driverChanged === true) {

            if (hasPayload(this.props.getEquipmentByID)) {
                const request = { ...this.state.equipment };

                // const equipment = this.props.getEquipments.payload.response[Number(request.id)];
                const equipment = this.props.getEquipmentByID.payload.response;
                if (request.driver === value) {
                    request.driver_Comments = equipment.driver_Comments;
                    request.driver_End_Date = equipment.driver_End_Date;
                    request.driver_Owned_By = equipment.driver_Owned_By;
                    request.driver_Start_Date = equipment.driver_Start_Date;

                    if (errors["driver_Start_Date"])
                        delete errors["driver_Start_Date"];

                    if (errors["driver_Owned_By"])
                        delete errors["driver_Owned_By"];
                } else {
                    if (name === "driver")
                        request.driver = value;
                }

                this.setState({
                    ...this.state,
                    errors,
                    equipment: request
                });
            }
        } else {
            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: value
                }
            });
        }
    };

    private onChange = (name: string, value: string): void => {
        // console.log(name,value)
        let errors = this.state.errors;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        if (errors["driver_Start_Date"] && name === "driver_Start_Date" && value.length > 0)
            delete errors["driver_Start_Date"];

        if (errors["driver_Owned_By"] && name === "driver_Owned_By" && value.length > 0)
            delete errors["driver_Owned_By"];

        if (errors["model"] && name === "model" && value.length > 0)
            delete errors["model"];

        this.setState({
            ...this.state,
            errors,
            backStatus: true,
            equipment: {
                ...this.state.equipment,
                [name]: value
            }
        });
    };

    private handleNumber = (name: string, value: string): void => {
        // console.log(name, value)
        let errors = { ...this.state.errors };
        const { widthView } = this.state;

        if (requiredFields.includes(name))
            errors = this.errorChecker(name, value, errors, true);

        if(name === "width") {
            const width = getConvertedValue(widthView, "Meters", Number(value))
            const eT_Move_Pilot_Required = width >= 3.81 && width < 4.45 ? "1 Pilot" : width >= 4.45 ? "2 Pilots" : "None"

            this.setState({
                ...this.state,
                equipment: {
                    ...this.state.equipment,
                    [name]: Number(value),
                    eT_Move_Pilot_Required : eT_Move_Pilot_Required
                },
            });
        }
        else {
            this.setState({
                ...this.state,
                equipment: {
                    ...this.state.equipment,
                    [name]: Number(value)
                },
                errors
            });
        }
    };

    private onWidthView = (e: any,value:string): void => {
        const {  equipment, widthView } = this.state;
        const width = getConvertedValue(widthView, value, equipment.width ?? 0)
        // console.log(width)
        this.setState({
            widthView: value,
            equipment: {
                ...this.state.equipment,
                width
            }
        });
    };

    private onLengthView = (e: any,value:string): void => {
        const { equipment, lengthView } = this.state;
        const length = getConvertedValue(lengthView, value, equipment.length ?? 0)
        this.setState({
            lengthView: value,
            equipment: {
                ...this.state.equipment,
                length
            }
        });
    };

    // private onNumberChange = (name: string, value: string): void => {
    //     if (!isNaN(+value)) {
    //         let errors = this.state.errors;

    //         if (requiredFields.includes(name))
    //             errors = this.errorChecker(name, value, errors, true);

    //         this.setState({
    //             ...this.state,
    //             errors,
    //             backStatus: true,
    //             equipment: {
    //                 ...this.state.equipment,
    //                 [name]: +value
    //             }
    //         });
    //     }
    // };

    private onYearChange = (name: string, value: string): void => {
        if (!isNaN(+value)) {
            let errors = this.state.errors;

            if (((+value > new Date().getFullYear()) || (+value < 1900)) && value !== "") {
                if (errors["year"] === undefined) {
                    errors["year"] = { key: "year", message: `Year must be between 1900 and ${new Date().getFullYear()}` };
                };
            } else {
                if (errors["year"])
                    delete errors["year"];
            }

            this.setState({
                ...this.state,
                errors,
                backStatus: true,
                equipment: {
                    ...this.state.equipment,
                    [name]: value !== "" ? +value : undefined
                }
            });
        }
    };

    private historyPopupClose = (): void => {
        this.setState({ historyOpen: false, column: "", certificateHistory: false });
    };

    private callColumnHistory = (columnName: string): void => {
        if (hasPayload(this.props.getToken) && this.state.equipment.id !== 0) {

            if (columnName !== "certificate") {
                this.props.getEquipmentHistoryColumnsRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        Column_Name: columnName,
                        Equipment_ID: this.state.equipment.id
                    }
                });

                this.setState({ historyOpen: true, column: columnName });
            } else {
                this.setState({ certificateHistory: true });
            }
        }
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getShopEquipmentColumnsStatus))
            this.props.getShopEquipmentColumnsRequest({
                token: this.props.getToken.payload.response.token
            });

        // if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipments) && shopRoleCheck([ReadOnly, ReadAndWrite]))
        //     this.props.getShopEquipmentsRequest({
        //         token: this.props.getToken.payload.response.token
        //     });

        
        if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getDropDownData) && shopRoleCheck([ReadOnly, ReadAndWrite]))
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });

        const query = queryString.parse(this.props.location.search);
        const id = query.id ?? "0";

        if (id !== "0"){
            if (hasPayload(this.props.getToken) && isNotLoaded(this.props.getEquipmentByID) && shopRoleCheck([ReadOnly, ReadAndWrite]))
                this.getEquipmentByID();
        }


        if ((id !== "0") && (hasPayload(this.props.getEquipmentByID)) && (this.state.equipment.id === 0)) {
            const equipment = this.props.getEquipmentByID.payload.response;
            let errors = this.state.errors;
            // console.log(equipment);
            if (equipment) {
                const existingAtt = equipment.attachments ? equipment.attachments.split(";") : [];
                equipment.pictures = equipment.pictures ? equipment.pictures : [];
                const existingFieldAtt = equipment.field_Attachments ? equipment.field_Attachments.split(";") : [];
                const attachments: IShopEquipmentFile[] = [];
                const fieldAttachments: IShopEquipmentFile[] = [];

                existingAtt.forEach(x => {
                    attachments.push({ name: x, base64String: "" });
                });

                existingFieldAtt.forEach(x => {
                    fieldAttachments.push({ name: x, base64String: "" });
                });

                errors = this.errorChecker("equipment", equipment.equipment, errors, true);
                errors = this.errorChecker("unit_Number", equipment.unit_Number ?? "", errors, true);
                errors = this.errorChecker("serial_Number", equipment.serial_Number ?? "", errors, true);
                errors = this.errorChecker("owner", equipment.owner, errors, true);
                errors = this.errorChecker("model", equipment.model, errors, true);
                errors = this.errorChecker("status", equipment.status ?? "", errors, true);
                errors = this.errorChecker("equipmentCode", equipment.equipmentCode ?? "", errors, true);

                if (equipment)
                    this.setState({ equipment, errors, attachments, fieldAttachments });
            }
        };

    };

    private getDropDownData = async(): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            this.props.getDropDownDataRequest({
                token: this.props.getToken.payload.response.token
            });
        }
    }

    private getEquipmentByID = async(): Promise<void> => {
        const query: any = queryString.parse(this.props.location.search);

        if (hasPayload(this.props.getToken) && Number(query.id) > 0) {
            await this.props.getEquipmentByIDRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: Number(query.id)
                }
            });
        }
    }

}

const mapStateToProps = (state: IStore): IShopEquipmentStoreProps => ({
    getToken: getToken(state),
    // getEquipments: getShopEquipments(state),
    getEquipmentByID: getEquipmentByID(state),
    addShopEquipment: addShopEquipment(state),
    updateShopEquipment: updateShopEquipment(state),
    getDropDownData: getLookups(state),
    validateShopGroup: validateShopGroup(state),
    addCertificate: addEquipmentCertificateStatus(state),
    getShopEquipmentColumnsStatus: getShopEquipmentColumns(state),
    getEquipmentVersionHistory: getEquipmentVersionHistory(state),
    getEquipmentHistoryColumns: getEquipmentHistoryColumns(state),
    getAttachmentHistory: getAttachmentVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IShopEquipmentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getShopEquipmentsRequest: (data: IShopEquipmentRequest) => dispatch(getShopEquipmentsLoadAction(data)),
    getEquipmentByIDRequest: (data: IGetEquipmentByIDRequest) => dispatch(getEquipmentByIDLoadAction(data)),
    addEquipmentRequest: (data: IAddShopEquipmentRequest) => dispatch(addShopEquipmentLoadAction(data)),
    updateEquipmentRequest: (data: IUpdateShopEquipmentRequest) => dispatch(updateShopEquipmentLoadAction(data)),
    getDropDownDataRequest: (data: IShopLookupsRequest) => dispatch(getLookupsLoadAction(data)),
    addCertificateRequest: (data: IAddEquipmentCertificateRequest) => dispatch(addEquipmentCertificateLoadAction(data)),
    getShopEquipmentColumnsRequest: (data: IGetShopEquipmentColumnRequest) => dispatch(getShopEquipmentColumnsLoadAction(data)),
    getEquipmentHistoryColumnsRequest: (data: IGetEquipmentHistoryColumnRequest) => dispatch(getEquipmentHistoryColumnsLoadAction(data)),
    getEquipmentVersionHistoryRequest: (data: IGetEquipmentVersionHistoryRequest) => dispatch(getEquipmentVersionHistoryLoadAction(data)),
    getAttachmentVersionHistoryRequest: (request: IGetAttachmentVersionHistoryRequest): unknown => dispatch(getAttachmentVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopEquipment);