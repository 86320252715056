import React from "react";
import { ById } from "../../shared/publicInterfaces";
import LAGridItem from "../../shared/gridList";
import LAGrid from "../../shared/grid";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DropPaper } from "../../shared/dragAndDropPaper";
import LAErrorBox from "../../shared/errorBox";
import { BLUE_COLOR, DARK_GREY_COLOR, GREEN_COLOR, RED_COLOR, WHITE_COLOR } from "../../shared/theme";
import { LAIconButton } from "../../shared/buttons";
import { CommunicationLogIcon, DeleteIcon, DispatchIcon, EditIcon } from "../../shared/icons";
import { undefinedFunction } from "../../shared/constExports";
import { IServiceTruck } from "../../../redux/field/dispatch/getServiceTrucks/getServiceTrucksConstants";
import { IAssignmentSite, IDriverAssignmentSelection, IDriverAssignmentViewSelection } from "./driversAssignment";
import { IServiceTruckList } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import LATextArea from "../../shared/textArea";
import { IDriversAssignment } from "../../../redux/field/dispatch/getDriversAssignment/getDriversAssignmentConstants";
import LATextField from "../../shared/textField";
import { LACheckBox } from "../../shared/checkBox";
import { MdWaterDrop, MdFormatColorReset } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { FormatColorReset } from '@mui/icons-material';

interface IDispatchDriverAssignment {
    idx: number;
    selected?: boolean;
    data: IAssignmentSite;
    onCheckBox?: (idx: number) => void;
    driverAssignmentSearch: string;
    assignmentList: IAssignmentSite[];
    serviceTrucks: ById<IServiceTruck>;
    onNotes: (value: string, key: number) => void;
    onChange: (date: string, key: number) => void;
    onDelete: (index: number, truckIndex: number) => void;
    driverAssignmentSelection: IDriverAssignmentSelection;
    // driverAssignmentViewSelection: IDriverAssignmentViewSelection;
    assignedView:boolean;
    unassignedView:boolean;
    onPriority: (value: string, index: number, truckIndex: number) => void;
};

export const getTodayAndTomorrow = (): { today: string, tomorrow: string, yesterday: string } => {
    const today = new Date();
    const tomorrow = new Date();
    const yesterday = new Date();
    tomorrow.setDate(today.getDate() + 1);
    yesterday.setDate(yesterday.getDate() - 1);

    return { today: today.toLocaleDateString('en-US'), tomorrow: tomorrow.toLocaleDateString('en-US'), yesterday: yesterday.toLocaleDateString('en-US') };
};

export const isYesterday = (view: IDriverAssignmentSelection, date: string): true | undefined => {
    return view === "yesterday" && new Date(date).toLocaleDateString('en-US') === getTodayAndTomorrow().yesterday ? true : undefined;
};

export const DispatchDriverAssignment = React.memo((props: IDispatchDriverAssignment): JSX.Element => {
    const yesterday = isYesterday(props.driverAssignmentSelection, props.data.date);
    const onNotes = (name: string, value: string) => props.onNotes(value, props.idx);
    
    return (
        (((yesterday)
            || ((props.driverAssignmentSelection === "today") &&
                (new Date(props.data.date).toLocaleDateString('en-US') === getTodayAndTomorrow().today))
            || ((props.driverAssignmentSelection === "tomorrow") &&
                (new Date(props.data.date).toLocaleDateString('en-US') === getTodayAndTomorrow().tomorrow))) &&
            searchSite(props.driverAssignmentSearch, props.data.serviceTruck)) &&
            (props.assignedView && !props.unassignedView ? props.data.assigned === true :
            !props.assignedView && props.unassignedView ? props.data.assigned === false :
            props.assignedView && props.unassignedView ? props.data.assigned === true || props.data.assigned === false : true)
            // (props.driverAssignmentViewSelection === "unassigned" ? props.data.assigned === false :
            // props.driverAssignmentViewSelection === "assigned" ? props.data.assigned === true :
            // props.driverAssignmentViewSelection === "all" ? props.data.assigned === true || props.data.assigned === false : true)
            ? <LAGridItem key={props.idx} xs={12} sm={6} md={3}>

                <Droppable key={props.idx} droppableId={props.idx.toString()} isDropDisabled={yesterday}>
                    {(provided, snapshot) => {
                    const onCheck = () => props.onCheckBox ? props.onCheckBox(props.idx) : undefinedFunction;
                        return (
                            <DropPaper
                                // className="cursor"
                                key={props.idx}
                                greyColor={yesterday}
                                innerRef={provided.innerRef}
                                backColor={props.driverAssignmentSelection === "today" && (props.data.site_Status === "Rained/Snowed out" || props.data.site_Status === "Weather Standby") ? `url(${process.env.PUBLIC_URL + '/img/giphy.webp'})` : undefined}
                                styleDraggar={snapshot.isDraggingOver}
                            >
                                {props.onCheckBox && <LACheckBox
                                    label="Clone"
                                    onChange={onCheck}
                                    value={props.selected ?? false}
                                />}

                                <DriverAssignmentInfo
                                    {...props}
                                    driverAssignment={props.data}
                                />

                                {(props.data && props.data.serviceTruck && props.data.serviceTruck.service_Trucks.length > 0)
                                    ?
                                    <>
                                        <LAGridItem xs={12} className="text-center mb-1">
                                            <LATextArea
                                                label="Notes"
                                                name="notes"
                                                minRows={1}
                                                rowsMax={9}
                                                size="small"
                                                onChange={onNotes}
                                                disabled={yesterday}
                                                value={props.data.serviceTruck.notes}
                                            />
                                        </LAGridItem>

                                        {props.data.serviceTruck.service_Trucks.map((e, ind) => {
                                            const onPriority = (name: string, value: string) => props.onPriority(value, props.idx, ind);
                                            const onDelete = () => props.data ? props.onDelete(props.idx, ind) : undefinedFunction;
                                            const st = props.data.serviceTruck ? props.serviceTrucks[e.service_Truck_ID] : undefined;

                                            return (
                                                <LAGrid spacing={0} className="text-center">
                                                    <LAGridItem xs={12}>
                                                        <ServiceTruckInfo
                                                            serviceTruck={st}
                                                            assignmentList={undefined}
                                                            selection={props.driverAssignmentSelection}
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={9}>
                                                        <LATextField
                                                            name="priority"
                                                            label="Priority"
                                                            disabled={yesterday}
                                                            onChange={onPriority}
                                                            value={e.priority ?? ""}
                                                        />
                                                    </LAGridItem>

                                                    <LAGridItem xs={3}>
                                                        <LAIconButton
                                                            label="Delete"
                                                            onClick={onDelete}
                                                            disabled={yesterday}
                                                            icon={<DeleteIcon color={yesterday ? DARK_GREY_COLOR : RED_COLOR} />}
                                                        />
                                                    </LAGridItem>

                                                    {(props.data.serviceTruck && ((props.data.serviceTruck.service_Trucks.length - 1) !== ind)) && <LAGridItem xs={12}>
                                                        <hr />
                                                    </LAGridItem>}

                                                </LAGrid>
                                            )
                                        })}
                                    </>
                                    : <LAErrorBox
                                        className="text-center"
                                        text="Service Truck Not Assigned"
                                    />}
                            </DropPaper>
                        )
                    }}
                </Droppable>

            </LAGridItem>
            : <></>
    )
});

const searchSite = (keyword: string, data: IDriversAssignment | undefined): boolean => {
    if (data && keyword.length > 0) {
        const fSite = data.site_Info.toLowerCase().includes(keyword.toLowerCase());
        const fUnit = data.service_Trucks.some((x) => x.unit_No?.toLowerCase().includes(keyword.toLowerCase()));
        const fDriver = data.service_Trucks.some((x) => x.driver?.toLowerCase().includes(keyword.toLowerCase()));

        if (fUnit || fDriver || fSite) {
            return true;
        } else {
            return false;
        }
    }

    return true;
}

interface IServiceTruckProps {
    index: number;
    data: IServiceTruck;
    onLog?: (id: number) => void;
    onEdit?: (id: number) => void;
    onTruck?: (id: number) => void;
    assignmentList: IAssignmentSite[];
    serviceTruckList: IServiceTruckList[];
    selection: IDriverAssignmentSelection;
};

export const ServiceTruck: React.FC<IServiceTruckProps> = React.memo((props: IServiceTruckProps) =>
    <LAGridItem key={props.data.id} xs={12} sm={6} md={4}>
        <Draggable
            key={props.data.id}
            draggableId={"driver," + props.data.id.toString()}
            index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={dragItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}>

                    <ServiceTruckInfo
                        onLog={props.onLog}
                        onEdit={props.onEdit}
                        onTruck={props.onTruck}
                        serviceTruck={props.data}
                        selection={props.selection}
                        assignmentList={props.assignmentList}
                    />
                </div>
            )}
        </Draggable>
    </LAGridItem>
);

const isServiceTruckAssigned = (serviceTruckId: number, assignments: IAssignmentSite[], selection: IDriverAssignmentSelection): string | undefined => {
    const uniqueJobNames: any = new Set(
        assignments
            .filter((x) => x.serviceTruck && isServiceTruckContainsKey(serviceTruckId, x.serviceTruck, selection))
            .map(({ site }) => getJob(site))
    );

    return [...uniqueJobNames].join(", ");
};

const isServiceTruckContainsKey = (key: number, data: IDriversAssignment, selection: IDriverAssignmentSelection): boolean => {
    if ((selection === "today" && (new Date(data.date).toLocaleDateString('en-US') === getTodayAndTomorrow().today)) ||
        (selection === "tomorrow" && (new Date(data.date).toLocaleDateString('en-US') === getTodayAndTomorrow().tomorrow))) {
        const findIndex = data.service_Trucks.findIndex(x => x.service_Truck_ID === key);
        if (findIndex >= 0) {
            return true;
        };
    }
    return false;
};

const getJob = (val: string): string => {
    const lastIndex = val.lastIndexOf("-");
    return val.substring(lastIndex + 1);
}

export const dragItemStyle = (isDragging: any, draggableStyle: any, borderColor?: string) => ({
    padding: 8,
    margin: 3,
    userSelect: "none",
    borderRadius: "5px",
    wordBreak: "break-word",
    background: isDragging ? WHITE_COLOR : WHITE_COLOR,
    border: `2px solid ${borderColor ?? DARK_GREY_COLOR}`,
    ...draggableStyle
});

interface IServiceTruckInfo {
    onLog?: (id: number) => void;
    onEdit?: (id: number) => void;
    onTruck?: (id: number) => void;
    selection: IDriverAssignmentSelection;
    serviceTruck: IServiceTruck | undefined;
    assignmentList: IAssignmentSite[] | undefined;
};

export const ServiceTruckInfo: React.FC<IServiceTruckInfo> = React.memo((props: IServiceTruckInfo) => {
    const onLog = () => props.onLog && props.serviceTruck ? props.onLog(props.serviceTruck.id) : undefined;
    const onEdit = () => props.onEdit && props.serviceTruck ? props.onEdit(props.serviceTruck.id) : undefined;
    const onTruck = () => props.onTruck && props.serviceTruck ? props.onTruck(props.serviceTruck.id) : undefined;
    const assigned = props.serviceTruck && props.assignmentList ?
        isServiceTruckAssigned(props.serviceTruck.id, props.assignmentList, props.selection)
        : undefined;

    return <div className={(assigned) ? "green-border text-center" : "text-center"}>

        <strong>{props.serviceTruck ? (props.serviceTruck.unit_No + " - " + props.serviceTruck.driver_Name + (props.serviceTruck.swamper_Name !== null ? " - " + props.serviceTruck.swamper_Name : "")) : ""}  </strong>
        <br />

        {props.onEdit && <EditIcon width={20} color={RED_COLOR} onClick={onEdit} className="mr-2" />}
        {props.onLog && <CommunicationLogIcon width={20} color={GREEN_COLOR} onClick={onLog} className="mr-2" />}
        {props.onTruck && <DispatchIcon width={20} color={BLUE_COLOR} onClick={onTruck} />}

        {assigned && <>
            <strong>Assignments: </strong>
            <span>{assigned}</span>
        </>}

    </div>
}
);

interface IDriverAssignmentInfo {
    driverAssignment: IAssignmentSite;
};

export const DriverAssignmentInfo: React.FC<IDriverAssignmentInfo> = React.memo((props: IDriverAssignmentInfo) => {
    return <LAGrid className="text-center">

        <LAGridItem xs={12}>
            {props.driverAssignment.service_Required === "Yes" && <MdWaterDrop color="primary" size={20}/>}
            {props.driverAssignment.service_Required === "No" && <MdFormatColorReset size={22} />}
            {props.driverAssignment.service_Required === "Scheduled" && <FaCalendarAlt size={16} color="#1976D2" />}
            <span className="red-text">{props.driverAssignment.site}</span>
        </LAGridItem>

    </LAGrid>
});