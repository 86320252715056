import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST } from "./getRepairLineItemByIdConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetRepairLineItemByIdLoadAction, IGetRepairLineItemByIdLoadFailedAction, IGetRepairLineItemByIdSuccessAction, getRepairLineItemByIdLoadFailedAction, getRepairLineItemByIdLoadSuccessAction } from "./getRepairLineItemByIdActions";
import { IRepairLineItem } from "../getRepairLines/getRepairLinesConstants";

export const getRepairLineItemByIdEpic: Epic = (
    action$: ActionsObservable<IGetRepairLineItemByIdLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetRepairLineItemByIdSuccessAction | IGetRepairLineItemByIdLoadFailedAction> =>
    action$.ofType(IGET_REPAIR_LINE_ITEM_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IRepairLineItem>>(
                    END_POINTS.FIELD.REPAIR_LINE.GET_REPAIR_LINE_ITEM_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IRepairLineItem>): IGetRepairLineItemByIdSuccessAction => {
                            return getRepairLineItemByIdLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getRepairLineItemByIdLoadFailedAction(response.message)))
                    )
            )
        );