import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_FILTER_CUTS_REQUEST, IFilterCut } from "./getFilterCutsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetFilterCutsLoadAction, IGetFilterCutsLoadFailedAction, IGetFilterCutsSuccessAction } from "./getFilterCutsActions";
import { IAddFilterCutSuccessAction } from "../addFilterCut/addFilterCutActions";
import { IUpdateFilterCutSuccessAction } from "../updateFilterCut/updateFilterCutActions";
import { IADD_FILTER_CUT_REQUEST } from "../addFilterCut/addFilterCutConstants";
import { IUPDATE_FILTER_CUT_REQUEST } from "../updateFilterCut/updateFilterCutConstants";

type Actions =
    | IGetFilterCutsLoadAction
    | IGetFilterCutsSuccessAction
    | IAddFilterCutSuccessAction
    | IUpdateFilterCutSuccessAction
    | IGetFilterCutsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetFilterCutsReducer = (state: Server<SurewayAPIResponse<ById<IFilterCut>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IFilterCut>>> => {
    switch (action.type) {
        case IGET_FILTER_CUTS_REQUEST.REQUEST:
            return loading;

        case IGET_FILTER_CUTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FILTER_CUTS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_FILTER_CUT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FILTER_CUT_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};