//var url:string = "https://api.sureway.ca";
//var url:string = "http://localhost:51380";

import { SOCT_URL } from "../../../../shared/styles";

async function updateSOCT(requestObject:any, currentUser:string, geotab_sync:boolean){
  var geotab_sync_check:string;
  if(geotab_sync){
    geotab_sync_check = "Yes";
  }
  else{
    geotab_sync_check = "No";
  }
    let responseStatus = null;
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type':'application/json' }, 
      body: JSON.stringify({
        token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
        request:{
            ID:requestObject.id,
            Make:requestObject.make,
            Model:requestObject.model,
            Location:requestObject.location,
            Sub_Location:requestObject.sub_Location,
            Sub_Location_ID:requestObject.sub_Location_ID,
            Service_Date:requestObject.service_Date,
            Status:requestObject.status,
            Current_Hours:requestObject.current_Hours,
            Filter_Cuttings:requestObject.filter_Cuttings,
            ECM:Number(requestObject.ecm),
            Comments:requestObject.soct_Comments,
            Geotab_Sync:geotab_sync_check,
            Attachments:requestObject.attachments,
            Modified_By:currentUser,
            }
    })
  }
    await fetch(SOCT_URL+'/api/SOCT/UpdateSOCT', requestOptions)
    .then(async response => {
        const data = response.headers.get('Content-Type')?.includes('application/json')
          // error response
        if(!response.ok) {
            const error = (data) || response.status;
            return Promise.reject(error);
        }
        responseStatus = response;
    });
    return responseStatus;
}

async function addSOCT(requestObject:any, currentUser:string){
  var geotab_sync_check:string;
  if(requestObject.geotab_sync){
    geotab_sync_check = "Yes";
  }
  else{
    geotab_sync_check = "No";
  }
  let responseStatus = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({
      token: 'ac54889e-5ccb-4898-9963-81bb41b47651',
      request:{
          Unit_Number:requestObject.unit_number,
          Make:requestObject.make,
          Model:requestObject.model,
          Location:requestObject.location,
          Sub_Location:requestObject.sub_location,
          Sub_Location_ID:requestObject.sub_location_id,
          Service_Date:requestObject.service_date,
          Current_Hours:requestObject.current_hours,
          Filter_Cuttings:requestObject.filter_cuttings,
          Geotab_Sync:geotab_sync_check,
          Comments:requestObject.comments,
          Attachments:requestObject.attachments,
          Created_By:currentUser,
          Modified_By:currentUser,
          Location_ID:requestObject.location_id
          }
  })
}
  await fetch(SOCT_URL+'/api/SOCT/AddSOCT', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json')
        // error response
      if(!response.ok) {
          const error = (data) || response.status;
          return Promise.reject(error);
      }
      responseStatus = response;
  });
  return responseStatus;
}

async function getSOCTUnitNumbers(){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTUnitNumbers', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      data.map((history:any) => {
        master_temp_list.push(history);
      });
  });
  return master_temp_list;
}


function getSOCTFile(id:any, filename:string){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Equipment_ID:id, File:filename}, token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  fetch(SOCT_URL+'/api/SOCT/GetSOCTFile', requestOptions)
  .then((response:any) => {
      return response.blob();
  })
  .then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();

    //  const link = document.createElement('a');
    //  link.href = filename;
    //  document.body.appendChild(link);
    //  link.click();
    //  document.body.removeChild(link);
    })
  .catch((res) => console.log(res));
}


async function GetSOCTCurrentHoursByID(unit_id:Number){
  let current_hours:any = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request: {ID:unit_id}})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTCurrentHoursByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      current_hours = data;
  });
  return current_hours;
}

async function getSOCTUserRole(currentUser:string){
  var role = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{username:currentUser},token: 'ac54889e-5ccb-4898-9963-81bb41b47651' })
}
await fetch(SOCT_URL+'/api/SOCT/GetSOCTUserGroup', requestOptions)
.then(async response => {
    const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
      // error response
      if(!response.ok) {
        const error = (data) || response.status;
        return Promise.reject(error);
    }
        role = data;
    });
    return role;
}

async function getEquipmentInfoByUnitNumber(unit_number:string){
  let master_temp_list:any[] = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Unit_Number:unit_number},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/getEquipmentInfoByUnitNumber', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      data.map((history:any) => {
        master_temp_list.push(history);
      });
  });
  return master_temp_list;
}

async function getAllSOCTUnits(){
  let master_temp_list:any = [];
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
// api.sureway.ca
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTMasterList', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      data.map((history:any) => {
        master_temp_list.push(history.unit_Number);
      });
  })
  return master_temp_list;
}

async function getSOCTUnitByID(unit_id:Number){
  let unit:any = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({token: 'ac54889e-5ccb-4898-9963-81bb41b47651', request: {ID:unit_id}})
}
  await fetch(SOCT_URL+'/api/SOCT/getSOCTUnitByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      unit = data;
  });
  return unit;
}


async function getEquipmentListIDByUnitNumber(unit_number:string){
  let res = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Unit_Number:unit_number},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetEquipmentListIDByUnitNumber', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      res = data;
  });
  return res;
}


async function getEquipmentListUnitByID(id:number){
  let res = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{ID:id},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetEquipmentListUnitByID', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      res = data;
  });
  return res;
}


async function getAPIListUnitByUnitNumber(unit_number:string){
  let res = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{Unit_Number:unit_number},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetAPIListUnitByUnitNumber', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      res = data;
  });
  return res;
}

async function getSOCTScheduledService(SOCT_ID:number){
  let res = null;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type':'application/json' }, 
    body: JSON.stringify({request:{SOCT_ID:SOCT_ID},token: 'ac54889e-5ccb-4898-9963-81bb41b47651'})
}
  await fetch(SOCT_URL+'/api/SOCT/GetSOCTScheduledService', requestOptions)
  .then(async response => {
      const data = response.headers.get('Content-Type')?.includes('application/json') && await response.json();
        // error response
        if(!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      // no error
      res = data;
  });
  return res;
}


export {updateSOCT, addSOCT, getSOCTUnitNumbers, getSOCTFile, GetSOCTCurrentHoursByID, getSOCTUserRole, getEquipmentInfoByUnitNumber, getAllSOCTUnits, getSOCTUnitByID, getEquipmentListIDByUnitNumber, getEquipmentListUnitByID, getAPIListUnitByUnitNumber, getSOCTScheduledService};