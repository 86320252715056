import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST, IGetShopEquipmentColumn } from "./getShopEquipmentColumnsConstants";
import { IGetShopEquipmentColumnsLoadAction, IGetShopEquipmentColumnsLoadFailedAction, IGetShopEquipmentColumnsSuccessAction } from "./getShopEquipmentColumnsActions";
import { IAssignEquipmentTypeColumnSuccessAction } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnActions";
import { IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST } from "../assignEquipmentTypeColumn/assignEquipmentTypeColumnConstants";

type Actions =
    | IGetShopEquipmentColumnsLoadAction
    | IGetShopEquipmentColumnsSuccessAction
    | IAssignEquipmentTypeColumnSuccessAction
    | IGetShopEquipmentColumnsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetShopEquipmentColumnsReducer = (state: Server<SurewayAPIResponse<IGetShopEquipmentColumn>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetShopEquipmentColumn>> => {
    switch (action.type) {
        case IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.REQUEST:
            return loading;

        case IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SHOP_EQUIPMENT_COLUMNS_REQUEST.FAILED:
            return failed(action.message);

        case IASSIGN_EQUIPMENT_TYPE_COLUMN_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};