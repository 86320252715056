import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IAddUpdateBaseKitRequest } from "../addBaseKit/addBaseKitConstants";
import { IUPDATE_BASE_KIT_REQUEST } from "./updateBaseKitConstants";

export interface IUpdateBaseKitLoadAction {
    type: IUPDATE_BASE_KIT_REQUEST.REQUEST;
    data: IAddUpdateBaseKitRequest;
}
export const updateBaseKitLoadAction = (data: IAddUpdateBaseKitRequest): IUpdateBaseKitLoadAction => ({
    type: IUPDATE_BASE_KIT_REQUEST.REQUEST,
    data
});
export interface IUpdateBaseKitSuccessAction {
    type: IUPDATE_BASE_KIT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<string>;
}
export const updateBaseKitLoadSuccessAction = (list: SurewayAPIResponse<string>): IUpdateBaseKitSuccessAction => ({
    type: IUPDATE_BASE_KIT_REQUEST.SUCCESS,
    list
});
export interface IUpdateBaseKitLoadFailedAction {
    type: IUPDATE_BASE_KIT_REQUEST.FAILED;
    message: string;
}
export const updateBaseKitLoadFailedAction = (message: string): IUpdateBaseKitLoadFailedAction => ({
    type: IUPDATE_BASE_KIT_REQUEST.FAILED,
    message
});
