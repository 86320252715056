import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon, SecurityIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, SKY_BLUE_COLOR, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { IWorkingSiteLog, IWorkingSiteLogRequest } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsConstants";
import { getWorkingSiteLogs } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsAccessor";
import { getWorkingSiteLogsLoadAction } from "../../../redux/field/workingSiteLogs/getWorkingSiteLogs/getWorkingSiteLogsActions";
import { WorkingSiteLogSubHeader } from "../../header/WorkingSiteLogSubHeader";
import { IGetWorkingSiteLogVersionHistory, IGetWorkingSiteLogVersionHistoryRequest } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryConstants";
import { getWorkingSiteLogVersionHistoryLoadAction } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryActions";
import { getWorkingSiteLogVersionHistory } from "../../../redux/field/getWorkingSiteLogVersionHistory/getWorkingSiteLogVersionHistoryAccessor";
import { WorkingSiteLogVersionHistoryPopup } from "./versionHistory";
import LAErrorBox from "../../shared/errorBox";
import { IGetWSSLogsRequest, IWSSLogs } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsConstants";
import { getWSSLogs } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsAccessor";
import { getWSSLogsLoadAction } from "../../../redux/field/workingSiteLogs/getWSSLogs/getWSSLogsActions";
import { IGetWSSLogsHistoryBySiteIDRequest, IWSSLogsHistory } from "../../../redux/field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDConstants";
import { getWSSLogsHistoryBySiteID } from "../../../redux/field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDAccessor";
import { getWSSLogsHistoryBySiteIDLoadAction } from "../../../redux/field/workingSiteLogs/getWSSLogsHistoryBySiteID/getWSSLogsHistoryBySiteIDActions";
import { LogsHistoryPopup } from "./logsHistory";
import { Tooltip } from "devextreme-react";
import CellTowerIcon from '@mui/icons-material/CellTower';

interface IWorkingSiteLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    // getWorkingSiteLog: Server<SurewayAPIResponse<ById<IWorkingSiteLog>>>;
    getWSSLogs: Server<SurewayAPIResponse<ById<IWSSLogs>>>;
    getVersionHistory: Server<SurewayAPIResponse<ById<IGetWorkingSiteLogVersionHistory>>>;
    getWSSLogsHistoryBySiteID: Server<SurewayAPIResponse<IWSSLogsHistory[]>>;
};

interface IWorkingSiteLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    // getWorkingSiteLogsRequest: (data: IWorkingSiteLogRequest) => unknown;
    getWSSLogsRequest: (data: IGetWSSLogsRequest) => unknown;
    getVersionHistoryRequest: (data: IGetWorkingSiteLogVersionHistoryRequest) => unknown;
    getWSSLogsHistoryBySiteIDRequest: (data: IGetWSSLogsHistoryBySiteIDRequest) => unknown;
};


interface IWorkingSiteLogsOwnProps {

};

interface IWorkingSiteLogsState {
    // activeView: boolean;
    view: string;
    versionHistoryPopup: boolean;
    logsHistoryPopup: boolean;
    logsHistorySite: string | undefined;
    activeWorkingSiteLogs: IWorkingSiteLog[];
    safetyObservationsCommentsTooltip: string | undefined;
    locationTooltip: string | undefined;
};

const WorkingSiteLogsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IWorkingSiteLogsProps = RouteComponentProps
    & IWorkingSiteLogsStoreProps
    & IWorkingSiteLogsDispatchProps
    & IWorkingSiteLogsOwnProps;

class WorkingSiteLogs extends PureComponent<IWorkingSiteLogsProps, IWorkingSiteLogsState> {
    tooltip: Tooltip | null | undefined;
    public constructor(props: IWorkingSiteLogsProps) {
        super(props);
        this.state = {
            // activeView: true,
            view: "Active",
            activeWorkingSiteLogs: [],
            versionHistoryPopup: false,
            logsHistoryPopup: false,
            logsHistorySite: undefined,
            safetyObservationsCommentsTooltip: undefined,
            locationTooltip: undefined,
        };
    }

    public async componentDidMount(): Promise<void> {
        // this.checkViewSelections();
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IWorkingSiteLogsProps): void {
        // this.checkViewSelections();

        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getWSSLogs, getToken, getVersionHistory, getWSSLogsHistoryBySiteID } = this.props;
        const { versionHistoryPopup, view, logsHistoryPopup, logsHistorySite} = this.state;
        const getRole = pageAccessCheck(getToken, "workingSiteLogs");
        const versionHistory = hasPayload(getVersionHistory) ? getVersionHistory.payload.response : {};
        const logsHistory = hasPayload(getWSSLogsHistoryBySiteID) ? getWSSLogsHistoryBySiteID.payload.response : [];
        const data = hasPayload(getWSSLogs) ? Object.values(getWSSLogs.payload.response) : [];
        // const data = activeView ? activeWorkingSiteLogs : hasPayload(getWorkingSiteLog) ? Object.values(getWorkingSiteLog.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Working Site Logs" description="FIELD - WORKING SITE LOGS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable) && <>
                    <WorkingSiteLogSubHeader
                        {...this.props}
                    />
                    <WorkingSiteLogsStyles>

                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>WORKING SITE LOGS</h2><br />
                                {/* <LAGrid direction="row" justify="flex-end" alignItems="center">
                                        
                                    <label htmlFor="from-location" style={{ marginRight: "5px" }}>
                                        <div style={{ width: "15px", height: "15px", backgroundColor: SKY_BLUE_COLOR }}></div>
                                    </label>
                                    <strong style={{ marginRight: "5px" }}>Rained/Snowed out</strong>
                                </LAGrid>   */}
                                <hr />

                                {getWSSLogs.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Working Site Logs..." />}
                                {getWSSLogs.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Working Site Logs..." />}

                                {getWSSLogs.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Active" control={<Radio />} label="Today" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2800}
                                                paging={50}
                                                id="WorkingSiteLog"
                                                customRowColor={true}
                                                exportFileName="WorkingSiteLogs"
                                                storageKey={view === "All" ? "working-site-logs-datagrid-all" : "working-site-logs-datagrid-today"}
                                                onCustomClick={this.handleCustomClick}
                                                onCustomIconClick={this.handleIconClick}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    // { name: "id", caption: "id", type: "number" },
                                                    { name: "site", caption: "Working Site", type: "string" },
                                                    { name: "date_", caption: "Date", type: "date", show: view === "Active" ? false : true, width: 100},
                                                    { name: "job_No", caption: "Job No", type: "string", width: 100 },
                                                    { name: "foreman_Display", caption: "Foreman", type: "string", width: 90 },
                                                    { name: "prime_Contractor", caption: "Prime Contractor", type: "string" },
                                                    { name: "radio", caption: "Radio", type: "number", show: view === "Active" ? false : true},
                                                    { name: "start_Time_Val", caption: "Start Time", type: "string" },
                                                    { name: "end_Time_Val", caption: "End Time", type: "string" },
                                                    { name: "work_Description", caption: "Work Description", type: "string" },
                                                    { name: "status", caption: "Status", type: "string", width: 100 },
                                                    { name: "security", caption: "Security", type: "string", width: 100, 
                                                        renderCell: (e:any) => {
                                                            if (e.data) {
                                                                const securtiy = e.data["security"];
                                                                return (
                                                                    securtiy === "Remote Tower" ? <CellTowerIcon color="primary"/> : 
                                                                    securtiy === "Manned Sureway" ? <SecurityIcon color="#1976d2"/> : 
                                                                    ""
                                                                )
                                                            }
                                                        }
                                                    },
                                                    { name: "equip_On_Site", caption: "Equip On Site", type: "string", 
                                                        renderCell: (e:any) => {
                                                            if (e.data) {
                                                                const find = e.data["equip_On_Site"];
                                                                const onCustomIconClick = (): void => find &&  this.handleIconClick("equip_On_Site", e.data) ;
                                                                return <div>
                                                                    {find && new Date(e.data.date).toLocaleDateString('en-US') === new Date().toLocaleDateString('en-US') ? <div className="link-btn" onClick={onCustomIconClick}>{find}</div> : 
                                                                       find ? <div>{find}</div> : ""}
                                                                </div>
                                                            }
                                                        } 
                                                    },
                                                    { name: "equip_Working", caption: "Equip Working", type: "number" },
                                                    { name: "emp_Working", caption: "EMP Working", type: "number" },
                                                    { name: "service_Required", caption: "Service Required", type: "string" },
                                                    { name: "safety", caption: "Safety", type: "string", width: 80, renderCell: (e:any) => {
                                                        if (e.data) {
                                                            const find = e.data["safety"];
                                                            return <div>
                                                                {find === "Yes" ? <div className="link-btn" onMouseOver={(event:any) => this.showTooltipForComments("mouseOver", event,e.data)} onMouseOut={(event:any) => this.showTooltipForComments("mouseOut",event,e.data)}>{find}</div> : <div>{find}</div>}
                                                            </div>
                                                        }
                                                    }},
                                                    { name: "logs_Count", caption: "Logs#", type: "number", width: 80 },
                                                    { name: "images_Count", caption: "Images#", type: "number", width: 80 },
                                                    { name: "workingSiteListActions", caption: "Version History", type: "ws-action-buttons" },
                                                    { name: "logs_History", caption: "Logs History", type: "button"},
                                                    { name: "created", caption: "Created", type: "datetime", show: false },
                                                    { name: "created_By", caption: "Created By", type: "string", show: false },
                                                    { name: "modified", caption: "Modified", type: "datetime", show: false },
                                                    { name: "modified_By", caption: "Modified By", type: "string", show: false },
                                                ]}
                                            />
                                        </LAGridItem>
                                            <Tooltip ref={ref => this.tooltip = ref} position={'top'}>
                                                <div style={{ maxWidth: "600px", height: "auto", wordWrap: "break-word", overflowWrap: "break-word" , whiteSpace: "normal" }}>
                                                    <div><b>{this.state.locationTooltip && `Site : ${this.state.locationTooltip}`}</b></div>
                                                    <div><b>{this.state.safetyObservationsCommentsTooltip && this.state.safetyObservationsCommentsTooltip}</b></div>
                                                </div>
                                            </Tooltip>
                                    </LAGrid>
                                    }
                            </LAGridItem>
                        </LAGrid>

                        {versionHistoryPopup && <WorkingSiteLogVersionHistoryPopup
                            data={versionHistory}
                            open={versionHistoryPopup}
                            status={getVersionHistory.kind}
                            onCancel={this.handleVersionHistoryClose}
                        />}

                        {logsHistoryPopup && <LogsHistoryPopup
                            data={logsHistory}
                            open={logsHistoryPopup}
                            site={logsHistorySite}
                            status={getWSSLogsHistoryBySiteID.kind}
                            onCancel={this.handleLogsHistoryClose}
                        />}

                    </WorkingSiteLogsStyles>
                </>}
            </PageSpacing>
        );
    }

    private showTooltipForComments = (name: string, e:any, data:any) => {
        // console.log(e)
        if(name === "mouseOver") {
            if(data) {
                this.setState({
                    safetyObservationsCommentsTooltip: data.safety_Observations_Comments,
                    locationTooltip: data.site
                });
                if(data.safety_Observations_Comments)
                    this.tooltip && this.tooltip.instance.show(e.target);
            }
        }
        else if(name === "mouseOut") {
            this.tooltip && this.tooltip.instance.hide();
            this.setState({
                safetyObservationsCommentsTooltip: undefined,
                locationTooltip: undefined
            });
        }
    }

    private handleCustomClick = (name: string, e: any): void => {
        const data: IWSSLogs = e.row.data;
        // console.log(data)
        if(name === "logs_History"){
            if (hasPayload(this.props.getToken)) {
                this.props.getWSSLogsHistoryBySiteIDRequest({
                    request: {
                        id: data.wsS_ID,
                    },
                    token: this.props.getToken.payload.response.token
                });

                this.setState({ logsHistoryPopup: true, logsHistorySite: data.site });
            }
        }

    };

    private handleIconClick = (name: string, e: any): void => {
        if(name === "history"){
            if (hasPayload(this.props.getToken)) {
                this.props.getVersionHistoryRequest({
                    request: {
                        ID: e.id,
                    },
                    token: this.props.getToken.payload.response.token
                });

                this.setState({ versionHistoryPopup: true });
            }
        } else if(name === "equip_On_Site"){
            
            callRouteWithQueryString({
                route: this.props,
                search: { keyword: e.wsS_ID.toString() },
                pathName: ROUTE.FIELD.EQUIPMENT.INDEX
            });
        }
    };

    private handleVersionHistoryClose = (): void => {
        this.setState({ versionHistoryPopup: false });
    };

    private handleLogsHistoryClose = (): void => {
        this.setState({ logsHistoryPopup: false, logsHistorySite: undefined });
    };

    private onViewClick = (e:any): void => {
        this.setState({ view: e.target.value });
        this.getDataForTable(e.target.value)

        // const getStorageValue = localStorage.getItem("key_workingSiteLog");
        // if (getStorageValue) {
        //     if (!this.state.activeView) {
        //         localStorage.setItem("key_workingSiteLog", "Active");
        //     } else {
        //         localStorage.setItem("key_workingSiteLog", "All");
        //     }
        // } else {
        //     localStorage.setItem("key_workingSiteLog", "Active");
        // }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOG
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.WORKING_SITE_LOGS.WORKING_SITE_LOG
        });
    };

    // private checkViewSelections = (): void => {
    //     let activeView = this.state.activeView;
    //     const getStorageValue = localStorage.getItem("key_workingSiteLog");
    //     if (getStorageValue) {
    //         if (getStorageValue === "active") {
    //             activeView = true;
    //         }
    //         else {
    //             activeView = false;
    //         }
    //     }
    //     else {
    //         localStorage.setItem("key_workingSiteLog", "active");
    //     }
    //     this.setState({ activeView });
    // }

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "workingSiteLogs") !== NotApplicable) {
                if (isNotLoaded(this.props.getWSSLogs)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            }

            // if (hasPayload(this.props.getWorkingSiteLog)) {
            //     const today = new Date().toDateString();

            //     this.setState({
            //         activeWorkingSiteLogs: Object.values(this.props.getWorkingSiteLog.payload.response)
            //             .filter(x => new Date(x.date).toDateString() === today)
            //     });
            // };

        };
    };

    private getDataForTable = (view?: string): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getWSSLogsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    view: view ? view : this.state.view
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IWorkingSiteLogsStoreProps => ({
    getToken: getToken(state),
    // getWorkingSiteLog: getWorkingSiteLogs(state),
    getVersionHistory: getWorkingSiteLogVersionHistory(state),
    getWSSLogs: getWSSLogs(state),
    getWSSLogsHistoryBySiteID: getWSSLogsHistoryBySiteID(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IWorkingSiteLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    // getWorkingSiteLogsRequest: (data: IWorkingSiteLogRequest) => dispatch(getWorkingSiteLogsLoadAction(data)),
    getVersionHistoryRequest: (data: IGetWorkingSiteLogVersionHistoryRequest) => dispatch(getWorkingSiteLogVersionHistoryLoadAction(data)),
    getWSSLogsRequest: (data: IGetWSSLogsRequest) => dispatch(getWSSLogsLoadAction(data)),
    getWSSLogsHistoryBySiteIDRequest: (data: IGetWSSLogsHistoryBySiteIDRequest) => dispatch(getWSSLogsHistoryBySiteIDLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingSiteLogs);