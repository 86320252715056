import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../shared/errorBox";
import { LocationsMenu, Navbar } from "../../shared/navbars";
import { IGetMedicalFacilities, IGetMedicalFacilitiesRequest } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesConstants";
import { getMedicalFacilities } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesAccessor";
import { getMedicalFacilitiesLoadAction } from "../../../redux/field/getMedicalFacilities/getMedicalFacilitiesActions";

interface IMedicalFacilitiesStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMedicalFacilities: Server<SurewayAPIResponse<ById<IGetMedicalFacilities>>>;
};

interface IMedicalFacilitiesDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMedicalFacilitiesRequest: (data: IGetMedicalFacilitiesRequest) => unknown;
};


interface IMedicalFacilitiesOwnProps {

};

interface IMedicalFacilitiesState {
    
};

const MedicalFacilitiesStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IMedicalFacilitiesProps = RouteComponentProps
    & IMedicalFacilitiesStoreProps
    & IMedicalFacilitiesDispatchProps
    & IMedicalFacilitiesOwnProps;

class MedicalFacilities extends PureComponent<IMedicalFacilitiesProps, IMedicalFacilitiesState> {

    public constructor(props: IMedicalFacilitiesProps) {
        super(props);
        this.state = {

        };
    }

    public componentDidMount(): void {
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IMedicalFacilitiesProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const {  } = this.state;
        const { getMedicalFacilities, getToken } = this.props;
        const getRole = pageAccessCheck(getToken, "locationList");
        const data = hasPayload(getMedicalFacilities) ? Object.values(getMedicalFacilities.payload.response) : [];
        // console.log(data);

        return (
            <PageSpacing title="Medical Facilities" description="Field - Medical Facilities List" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={LocationsMenu} role={true}/>
                {(getRole !== NotApplicable) && 
                    <MedicalFacilitiesStyles>
                        <LAPaperWithPadding>
                            <LAGrid>
                                <LAGridItem xs={12} className="text-center">

                                    <LAButton
                                        label="Add New"
                                        className="pull-left"
                                        onClick={this.handleAdd}
                                        startIcon={<AddIcon color={WHITE_COLOR} />}
                                    />

                                    <h2 className="title">MEDICAL FACILITIES</h2>
                                    <hr />

                                    {getMedicalFacilities.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Medical Facilities..." />}
                                    {getMedicalFacilities.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Medical Facilities..." />}

                                    {getMedicalFacilities.kind === STATUS_ENUM.SUCCEEDED &&
                                        <LAGrid>
                                            <LAGridItem xs={12}>
                                                <LADevExtremeGrid
                                                    data={data}
                                                    columnChoose={true}
                                                    columnsHiding={true}
                                                    onEdit={this.onEdit}
                                                    onClick={this.onClick}
                                                    // onCustomClick={this.handleCustomClick}
                                                    searchPanel={true}
                                                    filterHeader={true}
                                                    export={true}
                                                    actionWidth={90}
                                                    timeout={120}
                                                    removeStyleBtn={true}
                                                    height={1200}
                                                    key="id"
                                                    id="MedicalFacilitiesList"
                                                    customRowColor={true}
                                                    storageKey="medicalFacilitiesSessionKey"
                                                    exportFileName="MedicalFacilities"
                                                    getRefreshDataCall={this.getDataForTable}
                                                    columns={[
                                                        // { name: "id", caption: "ID", type: "number", sortDesc: true, show: false },
                                                        { name: "name", caption: "Name", type: "string" },
                                                        { name: "address", caption: "Address", type: "string" },
                                                        { name: "gps", caption: "GPS", type: "string" },
                                                        { name: "active", caption: "Active", type: "string" },
                                                        { name: "created_By", caption: "Created By", type: "string" },
                                                        { name: "created", caption: "Created", type: "datetime" },
                                                        { name: "modified_By", caption: "Modified By", type: "string" },
                                                        { name: "modified", caption: "Modified", type: "datetime", sortDesc: true},
                                                    ]}
                                                />
                                            </LAGridItem>
                                        </LAGrid>}
                                </LAGridItem>
                            </LAGrid>
                        </LAPaperWithPadding>
                    </MedicalFacilitiesStyles>
                }
            </PageSpacing>
        
        );
    }

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString(), token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.MEDICAL_FACILITIES.ADD_UPDATE_MEDICAL_FACILITY
        });
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", token: getTokenFromUrl(true) },
            pathName: ROUTE.FIELD.MEDICAL_FACILITIES.ADD_UPDATE_MEDICAL_FACILITY
        });
    };

    private callServer = (): void => {
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });

        if (hasPayload(this.props.getToken)) {
            if (pageAccessCheck(this.props.getToken, "locationList") !== NotApplicable) {
                if (isNotLoaded(this.props.getMedicalFacilities)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };
        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMedicalFacilitiesRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): IMedicalFacilitiesStoreProps => ({
    getToken: getToken(state),
    getMedicalFacilities: getMedicalFacilities(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IMedicalFacilitiesDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMedicalFacilitiesRequest: (data: IGetMedicalFacilitiesRequest) => dispatch(getMedicalFacilitiesLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalFacilities);
