import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_SURVEY_ATTACHMENT_REQUEST } from "./updateSurveyAttachmentConstants";
import { IUpdateSurveyAttachmentLoadAction, IUpdateSurveyAttachmentLoadFailedAction, IUpdateSurveyAttachmentSuccessAction, 
    updateSurveyAttachmentLoadFailedAction, updateSurveyAttachmentLoadSuccessAction } from "./updateSurveyAttachmentActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { SUCCESS_MESSAGE } from '../../../react/shared/constExports';

export const updateSurveyAttachmentEpic: Epic = (
    action$: ActionsObservable<IUpdateSurveyAttachmentLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateSurveyAttachmentSuccessAction | IUpdateSurveyAttachmentLoadFailedAction> =>
    action$.ofType(IUPDATE_SURVEY_ATTACHMENT_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.SURVEY.UPDATE_SURVEY_ATTACHMENT,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateSurveyAttachmentSuccessAction | IUpdateSurveyAttachmentLoadFailedAction => {
                            if(response.message === SUCCESS_MESSAGE){
                                return updateSurveyAttachmentLoadSuccessAction(response);
                            } else {
                                return updateSurveyAttachmentLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateSurveyAttachmentLoadFailedAction(response.message)))
                    )
            )
        );