import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_ATTACHMENT_TYPE_REQUEST, IUpdateAttachmentTypeRequest } from "./updateTypeConstants";


export interface IUpdateAttachmentTypeLoadAction {
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.REQUEST;
    data: IUpdateAttachmentTypeRequest
}
export const updateAttachmentTypeLoadAction = (data: IUpdateAttachmentTypeRequest): IUpdateAttachmentTypeLoadAction => ({
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.REQUEST,
    data
});
export interface IUpdateAttachmentTypeSuccessAction {
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateAttachmentTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateAttachmentTypeSuccessAction => ({
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.SUCCESS,
    message
});
export interface IUpdateAttachmentTypeLoadFailedAction {
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.FAILED;
    message: string;
}
export const updateAttachmentTypeLoadFailedAction = (message: string): IUpdateAttachmentTypeLoadFailedAction => ({
    type: IUPDATE_ATTACHMENT_TYPE_REQUEST.FAILED,
    message
});
