import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_SURVEY_LOOKUP_REQUEST, ISurveyLookup, ISurveyLookupRequest } from "./getSurveyLookupConstants";

export interface IGetSurveyLookupLoadAction {
    type: IGET_SURVEY_LOOKUP_REQUEST.REQUEST;
    data: ISurveyLookupRequest
}
export const getSurveyLookupLoadAction = (data: ISurveyLookupRequest): IGetSurveyLookupLoadAction => ({
    type: IGET_SURVEY_LOOKUP_REQUEST.REQUEST,
    data
});
export interface IGetSurveyLookupSuccessAction {
    type: IGET_SURVEY_LOOKUP_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ISurveyLookup>;
}
export const getSurveyLookupLoadSuccessAction = (list: SurewayAPIResponse<ISurveyLookup>): IGetSurveyLookupSuccessAction => ({
    type: IGET_SURVEY_LOOKUP_REQUEST.SUCCESS,
    list
});
export interface IGetSurveyLookupLoadFailedAction {
    type: IGET_SURVEY_LOOKUP_REQUEST.FAILED;
    message: string;
}
export const getSurveyLookupLoadFailedAction = (message: string): IGetSurveyLookupLoadFailedAction => ({
    type: IGET_SURVEY_LOOKUP_REQUEST.FAILED,
    message
});
