import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_WORKING_SITE_LOG_REQUEST, IUpdateWorkingSiteLogRequest } from "./updateWorkingSiteLogConstants";


export interface IUpdateWorkingSiteLogLoadAction {
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.REQUEST;
    data: IUpdateWorkingSiteLogRequest
}
export const updateWorkingSiteLogLoadAction = (data: IUpdateWorkingSiteLogRequest): IUpdateWorkingSiteLogLoadAction => ({
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.REQUEST,
    data
});
export interface IUpdateWorkingSiteLogSuccessAction {
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateWorkingSiteLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateWorkingSiteLogSuccessAction => ({
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.SUCCESS,
    message
});
export interface IUpdateWorkingSiteLogLoadFailedAction {
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.FAILED;
    message: string;
}
export const updateWorkingSiteLogLoadFailedAction = (message: string): IUpdateWorkingSiteLogLoadFailedAction => ({
    type: IUPDATE_WORKING_SITE_LOG_REQUEST.FAILED,
    message
});
