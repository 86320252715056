import React from "react";
import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import GoogleMapReact from "google-map-react";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken } from "../../../redux/getToken/getTokenConstants";
import { ILocation, ILocationRequest, ISubLocation, IWSSPicture } from "../../../redux/field/getLocation/getLocationConstants";
import { IAddLocationRequest, IAddUpdateSubLocation } from "../../../redux/field/addLocation/addLocationConstants";
import { IUpdateLocationRequest } from "../../../redux/field/updateLocation/updateLocationConstants";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LABluePaper, LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, LIGHT_ORANGE_COLOR, SECONDARY_COLOR, WHITE_COLOR, RED_COLOR, EXTRA_LIGHT_BLUE_COLOR, EXTRA_LIGHT_YELLOW_COLOR } from "../../shared/theme";
import { deleteText, fileToBase64Image, getTokenFromUrl, pageAccessCheck, undefinedFunction, YesOrNo, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LAIconUploadButton, LASaveAndCancelButton } from "../../shared/buttons";
import { AddIcon, ArrowLeftIcon, DeleteIcon, LocationPinIcon, UploadIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import LATextArea from "../../shared/textArea";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { addLocation } from "../../../redux/field/addLocation/addLocationAccessor";
import { updateLocation } from "../../../redux/field/updateLocation/updateLocationAccessor";
import { getLocationLoadAction } from "../../../redux/field/getLocation/getLocationActions";
import { addLocationLoadAction } from "../../../redux/field/addLocation/addLocationActions";
import { updateLocationLoadAction } from "../../../redux/field/updateLocation/updateLocationActions";
import { getLocations } from "../../../redux/field/getLocation/getLocationAccessor";
import { ILookup, ILookupJobs, ILookupsRequest } from "../../../redux/getLookups/getLookupsConstants";
// import { getLookup } from "../../../redux/getLookups/getLookupsAccessor";
// import { getLookupsLoadAction } from "../../../redux/getLookups/getLookupsActions";
import LAErrorBox from "../../shared/errorBox";
import { LACheckBox } from "../../shared/checkBox";
import { NotApplicable, ReadOnly } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { DevExtremePopupLookup } from "../../shared/devExtremePopupLookup";
import { LAPopover } from "../../shared/popOver";
import { defaultMapProps } from "../equipment/equipmentListMap";
import { webConfig } from "../../../utils/webConfig";
import LALinkButton from "../../shared/linkButton";
import { v4 as uuidv4 } from "uuid";
import { LAThumbnailWithLink } from "../../shared/thumbnail";
import { LADropZone } from "../../shared/dropZone";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";

const RequiredFields: string[] = ["site_Name"];

interface IAddUpdateLocationComponentStoreProps {
    token: Server<SurewayAPIResponse<IToken>>;
    locations: Server<SurewayAPIResponse<ById<ILocation>>>;
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addLocationStatus: Server<SurewayAPIResponse<string>>;
    updateLocationStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateLocationComponentDispatchProps {
    getLocationsRequest: (data: ILocationRequest) => unknown;
    addLocationRequest: (data: IAddLocationRequest) => unknown;
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    updateLocationRequest: (data: IUpdateLocationRequest) => unknown;
};

interface IAddUpdateLocationOwnProps {
    id?: string;
    popUp?: true;
    onPopupClose?: () => void;
};

interface IAddUpdateLocationComponentState {
    data: ILocation;
    serverError: string;
    nonAvaJobs: string[];
    duplicateArrayIndex: number[];
    mapNearestMedicalFacility : boolean;
    mapIndex: number | undefined;
    errors: ById<IFieldErrorKeyValue>;
    mapMusterIndex: number | undefined;
};

const AddUpdateLocationStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .title {
        font-weight: bold;
        color: ${WHITE_COLOR};
    };
    
    .header-row {
        background-color: ${SECONDARY_COLOR};
    };

    .warning-row {
        background-color: ${LIGHT_ORANGE_COLOR};
    };
    
    .even-row {
        background-color: ${EXTRA_LIGHT_BLUE_COLOR};
    }
    .odd-row {
        background-color: ${EXTRA_LIGHT_YELLOW_COLOR};
    };
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };

    .left-Reset{
        margin-left:4px;
    }
`;

type IAddUpdateLocationComponentProps =
    RouteComponentProps
    & IAddUpdateLocationOwnProps
    & IAddUpdateLocationComponentStoreProps
    & IAddUpdateLocationComponentDispatchProps;

class AddUpdateLocation extends PureComponent<IAddUpdateLocationComponentProps, IAddUpdateLocationComponentState> {

    public constructor(props: IAddUpdateLocationComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                site_Name: "",
                site_Address: "",
                daily_Service_Truck_Required: "No",
                active: YesOrNo[0].name,
                notes: "",
                nearest_Medical_Facility: "",
                nearest_Medical_Facility_GPS: "",
                rental_Status: YesOrNo[1].name,
                created: "",
                created_By: "",
                modified: "",
                modified_By: "",
                job_No: "",
                job_Number_ID: 0,
                no_Job_Number_Required: "",
                show_Sub_Sites_on_Map: "",
                service_Truck: "",
                working_Sub_Sites: [],
            },
            errors: {},
            nonAvaJobs: [],
            serverError: "",
            mapNearestMedicalFacility: false,
            mapIndex: undefined,
            mapMusterIndex: undefined,
            duplicateArrayIndex: []
        };
    }

    public componentDidMount(): void {
        this.callGetLookup();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateLocationComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addLocationStatus !== prevProps.addLocationStatus) {

                if (this.props.addLocationStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addLocationStatus.message });

                if (hasPayload(this.props.addLocationStatus) && this.props.addLocationStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateLocationStatus !== prevProps.updateLocationStatus) {

                if (this.props.updateLocationStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateLocationStatus.message });

                if (hasPayload(this.props.updateLocationStatus) && this.props.updateLocationStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, duplicateArrayIndex, mapIndex, mapMusterIndex, mapNearestMedicalFacility } = this.state;
        const { token, addLocationStatus, updateLocationStatus, popUp } = this.props;
        const getRole = pageAccessCheck(token, "locationList");
        const disabled = getRole === ReadOnly ? true : undefined;
        const saveDisable = Object.values(errors).length > 0 ? true : this.checkSubSitNames() ? true : disabled;
        const onActive = (event: unknown, value: IIdName): void => this.handleChange("active", value !== null ? value.name : data.active);
        const jobs = this.getJobs();
        const onNoJobRequiredChange = (check: boolean): void => this.onNoJobRequiredChange(check);
        const onRentalStatus = (check: boolean): void => this.onRentalStatusChange(check);
        const onDailyServiceTruck = (check: boolean): void => this.onDailyServiceTruckChange(check);
        const onShowSubSiteOnMapChange = (check: boolean): void => this.onShowSubSiteOnMapChange(check);
        // const onResetNearestMedicalFacility = (): void => this.handleChange("nearest_Medical_Facility_GPS", "");
        // console.log(this.props.getLookup)
        // console.log(data.working_Sub_Sites);
        return (
            <AddUpdateLocationStyles>
                {(getRole !== NotApplicable) &&
                    <LAGrid spacing={1}>

                        <LAGridItem xs={12} sm={12} md={8}>
                            <LAButton
                                onClick={this.handleCancel}
                                label={popUp ? "Back to Job" : "Back to List"}
                                startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={12} className="text-center">
                            <h2>LOCATION DETAILS</h2>
                            <hr />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                name="site_Name"
                                label="Site Name"
                                fullWidth={true}
                                variant="outlined"
                                value={data.site_Name}
                                onChange={this.handleChange}
                                disabled={disabled}
                                errorText={errors["site_Name"] ? errors["site_Name"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                name="site_Address"
                                label="Site Address"
                                fullWidth={true}
                                variant="outlined"
                                value={data.site_Address}
                                onChange={this.handleChange}
                                disabled={disabled}
                                errorText={errors["site_Address"] ? errors["site_Address"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LAAutoComplete
                                disabled={disabled}
                                multiple={false}
                                option={YesOrNo}
                                getOptionLabel="name"
                                autoHighlight={true}
                                onChange={onActive}
                                filterSelectedOptions={true}
                                dropDownPlaceHolder="Active"
                                selectionRemove={undefinedFunction}
                                value={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                                defaultValue={data.active ? YesOrNo.find(q => q.name === data.active) : null}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                label="Show Sub Sites on Map"
                                name="show_Sub_Sites_on_Map"
                                onChange={onShowSubSiteOnMapChange}
                                disabled={disabled}
                                value={data.show_Sub_Sites_on_Map === "Yes" ? true : false}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                label="No Job # required"
                                name="no_Job_Number_Required"
                                onChange={onNoJobRequiredChange}
                                disabled={disabled}
                                value={data.no_Job_Number_Required === "Yes" ? true : false}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                label="Rental Status"
                                name="rental_Status"
                                disabled={disabled}
                                onChange={onRentalStatus}
                                value={data.rental_Status === "Yes" ? true : false}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <LACheckBox
                                label="Daily Service Truck Required"
                                name="daily_Service_Truck_Required"
                                disabled={disabled}
                                onChange={onDailyServiceTruck}
                                value={data.daily_Service_Truck_Required === "Yes" ? true : false}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={2}>
                            <></>
                        </LAGridItem>

                        {data.no_Job_Number_Required !== "Yes" && <LAGridItem xs={12} sm={6} md={4}>
                            <DevExtremePopupLookup
                                data={jobs}
                                id="job-search"
                                placeHolder="Job #"
                                disabled={disabled}
                                displayExp="job_No"
                                name="job_No"
                                errorName="job_No"
                                errors={errors}
                                currentVal={data.job_No}
                                onClick={this.handleJobSelect}
                                columns={[
                                    { name: "job_No", caption: "Job Number", type: "string" },
                                    { name: "name", caption: "Name", type: "string" },
                                    { name: "client", caption: "Client", type: "string" }
                                ]}
                            />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                minRows={3}
                                rowsMax={4}
                                fullWidth={true}
                                name="notes"
                                label="Notes"
                                disabled={disabled}
                                value={data.notes}
                                onChange={this.handleChange}
                                errorText={errors["notes"] ? errors["notes"].message : undefined}
                            />
                        </LAGridItem>

                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextField
                                name="nearest_Medical_Facility"
                                label="Nearest Medical Facility"
                                fullWidth={true}
                                variant="outlined"
                                value={data.nearest_Medical_Facility}
                                onChange={this.handleChange}
                                disabled={disabled}
                                errorText={errors["nearest_Medical_Facility"] ? errors["nearest_Medical_Facility"].message : undefined}
                            />
                            <LALinkButton
                                label="Open Map"
                                onClick={this.handleGetCordinatesClick}
                            />
                            <LALinkButton
                                className="left-Reset"
                                label="Reset"
                                onClick={this.resetNearestMedicalFacility}
                            />
                        </LAGridItem>
                        <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                rowsMax={1}
                                disabled={true}
                                fullWidth={true}
                                variant="outlined"
                                label="Nearest Medical Facility GPS"
                                name="nearest_Medical_Facility_GPS"
                                onChange={undefinedFunction}
                                value={data.nearest_Medical_Facility_GPS ?? ""}
                            />
                        </LAGridItem>

                        {data.id > 0 && <LAGridItem xs={12} sm={6} md={4}>
                            <LATextArea
                                rowsMax={1}
                                disabled={true}
                                fullWidth={true}
                                variant="outlined"
                                label="Driving distance to medical facility"
                                name="nearest_Medical_Facility_Distance"
                                onChange={undefinedFunction}
                                value={data.nearest_Medical_Facility_Distance ?? ""}
                            />
                        </LAGridItem>}

                        <SubWorkingLocationTable
                            onAdd={this.onAdd}
                            disabled={disabled}
                            data={data.working_Sub_Sites}
                            onMapClick={this.handleMapClick}
                            handleChange={this.handleTableChange}
                            duplicateArrayIndex={duplicateArrayIndex}
                            onMusterMapClick={this.handleMapMusterClick}
                            handlePictureUpload={this.handlePictureUpload}
                            handlePictureDescription={this.handlePictureDescription}
                            HandlePictureRemove={this.HandlePictureRemove}
                            handleJournals={this.handleJournals}
                        />

                        <LAGridItem xs={12} >
                            {data.id > 0 && <LAGrid spacing={1}>
                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="created"
                                        label="Created"
                                        disabled={true}
                                        value={data.created ? new Date(data.created).toLocaleString() : ""}
                                        onChange={this.handleChange}
                                        errorText={errors["created"] ? errors["created"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="created_By"
                                        label="Created By"
                                        disabled={true}
                                        value={data.created_By}
                                        onChange={this.handleChange}
                                        errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                    />
                                </LAGridItem>

                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="modified"
                                        label="Last Modified"
                                        disabled={true}
                                        value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                        onChange={this.handleChange}
                                        errorText={errors["modified"] ? errors["modified"].message : undefined}
                                    />
                                </LAGridItem>


                                <LAGridItem xs={12} sm={6} md={3}>
                                    <LATextArea
                                        rowsMax={1}
                                        fullWidth={true}
                                        name="modified_By"
                                        label="Modified By"
                                        disabled={true}
                                        value={data.modified_By}
                                        onChange={this.handleChange}
                                        errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                    />
                                </LAGridItem>
                            </LAGrid>}
                        </LAGridItem>

                        {serverError.length > ZEROTH && <LAGridItem xs={12}>
                            <LAErrorBox text={serverError} />
                        </LAGridItem>}

                        <LAGridItem xs={12} sm={12} md={4}>
                            <LASaveAndCancelButton
                                fullWidth={true}
                                saveButtonText="Save"
                                onSave={this.handleSave}
                                cancelButtonText="Close"
                                disableSave={saveDisable}
                                onCancel={this.handleCancel}
                            />
                        </LAGridItem>

                    </LAGrid>}

                {mapNearestMedicalFacility && <GPSPopup
                    onChange={this.handleNearestMedicalFacilityGPSChange}
                    onCancel={this.handleMapNearestMedicalFacilityClose}
                    open={mapNearestMedicalFacility}
                    lat={this.getNearestMedicalFacility(true)}
                    long={this.getNearestMedicalFacility(false)}
                />}

                {(mapIndex !== undefined) && <GPSPopup
                    onChange={this.handleLatLong}
                    onCancel={this.handleMapClose}
                    open={(mapIndex !== undefined) ? true : false}
                    lat={data.working_Sub_Sites[mapIndex].latitude}
                    long={data.working_Sub_Sites[mapIndex].longitude}
                />}

                {(mapMusterIndex !== undefined) && <GPSPopup
                    lat={this.getMusterPoint(true)}
                    long={this.getMusterPoint(false)}
                    onChange={this.handleMusterChange}
                    onCancel={this.handleMusterMapClose}
                    open={(mapMusterIndex !== undefined) ? true : false}
                />}

                <RequestStatus requestStatus={addLocationStatus.kind} successMessage="Location has been added successfully" />
                <RequestStatus requestStatus={updateLocationStatus.kind} successMessage="Location has been updated successfully" />
            </AddUpdateLocationStyles>
        );
    }

    private handleJournals = (wssID: number): void => {
        window.open(ROUTE.FIELD.WORKING_SITE_LOGS.JOURNALS + "?keyword=" + wssID.toString() , "_blank" )
    };

    private checkSubSitNames = (): true | undefined => {
        const data = this.state.data.working_Sub_Sites;

        if(data.length > 0){
            const idx = data.findIndex((x) => (x.sub_Site_Name === null) || (x.sub_Site_Name.length === 0));
            if(idx >= 0)
                return true;
        }
        return undefined;
    };

    private handleMusterChange = (lat: number, long: number): void => {
        const { mapMusterIndex } = this.state;

        if (mapMusterIndex !== undefined) {
            const working_Sub_Sites = [...this.state.data.working_Sub_Sites];

            working_Sub_Sites[mapMusterIndex].muster_Point_GPS = lat.toString() + "," + long.toString();

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites
                }
            });
        };
    };

    private getMusterPoint = (lat: boolean): string => {
        const { data, mapMusterIndex } = this.state;

        if (mapMusterIndex !== undefined) {
            const musterPoint = data.working_Sub_Sites[mapMusterIndex];

            if ((musterPoint.muster_Point_GPS !== null) && (musterPoint.muster_Point_GPS.length > 0)) {
                if (lat) {
                    return musterPoint.muster_Point_GPS.split(",")[0];
                } else {
                    return musterPoint.muster_Point_GPS.split(",")[1];
                }
            }
        }

        return "";
    };

    private handleNearestMedicalFacilityGPSChange = async(lat: number, long: number): Promise<void> => {
        const { mapNearestMedicalFacility } = this.state;

        if (mapNearestMedicalFacility) {
            let nearest_Medical_Facility_GPS = this.state.data.nearest_Medical_Facility_GPS;

            nearest_Medical_Facility_GPS = lat.toString() + "," + long.toString();

            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${webConfig.googleMapKey}`
                );
                const data = await response.json();
                if (data.status === 'OK' && data.results.length > 0) {
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            nearest_Medical_Facility: data.results[0].formatted_address
                        }
                    });
                } 
            } catch (error) {
                console.error('Error fetching address:', error);
            }

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    nearest_Medical_Facility_GPS
                }
            });
        };
    };

    private getNearestMedicalFacility = (lat: boolean): string => {
        const { data, mapNearestMedicalFacility } = this.state;

        if (mapNearestMedicalFacility) {
            const nearest_Medical_Facility_GPS = data.nearest_Medical_Facility_GPS;

            if (nearest_Medical_Facility_GPS && (nearest_Medical_Facility_GPS !== null) && (nearest_Medical_Facility_GPS.length > 0)) {
                if (lat) {
                    return nearest_Medical_Facility_GPS.split(",")[0];
                } else {
                    return nearest_Medical_Facility_GPS.split(",")[1];
                }
            }
        }

        return "";
    };

    private resetNearestMedicalFacility = () => {
        this.setState({
            data: {
               ...this.state.data,
                nearest_Medical_Facility: "",
                nearest_Medical_Facility_GPS: ""
            }
        });
    }

    private handleMapNearestMedicalFacilityClose = (): void => {
        this.setState({ mapNearestMedicalFacility: false });
    };

    private handleLatLong = (lat: number, long: number): void => {
        const { mapIndex } = this.state;

        if (mapIndex !== undefined) {
            const working_Sub_Sites = [...this.state.data.working_Sub_Sites];

            working_Sub_Sites[mapIndex].latitude = lat.toString();
            working_Sub_Sites[mapIndex].longitude = long.toString();

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites
                }
            });
        };
    };

    private handleMapMusterClick = (mapMusterIndex: number): void => {
        this.setState({ mapMusterIndex });
    };

    private handleMapClick = (mapIndex: number): void => {
        this.setState({ mapIndex });
    };

    private handleMapClose = (): void => {
        this.setState({ mapIndex: undefined });
    };

    private handleMusterMapClose = (): void => {
        this.setState({ mapMusterIndex: undefined });
    };

    private handleJobSelect = (value: string): void => {
        if (value !== undefined)
            this.handleDropDownChange(null, value, "job");
    };

    private onAdd = (): void => {
        const list = [...this.state.data.working_Sub_Sites];

        if (hasPayload(this.props.token))
            list.push({
                id: 0,
                itemID: 0,
                modified: "",
                created: "",
                created_By: this.props.token.payload.response.upn,
                modified_By: this.props.token.payload.response.upn,
                active: YesOrNo[0].name,
                sub_Site_Name: "",
                sub_Site_Address: "",
                notes: "",
                working_Site_ID: this.state.data.id,
                latitude: "",
                longitude: "",
                whiteboard_ID: 0,
                sT_ID: "",
                muster_Point_GPS: "",
                pictures: [],
                cost_Code_Billing: "No",
                muster_Point: ""
            });

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                working_Sub_Sites: list
            }
        });
    };

    private handleTableChange = (name: string, value: string, index?: number | undefined): void => {
        if (index !== undefined) {

            const iS = [...this.state.data.working_Sub_Sites];
            let record = { ...iS[index] };

            if(name === "latitude" || name === "longitude"){
                if(/[a-zA-Z]/.test(value) === false){
                    record = { ...record, [name]: value };
                }
            } else {
                record = { ...record, [name]: value };
            }
            
            if(name === "resetGPS")
            {
                record = { ...record, latitude: "", longitude: "" };
            }
            if(name === "resetMusterGPS")
            {
                record = { ...record, muster_Point_GPS: "" };
            }

            iS[index] = record;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites: iS
                }
            });
        }       
    };

    private handlePictureDescription = (name: string, value: string, picIndex?: number | undefined, index?: number | undefined): void => {
        if (index !== undefined && picIndex !== undefined) {
            // console.log(name, value, picIndex,index)
            const iS = [...this.state.data.working_Sub_Sites]
            let record = { ...iS[index] };
            const pictures = record.pictures;
            pictures[picIndex] = { ...pictures[picIndex], [name]: value };
            record.pictures = pictures;
            iS[index] = record;
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites: iS
                }
            })
        } 
    };

    private HandlePictureRemove = (picIndex:number, index: number): void => {
        const selection = window.confirm(deleteText);

        if (selection) {
            const iS = [...this.state.data.working_Sub_Sites]
            let record = { ...iS[index] };
            const pictures = record.pictures;
            pictures.splice(picIndex, 1);
            record.pictures = pictures;
            iS[index] = record;
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites: iS
                }
            })
        }
    };

    private handlePictureUpload = async (event: any, x: ISubLocation, index:number): Promise<void> => { 
        const files = event;
        const iS = [...this.state.data.working_Sub_Sites];
        let record = { ...iS[index] };
        const newPictures = record.pictures;
        // console.log(newPictures);
        // console.log(record);
        if (files !== null) {    
            await Promise.all(Object.values(files).map(async (x: any) => {
                const imgId = uuidv4();
                const description = x.name;
                const base64String = await fileToBase64Image(x);
    
                newPictures.push({
                    name: imgId + "." + description.split(".")[1],
                    description: description.split(".")[0],
                    base64String,
                });
                record.pictures = newPictures;
                console.log(record)
                iS[index] = record;
                console.log(iS)
            }));
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    working_Sub_Sites: iS
                }
            });
        }
    };

    private getJobs = (): ILookupJobs[] => {
        if (hasPayload(this.props.getLookup) && hasPayload(this.props.locations)) {
            const list: ILookupJobs[] = [];
            const { nonAvaJobs } = this.state;

            this.props.getLookup.payload.response.jobs?.forEach((x:any, index:number) => {
                    if(nonAvaJobs.length > 0){
                        if(nonAvaJobs.includes(x.job_No) === false)
                        list.push({ id: index, name: x.name, job_No: x.job_No, client: x.client });
                    } else {
                        list.push({ id: index, name: x.name, job_No: x.job_No, client: x.client });
                    }
                });

            return list;
        };
        return [];
    };


    private onNoJobRequiredChange = (value: boolean): void => {
        let iS = { ...this.state.data };
        let no_job_required = iS.no_Job_Number_Required === "Yes" ? "Yes" : "No";
        if (value === true) {
            no_job_required = "Yes";
        }
        else {
            no_job_required = "No";
        }
        iS.no_Job_Number_Required = no_job_required;
        iS.job_No = "";
        this.setState({ data: iS });

      
    };

    private onRentalStatusChange = (value: boolean): void => {
        let iS = { ...this.state.data };
        let rental_Status = iS.rental_Status === "Yes" ? "Yes" : "No";
        if (value === true) {
            rental_Status = "Yes";
        }
        else {
            rental_Status = "No";
        }
        iS.rental_Status = rental_Status;

        this.setState({ data: iS });
    };

    private onDailyServiceTruckChange = (value: boolean): void => {
        let iS = { ...this.state.data };
        let daily_ST_Req = iS.daily_Service_Truck_Required === "Yes" ? "Yes" : "No";
        if (value === true) {
            daily_ST_Req = "Yes";
        }
        else {
            daily_ST_Req = "No";
        }
        iS.daily_Service_Truck_Required = daily_ST_Req;

        this.setState({ data: iS });
    };

    


    private onShowSubSiteOnMapChange = (value: boolean): void => {
        let iS = { ...this.state.data };
        let show_sub_site = iS.show_Sub_Sites_on_Map === "Yes" ? "Yes" : "No";
        if (value === true) {
            show_sub_site = "Yes";
        }
        else {
            show_sub_site = "No";
        }
        iS.show_Sub_Sites_on_Map = show_sub_site;

        this.setState({ data: iS });
    };

    private handleDropDownChange = (event: unknown, value: any, name?: string): void => {
        let errors = this.state.errors;

        if (name && (name === "job")) {

            let siteName = this.state.data.site_Name;

            if ((siteName === undefined || siteName === "") && this.state.data.id === 0) {
                const jobs = this.getJobs();
                let tempSiteName = jobs.find(item => item.job_No === value)?.name;
                errors = this.errorChecker("site_Name", value, errors);
                this.setState({
                    ...this.state,
                    errors,
                    data: {
                        ...this.state.data,
                        job_No: (value !== "" && value !== undefined) ? value : undefined,
                        site_Name: tempSiteName ? tempSiteName : ""
                    }
                });
            }
            else {
                this.setState({
                    ...this.state,
                    errors,
                    data: {
                        ...this.state.data,
                        job_No: (value !== "" && value !== undefined) ? value : undefined
                    }
                });
            }
        }
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        if (this.props.popUp && this.props.onPopupClose) {
            this.props.onPopupClose();
        } else {
            this.props.history.push({
                pathname: ROUTE.FIELD.LOCATION.INDEX,
                search: getTokenFromUrl(false)
            });
        };
    };

    private handleSave = async (): Promise<void> => {
        const { id, site_Address, site_Name, notes, active, job_No, no_Job_Number_Required, show_Sub_Sites_on_Map, working_Sub_Sites, created_By, daily_Service_Truck_Required,
            nearest_Medical_Facility, nearest_Medical_Facility_GPS
         } = this.state.data;

        if (hasPayload(this.props.token)) {

            const subLocations: IAddUpdateSubLocation[] = [];
            const duplicateArrayIndex: number[] = [];

            await working_Sub_Sites.forEach((x, ind) => {

                if (this.state.data.working_Sub_Sites.filter(q => q.sub_Site_Name === x.sub_Site_Name).length > 1)
                    duplicateArrayIndex.push(ind);

                subLocations.push({
                    ID: x.id,
                    Notes: x.notes,
                    Active: x.active,
                    Latitude: x.latitude,
                    Longitude: x.longitude,
                    Sub_Site_Name: x.sub_Site_Name,
                    Sub_Site_Address: x.sub_Site_Address,
                    Muster_Point_GPS: x.muster_Point_GPS,
                    Pictures: x.pictures,
                    Cost_Code_Billing: x.cost_Code_Billing,
                    Muster_Point: x.muster_Point
                });
            });

            const costCodeBillingCheck = subLocations.some(x => x.Cost_Code_Billing === "Yes");
            if(costCodeBillingCheck && !job_No) {
                alert("Job No is required if cost code billing is checked for one of the sub sites.")
            }
            else {
                if (duplicateArrayIndex.length === 0) {
                    if (id === 0) {
                        this.props.addLocationRequest({
                            token: this.props.token.payload.response.token,
                            request: {
                                Site_Name: site_Name,
                                Site_Address: site_Address,
                                Daily_Service_Truck_Required: daily_Service_Truck_Required,
                                Job_No: job_No,
                                No_Job_Number_Required: no_Job_Number_Required,
                                Show_Sub_Sites_on_Map: show_Sub_Sites_on_Map,
                                Notes: notes,
                                Nearest_Medical_Facility: nearest_Medical_Facility,
                                Nearest_Medical_Facility_GPS: nearest_Medical_Facility_GPS,
                                Active: active,
                                Created_By: this.props.token.payload.response.upn,
                                Modified_By: this.props.token.payload.response.upn,
                                Working_Sub_Sites: subLocations
                            }
                        });
                    } else {
                        // console.log(subLocations)
                        this.props.updateLocationRequest({
                            token: this.props.token.payload.response.token,
                            request: {
                                ID: id,
                                Site_Name: site_Name,
                                Site_Address: site_Address,
                                Daily_Service_Truck_Required: daily_Service_Truck_Required,
                                Job_No: job_No,
                                No_Job_Number_Required: no_Job_Number_Required,
                                Show_Sub_Sites_on_Map: show_Sub_Sites_on_Map,
                                Notes: notes,
                                Nearest_Medical_Facility: nearest_Medical_Facility,
                                Nearest_Medical_Facility_GPS: nearest_Medical_Facility_GPS,
                                Active: active,
                                Created_By: created_By,
                                Modified_By: this.props.token.payload.response.upn,
                                Working_Sub_Sites: subLocations
                            }
                        });
                    }
                }
                this.setState({ serverError: "", duplicateArrayIndex });
            }


        }
    };

    private handleGetCordinatesClick = async() : Promise<void> => {
        const { nearest_Medical_Facility } = this.state.data;
        if(nearest_Medical_Facility) {
            try {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        nearest_Medical_Facility
                    )}&key=${webConfig.googleMapKey}`
                );
                const data = await response.json();
                if (data.status === 'OK') {
                    const location = data.results[0].geometry.location;
                    // nearest_Medical_Facility_GPS = location.lat + "," + location.lng;
                    this.setState({
                        ...this.state,
                        data: {
                            ...this.state.data,
                            nearest_Medical_Facility_GPS : location.lat + "," + location.lng
                        },
                        mapNearestMedicalFacility: true
                    });
                    // setCoords({ lat: location.lat, lng: location.lng });
                    // props.onChange(location.lat, location.lng);
                } else {
                    alert('Address not found. Please try again.');
                }
            } catch (error) {
                alert('Error fetching coordinates. Please try again.');
            }
        }
        this.setState({
            ...this.state,
            mapNearestMedicalFacility: true
        });
    }

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private setDataToState = async (): Promise<void> => {
        const query: any = (this.props.popUp && this.props.id) ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.token)) {
                if (pageAccessCheck(this.props.token, "locationList") !== NotApplicable) {

                    if(isNotLoaded(this.props.locations)){
                        this.props.getLocationsRequest({
                            token: this.props.token.payload.response.token
                        });
                    };

                    if (query.id !== "0") {
                        if (hasPayload(this.props.locations)) {
                            const data = this.props.locations.payload.response[query.id];

                            await this.setNonAvaJobs(data.job_No);

                            await this.setState({
                                data
                            });
                        }
                    } else {
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });
                        
                        await this.setNonAvaJobs();

                        await this.setState({ 
                            ...this.state,
                            data: {
                                ...this.state.data,
                                working_Sub_Sites: [{
                                    id: 0,
                                    itemID: 0,
                                    modified: "",
                                    created: "",
                                    created_By: this.props.token.payload.response.upn,
                                    modified_By: this.props.token.payload.response.upn,
                                    active: YesOrNo[0].name,
                                    sub_Site_Name: "NA",
                                    sub_Site_Address: "NA",
                                    notes: "",
                                    working_Site_ID: this.state.data.id,
                                    latitude: "",
                                    longitude: "",
                                    whiteboard_ID: 0,
                                    sT_ID: "",
                                    muster_Point_GPS: "",
                                    pictures: [],
                                    cost_Code_Billing: "No",
                                    muster_Point: ""
                                }]
                            },
                            errors
                        });
                    };

                    if (isNotLoaded(this.props.getLookup)) {
                        this.callGetLookup();
                    };
                } else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private callGetLookup = (): void => {
        if(hasPayload(this.props.token))
            this.props.getLookupsRequest({
                token: this.props.token.payload.response.token,
                request: {
                    page: "Jobs"
                }
            });
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

    private setNonAvaJobs = (current?: string): void => {
        if(hasPayload(this.props.locations)){
            const nonAvaJobs = Object.values(this.props.locations.payload.response)
                                .filter((x) => x.job_No !== null)
                                .map(({ job_No }) => job_No);

            if(current){
                const index = nonAvaJobs.findIndex((x) => x === current);
                if(index >= 0)
                nonAvaJobs.splice(index, 1);
            }
            this.setState({ nonAvaJobs });
        };
    };

}

const mapStateToProps = (state: IStore): IAddUpdateLocationComponentStoreProps => ({
    locations: getLocations(state),
    token: getToken(state),
    getLookup: getFieldLookups(state),
    addLocationStatus: addLocation(state),
    updateLocationStatus: updateLocation(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateLocationComponentDispatchProps => ({
    getLocationsRequest: (data: ILocationRequest) => dispatch(getLocationLoadAction(data)),
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addLocationRequest: (data: IAddLocationRequest) => dispatch(addLocationLoadAction(data)),
    updateLocationRequest: (data: IUpdateLocationRequest) => dispatch(updateLocationLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateLocation);


interface ISubWorkingLocationTable {
    disabled?: true;
    onAdd: () => void;
    data: ISubLocation[];
    duplicateArrayIndex: number[];
    onMapClick: (index: number) => void;
    onMusterMapClick: (index: number) => void;
    handleChange: (name: string, value: string, index?: number | undefined) => void;
    handlePictureUpload: (event:any, x: ISubLocation, index: number ) => void;
    handlePictureDescription: (name: string, value: string, picIndex?: number | undefined, index?: number | undefined) => void;
    HandlePictureRemove: (picIndex: number, index: number) => void;
    handleJournals: (wssID: number) => void;
};

const SubWorkingLocationTable: React.FC<ISubWorkingLocationTable> = React.memo((props: ISubWorkingLocationTable) => (
    <TableContainer component={LAPaperWithLessPadding}>

        <LAIconButton
            label="Add New"
            icon={<AddIcon />}
            onClick={props.onAdd}
        />

        {(props.duplicateArrayIndex.length > ZEROTH) &&
            <LAErrorBox text="We found some duplicate records below. Please fix it before save." />}

        <Table size="small" aria-label="Service Items">

            <TableHead className="header-row">
                <TableRow>
                    <TableCell className="title">Sub Site Name</TableCell>
                    <TableCell className="title">Sub Site Address</TableCell>
                    <TableCell className="title">Latitude</TableCell>
                    <TableCell className="title">Longitude</TableCell>
                    <TableCell className="title">Active</TableCell>
                    <TableCell className="title">Notes</TableCell>
                    <TableCell className="title">Service Truck</TableCell>
                    <TableCell className="title">Muster Point</TableCell>
                    <TableCell className="title">Cost Code Required for Billing</TableCell>
                </TableRow>
            </TableHead>

            {props.data.map((x: ISubLocation, index: number) => {

                const onMapClick = (): void => props.onMapClick(index);
                const onMusterMapClick = (): void => props.onMusterMapClick(index);
                const onActive = (checked: boolean): void => props.handleChange("active", checked === true ? "Yes" : "No", index);
                const onCostCodeBilling = (checked: boolean): void => props.handleChange("cost_Code_Billing", checked === true ? "Yes" : "No", index);
                const onResetGPS = (): void => props.handleChange("resetGPS","Yes",index);
                const OnResetMusterGPS = (): void => props.handleChange("resetMusterGPS","Yes",index);
                const onPictureUpload = (event:any): void => props.handlePictureUpload(event, x, index);
                const pictures = x.pictures ? x.pictures : [];
                const handleJournalsClick = () : void => props.handleJournals(x.id);
                // console.log(x)

                return <><TableBody key={index} className={props.duplicateArrayIndex.includes(index) ? "warning-row" : index % 2 ? "odd-row" : "even-row"}>
                    <TableRow >

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                fullWidth={true}
                                variant="outlined"
                                indexPosition={index}
                                name="sub_Site_Name"
                                label="Sub Site Name"
                                disabled={props.disabled}
                                value={x.sub_Site_Name}
                                onChange={props.handleChange}
                                errorText={(x.sub_Site_Name === null || x.sub_Site_Name.length === 0) 
                                    ? FIELD_VALIDATOR_ERRORS.REQUIRED : undefined}
                            />
                            <LALinkButton
                                label="Journals"
                                onClick={handleJournalsClick}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                fullWidth={true}
                                variant="outlined"
                                indexPosition={index}
                                name="sub_Site_Address"
                                label="Sub Site Address"
                                disabled={props.disabled}
                                value={x.sub_Site_Address}
                                onChange={props.handleChange}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                fullWidth={true}
                                variant="outlined"
                                indexPosition={index}
                                name="latitude"
                                label="Latitude"
                                disabled={props.disabled}
                                value={x.latitude}
                                onChange={props.handleChange}
                            />

                            <LALinkButton
                                label="Open Map"
                                onClick={onMapClick}
                            />
                            <LALinkButton
                            className="left-Reset"
                                label="reset"
                                onClick={onResetGPS}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                fullWidth={true}
                                variant="outlined"
                                indexPosition={index}
                                name="longitude"
                                label="Longitude"
                                disabled={props.disabled}
                                value={x.longitude}
                                onChange={props.handleChange}
                            />
                        </TableCell>

                        <TableCell width={40}>
                            <LACheckBox
                                label="Active"
                                name="active"
                                disabled={props.disabled}
                                onChange={onActive}
                                value={x.active === "Yes" ? true : false}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                fullWidth={true}
                                variant="outlined"
                                indexPosition={index}
                                name="notes"
                                label="Notes"
                                disabled={props.disabled}
                                value={x.notes}
                                onChange={props.handleChange}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                disabled={true}
                                fullWidth={true}
                                variant="outlined"
                                name="service_Truck"
                                label="Service Truck"
                                indexPosition={index}
                                onChange={undefinedFunction}
                                value={x.service_Truck ?? ""}
                            />
                        </TableCell>

                        <TableCell>
                            <LATextArea
                                rowsMax={1}
                                disabled={true}
                                fullWidth={true}
                                variant="outlined"
                                label="Muster Point"
                                indexPosition={index}
                                name="muster_Point_GPS"
                                onChange={undefinedFunction}
                                value={x.muster_Point_GPS ?? ""}
                            />

                            <LALinkButton
                                label="Open Map"
                                onClick={onMusterMapClick}
                            />
                            <LALinkButton
                            className="left-Reset"
                                label="reset"
                                onClick={OnResetMusterGPS}
                            />
                        </TableCell>

                        <TableCell width={40}>
                            <LACheckBox
                                label=""
                                name="cost_Code_Billing"
                                onChange={onCostCodeBilling}
                                disabled={props.disabled}
                                value={x.cost_Code_Billing === "Yes" ? true : false}
                            />
                        </TableCell>

                    </TableRow>
                    
                    <TableRow> 
                        <TableCell colSpan={12}>
                            <LAGridItem xs={12} className="text-center"> 
                                <LAGrid>
                                        <LAGridItem xs={12} sm={3}>
                                            <LATextArea
                                                rowsMax={1}
                                                fullWidth={true}
                                                variant="outlined"
                                                indexPosition={index}
                                                name="muster_Point"
                                                label="Muster Point"
                                                disabled={props.disabled}
                                                value={x.muster_Point}
                                                onChange={props.handleChange}
                                            />
                                        </LAGridItem>
                                        <LAGridItem xs={12} sm={9}>
                                            <strong>Upload Images</strong>
                                            <LADropZone onFileDrop={onPictureUpload} />
                                        </LAGridItem>
                                        <br />

                                        {pictures.length > 0 &&  pictures.map((x, picIndex) => {
                                            // console.log(pictures)
                                            const onPictureDelete = (): void => props.HandlePictureRemove(picIndex,index);
                                            const onChangePicDescription = (name: string, value: string): void => props.handlePictureDescription(name, value,picIndex, index);
                                            const base64 = x.base64String !== null ? x.base64String : webConfig.imgApiBaseUrl + x.name;
                                            return <LAGridItem key={picIndex} xs={12} md={2}> 
                                                <LAThumbnailWithLink
                                                    className="thumbnail-img"
                                                    alt={x.name}
                                                    key={picIndex}
                                                    url={base64}
                                                />
                                                <LATextField
                                                    className="mt-2"
                                                    label="Description"
                                                    variant="outlined"
                                                    name="description"
                                                    value={x.description}
                                                    onChange={onChangePicDescription}
                                                    errorText={
                                                        x.description.length === 0 ?
                                                            FIELD_VALIDATOR_ERRORS.REQUIRED
                                                            : undefined
                                                    }
                                                />
                                                {x.name && <LAIconButton
                                                    label="Remove"
                                                    onClick={onPictureDelete}
                                                    icon={<DeleteIcon />}
                                                />}
                                            </LAGridItem>
                                        })}
                                        {/* </LAGridItem> */}
                                </LAGrid>
                            </LAGridItem>
                        </TableCell>
                    </TableRow>
                </TableBody>
                </>
            })}
        </Table>
    </TableContainer>
));


interface IGPSPopupProps {
    lat: string | null;
    long: string | null;
    open: boolean;
    onCancel: () => void;
    onChange: (lat: number, long: number) => void;
};

const GPSPopupStyles = styled(LAPaperWithPadding)`
    .popup-div {
        width: 20px;
        margin: auto;
    };
`;

export const GPSPopup: React.FC<IGPSPopupProps> = React.memo((props: IGPSPopupProps) => {

    const onCancel = () => props.onCancel();

    const handleMapClick = async(event: any) => {
        // console.log(event)
        props.onChange(event.lat, event.lng);
    };

    return <LAPopover anchorRef={null} open={props.open} onClose={onCancel}>
        <GPSPopupStyles>
            <LAGrid spacing={3} className="text-center">
                <LAGridItem xs={12}>
                    <div style={{ height: (window.screen.height - 300), width: (window.screen.width - 100) }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: webConfig.googleMapKey }}
                            defaultCenter={
                                (props.lat === null || props.long === null || props.lat === undefined || props.long === undefined || props.lat === '' || props.long === '')
                                ? {lat: 53.5280348, lng: -113.3612641}
                                : { lat: +props.lat, lng: +props.long }}
                                options={map => ({
                                    scrollwheel: true,
                                    mapTypeId: map.MapTypeId.HYBRID,
                                    
                                })}
                            defaultZoom={defaultMapProps.zoom}

                            onClick={handleMapClick}
                        >
                            <Marker
                                lat={props.lat !== null ? +props.lat : ""}
                                lng={props.long !== null ? +props.long  : ""}
                            />
                        </GoogleMapReact>
                    </div>
                </LAGridItem>

            </LAGrid>
        </GPSPopupStyles>
    </LAPopover>
});


const Marker = (props: any) => (
    <LocationPinIcon width={40} height={40} color={RED_COLOR} />
);