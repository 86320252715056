import { IADD_EQUIPMENT_TYPE_REQUEST } from "./addEquipmentTypeConstants";
import { IAddEquipmentTypeLoadAction, IAddEquipmentTypeLoadFailedAction, IAddEquipmentTypeSuccessAction } from "./addEquipmentTypeActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../server";

type Actions =
    | IAddEquipmentTypeLoadAction
    | IAddEquipmentTypeSuccessAction
    | IAddEquipmentTypeLoadFailedAction
    | IFlushDataSuccessAction;

export const AddEquipmentTypeReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IADD_EQUIPMENT_TYPE_REQUEST.REQUEST:
            return loading;

        case IADD_EQUIPMENT_TYPE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IADD_EQUIPMENT_TYPE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};