import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_VENDOR_LOOKUPS_REQUEST, IVendorLookup } from "./getVendorLookupsConstants";
import { IGetVendorLookupsLoadAction, IGetVendorLookupsLoadFailedAction, IGetVendorLookupsSuccessAction,
    getVendorLookupsLoadFailedAction, getVendorLookupsLoadSuccessAction } from "./getVendorLookupsActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";

export const getVendorLookupsEpic: Epic = (
    action$: ActionsObservable<IGetVendorLookupsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetVendorLookupsSuccessAction | IGetVendorLookupsLoadFailedAction> =>
    action$.ofType(IGET_VENDOR_LOOKUPS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IVendorLookup>>(
                    END_POINTS.FIELD.VENDOR.GET_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IVendorLookup>): IGetVendorLookupsSuccessAction => {
                            return getVendorLookupsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getVendorLookupsLoadFailedAction(response.message)))
                    )
            )
        );