import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IREJECT_ST_TIMESHEET_REQUEST, IRejectSTTimesheetRequest } from "./rejectSTTimesheetConstants";

export interface IRejectSTTimesheetLoadAction {
    type: IREJECT_ST_TIMESHEET_REQUEST.REQUEST;
    data: IRejectSTTimesheetRequest
}
export const rejectSTTimesheetLoadAction = (data: IRejectSTTimesheetRequest): IRejectSTTimesheetLoadAction => ({
    type: IREJECT_ST_TIMESHEET_REQUEST.REQUEST,
    data
});
export interface IRejectSTTimesheetSuccessAction {
    type: IREJECT_ST_TIMESHEET_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const rejectSTTimesheetLoadSuccessAction = (message: SurewayAPIResponse<string>): IRejectSTTimesheetSuccessAction => ({
    type: IREJECT_ST_TIMESHEET_REQUEST.SUCCESS,
    message
});
export interface IRejectSTTimesheetLoadFailedAction {
    type: IREJECT_ST_TIMESHEET_REQUEST.FAILED;
    message: string;
}
export const rejectSTTimesheetLoadFailedAction = (message: string): IRejectSTTimesheetLoadFailedAction => ({
    type: IREJECT_ST_TIMESHEET_REQUEST.FAILED,
    message
});
