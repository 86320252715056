import React, { useState } from 'react';
import { Tabs, Tab, AppBar, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { ROUTE } from '../routes';

interface ITabItem {
    name: string;
    label: string;
    url: string;
}

interface ITabNavbarProps {
    tabs: ITabItem[];
    handleTabClick: (event: any, url: string) => void;
}

const TabNavbarStyles = styled.div`
    .tab {
        color: black;
        font-weight: 800;
        margin: 0 10px;
        &.Mui-selected {
            color: green;
        }
    }
    .MuiTabs-indicator {
        background-color: green;
    }
    .appBar {
        background-color: #ccc; 
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    }
    .menuButton {
        display: none;
    }
    .drawerList {
        width: 250px;
    }
    @media (max-width: 600px) {
        .tabs {
            display: none;
        }
        .menuButton {
            display: block;
            color: black;
        }
    }
`;

export const SOCTTabNavbarMenu: ITabItem[] = [
    { name: "soct", label: "Sureway Oil Change Tracker", url: ROUTE.FIELD.SOCT.ADD_UPDATE_SOCT },
    { name: "service_History", label: "Service History", url: ROUTE.FIELD.SOCT.SERVICE_HISTORY.INDEX },
    { name: "meter_History", label: "Meter History", url: ROUTE.FIELD.SOCT.METER_HISTORY.INDEX },
    { name: "unit_History", label: "Unit History", url: ROUTE.FIELD.EQUIPMENT.EQUIPMENT_HISTORY },
];

export const TabNavbar: React.FC<ITabNavbarProps> = React.memo((props: ITabNavbarProps) => {
    const location = useLocation();
    const history = useHistory();
    const [drawerOpen, setDrawerOpen] = useState(false);

    // const handleTabClick = (event: any, newValue: string) => {
    //     history.push(newValue);
    // };

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    return (
        <TabNavbarStyles>
            <AppBar position="static" className="appBar">
                <IconButton
                    edge="start"
                    className="menuButton"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                >
                    <MenuIcon />
                </IconButton>
                <Tabs
                    value={location.pathname}
                    onChange={props.handleTabClick}
                    textColor="inherit"
                    indicatorColor="primary"
                    aria-label="navigation tabs"
                    className="tabs"
                >
                    {props.tabs.map((tab) => (
                        <Tab key={tab.label} label={tab.label} value={tab.url} className="tab" />
                    ))}
                </Tabs>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <div className="drawerList" role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List>
                        {props.tabs.map((tab) => (
                            <ListItem button key={tab.name} onClick={(event) => props.handleTabClick(event, tab.url)}>
                                <ListItemText primary={tab.label} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </TabNavbarStyles>
    );
});
