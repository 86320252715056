import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST {
    REQUEST = "getSOCTCustomServiceItemsBySOCTID/GET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_REQUEST",
    SUCCESS = "getSOCTCustomServiceItemsBySOCTID/GET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_SUCCESS",
    FAILED = "getSOCTCustomServiceItemsBySOCTID/GET_SOCT_CUSTOM_SERVICE_ITEMS_BY_SOCT_ID_FAILED"
};

export interface IGetSOCTCustomServiceItemsBySOCTIDRequest extends ISurewayTokenRequestBody {
    request: {
        soct_id : number
    };
};


export interface ISOCTCustomServiceItemsBySOCTID {
    id: number;
    name: string;
};
