export enum LOCAL_STORAGE_TOKEN {
    REQUEST =  "localStorageToken/LOCAL_STORAGE_TOKEN_REQUEST",
    SUCCESS =  "localStorageToken/LOCAL_STORAGE_TOKEN_SUCCESS",
    FAILED =  "localStorageToken/LOCAL_STORAGE_TOKEN_FAILED"
}

export interface ILocalStorageToken {
    token: string;
    email: string;
    storedTime: string;
};

export const TokenStoreName = "token";

export const saveToken = (token: string, email: string): void => {
    const tokenObj: ILocalStorageToken = {
        email,
        token,
        storedTime: new Date().toString()
    };
    localStorage.setItem(TokenStoreName, JSON.stringify(tokenObj));
};

export const getStoredToken = (): ILocalStorageToken | null => {
    const obj = localStorage.getItem(TokenStoreName);

    if (obj !== null) {
        const data: ILocalStorageToken = JSON.parse(obj);
        const currentD = new Date();
        const storedD = new Date(data.storedTime);
        const difference = Math.abs(currentD.getTime() - storedD.getTime());
        var hourDifference = difference / 1000 / 3600;

        if (hourDifference > 8) {
            localStorage.clear();
            return null;
        } else {
            return data;
        }
    } else {
        return null;
    };
};