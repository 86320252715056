import React from "react";
import { ById } from "../../shared/publicInterfaces";
import LAGridItem from "../../shared/gridList";
import { LAPaperWithLessPadding } from "../../shared/paper";
import LAGrid from "../../shared/grid";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DropPaper } from "../../shared/dragAndDropPaper";
import LAErrorBox from "../../shared/errorBox";
import { BLUE_COLOR, DARK_GREY_COLOR, GREEN_COLOR, LIGHT_BLUE_COLOR, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../shared/theme";
import { IMoveSelection } from "./driverDispatch";
import { IDriver } from "../../../redux/field/getDrivers/getDriversConstants";
import { IMoveRequest } from "../../../redux/field/getMoveRequests/getMoveRequestsConstants";
import { LAIconButton } from "../../shared/buttons";
import { CommunicationLogIcon, DeleteIcon, EmailIcon, PhoneIcon, PlaneIcon } from "../../shared/icons";
import { getDate, undefinedFunction } from "../../shared/constExports";
import LATextArea from "../../shared/textArea";
import LATextField from "../../shared/textField";
import { ITrailerConfig } from "../../../redux/field/equipmentTracker/getETLookups/getETLookupsConstants";
import LAAutoComplete from "../../shared/autoComplete";
import { ROUTE } from "../../routes";
import { LACheckBox } from "../../shared/checkBox";

interface IDispatchMove {
    moveSearch: string;
    drivers: ById<IDriver>;
    trailerList: ITrailerConfig[];
    moveSelection: IMoveSelection;
    data: IMoveRequest | undefined;
    onDelete: (id: number) => void;
    onConfigRemove: (id: number) => void;
    onPilotDelete: (pilotId: number, id: number) => void;
    onChange: (name: string, value: string, id: number) => void;
    getPartsDispatchRole: string | boolean;
    // initialMoveRequests: ById<IMoveRequest>;
};

export const DispatchMove = React.memo((props: IDispatchMove): JSX.Element => {

    const config = props.data && props.data.trailer_Config_ID && props.trailerList ? props.trailerList.find(q => props.data && q.trailer_Rate_ID === props.data.trailer_Config_ID) : null;
    const handleTrailer = (event: unknown, value: ITrailerConfig) => props.data ? value === null ?
        props.onConfigRemove(props.data.id) : props.onChange("trailer_Config_ID", value.trailer_Rate_ID.toString(), props.data.id)
        : undefinedFunction;
        // console.log(props.data)

    return (
        (props.data === undefined)
            ? <></>
            : (((props.data.unit_Number && props.moveSearch.length > 0) ? props.data.unit_Number.toLowerCase().includes(props.moveSearch.toLowerCase()) : true))
                ? <LAGridItem key={props.data.id} xs={12} sm={6} md={3} className="">
                    <LAPaperWithLessPadding>
                        <div style={{ background: (props.data.site_Status === "Rained/Snowed out" || props.data.site_Status === "Weather Standby") ? `url(${process.env.PUBLIC_URL + '/img/giphy.webp'})` : undefined }}>
                        <MoveInfo
                            move={props.data}
                            getPartsDispatchRole={props.getPartsDispatchRole}
                            onChange={props.onChange}
                        />

                        <Droppable key={props.data.id} droppableId={props.data.id.toString()} 
                            isDropDisabled={props.data.driver_ID ? true : 
                                props.getPartsDispatchRole !== true && props.data.move_Type === "Parts" ? true : 
                                props.getPartsDispatchRole === true && props.data.move_Type === "Trucking" ? true : 
                                false
                            }
                        >
                            {(provided, snapshot) => {
                                const onDelete = () => props.data ? props.onDelete(props.data.id) : undefinedFunction;
                                const onChange = (name: string, value: string) => props.data ? props.onChange(name, value, props.data.id) : undefinedFunction;

                                return (
                                    <>
                                        {<DropPaper
                                            className="cursor text-center"
                                            innerRef={provided.innerRef}
                                            key={props.data ? props.data.id : 0}
                                            styleDraggar={snapshot.isDraggingOver}
                                        >
                                            {(props.data && props.data.driver_ID) ?
                                                <>
                                                    <DriverInfo
                                                        assigned={{}}
                                                        unAssigned={{}}
                                                        leftSide={true}
                                                        driver={props.drivers[props.data.driver_ID]}
                                                    />

                                                    <LATextArea
                                                        minRows={1}
                                                        rowsMax={9}
                                                        label="Notes"
                                                        disabled={
                                                            props.getPartsDispatchRole !== true && props.data.move_Type === "Parts" ? true : 
                                                            props.getPartsDispatchRole === true && props.data.move_Type === "Trucking" ? true : 
                                                            undefined
                                                        }
                                                        onChange={onChange}
                                                        name="dispatch_Notes"
                                                        value={props.data.dispatch_Notes}
                                                    />

                                                    <LAGrid className="text-center">
                                                        <LAGridItem xs={9}>
                                                            <LATextField
                                                                type="number"
                                                                label="Priority"
                                                                disabled={
                                                                    props.getPartsDispatchRole !== true && props.data.move_Type === "Parts" ? true : 
                                                                    props.getPartsDispatchRole === true && props.data.move_Type === "Trucking" ? true : 
                                                                    undefined
                                                                }
                                                                onChange={onChange}
                                                                name="dispatch_Priority"
                                                                value={props.data.dispatch_Priority ?? ""}
                                                            />
                                                        </LAGridItem>

                                                        <LAGridItem xs={3}>
                                                            <LAIconButton
                                                                label="Delete"
                                                                disabled={
                                                                    props.getPartsDispatchRole !== true && props.data.move_Type === "Parts" ? true : 
                                                                    props.getPartsDispatchRole === true && props.data.move_Type === "Trucking" ? true : 
                                                                    undefined
                                                                }
                                                                onClick={onDelete}
                                                                icon={<DeleteIcon color={RED_COLOR} />}
                                                            />
                                                        </LAGridItem>
                                                    </LAGrid>
                                                </>
                                                : <LAErrorBox
                                                    className="text-center"
                                                    text="Driver Not Assigned"
                                                    height={1}
                                                />}
                                        </DropPaper>}
                                    </>
                                );
                            }}
                        </Droppable>


                        {props.getPartsDispatchRole !== true && props.data.move_Type === "Trucking" && <Droppable key={props.data.id} droppableId={"pilot_" + props.data.id.toString()}>
                            {(provided, snapshot) => {
                                const pilots: number[] = props.data && props.data.pilot_Ids ? props.data.pilot_Ids.split(",").map(Number) : [];
                                return (
                                    <>
                                        {<DropPaper
                                            className="cursor text-center"
                                            innerRef={provided.innerRef}
                                            styleDraggar={snapshot.isDraggingOver}
                                            key={props.data ? "pilot_" + props.data.id : 0}
                                        >
                                            {(pilots.length > 0) ?
                                                <>
                                                    <strong>PILOT {((props.data) && (props.data.pilot !== 0)) ? `(${pilots.length} of ${props.data.pilot})` : ""}</strong>

                                                    {pilots.map((q, ind) => {
                                                        const onPilotDelete = () => props.data ? props.onPilotDelete(q, props.data.id) : undefinedFunction;
                                                        return (
                                                            <div key={ind}>
                                                                <DriverInfo
                                                                    assigned={{}}
                                                                    unAssigned={{}}
                                                                    leftSide={true}
                                                                    onDelete={onPilotDelete}
                                                                    driver={props.drivers[q]}
                                                                />

                                                                <hr />
                                                            </div>
                                                        );
                                                    })}
                                                </>
                                                : <LAErrorBox
                                                    className="text-center"
                                                    text={`Pilot Not Assigned `}
                                                    color={LIGHT_BLUE_COLOR}
                                                    height={1}
                                                    pilot={props.data?.pilot}
                                                />}
                                        </DropPaper>}
                                    </>
                                );
                            }}
                        </Droppable>}

                        {props.getPartsDispatchRole !== true && props.data.move_Type === "Trucking" && <LAAutoComplete
                            size="small"
                            value={config}
                            disabled={undefined}
                            multiple={false}
                            autoHighlight={true}
                            defaultValue={config}
                            onChange={handleTrailer}
                            option={props.trailerList?.sort((a:any, b:any) => a.description > b.description ? 1 : -1)}
                            getOptionLabel="description"
                            filterSelectedOptions={true}
                            dropDownPlaceHolder="Trailer Config"
                            selectionRemove={undefinedFunction}
                        />}

                        </div>
                    </LAPaperWithLessPadding>
                </LAGridItem>
                : <></>
    )
});

interface IDriverProps {
    index: number;
    data: IDriver;
    assigned: ById<IMoveRequest>;
    unAssigned: ById<IMoveRequest>;
    onLogClick?: (driverId: number) => void;
};

export const Driver: React.FC<IDriverProps> = React.memo((props: IDriverProps) =>
    <LAGridItem key={props.data.id} xs={12} sm={6} md={4}>
        <Draggable
            key={props.data.id}
            draggableId={"driver," + props.data.id.toString()}
            index={props.index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={dragItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}>

                    <DriverInfo
                        driver={props.data}
                        assigned={props.assigned}
                        unAssigned={props.unAssigned}
                        onLogClick={props.onLogClick}
                    />
                </div>
            )}
        </Draggable>
    </LAGridItem>
);

const isDriverAssigned = (driver: IDriver, assigned: ById<IMoveRequest>, unAssigned: ById<IMoveRequest>): string | undefined => {
    const assignedUnits = [];
    const unAssignedUnits = [];

    for (const id in assigned) {
        if (assigned[id].driver_ID === driver.id && assigned[id].unit_Number) {
            assignedUnits.push(assigned[id].unit_Number);
        }
    }

    for (const id in unAssigned) {
        if (unAssigned[id].driver_ID === driver.id && unAssigned[id].unit_Number) {
            unAssignedUnits.push(unAssigned[id].unit_Number);
        }
    }

    const assignedUnitsStr = assignedUnits.join(', ');
    const unAssignedUnitsStr = unAssignedUnits.join(', ');

    if (assignedUnitsStr && unAssignedUnitsStr) {
        return assignedUnitsStr + ', ' + unAssignedUnitsStr;
    } else if (assignedUnitsStr) {
        return assignedUnitsStr;
    } else if (unAssignedUnitsStr) {
        return unAssignedUnitsStr;
    } else {
        return undefined;
    }
};


export const dragItemStyle = (isDragging: any, draggableStyle: any, borderColor?: string) => ({
    padding: 8,
    margin: 3,
    userSelect: "none",
    borderRadius: "5px",
    wordBreak: "break-word",
    background: isDragging ? WHITE_COLOR : WHITE_COLOR,
    border: `2px solid ${borderColor ?? DARK_GREY_COLOR}`,
    ...draggableStyle
});

interface IDriverInfo {
    driver: IDriver;
    leftSide?: true;
    onDelete?: () => void;
    assigned: ById<IMoveRequest>;
    unAssigned: ById<IMoveRequest>;
    onLogClick?: (driverId: number) => void;
};

export const DriverInfo: React.FC<IDriverInfo> = React.memo((props: IDriverInfo) => {
    const assigned = isDriverAssigned(props.driver, props.assigned, props.unAssigned);
    const onLog = (): void => props.onLogClick ? props.onLogClick(props.driver.id) : undefined;
    // console.log(props.driver)
    return <LAGrid className={(assigned) ? "green-border text-center" : "text-center"} spacing={1}>

        <LAGridItem xs={12}>
            {props.leftSide && <strong>DRIVER: </strong>}
            <span>{props.driver.driver_Firstname + " " + props.driver.driver_Lastname}</span>

            {props.onDelete && <LAIconButton
                label="Delete"
                onClick={props.onDelete}
                icon={<DeleteIcon color={RED_COLOR} width={20} height={20} />}
            />}

            {!props.leftSide && <>
                <br />

                <EmailIcon height={15} width={15}
                    color={(props.driver.email !== null && props.driver.email !== "" && props.driver.email !== undefined) ? GREEN_COLOR : WARNING_COLOR}
                />

                <PhoneIcon height={15} width={15}
                    color={(props.driver.phone_Number !== null && props.driver.phone_Number !== "" && props.driver.phone_Number !== undefined) ? GREEN_COLOR : WARNING_COLOR}
                />

                {(props.driver.pilot_Driver !== null && props.driver.pilot_Driver !== undefined && props.driver.pilot_Driver === "Yes") &&
                    <PlaneIcon height={15} width={15} color={BLUE_COLOR}
                    />}

                {props.driver.smS_Logs && <CommunicationLogIcon height={25} width={25} onClick={onLog} color={GREEN_COLOR} />}
            </>}
        </LAGridItem>

        {assigned && <LAGridItem xs={12}>
            <strong>Units: </strong>
            <span>{assigned}</span>
        </LAGridItem>}

    </LAGrid>
});

interface IMoveInfo {
    move: IMoveRequest;
    getPartsDispatchRole: string | boolean;
    onChange: (name: string, value: string, id: number) => void;
};

export const MoveInfo: React.FC<IMoveInfo> = (props: IMoveInfo) => {
    const onFromMobDemob = (checked: boolean) => props.move ? props.onChange("from_Demob", checked ? "Yes" : "No", props.move.id) : undefinedFunction;
    const onToMobDemob = (checked: boolean) => props.move ? props.onChange("to_Mob", checked ? "Yes" : "No", props.move.id) : undefinedFunction;
    const onRepairNotes = (name: string, value: string) => props.move ? props.onChange("repair_Notes", value, props.move.id) : undefinedFunction;
    // console.log(props.move)

    return <LAGrid className="text-center">
        <strong style={{fontSize: '10px'}}>{props.move.move_Type === "Trucking" ? "Trucking" : "Parts"}</strong>

        {!(props.getPartsDispatchRole === true) && props.move.pilot > 0 && props.move.pilot_Ids.split(",").map(Number).length !== props.move.pilot && 
            <LAGridItem xs={12}>
                <span className="warning-text">{`Require/s ${props.move.pilot} Pilot${props.move.pilot > 1 ? 's' : ''}`}</span>
            </LAGridItem>
        }

        {props.move.eT_Second_Trip === "Yes" && <LAGridItem xs={12}>
            <span className="warning-text">{`Require/s Two ET Moves`}</span>
        </LAGridItem>}
        {props.move.mob_Demob === "Yes" && <LAGridItem xs={12}>
            <span className="warning-text">{`Requires Mob Demob`}</span>
        </LAGridItem>}

        <LAGridItem xs={12}>
            <a href={`${ROUTE.FIELD.EQUIPMENT_TRACKER.TRUCKING.MOVEREQUESTVIEWUPDATE}${props.move.id}`} target='_blank' style={{ color: 'blue', textDecoration: 'underline' }} rel="noreferrer"><strong>
                {props.move.unit_Number} {props.move.make !== null ? `- ${props.move.make}` : ""} {props.move.model !== null ? `- ${props.move.model}` : ""}
            </strong> </a>
        </LAGridItem>

        <LAGridItem xs={12}>
            <strong className="green-text">{props.move.from_Location_Info}</strong>
            {/* {props.move.mob_Demob === "Yes" &&  <LACheckBox
                value={props.move.from_Mob_Demob === "Yes" ? true : false}
                label="From Mob/Demob"
                disabled={undefined}
                onChange={onFromMobDemob}
            />} */}
        </LAGridItem>

        <LAGridItem xs={12}>
            <span className="red-text">{props.move.to_Location_Info}</span>
            {/* {props.move.mob_Demob === "Yes" && <LACheckBox
                value={props.move.to_Mob_Demob === "Yes" ? true : false}
                label="To Mob/Demob"
                disabled={undefined}
                onChange={onToMobDemob}
            />} */}
        </LAGridItem>
        {/* {props.move.status === "Dispatched" && */}
            <LAGridItem xs={6}>
                <span className="green-text">{props.move.status}</span>
            </LAGridItem>
        {/* } */}
        <LAGridItem xs={6}>
            <span>{getDate(false, props.move.completed_Date)}</span>
        </LAGridItem>

        {props.move.ready_Any_Time === "Yes" && <LAGridItem xs={12}>
            <span className="green-text">Ready Any Time</span>
        </LAGridItem>}
        {props.move.mob_Demob === "Yes" && props.move.move_Type === "Trucking" && <>
            {/* <LAGrid> */}
                <LAGridItem xs={6}>
                    <LACheckBox
                        value={props.move.from_Demob === "Yes" ? true : false}
                        label="From Demob"
                        disabled={props.move.initial_From_Demob === "Yes" ? true : undefined}
                        onChange={onFromMobDemob}
                    />
                </LAGridItem>
                <LAGridItem xs={6}>
                    <LACheckBox
                        value={props.move.to_Mob === "Yes" ? true : false}
                        label="To Mob"
                        disabled={props.move.initial_To_Mob === "Yes" ? true : undefined}
                        onChange={onToMobDemob}
                    />
                </LAGridItem>
                <LAGridItem xs={12}>
                    <LATextArea
                        minRows={1}
                        rowsMax={3}
                        fullWidth={true}
                        label="Repair Notes"
                        disabled={props.move.initial_From_Demob === "Yes" || props.move.initial_To_Mob === "Yes" ? true : undefined}
                        onChange={onRepairNotes}
                        name="dispatch_Notes"
                        value={props.move.repair_Notes ?? ""}
                    />
                </LAGridItem>
            {/* </LAGrid> */}
        </>}
    </LAGrid>
}
;