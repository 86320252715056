import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { FIELD_VALIDATOR_ERRORS, FieldValidator, IFieldErrorKeyValue, IFieldValidatorProps } from "../../../shared/fieldValidator";
import { LAPaperWithPadding } from "../../../shared/paper";
import { MEDIA_QUERY_PHONE, RED_COLOR, WARNING_COLOR, WHITE_COLOR } from "../../../shared/theme";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, undefinedFunction, userName, ZEROTH } from "../../../shared/constExports";
import LAGrid from "../../../shared/grid";
import queryString from "query-string";
import LAGridItem from "../../../shared/gridList";
import { LAButton, LASaveAndCancelButton } from "../../../shared/buttons";
import LATextField from "../../../shared/textField";
import RequestStatus from "../../../shared/requestStatusSnackbar";
import { ROUTE } from "../../../routes";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import LAErrorBox from "../../../shared/errorBox";
import { ArrowLeftIcon } from "../../../shared/icons";
import LATextArea from "../../../shared/textArea";
import splitDate from "../../../shared/splitDate";
import { DevExtremePopupLookup } from "../../../shared/devExtremePopupLookup";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import { LACheckBox } from "../../../shared/checkBox";
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from "@material-ui/core";
import { ISOCTLookup, ISOCTLookupRequest } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsConstants";
import { getSOCTLookups } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsAccessor";
import { getSOCTLookupsLoadAction } from "../../../../redux/field/soct/getSOCTLookups/getSOCTLookupsActions";
import { IGetMeterReadingByIdRequest, IMeterReading } from "../../../../redux/field/soct/getMeterReadingById/getMeterReadingByIdConstants";
import { getMeterReadingById } from "../../../../redux/field/soct/getMeterReadingById/getMeterReadingByIdAccessor";
import { getMeterReadingByIdLoadAction } from "../../../../redux/field/soct/getMeterReadingById/getMeterReadingByIdActions";
import { addMeterReadingStatus } from "../../../../redux/field/soct/addMeterReading/addMeterReadingAccessor";
import { updateMeterReadingStatus } from "../../../../redux/field/soct/updateMeterReading/updateMeterReadingAccessor";
import { IAddMeterReadingRequest, IAddUpdateMeterReading } from "../../../../redux/field/soct/addMeterReading/addMeterReadingConstants";
import { IUpdateMeterReadingRequest } from "../../../../redux/field/soct/updateMeterReading/updateMeterReadingConstants";
import { addMeterReadingLoadAction } from "../../../../redux/field/soct/addMeterReading/addMeterReadingActions";
import { updateMeterReadingLoadAction } from "../../../../redux/field/soct/updateMeterReading/updateMeterReadingActions";
import PageSpacing from "../../../shared/pageSpacing";

const RequiredFields: string[] = ["unit_Number", "date", "current_Hours"];

interface IAddUpdateMeterReadingComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLookup: Server<SurewayAPIResponse<ISOCTLookup>>;
    getMeterReadingByID: Server<SurewayAPIResponse<IMeterReading>>;
    addMeterReadingStatus: Server<SurewayAPIResponse<string>>;
    updateMeterReadingStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateMeterReadingComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLookupsRequest: (data: ISOCTLookupRequest) => unknown;
    getMeterReadingByIDRequest: (request: IGetMeterReadingByIdRequest) => unknown;
    addMeterReadingRequest: (data: IAddMeterReadingRequest) => unknown;
    updateMeterReadingRequest: (data: IUpdateMeterReadingRequest) => unknown;
};

interface IAddUpdateMeterReadingOwnProps {
    id?: string;
};

interface IAddUpdateMeterReadingComponentState {
    data: IMeterReading;
    meter_Replacement_Checkbox: string;
    disabledForm: true | undefined;
    serverError: string;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateMeterReadingStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .required-text {
        color: ${WARNING_COLOR};

        strong {
            color: ${WARNING_COLOR};
        }
    };

    .required {
        color: ${RED_COLOR};

        strong {
            color: ${RED_COLOR};
        }
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateMeterReadingComponentProps =
    RouteComponentProps
    & IAddUpdateMeterReadingOwnProps
    & IAddUpdateMeterReadingComponentStoreProps
    & IAddUpdateMeterReadingComponentDispatchProps;

class AddUpdateMeterReading extends PureComponent<IAddUpdateMeterReadingComponentProps, IAddUpdateMeterReadingComponentState> {

    public constructor(props: IAddUpdateMeterReadingComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                unit_ID: 0,
                date: "",
                current_Hours: null,
                ecm: 0,
                comments: "",
                cancelled: "",
                meter_Replacement: "",
                replacement_Date: "",
                meter_Reset: null,
                created_By: "",
                created: "",
                modified_By: "",
                modified: "",
            },
            meter_Replacement_Checkbox: "No",
            disabledForm: undefined,
            errors: {},
            serverError: "",
        };
    }

    public async componentDidMount(): Promise<void> {
        this.getLook();
        await this.getData();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateMeterReadingComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addMeterReadingStatus !== prevProps.addMeterReadingStatus) {

                if (this.props.addMeterReadingStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addMeterReadingStatus.message });

                if (hasPayload(this.props.addMeterReadingStatus) && this.props.addMeterReadingStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            };

            if (this.props.updateMeterReadingStatus !== prevProps.updateMeterReadingStatus) {

                if (this.props.updateMeterReadingStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateMeterReadingStatus.message });

                if (hasPayload(this.props.updateMeterReadingStatus) && this.props.updateMeterReadingStatus.kind === STATUS_ENUM.SUCCEEDED) {
                    this.handleCancel();
                }
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, disabledForm, meter_Replacement_Checkbox } = this.state;
        const { getToken, addMeterReadingStatus, updateMeterReadingStatus, getLookup } = this.props;
        const getRole = pageAccessCheck(this.props.getToken, "soctAccess");
        // console.log(data, getToken)

        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        const disableSave = (Object.values(errors).length > 0) ? true : undefined;
        const onUnit = (value: string): void => value !== null ? this.handleUnit(value) : this.handleUnit();
        const unit = data.unit_ID && units ? units.find(q => q.id === data.unit_ID) : null;
        const onCancelledMeterReadingCheckbox = (checked: boolean): void => this.handleChange("cancelled", checked ? "Yes" : "No");
        const onReplacementTypeChange = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => this.handleChange("meter_Replacement", value);
        const disabledByMeterReplacement = data.id > 0 && (data.meter_Replacement === "Rollover" || data.meter_Replacement === "Reset") ? true : undefined;

        return (
            <PageSpacing title="SOCT - Add/Update Meter Reading" description="SOCT - ADD/Update Meter Reading" fixedSpaceOnSmallerScreens={true}>
                <AddUpdateMeterReadingStyles>
                    {(getRole === true) &&
                        <LAGrid spacing={1}>

                            <LAGridItem xs={12} sm={12} md={8}>
                                <LAButton
                                    label="Back to List"
                                    onClick={this.handleCancel}
                                    startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} className="text-center">
                                <h2>{data.id && data.id > 0 ? "VIEW/UPDATE " : "ADD "}Meter Reading</h2>
                                <hr />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Unit#</strong>
                                <DevExtremePopupLookup
                                    data={units}
                                    id="job-search"
                                    placeHolder=""
                                    disabled={true}
                                    displayExp="unit_Number"
                                    name="unit_Number"
                                    errorName="unit_Number"
                                    errors={errors}
                                    currentVal={unit?.unit_Number}
                                    onClick={onUnit}
                                    columns={[
                                        { name: "unit_Number", caption: "Unit Number", type: "string" },
                                        { name: "make", caption: "Make", type: "string" },
                                        { name: "model", caption: "Model", type: "string" },
                                        { name: "location_Details", caption: "Location", type: "string" },
                                        { name: "reading_Type", caption: "Reading Type", type: "string" },
                                    ]}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Location Details</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="location"
                                    onChange={undefinedFunction}
                                    value={unit ? unit.location_Details : ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Reading Type</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="reading_Type"
                                    onChange={undefinedFunction}
                                    value={(unit && unit.reading_Type) ?? ""}
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Previous Date</strong>
                                <LATextField
                                    id="previous_Date"
                                    value={(unit && unit.meter_Date && splitDate(unit.meter_Date)) ?? ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={true}
                                    name="previous_Date"
                                    label=""
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            {data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Previous Reading</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="previous_Reading"
                                    type="number"
                                    disabled={true}
                                    value={(unit && unit.meter_Reading) ?? ""}
                                    onChange={this.handleChange}
                                />
                            </LAGridItem>}
                            
                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Date</strong>
                                <LATextField
                                    id="date"
                                    value={splitDate(data.date) ?? ""}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabledForm || disabledByMeterReplacement}
                                    name="date"
                                    label=""
                                    errorText={errors["date"] ? errors["date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem>

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong >Current Hours <strong className="required">(on the new meter after reset/roll over if applicable)</strong></strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="current_Hours"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={disabledForm || disabledByMeterReplacement}
                                    value={data.current_Hours ?? ""}
                                    onChange={this.handleNumber}
                                    errorText={errors["current_Hours"] ? errors["current_Hours"].message : undefined}
                                />
                            </LAGridItem>

                            {unit?.reading_Type === "ECM" && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>ECM</strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="ecm"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={disabledForm || disabledByMeterReplacement}
                                    value={data.ecm > 0 ? data.ecm : ""}
                                    onChange={this.handleNumber}
                                />
                            </LAGridItem>}

                            <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Comments</strong>
                                <LATextArea
                                    minRows={3}
                                    rowsMax={6}
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={disabledForm || disabledByMeterReplacement}
                                    name="comments"
                                    value={data.comments ?? ""}
                                    onChange={this.handleChange}
                                    errorText={errors["comments"] ? errors["comments"].message : undefined}
                                />
                            </LAGridItem>

                            {data.id > 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <br />
                                <LACheckBox
                                    label="Cancel Meter Reading?"
                                    disabled={disabledForm || disabledByMeterReplacement}
                                    onChange={onCancelledMeterReadingCheckbox}
                                    value={data.cancelled === "Yes" ? true : false}
                                />
                            </LAGridItem>}

                            {data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <br />
                                <LACheckBox
                                    label="Meter Replacement?"
                                    disabled={disabledByMeterReplacement}
                                    onChange={this.handleMeterReplacementCheckbox}
                                    value={meter_Replacement_Checkbox === "Yes" ? true : false}
                                />
                            </LAGridItem>}

                            {(meter_Replacement_Checkbox === "Yes" || data.meter_Replacement) && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Replacement Type</strong>
                                <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={data.meter_Replacement} onChange={onReplacementTypeChange} >
                                    <FormControlLabel value={"Rollover"} control={<Radio />} label="Rollover" disabled= {data.id > 0}/>
                                    <FormControlLabel value={"Reset"} control={<Radio />} label="Reset" disabled= {data.id > 0} />
                                </RadioGroup>
                                <FormHelperText className="required-text">{errors["meter_Replacement"] ? errors["meter_Replacement"].message : undefined}</FormHelperText>
                            </LAGridItem> }

                            {(meter_Replacement_Checkbox === "Yes" || data.meter_Replacement) && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Replacement Date</strong>
                                <LATextField
                                    id="replacement_Date"
                                    value={data.replacement_Date}
                                    fullWidth={true}
                                    variant="outlined"
                                    disabled={data.id > 0}
                                    name="replacement_Date"
                                    label=""
                                    errorText={errors["replacement_Date"] ? errors["replacement_Date"].message : undefined}
                                    onChange={this.handleChange}
                                    type="date"
                                />
                            </LAGridItem> }

                            {(meter_Replacement_Checkbox === "Yes" || data.meter_Replacement) && data.id === 0 && <LAGridItem xs={12} sm={6} md={3}>
                                <strong>Meter Value <strong className="required">(on the old meter - at the time of reset/rollback)</strong></strong>
                                <LATextField
                                    label=""
                                    fullWidth={true}
                                    variant="outlined"
                                    name="meter_Reset"
                                    type="number"
                                    InputProps={{
                                        inputProps: {
                                            min: 0
                                        }
                                    }}
                                    disabled={data.id > 0}
                                    value={data.meter_Reset ?? ""}
                                    onChange={this.handleNumber}
                                    errorText={errors["meter_Reset"] ? errors["meter_Reset"].message : undefined}
                                />
                            </LAGridItem> }

                            {data.id && data.id > 0 ? <LAGrid>
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created"
                                            label="Created"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.created ? new Date(data.created).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["created"] ? errors["created"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="created_By"
                                            label="Created By"
                                            disabled={true}
                                            variant="outlined"
                                            value={data.created_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["created_By"] ? errors["created_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                    
                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified"
                                            label="Last Modified"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified ? new Date(data.modified).toLocaleString() : ""}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified"] ? errors["modified"].message : undefined}
                                        />
                                    </LAGridItem>

                                    <LAGridItem xs={12} sm={6} md={3}>
                                        <LATextField
                                            fullWidth={true}
                                            name="modified_By"
                                            label="Modified By"
                                            variant="outlined"
                                            disabled={true}
                                            value={data.modified_By}
                                            onChange={undefinedFunction}
                                            errorText={errors["modified_By"] ? errors["modified_By"].message : undefined}
                                        />
                                    </LAGridItem>
                                </LAGrid> : null}

                            {((serverError !== null) && (serverError.length > ZEROTH)) && <LAGridItem xs={12}>
                                <LAErrorBox text={serverError} />
                            </LAGridItem>}

                            <LAGridItem xs={12}>
                                <></>
                            </LAGridItem>

                            <LAGridItem xs={12} sm={12} md={4}>
                                <LASaveAndCancelButton
                                    fullWidth={true}
                                    saveButtonText="Save"
                                    cancelButtonText="Close"
                                    disableSave={disabledForm || disableSave || disabledByMeterReplacement}
                                    onSave={this.handleSave}
                                    onCancel={this.handleCancel}
                                />
                            </LAGridItem>

                        </LAGrid>
                    } 

                    <RequestStatus requestStatus={addMeterReadingStatus.kind} successMessage="Meter Reading has been added successfully" />
                    <RequestStatus requestStatus={updateMeterReadingStatus.kind} successMessage="Meter Reading has been updated successfully" />

                </AddUpdateMeterReadingStyles>
            </PageSpacing>
        );
    }

    private handleUnit = (value?: string): void => {
        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
        const unit = units.find((x: any) => x.unit_Number === value);

        let errors = { ...this.state.errors };
        if (value === undefined) {
            errors = this.errorChecker("unit_Number", "", errors);
        }
        else {
            errors = this.errorChecker("unit_Number", value.toString(), errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                unit_ID: unit?.id ?? 0,
            },
            errors
        });
    };

    
    private handleCancel = (): void => {
        const query = queryString.parse(this.props.location.search);
        if(query && query.soct_ID) {
            this.setState({ serverError: "" });

            // this.props.history.push({
            //     pathname: ROUTE.FIELD.SOCT.METER_HISTORY.INDEX,
            //     search: getTokenFromUrl(false)
            // });
            callRouteWithQueryString({
                route: this.props,
                search: { id: query.soct_ID.toString(), token: getTokenFromUrl(true) },
                pathName: ROUTE.FIELD.SOCT.METER_HISTORY.INDEX
            });
        }
    };

    private handleSave = async (): Promise<void> => {

        const { data } = this.state;

        if (hasPayload(this.props.getToken)) {

            if (data.id === 0) {
                let request: IAddUpdateMeterReading = {
                    ID: data.id,
                    Unit_ID: data.unit_ID,
                    Date: data.date,
                    ECM: Number(data.ecm),
                    Current_Hours: Number(data.current_Hours),
                    Comments: data.comments,
                    Meter_Replacement: data.meter_Replacement,
                    Replacement_Date: data.replacement_Date,
                    Meter_Reset: Number(data.meter_Reset),
                    Created_By: this.props.getToken.payload.response.upn,
                    Modified_By: this.props.getToken.payload.response.upn,
                };
                // console.log(request)
                this.props.addMeterReadingRequest({
                    token: this.props.getToken.payload.response.token,
                    request
                });
            } 
            else {
                let updateRequest: IAddUpdateMeterReading = {
                    ID: data.id,
                    Unit_ID: data.unit_ID,
                    Date: data.date,
                    ECM: Number(data.ecm),
                    Current_Hours: Number(data.current_Hours),
                    Comments: data.comments,
                    Cancelled: data.cancelled,
                    Created_By: this.props.getToken.payload.response.upn,
                    Modified_By: this.props.getToken.payload.response.upn,
                };

                this.props.updateMeterReadingRequest({
                    token: this.props.getToken.payload.response.token,
                    request : updateRequest
                });
            };

            this.setState({ serverError: "" });
        }
    };

    private handleNumber = (name: string, value: string): void => {
        // console.log(name, value)
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value , errors);

        if(name === "meter_Reset") {
            errors = this.errorChecker(name, value, errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value ? Number(value) : null
            },
            errors
        });
    };

    
    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}
        // console.log(errors)
        
        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        if(name === "meter_Replacement" || name === "replacement_Date") {
            errors = this.errorChecker(name, value, errors);
        }

        this.setState({
            ...this.state,
            data: {
                ...data,
                [name]: value
            },
            errors
        });
    };

    private handleMeterReplacementCheckbox = (checked: boolean): void => {
        let errors = { ...this.state.errors };
        const data = { ...this.state.data}

        if(checked) {
            errors = this.errorChecker("meter_Replacement", data.meter_Replacement ? data.meter_Replacement : "", errors);
            errors = this.errorChecker("replacement_Date", data.replacement_Date ? data.replacement_Date : "", errors);
            errors = this.errorChecker("meter_Reset", data.meter_Reset ? data.meter_Reset.toString() : "", errors);
        } else {
            delete errors["meter_Replacement"];
            delete errors["replacement_Date"];
            delete errors["meter_Reset"];
            data.meter_Replacement = "";
            data.replacement_Date = "";
            data.meter_Reset = null;
        }

        this.setState({
            ...this.state,
            data: {
                ...data,
            },
            meter_Replacement_Checkbox: checked ? "Yes" : "No",
            errors
        });
    };


    private setDataToState = async (): Promise<void> => {
        const query: any =  queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (isNotLoaded(this.props.getToken))
                this.props.getTokenRequest({
                    request: {
                        username: getTokenFromUrl(true) ? undefined : userName,
                        user_token: getTokenFromUrl(true)
                    }
                });

            if (hasPayload(this.props.getToken)) {
                if (pageAccessCheck(this.props.getToken, "soctAccess") === true) {
                    
                    if (query.id !== "0") {
                        if(isNotLoaded(this.props.getMeterReadingByID)) {
                            this.getData();
                        }

                        if (hasPayload(this.props.getMeterReadingByID)) {
                            if (this.state.data.id !== Number(query.id)) {
                                const data = this.props.getMeterReadingByID.payload.response;
                                const disabledForm = !data.latest ? true : undefined;
                                
                                this.setState({
                                    data,
                                    disabledForm
                                });
                            };
                        } 
                    }
                    else {
                        const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
                        const unit = (query && query.soct_ID && units) ? units.find(q => q.socT_ID === Number(query.soct_ID)) : null;
                        
                        const errors: ById<IFieldErrorKeyValue> = {};

                        RequiredFields.forEach((x) => {
                            errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                        });

                        if(unit) {
                            delete errors["unit_Number"];
                        }

                        this.setState({ 
                            data: {
                                ...this.state.data,
                                unit_ID: unit ? unit.id : 0
                            },
                            errors 
                        });
                    }

                } 
                else {
                    this.props.history.push({
                        pathname: ROUTE.ACCESS_DENIED,
                        search: getTokenFromUrl(false)
                    });
                };
            };
        };
    };

    private getLook = (): void => {
        if (hasPayload(this.props.getToken))
            this.props.getLookupsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    page: "Meter_Readings"
                }
            });
    };

    private getData = () : void => {
        if (hasPayload(this.props.getToken)) {
            const query: any =  queryString.parse(this.props.location.search);

            if (query !== undefined && query.id !== undefined && Number(query.id) > 0) {
                this.props.getMeterReadingByIDRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: Number(query.id)
                    }
                });
            }
        }
    }

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        let rules: IFieldValidatorProps = { required: true, minLength: 1 };

        const result = FieldValidator(value, rules);
        const err: ById<IFieldErrorKeyValue> = errors;

        // console.error(result)
        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateMeterReadingComponentStoreProps => ({
    getToken: getToken(state),
    getLookup: getSOCTLookups(state),
    getMeterReadingByID: getMeterReadingById(state),
    addMeterReadingStatus: addMeterReadingStatus(state),
    updateMeterReadingStatus: updateMeterReadingStatus(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateMeterReadingComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getLookupsRequest: (data: ISOCTLookupRequest) => dispatch(getSOCTLookupsLoadAction(data)),
    getMeterReadingByIDRequest: (request: IGetMeterReadingByIdRequest) => dispatch(getMeterReadingByIdLoadAction(request)),
    addMeterReadingRequest: (data: IAddMeterReadingRequest) => dispatch(addMeterReadingLoadAction(data)),
    updateMeterReadingRequest: (data: IUpdateMeterReadingRequest) => dispatch(updateMeterReadingLoadAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateMeterReading);