import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IUPDATE_SHOP_MODEL_REQUEST } from "./updateShopModelsConstants";
import { IUpdateShopModelLoadAction, IUpdateShopModelLoadFailedAction, IUpdateShopModelSuccessAction, updateShopModelLoadFailedAction, updateShopModelLoadSuccessAction } from "./updateShopModelsActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const updateShopModelEpic: Epic = (
    action$: ActionsObservable<IUpdateShopModelLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IUpdateShopModelSuccessAction | IUpdateShopModelLoadFailedAction> =>
    action$.ofType(IUPDATE_SHOP_MODEL_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<string>>(
                    END_POINTS.FIELD.UPDATE_MODEL,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<string>): IUpdateShopModelSuccessAction | IUpdateShopModelLoadFailedAction => {
                            if(response.message === "Success"){
                                return updateShopModelLoadSuccessAction(response);
                            } else {
                                return updateShopModelLoadFailedAction(response.message);
                            }
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(updateShopModelLoadFailedAction(response.message)))
                    )
            )
        );