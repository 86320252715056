import React from 'react';

  import Grid from '@mui/material/Grid';
  import Card from '@mui/material/Card';
  import {Button as MUIButton, Typography, FormControlLabel, Box, InputLabel, Modal, TextField, Backdrop, Checkbox} from '@mui/material';
  import {RouteComponentProps} from 'react-router-dom';
import { getTrailers, addTrailer, getTrailerRates} from './api/equipmenttracker/equipmentTrackerAPI';
import SOCTHome from './equipmenttrackerhome';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from "@mui/material/Autocomplete";
import EditTrailersForm from './forms/trailers/editTrailers';
import {MobileBoxStyle} from '../../shared/styles';
import { LADevExtremeGrid } from '../../shared/devExtreme';

interface IState {
  trailer_list:any;
  open:boolean;
  showEditForm:boolean;
  view:string;
  showTrailerAddPopup:boolean;
}
interface IProps extends RouteComponentProps{
  history:any
}
const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
    left:'94%'
  }
var editData:any;
var unitNumbers:any = [];
var copy_list:any[] = [];
const filterBuilderPopupPosition = {
    of: window,
    at: 'top',
    my: 'top',
    offset: { y: 10 },
  };
var new_trailer:any = {description:'', unit_number:'', serial_number:'', active:false, license_number:'', last_inspection_date:'', last_service_date:'', year:0, trailer_rate_id:0};
var trailer_config_options:any[] = [];
var trailer_config_rates:any[] = [];
var grid_xs_width:number = 4;
export default class TrailersPage extends React.Component<IProps,IState>{
  constructor(props:any){
    super(props);
    this.state ={trailer_list:[], open:false, showEditForm:false, view:'all', showTrailerAddPopup:false};
    this.setOpen = this.setOpen.bind(this);
    this.editItem = this.editItem.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.addNewTrailer = this.addNewTrailer.bind(this);
    this.closeTrailerAddPopup = this.closeTrailerAddPopup.bind(this);
    this.onSaveDriver = this.onSaveDriver.bind(this);
  }
  async onSaveDriver(){
    var isUnique = true;
    if(new_trailer.trailer_rate_id != 0 && new_trailer.unit_number !=''){
        this.state.trailer_list.map((trailer:any) => {
          if(trailer.unit_Number.toLowerCase().trim() === new_trailer.unit_number.toLowerCase().trim()){
            isUnique = false;
          }
        })
        if(isUnique){
          var res:any = await addTrailer(new_trailer);
          if(res.status === 200){
              alert("Successfully added new trailer");
              new_trailer = {description:'', unit_number:'', serial_number:'', active:false, license_number:'', last_inspection_date:'', last_service_date:'', year:0, trailer_rate_id:0};
              this.closeTrailerAddPopup();
              this.refreshGrid();
          }
          else{
              alert("Failed to add new trailer");
          }
        }
        else{
          alert("This Unit Number already exists");
        }
    }
    else{
        alert("Description and Unit Number are mandatory");
    }
  }
  handleChange(event:any){
    const fieldName:string = event.target.name;
    switch(fieldName){
        case 'description':
            new_trailer.description = event.target.value;
            break;
        case 'unit_number':
            new_trailer.unit_number = event.target.value;
            break;
        case 'serial_number':
            new_trailer.serial_number = event.target.value;
            break;
        case 'license_number':
            new_trailer.license_number = event.target.value;
            break;
        case 'year':
            new_trailer.year = event.target.value;
            break;
        case 'inspection_date':
            new_trailer.last_inspection_date = event.target.value;
            break;
        case 'service_date':
            new_trailer.last_service_date = event.target.value;
            break;
        case 'active':
            new_trailer.active = !new_trailer.active;
            break;
    }
  }
  closeTrailerAddPopup(){
    new_trailer = {description:'', unit_number:'', serial_number:'', active:false, license_number:'', last_inspection_date:'', last_service_date:'', year:0, trailer_rate_id:0};
    this.setState({showTrailerAddPopup:false});
  }
  addNewTrailer(){
    this.setState({showTrailerAddPopup:true});
  }
  setOpen(){
    this.setState({open:!this.state.open});
  }
  closeEditForm(){
      this.setState({showEditForm:false});
      this.props.history.push('/field/equipmenttracker/trailers');
      this.refreshGrid();
  }
  editItem = (e:any):void => {
    editData = e.row.data;
    this.props.history.push('/field/equipmenttracker/trailers/edit/id='+e.row.data.id);
    this.setState({showEditForm:true});
  }
  async refreshGrid(){
    if(localStorage.getItem("ET-Role")==="R"){
      this.props.history.push("/field/equipmenttracker/trucking");
    }
    if(window.innerWidth <=760){
      grid_xs_width = 12;
    }
    else{
      grid_xs_width = 4;
    }
     var trailers = await getTrailers();
     trailer_config_rates = [];
     trailer_config_options = [];
     trailer_config_rates = await getTrailerRates();
     trailer_config_rates.map((desc:any) => {
       if(desc.description != null){
        trailer_config_options.push(desc.description);
       }
     });
     trailer_config_options.sort((a:any,b:any) => (a > b) ? 1:-1);
    this.setState({trailer_list:trailers});
  }
  async componentDidMount(){
    this.refreshGrid();
  }
    render(){
        return (
          <> <SOCTHome history={this.props.history}></SOCTHome>
          <Grid container spacing={1} direction="row" style={{padding:'20px', backgroundColor:' #282c34'}} >
              <Card className='dataGrid-card' variant="outlined" >
            <MUIButton onClick={this.addNewTrailer} style={{backgroundColor:'#bf0000', marginRight:'90%'}} variant="contained" startIcon={<AddCircleIcon />}>Add New</MUIButton>
            <Typography className='dataGrid-title'>Trailers</Typography>
            <LADevExtremeGrid
                                               onClick={(e:any)=>{}}
                                                onEdit={this.editItem}
                                                data={this.state.trailer_list}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={100}
                                                removeStyleBtn={true}
                                                height={1200}
                                                paging={100}
                                                customRowColor={true}
                                                exportFileName="Trailers"                                  
                                                columns={[    
                                                  
                                                  { name: "description", caption: "Description", type: "string"},       
                                                  { name: "unit_Number", caption: "Unit #", type: "string"},             
                                                  { name: "serial_Number", caption: "Serial #", type: "string"},     
                                                  { name: "license_Number", caption: "License #", type: "string"},     
                                                  { name: "year", caption: "Year", type: "string"},    
                                                  { name: "last_Inspection_Date", caption: "Last Inspection Date", type: "string"},     
                                                  { name: "last_Service_Date", caption: "Last Service Date", type: "string"}, 
                                                  { name: "active", caption: "Active", type: "string"},                                                
                                                ]}
                                                />
            {this.state.showTrailerAddPopup ?<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={window.innerWidth <=760 ? MobileBoxStyle:BoxStyle}>
                    <MUIButton sx={ExitButtonStyle} onClick={this.closeTrailerAddPopup.bind(this)}>Close</MUIButton>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Adding New Trailer</Typography>
                      </Grid>
                      <Grid item xs={6}>
                      <InputLabel>Trailer Config</InputLabel>
                        <Autocomplete  disablePortal 
                            autoHighlight
                            onChange={async (event:any, newValue:String | null) => {
                                new_trailer.description = newValue;
                                trailer_config_rates.map((elem:any) => {
                                  if(elem.description === newValue){
                                    new_trailer.trailer_rate_id = elem.id;
                                  }
                                })
                                this.setState({});
                            }}
                            options={trailer_config_options} 
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" {...params} style={{backgroundColor:'white'}}></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Unit Number</InputLabel>
                        <TextField onChange={this.handleChange} name="unit_number"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Serial Number" onChange={this.handleChange} name="serial_number"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="License Number" onChange={this.handleChange} name="license_number"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField label="Year" type="number" onChange={this.handleChange} name="year"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>Last Inspection Date</InputLabel>
                        <TextField type="date" onChange={this.handleChange} name="inspection_date"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                          <InputLabel>Last Service Date</InputLabel>
                        <TextField type="date" onChange={this.handleChange} name="service_date"></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                            <FormControlLabel control={<Checkbox onChange={this.handleChange} name="active"/>} label="Active"></FormControlLabel>
                      </Grid>
                      <Grid item xs={12}>
                      <MUIButton style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}} variant="contained" onClick={this.onSaveDriver}>Save</MUIButton>
                      </Grid>
                      </Grid></Box></Modal>:null}
            {this.state.showEditForm ? <EditTrailersForm locationOptions={[]} closeEquipmentMoveForm={this.closeEditForm} clickedItem={[]} clickedLocation={null} refreshGrid={this.refreshGrid}></EditTrailersForm>:null}
            
            </Card>
            </Grid>
            </>
        );
    }
}
export {editData, unitNumbers};