import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IUPDATE_VENDOR_ORDER_REQUEST } from "./updateVendorOrderConstants";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IUpdateVendorOrderLoadAction, IUpdateVendorOrderLoadFailedAction, IUpdateVendorOrderSuccessAction } from "./updateVendorOrderActions";

type Actions =
    | IUpdateVendorOrderLoadAction
    | IUpdateVendorOrderSuccessAction
    | IUpdateVendorOrderLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateVendorOrderReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_VENDOR_ORDER_REQUEST.REQUEST:
            return loading;

        case IUPDATE_VENDOR_ORDER_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_VENDOR_ORDER_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};