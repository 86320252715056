import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGetHazardByID, IGET_HAZARD_BY_ID_REQUEST } from "./getHazardByIDConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetHazardByIDLoadAction, IGetHazardByIDLoadFailedAction, IGetHazardByIDSuccessAction, getHazardByIDLoadFailedAction, getHazardByIDLoadSuccessAction } from "./getHazardByIDActions";


export const getHazardByIDEpic: Epic = (
    action$: ActionsObservable<IGetHazardByIDLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetHazardByIDSuccessAction | IGetHazardByIDLoadFailedAction> =>
    action$.ofType(IGET_HAZARD_BY_ID_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<IGetHazardByID>>(
                    END_POINTS.FIELD.FORMS.ADMIN.GET_HAZARD_BY_ID,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<IGetHazardByID>): IGetHazardByIDSuccessAction => {
                            return getHazardByIDLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getHazardByIDLoadFailedAction(response.message)))
                    )
            )
        );