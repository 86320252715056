
  import React from 'react';
  import {Button, Typography, Modal, Box, Grid, FormLabel, Backdrop, FormControl, FormGroup, FormControlLabel, Checkbox, TextField} from '@mui/material';
import {MobileBoxStyle} from './styles';

  interface IProps {
    closePopup:any;
    confirmPopup:any;
    titleText:string;
    isApprovalButton:boolean;
  }
  interface IState {

  }
  const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
  }
var complete_move_request_email_check:boolean = false;
var complete_move_request_modify_check:boolean = false;
var complete_move_request_move_check:boolean = false;
var complete_move_request_approved_check = false;
var complete_move_request_rejected_check = false;
var rejection_comments:string = "";
var disableEdit:boolean = false;
var grid_xs_width:number = 3;

export default class CompleteMoveRequestPopup extends React.Component<IProps, IState>{
    constructor(props:any){
        super(props);
        this.state = {};
    }
    componentDidMount(){
      if(window.innerWidth <=1056){
        grid_xs_width = 9;
      }
      else{
        grid_xs_width = 4;
      }
      disableEdit = localStorage.getItem("ET-Role")==="R";
      complete_move_request_email_check = false;
      complete_move_request_modify_check = false;
      complete_move_request_move_check = false;
      complete_move_request_approved_check = false;
      complete_move_request_rejected_check = false;
      rejection_comments = "";
      this.setState({});
    }
    render(){
      console.log(this.props.isApprovalButton)
        return (
<Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    sx={{overflow:'auto'}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.props.closePopup}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>{this.props.titleText}</Typography>
                      </Grid>
                      {this.props.titleText==="Complete Move?" && !this.props.isApprovalButton?
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Send Notification Email</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={complete_move_request_email_check} 
                                            onChange={(e:any)=> {
                                                complete_move_request_email_check = !complete_move_request_email_check;this.setState({});}}/>
                                            }
                                            label="Yes"
                                            disabled={disableEdit}
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>:
                      <>
                      {this.props.isApprovalButton === true ?
                       <Grid item xs={grid_xs_width}>
                       <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                               <FormLabel component="legend">Approve?</FormLabel>
                               <FormGroup>
                                   <FormControlLabel
                                       control={
                                       <Checkbox checked={complete_move_request_approved_check} 
                                       onChange={(e:any)=> {
                                        complete_move_request_approved_check = !complete_move_request_approved_check;this.setState({});}}/>
                                       }
                                       label="Yes"
                                       disabled={complete_move_request_rejected_check || disableEdit}
                                   />
                               </FormGroup>
                       </FormControl>
                   </Grid>
                      :
                      <Grid item xs={grid_xs_width}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Moved?</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={complete_move_request_move_check} 
                                            onChange={(e:any)=> {
                                              complete_move_request_move_check = !complete_move_request_move_check;this.setState({});}}/>
                                            }
                                            label="Yes"
                                            disabled={complete_move_request_modify_check || disableEdit}
                                        />
                                    </FormGroup>
                            </FormControl>
                        </Grid>
                      }
                        {complete_move_request_move_check || complete_move_request_approved_check || complete_move_request_rejected_check? 
                        <Grid item xs={grid_xs_width}>
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                      <FormLabel component="legend">Send Notification Email</FormLabel>
                                      <FormGroup>
                                          <FormControlLabel
                                              control={
                                              <Checkbox checked={complete_move_request_email_check} 
                                              onChange={(e:any)=> {
                                                  complete_move_request_email_check = !complete_move_request_email_check;this.setState({});}}/>
                                              }
                                              label="Yes"
                                              disabled={disableEdit}
                                          />
                                      </FormGroup>
                              </FormControl>
                        </Grid>
                        :null}
                        {this.props.isApprovalButton === true ?
                        <>
                         <Grid item xs={grid_xs_width}>
                         <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                 <FormLabel component="legend">Reject?</FormLabel>
                                 <FormGroup>
                                     <FormControlLabel
                                         control={
                                         <Checkbox checked={complete_move_request_rejected_check} 
                                         onChange={(e:any)=> {
                                          complete_move_request_rejected_check = !complete_move_request_rejected_check;this.setState({});}}/>
                                         }
                                         label="Yes"
                                         disabled={complete_move_request_approved_check || disableEdit}
                                     />
                                 </FormGroup>
                         </FormControl>
                     </Grid>
                     {complete_move_request_rejected_check ?
                      <Grid item xs={grid_xs_width}>
                      <TextField label="Rejection Comments" onChange={(event:any)=>{rejection_comments = event.target.value;this.setState({});}} name="rejection_comments"></TextField>
                    </Grid>:null}
                    </>
                        :
                         <Grid item xs={grid_xs_width}>
                         <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                 <FormLabel component="legend">Modify?</FormLabel>
                                 <FormGroup>
                                     <FormControlLabel
                                         control={
                                         <Checkbox checked={complete_move_request_modify_check} 
                                         onChange={(e:any)=> {
                                          complete_move_request_modify_check = !complete_move_request_modify_check;this.setState({});}}/>
                                         }
                                         label="Yes"
                                         disabled={complete_move_request_move_check || disableEdit}
                                     />
                                 </FormGroup>
                         </FormControl>
                     </Grid>
                    }
                  </>
                  }
                      <Grid item xs={12}>
                      <Button   disabled={disableEdit || (this.props.isApprovalButton && !complete_move_request_approved_check && !complete_move_request_rejected_check)} 
                        style={{width:'100px',backgroundColor:'#bf0000', left:window.innerWidth<=1056?'0%':'80%'}} 
                        variant="contained" 
                        onClick={this.props.confirmPopup}
                        >Confirm</Button>
                      </Grid>
                      </Grid></Box></Modal>
        );
    }
}
export {complete_move_request_email_check, complete_move_request_modify_check, complete_move_request_move_check, complete_move_request_approved_check, complete_move_request_rejected_check, rejection_comments}