import { IAddUpdateFilterPart } from "../addFilterPart/addFilterPartConstants";

export enum IUPDATE_FILTER_PART_REQUEST {
    REQUEST = "updateFilterPart/UPDATE_FILTER_PART_REQUEST",
    SUCCESS = "updateFilterPart/UPDATE_FILTER_PART_SUCCESS",
    FAILED = "updateFilterPart/UPDATE_FILTER_PART_FAILED"
};

export interface IUpdateFilterPartRequest {
    token: string;
    request: IAddUpdateFilterPart;
};