import { IEquipmentManual } from "../getEquipmentManuals/getEquipmentManualsConstants";

export enum IADD_EQUIPMENT_MANUAL_REQUEST {
    REQUEST = "addEquipmentManual/ADD_EQUIPMENT_MANUAL_REQUEST",
    SUCCESS = "addEquipmentManual/ADD_EQUIPMENT_MANUAL_SUCCESS",
    FAILED = "addEquipmentManual/ADD_EQUIPMENT_MANUAL_FAILED"
};

export interface IAddEquipmentManualRequest {
    token: string;
    request: IAddEquipmentManual;
};

export interface IAddEquipmentManual extends IEquipmentManual {

}