import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_SHOP_MODELS_REQUEST, IShopModel } from "./getShopModelConstants";
import { IGetShopModelsLoadAction, IGetShopModelsLoadFailedAction, IGetShopModelsSuccessAction } from "./getShopModelActions";
import { IUPDATE_SHOP_MODEL_REQUEST } from "../updateModels/updateShopModelsConstants";
import { IADD_SHOP_MODEL_REQUEST } from "../addModels/addShopModelsConstants";
import { IAddShopModelSuccessAction } from "../addModels/addShopModelsActions";
import { IUpdateShopModelSuccessAction } from "../updateModels/updateShopModelsActions";

type Actions =
    | IGetShopModelsLoadAction
    | IGetShopModelsSuccessAction
    | IUpdateShopModelSuccessAction
    | IAddShopModelSuccessAction
    | IGetShopModelsLoadFailedAction
    | IFlushDataSuccessAction;

export const GetShopModelsReducer = (state: Server<SurewayAPIResponse<ById<IShopModel>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IShopModel>>> => {
    switch (action.type) {
        case IGET_SHOP_MODELS_REQUEST.REQUEST:
            return loading;

        case IGET_SHOP_MODELS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SHOP_MODELS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_SHOP_MODEL_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_SHOP_MODEL_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};