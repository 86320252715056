import { IUPDATE_OPERATOR_LOG_REQUEST } from "./updateOperatorLogConstants";
import { IUpdateOperatorLogLoadAction, IUpdateOperatorLogLoadFailedAction, IUpdateOperatorLogSuccessAction } from "./updateOperatorLogActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | IUpdateOperatorLogLoadAction
    | IUpdateOperatorLogSuccessAction
    | IUpdateOperatorLogLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateOperatorLogReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_OPERATOR_LOG_REQUEST.REQUEST:
            return loading;

        case IUPDATE_OPERATOR_LOG_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_OPERATOR_LOG_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};