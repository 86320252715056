import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { IGET_SOCT_LOOKUPS_REQUEST, ISOCTLookup } from "./getSOCTLookupsConstants";
import { IGetSOCTLookupsLoadAction, IGetSOCTLookupsLoadFailedAction, IGetSOCTLookupsSuccessAction } from "./getSOCTLookupsActions";
import { IAddMeterReadingSuccessAction } from "../addMeterReading/addMeterReadingActions";
import { IUpdateMeterReadingSuccessAction } from "../updateMeterReading/updateMeterReadingActions";
import { IADD_METER_READING_REQUEST } from "../addMeterReading/addMeterReadingConstants";
import { IUPDATE_METER_READING_REQUEST } from "../updateMeterReading/updateMeterReadingConstants";

type Actions =
    | IGetSOCTLookupsLoadAction
    | IGetSOCTLookupsSuccessAction
    | IGetSOCTLookupsLoadFailedAction
    | IAddMeterReadingSuccessAction
    | IUpdateMeterReadingSuccessAction
    | IFlushDataSuccessAction;

export const GetSOCTLookupsReducer = (state: Server<SurewayAPIResponse<ISOCTLookup>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ISOCTLookup>> => {
    switch (action.type) {
        case IGET_SOCT_LOOKUPS_REQUEST.REQUEST:
            return loading;

        case IGET_SOCT_LOOKUPS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_SOCT_LOOKUPS_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;
        
        case IADD_METER_READING_REQUEST.SUCCESS:
            return notLoaded;
        
        case IUPDATE_METER_READING_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};