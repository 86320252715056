import { ById } from '../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IGET_FILTER_REQUEST, IFilter, IFilterRequest } from "./getFilterConstants";

export interface IGetFilterLoadAction {
    type: IGET_FILTER_REQUEST.REQUEST;
    data: IFilterRequest
}
export const getFilterLoadAction = (data: IFilterRequest): IGetFilterLoadAction => ({
    type: IGET_FILTER_REQUEST.REQUEST,
    data
});
export interface IGetFilterSuccessAction {
    type: IGET_FILTER_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IFilter>>;
}
export const getFilterLoadSuccessAction = (list: SurewayAPIResponse<ById<IFilter>>): IGetFilterSuccessAction => ({
    type: IGET_FILTER_REQUEST.SUCCESS,
    list
});
export interface IGetFilterLoadFailedAction {
    type: IGET_FILTER_REQUEST.FAILED;
    message: string;
}
export const getFilterLoadFailedAction = (message: string): IGetFilterLoadFailedAction => ({
    type: IGET_FILTER_REQUEST.FAILED,
    message
});
