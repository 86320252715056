import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export interface IComponentWaitingPartsNotes {
    id: number;
    Parts_Notes: string;
    Parts_Dispatched: string;
    Modified_By: string;
}

export enum ISAVE_COMPONENT_WAITING_PARTS_REQUEST {
    REQUEST = "saveComponentWaitingParts/SAVE_COMPONENT_WAITING_PARTS_REQUEST",
    SUCCESS = "saveComponentWaitingParts/SAVE_COMPONENT_WAITING_PARTS_SUCCESS",
    FAILED = "saveComponentWaitingParts/SAVE_COMPONENT_WAITING_PARTS_FAILED"
};

export interface ISaveComponentWaitingPartsRequest extends ISurewayTokenRequestBody {
    // request: IComponentWaitingParts[];
    request: IComponentWaitingPartsNotes;
};