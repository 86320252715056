import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../../shared/paper";
import { IDispatch, IStore } from "../../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../../redux/server";
import { LADevExtremeGrid } from "../../../shared/devExtreme";
import { LAButton } from "../../../shared/buttons";
import { AddIcon } from "../../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../../shared/theme";
import { ROUTE } from "../../../routes";
import PageSpacing from "../../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl, pageAccessCheck, SUCCESS_MESSAGE, userName } from "../../../shared/constExports";
import { getToken } from "../../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../../redux/getToken/getTokenActions";
import LAGrid from "../../../shared/grid";
import LAGridItem from "../../../shared/gridList";
import LALoading from "../../../shared/loading";
import { NotApplicable } from "../../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import LAErrorBox from "../../../shared/errorBox";
import { Tooltip } from "devextreme-react";
// import { getFormsLoadAction } from "../../../../redux/field/getForms/getFormsActions";
import { FormsMenu, Navbar } from "../../../shared/navbars";
import { IFormResponse, IGetFormResponsesRequest } from "../../../../redux/field/getFormResponses/getFormResponsesConstants";
import { getFormResponses } from "../../../../redux/field/getFormResponses/getFormResponsesAccessor";
import { getFormResponsesLoadAction } from "../../../../redux/field/getFormResponses/getFormResponsesActions";
import { IGetFormResponseVersionHistoryByID, IGetFormResponseVersionHistoryByIDRequest } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDConstants";
import { getFormResponseVersionHistoryByID } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDAccessor";
import { getFormResponseVersionHistoryByIDLoadAction } from "../../../../redux/field/getFormResponseVersionHistoryByID/getFormResponseVersionHistoryByIDActions";
// import { FormResponseVersionHistoryByID } from "./formResponseVersionHistoryByIDPopup";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { END_POINTS } from "../../../../redux/endpoints";
import { IGetFormVersionHistory, IGetFormVersionHistoryRequest } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryConstants";
import { getFormVersionHistory } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryAccessor";
import { getFormVersionHistoryLoadAction } from "../../../../redux/field/getFormVersionHistory/getFormVersionHistoryActions";
import { FormVersionHistoryPopup } from "../formVersionHistoryPopup";

interface IFormResponsesComponentStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getFormResponses: Server<SurewayAPIResponse<IFormResponse[]>>;
    // getFormResponseVersionHistoryByID: Server<SurewayAPIResponse<ById<IGetFormResponseVersionHistoryByID>>>;
    getFormVersionHistory: Server<SurewayAPIResponse<ById<IGetFormVersionHistory>>>;
};

interface IFormResponsesComponentDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getFormResponsesRequest: (data: IGetFormResponsesRequest) => unknown;
    // getFormResponseVersionHistoryByIDRequest: (data: IGetFormResponseVersionHistoryByIDRequest) => unknown;
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => unknown;
};


interface IFormResponsesComponentOwnProps {

};

interface IFormResponsesComponentState {
    view: string;
    formResponseVersionHistoryPopup: boolean;
    serverError: string;
};

const FormResponsesComponentStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 40px;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type IFormResponsesComponentProps = RouteComponentProps
    & IFormResponsesComponentStoreProps
    & IFormResponsesComponentDispatchProps
    & IFormResponsesComponentOwnProps;

class FormResponsesComponent extends PureComponent<IFormResponsesComponentProps, IFormResponsesComponentState> {
    tooltip: Tooltip | null | undefined;
    public constructor(props: IFormResponsesComponentProps) {
        super(props);
        this.state = {
            view: "Today",
            formResponseVersionHistoryPopup: false,
            serverError: ""
        };
    }

    public async componentDidMount(): Promise<void> {
        await this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: IFormResponsesComponentProps): void {
        if (this.props !== prevProps)
            this.callServer();

        if(hasPayload(this.props.getFormResponses) && this.props.getFormResponses.payload.message !== SUCCESS_MESSAGE) {
            this.setState({ serverError: this.props.getFormResponses.payload.message });
        }
    };


    public render(): ReactNode {
        const { formResponseVersionHistoryPopup, serverError, view } = this.state;
        const { getFormResponses, getToken, getFormVersionHistory  } = this.props;
        const getRole = pageAccessCheck(getToken, "formsAdmin");
        const getCleanUpRole = pageAccessCheck(getToken, "formsCleanupAccess");
        // console.log(getRole)
        const data = hasPayload(getFormResponses) ? Object.values(getFormResponses.payload.response) : [];
        // const formResponseVersionHistoryPopupData = hasPayload(getFormResponseVersionHistoryByID) ? Object.values(getFormResponseVersionHistoryByID.payload.response) : [];
        const formVersionHistoryPopupData = hasPayload(getFormVersionHistory) ? Object.values(getFormVersionHistory.payload.response) : [];

        return (
            <PageSpacing title="Form Responses" description="Forms - Form Responses" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={FormsMenu} role={getRole === true ? true : getCleanUpRole === true ? "formsCleanupAccess": getRole}/>
                {/* {(getRole !== NotApplicable) && <> */}
                    <FormResponsesComponentStyles>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">
                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>FORM RESPONSES</h2><br />
                                <hr />

                                {getFormResponses.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Form Responses..." />}
                                {getFormResponses.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Form Responses..." />}

                                {getFormResponses.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Today" control={<Radio />} label="Today" />
                                                <FormControlLabel value="30 Days" control={<Radio />} label="Last 30 Days" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                onCustomClick={this.handleCustomClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={2000}
                                                paging={50}
                                                id="FormResponses"
                                                customRowColor={true}
                                                exportFileName="FormResponses"
                                                storageKey="formResponses-list"
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={[
                                                    { name: "name", caption: "Name", type: "string"},
                                                    { name: "created", caption: "Date", type: "date"  },
                                                    { name: "form_Name", caption: "Form Name", type: "string" },
                                                    { name: "site_Info", caption: "Working Site", type: "string" },
                                                    { name: "version_History", caption: "Version History", type: "button"},
                                                    { name: "view_Form", caption: "View Form", type: "button"},
                                                    { name: "pdf", caption: "PDF", type: "button"},
                                                    { name: "created", caption: "Created", type: "datetime"  },
                                                    { name: "created_By", caption: "Created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime", sortDesc: true },
                                                    { name: "modified_By", caption: "Modified By", type: "string"},
                                                ]}
                                            />
                                        </LAGridItem>
                                    </LAGrid>
                                }

                                {serverError && <LAErrorBox text={serverError} />}
                            </LAGridItem>
                        </LAGrid>
                        {/* {formResponseVersionHistoryPopup && <FormResponseVersionHistoryByID
                            data={formResponseVersionHistoryPopupData}
                            display={`Version History` }
                            open={formResponseVersionHistoryPopup}
                            status={getFormResponseVersionHistoryByID.kind}
                            onCancel={this.handleFormResponseVersionHistoryPopupClose}
                        />} */}
                        {formResponseVersionHistoryPopup && <FormVersionHistoryPopup
                            page="FormResponseVersionHistory"
                            data={formVersionHistoryPopupData}
                            display={`Version History`}
                            open={formResponseVersionHistoryPopup}
                            status={getFormVersionHistory.kind}
                            onCancel={this.handleFormResponseVersionHistoryPopupClose}
                        />}
                    </FormResponsesComponentStyles>
                {/* </>} */}
            </PageSpacing>
        );
    }

    private onViewClick = (e:any, value:string): void => {
        this.setState({ view: value });

        if (hasPayload(this.props.getToken)) {
            this.props.getFormResponsesRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    username: this.props.getToken.payload.response.upn,
                    view: value
                }
            });
        };
    };

    private handleCustomClick = (name: string, e: any): void => {
        const data: IFormResponse = e.row.data;
        if (name === "version_History") {
            this.handleFormResponseVersionHistory(data, "Form_Sheet")
        }
        if(name === "view_Form") {
            window.open(ROUTE.FIELD.FORMS.FORM_RESPONSE.FORM_RESPONSE_READ_ONLY + "?id=" + e.row.data.id, "_blank")
        }
        if(name === "pdf") {
            this.downloadPDF(e.row.data.name + "-" + e.row.data.form_Name + "_" + e.row.data.id, e.row.data.id);
        }
    }

    private downloadPDF = async (fileName: string, id: number): Promise<void> => {
        if (fileName && id) {
            if (hasPayload(this.props.getToken)) {
                await fetch(END_POINTS.FIELD.FORMS.DOWNLOAD_FORM_RESPONSE_PDF, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        request: {
                            "id": id,
                            "token": this.props.getToken.payload.response.mobileToken
                        },
                        token: this.props.getToken.payload.response.token
                    })
                })
                .then((res: any) => {
                    return res.blob();
                })
                .then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${fileName}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((res) => console.log(res));
            };
        };
    }

    private handleFormResponseVersionHistory = async(data: any, page: string): Promise<void> => {
        if (hasPayload(this.props.getToken)) {
            await this.props.getFormVersionHistoryRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    id: data.id,
                    page: page
                }
            });
        }
        this.setState({ formResponseVersionHistoryPopup: true });
    }

    private handleFormResponseVersionHistoryPopupClose = (): void => {
        this.setState({ formResponseVersionHistoryPopup: false });
    };

    private onEdit = (e: any): void => {
        if(e.row.data.wsS_Log_ID > 0) {
            callRouteWithQueryString({
                route: this.props,
                search: { id: e.row.data.id.toString(), logId: e.row.data.wsS_Log_ID.toString(), page: "form_Responses" },
                pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
            });
        } 
        else {
            callRouteWithQueryString({
                route: this.props,
                search: { id: e.row.data.id.toString() },
                pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
            });
        }
    };

    private onClick = (): void => {

    };

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0", page: "form_Responses" },
            pathName: ROUTE.FIELD.FORMS.FORM_RESPONSE.ADD_UPDATE_FORM_RESPONSE
        });
    };

    private callServer = (): void => {

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {
            // if (pageAccessCheck(this.props.getToken, "formsAdmin") !== NotApplicable) {
                if (isNotLoaded(this.props.getFormResponses)) {
                    this.getDataForTable();
                };
            // } 
            // else {
            //     this.props.history.push({
            //         pathname: ROUTE.ACCESS_DENIED,
            //         search: getTokenFromUrl(false)
            //     });
            // }

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getFormResponsesRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    username: this.props.getToken.payload.response.upn,
                    view: "Today"
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IFormResponsesComponentStoreProps => ({
    getToken: getToken(state),
    getFormResponses: getFormResponses(state),
    // getFormResponseVersionHistoryByID: getFormResponseVersionHistoryByID(state),
    getFormVersionHistory: getFormVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IFormResponsesComponentDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getFormResponsesRequest: (data: IGetFormResponsesRequest) => dispatch(getFormResponsesLoadAction(data)),
    // getFormResponseVersionHistoryByIDRequest: (data: IGetFormResponseVersionHistoryByIDRequest) => dispatch(getFormResponseVersionHistoryByIDLoadAction(data)),
    getFormVersionHistoryRequest: (data: IGetFormVersionHistoryRequest) => dispatch(getFormVersionHistoryLoadAction(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(FormResponsesComponent);