
export enum IADD_OPERATOR_LOG_REQUEST {
    REQUEST = "addOperatorLog/ADD_OPERATOR_LOG_REQUEST",
    SUCCESS = "addOperatorLog/ADD_OPERATOR_LOG_SUCCESS",
    FAILED = "addOperatorLog/ADD_OPERATOR_LOG_FAILED"
};

export interface IAddOperatorLogRequest {
    token: string;
    request: IAddUpdateOperatorLog;
};

export interface IAddUpdateOperatorLog {
    ID?: number;
    Name: string;
    Date_: string;
    Foreman: string;
    Project: string;
    Job_ID: number;
    Start_Time_Val: string;
    End_Time_Val: string;
    Lunch_Val: string;
    Total_Hours: string;
    Travel: string;
    LOA: string;
    Comments: string;
    Weekends: string;
    Created_By: string;
    Equipments: IAddUpdateOperatorLogEquipment[];
}

export interface IAddUpdateOperatorLogEquipment {
    ID?: number;
    Unit_ID: number;
    Type: string;
    Hours: number;
    Loads: number;
    Work_Description: string;
    Walkarounds: IAddUpdateOperatorLogWalkarounds[];
};

export interface IAddUpdateOperatorLogWalkarounds {
    ID?: number;
    Response: string;
    Operator_Log_Equipment_Walk_Around_ID: number;
}