import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import React from 'react';
import { Grid, Button, Box, Modal, Backdrop, Typography, TextField, FormLabel, FormGroup, FormControlLabel, Checkbox, FormControl, Autocomplete, FormHelperText} from "@mui/material";
import { v1 as uuidv1 } from "uuid";
import {moveSingleEquipment, getLocationMappingInfo, getEquipmentListUnitByID, getEquipmentMoveStatusByUnit, getMoveRequestIDByEquipmentID, completeMoveRequest, getActiveUsers} from "./api/equipmenttracker/equipmentTrackerAPI";
import DraggableHover from "../../shared/equipmentHover";
import EquipmentMoveForm from './forms//equipmenttracker/equipmentMoveRequest';
import { InputLabel } from "@material-ui/core";
import LACenteredLoading from '../../shared/loading';
import CustomEquipmentMoveForm from "./forms/equipmenttracker/equipmentMoveCustom";
import {getSOCTWorkingSubSitesByParentID} from '../soct/api/workingsites/workingSitesAPI';
import CompleteMoveRequestPopup, {complete_move_request_modify_check, complete_move_request_move_check, complete_move_request_email_check} from "../../shared/completeMoveRequestPopup";
import { OutlinedRequiredStyle, MobileBoxStyle } from "../../shared/styles";
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from "../../shared/theme";

var searchItemHeight:any;

const getItemStyle = (isDragging:any, draggableStyle:any, item:any, searched:any, provided_element:any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    alignItems:'center',
    // change background colour if dragging
    // changes status to move_Status
    background: isDragging ? 'lightgreen' : (item.move_Status !== ''  && item.location_Type==='To_Location') ? GREEN_COLOR :  item.location_Type==='From_Location' ? ORANGE_COLOR: item.move_Status === 'Cancelled' ? '#28282b':'#28282b',
    border:'solid 2px lightblue',
    color:'white',
    fontWeight:'bold',
    width:'100px',
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  
  const getListStyle = (isDraggingOver:any, item:any, searched:any) => ({
    backgroundColor:isDraggingOver ? "lightblue": '#fff',
    //   border:location.status === 'active' ? 'lightgreen solid 10px' : location.status === 'inactive' ? 'red solid 10px': location.status === '' ? '#282c34 solid 10px':'yellow solid 10px',
    // border:'lightgrey solid 5px',
    border:searched.length !=locations.length ? searched.map((each:any) => {
        if(each.site_Name === item.site_Name){
            return 'solid 8px orange'
        }
        return 'lightgrey solid 5px'
        
    }): 'lightgrey solid 5px',
    padding: 4,
    //width:400,
    minWidth:400,
    minHeight: 150,
  });

  const hoverStyles = () => ({
      background:'white',
      '&:hover':{
          background:'red',
      },
  })
  
const grid = 1;
const BoxStyle:any = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'rgb(241, 241, 241)',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '4ch'
  };
  const ExitButtonStyle = {
    bgcolor: 'rgb(174, 180, 184)',
    color: 'white',
    fontWeight: 'bold',
  }
  var driverData:any;

interface IProps{
    locations:any[];
    equipment:any[];
    unitSearched:any[];
    loaded:any;
    refreshState:any;
}
interface IState{
    showDriverPopup:boolean;
    loaded:boolean;
    showConfirmation:boolean;
    showMoveRequest:boolean;
    showCustomMoveRequest:boolean;
    showCompletePopup:boolean;
    isFromRequest:boolean;
    freezeSave:boolean;
}

const gridXS = 12;

var equipment:any[] = [{id:uuidv1(),content:'ST-01', status:'active', driverInfo:'N/A'},
{id:uuidv1(),content:'ST-02', status:'active', driverInfo:'John Appleseed'},
{id:uuidv1(),content:'ST-03', status:'inactive', driverInfo:'Tim Cook'},
{id:uuidv1(),content:'ST-04', status:'active', driverInfo:'Jeff Bezos'},
{id:uuidv1(),content:'ST-05', status:'active', driverInfo:'Elon Musk'},
{id:uuidv1(),content:'ST-06', status:'sold', driverInfo:'Mark Zuck'}]

// Previous structure changed from object to array since UUID as key is hard to locate when searching/mapping
// var locations= {
//     [uuidv1()]:{location:'Calgary Ring Road', status:'active', items:equipment, id:1},
//     [uuidv1()]:{location:'Edmonton', status:'inactive',items:[], id:2},
//     [uuidv1()]:{location:'Red Deer', status:'active',items:[], id:3}, 
//     [uuidv1()]:{location:'Huntsville, SK', status:'active',items:[], id:4}};

var locations:any[]= [
    {uuid:uuidv1(), location:'Calgary Ring Road', status:'active', items:equipment, id:1},
    {uuid:uuidv1(),location:'Edmonton', status:'active',items:[], id:2},
    {uuid:uuidv1(),location:'Red Deer', status:'active',items:[], id:3}, 
    {uuid:uuidv1(),location:'Huntsville, SK', status:'active',items:[], id:4}];


    var moved = false;
    var temp_locations:any[] = [];
    var temp_equipment:any[] = [];
    var moved_item:any;
    var from_location:any;
    var to_location:any;
    var move_request:any = {unit_number:'', from_location:'', to_location:'', completed_date:'', comments:'', send_email:true, moved_item:{}, from_Sub_Site_Name:'', to_Sub_Site_Name:'',
     completed:false, drive_over:false, moved_previously:false};
    var equipment_move_request_clicked:any = {clicked_item:null,clicked_location:'', location_options:[]};
    var from_sub_loc_options:any[] = [];
    var to_sub_loc_options:any[] = [];
    var userList: string[] = [];
    var edit_id:number = 0;
    var isCompletedDateInPast:boolean = false;
    var disableEdit:boolean = false;
    var grid_xs_width:number = 4;

export default class DraggableItem  extends React.Component<IProps, IState>{
    myRef: React.RefObject<HTMLInputElement>;
    itemsRef:any;
    constructor(props:any){
        super(props);
        this.state = {showDriverPopup:false, loaded:false, showConfirmation:false, showMoveRequest:false, showCustomMoveRequest:false, showCompletePopup:false, isFromRequest:false, freezeSave:false};
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSingleSave = this.onSingleSave.bind(this);
        this.checkEquipmentStatus = this.checkEquipmentStatus.bind(this);
        this.myRef = React.createRef();
        this.itemsRef = {};
        this.openCustomMoveRequest = this.openCustomMoveRequest.bind(this);
        this.closeCompleteMovePopup = this.closeCompleteMovePopup.bind(this);
        this.confirmCompleteMovePopup = this.confirmCompleteMovePopup.bind(this);
    }
    closeCompleteMovePopup(){
        this.setState({showCompletePopup:false});
    }
    async confirmCompleteMovePopup(){
        if(edit_id != null && edit_id!= 0){
            var move_req_id:any = await getMoveRequestIDByEquipmentID(edit_id);
            if(move_req_id!=null){

                if(complete_move_request_modify_check){
                    this.setState({showCompletePopup:false});
                    window.location.replace("/field/equipmenttracker/equipmentmoves/edit/id="+move_req_id);
                }
                else if(complete_move_request_move_check){
                    var res:any = await completeMoveRequest(move_req_id, complete_move_request_email_check);
                    if(res.status === 200){
                    alert("Successfully completed move");
                    this.setState({showCompletePopup:false});
                    this.refreshGrid();
                    }
                }
                else{
                    alert("Please select an option");
                }
            }
            else{
                alert("Error finding move request please refresh the page");
            }
        }
        else{
            alert("Cannot find move, please refresh the page");
        }
    }
    handleChange(event:any){
        const fieldName = event.target.name;
        switch(fieldName){
            case 'completed_date':
                move_request.completed_date = event.target.value;
                isCompletedDateInPast = false;
                // create a current date
               let current_date_offset = new Date();
               // calculate yesterdays date
               current_date_offset.setDate(current_date_offset.getDate()-1);
               // use yesterdays date and get only date without timestamp
               current_date_offset = new Date(current_date_offset.getFullYear(),current_date_offset.getMonth(),current_date_offset.getDate());
               // replace - with / for completed date
               let move_req_date = new Date(move_request.completed_date.replace(/-/g,'/'));
               // get only date for completed date without timestamp
               move_req_date = new Date(move_req_date.getFullYear(),move_req_date.getMonth(),move_req_date.getDate());
               // if completed date is in the past besides yesterday make moved_previously mandatory
               if(move_req_date < current_date_offset){
                   isCompletedDateInPast = true;
               }
                break;
            case 'comments':
                move_request.comments = event.target.value;
                break;
            case 'send_email':
                move_request.send_email = !move_request.send_email;
                break;
            case 'completed':
                move_request.completed = !move_request.completed;
                break;
            case 'drive_over':
                move_request.drive_over = !move_request.drive_over;
                break;
            case 'moved_previously':
                move_request.moved_previously = !move_request.moved_previously;
                break;
        }
        this.setState({});
    }
    openCustomMoveRequest(location:any, event:any){
        equipment_move_request_clicked.clicked_location = location;
        //equipment_move_request_clicked.clicked_item = item;
        equipment_move_request_clicked.location_options = locations;
        this.setState({showCustomMoveRequest:true});
    }
    openMoveRequest(item:any, location:any, event:any){
        edit_id = item.equipment_ID;
        if(this.checkEquipmentStatus(item.move_Status)){
            equipment_move_request_clicked.clicked_location = location;
            equipment_move_request_clicked.clicked_item = item;
            equipment_move_request_clicked.location_options = locations;
            this.setState({showMoveRequest:true});
        }
    }
    closeMoveRequest(){
        this.setState({showMoveRequest:false, showCustomMoveRequest:false});
    }
    async onSingleSave(){
        let unit_status:any = await getEquipmentMoveStatusByUnit(move_request.moved_item.equipment_ID);
        if(unit_status != "Initiated" && unit_status!="Requested"){
            if(move_request.completed_date !="" && move_request.to_Sub_Site_Name!="" && move_request.to_Sub_Site_Name!=null){
                if(!move_request.moved_previously && isCompletedDateInPast){
                    alert("Moved previously must be checked if completed date is in the past");
                }
                else{
                    this.setState({freezeSave:true});
                    var res:any = await moveSingleEquipment(move_request);
                    if(res.status == 200){
                        this.setState({showConfirmation:false});
                        this.refreshGrid();
                    }
                }
            }
            else{
                alert("Completed date and sub site name must be entered");
            }
        }
        else{
            alert("This equipment already has an active move, cannot create another");
        }
    }
    handlePopup(elem:any,event:any){
        driverData = elem;
        this.setState({showDriverPopup:true});
      }
      closePopup(event:any){
        this.setState({showDriverPopup:false, showConfirmation:false})
        this.refreshGrid();
      }
    checkEquipmentStatus(status:any){
        if(status === "Initiated" || status === "Requested"){
            this.setState({showCompletePopup:true});
            return false;
        }
        else{
            return true;
        }
    }
    async onDragEnd(result:any){
        moved = true;
        const { source, destination } = result;

        if (!destination) {
            console.log('Outside any area')
            return;
        }
         if(source.droppableId !== destination.droppableId){
             console.log('Different container dropped');
             const sourceColumn = locations[source.droppableId];
             const destColumn = locations[destination.droppableId];
             const sourceItems = [...sourceColumn.items];
             const destItems = [...destColumn.items];
             const [removed] = sourceItems.splice(source.index, 1);
             edit_id = removed.equipment_ID;
             if(this.checkEquipmentStatus([removed][0].move_Status)){
                destItems.splice(destination.index, 0, removed);
                locations = {...locations, [source.droppableId]:{...sourceColumn, items: sourceItems}, [destination.droppableId]:{...destColumn, items:destItems}};
                move_request.moved_item = removed;
                moved_item = removed;
                from_location = sourceColumn;
                to_location = destColumn;
                move_request.from_location = sourceColumn;
                move_request.to_location= destColumn;
                move_request.to_location.id = destColumn.working_Site_ID;
                var unit_info:any = await getEquipmentListUnitByID(move_request.moved_item.equipment_ID);
                move_request.from_Sub_Site_Name = unit_info.sub_Location;
                var to_sub_Site_info:any = await getSOCTWorkingSubSitesByParentID(destColumn.working_Site_ID,true);
                 to_sub_loc_options = to_sub_Site_info.map((sub_location:any)=> {return sub_location.sub_Site_Name});
                 userList = await getActiveUsers();
                this.setState({showConfirmation:true});
             }
             //this.refreshGrid();
         }
         else{
            console.log('Same container dropped');
             const location = locations[source.droppableId];
             const copiedItems = [...location.items];
             const [removed] = copiedItems.splice(source.index, 1);
             copiedItems.splice(destination.index, 0, removed);
             locations = {...locations, [source.droppableId]:{...location, items:copiedItems}};
             //this.refreshGrid();
         }
         this.setState({});
    }

    async refreshGrid(){
        if(window.innerWidth <= 760){
            grid_xs_width = 9;
          }
          else{
            grid_xs_width = 4;
          }
        disableEdit = localStorage.getItem("ET-Role")==="R";
        if(disableEdit){
            this.setState({isFromRequest:true});
        }
        temp_equipment = [];
        temp_locations = [];
        move_request.send_email = true;
     
        locations = await getLocationMappingInfo();
       
        //temp_equipment = await getEquipmentListInfo();
        
        locations = locations;
        //equipment = temp_equipment;
        this.setState({loaded:true});
        this.props.refreshState();
    }
    
    async componentDidMount(){
        // locations = this.props.locations;
        // equipment = this.props.equipment;
        this.refreshGrid();
    }
    render(){
        if(this.state.loaded){
            // used for location/equipment search and reset
            locations =  this.props.locations;
            // console.log(this.props.unitSearched, locations)
            // if(this.props.unitSearched.length != locations.length && this.props.unitSearched.length > 0){
            //     locations = this.props.unitSearched;
            // }
           // equipment = this.props.unitSearched;

        return (
            <Grid container spacing={3} direction="row"> 
          
            <DragDropContext onDragEnd={this.onDragEnd} >
            {Object.entries(locations).map(([columnId, location], index:number) => {
                // if container contains more than 20 records use gridXS of 12
              return <Grid item xs={window.innerWidth <=760 ? 12 :location.items.length > 20 ? gridXS:3}>
                
                     <div style={{display:'flex', flexDirection:'row'}} key={columnId}>
                         {/* setting overflow to auto for this div allows horizontal scroll on each droppable container */}
                        <div style={{margin:8}}>
                            <Droppable droppableId={columnId} key={columnId} direction="horizontal">
                                {(provided, snapshot) => {
                                    return (
                                        <div 
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver, location, this.props.locations)}
                                   
                                          >
                                              {/* <div style={{color:'black', backgroundColor:'yellow', width:'75px',fontFamily:'Roboto, sans-serif', alignItems:'center'}}><Button style={{padding:'1px'}} onClick={this.openCustomMoveRequest.bind(this, location)}>?</Button></div> */}
                                              <h3 style={{color:'white', backgroundColor:'#bf0000', padding:'5px', width:window.innerWidth<=760?'50%':'100%'}} id="test">{location.map_Name}</h3>
                                              {/* allows items to display horizontally */}
                                              <div style={{display:'flex', flexWrap:'wrap'}}>
                                            {location.items.map((item:any, index:number) => {
                                                return (
                                            
                                                    <Draggable
                                                    key={item.guid}
                                                    draggableId={item.guid}
                                                    index={index}>
                                                        
                                                        {(provided, snapshot) => {
                                                            //this.myRef = item.guid;
                                                            return (
                                                                <Grid container 
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style,
                                                                        item,
                                                                        item,
                                                                        item.id
                                                                    )}>
                                                                       <Grid item xs={12}>
                                                                        {/* <Button style={{color:'#fff', fontFamily:'Roboto, sans-serif'}} variant="text"  onClick={this.handlePopup.bind(this, item)}>{item.content}</Button> */}
                                                                        {/* <div>{item.content}</div> */}
                                                                        <Button style={{color:'lightgrey', fontFamily:'Roboto, sans-serif', fontWeight:'bold'}} onClick={this.openMoveRequest.bind(this,item, location)}><DraggableHover hoverText={item.content} content={item.driver_Info + (item.details !== null ? " - " + item.details : "")}>
                                                                            </DraggableHover></Button>
                                                                 
                                                                    {/* <div style={{width:'100%', height:'5px', marginTop:'10px', backgroundColor:'white'}}></div> */}
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        }}
                                                    </Draggable>
                                                    
                                                );
                                            })}</div>
                                            {provided.placeholder}
                                        </div>
                                    );
                                }}
                            </Droppable>
                            {this.state.showDriverPopup ? <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}>
                    <Box sx={BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button><Grid style={{border: 'green solid '}} item xs={12}>Driver: {driverData.driver_Info}, Job Title: Foreman
                    <div style={{color:'black',backgroundColor:driverData.status ==='active' ? 'lightgreen':driverData.status==='sold'?'yellow':'red'}}>{driverData.status}</div></Grid> </Box>
                                                             
                    </Modal>:null}
                        </div>
                
                    </div>
          
                    </Grid>
                  })}
     
            </DragDropContext>
          
            {this.state.showConfirmation ? <Modal id='warehouse-emp-modal'
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={true}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    sx={{overflow:'auto'}}>
                    <Box sx={window.innerWidth < 760 ? MobileBoxStyle:BoxStyle}>
                    <Button sx={ExitButtonStyle} onClick={this.closePopup.bind(this)}>Close</Button>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography>Confirm and Enter Move Request Information</Typography>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled label="Unit Number" defaultValue={moved_item.content}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled label="From" defaultValue={from_location.site_Name}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <TextField disabled label="From Sub Location" defaultValue={move_request.from_Sub_Site_Name}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                            <InputLabel>Requested By</InputLabel>
                            <Autocomplete disablePortal
                                autoHighlight
                                value={move_request.requested_By}
                                options={userList}
                                onChange={async (event: any, newValue: String | null) => {
                                    move_request.requested_By = newValue;
                                    this.setState({});
                                }}                                
                                renderInput={(params): JSX.Element => <TextField color="primary" multiline variant="outlined" {...params} style={{ backgroundColor: 'white' }}></TextField>}></Autocomplete>
                        </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled label="To" defaultValue={to_location.site_Name}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <InputLabel>To Sub Location</InputLabel>
                      <Autocomplete  disablePortal 
                            autoHighlight
                            disabled={disableEdit}
                            options={to_sub_loc_options} 
                            onChange={async (event:any, newValue:String | null) => {
                              move_request.to_Sub_Site_Name = newValue;
                                this.setState({});
                            }}
                            renderInput={(params):JSX.Element => <TextField color="primary" variant="outlined" {...params} style={{backgroundColor:'white'}}></TextField>}></Autocomplete>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                          <InputLabel>Completed By</InputLabel>
                        <TextField disabled={disableEdit} style={{backgroundColor:'white'}} type="date" name="completed_date" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Send Notification Email</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.send_email} onChange={this.handleChange} name="send_email" disabled={disableEdit}/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Moved</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.completed} onChange={this.handleChange} name="completed" disabled={disableEdit}/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                    <FormLabel component="legend">Drive Over\Non-Billable</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Checkbox checked={move_request.drive_over} onChange={this.handleChange} name="drive_over" disabled={disableEdit}/>
                                            }
                                            label="Yes"
                                        />
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard" style={move_request.completed_date !="" && isCompletedDateInPast ? OutlinedRequiredStyle:null}>
                                    <FormLabel component="legend">Moved Previously?</FormLabel>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={
                                            <Checkbox onChange={this.handleChange} name="moved_previously" disabled={disableEdit}/>
                                            }
                                            label="Yes" 
                                        />
                                        <FormHelperText>This does not update equipment location</FormHelperText>
                                    </FormGroup>
                            </FormControl>
                      </Grid>
                      <Grid item xs={grid_xs_width}>
                        <TextField disabled={disableEdit} style={{backgroundColor:'white'}} fullWidth multiline rows={3} type="string" label="Request Details" name="comments" onChange={this.handleChange}></TextField>
                      </Grid>
                      <Grid item xs={6}>
                      <Button disabled={disableEdit} style={{width:'100px',backgroundColor:'#bf0000', left:'80%'}}variant="contained" onClick={this.onSingleSave}>Save</Button>
                      </Grid>
                    </Grid>
                    </Box>
                    </Modal>:null}
                    {this.state.showMoveRequest? <EquipmentMoveForm isFromRequest={this.state.isFromRequest} refreshGrid={this.refreshGrid.bind(this)} clickedLocation={equipment_move_request_clicked.clicked_location} clickedItem={equipment_move_request_clicked.clicked_item} locationOptions={equipment_move_request_clicked.location_options} closeEquipmentMoveForm={this.closeMoveRequest.bind(this)}></EquipmentMoveForm>:null}
                    {this.state.showCustomMoveRequest? <CustomEquipmentMoveForm refreshGrid={this.refreshGrid.bind(this)} clickedLocation={equipment_move_request_clicked.clicked_location} clickedItem={equipment_move_request_clicked.clicked_item} locationOptions={equipment_move_request_clicked.location_options} closeEquipmentMoveForm={this.closeMoveRequest.bind(this)}></CustomEquipmentMoveForm>:null}
                    {this.state.showCompletePopup? <CompleteMoveRequestPopup isApprovalButton={false} titleText={"Move already in progress"} closePopup={this.closeCompleteMovePopup} confirmPopup={this.confirmCompleteMovePopup}></CompleteMoveRequestPopup>:null}
            </Grid>
          
        );
                }
                else{
                    return <><div>Loading...</div><LACenteredLoading></LACenteredLoading><br></br></>;
                }
    }
}
export {locations,equipment};