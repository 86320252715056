import { IGetVendorOrderByID } from "../getVendorOrderByID/getVendorOrderByIDConstants";

export enum IADD_VENDOR_ORDER_REQUEST {
    REQUEST = "addVendorOrder/ADD_VENDOR_ORDER_REQUEST",
    SUCCESS = "addVendorOrder/ADD_VENDOR_ORDER_SUCCESS",
    FAILED = "addVendorOrder/ADD_VENDOR_ORDER_FAILED"
};

export interface IAddVendorOrderRequest {
    token: string;
    request: IGetVendorOrderByID;
};