import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_JOBS_MHR_REQUEST, IJobMhr, IJobsMhrRequest } from "./getJobsMhrConstants";


export interface IGetJobsMHRLoadAction {
    type: IGET_JOBS_MHR_REQUEST.REQUEST;
    data: IJobsMhrRequest
}
export const getJobsMHRLoadAction = (data: IJobsMhrRequest): IGetJobsMHRLoadAction => ({
    type: IGET_JOBS_MHR_REQUEST.REQUEST,
    data
});
export interface IGetJobsMHRSuccessAction {
    type: IGET_JOBS_MHR_REQUEST.SUCCESS;
    data: SurewayAPIResponse<ById<IJobMhr>>;
}
export const getJobsMHRLoadSuccessAction = (data: SurewayAPIResponse<ById<IJobMhr>>): IGetJobsMHRSuccessAction => ({
    type: IGET_JOBS_MHR_REQUEST.SUCCESS,
    data
});
export interface IGetJobsMHRLoadFailedAction {
    type: IGET_JOBS_MHR_REQUEST.FAILED;
    message: string;
}
export const getJobsMHRLoadFailedAction = (message: string): IGetJobsMHRLoadFailedAction => ({
    type: IGET_JOBS_MHR_REQUEST.FAILED,
    message
});
