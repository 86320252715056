import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_ATTACHMENTS_REQUEST, IAttachment } from "./getAttachmentsConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { IGetAttachmentsLoadAction, IGetAttachmentsLoadFailedAction, IGetAttachmentsSuccessAction, getAttachmentsLoadFailedAction, getAttachmentsLoadSuccessAction } from "./getAttachmentsActions";
import { withJsonContentType } from "../../../epicUtils";

export const getAttachmentsEpic: Epic = (
    action$: ActionsObservable<IGetAttachmentsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAttachmentsSuccessAction | IGetAttachmentsLoadFailedAction> =>
    action$.ofType(IGET_ATTACHMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IAttachment>>>(
                    END_POINTS.FIELD.ATTACHMENT_LIST.GET_ATTACHMENT_LIST,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IAttachment>>): IGetAttachmentsSuccessAction => {
                            const res: SurewayAPIResponse<ById<IAttachment>> = { callTime: new Date().toString(), ...response }; 
                            return getAttachmentsLoadSuccessAction(res);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getAttachmentsLoadFailedAction(response.message)))
                    )
            )
        );