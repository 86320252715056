import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_EQUIPMENT_TYPE_REQUEST, IUpdateEquipmentTypeRequest } from "./updateEquipmentTypeConstants";


export interface IUpdateEquipmentTypeLoadAction {
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.REQUEST;
    data: IUpdateEquipmentTypeRequest
}
export const updateEquipmentTypeLoadAction = (data: IUpdateEquipmentTypeRequest): IUpdateEquipmentTypeLoadAction => ({
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.REQUEST,
    data
});
export interface IUpdateEquipmentTypeSuccessAction {
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateEquipmentTypeLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateEquipmentTypeSuccessAction => ({
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.SUCCESS,
    message
});
export interface IUpdateEquipmentTypeLoadFailedAction {
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.FAILED;
    message: string;
}
export const updateEquipmentTypeLoadFailedAction = (message: string): IUpdateEquipmentTypeLoadFailedAction => ({
    type: IUPDATE_EQUIPMENT_TYPE_REQUEST.FAILED,
    message
});
