import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_LOCATION_REQUEST, ILocation } from "./getLocationConstants";
import { IGetLocationLoadAction, IGetLocationLoadFailedAction, IGetLocationSuccessAction } from "./getLocationActions";
import { IAddLocationSuccessAction } from "../addLocation/addLocationActions";
import { IUpdateLocationSuccessAction } from "../updateLocation/updateLocationActions";
import { IUPDATE_LOCATION_REQUEST } from "../updateLocation/updateLocationConstants";
import { IADD_LOCATION_REQUEST } from "../addLocation/addLocationConstants";

type Actions =
    | IGetLocationLoadAction
    | IGetLocationSuccessAction
    | IUpdateLocationSuccessAction
    | IAddLocationSuccessAction
    | IGetLocationLoadFailedAction
    | IFlushDataSuccessAction;

export const GetLocationReducer = (state: Server<SurewayAPIResponse<ById<ILocation>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<ILocation>>> => {
    switch (action.type) {
        case IGET_LOCATION_REQUEST.REQUEST:
            return loading;

        case IGET_LOCATION_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_LOCATION_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_LOCATION_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_LOCATION_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};