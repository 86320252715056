import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IFormResponseByID, IGET_FORM_RESPONSE_BY_ID_REQUEST } from "./getFormResponseByIDConstants";
import { IGetFormResponseByIDLoadAction, IGetFormResponseByIDLoadFailedAction, IGetFormResponseByIDSuccessAction } from "./getFormResponseByIDActions";
import { IAddFormResponseSuccessAction } from "../addFormResponse/addFormResponseActions";
import { IUpdateFormResponseSuccessAction } from "../updateFormResponse/updateFormResponseActions";
import { IADD_FORM_RESPONSE_REQUEST } from "../addFormResponse/addFormResponseConstants";
import { IUPDATE_FORM_RESPONSE_REQUEST } from "../updateFormResponse/updateFormResponseConstants";

type Actions =
    | IGetFormResponseByIDLoadAction
    | IGetFormResponseByIDSuccessAction
    | IGetFormResponseByIDLoadFailedAction
    | IAddFormResponseSuccessAction
    | IUpdateFormResponseSuccessAction
    | IFlushDataSuccessAction;

export const GetFormResponseByIDReducer = (state: Server<SurewayAPIResponse<IFormResponseByID>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IFormResponseByID>> => {
    switch (action.type) {
        case IGET_FORM_RESPONSE_BY_ID_REQUEST.REQUEST:
            return loading;

        case IGET_FORM_RESPONSE_BY_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_FORM_RESPONSE_BY_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IADD_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_FORM_RESPONSE_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};