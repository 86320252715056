import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGetAttachmentsLoadAction, IGetAttachmentsLoadFailedAction, IGetAttachmentsSuccessAction, getAttachmentsLoadFailedAction, getAttachmentsLoadSuccessAction } from "./getSurveyAttachmentsActions";
import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IAttachments, IGET_ATTACHMENTS_REQUEST } from './getSurveyAttachmentsConstants';

export const getSurveyAttachmentsEpic: Epic = (
    action$: ActionsObservable<IGetAttachmentsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetAttachmentsSuccessAction | IGetAttachmentsLoadFailedAction> =>
    action$.ofType(IGET_ATTACHMENTS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IAttachments>>>(
                    END_POINTS.SURVEY.GET_ATTACHMENTS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IAttachments>>): IGetAttachmentsSuccessAction => {
                            return getAttachmentsLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getAttachmentsLoadFailedAction(response.message)))
                    )
            )
        );