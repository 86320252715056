import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { MEDIA_QUERY_PHONE } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, IIdName, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { getTokenFromUrl, pageAccessCheck, userName } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import { RepairLinesSubHeader, RepairLinesSubHeaderMobile } from "../../header/repairLineSubHeader";
import LAErrorBox from "../../shared/errorBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { IGetMechanicLogsRequest, IMechanicLogs } from "../../../redux/field/repairLine/getMechanicLogs/getMechanicLogsConstants";
import { getMechanicLogs } from "../../../redux/field/repairLine/getMechanicLogs/getMechanicLogsAccessor";
import { getMechanicLogsLoadAction } from "../../../redux/field/repairLine/getMechanicLogs/getMechanicLogsActions";
import { IGetMechanicTimecardVersionHistory, IGetMechanicTimecardVersionHistoryRequest } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryConstants";
import { getMechanicTimecardVersionHistoryLoadAction } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryActions";
import { getMechanicTimecardVersionHistory } from "../../../redux/field/repairLine/getMechanicTimecardVersionHistory/getMechanicTimecardVersionHistoryAccessor";
import { MechanicLogsVersionHistory } from "./mechanicLogsVersionHistory";
import LADatePicker from "../../shared/datePicker";
import LALinkButton from "../../shared/linkButton";

interface IViewMechanicLogsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getMechanicLogs: Server<SurewayAPIResponse<IMechanicLogs[]>>;
    getMechanicLogsVersionHistory: Server<SurewayAPIResponse<ById<IGetMechanicTimecardVersionHistory>>>;
};

interface IViewMechanicLogsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getMechanicLogsRequest: (data: IGetMechanicLogsRequest) => unknown;
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest) => unknown;
};


interface IViewMechanicLogsOwnProps {

};

interface IViewMechanicLogsState {
    view: string;
    dateRange: Date[] | undefined,
    mechLogsVersionHistoryPopup: boolean;
};

const payrollAcknowlegedOptions: IIdName[] = [
    {id: 1, name: "Yes"},
    {id: 2, name: "No"}
]

const ViewMechanicLogsStyles = styled.div`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };
    
    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    .show-on-phone {
        display: none;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 5px 5px;

        .title {
            padding-top: 12%;
        }
        .pull-left {
            position: absolute;
            left: 3%;
            height: 3%;
            top: 2%;
            background-color: rgb(191, 0, 0);
        };

        .hide-on-phone, .hide-on-phone * {
            display: none;
        };

        .show-on-phone {
            display: block;
        };
    };
`;

type IViewMechanicLogsProps = RouteComponentProps
    & IViewMechanicLogsStoreProps
    & IViewMechanicLogsDispatchProps
    & IViewMechanicLogsOwnProps;

class ViewMechanicLogs extends PureComponent<IViewMechanicLogsProps, IViewMechanicLogsState> {

    public constructor(props: IViewMechanicLogsProps) {
        super(props);
        this.state = {
            view: "Defect",
            dateRange: [],
            mechLogsVersionHistoryPopup: false
        };
    }

    public componentDidMount(): void {
        this.getDataForTable();
        this.callServer();
        
    };

    public componentDidUpdate(prevProps: IViewMechanicLogsProps): void {
        if (this.props !== prevProps)
            this.callServer();
            
    };


    public render(): ReactNode {

        const { getMechanicLogs, getToken , getMechanicLogsVersionHistory } = this.props;
        const { view, mechLogsVersionHistoryPopup, dateRange } = this.state;
        const getRole = pageAccessCheck(getToken, "repairLine");
        const payRollAccess = hasPayload(this.props.getToken) && this.props.getToken.payload.response.payrollAccess;
        const data = hasPayload(getMechanicLogs) ? getMechanicLogs.payload.response && Object.values(getMechanicLogs.payload.response) : [];
        const mechanicLogsVersionHistorypopupData = hasPayload(getMechanicLogsVersionHistory) ? getMechanicLogsVersionHistory.payload.response : {};
        const records = dateRange ? data.filter((x) => dateRange[1] ? ((new Date(x.date) >= dateRange[0]) && (new Date(x.date) <= dateRange[1])) : true) : data
        // console.log(data)

        const onOpenDefectItem = (name: string, data: IMechanicLogs) => {
            // console.log(name, data);
            if(data.defect_ID && data.repair_ID)
                window.open(ROUTE.FIELD.REPAIR_LINE.REPAIR_LINE + "?id=" + data.repair_ID.toString() + "&defect_ID=" + data.defect_ID.toString()+ "&view=All")
        }

        return (
            <PageSpacing title="Mechanic Logs" description="FIELD - MECHANIC LOGS" fixedSpaceOnSmallerScreens={true}>
                {(getRole !== NotApplicable || payRollAccess) && <ViewMechanicLogsStyles>

                    <div className="hide-on-phone">
                        <RepairLinesSubHeader
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <div className="show-on-phone">
                        <RepairLinesSubHeaderMobile
                            {...this.props}
                            token={getToken}
                        />
                    </div>

                    <LAPaperWithPadding>
                        <LAGrid>
                            <LAGridItem xs={12} className="text-center">

                                <h2 className="title">VIEW MECHANIC LOGS</h2>
                                <hr />

                                <strong>
                                    Date Range: <LADatePicker
                                        key={"clear"}
                                        mode="range"
                                        value={dateRange}
                                        // minDate={minDate}
                                        // maxDate={maxDate}
                                        onChange={this.setDateRange}
                                    />
                                </strong>

                                <LALinkButton onClick={this.clearDateRange} label="clear"></LALinkButton>

                                {getMechanicLogs.kind === STATUS_ENUM.LOADING && <LALoading message="Loading mechanic logs..." />}
                                {getMechanicLogs.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load mechanic logs..." />}

                                {getMechanicLogs.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>
                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={view} onChange={this.onViewClick}>
                                                <FormControlLabel value="Defect" control={<Radio />} label="Defect Logs" />
                                                <FormControlLabel value="Misc" control={<Radio />} label="Misc Logs" />
                                                <FormControlLabel value="All" control={<Radio />} label="All" />
                                            </RadioGroup>
                                        </LAGridItem>
                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={records}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onClick={this.onClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                paging={40}
                                                actionWidth={90}
                                                removeStyleBtn={true}
                                                height={1200}
                                                onClearDateRange={this.clearDateRange}
                                                key="id"
                                                id="mechanicLogsList"
                                                customRowColor={true}
                                                exportFileName="MechanicLogs"
                                                storageKey="mechanicLogsListSessionKey"
                                                onCustomClick={this.handleMechLogsVersionHistoryClick}
                                                getRefreshDataCall={this.getDataForTable}
                                                columns={view !== "All" ?
                                                    [
                                                        { name: "date", caption: "Date", type: "date", sortDesc: true, width: 90 },
                                                        { name: "mechanic_Name", caption: "Mechanic Name", type: "string" },
                                                        { name: "contractor", caption: "Contractor", type: "string" },
                                                        { name: "display", caption: "Unit/Attachment#", type: "string", show: view === "Defect" },                                                   
                                                        { name: "defect_Item", caption: "Defect Item", type: "string", show: view === "Defect" ,  renderCell: (e:any) => { return <div className="link-btn" onClick={() => onOpenDefectItem("defect_Item", e.row.data)}>{e.row.data.defect_Item}</div>} },                                                   
                                                        { name: "charge_Location", caption: "Charge Location", type: "string", show: view === "Defect" },
                                                        { name: "comments", caption: "Comments", type: "string", show: view === "Defect" },
                                                        { name: "work_Order_No", caption: "WO/Job-CC", type: "string", show: view === "Defect" , 
                                                            renderCell: (e:any) => { 
                                                                return <div>{
                                                                    (e.row.data.job_No || e.row.data.cost_Code) ? 
                                                                        (e.row.data.job_No && e.row.data.job_No) + "-" + (e.row.data.cost_Code && e.row.data.cost_Code) : 
                                                                        e.row.data.work_Order_No && e.row.data.work_Order_No 
                                                                    } </div>
                                                            } 
                                                        },                                                   
                                                        { name: "component_Code", caption: "Component Code", type: "string", show: view === "Defect" },                                                   
                                                        { name: "type", caption: "Type", type: "string", show: view === "Misc" },
                                                        { name: "travel_Type", caption: "Travel Type", type: "string", show: view === "Misc" },
                                                        { name: "description", caption: "Description", type: "string", show: view === "Misc" },
                                                        { name: "hours", caption: "Hours", type: "number" },
                                                        { name: "parts_Delay", caption: "Parts Delay", type: "string", show: view === "Defect" },
                                                        { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number", show: view === "Defect" },
                                                        { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string", show: view === "Defect" },
                                                        { name: "status", caption: "Approval Status", type: "string" },
                                                        { name: "job_No", caption: "Job No", type: "string", show: view === "Misc" },
                                                        { name: "cost_Code", caption: "Cost Code", type: "string", show: view === "Misc" },
                                                        { name: "billable", caption: "Billable", type: "string", show: view === "Misc" },
                                                        { name: "billable_Percentage", caption: "Billable Percentage", type: "number", show: view === "Misc" },
                                                        { name: "history", caption: "Version History", type: "button" },
                                                    ] : 
                                                    [
                                                        { name: "date", caption: "Date", type: "date", sortDesc: true, width: 90 },
                                                        { name: "name", caption: "Mechanic Name", type: "string" },
                                                        { name: "contractor", caption: "Contractor", type: "string" },
                                                        { name: "display", caption: "Unit/Attachment#", type: "string"},         
                                                        { name: "defect_Item", caption: "Defect Item", type: "string",  renderCell: (e:any) => { return <div className="link-btn" onClick={() => onOpenDefectItem("defect_Item", e.row.data)}>{e.row.data.defect_Item}</div>} },                                                                                      
                                                        { name: "charge_Location", caption: "Charge Location", type: "string" },
                                                        { name: "comments", caption: "Comments", type: "string" },
                                                        { name: "work_Order_No", caption: "WO/Job-CC", type: "string",  
                                                            renderCell: (e:any) => { 
                                                                return <div>{
                                                                    (e.row.data.job_No || e.row.data.cost_Code) ? 
                                                                        (e.row.data.job_No && e.row.data.job_No) + "-" + (e.row.data.cost_Code && e.row.data.cost_Code) : 
                                                                        e.row.data.work_Order_No && e.row.data.work_Order_No 
                                                                    } </div>
                                                            } 
                                                        },
                                                        { name: "component_Code", caption: "Component Code", type: "string" },
                                                        { name: "hours", caption: "Hours", type: "number" },
                                                        { name: "parts_Delay", caption: "Parts Delay", type: "string" },
                                                        { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number" },
                                                        { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string" },
                                                        { name: "type", caption: "Type", type: "string"},
                                                        { name: "travel_Type", caption: "Travel Type", type: "string" },
                                                        { name: "description", caption: "Description", type: "string" },
                                                        { name: "status", caption: "Approval Status", type: "string" },
                                                        { name: "loa", caption: "LOA", type: "string" },
                                                        { name: "history", caption: "Version History", type: "button" },
                                                        { name: "field_Log", caption: "Field Log", type: "string", allowEditing: false },
                                                        { name: "uploaded_Date", caption: "Uploaded Date", type: "datetime",  allowEditing: false},
                                                        { name: "payroll_Acknowledged", caption: "Payroll Acknowleged", type: "string", allowEditing: false,  dropDownSource: payrollAcknowlegedOptions, dropDownName:"name", dropDownId: "name"},
                                                        { name: "parts_Delay", caption: "Parts Delay", type: "string",  allowEditing: false, show: false},
                                                        { name: "parts_Delay_Hours", caption: "Parts Delay Hours", type: "number",  allowEditing: false, show: false},
                                                        { name: "parts_Delay_Comments", caption: "Parts Delay Comments", type: "string",  allowEditing: false, show: false},
                                                        { name: "truck_Hours", caption: "Travel Hours", type: "number",  allowEditing: false, show: false},
                                                        { name: "description", caption: "Description", type: "string",  allowEditing: false, show: false},
                                                        { name: "employee_Code", caption: "Employee Code", type: "string",  allowEditing: false, show: false},
                                                        { name: "repair_Location", caption: "Repair Location", type: "string",  allowEditing: false, show: false},
                                                        { name: "status", caption: "Status", type: "string",  allowEditing: false, show: false},
                                                        { name: "issue", caption: "Issue", type: "string",  allowEditing: false, show: false},
                                                        { name: "item_Repair_Location", caption: "Item Repair Location", type: "string",  allowEditing: false, show: false},
                                                        { name: "manager_Instructions", caption: "Manager Instructions", type: "string",  allowEditing: false, show: false},
                                                        { name: "defect_Status", caption: "Defect Status", type: "string",  allowEditing: false, show: false},
                                                        { name: "damaged", caption: "Damaged", type: "string",  allowEditing: false, show: false},
                                                        { name: "warranty", caption: "Warranty", type: "string",  allowEditing: false, show: false},
                                                        { name: "major_Component_Replacement", caption: "Major Component Replacement", type: "string",  allowEditing: false, show: false},
                                                        { name: "serial_No", caption: "Serial No", type: "string",  allowEditing: false, show: false},
                                                        { name: "major_Component_Replacement_Type", caption: "Major Component Replacement Type", type: "string",  allowEditing: false, show: false},
                                                        { name: "major_Component_Replacement_Hours", caption: "Major Component Replacement Hours", type: "number",  allowEditing: false, show: false},
                                                        { name: "major_Component_Replacement_Notes", caption: "Major Component Replacement Notes", type: "string",  allowEditing: false, show: false},
                                                        { name: "rejection_Notes", caption: "Rejection Notes", type: "string",  allowEditing: false, show: false},
                                                        { name: "modified_After_Upload", caption: "Modified After Upload", type: "string", show: false},
                                                    ]
                                                }
                                            />
                                        </LAGridItem>
                                        {mechLogsVersionHistoryPopup && <MechanicLogsVersionHistory
                                            data={mechanicLogsVersionHistorypopupData}
                                            // display={display}
                                            open={mechLogsVersionHistoryPopup}
                                            status={getMechanicLogsVersionHistory.kind}
                                            onCancel={this.handleMechLogsVersionHistoryPopupClose}
                                        />}

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>
                    </LAPaperWithPadding>
                </ViewMechanicLogsStyles>}
            </PageSpacing>
        );
    }

    private onViewClick = async(e:any, value: string): Promise<void> => {
        await this.setState({ view: value });
        localStorage.removeItem("mechanicLogsListSessionKey")
        this.getDataForTable();
    };

    private onClick = (): void => {

    };

    private setDateRange = (date: string, date2?: string): void => {
        if (date2) {
            this.setState({ dateRange: [new Date(date), new Date(date2)] });
        } else {
            this.setState({ dateRange: [new Date(date)] });
        }
    };

    private clearDateRange = (): void => {
        this.setState({ dateRange: undefined });
    };

    private handleMechLogsVersionHistoryPopupClose = (): void => {
        this.setState({ mechLogsVersionHistoryPopup: false });
    };

    private handleMechLogsVersionHistoryClick = async (name: string, e: any): Promise<void> => {
        // console.log(e.row.data)
        if (hasPayload(this.props.getToken)) {
            if(e.row.data.defect_ID > 0)
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Defect"
                    }
                });
            else {
                this.props.getMechanicLogsVersionHistoryRequest({
                    token: this.props.getToken.payload.response.token,
                    request: {
                        ID: e.row.data.id,
                        Type: "Misc"
                    }
                });
            }
        }
        await this.setState({ mechLogsVersionHistoryPopup: true });
    }

    private callServer = (): void => {
        localStorage.removeItem("mechanicLogsListSessionKey")
        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "repairLine") !== NotApplicable) {
                if (isNotLoaded(this.props.getMechanicLogs)) {
                    this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getMechanicLogsRequest({
                token: this.props.getToken.payload.response.token,
                request: {
                    view: this.state.view
                }
            });
        };
    };

}

const mapStateToProps = (state: IStore): IViewMechanicLogsStoreProps => ({
    getToken: getToken(state),
    getMechanicLogs: getMechanicLogs(state),
    getMechanicLogsVersionHistory: getMechanicTimecardVersionHistory(state),
});

const mapDispatchToProps = (dispatch: IDispatch): IViewMechanicLogsDispatchProps => ({
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request)),
    getMechanicLogsRequest: (data: IGetMechanicLogsRequest) => dispatch(getMechanicLogsLoadAction(data)),
    getMechanicLogsVersionHistoryRequest: (request: IGetMechanicTimecardVersionHistoryRequest): unknown => dispatch(getMechanicTimecardVersionHistoryLoadAction(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMechanicLogs);