import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST, IServiceHistory, IGetServiceHistoryBySOCTIDRequest } from "./getServiceHistoryBySOCTIDConstants";

export interface IGetServiceHistoryBySOCTIDLoadAction {
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.REQUEST;
    data: IGetServiceHistoryBySOCTIDRequest
}

export const getServiceHistoryBySOCTIDLoadAction = (data: IGetServiceHistoryBySOCTIDRequest): IGetServiceHistoryBySOCTIDLoadAction => ({
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.REQUEST,
    data
});

export interface IGetServiceHistoryBySOCTIDSuccessAction {
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IServiceHistory>>;
}

export const getServiceHistoryBySOCTIDLoadSuccessAction = (list: SurewayAPIResponse<ById<IServiceHistory>>): IGetServiceHistoryBySOCTIDSuccessAction => ({
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.SUCCESS,
    list
});

export interface IGetServiceHistoryBySOCTIDLoadFailedAction {
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.FAILED;
    message: string;
}

export const getServiceHistoryBySOCTIDLoadFailedAction = (message: string): IGetServiceHistoryBySOCTIDLoadFailedAction => ({
    type: IGET_SERVICE_HISTORY_BY_SOCT_ID_REQUEST.FAILED,
    message
});
