"use client";
import React, { useState, useEffect } from "react";
import { Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Box, Chip } from "@mui/material";
import styled from "styled-components";
import { IIdName } from "./publicInterfaces";
import { RED_COLOR } from "./theme";

const LAMultiSelectDropdownStyles = styled.div`
    .error-text {
        color: ${RED_COLOR};
        font-size: 12px;
    };
`;

interface IMultiSelectDropdownProps {
  options: IIdName[];
  selectedOptions: any[];
  selectAllOption: boolean;
  onChange: (value: string[]) => void;
  valueExpr: string;
  displayExpr: string;
  errorText?: string;
}

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: 500,
//       width: 550,
//     },
//   },
// };

export const LAMultiSelectDropdown: React.FC<IMultiSelectDropdownProps> = React.memo((props) => {
  const [selected, setSelected] = useState<string[]>(props.selectedOptions);
  const errorText: string | undefined = props.errorText;
  const error: true | undefined = errorText ? true : undefined;

  useEffect(() => {
    setSelected(props.selectedOptions);
  }, [props.selectedOptions]);

  const isAllSelected = props.options.length > 0 && selected.length === props.options.length;

  const handleChange = (event: any) => {
    const value = event.target.value;
    // console.log(value)
    // console.log(selected)
    if (value.includes("all")) {
      const newSelected = (selected && selected.length === props.options.length) ? [] : props.options.map((item:any) => item[props.valueExpr]);
      // console.log(newSelected)
      props.onChange(newSelected);
      return;
    }
    props.onChange(value);
  };

  return (
    <LAMultiSelectDropdownStyles>
      <FormControl sx={{ width: '100%' }}>
        <Select
          labelId="multiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          error={error}
          renderValue={(selected:any) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value:string) => {
                // console.log(value)
                const find:any = props.options.find((x:any) => x[props.valueExpr] === value)
                return <Chip key={value} label={find[props.displayExpr]} />
              }
              )}
            </Box>
          )}
          // MenuProps={MenuProps}
        >
          {props.selectAllOption && <MenuItem
            value="all"
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selected.length > 0 && selected.length < props.options.length}
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>}
          {props.options.map((option:any) => (
            <MenuItem key={option[props.valueExpr]} value={option[props.valueExpr]}>
              <ListItemIcon>
                <Checkbox name="select-checkbox" checked={selected.includes(option[props.valueExpr]) } />
              </ListItemIcon>
              <ListItemText primary={option[props.displayExpr]} />
            </MenuItem>
          ))}
        </Select>
        {props.errorText && <span className="error-text">{props.errorText}</span>}
      </FormControl>
    </LAMultiSelectDropdownStyles>
  );
});


// export default React.memo(LAMultiSelectDropdown);
