import { ISurewayTokenRequestBody } from "../../../../react/shared/publicInterfaces";

export enum IGET_MECHANICS_REQUEST {
    REQUEST = "getMechanics/GET_MECHANICS_REQUEST",
    SUCCESS = "getMechanics/GET_MECHANICS_SUCCESS",
    FAILED = "getMechanics/GET_MECHANICS_FAILED"
};

export interface IGetMechanicsRequest extends ISurewayTokenRequestBody {

};

export interface IMechanic {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    status: string;
    loginInfo: boolean;
    created_By: string;
    created: string;
    modified_By: string;
    modified: string;
    company: string;
    phone: string;
    active: string;
    comments: string;
    type: string;
    employee_Code: string;
    contractor: string;
};