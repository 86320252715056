// import { ById } from '../../../../react/shared/publicInterfaces';
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST, IPayrollMechanicLogsReport, IGetPayrollMechanicLogsReportRequest } from "./getPayrollMechanicLogsReportConstants";

export interface IGetPayrollMechanicLogsReportLoadAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.REQUEST;
    data: IGetPayrollMechanicLogsReportRequest
}
export const getPayrollMechanicLogsReportLoadAction = (data: IGetPayrollMechanicLogsReportRequest): IGetPayrollMechanicLogsReportLoadAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.REQUEST,
    data
});
export interface IGetPayrollMechanicLogsReportSuccessAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IPayrollMechanicLogsReport[]>;
}
export const getPayrollMechanicLogsReportLoadSuccessAction = (list: SurewayAPIResponse<IPayrollMechanicLogsReport[]>): IGetPayrollMechanicLogsReportSuccessAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.SUCCESS,
    list
});
export interface IGetPayrollMechanicLogsReportLoadFailedAction {
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.FAILED;
    message: string;
}
export const getPayrollMechanicLogsReportLoadFailedAction = (message: string): IGetPayrollMechanicLogsReportLoadFailedAction => ({
    type: IGET_PAYROLL_MECHANIC_LOGS_REPORT_REQUEST.FAILED,
    message
});
