import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import queryString from "query-string";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { FieldValidator, FIELD_VALIDATOR_ERRORS, IFieldErrorKeyValue } from "../../shared/fieldValidator";
import { LAPaperWithLessPadding, LAPaperWithPadding } from "../../shared/paper";
import { LIGHT_GREY_COLOR, MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { getTokenFromUrl, isWeekend, undefinedFunction, ZEROTH } from "../../shared/constExports";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import { LAButton, LAIconButton, LASaveAndCancelButton } from "../../shared/buttons";
import { AddIcon, ArrowLeftIcon, DeleteIcon } from "../../shared/icons";
import LATextField from "../../shared/textField";
import LAAutoComplete from "../../shared/autoComplete";
import RequestStatus from "../../shared/requestStatusSnackbar";
import { ROUTE } from "../../routes";
import { IDispatch, IStore } from "../../../redux/reducers";
import LAErrorBox from "../../shared/errorBox";
import { IGetOperatorLogsRequest, IOperatorLogs } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsConstants";
import { IAddOperatorLogRequest, IAddUpdateOperatorLogEquipment, IAddUpdateOperatorLogWalkarounds } from "../../../redux/field/operatorLogs/addOperatorLog/addOperatorLogConstants";
import { IUpdateOperatorLogRequest } from "../../../redux/field/operatorLogs/updateOperatorLog/updateOperatorLogConstants";
import { IValidateOperatorLogin } from "../../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginConstants";
import { addOperatorLogStatus } from "../../../redux/field/operatorLogs/addOperatorLog/addOperatorLogAccessor";
import { updateOperatorLogStatus } from "../../../redux/field/operatorLogs/updateOperatorLog/updateOperatorLogAccessor";
import { validateOperatorLogin } from "../../../redux/field/operatorLogs/validateOperatorLogin/validateOperatorLoginAccessor";
import { getOperatorLogsLoadAction } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsActions";
import { addOperatorLogLoadAction } from "../../../redux/field/operatorLogs/addOperatorLog/addOperatorLogActions";
import { updateOperatorLogLoadAction } from "../../../redux/field/operatorLogs/updateOperatorLog/updateOperatorLogActions";
import { getFieldLookups } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsAccessor";
import { IFieldLookup, IFieldLookupRequest } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsConstants";
import { getFieldLookupsLoadAction } from "../../../redux/field/workingSiteLogs/getFieldLookups/getFieldLookupsActions";
import LADatePicker from "../../shared/datePicker";
import LATextArea from "../../shared/textArea";
import { LACheckBox } from "../../shared/checkBox";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getOperatorLogs } from "../../../redux/field/operatorLogs/getOperatorLogs/getOperatorLogsAccessor";
import LALinkButton from "../../shared/linkButton";
import { LAPopover } from "../../shared/popOver";
import UnitDetails from "../../field/equipment/unitDetails";
import ExternalUserLogoutButton from "../logOut/externalUserLogOutButton";

const RequiredFields: string[] = ["unit_ID","hours", "job_ID", "foreman" ];

interface IAddUpdateOperatorLogComponentStoreProps {
    getLookup: Server<SurewayAPIResponse<IFieldLookup>>;
    addOperatorLogStatus: Server<SurewayAPIResponse<string>>;
    getLogs: Server<SurewayAPIResponse<ById<IOperatorLogs>>>;
    validateOperatorLoginStatus: Server<IValidateOperatorLogin>;
    updateOperatorLogStatus: Server<SurewayAPIResponse<string>>;
};

interface IAddUpdateOperatorLogComponentDispatchProps {
    getLookupsRequest: (data: IFieldLookupRequest) => unknown;
    addOperatorLogRequest: (data: IAddOperatorLogRequest) => unknown;
    getOperatorLogsRequest: (data: IGetOperatorLogsRequest) => unknown
    updateOperatorLogRequest: (data: IUpdateOperatorLogRequest) => unknown;
};

interface IAddUpdateOperatorLogOwnProps {
    id?: string;
};

interface IAddUpdateOperatorLogComponentState {
    data: IOperatorLogs;
    serverError: string;
    unitId: number | undefined;
    errors: ById<IFieldErrorKeyValue>;
};

const AddUpdateOperatorLogStyles = styled(LAPaperWithPadding)`
    margin: 40px 40px;

    .odd-equipment {
        background-color: ${LIGHT_GREY_COLOR}
    }
    
    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        margin: 10px 10px;
    };
`;

type IAddUpdateOperatorLogComponentProps =
    RouteComponentProps
    & IAddUpdateOperatorLogOwnProps
    & IAddUpdateOperatorLogComponentStoreProps
    & IAddUpdateOperatorLogComponentDispatchProps;

class AddUpdateOperatorLog extends PureComponent<IAddUpdateOperatorLogComponentProps, IAddUpdateOperatorLogComponentState> {

    public constructor(props: IAddUpdateOperatorLogComponentProps) {
        super(props);
        this.state = {
            data: {
                id: 0,
                weekends: "No",
                date_: "",
                name: "",
                project: "",
                job_ID: 0,
                foreman: "",
                start_Time: "",
                end_Time: "",
                lunch: "",
                total_Hours: "",
                travel: "Yes",
                loa: "No",
                comments: "",
                hurt_Today: "",
                created_By: "",
                modified_By: "",
                created: "",
                modified: "",
                start_Time_Val: "",
                end_Time_Val: "",
                lunch_Val: "",
                total_Hours_Val: "",
                equipments: []
            },
            unitId: undefined,
            errors: {},
            serverError: ""
        };
    }

    public componentDidMount(): void {
        this.getLook();
        this.setDataToState();
    };

    public componentDidUpdate(prevProps: IAddUpdateOperatorLogComponentProps): void {
        if (this.props !== prevProps) {
            this.setDataToState();

            if (this.props.addOperatorLogStatus !== prevProps.addOperatorLogStatus) {

                if (this.props.addOperatorLogStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.addOperatorLogStatus.message });

                if (hasPayload(this.props.addOperatorLogStatus) && this.props.addOperatorLogStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            };

            if (this.props.updateOperatorLogStatus !== prevProps.updateOperatorLogStatus) {

                if (this.props.updateOperatorLogStatus.kind === STATUS_ENUM.FAILED)
                    this.setState({ serverError: this.props.updateOperatorLogStatus.message });

                if (hasPayload(this.props.updateOperatorLogStatus) && this.props.updateOperatorLogStatus.kind === STATUS_ENUM.SUCCEEDED)
                    this.handleCancel();
            }
        }
    };


    public render(): ReactNode {

        const { data, errors, serverError, unitId } = this.state;
        // console.log(data)
        const { addOperatorLogStatus, updateOperatorLogStatus, getLookup } = this.props;
        const units = hasPayload(getLookup) ? getLookup.payload.response.units : [];
        // console.log(this.props.validateOperatorLoginStatus.payload?.email)
        const workAroundList = hasPayload(getLookup) ? getLookup.payload.response.workAroundList : [];
        const jobList = hasPayload(getLookup) ? getLookup.payload.response.jobs : [];
        const foremans = hasPayload(getLookup) ? getLookup.payload.response.foremanList : [];

        const onJob = (event: unknown, value: any): void => this.handleChange("job_ID", value !== null ? value.id : data.job_ID);
        const onForeman = (event: unknown, value: any): void => this.handleChange("foreman", value !== null ? value.upn : data.foreman);
   
        return (
            <AddUpdateOperatorLogStyles>
                <ExternalUserLogoutButton />

                <LAGrid spacing={1}>

                    <LAGridItem xs={12} sm={12} md={8}>
                        <LAButton
                            label="Back to List"
                            onClick={this.handleCancel}
                            startIcon={<ArrowLeftIcon color={WHITE_COLOR} />}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={12} className="text-center">
                        <h2>OPERATOR LOG</h2>
                        <hr />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            label="Name"
                            fullWidth={true}
                            variant="outlined"
                            name="name"
                            value={data.name}
                            onChange={this.handleChange}
                            disabled={true}
                            errorText={errors["name"] ? errors["name"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextField
                            label="Project"
                            fullWidth={true}
                            variant="outlined"
                            name="project"
                            value={data.project}
                            onChange={this.handleChange}
                            errorText={errors["project"] ? errors["project"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4} className="text-center">
                        <strong>Date</strong>
                        <br />
                        <LADatePicker
                            id="date"
                            mode="single"
                            value={data.date_}
                            onChange={this.handleDate}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={jobList}
                            onChange={onJob}
                            autoHighlight={true}
                            getOptionLabel="job_No"
                            dropDownPlaceHolder="Job #"
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            errorText={errors["job_ID"] ? errors["job_ID"].message : undefined}
                            value={data.job_ID ? jobList.find((q:any) => q.id === data.job_ID) : null}
                            defaultValue={data.job_ID ? jobList.find((q:any)=> q.id === data.job_ID) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LAAutoComplete
                            multiple={false}
                            option={foremans}
                            onChange={onForeman}
                            autoHighlight={true}
                            getOptionLabel="display_Name"
                            dropDownPlaceHolder="Foreman"
                            filterSelectedOptions={true}
                            selectionRemove={undefinedFunction}
                            errorText={errors["foreman"] ? errors["foreman"].message : undefined}
                            value={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                            defaultValue={data.foreman ? foremans.find(q => q.upn === data.foreman) : null}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <></>
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4} className="text-center">
                        <strong>Start Time</strong>
                        <br />
                        <LADatePicker
                            id="date"
                            mode="time"
                            value={data.start_Time_Val}
                            onChange={this.handleStart}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4} className="text-center">
                        <strong>Lunch Time</strong>
                        <br />
                        <LADatePicker
                            id="date"
                            mode="time"
                            value={data.lunch_Val}
                            onChange={this.handleLunch}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4} className="text-center">
                        <strong>End Time</strong>
                        <br />
                        <LADatePicker
                            id="date"
                            mode="time"
                            value={data.end_Time_Val}
                            onChange={this.handleEnd}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <LATextArea
                            rowsMax={4}
                            minRows={3}
                            label="Comments"
                            fullWidth={true}
                            variant="outlined"
                            name="comments"
                            value={data.comments}
                            onChange={this.handleChange}
                            errorText={errors["comments"] ? errors["comments"].message : undefined}
                        />
                    </LAGridItem>

                    <LAGridItem xs={12} sm={6} md={4}>
                        <RadioGroup row aria-label="" name="radioGroup" value={data.travel === "Yes" ? "travel" : data.loa === "Yes" ? "loa" : ""} onChange={this.onTravelCheck}>
                            <FormControlLabel value="travel" control={<Radio />} label="Travel" />
                            <FormControlLabel value="loa" control={<Radio />} label="LOA" />
                        </RadioGroup>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <LAGrid>
                            <LAIconButton
                                label="Add New"
                                icon={<AddIcon />}
                                onClick={this.onEquipmentAdd} 
                            /> 
                            {data.equipments.map((x, index) => {
                                const onUnitDetails = (): void => this.handleUnitDetails(x.unit_ID);
                                const onEqp = (name: string, value: string): void => this.handleEqp(name, value, index);
                                const onUnit = (event: unknown, value: any): void => this.handleEqp("unit_ID", value !== null ? value.id : x.unit_ID, index);
                                const unit = x.unit_ID && units && units.length > 0 ? units.find(q => q.id === x.unit_ID) : null;
                                return (
                                    <LAGridItem key={index} xs={12}>
                                        <LAPaperWithLessPadding>
                                            <LAGrid className= {index % 2 === 0 ? "" : "odd-equipment"}>

                                                <LAGridItem xs={12} sm={4} md={2}>
                                                    <LAAutoComplete
                                                        multiple={false}
                                                        option={units}
                                                        onChange={onUnit}
                                                        autoHighlight={true}
                                                        getOptionLabel="unit_Number"
                                                        dropDownPlaceHolder="Unit"
                                                        filterSelectedOptions={true}
                                                        selectionRemove={undefinedFunction}
                                                        errorText={errors["unit_ID"] ? errors["unit_ID"].message : undefined}
                                                        value={unit}
                                                        defaultValue={unit}
                                                    />
                                                    {(x.unit_ID > 0) &&
                                                        <LALinkButton
                                                            label="Unit Details"
                                                            onClick={onUnitDetails}
                                                        />}
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={4} md={2}>
                                                    <LATextField
                                                        label="Type"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="type"
                                                        value={x.type}
                                                        onChange={onEqp}
                                                        errorText={errors["type"] ? errors["type"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={4} md={2}>
                                                    <LATextField
                                                        label="Hours"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="hours"
                                                        value={x.hours}
                                                        type="number"
                                                        onChange={onEqp}
                                                        errorText={errors["hours"] ? errors["hours"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={4} md={2}>
                                                    <LATextField
                                                        label="Loads"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="loads"
                                                        value={x.loads}
                                                        onChange={onEqp}
                                                        errorText={errors["loads"] ? errors["loads"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12} sm={4} md={4}>
                                                    <LATextField
                                                        label="Work Description"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        name="work_Description"
                                                        value={x.work_Description}
                                                        onChange={onEqp}
                                                        errorText={errors["work_Description"] ? errors["work_Description"].message : undefined}
                                                    />
                                                </LAGridItem>

                                                <LAGridItem xs={12} className="text-center">
                                                    <br />
                                                    <strong>WORK AROUND</strong>
                                                    <hr />
                                                </LAGridItem>
                                                
                                                {/* <LAGridItem xs={6}>
                                                    <LAGrid>
                                                        {workAroundList
                                                            .slice(0, workAroundList.length / 2)
                                                            .map((e, ind) => {
                                                                const onOkCheck = (checked: boolean): void => this.onOkCheck(checked, index, ind);
                                                                const onREPCheck = (checked: boolean): void => this.onRepCheck(checked, index, ind);
                                                                // const wa = workAroundList.find(d => d.id === e.operator_Log_Equipment_Walk_Around_ID);

                                                                return (
                                                                    <LAGridItem xs={12} key={ind}>
                                                                        <LAGrid spacing={0}>

                                                                            <LAGridItem xs={4}>
                                                                                <strong>{e ? e.name : ""}</strong>
                                                                            </LAGridItem>

                                                                            <LAGridItem xs={4}>
                                                                                <LACheckBox
                                                                                    label="OK"
                                                                                    onChange={onOkCheck}
                                                                                    value={true}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="REP"
                                                                                    onChange={onREPCheck}
                                                                                    value={ false}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAGridItem>
                                                                )
                                                            })}
                                                    </LAGrid>
                                                </LAGridItem>

                                                <LAGridItem xs={6}>
                                                    <LAGrid>
                                                        {workAroundList
                                                            .slice(workAroundList.length / 2, workAroundList.length )
                                                            .map((e, ind) => {
                                                                const onOkCheck = (checked: boolean): void => this.onOkCheck(checked, index, ind);
                                                                const onREPCheck = (checked: boolean): void => this.onRepCheck(checked, index, ind);
                                                                // const wa = workAroundList.find(d => d.id === e.operator_Log_Equipment_Walk_Around_ID);

                                                                return (
                                                                    <LAGridItem xs={12} key={ind}>
                                                                        <LAGrid spacing={0}>

                                                                            <LAGridItem xs={4}>
                                                                                <strong>{e ? e.name : ""}</strong>
                                                                            </LAGridItem>

                                                                            <LAGridItem xs={4}>
                                                                                <LACheckBox
                                                                                    label="OK"
                                                                                    onChange={onOkCheck}
                                                                                    value={x.walkarounds[index].response === "Ok" ? true : false}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="REP"
                                                                                    onChange={onREPCheck}
                                                                                    value={x.walkarounds[index].response === "Ok" ? true : false}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAGridItem>
                                                                )
                                                            })}
                                                    </LAGrid>
                                                </LAGridItem> */}
                                                
                                                <LAGridItem xs={6}>
                                                    <LAGrid>
                                                        {x.walkarounds
                                                            .slice(0, x.walkarounds.length / 2)
                                                            .map((e, ind) => {
                                                                const onOkCheck = (checked: boolean): void => this.onOkCheck(checked, index, ind);
                                                                const onREPCheck = (checked: boolean): void => this.onRepCheck(checked, index, ind);
                                                                const onNACheck = (checked: boolean): void => this.onNACheck(checked, index, ind);
                                                                const wa = workAroundList.find(d => d.id === e.operator_Log_Equipment_Walk_Around_ID);

                                                                return (
                                                                    <LAGridItem xs={12} key={ind}>
                                                                        <LAGrid spacing={0}>

                                                                            <LAGridItem xs={4}>
                                                                                <strong>{wa ? wa.name : ""}</strong>
                                                                            </LAGridItem>

                                                                            <LAGridItem xs={4}>
                                                                                <LACheckBox
                                                                                    label="OK"
                                                                                    onChange={onOkCheck}
                                                                                    value={e.response === "OK" ? true : false}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="REP"
                                                                                    onChange={onREPCheck}
                                                                                    value={e.response === "REP" ? true : false}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="NA"
                                                                                    onChange={onNACheck}
                                                                                    value={e.response === "NA" ? true : false}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAGridItem>
                                                                )
                                                            })}
                                                    </LAGrid>
                                                </LAGridItem>

                                                <LAGridItem xs={6}>
                                                    <LAGrid>

                                                        {x.walkarounds
                                                            .slice(x.walkarounds.length / 2, x.walkarounds.length)
                                                            .map((e, ind) => {
                                                                const onOkCheck = (checked: boolean): void => this.onOkCheck(checked, index, (x.walkarounds.length / 2) + ind);
                                                                const onREPCheck = (checked: boolean): void => this.onRepCheck(checked, index, (x.walkarounds.length / 2) + ind);
                                                                const onNACheck = (checked: boolean): void => this.onNACheck(checked, index, (x.walkarounds.length / 2) + ind);
                                                                
                                                                const wa = workAroundList.find(d => d.id === e.operator_Log_Equipment_Walk_Around_ID);

                                                                return (
                                                                    <LAGridItem xs={12} key={ind}>
                                                                        <LAGrid spacing={0}>

                                                                            <LAGridItem xs={4}>
                                                                                <strong>{wa ? wa.name : ""}</strong>
                                                                            </LAGridItem>

                                                                            <LAGridItem xs={4}>
                                                                                <LACheckBox
                                                                                    label="OK"
                                                                                    onChange={onOkCheck}
                                                                                    value={e.response === "OK" ? true : false}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="REP"
                                                                                    onChange={onREPCheck}
                                                                                    value={e.response === "REP" ? true : false}
                                                                                />

                                                                                <LACheckBox
                                                                                    label="NA"
                                                                                    onChange={onNACheck}
                                                                                    value={e.response === "NA" ? true : false}
                                                                                />
                                                                            </LAGridItem>

                                                                        </LAGrid>
                                                                    </LAGridItem>
                                                                )
                                                            })}
                                                    </LAGrid>
                                                </LAGridItem>

                                            </LAGrid>
                                        </LAPaperWithLessPadding>
                                            <LAIconButton
                                                label="Delete"
                                                icon={<DeleteIcon />}
                                                onClick={() => this.onEquipmentDelete(index)} 
                                            />
                                    </LAGridItem>
                                )
                            })}
                        </LAGrid>
                    </LAGridItem>

                    <LAGridItem xs={12}>
                        <></>
                    </LAGridItem>

                    {serverError.length > ZEROTH && <LAGridItem xs={12}>
                        <LAErrorBox text={serverError} />
                    </LAGridItem>}

                    <LAGridItem xs={12} sm={12} md={4}>
                        <LASaveAndCancelButton
                            fullWidth={true}
                            saveButtonText="Save"
                            onSave={this.handleSave}
                            cancelButtonText="Close"
                            onCancel={this.handleCancel}
                            disableSave={Object.values(errors).length > 0 ? true : undefined}
                        />
                    </LAGridItem>

                </LAGrid>

                {unitId && <LAPopover
                    open={true}
                    onClose={this.onUnitDetailClose}
                    transformOrigin={{ horizontal: "left", vertical: "center" }}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorRef={null}>
                    <UnitDetails
                        {...this.props}
                        id={unitId}
                    />
                </LAPopover>}

                <RequestStatus requestStatus={addOperatorLogStatus.kind} successMessage="Operator Log has been added successfully" />
                <RequestStatus requestStatus={updateOperatorLogStatus.kind} successMessage="Operator Log has been updated successfully" />
            </AddUpdateOperatorLogStyles>
        );
    }

    private onEquipmentDelete = (index: number): void => {
        const shouldDelete = window.confirm('Are you sure to delete the added unit?');
        if(shouldDelete) {
            const newEquipments = [...this.state.data.equipments]
            newEquipments.splice(index, 1);
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    equipments: newEquipments
                }
            })
        }
    }

    private onEquipmentAdd = (): void => {
        const list = [...this.state.data.equipments];
        const workAroundList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.workAroundList : [];
        const workArounds = workAroundList.map((workAround) => ({
            id: 0,
            operator_Log_Equipment_ID: 0,
            operator_Log_Equipment_Walk_Around_ID: workAround.id,
            response: "",
            created_By: "",
            modified_By: "",
            created: "",
            modified: "",
        }))
        list.push({
            id: 0,
            operator_Log_ID: 0,
            unit_ID: 0,
            type: "",
            hours: 0,
            loads: 0,
            work_Description: "",
            created_By: "",
            modified_By: "",
            created: "",
            modified: "",
            walkarounds: workArounds,
        });
        this.setState({
            data: {
                ...this.state.data,
                equipments: list
            }
        });
        // console.log(this.state.attachment_list)
    }

    private onUnitDetailClose = (): void => {
        this.setState({ unitId: undefined });
    };

    private handleUnitDetails = (unitId: number): void => {
        this.setState({
            unitId
        });
    };

    private onTravelCheck = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        const iS = { ...this.state.data };

        if (value === "loa") {
            iS.loa = "Yes";
            iS.travel = "No";
        };

        if (value === "travel") {
            iS.loa = "No";
            iS.travel = "Yes";
        };

        this.setState({ data: iS });
    };

    private onOkCheck = (checked: boolean, tableIndex: number, subTableIndex: number): void => {
        const iS = { ...this.state.data };
        iS.equipments[tableIndex].walkarounds[subTableIndex].response = checked ? "OK" : "";

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...iS
            }
        });
    };

    private onRepCheck = (checked: boolean, tableIndex: number, subTableIndex: number): void => {
        const iS = { ...this.state.data };
        iS.equipments[tableIndex].walkarounds[subTableIndex].response = checked ? "REP" : "";

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...iS
            }
        });
    };

    private onNACheck = (checked: boolean, tableIndex: number, subTableIndex: number): void => {
        const iS = { ...this.state.data };
        iS.equipments[tableIndex].walkarounds[subTableIndex].response = checked ? "NA" : "";

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...iS
            }
        });
    };


    private handleDate = (date: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                date_: new Date(date).toISOString(),
                weekends: (isWeekend(new Date(date)) === true) ? "Yes" : "No"
            }
        });
    };

    private handleStart = (time: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                start_Time_Val: time
            }
        });
    };

    private handleEnd = (time: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                end_Time_Val: time
            }
        });
    };

    private handleLunch = (time: string): void => {
        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                lunch_Val: time
            }
        });
    };

    private handleCancel = (): void => {
        this.setState({ serverError: "" });

        this.props.history.push({
            pathname: ROUTE.EXTERNAL.OPERATOR_LOGS,
            search: getTokenFromUrl(false)
        });
    };

    private handleSave = async (): Promise<void> => {
        const data = this.state.data;

        if (hasPayload(this.props.validateOperatorLoginStatus)) {

            const eqps: IAddUpdateOperatorLogEquipment[] = [];
            let invalidHours = false;

            const hasDuplicatesUnit = data.equipments.some(
                (obj, index) => data.equipments.findIndex(item => item.unit_ID === obj.unit_ID) !== index
              );

            const duplicatedUnits: any = [];

            if (hasDuplicatesUnit) {
                // Duplicates found, populate duplicatedUnits array
                data.equipments.forEach((obj, index) => {
                  if (data.equipments.findIndex(item => item.unit_ID === obj.unit_ID) !== index &&
                      !duplicatedUnits.includes(obj.unit_ID)) {
                    duplicatedUnits.push(obj.unit_ID);
                  }
                });
            }

            const units = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.units : [];
            const duplicateUnitNumbers = duplicatedUnits.map((duplicateId:any) => {
                const matchingUnit = units.find((unit) => unit.id === duplicateId);
                return matchingUnit ? matchingUnit.unit_Number : null;
              });

            let invalidWalks = false;
            data.equipments.forEach((x) => {
                const walks: IAddUpdateOperatorLogWalkarounds[] = [];

                x.walkarounds.forEach((q) => {
                    if(q.response === "") {
                        invalidWalks = true
                    }
                    walks.push({
                        ID: q.id,
                        Response: q.response,
                        Operator_Log_Equipment_Walk_Around_ID: q.operator_Log_Equipment_Walk_Around_ID
                    });
                });

                eqps.push({
                    Walkarounds: walks,
                    ID: x.id,
                    Unit_ID: x.unit_ID,
                    Type: x.type,
                    Hours: x.hours,
                    Loads: x.loads,
                    Work_Description: x.work_Description
                });
                if(!(x.hours > 0)) {
                    invalidHours = true
                }
            });

            let inValidStartLunchEndTime = false;
            let inValidStartLunchEndTimeMessage = "";

            const parseTime = (timeString:string) => {
                const [hours, minutes] = timeString.split(":").map(Number);
                return hours * 60 + minutes;
            };

            const startTime = parseTime(data.start_Time_Val);
            const lunchTime = parseTime(data.lunch_Val);
            const endTime = parseTime(data.end_Time_Val);

            if (startTime >= lunchTime || startTime >= endTime ) {
                // Start time must be less than end time and lunch time
                inValidStartLunchEndTime = true;
                inValidStartLunchEndTimeMessage = "Start time must be less than end time and lunch time";
            } else if (lunchTime <= startTime || lunchTime >= endTime) {
                // Lunch time must be between start and end time
                inValidStartLunchEndTime = true;
                inValidStartLunchEndTimeMessage = "Lunch time must be between start and end time";
            } else if (endTime <= startTime || endTime <= lunchTime) {
                // End time must be More start and end time
                inValidStartLunchEndTime = true;
                inValidStartLunchEndTimeMessage = "End time must be more than start and end time";
            }

            if(invalidHours) {
                alert("Hours must be a greater than zero");
            } else if(hasDuplicatesUnit) {
                alert(`Duplicate Unit# ${duplicateUnitNumbers.join(', ')} detected in the Operator log. Please correct.`);
            } else if(invalidWalks) {
                alert("Please select All walk-around response/s for the added units");
            } else if(inValidStartLunchEndTime) {
                alert(`${inValidStartLunchEndTimeMessage}`);
            } else {
                if (data.id === 0) {
                    this.props.addOperatorLogRequest({
                        token: this.props.validateOperatorLoginStatus.payload.token,
                        request: {
                            Name: data.name,
                            Date_: data.date_,
                            Foreman: data.foreman,
                            Project: data.project,
                            Job_ID: data.job_ID,
                            Start_Time_Val: data.start_Time_Val,
                            End_Time_Val: data.end_Time_Val,
                            Lunch_Val: data.lunch_Val,
                            Total_Hours: data.total_Hours,
                            Travel: data.travel,
                            LOA: data.loa,
                            Comments: data.comments,
                            Weekends: data.weekends,
                            Equipments: eqps,
                            Created_By: this.props.validateOperatorLoginStatus.payload.email ?? "",
                        }
                    });
                } else {
                    this.props.updateOperatorLogRequest({
                        token: this.props.validateOperatorLoginStatus.payload.token,
                        request: {
                            ID: data.id,
                            Name: data.name,
                            Date_: data.date_,
                            Foreman: data.foreman,
                            Project: data.project,
                            Job_ID: data.job_ID,
                            Start_Time_Val: data.start_Time_Val,
                            End_Time_Val: data.end_Time_Val,
                            Lunch_Val: data.lunch_Val,
                            Total_Hours: data.total_Hours,
                            Travel: data.travel,
                            LOA: data.loa,
                            Comments: data.comments,
                            Weekends: data.weekends,
                            Equipments: eqps,
                            Created_By: this.props.validateOperatorLoginStatus.payload.email ?? "",
                        }
                    });
                }
    
                this.setState({ serverError: "" });
            }
        };
    };


    private handleEqp = (name: string, value: string, index: number): void => {
        const iS = { ...this.state.data };

        const record = { ...iS.equipments[index], [name]: value };
        iS.equipments[index] = record;

        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                ...iS
            },
            errors
        });
    };

    private handleChange = (name: string, value: string): void => {
        let errors = { ...this.state.errors };

        if (RequiredFields.includes(name))
            errors = this.errorChecker(name, value, errors);

        this.setState({
            ...this.state,
            data: {
                ...this.state.data,
                [name]: value
            },
            errors
        });
    };

    private getLook = (): void => {
        if (hasPayload(this.props.validateOperatorLoginStatus))
            this.props.getLookupsRequest({
                token: this.props.validateOperatorLoginStatus.payload.token,
                request: {
                    page: "Operator_Logs"
                }
            });
    };

    private setDataToState = (): void => {
        const query: any = this.props.id ? { id: this.props.id } : queryString.parse(this.props.location.search);

        if (query !== undefined && query.id !== undefined) {
            if (hasPayload(this.props.validateOperatorLoginStatus)) {
                
                if (isNotLoaded(this.props.getLookup)) {
                    this.getLook();
                };

                if (hasPayload(this.props.getLogs)) {
                if (query.id !== "0") {
                        const data = this.props.getLogs.payload.response[query.id];

                        this.setState({
                            data
                        });
            
                } else {
                    const email = this.props.validateOperatorLoginStatus.payload.email;
                    const workAroundList = hasPayload(this.props.getLookup) ? this.props.getLookup.payload.response.workAroundList : [];
                    const workArounds = workAroundList.map((workAround) => ({
                        id: 0,
                        operator_Log_Equipment_ID: 0,
                        operator_Log_Equipment_Walk_Around_ID: workAround.id,
                        response: "",
                        created_By: "",
                        modified_By: "",
                        created: "",
                        modified: "",
                    }))
                    this.setState({ 
                        data : {
                            ...this.state.data,
                            name: email? email: "",
                            equipments: [{
                                id: 0,
                                operator_Log_ID: 0,
                                unit_ID: 0,
                                type: "",
                                hours: 0,
                                loads: 0,
                                work_Description: "",
                                created_By: "",
                                modified_By: "",
                                created: "",
                                modified: "",
                                walkarounds: workArounds,
                            }
                            ]
                        } 
                    });
                    const errors: ById<IFieldErrorKeyValue> = {};

                    RequiredFields.forEach((x) => {
                        errors[x] = { key: x, message: FIELD_VALIDATOR_ERRORS.REQUIRED };
                    });
                    // // const data = Object.values(this.props.getLogs.payload.response).slice(-1)[0];
                    // // data.id = 0;
                    // // data.equipments.forEach((x) => {
                    // //     x.id = 0;
                    // //     x.walkarounds.forEach((q) => {
                    // //         q.id = 0;
                    // //         q.operator_Log_Equipment_ID = 0;
                    // //     });
                    // // });

                    // this.setState({
                    //     data,
                    //     errors
                    // });
                    this.setState({
                        errors
                    });
                }
            } else {
                this.props.getOperatorLogsRequest({
                    token: this.props.validateOperatorLoginStatus.payload.token,
                    request: {
                        email: this.props.validateOperatorLoginStatus.payload.email ?? ""
                    }
                });
            }

            };
        };
    };

    private errorChecker = (name: string, value: string, errors: ById<IFieldErrorKeyValue>): ById<IFieldErrorKeyValue> => {
        const result = FieldValidator(value, { required: true });
        const err: ById<IFieldErrorKeyValue> = errors;

        if (result.length > 0) {
            err[name] = { key: name, message: result };
        } else {
            delete err[name];
        }
        return err;
    };

}

const mapStateToProps = (state: IStore): IAddUpdateOperatorLogComponentStoreProps => ({
    getLogs: getOperatorLogs(state),
    getLookup: getFieldLookups(state),
    addOperatorLogStatus: addOperatorLogStatus(state),
    updateOperatorLogStatus: updateOperatorLogStatus(state),
    validateOperatorLoginStatus: validateOperatorLogin(state)
});

const mapDispatchToProps = (dispatch: IDispatch): IAddUpdateOperatorLogComponentDispatchProps => ({
    getLookupsRequest: (data: IFieldLookupRequest) => dispatch(getFieldLookupsLoadAction(data)),
    addOperatorLogRequest: (data: IAddOperatorLogRequest) => dispatch(addOperatorLogLoadAction(data)),
    getOperatorLogsRequest: (data: IGetOperatorLogsRequest) => dispatch(getOperatorLogsLoadAction(data)),
    updateOperatorLogRequest: (data: IUpdateOperatorLogRequest) => dispatch(updateOperatorLogLoadAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateOperatorLog);