import { ReactNode, PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import { LAPaperWithPadding } from "../../shared/paper";
import { IDispatch, IStore } from "../../../redux/reducers";
import { hasPayload, isNotLoaded, Server, STATUS_ENUM } from "../../../redux/server";
import { LADevExtremeGrid } from "../../shared/devExtreme";
import { LAButton } from "../../shared/buttons";
import { AddIcon } from "../../shared/icons";
import { MEDIA_QUERY_PHONE, WHITE_COLOR } from "../../shared/theme";
import { ROUTE } from "../../routes";
import PageSpacing from "../../shared/pageSpacing";
import { ById, SurewayAPIResponse } from "../../shared/publicInterfaces";
import { IToken, ITokenRequest } from "../../../redux/getToken/getTokenConstants";
import { callRouteWithQueryString, getTokenFromUrl,  pageAccessCheck, undefinedFunction, userName, YesOrNo } from "../../shared/constExports";
import { getToken } from "../../../redux/getToken/getTokenAccessor";
import { getTokenLoadAction } from "../../../redux/getToken/getTokenActions";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getLocations } from "../../../redux/field/getLocation/getLocationAccessor";
import { getLocationLoadAction } from "../../../redux/field/getLocation/getLocationActions";
import { ILocation, ILocationRequest } from "../../../redux/field/getLocation/getLocationConstants";
import LAGrid from "../../shared/grid";
import LAGridItem from "../../shared/gridList";
import LALoading from "../../shared/loading";
import { NotApplicable } from "../../../redux/toolRentals/validateShopGroup/validateShopGroupConstants";
import React from "react";
import { LAPopover } from "../../shared/popOver";
import { LocationHistoryPopup, SubLocationHistoryPopup } from "./locationHistory";
import LAErrorBox from "../../shared/errorBox";
import Lightbox from 'react-awesome-lightbox';
import "react-awesome-lightbox/build/style.css";
import { LocationsMenu, Navbar } from "../../shared/navbars";

interface ILocationsStoreProps {
    getToken: Server<SurewayAPIResponse<IToken>>;
    getLocation: Server<SurewayAPIResponse<ById<ILocation>>>;
};

interface ILocationsDispatchProps {
    getTokenRequest: (request: ITokenRequest) => unknown;
    getLocationsRequest: (data: ILocationRequest) => unknown;
};


interface ILocationsOwnProps {

};

interface ILocationsState {
    activeView: boolean;
    activeLocations: ILocation[];
    popUpData: ILocation | undefined;
    locationHistory: number | undefined;
    subLocationHistory: number | undefined;
    openImages: string[] | undefined;
};

const LocationsStyles = styled(LAPaperWithPadding)`
    margin: 10px 10px;
    word-break: break-word;

    .pull-left {
        position: absolute;
        left: 3%;
        height: 3%;
        top: 2%;
        background-color: rgb(191, 0, 0);
    };

    .toggledMenu{
        margin-left: 500px;
    }

    .pull-right {
        top: 2%;
        right: 3%;
        position: absolute;
    };

    .downloadStyle {
        font-size: 16px;
        text-decoration: underline;
        font-family: Arial, Helvetica, sans-serif;
    };

    @media only screen and (max-width: ${MEDIA_QUERY_PHONE}) {
        .pull-left {
            height: auto;
            width: auto;
            position: static;
        };
    };
`;

type ILocationsProps = RouteComponentProps
    & ILocationsStoreProps
    & ILocationsDispatchProps
    & ILocationsOwnProps;

class Locations extends PureComponent<ILocationsProps, ILocationsState> {

    public constructor(props: ILocationsProps) {
        super(props);
        this.state = {
            activeView: true,
            activeLocations: [],
            popUpData: undefined,
            subLocationHistory: undefined,
            locationHistory: undefined,
            openImages: undefined,
        };
    }

    public componentDidMount(): void {
        this.checkViewSelections();
        this.getDataForTable();
        this.callServer();
    };

    public componentDidUpdate(prevProps: ILocationsProps): void {
        this.checkViewSelections();
        if (this.props !== prevProps)
            this.callServer();
    };


    public render(): ReactNode {

        const { getLocation, getToken } = this.props;
        const { activeView, activeLocations, popUpData, subLocationHistory, locationHistory ,  openImages } = this.state;
        const getRole = pageAccessCheck(getToken, "locationList");
        const data = activeView ? activeLocations : hasPayload(getLocation) ? Object.values(getLocation.payload.response) : [];
        // console.log(data);
        return (
            <PageSpacing title="Locations" description="FIELD - LOCATIONS" fixedSpaceOnSmallerScreens={true}>
                <Navbar menu={LocationsMenu} role={true}/>
                {(getRole !== NotApplicable) && <>
                    {/* <LocationSubHeader
                        {...this.props}
                    /> */}

                    <LocationsStyles>
                        <LAGrid>

                            <LAGridItem xs={12} className="text-center">

                                {/* <ToggleButtonGroup
                                    value={"locations"}
                                    exclusive
                                    onChange={this.handleTabClick}
                                    aria-label="text alignment"
                                    className="toggledMenu"
                                >
                                    <ToggleButton value="jobs" aria-label="left aligned">
                                        JOBS
                                    </ToggleButton>
                                    <ToggleButton value="locations" aria-label="centered">
                                        LOCATIONS
                                    </ToggleButton>
                                </ToggleButtonGroup> */}

                                <LAButton
                                    label="Add New"
                                    className="pull-left"
                                    onClick={this.handleAdd}
                                    startIcon={<AddIcon color={WHITE_COLOR} />}
                                />

                                <h2>LOCATIONS</h2>
                                <hr />

                                {getLocation.kind === STATUS_ENUM.LOADING && <LALoading message="Loading Locations..." />}
                                {getLocation.kind === STATUS_ENUM.FAILED && <LAErrorBox text="Failed to load Locations..." />}

                                {getLocation.kind === STATUS_ENUM.SUCCEEDED &&
                                    <LAGrid>

                                        <LAGridItem xs={12}>
                                            <RadioGroup className="view-btn" row aria-label="" name="radioGroup" value={activeView ? "active" : "all"} onChange={this.onViewClick}>
                                                <FormControlLabel value="active" control={<Radio />} label="Active Locations" />
                                                <FormControlLabel value="all" control={<Radio />} label="All Locations" />
                                            </RadioGroup>
                                        </LAGridItem>

                                        <LAGridItem xs={12}>
                                            <LADevExtremeGrid
                                                data={data}
                                                columnChoose={true}
                                                columnsHiding={true}
                                                onEdit={this.onEdit}
                                                onClick={this.onClick}
                                                onImageClick = {this.onImageClick}
                                                searchPanel={true}
                                                filterHeader={true}
                                                export={true}
                                                actionWidth={120}
                                                removeStyleBtn={true}
                                                height={1200}
                                                id="locationList"
                                                customRowColor={true}
                                                exportFileName="Locations"
                                                storageKey="locationsSessionKey"
                                                onCustomClick={this.handleCustom}
                                                getRefreshDataCall={this.getDataForTable}
                                                onCustomIconClick={this.handleCustomIconClick}
                                                columns={[
                                                    { name: "site_Name", caption: "Site Name", type: "string", sortAsc: true },
                                                    { name: "site_Address", caption: "Site Address", type: "string" },
                                                    { name: "equip_On_Site", caption: "Equip on Site", type: "link" },
                                                    { name: "active", caption: "Active", width: 100, type: "string" },
                                                    { name: "service_Truck", caption: "Service Truck", type: "string" },
                                                    { name: "job_No", caption: "Job #", width: 100, type: "string" },
                                                    { name: "notes", caption: "Notes", type: "string" },
                                                    { name: "nearest_Medical_Facility", caption: "Nearest Medical Facilty", type: "string" },
                                                    // { name: "nearest_Medical_Facility_Distance", caption: " Driving distance to medical facility", type: "string" },
                                                    { name: "show_Sub_Sites_on_Map", caption: "Show Sub Sites on Map", width: 80, type: "string" },
                                                    { name: "Sub Working Sites", caption: "Sub Working Sites", type: "button" },
                                                    { name: "Location History", caption: "Location History", type: "button" },
                                                    { name: "Sub Location History", caption: "Sub Location History", type: "button" },
                                                    { name: "created", caption: "Created", type: "datetime" },
                                                    { name: "created_By", caption: "created By", type: "string" },
                                                    { name: "modified", caption: "Modified", type: "datetime" },
                                                    { name: "modified_By", caption: "Modified By", type: "string" },
                                                    { name: "default_Image", caption: "Images", type: "picture", width: 80 },
                                                ]}
                                            />
                                        </LAGridItem>

                                    </LAGrid>}
                            </LAGridItem>

                        </LAGrid>

                        {popUpData &&
                            <ServiceItems
                                data={popUpData}
                                onClose={this.handlePopupClose}
                                open={popUpData ? true : false}
                                handleCustomIconClick={this.handleCustomIconPopupClick}
                            />}

                        {locationHistory &&
                            <LocationHistoryPopup
                                token={getToken}
                                id={locationHistory}
                                onCancel={this.onHistoryClose}
                                open={locationHistory ? true : false}
                            />}

                        {subLocationHistory &&
                            <SubLocationHistoryPopup
                                token={getToken}
                                id={subLocationHistory}
                                onCancel={this.onHistoryClose}
                                open={subLocationHistory ? true : false}
                            />}

                        { openImages && <Lightbox
                            images={openImages}
                            onClose={this.handleImagesClose}
                        />}

                    </LocationsStyles>
                </>}
            </PageSpacing>
        );
    }

    private handleCustomIconClick = (name: string, e: any): void => {
        if(name === "equip_On_Site"){
            callRouteWithQueryString({
                route: this.props,
                search: { id: e.id.toString() },
                pathName: ROUTE.FIELD.EQUIPMENT.INDEX
            });
        }
    };

    private handleCustomIconPopupClick = (name: string, e: any): void => {
        if(name === "equip_On_Site"){
            callRouteWithQueryString({
                route: this.props,
                search: { keyword: e.id.toString() },
                pathName: ROUTE.FIELD.EQUIPMENT.INDEX
            });
        }
    };

    private onHistoryClose = (): void => {
        this.setState({
            locationHistory: undefined,
            subLocationHistory: undefined
        });
    };

    private handleCustom = (name: string, column: any): void => {
        const data: ILocation = column.row.data;

        switch (name) {
            case "Sub Working Sites":
                this.setState({ popUpData: data });
                break

            case "Location History":
                this.setState({ locationHistory: data.id });
                break;

            case "Sub Location History":
                this.setState({ subLocationHistory: data.id });
                break;
        };
    };

    private handlePopupClose = (): void => {
        this.setState({ popUpData: undefined });
    };

    private handleTabClick = (e: any, value: string): void => {
        if (value === "jobs")
            this.props.history.push({
                pathname: ROUTE.FIELD.JOBS.INDEX,
                search: getTokenFromUrl(false)
              });
    };

    private onViewClick = (): void => {
        this.setState({ activeView: !this.state.activeView });
        let activeView = !this.state.activeView;
            const getStorageValue = localStorage.getItem("key_locations");
            if(getStorageValue)
            {
                if(activeView)
                {
                    localStorage.setItem("key_locations", "active");
                }
                else
                {
                    localStorage.setItem("key_locations", "all");
                }
            }
            else
            {
                localStorage.setItem("key_locations", "active");
            }
    };

    private onEdit = (e: any): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: e.row.data.id.toString() },
            pathName: ROUTE.FIELD.LOCATION.LOCATION
        });
    };

    private onClick = (): void => {

    };

    private handleImagesClose = () => {
        this.setState({openImages: undefined});
    }

    private onImageClick = (name:string, images: string[]): void => {
        // console.log(name, images);
        this.setState({openImages:images})
    }

    private handleAdd = (): void => {
        callRouteWithQueryString({
            route: this.props,
            search: { id: "0" },
            pathName: ROUTE.FIELD.LOCATION.LOCATION
        });
    };

private checkViewSelections = (): void => {
    let activeView = this.state.activeView;
            const getStorageValue = localStorage.getItem("key_locations");
            if(getStorageValue)
            {
                if(getStorageValue === "active")
                {
                    activeView = true;
                }
                else
                {
                    activeView = false;
                }
            }
            else
            {
                localStorage.setItem("key_locations", "active");
            }
            this.setState({activeView: activeView});
}

    private callServer = (): void => {        

        if (isNotLoaded(this.props.getToken))
            this.props.getTokenRequest({
                request: {
                    username: getTokenFromUrl(true) ? undefined : userName,
                    user_token: getTokenFromUrl(true)
                }
            });


        if (hasPayload(this.props.getToken)) {

            if (pageAccessCheck(this.props.getToken, "locationList") !== NotApplicable) {
                if (isNotLoaded(this.props.getLocation)) {
                   this.getDataForTable();
                };
            } else {
                this.props.history.push({
                    pathname: ROUTE.ACCESS_DENIED,
                    search: getTokenFromUrl(false)
                  });
            }

            if (hasPayload(this.props.getLocation)) {
                this.setState({ activeLocations: Object.values(this.props.getLocation.payload.response).filter(x => x.active === YesOrNo[0].name) });
            };

        };
    };

    private getDataForTable = (): void => {
        if (hasPayload(this.props.getToken)) {
            this.props.getLocationsRequest({
                token: this.props.getToken.payload.response.token
            });
        };
    };

}

const mapStateToProps = (state: IStore): ILocationsStoreProps => ({
    getToken: getToken(state),
    getLocation: getLocations(state)
});

const mapDispatchToProps = (dispatch: IDispatch): ILocationsDispatchProps => ({
    getLocationsRequest: (data: ILocationRequest) => dispatch(getLocationLoadAction(data)),
    getTokenRequest: (request: ITokenRequest): unknown => dispatch(getTokenLoadAction(request))
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);



interface IServiceItemsPopup {
    open: boolean;
    data: ILocation;
    onClose: () => void;
    handleCustomIconClick: (name: string, e: any) => void;
};

export const ServiceItems: React.FC<IServiceItemsPopup> = React.memo((props: IServiceItemsPopup) => (
    <LAPopover open={props.open} onClose={props.onClose} anchorRef={null}>
        <LAPaperWithPadding>

            <div className="text-center">

                <strong>
                    Section Name:
                </strong>
                <span>
                    {props.data.site_Name}
                </span>

                <hr />
            </div>

            <LADevExtremeGrid
                height={400}
                id="subLocations"
                searchPanel={true}
                filterHeader={true}
                onClick={undefinedFunction}
                data={props.data.working_Sub_Sites ?? []}
                onCustomIconClick={props.handleCustomIconClick}
                columns={[
                    { name: "sub_Site_Name", caption: "Sub Site Name", type: "string" },
                    { name: "active", caption: "Active", type: "string" },
                    { name: "sub_Site_Address", caption: "Sub Site Address", type: "string" },
                    { name: "equip_On_Site", caption: "Equip on Site", type: "link" },
                    { name: "latitude", caption: "Latitude", type: "string" },
                    { name: "longitude", caption: "Longitude", type: "string" },
                    { name: "notes", caption: "Notes", type: "string" },
                    { name: "service_Truck", caption: "Service Truck", type: "string" },
                    { name: "created_By", caption: "Created By", type: "string" },
                    { name: "modified_By", caption: "Modified By", type: "string" },
                    { name: "created", caption: "Created", type: "string" },
                    { name: "modified", caption: "Modified", type: "string" },
                ]}
            />
        </LAPaperWithPadding>
    </LAPopover>
));