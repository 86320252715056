import { IAddUpdateExternalUser } from "../addExternalUser/addExternalUserConstants";

export enum IUPDATE_EXTERNAL_USER_REQUEST {
    REQUEST = "updateExternalUser/UPDATE_EXTERNAL_USER_REQUEST",
    SUCCESS = "updateExternalUser/UPDATE_EXTERNAL_USER_SUCCESS",
    FAILED = "updateExternalUser/UPDATE_EXTERNAL_USER_FAILED"
};

export interface IUpdateExternalUserRequest {
    token: string;
    request: IAddUpdateExternalUser;
};