import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_SURVEY_LOOKUP_REQUEST, ISurveyLookup } from "./getSurveyLookupConstants";
import { IGetSurveyLookupLoadAction, IGetSurveyLookupLoadFailedAction, IGetSurveyLookupSuccessAction, getSurveyLookupLoadFailedAction, getSurveyLookupLoadSuccessAction } from "./getSurveyLookupActions";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";

export const getSurveyLookupEpic: Epic = (
    action$: ActionsObservable<IGetSurveyLookupLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetSurveyLookupSuccessAction | IGetSurveyLookupLoadFailedAction> =>
    action$.ofType(IGET_SURVEY_LOOKUP_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ISurveyLookup>>(
                    END_POINTS.SURVEY.GET_SURVEY_LOOKUPS,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ISurveyLookup>): IGetSurveyLookupSuccessAction => {
                            return getSurveyLookupLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getSurveyLookupLoadFailedAction(response.message)))
                    )
            )
        );