import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST, IGetScheduleServiceHistoryByIdRequest, IScheduleServiceHistory } from "./getScheduleServiceHistoryByIdConstants";

export interface IGetScheduleServiceHistoryByIdLoadAction {
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.REQUEST;
    data: IGetScheduleServiceHistoryByIdRequest
}
export const getScheduleServiceHistoryByIdLoadAction = (data: IGetScheduleServiceHistoryByIdRequest): IGetScheduleServiceHistoryByIdLoadAction => ({
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.REQUEST,
    data
});
export interface IGetScheduleServiceHistoryByIdSuccessAction {
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.SUCCESS;
    list: SurewayAPIResponse<IScheduleServiceHistory>;
}
export const getScheduleServiceHistoryByIdLoadSuccessAction = (list: SurewayAPIResponse<IScheduleServiceHistory>): IGetScheduleServiceHistoryByIdSuccessAction => ({
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.SUCCESS,
    list
});
export interface IGetScheduleServiceHistoryByIdLoadFailedAction {
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.FAILED;
    message: string;
}
export const getScheduleServiceHistoryByIdLoadFailedAction = (message: string): IGetScheduleServiceHistoryByIdLoadFailedAction => ({
    type: IGET_SCHEDULE_SERVICE_HISTORY_BY_ID_REQUEST.FAILED,
    message
});
