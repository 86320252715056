import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IUPDATE_FORM_TEMPLATE_REQUEST, IUpdateFormTemplateRequest } from "./updateFormTemplateConstants";


export interface IUpdateFormTemplateLoadAction {
    type: IUPDATE_FORM_TEMPLATE_REQUEST.REQUEST;
    data: IUpdateFormTemplateRequest
}
export const updateFormTemplateLoadAction = (data: IUpdateFormTemplateRequest): IUpdateFormTemplateLoadAction => ({
    type: IUPDATE_FORM_TEMPLATE_REQUEST.REQUEST,
    data
});
export interface IUpdateFormTemplateSuccessAction {
    type: IUPDATE_FORM_TEMPLATE_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateFormTemplateLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateFormTemplateSuccessAction => ({
    type: IUPDATE_FORM_TEMPLATE_REQUEST.SUCCESS,
    message
});
export interface IUpdateFormTemplateLoadFailedAction {
    type: IUPDATE_FORM_TEMPLATE_REQUEST.FAILED;
    message: string;
}
export const updateFormTemplateLoadFailedAction = (message: string): IUpdateFormTemplateLoadFailedAction => ({
    type: IUPDATE_FORM_TEMPLATE_REQUEST.FAILED,
    message
});
