import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST, IMechanicTimesheetForApprovalGroupByDate } from "./getMechanicTimesheetForApprovalGroupByDateConstants";
import {  SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetMechanicTimesheetForApprovalGroupByDateLoadAction, IGetMechanicTimesheetForApprovalGroupByDateLoadFailedAction, IGetMechanicTimesheetForApprovalGroupByDateSuccessAction } from "./getMechanicTimesheetForApprovalGroupByDateActions";
import { IAPPROVE_MECHANIC_LOG_REQUEST } from "../approveMechanicLog/approveMechanicLogConstants";
import { IApproveMechanicLogSuccessAction } from "../approveMechanicLog/approveMechanicLogActions";
import { ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST } from "../SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemConstants";
import { ISaveManagerCommentsForDefectItemSuccessAction } from "../SaveManagerCommentsForDefectItem/saveManagerCommentsForDefectItemActions";
import { IRejectMechanicLogSuccessAction } from "../rejectMechanicLog/rejectMechanicLogActions";
import { IREJECT_MECHANIC_LOG_REQUEST } from "../rejectMechanicLog/rejectMechanicLogConstants";

type Actions =
    | IGetMechanicTimesheetForApprovalGroupByDateLoadAction
    | IGetMechanicTimesheetForApprovalGroupByDateSuccessAction
    | IGetMechanicTimesheetForApprovalGroupByDateLoadFailedAction
    | IApproveMechanicLogSuccessAction
    | IRejectMechanicLogSuccessAction
    | ISaveManagerCommentsForDefectItemSuccessAction
    | IFlushDataSuccessAction;

export const GetMechanicTimesheetForApprovalGroupByDateReducer = (state: Server<SurewayAPIResponse<IMechanicTimesheetForApprovalGroupByDate[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IMechanicTimesheetForApprovalGroupByDate[]>> => {
    switch (action.type) {
        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.REQUEST:
            return loading;

        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_MECHANIC_TIMESHEET_FOR_APPROVAL_GROUP_BY_DATE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        case IAPPROVE_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case IREJECT_MECHANIC_LOG_REQUEST.SUCCESS:
            return notLoaded;

        case ISAVE_MANAGER_COMMENTS_FOR_DEFECT_ITEM_REQUEST.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};