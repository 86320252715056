import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_UNIT_INSPECTION_SHEETS_REQUEST, IGetUnitInspectionSheets } from "./getUnitInspectionSheetsConstants";
import { IGetUnitInspectionSheetsLoadAction, IGetUnitInspectionSheetsLoadFailedAction, IGetUnitInspectionSheetsSuccessAction } from "./getUnitInspectionSheetsActions";
import { ISaveInspectionSheetSuccessAction } from "../saveInspectionSheet/saveInspectionSheetActions";
import { ISAVE_INSPECTION_SHEET_REQUEST } from "../saveInspectionSheet/saveInspectionSheetConstants";

type Actions =
    | IGetUnitInspectionSheetsLoadAction
    | IGetUnitInspectionSheetsSuccessAction
    | IGetUnitInspectionSheetsLoadFailedAction
    | ISaveInspectionSheetSuccessAction
    // | IUpdateUnitInspectionSheetsuccessAction
    | IFlushDataSuccessAction;

export const GetUnitInspectionSheetsReducer = (state: Server<SurewayAPIResponse<IGetUnitInspectionSheets[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<IGetUnitInspectionSheets[]>> => {
    switch (action.type) {
        case IGET_UNIT_INSPECTION_SHEETS_REQUEST.REQUEST:
            return loading;

        case IGET_UNIT_INSPECTION_SHEETS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_UNIT_INSPECTION_SHEETS_REQUEST.FAILED:
            return failed(action.message);
        
        case ISAVE_INSPECTION_SHEET_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};