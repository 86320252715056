import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IUPDATE_FORM_TEMPLATE_REQUEST } from "./updateFormTemplateConstants";
import { IUpdateFormTemplateLoadAction, IUpdateFormTemplateLoadFailedAction, IUpdateFormTemplateSuccessAction } from "./updateFormTemplateActions";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";

type Actions =
    | IUpdateFormTemplateLoadAction
    | IUpdateFormTemplateSuccessAction
    | IUpdateFormTemplateLoadFailedAction
    | IFlushDataSuccessAction;

export const UpdateFormTemplateReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case IUPDATE_FORM_TEMPLATE_REQUEST.REQUEST:
            return loading;

        case IUPDATE_FORM_TEMPLATE_REQUEST.SUCCESS:
            return succeeded(action.message);

        case IUPDATE_FORM_TEMPLATE_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};