export enum ICLONE_ATTACHMENT_GET_REQUEST {
    REQUEST = "cloneAttachmentGET/CLONE_ATTACHMENT_GET_REQUEST",
    SUCCESS = "cloneAttachmentGET/CLONE_ATTACHMENT_GET_SUCCESS",
    FAILED = "cloneAttachmentGET/CLONE_ATTACHMENT_GET_FAILED"
};

export interface ICloneAttachmentGETRequest {
    token: string;
    request: ICloneAttachmentGET[];
};

export interface ICloneAttachmentGET {
    Attachment_ID: number;
    Quantity: number;
    Part_No: string;
    Description: string;
    Created_By: string;
}