import { ById, SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { failed, loading, notLoaded, Server, succeeded } from "../../server";
import { IFlushDataSuccessAction } from "../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../clearRedux/clearReduxConstants";
import { IGET_DEPARTMENTS_REQUEST, IGetDepartments } from "./getDepartmentsConstants";
import { IGetDepartmentsLoadAction, IGetDepartmentsLoadFailedAction, IGetDepartmentsSuccessAction } from "./getDepartmentsActions";
import { IAddDepartmentSuccessAction } from "../addDepartment/addDepartmentActions";
import { IUpdateDepartmentSuccessAction } from "../updateDepartment/updateDepartmentActions";
import { IADD_DEPARTMENT_REQUEST } from "../addDepartment/addDepartmentConstants";
import { IUPDATE_DEPARTMENT_REQUEST } from "../updateDepartment/updateDepartmentConstants";

type Actions =
    | IGetDepartmentsLoadAction
    | IGetDepartmentsSuccessAction
    | IGetDepartmentsLoadFailedAction
    | IAddDepartmentSuccessAction
    | IUpdateDepartmentSuccessAction
    | IFlushDataSuccessAction;

export const GetDepartmentsReducer = (state: Server<SurewayAPIResponse<ById<IGetDepartments>>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ById<IGetDepartments>>> => {
    switch (action.type) {
        case IGET_DEPARTMENTS_REQUEST.REQUEST:
            return loading;

        case IGET_DEPARTMENTS_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_DEPARTMENTS_REQUEST.FAILED:
            return failed(action.message);
        
        case IADD_DEPARTMENT_REQUEST.SUCCESS:
            return notLoaded;

        case IUPDATE_DEPARTMENT_REQUEST.SUCCESS:
            return notLoaded;

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};