import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { ISAVE_DRIVERS_ASSIGNMENT_REQUEST, ISaveDriversAssignmentRequest } from "./saveDriversAssignmentConstants";


export interface ISaveDriversAssignmentLoadAction {
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.REQUEST;
    data: ISaveDriversAssignmentRequest
}
export const saveDriversAssignmentLoadAction = (data: ISaveDriversAssignmentRequest): ISaveDriversAssignmentLoadAction => ({
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.REQUEST,
    data
});
export interface ISaveDriversAssignmentSuccessAction {
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const saveDriversAssignmentLoadSuccessAction = (message: SurewayAPIResponse<string>): ISaveDriversAssignmentSuccessAction => ({
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.SUCCESS,
    message
});
export interface ISaveDriversAssignmentLoadFailedAction {
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.FAILED;
    message: string;
}
export const saveDriversAssignmentLoadFailedAction = (message: string): ISaveDriversAssignmentLoadFailedAction => ({
    type: ISAVE_DRIVERS_ASSIGNMENT_REQUEST.FAILED,
    message
});
