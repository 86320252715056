import { ISAVE_REPAIR_LINE_ITEM_REQUEST } from "./saveRepairLineItemConstants";
import { ISaveRepairLineItemLoadAction, ISaveRepairLineItemLoadFailedAction, ISaveRepairLineItemSuccessAction } from "./saveRepairLineItemActions";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { Server, notLoaded, loading, succeeded, failed } from "../../../server";

type Actions =
    | ISaveRepairLineItemLoadAction
    | ISaveRepairLineItemSuccessAction
    | ISaveRepairLineItemLoadFailedAction
    | IFlushDataSuccessAction;

export const SaveRepairLineReducer = (state: Server<SurewayAPIResponse<string>> = notLoaded, action: Actions): Server<SurewayAPIResponse<string>> => {
    switch (action.type) {
        case ISAVE_REPAIR_LINE_ITEM_REQUEST.REQUEST:
            return loading;

        case ISAVE_REPAIR_LINE_ITEM_REQUEST.SUCCESS:
            return succeeded(action.message);

        case ISAVE_REPAIR_LINE_ITEM_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};