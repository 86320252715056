import { FLUSH_DATA } from "../../../clearRedux/clearReduxConstants";
import { failed, loading, notLoaded, Server, succeeded } from "../../../server";
import { IFlushDataSuccessAction } from "../../../clearRedux/clearReduxActions";
import { IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST } from "./getTechServicesByUnitIDConstants";
import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IGetTechServicesByUnitIDLoadAction, IGetTechServicesByUnitIDLoadFailedAction, IGetTechServicesByUnitIDSuccessAction } from "./getTechServicesByUnitIDActions";
import { ITechService } from "../getTechServices/getTechServicesConstants";


type Actions =
    | IGetTechServicesByUnitIDLoadAction
    | IGetTechServicesByUnitIDSuccessAction
    | IGetTechServicesByUnitIDLoadFailedAction
    | IFlushDataSuccessAction;

export const GetTechServicesByUnitIDReducer = (state: Server<SurewayAPIResponse<ITechService[]>> = notLoaded, action: Actions): Server<SurewayAPIResponse<ITechService[]>> => {
    switch (action.type) {
        case IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.REQUEST:
            return loading;

        case IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.SUCCESS:
            return succeeded(action.list);

        case IGET_TECH_SERVICES_BY_UNIT_ID_REQUEST.FAILED:
            return failed(action.message);

        case FLUSH_DATA.SUCCESS:
            return notLoaded;

        default:
            return state;
    }
};