import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IDELETE_FILTER_PART_REQUEST, IDeleteFilterPartRequest } from "./deleteFilterPartConstants";


export interface IDeleteFilterPartLoadAction {
    type: IDELETE_FILTER_PART_REQUEST.REQUEST;
    data: IDeleteFilterPartRequest
}
export const deleteFilterPartLoadAction = (data: IDeleteFilterPartRequest): IDeleteFilterPartLoadAction => ({
    type: IDELETE_FILTER_PART_REQUEST.REQUEST,
    data
});
export interface IDeleteFilterPartSuccessAction {
    type: IDELETE_FILTER_PART_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const deleteFilterPartLoadSuccessAction = (message: SurewayAPIResponse<string>): IDeleteFilterPartSuccessAction => ({
    type: IDELETE_FILTER_PART_REQUEST.SUCCESS,
    message
});
export interface IDeleteFilterPartLoadFailedAction {
    type: IDELETE_FILTER_PART_REQUEST.FAILED;
    message: string;
}
export const deleteFilterPartLoadFailedAction = (message: string): IDeleteFilterPartLoadFailedAction => ({
    type: IDELETE_FILTER_PART_REQUEST.FAILED,
    message
});
