import { IG_DRIVERS_ASSIGNMENTS_REQUEST, IDriversAssignment, IGetDriversAssignmentRequest } from "./getDriversAssignmentConstants";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export interface IGetDriversAssignmentLoadAction {
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.REQUEST;
    data: IGetDriversAssignmentRequest
}
export const getDriversAssignmentLoadAction = (data: IGetDriversAssignmentRequest): IGetDriversAssignmentLoadAction => ({
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.REQUEST,
    data
});
export interface IGetDriversAssignmentSuccessAction {
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.SUCCESS;
    list: SurewayAPIResponse<ById<IDriversAssignment>>;
}
export const getDriversAssignmentLoadSuccessAction = (list: SurewayAPIResponse<ById<IDriversAssignment>>): IGetDriversAssignmentSuccessAction => ({
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.SUCCESS,
    list
});
export interface IGetDriversAssignmentLoadFailedAction {
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.FAILED;
    message: string;
}
export const getDriversAssignmentLoadFailedAction = (message: string): IGetDriversAssignmentLoadFailedAction => ({
    type: IG_DRIVERS_ASSIGNMENTS_REQUEST.FAILED,
    message
});
