import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IUPDATE_JOURNEL_REQUEST, IUpdateJournelRequest } from "./updateJournelConstants";


export interface IUpdateJournelLoadAction {
    type: IUPDATE_JOURNEL_REQUEST.REQUEST;
    data: IUpdateJournelRequest
}
export const updateJournelLoadAction = (data: IUpdateJournelRequest): IUpdateJournelLoadAction => ({
    type: IUPDATE_JOURNEL_REQUEST.REQUEST,
    data
});
export interface IUpdateJournelSuccessAction {
    type: IUPDATE_JOURNEL_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const updateJournelLoadSuccessAction = (message: SurewayAPIResponse<string>): IUpdateJournelSuccessAction => ({
    type: IUPDATE_JOURNEL_REQUEST.SUCCESS,
    message
});
export interface IUpdateJournelLoadFailedAction {
    type: IUPDATE_JOURNEL_REQUEST.FAILED;
    message: string;
}
export const updateJournelLoadFailedAction = (message: string): IUpdateJournelLoadFailedAction => ({
    type: IUPDATE_JOURNEL_REQUEST.FAILED,
    message
});
