import { SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";
import { IREJECT_MECHANIC_LOG_REQUEST, IRejectMechanicLogRequest } from "./rejectMechanicLogConstants";


export interface IRejectMechanicLogLoadAction {
    type: IREJECT_MECHANIC_LOG_REQUEST.REQUEST;
    data: IRejectMechanicLogRequest
}
export const rejectMechanicLogLoadAction = (data: IRejectMechanicLogRequest): IRejectMechanicLogLoadAction => ({
    type: IREJECT_MECHANIC_LOG_REQUEST.REQUEST,
    data
});
export interface IRejectMechanicLogSuccessAction {
    type: IREJECT_MECHANIC_LOG_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const rejectMechanicLogLoadSuccessAction = (message: SurewayAPIResponse<string>): IRejectMechanicLogSuccessAction => ({
    type: IREJECT_MECHANIC_LOG_REQUEST.SUCCESS,
    message
});
export interface IRejectMechanicLogLoadFailedAction {
    type: IREJECT_MECHANIC_LOG_REQUEST.FAILED;
    message: string;
}
export const rejectMechanicLogLoadFailedAction = (message: string): IRejectMechanicLogLoadFailedAction => ({
    type: IREJECT_MECHANIC_LOG_REQUEST.FAILED,
    message
});
