import { END_POINTS } from '../../endpoints';
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_MOVE_REQUEST_REQUEST, IMoveRequest } from "./getMoveRequestConstants";
import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IStore } from "../../reducers";
import { EpicDependencies } from "../../store";
import { withJsonContentType } from "../../epicUtils";
import { IGetMoveRequestLoadAction, IGetMoveRequestLoadFailedAction, IGetMoveRequestSuccessAction, getMoveRequestLoadFailedAction, getMoveRequestLoadSuccessAction } from "./getMoveRequestActions";

export const getMoveRequestEpic: Epic = (
    action$: ActionsObservable<IGetMoveRequestLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetMoveRequestSuccessAction | IGetMoveRequestLoadFailedAction> =>
    action$.ofType(IGET_MOVE_REQUEST_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<IMoveRequest[]>(
                    END_POINTS.EXTERNAL.MOVE_REQUEST,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: IMoveRequest[]): IGetMoveRequestSuccessAction => {
                            return getMoveRequestLoadSuccessAction(response);
                        }),
                        catchError((response: SurewayAPIResponse<string>) => ActionsObservable.of(getMoveRequestLoadFailedAction(response.message)))
                    )
            )
        );