import { END_POINTS } from "../../../endpoints";
import { mergeMap, catchError, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { StateObservable, ActionsObservable, Epic } from "redux-observable";
import { IGET_BULK_METER_READINGS_REQUEST, IBulkMeterReading } from "./getBulkMeterReadingsConstants";
import { IStore } from "../../../reducers";
import { EpicDependencies } from "../../../store";
import { withJsonContentType } from "../../../epicUtils";
import { IGetBulkMeterReadingsLoadAction, IGetBulkMeterReadingsLoadFailedAction, IGetBulkMeterReadingsSuccessAction, 
         getBulkMeterReadingsLoadFailedAction, getBulkMeterReadingsLoadSuccessAction } from "./getBulkMeterReadingsActions";
import { ById, SurewayAPIResponse } from "../../../../react/shared/publicInterfaces";

export const getBulkMeterReadingsEpic: Epic = (
    action$: ActionsObservable<IGetBulkMeterReadingsLoadAction>,
    store: StateObservable<IStore>,
    {post}: EpicDependencies
): Observable<IGetBulkMeterReadingsSuccessAction | IGetBulkMeterReadingsLoadFailedAction> =>
    action$.ofType(IGET_BULK_METER_READINGS_REQUEST.REQUEST)
        .pipe(
            mergeMap((action) =>
                post<SurewayAPIResponse<ById<IBulkMeterReading>>>(
                    END_POINTS.FIELD.SCOT.GetBulkMeterReadings,
                    action.data,
                    withJsonContentType(store.value)
                )
                    .pipe(
                        map((response: SurewayAPIResponse<ById<IBulkMeterReading>>): IGetBulkMeterReadingsSuccessAction => {
                            return getBulkMeterReadingsLoadSuccessAction(response);
                        }),
                        catchError((response: string) => ActionsObservable.of(getBulkMeterReadingsLoadFailedAction(response)))
                    )
            )
        );