import { SurewayAPIResponse } from "../../../react/shared/publicInterfaces";
import { IADD_TASK_REQUEST, IAddTaskRequest } from "./addTaskConstants";


export interface IAddTaskLoadAction {
    type: IADD_TASK_REQUEST.REQUEST;
    data: IAddTaskRequest
}
export const addTaskLoadAction = (data: IAddTaskRequest): IAddTaskLoadAction => ({
    type: IADD_TASK_REQUEST.REQUEST,
    data
});
export interface IAddTaskSuccessAction {
    type: IADD_TASK_REQUEST.SUCCESS;
    message: SurewayAPIResponse<string>;
}
export const addTaskLoadSuccessAction = (message: SurewayAPIResponse<string>): IAddTaskSuccessAction => ({
    type: IADD_TASK_REQUEST.SUCCESS,
    message
});
export interface IAddTaskLoadFailedAction {
    type: IADD_TASK_REQUEST.FAILED;
    message: string;
}
export const addTaskLoadFailedAction = (message: string): IAddTaskLoadFailedAction => ({
    type: IADD_TASK_REQUEST.FAILED,
    message
});
