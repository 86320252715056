
export enum IREMOVE_DEPARTMENT_FROM_ITEMS_REQUEST {
    REQUEST = "removeDepartmentFromItems/REMOVE_DEPARTMENT_FROM_ITEMS_REQUEST",
    SUCCESS = "removeDepartmentFromItems/REMOVE_DEPARTMENT_FROM_ITEMS_SUCCESS",
    FAILED = "removeDepartmentFromItems/REMOVE_DEPARTMENT_FROM_ITEMS_FAILED"
};

export interface IRemoveDepartmentFromItemsRequest {
    token: string;
    request: IRemoveDepartmentFromItems;
};

export interface IRemoveDepartmentFromItems {
    department_id: number;
    page: string;
    items: number[];
    username: string;
};